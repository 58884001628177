import Badge from 'components/badge';

export interface PropsType {
  start?: string;
  end?: string;
}

const isNextDay = (start: string, end: string) => {
  const endMinutes = end ? end.split(':')[1] : undefined;

  if (endMinutes === undefined) {
    return false;
  }
  return Number(start?.replace(':', '.')) > Number(end?.replace(':', '.'));
};

const SupOneDay = ({ start, end }: PropsType) =>
  isNextDay(start, end) ? (
    <small className="mx-2">
      <Badge variant="danger">Next Day</Badge>
    </small>
  ) : null;

export default SupOneDay;
