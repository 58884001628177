import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropdownRadiologist } from 'components/project/dropdown/RadiologistDropdown';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropdownProviderType } from 'components/project/dropdown/ProviderTypeDropdown';
import { DropdownRadiology } from 'components/project/dropdown/RadiologyDropdown';
import { DropdownLocation } from 'components/project/dropdown/LocationDropdown';
import { DropDownExamType } from 'components/project/dropdown/ExamTypeDropdown';
import { DropdownDivision } from 'components/project/dropdown/Division';
import { Select } from 'components/form/select';
import { Input } from 'components/form/textField';
import { DateRange } from 'components/form/dateRange';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import {
  storeProFfsReport,
  defaultFilterValue,
  FilterType,
} from 'stores/_mobx/billingCodding/proFfsReport';

const className = 'col-md-3';

// N - None,
// F-Facility,
// I-Insurance,
// S-Selfpay,
// H-Hospice,
// O-Others,
// C-Occ Health care,
// T-Fee for Service,
// P-Purchased Service,
// B-Non Billable,
// R-Correctional

const optionsBillProcess = [
  {
    label: 'Fee for service',
    value: 'T',
  },
  {
    label: 'Purchased service',
    value: 'P',
  },
];

interface PropsType {
  fetching: boolean;
  isFilterVisible: boolean;
  onSearch: (filter: FilterType) => void;
}

const Filter = ({ fetching, isFilterVisible, onSearch }: PropsType) => {
  const { control, handleSubmit, watch, reset, setValue } = useForm<FilterType>(
    { defaultValues: storeProFfsReport.filter }
  );

  const radiologyGroupId = watch('radiologyGroupId');

  const handleClickReset = () => {
    reset(defaultFilterValue);
    onSearch(defaultFilterValue);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'radiologyGroupId') {
        setValue('radiologistId', 0);
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form
      className={`row${isFilterVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSearch)}>
      <Input
        name="lastName"
        label="Last Name"
        className={className}
        control={control}
      />
      <Input
        name="firstName"
        label="First Name"
        className={className}
        control={control}
      />
      <DropDownExamType
        name="examType"
        label="Modality"
        optionsForOrderOnly
        className={className}
        control={control}
      />
      <DropdownProviderType
        name="providerType"
        label="Provider Type"
        className={className}
        control={control}
      />

      <div />

      <DropDownFacility
        name="facilityId"
        label="Facility"
        className={className}
        control={control}
      />
      <DropDownState
        name="stateId"
        label="State"
        className={className}
        control={control}
      />
      <DropdownLocation
        name="locationId"
        label="Location"
        className={className}
        control={control}
      />
      <DropdownDivision
        name="division"
        label="Division"
        className={className}
        control={control}
      />
      <DropDownPlaceOfService
        name="pos"
        label="Place of Service"
        className={className}
        control={control}
      />
      <DropdownRadiology
        name="radiologyGroupId"
        label="Radiology Group"
        className={className}
        control={control}
      />
      <DropdownRadiologist
        name="radiologistId"
        label="Radiologist"
        className={className}
        radiologyGroupId={radiologyGroupId}
        control={control}
      />
      <Select
        name="billProcess"
        label="Bill Process"
        className={className}
        options={optionsBillProcess}
        control={control}
      />

      <div />

      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-6 col-lg-auto"
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
