import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Observer } from 'mobx-react-lite';

import Tabs from 'components/tabs';
import { Button } from 'components/button';
import { LayoutSideTitle } from 'components/layout';
import FacilityTab from './tabs/FacilityTab';
import ContactTab from './tabs/ContactTab';
import BillingTabContainer, { BillingTab } from './tabs/BillingTab';
import DocumentTab from './tabs/DocumentTab';
import SettingsTab from './tabs/Settings';

import FacilityPageActions from 'actions/system-setup/master-setup/facility/FacilityPageActions';
import FacilityPageStore from 'stores/system-setup/facility/FacilityPageStore';
import {
  storeFacility,
  FacilitySettingsType,
} from 'stores/_mobx/systemSetup/facility';
import { URL_FACILITY } from 'constant/path/systemSetup';

export const TABS = {
  FACILITY_TAB: 0,
  BILLING_TAB: 1,
  CONTACT_TAB: 2,
  DOCUMENT_TAB: 3,
  SETTINGS_TAB: 4,
};

interface MatchParams {
  id: string;
}

export interface PAbstractFacilityFormPage
  extends RouteComponentProps<MatchParams> {
  updateOverview: () => void;
}

export class SAbstractFacilityFormPage {
  updateOverviewRequired: boolean = false;
  loaded: boolean = false;
  currentTab: number = 0;
  blocked: Array<any> = [];
  facilityItem: any = {};
  locationList: Array<any> = [];
  billingItem: any = {};
  serviceList: Array<any> = [];
  contactData: any;
  selectedContacts: Array<any> = [];
  documentList: Array<any> = [];
  facilityName: string = '';
}

const tabsList = [
  { label: 'Facility' },
  { label: 'Billing' },
  { label: 'Contact' },
  { label: 'Document' },
  { label: 'Settings' },
];

export default abstract class AbstractFacilityFormPage<
  P extends PAbstractFacilityFormPage,
  S extends SAbstractFacilityFormPage
> extends React.Component<P, S> {
  facilityTab: FacilityTab = null;
  billingTab: BillingTab = null;
  contactTab: ContactTab = null;
  documentTab: DocumentTab = null;

  abstract getTitle(): string;
  abstract onSubmitFacilityTab(): Promise<null | Record<string, string[]>>;
  abstract onSubmitBillingTab(): void;
  abstract onSubmitContactTab(): void;
  abstract updateContactTabData(): void;
  abstract onSubmitDocumentTab(): void;
  abstract updateDocumentTabData(): void;
  abstract onSubmitSettingsTab(
    settings: FacilitySettingsType
  ): Promise<boolean>;

  submitPostProcess(clb: () => void) {
    this.setState({ updateOverviewRequired: true }, clb);
  }

  static calculateFacilityPageState = (
    prevState: SAbstractFacilityFormPage
  ) => {
    const facilityItem = FacilityPageStore.getState().facilityItem;

    return {
      ...prevState,
      facilityItem,
      locationList: FacilityPageStore.getState().locationList,
      billingItem: FacilityPageStore.getState().billingItem,
      serviceList: FacilityPageStore.getState().serviceList,
      contactData: FacilityPageStore.getState().contactData,
      selectedContacts: FacilityPageStore.getState().selectedContacts,
      documentList: FacilityPageStore.getState().documentList,
      currentTab: FacilityPageStore.getState().currentTab,
      blocked: FacilityPageStore.getState().blocked,
      facilityName: facilityItem?.facilityData?.facility_nm || '',
    };
  };

  render() {
    const facilityId = Number(this.props.match.params.id);

    return (
      <div className={this.state.loaded ? '' : 'on-ajax'}>
        <LayoutSideTitle title={this.getTitle()}>
          <Button
            variant="danger"
            text="Back to List"
            onClick={this.backToList.bind(this)}
          />
        </LayoutSideTitle>

        <Tabs
          defaultTabIndex={this.state.currentTab}
          tabsList={tabsList}
          onClick={this.handleSelected}
          className="mb-4">
          {this.state.loaded ? (
            <FacilityTab
              blocked={this.state.blocked[TABS.FACILITY_TAB]}
              facilityItem={this.state.facilityItem}
              locationList={this.state.locationList}
              updateLocationList={(regionId: number, stateId: number) =>
                AbstractFacilityFormPage.updateLocationList(regionId, stateId)
              }
              submitFacilityTab={this.onSubmitFacilityTab}
              backToList={this.backToList.bind(this)}
              ref={(r) => (this.facilityTab = r)}
            />
          ) : null}

          {this.state.loaded ? (
            <BillingTabContainer
              blocked={this.state.blocked[TABS.BILLING_TAB]}
              facilityItem={this.state.facilityItem.facilityData}
              billingItem={this.state.billingItem}
              serviceList={this.state.serviceList}
              submitBillingTab={this.onSubmitBillingTab.bind(this)}
              backToList={this.backToList.bind(this)}
              facilityId={facilityId}
              refReplace={(r) => {
                this.billingTab = r;
              }}
              chargeUpdateOverview={() =>
                this.setState({ updateOverviewRequired: true })
              }
              history={this.props.history}
            />
          ) : null}

          {this.state.loaded ? (
            <ContactTab
              blocked={this.state.blocked[TABS.CONTACT_TAB]}
              contactData={this.state.contactData}
              selectedContacts={this.state.selectedContacts}
              submitContactTab={this.onSubmitContactTab.bind(this)}
              updateContactList={this.updateContactTabData.bind(this)}
              facilityId={facilityId}
              backToList={this.backToList.bind(this)}
              ref={(r) => (this.contactTab = r)}
              history={this.props.history}
            />
          ) : null}

          {this.state.loaded ? (
            <DocumentTab
              blocked={this.state.blocked[TABS.DOCUMENT_TAB]}
              documentList={this.state.documentList}
              submitDocumentTab={this.onSubmitDocumentTab.bind(this)}
              id={facilityId}
              backToList={this.backToList.bind(this)}
              ref={(r) => (this.documentTab = r)}
              history={this.props.history}
            />
          ) : null}

          {this.state.loaded ? (
            <Observer>
              {() => (
                <SettingsTab
                  isBlocked={!facilityId}
                  fetching={storeFacility.fetchingSettings}
                  initialValues={storeFacility.settings}
                  onSubmit={this.onSubmitSettingsTab}
                />
              )}
            </Observer>
          ) : null}
        </Tabs>
      </div>
    );
  }

  static updateLocationList(regionId: number, stateId: number) {
    return FacilityPageActions.loadLocationList(regionId, stateId);
  }

  handleSelected = (index: number) => {
    const blocked = { ...this.state.blocked };
    FacilityPageActions.updateTabsState(index, blocked);
  };

  backToList() {
    FacilityPageActions.clearAllTabs();
    FacilityPageActions.setBackToList(true);
    if (this.state.updateOverviewRequired) {
      if (this.props.updateOverview) {
        this.props.updateOverview();
      }
    }
    this.props.history.push(URL_FACILITY);
  }

  goToOverview() {
    if (this.state.updateOverviewRequired) {
      if (this.props.updateOverview) {
        this.props.updateOverview();
      }
    }
    this.props.history.push(URL_FACILITY);
  }
}
