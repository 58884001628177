import React from 'react';

import { Grid } from 'components/grid';
import { IconButton } from 'components/button';
import DialogConfirm from 'components/modal/dialogConfirm';
import Notification from 'components/modal/Notification';
import FileUpload from 'components/form/input/FileUpload';
import ApplicationUsersAddEditActions from 'actions/privileges/ApplicationUsersAddEditActions';
import Text from 'components/form/input/Text';
import { BASE_URL_FILE_DIR } from 'constant/config';

export interface PDocumentsTree {
  userId: number;
  noclone: string;
}

export class TDocTreeItem {
  refid: number;
  fileName: string;
  description: string;
  created_at: string;
  created_by: string;
}

export class SDocumentsTree {
  rootOpened: boolean = true;
  deleteFile: { refid: number; fileName: string } = null;
  selectedDir: string = null;
  documentTree: any = {}; // map folder to TDocTreeItem
  onAjax: boolean = false;
  showDeleteItemPopUp: boolean = false;
  isOpenModal: boolean = false;
  filter: any = {};
}

export default class DocumentsTree extends React.Component<
  PDocumentsTree,
  SDocumentsTree
> {
  constructor(props: PDocumentsTree) {
    super(props);
    this.state = new SDocumentsTree();
  }

  componentDidMount() {
    if (this.props.userId === -1) {
      ApplicationUsersAddEditActions.getOtherDocumentsStructure().then((v) =>
        this.setState({ documentTree: v })
      );
    } else {
      ApplicationUsersAddEditActions.getOtherDocuments(this.props.userId).then(
        (v) => this.setState({ documentTree: v })
      );
    }
  }

  getDocumentTree() {
    if (this.state.rootOpened) {
      let dirs = [];
      for (let i in this.state.documentTree) {
        if (this.state.documentTree.hasOwnProperty(i)) {
          dirs.push({ fileName: i });
        }
      }
      return dirs;
    } else {
      return [{ fileName: 'root' }].concat(
        this.state.documentTree[this.state.selectedDir]
      );
    }
  }

  handleCloseDialogConfirmDeleting = () => {
    this.setState({
      showDeleteItemPopUp: false,
      deleteFile: null,
    });
  };

  handleApproveDelete = () => {
    let clb = null;
    const deleteFile = this.state.deleteFile;
    if (deleteFile.refid) {
      clb = () =>
        ApplicationUsersAddEditActions.deleteUserDocument(
          deleteFile.refid
        ).then((r: any) => {
          if (r.status === 'S') {
            DocumentsTree.deletedNotifiction();
            this.dropFromCollection(deleteFile);
          } else {
            Notification.warning("Can't delete file because it is not exists");
          }
        });
    } else {
      clb = () => {
        this.dropFromCollection(deleteFile);
        DocumentsTree.deletedNotifiction();
      };
    }

    this.setState(
      {
        showDeleteItemPopUp: false,
        deleteFile: null,
      },
      clb
    );
  };

  static deletedNotifiction() {
    Notification.success('File was deleted');
  }

  dropFromCollection(file: any) {
    const { selectedDir } = this.state;
    const data = this.state.documentTree[selectedDir]?.filter(
      (el: any) => el !== file
    );
    const documentTree = { ...this.state.documentTree, [selectedDir]: data };

    this.setState({ documentTree });
  }

  openDirectory(event: any, selectedDir: any) {
    if (event) {
      event.preventDefault();
    }
    if (selectedDir === 'root') {
      this.setState({
        rootOpened: true,
        selectedDir: null,
      });
    } else {
      this.setState({
        selectedDir: selectedDir,
        rootOpened: false,
      });
    }
  }

  static getFileIcon(fileName: string) {
    let ext = fileName.split('.').pop().toLowerCase();
    switch (ext) {
      case 'xlsx':
        ext = 'xls';
        break;
      case 'docx':
        ext = 'doc';
        break;
      case 'gif':
        ext = 'img';
        break;
      case 'zip':
      case 'xls':
      case 'doc':
      case 'jpg':
      case 'png':
      case 'pdf':
      case 'txt':
        break;
      default:
        ext = 'unknown';
    }
    return 'assets/images/filetypes/small-' + ext + '.png';
  }

  renderTitle(value: string, file: { refid: number }) {
    const style = { margin: '0 15px' };
    if (this.state.rootOpened) {
      return (
        <a href="/" onClick={(event) => this.openDirectory(event, value)}>
          <img
            src="/assets/images/filetypes/small-folder.png"
            style={style}
            alt="name"
          />
          {value}
        </a>
      );
    }
    if (value === 'root') {
      return (
        <a href="/" onClick={(event) => this.openDirectory(event, 'root')}>
          <img
            src="/assets/images/filetypes/small-folder.png"
            style={style}
            alt="name"
          />{' '}
          ..
        </a>
      );
    }
    let path = BASE_URL_FILE_DIR;

    if (this.props.userId === -1 || !file.refid) {
      path += `tempfolder/${value}`;
    } else {
      path += `documents/${this.props.userId}/other/${value}`;
    }

    return (
      <a href={path} target="_blank" rel="noreferrer">
        <img src={DocumentsTree.getFileIcon(value)} style={style} alt="name" />
        {value}
      </a>
    );
  }

  getColumns() {
    const columns: Array<{
      name: string;
      title: string;
      className?: string;
      render?: (value: string, row: any) => React.ReactNode;
    }> = [
      {
        name: 'fileName',
        title: 'Name',
        render: (value: string, row: any) => {
          return this.renderTitle(value, row);
        },
      },
    ];
    if (this.state.selectedDir) {
      columns.push({
        name: 'description',
        title: 'Description',
        render: (description, data) => {
          if (data.fileName === 'root') {
            return null;
          }
          if (!data.key) {
            data.key = window.performance.now().toString(36);
          }
          return (
            <Text
              name={'desciption_' + data.key}
              noLabel
              value={description}
              shouldComponentUpdate={(nextProps, thisProps) => {
                return nextProps.value !== thisProps.value;
              }}
              attr={{
                onBlur: () => {
                  if (this.props.userId > 0) {
                    ApplicationUsersAddEditActions.updateOtherDocuments(
                      data
                    ).then((r) => {
                      if (r.status === 'S') {
                        Notification.success('File description was updated');
                      } else {
                        Notification.danger(
                          'Could not update file due unknown reasons'
                        );
                      }
                    });
                  }
                },
              }}
              onSetValue={(n: string, v: string) => {
                data.description = v;
                this.forceUpdate();
              }}
            />
          );
        },
      });
      columns.push({
        name: 'created_by',
        title: 'Uploaded By',
        className: 'width-150',
      });

      columns.push({
        name: 'created_at',
        title: 'Uploaded',
        className: 'width-150',
      });

      columns.push({
        name: 'actions',
        title: 'Actions',
        className: 'width-150',
        render: (value, file) => {
          if (file.fileName === 'root') {
            return null;
          }
          return (
            <IconButton onClick={(e) => this.deleteFile(e, file)}>
              <i className="bi bi-trash" />
            </IconButton>
          );
        },
      });
    }
    return columns;
  }

  getData() {
    return this.state.documentTree;
  }

  afterFileUpload(fileData: any) {
    const selectedDir: string = this.state.selectedDir;
    if (!fileData || !fileData.length || !selectedDir) {
      return;
    }
    const file: {
      fileName: string;
      folder: string;
      description: string;
      userId?: number;
    } = {
      fileName: fileData,
      folder: selectedDir,
      description: '',
    };
    if (this.props.userId > 0) {
      file.userId = this.props.userId;
      ApplicationUsersAddEditActions.uploadOtherDocuments(file)
        .then(() =>
          ApplicationUsersAddEditActions.getOtherDocuments(this.props.userId)
        )
        .then((documentTree) => this.setState({ documentTree }));
    } else {
      let tree = this.state.documentTree;
      tree[selectedDir as any].push(file);
      this.setState({ documentTree: tree });
    }
  }

  deleteFile(event: any, file: { refid: number; fileName: string }) {
    if (event) {
      event.preventDefault();
    }
    this.setState({ deleteFile: file, showDeleteItemPopUp: true });
  }

  render() {
    return (
      <div className="document-tree">
        <Grid
          columns={this.getColumns()}
          onAjax={this.state.onAjax}
          dataSource={this.getDocumentTree()}
          id="otherDocumentsGrid"
          gridControlPanel={
            !this.state.rootOpened && (
              <FileUpload
                onSetValue={(name: string, fileName: string) =>
                  this.afterFileUpload(fileName)
                }
                className="uploader"
                buttonLabel="Upload File"
                noWarning
              />
            )
          }
          disablePagination
          stateless
          // noControl
        />
        {this.state.showDeleteItemPopUp ? (
          <DialogConfirm
            onCancel={this.handleCloseDialogConfirmDeleting}
            onApprove={this.handleApproveDelete}>
            Are you sure you want to delete {this.state.deleteFile.fileName}?
          </DialogConfirm>
        ) : null}
      </div>
    );
  }
}
