import { useEffect, useMemo, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { Button } from 'components/button';
import { PureTextEditor } from 'components/form/richTextEditor';
import { SpinnerFixed } from 'components/spinner';
import { ControlsLayout } from 'components/layout';

import {
  storeHomePage,
  prettierBytes,
  MAX_CONTENT_SIZE,
} from 'stores/_mobx/systemSetup/masterSetting/homePage';
import UserProfileStore from 'stores/UserProfileStore';

const convertedMaxSize = prettierBytes(MAX_CONTENT_SIZE);

interface PropsType extends RouteComponentProps {}

const EditHomePage = ({ history }: PropsType) => {
  const { fetching, editableContent } = storeHomePage;

  const [content, setContent] = useState<string>(editableContent);

  const isSimpleUser = useMemo(
    () => UserProfileStore.getUserType() === 'U',
    []
  );

  const isDirty = editableContent !== content;

  const handleClickReset = () => {
    setContent(editableContent);
  };

  const handleClickSubmit = () => {
    storeHomePage.saveContent(content).then((isSucceed) => {
      if (isSucceed) {
        history.push('/');
      }
    });
  };

  const contentSize = useMemo(() => new Blob([content]).size, [content]);

  const convertedContentSize = prettierBytes(contentSize);

  const errorMessage =
    contentSize > MAX_CONTENT_SIZE
      ? `Allowed content size is ${convertedMaxSize}`
      : '';

  useEffect(() => {
    setContent(editableContent);
  }, [editableContent]);

  useEffect(() => {
    if (isSimpleUser) {
      storeHomePage.getHomePageData();
    } else {
      history.push('/');
    }

    return storeHomePage.clearContent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isSimpleUser ? (
    <>
      <Title title="Edit Home Page" />
      <PureTextEditor
        name="html"
        value={content}
        errorMessage={errorMessage}
        onChange={setContent}
      />
      <label>{`Current content size is ${convertedContentSize}. `}</label>
      <ControlsLayout alignX="right">
        <Link to="/" className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={!isDirty || fetching}
          onClick={handleClickSubmit}
        />
      </ControlsLayout>
      {fetching && <SpinnerFixed />}
    </>
  ) : null;
};

export default observer(EditHomePage);
