import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { phoneValidator } from 'utils/formValidators';

interface PropsType {
  minLength: number;
  maxLength: number;
  alphaNumeric: boolean;
}

export const validationUserAuthentic = yupResolver(
  yup.object().shape({
    userName: yup.string().required('Please, fill in the user name'),
    email: yup
      .string()
      .required('Please, fill in the email')
      .email('Invalid email address.'),
  })
);

export const validationSecurityQuestion = yupResolver(
  yup.object().shape({
    answer1: yup.string().required('Please, enter answer for question.'),
    answer2: yup.string().required('Please, enter answer for question.'),
  })
);

export const validationResetPassword = (props: PropsType) =>
  yupResolver(
    yup.object().shape({
      password: yup
        .string()
        .required('Please specify password')
        .min(
          props.minLength,
          `Password length must be between ${props.minLength} and ${props.maxLength}`
        )
        .max(
          props.maxLength,
          `Password length must be between ${props.minLength} and ${props.maxLength}`
        )
        .test({
          test: (password: string) =>
            props.alphaNumeric ? /(\d[a-z])|([a-z]\d)/i.test(password) : true,
          message: 'Password should include at least 1 letter and 1 number',
        }),
      repeatPassword: yup
        .string()
        .required('Please repeat password')
        .oneOf([yup.ref('password'), null], 'Password did not match'),
    })
  );

export const validationRequestForLogin = yupResolver(
  yup.object().shape({
    firstName: yup.string().required("Can't be empty."),
    lastName: yup.string().required("Can't be empty."),
    email: yup
      .string()
      .required("Can't be empty.")
      .email('Invalid email address.'),
    phone: yup.string().required("Can't be empty.").test({
      test: phoneValidator,
      message: 'Should be in xxx-xxx-xxxx format.',
    }),
  })
);

export const validationLogin = yupResolver(
  yup.object().shape({
    username: yup.string().required('Login is required'),
    password: yup.string().required('Password is required'),
  })
);

export const validationPin = yupResolver(
  yup.object().shape({
    ipin: yup.string().required('PIN is required'),
  })
);
