import { Link } from 'react-router-dom';

const AccessDenied = () => (
  <div className="error-page">
    <h1>403</h1>
    <div className="error-page-title">Forbidden</div>
    <div className="error-page-body">Sorry, you cannot access this page!</div>
    <Link to="/" className="btn btn-success">
      Go Home
    </Link>
  </div>
);

export default AccessDenied;
