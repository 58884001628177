import { Route, Switch } from 'react-router-dom';

import { AccessRoute } from 'components/HOC';
import * as IDS from 'constant/pagesId/messages';
import * as PATH from 'constant/path/messages';

import InboxPage from 'page/messages/inbox/InboxPage';
import InboxEmailPreviewPage from 'page/messages/inbox/EmailPreviewPage';
import InboxReplyPage from 'page/messages/inbox/InboxReplyPage';
import InboxForwardPage from 'page/messages/inbox/InboxForwardPage';
import ComposePage from 'page/messages/compose/ComposePage';
import DraftListPage from 'page/messages/draft/ListPage';
import DraftDetailsPage from 'page/messages/draft/DetailsPage';
import SentListPage from 'page/messages/sent/ListPage';
import SentEmailPreviewPage from 'page/messages/sent/EmailPreviewPage';
import SentForwardPage from 'page/messages/sent/SentForwardPage';
import TrashListPage from 'page/messages/trash/ListPage';
import TrashEmailPreviewPage from 'page/messages/trash/EmailPreviewPage';
import ReminderListPage from 'page/messages/reminder/ListPage';
import ReminderPreviewPage from 'page/messages/reminder/PreviewPage';
import ReminderAddPage from 'page/messages/reminder/AddPage';
import E404 from 'page/errors/404';

export const messagesRoutes = ['/messages', '/reminders'];

const Messages = () => (
  <Switch>
    <AccessRoute
      exact
      pageId={IDS.INBOX.PAGE}
      path={PATH.URL_INBOX}
      component={InboxPage}
    />
    <AccessRoute
      exact
      pageId={IDS.INBOX.PAGE}
      path={PATH.URL_INBOX_MESSAGE_DETAIL}
      component={InboxEmailPreviewPage}
    />
    <AccessRoute
      exact
      pageId={IDS.INBOX.PAGE}
      path={PATH.URL_INBOX_REPLY}
      component={InboxReplyPage}
    />
    <AccessRoute
      exact
      pageId={IDS.INBOX.PAGE}
      path={PATH.URL_INBOX_FORWARD}
      component={InboxForwardPage}
    />

    <AccessRoute
      exact
      pageId={IDS.COMPOSE.PAGE}
      path={PATH.URL_COMPOSE}
      component={ComposePage}
    />

    <AccessRoute
      exact
      pageId={IDS.DRAFT.PAGE}
      path={PATH.URL_DRAFT}
      component={DraftListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.DRAFT.PAGE}
      path={PATH.URL_DRAFT_ITEM}
      component={DraftDetailsPage}
    />

    <AccessRoute
      exact
      pageId={IDS.SENT.PAGE}
      path={PATH.URL_SENT_MAIL}
      component={SentListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.SENT.PAGE}
      path={PATH.URL_SENT_MESSAGE_DETAIL}
      component={SentEmailPreviewPage}
    />
    <AccessRoute
      exact
      pageId={IDS.SENT.PAGE}
      path={PATH.URL_SENT_FORWARD}
      component={SentForwardPage}
    />

    <AccessRoute
      exact
      pageId={IDS.TRASH.PAGE}
      path={PATH.URL_TRASH}
      component={TrashListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.TRASH.PAGE}
      path={PATH.URL_TRASH_MESSAGE_DETAIL}
      component={TrashEmailPreviewPage}
    />

    <AccessRoute
      exact
      pageId={IDS.REMINDER.PAGE}
      path={PATH.URL_REMINDER}
      component={ReminderListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.REMINDER.PAGE}
      path={PATH.URL_REMINDER_ADD}
      component={ReminderAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.REMINDER.PAGE}
      path={PATH.URL_REMINDER_MESSAGE_DETAIL}
      component={ReminderPreviewPage}
    />

    <Route path="*" component={E404} />
  </Switch>
);

export default Messages;
