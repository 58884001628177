import { action, makeObservable, observable } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest, errorPrettier, ErrorType } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { GRID_ID_INTEGRATION_MASTER } from 'constant/gridsId/systemSetup';

const getChannelsToUpdate = (
  initialChannels: ChannelType[],
  finalChannels: ChannelType[]
) =>
  finalChannels
    .filter(({ channel: { channelID }, id }) => {
      if (!channelID) return false;

      return initialChannels.every(
        ({ channel, id: initId }) =>
          channel.channelID !== channelID || initId !== id
      );
    })
    .map(({ channel }) => channel.channelID);

const getSubscriptionsToUpdate = (subscriptions: SubscriptionType[]) =>
  subscriptions
    .filter(({ subscriptionId }) => !subscriptionId)
    .map(({ value }) => value);

const getChannelsForDelete = (
  initialChannels: ChannelType[],
  finalChannels: ChannelType[]
) =>
  initialChannels
    .filter(({ channel: { channelID }, id }) => {
      if (id === 0) return false;
      if (!channelID && id) return true;

      return finalChannels.every(
        ({ channel, id: finalId }) =>
          channel.channelID !== channelID || finalId !== id
      );
    })
    .map(({ id }) => id);

const getSubscriptionsForDelete = (
  initialSubscriptions: SubscriptionType[],
  finalSubscriptions: SubscriptionType[]
) =>
  initialSubscriptions
    .filter(({ value }) =>
      finalSubscriptions.every((subscription) => subscription.value !== value)
    )
    .map(({ subscriptionId }) => subscriptionId);

export const defaultChannelValue: ChannelType = {
  id: 0,
  channel: {
    channelID: '',
  },
};

export const defaultFilterValues: FilterModel = {
  name: '',
};

export const initialIntegrationDetails: IntegrationDetailsType = {
  id: 0,
  name: '',
  externalApplicationName: '',
  shortName: '',
  timezone: Intl.DateTimeFormat('en-US').resolvedOptions().timeZone,
  sendResultsAsPDF: false,
  orderType: 0,
  ormSettings: {
    sendOnOrderCanceled: false,
    sendOnVisitNotesCompleted: false,
    sendOnPhysicianAssignment: false,
    sendOnTechnicianAssignment: false,
  },
  oruSettings: {
    sendOnResultsInbound: false,
    sendOnTranscriptionAdded: false,
    associatedRadiologyGroup: 0,
  },
  adtSettings: {
    sendOnPatientCreated: false,
    sendOnPatientUpdated: false,
  },
  ormChannels: [defaultChannelValue],
  oruChannels: [defaultChannelValue],
  adtChannels: [defaultChannelValue],
  facilitySubscriptions: [],
  radiologyGroupSubscriptions: [],
  physicianGroupSubscriptions: [],
};

export interface FilterModel {
  name: string;
}

export interface IntegrationType {
  id: number;
  name: string;
  shortName: string;
  externalApplicationName: string;
  timezone: string;
  sendResultsAsPDF: boolean;
  createdAt: string;
}

type SubscriptionInstanceType =
  | 'facilities'
  | 'radiology_groups'
  | 'radiology-groups'
  | 'group_managers'
  | 'physician-groups';

type ChannelType = {
  id: number;
  channel: {
    channelID: string;
  };
};

type SubscriptionType = {
  value: number;
  label: string;
  subscriptionId: number;
};

interface IntegrationDetailsResponseType
  extends Omit<IntegrationType, 'createdAt'> {
  orderType: OrderType;
  ormSettings: {
    sendOnOrderCanceled: boolean;
    sendOnVisitNotesCompleted: boolean;
    sendOnPhysicianAssignment: boolean;
    sendOnTechnicianAssignment: boolean;
  };
  oruSettings: {
    sendOnResultsInbound: boolean;
    sendOnTranscriptionAdded: boolean;
    associatedRadiologyGroup: AssociatedRadiologyGroup;
  };
  adtSettings: {
    sendOnPatientCreated: boolean;
    sendOnPatientUpdated: boolean;
  };
  ormChannels: ChannelType[];
  oruChannels: ChannelType[];
  adtChannels: ChannelType[];
  facilitySubscriptions: {
    id: number;
    facility: {
      id: number;
      facilityName: string;
    };
  }[];
  radiologyGroupSubscriptions: {
    id: number;
    radiologyGroup: {
      id: number;
      radiologyGroupName: string;
      radiologyGroupDesc: string;
    };
  }[];
  physicianGroupSubscriptions: {
    id: number;
    physicianGroup: {
      id: number;
      groupName: string;
      groupDescription: string;
    };
  }[];
}

export interface IntegrationDetailsType
  extends Omit<
    IntegrationDetailsResponseType,
    | 'physicianGroupSubscriptions'
    | 'radiologyGroupSubscriptions'
    | 'facilitySubscriptions'
    | 'orderType'
    | 'oruSettings'
  > {
  orderType: number;
  oruSettings: ORUSettings;
  facilitySubscriptions: SubscriptionType[];
  radiologyGroupSubscriptions: SubscriptionType[];
  physicianGroupSubscriptions: SubscriptionType[];
}

type ORUSettings = {
  sendOnResultsInbound: boolean;
  sendOnTranscriptionAdded: boolean;
  associatedRadiologyGroup: number;
};

type AssociatedRadiologyGroup = {
  id: string;
  radiologyGroupName: string;
  radiologyGroupDesc: string;
};

type OrderType = {
  id: string;
  orderTypeName: string;
  orderType: string;
};

type UpdateIntegrationORUSettings = {
  sendOnResultsInbound: boolean;
  sendOnTranscriptionAdded: boolean;
  associatedRadiologyGroup: string;
};

type CreateIntegrationORUSettings = {
  sendOnResultsInbound: boolean;
  sendOnTranscriptionAdded: boolean;
  associatedRadiologyGroupId: string;
};

type ORMSettings = {
  sendOnOrderCanceled: boolean;
  sendOnVisitNotesCompleted: boolean;
  sendOnPhysicianAssignment: boolean;
  sendOnTechnicianAssignment: boolean;
};

type ADTSettings = {
  sendOnPatientCreated: boolean;
  sendOnPatientUpdated: boolean;
};

export type CreateIntegrationPayload = {
  name: string;
  shortName: string;
  externalApplicationName: string;
  timezone: string;
  sendResultsAsPDF: boolean;
  orderTypeId: string;
  ormSettings: ORMSettings;
  oruSettings: CreateIntegrationORUSettings;
  adtSettings: ADTSettings;
  ormChannels: string[];
  oruChannels: string[];
  adtChannels: string[];
  facilitySubscriptions: string[];
  radiologyGroupSubscriptions: string[];
  physicianGroupSubscriptions: string[];
};

export type UpdateIntegrationPayload = {
  name: string;
  shortName: string;
  externalApplicationName: string;
  timezone: string;
  sendResultsAsPDF: boolean;
  orderType: string;
  ormSettings: ORMSettings;
  oruSettings: UpdateIntegrationORUSettings;
  adtSettings: ADTSettings;
};

class Integrations {
  fetching: boolean = false;
  integrations: IntegrationType[] = [];
  integrationsTotal: number = 0;
  integrationDetails: IntegrationDetailsType = initialIntegrationDetails;
  filter: FilterModel = defaultFilterValues;
  idForDelete: number = 0;

  page: Pagination = new Pagination({ id: GRID_ID_INTEGRATION_MASTER });
  gridId = GRID_ID_INTEGRATION_MASTER;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      integrations: observable,
      integrationsTotal: observable,
      integrationDetails: observable,
      idForDelete: observable,

      setFetching: action,
      setIntegrations: action,
      setIntegrationDetails: action,
      setFilter: action,
      setIdForDelete: action,
      clearIdForDelete: action.bound,
      clearIntegrationDetails: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setIntegrations({
    items,
    totalItems,
  }: {
    items: IntegrationType[];
    totalItems: number;
  }) {
    this.integrations = items;
    this.integrationsTotal = totalItems;
  }

  setIntegrationDetails(details: IntegrationDetailsType) {
    this.integrationDetails = details;
  }

  setFilter(filter: FilterModel) {
    this.filter = filter;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  clearIntegrationDetails() {
    this.integrationDetails = initialIntegrationDetails;
  }

  async getIntegrations() {
    const {
      filter,
      page: { pagination },
    } = this;

    this.setFetching(true);

    const data = {
      ...filter,
      page: pagination.page,
      itemsPerPage: pagination.pageSize,
    };

    try {
      const response = await apiRequest<{
        items: IntegrationType[];
        totalItems: number;
      }>({
        url: 'hl7/integrations',
        method: 'GET',
        legacy: false,
        contentType: 'ld',
        data,
      });
      this.setIntegrations(response);
    } catch (error: unknown) {
      this.setIntegrations({ items: [], totalItems: 0 });
    } finally {
      this.setFetching(false);
    }
  }

  async getIntegrationDetails(id: number) {
    this.setFetching(true);

    try {
      const {
        orderType,
        facilitySubscriptions,
        radiologyGroupSubscriptions,
        physicianGroupSubscriptions,
        oruSettings,
        ...response
      } = await apiRequest<IntegrationDetailsResponseType>({
        url: `hl7/integrations/${id}`,
        method: 'GET',
        legacy: false,
      });

      const integration = {
        ...response,
        externalName: '',
        orderType: Number(orderType.id),
        ormChannels: response.ormChannels.length
          ? response.ormChannels
          : [defaultChannelValue],
        oruChannels: response.oruChannels.length
          ? response.oruChannels
          : [defaultChannelValue],
        oruSettings: {
          ...oruSettings,
          associatedRadiologyGroup: Number(
            oruSettings.associatedRadiologyGroup?.id
          ),
        },
        facilitySubscriptions: facilitySubscriptions.map(
          ({ id, facility }) => ({
            label: facility.facilityName,
            value: facility.id,
            subscriptionId: id,
          })
        ),
        radiologyGroupSubscriptions: radiologyGroupSubscriptions.map(
          ({ id, radiologyGroup }) => ({
            label: radiologyGroup.radiologyGroupName,
            value: radiologyGroup.id,
            subscriptionId: id,
          })
        ),
        physicianGroupSubscriptions: physicianGroupSubscriptions.map(
          ({ id, physicianGroup }) => ({
            label: physicianGroup.groupName,
            value: physicianGroup.id,
            subscriptionId: id,
          })
        ),
      };
      this.setIntegrationDetails(integration);
    } catch (e: any) {
      Notification.danger("Requested Integration doesn't exist!");
      this.setIntegrationDetails(null);
    } finally {
      this.setFetching(false);
    }
  }

  async createIntegration(data: IntegrationDetailsType) {
    let payload: CreateIntegrationPayload = {
      name: data.name,
      shortName: data.shortName,
      externalApplicationName: data.externalApplicationName,
      timezone: data.timezone,
      sendResultsAsPDF: data.sendResultsAsPDF,
      orderTypeId: String(data.orderType),
      ormSettings: data.ormSettings,
      oruSettings: {
        ...data.oruSettings,
        associatedRadiologyGroupId: String(
          data.oruSettings.associatedRadiologyGroup
        ),
      },
      adtSettings: data.adtSettings,
      ormChannels: data.ormChannels.map(({ channel }) => channel.channelID),
      oruChannels: data.oruChannels.map(({ channel }) => channel.channelID),
      adtChannels: data.adtChannels.map(({ channel }) => channel.channelID),
      facilitySubscriptions: data.facilitySubscriptions.map(({ value }) =>
        String(value)
      ),
      radiologyGroupSubscriptions: data.radiologyGroupSubscriptions.map(
        ({ value }) => String(value)
      ),
      physicianGroupSubscriptions: data.physicianGroupSubscriptions.map(
        ({ value }) => String(value)
      ),
    };
    try {
      await apiRequest({
        url: 'hl7/integrations',
        method: 'POST',
        legacy: false,
        data: payload,
      });
      Notification.success(`Integration was created successfully!`);
    } catch (e: unknown) {
      const errors = errorPrettier(e as ErrorType<IntegrationDetailsType>);
      if (!errors) Notification.danger('An error occurred!');
      return errors || [];
    }
  }

  async updateIntegration(settings: IntegrationDetailsType) {
    const { integrationDetails } = this;
    const integrationId = settings.id;
    const ormChannelsToDelete = getChannelsForDelete(
      integrationDetails.ormChannels,
      settings.ormChannels
    );
    const oruChannelsToDelete = getChannelsForDelete(
      integrationDetails.oruChannels,
      settings.oruChannels
    );
    const adtChannelsToDelete = getChannelsForDelete(
      integrationDetails.adtChannels,
      settings.adtChannels
    );
    const ormChannelsToUpdate = getChannelsToUpdate(
      integrationDetails.ormChannels,
      settings.ormChannels
    );
    const oruChannelsToUpdate = getChannelsToUpdate(
      integrationDetails.oruChannels,
      settings.oruChannels
    );
    const adtChannelsToUpdate = getChannelsToUpdate(
      integrationDetails.adtChannels,
      settings.adtChannels
    );
    const facilitySubscriptionToUpdate = getSubscriptionsToUpdate(
      settings.facilitySubscriptions
    );
    const radiologyGroupSubscriptionToUpdate = getSubscriptionsToUpdate(
      settings.radiologyGroupSubscriptions
    );
    const physicianGroupSubscriptionToUpdate = getSubscriptionsToUpdate(
      settings.physicianGroupSubscriptions
    );
    const facilitySubscriptionToDelete = getSubscriptionsForDelete(
      integrationDetails.facilitySubscriptions,
      settings.facilitySubscriptions
    );
    const radiologyGroupSubscriptionToDelete = getSubscriptionsForDelete(
      integrationDetails.radiologyGroupSubscriptions,
      settings.radiologyGroupSubscriptions
    );
    const physicianGroupSubscriptionToDelete = getSubscriptionsForDelete(
      integrationDetails.physicianGroupSubscriptions,
      settings.physicianGroupSubscriptions
    );

    try {
      let payload: UpdateIntegrationPayload = {
        name: settings.name,
        shortName: settings.shortName,
        externalApplicationName: settings.externalApplicationName,
        timezone: settings.timezone,
        sendResultsAsPDF: settings.sendResultsAsPDF,
        orderType: `/order_types/${settings.orderType}`,
        oruSettings: {
          ...settings.oruSettings,
          associatedRadiologyGroup: integrationDetails.oruSettings
            .associatedRadiologyGroup
            ? `/radiology_groups/${integrationDetails.oruSettings.associatedRadiologyGroup}`
            : null,
        },
        ormSettings: settings.ormSettings,
        adtSettings: settings.adtSettings,
      };
      const settingsResponse = apiRequest({
        url: `hl7/integrations/${settings.id}`,
        method: 'PATCH',
        legacy: false,
        data: payload,
      });
      const ormChannelResponse = this.addChannel({
        integrationId,
        instanceIds: ormChannelsToUpdate,
        channelType: 'orm',
      });
      const oruChannelResponse = this.addChannel({
        integrationId,
        instanceIds: oruChannelsToUpdate,
        channelType: 'oru',
      });
      const adtChannelResponse = this.addChannel({
        integrationId,
        instanceIds: adtChannelsToUpdate,
        channelType: 'adt',
      });
      const facilitySubscriptionsResponse = this.addSubscriptions({
        integrationId,
        instanceIds: facilitySubscriptionToUpdate,
        instanceType: 'facilities',
      });
      const radiologyGroupSubscriptionsResponse = this.addSubscriptions({
        integrationId,
        instanceIds: radiologyGroupSubscriptionToUpdate,
        instanceType: 'radiology-groups',
      });
      const physicianGroupSubscriptionsResponse = this.addSubscriptions({
        integrationId,
        instanceIds: physicianGroupSubscriptionToUpdate,
        instanceType: 'physician-groups',
      });
      await Promise.all([
        settingsResponse,
        ormChannelResponse,
        oruChannelResponse,
        adtChannelResponse,
        facilitySubscriptionsResponse,
        radiologyGroupSubscriptionsResponse,
        physicianGroupSubscriptionsResponse,
      ]);
      const deleteOrmChannelResponse = this.deleteChannel({
        integrationId,
        instanceIds: ormChannelsToDelete,
        channelType: 'orm',
      });
      const deleteOruChannelResponse = this.deleteChannel({
        integrationId,
        instanceIds: oruChannelsToDelete,
        channelType: 'oru',
      });
      const deleteAdtChannelResponse = this.deleteChannel({
        integrationId,
        instanceIds: adtChannelsToDelete,
        channelType: 'adt',
      });
      const deleteFacilitySubscriptionsResponse = this.deleteSubscriptions({
        integrationId,
        instanceIds: facilitySubscriptionToDelete,
        instanceType: 'facilities',
      });
      const deleteRadiologyGroupSubscriptionsResponse =
        this.deleteSubscriptions({
          integrationId,
          instanceIds: radiologyGroupSubscriptionToDelete,
          instanceType: 'radiology-groups',
        });
      const deletePhysicianGroupSubscriptionsResponse =
        this.deleteSubscriptions({
          integrationId,
          instanceIds: physicianGroupSubscriptionToDelete,
          instanceType: 'physician-groups',
        });
      await Promise.all([
        deleteOrmChannelResponse,
        deleteOruChannelResponse,
        deleteAdtChannelResponse,
        deleteFacilitySubscriptionsResponse,
        deleteRadiologyGroupSubscriptionsResponse,
        deletePhysicianGroupSubscriptionsResponse,
      ]);
      Notification.success(`Integration was updated successfully!`);
      return null;
    } catch (e: unknown) {
      const errors = errorPrettier(e as ErrorType<IntegrationDetailsType>);
      if (!errors) Notification.danger('An error occurred!');
      return errors || [];
    }
  }

  async deleteChannel({
    channelType,
    ...data
  }: {
    integrationId: number;
    instanceIds: number[];
    channelType: 'orm' | 'oru' | 'dft' | 'adt';
  }) {
    if (!data.instanceIds?.length) return Promise.resolve(null);

    return apiRequest({
      url: `hl7/integrations/channels/${channelType}/batch/delete`,
      method: 'POST',
      legacy: false,
      data,
    });
  }

  async deleteSubscriptions({
    instanceType,
    ...data
  }: {
    integrationId: number;
    instanceIds: number[];
    instanceType: SubscriptionInstanceType;
  }) {
    if (!data.instanceIds?.length) return Promise.resolve(null);

    return apiRequest({
      url: `hl7/integrations/subscriptions/${instanceType}/batch/delete`,
      method: 'POST',
      legacy: false,
      data,
    });
  }

  async addChannel({
    channelType,
    ...data
  }: {
    integrationId: number;
    instanceIds: string[];
    channelType: 'orm' | 'oru' | 'dft' | 'adt';
  }) {
    if (!data.instanceIds.length) return Promise.resolve(null);

    return apiRequest({
      url: `hl7/integrations/channels/${channelType}/batch`,
      method: 'POST',
      legacy: false,
      data,
    });
  }

  async addSubscriptions({
    instanceType,
    ...data
  }: {
    integrationId: number;
    instanceIds: number[];
    instanceType: SubscriptionInstanceType;
  }) {
    if (!data.instanceIds.length) return Promise.resolve(null);

    return apiRequest({
      url: `hl7/integrations/subscriptions/${instanceType}/batch`,
      method: 'POST',
      legacy: false,
      data,
    });
  }

  async deleteIntegration() {
    const { idForDelete } = this;

    this.setFetching(true);
    try {
      await apiRequest({
        url: `hl7/integrations/${idForDelete}`,
        method: 'DELETE',
        legacy: false,
      });

      Notification.success('Integration was deleted!');

      this.clearIdForDelete();
      return true;
    } catch (e: any) {
      Notification.danger(
        'Unable to delete Integration. Integration already in use.'
      );
      this.setFetching(false);
      return false;
    }
  }
}

export const storeIntegrations = new Integrations();
