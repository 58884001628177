import React from 'react';

import Custom from 'components/form/input/Custom';
import Fieldset, { PFieldset, SFieldset } from 'components/form/Fieldset';

export interface PPhysicianView extends PFieldset {}

export class SPhysicianView extends SFieldset {}

export default class PhysicianView extends Fieldset<
  PPhysicianView,
  SPhysicianView
> {
  renderChildren() {
    const className = 'col-sm-6';
    const formModel = this.props.formModel || {};
    return [
      <Custom
        name="firstName"
        custom={formModel.firstName || <span>&nbsp;</span>}
        className={className}
      />,
      <Custom
        name="validityDate"
        custom={formModel.validityDate || <span>&nbsp;</span>}
        className={className}
      />,
      <Custom
        name="lastName"
        custom={formModel.lastName || <span>&nbsp;</span>}
        className={className}
      />,
    ].map((child: React.ReactNode, key: number) => {
      return this._cloneChild(child, '' + key);
    });
  }
}
