import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';
import JurisdictionZipCodesGrid from 'page/system-setup/master-setup/jurisdiction/components/JurisdictionZipCodesGrid';

interface PropsType {
  id: number;
  onClose: () => void;
}

const DialogJurisdictionZipCodes = ({ id, onClose }: PropsType) => (
  <Dialog size="large" handleClose={onClose}>
    <DialogHeader title="Zip Codes" onClose={onClose} />
    <DialogBody>
      <JurisdictionZipCodesGrid id={id} />
    </DialogBody>
  </Dialog>
);

export default DialogJurisdictionZipCodes;
