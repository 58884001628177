import { apiRequest } from 'services/RequestService';
import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';

interface InitCptBasicType {
  cpt_code: string;
  cpt_code_val: string;
  cpt_desc: string;
  cpt_price: number;
  cptcount: number;
  modifier_code: string;
  refid: string;
  single_price: string;
}

interface InitCptExtendedType extends InitCptBasicType {
  cpt_id: string;
  globalprice: string;
  technical_price: string;
  accession_number: string;
  external_accession_number: string;
  external_placer_order_number: string;
  defaultCpt: string;
}

export type InitCptType = (InitCptBasicType | InitCptExtendedType)[];

export class TFaxReport {
  refid: number = null;
  file_name: string = '';
  file_desc: string = '';
  file_path: string = '';
  critical: string = '';
  fileselect: boolean = false;
  image: string = '';
}

export class TFaxNumber {
  refid: number = null;
  fax_no: string = '';
  receiver_name: string = '';
  phone: string = '';
  floor_name: string = '';
}

export class TReports {
  fax_report: Array<TFaxReport> = [];
  fax_faxnumber: Array<TFaxNumber> = [];
  fax_faxnumlabel: string = '';
}

export interface TCoddingValues
  extends Omit<TSignOrderItem, 'GetIntCptArr' | 'GetFnlCptArr'> {
  GetFnlCptArr: InitCptType;
  GetIntCptArr: InitCptType;
  completion_time: string;
  patient_name: string;
  modality: string;
  isfinding: string;
  Reports: TReports;
  dateofbirth: string;
  npi: string;
  tc_billprocess: string;
  noofpatient: string;
  assign_status: string;
  assign_by: string;
  GetIntIcdArr: Array<any>;
  flag: string;
  daily_encounter: number | null;
  same_visits: boolean;
}

class CodingService {
  loadCodingValues(orderId: number) {
    return apiRequest<TCoddingValues>({
      url: 'order.Order.getCodingValues',
      data: { orderId },
    });
  }

  updateNpi(providerId: any, npi: any) {
    return apiRequest({
      url: 'facility.FacilityBilling.Update_NPI',
      data: [providerId, npi],
    });
  }

  loadFindingCategory(category: string): Promise<Array<TFindingCategory>> {
    return apiRequest({
      url: 'generalmaster.Finding.GetFindingDropDownByCategory',
      data: [category],
    });
  }

  loadFindings(
    orderId: number,
    cpt: Array<TCptId>
  ): Promise<Array<TFindingCpt>> {
    return apiRequest({
      url: 'generalmaster.Finding.GetFindingCPT',
      data: [orderId, cpt],
    });
  }

  saveFindingMapping(orderId: number, cpt: Array<TCptFindingMapping>) {
    return apiRequest({
      url: 'generalmaster.Finding.SaveCPTFindingMappingByOrderId',
      data: [orderId, cpt],
    });
  }
}

export default new CodingService();

export class TCptId {
  cpt_id: string = '';
  cpt_code: string = '';
  cpt_description: string = '';
  modifier_code: string = '';
}

export class TCptFindingMapping {
  cpt_id: string = '';
  category_type: string = '';
  modifier: string = '';
  category_id: string = '';
}

export class TFindingCpt {
  cpt_id: string = '';
  cpt_code: string = '';
  modifier_code: string = '';
  cpt_description: string = '';
  category_id: string = '';
  category_type: string = '';
  category_type_index: string = '';
  category: Array<TFindingCategory> = [];
  category_name_index: string = '';
}

export class TFindingCategory {
  label: string = '';
  value: number = 0;
  data: string = ''; // TODO: same as value - remove this
}
