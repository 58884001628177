import { apiRequest } from 'services/RequestService';
import appDispatcher from 'dispatcher/AppDispatcher';

export class TDropDownItem {
  label: string;
  data: string | number;
  value: string | number;
}

export default class DropdownActions {
  static loadDropdown<T extends TDropDownItem>(
    url: string,
    alphaKeys: boolean = false,
    data?: any,
    flagNoDispatcher?: boolean
  ): Promise<Array<T>> {
    // appDispatcher.dispatch({
    //   type: `${url}.fetching`,
    // });
    return apiRequest<'SE' | Array<T>>({ url, data }).then(
      (response: 'SE' | Array<T>) => {
        if (response === 'SE' || !response || (response as any) === '0') {
          response = [];
        }
        let length = response.length;
        if (!alphaKeys) {
          alphaKeys = false;
        }
        while (length--) {
          const label = response[length].label;
          if (
            (label && label.trim().toLowerCase() === '-- select --') ||
            (response[length].label === '' && response[length].data === 0)
          ) {
            response.splice(length, 1);
          } else {
            let value: string | number = response[length].data;
            const item = response[length];
            if (alphaKeys) {
              item.value = value;
            } else {
              value = value ? parseInt('' + value) : 0;
              item.value = isNaN(value) ? 0 : value;
            }
          }
        }
        if (!flagNoDispatcher) {
          appDispatcher.dispatch({
            type: url,
            data: response,
          });
        }
        return response;
      }
    );
  }
}
