import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

interface RawSuffixType {
  refid: string;
  suffix_desc: string;
  suffix_nm: string;
}

interface SuffixType {
  refid: number;
  description: string;
  name: string;
}

export interface OptionType {
  label: string;
  value: number;
}

class Suffix {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  suffixList: SuffixType[] = [];
  suffixTotal: number = 0;
  idForDelete: number | null = null;
  suffix: SuffixType | undefined = undefined;
  options: OptionType[] = [];
  filter: string = '';

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'suffix_grid' });

    makeObservable(this, {
      fetching: observable,
      suffixList: observable,
      suffixTotal: observable,
      idForDelete: observable,
      suffix: observable,
      filter: observable,
      fetchingOptions: observable,
      options: observable,

      setFetching: action,
      setFetchingOptions: action,
      setSuffixList: action,
      setSuffixTotal: action,
      setSuffix: action,
      setSuffixForDelete: action,
      setOptions: action,
      setFilter: action.bound,
      clearSuffixForDelete: action.bound,
      clearSuffix: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFetchingOptions = (fetching: boolean) => {
    this.fetchingOptions = fetching;
  };

  setSuffixList = (list: SuffixType[]) => {
    this.suffixList = list;
  };

  setSuffixTotal = (count: number) => {
    this.suffixTotal = count;
  };

  setSuffix = (suffix: SuffixType) => {
    this.suffix = suffix;
  };

  setSuffixForDelete = (id: number) => {
    this.idForDelete = id;
  };

  setOptions = (options: OptionType[]) => {
    this.options = options;
  };

  setFilter(name: string) {
    this.filter = name;
  }

  clearSuffixForDelete() {
    this.idForDelete = null;
  }

  clearSuffix() {
    this.suffix = undefined;
  }

  getSuffixCount = async () => {
    try {
      const count = await apiRequest<'SE' | string>({
        url: 'generalmaster.Suffix.TotalRecordCount',
        data: [this.filter],
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }

      this.setSuffixTotal(total);
    } catch (e) {
      this.setSuffixTotal(0);
    }
  };

  getSuffixes = async () => {
    const {
      filter: suffixName,
      page: { pagination },
    } = this;

    try {
      const response = await apiRequest<RawSuffixType[] | null>({
        url: 'generalmaster.Suffix.SuffixList',
        data: [pagination.skip, pagination.pageSize, suffixName],
      });

      const list = response
        ? response.map((suffix) => ({
            refid: Number(suffix.refid),
            name: suffix.suffix_nm,
            description: suffix.suffix_desc,
          }))
        : [];

      this.setSuffixList(list);
    } catch (e) {
      this.setSuffixList([]);
    }
  };

  getSuffixList = () => {
    this.setFetching(true);

    const promiseList = this.getSuffixes();

    const promiseCount = this.getSuffixCount();

    Promise.allSettled([promiseList, promiseCount]).finally(() => {
      this.setFetching(false);
    });
  };

  getSuffix = async (id: number | string) => {
    this.setFetching(true);
    try {
      const [response] = await apiRequest<[RawSuffixType]>({
        url: 'generalmaster.Suffix.ViewSuffix',
        data: [id],
      });

      const suffix = {
        refid: Number(response.refid),
        name: response.suffix_nm,
        description: response.suffix_desc,
      };

      this.setSuffix(suffix);

      return suffix;
    } catch (e) {
      this.setSuffix(undefined);
      return null;
    } finally {
      this.setFetching(false);
    }
  };

  addSuffix = async (payload: Omit<SuffixType, 'refid'>) => {
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.Suffix.AddSuffix',
        data: [payload.name, payload.description],
      });
      if (result === 'S') {
        Notification.success('Suffix was added successfully!');
        return true;
      } else if (result === 'E') {
        return { message: 'Suffix with this name already exist!' };
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  };

  updateSuffix = async (payload: SuffixType) => {
    const data = [payload.refid, payload.name, payload.description];

    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.Suffix.EditSuffix',
        data,
      });
      if (result === 'S') {
        Notification.success('Suffix was updated successfully!');
        return true;
      } else if (result === 'E') {
        return { message: 'Suffix with this name already exist!' };
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  };

  deleteSuffix = async () => {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.Suffix.DeleteSuffix',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Suffix was deleted!');
        this.clearSuffixForDelete();
        return true;
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  };

  async getOptions() {
    if (this.fetchingOptions) Promise.resolve([]);

    this.setFetchingOptions(true);
    try {
      const options = await apiRequest<OptionType[]>({
        url: 'generalmaster.Suffix.LoadSuffix',
      });

      this.setOptions(options);
      return options;
    } catch (e) {
      this.setOptions([]);
      return [];
    } finally {
      this.setFetchingOptions(false);
    }
  }
}

export const storeSuffix = new Suffix();
