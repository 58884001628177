import Title from 'components/project/common/title';
import EmailDashboard from './EmailDashboard';

const IntegrationsDashboard = () => (
  <>
    <Title title="Integrations" />
    <div className="integrations-dashboard">
      <EmailDashboard />
    </div>
  </>
);

export default IntegrationsDashboard;
