import { TPagination } from 'components/grid/GridTypes';

import { apiRequest } from 'services/RequestService';
import { getDateRangeBounds } from 'utils/DateUtils';

export class TFacilityInvoiceFilter {
  facility: number[] = [];
  corporate: number = 0;
  state: number[] = [];
  location: number[] = [];
  pos: number = 0;
  orderType: string = '';
  dosStart: string = '';
  dosEnd: string = '';
  lastName: string = '';
  firstName: string = '';
  providerType: number[] = [];
  division: number = 0;
}

export class TFacilityInvoice {
  orderid_grp: string = '';
  hasLog: boolean = false;
  facilitynm: string = '';
  facilityid: number = null;
  perdiem: string = '';
  facility_billemail: string = '';
  facility_zipcode: string = '';
  facility_billingcontact: string = '';
  bill_phone: string = '';
  facility_billfax: string = '';
  facility_add1: string = '';
  facility_add2: string = '';
  facility_city: string = '';
  facility_state: string = '';
  fax_time: string = '';
  facility_type: string = '';
  assigned_count: number = null;
  unassigned_count: number = null;
  preinvoice_no: string = '';
  preinvoice_alpha: string = '';
}

export class TSaveEmail {
  facilitynm: string = '';
  facilityid: number = null;
  bill_email: string = '';
}

export class TOrderPreinvoiceMultipleInsertUpdate {
  facilityid: number = null;
  dueData: string = '';
}

export class TPreinvoiceMultipleInsertUpdate {
  invoice: string = '';
  facility: number = null;
  label: string = '';
}

export class TInvoiceItem {
  studyId: number = null;
  cpt_price_val: number = null;
  selstatus: string = '';
  flag: string = '';
  discount: number = null;
}

export class TDueData {
  dateFrom: string = '';
  dateTo: string = '';
  lateFee: number = null;
  lateFeePaid: number = null;
  facility_id: number = null;
}

class PreInvoicesService {
  loadOverview(
    filter: TFacilityInvoiceFilter,
    pagination: TPagination,
    invoiceType: 'F' | 'D' | 'H',
    signal: AbortSignal
  ) {
    const data = [
      filter.facility,
      filter.corporate,
      filter.state,
      filter.location,
      filter.pos,
      filter.orderType,
      '',
      filter.dosStart,
      filter.dosEnd,
      '',
      filter.lastName,
      filter.firstName,
      invoiceType,
      pagination.skip,
      pagination.pageSize,
      filter.providerType,
      filter.division,
    ];

    return apiRequest<'SE' | 0 | Array<TFacilityInvoice>>({
      url: 'facility.FacilityBilling.FacilityInvoiceList',
      data,
      signal,
    });
  }

  loadOverviewCount(
    filter: TFacilityInvoiceFilter,
    invoiceType: 'F' | 'D' | 'H',
    signal: AbortSignal
  ) {
    const data = [
      filter.facility,
      filter.corporate,
      filter.state,
      filter.location,
      filter.pos,
      filter.orderType,
      '',
      filter.dosStart,
      filter.dosEnd,
      '',
      filter.lastName,
      filter.firstName,
      invoiceType,
      filter.providerType,
      filter.division,
    ];

    return apiRequest<'SE' | number>({
      url: 'facility.FacilityBilling.FacilityListCount',
      data,
      signal,
    });
  }

  email(email: string, orders: Array<TSaveEmail>) {
    return apiRequest<void>({
      url: 'facility.Billingassignment.SaveEmail',
      data: [email, 'PRE', orders],
    });
  }

  preinvoiceMultipleInsertUpdate(
    orders: Array<TOrderPreinvoiceMultipleInsertUpdate>,
    perDiem: string,
    dosStart: string,
    dosEnd: string,
    invoiceType: 'F' | 'D' | 'H' | 'P' | '',
    lastName: string,
    firstName: string
  ): Promise<Array<TPreinvoiceMultipleInsertUpdate>> {
    const dos = getDateRangeBounds({
      from: dosStart,
      to: dosEnd,
    });

    const data = [
      orders,
      perDiem,
      dos.dateFrom,
      dos.dateTo,
      invoiceType,
      firstName,
      lastName,
    ];

    return apiRequest<'SE' | Array<TPreinvoiceMultipleInsertUpdate>>({
      url: 'facility.FacilityBilling.PreinvoiceMultipleInsertUpdate',
      data,
    }).then((response: 'SE' | Array<TPreinvoiceMultipleInsertUpdate>) => {
      return response === 'SE' ? [] : response;
    });
  }

  insertUpdate(
    invoiceNo: number,
    invoiceArr: Array<TInvoiceItem>,
    facilityId: number,
    perDiem: 'Y' | 'N',
    invoiceType: 'F' | 'D' | 'H' | 'P' | '',
    pdUnits: string,
    mode: 'A' | 'E',
    perdiemName: string,
    perdiemPrice: string,
    due: TDueData
  ) {
    const data = {
      invoiceNo,
      invoiceArr,
      facilityId,
      perDiem,
      invoiceType,
      pdUnits,
      mode,
      perdiemName,
      perdiemPrice,
      due,
    };

    return apiRequest<TPreinvoiceMultipleInsertUpdate>({
      url: 'facility.FacilityBilling.Preinvoice_insertupdate',
      data,
    });
  }
}

export default new PreInvoicesService();
