import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';
import Title from 'components/project/common/title';

import {
  storeMyProfile,
  ProfileDetailsType,
} from 'stores/_mobx/profile/myProfile';
import UserProfileStore from 'stores/UserProfileStore';
import { MY_PROFILE as PAGE_ID } from 'constant/pagesId/personalProfile';

const MyProfilePage = () => {
  const { fetching, details, emailTemplatesOption } = storeMyProfile;

  const fetchingOptions = useMemo(() => {
    const refid = UserProfileStore.getUserId();
    const userId = UserProfileStore.getTypedUserId();
    const userTypeId = UserProfileStore.getUserTypeId();

    return { refid, userId, userTypeId };
  }, []);

  const handleSubmit = (data: ProfileDetailsType) =>
    storeMyProfile.updateProfile(data).then((isSucceed) => {
      if (isSucceed) storeMyProfile.getUserProfile(fetchingOptions);
    });

  useEffect(() => {
    storeMyProfile.getUserProfile(fetchingOptions);

    storeMyProfile.getEmailTemplates(fetchingOptions);

    return storeMyProfile.clearStore;
  }, [fetchingOptions]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="My Profile" />

      <Form
        fetching={fetching}
        onSubmit={handleSubmit}
        emailTemplatesOption={emailTemplatesOption}
        defaultValues={details}
      />

      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(MyProfilePage);
