import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { GenerateStatus, RecordType } from 'stores/_mobx/exporter';

interface PropsType {
  record: RecordType;
  onDownload: (record: RecordType) => void;
  onDelete: (id: number) => void;
}

const DownloadItem = ({ record, onDownload, onDelete }: PropsType) => {
  const handleClickDownload = () => {
    if (record.generateStatus === GenerateStatus.success) onDownload(record);
  };

  const handleClickDelete = () => {
    onDelete(record.id);
  };

  const iconClassName = clsx('downloads-icon', {
    fill: Boolean(record.requested),
  });

  const dropDownItemClassName = clsx('dropdown-item', {
    failed: record.generateStatus === GenerateStatus.failed
  });

  return (
    <li className="nav-item">
      <p className={dropDownItemClassName}>
        <span className="downloads-record">
          {record.generateStatus !== GenerateStatus.loading ? (
            <span
              title="Delete"
              className="downloads-icon-delete show-on-hover"
              onClick={handleClickDelete}>
              <i className="bi bi-x-circle-fill" />
            </span>
          ) : null}
          <span className="downloads-icon-format">
            <i className={`bi bi-filetype-${record.fileExtension}`} />
          </span>
          <span
            className="downloads-record-content"
            onClick={handleClickDownload}>
            <span>{record.source}</span>
            <small className="fw-light">{record.createdAt}</small>
          </span>
          {record.generateStatus === GenerateStatus.success ? (
            <span
              title={record.requested ? 'Re-download' : 'Download'}
              className={iconClassName}
              onClick={handleClickDownload}>
              <i className="bi bi-download" />
            </span>
          ) : record.generateStatus === GenerateStatus.failed ? (
            <span className="downloads-icon-error">
              <i className="bi bi-exclamation-circle" />
            </span>
            ) : (
            <span className="spinner-border text-primary" role="status" />
          )}
        </span>
      </p>
    </li>
  );
};

export default observer(DownloadItem);
