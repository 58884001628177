import FormEmployer from '../../components/FormEmployer';

import PatientRecordPageStateActions from 'actions/patient-records/patient-demographics/PatientRecordPageStateActions';
import { TEmployerInfo } from 'services/patient-records/patient-demographics/PatientRecordPageService';

interface PropsType {
  mode: number;
  employerInfo: TEmployerInfo;
  employerInfoEditable: boolean;
  updateEmployerInfo: (employerInfo: TEmployerInfo) => Promise<any>;
}

const EmployerInfoTab = ({
  mode,
  employerInfoEditable,
  employerInfo,
  updateEmployerInfo,
}: PropsType) => {
  const handleReset = () => {
    PatientRecordPageStateActions.restoreInitialEmployerInfoTab();
  };

  return (
    <>
      {mode === 1 ? <div className="overlay"></div> : null}
      <FormEmployer
        defaultValues={employerInfo}
        readOnly={!employerInfoEditable}
        onSubmit={updateEmployerInfo}
        onReset={handleReset}
        onSaveCache={PatientRecordPageStateActions.cacheEmployerInfoTab}
      />
    </>
  );
};

export default EmployerInfoTab;
