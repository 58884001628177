import { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Exporter from 'components/project/ExporterNew';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import Filter from './components/Filter';

import UserProfileStore from 'stores/UserProfileStore';
import { storeLedger, FilterType } from 'stores/_mobx/billingCodding/ledger';
import MathUtils from 'utils/MathUtils';
import { moneyRender } from 'utils/StringUtils';
import { LEDGER as PAGE_ID } from 'constant/pagesId/billingCodding';

const totalColumns = [
  { name: 'charge', title: 'Total Charge', render: moneyRender },
  { name: 'payment', title: 'Total Payment', render: moneyRender },
  { name: 'balance', title: 'Total Balance', render: moneyRender },
];

const getColumns = (isDivisionEnabled: boolean) => {
  const columns = [
    {
      title: 'Date',
      name: 'date',
      group: 'date',
      groupName: 'Invoice Verification',
    },
    {
      title: 'Month',
      name: 'month',
      group: 'date',
      groupName: 'Invoice Verification',
    },
    {
      title: 'Year',
      name: 'year',
      group: 'date',
      groupName: 'Invoice Verification',
    },
    {
      title: 'Invoice #',
      name: 'invoice_no',
      sorter: (
        i1: number,
        i2: number,
        direction: number,
        row1: { invoice_id: number },
        row2: { invoice_id: number }
      ) => MathUtils.compare(row1.invoice_id, row2.invoice_id, direction),
    },
    { title: 'Facility', name: 'facilitynm' },
    ...(isDivisionEnabled ? [{ title: 'Division', name: 'division' }] : []),
    { title: 'Region', name: 'region' },
    { title: 'Location', name: 'location' },
    { title: 'Phone #', name: 'phone' },
    { title: 'Transaction Details', name: 'transdetail' },
    {
      title: 'Debit|Billed',
      name: 'debit_billed',
      render: moneyRender,
      export: 'render',
    },
    {
      title: 'Credit|Paid',
      name: 'paymentamount',
      render: moneyRender,
      export: 'render',
    },
    {
      title: 'Write Off',
      name: 'write_off',
      render: moneyRender,
      export: 'render',
    },
    { title: 'Age', name: 'age' },
    {
      title: 'Balance',
      name: 'bal',
      render: (balance: any, data: any) =>
        data.actual ? (
          moneyRender(balance)
        ) : (
          <span className="color-gray">{moneyRender(balance)}</span>
        ),
      export: {
        target: (balance: any, data: any) =>
          data.actual ? moneyRender(balance) : '$ 0.00',
      },
    },
  ];
  return columns;
};

const LedgerPage = ({ isDivisionEnabled }: PropsTypeWitDivisionSettings) => {
  const {
    fetching,
    ledgerList,
    ledgerTotal,
    totalSum,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeLedger;

  const gridRef = useRef<Grid>(null);

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const columns = useMemo(
    () => getColumns(isDivisionEnabled),
    [isDivisionEnabled]
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleSearch = (filter: FilterType) => {
    storeLedger.setFilter(filter);
    setPaginationToStart();
  };

  useEffect(() => {
    storeLedger.getLedgerListMain(isDivisionEnabled);
  }, [isDivisionEnabled, pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        fetching={fetching}
        isFilterVisible={isFilterVisible}
        onSearch={handleSearch}
      />

      <Grid
        wrapperClass="col-md-8 col-lg-6"
        disablePagination
        noControl
        columns={totalColumns}
        dataSource={[totalSum]}
      />
      <Grid
        id="ledger_grid"
        onAjax={fetching}
        ref={gridRef}
        headerGroups
        columns={columns}
        dataSource={ledgerList}
        dataSourceCount={ledgerTotal}
        onPaginationChange={setPagination}
        pagination={pagination}
        gridControlPanel={
          <GridControlButton
            title="Export"
            disabled={!ledgerTotal}
            onClick={handleToggleExport}
          />
        }
      />
      {isExportVisible && (
        <Exporter
          exporter="LedgerExporter"
          gridRef={gridRef}
          dataSource={ledgerList}
          columns={columns}
          afterDataSource={[totalSum]}
          filter={storeLedger.filter}
          filterForRequest={storeLedger.prepareFilterForExport(
            isDivisionEnabled
          )}
          reportName={
            UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) || 'Ledger'
          }
          pdfExporter={storeLedger.generatePdf}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default withDivisionSettings(observer(LedgerPage));
