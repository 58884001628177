import { Route, Switch } from 'react-router-dom';

import { AccessRoute } from 'components/HOC';
import * as IDS from 'constant/pagesId/personalProfile';
import * as PATH from 'constant/path/personalProfile';

import ChangeLoginDetailsPage from 'page/profile/personal/ChangeLoginDetailsPage';
import LoginReportPage from 'page/profile/personal/LoginReportPage';
import BriefcasePage from 'page/profile/personal/BriefcasePage';
import TimeSheetPage from 'page/profile/personal/TimeSheetPage';
import MyProfilePage from 'page/profile/myProfile';
import E404 from 'page/errors/404';

export const personalProfileRoutes = [
  '/briefcase',
  '/personal-profile',
  '/work-records',
  '/bug',
];

const PersonalProfile = () => (
  <Switch>
    <AccessRoute
      exact
      pageId={IDS.BRIEFCASE.PAGE}
      path={PATH.URL_BRIEFCASE}
      component={BriefcasePage}
    />
    <AccessRoute
      exact
      pageId={IDS.LOGIN_REPORT.PAGE}
      path={PATH.URL_LOGIN_REPORT}
      component={LoginReportPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CHANGE_LOGIN_DETAIL.PAGE}
      path={PATH.URL_CHANGE_LOGIN_DETAILS}
      component={ChangeLoginDetailsPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MY_PROFILE.PAGE}
      path={PATH.URL_MY_PROFILE}
      component={MyProfilePage}
    />
    <AccessRoute
      exact
      pageId={IDS.TIME_SHEET.PAGE}
      path={PATH.URL_TIME_SHEET}
      component={TimeSheetPage}
    />
    <Route path="*" component={E404} />
  </Switch>
);

export default PersonalProfile;
