import { useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { Grid, GridControlButton } from 'components/grid';
import { PureInput } from 'components/form/textField';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';

import { storeDraft } from 'stores/_mobx/message/draft';
import { DRAFT as PAGE_ID } from 'constant/pagesId/messages';
import { URL_DRAFT } from 'constant/path/messages';

const columns = [
  { name: 'recipient', title: 'Recipient' },
  { name: 'subject', title: 'Subject', filterable: true },
  { name: 'date', title: 'Date' },
  { name: 'time', title: 'Time' },
  {
    name: 'id',
    title: 'Action',
    render: (id: number) => (
      <div className="control">
        <Link
          to={`${URL_DRAFT}/${id}`}
          className="bi bi-eye-fill"
          title="View"
        />
      </div>
    ),
  },
];

interface PropsType extends RouteComponentProps {}

const DraftListPage = ({ history }: PropsType) => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    draftList,
    draftTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeDraft;

  const [selected, setSelected] = useState<number[]>([]);

  const [isDialogVisible, toggleDialog] = useState<boolean>(false);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleToggleDialog = () => {
    toggleDialog((state) => !state);
  };

  const handleApproveDelete = () => {
    storeDraft.moveToTrash(selected).then((isSucceed) => {
      if (isSucceed) {
        gridRef.current.clearSelection();
        storeDraft.getDraftsMain();
      }
      handleToggleDialog();
    });
  };

  const handleDoubleClick = ({ id }: { id: number }) => {
    history.push(`${URL_DRAFT}/${id}`);
  };

  const handleFilterChange = (value: string) => {
    storeDraft.setFilter(value);
    debouncedMethod();
  };

  useEffect(() => {
    storeDraft.getDraftsMain();
  }, [pagination]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Drafts" />

      <PureInput
        name="subject"
        placeholder="Subject"
        className="col-md-6 col-lg-4"
        value={filter}
        onChange={handleFilterChange}
      />

      <Grid
        id="drafts_grid"
        selectId="id"
        onAjax={fetching}
        columns={columns}
        ref={gridRef}
        dataSource={draftList}
        dataSourceCount={draftTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        onDoubleClick={handleDoubleClick}
        onSelectChange={setSelected}
        gridControlPanel={
          <GridControlButton
            title="Move to Trash"
            disabled={!selected.length}
            onClick={handleToggleDialog}
          />
        }
      />
      {isDialogVisible && (
        <DialogConfirm
          onCancel={handleToggleDialog}
          onApprove={handleApproveDelete}>
          {`Do you want to move ${selected.length} selected message${
            selected.length > 1 ? 's' : ''
          } to the Trash?`}
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(DraftListPage);
