import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';

import { storeDropdownMirthChannel } from 'stores/_mobx/dropDown/mirthChannels';
import { DFTChannel } from 'stores/_mobx/systemSetup/masterSetting/integrationsDft';

interface PropsType extends Omit<SelectPropsType, 'options'> {
  currentChannels: DFTChannel[];
  index: number;
}

interface OptionType {
  value: string;
  label: string;
}

interface OptionsFilterType {
  options: OptionType[];
  currentChannels: DFTChannel[];
  index: number;
}

const filterOption = ({
    options,
    currentChannels,
    index,
  }: OptionsFilterType) =>
  options.filter((option) => {
    const currentValue = currentChannels[index];

    if (currentValue.channel.channelID === option.value) return true;
    return currentChannels.every(({ channel: { channelID } }) => channelID !== option.value);
  });

const DropdownMirthChannel = ({ currentChannels, index, ...props }: PropsType) => {
  const { options, fetching } = storeDropdownMirthChannel;

  useEffect(() => {
    if (!options.length) storeDropdownMirthChannel.getOptions();
  }, [options.length]);

  return (
    <Select
      {...props}
      options={filterOption({
        options,
        currentChannels,
        index
      })}
      isLoading={fetching}
    />
  );
};

export default observer(DropdownMirthChannel);
