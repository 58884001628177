import { action, makeObservable, observable, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

interface OptionResponseType {
  data: string;
  label: string;
}

interface OptionType {
  label: string;
  value: number;
}

interface QuestionType {
  questnm: string;
  refid: number;
}

export interface FormModel {
  question: string;
  id: number | null;
}

class SecurityQuestion {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  idForDelete: number = 0;
  questionList: QuestionType[] = [];
  questionTotal: number = 0;
  question?: FormModel = undefined;
  options: OptionType[] = [];

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'security_question_grid' });

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      question: observable,
      questionList: observable,
      questionTotal: observable,
      idForDelete: observable,
      options: observable,

      setFetching: action,
      setQuestionList: action,
      setQuestionTotal: action,
      setQuestionForEdit: action,
      setIdForDelete: action,
      clearIdForDelete: action.bound,
      clearQuestion: action.bound,
    });
  }

  setFetching = (status: boolean) => {
    this.fetching = status;
  };

  setFetchingOptions = (status: boolean) => {
    this.fetchingOptions = status;
  };

  setQuestionList = (list: QuestionType[]) => {
    this.questionList = list;
  };

  setQuestionTotal = (total: number) => {
    this.questionTotal = total;
  };

  setQuestionForEdit = (question?: FormModel) => {
    this.question = question;
  };

  setIdForDelete = (id: number) => {
    this.idForDelete = id;
  };

  setOptions = (options: OptionType[]) => {
    this.options = options;
  };

  clearIdForDelete = () => {
    this.idForDelete = undefined;
  };

  clearQuestion = () => {
    this.question = undefined;
  };

  async getQuestionList(question: string) {
    const { pagination } = this.page;

    const data = [pagination.skip, pagination.pageSize, question];

    try {
      const response = await apiRequest<'SE' | QuestionType[]>({
        url: 'generalmaster.QuesType.QuesTypeList',
        data,
      });

      if (Array.isArray(response)) {
        const list = response.map((el) => ({
          ...el,
          refid: Number(el.refid),
        }));
        this.setQuestionList(list);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setQuestionList([]);
    }
  }

  async getQuestionCount(question: string) {
    try {
      const total = await apiRequest<number>({
        url: 'generalmaster.QuesType.QuesTypeTotalCount',
        data: [question],
      });
      this.setQuestionTotal(Number(total) || 0);
    } catch (e) {
      this.setQuestionTotal(0);
    }
  }

  getQuestionsMain = async (type: string) => {
    this.setFetching(true);

    const promiseList = this.getQuestionList(type);

    const promiseCount = this.getQuestionCount(type);

    Promise.allSettled([promiseList, promiseCount]).finally(() => {
      this.setFetching(false);
    });
  };

  async getQuestion(id: number) {
    this.setFetching(true);
    try {
      const response = await apiRequest<'SE' | [QuestionType]>({
        url: 'generalmaster.QuesType.GetViewQuesType',
        data: [id],
      });

      if (Array.isArray(response) && response.length) {
        const formValue = {
          question: response[0].questnm,
          id: Number(response[0].refid),
        };
        this.setQuestionForEdit(formValue);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setQuestionForEdit();
    } finally {
      this.setFetching(false);
    }
  }

  async addQuestion({ question }: FormModel) {
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.QuesType.AddQuesType',
        data: [question],
      });
      if (result === 'S') {
        Notification.success('Security Question was added successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This question already exist!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateQuestion(payload: FormModel) {
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.QuesType.EditQuesType',
        data: [payload.id, payload.question],
      });

      if (result === 'S') {
        Notification.success('Security Question was updated successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This question already exist!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async deleteQuestion() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.QuesType.DeleteQuesType',
        data: [this.idForDelete],
      });

      if (result === 'S') {
        Notification.success('Security question was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getQuestionOption() {
    runInAction(() => {
      this.fetchingOptions = true;
    });
    try {
      const list = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.QuesType.QuesTypeDropDown',
      });
      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storeSecurityQuestion = new SecurityQuestion();
