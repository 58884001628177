export const URL_CALL_LOG = '/orders/call-log';
export const URL_CALL_LOG_ADD = `${URL_CALL_LOG}/add`;
export const URL_CALL_LOG_EDIT = `${URL_CALL_LOG}/edit/:id`;

export const URL_ORDER_FAX = '/orders/fax';
export const URL_ORDER_FAX_ADD = `${URL_ORDER_FAX}/add`;

export const URL_SERVICE_MAINTENANCE = '/service-and-maintenance';
export const URL_SERVICE_REJECT_REASON_REPORT = `${URL_SERVICE_MAINTENANCE}/reject-reason-report`;
export const URL_SERVICE_HISTORY = `${URL_SERVICE_MAINTENANCE}/service-history`;
export const URL_EQUIPMENT_MAINTENANCE = `${URL_SERVICE_MAINTENANCE}/calibration-records`;
export const URL_TASK_MANAGER = `${URL_SERVICE_MAINTENANCE}/task-manager`;
export const URL_EMAIL_REPORT = `${URL_SERVICE_MAINTENANCE}/email-report`;

export const URL_EQUIPMENT_CHECKOUT_IN = '/check-out-in';
export const URL_EQUIPMENT_CHECKOUT = `${URL_EQUIPMENT_CHECKOUT_IN}/out`;
export const URL_EQUIPMENT_CHECKOUT_EDIT = `${URL_EQUIPMENT_CHECKOUT}/edit/:id/:mode`;
export const URL_EQUIPMENT_CHECKOUT_ADD = `${URL_EQUIPMENT_CHECKOUT_IN}/out/add`;
export const URL_EQUIPMENT_CHECKOUT_HISTORY = '/check-out-history';
export const URL_EQUIPMENT_CHECK_IN = `${URL_EQUIPMENT_CHECKOUT_IN}/in`;
export const URL_EQUIPMENT_CHECKOUT_REQUEST_SERVICE = `${URL_EQUIPMENT_CHECKOUT_IN}/request-service`;

export const URL_ORDER_BASE = '/orders/order-information';
export const URL_ORDER_PLACED = `${URL_ORDER_BASE}/placed-orders`;
export const URL_ORDER_UNASSIGNED = `${URL_ORDER_BASE}/unassigned-orders`;
export const URL_ORDER_ASSIGNED = `${URL_ORDER_BASE}/assigned-orders`;
export const URL_ORDER_REPORT_MANAGER = `${URL_ORDER_BASE}/report-manager`;
export const URL_ORDER_CALLBACK_MANAGER = `${URL_ORDER_BASE}/callback-manager`;
export const URL_ORDER_VISIT_SCHEDULE = `${URL_ORDER_BASE}/visit-schedule`;
export const URL_ORDER_EXAM_REVIEW = `${URL_ORDER_BASE}/exam-review`;
export const URL_ORDER_ADD = `${URL_ORDER_BASE}/add`;

export const URL_CLOCK_IN = '/clock-in/clock-in';

export const URL_EQUIPMENT_INVENTORY = `${URL_SERVICE_MAINTENANCE}/equipment-inventory`;
export const URL_BONE_SONOMETER = `${URL_EQUIPMENT_INVENTORY}/bonesonometer`;
export const URL_BONE_SONOMETER_ADD = `${URL_BONE_SONOMETER}/add`;
export const URL_BONE_SONOMETER_EDIT = `${URL_BONE_SONOMETER}/edit/:id`;
export const URL_COMPUTER = `${URL_EQUIPMENT_INVENTORY}/computermaster`;
export const URL_COMPUTER_ADD = `${URL_COMPUTER}/add`;
export const URL_COMPUTER_EDIT = `${URL_COMPUTER}/edit/:id`;
export const URL_DR_PLATE = `${URL_EQUIPMENT_INVENTORY}/platemaster`;
export const URL_DR_PLATE_ADD = `${URL_DR_PLATE}/add`;
export const URL_DR_PLATE_EDIT = `${URL_DR_PLATE}/edit/:id`;
export const URL_EKG = `${URL_EQUIPMENT_INVENTORY}/ekgmaster`;
export const URL_EKG_ADD = `${URL_EKG}/add`;
export const URL_EKG_EDIT = `${URL_EKG}/edit/:id`;
export const URL_GENERATOR = `${URL_EQUIPMENT_INVENTORY}/generatormaster`;
export const URL_GENERATOR_ADD = `${URL_GENERATOR}/add`;
export const URL_GENERATOR_EDIT = `${URL_GENERATOR}/edit/:id`;
export const URL_GLOW = `${URL_EQUIPMENT_INVENTORY}/glovemaster`;
export const URL_GLOW_ADD = `${URL_GLOW}/add`;
export const URL_GLOW_EDIT = `${URL_GLOW}/edit/:id`;
export const URL_ULTRASOUND = `${URL_EQUIPMENT_INVENTORY}/devicemaster`;
export const URL_ULTRASOUND_ADD = `${URL_ULTRASOUND}/add`;
export const URL_ULTRASOUND_EDIT = `${URL_ULTRASOUND}/edit/:id`;
export const URL_THYROID_SHIELD = `${URL_EQUIPMENT_INVENTORY}/thyroidmaster`;
export const URL_THYROID_SHIELD_ADD = `${URL_THYROID_SHIELD}/add`;
export const URL_THYROID_SHIELD_EDIT = `${URL_THYROID_SHIELD}/edit/:id`;
export const URL_OTHER_EQUIPMENT = `${URL_EQUIPMENT_INVENTORY}/otherequipment`;
export const URL_OTHER_EQUIPMENT_ADD = `${URL_OTHER_EQUIPMENT}/add`;
export const URL_OTHER_EQUIPMENT_EDIT = `${URL_OTHER_EQUIPMENT}/edit/:id`;
export const URL_PROBE = `${URL_EQUIPMENT_INVENTORY}/probemaster`;
export const URL_PROBE_ADD = `${URL_PROBE}/add`;
export const URL_PROBE_EDIT = `${URL_PROBE}/edit/:id`;
export const URL_VEHICLE = `${URL_EQUIPMENT_INVENTORY}/vehiclemaster`;
export const URL_VEHICLE_ADD = `${URL_VEHICLE}/add`;
export const URL_VEHICLE_EDIT = `${URL_VEHICLE}/edit/:id`;
export const URL_XRAY = `${URL_EQUIPMENT_INVENTORY}/xraymaster`;
export const URL_XRAY_ADD = `${URL_XRAY}/add`;
export const URL_XRAY_EDIT = `${URL_XRAY}/edit/:id`;
export const URL_LEAD = `${URL_EQUIPMENT_INVENTORY}/leadmaster`;
export const URL_LEAD_ADD = `${URL_LEAD}/add`;
export const URL_LEAD_EDIT = `${URL_LEAD}/edit/:id`;
export const URL_PHONE = `${URL_EQUIPMENT_INVENTORY}/phonemaster`;
export const URL_PHONE_ADD = `${URL_PHONE}/add`;
export const URL_PHONE_EDIT = `${URL_PHONE}/edit/:id`;
export const URL_ULTRASOUND_UNIT = `${URL_EQUIPMENT_INVENTORY}/ultrasoundgroup`;
export const URL_ULTRASOUND_UNIT_ADD = `${URL_ULTRASOUND_UNIT}/add`;
export const URL_ULTRASOUND_UNIT_EDIT = `${URL_ULTRASOUND_UNIT}/edit/:id`;
export const URL_XRAY_UNIT = `${URL_EQUIPMENT_INVENTORY}/xraygroup`;
export const URL_XRAY_UNIT_ADD = `${URL_XRAY_UNIT}/add`;
export const URL_XRAY_UNIT_EDIT = `${URL_XRAY_UNIT}/edit/:id`;
