import { useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import Title from 'components/project/common/title';

import { storeReminder } from 'stores/_mobx/message/reminder';
import { REMINDER as PAGE_ID } from 'constant/pagesId/messages';
import { URL_REMINDER } from 'constant/path/messages';

const columns = [
  { name: 'username', title: 'To' },
  { name: 'subject', title: 'Subject' },
  { name: 'description', title: 'Description' },
  { name: 'reminder_dt', title: 'Reminder Date' },
  { name: 'reminder_time', title: 'Reminder Time' },
  {
    name: 'refid',
    title: 'Actions',
    render: (id: number) =>
      id ? (
        <div className="control">
          <Link
            to={`${URL_REMINDER}/${id}`}
            className="icon icon-view"
            title="View"
          />
        </div>
      ) : null,
  },
];

interface PropsType extends RouteComponentProps {}

const ReminderListPage = ({ history }: PropsType) => {
  const {
    fetching,
    reminderList,
    remindersTotal,
    page: { pagination, setPagination },
  } = storeReminder;

  const gridRef = useRef(null);

  const [selected, setSelected] = useState<number[]>([]);

  const [showDialogConfirm, toggleDialogConfirm] = useState<boolean>(false);

  const handleToggleDialogConfirm = () => {
    toggleDialogConfirm((state) => !state);
  };

  const handleApproveDelete = () => {
    storeReminder.deleteReminders(selected).then((isSucceed) => {
      if (isSucceed) {
        toggleDialogConfirm(false);
        setSelected([]);
        gridRef.current.clearSelection();
        storeReminder.getReminderListMain();
      }
    });
  };

  const handleDoubleClick = ({ refid }: { refid: number }) => {
    history.push(`${URL_REMINDER}/${refid}`);
  };

  useEffect(() => {
    if (!fetching) storeReminder.getReminderListMain();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Reminders" />

      <Grid
        id="reminder_grid"
        selectId="refid"
        ref={gridRef}
        onAjax={fetching}
        columns={columns}
        dataSource={reminderList}
        dataSourceCount={remindersTotal}
        pagination={pagination}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        onDoubleClick={handleDoubleClick}
        gridControlPanel={
          <>
            <GridControlButton title="Add" url={`${URL_REMINDER}/add`} />
            <GridControlButton
              title="Delete"
              disabled={!selected.length}
              onClick={handleToggleDialogConfirm}
            />
          </>
        }
      />

      {showDialogConfirm && (
        <DialogConfirm
          onCancel={handleToggleDialogConfirm}
          onApprove={handleApproveDelete}>
          Are you sure to delete this reminders?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(ReminderListPage);
