import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormFacilityContact from './components/Form';

import {
  storeFacilityContact,
  FacilityContactEditType,
} from 'stores/_mobx/systemSetup/facilityContact';
import { FACILITY_CONTACT as PAGE_ID } from 'constant/pagesId/systemSetup';
import { URL_FACILITY_CONTACTS } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const FacilityContactsAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: FacilityContactEditType) =>
    storeFacilityContact.addFacilityContact(data).then((result) => {
      if (result) return result;
      history.push(URL_FACILITY_CONTACTS);
    });

  return (
    <>
      <Title title="Add Facility Contact" appId={PAGE_ID.ADD} />
      <FormFacilityContact
        backUrl={URL_FACILITY_CONTACTS}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default FacilityContactsAddPage;
