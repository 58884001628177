import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { IconButton } from 'components/button';
import { Grid } from 'components/grid';
import DialogNotes from './components/DialogNotes';
import Filter from './components/Filter';
import DialogUpdateCalibrationDate from './components/DialogUpdateCalibrationDate';

import {
  storeCalibrationRecords,
  RecordType,
  CalibrationDateType,
} from 'stores/_mobx/workflow/serviceMaintenance/calibrationRecords';
import { getDateString } from 'utils/DateUtils';

interface PropsType {
  isFilterVisible: boolean;
}

const EquipmentMaintenanceTab = ({ isFilterVisible }: PropsType) => {
  const { fetching, records, filterMaintenanceDue, notes } =
    storeCalibrationRecords;

  const [defaultValues, setDefaultValues] = useState<CalibrationDateType>(null);

  const columns = useMemo(
    () => [
      { name: 'device_name', title: 'Equipment Name' },
      { name: 'serial_no', title: 'Serial #' },
      { name: 'type', title: 'Equipment Type' },
      { name: 'calibration_date', title: 'Last Calibration Date' },
      { name: 'calibration_due_date', title: 'Next Calibration Date' },
      {
        name: 'refid',
        title: 'Update Calibration',
        render: (
          refId: number,
          { type_val, calibration_due_date }: RecordType
        ) => (
          <div className="control">
            <IconButton
              onClick={() =>
                setDefaultValues({
                  nextCalibrationDate: calibration_due_date,
                  calibrationDate: getDateString(),
                  type: type_val,
                  refId,
                })
              }>
              <i className="bi bi-pencil" />
            </IconButton>
          </div>
        ),
      },
      {
        name: 'refid',
        title: 'Notes',
        render: (id: number, { type_val }: RecordType) => (
          <div className="control">
            <IconButton
              onClick={() => storeCalibrationRecords.getNotes(id, type_val)}>
              <i className="icon icon-notes" />
            </IconButton>
          </div>
        ),
      },
    ],
    []
  );

  const handleCloseDialogUpdating = () => {
    setDefaultValues(null);
  };

  useEffect(() => {
    storeCalibrationRecords.getCalibrationRecords('D');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterMaintenanceDue]);

  return (
    <>
      <Filter
        filterType="filterMaintenanceDue"
        visibility={isFilterVisible}
        fetching={fetching}
      />

      <Grid
        id="equipmentMaintenanceGrid"
        columns={columns}
        onAjax={fetching}
        dataSource={records}
        dataSourceCount={records.length}
        disablePagination
      />

      {notes && <DialogNotes />}

      {defaultValues && (
        <DialogUpdateCalibrationDate
          defaultValues={defaultValues}
          onClose={handleCloseDialogUpdating}
        />
      )}
    </>
  );
};

export default observer(EquipmentMaintenanceTab);
