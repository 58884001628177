import ClaimsService, {
  TClaimManagerFilter,
  TClaimManager,
} from 'services/billing-coding/claims/ClaimsService';
import appDispatcher from 'dispatcher/AppDispatcher';
import { TPagination } from 'components/grid/GridTypes';
import { getDateRangeBounds, getDayBounds } from 'utils/DateUtils';

export class TClaimManagerFilterExt extends TClaimManagerFilter {
  batched: string = '';
  batchedDate: string = '';
}

export default class TcClaimsActions {
  static loadOverview(
    { batchedDate, ...filterPayload }: TClaimManagerFilterExt,
    pagination: TPagination,
    isDivisionEnabled: boolean
  ) {
    let count = 0;
    const batchDate =
      filterPayload.batched === 'B' && batchedDate
        ? getDayBounds(batchedDate)
        : '';
    const dos = getDateRangeBounds({
      from: filterPayload.dosStart,
      to: filterPayload.dosEnd,
    });
    const filter = {
      ...filterPayload,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
      division: isDivisionEnabled ? filterPayload.division : 0,
    };

    return ClaimsService.loadOverviewCount(
      filter,
      'A',
      filter.batched,
      '',
      'T',
      '',
      '',
      batchDate,
      ''
    )
      .then((response: 'SE' | number) => {
        count = response === 'SE' ? 0 : response;
        if (response && parseInt('' + response) > 0) {
          return ClaimsService.loadOverview(
            filter,
            pagination,
            'A',
            filter.batched,
            '',
            'T',
            '',
            '',
            batchDate,
            ''
          );
        } else {
          return [];
        }
      })
      .then((response: 'SE' | 0 | Array<TClaimManager>) => {
        response = !response || response === 'SE' ? [] : response;
        appDispatcher.dispatch({
          type: 'claim-tc-overview',
          data: response,
          count: count,
        } as any);
        return [];
      });
  }

  static createBatch(claimUniqIds: Array<string>) {
    return ClaimsService.createBatch(claimUniqIds, 'T');
  }
}
