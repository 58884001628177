import React from 'react';
import { Container } from 'flux/utils';

import { Button, IconButton } from 'components/button';
import Text from 'components/form/input/Text';
import Radio from 'components/form/input/Radio';
import Select from 'components/form/input/Select';
import Form from 'components/form/Form';
import { DateRangeLegacy } from 'components/form/dateRange';
import Notification from 'components/modal/Notification';
import ExamTypeDropdown from 'components/project/dropdown/ExamTypeDropdown';
import RadiologyDropdown from 'components/project/dropdown/RadiologyDropdown';
import ProviderTypeDropdown from 'components/project/dropdown/ProviderTypeDropdown';
import LocationDropdown from 'components/project/dropdown/LocationDropdown';
import StateDropdown from 'components/project/dropdown/StateDropdown';
import PlaceOfServiceDropdown from 'components/project/dropdown/PlaceOfServiceDropdown';
import RadioOptions from 'components/project/common/RadioOptions';
import PayerNameDropdown from 'components/project/dropdown/PayerNameDropdown';
import OrderTypeDropdown from 'components/project/dropdown/OrderType';
import FacilityDropdown from 'components/project/dropdown/FacilityDropdown';
import { DropdownPureDivision } from 'components/project/dropdown/Division';
import { GridControlButton } from 'components/grid';
import { ControlsLayout } from 'components/layout';
import DialogConfirm from 'components/modal/dialogConfirm';
import DialogJurisdiction from './components/DialogJurisdiction';
import DialogOutsideLab from './components/DialogOutsideLab';
import AbstractClaim, {
  PAbstractClaim,
  SAbstractClaim,
  TAbstractClaimFilter,
} from './AbstractClaim';

import PcClaimsActions, {
  TClaimManagerFilterExt,
} from 'actions/billing-coding/claims/PcClaimsActions';
import PcClaimsStore from 'stores/billing-coding/claims/PcClaimsStore';
import { tcBillingOptions } from 'stores/_mobx/billingCodding/billingAssignment';
import { storeGrid } from 'stores/_mobx/grid';

const GRID_ID = 'pc_claim_grid';

const className = 'col-sm-12 col-md-6 col-lg-3';

const radioClass = `${className} part-inline`;

const SIGNED_OPTIONS = [
  { value: 'Y', label: 'Signed' },
  { value: 'N', label: 'Unsigned' },
  { value: '', label: 'All' },
];

const dropdownAttributes = {
  isMulti: true,
  isStyleDependsOnValue: true,
};

const columnsVisibleByDefault = [
  'facility_name',
  'patientLastNm',
  'patientFirstNm',
  'dos',
  'radiologist',
  'insImg',
  'facImg',
  'zipImg',
  'doctor_pop_img',
  'dobImg',
  'posImg',
  'viewClaim',
  'examCode',
  'fee',
  'info',
  'encounterLog',
  'visitInfo',
  'division',
];

export interface PPcClaims extends PAbstractClaim {}

export class TPcClaimsFilter extends TAbstractClaimFilter {
  batched: string = 'U';
  inJurisdiction: string = '';
  batchedDate: string = '';
}

export class SPcClaims extends SAbstractClaim {
  filter: TPcClaimsFilter = new TPcClaimsFilter();
  assign31Pos: boolean = false;
  revertPos: boolean = false;
  showJurisdiction: boolean = false;
  showOutsideLab: boolean = false;
  orderId: number = null;
  claimUniqId: string = '';
}

class PcClaims extends AbstractClaim<PPcClaims, SPcClaims> {
  static getStores() {
    return [PcClaimsStore];
  }

  static calculateState(prevState: SPcClaims) {
    if (!prevState) {
      prevState = Object.assign(
        new SPcClaims(),
        AbstractClaim.getDefaultState(GRID_ID)
      );
      prevState.filter = storeGrid.getFilter(
        GRID_ID,
        new TPcClaimsFilter()
      ) as TPcClaimsFilter;
      prevState.assign31Pos = false;
      prevState.revertPos = false;
    }
    const store = PcClaimsStore.getState();
    return {
      ...prevState,
      dataSource: store.dataSource,
      dataSourceCount: store.dataSourceCount,
    };
  }

  componentWillUnmount() {
    storeGrid.saveFilter(GRID_ID, this.state.filter);
  }

  getTitle() {
    return 'Professional Component Claims';
  }

  getGridId() {
    return GRID_ID;
  }

  hideLeftGrid() {
    return true;
  }

  isProfessional() {
    return true;
  }

  getButtons = () => {
    return this.state.selected?.length ? (
      <Select
        noLabel
        className="col-md-6 col-lg-4"
        onSetValue={(n, v) => this.assignPos(v)}
        options={[
          { value: 'assign31', label: 'Assign POS to 31' },
          { value: 'revert', label: 'Revert to initial POS' },
        ]}
      />
    ) : null;
  };

  assignPos(v: 'assign31' | 'revert') {
    switch (v) {
      case 'assign31':
        this.setState({ assign31Pos: true });
        break;
      case 'revert':
        this.setState({ revertPos: true });
        break;
    }
  }

  handleChangeFilter = (name: string, value?: string) => {
    const filter = { ...this.state.filter, [name]: value };
    this.setState({ filter });
  };

  handleChangeDatePeriod = (filter: TPcClaimsFilter) => {
    this.setState({ filter });
  };

  getFilterComponent() {
    const { isFilterVisible } = this.props;
    const { division } = this.state.filter;

    return (
      isFilterVisible && (
        <Form
          onCollectValues={this.handleChangeFilter}
          model={this.state.filter}
          submit={() => this.onFilterSubmit()}>
          <Text name="lastName" className={className} />
          <Text name="firstName" className={className} />
          <ExamTypeDropdown
            name="examType"
            label="Modality"
            optionsForOrderOnly
            className={className}
            attr={dropdownAttributes}
          />
          <ProviderTypeDropdown
            name="providerType"
            className={className}
            attr={dropdownAttributes}
          />
          <div />
          <FacilityDropdown
            name="facility"
            className={className}
            attr={dropdownAttributes}
          />
          <LocationDropdown
            name="location"
            className={className}
            attr={dropdownAttributes}
          />
          <StateDropdown
            name="state"
            className={className}
            attr={dropdownAttributes}
          />
          <PlaceOfServiceDropdown
            name="pos"
            label="Place of Service"
            className={className}
            attr={dropdownAttributes}
          />
          <div />
          <>
            <DropdownPureDivision
              name="division"
              label="Division"
              className={className}
              value={division}
              onChange={this.handleChangeDivision}
            />
          </>
          <DateRangeLegacy
            nameFrom="dosStart"
            nameTo="dosEnd"
            nameRadio="period"
            onChange={this.handleChangeDatePeriod}
          />
          <div />
          <RadiologyDropdown
            name="radiologyId"
            label="Radiology Group"
            className={className}
            attr={dropdownAttributes}
          />
          <OrderTypeDropdown
            name="orderType"
            attr={dropdownAttributes}
            className={className}
          />
          <Text name="cpt" label="CPT Code" className={className} />
          <PayerNameDropdown
            name="payerName"
            label="Primary Payer"
            className={className}
            attr={dropdownAttributes}
          />
          <div />
          <Select
            name="tcAssignment"
            label="TC Assignment"
            className={className}
            options={tcBillingOptions}
          />
          <Radio
            name="inJurisdiction"
            options={RadioOptions.yesNoAll}
            className={radioClass}
          />
          <Radio
            name="isSigned"
            label="Provider"
            options={SIGNED_OPTIONS}
            allowDeselect
            className="col-md-6 col-lg-auto part-inline"
          />
          <ControlsLayout alignX="auto">
            <Button
              variant="warning"
              text="Reset"
              onClick={this.handleResetFilter}
            />
            <Button text="Apply" type="submit" />
          </ControlsLayout>
        </Form>
      )
    );
  }

  createBatch = () => {
    const { state } = this;
    if (!state.selected.length) {
      Notification.warning('Please select at least one order');
    } else if (!state.onAjax) {
      this.setState({ onAjax: true }, () => {
        PcClaimsActions.createBatch(state.selected).then(() => {
          Notification.success(
            'Batch has been created for the selected order(s)'
          );
          this.updateData();
        });
      });
    }
  };

  render() {
    const { state } = this;
    return (
      <div>
        {super.render()}
        {state.showJurisdiction && (
          <DialogJurisdiction
            onClose={this.closeJurisdiction}
            orderId={this.state.orderId}
            claimId={this.state.claimUniqId}
          />
        )}
        {state.showOutsideLab && (
          <DialogOutsideLab
            onClose={() => this.setState({ showOutsideLab: false })}
            orderId={this.state.orderId}
          />
        )}
        {state.assign31Pos && (
          <DialogConfirm
            onCancel={this.handleCloseDialogConfirmPos}
            onApprove={this.processAssignPos}>
            Are you sure to assign selected order to POS 31?
          </DialogConfirm>
        )}
        {state.revertPos && (
          <DialogConfirm
            onCancel={this.handleCloseDialogRevertPos}
            onApprove={this.processRevertPos}>
            Are you sure to revert selected orders POS to default value?
          </DialogConfirm>
        )}
      </div>
    );
  }

  handleCloseDialogConfirmPos = () => {
    this.setState({ assign31Pos: false });
  };
  processAssignPos = () => {
    this.setState({ assign31Pos: false });
    PcClaimsActions.assignPosTo31(this.state.selected).then(() =>
      this.updateData()
    );
  };

  handleCloseDialogRevertPos = () => {
    this.setState({ revertPos: false });
  };

  processRevertPos = () => {
    this.setState({ revertPos: false });
    PcClaimsActions.revertPos(this.state.selected).then(() =>
      this.updateData()
    );
  };

  closeJurisdiction = (response: boolean) => {
    this.setState(
      { showJurisdiction: false, orderId: null, claimUniqId: '' },
      response ? () => this.updateData() : undefined
    );
  };

  handleClickJurisdiction = (row: any) => (e: React.MouseEvent) => {
    e.preventDefault();

    this.setState({
      showJurisdiction: true,
      orderId: row.refid,
      claimUniqId: row.claimUniqId,
    });
  };

  handleClickArrPrice = (row: any) => (e: React.MouseEvent) => {
    e.preventDefault();

    this.setState({
      showOutsideLab: true,
      orderId: row.refid,
      claimUniqId: row.claimUniqId,
    });
  };

  getColumns() {
    const out = super.getColumns();
    for (let i = 0; i < out.length; i++) {
      const column = out[i];
      if (column.name === 'doctor_pop_img') {
        out.splice(
          i,
          0,
          {
            name: 'jurisdiction',
            title: 'Jurisdiction',
            render: (value, row) => (
              <IconButton onClick={this.handleClickJurisdiction(row)}>
                <i className="icon icon-view" />
              </IconButton>
            ),
          },
          {
            name: 'outside_lab',
            title: 'ARR Price',
            render: (value, row) => (
              <IconButton onClick={this.handleClickArrPrice(row)}>
                <i className="icon icon-view" />
              </IconButton>
            ),
          }
        );
        break;
      }
    }
    return out;
  }

  updateData() {
    const { isDivisionEnabled } = this.props;
    const { filter, pagination } = this.state;
    this.setState({ onAjax: true, appliedClaimType: filter.claimType }, () => {
      PcClaimsActions.loadOverview(
        Object.assign(new TClaimManagerFilterExt(), filter),
        pagination,
        isDivisionEnabled
      ).then(this.updateDataCallback);
    });
  }

  handleResetFilter = () => {
    const filter = new TPcClaimsFilter();
    const pagination = {
      ...this.state.pagination,
      page: 1,
      skip: 0,
    };
    this.setState({ pagination, appliedClaimType: filter.claimType, filter }, this.updateData);
  };

  getGridActions() {
    return (
      <GridControlButton title="Create Batch" onClick={this.createBatch} />
    );
  }

  getStartShownColumns(): Map<string, boolean> {
    const out = new Map<string, boolean>();
    const columns = this.getColumns();
    for (let i = 0; i < columns.length; i++) {
      const name = columns[i].name;
      if (!columnsVisibleByDefault.includes(name)) {
        out.set(name, false);
      }
    }
    out.delete('jurisdiction');
    out.delete('outside_lab');
    return out;
  }
}

export default Container.create(PcClaims);
