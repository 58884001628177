import AbstractLikeFilter from './AbstractLikeFilter';

export default class LastNameFilter extends AbstractLikeFilter {
  name = 'patientLastName';
  field = 'Patient Last Name';
  field1 = AbstractLikeFilter.getInsecureSecureField(
    'ppxray_patientinfo.last_name'
  );
  className = 'last-name-filter';
}
