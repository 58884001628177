import { sub, startOfToday, endOfToday, startOfTomorrow } from 'date-fns';

import { getDateString } from 'utils/DateUtils';

interface ResultType {
  dosStart: string;
  dosEnd: string;
}

export const calcDateRange = (value: string, isUTC?: boolean): ResultType => {
  if (value === 'A')
    return {
      dosStart: '',
      dosEnd: '',
    };

  const currentTime = new Date().getTime();

  const end = endOfToday();

  let start = startOfToday();

  if (value === 'M') {
    start = sub(startOfTomorrow(), { months: 1 });
  } else if (value === 'W') {
    start = sub(startOfTomorrow(), { weeks: 1 });
  } else if (value === 'YTD') {
    start = sub(startOfTomorrow(), { years: 1 });
  } else if (parseInt(value) > 0) {
    start.setTime(currentTime - parseInt(value) * 24 * 3600 * 1000);
  }

  return {
    dosStart: getDateString(start, isUTC),
    dosEnd: getDateString(end, isUTC),
  };
};
