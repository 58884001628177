import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';

import {
  exporterInterface,
  storeExporter,
  createFullFilename,
  GenerateStatus,
} from 'stores/_mobx/exporter';

const ExporterDialog = () => {
  const { openedSubscription } = exporterInterface;

  const readyToDownload = openedSubscription?.generateStatus === GenerateStatus.success || false;
  const reportGenerationFailed = openedSubscription?.generateStatus === GenerateStatus.failed || false;

  const dialogTitle = readyToDownload ? 'Report Complete' : reportGenerationFailed ? 'Report Failed' : 'Generating Report';

  const handleDismiss = () => {
    exporterInterface.closeDialogInfo(openedSubscription.instance);
  };

  const handleCloseDialog = (readyToDownload || reportGenerationFailed) && handleDismiss;

  const handleDownload = (e: React.SyntheticEvent) => {
    e.preventDefault();
    exporterInterface.downloadFile(openedSubscription);
  };

  useEffect(() => {
    if (openedSubscription?.id) {
      storeExporter.insertRecord(openedSubscription);
    }
  }, [openedSubscription, openedSubscription?.id]);

  useEffect(() => exporterInterface.unsubscribeAll, []);

  return openedSubscription ? (
    <Dialog handleClose={handleCloseDialog}>
      <DialogHeader
        title={dialogTitle}
        onClose={handleCloseDialog}
      />
      <DialogBody className={openedSubscription.id ? undefined : 'on-ajax'}>
        {readyToDownload ? (
          <>
            <p>Your report has been generated!</p>
            <a
              className="text-primary text-nowrap"
              href="/"
              target="_blank"
              rel="noreferrer"
              onClick={handleDownload}>
              {createFullFilename(openedSubscription)}
            </a>
          </>
        ) : reportGenerationFailed ? (
          <>
            <p>We couldn't generate your report.</p>
            <p className="row row-cols-auto">
              <span className="col align-self-center text-danger">
                {`${openedSubscription.source}.${openedSubscription.fileExtension}`}
              </span>
              <span
                className={
                  openedSubscription.id ? 'col downloads-manager' : 'invisible'
                }>
                <i className="bi bi-exclamation-circle downloads-icon-error text-danger" role="status" />
              </span>
            </p>
            <p>
              Click <b>dismiss</b> to close this pop-up and remove the failed report from the Exports list.
              You can re-generate the report directly from the grid.
            </p>
          </>
        ) : (
          <>
            <p>Your report is being generated in the background</p>
            <p className="row row-cols-auto">
              <span className="col align-self-center text-primary">
                {`${openedSubscription.source}.${openedSubscription.fileExtension}`}
              </span>
              <span
                className={
                  openedSubscription.id ? 'col downloads-manager' : 'invisible'
                }>
                <i className="spinner-border text-primary" role="status" />
              </span>
            </p>
            <p>
              You may wait for your report to finish or click <b>dismiss</b> and
              continue working. We will notify you when your report is ready for
              download.
            </p>
          </>
        )}
      </DialogBody>
      <DialogFooter>
        <Button
          disabled={!openedSubscription?.id}
          text={readyToDownload ? 'Download' : 'Dismiss'}
          onClick={readyToDownload ? handleDownload : handleDismiss}
        />
      </DialogFooter>
    </Dialog>
  ) : null;
};

export default observer(ExporterDialog);
