import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import resolver from './validation';

import {
  StateType,
  initialValues,
} from 'stores/_mobx/systemSetup/masterSetting/state';
import { ServerFormValidationType } from 'types';

interface PropsType {
  defaultValues?: StateType;
  onSubmit: (data: StateType) => Promise<any>;
}

const Form = ({ defaultValues = initialValues, onSubmit }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm({ defaultValues, resolver });

  const cn = `col-lg-8 col-xl-6${isSubmitting ? ' on-ajax' : ''}`;

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: StateType) =>
    onSubmit(data).then((errors: ServerFormValidationType<StateType>[]) => {
      if (errors)
        errors.forEach(({ field, ...body }) => {
          setError(field, body);
        });
    })
  );

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form className={cn} onSubmit={handleClickSubmit}>
      <Input name="state" label="State" required control={control} />
      <Input
        name="shortName"
        label="Short Name"
        maxLength={4}
        required
        control={control}
      />
      <ControlsLayout alignX="right">
        <Button
          variant="warning"
          text="Reset"
          disabled={!isDirty || isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={!isDirty || isSubmitting}
        />
      </ControlsLayout>
    </form>
  );
};

export default Form;
