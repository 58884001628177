import React from 'react';
import { Container } from 'flux/utils';

import { getDateString } from 'utils/DateUtils';
import SignOrdersPageStore from 'stores/clinician-manager/sign-orders/SignOrdersPageStore';
import AbstractSignOrdersPage, {
  PAbstractSignOrdersPage,
  SAbstractSignOrdersPage,
  TAbstractSignOrdersPageFilter,
} from 'page/clinician-manager/sign-orders/AbstractSignOrdersPage';
import UserProfileStore from 'stores/UserProfileStore';
import { URL_SIGNED_ORDER_SIGNED_TAB } from 'constant/path/clinicalManager';
import { storeGrid } from 'stores/_mobx/grid';

const GRID_ID = 'signed_orders_grid';

const getDefaultFilter = (isUserPhysician: boolean) => ({
  ...new TAbstractSignOrdersPageFilter(),
  signed: 'Y',
  period: isUserPhysician ? 'A' : 'TD',
  fromDate: isUserPhysician ? '' : getDateString(),
  toDate: isUserPhysician ? '' : getDateString(),
});

export class SignedOrdersTab extends AbstractSignOrdersPage<
  PAbstractSignOrdersPage,
  SAbstractSignOrdersPage
> {
  static get TITLE() {
    if (UserProfileStore.getUserType() === 'D') {
      return 'My Signed Orders';
    } else {
      return 'Signed Orders';
    }
  }

  static calculateState(prevState: SAbstractSignOrdersPage) {
    if (!prevState) {
      prevState = {
        ...AbstractSignOrdersPage.getDefaultState(GRID_ID),
        filter: storeGrid.getFilter(
          GRID_ID,
          getDefaultFilter(UserProfileStore.getUserType() === 'D')
        ),
      };
    }
    return {
      ...prevState,
      dataSource: SignOrdersPageStore.getState().signOrdersList,
      dataSourceCount: SignOrdersPageStore.getState().signOrdersCount,
    };
  }

  getDefaultFilter = () =>
    getDefaultFilter(UserProfileStore.getUserType() === 'D');

  getAllowedColumns() {
    return [
      'dos',
      'dostime',
      'last_name',
      'first_name',
      'room',
      'facility',
      'technologistnm',
      'physicianname',
      'visitInfo',
      'images',
      'viewImage',
      'cancel',
      'ordercreated_dt',
      'vw_patientid',
      'clientMRN',
      'gender',
      'patientdob',
      'ssn',
      'pos',
      'eligibility_status',
      'exam_request',
      'priority',
      'cpt',
      'diagnosis',
    ];
  }

  prepareFilter() {
    const filter = super.prepareFilter();
    filter.signed = 'Y';
    return filter;
  }

  goToOverview() {
    this.props.history.push(URL_SIGNED_ORDER_SIGNED_TAB);
  }

  getGridId() {
    return GRID_ID;
  }

  getTitle() {
    return SignedOrdersTab.TITLE;
  }

  getControlPanel = (): React.ReactNode => null;

  renderOrderStatus(): React.ReactNode {
    return null;
  }

  getShownColumns() {
    return [
      'dos',
      'dostime',
      'last_name',
      'first_name',
      'room',
      'facility',
      'technologistnm',
      'physicianname',
      'visit_info',
      'exam_request',
    ];
  }
}

export default Container.create(SignedOrdersTab);
