import AbstractLikeFilter from './AbstractLikeFilter';

export default class TechnicianLastName extends AbstractLikeFilter {
  name = 'technicianLastName';
  field = 'Technician Last Name';
  field1 = AbstractLikeFilter.getInsecureSecureField(
    'ppxray_staffinfo.last_name'
  );
  className = 'technician-last-name-filter';
}
