import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import FormAdjustmentCode from './components/Form';

import {
  storeAdjustmentCode,
  CodeType,
} from 'stores/_mobx/systemSetup/masterSetting/adjustmentCode';
import { URL_ADJUSTMENT_CODE } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const AdjustmentCodeEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, code } = storeAdjustmentCode;

  const handleSubmit = async (data: CodeType) =>
    storeAdjustmentCode.updateCode(data).then((error) => {
      if (error) return error;
      history.push(URL_ADJUSTMENT_CODE);
    });

  useEffect(() => {
    if (!fetching) storeAdjustmentCode.getCode(Number(params.id));

    return storeAdjustmentCode.clearCode;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Adjustment Code" />
      <FormAdjustmentCode
        backUrl={URL_ADJUSTMENT_CODE}
        defaultValues={code}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(AdjustmentCodeEditPage);
