import { Button } from 'components/button';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';

interface PropsType {
  pin: string;
  title?: string;
  onClose: () => void;
}

const DialogPinDisplaying = ({
  pin,
  title = 'Lattice Pro',
  onClose,
}: PropsType) => (
  <Dialog>
    <DialogHeader title={title} />
    <DialogBody>
      <ul>
        <li>Password was reset!</li>
        <li>New PIN: {pin}</li>
        <li>Check your mail for more information</li>
      </ul>
    </DialogBody>
    <DialogFooter>
      <Button text="Ok" onClick={onClose} />
    </DialogFooter>
  </Dialog>
);

export default DialogPinDisplaying;
