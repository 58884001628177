import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Container } from 'flux/utils';

import MultipleFileUpload from 'components/form/input/MultipleFileUpload';
import { Button, IconButton } from 'components/button';
import Text from 'components/form/input/Text';
import Fieldset from 'components/form/Fieldset';
import TextArea from 'components/form/input/TextArea';
import Form from 'components/form/Form';
import Notification from 'components/modal/Notification';
import NumberInput from 'components/form/input/NumberInput';
import StateShortNameDropdown from 'components/project/dropdown/StateShortName';
import { SpinnerFixed } from 'components/spinner';

import CorporationOverviewPageStore, {
  AdditionalAddressList
} from 'stores/system-setup/corporations/CorporationOverviewPageStore';
import CorporationOverviewPageActions from 'actions/system-setup/master-setup/corporations/CorporationOverviewPageActions';
import { URL_CORPORATION } from 'constant/path/systemSetup';

import IterableUtils from 'utils/IterableUtils';

export interface PContactTab {
  corporationId: number;
  history: RouteComponentProps['history'];
  updateCorporationName: (corporationName: string) => void;
}

class TContactInfo {
  corp_name: string = '';
  collapsedContactInfo: boolean = false;
  additionalAddressList: Array<any> = [];
}

class TDataSource {
  contactInfoList: Array<TContactInfo> = [];
  fileUploadList: Array<any> = [];
  fileListLoaded: boolean = false;
  errors: any = {};
}

export class SContactTab {
  itemLoaded: boolean = false;
  errors: any = {};
  dataSource: TDataSource = new TDataSource();
  isAdditionalContact: boolean;
}

class ContactTab extends React.Component<PContactTab, SContactTab> {
  static getStores() {
    return [CorporationOverviewPageStore];
  }

  static calculateState(prevState: SContactTab) {
    if (!prevState) {
      prevState = Object.assign({
        dataSource: {
          contactInfoList: [new TContactInfo()],
          errors: {},
        },
        itemLoaded: false,
        errors: {},
        isAdditionalContact: false
      });
    }

    const store = CorporationOverviewPageStore.getState();
    const dataSource: TDataSource = new TDataSource();
    dataSource.contactInfoList = store.addressList;
    dataSource.fileUploadList = store.fileList;
    dataSource.fileListLoaded = store.fileListLoaded;

    dataSource.contactInfoList[0].collapsedContactInfo = false;

    const additionalAddressList: Array<any> = store.additionalAddressList;

    if (additionalAddressList?.length) {
      additionalAddressList?.forEach(
        (item: TContactInfo) => (item.collapsedContactInfo = true)
      );

      dataSource.contactInfoList[0].additionalAddressList =
        additionalAddressList.reduce((acc, cur, i) => {
          acc[i] = cur;
          return acc;
        }, {});
    } else {
      dataSource.contactInfoList[0].additionalAddressList = [];
    }

    return {
      ...prevState,
      dataSource,
    };
  }

  handleSubmit = (
    updatedModel: any,
    hasErrors: boolean,
    updatedErrors: any
  ) => {
    if (hasErrors) {
      const { state } = this;
      const dataSource = { ...state.dataSource, ...updatedModel };
      const errors = { ...state.errors, ...updatedErrors };
      this.setState({ dataSource, errors });
    } else {
      this.submitSuccess();
    }
  };

  addEmptyContactItem() {
    const dataSource = { ...this.state.dataSource };
    const additionalAddressList =
      dataSource.contactInfoList[0].additionalAddressList;
    const newKey = Object.keys(additionalAddressList).length;
    additionalAddressList[newKey] = { ...new AdditionalAddressList() };
    const isAdditionalContact = Boolean(Object.keys(additionalAddressList).length);
    this.setState({ dataSource, isAdditionalContact });
  }

  modifyContactItem() {
    const dataSource = { ...this.state.dataSource };
    const additionalAddressList = dataSource.contactInfoList[0].additionalAddressList;
    additionalAddressList[0].collapsedContactInfo = false;
    this.setState({ isAdditionalContact: true, dataSource });
  }

  removeContactItem(index: number) {
    index = parseInt('' + index);
    const dataSource = { ...this.state.dataSource };
    const additionalAddressList: any = dataSource.contactInfoList[0].additionalAddressList;

    while (++index in additionalAddressList) {
      additionalAddressList[index - 1] = additionalAddressList[index];
      delete additionalAddressList[index];
    }
    this.setState({ dataSource: dataSource });
  }

  onCollectValues(name: string, value: any, errorMessages: any) {
    const state = IterableUtils.extend(true, {}, this.state);
    state.dataSource.contactInfoList[0][name] = value;
    state.errors[name] = errorMessages;
    this.setState(state);
  }

  submitSuccess() {
    const {
      props,
      state
    } = this;
    const {
      history,
      corporationId,
      updateCorporationName
    } = props;

    const corpId = corporationId;
    const { dataSource } = state;
    const additionalAddressList = Object.values(
      dataSource.contactInfoList[0].additionalAddressList
    );
    if (corpId) {
      return CorporationOverviewPageActions.updateCorporationItem(
        corpId,
        dataSource.contactInfoList,
        additionalAddressList,
        dataSource.fileUploadList
      ).then((response) => {
        if (response && parseInt('' + response, 10) > 0) {
          updateCorporationName(dataSource.contactInfoList[0].corp_name);

          Notification.success('Successfully changed!');
          history.push({ search: '?subtub=facilities' });
        } else if (response === 'E') {
          Notification.danger('Corporation with such name already exists!');
        } else {
          Notification.danger('An error occurred!');
        }
      });
    }
    return CorporationOverviewPageActions.saveCorporationItem(
      dataSource.contactInfoList,
      additionalAddressList,
      dataSource.fileUploadList
    ).then((response) => {
      const id = parseInt('' + response, 10);
      if (response && id > 0) {
        Notification.success('Successfully saved!');
        updateCorporationName(dataSource.contactInfoList[0].corp_name);

        this.props.history.push(
          `${URL_CORPORATION}/edit/${id}?subtub=facilities`
        );
      } else if (response === 'E') {
        Notification.danger('Corporation with such name already exists!');
      } else {
        Notification.danger('An error occurred!');
      }
    });
  }

  reset() {
    CorporationOverviewPageActions.restoreInitialContactTab();

    const additionalAddressListFiltered = this.getAdditionalAddressListFiltered();
    const isAdditionalContact = Boolean(Object.keys(additionalAddressListFiltered).length);

    this.setState({ isAdditionalContact });
  }

  updateData() {
    const { props } = this;
    const corpId = props.corporationId;
    if (corpId) {
      CorporationOverviewPageActions.loadCorporationItem(corpId).then((res) => {
        this.setState({ itemLoaded: true });
        props.updateCorporationName(res.addressList[0].corp_name);

        const additionalAddressListFiltered = this.getAdditionalAddressListFiltered();
        const isAdditionalContact = Boolean(Object.keys(additionalAddressListFiltered).length);

        this.setState({ isAdditionalContact });
      });
    } else {
      this.setState({ itemLoaded: true });
    }
  }

  handleClickControl() {
    const additionalAddressListFiltered = this.getAdditionalAddressListFiltered(true);
    const isAdditionalContact = Boolean(Object.keys(additionalAddressListFiltered).length);

    if (isAdditionalContact) {
      this.modifyContactItem();
    } else {
      this.addEmptyContactItem();
    }
  }

  getAdditionalAddressListFiltered(isEmptyContact: boolean = false) {
    let addressListFiltered: { [key: string]: { [key: string]: any } } = {};

    const { dataSource } = this.state;
    const additionalAddressList = dataSource.contactInfoList[0].additionalAddressList;

    if (Object.keys(additionalAddressList).length > 0) {
      Object.keys(additionalAddressList).forEach(key => {
        const keyNum = parseInt(key);
        const contact = additionalAddressList[keyNum];

        if (Object.values(contact).some(
          val => val !== undefined && val !== null
            && contact.hasOwnProperty('address1') && contact.address1 !== null
            && (isEmptyContact ? contact.address1 === '' : contact.address1 !== '')
        )) {
          addressListFiltered[keyNum] = contact;
        }
      });
    }

    return addressListFiltered;
  }

  getContactInfoSection(additional: boolean = false, key: number = 0) {
    const { dataSource } = this.state;
    const additionalAddressList = dataSource.contactInfoList[0].additionalAddressList;
    const contactInfoList = dataSource.contactInfoList;

    const dataList = additional ? additionalAddressList : contactInfoList;
    const additionalAddressListLength = Object.keys(additionalAddressList).length;
    const lastElementIndex = additionalAddressListLength > 0 ? additionalAddressListLength - 1 : 0;

    const additionalAddressListFiltered = this.getAdditionalAddressListFiltered(true);
    const isAddButtonDisabled = Boolean(Object.keys(additionalAddressListFiltered).length);

    return (
      <Fieldset className="row mx-0">
        <Fieldset className={additional ? '' : 'grouped-fields-block mb-5'}>
          <Fieldset
            className={dataList[key].collapsedContactInfo ? 'collapsed' : 'expanded'}
            name={additional ? 'additionalAddressList' : ''}
          >
            <legend
              className={dataList[key].collapsedContactInfo ? 'without-border col-sm-12' : 'col-sm-12'}
              onClick={() => {
                let dataToUpdate = additional
                  ? this.state.dataSource.contactInfoList[0].additionalAddressList[
                    key
                    ]
                  : this.state.dataSource.contactInfoList;
                let contactInfoListUpdated;

                if (additional) {
                  dataToUpdate.collapsedContactInfo =
                    !dataToUpdate.collapsedContactInfo;
                  contactInfoListUpdated = this.state.dataSource.contactInfoList;
                  contactInfoListUpdated[0].additionalAddressList[key] =
                    dataToUpdate;
                } else {
                  dataToUpdate[key].collapsedContactInfo =
                    !dataToUpdate[key].collapsedContactInfo;
                  contactInfoListUpdated = dataToUpdate;
                }
                this.setState({
                  dataSource: {
                    ...this.state.dataSource,
                    contactInfoList: contactInfoListUpdated
                  }
                });
              }}
            >
              {dataList[key].title ? dataList[key].title : <b>&nbsp;</b>}
            </legend>
            <div />
            <Fieldset name={additional ? '' + key : ''} className="row">
              <Fieldset className="col-sm-6">
                <Text
                  name="first_name"
                  label="First name"
                  validations="required"
                  errorMessages="Please, enter the first name!"
                />
                <Text name="middle_name" label="Middle name" />
                <Text
                  name="last_name"
                  label="Last name"
                  validations="required"
                  errorMessages="Please, enter the last name!"
                />
                <Text
                  name="title"
                  label="Contact title"
                  validations="required"
                  errorMessages="Please, enter the contact title!"
                />
                <Text
                  name="address1"
                  label="Address 1"
                  validations="required"
                  errorMessages="Please, enter address 1!"
                />
                <Text
                  name="city"
                  label="City"
                  validations="required"
                  errorMessages="Please, enter the city!"
                />
                <Text
                  name="email"
                  label="Email"
                  validations="required"
                  errorMessages="Please, enter an email!"
                />
                <NumberInput
                  name="phone"
                  label="Phone"
                  format="###-###-####"
                  validations="required"
                  errorMessages="Please, enter the phone!"
                />
              </Fieldset>
              <Fieldset className="col-sm-6">
                <Text name="website" label="Website" />
                <StateShortNameDropdown
                  name="state"
                  label="State"
                  validations="required"
                  errorMessages="Please, select the state!"
                />
                <Text name="country" label="County" />
                <Text name="address2" label="Address 2" />
                <Text name="alt_email" label="Alternative email" />
                <NumberInput
                  name="alt_phone"
                  label="Alternative phone"
                  format="###-###-####"
                />
                <NumberInput name="fax" label="Fax" format="###-###-####" />
                <NumberInput name="zipcode" label="Zip Code" format="#####-####" />
              </Fieldset>
            </Fieldset>

            {(additional || (!additional && !this.state.isAdditionalContact)) && (
              <div className='grouped-fields-controls'>
                <Fieldset>
                  {key === lastElementIndex ? (
                    <IconButton
                      className="text-primary fs-5"
                      style={{ float: 'right' }}
                      onClick={() => this.handleClickControl()}
                      disabled={additional && key === lastElementIndex ? isAddButtonDisabled : false}
                    >
                      <i className="bi bi-plus-circle" />
                    </IconButton>
                  ) : (
                    <IconButton
                      className="text-primary fs-5"
                      style={{ float: 'right' }}
                      onClick={() => this.removeContactItem(key)}>
                      <i className="bi bi-trash" />
                    </IconButton>
                  )}
                </Fieldset>
              </div>
            )}
          </Fieldset>
        </Fieldset>
      </Fieldset>
    );
  }

  componentDidMount() {
    this.updateData();
  }

  render() {
    const { state, props } = this;
    const additionalAddressList = state.dataSource.contactInfoList[0].additionalAddressList;

    return (
      <>
        <Form
          model={state.dataSource.contactInfoList[0]}
          onCollectValues={(name, value, errorMessages) => {
            this.onCollectValues(name, value, errorMessages);
          }}
          submit={this.handleSubmit}
          errors={state.errors}>
          <Fieldset className="row">
            <Fieldset className="col-sm-6">
              <Text
                name="corp_name"
                label="Corporation name"
                validations="required"
                errorMessages="Please, enter the corporation name!"
              />
              <TextArea name="desc" label="Description" attr={{ rows: 8 }} />
            </Fieldset>
            <Fieldset className="col-sm-6">
              {state.dataSource.fileListLoaded ? (
                <MultipleFileUpload
                  value={state.dataSource.fileUploadList}
                  onSetValue={(fileUploadList) => {
                    const dataSource = { ...state.dataSource };
                    dataSource.fileUploadList = fileUploadList;
                    this.setState({ dataSource });
                  }}
                  dir="corporate"
                  refid={'' + props.corporationId}
                />
              ) : null}
            </Fieldset>
          </Fieldset>
          {state.itemLoaded ? this.getContactInfoSection(false, 0) : null}
          {state.isAdditionalContact && (
            <Fieldset className="mb-4">
              {Object.keys(additionalAddressList).map((addressKey) => {
                const addressKeyNum = parseInt('' + addressKey);
                return (
                  <Fieldset key={'' + (1 + addressKeyNum)} className="grouped-fields-block">
                    {this.getContactInfoSection(true, addressKeyNum)}
                  </Fieldset>
                );
              })}
            </Fieldset>
          )}
          <div className="d-flex justify-content-between">
            <Link className="btn btn-danger" to={URL_CORPORATION}>
              Back
            </Link>
            <div className="d-flex gap-3">
              <Button
                variant="warning"
                text="Reset"
                onClick={this.reset.bind(this)}
              />
              <Button type="submit" text="Submit" />
            </div>
          </div>
        </Form>

        {!state.itemLoaded && <SpinnerFixed />}
      </>
    );
  }
}

export default Container.create(ContactTab);
