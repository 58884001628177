import { ReduceStore } from 'flux/utils';
import appDispatcher from 'dispatcher/AppDispatcher';

let buildActionName = function (name) {
  return 'reports/report-creator/procedure-code-count/' + name;
};

export let Actions = {
  LOAD_PROCEDURE_CODE_COUNT_LIST: buildActionName(
    'load-procedure-code-count-list'
  ),
};

class ProcedureCodeCountPageStore extends ReduceStore {
  getEmptyDiagram() {
    return [['CPT Code', 'Count']];
  }

  getInitialState() {
    return {
      procedureCodeCountList: [],
      procedureCodeCountDiagram: this.getEmptyDiagram(),
    };
  }

  reduce(state, action) {
    switch (action.type) {
      case Actions.LOAD_PROCEDURE_CODE_COUNT_LIST:
        this.getState().procedureCodeCountList = action.data.cntval || [];
        this.getState().procedureCodeCountDiagram =
          action.data.chartval || this.getEmptyDiagram();
        break;
      default:
        break;
    }
    return this.getState();
  }

  areEqual(one, two) {
    return false;
  }
}

const procedureCodeCountPageStore = new ProcedureCodeCountPageStore(
  appDispatcher
);
export default procedureCodeCountPageStore;
