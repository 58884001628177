import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropdownRegion } from 'components/project/dropdown/RegionDropdown';
import { DropDownJurisdiction } from 'components/project/dropdown/JurisdictionDropdown';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropdownProviderType } from 'components/project/dropdown/ProviderTypeDropdown';
import { DropdownDivision } from 'components/project/dropdown/Division';
import RadioOptions from 'components/project/common/RadioOptions';
import { Select } from 'components/form/select';
import { Input } from 'components/form/textField';
import { Radio } from 'components/form/radio';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import {
  storeFacility,
  filterDefaultValues,
  FilterType,
} from 'stores/_mobx/systemSetup/facility';

const optionSearchBy = [
  { label: 'Facility name', value: 'N' },
  { label: 'Location', value: 'R' },
];

const optionCompletenessOfData = [
  { label: 'All', value: '' },
  { label: 'Complete', value: 'Y' },
  { label: 'Incomplete', value: 'I' },
];

interface PropsType {
  fetching: boolean;
  isVisible: boolean;
  onFilterChange: (payload: FilterType) => void;
}

const Filter = ({ fetching, isVisible, onFilterChange }: PropsType) => {
  const { control, watch, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeFacility.filter,
  });

  const searchKey = watch('searchKey');

  const searchByName = searchKey === 'N';

  const handleClickReset = () => {
    reset(filterDefaultValues);
    onFilterChange(filterDefaultValues);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'searchKey') {
        const formData = {
          ...formValue,
          facilityName:
            formValue.searchKey === 'R' ? '' : formValue.facilityName,
          stateId: formValue.searchKey === 'N' ? 0 : formValue.stateId,
          regionId: formValue.searchKey === 'N' ? 0 : formValue.regionId,
        } as FilterType;

        reset(formData);
        onFilterChange(formData);
      } else {
        onFilterChange(formValue as FilterType);
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onFilterChange)}>
      <Select
        className="col-md-6 col-lg-4"
        name="searchKey"
        label="Search By"
        isClearable={false}
        control={control}
        options={optionSearchBy}
      />
      <Input
        name="facilityName"
        label="Facility Name"
        className={`col-md-6 col-lg-4${searchByName ? '' : ' d-none'}`}
        control={control}
      />

      <DropDownState
        name="stateId"
        label="State"
        className={`col-md-6 col-lg-4${searchByName ? ' d-none' : ''}`}
        control={control}
      />
      <DropdownRegion
        name="regionId"
        label="Region"
        className={`col-md-6 col-lg-4${searchByName ? ' d-none' : ''}`}
        control={control}
      />
      <div />
      <DropdownDivision
        name="division"
        label="Division"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <DropDownJurisdiction
        name="billingJurisdictionId"
        label="Billing Jurisdiction"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <DropDownPlaceOfService
        name="pos"
        label="Place of Service"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <DropdownProviderType
        name="providerTypeId"
        label="Provider Type"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <Radio
        name="status"
        label="Status"
        className="col-md-6 col-lg-4 col-lg-3 part-inline"
        options={RadioOptions.active}
        control={control}
      />
      <Radio
        name="completenessOfData"
        label="State"
        className="col-sm-auto part-inline"
        options={optionCompletenessOfData}
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
