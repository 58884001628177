import { makeObservable, observable, action, runInAction } from 'mobx';

import { TPagination } from 'components/grid/GridTypes';
import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import FilterNameAndState, {
  FilterType,
} from 'stores/_mobx/options/filterNameAndStatus';

export interface ComputerType {
  computer: string;
  description: string;
  mac_id: string;
  mark: string;
  model: string;
  refid: number;
  serial_no: string;
}

interface ComputerResponseType {
  computer: string;
  description: string;
  mac_id: string;
  mark: string;
  model: string;
  serial_no: string;
}

interface ComputerEditType extends ComputerResponseType {
  refid: number;
}

interface OptionType {
  label: string;
  value: number;
}

class Computer {
  fetching: boolean = false;
  fetchingOption: boolean = false;
  computersList: ComputerType[] = [];
  computersTotal: number = 0;
  computer: ComputerEditType | undefined = undefined;
  computerForDelete: number | null = null;
  options: OptionType[] = [];

  filter: FilterNameAndState = new FilterNameAndState();
  page: Pagination = new Pagination({ id: 'computer_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingOption: observable,
      computersList: observable,
      computersTotal: observable,
      computer: observable,
      computerForDelete: observable,
      options: observable,

      setFetching: action,
      setFetchingOption: action,
      setComputersList: action,
      setComputersTotal: action,
      setComputer: action,
      setComputerForDelete: action,
      clearComputerForDelete: action,
      clearComputer: action,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFetchingOption = (fetching: boolean) => {
    this.fetchingOption = fetching;
  };

  setComputersList(list: ComputerType[]) {
    this.computersList = list;
  }

  setComputersTotal(count: number) {
    this.computersTotal = count;
  }

  setComputer(computer?: ComputerEditType) {
    this.computer = computer;
  }

  setComputerForDelete(id: number) {
    this.computerForDelete = id;
  }

  clearComputerForDelete = () => {
    this.computerForDelete = null;
  };

  clearComputer = () => {
    this.computer = undefined;
  };

  async getComputersList({
    pagination,
    filter,
  }: {
    pagination: TPagination;
    filter: FilterType;
  }) {
    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.name,
      filter.state,
    ];
    try {
      const response = await apiRequest<'SE' | ComputerType[]>({
        url: 'vehicle.ComputerMaster.GetComputerList',
        data,
      });
      if (Array.isArray(response)) {
        const computers = response.map((pc) => ({
          ...pc,
          refid: Number(pc.refid),
        }));
        this.setComputersList(computers);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setComputersList([]);
    }
  }

  async getComputersCount(filter: FilterType) {
    try {
      const count = await apiRequest<'SE' | 'S' | number>({
        url: 'vehicle.ComputerMaster.ComputerCount',
        data: [filter.name, filter.state],
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setComputersTotal(total);
    } catch (e) {
      this.setComputersTotal(0);
    }
  }

  getComputersListMain = () => {
    this.setFetching(true);

    const props = {
      pagination: this.page.pagination,
      filter: this.filter.options,
    };

    const promiseList = this.getComputersList(props);

    const promiseCount = this.getComputersCount(props.filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getComputer(id: number | string) {
    this.setFetching(true);

    try {
      const response = await apiRequest<'SE' | [ComputerResponseType]>({
        url: 'vehicle.ComputerMaster.GetComputer',
        data: [id],
      });
      if (Array.isArray(response)) {
        const pc = { ...response[0], refid: Number(id) };

        this.setComputer(pc);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setComputer();
    } finally {
      this.setFetching(false);
    }
  }

  async addComputer(payload: Omit<ComputerEditType, 'refid'>) {
    const data = [
      payload.computer,
      payload.mac_id,
      payload.serial_no,
      payload.model,
      payload.mark,
      payload.description,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'vehicle.ComputerMaster.AddComputer',
        data,
      });
      if (result === 'S') {
        Notification.success('Computer was added successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'This MAC address already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateComputer(payload: ComputerEditType) {
    const data = [
      payload.refid,
      payload.computer,
      payload.mac_id,
      payload.serial_no,
      payload.model,
      payload.mark,
      payload.description,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'vehicle.ComputerMaster.EditComputer',
        data,
      });

      if (result === 'S') {
        Notification.success('Computer was updated successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'This MAC address already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  changeComputerStatus = async (ids: number[], state: string) => {
    this.setFetching(true);
    try {
      const response = await apiRequest({
        url: 'vehicle.ComputerMaster.changeStatus',
        data: [ids],
      });
      if (response === 'S') {
        const { length } = ids;

        Notification.success(
          `${length} Computer${length > 1 ? 's were' : 'was'} ${state}`
        );
        return true;
      } else Error('');
    } catch (e) {
      Notification.warning('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  };

  async deleteComputer() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'vehicle.ComputerMaster.DeleteComputer',
        data: [this.computerForDelete],
      });
      if (result === 'S') {
        Notification.success('Computer was deleted!');
        this.clearComputerForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getOptions() {
    if (this.fetchingOption) return Promise.resolve([]);

    this.setFetchingOption(true);

    try {
      const options = await apiRequest<OptionType[]>({
        url: 'vehicle.ComputerMaster.GetComputerDropDown',
      });

      runInAction(() => {
        this.fetchingOption = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOption = false;
        this.options = [];
      });
    }
  }
}

export const storeComputer = new Computer();
