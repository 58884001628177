import { useForm } from 'react-hook-form';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { TextEditor } from 'components/form/richTextEditor';
import { Input } from 'components/form/textField';
import resolver from './validation';

const initialValues: FormModel = {
  subject: '',
  body: '',
};

export interface FormModel {
  body: string;
  subject: string;
}

interface PropsType {
  title: string;
  defaultValues?: FormModel;
  onClose: () => void;
  onSubmit: (data: FormModel) => void;
}

const DialogNotificationMessage = ({
  title,
  defaultValues = initialValues,
  onClose,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting },
    reset,
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title={title} onClose={onClose} />
      <DialogBody className={isSubmitting ? 'on-ajax' : ''}>
        <form className="row" onSubmit={handleClickSubmit}>
          <Input
            name="subject"
            label="Subject"
            className="col-md-6"
            required
            control={control}
          />
          <TextEditor name="body" label="Message" required control={control} />
          <button type="submit" className="d-none" />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          text="Send"
          disabled={isSubmitting}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogNotificationMessage;
