import React from 'react';

import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'components/modal/dialog';
import { Button } from 'components/button';

interface Props {
  title?: string;
  isLoading?: boolean;
  children: React.ReactNode;
  onCancel: () => void;
  onApprove?: () => void;
}

const DialogConfirm = ({
  title = 'Confirm',
  isLoading,
  children,
  onCancel,
  onApprove,
}: Props) => (
  <Dialog handleClose={onCancel}>
    <DialogHeader title={title} />
    <DialogBody className={isLoading ? 'on-ajax' : ''}>{children}</DialogBody>
    <DialogFooter>
      <Button variant="warning" disabled={isLoading} onClick={onCancel}>
        No
      </Button>
      {onApprove ? (
        <Button disabled={isLoading} onClick={onApprove}>
          Yes
        </Button>
      ) : null}
    </DialogFooter>
  </Dialog>
);

export default DialogConfirm;
