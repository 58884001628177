import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeDesignation,
  DesignationEditType,
} from 'stores/_mobx/systemSetup/masterSetting/designation';
import { URL_DESIGNATION } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const DesignationEditPage = ({ match: { params }, history }: PropsType) => {
  const { fetching, designation } = storeDesignation;

  const handleSubmit = async (data: DesignationEditType) =>
    storeDesignation.updateDesignation(data).then((error) => {
      if (error) return error;
      history.push(URL_DESIGNATION);
    });

  useEffect(() => {
    if (!fetching) storeDesignation.getDesignation(Number(params.id));

    return storeDesignation.clearDesignation;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit User Type" />
      <Form
        backUrl={URL_DESIGNATION}
        defaultValues={designation}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(DesignationEditPage);
