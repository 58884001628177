import { useForm } from 'react-hook-form';

import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { DropdownProviderType } from 'components/project/dropdown/ProviderTypeDropdown';
import { DropDownOrderType } from 'components/project/dropdown/OrderType';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropDownCorporation } from 'components/project/dropdown/CorporateDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropdownRegion } from 'components/project/dropdown/RegionDropdown';
import { DropdownLocation } from 'components/project/dropdown/LocationDropdown';
import { DropdownDivision } from 'components/project/dropdown/Division';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import { InputCalendar } from 'components/form/inputCalendar';
import { DateRange } from 'components/form/dateRange';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import {
  storeArAging,
  filterDefaultValue,
  FilterType,
} from 'stores/_mobx/billingCodding/arAging';

const AR_AGING_OPTIONS = [
  { value: 'F', label: 'Facility AR Aging' },
  { value: 'P', label: 'Patient AR Aging' },
];

const AR_AGING_BALANCE_DUE_DAYS_OPTIONS = [
  { label: 'Current', value: '0to30' },
  { label: '31-60', value: '31to60' },
  { label: '61-90', value: '61to90' },
  { label: '91-120', value: '91to120' },
  { label: '120+', value: 'above' },
  { label: 'All', value: '' },
];

const commonClassName = 'col-md-6 col-lg-3';

interface PropsType {
  fetching: boolean;
  isVisible: boolean;
  onSubmit: (data: FilterType) => void;
}

const Filter = ({ fetching, isVisible, onSubmit }: PropsType) => {
  const { control, watch, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeArAging.filter,
  });

  const agingType = watch('arAgingType');

  const handleClickReset = () => {
    reset(filterDefaultValue);
    onSubmit(filterDefaultValue);
  };

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Radio
        name="arAgingType"
        className="col-sm-12 part-inline"
        label="AR Aging Type"
        options={AR_AGING_OPTIONS}
        control={control}
      />
      <DropDownFacility
        name="facilityId"
        label="Facility"
        className={commonClassName}
        isMulti
        isStyleDependsOnValue
        control={control}
      />
      <DropDownCorporation
        name="corporationId"
        label="Corporation"
        className={commonClassName}
        control={control}
      />
      {agingType === 'P' && (
        <>
          <Input
            name="lastName"
            label="Last name"
            className={commonClassName}
            control={control}
          />
          <Input
            name="firstName"
            label="First name"
            className={commonClassName}
            control={control}
          />
        </>
      )}
      <div />
      <DropdownProviderType
        name="providerType"
        label="Provider Type"
        className={commonClassName}
        control={control}
      />
      <DropDownOrderType
        name="orderType"
        label="Order Type"
        className={commonClassName}
        control={control}
      />
      <DropDownPlaceOfService
        name="pos"
        label="Place of Service"
        className={commonClassName}
        control={control}
      />
      <DropDownState
        name="stateId"
        label="State"
        isMulti
        isStyleDependsOnValue
        className={commonClassName}
        control={control}
      />
      <div />
      <DropdownRegion
        name="region"
        label="Region"
        isMulti
        isStyleDependsOnValue
        className={commonClassName}
        control={control}
      />
      <DropdownLocation
        name="location"
        label="Location"
        isMulti
        isStyleDependsOnValue
        className={commonClassName}
        control={control}
      />
      <DropdownDivision
        name="division"
        label="Division"
        className={commonClassName}
        control={control}
      />
      <div />
      <InputCalendar
        name="billedDateFrom"
        label="Billed Date From"
        className="col-md-6 col-lg-3 col-xl-2"
        control={control}
      />
      <InputCalendar
        name="billedDateTo"
        label="Billed Date To"
        className="col-md-6 col-lg-3 col-xl-2"
        control={control}
      />
      <Radio
        name="balanceDueDays"
        label="Balance Due"
        className="col-sm-12 col-lg-6 part-inline"
        options={AR_AGING_BALANCE_DUE_DAYS_OPTIONS}
        control={control}
      />
      <div />
      <DateRange
        nameFrom="invoiceDateFrom"
        nameTo="invoiceDateTo"
        nameRadio="period"
        labelFrom="Invoice Date From"
        labelTo="Invoice Date To"
        labelRadio="Invoice Period"
        radioClassName="col-sm-12 col-md-6 col-lg-auto"
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
