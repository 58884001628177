import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Exporter from 'components/project/ExporterNew';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import { SpinnerFixed } from 'components/spinner';
import { Button } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import { LayoutSideTitle } from 'components/layout';
import Filter from './components/Filter';

import UserProfileStore from 'stores/UserProfileStore';
import {
  storeFindingCoding,
  FilterType,
} from 'stores/_mobx/billingCodding/findingCoding';
import { FINDING_CODING as PAGE_ID } from 'constant/pagesId/billingCodding';

const FindingCodingPage = ({
  isDivisionEnabled,
}: PropsTypeWitDivisionSettings) => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    findingList,
    findingTotal,
    columns,
    summary,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeFindingCoding;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeFindingCoding.setFilter(filter);
    setPaginationToStart();
  };

  const handleExportPdf = (props: any) => {
    const payload = {
      ...props,
      isDivisionEnabled,
    };
    return storeFindingCoding.generatePdf(payload);
  };

  useEffect(() => {
    storeFindingCoding.getFindingsMain(isDivisionEnabled);
  }, [isDivisionEnabled, pagination]);

  useEffect(() => storeFindingCoding.clearFindings, []);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Findings">
        <Button
          text={isFilterVisible ? 'Hide filter' : 'Show filter'}
          variant="default"
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        isVisible={isFilterVisible}
        fetching={fetching}
        onSubmit={handleChangeFilter}
      />

      {columns.length ? (
        <Grid
          id="claim_management_finding_coding_grid"
          headerGroups
          columns={columns}
          onAjax={fetching}
          ref={gridRef}
          dataSource={findingList}
          dataSourceCount={findingTotal}
          pagination={pagination}
          onPaginationChange={setPagination}
          gridControlPanel={
            <GridControlButton
              title="Export"
              disabled={!findingList.length}
              onClick={handleToggleExport}
            />
          }
        />
      ) : (
        <SpinnerFixed />
      )}

      {isExportVisible && (
        <Exporter
          exporter="FindingCodingExporter"
          gridRef={gridRef}
          reportName={
            UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) || 'FindingCoding'
          }
          filter={storeFindingCoding.prepareFilterForExport(isDivisionEnabled)}
          columns={columns}
          dataSource={findingList}
          afterDataSource={summary}
          pdfExporter={handleExportPdf}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default withDivisionSettings(observer(FindingCodingPage));
