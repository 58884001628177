/**
 * Function modified name of file replacing symbols with _
 * @function fileNamePrettier
 * @param {(File | string | null)} fileRaw - File which will be send to BE
 * @returns {(File | string | null)}
 */
export const fileNamePrettier = (fileRaw: File | string | null) => {
  if (fileRaw && typeof fileRaw !== 'string') {
    const fileName = fileRaw.name.replace(/[\s?|\-:<>/%#&',]+/g, '_');

    const file = new File([fileRaw], fileName, { type: fileRaw.type });

    return file;
  }

  return fileRaw;
};
