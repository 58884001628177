import appDispatcher from 'dispatcher/AppDispatcher';
import {
  Actions,
  POrderTabStateStore,
} from 'stores/workflow/order/OrderTabStateStore';

export default class OrderTabStateActions {
  static unblockAllTabs() {
    appDispatcher.dispatch({
      type: Actions.UNBLOCK_ALL_TABS_ACTION,
    });
  }

  static initTabs() {
    appDispatcher.dispatch({
      type: Actions.INIT_TABS_ACTION,
    });
  }

  static unblockTab(tabNumber: number) {
    appDispatcher.dispatch({
      type: Actions.UNBLOCK_TAB_ACTION,
      tabNumber: tabNumber,
    } as POrderTabStateStore);
  }
}
