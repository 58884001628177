import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import ModifyGloveForm, { FormModel } from './components/Form';
import { URL_GLOW } from 'constant/path/workflow';

import { storeGlove } from 'stores/_mobx/workflow/equipmentInventory/glove';

interface MatchParam {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParam> {}

const GloveEditPage = ({ match: { params }, history }: PropsType) => {
  const { fetching, glove } = storeGlove;

  const handleSubmit = (data: FormModel) =>
    storeGlove.updateGlove(data as Required<FormModel>).then((response) => {
      if (response) {
        history.push(URL_GLOW);
      }
    });

  useEffect(() => {
    storeGlove.getGlove(params.id);
    return storeGlove.clearGlove;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Glove" />
      <ModifyGloveForm
        defaultValues={glove}
        backUrl={URL_GLOW}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(GloveEditPage);
