import React from 'react';
import { Container } from 'flux/utils';

import Select from 'components/form/input/Select';
import AbstractOrderOverview, {
  PAbstractOrderOverview,
  SAbstractOrderOverview,
  TFilter,
} from 'page/workflow/order/AbstractOrderOverview';
import OrderOverviewStore from 'stores/workflow/order/OrderOverviewStore';
import ReportManagerStore from 'stores/workflow/order/ReportManagerStore';
import UserProfileStore from 'stores/UserProfileStore';
import AccessUtils from 'utils/AccessUtils';
import { Grid, GridControlButton } from 'components/grid';
import WorkflowOrderColumnsDescriptor from 'page/workflow/order/WorkflowOrderColumnsDescriptor';
import { storeGrid } from 'stores/_mobx/grid';
import { URL_ORDER_ADD } from 'constant/path/workflow';
import { TRANSCRIPTION } from 'constant/pagesId/workflow';

const REPORTED = [
  { value: 'U', label: 'Pending Result' },
  { value: 'T', label: 'Pending Fax' },
];

const GRID_ID = 'report_manager_orders_grid';

export interface PReportManager extends PAbstractOrderOverview {}

export class SReportManager extends SAbstractOrderOverview {
  reported: 'U' | '' = 'U';
}

export class ReportManager extends AbstractOrderOverview<
  PReportManager,
  SReportManager
> {
  static TITLE = 'Report Manager';

  static getStores() {
    return [OrderOverviewStore, ReportManagerStore];
  }

  static calculateState(prevState: SReportManager) {
    if (!prevState) {
      const defaultFilter = {
        ...new TFilter(),
        period: 'A',
        dosStart: '',
        dosEnd: '',
      };

      prevState = {
        ...AbstractOrderOverview.getDefaultState(GRID_ID),
        reported: 'U',
        filter: storeGrid.getFilter(GRID_ID, defaultFilter) as TFilter,
      };
    }
    return {
      ...prevState,
      dataSource: OrderOverviewStore.getState().dataSource,
      dataSourceCount: OrderOverviewStore.getState().dataSourceCount,
    };
  }

  getGridId() {
    return GRID_ID;
  }

  getTitle() {
    return ReportManager.TITLE;
  }

  getShownColumns() {
    const out = Object.assign(
      [],
      WorkflowOrderColumnsDescriptor.ReportManagerInitial
    );
    if (this.state.reported === 'U') {
      out.push('addReport');
    } else {
      out.push('faxReport');
    }
    return out;
  }

  prepareFilter() {
    const filter = super.prepareFilter();
    filter.dosSearch = 'A';
    filter.status = 'IC';
    filter.currentUserType = UserProfileStore.getUserType();
    filter.recordsAccess = UserProfileStore.getRecordsAccess();
    filter.faxstatus = this.state.reported;
    return filter;
  }

  setAdditionalControls() {
    const options = AccessUtils.filter(
      REPORTED,
      TRANSCRIPTION.ADD_FAX,
      (v) => v.value
    );

    this.props.setAdditionalButtons(
      <Select
        name="reported"
        value={this.state.reported}
        options={options}
        noLabel
        className="no-margin btn-sized"
        onSetValue={(n, v) => this.changeReported(v)}
      />
    );
  }

  getControlPanel = () => (
    <>
      <GridControlButton title="Place Order" url={URL_ORDER_ADD} />
      <GridControlButton
        title="Complete"
        onClick={() => this.onCompleteOrder(this.state.selected)}
      />
    </>
  );

  renderOrderStatus(): React.ReactNode {
    return null;
  }

  changeReported(reported: 'U' | '') {
    const grid = this.refs.grid as Grid;
    if (grid) {
      // TODO: grid.state.shownColumns & grid.setState outside grid component use - rework this!!!
      const columns = new Map<string, boolean>(grid.state.shownColumns);
      if (this.state.reported === 'U') {
        columns.set('addReport', false);
        columns.delete('faxReport');
      } else {
        columns.set('faxReport', false);
        columns.delete('addReport');
      }
      grid.onShownColumnsChange(columns);
    }
    this.setState({ reported }, () => this.updateData());
  }

  getAllowedColumns() {
    return Object.assign(
      [],
      WorkflowOrderColumnsDescriptor.ReportManagerDefault
    );
  }

  onReset() {
    const filter = {
      ...new TFilter(),
      period: 'A',
      dosStart: '',
      dosEnd: '',
    };

    this.setState({ filter }, this.updateData);
  }
}

const container = Container.create(ReportManager);
export default container;
