import { Container } from 'flux/utils';

import AbstractPreInvoices, {
  PAbstractPreInvoices,
  SAbstractPreInvoices,
} from './AbstractPreInvoices';

const GRID_ID = 'per_diem_invoice_grid';

class PerDiemInvoices extends AbstractPreInvoices<
  PAbstractPreInvoices,
  SAbstractPreInvoices
> {
  getGridId() {
    return GRID_ID;
  }

  static getStores() {
    return AbstractPreInvoices.getStores();
  }

  static calculateState(prevState: SAbstractPreInvoices) {
    return AbstractPreInvoices.calculateState(prevState, GRID_ID);
  }

  getInvoiceType(): 'D' | 'F' | 'H' {
    return 'D';
  }

  isPerDiem() {
    return 'Y';
  }

  handleGeneratePerDiemInvoice = (facilityId: number) => {
    const state = {
      generateInvoices: true,
      order: {
        facilityid: facilityId,
        facilitynm: '',
        perdiem: 'Y',
      },
    };

    this.setState(state);
  };
}

export default Container.create(PerDiemInvoices);
