import { Button } from 'components/button';
import Dialog, { DialogBody, DialogFooter, DialogHeader } from './dialog';

interface PropsType {
  text?: string;
  title?: string;
  children?: React.ReactNode;
  onApprove: () => void;
}

const DialogAlert = ({
  children,
  text,
  title = 'Alert',
  onApprove,
}: PropsType) => (
  <Dialog>
    <DialogHeader title={title} />
    <DialogBody>{text || children}</DialogBody>
    <DialogFooter>
      <Button text="Ok" onClick={onApprove} />
    </DialogFooter>
  </Dialog>
);

export default DialogAlert;
