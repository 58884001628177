import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface AccessLogType {
  check_in_dt: string;
  check_out_dt: string;
  technisican: string;
}

interface ServiceLogType {
  date: string;
  equ_status: string;
  name: string;
  reason: string;
  time: string;
}

class EquipmentLogs {
  fetching: boolean = false;
  accessLogs: AccessLogType[] = [];
  statusLog: ServiceLogType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      accessLogs: observable,
      statusLog: observable,

      setFetching: action,
      setAccessLog: action,
      setStatusLog: action,
      clearAccessLog: action.bound,
      clearStatusLog: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setAccessLog(logs: AccessLogType[]) {
    this.accessLogs = logs;
  }

  setStatusLog(logs: ServiceLogType[]) {
    this.statusLog = logs;
  }

  clearStatusLog() {
    this.statusLog = [];
  }

  clearAccessLog() {
    this.accessLogs = [];
  }

  async getAccessLog(id: number) {
    this.setFetching(true);
    try {
      const response = await apiRequest<AccessLogType[]>({
        url: 'vehicle.EquipmentMaster.GetEquipmentLog',
        data: [id],
      });

      const logs = response.map((log) => ({
        ...log,
        check_in_dt: dateToLocalTimezone({ date: log.check_in_dt }),
        check_out_dt: dateToLocalTimezone({ date: log.check_out_dt }),
      }));

      this.setAccessLog(logs);
    } catch (e: any) {
      this.setAccessLog([]);
    } finally {
      this.setFetching(false);
    }
  }

  async getStatusLog(id: number) {
    this.setFetching(true);
    try {
      const response = await apiRequest<ServiceLogType[]>({
        url: 'vehicle.EquipmentMaster.GetEquipmentServiceLog',
        data: [id],
      });

      const logs = response.map((log) => {
        const [date, time] = dateToLocalTimezone({
          date: `${log.date} ${log.time}`,
        }).split(' ');

        return {
          ...log,
          date,
          time,
        };
      });

      this.setStatusLog(logs);
    } catch (e: any) {
      this.setStatusLog([]);
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeEquipmentLogs = new EquipmentLogs();
