import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import { DropdownIntegration } from 'components/project/dropdown/SendingApplicationDropdown';
import { DropDownStateShortName } from 'components/project/dropdown/StateShortName';
import RadioOptions from 'components/project/common/RadioOptions';
import { Button, IconButton } from 'components/button';
import { Grid } from 'components/grid';
import { Input, PureInput } from 'components/form/textField';
import { Checkbox, CheckboxGroup } from 'components/form/checkbox';
import MaskInput from 'components/form/maskInput';
import { Select } from 'components/form/select';
import { Textarea } from 'components/form/textarea';
import Tooltip from 'components/tooltip';
import Info from './Info';
import DialogFormImportFeeSchedule from '../DialogFormImportFeeSchedule';
import DialogFeeSchedule from '../DialogFeeSchedule';

import {
  storeRadiologyGroup,
  RadiologyGroupDetailsType,
  ImportFeePayloadType,
  DocumentType,
} from 'stores/_mobx/systemSetup/masterSetting/radiologyGroup';
import { storeDropdownCoordinator } from 'stores/_mobx/dropDown/coordinator';
import { BASE_URL_FILE_DIR } from 'constant/config';

const additionalFieldOption = {
  shouldDirty: true,
  shouldTouch: true,
};

interface PropsType {
  isNewGroup: boolean;
  control: Control<RadiologyGroupDetailsType>;
  setValue: UseFormSetValue<RadiologyGroupDetailsType>;
  getValues: UseFormGetValues<RadiologyGroupDetailsType>;
}

const InformationTab = ({
  isNewGroup,
  control,
  setValue,
  getValues,
}: PropsType) => {
  const { permission } = storeRadiologyGroup;

  const [isImportDialogVisible, toggleImportDialog] = useState<boolean>(false);

  const [isIntegrationEnabled, radiologyGroupDocuments, feeData] = useWatch({
    control,
    name: ['isIntegrationEnabled', 'radiologyGroupDocuments', 'feeData'],
  });

  const isFeeUploaded = !feeData;

  const handleToggleImportDialog = () => {
    toggleImportDialog((state) => !state);
  };

  const clearSelectedFeeSchedule = () => {
    setValue('feeData', null);
  };

  const handleToggleViewFeeDialog = () => {
    const [feeScheduleId, groupName] = getValues([
      'feeScheduleId',
      'radiologyGroupName',
    ]);

    storeRadiologyGroup.setFeeProps({
      feeScheduleId: feeScheduleId,
      radiologyGroupName: groupName,
    });
  };

  const handleImportFeeSchedule = (
    payload: Omit<ImportFeePayloadType, 'radiologyGroup'>
  ) => {
    setValue('feeData', payload, additionalFieldOption);
    handleToggleImportDialog();
  };

  const handleDeleteFile = (idx: number) => {
    const files = getValues('radiologyGroupDocuments');

    const updatedFiles = files.filter((file, index) => index !== idx);

    setValue('radiologyGroupDocuments', updatedFiles, additionalFieldOption);
  };

  const columns = useMemo(
    () => [
      {
        name: 'docName',
        title: 'File name',
      },
      {
        name: 'description',
        title: 'Description',
        render: (fileDescription: string, file: DocumentType, idx: number) => (
          <PureInput
            name="fileDescription"
            value={fileDescription}
            onChange={(value) =>
              setValue(
                `radiologyGroupDocuments.${idx}.description`,
                value,
                additionalFieldOption
              )
            }
          />
        ),
      },
      {
        name: 'view',
        title: 'View',
        className: 'text-center width-50',
        render: (rowItem: any, file: DocumentType) => {
          const path = file.id
            ? `${BASE_URL_FILE_DIR}doc_img/radiology/${file.docName}`
            : URL.createObjectURL(file.file);

          return file.docName ? (
            <a
              target="_blank"
              rel="noreferrer"
              className="text-primary fs-5"
              title={rowItem}
              href={path}>
              <i className="bi bi-eye-fill" />
            </a>
          ) : null;
        },
      },
      {
        name: 'remove',
        title: 'Remove',
        className: 'width-50',
        render: (...params: [undefined, DocumentType, number]) => (
          <IconButton
            title="Delete file"
            className="text-primary"
            onClick={() => handleDeleteFile(params[2])}>
            <i className="bi bi-trash" />
          </IconButton>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleFileSelected = ({
    currentTarget,
  }: React.SyntheticEvent<HTMLInputElement>) => {
    const file = currentTarget.files?.[0];

    if (file) {
      const fileData = {
        description: '',
        lastModified: '',
        docName: file.name,
        file,
      };

      setValue(
        `radiologyGroupDocuments.${radiologyGroupDocuments.length}`,
        fileData,
        additionalFieldOption
      );

      currentTarget.value = null;
    }
  };

  return (
    <>
      <div className="fw-bold fs-5 mb-5">
        <Tooltip title="Feature Info" theme="warning" body={<Info />}>
          Information <i className="text-warning bi bi-info-circle" />
        </Tooltip>
      </div>
      <Input
        name="radiologyGroupName"
        label="Group name"
        className="col-md-6"
        required
        control={control}
      />
      <div />
      {permission.changeIntegration && (
        <>
          <Checkbox
            name="isIntegrationEnabled"
            label="Integrations Active"
            className="col-md-6"
            control={control}
          />
          <DropdownIntegration
            name="radiologyGroupIntegrationIds"
            label="Integration Name"
            isMulti
            required={isIntegrationEnabled}
            disabled={!isIntegrationEnabled}
            control={control}
            className="col-md-6"
          />
        </>
      )}
      <Input
        name="address"
        label="Address primary"
        className="col-md-6"
        required
        control={control}
      />
      <Input
        name="address2"
        label="Address secondary"
        className="col-md-6"
        control={control}
      />
      <DropDownStateShortName
        name="state"
        label="State"
        className="col-md-6"
        required
        control={control}
      />
      <Input
        name="city"
        label="City"
        className="col-md-6"
        required
        control={control}
      />
      <MaskInput
        name="zipcode"
        label="Zip Code"
        className="col-md-6"
        format="#####-####"
        required
        control={control}
      />
      <Input
        name="taxId"
        label="Tax ID"
        className="col-md-6"
        control={control}
      />
      <Select
        label="Radiology Admin"
        name="userCoordinators"
        className="col-md-6"
        isMulti
        options={storeDropdownCoordinator.options}
        control={control}
      />
      <Select
        label="Radiology Group Billing"
        name="radiologyGroupBillingUsers"
        className="col-md-6"
        isMulti
        options={storeDropdownCoordinator.optionsBillingUsers}
        control={control}
      />
      <Input
        name="dicomRoutingTag"
        label="DICOM Routing Tag (for PDF upload in Visit Notes)"
        className="col-md-6"
        control={control}
      />
      <CheckboxGroup
        name="radiologyType"
        label="Radiology Type"
        className="col-sm-12 col-md-auto col-lg-6 part-inline mb-5"
        options={RadioOptions.radiologyType}
        required
        control={control}
      />

      {permission.importFee && (
        <div className="col-xl-4 mb-5">
          <p className="fw-bold">Fee schedule: </p>
          <div className="d-flex align-items-center gap-3">
            {!isNewGroup && (
              <Button
                text="View uploaded"
                disabled={!isFeeUploaded}
                className="flex-shrink-0"
                onClick={handleToggleViewFeeDialog}
              />
            )}

            <Button
              variant="warning"
              text="Upload"
              className="flex-shrink-0"
              onClick={handleToggleImportDialog}
            />
            {feeData ? (
              <div className="d-flex d-flex align-items-center align-self-center">
                <i className="bi bi-filetype-csv text-primary fs-5" />
                <span className="ms-2 me-4 text-break">{feeData.file?.name}</span>
                <IconButton
                  className="text-danger fs-5"
                  onClick={clearSelectedFeeSchedule}>
                  <i className="bi bi-trash" />
                </IconButton>
              </div>
            ) : null}
          </div>
        </div>
      )}

      <Grid
        wrapperClass="col-xl-8"
        disablePagination
        gridControlPanelBottom={null}
        columns={columns}
        dataSource={radiologyGroupDocuments}
        gridControlPanelTop={
          <label className="control-btn">
            Upload Document
            <input
              className="d-none"
              type="file"
              onChange={handleFileSelected}
            />
          </label>
        }
      />

      <Textarea
        name="radiologyGroupDesc"
        label="Description"
        rows={3}
        control={control}
      />

      {isImportDialogVisible && (
        <DialogFormImportFeeSchedule
          inProgress={storeRadiologyGroup.uploading}
          onSubmit={handleImportFeeSchedule}
          onClose={handleToggleImportDialog}
        />
      )}

      {storeRadiologyGroup.feeProps && (
        <DialogFeeSchedule onClose={storeRadiologyGroup.clearFee} />
      )}
    </>
  );
};

export default observer(InformationTab);
