import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Button } from 'components/button';

import { storeService } from 'stores/_mobx/service';
import clsx from 'clsx';

let timer: number = 0;

const AppVersion = () => {
  const [hide, setHide] = useState(false);

  const {
    fetching,
    frontendVersion,
    backendVersion,
    isNewVersionAvailable,
    showDialogNewVersion,
  } = storeService;

  const wrapperClassName = clsx('versioning', {
    visible: !hide && showDialogNewVersion,
    hide,
  });

  const handleClickCancel = () => {
    window.clearTimeout(timer);
    storeService.closeDialogNewVersion();
    setHide(true);
    timer = window.setTimeout(() => setHide(false), 300);
  };

  useEffect(() => {
    storeService.getAppVersions();
    return window.clearTimeout(timer);
  }, []);

  return (
    !fetching && (
      <div className={wrapperClassName}>
        <div className="versioning_content">
          <div className="versioning_title">App versions:</div>
          {isNewVersionAvailable && (
            <div className="mb-3 versioning_new-info">
              <div
                className="fw-bold text-secondary"
                style={{ fontSize: '1.1rem' }}>
                A new version is available!
              </div>
              <div className="mb-3 text-danger fw-bold">
                Finish any unsaved work before installing.
              </div>
              <div className="d-flex gap-2">
                <Button
                  text="INSTALL"
                  variant="success"
                  className="w-100"
                  style={{ fontSize: '10px' }}
                  onClick={storeService.updateToLatestVersion}
                />
                <Button
                  text="CANCEL"
                  variant="warning"
                  className="w-100"
                  style={{ fontSize: '10px' }}
                  onClick={handleClickCancel}
                />
              </div>
            </div>
          )}
          <div>{`${frontendVersion} - Frontend version`}</div>
          <div>{`${backendVersion} - Backend version`}</div>
        </div>
        {isNewVersionAvailable && (
          <span className="versioning_new-icon" title="New version available">
            <i className="bi bi-exclamation-lg" />
          </span>
        )}
      </div>
    )
  );
};

export default observer(AppVersion);
