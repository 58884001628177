import { useEffect, useMemo, useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { parseFromTimeZone } from 'date-fns-timezone';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button, IconButton } from 'components/button';
import Notification from 'components/modal/Notification';
import { DropdownRadiology } from 'components/project/dropdown/RadiologyDropdown';
import DropdownRejectReasons from 'components/project/dropdown/RejectReason';
import { DropdownVisitSchedule } from 'components/project/dropdown/VisitScheduleDropdown';
import { OPTIONS_MEDIA as mediaTypeOptions } from 'constant/formComponentOptions';
import CPTCodeModal from 'page/workflow/order/order/CPTCodeModal';
import DialogSelectPhysician from 'page/workflow/order/order/dialogSelectPhysician';
import DialogIcdCode from 'page/components/DialogIcdCode';
import { Input } from 'components/form/textField';
import { Textarea } from 'components/form/textarea';
import { InputCalendar, InputTime } from 'components/form/inputCalendar';
import { Select } from 'components/form/select';
import { FileField } from 'components/form/file';
import validation from './validation';
import { reducer, initialState, ActionType } from './state';
import { CptCodes, IcdCodes } from './Codes';
import PatientDetailsBlock from './PatientDetailsBlock';

import TechnicianActions from 'actions/project/TechnicianActions';
import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';
import {
  storeAssignedOrders,
  VisitNotesType,
  IcdCodeType,
} from 'stores/_mobx/workflow/order/assignedOrders';
import { PhysicianType } from 'stores/_mobx/clinicianManager/physician';
import mapCptCode, { CptCodeType } from 'utils/cptCode';
import { dateToLocalTimezone, getAppCurrentTimeString } from 'utils/DateUtils';

export interface FormModel {
  arrivalTime: string | null;
  arrivalDate: string;
  media_type: string;
  submittedImages: number | '';
  visitSchedule: string;
  radiologyGroup: number;
  rejectedImages: number | '';
  rejectedReasonId: number | '';
  groupAffiliation: string;
  modality: string;
  modalityId: number;
  assistedBy: string;
  additionalClinicalNotes: string;
  phyid: number;
  physicianname: string;
  cptCodes: CptCodeType[];
  icdCodes: IcdCodeType[];
  orderId: number;
  techId: number;
  dos: string;
  pacsFile: File | null;
}

interface PropsType {
  defaultValues?: FormModel;
  order: TSignOrderItem;
  fetching: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const initialValues: FormModel = {
  arrivalTime: '',
  arrivalDate: dateToLocalTimezone({ date: new Date(), dateOnly: true }),
  submittedImages: '',
  visitSchedule: '',
  radiologyGroup: 0,
  rejectedImages: '',
  rejectedReasonId: 0,
  assistedBy: '',
  additionalClinicalNotes: '',
  groupAffiliation: '',
  media_type: '',
  modality: '',
  modalityId: 0,
  phyid: 0,
  physicianname: '',
  cptCodes: [],
  icdCodes: [],
  orderId: 0,
  techId: 0,
  dos: '',
  pacsFile: null,
};

const className = 'col-sm-4';

const DialogVisitNotes = ({
  defaultValues = initialValues,
  order,
  fetching,
  onClose,
  onSubmit,
}: PropsType) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const orderMediaIsRequired = order.order_media_type === 'N';

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    formState: { isDirty, isSubmitting, isSubmitted, errors },
  } = useForm<FormModel>({
    defaultValues,
    resolver: validation({
      orderMediaIsRequired,
    }),
  });

  const maxDate = useMemo(() => {
    const date = `${getAppCurrentTimeString().replace(',', '')}:00`;

    const currentDate = parseFromTimeZone(date, 'MM/DD/YYYY hh:mm:ss', {
      timeZone: 'America/Chicago',
    });

    return currentDate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitNotes = async ({
    cptCodes,
    icdCodes,
    ...model
  }: FormModel) => {
    // @ts-ignore
    const payload: VisitNotesType = {
      ...model,
      codes: {
        cpt: mapCptCode(cptCodes),
        icd: icdCodes,
      },
    };

    return storeAssignedOrders.saveVisitNotes(payload).then((response) => {
      if (response) {
        onSubmit();
      } else {
        onClose();
      }
    });
  };

  const handlePayloadValidation = async (data: FormModel) => {
    try {
      const schedule = data.visitSchedule;
      const visitsPromise = schedule?.toLowerCase().startsWith('visit')
        ? TechnicianActions.loadVisits(order.refid).then((response) => {
            const filteredSchedules = response.forbidden.filter(
              (forbidItem) => schedule === forbidItem.visit
            );
            const crossedSchedules: string[] = filteredSchedules.reduce(
              (prev, { facilityName }) =>
                prev.includes(facilityName) ? prev : [...prev, facilityName],
              []
            );
            return crossedSchedules.join(', ');
          })
        : Promise.resolve('');

      const hasSameVisit = await visitsPromise.then((response: string) => {
        if (response) {
          dispatch({ type: ActionType.SetSameVisit, payload: response });
        }
        return Promise.resolve(response);
      });

      return hasSameVisit ? Promise.resolve() : handleSubmitNotes(data);
    } catch (e: any) {
      Notification.danger('Error occurred!');
      onClose();
    }
  };

  const handleClickSubmit = () => {
    handleSubmit(handlePayloadValidation)();
  };

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleToggleDialogSelectPhysician = () => {
    dispatch({ type: ActionType.TogglePhysician });
  };

  const handleSelectPhysician = (response: PhysicianType) => {
    setValue('phyid', Number(response.id), { shouldDirty: true });
    setValue('physicianname', `${response.first_name} ${response.last_name}`, {
      shouldDirty: true,
    });
    setValue('groupAffiliation', response.group_affiliation_name, {
      shouldDirty: true,
    });
    handleToggleDialogSelectPhysician();
  };

  const handleToggleCptCodesDialog = () => {
    dispatch({ type: ActionType.ToggleCptCode });
  };

  const handleSelectCptCode = (cptCodes: CptCodeType[] | null) => {
    if (cptCodes) {
      setValue('cptCodes', cptCodes, {
        shouldDirty: true,
      });
    }
    handleToggleCptCodesDialog();
  };

  const handleToggleIcdCodesDialog = () => {
    dispatch({ type: ActionType.ToggleIcdCode });
  };

  const handleSelectIcdCode = (icdCodes: IcdCodeType[]) => {
    if (icdCodes) {
      setValue('icdCodes', icdCodes, {
        shouldDirty: true,
      });
    }
    handleToggleIcdCodesDialog();
  };

  const handleCloseDialogSameVisit = () => {
    dispatch({ type: ActionType.SetSameVisit, payload: '' });
  };

  const handleConfirmDialogSameVisit = () => {
    handleCloseDialogSameVisit();
    handleSubmit(handleSubmitNotes)();
  };

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (!isSubmitted) return;
      if (name === 'radiologyGroup') {
        trigger('pacsFile');
      } else if (name === 'arrivalDate' || name === 'arrivalTime') {
        // @ts-ignore
        trigger('arrivalDateTime');
        name === 'arrivalDate' && trigger('arrivalTime');
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, watch]);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <>
      <Dialog size="large" handleClose={onClose}>
        <DialogHeader title="Visit Notes" onClose={onClose} />

        <DialogBody className={fetching || isSubmitting ? 'on-ajax' : ''}>
          <form onSubmit={handleClickSubmit}>
            <PatientDetailsBlock order={order} />

            <legend>Arrival Details</legend>
            <div className="row">
              <InputTime
                name="arrivalTime"
                className="col-sm-3"
                label="Arrival Time"
                required
                control={control}
              />
              <InputCalendar
                name="arrivalDate"
                className="col-sm-3"
                label="Arrival Date"
                required
                maxDate={maxDate}
                control={control}
              />
              <div className="col-sm-3 mt-5">(Central)</div>
              {orderMediaIsRequired && (
                <Select
                  name="media_type"
                  className="col-sm-3"
                  label="Order Media Type"
                  required
                  options={mediaTypeOptions}
                  control={control}
                />
              )}
              {/* @ts-ignore-block */}
              {errors?.arrivalDateTime?.message ? (
                <div
                  className="col-sm-12 mb-4 text-danger"
                  style={{ marginTop: '-10px' }}>
                  {/* @ts-ignore */}
                  {errors.arrivalDateTime.message}
                </div>
              ) : null}
            </div>

            <legend>Visit Details</legend>
            <div className="row mb-5">
              <Input
                name="submittedImages"
                type="number"
                label="Submitted Images"
                required
                min={0}
                className={className}
                control={control}
              />
              <DropdownVisitSchedule
                name="visitSchedule"
                label="Visit Schedule"
                required
                className={className}
                control={control}
              />
              <DropdownRadiology
                name="radiologyGroup"
                label="Radiology Group"
                required
                className={className}
                control={control}
              />
              <Input
                name="rejectedImages"
                type="number"
                label="Rejected Images"
                min={0}
                className={className}
                control={control}
              />
              <DropdownRejectReasons
                name="rejectedReasonId"
                label="Reject Reason"
                className={className}
                required={Boolean(watch('rejectedImages'))}
                control={control}
              />

              <Input
                name="groupAffiliation"
                label="Group Affiliation"
                className={className}
                disabled
                control={control}
              />

              <div className={className}>
                <div>
                  <b>Modality</b>
                </div>
                <div>{order.examtype}</div>
              </div>

              <Input
                name="assistedBy"
                label="Assisted By"
                className={className}
                control={control}
              />
              <Textarea
                name="additionalClinicalNotes"
                label="Additional Clinical Notes"
                className={className}
                control={control}
                rows={1}
              />

              <legend>Results upload</legend>
              <FileField
                name="pacsFile"
                label="Send Results to PACS (PDF only)"
                accept="application/pdf"
                className="col-sm-12 col-md-4"
                control={control}
              />
              <i className="text-secondary pt-5 col-sm-12 col-md-8">
                *A DICOM tag is required for the intended Radiology Group, it
                can be added in the Radiology Group edit page
              </i>
            </div>

            <div className="mb-5">
              <legend>Ordering Physician</legend>
              <div className="d-flex gap-3">
                <IconButton
                  className="text-secondary"
                  onClick={handleToggleDialogSelectPhysician}>
                  <i className="bi bi-pencil" />
                </IconButton>
                <strong>Physician:</strong>
                <span>{watch('physicianname')}</span>
              </div>
            </div>

            <>
              <legend>Code Details</legend>
              <div className="d-flex gap-3">
                <IconButton
                  className="text-secondary"
                  onClick={handleToggleCptCodesDialog}>
                  <i className="bi bi-pencil" />
                </IconButton>
                <strong>CPT:</strong>
              </div>
              <CptCodes codes={watch('cptCodes') || []} />

              <div className="d-flex gap-3">
                <IconButton
                  className="text-secondary"
                  onClick={handleToggleIcdCodesDialog}>
                  <i className="bi bi-pencil" />
                </IconButton>
                <strong>Diagnosis:</strong>
              </div>
              <IcdCodes codes={watch('icdCodes') || []} />
            </>
            <button type="submit" className="d-none" />
          </form>
        </DialogBody>

        <DialogFooter>
          <Button
            variant="warning"
            text="Reset"
            onClick={handleReset}
            disabled={isSubmitting}
          />
          <Button
            type="submit"
            text="Save"
            onClick={handleClickSubmit}
            disabled={!isDirty || isSubmitting}
          />
        </DialogFooter>
      </Dialog>

      {state.isOrdSelectPhysicianOpen && (
        <DialogSelectPhysician
          onCancel={handleToggleDialogSelectPhysician}
          onSelect={handleSelectPhysician}
        />
      )}

      {state.isCptCodeModalOpen && (
        <CPTCodeModal
          modalityId={order.examtypeid}
          dataSource={watch('cptCodes') || []}
          callback={handleSelectCptCode}
        />
      )}

      {state.isIcdCodeModalOpen && (
        <DialogIcdCode
          smoking="N"
          symptomatic="N"
          // @ts-ignore
          callback={handleSelectIcdCode}
          // @ts-ignore
          selectedCodes={(watch('icdCodes') || []).map((code) => ({
            ...code,
            refid: Number(code.refid),
          }))}
        />
      )}

      {state.sameVisit ? (
        <Dialog>
          <DialogHeader title="Confirm" />
          <DialogBody>
            {`${watch('visitSchedule')} is already used at the facility ${
              state.sameVisit
            }. Do you want to continue with this visit or select a new one?`}
          </DialogBody>
          <DialogFooter>
            <Button
              text="New Visit"
              variant="warning"
              onClick={handleCloseDialogSameVisit}
            />
            <Button text="Continue" onClick={handleConfirmDialogSameVisit} />
          </DialogFooter>
        </Dialog>
      ) : null}
    </>
  );
};

export default DialogVisitNotes;
