import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone } from 'utils/DateUtils';

import { BASE_URL_FILE_DIR } from 'constant/config';

interface CorporationResponseType {
  address: string;
  alternate_email: string;
  alternate_phone: string;
  city: string;
  contact_firstname: string;
  contact_lastname: string;
  contact_middlename: string;
  contact_title: string;
  email: string;
  enable: boolean;
  facilitynames: string[];
  fax: string;
  groupdesc: string;
  groupname: string;
  phone: string;
  refid: number;
  state: string;
  website: string;
  zipcode: string;
}

export interface CorporationType
  extends Omit<CorporationResponseType, 'facilitynames'> {
  facilityCount: number;
  facilities: { name: string }[];
}

interface DocumentResponseType {
  filenm: string;
  filestatus: string;
  remvImg: string;
  uploaded_dt: string;
}

interface DocumentType {
  fileName: string;
  uploaded: string;
  filePath: string;
}

interface ParamsType {
  id: number;
  name: string;
}

interface ParamsFacilityType {
  facilities: { name: string }[];
  corporationName: string;
}

class Corporation {
  fetching: boolean = false;
  fetchingDocuments: boolean = false;
  corporationList: CorporationType[] = [];
  corporationTotal: number = 0;
  paramsForDelete: ParamsType | null = null;
  paramsForDocuments: ParamsType | null = null;
  paramsFacilities: ParamsFacilityType | null = null;
  documents: DocumentType[] = [];
  filter: string = '';
  page: Pagination = new Pagination({ id: 'corporations_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingDocuments: observable,
      corporationList: observable,
      corporationTotal: observable,
      filter: observable,
      paramsForDelete: observable,
      paramsForDocuments: observable,
      paramsFacilities: observable,
      documents: observable,

      setFetching: action,
      setFetchingDocuments: action,
      setContactList: action,
      setContactCount: action,
      setFilter: action,
      setDocuments: action,
      setParamsForDelete: action,
      setParamsForDocuments: action,
      setParamsFacilities: action,
      clearDocuments: action.bound,
      clearParamsForDelete: action.bound,
      clearParamsForDocuments: action.bound,
      clearParamsFacilities: action.bound,
      clearStore: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingDocuments(fetching: boolean) {
    this.fetchingDocuments = fetching;
  }

  setContactList(corporations: CorporationType[]) {
    this.corporationList = corporations;
  }

  setContactCount(count: number) {
    this.corporationTotal = count;
  }

  setFilter(corporationName: string) {
    this.filter = corporationName;
  }

  setDocuments(docs: DocumentType[]) {
    this.documents = docs;
  }

  setParamsForDelete(params: ParamsType) {
    this.paramsForDelete = params;
  }

  setParamsForDocuments(params: ParamsType) {
    this.paramsForDocuments = params;
  }

  setParamsFacilities(params: ParamsFacilityType) {
    this.paramsFacilities = params;
  }

  clearDocuments() {
    this.documents = [];
  }

  clearParamsForDelete() {
    this.paramsForDelete = null;
  }

  clearParamsForDocuments() {
    this.paramsForDocuments = null;
  }

  clearParamsFacilities() {
    this.paramsFacilities = null;
  }

  clearStore() {
    this.clearParamsForDelete();
    this.clearParamsForDocuments();
    this.clearParamsFacilities();
  }

  async getCorporationCount(filter: string) {
    try {
      const count = await apiRequest<string>({
        url: 'groupmanager.CorporateGroup.GetGroupCount',
        data: [filter],
      });

      this.setContactCount(Number(count) || 0);
    } catch (e: any) {
      this.setContactCount(0);
    }
  }

  async getCorporationList(filter: string) {
    const { pagination } = this.page;
    try {
      const response = await apiRequest<CorporationResponseType[]>({
        url: 'groupmanager.CorporateGroup.GetAllGroup',
        data: [pagination.skip, pagination.pageSize, filter],
      });

      const corporations = response.map(
        ({ facilitynames, ...corp }) => {
          const facilities = facilitynames.map(
            (name) => ({ name })
          );

          return {
            ...corp,
            facilities,
            facilityCount: facilitynames.length || 0,
            refid: Number(corp.refid),
          };
        }
      );

      this.setContactList(corporations);
    } catch (e: any) {
      this.setContactList([]);
    }
  }

  getCorporationListMain = (corpName: string = this.filter) => {
    this.setFetching(true);

    const promiseCount = this.getCorporationCount(corpName);

    const promiseRegions = this.getCorporationList(corpName);

    Promise.allSettled([promiseCount, promiseRegions]).finally(() => {
      this.setFetching(false);
    });
  };

  async deleteCorporation() {
    if (this.fetching) return false;

    const corporation = this.corporationList.find(
      (corp) => corp.refid === this.paramsForDelete.id
    );

    try {
      if (corporation?.facilityCount) {
        Notification.danger(
          "Corporation cannot be deleted. There's a Facility linked to Corporation!"
        );
        return false;
      }

      this.setFetching(true);

      const response = await apiRequest<'S' | 'E'>({
        url: 'groupmanager.CorporateGroup.DeleteGroup',
        data: [this.paramsForDelete.id],
      });

      if (response === 'S') {
        Notification.success('Corporation deleted successfully!');
        return true;
      }
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    } finally {
      this.clearParamsForDelete();
    }
  }

  async getDocuments(params: ParamsType) {
    this.setFetchingDocuments(true);
    this.setParamsForDocuments(params);
    try {
      const { file } = await apiRequest<{ file: DocumentResponseType[] }>({
        url: 'facility.FacilityMaster.GetDocss',
        data: [params.id],
      });

      const documents = file.map((doc) => ({
        fileName: doc.filenm,
        uploaded: dateToLocalTimezone({ date: doc.uploaded_dt }),
        filePath: `${BASE_URL_FILE_DIR}doc_img/corporate/${doc.filenm}`,
      }));

      this.setDocuments(documents);
    } catch (e: any) {
      this.setDocuments([]);
    } finally {
      this.setFetchingDocuments(false);
    }
  }
}

export const storeCorporation = new Corporation();
