import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';

/**
  @prop {string} 'data-testid' - data attribute for testing libraries
  If data-testid not presented, then prefix for data-testid attribute will generated with 'name' property
  '{dataTestId}-label' - data attribute for the label
  '{dataTestId}-field' - data attribute for the input field
  '{dataTestId}-error' - data attribute for the error block
*/

const exceptionKey = ['e', 'E', '+'];

export interface PureInputProps {
  name?: string;
  value?: string | number;
  type?: 'text' | 'password' | 'number' | 'time' | 'color';
  label?: React.ReactNode;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  autoFocus?: boolean;
  min?: number;
  max?: number;
  maxLength?: number;
  minLength?: number;
  step?: number;
  errorMessage?: string;
  pattern?: string;
  children?: React.ReactNode;
  isChildrenPrefix?: boolean;
  isClearable?: boolean;
  'data-testid'?: string;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  onFocus?: (e: React.SyntheticEvent) => void;
  onKeyPress?: (e: React.KeyboardEvent<object>) => void;
}

const PureInput = React.forwardRef<HTMLInputElement, PureInputProps>(
  (
    {
      name,
      required = false,
      disabled = false,
      type = 'text',
      label = '',
      className,
      errorMessage,
      children,
      isChildrenPrefix,
      isClearable = true,
      'data-testid': dataTestId,
      onBlur,
      onChange,
      ...rest
    },
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>();

    const inputRef = ref || innerRef;

    const cn = clsx(
      'formfield-holder',
      'formfield-text',
      { required },
      className
    );

    const testAttr = dataTestId || name || 'input';

    const displayClearIndicator =
      isClearable && !disabled && (!children || (children && isChildrenPrefix));

    const handleClear = () => {
      if (onChange && rest.value !== '') onChange('');
    };

    const handleBlockInput = (e: React.SyntheticEvent) => {
      // @ts-ignore
      const currentKey = e.key as string;

      if (type === 'number') {
        const shouldPreventInput =
          exceptionKey.includes(currentKey) ||
          (rest.value && currentKey === '-');

        if (shouldPreventInput) e.preventDefault();
      }
    };

    const handleBlur = (e: React.SyntheticEvent) => {
      if (onBlur) {
        const { value } = e.currentTarget as HTMLInputElement;
        onBlur(value);
      }
    };

    const handleChange = (e: React.SyntheticEvent) => {
      const currentValue = (e.target as HTMLInputElement).value;

      const typeIsNumber = type === 'number';

      if (!onChange) return;

      if (typeIsNumber) {
        const formattedValue =
          currentValue === '' ? currentValue : Number(currentValue);
        let result = formattedValue;

        if (rest.min !== undefined) {
          result = result >= rest.min ? result : rest.min;
        }

        if (rest.max !== undefined) {
          result = result >= rest.max ? rest.max : result;
        }

        if (rest.value !== result) {
          onChange(result);
        }
      } else {
        onChange(currentValue);
      }
    };

    useEffect(() => {
      // @ts-ignore
      if (rest.autoFocus) inputRef?.current?.focus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className={cn}>
        {label ? (
          <label
            htmlFor={name}
            className="form-label"
            data-testid={`${testAttr}-label`}>
            {label}
          </label>
        ) : null}
        <div className="input-group">
          {isChildrenPrefix ? children : null}
          <input
            {...rest}
            onKeyDown={handleBlockInput}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
            type={type}
            id={name}
            ref={inputRef}
            className={`form-control${
              displayClearIndicator ? ' clearable' : ''
            }`}
            data-testid={`${testAttr}-field`}
          />
          {displayClearIndicator && (
            <span className="field-clearIndicator" onClick={handleClear}>
              <i className="bi bi-x-lg"></i>
            </span>
          )}
          {isChildrenPrefix ? null : children}
        </div>
        {errorMessage && (
          <div className="error" data-testid={`${testAttr}-error`}>
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

export default PureInput;
