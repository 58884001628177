import { CPTCodeType as CPTCodeMappedType } from 'stores/_mobx/workflow/order/assignedOrders';

export interface CptCodeType {
  accession_number: string;
  cpt_code: string;
  cpt_desc: string;
  cpt_description: string;
  external_accession_number: string;
  external_placer_order_number: string | null;
  extremity: string;
  flag_need_addendum: boolean;
  need_addendum_from_radiology_group_id: number;
  order_initial_cpt_refid: number;
  refid: string;
  session_no: string;
}

export interface CptCodeExtendedType extends CptCodeType {
  modifier_code: string;
  cpt_price: string;
}

export const isQrCpt = (cptCode?: string | null) => /^(r|q)/i.test(cptCode);

const mapCptCode = (
  cptCodes: CptCodeType[] | CptCodeExtendedType[]
): CPTCodeMappedType[] =>
  cptCodes.map((item) => ({
    refid: item.refid,
    modifier: 'modifier_code' in item ? item.modifier_code : '',
    price: 'cpt_price' in item ? item.cpt_price : 0,
    editvisible: true,
    data: item.cpt_code,
    desc: item.cpt_description,
    extremity: '',
    cpt_desc: item.cpt_desc || item.cpt_description,
    cpt_description: item.cpt_desc || item.cpt_description,
    addvisible: true,
    accession_number: item.accession_number,
    external_accession_number: item.external_accession_number || '',
    external_placer_order_number: item.external_placer_order_number || '',
    discount_percentage: '',
    cptcount: '',
    cpt_id: '',
    mode: '',
  }));

export default mapCptCode;
