import { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import DialogFormRadiologist from 'page/components/DialogFormRadiologist';
import { Button } from 'components/button';
import {
  TwoSideMapper,
  filterRadiologistList,
} from 'components/form/twoSideMapper';

import { RadiologyGroupDetailsType } from 'stores/_mobx/systemSetup/masterSetting/radiologyGroup';
import { RadiologistType } from 'stores/_mobx/users/radiologist';
import { storeRadiologist } from 'stores/_mobx/users/radiologist';

const columns = [
  {
    title: 'Name',
    name: 'label',
  },
];

const settings = {
  leftFirstPlaceholder: 'First Name',
  leftSecondPlaceholder: 'Last Name',
  leftFirstClassName: 'col-sm',
  leftSecondClassName: 'col-sm',
  rightFirstPlaceholder: 'First Name',
  rightSecondPlaceholder: 'Last Name',
  rightFirstClassName: 'col-sm',
  rightSecondClassName: 'col-sm',
};

interface PropsType {
  isDirty: boolean;
  control: Control<RadiologyGroupDetailsType>;
  setValue: UseFormSetValue<RadiologyGroupDetailsType>;
  getValues: UseFormGetValues<RadiologyGroupDetailsType>;
}

const MappingTab = ({ control, isDirty, getValues, setValue }: PropsType) => {
  const { options } = storeRadiologist;

  const [isDialogVisible, toggleDialog] = useState<boolean>(false);

  const radiologistsOptions = useMemo(() => {
    const radiologists = getValues('radiologists');

    const filteredOptions = radiologists.length
      ? options.filter(
          ({ value }) => !radiologists.some((el) => el.value === value)
        )
      : options;

    return filteredOptions;
  }, [options, getValues]);

  const handleToggleDialog = () => {
    toggleDialog((state) => !state);
  };

  const handleAddRadiologist = (radiologist: RadiologistType) => {
    if (radiologist) {
      const newRadiologist = {
        label: `${radiologist.firstName} ${radiologist.lastName}`,
        firstName: radiologist.firstName,
        lastName: radiologist.lastName,
        value: radiologist.radiologistId,
      };

      const radiologists = getValues('radiologists');

      const newValue = radiologists.concat(newRadiologist);

      setValue('radiologists', newValue, {
        shouldTouch: true,
        shouldDirty: true,
      });
    }

    toggleDialog(false);
  };

  return (
    <>
      <div className="col-auto me-auto" />
      <Button
        className="col-auto mb-4"
        variant="default"
        text="Add Radiologist"
        onClick={handleToggleDialog}
      />

      <TwoSideMapper
        titleLeft="Total available radiologists"
        titleRight="Total radiologists in this group"
        name="radiologists"
        className="mb-4"
        settings={settings}
        columnLeft={columns}
        columnRight={columns}
        options={radiologistsOptions}
        isDirty={isDirty}
        filterMethod={filterRadiologistList}
        control={control}
      />

      {isDialogVisible && (
        <DialogFormRadiologist
          onClose={handleToggleDialog}
          onSubmit={handleAddRadiologist}
        />
      )}
    </>
  );
};

export default observer(MappingTab);
