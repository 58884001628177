import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import {
  TRgApproval,
  TExpHistoryItem,
} from 'services/billing-coding/ProfessionalGroupBillingService';
import { dateToLocalTimezone } from 'utils/DateUtils';

export class PProfessionalGroupBillingStore extends CommonPayload {}

export class SProfessionalGroupBillingStore {
  historyDataSource: Array<TExpHistoryItem> = [];
  historyDataSourceCount: number = 0;
  dataSource: Array<TRgApproval> = [];
  dataSourceCount: number = 0;
}

class ProfessionalGroupBillingStore extends ReduceStore<
  SProfessionalGroupBillingStore,
  PProfessionalGroupBillingStore
> {
  getInitialState() {
    return new SProfessionalGroupBillingStore();
  }

  areEqual(
    state1: SProfessionalGroupBillingStore,
    state2: SProfessionalGroupBillingStore
  ) {
    return false;
  }

  reduce(
    state: SProfessionalGroupBillingStore,
    action: PProfessionalGroupBillingStore
  ) {
    const storeState = this.getState();
    switch (action.type) {
      case 'professional-group-billing-overview':
        storeState.dataSource = action.data.map((el: TRgApproval) => ({
          ...el,
          dos: dateToLocalTimezone({ date: el.dos, dateOnly: true }),
        }));
        storeState.dataSourceCount = (action as any).count;
        break;
      case 'professional-group-billing-clean':
        storeState.dataSource = [];
        storeState.dataSourceCount = 0;
        storeState.dataSource = [];
        storeState.dataSourceCount = 0;
        break;
      case 'professional-group-billing-history-overview':
        storeState.historyDataSource = action.data;
        storeState.historyDataSourceCount = (action as any).count;
        break;
      default:
        break;
    }
    return storeState;
  }
}

export default new ProfessionalGroupBillingStore(appDispatcher);
