import { useState } from 'react';
import { useForm } from 'react-hook-form';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Grid } from 'components/grid';
import { Button } from 'components/button';
import { InputCalendar } from 'components/form/inputCalendar';
import { FileField } from 'components/form/file';
import { ControlsLayout } from 'components/layout';
import resolver from './validation';

import { DIR_WITH_EXAMPLE } from 'constant/config';

const exampleData = [
  {
    cpt: '51798',
    arranged: '1.1',
    purchased: '1.2',
    other: '1.3',
    insurance: '1.4',
    description: 'US bladder scan',
  },
  {
    cpt: '70030',
    arranged: '2.1',
    purchased: '2.2',
    other: '2.3',
    insurance: '2.4',
    description: 'X-ray eye',
  },
  {
    cpt: '23030',
    arranged: '3.1',
    purchased: '3.2',
    other: '4.3',
    insurance: '5.4',
    description: 'CPT description',
  },
];

const columns = [
  { name: 'cpt', title: 'CPT Code', className: 'width-100' },
  { name: 'arranged', title: 'Arranged Rate' },
  { name: 'purchased', title: 'Purchased Services' },
  { name: 'other', title: 'Other Rate' },
  { name: 'insurance', title: 'Insurance' },
  { name: 'description', title: 'CPT Description' },
];

const defaultValues: FormType = {
  file: '',
  fromDate: '',
  toDate: '',
};

interface FormType {
  file: '' | File;
  fromDate: string;
  toDate: string;
}

interface FormSubmitPayload extends Omit<FormType, 'file'> {
  file: File;
}

interface PropsType {
  inProgress: boolean;
  onSubmit: (data: FormSubmitPayload) => void;
  onClose: () => void;
}

const DialogFormImportFeeSchedule = ({
  inProgress,
  onSubmit,
  onClose,
}: PropsType) => {
  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<FormType>({
    defaultValues,
    resolver,
  });
  const [isExampleVisible, toggleExample] = useState<boolean>(false);

  const handleToggleExample = () => {
    toggleExample((state) => !state);
  };

  const handleClickSubmit = () => {
    // @ts-ignore
    handleSubmit(onSubmit)();
  };

  const handleClose = () => {
    if (!inProgress) onClose();
  };

  return (
    <Dialog size="large" scrollable={false} handleClose={handleClose}>
      <DialogHeader title="Confirm" onClose={handleClose} />
      <DialogBody className={inProgress ? 'on-ajax' : ''}>
        <form className="row">
          <InputCalendar
            name="fromDate"
            className="col-md-6 col-lg-4"
            label="From"
            required
            control={control}
          />
          <InputCalendar
            name="toDate"
            className="col-md-6 col-lg-4"
            label="To"
            required
            control={control}
          />
          <div />
          <FileField
            accept=".csv"
            name="file"
            label="CSV file"
            className="col-md-6 col-lg-4"
            required
            control={control}
          />
          <ControlsLayout alignX="auto">
            <a
              href={`${DIR_WITH_EXAMPLE}/samplecptcode_radiology.csv`}
              target="_blank"
              className="btn btn-primary d-flex gap-4"
              rel="noreferrer">
              <i className="bi bi-cloud-download" />
              <span>Download sample</span>
            </a>
          </ControlsLayout>
        </form>
        {isExampleVisible && (
          <div className="mt-4">
            <Grid
              columns={columns}
              dataSource={exampleData}
              noControl
              disablePagination
            />
          </div>
        )}
      </DialogBody>
      <DialogFooter>
        <Button
          variant="default"
          text={isExampleVisible ? 'Hide Sample' : 'Show Sample'}
          disabled={inProgress}
          onClick={handleToggleExample}
        />
        <Button
          text="Apply"
          disabled={!isDirty || inProgress}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogFormImportFeeSchedule;
