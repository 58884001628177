import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';
import JurisdictionZipCodeRangesGrid from './JurisdictionZipCodeRangesGrid';

interface PropsType {
  id: number;
  isNew?: boolean;
  onClose: () => void;
}

const DialogJurisdictionZipCodeRanges = ({ onClose, ...rest }: PropsType) => (
  <Dialog size="large" handleClose={onClose}>
    <DialogHeader title="Zip Code Ranges" onClose={onClose} />
    <DialogBody>
      <JurisdictionZipCodeRangesGrid {...rest} />
    </DialogBody>
  </Dialog>
);

export default DialogJurisdictionZipCodeRanges;
