import { useEffect, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { TPagination } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';
import PatientFilter, { getDefaultValues, FilterType } from './PatientFilter';
import PatientsGrid from './PatientGrid';

import { storeCurrentUserOptions } from 'stores/_mobx/curentUserOptions';
import {
  storePatient,
  PatientInfoExtendedType,
} from 'stores/_mobx/workflow/order/patient';
import { getPagination } from 'utils/getPagination';

interface PropsType {
  onClose: () => void;
  onSelectPatient: (patient: PatientInfoExtendedType) => void;
  onClickAddPatient: () => void;
}

const DialogSelectPatient = ({
  onSelectPatient,
  onClickAddPatient,
  onClose,
}: PropsType) => {
  const { isCorrectional, fetchingCorrectionalStatus } =
    storeCurrentUserOptions;

  const { fetchingPatients, patients, patientsTotal } = storePatient;

  const [filter, setFilter] = useState<FilterType>(getDefaultValues());

  const [pagination, setPagination] = useState<TPagination>(getPagination());

  const [filterApplied, setFilterApplied] = useState<boolean>(false);

  const isLoading = fetchingCorrectionalStatus || fetchingPatients;

  const handleApplyFilter = (filter: FilterType) => {
    setFilter(filter);
    setPagination({ ...pagination, skip: 0, page: 1 });
    setFilterApplied(true);
  };

  useEffect(() => {
    storePatient.getPatientsMain(filter, pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  useLayoutEffect(() => {
    storeCurrentUserOptions.checkCorrectionalStatus();

    return storePatient.clearPatients;
  }, []);

  return (
    <Dialog size="extraLarge" handleClose={onClose}>
      <DialogHeader title="Select Patient" onClose={onClose} />
      <DialogBody>
        <PatientFilter
          isCorrectional={isCorrectional}
          onSubmit={handleApplyFilter}
        />
        <PatientsGrid
          isLoading={isLoading}
          isCorrectional={isCorrectional}
          isAddPatientBtnDisabled={!filterApplied}
          patients={patients}
          patientsTotal={patientsTotal}
          pagination={pagination}
          onSelect={onSelectPatient}
          onChangePage={setPagination}
          onClickAddPatient={onClickAddPatient}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogSelectPatient);
