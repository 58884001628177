import React from 'react';

import { TAbstractInputAttr } from 'components/form/input/AbstractInput';
import Select, {
  SelectOptionType,
  SelectAttributes,
} from 'components/form/input/Select';

export interface PGeneralDropdown {
  name: string;
  options?: Array<SelectOptionType>;
  optionsFilter?: any;
  value?: any;
  attr?: TAbstractInputAttr & SelectAttributes;
  label?: React.ReactNode;
  noLabel?: boolean;
  validations?: string;
  errorMessages?: string;
  flagOneListItemSelectedByDefault?: boolean;
  onAjax?: boolean;
  isClearable?: boolean;
  sortAlphabetically?: boolean;
  optionsLoader?: () => Promise<Array<SelectOptionType>>;
  onSetValue?: (name: string, value: any, error: any) => void;
  noclone?: string;
  className?: string;
}

interface SGeneralDropdown {
  options: SelectOptionType[];
}

export default class GeneralDropdown extends React.Component<
  PGeneralDropdown,
  SGeneralDropdown
> {
  constructor(props: PGeneralDropdown) {
    super(props);
    this.state = {
      options: props.options || [],
    };
  }

  componentDidMount() {
    const { options } = this.state;
    if (!options.length && this.props.optionsLoader) {
      this.props.optionsLoader().then((options) => this.setState({ options }));
    }
  }

  getOptions() {
    return this.state.options;
  }

  sortAlphabetically(list: Array<SelectOptionType>) {
    if (list && Array.isArray(list)) {
      return list.sort((item1, item2) => {
        if (
          (item1.label as string).toLowerCase() <
          (item2.label as string).toLowerCase()
        ) {
          return -1;
        }
        if (
          (item1.label as string).toLowerCase() >
          (item2.label as string).toLowerCase()
        ) {
          return 1;
        }
        return 0;
      });
    }
    return [];
  }

  getRenderOptions() {
    return this.getOptions();
  }

  render() {
    let options = this.getRenderOptions();
    if (this.props.optionsFilter) {
      options = (options || []).filter(this.props.optionsFilter);
    }
    if (this.props.sortAlphabetically) {
      options = this.sortAlphabetically(options);
    }
    const attr = this.getAttributes();

    const getValuerManually =
      this.props.flagOneListItemSelectedByDefault && options.length === 1;
    return (
      <Select
        {...this.props}
        value={getValuerManually ? options[0].value : this.props.value}
        attr={attr}
        ref="input"
        options={options}
        onSetValue={(name: string, value: any, error: any) =>
          this.onSetValue(name, value, error)
        }
      />
    );
  }

  onSetValue(name: string, value: any, error: any) {
    this.props.onSetValue(name, value, error);
  }

  getAttributes() {
    const { attr } = this.props;
    if (!this.isOnAjax()) {
      return attr;
    }
    if (!attr) {
      return { className: 'on-ajax-line', disable: true };
    }
    const className = (attr.className ? attr.className : '') + ' on-ajax-line';
    return Object.assign({}, attr, { className, disabled: true });
  }

  isOnAjax() {
    try {
      return this.getOptions().length === 0;
    } catch (ex) {
      throw Error('Error with options in ' + this.props.name);
    }
  }

  getValue() {
    return this.props.value;
  }

  getInputState() {
    return this.refs.input
      ? (this.refs.input as Select).getInputState(this.getValue())
      : null;
  }
}
