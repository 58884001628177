import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormPlaceOfService, { FormModel } from './components/Form';

import { storePlaceOfService } from 'stores/_mobx/systemSetup/masterSetting/placeOfService';
import { URL_PLACE_OF_SERVICE } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const PlaceOfServiceAddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: FormModel) =>
    storePlaceOfService.addPlace(payload).then((isSucceed) => {
      if (isSucceed) {
        history.push(URL_PLACE_OF_SERVICE);
      }
    });

  return (
    <>
      <Title title="Add Place of Service" />
      <FormPlaceOfService
        backUrl={URL_PLACE_OF_SERVICE}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default PlaceOfServiceAddPage;
