import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import TextArea from 'components/form/input/TextArea';
import Form from 'components/form/Form';
import FormControl from 'components/form/FormControl';
import ContactInfoSection from './ContactInfoSection';
import { Button } from 'components/button';
import DialogContactsList from '../../dialogContactsList';

import { URL_FACILITY } from 'constant/path/systemSetup';
import { areObjectsNotEqual } from 'utils/compareObjects';

interface ContactListItem {
  refid: number;
  is_selected: boolean;

  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  fax: string;
  contact_title: string;
  status: 'A' | 'I';
  comments: string;
}

export interface PContactTab {
  blocked: boolean;
  contactData: any;
  submitContactTab: (contactData: any) => void;
  facilityId: number;
  selectedContacts: Array<any>;
  updateContactList: () => void;
  backToList: () => void;
  history: RouteComponentProps['history'];
}

export class SContactTab {
  errors: any = {};
  isSelectFromListModalOpen: boolean = false;
  contactData: any = {};
  isContactDataChanged: boolean = false;
}

export default class ContactTab extends React.Component<
  PContactTab,
  SContactTab
> {
  static newItemsPrefix = '-stub-';

  constructor(props: PContactTab) {
    super(props);
    this.state = Object.assign(new SContactTab(), {
      errors: {},
      isSelectFromListModalOpen: false,
      contactData: { ...props.contactData },
      isContactDataChanged: false,
    });
  }

  updateTabState(state: SContactTab) {
    this.setState(state);
  }

  getSelectedContactList = (contactList: ContactListItem[]) => contactList?.filter((item: ContactListItem) => item.is_selected);

  newEmptyContactItem(collapsed: boolean) {
    const res = {
      refid:
        ContactTab.newItemsPrefix +
        new Date().getTime() +
        Math.floor((1 + Math.random()) * 0x10000).toString(16),
      is_selected: true,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      fax: '',
      contact_title: '',
      status: 'A',
      comments: '',
      collapsed: collapsed,
    };
    return res;
  }

  addEmptyContactItem(collapsed: boolean) {
    const { state } = this;
    const contactData = {
      ...state.contactData,
      contactList: state.contactData.contactList.concat(
        this.newEmptyContactItem(collapsed)
      ),
    };
    this.setState({ contactData, isContactDataChanged: true });
  }

  removeContactItem(id: number) {
    const { state, props } = this;
    const contactList = state.contactData.contactList
      .filter((item: ContactListItem) => (
        item.refid === id && !('' + item.refid).startsWith(ContactTab.newItemsPrefix) ? true : item.refid !== id
      ))
      .map((item: ContactListItem) => ({
        ...item,
        is_selected: item.refid === id && !('' + item.refid).startsWith(ContactTab.newItemsPrefix) ? false : item.is_selected
      }));

    const contactData = { ...state.contactData, contactList };

    const contactListSelectedLength = this.getSelectedContactList(props.contactData.contactList).length;
    const contactListSelectedUpdatedLength = this.getSelectedContactList(contactList).length;

    const isContactDataChanged = contactList.some((item: ContactListItem) => (
      item.refid.toString().startsWith(ContactTab.newItemsPrefix))
      || contactListSelectedLength !== contactListSelectedUpdatedLength
    );
    this.setState({ contactData, isContactDataChanged });
  }

  clickContactHandler = (item: any, isLastElement: boolean) => {
    if (isLastElement) {
      this.addEmptyContactItem(false);
    } else {
      this.removeContactItem(item.refid);
    }
  };

  handleSetValueToContacts = (contactValue: ContactListItem) => {
    const contact = this.props.contactData.contactList.find(
      (contactItem: ContactListItem) => contactItem.refid === contactValue.refid
    );

    const isContactDataChanged = contact ? areObjectsNotEqual(contact, contactValue) : true;

    const contactList = this.state.contactData.contactList.map((contact: ContactListItem) =>
      contact.refid === contactValue.refid ? contactValue : contact
    );
    const contactData = {
      ...this.state.contactData,
      contactList: contactList.some(
        ({ refid }: any) => refid === contactValue.refid
      )
        ? contactList
        : contactList.concat(contactValue),
    };

    this.setState({ contactData, isContactDataChanged });
  };

  renderContactInfoItem(item: any, flagLastItem: boolean) {
    return (
      <ContactInfoSection
        key={item.refid}
        value={item}
        additional
        isLastElement={flagLastItem}
        onControlClick={this.clickContactHandler}
        onSetValue={this.handleSetValueToContacts}
      />
    );
  }

  render() {
    const { state, props } = this;
    const contactList = state.contactData.contactList;
    const contactListSelected = contactList.filter(
      (v: ContactListItem) => v.is_selected
    );

    return (
      <div className="contact-tab position-relative">
        {props.blocked ? <div className="overlay" /> : null}

        {contactListSelected.map((item: ContactListItem, idx: number) =>
          this.renderContactInfoItem(
            item,
            idx + 1 === contactListSelected.length
          )
        )}
        <Form
          model={state.contactData}
          onCollectValues={(name, value, errorMessages) =>
            this.onCollectValues(name, value, errorMessages)
          }
          submit={this.handleSubmit}
          errors={this.state.errors}>
          <FormControl className="mb-5">
            <Button
              text="Select Contacts"
              onClick={() => this.setState({ isSelectFromListModalOpen: true })}
            />
          </FormControl>
          <TextArea
            name="comments"
            noLabel
            attr={{ placeholder: 'Comment', rows: 6 }}
          />
          <FormControl>
            <Button
              variant="warning"
              text="Reset"
              onClick={() => this.reset()}
              disabled={!this.state.isContactDataChanged}
            />
            <input
              type="submit"
              className="btn btn-primary"
              value="Submit"
              disabled={!this.state.isContactDataChanged}
            />
          </FormControl>
        </Form>
        {state.isSelectFromListModalOpen && (
          <DialogContactsList
            contacts={state.contactData.contactList}
            callback={this.selectFromListModalCallback}
            onClose={this.handleCloseDialogFacilityList}
          />
        )}
      </div>
    );
  }

  handleCloseDialogFacilityList = () => {
    this.setState({ isSelectFromListModalOpen: false });
  };

  selectFromListModalCallback = (response: Array<any>) => {
    const { state: { contactData } } = this;
    const contactList = contactData.contactList?.map((listItem: ContactListItem) => ({
      ...listItem,
      is_selected: response.indexOf(listItem.refid) >= 0 ? true : listItem.is_selected
    }));
    this.setState({
      contactData: { ...contactData, contactList },
      isSelectFromListModalOpen: false,
      isContactDataChanged: true,
    });
  };

  reset() {
    const { props } = this;
    this.setState({
      contactData: { ...props.contactData },
      isContactDataChanged: false,
    });
  }

  handleSubmit = (model: any, hasErrors: any, errors: any) => {
    if (hasErrors) {
      const state = {
        contactData: { ...this.state.contactData },
        errors: { ...this.state.errors },
        isContactDataChanged: this.state.isContactDataChanged,
      };
      if (errors !== null) {
        for (let key in model) {
          state.errors[key] = errors[key];
        }
      }
      this.setState(state);
    } else {
      const contactList = this.state.contactData.contactList.map((item: ContactListItem) => ({
        ...item,
        refid: ('' + item.refid).startsWith(ContactTab.newItemsPrefix) ? '' : item.refid,
      }));
      this.props.submitContactTab({ ...this.state.contactData, contactList });
    }
  };

  onCollectValues(name: any, value: any, errorMessages: any) {
    const state = { ...this.state };
    state.contactData[name] = value;
    state.errors[name] = errorMessages;
    this.setState({ ...state, isContactDataChanged: value.length });
  }

  goToOverview() {
    this.props.history.push(URL_FACILITY);
  }
}
