import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { zipValidator } from 'utils/formValidators';

const zipCodeMessage = 'ZIP code should be 5 or 9 numbers in length';

const validation = yupResolver(
  yup.object().shape({
    serviceLocation: yup.object().shape({
      address: yup.string().required('Please, fill in Address!'),
      city: yup.string().required('Please, fill in City!'),
      state: yup.string().required('Please, fill in State!'),
      zipcode: yup.string().required('Please, fill in Zipcode!').test({
        test: zipValidator,
        message: zipCodeMessage,
      }),
    }),
    renderingDoctor: yup.object().shape({
      last_name: yup.string().required('Please, fill in Last Name!'),
      first_name: yup.string().required('Please, fill in First Name!'),
      office_address: yup
        .string()
        .required('Please, fill in Practice Address!'),
      officestate: yup.string().required('Please, fill in Practice State!'),
      officecity: yup.string().required('Please, fill in Practice City!'),
      offzipcode: yup
        .string()
        .required('Please, fill in Practice Zipcode!')
        .test({
          test: zipValidator,
          message: zipCodeMessage,
        }),
      radio_npi: yup
        .string()
        .required('Please, fill in a NPI')
        .test({
          test: (npi: string) => npi.length >= 10,
          message: 'NPI have to contain 10 digits length',
        }),
    }),
    jurisdiction: yup.object().shape({
      state: yup
        .string()
        .typeError('Please, fill in State!')
        .required('Please, fill in State!'),
      area: yup.string().required('Please, fill in Area!'),
    }),
  })
);

export default validation;
