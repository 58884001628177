import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';

import { storeLoginAttempt } from 'stores/_mobx/systemSetup/masterSetting/loginAttempt';
import {
  URL_MASTER_SETTINGS,
  URL_LOGIN_ATTEMPT_ADD,
} from 'constant/path/systemSetup';
import { MS_LOGIN_ATTEMPT } from 'constant/pagesId/systemSetup';

const columns = [
  {
    name: 'no_of_attempts',
    title: 'No. of attempts',
  },
  {
    name: 'release_duration',
    title: 'Release duration [minutes]',
  },
  {
    name: 'period_type',
    title: 'Inactive period type',
  },
  {
    name: 'period_value',
    title: 'Inactive period value',
  },
  {
    name: 'chngpwd_type',
    title: 'Pwd. period type',
  },
  {
    name: 'chngpwd_value',
    title: 'Pwd. period value',
  },
  {
    name: 'comments',
    title: 'Comment',
  },
  {
    name: 'lastmodified',
    title: 'Created',
  },
  {
    name: 'status',
    title: 'Status',
  },
];

const LoginAttemptListPage = () => {
  const {
    fetching,
    attemptsList,
    attemptsTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeLoginAttempt;

  const [attemptsNumber, setAttemptsNumber] = useState<number | ''>('');

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleChangeFilter = (value: number | '') => {
    setAttemptsNumber(value);
    debouncedMethod();
  };

  useEffect(() => {
    storeLoginAttempt.getAttemptsListMain(attemptsNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={MS_LOGIN_ATTEMPT.PAGE} title="Login Attempt">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Number of attempts"
        type="number"
        className="col-md-6 col-lg-4 col-xl-3"
        value={attemptsNumber}
        onChange={handleChangeFilter}
      />

      <Grid
        id="login_attempt_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={attemptsList}
        dataSourceCount={attemptsTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_LOGIN_ATTEMPT_ADD} />
        }
      />
    </>
  );
};

export default observer(LoginAttemptListPage);
