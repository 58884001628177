const colorsOfRange = ['#8fc95a', '#72a653', '#ffc33d', '#ad331f', '#ce2b2b'];

interface PropsType {
  summaryInfo: { label: string; value: string }[];
}

const SummaryView = ({ summaryInfo }: PropsType) => (
  <ul>
    {summaryInfo.map(({ label, value }, idx) => (
      <li key={label}>
        <span>{`${label}: `}</span>
        <span style={{ color: colorsOfRange[idx] }}>{value}</span>
      </li>
    ))}
  </ul>
);

export default SummaryView;
