import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { MultipleFileUploadControl } from 'components/form/input/MultipleFileUpload';
import { Button } from 'components/button';
import resolver from './validation';

import { DrPlateEntryType } from 'stores/_mobx/workflow/equipmentInventory/drPlate';
import { InputCalendar } from 'components/form/inputCalendar';

interface FormModel extends DrPlateEntryType {
  refid?: number;
}

const initialValues: DrPlateEntryType = {
  cal_due_date: '',
  last_cal_date: '',
  plate_name: '',
  start_date: '',
  center_id: '',
  unique_id: '',
  documentList: [],
};

interface PropsType {
  backUrl: string;
  defaultValues?: DrPlateEntryType;
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormModifyDrPlate = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: FormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('unique_id', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={isSubmitting ? 'on-ajax' : ''}
      onSubmit={handleClickSubmit}>
      <div className="row">
        <Input
          name="unique_id"
          label="Serial No."
          className="col-md-7 col-lg-4"
          required
          control={control}
        />
        <div />
        <Input
          name="plate_name"
          label="Plate name"
          className="col-md-7 col-lg-4"
          required
          control={control}
        />
        <div />
        <Input
          name="center_id"
          label="Sensor ID"
          className="col-md-7 col-lg-4"
          control={control}
        />
      </div>

      <div className="row">
        <InputCalendar
          name="start_date"
          label="Installation purchase date"
          className="col-md-7 col-lg-4"
          required
          control={control}
        />
        <InputCalendar
          name="last_cal_date"
          label="Last calibration date"
          className="col-md-7 col-lg-4"
          control={control}
        />
        <InputCalendar
          name="cal_due_date"
          label="Next calibration date"
          className="col-md-7 col-lg-4"
          control={control}
        />
      </div>

      <MultipleFileUploadControl
        name="documentList"
        dir="xrayplate"
        control={control}
      />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          type="reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormModifyDrPlate;
