import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import { LayoutSideTitle } from 'components/layout';
import Form from './components/Form';

import {
  storeState,
  StateType,
} from 'stores/_mobx/systemSetup/masterSetting/state';
import { URL_STATE } from 'constant/path/systemSetup';

interface MatchParam {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParam> {}

const StateEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, state } = storeState;

  const handleSubmit = (payload: StateType) =>
    storeState.modifyState(payload).then((response) => {
      if (response) {
        return response;
      }
      history.push(URL_STATE);
    });

  useEffect(() => {
    if (state === null) {
      history.replace(URL_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    const id = Number(params.id);

    if (id !== state?.id) {
      storeState.getState(id);
    }
    return storeState.clearState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <>
      <LayoutSideTitle title="Edit State">
        <Link to={URL_STATE} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <Form defaultValues={state} onSubmit={handleSubmit} />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(StateEditPage);
