export const EXAM_INFO = {
  PAGE: 'PatientInformation',
};

export const PATIENT_DEMOGRAPHIC = {
  ACTIVE: 'pat_active',
  INACTIVE: 'pat_inactive',
  DELETE: 'pat_delete',
  EDIT: 'pat_edit',
  ADD: 'pat_add',
  PAGE: 'patient',
};

export const DOC_MANAGER = {
  PAGE: 'docmanager',
};
