import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import DialogImportDocument from './DialogImportDocument';

import {
  storeOrderDocuments,
  DocumentType,
  UploadDocumentFormType,
} from 'stores/_mobx/workflow/order/documents';

interface PropsType {
  orderId: number;
  patientId: number;
}

const OrderDocuments = ({ patientId, orderId }: PropsType) => {
  const {
    fetching,
    idForDelete,
    isDeleteFaceSheetVisible,
    documents,
    permission,
  } = storeOrderDocuments;

  const [idForEdit, setIdForEdit] = useState<null | number | 'new'>(null);

  const columns = useMemo(
    () => [
      { name: 'typeName', title: 'Document type' },
      {
        name: 'fileName',
        title: 'File name',
        render: (fileName: string, { filePath }: DocumentType) => (
          <a href={filePath} rel="noreferrer" target="_blank">
            {fileName}
          </a>
        ),
      },
      { name: 'comments', title: 'Comments' },
      { name: 'creationDate', title: 'Date' },
      {
        name: 'control',
        title: 'Action',
        className: 'width-100',
        render: (value: undefined, doc: DocumentType) => {
          if (!doc.essence) return null;
          return doc.essence === 'import' ? (
            <div className="control">
              <IconButton
                onClick={() => {
                  setIdForEdit(doc.refid);
                }}>
                <i className="bi bi-pencil" />
              </IconButton>
              <IconButton
                onClick={() => storeOrderDocuments.setIdForDelete(doc.refid)}>
                <i className="bi bi-trash" />
              </IconButton>
            </div>
          ) : (
            <div className="control">
              <IconButton onClick={storeOrderDocuments.openFaceSheetDialog}>
                <i className="bi bi-trash" />
              </IconButton>
            </div>
          );
        },
      },
    ],
    []
  );

  const fetchData = useCallback(
    () => storeOrderDocuments.getDocuments({ patientId, orderId }),
    [patientId, orderId]
  );

  const handleApproveDelete = () => {
    storeOrderDocuments.deleteDocument().then((isSucceed) => {
      if (isSucceed) {
        fetchData();
      }
    });
  };

  const handleApproveDeleteFaceSheet = () => {
    storeOrderDocuments.deleteFaceSheet(orderId).then((isSucceed) => {
      if (isSucceed) {
        fetchData();
      }
    });
  };

  const handleCloseImport = () => {
    setIdForEdit(null);
  };

  const handleUpdateDocument = (payload: UploadDocumentFormType) => {
    const params = {
      orderId,
      patientId,
    };
    const action =
      idForEdit === 'new'
        ? storeOrderDocuments.addDocument
        : storeOrderDocuments.updateDocument;

    action({ ...payload, params }).then((isSucceed) => {
      if (isSucceed) {
        fetchData();
        handleCloseImport();
      }
    });
  };

  useEffect(() => {
    fetchData();
    storeOrderDocuments.checkPermission();

    return storeOrderDocuments.clearDocumentsStore;
  }, [fetchData]);

  return (
    <>
      <Grid
        id="order_document_overview_grid"
        disablePagination
        columns={columns}
        onAjax={fetching}
        dataSource={documents}
        gridControlPanel={
          permission.import && (
            <GridControlButton
              title="Import"
              onClick={() => setIdForEdit('new')}
            />
          )
        }
      />

      {idForDelete ? (
        <DialogConfirm
          onCancel={storeOrderDocuments.clearIdForDelete}
          onApprove={handleApproveDelete}>
          Are you sure you want to delete?
        </DialogConfirm>
      ) : null}

      {isDeleteFaceSheetVisible && (
        <DialogConfirm
          onCancel={storeOrderDocuments.closeFaceSheetDialog}
          onApprove={handleApproveDeleteFaceSheet}>
          Are you sure you want delete face sheet document for this order?
        </DialogConfirm>
      )}

      {idForEdit && (
        <DialogImportDocument
          documentId={idForEdit}
          onClose={handleCloseImport}
          onDocumentUpdate={handleUpdateDocument}
        />
      )}
    </>
  );
};

export default observer(OrderDocuments);
