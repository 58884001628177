import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { GridControlButton } from 'components/grid';
import {
  Grid,
  GridExtraControlPanel,
  ICellRendererParams,
  ColDef,
} from 'components/gridModern';
import DialogConfirm from 'components/modal/dialogConfirm';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';

import {
  storeRegion,
  RegionType,
} from 'stores/_mobx/systemSetup/masterSetting/region';
import { MS_REGION as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_REGION_ADD,
  URL_REGION_EDIT,
} from 'constant/path/systemSetup';

const defaultColDef = {
  sortable: true,
  editable: false,
  resizable: true,
  autoHeaderHeight: true,
  wrapHeaderText: true,
  showDisabledCheckboxes: true,
};

const columns: ColDef<RegionType>[] = [
  { field: 'region', headerName: 'Region', flex: 1 },
  {
    field: 'id',
    colId: 'action',
    headerName: 'Action',
    headerClass: 'text-center',
    width: 100,
    sortable: false,
    cellRenderer: ({
      value,
      data,
    }: ICellRendererParams<RegionType, number>) => (
      <div className="controls">
        <Link
          className="bi bi-pencil"
          to={URL_REGION_EDIT.replace(':id', `${value}`)}
          onClick={() => storeRegion.setRegionDetails(data)}
        />
        <IconButton onClick={() => storeRegion.setRegionForDelete(value)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const RegionListPage = () => {
  const {
    fetching,
    regions,
    regionsTotal,
    regionIdForDelete,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeRegion;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleApproveDelete = () => {
    storeRegion.deleteRegion().then((isSucceed) => {
      if (isSucceed) storeRegion.getRegions();
    });
  };

  const handleChangeFilter = (regionName: string) => {
    storeRegion.setFilter(regionName);

    debouncedMethod(regionName);
  };

  useEffect(() => {
    storeRegion.getRegions();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Region">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Region Name"
        className="col-sm-12 col-md-6 col-lg-4"
        value={filter}
        onChange={handleChangeFilter}
      />

      <GridExtraControlPanel>
        <GridControlButton title="Add" url={URL_REGION_ADD} />
      </GridExtraControlPanel>
      <Grid
        id={storeRegion.gridId}
        defaultColDef={defaultColDef}
        columnDefs={columns}
        isLoading={fetching}
        rowData={regions}
        totalCount={regionsTotal}
        paginationSettings={pagination}
        onPaginationChange={setPagination}
      />
      {regionIdForDelete !== null && (
        <DialogConfirm
          onCancel={storeRegion.clearRegionForDelete}
          onApprove={handleApproveDelete}>
          Are you sure you want to delete?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(RegionListPage);
