import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import {
  storeHospice,
  FormModel,
} from 'stores/_mobx/systemSetup/masterSetting/hospice';
import { URL_HOSPICE } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const HospiceEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, hospice } = storeHospice;

  const handleSubmit = async (data: FormModel) =>
    storeHospice.updateHospice(data).then((error) => {
      if (error) return error;
      history.push(URL_HOSPICE);
    });

  useEffect(() => {
    if (!fetching) storeHospice.getHospice(Number(params.id));

    return storeHospice.clearHospice;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Hospice" />
      <Form
        backUrl={URL_HOSPICE}
        defaultValues={hospice}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(HospiceEditPage);
