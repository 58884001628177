import { ReduceStore } from 'flux/utils';
import appDispatcher from 'dispatcher/AppDispatcher';

let buildActionName = function (name) {
  return 'reports/report-creator/physician-exam-count/' + name;
};

export let Actions = {
  LOAD_PHYSICIAN_EXAM_COUNT_LIST: buildActionName(
    'load-physician-exam-count-list'
  ),
};

class PhysicianExamCountPageStore extends ReduceStore {
  getEmptyDiagram() {
    return [['Data', 'Value']];
  }

  getInitialState() {
    return {
      physicianExamCountList: [],
      physicianExamCountDiagram: this.getEmptyDiagram(),
    };
  }

  reduce(state, action) {
    switch (action.type) {
      case Actions.LOAD_PHYSICIAN_EXAM_COUNT_LIST:
        this.getState().physicianExamCountList = action.data.contval || [];
        this.getState().physicianExamCountDiagram =
          action.data.chartval || this.getEmptyDiagram();
        break;
      default:
        break;
    }
    return this.getState();
  }

  areEqual(one, two) {
    return false;
  }
}

const physicianExamCountPageStore = new PhysicianExamCountPageStore(
  appDispatcher
);
export default physicianExamCountPageStore;
