export const INBOX = {
  PAGE: 'inboxid',
};

export const COMPOSE = {
  PAGE: 'ComposeMessage',
};

export const DRAFT = {
  PAGE: 'draftid',
};

export const SENT = {
  PAGE: 'sentitemid',
};

export const TRASH = {
  PAGE: 'trashid',
};

export const REMINDER = {
  PAGE: 'Reminder',
};
