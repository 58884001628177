import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import useDebounce from 'components/hook/useDebounce';
import { Grid, TPagination } from 'components/grid';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';

import {
  storePhysician,
  PhysicianType,
} from 'stores/_mobx/clinicianManager/physician';
import { storeGrid } from 'stores/_mobx/grid';
import { getPagination } from 'utils/getPagination';

const gridId = 'select_physician_modal_grid';

const COLUMNS = [
  {
    name: 'last_name',
    title: 'Last name',
  },
  {
    name: 'first_name',
    title: 'First name',
  },
  {
    name: 'clinician_group',
    title: 'Physician Group',
  },
  {
    name: 'npi',
    title: 'NPI',
  },
];

interface PropsType {
  onCancel: () => void;
  onSelect: (physician: PhysicianType) => void;
}

interface FilterType {
  firstName: string;
  lastName: string;
  npi: string;
}

const defaultValues: FilterType = {
  firstName: '',
  lastName: '',
  npi: '',
};

const DialogSelectPhysician = ({ onCancel, onSelect }: PropsType) => {
  const { fetching, physiciansList, physiciansTotal } = storePhysician;

  const { control, getValues, watch } = useForm<FilterType>({ defaultValues });

  const [pagination, setPagination] = useState<TPagination>(
    getPagination(storeGrid.getPageSize(gridId))
  );

  const debouncedMethod = useDebounce(setPagination);

  useEffect(() => {
    const subscription = watch(() => {
      debouncedMethod({
        ...pagination,
        skip: 0,
        page: 1,
      });
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    const filter = getValues();

    storePhysician.getPhysicians({ ...filter, pagination });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <Dialog size="large" handleClose={onCancel}>
      <DialogHeader title="Select Physician" onClose={onCancel} />
      <DialogBody>
        <div className="row">
          <Input
            className="col-sm-12 col-md-4 col-lg-4"
            name="lastName"
            placeholder="Last name"
            autoFocus
            control={control}
          />
          <Input
            className="col-sm-12 col-md-4 col-lg-5"
            name="firstName"
            placeholder="First name"
            control={control}
          />
          <Input
            className="col-sm-6 col-md-4 col-lg-3"
            name="npi"
            placeholder="NPI"
            onKeyPress={(e) => {
              if (!/^\d/.test(e.key)) e.preventDefault();
            }}
            maxLength={10}
            control={control}
          />
        </div>

        <Grid
          id={gridId}
          noDataText="Please search for physician's First/Last Name or NPI"
          onAjax={fetching}
          columns={COLUMNS}
          dataSource={physiciansList}
          dataSourceCount={physiciansTotal}
          pagination={pagination}
          onClick={onSelect}
          onPaginationChange={setPagination}
        />
      </DialogBody>
      <DialogFooter>
        <Button variant="warning" text="Cancel" onClick={onCancel} />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogSelectPhysician);
