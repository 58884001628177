/**
 * Compares two objects for equality, considering all nested properties.
 *
 * This function performs a deep comparison between two objects to determine if they are equivalent.
 * It checks if both objects have the same set of properties and if each property in one object
 * is equal to its corresponding property in the other object. This includes nested objects,
 * which are compared recursively. The comparison does not consider the order of properties.
 *
 * @param {any} obj1 The first object to compare.
 * @param {any} obj2 The second object to compare.
 * @returns {boolean} Returns `true` if both objects are equal, including their nested structures. Returns `false` otherwise.
 *
 * @example
 * const objA = { a: 1, b: { c: 2 } };
 * const objB = { a: 1, b: { c: 2 } };
 * console.log(compareFilters(objA, objB)); // true
 *
 * @example
 * const objA = { a: 1, b: { c: 2 } };
 * const objB = { a: 1, b: { c: 3 } };
 * console.log(compareFilters(objA, objB)); // false
 */

export const deepEqualObjectsCompare = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true;
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key)) return false;
    if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
      if (!deepEqualObjectsCompare(obj1[key], obj2[key])) return false;
    } else if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}