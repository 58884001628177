import AbstractChart, {
  PAbstractChart,
  SAbstractChart,
} from 'components/project/common/charts/AbstractChart';

export interface PBarChart extends PAbstractChart {
  options?: any;
}

/**
 * <BarChart data={[['DataDescription', 'value description'], ['apples', 10], ['oranges', 5]]} chartTitle='My Chart'/>
 */
export default class BarChart extends AbstractChart<PBarChart, SAbstractChart> {
  prepareCache() {
    this.cache = Object.assign({}, this.props);
    if (!this.cache.options) {
      this.cache.options = {};
    }
    const options = this.cache.options;
    if (options.bar === undefined) {
      options.bar = '95%';
    }
    const data = this.props.data;
    if (!data || !data.length) {
      throw Error(
        "Data must be defined, at lease first element of data. This element should be array with data description in following format - ['dataDescription', 'valueDescription']"
      );
    }
    for (let i = 1; i < data.length; i++) {
      const item = data[i];
      if (typeof item[1] !== 'number') {
        throw Error('Second element of data array must be a number');
      }
    }
  }

  getChartType() {
    return 'BarChart';
  }
}
