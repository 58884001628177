import React from 'react';
import { PText, SText, TText } from 'components/form/input/Text';

const SPECIAL_CHARS = "!@#$%^&*()_+{}:'<>?| []; ',./`~";
const LOWERCASE_CHARS = 'abcdefghijklmnopqrstuvwxyz';
const UPPERCASE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const DIGIT_CHARS = '0123456789';
const CharType = {
  SPECIAL: 'SPECIAL',
  DIGIT: 'DIGIT',
  UPPERCASE: 'UPPERCASE',
  LOWERCASE: 'LOWERCASE',
};

interface PPasswordGenerator extends PText {
  hideHints: boolean;
  passwordStrength?: PasswordStrength;
  generatePasswordText?: string;
  showPasswordText?: string;
  hidePasswordText?: string;
}

class SPasswordGenerator extends SText {
  passwordStrength?: PasswordStrength = {
    extreme: 'Extreme',
    strong: 'Strong',
    good: 'Good',
    weak: 'Weak',
    empty: null,
  };
  showPassword: boolean = false;
  readOnly: boolean = true;
}

interface PasswordStrength {
  extreme: string;
  strong: string;
  good: string;
  weak: string;
  empty: null;
}

export default class PasswordGenerator extends TText<
  PPasswordGenerator,
  SPasswordGenerator
> {
  constructor(props: PPasswordGenerator) {
    super(props);
    this.state = new SPasswordGenerator();
  }

  setShowPassword(showPassword: boolean) {
    this.setState({ showPassword });
  }

  setReadOnly(readOnly: boolean) {
    this.setState({ readOnly });
  }

  componentDidMount() {
    super.componentDidMount();
    this.setReadOnly(false);
  }

  buildInput(attributes: any): React.ReactElement {
    const { props } = this;
    const hideHints = props.hideHints;
    const strength: PasswordStrength = props.passwordStrength
      ? props.passwordStrength
      : this.state.passwordStrength;
    const strengthType = hideHints
      ? null
      : this.getStrengthType(props.value.toString());

    attributes.type = this.state.showPassword ? 'text' : 'password';
    if (this.state.readOnly) {
      attributes.readOnly = true;
    }
    let showPasswordText;
    let showPasswordClass;
    const generatePasswordText = props.generatePasswordText
      ? props.generatePasswordText
      : 'Generate';
    if (!this.state.showPassword) {
      showPasswordClass = 'show-pass';
      showPasswordText = props.showPasswordText
        ? props.showPasswordText
        : 'Show';
    } else {
      showPasswordClass = 'hide-pass';
      showPasswordText = props.hidePasswordText
        ? props.hidePasswordText
        : 'Hide';
    }
    const item: any = Object.entries(strength).find(
      (entry) => entry[0] === strengthType
    );
    const strengthText: string = item ? item[1] : '';

    return (
      <div className="password-strength">
        {super.buildInput(attributes)}

        {hideHints ? null : (
          <span className={'meter ' + strengthType}>{strengthText}</span>
        )}

        <span className="control">
          {hideHints ? null : (
            <a
              href="/"
              className="generate"
              onClick={this.generatePassword.bind(this)}
            >
              {generatePasswordText}
            </a>
          )}
          <a
            className={showPasswordClass}
            onClick={this.showPassword.bind(this)}
            href="/"
          >
            {showPasswordText}
          </a>
        </span>
      </div>
    );
  }

  getStrengthType(value: string): string {
    if (value) {
      if (this.isExtreme(value)) {
        return 'extreme';
      } else if (this.isStrong(value)) {
        return 'strong';
      } else if (this.isGood(value)) {
        return 'good';
      } else {
        return 'weak';
      }
    } else {
      return 'empty';
    }
  }

  static randomBetween(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  static removeArrayValue(value: string, array: string[]): void {
    const index = array.indexOf(value);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  static generateCustomPassword(): string {
    const length = PasswordGenerator.randomBetween(12, 14);

    const maxSpecials = PasswordGenerator.randomBetween(1, 3);
    let specials = 0;

    const maxDigits = PasswordGenerator.randomBetween(2, 4);
    let digits = 0;

    const maxUppercase = PasswordGenerator.randomBetween(2, 4);
    let uppercase = 0;

    const maxLowercase = length - maxSpecials - maxDigits - maxUppercase;
    let lowercase = 0;

    let result = '';
    const types = [
      CharType.SPECIAL,
      CharType.DIGIT,
      CharType.UPPERCASE,
      CharType.LOWERCASE,
    ];

    for (let i = 0; i < length; i++) {
      const type = types[PasswordGenerator.randomBetween(0, types.length - 1)];
      switch (type) {
        case CharType.SPECIAL:
          if (specials < maxSpecials) {
            result += SPECIAL_CHARS.charAt(
              PasswordGenerator.randomBetween(0, SPECIAL_CHARS.length - 1)
            );
            specials++;
          } else {
            i--;
            PasswordGenerator.removeArrayValue(CharType.SPECIAL, types);
          }
          break;
        case CharType.DIGIT:
          if (digits < maxDigits) {
            result += DIGIT_CHARS.charAt(
              PasswordGenerator.randomBetween(0, DIGIT_CHARS.length - 1)
            );
            digits++;
          } else {
            i--;
            PasswordGenerator.removeArrayValue(CharType.DIGIT, types);
          }
          break;
        case CharType.UPPERCASE:
          if (uppercase < maxUppercase) {
            result += UPPERCASE_CHARS.charAt(
              PasswordGenerator.randomBetween(0, UPPERCASE_CHARS.length - 1)
            );
            uppercase++;
          } else {
            i--;
            PasswordGenerator.removeArrayValue(CharType.UPPERCASE, types);
          }
          break;
        case CharType.LOWERCASE:
          if (lowercase < maxLowercase) {
            result += LOWERCASE_CHARS.charAt(
              PasswordGenerator.randomBetween(0, LOWERCASE_CHARS.length - 1)
            );
            lowercase++;
          } else {
            i--;
            PasswordGenerator.removeArrayValue(CharType.LOWERCASE, types);
          }
          break;
      }
    }
    return result;
  }

  generatePassword(event: React.MouseEvent): void {
    event.preventDefault();
    let result = PasswordGenerator.generateCustomPassword();
    this.setShowPassword(true);
    this.setValue(result, null);
  }

  showPassword(event: React.MouseEvent): void {
    event.preventDefault();
    this.setShowPassword(!this.state.showPassword);
  }

  isExtreme(value: string): boolean {
    const extremePassPattern = this.buildPasswordPattern([
      (val) => val.length >= 14,
      (val) => this.isMixedCase(val),
      (val) => this.containsDigits(val),
      (val) => this.containsSpecialChars(val),
    ]);
    return !!value && extremePassPattern(value);
  }

  isStrong(value: string): boolean {
    const strongPassPattern = this.buildPasswordPattern([
      (val) => val.length >= 10,
      (val) => this.isMixedCase(val),
      (val) => this.containsNonAlphabetChars(val),
    ]);
    return !!value && strongPassPattern(value);
  }

  isGood(value: string): boolean {
    const goodPassPattern = this.buildPasswordPattern([
      (val) => val.length >= 6,
      (val) => this.isMixedCase(val),
      (val) => this.containsNonAlphabetChars(val),
    ]);
    return value && (value.length >= 8 || goodPassPattern(value));
  }

  buildPasswordPattern(
    rules: ((value: string) => boolean)[]
  ): (arg: string) => boolean {
    return (value: string) => {
      let result: boolean = true;
      for (let i = 0; i < rules.length; i++) {
        const rule = rules[i];
        const ruleResult = rule(value);
        result = result && ruleResult;
      }
      return result;
    };
  }

  isMixedCase(str: string): boolean {
    return !(str === str.toUpperCase() || str === str.toLowerCase());
  }

  containsDigits(value: string): boolean {
    return /\d/.test(value);
  }

  containsSpecialChars(value: string): boolean {
    return /[^a-z0-9\s]/i.test(value);
  }

  containsNonAlphabetChars(value: string): boolean {
    return /[^a-z\s]/i.test(value);
  }
}
