import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { startOfMonth, endOfMonth } from 'date-fns';

import { DropDownApplicationUserType } from 'components/project/dropdown/ApplicationUsersDropdown';
import { DropDownYear } from 'components/project/dropdown/YearSelectionDropdown';
import { DropDownMonth } from 'components/project/dropdown/MonthSelectionDropdown';
import { InputCalendar } from 'components/form/inputCalendar';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';
import resolver from './validation';

import { getDateString } from 'utils/DateUtils';

const ASSIGN_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'Y', label: 'Assigned' },
  { value: 'N', label: 'Not Assigned' },
];

export const initialValues: FilterType = {
  employeeName: '',
  employeeTypeId: 0,
  year: new Date().getFullYear(),
  month: null,
  assign: 'Y',
  dateFrom: getDateString(startOfMonth(new Date())),
  dateTo: getDateString(endOfMonth(new Date())),
};

interface FilterType {
  employeeName: string;
  employeeTypeId: 0;
  year: null | number;
  month: null | number;
  assign: 'Y' | 'N' | '';
  dateFrom: string;
  dateTo: string;
}

interface PropsType {
  defaultValues?: FilterType;
  fetching: boolean;
  isVisible: boolean;
  onSubmit: (data: FilterType) => void;
}

const FilterHrAdmin = ({
  isVisible,
  defaultValues = initialValues,
  fetching,
  onSubmit,
}: PropsType) => {
  const { control, watch, reset, setValue, handleSubmit } = useForm<FilterType>(
    {
      defaultValues,
      resolver,
    }
  );

  const [month, year] = watch(['month', 'year']);

  const [minDate, maxDate] = useMemo(() => {
    if (year) {
      return [
        new Date(year, month || 0),
        endOfMonth(new Date(year, month || 11)),
      ];
    }
    return [null, null];
  }, [month, year]);

  const handleClickReset = () => {
    reset(initialValues);
    onSubmit(initialValues);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'year' || name === 'month') {
        setValue('dateFrom', '');
        setValue('dateTo', '');
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form
      className={`row mb-4${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="employeeName"
        label="Employee Name"
        className="col-md-3"
        control={control}
      />
      <DropDownApplicationUserType
        name="employeeTypeId"
        label="Employee Type"
        className="col-md-4"
        control={control}
      />
      <Radio
        name="assign"
        label="Assign"
        className="col-md-5 part-inline"
        options={ASSIGN_OPTIONS}
        control={control}
      />
      <div />
      <DropDownYear
        name="year"
        label="Select Year"
        className="col-sm-6 col-md-3 col-lg-3 col-xl-2"
        control={control}
      />
      <DropDownMonth
        name="month"
        label="Select Month"
        className="col-sm-6 col-md-3 col-lg-3 col-xl-2"
        control={control}
      />
      <InputCalendar
        name="dateFrom"
        label="Date From"
        className="col-sm-6 col-md-3 col-lg-3"
        minDate={minDate}
        maxDate={maxDate}
        showDisabledMonthNavigation
        control={control}
      />
      <InputCalendar
        name="dateTo"
        label="Date To"
        className="col-sm-6 col-md-3 col-lg-3"
        minDate={minDate}
        maxDate={maxDate}
        showDisabledMonthNavigation
        control={control}
      />

      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default FilterHrAdmin;
