import { memo } from 'react';

const imgStyle = {
  width: '19px',
  height: '19px',
};

const Legend = () => (
  <div className="legend">
    <div className="legend-title">Icons Color Legend:</div>
    <div className="legend-item">
      <img
        src="/assets/images/info_available.png"
        alt="All data is valid"
        style={imgStyle}
      />
      <span>All data is valid</span>
    </div>
    <div className="legend-item">
      <img
        src="/assets/images/info_unavailable.png"
        alt="Some fields are not filled in"
        style={imgStyle}
      />
      <span>Some fields are not filled in</span>
    </div>
    <div className="legend-item">
      <img
        src="/assets/images/info_reassigned.png"
        alt="POS changed automatically (for PC only)"
        style={imgStyle}
      />
      <span>POS changed automatically (for PC only)</span>
    </div>
    <div className="legend-item">
      <img
        src="/assets/images/info_unavailable_blue.png"
        alt="No insurances are loaded"
        style={imgStyle}
      />
      <span>No insurances are loaded</span>
    </div>
  </div>
);

export default memo(Legend);
