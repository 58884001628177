import { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import DialogAuditLog from 'page/workflow/order/components/DialogAuditLog';
import EncounterMessageDialog from 'page/components/messages/EncounterMessageDialog';
import VisitInformationPage from 'page/workflow/order/VisitInformationPage';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import { Button } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import { LayoutSideTitle } from 'components/layout';
import Filter from './components/Filter';
import columnsPrettier from './components/ColumnPrettier';
import DialogVerifyInsurance from '../components/DialogVerifyInsurance';

import {
  storeInsuranceVerification,
  FilterType,
} from 'stores/_mobx/billingCodding/insuranceVerification';
import UserProfileStore from 'stores/UserProfileStore';
import { INSURANCE_VERIFY as PAGE_ID } from 'constant/pagesId/billingCodding';

const getStartShownColumns = (): Map<string, boolean> =>
  new Map<string, boolean>([
    ['refid', false],
    ['sessionno', false],
    ['state', false],
    ['location', false],
    ['pos', false],
    ['technologistnm', false],
    ['orderassigned_date', false],
    ['cptdesc', false],
    ['icddesc', false],
    ['Auditlog', false],
  ]);

const InsuranceVerificationPage = ({
  isDivisionEnabled,
}: PropsTypeWitDivisionSettings) => {
  const {
    fetching,
    insuranceList,
    insuranceTotal,
    insurancePreview,
    auditLogProps,
    idForOrderMessage,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeInsuranceVerification;

  const gridRef = useRef<Grid>(null);

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [showVerifyInsurance, toggleVerify] = useState<boolean>(false);

  const [selectedIds, setSelected] = useState<number[]>([]);

  const columns = useMemo(
    () =>
      columnsPrettier({
        isClientUser: UserProfileStore.isClientUser(),
        isDivisionEnabled,
      }),
    [isDivisionEnabled]
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleVerify = () => {
    toggleVerify((state) => !state);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeInsuranceVerification.setFilter(filter);
    setPaginationToStart();
  };

  const handleVerifyInsurance = (isWaiting: boolean) => {
    storeInsuranceVerification
      .verifyInsurance({ isWaiting, selectedIds })
      .then((isSucceed) => {
        if (isSucceed) {
          gridRef.current?.clearSelection();
          storeInsuranceVerification.getInsuranceListMain(isDivisionEnabled);
          toggleVerify(false);
        }
      });
  };

  const handleCloseOrderMessage = (shouldUpdate: boolean) => {
    if (shouldUpdate)
      storeInsuranceVerification.getInsuranceListMain(isDivisionEnabled);
    storeInsuranceVerification.clearIdForOrderMessage();
  };

  useEffect(() => {
    storeInsuranceVerification.getInsuranceListMain(isDivisionEnabled);
  }, [isDivisionEnabled, pagination]);

  useEffect(() => storeInsuranceVerification.clearInsurancePreview, []);

  return (
    <>
      {insurancePreview && (
        <VisitInformationPage
          callback={storeInsuranceVerification.clearInsurancePreview}
          // @ts-ignore
          selectedOrder={insurancePreview}
        />
      )}
      <div
        className={`claim-management-insurance-verification ${
          insurancePreview ? 'visually-hidden' : ''
        }`}>
        <LayoutSideTitle appId={PAGE_ID.PAGE} title="Insurance Verification">
          <Button
            variant="default"
            data-testid="toggleFilter"
            text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
            onClick={handleToggleFilter}
          />
        </LayoutSideTitle>
        <Filter
          fetching={fetching}
          isFilterVisible={isFilterVisible}
          onApply={handleChangeFilter}
        />

        <Grid
          id="claim_management_insurance_verification_grid"
          selectId="refid"
          columns={columns}
          onAjax={fetching}
          dataSource={insuranceList}
          dataSourceCount={insuranceTotal}
          ref={gridRef}
          selectedIds={selectedIds}
          onSelectChange={setSelected}
          pagination={pagination}
          shownColumns={getStartShownColumns()}
          gridControlPanel={
            <GridControlButton
              title="Verify Insurance"
              disabled={!selectedIds.length}
              onClick={handleToggleVerify}
            />
          }
          onPaginationChange={setPagination}
          onDoubleClick={storeInsuranceVerification.setInsurancePreview}
        />

        {auditLogProps && (
          <DialogAuditLog
            order={auditLogProps}
            onClose={storeInsuranceVerification.clearAuditLogProps}
          />
        )}

        {showVerifyInsurance && (
          <DialogVerifyInsurance
            onApprove={handleVerifyInsurance}
            onClose={handleToggleVerify}
          />
        )}

        {idForOrderMessage ? (
          <EncounterMessageDialog
            id={idForOrderMessage}
            onClose={handleCloseOrderMessage}
          />
        ) : null}
      </div>
    </>
  );
};

export default withDivisionSettings(observer(InsuranceVerificationPage));
