import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import { TClaimManager } from 'services/billing-coding/claims/ClaimsService';
import { dateToLocalTimezone } from 'utils/DateUtils';

export class PClaimsAuditStore extends CommonPayload {}

export class SClaimsAuditStore {
  dataSource: Array<TClaimManager> = [];
  dataSourceCount: number = 0;
}

class ClaimsAuditStore extends ReduceStore<
  SClaimsAuditStore,
  PClaimsAuditStore
> {
  getInitialState() {
    return new SClaimsAuditStore();
  }

  areEqual(state1: SClaimsAuditStore, state2: SClaimsAuditStore) {
    return false;
  }

  reduce(state: SClaimsAuditStore, action: PClaimsAuditStore) {
    const storeState = this.getState();
    switch (action.type) {
      case 'claim-audit-overview':
        storeState.dataSource = action.data.map((el: TClaimManager) => ({
          ...el,
          dos: dateToLocalTimezone({ date: el.dos, dateOnly: true }),
        }));
        storeState.dataSourceCount = (action as any).count;
        break;
      default:
        break;
    }
    return storeState;
  }
}

export default new ClaimsAuditStore(appDispatcher);
