import { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import PieChart from 'components/project/common/charts/PieChart';
import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import Exporter from 'components/project/ExporterNew';
import Filter from './components/Filter';

import UserProfileStore from 'stores/UserProfileStore';
import {
  storeRadiologyReport,
  FilterType,
} from 'stores/_mobx/report/radiologyReport';
import { RADIOLOGY_REPORT as PAGE_ID } from 'constant/pagesId/reports';

const columns = [
  { name: 'studyid', title: 'Study ID' },
  { name: 'radioname', title: 'Radiologist name' },
  { name: 'rgname', title: 'Radiology group name' },
  { name: 'faci_name', title: 'Facility' },
  { name: 'patient', title: 'Patient name' },
  { name: 'mrn', title: 'Patient MRN' },
  { name: 'accessionno', title: 'Accession No.' },
  { name: 'dob', title: 'DOB', className: 'text-nowrap' },
  { name: 'modality', title: 'Modality' },
  {
    name: 'sheduleservdate',
    title: 'DOS',
    className: 'text-nowrap',
    dataType: 'date',
  },
  { name: 'cptcnt', title: 'Provider/Technician CPT count' },
  { name: 'p_cptcnt', title: 'Professional/Group CPT count' },
];

const RadiologyReportPage = () => {
  const {
    fetching,
    logList,
    logTotal,
    chartData,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeRadiologyReport;

  const gridRef = useRef<Grid>(null);

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const [isChartVisible, toggleChart] = useState<boolean>(false);

  const appName = useMemo(
    () =>
      UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
      'Radiology_Group_Report',
    []
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleToggleChart = () => {
    toggleChart((state) => !state);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeRadiologyReport.setFilter(filter);

    setPaginationToStart();
  };

  useEffect(() => {
    storeRadiologyReport.setDefaultFilter();
  }, []);

  useEffect(() => {
    storeRadiologyReport.getLogsListMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Radiology Report">
        <Button
          text={isChartVisible ? 'Grid View' : 'Chart View'}
          disabled={!logList.length}
          onClick={handleToggleChart}
        />
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        isVisible={isFilterVisible}
        fetching={fetching}
        onSubmit={handleChangeFilter}
      />

      {isChartVisible ? (
        <PieChart className="col-lg-6" data={chartData} />
      ) : (
        <Grid
          id="radiology_report_grid"
          noDataText="Please refine your search using the filters above"
          ref={gridRef}
          columns={columns}
          onAjax={fetching}
          dataSource={logList}
          dataSourceCount={logTotal}
          pagination={pagination}
          onPaginationChange={setPagination}
          gridControlPanel={
            <GridControlButton title="Export" onClick={handleToggleExport} />
          }
        />
      )}

      {isExportVisible && (
        <Exporter
          exporter="RadilogyReportPageExporter"
          reportName={appName}
          filter={storeRadiologyReport.prepareFilterForExport()}
          gridRef={gridRef}
          columns={columns}
          dataSource={logList}
          pdfExporter={storeRadiologyReport.generatePdf}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default observer(RadiologyReportPage);
