import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { emailValidator } from 'utils/formValidators';

const validation = yupResolver(
  yup.object().shape({
    template: yup
      .string()
      .typeError('Select email template')
      .required('Select email template'),
    emailsList: yup
      .string()
      .required('Fill in your email address')
      .test({
        test: (emailsList: string) =>
          emailsList.split(',').every((address) => {
            const res = emailValidator(address.trim());

            return res;
          }),
        message: 'Invalid email address.',
      }),
    body: yup.string().test({
      test: (body: string) => body && body !== '<p><br></p>',
      message: 'Please, fill in email message!',
    }),
  })
);

export default validation;
