import Notification from 'components/modal/Notification';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

const optionsKeyMapper = {
  'orm_send_events:send_orm': 'optionsORM',
  'oru_send_events:send_oru': 'optionsORU',
} as const;

const defaultOptionSettings = (
  Object.keys(optionsKeyMapper) as Action[]
).reduce(
  (prev, action) => ({
    ...prev,
    [action]: '',
  }),
  {} as SettingsFormType
);

type Action = keyof typeof optionsKeyMapper;

export interface OptionResponseType<T> {
  path: Action;
  value: T;
  possibleValues: OptionType[];
}

interface OptionType {
  label: string;
  value: string;
}

export interface SettingsFormType extends Record<Action, string> {}

class Integration {
  fetchingOptions: boolean = false;
  optionsORM: OptionType[] = [];
  optionsORU: OptionType[] = [];
  optionsSettings: SettingsFormType = defaultOptionSettings;

  constructor() {
    makeObservable(this, {
      fetchingOptions: observable,
      optionsSettings: observable,
      optionsORM: observable,
      optionsORU: observable,

      setFetchingOptions: action,
    });
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  getOptions = async (keyAction: Action) => {
    const optionKey = optionsKeyMapper[keyAction];

    try {
      const { possibleValues, value } = await apiRequest<
        OptionResponseType<string>
      >({
        url: `system/application_settings/${keyAction}`,
        method: 'GET',
        legacy: false,
      });

      runInAction(() => {
        this.optionsSettings[keyAction] = value || '';

        this[optionKey] = possibleValues;
      });
    } catch (e: any) {
      runInAction(() => {
        this[optionKey] = [];
        this.optionsSettings[keyAction] = '';
      });
    }
  };

  async getAllOptions() {
    this.setFetchingOptions(true);

    const promises = (Object.keys(optionsKeyMapper) as Action[]).map(
      this.getOptions
    );

    Promise.all(promises).then(() => {
      this.setFetchingOptions(false);
    });
  }

  async updateSettings({ path, value }: { path: Action; value: string }) {
    try {
      const response = await apiRequest<OptionResponseType<string>>({
        url: `system/application_settings/${path}`,
        method: 'PUT',
        legacy: false,
        data: { value },
      });

      if (value === response.value) {
        Notification.success('Settings were saved successfully!');
      } else {
        Notification.danger('Settings were not saved!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'Something went wrong!');
    }
  }
}

export const storeIntegration = new Integration();
