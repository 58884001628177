import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import FormPerDiem, { FormModel } from './components/Form';

import { storePerDiem } from 'stores/_mobx/systemSetup/masterSetting/perDiem';
import { URL_PER_DIEM } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const PerDiemEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, perDiem } = storePerDiem;

  const handleSubmit = (data: FormModel) =>
    storePerDiem.updatePerDiem(data as Required<FormModel>).then((response) => {
      if (response) return response;
      history.push(URL_PER_DIEM);
    });

  useEffect(() => {
    storePerDiem.getPerDiem(params.id);
    return storePerDiem.clearPerDiem;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Per Diem" />
      <FormPerDiem
        defaultValues={perDiem}
        backUrl={URL_PER_DIEM}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(PerDiemEditPage);
