import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import RadioOptions from 'components/project/common/RadioOptions';

import {
  storeFinding,
  FilterModel,
} from 'stores/_mobx/systemSetup/masterSetting/finding';

const categoryList = [
  { label: 'All', value: '' },
  { label: 'Positive', value: 'P' },
  { label: 'Negative', value: 'N' },
  { label: 'Formative', value: 'F' },
  { label: 'Indeterminate', value: 'I' },
];

interface PropsType {
  onSearch: (payload: FilterModel) => void;
}

const Filter = ({ onSearch }: PropsType) => {
  const { control, watch } = useForm<FilterModel>({
    defaultValues: storeFinding.filter,
  });

  useEffect(() => {
    const subscription = watch((formValue) => {
      onSearch(formValue as FilterModel);
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row">
      <Input
        className="col-md-6 col-lg-4"
        name="name"
        label="Name"
        control={control}
      />
      <Radio
        className="col-md-6 inline"
        name="status"
        label="Status"
        options={RadioOptions.active}
        control={control}
      />
      <Radio
        className="col-sm-12 inline"
        name="category"
        label="Category"
        options={categoryList}
        control={control}
      />
    </form>
  );
};

export default Filter;
