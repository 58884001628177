import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import { dateToLocalTimezone } from 'utils/DateUtils';

const buildActionName = (name: string) => {
  return 'patient-records/document-manager/document-audit/facility/' + name;
};

export const Actions = {
  LOAD_DOCUMENT_FACILITY_COUNT_ACTION: buildActionName(
    'load-document-facility-count-action'
  ),
  LOAD_TRANSMIT_LOG_LIST_ACTION: buildActionName(
    'load-transmit-log-list-action'
  ),
};

class SFacilityTabStore {
  existingDocuments: Array<any> = [];
  documentFacilityList: Array<any> = [];
  transmitLogList: Array<any> = [];
  transmitLogCount: number = 0;
  documentFacilityCount: number = 0;
}

class FacilityTabStore extends ReduceStore<SFacilityTabStore, CommonPayload> {
  getInitialState() {
    return new SFacilityTabStore();
  }

  areEqual(state1: SFacilityTabStore, state2: SFacilityTabStore) {
    return false;
  }

  reduce(state: SFacilityTabStore, action: CommonPayload) {
    switch (action.type) {
      case 'facility.DocumentManager.DocFacilityList':
        this.getState().documentFacilityList = action.data;
        this.getState().documentFacilityCount = (action as any).count;
        break;
      case Actions.LOAD_TRANSMIT_LOG_LIST_ACTION:
        this.getState().transmitLogList = action.data.map((el: any) => ({
          ...el,
          dos: dateToLocalTimezone({
            date: el.sheduleservdate,
            dateOnly: true,
          }),
        }));
        this.getState().transmitLogCount = (action as any).count;
        break;
      default:
        break;
    }
    return state;
  }
}

const facilityTabStore = new FacilityTabStore(appDispatcher);
export default facilityTabStore;
