import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    vehicle: yup.string().required('Please, enter the name!'),
    regnumber: yup.string().required('Please, the license plate number!'),
    vehicleyear: yup.number().test({
      test: (year: number) => /^(\d){4}$/.test(String(year)) && year > 1900,
      message: 'Year is not correct!',
    }),
  })
);

export default validation;
