import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    document: yup.mixed().test({
      test: (file: File | string) => Boolean(file),
      message: 'Please, select the file!',
    }),
    vaccinationTypeId: yup
      .number()
      .typeError('Please, select a vaccination!')
      .min(1, 'Please, select a vaccination!'),
  })
);

export default validation;
