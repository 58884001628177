import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

interface CodeResponseType {
  exam_type_nm: string;
  refid: string;
}

interface CodeType {
  name: string;
  id: number;
}

interface CptResponseType {
  label: string;
  data: number;
}

interface CptType {
  label: string;
  value: number;
}

interface CodeDetailResponseType {
  CptCodeArr: CptResponseType[];
  data: number;
  exam_type_nm: string;
  refid: number;
}

export interface CodeDetailType {
  cpt: CptType[];
  serviceId: number;
  name: string;
  id: number;
  filterQCode: boolean;
  filterRCode: boolean;
}

export interface PayloadGetCpt {
  id: number;
  filterQCode: boolean;
  filterRCode: boolean;
}

class CodeMapping {
  fetching: boolean = false;
  fetchingCptCodes: boolean = false;
  codeList: CodeType[] = [];
  codeTotal: number = 0;
  code?: CodeDetailType;
  cptCodes: CptType[] = [];
  filter: string = '';
  idForDelete: number = 0;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'code_set_code_mapping_by_modality' });

    makeObservable(this, {
      fetching: observable,
      fetchingCptCodes: observable,
      codeList: observable,
      codeTotal: observable,
      code: observable,
      cptCodes: observable,
      idForDelete: observable,
      filter: observable,

      setFetching: action,
      setFetchingCptCodes: action,
      setCodesList: action,
      setCodesTotal: action,
      setCode: action,
      setCptCodes: action,
      setFilter: action,
      setIdForDelete: action,
      clearIdForDelete: action.bound,
      clearCode: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingCptCodes(fetching: boolean) {
    this.fetchingCptCodes = fetching;
  }

  setCodesList(codes: CodeType[]) {
    this.codeList = codes;
  }

  setCodesTotal(count: number) {
    this.codeTotal = count;
  }

  setFilter(filter: string) {
    this.filter = filter;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setCode(code: CodeDetailType) {
    this.code = code;
  }

  setCptCodes(list: CptType[]) {
    this.cptCodes = list;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  clearCode() {
    this.code = undefined;
    this.cptCodes = [];
  }

  async getCodesList(filter: string) {
    const { pagination } = this.page;

    try {
      const response = await apiRequest<CodeResponseType[]>({
        url: 'codemaster.CPTCode.ServiceList',
        data: [filter, pagination.skip, pagination.pageSize],
      });

      const codes = response.map((code) => ({
        name: code.exam_type_nm,
        id: Number(code.refid),
      }));

      this.setCodesList(codes);
    } catch (e: any) {
      this.setCodesList([]);
    }
  }

  async getCodesCount(filter: string) {
    try {
      const count = await apiRequest<string>({
        url: 'codemaster.CPTCode.ServiceListTotalCount',
        data: [filter],
      });
      this.setCodesTotal(Number(count) || 0);
    } catch (e) {
      this.setCodesTotal(0);
    }
  }

  getCodesListMain = (filter: string = this.filter) => {
    this.setFetching(true);

    const promiseCount = this.getCodesCount(filter);

    const promiseRegions = this.getCodesList(filter);

    Promise.allSettled([promiseCount, promiseRegions]).finally(() => {
      this.setFetching(false);
    });
  };

  async getCode(id: number) {
    this.setFetching(true);
    try {
      const [response] = await apiRequest<[CodeDetailResponseType]>({
        url: 'codemaster.CPTCode.GetGroupCodeByID',
        data: [id],
      });

      const cpt = response.CptCodeArr.map(({ data, label }) => ({
        label,
        value: data,
      }));

      const code = {
        cpt,
        name: response.exam_type_nm,
        serviceId: response.data,
        id: response.refid,
        filterQCode: false,
        filterRCode: false,
      };

      this.setCode(code);
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
    } finally {
      this.setFetching(false);
    }
  }

  getCptCodes = async ({ id, filterQCode, filterRCode }: PayloadGetCpt) => {
    const data = [
      '',
      0,
      1000000,
      '',
      filterQCode ? 'Q' : '',
      filterRCode ? 'R' : '',
      id,
    ];

    this.setFetchingCptCodes(true);
    try {
      const response = await apiRequest<CptResponseType[]>({
        url: 'codemaster.CPTCode.GetCPTCodeList',
        data,
      });

      const codes = response.map(({ data, label }) => ({
        label,
        value: data,
      }));

      this.setCptCodes(codes);
    } catch (e: any) {
      this.setCptCodes([]);
    } finally {
      this.setFetchingCptCodes(false);
    }
  };

  async addCode({ serviceId, cpt }: CodeDetailType) {
    const data = cpt.map(({ label, value }) => ({ label, data: value }));

    try {
      const response = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'codemaster.CPTCode.AddService',
        data: [serviceId, data],
      });
      if (response === 'S') {
        Notification.success('Code mapping was added!');
        return null;
      }
      if (response === 'E') {
        return { message: 'Service name already exists!' };
      }
      throw Error('');
    } catch (e: any) {
      return { message: '' };
    }
  }

  async updateCode({ id, serviceId, cpt }: CodeDetailType) {
    const data = cpt.map(({ label, value }) => ({ label, data: value }));

    try {
      const response = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'codemaster.CPTCode.EditService',
        data: [id, serviceId, data],
      });
      if (response === 'S') {
        Notification.success('Code Mapping was updated!');
        return null;
      }
      if (response === 'E') {
        return { message: 'Service name already exists!' };
      }
      throw Error('');
    } catch (e: any) {
      return { message: '' };
    }
  }

  async deleteCode() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'codemaster.CPTCode.DeleteService',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Code was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('An error occurred!');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }
}

export const storeCodeMapping = new CodeMapping();
