import { useState, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
// @ts-ignore
import useFluxStore from 'flux-hooks';

import EmailFormWrapper from '../components/EmailFormWrapper';

import SentPageActions from 'actions/messages/sent/SentPageActions';
import SentPageStore from 'stores/messages/sent/SentPageStore';
import { emailForwardFormatter } from 'utils/emailValuesFormatter';
import { URL_SENT_MAIL } from 'constant/path/messages';

interface MatchParams {
  id: string;
}
interface PropsType extends RouteComponentProps<MatchParams> {}

const SentForwardPage = ({ match: { params } }: PropsType) => {
  const [fetching, setFetching] = useState<boolean>(true);

  const messageDetailed = useFluxStore(
    SentPageStore,
    (prevState: any, store: any) => store.getState().messageDetailed
  );

  const defaultValues = useMemo(
    () => (messageDetailed ? emailForwardFormatter(messageDetailed) : null),
    [messageDetailed]
  );

  useEffect(() => {
    SentPageActions.getSentMessageDetails(params.id).then(() => {
      setFetching(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EmailFormWrapper
      title="Sent: forward"
      backLink={URL_SENT_MAIL}
      fetching={fetching}
      defaultValues={defaultValues}
    />
  );
};

export default SentForwardPage;
