export const exampleColumns = [
  { name: 'modifier_code', title: 'modifier_code*' },
  { name: 'modifier_desc', title: 'modifier description*' },
];

export const exampleData = [
  {
    modifier_code: '50',
    modifier_desc: 'Bilateral Procedure',
  },
  {
    modifier_code: '26',
    modifier_desc: 'Professional Components',
  },
];
