import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import { storeImport } from 'stores/_mobx/import';
import { OptionResponseType } from 'stores/_mobx/integration';
import { fileNamePrettier } from 'utils/fileNamePrettier';

const billingAddress: DivisionBillingAddressType = {
  address1: '',
  address2: '',
  city: '',
  email: '',
  stateId: 0,
  zipCode: '',
  phone: '',
  fax: '',
  tollFreeNumber: '',
};

export const defaultDivisionSettings: DivisionDetailsType = {
  id: 0,
  name: '',
  shortName: '',
  overrideCompanyInformation: false,
  divisionLogo: null,
  divisionLogoId: 0,
  divisionCompanyAddress: billingAddress,
  divisionBillingAddress: billingAddress,
};

export interface DivisionStateFormModel {
  divisionState: boolean;
}

interface DivisionResponseType {
  id: number;
  name: string;
  shortName: string;
  overrideCompanyInformation: boolean;
  divisionLogo?: string;
  divisionLogoId?: number;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
}

interface DivisionDetailsResponseType extends DivisionResponseType {
  divisionCompanyAddress: DivisionBillingAddressType;
  divisionBillingAddress: DivisionBillingAddressType;
}

interface DivisionBillingAddressType {
  id?: number;
  address1: string;
  address2: string;
  city: string;
  email: string;
  state?: string;
  stateId?: number;
  zipCode: string;
  phone: string;
  fax: string;
  tollFreeNumber: string;
}

interface LogoResponseType {
  id: number;
  contentUrl: string;
}

export interface DivisionType {
  id: number;
  name: string;
  shortName: string;
  overrideCompanyInformation: boolean;
}

export interface DivisionDetailsType
  extends Omit<DivisionType, 'divisionLogo'> {
  divisionLogo: string | File | null;
  divisionLogoId: number;
  divisionCompanyAddress: DivisionBillingAddressType;
  divisionBillingAddress: DivisionBillingAddressType;
}

export interface OptionType {
  value: number;
  label: string;
}

export class DivisionSettingsStatus {
  fetching: boolean = false;
  isDivisionEnabled: boolean = false;

  constructor() {
    makeObservable(this, {
      isDivisionEnabled: observable,
      fetching: observable,

      setFetchingState: action,
      clearState: action.bound,
    });
  }

  setFetchingState(fetching: boolean) {
    this.fetching = fetching;
  }

  clearState() {
    this.isDivisionEnabled = false;
  }

  async getDivisionState() {
    if (this.fetching) return Promise.resolve();

    this.setFetchingState(true);

    try {
      const { value } = await apiRequest<OptionResponseType<boolean>>({
        url: 'system/application_settings/features_switch:facility_division',
        method: 'GET',
        legacy: false,
      });

      runInAction(() => {
        this.fetching = false;
        this.isDivisionEnabled = value;
      });
    } catch (e: unknown) {
      runInAction(() => {
        this.fetching = false;
        this.isDivisionEnabled = false;
      });
    }
  }
}

class DivisionMaster {
  fetching: boolean;
  fetchingOptions: boolean = false;
  divisionsList: DivisionType[] = [];
  divisionSettings: DivisionDetailsType | undefined;
  idForDelete: number | null = null;
  options: OptionType[] = [];
  filter: string = '';
  storeDivisionSettingsStatus: DivisionSettingsStatus = null;

  constructor(storeDivisionSettingsStatus: DivisionSettingsStatus) {
    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      divisionsList: observable,
      divisionSettings: observable,
      idForDelete: observable,
      options: observable,
      filter: observable,

      divisions: computed,
      appSettings: computed,

      setFetching: action,
      setFetchingOptions: action,
      setDivisions: action,
      setDivisionSettings: action,
      setIdForDelete: action,
      setFilter: action.bound,
      clearOptions: action.bound,
      clearIdForDelete: action.bound,
      clearDivisionSettings: action.bound,
      clearAppSettings: action.bound,
    });

    this.storeDivisionSettingsStatus = storeDivisionSettingsStatus;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setFilter(name: string) {
    this.filter = name;
  }

  setDivisions(divisions: DivisionType[]) {
    this.divisionsList = divisions;
  }

  setDivisionSettings(division: DivisionDetailsType) {
    this.divisionSettings = division;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  clearIdForDelete() {
    this.idForDelete = null;
  }

  clearDivisionSettings() {
    this.divisionSettings = undefined;
  }

  clearOptions() {
    this.options = [];
  }

  clearAppSettings() {
    this.storeDivisionSettingsStatus.clearState();
  }

  get appSettings(): DivisionStateFormModel {
    return {
      divisionState: this.storeDivisionSettingsStatus.isDivisionEnabled,
    };
  }

  get divisions() {
    const { filter, divisionsList } = this;

    const filteredDivisions = divisionsList.filter(({ name }) =>
      name.toLowerCase().includes(filter.toLowerCase())
    );

    return {
      divisionsList: filteredDivisions,
      divisionsTotal: filteredDivisions.length,
    };
  }

  async getDivisions() {
    this.setFetching(true);
    try {
      const response = await apiRequest<DivisionResponseType[]>({
        url: 'divisions?page=1',
        method: 'GET',
        legacy: false,
      });

      const divisions = response.map(
        ({ id, name, shortName, overrideCompanyInformation }) => ({
          id,
          name,
          shortName,
          overrideCompanyInformation,
        })
      );

      this.setDivisions(divisions);
    } catch (e: any) {
      this.setDivisions([]);
    } finally {
      this.setFetching(false);
    }
  }

  async getDivision(id: number) {
    this.setFetching(true);
    try {
      const { createdAt, createdBy, updatedAt, divisionLogoId, ...rest } =
        await apiRequest<DivisionDetailsResponseType>({
          url: `divisions/${id}`,
          method: 'GET',
          legacy: false,
        });

      const filePath = divisionLogoId
        ? await this.getDivisionLogo(divisionLogoId)
        : '';

      const divisionSettings = {
        ...rest,
        divisionLogoId: divisionLogoId || 0,
        divisionLogo: filePath || null,
      };

      this.setDivisionSettings(divisionSettings);
    } catch (e: any) {
      Notification.danger("Division you request doesn't exist!");
      this.setDivisionSettings(defaultDivisionSettings);
    } finally {
      this.setFetching(false);
    }
  }

  async modifyDivision({
    id,
    divisionLogo,
    divisionLogoId,
    divisionBillingAddress,
    divisionCompanyAddress,
    ...rest
  }: DivisionDetailsType) {
    const isEditDivision = Boolean(id);

    this.setFetching(true);

    try {
      const logoId = await this.uploadDivisionLogo({
        logoId: divisionLogoId,
        file: divisionLogo,
      });

      const divisionData = {
        ...rest,
        ...(id ? { id } : {}),
        ...(logoId
          ? {
              divisionLogo: `division_logos/${logoId}`,
              divisionLogoId: logoId,
            }
          : {}),
        divisionBillingAddress: {
          ...divisionBillingAddress,
          state: divisionBillingAddress.stateId
            ? `states/${divisionBillingAddress.stateId}`
            : null,
        },
        divisionCompanyAddress: {
          ...divisionCompanyAddress,
          state: divisionCompanyAddress.stateId
            ? `states/${divisionCompanyAddress.stateId}`
            : null,
        },
      };

      const response = await apiRequest<DivisionResponseType>({
        url: isEditDivision ? `divisions/${id}` : 'divisions',
        method: isEditDivision ? 'PATCH' : 'POST',
        legacy: false,
        data: divisionData,
      });

      if (response.id) {
        Notification.success(
          isEditDivision
            ? 'Division updated successfully!'
            : 'Division added successfully!'
        );
        return null;
      }
      return { message: '' };
    } catch (e: any) {
      this.setFetching(false);
      return {
        message: 'Division with such name already exist!',
      };
    }
  }

  async deleteDivision() {
    if (this.fetching) return Promise.resolve(false);
    this.setFetching(true);
    try {
      await apiRequest<DivisionResponseType>({
        url: `divisions/${this.idForDelete}`,
        method: 'DELETE',
        legacy: false,
      });

      Notification.success('Division deleted successfully!');
      return true;
    } catch (e: any) {
      Notification.danger(
        e?.message ||
          "This Division already in use. You can't delete this division!"
      );
      this.setFetching(false);
      return false;
    } finally {
      this.clearIdForDelete();
    }
  }

  // START: Division Logo management
  async getDivisionLogo(id: number) {
    try {
      const { contentUrl } = await apiRequest<LogoResponseType>({
        url: `division_logos/${id}`,
        method: 'GET',
        legacy: false,
      });

      return contentUrl;
    } catch (e: any) {
      return '';
    }
  }

  async uploadDivisionLogo({
    file: fileRaw,
    logoId,
  }: {
    file: string | File;
    logoId: number;
  }) {
    try {
      const isFileChanged = fileRaw && typeof fileRaw !== 'string';

      const file = fileNamePrettier(fileRaw);

      const { id } = isFileChanged
        ? await storeImport.uploadFileNewApi<LogoResponseType>({
            url: logoId ? `division_logos/${logoId}` : 'division_logos',
            payload: { file },
          })
        : { id: logoId };

      return id;
    } catch (e: any) {
      Notification.warning('Logo loading Error!');
      return null;
    }
  }
  // END: Division Logo management

  async getOptions() {
    const { isDivisionEnabled } = this.storeDivisionSettingsStatus;

    if (this.fetchingOptions) return Promise.resolve();

    try {
      if (!isDivisionEnabled) throw new Error('');

      this.setFetchingOptions(true);

      const response = await apiRequest<DivisionResponseType[]>({
        url: 'divisions?page=1',
        method: 'GET',
        legacy: false,
      });

      const options = response.map(({ id, name }) => ({
        label: name,
        value: id,
      }));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }

  getDivisionSettings() {
    this.storeDivisionSettingsStatus.getDivisionState();
  }

  findOptionById = (id: number) =>
    this.options.find((division) => division.value === id);
}

export const storeDivisionSettingsStatus = new DivisionSettingsStatus();

export const storeDivisionMaster = new DivisionMaster(
  storeDivisionSettingsStatus
);
