import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Grid } from 'components/grid';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';

import FacilityPageStore from 'stores/system-setup/facility/FacilityPageStore';

interface ContactType {
  comments: string;
  contact_title: string;
  email: string;
  fax: string;
  first_name: string;
  fullname: string;
  is_selected: boolean;
  last_name: string;
  phone: string;
  refid: number;
  status: string;
}

const COLUMN = [
  {
    name: 'first_name',
    title: 'First name',
  },
  {
    name: 'last_name',
    title: 'Last name',
  },
  {
    name: 'contact_title',
    title: 'Contact title',
  },
];

const defaultValues: FormModel = {
  firstName: '',
  lastName: '',
  contactTitle: '',
};
interface PropsType {
  contacts: ContactType[];
  onClose: () => void;
  callback: (data: string[]) => void;
}

interface FormModel {
  firstName: string;
  lastName: string;
  contactTitle: string;
}

const DialogContactsList = ({ contacts, callback, onClose }: PropsType) => {
  const [contactsIds, selectContacts] = useState<string[]>([]);

  const { control, watch } = useForm({ defaultValues });

  const filter = watch();

  const handleSubmit = () => {
    callback(contactsIds);
  };

  const restOfContacts = useMemo(
    () =>
      contacts.filter(
        (v: { is_selected: boolean; status: string }) =>
          !v.is_selected && v.status === 'A'
      ),
    [contacts]
  );

  const filteredList = FacilityPageStore.getFilteredContactData(
    restOfContacts,
    filter
  );

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Select contact" onClose={onClose} />
      <DialogBody>
        <form className="row">
          <Input
            name="firstName"
            placeholder="First name"
            className="col-sm-4"
            control={control}
          />
          <Input
            name="lastName"
            placeholder="Last name"
            className="col-sm-4"
            control={control}
          />
          <Input
            name="contactTitle"
            placeholder="Contact title"
            className="col-sm-4"
            control={control}
          />
        </form>
        <Grid
          columns={COLUMN}
          selectId="refid"
          selectedIds={contactsIds}
          onSelectChange={selectContacts}
          dataSource={filteredList}
          dataSourceCount={filteredList.length}
          disablePagination
        />
      </DialogBody>
      <DialogFooter>
        <Button text="Add" onClick={handleSubmit} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogContactsList;
