import React from 'react';

import StringUtils from 'utils/StringUtils';
import TD from './TD';
import DraggableTR from './DraggableTR';
import GroupUtils from './GroupUtils';
import TR from 'components/grid/TR';
import { IconButton } from '../button';

interface GroupInfo {
  group: any;
  groupName: string;
  headers: Array<any>;
  count: number;
}

interface PProjectGrid {
  id?: string;
  shouldRowUpdate?: boolean;
  dataSourceCount?: number;
  disablePagination?: boolean;
  paginationToolbarProps?: any;
  isLoading?: boolean;
  dataSource: Array<any> | string | number;
  columns: Array<any>;
  selectedIds?: Array<any>;
  headerGroups?: boolean;
  onChangeSort: (columnName: string, direction: number) => void;
  noDrag: any;
  sort: any;
  dragColumns: (t: number, a: number) => void;
  groupBy: any;
  rowArguments: ((val: any, idx: number) => any) | Array<any>;
  noDataText: React.ReactNode;
  onDoubleClick?: (data: any, index: number) => void;
  onClick?: (data: any, index: number) => void;
}

interface State {
  hoveredColumns: any;
  collapsed: { [key: string]: any };
  selectedRowIndex: number;
}

export default class ProjectGrid extends React.PureComponent<
  PProjectGrid,
  State
> {
  cache4ColumnTitle: Record<string, string> = {};

  constructor(props: PProjectGrid) {
    super(props);
    this.state = {
      hoveredColumns: null,
      collapsed: {},
      selectedRowIndex: -1,
    };
  }

  render() {
    return (
      <div ref="grid" className="datagrid">
        <table className="table table-striped">
          {this.buildHeader()}
          {this.buildBody()}
          {this.buildFooter()}
        </table>
      </div>
    );
  }

  componentDidUpdate(prevProps: PProjectGrid) {
    if (prevProps && this.props) {
      const isDataLoaded = prevProps.isLoading && !this.props.isLoading;
      const isNewSource = prevProps.dataSource !== this.props.dataSource;
      const idx =
        isNewSource || isDataLoaded ? -1 : this.state.selectedRowIndex;

      this.setState({
        selectedRowIndex: idx,
      });
    }
  }

  setCacheValue4ColumnTitle(name: string) {
    if (!name) {
      return;
    }
    const newValue = StringUtils.normalizeText(name);
    this.cache4ColumnTitle[name] = newValue;
  }

  setHoveredColumns(hoveredColumns: any, callback?: () => void) {
    this.setState({ hoveredColumns }, callback);
  }

  setCollapsed(collapsed: { [key: string]: any }) {
    this.setState({ collapsed });
  }

  getHeaderMeta(
    metadata: Array<{
      group: any;
      groupName: string;
      headers: any[];
      count: number;
    }>,
    col: any
  ): GroupInfo {
    let out: GroupInfo;
    const header = col.title ? col.title : this.getTitleValue(col.name);
    if (col.group) {
      for (let i = 0; i < metadata.length; i++) {
        const group = metadata[i];
        if (group.group === col.group) {
          group.headers.push(header);
          return group;
        }
      }
      out = {
        group: col.group,
        groupName: col.groupName,
        headers: [header],
        count: 0,
      };
    } else {
      out = { group: null, groupName: '', headers: [header], count: 0 };
    }
    metadata.push(out);
    return out;
  }

  buildHeader(): React.ReactElement {
    const { columns, headerGroups, onChangeSort, noDrag, sort } = this.props;
    let header: Array<React.ReactElement> = [];
    if (headerGroups) {
      let groups: Array<React.ReactElement> = [];
      let groupMetadata: Array<{
        group: any;
        groupName: string;
        headers: Array<any>;
        count: number;
      }> = [];
      let noDrag1: Array<number> = [];
      for (let i = 0; i < columns.length; i++) {
        let col = columns[i];
        let group = this.getHeaderMeta(groupMetadata, col);
        group.count++;
        if (group.group !== null) {
          noDrag1.push(i);
        }
      }
      for (let i = 0; i < groupMetadata.length; i++) {
        const { group, count, groupName } = groupMetadata[i];
        const titleValue = this.getTitleValue(group);
        groups.push(
          <th
            key={i}
            className={'group' + (group ? ' group-custom group-' + group : '')}
            colSpan={count}>
            {groupName ? groupName : titleValue}
          </th>
        );
      }
      header.push(<tr key="grouped">{groups}</tr>);
      header.push(
        <DraggableTR
          key="main"
          noDrag={noDrag1}
          onChangeSort={onChangeSort}
          onDragOver={(on: any, source: any) =>
            this.setHoveredColumns({ on: on, source: source })
          }
          currentSort={sort}
          headers={null}
          groupMetadata={groupMetadata}
          dragColumns={(target, after) => this.dragColumns(target, after)}
          columns={columns}
        />
      );
    } else {
      const headers = columns.map((el) =>
        el.title ? el.title : this.getTitleValue(el.name)
      );
      return (
        <thead key="thead">
          {
            <DraggableTR
              key="DraggableTR"
              noDrag={noDrag}
              onChangeSort={onChangeSort}
              onDragOver={(on: any, source: any) =>
                this.setHoveredColumns({ on: on, source: source })
              }
              currentSort={sort}
              headers={headers}
              dragColumns={(target, after) => this.dragColumns(target, after)}
              columns={columns}
            />
          }
        </thead>
      );
    }
    return <thead key="thead">{header}</thead>;
  }

  dragColumns(target: any, after: any) {
    this.setHoveredColumns(null, () => this.props.dragColumns(target, after));
  }

  buildBody(): React.ReactElement {
    let rows;
    if (this.props.dataSource) {
      let ds: any;
      if (
        !Array.isArray(this.props.dataSource) ||
        !this.props.dataSource.length
      ) {
        ds = [];
      } else {
        if (this.props.groupBy) {
          let orderedDs = GroupUtils.getGroupedDs(
            this.props.dataSource,
            this.props.groupBy
          );
          if (Array.isArray(orderedDs)) {
            ds = orderedDs;
          } else {
            ds = [];
            this.flatDataSource(ds, orderedDs, 0, 0);
          }
        } else {
          ds = this.props.dataSource;
        }
      }
      rows = this.getRowsArray(ds);
    } else {
      rows = this.getRowsArray([]);
    }
    return <tbody key="tbody">{rows}</tbody>;
  }

  findColumn(name: string) {
    const cols = this.props.columns;
    for (let column of cols) {
      if (column.name === name) {
        return column;
      }
    }
    return null;
  }

  flatDataSource(
    out: Array<{ [key: string]: React.ReactElement }>,
    ordered: any,
    rowIndex: any,
    groupIndex: any
  ) {
    for (let key in ordered) {
      if (!ordered.hasOwnProperty(key)) {
        continue;
      }

      let fakeDataSource: { [key: string]: React.ReactElement } = {};

      const colName: string =
        typeof this.props.groupBy === 'string'
          ? this.props.groupBy
          : this.props.groupBy[groupIndex];
      const col = this.findColumn(colName);
      const collapseClass = `bi bi-caret-${
        this.state.collapsed[key] ? 'up' : 'down'
      }-fill text-secondary`;
      const cn = `group-toggler ${col?.className || ''}`;

      fakeDataSource[colName] = (
        <div className={cn}>
          <IconButton
            onClick={(event: React.MouseEvent) =>
              this.collapseGroup(event, key)
            }>
            <i className={collapseClass} />
          </IconButton>
          {col && col.groupRender ? (
            col.groupRender(key)
          ) : (
            <span className="grid-group-wrap">{key}</span>
          )}
        </div>
      );

      out.push(fakeDataSource);

      const item = ordered[key];
      if (this.state.collapsed[key] === true) {
        continue;
      }
      if (Array.isArray(item)) {
        let rows = [];
        for (let j = 0; j < item.length; j++) {
          let row: any = {};
          for (let k in item[j]) {
            let indexOf: number;
            if (typeof this.props.groupBy === 'string') {
              indexOf = k !== this.props.groupBy ? -1 : 1;
            } else {
              indexOf = this.props.groupBy.indexOf(k);
            }
            if (indexOf === -1) {
              row[k] = item[j][k];
            }
          }
          rows.push(row);
        }
        rows.forEach((row) => {
          out.push(row);
        });
      } else {
        rowIndex = this.flatDataSource(out, item, rowIndex, groupIndex + 1);
      }
    }
    return rowIndex;
  }

  collapseGroup(event: React.MouseEvent, group: any): void {
    if (event) {
      event.preventDefault();
    }
    const collapsed = {
      ...this.state.collapsed,
      [group]: !this.state.collapsed[group],
    };
    this.setCollapsed(collapsed);
  }

  handleClickTr = (index: number) => {
    const { dataSource, onClick } = this.props;

    this.setState(
      (state) => ({
        selectedRowIndex: state.selectedRowIndex === index ? -1 : index,
      }),
      () => {
        if (onClick && Array.isArray(dataSource))
          onClick(dataSource[index], index);
      }
    );
  };

  handleDoubleClick = (index: number) => {
    const { dataSource, onDoubleClick } = this.props;
    if (this.props.onDoubleClick && Array.isArray(dataSource)) {
      onDoubleClick(dataSource[index], index);
    }
  };

  getRowsArray(dataSource: any): Array<React.ReactElement> {
    const { rowArguments } = this.props;

    if (dataSource && dataSource.length > 0) {
      const isFunction = typeof rowArguments === 'function';
      const isArray = !isFunction && Array.isArray(rowArguments);

      return dataSource.map((data: any, i: number) => {
        let args = isArray
          ? (rowArguments as Array<any>)[i]
          : isFunction
          ? (rowArguments as (val: any, idx: number) => any)(data, i)
          : rowArguments;
        if (!args) {
          args = {};
        }
        return (
          <TR
            key={i}
            index={i}
            args={args}
            isSelected={i === this.state.selectedRowIndex}
            onDoubleClick={this.handleDoubleClick}
            onClick={this.handleClickTr}>
            {this.getCellsArray(data, i)}
          </TR>
        );
      });
    } else {
      return [
        <tr key={0}>
          <TD
            className="no-data"
            index={0}
            colSpan={this.props.columns.length}
            value={this.props.noDataText || 'No data'}
          />
        </tr>,
      ];
    }
  }

  prepareHoverClass(i: number): string {
    const hoveredColumns = this.state.hoveredColumns;
    if (hoveredColumns && hoveredColumns.source !== hoveredColumns.on) {
      const hoverRight = ' hover-right';
      const hoverLeft = ' hover-left';
      if (hoveredColumns.source > i) {
        if (hoveredColumns.on === i) {
          return hoverLeft;
        } else if (hoveredColumns.on - 1 === i) {
          return hoverRight;
        }
      } else if (hoveredColumns.source < i) {
        if (hoveredColumns.on === i) {
          return hoverRight;
        } else if (hoveredColumns.on + 1 === i) {
          return hoverLeft;
        }
      }
    }
    return '';
  }

  getCellsArray(dataRow: any, index: number): Array<React.ReactElement> {
    const { props } = this;
    let row: Array<React.ReactElement> = [];
    for (let c = 0; c < props.columns.length; c++) {
      const col = props.columns[c];
      const value = dataRow[col.name];
      const className =
        (col.className ? col.className : '') +
        (col.textAlign ? ' align-' + col.textAlign : '') +
        this.prepareHoverClass(c);
      row.push(
        <TD
          key={c}
          index={index}
          className={className}
          maxWidth={col.maxWidth}
          shouldUpdate={col.shouldCellUpdate}
          render={col.render}
          value={value}
          dataRow={dataRow}
        />
      );
    }
    return row;
  }

  buildFooter(): React.ReactElement {
    return <tfoot key="tfoot" />;
  }

  getTitleValue(name: string): string {
    if (!this.cache4ColumnTitle[name]) {
      this.setCacheValue4ColumnTitle(name);
    }
    return this.cache4ColumnTitle[name];
  }
}
