import React from 'react';
import { Container } from 'flux/utils';

import { Button } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import ExamTypeDropdown from 'components/project/dropdown/ExamTypeDropdown';
import ProviderTypeDropdown from 'components/project/dropdown/ProviderTypeDropdown';
import StateDropdown from 'components/project/dropdown/StateDropdown';
import LocationDropdown from 'components/project/dropdown/LocationDropdown';
import PlaceOfServiceDropdown from 'components/project/dropdown/PlaceOfServiceDropdown';
import RadiologyDropdown from 'components/project/dropdown/RadiologyDropdown';
import RadiologistDropdown from 'components/project/dropdown/RadiologistDropdown';
import UserTypeDropdown from 'components/project/dropdown/UserTypeDropdown';
import Form from 'components/form/Form';
import Text from 'components/form/input/Text';
import Radio from 'components/form/input/Radio';
import { DateRangeLegacy } from 'components/form/dateRange';
import { ControlsLayout, LayoutSideTitle } from 'components/layout';
import Notification from 'components/modal/Notification';
import DialogFormClaimAddressDetails from '../components/dialogFormClaimAddressDetails';

import { storeGrid } from 'stores/_mobx/grid';
import EligibilityPopup, {
  TOrder4EligibilityPopup,
} from 'page/workflow/order/eligibility/EligibilityPopup';
import ProfessionalGroupBillingActions from 'actions/billing-coding/ProfessionalGroupBillingActions';
import ProfessionalGroupBillingStore from 'stores/billing-coding/ProfessionalGroupBillingStore';
import ProviderUpdate, {
  TOrder4ProviderUpdate,
} from 'page/billing-coding/components/ProviderUpdate';
import ExamCode, {
  TOrder4ExamCode,
} from 'page/billing-coding/components/provider-update/ExamCode';
import Exporter from 'components/project/Exporter';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import UserProfileStore from 'stores/UserProfileStore';
import DropdownService from 'services/project/DropdownService';
import FacilityDropdown from 'components/project/dropdown/FacilityDropdown';
import Select from 'components/form/input/Select';
import { TPagination } from 'components/grid/GridTypes';
import {
  TExpHistoryFilter,
  TRgApproval,
  TExpHistoryItem,
} from 'services/billing-coding/ProfessionalGroupBillingService';
import { getPagination } from 'utils/getPagination';
import { getDateRangeBounds } from 'utils/DateUtils';
import { BASE_URL_FILE_DIR } from 'constant/config';
import { PRO_GROUP_BILLING as PAGE_ID } from 'constant/pagesId/billingCodding';
import { DropdownPureDivision } from 'components/project/dropdown/Division';

const GRID_ID = 'pgb_grid';

const OPTIONS = [
  { value: 'A', label: 'All' },
  { value: 'E', label: 'Exported' },
  { value: 'H', label: 'History' },
];

const defaultFilter: FilterType = {
  radiologyGroup: 0,
  radiologist: 0,
  lastName: '',
  firstName: '',
  examType: 0,
  providerType: 0,
  facility: 0,
  location: 0,
  state: 0,
  orderType: 0,
  pos: 0,
  claims: 'T',
  billProcess: 'N',
  dosStart: '',
  dosEnd: '',
  period: 'A',
  claimType: 'T',
  exportFlag: 'A',
  userType: 0,
  division: 0,
};

const HISTORY_COLUMNS = [
  {
    name: 'filename',
    title: 'Filename',
    render: (fileName: any) => (
      <a
        target="_blank"
        title="Download file"
        href={`${BASE_URL_FILE_DIR}doc_img/billingexport/${fileName}`}
        rel="noreferrer">
        {fileName}
      </a>
    ),
  },
  { name: 'exported_by', title: 'Exported By' },
  { name: 'exported_dt', title: 'Date' },
];

const columnsVisibleByDefault = [
  'dos',
  'Studyid',
  'radioname',
  'bill_process',
  'patientlast',
  'patientfist',
  'facilitynm',
  'insImg',
  'facImg',
  'zipImg',
  'doctor_pop_img',
  'dobImg',
  'posImg',
  'viewClaim',
  'examCode',
  'batchid',
  'batched_date',
  'modality',
  'location',
  'state',
  'division',
];

interface FilterType {
  radiologyGroup: number;
  radiologist: number;
  lastName: string;
  firstName: string;
  examType: number;
  providerType: number;
  facility: number;
  location: number;
  state: number;
  orderType: number;
  pos: number;
  claims: string;
  billProcess: 'N' | 'I' | 'H' | 'O';
  dosStart: string;
  dosEnd: string;
  period: string;
  claimType: string;
  exportFlag: 'A' | 'E' | 'H';
  userType: number;
  division: number;
}

export class SBilling {
  onAjax: boolean = false;
  isFilterVisible: boolean = false;
  dataSource: Array<TRgApproval> = [];
  dataSourceCount: number = 0;
  selected: Array<any> = [];
  pagination: TPagination = new TPagination(storeGrid.getPageSize(GRID_ID));
  filter: FilterType = storeGrid.getFilter(
    GRID_ID,
    defaultFilter
  ) as FilterType;
  facilityView: boolean = false;
  providerUpdateDetails: TOrder4ProviderUpdate | null = null;
  showEligibilityPopup: boolean = false;
  viewClaim: boolean = false;
  examCode: boolean = false;
  order: TRgApproval = null;
  historyDataSource: Array<TExpHistoryItem> = [];
  historyDataSourceCount: number = 0;
  zipView: boolean = false;
  exporter: boolean = false;
}

class ProfessionalGroupBilling extends React.Component<
  PropsTypeWitDivisionSettings,
  SBilling
> {
  getGridId() {
    return GRID_ID;
  }

  static getStores() {
    return [ProfessionalGroupBillingStore];
  }

  static calculateState(prevState = new SBilling()) {
    const store = ProfessionalGroupBillingStore.getState();

    return {
      ...prevState,
      dataSource: store.dataSource,
      dataSourceCount: store.dataSourceCount,
      historyDataSource: store.historyDataSource,
      historyDataSourceCount: store.historyDataSourceCount,
    };
  }

  componentDidMount() {
    this.updateData();
  }

  componentWillUnmount() {
    storeGrid.saveFilter(GRID_ID, this.state.filter);
    ProfessionalGroupBillingActions.clear();
  }

  onPaginationChange = (pagination: TPagination) => {
    this.setState({ pagination }, this.updateData);
  };

  handleChangeFilter = (name: string, value?: string) => {
    const filter = { ...this.state.filter, [name]: value };

    this.setState({ filter });
  };

  handleChangeDatePeriod = (filter: FilterType) => {
    this.setState({ filter });
  };

  handleDivisionChange = (division: number) => {
    const filter = { ...this.state.filter, division };

    this.setState({ filter });
  };

  handlePrepareFilterForExport = () => {
    const { isDivisionEnabled } = this.props;

    const { filter } = this.state;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return {
      ...filter,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
      division: isDivisionEnabled ? filter.division : 0,
    };
  };

  getFilterComponent() {
    const className = 'col-sm-3';
    const { filter, isFilterVisible } = this.state;

    return filter.exportFlag === 'H' ? (
      <Form
        className={isFilterVisible ? '' : 'd-none'}
        onCollectValues={this.handleChangeFilter}
        submit={(m: any, he: any) => {
          if (!he) {
            this.updateData();
          }
        }}
        model={filter}>
        <UserTypeDropdown name="userType" className={className} />
        <Text name="lastName" className={className} />
        <Text name="firstName" className={className} />
        <RadiologyDropdown
          name="radiologyGroup"
          label="Radiology Group"
          className={className}
        />
      </Form>
    ) : (
      <Form
        className={isFilterVisible ? '' : 'd-none'}
        onCollectValues={this.handleChangeFilter}
        submit={this.handleApplyFilter}
        model={filter}>
        <Text name="lastName" className={className} />
        <Text name="firstName" className={className} />
        <ExamTypeDropdown
          name="examType"
          label="Modality"
          optionsForOrderOnly
          className={className}
        />
        <ProviderTypeDropdown name="providerType" className={className} />
        <div />

        <FacilityDropdown name="facility" className={className} />
        <StateDropdown name="state" className={className} />
        <LocationDropdown name="location" className={className} />
        <>
          <DropdownPureDivision
            name="division"
            label="Division"
            className={className}
            value={filter.division}
            onChange={this.handleDivisionChange}
          />
        </>
        <Select
          name="billProcess"
          className={className}
          options={DropdownService.billProcessList}
        />
        <PlaceOfServiceDropdown
          name="pos"
          label="Place of Service"
          className={className}
        />
        <RadiologyDropdown
          name="radiologyGroup"
          label="Radiology Group"
          className={className}
          flagOneListItemSelectedByDefault
        />
        <RadiologistDropdown
          name="radiologist"
          className={className}
          radiologyGroupId={filter.radiologyGroup}
        />
        <div />
        <DateRangeLegacy
          nameFrom="dosStart"
          nameTo="dosEnd"
          nameRadio="period"
          radioClassName="col-sm-12 col-md-6 col-lg-auto"
          onChange={this.handleChangeDatePeriod}
        />
        <ControlsLayout alignX="auto">
          <Button
            variant="warning"
            text="Reset"
            onClick={this.handleResetFilter}
          />
          <Button text="Apply" type="submit" />
        </ControlsLayout>
      </Form>
    );
  }

  onSelectChange = (selected: Array<any>) => {
    this.setState({ selected });
  };

  getComponentGrid() {
    const { state } = this;
    if (state.filter.exportFlag === 'H') {
      return (
        <Grid
          ref="grid"
          onAjax={state.onAjax}
          id="pgb_history_grid"
          columns={HISTORY_COLUMNS}
          dataSource={this.state.historyDataSource}
          dataSourceCount={this.state.historyDataSourceCount}
          onPaginationChange={this.onPaginationChange}
        />
      );
    }
    return (
      <Grid
        ref="grid"
        id="pgb_grid"
        selectId="Studyid"
        gridControlPanel={
          <GridControlButton title="Export" onClick={this.handleClickExport} />
        }
        headerGroups
        onAjax={state.onAjax}
        columns={this.getColumns()}
        dataSource={state.dataSource}
        pagination={state.pagination}
        dataSourceCount={state.dataSourceCount}
        shownColumns={this.getStartShownColumns()}
        onPaginationChange={this.onPaginationChange}
        onSelectChange={this.onSelectChange}
      />
    );
  }

  handleToggleFilter = () => {
    this.setState((state) => ({
      ...state,
      isFilterVisible: !state.isFilterVisible,
    }));
  };

  handleApplyFilter = () => {
    const pagination = {
      ...this.state.pagination,
      page: 1,
      skip: 0,
    };

    this.setState({ pagination }, this.updateData);
  };

  handleResetFilter = () =>
    this.setState({ filter: defaultFilter }, () =>
      ProfessionalGroupBillingActions.clear()
    );

  render() {
    const { state } = this;
    const { claimType } = state.filter;
    const exportName =
      UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
      'professional_group_billing';

    return (
      <>
        <LayoutSideTitle
          appId={PAGE_ID.PAGE}
          title="Professional Group Billing">
          <Button
            variant="default"
            text={state.isFilterVisible ? 'Hide Filter' : 'Show Filter'}
            onClick={this.handleToggleFilter}
          />
        </LayoutSideTitle>
        <div className="claim-audit row">
          <div className="mb-4">
            <Radio
              options={OPTIONS}
              name="exportFlag"
              className="inline"
              noLabel
              // @ts-ignore
              onSetValue={this.onExportFlag}
              value={state.filter.exportFlag}
            />
          </div>

          {this.getFilterComponent()}
          {this.getComponentGrid()}
          {state.showEligibilityPopup && (
            <EligibilityPopup
              order={Object.assign(new TOrder4EligibilityPopup(), state.order)}
              eligibility="ONLY_SAVE"
              callback={(v: boolean) => this.closeEligibility(v)}
            />
          )}
          {state.facilityView && (
            <DialogFormClaimAddressDetails
              title="Facility Information"
              order={{
                refid: state.order.Studyid,
                facilityid: state.order.facilityid,
                last_name: state.order.patientlast,
                first_name: state.order.patientfist,
                dob: state.order.birthdate,
                dos: state.order.dos,
                modality: state.order.examtype,
                pos_override: '0',
                pos: state.order.pos,
                facility_name: state.order.facilitynm,
                npi: state.order.npi,
                facility_type: state.order.facility_type,
              }}
              facilityOnly
              claimAndFacility
              isPCInstance={null}
              onClose={this.handleCloseDialogFacility}
              onSubmit={this.handleApplyDialogFacility}
            />
          )}
          {state.zipView && (
            <DialogFormClaimAddressDetails
              title="Service Location"
              order={{
                refid: state.order.Studyid,
                facilityid: state.order.facilityid,
                last_name: state.order.patientlast,
                first_name: state.order.patientfist,
                dob: state.order.birthdate,
                dos: state.order.dos,
                modality: state.order.examtype,
                pos_override: '0',
                pos: state.order.pos,
                facility_name: state.order.facilitynm,
                npi: state.order.npi,
                facility_type: state.order.facility_type,
              }}
              facilityOnly={false}
              claimAndFacility={true}
              isPCInstance={null}
              onClose={this.handleCloseDialogZip}
              onSubmit={this.handleApplyDialogZip}
            />
          )}
          {state.providerUpdateDetails && (
            <ProviderUpdate
              claimUniqId={String(state.providerUpdateDetails.refid)}
              order={state.providerUpdateDetails}
              onClose={this.handleCloseDialogProviderUpdate}
              onUpdate={this.handleSubmitDialogProviderUpdate}
            />
          )}
          {state.viewClaim && (
            <ExamCode
              order={Object.assign(new TOrder4ExamCode(), state.order)}
              cancelStatus={claimType === 'T' ? 'V' : 'N'}
              onClose={this.handleCloseViewClaim}
            />
          )}
          {state.examCode && (
            <ExamCode
              order={Object.assign(new TOrder4ExamCode(), state.order)}
              cancelStatus={claimType === 'T' ? 'V' : 'N'}
              onClose={this.handleCloseExamCode}
            />
          )}
          {state.exporter && (
            <Exporter
              exporter="ProfessionalGroupBillingExporter"
              reportName={exportName}
              this={this}
              callback={() => this.setState({ exporter: false })}
              getColumns={() => this.getExportColumns()}
              filters={this.handlePrepareFilterForExport()}
            />
          )}
        </div>
      </>
    );
  }

  getExportColumns() {
    const { isDivisionEnabled } = this.props;

    return [
      { name: 'patientlast', title: 'Patient Last Name' },
      { name: 'patientfist', title: 'Patient First Name' },
      { name: 'patientmrn', title: 'Patient MRN' },
      { name: 'birthdate', title: 'Patient Date of Birth' },
      { name: 'room', title: 'Patient Room#' },
      { name: 'ssn', title: 'Patient SSN#' },
      { name: 'gender', title: 'Gender' },
      { name: 'dos', title: 'Date of Service' },
      { name: 'facilitynm', title: 'Service Location/Facility Name' },
      ...(isDivisionEnabled ? [{ name: 'division', title: 'Division' }] : []),
      { name: 'facility_addr', title: 'Facility/Patient/POS address' },
      { name: 'pos', title: 'POS code' },
      { name: 'cpt', title: 'CPT(s)' },
      { name: 'cpt_desc', title: 'CPT Desc' },
      { name: 'icd', title: 'ICD' },
      { name: 'icd_desc', title: 'ICD Desc' },
      { name: 'examtype', title: 'Modality' },
      {
        name: 'profirst_name',
        title: 'Ordering/Referring Provider',
        export: {
          target: (v: any, r: any) =>
            [r.profirst_name, r.prolast_name].filter(Boolean).join(' '),
        },
      },
      { name: 'medicare_id', title: 'Medicare Id' },
      { name: 'npi', title: 'Ordering/Referring NPI' },
      { name: 'pripayorname', title: 'Primary Insurance/Payer' },
      { name: 'priplan_name', title: 'Primary Insurance/Plan' },
      { name: 'priinsurance_no', title: 'Primary ID#' },
      { name: 'pristart_dt', title: 'Primary Start Date' },
      { name: 'priend_dt', title: 'Primary End Date' },
      { name: 'secpayorname', title: 'Secondary Insurance/Payer' },
      { name: 'secplan_name', title: 'Secondary Insurance/Plan' },
      { name: 'secinsurance_no', title: 'Secondary Insurance ID#' },
      { name: 'secstart_dt', title: 'Secondary Insurance Start Date' },
      { name: 'secend_dt', title: 'Secondary Insurance End Date' },
      { name: 'tripayorname', title: 'Tertiary Insurance/Payer' },
      { name: 'triplan_name', title: 'Tertiary Insurance/Plan' },
      { name: 'triinsurance_no', title: 'Tertiary Insurance ID#' },
      { name: 'tristart_dt', title: 'Tertiary Start Date' },
      { name: 'triend_dt', title: 'Tertiary End Date' },
      {
        name: 'radio_name',
        title: 'Rendering MD/Radiologist First & Last Name',
      },
      { name: 'radio_npi', title: 'Rendering MD/Radiologist NPI#' },
      {
        name: 'radio_addr',
        title: 'Rendering MD/Radiologist Physical Address',
      },
      { name: 'bill_process', title: 'Guarant Bill Process' },
      { name: 'gualast_name', title: 'Guarantor Last name' },
      { name: 'guafirst_name', title: 'Guarantor First Name' },
      { name: 'guarantor_address', title: 'Guarantor Address' },
      { name: 'guarantor_city', title: 'Guarantor City' },
      { name: 'guarantor_site', title: 'Guarantor State' },
      { name: 'guarantor_zip', title: 'Guarantor PostalCode' },
      { name: 'guarantor_phone', title: 'Guarantor Phone ' },
    ];
  }

  handleClickExport = () => {
    this.setState({ exporter: true });
  };

  onExportFlag = (n: string, v: 'A' | 'E' | 'H') => {
    const old = this.state.filter.exportFlag;
    if ((v === 'H' || old === 'H') && old !== v) {
      const filter = {
        ...this.state.filter,
        lastName: '',
        firstName: '',
        userType: 0,
        radiologyGroup: 0,
        exportFlag: v,
      };
      this.setState(
        {
          filter,
          pagination: getPagination(),
        },
        this.updateData
      );
    } else {
      this.handleChangeFilter(n, v);
    }
  };

  handleCloseViewClaim = () => {
    this.setState({
      viewClaim: false,
      order: null,
    });
  };

  handleCloseExamCode = () => {
    this.setState({
      examCode: false,
      order: null,
    });
  };

  handleCloseDialogProviderUpdate = () => {
    this.setState({ providerUpdateDetails: null });
  };

  handleSubmitDialogProviderUpdate = () => {
    this.handleCloseDialogProviderUpdate();
    this.updateData();
  };

  handleApplyDialogFacility = () => {
    this.setState({ facilityView: false, order: null }, () =>
      this.updateData()
    );
  };

  handleCloseDialogFacility = () => {
    this.setState({ facilityView: false, order: null });
  };

  handleApplyDialogZip = () => {
    this.setState({ zipView: false, order: null }, () => this.updateData());
  };

  handleCloseDialogZip = () => {
    this.setState({ zipView: false, order: null });
  };

  closeEligibility(shouldUpdate: boolean) {
    this.setState(
      {
        showEligibilityPopup: false,
        order: null,
      },
      shouldUpdate ? this.updateData : undefined
    );
  }

  buildIcon(
    iconPath: string,
    order: TRgApproval,
    clb: (order: TRgApproval) => void
  ) {
    return (
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault();
          clb(order);
        }}>
        <img src={`assets/${iconPath}`} alt="" />
      </a>
    );
  }

  validateInsurance(order: TRgApproval) {
    this.setState({
      showEligibilityPopup: true,
      order,
    });
  }

  validateFacility(order: TRgApproval) {
    this.setState({
      facilityView: true,
      order,
    });
  }

  validateZip(order: TRgApproval) {
    this.setState({
      zipView: true,
      order,
    });
  }

  displayOrderingPopup(order: TRgApproval) {
    const providerUpdateDetails = {
      refid: order.Studyid,
      last_name: order.patientlast,
      first_name: order.patientfist,
      dob: order.birthdate,
      dos: order.dos,
      modality: order.examtype,
      facility_name: order.facilitynm,
    };

    this.setState({
      providerUpdateDetails,
    });
  }

  displayViewClaim(order: TRgApproval) {
    this.setState({
      viewClaim: true,
      order,
    });
  }

  displayExamCode(order: TRgApproval) {
    this.setState({
      examCode: true,
      order,
    });
  }

  getColumns() {
    const { isDivisionEnabled } = this.props;

    const out = [
      { name: 'dos', title: 'DOS', className: 'text-nowrap', dataType: 'date' },
      { name: 'Studyid', title: 'Claim #' },
      {
        name: 'radioname',
        title: 'Bill Type',
        group: 'professionalGuarantor',
        groupName: 'Professional Guarantor',
      },
      {
        name: 'bill_process',
        title: 'Bill Process',
        group: 'professionalGuarantor',
        groupName: 'Professional Guarantor',
      },
      { name: 'vw_patientid', title: 'Patient ID' },
      { name: 'clientMRN', title: 'Other MRN' },
      { name: 'patientmrn', title: 'Other MRN(p)' },
      { name: 'patientlast', title: 'Patient Last Name' },
      { name: 'patientfist', title: 'Patient First Name' },
      { name: 'facilitynm', title: 'Facility' },
      ...(isDivisionEnabled ? [{ name: 'division', title: 'Division' }] : []),
      {
        name: 'insImg',
        title: 'Insurance',
        export: 'exclude',
        render: (value: string, data: TRgApproval) =>
          this.buildIcon(value, data, (order) => this.validateInsurance(order)),
      },
      {
        name: 'facImg',
        title: 'Facility Address',
        export: 'exclude',
        render: (value: string, data: TRgApproval) =>
          this.buildIcon(value, data, (order) => this.validateFacility(order)),
      },
      {
        name: 'zipImg',
        title: '9 Digit Zip',
        export: 'exclude',
        render: (value: string, data: TRgApproval) =>
          this.buildIcon(value, data, (order) => this.validateZip(order)),
      },
      {
        name: 'doctor_pop_img',
        title: 'Ordering MD',
        export: 'exclude',
        render: (value: string, data: TRgApproval) =>
          this.buildIcon(value, data, (order) =>
            this.displayOrderingPopup(order)
          ),
      },
      {
        name: 'dobImg',
        title: 'DOB',
        export: 'exclude',
        className: 'text-nowrap',
        render: (value: string, data: TRgApproval) =>
          this.buildIcon(value, data, (order) => this.validateInsurance(order)),
      },
      {
        name: 'posImg',
        title: 'POS',
        export: 'exclude',
        render: (value: string, data: TRgApproval) =>
          this.buildIcon(value, data, (order) => this.validateZip(order)),
      },
      {
        name: 'viewClaim',
        title: 'View Claim',
        export: 'exclude',
        render: (value: string, data: TRgApproval) =>
          this.buildIcon('images/info_available.png', data, (order) =>
            this.displayViewClaim(order)
          ),
      },
      {
        name: 'examCode',
        title: 'Exam Claim',
        export: 'exclude',
        render: (value: string, data: TRgApproval) =>
          this.buildIcon('images/info_available.png', data, (order) =>
            this.displayExamCode(order)
          ),
      },
      ...(this.state.filter.exportFlag === 'E'
        ? [
            { name: 'exported_nm', title: 'Exported By' },
            { name: 'exported_date', title: 'Exported Date' },
          ]
        : []),
    ];

    return out;
  }

  getStartShownColumns(): Map<string, boolean> {
    const out = new Map<string, boolean>();
    const columns = this.getColumns();
    for (let i = 0; i < columns.length; i++) {
      const { name } = columns[i];
      out.set(name, columnsVisibleByDefault.includes(name));
    }
    return out;
  }

  updateData = () => {
    const { isDivisionEnabled } = this.props;

    const { filter, pagination } = this.state;

    this.setState({
      onAjax: true,
    });

    let promiseInstance: Promise<any>;

    if (filter.exportFlag !== 'H') {
      if (filter.exportFlag === 'A' && !filter.dosStart && !filter.dosEnd) {
        Notification.warning('Please specify date range');
        this.setState({ onAjax: false });
        return;
      }
      promiseInstance = ProfessionalGroupBillingActions.loadOverview(
        {
          ...filter,
          division: isDivisionEnabled ? filter.division : 0,
        },
        pagination
      );
    } else {
      promiseInstance = ProfessionalGroupBillingActions.loadHistoryOverview(
        Object.assign(new TExpHistoryFilter(), filter),
        pagination
      );
    }

    promiseInstance.finally(this.updateDataCallback);
  };

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.setState({
      onAjax: false,
      selected: [],
    });

    if (grid) {
      // @ts-ignore
      grid.clearSelection();
    }
  };
}
export default withDivisionSettings(Container.create(ProfessionalGroupBilling));
