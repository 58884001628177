import EmployeeTimeSheetPageStore from 'stores/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageStore';
import EmployeeTimeSheetPageActions from 'actions/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageActions';
import AbstractDropdown from './AbstractDropdown';

export default class SupervisorDropdown extends AbstractDropdown {
  getStore() {
    return EmployeeTimeSheetPageStore;
  }

  loadOptions() {
    return EmployeeTimeSheetPageActions.getSuperVisorsFullList();
  }

  getOptions() {
    let superVisorsFullList =
      EmployeeTimeSheetPageStore.getState().superVisorsFullList;
    if (!Array.isArray(superVisorsFullList)) {
      superVisorsFullList = [];
    }
    const res = superVisorsFullList.map((child, key) => {
      return { label: child.user_full_name, value: child.refid };
    });
    return res;
  }
}
