import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import { ControlsLayout } from 'components/layout';
import { Select } from 'components/form/select';
import { Input } from 'components/form/textField';
import { Button } from 'components/button';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import resolver from './validation';

import {
  RegionDetailsType,
  initialValues,
} from 'stores/_mobx/systemSetup/dispatchRegions';

type OptionType = Array<{ label: string; value: number }>;

interface Props {
  defaultValues?: RegionDetailsType;
  regionOptions: OptionType;
  backUrl: string;
  onSubmit: (data: RegionDetailsType) => Promise<any>;
}

const DispatchRegionForm = ({
  defaultValues = initialValues,
  regionOptions,
  backUrl,
  onSubmit,
}: Props) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm<RegionDetailsType>({
    defaultValues,
    resolver,
  });

  const formClassName = clsx('col-sm-9 col-md-6 position-relative', {
    'on-ajax': isSubmitting,
  });

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: RegionDetailsType) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('location', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form className={formClassName} onSubmit={handleClickSubmit}>
      <Select
        name="region_id"
        label="Region"
        required
        options={regionOptions}
        control={control}
      />
      <DropDownState name="state_id" label="State" required control={control} />
      <Input name="location" label="Location" required control={control} />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default DispatchRegionForm;
