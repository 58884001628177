import GregorianCalendar from 'gregorian-calendar';
import { isValid, endOfMonth } from 'date-fns';
import { parseFromTimeZone } from 'date-fns-timezone';

import { settingsDateOnly, settingsFullDate } from 'stores/_mobx/service';

const CalendarLocale = require('rc-calendar/lib/locale/en_US');

const localeSettings = 'en-US';

export type TDateSeparator = '-' | '/';
export type TDateFormat = { '-': string; '/': string };

const FORMAT: TDateFormat = {
  '-': 'm-d-y',
  '/': 'm/d/y',
};

export type TMonthName =
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December';

export type TDayName =
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday';

export const months: TMonthName[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const daysOfWeek: TDayName[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const ALLOWED_TZ = {
  CST: 'Central',
  CDT: 'Central',
  MST: 'Mountain',
  MDT: 'Mountain',
  EST: 'Eastern',
  EDT: 'Eastern',
  PST: 'Pacific',
  PDT: 'Pacific',
};

export const getAppCurrentTimeString = () =>
  new Date().toLocaleString('en-US', settingsFullDate);

const getStartOfDate = (date: string) => `${date} 00:00:00.000`;

const getEndOfDate = (date: string) => `${date} 23:59:59:999`;

export const convertToAppTimezone = (date: string) => {
  let finalDate = /^\d{2}\/\d{2}\/\d{4}$/.test(date)
    ? getStartOfDate(date)
    : /^\d{2}\/\d{2}\/\d{4}\s\d{2}:\d{2}?$/.test(date)
    ? `${date}:00`
    : date;

  return parseFromTimeZone(finalDate, 'MM/DD/YYYY hh:mm:ss', {
    timeZone: settingsDateOnly.timeZone,
  });
};

export const getTimeZoneName = () => {
  const date = new Date().toLocaleString(localeSettings, {
    ...settingsDateOnly,
    timeZoneName: 'short',
  });

  const timezoneName: string = date.substring(12);
  // @ts-ignore
  return ALLOWED_TZ[timezoneName] || timezoneName;
};

export default class DateUtils {
  static timezoneCache = {
    CST: { offset: -6, label: 'Central' },
    CDT: { offset: -5, label: 'Central' },
    MST: { offset: -7, label: 'Mountain' },
    MDT: { offset: -6, label: 'Mountain' },
    EST: { offset: -5, label: 'Eastern' },
    EDT: { offset: -4, label: 'Eastern' },
    PST: { offset: -7, label: 'Pacific' },
    PDT: { offset: -7, label: 'Pacific' },
  };

  /**
   * get week number in month from 0
   * @return int
   */
  static getWeekNumber(date: Date) {
    if (!date) {
      date = new Date();
    }
    const month = date.getMonth();
    const year = date.getFullYear();
    const firstWeekDay = new Date(year, month).getDay();
    const offsetDate = date.getDate() - 1 + firstWeekDay;
    return Math.floor(offsetDate / 7);
  }

  static getWeekMaxNumber(dateProp: Date) {
    const date = dateProp || new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    const first = new Date(year, month);
    const last = endOfMonth(new Date(year, month));
    const firstWeekDay = first.getDay();
    const offsetDate = last.getDate() - 1 + firstWeekDay;
    return Math.floor(offsetDate / 7);
  }

  /**
   * Do time string representation (hh:mm:ss)
   * @param calendar GregorianCalendar
   * @returns string
   */
  static doTimeString(calendar: GregorianCalendar) {
    if (!calendar) {
      return '';
    }
    return (
      DateUtils.twoDigits(calendar.getHourOfDay().valueOf()) +
      ':' +
      DateUtils.twoDigits(calendar.getMinutes().valueOf()) +
      ':' +
      DateUtils.twoDigits(calendar.getSeconds().valueOf())
    );
  }

  /**
   * Do date string representation from gregorianCalendar instance
   * @param gregorianCalendar GregorianCalendar
   * @param separator string (/, -, etc)
   * @param format string (m/d/y, m-d-y, y-m-d)
   * @returns string  (01/22/2016) (m/d/y)
   */
  static doDateString(
    gregorianCalendar: GregorianCalendar | string,
    separator?: TDateSeparator,
    format?: string
  ): string {
    if (typeof gregorianCalendar === 'string') {
      return gregorianCalendar;
    }
    if (separator === undefined) {
      separator = '/';
    }
    if (!gregorianCalendar) {
      return '';
    }
    if (!format) {
      format = `m${separator}d${separator}y`;
    }
    const year = gregorianCalendar.getYear().toString();
    const month = DateUtils.twoDigits(
      gregorianCalendar.getMonth().valueOf() + 1
    );
    const day = DateUtils.twoDigits(
      gregorianCalendar.getDayOfMonth().valueOf()
    );
    const hour = DateUtils.twoDigits(
      gregorianCalendar.getHourOfDay().valueOf()
    );
    const minutes = DateUtils.twoDigits(
      gregorianCalendar.getMinutes().valueOf()
    );
    const seconds = DateUtils.twoDigits(
      gregorianCalendar.getSeconds().valueOf()
    );
    let res: string = format;
    res = res.replace('y', year);
    res = res.replace('m', month);
    res = res.replace('d', day);
    res = res.replace('h', hour);
    res = res.replace('M', minutes);
    res = res.replace('s', seconds);
    return res;
  }

  /**
   * Append leading zero
   * @param value int|string
   * @returns string
   */
  static twoDigits(value: number | string): string {
    value = '' + value;
    if (parseInt(value, 10) > -10 && parseInt(value, 10) < 10) {
      return `0${value}`;
    }
    return value;
  }

  // Return false if invalid date
  static validateDate(day: number, month: number, year: number) {
    const date = new Date(
      `${year}-${month.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}-${day.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })} 00:00:00`
    );

    return isValid(date)
      ? date.getFullYear() === Number(year) &&
          1 + date.getMonth() === month &&
          date.getDate() === Number(day)
      : false;
  }

  /**
   * Parse date and return gregorianCalendar instance
   * @param strDate string (2016/01/31)
   * @param separator string (/)
   * @param format string (y/m/d)
   * @returns GregorianCalendar
   */
  static parseDate(
    strDate: string,
    separator: string,
    format?: string
  ): GregorianCalendar {
    if (!strDate) {
      return null;
    }
    if (!separator) {
      separator = '-';
    }
    if (!format) {
      format = (FORMAT as any)[separator];
    }
    const arr = strDate.split(separator);
    let year: number = 0;
    let month: number = 1;
    let day: number = 0;
    const f = (format as string).split(separator);
    for (let i = 0; i < f.length; i++) {
      switch (f[i]) {
        case 'd':
          day = parseInt(arr[i], 10);
          break;
        case 'm':
          month = parseInt(arr[i], 10);
          break;
        case 'y':
          year = parseInt(arr[i], 10);
          break;
      }
    }
    if (isValid(new Date(day, month, year))) {
      return DateUtils.getGregorianCalendar(year, month - 1, day);
    }
    return null;
  }

  static parseDateTime(strDate: string, format: string): GregorianCalendar {
    const parts = strDate.split(/\D/g);
    const date = {
      y: 0,
      m: 0,
      d: 0,
      h: 0,
      M: 0,
      s: 0,
    };
    let index = 0;
    for (let c = 0; c < format.length; c++) {
      switch (format[c]) {
        case 'y':
        case 'm':
        case 'd':
        case 'h':
        case 'M':
        case 's':
          (date as any)[format[c]] = parseInt(parts[index], 10);
          index++;
          break;
      }
    }
    return DateUtils.getGregorianCalendar(
      date.y,
      date.m,
      date.d,
      date.h,
      date.M,
      date.s
    );
  }

  static getLocale() {
    return {
      ...CalendarLocale,
      timezoneOffset: -1 * new Date().getTimezoneOffset(),
      firstDayOfWeek: 0,
      minimalDaysInFirstWeek: 1,
    };
  }

  /**
   * Create gregorian calendar instance
   * for more details
   * @see /node_modules/gregorian-calendar/lib/locale/en_US.js
   *
   * @param year
   * @param month
   * @param day
   * @param hour
   * @param minute
   * @param sec
   * @return GregorianCalendar
   */
  static getGregorianCalendar(
    year?: number,
    month?: number,
    day?: number,
    hour?: number,
    minute?: number,
    sec?: number
  ): GregorianCalendar {
    const calendar = new GregorianCalendar(DateUtils.getLocale());
    if (
      year === undefined &&
      month === undefined &&
      day === undefined &&
      hour === undefined &&
      minute === undefined &&
      sec === undefined
    ) {
      calendar.setTime(new Date().getTime());
    } else {
      if (!(year === null || year === undefined)) {
        calendar.setYear(year);
      }
      if (!(month === null || month === undefined)) {
        calendar.setMonth(month);
      }
      if (!(day === null || day === undefined)) {
        calendar.setDayOfMonth(day);
      }
      if (!(hour === null || hour === undefined)) {
        calendar.setHourOfDay(hour);
      }
      if (!(minute === null || minute === undefined)) {
        calendar.setMinutes(minute);
      }
      if (!(sec === null || sec === undefined)) {
        calendar.setSeconds(sec);
      }
    }
    return calendar;
  }

  /**
   * restore gregorian calendar after json transformation:
   * var string = JSON.stringify(gregorianCalendar);
   * var calendar = JSON.parse(string);
   * @param object
   * @returns GregorianCalendar
   */
  static restoreCalendar(object: any) {
    if (object && object.time) {
      const calendar = DateUtils.getGregorianCalendar(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      );
      calendar.setTime(object.time);
      return calendar;
    }
    return null;
  }

  static toDateFull(
    timestamp: number,
    separator: TDateSeparator,
    format?: string
  ) {
    const calendar = DateUtils.getGregorianCalendar(); // locale issue
    calendar.setTime(timestamp);
    return DateUtils.doDateString(calendar, separator, format);
  }

  static toDateCanonic(timestamp: number) {
    return DateUtils.toDateFull(timestamp, '-', 'y-m-d');
  }

  static toDateTimeCanonic(timestamp: number) {
    return DateUtils.toDateFull(timestamp, '-', 'y-m-d h:M:s');
  }

  static toDate(timestamp: number) {
    return DateUtils.toDateFull(timestamp, '/');
  }

  static toDateTime(timestamp: number) {
    const calendar = DateUtils.getGregorianCalendar(); // locale issue
    calendar.setTime(timestamp);
    return (
      DateUtils.doDateString(calendar, '/') +
      ' ' +
      DateUtils.doTimeString(calendar)
    );
  }
}

export const timeZoneRender = (
  value: string,
  timezone: string,
  dateOnly?: boolean
) => {
  const offset = (DateUtils.timezoneCache as any)[timezone];

  if (!isValid(new Date(value))) {
    return '';
  }

  if (offset === undefined) {
    return dateToLocalTimezone({ date: value, dateOnly });
  }

  const dateUtc = addZuluTimezone<string>(value);

  const timeZoneDifference =
    -new Date().getTimezoneOffset() - offset.offset * 60;

  const dateResult = new Date(dateUtc).getTime() - timeZoneDifference * 60000;

  const date = new Date(dateResult);

  const { timeZone, ...options } = dateOnly
    ? settingsDateOnly
    : settingsFullDate;

  return date.toLocaleString(localeSettings, options).replace(/,/g, '');
};

/**
 * function to convert date string (MM/dd/yyyy) to Date object according with user timezone
 * @param {string} dateString - The date string in format MM/dd/yyyy
 * @returns {Date | null} - The date object or null
 */
export const strToDate = (dateString: string) => {
  const date = new Date(dateString);

  return Number.isNaN(date.getDate()) ? null : date;
};

export const timeStringToDate = (timeStr: string) => {
  const date = new Date();

  const [hours, minutes] = timeStr ? timeStr.split(':') : [0, 0];

  date.setHours(Number(hours));

  date.setMinutes(Number(minutes));

  return date;
};

export const getDateString = (date: Date = new Date(), isUTC?: boolean) => {
  const { timeZone, ...options } = settingsDateOnly;

  return isUTC
    ? date.toISOString()
    : date.toLocaleString(localeSettings, options);
};

// TODO: delete function addZuluTimezone after backend start return date/time string in format 'yyyy-MM-ddTHH:mm:ssZ'
/**
 *
 * @param {string} date - Time in format like this 01/11/2023 04:59?:00
 * @return {string} - Date/time in format by Greenwich '01/11/2023 04:59?:00Z'
 */
export const addZuluTimezone = <T>(
  dateIncome: string,
  defaultValue?: T
): string | T => {
  if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{1,6})?Z/.test(dateIncome)) {
    return dateIncome;
  } else if (/^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}(:\d{2})?$/.test(dateIncome)) {
    return `${dateIncome}Z`;
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(dateIncome)) {
    return `${dateIncome} 00:00Z`;
  } else if (
    /^\d{1,2}\/\d{2}\/\d{4}\s\d{2}:\d{2}(:\d{2,4})?$/.test(dateIncome)
  ) {
    const [dateStr] = dateIncome.match(/^\d{1,2}\/\d{2}\/\d{4}/);
    const [month, date, year] = dateStr.split('/');
    const correctDateFormat = `${year}-${
      month.length === 2 ? month : '0' + month
    }-${date}`;
    return `${dateIncome.replace(
      /^\d{1,2}\/\d{2}\/\d{4}/,
      correctDateFormat
    )}Z`;
  } else if (/^\d{1,2}\/\d{2}\/\d{4}$/.test(dateIncome)) {
    const [month, date, year] = dateIncome.split('/');
    return `${year}-${month.length === 2 ? month : '0' + month}-${date}T00:00Z`;
  }

  return defaultValue === undefined ? dateIncome : defaultValue;
};

/**
 *
 * @param {string} dateISO - date|time in ISO format yyyy-MM-ddTHH:mm:ssZ
 * @returns {[string,string]} - date and time in format ['MM/dd/yyyy', 'HH:mm:ss']
 */
export const dateISOStringToDateAndTime = (
  dateISO: string
): [string, string] => {
  const isDateValid = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{3})?Z/.test(
    dateISO
  );

  const time = dateISO.substring(11, 19);

  const [yyyy, MM, dd] = isDateValid
    ? dateISO.substring(0, 10).split('-')
    : ['', '', ''];

  return [isDateValid ? `${MM}/${dd}/${yyyy}` : '', time];
};

/**
 * function converts date from ISO to local timezone
 * @param {string | Date} date.date - date|time in ISO format yyyy-MM-ddTHH:mm:ssZ or Date instance
 * @param {string=} date.defaultValue - not required value, it returns if date is not valid
 * @param {boolean=} date.dateOnly - set to 'true' if returned value should be in format 'MM/dd/yyyy' otherwise 'MM/dd/yyyy HH:mm'
 * @returns {string} - date and time in format 'MM/dd/yyyy HH:mm' | 'MM/dd/yyyy' | default value if it was passed
 */
export const dateToLocalTimezone = ({
  date,
  defaultValue = '',
  dateOnly = false,
}: {
  date: string | Date;
  defaultValue?: string;
  dateOnly?: boolean;
}): string => {
  if (date) {
    const isInstanceOfDate = date instanceof Date;

    const dateISO = isInstanceOfDate ? date : addZuluTimezone(date, '');

    const dateObj = isInstanceOfDate ? date : new Date(dateISO);

    const settings = dateOnly ? settingsDateOnly : settingsFullDate;

    return isValid(dateObj)
      ? dateObj.toLocaleString(localeSettings, settings).replace(/,/g, '')
      : defaultValue;
  }
  return defaultValue;
};

export const getDateRangeBounds = ({
  from,
  to,
}: {
  from: string | null;
  to: string | null;
}) => {
  const dateFrom = isValid(new Date(from))
    ? convertToAppTimezone(getStartOfDate(from)).toISOString()
    : from;
  const dateTo = isValid(new Date(to))
    ? convertToAppTimezone(getEndOfDate(to)).toISOString()
    : to;

  return {
    dateFrom,
    dateTo,
  };
};

/**
 * function converts date from string 'MM/dd/yyyy' to extremes of this date in ISO format 'yyyy-MM-ddTHH:mm:ssZ'
 * @param {string} date - date bounds of which we need to get
 * @returns {Object} dayBounds - object with day extremes
 * @returns {string | null} dayBounds.dateFrom - value with begin of day
 * @returns {string | null} dayBounds.dateTo - value with end of day
 */
export const getDayBounds = (
  date: string
): { dateFrom: string | null; dateTo: string | null } => {
  const dayRange = date
    ? getDateRangeBounds({ from: date, to: date })
    : { dateFrom: '', dateTo: '' };
  return dayRange;
};

const replacer = (date: string) => {
  const dateInLocalTimezone = dateToLocalTimezone({ date });

  return dateInLocalTimezone;
};

export const convertDateInStingToLocalTimezone = (text: string) => {
  return text.replace(
    /((\d{2}\/){2}\d{4}\s(\d{2}:){2}\d{2})|(\d{4}(-\d{2}){2}\s\d{2}:\d{2}(:\d{2})?)/g,
    replacer
  );
};

export const sortByDate = (a: string, b: string, dir: number) => {
  const dateA = Number(new Date(a)) || 0;

  const dateB = Number(new Date(b)) || 0;

  if (dir) {
    return dir < 0 ? dateB - dateA : dateA - dateB;
  }
  return 0;
};

/**
 * function convert duration (measured in seconds) into days/hours/minutes
 * @param {string} value - duration in seconds
 * @returns - duration looks like '5d 6h 34m'
 */
export const durationFormatter = (value?: string | null): string => {
  const totalSeconds = Number(value);

  if (!totalSeconds) return '';

  const minute = Math.trunc((totalSeconds % 3600) / 60);

  const hour = Math.trunc((totalSeconds % 86400) / 3600);

  const day = Math.trunc(totalSeconds / 86400);

  const duration = `${day ? `${day}d ` : ''}${day || hour ? `${hour}h ` : ''}${
    day || hour || minute ? `${minute}m ` : ''
  }`;

  return duration;
};
