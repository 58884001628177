import React from 'react';
import { Container } from 'flux/utils';

import { DiagnosticViewingLink } from 'page/components/DiagnosticViewing';
import { IconButton } from 'components/button';
import Notification from 'components/modal/Notification';
import Tooltip from 'components/tooltip';
import { Grid, GridControlButton } from 'components/grid';
import DialogDocuments from './DialogDocuments';
import DialogTranscriptionReports from './DialogTranscriptionReports';

import OrderFormPageActions from 'actions/workflow/order/OrderFormPageActions';
import OrderFormPageStore, {
  CptCode,
  CptIcdItem,
  IcdCode,
} from 'stores/workflow/order/OrderFormPageStore';
import { storeOrderOverview } from 'stores/_mobx/workflow/order/overview';

interface PViewOtherExams {
  patientId: number;
}

class OtherExamsPopup {
  type: string = '';
  orderId: number = 0;
}

class SViewOtherExams {
  dataSource: Array<CptIcdItem> = [];
  popup: OtherExamsPopup = new OtherExamsPopup();
  onAjax: boolean = false;
  selected: Array<number> = [];
  cptCodes: Array<number> = [];
}

class ViewOtherExams extends React.Component<PViewOtherExams, SViewOtherExams> {
  static getStores() {
    return [OrderFormPageStore];
  }

  static calculateState(state: SViewOtherExams) {
    const newState = !!state ? state : new SViewOtherExams();
    return Object.assign({}, newState, {
      dataSource: OrderFormPageStore.getState().cptIcdByPatient,
    });
  }

  COLUMNS = [
    { name: 'facility_nm', title: 'Facility' },
    {
      name: 'dateofservice',
      title: 'DOS',
      className: 'text-nowrap',
      dataType: 'date',
    },
    { name: 'examtype', title: 'Modality' },
    {
      name: 'cpt_codes',
      title: 'CPT',
      render: (code: Array<CptCode>) => (
        <span className="fs-5">
          {Array.isArray(code) && code.length > 0
            ? this.codeRender(this.mapCpt(code))
            : null}
        </span>
      ),
    },
    {
      name: 'icddesc',
      title: 'ICD',
      render: (code: Array<IcdCode>) => (
        <span className="fs-5">
          {Array.isArray(code) && code.length
            ? this.codeRender(this.mapIcd(code))
            : null}
        </span>
      ),
    },
    {
      name: 'enableRep',
      title: 'Report',
      render: (value: boolean, data: CptIcdItem) =>
        this.linkRender(value, data.resulttooltip, 'report', data.refid),
    },
    {
      name: 'enableFax',
      title: 'Fax Report',
      render: (value: boolean, data: CptIcdItem) =>
        this.linkRender(value, data.faxtooltip, 'fax', data.refid),
    },
    {
      name: 'enablePh',
      title: 'Phone Result',
      render: (value: boolean, data: CptIcdItem) =>
        this.linkRender(value, data.phonetooltip, 'phone', data.refid),
    },
    {
      name: 'enableEx',
      title: 'Document',
      render: (value: boolean, data: CptIcdItem) =>
        this.linkRender(value, data.externaltooltip, 'document', data.refid),
    },
    {
      name: 'enableImg',
      title: 'View Images',
      render: (v: any, { refid }: CptIcdItem) => (
        <DiagnosticViewingLink orderId={refid} />
      ),
    },
  ];

  linkRender = (
    isEnable: boolean,
    tooltip: string,
    type: string,
    orderId: number
  ) => {
    return (
      <div className={`available ${isEnable ? '' : ' negative'}`}>
        {isEnable ? (
          <IconButton
            title={tooltip}
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                popup: {
                  type,
                  orderId,
                },
              });
            }}>
            <i className="bi bi-check-circle" />
          </IconButton>
        ) : (
          <i className="bi bi-x-circle" title={tooltip} />
        )}
      </div>
    );
  };

  codeRender = (code: Array<React.ReactNode>) => (
    <Tooltip body={code}>
      <i className="bi bi-upc-scan text-primary" />
    </Tooltip>
  );

  componentDidMount() {
    this.updateData();
  }

  componentWillUnmount() {
    OrderFormPageActions.clearCptIcdByPatient();
  }

  onChangeSelect = (selected: Array<number>) => {
    const cptCodes = this.getValidCptCodes(selected);
    this.setState({ selected, cptCodes });
  };

  getValidCptCodes = (selected: Array<number>): Array<number> => {
    return this.state.dataSource
      .filter((cptIcdItem: CptIcdItem) => selected.includes(cptIcdItem.refid))
      .flatMap((cptIcdItem: CptIcdItem) => cptIcdItem.cpt_codes)
      .filter((cptCode: CptCode) => !!cptCode)
      .map((code: CptCode) => code.cpt_code)
      .map((codeValue: string) => parseInt(codeValue))
      .filter((codeValue: number) => !isNaN(codeValue));
  };

  mapCpt = (cptCodes: CptCode[]): Array<React.ReactNode> => {
    const codesList: Array<React.ReactNode> = cptCodes
      .filter((code: CptCode) => !isNaN(parseInt(code.cpt_code)))
      .filter((code: CptCode) => !!code.cpt_desc && code.cpt_desc.length > 0)
      .map((code: CptCode, i: number) => (
        <p key={i}>
          {i + 1}) <b>{code.cpt_code}</b> - {code.cpt_desc} ({code.session_no})
        </p>
      ));

    return codesList;
  };

  mapIcd = (icdCodes: IcdCode[]): Array<React.ReactNode> => {
    const icdList: Array<React.ReactNode> = icdCodes
      .filter((code: IcdCode) => !!code)
      .map((code: IcdCode, i: number) => (
        <p key={i}>
          {i + 1}) <b>{code.icd_code}</b> - {code.short_description}
        </p>
      ));
    return icdList;
  };

  getControlButtons = () => {
    const { cptCodes, selected } = this.state;
    const ordersLength: number = selected.length;
    const cptCodesLength: number = cptCodes.length;
    const isValid = ordersLength >= 2 && cptCodesLength <= 8;

    const handleClick = () => {
      if (!isValid) {
        Notification.warning(
          'Please select orders for same patient with total amount of CPT procedures greater than 2 and less than 8',
          { autoClose: 6000 }
        );
      } else {
        storeOrderOverview.openEUnityLinkByParams(this.state.selected);
      }
    };

    return (
      <GridControlButton
        title="View Images in eUnity"
        onClick={handleClick}
        className={`view_in_eunity${isValid ? '' : ' disabled'}`}
      />
    );
  };

  render() {
    const { onAjax, dataSource } = this.state;

    return (
      <div className="view-other-exams">
        <Grid
          id="view_other_exams"
          columns={this.COLUMNS}
          selectId="refid"
          onAjax={onAjax}
          gridControlPanel={this.getControlButtons()}
          dataSource={dataSource}
          dataSourceCount={dataSource.length}
          disablePagination
          onSelectChange={(selectedItems) => this.onChangeSelect(selectedItems)}
        />

        {this.renderPopup()}
      </div>
    );
  }

  renderPopup() {
    const { patientId } = this.props;
    const { type, orderId } = this.state.popup;
    const clb = () => this.setState({ popup: new OtherExamsPopup() });
    switch (type) {
      case 'document':
        return (
          <DialogDocuments
            onClose={clb}
            patientId={patientId}
            orderId={orderId}
          />
        );
      case 'report':
        return (
          <DialogTranscriptionReports
            onClose={clb}
            patientId={patientId}
            orderId={orderId}
          />
        );
      default:
        return null;
    }
  }

  updateData() {
    this.setState({ onAjax: true });
    OrderFormPageActions.getCptIcdByPatient(this.props.patientId).then(
      this.updateDataCallback
    );
  }

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.setState({
      onAjax: false,
      selected: [],
    });

    if (grid) {
      // @ts-ignore
      grid.clearSelection();
    }
  };
}

export default Container.create(ViewOtherExams, { withProps: true });
