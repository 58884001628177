import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import DropdownEmailTemplate from 'components/project/dropdown/EmailTemplate';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Checkbox } from 'components/form/checkbox';
import { TextEditor } from 'components/form/richTextEditor';
import { sendEmailValidation as resolver } from './validation';

import { storeEmailTemplate } from 'stores/_mobx/systemSetup/masterSetting/emailTemplate';

const initialState: FormModel = {
  attachment: false,
  body: '',
  email: '',
  subject: '',
  template: null,
  examRequisition: false,
};

export interface FormModel {
  attachment: boolean;
  body: string;
  email: string;
  subject: string;
  template: number | null;
  examRequisition: boolean;
}

interface PropsType {
  defaultEmail: string;
  withExam?: boolean;
  onClose: () => void;
  onSubmit: (data: FormModel) => void;
}

const DialogFormEmail = ({
  defaultEmail,
  withExam = false,
  onClose,
  onSubmit,
}: PropsType) => {
  const defaultValues = useMemo(
    () => ({
      ...initialState,
      email: defaultEmail,
    }),
    [defaultEmail]
  );

  const {
    control,
    formState: { isDirty, isSubmitting },
    watch,
    setValue,
    reset,
    handleSubmit,
  } = useForm<FormModel>({ defaultValues, resolver });

  const selectedTemplate = watch('template');

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    const template = storeEmailTemplate.findTemplate(selectedTemplate);

    setValue('subject', template?.subject || '');
    setValue('body', template?.body || '');
  }, [selectedTemplate, setValue]);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Send email" onClose={onClose} />
      <DialogBody>
        <form className="row flex-column">
          <Input
            name="email"
            label="Email"
            className="col-md-10 col-lg-8"
            required
            control={control}
          />
          <DropdownEmailTemplate
            name="template"
            label="Template"
            className="col-md-10 col-lg-8"
            control={control}
          />
          <Checkbox
            name="attachment"
            label="Include Attachment"
            control={control}
          />
          {withExam && (
            <Checkbox
              name="examRequisition"
              label="Include Exam Requisition Form(s)"
              control={control}
            />
          )}
          <Input name="subject" label="Subject" required control={control} />
          <TextEditor name="body" control={control} />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          text="Reset"
          variant="warning"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          text="Send"
          disabled={!isDirty || isSubmitting}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogFormEmail;
