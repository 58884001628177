import React from "react";
import AbstractFilter, { EQUAL_EXT_CONDITIONS } from "./AbstractFilter";

export default class AbstractEqualExtFilter extends AbstractFilter {
  CONDITIONS = EQUAL_EXT_CONDITIONS;

  render() {
    return (
      <div className={`row ${this.className}`}>
        {this.renderConditions()}
        {this.state.filterItem.condition === "between"
          ? this.renderDateRange()
          : this.renderDateValue()}
      </div>
    );
  }
}
