import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';

import { storeMobileOperator } from 'stores/_mobx/systemSetup/masterSetting/mobileOperator';

const DropdownMobileOperator = (props: Omit<SelectPropsType, 'options'>) => {
  const { options, fetchingOptions } = storeMobileOperator;

  useEffect(() => {
    storeMobileOperator.getOptions();
  }, []);

  return <Select {...props} options={options} isLoading={fetchingOptions} />;
};

export default observer(DropdownMobileOperator);
