import React from 'react';

import { Grid } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import { downloadFile } from 'utils/downloadFile';

interface FilesPreviewType {
  desc: string;
  fileName: string;
  path: string;
  uploadDate: string;
}

interface PropsType {
  files: FilesPreviewType[];
  fetching: boolean;
  onClose: () => void;
}
function handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
  const link = e.currentTarget;
  if (!/.*\.pdf$/.test(link.href)) {
    e.preventDefault();
    downloadFile(link.href);
  }
}

const columns = [
  { name: 'fileName', title: 'Document name' },
  { name: 'uploadDate', title: 'Uploaded date' },
  {
    name: 'path',
    title: 'View',
    className: 'text-center',
    render: (path: string) => (
      <a
        href={path}
        target="_blank"
        rel="noreferrer"
        className="text-primary fs-5"
        onClick={handleClick}>
        <i className="bi bi-eye-fill" />
      </a>
    ),
  },
];

const DialogDocumentsList = ({ files, fetching, onClose }: PropsType) => (
  <Dialog size="large" handleClose={onClose}>
    <DialogHeader title="Documents" onClose={onClose} />
    <DialogBody>
      <Grid
        id="document_modal_grid"
        onAjax={fetching}
        columns={columns}
        dataSource={files}
        disablePagination
      />
    </DialogBody>
  </Dialog>
);

export default DialogDocumentsList;
