import { makeObservable, observable, action, runInAction } from 'mobx';

import CorporateRolesDropdown from 'components/project/dropdown/CorporateRolesDropdown';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { getDateRangeBounds } from 'utils/DateUtils';

const defaultFilterValue: FilterType = {
  dosStart: '',
  dosEnd: '',
  period: 'A',
  examType: [],
  corporateId: [],
};

export interface FilterType {
  dosStart: string;
  dosEnd: string;
  period: string;
  examType: number[];
  corporateId: number[];
}

interface LogType {
  cancellations: number;
  encounters: number;
  facility: string;
  patients: number;
  pos: number;
  procedures: number;
  visits: number;
}

interface CorporateSummaryResponse {
  count: number;
  data: LogType[];
}

class CorporateSummary {
  fetching: boolean = false;
  logList: LogType[] = [];
  logTotal: number = 0;
  filter: FilterType = defaultFilterValue;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'corporate_summary_gridId' });

    makeObservable(this, {
      fetching: observable,
      logList: observable,
      logTotal: observable,
      filter: observable,

      setFetching: action,
      setFilter: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFilter(payload: FilterType) {
    this.filter = payload;
  }

  setDefaultFilter() {
    const currentUserCorporation = CorporateRolesDropdown.getCorporationId();

    const filter = {
      ...this.filter,
      corporateId: currentUserCorporation
        ? [currentUserCorporation]
        : this.filter.corporateId,
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const currentUserCorporation = CorporateRolesDropdown.getCorporationId();

    const filter = {
      ...defaultFilterValue,
      corporateId: currentUserCorporation
        ? [currentUserCorporation]
        : defaultFilterValue.corporateId,
    };

    return filter;
  }

  async getCorporateSummaryLog(filter: FilterType = this.filter) {
    const { pagination } = this.page;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const payload = [
      {
        skip: pagination.skip,
        pageSize: pagination.pageSize,
      },
      {
        dosFrom: dos.dateFrom,
        dosTo: dos.dateTo,
        corporateId: filter.corporateId,
        modalityId: filter.examType,
      },
    ];

    this.setFetching(true);

    try {
      const { count, data } = await apiRequest<CorporateSummaryResponse>({
        url: 'report.CorporateSummary.getOverview',
        data: payload,
      });

      const logList = data.map((log) => ({
        ...log,
        cancellations: Number(log.cancellations),
        encounters: Number(log.encounters),
        patients: Number(log.patients),
        pos: Number(log.pos),
        procedures: Number(log.procedures),
        visits: Number(log.visits),
      }));

      runInAction(() => {
        this.logList = logList;
        this.logTotal = count;
      });
    } catch (e) {
      runInAction(() => {
        this.logList = [];
        this.logTotal = 0;
      });
    } finally {
      this.setFetching(false);
    }
  }

  prepareFilterToExport() {
    const { filter } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return {
      ...filter,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
    };
  }
}

export const storeCorporateSummary = new CorporateSummary();
