import Grid from '../Grid';
import DomUtils from 'utils/DomUtils';

export default class ScrollFacade {
  columns: Array<any>;
  triggerUpdate?: () => void = null;
  grid: Grid;
  minColumns: number;
  startColumns: number;
  requireSlider: boolean;
  renderContainerWidth: number;

  constructor(cols: Array<any>, grid: Grid, triggerUpdate?: () => void) {
    this.triggerUpdate = triggerUpdate;
    this.grid = grid;

    let minColumns: number = grid.props.minColumns || 0;
    if (minColumns >= cols.length) {
      minColumns = cols.length - 1;
    }
    this.minColumns = minColumns;
    this.startColumns = 0;

    const minColWidth = grid.props.minColWidth || 115;
    if (cols.length <= minColumns) {
      this.columns = cols;
      this.requireSlider = false;
    }
    const container = (grid.refs as any).container;
    let containerWidth: number;
    if (!container) {
      const win = DomUtils.getBrowserSize();
      containerWidth = win.width;
    } else {
      const parent = container.parentNode;
      containerWidth = parent ? parent.offsetWidth : container.offsetWidth;
    }
    this.renderContainerWidth = containerWidth;

    let total = (cols.length - 1) * minColWidth;
    let flagHasMaxWidth = false;
    for (let i = 0; i < cols.length; i++) {
      if (cols[i].maxWidth) {
        flagHasMaxWidth = true;
        break;
      }
    }
    if (flagHasMaxWidth) {
      total = 0;
      for (let i = 0; i < cols.length; i++) {
        total += cols[i].maxWidth ? cols[i].maxWidth : minColWidth;
      }
    }
    if (containerWidth > total) {
      this.columns = cols;
      this.requireSlider = false;
    } else {
      let filler = minColWidth * minColumns;
      const columns = [];
      for (let i = 0; i < minColumns; i++) {
        const clazz1 =
          cols[i] &&
          cols[i].className &&
          cols[i].className.indexOf('width-') > -1
            ? ''
            : ' width-100';
        const clazz2 = clazz1 + ' bordered';
        if (i === minColumns - 1) {
          const borderColumn = { ...cols[i] };

          if (borderColumn.className) {
            borderColumn.className += clazz2;
          } else {
            borderColumn.className = clazz2;
          }
          columns.push(borderColumn);
        } else if (i < minColumns) {
          const shownColumn = { ...cols[i] };
          if (shownColumn.className) {
            shownColumn.className += clazz1;
          } else {
            shownColumn.className = clazz1;
          }
          columns.push(shownColumn);
        } else {
          columns.push(cols[i]);
        }
      }
      let length = 0;
      let tempFiller = filler;
      while (tempFiller < containerWidth) {
        length++;
        tempFiller += minColWidth;
      }
      let startColumns =
        minColumns +
        Math.round(
          (cols.length - length - minColumns) * grid.state.columnsOffset
        );
      this.startColumns = startColumns;
      while (filler <= containerWidth) {
        const col = cols[startColumns++];
        if (col) {
          columns.push(col);
        }
        filler += minColWidth;
      }
      this.columns = columns;

      this.requireSlider = true;
    }
  }

  getColumns(): Array<any> {
    return this.columns;
  }

  afterUpdate(): void {
    let containerWidth: number;
    const container = this.grid.refs.container as any;
    if (!container) {
      const win = DomUtils.getBrowserSize();
      containerWidth = win.width;
    } else {
      const parent = container.parentNode;
      containerWidth = parent ? parent.offsetWidth : container.offsetWidth;
    }
    if (containerWidth !== this.renderContainerWidth && this.triggerUpdate) {
      this.triggerUpdate();
    }
  }
}
