import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import { storeEmailReport } from 'stores/_mobx/workflow/serviceMaintenance/emailReport';

interface PropsType {
  message: string;
}

const DialogMessage = ({ message }: PropsType) => (
  <Dialog handleClose={storeEmailReport.clearMessagePreview}>
    <DialogHeader
      title="Message"
      onClose={storeEmailReport.clearMessagePreview}
    />
    <DialogBody>
      <div
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      />
    </DialogBody>
  </Dialog>
);

export default DialogMessage;
