import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormPrefix, { FormModel } from './components/Form';

import { storePrefix } from 'stores/_mobx/systemSetup/masterSetting/prefix';
import { URL_PREFIX } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const PrefixAddPage = ({ history }: PropsType) => {
  const handleSubmit = (data: Omit<FormModel, 'refid'>) =>
    storePrefix.addPrefix(data).then((response) => {
      if (response) return response;
      history.push(URL_PREFIX);
      return null;
    });

  return (
    <>
      <Title title="Add prefix" />
      <FormPrefix backUrl={URL_PREFIX} onSubmit={handleSubmit} />
    </>
  );
};

export default PrefixAddPage;
