import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { SpinnerFixed } from 'components/spinner';
import MessageViewer from '../components/MessageViewer';

import { storeInbox } from 'stores/_mobx/message/inbox';
import { URL_INBOX } from 'constant/path/messages';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const InboxEmailPreviewPage = ({ match: { params } }: PropsType) => {
  const { fetching, emailPreview } = storeInbox;

  useEffect(() => {
    const emailId = Number(params.id);

    storeInbox.getEmailPreview([emailId, 'I', 'I']).then(() => {
      storeInbox.getUnreadEmailCount();
    });

    return storeInbox.clearEmailPreview;
  }, [params.id]);

  return (
    <>
      <LayoutSideTitle title="Inbox Message">
        <Link
          to={`${URL_INBOX}/${params.id}/forward`}
          className="btn btn-primary">
          Forward
        </Link>
        <Link
          to={`${URL_INBOX}/${params.id}/reply`}
          className="btn btn-primary">
          Reply
        </Link>
        <Link to={URL_INBOX} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      {emailPreview && <MessageViewer email={emailPreview} />}

      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(InboxEmailPreviewPage);
