import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { SelectCptCategory } from 'components/project/dropdown/CptCategoryDropdown';
import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { Textarea } from 'components/form/textarea';
import { Button } from 'components/button';
import resolver from './validation';

import {
  initialValues,
  CptCodeDetailsType,
} from 'stores/_mobx/systemSetup/codeActivationMaster';
import { ServerFormValidationType } from 'types';

interface PropsType {
  defaultValues?: CptCodeDetailsType;
  backUrl: string;
  onSubmit: (data: CptCodeDetailsType) => Promise<any>;
}

const CodeActivationForm = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting, isDirty },
    reset,
    handleSubmit,
    setError,
  } = useForm<CptCodeDetailsType>({
    defaultValues,
    resolver,
  });

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: CptCodeDetailsType) =>
    onSubmit(data).then(
      (errors: ServerFormValidationType<CptCodeDetailsType>[]) => {
        if (errors)
          errors.forEach(({ field, ...body }) => {
            setError(field, body);
          });
      }
    )
  );

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      onSubmit={handleClickSubmit}
      className={`col-lg-8${isSubmitting ? ' on-ajax' : ''}`}>
      <div className="row">
        <Input
          name="cptCode"
          label="Code"
          className="col-sm-6 col-md-4"
          required
          control={control}
        />
        <Input
          name="cptPrice"
          label="Price"
          className="col-sm-6 col-md-4"
          type="number"
          step={0.01}
          required
          isChildrenPrefix
          control={control}>
          <span className="input-group-text">$</span>
        </Input>
        <SelectCptCategory
          name="cptCategory.id"
          label="Category"
          className="col-sm-6 col-md-4"
          control={control}
        />
      </div>
      <Textarea
        name="cptDescription"
        label="Description"
        rows={3}
        required
        control={control}
      />
      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          type="reset"
          text="Reset"
          variant="warning"
          onClick={handleReset}
          disabled={isSubmitting}
        />
        <Button type="submit" text="Save" disabled={isSubmitting || !isDirty} />
      </ControlsLayout>
    </form>
  );
};

export default CodeActivationForm;
