import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import DialogFax from 'page/components/DialogFaxPhone/DialogFax';
import { Grid } from 'components/grid';
import DialogSendMessage from './acquisition/DialogSendMessage';
import FilterAcquisition from './acquisition/Filter';
import columns from './acquisition/Columns';

import {
  storeDocumentManagerAcquisition,
  FilterType,
} from 'stores/_mobx/patientRecord/documentManagerAcquisition';

interface PropsType {
  isFilterVisible: boolean;
}

const DocumentAcquisitionTab = ({ isFilterVisible }: PropsType) => {
  const {
    fetching,
    documentList,
    documentTotal,
    email,
    faxDetail,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeDocumentManagerAcquisition;

  const handleClickSearch = (filterData: FilterType) => {
    storeDocumentManagerAcquisition.setFilter(filterData);
    setPaginationToStart();
  };

  useEffect(() => {
    storeDocumentManagerAcquisition.getDocumentList();
  }, [pagination]);

  return (
    <>
      <FilterAcquisition
        fetching={fetching}
        isFilterVisible={isFilterVisible}
        onSearch={handleClickSearch}
      />

      <Grid
        id="document_acquisition_tab_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={documentList}
        dataSourceCount={documentTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      {email && (
        <DialogSendMessage
          defaultValues={email}
          onSubmit={storeDocumentManagerAcquisition.sendEmail}
          onClose={storeDocumentManagerAcquisition.clearEmail}
        />
      )}
      {faxDetail && (
        <DialogFax
          title="Fax Requisition"
          details={faxDetail}
          onSendFax={storeDocumentManagerAcquisition.clearFaxDetail}
          onClose={storeDocumentManagerAcquisition.clearFaxDetail}
        />
      )}
    </>
  );
};

export default observer(DocumentAcquisitionTab);
