import { Controller, Control } from 'react-hook-form';
import clsx from 'clsx';

import PureCheckbox, {
  PureCheckboxProps,
} from 'components/form/checkbox/PureCheckbox';

interface OptionType {
  label: string;
  value: number | string;
}

export interface PropsType
  extends Omit<PureCheckboxProps, 'onChange' | 'onBlur' | 'onFocus'> {
  control: Control<any>;
  displayVariant?: 'multiLine' | 'oneLine';
  options: OptionType[];
  fetching?: boolean;
}

const CheckboxGroup = ({
  control,
  name,
  required = false,
  disabled = false,
  label = '',
  className,
  displayVariant = 'multiLine',
  'data-testid': dataTestId,
  fetching,
  options,
  ...rest
}: PropsType) => {
  const cn = clsx(
    'formfield-holder formfield-checkboxes position-relative',
    { required, 'part-inline': displayVariant === 'oneLine' },
    className
  );

  const testAttr = dataTestId || name;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({
        field: { value: currentValueList, onChange, ...field },
        fieldState: { error },
      }) => {
        const errorMessage = (error && error.message) || '';

        const isValuesArray = Array.isArray(currentValueList);

        return (
          <div className={cn}>
            {label && (
              <div className="formfield-label">
                <label data-testid={`${testAttr}-label-common`}>{label}</label>
              </div>
            )}
            <div className={`formfield-input${fetching ? ' on-ajax' : ''}`}>
              {options.map(({ value, label: optionLabel }) => (
                <PureCheckbox
                  {...field}
                  {...rest}
                  key={optionLabel}
                  disabled={disabled}
                  name={`${name}.${value}`}
                  label={optionLabel}
                  checked={
                    currentValueList
                      ? isValuesArray
                        ? currentValueList.includes(value)
                        : currentValueList[value]
                      : false
                  }
                  data-testid={`${testAttr}-${value}`}
                  onChange={(checked: boolean) => {
                    if (isValuesArray) {
                      const valuesResult = checked
                        ? currentValueList.concat(value)
                        : currentValueList.filter((id) => id !== value);
                      onChange(valuesResult);
                    } else {
                      onChange({ ...currentValueList, [value]: checked });
                    }
                  }}
                />
              ))}
            </div>
            {errorMessage && (
              <div className="error" data-testid={`${testAttr}-error`}>
                {errorMessage}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default CheckboxGroup;
