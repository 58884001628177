import { useState } from 'react';

import { LayoutSideTitle } from 'components/layout';
import Tabs from 'components/tabs';
import { Button } from 'components/button';
import EquipmentMaintenanceTab from './EquipmentMaintenanceTab';
import EquipmentMaintenanceTabDue from './EquipmentMaintenanceTabDue';

const tabsList = [
  { label: 'Equipment Maintenance', url: '?tab=maintenance' },
  { label: 'Equipment Maintenance Due', url: '?tab=mdue' },
];

const CalibrationRecordsPage = () => {
  const [isFilterVisible, setFilterVisibility] = useState<boolean>(false);

  const handleToggleFilter = () => {
    setFilterVisibility((state) => !state);
  };

  return (
    <>
      <LayoutSideTitle title="Calibration Records">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>
      <Tabs tabsList={tabsList}>
        <EquipmentMaintenanceTab isFilterVisible={isFilterVisible} />

        <EquipmentMaintenanceTabDue isFilterVisible={isFilterVisible} />
      </Tabs>
    </>
  );
};

export default CalibrationRecordsPage;
