export default class AbortControllerService {
  private abortController: AbortController | null = null;

  cancelAbortController = () => {
    this.abortController?.abort();
  };

  clearAbortController() {
    this.abortController = null;
  }

  manageAbortController() {
    this.cancelAbortController();

    const abortController = new AbortController();

    this.abortController = abortController;

    return abortController.signal;
  }
}
