import { Dispatcher } from 'flux';
import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import { dateToLocalTimezone } from 'utils/DateUtils';

export class PEligibilityManagerPageStore extends CommonPayload {
  count: number = null;
  loaded: boolean = false;
}

export class SEligibilityManagerPageStore {
  dataSource: Array<any> = [];
  dataSourceCount: number = 0;
  facilityValues: any = {};
  facilityValuesLoaded: boolean = false;
}

class EligibilityManagerPageStore extends ReduceStore<
  SEligibilityManagerPageStore,
  PEligibilityManagerPageStore
> {
  getInitialState() {
    return new SEligibilityManagerPageStore();
  }

  areEqual(
    state1: SEligibilityManagerPageStore,
    state2: SEligibilityManagerPageStore
  ) {
    return false;
  }

  reduce(
    state: SEligibilityManagerPageStore,
    action: PEligibilityManagerPageStore
  ) {
    switch (action.type) {
      case 'claim-management-eligibility-manager-overview':
        this.getState().dataSource = action.data.map((order: any) => {
          const dos = dateToLocalTimezone({
            date: order.dos,
            dateOnly: true,
          });

          const sheduleservdate = dateToLocalTimezone({
            date: order.sheduleservdate,
            dateOnly: true,
          });

          const orderassigned_date = dateToLocalTimezone({
            date: order.orderassigned_date,
          });

          const ordercreated_dt = dateToLocalTimezone({
            date: order.ordercreated_dt,
          });

          const ordercompletion_date = dateToLocalTimezone({
            date: order.ordercompletion_date,
          });

          const eligible_date = dateToLocalTimezone({
            date: order.eligible_date,
            defaultValue: order.eligible_date,
          });

          return {
            ...order,
            dos,
            sheduleservdate,
            orderassigned_date,
            ordercreated_dt,
            ordercompletion_date,
            eligible_date,
          };
        });
        this.getState().dataSourceCount = Number(action.count);
        break;
      case 'facility.FacilityMaster.GetFacilityValues-forEligibility':
        this.getState().facilityValues = action.data;
        this.getState().facilityValuesLoaded = action.loaded;
        break;
      default:
        break;
    }
    return state;
  }
}

const eligibilityManagerPageStore = new EligibilityManagerPageStore(
  appDispatcher as Dispatcher<PEligibilityManagerPageStore>
);
export default eligibilityManagerPageStore;
