import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import Dialog, { DialogHeader, DialogBody } from 'components/modal/dialog';

import { storeEquipmentLogs } from 'stores/_mobx/workflow/checkOutIn/logs';

const columns = [
  { name: 'name', title: 'Created By' },
  { name: 'equ_status', title: 'Status' },
  { name: 'reason', title: 'Reason' },
  { name: 'date', title: 'Date' },
  { name: 'time', title: 'Time' },
];

interface PropsType {
  id: number;
  onClose: () => void;
}

const DialogStatusLogs = ({ id, onClose }: PropsType) => {
  const { fetching, statusLog } = storeEquipmentLogs;

  useEffect(() => {
    storeEquipmentLogs.getStatusLog(id);

    return storeEquipmentLogs.clearStatusLog;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Status logs" onClose={onClose} />
      <DialogBody>
        <Grid
          columns={columns}
          onAjax={fetching}
          disablePagination
          dataSource={statusLog}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogStatusLogs);
