import * as React from 'react';
import * as ReactDOM from 'react-dom';

import Validation from 'components/form/Validation';
import InputState from 'components/form/InputState';
import Tooltip from 'components/tooltip';
import StringUtils from 'utils/StringUtils';

const LC_UC = /([a-z])([A-Z])/g;
const LOW_DASH = /_/g;

export type TAllMessages = keyof typeof Validation.messages;

export type TAbstractInputAttr = React.InputHTMLAttributes<HTMLElement> &
  React.Attributes & { idx?: string | number; ref?: React.Ref<HTMLElement> };

type TValueType = string | Array<string> | number;

export type TInputHTMLAttributes = {
  value?: TValueType;
} & React.InputHTMLAttributes<HTMLElement>;

export interface PAbstractInput extends TInputHTMLAttributes {
  id?: string;
  name?: string;
  value?: TValueType;
  label?: React.ReactNode;
  formId?: string;
  errorMessages?:
    | string
    | {
        required?: string;
        length?: string;
        positive?: string;
        custom?: string;
        max?: string;
      }
    | Array<any>;
  className?: string;
  style?: React.CSSProperties;
  validations?: any;
  type?: string;
  contentAfter?: React.ReactElement;
  contentAfterInput?: React.ReactElement;
  contentBefore?: React.ReactElement;
  errors?: Array<string>;
  onSetValue?: (
    name: string,
    value: any,
    errorMessages?: any,
    errorKeys?: any,
    event?: any,
    rowRef?: any
  ) => void;
  rowRef?: any;
  forceLabel?: string;
  noLabel?: boolean;
  attr?: TAbstractInputAttr;
  noclone?: string;
  'data-testid'?: string;

  shouldComponentUpdate?: (
    nextProps?: any,
    currentProps?: any,
    nextState?: any,
    currentState?: any
  ) => boolean;
  tooltip?: any;
}

export class SAbstractInput {
  id: string = '';
}

export default class AbstractInput<
  P extends PAbstractInput,
  S extends SAbstractInput
> extends React.Component<P, S> {
  id: string; // TODO use from SAbstractInput

  nameCache: Map<string, string> = new Map<string, string>();

  constructor(props: P) {
    super(props);
    this.state = Object.assign(new SAbstractInput(), this.state);
    this.id = '';
  }

  componentDidMount() {
    const { props } = this;
    if ((props.attr && props.attr.autoFocus) || props.autoFocus) {
      if (this.refs.input) {
        const node = ReactDOM.findDOMNode(this.refs.input);
        if (node) {
          (node as HTMLElement).focus();
        }
      }
    }
  }

  changeValue(event: any) {
    this.setValue(event.target.value, event);
  }

  setValue(
    value: string | number | boolean | Array<string>,
    event?: React.SyntheticEvent
  ) {
    const props = this.props;
    if (typeof props.onSetValue === 'function') {
      const { errorMessages, errorKeys } = this.getInputState(value);
      props.onSetValue(
        props.name,
        value,
        errorMessages,
        errorKeys,
        event,
        props.rowRef
      );
    }
  }

  getInputState(value: any): InputState {
    const errorKeys = this.validate(value) as Array<TAllMessages>;
    const errorMessages = this.findErrorMessage(errorKeys, value);
    return new InputState(value, errorKeys, errorMessages);
  }

  validate(value: any) {
    return Validation.validate(value, this.props.validations);
  }

  findErrorMessage(errorKeys: Array<TAllMessages>, value: any) {
    return AbstractInput.findErrorMessage(
      errorKeys,
      this.props.errorMessages,
      value
    );
  }

  static findErrorMessage(
    errorKeys: Array<TAllMessages>,
    errorCandidates: string | any,
    newValue?: any
  ) {
    const errors: Array<string> = [];
    for (let i = 0; i < errorKeys.length; i++) {
      let message = null;
      const candidateMessages = errorCandidates;
      if (
        typeof candidateMessages === 'string' &&
        candidateMessages.length > 0
      ) {
        message = candidateMessages;
      } else if (candidateMessages && typeof candidateMessages === 'object') {
        message = candidateMessages[errorKeys[i]]
          ? candidateMessages[errorKeys[i]]
          : null;
      }
      if (message === null || message === undefined) {
        message = Validation.messages[errorKeys[i]]
          ? Validation.messages[errorKeys[i]]
          : Validation.messages['default'];
      } else if (typeof message === 'function') {
        message = message(newValue);
      }
      if (errors.indexOf(message) === -1) {
        errors.push(message);
      }
    }
    return errors;
  }

  getValue() {
    return this.props.value;
  }

  buildLabel(
    id: string,
    inner: Array<React.ReactNode>,
    key?: string,
    disabled: boolean = false
  ): React.DetailedReactHTMLElement<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
    const { formId, name } = this.props;
    const datatestId = this.props['data-testid'] || this.props.name;
    const forId: string = id ? id : formId ? formId + '_' + name : null;
    const params: {
      key?: string;
      htmlFor?: string;
      style?: React.CSSProperties;
      className?: string;
      'data-testid': string;
    } = forId
      ? { htmlFor: forId, 'data-testid': `${datatestId}-label` }
      : { 'data-testid': `${datatestId}-label` };
    params.key = 'label' + (key ? '_' + key : '');
    params.className = 'form-label';
    params.style = disabled
      ? {
          opacity: 0.6,
        }
      : undefined;
    return React.createElement<
      React.LabelHTMLAttributes<HTMLLabelElement>,
      HTMLLabelElement
    >('label', params, inner);
  }

  wrapInput(
    input: any,
    label: React.ReactElement | string,
    forceLabel: string | boolean = false,
    className1: string = null
  ): React.ReactNode {
    const { tooltip, noLabel, type, className, validations, style } =
      this.props;

    const dataTestId = this.props['data-testid'] || this.props.name;
    const input0 = (
      <Tooltip
        alwaysVisible={tooltip?.visible}
        body={tooltip?.visible ? tooltip?.overlay : ''}
        theme={tooltip?.theme}
        allowPointer={tooltip?.allowPointer}
        className="formfield-input position-relative w-100">
        {input}
      </Tooltip>
    );
    const label0 =
      noLabel === true ? null : forceLabel || label ? (
        <div className="formfield-label">{label}</div>
      ) : null;

    let className0 = className1
      ? className1
      : 'formfield-holder formfield-' +
        (type
          ? type
          : this.getInputType() === 'input'
          ? 'text'
          : this.getInputType()) +
        (className ? ' ' + className : '');

    if (validations) {
      if (
        (typeof validations === 'string' &&
          validations.indexOf('required') > -1) ||
        validations.required !== undefined
      ) {
        className0 += ' required';
      }
    }

    const errors = this.buildErrorMessage();
    return (
      <div className={className0} style={style} data-testid={dataTestId}>
        {this.getContentBefore()}
        {label0}
        {input0}
        {this.getContentAfterInput()}
        {errors}
        {this.getContentAfter()}
        <div className="clearfix" />
      </div>
    );
  }

  getInputType(): string {
    return 'AbstractInput';
  }

  getContentAfterInput(): React.ReactElement {
    return this.props.contentAfterInput;
  }

  getContentAfter(): React.ReactNode {
    return this.props.contentAfter;
  }

  getContentBefore(): React.ReactElement {
    return this.props.contentBefore;
  }

  buildErrorMessage() {
    const { errors } = this.props;
    const dataTestId = this.props['data-testid'] || this.props.name;
    if (!errors) {
      return null;
    }
    const messages = [];
    for (let i = 0; i < errors.length; i++) {
      messages.push(<div key={i}>{errors[i]}</div>);
    }
    return (
      <div className="error" data-testid={`${dataTestId}-error`}>
        {messages}
      </div>
    );
  }

  getLabelValue(): React.ReactNode {
    const { label, name, noLabel } = this.props;
    if (label || label === '') {
      return label;
    }
    if (noLabel) {
      return undefined;
    }
    if (!this.nameCache.get(name)) {
      let str = name;
      if (!str) {
        return '';
      }
      if (str.indexOf('_') > -1) {
        str = str.replace(LOW_DASH, ' ');
      }
      const regexp = LC_UC;
      if (str.match(regexp)) {
        str = str.replace(regexp, '$1 $2');
      }
      if (str.indexOf(' ') > -1) {
        const out = StringUtils.normalizeString(str);
        this.nameCache.set(name, out);
      } else {
        this.nameCache.set(
          name,
          str.charAt(0).toUpperCase() + str.substring(1)
        );
      }
    }
    return this.nameCache.get(name);
  }

  getId(): string {
    const { props, state } = this;
    const { formId, id, name } = props;
    const stateId = state.id;
    if (!this.id || this.id.length <= 0) {
      const formId1 = formId ? formId + '_' : '';
      this.id =
        formId1 +
        (stateId && stateId.length > 0
          ? stateId
          : id && id.length > 0
          ? id
          : name && name.length > 0
          ? name
          : window.performance.now().toString(36));
    }
    return this.id;
  }
}
