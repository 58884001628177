import UserSelectionService, {
  TUserType,
  TUserTypeEnum,
  TLabelValue,
  TUserSelection,
  TUserName,
} from 'services/project/UserSelectionService';
import appDispatcher from 'dispatcher/AppDispatcher';
import MathUtils from 'utils/MathUtils';
import { apiRequest } from 'services/RequestService';

export default class UserSelectionActions {
  static isLoadedWithoutFilter = false;

  static loadUserTypes(): Promise<Array<TUserType>> {
    UserSelectionActions.isLoadedWithoutFilter = true;
    return UserSelectionService.loadUserTypes().then(
      (response: Array<TUserType>) => {
        appDispatcher.dispatch({
          type: 'user-selection-load-user-types',
          data: response,
        });
        return response;
      }
    );
  }

  static loadUserGroups() {
    return UserSelectionService.loadUserGroups().then(
      (response: Array<TLabelValue>) => {
        appDispatcher.dispatch({
          type: 'user-selection-custom-user-groups',
          data: response,
        });
        return response;
      }
    );
  }

  static clearRecipients() {
    appDispatcher.dispatch({
      type: 'user-selection-load-recipients',
      data: [],
    });
  }

  static loadGroupMembers(groupId: number) {
    return UserSelectionService.loadGroupMembers(groupId).then(
      (response: Array<TLabelValue>) => {
        const data: Array<TUserName> = [];
        for (let i = 0; response && i < response.length; ++i) {
          data.push(
            Object.assign(new TUserName(), {
              refid: response[i].value,
              to_name: response[i].label,
              to_mail: response[i].value,
              type: '',
              facility: 'N/A',
            })
          );
        }
        appDispatcher.dispatch({
          type: 'user-selection-load-recipients',
          data,
        });
        return response;
      }
    );
  }

  static loadRecipients(
    type: TUserTypeEnum,
    currentUserType: TUserTypeEnum,
    userId: number,
    facilityId: number,
    facilityFlag: 'F' | string,
    id: number
  ) {
    return UserSelectionService.loadRecipients(
      type,
      currentUserType,
      userId,
      facilityId,
      facilityFlag,
      id
    ).then((response: Array<TUserName>) => {
      response.forEach(
        (v: TUserName) => (v.to_mail = MathUtils.parseInt('' + v.to_mail))
      );
      appDispatcher.dispatch({
        type: 'user-selection-load-recipients',
        data: response,
      });
    });
  }

  static loadUserList(
    isTech: boolean,
    userTypeName: TUserTypeEnum,
    facilityId: number,
    userTypeId: number,
    actionType: string
  ): Promise<Array<TLabelValue>> {
    const method = isTech
      ? UserSelectionService.loadUserListOfTech(userTypeName, userTypeId)
      : UserSelectionService.loadUserList(userTypeName, facilityId, userTypeId);
    return method.then((response: Array<TUserSelection>) => {
      const updatedRes: Array<TLabelValue> = response.map(
        (v: TUserSelection) => {
          const newItem: TLabelValue = new TLabelValue();
          newItem.value = parseInt('' + v.data);
          newItem.label = v.label;
          return newItem;
        }
      );
      appDispatcher.dispatch({
        type: actionType || 'user-selection-load-user-list',
        data: updatedRes,
      });
      return updatedRes;
    });
  }

  static clearUsersList() {
    appDispatcher.dispatch({
      type: 'user-selection-load-user-list',
      data: [],
    });
  }

  static getEmailTemplateNameWithUpdateDateTimeLabel(
    appId: number,
    userId: number
  ) {
    const data = {
      appId: appId,
      userId: userId,
    };

    return apiRequest<{
      appRefID: string;
      selected: string;
      template_name: string;
      template_code: string;
    }>({
      url: 'usermaster.UserInfo.getEmailTemplateNameWithUpdateDateTimeLabel',
      data,
    });
  }
}
