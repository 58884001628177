import { Dispatcher } from 'flux';
import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

export class PInvoicesStore extends CommonPayload {
  count: number = 0;
  isLoaded: boolean = false;
}

export class SInvoicesStore {
  perDiemData: any = {};
  invoiceDetails: Array<any> = [];
}

class InvoicesStore extends ReduceStore<SInvoicesStore, PInvoicesStore> {
  getInitialState() {
    return new SInvoicesStore();
  }

  areEqual(state1: SInvoicesStore, state2: SInvoicesStore) {
    return false;
  }

  reduce(state: SInvoicesStore, action: PInvoicesStore) {
    const storeState = this.getState();
    switch (action.type) {
      case 'facility.FacilityBilling.OrderNewPayList':
        storeState.invoiceDetails = action.data;
        break;
      case 'charge-posting-facility.FacilityBilling.Getfacilitydetail_preinvoice':
        storeState.perDiemData = action.data;
        break;
      default:
        break;
    }
    return storeState;
  }
}

export default new InvoicesStore(appDispatcher as Dispatcher<PInvoicesStore>);
