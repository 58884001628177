import { Container } from 'flux/utils';

import AbstractOrderOverview, {
  PAbstractOrderOverview,
  SAbstractOrderOverview,
  TFilter,
} from 'page/workflow/order/AbstractOrderOverview';
import OrderOverviewStore from 'stores/workflow/order/OrderOverviewStore';
import OrderOverviewActions from 'actions/workflow/order/OrderOverviewActions';
import { getDateString } from 'utils/DateUtils';
import UserProfileStore from 'stores/UserProfileStore';
import WorkflowOrderColumnsDescriptor from 'page/workflow/order/WorkflowOrderColumnsDescriptor';
import { storeGrid } from 'stores/_mobx/grid';

export interface PVisitSchedule extends PAbstractOrderOverview {}

export class SVisitSchedule extends SAbstractOrderOverview {
  orderFlags: any = {};
}

const GRID_ID = 'visit_schedule_orders_grid';

export class VisitSchedule extends AbstractOrderOverview<
  PVisitSchedule,
  SVisitSchedule
> {
  abortcontroller: AbortController | null = null;

  static TITLE = 'Visit Schedule';

  static getStores() {
    return [OrderOverviewStore];
  }

  static calculateState(prevState: SVisitSchedule) {
    if (!prevState) {
      const defaultFilter = {
        ...new TFilter(),
        period: 'TD',
        dosStart: getDateString(),
        dosEnd: getDateString(),
      };

      prevState = {
        ...AbstractOrderOverview.getDefaultState(GRID_ID),
        filter: storeGrid.getFilter(GRID_ID, defaultFilter) as TFilter,
        orderFlags: {},
      };
    }
    return {
      ...prevState,
      dataSource: OrderOverviewStore.getState().dataSource,
      dataSourceCount: OrderOverviewStore.getState().dataSourceCount,
    };
  }

  mapFlags() {
    const out = [];
    const flags = this.state.orderFlags;
    for (let flagName in flags) {
      if (flags.hasOwnProperty(flagName)) {
        const value = flags[flagName];
        if (value) {
          const split = flagName.split('_');
          const mapped = {
            selected: true,
            flag: split[0],
            sub: '',
          };
          if (split.length === 2) {
            mapped.sub = split[1];
          }
          out.push(mapped);
        }
      }
    }
    return out;
  }

  onReset() {
    const filter = {
      ...new TFilter(),
      period: 'TD',
      dosStart: getDateString(),
      dosEnd: getDateString(),
    };

    this.setState({ filter }, this.updateData);
  }

  updateData() {
    const controller = new AbortController();

    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = controller;

    this.setState({ onAjax: true });

    OrderOverviewActions.loadMedicalRecordGridData(
      this.prepareFilter(),
      this.state.pagination,
      this.mapFlags(),
      controller.signal
    ).then(this.updateDataCallback);
  }

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.abortController = null;

    this.setState({
      onAjax: false,
      selected: [],
    });

    if (grid) {
      // @ts-ignore
      grid.clearSelection();
    }
  };

  static grid_id = GRID_ID;

  getGridId() {
    return GRID_ID;
  }

  getTitle() {
    return VisitSchedule.TITLE;
  }

  prepareFilter() {
    const filter = super.prepareFilter();
    filter.recordsAccess = UserProfileStore.getRecordsAccess();
    return filter;
  }

  getShownColumns() {
    return Object.assign(
      [],
      WorkflowOrderColumnsDescriptor.VisitScheduleInitial
    );
  }

  getControlPanel = (): React.ReactNode => null;

  getAllowedColumns() {
    return Object.assign(
      [],
      WorkflowOrderColumnsDescriptor.VisitScheduleDefault
    );
  }

  renderOrderStatus(): React.ReactNode {
    return null;
  }
}

const container = Container.create(VisitSchedule);
export default container;
