import { makeObservable, action, observable } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import {
  TCptFacilityPayment,
  TCptBulkPayment,
  TPdDetail,
} from 'services/billing-coding/InvoicesService';

export interface InvoiceTransmittingPAyloadType {
  invoice_no: number;
  note: string;
  total_price: number;
  dataSource: {
    IsSelect: boolean;
    orderId: number;
  }[];
}

interface PaymentPayloadType {
  invoiceNumber: number;
  invoices: TCptBulkPayment[];
  notes: string;
  adjustment: number;
  modeOfPayment: number;
  perDiemData: TPdDetail[];
  mode: 'A' | 'E';
  facilityId: number;
  lateFee: { lateFee: number; lateFeePaid: number };
}

interface LinePostingInvoicePayloadType {
  invoiceId: number;
  invoices: TCptFacilityPayment[];
  notes: string;
  payMode: 1 | 2 | 3 | 4 | 5 | 6 | null;
  total: number;
  mode: 'E' | 'A';
  perDiemData: TPdDetail[];
  facilityId: number;
  invoiceType: string;
  lateFee: { lateFee: number; lateFeePaid: number };
}

class InvoiceChargePosting {
  fetchingPayment: boolean = false;

  constructor() {
    makeObservable(this, {
      fetchingPayment: observable,

      setFetchingPayment: action,
    });
  }

  setFetchingPayment(fetching: boolean) {
    this.fetchingPayment = fetching;
  }

  async addBulkPayment(payload: PaymentPayloadType) {
    const data = [
      payload.invoiceNumber,
      payload.invoices,
      payload.notes,
      0, // payload.adjustment
      payload.modeOfPayment,
      payload.perDiemData,
      'A', // payload.mode
      payload.facilityId,
      payload.lateFee,
    ];

    this.setFetchingPayment(true);

    try {
      const response = await apiRequest<'S' | 'E'>({
        url: 'facility.FacilityBilling.AddBulkPaymentByCpt',
        data,
      });

      if (response === 'S') {
        Notification.success('Invoice sent successfully!');
        return true;
      }
      throw Error('');
    } catch (error) {
      Notification.warning("Payment didn't send! Error occurred!");
      return false;
    } finally {
      this.setFetchingPayment(false);
    }
  }

  async addLinePayment(payload: LinePostingInvoicePayloadType) {
    this.setFetchingPayment(true);
    try {
      const data = {
        invoiceId: payload.invoiceId,
        cptArr: payload.invoices,
        notes: payload.notes,
        payMode: null as null,
        balance: payload.total,
        mode: payload.mode,
        pDdetails: payload.perDiemData,
        facilityId: payload.facilityId,
        invoiceType: payload.invoiceType,
        lateFee: payload.lateFee,
      };

      const response = await apiRequest<'S' | 'E'>({
        url: 'facility.FacilityBilling.AddFacilityPayment',
        data,
      });

      if (response === 'S') {
        Notification.success('Invoice sent successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.warning("Payment didn't send! Error occurred!");
      return false;
    } finally {
      this.setFetchingPayment(false);
    }
  }

  async reverseBulkPost(invoiceNo: number) {
    this.setFetchingPayment(true);
    try {
      const response = await apiRequest<'E' | 'S'>({
        url: 'facility.FacilityBilling.reverseBulkPost',
        data: { invoiceNo },
      });

      if (response === 'S') {
        Notification.success('Bulk post was reverted');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Unable to revert bulk post.');
      return false;
    } finally {
      this.setFetchingPayment(false);
    }
  }

  async moveInvoiceToAssignment(data: InvoiceTransmittingPAyloadType) {
    this.setFetchingPayment(true);

    try {
      const response = await apiRequest<'S' | 'E'>({
        url: 'facility.FacilityBilling.DeleteOrder',
        data,
      });

      if (response === 'S') {
        Notification.success(
          'Selected orders successfully moved to Billing Assignment'
        );
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.warning('Select any one Exam to proceed further!');
      return false;
    } finally {
      this.setFetchingPayment(false);
    }
  }
}

export const storeInvoiceChargePosting = new InvoiceChargePosting();
