import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const periodTypeValidation = yup
  .number()
  .typeError('Please, select an option first!');

const periodValidation = (fieldName: 'inactivePeriodType' | 'pwdPeriodType') =>
  yup
    .number()
    .positive('Period must be a positive number!')
    .when(fieldName, {
      is: (periodType: number) => periodType === 1,
      then: yup
        .number()
        .typeError('Period must be a positive number!')
        .test(
          'inactivePeriodValue',
          'Please, enter an integer number from 1 to 31',
          (periodValue: number) => periodValue > 0 && periodValue <= 31
        ),
      otherwise: yup.number().when(fieldName, {
        is: (periodType: number) => periodType === 2,
        then: yup
          .number()
          .typeError('Period must be a positive number!')
          .test(
            'inactivePeriodValue',
            'Please, enter an integer number from 1 to 12',
            (periodValue: number) => periodValue > 0 && periodValue <= 12
          ),
        otherwise: yup.number().when(fieldName, {
          is: (periodType: number) => periodType === 3,
          then: yup
            .number()
            .typeError('Period must be a positive number!')
            .test(
              'inactivePeriodValue',
              'Please, enter an integer number from 1 to 99',
              (periodValue: number) => periodValue > 0 && periodValue <= 99
            ),
          otherwise: yup
            .number()
            .typeError('Period must be a positive number!'),
        }),
      }),
    });

const validation = yupResolver(
  yup.object().shape({
    attemptNumber: yup
      .number()
      .typeError('Please, enter number of attempts!')
      .min(1, 'Min number of attempts is 1!')
      .max(99, 'Number of attempts should not exceed 99!')
      .required('Please, enter number of attempts!'),
    releaseDuration: yup
      .number()
      .typeError('Please, enter autorelease duration!')
      .min(1, 'Must be a positive number!'),
    inactivePeriodType: periodTypeValidation,
    inactivePeriodValue: periodValidation('inactivePeriodType'),
    pwdPeriodType: periodTypeValidation,
    pwdPeriodValue: periodValidation('pwdPeriodType'),
  })
);

export default validation;
