import { Control, UseFormWatch } from 'react-hook-form';

import { DropDownOrderTypeId } from 'components/project/dropdown/OrderType';
import { Select } from 'components/form/select';
import { Input } from 'components/form/textField';
import { Switcher } from 'components/form/switcher';
import Channels from './Channels';

import { IntegrationDetailsType } from 'stores/_mobx/systemSetup/masterSetting/integrations';
import { OPTIONS_TIMEZONE } from 'constant/formComponentOptions';
import { DropdownRadiology } from 'components/project/dropdown/RadiologyDropdown';

export const checkIsSomeEnabled = (
  settings: Record<string, boolean | number | string>
) =>
  Object.values(settings)
    .filter((el) => typeof el === 'boolean')
    .some(Boolean);

interface PropsType {
  control: Control<IntegrationDetailsType>;
  watch: UseFormWatch<IntegrationDetailsType>;
}

const Settings = ({ control, watch }: PropsType) => {
  const [ormSettings, oruSettings, adtSettings] = watch(['ormSettings', 'oruSettings', 'adtSettings']);
  const isOrmSettingsEnabled = checkIsSomeEnabled(ormSettings);
  const isOruSettingsEnabled = checkIsSomeEnabled(oruSettings);
  const isAdtSettingsEnabled = checkIsSomeEnabled(adtSettings);

  return (
    <div>
      <div className='row'>
        <Input
          name='name'
          label='Name'
          className='col-md-6 col-lg-4'
          required
          control={control}
        />
        <Input
          name='externalApplicationName'
          label='External Application Name'
          className='col-md-6 col-lg-4'
          required
          control={control}
        />
        <div />
        <Input
          name='shortName'
          label='Short Name'
          className='col-md-6 col-lg-4'
          required
          control={control}
        />
        <DropDownOrderTypeId
          name='orderType'
          label='Order Based'
          className='col-md-6 col-lg-4'
          required={isOrmSettingsEnabled}
          control={control}
        />
        <div />
        <Select
          name='timezone'
          label='Timezone'
          className='col-md-6 col-lg-4 mb-5'
          isClearable={false}
          options={OPTIONS_TIMEZONE}
          control={control}
        />
      </div>

      <legend>Outbound ORM</legend>
      <small className='mb-2 d-block text-secondary'>
        Select events to send ORM messages when
      </small>
      <Switcher
        name='ormSettings.sendOnVisitNotesCompleted'
        label='Visit Notes Complete'
        control={control}
      />
      <Switcher
        name='ormSettings.sendOnOrderCanceled'
        label='Order Canceled'
        control={control}
      />
      <Switcher
        name='ormSettings.sendOnPhysicianAssignment'
        label='Physician Assigned'
        control={control}
      />
      <Switcher
        name='ormSettings.sendOnTechnicianAssignment'
        label='Technician Assigned'
        className='mb-5'
        control={control}
      />
      <Channels
        name='ormChannels'
        isSettingsEnabled={isOrmSettingsEnabled}
        control={control}
        watch={watch}
      />
      <div className='mb-5' />

      <legend>Outbound ORU</legend>
      <small className='mb-2 d-block text-secondary'>
        Select events to send ORU messages when
      </small>
      <Switcher
        name='oruSettings.sendOnResultsInbound'
        label='Send ORU for Order Created VIA ORM'
        control={control}
      />
      <Switcher
        name='oruSettings.sendOnTranscriptionAdded'
        label='Transcription Added'
        control={control}
      />
      <DropdownRadiology
        name='oruSettings.associatedRadiologyGroup'
        label='Associated Radiology Group'
        className='col-md-6 col-lg-4 mb-5'
        control={control}
      />
      <small className='mb-2 d-block text-secondary'>
        Select how results are handled
      </small>
      <Switcher
        name='sendResultsAsPDF'
        label='Send as Base64 PDF'
        className='mb-5'
        control={control}
      />
      <Channels
        name='oruChannels'
        isSettingsEnabled={isOruSettingsEnabled}
        control={control}
        watch={watch}
      />
      <div className='mb-5' />

      <legend>Outbound ADT</legend>
      <small className='mb-2 d-block text-secondary'>
        Select events to send ADT messages when
      </small>
      <Switcher
        name='adtSettings.sendOnPatientCreated'
        label='Send on Patient Created'
        control={control}
      />
      <Switcher
        name='adtSettings.sendOnPatientUpdated'
        label='Send on Patient Updated'
        control={control}
      />
      <Channels
        name='adtChannels'
        isSettingsEnabled={isAdtSettingsEnabled}
        control={control}
        watch={watch}
      />
    </div>
  );
};

export default Settings;
