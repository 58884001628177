import { useState } from 'react';

import { Grid } from 'components/grid';
import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'components/modal/dialog';
import { DropdownRadiologyPureSelect } from 'components/project/dropdown/RadiologyDropdown';
import { Button } from 'components/button';
import useRequest from 'components/hook/useRequest';
import { getDefaultSelectedOrders, formatToGridData } from './helpers';

import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';
import { storeOrderTranscription } from 'stores/_mobx/workflow/order/transcription';

interface AddendumType {
  id: number;
  cptCode: string;
  cptDescription: string;
  modality: string;
  accession: string;
  radiologyGroupId: number;
}

interface PropsType {
  order: TSignOrderItem;
  onClose: () => void;
  onUpdate: () => void;
}

const DialogAddendum = ({ order, onClose, onUpdate }: PropsType) => {
  const { fetching, controlledAction: updateAddendumOptions } = useRequest(
    storeOrderTranscription.setAdditionalOptions
  );

  const [selected, setSelected] = useState<number[]>(
    getDefaultSelectedOrders(order.cptCodes)
  );

  const [dataSource, setDataSource] = useState<AddendumType[]>(() =>
    formatToGridData(order.cptCodes, order.examtype)
  );

  const handleChangeRadiology = (data: AddendumType) => (value: number) => {
    const addendumList: Array<AddendumType> = dataSource.map(
      (addendum: AddendumType) =>
        addendum.id === data.id
          ? {
              ...addendum,
              radiologyGroupId: Number(value),
            }
          : addendum
    );
    setDataSource(addendumList);
  };

  const COLUMNS = [
    {
      name: 'cptCode',
      title: 'CPT',
    },
    {
      name: 'cptDescription',
      title: 'CPT Description',
    },
    {
      name: 'modality',
      title: 'Modality',
    },
    {
      name: 'accession',
      title: 'Accession',
    },
    {
      name: 'radiologyGroupId',
      title: 'Radiology Group',
      className: 'width-250',
      render: (radiologyGroupId: number, data: AddendumType) => (
        <DropdownRadiologyPureSelect
          value={radiologyGroupId}
          onChange={handleChangeRadiology(data)}
        />
      ),
    },
  ];

  const handleClickApply = () => {
    const options = dataSource.map(({ radiologyGroupId, id }) => ({
      refid: id,
      flagNeedAddendum: selected.includes(id),
      needAddendumFromRadiologyGroupId: radiologyGroupId,
    }));

    const payload = {
      orderId: order.refid,
      options,
    };

    updateAddendumOptions(payload).then((isSucceed) => {
      if (isSucceed) onUpdate();
    });
  };

  const handleClose = () => {
    if (!fetching) {
      onClose();
    }
  };

  return (
    <Dialog size="large" scrollable={false} handleClose={handleClose}>
      <DialogHeader title="Addendum" onClose={handleClose} />
      <DialogBody className={fetching ? 'on-ajax' : ''}>
        <dl className="description row mb-4">
          <dt className="col-sm-3">Patient Name</dt>
          <dd className="col-sm-3">{order.patientnm}</dd>
          <dt className="col-sm-3">Date of Birth</dt>
          <dd className="col-sm-3">{order.patientdob}</dd>
          <dt className="col-sm-3 clear-left">Gender</dt>
          <dd className="col-sm-3">{order.gender}</dd>
          <dt className="col-sm-3">Date of Service</dt>
          <dd className="col-sm-3">{order.dos}</dd>
        </dl>
        <Grid
          id="addendum_popup_grid"
          selectId="id"
          disablePagination
          noControl
          columns={COLUMNS}
          dataSource={dataSource}
          selectedIds={selected}
          onSelectChange={setSelected}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          variant="warning"
          disabled={fetching || !selected.length}
          onClick={handleClickApply}>
          Needs Addendum
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default DialogAddendum;
