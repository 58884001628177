import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import EditPatientRecordPage from 'page/patient-records/patientDemographics/patient-record/EditPatientRecordPage';
import OrderViewComponent from 'page/workflow/order/visit-information/OrderViewComponent';
import ViewResultsTab from 'page/workflow/order/visit-information/ViewResultsTab';
import OrderDocuments from 'page/workflow/order/orderDocuments';
import Tabs from 'components/tabs';
import { SpinnerFixed } from 'components/spinner';
import { LayoutSideTitle } from 'components/layout';
import CptDetails from './components/CptDetails';

import CodingActions from 'actions/billing-coding/CodingActions';
import { storeOrderTranscription } from 'stores/_mobx/workflow/order/transcription';
import { URL_CODING } from 'constant/path/billingCoding';

const tabsList = [
  { label: 'CPT/Diagnosis Details', url: '?tab=diagnosis-details' },
  { label: 'Exam Result', url: '?tab=exam-result' },
  { label: 'Patient Demographic', url: '?tab=patient-demographics' },
  { label: 'Study Details', url: '?tab=order-details' },
  { label: 'Documents', url: '?tab=documents' },
];

interface MatchParams {
  id: string;
}

interface PCodingScreen extends RouteComponentProps<MatchParams> {}

interface SCodingScreen {
  order: any;
  fetching: boolean;
}

class CodingDetail extends React.Component<PCodingScreen, SCodingScreen> {
  constructor(props: PCodingScreen) {
    super(props);
    const { search } = props.location;

    const tabIndex = search
      ? tabsList.findIndex(({ url }) => url === search)
      : 0;

    this.changeTab(~tabIndex ? tabIndex : 0);

    this.state = {
      order: null,
      fetching: false,
    };
  }

  changeTab = (index: number) => {
    this.props.history.push({ search: tabsList[index].url });
  };

  renderTitle = () => {
    const { order } = this.state;

    const titlePatient = order
      ? [order?.patient_name, order?.facilitynm, order?.sheduleservdate]
          .filter((v) => v)
          .join(' - ')
      : '';

    return (
      <>
        Coding Details
        {titlePatient ? (
          <span className="fs-5">{` (${titlePatient})`}</span>
        ) : null}
      </>
    );
  };

  componentDidMount() {
    this.setState({ fetching: true }, () => {
      CodingActions.loadCodingValues(Number(this.props.match.params.id)).then(
        (order) => this.setState({ order, fetching: false })
      );
    });
  }

  componentWillUnmount() {
    storeOrderTranscription.clearFilter();
    storeOrderTranscription.clearTranscriptions();
  }

  render() {
    const { props, state } = this;

    const id = Number(props.match.params.id);

    return (
      <>
        <LayoutSideTitle title={this.renderTitle()}>
          <Link to={URL_CODING} className="btn btn-danger">
            Back
          </Link>
        </LayoutSideTitle>

        <div className="claim-management-coding pt-4">
          <Tabs tabsList={tabsList} onClick={this.changeTab} className="mb-4">
            <CptDetails
              orderId={id}
              order={state.order}
              history={props.history}
              fetching={state.fetching}
            />

            {state.order ? (
              <ViewResultsTab order={state.order} />
            ) : (
              <SpinnerFixed />
            )}

            {state.order ? (
              <EditPatientRecordPage
                // @ts-ignore
                match={{ params: { id: state.order.patientid } }}
                noTitle
              />
            ) : (
              <SpinnerFixed />
            )}

            {state.order ? (
              <OrderViewComponent order={state.order} />
            ) : (
              <SpinnerFixed />
            )}

            {state.order ? (
              <OrderDocuments orderId={id} patientId={state.order.patientid} />
            ) : (
              <SpinnerFixed />
            )}
          </Tabs>
        </div>
      </>
    );
  }
}

export default CodingDetail;
