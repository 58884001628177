import appDispatcher from 'dispatcher/AppDispatcher';

import TechExamCountPageService from 'services/reports/report-creator/tech-exam-count/TechExamCountPageService';
import { Actions } from 'stores/reports/report-creator/tech-exam-count/TechExamCountPageStore';

export default class TechExamCountPageActions {
  static loadTechExamCountList(conditionList, joint) {
    return TechExamCountPageService.loadTechExamCountList(
      conditionList,
      joint
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_TECH_EXAM_COUNT_LIST,
        data: response,
      });
    });
  }

  static createPdf(studiesByFacilityList, formatDescription) {
    return TechExamCountPageService.createPdf(
      studiesByFacilityList,
      formatDescription
    );
  }
}
