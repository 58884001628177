import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import ModifierForm from './components/Form';

import {
  storeModifiers,
  ModifierFormModel,
} from 'stores/_mobx/systemSetup/codeSet/modifiers';
import { CODES_SET_MODIFIERS } from 'constant/pagesId/systemSetup';
import { URL_MODIFIER } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const ModifiersEditPage = ({ match: { params }, history }: PropsType) => {
  const { fetching, modifier } = storeModifiers;

  const handleSubmit = async (data: ModifierFormModel) =>
    storeModifiers.updateModifier(data).then((error) => {
      if (error) return error;
      history.push(URL_MODIFIER);
    });

  useEffect(() => {
    if (!fetching) storeModifiers.getModifier(Number(params.id));

    return storeModifiers.clearModifier;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title appId={CODES_SET_MODIFIERS.EDIT} title="Edit Modifier" />
      <ModifierForm
        backUrl={URL_MODIFIER}
        defaultValues={modifier}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(ModifiersEditPage);
