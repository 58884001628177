import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import MathUtils from 'utils/MathUtils';
import AbstractStore from 'stores/AbstractStore';

interface OptionType {
  label: string;
  value: number;
}

type DEFAULT_AUTH = 0;
type MANUAL_AUTH = 1;

class PSendingApplicationsStore extends CommonPayload {}

class SSendingApplicationsStore {
  isAuthorized: boolean = false;
  isServiceAvailable: boolean = true;
  appSn: string = '';
  userNames: any = {};
  dropdown: OptionType[] = [];
  dataSource: Array<any> = [];
  dataSourceCount: number = 0;
  //used an integer to perform update on unsuccessful auth
  notAuthorised: DEFAULT_AUTH | MANUAL_AUTH = 0 as DEFAULT_AUTH;
  authError: boolean = false;
  orders: Array<any> = [];
  ordersCount: number = 0;
}

class SendingApplicationsStore extends AbstractStore<
  SSendingApplicationsStore,
  PSendingApplicationsStore
> {
  getInitialState() {
    return new SSendingApplicationsStore();
  }

  reduce(state: SSendingApplicationsStore, action: PSendingApplicationsStore) {
    switch (action.type) {
      case 'hl7-integration-authorization':
        this.getState().isAuthorized = action.data;
        this.getState().isServiceAvailable = action.data;
        break;
      case 'hl7-integration-not-authorized--error':
        this.getState().notAuthorised = 1 as MANUAL_AUTH;
        this.getState().authError = true;
        break;
      case 'hl7-integration-not-authorized--close':
        this.getState().notAuthorised = 0 as DEFAULT_AUTH;
        this.getState().authError = false;
        break;
      case 'hl7-integration-not-authorized':
        this.getState().notAuthorised++;
        this.getState().authError = false;
        break;
      case 'admin/ajax/sendingApplication/Overview--names':
        this.getState().userNames = action.data;
        break;
      case 'admin/ajax/sendingApplication/Overview':
        this.getState().dataSource = action.data.content;
        this.getState().dataSourceCount = action.data.total;
        this.getState().dropdown = [];
        break;
      case 'admin/orders/application/id':
        this.getState().orders = action.data.content;
        this.getState().ordersCount = action.data.total;
        break;
      case 'admin/sendingApplication/dropdown':
        this.getState().dropdown = action.data;
        break;
      case 'admin/sendingApplication/appSn':
        this.getState().appSn = action.data.app_sn;
        break;
      default:
        break;
    }
    return this.getState();
  }

  findOption(id: number) {
    id = MathUtils.parseInt('' + id);
    if (!id) {
      return null;
    }
    let res: OptionType = null;
    const options = this.getState().dropdown;
    let length = options.length;
    while (length--) {
      const option = options[length];
      if (option.value === id) {
        res = option;
        break;
      }
    }
    return res;
  }
}

export default new SendingApplicationsStore(appDispatcher);
