import { action, makeObservable, observable } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface BasicInfoType {
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  gender?: 'F' | 'M';
  status: string;
  name_prefix?: string;
}

export interface AddressType {
  city: string;
  address_1: string;
  address_2: string;
  fax_number?: string;
  telephone_number: string;
  postal_code: string;
  state: string;
}

export interface TaxonomyType {
  code: string;
  desc: string;
  state: string;
  license: string;
  primary: string;
}

export interface NpiType {
  basic: BasicInfoType;
  addresses: AddressType[];
  number: string;
  taxonomies: [TaxonomyType];
}

interface NpiResponse {
  result_count: number;
  results: [NpiType?];
}

class Npi {
  fetching: boolean = false;
  npiDetail?: NpiType | null = null;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      npiDetail: observable,

      setFetching: action,
      setNpi: action,
      clearNpi: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setNpi(npi?: NpiType | null) {
    this.npiDetail = npi;
  }

  clearNpi() {
    this.npiDetail = null;
  }

  async getNpi({ npi }: { npi: string }) {
    if (String(this.npiDetail?.number) === npi) {
      this.setNpi({ ...this.npiDetail });
      return Promise.resolve(this.npiDetail);
    }
    this.setFetching(true);

    try {
      const { results } = await apiRequest<NpiResponse>({
        url: 'api.Nppes.npiLookup',
        data: { npi },
      });

      this.setNpi(results[0]);
      return results[0];
    } catch (e: any) {
      return undefined;
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeNpi = new Npi();
