import { forwardRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';

import {
  storeDropdownFacility,
  OptionType,
} from 'stores/_mobx/dropDown/facility';

interface PropsType extends PAbstractDropdown {
  options: OptionType[];
  ref: any;
}

class FacilityOnlyActiveDropdown extends AbstractDropdown<
  PropsType,
  SAbstractDropdown
> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<FacilityOnlyActiveDropdown, any>(
  (props: PAbstractDropdown, ref) => {
    const { optionsActiveOnly, fetchingActiveOnly } = storeDropdownFacility;

    useEffect(() => {
      storeDropdownFacility.getOptionsActiveFacility();
    }, []);

    return (
      <FacilityOnlyActiveDropdown
        {...props}
        ref={ref}
        options={optionsActiveOnly}
        fetching={fetchingActiveOnly}
      />
    );
  }
);

export default observer(DropDownMiddleware);
