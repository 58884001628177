import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import QuestionForm from './components/Form';

import {
  storeSecurityQuestion,
  FormModel,
} from 'stores/_mobx/systemSetup/masterSetting/securityQuestion';
import { URL_SECURITY_QUESTION } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const SecurityQuestionAddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: FormModel) =>
    storeSecurityQuestion.addQuestion(payload).then((error) => {
      if (error) return error;

      history.push(URL_SECURITY_QUESTION);
    });

  return (
    <>
      <Title title="Add Security Question" />
      <QuestionForm backUrl={URL_SECURITY_QUESTION} onSubmit={handleSubmit} />
    </>
  );
};

export default SecurityQuestionAddPage;
