import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    type: yup.string().required('Please, enter facility type!'),
    description: yup.string().required('Please, enter a description!'),
    orderId: yup.mixed().test({
      test: (orderId) =>
        orderId ? Object.values(orderId).some((checked) => checked) : false,
      message: 'Please, select at least one option!',
    }),
  })
);

export default validation;
