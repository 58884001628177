import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validation = yupResolver(
  yup.object().shape({
    cptCode: yup.string().trim().required('Please specify code value'),
    cptPrice: yup
      .number()
      .typeError('Please specify code price')
      .min(0, 'Please specify code price'),
    cptDescription: yup
      .string()
      .trim()
      .required('Please specify description for code'),
  })
);

export default validation;
