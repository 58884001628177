import { ReduceStore } from 'flux/utils';
import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

export const Actions = {
  LOAD_USER_TYPE_LIST_ACTION: 'load-user-type-list',
};

export class UserTypeItem {
  refid: number;
  usertype_name: string;
  usertype: string;
  category: string;
  type: number;
  value: number;
  label: string;
}

class UserTypeState {
  userTypeList: Array<UserTypeItem> = [];
  cache: any = {};
}

class UserTypeStore extends ReduceStore<UserTypeState, CommonPayload> {
  getInitialState(): UserTypeState {
    return new UserTypeState();
  }

  areEqual(state1: UserTypeState, state2: UserTypeState) {
    return false;
  }

  reduce(state: UserTypeState, action: CommonPayload) {
    switch (action.type) {
      case Actions.LOAD_USER_TYPE_LIST_ACTION:
        this.getState().userTypeList = action.data;
        break;
      default:
        break;
    }
    return state;
  }

  isRadiologist(userTypeId: number) {
    const list = this.getState().userTypeList;
    return list.some(
      ({ value, usertype }) => userTypeId === value && usertype === 'L'
    );
  }

  findUserType(id: number) {
    const list = this.getState().userTypeList;
    for (let i = 0; i < list.length; i++) {
      if (list[i].value === id) {
        return list[i];
      }
    }
    return null;
  }

  isLoaded() {
    return this.getState().userTypeList.length > 0;
  }
}

const userTypeStore = new UserTypeStore(appDispatcher);
export default userTypeStore;
