import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import { AccessRoute } from 'components/HOC';
import * as IDS from 'constant/pagesId/workflow';
import * as PATH from 'constant/path/workflow';

import CallLogListPage from 'page/workflow/callLog/ListPage';
import CallLogAddPage from 'page/workflow/callLog/AddPage';
import CallLogEditPage from 'page/workflow/callLog/EditPage';
import FaxListPage from 'page/workflow/fax/FaxListPage';
import FaxAddPage from 'page/workflow/fax/FaxAddPage';
import RejectReasonReportPage from 'page/workflow/rejectReasonReport';
import EquipmentCheckOut from 'page/workflow/equipment/EquipmentCheckOut';
import CheckOutHistoryPage from 'page/workflow/checkOutHistory';
import EditEquipmentCheckOut from 'page/workflow/equipment/EditEquipmentCheckOut';
import AddEquipmentCheckOut from 'page/workflow/equipment/AddEquipmentCheckOut';
import { CheckOutTab, RequestServiceTab } from 'page/workflow/checkOutIn';
import CheckInPage from 'page/workflow/equipment/CheckInPage';
import ServiceHistoryPage from 'page/workflow/serviceMaintenance/serviceHistory';
import CalibrationRecordsPage from 'page/workflow/serviceMaintenance/calibration';
import TaskManagerPage from 'page/workflow/serviceMaintenance/taskManager/TaskManagerPage';
import EmailReportPage from 'page/workflow/serviceMaintenance/emailReport';
import OrderBase from 'page/workflow/order/OrderBase';
import NewOrderFormPage from 'page/workflow/order/NewOrderFormPage';

import ClockIn from 'page/workflow/clock-in/ClockIn';
import EquipmentInventoryPage from 'page/workflow/equipmentInventory';
import BoneSonometerListPage from 'page/workflow/equipmentInventory/boneSonometer/ListPage';
import BoneSonometerAddPage from 'page/workflow/equipmentInventory/boneSonometer/AddPage';
import BoneSonometerEditPage from 'page/workflow/equipmentInventory/boneSonometer/EditPage';
import ComputersListPage from 'page/workflow/equipmentInventory/computer/ListPage';
import ComputerAddPage from 'page/workflow/equipmentInventory/computer/AddPage';
import ComputerEditPage from 'page/workflow/equipmentInventory/computer/EditPage';
import DrPlateListPage from 'page/workflow/equipmentInventory/drPlate/ListPage';
import DrPlateAddPage from 'page/workflow/equipmentInventory/drPlate/AddPage';
import DrPlateEditPage from 'page/workflow/equipmentInventory/drPlate/EditPage';
import EkgListPage from 'page/workflow/equipmentInventory/ekg/ListPage';
import EkgAddPage from 'page/workflow/equipmentInventory/ekg/AddPage';
import EkgEditPage from 'page/workflow/equipmentInventory/ekg/EditPage';
import GeneratorListPage from 'page/workflow/equipmentInventory/generator/ListPage';
import GeneratorAddPage from 'page/workflow/equipmentInventory/generator/AddPage';
import GeneratorEditPage from 'page/workflow/equipmentInventory/generator/EditPage';
import GlovesListPage from 'page/workflow/equipmentInventory/glove/ListPage';
import GloveAddPage from 'page/workflow/equipmentInventory/glove/AddPage';
import GloveEditPage from 'page/workflow/equipmentInventory/glove/EditPage';
import UltrasoundListPage from 'page/workflow/equipmentInventory/ultrasound/ListPage';
import UltrasoundAddPage from 'page/workflow/equipmentInventory/ultrasound/AddPage';
import UltrasoundEditPage from 'page/workflow/equipmentInventory/ultrasound/EditPage';
import ThyroidShieldsListPage from 'page/workflow/equipmentInventory/thyroidShield/ListPage';
import ThyroidShieldAddPage from 'page/workflow/equipmentInventory/thyroidShield/AddPage';
import ThyroidShieldEditPage from 'page/workflow/equipmentInventory/thyroidShield/EditPage';
import OtherEquipmentListPage from 'page/workflow/equipmentInventory/otherEquipment/ListPage';
import OtherEquipmentAddPage from 'page/workflow/equipmentInventory/otherEquipment/AddPage';
import OtherEquipmentEditPage from 'page/workflow/equipmentInventory/otherEquipment/EditPage';
import ProbeListPage from 'page/workflow/equipmentInventory/probe/ListPage';
import ProbeAddPage from 'page/workflow/equipmentInventory/probe/AddPage';
import ProbeEditPage from 'page/workflow/equipmentInventory/probe/EditPage';
import VehicleOverviewPage from 'page/workflow/equipmentInventory/vehicle/ListPage';
import VehicleAddPage from 'page/workflow/equipmentInventory/vehicle/AddPage';
import VehicleEditPage from 'page/workflow/equipmentInventory/vehicle/EditPage';
import XRayFrameListPage from 'page/workflow/equipmentInventory/xRayFrame/ListPage';
import XRayFrameAddPage from 'page/workflow/equipmentInventory/xRayFrame/AddPage';
import XRayFrameEditPage from 'page/workflow/equipmentInventory/xRayFrame/EditPage';
import LeadListPage from 'page/workflow/equipmentInventory/lead/ListPage';
import LeadAddPage from 'page/workflow/equipmentInventory/lead/AddPage';
import LeadEditPage from 'page/workflow/equipmentInventory/lead/EditPage';
import PhoneListPage from 'page/workflow/equipmentInventory/phone/ListPage';
import PhoneAddPage from 'page/workflow/equipmentInventory/phone/AddPage';
import PhoneEditPage from 'page/workflow/equipmentInventory/phone/EditPage';
import UltrasoundUnitListPage from 'page/workflow/equipmentInventory/ultrasoundUnit/ListPage';
import UltrasoundUnitAddPage from 'page/workflow/equipmentInventory/ultrasoundUnit/AddPage';
import UltrasoundUnitEditPage from 'page/workflow/equipmentInventory/ultrasoundUnit/EditPage';
import XRayUnitsListPage from 'page/workflow/equipmentInventory/xRayUnit/ListPage';
import XRayUnitAddPage from 'page/workflow/equipmentInventory/xRayUnit/AddPage';
import XRayUnitEditPage from 'page/workflow/equipmentInventory/xRayUnit/EditPage';
import E404 from 'page/errors/404';

export const workflowRoutes = [
  '/orders',
  '/check-out-in',
  '/check-out-history',
  '/clock-in',
  '/service-and-maintenance',
];

const Workflow = () => (
  <Switch>
    <AccessRoute
      exact
      pageId={IDS.ORDER_MAIN.PAGE}
      path={PATH.URL_ORDER_ADD}
      component={NewOrderFormPage}
    />
    <AccessRoute
      pageId={IDS.ORDER_MAIN.PAGE}
      path={PATH.URL_ORDER_BASE}
      component={OrderBase}
    />
    <AccessRoute
      exact
      pageId={IDS.FAX.PAGE}
      path={PATH.URL_ORDER_FAX}
      component={FaxListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.FAX.PAGE}
      path={PATH.URL_ORDER_FAX_ADD}
      component={FaxAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CALL_LOG.PAGE}
      path={PATH.URL_CALL_LOG}
      component={CallLogListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CALL_LOG.PAGE}
      path={PATH.URL_CALL_LOG_ADD}
      component={CallLogAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CALL_LOG.PAGE}
      path={PATH.URL_CALL_LOG_EDIT}
      component={CallLogEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CHECK_OUT.PAGE}
      path={PATH.URL_EQUIPMENT_CHECKOUT_ADD}
      component={AddEquipmentCheckOut}
    />
    <AccessRoute
      exact
      pageId={IDS.CHECK_OUT.PAGE}
      path={PATH.URL_EQUIPMENT_CHECKOUT_EDIT}
      component={EditEquipmentCheckOut}
    />
    <AccessRoute
      pageId={IDS.CHECK_OUT.PAGE}
      path={PATH.URL_EQUIPMENT_CHECKOUT_IN}>
      {(props: RouteComponentProps) => (
        <EquipmentCheckOut {...props}>
          <Route
            exact
            path={PATH.URL_EQUIPMENT_CHECKOUT}
            component={CheckOutTab}
          />
          <Route
            exact
            path={PATH.URL_EQUIPMENT_CHECK_IN}
            component={CheckInPage}
          />
          <Route
            exact
            path={PATH.URL_EQUIPMENT_CHECKOUT_REQUEST_SERVICE}
            component={RequestServiceTab}
          />
        </EquipmentCheckOut>
      )}
    </AccessRoute>
    <AccessRoute
      exact
      pageId={IDS.CHECK_OUT_HISTORY.PAGE}
      path={PATH.URL_EQUIPMENT_CHECKOUT_HISTORY}
      component={CheckOutHistoryPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CLOCK_IN.PAGE}
      path={PATH.URL_CLOCK_IN}
      component={ClockIn}
    />
    <AccessRoute
      exact
      pageId={IDS.EI.PAGE}
      path={PATH.URL_EQUIPMENT_INVENTORY}
      component={EquipmentInventoryPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_BONE_SONOMETER.PAGE}
      path={PATH.URL_BONE_SONOMETER}
      component={BoneSonometerListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_BONE_SONOMETER.PAGE}
      path={PATH.URL_BONE_SONOMETER_ADD}
      component={BoneSonometerAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_BONE_SONOMETER.PAGE}
      path={PATH.URL_BONE_SONOMETER_EDIT}
      component={BoneSonometerEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_COMPUTER.PAGE}
      path={PATH.URL_COMPUTER}
      component={ComputersListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_COMPUTER.PAGE}
      path={PATH.URL_COMPUTER_ADD}
      component={ComputerAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_COMPUTER.PAGE}
      path={PATH.URL_COMPUTER_EDIT}
      component={ComputerEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_DR_PLATE.PAGE}
      path={PATH.URL_DR_PLATE}
      component={DrPlateListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_DR_PLATE.PAGE}
      path={PATH.URL_DR_PLATE_ADD}
      component={DrPlateAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_DR_PLATE.PAGE}
      path={PATH.URL_DR_PLATE_EDIT}
      component={DrPlateEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_EKG.PAGE}
      path={PATH.URL_EKG}
      component={EkgListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_EKG.PAGE}
      path={PATH.URL_EKG_ADD}
      component={EkgAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_EKG.PAGE}
      path={PATH.URL_EKG_EDIT}
      component={EkgEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_GENERATOR.PAGE}
      path={PATH.URL_GENERATOR}
      component={GeneratorListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_GENERATOR.PAGE}
      path={PATH.URL_GENERATOR_ADD}
      component={GeneratorAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_GENERATOR.PAGE}
      path={PATH.URL_GENERATOR_EDIT}
      component={GeneratorEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_GLOVE.PAGE}
      path={PATH.URL_GLOW}
      component={GlovesListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_GLOVE.PAGE}
      path={PATH.URL_GLOW_ADD}
      component={GloveAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_GLOVE.PAGE}
      path={PATH.URL_GLOW_EDIT}
      component={GloveEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_LEAD.PAGE}
      path={PATH.URL_LEAD}
      component={LeadListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_LEAD.PAGE}
      path={PATH.URL_LEAD_ADD}
      component={LeadAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_LEAD.PAGE}
      path={PATH.URL_LEAD_EDIT}
      component={LeadEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_OTHER.PAGE}
      path={PATH.URL_OTHER_EQUIPMENT}
      component={OtherEquipmentListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_OTHER.PAGE}
      path={PATH.URL_OTHER_EQUIPMENT_ADD}
      component={OtherEquipmentAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_OTHER.PAGE}
      path={PATH.URL_OTHER_EQUIPMENT_EDIT}
      component={OtherEquipmentEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_PHONES.PAGE}
      path={PATH.URL_PHONE}
      component={PhoneListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_PHONES.PAGE}
      path={PATH.URL_PHONE_ADD}
      component={PhoneAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_PHONES.PAGE}
      path={PATH.URL_PHONE_EDIT}
      component={PhoneEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_PROBE.PAGE}
      path={PATH.URL_PROBE}
      component={ProbeListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_PROBE.PAGE}
      path={PATH.URL_PROBE_ADD}
      component={ProbeAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_PROBE.PAGE}
      path={PATH.URL_PROBE_EDIT}
      component={ProbeEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_THYROID_SHIELD.PAGE}
      path={PATH.URL_THYROID_SHIELD}
      component={ThyroidShieldsListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_THYROID_SHIELD.PAGE}
      path={PATH.URL_THYROID_SHIELD_ADD}
      component={ThyroidShieldAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_THYROID_SHIELD.PAGE}
      path={PATH.URL_THYROID_SHIELD_EDIT}
      component={ThyroidShieldEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_ULTRASOUND.PAGE}
      path={PATH.URL_ULTRASOUND}
      component={UltrasoundListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_ULTRASOUND.PAGE}
      path={PATH.URL_ULTRASOUND_ADD}
      component={UltrasoundAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_ULTRASOUND.PAGE}
      path={PATH.URL_ULTRASOUND_EDIT}
      component={UltrasoundEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_VEHICLE.PAGE}
      path={PATH.URL_VEHICLE}
      component={VehicleOverviewPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_VEHICLE.PAGE}
      path={PATH.URL_VEHICLE_ADD}
      component={VehicleAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_VEHICLE.PAGE}
      path={PATH.URL_VEHICLE_EDIT}
      component={VehicleEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_XRAY_FRAME.PAGE}
      path={PATH.URL_XRAY}
      component={XRayFrameListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_XRAY_FRAME.PAGE}
      path={PATH.URL_XRAY_ADD}
      component={XRayFrameAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_XRAY_FRAME.PAGE}
      path={PATH.URL_XRAY_EDIT}
      component={XRayFrameEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_UNIT_ULTRASOUND.PAGE}
      path={PATH.URL_ULTRASOUND_UNIT}
      component={UltrasoundUnitListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_UNIT_ULTRASOUND.PAGE}
      path={PATH.URL_ULTRASOUND_UNIT_ADD}
      component={UltrasoundUnitAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_UNIT_ULTRASOUND.PAGE}
      path={PATH.URL_ULTRASOUND_UNIT_EDIT}
      component={UltrasoundUnitEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_UNIT_XRAY.PAGE}
      path={PATH.URL_XRAY_UNIT}
      component={XRayUnitsListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_UNIT_XRAY.PAGE}
      path={PATH.URL_XRAY_UNIT_ADD}
      component={XRayUnitAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EI_UNIT_XRAY.PAGE}
      path={PATH.URL_XRAY_UNIT_EDIT}
      component={XRayUnitEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.TASK_MANAGER.PAGE}
      path={PATH.URL_TASK_MANAGER}
      component={TaskManagerPage}
    />
    <AccessRoute
      exact
      pageId={IDS.SERVICE_HISTORY.PAGE}
      path={PATH.URL_SERVICE_HISTORY}
      component={ServiceHistoryPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EQUIPMENT_MAINTENANCE.PAGE}
      path={PATH.URL_EQUIPMENT_MAINTENANCE}
      component={CalibrationRecordsPage}
    />
    <AccessRoute
      exact
      pageId={IDS.REJECT_REASON.PAGE}
      path={PATH.URL_SERVICE_REJECT_REASON_REPORT}
      component={RejectReasonReportPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EMAIL_REPORT.PAGE}
      path={PATH.URL_EMAIL_REPORT}
      component={EmailReportPage}
    />
    <Route path="*" component={E404} />
  </Switch>
);

export default Workflow;
