import React from 'react';
import DatePicker from 'react-datepicker';
import { format, isValid } from 'date-fns';

import Validation from 'components/form/Validation';
import IconedInput from 'components/form/input/IconedInput';
import AbstractInput, {
  PAbstractInput,
  SAbstractInput,
} from 'components/form/input/AbstractInput';
import CustomInput from '../inputCalendar/CustomInputField';

const DEFAULT_FORMAT = 'm/d/y';
const DEFAULT_SEPARATOR = '/';

interface PTextCalendar extends PAbstractInput {
  format?: string;
  separator?: string;
  attr?: CalendarAttributes;
  preventOpenOnFocus?: boolean;
  showYearDropdown?: boolean;
  showMonthDropdown?: boolean;
  scrollableYearDropdown?: boolean;
  yearDropdownItemNumber?: number;
  dropdownMode?: string;
  maxDate?: Date | null;
}

class STextCalendar extends SAbstractInput {
  showDateInput: boolean = true;
  formatTemplate: string = '';
  formattedValue: string;
}

interface CalendarAttributes {
  id?: string;
  placeholder?: string;
  getCalendarContainer?: () => any;
  disabled?: boolean;
}

export default class TextCalendar extends AbstractInput<
  PTextCalendar,
  STextCalendar
> {
  static formatCache: { [key: string]: string } = {};
  datePickerRef: any;

  constructor(props: PTextCalendar) {
    super(props);
    const formatTemplate = this._buildFormatTemplate();
    this.datePickerRef = React.createRef();
    this.state = {
      ...new STextCalendar(),
      formatTemplate,
    };
  }

  validate(value: string): string[] {
    return Validation.validate(value, this.prepareValidations());
  }

  keyListener = (e: KeyboardEvent) => {
    if (e.key === 'Tab') this.datePickerRef.current.setOpen(false);
  };

  handleOpen = () => {
    document.addEventListener('keydown', this.keyListener);
  };

  handleClose = () => {
    document.removeEventListener('keydown', this.keyListener);
  };

  handleOpenCalendarManually = () => {
    this.datePickerRef.current.setOpen(true);
  };

  render() {
    const {
      state,
      props: {
        preventOpenOnFocus,
        showYearDropdown,
        showMonthDropdown,
        scrollableYearDropdown,
        yearDropdownItemNumber,
        dropdownMode,
        maxDate,
        ...props
      },
    } = this;
    const attr: any = props.attr
      ? { ...props.attr, noInput: true }
      : { noInput: true };
    const validations = this.prepareValidations();
    const placeholder = state.formatTemplate.toUpperCase();
    if (attr.placeholder === undefined) {
      attr.placeholder = placeholder;
    }
    const value: any = props.value || '';
    const formatStr = this.prepareMomentFormat();
    let calendarValue = new Date(value);
    if (!isValid(calendarValue)) {
      calendarValue = null;
    }

    const testAttr = props['data-testid'] || props.name;

    return (
      <IconedInput
        {...props}
        attr={attr}
        type="text"
        value={props.value || ''}
        icon={
          <DatePicker
            className="form-control"
            todayButton="Today"
            disabledKeyboardNavigation
            ref={this.datePickerRef}
            tabIndex={props.tabIndex}
            showYearDropdown={showYearDropdown}
            scrollableYearDropdown={scrollableYearDropdown}
            showMonthDropdown={showMonthDropdown}
            yearDropdownItemNumber={yearDropdownItemNumber}
            maxDate={maxDate}
            // @ts-ignore
            dropdownMode={dropdownMode}
            dateFormat={formatStr}
            disabled={props.disabled || attr?.disabled}
            preventOpenOnFocus={preventOpenOnFocus}
            selected={calendarValue}
            placeholderText={placeholder}
            onChange={this.setCalendarValue}
            onCalendarOpen={this.handleOpen}
            onCalendarClose={this.handleClose}
            customInput={
              <CustomInput
                data-testid={`${testAttr}-field`}
                preventOpenOnFocus={preventOpenOnFocus}
                setOpen={this.datePickerRef.current?.setOpen}
                onClick={this.handleOpenCalendarManually}
              />
            }
          />
        }
        disabled={attr.disabled}
        validations={validations}
        onSetValue={(name: string, value: string) => this.onStrValue(value)}
        className={`date-picker__field ${props.className || ''}`}
      />
    );
  }

  onStrValue(value: string): void {
    if (value) {
      value = this.fixStrValue(value);
    }
    this.setValue(value, null);
  }

  fixStrValue(value: string): string {
    const { props } = this;
    const propValue = props.value ? props.value.toString() : '';
    if (propValue.length > value.length) {
      return value;
    }
    const separator = props.separator || DEFAULT_SEPARATOR;
    const template = this.state.formatTemplate;
    const templateParts = template.split(separator);
    const valueParts = value.split(separator);
    let isValid = true;
    for (let i = 0; i < valueParts.length; i++) {
      if (!templateParts[i]) {
        isValid = false;
        break;
      } else if (valueParts[i].length !== templateParts[i].length) {
        isValid = false;
        break;
      }
    }
    if (isValid) {
      const length = value.length;
      if (template[length] === separator) {
        value += separator;
      }
    }
    return value;
  }

  prepareValidations(): string {
    let validations = this.props.validations;
    const format = this.props.format || DEFAULT_FORMAT;
    const separator = this.props.separator || DEFAULT_SEPARATOR;
    const rule = [format, separator];
    if (!validations) {
      validations = { dateString: rule };
    } else if (typeof validations === 'string') {
      if (validations.indexOf('dateString') === -1) {
        validations = validations + ' dateString:' + rule.join(':');
      }
    } else {
      if (!validations.dateString) {
        validations.dateString = rule;
      }
    }
    return validations;
  }

  setCalendarValue = (date: Date | null) => {
    const dateFormat = this.prepareMomentFormat();
    const formattedValue = date ? format(date, dateFormat) : '';
    this.setValue(formattedValue);
  };

  prepareMomentFormat = (): string => {
    const separator = this.props.separator || DEFAULT_SEPARATOR;
    const format = this.props.format || DEFAULT_FORMAT;
    const cacheName = format + separator;
    if (!TextCalendar.formatCache[cacheName]) {
      TextCalendar.formatCache[cacheName] = format
        .split(separator)
        .map((v) => {
          switch (v) {
            case 'm':
              return 'MM';
            case 'd':
              return 'dd';
            case 'y':
              return 'yyyy';
            default:
              return undefined;
          }
        })
        .filter((v) => !!v)
        .join(separator);
    }
    return TextCalendar.formatCache[cacheName];
  };

  _buildFormatTemplate: () => string = () => {
    const { format, separator } = this.props;
    const validSeparator = separator ? separator : DEFAULT_SEPARATOR;
    let formatTemplate: string;
    if (format) {
      const parts = format.split(validSeparator);
      for (let i = 0; i < parts.length; i++) {
        switch (parts[i]) {
          case 'd':
            parts[i] = 'dd';
            break;
          case 'm':
            parts[i] = 'MM';
            break;
          case 'y':
            parts[i] = 'yyyy';
            break;
        }
      }
      formatTemplate = parts.join(validSeparator);
    } else {
      formatTemplate = ['MM', 'dd', 'yyyy'].join(validSeparator);
    }
    return formatTemplate;
  };
}
