import React from 'react';

import Title from 'components/project/common/title';
import { Grid, GridControlButton } from 'components/grid';
import Exporter from 'components/project/Exporter';
import { Button } from 'components/button';
import MultipleConditionFilter from './filter/MultipleConditionFilter';
import { prettierFilterPayload } from './filter/prettierFilterPayload';

import UserProfileStore from 'stores/UserProfileStore';
import { storeGrid } from 'stores/_mobx/grid';
import CsvExporter from 'utils/CsvExporter';
import { getPagination } from 'utils/getPagination';
import { getTimeZoneName } from 'utils/DateUtils';

export default class AbstractReportPage extends React.Component {
  static LOGIC_PAGINATION = 'logic';

  static getPageState(gridId) {
    return {
      conditionList: [],
      showChart: false,
      onAjax: false,
      joint: null,
      pagination: getPagination(storeGrid.getPageSize(gridId)),
      hideChart: false,
      flagExporterDialog: false,
    };
  }

  constructor(props) {
    super(props);
    this.state = AbstractReportPage.getPageState();
  }

  getTitle() {
    return null;
  }

  getColumns() {
    return null;
  }

  getGridID() {
    return null;
  }
  getClass() {
    return null;
  }

  renderChart() {
    return null;
  }

  getShownFilters() {
    return null;
  }

  getExportedFileName() {
    return null;
  }

  loadListAction() {
    return null;
  }

  extraFilters() {
    return null;
  }

  exportCsv() {
    const csvExporter = new CsvExporter(
      this.getExportDataSource(),
      this.getColumns()
    );
    csvExporter.exportAndDownload(this.getExportedFileName());
  }

  getExportDataSource() {
    return this.state.dataSource;
  }

  exportPdf = () => {
    const dataSource = [...this.state.dataSource];
    return this.getActions().createPdf(
      dataSource,
      this.getDataArguments().toString()
    );
  };

  onSetConditionList(conditionList, joint) {
    const pagination = {
      ...this.state.pagination,
      skip: 0,
      page: 1,
    };
    this.setState(
      { pagination, conditionList, joint },
      this.updateData.bind(this)
    );
  }

  onPaginationChange = (pagination) => {
    this.setState({ pagination }, () => this.updateData());
  };

  render() {
    const { state } = this;

    return (
      <div className={this.getClass()}>
        <Title appId={this.state.appId} title={this.getTitle()} />

        {this.extraFilters()}

        <MultipleConditionFilter
          ref="filter"
          onSetValue={(conditionList, joint) =>
            this.onSetConditionList(conditionList, joint)
          }
          shownFilters={this.getShownFilters()}
          timezone={this.state.timezone}
          timezoneChange={
            this.allowTimezoneChange() ? (tz) => this.timezoneChange(tz) : null
          }
        />

        {!state.hideChart && (
          <div className="row">
            <div className="col-sm-8 col-md-9 col-lg-10">
              {state.showChart ? this.renderChart() : null}
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2 text-end">
              <Button
                variant="default"
                text={state.showChart ? 'Hide Chart' : 'Show Chart'}
                onClick={() =>
                  this.setState((state) => ({ showChart: !state.showChart }))
                }
              />
            </div>
          </div>
        )}

        <Grid
          {...this.getSpreadProps()}
          id={this.getGridID()}
          noDataText="Please refine your search using the filters above"
          gridControlPanel={this.getGridControlPanel()}
          columns={this.getColumns()}
          onAjax={state.onAjax}
          ref="grid"
          dataSourceCount={this.getDataSourceCount()}
          dataSource={this.applyPagination(this.getDataSource())}
        />
        {this.renderDialogs()}
      </div>
    );
  }

  applyPagination(ds) {
    if (this.state.paginationStrategy === AbstractReportPage.LOGIC_PAGINATION) {
      const { pagination } = this.state;
      const start = pagination.skip;
      const limit = pagination.pageSize + start;
      const out = [];
      for (let i = start; i < limit && i < ds.length; i++) {
        out.push(ds[i]);
      }
      return out;
    } else {
      return ds;
    }
  }

  timezoneChange(timezone) {
    this.setState({ timezone });
  }
  allowTimezoneChange() {
    return false;
  }

  renderDialogs() {
    const { state } = this;
    if (!state.flagExporterDialog) {
      return null;
    }
    let appId = this.state.appId;
    let appName = UserProfileStore.findAppNameByAppId(appId);
    let exportName = appName ? appName : null;
    return (
      <Exporter
        exporter={state.exporterName}
        filters={() => this.getDataArguments()}
        callback={() => this.setState({ flagExporterDialog: false })}
        this={this}
        logicPagination={
          this.state.paginationStrategy === AbstractReportPage.LOGIC_PAGINATION
        }
        reportName={exportName}
        pdfExporter={this.allowPdfExport() ? this.exportPdf : null}
      />
    );
  }
  handleClickExport = () => {
    this.setState({ flagExporterDialog: true });
  };

  getGridControlPanel() {
    return [
      <GridControlButton title="Export" onClick={this.handleClickExport} />,
    ];
  }

  allowPdfExport() {
    return true;
  }

  getActions() {
    throw new Error('Implement this function. Return type: Actions object');
  }

  getDataSourceCount() {
    return this.state.dataSource ? this.state.dataSource.length : 0;
  }

  getDataSource() {
    return this.state.dataSource;
  }

  getSpreadProps() {
    if (this.state.paginationStrategy === AbstractReportPage.LOGIC_PAGINATION) {
      return {
        onPaginationChange: (pagination) => this.onPaginationChange(pagination),
        pagination: this.state.pagination,
      };
    } else {
      return {
        disablePagination: true,
      };
    }
  }

  updateData() {
    this.setState({ onAjax: true }, () => {
      this.loadListAction(this.state.conditionList, this.state.joint).then(
        () => {
          this.setState({ onAjax: false, showPieChart: true });
        }
      );
    });
  }

  getDataArguments() {
    const conditionList = this.state.conditionList;
    const joint = this.state.joint;

    const out = [
      {
        conditionList: prettierFilterPayload(conditionList),
        joint: joint,
      },
    ];
    out.toString = () => {
      const out = [];
      conditionList.forEach((v) => {
        out.push(v.field + ' ' + v.condition + " '" + v.value + "'");
      });
      return `Timezone: ${getTimeZoneName()};\n`.concat(out.join(joint));
    };
    return out;
  }
}
