import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import { storeFacilityContact } from 'stores/_mobx/systemSetup/facilityContact';

const columns = [
  { name: 'action', title: 'Action' },
  { name: 'ts', title: 'Date' },
  { name: 'user', title: 'User' },
];

interface PropsType {
  userId: number;
  onClose: () => void;
}

const DialogAuditLog = ({ userId, onClose }: PropsType) => {
  const { fetchingAuditLog, auditLogList } = storeFacilityContact;

  useEffect(() => {
    storeFacilityContact.getAuditLogs(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Contact Audit Log" onClose={onClose} />
      <DialogBody>
        <Grid
          id="statusLogGrid"
          columns={columns}
          onAjax={fetchingAuditLog}
          disablePagination
          dataSource={auditLogList}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogAuditLog);
