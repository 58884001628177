import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { DropDownCorporation } from 'components/project/dropdown/CorporateDropdown';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import {
  storeFeeScheduleCorporate,
  filterDefaultValue,
  FilterType,
} from 'stores/_mobx/systemSetup/feeSchedule/corporate';

const RADIO_BUTTON_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Archive', value: 'archived' },
];

interface PropsType {
  isVisible: boolean;
  fetching: boolean;
  onChange: (data: FilterType) => void;
}

const Filter = ({ isVisible, fetching, onChange }: PropsType) => {
  const { control, reset, watch } = useForm<FilterType>({
    defaultValues: storeFeeScheduleCorporate.filter,
  });

  const handleClickReset = () => {
    reset(filterDefaultValue);
  };

  useEffect(() => {
    const subscription = watch((formValue) => {
      onChange(formValue as Required<FilterType>);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className={`row${isVisible ? '' : ' d-none'}`}>
      <DropDownCorporation
        name="corporationId"
        label="Corporation"
        className="col-md-6 col-lg-4 col-xl-3"
        control={control}
      />
      <Input
        name="description"
        label="Description"
        className="col-md-6 col-lg-4 col-xl-3"
        control={control}
      />
      <Radio
        name="status"
        label="Schedule status"
        className="inline col-md-6 col-lg-auto"
        options={RADIO_BUTTON_OPTIONS}
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
