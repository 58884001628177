import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import { dateToLocalTimezone } from 'utils/DateUtils';

export class SBriefcasePageStore {
  directories: any = {};
  files: any = [];
  users: Array<any> = [];
  sharingUsers: Array<any> = [];
}

class BriefcasePageStore extends ReduceStore<
  SBriefcasePageStore,
  CommonPayload
> {
  getInitialState() {
    return new SBriefcasePageStore();
  }

  areEqual(state1: SBriefcasePageStore, state2: SBriefcasePageStore) {
    return false;
  }

  reduce(state: SBriefcasePageStore, action: CommonPayload) {
    switch (action.type) {
      case 'briefcase-load-directories': {
        const { own, share } = action.data as any;
        const directories = {
          own: {
            ...own,
            children: Array.isArray(own?.children)
              ? own.children.map((el: any) => ({
                  ...el,
                  createdDate: dateToLocalTimezone({
                    date: el.createdDate,
                  }),
                  sharedDate: dateToLocalTimezone({
                    date: el.sharedDate,
                  }),
                }))
              : own?.children,
          },
          share: {
            ...share,
            children: Array.isArray(share?.children)
              ? share.children.map((el: any) => ({
                  ...el,
                  createdDate: dateToLocalTimezone({
                    date: el.createdDate,
                  }),
                  sharedDate: dateToLocalTimezone({
                    date: el.sharedDate,
                  }),
                }))
              : share?.children,
          },
        };
        this.getState().directories = directories;
        break;
      }
      case 'briefcase-load-files':
        this.getState().files[(action as any).directoryId] = action.data;
        break;
      case 'briefcase-sharing-users':
        this.getState().sharingUsers = action.data;
        break;
      default:
        break;
    }
    return state;
  }
}

const briefcasePageStore = new BriefcasePageStore(appDispatcher);
export default briefcasePageStore;
