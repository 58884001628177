import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface AuditType {
  message: string;
  user: string;
  utc: string;
}

class AuditLog {
  fetching: boolean = false;
  logs: AuditType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      logs: observable,

      setLogs: action,
      setFetching: action,
    });
  }

  setLogs(logs: AuditType[]) {
    this.logs = logs;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  async getAuditLogs(id: number, withPayment: boolean) {
    if (this.fetching) return Promise.resolve();

    this.setFetching(true);

    try {
      const response = await apiRequest<AuditType[]>({
        url: 'log.InvoiceAuditLog.getLogForInvoice',
        data: [id, withPayment],
      });

      const logs = response.map((log) => ({
        ...log,
        utc: dateToLocalTimezone({ date: log.utc }),
      }));

      this.setLogs(logs);
    } catch (e: any) {
      this.setLogs([]);
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeAuditLog = new AuditLog();
