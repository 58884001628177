class MathUtils {
  parseFloat = (v: string | number, dots?: number) => {
    const n = window.parseFloat('' + v);
    if (window.isNaN(n)) {
      return 0;
    }
    if (dots) {
      const base = Math.pow(10, dots);
      return Math.round(n * base) / base;
    }
    return n;
  };

  parseInt = (v: string) => {
    const out = window.parseInt(v);
    return window.isNaN(out) ? 0 : out;
  };

  compare = (v1: string | number, v2: string | number, direction: number) => {
    if (typeof v1 !== 'number') {
      v1 = this.parseFloat(v1);
    }
    if (typeof v2 !== 'number') {
      v2 = this.parseFloat(v2);
    }
    const out = v1 - v2;
    if (!direction || direction > 0) {
      return out;
    }
    if (out === 0) {
      return 0;
    }
    return -out;
  };

  random = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min) + min);
  };
}

const mathUtils = new MathUtils();
export default mathUtils;
