import { makeObservable, observable, action, runInAction } from 'mobx';

export interface FilterType {
  state: 'A' | 'I';
  name: string;
  isActive: boolean;
}

class FilterNameAndState {
  options: FilterType = {
    state: 'A',
    name: '',
    isActive: true,
  };

  constructor() {
    makeObservable(this, {
      options: observable,

      setName: action.bound,
      setOptions: action,
    });
  }

  setName(name: string) {
    this.options.name = name;
  }

  setStatus = (state: 'A' | 'I') => {
    runInAction(() => {
      this.options.state = state;
      this.options.isActive = state === 'A';
    });
  };

  setOptions = (options: FilterType) => {
    this.options = options;
  };
}

export default FilterNameAndState;
