import { Control, UseFormWatch, useFieldArray } from 'react-hook-form';

import { IconButton } from 'components/button';
import { Select } from 'components/form/select';
import { filterOption } from './helpers';

import {
  defaultMappingValue,
  FacilitySettingsType,
} from 'stores/_mobx/systemSetup/facility';

interface OptionType {
  value: number;
  label: string;
}

interface PropsType {
  fetchingModalityOptions: boolean;
  fetchingRadiologyOptions: boolean;
  optionsModality: OptionType[];
  optionsRadiologyGroup: OptionType[];
  control: Control<FacilitySettingsType, any>;
  watch: UseFormWatch<FacilitySettingsType>;
}

const RadiologyGroupToModalityMappingField = ({
  fetchingModalityOptions,
  fetchingRadiologyOptions,
  optionsModality,
  optionsRadiologyGroup,
  control,
  watch,
}: PropsType) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'radiologyGroupToModalityMapping',
  });

  const currentMapping = watch('radiologyGroupToModalityMapping');

  const isAddMappingButtonDisabled =
    currentMapping.length >= optionsModality.length ||
    currentMapping.some(({ examType }) => !examType);

  const handleClickAddMapping = () => {
    append(defaultMappingValue);
  };

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id} className="row">
          <div className="col-md-10 col-lg-8 col-xl-6">
            <div className="d-flex justify-between">
              <Select
                name={`radiologyGroupToModalityMapping.${index}.examType`}
                className="w-100"
                options={filterOption({
                  options: optionsModality,
                  currentMapping,
                  index,
                })}
                isLoading={fetchingModalityOptions}
                control={control}
              />
              <i className="bi bi-arrow-left mx-3 mt-2" />
              <Select
                name={`radiologyGroupToModalityMapping.${index}.radiologyGroup`}
                className="w-100"
                isLoading={fetchingRadiologyOptions}
                options={optionsRadiologyGroup}
                control={control}
              />
            </div>
          </div>
          <div className="text-primary col-sm-auto col-lg-1 mt-2">
            <div className="d-flex gap-3 justify-content-end">
              <IconButton
                data-testid={`radiologyGroupToModalityMapping[${index}].add`}
                disabled={isAddMappingButtonDisabled}
                onClick={handleClickAddMapping}>
                <i className="bi bi-plus-circle" />
              </IconButton>
              <IconButton
                className="text-danger"
                data-testid={`radiologyGroupToModalityMapping[${index}].delete`}
                disabled={fields.length <= 1}
                onClick={() => remove(index)}>
                <i className="bi bi-trash" />
              </IconButton>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default RadiologyGroupToModalityMappingField;
