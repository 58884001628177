import React from 'react';
import { Container } from 'flux/utils';

import { getDateString } from 'utils/DateUtils';
import SignOrdersPageStore from 'stores/clinician-manager/sign-orders/SignOrdersPageStore';
import AbstractSignOrdersPage, {
  PAbstractSignOrdersPage,
  SAbstractSignOrdersPage,
  TAbstractSignOrdersPageFilter,
} from 'page/clinician-manager/sign-orders/AbstractSignOrdersPage';
import { URL_SIGNED_ORDER_GROUP_TAB } from 'constant/path/clinicalManager';
import { storeGrid } from 'stores/_mobx/grid';

const GRID_ID = 'group_orders_grid';

const defaultFilter = {
  ...new TAbstractSignOrdersPageFilter(),
  period: 'TD',
  fromDate: getDateString(),
  toDate: getDateString(),
};

export class GroupOrdersTab extends AbstractSignOrdersPage<
  PAbstractSignOrdersPage,
  SAbstractSignOrdersPage
> {
  static TITLE = 'Group Orders';

  static calculateState(prevState: SAbstractSignOrdersPage) {
    if (!prevState) {
      prevState = {
        ...AbstractSignOrdersPage.getDefaultState(GRID_ID),
        filter: storeGrid.getFilter(GRID_ID, defaultFilter),
      };
    }
    return {
      ...prevState,
      dataSource: SignOrdersPageStore.getState().signOrdersList,
      dataSourceCount: SignOrdersPageStore.getState().signOrdersCount,
    };
  }

  getDefaultFilter = () => defaultFilter;

  prepareFilter() {
    const filter = super.prepareFilter();
    filter.signed = 'G';
    return filter;
  }

  getAllowedColumns() {
    return [
      'revert',
      'dos',
      'dostime',
      'last_name',
      'first_name',
      'room',
      'facility',
      'technologistnm',
      'physicianname',
      'visitInfo',
      'images',
      'viewImage',
      'cancel',
      'ordercreated_dt',
      'vw_patientid',
      'clientMRN',
      'gender',
      'patientdob',
      'ssn',
      'pos',
      'eligibility_status',
      'exam_request',
      'priority',
      'cpt',
      'diagnosis',
    ];
  }

  goToOverview() {
    this.props.history.push(URL_SIGNED_ORDER_GROUP_TAB);
  }

  getGridId() {
    return GRID_ID;
  }

  getTitle() {
    return GroupOrdersTab.TITLE;
  }

  getControlPanel = (): React.ReactNode => null;

  renderOrderStatus(): React.ReactNode {
    return null;
  }

  getShownColumns() {
    return [
      'dos',
      'dostime',
      'last_name',
      'first_name',
      'room',
      'facility',
      'technologistnm',
      'physicianname',
      'visit_info',
      'exam_request',
    ];
  }
}

const groupOrdersTab = Container.create(GroupOrdersTab);
export default groupOrdersTab;
