import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import Notification from 'components/modal/Notification';
import { Button } from 'components/button';
import { InputCalendar, InputTime } from 'components/form/inputCalendar';
import Badge from 'components/badge';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import resolver from './validation';

import OrderFormPageActions from 'actions/workflow/order/OrderFormPageActions';
import { getTimeZoneName } from 'utils/DateUtils';

const initialState: FormModel = {
  date: '',
  timeStart: '',
  timeEnd: '',
  refid: 0,
};

export interface FormModel {
  date: string;
  timeStart: string;
  timeEnd: string;
  refid: number;
}

interface PropsType {
  defaultValues?: FormModel;
  onClose: () => void;
  onSubmit: () => void;
}

export const DialogScheduledServiceDate = ({
  defaultValues = initialState,
  onClose,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    watch,
  } = useForm({ defaultValues, resolver });

  const [timeStart, timeEnd] = watch(['timeStart', 'timeEnd']);

  const timeZoneName = useMemo(getTimeZoneName, []);

  const isNextDay = timeStart && timeEnd && timeStart >= timeEnd;

  const submit = (payload: FormModel) => {
    return OrderFormPageActions.updateScheduleServiceDate(payload).then(
      (response) => {
        if (response === 'S') {
          Notification.success('Successfully updated!');
          onSubmit();
        } else {
          Notification.danger('Error occurred!');
        }
      }
    );
  };

  const handleClickSubmit = () => {
    handleSubmit(submit)();
  };

  return (
    <Dialog scrollable={false} handleClose={onClose}>
      <DialogHeader title="Scheduled Service Date" onClose={onClose} />
      <DialogBody>
        <form className="row">
          <InputCalendar
            name="date"
            label="Date"
            placeholder="MM/DD/YYYY"
            className="col"
            required
            control={control}
          />
          <InputTime
            name="timeStart"
            className="col"
            label="Start"
            control={control}
          />
          <div className="col position-relative">
            <InputTime name="timeEnd" label="End" control={control} />
            {isNextDay && (
              <Badge horizontal="middle" variant="danger" position="absolute">
                Next Day
              </Badge>
            )}
          </div>
        </form>
        <p>Timezone: {timeZoneName}</p>
      </DialogBody>
      <DialogFooter>
        <Button
          text="Update"
          disabled={!isDirty || isSubmitting}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};
