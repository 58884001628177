import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    region: yup.string().trim().required('Please, enter the region name!'),
  })
);

export default validation;
