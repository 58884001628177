import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';

import Notification from 'components/modal/Notification';
import Title from 'components/project/common/title';
import { Input } from 'components/form/textField';
import { Select } from 'components/form/select';
import { Button } from 'components/button';
import validation from './validation';

import UserProfileActions from 'actions/UserProfileActions';
import UserProfileStore from 'stores/UserProfileStore';
import { storePasswordStrength } from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';
import { storeSecurityQuestion } from 'stores/_mobx/systemSetup/masterSetting/securityQuestion';
import { URL_LOGOUT } from 'constant/path/auth';

interface FormValue {
  oldPassword: string;
  password: string;
  repeatPassword: string;
  secQues1?: number;
  secQues2?: number;
  answer1: string;
  answer2: string;
}

const defaultValues: FormValue = {
  oldPassword: '',
  password: '',
  repeatPassword: '',
  secQues1: undefined,
  secQues2: undefined,
  answer1: '',
  answer2: '',
};

const ChangePasswordPage = ({ history }: RouteComponentProps) => {
  const { fetching, passwordOptions } = storePasswordStrength;

  const { fetchingOptions, options } = storeSecurityQuestion;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValue>({
    defaultValues,
    resolver: yupResolver(validation(passwordOptions)),
  });

  const isFetching = fetching || fetchingOptions;

  const handleCancel = () => {
    history.replace(URL_LOGOUT);
  };

  const onSubmit = (model: FormValue) => {
    const userId = UserProfileStore.getUserId();
    const login = UserProfileStore.getLogin();

    if (model.secQues1 === model.secQues2) {
      Notification.warning('The security questions must be different!');
      return Promise.resolve();
    }
    return UserProfileActions.loginChangePassword(
      userId,
      login,
      model.oldPassword,
      model.password,
      model.secQues1,
      model.answer1,
      model.secQues2,
      model.answer2
    ).then((response) => {
      if (response === 'S') {
        Notification.success('The password has been successfully changed!');
        history.push(URL_LOGOUT);
      } else if (response === 'P') {
        Notification.warning(
          'The password should be different from three last passwords!'
        );
      } else if (response === 'E') {
        Notification.warning('Incorrect old password');
      } else {
        Notification.danger('The password is invalid!');
      }
    });
  };

  useEffect(() => {
    storeSecurityQuestion.getQuestionOption();
    storePasswordStrength.getPasswordSettings();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <Title title="Change Password" />
        </div>
        <form
          className={`row${isFetching ? ' on-ajax' : ''}`}
          onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="oldPassword"
            type="password"
            label="Old Password"
            required
            control={control}
          />
          <Input
            name="password"
            type="password"
            label="New Password"
            required
            control={control}
          />
          <Input
            name="repeatPassword"
            type="password"
            label="Confirm Password"
            required
            control={control}
          />
          <Select
            name="secQues1"
            label="Security Question 1"
            required
            control={control}
            options={options}
          />
          <Input name="answer1" label="Answer 1" required control={control} />
          <Select
            name="secQues2"
            label="Security Question 2"
            required
            control={control}
            options={options}
          />
          <Input name="answer2" label="Answer 2" required control={control} />
          <div className="control right">
            <Button
              variant="danger"
              text="Cancel"
              disabled={isSubmitting}
              onClick={handleCancel}
            />
            <Button type="submit" text="Submit" disabled={isSubmitting} />
          </div>
        </form>
      </div>
    </>
  );
};

export default observer(ChangePasswordPage);
