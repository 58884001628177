import React from 'react';
import { startOfToday, startOfYear } from 'date-fns';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { Grid } from 'components/grid';
import Select from 'components/form/input/Select';
import Text from 'components/form/input/Text';
import Radio from 'components/form/input/Radio';
import TextCalendar from 'components/form/input/TextCalendar';
import { getDateString } from 'utils/DateUtils';

const DUE_PERIOD_OPTIONS = [
  { label: '30', value: '30' },
  { label: '60', value: '60' },
  { label: '90', value: '90' },
  { label: '120', value: '120' },
  { label: 'YTD', value: 'ytd' },
];

const OVERDUE_AMOUNT_TYPES = {
  ALL_DATES: 'AD',
  DATE_RANGE: 'DR',
  DUE_PERIOD: 'DP',
  NONE: 'N',
};

const options = [
  {
    label: 'All Dates',
    value: OVERDUE_AMOUNT_TYPES.ALL_DATES,
  },
  {
    label: 'Date Range',
    value: OVERDUE_AMOUNT_TYPES.DATE_RANGE,
  },
  {
    label: 'Due Period',
    value: OVERDUE_AMOUNT_TYPES.DUE_PERIOD,
  },
  {
    label: 'None',
    value: OVERDUE_AMOUNT_TYPES.NONE,
  },
];

export interface PFacilityOverdueAmountsModal {
  dataSource: Array<any>;
  callback: (
    value: boolean,
    mappedDataSource?: Array<TFacilityOverdueAmountsItem>
  ) => void;
}

export class TFacilityOverdueAmountsItem {
  dateFrom: string = '';
  dateTo: string = '';
  type: string = OVERDUE_AMOUNT_TYPES.ALL_DATES;
  selectionPeriod: string = '';
  index: number = null;
  errors: any = {};
  facilityid: number = null;
  facilitynm: string = '';
  lateFee: string = '';
}

export class SFacilityOverdueAmountsModal {
  onAjax: boolean = true;
  globalRange: any = [];
  dataSource: Array<TFacilityOverdueAmountsItem> = [];
}

export default class FacilityOverdueAmountsModal extends React.Component<
  PFacilityOverdueAmountsModal,
  SFacilityOverdueAmountsModal
> {
  inputReferences: Array<any> = [];

  constructor(props: PFacilityOverdueAmountsModal) {
    super(props);
    const state = new SFacilityOverdueAmountsModal();
    const { dataSource } = this.props;
    if (dataSource) {
      state.dataSource = (dataSource || []).map((item, index) =>
        Object.assign(new TFacilityOverdueAmountsItem(), {
          dateFrom: '',
          dateTo: '',
          type: OVERDUE_AMOUNT_TYPES.ALL_DATES,
          selectionPeriod: '',
          index,
          errors: {},
          facilityid: item.facilityid,
          facilitynm: item.facilitynm,
        })
      );
    } else {
      state.dataSource = [];
    }
    this.inputReferences = [];
    this.state = state;
  }

  componentDidMount() {
    this.updateData();
  }

  updateData() {
    this.setState({ onAjax: false });
  }

  selectionRender(data: TFacilityOverdueAmountsItem) {
    const { facilityid } = data;
    switch (data.type) {
      case OVERDUE_AMOUNT_TYPES.DATE_RANGE:
        return (
          <div className="d-flex gap-3">
            <TextCalendar
              value={data.dateFrom}
              ref={(v) => this.createReference(v, data, 'dateFrom')}
              onSetValue={(n, d, e) =>
                this.setSelectionRangeValue(facilityid, 'dateFrom', d, e)
              }
              name={`dateFrom_${facilityid}`}
              className="width-150"
              validations="required"
              errors={data.errors.dateFrom}
              noLabel
            />
            <TextCalendar
              value={data.dateTo}
              ref={(r) => this.createReference(r, data, 'dateTo')}
              onSetValue={(n, d, e) =>
                this.setSelectionRangeValue(facilityid, 'dateTo', d, e)
              }
              name={`dateTo_${+facilityid}`}
              className="width-150"
              validations="required"
              errors={data.errors.dateTo}
              noLabel
            />
          </div>
        );
      case OVERDUE_AMOUNT_TYPES.DUE_PERIOD:
        return (
          <Radio
            key={data.selectionPeriod}
            name={`selectionPeriod_${facilityid}`}
            ref={(v) => this.createReference(v, data, 'selectionPeriod')}
            noLabel
            className="part-inline"
            validations="required"
            errors={data.errors.selectionPeriod}
            options={DUE_PERIOD_OPTIONS}
            value={data.selectionPeriod}
            onSetValue={(n, d, e) =>
              this.setSelectionPeriodValue(facilityid, d, e)
            }
          />
        );
      case OVERDUE_AMOUNT_TYPES.NONE:
      case OVERDUE_AMOUNT_TYPES.ALL_DATES:
      default:
        return null;
    }
  }

  createReference(
    reference: any,
    dataRow: TFacilityOverdueAmountsItem,
    name: string
  ) {
    const { index } = dataRow;
    if (!this.inputReferences[index]) {
      this.inputReferences[index] = {};
    }
    this.inputReferences[index][name] = reference;
  }

  setSelectionRangeValue(id: number, name: string, value: any, errors: any) {
    this.assignValue(id, (item: any) => {
      item[name] = value;
      item.errors[name] = errors;
      return item;
    });
  }

  setSelectionPeriodValue(id: number, value: string, errors: any) {
    this.assignValue(id, (item) => {
      item.selectionPeriod = value;
      item.errors.selectionPeriod = errors;
      return item;
    });
  }

  setTypeValue(id: number, value: string, errors: any) {
    this.assignValue(id, (item) => {
      item.type = value;
      item.errors.type = errors;
      return item;
    });
  }

  setLateFee(id: number, value: string) {
    this.assignValue(id, (item: TFacilityOverdueAmountsItem) => {
      item.lateFee = value;
      return item;
    });
  }

  assignValue(
    id: number,
    mapper: (value: TFacilityOverdueAmountsItem) => TFacilityOverdueAmountsItem
  ) {
    const dataSource = this.state.dataSource.map((item) => {
      if (item.facilityid === id) {
        return mapper(item);
      }
      return item;
    });
    this.setState({ dataSource });
  }

  typeRender(data: TFacilityOverdueAmountsItem) {
    return (
      <Select
        noLabel
        className="type-select"
        ref={(r) => this.createReference(r, data, 'type')}
        name={`type_${data.facilityid}`}
        value={data.type}
        validations="required:lazy"
        errors={data.errors.type}
        onSetValue={(n, d, e) => this.setTypeValue(data.facilityid, d, e)}
        options={options}
      />
    );
  }

  getColumns() {
    return [
      {
        name: 'facilitynm',
        title: 'Facility Name',
        className: 'width-200',
      },
      {
        name: 'type',
        title: 'Type',
        className: 'width-100',
        render: (key: any, row: TFacilityOverdueAmountsItem) =>
          this.typeRender(row),
      },
      {
        name: 'sel',
        title: 'Selection',
        className: 'width-400',
        render: (key: any, row: TFacilityOverdueAmountsItem) =>
          this.selectionRender(row),
      },
      {
        name: 'lateFee',
        title: 'Late Fee, $',
        render: (key: any, row: TFacilityOverdueAmountsItem) => (
          <Text
            name="lateFee_"
            noLabel
            value={row.lateFee}
            onSetValue={(n, d) => this.setLateFee(row.facilityid, d)}
          />
        ),
        className: 'width-100',
      },
    ];
  }

  static getRangeByPeriod(period: string) {
    if (!period) {
      return null;
    }
    let startDate = startOfToday();

    const currentDate = new Date();

    if (period === 'ytd') {
      startDate = startOfYear(currentDate);
    } else {
      startDate.setTime(
        currentDate.getTime() - parseInt(period) * 24 * 3600 * 1000
      );
    }
    return {
      dateFrom: getDateString(startDate),
      dateTo: getDateString(currentDate),
    };
  }

  validate() {
    let out = true;
    this.inputReferences.forEach((row, index) => {
      Object.entries(row).forEach(([name, input]) => {
        if (!input) {
          return;
        }
        const state = (input as any).getInputState((input as any).getValue());
        if (state.errorKeys && state.errorKeys.length > 0) {
          out = false;
          const dataSource = this.state.dataSource.map((el: any, idx: number) =>
            idx === index
              ? {
                  ...el,
                  errors: {
                    ...(el?.errors || {}),
                    [name]: state.errorMessages,
                  },
                }
              : el
          );
          this.setState({ dataSource });
        }
      });
    });
    return out;
  }

  handleSubmit = () => {
    if (this.validate()) {
      const mappedDataSource = this.state.dataSource.map((item) => {
        const out = Object.assign({}, item);
        if (out.type === OVERDUE_AMOUNT_TYPES.DUE_PERIOD) {
          const range = FacilityOverdueAmountsModal.getRangeByPeriod(
            out.selectionPeriod
          );
          out.dateFrom = range.dateFrom;
          out.dateTo = range.dateTo;
        } else if (out.type === OVERDUE_AMOUNT_TYPES.ALL_DATES) {
          out.dateFrom = '01/01/1970';
          out.dateTo = '01/01/2038';
        }
        delete out.errors;
        delete out.index;
        delete out.facilitynm;
        delete out.type;
        return out;
      });
      this.props.callback(true, mappedDataSource);
    } else {
      this.forceUpdate(); // todo need rewrite. bad practice in this case
    }
  };

  onSetGlobalRange(name: string, value: any) {
    const globalRange = Object.assign({}, this.state.globalRange);
    const dataSource = this.state.dataSource.map((item: any) => {
      item[name] = value;
      return item;
    });
    globalRange[name] = value;
    this.setState({ globalRange, dataSource });
  }

  handleClose = () => {
    this.props.callback(false);
  };

  render() {
    this.inputReferences = [];

    const { globalRange, dataSource, onAjax } = this.state;
    const { dateTo, dateFrom } = globalRange;

    return (
      <Dialog size="large" scrollable={false} handleClose={this.handleClose}>
        <DialogHeader
          title="Facility Overdue Amounts"
          onClose={this.handleClose}
        />
        <DialogBody>
          <div className="d-flex gap-3">
            <TextCalendar
              value={dateFrom}
              onSetValue={(n, d) => this.onSetGlobalRange(n, d)}
              name="dateFrom"
              className="width-150"
              noLabel
            />
            <TextCalendar
              value={dateTo}
              onSetValue={(n, d) => this.onSetGlobalRange(n, d)}
              name="dateTo"
              className="width-150"
              noLabel
            />
          </div>

          <Grid
            id="facility_overdue_amounts_grid"
            columns={this.getColumns()}
            disablePagination
            onAjax={onAjax}
            dataSource={dataSource}
          />
        </DialogBody>
        <DialogFooter>
          <Button text="Submit" onClick={this.handleSubmit} />
        </DialogFooter>
      </Dialog>
    );
  }
}
