import AbstractFilter, { EQUAL_LABELED_CONDITIONS } from './AbstractFilter';
import IterableUtils from 'utils/IterableUtils';
import Select from 'components/form/input/Select';

export default class ModeOfPaymentFilter extends AbstractFilter {
  name = 'modeOfPayment';
  field = 'Mode of Payment';

  CONDITIONS = EQUAL_LABELED_CONDITIONS;

  OPTIONS = [
    { label: 'Cash', value: 1 },
    { label: 'Credit Card', value: 2 },
    { label: 'Cheque', value: 3 },
    { label: 'Direct Deposit', value: 4 },
    { label: 'Cashier Cheque', value: 5 },
  ];

  render() {
    return (
      <div className="mode-of-payment-filter">
        {this.renderConditions()}
        {this.renderSelectValue()}
      </div>
    );
  }

  renderSelectValue() {
    return (
      <Select
        name="filter1"
        options={this.OPTIONS}
        label="Value"
        className="col-sm-3"
        value={this.state.filterItem.field1}
        onSetValue={(name, value) =>
          this.onSetField1Value(name, parseInt(value))
        }
      />
    );
  }

  onSetField1Value(name, value) {
    const optionsValues = { ...this.OPTIONS };
    const filterItem = {
      ...this.state.filterItem,
      field1: value,
      value: IterableUtils.grep(optionsValues, (e) => e.value === value),
    };
    this.setState({ filterItem: filterItem }, () =>
      this.props.onSetValue(filterItem)
    );
  }
}
