const style = {
  width: 0,
  height: 0,
  position: 'absolute',
  visibility: 'hidden',
};

const createLinkElement = (href: string) => {
  const link = document.createElement('a');
  // @ts-ignore
  link.style = style;
  link.href = href;
  link.rel = 'noreferrer';
  link.target = '_blank';
  return link;
};

export default createLinkElement;
