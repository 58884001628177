import { apiRequest } from 'services/RequestService';

export class TCorporateGroup {
  refid: number = null;
  groupname: string = '';
  groupdesc: string = '';
  address: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  phone: string = '';
  email: string = '';
  website: string = '';
  fax: string = '';
  alternate_phone: string = '';
  alternate_email: string = '';
  contact_firstname: string = '';
  contact_lastname: string = '';
  contact_middlename: string = '';
  contact_title: string = '';
  facilitycnt: number = null;
  facilityname: string = '';
  enable: boolean = false;
}

export class TDocs {
  filenm: string = '';
  filestatus: 'O' = 'O';
  remvImg: string = '';
  uploaded_dt: string = '';
}

export class TCorporateGroupById {
  refid: number = null;
  group_nm: string = '';
  group_desc: string = '';
  address: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  phone: string = '';
  email: string = '';
  website: string = '';
  country: string = '';
  fax: string = '';
  address2: string = '';
  alternate_phone: string = '';
  alternate_email: string = '';
  contact_firstname: string = '';
  contact_lastname: string = '';
  contact_middlename: string = '';
  contact_title: string = '';
  jurisdiction_id: string = '';
  groupid: number = null;
  stateId: number = null;
  cptFlag: 'S' | 'C' = 'C';
}

export class TAddressById {
  refid: number = null;
  address1val: string = '';
  cityval: string = '';
  sateval: string = '';
  zipval: string = '';
  phoneval: string = '';
  mailval: string = '';
  website: string = '';
  countryval: string = '';
  address2val: string = '';
  phone1yval: string = '';
  mail1val: string = '';
  firstname: string = '';
  lastname: string = '';
  middlename: string = '';
  contact_title: string = '';
  faxval: string = '';
  AddOpt?: boolean = false;
  DelOpt?: boolean = true;
}

export class TGroupById {
  facility: Array<TCorporateGroupById> = [];
  AddressArr: 0 | Array<TAddressById> = [];
  fileArr: 0 | { file: Array<TDocs> } = 0;
}

export class TCorporationAddAddress {
  refid?: number = null;
  first_name?: string = '';
  last_name?: string = '';
  middle_name?: string = '';
  contact_title?: string = '';
  address1?: string = '';
  city?: string = '';
  email?: string = '';
  phone?: string = '';
  website?: string = '';
  state?: string = '';
  country?: string = '';
  address2?: string = '';
  fax?: string = '';
  zipcode?: string = '';
  alt_email?: string = '';
  alt_phone?: string = '';
  title?: string = '';
  corp_name: string = '';
  desc?: string = '';
}

export class TCorporationAddress extends TCorporationAddAddress {
  groupid?: number = null;
  stateId?: number = null;
  cptFlag?: 'S' | 'C' = 'C';
}

export class TCorporationItem {
  addressList: Array<TCorporationAddress> = [];
  additionalAddressList: Array<TCorporationAddAddress> = [];
  fileList: Array<TDocs> = [];
}

export class TFacilityMember {
  refid: number = null;
  zipcode: string = '';
  facility_npi: string = '';
  facility_id: number = null;
  facility_nm: string = '';
  location: string = '';
}

export class TFacility {
  refid: number = null;
  facility_nm: string = '';
  state: string = '';
  phone: string = '';
  zipcode: string = '';
  facility_npi: string = '';
  pos: string = '';
}

export class TOrderType {
  1: boolean = false;
  2: boolean = false;
  3: boolean = false;
  4: boolean = false;
  5: boolean = false;
}

export class TFacilityItem {
  facilityName: string = '';
  address: string = '';
  phone: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  country: string = '';
  locationId: number = null;
  orderType: TOrderType = new TOrderType();
  //
  regionId: number = null;
  stateId: number = null;
}

export interface CorporateAminUserType {
  birthdate: null | string;
  corporate_id: string;
  corpporateid: string;
  first_name: string;
  gender: 'Male' | 'Female' | null;
  homeaddress: string;
  homecity: string;
  homestate: string;
  homezipcode: string;
  last_name: string;
  ['ppxray_mv_usertype.usertype_name']: string;
  ['ppxray_userinfo.refid']: string;
  refid: number;
  ssn: string;
  username: string;
  usertype_name: string;
}

export class TServiceBase {
  refid: number = null;
  percent: string = '';
}

export class TServiceName extends TServiceBase {
  name: string = '';
}

export class TCorporateCptCode {
  refid: number = null;
  cpt_code: string = '';
  cpt_description: string = '';
  cpt_price: string = '';
  profprice: string = '';
  globalprice: string = '';
  is_default: 'T' | 'G' | '' = '';
  insure_glbl_prc: string = '';
  insure_prof_prc: string = '';
  insure_tech_prc: string = '';
  is_default_insure: 'T' | 'G' | 'P' | '' = '';
  modifier_code: string = '';
  corporate_id: number = null;
  flag: 'A' | 'R' = null;
}

class CorporationOverviewPageService {
  loadCorporationItem(corpId: number) {
    return apiRequest<TGroupById>({
      url: 'groupmanager.CorporateGroup.GetGroupById',
      data: [corpId],
    }).then((response) => {
      const modifiedResponse: TCorporationItem = new TCorporationItem();
      modifiedResponse.addressList = [];
      modifiedResponse.additionalAddressList = [];
      const corpList: Array<TCorporateGroupById> = response.facility;
      const corpItem: TCorporateGroupById = corpList[0];
      if (corpItem) {
        modifiedResponse.addressList.push({
          refid: corpItem.refid,
          first_name: corpItem.contact_firstname,
          last_name: corpItem.contact_lastname,
          middle_name: corpItem.contact_middlename,
          contact_title: corpItem.contact_title,
          address1: corpItem.address,
          city: corpItem.city,
          email: corpItem.email,
          phone: corpItem.phone,
          website: corpItem.website,
          state: corpItem.state,
          country: corpItem.country,
          address2: corpItem.address2,
          fax: corpItem.fax,
          zipcode: corpItem.zipcode,
          alt_email: corpItem.alternate_email,
          alt_phone: corpItem.alternate_phone,
          title: corpItem.contact_title,
          corp_name: corpItem.group_nm,
          desc: corpItem.group_desc,
          groupid: corpItem.groupid,
          stateId: corpItem.stateId,
          cptFlag: corpItem.cptFlag,
        });
      }
      if (
        response.AddressArr &&
        Array.isArray(response.AddressArr) &&
        response.AddressArr.length > 0
      ) {
        response.AddressArr.forEach((corpItem: TAddressById) => {
          modifiedResponse.additionalAddressList.push({
            refid: corpItem.refid,
            first_name: corpItem.firstname,
            last_name: corpItem.lastname,
            middle_name: corpItem.middlename,
            contact_title: corpItem.contact_title,
            address1: corpItem.address1val,
            city: corpItem.cityval,
            email: corpItem.mailval,
            phone: corpItem.phoneval,
            website: corpItem.website,
            state: corpItem.sateval,
            country: corpItem.countryval,
            address2: corpItem.address2val,
            fax: corpItem.faxval,
            zipcode: corpItem.zipval,
            alt_email: corpItem.mail1val,
            alt_phone: corpItem.phone1yval,
            title: corpItem.contact_title,
            corp_name: (corpItem as any).group_nm, // no such in response
            desc: (corpItem as any).group_desc, // no such in response
          });
        });
      }
      modifiedResponse.fileList = response.fileArr ? response.fileArr.file : [];
      return modifiedResponse;
    });
  }

  saveCorporationItem(
    addressList: Array<TCorporationAddress>,
    additionalAddressList: Array<TCorporationAddAddress>,
    fileList: Array<TDocs>
  ) {
    const first: TCorporationAddress = addressList[0];
    let mappedSecondaryAddressList: Array<TAddressById> = [];
    if (additionalAddressList.length !== 0) {
      mappedSecondaryAddressList = [];
      additionalAddressList.forEach((corpItem) => {
        mappedSecondaryAddressList.push({
          refid: null,
          address1val: corpItem.address1,
          cityval: corpItem.city,
          sateval: corpItem.state,
          zipval: corpItem.zipcode,
          phoneval: corpItem.phone,
          mailval: corpItem.email,
          website: corpItem.website,
          countryval: corpItem.country,
          address2val: corpItem.address2,
          phone1yval: corpItem.alt_phone,
          mail1val: corpItem.alt_email,
          firstname: corpItem.first_name,
          lastname: corpItem.last_name,
          middlename: corpItem.middle_name,
          contact_title: corpItem.title,
          faxval: corpItem.fax,
        });
      });
    }
    if (fileList && Array.isArray(fileList) && fileList.length === 0) {
      fileList = [new TDocs()];
    }
    const data = [
      first.corp_name,
      first.desc,
      first.address1,
      first.address2,
      first.city,
      first.state,
      first.zipcode,
      first.phone,
      first.alt_phone,
      first.email,
      first.website,
      first.alt_email,
      first.country,
      first.fax,
      first.first_name,
      first.last_name,
      first.middle_name,
      first.title,
      mappedSecondaryAddressList,
      fileList,
    ];

    return apiRequest<'E' | number>({
      url: 'groupmanager.CorporateGroup.AddGroup',
      data,
    });
  }

  updateCorporationItem(
    corpId: number,
    addressList: Array<TCorporationAddress>,
    additionalAddressList: Array<TCorporationAddAddress>,
    fileList: Array<TDocs>
  ) {
    const first = addressList[0];
    let mappedSecondaryAddressList: Array<TAddressById> = [];
    if (additionalAddressList.length !== 0) {
      mappedSecondaryAddressList = [];
      additionalAddressList.forEach((corpItem) => {
        mappedSecondaryAddressList.push({
          refid: corpItem.refid || null,
          firstname: corpItem.first_name,
          lastname: corpItem.last_name,
          middlename: corpItem.middle_name,
          address1val: corpItem.address1,
          cityval: corpItem.city,
          mailval: corpItem.email,
          phoneval: corpItem.phone,
          website: corpItem.website,
          sateval: corpItem.state,
          countryval: corpItem.country,
          address2val: corpItem.address2,
          faxval: corpItem.fax,
          zipval: corpItem.zipcode,
          mail1val: corpItem.alt_email,
          phone1yval: corpItem.alt_phone,
          contact_title: corpItem.title,
        });
      });
    }
    if (fileList && Array.isArray(fileList) && fileList.length === 0) {
      fileList = [new TDocs()];
    }
    const data = [
      corpId,
      first.corp_name,
      first.desc,
      first.address1,
      first.address2,
      first.city,
      first.state,
      first.zipcode,
      first.phone,
      first.alt_phone,
      first.email,
      first.website,
      first.alt_email,
      first.country,
      first.fax,
      first.first_name,
      first.last_name,
      first.middle_name,
      first.title,
      mappedSecondaryAddressList,
      fileList,
    ];

    return apiRequest<'E' | number>({
      url: 'groupmanager.CorporateGroup.EditGroup',
      data,
    });
  }

  loadFacilityMemberList(corpId: number) {
    return apiRequest<Array<TFacilityMember>>({
      url: 'groupmanager.CorporateGroup.GetFacilityMembers',
      data: [corpId],
    });
  }

  loadFacilityList() {
    return apiRequest<Array<TFacility>>({
      url: 'groupmanager.CorporateGroup.GetFacilityList',
    }).then((response) => {
      if (!response || !Array.isArray(response)) {
        return response;
      }
      for (let i = 0; i < response.length; i++) {
        if (!response[i].facility_nm) {
          response[i].facility_nm = '';
        }
        if (!response[i].zipcode) {
          response[i].zipcode = '';
        }
        if (!response[i].facility_npi) {
          response[i].facility_npi = '';
        }
      }
      return response;
    });
  }

  loadFacilityListById(corpId: number) {
    return apiRequest<Array<TFacilityMember>>({
      url: 'groupmanager.CorporateGroup.getFacilityById',
      data: [corpId],
    });
  }

  saveFacilityList(corpId: number, facilityList: Array<{ refid: number }>) {
    return apiRequest<number>({
      url: 'groupmanager.CorporateGroup.FacilityMappByCorp',
      data: [corpId, facilityList],
    });
  }

  saveNewFacilityItem(facilityItem: TFacilityItem) {
    const orderType = facilityItem.orderType;
    const orderTypeArr: Array<number> = [];
    if (orderType) {
      for (let key in orderType) {
        if ((orderType as any)[key]) {
          orderTypeArr.push(parseInt('' + key));
        }
      }
    }
    const data = [
      facilityItem.facilityName,
      facilityItem.address,
      facilityItem.phone,
      facilityItem.city,
      facilityItem.state,
      facilityItem.zipcode,
      facilityItem.country,
      facilityItem.locationId,
      orderTypeArr,
    ];

    return apiRequest<'SE' | 'E' | number>({
      url: 'facility.FacilityMaster.AddNewFacility',
      data,
    });
  }

  loadUserList() {
    return apiRequest<0 | CorporateAminUserType[]>({
      url: 'groupmanager.CorporateGroup.GetCorporateAminUser',
    }).then((response) =>
      Array.isArray(response)
        ? response.map((user) => ({ ...user, refid: Number(user.refid) }))
        : []
    );
  }

  loadSelectedUsers(corpId: number): Promise<Array<number>> {
    return apiRequest<0 | Array<{ refid: number }>>({
      url: 'groupmanager.CorporateGroup.GetUserById',
      data: [corpId],
    }).then((response) =>
      Array.isArray(response) ? response.map(({ refid }) => Number(refid)) : []
    );
  }

  saveSelectedUsers(corpId: number, userIdList: Array<number>) {
    const userList: Array<{ refid: number }> = [];
    userIdList.forEach((item) => {
      userList.push({ refid: item });
    });
    return apiRequest<'S'>({
      url: 'groupmanager.CorporateGroup.AddUserByCorporate',
      data: [corpId, userList],
    });
  }

  loadServiceList(corpId: number) {
    const flag = 'C';
    return apiRequest<Array<TServiceName>>({
      url: 'codemaster.CPTCode.GetServiceName',
      data: [corpId, flag],
    });
  }

  saveServiceList(corpId: number, serviceList: Array<TServiceBase>) {
    return apiRequest<'S'>({
      url: 'facility.FacilityMaster.AddServiceByCorporate',
      data: [corpId, serviceList],
    });
  }

  loadFeeListByCorporation(
    corpId: number,
    groupId: number,
    cptStatus: 'A' | 'R'
  ) {
    return apiRequest<'SE' | 0 | Array<TCorporateCptCode>>({
      url: 'codemaster.CorporateCPTCode.CorporateCPTCodeAllList',
      data: [corpId, groupId, cptStatus],
    });
  }

  loadFeeCountByCorporation(
    corpId: number,
    groupId: number,
    cptStatus: 'A' | 'R'
  ) {
    return apiRequest<'SE' | number>({
      url: 'codemaster.CorporateCPTCode.CorporateCPTCodeTotalCount',
      data: [corpId, groupId, cptStatus],
    });
  }
}

export default new CorporationOverviewPageService();
