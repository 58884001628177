import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import FormReportType, { FormModel } from './components/Form';

import { storeDocumentType } from 'stores/_mobx/systemSetup/masterSetting/documentType';
import { URL_IMPORT_ITEM } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const ImportItemEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, document } = storeDocumentType;

  const handleSubmit = (payload: FormModel) =>
    storeDocumentType
      .updateDocument(payload as Required<FormModel>)
      .then((response) => {
        if (!response) {
          history.push(URL_IMPORT_ITEM);
        }
        return response;
      });

  useEffect(() => {
    storeDocumentType.getDocument(params.id);
    return storeDocumentType.clearDocumentForDelete;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fetching ? (
    <SpinnerFixed />
  ) : (
    <>
      <Title title="Edit Report Type" />
      {document ? (
        <FormReportType
          backUrl={URL_IMPORT_ITEM}
          defaultValues={document}
          onSubmit={handleSubmit}
        />
      ) : null}
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(ImportItemEditPage);
