import { makeObservable, observable, action, runInAction } from 'mobx';

import { TPagination } from 'components/grid/GridTypes';
import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import FilterNameAndState, {
  FilterType,
} from 'stores/_mobx/options/filterNameAndStatus';

export interface VehicleFormModel {
  refid: null | number;
  vehicledesc: string;
  vehicle: string;
  vechcolor: string;
  regnumber: string;
  vehicleyear: number | '';
  model: string;
  vinnumber: string;
  documentList: FileType[];
}

export interface VehicleType {
  color: string;
  description: string;
  image_path: string;
  make_year: string;
  reg_number: string;
  vehicle_model: string;
  vehicle_name: string;
  vin_number: string;
  enable: boolean;
  refid: number;
}

interface FileResponseType {
  desc: string;
  filenm: string;
  filestatus: string;
  remvImg: string;
  uploaded_dt: string;
}

interface VehicleResponseType {
  0: Omit<VehicleType, 'enable'>;
  file: 0 | FileResponseType[];
}

interface FileType {
  desc: string;
  filenm: string;
  filestatus: string;
}

export interface OptionType {
  value: number;
  label: string;
}

class Vehicle {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  vehicleList: VehicleType[] = [];
  vehicleTotal: number = 0;
  vehicle: VehicleFormModel | undefined = undefined;
  idForDelete: number | null = null;
  noteId: number = 0;
  options: OptionType[] = [];

  filter: FilterNameAndState = new FilterNameAndState();
  page: Pagination = new Pagination({ id: 'vehicle_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      vehicleList: observable,
      vehicleTotal: observable,
      vehicle: observable,
      idForDelete: observable,
      noteId: observable,
      options: observable,

      setFetching: action,
      setFetchingOptions: action,
      setVehicleList: action,
      setVehicleTotal: action,
      setVehicle: action,
      setIdForDelete: action.bound,
      setNoteId: action,
      clearIdForDelete: action.bound,
      clearVehicle: action.bound,
      clearNoteId: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFetchingOptions = (fetching: boolean) => {
    this.fetchingOptions = fetching;
  };

  setVehicleList(list: VehicleType[]) {
    this.vehicleList = list;
  }

  setVehicleTotal(count: number) {
    this.vehicleTotal = count;
  }

  setVehicle(vehicle?: VehicleFormModel) {
    this.vehicle = vehicle;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setNoteId(id: number) {
    this.noteId = id;
  }

  clearIdForDelete() {
    this.idForDelete = null;
  }

  clearVehicle() {
    this.vehicle = undefined;
  }

  clearNoteId() {
    this.noteId = 0;
  }

  async getVehicleList({
    pagination,
    filter,
  }: {
    pagination: TPagination;
    filter: FilterType;
  }) {
    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.name,
      filter.state,
    ];

    try {
      const response = await apiRequest<'SE' | VehicleType[]>({
        url: 'vehicle.VehicleMaster.GetVehicleList',
        data,
      });
      if (Array.isArray(response)) {
        const list = response.map((item) => ({
          ...item,
          refid: Number(item.refid),
        }));
        this.setVehicleList(list);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setVehicleList([]);
    }
  }

  async getVehicleCount(filter: FilterType) {
    try {
      const count = await apiRequest<'SE' | 'S' | number>({
        url: 'vehicle.VehicleMaster.VehicleTotalCount',
        data: [filter.name, filter.state],
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setVehicleTotal(total);
    } catch (e) {
      this.setVehicleTotal(0);
    }
  }

  getVehicleMain = () => {
    this.setFetching(true);

    const props = {
      pagination: this.page.pagination,
      filter: this.filter.options,
    };

    const promiseList = this.getVehicleList(props);

    const promiseCount = this.getVehicleCount(props.filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getVehicle(id: number) {
    this.setFetching(true);

    try {
      const response = await apiRequest<[] | VehicleResponseType>({
        url: 'vehicle.VehicleMaster.GetViewVehicle',
        data: [id],
      });
      if (Array.isArray(response)) {
        throw Error('');
      } else {
        const documentList = response.file
          ? response.file.map(({ filenm, desc, filestatus }) => ({
              filenm,
              desc,
              filestatus,
            }))
          : [];

        const vehicleEntry = response[0];
        const year = Number(vehicleEntry.make_year);

        const vehicle = {
          vehicledesc: vehicleEntry.description,
          vehicle: vehicleEntry.vehicle_name,
          vechcolor: vehicleEntry.color,
          regnumber: vehicleEntry.reg_number,
          vehicleyear: Number.isNaN(year) || !Number(year) ? ('' as '') : year,
          model: vehicleEntry.vehicle_model,
          vinnumber: vehicleEntry.vin_number,
          refid: id,
          documentList,
        };

        this.setVehicle(vehicle);
      }
    } catch (e) {
      this.setVehicle();
    } finally {
      this.setFetching(false);
    }
  }

  async addVehicle({ documentList, ...payload }: VehicleFormModel) {
    const data = [payload, [], documentList];
    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'vehicle.VehicleMaster.AddVehicle',
        data,
      });
      if (result === 'S') {
        Notification.success('Vehicle was added successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'License number is not unique!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateVehicle({ documentList, ...payload }: VehicleFormModel) {
    const data = [payload.refid, payload, documentList];
    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'vehicle.VehicleMaster.EditVehicle',
        data,
      });

      if (result === 'S') {
        Notification.success('Vehicle was updated successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'This Serial number already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  changeVehicleStatus = async (ids: number[]) => {
    this.setFetching(true);
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'vehicle.VehicleMaster.changeStatus',
        data: [ids],
      });
      if (response === 'S') {
        const { length } = ids;

        const statusMsg = this.filter.options.isActive
          ? 'deactivated'
          : 'activated';

        Notification.success(
          `${length} Vehicle${length > 1 ? 's were' : 'was'} ${statusMsg}`
        );
        return true;
      } else Error('');
    } catch (e) {
      Notification.warning('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  };

  async deleteVehicle() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'vehicle.VehicleMaster.DeleteVehicle',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Vehicle was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("This item is in use, so it can't be deleted!");
      this.setFetching(false);
      return false;
    }
  }

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOptions(true);
    try {
      const options = await apiRequest<OptionType[]>({
        url: 'vehicle.EquipmentMaster.VehicleDropDown',
      });

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storeVehicle = new Vehicle();
