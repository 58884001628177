import FacilityDetailsService from 'services/system-setup/master-setup/facility/FacilityDetailsService';
import { Actions } from 'stores/system-setup/facility/FacilityDetailsStore';
import appDispatcher from 'dispatcher/AppDispatcher';

export default class FacilityDetailsActions {
  static getFacilityDetails(facilityId: number) {
    return FacilityDetailsService.getStateRegionLoc(facilityId).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.getFacilityDetails,
          data: response?.[0] || null,
        });
      }
    );
  }

  static clearFacilityDetails() {
    appDispatcher.dispatch({
      type: Actions.clearFacilityDetails,
    });
  }
}
