import { forwardRef } from 'react';
import { Controller, Control, ValidationRule } from 'react-hook-form';

import PureInput, { PureInputProps } from './PureInput';

/**
  @prop {string} 'data-testid' - data attribute for testing libraries
  If data-testid not presented, then prefix for data-testid attribute will generated with 'name' property
  '{dataTestId}-label' - data attribute for the label
  '{dataTestId}-field' - data attribute for the input field
  '{dataTestId}-error' - data attribute for the error block
*/

export interface PropsType
  extends Omit<
    PureInputProps,
    | 'value'
    | 'name'
    | 'onChange'
    | 'onBlur'
    | 'onFocus'
    | 'errorMessage'
    | 'pattern'
  > {
  control: Control<any>;
  defaultValue?: string;
  name: string;
  pattern?: ValidationRule<RegExp>;
}

const Input = forwardRef<HTMLInputElement, PropsType>(
  (
    {
      control,
      name,
      defaultValue = '',
      required = false,
      pattern,
      ...rest
    }: PropsType,
    ref
  ) => (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required,
        max: rest.max,
        maxLength: rest.maxLength,
        pattern,
      }}
      render={({ field, fieldState: { error } }) => {
        const errorMessage = error?.message || '';

        return (
          <PureInput
            errorMessage={errorMessage}
            required={required}
            {...rest}
            {...field}
            ref={ref}
          />
        );
      }}
    />
  )
);

export default Input;
