import clsx from 'clsx';

import NumberInput from 'components/form/input/NumberInput';
import GeneralDropdown from 'components/project/dropdown/GeneralDropdown';
import DropdownService from 'services/project/DropdownService';

interface Props {
  wrapperClass?: string;
  text: any;
  select: any;
  onSetValue?: (name: string, value: any, error: any) => void;
}

const PhoneComponent = ({
  wrapperClass = '',
  text,
  select,
  onSetValue,
}: Props) => {
  const cn = clsx(wrapperClass, 'mobile-phone');

  return (
    <div className={cn}>
      <NumberInput format="###-###-####" {...text} onSetValue={onSetValue} />
      <GeneralDropdown
        {...select}
        onSetValue={onSetValue}
        optionsLoader={() => DropdownService.loadMobileOperators()}
      />
    </div>
  );
};

export default PhoneComponent;
