import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Input } from 'components/form/textField';

export const defaultFilter: FilterType = {
  orderId: '',
  cpt_code_val: '',
  modifier: '',
};

export interface FilterType {
  orderId: string;
  cpt_code_val: string;
  modifier: string;
}

interface PropsType {
  defaultValues: FilterType;
  onChangeFilter: (payload: FilterType) => void;
}

const InvoiceGridFilter = ({ defaultValues, onChangeFilter }: PropsType) => {
  const { control, watch } = useForm<FilterType>({ defaultValues });

  useEffect(() => {
    const subscription = watch((formValue) => {
      onChangeFilter(formValue as Required<FilterType>);
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row">
      <div className="fw-bold mb-2">Filter invoices by:</div>
      <Input
        name="orderId"
        placeholder="Study ID"
        className="col-md-4"
        control={control}
      />
      <Input
        name="cpt_code_val"
        placeholder="CPT Code"
        className="col-md-4"
        control={control}
      />
      <Input
        name="modifier"
        placeholder="Modifier"
        className="col-md-4"
        control={control}
      />
    </form>
  );
};

export default InvoiceGridFilter;
