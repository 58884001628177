import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Exporter from 'components/project/ExporterNew';
import { Grid, GridControlButton } from 'components/grid';
import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import Filter from './components/Filter';

import UserProfileStore from 'stores/UserProfileStore';
import { storeExamReport, FilterType } from 'stores/_mobx/report/examReport';
import { EXAM_REPORT_OTHER as PAGE_ID } from 'constant/pagesId/reports';

const columns = [
  { name: 'facility_nm', title: 'Facility' },
  { name: 'pat_ltnm', title: 'Patient Last Name' },
  { name: 'pat_ftnm', title: 'Patient First Name' },
  { name: 'examtype', title: 'Modality' },
  { name: 'tech_lastnm', title: 'Technologist Last Name' },
  { name: 'tech_firstnm', title: 'Technologist First Name' },
  {
    name: 'orderassign_dt',
    title: 'Assigned Date',
    className: 'text-nowrap',
  },
  {
    name: 'ordercompletion_dt',
    title: 'Completion Date',
    className: 'text-nowrap',
  },
];

const ExamReport = () => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    finalLogList,
    logTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeExamReport;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleApplyFilter = (filter: FilterType) => {
    storeExamReport.setFilter(filter);

    setPaginationToStart();
  };

  useLayoutEffect(() => {
    storeExamReport.setDefaultFilter();
  }, []);

  useEffect(() => {
    storeExamReport.getLogsListMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle
        appId={PAGE_ID.PAGE}
        title="Modality Report (Dev Needed)">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        fetching={fetching}
        isVisible={isFilterVisible}
        onSubmit={handleApplyFilter}
      />

      <Grid
        id="exam_report_gridId"
        noDataText="Please refine your search using the filters above"
        gridControlPanel={
          <GridControlButton title="Export" onClick={handleToggleExport} />
        }
        columns={columns}
        ref={gridRef}
        onAjax={fetching}
        dataSource={finalLogList}
        dataSourceCount={logTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      {isExportVisible && (
        <Exporter
          exporter="ExamExporter"
          reportName={
            UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
            'Modality Report'
          }
          filter={storeExamReport.prepareFilterToExport()}
          gridRef={gridRef}
          columns={columns}
          dataSource={finalLogList}
          // Export to PDF was disabled in the legacy version
          // pdfExporter={storeExamReport.generatePdf}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default observer(ExamReport);
