import { ReduceStore } from 'flux/utils';
import { Dispatcher } from 'flux';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import { TDropDownItem } from 'actions/project/DropdownActions';
import { THospiceAddress } from 'actions/project/HospiceActions';

class PHospiceStore extends CommonPayload {
  id: string;
}

class SHospiceStore {
  hospiceList: Array<TDropDownItem> = [];
  hospiceDetails: Map<string, THospiceAddress> = new Map<
    string,
    THospiceAddress
  >();
}

class HospiceStore extends ReduceStore<SHospiceStore, PHospiceStore> {
  getInitialState() {
    return new SHospiceStore();
  }

  areEqual(first: SHospiceStore, second: SHospiceStore) {
    return false;
  }

  reduce(state: SHospiceStore, action: PHospiceStore) {
    switch (action.type) {
      case 'generalmaster.ExamType.GetHospiceAddress':
        this.getState().hospiceDetails.set(action.id, action.data);
        break;
      default:
        break;
    }
    return state;
  }

  getHospiceDetails(id: string) {
    return this.getState().hospiceDetails.get(id);
  }

  isDetailsLoaded(id: string) {
    return !!this.getState().hospiceDetails.get(id);
  }
}

const stateStore = new HospiceStore(appDispatcher as Dispatcher<PHospiceStore>);
export default stateStore;
