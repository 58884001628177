import React from 'react';

import IterableUtils from 'utils/IterableUtils';
import Text from 'components/form/input/Text';
import NumberInput from 'components/form/input/NumberInput';
import TextCalendar from 'components/form/input/TextCalendar';
import Form from 'components/form/Form';
import Radio from 'components/form/input/Radio';
import Select from 'components/form/input/Select';
import FormControl from 'components/form/FormControl';
import Fieldset from 'components/form/Fieldset';
import StateShortNameDropdown from 'components/project/dropdown/StateShortName';
import StateDropdown from 'components/project/dropdown/StateDropdown';
import PayerNameDropdown from 'components/project/dropdown/PayerNameDropdown';
import PatientRecordPageStateActions from 'actions/patient-records/patient-demographics/PatientRecordPageStateActions';
import PatientRecordPageActions from 'actions/patient-records/patient-demographics/PatientRecordPageActions';
import RadioOptions from 'components/project/common/RadioOptions';
import UserProfileStore from 'stores/UserProfileStore';
import {
  TPatientStatusInfo,
  TGuarantorInfo,
  TInsuredInfo,
} from 'services/patient-records/patient-demographics/PatientRecordPageService';

export interface PInsuranceInfoTab {
  mode: number;
  patientStatusInfo: TPatientStatusInfo;
  patientStatusInfoEditable: boolean;
  guarantorInfo: TGuarantorInfo;
  guarantorInfoEditable: boolean;
  insuredInfo: TInsuredInfo;
  primaryInsuredEditable: boolean;
  secondaryInsuredEditable: boolean;
  tertiaryInsuredEditable: boolean;
  updateInsuredInfo: (value: any) => any;
  updatePatientStatusInfo: (value: any) => any;
  updateGuarantorInfo: (value: any) => any;
}

export class SInsuranceInfoTab {
  mode: number = null;
  primaryPayerPlanList: Array<any> = [];
  secondaryPayerPlanList: Array<any> = [];
  tertiaryPayerPlanList: Array<any> = [];
  patientStatusInfo: TPatientStatusInfo = new TPatientStatusInfo();
  patientStatusInfoEditable: boolean = false;
  guarantorInfo: TGuarantorInfo = new TGuarantorInfo();
  guarantorInfoEditable: boolean = false;
  insuredInfo: TInsuredInfo = new TInsuredInfo();
  primaryInsuredEditable: boolean = false;
  secondaryInsuredEditable: boolean = false;
  tertiaryInsuredEditable: boolean = false;
  errors: any = {};
  collapsedPatientStatusInfo: boolean = false;
  collapsedGuarantorInfo: boolean = false;
  collapsedInsuredInfo: boolean = false;
  collapsedAddressInfo: boolean = false;
  collapsedPrimaryInsInfo: boolean = false;
  collapsedSecondaryInsInfo: boolean = true;
  collapsedTertiaryInsInfo: boolean = true;
  collapsedAddress1InsInfo: boolean = true;
  collapsedAddress2InsInfo: boolean = true;
}

export default class InsuranceInfoTab extends React.Component<
  PInsuranceInfoTab,
  SInsuranceInfoTab
> {
  constructor(props: PInsuranceInfoTab) {
    super(props);
    this.state = Object.assign(new SInsuranceInfoTab(), {
      mode: this.props.mode,
      primaryPayerPlanList: [],
      secondaryPayerPlanList: [],
      tertiaryPayerPlanList: [],
      patientStatusInfo: this.props.patientStatusInfo,
      patientStatusInfoEditable: this.props.patientStatusInfoEditable,
      guarantorInfo: this.props.guarantorInfo,
      guarantorInfoEditable: this.props.guarantorInfoEditable,
      insuredInfo: this.props.insuredInfo,
      primaryInsuredEditable: this.props.primaryInsuredEditable,
      secondaryInsuredEditable: this.props.secondaryInsuredEditable,
      tertiaryInsuredEditable: this.props.tertiaryInsuredEditable,
      errors: {},
      collapsedPatientStatusInfo: false,
      collapsedGuarantorInfo: false,
      collapsedInsuredInfo: false,
      collapsedAddressInfo: false,
      collapsedPrimaryInsInfo: false,
      collapsedSecondaryInsInfo: true,
      collapsedTertiaryInsInfo: true,
      collapsedAddress1InsInfo: true,
      collapsedAddress2InsInfo: true,
    });
  }

  componentDidMount() {
    PatientRecordPageActions.loadPayerPlan(
      this.state.insuredInfo.primpayorid
    ).then((response) => {
      this.setState({ primaryPayerPlanList: response });
    });
    PatientRecordPageActions.loadPayerPlan(
      this.state.insuredInfo.secpayorid
    ).then((response) => {
      this.setState({ secondaryPayerPlanList: response });
    });
    PatientRecordPageActions.loadPayerPlan(
      this.state.insuredInfo.terpayorid
    ).then((response) => {
      this.setState({ tertiaryPayerPlanList: response });
    });
  }

  componentWillUnmount() {
    let patientStatusInfo = IterableUtils.extend(
      true,
      {},
      this.state.patientStatusInfo
    );
    let guarantorInfo = IterableUtils.extend(
      true,
      {},
      this.state.guarantorInfo
    );
    const insuredInfo = { ...this.state.insuredInfo };
    PatientRecordPageStateActions.cacheInsuranceInfoTab(
      patientStatusInfo,
      guarantorInfo,
      insuredInfo
    );
  }

  onSetDateOfDeath(name: string, value: any) {
    let patientStatusInfo = IterableUtils.extend(
      true,
      {},
      this.state.patientStatusInfo
    );
    patientStatusInfo[name] = value;
    this.setState({ patientStatusInfo: patientStatusInfo });
  }

  onSetInsuranceLastVerified(name: string, value: any) {
    let patientStatusInfo = IterableUtils.extend(
      true,
      {},
      this.state.patientStatusInfo
    );
    patientStatusInfo[name] = value;
    this.setState({ patientStatusInfo: patientStatusInfo });
  }

  onSetDateOfBirth(name: string, value: any) {
    let guarantorInfo = IterableUtils.extend(
      true,
      {},
      this.state.guarantorInfo
    );
    guarantorInfo[name] = value;
    this.setState({ guarantorInfo: guarantorInfo });
  }

  onSetInsuredDate(name: string, value: any) {
    let insuredInfo = IterableUtils.extend(true, {}, this.state.insuredInfo);
    insuredInfo[name] = value;
    this.setState({ insuredInfo });
  }

  render() {
    const insCollapsed =
      this.state.collapsedInsuredInfo &&
      this.state.collapsedPrimaryInsInfo &&
      this.state.collapsedSecondaryInsInfo &&
      this.state.collapsedTertiaryInsInfo &&
      this.state.collapsedAddress1InsInfo &&
      this.state.collapsedAddress2InsInfo;
    const insuredInfo = this.state.insuredInfo;
    const patientStatusInfoDisabled = !this.state.patientStatusInfoEditable
      ? { disabled: true }
      : {};
    const guarantorInfoDisabled = !this.state.guarantorInfoEditable
      ? { disabled: true }
      : {};
    const primaryInsuredDisabled = !this.state.primaryInsuredEditable
      ? { disabled: true }
      : {};
    const secondaryInsuredDisabled = !this.state.secondaryInsuredEditable
      ? { disabled: true }
      : {};
    const tertiaryInsuredDisabled = !this.state.tertiaryInsuredEditable
      ? { disabled: true }
      : {};
    const priReq =
      this.state.primaryInsuredEditable &&
      insuredInfo.primpayorid &&
      '' + insuredInfo.primpayorid !== '0';
    const secReq =
      this.state.secondaryInsuredEditable &&
      insuredInfo.secpayorid &&
      '' + insuredInfo.secpayorid !== '0';
    const terReq =
      this.state.tertiaryInsuredEditable &&
      insuredInfo.terpayorid &&
      '' + insuredInfo.terpayorid !== '0';
    return (
      <div className="insurance-info-tab">
        {this.props.mode === 1 ? <div className="overlay" /> : null}
        <Form
          model={this.state.patientStatusInfo}
          submit={this.onSubmitPatientStatusInfo.bind(this)}
          onCollectValues={this.onCollectPatientStatusInfoValues.bind(this)}
          errors={this.state.errors}>
          <Fieldset
            className={
              this.state.collapsedPatientStatusInfo ? 'collapsed' : 'expanded'
            }>
            <legend
              onClick={() => {
                this.setState({
                  collapsedPatientStatusInfo:
                    !this.state.collapsedPatientStatusInfo,
                });
              }}>
              Patient status information
            </legend>
            <Fieldset className="row">
              <Fieldset className="col-sm-6">
                <Text
                  name="room"
                  label="Bldg / Station / Unit / Room"
                  attr={patientStatusInfoDisabled}
                />
                <Radio
                  className="part-inline"
                  label="Patient status"
                  name="patient_status"
                  options={[
                    { label: 'Patient Active', value: 'A' },
                    { label: 'Patient Discharged', value: 'C' },
                    { label: 'Patient Died', value: 'D' },
                  ]}
                  attr={patientStatusInfoDisabled}
                />
              </Fieldset>
              <Fieldset className="col-sm-6">
                <TextCalendar
                  name="dt_of_death"
                  label="Date of death"
                  onSetValue={this.onSetDateOfDeath.bind(this)}
                  attr={patientStatusInfoDisabled}
                />
                <TextCalendar
                  name="ins_last_verified"
                  label="Insurance last verified"
                  onSetValue={this.onSetInsuranceLastVerified.bind(this)}
                  attr={{ disabled: true }}
                />
              </Fieldset>
            </Fieldset>
          </Fieldset>
          <FormControl>
            {!this.state.collapsedPatientStatusInfo &&
            this.state.patientStatusInfoEditable
              ? [
                  <input
                    key="2"
                    type="submit"
                    className="btn btn-primary"
                    value="Save"
                  />,
                ]
              : null}
          </FormControl>
        </Form>
        <Form
          model={this.state.guarantorInfo}
          submit={this.onSubmitGuarantorInfo.bind(this)}
          onCollectValues={this.onCollectGuarantorInfoValues.bind(this)}
          errors={this.state.errors}>
          <Fieldset
            className={
              this.state.collapsedGuarantorInfo ? 'collapsed' : 'expanded'
            }>
            <legend
              onClick={() => {
                this.setState({
                  collapsedGuarantorInfo: !this.state.collapsedGuarantorInfo,
                });
              }}>
              Guarantor information
            </legend>
            <Fieldset className="row">
              <Fieldset className="col-sm-6">
                <Text
                  name="guar_g_fnm"
                  label="First name"
                  attr={guarantorInfoDisabled}
                />
                <Text
                  name="guar_g_mid"
                  label="Middle name"
                  attr={guarantorInfoDisabled}
                />
                <Text
                  name="guar_g_lnm"
                  label="Last name"
                  attr={guarantorInfoDisabled}
                />
                <Radio
                  className="part-inline"
                  label="Gender"
                  name="guar_g_gen"
                  options={RadioOptions.gender}
                  attr={guarantorInfoDisabled}
                />
              </Fieldset>
              <Fieldset className="col-sm-6">
                <NumberInput
                  name="guar_g_wphone"
                  label="Work phone"
                  format="###-###-####"
                  attr={guarantorInfoDisabled}
                />
                <NumberInput
                  name="guar_g_ssn"
                  label="Social security number"
                  format="###-##-####"
                  attr={guarantorInfoDisabled}
                />
                <TextCalendar
                  name="guar_g_dob"
                  label="Date of birth"
                  onSetValue={this.onSetDateOfBirth.bind(this)}
                  attr={guarantorInfoDisabled}
                />
                <Radio
                  className="part-inline"
                  label="Guarantor relationship"
                  name="guar_g_relation"
                  options={RadioOptions.relationship}
                  attr={guarantorInfoDisabled}
                />
              </Fieldset>
            </Fieldset>
            <Fieldset
              className={
                this.state.collapsedAddressInfo ? 'collapsed' : 'expanded'
              }>
              <legend
                onClick={() => {
                  this.setState({
                    collapsedAddressInfo: !this.state.collapsedAddressInfo,
                  });
                }}>
                Address information
              </legend>
              <Fieldset className="row">
                <Fieldset className="col-sm-6">
                  <legend>1st address</legend>
                  <Text
                    name="guar_g_address1"
                    label="Address"
                    attr={guarantorInfoDisabled}
                  />
                  <StateShortNameDropdown
                    name="guar_g_state"
                    label="State"
                    attr={guarantorInfoDisabled}
                  />
                  <Text
                    name="guar_g_city"
                    label="City"
                    attr={guarantorInfoDisabled}
                  />
                  <NumberInput
                    name="guar_g_zip"
                    label="Zip Code"
                    format="#####-####"
                    attr={guarantorInfoDisabled}
                  />
                </Fieldset>
                <Fieldset className="col-sm-6">
                  <legend>2nd address</legend>
                  <Text
                    name="guar_g_address2"
                    label="Address"
                    attr={guarantorInfoDisabled}
                  />
                  <StateShortNameDropdown
                    name="guar_g_state2"
                    label="State"
                    attr={guarantorInfoDisabled}
                  />
                  <Text
                    name="guar_g_city2"
                    label="City"
                    attr={guarantorInfoDisabled}
                  />
                  <NumberInput
                    name="guar_g_zip2"
                    label="Zip Code"
                    format="#####-####"
                    attr={guarantorInfoDisabled}
                  />
                </Fieldset>
              </Fieldset>
              <Fieldset className="row">
                <Fieldset className="col-sm-12">
                  <Text
                    name="guar_g_email"
                    label="Email"
                    attr={guarantorInfoDisabled}
                  />
                  <NumberInput
                    name="guar_g_fax"
                    label="Fax"
                    format="###-###-####"
                    attr={guarantorInfoDisabled}
                  />
                  <NumberInput
                    name="guar_g_mobile"
                    label="Mobile"
                    format="###-###-####"
                    attr={guarantorInfoDisabled}
                  />
                  <NumberInput
                    name="guar_g_phone"
                    label="Home phone"
                    format="###-###-####"
                    attr={guarantorInfoDisabled}
                  />
                </Fieldset>
              </Fieldset>
            </Fieldset>
          </Fieldset>
          <FormControl>
            {!this.state.guarantorInfo && this.state.guarantorInfoEditable
              ? [
                  <input
                    key="2"
                    type="submit"
                    className="btn btn-primary"
                    value="Save"
                  />,
                ]
              : null}
          </FormControl>
        </Form>
        <Form
          model={this.state.insuredInfo}
          submit={this.onSubmitInsuredInfo.bind(this)}
          onCollectValues={this.onCollectInsuredInfoValues.bind(this)}
          errors={this.state.errors}>
          <Fieldset
            className={
              this.state.collapsedInsuredInfo ? 'collapsed' : 'expanded'
            }>
            <legend
              onClick={() => {
                this.setState({
                  collapsedInsuredInfo: !this.state.collapsedInsuredInfo,
                });
              }}>
              Insured's information
            </legend>
            <Fieldset className="row">
              <Fieldset className="col-sm-4">
                <Text name="medicare_id" label="Medicare ID" />
              </Fieldset>
              <Fieldset className="col-sm-4">
                <Text name="medicaid_id" label="Medicaid ID" />
              </Fieldset>
              <Fieldset className="col-sm-4">
                <StateDropdown name="medicaid_stateid" label="Covered under" />
              </Fieldset>
            </Fieldset>
            <Fieldset
              className={
                this.state.collapsedPrimaryInsInfo ? 'collapsed' : 'expanded'
              }>
              <legend
                onClick={() => {
                  this.setState({
                    collapsedPrimaryInsInfo:
                      !this.state.collapsedPrimaryInsInfo,
                  });
                }}>
                Primary Insured
              </legend>
              <Fieldset className="row">
                <Fieldset className="col-sm-6">
                  <Radio
                    className="part-inline"
                    label="Gender"
                    name="priminsgender"
                    options={[
                      { label: 'Male', value: 'M' },
                      { label: 'Female', value: 'F' },
                      { label: 'Unknown', value: '' },
                    ]}
                    attr={primaryInsuredDisabled}
                  />
                  <Text
                    name="priminsfname"
                    label="First name"
                    attr={primaryInsuredDisabled}
                  />
                  <Text
                    name="priminsmname"
                    label="Middle name"
                    attr={primaryInsuredDisabled}
                  />
                  <Text
                    name="priminslname"
                    label="Last name"
                    attr={primaryInsuredDisabled}
                  />
                  <TextCalendar
                    name="priminsdt"
                    label="Date of birth"
                    onSetValue={this.onSetInsuredDate.bind(this)}
                    attr={primaryInsuredDisabled}
                  />
                  <Text
                    name="prigroupno"
                    label="Group number"
                    attr={primaryInsuredDisabled}
                  />
                </Fieldset>
                <Fieldset className="col-sm-6">
                  <Radio
                    className="part-inline"
                    label="Insured relationship"
                    name="priminspatrelation"
                    options={[
                      { label: 'Self', value: 'SE' },
                      { label: 'Spouse', value: 'SP' },
                      { label: 'Child', value: 'CH' },
                      { label: 'Other', value: 'OT' },
                    ]}
                    attr={primaryInsuredDisabled}
                  />
                  <TextCalendar
                    name="priminsstdt"
                    label="Effective start date"
                    onSetValue={this.onSetInsuredDate.bind(this)}
                    attr={primaryInsuredDisabled}
                    validations={priReq ? 'required' : ''}
                  />
                  <TextCalendar
                    name="priminsendt"
                    label="Effective end date"
                    onSetValue={this.onSetInsuredDate.bind(this)}
                    attr={primaryInsuredDisabled}
                  />
                  <Text
                    name="priminsurance_no"
                    label="Insurance number"
                    validations={priReq ? 'required' : ''}
                    attr={primaryInsuredDisabled}
                  />
                  <Text
                    name="priminsorgname"
                    label="Organization name"
                    attr={primaryInsuredDisabled}
                  />
                  <PayerNameDropdown
                    name="primpayorid"
                    label="Payer name"
                    attr={primaryInsuredDisabled}
                    validations={priReq ? 'required:lazy' : ''}
                  />
                  <Select
                    name="primplanid"
                    options={this.state.primaryPayerPlanList}
                    label="Payer plan"
                    attr={primaryInsuredDisabled}
                    validations={priReq ? 'required:lazy' : ''}
                  />
                </Fieldset>
              </Fieldset>
            </Fieldset>
            <Fieldset
              className={
                this.state.collapsedSecondaryInsInfo ? 'collapsed' : 'expanded'
              }>
              <legend
                onClick={() => {
                  this.setState({
                    collapsedSecondaryInsInfo:
                      !this.state.collapsedSecondaryInsInfo,
                  });
                }}>
                Secondary Insured
              </legend>
              <Fieldset className="row">
                <Fieldset className="col-sm-6">
                  <Radio
                    className="part-inline"
                    label="Gender"
                    name="secinsgender"
                    options={[
                      { label: 'Male', value: 'M' },
                      { label: 'Female', value: 'F' },
                      { label: 'Unknown', value: '' },
                    ]}
                    attr={secondaryInsuredDisabled}
                  />
                  <Text
                    name="secinsfname"
                    label="First name"
                    attr={secondaryInsuredDisabled}
                  />
                  <Text
                    name="secinsmname"
                    label="Middle name"
                    attr={secondaryInsuredDisabled}
                  />
                  <Text
                    name="secinslname"
                    label="Last name"
                    attr={secondaryInsuredDisabled}
                  />
                  <TextCalendar
                    name="secinsdt"
                    label="Date of birth"
                    onSetValue={this.onSetInsuredDate.bind(this)}
                    attr={secondaryInsuredDisabled}
                  />
                  <Text
                    name="secgroupno"
                    label="Group number"
                    attr={secondaryInsuredDisabled}
                  />
                </Fieldset>
                <Fieldset className="col-sm-6">
                  <Radio
                    className="part-inline"
                    label="Insured relationship"
                    name="secinspatrelation"
                    options={[
                      { label: 'Self', value: 'SE' },
                      { label: 'Spouse', value: 'SP' },
                      { label: 'Child', value: 'CH' },
                      { label: 'Other', value: 'OT' },
                    ]}
                    attr={secondaryInsuredDisabled}
                  />
                  <TextCalendar
                    name="secinsstdt"
                    label="Effective start date"
                    onSetValue={this.onSetInsuredDate.bind(this)}
                    attr={secondaryInsuredDisabled}
                    validations={secReq ? 'required' : ''}
                  />
                  <TextCalendar
                    name="secinsendt"
                    label="Effective end date"
                    onSetValue={this.onSetInsuredDate.bind(this)}
                    attr={secondaryInsuredDisabled}
                  />
                  <Text
                    name="secninsurance_no"
                    label="Insurance number"
                    validations={secReq ? 'required' : ''}
                    attr={secondaryInsuredDisabled}
                  />
                  <Text
                    name="secinsorgname"
                    label="Organization name"
                    attr={secondaryInsuredDisabled}
                  />
                  <PayerNameDropdown
                    name="secpayorid"
                    label="Payer name"
                    attr={secondaryInsuredDisabled}
                    validations={secReq ? 'required:lazy' : ''}
                  />
                  <Select
                    name="secplanid"
                    options={this.state.secondaryPayerPlanList}
                    label="Payer plan"
                    attr={secondaryInsuredDisabled}
                  />
                </Fieldset>
              </Fieldset>
            </Fieldset>
            <Fieldset
              className={
                this.state.collapsedTertiaryInsInfo ? 'collapsed' : 'expanded'
              }>
              <legend
                onClick={() => {
                  this.setState({
                    collapsedTertiaryInsInfo:
                      !this.state.collapsedTertiaryInsInfo,
                  });
                }}>
                Tertiary Insured
              </legend>
              <Fieldset className="row">
                <Fieldset className="col-sm-6">
                  <Radio
                    className="part-inline"
                    label="Gender"
                    name="teryinsgender"
                    options={[
                      { label: 'Male', value: 'M' },
                      { label: 'Female', value: 'F' },
                      { label: 'Unknown', value: '' },
                    ]}
                    attr={tertiaryInsuredDisabled}
                  />
                  <Text
                    name="teryinsfname"
                    label="First name"
                    attr={tertiaryInsuredDisabled}
                  />
                  <Text
                    name="teryinsmname"
                    label="Middle name"
                    attr={tertiaryInsuredDisabled}
                  />
                  <Text
                    name="teryinslname"
                    label="Last name"
                    attr={tertiaryInsuredDisabled}
                  />
                  <TextCalendar
                    name="teryinsdt"
                    label="Date of birth"
                    onSetValue={this.onSetInsuredDate.bind(this)}
                    attr={tertiaryInsuredDisabled}
                  />
                  <Text
                    name="tergroupno"
                    label="Group number"
                    attr={tertiaryInsuredDisabled}
                  />
                </Fieldset>
                <Fieldset className="col-sm-6">
                  <Radio
                    className="part-inline"
                    label="Insured relationship"
                    name="teryinspatrelation"
                    options={[
                      { label: 'Self', value: 'SE' },
                      { label: 'Spouse', value: 'SP' },
                      { label: 'Child', value: 'CH' },
                      { label: 'Other', value: 'OT' },
                    ]}
                    attr={tertiaryInsuredDisabled}
                  />
                  <TextCalendar
                    name="teryinsstdt"
                    label="Effective start date"
                    validations={terReq ? 'required' : ''}
                    onSetValue={this.onSetInsuredDate.bind(this)}
                    attr={tertiaryInsuredDisabled}
                  />
                  <TextCalendar
                    name="teryinsendt"
                    label="Effective end date"
                    onSetValue={this.onSetInsuredDate.bind(this)}
                    attr={tertiaryInsuredDisabled}
                  />
                  <Text
                    name="teryinsurance_no"
                    label="Insurance number"
                    validations={terReq ? 'required' : ''}
                    attr={tertiaryInsuredDisabled}
                  />
                  <Text
                    name="teryinsorgname"
                    label="Organization name"
                    attr={tertiaryInsuredDisabled}
                  />
                  <PayerNameDropdown
                    name="terpayorid"
                    label="Payer name"
                    validations={terReq ? 'required:lazy' : ''}
                    attr={tertiaryInsuredDisabled}
                  />
                  <Select
                    name="teryplanid"
                    options={this.state.tertiaryPayerPlanList}
                    label="Payer plan"
                    attr={tertiaryInsuredDisabled}
                  />
                </Fieldset>
              </Fieldset>
            </Fieldset>
            <Fieldset
              className={
                this.state.collapsedAddress1InsInfo ? 'collapsed' : 'expanded'
              }>
              <legend
                onClick={() => {
                  this.setState({
                    collapsedAddress1InsInfo:
                      !this.state.collapsedAddress1InsInfo,
                  });
                }}>
                Address 1 Information
              </legend>
              <Fieldset className="row">
                <Fieldset className="col-sm-6">
                  <Text name="teryinsaddr1" label="Address" />
                  <StateShortNameDropdown name="teryinsstate" label="State" />
                  <NumberInput
                    name="teryinsmobile"
                    label="Cellphone"
                    format="###-###-####"
                  />
                  <NumberInput
                    name="teryinsfax"
                    label="Fax"
                    format="###-###-####"
                  />
                </Fieldset>
                <Fieldset className="col-sm-6">
                  <Text name="teryinscity" label="City" />
                  <NumberInput
                    name="teryinszip"
                    label="Zip Code"
                    format="#####-####"
                  />
                  <NumberInput
                    name="teryinsph"
                    label="Home Phone"
                    format="###-###-####"
                  />
                  <Text name="teryinsemail" label="Email" />
                </Fieldset>
              </Fieldset>
            </Fieldset>
            <Fieldset
              className={
                this.state.collapsedAddress2InsInfo ? 'collapsed' : 'expanded'
              }>
              <legend
                onClick={() => {
                  this.setState({
                    collapsedAddress2InsInfo:
                      !this.state.collapsedAddress2InsInfo,
                  });
                }}>
                Address 2 Information
              </legend>
              <Fieldset className="row">
                <Fieldset className="col-sm-6">
                  <Text name="teryinsaddr2" label="Address" />
                  <StateShortNameDropdown name="teryinsstate2" label="State" />
                </Fieldset>
                <Fieldset className="col-sm-6">
                  <Text name="teryinscity2" label="City" />
                  <NumberInput
                    name="teryinszip2"
                    label="Zip Code"
                    format="#####-####"
                  />
                </Fieldset>
              </Fieldset>
            </Fieldset>
          </Fieldset>
          <FormControl>
            {!insCollapsed
              ? [
                  <input
                    key="2"
                    type="submit"
                    className="btn btn-primary"
                    value="Save"
                  />,
                ]
              : null}
          </FormControl>
        </Form>
      </div>
    );
  }

  reset() {
    PatientRecordPageStateActions.restoreInitialInsuranceInfoTab();
  }

  onCollectPatientStatusInfoValues(
    name: string,
    value: any,
    errorMessages: any
  ) {
    let state = IterableUtils.extend(true, {}, this.state);
    state.patientStatusInfo[name] = value;
    state.errors[name] = errorMessages;
    this.setState(state);
  }

  onCollectGuarantorInfoValues(name: string, value: any, errorMessages: any) {
    let state = IterableUtils.extend(true, {}, this.state);
    state.guarantorInfo[name] = value;
    state.errors[name] = errorMessages;
    this.setState(state);
  }

  onCollectInsuredInfoValues(name: string, value: any, errorMessages: any) {
    let state = IterableUtils.extend(true, {}, this.state);
    if (name === 'primpayorid') {
      PatientRecordPageActions.loadPayerPlan(value).then((response) => {
        this.setState({ primaryPayerPlanList: response });
      });
    }
    if (name === 'secpayorid') {
      PatientRecordPageActions.loadPayerPlan(value).then((response) => {
        this.setState({ secondaryPayerPlanList: response });
      });
    }
    if (name === 'terpayorid') {
      PatientRecordPageActions.loadPayerPlan(value).then((response) => {
        this.setState({ tertiaryPayerPlanList: response });
      });
    }
    state.insuredInfo[name] = value;
    state.errors[name] = errorMessages;
    this.setState(state);
  }

  onSubmitPatientStatusInfo() {
    let patientStatusInfo = IterableUtils.extend(
      true,
      {},
      this.state.patientStatusInfo
    );
    this.props.updatePatientStatusInfo(patientStatusInfo);
  }

  onSubmitGuarantorInfo() {
    let guarantorInfo = IterableUtils.extend(
      true,
      {},
      this.state.guarantorInfo
    );
    this.props.updateGuarantorInfo(guarantorInfo);
  }

  onSubmitInsuredInfo(model: any, hasErrors: boolean, errors: any) {
    if (hasErrors) {
      const thisErrors = { ...this.state.errors };
      for (let name in errors) {
        thisErrors[name] = errors[name];
      }
      this.setState({ errors: thisErrors });
      return;
    }
    let insInfo: any = this.state.insuredInfo;
    let guarantorInfo = this.state.guarantorInfo;
    let insuredInfo = {
      refid: insInfo.refid,
      patient_mrn: UserProfileStore.getAppSn() + insInfo.patient_id,
      medicare_id: insInfo.medicare_id,
      medicaid_id: insInfo.medicaid_id,
      medicaid_stateid: insInfo.medicaid_stateid,

      prim_gender: insInfo.priminsgender,
      prim_fname: insInfo.priminsfname,
      prim_mname: insInfo.priminsmname,
      prim_lname: insInfo.priminslname,
      prim_dob: insInfo.priminsdt,
      pri_groupno: insInfo.prigroupno,
      prim_relationship: insInfo.priminspatrelation,
      prim_stdt: insInfo.priminsstdt,
      prim_enddt: insInfo.priminsendt,
      prim_insno: insInfo.priminsurance_no,
      prim_orgnm: insInfo.priminsorgname,
      prim_payor: insInfo.primpayorid,
      prim_plan: insInfo.primplanid,

      sec_gender: insInfo.secinsgender,
      sec_fname: insInfo.secinsfname,
      sec_mname: insInfo.secinsmname,
      sec_lname: insInfo.secinslname,
      sec_dob: insInfo.secinsdt,
      sec_groupno: insInfo.secgroupno,
      sec_relationship: insInfo.secinspatrelation,
      sec_stdt: insInfo.secinsstdt,
      sec_enddt: insInfo.secinsendt,
      sec_insno: insInfo.secninsurance_no,
      sec_orgnm: insInfo.secinsorgname,
      sec_payor: insInfo.secpayorid,
      sec_plan: insInfo.secplanid,

      ter_gender: insInfo.teryinsgender,
      ter_fname: insInfo.teryinsfname,
      ter_mname: insInfo.teryinsmname,
      ter_lname: insInfo.teryinslname,
      ter_dob: insInfo.teryinsdt,
      ter_groupno: insInfo.tergroupno,
      ter_relationship: insInfo.teryinspatrelation,
      ter_stdt: insInfo.teryinsstdt,
      ter_enddt: insInfo.teryinsendt,
      ter_insno: insInfo.teryinsurance_no,
      ter_orgnm: insInfo.teryinsorgname,
      ter_payor: insInfo.terpayorid,
      ter_plan: insInfo.teryplanid,

      zipcode1: insInfo.teryinszip,
      zip2: insInfo.teryinszip2,
      addr1: insInfo.teryinsaddr1,
      addr2: insInfo.teryinsaddr2,
      state1: insInfo.teryinsstate,
      state2: insInfo.teryinsstate2,
      city1: insInfo.teryinscity,
      city2: insInfo.teryinscity2,
      fax: insInfo.teryinsfax,
      phno: insInfo.teryinsph,
      mobile: insInfo.teryinsmobile,
      email: insInfo.teryinsemail,

      guar_g_fnm: guarantorInfo.guar_g_fnm,
      guar_g_mid: guarantorInfo.guar_g_mid,
      guar_g_lnm: guarantorInfo.guar_g_lnm,
      guar_g_gen: guarantorInfo.guar_g_gen,
      guar_g_wphone: guarantorInfo.guar_g_wphone,
      guar_g_ssn: guarantorInfo.guar_g_ssn,
      guar_g_dob: guarantorInfo.guar_g_dob,
      guar_g_relation: guarantorInfo.guar_g_relation,

      guar_g_address1: guarantorInfo.guar_g_address1,
      guar_g_state: guarantorInfo.guar_g_state,
      guar_g_city: guarantorInfo.guar_g_city,
      guar_g_zip: guarantorInfo.guar_g_zip,
      guar_g_address2: guarantorInfo.guar_g_address2,
      guar_g_state2: guarantorInfo.guar_g_state2,
      guar_g_city2: guarantorInfo.guar_g_city2,
      guar_g_zip2: guarantorInfo.guar_g_zip2,
      guar_g_email: guarantorInfo.guar_g_email,
      guar_g_fax: guarantorInfo.guar_g_fax,
      guar_g_phone: guarantorInfo.guar_g_phone,
      guar_g_mobile: guarantorInfo.guar_g_mobile,
    };
    this.props.updateInsuredInfo(insuredInfo);
  }
}
