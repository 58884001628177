import AbstractLikeFilter from './AbstractLikeFilter';

export default class FacilityFilter extends AbstractLikeFilter {
  name = 'facility';
  field = 'Facility';
  field1 = AbstractLikeFilter.getInsecureSecureField(
    'ppxray_facility.facility_nm'
  );
  className = 'facility-filter';
}
