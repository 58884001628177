import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Input } from 'components/form/textField';
import { InputCalendar } from 'components/form/inputCalendar';
import { calcDateRange } from 'utils/calcDateRange';
import { Radio } from 'components/form/radio';
import RadioOptions from 'components/project/common/RadioOptions';
import { CheckboxGroup } from 'components/form/checkbox';
import { Button } from 'components/button';

import {
  storeBriefcaseHistory,
  defaultFilterValue,
  FilterType,
} from 'stores/_mobx/report/briefcaseHistory';

const options = [
  { value: 'upload', label: 'Upload' },
  { value: 'download', label: 'Download' },
  { value: 'create', label: 'Create' },
  { value: 'rename', label: 'Rename' },
  { value: 'delete', label: 'Delete' },
  { value: 'share', label: 'Shared' },
];

interface PropsType {
  fetching: boolean;
  isFilterVisible: boolean;
  onSubmit: (data: FilterType) => void;
}

const Filter = ({ fetching, isFilterVisible, onSubmit }: PropsType) => {
  const { control, watch, setValue, reset } = useForm<FilterType>({
    defaultValues: storeBriefcaseHistory.filter,
  });

  const handleClickReset = () => {
    reset(defaultFilterValue);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      let filterData = formValue;
      if (name === 'period') {
        const { dosEnd, dosStart } = calcDateRange(formValue.period);
        setValue('dateTo', dosEnd);
        setValue('dateFrom', dosStart);
        filterData = {
          ...formValue,
          dateTo: dosEnd,
          dateFrom: dosStart,
        };
      }
      onSubmit(filterData as Required<FilterType>);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className={`row mb-4${isFilterVisible ? '' : ' d-none'}`}>
      <Input
        name="fileName"
        label="File Name"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <div />
      <InputCalendar
        name="dateFrom"
        label="From"
        className="col-md-4 col-lg-3"
        control={control}
      />
      <InputCalendar
        name="dateTo"
        label="To"
        className="col-md-4 col-lg-3"
        control={control}
      />
      <Radio
        name="period"
        className="part-inline col-md-4 col-lg-auto"
        label="Period"
        options={RadioOptions.filterPeriod}
        control={control}
      />
      <CheckboxGroup
        label="Action type"
        name="actionType"
        className="col-sm-auto me-auto part-inline"
        options={options}
        control={control}
      />
      <div className="col-auto align-self-end">
        <Button
          text="Reset"
          variant="danger"
          disabled={fetching}
          onClick={handleClickReset}
        />
      </div>
    </form>
  );
};

export default Filter;
