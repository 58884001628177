import { Actions } from 'stores/patient-records/patient-demographics/PatientRecordPageStore';
import appDispatcher from 'dispatcher/AppDispatcher';

const storage = window.localStorage;

export default class PatientRecordPageStateActions {
  static setCurrentTab(currentTab: number) {
    storage['currentTab'] = currentTab;
  }

  static getCurrentTab() {
    return storage['currentTab'];
  }

  static unblockTab(tabNumber: number) {
    let pageState = storage['patientRecordPageState'];
    pageState[tabNumber] = false;
    storage['patientRecordPageState'] = JSON.stringify(pageState);
  }

  static cachePatientInfoTab(
    generalInfo: any,
    facilityInfo: any,
    contactInfo: any
  ) {
    appDispatcher.dispatch({
      type: Actions.CACHE_PATIENT_INFO_TAB_ACTION,
      data: {
        generalInfo: generalInfo,
        facilityInfo: facilityInfo,
        contactInfo: contactInfo,
      },
    });
  }

  static cacheEmployerInfoTab(employerInfo: any) {
    appDispatcher.dispatch({
      type: Actions.CACHE_EMPLOYER_INFO_TAB_ACTION,
      data: {
        employerInfo: employerInfo,
      },
    });
  }

  static cacheInsuranceInfoTab(patientStatusInfo: any, guarantorInfo: any, insuredInfo: any) {
    appDispatcher.dispatch({
      type: Actions.CACHE_INSURANCE_INFO_TAB_ACTION,
      data: {
        patientStatusInfo: patientStatusInfo,
        guarantorInfo: guarantorInfo,
        insuredInfo: insuredInfo,
      },
    });
  }

  static cacheCommentInfoTab(commentInfo: any) {
    appDispatcher.dispatch({
      type: Actions.CACHE_COMMENT_INFO_TAB_ACTION,
      data: {
        commentInfo: commentInfo,
      },
    });
  }

  static uncachePatientInfoTab() {
    appDispatcher.dispatch({
      type: Actions.UNCACHE_PATIENT_INFO_TAB_ACTION,
    });
  }

  static uncacheEmployerInfoTab() {
    appDispatcher.dispatch({
      type: Actions.UNCACHE_EMPLOYER_INFO_TAB_ACTION,
    });
  }

  static uncacheInsuranceInfoTab() {
    appDispatcher.dispatch({
      type: Actions.UNCACHE_INSURANCE_INFO_TAB_ACTION,
    });
  }

  static uncacheCommentInfoTab() {
    appDispatcher.dispatch({
      type: Actions.UNCACHE_COMMENT_INFO_TAB_ACTION,
    });
  }

  static restoreInitialPatientInfoTab() {
    appDispatcher.dispatch({
      type: Actions.RESTORE_INITIAL_PATIENT_INFO_TAB_ACTION,
    });
  }

  static restoreInitialEmployerInfoTab() {
    appDispatcher.dispatch({
      type: Actions.RESTORE_INITIAL_EMPLOYER_INFO_TAB_ACTION,
    });
  }

  static restoreInitialInsuranceInfoTab() {
    appDispatcher.dispatch({
      type: Actions.RESTORE_INITIAL_INSURANCE_INFO_TAB_ACTION,
    });
  }

  static restoreInitialCommentInfoTab() {
    appDispatcher.dispatch({
      type: Actions.RESTORE_INITIAL_COMMENT_INFO_TAB_ACTION,
    });
  }
}
