import { MessagePreviewType } from 'stores/_mobx/message/sent';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface PropsType {
  mailcontent: { mailheading: string; mailid: string; refid: string }[];
  dttime: string;
  mail_sub: string;
  mail_body: string;
}

const messagePrettier = <T extends PropsType>({
  mailcontent,
  dttime,
  mail_sub,
  mail_body,
}: T): MessagePreviewType => {
  const fromItem = mailcontent.find(({ mailheading }) =>
    /^from(:)?$/i.test(mailheading)
  );

  const toItem = mailcontent.find(({ mailheading }) =>
    /^to(:)?$/i.test(mailheading)
  );

  const date = dateToLocalTimezone({
    date: dttime.trim(),
  });

  return {
    date,
    from: fromItem?.mailid || '',
    to: toItem?.mailid || '',
    subject: mail_sub || '',
    body: mail_body || '',
  };
};

export default messagePrettier;
