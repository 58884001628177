import React from 'react';

import Text from 'components/form/input/Text';
import Checkbox from 'components/form/input/Checkbox';
import TextArea from 'components/form/input/TextArea';
import NumberInput from 'components/form/input/NumberInput';
import ZipCodeInput from 'components/form/input/ZipCodeInput';
import TextCalendar from 'components/form/input/TextCalendar';
import Form from 'components/form/Form';
import Radio from 'components/form/input/Radio';
import Select from 'components/form/input/Select';
import FormControl from 'components/form/FormControl';
import Fieldset from 'components/form/Fieldset';
import RegionDropdown from 'components/project/dropdown/RegionDropdown';
import OrderTypeCheckboxes from 'components/project/checkboxes/OrderTypeCheckboxes';
import StateDropdown from 'components/project/dropdown/StateDropdown';
import StateShortNameDropdown from 'components/project/dropdown/StateShortName';
import RadioOptions from 'components/project/common/RadioOptions';
import NpiInput from 'components/project/NpiInput';
import SendingApplicationDropdown from 'components/project/dropdown/SendingApplicationDropdown';
import ProviderTypeDropdown from 'components/project/dropdown/ProviderTypeDropdown';
import { DropdownPureDivision } from 'components/project/dropdown/Division';
import { Button, IconButton } from 'components/button';

import FacilityPageActions from 'actions/system-setup/master-setup/facility/FacilityPageActions';
import UserProfileStore from 'stores/UserProfileStore';
import { defaultStationDetails } from 'stores/system-setup/facility/FacilityPageStore';
import { storeProviderType } from 'stores/_mobx/systemSetup/masterSetting/providerType';
import IterableUtils from 'utils/IterableUtils';

export interface PFacilityTab {
  blocked: boolean;
  facilityItem: any;
  locationList: Array<any>;
  submitFacilityTab: (facilityItem: any) => Promise<any>;
  updateLocationList: (regionId: number, stateId: number) => void;
  backToList: () => void;
}

export class SFacilityTab {
  showAddModal: boolean = false;
  errors: any = {};
  facilityItem: any = {};
  eligibilityDisabled: boolean = false;
}

export default class FacilityTab extends React.Component<
  PFacilityTab,
  SFacilityTab
> {
  constructor(props: PFacilityTab) {
    super(props);
    this.state = {
      ...new SFacilityTab(),
      errors: {},
      facilityItem: {
        ...this.props.facilityItem,
        facilityData: {
          ...(this.props.facilityItem?.facilityData || {}),
          facility_type:
            Number(this.props.facilityItem?.facilityData?.facility_type) || 0,
        },
      },
    };
  }

  updateTabState(state: SFacilityTab) {
    this.setState(state);
  }

  onSetValue(name: string, value: any) {
    const facilityItem = {
      ...this.state.facilityItem,
      [name]: value,
    };
    this.setState({ facilityItem });
  }

  onAddRowClicked = (idx: number) => () => {
    const stationDetailsList = [...this.state.facilityItem.stationDetailsList];

    stationDetailsList.splice(++idx, 0, defaultStationDetails);

    const facilityItem = {
      ...this.state.facilityItem,
      stationDetailsList,
    };

    this.setState({ facilityItem });
  };

  onRemoveRowClicked = (i: number) => () => {
    const { stationDetailsList } = this.state.facilityItem;

    const filteredOutStations =
      stationDetailsList.length > 1
        ? (stationDetailsList as []).filter((item: any, index) => index !== i)
        : [defaultStationDetails];

    const facilityItem = {
      ...this.state.facilityItem,
      stationDetailsList: filteredOutStations,
    };
    this.setState({ facilityItem });
  };

  onDetailsChanged(name: string, value: any, i: number) {
    const { stationDetailsList } = this.state.facilityItem;
    const facilityItem = {
      ...this.state.facilityItem,
      stationDetailsList: (stationDetailsList as Record<string, string>[]).map(
        (el: any, index) =>
          index === i
            ? {
                ...el,
                [name]: value,
              }
            : el
      ),
    };

    this.setState({ facilityItem });
  }

  renderStationDetails() {
    const { stationDetailsList } = this.state.facilityItem;

    const optionList = stationDetailsList.map((details: any, i: number) => (
      <Fieldset className="row grouped-fields-block" key={String(i)}>
        <Fieldset className="col-md-6 col-lg-4 col-xl-3">
          <Text
            name="Name"
            label="Name"
            value={details.Name}
            onSetValue={(name, value) => this.onDetailsChanged(name, value, i)}
          />
        </Fieldset>
        <Fieldset className="col-md-6 col-lg-4 col-xl-2">
          <NumberInput
            name="Fax"
            label="Fax number"
            value={details.Fax}
            onSetValue={(name, value) => this.onDetailsChanged(name, value, i)}
            format="###-###-####"
          />
        </Fieldset>
        <Fieldset className="col-md-6 col-lg-4 col-xl-2">
          <NumberInput
            name="Phone"
            label="Phone"
            value={details.Phone}
            onSetValue={(name, value) => this.onDetailsChanged(name, value, i)}
            format="###-###-####"
          />
        </Fieldset>
        <Fieldset className="col-md-6 col-lg-4 col-xl-2">
          <NumberInput
            name="extension"
            label="Extension"
            value={details.extension}
            onSetValue={(name, value) => this.onDetailsChanged(name, value, i)}
            format="#####"
          />
        </Fieldset>
        <Fieldset className="col-md-6 col-lg-4 col-xl-2">
          <Text
            name="Email"
            label="Email"
            value={details.Email}
            onSetValue={(name, value) => this.onDetailsChanged(name, value, i)}
          />
        </Fieldset>

        <div className="grouped-fields-controls">
          <IconButton onClick={this.onAddRowClicked(i)}>
            <i className="bi bi-plus-circle" />
          </IconButton>
          <IconButton onClick={this.onRemoveRowClicked(i)}>
            <i className="bi bi-trash" />
          </IconButton>
        </div>
        <Fieldset className="comment col-lg-7">
          <TextArea
            name="Comment"
            label="Comment"
            value={details.Comment}
            onSetValue={(name, value) => this.onDetailsChanged(name, value, i)}
          />
        </Fieldset>
      </Fieldset>
    ));
    return optionList;
  }

  onSetDate(name: string, value: any) {
    const { facilityData } = this.state.facilityItem;

    const facilityItem = {
      ...this.state.facilityItem,
      facilityData: {
        ...facilityData,
        start_dt: value,
      },
    };
    this.setState({ facilityItem });
  }

  onFacilityTypeChange = (name: string, value: number) => {
    const { orderMapToProvider } = storeProviderType;
    const orderType = orderMapToProvider[value];
    const selected: Array<{ OrdId: string }> = [...(orderType || [])];

    const facilityItem = Object.assign({}, this.state.facilityItem);
    facilityItem.facilityData = Object.assign({}, facilityItem.facilityData);
    let eligibilityDisabled = false;
    if (value === 4) {
      facilityItem.facilityData.eligibility_status = 'N';
      eligibilityDisabled = true;
    }
    facilityItem.OrderType = {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
    };
    if (facilityItem.OrderType !== null) {
      const len = selected.length;
      for (let i = 0; i < len; i++) {
        facilityItem.OrderType[parseInt('' + selected[i].OrdId)] = true;
      }
    }
    facilityItem.facilityData.facility_type = value;
    this.setState({
      facilityItem,
      eligibilityDisabled: eligibilityDisabled,
    });
  };

  render() {
    const { state, props } = this;
    const { facilityItem } = state;
    const facilityData = facilityItem.facilityData || {};

    return (
      <div className="facility-tab col-sm-12">
        {this.props.blocked ? <div className="overlay" /> : null}
        <Form
          className="row"
          model={facilityItem}
          submit={this.handleSubmit}
          errors={state.errors}>
          <Fieldset
            onSetValue={(n, v, e) => this.onCollectFacilityDataValues(n, v, e)}
            formModel={facilityData}>
            <Fieldset className="row">
              <Fieldset className="col-sm-6">
                <Text
                  name="facility_nm"
                  label="Facility name"
                  validations="required"
                  errorMessages="Please, enter the facility name!"
                />
                <Text name="facility_alias_nm" label="Facility Alias Name" />
                <Text name="bed_cnt" label="Bed count" />
                <NpiInput name="facility_npi" label="Facility NPI" />
                {UserProfileStore.getUserType() === 'U' && (
                  <Fieldset className="row">
                    <Checkbox
                      attr={{ id: 'integration_active' }}
                      className="col-lg-4"
                      name="integration_active"
                      label="Integrations Active"
                    />
                    <SendingApplicationDropdown
                      name="integration_id"
                      attr={{
                        disabled: !facilityData.integration_active,
                        isMulti: true,
                      }}
                      className="col-lg-8"
                      value={
                        facilityData.integration_active
                          ? facilityData.integration_id
                          : []
                      }
                      validations={
                        facilityData.integration_active ? 'required' : ''
                      }
                      label="Integration Name"
                    />
                  </Fieldset>
                )}
                <DropdownPureDivision
                  name="division_id"
                  label="Division"
                  value={facilityData.division_id}
                  onChange={(value: number) =>
                    this.onCollectFacilityDataValues('division_id', value, '')
                  }
                />
              </Fieldset>
              <Fieldset className="col-sm-6">
                <ProviderTypeDropdown
                  name="facility_type"
                  label="Provider Type"
                  validations="required:lazy"
                  errorMessages="Please, select a provider type!"
                  onSetValue={this.onFacilityTypeChange}
                />
                <Fieldset
                  onSetValue={this.onSetOrderType.bind(this)}
                  formModel={facilityItem}>
                  <OrderTypeCheckboxes
                    className="part-inline"
                    name="OrderType"
                    label="Order type"
                    validations="required:checkboxes"
                    errorMessages="Please, select at least one option!"
                  />
                </Fieldset>
                <TextCalendar
                  name="start_dt"
                  label="Start date"
                  onSetValue={this.onSetDate.bind(this)}
                />
                <Radio
                  name="eligibility_status"
                  className="part-inline"
                  label="Eligibility required"
                  attr={{
                    disabled: state.eligibilityDisabled,
                    id: 'eligibility_status',
                  }}
                  options={RadioOptions.yesNo}
                />
                <Radio
                  name="facility_stat"
                  className="part-inline"
                  label="Facility status"
                  attr={{ id: 'facility_stat' }}
                  options={RadioOptions.active}
                  validations="required"
                  errorMessages="Please, select the status!"
                />
                <Checkbox
                  label="Vaccination Required"
                  name="isVaccinationRequired"
                />
                <Text
                  name="external_facility_id"
                  label="External facility ID"
                />
              </Fieldset>
            </Fieldset>
            <legend>Contact Information</legend>
            <Fieldset className="row">
              <Fieldset className="col-sm-6">
                <Text
                  name="address1"
                  label="Address 1"
                  validations="required"
                  errorMessages="Please, enter the address!"
                />
                <Text name="address2" label="Address 2" />
                <ZipCodeInput
                  name="zipcode"
                  label="Zip Code"
                  validations="required"
                />
                <NumberInput
                  name="phone"
                  label="Phone"
                  format="###-###-####"
                  validations="required"
                  errorMessages="Please, enter the phone!"
                />
                <Text
                  name="nursing_nm"
                  label="Nursing contact"
                  validations="required"
                  errorMessages="Please, enter a contact!"
                />
                <Text name="nursing_email" label="Nursing email" />
                <Text name="website" label="Website" />
              </Fieldset>
              <Fieldset className="col-sm-6">
                <Text name="country" label="Country" />
                <Text
                  name="city"
                  label="City"
                  validations="required"
                  errorMessages="Please, enter the city!"
                />
                <StateShortNameDropdown
                  name="state"
                  label="State"
                  validations="required"
                  errorMessages="Please, enter the state!"
                />
                <NumberInput name="fax" label="Fax" format="###-###-####" />
                <Text name="admin_nm" label="Admin name" />
                <Text name="admin_email" label="Admin email" />
              </Fieldset>
            </Fieldset>
            <TextArea
              name="alert_facility"
              label={
                <>
                  Alert{' '}
                  <span className="fw-normal text-secondary">
                    (Alerts will be displayed in the User interface, hover over
                    the icon{' '}
                    <i className="bi bi-exclamation-triangle-fill text-danger" />{' '}
                    to see Alerts)
                  </span>
                </>
              }
              attr={{ rows: 6 }}
            />
            <TextArea
              name="comment_facility"
              label="Comments"
              attr={{ rows: 6 }}
            />
          </Fieldset>

          <legend>Location</legend>
          <Fieldset
            className="mb-3"
            onSetValue={this.onCollectLocationValues.bind(this)}
            formModel={facilityItem.location}>
            <Fieldset className="row">
              <RegionDropdown
                name="region_id"
                label="Region"
                className="col-md-6 col-lg-4"
                validations="required:lazy"
                errorMessages="Please, select a region!"
              />
              <StateDropdown
                name="state_id"
                label="State"
                className="col-md-6 col-lg-4"
                validations="required:lazy"
                errorMessages="Please, select a state!"
              />
              <Select
                name="refid"
                label="Location"
                className="col-md-6 col-lg-4"
                options={props.locationList}
                validations="required:lazy"
                errorMessages="Please, select a location!"
              />
            </Fieldset>
          </Fieldset>
          <legend>Station Details</legend>
          {this.renderStationDetails()}
          <FormControl className="mt-3">
            <Button variant="warning" text="Reset" onClick={this.reset} />
            <Button type="submit" text="Submit" />
          </FormControl>
        </Form>
      </div>
    );
  }

  reset = () => {
    this.setState({ facilityItem: this.props.facilityItem });
  };

  onSetOrderType(name: string, values: any, errorMessages: any) {
    const state = IterableUtils.extend(true, {}, this.state);
    state.facilityItem[name] = values;
    state.errors[name] = errorMessages;
    this.setState(state);
  }

  onCollectFacilityDataValues(name: string, value: any, errorMessages: any) {
    const state = IterableUtils.extend(true, {}, this.state);
    state.facilityItem.facilityData[name] = value;
    if (name === 'integration_active' && !value) {
      state.facilityItem.facilityData.integration_id = [];
      state.errors.integration_id = [];
    }
    state.errors[name] = errorMessages;
    this.setState(state);
  }

  onCollectLocationValues(name: string, value: any, errorMessages: any) {
    const state = IterableUtils.extend(true, {}, this.state);
    state.facilityItem.location[name] = parseInt(value);
    state.errors[name] = errorMessages;
    this.setState(
      state,
      name !== 'refid'
        ? () => {
            FacilityPageActions.loadLocationList(
              state.facilityItem.location.region_id,
              state.facilityItem.location.state_id
            ).then((response) => {
              state.locationList = response || [];
              this.setState(state);
            });
          }
        : null
    );
  }

  handleSubmit = async (model: any, hasErrors: boolean, errors: any) => {
    if (hasErrors) {
      this.setState({ errors });
    } else {
      const { facilityData, ...facilityItem } = this.state.facilityItem;
      const payload = {
        ...facilityItem,
        facilityData: {
          ...facilityData,
          integration_id: facilityData.integration_id.length
            ? facilityData.integration_id
            : null,
          dispatch_region_nm: facilityItem.location.refid,
        },
      };
      const errorsFromBackend = await this.props.submitFacilityTab(payload);

      this.setState({
        errors: {
          ...errors,
          ...errorsFromBackend,
        },
      });
    }
  };
}
