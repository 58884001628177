import {
  makeObservable,
  observable,
  computed,
  action,
  set,
  remove,
  toJS,
} from 'mobx';
import { ColumnState } from 'ag-grid-community';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import { removeSlashes, deleteIdFromUrl, strMapToObj } from 'utils/StringUtils';

const prepareGridsData = (data: GridSettingResponseType[]) => {
  try {
    return data.map((item) => {
      const obj = item.grid_columns
        ? JSON.parse(item.grid_columns)
        : item.grid_columns;
      const grid_columns =
        obj instanceof Map
          ? obj
          : new Map<string, boolean>(Object.entries(obj));

      const column_drag_order = item.column_drag_order
        ? JSON.parse(item.column_drag_order)
        : null;

      const column_sort_order = item.column_sort_order
        ? JSON.parse(item.column_sort_order)
        : null;

      return {
        ...item,
        grid_columns,
        column_drag_order,
        column_sort_order,
        grid_size: Number(item.grid_size),
        refid: Number(item.refid),
        user_id: Number(item.user_id),
      };
    });
  } catch (e: any) {
    return [];
  }
};

interface GridSettingResponseType {
  column_drag_order: null | string;
  column_sort_order: null | string;
  grid_columns: string;
  grid_filters: string;
  grid_size: string;
  page_url: string;
  refid: string;
  table_id: string;
  user_id: string;
}

export interface GridSettingType {
  column_drag_order: null | string[];
  column_sort_order: null | { column: string; direction: number };
  grid_columns: Map<string, boolean>;
  grid_filters: string;
  grid_size: number;
  page_url: string;
  refid: number;
  table_id: string;
  user_id: number;
}

interface SettingsPayload {
  gridId: string;
  size: number;
  shownColumns: Map<string, boolean>;
  columnsOrder: any;
  sort: any;
}

interface GridStatePayload {
  gridId: string;
  pageSize: number;
  columnState: ColumnState[];
}

class Grid {
  fetchingSettings: boolean;
  settingsList: GridSettingType[] = [];
  settingsSearch: string = '';
  settingForDelete: string = '';
  tempFilters: { [key: string]: Record<string, any> } = {};

  constructor() {
    makeObservable(this, {
      fetchingSettings: observable,
      settingsList: observable,
      settingsSearch: observable,
      settingForDelete: observable,
      tempFilters: observable,

      settings: computed,

      setFetchingSettings: action,
      setSettings: action,
      setSettingForDelete: action,
      saveFilter: action,
      setSearchSettings: action.bound,
      clearSettingForDelete: action.bound,
    });
  }

  setFetchingSettings(fetching: boolean) {
    this.fetchingSettings = fetching;
  }

  setSettings(settings: GridSettingType[]) {
    this.settingsList = settings;
  }

  setSearchSettings(search: string) {
    this.settingsSearch = search;
  }

  setSettingForDelete(gridId: string) {
    this.settingForDelete = gridId;
  }

  // TODO: delete this method after flux will delete and filter will save to the pages store
  saveFilter(gridId: string, filter: Record<string, any>) {
    set(this.tempFilters, gridId, filter);
  }

  clearSettingForDelete() {
    this.settingForDelete = null;
  }

  get settings() {
    const search = this.settingsSearch.startsWith('/')
      ? this.settingsSearch.substring(1)
      : this.settingsSearch;

    return this.settingsList.filter(({ page_url }) =>
      page_url.toLowerCase().startsWith(search.toLowerCase())
    );
  }

  getDataFromFlux(response: GridSettingResponseType[]) {
    const settings = prepareGridsData(response);

    this.setSettings(settings);
  }

  async getGridSettings() {
    this.setFetchingSettings(true);
    try {
      const response = await apiRequest<GridSettingResponseType[]>({
        url: 'userlogin.LoginMaster.loadGridsData',
      });

      const settings = response.map((grid, i) => {
        const columns = grid.grid_columns
          ? Object.entries(JSON.parse(grid.grid_columns))
          : null;

        const gridColumns: Map<string, boolean> = columns
          ? new Map(columns)
          : new Map();

        const columnDragOrder = grid.column_drag_order
          ? JSON.parse(grid.column_drag_order)
          : null;

        const columnSortOrder = grid.column_sort_order
          ? JSON.parse(grid.column_sort_order)
          : null;

        return {
          ...grid,
          column_sort_order: columnSortOrder,
          grid_columns: gridColumns,
          column_drag_order: columnDragOrder,
          grid_size: Number(grid.grid_size) || 10,
          refid: Number(grid.refid),
          user_id: Number(grid.user_id),
        };
      });
      this.setSettings(settings);
    } catch (e: any) {
      this.setSettings([]);
    } finally {
      this.setFetchingSettings(false);
    }
  }

  deleteGridSetting = async (gridId: string = this.settingForDelete) => {
    try {
      const result = await apiRequest<'S' | 'SE'>({
        url: 'userlogin.LoginMaster.deleteGridData',
        data: {
          gridId,
        },
      });
      if (result === 'S') {
        this.removeSetting(gridId);
        Notification.success(`Grid settings were reset!`);
        return true;
      }

      throw Error('Error occurred');
    } catch (e: any) {
      Notification.danger(`Can't reset grid settings!`);
      return false;
    }
  };

  deleteAllGridSetting = async () => {
    try {
      const result = await apiRequest<'S' | 'SE'>({
        url: 'userlogin.LoginMaster.deleteAllGridData',
      });
      if (result === 'S') {
        Notification.success('Settings was deleted');
        return true;
      }

      throw Error('Error occurred');
    } catch (e: any) {
      Notification.danger("Can't delete settings");
      return false;
    }
  };

  async saveGridSettings({
    gridId,
    size,
    shownColumns,
    columnsOrder,
    sort,
  }: SettingsPayload) {
    const pathname = deleteIdFromUrl(removeSlashes(document.location.pathname));

    try {
      const data = {
        gridId,
        page_url: deleteIdFromUrl(pathname),
        size,
        columns: JSON.stringify(strMapToObj(shownColumns)),
        column_drag_order: JSON.stringify(columnsOrder),
        column_sort_order: JSON.stringify(sort),
      };

      const response = await apiRequest<string>({
        url: 'userlogin.LoginMaster.saveGridData',
        data: { data },
      });

      if (Number(response)) {
        Notification.success('Grid settings saved');
        const setting = {
          column_drag_order: columnsOrder,
          column_sort_order: sort,
          grid_columns: shownColumns,
          grid_filters: null as null,
          grid_size: size,
          page_url: data.page_url,
          refid: performance.now(),
          table_id: gridId,
          user_id: UserProfileStore.getUserId(),
        };
        this.addSetting(setting);
        return true;
      }
      throw Error('Error occurred!');
    } catch (e: any) {
      Notification.danger('Fail of saving Grid settings!');
      return false;
    }
  }

  async saveGridState({ gridId, pageSize, columnState }: GridStatePayload) {
    const pathname = deleteIdFromUrl(removeSlashes(document.location.pathname));

    try {
      const data = {
        gridId,
        page_url: deleteIdFromUrl(pathname),
        size: pageSize,
        columns: '',
        column_drag_order: JSON.stringify(columnState),
        column_sort_order: '',
      };

      const response = await apiRequest<string>({
        url: 'userlogin.LoginMaster.saveGridData',
        data: { data },
      });

      if (Number(response)) {
        const setting = {
          column_drag_order: columnState,
          column_sort_order: '',
          grid_columns: null as null,
          grid_filters: null as null,
          grid_size: pageSize,
          page_url: data.page_url,
          refid: performance.now(),
          table_id: gridId,
          user_id: UserProfileStore.getUserId(),
        };
        // @ts-ignore
        this.addSetting(setting);
      }
      throw Error('Error occurred!');
    } catch (e: any) {}
  }

  getPageSize(id: string) {
    const settings = this.settingsList.find(({ table_id }) => table_id === id);
    return settings?.grid_size || 10;
  }

  getSetting<T extends any>(id: string): T {
    if (!id) return null;

    const setting = this.settingsList.find(({ table_id }) => table_id === id);

    return setting ? (toJS(setting) as T) : null;
  }

  addSetting(setting: GridSettingType) {
    const index = this.settingsList.findIndex(
      ({ table_id }) => table_id === setting.table_id
    );

    const key = ~index ? index : this.settingsList.length;

    set(this.settingsList, { [key]: setting });
  }

  removeSetting(gridId: string) {
    const index = this.settingsList.findIndex(
      ({ table_id }) => table_id === gridId
    );
    if (~index) remove(this.settingsList, String(index));
  }

  // TODO: delete this method after flux will delete and filter will save to the pages store
  getFilter<T>(gridId: string, defaultFilter: T) {
    const setting = this.tempFilters[gridId];

    return setting ? (toJS(setting) as T) : defaultFilter;
  }
}

export const storeGrid = new Grid();
