interface PropsType {
  data: [string, string | number][];
}

const ExpenseSummary = ({ data }: PropsType) => {
  const charge = data.length ? data[1]?.[1] || 0 : 0;

  const payment = data.length ? data[2]?.[1] || 0 : 0;

  return (
    <ul className="font-large">
      <li className="mb-3">
        <b>Total Charge:</b> {`$${charge}`}
      </li>
      <li className="mb-3">
        <b>Total Payment:</b> {`$${payment}`}
      </li>
    </ul>
  );
};

export default ExpenseSummary;
