import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormFinding from './components/Form';

import {
  storeFinding,
  FormModel,
} from 'stores/_mobx/systemSetup/masterSetting/finding';
import { URL_FINDING } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const FindingAddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: FormModel) =>
    storeFinding.addFinding(payload).then((response) => {
      if (!response) {
        history.push(URL_FINDING);
      }
    });

  return (
    <>
      <Title title="Add Finding" />
      <FormFinding backUrl={URL_FINDING} onSubmit={handleSubmit} />
    </>
  );
};

export default FindingAddPage;
