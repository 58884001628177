import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormSpecialty from './components/Form';

import {
  storeSpecialty,
  SpecialtyFormModel,
} from 'stores/_mobx/systemSetup/masterSetting/specialty';
import { URL_SPECIALTY } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const SpecialtyAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: SpecialtyFormModel) =>
    storeSpecialty.addSpecialty(data).then((error) => {
      if (error) return error;
      history.push(URL_SPECIALTY);
    });

  return (
    <>
      <Title title="Add Specialty" />
      <FormSpecialty backUrl={URL_SPECIALTY} onSubmit={handleSubmit} />
    </>
  );
};

export default SpecialtyAddPage;
