import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormPerDiem, { FormModel } from './components/Form';

import { storePerDiem } from 'stores/_mobx/systemSetup/masterSetting/perDiem';
import { URL_PER_DIEM } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const PerDiemAddPage = ({ history }: PropsType) => {
  const handleSubmit = (data: FormModel) =>
    storePerDiem.addPerDiem(data).then((response) => {
      if (response) return response;
      history.push(URL_PER_DIEM);
    });

  return (
    <>
      <Title title="Add Per Diem" />
      <FormPerDiem backUrl={URL_PER_DIEM} onSubmit={handleSubmit} />
    </>
  );
};

export default PerDiemAddPage;
