export const URL_INBOX = '/messages/inbox';
export const URL_INBOX_MESSAGE_DETAIL = `${URL_INBOX}/:id`;
export const URL_INBOX_REPLY = `${URL_INBOX}/:id/reply`;
export const URL_INBOX_FORWARD = `${URL_INBOX}/:id/forward`;

export const URL_COMPOSE = '/messages/compose';

export const URL_DRAFT = '/messages/drafts';
export const URL_DRAFT_ITEM = `${URL_DRAFT}/:id`;

export const URL_SENT_MAIL = '/messages/sent-items';
export const URL_SENT_MESSAGE_DETAIL = `${URL_SENT_MAIL}/:id`;
export const URL_SENT_FORWARD = `${URL_SENT_MAIL}/:id/forward`;

export const URL_TRASH = '/messages/trash';
export const URL_TRASH_MESSAGE_DETAIL = `${URL_TRASH}/:id`;

export const URL_REMINDER = '/reminders/reminders';
export const URL_REMINDER_MESSAGE_DETAIL = `${URL_REMINDER}/:id`;
export const URL_REMINDER_ADD = `${URL_REMINDER}/add`;
