import { Control, useFieldArray, UseFormWatch } from 'react-hook-form';

import { IconButton } from 'components/button';
import DropdownMirthChannel from 'components/project/dropdown/MirthChannel';

import { UpdateDFTSettingsPayload } from 'stores/_mobx/systemSetup/masterSetting/integrationsDft';
import { storeDropdownMirthChannel } from 'stores/_mobx/dropDown/mirthChannels';
export const defaultChannelValue = {
  id: 0,
  channel: {
    channelID: '',
  },
};
interface PropsType {
  control: Control<UpdateDFTSettingsPayload>;
  watch: UseFormWatch<UpdateDFTSettingsPayload>;
}

const Channels = ({ control, watch }: PropsType) => {
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'channels',
  });
  const { options } = storeDropdownMirthChannel;
  const currentChannels = watch('channels');

  const handleClickReplace = () => {
    replace([defaultChannelValue]);
  };

  const handleClickAddChannel = () => {
    append(defaultChannelValue);
  };

  const isAddChannelsButtonDisabled =
    currentChannels.length >= options.length ||
    currentChannels.some(({ channel: { channelID } }) => !channelID);

  return (
    <>
      <small className="mb-2 d-block text-secondary">Outbound Channel ID</small>
      {fields.map((field, idx) => (
        <fieldset className="row mb-3" key={field.id}>
          <span style={{ width: '100px', marginTop: '5px' }}>Channel ID</span>
          <DropdownMirthChannel
            key={performance.now().toString(16)}
            className="col-md-6 col-lg-4 mb-0"
            name={`channels.${idx}.channel.channelID`}
            required={true}
            control={control}
            currentChannels={currentChannels}
            index={idx}
          />
          <div className="d-flex text-primary col-sm-auto gap-3">
            <IconButton
              disabled={isAddChannelsButtonDisabled}
              onClick={handleClickAddChannel}>
              <i className="bi bi-plus-circle" />
            </IconButton>
            <IconButton
              disabled={fields.length <= 1}
              onClick={
                fields.length > 1 ? () => remove(idx) : handleClickReplace
              }>
              <i className="bi bi-trash" />
            </IconButton>
          </div>
        </fieldset>
      ))}
    </>
  );
};

export default Channels;
