import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import useDebounce from 'components/hook/useDebounce';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import { PureInput } from 'components/form/textField';
import { LayoutSideTitle } from 'components/layout';

import { storePayerPlan } from 'stores/_mobx/systemSetup/masterSetting/payerPlan';
import { MS_PAYER_PLAN as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_PAYER_PLAN,
  URL_PAYER_PLAN_ADD,
} from 'constant/path/systemSetup';

const renderControl = (id: any) => (
  <div className="control">
    <Link className="bi bi-pencil" to={`${URL_PAYER_PLAN}/edit/${id}`} />
    <IconButton onClick={() => storePayerPlan.setIdForDelete(id)}>
      <i className="bi bi-trash" />
    </IconButton>
  </div>
);

const columns = [
  { name: 'plan_name', title: 'Plan name' },
  { name: 'payername', title: 'Payer name' },
  { name: 'comments', title: 'Comments' },
  {
    name: 'refid',
    title: 'Action',
    className: 'width-75',
    render: renderControl,
  },
];

const PayerPlanListPage = () => {
  const {
    payerPlans,
    payerPlansTotal,
    fetching,
    deletePayerId,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storePayerPlan;

  const debouncedAction = useDebounce(setPaginationToStart);

  const handleSetPlanName = (planName: string) => {
    storePayerPlan.setFilter(planName);
    debouncedAction();
  };

  const onDeleteConfirmation = () => {
    storePayerPlan.deletePayerPlan().then((isSucceed) => {
      if (isSucceed) storePayerPlan.getPayerPlansList();
    });
  };

  useEffect(() => {
    storePayerPlan.getPayerPlansList();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Payer Plan">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Plan name"
        className="col-sm-12 col-md-6 col-lg-4"
        value={filter}
        onChange={handleSetPlanName}
      />

      <Grid
        id="payer_plan_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={payerPlans}
        dataSourceCount={payerPlansTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_PAYER_PLAN_ADD} />
        }
      />

      {deletePayerId !== null && (
        <DialogConfirm
          onCancel={storePayerPlan.clearIdForDelete}
          onApprove={onDeleteConfirmation}>
          Are you sure you want to delete?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(PayerPlanListPage);
