import { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
// @ts-ignore
import useFluxStore from 'flux-hooks';

import EmailFormWrapper from '../components/EmailFormWrapper';

import DraftPageActions from 'actions/messages/draft/DraftPageActions';
import DraftPageStore from 'stores/messages/draft/DraftPageStore';
import { DraftMessageDetailsType } from 'services/messages/draft/DraftsService';
import { emailDraftFormatter } from 'utils/emailValuesFormatter';
import { URL_DRAFT } from 'constant/path/messages';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const DraftDetailsPage = ({ match: { params } }: PropsType) => {
  const [fetching, setFetching] = useState<boolean>(true);

  const messageDetails: DraftMessageDetailsType | null = useFluxStore(
    DraftPageStore,
    (prevState: any, store: any) => store.getState().messageDetails
  );

  const defaultValues = useMemo(
    () =>
      messageDetails
        ? emailDraftFormatter(messageDetails, Number(params.id))
        : undefined,
    [messageDetails, params.id]
  );

  useEffect(() => {
    DraftPageActions.getDraftMessageDetails(params.id).then(() => {
      setFetching(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EmailFormWrapper
      title="Reply"
      fetching={fetching}
      defaultValues={defaultValues}
      backLink={URL_DRAFT}
    />
  );
};

export default DraftDetailsPage;
