import React from 'react';

import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'components/modal/dialog';
import { Grid, TPagination } from 'components/grid';
import FormCells from 'components/grid/FormCells';
import { Button } from 'components/button';

import StringUtils from 'utils/StringUtils';
import convertToCsv from 'utils/convertToCsv';
import { downloadCsv } from 'utils/downloadFile';

interface PropsType {
  title: string;
  onAjax: boolean;
  dataSource: Array<any>;
  dataSourceCount: number;
  pagination: TPagination;
  errorsStorage: any;
  onClose: () => void;
  onUpdate: () => void;
  onPaginationChange: (pagination: TPagination) => void;
  updateData: (
    rowId: number,
    name: string,
    value: any,
    errors: string[]
  ) => void;
}

class GridFeeSchedule extends React.Component<PropsType> {
  columns = [
    { name: 'cptCode', title: 'CPT Code', className: 'width-100' },
    {
      name: 'tech_fee',
      title: 'Arranged Rate',
      className: 'width-100',
      render: this.moneyFactory('tech_fee'),
    },

    {
      name: 'pro_fee',
      title: 'Purchased Services',
      render: this.moneyFactory('pro_fee'),
    },
    {
      name: 'global_fee',
      title: 'Other Rate',
      render: this.moneyFactory('global_fee'),
    },
    {
      name: 'insurance_fee',
      title: 'Insurance',
      render: this.moneyFactory('insurance_fee'),
    },

    {
      name: 'insurance_pro_fee',
      title: 'CPT Description',
      render: this.textFactory('insurance_pro_fee', 'min:1 max:55'),
    },
  ];

  textFactory(name: string, validations: any = null) {
    return FormCells.textInputFactory(
      name,
      validations,
      this,
      'refid',
      this.props.errorsStorage
    );
  }

  moneyFactory(name: string) {
    return this.textFactory(name, {
      custom: (value: string) => StringUtils.isDollarPrice(value),
    });
  }

  updateTableCell(rowId: number, name: string, value: any, errors: string[]) {
    if (rowId) {
      this.props.updateData(rowId, name, value, errors);
    }
  }

  handleExport = () => {
    const { dataSource } = this.props;

    const columns = this.columns.map(({ title, name }) => ({
      title,
      name,
    }));

    const csvData = convertToCsv(dataSource, columns);

    const date = new Date();

    const fileName = `State_Medicare${date.getFullYear()}_${
      date.getMonth() + 1
    }_${date.getDate()}_${date.getHours()}-${date.getMinutes()}.csv`;

    downloadCsv(csvData, fileName);
  };

  render() {
    return (
      <Dialog size="extraLarge" handleClose={this.props.onClose}>
        <DialogHeader title={this.props.title} onClose={this.props.onClose} />

        <DialogBody className={this.props.onAjax ? ' on-ajax' : ''}>
          <Grid
            wrapperClass="fee-schedules"
            dataSource={this.props.dataSource}
            dataSourceCount={this.props.dataSourceCount}
            pagination={this.props.pagination}
            onPaginationChange={this.props.onPaginationChange}
            columns={this.columns}
          />
        </DialogBody>

        <DialogFooter>
          <Button
            key="0"
            disabled={this.props.onAjax}
            onClick={this.props.onUpdate}>
            Update
          </Button>
          <Button disabled={this.props.onAjax} onClick={this.handleExport}>
            Export
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }
}

export default GridFeeSchedule;
