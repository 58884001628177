import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed,
} from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import FilterNameAndState, {
  FilterType,
} from 'stores/_mobx/options/filterNameAndStatus';

const messagePrettier = (availableMessage: Record<number, boolean>) => {
  const messages = Object.entries(availableMessage)
    .reduce((prev, [key, value]) => {
      const idx = Number(key);
      prev[idx] = value;
      return prev;
    }, [])
    .map((el) => (el ? 'Y' : 'N'))
    .join('|');

  return messages;
};
export const availableMessage = [
  { label: 'Patient name', value: 0 },
  { label: 'Patient DOB', value: 1 },
  { label: 'Patient gender', value: 2 },
  { label: 'Patient facility', value: 3 },
  { label: 'Homecare patient address and phone', value: 4 },
  { label: 'Patient room number', value: 5 },
  { label: 'Station details', value: 6 },
  { label: 'Modality', value: 7 },
  { label: 'Exam description', value: 8 },
  { label: 'CPT description', value: 9 },
  { label: 'Diagnosis', value: 10 },
  { label: 'Isolation precautions', value: 11 },
  { label: 'Priority', value: 12 },
  { label: 'Notes', value: 13 },
  { label: 'Accession Number', value: 14 },
  { label: 'MRN', value: 15 },
  { label: 'Ordering physician', value: 16 },
  { label: 'Radiology group', value: 17 },
];

export const formDefaultValue: PhoneFormModel = {
  operatorId: 0,
  phoneNumber: '',
  status: 'A',
  description: '',
  id: 0,
  regions: [],
  availableMessage: availableMessage.reduce(
    (prev, { value }) => ({ [value]: false }),
    {}
  ),
};

export interface PhoneType {
  carrier_id: number;
  mobileop_name: string;
  order_status: 'A' | 'S';
  phone_desc: string;
  phone_no: string;
  refid: number;
}

interface PhoneResponseType extends PhoneType {
  dispatcharr: { data: string; label: string }[];
  messagefield: ('Y' | 'N')[];
}

export interface PhoneFormModel {
  id: number;
  description: string;
  phoneNumber: string;
  status: 'A' | 'S';
  operatorId: number;
  regions: { value: number; label: string }[];
  availableMessage: Record<number, boolean>;
}

interface OptionResponseType {
  label: string;
  data: string;
}

interface OptionType {
  label: string;
  value: number;
}

class Phone {
  fetching: boolean = false;
  fetchingRegions: boolean = false;
  fetchingOptions: boolean = false;
  phoneList: PhoneType[] = [];
  phoneTotal: number = 0;
  phone: PhoneFormModel = formDefaultValue;
  idForDelete: number = 0;
  noteId: number = 0;
  regions: OptionType[] = [];
  options: OptionType[] = [];

  filter: FilterNameAndState = new FilterNameAndState();
  page: Pagination = new Pagination({ id: 'phone_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingRegions: observable,
      fetchingOptions: observable,
      phoneList: observable,
      phoneTotal: observable,
      phone: observable,
      idForDelete: observable,
      noteId: observable,
      regions: observable,
      options: observable,

      availableRegions: computed,

      setFetching: action,
      setFetchingRegions: action,
      setFetchingOptions: action,
      setPhoneList: action,
      setPhoneTotal: action,
      setPhone: action,
      setNoteId: action,
      setIdForDelete: action.bound,
      clearIdForDelete: action.bound,
      clearPhone: action.bound,
      clearNoteId: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFetchingRegions = (fetching: boolean) => {
    this.fetchingRegions = fetching;
  };

  setFetchingOptions = (fetching: boolean) => {
    this.fetchingOptions = fetching;
  };

  setPhoneList(list: PhoneType[]) {
    this.phoneList = list;
  }

  setPhoneTotal(count: number) {
    this.phoneTotal = count;
  }

  setPhone(phone: PhoneFormModel) {
    this.phone = phone;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setNoteId(id: number) {
    this.noteId = id;
  }

  clearIdForDelete() {
    this.idForDelete = null;
  }

  clearPhone() {
    this.phone = formDefaultValue;
    this.regions = [];
  }

  clearNoteId() {
    this.noteId = 0;
  }

  get availableRegions() {
    const { regions } = this.phone;
    return this.regions.filter(
      ({ value }) => !regions.some(({ value: id }) => id === value)
    );
  }

  async getPhoneCount(filter: FilterType) {
    try {
      const count = await apiRequest<string>({
        url: 'vehicle.PhoneMaster.PhoneNumberTotalCount',
        data: [filter.name, filter.state],
      });

      this.setPhoneTotal(Number(count) || 0);
    } catch (e) {
      this.setPhoneTotal(0);
    }
  }

  async getPhoneList(filter: FilterType) {
    const { pagination } = this.page;

    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.name,
      filter.state,
    ];

    try {
      const response = await apiRequest<PhoneType[]>({
        url: 'vehicle.PhoneMaster.PhoneNumberList',
        data,
      });

      const list = response.map((phone) => ({
        ...phone,
        carrier_id: Number(phone.carrier_id),
        refid: Number(phone.refid),
      }));
      this.setPhoneList(list);
    } catch (e) {
      this.setPhoneList([]);
    }
  }

  getPhoneMain = () => {
    this.setFetching(true);

    const filter = this.filter.options;

    const promiseList = this.getPhoneList(filter);

    const promiseCount = this.getPhoneCount(filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getPhone(id: number) {
    this.setFetching(true);

    try {
      const [{ dispatcharr, messagefield, ...rest }] = await apiRequest<
        [PhoneResponseType]
      >({
        url: 'vehicle.PhoneMaster.GetViewPhoneNumber',
        data: [id],
      });

      const phone: PhoneFormModel = {
        id,
        description: rest.phone_desc,
        phoneNumber: rest.phone_no,
        status: rest.order_status,
        operatorId: Number(rest.carrier_id),
        availableMessage: messagefield.reduce(
          (prev, el, idx) => ({
            ...prev,
            [idx]: el === 'Y',
          }),
          {}
        ),
        regions: dispatcharr.map((region) => ({
          label: region.label,
          value: Number(region.data),
        })),
      };

      this.setPhone(phone);
    } catch (e) {
      this.clearPhone();
    } finally {
      this.setFetching(false);
    }
  }

  async addPhone({ availableMessage, regions, ...phone }: PhoneFormModel) {
    const messages = messagePrettier(availableMessage);

    const data = [
      phone.phoneNumber,
      phone.description,
      phone.operatorId,
      phone.status,
      regions.map((region) => ({
        label: region.label,
        data: region.value,
      })),
      messages,
    ];

    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'vehicle.PhoneMaster.AddPhoneNumber',
        data,
      });
      if (result === 'S') {
        Notification.success('Phone was added successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'This phone number is already in use!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updatePhone({ regions, availableMessage, ...phone }: PhoneFormModel) {
    const messages = messagePrettier(availableMessage);

    const data = [
      phone.id,
      phone.phoneNumber,
      phone.description,
      phone.operatorId,
      phone.status,
      regions.map((region) => ({
        label: region.label,
        data: region.value,
      })),
      messages,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'vehicle.PhoneMaster.EditPhoneNumber',
        data,
      });

      if (result === 'S') {
        Notification.success('Phone was updated successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'This phone number is already in use!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  changePhoneStatus = async (ids: number[]) => {
    this.setFetching(true);
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'vehicle.PhoneMaster.changeStatus',
        data: [ids],
      });
      if (response === 'S') {
        const { length } = ids;

        const statusMsg = this.filter.options.isActive
          ? 'deactivated'
          : 'activated';

        Notification.success(
          `${length} Phone${length > 1 ? 's were' : 'was'} ${statusMsg}`
        );
        return true;
      } else Error('');
    } catch (e) {
      Notification.warning('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  };

  async deletePhone() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'vehicle.PhoneMaster.DeletePhoneNumber',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Phone was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("This Phone is in use, it can't be deleted!");
      this.setFetching(false);
      return false;
    }
  }

  async getRegions() {
    this.setFetchingRegions(true);
    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.MvLocation.GetRegionForPhone',
      });

      const regions = response.map((region) => ({
        label: region.label,
        value: Number(region.data),
      }));

      runInAction(() => {
        this.fetchingRegions = false;
        this.regions = regions;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingRegions = false;
        this.regions = [];
      });
    }
  }

  async getOptions() {
    this.setFetching(true);
    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'vehicle.PhoneMaster.PhoneNumberDropDown',
      });

      const options = response.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storePhone = new Phone();
