import { Container } from 'flux/utils';

import BarChart from 'components/project/common/charts/BarChart';
import AbstractReportPage from '../AbstractReportPage';
import { prettierFilterPayload } from '../filter/prettierFilterPayload';

import TechPatientPageActions from 'actions/reports/report-creator/tech-patient/TechPatientPageActions';
import TechPatientPageStore from 'stores/reports/report-creator/tech-patient/TechPatientPageStore';
import { timeZoneRender } from 'utils/DateUtils';
import { TECH_PATIENT as PAGE_ID } from 'constant/pagesId/reports';

export class TechPatientPage extends AbstractReportPage {
  static getStores() {
    return [TechPatientPageStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = AbstractReportPage.getPageState('tech_patient_grid');
      prevState.paginationStrategy = AbstractReportPage.LOGIC_PAGINATION;
    }
    return {
      ...prevState,
      appId: PAGE_ID.PAGE,
      dataSource: TechPatientPageStore.getState().techPatientList,
      chartValues: TechPatientPageStore.getState().techPatientDiagram,
    };
  }

  COLUMNS = [
    { name: 'studyid', title: 'Study ID' },
    { name: 'patientname', title: 'Patient name' },
    { name: 'facility_name', title: 'Facility' },
    {
      name: 'dispatchdate',
      title: 'Dispatch date',
      render: (date) =>
        this.state.timezone && this.state.timezone !== 'CDT'
          ? timeZoneRender(date, this.state.timezone)
          : date,
    },
    {
      name: 'completeddate',
      title: 'Completed date',
      render: (date) =>
        this.state.timezone && this.state.timezone !== 'CDT'
          ? timeZoneRender(date, this.state.timezone)
          : date,
    },
    { name: 'technicianname', title: 'Technologist' },
    {
      name: 'dos',
      title: 'DOS',
      className: 'text-nowrap',
      render: (date) =>
        this.state.timezone && this.state.timezone !== 'CDT'
          ? timeZoneRender(date, this.state.timezone, true)
          : date,
    },
    { name: 'facility_type', title: 'Facility type' },
  ];

  getTitle() {
    return 'Tech Patient';
  }

  getColumns() {
    return this.COLUMNS;
  }

  getGridID() {
    return 'tech_patient_grid';
  }

  getExportedFileName() {
    return 'tech_patient';
  }

  getClass() {
    return 'tech-patient-overview-page';
  }

  renderChart() {
    return <BarChart data={this.state.chartValues} />;
  }

  getShownFilters() {
    return [
      'patientFirstName',
      'patientLastName',
      'facility',
      'facilityType',
      'dispatchedDate',
      'examCompletedDate',
      'technicianFirstName',
      'technicianLastName',
      'dos',
      'studyID',
    ];
  }

  loadListAction(conditionList, joint) {
    const payload = prettierFilterPayload(conditionList);

    return TechPatientPageActions.loadTechPatientList(payload, joint);
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }
  allowTimezoneChange() {
    return true;
  }

  getActions() {
    return TechPatientPageActions;
  }
}

const techPatientPage = Container.create(TechPatientPage);
export default techPatientPage;
