import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormExamType, { FormModel } from './components/Form';

import { storeExamType } from 'stores/_mobx/systemSetup/masterSetting/examType';
import { URL_EXAM_TYPE } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const ExamTypeAddPage = ({ history }: PropsType) => {
  const handleSubmit = (data: FormModel) =>
    storeExamType.addExamType(data).then((response) => {
      if (response) {
        history.push(URL_EXAM_TYPE);
      }
    });

  return (
    <>
      <Title title="Add Modality" />
      <FormExamType backUrl={URL_EXAM_TYPE} onSubmit={handleSubmit} />
    </>
  );
};

export default ExamTypeAddPage;
