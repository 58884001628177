import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Export from 'components/project/ExporterNew';
import { TECH_PATIENT_OTHER as PAGE_ID } from 'constant/pagesId/reports';
import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import { Button } from 'components/button';
import Filter from './components/Filter';

import UserProfileStore from 'stores/UserProfileStore';
import {
  storeEncounterDuration,
  FilterType,
} from 'stores/_mobx/report/encounterDuration';

const columns = [
  { name: 'facility_nm', title: 'Facility' },
  { name: 'patient_firstnm', title: 'Patient First Name' },
  { name: 'patient_lastnm', title: 'Patient Last Name' },
  {
    name: 'technologistName',
    title: 'Technologist',
  },
  { name: 'service_dt', title: 'Date Of Service' },
  {
    name: 'dispatched_dt',
    title: 'Dispatched Date',
  },
  {
    name: 'complete_dt',
    title: 'Completed Date',
  },
  { name: 'elapsed_time', title: 'Elapsed Hours' },
  { name: 'proc_cnt', title: 'Procedure Count' },
];

const EncounterDuration = () => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    logs,
    logTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeEncounterDuration;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const appName = useMemo(
    () =>
      UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
      'Encounter_Duration_Report',
    []
  );

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleApplyFilter = (filter: FilterType) => {
    storeEncounterDuration.setFilter(filter);

    setPaginationToStart();
  };

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  useLayoutEffect(() => {
    storeEncounterDuration.setDefaultFilter();
  }, []);

  useEffect(() => {
    storeEncounterDuration.getLogsMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Encounter Duration">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        isVisible={isFilterVisible}
        fetching={fetching}
        setTimeZone={storeEncounterDuration.setTimeZone}
        onSearch={handleApplyFilter}
      />

      <Grid
        id="tech_patients_gridId"
        noDataText="Please refine your search using the filters above"
        ref={gridRef}
        columns={columns}
        onAjax={fetching}
        dataSource={logs}
        dataSourceCount={logTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton
            title="Export"
            disabled={!logTotal}
            onClick={handleToggleExport}
          />
        }
      />

      {isExportVisible && (
        <Export
          exporter="TechPatientsExporter"
          reportName={appName}
          filter={storeEncounterDuration.prepareFilterForExport()}
          gridRef={gridRef}
          columns={columns}
          dataSource={logs}
          // pdfExporter={storeEncounterDuration.exportToPdf} This feature doesn't works correct on backend side. Legacy bug
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default observer(EncounterDuration);
