import { Link, RouteComponentProps } from 'react-router-dom';

import { LayoutSideTitle } from 'components/layout';
import FormRegion from './components/Form';

import {
  storeRegion,
  RegionType,
} from 'stores/_mobx/systemSetup/masterSetting/region';
import { URL_REGION } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const RegionAddPage = ({ history }: PropsType) => {
  const handleSubmit = (data: RegionType) =>
    storeRegion.modifyRegion(data).then((response) => {
      if (response) {
        return response;
      }
      history.push(URL_REGION);
    });

  return (
    <>
      <LayoutSideTitle title="Add Region">
        <Link to={URL_REGION} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <FormRegion onSubmit={handleSubmit} />
    </>
  );
};

export default RegionAddPage;
