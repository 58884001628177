import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid, TPagination } from 'components/grid';
import Dialog, { DialogHeader, DialogBody } from 'components/modal/dialog';

import {
  storeOrderTranscription,
  TranscriptionType,
} from 'stores/_mobx/workflow/order/transcription';
import { getPagination } from 'utils/getPagination';

const COLUMNS = [
  {
    name: 'fileName',
    title: 'File',
    render: (fileName: string, { filePath, isCritical }: TranscriptionType) =>
      filePath ? (
        <a
          href={filePath}
          target="_blank"
          rel="noreferrer"
          className="d-flex align-items-center gap-3">
          <span>{fileName}</span>
          {isCritical ? (
            <span
              className="text-danger"
              style={{ fontSize: '1.9rem' }}
              title="Critical">
              <i className="bi bi-check-circle" />
            </span>
          ) : null}
        </a>
      ) : (
        fileName
      ),
  },
  {
    name: 'reportTypeName',
    title: 'Report Type',
  },
];

interface PropsType {
  patientId: number;
  orderId: number;
  onClose: () => void;
}

const DialogTranscriptionReports = ({
  patientId,
  orderId,
  onClose,
}: PropsType) => {
  const { fetching, transcriptions, transcriptionsTotal } =
    storeOrderTranscription;

  const [pagination, setPagination] = useState<TPagination>(getPagination(100));

  useEffect(() => {
    const payload = {
      orderId,
      patientId,
      radiologyGroupId: 0,
      radiologistId: 0,
      cptDescription: '',
      pagination,
    };
    storeOrderTranscription.getTranscriptionMain(payload);

    return () => {
      storeOrderTranscription.clearTranscriptions();
    };
  }, [orderId, patientId, pagination]);

  return (
    <Dialog size="large" scrollable handleClose={onClose}>
      <DialogHeader title="Reports" onClose={onClose} />
      <DialogBody>
        <Grid
          // id="report_popup_grid"
          stateless
          onAjax={fetching}
          columns={COLUMNS}
          pagination={pagination}
          dataSource={transcriptions}
          dataSourceCount={transcriptionsTotal}
          onPaginationChange={setPagination}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogTranscriptionReports);
