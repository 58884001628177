import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';

import { storeDivisionSettingsStatus } from 'stores/_mobx/systemSetup/masterSetting/divisionMaster';

export type PropsTypeWitDivisionSettings = {
  isDivisionEnabled: boolean;
};

const WithDivisionSettings = ({
  component: Component,
  ...props
}: {
  component: React.FunctionComponent | React.ClassType<any, any, any>;
}) => {
  const isRequestFinished = useRef(false);

  const { fetching, isDivisionEnabled } = storeDivisionSettingsStatus;

  useEffect(() => {
    storeDivisionSettingsStatus.getDivisionState();

    isRequestFinished.current = true;

    return storeDivisionSettingsStatus.clearState;
  }, []);

  return !isRequestFinished.current || fetching ? (
    <SpinnerFixed />
  ) : (
    <Component isDivisionEnabled={isDivisionEnabled} {...props} />
  );
};

const WrapperWithDivisionSettings = observer(WithDivisionSettings);

export default function withDivisionSettings(
  Component: React.FunctionComponent | React.ClassType<any, any, any>
) {
  return (props: any) => (
    <WrapperWithDivisionSettings component={Component} {...props} />
  );
}
