import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Input } from 'components/form/textField';

export const defaultValues: FormModel = {
  firstName: '',
  lastName: '',
  npi: '',
};

export interface FormModel {
  firstName: string;
  lastName: string;
  npi: string;
}

interface PropsType {
  onChange: (filter: FormModel) => void;
}

const FilterPhysicianTab = ({ onChange }: PropsType) => {
  const { control, watch } = useForm({ defaultValues });

  useEffect(() => {
    const subscription = watch((formValue) => {
      onChange(formValue as Required<FormModel>);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row">
      <Input
        className="col-md-4"
        name="firstName"
        placeholder="First name"
        control={control}
      />
      <Input
        className="col-md-4"
        name="lastName"
        placeholder="Last name"
        control={control}
      />
      <Input
        className="col-md-4"
        name="npi"
        placeholder="NPI"
        type="number"
        control={control}
      />
    </form>
  );
};

export default FilterPhysicianTab;
