import { memo } from 'react';

const cnLabel = 'col-sm-6 col-md-3 col-lg-2';

const cnValue = 'col-sm-6 col-md-3 col-lg-4';

interface InfoType {
  dos: string;
  facility: string;
  patient: string;
  examType: string;
  room: string;
  id: number;
}

interface PropsType {
  info: InfoType;
}

const ReportInfoBlock = ({ info }: PropsType) => (
  <dl className="row description mb-4">
    <dt className={cnLabel}>Date of Service:</dt>
    <dd className={cnValue}>{info.dos}</dd>

    <dt className={cnLabel}>Facility:</dt>
    <dd className={cnValue}>{info.facility}</dd>

    <dt className={cnLabel}>Patient Name:</dt>
    <dd className={cnValue}>{info.patient}</dd>

    <dt className={cnLabel}>Modality:</dt>
    <dd className={cnValue}>{info.examType}</dd>

    <dt className={cnLabel}>Room:</dt>
    <dd className={cnValue}>{info.room}</dd>
  </dl>
);

export default memo(ReportInfoBlock);
