import { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'components/modal/dialog';
import Messages from 'components/messages';
import { Button } from 'components/button';
import { PureTextarea } from 'components/form/textarea';

import { storeChatting } from 'stores/_mobx/message/chatting';

export interface ParentPropsType {
  id: number;
  onClose: (shouldUpdate: boolean) => void;
}

interface PropsType {
  id: number;
  onClose: (shouldUpdate: boolean) => void;
}

const DialogMessage = ({ id, onClose }: PropsType) => {
  const { count, messages, fetching } = storeChatting;

  const [newMessage, setNewMessage] = useState<string>('');

  const shouldUpdate = useRef<boolean>(null);

  const handleClickSend = () => {
    storeChatting
      .addNote({ orderId: id, message: newMessage })
      .then((isSucceed) => {
        if (isSucceed) {
          setNewMessage('');
          storeChatting.getOrderNotes(id);
          shouldUpdate.current = true;
        }
      });
  };

  const handleClose = () => {
    onClose(shouldUpdate.current);
  };

  useEffect(() => {
    storeChatting.getOrderNotes(id);

    return storeChatting.clearMessages;
  }, [id]);

  return (
    <Dialog size="large" handleClose={handleClose}>
      <DialogHeader title={`Messages for Order #${id}`} onClose={handleClose} />
      <DialogBody className={`${fetching ? 'on-ajax' : ''}`}>
        <div className="pb-3">
          {count ? <Messages list={messages} /> : 'No messages'}
        </div>

        <PureTextarea
          className="mb-4"
          placeholder="Type new message"
          value={newMessage}
          rows={4}
          resize={false}
          onChange={setNewMessage}
          autoFocus
        />
      </DialogBody>
      <DialogFooter>
        <Button
          text="Send"
          disabled={!newMessage.length || fetching}
          onClick={handleClickSend}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogMessage);
