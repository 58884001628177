import Notification from 'components/modal/Notification';
import { makeObservable, observable, action, computed } from 'mobx';

import { apiRequest } from 'services/RequestService';

const defaultJurisdictionDetails: JurisdictionDetailsType = {
  isInJurisdiction: false,
  pos: 0,
  jurisdiction: {
    state: 0,
    area: '',
    refid: 0,
  },
  patient: {
    firstName: '',
    lastName: '',
    dos: '',
    dob: '',
    modality: '',
    modalityId: 0,
  },
  // @ts-ignore
  renderingDoctor: {
    last_name: '',
    first_name: '',
    office_address: '',
    officestate: '',
    officecity: '',
    offzipcode: '',
    radio_npi: '',
    taxonomy: 0,
  },
  // @ts-ignore
  serviceLocation: {
    facility_nm: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
  },
  // @ts-ignore
  zip: {
    state: '',
    locality: '',
    zip: '',
  },
};

interface OutsideLabResponseType {
  cpt: string;
  icd: string;
  price: number;
  units: string;
}

export interface JurisdictionDetailsType {
  facility: {
    refid: string;
    name: string;
  };
  isInJurisdiction: boolean;
  jurisdiction: { state: number; area: string; refid: number };
  patient: {
    dob: string;
    dos: string;
    firstName: string;
    lastName: string;
    modality: string;
    modalityId: number;
  };
  pos: number;
  renderingDoctor: {
    Radiologygrp: string;
    birthdate: string;
    email: string;
    emergency_name: string;
    emergency_phone: string;
    fax: string;
    first_name: string;
    gender: 'F' | 'M';
    home_address: string;
    home_phone: string;
    homecity: string;
    homestate: string;
    homezipcode: string;
    img_path: string;
    last_name: string;
    mail_type: string;
    middle_name: string;
    mobile: string;
    mobileop_id: number;
    mobileop_name: null;
    office_address: string;
    officecity: string;
    officestate: string;
    offlocality: null;
    offzipcode: string;
    prefix: number;
    radio_npi: string;
    radiologyGroups: number[];
    refid: number;
    sig_path: string;
    spouse_name: string;
    statearr: string;
    suffix: number;
    taxonomy: number;
    user_title: string;
    work_phone: string;
  };
  serviceLocation: {
    address: string;
    address1: string;
    address2: string;
    bill_id: number;
    bill_regionid: number;
    city: string;
    claim: boolean;
    facility_nm: string;
    isHomeCare: boolean;
    isInJurisdiction: boolean;
    order_id: number;
    pdfimage: null;
    phone: string;
    pos: string;
    pos_id: number;
    refid: number;
    region: string;
    state: string;
    zipcode: string;
    zipimage: null;
  };
  zip: {
    carrier: string;
    lab_cb_locality: string;
    locality: string;
    part_b_drug_indicator: string;
    plus_4_flag: 'N' | 'Y' | '';
    refid: number;
    rural_index: string;
    rural_index_2: string;
    state: string;
    stateId: number;
    year_qtr: string;
    zip: string;
  };
}

class ClaimManagerPC {
  fetchingJurisdictionDetails: boolean = false;
  fetchingOutsideLabRecords: boolean = false;
  outsideLabRecords: OutsideLabResponseType[] = [];
  jurisdictionDetails: JurisdictionDetailsType | undefined =
    defaultJurisdictionDetails;

  constructor() {
    makeObservable(this, {
      fetchingJurisdictionDetails: observable,
      fetchingOutsideLabRecords: observable,
      outsideLabRecords: observable,
      jurisdictionDetails: observable,

      outsideLabTotalPrice: computed,

      setFetchingJurisdictionDetails: action,
      setFetchingOutsideLab: action,
      setOutsideLabRecords: action,
      setJurisdictionDetails: action,
      clearOutsideLabRecords: action.bound,
      clearJurisdictionDetails: action.bound,
    });
  }

  setFetchingJurisdictionDetails(fetching: boolean) {
    this.fetchingJurisdictionDetails = fetching;
  }

  setFetchingOutsideLab(fetching: boolean) {
    this.fetchingOutsideLabRecords = fetching;
  }

  setOutsideLabRecords(records: OutsideLabResponseType[]) {
    this.outsideLabRecords = records;
  }

  setJurisdictionDetails(details: JurisdictionDetailsType) {
    this.jurisdictionDetails = details;
  }

  clearOutsideLabRecords() {
    this.outsideLabRecords = [];
  }

  clearJurisdictionDetails() {
    this.jurisdictionDetails = defaultJurisdictionDetails;
  }

  get outsideLabTotalPrice() {
    const totalPrice = this.outsideLabRecords.reduce(
      (totalPrice, { price }) => totalPrice + price,
      0
    );

    return `$${Number(totalPrice).toFixed(2)}`;
  }

  async getOutsideLabRecords(orderId: number) {
    this.setFetchingOutsideLab(true);
    try {
      const records = await apiRequest<OutsideLabResponseType[]>({
        url: 'facility.ClaimManager.loadOutsideLabCharges',
        data: { orderId },
      });

      this.setOutsideLabRecords(records);
    } catch (e: any) {
      this.setOutsideLabRecords([]);
    } finally {
      this.setFetchingOutsideLab(false);
    }
  }

  async getJurisdictionDetails(claimUniqId: string) {
    this.setFetchingJurisdictionDetails(true);
    try {
      const response = await apiRequest<JurisdictionDetailsType>({
        url: 'facility.ClaimManager.getJurisdictionOrder',
        data: { claimUniqId },
      });
      const jurisdictionDetails: JurisdictionDetailsType = {
        ...response,
        pos: Number(response.pos),
        patient: {
          ...response.patient,
          modalityId: Number(response.patient.modalityId),
        },
        renderingDoctor: {
          ...response.renderingDoctor,
          mobileop_id: Number(response.renderingDoctor.mobileop_id),
          prefix: Number(response.renderingDoctor.prefix),
          refid: Number(response.renderingDoctor.refid),
          suffix: Number(response.renderingDoctor.suffix),
          taxonomy: Number(response.renderingDoctor.taxonomy),
        },
        serviceLocation: {
          ...response.serviceLocation,
          bill_id: Number(response.serviceLocation.bill_id),
          bill_regionid: Number(response.serviceLocation.bill_regionid),
          pos_id: Number(response.serviceLocation.pos_id),
          refid: Number(response.serviceLocation.refid),
        },
      };

      this.setJurisdictionDetails(jurisdictionDetails);
    } catch (e: any) {
      this.setJurisdictionDetails(defaultJurisdictionDetails);
    } finally {
      this.setFetchingJurisdictionDetails(false);
    }
  }

  async updateJurisdictionDetails(model: JurisdictionDetailsType) {
    this.setFetchingJurisdictionDetails(true);
    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'facility.ClaimManager.savePcJurisdiction',
        data: { model },
      });
      return response === 'S';
    } catch (error) {
      Notification.danger('An error occurred');
      return false;
    } finally {
      this.setFetchingJurisdictionDetails(false);
    }
  }
}

export const storeClaimManagerPC = new ClaimManagerPC();
