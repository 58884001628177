import { makeObservable, observable, action, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { getDateRangeBounds } from 'utils/DateUtils';

const defaultColumns = [
  { name: 'facilityName', id: 'f_name', title: 'Facility' },
];

const indexDescription = {
  P: 'Positive',
  I: 'Intermediate',
  N: 'Negative',
} as const;

function calculateSummary(sum: FindingListResponseType['sum']) {
  const keys = Object.keys(sum).filter((key) =>
    key ? key !== 'f_id' && key !== 'f_name' : false
  );

  const total = keys.reduce((count, key) => count + (sum[key] || 0), 0);

  const summery = keys.reduce(
    (prev, key) => ({
      ...prev,
      [key]: sum[key] || 0,
    }),
    { facilityName: 'Sum' }
  );

  const percentage = keys.reduce(
    (prev, key) => ({
      ...prev,
      [key]: Number(((sum[key] || 0) * 100) / total).toFixed(2),
    }),
    { facilityName: 'Percentage(%)' }
  );

  return [summery, percentage];
}

type DescriptionKey = keyof typeof indexDescription;

type DescriptionValues =
  (typeof indexDescription)[keyof typeof indexDescription];

export const filterDefaultValues: FilterType = {
  corporationId: 0,
  dosEnd: '',
  dosStart: '',
  facilityId: 0,
  examType: 0,
  pos: 0,
  radiologyGroupId: 0,
  stateId: 0,
  division: 0,
};

export interface FilterType {
  corporationId: number;
  dosEnd: string;
  dosStart: string;
  facilityId: number;
  examType: number;
  pos: number;
  radiologyGroupId: number;
  stateId: number;
  division: number;
}

interface HeaderType {
  category: DescriptionKey;
  name: string;
}

type FindingResponseType = {
  [key: string]: string | number;
  f_id: string;
  f_name: string;
  division: string;
};

interface FindingType extends Omit<FindingResponseType, 'f_id' | 'f_name'> {
  facilityId: number;
  facilityName: string;
}

interface FindingListResponseType {
  dataprovider: FindingResponseType[];
  sum: Record<string, number>;
}

interface ColumnType {
  name: HeaderType['name'];
  title: HeaderType['name'];
  group?: DescriptionKey;
  groupName?: DescriptionValues;
}

class FindingCoding {
  fetching: boolean = false;
  findingList: FindingType[] = [];
  findingTotal: number = 0;
  summary: Record<string, string | number>[] = [];
  columns: ColumnType[] = [];
  filter: FilterType = filterDefaultValues;

  page: Pagination = new Pagination({
    id: 'claim_management_finding_coding_grid',
  });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      findingList: observable,
      findingTotal: observable,
      columns: observable,
      summary: observable,
      filter: observable,

      setFetching: action,
      setFindingList: action,
      setFindingTotal: action,
      setColumns: action,
      setSummary: action,
      setFilter: action.bound,
      clearFindings: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFindingList(findings: FindingType[]) {
    this.findingList = findings;
  }

  setFindingTotal(count: number) {
    this.findingTotal = count;
  }

  setColumns(columns: ColumnType[]) {
    this.columns = columns;
  }

  setSummary(summary: Record<string, string | number>[]) {
    this.summary = summary;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  clearFindings() {
    this.findingTotal = 0;
    this.findingList = [];
    this.columns = [];
    this.summary = [];
  }

  async getHeaders(): Promise<HeaderType[]> {
    if (this.columns.length) return Promise.resolve([]);
    try {
      const response = await apiRequest<HeaderType[]>({
        url: 'generalmaster.Finding.HeaderName',
      });

      return response;
    } catch (error) {
      return [];
    }
  }

  async getFindingCount(data: Record<string, string | number>) {
    try {
      const count = await apiRequest<number>({
        url: 'generalmaster.Finding.GetFindingListCount',
        data,
      });

      return count;
    } catch (error) {
      return 0;
    }
  }

  async getFindingList(data: Record<string, string | number>) {
    try {
      const { dataprovider, sum } = await apiRequest<FindingListResponseType>({
        url: 'generalmaster.Finding.GetFindingList',
        data,
      });

      const summary = calculateSummary(sum);

      this.setSummary(summary);

      return dataprovider.map(({ f_id, f_name, ...rest }) => ({
        ...rest,
        facilityId: Number(f_id),
        facilityName: f_name,
      }));
    } catch (error) {
      return [];
    }
  }

  getFindingsMain = async (isDivisionEnabled: boolean) => {
    const {
      filter,
      page: { pagination },
    } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const commonPayload = {
      facilityid: filter.facilityId,
      radiology: filter.radiologyGroupId,
      modality: filter.examType,
      corporation: filter.corporationId,
      state: filter.stateId,
      pos: filter.pos,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
      division: isDivisionEnabled ? filter.division : 0,
    };

    const listPayload = {
      ...commonPayload,
      skip: pagination.skip,
      limit: pagination.pageSize,
    };

    this.setFetching(true);
    try {
      const promiseHeader = this.getHeaders();

      const promiseCount = this.getFindingCount(commonPayload);

      const promiseList = this.getFindingList(listPayload);

      const [count, findingList, header] = await Promise.all([
        promiseCount,
        promiseList,
        promiseHeader,
      ]);

      const initialColumns: ColumnType[] = isDivisionEnabled
        ? defaultColumns.concat({
            name: 'division',
            id: 'division',
            title: 'Division',
          })
        : defaultColumns;

      const columns = this.columns.length
        ? this.columns
        : header.reduce(
            (prev, current) =>
              prev.concat({
                name: current.name,
                title: current.name,
                group: current.category,
                groupName: indexDescription[current.category],
              }),
            initialColumns
          );

      runInAction(() => {
        this.findingTotal = count;
        this.findingList = findingList;
        this.columns = columns;
        this.fetching = false;
      });
    } catch (error) {
      runInAction(() => {
        this.findingTotal = 0;
        this.findingList = [];
        this.columns = [];
        this.fetching = false;
      });
    }
  };

  prepareFilterForExport(isDivisionEnabled: boolean) {
    const { filter } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return {
      ...filter,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
      division: isDivisionEnabled ? filter.division : 0,
    };
  }

  generatePdf = async (props: {
    filter: string;
    isDivisionEnabled: boolean;
  }) => {
    const {
      filter,
      page: { pagination },
    } = this;

    const data = {
      facilityid: filter.facilityId,
      radiology: filter.radiologyGroupId,
      modality: filter.examType,
      corporation: filter.corporationId,
      state: filter.stateId,
      pos: filter.pos,
      dosStart: filter.dosStart,
      dosEnd: filter.dosEnd,
      division: props.isDivisionEnabled ? filter.division : 0,
      skip: pagination.skip,
      limit: pagination.pageSize,
      exportName: 'FindingCoding',
    };
    try {
      const fileName = await apiRequest<string>({
        url: 'pdfcreater.OrderPdfGenerator.generatefindingpdf',
        data,
      });
      return fileName;
    } catch (e: any) {
      return '';
    }
  };
}

export const storeFindingCoding = new FindingCoding();
