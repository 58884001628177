import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

export interface RejectReasonType {
  created_by: number;
  created_dt: string;
  reason: string;
  refid: number;
}

export interface FormModel {
  id: number;
  reason: string;
}

interface OptionResponseType {
  data: string;
  label: string;
}

export interface OptionType {
  value: number;
  label: string;
}

class RejectReason {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  reasonList: RejectReasonType[] = [];
  reasonTotal: number = 0;
  reason?: FormModel;
  idForDelete: number = 0;
  search: string = '';
  options: OptionType[] = [];

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'rejectReasonGrid' });

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      reasonList: observable,
      reasonTotal: observable,
      reason: observable,
      search: observable,
      idForDelete: observable,
      options: observable,

      setFetching: action,
      setFetchingOptions: action,
      setReasonList: action,
      setReasonTotal: action,
      setReason: action,
      setSearch: action,
      setIdForDelete: action.bound,
      clearIdForDelete: action.bound,
      clearReason: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFetchingOptions = (fetching: boolean) => {
    this.fetchingOptions = fetching;
  };

  setReasonList(list: RejectReasonType[]) {
    this.reasonList = list;
  }

  setReasonTotal(count: number) {
    this.reasonTotal = count;
  }

  setReason(reason?: FormModel) {
    this.reason = reason;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setSearch(search: string) {
    this.search = search;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  clearReason() {
    this.reason = undefined;
  }

  async getReasonList(search: string) {
    const { pagination } = this.page;

    const data = [pagination.skip, pagination.pageSize, search];

    try {
      const response = await apiRequest<RejectReasonType[]>({
        url: 'generalmaster.RejectReason.GetReasonValues',
        data,
      });

      const list = response.map((reason) => ({
        ...reason,
        created_by: Number(reason.created_by),
        refid: Number(reason.refid),
      }));

      this.setReasonList(list);
    } catch (e) {
      this.setReasonList([]);
    }
  }

  async getReasonCount(search: string) {
    try {
      const count = await apiRequest<number>({
        url: 'generalmaster.RejectReason.RejectReasonTotalCount',
        data: [search],
      });

      this.setReasonTotal(Number(count) || 0);
    } catch (e) {
      this.setReasonTotal(0);
    }
  }

  getRejectReasonMain = (search: string = this.search) => {
    this.setFetching(true);

    const promiseList = this.getReasonList(search);

    const promiseCount = this.getReasonCount(search);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getReason(id: number) {
    this.setFetching(true);

    try {
      const [{ refid, reason }] = await apiRequest<[RejectReasonType]>({
        url: 'generalmaster.RejectReason.GetReasonValuesByRefid',
        data: [id],
      });

      const rejectReason = {
        id: Number(refid),
        reason,
      };

      this.setReason(rejectReason);
    } catch (e) {
      this.setReason();
    } finally {
      this.setFetching(false);
    }
  }

  async addReason({ reason }: FormModel) {
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.RejectReason.AddReason',
        data: [reason],
      });
      if (result === 'S') {
        Notification.success('Reject reason was added successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This reason already exist!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateReason({ id, reason }: FormModel) {
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.RejectReason.EditReason',
        data: [id, reason],
      });

      if (result === 'S') {
        Notification.success('Reject reason was updated successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This reason already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async deleteReason() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.RejectReason.DeleteReason',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Reject reason was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("This reason can't be deleted!");
      this.setFetching(false);
      return false;
    }
  }

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOptions(true);

    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.RejectReason.GetReasonDropDown',
      });

      const options = response.map(({ data, label }) => ({
        value: Number(data),
        label,
      }));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storeRejectReason = new RejectReason();
