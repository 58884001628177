import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import Form from './components/form';

import {
  storeRadiologyGroup,
  RadiologyGroupDetailsType,
} from 'stores/_mobx/systemSetup/masterSetting/radiologyGroup';
import { storeDropdownCoordinator } from 'stores/_mobx/dropDown/coordinator';
import { storeRadiologist } from 'stores/_mobx/users/radiologist';
import { URL_RADIOLOGY_GROUP } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const RadiologyGroupDetailsPage = ({
  match: { params },
  history,
}: PropsType) => {
  const { fetching, radiologyGroupDetails } = storeRadiologyGroup;
  const { fetching: fetchingCoordinatorOptions, fetchingBillingUsers } =
    storeDropdownCoordinator;
  const { fetchingOptions } = storeRadiologist;

  const isNewGroup = !params.id;

  const isLoading =
    fetching ||
    fetchingOptions ||
    fetchingBillingUsers ||
    fetchingCoordinatorOptions;

  const handleSubmit = (payload: RadiologyGroupDetailsType) => {
    const action = isNewGroup
      ? storeRadiologyGroup.addRadiologyGroup
      : storeRadiologyGroup.updateRadiologyGroup;

    return action(payload).then((response) => {
      if (response) return response;

      history.push(URL_RADIOLOGY_GROUP);
    });
  };

  useEffect(() => {
    if (!isNewGroup) {
      const groupId = Number(params.id);
      storeRadiologyGroup.getRadiologyGroupDetails(groupId);
    }

    storeDropdownCoordinator.getOptions();
    storeDropdownCoordinator.getOptionsBillingUsers();
    storeRadiologist.getRadiologistsAbbreviatedInfo();
    storeRadiologyGroup.checkPermission();

    return storeRadiologyGroup.clearRadiologyGroupDetails;
  }, [params.id, isNewGroup]);

  return (
    <>
      <LayoutSideTitle
        title={isNewGroup ? 'Add Radiology group' : 'Edit Radiology group'}>
        <Link to={URL_RADIOLOGY_GROUP} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <Form
        fetching={isLoading}
        isNewGroup={isNewGroup}
        details={radiologyGroupDetails}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default observer(RadiologyGroupDetailsPage);
