import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { DropDownJurisdiction } from 'components/project/dropdown/JurisdictionDropdown';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { FileField } from 'components/form/file';
import { Grid } from 'components/grid';
import { Button } from 'components/button';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { InputCalendar } from 'components/form/inputCalendar';
import { ControlsLayout } from 'components/layout';
import resolver from './validation';

import { DIR_WITH_EXAMPLE } from 'constant/config';

const columns = [
  { name: 'cpt_code', title: 'CPT Code' },
  { name: 'tech_fee', title: 'Tech Fee*' },
  { name: 'pro_fee', title: 'Pro Fee*' },
  { name: 'global_fee', title: 'Global Fee*' },
  { name: 'ins_tech_fee', title: 'Ins Tech Fee*' },
  { name: 'ins_pro_fee', title: 'Ins Pro Fee*' },
  { name: 'ins_global_fee', title: 'Ins Global Fee*' },
  { name: 'cpt_description', title: 'CPT Description*' },
  { name: 'default_facility', title: 'Default Facility' },
  { name: 'default_insurance', title: 'Default Insurance' },
];

const exampleData = [
  {
    cpt_code: '78801',
    tech_fee: '$23',
    pro_fee: '$23',
    global_fee: '$23',
    ins_tech_fee: '$23',
    ins_pro_fee: '$23',
    ins_global_fee: '$23',
    cpt_description: 'RADIOPHARMACY',
    default_facility: 'T',
    default_insurance: 'T',
  },
  {
    cpt_code: '78803',
    tech_fee: '$23',
    pro_fee: '$23',
    global_fee: '$23',
    ins_tech_fee: '$23',
    ins_pro_fee: '$23',
    ins_global_fee: '$23',
    cpt_description: 'RADIOPHARMACY',
    default_facility: 'T',
    default_insurance: 'T',
  },
];

const initialValues: ImportPayloadType = {
  from: '',
  to: '',
  destinationId: 0,
  jurisdictionId: 0,
  file: '',
};

interface PropsType {
  isFacilityDisabled?: boolean;
  defaultValues?: ImportPayloadType;
  onClose: () => void;
  onSubmit: (data: ImportPayloadType) => Promise<boolean>;
}

export interface ImportPayloadType {
  from: string;
  to: string;
  destinationId: number;
  jurisdictionId: number;
  file: File | '';
}

const DialogFormImportSchedule = ({
  defaultValues = initialValues,
  isFacilityDisabled = false,
  onSubmit,
  onClose,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting },
    reset,
    handleSubmit,
  } = useForm<ImportPayloadType>({
    defaultValues,
    resolver,
  });

  const [isSampleVisible, toggleSample] = useState<boolean>(false);

  const handleClose = () => {
    if (!isSubmitting) onClose();
  };

  const handleToggleSample = () => {
    toggleSample((state) => !state);
  };

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Dialog size="large" scrollable={false} handleClose={handleClose}>
      <DialogHeader title="Confirm" onClose={handleClose} />
      <DialogBody className={`fee-import${isSubmitting ? ' on-ajax' : ''}`}>
        <form className="row" onSubmit={handleClickSubmit}>
          <DropDownFacility
            name="destinationId"
            label="Facility"
            className="col-md-6"
            required
            disabled={isFacilityDisabled}
            control={control}
          />
          <DropDownJurisdiction
            name="jurisdictionId"
            label="Jurisdiction"
            className="col-md-6"
            required
            control={control}
          />
          <InputCalendar
            name="from"
            label="From"
            className="col-sm-6 col-md-4 col-lg-3"
            required
            tabIndex={1}
            control={control}
          />
          <InputCalendar
            name="to"
            label="To"
            className="col-sm-6 col-md-4 col-lg-3"
            required
            tabIndex={2}
            control={control}
          />
          <div />
          <div className="col-md-4 col-lg-3">
            <FileField
              name="file"
              label="CSV file"
              accept=".csv"
              required
              control={control}
            />
          </div>
          <ControlsLayout alignX="auto">
            <a
              href={`${DIR_WITH_EXAMPLE}/samplecptcode.csv`}
              target="_blank"
              className="btn btn-primary d-flex gap-4"
              rel="noreferrer">
              <i className="bi bi-cloud-download" />
              <span>Download sample</span>
            </a>
          </ControlsLayout>
        </form>

        {isSampleVisible && (
          <>
            <h5>Sample:</h5>
            <Grid
              noControl
              hideSwitcher
              disablePagination
              columns={columns}
              dataSource={exampleData}
            />
            <ul className="legend text-danger">
              <li>T - Tech</li>
              <li>P - Prof</li>
              <li>G - Global</li>
            </ul>
          </>
        )}
      </DialogBody>

      <DialogFooter>
        <Button
          variant="default"
          disabled={isSubmitting}
          text={isSampleVisible ? 'Hide Sample' : 'Show Sample'}
          onClick={handleToggleSample}
        />
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          text="Submit"
          disabled={isSubmitting}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogFormImportSchedule;
