import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    callTypeId: yup
      .number()
      .typeError('Please, select a call type!')
      .min(1, 'Please, select a call type!'),
    callerName: yup.string().required('Please, enter the caller name!'),
    phoneNumber: yup.string().required('Please, enter the phone number!'),
    reason: yup.string().required('Please, enter the reason!'),
  })
);

export default validation;
