import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

export const defaultFilterValue: FilterType = {
  dateFrom: '',
  dateTo: '',
  employeeName: '',
  employeeTypeId: 0,
  month: null,
  year: null,
};

interface LogResponseType extends LogType {
  approved_by: null;
  approved_by_full_name: '';
  benefits_B: null;
  benefits_H: null;
  benefits_S: null;
  day_date: string;
  expenses: number;
  flagChanged: false;
  has_comments: false;
  is_locked: null;
  is_submitted: '';
  locked_by: null;
  locked_by_full_name: '';
  mileage: number;
  updated_by: '';
}

interface LogType {
  benefits: string;
  call_hours: string;
  employee_name: string;
  employee_type: string;
  employee_type_id: number;
  over_time: string;
  pto_paid: string;
  refid: number;
  regular_time: string;
  vacation: string;
}

export interface FilterType {
  dateFrom: string;
  dateTo: string;
  employeeName: string;
  employeeTypeId: number;
  month: number | null;
  year: number | null;
}

class HoursByEmployee {
  fetching: boolean = false;
  logList: LogType[] = [];
  logTotal: number = 0;
  filter: FilterType = defaultFilterValue;
  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'hoursByEmployeeTypeGrid' });

    makeObservable(this, {
      fetching: observable,
      logList: observable,
      logTotal: observable,
      filter: observable,

      setFetching: action,
      setLogList: action,
      setLogTotal: action,
      setFilter: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setLogList(logs: LogType[]) {
    this.logList = logs;
  }

  setLogTotal(count: number) {
    this.logTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  async getLogCount() {
    try {
      const count = await apiRequest<number>({
        url: 'filemanager.TimeSheet.getHoursByEmployeeTypeReportCount',
        data: [this.filter],
      });

      this.setLogTotal(count);
    } catch (e: any) {
      this.setLogTotal(0);
    }
  }

  async getLogList() {
    const {
      filter,
      page: { pagination },
    } = this;
    try {
      const response = await apiRequest<LogResponseType[]>({
        url: 'filemanager.TimeSheet.getHoursByEmployeeTypeReportList',
        data: [filter, pagination.skip, pagination.pageSize],
      });

      const logs = response.map((log) => ({
        benefits: log.benefits,
        call_hours: log.call_hours,
        employee_name: log.employee_name,
        employee_type: log.employee_type,
        employee_type_id: Number(log.employee_type_id),
        over_time: log.over_time,
        pto_paid: log.pto_paid,
        refid: Number(log.refid),
        regular_time: log.regular_time,
        vacation: log.vacation,
      }));

      this.setLogList(logs);
    } catch (e: any) {
      this.setLogList([]);
    }
  }

  async getLogsMain() {
    this.setFetching(true);

    const promiseList = this.getLogCount();

    const promiseCount = this.getLogList();

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  }
}

export const storeHouseByEmployee = new HoursByEmployee();
