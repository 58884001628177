import React from 'react';
import { Container } from 'flux/utils';

import Form from 'components/form/Form';
import Fieldset from 'components/form/Fieldset';
import FormControl from 'components/form/FormControl';
import Text from 'components/form/input/Text';
import PhoneInput from 'components/form/input/PhoneInput';
import ZipCodeInput from 'components/form/input/ZipCodeInput';
import Radio from 'components/form/input/Radio';
import Select from 'components/form/input/Select';
import FileUpload from 'components/form/input/FileUpload';
import NumberInput from 'components/form/input/NumberInput';
import { Button } from 'components/button';
import FacilityOnlyActiveDropdown from 'components/project/dropdown/FacilityOnlyActiveDropdown';
import StateShortNameDropdown from 'components/project/dropdown/StateShortName';
import RadioOptions from 'components/project/common/RadioOptions';
import DialogAddPatient, {
  PatientInfoType,
} from 'page/workflow/order/order/dialogAddPatient';
import DialogSelectPatient from 'page/workflow/order/order/dialogSelectPatient';
import OrderFormPageActions from 'actions/workflow/order/OrderFormPageActions';
import EligibilityPopup, {
  TEligibilityModel,
} from 'page/workflow/order/eligibility/EligibilityPopup';
import AbstractOrderPage, {
  PAbstractOrderPage,
  SAbstractOrderPage,
} from 'page/workflow/order/order/AbstractOrderPage';
import HospiceStore from 'stores/project/HospiceStore';
import UserProfileStore from 'stores/UserProfileStore';
import OrderFormPageStore from 'stores/workflow/order/OrderFormPageStore';
import Tooltip from 'components/tooltip';
import Notification from 'components/modal/Notification';
import { THospiceAddress } from 'actions/project/HospiceActions';
import {
  TPatientOrder,
  TCorrectional,
  TAddPatientOrderRes,
  TPatient,
  TFacilityAddress,
} from 'services/workflow/order/OrderFormPageService';
import { TPatientRecord } from 'services/patient-records/patient-demographics/PatientRecordPageService';
import { TPiModel } from 'page/workflow/order/order/PatientInformationModel';
import { BASE_URL_FILE_DIR } from 'constant/config';
import DialogConfirm from 'components/modal/dialogConfirm';
import DialogAddFacility from 'page/workflow/order/components/DialogAddFacility';
import { storeDropdownState } from 'stores/_mobx/dropDown/state';
import { PatientInfoExtendedType } from 'stores/_mobx/workflow/order/patient';
import { dateToLocalTimezone } from 'utils/DateUtils';
import { URL_GOOGLE_MAP } from 'constant/externalLinks';

const PATIENT_GENDER = RadioOptions.gender;
const PLACE_OF_SERVICE = [
  { value: 'PH', label: 'Other' },
  { value: 'F', label: 'Same as Facility' },
];

const POPUP_CLOSE = 1;
const POPUP_OPEN = 2;
const POPUP_NO_CHANGE = 3;

const buildGMapsLink = ({ address }: TFacilityAddress) => {
  const encodedAddress = address ? encodeURIComponent(address) : '';

  return `${URL_GOOGLE_MAP}${encodedAddress}`;
};

export interface PPatientInformation extends PAbstractOrderPage {
  onPopupOpen: (flag: boolean) => void;
  allowSubmit: boolean;
  callerName: string;
  submitSuccess: (
    orderId: number,
    state: SPatientInformation,
    lastModifiedLock: string
  ) => void;
  ins_notes: string;
  flagOrderEdit: boolean;
  ref: string;
  cachedState: SPatientInformation;
}

export class SPatientInformation extends SAbstractOrderPage {
  orderDataInit: boolean = false;
  model: TPiModel = new TPiModel();
  errors: any = {};
  hospiceDetails: THospiceAddress = new THospiceAddress();
  oldFacility: number = null;
  showChangeFacility: boolean = false;
  showPatientConfirmPopup: boolean = false;
  isInsuranceDialogVisible: boolean = false;
  patientSelected: boolean = false;
  patient: PatientInfoExtendedType | PatientInfoType = null;
  insuranceTooltip: boolean = false;
  posSelected: boolean = false;
}

export class PatientInformation extends AbstractOrderPage<
  PPatientInformation,
  SPatientInformation
> {
  static getStores() {
    return [OrderFormPageStore, HospiceStore];
  }

  static calculateState(
    prevState: SPatientInformation,
    props: PPatientInformation
  ) {
    const state: SPatientInformation = Object.assign(
      new SPatientInformation(),
      AbstractOrderPage.calculateState(prevState, props)
    );
    state.model.lastModifiedLock = props.lastModifiedLock;
    if (props.orderId !== 0) {
      let model: TPiModel = Object.assign(new TPiModel(), state.model);
      const patient: TPatientRecord =
        OrderFormPageStore.getState().patientViewInfo[0];
      if (patient && !state.patientLoaded) {
        state.patientLoaded = true;
        model = Object.assign(
          new TPiModel(),
          AbstractOrderPage.addPatientToModel(patient, model)
        );
      }
      if (
        !state.orderDataInit &&
        AbstractOrderPage.hasPatientData(props.orderId)
      ) {
        state.orderDataInit = true;
        const orderDataPatient: TPatient = Object.assign(
          new TPatient(),
          state.orderDataPatient
        );
        model.patient_diff_address = orderDataPatient.patient_diff_address;
        model.patient_diff_city = orderDataPatient.patient_diff_city;
        model.patient_diff_state = orderDataPatient.patient_diff_state;
        model.patient_diff_zipcode = orderDataPatient.patient_diff_zipcode;
        model.patient_diff_phone = orderDataPatient.patient_diff_phone;
        model.room = orderDataPatient.room;
        model.orderType = orderDataPatient.order_based;
        model.placeOfService = orderDataPatient.order_based_type;
        model.faceSheet = orderDataPatient.face_sheet;
        model.lastModifiedLock = orderDataPatient.lastModifiedLock;
      }
      state.model = model;
    }
    const payment = state.model.hospicePayment;
    state.hospiceDetails = HospiceStore.isDetailsLoaded(payment)
      ? HospiceStore.getHospiceDetails(payment)
      : new THospiceAddress();
    return state;
  }

  beforeDataLoaded(r: any) {
    this.updateFacilityInfo(POPUP_NO_CHANGE);
    return OrderFormPageActions.getCorrectional(this.props.orderId)
      .then((correctionalDetails) => {
        const details: TCorrectional =
          correctionalDetails &&
          correctionalDetails !== 'SE' &&
          correctionalDetails[0]
            ? correctionalDetails[0]
            : new TCorrectional();
        this.onUpdateModel('ice_number', details.ice_number, []);
        this.onUpdateModel('inmate_number', details.inmate_number, []);
        this.onUpdateModel('immigration_id', details.immigration_id, []);
        return correctionalDetails;
      })
      .then((r) => {
        this.updateDataCallback();
        return r;
      });
  }

  updateDataCallback = () => {
    this.setState({
      onAjax: false,
    });
  };

  addFacilityHandle = () => {
    this.setState({ showAddFacility: true }, () =>
      this.props.onPopupOpen(true)
    );
  };

  resetHandle = () => {
    this.setState({
      model: new TPiModel(),
      patientSelected: false,
    });
  };

  handleCloseDialogConfirmPatient = () => {
    this.setState({ showPatientConfirmPopup: false });
  };

  handleApproveDialogConfirmPatient = () => {
    this.setState(
      { showPatientConfirmPopup: false },
      this.afterPatientConfirmed
    );
  };

  handleCloseDialogPatient = () => {
    this.setState({ showSelectPatient: false }, () =>
      this.props.onPopupOpen(false)
    );
  };

  handleOpenAddPatientDialog = () => {
    this.setState({ showAddPatient: true });
  };

  handleSubmit = (
    updatedModel: any,
    hasErrors: boolean,
    updatedErrors: any
  ) => {
    const { state } = this;
    if (!state.model.patientId) {
      const errors = {
        ...state.errors,
        patient: 'Please, select Patient first.',
      };
      return this.setState({
        errors,
      });
    }
    if (hasErrors) {
      const model = { ...state.model, ...updatedModel };
      const errors = { ...state.errors, ...updatedErrors };
      this.setState({ model, errors });
    } else {
      this.submitSuccess();
    }
  };

  render() {
    const onClickPatient = { disabled: true };
    const { state, props } = this;
    const { flagOrderEdit } = props;
    const { facilityAddress, patientSelected, model } = state;
    const showAddress = model.orderType === 'C';
    const hasDiffAddress =
      model.orderType === 'O' ||
      model.orderType === 'P' ||
      model.orderType === 'H';
    const displayDiffAddress = hasDiffAddress && model.placeOfService !== 'F';
    const showToolTips =
      props.showToolTips &&
      !state.showSelectPatient &&
      !state.showAddPatient &&
      !state.showAddFacility &&
      !state.showChangeFacility &&
      !state.isInsuranceDialogVisible &&
      !state.showPatientConfirmPopup;
    const onEdit = !!props.onEdit;
    const isHelpOn =
      showToolTips && !state.insuranceTooltip && (patientSelected || onEdit);

    return (
      <div
        className={
          'patient-information ' + (state.dataLoaded || !onEdit) &&
          !state.onAjax
            ? ''
            : 'on-ajax'
        }>
        <Form
          onCollectValues={this.onUpdateModel.bind(this)}
          submit={this.handleSubmit}
          model={model}
          errors={state.errors}
          errorMessages={state.errorMessages}>
          {onEdit || (!onEdit && patientSelected) ? (
            <Fieldset className="row">
              <legend>Patient Information</legend>
              <NumberInput
                format="##/##/####"
                name="dob"
                className="col-md-3"
                attr={onClickPatient}
                label="DOB"
              />
              <Text
                name="lastName"
                className="col-md-3"
                attr={onClickPatient}
              />
              <Text
                name="middleName"
                className="col-md-3"
                attr={onClickPatient}
              />
              <Text
                name="firstName"
                className="col-md-3"
                attr={onClickPatient}
              />

              <Text
                name="ssn"
                label="SSN"
                className="col-md-3"
                attr={onClickPatient}
              />
              <Text name="clientMRN" label="Other MRN" className="col-md-3" />
              <Radio
                name="patientGender"
                label="Gender"
                className="col-md-6 part-inline"
                options={PATIENT_GENDER}
              />
            </Fieldset>
          ) : null}

          <div className="formfield-holder col-md-6 col-lg-auto col-xl-3">
            <Button
              className={onEdit ? '' : ' search-patient-btn btn-lg w-100'}
              onClick={this.showSelectPatientPopup}
              text={
                props.orderId ? 'Change Patient' : 'Select Existing Patient'
              }
              data-testid="selectPatient"
            />
            {state.errors?.patient ? (
              <div className="text-danger">{state.errors.patient}</div>
            ) : null}
          </div>
          {!this.canShowInsurancePopup() ? null : (
            <div className="formfield-holder col-md-6 col-lg-3">
              <Tooltip
                className="w-100"
                alwaysVisible={isHelpOn}
                theme="dark"
                body={
                  isHelpOn ? 'Manage insurance details for this patient' : ''
                }>
                <Button
                  className="insuranceColor"
                  fullWidth
                  onClick={this.showInsurancePopup}
                  text="Insurance">
                  Insurance
                </Button>
              </Tooltip>
            </div>
          )}

          <Fieldset
            className={`row ${
              patientSelected || flagOrderEdit ? '' : 'visually-hidden'
            }`}>
            <legend>Order Type</legend>
            <Select
              options={state.orderTypeDropdown}
              name="orderType"
              validations="required:lazy"
              value={model.orderType}
              className={'col-md-3 ' + this.solveBackground('orderType', true)}
              tooltip={{
                theme: 'dark',
                overlay: 'Select the order type for this patient here',
                visible:
                  showToolTips &&
                  !model.orderType &&
                  (patientSelected || onEdit),
              }}
            />
            <FileUpload
              name="faceSheet"
              buttonLabel="Upload Face Sheet"
              label="&nbsp;"
              className="col-sm-6"
              value={model.tempFaceSheet || model.faceSheet}
              onSetValue={(n, v) => this.updateFacesheet(v)}
              onDelete={(v: React.MouseEvent) => this.updateFacesheet()}
              path={this.getViewPath()}
            />
          </Fieldset>
          <Fieldset
            className={`row align-items-end${
              patientSelected || flagOrderEdit ? '' : ' visually-hidden'
            }`}>
            <legend>Facility Information</legend>
            <StateShortNameDropdown
              name="facilityState"
              label="State"
              className="col-md-3"
            />
            <FacilityOnlyActiveDropdown name="facility" className="col-md-3" />
            <NumberInput
              format="###-###-####"
              name="facilityPhone"
              className="col-md-3"
            />

            {UserProfileStore.isClientUser() ? null : (
              <div
                className="col-md-3 mt-auto"
                style={{ marginBottom: '15px' }}>
                <Button
                  text="Add Facility"
                  fullWidth
                  onClick={this.addFacilityHandle}
                />
              </div>
            )}
          </Fieldset>

          <Fieldset
            className={`row ${hasDiffAddress ? '' : 'visually-hidden'}`}>
            <legend>Place of Service</legend>
            <Radio
              name="placeOfService"
              options={PLACE_OF_SERVICE}
              onSetValue={(n, v, e) => {
                this.setState({ posSelected: true }, () =>
                  this.onUpdateModel(n, v, e)
                );
              }}
              className="col-md-3 part-inline field-right"
              tooltip={{
                overlay: "Enter the patient's home address here",
                visible:
                  showToolTips &&
                  !state.posSelected &&
                  (patientSelected || onEdit) &&
                  hasDiffAddress,
                onVisibleChange: () => {},
                placement: 'bottom',
              }}
            />
            <Fieldset
              className={
                displayDiffAddress ? 'col-sm-9 row' : 'visually-hidden'
              }>
              <Text
                name="patient_diff_address"
                label="Address"
                className={
                  'col-sm-4 ' + this.solveBackground('patient_diff_address')
                }
                validations={displayDiffAddress ? 'required' : null}
              />
              <Text
                name="patient_diff_city"
                label="City"
                className={
                  'col-sm-4 ' + this.solveBackground('patient_diff_city')
                }
                validations={displayDiffAddress ? 'required' : null}
              />
              <StateShortNameDropdown
                name="patient_diff_state"
                label="State"
                className={
                  'col-sm-4 ' + this.solveBackground('patient_diff_state', true)
                }
                validations={displayDiffAddress ? 'required:lazy' : null}
              />

              <ZipCodeInput
                name="patient_diff_zipcode"
                label="Zip Code"
                className={
                  'col-sm-4 ' + this.solveBackground('patient_diff_zipcode')
                }
                validations={displayDiffAddress ? 'required' : null}
              />
              <PhoneInput
                format="###-###-####"
                name="patient_diff_phone"
                label="Phone"
                className={
                  'col-sm-4 ' + this.solveBackground('patient_diff_phone')
                }
                validations={displayDiffAddress ? 'required' : null}
              />
            </Fieldset>
          </Fieldset>

          <Fieldset
            className={`row ${
              !displayDiffAddress && (patientSelected || flagOrderEdit)
                ? ''
                : 'visually-hidden'
            }`}>
            <legend>Address Detail</legend>
            <div className="col-sm-6">
              <b>Address: </b>{' '}
              {facilityAddress.address ? (
                <>
                  <a
                    className="text-primary fs-5 me-2"
                    target="_blank"
                    href={buildGMapsLink(facilityAddress)}
                    rel="noreferrer">
                    <i className="bi bi-geo-alt" />
                  </a>
                  <span>{facilityAddress.address}</span>
                </>
              ) : (
                'N/A'
              )}
            </div>
            <div className="col-sm-3">
              <b>Email: </b> {facilityAddress.email || 'N/A'}
            </div>
            <div className="col-sm-3">
              <b>Phone: </b> {facilityAddress.phone || 'N/A'}
            </div>
          </Fieldset>

          <Fieldset className={`row ${showAddress ? '' : 'visually-hidden'}`}>
            <legend>Correctional Information</legend>
            <Text
              name="ice_number"
              label="ICE Number"
              className="col-sm-6 col-md-4"
            />
            <Text
              name="inmate_number"
              label="OID Number"
              className="col-sm-6 col-md-4"
            />
            <Text
              name="immigration_id"
              label="Marshall ID"
              className="col-sm-6 col-md-4"
            />
          </Fieldset>
          <Fieldset
            className={`row pt-4${
              patientSelected || (flagOrderEdit && onEdit && !patientSelected)
                ? ''
                : 'visually-hidden'
            }`}>
            <FormControl>
              <Button variant="warning" onClick={this.resetHandle} text="Reset">
                Reset
              </Button>
              <Button
                type="submit"
                disabled={!this.props.allowSubmit}
                text="Submit">
                Submit
              </Button>
            </FormControl>
          </Fieldset>
        </Form>
        {state.showSelectPatient && (
          <DialogSelectPatient
            onClose={this.handleCloseDialogPatient}
            onClickAddPatient={this.handleOpenAddPatientDialog}
            onSelectPatient={this.onSelectPatient}
          />
        )}
        {state.showAddPatient && (
          <DialogAddPatient
            onClose={this.handleCloseDialogNewPatient}
            onSubmit={this.afterPatientAdd}
          />
        )}
        {state.showAddFacility && (
          <DialogAddFacility
            onClose={this.handleCloseDialogFacility}
            onSucceed={this.handleAddFacilitySuccess}
          />
        )}
        {state.showChangeFacility && (
          <DialogConfirm
            onApprove={this.changeFacility}
            onCancel={this.handleCloseDialogChangeFacility}>
            Do you want to change the facility for this patient?
          </DialogConfirm>
        )}
        {state.isInsuranceDialogVisible && (
          <EligibilityPopup
            order={this.prepareInsurancePopupOrder()}
            eligibility="ONLY_SAVE"
            callback={(v: boolean, model: TEligibilityModel) =>
              this.closeInsurancePopup(v, model)
            }
          />
        )}
        {state.showPatientConfirmPopup && (
          <DialogConfirm
            onApprove={this.handleApproveDialogConfirmPatient}
            onCancel={this.handleCloseDialogConfirmPatient}>
            {state.patient.patient_comment} <br /> Are you sure you want to
            continue?
          </DialogConfirm>
        )}
        {this.renderOrderModifyWarningNotification()}
      </div>
    );
  }

  mergeInsuranceModel(insurance: TEligibilityModel) {
    const model = Object.assign(new TPiModel(), this.state.model);
    model.dob = insurance.dob;
    model.ice_number = insurance.iceNumber;
    model.inmate_number = insurance.inmateNumber;
    model.immigration_id = insurance.immigrationNumber;
    model.medicaid_id = insurance.medicaidId;
    model.medicare_id = insurance.medicareId;
    model.lastName = insurance.lastName;
    model.medicaid_stateid = insurance.coveredUnder;
    model.ins_notes = insurance.ins_notes;
    model.firstName = insurance.firstName;
    model.ssn = insurance.ssn;
    this.setState({ model }, () => this.props.onPopupOpen(false));
  }

  showInsurancePopup = () => {
    this.setState(
      { isInsuranceDialogVisible: true, insuranceTooltip: true },
      () => this.props.onPopupOpen(true)
    );
  };

  canShowInsurancePopup() {
    const order = this.prepareInsurancePopupOrder();
    return order.patientid && order.facilityid;
  }

  prepareInsurancePopupOrder() {
    const { model, patientViewInfo } = this.state;
    const facility = this.state.facilityAddress;
    const examData = this.state.examData;

    return {
      last_name: model.lastName,
      first_name: model.firstName,
      dob: model.dob,
      dos: dateToLocalTimezone({
        date: examData.sheduleservdate,
        dateOnly: true,
      }),
      ssn: model.ssn,
      refid: this.props.orderId || 0,
      iceNumber: model.ice_number,
      inmateNumber: model.inmate_number,
      immigrationNumber: model.immigration_id,
      state: storeDropdownState.findByShortName(model.facilityState)?.label,
      phone: model.facilityPhone,
      medicare_id: model.medicare_id,
      medicaid_id: model.medicaid_id,
      medicaid_stateid: model.medicaid_stateid,
      ins_notes:
        model.ins_notes === undefined ? this.props.ins_notes : model.ins_notes,
      patientid: model.patientId,
      facilitynm: facility.facility_name,
      facilityid: model.facility,
      correctionalFacility: model.orderType === 'C',
      face_sheet: model.faceSheet,
      clientMRN: patientViewInfo?.[0]?.clientMRN || model?.clientMRN || '',
    };
  }

  handleCloseDialogChangeFacility = () => {
    const model = { ...this.state.model, facility: this.state.oldFacility };
    this.setState({ showChangeFacility: false, model }, () => {
      this.updateFacilityInfo(POPUP_OPEN);
    });
    return;
  };

  changeFacility = () => {
    const model = { ...this.state.model };

    this.setState({ showChangeFacility: false }, () => {
      OrderFormPageActions.changeFacility(
        model.patientId,
        model.facility,
        this.state.oldFacility,
        this.props.orderId || 0
      )
        .then(() => {
          return OrderFormPageActions.loadPatientViewInfo(model.patientId);
        })
        .then(() => {
          model.facilityState = null;
          this.setState({ model }, () => this.updateFacilityInfo(POPUP_OPEN));
        });
    });
  };

  updateFacesheet = (value?: string) => {
    if (value) {
      this.onUpdateModel('tempFaceSheet', value, []);
    } else {
      if (this.state.model.tempFaceSheet) {
        this.onUpdateModel('tempFaceSheet', '', []);
      } else {
        this.onUpdateModel('faceSheet', '', []);
      }
    }
  };

  afterPatientConfirmed = () => {
    const { patient } = this.state;
    const model = AbstractOrderPage.addPatientToModel(
      patient,
      this.state.model,
      true
    );
    this.setState(
      { model, showSelectPatient: false, patientSelected: true },
      () => {
        this.props.onPopupOpen(false);
        this.updateFacilityInfo(POPUP_CLOSE);
      }
    );
  };

  onSelectPatient = (patient: PatientInfoExtendedType | PatientInfoType) => {
    if (patient.patient_comment) {
      this.setState({ showPatientConfirmPopup: true, patient });
    } else {
      const errors = {
        ...this.state.errors,
        patient: patient?.refid ? '' : this.state.errors.patient,
      };
      this.setState({ patient, errors }, () => this.afterPatientConfirmed());
    }
  };

  updateFacilityInfo(triggerPopup?: 1 | 2 | 3) {
    const id = Number(this.state.model.facility);
    if (id) {
      OrderFormPageActions.getOrderTypeByFacility(id)
        .then((arrOrderTypeByFacility) => {
          if (arrOrderTypeByFacility.length === 1) {
            const model = Object.assign({}, this.state.model);
            model.orderType = arrOrderTypeByFacility[0].value as
              | 'F'
              | 'O'
              | 'H'
              | 'C'
              | 'P'
              | '';
            this.setState({ model: model });
          }
          return OrderFormPageActions.loadFacilityAddress(id);
        })
        .then((r) => {
          const stateId = Number.isNaN(Number(r.state))
            ? r.state
            : storeDropdownState.findById(Number(r.state))?.shortName;
          const model = {
            ...this.state.model,
            facilityState: stateId,
            facilityPhone: r.phone,
          };

          this.setState(
            { model },
            triggerPopup === POPUP_NO_CHANGE
              ? null
              : () => this.props.onPopupOpen(triggerPopup === POPUP_OPEN)
          );

          return r;
        });
    } else {
      this.setState({ orderTypeDropdown: [] }, () =>
        this.props.onPopupOpen(false)
      );
    }
  }

  submitSuccess(modelIn?: any): Promise<any> {
    const { props, state } = this;
    if (!props.allowSubmit) {
      return;
    }
    const modelCurr = state.model;
    if (
      props.orderId &&
      (!state.dataLoaded ||
        !state.patientLoaded ||
        state.onAjax ||
        !modelCurr.facility ||
        0 === parseInt('' + modelCurr.facility))
    ) {
      Notification.warning('Wait dataload for submit!');
      return;
    }
    this.setState({ onAjax: true });
    return OrderFormPageActions.addPatientOrder(
      Object.assign(new TPatientOrder(), {
        clientMRN: modelCurr.clientMRN,
        callerName: props.callerName,
        order_based: modelCurr.orderType,
        patient_diff_state: modelCurr.patient_diff_state,
        patient_diff_phone: modelCurr.patient_diff_phone,
        order_based_type: modelCurr.placeOfService,
        patient_diff_zipcode: modelCurr.patient_diff_zipcode,
        orderid: props.orderId,
        patientid: modelCurr.patientId,
        facilityid: modelCurr.facility,
        facesheet: modelCurr.tempFaceSheet || modelCurr.faceSheet,
        Oldfacesheet: modelCurr.tempFaceSheet || modelCurr.faceSheet,
        //FileNameChange: false,//@todo add rename facesheet logic
        //tcbillprocess: modelCurr.billingResponsibility,
        patient_diff_address: modelCurr.patient_diff_address,
        patient_diff_city: modelCurr.patient_diff_city,
        lastModifiedLock: modelCurr.lastModifiedLock,
      }),
      {
        ice_number: modelCurr.ice_number,
        immigration_id: modelCurr.immigration_id,
        inmate_number: modelCurr.inmate_number,
      }
    ).then((resp: 'SE' | Array<TAddPatientOrderRes>) => {
      if (!resp || resp === 'SE' || !resp[0] || !resp[0].success) {
        if (resp === 'SE') {
          this.setState({ onAjax: false }, () => {
            Notification.warning('Data submit error!');
          });
        } else {
          this.showOrderModifyWarningNotification();
        }
        return;
      }
      let modelTmp = Object.assign(new TPiModel(), this.state.model);
      if (modelTmp.tempFaceSheet) {
        modelTmp = Object.assign(modelTmp, {
          tempFaceSheet: '',
          faceSheet: modelTmp.tempFaceSheet,
        });
      }
      modelTmp.lastModifiedLock = resp[0].lastModifiedLock;
      const state4Update: {
        onAjax: boolean;
        model: TPiModel;
      } = {
        onAjax: false,
        model: modelTmp,
      };
      this.setState(state4Update, () => {
        this.props.submitSuccess(
          resp[0].orderId,
          this.state,
          resp[0].lastModifiedLock
        );
      });
    });
  }

  showSelectPatientPopup = () => {
    this.setState({ showSelectPatient: true }, () =>
      this.props.onPopupOpen(true)
    );
  };

  getViewPath() {
    const { model } = this.state;

    if (model.tempFaceSheet) {
      return `${BASE_URL_FILE_DIR}tempfolder/`;
    } else if (model.faceSheet) {
      return `${BASE_URL_FILE_DIR}doc_img/externaldoc/`;
    }
  }

  handleCloseDialogNewPatient = () => {
    this.setState({ showAddPatient: false });
  };

  afterPatientAdd = (patientAdd: PatientInfoType) => {
    this.handleCloseDialogNewPatient();

    this.onSelectPatient(patientAdd);
  };

  handleCloseDialogFacility = () => {
    this.setState({ showAddFacility: false });
  };

  handleAddFacilitySuccess = (facility: number) => {
    this.setState({ showAddFacility: false }, () =>
      this.onUpdateModel('facility', facility, [])
    );
  };

  onUpdateModel(
    name: string,
    value: any,
    error: any,
    triggerPopupClb?: () => void
  ) {
    const clickable = ['dob', 'lastName', 'middleName', 'firstName', 'ssn'];
    if (clickable.indexOf(name) === -1) {
      let clb = null;
      const model = this.state.model;
      if (name === 'facility') {
        if (!model.patientId) {
          clb = () => {
            this.setState({ showSelectPatient: true }, () =>
              this.onUpdateModel('orderType', null, [], triggerPopupClb)
            );
          };
        } else {
          clb = () => {
            this.setState(
              { showChangeFacility: true, oldFacility: model.facility },
              () => this.onUpdateModel('orderType', null, [], triggerPopupClb)
            );
          };
        }
      } else if (name === 'orderType') {
        if (value === 'P' || value === 'O') {
          clb = () =>
            this.onUpdateModel('placeOfService', 'PH', [], triggerPopupClb);
        } else if (value === 'H') {
          if (!this.state.model.placeOfService) {
            clb = () =>
              this.onUpdateModel('placeOfService', 'PH', [], triggerPopupClb);
          }
        } else {
          clb = () =>
            this.onUpdateModel('placeOfService', 'F', [], triggerPopupClb);
        }
      }
      if (clb === null && triggerPopupClb) {
        clb = () => this.props.onPopupOpen(false);
      }
      this.localUpdateModel(name, value, error, clb);
    }
  }

  localUpdateModel(name: string, value: string, err?: any, clb?: () => void) {
    const { state } = this;

    const model = {
      ...state.model,
      [name]: value,
    };

    const errors = {
      ...state.errors,
      [name]: err,
    };

    this.setState({ model, errors }, typeof clb === 'function' ? clb : null);
  }

  closeInsurancePopup(v: boolean, model: TEligibilityModel) {
    this.setState({ isInsuranceDialogVisible: false }, () =>
      v ? this.mergeInsuranceModel(model) : undefined
    );
  }
}

export default Container.create(PatientInformation as any, { withProps: true });
