import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/FormPayerInfo';

import {
  storePayerInfo,
  FormModel,
} from 'stores/_mobx/systemSetup/masterSetting/payerInfo';
import { URL_PAYER_INFO } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const PayerInfoEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, payerInfo } = storePayerInfo;

  const handleSubmit = async (data: FormModel) =>
    storePayerInfo.updatePayerInfo(data).then((error) => {
      if (!error) history.push(URL_PAYER_INFO);
    });

  useEffect(() => {
    if (!fetching) storePayerInfo.getPayerInfo(Number(params.id));

    return storePayerInfo.clearPayerInfo;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Payer Information" />
      <Form
        backUrl={URL_PAYER_INFO}
        defaultValues={payerInfo}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(PayerInfoEditPage);
