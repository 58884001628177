import { ReduceStore } from 'flux/utils';
import appDispatcher from 'dispatcher/AppDispatcher';

let buildActionName = function (name) {
  return 'reports/report-creator/facility-patient/' + name;
};

export let Actions = {
  LOAD_FACILITY_PATIENT_LIST: buildActionName('load-facility-patient-list'),
};

class FacilityPatientPageStore extends ReduceStore {
  getEmptyDiagram() {
    return [['Facility', 'Patient count']];
  }

  getInitialState() {
    return {
      facilityPatientList: [],
      facilityPatientDiagram: this.getEmptyDiagram(),
    };
  }

  reduce(state, action) {
    switch (action.type) {
      case Actions.LOAD_FACILITY_PATIENT_LIST:
        this.getState().facilityPatientList = action.data.dataval || [];
        this.getState().facilityPatientDiagram =
          action.data.chartval || this.getEmptyDiagram();
        break;
      default:
        break;
    }
    return this.getState();
  }

  areEqual(one, two) {
    return false;
  }
}

const facilityPatientPageStore = new FacilityPatientPageStore(appDispatcher);
export default facilityPatientPageStore;
