import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    name: yup.string().required("Suffix name can't be empty!"),
    description: yup.string().required("Suffix description can't be empty!"),
  })
);

export default validation;
