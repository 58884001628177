import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Tabs from 'components/tabs';
import Dialog, { DialogHeader, DialogBody } from 'components/modal/dialog';
import SelfPayTab from './SelfPayTab';
import FacilityTab from './FacilityTab';
import InsurancesTab from './InsurancesTab';

import { storeClaimReview } from 'stores/_mobx/billingCodding/claimReview';

const tabsList = [
  { label: 'Self pay' },
  { label: 'Facility' },
  { label: 'Insurance' },
];

interface PropsType {
  params: { facilityId: number; patientId: number };
  onClose: () => void;
}

const DialogReview = ({ params, onClose }: PropsType) => {
  const { fetchingOrderDetails, info } = storeClaimReview;

  const [currentTab, setTab] = useState<number>(0);

  useEffect(() => {
    storeClaimReview.getClaimDetails(params);

    return storeClaimReview.clearInfo;
  }, [params]);

  const dialogTitle = tabsList[currentTab].label || '';

  return (
    <Dialog size="large" scrollable handleClose={onClose}>
      <DialogHeader title={dialogTitle} onClose={onClose} />
      <DialogBody className={fetchingOrderDetails ? ' on-ajax' : ''}>
        <Tabs className="mb-4" tabsList={tabsList} onClick={setTab}>
          <SelfPayTab guarantor={info?.guarantor} />

          <FacilityTab facility={info?.facility} />

          <InsurancesTab insurances={info?.insurances} />
        </Tabs>
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogReview);
