import React from 'react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';

export const defaultFilter: FilterType = {
  dateFrom: '',
  dateTo: '',
  period: 'A',
  emailTemplateSelect: '',
};

interface EmailTemplateResponse {
  templateCode: string;
  templateName4Ui: string;
}

interface OptionType {
  label: string;
  value: string;
}

export interface FilterType {
  emailTemplateSelect: string;
  dateFrom: string;
  dateTo: string;
  period: 'M' | 'W' | 'A' | 'TD';
}

export interface EmailReportType {
  ct_name: string;
  file_nm: string;
  msg_body: string;
  rec_mail: string;
  refid: number;
  sending_date: string;
  template_flag: string;
  template_name: string;
}

class EmailReport {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  emailReport: EmailReportType[] = [];
  emailsReportTotal: number = 0;
  message: string = '';
  optionsEmailTemplate: OptionType[] = [];
  filesToDisplay: string[] = [];
  filter: FilterType = defaultFilter;
  page: Pagination = new Pagination({ id: 'emailReportGrid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      emailReport: observable,
      emailsReportTotal: observable,
      optionsEmailTemplate: observable,
      filesToDisplay: observable,
      message: observable,
      filter: observable,

      setFetching: action,
      setEmailReport: action,
      setEmailReportTotal: action,
      setMessagePreview: action,
      setFilter: action,
      setFilesToDisplay: action,
      clearMessagePreview: action.bound,
      clearFilesToDisplay: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setEmailReport(list: EmailReportType[]) {
    this.emailReport = list;
  }

  setEmailReportTotal = (count: number) => {
    this.emailsReportTotal = count;
  };

  setMessagePreview = (id: number) => {
    const email = this.emailReport.find(({ refid }) => refid === id);
    this.message = email?.msg_body || '';
  };

  setFilter = (filter: FilterType) => {
    this.filter = filter;
  };

  setFilesToDisplay = (fileName: string) => (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.filesToDisplay = fileName.split('|');
  };

  clearMessagePreview() {
    this.message = '';
  }

  clearFilesToDisplay = () => {
    this.filesToDisplay = [];
  };

  async getEmailReport(data: any[]) {
    try {
      const response = await apiRequest<'SE' | EmailReportType[]>({
        url: 'email.Email.GetEmailList',
        data,
      });
      if (Array.isArray(response)) {
        const logs = response.map((log) => ({
          ...log,
          sending_date: dateToLocalTimezone({
            date: log.sending_date,
          }),
          refid: Number(log.refid),
        }));
        this.setEmailReport(logs);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setEmailReport([]);
    }
  }

  async getEmailReportCount(data: any[]) {
    try {
      const count = await apiRequest<'SE' | number>({
        url: 'email.Email.GetEmailListCount',
        data,
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setEmailReportTotal(total);
    } catch (e) {
      this.setEmailReportTotal(0);
    }
  }

  getEmailReportMain = async () => {
    const {
      filter,
      page: { pagination },
    } = this;

    this.setFetching(true);

    const { dateFrom, dateTo } = getDateRangeBounds({
      from: filter.dateFrom,
      to: filter.dateTo,
    });

    const data = [filter.emailTemplateSelect, dateFrom, dateTo];

    const promiseList = this.getEmailReport([
      ...data,
      pagination.skip,
      pagination.pageSize,
    ]);

    const promiseCount = this.getEmailReportCount(data);

    return Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  getEmailTemplateList = async () => {
    try {
      if (this.optionsEmailTemplate.length || this.fetchingOptions)
        return Promise.resolve();

      runInAction(() => {
        this.fetchingOptions = true;
      });

      const response = await apiRequest<EmailTemplateResponse[]>({
        url: 'email.Email.getEmailTemplateFlagLookUp',
      });

      const options = response.map((option) => ({
        label: option.templateName4Ui,
        value: option.templateCode,
      }));
      runInAction(() => {
        this.fetchingOptions = false;
        this.optionsEmailTemplate = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
      });
    }
  };
}

export const storeEmailReport = new EmailReport();
