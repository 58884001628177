import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Textarea } from 'components/form/textarea';
import { Input } from 'components/form/textField';
import resolver from './validation';

const initialValues: FormModel = {
  pos: '',
  pos_code: '',
  pos_desc: '',
};

export interface FormModel {
  pos: string;
  pos_code: string;
  pos_desc: string;
  refid?: number;
}

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormPlaceOfService = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
  } = useForm({ defaultValues, resolver });

  const handleReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form className="col-lg-9" onSubmit={handleSubmit(onSubmit)}>
      <Input name="pos_code" label="Code" required control={control} />
      <Input name="pos" label="Place of service" required control={control} />
      <Textarea
        name="pos_desc"
        label="Description"
        rows={10}
        control={control}
      />
      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormPlaceOfService;
