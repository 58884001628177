import { apiRequest } from 'services/RequestService';

class PhysicianPatientPageService {
  loadList(conditionList, joint) {
    return apiRequest({
      url: 'report.ReportMaster.GetstudiesReport',
      data: [conditionList, joint],
    }).then((response) => {
      if (response && response.chartval) {
        var newChartValues = [];
        newChartValues.push(['Data', 'Value']);
        let chartValues = response.chartval;
        chartValues.forEach((item) => {
          newChartValues.push([item.physicianname, parseInt(item.phycount)]);
        });
        response.chartval = newChartValues;
        return response;
      } else {
        return {};
      }
    });
  }

  createPdf(list, description) {
    return apiRequest({
      url: 'pdfcreater.OrderPdfGenerator.GetStudiesPDF',
      data: [list, description],
    });
  }
}
export default new PhysicianPatientPageService();
