import PreInvoicesService, {
  TFacilityInvoiceFilter,
  TOrderPreinvoiceMultipleInsertUpdate,
  TSaveEmail,
  TInvoiceItem,
  TDueData,
} from 'services/billing-coding/PreInvoicesService';
import appDispatcher from 'dispatcher/AppDispatcher';
import { TPagination } from 'components/grid/GridTypes';
import { getDateRangeBounds } from 'utils/DateUtils';

export default class PreInvoicesActions {
  static async loadGridData(
    filter: TFacilityInvoiceFilter,
    pagination: TPagination,
    invoiceType: 'F' | 'D' | 'H',
    signal: AbortSignal
  ) {
    try {
      const dos = getDateRangeBounds({
        from: filter.dosStart,
        to: filter.dosEnd,
      });

      const filterFormatted = {
        ...filter,
        dosStart: dos.dateFrom,
        dosEnd: dos.dateTo,
      };
      const promiseCount = PreInvoicesService.loadOverviewCount(
        filterFormatted,
        invoiceType,
        signal
      );
      const promiseList = PreInvoicesService.loadOverview(
        filterFormatted,
        pagination,
        invoiceType,
        signal
      );
      const [countResponse, listResponse] = await Promise.all([
        promiseCount,
        promiseList,
      ]);
      const count = countResponse === 'SE' ? 0 : countResponse;
      const response = Array.isArray(listResponse) ? listResponse : [];

      appDispatcher.dispatch({
        type: 'pre-invoice-overview',
        data: response,
        count: count,
      } as any);
      return response;
    } catch (error) {
      return [];
    }
  }

  static clearStore() {
    appDispatcher.dispatch({
      type: 'invoice-overview',
      data: [],
    });
  }

  static preinvoiceMultipleInsertUpdate(
    orders: Array<TOrderPreinvoiceMultipleInsertUpdate>,
    perDiem: string,
    dosStart: string,
    dosEnd: string,
    invoiceType: 'F' | 'D' | 'H' | 'P' | '',
    lastName: string,
    firstName: string
  ) {
    return PreInvoicesService.preinvoiceMultipleInsertUpdate(
      orders,
      perDiem,
      dosStart,
      dosEnd,
      invoiceType,
      lastName,
      firstName
    );
  }

  static email(email: string, orders: Array<TSaveEmail>) {
    return PreInvoicesService.email(email, orders);
  }

  static insertUpdate(
    invoiceNo: number,
    invoiceArr: Array<TInvoiceItem>,
    facilityId: number,
    perDiem: 'Y' | 'N',
    invoiceType: 'F' | 'D' | 'H' | 'P' | '',
    pdUnits: string,
    mode: 'A' | 'E',
    perdiemName: string,
    perdiemPrice: string,
    due: TDueData
  ) {
    return PreInvoicesService.insertUpdate(
      invoiceNo,
      invoiceArr,
      facilityId,
      perDiem,
      invoiceType,
      pdUnits,
      mode,
      perdiemName,
      perdiemPrice,
      due
    );
  }
}
