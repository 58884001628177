import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Textarea } from 'components/form/textarea';
import { Input } from 'components/form/textField';
import { Button } from 'components/button';
import resolver from './validation';

import { ModifierFormModel } from 'stores/_mobx/systemSetup/codeSet/modifiers';

const initialValues: ModifierFormModel = {
  comment: '',
  code: '',
  description: '',
  id: null,
};

interface PropsType {
  backUrl: string;
  defaultValues?: ModifierFormModel;
  onSubmit: (data: ModifierFormModel) => Promise<any>;
}

const ModifierForm = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting, isDirty },
  } = useForm({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: ModifierFormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('code', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={`col-lg-6${isSubmitting ? ' on-ajax' : ''}`}
      onSubmit={handleClickSubmit}>
      <Input name="code" label="Modifier Code" required control={control} />

      <Textarea
        name="description"
        label="Description"
        required
        control={control}
      />

      <Textarea name="comment" label="Comments" rows={4} control={control} />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          text="Reset"
          variant="warning"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button type="submit" text="Save" disabled={isSubmitting || !isDirty} />
      </ControlsLayout>
    </form>
  );
};

export default ModifierForm;
