import { apiRequest } from 'services/RequestService';

class OrderReportPageService {
  loadOrderReportList(conditionList, joint, startIndex, limit, oldListCount) {
    const data = [conditionList, joint, startIndex, limit, oldListCount];

    return apiRequest({
      url: 'report.ReportMaster.getExamOrderReport',
      data,
    }).then((response) => {
      if (response && response.chartval) {
        const newChartValues = [];
        newChartValues.push(['Order type', 'Count']);
        const chartValues = response.chartval;
        chartValues.forEach((item) => {
          newChartValues.push([item.ordertype, parseInt(item.CompOrders)]);
        });
        response.chartval = newChartValues;
        return response;
      }
      return {};
    });
  }

  createPdf(facilityPatientList, filter) {
    return apiRequest({
      url: 'pdfcreater.OrderPdfGenerator.GetGeneratePDF',
      data: [facilityPatientList, filter],
    });
  }
}

export default new OrderReportPageService();
