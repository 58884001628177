import React from 'react';

import { Grid } from 'components/grid';
import Title from 'components/project/common/title';

import TimeSheetPageService from 'services/profile/personal/TimeSheetPageService';
import { storeGrid } from 'stores/_mobx/grid';
import { getPagination } from 'utils/getPagination';
import { CLOCK_IN as PAGE_ID } from 'constant/pagesId/workflow';

export default class ClockIn extends React.Component {
  state = {
    onAjax: false,
    dataSource: [],
    dataSourceCount: 0,
    pagination: getPagination(storeGrid.getPageSize('clock_in')),
  };

  onPaginationChange = (pagination) => {
    this.setState({ pagination }, () => this.updateData());
  };

  componentDidMount() {
    this.updateData();
  }

  updateData() {
    this.setState(
      {
        onAjax: true,
      },
      () =>
        TimeSheetPageService.getClockedIn(this.state.pagination).then(
          (response) => {
            this.setState({
              dataSource: response.data,
              dataSourceCount: response.count,
              onAjax: false,
            });
          }
        )
    );
  }

  getColumns() {
    return [
      { name: 'employee_name', title: 'Employee Name' },
      { name: 'user_type', title: 'User Type' },
      { name: 'clock_start', title: 'Time Clocked In' },
    ];
  }

  render() {
    return (
      <>
        <Title appId={PAGE_ID.PAGE} title="Employees Clocked In" />
        <Grid
          id="clock_in"
          columns={this.getColumns()}
          onAjax={this.state.onAjax}
          dataSource={this.state.dataSource}
          dataSourceCount={this.state.dataSourceCount}
          pagination={this.state.pagination}
          onPaginationChange={this.onPaginationChange}
        />
      </>
    );
  }
}
