import { useState } from 'react';

import { IconButton } from 'components/button';

import {
  storeLegacyPatientData,
  PatientType,
} from 'stores/_mobx/patientRecord/legacyPatientData';

interface PropsType {
  patient: PatientType;
}

const DiagnosticViewingLink = ({ patient }: PropsType) => {
  const [fetching, setFetching] = useState<boolean>(false);

  const handleClick = () => {
    setFetching(true);
    storeLegacyPatientData.openEUnityLinkByParams(patient).then(() => {
      setFetching(false);
    });
  };

  return (
    <IconButton disabled={fetching} onClick={handleClick}>
      <i className="icon icon-eunity" />
    </IconButton>
  );
};

export default DiagnosticViewingLink;
