import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import { TClinicianGroup } from 'actions/privileges/GroupManagerActions';

export class SGroupManagerStore {
  flagClinicalGroupDataLoaded: boolean = false;
  clinicalGroupData: Array<TClinicianGroup> = [];
}

class GroupManagerStore extends ReduceStore<SGroupManagerStore, CommonPayload> {
  getInitialState() {
    return new SGroupManagerStore();
  }

  areEqual(state1: SGroupManagerStore, state2: SGroupManagerStore) {
    return false;
  }

  reduce(state: SGroupManagerStore, action: CommonPayload) {
    switch (action.type) {
      case 'groupmanager.GroupManager.GetAllClinicianGroup':
        this.getState().flagClinicalGroupDataLoaded = true;
        this.getState().clinicalGroupData = action.data;
        break;
      default:
        break;
    }
    return state;
  }
}

const groupManagerStore = new GroupManagerStore(appDispatcher);
export default groupManagerStore;
