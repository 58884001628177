import Grid from '../Grid';

export default class SimpleFacade {
  columns: Array<any>;

  constructor(columns: Array<any>, grid: Grid) {
    const out: Array<any> = [];
    const { state } = grid;
    //populate out with columns from props, or populate temp array 'sortedColumns' in case drag-n-drop order present
    const sortedColumns: Array<any> = [];

    if (columns !== undefined) {
      for (let i = 0; i < columns.length; i++) {
        let column = columns[i];
        if (state.shownColumns.get(column.name) === false) {
          continue;
        }
        if (state.columnsOrder) {
          sortedColumns.push(column);
        } else {
          out.push(column);
        }
      }
    }

    //populate out with columns in case if drag-n-drop order present
    if (state.columnsOrder) {
      let realColumnOrder = [...state.columnsOrder];
      //additional check, for case when columns from props is different from columns drag-n-drop
      for (let i = 0; i < columns.length; i++) {
        let col = columns[i];
        if (realColumnOrder.indexOf(col.name) === -1) {
          realColumnOrder.push(col.name);
        }
      }
      for (let i = 0; i < realColumnOrder.length; i++) {
        let sortColumnName = realColumnOrder[i];
        for (let j = 0; j < sortedColumns.length; j++) {
          if (sortColumnName === sortedColumns[j].name) {
            out.push(sortedColumns[j]);
            break;
          }
        }
      }
    }
    this.columns = out;
  }

  getColumns = () => {
    return this.columns;
  };
}
