import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import { FakeInput } from 'components/form/textField';
import { Textarea } from 'components/form/textarea';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

export interface BulkInvoiceFormType {
  bal: number;
  discount: number;
  feeSchedule: number;
  modeOfPayment: number;
  notes: string;
  payment: number;
  total: number;
}

interface PropTypes {
  defaultValues: BulkInvoiceFormType;
  onSubmit: (payload: BulkInvoiceFormType) => Promise<any>;
}

const FormInvoiceLineMode = ({ defaultValues, onSubmit }: PropTypes) => {
  const {
    control,
    formState: { isSubmitting },
    reset,
    handleSubmit,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form className="row" onSubmit={handleSubmit(onSubmit)}>
      <Textarea
        name="notes"
        label="Notes"
        className="col-sm-12"
        rows={1}
        required
        control={control}
      />
      <FakeInput
        name=""
        label="Balance Total:"
        className={clsx(
          'col-sm-6 col-md-3',
          defaultValues.total < 0.01 ? 'color-green' : 'text-danger'
        )}
        value={`$${defaultValues.total.toFixed(2)}`}
      />
      <ControlsLayout alignX="auto">
        <Button
          text="Apply"
          type="submit"
          data-testid="submit"
          disabled={isSubmitting}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormInvoiceLineMode;
