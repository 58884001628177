import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import { Grid } from 'components/grid';
import Filter from './components/Filter';

import { storeLoginLog, FilterType } from 'stores/_mobx/users/login';
import { LOGIN_LOG as PAGE_ID } from 'constant/pagesId/privileges';

const COLUMNS = [
  { name: 'user_name', title: 'Name' },
  { name: 'username', title: 'User Name' },
  { name: 'usertype_id', title: 'User Type' },
  { name: 'date', title: 'Date', className: 'width-100' },
  { name: 'login_time', title: 'Login Time', className: 'width-150' },
  { name: 'logout_time', title: 'Logout Time', className: 'width-150' },
  { name: 'woking_hours', title: 'Working Hours' },
];

const LoginLogPage = () => {
  const {
    fetching,
    logs,
    logsTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeLoginLog;

  const [isFilterVisible, setFilterVisibility] = useState<boolean>(false);

  const toggleFilterVisibility = () => {
    setFilterVisibility((state) => !state);
  };

  const handleSearch = (filter: FilterType) => {
    storeLoginLog.setFilter(filter);

    setPaginationToStart();
  };

  useEffect(() => {
    storeLoginLog.getLoginLogMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Login Log">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={toggleFilterVisibility}
        />
      </LayoutSideTitle>

      <Filter
        isVisible={isFilterVisible}
        fetching={fetching}
        onSubmit={handleSearch}
      />

      <Grid
        id="current_activity_log"
        columns={COLUMNS}
        onAjax={fetching}
        dataSource={logs}
        dataSourceCount={logsTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
    </>
  );
};

export default observer(LoginLogPage);
