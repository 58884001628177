import SignOrdersPageService, {
  TSignOrderFilter,
  TBillingAssignmentEmail,
  TBillingAssignmentFax,
} from 'services/clinician-manager/sign-orders/SignOrdersPageService';
import { Actions } from 'stores/clinician-manager/sign-orders/SignOrdersPageStore';
import appDispatcher from 'dispatcher/AppDispatcher';

export default class SignOrdersPageActions {
  static sendEmail(item: TBillingAssignmentEmail) {
    return SignOrdersPageService.sendEmail(item);
  }

  static confirmSign(
    id: number,
    userId: number = undefined,
    userType: string = undefined
  ) {
    return SignOrdersPageService.confirmSign(id);
  }

  static updateSignStatusByStaff(orderIds: Array<number>) {
    return SignOrdersPageService.updateSignStatusByStaff(orderIds);
  }

  static sendFax(item: TBillingAssignmentFax) {
    return SignOrdersPageService.sendFax(item);
  }

  static loadSignOrdersCount(item: TSignOrderFilter) {
    return SignOrdersPageService.loadSignOrdersCount(item).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_COUNT_ACTION,
        data: response,
      });
      return response;
    });
  }

  static loadSignOrdersList(item: TSignOrderFilter) {
    return SignOrdersPageService.loadSignOrdersList(item).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_LIST_ACTION,
        data: response,
      });
      return response;
    });
  }

  static loadOverview(item: TSignOrderFilter) {
    return SignOrdersPageActions.loadSignOrdersCount(item).then((response) => {
      if (response > 0) {
        return SignOrdersPageActions.loadSignOrdersList(item);
      }
      appDispatcher.dispatch({
        type: Actions.LOAD_LIST_ACTION,
        data: [],
      });
      return [];
    });
  }

  static updateTabsState(currentTab: any, blocked: any) {
    return appDispatcher.dispatch({
      type: Actions.UPDATE_TABS_STATE,
      data: {
        currentTab,
        blocked,
      },
    });
  }

  static clear() {
    appDispatcher.dispatch({
      type: Actions.CLEAR_STORE_ACTION,
      data: {},
    });
  }
}
