import EmployeeTimeSheetPageService from 'services/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageService';
import { Actions } from 'stores/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageStore';
import appDispatcher from 'dispatcher/AppDispatcher';

export default class EmployeeTimeSheetPageActions {
  static clear() {
    appDispatcher.dispatch({
      type: Actions.CLEAR_STORE_ACTION,
      data: {},
    });
  }

  static getPayrollReportCount(filter) {
    return EmployeeTimeSheetPageService.getPayrollReportCount(filter).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_COUNT_ACTION,
          data: response,
        });
      }
    );
  }

  static getPayrollReportList(filter, skip, pageSize) {
    return EmployeeTimeSheetPageService.getPayrollReportList(
      filter,
      skip,
      pageSize
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_LIST_ACTION,
        data: response,
        filter,
      });
    });
  }

  static getHrAdminReportCount(filter) {
    return EmployeeTimeSheetPageService.getHrAdminReportCount(filter).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_COUNT_ACTION,
          data: response,
        });
        return response;
      }
    );
  }

  static getHrAdminReportList(filter, skip, pageSize) {
    return EmployeeTimeSheetPageService.getHrAdminReportList(
      filter,
      skip,
      pageSize
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_LIST_ACTION,
        data: response,
        filter,
      });
      return response;
    });
  }

  static createPdf(list, description) {
    return EmployeeTimeSheetPageService.createPdf(list, description);
  }

  static clearSuperVisorIdsList() {
    appDispatcher.dispatch({
      type: Actions.CLEAR_SUPERVISOR_IDS_LIST_ACTION,
      data: {},
    });
  }

  static getSuperVisorIdsList() {
    return EmployeeTimeSheetPageService.getSuperVisorIdsList().then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_SUPERVISOR_IDS_LIST_ACTION,
          data: response,
        });
      }
    );
  }

  static clearEmployeeIdsLists() {
    appDispatcher.dispatch({
      type: Actions.CLEAR_EMPLOYEE_IDS_LISTS_ACTION,
      data: {},
    });
  }

  static getEmployeeIdsList(superVisorId) {
    return EmployeeTimeSheetPageService.getEmployeeIdsList(superVisorId).then(
      (response) => {
        appDispatcher.dispatch({
          superVisorId,
          type: Actions.LOAD_EMPLOYEE_IDS_LIST_ACTION,
          data: response,
        });
      }
    );
  }

  static loadSuperVisorAssignedEmployeesList(superVisorId) {
    return EmployeeTimeSheetPageService.loadSuperVisorAssignedEmployeesList(
      superVisorId
    ).then((response) => {
      appDispatcher.dispatch({
        superVisorId,
        type: Actions.LOAD_SUPERVISOR_ASSIGNED_EMPLOYEES_LIST_ACTION,
        data: response,
      });
    });
  }

  static assignSuperVisors(superVisorIdList) {
    return EmployeeTimeSheetPageService.assignSuperVisors(superVisorIdList);
  }

  static assignEmployees(superVisorId, employeeIdList) {
    return EmployeeTimeSheetPageService.assignEmployees(
      superVisorId,
      employeeIdList
    );
  }

  static assignSupervisorsEmployees(employeeIdsLists) {
    return EmployeeTimeSheetPageService.assignSupervisorsEmployees(
      employeeIdsLists
    );
  }

  static assignSuperVisors4Employee(employeeId, superVisorIdList) {
    return EmployeeTimeSheetPageService.assignSuperVisors4Employee(
      employeeId,
      superVisorIdList
    );
  }

  static approveTimeSheet(refid, filter) {
    return EmployeeTimeSheetPageService.approveTimeSheet(refid, filter);
  }

  static getSuperVisorsFullCount(filter) {
    return EmployeeTimeSheetPageService.getSuperVisorsFullCount(filter).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_SUPERVISORS_FULL_COUNT_ACTION,
          data: response,
        });
      }
    );
  }

  static getSuperVisorsFullList(filter, skip, pageSize) {
    return EmployeeTimeSheetPageService.getSuperVisorsFullList(
      filter,
      skip,
      pageSize
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_SUPERVISORS_FULL_LIST_ACTION,
        data: response,
        filter,
      });
    });
  }

  static getEmployeesFullCount(filter) {
    return EmployeeTimeSheetPageService.getEmployeesFullCount(filter).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_EMPLOYEES_FULL_COUNT_ACTION,
          data: response,
        });
      }
    );
  }

  static getEmployeesFullList(filter, skip, pageSize) {
    return EmployeeTimeSheetPageService.getEmployeesFullList(
      filter,
      skip,
      pageSize
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_EMPLOYEES_FULL_LIST_ACTION,
        data: response,
        filter,
      });
    });
  }

  static getUsersFullCount(filter) {
    return EmployeeTimeSheetPageService.getUsersFullCount(filter).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_USERS_FULL_COUNT_ACTION,
          data: response,
        });
      }
    );
  }

  static getUsersFullList(filter, skip, pageSize) {
    return EmployeeTimeSheetPageService.getUsersFullList(
      filter,
      skip,
      pageSize
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_USERS_FULL_LIST_ACTION,
        data: response,
        filter,
      });
    });
  }

  static getCompanyUsersFullCount(filter) {
    return EmployeeTimeSheetPageService.getCompanyUsersFullCount(filter).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_COMPANY_USERS_FULL_COUNT_ACTION,
          data: response,
        });
      }
    );
  }

  static getCompanyUsersFullList(filter, skip, pageSize) {
    return EmployeeTimeSheetPageService.getCompanyUsersFullList(
      filter,
      skip,
      pageSize
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_COMPANY_USERS_FULL_LIST_ACTION,
        data: response,
        filter,
      });
    });
  }

  static sendNotifyMessage(selected, model) {
    return EmployeeTimeSheetPageService.sendNotifyMessage(selected, model);
  }

  static getHrAdminOverview(filter, skip, pageSize) {
    return EmployeeTimeSheetPageActions.getHrAdminReportCount(filter).then(
      (count) => {
        if (count > 0) {
          return EmployeeTimeSheetPageActions.getHrAdminReportList(
            filter,
            skip,
            pageSize
          );
        } else {
          const out = [];
          appDispatcher.dispatch({
            type: Actions.LOAD_LIST_ACTION,
            data: out,
            filter: filter,
          });
          return out;
        }
      }
    );
  }

  static lockTimesheet(ids, dateFrom, dateTo) {
    return EmployeeTimeSheetPageService.updateLockTimesheet(
      ids,
      true,
      dateFrom,
      dateTo
    );
  }

  static unLockTimesheet(id, dateFrom, dateTo) {
    return EmployeeTimeSheetPageService.updateLockTimesheet(
      [id],
      false,
      dateFrom,
      dateTo
    );
  }
}
