import { ColDef, ColumnState } from 'ag-grid-community';

import {
  WrappedColumnSettingsPropsType,
  GridColumnVisibilityDefaultValuesType,
} from './types';

export const defaultValueForGridColumnVisibility = (
  columnState: WrappedColumnSettingsPropsType['columnState'],
  columnDefs: WrappedColumnSettingsPropsType['columnDefs']
) => {
  const columns = columnState.map(({ colId, hide }) => {
    const column = columnDefs
      .reduce((prev: ColDef[], curr) => {
        if ('children' in curr) {
          const children = curr.children.map((col) => ({
            ...col,
            headerName: `[${curr.headerName}] ${col.headerName}`,
          }));

          return prev.concat(children);
        }
        return prev.concat(curr);
      }, [] as ColDef[])
      .find((col) =>
        'colId' in col ? col.colId === colId : col.field === colId
      );

    return {
      colId,
      isVisible: !hide,
      label: column.headerName,
    };
  });

  const sortedColumns = columns.sort((a, b) => a.label.localeCompare(b.label));

  const selectAll = columns.every(({ isVisible }) => isVisible);

  return {
    search: '',
    selectAll,
    columns: sortedColumns,
  };
};

export const checkboxValuePrettier = (
  formValue: GridColumnVisibilityDefaultValuesType
) => {
  const { selectAll } = formValue;

  return formValue.columns.reduce(
    (prev, col) => {
      const column = {
        ...col,
        isVisible: selectAll,
      };

      const state = {
        colId: col.colId,
        hide: !selectAll,
      };

      return {
        columns: prev.columns.concat(column),
        state: prev.state.concat(state),
      };
    },
    { columns: [], state: [] }
  );
};

export const currencyFormatter = ({ value }: { value: any }) => `$${value}`;

export const throttle = (method: () => void, delay: number = 300) => {
  let canBeRun = true;

  function wrapper() {
    if (canBeRun) {
      canBeRun = false;

      setTimeout(function () {
        canBeRun = true;
        method();
      }, delay);
    }
  }

  return wrapper;
};

export const dateComparator = (date1: string, date2: string) => {
  const date1Number = monthToComparableNumber(date1);
  const date2Number = monthToComparableNumber(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return date1Number - date2Number;
};

// eg 08/29/2004 gets converted to 20040829
const monthToComparableNumber = (date: string) => {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }
  const [month, day, year] = date.split('/');
  const yearNumber = Number(year);
  const monthNumber = Number(month);
  const dayNumber = Number(day);
  return yearNumber * 10000 + monthNumber * 100 + dayNumber;
};

export const getSortOptions = (columnsState: ColumnState[]) =>
  columnsState
    .filter((column) => Boolean(column.sort))
    .sort((columnA, columnB) => columnA.sortIndex - columnB.sortIndex)
    .reduce(
      (prev, { sort, colId }) => ({ ...prev, [`order[${colId}]`]: sort }),
      {}
    );
