import EmployeeTimeSheetPageActions from 'actions/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageActions';
import EmployeeTimeSheetPageStore from 'stores/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageStore';

import AbstractDropdown from 'components/project/dropdown/AbstractDropdown';

export default class SuperVisorAssignedEmployeesDropDown extends AbstractDropdown {
  getStore() {
    return EmployeeTimeSheetPageStore;
  }

  loadOptions() {
    return EmployeeTimeSheetPageActions.loadSuperVisorAssignedEmployeesList(
      this.props.superVisorId
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.superVisorId !== this.props.superVisorId) {
      return EmployeeTimeSheetPageActions.loadSuperVisorAssignedEmployeesList(
        prevProps.superVisorId
      );
    }
  }

  getOptions() {
    return EmployeeTimeSheetPageStore.getState()
      .superVisorAssignedEmployeesList;
  }
}
