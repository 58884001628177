import React from 'react';
import { Container } from 'flux/utils';

import AbstractOrderOverview, {
  PAbstractOrderOverview,
  SAbstractOrderOverview,
  TFilter,
} from 'page/workflow/order/AbstractOrderOverview';
import Notification from 'components/modal/Notification';
import OrderOverviewStore from 'stores/workflow/order/OrderOverviewStore';
import UserProfileStore from 'stores/UserProfileStore';
import { getDateString } from 'utils/DateUtils';
import WorkflowOrderColumnsDescriptor from 'page/workflow/order/WorkflowOrderColumnsDescriptor';
import { GridControlButton } from 'components/grid';
import { Button } from 'components/button';
import { storeGrid } from 'stores/_mobx/grid';
import { URL_ORDER_ADD } from 'constant/path/workflow';

export interface PAssignedOrders extends PAbstractOrderOverview {}

export class SAssignedOrders extends SAbstractOrderOverview {}

const GRID_ID = 'assigned_orders_grid';

export class AssignedOrders extends AbstractOrderOverview<
  PAssignedOrders,
  SAssignedOrders
> {
  static TITLE = 'Assigned Orders';

  static getStores() {
    return [OrderOverviewStore];
  }

  static calculateState(prevState: SAssignedOrders) {
    if (!prevState) {
      const defaultFilter = {
        ...new TFilter(),
        period: 'TD',
        dosStart: getDateString(),
        dosEnd: getDateString(),
      };

      prevState = AbstractOrderOverview.getDefaultState(GRID_ID);
      prevState.filter = storeGrid.getFilter(GRID_ID, defaultFilter) as TFilter;
      prevState.unassignOption = true;
      prevState.dosEditable = true;
    }

    return {
      ...prevState,
      dataSource: OrderOverviewStore.getState().dataSource,
      dataSourceCount: OrderOverviewStore.getState().dataSourceCount,
    };
  }

  onReset() {
    const filter = {
      ...new TFilter(),
      period: 'TD',
      dosStart: getDateString(),
      dosEnd: getDateString(),
    };
    this.setState({ filter }, this.updateData);
  }

  getGridId() {
    return GRID_ID;
  }

  getTitle() {
    return AssignedOrders.TITLE;
  }

  prepareFilter() {
    const filter = super.prepareFilter();
    filter.currentUserType = UserProfileStore.getUserType();
    filter.recordsAccess = UserProfileStore.getRecordsAccess();
    filter.status = 'A';
    return filter;
  }

  getShownColumns() {
    return Object.assign(
      [],
      WorkflowOrderColumnsDescriptor.AssignedOrdersInitial
    );
  }

  handleClickVisitNotes = () => {
    const selectedOrder = this.getVisitNotesOrder();
    if (!selectedOrder || !selectedOrder.group_affiliation_name) {
      this.setState({
        showVisitNotes: true,
        selectedOrder,
        isVisitNotesLoading: true,
      });
      this.getVisitNote();
    } else {
      this.setState({ showConfirmAffiliation: true });
    }
  };

  setAdditionalControls = () => {
    this.props.setAdditionalButtons(
      <Button
        disabled={this.state.selected.length !== 1}
        text="Visit Notes"
        onClick={this.handleClickVisitNotes}
      />
    );
  };

  onAssignOrder = () => {
    const { selected } = this.state;
    if (!selected || !selected.length) {
      Notification.warning('Select at least one order!');
      return;
    }
    this.setState(
      { isAssignOpen: true } /*, this.onMultipleAssignOrder.bind(this)*/
    );
  };

  getControlPanel = () => (
    <>
      <GridControlButton title="Place Order" url={URL_ORDER_ADD} />
      <GridControlButton title="Un/Reassign" onClick={this.onAssignOrder} />
    </>
  );

  renderOrderStatus(): React.ReactNode {
    return null;
  }

  getAllowedColumns() {
    return Object.assign(
      [],
      WorkflowOrderColumnsDescriptor.AssignedOrdersDefault
    );
  }
}

const container = Container.create(AssignedOrders);
export default container;
