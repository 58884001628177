import { RouteComponentProps } from 'react-router';

import Title from 'components/project/common/title';
import ModifyGloveForm, { FormModel } from './components/Form';

import { storeGlove } from 'stores/_mobx/workflow/equipmentInventory/glove';
import { URL_GLOW } from 'constant/path/workflow';

interface PropsType extends RouteComponentProps {}

const GloveAddPage = ({ history }: PropsType) => {
  const handleSubmit = (data: FormModel) =>
    storeGlove.addGlove(data).then((response) => {
      if (response) {
        history.push(URL_GLOW);
      }
    });

  return (
    <>
      <Title title="Add Glove" />
      <ModifyGloveForm backUrl={URL_GLOW} onSubmit={handleSubmit} />
    </>
  );
};

export default GloveAddPage;
