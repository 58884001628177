import Dialog, { DialogBody, DialogFooter } from 'components/modal/dialog';
import { Button } from 'components/button';
import Tabs from 'components/tabs';
import GroupTab from './GroupTab';
import PhysicianTab from './PhysicianTab';

interface PropsType {
  orderId: number;
  order: { assign_providergrpid: number; assign_providerid: number };
  onClose: () => void;
  onAssign: () => void;
}

const tabsList = [{ label: 'Physician Groups' }, { label: 'Physicians' }];

const DialogPhysicianAssignment = ({ onClose, ...rest }: PropsType) => (
  <Dialog size="large" handleClose={onClose}>
    <DialogBody>
      <Tabs tabsList={tabsList}>
        <GroupTab {...rest} />
        <PhysicianTab {...rest} />
      </Tabs>
    </DialogBody>
    <DialogFooter>
      <Button variant="warning" text="Cancel" onClick={onClose} />
    </DialogFooter>
  </Dialog>
);

export default DialogPhysicianAssignment;
