import { PureCheckbox } from 'components/form/checkbox';

const GridCheckbox = ({
  id,
  type,
  selectedIds,
  disabled,
  setIds,
}: {
  id: number;
  disabled: boolean;
  selectedIds: number[];
  type: 'fax' | 'email';
  setIds: (ids: number[]) => void;
}) => {
  const isChecked = selectedIds.includes(id);

  const handleClick = (checked: boolean) => {
    const selected = checked
      ? selectedIds.concat(id)
      : selectedIds.filter((selectedId) => selectedId !== id);
    setIds(selected);
  };

  return (
    <PureCheckbox
      disabled={disabled}
      name={`${id}_${type}`}
      checked={isChecked}
      onChange={handleClick}
    />
  );
};

export default GridCheckbox;
