import React from 'react';

import useRequest from 'components/hook/useRequest';
import CommonLink from './CommonLink';

import { exportPreInvoice, PreInvoiceType } from 'stores/_mobx/export';
import { downloadFile } from 'utils/downloadFile';
import { getDateRangeBounds } from 'utils/DateUtils';
import { BASE_URL_FILE_DIR } from 'constant/config';

interface PropsType {
  invoiceDetail: PreInvoiceType;
  className?: string;
}

const PreInvoiceExportLink = ({ invoiceDetail, className }: PropsType) => {
  const { fetching, controlledAction } =
    useRequest<PreInvoiceType>(exportPreInvoice);

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const dos = getDateRangeBounds({
      from: invoiceDetail.fromDate,
      to: invoiceDetail.toDate,
    });

    const data = {
      ...invoiceDetail,
      fromDate: dos.dateFrom,
      toDate: dos.dateTo,
    };

    controlledAction(data).then((url) => {
      if (url) {
        const link = `${BASE_URL_FILE_DIR}pdf-creator/${url}`;

        downloadFile(link, true);
      }
    });
  };

  return (
    <CommonLink
      link="/"
      fetching={fetching}
      className={className}
      onClick={handleClick}
    />
  );
};

export default PreInvoiceExportLink;
