import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import GridActionControl from 'page/components/GridActionControls';
import FilterNameAndStatus, {
  FilterType,
} from 'page/components/FilterNameAndStatus';
import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import DialogDocumentsList from '../components/DialogDocumentsList';
import DialogServicesNotes from '../components/DialogServicesNotes';

import {
  storeDrPlate,
  DrPlateType,
} from 'stores/_mobx/workflow/equipmentInventory/drPlate';
import { EI_DR_PLATE as PAGE_ID } from 'constant/pagesId/workflow';
import {
  URL_EQUIPMENT_INVENTORY,
  URL_DR_PLATE_ADD,
  URL_DR_PLATE,
} from 'constant/path/workflow';

const columns = [
  { name: 'unique_id', title: 'Serial No.' },
  { name: 'plate_name', title: 'DR Plate name' },
  { name: 'center_id', title: 'Sensor ID' },
  { name: 'start_date', title: 'Installation purchase date' },
  { name: 'last_cal_date', title: 'Last calibration date' },
  { name: 'cal_due_date', title: 'Next calibration date' },
  {
    name: 'enable',
    title: 'Document(s)',
    className: 'text-center',
    render: (isFileExist: boolean, { refid }: DrPlateType) => (
      <IconButton
        disabled={!isFileExist}
        className={isFileExist ? 'text-primary' : 'text-secondary'}
        onClick={() => storeDrPlate.getFileList(refid)}>
        <i className="bi bi-folder2-open" />
      </IconButton>
    ),
  },
  {
    name: 'refid',
    title: 'History',
    render: (id: number) => (
      <div className="control">
        <IconButton onClick={() => storeDrPlate.setNoteId(id)}>
          <i className="icon icon-notes" />
        </IconButton>
      </div>
    ),
  },
  {
    name: 'refid',
    title: 'Actions',
    className: 'width-100',
    render: (id: number) => (
      <GridActionControl
        id={id}
        url={`${URL_DR_PLATE}/edit/${id}`}
        onDelete={storeDrPlate.setIdForDelete}
      />
    ),
  },
];

const DrPlateListPage = () => {
  const gridRef = useRef(null);

  const [selected, setSelected] = useState<number[]>([]);

  const {
    fetching,
    fetchingFileList,
    noteId,
    fileListPreview,
    idForDelete,
    drPlatesList,
    drPlatesTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeDrPlate;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const fetchData = () => {
    storeDrPlate.getDrPlateMain();
    if (selected) gridRef.current?.clearSelection();
  };

  const handleConfirmDelete = () => {
    storeDrPlate.deleteDrPlate().then((isSucceed) => {
      if (isSucceed) {
        fetchData();
      }
    });
  };

  const handleChangeStatus = () => {
    storeDrPlate.changeDrPlateStatus(selected).then((isSucceed) => {
      if (isSucceed) fetchData();
    });
  };

  const handleChangeFilter = (newFilter: FilterType) => {
    filter.setOptions(newFilter);
    debouncedMethod();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Computer">
        <Link to={URL_EQUIPMENT_INVENTORY} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <FilterNameAndStatus
        placeholder="DR Plate Name"
        filter={filter.options}
        fetching={fetching}
        onChangeFilter={handleChangeFilter}
      />

      <Grid
        id="computer_grid"
        selectId="refid"
        ref={gridRef}
        columns={columns}
        onAjax={fetching}
        dataSource={drPlatesList}
        dataSourceCount={drPlatesTotal}
        pagination={pagination}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        gridControlPanel={
          <>
            <GridControlButton title="Add" url={URL_DR_PLATE_ADD} />
            <GridControlButton
              title={filter.options.isActive ? 'Deactivate' : 'Activate'}
              disabled={!selected.length}
              onClick={handleChangeStatus}
            />
          </>
        }
      />

      {Boolean(idForDelete) && (
        <DialogConfirm
          onCancel={storeDrPlate.clearIdForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this DR Plate?
        </DialogConfirm>
      )}

      {fileListPreview && (
        <DialogDocumentsList
          files={fileListPreview}
          fetching={fetchingFileList}
          onClose={storeDrPlate.clearFileListPreview}
        />
      )}

      {Boolean(noteId) && (
        <DialogServicesNotes
          type="D"
          id={noteId}
          onClose={storeDrPlate.clearNoteId}
        />
      )}
    </>
  );
};

export default observer(DrPlateListPage);
