import React, { useMemo } from 'react';

import { PropsType as SelectPropsType } from 'components/form/select/Select';
import { FakeInput } from 'components/form/textField';
import Custom from 'components/form/input/Custom';
import CorporateDropdown, { DropDownCorporation } from './CorporateDropdown';

import UserProfileStore from 'stores/UserProfileStore';
import { storeDropdownCorporation } from 'stores/_mobx/dropDown/corporation';

interface PropsType {
  name: string;
  label?: string;
  value?: number;
  className?: string;
  noLabel?: boolean;
  attr?: any;
}

export default class CorporateRolesDropdown extends React.Component<
  PropsType,
  any
> {
  corporationName = '';

  static getCorporationId() {
    if (CorporateRolesDropdown.canSeeCorporations()) {
      return 0;
    }
    return UserProfileStore.getCorporateId();
  }

  static canSeeCorporations() {
    return (
      UserProfileStore.getUserType() === 'D' ||
      !UserProfileStore.isClientUser() ||
      UserProfileStore.getUser()
        .usertypename.toLowerCase()
        .indexOf('corporate') > -1
    );
  }

  getCorporationName() {
    if (!this.corporationName) {
      this.corporationName = storeDropdownCorporation.findOptionById(
        UserProfileStore.getCorporateId()
      )?.label;
    }
    return this.corporationName;
  }

  getValue() {
    if (CorporateRolesDropdown.canSeeCorporations()) {
      return this.props.value;
    }
    return UserProfileStore.getCorporateId();
  }

  render() {
    //due weird application logic,
    //if user have facility, this is facility user,
    //and this user should not see corporations dropdown
    if (UserProfileStore.getFacility()) {
      return null;
    } else if (CorporateRolesDropdown.canSeeCorporations()) {
      return <CorporateDropdown {...this.props} />;
    } else {
      return (
        <Custom
          ref="input"
          {...this.props}
          custom={this.getCorporationName()}
        />
      );
    }
  }
}

//due weird application logic,
//if user have facility, this is facility user,
//and this user should not see corporations dropdown

export const DropdownCorporateWithPermission = (
  props: Omit<SelectPropsType, 'options'>
) => {
  const facilityId = useMemo(() => UserProfileStore.getFacility(), []);

  const hasPermission = useMemo(
    () =>
      UserProfileStore.getUserType() === 'D' ||
      !UserProfileStore.isClientUser() ||
      UserProfileStore.getUser()
        .usertypename.toLowerCase()
        .indexOf('corporate') > -1,
    []
  );

  return facilityId ? null : hasPermission ? (
    <DropDownCorporation {...props} />
  ) : (
    <FakeInput
      className={props.className}
      required={props.required}
      label={props.label}
      value={
        storeDropdownCorporation.findOptionById(
          UserProfileStore.getCorporateId()
        )?.label
      }
    />
  );
};
