import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import { ControlsLayout } from 'components/layout';
import { validationSecurityQuestion as resolver } from './validation';

const defaultValues: FormModel = {
  answer1: '',
  answer2: '',
};

export interface FormModel {
  answer1: string;
  answer2: string;
}

interface PropsType {
  questions: [string, string];
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormSecurityQuestion = ({ questions, onSubmit }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>Answer your security question</p>
      {questions.map((question, index) => (
        <Input
          required
          key={`${index}`}
          name={`answer${index + 1}`}
          label={question}
          control={control}
        />
      ))}
      <ControlsLayout alignX="center">
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormSecurityQuestion;
