import { useEffect, useState, useRef } from 'react';

interface OptionType<Payload> {
  fetching: boolean;
  controlledAction: (payload?: Payload) => Promise<any>;
}

function useRequest<Payload>(
  request: (data?: Payload, signal?: AbortSignal) => Promise<any>
): OptionType<Payload> {
  const controller = useRef<AbortController | null>(null);

  const [fetching, setFetching] = useState<boolean>(false);

  const handleAbortRequest = () => {
    controller.current?.abort();
  };

  const controlledAction = async (data: Payload) => {
    handleAbortRequest();

    controller.current = new AbortController();

    setFetching(true);

    return request(
      // @ts-ignore
      data ? data : controller.current.signal,
      data ? controller.current.signal : undefined
    )
      .then((response) => {
        setFetching(false);
        return response;
      })
      .catch((error) => {})
      .finally(() => {
        if (!controller.current?.signal?.aborted) setFetching(false);
      });
  };

  useEffect(() => {
    return () => {
      controller.current?.abort();
    };
  }, []);

  return {
    fetching,
    controlledAction,
  };
}

export default useRequest;
