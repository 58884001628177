import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { phoneValidator, zipValidator } from 'utils/formValidators';

const billingAddressValidation = yup.object().shape({
  address1: yup.string().trim().required('Please, fill in Primary address!'),
  city: yup.string().trim().required('Please, fill in City!'),
  stateId: yup
    .number()
    .typeError('Please, select a State!')
    .positive('Please, select a State!'),
  zipCode: yup
    .string()
    .required('Please, fill in Zipcode!')
    .test({
      test: (zipCode) => zipValidator(zipCode),
      message: 'Zipcode must contain 5 or 9 numbers!',
    }),
  fax: yup.string().test({
    test: (fax) => (fax ? phoneValidator(fax) : true),
    message: 'Should be in xxx-xxx-xxxx format!',
  }),
  tollFreeNumber: yup.string().test({
    test: (tollFreeNumber) =>
      tollFreeNumber ? phoneValidator(tollFreeNumber) : true,
    message: 'Should be in xxx-xxx-xxxx format!',
  }),
  phone: yup
    .string()
    .required('Please, fill in Phone!')
    .test({
      test: (phone) => phoneValidator(phone),
      message: 'Should be in xxx-xxx-xxxx format!',
    }),
  email: yup
    .string()
    .trim()
    .required('Please, fill in Email!')
    .email('Invalid email address.'),
});

const validation = yupResolver(
  yup.object().shape({
    name: yup.string().trim().required('Please, fill in division Name!'),
    shortName: yup
      .string()
      .trim()
      .required('Please, fill in Division Short Name!'),
    overrideCompanyInformation: yup.boolean(),
    divisionLogo: yup.mixed().when('overrideCompanyInformation', {
      is: true,
      then: yup.mixed().test({
        test: (logo: null | string | File) => Boolean(logo),
        message: 'Please select a file. A logo is required',
      }),
    }),
    divisionBillingAddress: yup.object().when('overrideCompanyInformation', {
      is: true,
      then: billingAddressValidation,
    }),
    divisionCompanyAddress: yup.object().when('overrideCompanyInformation', {
      is: true,
      then: billingAddressValidation,
    }),
  })
);

export default validation;
