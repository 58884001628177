import { Route, Switch } from 'react-router-dom';

import { AccessRoute } from 'components/HOC';
import * as IDS from 'constant/pagesId/billingCodding';
import * as PATH from 'constant/path/billingCoding';

import FindingCodingPage from 'page/billing-coding/findingsCodingPage';
import InsuranceVerification from 'page/billing-coding/insuranceVerification';
import EligibilityManagerPage from 'page/billing-coding/eligibilityManager';
import Coding from 'page/billing-coding/coding';
import CodingDetail from 'page/billing-coding/coding/CodingDetail';
import BillingAssignment from 'page/billing-coding/billingAssignment';
import TransmitUnassignment from 'page/billing-coding/transmitUnassignment';
import ClaimManager from 'page/billing-coding/claimManager';
import LedgerPage from 'page/billing-coding/ledger';
import ProFFSReportsPage from 'page/billing-coding/proFfsReports';
import ArAgingInvoiceFacilityDetailsPage from 'page/billing-coding/arAging/InvoiceFacilityDetailsPage';
import ArAgingListPage from 'page/billing-coding/arAging';
import InvoicePage from 'page/billing-coding/invoice';
import PreInvoices from 'page/billing-coding/pre-invoices/PreInvoices';
import FacilityPreview from 'page/billing-coding/FacilityPreview';
import ProfessionalGroupBilling from 'page/billing-coding/professionalGroupBilling';
import ClaimReview from 'page/billing-coding/claimReview';
import E404 from 'page/errors/404';

export const billingCoddingRoutes = [
  '/claim-management',
  '/professional-group-billing',
  '/invoices',
  '/accounts-receivable',
];

const BillingCoding = () => (
  <Switch>
    <AccessRoute
      exact
      pageId={IDS.FINDING_CODING.PAGE}
      path={PATH.URL_FINDING_CODING}
      component={FindingCodingPage}
    />
    <AccessRoute
      exact
      pageId={IDS.INSURANCE_VERIFY.PAGE}
      path={PATH.URL_INSURANCE_VERIFICATION}
      component={InsuranceVerification}
    />
    <AccessRoute
      exact
      pageId={IDS.ELIGIBILITY.PAGE}
      path={PATH.URL_ELIGIBILITY_MANAGER_PAGE}
      component={EligibilityManagerPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CODING.PAGE}
      path={PATH.URL_CODING}
      component={Coding}
    />
    <AccessRoute
      exact
      pageId={IDS.CODING.PAGE}
      path={PATH.URL_CODING_DETAIL}
      component={CodingDetail}
    />

    <AccessRoute
      exact
      pageId={IDS.BILLING_ASSIGNMENT.PAGE}
      path={PATH.URL_BILLING_ASSIGNMENT}
      component={BillingAssignment}
    />
    <AccessRoute
      exact
      pageId={IDS.TRANSMIT_UNASSIGNMENT.PAGE}
      path={PATH.URL_TRANSMIT_UNASSIGNED}
      component={TransmitUnassignment}
    />
    <AccessRoute
      exact
      pageId={IDS.FACILITY_PREVIEW.PAGE}
      path={PATH.URL_FACILITY_PREVIEW}
      component={FacilityPreview}
    />
    <AccessRoute
      pageId={IDS.CLAIM_MANAGER.PAGE}
      path={PATH.URL_CLAIM_MANAGER}
      component={ClaimManager}
    />
    <AccessRoute
      pageId={IDS.CLAIM_REVIEW.PAGE}
      path={PATH.URL_CLAIM_REVIEW}
      component={ClaimReview}
    />
    <AccessRoute
      exact
      pageId={IDS.PRO_FSS.PAGE}
      path={PATH.URL_PRO_FSS_REPORT}
      component={ProFFSReportsPage}
    />
    <AccessRoute
      exact
      pageId={IDS.PRO_GROUP_BILLING.PAGE}
      path={PATH.URL_PRO_GROUP_BILLING}
      component={ProfessionalGroupBilling}
    />
    <AccessRoute
      pageId={IDS.PRE_INVOICE.PAGE}
      path={PATH.URL_PRE_INVOICE}
      component={PreInvoices}
    />
    <AccessRoute
      exact
      pageId={IDS.INVOICE.PAGE}
      path={PATH.URL_INVOICES}
      component={InvoicePage}
    />
    <AccessRoute
      exact
      pageId={IDS.LEDGER.PAGE}
      path={PATH.URL_LEDGER}
      component={LedgerPage}
    />
    <AccessRoute
      exact
      pageId={IDS.AR_AGING.PAGE}
      path={PATH.URL_AR_AGING}
      component={ArAgingListPage}
    />
    <Route
      exact
      path={PATH.URL_AR_AGING_EDIT}
      component={ArAgingInvoiceFacilityDetailsPage}
    />
    <Route path="*" component={E404} />
  </Switch>
);

export default BillingCoding;
