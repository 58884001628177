import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
  TwoSideMapper,
  filterCptCodeList,
} from 'components/form/twoSideMapper';
import { DropDownExamType } from 'components/project/dropdown/ExamTypeDropdown';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Checkbox } from 'components/form/checkbox';
import resolver from './validation';

import {
  CodeDetailType,
  PayloadGetCpt,
} from 'stores/_mobx/systemSetup/codeSet/codeMapping';

const columnsLeft = [{ title: 'Codes', name: 'label' }];

const columnsRight = [{ title: 'Services', name: 'label' }];

const initialValues: CodeDetailType = {
  cpt: [],
  serviceId: 0,
  name: '',
  id: 0,
  filterQCode: false,
  filterRCode: false,
};

interface PropType {
  backUrl: string;
  cptCodes: any[];
  defaultValues?: CodeDetailType;
  onSubmit: (data: CodeDetailType) => Promise<any>;
  onCodeSearch: (data: PayloadGetCpt) => void;
}

const Form = ({
  defaultValues = initialValues,
  cptCodes,
  backUrl,
  onSubmit,
  onCodeSearch,
}: PropType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    watch,
    setError,
  } = useForm<CodeDetailType>({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: CodeDetailType) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('serviceId', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'filterQCode' || name === 'filterRCode') {
        onCodeSearch({
          id: formValue.id,
          filterQCode: formValue.filterQCode,
          filterRCode: formValue.filterRCode,
        });
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={`row${isSubmitting ? ' on-ajax' : ''}`}
      onSubmit={handleClickSubmit}>
      <DropDownExamType
        className="col-md-6 col-lg-4 mb-5"
        label="Service name"
        name="serviceId"
        required
        control={control}
      />
      <div />
      <div className="col-sm-auto">
        <b>CPT codes filter:</b>
      </div>
      <Checkbox
        name="filterQCode"
        label="Q Code"
        className="col-sm-auto"
        control={control}
      />
      <Checkbox
        name="filterRCode"
        label="R Code"
        className="col-sm-auto"
        control={control}
      />
      <div />
      <TwoSideMapper
        name="cpt"
        className="mb-4"
        titleLeft="Total codes"
        titleRight="Total services"
        filterMethod={filterCptCodeList}
        columnLeft={columnsLeft}
        columnRight={columnsRight}
        control={control}
        options={cptCodes}
      />
      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          disabled={!isDirty}
          onClick={handleClickReset}>
          Reset
        </Button>
        <Button type="submit" disabled={!isDirty}>
          Save
        </Button>
      </ControlsLayout>
    </form>
  );
};

export default Form;
