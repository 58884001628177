import { Control } from 'react-hook-form';

import Badge from 'components/badge';
import { Button } from 'components/button';
import MaskInput from 'components/form/maskInput';
import FormControl from 'components/form/FormControl';
import { FakeInput, Input } from 'components/form/textField';
import { DropdownTaxonomyCode } from 'components/project/dropdown/TaxonomyCodeDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';

import { JurisdictionDetailsType } from 'stores/_mobx/billingCodding/claimManagerPC';
import { URL_NPPES_NPI } from 'constant/externalLinks';

interface PropsType {
  facilityName: string;
  isInJurisdiction: boolean;
  control: Control<JurisdictionDetailsType>;
  onToggleDialogZipcode: () => void;
  onToggleDialogZipcodeRange: () => void;
  onSubmit: () => Promise<any>;
}

const npiLabel = (
  <>
    NPI
    <a href={URL_NPPES_NPI} className="mx-2" target="_blank" rel="noreferrer">
      <Badge variant="danger">NPI Lookup</Badge>
    </a>
  </>
);

const Form = ({
  control,
  facilityName,
  isInJurisdiction,
  onSubmit,
  onToggleDialogZipcode,
  onToggleDialogZipcodeRange,
}: PropsType) => {
  return (
    <form className="row" onSubmit={onSubmit}>
      <div className="col-sm-12 col-lg-6 mb-4">
        <legend>Service Location</legend>
        <div className="row">
          <FakeInput
            label="Facility"
            className="col-sm-12"
            value={facilityName}
          />

          <Input
            name="serviceLocation.address"
            label="Address"
            className="col-md-6"
            required
            control={control}
          />
          <Input
            name="serviceLocation.city"
            label="City"
            className="col-md-6"
            required
            control={control}
          />
          <Input
            name="serviceLocation.state"
            label="State"
            className="col-md-6"
            required
            control={control}
          />
          <MaskInput
            name="serviceLocation.zipcode"
            label="Zip Code"
            className="col-md-6"
            format="#####-####"
            required
            control={control}
          />
        </div>
      </div>

      <div className="col-sm-12 col-lg-6 mb-4">
        <legend>Rendering MD</legend>
        <div className="row">
          <Input
            name="renderingDoctor.last_name"
            label="Last Name"
            className="col-md-6"
            required
            control={control}
          />
          <Input
            name="renderingDoctor.first_name"
            label="First Name"
            className="col-md-6"
            required
            control={control}
          />
          <Input
            name="renderingDoctor.office_address"
            label="Practice Address"
            className="col-md-6"
            required
            control={control}
          />
          <Input
            name="renderingDoctor.officestate"
            label="Practice State"
            className="col-md-6"
            required
            control={control}
          />
          <Input
            name="renderingDoctor.officecity"
            label="Practice City"
            className="col-md-6"
            required
            control={control}
          />
          <MaskInput
            name="renderingDoctor.offzipcode"
            label="Practice Zip Code"
            className="col-md-6"
            format="#####-####"
            required
            control={control}
          />
          <Input
            name="renderingDoctor.radio_npi"
            className="col-md-6"
            label={npiLabel}
            maxLength={10}
            control={control}
          />
          <DropdownTaxonomyCode
            name="renderingDoctor.taxonomy"
            label="Taxonomy Code"
            className="col-md-6"
            control={control}
          />
        </div>
      </div>

      <div className="col-sm-12 col-lg-6 mb-sm-4 mb-lg-0">
        <legend>In Jurisdiction Locality</legend>
        <DropDownState
          name="jurisdiction.state"
          label="State"
          required
          control={control}
        />
        <Input
          name="jurisdiction.area"
          label="Area"
          required
          control={control}
        />
        <FormControl direction="left">
          <Button text="Zip Codes" onClick={onToggleDialogZipcode} />
          <Button text="Zip Code Ranges" onClick={onToggleDialogZipcodeRange} />
        </FormControl>
      </div>

      <div className="col-sm-12 col-lg-6">
        <legend>
          Service rendered:
          {isInJurisdiction ? (
            <small className="mx-2 color-green">In Jurisdiction</small>
          ) : (
            <small className="mx-2 text-danger">Out of Jurisdiction</small>
          )}
        </legend>
        <div className="row">
          <Input
            name="zip.state"
            label="Practice State"
            className="col-sm-12"
            disabled
            control={control}
          />
          <Input
            name="zip.locality"
            label="Locality"
            className="col-md-7 col-lg-8"
            disabled
            control={control}
          />
          <MaskInput
            name="zip.zip"
            label="Practice Zip Code"
            format="#####-####"
            className="col-md-5 col-lg-4"
            disabled
            control={control}
          />
        </div>
      </div>
    </form>
  );
};

export default Form;
