import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import MaskInput from 'components/form/maskInput';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import RadioOptions from 'components/project/common/RadioOptions';
import { Textarea } from 'components/form/textarea';
import { Button } from 'components/button';
import { DropDownStateShortName } from 'components/project/dropdown/StateShortName';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { validation as resolver } from './validation';

import { FacilityContactEditType } from 'stores/_mobx/systemSetup/facilityContact';

const initialValues: FacilityContactEditType = {
  refid: 0,
  address1: '',
  address2: '',
  billing_address: '',
  city: '',
  comments: '',
  con_title: '',
  contact_name: '',
  contact_nm: '',
  corportation_name: '',
  email: '',
  facility_id: [],
  facility_local: '',
  fax: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  phone: '',
  radiology_id: null,
  state: '',
  status: 'A',
  zipcode: '',
};

interface PropsType {
  backUrl: string;
  defaultValues?: FacilityContactEditType;
  onSubmit: (data: FacilityContactEditType) => Promise<any>;
}

const FormFacilityContact = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm<FacilityContactEditType>({ defaultValues, resolver });

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: FacilityContactEditType) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('email', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={`row${isSubmitting ? ' on-ajax' : ''}`}
      onSubmit={handleClickSubmit}>
      <div className="col-md-6">
        <Input name="con_title" label="Role" required control={control} />
        <Input
          name="first_name"
          label="First name"
          required
          control={control}
        />
        <Input name="middle_name" label="Middle name" control={control} />
        <Input name="last_name" label="Last name" required control={control} />
        <Input name="email" label="Email" control={control} />
        <MaskInput
          name="fax"
          label="Fax"
          format="###-###-####"
          control={control}
        />
        <MaskInput
          name="phone"
          label="Phone"
          format="###-###-####"
          control={control}
        />
        <MaskInput
          name="zipcode"
          label="Zip Code"
          format="#####-####"
          control={control}
        />
      </div>

      <div className="col-md-6">
        <Input name="city" label="City" control={control} />
        <DropDownStateShortName name="state" label="State" control={control} />
        <Input
          name="billing_address"
          label="Billing address"
          control={control}
        />
        <Input name="address1" label="Address 1" control={control} />
        <Input name="address2" label="Address 2" control={control} />
        <Input name="contact_name" label="Contact name" control={control} />

        <DropDownFacility
          name="facility_id"
          label="Facility"
          isMulti
          disabled={defaultValues.facility_local === 'Y'}
          control={control}
        />
      </div>

      <Radio
        name="status"
        label="Status"
        className="inline"
        required
        options={RadioOptions.active}
        control={control}
      />
      <Textarea name="comments" label="Comments" control={control} rows={6} />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={!isDirty || isSubmitting}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormFacilityContact;
