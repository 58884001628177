import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { storeEligibilitySettingsStatus } from 'stores/_mobx/systemSetup/masterSetting/eligibilityMaster';
import { SpinnerFixed } from 'components/spinner';

export type PropsTypeWithEligibilityManagerSettings = {
  isExperianEnabled: boolean;
  isAvailityEnabled: boolean;
};

const WithEligibilitySettings = ({
  component: Component,
  ...props
}: {
  component: React.FunctionComponent | React.ClassType<any, any, any>;
}) => {
  const isRequestFinished = useRef(false);

  const { eligibilityFetching, isExperianEnabled, isAvailityEnabled } = storeEligibilitySettingsStatus;

  useEffect(() => {
    storeEligibilitySettingsStatus.getEligibilityState();

    isRequestFinished.current = true;

    return storeEligibilitySettingsStatus.clearState;
  }, []);

  return !isRequestFinished.current || eligibilityFetching ? (
    <SpinnerFixed />
  ) : (
    <Component isExperianEnabled={isExperianEnabled} isAvailityEnabled={isAvailityEnabled} {...props} />
  );
};

const WrapperWithEligibilitySettings = observer(WithEligibilitySettings);

export default function withEligibilitySettings(
  Component: React.FunctionComponent | React.ClassType<any, any, any>
) {
  return (props: any) => (
    <WrapperWithEligibilitySettings component={Component} {...props} />
  );
}
