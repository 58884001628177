interface State {
  selected: number[];
  isExportVisible: boolean;
  isImportVisible: boolean;
  isFilterVisible: boolean;
}

export enum ActionType {
  SET_SELECTED = 'setSelected',
  TOGGLE_EXPORT = 'toggleExport',
  TOGGLE_IMPORT = 'toggleImport',
  TOGGLE_FILTER = 'toggleFilter',
}

type Action = { type: ActionType; payload?: any };

export const initialState: State = {
  selected: [],
  isExportVisible: false,
  isImportVisible: false,
  isFilterVisible: false,
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.SET_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };
    case ActionType.TOGGLE_EXPORT:
      return {
        ...state,
        isExportVisible: !state.isExportVisible,
      };
    case ActionType.TOGGLE_IMPORT:
      return { ...state, isImportVisible: !state.isImportVisible };
    case ActionType.TOGGLE_FILTER:
      return { ...state, isFilterVisible: !state.isFilterVisible };
    default:
      return state;
  }
}
