import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import { SpinnerFixed } from 'components/spinner';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import DialogJurisdictionZipCodeRanges from 'components/project/common/DialogJurisdictionZipCodeRanges';
import PatientInfo from './PatientInfo';
import Form from './Form';
import resolver from './validation';
import DialogJurisdictionZipCodes from '../DialogJurisdictionZipCodes';

import {
  storeClaimManagerPC,
  JurisdictionDetailsType,
} from 'stores/_mobx/billingCodding/claimManagerPC';
import { storeDropdownPlaceOfService } from 'stores/_mobx/dropDown/placeOfService';

interface PropsType {
  orderId: number;
  claimId: string;
  onClose: (shouldUpdate: boolean) => void;
}

const DialogJurisdiction = ({ orderId, claimId, onClose }: PropsType) => {
  const { fetchingJurisdictionDetails, jurisdictionDetails } =
    storeClaimManagerPC;

  const { fetching, options } = storeDropdownPlaceOfService;

  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<JurisdictionDetailsType>({ resolver });

  const [isZipCodeDialogVisible, toggleZipCodeDialog] =
    useState<boolean>(false);

  const [isZipCodeRangeDialogVisible, toggleZipCodeRangeDialog] =
    useState<boolean>(false);

  const isLoading = fetching || fetchingJurisdictionDetails || isSubmitting;

  const pos = useMemo(
    () =>
      storeDropdownPlaceOfService.findOption(jurisdictionDetails.pos)?.label ||
      '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, jurisdictionDetails.pos]
  );

  const handleToggleZipCodeDialog = () => {
    toggleZipCodeDialog((state) => !state);
  };

  const handleToggleZipCodeRangeDialog = () => {
    toggleZipCodeRangeDialog((state) => !state);
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleClickSubmit = () =>
    handleSubmit((payload) =>
      storeClaimManagerPC
        .updateJurisdictionDetails(payload)
        .then((isSucceed) => {
          if (isSucceed) onClose(true);
        })
    )();

  const handleClickReset = () => {
    reset(jurisdictionDetails);
  };

  useEffect(() => {
    reset(jurisdictionDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jurisdictionDetails]);

  useEffect(() => {
    storeDropdownPlaceOfService.getOptions();
    storeClaimManagerPC.getJurisdictionDetails(claimId);

    return storeClaimManagerPC.clearJurisdictionDetails;
  }, [claimId]);

  return (
    <>
      <Dialog size="extraLarge" handleClose={handleClose}>
        <DialogHeader title="Jurisdiction details" onClose={handleClose} />
        <DialogBody>
          <PatientInfo
            patientDetails={jurisdictionDetails.patient}
            pos={pos}
            orderId={orderId}
          />
          <Form
            facilityName={jurisdictionDetails.serviceLocation.facility_nm}
            isInJurisdiction={jurisdictionDetails.isInJurisdiction}
            control={control}
            onSubmit={handleClickSubmit}
            onToggleDialogZipcode={handleToggleZipCodeDialog}
            onToggleDialogZipcodeRange={handleToggleZipCodeRangeDialog}
          />
          {isLoading && <SpinnerFixed />}
        </DialogBody>
        <DialogFooter>
          <Button
            text="Reset"
            variant="warning"
            disabled={isLoading}
            onClick={handleClickReset}
          />
          <Button
            text="Claim"
            disabled={isLoading || !isDirty}
            onClick={handleClickSubmit}
          />
        </DialogFooter>
      </Dialog>

      {isZipCodeDialogVisible && (
        <DialogJurisdictionZipCodes
          id={jurisdictionDetails.jurisdiction.refid}
          onClose={handleToggleZipCodeDialog}
        />
      )}

      {isZipCodeRangeDialogVisible && (
        <DialogJurisdictionZipCodeRanges
          id={jurisdictionDetails.jurisdiction.refid}
          onClose={handleToggleZipCodeRangeDialog}
        />
      )}
    </>
  );
};

export default observer(DialogJurisdiction);
