import { action, makeObservable, observable, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

interface PayerTypeProp {
  payor_desc: string;
  payortype_nm: string;
  refid: number;
}

interface OptionResponseType {
  label: string;
  data: string;
}

interface OptionType {
  label: string;
  value: number;
}

class PayerType {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  payerIdForDelete: number | null = null;
  payerType: PayerTypeProp | null = null;
  payerTypeList: PayerTypeProp[] = [];
  payerTypeTotal: number = 0;
  options: OptionType[] = [];
  filter: string = '';

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'payer_type_grid' });

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      payerType: observable,
      payerTypeList: observable,
      payerTypeTotal: observable,
      payerIdForDelete: observable,
      options: observable,
      filter: observable,

      setFetching: action,
      setFetchingOptions: action,
      setList: action,
      setTotal: action,
      setFilter: action.bound,
      clearIdForDelete: action.bound,
    });
  }

  setFetching = (status: boolean) => {
    this.fetching = status;
  };

  setFetchingOptions = (status: boolean) => {
    this.fetchingOptions = status;
  };

  setPayerForEdit = (payer: PayerTypeProp | null) => {
    this.payerType = payer;
  };

  setList = (list: PayerTypeProp[]) => {
    this.payerTypeList = list;
  };

  setTotal = (total: number) => {
    this.payerTypeTotal = total;
  };

  setPayerIdForDelete = (id: number) => {
    this.payerIdForDelete = id;
  };

  setFilter(payerType: string) {
    this.filter = payerType;
  }

  clearIdForDelete() {
    this.payerIdForDelete = null;
  }

  getPayerType = async (id: string | number) => {
    this.setFetching(true);
    try {
      const payerType = await apiRequest<PayerTypeProp[]>({
        url: 'generalmaster.PayerType.GetViewPayerType',
        data: [id],
      });

      this.setPayerForEdit(payerType[0]);
    } catch (e) {
    } finally {
      this.setFetching(false);
    }
  };

  getPayerTypes = async () => {
    const {
      filter: payerTypeName,
      page: { pagination },
    } = this;

    const data = [pagination.skip, pagination.pageSize, payerTypeName];

    try {
      const list = await apiRequest<PayerTypeProp[]>({
        url: 'generalmaster.PayerType.PayerTypeList',
        data,
      });

      this.setList(list);
    } catch (e) {}
  };

  getPayerTypesCount = async () => {
    try {
      const total = await apiRequest<string>({
        url: 'generalmaster.PayerType.PayerTypeTotalCount ',
        data: [this.filter],
      });
      this.setTotal(Number(total) || 0);
    } catch (e) {}
  };

  getPayerTypesList = async () => {
    this.setFetching(true);

    const promiseList = this.getPayerTypes();

    const promiseCount = this.getPayerTypesCount();

    Promise.allSettled([promiseList, promiseCount]).finally(() => {
      this.setFetching(false);
    });
  };

  addPayerType = async (payload: Omit<PayerTypeProp, 'refid'>) => {
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.PayerType.AddPayerType',
        data: [payload.payortype_nm, payload.payor_desc],
      });
      if (result === 'S') {
        Notification.success('Payer type was added successfully!');
        return true;
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message);
    }
  };

  updatePayerType = async (payload: PayerTypeProp) => {
    const data = [payload.refid, payload.payortype_nm, payload.payor_desc];
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.PayerType.EditPayerType',
        data,
      });

      if (result === 'S') {
        Notification.success('Payer type was updated successfully!');
        return true;
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  };

  deletePayerType = async () => {
    try {
      if (!this.payerIdForDelete) {
        Notification.warning('Payer type not selected for delete');
        return false;
      }

      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.PayerType.DeletePayerType',
        data: [this.payerIdForDelete],
      });

      if (result === 'S') {
        Notification.success('Item was deleted!');
        this.clearIdForDelete();
        return true;
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  };

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOptions(true);

    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'usermaster.PayerInfo.GetPayerTypes',
      });

      const options = response.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storePayerType = new PayerType();
