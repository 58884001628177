import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import Notification from 'components/modal/Notification';
import { TPagination } from 'components/grid/GridTypes';

import SecurityPermissionActions from 'actions/SecurityPermissionActions';
import { apiFileRequest, apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import { storePasswordStrength } from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';
import { storeUser } from 'stores/_mobx/users/user';
import Pagination from 'stores/_mobx/options/pagination';
import { storeImport } from 'stores/_mobx/import';
import { UserInfoResponseType } from 'stores/_mobx/profile/myProfile';
import AbortControllerService from 'stores/_mobx/abortControllerService';

import AccessUtils from 'utils/AccessUtils';
import { passwordGenerator } from 'utils/generatePassword';
import { getDayBounds } from 'utils/DateUtils';
import { PHYSICIAN as PAGE_ID } from 'constant/pagesId/clinicianManager';
import { BASE_URL_FILE_DIR } from 'constant/config';

const defaultFormValues: PhysicianFormType = {
  id: 0,
  userInfoId: 0,
  mailType: 'B' as 'B',
  prefix: 0,
  lastName: '',
  firstName: '',
  middleName: '',
  qualification: 0,
  dateOfBirth: '',
  gender: '',
  phy_group: '',
  profileImage: '',
  profileImageDefault: '',
  username: '',
  password: '',
  repeatPassword: '',
  signature: '',
  signatureDefault: '',
  emailNotify: 'W' as 'W',
  emailNotifyPeriod: 0,
  notificationEmail: '',
  emailTemplates: [] as [],
  comments: '',
  participateInGroup: [] as [],
  supervisedGroup: 0,
  userType: 2, // 2 - UserType for physicians
  createdBy: 0,
  primaryPractice: {
    address: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
  },
  contact: {
    address: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    mobileOperator: 0,
    mobilePhone: '',
    email: '',
    fax: '',
    emergencyName: '',
    emergencyPhone: '',
    spouseName: '',
    linkedinId: '',
  },
  licensor: {
    dea: '',
    coSignRequired: '',
    providerStatus: 'A' as 'A',
    website: '',
    providerLevel: '',
    upin: '',
    einName: '',
    ein: '',
    specialty: 0,
    supervisor: '',
    cliaNumber: '',
    notifySupervisor: 'N',
    npi: '',
    referring: '',
    state: 0,
    licenseNumber: '',
    taxonomyCode: 0,
    pecos: '',
  },
};

const defaultPermissionValue: PermissionType = {
  viewCredentials: false,
  resetPassword: false,
  sendCredentials: false,
  configurePrivileges: false,
  editPhysician: false,
  addPhysician: false,
  toggleState: false,
  importPhysicians: false,
  canAssignGroupAdmin: false,
};

export const filterDefaultValues: FilterType = {
  status: 'A',
  lastName: '',
  loginDate: '',
  firstName: '',
  mobilePhone: '',
  npi: '',
  pecos: '',
  specialtyId: 0,
  clinicianGroupId: 0,
  completeness: 'Y',
  groupAdmin: '',
};

interface PermissionType {
  viewCredentials: boolean;
  resetPassword: boolean;
  sendCredentials: boolean;
  configurePrivileges: boolean;
  editPhysician: boolean;
  addPhysician: boolean;
  toggleState: boolean;
  importPhysicians: boolean;
  canAssignGroupAdmin: boolean;
}

export interface FilterType {
  status: 'A' | 'I';
  lastName: string;
  loginDate: string;
  firstName: string;
  mobilePhone: string;
  npi: string;
  pecos: 'Y' | 'N' | '';
  specialtyId: number;
  clinicianGroupId: number;
  completeness: 'Y' | 'N';
  groupAdmin: string;
}

export interface FormPartialPhysicianType {
  address: string;
  city: string;
  contactAddress: string;
  contactCity: string;
  contactPhone: string;
  contactState: string;
  contactZipCode: string;
  fax: string;
  firstName: string;
  gender: 'M' | 'F' | '';
  lastName: string;
  middleName: string;
  npi: string;
  phone: string;
  provider_status: string;
  pwd: string;
  repeatPassword: string;
  salutation: number;
  specialty: number;
  state: number;
  taxonomy_code: number;
  username: string;
  zipCode: string;
}

interface CreatePhysicianResponseType {
  pinInfo: string;
  userId: number;
}

export interface CreatedPartialPhysicianType
  extends CreatePhysicianResponseType,
    Omit<FormPartialPhysicianType, 'repeatPassword'> {}

export interface PhysicianFormType {
  id: number;
  userInfoId: number;
  mailType: 'I' | 'E' | 'B' | 'N';
  prefix: number;
  lastName: string;
  firstName: string;
  middleName: string;
  qualification: number;
  dateOfBirth: string;
  gender: string;
  phy_group: string;
  profileImage: string | File | null;
  profileImageDefault: string;
  username: string;
  password: string;
  repeatPassword: string;
  signature: string;
  signatureDefault: string;
  emailNotify: 'M' | 'W' | 'D' | 'H';
  emailNotifyPeriod: number | '';
  notificationEmail: string;
  emailTemplates: number[];
  comments: string;
  participateInGroup: number[];
  supervisedGroup: number;
  userType: number;
  createdBy: number;
  primaryPractice: {
    address: string;
    city: string;
    state: string;
    zipCode: string;
    phone: string;
  };
  contact: {
    address: string;
    city: string;
    state: string;
    zipCode: string;
    phone: string;
    mobileOperator: number;
    mobilePhone: string;
    email: string;
    fax: string;
    emergencyName: string;
    emergencyPhone: string;
    spouseName: string;
    linkedinId: string;
  };
  licensor: {
    dea: string;
    coSignRequired: string;
    providerStatus: 'A' | 'I';
    website: string;
    providerLevel: string;
    upin: string;
    einName: string;
    ein: string;
    specialty: number;
    supervisor: string;
    cliaNumber: string;
    notifySupervisor: string;
    npi: string;
    referring: string;
    state: number;
    licenseNumber: string;
    taxonomyCode: number;
    pecos: string;
  };
}

interface PhysicianDetailsType {
  birthdate: string;
  cell: string;
  clia_number: string;
  co_sign_required: 'Y' | 'N' | '';
  dea: string;
  ein: string;
  ein_name: string;
  email: string;
  email_notify: 'M' | 'W' | 'D' | 'H';
  emailnotify_period: string;
  emergency_name: string;
  emergency_phone: string;
  fax: string;
  first_name: string;
  gender: 'F' | 'M';
  home_address: string;
  home_phone: string;
  homecity: string;
  homestate: string; // "KH"
  homezipcode: string;
  last_name: string;
  mail_type: 'I' | 'E' | 'B' | 'N' | '';
  middle_name: string;
  mobileop_id: string;
  mobileop_name: string;
  notify_supervisor: 'N' | 'Y';
  npi: string;
  office_address: string;
  officecity: string;
  officestate: string; //"LV"
  officezipcode: string;
  pecos: 'Y' | 'N';
  phy_group: string;
  prefix: string;
  provider_img: string;
  provider_level: string;
  provider_sig_path: string;
  provider_status: 'A' | 'I';
  qualification: string;
  referring_nos: string;
  refid: string;
  secemail: string;
  specialty: string;
  spouse_name: string;
  stateIdVal: string;
  stateLisence: string;
  statearr: any[];
  supervisedGroup: number | string;
  supervisor: string;
  taxonomy_code: string;
  taxonomy_code1: string;
  upin: string;
  website: string;
  work_phone: string;
}

export interface PhysicianExtendedType {
  UserId: number;
  birthdate: string;
  clia_number: string;
  co_sign_required: string;
  comment: string;
  dea: string;
  ein: string;
  ein_name: string;
  email: string;
  facility: string;
  fax: string;
  first_name: string;
  flagViewPassword: boolean;
  gender_name: string;
  last_name: string;
  license_no: null;
  mailingAddress: string;
  mobile: string;
  notification_email: string;
  notify_supervisor: string;
  npi: string;
  pecos: 'N' | 'Y';
  phy_unsign_notification: string;
  primaryPracticeAddress: string;
  provider_level: string;
  provider_status: 'A' | 'I';
  referring_nos: string;
  refid: number;
  speciality: string;
  state: string;
  status: 'Active' | 'Inactive';
  supervisor: string;
  taxonomyCode: string;
  type: number;
  upin: string;
  user_name: string;
  user_no: number;
  user_type: string;
  usertype_id: number;
  usertypename: string;
  website: string;
  work_phone: string;
}

export interface PhysicianAddedSuccessResponseType {
  ipin: string;
  refid: number;
  user_id: number;
}

interface OptionResponseType {
  clinician_group: string;
  data: string;
  fax: string;
  first_name: string;
  group_affiliation_name: string;
  last_name: string;
  mobile: string;
  npi: string;
  phy_group: string;
  suffix_nm: string;
}

interface PhysicianShortInfoResponseType {
  UserName: string;
  email: string;
  first_name: string;
  last_name: string;
  npi: string;
  office_address: string;
  officecity: string;
  officestate: string;
  officezipcode: string;
  refid: string;
  type: string;
  user_id: number;
  work_phone: string;
}

export interface PhysicianShortInfoType {
  id: number;
  firstName: string;
  lastName: string;
  npi: string;
}

interface PayloadPhysicianShortInfoType {
  firstName: string;
  lastName: string;
  npi: string;
  pagination: TPagination;
}

export interface OptionType {
  value: number;
  label: string;
}

export interface PhysicianType extends Omit<OptionResponseType, 'data'> {
  id: number;
}

interface MyProfilePhysicianDetailsType {
  refid: number;
  home_address: string;
  homecity: string;
  homestate: string;
  homezipcode: string;
  home_phone: string;
  cell: string;
  mobileop_id: number;
  email: string;
  fax: string;
  emergency_name: string;
  emergency_phone: string;
  spouse_name: string;
  sig_path_staff: string;
}

interface OptionsRequestPayloadType {
  firstName: string;
  lastName: string;
  npi: string;
  pagination: TPagination;
}

export interface PhysicianClinicalGroupsType {
  label: string;
  data: number;
}

class Physician extends AbortControllerService {
  private numbersOfRequest: number = 0;

  fetching: boolean = false;
  fetchingPermission: boolean = false;
  fetchingExtendedPhysicians: boolean = false;
  fetchingPhysicianDetails: boolean = false;
  options: OptionType[] = [];
  physiciansList: PhysicianType[] = [];
  physiciansTotal: number = 0;
  physicianShortInfoList: PhysicianShortInfoType[] = [];
  physicianShortInfoTotal: number = 0;
  extendedPhysiciansList: PhysicianExtendedType[] = [];
  extendedPhysiciansTotal: number = 0;
  permission: PermissionType = { ...defaultPermissionValue };
  physicianDetails: PhysicianFormType = defaultFormValues;
  idsForChangeStatus: number[] = [];
  filter: FilterType = filterDefaultValues;
  physicianClinicalGroups: PhysicianClinicalGroupsType[] = [];

  page: Pagination = new Pagination({ id: 'physicians_users_grid' });

  constructor() {
    super();

    makeObservable(this, {
      fetching: observable,
      fetchingPermission: observable,
      fetchingExtendedPhysicians: observable,
      fetchingPhysicianDetails: observable,
      options: observable,
      physiciansList: observable,
      physiciansTotal: observable,
      physicianShortInfoList: observable,
      physicianShortInfoTotal: observable,
      extendedPhysiciansList: observable,
      extendedPhysiciansTotal: observable,
      permission: observable,
      idsForChangeStatus: observable,
      filter: observable,
      physicianDetails: observable,
      physicianClinicalGroups: observable,

      physicianShortInfoListAsOptions: computed,
      physicianDetailsDefaultValues: computed,

      setFetching: action,
      setFetchingPermission: action,
      setFetchingExtendedPhysicians: action,
      setFetchingPhysicianDetails: action,
      setExtendedPhysicians: action,
      setPermission: action,
      setIdsForChangeStatus: action,
      setFilter: action.bound,
      setPhysicianShortInfoList: action,
      setPhysicianShortInfoTotal: action,
      setPhysicianDetails: action,
      clearPermission: action.bound,
      clearIdsForChangeStatus: action.bound,
      clearPhysicianShortInfoList: action.bound,
      clearPhysicianDetails: action.bound,
      clearPhysicianClinicalGroups: action.bound,
      clearPhysicianStore: action.bound,
      setPhysicianClinicalGroups: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingPermission(fetching: boolean) {
    this.fetchingPermission = fetching;
  }

  setFetchingExtendedPhysicians(fetching: boolean) {
    this.fetchingExtendedPhysicians = fetching;
  }

  setFetchingPhysicianDetails(fetching: boolean) {
    this.fetchingPhysicianDetails = fetching;
  }

  setExtendedPhysicians(props: {
    physicians: PhysicianExtendedType[];
    count: number;
  }) {
    this.extendedPhysiciansList = props.physicians;
    this.extendedPhysiciansTotal = props.count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setPermission(permission: PermissionType) {
    this.permission = permission;
  }

  setIdsForChangeStatus(ids: number[]) {
    this.idsForChangeStatus = ids;
  }

  setPhysicianShortInfoList(list: PhysicianShortInfoType[]) {
    this.physicianShortInfoList = list;
  }

  setPhysicianShortInfoTotal(count: number) {
    this.physicianShortInfoTotal = count;
  }

  setPhysicianDetails(physicianDetails: PhysicianFormType) {
    this.physicianDetails = physicianDetails;
  }

  setPhysicianClinicalGroups(physicianClinicalGroups: PhysicianClinicalGroupsType[]) {
    this.physicianClinicalGroups = physicianClinicalGroups;
  }

  clearPermission() {
    this.permission = {
      ...defaultPermissionValue,
    };
  }

  clearPhysicianDetails() {
    this.physicianDetails = defaultFormValues;
  }

  clearPhysicianClinicalGroups() {
    this.physicianClinicalGroups = [];
  }

  clearPhysicianStore() {
    this.clearPhysicianDetails();
    this.clearPhysicianClinicalGroups();
  }

  clearIdsForChangeStatus() {
    this.idsForChangeStatus = [];
  }

  clearPhysicianShortInfoList() {
    this.physicianShortInfoList = [];
    this.physicianShortInfoTotal = 0;
  }

  get physicianShortInfoListAsOptions() {
    const { physicianShortInfoTotal, physicianShortInfoList } = this;

    const options = physicianShortInfoList.map((physician) => ({
      ...physician,
      value: physician.id,
      label: `${physician.lastName} ${physician.firstName}`.trim(),
    }));

    return {
      total: physicianShortInfoTotal,
      options,
    };
  }

  get physicianDetailsDefaultValues() {
    const defaultValues = this.physicianDetails;

    const passwordOptions = storePasswordStrength.passwordOptions;

    const password = defaultValues.id
      ? ''
      : passwordGenerator(passwordOptions.minLength);

    return defaultValues.id
      ? defaultValues
      : {
          ...defaultValues,
          password,
          repeatPassword: password,
        };
  }

  getPhysicians = async ({
    firstName,
    lastName,
    npi,
    pagination,
  }: OptionsRequestPayloadType) => {
    if (this.fetching) return Promise.resolve();

    this.setFetching(true);

    try {
      const { data, count } = await apiRequest<{
        count: number;
        data: OptionResponseType[];
      }>({
        url: 'order.Order.GetPhysicianDropDown',
        data: [firstName, lastName, npi, pagination.skip, pagination.pageSize],
      });

      const options = data
        .filter(({ first_name, last_name }) => first_name || last_name)
        .map(({ data, ...rest }) => ({
          ...rest,
          id: Number(data),
        }));

      runInAction(() => {
        this.fetching = false;
        this.physiciansList = options;
        this.physiciansTotal = count;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.physiciansList = [];
        this.physiciansTotal = 0;
      });
    }
  };

  async getExtendedPhysiciansList() {
    const {
      filter,
      page: { pagination },
    } = this;

    const abortSignal = this.manageAbortController();

    this.setFetchingExtendedPhysicians(true);

    const loginDate = getDayBounds(filter.loginDate);

    const payload = [
      2,
      filter.status,
      '',
      filter.lastName,
      loginDate.dateFrom,
      loginDate.dateTo,
      'P',
      pagination.skip,
      pagination.pageSize,
      filter.firstName,
      filter.mobilePhone,
      '',
      filter.npi,
      filter.pecos,
      filter.specialtyId,
      filter.clinicianGroupId,
      filter.completeness,
      filter.groupAdmin,
    ];
    try {
      const { count, data } = await apiRequest<{
        count: number;
        data: PhysicianExtendedType[];
      }>({
        url: 'usermaster.UserInfo.getPhysicians',
        data: payload,
        signal: abortSignal,
      });

      const physicians = data.map((el) => ({
        ...el,
        birthdate: el.birthdate === '0000-00-00' ? '' : el.birthdate,
        UserId: Number(el.UserId),
        refid: Number(el.refid),
        type: Number(el.type),
        user_no: Number(el.user_no),
        usertype_id: Number(el.usertype_id),
      }));

      this.setExtendedPhysicians({
        physicians,
        count,
      });
    } catch (e: any) {
      this.setExtendedPhysicians({
        physicians: [],
        count: 0,
      });
    } finally {
      if (!abortSignal.aborted) {
        this.setFetchingExtendedPhysicians(false);

        this.clearAbortController();
      }
    }
  }

  async getShortInfoPhysiciansCount(payload: PayloadPhysicianShortInfoType) {
    const data = [payload.firstName, payload.lastName, payload.npi];
    try {
      const count = await apiRequest<string>({
        url: 'groupmanager.GroupManager.GetPhysicianCount',
        data,
      });

      this.setPhysicianShortInfoTotal(Number(count) || 0);
    } catch (e: any) {
      this.setPhysicianShortInfoTotal(0);
    }
  }

  async getShortInfoPhysiciansList({
    pagination,
    ...payload
  }: PayloadPhysicianShortInfoType) {
    const data = [
      payload.firstName,
      payload.lastName,
      payload.npi,
      pagination.skip,
      pagination.pageSize,
    ];
    try {
      const response = await apiRequest<PhysicianShortInfoResponseType[]>({
        url: 'groupmanager.GroupManager.GetPhysicianList',
        data,
      });

      const physicians = response.map((el) => ({
        firstName: el.first_name,
        lastName: el.last_name,
        npi: el.npi,
        id: el.user_id,
      }));
      this.setPhysicianShortInfoList(physicians);
    } catch (e: any) {
      this.setPhysicianShortInfoList([]);
    }
  }

  getShortInfoPhysiciansListMain = (payload: PayloadPhysicianShortInfoType) => {
    this.numbersOfRequest += 1;

    this.setFetching(true);

    const promiseCount = this.getShortInfoPhysiciansCount(payload);

    const promiseList = this.getShortInfoPhysiciansList(payload);

    Promise.all([promiseCount, promiseList]).finally(() => {
      this.numbersOfRequest -= 1;

      this.setFetching(Boolean(this.numbersOfRequest));
    });
  };

  async checkPermission() {
    const userId = UserProfileStore.getUserId();

    this.setFetchingPermission(true);
    try {
      const response =
        await SecurityPermissionActions.getUserViewPasswordPermission(userId);

      const hasPermissionConfigurePrivileges =
        AccessUtils.checkAccess('Userperm') &&
        UserProfileStore.haveAccess('usrperm_save');

      const userType = Number(UserProfileStore.getUserTypeId());

      const editPhysician =
        ![2, 26, 27].includes(userType) &&
        AccessUtils.checkAccess(PAGE_ID.EDIT);

      const addPhysician =
        ![2, 26, 27].includes(userType) && AccessUtils.checkAccess(PAGE_ID.ADD);

      this.setPermission({
        viewCredentials: response.includes('clinician'),
        resetPassword: UserProfileStore.haveAccess(PAGE_ID.PASSWORD_RESET),
        sendCredentials: UserProfileStore.haveAccess('phy_resend'),
        configurePrivileges: hasPermissionConfigurePrivileges,
        toggleState: UserProfileStore.haveAccess(PAGE_ID.INACTIVE),
        importPhysicians: UserProfileStore.isSuperAdmin(),
        editPhysician,
        addPhysician,
        canAssignGroupAdmin: UserProfileStore.haveAccess(
          'physician_admin_assign'
        ),
      });
    } catch (e: any) {
      this.setPermission(defaultPermissionValue);
    } finally {
      this.setFetchingPermission(false);
    }
  }

  async changePhysicianStatusMain() {
    const {
      filter: { status },
      idsForChangeStatus,
    } = this;

    const currentStatus = status === 'A' ? 'Active' : 'Inactive';

    this.setFetching(true);

    try {
      const result = await Promise.allSettled(
        idsForChangeStatus.map((userId) =>
          storeUser.changeUserStatus({ userId, status: currentStatus })
        )
      );

      // @ts-ignore
      const count = result.reduce((prev, { value }) => prev + value, 0);

      if (count) {
        Notification.success(
          `${count} physician${count > 1 ? 's were' : ' was'} ${
            status === 'A' ? 'deactivated' : 'activated'
          } successfully!`
        );
        return true;
      }
      throw Error('');
    } catch (error) {
      Notification.danger('Error occurred!');
      this.setFetching(false);
      return false;
    } finally {
      this.clearIdsForChangeStatus();
    }
  }

  prepareFilterForExport() {
    const { filter, extendedPhysiciansTotal } = this;

    const loginDate = getDayBounds(filter.loginDate);

    return {
      userTypeId: 2,
      userTypeMode: filter.status,
      facilityId: '',
      lastName: filter.lastName,
      loginDateTimeFrom: loginDate.dateFrom,
      loginDateTimeTo: loginDate.dateTo,
      userDisplay: 'P',
      limit: extendedPhysiciansTotal,
      firstName: filter.firstName,
      cellPhone: filter.mobilePhone,
      type: '',
      npi: filter.npi,
      pecos: filter.pecos,
      specialityId: filter.specialtyId,
      clinicianGroupId: filter.clinicianGroupId,
      complete: filter.completeness,
      physicianAdmin: filter.groupAdmin,
    };
  }

  generatePdf = async ({
    filter,
    data,
  }: {
    filter: string;
    data: number[];
  }) => {
    try {
      const fileName = await apiRequest<string>({
        url: 'export.exporters.PhysicianExporter.generatePdfByIds',
        data: [data],
      });
      return fileName;
    } catch (e: any) {
      return '';
    }
  };

  async getOptions() {
    if (this.fetching) return Promise.resolve();

    this.setFetching(true);

    try {
      const { data } = await apiRequest<{
        count: number;
        data: OptionResponseType[];
      }>({
        url: 'order.Order.GetPhysicianDropDown',
        data: ['', '', '', 0, 999999],
      });

      const options = data
        .filter(({ first_name, last_name }) => first_name || last_name)
        .map(({ first_name, last_name, data }) => ({
          label: `${first_name} ${last_name}`,
          value: Number(data),
        }))
        .sort((a: any, b: any) => a.label.localeCompare(b.label));

      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }

  findPhysicianById = (id: number) =>
    this.options.find(({ value }) => value === id);

  async updatePhysician(payload: MyProfilePhysicianDetailsType) {
    try {
      const signatureFileName = await this.uploadSignature(
        payload.sig_path_staff
      );

      const physician = {
        ...payload,
        sig_path_staff: signatureFileName,
      };

      const response = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'usermaster.ProviderInfo.simpleEditPhysician',
        data: { physician },
      });

      return response;
    } catch (e: any) {
      return '';
    }
  }

  async uploadSignature(signature: string): Promise<string | null> {
    try {
      if (signature) {
        const isNewImg = signature.startsWith('data:image/png');
        const payload = {
          name: `signature_${Date.now()}${parseInt(
            String(window.performance.now())
          )}.png`,
          content: signature,
        };

        const fileName = isNewImg
          ? await apiFileRequest<string>({
              url: 'temppath/json.image.php',
              data: JSON.stringify(payload),
            })
          : signature;

        return fileName;
      }
      return '';
    } catch (error) {
      Notification.danger('An signature upload error occurred!');
      return '';
    }
  }

  async getPhysicianDetails(userId: number) {
    this.setFetchingPhysicianDetails(true);
    try {
      const [userInfo] = await apiRequest<[UserInfoResponseType]>({
        url: 'usermaster.UserInfo.GetViewUserInfo',
        data: {
          userId,
        },
      });

      if (!userInfo.refid) {
        throw new Error("Patient doesn't exist");
      }

      const clinicianGroupPromise = apiRequest<
        { label: string; data: string }[]
      >({
        url: 'groupmanager.GroupManager.GetClinicianGroup',
        data: [userInfo.user_id],
      });

      const physicianDetailsPromise = apiRequest<[PhysicianDetailsType]>({
        url: 'usermaster.ProviderInfo.GetViewProviderInfo',
        data: [userInfo.user_id],
      });

      const [[response], clinicianGroupsResponse] = await Promise.all([
        physicianDetailsPromise,
        clinicianGroupPromise,
      ]);

      const filteredTemplates = userInfo.emailTemplates.filter(
        ({ template_name, selected }) => Boolean(template_name) && selected
      );

      const emailTemplates = filteredTemplates.map(({ appRefID }) =>
        Number(appRefID)
      );

      const participateInGroup = clinicianGroupsResponse.map(({ data }) =>
        Number(data)
      );

      const participateInGroupNameList: PhysicianClinicalGroupsType[] = clinicianGroupsResponse.map(
        (group) => ({
          ...group,
          data: Number(group.data),
        })
      );

      const isDefaultProfileImage = [
        'user_female.jpg',
        'user_male.jpg',
        'default_user.jpg',
      ].includes(response.provider_img);

      const profileImage = isDefaultProfileImage
        ? response.provider_img === 'user_female.jpg'
          ? '/assets/images/user_female.jpg'
          : '/assets/images/default_user.jpg'
        : `${BASE_URL_FILE_DIR}doc_img/providerimage/${response.provider_img}`;

      const physician: PhysicianFormType = {
        id: userInfo.user_id,
        userInfoId: userId,
        mailType: response.mail_type || 'B',
        prefix: Number(response.prefix),
        lastName: response.last_name,
        firstName: response.first_name,
        middleName: response.middle_name,
        qualification: Number(response.qualification),
        dateOfBirth: response.birthdate,
        gender: response.gender,
        phy_group: response.phy_group,
        profileImage,
        profileImageDefault: response.provider_img,
        username: userInfo.user_name,
        password: userInfo.user_pass,
        repeatPassword: userInfo.user_pass,
        signature: response.provider_sig_path
          ? `${BASE_URL_FILE_DIR}doc_img/providersignature/${response.provider_sig_path}`
          : '',
        signatureDefault: response.provider_sig_path,
        emailNotify: response.email_notify || 'W',
        emailNotifyPeriod: Number(response.emailnotify_period) || 0,
        notificationEmail: response.secemail,
        emailTemplates,
        comments: userInfo.comment,
        participateInGroup,
        supervisedGroup: Number(response.supervisedGroup),
        userType: userInfo.usertype_id,
        createdBy: userInfo.created_by,
        primaryPractice: {
          address: response.office_address,
          city: response.officecity,
          state: response.officestate,
          zipCode: response.officezipcode,
          phone: response.work_phone,
        },
        contact: {
          address: response.home_address,
          city: response.homecity,
          state: response.homestate,
          zipCode: response.homezipcode,
          phone: response.home_phone,
          mobileOperator: Number(response.mobileop_id),
          mobilePhone: response.cell,
          email: response.email,
          fax: response.fax,
          emergencyName: response.emergency_name,
          emergencyPhone: response.emergency_phone,
          spouseName: response.spouse_name,
          linkedinId: userInfo.linked_url,
        },
        licensor: {
          dea: response.dea,
          coSignRequired: response.co_sign_required,
          providerStatus: response.provider_status,
          website: response.website,
          providerLevel: response.provider_level,
          upin: response.upin,
          einName: response.ein_name,
          ein: response.ein,
          specialty: Number(response.specialty),
          supervisor: response.supervisor,
          cliaNumber: response.clia_number,
          notifySupervisor: response.notify_supervisor,
          npi: response.npi,
          referring: response.referring_nos,
          state: Number(response.stateIdVal),
          licenseNumber: response.stateLisence,
          taxonomyCode: Number(response.taxonomy_code),
          pecos: response.pecos,
        },
      };

      this.setPhysicianDetails(physician);
      this.setPhysicianClinicalGroups(participateInGroupNameList);
      return true;
    } catch (error) {
      Notification.danger("Physician you request doesn't exist!");
      return false;
    } finally {
      this.setFetchingPhysicianDetails(false);
    }
  }

  async addPhysicianPartial({
    repeatPassword,
    ...rest
  }: FormPartialPhysicianType) {
    this.setFetchingPhysicianDetails(true);
    try {
      const response = await apiRequest<
        'SE' | 'E' | 'U' | 'S' | CreatePhysicianResponseType
      >({
        url: 'usermaster.ProviderInfo.addProviderJson',
        data: [{ ...rest, ipinstat: 'S' }],
      });

      if (typeof response === 'object') {
        return {
          ...rest,
          ...response,
        };
      }

      const message =
        response === 'E'
          ? { npi: 'The NPI already exists!' }
          : response === 'U'
          ? { username: 'Username already exists!' }
          : { message: '' };

      return message;
    } catch (e: any) {
      Notification.danger('An error occurred!');
      return { message: '' };
    } finally {
      this.setFetchingPhysicianDetails(false);
    }
  }

  async addPhysician(payload: PhysicianFormType) {
    try {
      const isNewAvatarSet = typeof payload.profileImage !== 'string';

      const profileImageFileName = isNewAvatarSet
        ? await storeImport.uploadFile({ file: payload.profileImage as File })
        : !payload.profileImage
        ? 'deleted'
        : '';

      const signatureFileName = await this.uploadSignature(payload.signature);

      const license =
        payload.licensor.state || payload.licensor.licenseNumber
          ? [
              {
                state: payload.licensor.state,
                licenseno: payload.licensor.licenseNumber,
              },
            ]
          : [];

      const physician = {
        prefix: payload.prefix,
        referring_nos: payload.licensor.referring,
        first_name: payload.firstName,
        last_name: payload.lastName,
        middle_name: payload.middleName,
        qualification: payload.qualification,
        gender: payload.gender,
        phy_group: payload.phy_group,
        dateofbirth: payload.dateOfBirth,
        dea: payload.licensor.dea,
        office_address: payload.primaryPractice.address,
        officecity: payload.primaryPractice.city,
        officestate: payload.primaryPractice.state,
        officezipcode: payload.primaryPractice.zipCode,
        work_phone: payload.primaryPractice.phone,
        home_address: payload.contact.address,
        homecity: payload.contact.city,
        homestate: payload.contact.state,
        homezipcode: payload.contact.zipCode,
        email: payload.contact.email,
        home_phone: payload.contact.phone,
        emergency_name: payload.contact.emergencyName,
        emergency_phone: payload.contact.emergencyPhone,
        spouse_name: payload.contact.spouseName,
        cell: payload.contact.mobilePhone,
        mobileop_id: payload.contact.mobileOperator,
        fax: payload.contact.fax,
        website: payload.licensor.website,
        provider_level: payload.licensor.providerLevel,
        co_sign_required: payload.licensor.coSignRequired,
        supervisor: payload.licensor.supervisor,
        notify_supervisor: payload.licensor.notifySupervisor,
        provider_status: payload.licensor.providerStatus,
        upin: payload.licensor.upin,
        ein_name: payload.licensor.einName,
        ein: payload.licensor.ein,
        clia_number: payload.licensor.cliaNumber,
        taxcode: payload.licensor.taxonomyCode,
        img_path: profileImageFileName,
        prov_specialty: payload.licensor.specialty,
        npi: payload.licensor.npi,
        pecos: payload.licensor.pecos,
        sig_path: signatureFileName,
        usernm: payload.username,
        paswrd: payload.password,
        comment: payload.comments,
        linkid: payload.contact.linkedinId,
        mail_type: payload.mailType,
        supervisedGroup: payload.supervisedGroup,
        emailnotify: payload.emailNotify,
        secemail: payload.notificationEmail,
        emailnotify_period: payload.emailNotifyPeriod,
        usetyp: payload.userType,
        creatid: payload.createdBy,
      };

      const data = {
        providerData: physician,
        stateData: license,
      };

      const response = await apiRequest<
        'SE' | 'E' | 'X' | PhysicianAddedSuccessResponseType
      >({
        url: 'usermaster.ProviderInfo.AddProviderInfo',
        data,
      });
      if (typeof response === 'object' && response.ipin) {
        return {
          isSucceed: true,
          physicianInfo: response,
        };
      }
      if (response === 'E') {
        return {
          isSucceed: false,
          errorMessage: {
            'licensor.npi': 'Physician with this NPI already exist!',
          },
        };
      } else if (response === 'X') {
        return {
          isSucceed: false,
          errorMessage: {
            username: 'Username is not uniq!',
          },
        };
      }
      throw Error('');
    } catch (e: any) {
      return {
        isSucceed: false,
        errorMessage: {},
      };
    }
  }

  async editPhysician(payload: PhysicianFormType) {
    try {
      const isNewAvatarSet = typeof payload.profileImage !== 'string';

      const profileImageFileName = isNewAvatarSet
        ? await storeImport.uploadFile({ file: payload.profileImage as File })
        : !payload.profileImage
        ? 'deleted'
        : '';

      const signatureFileName = await this.uploadSignature(payload.signature);

      const license =
        payload.licensor.state || payload.licensor.licenseNumber
          ? [
              {
                state: payload.licensor.state,
                licenseno: payload.licensor.licenseNumber,
              },
            ]
          : [];

      const emailTemplates = payload.emailTemplates.map((id) => ({
        selected: true,
        appRefID: id,
      }));

      const physician = {
        refid: payload.id,
        userinforefid: payload.userInfoId,
        prefix: payload.prefix,
        referring_nos: payload.licensor.referring,
        first_name: payload.firstName,
        last_name: payload.lastName,
        middle_name: payload.middleName,
        qualification: payload.qualification,
        gender: payload.gender,
        phy_group: payload.phy_group,
        dateofbirth: payload.dateOfBirth,
        dea: payload.licensor.dea,
        office_address: payload.primaryPractice.address,
        officecity: payload.primaryPractice.city,
        officestate: payload.primaryPractice.state,
        officezipcode: payload.primaryPractice.zipCode,
        work_phone: payload.primaryPractice.phone,
        home_address: payload.contact.address,
        homecity: payload.contact.city,
        homestate: payload.contact.state,
        homezipcode: payload.contact.zipCode,
        email: payload.contact.email,
        home_phone: payload.contact.phone,
        emergency_name: payload.contact.emergencyName,
        emergency_phone: payload.contact.emergencyPhone,
        spouse_name: payload.contact.spouseName,
        cell: payload.contact.mobilePhone,
        mobileop_id: payload.contact.mobileOperator,
        fax: payload.contact.fax,
        website: payload.licensor.website,
        provider_level: payload.licensor.providerLevel,
        co_sign_required: payload.licensor.coSignRequired,
        supervisor: payload.licensor.supervisor,
        notify_supervisor: payload.licensor.notifySupervisor,
        provider_status: payload.licensor.providerStatus,
        upin: payload.licensor.upin,
        ein_name: payload.licensor.einName,
        ein: payload.licensor.ein,
        clia_number: payload.licensor.cliaNumber,
        taxcode: payload.licensor.taxonomyCode,
        oldimg: payload.profileImageDefault,
        img_path: profileImageFileName,
        prov_specialty: payload.licensor.specialty,
        npi: payload.licensor.npi,
        pecos: payload.licensor.pecos,
        oldsig: payload.signatureDefault,
        sig_path: signatureFileName.startsWith(BASE_URL_FILE_DIR)
          ? payload.signatureDefault
          : signatureFileName,
        is_complete: true,

        username: payload.username,
        usernm: payload.username,
        paswrd: payload.password,
        comment: payload.comments,
        linkid: payload.contact.linkedinId,
        mail_type: payload.mailType,
        emailTemplates,
        supervisedGroup: payload.supervisedGroup,
        emailnotify: payload.emailNotify,
        secemail: payload.notificationEmail,
        emailnotify_period: payload.emailNotifyPeriod,
        usetyp: payload.userType,
        createdby: payload.createdBy,
      };

      const data = {
        physician,
        editmsg: '',
        license,
      };

      const response = await apiRequest<'SE' | 'S' | 'E' | 'X'>({
        url: 'usermaster.ProviderInfo.EditProviderInfo',
        data,
      });

      if (response === 'S') {
        Notification.success('Physician was successfully updated!');
        return {
          isSucceed: true,
          errorMessage: null,
        };
      }
      if (response === 'E') {
        return {
          isSucceed: false,
          errorMessage: {
            'licensor.npi': 'Physician with this NPI already exist!',
          },
        };
      } else if (response === 'X') {
        return {
          isSucceed: false,
          errorMessage: {
            username: 'Username is not uniq!',
          },
        };
      }
      throw Error('');
    } catch (e: any) {
      return {
        isSucceed: false,
        errorMessage: {},
      };
    }
  }
}

export const storePhysician = new Physician();
