import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import { PureInput } from 'components/form/textField';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';

import { storePerDiem } from 'stores/_mobx/systemSetup/masterSetting/perDiem';
import { MS_PER_DIEM as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_PER_DIEM_ADD,
  URL_PER_DIEM,
} from 'constant/path/systemSetup';

const renderControl = (id: number) => (
  <div className="control">
    <Link className="bi bi-pencil" to={`${URL_PER_DIEM}/edit/${id}`} />
    <IconButton onClick={() => storePerDiem.setPerDiemForDelete(id)}>
      <i className="bi bi-trash" />
    </IconButton>
  </div>
);

const columns = [
  { name: 'unitname', title: 'Unit name' },
  { name: 'unitdesc', title: 'Unit description' },
  {
    name: 'refid',
    title: 'Action',
    className: 'width-75',
    render: renderControl,
  },
];

const PerDiemListPage = () => {
  const {
    fetching,
    perDiemIdForDelete,
    perDiemList,
    perDiemTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storePerDiem;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleChangeFilter = (name: string) => {
    storePerDiem.setFilter(name);
    debouncedMethod();
  };

  const handleConfirmDelete = () => {
    storePerDiem.deletePerDiem().then((isSucceed) => {
      if (isSucceed) storePerDiem.getPerDiemListMain();
    });
  };

  useEffect(() => {
    storePerDiem.getPerDiemListMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Per Diem">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Unit name"
        className="col-sm-12 col-md-6 col-lg-4"
        value={filter}
        onChange={handleChangeFilter}
      />

      <Grid
        id="per_diem_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={perDiemList}
        dataSourceCount={perDiemTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_PER_DIEM_ADD} />
        }
      />
      {Boolean(perDiemIdForDelete) && (
        <DialogConfirm
          onCancel={storePerDiem.clearPerDiemForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(PerDiemListPage);
