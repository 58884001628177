import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import { storeTermsAndConditions } from 'stores/_mobx/systemSetup/masterSetting/termsAndConditions';
import { URL_CHANGE_PASSWORD, URL_LOGOUT } from 'constant/path/auth';

interface PropsType extends RouteComponentProps {}

const LicenseAgreementPage = ({ history }: PropsType) => {
  const { fetching, terms } = storeTermsAndConditions;

  const handleAcceptLicense = () => {
    history.push(URL_CHANGE_PASSWORD);
  };

  useEffect(() => {
    storeTermsAndConditions.getTerms();
  }, []);

  return (
    <div className={`container${fetching ? ' on-ajax' : ''}`}>
      <div className="wrapper">
        <h2 className="text-center text-danger my-4">Terms and Conditions</h2>

        <div
          className={`mb-3${terms ? '' : ' flex-grow-1'}`}
          dangerouslySetInnerHTML={{ __html: terms }}
        />

        <ControlsLayout alignX="between" className="my-5">
          <Link to={URL_LOGOUT} className="btn btn-danger">
            Disagree
          </Link>
          <Button text="Agree" onClick={handleAcceptLicense} />
        </ControlsLayout>
      </div>
    </div>
  );
};

export default observer(LicenseAgreementPage);
