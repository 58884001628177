import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    carrier_format: yup
      .string()
      .required('Please, enter the mobile operator name!'),
    mobile_operator: yup.string().required('Please, enter the carrier format!'),
  })
);

export default validation;
