import InvoiceReportPageService from 'services/reports/custom-reports/invoice-report/InvoiceReportPageService';
import { Actions } from 'stores/reports/custom-reports/invoice-report/InvoiceReportPageStore';
import appDispatcher from 'dispatcher/AppDispatcher';

export default class InvoiceReportPageActions {
  static clear() {
    appDispatcher.dispatch({
      type: Actions.CLEAR_STORE_ACTION,
      data: {},
    });
  }

  static createPdf(dataSource, formatDescription, pdfType) {
    return InvoiceReportPageService.createPdf(
      dataSource,
      formatDescription,
      pdfType
    );
  }

  static getInvoiceOrderReportByPatient(conditionList, joint) {
    return InvoiceReportPageService.getInvoiceOrderReportByPatient(
      conditionList,
      joint
    ).then((data) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_PATIENT_LIST,
        data,
      });
    });
  }

  static getInvoiceOrderReportByFacility(conditionList, joint) {
    return InvoiceReportPageService.getInvoiceOrderReportByFacility(
      conditionList,
      joint
    ).then((data) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_FACILITY_LIST,
        data,
      });
    });
  }
}
