import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { Textarea } from 'components/form/textarea';
import { Button } from 'components/button';
import resolver from './validation';

import { CodeType } from 'stores/_mobx/systemSetup/masterSetting/adjustmentCode';

const initialValues: CodeType = {
  refid: 0,
  code_type: '',
  description: '',
};

interface PropsType {
  backUrl: string;
  defaultValues?: CodeType;
  onSubmit: (data: CodeType) => Promise<any>;
}

const FormAdjustmentCode = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: CodeType) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('code_type', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={`col-lg-8${isSubmitting ? ' on-ajax' : ''}`}
      onSubmit={handleClickSubmit}>
      <Input
        name="code_type"
        label="Adjustment code"
        control={control}
        required
      />
      <Textarea
        name="description"
        label="Description"
        rows={3}
        control={control}
      />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormAdjustmentCode;
