import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import GridActionControls from 'page/components/GridActionControls';
import DialogConfirm from 'components/modal/dialogConfirm';
import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';

import { storeRejectReason } from 'stores/_mobx/systemSetup/masterSetting/rejectReason';
import { MS_REJECT_REASON as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_REJECT_REASON_ADD,
  URL_REJECT_REASON,
} from 'constant/path/systemSetup';

const columns = [
  { name: 'reason', title: 'Reason', className: 'w-100' },
  {
    name: 'refid',
    title: 'Action',
    render: (id: number) => (
      <GridActionControls
        id={id}
        url={`${URL_REJECT_REASON}/edit/${id}`}
        onDelete={storeRejectReason.setIdForDelete}
      />
    ),
  },
];

const RejectReasonListPage = () => {
  const {
    fetching,
    reasonList,
    reasonTotal,
    search,
    idForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeRejectReason;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleChangeSearch = (search: string) => {
    storeRejectReason.setSearch(search);
    debouncedMethod();
  };

  const handleConfirmDelete = () => {
    storeRejectReason.deleteReason().then((isSucceed) => {
      if (isSucceed) storeRejectReason.getRejectReasonMain();
    });
  };

  useEffect(() => {
    storeRejectReason.getRejectReasonMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Reject Reason">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Reject Reason"
        className="col-sm-12 col-md-6 col-lg-4"
        value={search}
        onChange={handleChangeSearch}
      />

      <Grid
        id="rejectReasonGrid"
        columns={columns}
        onAjax={fetching}
        dataSource={reasonList}
        dataSourceCount={reasonTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_REJECT_REASON_ADD} />
        }
      />
      {idForDelete ? (
        <DialogConfirm
          onCancel={storeRejectReason.clearIdForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this Reject reason?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(RejectReasonListPage);
