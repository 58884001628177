import VisitNotesService from 'services/workflow/order/VisitNotesService';
import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';

export default class VisitNotesActions {
  static loadNotes(orderId: number) {
    return VisitNotesService.loadNotes(orderId);
  }
  static getVisitNotesForOrder(order: TSignOrderItem) {
    return VisitNotesService.getVisitNotesForOrder(order);
  }
}
