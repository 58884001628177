import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { zipValidator } from 'utils/formValidators';

const validation = yupResolver(
  yup.object().shape({
    radiologyGroupName: yup.string().required('Please, enter a Group name!'),
    address: yup.string().required('Please, enter a Primary address!'),
    state: yup
      .string()
      .typeError('Please, select a State!')
      .required('Please, select a State!'),
    city: yup.string().required('Please, enter a City!'),
    zipcode: yup
      .string()
      .required('Please, enter a Zip code!')
      .test({
        test: (zipCode: string) => (zipCode ? zipValidator(zipCode) : true),
        message: 'Should be in xxxxx-xxxx format!',
      }),
    radiologyType: yup.mixed().test({
      test: (type: Record<string, boolean>) => {
        const typeValues = Object.values(type);
        const isSomeSelected = typeValues.some((value) => value);
        return isSomeSelected;
      },
      message: 'Please, select at least one option!',
    }),
    radiologyGroupIntegrationIds: yup
      .array()
      .when('isIntegrationEnabled', (isIntegrationEnabled: boolean, field) =>
        isIntegrationEnabled
          ? field.min(1, 'Please, select at least 1 Integration!')
          : field
      ),
  })
);

export default validation;
