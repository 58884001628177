import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';

import {
  storeCptCategory,
  FilterModel,
} from 'stores/_mobx/systemSetup/masterSetting/cptCategory';

const options = [
  { label: 'Active', value: 'A' },
  { label: 'Inactive', value: 'R' },
];

interface PropsType {
  onFilterChange: (filter: FilterModel) => void;
}

const Filter = ({ onFilterChange }: PropsType) => {
  const { control, watch } = useForm<FilterModel>({
    defaultValues: storeCptCategory.filter,
  });

  useEffect(() => {
    const subscription = watch((formValue) => {
      onFilterChange(formValue as FilterModel);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form>
      <div className="row">
        <Input
          name="cptCategoryTitle"
          label="Category name"
          className="col-sm-6 col-lg-4"
          control={control}
        />
        <Radio
          name="cptCategoryFlag"
          label="Status"
          className="col-sm-6 col-md-auto part-inline"
          options={options}
          control={control}
        />
      </div>
    </form>
  );
};

export default Filter;
