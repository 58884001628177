import { action, makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionsStateResponse {
  data: string;
  label: string;
}

export interface OptionType {
  value: number;
  label: string;
}

export class DropdownRadiology {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,

      setFetching: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  async getOptions() {
    if (this.fetching) return Promise.resolve();

    this.setFetching(true);

    try {
      const list = await apiRequest<OptionsStateResponse[]>({
        url: 'generalmaster.RadiologyGroup.GetRadiologyGroupDropDown',
        data: [null],
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));

      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }

  findOption = (id: number) => this.options.find(({ value }) => id === value);
}

export const storeDropdownRadiology = new DropdownRadiology();
