import { makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionsJurisdictionResponse {
  data: string;
  label: string;
  short_name: string;
}

export interface OptionType {
  value: number;
  label: string;
}

export class DropdownJurisdiction {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,
    });
  }

  async getOptions(stateId: number) {
    if (this.fetching) return Promise.resolve();
    try {
      runInAction(() => {
        this.fetching = true;
      });
      const list = await apiRequest<OptionsJurisdictionResponse[]>({
        url: 'generalmaster.MvLocation.GetBillingJurisdictionDropDown',
        data: [stateId],
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));
      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }
}

export const storeDropdownJurisdiction = new DropdownJurisdiction();
