import { InterpretationType } from 'stores/_mobx/workflow/order/interpretation';

const InterpretationInfo = ({ info }: { info: InterpretationType | null }) => (
  <dl className="description row">
    <dt className="col-sm-2">Name</dt>
    <dd className="col-sm-4">{info?.name}</dd>
    <dt className="col-sm-2">Facility</dt>
    <dd className="col-sm-4">{info?.facility}</dd>

    <dt className="col-sm-2 clear-left">DOB</dt>
    <dd className="col-sm-4">{info?.dob}</dd>
    <dt className="col-sm-2">Age</dt>
    <dd className="col-sm-4">{info?.age}</dd>

    <dt className="col-sm-2 clear-left">MRN</dt>
    <dd className="col-sm-4">{info?.mrn}</dd>
    <dt className="col-sm-2">Accession</dt>
    <dd className="col-sm-4">{info?.accession}</dd>

    <dt className="col-sm-2 clear-left">DOS</dt>
    <dd className="col-sm-4">{info?.dateOfService}</dd>
    <dt className="col-sm-2">Study</dt>
    <dd className="col-sm-4">{info?.study}</dd>

    <dt className="col-sm-2 clear-left">Physician</dt>
    <dd className="col-sm-4">{info?.physician}</dd>
    <dt className="col-sm-2" style={{ whiteSpace: 'nowrap' }}>
      Reason for exam
    </dt>
    <dd className="col-sm-4">{info?.reasonForExam}</dd>

    <dt className="col-sm-2 clear-left" />
  </dl>
);

export default InterpretationInfo;
