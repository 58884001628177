import { makeObservable, observable, action, computed } from 'mobx';

import { apiRequest } from 'services/RequestService';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface NoteResponseType {
  bgcolor: string;
  date: string;
  name: string;
  reason: string;
  time: string;
}

export interface NoteType {
  message: string;
  date: string;
  user: string;
}

interface NoteRequestType {
  id: number;
  type: string;
  onlyNew: boolean;
}

interface NotePayload {
  id: number;
  type: string;
  message: string;
}

class ServiceNote {
  fetching: boolean = false;
  notes: NoteType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      notes: observable,

      noteCount: computed,

      setFetching: action,
      setNotes: action,
      clearNotes: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setNotes(notes: NoteType[]) {
    this.notes = notes;
  }

  clearNotes() {
    this.notes = [];
  }

  get noteCount() {
    return this.notes.length;
  }

  async getServiceNotes({ id, type, onlyNew }: NoteRequestType) {
    this.setFetching(true);
    try {
      const response = await apiRequest<NoteResponseType[]>({
        url: 'vehicle.EquipmentMaster.GetServiceNotes',
        data: [id, type, onlyNew],
      });

      const notes = response.map((note) => ({
        message: note.reason,
        date: dateToLocalTimezone({
          date: `${note.date} ${note.time}`,
        }),
        user: note.name,
      }));

      this.setNotes(notes);
    } catch (e: any) {
      this.setNotes([]);
    } finally {
      this.setFetching(false);
    }
  }

  async addMessage(payload: NotePayload) {
    this.setFetching(true);

    try {
      const response = await apiRequest<null | string>({
        url: 'vehicle.EquipmentMaster.addServiceMessageByType',
        data: [payload.id, payload.type, payload.message],
      });

      if (!response) return true;

      throw Error('Error occurred!');
    } catch (e: any) {
      this.setFetching(false);
      return false;
    }
  }
}

export const storeServiceNote = new ServiceNote();
