import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, RouteComponentProps } from 'react-router-dom';

import { SpinnerFixed } from 'components/spinner';
import { LayoutSideTitle } from 'components/layout';
import Form from './components/Form';

import {
  storeCompanyInformation,
  FormCompanyType,
} from 'stores/_mobx/systemSetup/masterSetting/companyInformation';
import { URL_MASTER_SETTINGS } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const CompanyInformationPage = ({ history }: PropsType) => {
  const { fetching, companyDetails } = storeCompanyInformation;

  const handleSubmit = (payload: FormCompanyType) =>
    storeCompanyInformation
      .updateCompanyInformation(payload)
      .then((isSucceed) => {
        if (isSucceed) history.push(URL_MASTER_SETTINGS);
      });

  useEffect(() => {
    storeCompanyInformation.getCompanyInfo();
  }, []);

  return (
    <>
      <LayoutSideTitle title="Company Information">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <Form companyDetails={companyDetails} onSubmit={handleSubmit} />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(CompanyInformationPage);
