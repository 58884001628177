import {
  makeObservable,
  action,
  observable,
  runInAction,
  computed,
} from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import AccessUtils from 'utils/AccessUtils';
import { FEE_SCHEDULE_CORPORATE as PAGE_ID } from 'constant/pagesId/systemSetup';

const prettierSchedule = (
  schedule: ScheduleItemResponseType[]
): ScheduleItemType[] =>
  schedule
    .map((el) => ({
      active_end: el.active_end || '',
      active_on: el.active_on || '',
      cpt_status: el.cpt_status || ('C' as 'C'),
      date: el.date || '',
      deldate: el.deldate || '',
      facilitycnt: Number(el.facilitycnt),
      facilityname: el.facilityname,
      filename: el.filename || '',
      groupid: Number(el.groupid),
      refid: Number(el.refid),
      statename: el.statename,
      status: el.status || '',
      editId: el.cpt_status === 'S' ? Number(el.state_id) : Number(el.refid),
    }))
    .sort((a, b) =>
      a.statename.toLowerCase().localeCompare(b.statename.toLowerCase())
    );

const filterOutSchedule = (
  scheduleIncome: ScheduleItemType[],
  filter: FilterType
) => {
  const isFilterSet =
    Boolean(filter.description) || Boolean(filter.corporationId);

  const scheduleFiltered = isFilterSet
    ? scheduleIncome.filter((el) => {
        if (filter.description) return el.filename.includes(filter.description);
        return el.refid === filter.corporationId;
      })
    : scheduleIncome;

  return scheduleFiltered;
};

const defaultPermissionValue: PermissionType = {
  importAction: false,
  archive: false,
  edit: false,
};

export const filterDefaultValue: FilterType = {
  corporationId: 0,
  description: '',
  status: 'active',
  isActive: true,
};

export interface FilterType {
  corporationId: number;
  description: string;
  status: 'active' | 'archive';
  isActive: boolean;
}

interface ScheduleItemResponseType {
  active_end: string | null;
  active_on: string | null;
  cpt_status: 'S' | null;
  date: string | null;
  deldate: string | null;
  facilitycnt: string | null;
  facilityname: string;
  filename?: string;
  state_id?: string;
  groupid: string | null;
  refid: string;
  statename: string;
  status: string | null;
}

export interface ScheduleItemType {
  active_end: string;
  active_on: string;
  cpt_status: 'C' | 'S';
  date: string;
  deldate: string;
  facilitycnt: number;
  facilityname: string;
  filename: string;
  groupid: number;
  refid: number;
  editId: number;
  statename: string;
  status: string;
}

interface ScheduleResponseType {
  active: ScheduleItemResponseType[];
  archived: ScheduleItemResponseType[];
}

interface PermissionType {
  importAction: boolean;
  archive: boolean;
  edit: boolean;
}

class FeeScheduleCorporate {
  fetching: boolean = false;
  activeSchedule: ScheduleItemType[] = [];
  archiveSchedule: ScheduleItemType[] = [];
  filter: FilterType = filterDefaultValue;
  permission: PermissionType = defaultPermissionValue;

  page: Pagination = new Pagination({
    id: 'corporate_fee_schedule',
    clearOnUnmount: true,
  });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      activeSchedule: observable,
      archiveSchedule: observable,
      permission: observable,
      filter: observable,

      scheduleData: computed,

      setFetching: action,
      setPermission: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFilter(filter: FilterType) {
    this.filter = {
      ...filter,
      isActive: filter.status === 'active',
    };
  }

  setPermission(permission: PermissionType) {
    this.permission = permission;
  }

  get scheduleData() {
    const {
      filter,
      page: { pagination },
    } = this;

    const scheduleIncome =
      filter.status === 'active' ? this.activeSchedule : this.archiveSchedule;

    const scheduleFiltered = filterOutSchedule(scheduleIncome, filter);

    return {
      scheduleTotal: scheduleFiltered.length,
      scheduleList: scheduleFiltered.slice(
        pagination.skip,
        pagination.skip + pagination.pageSize
      ),
    };
  }

  async getFeeSchedule() {
    this.setFetching(true);
    try {
      const { active, archived } = await apiRequest<ScheduleResponseType>({
        url: 'codemaster.CorporateCPTCode.CorporateCPTList',
        data: [0, ''], // [corpId, descriptionText],
      });
      runInAction(() => {
        this.activeSchedule = prettierSchedule(active);
        this.archiveSchedule = prettierSchedule(archived);
      });
    } catch (e: any) {
      runInAction(() => {
        this.activeSchedule = [];
        this.archiveSchedule = [];
      });
    } finally {
      this.setFetching(false);
    }
  }

  checkPermission() {
    const importAction = AccessUtils.checkAccess(PAGE_ID.IMPORT);

    const archive = AccessUtils.checkAccess(PAGE_ID.ARCHIVE);

    const edit = AccessUtils.checkAccess(PAGE_ID.EDIT);

    this.setPermission({
      importAction,
      archive,
      edit,
    });
  }
}

export const storeFeeScheduleCorporate = new FeeScheduleCorporate();
