import { options as optionsTimezone } from 'components/project/dropdown/SimpleTimezoneDropdown';

import { storeDropdownFacility } from 'stores/_mobx/dropDown/facility';
import { storeDropdownOrderType } from 'stores/_mobx/dropDown/orderType';
import { storeProviderType } from 'stores/_mobx/systemSetup/masterSetting/providerType';
import { storeDropdownState } from 'stores/_mobx/dropDown/state';
import { storeDropdownPlaceOfService } from 'stores/_mobx/dropDown/placeOfService';
import { storeDropdownCorporation } from 'stores/_mobx/dropDown/corporation';
import { storeDropdownRadiologist } from 'stores/_mobx/dropDown/radiologist';
import { storeDropdownRadiology } from 'stores/_mobx/dropDown/radiology';
import { storeRegion } from 'stores/_mobx/systemSetup/masterSetting/region';
import { storeDispatchRegions } from 'stores/_mobx/systemSetup/dispatchRegions';
import { storeExamType } from 'stores/_mobx/systemSetup/masterSetting/examType';
import { storeDropdownTechnologist } from 'stores/_mobx/dropDown/technologist';
import { storeGroupManager } from 'stores/_mobx/clinicianManager/groupManager';
import { storeEmailTemplate } from 'stores/_mobx/systemSetup/masterSetting/emailTemplate';
import { storePhysician } from 'stores/_mobx/clinicianManager/physician';
import { storeDivisionMaster } from 'stores/_mobx/systemSetup/masterSetting/divisionMaster';
import RadioOptions from 'components/project/common/RadioOptions';

const AR_AGING_BALANCE_DUE_DAYS_OPTIONS = [
  { label: 'Current', value: '0to30' },
  { label: '31-60', value: '31to60' },
  { label: '61-90', value: '61to90' },
  { label: '91-120', value: '91to120' },
  { label: '120+', value: 'above' },
  { label: 'All', value: '' },
];

const AR_AGING_OPTIONS = [
  { value: 'F', label: 'Facility AR Aging' },
  { value: 'P', label: 'Patient AR Aging' },
];

const BALANCE_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'D', label: 'Due' },
  { value: 'P', label: 'Paid' },
];

const INVOICE_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'B', label: 'Aging' },
  { value: 'R', label: 'Posting' },
];

const optionsBillProcess = [
  {
    label: 'Fee for service',
    value: 'T',
  },
  {
    label: 'Purchased service',
    value: 'P',
  },
];

const optionCompletenessOfData = [
  { label: 'All', value: '' },
  { label: 'Complete', value: 'Y' },
  { label: 'Incomplete', value: 'I' },
];

const optionSearchBy = [
  { label: 'Facility name', value: 'N' },
  { label: 'Location', value: 'R' },
];

const labels: Record<string, any> = {
  assigned: 'Assignment',
  corporateId: 'Corporation',
  corporationId: 'Corporation',
  facilityID: 'Facility ID',
  facilityId: 'Facility',
  facility: 'Facility',
  fileName: 'File name',
  dosStart: 'DOS start',
  dosEnd: 'DOS end',
  invoiceDateFrom: 'Billed Date From',
  invoiceDateTo: 'Billed Date To',
  dateFrom: 'Date from',
  dateTo: 'Date to',
  loginDate: 'Login date',
  providerType: 'Provider Type',
  invoice: 'Invoice like',
  postedFrom: 'Posted from',
  postedTo: 'Posted to',
  invoiceFrom: 'Invoice from',
  invoiceTo: 'Invoice to',
  balance: 'Balance',
  stateId: 'State',
  state: 'State',
  orderType: 'Order Type',
  pos: 'POS',
  physicianId: 'Physician',
  lastName: 'Last Name',
  firstName: 'First Name',
  patientLastName: 'Patient Last Name',
  patientFirstName: 'Patient First Name',
  examType: 'Modality',
  locationId: 'Location',
  location: 'Location',
  radiologyGroupId: 'Radiology group',
  radiologistId: 'Radiologist',
  billProcess: 'Bill process',
  procedureCode: 'Procedure Code',
  procedureDescription: 'Procedure Description',
  technologistId: 'Technologist',
  techId: 'Technologist',
  actionType: 'Action type',
  year: 'Year',
  month: 'Month',
  employeeName: 'Employee Name',
  studyId: 'Study ID',
  mrn: 'Patient MRN',
  arAgingType: 'AR Aging type',
  clinicianGroupId: 'Clinician group',
  pecos: 'PECOS',
  status: 'Status',
  emailTemplateId: 'Email template',
  locality: 'Locality',
  timezone: 'Timezone',
  regionId: 'Region',
  region: 'Region',
  invoicesStatus: 'Invoice status',
  claim: 'Claim',
  balanceDueDays: 'Balance Due',
  division: 'Division',
  completenessOfData: 'State',
  searchKey: 'Search by',
};

const getValues: Record<string, any> = {
  corporateId: storeDropdownCorporation.findOptionById,
  corporationId: storeDropdownCorporation.findOptionById,
  facilityId: storeDropdownFacility.findOption,
  facility: storeDropdownFacility.findOption,
  providerType: storeProviderType.findOption,
  balance: (balance: string, duePeriod: string) => {
    const option = BALANCE_OPTIONS.find(({ value }) => value === balance);

    return balance === 'D'
      ? {
          ...option,
          label: `${option.label}${duePeriod ? ` (${duePeriod})` : ''}`,
        }
      : option;
  },
  duePeriod: () => null as null,
  balanceDueDays: (days: unknown) => {
    const option = AR_AGING_BALANCE_DUE_DAYS_OPTIONS.find(
      ({ value }) => value === days
    );

    return option;
  },
  stateId: storeDropdownState.findById,
  state: storeDropdownState.findById,
  orderType: storeDropdownOrderType.findBasicOrderType,
  pos: storeDropdownPlaceOfService.findOption,
  billProcess: (value: string) =>
    optionsBillProcess.find((el) => value === el.value),
  radiologistId: storeDropdownRadiologist.findOption,
  radiologyGroupId: storeDropdownRadiology.findOption,
  locationId: storeRegion.findOption,
  location: storeRegion.findOption,
  regionId: storeDispatchRegions.findOption,
  region: storeDispatchRegions.findOption,
  examType: storeExamType.findOption,
  technologistId: storeDropdownTechnologist.findOption,
  techId: storeDropdownTechnologist.findOption,
  arAgingType: (value: string) =>
    AR_AGING_OPTIONS.find((option) => option.value === value),
  clinicianGroupId: storeGroupManager.findOptionById,
  emailTemplateId: storeEmailTemplate.findTemplate,
  physicianId: storePhysician.findPhysicianById,
  timezone: (value: string) => {
    const option = optionsTimezone.find((el) => el.value === value);
    return option?.label || '';
  },
  invoicesStatus: (status: any) => {
    const option = INVOICE_OPTIONS.find(({ value }) => value === status);

    return option;
  },
  division: storeDivisionMaster.findOptionById,
  completenessOfData: (value: 'Y' | 'I' | '') =>
    optionCompletenessOfData.find((el) => el.value === value),
  status: (value: any) => RadioOptions.active.find((el) => el.value === value),
  searchKey: (value: any) => optionSearchBy.find((el) => el.value === value),
};

const filterToString = (filterValue: Record<string, any>) => {
  const result = Object.entries(filterValue)
    .filter(([key, value]) => key !== 'period' && Boolean(value))
    .map(([key, value]) => {
      const method = getValues[key];

      // This method need to parse filter for BriefcaseHistory page
      if (typeof value === 'object' && !Array.isArray(value)) {
        const subProp = Object.entries(value)
          .filter(([key, value]) => value)
          .map(([key]) => key)
          .join(', ');
        return subProp ? `${labels[key]}: ${subProp}` : '';
      }
      const valueIsArray = Array.isArray(value);

      let calculatedValue = '';

      if (method) {
        calculatedValue = valueIsArray
          ? value
              .map((val) => method(val, filterValue.duePeriod)?.label || '')
              .join(', ')
          : method(value, filterValue.duePeriod)?.label || '';
      } else {
        calculatedValue = valueIsArray ? value.join(', ') : value || '';
      }

      return calculatedValue ? `${labels[key]}: ${calculatedValue}` : '';
    });

  return result.filter((el) => Boolean(el)).join('; ');
};

export default filterToString;
