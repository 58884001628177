import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import ModifyThyroidShieldForm, { FormModel } from './components/Form';
import { URL_THYROID_SHIELD } from 'constant/path/workflow';

import { storeThyroidShield } from 'stores/_mobx/workflow/equipmentInventory/thyroidShield';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const ThyroidShieldEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, thyroidShield } = storeThyroidShield;

  useEffect(() => {
    storeThyroidShield.getThyroidShield(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (payload: FormModel) =>
    storeThyroidShield
      .updateThyroidShield(payload as Required<FormModel>)
      .then((response) => {
        if (response) {
          history.push(URL_THYROID_SHIELD);
        }
      });

  return (
    <>
      <Title title="Edit Thyroid Shield" />
      <ModifyThyroidShieldForm
        backUrl={URL_THYROID_SHIELD}
        defaultValues={thyroidShield}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(ThyroidShieldEditPage);
