interface PropsType {
  id: number;
  error: Map<string, string | number>;
  onMessageClick: (id: number) => void;
}

const ValidationMessageContent = ({ id, error, onMessageClick }: PropsType) => {
  const patientFullName = error.get('patientFullName');

  const handleClick = () => {
    onMessageClick(id);
  };

  return (
    <div
      className="row py-2 mb-4 rounded validation-message-card"
      onClick={handleClick}>
      <div className="col-sm-4 border-end border-light-subtle">
        {patientFullName}
      </div>
      <div className="col-sm-8 d-flex align-items-center flex-nowrap">
        <div className="d-flex flex-column gap-3 w-100">
          {
            // @ts-ignore
            [...error].map(([key, message]) =>
              key === 'id:' || key === 'patientFullName' ? null : (
                <div key={key}>
                  <b className="me-3">{key}</b>
                  <span className="text-danger">{message}</span>
                </div>
              )
            )
          }
        </div>
        <span className="validation-message-card-icon">
          <i className="bi bi-forward-fill" />
        </span>
      </div>
    </div>
  );
};

export default ValidationMessageContent;
