import CompanyUserPrivilegesPage from 'page/privileges/settings/CompanyUserPrivilegesPage';
import ClientUserPrivilegesPage from 'page/privileges/settings/ClientUserPrivilegesPage';

interface PropsType {
  userType: number;
  userId: number;
  isCompanyUser: boolean;
  onClose: () => void;
}

const PrivilegesForm = ({
  isCompanyUser,
  userId,
  userType,
  onClose,
}: PropsType) =>
  isCompanyUser ? (
    <CompanyUserPrivilegesPage
      userType={userType}
      userId={userId}
      backButton={onClose}
    />
  ) : (
    <ClientUserPrivilegesPage
      userType={userType}
      userId={userId}
      backButton={onClose}
    />
  );

export default PrivilegesForm;
