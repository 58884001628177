import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import { TClaimManager } from 'services/billing-coding/claims/ClaimsService';
import { dateToLocalTimezone } from 'utils/DateUtils';

export class PTcClaimsStore extends CommonPayload {}

export class STcClaimsStore {
  dataSource: Array<TClaimManager> = [];
  dataSourceCount: number = 0;
}

class TcClaimsStore extends ReduceStore<STcClaimsStore, PTcClaimsStore> {
  getInitialState() {
    return new STcClaimsStore();
  }

  areEqual(state1: STcClaimsStore, state2: STcClaimsStore) {
    return false;
  }

  reduce(state: STcClaimsStore, action: PTcClaimsStore) {
    const storeState = this.getState();
    switch (action.type) {
      case 'claim-tc-overview':
        storeState.dataSource = action.data.map((el: TClaimManager) => ({
          ...el,
          dos: dateToLocalTimezone({
            date: el.dos,
            dateOnly: true,
          }),
        }));
        storeState.dataSourceCount = (action as any).count;
        break;
      default:
        break;
    }
    return storeState;
  }
}

export default new TcClaimsStore(appDispatcher);
