import { observer, Observer } from 'mobx-react-lite';

import { IconButton } from 'components/button';

import { PermissionType } from 'stores/_mobx/workflow/order/transcription';

interface PropsType {
  idx: number;
  permission: PermissionType;

  isDeleteDisabled: boolean;
  onRemove: (idx: number) => void;
  onInsert: (idx: number) => void;
}

const GroupButtons = ({
  idx,
  permission,
  isDeleteDisabled,
  onInsert,
  onRemove,
}: PropsType) => {
  const handleClickRemove = () => {
    onRemove(idx);
  };

  const handleClickInsert = () => {
    onInsert(idx);
  };

  return (
    <div className="grouped-fields-controls">
      <Observer>
        {() => (
          <>
            {permission.add && (
              <IconButton onClick={handleClickInsert}>
                <i className="bi bi-plus-circle" />
              </IconButton>
            )}
            {permission.delete && (
              <IconButton
                disabled={isDeleteDisabled}
                onClick={handleClickRemove}>
                <i className="bi bi-trash" />
              </IconButton>
            )}
          </>
        )}
      </Observer>
    </div>
  );
};

export default observer(GroupButtons);
