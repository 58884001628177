import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import { ControlsLayout } from 'components/layout';
import { validationUserAuthentic as resolver } from './validation';

const defaultValues: FormModel = {
  userName: '',
  email: '',
};

export interface FormModel {
  userName: string;
  email: string;
}

interface PropsType {
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormUserAuthentic = ({ onSubmit }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>
        Please enter valid user name and recovery email address you provided
        when creating your account
      </p>
      <Input
        name="userName"
        label="Enter your user name"
        required
        control={control}
      />
      <Input name="email" label="Enter your email" required control={control} />

      <ControlsLayout alignX="center">
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormUserAuthentic;
