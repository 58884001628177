import EligibilityManagerPageService, {
  TOrdersFilter,
  TArchivePayer,
  TCompletedInsuranceInfo,
} from 'services/billing-coding/EligibilityManagerPageService';
import FacilityService from 'services/project/FacilityService';
import appDispatcher from 'dispatcher/AppDispatcher';
import { TPagination } from 'components/grid/GridTypes';

export default class EligibilityManagerPageActions {
  static loadGridData(
    filter: TOrdersFilter,
    pagination: TPagination,
    signal: AbortSignal
  ) {
    const promiseCount = EligibilityManagerPageService.loadOverviewCount(
      filter,
      signal
    );
    const promiseList = EligibilityManagerPageService.loadOverview(
      filter,
      pagination,
      signal
    );

    return Promise.all([promiseCount, promiseList]).then(([count, list]) => {
      appDispatcher.dispatch({
        type: 'claim-management-eligibility-manager-overview',
        data: list,
        count,
      } as any);
      return [];
    });
  }

  static exportOrders(
    filter: TOrdersFilter,
    pagination: TPagination,
    ids: Array<number>,
    updateStatus: boolean = false,
  ) {
    return EligibilityManagerPageService.exportOrders(filter, pagination, ids, updateStatus);
  }

  static updateStatus(flag: 'W' | 'I' | 'C' | '', ids: Array<number>) {
    return EligibilityManagerPageService.updateStatus(flag, ids);
  }

  static archivePayer(
    refid: number,
    payorType: 'P' | 'S' | 'T',
    data: TArchivePayer,
    flagOrderEntryPayerInfo: boolean
  ) {
    return EligibilityManagerPageService.archivePayer(
      refid,
      payorType,
      data,
      flagOrderEntryPayerInfo
    );
  }

  static deletePayor(refid: number) {
    return EligibilityManagerPageService.deletePayor(refid);
  }

  static updateCompletedInsuranceInfo(
    data: TCompletedInsuranceInfo,
    patientId: number,
    orderDataWithId: Array<number>,
    insStatus: 'W' | 'I' | 'C' | '',
    correctional: {
      ice_number: string;
      inmate_number: string;
      immigration_id: string;
      orderid?: number;
    },
    insNotes: string,
    msp: number,
    flagOrderEntryPayerInfo: boolean
  ): Promise<'SE' | 'S' | 'E'> {
    return EligibilityManagerPageService.updateCompletedInsuranceInfo(
      data,
      patientId,
      orderDataWithId,
      insStatus,
      correctional,
      insNotes,
      msp,
      flagOrderEntryPayerInfo
    );
  }

  static loadGuarantor(facilityId: number) {
    return FacilityService.loadFacilityValues(facilityId).then((r) => {
      appDispatcher.dispatch({
        type: 'facility.FacilityMaster.GetFacilityValues-forEligibility',
        data: r && r[0] ? r[0] : {},
        loaded: true,
      } as any);
      return r;
    });
  }

  static clearGuarantor() {
    appDispatcher.dispatch({
      type: 'facility.FacilityMaster.GetFacilityValues-forEligibility',
      data: [],
      loaded: false,
    } as any);
  }

  static getArchivePayerInfo(payerId: number, orderId: number) {
    return EligibilityManagerPageService.loadArchivePayerInfo(payerId, orderId);
  }

  static getInitialPayerInfo(
    payerId: number,
    orderId: number,
    flagOrderEntryPayerInfo: boolean
  ) {
    return EligibilityManagerPageService.loadInitialPayerInfo(
      payerId,
      orderId,
      flagOrderEntryPayerInfo
    );
  }

  static revertOrder(orderId: number) {
    return EligibilityManagerPageService.revertOrder(orderId);
  }
}
