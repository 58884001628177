import { Button } from 'components/button';
import { useForm } from 'react-hook-form';

import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { DropdownRadiology } from 'components/project/dropdown/RadiologyDropdown';
import { DropDownExamType } from 'components/project/dropdown/ExamTypeDropdown';
import { DropDownCorporation } from 'components/project/dropdown/CorporateDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropdownDivision } from 'components/project/dropdown/Division';
import { InputCalendar } from 'components/form/inputCalendar';
import { ControlsLayout } from 'components/layout';

import {
  storeFindingCoding,
  filterDefaultValues,
  FilterType,
} from 'stores/_mobx/billingCodding/findingCoding';

const className = 'col-md-6 col-lg-3';

interface PropsType {
  fetching: boolean;
  isVisible: boolean;
  onSubmit: (data: FilterType) => void;
}

const Filter = ({ isVisible, fetching, onSubmit }: PropsType) => {
  const { control, reset, handleSubmit } = useForm({
    defaultValues: storeFindingCoding.filter,
  });

  const handleClickReset = () => {
    reset(filterDefaultValues);
    onSubmit(filterDefaultValues);
  };

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <DropDownFacility
        name="facilityId"
        label="Facility"
        className={className}
        control={control}
      />
      <DropDownExamType
        name="examType"
        label="Modality"
        optionsForOrderOnly
        className={className}
        control={control}
      />
      <DropDownCorporation
        name="corporationId"
        label="Corporation"
        className={className}
        control={control}
      />
      <DropDownState
        name="stateId"
        label="State"
        className={className}
        control={control}
      />
      <DropdownDivision
        name="division"
        label="Division"
        className={className}
        control={control}
      />
      <DropDownPlaceOfService
        name="pos"
        label="POS Code"
        className={className}
        control={control}
      />
      <InputCalendar
        name="dosStart"
        label="Start Date"
        className={className}
        control={control}
      />
      <InputCalendar
        name="dosEnd"
        label="End Date"
        className={className}
        control={control}
      />
      <DropdownRadiology
        name="radiologyGroupId"
        label="Radiology Group"
        className={className}
        control={control}
      />

      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
