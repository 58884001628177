import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { IconButton } from 'components/button';
import DashboardCard from './components/DashboardCard';
import EmailWithErrorsModal from './components/DialogEmailWithError';

import { storeEmailDashboard } from 'stores/_mobx/integration/emailDashboard';

const EmailDashboard = () => {
  const { fetching, info } = storeEmailDashboard;

  const [isReportVisible, toggleVisibility] = useState<boolean>(false);

  const status = info?.status ? 'On' : 'Off';

  const handleToggleVisibility = () => {
    toggleVisibility((state) => !state);
  };

  const refreshData = () => {
    if (!fetching) storeEmailDashboard.getDashboardInfo();
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardCard
        title="Emails Dashboard"
        fetching={fetching}
        onUpdate={refreshData}>
        <>
          <div>In Queue: {info?.inQueue || 0}</div>
          <div>
            With Errors: {info?.errors || 0}
            {info ? (
              <IconButton
                className="mx-3"
                disabled={!info?.errors}
                onClick={handleToggleVisibility}>
                <i className="bi bi-eye-fill" />
              </IconButton>
            ) : null}
          </div>
          <div>Last Error: {info?.lastError || ''}</div>
          <div>
            Status:
            <IconButton
              className="dashboard-status"
              disabled={fetching || !info}
              onClick={storeEmailDashboard.changeStatus}>
              {info ? status : 'unknown'}
            </IconButton>
          </div>
        </>
      </DashboardCard>
      {isReportVisible && (
        <EmailWithErrorsModal onClose={handleToggleVisibility} />
      )}
    </>
  );
};

export default observer(EmailDashboard);
