import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';

import { storeUserCategory } from 'stores/_mobx/systemSetup/masterSetting/userCategory';

const DropdownUserCategory = (props: Omit<SelectPropsType, 'options'>) => {
  const { fetching, options } = storeUserCategory;

  useEffect(() => {
    storeUserCategory.getOptions();
  }, []);

  return <Select {...props} options={options} isLoading={fetching} />;
};

export default observer(DropdownUserCategory);
