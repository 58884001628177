import React, { useState } from 'react';
import { Control, ValidationRule, useController } from 'react-hook-form';

import NpiInput, { PNpiInput, SNpiInput } from 'components/project/NpiInput';
import { DialogNPI } from 'page/components/NPI';
import PureInput, { PureInputProps } from 'components/form/textField/PureInput';
import Badge from 'components/badge';

import { NpiType } from 'stores/_mobx/npi';

export interface PNpiLookupInput extends PNpiInput {
  onLookup?: (entity: NpiType) => void;
}

export class SNpiLookupInput extends SNpiInput {
  showLookup: boolean = false;
}

export default class NpiLookupInput extends NpiInput<
  PNpiLookupInput,
  SNpiLookupInput
> {
  state = new SNpiLookupInput();

  handleCloseLookup = () => {
    this.setState({ showLookup: false });
  };

  getLabelValue() {
    const out = super.getLabelValue();
    const link = (
      <Badge variant="danger" className="mx-2">
        <a
          key="npi"
          href="/"
          onClick={(event) => this.showLookup(event)}
          className="link-light">
          NPI Lookup
        </a>
      </Badge>
    );
    if (out !== null) {
      return [out, link];
    }
    return link;
  }

  showLookup(event: React.SyntheticEvent) {
    event.preventDefault();
    this.setState({ showLookup: true });
  }

  getContentAfter(): React.ReactNode {
    const parent = super.getContentAfter();
    const link = this.state.showLookup && (
      <DialogNPI
        npi={String(this.props.value)}
        onClose={this.handleCloseLookup}
        onSelect={this.onLookupEnd}
      />
    );
    if (link) {
      if (parent) {
        return [link, parent];
      }
      return link;
    } else if (parent) {
      return parent;
    }
    return null;
  }

  onLookupEnd = (entity: NpiType) => {
    this.props.onLookup(entity);

    this.setState({ showLookup: false });
  };
}

interface PropsType
  extends Omit<
    PureInputProps,
    | 'value'
    | 'name'
    | 'onChange'
    | 'onBlur'
    | 'onFocus'
    | 'errorMessage'
    | 'pattern'
  > {
  control: Control<any>;
  name: string;
  pattern?: ValidationRule<RegExp>;
  onPhysicianSelected: (entity: NpiType) => void;
}

export const InputNpiExtended = ({
  control,
  name,
  required = false,
  pattern,
  onPhysicianSelected,
  ...rest
}: PropsType) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required,
      max: rest.max,
      maxLength: rest.maxLength,
      pattern,
    },
  });

  const [isDialogVisible, toggleDialog] = useState<boolean>(false);

  const errorMessage = error?.message || '';

  const handleToggleDialog = () => {
    toggleDialog((state) => !state);
  };

  const showSearchPhysicianDialog = () => {
    handleToggleDialog();
  };

  const handlePhysicianSelected = (entity: NpiType) => {
    onPhysicianSelected(entity);
    handleToggleDialog();
  };

  const handleChange = (value: string) => {
    const isValueCorrect = value ? /^\d+$/.test(value) : true;
    if (isValueCorrect) field.onChange(value);
    else field.onChange(field.value);
  };

  return (
    <>
      <PureInput
        errorMessage={errorMessage}
        required={required}
        {...rest}
        {...field}
        maxLength={10}
        onChange={handleChange}>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={showSearchPhysicianDialog}>
          <i className="bi bi-search" />
        </button>
      </PureInput>

      {isDialogVisible && (
        <DialogNPI
          npi={String(field.value)}
          onClose={handleToggleDialog}
          onSelect={handlePhysicianSelected}
        />
      )}
    </>
  );
};
