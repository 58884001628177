import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { URL_EKG } from 'constant/path/workflow';
import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import {
  storeEkg,
  EkgFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/ekg';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const EkgEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, ekg } = storeEkg;

  const handleSubmit = async (data: EkgFormModel) =>
    storeEkg.updateEkg(data).then((error) => {
      if (error) return error;
      history.push(URL_EKG);
    });

  useEffect(() => {
    if (!fetching) storeEkg.getEkg(Number(params.id));

    return storeEkg.clearEkg;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit EKG" />

      <Form backUrl={URL_EKG} defaultValues={ekg} onSubmit={handleSubmit} />

      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(EkgEditPage);
