import React from 'react';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import Form from 'components/form/Form';
import Radio from 'components/form/input/Radio';
import Notification from 'components/modal/Notification';
import { Grid } from 'components/grid';
import CsvExporter from 'utils/CsvExporter';
import XlsxExporter from 'utils/XlsxExporter';
import AllDsExporter from 'utils/AllDsExporter';
import filterToString from 'utils/filterToString';
import { BASE_URL_FILE_DIR } from 'constant/config';
import { downloadFile } from 'utils/downloadFile';

const defaultOptions = [
  { value: 'csv', label: 'CSV' },
  { value: 'xlsx', label: 'XLSX' },
];

interface ExporterModel {
  type: string;
  volume: string;
}

interface PropsType {
  reportName: string;
  pdfExporter?: (props?: { filter: string; data: any }) => Promise<string>;
  exporter?: string;
  logicPagination?: any;
  afterDataSource?: Array<any>;
  filter?: Record<string, any>;
  filterForRequest?: any[] | Record<string, any> | [Record<string, any>];
  exportOptions?: Array<{
    value: string;
    label: string;
  }>;
  columnsForExport?: Record<string, string>[];
  gridRef: React.RefObject<Grid>;
  dataSource: Array<any>;
  columns: Array<any>;
  selected?: (string | number)[];
  exportOnBackend?: boolean;
  onClose: () => void;
}

class StateType {
  model: ExporterModel = {
    type: 'csv',
    volume: 'screen',
  };
  errors: any = {};
  errorMessages: any = {};
}

class Exporter extends React.Component<PropsType, StateType> {
  submitRef = React.createRef<HTMLButtonElement>();

  constructor(props: PropsType) {
    super(props);
    const state = new StateType();
    const type = localStorage.getItem('export_type');
    const optionIsExist = (props.exportOptions || defaultOptions).some(
      ({ value }) => value === type
    );
    state.model.type = optionIsExist ? type : 'csv';
    this.state = state;
  }

  handleClose = () => {
    this.props.onClose();
  };

  handleSubmit = () => {
    this.submitRef.current?.click();
  };

  onUpdateModel = (
    name?: string,
    value?: string,
    errorName?: string,
    errorKeys?: any,
    event?: Event,
    clb?: () => void
  ) => {
    const model = {
      ...this.state.model,
      [name]: value,
    };

    const errors = { ...this.state.errors, [name]: errorName };

    this.setState({ model, errors }, clb);
  };

  submit = (model?: any, hasErrors?: boolean, errors?: any) => {
    if (hasErrors) {
      this.setState({ errors, model });
    } else {
      this.submitSuccess(model);
    }
  };

  render() {
    return (
      <Dialog handleClose={this.handleClose}>
        <DialogHeader title="Export" onClose={this.handleClose} />
        <DialogBody>
          <Form
            ref="form"
            model={this.state.model}
            errors={this.state.errors}
            errorMessages={this.state.errorMessages}
            onCollectValues={this.onUpdateModel}
            submit={this.submit}>
            <Radio
              name="type"
              validations="required"
              onSetValue={(n, v, e) => this.onUpdateType(n, v, e)}
              options={this.getTypeOptions()}
            />
            <Radio
              name="volume"
              validations="required"
              options={this.getVolumeOptions()}
            />

            <button type="submit" className="d-none" ref={this.submitRef} />
          </Form>
        </DialogBody>
        <DialogFooter>
          <Button text="Submit" onClick={this.handleSubmit} />
        </DialogFooter>
      </Dialog>
    );
  }

  onUpdateType(name?: string, value?: string, errorName?: string) {
    localStorage.setItem('export_type', value);
    this.onUpdateModel(name, value, errorName);
  }

  getColumns = () => this.props.columnsForExport || this.getGridCleanColumns();

  submitSuccess(model: ExporterModel) {
    const { exportOnBackend } = this.props;
    const columns = this.getColumns();
    const filter = this.getFiltersString();
    // START Only for export facilities & physicians
    if (exportOnBackend && model.type !== 'pdf') {
      const idsForExport =
        exportOnBackend && model.volume !== 'all' ? this.prepareData()[1] : [];
      if (model.volume !== 'all' && !idsForExport.length) {
        Notification.warning('Nothing to export');
        return;
      }

      const exporter = new AllDsExporter(
        columns,
        model.type,
        this.props.exporter,
        this.props.filterForRequest || this.props.filter,
        filter,
        idsForExport
      );
      exporter
        .exportAndDownload(this.props.reportName || 'report')
        .catch(() => {
          Notification.danger('Error occurred during exporting data.');
        });
    }
    // END
    else if (model.volume === 'all' && !this.props.logicPagination) {
      const exporter = new AllDsExporter(
        columns,
        model.type,
        this.props.exporter,
        this.props.filterForRequest || this.props.filter,
        filter
      );
      exporter
        .exportAndDownload(this.props.reportName || 'report')
        .catch(() => {
          Notification.danger('Error occurred during exporting data.');
        });
    } else {
      let exporter;
      let [data, ids] = this.prepareData();
      if (data !== null && data.length === 0) {
        Notification.warning('Nothing to export');
        return;
      }
      if (this.props.afterDataSource) {
        data = [].concat(data, this.props.afterDataSource);
      }

      switch (model.type) {
        case 'csv':
          exporter = new CsvExporter(data, columns, filter);
          break;
        case 'xlsx':
          exporter = new XlsxExporter(data, columns, '', filter);
          break;
        case 'pdf':
          this.props.pdfExporter({ filter, data: ids }).then((filePath) => {
            if (filePath) {
              downloadFile(`${BASE_URL_FILE_DIR}${filePath}`, true);
              this.submitCallBack();
            } else {
              Notification.danger('There is no data to export');
            }
          });
          return;
        default:
          Notification.danger(
            'An error occurred, look like export type is not specified.'
          );
          return;
      }
      exporter.exportAndDownload(this.props.reportName || 'report');
    }
    this.submitCallBack();
  }

  submitCallBack = () => {
    this.props.onClose();
    Notification.success(
      'Please wait for a moment, your report will be ready after few moments'
    );
  };

  getTypeOptions() {
    const exportOptions = this.props.exportOptions || defaultOptions;
    return this.props.pdfExporter
      ? [...exportOptions, { value: 'pdf', label: 'PDF' }]
      : exportOptions;
  }

  getVolumeOptions() {
    const out = [{ value: 'screen', label: 'Current page' }];

    try {
      if (this.getGrid().props.onSelectChange) {
        out.push({ value: 'selected', label: 'Selected' });
      }
    } catch (e) {
      throw new Error(
        'error with this.props.this.refs.grid.state.selected chain'
      );
    }
    if (this.props.exporter || this.props.logicPagination) {
      out.push({ value: 'all', label: 'All' });
    }
    return out;
  }

  prepareData() {
    switch (this.state.model.volume) {
      case 'screen':
        return this.getCleanDataSource();
      case 'selected':
        return this.getSelectedDataSource();
      case 'all':
        return [this.props.logicPagination ? this.props.dataSource : null];
      default:
        Notification.danger(
          'An error occurred, look like data volume is not specified'
        );
        return [];
    }
  }

  getGrid = (): Grid => {
    return this.props.gridRef.current;
  };

  getSelectedDataSource() {
    const filteredDs = [];
    const grid = this.getGrid();
    const gridDataSource = this.getInitialDataSource();
    const { selected } = Array.isArray(this.props.selected)
      ? this.props
      : grid.state;
    const idKey = grid.props.selectId;

    for (let i = 0; i < gridDataSource.length; i++) {
      for (let s = 0; s < selected.length; s++) {
        const sel = selected[s];
        if (sel === gridDataSource[i][idKey]) {
          filteredDs.push(gridDataSource[i]);
          break;
        }
      }
    }
    return this.getCleanDataSource(filteredDs);
  }

  getInitialDataSource() {
    const grid = this.getGrid();
    return grid.state.sort ? grid.getSortedDataSource() : grid.props.dataSource;
  }

  getGridCleanColumns() {
    try {
      const columns = [...this.props.columns];

      if (columns[0] && columns[0].name === 'select-all-column') {
        columns.shift();
      }
      let index = columns.length;
      while (index--) {
        const col = columns[index];
        if (this.exclude(col.export)) {
          columns.splice(index, 1);
        }
      }
      return columns;
    } catch (e) {
      throw new Error(
        'Error with this.props.this.refs.grid.props.dataSource chain'
      );
    }
  }

  exclude(exp: any) {
    if (exp) {
      if (typeof exp === 'string') {
        return exp === 'exclude';
      }
      return exp.exclude;
    }
    return false;
  }

  getCleanDataSource(filteredDs?: any) {
    try {
      const grid = this.getGrid();
      const idKey = grid.props.selectId;
      const shownColumns = this.getColumns();
      const gridDs = filteredDs || this.getInitialDataSource();
      const out = [];
      for (let i = 0; i < gridDs.length; i++) {
        const row: any = {};
        const item = gridDs[i];
        for (let key in item) {
          if (!item.hasOwnProperty(key)) {
            continue;
          }
          for (let c = 0; c < shownColumns.length; c++) {
            const col = shownColumns[c];
            if (col.name === key) {
              if (col.export && typeof col.export.target === 'function') {
                row[key] = col.export.target(item[key], item);
              } else if (this.getDataAsRender(col.export)) {
                row[key] = col.render(item[key], item);
              } else {
                row[key] = item[key];
              }
              break;
            }
          }
        }
        out.push(row);
      }
      return [out, gridDs.map((el: any) => el[idKey])];
    } catch (e) {
      throw new Error(
        'Error with this.props.this.refs.grid.props.dataSource chain'
      );
    }
  }

  getDataAsRender(exp: any) {
    if (exp) {
      if (typeof exp === 'string') {
        return exp === 'render';
      }
      return exp.target === 'render';
    }
    return false;
  }

  getFiltersString() {
    const { filter } = this.props;

    return filter ? filterToString(filter) : '';
  }
}

export default Exporter;
