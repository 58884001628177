import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Input } from 'components/form/textField';

import {
  storeIntegrations,
  FilterModel,
} from 'stores/_mobx/systemSetup/masterSetting/integrations';

interface PropsType {
  onFilterChange: (filter: FilterModel) => void;
}

const Filter = ({ onFilterChange }: PropsType) => {
  const { control, watch } = useForm<FilterModel>({
    defaultValues: storeIntegrations.filter,
  });

  useEffect(() => {
    const subscription = watch((formValue) => {
      onFilterChange(formValue as FilterModel);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form>
      <div className="row">
        <Input
          name="name"
          placeholder="Integration name"
          className="col-sm-6 col-lg-4"
          control={control}
        />
      </div>
    </form>
  );
};

export default Filter;
