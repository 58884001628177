import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import { PureInput } from 'components/form/textField';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';

import { storeSpecialty } from 'stores/_mobx/systemSetup/masterSetting/specialty';
import { MS_SPECIALTY as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_SPECIALTY,
  URL_SPECIALTY_ADD,
} from 'constant/path/systemSetup';

const columns = [
  { name: 'specialty_name', title: 'Specialty Name' },
  { name: 'texonomy_code', title: 'Taxonomy Code' },
  {
    name: 'refid',
    title: 'Action',
    className: 'width-75',
    render: (id: number) => (
      <div className="control">
        <Link className="bi bi-pencil" to={`${URL_SPECIALTY}/edit/${id}`} />
        <IconButton onClick={() => storeSpecialty.setIdForDelete(id)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const SpecialtyListPage = () => {
  const {
    fetching,
    specialtyList,
    specialtyTotal,
    idForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeSpecialty;

  const [specialtyName, setSpecialtyName] = useState<string>('');

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleFilterChange = (specialty: string) => {
    setSpecialtyName(specialty);

    debouncedMethod();
  };

  const handleConfirmDelete = () => {
    storeSpecialty.deleteSpecialty().then((isSucceed) => {
      if (isSucceed) storeSpecialty.getSpecialtiesMain(specialtyName);
    });
  };

  useEffect(() => {
    storeSpecialty.getSpecialtiesMain(specialtyName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle title="Specialty" appId={PAGE_ID.PAGE}>
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Specialty Name"
        className="col-sm-12 col-md-6 col-lg-4"
        value={specialtyName}
        onChange={handleFilterChange}
      />

      <Grid
        id="mvSpecialtyGrid"
        columns={columns}
        onAjax={fetching}
        dataSource={specialtyList}
        dataSourceCount={specialtyTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_SPECIALTY_ADD} />
        }
      />
      {idForDelete ? (
        <DialogConfirm
          onCancel={storeSpecialty.clearIdForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this Specialty?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(SpecialtyListPage);
