import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface TrashResponseType {
  mail_date: string;
  mail_subject: string;
  mail_time: string;
  mail_to: string;
  messageid: number;
  messdetail_id: number;
  refid: number;
  trash: string;
}

interface TrashType {
  id: number;
  to: string;
  subject: string;
  date: string;
  time: string;
}

interface EmailType {
  stat: 'Trash';
  flag: 'R';
  mode: null;
  idvalue: number;
}

class Trash {
  fetching: boolean = false;
  trashList: TrashType[] = [];
  trashesCount: number = 0;
  filter: string = '';
  page: Pagination = new Pagination({ id: 'trash_page_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      trashList: observable,
      trashesCount: observable,
      filter: observable,

      setFetching: action,
      setTrashList: action,
      setTrashCount: action,
      setFilter: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setTrashList(list: TrashType[]) {
    this.trashList = list;
  }

  setTrashCount(count: number) {
    this.trashesCount = count;
  }

  setFilter(filter: string) {
    this.filter = filter;
  }

  async getTrashList(search: string) {
    const { pagination } = this.page;

    const data = {
      search,
      StartIndex: pagination.skip,
      Limit: pagination.pageSize,
    };
    try {
      const response = await apiRequest<TrashResponseType[] | 'SE'>({
        url: 'email.Email.NewTrashItemList',
        data,
      });

      if (Array.isArray(response)) {
        const list = response.map(({ mail_date, mail_time, ...mail }) => {
          const [date, time] = dateToLocalTimezone({
            date: `${mail_date} ${mail_time}`,
          }).split(' ');

          return {
            date,
            time,
            id: mail.refid,
            to: mail.mail_to,
            subject: mail.mail_subject,
          };
        });
        this.setTrashList(list);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e: any) {
      this.setTrashList([]);
    }
  }

  async getTrashesTotal(filter: string) {
    try {
      const count = await apiRequest<number>({
        url: 'email.Email.NewTrashItemCount',
        data: {
          filter,
        },
      });

      this.setTrashCount(count);
    } catch (e: any) {
      this.setTrashCount(0);
    }
  }

  getTrashListMain = async () => {
    this.setFetching(true);

    const search = this.filter;

    const promiseCount = this.getTrashesTotal(search);

    const promiseTrashList = this.getTrashList(search);

    Promise.allSettled([promiseCount, promiseTrashList]).finally(() => {
      this.setFetching(false);
    });
  };

  async clearTrash(ids: number[]) {
    const payload: EmailType[] = ids.map((idvalue) => ({
      flag: 'R',
      mode: null,
      stat: 'Trash',
      idvalue,
    }));

    try {
      const response = await apiRequest<string>({
        url: 'email.Email.MoveEmail',
        data: [payload],
      });

      if (response !== 'SE') {
        Notification.success('Item was deleted!');
        return true;
      }
      throw Error('Error occurred!');
    } catch (e: any) {
      Notification.danger("Can't delete message due some unknown reasons.");
      return false;
    }
  }
}

export const storeTrash = new Trash();
