import { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import { Button } from 'components/button';
import Export from 'components/project/ExporterNew';
import Filter from './components/Filter';

import UserProfileStore from 'stores/UserProfileStore';
import {
  storeModalitySummary,
  FilterType,
} from 'stores/_mobx/report/modalitySummary';
import { MODALITY_SUMMARY as PAGE_ID } from 'constant/pagesId/reports';

const columns = [
  { name: 'modality', title: 'Modality' },
  {
    name: 'count',
    title: 'Procedure Count',
    className: 'text-center width-100',
  },
];

const ModalitySummaryPage = () => {
  const {
    fetching,
    logList,
    logTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeModalitySummary;

  const gridRef = useRef<Grid>(null);

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const appName = useMemo(
    () =>
      UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
      'Modality_Summary_Report',
    []
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleApplyFilter = (filter: FilterType) => {
    storeModalitySummary.setFilter(filter);
    setPaginationToStart();
  };

  useEffect(() => {
    storeModalitySummary.setDefaultFilter();
  }, []);

  useEffect(() => {
    storeModalitySummary.getModalitySummaryLog();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Modality Summary">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        fetching={fetching}
        isFilterVisible={isFilterVisible}
        onSearch={handleApplyFilter}
      />

      <Grid
        id="modality_summary_gridId"
        noDataText="Please refine your search using the filters above"
        gridControlPanel={
          <GridControlButton
            title="Export"
            disabled={!logTotal}
            onClick={handleToggleExport}
          />
        }
        columns={columns}
        ref={gridRef}
        onAjax={fetching}
        dataSource={logList}
        dataSourceCount={logTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
      {isExportVisible && (
        <Export
          reportName={appName}
          filter={filter}
          gridRef={gridRef}
          columns={columns}
          dataSource={logList}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default observer(ModalitySummaryPage);
