import { useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import Tabs from 'components/tabs';
import DocumentAcquisitionTab from './AcquisitionTab';
import DocumentAuditTab from './DocumentAuditTab';

import { DOC_MANAGER as PAGE_ID } from 'constant/pagesId/patientRecords';
import {
  URL_DOC_MANAGER_ACQUISITION,
  URL_DOC_MANAGER_AUDIT,
} from 'constant/path/patientRecords';

const tabsList = [
  {
    label: 'Document Acquisition',
    url: URL_DOC_MANAGER_ACQUISITION,
  },
  {
    label: 'Document Audit',
    url: URL_DOC_MANAGER_AUDIT,
  },
];

const DocManagerPage = ({ location }: RouteComponentProps) => {
  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isButtonVisible, toggleButton] = useState<boolean>(true);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  return (
    <>
      <LayoutSideTitle title="Document Manager" appId={PAGE_ID.PAGE}>
        {isButtonVisible && (
          <Button
            variant="default"
            text={isFilterVisible ? 'Hide filter' : 'Show filter'}
            onClick={handleToggleFilter}
          />
        )}
      </LayoutSideTitle>

      <Tabs tabsList={tabsList} />

      <Switch>
        <Route
          exact
          path={tabsList[0].url}
          render={() => (
            <DocumentAcquisitionTab isFilterVisible={isFilterVisible} />
          )}
        />
        <Route
          exact
          path={tabsList[1].url}
          render={() => (
            <DocumentAuditTab
              isFilterVisible={isFilterVisible}
              toggleFilterButton={toggleButton}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default DocManagerPage;
