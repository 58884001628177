import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Input } from 'components/form/textField';

export interface FormFeesValueType {
  lateFee: number;
  lateFeePaid: number;
}

interface PropsType {
  defaultValues: FormFeesValueType;
  isBulk: boolean;
  onUpdate: (payload: FormFeesValueType) => void;
}

const FormFees = ({ defaultValues, isBulk, onUpdate }: PropsType) => {
  const { control, watch, reset } = useForm<FormFeesValueType>({
    defaultValues,
  });

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      const lateFeePaid = isBulk ? formValue.lateFee : formValue.lateFeePaid;

      const finalFormValue = {
        ...formValue,
        lateFeePaid,
      };

      if (name) {
        onUpdate(finalFormValue as Required<FormFeesValueType>);
      }
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBulk]);

  return (
    <form className="row">
      <Input
        name="lateFee"
        label="Late Fee"
        className="col-md-4 col-sm-6"
        type="number"
        isChildrenPrefix
        control={control}>
        <span className="input-group-text">$</span>
      </Input>
      <Input
        name="lateFeePaid"
        className="col-md-4 col-sm-6"
        label="Late Fee Payment"
        type="number"
        isChildrenPrefix
        disabled={isBulk}
        control={control}>
        <span className="input-group-text">$</span>
      </Input>
    </form>
  );
};

export default FormFees;
