import { Link } from 'react-router-dom';

const PageNotFound = () => (
  <div className="error-page">
    <h1>404</h1>
    <div className="error-page-title">Page not found</div>
    <div className="error-page-body">
      The page you are looking for does not seem to exist.
    </div>
    <Link to="/" className="btn btn-success">
      Go Home
    </Link>
  </div>
);

export default PageNotFound;
