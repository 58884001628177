import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Badge from 'components/badge';
import { IconButton } from 'components/button';
import { Grid } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import Title from 'components/project/common/title';
import useDebounce from 'components/hook/useDebounce';
import DialogImport from './components/DialogImport';
import DiagnosisCodesFilter from './components/Filter';
import DiagnosisControlPanel from './components/ControlPanel';

import {
  storeDiagnosisCode,
  FilterModel,
} from 'stores/_mobx/systemSetup/codeSet/diagnosisCodes';
import AccessUtils from 'utils/AccessUtils';
import { CODES_SET_DIAGNOSIS as PAGE_ID } from 'constant/pagesId/systemSetup';
import { URL_DIAGNOSIS_CODES } from 'constant/path/systemSetup';

const DiagnosisCodesListPage = () => {
  const gridRef = useRef(null);

  const {
    fetching,
    uploadingInProgress,
    codesList,
    codesTotal,
    codeForDelete,
    page: { pagination, setPagination, setPaginationToStart },
    filter,
  } = storeDiagnosisCode;

  const [selected, setSelected] = useState<number[]>([]);

  const [isImportActive, setImportStatus] = useState<boolean>(false);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const renderCell = (icdCode: string) =>
    filter.billable === 'B' ? (
      icdCode
    ) : (
      <span className="text-danger">{icdCode}</span>
    );

  const columns = useMemo(
    () => [
      {
        name: 'icd_code',
        title: 'ICD Code',
        render: renderCell,
      },
      {
        name: 'short_description',
        title: 'ICD Description',
        render: renderCell,
      },
      {
        name: 'status',
        title: 'Status',
        render: (value: string) => (
          <Badge variant={value === 'Active' ? 'success' : 'danger'}>
            {value}
          </Badge>
        ),
      },
      AccessUtils.checkAccessAny(PAGE_ID.EDIT, PAGE_ID.DELETE)
        ? {
            name: 'refid',
            title: 'Control',
            render: (id: number) => (
              <div className="control">
                {filter.status === 'A' &&
                  AccessUtils.get(
                    PAGE_ID.EDIT,
                    <Link
                      className="bi bi-pencil"
                      to={`${URL_DIAGNOSIS_CODES}/edit/${id}`}
                    />
                  )}
                {AccessUtils.get(
                  PAGE_ID.DELETE,
                  <IconButton
                    onClick={() => storeDiagnosisCode.setCodeForDelete(id)}>
                    <i className="bi bi-trash" />
                  </IconButton>
                )}
              </div>
            ),
          }
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter.billable, filter.status]
  );

  const handleChangeFilter = (filter: FilterModel) => {
    storeDiagnosisCode.setFilter(filter);

    debouncedMethod();
  };

  const handleChangeStatus = () => {
    storeDiagnosisCode
      .changeCodeStatus(selected, filter.status)
      .then((result) => {
        if (result) storeDiagnosisCode.getCodesListMain();
      });
  };

  const handleClickExport = () => {
    storeDiagnosisCode.exportCodes(selected).then((result) => {
      if (result) gridRef.current?.clearSelection();
    });
  };

  const handleToggleImport = () => {
    setImportStatus((state) => !state);
  };

  const handleImport = (file: File) => {
    storeDiagnosisCode.importCodes(file).then((response) => {
      if (response) {
        setImportStatus(false);
        storeDiagnosisCode.getCodesListMain();
      }
    });
  };

  const handleConfirmDelete = () => {
    storeDiagnosisCode.deleteCode().then((response) => {
      if (response) {
        storeDiagnosisCode.getCodesListMain();
      }
    });
  };

  useEffect(() => {
    storeDiagnosisCode.getCodesListMain();
  }, [pagination]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Diagnosis Codes" />

      <DiagnosisCodesFilter onChange={handleChangeFilter} />

      <Grid
        id="diagnosis_codes_grid"
        selectId="refid"
        columns={columns}
        onAjax={fetching}
        dataSource={codesList}
        dataSourceCount={codesTotal}
        ref={gridRef}
        pagination={pagination}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        gridControlPanel={
          <DiagnosisControlPanel
            isActive={filter.status === 'A'}
            isButtonDisabled={!selected.length}
            basicUrl={URL_DIAGNOSIS_CODES}
            onChangeStatus={handleChangeStatus}
            onClickImport={handleToggleImport}
            onClickExport={handleClickExport}
          />
        }
      />

      {codeForDelete ? (
        <DialogConfirm
          onCancel={storeDiagnosisCode.clearCodeForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this ICD Code?
        </DialogConfirm>
      ) : null}

      {isImportActive && (
        <DialogImport
          fetching={uploadingInProgress}
          onSubmit={handleImport}
          onClose={handleToggleImport}
        />
      )}
    </>
  );
};

export default observer(DiagnosisCodesListPage);
