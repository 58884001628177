import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import { storeClaimManagerPC } from 'stores/_mobx/billingCodding/claimManagerPC';
import { moneyRender } from 'utils/StringUtils';

interface PropsType {
  orderId: number;
  onClose: () => void;
}

const columns = [
  { name: 'cpt', title: 'CPT Codes' },
  { name: 'units', title: 'Units' },
  { name: 'icd', title: 'ICD Pointer' },
  {
    name: 'price',
    title: 'ARR PC',
    className: 'width-100',
    render: moneyRender,
  },
];

const DialogOutsideLab = ({ orderId, onClose }: PropsType) => {
  const { fetchingOutsideLabRecords, outsideLabTotalPrice, outsideLabRecords } =
    storeClaimManagerPC;

  useEffect(() => {
    storeClaimManagerPC.getOutsideLabRecords(orderId);

    return storeClaimManagerPC.clearOutsideLabRecords;
  }, [orderId]);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Outside Lab Charges" onClose={onClose} />
      <DialogBody>
        <Grid
          id="outside_lab_popup"
          stateless
          noControl
          disablePagination
          columns={columns}
          onAjax={fetchingOutsideLabRecords}
          dataSource={outsideLabRecords}
        />
        <div className="text-end fw-bold">Total: {outsideLabTotalPrice}</div>
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogOutsideLab);
