const simpleTypes = ['string', 'number', 'boolean', 'char', 'function'];

class IterableUtils {
  grep = (array: any[], callback: Function, invert: boolean = false) => {
    const compare = !invert;
    const out = [];
    for (let i = 0; i < array.length; i++) {
      if (callback(array[i]) === compare) {
        out.push(array[i]);
      }
    }
    return out;
  };

  mergeDeep = (array1: any[], array2: any[]) => {
    if (!array1) {
      array1 = [];
    }
    if (!array2) {
      return array1;
    }
    for (let i = 0; i < array2.length; i++) {
      const val = array2[i];
      if (
        val === null ||
        val === undefined ||
        simpleTypes.indexOf(typeof val) > -1
      ) {
        array1.push(val);
      } else {
        array1.push(this.extend(true, {}, val));
      }
    }
    return array1;
  };

  extend = function (...args: any[]) {
    const deep = args[0] === true;
    const index = deep ? 1 : 0;
    const target = arguments[index] || {};
    for (let i = index + 1; i < args.length; i++) {
      for (let key in args[i]) {
        if (args[i].hasOwnProperty(key)) {
          const arg = args[i][key];
          if (deep) {
            if (
              arg === null ||
              arg === undefined ||
              simpleTypes.indexOf(typeof arg) > -1
            ) {
              target[key] = arg;
            } else if (Array.isArray(arg)) {
              target[key] = iterableUtils.mergeDeep([], arg);
            } else {
              target[key] = iterableUtils.extend(
                true,
                target[key] ? target[key] : {},
                arg
              );
            }
          } else {
            target[key] = arg;
          }
        }
      }
    }
    return target;
  };
}

const iterableUtils: IterableUtils = new IterableUtils();
export default iterableUtils;
export const { extend } = iterableUtils;
