import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    interpretation: yup.string().required("Interpretation can't be empty"),
  })
);

export default validation;
