import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import { storeTransmitLog } from 'stores/_mobx/billingCodding/transmitLog';

const COLUMNS = [
  { name: 'unassigned_count', title: 'Un-assigned Count' },
  { name: 'sender_name', title: 'Sent By' },
  { name: 'sent_date', title: 'Processed Date and Time' },
  { name: 'message_type', title: 'Type' },
];

interface PropsType {
  details: { facilityName: string; facilityId: number };
  onClose: () => void;
}

const DialogTransmitLog = ({ details, onClose }: PropsType) => {
  const {
    fetching,
    logs,
    logsTotal,
    page: { pagination, setPagination },
  } = storeTransmitLog;

  const title = useMemo(
    () => `${details.facilityName || ''} Transmit Log`.trim(),
    [details.facilityName]
  );

  useEffect(() => {
    storeTransmitLog.getLogsListMain(details.facilityId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  useEffect(() => {
    return storeTransmitLog.clearLogs;
  }, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title={title} onClose={onClose} />
      <DialogBody>
        <Grid
          id="transmit_log_grid"
          stateless
          onAjax={fetching}
          columns={COLUMNS}
          pagination={pagination}
          dataSource={logs}
          dataSourceCount={logsTotal}
          onPaginationChange={setPagination}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogTransmitLog);
