import React, { KeyboardEventHandler } from 'react';
import ReactSelect, { createFilter, OnChangeValue } from 'react-select';

import Notification from 'components/modal/Notification';
import AbstractInput, {
  PAbstractInput,
  SAbstractInput,
  TAbstractInputAttr,
} from 'components/form/input/AbstractInput';
import {
  MenuList,
  Option,
  SingleValue,
} from 'components/form/select/Components';
import customStyles from 'components/form/select/styles';

const MAX_MENU_HEIGHT = 200;

export class OptionType {
  value: string | number = '';
  label: string = '';

  constructor(value: string | number, label: string) {
    this.value = value;
    this.label = label;
  }
}

type SelectValueType = number | string | boolean;

export interface SelectOptionType {
  value: SelectValueType;
  label: React.ReactNode;
}

export interface SelectAttributes {
  id?: string;
  onKeyDown?: KeyboardEventHandler;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  isMulti?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  readOnlyMode?: boolean;
  placeholder?: React.ReactNode;
  onBlurResetsInput?: any;
  onInputChange?: (arg: string) => void;
  openDirection?: number;
  className?: string;
  defaultValue?: OnChangeValue<OptionType, boolean>;
  tabIndex?: string;
  'aria-invalid'?: boolean;
}

export interface PSelect extends PAbstractInput {
  options?: Array<SelectOptionType>;
  value?: any;
  attr?: TAbstractInputAttr & SelectAttributes;
  onAjax?: boolean;
  reactForm?: any;
  isClearable?: boolean;
  accurateSearch?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
}

export class SSelect extends SAbstractInput {
  savedOption: Array<OnChangeValue<OptionType, boolean>> = [];
}

export class TSelect<
  P extends PSelect,
  S extends SSelect
> extends AbstractInput<P, S> {
  internalSelect: React.RefObject<any | null> = null;
  stateOptions: Array<OptionType> = [];

  constructor(props: P) {
    super(props);
    this.state = Object.assign(new SSelect());
  }

  render() {
    const { noLabel, attr, className } = this.props;
    const label =
      noLabel !== false
        ? this.buildLabel(attr ? attr.id : null, [this.getLabelValue()])
        : null;

    const formFieldClassName =
      'formfield-holder formfield-select ' + (className ? ' ' + className : '');
    return this.wrapInput(this.buildInput(), label, false, formFieldClassName);
  }

  getAttributes() {
    const res = { ...this.props.attr };
    if (res.disabled) {
      res.readOnlyMode = true;
    }
    return res;
  }

  getParsedValue(): number | number[] {
    const { value } = this.props;
    const parsedValue: number | number[] = Array.isArray(value)
      ? value
      : isNaN(value)
      ? value
      : Number(value);

    const isMulti = this.getIsMulti();

    if (!parsedValue) {
      return isMulti ? [] : 0;
    }
    if (Array.isArray(parsedValue) && parsedValue.length === 0) {
      return isMulti ? parsedValue : 0;
    }
    return parsedValue;
  }

  getIsMulti() {
    const attributes = this.getAttributes();
    return attributes.isMulti ? true : false;
  }

  buildInput(): React.ReactElement {
    const { props } = this;
    const { attr, accurateSearch } = props;

    const cssClass = 'react-select';
    const parsedValue: number | number[] = this.getParsedValue();
    const attributes = this.getAttributes();
    const savedOption: OnChangeValue<OptionType, boolean> =
      this.buildSavedOption(attributes, parsedValue);
    const isClearable = this.getClearable(savedOption);
    const options = this.getOptions();

    return (
      <ReactSelect
        maxMenuHeight={MAX_MENU_HEIGHT}
        menuPlacement={props.menuPlacement || 'auto'}
        {...attributes}
        isDisabled={attributes.readOnlyMode}
        ref={this.internalSelect}
        styles={customStyles}
        isClearable={isClearable}
        filterOption={createFilter({
          matchFrom: accurateSearch ? 'start' : 'any',
          ignoreAccents: false,
          trim: false,
          stringify: (option) => `${option.label}`,
        })}
        placeholder={attr && attr.placeholder ? attr.placeholder : undefined}
        onBlurResetsInput={
          attr && attr.onBlurResetsInput ? attr.onBlurResetsInput : true
        }
        onInputChange={this.onInputChange}
        scrollMenuIntoView={false}
        // @ts-ignore
        isLoading={props.onAjax || props.fetching}
        className={cssClass}
        data-testid={`${props.name}-field`}
        classNamePrefix="react-select"
        options={options}
        value={savedOption}
        // @ts-ignore
        components={{ MenuList, Option, SingleValue }}
        // @ts-ignore
        onChange={this.onChange}
      />
    );
  }

  buildSavedOption = (
    attributes: SelectAttributes,
    parsedValue: SelectValueType | Array<SelectValueType>
  ): OnChangeValue<OptionType, boolean> => {
    let res = attributes.disabled ? null : this.buildOption(parsedValue);
    if (!res && attributes.readOnlyMode) {
      res = this.buildOption(parsedValue);
    }
    return res;
  };

  buildOption = (
    parsedValue: SelectValueType | Array<SelectValueType>
  ): OnChangeValue<OptionType, boolean> => {
    let stateOption = this.getSavedOption();
    const options: Array<OptionType> = this.getOptions();
    const isMulti = this.getIsMulti();
    if (!isMulti && !Array.isArray(parsedValue)) {
      stateOption = this.findOption(parsedValue, options);
    } else if (isMulti && Array.isArray(parsedValue)) {
      stateOption = this.checkMultiSavedOption(
        parsedValue,
        options,
        stateOption
      );
    } else {
      Notification.danger('Unexpected state of select control.');
    }
    return stateOption;
  };

  getSavedOption() {
    const { savedOption } = this.state;
    return savedOption.length > 0 ? savedOption[0] : null;
  }

  setSavedOption(value: OnChangeValue<OptionType, boolean>) {
    this.setState({ savedOption: [value] });
  }

  findOption(value: SelectValueType, options: Array<OptionType>): OptionType {
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      // eslint-disable-next-line
      if (option.value == value) {
        return option;
      }
    }
    return null;
  }

  onChange = (value: OnChangeValue<OptionType, boolean>) => {
    this.setSavedOption(value);
    const isMulti = this.getIsMulti();
    if (value) {
      this.setValue(
        isMulti
          ? (value as any).map((obj: OptionType) => obj.value)
          : (value as OptionType).value
      );
    } else {
      this.setValue(isMulti ? [] : null);
    }
  };

  checkMultiSavedOption(
    value: Array<SelectValueType>,
    options: Array<OptionType>,
    savedOptions: OnChangeValue<OptionType, boolean>
  ) {
    const savedOptionsArr = savedOptions as [];
    let valid = false;
    if (savedOptions && savedOptionsArr.length === value.length) {
      valid = true;
      for (let i = 0; i < value.length; i++) {
        const val = value[i];
        let optionExists = false;
        for (let j = 0; j < options.length; j++) {
          const opt = options[j];
          // eslint-disable-next-line
          if (opt.value == val) {
            optionExists = true;
            break;
          }
        }
        if (!optionExists) {
          valid = false;
          break;
        }
      }
    }
    const res: Array<any> = [];
    if (valid) {
      for (let i = 0; savedOptionsArr && i < savedOptionsArr.length; i++) {
        res.push(savedOptionsArr[i]);
      }
    } else {
      for (let i = 0; i < value.length; i++) {
        const val = value[i];
        for (let j = 0; j < options.length; j++) {
          const opt = options[j];
          if (opt.value === val) {
            res.push(opt);
            break;
          }
        }
      }
    }
    return res;
  }

  onInputChange = (newValue: string) => {
    const { attr } = this.props;
    if (attr && typeof attr.onInputChange === 'function') {
      attr.onInputChange(newValue);
    }
  };

  renewOptionsPropsToState(
    propsOptions: Array<SelectOptionType>,
    stateOptions: Array<OptionType>
  ): Array<OptionType> {
    stateOptions.length = 0;
    propsOptions.forEach((obj: SelectOptionType) => {
      // @ts-ignore
      stateOptions.push(new OptionType(obj.value, '' + obj.label));
    });
    return stateOptions;
  }

  convertOptionsPropsToState(
    propsOptions: Array<SelectOptionType>,
    stateOptions: Array<OptionType>
  ): Array<OptionType> {
    let i = 0;
    propsOptions.forEach((obj: SelectOptionType) => {
      const optionType: OptionType = stateOptions[i];
      if (optionType.value !== obj.value) {
        // @ts-ignore
        optionType.value = obj.value;
      }
      if (optionType.label !== obj.label) {
        optionType.label = `${obj.label}`;
      }
      i++;
    });
    return stateOptions;
  }

  getOptions() {
    if (this.stateOptions.length !== this.props.options.length) {
      this.stateOptions = this.renewOptionsPropsToState(this.props.options, []);
    } else {
      this.convertOptionsPropsToState(this.props.options, this.stateOptions);
    }
    return this.stateOptions;
  }

  getIndicatorsWidth(
    value: OnChangeValue<OptionType, boolean>,
    isClearable: boolean
  ): number {
    const isMulti = this.getIsMulti();
    const onAjax = this.props.onAjax ? 30 : 0;
    if (!isClearable) {
      return 22 + onAjax;
    }
    let flagLarge: boolean;
    if (isMulti) {
      flagLarge = !!value && !!(value as []).length;
    } else {
      flagLarge = value && (value as OptionType).value !== '0';
    }
    const res = (flagLarge ? 42 : 22) + onAjax;
    return res;
  }

  getClearable(value: OnChangeValue<OptionType, boolean>): boolean {
    const attributes = this.getAttributes();
    if (attributes.readOnlyMode) {
      return false;
    }
    const isMulti = this.getIsMulti();
    const flagNotLarge =
      !isMulti && value && (value as OptionType).value === '0';
    const { props } = this;
    const res =
      attributes.isClearable !== undefined
        ? attributes.isClearable
        : props.isClearable !== undefined
        ? props.isClearable
        : !flagNotLarge;
    return res;
  }
}

export default class Select extends TSelect<PSelect, SSelect> {}
