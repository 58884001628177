import { ReduceStore } from 'flux/utils';
import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import { TStationDetails } from 'services/system-setup/master-setup/facility/FacilityPageService';
import { emailsUrlPrettier } from 'utils/emailValuesFormatter';

export class DiscountService {
  service: string = '';
  percentage: number = 0;
  serviceId: number = 0;
}

export class FacilityPageDiscount {
  '1_X-Ray': DiscountService = new DiscountService();
  '2_Ultrasound': DiscountService = new DiscountService();
  '3_EKG': DiscountService = new DiscountService();
  '5_Transportation single pt': DiscountService = new DiscountService();
  '6_Transportation multi pts': DiscountService = new DiscountService();
  '7_Set Up': DiscountService = new DiscountService();
  '8_Employee Screening': DiscountService = new DiscountService();
  '9_Echocardiogram': DiscountService = new DiscountService();
  '10_Bone Density': DiscountService = new DiscountService();
  '11_CT': DiscountService = new DiscountService();
  '12_Athletic Services': DiscountService = new DiscountService();
  '13_H.I.R. & Interest Payments': DiscountService = new DiscountService();
}

const buildActionName = (name: string) => {
  return 'system-setup/master-setup/facilities/' + name;
};

export const Actions = {
  LOAD_FACILITY_ITEM_ACTION: buildActionName('load-facility-item-action'),
  EDIT_FACILITY_ITEM_ACTION: buildActionName('edit-facility-item-action'),
  SAVE_FACILITY_ITEM_ACTION: buildActionName('save-facility-item-action'),
  LOAD_LOCATION_LIST: buildActionName('load-location-list-action'),

  LOAD_BILLING_ITEM_ACTION: buildActionName('load-billing-item-action'),
  LOAD_SERVICE_LIST_ACTION: buildActionName('load-service-list-action'),

  LOAD_CONTACT_LIST_ACTION: buildActionName('load-contact-list-action'),
  LOAD_CONTACT_ITEM_ACTION: buildActionName('load-contact-item-action'),

  LOAD_DOCUMENT_LIST_ACTION: buildActionName('load-document-list-action'),

  CLEAR_ALL_TABS_ACTION: buildActionName('clear-all-tabs-action'),
  CLEAR_FACILITY_TAB: buildActionName('clear-facility-tab-action'),
  CLEAR_BILLING_TAB: buildActionName('clear-billing-tab-action'),
  CLEAR_CONTACT_TAB: buildActionName('clear-contact-tab-action'),
  CLEAR_DOCUMENT_TAB: buildActionName('clear-document-tab-action'),
  CLEAR_CONTACT_ITEM_MODAL_ACTION: buildActionName(
    'clear-contact-item-modal-action'
  ),

  LOAD_FEE_COUNT: buildActionName('load-fee-count-action'),
  LOAD_FEE_LIST: buildActionName('load-fee-list-action'),

  UPDATE_TABS_STATE: buildActionName('update-tabs-state'),
  UPDATE_CURRENT_TAB: buildActionName('update-current-tab'),
  SET_BACK_TO_LIST: buildActionName('back-to-list'),
};

export const defaultStationDetails: TStationDetails = {
  Name: '',
  Fax: '',
  Phone: '',
  extension: '',
  Email: '',
  Comment: '',
};

class FacilityPageState {
  currentTab: number = 0;
  blocked: { 0: boolean; 1: boolean; 2: boolean; 3: boolean } = {
    0: false,
    1: false,
    2: false,
    3: false,
  };
  backToList: boolean = false;
  locationList: Array<any> = [];
  serviceList: Array<any> = [];
  billingItem: any = {};
  billingItemLoaded: boolean = true;
  contactData: any = {
    contactList: [],
  };
  contactItem: any = {};
  contactItemLoaded: boolean = false;
  selectedContacts: Array<any> = [];
  documentList: Array<any> = [];
  facilityItem: any;
}

class FacilityPageStore extends ReduceStore<FacilityPageState, CommonPayload> {
  getInitialState(): FacilityPageState {
    const facilityPageState = new FacilityPageState();
    facilityPageState.facilityItem = this.getEmptyFacilityItem();
    return facilityPageState;
  }

  getEmptyFacilityItem() {
    return {
      location: {},
      facilityData: {
        eligibility_status: 'Y',
        facility_stat: 'A',
        integration_active: false,
        integration_id: [] as number[],
        division_id: 0,
      },
      OrderType: { 0: false, 1: false, 2: false, 3: false, 4: false },
      stationDetailsList: [defaultStationDetails],
    };
  }

  areEqual(state1: FacilityPageState, state2: FacilityPageState) {
    return false;
  }

  getFilteredContactData(list: any, filter: any) {
    const firstnameFilter = filter['firstName']?.toLowerCase();
    const lastnameFilter = filter['lastName']?.toLowerCase();
    const contactTitleFilter = filter['contactTitle']?.toLowerCase();
    if (
      firstnameFilter === '' &&
      lastnameFilter === '' &&
      contactTitleFilter === ''
    ) {
      return list;
    }
    return list.filter(
      (item: any) =>
        item['first_name'] !== null &&
        item['first_name'].toLowerCase().startsWith(firstnameFilter) &&
        item['last_name'] !== null &&
        item['last_name'].toLowerCase().startsWith(lastnameFilter) &&
        item['contact_title'] !== null &&
        item['contact_title'].toLowerCase().startsWith(contactTitleFilter)
    );
  }

  reduce(state: FacilityPageState, action: CommonPayload) {
    switch (action.type) {
      case Actions.LOAD_FACILITY_ITEM_ACTION: {
        const {
          floor,
          OrderType,
          Region: [location],
          facility: [{ integration_id, ...rest }],
        } = action.data;
        const integrations =
          integration_id?.filter((id: string) => Boolean(Number(id))) || [];
        const stationDetailsList = floor.length
          ? floor
          : [defaultStationDetails];
        const facilityData = {
          ...rest,
          integration_active: Boolean(integrations?.length),
          integration_id: integrations.map((id: string) => Number(id)),
        };
        const facilityItem = {
          facilityData,
          location: {
            ...location,
            refid: Number(location.refid) || 0,
            region_id: Number(location.region_id) || 0,
            state_id: Number(location.state_id) || 0,
          },
          stationDetailsList,
          OrderType,
        };
        this.getState().facilityItem = facilityItem;
        break;
      }
      case Actions.LOAD_LOCATION_LIST:
        this.getState().locationList = action.data;
        break;

      case Actions.LOAD_BILLING_ITEM_ACTION:
        this.getState().billingItem = {
          ...action.data,
          bill_email: emailsUrlPrettier(action.data.bill_email),
        };
        this.getState().billingItemLoaded = true;
        break;
      case Actions.LOAD_SERVICE_LIST_ACTION:
        this.getState().serviceList = action.data;
        break;

      case Actions.LOAD_CONTACT_LIST_ACTION:
        this.getState().contactData.contactList = action.data.corpowner;
        this.getState().contactData.comments = action.data.comments;
        this.getState().selectedContacts = action.data.selected;
        break;
      case Actions.LOAD_CONTACT_ITEM_ACTION:
        this.getState().contactItem = action.data;
        this.getState().contactItemLoaded = true;
        break;

      case Actions.LOAD_DOCUMENT_LIST_ACTION:
        this.getState().documentList = action.data;
        break;
      case 'reload-facility-on-fee-schedule':
        this.getState().billingItemLoaded = false;
        break;
      case Actions.CLEAR_FACILITY_TAB:
        this.getState().facilityItem = this.getEmptyFacilityItem();
        break;
      case Actions.CLEAR_BILLING_TAB:
        this.getState().billingItem = {};
        this.getState().billingItemLoaded = false;
        break;
      case Actions.CLEAR_CONTACT_TAB:
        this.getState().contactItem = {};
        this.getState().contactItemLoaded = false;
        break;
      case Actions.CLEAR_DOCUMENT_TAB:
        this.getState().contactData = { contactList: [] };
        this.getState().selectedContacts = [];
        break;
      case Actions.CLEAR_CONTACT_ITEM_MODAL_ACTION:
        this.getState().contactItem = {};
        this.getState().contactItemLoaded = false;
        break;
      case Actions.CLEAR_ALL_TABS_ACTION:
        this.getState().facilityItem = this.getEmptyFacilityItem();
        this.getState().billingItem = {};
        this.getState().contactItem = {};
        this.getState().contactItemLoaded = false;
        this.getState().contactData = { contactList: [] };
        this.getState().selectedContacts = [];
        this.getState().currentTab = 0;
        this.getState().blocked = {
          0: false,
          1: false,
          2: false,
          3: false,
        };
        break;
      case Actions.UPDATE_TABS_STATE:
        this.getState().currentTab = action.data.currentTab;
        this.getState().blocked = action.data.blocked;
        break;
      case Actions.UPDATE_CURRENT_TAB:
        this.getState().currentTab = action.data.currentTab;
        break;
      case Actions.SET_BACK_TO_LIST:
        this.getState().backToList = action.data.backToList;
        break;
      default:
        break;
    }
    return state;
  }
}

const facilityPageStore = new FacilityPageStore(appDispatcher);
export default facilityPageStore;
