import { ReduceStore } from 'flux/utils';
import appDispatcher from 'dispatcher/AppDispatcher';

let buildActionName = function (name) {
  return 'reports/report-creator/facility-exam-count/' + name;
};

export let Actions = {
  LOAD_FACILITY_EXAM_COUNT_LIST: buildActionName(
    'load-facility-exam-count-list'
  ),
};

class FacilityExamCountPageStore extends ReduceStore {
  getEmptyDiagram() {
    return [['Data', 'Value']];
  }

  getInitialState() {
    return {
      facilityExamCountList: [],
      facilityExamCountDiagram: this.getEmptyDiagram(),
    };
  }

  reduce(state, action) {
    switch (action.type) {
      case Actions.LOAD_FACILITY_EXAM_COUNT_LIST:
        this.getState().facilityExamCountList = action.data.contval || [];
        this.getState().facilityExamCountDiagram =
          action.data.chartval || this.getEmptyDiagram();
        break;
      default:
        break;
    }
    return this.getState();
  }

  areEqual(one, two) {
    return false;
  }
}

const facilityExamCountPageStore = new FacilityExamCountPageStore(
  appDispatcher
);
export default facilityExamCountPageStore;
