import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Container } from 'flux/utils';

import ApplicationUsersAdd from 'page/privileges/ApplicationUsersAdd';
import { ControlsLayout } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import Notification from 'components/modal/Notification';
import { Button } from 'components/button';
import { SpinnerFixed } from 'components/spinner';
import DialogCorporationUsers from './DialogCorporationUsers';

import CorporationOverviewPageActions from 'actions/system-setup/master-setup/corporations/CorporationOverviewPageActions';
import CorporationOverviewPageStore from 'stores/system-setup/corporations/CorporationOverviewPageStore';
import { CorporateAminUserType } from 'services/system-setup/master-setup/corporations/CorporationOverviewPageService';

interface MatchParams {
  id?: string;
}
export interface PUsersTab extends RouteComponentProps<MatchParams> {
  corporationId: number;
}

export class TUsersTabFilter {
  firstName: string = '';
  lastName: string = '';
}

export class SUsersTab {
  selected: number[] = [];
  filter: TUsersTabFilter = new TUsersTabFilter();
  addUserPage: boolean = false;
  showSelectionModal: boolean = false;
  selectedUsers: CorporateAminUserType[] = [];
  selectedUsersIds: number[] = [];
  userList: CorporateAminUserType[] = [];
  userListLoaded: boolean = false;
  selectedUsersLoaded: boolean = false;
}

class UsersTab extends React.Component<PUsersTab, SUsersTab> {
  grid: Grid = null;

  static getStores() {
    return [CorporationOverviewPageStore];
  }

  static calculateState(prevState: SUsersTab) {
    if (!prevState) {
      prevState = new SUsersTab();
    }
    const state = CorporationOverviewPageStore.getState();
    return {
      ...prevState,
      userList: state.userList,
      selectedUsersIds: state.selectedUsersIds,
      selectedUsers: state.selectedUsers,
    };
  }

  componentDidMount() {
    this.updateData();
  }

  componentWillUnmount() {
    this.setState({ selected: [] });
  }

  getColumns() {
    return [
      { name: 'first_name', title: 'First name' },
      { name: 'last_name', title: 'Last name' },
      { name: 'usertype_name', title: 'User Type' },
    ];
  }

  filteredUserList(userList: Array<any>, filter: any) {
    if (!userList || !filter) {
      return;
    }
    return userList.filter((item) => {
      return (
        (item.first_name
          .toLowerCase()
          .startsWith(filter.firstName.toLowerCase()) ||
          filter.firstName === '') &&
        (item.last_name
          .toLowerCase()
          .startsWith(filter.lastName.toLowerCase()) ||
          filter.lastName === '')
      );
    });
  }

  render() {
    const { match, history, location, corporationId } = this.props;
    const { state } = this;
    return (
      <>
        {state.addUserPage ? (
          <ApplicationUsersAdd
            history={history}
            location={location}
            match={match}
            callback={(response, data: any) =>
              this.setState({ addUserPage: false }, () => {
                this.updateData();
                if (response && data.refid) {
                  CorporationOverviewPageActions.saveSelectedUsers(
                    this.props.corporationId,
                    [data.refid]
                  ).then(() => this.updateData());
                } else {
                  this.updateData();
                }
              })
            }
            preselectedType="CA"
            onlyCorporate
          />
        ) : (
          <>
            {state.showSelectionModal ? (
              <DialogCorporationUsers
                corporationId={corporationId}
                userList={state.userList}
                selectedUsersIds={state.selectedUsersIds}
                onClose={this.handleCloseDialogUsers}
                onSubmit={this.handleSubmitSelectedUsers}
              />
            ) : (
              <Grid
                id="user_tab_grid"
                ref="grid"
                columns={this.getColumns()}
                dataSource={this.state.selectedUsers}
                disablePagination
                selectId="refid"
                selectedIds={this.state.selectedUsersIds}
                onSelectChange={this.onSelectChange}
                gridControlPanel={
                  <>
                    <GridControlButton
                      title="Add User"
                      onClick={() => {
                        this.setState({ addUserPage: true });
                      }}
                    />
                    <GridControlButton
                      title="Select User"
                      onClick={() => {
                        this.setState({ showSelectionModal: true });
                      }}
                    />
                  </>
                }
              />
            )}
            <ControlsLayout alignX="right" className="mb-3">
              <Button type="submit" onClick={this.submit.bind(this)}>
                Save
              </Button>
            </ControlsLayout>
          </>
        )}

        {!this.state.userListLoaded && !this.state.selectedUsersLoaded && <SpinnerFixed />}
      </>
    );
  }

  handleCloseDialogUsers = () => {
    this.setState({ showSelectionModal: false });
  };

  handleSubmitSelectedUsers = (payload: number[]) => {
    CorporationOverviewPageActions.saveSelectedUsers(
      this.props.corporationId,
      payload
    ).then((response) => {
      if (response === 'S') {
        Notification.success('Successfully changed!');
        this.handleCloseDialogUsers();
        this.updateData();
      } else {
        Notification.danger('An error occurred!');
      }
    });
  };

  onSelectChange = (selectedUsersIds: number[]) => {
    this.setState({ selectedUsersIds });
  };

  updateData = () => {
    let corpId = this.props.corporationId;

    CorporationOverviewPageActions.loadUserList().then(() =>
      this.setState({ userListLoaded: true }));

    if (corpId) {
      CorporationOverviewPageActions.loadSelectedUsers(corpId).then(
        () => this.setState({ selectedUsersLoaded: true }));
    }
  };

  submit() {
    CorporationOverviewPageActions.saveSelectedUsers(
      this.props.corporationId,
      this.state.selectedUsersIds
    ).then((response) => {
      if (response === 'S') {
        Notification.success('Successfully changed!');
        this.props.history.push({ search: '?subtub=billing' });
      } else {
        Notification.danger('An error occurred!');
      }
      this.updateData();
    });
  }
}

const usersTab = Container.create(UsersTab);
export default withRouter(usersTab);
