import React from 'react';

import DialogColumnsSwitcher from 'components/grid/DialogColumnsSwitcher';
import ProjectGrid from 'components/grid/ProjectGrid';
import DatasourceSorter from 'components/grid/DatasourceSorter';
import Scroll from 'components/grid/Scroll';
import DialogConfirm from 'components/modal/dialogConfirm';
import SelectFacade from 'components/grid/columns/SelectFacade';
import SimpleFacade from 'components/grid/columns/SimpleFacade';
import ScrollFacade from 'components/grid/columns/ScrollFacade';
import Pagination from 'components/grid/Pagination';
import { TPagination } from 'components/grid/GridTypes';
import { IconButton } from 'components/button';
import { SpinnerFixed } from 'components/spinner';
import { PureCheckbox } from 'components/form/checkbox';

import { storeGrid, GridSettingType } from 'stores/_mobx/grid';
import { getPagination } from 'utils/getPagination';

export interface PGrid {
  id?: string;
  shouldRowUpdate?: boolean;
  onSelectChange?: (arr: Array<any>) => any;
  selectId?: any;
  selectedIds?: Array<any>;
  disablePagination?: boolean;
  onPaginationChange?: (pagination: TPagination) => void;
  groupBy?: any;
  dataSource: Array<any>;
  dataSourceCount?: number;
  selectAllLabel?: string;
  columns: Array<any>;
  noCheckBoxes?: boolean;
  pagination?: TPagination;
  stateless?: boolean;
  hideSwitcher?: boolean;
  onMoveToTrash?: (event: React.MouseEvent) => void;
  moveToTrashText?: string;
  noControl?: boolean;
  shownColumns?: Map<string, boolean>;
  minColumns?: number;
  minColWidth?: number;
  headerGroups?: boolean;
  noDataText?: React.ReactNode;
  noDrag?: Array<any>;
  rowArguments?: ((val: any, idx: number) => any) | Array<any>;
  wrapperClass?: string;
  onAjax?: boolean;
  disabled?: boolean;
  gridControlPanel?: React.ReactNode;
  gridControlPanelTop?: React.ReactNode;
  gridControlPanelBottom?: React.ReactNode;
  noclone?: string;
  onDoubleClick?: (data: any, index: number) => void;
  onClick?: (data: any, index: number) => void;
}

export class SGrid {
  selectionUpdate: boolean = false;
  shownColumns: Map<string, boolean> = new Map<string, boolean>();
  isAllSelected: boolean = false;
  columnsOffset: number = 0;
  shiftY: number = 0;
  isAllChanged: boolean = false;
  checkboxesInit: boolean = false;
  selected: Array<any> = [];
  sort: { column: string; direction: number } = null;
  columnsOrder: string[] = null;
  showSwitchers: boolean = false;
  showResetGridConfirmation: boolean = false;
  showSaveGridConfirmation: boolean = false;
}

export default class Grid extends React.Component<PGrid, SGrid> {
  selectionUpdatePostponed: boolean = false;
  scrollFacade: ScrollFacade = null;
  scrollableContainer: any = null;
  scroll: Scroll = null;
  container: HTMLDivElement = null;
  scrollListener: () => void;

  constructor(props: PGrid) {
    super(props);
    if (typeof props.onSelectChange === 'function' && !props.selectId) {
      throw new Error(
        'onSelectChange function was specified, but selectId not.'
      );
    }
    if (
      props.disablePagination !== true &&
      typeof props.onPaginationChange !== 'function'
    ) {
      throw new Error("Please specify 'onPaginationChange' function.");
    }
    const settings = storeGrid.getSetting<GridSettingType>(props.id);

    const shownColumns: Map<string, boolean> =
      settings?.grid_columns || new Map();

    const columnsFromProps: Map<string, boolean> =
      props.shownColumns instanceof Map
        ? props.shownColumns
        : new Map(Object.entries(props.shownColumns || {}));

    this.selectionUpdatePostponed = false;
    this.state = {
      selectionUpdate: false,
      shownColumns: shownColumns.size ? shownColumns : columnsFromProps,
      isAllSelected: false,
      columnsOffset: 0,
      shiftY: 0,
      isAllChanged: false,
      checkboxesInit: false,
      selected: [],
      sort: settings?.column_sort_order || null,
      columnsOrder: settings?.column_drag_order || null,
      showSwitchers: false,
      showResetGridConfirmation: false,
      showSaveGridConfirmation: false,
    };
    this.scrollableContainer = null;
    this.scrollFacade = null;
  }

  static getDerivedStateFromProps(props: PGrid, state: SGrid) {
    if (props.selectedIds && Array.isArray(props.selectedIds)) {
      const isLengthSame = props.selectedIds.length === state.selected.length;
      const isDifferent = isLengthSame
        ? props.selectedIds.some((id) => !state.selected.includes(id))
        : true;
      return isLengthSame && !isDifferent
        ? null
        : {
            ...state,
            selected: props.selectedIds,
            isAllSelected: props.dataSource.length
              ? props.selectedIds.length === props.dataSource.length
              : false,
          };
    } else if (props.onSelectChange) {
      return {
        ...state,
        selected: props.dataSource?.length ? state.selected : [],
        isAllSelected: props.dataSource?.length
          ? state.selected.length === props.dataSource.length
          : false,
      };
    }
    return null;
  }

  buildPagination() {
    const { dataSourceCount } = this.props;
    return (
      <Pagination
        pagination={this.props.pagination}
        totalCount={dataSourceCount}
        fetching={this.props.onAjax}
        onChangePageSettings={this.handleChangePagination}
      />
    );
  }

  initCheckboxes() {
    const { props, state } = this;
    if (props.selectedIds && !state.checkboxesInit && !state.isAllChanged) {
      this.setState({ selected: props.selectedIds });
      if (state.selected.length > 0) {
        this.setState({ checkboxesInit: true });
      }
    }
    this.setState({ isAllChanged: false });
  }

  getTopPanels() {
    const { props } = this;
    return (
      <div className="row align-items-center controls-top">
        <div className="col-sm d-flex align-items-center gap-3">
          {this.getControlPanel('gridControlPanel')}
          {this.getControlPanel('gridControlPanelTop')}
        </div>
        <div className="col-sm-auto">{this.getDefaultControl('top')}</div>
        <div className="col-sm-auto">
          {!props.disablePagination ? this.buildPagination() : null}
        </div>
      </div>
    );
  }

  getBottomPanels() {
    const { props } = this;
    const isPanelExist =
      this.getControlPanel('gridControlPanelBottom')?.props?.children !== null;

    return isPanelExist ? (
      <div className="row align-items-center controls-bottom">
        <div className="col-sm d-flex align-items-center gap-3">
          {this.getControlPanel('gridControlPanel') ||
            this.getControlPanel('gridControlPanelBottom')}
        </div>
        <div className="col-sm-auto">{this.getDefaultControl('bottom')}</div>
        <div className="col-sm-auto">
          {props.disablePagination ? null : this.buildPagination()}
        </div>
      </div>
    ) : null;
  }

  componentDidMount() {
    this.scrollListener = () => {
      const columns = this.getColumns();
      const facade = new ScrollFacade(columns, this);
      if (
        this.scrollFacade !== null &&
        (facade.requireSlider !== this.scrollFacade.requireSlider ||
          facade.getColumns().length !== this.scrollFacade.getColumns().length)
      ) {
        this.forceUpdate();
      } else {
        this.moveScroll();
      }
    };
    this.scrollableContainer = this.findScrollContainer();
    this.scrollableContainer.addEventListener('scroll', this.scrollListener);
    if (this.scroll) {
      this.scroll.setContainer(this.scrollableContainer);
      this.scroll.setGrid(this.refs.project_grid);
    }
    window.addEventListener('resize', this.scrollListener);
    this.moveScroll();
    this.initCheckboxes();
  }

  findScrollContainer() {
    let container = this.container.parentNode;
    while (container && container !== document) {
      if (
        (container as any).classList &&
        (container as any).classList.contains('modal-window')
      ) {
        return container;
      }
      container = container.parentNode;
    }
    return document;
  }

  handleCloseSaveGrid = () => {
    this.setState({ showSaveGridConfirmation: false });
  };

  handleCloseResetGrid = () => {
    this.setState({ showResetGridConfirmation: false });
  };

  componentWillUnmount() {
    this.scrollableContainer.removeEventListener('scroll', this.scrollListener);
    DatasourceSorter.clearCache(this);
    window.removeEventListener('resize', this.scrollListener);
  }

  componentDidUpdate() {
    if (this.scroll) {
      this.scroll.setContainer(this.scrollableContainer);
      this.scroll.setGrid(this.refs.project_grid);
    }
    this.scrollFacade.afterUpdate();
    this.moveScroll();
  }

  moveScroll() {
    const me = this;
    if (this.scroll) {
      const projectGrid = me.refs.project_grid;
      if (!projectGrid) {
        return; // case when projectGrid was unmounted
      }
      this.scroll.moveScroll();
    }
  }

  render() {
    const { props, state } = this;
    const wrapperClass = `grid-wrapper ${props.wrapperClass || ''}`;
    const columns = this.getColumns();
    const facade = new ScrollFacade(columns, this, () => this.forceUpdate());
    this.scrollFacade = facade;

    return (
      <div
        className={wrapperClass}
        id={props.id}
        ref={(ref) => (this.container = ref)}>
        {!props.noControl ? this.getTopPanels() : null}
        <div
          className={`position-relative ${
            props.disabled ? ' disabled-grid' : ''
          }`}>
          {props.onAjax && <SpinnerFixed />}
          {facade.requireSlider ? (
            <Scroll
              width={facade.getColumns().length / columns.length}
              ref={(ref) => (this.scroll = ref)}
              onChange={(offset) => {
                this.updateOffset(offset);
              }}
            />
          ) : null}
          <ProjectGrid
            id={props.id}
            shouldRowUpdate={props.shouldRowUpdate}
            noDataText={props.noDataText}
            ref="project_grid"
            sort={state.sort}
            isLoading={this.props.onAjax}
            groupBy={props.groupBy}
            columns={facade.getColumns()}
            selectedIds={props.selectedIds}
            headerGroups={props.headerGroups}
            rowArguments={props.rowArguments}
            dataSourceCount={props.dataSourceCount}
            disablePagination={props.disablePagination}
            dragColumns={this.dragColumns}
            paginationToolbarProps={Grid.getToolbarProps()}
            onDoubleClick={props.onDoubleClick}
            onClick={props.onClick}
            onChangeSort={(name, direction) =>
              this.onChangeSort(name, direction)
            }
            noDrag={
              props.onSelectChange ? (props.noDrag ? props.noDrag : [0]) : []
            }
            dataSource={
              state.sort === null
                ? props.dataSource
                : this.getSortedDataSource()
            }
          />
        </div>

        {!props.noControl ? this.getBottomPanels() : null}

        {state.showSwitchers && (
          <DialogColumnsSwitcher
            title="Column Visibility Options"
            columns={props.columns}
            shownColumns={state.shownColumns}
            onClose={() => this.setState({ showSwitchers: false })}
            onShownColumnsChange={this.onShownColumnsChange}
          />
        )}
        {state.showSaveGridConfirmation && (
          <DialogConfirm
            onApprove={this.handleApproveSaveGrid}
            onCancel={this.handleCloseSaveGrid}>
            Are you sure you want to save grid state?
          </DialogConfirm>
        )}
        {state.showResetGridConfirmation && (
          <DialogConfirm
            onCancel={this.handleCloseResetGrid}
            onApprove={this.handleApproveResetGridData}>
            Are you sure you want to reset grid state?
          </DialogConfirm>
        )}
      </div>
    );
  }

  updateOffset(offset: number) {
    if (this.state.columnsOffset !== offset) {
      this.setState({ columnsOffset: offset });
    }
  }

  dragColumns = (target: number, after: number) => {
    if (this.props.onSelectChange) {
      target--;
      after--;
    }
    if (after !== target) {
      const { columnsOrder } = this.state;

      const columnsFromProps = this.props.columns.map(({ name }) => name);

      const restColumns = columnsOrder
        ? columnsFromProps.filter((name) => !columnsOrder.includes(name))
        : [];

      const columnsForDnD = columnsOrder
        ? columnsOrder
            .filter((name) => columnsFromProps.includes(name))
            .concat(restColumns)
        : columnsFromProps;

      const scrollFacade = new ScrollFacade(this.getColumns(), this);
      let minColumns = scrollFacade.minColumns;
      if (this.props.onSelectChange) {
        minColumns--;
      }
      const delta = scrollFacade.startColumns - scrollFacade.minColumns;
      if (after >= minColumns && delta > 0) {
        after += delta;
      }
      if (target >= minColumns && delta > 0) {
        target += delta;
      }
      for (let i = 0, limit = columnsForDnD.length; i < limit; i++) {
        let colName = columnsForDnD[i];
        if (this.state.shownColumns.get(colName) === false) {
          if (after >= i) {
            after++;
          }
          if (target >= i) {
            target++;
          }
        }
      }
      if (after > target) {
        columnsForDnD.splice(after + 1, 0, columnsForDnD[target]);
        columnsForDnD.splice(target, 1);
      } else {
        columnsForDnD.splice(after, 0, columnsForDnD[target]);
        columnsForDnD.splice(target + 1, 1);
      }
      this.setState({ columnsOrder: columnsForDnD });
    }
  };

  onChangeSort(name: string, direction: number) {
    if (direction === 0) {
      this.setState({ sort: null });
    } else {
      this.setState({ sort: { column: name, direction: direction } });
    }
  }

  getSortedDataSource() {
    return DatasourceSorter.getSorterDataSource(
      this.props.dataSource,
      this.state.sort,
      this.props.columns,
      this
    );
  }

  getControlPanel(
    key: 'gridControlPanel' | 'gridControlPanelTop' | 'gridControlPanelBottom'
  ) {
    return key in this.props ? <>{this.props[key]}</> : null;
  }

  static calculateSkip(page: number, pageSize: number) {
    return (page - 1) * pageSize;
  }

  handleChangePagination = (pageSettings: TPagination) => {
    this.selectAll(false);

    if (this.props.onPaginationChange) {
      this.props.onPaginationChange(pageSettings);
    }
  };

  onRefresh(event: React.MouseEvent) {
    this.selectAll(false);
    if (event) {
      event.preventDefault();
    }
    if (this.props.onPaginationChange && !this.props.onAjax)
      this.props.onPaginationChange({ ...this.props.pagination });
  }

  onShownColumnsChange = (
    shownColumns: Map<string, boolean>,
    callback?: () => void
  ) => {
    const shownColumnsMap = new Map<string, boolean>(
      shownColumns instanceof Map ? shownColumns : Object.entries(shownColumns)
    );
    this.setState({ shownColumns: shownColumnsMap }, callback);
  };

  static getToolbarProps(): {
    showSeparators: boolean;
    iconSize: number;
    iconProps: {
      overStyle: {
        fill: string;
      };
    };
  } {
    return {
      showSeparators: false,
      iconSize: 15,
      iconProps: {
        overStyle: { fill: '#157C8E' },
      },
    };
  }

  handleClickRefresh = (event: React.MouseEvent) => {
    event.preventDefault();
    this.onRefresh(event);
  };

  handleClickShowColumns = (event: React.MouseEvent) => {
    event.preventDefault();
    this.setState({ showSwitchers: true });
  };

  handleClickGridSave = (event: React.MouseEvent) => {
    event.preventDefault();
    this.setState({ showSaveGridConfirmation: true });
  };

  handleClickResetGrid = (event: React.MouseEvent) => {
    event.preventDefault();
    this.setState({ showResetGridConfirmation: true });
  };

  getDefaultControl(place?: 'top' | 'bottom') {
    const { props } = this;
    if (props.noControl) {
      return null;
    }

    const controls = [];
    if (props.onMoveToTrash) {
      controls.push(
        <a href="/" onClick={props.onMoveToTrash} key="1">
          {props.moveToTrashText ? props.moveToTrashText : 'Delete selected'}
        </a>
      );
    }
    if (props.onPaginationChange) {
      controls.push(
        <IconButton
          onClick={this.handleClickRefresh}
          disabled={this.props.onAjax}
          key="2"
          title="Refresh">
          <i className="bi bi-arrow-repeat" />
        </IconButton>
      );
    }
    if (!props.hideSwitcher) {
      controls.push(
        <IconButton
          onClick={this.handleClickShowColumns}
          key="3"
          title="Set shown columns">
          <i className="bi bi-gear" />
        </IconButton>
      );
    }
    if (props.id && !props.stateless) {
      controls.push(
        <IconButton
          onClick={this.handleClickGridSave}
          key="4"
          title="Save grid state">
          <i className="bi bi-download" />
        </IconButton>
      );
      controls.push(
        <IconButton
          onClick={this.handleClickResetGrid}
          key="5"
          title="Reset grid state">
          <i className="bi bi-eraser" />
        </IconButton>
      );
    }

    return (
      <div className="subcontrols" style={{ textAlign: 'right' }}>
        {controls}
      </div>
    );
  }

  handleApproveResetGridData = () => {
    if (this.props.id)
      storeGrid.deleteGridSetting(this.props.id).then(() => {
        this.handleCloseResetGrid();
        this.props.onPaginationChange(getPagination());
      });
  };

  handleApproveSaveGrid = () => {
    const { state, props } = this;

    storeGrid
      .saveGridSettings({
        gridId: props.id,
        size: props.pagination?.pageSize || 10,
        shownColumns: state.shownColumns,
        columnsOrder: state.columnsOrder,
        sort: state.sort,
      })
      .then((isSucceed) => {
        if (isSucceed) this.handleCloseSaveGrid();
      });
  };

  getColumns = () => {
    let facade;
    if (
      typeof this.props.onSelectChange === 'function' &&
      !this.props.noCheckBoxes
    ) {
      facade = new SelectFacade(this.props.columns, this);
    } else {
      facade = new SimpleFacade(this.props.columns, this);
    }
    return facade.getColumns();
  };

  getRowSelectionCheckbox = (pseudoValue: any, row: { [key: string]: any }) => {
    const value = row[this.props.selectId];
    const checked = this.state.selected.includes(value);
    return (
      <div
        onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
        onDoubleClick={(e: React.SyntheticEvent) => e.stopPropagation()}>
        <PureCheckbox
          name={value}
          checked={checked}
          onChange={(isChecked: boolean) => {
            this.selectRow(value, isChecked);
          }}
          className="m-0"
        />
      </div>
    );
  };

  getSelectAllCheckbox = () => (
    <PureCheckbox
      className="m-0"
      name="selectAll"
      checked={this.state.isAllSelected}
      label={this.props.selectAllLabel ? this.props.selectAllLabel : ''}
      disabled={!this.props.dataSource?.length}
      onClick={() => this.selectAll(!this.state.isAllSelected)}
    />
  );

  shouldComponentUpdate() {
    if (this.selectionUpdatePostponed) {
      this.selectionUpdatePostponed = false;
      return false;
    }
    return true;
  }

  selectAll = (isChecked: boolean) => {
    const { dataSource, selectId } = this.props;

    this.selectionUpdatePostponed = false;

    const selected = isChecked ? dataSource.map((item) => item[selectId]) : [];

    this.setState(
      { isAllSelected: isChecked, isAllChanged: true, selected },
      () => this.triggerSelection(selected)
    );
  };

  selectGroupOfRows = (ids: any[]) => {
    const selected = this.validateSelection(ids);
    const isAllSelected = selected.length === this.props.dataSource.length;
    this.setState({ isAllSelected, selected });
  };

  selectRow = (name: string, value: boolean) => {
    const { state } = this;
    const selected = [...state.selected];
    const position = selected.indexOf(name);
    if (value) {
      if (position === -1) {
        selected.push(name);
      }
    } else {
      if (position !== -1) {
        selected.splice(position, 1);
      }
    }
    const newSelection = this.validateSelection(selected);
    let isAllSelected = state.isAllSelected;
    if (!value && isAllSelected) {
      isAllSelected = false;
    } else if (value && !isAllSelected) {
      isAllSelected = newSelection.length === this.props.dataSource.length;
    }
    this.selectionUpdatePostponed = true;
    this.setState({ selected: newSelection, isAllSelected }, () =>
      this.triggerSelection(newSelection)
    );
  };

  validateSelection = (selected: Array<any>) => {
    const { props, state } = this;
    if (props.dataSource && props.dataSource.length && state.selected) {
      for (let i = state.selected.length; i > 0; i--) {
        let id = state.selected[i - 1];
        let found = false;
        for (let j = 0; j < props.dataSource.length; j++) {
          if (id === props.dataSource[j][props.selectId]) {
            found = true;
            break;
          }
        }
        if (!found) {
          selected.splice(i - 1, 1);
        }
      }
    } else {
      selected = [];
    }
    return selected;
  };

  triggerSelection = (selected: Array<any>) => {
    let updateWithParent = false;
    if (this.props.onSelectChange) {
      updateWithParent = !!this.props.onSelectChange(selected);
    }
    if (!updateWithParent && this.selectionUpdatePostponed) {
      this.setState({ selectionUpdate: !this.state.selectionUpdate });
    }
  };

  clearSelection = () => {
    const clear: Array<any> = [];
    this.selectionUpdatePostponed = true;
    this.setState({ selected: clear }, () => this.triggerSelection(clear));
  };
}
