import { OptionType } from 'stores/_mobx/systemSetup/masterSetting/examType';
import { RadiologyGroupMappingType } from 'stores/_mobx/systemSetup/facility';

interface OptionsFilterType {
  options: OptionType[];
  currentMapping: RadiologyGroupMappingType[];
  index: number;
}

export const filterOption = ({
  options,
  currentMapping,
  index,
}: OptionsFilterType) =>
  options.filter((option) => {
    const currentValue = currentMapping[index];

    if (currentValue.examType === option.value) return true;
    return currentMapping.every(({ examType }) => examType !== option.value);
  });
