import { apiRequest } from 'services/RequestService';

export class TOption {
  data: number = null;
  label: string = '';
}

export class TAdminItem {
  refid: number = null;
  userName: string = '';
}

export class TCompanyUserPermission {
  data: number = null;
  label: string = '';
  viewPasswordCompany: boolean = false;
  viewPasswordClient: boolean = false;
  viewPasswordClinician: boolean = false;
  hrAdmin: boolean = false;
}

export class TCompanyUserPermissions {
  securityPermissionIdsCompany: Array<number> = [];
  securityPermissionIdsClient: Array<number> = [];
  securityPermissionIdsClinician: Array<number> = [];
  hrAdminIds: Array<number> = [];
}

export class TTemplateForUserType {
  appRefID: string = null;
  template_name: string = '';
  template_code: string = '';
  selected: boolean = false;
}

class SecurityPermissionService {
  loadSuperAdminList(): Promise<Array<TAdminItem>> {
    return apiRequest<Array<TOption> | 'SE'>({
      url: 'permission.PermissionInfo.GetAllSuperadminDropdown',
    }).then((response: Array<TOption> | 'SE') => {
      const adminList: Array<TAdminItem> = [];
      if (response && Array.isArray(response) && response.length > 0) {
        response.forEach((item) =>
          adminList.push({ refid: item.data, userName: item.label })
        );
      }
      return adminList;
    });
  }

  loadCompanyUserPermissions(): Promise<TCompanyUserPermissions> {
    return apiRequest<Array<TCompanyUserPermission> | 'SE'>({
      url: 'permission.PermissionInfo.getCompanyUserPermissions',
    }).then((response) => {
      const res = new TCompanyUserPermissions();
      if (response && Array.isArray(response) && response.length > 0) {
        response.forEach((item) => {
          if (item.viewPasswordCompany) {
            res.securityPermissionIdsCompany.push(item.data);
          }
          if (item.viewPasswordClient) {
            res.securityPermissionIdsClient.push(item.data);
          }
          if (item.viewPasswordClinician) {
            res.securityPermissionIdsClinician.push(item.data);
          }
          if (item.hrAdmin) {
            res.hrAdminIds.push(item.data);
          }
        });
      }
      return res;
    });
  }

  updateCompanyUserPermissions(
    selectedSecurityPermissionIdsCompany: Array<number>,
    selectedSecurityPermissionIdsClient: Array<number>,
    selectedSecurityPermissionIdsClinician: Array<number>,
    selectedHrAdminIds: Array<number>
  ) {
    const data = [
      selectedSecurityPermissionIdsCompany,
      selectedSecurityPermissionIdsClient,
      selectedSecurityPermissionIdsClinician,
      selectedHrAdminIds,
    ];
    return apiRequest<'SE' | 'S' | 'E'>({
      url: 'permission.PermissionInfo.updateCompanyUserPermissions',
      data,
    });
  }

  getUserViewPasswordPermission(userId: number): Promise<string> {
    return apiRequest<string>({
      url: 'permission.PermissionInfo.getCompanyUserViewPasswordPermission',
      data: [userId],
    });
  }

  userHasHrAdminPermission(userId: number): Promise<boolean> {
    return apiRequest<'S' | 'E'>({
      url: 'permission.PermissionInfo.getCompanyUserHrAdminPermission',
      data: [userId],
    }).then((response) => {
      return response === 'S';
    });
  }

  loadTemplateListForType(userTypeId: number) {
    return apiRequest<Array<TTemplateForUserType>>({
      url: 'permission.PermissionInfo.loadTemplateListForType',
      data: [userTypeId],
    });
  }
}

export default new SecurityPermissionService();
