import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Observer } from 'mobx-react-lite';

import { DropdownPlaceOfServiceWithPermission } from 'components/project/dropdown/PlaceOfServiceRolesDropdown';
import { DropdownProviderType } from 'components/project/dropdown/ProviderTypeDropdown';
import { DropDownExamType } from 'components/project/dropdown/ExamTypeDropdown';
import { DropDownFacilityRole } from 'components/project/dropdown/FacilityRolesDropdown';
import { DropdownStateWithPermission } from 'components/project/dropdown/StateRolesDropdown';
import { DropdownLocation } from 'components/project/dropdown/LocationDropdown';
import { DropdownRadiology } from 'components/project/dropdown/RadiologyDropdown';
import { DropdownRadiologist } from 'components/project/dropdown/RadiologistDropdown';
import { DropDownOrderType } from 'components/project/dropdown/OrderType';
import { DropdownPayerName } from 'components/project/dropdown/PayerNameDropdown';
import { DropdownDivision } from 'components/project/dropdown/Division';
import { DateRange } from 'components/form/dateRange';
import { Radio } from 'components/form/radio';
import { Button } from 'components/button';
import { Select } from 'components/form/select';
import { Input } from 'components/form/textField';
import { ControlsLayout } from 'components/layout';

import {
  storeBillingAssignment,
  tcBillingOptions,
  FilterTCType,
} from 'stores/_mobx/billingCodding/billingAssignment';

const className = 'col-md-6 col-lg-3';

const OPTIONS = [
  { value: 'N', label: 'Not Assigned' },
  { value: 'A', label: 'Assigned' },
];

interface PropsType {
  isVisible: boolean;
  fetching: boolean;
  defaultValues: FilterTCType;
  onSubmit: (filter: FilterTCType) => void;
}
const FilterTC = ({
  isVisible,
  fetching,
  defaultValues,
  onSubmit,
}: PropsType) => {
  const { control, watch, reset, handleSubmit, setValue } =
    useForm<FilterTCType>({
      defaultValues,
    });

  const assigned = watch('assigned');

  const handleClickReset = () => {
    const defaultFilter = storeBillingAssignment.getDefaultFilter(true);

    reset(defaultFilter);

    onSubmit(defaultFilter);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'assigned' && formValue.assigned === 'N') {
        setValue('billProcess', '');
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="patientLastName"
        label="Last Name"
        className={className}
        control={control}
      />
      <Input
        name="patientFirstName"
        label="First Name"
        className={className}
        control={control}
      />
      <DropdownPlaceOfServiceWithPermission
        name="pos"
        label="Place of Service"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownProviderType
        name="providerTypeId"
        label="Provider Type"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />

      <DropDownExamType
        name="examTypeId"
        label="Modality"
        optionsForOrderOnly
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropDownFacilityRole
        name="facilityId"
        label="Facility"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownStateWithPermission
        name="stateId"
        label="State"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownLocation
        name="locationId"
        label="Location"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />

      <DropdownRadiology
        name="radiologyGroupId"
        label="Radiology Group"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownRadiologist
        name="radiologistId"
        label="Radiologist"
        className={className}
        control={control}
      />
      <DropDownOrderType
        name="orderTypeId"
        label="Order Type"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <Observer>
        {() => (
          <Radio
            name="assigned"
            label="Assignment"
            className={`${className} part-inline`}
            options={
              storeBillingAssignment.permission.isClient
                ? OPTIONS.filter((option) => option.value === 'N')
                : OPTIONS
            }
            control={control}
          />
        )}
      </Observer>
      <div />
      <Select
        name="billProcess"
        label="Technical Bill Process"
        disabled={assigned === 'N'}
        options={tcBillingOptions}
        className={className}
        control={control}
      />
      <DropdownPayerName
        name="payerId"
        label="Primary Payer"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownDivision
        name="division"
        label="Division"
        className={className}
        control={control}
      />
      <div />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-md-6 col-lg-auto"
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default FilterTC;
