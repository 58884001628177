const clearDebounce = (timer: NodeJS.Timeout) => {
  clearTimeout(timer);
};

const debounce = (delay: number, method: (data?: any) => void) => {
  let timer: NodeJS.Timeout;

  function debouncedMethod() {
    clearTimeout(timer);
    timer = setTimeout(method, delay);
  }

  return {
    method: debouncedMethod,
    clear: () => clearDebounce(timer),
    timer,
  };
};

export default debounce;
