import Notification from 'components/modal/Notification';
import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import { FeeType } from 'stores/_mobx/systemSetup/feeSchedule/common';
import Pagination from 'stores/_mobx/options/pagination';
import UserProfileStore from 'stores/UserProfileStore';
import AccessUtils from 'utils/AccessUtils';
import { FACILITY_ID as PAGE_ID } from 'constant/pagesId/systemSetup';

export const defaultMappingValue: RadiologyGroupMappingType = {
  examType: 0,
  radiologyGroup: 0,
};

export const defaultSettings: FacilitySettingsType = {
  id: 0,
  radiologyGroupToModalityMapping: [defaultMappingValue],
  shouldMoveOrderToExamReview: false,
  sendFaxResultAll: false,
  sendFaxResultPrioritySTAT: false,
  sendFaxResultCritical: false,
  callbackAll: false,
  callbackPrioritySTAT: false,
  callbackCritical: false,
};

const defaultPermissionValue: PermissionType = {
  addFacility: false,
  editFacility: false,
  deleteFacility: false,
  activateFacility: false,
  deactivateFacility: false,
  importAction: false,
};

export const filterDefaultValues: FilterType = {
  searchKey: 'N',
  facilityName: '',
  status: 'A',
  billingJurisdictionId: 0,
  stateId: 0,
  regionId: 0,
  pos: 0,
  providerTypeId: 0,
  completenessOfData: '',
  division: 0,
};

interface PermissionType {
  addFacility: boolean;
  editFacility: boolean;
  deleteFacility: boolean;
  activateFacility: boolean;
  deactivateFacility: boolean;
  importAction: boolean;
}

export interface FilterType {
  searchKey: 'N' | 'R';
  facilityName: string;
  status: 'A' | 'I';
  billingJurisdictionId: number;
  stateId: number;
  regionId: number;
  pos: number;
  providerTypeId: number;
  completenessOfData: 'Y' | 'I' | '';
  division: number;
}

interface DiscountType {
  service: string;
  percentage: number;
  serviceId?: string;
}

interface FacilityDiscountType {
  '1_X-Ray'?: DiscountType;
  '2_Ultrasound'?: DiscountType;
  '3_EKG'?: DiscountType;
  '5_Transportation single pt'?: DiscountType;
  '6_Transportation multi pts'?: DiscountType;
  '7_Set Up'?: DiscountType;
  '8_Employee Screening'?: DiscountType;
  '9_Echocardiogram'?: DiscountType;
  '10_Bone Density'?: DiscountType;
  '11_CT'?: DiscountType;
  '12_Athletic Services'?: DiscountType;
  '13_H.I.R. & Interest Payments'?: DiscountType;
}

interface ParamsFeeDialogType {
  id: number;
  feeType: FeeType;
  groupId: number;
}

interface FacilityResponseType {
  active_end: string | null;
  active_on: string | null;
  address1: string;
  address2: string;
  adminEmail: string;
  adminName: string;
  alert_facility: string;
  bedCount: string;
  bill_email: string;
  billingAddress1: string;
  billingAddress2: string;
  billingCity: string;
  billingFax: string;
  billingJurisdiction: boolean;
  billingPhone: string;
  billingState: string;
  billingType: boolean;
  billingZip: string;
  city: string;
  comment_facility: string;
  contact: string;
  corpid?: string | null;
  country: string;
  cpt_status: FeeType;
  date: string | null;
  deldate: string | null;
  discount: FacilityDiscountType;
  divisionShortName: string;
  eligibilityRequired: 'Y' | 'N';
  external_facility_id: string;
  facilityAliasName: string;
  facilityStatus: 'A' | 'I';
  facility_alias: string;
  facility_nm: string;
  facility_stat: string;
  facility_state: string;
  facilitytype: string;
  fax: string;
  groupid?: string | null;
  jurisdiction: string;
  location: string;
  npi: string;
  nursingContact: string;
  nursingEmail: string;
  orderType: string;
  ownername: unknown[];
  perDiem: 'N' | 'Y';
  phone: string;
  pos: string;
  q_values: string;
  r_values: string;
  refid: string;
  region: string;
  startDate: string;
  state: string;
  status: string;
  webSite: string;
  zip: string;
}

export interface FacilityType
  extends Omit<FacilityResponseType, 'refid' | 'groupid' | 'corpid'> {
  corpid: number;
  groupid: number;
  refid: number;
}

interface FaxSettings {
  id: number;
  emailSettings: boolean;
  sendFaxResults: number;
  callbackOrderResults: number;
  shouldMoveOrderToExamReview: boolean;
}

export interface RadiologyGroupMappingResponseType {
  examType: {
    id: string;
  };
  radiologyGroup: {
    id: string;
  };
  facility: string;
  id: number;
}

export interface RadiologyGroupMappingType {
  examType: number;
  radiologyGroup: number;
}

interface FacilitySettingsResponseType {
  id: number;
  facilityRadiologyGroupExamTypeMappings: RadiologyGroupMappingResponseType[];
}

export interface FacilitySettingsType {
  id: number;
  radiologyGroupToModalityMapping: RadiologyGroupMappingType[];
  shouldMoveOrderToExamReview: boolean;
  sendFaxResultAll: boolean;
  sendFaxResultPrioritySTAT: boolean;
  sendFaxResultCritical: boolean;
  callbackAll: boolean;
  callbackPrioritySTAT: boolean;
  callbackCritical: boolean;
}

export interface FacilityAddressResponseType {
  address: string;
  address1: string;
  bill_addres1: string;
  bill_city: string;
  bill_id: string;
  bill_state: string;
  bill_zipcode: string;
  city: string;
  email: string;
  facility_name: string;
  fax: string;
  mobile: string;
  pdfimage: string;
  phone: string;
  pos: string;
  region: string;
  source: string;
  state: string;
  zipcode: string;
  zipimage: string;
}

class Facility {
  fetching: boolean = false;
  fetchingSettings: boolean = false;
  facilities: FacilityType[] = [];
  facilityTotal: number = 0;
  filter: FilterType = filterDefaultValues;
  permission: PermissionType = defaultPermissionValue;
  paramsFeeDialog: ParamsFeeDialogType | null = null;
  settings: FacilitySettingsType = undefined;

  page: Pagination = new Pagination({ id: 'facility_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingSettings: observable,
      facilities: observable,
      facilityTotal: observable,
      filter: observable,
      paramsFeeDialog: observable,
      settings: observable,

      setFetching: action,
      setFetchingSettings: action,
      setFacilitiesList: action,
      setFacilityCount: action,
      setFilter: action,
      setPermission: action,
      setParamsFeeDialog: action,
      setSettings: action,
      clearSettings: action.bound,
      clearParamsFeeDialog: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingSettings(fetching: boolean) {
    this.fetchingSettings = fetching;
  }

  setFacilitiesList(facilities: FacilityType[]) {
    this.facilities = facilities;
  }

  setFacilityCount(count: number) {
    this.facilityTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setPermission(permission: PermissionType) {
    this.permission = permission;
  }

  setParamsFeeDialog(facility: FacilityType) {
    this.paramsFeeDialog = {
      id: facility.cpt_status === 'F' ? facility.refid : facility.corpid,
      feeType: facility.cpt_status,
      groupId: facility.groupid,
    };
  }

  setSettings(settings: FacilitySettingsType) {
    this.settings = settings;
  }

  clearSettings() {
    this.settings = undefined;
  }

  clearParamsFeeDialog() {
    this.paramsFeeDialog = null;
  }

  async getFacilityCount(data: any[]) {
    try {
      const count = await apiRequest<number>({
        url: 'facility.FacilityMaster.FacilityMasterTotalCount',
        data,
      });

      this.setFacilityCount(count);
    } catch (e: any) {
      this.setFacilityCount(0);
    }
  }

  async getFacilitiesList(payload: any[]) {
    const { pagination } = this.page;

    const data = [pagination.skip, pagination.pageSize].concat(payload);

    try {
      const response = await apiRequest<FacilityResponseType[]>({
        url: 'facility.FacilityMaster.FacilityMasterList',
        data,
      });

      const facilities = response.map((facility) => ({
        ...facility,
        corpid: Number(facility.corpid) || 0,
        groupid: Number(facility.groupid) || 0,
        refid: Number(facility.refid) || 0,
      }));

      this.setFacilitiesList(facilities);
    } catch (e: any) {
      this.setFacilitiesList([]);
    }
  }

  getFacilitiesListMain = async (isDivisionEnabled: boolean) => {
    const { filter } = this;

    this.setFetching(true);

    const payload = [
      filter.searchKey,
      filter.facilityName,
      filter.status,
      filter.billingJurisdictionId,
      filter.stateId,
      filter.regionId,
      '', // lastname,
      '', // firstname,
      '', // contactTitle,
      1,
      filter.pos,
      filter.providerTypeId,
      filter.completenessOfData,
      isDivisionEnabled ? filter.division : 0,
    ];

    const promiseList = this.getFacilitiesList(payload);

    const promiseCount = this.getFacilityCount(payload);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getFacilitySettings(facilityId: number) {
    this.setFetchingSettings(true);

    try {
      const { id, facilityRadiologyGroupExamTypeMappings } =
        await apiRequest<FacilitySettingsResponseType>({
          url: `facilities/${facilityId}`,
          method: 'GET',
          legacy: false,
        });

      const settingsResponse = await apiRequest<FaxSettings>({
        url: `facilities/${facilityId}/settings`,
        method: 'GET',
        legacy: false,
      });

      const radiologyGroupToModalityMapping =
        facilityRadiologyGroupExamTypeMappings.length
          ? facilityRadiologyGroupExamTypeMappings.map(
              ({ examType, radiologyGroup }) => ({
                examType: Number(examType.id),
                radiologyGroup: Number(radiologyGroup.id),
              })
            )
          : [defaultMappingValue];

      const settings: FacilitySettingsType = {
        ...defaultSettings,
        id,
        radiologyGroupToModalityMapping,
        sendFaxResultAll: settingsResponse.sendFaxResults === 1,
        sendFaxResultPrioritySTAT: settingsResponse.sendFaxResults === 2,
        sendFaxResultCritical: settingsResponse.sendFaxResults === 3,
        callbackAll: settingsResponse.callbackOrderResults === 1,
        callbackPrioritySTAT: settingsResponse.callbackOrderResults === 2,
        callbackCritical: settingsResponse.callbackOrderResults === 3,
        shouldMoveOrderToExamReview:
          settingsResponse.shouldMoveOrderToExamReview,
      };

      this.setSettings(settings);
    } catch (e: any) {
      this.setSettings({
        ...defaultSettings,
        id: facilityId,
      });
    } finally {
      this.setFetchingSettings(false);
    }
  }

  async updateFacilitySettings({
    id,
    radiologyGroupToModalityMapping: mapping,
    shouldMoveOrderToExamReview,
    ...rest
  }: FacilitySettingsType) {
    this.setFetchingSettings(true);

    const mappingPayload = {
      radiologyGroupToModalityMapping: mapping.filter(
        (option) => Boolean(option.examType) || Boolean(option.radiologyGroup)
      ),
    };

    const sendFaxResults = [
      false,
      rest.sendFaxResultAll,
      rest.sendFaxResultPrioritySTAT,
      rest.sendFaxResultCritical,
    ].findIndex((el) => el);

    const callbackOrderResults = [
      false,
      rest.callbackAll,
      rest.callbackPrioritySTAT,
      rest.callbackCritical,
    ].findIndex((el) => el);

    const settingsPayload = {
      emailSettings: false,
      shouldMoveOrderToExamReview,
      sendFaxResults: ~sendFaxResults ? sendFaxResults : 0,
      callbackOrderResults: ~callbackOrderResults ? callbackOrderResults : 0,
    };

    try {
      const promiseMapping = apiRequest<RadiologyGroupMappingType[]>({
        url: `facilities/${id}/radiology_group_to_modality_mapping`,
        method: 'PATCH',
        legacy: false,
        data: mappingPayload,
      });
      const promiseSettings = apiRequest<RadiologyGroupMappingType[]>({
        url: `facilities/${id}/settings`,
        method: 'PATCH',
        legacy: false,
        data: settingsPayload,
      });

      await Promise.all([promiseMapping, promiseSettings]);

      Notification.success('Facility settings updated successfully!');
      return true;
    } catch (e: any) {
      Notification.warning('Facility settings updating error!');
      return false;
    } finally {
      this.setFetchingSettings(false);
    }
  }

  async getFacilityAddress(id: number) {
    try {
      const response = await apiRequest<FacilityAddressResponseType>({
        url: 'facility.FacilityMaster.GetFacilityAddress',
        data: [id],
      });
      return response;
    } catch (e: any) {
      return null;
    }
  }

  checkPermission() {
    const importAction = UserProfileStore.isSuperAdmin();

    const addFacility = AccessUtils.checkAccess(PAGE_ID.ADD);

    const editFacility = AccessUtils.checkAccess(PAGE_ID.EDIT);

    const activateFacility = AccessUtils.checkAccess(PAGE_ID.ACTIVE);

    const deactivateFacility = AccessUtils.checkAccess(PAGE_ID.INACTIVE);

    this.setPermission({
      importAction,
      addFacility,
      editFacility,
      deleteFacility: false,
      activateFacility,
      deactivateFacility,
    });
  }

  async changeStatus(data: { id: number; status: 'Active' | 'Inactive' }) {
    try {
      const response = await apiRequest<'S'>({
        url: 'facility.FacilityMaster.UpdateStatus',
        data: [data.id, data.status],
      });
      return response === 'S';
    } catch (e: any) {
      return false;
    }
  }

  async changeFacilityStatusMain(ids: number[]) {
    const {
      filter: { status },
    } = this;

    const currentStatus = status === 'A' ? 'Active' : 'Inactive';

    this.setFetching(true);

    try {
      const result = await Promise.all(
        ids.map((id) => this.changeStatus({ id, status: currentStatus }))
      );

      const count = result.reduce(
        (prev, isSucceed) => prev + Number(isSucceed),
        0
      );

      if (count) {
        Notification.success(
          `${count} facilit${count > 1 ? 'ies were' : 'y was'} ${
            status === 'A' ? 'deactivated' : 'activated'
          } successfully!`
        );
        return true;
      }
      throw Error('');
    } catch (error) {
      Notification.danger('Error occurred!');
      this.setFetching(false);
      return false;
    }
  }
}

export const storeFacility = new Facility();
