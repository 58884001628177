import { apiRequest } from 'services/RequestService';

class ApplicationUsersPageService {
  changeActiveStatus(userId: number, status: 'Active' | 'Inactive') {
    const data = {
      userId: userId,
      status: status,
    };

    return apiRequest<string>({
      url: 'usermaster.UserInfo.UpdateStatus',
      data,
    });
  }

  deleteUser(id: number) {
    return apiRequest<string>({
      url: 'usermaster.UserInfo.DeleteUserInfo',
      data: [id],
    });
  }
}

export default new ApplicationUsersPageService();
