import { useForm } from 'react-hook-form';

import { DropdownCorporateWithPermission } from 'components/project/dropdown/CorporateRolesDropdown';
import { DropDownExamType } from 'components/project/dropdown/ExamTypeDropdown';
import { DateRange } from 'components/form/dateRange';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import {
  storeCorporateSummary,
  FilterType,
} from 'stores/_mobx/report/corporateSummary';

interface PropsType {
  fetching: boolean;
  isFilterVisible: boolean;
  onSearch: (filter: FilterType) => void;
}

const Filter = ({ fetching, isFilterVisible, onSearch }: PropsType) => {
  const { control, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeCorporateSummary.filter,
  });

  const handleClickReset = () => {
    const defaultFilterValue = storeCorporateSummary.getDefaultFilter();

    reset(defaultFilterValue);
    onSearch(defaultFilterValue);
  };

  return (
    <form
      className={`row${isFilterVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSearch)}>
      <DropDownExamType
        name="examType"
        label="Modality"
        className="col-md-6 col-xl-4"
        isMulti
        isStyleDependsOnValue
        control={control}
      />
      <DropdownCorporateWithPermission
        name="corporateId"
        label="Corporation"
        className="col-md-6 col-xl-4"
        isMulti
        isStyleDependsOnValue
        control={control}
      />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-6 col-lg-auto"
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
