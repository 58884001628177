export default class InputState {
    value: any;
    errorKeys: any;
    errorMessages: any;

    constructor(value: any, errorKeys: any, errorMessages: any) {
        this.value = value;
        this.errorKeys = errorKeys;
        this.errorMessages = errorMessages;
    }
}