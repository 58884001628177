import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';

import { storeDropdownPrivilegeEquivalence } from 'stores/_mobx/dropDown/privilegeEquivalence';

interface PropsType extends Omit<SelectPropsType, 'options'> {
  id?: number;
}

const DropdownPrivilegeEquivalence = ({ id, ...props }: PropsType) => {
  const { fetching, options } = storeDropdownPrivilegeEquivalence;

  useEffect(() => {
    storeDropdownPrivilegeEquivalence.getOptions(id);
  }, [id]);

  return <Select {...props} options={options} isLoading={fetching} />;
};

export default observer(DropdownPrivilegeEquivalence);
