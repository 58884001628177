import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import DialogFormTextFax, {
  getInitialValues,
  FaxModel,
} from 'page/components/DialogFormTextFax';
import { IconButton } from 'components/button';
import { Grid } from 'components/grid';
import DialogUpdate from './components/DialogUpdate';
import TransmitLogComponent from './components/TransmitLogComponent';
import DialogFormEmail, {
  FormModel as DialogFormEmailType,
} from './components/DialogFormEmail';
import FilterFacility from './components/FilterFacility';
import DialogTransmitLog from './components/DialogTransmitLog';

import {
  storeDocManagerAuditFacility,
  FilterType,
  AuditFacilityType,
} from 'stores/_mobx/patientRecord/docManagerAuditFacility';
import {
  storeDocManagerFaxAndMail,
  SendFaxPayloadType,
  SendEmailPayloadType,
} from 'stores/_mobx/patientRecord/docManagerFaxAndMail';
import { storeDocManagerAuditTransmitLog } from 'stores/_mobx/patientRecord/docManagerAuditTransmitLog';

interface PropsType {
  isFilterVisible: boolean;
  toggleFilterButton: (isVisible: boolean) => void;
}

const FacilityAuditTab = ({
  isFilterVisible,
  toggleFilterButton,
}: PropsType) => {
  const {
    auditFacilityEntries,
    auditFacilityTotal,
    fetching,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeDocManagerAuditFacility;

  const {
    fetching: fetchingFaxMail,
    selectedFax,
    selectedEmail,
    newFax,
    newEmail,
  } = storeDocManagerFaxAndMail;

  const { idForTransmitLogDialog, transmitLogProps } =
    storeDocManagerAuditTransmitLog;

  const columns = useMemo(
    () => [
      { name: 'name', title: 'Facility' },
      {
        name: 'unsign_count',
        title: 'Unsigned Order Count',
        className: 'text-center',
      },
      {
        name: 'clinical_count',
        title: 'Clinical Notes Count',
        className: 'text-center',
      },
      {
        name: 'insurance_count',
        title: 'Insurance Count',
        className: 'text-center',
      },
      { name: 'bill_email', title: 'Facility E-mail' },
      { name: 'bill_fax', title: 'Facility Fax' },
      { name: 'fax_date', title: 'TX Status', className: 'text-nowrap' },
      {
        name: 'documentReq',
        title: 'Document Request',
        className: 'text-center',
        render: (value: undefined, data: AuditFacilityType) => (
          <IconButton
            className="text-primary"
            onClick={() =>
              storeDocManagerAuditTransmitLog.setTransmitLogProps(data)
            }>
            <i className="bi bi-file-earmark-text" />
          </IconButton>
        ),
      },
      {
        name: 'id',
        title: 'Transmit Log',
        render: (id: number) => (
          <IconButton
            onClick={() => {
              storeDocManagerAuditTransmitLog.setIdForTransmitLogDialog(id);
            }}>
            <i className="icon icon-notes" />
          </IconButton>
        ),
      },
      {
        name: 'bill_email',
        title: 'Email',
        render: (email: string, data: AuditFacilityType) => (
          <IconButton
            className="text-primary"
            onClick={() => storeDocManagerFaxAndMail.setEmail(data)}>
            <i className="bi bi-envelope" />
          </IconButton>
        ),
      },
      {
        name: 'bill_fax',
        title: 'Fax',
        render: (fax: string, data: AuditFacilityType) => (
          <IconButton
            className="text-primary"
            onClick={() => storeDocManagerFaxAndMail.setFax(data)}>
            <i className="bi bi-printer" />
          </IconButton>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleFilterSubmit = (filter: FilterType) => {
    storeDocManagerAuditFacility.setFilter(filter);

    setPaginationToStart();
  };

  const sendFax = (data: SendFaxPayloadType) => {
    storeDocManagerFaxAndMail.sendFax(data).then((isSucceed) => {
      if (isSucceed) storeDocManagerAuditFacility.getAuditFacility();
    });
  };

  const sendEmail = (data: SendEmailPayloadType) => {
    storeDocManagerFaxAndMail.sendEmail(data).then((isSucceed) => {
      if (isSucceed) storeDocManagerAuditFacility.getAuditFacility();
    });
  };

  const handleSendFax = (fax: FaxModel, orders?: any[]) => {
    const selectedData = selectedFax || transmitLogProps;

    const recipient = {
      refid: selectedData.id,
      name: selectedData.name,
      fax: fax.to,
      email: null as null,
    };

    const payload = { fax, recipient, type: 'F' as 'F', orders, filter };

    if (fax.to === selectedData.bill_fax) {
      storeDocManagerFaxAndMail.clearFax();
      sendFax(payload);
    } else {
      storeDocManagerFaxAndMail.clearFax();
      storeDocManagerFaxAndMail.setNewFax(payload);
    }
  };

  const handleSendEmail = (email: DialogFormEmailType, orders?: any[]) => {
    const selectedData = selectedEmail || transmitLogProps;

    const recipient = {
      refid: selectedData.id,
      name: selectedData.name,
      email: email.email,
      fax: null as null,
    };

    const payload = { email, recipient, type: 'F' as 'F', orders, filter };

    const isNewEmail =
      !selectedData.bill_email ||
      selectedData.bill_email
        .split(',')
        .some((existedEmail) => existedEmail.trim() !== email.email.trim());

    if (isNewEmail) {
      storeDocManagerFaxAndMail.clearEmail();
      storeDocManagerFaxAndMail.setNewEmail(payload);
    } else {
      storeDocManagerFaxAndMail.clearEmail();
      sendEmail(payload);
    }
  };

  const handleUpdateFax = () => {
    storeDocManagerFaxAndMail.clearNewFax();
    sendFax(newFax);
    return storeDocManagerFaxAndMail.updateFax({
      id: newFax.recipient.refid,
      type: newFax.type,
      fax: newFax.fax.to,
    });
  };

  const handleUpdateEmail = () => {
    storeDocManagerFaxAndMail.clearNewEmail();
    sendEmail(newEmail);
    return storeDocManagerFaxAndMail.updateEmail({
      id: newEmail.recipient.refid,
      type: newEmail.type,
      email: newEmail.email.email,
    });
  };

  const handleCloseDialogUpdateFax = () => {
    sendFax(newFax);
    storeDocManagerFaxAndMail.clearNewFax();
  };

  const handleCloseDialogUpdateEmail = () => {
    sendEmail(newEmail);
    storeDocManagerFaxAndMail.clearNewEmail();
  };

  const handleCloseTransmitLogDetails = () => {
    storeDocManagerAuditTransmitLog.clearTransmitLogsStore();
    storeDocManagerAuditFacility.getAuditFacility();
  };

  useEffect(() => {
    storeDocManagerAuditFacility.getAuditFacility();
  }, [pagination]);

  useEffect(() => {
    return () => {
      storeDocManagerAuditTransmitLog.clearTransmitLogsStore();
      storeDocManagerFaxAndMail.clearStore();
    };
  }, []);

  return (
    <>
      {transmitLogProps ? (
        <TransmitLogComponent
          pageKey="F"
          facilityId={transmitLogProps.id}
          title={transmitLogProps.name}
          filter={filter}
          selectedEntity={transmitLogProps}
          toggleFilterButton={toggleFilterButton}
          saveFax={handleSendFax}
          saveEmail={handleSendEmail}
          callback={handleCloseTransmitLogDetails}
        />
      ) : (
        <>
          <FilterFacility
            isVisible={isFilterVisible}
            onSubmit={handleFilterSubmit}
          />

          <Grid
            id="facility_tab_grid"
            columns={columns}
            onAjax={fetching || fetchingFaxMail}
            dataSource={auditFacilityEntries}
            dataSourceCount={auditFacilityTotal}
            pagination={pagination}
            onPaginationChange={setPagination}
          />
        </>
      )}

      {idForTransmitLogDialog ? (
        <DialogTransmitLog
          type="facility"
          id={idForTransmitLogDialog}
          onClose={storeDocManagerAuditTransmitLog.clearIdForTransmitLogDialog}
        />
      ) : null}

      {selectedFax && (
        <DialogFormTextFax
          withExamRequisition
          withRecipientNumber
          defaultValues={{
            ...getInitialValues(),
            to: selectedFax.bill_fax,
          }}
          onSubmit={handleSendFax}
          onClose={storeDocManagerFaxAndMail.clearFax}
        />
      )}

      {newFax && (
        <DialogUpdate
          title="Fax Update"
          label="Do you want to update billing fax according to this value?"
          defaultValues={newFax.fax.to}
          onSubmit={handleUpdateFax}
          onClose={handleCloseDialogUpdateFax}
        />
      )}

      {selectedEmail && (
        <DialogFormEmail
          defaultEmail={selectedEmail.bill_email}
          onSubmit={handleSendEmail}
          onClose={storeDocManagerFaxAndMail.clearEmail}
        />
      )}

      {newEmail && (
        <DialogUpdate
          title="Email Update"
          label="Do you want to update billing email according to this value?"
          isEmailUpdate
          defaultValues={newEmail.email.email}
          onSubmit={handleUpdateEmail}
          onClose={handleCloseDialogUpdateEmail}
        />
      )}
    </>
  );
};

export default observer(FacilityAuditTab);
