import { backend } from 'constant/config';

export const checkSession = async () => {
  try {
    const status = await fetch(`${backend}?ts_check=1`).then((response) =>
      response.json()
    );

    return status === 'S';
  } catch (error: any) {
    return false;
  }
};
