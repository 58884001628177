import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    last_name: yup.string().required('Please, fill in Last Name'),
    first_name: yup.string().required('Please, fill in First Name!'),
    birthdate: yup.string().required('Please, fill in Date of Birth!'),
    gender: yup.string().required('Please, select Gender!'),
    facilityid: yup
      .number()
      .typeError('Please, select a Facility!')
      .min(1, 'Please, select a Facility!'),
    ssn: yup.string().test({
      test: (ssn: string) => (ssn ? /^\d{3}-\d{2}-\d{4}$/.test(ssn) : true),
      message: 'SSN should be 9 numbers in length!',
    }),
  })
);

export default validation;
