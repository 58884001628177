import { apiRequest } from 'services/RequestService';
import { TPagination } from 'components/grid/GridTypes';

export class TOrdersFilter {
  facility: number[] = [];
  dosStart: string = '';
  dosEnd: string = '';
  corporateId: number = null;
  mode: string = '';
  srchlastnameval: string = '';
  srchfirstnameval: string = '';
  orderId: number = null;
  status: string = '';
  examtype: number[] = [];
  stateId: number[] = [];
  locId: number[] = [];
  techId: number = null;
  posId: number[] = [];
  dosSearch: string = '';
  batchclaim: string = '';
  orderType: string[] = [];
  providerType: string[] = [];
  srchdate: string = '';
  payer: number[] = [];
  division: number = 0;
}

export class TArchivePayer {
  pripayor_id: number = null;
  pri_insureid: string = '';
  priplan_id: number = null;
  pri_effdt: string = '';
  pri_termdt: string = '';
  pri_groupno: string = '';
  prirelationship: string = '';
  pri_payer_type: string = '';
  pri_refid: number = null;
  secpayor_id: number = null;
  sec_insureid: string = '';
  secplan_id: number = null;
  sec_effdt: string = '';
  sec_termdt: string = '';
  sec_groupno: string = '';
  secrelationship: string = '';
  sec_msp: number = 0;
  sec_payer_type: string = '';
  sec_refid: number = null;
  terpayor_id: number = null;
  ter_insureid: string = '';
  terplan_id: number = null;
  ter_effdt: string = '';
  ter_termdt: string = '';
  ter_groupno: string = '';
  terrelationship: string = '';
  ter_payer_type: string = '';
  ter_refid: number = null;
  orderID: number = null;
}

export class TCompletedInsuranceInfo {
  dob: string = '';
  ssn: string = '';
  lastname: string = '';
  firstname: string = '';
  medicare_id: string = '';
  medicaid_id: string = '';
  medicaid_state: string = '';
  pripayor_id: number = null;
  pri_insureid: string = '';
  priplan_id: number = null;
  pri_effdt: string = '';
  pri_termdt: string = '';
  pri_groupno: string = '';
  prirelationship: string = '';
  pri_payer_type: string = '';
  pri_refid: number = null;
  secpayor_id: number = null;
  sec_insureid: string = '';
  secplan_id: number = null;
  sec_effdt: string = '';
  sec_termdt: string = '';
  sec_groupno: string = '';
  secrelationship: string = '';
  sec_payer_type: string = '';
  sec_refid: number = null;
  terpayor_id: number = null;
  ter_insureid: string = '';
  terplan_id: number = null;
  ter_effdt: string = '';
  ter_termdt: string = '';
  ter_groupno: string = '';
  terrelationship: string = '';
  ter_payer_type: string = '';
  ter_refid: number = null;
  guar_lastnm: string = '';
  guar_firstnm: string = '';
  guar_relationship: string = '';
  guar_addr: string = '';
  guar_addr2: string = '';
  guar_city: string = '';
  guar_state: string = '';
  guar_zip: string = '';
  //
  pri_payerType: string = '';
  sec_payerType: string = '';
  sec_msp: number = 0;
  ter_payerType: string = '';
  orderID: number = null;
}

export class TArchivePayerInfo {
  msp: number = 0;
  payor_id: number = null;
  patient_relationship: string = '';
  plan_id: number = null;
  patient_id: number = null;
  insurance_no: string = '';
  payor_type: string = '';
  groupno: string = '';
  payer_type: Array<{ label: string; data: string; value: number }>;
  plan_names: Array<{ label: string; data: string; value?: number }>;
  start_dt: string = '';
  end_dt: string = '';
}

class EligibilityManagerPageService {
  loadOverview(
    filter: TOrdersFilter,
    pagination: TPagination,
    signal: AbortSignal
  ) {
    const data = [
      filter.facility,
      filter.dosStart,
      filter.dosEnd,
      filter.corporateId,
      filter.mode,
      pagination.skip,
      pagination.pageSize,
      filter.srchlastnameval,
      filter.srchfirstnameval,
      filter.orderId,
      filter.status,
      filter.examtype,
      filter.stateId,
      filter.locId,
      filter.techId,
      filter.posId,
      filter.dosSearch,
      filter.batchclaim,
      filter.orderType,
      filter.providerType,
      filter.srchdate,
      filter.payer,
      filter.division,
    ];
    return apiRequest({
      url: 'order.EligibilityMaster.GetEligibleOrdersList',
      data,
      signal,
    });
  }

  loadOverviewCount(filter: TOrdersFilter, signal: AbortSignal) {
    const data = [
      filter.facility,
      filter.dosStart,
      filter.dosEnd,
      filter.corporateId,
      filter.mode,
      filter.srchlastnameval,
      filter.srchfirstnameval,
      filter.orderId,
      filter.status,
      filter.examtype,
      filter.stateId,
      filter.locId,
      filter.techId,
      filter.posId,
      filter.dosSearch,
      filter.batchclaim,
      filter.orderType,
      filter.providerType,
      filter.srchdate,
      filter.payer,
      filter.division,
    ];
    return apiRequest<number>({
      url: 'order.EligibilityMaster.GetEligibleOrdersCount',
      data,
      signal,
    });
  }

  exportOrders(
    filter: TOrdersFilter,
    pagination: TPagination,
    ids: Array<number>,
    updateStatus: boolean,
  ) {
    const data = [
      filter.facility,
      filter.dosStart,
      filter.dosEnd,
      filter.corporateId,
      filter.mode,
      pagination.skip,
      pagination.pageSize,
      filter.srchlastnameval,
      filter.srchfirstnameval,
      filter.orderId,
      filter.status,
      filter.examtype,
      filter.stateId,
      filter.locId,
      filter.techId,
      filter.posId,
      filter.dosSearch,
      filter.batchclaim,
      filter.orderType,
      filter.providerType,
      ids,
      filter.division,
      updateStatus,
    ];

    return apiRequest<string>({ url: 'order.EligibilityMaster.Export', data });
  }

  exportExperianOrders(
    filter: TOrdersFilter,
    pagination: TPagination,
    ids: Array<number>,
    updateStatus: boolean,
  ) {
    const data = [
      filter.facility,
      filter.dosStart,
      filter.dosEnd,
      filter.corporateId,
      filter.mode,
      pagination.skip,
      pagination.pageSize,
      filter.srchlastnameval,
      filter.srchfirstnameval,
      filter.orderId,
      filter.status,
      filter.examtype,
      filter.stateId,
      filter.locId,
      filter.techId,
      filter.posId,
      filter.dosSearch,
      filter.batchclaim,
      filter.orderType,
      filter.providerType,
      ids,
      filter.division,
      updateStatus,
    ];

    return apiRequest<string>({ url: 'order.EligibilityMaster.ExperianExport', data });
  }

  archivePayer(
    refid: number,
    payorType: 'P' | 'S' | 'T',
    payload: TArchivePayer,
    flagOrderEntryPayerInfo: boolean
  ) {
    const data = { refid, payorType, data: payload, flagOrderEntryPayerInfo };
    return apiRequest<'E' | 'S'>({
      url: 'patient.PatientInfo.archivePayor',
      data,
    });
  }

  deletePayor(refid: number) {
    return apiRequest<'S'>({
      url: 'patient.PatientInfo.deletePayerInfo',
      data: { refid },
    });
  }

  updateCompletedInsuranceInfo(
    data: TCompletedInsuranceInfo,
    patientId: number,
    orderDataWithId: Array<number>,
    insStatus: 'W' | 'I' | 'C' | '',
    correctional: {
      ice_number: string;
      inmate_number: string;
      immigration_id: string;
    },
    ins_notes: string,
    msp: number,
    flagOrderEntryPayerInfo: boolean
  ) {
    const payload = {
      data,
      patientId,
      orderDataWithId,
      insStatus,
      correctional,
      ins_notes,
      msp,
      flagOrderEntryPayerInfo,
    };
    return apiRequest<'SE' | 'S' | 'E'>({
      url: 'patient.PatientInfo.UpdateCompletedInsuranceInfo',
      data: payload,
    });
  }

  loadArchivePayerInfo(payerId: number, orderId: number) {
    return apiRequest<Array<TArchivePayerInfo>>({
      url: 'patient.PatientInfo.GetArchivePayerInfo',
      data: { payerId, orderId },
    });
  }

  loadInitialPayerInfo(
    payerId: number,
    orderId: number,
    flagOrderEntryPayerInfo: boolean
  ) {
    return apiRequest<Array<TArchivePayerInfo>>({
      url: 'patient.PatientInfo.GetInitialPayerInfo',
      data: { payerId, flagOrderEntryPayerInfo },
    });
  }

  revertOrder(orderId: number) {
    return apiRequest<'S'>({
      url: 'order.EligibilityMaster.RevertToWaiting',
      data: { orderId },
    });
  }

  updateStatus(flag: 'W' | 'I' | 'C' | '', ids: Array<number>) {
    return apiRequest<'S' | 'E'>({
      url: 'order.EligibilityMaster.updateExportedStatus',
      data: { flag, ids },
    });
  }

  updateStatusForAll(
    filter: TOrdersFilter,
    pagination: TPagination,
  ) {
    const data = [
      filter.facility,
      filter.dosStart,
      filter.dosEnd,
      filter.corporateId,
      filter.mode,
      pagination.skip,
      pagination.pageSize,
      filter.srchlastnameval,
      filter.srchfirstnameval,
      filter.orderId,
      filter.status,
      filter.examtype,
      filter.stateId,
      filter.locId,
      filter.techId,
      filter.posId,
      filter.dosSearch,
      filter.batchclaim,
      filter.orderType,
      filter.providerType,
      filter.division,
    ];

    return apiRequest<'S' | 'E'>({
      url: 'order.EligibilityMaster.UpdateStatusForAll',
      data,
    });
  }
}

export default new EligibilityManagerPageService();
