import ApplicationUsersPageService from 'services/privelegies/ApplicationUsersPageService';

export default class ApplicationUsersPageActions {
  static changeActiveStatus(usersId: number[], status: 'Active' | 'Inactive') {
    const listOfPromises = usersId.map((id) =>
      ApplicationUsersPageService.changeActiveStatus(id, status)
    );
    return Promise.allSettled(listOfPromises);
  }

  static deleteUser(id: number) {
    return ApplicationUsersPageService.deleteUser(id);
  }
}
