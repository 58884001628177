import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { IconButton } from 'components/button';
import { Grid } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import Filter from './components/Filter';
import DialogAuditLog from './components/DialogAuditLog';
import ControlPanel from './components/ControlPanel';

import {
  storeFacilityContact,
  FilterType,
} from 'stores/_mobx/systemSetup/facilityContact';
import AccessUtils from 'utils/AccessUtils';
import { FACILITY_CONTACT as PAGE_ID } from 'constant/pagesId/systemSetup';
import { URL_FACILITY_CONTACTS } from 'constant/path/systemSetup';

const columns = [
  { name: 'first_name', title: 'First name' },
  { name: 'last_name', title: 'Last name' },
  { name: 'con_title', title: 'Role' },
  { name: 'facility', title: 'Facilities' },
  { name: 'phone', title: 'Phone' },
  {
    name: 'facility_local',
    title: 'Contact Type',
    render: (v: any) => (v === 'Y' ? 'Facility Local' : 'Global'),
  },
  { name: 'fax', title: 'Fax' },
  {
    name: 'refid',
    title: 'Audit Log',
    render: (id: number) => (
      <div className="control">
        <IconButton onClick={() => storeFacilityContact.setIdForAuditLog(id)}>
          <i className="icon icon-auditlog" />
        </IconButton>
      </div>
    ),
  },
];

const FacilityContactListPage = () => {
  const gridRef = useRef(null);

  const {
    fetching,
    facilityList,
    facilityTotal,
    idForDelete,
    idForAuditLog,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeFacilityContact;

  const [selected, setSelected] = useState<number[]>([]);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const isAccessHave = useMemo(
    () => AccessUtils.checkAccessAny(PAGE_ID.EDIT, PAGE_ID.DELETE),
    []
  );

  const allColumns = useMemo(
    () =>
      isAccessHave
        ? [
            ...columns,
            {
              name: 'refid',
              title: 'Action',
              className: 'width-100',
              render: (id: number) => (
                <div className="control">
                  {AccessUtils.get(
                    PAGE_ID.EDIT,
                    <Link
                      className="bi bi-pencil"
                      to={`${URL_FACILITY_CONTACTS}/edit/${id}`}
                    />
                  )}
                  {AccessUtils.get(
                    PAGE_ID.DELETE,
                    <IconButton
                      onClick={() => storeFacilityContact.setIdForDelete(id)}>
                      <i className="bi bi-trash" />
                    </IconButton>
                  )}
                </div>
              ),
            },
          ]
        : columns,
    [isAccessHave]
  );

  const handleChangeStatus = () => {
    storeFacilityContact
      .changeFacilityContactStatus(selected)
      .then((isSucceed) => {
        if (isSucceed) {
          gridRef.current?.clearSelection();

          storeFacilityContact.getFacilityContactList();
        }
      });
  };

  const handleDeleteApprove = () => {
    storeFacilityContact.deleteFacilityContact().then((isSucceed) => {
      if (isSucceed) {
        gridRef.current?.clearSelection();

        storeFacilityContact.getFacilityContactList();
      }
    });
  };

  const handleFilterChange = (filter: FilterType) => {
    storeFacilityContact.setFilter(filter);
    debouncedMethod();
  };

  useEffect(() => {
    storeFacilityContact.getFacilityContactList();
  }, [pagination, filter.isActive, filter.facilityId]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Facility Contacts" />

      <Filter onSearch={handleFilterChange} />

      <Grid
        id="facility_contacts_grid"
        selectId="refid"
        columns={allColumns}
        onAjax={fetching}
        dataSource={facilityList}
        dataSourceCount={facilityTotal}
        ref={gridRef}
        pagination={pagination}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        gridControlPanel={
          <ControlPanel
            isActive={filter.isActive === 'A'}
            isContactSelected={Boolean(selected.length)}
            onChangeStatus={handleChangeStatus}
          />
        }
      />

      {idForDelete ? (
        <DialogConfirm
          onCancel={storeFacilityContact.clearIdForDelete}
          onApprove={handleDeleteApprove}>
          Are you sure you want to delete this Facility contact?
        </DialogConfirm>
      ) : null}

      {idForAuditLog ? (
        <DialogAuditLog
          userId={idForAuditLog}
          onClose={storeFacilityContact.clearAuditLog}
        />
      ) : null}
    </>
  );
};

export default observer(FacilityContactListPage);
