import { apiRequest } from 'services/RequestService';

export class TOrderNewPay {
  refid: number = null;
  mode: 'A' | 'E' | string = '';
  hasLog: boolean = false;
  studyId: number = null;
  claimId: number = null;
  orderId: number = null;
  patientname: string = '';
  sheduleservdate: string = '';
  dos_content: string = '';
  facilityid: number = null;
  perdiem: string = '';
  referalname: string = '';
  selstatus: boolean = false;
  selpps: boolean = false;
  selmco: boolean = false;
  selarranged: boolean = false;
  dob: string = '';
  flag: 'A' | string = '';
  units: string = '';
  modifier: string = '';
  icd_code: string = '';
  icd_desc: string = '';
  cptcode: string = '';
  cpt_desc: string = '';
  cptcode_modifier: string = '';
  cpt_price_val: string = '';
  discount_val: string = '';
  primary_ins: 'NA' | string = '';
}

export class TPerDiemRpt {
  index: number = 0;
  name: string = 'NA';
  fee: string = '0.00';
  price: string = '0.00';
  total: string = '';
  payment: string = '0.00';
  writeoff: string = '0.00';
  adjcode: string = '0';
  balance: string = '';
}

export class TFacilityData {
  facility_nm: string = '';
  perdiem_payment: string = '';
  perdiem_writeoff: string = '';
  perdiem_adjustcode: string = '';
  invoice_notes: string = '';
  bill_phone: string = '';
  perdiem_rptrvalue: Array<TPerDiemRpt> = [];
}

class FacilityPreviewService {
  loadOverview(
    facilityId: number,
    invoiceNo: number,
    invoiceEditFlag: 'I' | string,
    perDiem: string,
    dosFrom: string,
    dosTo: string,
    invoiceType: 'H' | '' | string,
    pdfFlag: boolean,
    lastName: string,
    firstName: string,
    patientId: number,
    orderIds: string
  ) {
    const data = {
      facilityId,
      invoiceNo,
      invoiceEditFlag,
      perDiem,
      dosFrom,
      dosTo,
      invoiceType,
      pdfFlag,
      lastName,
      firstName,
      patientId,
      orderIds,
    };

    return apiRequest<'SE' | Array<TOrderNewPay>>({
      url: 'facility.FacilityBilling.OrderNewPayList1',
      data,
    });
  }

  loadFacilityData(data: { facilityId: number; invoiceNo: number }) {
    return apiRequest<Array<TFacilityData>>({
      url: 'facility.FacilityBilling.Getfacilitydetail_preinvoice',
      data,
    });
  }
}

export default new FacilityPreviewService();
