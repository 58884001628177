import { PureInput } from 'components/form/textField';
import { PureRadio } from 'components/form/radio';

import RadioOptions from 'components/project/common/RadioOptions';

export interface FilterType {
  name: string;
  state: 'A' | 'I';
  isActive: boolean;
}

interface PropsType {
  placeholder?: string;
  filter: FilterType;
  fetching: boolean;
  onChangeFilter: (filter: FilterType) => void;
}

const FilterNameAndStatus = ({
  filter,
  fetching,
  placeholder,
  onChangeFilter,
}: PropsType) => {
  const handleChangeName = (name: string) => {
    const data = {
      ...filter,
      name,
    };
    onChangeFilter(data);
  };

  const handleChangeState = (state: 'A' | 'I') => {
    const data = {
      ...filter,
      state,
      isActive: state === 'A',
    };
    onChangeFilter(data);
  };

  return (
    <div className="row">
      <PureInput
        name="name"
        className="col-md-6 col-lg-4"
        placeholder={placeholder}
        value={filter.name}
        onChange={handleChangeName}
      />
      <PureRadio
        name="state"
        className="col-md-6 col-lg-auto inline"
        disabled={fetching}
        options={RadioOptions.active}
        value={filter.state}
        onChange={handleChangeState}
      />
    </div>
  );
};

export default FilterNameAndStatus;
