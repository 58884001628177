import Collapse from 'components/collapse';
import InsuranceDetailsBlock from './InsuranceBlock';

import { InfoType } from 'stores/_mobx/billingCodding/claimReview';

interface PropsType {
  insurances?: InfoType['insurances'];
}

const InsurancesTab = ({ insurances }: PropsType) =>
  insurances ? (
    <div style={{ minHeight: '256px' }}>
      <Collapse title="Primary Insurance" isCollapsed={false}>
        {insurances.primary ? (
          <InsuranceDetailsBlock insurance={insurances.primary} />
        ) : (
          'No information about Primary insurance'
        )}
      </Collapse>
      <Collapse title="Secondary Insurance">
        {insurances.secondary ? (
          <InsuranceDetailsBlock insurance={insurances.secondary} />
        ) : (
          'No information about Secondary insurance'
        )}
      </Collapse>
      <Collapse title="Tertiary Insurance">
        {insurances.tertiary ? (
          <InsuranceDetailsBlock insurance={insurances.tertiary} />
        ) : (
          'No information about Tertiary insurance'
        )}
      </Collapse>
    </div>
  ) : null;

export default InsurancesTab;
