import React from 'react';
import { Prompt, Link, RouteComponentProps } from 'react-router-dom';

import { LayoutSideTitle } from 'components/layout';
import Tabs from 'components/tabs';
import UrlUtils from 'utils/UrlUtils';
import Text from 'components/form/input/Text';
import PatientInformationContainer, {
  PatientInformation,
  SPatientInformation,
} from 'page/workflow/order/order/PatientInformation';
import { TPiModel } from 'page/workflow/order/order/PatientInformationModel';
import ExamInformation, {
  SExamInformation,
} from 'page/workflow/order/order/ExamInformation';
import PhysicianInformationContainer, {
  SPhysicianInformation,
} from 'page/workflow/order/order/PhysicianInformation';
import EditOrderFormPage from 'page/workflow/order/EditOrderFormPage';
import OrderFormPageStore from 'stores/workflow/order/OrderFormPageStore';
import OrderFormPageActions from 'actions/workflow/order/OrderFormPageActions';
import OrderTabStateStore from 'stores/workflow/order/OrderTabStateStore';
import OrderTabStateActions from 'actions/workflow/order/OrderTabStateActions';
import { TPatientRecord } from 'services/patient-records/patient-demographics/PatientRecordPageService';
import {
  TFacilityAddressExt,
  TPhysicianDetail,
} from 'services/workflow/order/OrderFormPageService';
import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';
import {
  URL_ORDER_BASE,
  URL_ORDER_PLACED,
  URL_ORDER_ADD,
} from 'constant/path/workflow';
import { Button } from 'components/button';

let ORDER_PLACE_HELP: boolean = true;

const LEAVE_MESSAGE =
  'Are you sure you want to navigate away from placing this order? All changes will be lost.';

try {
  ORDER_PLACE_HELP = JSON.parse(localStorage.getItem('ORDER_PLACE_HELP'));
  if (ORDER_PLACE_HELP !== false && ORDER_PLACE_HELP !== true) {
    ORDER_PLACE_HELP = true;
  }
} catch (e) {}

export interface PAbstractOrderFormPage extends RouteComponentProps {
  onEdit?: boolean;
  submitCaller?: () => void;
  noPadding?: boolean;
  hideTitle?: boolean;
  params?: { id: number; facilityId: number };
  order?: TSignOrderItem;
  backToListCallBack?: () => void;
  handleUpdateOrder?: (formData: SExamInformation['model']) => void;
  handleUpdatePatient?: (formData: SPatientInformation['model']) => void;
}

export class SAbstractOrderFormPage {
  onAjax: boolean = false;
  selectedTab: number = 0;
  isOrderEditPage: boolean = false;
  orderStatePatientTab1: SPatientInformation = null;
  orderStateExamTab2: SExamInformation = null;
  orderStatePhysicianTab3: SPhysicianInformation = null;
  callerNameErrors: Array<any> = [];
  callerName: string = '';
  orderId: number = 0;
  confirmFaxModal: boolean = false;
  popupOpen: boolean = false;
  tooltip: {
    examTab: boolean;
    callerName: boolean;
  } = {
    examTab: ORDER_PLACE_HELP,
    callerName: ORDER_PLACE_HELP,
  };
  facilityAddress: TFacilityAddressExt = new TFacilityAddressExt();
  patientLoaded: boolean = false;
  patientSelected: boolean = false;
  physicianDetails: TPhysicianDetail = new TPhysicianDetail();
  lastModifiedLock: string = '';
}

export default class AbstractOrderFormPage<
  P extends PAbstractOrderFormPage,
  S extends SAbstractOrderFormPage
> extends React.Component<P, S> {
  backUrl: string = null;

  constructor(props: P) {
    super(props);
    OrderFormPageActions.clearOrderInfo();
    OrderFormPageActions.clearPatientInfo();
    this.state = Object.assign(
      new SAbstractOrderFormPage(),
      this.state,
      new SAbstractOrderFormPage(),
      {
        lastModifiedLock: (props.order || { lastModifiedLock: '' })
          .lastModifiedLock,
      }
    );
    this.backUrl = UrlUtils.getQuery('backUrl');
  }

  update() {
    OrderFormPageActions.loadFacilityWithoutInactiveList(0);
  }

  static calculateState(
    prevState: SAbstractOrderFormPage,
    props: PAbstractOrderFormPage,
    isOnEdit: boolean
  ) {
    const orderFormPageStore = OrderFormPageStore.getState();
    const isOrderEditPage = isOnEdit;
    if (!prevState) {
      const physicianDetails = orderFormPageStore.physicianDetails;
      const callerName =
        physicianDetails && physicianDetails.callerName
          ? physicianDetails.callerName
          : '';
      prevState = Object.assign(new SAbstractOrderFormPage(), {
        isOrderEditPage,
        callerName,
        lastModifiedLock: ((props && props.order) || { lastModifiedLock: '' })
          .lastModifiedLock,
      });
    }

    return Object.assign({
      ...prevState,
      patientLoaded: !isOrderEditPage ? true : orderFormPageStore.patientLoaded,
      physicianDetails: orderFormPageStore.physicianDetails,
      physicianDetailsLoaded: orderFormPageStore.physicianDetailsLoaded,
    });
  }

  static getStores() {
    return [OrderFormPageStore];
  }

  componentWillUnmount() {
    OrderTabStateActions.initTabs();
    OrderFormPageActions.clearOrderInfo();
    OrderFormPageActions.clearOrderTypeByFacility();
    OrderFormPageActions.clearFacilityAddress();
    OrderFormPageActions.clearExamDetails();
  }

  componentDidMount() {
    const tooltip = this.refs.tab_tooltip;
    if (tooltip) {
      (tooltip as React.Component).forceUpdate();
    }
    if (!this.props.onEdit) {
      OrderTabStateActions.initTabs();
    }
    this.update();
  }

  getCallerName() {
    return this.state.callerName;
  }

  submitPatientInfo(orderId: number, tabState: number) {
    this.setState({ selectedTab: 1 });
  }

  goForwardFromTab1(orderId: number, state: SPatientInformation) {
    OrderTabStateActions.unblockTab(1);
    this.setState({
      selectedTab: 1,
      orderId: orderId,
      orderStatePatientTab1: state,
      onAjax: false,
    });
  }

  goForwardFromTab2(orderId: number, state: SExamInformation) {
    OrderTabStateActions.unblockTab(2);
    this.setState({
      selectedTab: 2,
      orderId: orderId,
      orderStateExamTab2: state,
      onAjax: false,
    });
  }

  submitCaller(response?: any, state?: any) {
    if (this.props.submitCaller) {
      this.props.submitCaller();
    } else {
      this.props.history.push(URL_ORDER_BASE);
    }
  }

  onNewOrder() {
    this.reset(() => {
      OrderFormPageActions.clearOrderInfo();
    });
  }

  onSamePatient() {
    this.reset(() => {
      OrderFormPageActions.clearForSamePatient();
      this.updatePatientTab(true);
    });
  }

  onSameAsFacility() {
    this.reset(() => {
      OrderFormPageActions.clearForSameFacility();
      this.updatePatientTab(false);
    });
  }

  updatePatientTab(updatePatient: boolean) {
    const tab: PatientInformation = this.refs.patientTab as PatientInformation;
    if (tab) {
      const store = OrderFormPageStore.getState();
      let model: TPiModel = { ...tab.state.model };
      model.facility = parseInt('' + store.orderDataPatient.facilityid, 10);
      if (updatePatient) {
        const patient: TPatientRecord = store.patientViewInfo[0];
        if (patient) {
          model = { ...PatientInformation.addPatientToModel(patient, model) };
        }
      }
      tab.setState({ model, patientSelected: updatePatient }, () => {
        tab.updateFacilityInfo();
      });
    }
  }

  reset(clb: () => void) {
    const resetState = EditOrderFormPage.calculateState(null);
    OrderTabStateActions.initTabs();
    this.setState({ ...resetState, callerName: this.state.callerName }, () => {
      this.selectTab(0, 3, clb);
    });
  }

  onPopupOpen = (popupOpen: boolean) => {
    this.setState({ popupOpen });
  };

  selectTab = (
    selectedTab: number,
    prevTab: number,
    clb: () => void = null
  ) => {
    this.setState({ selectedTab }, clb);
  };

  renderTabsList = () => {
    const { props } = this;
    return [
      { label: 'Patient Information' },
      {
        label: 'Exam Information',
        disabled: !props.onEdit && !OrderTabStateStore.getTabState(1),
      },
      {
        label: 'Physician Information',
        disabled: !props.onEdit && !OrderTabStateStore.getTabState(2),
      },
    ];
  };

  renderButtons = () => {
    const { state } = this;

    const url = this.backUrl || URL_ORDER_PLACED;
    return (
      <>
        <div className="line-form">
          <Text
            name="callerName"
            className={
              'caller-name-input ' +
              (ORDER_PLACE_HELP && !state.callerName
                ? 'warmInputBackground'
                : '')
            }
            onSetValue={(n, v, e) =>
              this.setState({ callerName: v, callerNameErrors: e })
            }
            value={state.callerName}
            validations={state.selectedTab === 2 ? 'required' : ''}
            errors={state.callerNameErrors}
            tooltip={{
              theme: 'dark',
              visible:
                ORDER_PLACE_HELP && !state.popupOpen && !state.callerName,
              overlay:
                'Enter the name of the person calling to place the order here',
            }}
          />
        </div>
        <Button
          text={ORDER_PLACE_HELP ? 'Hide Help' : 'Show Help'}
          className="width-100"
          onClick={() => {
            ORDER_PLACE_HELP = !ORDER_PLACE_HELP;
            try {
              localStorage.setItem('ORDER_PLACE_HELP', '' + ORDER_PLACE_HELP);
            } catch (e) {}
            this.forceUpdate();
          }}
        />
        <Link
          className="btn btn-danger"
          to={url.startsWith('/') ? url : `/${url}`}>
          Back to List
        </Link>
      </>
    );
  };

  render() {
    const { props, state } = this;

    const tabClass = state.patientLoaded ? '' : 'on-ajax';

    const callerName = this.getCallerName();

    const allowSubmit = props.onEdit
      ? Boolean(callerName)
      : state.callerNameErrors.length === 0;

    const tabsList = this.renderTabsList();

    return (
      <div className={'orders-page' + (props.noPadding ? '' : ' col-sm-12')}>
        <Prompt
          message={(location) =>
            location.pathname !== URL_ORDER_ADD &&
            // @ts-ignore
            !this.flowFinished &&
            !props.onEdit
              ? LEAVE_MESSAGE
              : true
          }
        />

        {props.hideTitle ? null : (
          <LayoutSideTitle title="Place Order">
            {this.renderButtons()}
          </LayoutSideTitle>
        )}

        <Tabs
          tabsList={tabsList}
          onClick={this.selectTab}
          defaultTabIndex={this.state.selectedTab}
          className="mb-4">
          <div className={tabClass}>
            <PatientInformationContainer
              ref="patientTab"
              onEdit={props.onEdit || state.patientSelected}
              cachedState={state.orderStatePatientTab1}
              allowSubmit={allowSubmit}
              ins_notes={(props.order || { ins_notes: '' }).ins_notes}
              lastModifiedLock={state.lastModifiedLock}
              orderId={parseInt(
                '' +
                  (props.params && props.params?.id
                    ? props.params.id
                    : state.orderId)
              )}
              callerName={callerName}
              showToolTips={ORDER_PLACE_HELP}
              onPopupOpen={this.onPopupOpen}
              flagOrderEdit={state.isOrderEditPage}
              backToListCallBack={props.backToListCallBack}
              submitSuccess={(
                orderId: number,
                state: SPatientInformation,
                lastModifiedLock: string
              ) => {
                this.setState(
                  {
                    facilityAddress: state.facilityAddress,
                    lastModifiedLock: lastModifiedLock,
                  },
                  () => {
                    this.goForwardFromTab1(orderId, state);
                    if (this.props.handleUpdatePatient)
                      this.props.handleUpdatePatient(state.model);
                  }
                );
              }}
            />
          </div>

          <div className={tabClass}>
            <ExamInformation
              cachedState={state.orderStateExamTab2}
              lastModifiedLock={state.lastModifiedLock}
              onEdit={props.onEdit}
              showToolTips={ORDER_PLACE_HELP}
              allowSubmit={allowSubmit}
              callerName={callerName}
              history={this.props.history}
              onPopupOpen={(v: boolean) => this.onPopupOpen(v)}
              orderId={parseInt(
                '' +
                  (props.params && props.params.id
                    ? props.params.id
                    : state.orderId)
              )}
              onGoBack={() => this.setState({ selectedTab: 0 })}
              backToListCallBack={props.backToListCallBack}
              submitSuccess={(
                orderId: number,
                state: SExamInformation,
                lastModifiedLock: string
              ) => {
                this.setState({ lastModifiedLock }, () => {
                  this.goForwardFromTab2(orderId, state);
                  if (this.props.handleUpdateOrder)
                    this.props.handleUpdateOrder(state.model);
                });
              }}
            />
          </div>

          <div className={tabClass}>
            <PhysicianInformationContainer
              cachedState={state.orderStatePhysicianTab3}
              lastModifiedLock={state.lastModifiedLock}
              onEdit={props.onEdit}
              callerName={callerName}
              showToolTips={ORDER_PLACE_HELP}
              allowSubmit={allowSubmit}
              onPopupOpen={(v: boolean) => this.onPopupOpen(v)}
              orderId={parseInt(
                '' +
                  (props.params && props.params.id
                    ? props.params.id
                    : state.orderId)
              )}
              physicianDetails={state.physicianDetails}
              onGoBack={() => this.setState({ selectedTab: 1 })}
              submitSuccess={(
                orderId?: number,
                state?: SPhysicianInformation,
                lastModifiedLock?: string
              ) => {
                this.setState(
                  {
                    lastModifiedLock:
                      lastModifiedLock || this.state.lastModifiedLock,
                  },
                  () => this.submitCaller()
                );
              }}
              newOrderCallback={() => this.onNewOrder()}
              onSamePatient={() => this.onSamePatient()}
              onSameAsFacility={() => this.onSameAsFacility()}
              backToListCallBack={props.backToListCallBack}
            />
          </div>
        </Tabs>
      </div>
    );
  }
}
