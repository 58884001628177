import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction,
} from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import { dateToLocalTimezone } from 'utils/DateUtils';

export const defaultFilter: FilterType = {
  name: '',
  date: '',
  equipmentType: {
    device: false,
    ekg: false,
    genor: false,
    plate: false,
    xray: false,
  },
};

export interface FilterType {
  name: string;
  date: string;
  equipmentType: {
    device: boolean;
    ekg: boolean;
    genor: boolean;
    plate: boolean;
    xray: boolean;
  };
}

export interface RecordType {
  calibration_date: string;
  calibration_due_date: string;
  comments: string;
  description: string;
  device_name: string;
  image_path: string;
  name: string;
  refid: number;
  serial_no: string;
  start_date: string;
  type: string;
  type_val: string;
  unique_id: string;
}

interface NoteResponseType {
  bgcolor: string;
  date: string;
  name: string;
  notes: string;
  time: string;
  createdDate: string;
}

interface NoteOptionsType {
  id: number;
  type: string;
}

export interface CalibrationDateType {
  nextCalibrationDate: Date | string;
  calibrationDate: Date | string;
  type: string;
  refId: number;
}

export interface NoteType {
  date: string;
  message: string;
  user: string;
}

class CalibrationRecords {
  fetching: boolean = false;
  fetchingNote: boolean = false;
  records: RecordType[] = [];
  notes: NoteType[] | null = null;
  notesOptions: NoteOptionsType | null = null;
  filterMaintenance: FilterType = defaultFilter;
  filterMaintenanceDue: FilterType = defaultFilter;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingNote: observable,
      records: observable,
      filterMaintenance: observable,
      filterMaintenanceDue: observable,
      notes: observable,

      notesTotal: computed,

      setFetching: action,
      setRecords: action,
      setFilter: action,
      setNote: action,
      setFetchingNote: action,
      clearNote: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setRecords(records: RecordType[]) {
    this.records = records;
  }

  setFilter(
    filter: FilterType,
    key: 'filterMaintenance' | 'filterMaintenanceDue'
  ) {
    this[key] = filter;
  }

  setFetchingNote(fetching: boolean) {
    this.fetchingNote = fetching;
  }

  setNote(notes: NoteType[]) {
    this.notes = notes;
  }

  clearNote() {
    this.notes = null;
    this.notesOptions = null;
  }

  get notesTotal() {
    return this.notes?.length || 0;
  }

  async getCalibrationRecords(type: 'M' | 'D') {
    const key = type === 'M' ? 'filterMaintenance' : 'filterMaintenanceDue';

    const { name, date, equipmentType } = this[key];

    const data = [type, name, date, equipmentType];

    this.setFetching(true);

    try {
      const response = await apiRequest<RecordType[] | 'SE'>({
        url: 'vehicle.EquipmentMaster.GetAllEquipMentList',
        data,
      });

      if (Array.isArray(response)) {
        const records = response.map((record) => ({
          ...record,
          refid: Number(record.refid),
        }));

        this.setRecords(records);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e: any) {
      this.setRecords([]);
    } finally {
      this.setFetching(false);
    }
  }

  async getNotes(id: number, type: string) {
    runInAction(() => {
      this.notes = [];
      this.fetchingNote = true;
      this.notesOptions = { id, type };
    });

    try {
      const response = await apiRequest<NoteResponseType[] | 'SE'>({
        url: 'vehicle.EquipmentMaster.GetNotes',
        data: [id, type],
      });
      if (Array.isArray(response)) {
        const notes = response.map((note) => ({
          date: dateToLocalTimezone({
            date: note.createdDate,
          }),
          message: note.notes,
          user: note.name,
        }));

        this.setNote(notes);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e: any) {
      runInAction(() => {
        this.notes = null;
        this.notesOptions = null;
      });
    } finally {
      this.setFetchingNote(false);
    }
  }

  async addNote(userId: number, message: string) {
    const { id, type } = this.notesOptions || {};

    const data = [id, type, userId, message];

    this.setFetchingNote(true);

    try {
      const response = await apiRequest<string>({
        url: 'vehicle.EquipmentMaster.AddNotes',
        data,
      });
      if (response === 'S') {
        this.clearNote();
        Notification.success('Message saved');
      } else {
        throw Error("Message doesn't saved");
      }
    } catch (e: any) {
      Notification.warning("Message doesn't saved");
    } finally {
      this.setFetchingNote(false);
    }
  }

  updateCalibrationDate = async ({
    calibrationDate,
    nextCalibrationDate,
    ...payload
  }: CalibrationDateType) => {
    const data = [
      dateToLocalTimezone({
        date: calibrationDate,
        dateOnly: true,
      }),
      dateToLocalTimezone({
        date: nextCalibrationDate,
        dateOnly: true,
      }),
      payload.refId,
      payload.type,
    ];

    try {
      const response = await apiRequest({
        url: 'vehicle.EquipmentMaster.UpdateCalibration',
        data,
      });

      if (response === 'S') {
        Notification.success('Successfully saved!');
        return true;
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger('An error occurred!');
      return false;
    }
  };
}

export const storeCalibrationRecords = new CalibrationRecords();
