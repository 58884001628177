import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import {
  storeEquipment,
  EquipmentFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/other';
import { URL_OTHER_EQUIPMENT } from 'constant/path/workflow';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const OtherEquipmentEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, equipment } = storeEquipment;

  const handleSubmit = async (data: EquipmentFormModel) =>
    storeEquipment.updateEquipment(data).then((error) => {
      if (error) return error;
      history.push(URL_OTHER_EQUIPMENT);
    });

  useEffect(() => {
    if (!fetching) storeEquipment.getEquipment(Number(params.id));

    return storeEquipment.clearEquipment;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Other Equipment" />
      <Form
        backUrl={URL_OTHER_EQUIPMENT}
        defaultValues={equipment}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(OtherEquipmentEditPage);
