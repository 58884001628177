import { apiRequest } from 'services/RequestService';
import { exchange } from 'constant/config';
import appDispatcher from 'dispatcher/AppDispatcher';

export default class ExternalService {
  static NOT_AUTHORISED = { status: 'not authorized' };

  static authRequest: Promise<{ status: string }> = null;

  static doPut<TResult>(
    url: string,
    data?: any
  ): Promise<TResult | { status: string }> {
    return ExternalService._raw<TResult | { status: string }>(
      'PUT',
      url,
      data
    ).catch(() =>
      ExternalService.processResponse().then((isUserAuthorized) => {
        if (isUserAuthorized) return ExternalService._raw('PUT', url, data);
        return Promise.resolve(ExternalService.NOT_AUTHORISED);
      })
    );
  }

  static doGet<TResult>(url: string): Promise<TResult | { status: string }> {
    return ExternalService._raw<TResult | { status: string }>('GET', url).catch(
      () =>
        ExternalService.processResponse().then((isUserAuthorized) => {
          if (isUserAuthorized) return ExternalService._raw('GET', url);
          return Promise.resolve(ExternalService.NOT_AUTHORISED);
        })
    );
  }

  static doPost<TResult>(
    url: string,
    data?: any
  ): Promise<TResult | { status: string }> {
    return ExternalService._raw<TResult | { status: string }>(
      'POST',
      url,
      data
    ).catch(() =>
      ExternalService.processResponse().then((isUserAuthorized) => {
        if (isUserAuthorized) return ExternalService._raw('POST', url, data);
        return Promise.resolve(ExternalService.NOT_AUTHORISED);
      })
    );
  }

  static processResponse() {
    ExternalService.authRequest = ExternalService.authRequest
      ? ExternalService.authRequest
      : ExternalService.performDefaultAuth();
    return ExternalService.authRequest.then((response) =>
      Promise.resolve(response.status === 'Success')
    );
  }

  static performDefaultAuth() {
    return apiRequest<['Success' | 'Error']>({
      url: 'usermaster.StaffInfo.getHl7Credentials',
    })
      .then(([status]) => {
        if (status === 'Success') {
          appDispatcher.dispatch({
            type: 'hl7-integration-authorization',
            data: true,
          });
          return { status };
        }
        throw Error;
      })
      .catch(() => {
        appDispatcher.dispatch({
          type: 'hl7-integration-authorization',
          data: false,
        });
        return Promise.resolve(ExternalService.NOT_AUTHORISED);
      });
  }

  static _raw<TResult>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    url: string,
    data?: any
  ): Promise<TResult> {
    return fetch(`${exchange}${url}`, {
      method,
      body:
        typeof data === 'string' || data === undefined
          ? data
          : JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json());
  }
}
