import React from 'react';

import Checkbox from 'components/form/input/Checkbox';
import Text from 'components/form/input/Text';
import StringUtils from 'utils/StringUtils';
import Dialog, { DialogHeader, DialogBody } from 'components/modal/dialog';

export class TColumn {
  name: string;
  title?: string;
  shownCheckboxTitle?: string;
}

interface PropsType {
  title: string;
  columns: Array<TColumn>;
  shownColumns: Map<string, boolean>;
  onShownColumnsChange: (shownColumns: Map<string, boolean>) => void;
  onClose: () => void;
}

class StateType {
  filter: string = '';
  columns: Array<TColumn> = [];
  shownColumns: Record<string, boolean> = {};
  flagAllSelected: boolean = false;

  constructor(
    parent: DialogColumnsSwitcher,
    isAllSelected: boolean,
    shownColumns: Record<string, boolean> = {}
  ) {
    this.columns = parent.props.columns;
    this.shownColumns = shownColumns;
    this.flagAllSelected = isAllSelected;
  }
}

class DialogColumnsSwitcher extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    const shownColumns: Record<string, boolean> = Object.fromEntries(
      props.shownColumns.entries()
    );

    this.state = new StateType(
      this,
      this.isAllSelected(props.columns, shownColumns),
      shownColumns
    );
  }

  isAllSelected = (columns: Array<TColumn>, obj?: Record<string, boolean>) => {
    const columnObj = obj || this.state.shownColumns;
    for (let i = 0; i < columns.length; i++) {
      if (columnObj[columns[i].name] === false) {
        return false;
      }
    }
    return true;
  };

  selectColumn = (name: string, value: boolean) => {
    const shownColumns = Object.assign(this.state.shownColumns, {
      [name]: value,
    });
    this.setState({
      shownColumns,
      flagAllSelected: this.isAllSelected(this.state.columns, shownColumns),
    });
  };

  setFilter = (filter: string) => {
    this.setState({ filter });
  };

  onSelectAll = (name: string, value: boolean) => {
    const shownColumns = this.state.columns.reduce(
      (prev: Record<string, boolean>, cur) => {
        prev[cur.name] = value;
        return prev;
      },
      {}
    );

    this.setState({ flagAllSelected: value, shownColumns, filter: '' });
    this.handleOnShowColumnsChange(shownColumns);
  };

  onShowCheckBox = (name: string, value: boolean) => {
    const { shownColumns } = this.state;
    this.selectColumn(name, value);
    this.handleOnShowColumnsChange(shownColumns);
  };

  handleOnShowColumnsChange = (shownColumnsObj: Record<string, boolean>) => {
    const shownColumns = new Map();
    Object.entries(shownColumnsObj).forEach(([key, value]) => {
      shownColumns.set(key, value);
    });
    this.props.onShownColumnsChange(shownColumns);
  };

  wrapChildren(): React.ReactElement {
    const { state } = this;
    const lowerCaseFilter = state.filter.toLowerCase();
    let wrappers = [];
    for (let i = 0; i < state.columns.length; i++) {
      let column = state.columns[i];
      if (column.name === 'control' && !column.title) {
        continue;
      }
      let label: string = '';
      if (column.shownCheckboxTitle) {
        label = column.shownCheckboxTitle;
      } else if (column.title) {
        label = column.title;
      } else {
        label = StringUtils.normalizeText(column.name);
      }
      if (
        this.state.filter !== '' &&
        (!label.toLowerCase ||
          label.toLowerCase().indexOf(lowerCaseFilter) === -1)
      ) {
        continue;
      }
      wrappers.push({
        lcLabel: label.toLowerCase(),
        label,
        column,
      });
    }
    wrappers = wrappers.sort((a, b) => a.lcLabel.localeCompare(b.lcLabel));

    return (
      <div key="content" className="row">
        {wrappers.map(({ label, column }, i) => (
          <Checkbox
            key={i}
            name={column.name}
            label={label}
            className="col-sm-4"
            onSetValue={this.onShowCheckBox}
            checked={state.shownColumns[column.name] !== false}
          />
        ))}
      </div>
    );
  }

  render() {
    return (
      <Dialog size="large" handleClose={this.props.onClose}>
        <DialogHeader title={this.props.title} onClose={this.props.onClose} />
        <DialogBody>
          <div className="row">
            <Checkbox
              name="selectAll"
              value={this.state.flagAllSelected ? 1 : 0}
              className="col-sm-4 control-input"
              onSetValue={this.onSelectAll}
            />
            <Text
              name="filter"
              noLabel
              attr={{ autoFocus: true, placeholder: 'Search for column' }}
              value={this.state.filter}
              className="col-sm-8 control-filter"
              onSetValue={(n: any, v: string) => this.setFilter(v)}
              onKeyDown={(event: React.KeyboardEvent) => {
                if (event.key === 'Escape' || event.key === 'Enter') {
                  this.props.onClose();
                }
              }}
            />
          </div>
          <div className="row">{this.wrapChildren()}</div>
        </DialogBody>
      </Dialog>
    );
  }
}

export default DialogColumnsSwitcher;
