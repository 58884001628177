import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import {
  TUserType,
  TUserTypeEnum,
  TLabelValue,
  TUserName,
} from 'services/project/UserSelectionService';

class SUserSelectionStore {
  recipients: Array<TUserName> = [];
  userGroups: Array<TLabelValue> = [];
  userTypes: Array<TUserType> = [];
  companyUserTypes: Array<TUserType> = [];
  clientUserTypes: Array<TUserType> = [];
  count: number = 0;
  usersList: Array<TLabelValue> = [];
}

class UserSelectionStore extends ReduceStore<
  SUserSelectionStore,
  CommonPayload
> {
  getInitialState() {
    const state = new SUserSelectionStore();
    return state;
  }

  reduce(state: SUserSelectionStore, action: CommonPayload) {
    switch (action.type) {
      case 'user-selection-load-recipients':
        if (action.data && action.data.length) {
          this.getState().recipients = action.data;
        } else {
          this.getState().recipients = [];
        }
        break;
      case 'user-selection-load-user-types':
        if (action.data && action.data.length) {
          this.getState().userTypes = action.data;
          this.getState().companyUserTypes = [];
          this.getState().clientUserTypes = [];
          action.data.forEach((item: TUserType) => {
            if ('' + item.type === '0' || item.type === '') {
              this.getState().companyUserTypes.push(item);
            } else {
              this.getState().clientUserTypes.push(item);
            }
          });
        }
        break;
      case 'user-selection-load-user-list':
        this.getState().usersList = action.data;
        break;
      case 'user-selection-custom-user-groups':
        this.getState().userGroups = action.data;
        break;
      default:
        break;
    }
    return state;
  }

  areEqual(one: SUserSelectionStore, two: SUserSelectionStore) {
    return false;
  }

  isTech(userTypeId: string | number): boolean {
    const item = this.findById(userTypeId);
    return item ? '' + item.type === '0' || item.type === '' : false;
  }

  getUserTypeName(userTypeId: string | number): TUserTypeEnum {
    const item = this.findById(userTypeId);
    return item ? item.usertype : null;
  }

  getUserTypeFullName(userTypeId: string | number): string {
    const item = this.findById(userTypeId);
    return item ? item.usertype_name : null;
  }

  findById(userTypeId: string | number): TUserType {
    const types = this.getState().userTypes;
    if (types.length === 0) {
      throw Error('Please instantiate user types at first');
    }
    for (let i = 0; i < types.length; i++) {
      if ('' + types[i].refid === '' + userTypeId) {
        return types[i];
      }
    }
    return null;
  }

  isUserTypesLoaded() {
    return this.getState().userTypes.length > 0;
  }

  getClientUsers() {
    return this.getState().clientUserTypes;
  }

  getCompanyUsers() {
    return this.getState().companyUserTypes;
  }

  findUserByType(usertype: string) {
    const list = this.getState().userTypes;
    if (list.length === 0) {
      throw Error('Please instantiate user types at first');
    }
    let length = list.length;
    while (length--) {
      const type = list[length];
      if ('' + type.usertype === '' + usertype) {
        return type;
      }
    }
    return null;
  }

  findUserType(id: string | number) {
    const list = this.getState().userTypes;
    let length = list.length;
    while (length--) {
      const type = list[length];
      if ('' + type.refid === '' + id) {
        return type;
      }
    }
    return null;
  }
}

const userSelectionStore = new UserSelectionStore(appDispatcher);
export default userSelectionStore;
