import appDispatcher from 'dispatcher/AppDispatcher';

import SummaryTechnologistService from 'services/reports/SummaryTechnologistService';

export default class FacilityExamCountPageActions {
  static loadData(filter, pagination) {
    return SummaryTechnologistService.loadOverview(filter, pagination).then(
      (response) => {
        appDispatcher.dispatch({
          type: 'summarytechnologist_data_overview',
          data: response.data,
          count: response.count,
        });
        return [];
      }
    );
  }

  static clear() {
    return appDispatcher.dispatch({
      type: 'summarytechnologist_data_overview',
      data: [],
      count: 0,
    });
  }

  static createPdf(dataSource, columns) {
    return SummaryTechnologistService.createPdf(dataSource, columns);
  }
}
