import { ReduceStore } from 'flux/utils';
import { Dispatcher } from 'flux';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

const buildActionName = (name: string) => {
  return 'orders/order-information/exam-review/' + name;
};

export const Actions = {
  LOAD_PHYSICIANS_ACTION: buildActionName('load-physicians-action'),
  LOAD_PHYSICIAN_GROUPS_ITEM_ACTION: buildActionName(
    'load-physician-groups-action'
  ),
};

export class PExamReviewStore extends CommonPayload {
  count: number;
}

export class SExamReviewStore {
  dataSource: Array<any> = [];
  dataSourceCount: number = 0;
  physicians: Array<any> = [];
  physiciansCount: number = 0;
  physicianGroups: Array<any> = [];
}

class ExamReviewStore extends ReduceStore<SExamReviewStore, PExamReviewStore> {
  getInitialState() {
    return new SExamReviewStore();
  }

  areEqual(state1: SExamReviewStore, state2: SExamReviewStore) {
    return false;
  }

  reduce(state: SExamReviewStore, action: PExamReviewStore) {
    switch (action.type) {
      case 'orders-order-information-exam-review-overview':
        this.getState().dataSource = action.data;
        this.getState().dataSourceCount = action.count;
        break;
      case Actions.LOAD_PHYSICIANS_ACTION:
        this.getState().physicians = action.data.res;
        this.getState().physiciansCount = action.data.count;
        break;
      case Actions.LOAD_PHYSICIAN_GROUPS_ITEM_ACTION:
        this.getState().physicianGroups = action.data;
        break;
      default:
        break;
    }
    return state;
  }
}

const examReviewStore = new ExamReviewStore(
  appDispatcher as Dispatcher<PExamReviewStore>
);
export default examReviewStore;
