import React from 'react';

import DomUtils from 'utils/DomUtils';

interface PTD {
  shouldUpdate?: (currentProps: PTD, nextProps: PTD) => boolean;
  noOverflow?: boolean;
  render?: (
    value: React.ReactNode,
    row: any,
    index: number
  ) => React.ReactElement;
  value?: React.ReactNode;
  dataRow?: any;
  className?: string;
  colSpan?: number;
  maxWidth?: number;
  index: number;
}

interface State {
  mouseInCell: boolean;
  mouseX: number;
  mouseY: number;
}

export default class TD extends React.Component<PTD, State> {
  cellRef: Element = null;

  state = {
    mouseInCell: false,
    mouseX: 0,
    mouseY: 0,
  };

  onMouseEnter = (e: React.MouseEvent) => {
    e.preventDefault();

    this.setState({
      mouseInCell: true,
      mouseX: e.clientX,
      mouseY: e.clientY,
    });
  };

  wrapGridCellContent(content: React.ReactNode) {
    return (
      <div
        className={this.props.noOverflow ? '' : 'grid-cell-overflow-hidden'}
        onClick={(e) => e.preventDefault()}
        onMouseLeave={() => this.setState({ mouseInCell: false })}
        onMouseEnter={this.onMouseEnter}>
        <div
          className={this.props.noOverflow ? '' : 'grid-cell-content'}
          ref={(cellRef) => {
            this.cellRef = cellRef;
          }}>
          {content}
        </div>
      </div>
    );
  }

  getContent() {
    return this.wrapGridCellContent(
      this.props.render
        ? this.props.render(
            this.props.value,
            this.props.dataRow,
            this.props.index
          )
        : this.props.value
    );
  }

  showHoverPopup() {
    if (!this.state.mouseInCell) {
      return false;
    }
    if (this.cellRef && this.refs.tdRef) {
      let innerCellSize = DomUtils.getInnerSizes(this.cellRef);
      let outerCellSize = DomUtils.getInnerSizes((this.refs as any).tdRef);
      if (innerCellSize.width > outerCellSize.width - 10) {
        return true;
      }
    }
    return false;
  }

  render() {
    const { props } = this;
    return (
      <td
        colSpan={props.colSpan}
        className={props.className}
        style={
          props.maxWidth && { maxWidth: props.maxWidth, width: props.maxWidth }
        }>
        {props.render
          ? props.render(props.value, props.dataRow, props.index)
          : props.value}
      </td>
    );
  }
}
