import React from 'react';
import { Container } from 'flux/utils';

import { GridControlButton } from 'components/grid';
import AbstractOrderOverview, {
  TFilter,
  PAbstractOrderOverview,
  SAbstractOrderOverview,
} from 'page/workflow/order/AbstractOrderOverview';
import OrderOverviewStore from 'stores/workflow/order/OrderOverviewStore';
import CallbackManagerStore from 'stores/workflow/order/CallbackManagerStore';
import UserProfileStore from 'stores/UserProfileStore';
import WorkflowOrderColumnsDescriptor from 'page/workflow/order/WorkflowOrderColumnsDescriptor';
import { storeGrid } from 'stores/_mobx/grid';
import { URL_ORDER_ADD } from 'constant/path/workflow';

export interface PCallbackManager extends PAbstractOrderOverview {}

export class SCallbackManager extends SAbstractOrderOverview {}

const GRID_ID = 'callback_manager_orders_grid';

export class CallbackManager extends AbstractOrderOverview<
  PCallbackManager,
  SCallbackManager
> {
  static TITLE = 'Callback Manager';

  static getStores() {
    return [OrderOverviewStore, CallbackManagerStore];
  }

  static calculateState(prevState: SCallbackManager) {
    if (!prevState) {
      const defaultFilter = {
        ...new TFilter(),
        period: 'A',
        dosStart: '',
        dosEnd: '',
      };

      prevState = {
        ...AbstractOrderOverview.getDefaultState(GRID_ID),
        filter: storeGrid.getFilter(GRID_ID, defaultFilter) as TFilter,
      };
    }
    return {
      ...prevState,
      dataSource: OrderOverviewStore.getState().dataSource,
      dataSourceCount: OrderOverviewStore.getState().dataSourceCount,
    };
  }

  getGridId() {
    return GRID_ID;
  }

  getTitle() {
    return CallbackManager.TITLE;
  }

  prepareFilter() {
    const filter = super.prepareFilter();
    filter.recordsAccess = UserProfileStore.getRecordsAccess();
    filter.dosSearch = 'A';
    filter.status = 'C';
    return filter;
  }

  getShownColumns() {
    return Object.assign(
      [],
      WorkflowOrderColumnsDescriptor.CallbackManagerInitial
    );
  }

  getControlPanel = () => (
    <GridControlButton title="Place Order" url={URL_ORDER_ADD} />
  );

  renderOrderStatus(): React.ReactNode {
    return null;
  }

  getAllowedColumns() {
    return Object.assign(
      [],
      WorkflowOrderColumnsDescriptor.CallbackManagerDefault
    );
  }

  onReset() {
    const filter = {
      ...new TFilter(),
      period: 'A',
      dosStart: '',
      dosEnd: '',
    };

    this.setState({ filter }, this.updateData);
  }
}

const container = Container.create(CallbackManager);
export default container;
