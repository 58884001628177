import { action, makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

const compareObjects = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
) => {
  const keysOfObj1 = Object.keys(obj1);

  const keysOfObj2 = Object.keys(obj2);

  const isLengthEqual = keysOfObj1.length === keysOfObj2.length;

  const isObjectEqual =
    isLengthEqual && keysOfObj1.every((key) => obj2[key] === obj1[key]);

  return isObjectEqual;
};

const defaultSearchQuery: OptionsSearchParams = {
  perDiemOnly: false,
};

interface OptionsSearchParams {
  perDiemOnly?: boolean;
}

export interface OptionsResponse {
  data: string;
  facility_stat: string;
  fax: string;
  label: string;
  phone: string;
  stateid: string;
}

export interface OptionType {
  value: number;
  label: string;
}

class DropdownFacility {
  fetching: boolean = false;
  fetchingActiveOnly: boolean = false;
  optionsSearchParams: OptionsSearchParams = defaultSearchQuery;
  options: OptionType[] = [];
  optionsActiveOnly: OptionType[] = [];
  currentUserFacilityName: string = '';

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingActiveOnly: observable,
      options: observable,
      optionsActiveOnly: observable,
      currentUserFacilityName: observable,

      setFetching: action,
      setFetchingActiveOnly: action,
      setFacilityName: action,
      clearFacilityName: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingActiveOnly(fetching: boolean) {
    this.fetchingActiveOnly = fetching;
  }

  setFacilityName(facilityName: string) {
    this.currentUserFacilityName = facilityName;
  }

  clearFacilityName() {
    this.currentUserFacilityName = '';
  }

  async getOptionsFacility(
    searchQuery: OptionsSearchParams = defaultSearchQuery
  ) {
    const { optionsSearchParams } = this;

    const isSearchQueryEqual = compareObjects(optionsSearchParams, searchQuery);

    if (this.fetching && isSearchQueryEqual) return Promise.resolve();

    try {
      this.setFetching(true);

      const data = [
        0, // corporate id
        0, // state id
        '', // facility name
        '', // phone
        searchQuery.perDiemOnly,
      ];

      const list = await apiRequest<OptionsResponse[]>({
        url: 'facility.FacilityMaster.FacilityDropDown',
        data,
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));
      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }

  // This endpoint not protected
  async getOptionsFacilityPublic() {
    if (this.fetching) return Promise.resolve();
    this.setFetching(true);
    try {
      const list = await apiRequest<OptionsResponse[]>({
        url: 'facility.FacilityMaster.facilityDropDownNew',
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));
      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }

  async getOptionsActiveFacility() {
    if (this.fetchingActiveOnly) return Promise.resolve();
    this.setFetchingActiveOnly(true);
    try {
      const list = await apiRequest<OptionsResponse[]>({
        url: 'facility.FacilityMaster.FacilityDropDownWithoutInactive',
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));
      runInAction(() => {
        this.fetchingActiveOnly = false;
        this.optionsActiveOnly = options;
      });
    } catch {
      runInAction(() => {
        this.fetchingActiveOnly = false;
        this.optionsActiveOnly = [];
      });
    }
  }

  async getFacilityName(facilityId: number) {
    try {
      const facilityName = await apiRequest<string>({
        url: 'facility.FacilityMaster.GetFacilityName',
        data: [facilityId],
      });

      this.setFacilityName(facilityName);
    } catch (e: any) {
      this.setFacilityName('');
    }
  }

  findOption = (id: number) =>
    this.options.find(({ value }) => value === id) ||
    this.optionsActiveOnly.find(({ value }) => value === id);
}

export const storeDropdownFacility = new DropdownFacility();
