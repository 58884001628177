import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import GridActionControls from 'page/components/GridActionControls';
import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import Filter from './components/Filter';

import {
  storeJurisdiction,
  JurisdictionType,
  FilterType,
} from 'stores/_mobx/systemSetup/masterSetting/jurisdiction';
import { MS_JURISDICTION as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_JURISDICTION,
  URL_JURISDICTION_ADD,
} from 'constant/path/systemSetup';

const columns = [
  { name: 'state', title: 'State' },
  { name: 'area', title: 'Area' },
  { name: 'zipcode', title: 'Zip Code' },
  { name: 'zipcoderange', title: 'Zip code range' },
  {
    name: 'control',
    title: 'Action',
    className: 'width-100',
    render: (value: undefined, { refid }: JurisdictionType) => (
      <GridActionControls
        id={refid}
        url={`${URL_JURISDICTION}/${refid}`}
        onDelete={storeJurisdiction.setIdForDelete}
      />
    ),
  },
];

const JurisdictionListPage = () => {
  const {
    fetching,
    jurisdictionsList,
    jurisdictionsTotal,
    idForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeJurisdiction;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleFilterChange = (filter: FilterType) => {
    storeJurisdiction.setFilter(filter);

    debouncedMethod();
  };

  const handleApproveDelete = () => {
    storeJurisdiction.deleteJurisdiction().then((isSucceed) => {
      if (isSucceed) storeJurisdiction.getJurisdictionList();
    });
  };

  useEffect(() => {
    storeJurisdiction.getJurisdictionList();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Jurisdiction">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <Filter onSubmit={handleFilterChange} />

      <Grid
        id="jurisdictionGrid"
        columns={columns}
        onAjax={fetching}
        dataSource={jurisdictionsList}
        dataSourceCount={jurisdictionsTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_JURISDICTION_ADD} />
        }
      />
      {idForDelete ? (
        <DialogConfirm
          onApprove={handleApproveDelete}
          onCancel={storeJurisdiction.clearIdForDelete}>
          Are you sure you want to delete this Jurisdiction?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(JurisdictionListPage);
