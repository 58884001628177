import { ReduceStore } from 'flux/utils';
import appDispatcher from 'dispatcher/AppDispatcher';
import { dateToLocalTimezone } from 'utils/DateUtils';

export const Actions = {
  LOAD_SUMMARY_LIST: 'load-summary-list',
};

class ProcedureSummaryPageStore extends ReduceStore {
  getInitialState() {
    return {
      resultSubList: [],
      resultListCount: 0,
    };
  }

  areEqual(one, two) {
    return false;
  }

  reduce(state, action) {
    switch (action.type) {
      case Actions.LOAD_SUMMARY_LIST:
        this.getState().resultSubList = action.subList.map((log) => ({
          ...log,
          dos: dateToLocalTimezone({ date: log.dos, dateOnly: true }),
        }));
        this.getState().resultListCount = action.listCount;
        break;
      default:
        break;
    }
    return this.getState();
  }
}

const procedureSummaryPageStore = new ProcedureSummaryPageStore(appDispatcher);
export default procedureSummaryPageStore;
