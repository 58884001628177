import { Dispatcher } from 'flux';
import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

export class PUnassignedOrdersStore extends CommonPayload {
  count: number = 0;
}

export class SUnassignedOrdersStore {
  dataSource: Array<any> = [];
  dataSourceCount: number = 0;
}

class UnassignedOrdersStore extends ReduceStore<
  SUnassignedOrdersStore,
  PUnassignedOrdersStore
> {
  getInitialState() {
    return new SUnassignedOrdersStore();
  }

  areEqual(state1: SUnassignedOrdersStore, state2: SUnassignedOrdersStore) {
    return false;
  }

  reduce(state: SUnassignedOrdersStore, action: PUnassignedOrdersStore) {
    switch (action.type) {
      case 'orders-order-information-unassigned-orders-overview':
        this.getState().dataSource = action.data;
        this.getState().dataSourceCount = action.count;
        break;
      default:
        break;
    }
    return state;
  }
}

const unassignedOrdersStore = new UnassignedOrdersStore(
  appDispatcher as Dispatcher<PUnassignedOrdersStore>
);
export default unassignedOrdersStore;
