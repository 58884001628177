import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { LayoutSideTitle } from 'components/layout';
import Tabs from 'components/tabs';
import Notification from 'components/modal/Notification';
import { SpinnerFixed } from 'components/spinner';
import PatientInfoTab from './tabs/PatientInfoTab';
import EmployerInfoTab from './tabs/EmployerInfoTab';
import InsuranceInfoTab from './tabs/InsuranceInfoTab';
import CommentTab from './tabs/CommentTab';

import PatientRecordPageActions from 'actions/patient-records/patient-demographics/PatientRecordPageActions';
import PatientRecordPageStateActions from 'actions/patient-records/patient-demographics/PatientRecordPageStateActions';
import UrlUtils from 'utils/UrlUtils';
import {
  TGeneralInfo,
  TFacilityInfo,
  TContactInfo,
  TEmployerInfo,
  TPatientStatusInfo,
  TGuarantorInfo,
  TInsuredInfo,
  TCommentInfo,
} from 'services/patient-records/patient-demographics/PatientRecordPageService';
import { URL_PATIENT_DEMOGRAPHIC } from 'constant/path/patientRecords';

export const TABS = {
  PATIENT_INFO_TAB: 0,
  EMPLOYER_INFO_TAB: 1,
  INSURANCE_INFO_TAB: 2,
  COMMENT_TAB: 3,
};

interface MatchParams {
  id: string;
}

export interface PAbstractPatientRecordPage
  extends RouteComponentProps<MatchParams> {
  noTitle: boolean;
  updateOverview?: () => void;
}

export class SAbstractPatientRecordPage {
  patientRecord: any = null;
  patientRecordLoaded: boolean = false;
  mode: number = null;
  generalInfo: any = null;
  generalInfoEditable: boolean = false;
  employerInfo: any = null;
  employerInfoEditable: boolean = false;
  facilityInfo: any = null;
  facilityInfoEditable: boolean = false;
  contactInfo: any = null;
  contactInfoEditable: boolean = false;
  patientStatusInfo: any = null;
  patientStatusInfoEditable: boolean = false;
  commentInfo: any = null;
  commentInfoEditable: boolean = false;
  guarantorInfo: any = null;
  guarantorInfoEditable: boolean = false;
  insuredInfo: any = null;
  primaryInsuredEditable: boolean = false;
  secondaryInsuredEditable: boolean = false;
  tertiaryInsuredEditable: boolean = false;
  onAjax: boolean = false;
}

const tabsList = [
  { label: 'Patient Info' },
  { label: 'Employer Info' },
  { label: 'Insurance Info' },
  { label: 'Comments - Alerts' },
];

export default abstract class AbstractPatientRecordPage extends React.Component<
  PAbstractPatientRecordPage,
  SAbstractPatientRecordPage
> {
  COLUMNS: any = null;

  abstract submitGeneralInfo(generalInfo: TGeneralInfo): Promise<any>;
  abstract submitFacilityInfo(facilityInfo: TFacilityInfo): Promise<any>;
  abstract submitContactInfo(contactInfo: TContactInfo): Promise<any>;
  abstract submitPatientInfo(patientInfo: any): Promise<any>;
  abstract submitEmployerInfo(employerInfo: TEmployerInfo): Promise<any>;
  abstract submitPatientStatusInfo(
    patientStatusInfo: TPatientStatusInfo
  ): Promise<any>;
  abstract submitGuarantorInfo(guarantorInfo: TGuarantorInfo): Promise<any>;
  abstract submitInsuredInfo(insuredInfo: TInsuredInfo): Promise<any>;
  abstract submitCommentInfo(commentInfo: TCommentInfo): Promise<any>;

  componentWillUnmount() {
    PatientRecordPageActions.clearPatientRecord();
  }

  static getFormState() {
    const state = Object.assign(new SAbstractPatientRecordPage(), {
      errors: {},
      editNotificationText: 'Sorry, an error occurred!',
      showEditNotification: false,
      editNotificationType: 'danger',
    });
    return state;
  }

  handleSelected = (currentTab: number) => {
    PatientRecordPageStateActions.setCurrentTab(currentTab);

    switch (currentTab) {
      case TABS.PATIENT_INFO_TAB:
        PatientRecordPageStateActions.uncachePatientInfoTab();
        break;
      case TABS.EMPLOYER_INFO_TAB:
        PatientRecordPageStateActions.uncacheEmployerInfoTab();
        break;
      case TABS.INSURANCE_INFO_TAB:
        PatientRecordPageStateActions.uncacheInsuranceInfoTab();
        break;
      case TABS.COMMENT_TAB:
        PatientRecordPageStateActions.uncacheCommentInfoTab();
        break;
    }
  };

  updateTabData(value: any) {
    this.setState({ patientRecord: value });
  }

  getColumns() {
    return this.COLUMNS;
  }

  render() {
    const urlParam = UrlUtils.getQuery('backUrl');
    const backUrl =
      urlParam !== '' && urlParam !== null ? urlParam : URL_PATIENT_DEMOGRAPHIC;
    const patientRecord = this.state.patientRecord;
    const currentPatientTitle = this.state.patientRecordLoaded
      ? patientRecord.first_name + ' ' + patientRecord.last_name
      : null;
    return (
      <>
        {this.state.onAjax && <SpinnerFixed />}
        <div className="patient-record-page pt-4">
          {!this.props.noTitle && (
            <LayoutSideTitle
              title={
                this.state.mode === 2 ? currentPatientTitle : 'Add Patient'
              }>
              <Link to={backUrl} className="btn btn-danger">
                Back to List
              </Link>
            </LayoutSideTitle>
          )}
          <Tabs
            onClick={this.handleSelected}
            tabsList={tabsList}
            className="mb-4">
            {this.state.patientRecordLoaded ? (
              <PatientInfoTab
                generalInfo={this.state.generalInfo}
                updateGeneralInfo={this.submitGeneralInfo.bind(this)}
                generalInfoEditable={this.state.generalInfoEditable}
                facilityInfo={this.state.facilityInfo}
                updateFacilityInfo={this.submitFacilityInfo.bind(this)}
                facilityInfoEditable={this.state.facilityInfoEditable}
                contactInfo={this.state.contactInfo}
                updateContactInfo={this.submitContactInfo.bind(this)}
                contactInfoEditable={this.state.contactInfoEditable}
                mode={this.state.mode}
                addPatientInfo={
                  this.state.mode === 1
                    ? this.submitPatientInfo.bind(this)
                    : undefined
                }
              />
            ) : null}

            {this.state.patientRecordLoaded ? (
              <EmployerInfoTab
                employerInfo={this.state.employerInfo}
                updateEmployerInfo={this.submitEmployerInfo.bind(this)}
                employerInfoEditable={this.state.employerInfoEditable}
                mode={this.state.mode}
              />
            ) : null}
            {this.state.patientRecordLoaded ? (
              <InsuranceInfoTab
                patientStatusInfo={this.state.patientStatusInfo}
                patientStatusInfoEditable={this.state.patientStatusInfoEditable}
                updatePatientStatusInfo={this.submitPatientStatusInfo.bind(
                  this
                )}
                guarantorInfo={this.state.guarantorInfo}
                updateGuarantorInfo={this.submitGuarantorInfo.bind(this)}
                guarantorInfoEditable={this.state.guarantorInfoEditable}
                insuredInfo={this.state.insuredInfo}
                primaryInsuredEditable={this.state.primaryInsuredEditable}
                secondaryInsuredEditable={this.state.secondaryInsuredEditable}
                tertiaryInsuredEditable={this.state.tertiaryInsuredEditable}
                updateInsuredInfo={this.submitInsuredInfo.bind(this)}
                mode={this.state.mode}
              />
            ) : null}

            {this.state.patientRecordLoaded ? (
              <CommentTab
                commentInfo={this.state.commentInfo}
                updateCommentInfo={this.submitCommentInfo.bind(this)}
                commentInfoEditable={this.state.commentInfoEditable}
                mode={this.state.mode}
              />
            ) : null}
          </Tabs>
        </div>
      </>
    );
  }

  reset() {}

  goToOverview() {
    this.props.history.push(URL_PATIENT_DEMOGRAPHIC);
  }

  processResponse(response: any) {
    if (response === 'S') {
      Notification.success('Successfully changed!');
      if (this.props.updateOverview) {
        this.props.updateOverview();
      }
    } else {
      Notification.danger('Ar error occurred!');
    }
    return response;
  }
}
