import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import DialogChangingFeeSchedule from 'page/system-setup/components/DialogChangingFeeSchedule';
import { Button, IconButton } from 'components/button';
import { LayoutSideTitle } from 'components/layout';
import { Grid } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import Filter from './components/Filter';
import DialogFormImportSchedule from './components/DialogFormImportSchedule';

import {
  storeFacilityFeeSchedule,
  ScheduleItemType,
  FilterType,
  ImportPayloadType,
} from 'stores/_mobx/systemSetup/feeSchedule/facility';
import { storeFeeScheduleCommon } from 'stores/_mobx/systemSetup/feeSchedule/common';
import { FEE_SCHEDULE_FACILITY as PAGE_ID } from 'constant/pagesId/systemSetup';

const getCommonColumns = (isActive: boolean, hasPermissionArchive: boolean) => {
  const columns = [
    { name: 'statename', title: 'Facility' },
    { name: 'filename', title: 'Description' },
    { name: 'area', title: 'Bill Jurisdiction' },
    { name: 'status', title: 'Status' },
    { name: 'date', title: 'Added Date' },
    { name: 'deldate', title: 'Archive Date' },
    {
      name: 'active_fee',
      title: 'Action',
      className: 'text-center',
      render: (value: undefined, schedule: ScheduleItemType) =>
        schedule.refid ? (
          <div className="control justify-content-center">
            <IconButton
              title={isActive ? 'Active Fee' : 'Archive Fee'}
              disabled={!schedule.cpt_status}
              onClick={() => storeFeeScheduleCommon.setEditProps(schedule)}>
              <i className="bi bi-cash-coin" />
            </IconButton>
            {hasPermissionArchive && isActive && (
              <IconButton
                title="To archive"
                disabled={!schedule.cpt_status}
                onClick={() => {
                  storeFeeScheduleCommon.setArchiveProps(schedule);
                }}>
                <i className="bi bi-file-zip" />
              </IconButton>
            )}
          </div>
        ) : null,
    },
  ];

  return columns.filter((column) => Boolean(column));
};

const FeeScheduleFacilityPage = () => {
  const {
    fetching,
    feeSchedulesList,
    feeSchedulesTotal,
    filter,
    permission,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeFacilityFeeSchedule;

  const { archiveInProgress, editProps, archiveProps } = storeFeeScheduleCommon;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isImportVisible, toggleImport] = useState<boolean>(false);

  const columns = useMemo(
    () => getCommonColumns(filter.isActive, permission.archive),
    [filter.isActive, permission.archive]
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleImport = () => {
    toggleImport((state) => !state);
  };

  const handleFilterChange = (filterData: FilterType) => {
    setPaginationToStart();
    storeFacilityFeeSchedule.setFilter(filterData);
  };

  const handleApproveArchive = () => {
    storeFeeScheduleCommon.archiveFeeSchedule().then((isSucceed) => {
      if (isSucceed) storeFacilityFeeSchedule.getFeeSchedulesListMain();
    });
  };

  const handleImport = (payload: ImportPayloadType) =>
    storeFacilityFeeSchedule.importFeeSchedule(payload).then((isSucceed) => {
      if (isSucceed) {
        toggleImport(false);
        storeFacilityFeeSchedule.getFeeSchedulesListMain();
      }
      return isSucceed;
    });

  useEffect(() => {
    storeFacilityFeeSchedule.getFeeSchedulesListMain();
  }, [pagination, filter]);

  useEffect(() => {
    storeFacilityFeeSchedule.checkPermission();

    return storeFeeScheduleCommon.clearStore;
  }, []);

  return (
    <div className="fee-schedule-page">
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Corporate Fee Schedules">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide filter' : 'Show filter'}
          onClick={handleToggleFilter}
        />
        {permission.importAction && (
          <Button text="Import" onClick={handleToggleImport} />
        )}
      </LayoutSideTitle>

      <Filter
        isVisible={isFilterVisible}
        fetching={fetching}
        onChange={handleFilterChange}
      />

      <Grid
        id="facility_fee_schedule"
        groupBy="statename"
        onAjax={fetching}
        columns={columns}
        dataSource={feeSchedulesList}
        dataSourceCount={feeSchedulesTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      {editProps && (
        <DialogChangingFeeSchedule
          feeType={editProps.cpt || 'C'}
          id={editProps.id}
          groupId={editProps.groupId}
          isActive={filter.isActive}
          readOnly={!permission.edit || !filter.isActive}
          onClose={storeFeeScheduleCommon.clearEditProps}
        />
      )}

      {archiveProps && (
        <DialogConfirm
          isLoading={archiveInProgress}
          onCancel={storeFeeScheduleCommon.clearArchiveProps}
          onApprove={handleApproveArchive}>
          Are you sure, you want to archive?
        </DialogConfirm>
      )}

      {isImportVisible && (
        <DialogFormImportSchedule
          onClose={handleToggleImport}
          onSubmit={handleImport}
        />
      )}
    </div>
  );
};

export default observer(FeeScheduleFacilityPage);
