import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import { TDropDownItem } from 'actions/project/DropdownActions';

export type DropDownItemType = TDropDownItem;

class PModifierStore extends CommonPayload {}

class SModifierStore {
  modifierList: Array<TDropDownItem> = [];
}

class ModifierStore extends ReduceStore<SModifierStore, PModifierStore> {
  getInitialState() {
    return new SModifierStore();
  }

  areEqual(state1: SModifierStore, state2: SModifierStore) {
    return false;
  }

  reduce(state: SModifierStore, action: PModifierStore) {
    switch (action.type) {
      case 'codemaster.Modifier.ModifierDropdown':
        this.getState().modifierList = action.data;
        break;
      default:
        break;
    }
    return state;
  }

  hasList() {
    return Boolean(this.getState().modifierList.length);
  }

  findValue(id: number) {
    const list = this.getState().modifierList;
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if ('' + item.value === '' + id) {
        return item.label;
      }
    }
    return '';
  }
}

export default new ModifierStore(appDispatcher);
