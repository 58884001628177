import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { Checkbox } from 'components/form/checkbox';
import { Input } from 'components/form/textField';

import {
  defaultFilterValues,
  IcdCodeFilterType,
} from 'stores/_mobx/workflow/order/icdCode';

interface PropsType {
  smoking: IcdCodeFilterType['smoking'];
  symptomatic: IcdCodeFilterType['symptomatic'];
  onChange: (payload: IcdCodeFilterType) => void;
}

const Filter = ({ onChange, ...rest }: PropsType) => {
  const refIcdNameField = useRef<HTMLInputElement>();

  const { control, watch, handleSubmit } = useForm<IcdCodeFilterType>({
    defaultValues: { ...defaultFilterValues, ...rest },
  });

  useEffect(() => {
    const subscription = watch((formValue) => {
      onChange(formValue as Required<IcdCodeFilterType>);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    refIcdNameField.current.focus();
  }, []);

  return (
    <form className="row" onSubmit={handleSubmit(onChange)}>
      <Input
        name="name"
        label="Search By ICD Code"
        className="col-md-4"
        ref={refIcdNameField}
        control={control}
      />
      <Input
        name="description"
        label="Search By ICD Description"
        className="col-md-6"
        control={control}
      />
      <Checkbox
        name="all"
        label="All ICD Code"
        className="col-md-2 mt-5"
        control={control}
      />
    </form>
  );
};

export default Filter;
