import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { options as optionsTimezone } from 'components/project/dropdown/SimpleTimezoneDropdown';
import { Input } from 'components/form/textField';
import { Select } from 'components/form/select';
import { DateRange } from 'components/form/dateRange';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import {
  storeOrderTrackHistory,
  defaultFilterValue,
  FilterType,
} from 'stores/_mobx/report/orderTrackHistory';
import { getTimeZoneName } from 'utils/DateUtils';

interface PropsType {
  isVisible: boolean;
  fetching: boolean;
  onSubmit: (data: FilterType) => void;
}

const Filter = ({ fetching, isVisible, onSubmit }: PropsType) => {
  const { control, reset, watch, handleSubmit } = useForm<FilterType>({
    defaultValues: storeOrderTrackHistory.filter,
  });

  const timeZoneName = useMemo(getTimeZoneName, []);

  const handleClickReset = () => {
    reset(defaultFilterValue);
    onSubmit(defaultFilterValue);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'timezone') {
        storeOrderTrackHistory.setFilter(formValue as FilterType);
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="patientLastName"
        label="Patient Last Name"
        className="col-sm-12 col-md-6 col-lg-3"
        control={control}
      />
      <Input
        name="patientFirstName"
        label="Patient First Name"
        className="col-sm-12 col-md-6 col-lg-3"
        control={control}
      />
      <Input
        name="studyId"
        label="Study ID"
        className="col-sm-12 col-md-6 col-lg-3"
        control={control}
      />
      <Select
        name="timezone"
        label="Timezone"
        className="col-sm-12 col-md-6 col-lg-3"
        options={optionsTimezone}
        placeholder={timeZoneName}
        control={control}
      />
      <div />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-6 col-lg-auto"
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          onClick={handleClickReset}
          variant="warning"
          type="reset"
          text="Reset"
          disabled={fetching}
        />

        <Button type="submit" text="Apply" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
