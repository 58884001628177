import { useState } from 'react';

import { Button } from 'components/button';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Grid } from 'components/grid';
import { PureFile } from 'components/form/file';
import { ControlsLayout } from 'components/layout';

import { DIR_WITH_EXAMPLE } from 'constant/config';

const COLUMNS = [
  { name: 'icd_code', title: 'ICD - Code *' },
  { name: 'icd_desc', title: 'ICD - Description *' },
  { name: 'icd_type', title: 'ICD - Type *' },
  { name: 'icd_bill', title: 'ICD - Billable *' },
];

const DATA_SOURCE = [
  {
    icd_code: '233.7',
    icd_desc: 'CA IN SITU BLADDER',
    icd_type: 'icd9',
    icd_bill: 'yes',
  },
  {
    icd_code: 'L33.6',
    icd_desc: 'CA IN SITU MALE GEN OT',
    icd_type: 'icd10',
    icd_bill: 'no',
  },
];

interface PropsType {
  fetching: boolean;
  onClose: () => void;
  onSubmit: (file: File) => void;
}

const DialogImport = ({ fetching, onClose, onSubmit }: PropsType) => {
  const [isExampleVisible, toggleExample] = useState<boolean>(false);

  const [file, setFile] = useState<File | string>('');

  const handleToggleExample = () => {
    toggleExample((state) => !state);
  };

  const handleClickSubmit = () => {
    onSubmit(file as File);
  };

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Confirm" onClose={onClose} />
      <DialogBody className={fetching ? 'on-ajax' : ''}>
        <div className="row align-items-end justify-content-between">
          <div className="col-sm-8 col-md-4 col-lg-3">
            <PureFile
              accept=".csv"
              name="uploader"
              label="CSV file"
              required
              value={file}
              onChange={setFile}
            />
          </div>
          <ControlsLayout alignX="auto">
            <a
              href={`${DIR_WITH_EXAMPLE}/sampleicdcode.csv`}
              target="_blank"
              className="btn btn-primary"
              rel="noreferrer">
              Download Sample ICD Code
            </a>
          </ControlsLayout>
        </div>

        {isExampleVisible && (
          <>
            <h5>Example:</h5>
            <Grid
              noControl
              hideSwitcher
              disablePagination
              columns={COLUMNS}
              dataSource={DATA_SOURCE}
            />
            <ul className="d-flex gap-3 text-danger">
              <li>T - Tech</li>
              <li>P - Prof</li>
              <li>G - Global</li>
            </ul>
          </>
        )}
      </DialogBody>
      <DialogFooter>
        <Button
          text={isExampleVisible ? 'Hide sample' : 'Show sample'}
          variant="default"
          onClick={handleToggleExample}
        />
        <Button text="Submit" disabled={!file} onClick={handleClickSubmit} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogImport;
