import { forwardRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import PureSelect, { PureSelectProps } from 'components/form/select/PureSelect';
import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';

import {
  storeDropdownTechnician,
  OptionType,
} from 'stores/_mobx/dropDown/technician';

interface PropsType extends PAbstractDropdown {
  options: OptionType[];
  ref: any;
}

class TechnicianDropdown extends AbstractDropdown<
  PropsType,
  SAbstractDropdown
> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<TechnicianDropdown, any>(
  (props: PAbstractDropdown, ref) => {
    const { fetching, options } = storeDropdownTechnician;

    useEffect(() => {
      storeDropdownTechnician.getOptions();
    }, []);

    return (
      <TechnicianDropdown
        {...props}
        ref={ref}
        options={options}
        fetching={fetching}
      />
    );
  }
);

export default observer(DropDownMiddleware);

interface PurePropsTypes extends Omit<PureSelectProps, 'options'> {
  isVaccinationRequired?: boolean;
}
export const PureSelectTechnician = observer(
  ({ isVaccinationRequired, ...props }: PurePropsTypes) => {
    const { options, fetching } = storeDropdownTechnician;

    useEffect(() => {
      storeDropdownTechnician.getOptions();
    }, []);

    return (
      <PureSelect
        {...props}
        options={options}
        isLoading={fetching}
        optionsWithBadges={isVaccinationRequired}
      />
    );
  }
);
