import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Textarea } from 'components/form/textarea';
import { Input } from 'components/form/textField';
import RadioOptions from 'components/project/common/RadioOptions';
import { Radio } from 'components/form/radio';
import { Button } from 'components/button';
import resolver from './validation';

const MEDIA_OPTIONS = [
  { value: 'DX', label: 'DX' },
  { value: 'CR', label: 'CR' },
  { value: 'RG', label: 'RG (Film)' },
  { value: 'not_defined', label: 'Not Defined' },
  { value: 'N', label: 'None' },
];

const fieldCN = 'col-md-6';

const initialState: FormModel = {
  exam_type_desc: '',
  exam_type_nm: '',
  media_type: '',
  mod_price: '',
  q0092_setup: '',
};

export interface FormModel {
  exam_type_desc: string;
  exam_type_nm: string;
  media_type: string;
  mod_price: string;
  q0092_setup: string;
  refid?: number;
}

interface PropsType {
  defaultValues?: FormModel;
  backUrl: string;
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormExamType = ({
  defaultValues = initialState,
  backUrl,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting, isDirty },
    reset,
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const cn = `row col-lg-10 col-xl-8${isSubmitting ? ' on-ajax' : ''}`;

  const handleReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form className={cn} onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="exam_type_nm"
        label="Exam type"
        required
        className={fieldCN}
        control={control}
      />
      <Textarea
        name="exam_type_desc"
        label="Description"
        required
        className={fieldCN}
        rows={4}
        control={control}
      />

      <div className="row col-md-6">
        <Radio
          name="q0092_setup"
          label="Q00092 setup"
          required
          options={RadioOptions.yesNo}
          control={control}
        />

        <Radio
          name="mod_price"
          label="Show on Placed Order"
          required
          options={RadioOptions.yesNo}
          control={control}
        />
      </div>

      <Radio
        name="media_type"
        label="Default Media"
        required
        className={fieldCN}
        options={MEDIA_OPTIONS}
        control={control}
      />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={!isDirty || isSubmitting}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormExamType;
