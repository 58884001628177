import { Dispatcher } from 'flux';
import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

export class PCallbackManagerStore extends CommonPayload {
  count: number = 0;
}

export class SCallbackManagerStore {
  dataSource: Array<any> = [];
  dataSourceCount: number = 0;
}

class CallbackManagerStore extends ReduceStore<
  SCallbackManagerStore,
  PCallbackManagerStore
> {
  getInitialState() {
    return new SCallbackManagerStore();
  }

  areEqual(state1: SCallbackManagerStore, state2: SCallbackManagerStore) {
    return false;
  }

  reduce(state: SCallbackManagerStore, action: PCallbackManagerStore) {
    switch (action.type) {
      case 'orders-order-information-callback-manager-overview':
        this.getState().dataSource = action.data;
        this.getState().dataSourceCount = action.count;
        break;
      default:
        break;
    }
    return state;
  }
}

const callbackManagerStore = new CallbackManagerStore(
  appDispatcher as Dispatcher<PCallbackManagerStore>
);
export default callbackManagerStore;
