import { RouteComponentProps } from 'react-router-dom';

import { URL_EKG } from 'constant/path/workflow';
import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeEkg,
  EkgFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/ekg';

interface PropsType extends RouteComponentProps {}

const EkgAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: EkgFormModel) =>
    storeEkg.addEkg(data).then((error) => {
      if (error) return error;
      history.push(URL_EKG);
    });

  return (
    <>
      <Title title="Add EKG" />
      <Form onSubmit={handleSubmit} backUrl={URL_EKG} />
    </>
  );
};

export default EkgAddPage;
