import React from 'react';
import { Container } from 'flux/utils';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button, IconButton } from 'components/button';
import Form from 'components/form/Form';
import Text from 'components/form/input/Text';
import { Grid, TPagination } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import DialogConfirmDeleteCPTCode from '../components/DialogConfirmDeleteCPTCode';
import DialogConfirmEditCPTCode from '../components/DialogConfirmEditCPTCode';

import OrderFormPageActions from 'actions/workflow/order/OrderFormPageActions';
import { TCptCode } from 'services/workflow/order/OrderFormPageService';
import OrderFormPageStore from 'stores/workflow/order/OrderFormPageStore';
import { storeGrid } from 'stores/_mobx/grid';
import debounce from 'utils/debounce';
import { getPagination } from 'utils/getPagination';
import { isQrCpt } from 'utils/cptCode';
import AccessUtils from 'utils/AccessUtils';
import { DEBOUNCE_DELAY } from 'constant/debounceDelay';

interface FilterType {
  search: string;
  searchDesc: string;
  flag: string;
}

interface PCPTCodeModal {
  orderId?: number;
  modalityId: number;
  checkableZeroPrice?: boolean;
  dataSource: any[];
  callback: (list: any[]) => void;
}

class TCptCodeSelected extends TCptCode {
  tempId: string = '';
}

class SCPTCodeModal {
  onAjax: boolean = false;
  filter: FilterType = {
    search: '',
    searchDesc: '',
    flag: 'A',
  };
  itemToAdd: TCptCode = null;
  cptForDelete: any = null;
  cptForEdit: any = null;
  confirmEdit: boolean = false;
  dataSource: Array<any> = [];
  cptCodeCount: number = 0;
  cptCode: Array<TCptCode> = [];
  zeroPriceConfirm: number = 0;
  pagination = getPagination();
}

export class CPTCodeModal extends React.Component<
  PCPTCodeModal,
  SCPTCodeModal
> {
  submitSuccess(model?: any): void {}

  static hasAccessionNumber(cpt: {
    external_accession_number: string;
    accession_number: string;
  }) {
    return !!(cpt.external_accession_number || cpt.accession_number);
  }

  selectedCptGridRef: React.RefObject<Grid> = React.createRef<Grid>();

  showDeleteCptButtonToAllowedUserTypes = AccessUtils.haveAccessToDeleteCptCodes();

  renderControl = (refid: any, data: any, index: number) => {
    const isEditMode = this.checkMode(data);

    return (
      <div className="control">
        <IconButton
          className="text-danger"
          onClick={() => {
            this.setState({ cptForDelete: data },
              CPTCodeModal.hasAccessionNumber(data)
                ? undefined
                : this.handleConfirmDelete
            );
          }}
          disabled={!this.showDeleteCptButtonToAllowedUserTypes && Boolean(data.transcription_id)}>
          <i className="bi bi-x-circle" />
        </IconButton>
        <IconButton
          onClick={() => {
            if (this.state.cptForEdit === data) {
              this.setState({
                cptForEdit: null
              });
            } else {
              this.setState({
                cptForEdit: data,
                confirmEdit: CPTCodeModal.hasAccessionNumber(data),
              });
            }
          }}>
          <i
            className={`bi ${isEditMode ? 'bi-pencil-fill' : 'bi-pencil bi'}`}
          />
        </IconButton>
      </div>
    );
  };

  wrapOnEdit = (value: string, data: any, index: number) => {
    const isEditMode = this.checkMode(data);
    return (
      <span className={isEditMode ? '' : 'cpt-on-edit-overlay'}>{value}</span>
    );
  };

  cellsRender = (value: string, row: TCptCode) => {
    const { checkableZeroPrice, orderId } = this.props;
    return (
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault();
          const zeroPriceConfirm =
            checkableZeroPrice && !Number(row.cpt_price) ? orderId : 0;

          this.setState(
            { zeroPriceConfirm, itemToAdd: { ...row } },
            zeroPriceConfirm ? null : () => this.selectCptCode(row)
          );
        }}>
        {value}
      </a>
    );
  };

  COLUMNS = [
    {
      name: 'cpt_code',
      title: 'CPT Code',
      render: this.cellsRender,
    },
    {
      name: 'cpt_description',
      title: 'CPT Description',
      render: this.cellsRender,
    },
    {
      name: 'cpt_price',
      title: 'Technical Pricing',
      render: this.cellsRender,
    },
  ];

  SELECTED_COLUMNS = [
    {
      name: 'accession_number',
      title: 'Accession #',
      render: (value: string, d: any, index: number) => {
        const accessionNumber =
          d.external_accession_number || value || 'Not Assigned';

        return this.wrapOnEdit(accessionNumber, d, index);
      },
    },
    {
      name: 'cpt_code',
      title: 'CPT Code',
      render: this.wrapOnEdit,
    },
    {
      name: 'cpt_description',
      title: 'CPT Description',
      render: this.wrapOnEdit,
    },
    {
      name: 'extremity',
      title: 'Extremity',
      render: (extremity: string, data: TCptCodeSelected) => (
        <Text
          name="extremity"
          noLabel
          value={extremity}
          onSetValue={(name: string, value: string) =>
            this.handleEditExtremity(value, data)
          }
        />
      ),
    },
    {
      name: 'refid',
      title: 'Actions',
      render: this.renderControl,
    },
  ];

  handleEditExtremity = (value: any, data: TCptCodeSelected) => {
    const dataSource = [...this.state.dataSource];
    const index = dataSource.indexOf(data);

    dataSource[index] = { ...dataSource[index], extremity: value };

    this.setState({ dataSource });
  };

  checkMode = (data: any) => {
    const { confirmEdit, cptForEdit } = this.state;
    return !confirmEdit && cptForEdit === data;
  };

  handleConfirmDelete = () => {
    const { cptForDelete } = this.state;
    const dataSource = [...this.state.dataSource];
    const index = dataSource.indexOf(cptForDelete);
    if (index >= 0) {
      dataSource.splice(index, 1);
    }
    this.setState({
      dataSource,
      cptForDelete: null,
      cptForEdit: null
    });
  };

  static getStores() {
    return [OrderFormPageStore];
  }

  selectCptCode = (cptCode: TCptCode) => {
    const { cptForEdit } = this.state;

    if (cptForEdit !== null) {
      const cpt = this.state.dataSource.find(
        (el) => el === cptForEdit
      ) || {};

      const dataSource = this.state.dataSource.map((el) =>
        el === cptForEdit
          ? {
              ...cpt,
              ...cptCode,
              cpt_desc: (cptCode as any).cpt_desc || cptCode.cpt_description,
              tempId: window.performance.now().toString(36)
            }
          : el
      );

      this.setState({
        dataSource,
        cptForEdit: null
      });
    } else {
      const cptCodeToAdd = {
        ...cptCode,
        tempId: window.performance.now().toString(36)
      }
      const dataSource = this.state.dataSource.concat(cptCodeToAdd);
      this.setState({ dataSource });
    }
  };

  static calculateState(prevState: SCPTCodeModal, props: PCPTCodeModal) {
    if (!prevState) {
      prevState = Object.assign(new SCPTCodeModal(), {
        model: {},
        errors: {},
        isOpen: false,
        onAjax: false,
        filter: {
          search: '',
          searchDesc: '',
          flag: 'A',
        },
        pagination: getPagination(storeGrid.getPageSize('cptCodeGrid')),
        itemToAdd: null,
        cptToDelete: null,
        cptOnEdit: null,
        confirmEdit: false,
        dataSource: props.dataSource,
      });
    }
    return {
      ...prevState,
      cptCode: OrderFormPageStore.getState().cptCode,
      cptCodeCount: OrderFormPageStore.getState().cptCodeCount,
    };
  }

  onPaginationChange = (pagination: TPagination) => {
    this.setState({ pagination: pagination }, this.updateData);
  };

  updateData = () => {
    const { props, state } = this;
    this.setState({ onAjax: true }, () => {
      OrderFormPageActions.GetCPTCodeTotalRecordCountByModality(
        state.filter,
        props.modalityId
      ).then(() => {
        if (props.orderId) {
          OrderFormPageActions.GetCPTCodeListValueByOrder(
            state.filter,
            props.modalityId,
            state.pagination.skip,
            state.pagination.pageSize,
            props.orderId
          ).then(() => this.setState({ onAjax: false }));
        } else {
          OrderFormPageActions.GetCPTCodeListValueByModality(
            state.filter,
            props.modalityId,
            state.pagination.skip,
            state.pagination.pageSize
          ).then(() => this.setState({ onAjax: false }));
        }
      });
    });
  };

  debouncedUpdateData = debounce(DEBOUNCE_DELAY, this.updateData);

  handleChangeFilter = (name: string, value: string) => {
    const filter = { ...this.state.filter, [name]: value };
    this.setState({ filter }, this.debouncedUpdateData.method);
  };

  searchByCPTCode: any = null;

  componentDidMount() {
    this.updateData();

    this.searchByCPTCode && this.searchByCPTCode.focus();
  }

  componentWillUnmount() {
    this.debouncedUpdateData.clear();
  }

  render() {
    const { state } = this;

    return (
      <>
        <Dialog size="large" handleClose={this.handleClose}>
          <DialogHeader title="Add Procedure Code" onClose={this.handleClose} />

          <DialogBody className="codes-window">
            <Form
              onCollectValues={this.handleChangeFilter}
              model={state.filter}>
              <Text
                name="search"
                attr={{ placeholder: 'Search By CPT Code' }}
                noLabel
                className="col-sm-6"
                inputRef={(el) => (this.searchByCPTCode = el)}
              />
              <Text
                name="searchDesc"
                attr={{ placeholder: 'Search By CPT Description' }}
                noLabel
                className="col-sm-6"
              />
            </Form>

            <Grid
              wrapperClass="pb-5"
              id="cptCodeGrid"
              columns={this.COLUMNS}
              onAjax={state.onAjax}
              pagination={state.pagination}
              dataSource={state.cptCode}
              dataSourceCount={state.cptCodeCount}
              rowArguments={(row) => this.prepareRowArguments(row)}
              onPaginationChange={this.onPaginationChange}
            />

            <Grid
              id="cptCodeSelectedGrid"
              ref={this.selectedCptGridRef}
              disablePagination
              columns={this.SELECTED_COLUMNS}
              dataSource={state.dataSource}
              // rowArguments={this.getOnEditRowArguments()}
            />
          </DialogBody>
          <DialogFooter>
            <Button variant="warning" text="Save" onClick={this.onPositive} />
          </DialogFooter>
        </Dialog>

        {state.zeroPriceConfirm && (
          <DialogConfirm
            onCancel={() => {
              this.setState({ zeroPriceConfirm: 0 });
            }}
            onApprove={() => {
              this.setState({ zeroPriceConfirm: 0 }, () =>
                this.selectCptCode(this.state.itemToAdd)
              );
            }}>
            Are you sure you want to add a zero price CPT?
          </DialogConfirm>
        )}

        {state.cptForDelete !== null && (
          <DialogConfirmDeleteCPTCode
            onCancel={this.handleCloseDialogDeleteConfirm}
            onConfirm={this.handleConfirmDelete}
          />
        )}

        {state.confirmEdit && (
          <DialogConfirmEditCPTCode
            onCancel={this.handleCloseDialogEditConfirm}
            onConfirm={this.handleConfirmEdit}
          />
        )}
      </>
    );
  }

  handleCloseDialogEditConfirm = () => {
    this.setState({
      confirmEdit: false,
      cptForEdit: null
    });
  };

  handleConfirmEdit = () => {
    this.setState({
      confirmEdit: false,
    });
  };

  handleCloseDialogDeleteConfirm = () => {
    this.setState({
      cptForDelete: null
    });
  };

  onPositive = () => {
    const ds = this.prepareDs();

    const newProceduresCount = ds.reduce(
      (count, procedure) =>
        !procedure.accession_number && !isQrCpt(procedure.cpt_code)
          ? ++count
          : count,
      0
    );

    if (newProceduresCount) {
      OrderFormPageActions.getGeneratedAccessionNumbersList(
        newProceduresCount
      ).then((accessionList) => {
        let index = -1;
        const procedures = ds.map((procedure) => {
          if (!procedure.accession_number && !isQrCpt(procedure.cpt_code)) {
            index++;
            return {
              ...procedure,
              accession_number: accessionList[index],
            };
          }
          return procedure;
        });
        this.props.callback(procedures);
      });
    } else {
      this.props.callback(ds);
    }
    return true;
  };

  handleClose = () => {
    this.props.callback(null);
  };

  prepareDs() {
    return this.state.dataSource.map((v) => {
      if (v.extremity) {
        v.cpt_desc =
          `[${v.extremity}] ` +
          v.cpt_description.replace(/^\[(\w|\d|,|-|\.|\s)+]/, '');
      }
      return v;
    });
  }

  prepareRowArguments(row: TCptCode) {
    const state = this.state;

    let exists = false;
    for (let i = 0; i < state.dataSource.length; i++) {
      if (state.dataSource[i].refid === row.refid) {
        exists = true;
        break;
      }
    }
    return exists ? { className: 'selected' } : {};
  }
}

const ctpModal = Container.create(CPTCodeModal, { withProps: true });
export default ctpModal;
