import { forwardRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';

import {
  storeDropdownUniversalIdType,
  OptionType,
} from 'stores/_mobx/dropDown/universalIdType';

interface PropsType extends PAbstractDropdown {
  options: OptionType[];
  ref: any;
}

class UniversalIdTypeDropdown extends AbstractDropdown<
  PropsType,
  SAbstractDropdown
> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<UniversalIdTypeDropdown, any>(
  (props: PAbstractDropdown, ref) => {
    const { fetching, options } = storeDropdownUniversalIdType;

    useEffect(() => {
      storeDropdownUniversalIdType.getOptions();
    }, []);

    return (
      <UniversalIdTypeDropdown
        {...props}
        ref={ref}
        options={options}
        fetching={fetching}
      />
    );
  }
);

export default observer(DropDownMiddleware);
