import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import GridActionControls from 'page/components/GridActionControls';
import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import Filter from './components/Filter';

import {
  storeHospice,
  FilterModel,
} from 'stores/_mobx/systemSetup/masterSetting/hospice';
import { MS_HOSPICE as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_HOSPICE_ADD,
  URL_HOSPICE,
} from 'constant/path/systemSetup';

const columns = [
  { name: 'hospice_nm', title: 'Hospice' },
  { name: 'address1', title: 'Address 1' },
  { name: 'address2', title: 'Address 2' },
  { name: 'city', title: 'City' },
  { name: 'state', title: 'State' },
  { name: 'zipcode', title: 'Zip Code' },
  { name: 'npi', title: 'NPI' },
  {
    name: 'refid',
    title: 'Action',
    render: (id: number) => (
      <GridActionControls
        id={id}
        url={`${URL_HOSPICE}/edit/${id}`}
        onDelete={storeHospice.setIdForDelete}
      />
    ),
  },
];

const HospiceListPage = () => {
  const {
    fetching,
    hospiceList,
    hospiceTotal,
    idForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeHospice;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleFilterChange = (filter: FilterModel) => {
    storeHospice.setFilter(filter);

    debouncedMethod();
  };

  const handleConfirmDelete = () => {
    storeHospice.deleteHospice().then((isSucceed) => {
      if (isSucceed) storeHospice.getHospiceListMain();
    });
  };

  useEffect(() => {
    storeHospice.getHospiceListMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Hospice">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <Filter onSearch={handleFilterChange} />

      <Grid
        id="hospice_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={hospiceList}
        dataSourceCount={hospiceTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_HOSPICE_ADD} />
        }
      />
      {idForDelete ? (
        <DialogConfirm
          onCancel={storeHospice.clearIdForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this Hospice?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(HospiceListPage);
