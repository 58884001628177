import { forwardRef, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useController } from 'react-hook-form';

import { PureSelect, SelectPropsType } from 'components/form/select';
import { OptionType } from 'components/form/input/Select';
import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';

import { storeDropdownRadiologist } from 'stores/_mobx/dropDown/radiologist';

interface PropsTypeLegacy extends PAbstractDropdown {
  radiologyGroupId?: number;
  options: OptionType[];
  ref: any;
}

interface PropsType extends Omit<SelectPropsType, 'options'> {
  radiologyGroupId?: number;
  defaultRadiologistName?: string;
}

class RadiologistDropdown extends AbstractDropdown<
  Omit<PropsTypeLegacy, 'radiologyGroupId'>,
  SAbstractDropdown
> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<RadiologistDropdown, any>(
  ({ radiologyGroupId = 0, ...props }: PropsTypeLegacy, ref) => {
    const { optionsCollection } = storeDropdownRadiologist;

    const optionsDetails = optionsCollection[String(radiologyGroupId)];

    useEffect(() => {
      storeDropdownRadiologist.getOptions(radiologyGroupId);
    }, [radiologyGroupId]);

    return (
      <RadiologistDropdown
        {...props}
        ref={ref}
        options={optionsDetails?.options || []}
        fetching={optionsDetails?.fetching || false}
      />
    );
  }
);

export default observer(DropDownMiddleware);

const defaultOptionsInstance = { options: [] as OptionType[], fetching: false };

export const DropdownRadiologist = observer(
  ({
    radiologyGroupId = 0,
    defaultRadiologistName = '',
    name,
    control,
    ...props
  }: PropsType) => {
    const { optionsCollection } = storeDropdownRadiologist;

    const { field, fieldState } = useController({ control, name });

    const errorMessage = fieldState.error?.message || '';

    const { options, fetching } =
      optionsCollection[String(radiologyGroupId)] || defaultOptionsInstance;

    const finalOptions = useMemo(() => {
      const isOptionExist = field.value
        ? options.some(({ value }) => value === field.value)
        : true;

      if (isOptionExist || fetching) return options;

      return defaultRadiologistName
        ? [
            ...options,
            {
              value: field.value,
              label: `${defaultRadiologistName}  [Excluded from this group]`,
              color: 'rgb(128, 128, 128)',
              isDisabled: true,
            },
          ]
        : options;
    }, [field?.value, defaultRadiologistName, options, fetching]);

    useEffect(() => {
      storeDropdownRadiologist.getOptions(radiologyGroupId);
    }, [radiologyGroupId]);

    return (
      <PureSelect
        errorMessage={errorMessage}
        {...field}
        {...props}
        options={finalOptions}
        isLoading={fetching}
      />
    );
  }
);
