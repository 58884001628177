import { Link } from 'react-router-dom';

import { IconButton } from 'components/button';

import {
  storePayerInfo,
  PayerInfoType,
} from 'stores/_mobx/systemSetup/masterSetting/payerInfo';
import AccessUtils from 'utils/AccessUtils';
import { BASE_URL_FILE_DIR } from 'constant/config';
import { MS_PAYER_INFO as PAGE_ID } from 'constant/pagesId/systemSetup';
import { URL_PAYER_INFO } from 'constant/path/systemSetup';

const defaultColumns = [
  { name: 'name', title: 'Name' },
  { name: 'payortype_nm', title: 'Payer Type' },
  { name: 'payor_code', title: 'Payer ID' },
];

const getColumns = () => {
  const controlCellIsAllowed = AccessUtils.checkAccessAny(
    PAGE_ID.EDIT,
    PAGE_ID.DELETE
  );

  const columns = [
    ...defaultColumns,
    ...[
      AccessUtils.checkAccess(PAGE_ID.CONTRACT)
        ? {
            name: 'filenm',
            className: 'text-center width-100',
            title: 'Contract',
            render: (
              fileName: string,
              { extension, filepath }: PayerInfoType
            ) =>
              filepath ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="fs-4"
                  title={fileName}
                  href={`${BASE_URL_FILE_DIR}doc_img/contractdetails/${filepath}`}>
                  <i className={`bi bi-filetype-${extension}`} />
                </a>
              ) : null,
          }
        : null,

      controlCellIsAllowed && {
        name: 'refid',
        title: 'Action',
        className: 'width-75',
        render: (id: number) => (
          <div className="control">
            {AccessUtils.get(
              PAGE_ID.EDIT,
              <Link
                className="bi bi-pencil"
                to={`${URL_PAYER_INFO}/edit/${id}`}
              />
            )}
            {AccessUtils.get(
              PAGE_ID.DELETE,
              <IconButton onClick={() => storePayerInfo.setIdForDelete(id)}>
                <i className="bi bi-trash" />
              </IconButton>
            )}
          </div>
        ),
      },
    ],
  ];

  return columns.filter((column) => Boolean(column));
};

export default getColumns;
