import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Exporter from 'components/project/ExporterNew';
import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import { Button } from 'components/button';
import Filter from './components/Filter';

import {
  storeSummaryFacility,
  FilterType,
} from 'stores/_mobx/report/summaryFacility';
import { FACILITY_SUMMARY as PAGE_ID } from 'constant/pagesId/reports';

const columns = [
  { name: 'facilityname', title: 'Facility' },
  { name: 'corporate', title: 'Corporate' },
  {
    name: 'physician',
    title: 'Physician',
  },
  {
    name: 'technologist',
    title: 'Technologist',
  },
  { name: 'patientlname', title: 'Patient Last Name' },
  { name: 'patientfname', title: 'Patient First Name' },
  { name: 'dos', title: 'DOS', className: 'text-nowrap' },
  { name: 'pos', title: 'POS' },
  {
    name: 'mrn',
    title: 'Patient MRN',
  },
  { name: 'cptcodedesc', title: 'Procedure' },
  { name: 'findingsCode', title: 'Findings Code' },
];

const SummaryFacility = () => {
  const {
    fetching,
    logList,
    logTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeSummaryFacility;

  const gridRef = useRef<Grid>(null);

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const handleClickExport = () => {
    toggleExport((state) => !state);
  };

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleClickSearch = (payload: FilterType) => {
    storeSummaryFacility.setFilter(payload);
    setPaginationToStart();
  };

  useLayoutEffect(() => {
    storeSummaryFacility.setDefaultFilter();
  }, []);

  useEffect(() => {
    storeSummaryFacility.getLogs();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Facility/Patient Detail">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        fetching={fetching}
        isFilterVisible={isFilterVisible}
        onSearch={handleClickSearch}
      />

      <Grid
        id="summary_facility_gridId"
        noDataText="Please refine your search using the filters above"
        gridControlPanel={
          <GridControlButton
            title="Export"
            disabled={!logTotal}
            onClick={handleClickExport}
          />
        }
        columns={columns}
        ref={gridRef}
        onAjax={fetching}
        dataSource={logList}
        dataSourceCount={logTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      {isExportVisible && (
        <Exporter
          exporter="SummaryFacilityExporter"
          reportName="summary_facility_report"
          gridRef={gridRef}
          columns={columns}
          dataSource={logList}
          filter={storeSummaryFacility.filter}
          filterForRequest={storeSummaryFacility.prepareFilterForExport()}
          pdfExporter={storeSummaryFacility.generatePdf}
          onClose={handleClickExport}
        />
      )}
    </>
  );
};

export default observer(SummaryFacility);
