import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = (message: string) =>
  yup.number().typeError(message).positive(message);

export default yupResolver(
  yup.object().shape({
    xray_id: schema('Select a device'),
    computer_id: schema('Select a computer'),
    generator_id: schema('Select a generator'),
    plate_id: schema('Select a plate'),
  })
);
