import React, { useMemo, useEffect } from 'react';
import { Observer, observer } from 'mobx-react-lite';

import Custom from 'components/form/input/Custom';
import { PropsType as SelectPropsType } from 'components/form/select/Select';
import { FakeInput } from 'components/form/textField';
import FacilityDropdown, { DropDownFacility } from './FacilityDropdown';

import UserProfileStore from 'stores/UserProfileStore';
import { storeDropdownFacility } from 'stores/_mobx/dropDown/facility';

interface PropsType {
  name: string;
  label?: string;
  value?: number;
  className?: string;
  noLabel?: boolean;
  attr?: any;
  validations?: any;
  onSetValue?: (name: string, value: number, error: any) => void;
}

export default class FacilityRolesDropdown extends React.Component<
  PropsType,
  any
> {
  static getFacilityId() {
    return UserProfileStore.getFacilityId();
  }

  static canSeeFacilities() {
    return UserProfileStore.canSeeFacilities();
  }

  componentDidMount() {
    if (
      !FacilityRolesDropdown.canSeeFacilities() &&
      !storeDropdownFacility.currentUserFacilityName
    ) {
      storeDropdownFacility.getFacilityName(UserProfileStore.getFacility());
    }
  }

  componentWillUnmount() {
    storeDropdownFacility.clearFacilityName();
  }

  render() {
    if (FacilityRolesDropdown.canSeeFacilities()) {
      return <FacilityDropdown {...this.props} />;
    }
    return (
      <Observer>
        {() => (
          <Custom
            {...this.props}
            custom={
              <span>{storeDropdownFacility.currentUserFacilityName}</span>
            }
          />
        )}
      </Observer>
    );
  }

  getValue() {
    return FacilityRolesDropdown.canSeeFacilities()
      ? this.props.value
      : UserProfileStore.getFacility();
  }

  static findOption(facilityId: number) {
    return storeDropdownFacility.findOption(facilityId);
  }
}

export const DropDownFacilityRole = observer(
  (props: Omit<SelectPropsType, 'options'>) => {
    const { currentUserFacilityName } = storeDropdownFacility;

    const isAllowed = useMemo(() => UserProfileStore.canSeeFacilities(), []);

    useEffect(() => {
      if (!isAllowed && !currentUserFacilityName) {
        storeDropdownFacility.getFacilityName(UserProfileStore.getFacilityId());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAllowed, currentUserFacilityName]);

    return isAllowed ? (
      <DropDownFacility {...props} />
    ) : (
      <FakeInput
        className={props.className}
        label={props.label}
        value={currentUserFacilityName}
      />
    );
  }
);
