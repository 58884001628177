import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const message = 'Service name must be selected!';

const validation = yupResolver(
  yup.object().shape({
    serviceId: yup.number().typeError(message).positive(message),
  })
);

export default validation;
