import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import { TPatientRecord } from 'services/patient-records/patient-demographics/PatientRecordPageService';
import {
  TPhysician,
  TPhysicianDetail,
  TCptCode,
  TOrderFaxPhoneItem,
  TPatient,
  TExamInfoOrderDataExt,
  TFacilityAddressExt,
  TFacilityDropDown,
  TExamDetail,
  TDirectNumber,
  TOrderCallerDetails,
  TDirectFaxPhoneNumber,
  TVisitComments,
  TCptIcd,
} from 'services/workflow/order/OrderFormPageService';
import { dateToLocalTimezone } from 'utils/DateUtils';

export class IcdCode {
  icd_code: string = '';
  short_description: string = '';
}

export class CptCode {
  refid: number = 0;
  cpt_code: string = '';
  cpt_description: string = '';
  cpt_desc: string = '';
  session_no: string = '';
  external_accession_number: string = '';
  accession_number: string = '';
  extremity: string = '';
}

export class CptIcdItem {
  dateofservice: string = '';
  examtype: string = '';
  refid: number = 0;
  facility_nm: string = '';
  physicianname: string = '';
  providername: string = '';
  cptdesc: string = '';
  icddesc: Array<IcdCode> = [];
  technologistnm: string = '';
  Image: number = 0;
  ImageId: number = 0;
  cpt_codes: Array<CptCode> = [];
  phoneimgsource: string = '';
  phonetooltip: string = '';
  enablePh: boolean = false;
  imgsource: string = '';
  imgtooltip: string = '';
  enableImg: boolean;
  faximgsource: string = '';
  faxtooltip: string = '';
  enableFax: boolean = false;
  resultimgsource: string = '';
  resulttooltip: string = '';
  enableRep: boolean = false;
  externalimgsource: string = '';
  externaltooltip: string = '';
  enableEx: boolean = false;
}

export class OrderItem {
  patient_comment: string = '';
  hasLog: boolean = false;
  assign_providergrpid: any = null;
  assign_providerid: any = null;
  hasMedicalLog: boolean = false;
  medicare_id: number = 0;
  medicaid_id: number = 0;
  medicaid_stateid: number = 0;
  number_of_patient: number = 0;
  coding_status: string = '';
  priority_nm: string = '';
  row_color: string = '';
  exam_symptoms: string = '';
  refid: number = 0;
  spoke_with: any = null;
  inmateNumber: number = 0;
  immigrationNumber: string = '';
  iceNumber: string = '';
  acknowledgement: string = '';
  patient_diff_phone: string = '';
  patient_diff_address: string = '';
  patient_diff_city: string = '';
  patient_diff_state: string = '';
  patient_diff_zipcode: string = '';
  orderType: string = '';
  order_based_type: string = '';
  sessionno: string = '';
  external_accession_number: string = '';
  accessionNumbersStr: string = '';
  accessionNumbersStrForReport: string = '';
  providerName: string = '';
  providerid: number = 0;
  physicianname: string = '';
  phyemail: string = '';
  phynpi: number = 0;
  phyuserinfoid: number = 0;
  procity: string = '';
  prostate: string = '';
  assignflag: boolean = false;
  last_name: string = '';
  first_name: string = '';
  patientnm: string = '';
  patientid: number = 0;
  face_sheet: string = '';
  patientmrn: number = 0;
  eunity: string = '';
  facility_stat: string = '';
  facility_addtype: string = '';
  patientdob: string = '';
  ssn: string = '';
  room: string = '';
  examtype: string = '';
  modality_media_type: string = '';
  examtypeid: number = 0;
  order_media_type: any = null;
  faxed_time: string = '';
  cptcount: number = 0;
  GetFnlCptArr: Array<any> = [];
  GetIntCptArr: Array<any> = [];
  fnlcptdesc: string = '';
  cptdesc: string = '';
  cptCodes: Array<any> = [];
  cpt_code: number = 0;
  transRefid: any = null;
  imgvisible: boolean = false;
  lblvisible: boolean = false;
  lblvalue: string = '';
  cpt_code1: Array<any> = [];
  cpt_code_session_no2: Array<any> = [];
  cpt_refid: Array<any> = [];
  icd: Array<any> = [];
  phoneimgsource: string = '';
  phonetooltip: string = '';
  enablePh: boolean = false;
  imgsource: string = '';
  imgtooltip: string = '';
  enableImg: boolean = false;
  faximgsource: string = '';
  faxtooltip: string = '';
  enableFax: boolean = false;
  resultimgsource: string = '';
  resulttooltip: string = '';
  enableRep: boolean = false;
  critical: boolean = false;
  externalimgsource: string = '';
  externaltooltip: string = '';
  enableEx: boolean = false;
  insure: string = '';
  priority: number = 0;
  phoneresult: string = '';
  comment: string = '';
  hl7: any = null;
  gender: string = '';
  location: string = '';
  facilitynm: string = '';
  facilityid: number = 0;
  correctionalFacility: boolean = false;
  clientMRN: string = '';
  vw_patientid: string = '';
  radiologygrouprefid: number = 0;
  radiologygroupid: string = '';
  username: string = '';
  ordercreated_dt: string = '';
  technologistnm: string = '';
  techid: number = 0;
  address1: string = '';
  address2: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  phone: string = '';
  bill_email: string = '';
  facility_npi: string = '';
  order_source: string = '';
  order_source_nm: string = '';
  order_based: string = '';
  ordertaken_date: string = '';
  status_flag: string = '';
  orderassigned_date: string = '';
  ordercompletion_date: string = '';
  sheduleservdate: string = '';
  dos: string = '';
  sheduleservdate1: string = '';
  sheduleservtime1: string = '';
  schedulestime: string = '';
  dostime: string = '';
  scheduleendtime: string = '';
  dateofservice: string = '';
  ordersigned_date: string = '';
  patient_img_path: string = '';
  duedate: string = '';
  orderstatus: string = '';
  status: string = '';
  btnvisible: boolean = false;
  signstatusvis: boolean = false;
  pdfimgvis: boolean = false;
  cptvisible: boolean = false;
  icdvisible: boolean = false;
  pos: string = '';
  pos_id: number = 0;
  reasonval: string = '';
  ordersrc: string = '';
  ins_notes: string = '';
  phoneResult: string = '';
  notes: string = '';
  imgnotes: string = '';
}

const buildActionName = (name: string) => {
  return 'orders/order-information/' + name;
};

export const Actions = {
  LOAD_EXAM_ITEM_ACTION: buildActionName('load-exam-item-action'),
  LOAD_PATIENT_ITEM_ACTION: buildActionName('load-patient-item-action'),
  LOAD_PATIENT_VIEW_INFO_ACTION: buildActionName(
    'load-patient-view-info-action'
  ),
  LOAD_LAST_ACCESSION_NUMBER_ACTION: buildActionName(
    'load-last-accession-number-action'
  ),
  LOAD_PHYSICIAN_LIST_ACTION: buildActionName('load-physician-list-action'),
  LOAD_FLOOR_LIST: buildActionName('load-floor-list'),
  LOAD_FACILITY_ADDRESS: buildActionName('load-facility-address'),
  LOAD_FACILITY_WITHOUT_INACTIVE_LIST: buildActionName(
    'load-facility-without-inactive-list'
  ),
  LOAD_DIRECT_NUMBER_LIST: buildActionName('load-direct-number-list'),
  CLEAR_ORDER_INFO: buildActionName('clear'),
  CLEAR_PATIENT_INFO: buildActionName('clear-patient'),
  LOAD_PHYSICIAN_DETAILS: buildActionName('load-physician-details'),
  UPDATE_PHYSICIAN_INFO_ACTION: buildActionName('update-physician-info-action'),
  LOAD_ORDER_DETAIL_ACTION: buildActionName('load-order-detail-action'),
  LOAD_FAX_PHONE_INFORMATION: buildActionName('load-fax-phone-information'),
  LOAD_ORDER_DIRECT_NUMBER_LIST: buildActionName(
    'load-order-direct-number-list'
  ),
  UPDATE_EXAM_INFO_ORDER: buildActionName('update-exam-info-order'),
  LOAD_PATIENT_RECORD: buildActionName('load-patient-record'),
  LOAD_CPT_CODE: buildActionName('load-cpt-code'),
  LOAD_CPT_CODE_BY_ORDER: buildActionName('load-cpt-code-by-order'),
  LOAD_CPT_CODE_COUNT: buildActionName('load-cpt-code-count'),
};

export const orderTypeDropdownFullArray: Array<{
  label: string;
  value: string;
}> = [
  { value: 'C', label: 'Correctional' },
  { value: 'F', label: 'Facility' },
  { value: 'P', label: 'Home Care' },
  { value: 'H', label: 'Hospice' },
  { value: 'O', label: 'Occupational/Employee' },
];

class SOrderFormPageStore {
  orderDataPatient: TPatient = new TPatient();
  patientLoaded: boolean = false;
  orderDataExamInfo: TExamInfoOrderDataExt = new TExamInfoOrderDataExt();
  floorList: Array<{ data: string; label: string }> = [];
  patientViewInfo: Array<TPatientRecord> = [];
  physicianDetails: TPhysicianDetail = new TPhysicianDetail();
  physicianList: Array<TPhysician> = [];
  physicianDetailsLoaded: boolean = false;
  patientViewInfoLoaded: boolean = false;
  facilityWithoutInactive: Array<TFacilityDropDown> = [];
  examViewInfo: TExamDetail = new TExamDetail();
  examViewInfoLoaded: boolean = false;
  lastAccessionNumber: string = null;
  physicianOverview: Array<TPhysician> = [];
  physicianOverviewCount: number = 0;
  directNumberList: Array<TDirectNumber> = [];
  orderDetail: TOrderCallerDetails = new TOrderCallerDetails();
  orderTypeDropdown: Array<{ label: string; value: string }> = [];
  faxPhone: Array<TOrderFaxPhoneItem> = null;
  facilityAddress: TFacilityAddressExt = new TFacilityAddressExt();
  directNumberListLoaded: boolean = false;
  orderDirectNumberList: Array<TDirectFaxPhoneNumber> = [];
  cptCode: Array<TCptCode> = [];
  cptCodeCount: number = 0;
  visitComments: Array<TVisitComments> = [];
  cptIcdByPatient: Array<TCptIcd> = [];
  accessionNumbersList: Array<string> = [];
}

class OrderFormPageStore extends ReduceStore<
  SOrderFormPageStore,
  CommonPayload
> {
  getInitialState() {
    const state = new SOrderFormPageStore();
    return state;
  }

  areEqual(state1: SOrderFormPageStore, state2: SOrderFormPageStore) {
    return false;
  }

  reduce(state: SOrderFormPageStore, action: CommonPayload) {
    switch (action.type) {
      case Actions.LOAD_PATIENT_ITEM_ACTION:
        const newData: TPatient = action.data[0];
        this.getState().orderDataPatient = newData;
        this.getState().patientLoaded = true;
        break;
      case Actions.LOAD_PATIENT_VIEW_INFO_ACTION:
        this.getState().patientViewInfo = action.data;
        this.getState().patientViewInfoLoaded = true;
        break;
      case Actions.LOAD_EXAM_ITEM_ACTION:
        this.getState().examViewInfo = action.data[0];
        this.getState().examViewInfoLoaded = true;
        break;
      case Actions.LOAD_LAST_ACCESSION_NUMBER_ACTION:
        this.getState().lastAccessionNumber = action.data;
        break;
      case 'order.Order.GetPhysicianDropDown':
        this.getState().physicianOverview = action.data.data;
        this.getState().physicianOverviewCount = action.data.count;
        break;
      case Actions.LOAD_PHYSICIAN_LIST_ACTION:
        this.getState().physicianList = action.data;
        break;
      case Actions.LOAD_FLOOR_LIST:
        this.getState().floorList = action.data;
        break;
      case Actions.LOAD_FACILITY_ADDRESS:
        this.getState().facilityAddress = action.data;
        break;
      case Actions.LOAD_FACILITY_WITHOUT_INACTIVE_LIST:
        this.getState().facilityWithoutInactive = action.data;
        break;
      case Actions.LOAD_DIRECT_NUMBER_LIST:
        this.getState().directNumberList = action.data;
        this.getState().directNumberListLoaded = true;
        break;
      case Actions.LOAD_PHYSICIAN_DETAILS:
        this.getState().physicianDetails = action.data;
        this.getState().physicianDetailsLoaded = true;
        break;
      case Actions.CLEAR_ORDER_INFO:
        {
          const out = Object.assign(
            {},
            this.getState(),
            this.getInitialState()
          );
          const state4Update = this.getState() as any;
          for (let key in out) {
            state4Update[key] = (out as any)[key];
          }
        }
        break;
      case Actions.CLEAR_PATIENT_INFO:
        this.getState().orderDataPatient = new TPatient();
        this.getState().patientLoaded = false;
        break;
      case 'order-form-clear-for-patient':
        {
          const orderDataPatient = Object.assign(
            new TPatient(),
            this.getState().orderDataPatient
          );
          const savedState: any = {
            orderDataPatient: orderDataPatient,
            facilityAddress: this.getState().facilityAddress,
            facilityWithoutInactive: this.getState().facilityWithoutInactive,
            floorList: this.getState().floorList,
            orderTypeDropdown: this.getState().orderTypeDropdown,
            patientLoaded: this.getState().patientLoaded,
            patientViewInfo: this.getState().patientViewInfo,
            physicianDetails: {
              callerName: this.getState().physicianDetails.callerName,
            },
            examViewInfo: {
              FaxPhone: this.getState().examViewInfo.FaxPhone,
            },
          };
          Object.assign(this.getState(), this.getInitialState());
          const state4Update = this.getState() as any;
          for (let key in savedState) {
            if (savedState.hasOwnProperty(key)) {
              state4Update[key] = savedState[key];
            }
          }
        }
        break;
      case 'order-form-clear-for-facility':
        {
          const orderDataPatient = this.getState().orderDataPatient;
          const savedState: any = {
            orderDataPatient: Object.assign(new TPatient(), {
              LocationArr: orderDataPatient.LocationArr,
              Region: orderDataPatient.Region,
              facilityid: orderDataPatient.facilityid,
              facilityname: orderDataPatient.facilityname,
            }),
            facilityAddress: this.getState().facilityAddress,
            facilityWithoutInactive: this.getState().facilityWithoutInactive,
            floorList: this.getState().floorList,
            orderTypeDropdown: this.getState().orderTypeDropdown,
          };
          Object.assign(this.getState(), this.getInitialState());
          const state4Update = this.getState() as any;
          for (let key in savedState) {
            if (savedState.hasOwnProperty(key)) {
              state4Update[key] = savedState[key];
            }
          }
        }
        break;
      case 'order.Order.GetOrderTypeByFacility':
        this.getState().orderTypeDropdown = action.data;
        break;
      case Actions.LOAD_FAX_PHONE_INFORMATION:
        this.getState().faxPhone = action.data;
        break;
      case Actions.LOAD_ORDER_DETAIL_ACTION:
        this.getState().orderDetail = action.data[0];
        break;
      case Actions.LOAD_ORDER_DIRECT_NUMBER_LIST:
        this.getState().orderDirectNumberList = [
          action.data as TDirectFaxPhoneNumber,
        ];
        break;
      case Actions.UPDATE_EXAM_INFO_ORDER:
        {
          const examViewInfo = this.getState().examViewInfo;
          const orderDataExamInfo: TExamInfoOrderDataExt = (action as any)
            .orderDataExamInfo;

          this.getState().examViewInfo = {
            ...examViewInfo,
            // @ts-ignore
            FaxPhone: [...orderDataExamInfo.faxPhoneArr],
          };

          this.getState().orderDataExamInfo = orderDataExamInfo;
        }
        break;
      case Actions.LOAD_CPT_CODE:
        this.getState().cptCode = action.data;
        break;
      case Actions.LOAD_CPT_CODE_COUNT:
        this.getState().cptCodeCount = action.data;
        break;
      case 'medicalrecord.OrderResult.GetResult': {
        const visitComments = action.data.map((comment: TVisitComments) => ({
          ...comment,
          date: dateToLocalTimezone({ date: comment.date }),
        }));

        this.getState().visitComments = visitComments;
        break;
      }
      case 'order.Order.GetCptIcdByPatient':
        const cptList = action.data.map((cpt: TCptIcd) => ({
          ...cpt,
          dateofservice: dateToLocalTimezone({
            date: cpt.dateofservice,
            dateOnly: true,
          }),
        }));
        this.getState().cptIcdByPatient = cptList;
        break;
      default:
        break;
    }
    return state;
  }

  getFacility(id: number) {
    let res: TFacilityDropDown = null;
    const facilityWithoutInactive: Array<TFacilityDropDown> =
      this.getState().facilityWithoutInactive;
    for (let i = 0; i < facilityWithoutInactive.length; i++) {
      const item: TFacilityDropDown = facilityWithoutInactive[i];
      if ('' + item.data === '' + id) {
        res = item;
        break;
      }
    }
    return res;
  }
}

export default new OrderFormPageStore(appDispatcher);
