import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import Form from './components/Form';

import {
  storeGroupManager,
  defaultValuesGroupDetails,
  PhysicianGroupDetailsType,
} from 'stores/_mobx/clinicianManager/groupManager';
import { URL_GROUP_MANAGER } from 'constant/path/clinicalManager';

interface PropsType extends RouteComponentProps {
  updateOverview: () => void;
}

const GroupManagerAddPAge = ({ history }: PropsType) => {
  const { permission } = storeGroupManager;

  const handleSubmit = (payload: PhysicianGroupDetailsType) =>
    storeGroupManager.addGroup(payload).then((result) => {
      if (result) return result;
      history.push(URL_GROUP_MANAGER);
    });

  useEffect(() => {
    storeGroupManager.checkPermission();

    return storeGroupManager.clearGroupDetails;
  }, []);

  return (
    <>
      <LayoutSideTitle title="Create Group">
        <Link to={URL_GROUP_MANAGER} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <Form
        fetching={false}
        defaultValues={defaultValuesGroupDetails}
        {...permission}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default observer(GroupManagerAddPAge);
