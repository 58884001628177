import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    no_of_days: yup
      .string()
      .required('Please, enter a positive integer number!'),
  })
);

export default validation;
