import { useState } from 'react';

import { Button } from 'components/button';
import { PureTextarea } from 'components/form/textarea';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';

interface PropsType {
  label: string;
  title: string;
  defaultValue?: string;
  onClose: () => void;
  onSubmit: (name: string) => void;
}

const DialogPrompt = ({
  label,
  title,
  defaultValue,
  onClose,
  onSubmit,
}: PropsType) => {
  const [name, setName] = useState<string>(defaultValue || '');

  const isButtonDisabled = !name.length || name === defaultValue;

  const handleClickApply = () => {
    onSubmit(name);
  };

  return (
    <Dialog handleClose={onClose}>
      <DialogHeader title={title} onClose={onClose} />
      <DialogBody>
        <PureTextarea
          label={label}
          autoFocus
          required
          value={name}
          rows={4}
          onChange={setName}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          text="Apply"
          disabled={isButtonDisabled}
          onClick={handleClickApply}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogPrompt;
