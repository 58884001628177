import StringUtils from './StringUtils';

const QUESTION_CHAR = /^(\?)/;

export default class UrlUtils {
  /**
   * Get path from document.location without trailing slashes
   * example:
   * (without arguments)
   * http://mysite.com/some/path/here/
   * UrlUtils.getPath() => some/path/here
   *
   * (with argument)
   * http://mysite.com/some/path/here/
   * UrlUtils.getPath(1) => some
   * UrlUtils.getPath(2) => path
   *
   * @returns string
   */
  static getPath(param: number = 0) {
    const path = StringUtils.removeSlashes(document.location.pathname);
    if (param) {
      return path.split('/')[param - 1];
    }
    return path;
  }

  /**
   * Fetch query value from document.location
   *
   * example:
   * http://mysite.com?param=argument
   * UrlUtils.getQuery('param') => argument  (decoded)
   *
   * @param paramName
   * @returns string|null
   */
  static getQuery(paramName: string): string {
    const queryString = document.location.search.replace(QUESTION_CHAR, '');
    const queryArray = queryString.split('&');
    if (queryArray.length > 0) {
      const eqParamName = paramName + '=';
      for (let i = 0; i < queryArray.length; i++) {
        if (queryArray[i].indexOf(eqParamName) === 0) {
          return decodeURIComponent(
            queryArray[i].substring(eqParamName.length)
          );
        } else if (
          queryArray[i] === eqParamName ||
          queryArray[i] === paramName
        ) {
          return '';
        }
      }
    }
    return null;
  }
}
