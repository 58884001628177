import { MessagePreviewType } from 'stores/_mobx/message/sent';

interface PropsType {
  email: MessagePreviewType;
}

const MessageViewer = ({ email }: PropsType) => (
  <div className="email-read-panel">
    <div className="message-head">
      <p className="line">
        <b className="from">From:</b>
        <span className="from">{email.from}</span>
      </p>
      <p className="line">
        <b className="to">To:</b>
        <span className="to">{email.to}</span>
      </p>
      <p className="line">
        <b className="date">Date:</b>
        <span className="date">{email.date}</span>
      </p>
      <p className="line">
        <b className="subject">Subject:</b>
        <span className="subject">{email.subject}</span>
      </p>
    </div>
    <p
      className="message-body"
      dangerouslySetInnerHTML={{ __html: email.body }}
    />
  </div>
);

export default MessageViewer;
