export const BRIEFCASE = {
  FOLDER_CREATE: 'create_folder',
  FILE_UPLOAD: 'upload_file',
  PAGE: 'Briefcase',
};

export const LOGIN_REPORT = {
  PAGE: 'LoginReport',
};

export const CHANGE_LOGIN_DETAIL = {
  PAGE: 'ChangePassword',
};

export const MY_PROFILE = {
  PAGE: 'Account',
};

export const TIME_SHEET = {
  PAGE: 'TimeSheetManager',
};
