import { Container } from 'flux/utils';

import Notification from 'components/modal/Notification';
import AbstractPatientRecordPage, {
  SAbstractPatientRecordPage,
} from './AbstractPatientRecordPage';

import PatientRecordPageActions from 'actions/patient-records/patient-demographics/PatientRecordPageActions';
import {
  TGeneralInfo,
  TFacilityInfo,
  TContactInfo,
  TEmployerInfo,
  TPatientStatusInfo,
  TGuarantorInfo,
  TInsuredInfo,
  TCommentInfo,
} from 'services/patient-records/patient-demographics/PatientRecordPageService';
import PatientRecordPageStore from 'stores/patient-records/patient-demographics/PatientRecordPageStore';
import AccessUtils from 'utils/AccessUtils';
import { PATIENT_DEMOGRAPHIC as PAGE_ID } from 'constant/pagesId/patientRecords';
import { URL_PATIENT_DEMOGRAPHIC } from 'constant/path/patientRecords';

const PERMISSIONS = {
  INFORMATION: {
    GENERAL: 'pat_gene_edit',
    FACILITY: 'pat_faci_edit',
    CONTACT: 'pat_contact_edit',
    EMPLOYER: 'pat_org_edit',
    GUARANTOR: 'pat_guar_edit',
    COMMENT: 'pat_cmt_edit',
    STATUS: 'pat_status_info_edit',
  },
  INSURANCE: {
    PRIMARY: 'pat_primins_edit',
    SECONDARY: 'pat_secins_edit',
    TERTIARY: 'pat_tert_edit',
  },
};

export class EditPatientRecordPage extends AbstractPatientRecordPage {
  static getStores() {
    return [PatientRecordPageStore];
  }

  submitGeneralInfo(generalInfo: TGeneralInfo): Promise<any> {
    this.setState({ onAjax: true });

    return PatientRecordPageActions.updateGeneralInfo(generalInfo).then(
      (response: any) => {
        this.setState({ onAjax: false });

        if (response.status === 'S') {
          Notification.success('Successfully changed!');
        } else {
          Notification.danger('Ar error occurred!');
        }
        return response;
      }
    );
  }

  submitFacilityInfo(facilityInfo: TFacilityInfo): Promise<any> {
    this.setState({ onAjax: true });

    return PatientRecordPageActions.updateFacilityInfo(facilityInfo).then(
      (response) => {
        this.setState({ onAjax: false });
        this.processResponse(response);
      }
    );
  }

  submitContactInfo(contactInfo: TContactInfo): Promise<any> {
    this.setState({ onAjax: true });

    return PatientRecordPageActions.updateContactInfo(contactInfo).then(
      (response) => {
        this.setState({ onAjax: false });
        this.processResponse(response);
      }
    );
  }

  submitPatientInfo(patientInfo: any): Promise<any> {
    return null;
  }

  submitEmployerInfo(employerInfo: TEmployerInfo): Promise<any> {
    this.setState({ onAjax: true });
    return PatientRecordPageActions.updateEmployerInfo(employerInfo).then(
      (response) => {
        this.setState({ onAjax: false });
        this.processResponse(response);
      }
    );
  }

  submitPatientStatusInfo(patientStatusInfo: TPatientStatusInfo): Promise<any> {
    this.setState({ onAjax: true });
    return PatientRecordPageActions.updatePatientStatusInfo(
      patientStatusInfo
    ).then((response) => {
      this.setState({ onAjax: false });
      this.processResponse(response);
    });
  }

  submitGuarantorInfo(guarantorInfo: TGuarantorInfo): Promise<any> {
    this.setState({ onAjax: true });
    return PatientRecordPageActions.updateGuarantorInfo(guarantorInfo).then(
      (response) => {
        this.setState({ onAjax: false });
        this.processResponse(response);
      }
    );
  }

  submitInsuredInfo(insuredInfo: TInsuredInfo): Promise<any> {
    this.setState({ onAjax: true });
    return PatientRecordPageActions.updateInsuredInfo(insuredInfo).then(
      (response) => {
        this.setState({ onAjax: false });
        this.processResponse(response);
      }
    );
  }

  submitCommentInfo(commentInfo: TCommentInfo): Promise<any> {
    this.setState({ onAjax: true });
    return PatientRecordPageActions.updateCommentInfo(commentInfo).then(
      (response) => {
        this.setState({ onAjax: false });
        this.processResponse(response);
      }
    );
  }

  static calculateState(prevState: SAbstractPatientRecordPage) {
    if (!prevState) {
      prevState = AbstractPatientRecordPage.getFormState();
      prevState.mode = 2; //edit mode
    }
    return {
      ...prevState,
      patientRecord: PatientRecordPageStore.getState().patientRecord,

      generalInfo: PatientRecordPageStore.getState().generalInfo,
      generalInfoEditable: AccessUtils.checkAccess(
        PERMISSIONS.INFORMATION.GENERAL
      ),

      facilityInfo: PatientRecordPageStore.getState().facilityInfo,
      facilityInfoEditable: AccessUtils.checkAccess(
        PERMISSIONS.INFORMATION.FACILITY
      ),

      contactInfo: PatientRecordPageStore.getState().contactInfo,
      contactInfoEditable: AccessUtils.checkAccess(
        PERMISSIONS.INFORMATION.CONTACT
      ),

      employerInfo: PatientRecordPageStore.getState().employerInfo,
      employerInfoEditable: AccessUtils.checkAccess(
        PERMISSIONS.INFORMATION.EMPLOYER
      ),

      patientStatusInfo: PatientRecordPageStore.getState().patientStatusInfo,
      patientStatusInfoEditable: AccessUtils.checkAccess(
        PERMISSIONS.INFORMATION.STATUS
      ),

      guarantorInfo: PatientRecordPageStore.getState().guarantorInfo,
      guarantorInfoEditable: AccessUtils.checkAccess(
        PERMISSIONS.INFORMATION.GUARANTOR
      ),

      insuredInfo: PatientRecordPageStore.getState().insuredInfo,
      primaryInsuredEditable: AccessUtils.checkAccess(
        PERMISSIONS.INSURANCE.PRIMARY
      ),
      secondaryInsuredEditable: AccessUtils.checkAccess(
        PERMISSIONS.INSURANCE.SECONDARY
      ),
      tertiaryInsuredEditable: AccessUtils.checkAccess(
        PERMISSIONS.INSURANCE.TERTIARY
      ),

      commentInfo: PatientRecordPageStore.getState().commentInfo,
      commentInfoEditable: AccessUtils.checkAccess(
        PERMISSIONS.INFORMATION.COMMENT
      ),

      patientRecordLoaded:
        PatientRecordPageStore.getState().patientRecordLoaded,
    };
  }

  getPageAppId() {
    return PAGE_ID.EDIT;
  }

  updateData() {
    const { params } = this.props.match;

    const id = Number(params.id);

    this.setState({ onAjax: true });

    return PatientRecordPageActions.loadPatientRecord(id).then((response) => {
      this.setState({ onAjax: false });

      if (!response.refid) {
        this.props.history.replace(URL_PATIENT_DEMOGRAPHIC);
        Notification.danger("Patient you request doesn't exist!");
      }
    });
  }

  componentDidMount() {
    this.updateData();
  }
}

const editPatientRecordPage = Container.create(EditPatientRecordPage);
export default editPatientRecordPage;
