import React from 'react';
import { Container } from 'flux/utils';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import Notification from 'components/modal/Notification';
import SecurityPermissionStore from 'stores/SecurityPermissionStore';
import Checkbox from 'components/form/input/Checkbox';
import { Button } from 'components/button';
import { Grid, TPagination } from 'components/grid';
import { PureDropDownApplicationUserType } from 'components/project/dropdown/ApplicationUsersDropdown';
import SecurityPermissionActions from 'actions/SecurityPermissionActions';
import EmployeeTimeSheetPageActions from 'actions/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageActions';
import { storeGrid } from 'stores/_mobx/grid';
import EmployeeTimeSheetPageStore from 'stores/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageStore';
import { getPagination } from 'utils/getPagination';

interface PSecurityPermissionPopup {
  callback: () => void;
}

class TSecurityPermissionFilter {
  userTypeId: number = 0;
  employeeName: string = '';
}

class SSecurityPermissionPopup {
  userList: Array<any> = [];
  selectedSecurityPermissionIdsCompany: Array<number> = [];
  selectedSecurityPermissionIdsClient: Array<number> = [];
  selectedSecurityPermissionIdsClinician: Array<number> = [];
  selectedHrAdminIds: Array<number> = [];
  pagination = getPagination();
  onAjax: boolean = false;
  filter: TSecurityPermissionFilter = new TSecurityPermissionFilter();
  totalRows: number = 0;
}

class DialogSecurityPermission extends React.Component<
  PSecurityPermissionPopup,
  SSecurityPermissionPopup
> {
  COLUMNS = [
    {
      name: 'user_full_name',
      title: 'Name',
      render: (value: any, row: any) => this.renderItem(value, row),
    },
    {
      name: 'chk1',
      title: 'Security (Company)',
      maxWidth: 100,
      render: (value: any, row: any) => {
        const { selectedSecurityPermissionIdsCompany } = this.state;
        return this.renderSelectCheckbox('security_company', row, (data) =>
          this.isSelected(data, selectedSecurityPermissionIdsCompany)
        );
      },
    },
    {
      name: 'chk2',
      title: 'Security (Client)',
      maxWidth: 100,
      render: (value: any, row: any) => {
        const { selectedSecurityPermissionIdsClient } = this.state;
        return this.renderSelectCheckbox('security_client', row, (data) =>
          this.isSelected(data, selectedSecurityPermissionIdsClient)
        );
      },
    },
    {
      name: 'chk3',
      title: 'Security (Clinician)',
      maxWidth: 100,
      render: (value: any, row: any) => {
        const { selectedSecurityPermissionIdsClinician } = this.state;
        return this.renderSelectCheckbox('security_clinician', row, (data) =>
          this.isSelected(data, selectedSecurityPermissionIdsClinician)
        );
      },
    },
    {
      name: 'chk4',
      title: 'HR Admin',
      maxWidth: 100,
      render: (value: any, row: any) => {
        const { selectedHrAdminIds } = this.state;
        return this.renderSelectCheckbox('hr_admin', row, (data) =>
          this.isSelected(data, selectedHrAdminIds)
        );
      },
    },
  ];

  renderItem(value: any, row: { user_full_name?: string; user_type?: string }) {
    let userFullName = row.user_full_name ? row.user_full_name.trim() : '';
    if (userFullName === ',') {
      userFullName = '';
    }
    if (userFullName.length > 0) {
      userFullName += ' ';
    }
    let userType = row.user_type ? row.user_type.trim() : '';
    if (userType.length > 0) {
      userType = `(${userType})`;
    }
    return `${userFullName}${userType}`;
  }

  renderSelectCheckbox(
    checkboxName:
      | 'security_company'
      | 'security_client'
      | 'security_clinician'
      | 'hr_admin',
    row: any,
    isSelected: (data: any) => boolean
  ) {
    return (
      <Checkbox
        name={checkboxName}
        checked={isSelected(row)}
        onSetValue={(name, value) => this.selectRow(checkboxName, value, row)}
        className="no-margin"
        noLabel
      />
    );
  }

  selectRow(
    name:
      | 'security_company'
      | 'security_client'
      | 'security_clinician'
      | 'hr_admin',
    value: any,
    row: { refid: number }
  ) {
    const { state } = this;
    const origin =
      'security_company' === name
        ? state.selectedSecurityPermissionIdsCompany
        : 'security_client' === name
        ? state.selectedSecurityPermissionIdsClient
        : 'security_clinician' === name
        ? state.selectedSecurityPermissionIdsClinician
        : 'hr_admin' === name
        ? state.selectedHrAdminIds
        : [];
    let arr;
    const idx = this.getSelectedIndex(row, origin);
    if (idx > -1) {
      arr = origin.filter((item, index) => idx !== index);
    } else {
      arr = origin.concat([row.refid]);
    }
    if ('security_company' === name) {
      this.setState({
        selectedSecurityPermissionIdsCompany: arr,
      });
    } else if ('security_client' === name) {
      this.setState({
        selectedSecurityPermissionIdsClient: arr,
      });
    } else if ('security_clinician' === name) {
      this.setState({
        selectedSecurityPermissionIdsClinician: arr,
      });
    } else if ('hr_admin' === name) {
      this.setState({
        selectedHrAdminIds: arr,
      });
    }
  }

  isSelected(row: { refid: number }, arr: Array<number>): boolean {
    return this.getSelectedIndex(row, arr) > -1;
  }

  getSelectedIndex(row: { refid: number }, arr: Array<number>) {
    return arr.indexOf(row.refid);
  }

  storeChangeListener: any;

  componentDidMount() {
    this.storeChangeListener = EmployeeTimeSheetPageStore.addListener(() =>
      this.onStoreChange()
    );
    this.updateData(true);
  }

  componentWillUnmount() {
    if (this.storeChangeListener) {
      this.storeChangeListener.remove();
    }
    if (this.refs.grid) {
      (this.refs.grid as Grid).clearSelection();
    }

    storeGrid.saveFilter('SecurityPermissionPopupGrid', this.state.filter);
  }

  onStoreChange() {
    const store = EmployeeTimeSheetPageStore.getState();
    this.setState({
      totalRows: store.usersFullCount,
      userList: store.usersFullList,
    });
  }

  onPageChange(paginationSettings: TPagination) {
    if (this.refs.grid) {
      (this.refs.grid as Grid).handleChangePagination(paginationSettings);
    }
  }

  updateData(flagLoadCompanyUserPermissions: boolean) {
    const { state } = this;
    if (!state.onAjax) {
      this.setState({ onAjax: true });
    }
    EmployeeTimeSheetPageActions.getCompanyUsersFullCount(state.filter);
    EmployeeTimeSheetPageActions.getCompanyUsersFullList(
      state.filter,
      state.pagination.skip,
      state.pagination.pageSize
    ).then(() => {
      if (flagLoadCompanyUserPermissions) {
        SecurityPermissionActions.loadCompanyUserPermissions().then(
          (response) => {
            this.setState({
              onAjax: false,
              selectedSecurityPermissionIdsCompany:
                response.securityPermissionIdsCompany,
              selectedSecurityPermissionIdsClient:
                response.securityPermissionIdsClient,
              selectedSecurityPermissionIdsClinician:
                response.securityPermissionIdsClinician,
              selectedHrAdminIds: response.hrAdminIds,
            });
          }
        );
      } else {
        this.setState({
          onAjax: false,
        });
      }
    });
  }

  static getStores() {
    return [SecurityPermissionStore, EmployeeTimeSheetPageStore];
  }

  static calculateState(prevState: SSecurityPermissionPopup) {
    if (!prevState) {
      prevState = {
        pagination: getPagination(
          storeGrid.getPageSize('SecurityPermissionPopupGrid')
        ),
        filter: storeGrid.getFilter(
          'SecurityPermissionPopupGrid',
          new TSecurityPermissionFilter()
        ) as TSecurityPermissionFilter,
        onAjax: true,
        totalRows: 0,
        userList: [],
        selectedSecurityPermissionIdsCompany: [],
        selectedSecurityPermissionIdsClient: [],
        selectedSecurityPermissionIdsClinician: [],
        selectedHrAdminIds: [],
      };
    }
    const store = EmployeeTimeSheetPageStore.getState();
    const securityStore = SecurityPermissionStore.getState();

    const newState = {
      ...prevState,
      userList:
        prevState.userList.length === 0
          ? store.usersFullList
          : prevState.userList,
      totalRows: store.usersFullCount,

      selectedSecurityPermissionIdsCompany:
        prevState.selectedSecurityPermissionIdsCompany.length === 0
          ? securityStore.securityPermissionIdsCompany
          : prevState.selectedSecurityPermissionIdsCompany,
      selectedSecurityPermissionIdsClient:
        prevState.selectedSecurityPermissionIdsClient.length === 0
          ? securityStore.securityPermissionIdsClient
          : prevState.selectedSecurityPermissionIdsClient,
      selectedSecurityPermissionIdsClinician:
        prevState.selectedSecurityPermissionIdsClinician.length === 0
          ? securityStore.securityPermissionIdsClinician
          : prevState.selectedSecurityPermissionIdsClinician,
      selectedHrAdminIds:
        prevState.selectedHrAdminIds.length === 0
          ? securityStore.hrAdminIds
          : prevState.selectedHrAdminIds,
    };
    return newState;
  }

  onSave = () => {
    const { props, state } = this;
    const selectedSecurityPermissionIdsCompany =
      state.selectedSecurityPermissionIdsCompany.slice();
    const selectedSecurityPermissionIdsClient =
      state.selectedSecurityPermissionIdsClient.slice();
    const selectedSecurityPermissionIdsClinician =
      state.selectedSecurityPermissionIdsClinician.slice();
    const selectedHrAdminIds = state.selectedHrAdminIds.slice();
    SecurityPermissionActions.updateCompanyUserPermissions(
      selectedSecurityPermissionIdsCompany,
      selectedSecurityPermissionIdsClient,
      selectedSecurityPermissionIdsClinician,
      selectedHrAdminIds
    ).then((response) => {
      if (response === 'S') {
        Notification.success('Permissions have been successfully updated!');
      } else {
        Notification.danger('An error occurred!');
      }
      props.callback();
    });
  };

  onResetSelected = () => {
    this.setState({
      selectedSecurityPermissionIdsCompany: [],
      selectedSecurityPermissionIdsClient: [],
      selectedSecurityPermissionIdsClinician: [],
      selectedHrAdminIds: [],
      onAjax: false,
    });
  };

  onPaginationChange = (pagination: TPagination) => {
    this.setState({ pagination, onAjax: true }, () => this.updateData(false));
  };

  onSetValue4UserTypeId = (userTypeId: number) => {
    this.onPageChange({
      page: 1,
      skip: 0,
      pageSize: this.state.pagination.pageSize,
    });
    const filter = { ...this.state.filter, userTypeId };
    this.setState({ filter, onAjax: true }, () => this.updateData(false));
  };

  render() {
    const { state } = this;
    return (
      <Dialog size="large" handleClose={this.props.callback}>
        <DialogHeader
          title="Company User Permissions"
          onClose={this.props.callback}
        />
        <DialogBody>
          <PureDropDownApplicationUserType
            name="userTypeId"
            label="User Type"
            className="col-sm-4"
            type={0}
            value={state.filter.userTypeId}
            onChange={this.onSetValue4UserTypeId}
          />
          <Grid
            id="SecurityPermissionPopupGrid"
            columns={this.COLUMNS}
            onAjax={state.onAjax}
            dataSourceCount={state.totalRows}
            dataSource={state.userList}
            pagination={state.pagination}
            onPaginationChange={this.onPaginationChange}
            ref="grid"
          />
        </DialogBody>
        <DialogFooter>
          <Button
            variant="warning"
            text="Reset"
            onClick={this.onResetSelected}
          />
          <Button text="Save" onClick={this.onSave} />
        </DialogFooter>
      </Dialog>
    );
  }
}

export default Container.create(DialogSecurityPermission);
