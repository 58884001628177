import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const modeOfPaymentRequiresNotes = [2, 3, 4];

const validationBulkForm = yupResolver(
  yup.object().shape({
    notes: yup.string().when('modeOfPayment', {
      is: (mode: number) => modeOfPaymentRequiresNotes.includes(mode),
      then: yup.string().required('please fill in the Notes!'),
    }),
  })
);

export default validationBulkForm;
