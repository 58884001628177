import React from 'react';

import { IconButton } from 'components/button';

const LINK_STYLE = { top: 5 };

export default class HrCanTLock extends React.Component {
  static INVALID_DATA = 1;
  static NOT_SUBMITTED = 2;
  static NOT_APPROVED = 3;
  static ALREADY_LOCKED = 4;
  static VALID = 5;

  constructor(props) {
    super(props);
    this.state = {
      hide: {},
    };
  }

  createRenderer() {
    const out = {};
    out[HrCanTLock.INVALID_DATA] = {
      wrapper: (content) => (
        <div key={HrCanTLock.INVALID_DATA} className="alert alert-danger">
          {content}
        </div>
      ),
      item: (item, key) => (
        <div key={key}>
          <strong>
            Datasource was changed. Unexpected behavior may occur, please close
            this popup, refresh grid data and select employees again.
          </strong>
        </div>
      ),
    };
    out[HrCanTLock.ALREADY_LOCKED] = {
      wrapper: (content) => (
        <div key={HrCanTLock.ALREADY_LOCKED} className="alert alert-warning">
          <div>
            {this.createCollapse(HrCanTLock.ALREADY_LOCKED)}
            <strong>
              Timesheet is already locked for these users (users will be
              ignored):
            </strong>
          </div>
          {content}
        </div>
      ),
      item: (item, key) => <div key={key}>{item.person.employee_name}</div>,
    };
    out[HrCanTLock.NOT_APPROVED] = {
      wrapper: (content) => (
        <div key={HrCanTLock.NOT_APPROVED} className="alert alert-warning">
          <div>
            {this.createCollapse(HrCanTLock.NOT_APPROVED)}
            <strong>
              Timesheet was not approved for these users (timesheet will be
              locked):
            </strong>
          </div>
          {content}
        </div>
      ),
      item: (item, key) => <div key={key}>{item.person.employee_name}</div>,
    };
    out[HrCanTLock.NOT_SUBMITTED] = {
      wrapper: (content) => (
        <div key={HrCanTLock.NOT_SUBMITTED} className="alert alert-danger">
          <div>
            {this.createCollapse(HrCanTLock.NOT_SUBMITTED)}
            <strong>
              Timesheet was not submitted for these users and can not be locked
              (users will be ignored):
            </strong>
          </div>
          {content}
        </div>
      ),
      item: (item, key) => <div key={key}>{item.person.employee_name}</div>,
    };
    out[HrCanTLock.VALID] = {
      wrapper: (content) => (
        <div key={HrCanTLock.VALID} className="alert alert-info">
          <div>
            {this.createCollapse(HrCanTLock.VALID)}
            <strong>Timesheet can be locked for:</strong>
          </div>
          {content}
        </div>
      ),
      item: (item, key) => <div key={key}>{item.person.employee_name}</div>,
    };
    return out;
  }

  createCollapse(priority) {
    const cn = `bi ${
      this.state.hide[priority] ? 'bi-caret-up-fill' : 'bi-caret-down-fill'
    }`;

    return (
      <IconButton
        style={LINK_STYLE}
        onClick={(e) => {
          e.preventDefault();
          const hide = {
            ...this.state.hide,
            [priority]: !this.state.hide[priority],
          };
          this.setState({ hide });
        }}>
        <i className={cn} />
      </IconButton>
    );
  }

  render() {
    if (this.props.data && this.props.data.length) {
      return <div>{this.renderPersons()}</div>;
    } else {
      return null;
    }
  }

  renderPersons() {
    const out = [];
    const renderMap = this.createRenderer();
    const data = {};
    this.props.data
      .sort((v1, v2) =>
        v1.priority === v2.priority ? 0 : v1.priority > v2.priority ? 1 : -1
      )
      .forEach((person, index) => {
        const priority = person.priority;
        if (!data[priority]) {
          //need this data to render wrapper
          data[priority] = [];
        }

        if (!this.state.hide[priority]) {
          data[priority].push({
            object: renderMap[priority].item(person, index),
            value: person.person.employee_name,
          });
        }
      });
    for (let priority in data) {
      const list = data[priority]
        .sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        })
        .map((v) => v.object);
      out.push(renderMap[priority].wrapper(list));
    }
    return out;
  }
}
