import React from 'react';
import { Container } from 'flux/utils';

import Fieldset from 'components/form/Fieldset';
import Form from 'components/form/Form';
import Custom from 'components/form/input/Custom';
import Text from 'components/form/input/Text';
import Title from 'components/project/common/title';
import FormControl from 'components/form/FormControl';
import Notification from 'components/modal/Notification';

import EquipmentPageActions from 'actions/workflow/equipment/EquipmentPageActions';
import EquipmentPageStore from 'stores/workflow/equipment/EquipmentPageStore';
import UserProfileStore from 'stores/UserProfileStore';
import MathUtils from 'utils/MathUtils';
import { URL_EQUIPMENT_CHECKOUT } from 'constant/path/workflow';

export class CheckInPage extends React.Component {
  title = 'Equipment Check In';

  static getStores() {
    return [EquipmentPageStore];
  }

  buildFullLeads() {
    let arr = this.state.model.lead2;
    let out = [];
    if (arr && arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        out.push(arr[i].lead_name);
      }
    }
    return <Custom label="Full Lead" custom={out.join('; ')} />;
  }

  processOdometer(model, errors) {
    let end = model.ending_odometer - model.starting_odometer;
    model.total_miles = end < 0 ? 0 : end;
    const state = { model };
    if (errors) {
      state.errors = errors;
    }
    this.setState(state);
  }

  calculateTotalOdometer(n, v) {
    const model = { ...this.state.model, starting_odometer: v };
    this.processOdometer(model, null);
  }

  calculateEndingOdometer(n, v, e) {
    const model = { ...this.state.model, ending_odometer: v };
    const errors = { ...this.state.errors, [n]: e };
    this.processOdometer(model, errors);
  }

  onUpdateModel = (name, value, err) => {
    const { state } = this;

    const model = {
      ...state.model,
      [name]: value,
    };

    const errors = {
      ...state.errors,
      [name]: err,
    };

    this.setState({ model, errors });
  };

  handleSubmit = (updatedModel, hasErrors, updatedErrors) => {
    if (hasErrors) {
      const { state } = this;
      const model = { ...state.model, ...updatedModel };
      const errors = { ...state.errors, ...updatedErrors };
      this.setState({ model, errors });
    } else {
      this.submitSuccess();
    }
  };

  render() {
    let user = UserProfileStore.getUser();
    var state = this.state;
    var model = state.model;
    let vehicleName = <div>{model.vehicle}</div>;
    return (
      <div className="check-in-page">
        <Title title={this.title} />
        <div className="facility-tab col-sm-12">
          <Form
            ref="form"
            model={this.state.model}
            errors={this.state.errors}
            onCollectValues={this.onUpdateModel}
            submit={this.handleSubmit}>
            <Fieldset className="row">
              <Fieldset className="col-sm-6">
                <Custom label="Vehicle" noclone="true" custom={vehicleName} />
                {user ? (
                  <Custom label="Technologist" custom={user.name} />
                ) : null}
                <Custom noclone="true" label="EKG" custom={model.ekg} />
                <Text
                  name="starting_odometer"
                  label="Starting Odometer"
                  validations="required min:1"
                  onSetValue={(n, v) => this.calculateTotalOdometer(n, v)}
                />
                <Text
                  name="total_miles"
                  label="Total Miles"
                  attr={{ disabled: true }}
                />
                <Custom
                  noclone="true"
                  label="Glove"
                  custom={model.lblleftglove}
                />
                {this.buildFullLeads()}
              </Fieldset>
              <Fieldset className="col-sm-6">
                <Custom noclone="true" label="Unit" custom={model.device} />
                <Custom noclone="true" label="Phone" custom={model.phone} />
                <Text
                  name="ending_odometer"
                  label="Ending Odometer"
                  errorMessages={{
                    min: 'Invalid mileage!',
                    custom: 'Mileage should be greater then starting odometer',
                  }}
                  onSetValue={(n, v, e) =>
                    this.calculateEndingOdometer(n, v, e)
                  }
                  validations={{
                    min: 1,
                    custom: (v) => {
                      v = MathUtils.parseFloat(v);
                      const v1 = MathUtils.parseFloat(model.starting_odometer);
                      return v - v1 >= 0;
                    },
                  }}
                />

                <Custom
                  noclone="true"
                  label="Bone Sonometer"
                  custom={model.bone}
                />
                <Custom
                  noclone="true"
                  label="Thyroid Shield"
                  custom={model.thyroid_shield}
                />
                <Custom noclone="true" label="Half Lead" custom={model.lead} />
              </Fieldset>
            </Fieldset>

            <FormControl direction="left">
              <input type="submit" className="btn btn-primary" value="Save" />
            </FormControl>
          </Form>
        </div>
      </div>
    );
  }

  submitSuccess() {
    const { model } = this.state;
    if (model && model.ending_odometer > 0) {
      let vehicle = {
        refid: model.refid,
        vehicle: model.vehicle_id,
        startOdom: model.starting_odometer,
        endOdom: model.ending_odometer,
        total: model.total_miles,
      };
      EquipmentPageActions.UpdateOdometerEquipment(vehicle).then((response) => {
        if (response === 'S') {
          Notification.success('The details successfully updated.');
          EquipmentPageActions.setBackToList(true, false);
          this.props.history.push(URL_EQUIPMENT_CHECKOUT);
        } else {
          Notification.danger('Update failed');
        }
      });
    } else {
      Notification.danger('Field(s) marked in asterisks are mandatory!');
    }
  }

  static calculateState(prevState) {
    var store = EquipmentPageStore.getState();
    if (!prevState) {
      prevState = {
        errors: {},
        errorMessages: {},
      };
    }
    return { ...prevState, model: store.techInformation };
  }

  componentDidMount() {
    EquipmentPageActions.loadEquipmentByTech(UserProfileStore.getTypedUserId());
  }

  componentWillUnmount() {
    EquipmentPageActions.clear();
  }
}

const checkInTab = Container.create(CheckInPage);
export default checkInTab;
