import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import {
  storeXRayMaster,
  XRayFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/xRayMaster';
import { URL_XRAY } from 'constant/path/workflow';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const XRayFrameEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, xRay } = storeXRayMaster;

  const handleSubmit = async (data: XRayFormModel) =>
    storeXRayMaster.updateXRay(data).then((error) => {
      if (error) return error;
      history.push(URL_XRAY);
    });

  useEffect(() => {
    if (!fetching) storeXRayMaster.getXRay(Number(params.id));

    return storeXRayMaster.clearXRay;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit X-Ray Frame" />
      <Form backUrl={URL_XRAY} defaultValues={xRay} onSubmit={handleSubmit} />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(XRayFrameEditPage);
