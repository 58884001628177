import React from 'react';

import { Grid } from 'components/grid';
import Select from 'components/form/input/Select';
import Radio from 'components/form/input/Radio';
import { Button, IconButton } from 'components/button';
import Notification from 'components/modal/Notification';
import CPTFilter from '../filter/subfilters/CPTFilter';
import DiagnosisFilter from '../filter/subfilters/DiagnosisFilter';
import CorporateFilter from '../filter/subfilters/CorporateFilter';
import DispatchedDateFilter from '../filter/subfilters/DispatchedDateFilter';
import ExamCompletedDateFilter from '../filter/subfilters/ExamCompletedDateFilter';
import FacilityFilter from '../filter/subfilters/FacilityFilter';
import FacilityNPIFilter from '../filter/subfilters/FacilityNPIFilter';
import FacilityPhoneFilter from '../filter/subfilters/FacilityPhoneFilter';
import MRNFilter from '../filter/subfilters/MRNFilter';
import FirstNameFilter from '../filter/subfilters/FirstNameFilter';
import State from '../filter/subfilters/State';
import Age from '../filter/subfilters/Age';
import LastNameFilter from '../filter/subfilters/LastNameFilter';
import DOBFilter from '../filter/subfilters/DOBFilter';
import StudyIDFilter from '../filter/subfilters/StudyIDFilter';
import RegionFilter from '../filter/subfilters/RegionFilter';
import LocationFilter from '../filter/subfilters/LocationFilter';
import ProviderTypeFilter from '../filter/subfilters/ProviderTypeFilter';
import OrderTypeFilter from '../filter/subfilters/OrderTypeFilter';
import FacilityTypeFilter from '../filter/subfilters/FacilityTypeFilter';
import POSFilter from '../filter/subfilters/POSFilter';
import CptCategoryFilter from '../filter/subfilters/CptCategoryFilter';
import CPTDescriptionFilter from '../filter/subfilters/CPTDescriptionFilter';
import TechnicianFirstNameFilter from '../filter/subfilters/TechnicianFirstNameFilter';
import TechnicianLastNameFilter from '../filter/subfilters/TechnicianLastNameFilter';
import PhysicianFirstNameFilter from '../filter/subfilters/PhysicianFirstNameFilter';
import PhysicianLastNameFilter from '../filter/subfilters/PhysicianLastNameFilter';
import ExamTypeFilter from '../filter/subfilters/ExamTypeFilter';
import DOSFilter from '../filter/subfilters/DOSFilter';
import ModalityFilter from '../filter/subfilters/ModalityFilter';
import AdjustmentCodeFilter from '../filter/subfilters/AdjustmentCodeFilter';
import ModeOfPaymentFilter from '../filter/subfilters/ModeOfPaymentFilter';
import InvoiceDateFilter from '../filter/subfilters/InvoiceDateFilter';
import InvoiceNumberFilter from '../filter/subfilters/InvoiceNumberFilter';
import InvoiceIdFilter from '../filter/subfilters/InvoiceIdFilter';
import FacilityRolesDropdown from 'components/project/dropdown/FacilityRolesDropdown';
import UserProfileStore from 'stores/UserProfileStore';
import SimpleTimezoneDropdown from 'components/project/dropdown/SimpleTimezoneDropdown';
import { getTimeZoneName } from 'utils/DateUtils';

export default class MultipleConditionFilter extends React.Component {
  timeZoneName = getTimeZoneName();
  LABELS = {
    patientFirstName: 'Patient First Name',
    patientLastName: 'Patient Last Name',
    mrn: 'MRN',
    facility: 'Facility',
    diagnosis: 'Diagnosis',
    corporate: 'Corporate',
    cpt: 'CPT',
    facilityNPI: 'Facility NPI',
    facilityPhone: 'Facility phone',
    examCompletedDate: 'Exam completed date',
    dispatchedDate: 'Dispatched date',
    dob: 'DOB',
    studyID: 'Study ID',
    region: 'Region',
    location: 'Location',
    providerType: 'Provider type',
    facilityType: 'Facility type',
    orderType: 'Order type',
    pos: 'POS',
    state: 'State',
    age: 'Age',
    cptDescription: 'CPT Description',
    cptCategory: 'CPT Category',
    technicianFirstName: 'Technician first name',
    technicianLastName: 'Technician last name',
    physicianFirstName: 'Physician first name',
    physicianLastName: 'Physician last name',
    examType: 'Exam type',
    dos: 'DOS',
    modality: 'Modality',
    adjustmentCode: 'Adjustment Code',
    modeOfPayment: 'Mode of Payment',
    invoiceDate: 'Invoice Date',
    invoiceNumber: 'Invoice Number',
    invoiceId: 'Invoice ID',
  };

  constructor(props) {
    super(props);
    const onSetValue = (name, value) => this.onSetValue(name, value);
    this.FILTERS = {
      patientFirstName: (
        <FirstNameFilter name="patientFirstName" onSetValue={onSetValue} />
      ),
      patientLastName: (
        <LastNameFilter name="patientLastName" onSetValue={onSetValue} />
      ),
      mrn: <MRNFilter name="mrn" onSetValue={onSetValue} />,
    };
    if (FacilityRolesDropdown.canSeeFacilities()) {
      this.FILTERS.facility = (
        <FacilityFilter name="facility" onSetValue={onSetValue} />
      );
    }
    this.FILTERS.diagnosis = (
      <DiagnosisFilter name="diagnosis" onSetValue={onSetValue} />
    );

    const corporateId = UserProfileStore.getCorporateId();
    if (corporateId === '0' || corporateId === 0) {
      this.FILTERS.corporate = (
        <CorporateFilter name="corporate" onSetValue={onSetValue} />
      );
    }

    this.FILTERS.cpt = (
      <CPTFilter name="cpt" className="col-sm-3" onSetValue={onSetValue} />
    );
    if (FacilityRolesDropdown.canSeeFacilities()) {
      this.FILTERS.facilityNPI = (
        <FacilityNPIFilter name="facilityNPI" onSetValue={onSetValue} />
      );
      this.FILTERS.facilityPhone = (
        <FacilityPhoneFilter name="facilityPhone" onSetValue={onSetValue} />
      );
    }

    this.FILTERS = {
      ...this.FILTERS,
      examCompletedDate: (
        <ExamCompletedDateFilter
          name="examCompletedDate"
          onSetValue={onSetValue}
        />
      ),
      dispatchedDate: (
        <DispatchedDateFilter name="dispatchedDate" onSetValue={onSetValue} />
      ),
      dob: <DOBFilter name="dob" onSetValue={onSetValue} />,
      studyID: <StudyIDFilter name="studyID" onSetValue={onSetValue} />,
      region: <RegionFilter name="region" onSetValue={onSetValue} />,
      location: <LocationFilter name="location" onSetValue={onSetValue} />,
      providerType: (
        <ProviderTypeFilter name="providerType" onSetValue={onSetValue} />
      ),
    };
    this.FILTERS.facilityType = (
      <FacilityTypeFilter name="facilityType" onSetValue={onSetValue} />
    );
    this.FILTERS = {
      ...this.FILTERS,
      orderType: <OrderTypeFilter name="orderType" onSetValue={onSetValue} />,
      pos: <POSFilter name="pos" onSetValue={onSetValue} />,
      state: <State name="state" onSetValue={onSetValue} />,
      age: <Age name="state" onSetValue={onSetValue} />,
      cptDescription: (
        <CPTDescriptionFilter name="cptDescription" onSetValue={onSetValue} />
      ),
      cptCategory: (
        <CptCategoryFilter name="cptCategory" onSetValue={onSetValue} />
      ),
      technicianFirstName: (
        <TechnicianFirstNameFilter
          name="technicianFirstName"
          onSetValue={onSetValue}
        />
      ),
      technicianLastName: (
        <TechnicianLastNameFilter
          name="technicianLastName"
          onSetValue={onSetValue}
        />
      ),
      physicianFirstName: (
        <PhysicianFirstNameFilter
          name="physicianFirstName"
          onSetValue={onSetValue}
        />
      ),
      physicianLastName: (
        <PhysicianLastNameFilter
          name="physicianLastName"
          onSetValue={onSetValue}
        />
      ),
      examType: <ExamTypeFilter name="examType" onSetValue={onSetValue} />,
      dos: <DOSFilter name="dos" onSetValue={onSetValue} />,
      modality: <ModalityFilter name="modality" onSetValue={onSetValue} />,
      adjustmentCode: (
        <AdjustmentCodeFilter name="adjustmentCode" onSetValue={onSetValue} />
      ),
      modeOfPayment: (
        <ModeOfPaymentFilter name="modeOfPayment" onSetValue={onSetValue} />
      ),
      invoiceDate: (
        <InvoiceDateFilter name="invoiceDate" onSetValue={onSetValue} />
      ),
      invoiceNumber: (
        <InvoiceNumberFilter name="invoiceNumber" onSetValue={onSetValue} />
      ),
      invoiceId: <InvoiceIdFilter name="invoiceId" onSetValue={onSetValue} />,
    };

    this.state = {
      currentCondition: this.getEmptyCondition(),
      currentConditionList: [],
      shownItems: [],
      shownFilters: this.props.shownFilters || [],
      joint: ' OR ',
    };
    const shownItems = this.state.shownItems;
    const shownFilters = this.state.shownFilters;
    shownFilters.forEach((item) => {
      if (this.LABELS[item] && this.FILTERS[item]) {
        shownItems.push({ value: item, label: this.LABELS[item] });
      }
    });
  }

  onSetValue(filterItem) {
    this.setState({ currentCondition: filterItem });
  }

  getEmptyCondition() {
    return {
      field: 0,
      condition: 0,
      value: null,
      field1: null,
      joint: 1,
      timezone: '',
    };
  }

  onChangeField(name, value) {
    const currentCondition = { ...this.state.currentCondition, name: value };
    this.setState({ currentCondition });
  }

  onSearch = () => {
    if (this.state.currentConditionList.length === 0) {
      Notification.warning('Define at least one filter!');
      return;
    }
    const currentConditionList = [...this.state.currentConditionList];
    this.props.onSetValue(currentConditionList, this.state.joint);
  };

  addCondition = () => {
    const currentConditionList = [...this.state.currentConditionList];
    const currentCondition = { ...this.state.currentCondition };
    if (!currentCondition.field) {
      Notification.warning('Please select a field!');
      return;
    }
    if (!currentCondition.condition) {
      Notification.warning('Please select a condition!');
      return;
    }
    if (!currentCondition.value) {
      Notification.warning('Please enter a value!');
      return;
    }
    if (currentCondition.condition === 'between') {
      const [from, to] = currentCondition.value?.split(' AND ');
      if (
        !/\d{2}\/\d{2}\/\d{4}/.test(from) ||
        !/\d{2}\/\d{2}\/\d{4}/.test(to)
      ) {
        Notification.warning('Please fill in date range!');
        return;
      }
    }
    const obj = currentConditionList.find((item) => {
      return (
        item.field === currentCondition.field &&
        item.value === currentCondition.value
      );
    });
    if (!obj) {
      currentConditionList.push(currentCondition);
      this.setState({ currentConditionList: currentConditionList });
    } else {
      Notification.warning('This condition already added!');
    }
  };

  COLUMNS = [
    { name: 'field', title: 'Field' },
    { name: 'condition', title: 'Condition' },
    { name: 'value', title: 'Value' },
    {
      name: 'delete',
      title: 'Delete',
      className: 'text-center width-50',
      render: function (name, value) {
        return (
          <IconButton onClick={(e) => this.onRemove(e, name, value)}>
            <i className="bi bi-trash text-primary" />
          </IconButton>
        );
      }.bind(this),
    },
  ];

  getColumns() {
    return this.COLUMNS;
  }

  onRemove(e, name, value) {
    if (e) {
      e.preventDefault();
    }
    const currentConditionList = [...this.state.currentConditionList];
    const index = currentConditionList.indexOf(value);
    currentConditionList.splice(index, 1);
    this.setState({ currentConditionList });
  }

  render() {
    const { state, props } = this;
    const currentCondition = state.currentCondition;
    const currentFilter = this.FILTERS[currentCondition.name];
    return (
      <div className="multiple-condition-filter row">
        <Select
          options={state.shownItems}
          label="Fields"
          className="col-sm-3"
          value={state.currentCondition.name}
          onSetValue={this.onChangeField.bind(this)}
        />
        {currentFilter ? <div className="col-sm-6">{currentFilter}</div> : null}
        <div className="col-sm-3 tz-wrapper">
          <Radio
            className="part-inline"
            label="&nbsp;"
            value={state.joint}
            onSetValue={(n, v) => this.setState({ joint: v })}
            options={[
              { label: 'AND', value: ' AND ' },
              { label: 'OR', value: ' OR ' },
            ]}
          />
          {props.timezoneChange ? (
            <SimpleTimezoneDropdown
              name="timezone"
              attr={{ placeholder: this.timeZoneName }}
              value={props.timezone}
              onSetValue={(n, v) => props.timezoneChange(v)}
            />
          ) : null}
        </div>

        <div className="d-flex gap-3 mb-3">
          <Button text="Add" onClick={this.addCondition} />
          <Button text="Search" onClick={this.onSearch} />
        </div>

        <Grid
          id="condition_list_grid"
          columns={this.getColumns()}
          dataSource={state.currentConditionList}
          disablePagination
          noControl
        />
      </div>
    );
  }
}
