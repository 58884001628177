import { apiRequest } from 'services/RequestService';
import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';

export class TOrderNote {
  name: string = '';
  notes: string = '';
  date: string = '';
  time: string = '';
  bgcolor: string = '';
}

class OrderOverviewService {
  loadOverview(
    dosSearch: string,
    srchLastNameVal: string,
    srchDate: string,
    facilityId: number,
    examType: string,
    status: string,
    fromDt: string,
    toDate: string,
    provid: number,
    pcpid: number,
    startIndex: number,
    limit: number,
    currentUserType: string,
    sign: string,
    srchFirstNameVal: string,
    srchSsnVal: string,
    stateArr: Array<{ refid: number }>,
    regionArr: Array<{ refid: number }>,
    faxStatus: string,
    techId: number,
    recordsAccess: string,
    stateId: number,
    locId: number,
    posId: number,
    radioId: number,
    ordSource: string,
    signed: string,
    faceSheet: string,
    clinicianGroupId: number,
    division: number,
    signal: AbortSignal
  ) {
    const data = {
      dosSearch,
      srchLastNameVal,
      srchDate,
      facilityId,
      examType,
      status,
      fromDt,
      toDate,
      provid,
      pcpid,
      startIndex,
      limit,
      currentUserType,
      sign,
      srchFirstNameVal,
      srchSsnVal,
      stateArr,
      regionArr,
      faxStatus,
      techId,
      recordsAccess,
      stateId,
      locId,
      posId,
      radioId,
      ordSource,
      signed,
      faceSheet,
      clinicianGroupId,
      division,
    };

    return apiRequest<Array<TSignOrderItem>>({
      url: 'order.Order.getOrderList',
      data,
      signal,
    });
  }

  revertCancelledOrder(order: { refid: number }) {
    return apiRequest<'S'>({
      url: 'medicalrecord.MedicalRecord.Updateorderstatus',
      data: { refid: order.refid },
    });
  }

  getNotes(orderId: number) {
    return apiRequest<Array<TOrderNote>>({
      url: 'order.OrderNotes.GetNotes',
      data: { orderId },
    });
  }

  loadOverviewCount(
    dosSearch: string,
    srchLastNameVal: string,
    srchDate: string,
    facilityId: number,
    examType: string,
    status: string,
    fromDt: string,
    toDate: string,
    provid: number,
    pcpid: number,
    /*currentUserType, no this field in count request*/ sign: string,
    srchFirstNameVal: string,
    srchSsnVal: string,
    stateArr: Array<{ refid: number }>,
    regionArr: Array<{ refid: number }>,
    faxStatus: string,
    techId: number,
    recordsAccess: string,
    stateId: number,
    locId: number,
    posId: number,
    radioId: number,
    ordSource: string,
    signed: string,
    faceSheet: string,
    clinicianGroupId: number,
    division: number,
    signal: AbortSignal
  ) {
    const data = {
      dosSearch,
      srchLastNameVal,
      srchDate,
      facilityId,
      examType,
      status,
      fromDt,
      toDate,
      provid,
      pcpid, //currentUserType, no this field in count request,
      sign,
      srchFirstNameVal,
      srchSsnVal,
      stateArr,
      regionArr,
      faxStatus,
      techId,
      recordsAccess,
      stateId,
      locId,
      posId,
      radioId,
      ordSource,
      signed,
      faceSheet,
      clinicianGroupId,
      division,
    };

    return apiRequest<number>({
      url: 'order.Order.getOrderCount',
      data,
      signal,
    });
  }

  cancelOrder(orderId: number, reason: string) {
    return apiRequest<void>({
      url: 'order.Order.cancelOrder',
      data: [orderId, reason],
    });
  }

  getPatientCountByNoTechnList(
    corporateId: number,
    srchLastNameVal: string,
    srchDate: string,
    fromDate: string,
    toDate: string,
    userType: string,
    facilityId: number,
    userId: number,
    srchFirstNameVal: string,
    modalityID: number,
    techID: number,
    stateID: number,
    locID: number,
    posID: number,
    radioID: number,
    ordSource: string,
    signed: string,
    faceSheet: string,
    dosSearch: string,
    orderFlags: Array<{ selected: boolean; flag: string; sub?: string }>,
    physicianId: number,
    physicianGroupId: number,
    pcpid: number,
    cancelValue: string,
    cptCode: string,
    cpt_category_id: number,
    accessionNum: string,
    mrn: string,
    otherMrn: string,
    oid: string,
    iceNumber: string,
    marshallId: string,
    clinicianGroupId: number,
    division: number,
    signal: AbortSignal
  ) {
    const data = [
      corporateId,
      srchLastNameVal,
      srchDate,
      fromDate,
      toDate,
      userType,
      facilityId,
      userId,
      srchFirstNameVal,
      modalityID,
      techID,
      stateID,
      locID,
      posID,
      radioID,
      ordSource,
      signed,
      faceSheet,
      dosSearch,
      orderFlags,
      physicianId,
      physicianGroupId,
      pcpid,
      cancelValue,
      cptCode,
      cpt_category_id,
      accessionNum,
      mrn,
      otherMrn,
      oid,
      iceNumber,
      marshallId,
      clinicianGroupId,
      division,
    ];

    return apiRequest<number>({
      url: 'medicalrecord.MedicalRecord.GetPatientCountByNoTechnList',
      data,
      signal,
    });
  }

  getPatientByNoTechnList(
    corporateId: number,
    srchLastNameVal: string,
    srchDate: string,
    fromDate: string,
    toDate: string,
    userType: string,
    facilityId: number,
    userId: number,
    StartIndex: number,
    limit: number,
    srchFirstNameVal: string,
    modalityID: number,
    techID: number,
    stateID: number,
    locID: number,
    posID: number,
    radioID: number,
    ordSource: string,
    signed: string,
    faceSheet: string,
    dosSearch: string,
    orderFlags: Array<{ selected: boolean; flag: string; sub?: string }>,
    physicianId: number,
    physicianGroupId: number,
    pcpid: number,
    cancelValue: string,
    cptCode: string,
    cpt_category_id: number,
    accessionNum: string,
    mrn: string,
    otherMrn: string,
    oid: string,
    iceNumber: string,
    marshallId: string,
    clinicianGroupId: number,
    division: number,
    signal: AbortSignal
  ) {
    const data = [
      corporateId,
      srchLastNameVal,
      srchDate,
      fromDate,
      toDate,
      userType,
      facilityId,
      userId,
      StartIndex,
      limit,
      srchFirstNameVal,
      modalityID,
      techID,
      stateID,
      locID,
      posID,
      radioID,
      ordSource,
      signed,
      faceSheet,
      dosSearch,
      orderFlags,
      physicianId,
      physicianGroupId,
      pcpid,
      cancelValue,
      cptCode,
      cpt_category_id,
      accessionNum,
      mrn,
      otherMrn,
      oid,
      iceNumber,
      marshallId,
      clinicianGroupId,
      division,
    ];

    return apiRequest<Array<TSignOrderItem>>({
      url: 'medicalrecord.MedicalRecord.GetPatientByNoTechnList',
      data,
      signal,
    });
  }

  completeOrders(orderIds: Array<number>) {
    return apiRequest<'S'>({
      url: 'order.Order.orderComplete',
      data: [orderIds],
    });
  }

  loadCheckOutDetails(techId: number) {
    return apiRequest<TCheckOutDetails>({
      url: 'vehicle.EquipmentMaster.GetCheckOutDetails',
      data: [techId],
    });
  }

  updateOrderStatus(orderId: number, techItem: { id: number; name: string }) {
    const status = 'A';
    const state = 'A';
    const userType = 'T';
    const assign = 'Assign';
    const data = [
      orderId,
      status,
      state,
      techItem.id,
      userType,
      techItem.name,
      assign,
    ];

    return apiRequest<'SE' | 'S'>({ url: 'order.Order.UpdateStatus', data });
  }
}

export class TCheckOutDetails {
  vehicle_name: string = '';
  phone_no: string = '';
  start_odometer: string = '';
  date: string = '';
  last_name: string = '';
  first_name: string = '';
  device_type: string = '';
  device_id: string = '';
}

export default new OrderOverviewService();
