import ClaimsService, {
  TOrderDetails,
  TExamCodeDetail,
} from 'services/billing-coding/claims/ClaimsService';
import appDispatcher from 'dispatcher/AppDispatcher';
import { dateToLocalTimezone } from 'utils/DateUtils';

export default class ClaimActions {
  static getOrderDetails(claimUniqId: string) {
    return ClaimsService.getOrderDetails(claimUniqId).then(
      (response: 'SE' | 0 | Array<TOrderDetails>) => {
        response =
          !response || response === 'SE' ? [new TOrderDetails()] : response;
        appDispatcher.dispatch({
          type: 'facility.FacilityMaster.GetOrderDetails',
          data: response[0],
          loaded: true,
        } as any);
        return response;
      }
    );
  }

  static clearOrderDetails() {
    appDispatcher.dispatch({
      type: 'facility.FacilityMaster.GetOrderDetails',
      data: null,
      loaded: false,
    } as any);
  }

  static updateClaimDoctorDetails(
    ordupdate: 1 | 0,
    renderupdate: 1 | 0,
    billupdate: 1 | 0,
    ord_pecos: string,
    rend_pecos: string,
    billing_pecos: string,
    orderingnpi: string,
    renderingnpi: string,
    billingnpi: string,
    orderingstate: string,
    renderingstate: string,
    billingstate: string,
    ordering_id: number,
    rendering_ids: string,
    billing_id: number,
    j_refid: {
      j_npi_nm: string;
      j_modality: string;
      j_jurid: number;
      j_refid: number;
    }
  ) {
    return ClaimsService.updateClaimDoctorDetails(
      ordupdate,
      renderupdate,
      billupdate,
      ord_pecos,
      rend_pecos,
      billing_pecos,
      orderingnpi,
      renderingnpi,
      billingnpi,
      orderingstate,
      renderingstate,
      billingstate,
      ordering_id,
      rendering_ids,
      billing_id,
      j_refid
    );
  }

  static getExamCodeDetails(orderId: number) {
    return ClaimsService.getExamCodeDetails(orderId).then(
      (response: Array<TExamCodeDetail>) => {
        let data: TExamCodeDetail;
        if (response?.[0]) {
          data = response[0];
          const filter = (v: { cpt_code: string }) => {
            return !!v.cpt_code;
          };
          const map = (v: { cpt_code: string }) => {
            return v.cpt_code;
          };
          if (data.qrcode) {
            data.qrcode = data.qrcode.filter(filter).map(map);
          }
          if (data.othercpt) {
            data.othercpt = data.othercpt.filter(filter).map(map);
          }
          data.dos = dateToLocalTimezone({ date: data.dos, dateOnly: true });
        } else {
          data = new TExamCodeDetail();
        }

        appDispatcher.dispatch({
          type: 'facility.ClaimManager.GetExamCodeDetails',
          data,
          loaded: true,
        } as any);
        return response;
      }
    );
  }

  static clearExamCodeDetails(orderId?: number) {
    appDispatcher.dispatch({
      type: 'facility.ClaimManager.GetExamCodeDetails',
      data: new TExamCodeDetail(),
      loaded: false,
    } as any);
  }

  static saveCancelReason(
    orderId: number,
    reasons: string,
    cancelStatus: 'V' | 'N' | ''
  ) {
    return ClaimsService.saveCancelReason(orderId, reasons, cancelStatus);
  }
}
