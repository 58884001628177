import { action, makeObservable, observable } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';

const claimDefaultValues: ClaimAddressDetailsType = {
  orderId: 0,
  facilityId: 0,
  address: '',
  state: '',
  city: '',
  zipcode: '',
  posId: 0,
  billId: 0,
  isHomeCare: false,
  isInJurisdiction: true,
  radiologist: {
    lastName: '',
    firstName: '',
    npi: '',
    taxonomyCode: 0,
  },
};

interface ClaimRequestPayloadType {
  orderId: number;
  facilityId: number;
  facilityOnly: boolean;
  isPc: boolean;
}

interface ClaimAddressResponseType {
  address: string;
  address1: string;
  address2: string;
  bill_id: string;
  bill_regionid: string;
  city: string;
  claim: boolean;
  facility_nm: string;
  isHomeCare: boolean;
  isInJurisdiction: boolean;
  order_id: number;
  pdfimage: null;
  phone: string;
  pos: string;
  pos_id: string;
  refid: string;
  region: string;
  state: string;
  zipcode: string;
  zipimage: null;
}

export interface ClaimAddressDetailsType {
  orderId: number;
  facilityId: number;
  address: string;
  state: string;
  city: string;
  zipcode: string;
  posId: number;
  billId: number;
  isHomeCare: boolean;
  isInJurisdiction: boolean;
  radiologist: {
    lastName: string;
    firstName: string;
    npi: string;
    taxonomyCode: number;
  };
}

class ClaimAddressDetails {
  fetching: boolean = false;
  claimDetails?: ClaimAddressDetailsType = undefined;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      claimDetails: observable,

      setFetching: action,
      setClaimDetails: action,
      clearClaimDetails: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setClaimDetails(details: ClaimAddressDetailsType) {
    this.claimDetails = details;
  }

  clearClaimDetails() {
    this.claimDetails = undefined;
  }

  async getClaimDetails(data: ClaimRequestPayloadType) {
    this.setFetching(true);
    try {
      const [response] = await apiRequest<[ClaimAddressResponseType]>({
        url: 'facility.ClaimManager.GetOrderClaim',
        data,
      });

      const details = {
        orderId: response.order_id,
        facilityId: data.facilityId,
        address: response.address,
        state: response.state,
        city: response.city,
        zipcode: response.zipcode,
        posId: Number(response.pos_id) || 0,
        billId: Number(response.bill_id) || 0,
        isHomeCare: response.isHomeCare,
        isInJurisdiction: response.isInJurisdiction,
        radiologist: {
          lastName: '',
          firstName: '',
          npi: '',
          taxonomyCode: 0,
        },
      };

      this.setClaimDetails(details);
    } catch (e: any) {
      this.setClaimDetails(claimDefaultValues);
    } finally {
      this.setFetching(false);
    }
  }

  async addOrderClaim(payload: ClaimAddressDetailsType) {
    const data = {
      orderId: payload.orderId,
      posId: payload.posId || 0,
      address: payload.address,
      city: payload.city,
      state: payload.state,
      zipcode: payload.zipcode,
      billId: payload.billId || 0,
      radiologist: payload.radiologist.firstName ? payload.radiologist : null,
    };

    try {
      const response = await apiRequest<'SE' | 'E'>({
        url: 'facility.ClaimManager.AddOrderClaim',
        data,
      });

      if (response === 'E') {
        Notification.success('Claim updated!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      return false;
    }
  }

  async addOrderClaimFacility(payload: ClaimAddressDetailsType) {
    const data = {
      orderId: payload.orderId,
      posId: payload.posId || 0,
      address: payload.address,
      city: payload.city,
      state: payload.state,
      zipcode: payload.zipcode,
      facilityId: payload.facilityId,
      billId: payload.billId || 0,
    };
    try {
      const response = await apiRequest<'SE' | 'E'>({
        url: 'facility.ClaimManager.AddOrderClaimFacility',
        data,
      });

      if (response === 'E') {
        Notification.success('Claim and Facility updated!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      return false;
    }
  }
}

export const storeClaimAddressDetails = new ClaimAddressDetails();
