import { useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import VisitInformationPage from 'page/workflow/order/VisitInformationPage';
import { withDivisionSettings } from 'components/HOC';
import Tabs from 'components/tabs';
import { Button } from 'components/button';
import { LayoutSideTitle } from 'components/layout';
import { SpinnerFixed } from 'components/spinner';
import TcAssignment from './TcAssignmentTab';
import PcAssignment from './PcAssignmentTab';

import { storeBillingAssignment } from 'stores/_mobx/billingCodding/billingAssignment';
import { BILLING_ASSIGNMENT as PAGE_ID } from 'constant/pagesId/billingCodding';
import { URL_BILLING_ASSIGNMENT } from 'constant/path/billingCoding';

const tabsList = [
  { label: 'TC Assignment', url: `${URL_BILLING_ASSIGNMENT}?tab=tc` },
  { label: 'PC Assignment', url: `${URL_BILLING_ASSIGNMENT}?tab=pc` },
];

const BillingAssignment = () => {
  const { visitDetails, permission } = storeBillingAssignment;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  useLayoutEffect(() => {
    storeBillingAssignment.checkPermission();

    storeBillingAssignment.setDefaultFilter();

    return storeBillingAssignment.clearTabData;
  }, []);

  return (
    <>
      {!visitDetails && (
        <LayoutSideTitle appId={PAGE_ID.PAGE} title="Billing Assignment">
          <Button
            variant="default"
            text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
            data-testid="toggleFilter"
            onClick={handleToggleFilter}
          />
        </LayoutSideTitle>
      )}

      {permission ? (
        <div className={visitDetails ? 'd-none' : ''}>
          {permission.isClient ? (
            <TcAssignment isFilterVisible={isFilterVisible} />
          ) : (
            <Tabs tabsList={tabsList}>
              <TcAssignment isFilterVisible={isFilterVisible} />
              <PcAssignment isFilterVisible={isFilterVisible} />
            </Tabs>
          )}
        </div>
      ) : (
        <SpinnerFixed />
      )}

      {visitDetails && (
        <VisitInformationPage
          callback={storeBillingAssignment.clearVisitDetails}
          selectedOrder={visitDetails}
        />
      )}
    </>
  );
};

export default withDivisionSettings(observer(BillingAssignment));
