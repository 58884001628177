import { OptionType } from './Cell';

export interface FilterPhysiciansPayloadType {
  list: OptionType[];
  searchQuery: {
    firstName: string;
    lastName: string;
    npi: string;
  };
}

export interface FilterPayloadType {
  list: OptionType[];
  firstValue: string;
  secondValue: string;
}

export const filterCptCodeList = ({
  list,
  firstValue,
  secondValue,
}: FilterPayloadType) => {
  if (firstValue || secondValue) {
    return list.filter(({ label }) => {
      const [code, description] = label.split(' - ');

      const isCodeMatches = firstValue
        ? code.startsWith(firstValue.toLowerCase())
        : true;

      const isDescriptionMatches = secondValue
        ? description.toLowerCase().includes(secondValue.toLowerCase())
        : true;

      return isCodeMatches && isDescriptionMatches;
    });
  }

  return list;
};

export const filterRadiologistList = ({
  list,
  firstValue,
  secondValue,
}: FilterPayloadType) => {
  if (firstValue || secondValue) {
    return list.filter((radiologist) => {
      const isFirstNameMatch = firstValue
        ? radiologist.firstName
            .toLowerCase()
            .startsWith(firstValue.toLocaleLowerCase())
        : true;

      const isLastNameMatch = secondValue
        ? radiologist.lastName
            .toLocaleLowerCase()
            .startsWith(secondValue.toLocaleLowerCase())
        : true;

      return isFirstNameMatch && isLastNameMatch;
    });
  }
  return list;
};

export const filterPhysicianList = ({
  list,
  searchQuery: { firstName, lastName, npi },
}: FilterPhysiciansPayloadType) => {
  if (firstName || lastName || npi) {
    return list.filter((physician) => {
      const isLastNameMatch = lastName
        ? physician.lastName.toLowerCase().startsWith(lastName.toLowerCase())
        : true;

      const isFirstNameMatch = firstName
        ? physician.firstName.toLowerCase().startsWith(firstName.toLowerCase())
        : true;

      const isNpiMatch = npi ? physician.npi.startsWith(npi) : true;

      return isFirstNameMatch && isLastNameMatch && isNpiMatch;
    });
  }
  return list || [];
};

export const filterRegionList = ({ list, firstValue }: FilterPayloadType) => {
  if (firstValue) {
    return list.filter((region) =>
      region.label.toLowerCase().startsWith(firstValue)
    );
  }

  return list;
};
