import { memo } from 'react';
import { Link } from 'react-router-dom';

import { IconButton } from 'components/button';

interface PropTypes {
  url: string;
  id: number;
  onDelete: (id: number) => void;
}

const GridActionControl = ({ url, id, onDelete }: PropTypes) => (
  <div className="grid-control">
    <Link className="bi bi-pencil" to={url} />
    <IconButton onClick={() => onDelete(id)}>
      <i className="bi bi-trash" />
    </IconButton>
  </div>
);

export default memo(GridActionControl);
