import { makeObservable, observable, action, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import { dateToLocalTimezone } from 'utils/DateUtils';

const request: Record<RequestType, string> = {
  facility: 'log.EncounterLog.getByFacility',
  order: 'log.EncounterLog.getByOrder',
  invoice: 'log.EncounterLog.getByInvoice',
};

const messagePrettier = ({ date, user, message }: MessageRawType) => ({
  user,
  message,
  date: dateToLocalTimezone({ date }),
});

interface MessageRawType {
  date: string;
  facilityId: string | null;
  invoiceId: string | null;
  message: string;
  orderId: string | null;
  refid: string;
  user: string;
  userId: string;
}

interface NoteRawType {
  bgcolor: string;
  date: string;
  name: string;
  notes: string;
  time: string;
}

interface MessagesResponseType {
  count: number;
  data: MessageRawType[];
}

interface MessageType
  extends Pick<MessageRawType, 'date' | 'user' | 'message'> {}

type RequestType = 'facility' | 'order' | 'invoice';

interface MessagePayload {
  message: string;
  facilityId?: number;
  invoiceId?: number;
  orderId?: number;
}

class Chatting {
  fetching: boolean = false;
  messages: MessageType[] = [];
  count: number;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      messages: observable,
      count: observable,

      setFetching: action,
      clearMessages: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  clearMessages() {
    this.messages = [];
    this.count = 0;
  }

  async getMessages(id: number, type: RequestType) {
    this.setFetching(true);

    try {
      const { count, data } = await apiRequest<MessagesResponseType>({
        url: request[type],
        data: [id, 0, 9999999], // [orderId, skip, limit]
      });

      const messages = data.map(messagePrettier);

      runInAction(() => {
        this.messages = messages;
        this.count = count;
        this.fetching = false;
      });
    } catch (e: any) {
      runInAction(() => {
        this.messages = [];
        this.count = 0;
        this.fetching = false;
      });
    }
  }

  async getOrderNotes(orderId: number) {
    this.setFetching(true);

    try {
      const data = await apiRequest<NoteRawType[]>({
        url: 'order.OrderNotes.GetNotes',
        data: { orderId },
      });

      const messages = data.map(({ name, date, notes }) => ({
        user: name,
        message: notes,
        date: dateToLocalTimezone({ date }),
      }));

      runInAction(() => {
        this.messages = messages;
        this.count = messages.length;
        this.fetching = false;
      });
    } catch (e: any) {
      runInAction(() => {
        this.messages = [];
        this.count = 0;
        this.fetching = false;
      });
    }
  }

  async addMessage(log: MessagePayload) {
    this.setFetching(true);

    try {
      const result = await apiRequest<number>({
        url: 'log.EncounterLog.save',
        data: { log },
      });

      if (Number.isNaN(result)) throw Error('');

      return true;
    } catch (e: any) {
      return false;
    } finally {
      this.setFetching(false);
    }
  }

  async addNote(log: MessagePayload) {
    this.setFetching(true);

    const data = {
      orderId: log.orderId,
      userId: UserProfileStore.getUserId(),
      message: log.message,
    };

    try {
      const result = await apiRequest<'S' | any>({
        url: 'order.OrderNotes.AddNotes',
        data,
      });

      if (result !== 'S') throw Error('');

      return true;
    } catch (e: any) {
      return false;
    } finally {
      this.setFetching(false);
    }
  }

  async hasLogByInvoices(id: number) {
    try {
      const result = await apiRequest<Record<number, boolean> | []>({
        url: 'log.EncounterLog.hasLogByInvoices',
        data: { invoiceIds: [id] },
      });

      if (Array.isArray(result)) throw Error('');
      return result;
    } catch (e: any) {
      return { [id]: false };
    }
  }

  async hasLogByFacilityIds(id: number) {
    try {
      const result = await apiRequest<Record<number, boolean> | []>({
        url: 'log.EncounterLog.hasLogByFacilityIds',
        data: { facilityIds: [id] },
      });

      if (Array.isArray(result)) throw Error('');
      return result;
    } catch (e: any) {
      return { [id]: false };
    }
  }
}

export const storeChatting = new Chatting();
