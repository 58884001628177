import { Link, RouteComponentProps } from 'react-router-dom';

import { LayoutSideTitle } from 'components/layout';
import {
  UpdateDFTSettingsPayload,
  storeDftSettings,
} from 'stores/_mobx/systemSetup/masterSetting/integrationsDft';
import { URL_MASTER_SETTINGS } from 'constant/path/systemSetup';
import { useEffect } from 'react';
import Form from './components/form';
import { observer } from 'mobx-react-lite';

const IntegrationDFT = ({ history }: RouteComponentProps) => {
  const { fetching } = storeDftSettings;
  useEffect(() => {
    storeDftSettings.fetchDftSettings();
  }, []);

  const onSubmit = (payload: UpdateDFTSettingsPayload) =>
    storeDftSettings
      .update(payload)
      .then(() => history.push(URL_MASTER_SETTINGS));
  if (fetching) {
    return (
      <>
        <div className="on-ajax"></div>
      </>
    );
  }
  const defaultValues = storeDftSettings.getFormDefaultValues();
  return (
    <>
      <LayoutSideTitle title="DFT settings">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <Form
        onSubmit={onSubmit}
        fetching={fetching}
        defaultValues={defaultValues}
      />
    </>
  );
};

export default observer(IntegrationDFT);
