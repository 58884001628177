import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'components/modal/dialog';
import { Button } from 'components/button';

import {
  storeServiceHistory,
  HistoryType,
} from 'stores/_mobx/workflow/serviceMaintenance/serviceHistory';
import CsvExporter from 'utils/CsvExporter';

const columns = [
  { name: 'reason', title: 'Notes' },
  { name: 'status', title: 'Service Status' },
  { name: 'created_by', title: 'Carried Out By' },
  { name: 'createdDate', title: 'Date', className: 'width-150' },
];

const handleExport = (historyLog: HistoryType[], fileName: string) => {
  const csvExporter = new CsvExporter(historyLog, columns);
  csvExporter.exportAndDownload(fileName);
};

const DialogServiceHistory = () => {
  const { historyLog, fetchingHistory, historyLogDetails } =
    storeServiceHistory;

  const handleClickExport = () => {
    handleExport(historyLog, historyLogDetails.fileName);
  };

  return (
    <Dialog size="large" handleClose={storeServiceHistory.clearHistoryLog}>
      <DialogHeader
        title={historyLogDetails.title}
        onClose={storeServiceHistory.clearHistoryLog}
      />
      <DialogBody>
        <Grid
          id="serviceHistoryLogGrid"
          disablePagination
          columns={columns}
          onAjax={fetchingHistory}
          dataSource={historyLog}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          text="Export"
          onClick={handleClickExport}
          disabled={fetchingHistory || !historyLog.length}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogServiceHistory);
