import { useForm } from 'react-hook-form';

import { DropdownCorporateWithPermission } from 'components/project/dropdown/CorporateRolesDropdown';
import DropDownTechnologist from 'components/project/dropdown/TechnologistDropDown';
import { DropDownFacilityRole } from 'components/project/dropdown/FacilityRolesDropdown';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { Input } from 'components/form/textField';
import { DateRange } from 'components/form/dateRange';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';

import {
  storeProcedureCount,
  FilterType,
} from 'stores/_mobx/report/procedureCount';

const commonClassName = 'col-md-6 col-lg-3';

interface PropsType {
  fetching: boolean;
  isFilterVisible: boolean;
  onSearch: (filter: FilterType) => void;
}

const Filter = ({ fetching, isFilterVisible, onSearch }: PropsType) => {
  const {
    control,
    formState: { isDirty },
    reset,
    handleSubmit,
  } = useForm<FilterType>({ defaultValues: storeProcedureCount.filter });

  const handleClickReset = () => {
    const defaultFilterValue = storeProcedureCount.getDefaultFilter();

    reset(defaultFilterValue);
    onSearch(defaultFilterValue);
  };

  return (
    <form
      className={`row${isFilterVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSearch)}>
      <Input
        name="procedureCode"
        label="Procedure Code"
        className={commonClassName}
        control={control}
      />
      <Input
        name="procedureDescription"
        label="Procedure Description"
        className={commonClassName}
        control={control}
      />
      <Input
        name="lastName"
        label="Patient Last Name"
        className={commonClassName}
        control={control}
      />
      <Input
        name="firstName"
        label="Patient First Name"
        className={commonClassName}
        control={control}
      />
      <div />
      <DropDownTechnologist
        name="technologistId"
        label="Technologist"
        className={commonClassName}
        control={control}
      />
      <DropDownFacilityRole
        name="facilityId"
        label="Facility"
        className={commonClassName}
        control={control}
      />
      <DropdownCorporateWithPermission
        name="corporateId"
        label="Corporation"
        className={commonClassName}
        control={control}
      />
      <DropDownPlaceOfService
        name="pos"
        label="POS"
        className={commonClassName}
        control={control}
      />
      <div />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-md-6 col-lg-auto"
        control={control}
      />
      <ControlsLayout alignX="right" className="mb-3">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching || !isDirty} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
