import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import GridActionControl from 'page/components/GridActionControls';
import { LayoutSideTitle } from 'components/layout';
import { Grid } from 'components/grid';
import { Button } from 'components/button';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import Filter from './components/Filter';

import { storeCallLog, FilterModel } from 'stores/_mobx/workflow/order/callLog';
import { CALL_LOG as PAGE_ID } from 'constant/pagesId/workflow';
import { URL_CALL_LOG, URL_CALL_LOG_ADD } from 'constant/path/workflow';

const GridControlButtons = () => (
  <Link to={URL_CALL_LOG_ADD}>
    <b>Add</b>
  </Link>
);

const columns = [
  { name: 'call_type', title: 'Call type' },
  {
    name: 'utc',
    title: 'Date/Time',
  },
  { name: 'facilityname', title: 'Facility' },
  { name: 'contact_person', title: 'Caller name' },
  { name: 'phone_no', title: 'Phone No' },
  { name: 'reason', title: 'Reason' },
  { name: 'remarks', title: 'Remarks' },
  {
    name: 'refid',
    title: 'Actions',
    className: 'width-100',
    render: (id: number) => (
      <GridActionControl
        id={id}
        url={`${URL_CALL_LOG}/edit/${id}`}
        onDelete={storeCallLog.setIdForDelete}
      />
    ),
  },
];

const CallLogListPage = () => {
  const {
    fetching,
    logsList,
    logsTotal,
    idForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeCallLog;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const toggleFilterVisibility = () => {
    toggleFilter((state) => !state);
  };

  const handleDeleteApprove = () => {
    storeCallLog.deleteCallLog().then((isSucceed) => {
      if (isSucceed) storeCallLog.getLogsMain();
    });
  };

  const handleChangeFilter = (filter: FilterModel) => {
    storeCallLog.setFilter(filter);

    debouncedMethod();
  };

  useEffect(() => {
    storeCallLog.getLogsMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle title="Call Log" appId={PAGE_ID.PAGE}>
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={toggleFilterVisibility}
        />
      </LayoutSideTitle>

      <Filter isVisible={isFilterVisible} onChange={handleChangeFilter} />

      <Grid
        id="call_log_grid"
        gridControlPanel={<GridControlButtons />}
        columns={columns}
        onAjax={fetching}
        dataSource={logsList}
        dataSourceCount={logsTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      {idForDelete ? (
        <DialogConfirm
          onApprove={handleDeleteApprove}
          onCancel={storeCallLog.clearIdForDelete}>
          Are you sure you want to delete this call log?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(CallLogListPage);
