import { Grid } from 'components/grid';
import { getTimeZoneName } from 'utils/DateUtils';

const CPT_COLUMNS = [
  { name: 'cpt_code', title: 'Cpt Code' },
  { name: 'cpt_modifier', title: 'Modifier' },
  { name: 'cpt_price', title: 'Price' },
  { name: 'cptcount', title: 'Units' },
  {
    name: 'cpt_description',
    title: 'Description',
    render: (description: string, data: { cpt_desc: string }) =>
      data.cpt_desc || description,
  },
];

interface PropsType {
  order: any;
}

const classNameColumn = 'col-sm-6';

const OrderViewComponent = ({ order }: PropsType) => {
  const finalCpt = order.GetFnlCptArr || [];

  const isCorrectional = order.correctionalFacility;

  return (
    <>
      <div className="row">
        <dl className="order-view row col-sm-6">
          <dt className={classNameColumn}>Order Based On:</dt>
          <dd className={classNameColumn}>
            {order.ordersrc ? order.ordersrc : 'N/A'}
          </dd>

          <dt className={classNameColumn}>Patient:</dt>
          <dd className={classNameColumn}>{order.patientnm}</dd>

          <dt className={classNameColumn}>Entered By:</dt>
          <dd className={classNameColumn}>{order.username}</dd>

          <dt className={classNameColumn}>Modality:</dt>
          <dd className={classNameColumn}>{order.examtype}</dd>

          <dt className={classNameColumn}>Scheduled Service Date:</dt>
          <dd className={classNameColumn}>{order.dos}</dd>

          <dt className={classNameColumn}>Assigned Timestamp:</dt>
          <dd className={classNameColumn}>
            {order.orderassigned_date}
            {` ${getTimeZoneName()}`}
          </dd>

          <dt className={classNameColumn}>Result To:</dt>
          <dd className={classNameColumn}>{order.phoneResult}</dd>
          {isCorrectional ? (
            <>
              <dt className={classNameColumn}>Marshall ID:</dt>
              <dd className={classNameColumn}>{order.immigrationNumber}</dd>

              <dt className={classNameColumn}>ICE Number:</dt>
              <dd className={classNameColumn}>{order.iceNumber}</dd>

              <dt className={classNameColumn}>OID Number:</dt>
              <dd className={classNameColumn}>{order.inmateNumber}</dd>
            </>
          ) : null}

          <dt className={classNameColumn}>Accession Number:</dt>
          <dd className={classNameColumn}>{order.accessionNumbersStr}</dd>

          <dt className={classNameColumn}>Other MRN:</dt>
          <dd className={classNameColumn}>{order.clientMRN || 'N/A'}</dd>

          <dt className={classNameColumn}>Number of patients:</dt>
          <dd className={classNameColumn}>{order.number_of_patient}</dd>
        </dl>
        <dl className="order-view row col-sm-6">
          <dt className={classNameColumn}>Ordering Physician:</dt>
          <dd className={classNameColumn}>{order.physicianname}</dd>

          <dt className={classNameColumn}>Primary Care Physician:</dt>
          <dd className={classNameColumn}>{order.Pcpid}</dd>

          <dt className={classNameColumn}>Consulting Physician:</dt>
          <dd className={classNameColumn}>{order.consPhysName}</dd>

          <dt className={classNameColumn}>Facility:</dt>
          <dd className={classNameColumn}>{order.facilitynm}</dd>

          <dt className={classNameColumn}>Order Timestamp:</dt>
          <dd className={classNameColumn}>
            {order.ordercreated_dt}
            {` ${getTimeZoneName()}`}
          </dd>

          <dt className={classNameColumn}>Order Priority:</dt>
          <dd className={classNameColumn}>{order.priority_nm}</dd>

          <dt className={classNameColumn}>Technologist:</dt>
          <dd className={classNameColumn}>{order.technologistnm}</dd>

          <dt className={classNameColumn}>Completed Timestamp:</dt>
          <dd className={classNameColumn}>
            {order.ordercompletion_date}
            {` ${getTimeZoneName()}`}
          </dd>

          <dt className={classNameColumn}>Radiology Group:</dt>
          <dd className={classNameColumn}>{order.radiologygroupid}</dd>

          <dt className={classNameColumn}>Report Faxed On:</dt>
          <dd className={classNameColumn}>{order.faxed_time}</dd>
        </dl>

        <dl className="order-view row">
          <dt className="col-sm-4 col-md-3">Procedure code:</dt>
          <dd className="col-sm-8 col-md-9">{order.cptdesc}</dd>

          <dt className="col-sm-4 col-md-3">Initial Diagnosis:</dt>
          <dd className="col-sm-8 col-md-9">
            {order.icd?.map((code: any, idx: number) => (
              <span key={`${idx}_${code.icd_code}`} className="d-block">
                {code.icd_code} - {code.short_description}
              </span>
            ))}
          </dd>

          <dt className="col-sm-4 col-md-3">Exam Comments:</dt>
          <dd className="col-sm-8 col-md-9">{order.comment}</dd>
          {order.hl7 ? (
            <>
              <dt className="col-sm-4 col-md-3">HL7:</dt>
              <dd className="col-sm-8 col-md-9 hl7-text">
                {order.hl7.split('\r').map((text: string, i: number) => (
                  <span key={i}>{text}</span>
                ))}
              </dd>
            </>
          ) : null}
        </dl>
      </div>
      {finalCpt?.length ? (
        <>
          <div className="fw-bold">Final Coding</div>
          <Grid
            dataSource={finalCpt}
            noControl
            disablePagination
            columns={CPT_COLUMNS}
          />
        </>
      ) : null}
    </>
  );
};

export default OrderViewComponent;
