import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormReportType, { FormModel } from './components/Form';

import { storeDocumentType } from 'stores/_mobx/systemSetup/masterSetting/documentType';
import { URL_IMPORT_ITEM } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const ImportItemAddPage = ({ history }: PropsType) => {
  const handleSubmit = (data: FormModel) =>
    storeDocumentType.addDocument(data).then((response) => {
      if (!response) {
        history.push(URL_IMPORT_ITEM);
      }
      return response;
    });

  return (
    <>
      <Title title="Add Report Type" />
      <FormReportType backUrl={URL_IMPORT_ITEM} onSubmit={handleSubmit} />
    </>
  );
};

export default ImportItemAddPage;
