import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import Notification from 'components/modal/Notification';
import Dialog, { DialogBody, DialogFooter } from 'components/modal/dialog';
import { Input } from 'components/form/textField';
import { Button } from 'components/button';
import resolver from './validation';

import OrderOverviewActions from 'actions/workflow/order/OrderOverviewActions';

const defaultValues: FormModel = {
  reason: '',
};

interface FormModel {
  reason: string;
}

export interface PropsType {
  id: number;
  onClose: () => void;
  onApproveCancel: () => void;
}

export class SOrderCancelPopup {
  model: {
    cancelReasons: string;
    reason: string;
  } = {
    cancelReasons: '',
    reason: '',
  };
}

const DialogCancelOrder = ({ id, onClose, onApproveCancel }: PropsType) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const handleClickSubmit = () => {
    handleSubmit(({ reason }: FormModel) =>
      OrderOverviewActions.cancelOrder(id, reason).then(() => {
        Notification.success('Order cancelled');
        onApproveCancel();
      })
    )();
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  }, []);

  return (
    <Dialog handleClose={onClose}>
      <DialogBody className={isSubmitting ? 'on-ajax' : ''}>
        <form onSubmit={handleClickSubmit}>
          <Input
            name="reason"
            label="Please enter the reason for canceling this exam:"
            required
            ref={inputRef}
            control={control}
          />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="warning"
          text="Submit"
          disabled={!isDirty || isSubmitting}
          onClick={handleClickSubmit}
        />
        <Button text="Cancel" disabled={isSubmitting} onClick={onClose} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogCancelOrder;
