import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';

import { storeDropdownUserType } from 'stores/_mobx/dropDown/userType';
import { storeUsers } from 'stores/_mobx/dropDown/users';

interface PropsType extends Omit<SelectPropsType, 'options'> {
  userTypeId?: number;
}

const DropdownUsers = ({ userTypeId, ...props }: PropsType) => {
  const { fetchingOptionsUserType, optionsRaw } = storeDropdownUserType;

  const { fetching, optionsRegularUsers, optionsCompanyUsers } = storeUsers;

  const userType = useMemo(
    () => optionsRaw.find(({ id }) => id === userTypeId),
    [userTypeId, optionsRaw]
  );

  const userTypeShortName = userType?.usertype || null;

  const isCompanyUserType = userType?.type === 0 || false;

  const getDropdownOptions = () => {
    if (userTypeShortName && userTypeId)
      storeUsers.getOptions({
        isCompanyUserType,
        userTypeShortName,
        userTypeId,
      });
  };

  useEffect(() => {
    if (optionsRaw.length) {
      getDropdownOptions();
    } else {
      storeDropdownUserType.getOptionsUserType().then(getDropdownOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTypeShortName, userTypeId]);

  return (
    <Select
      {...props}
      options={isCompanyUserType ? optionsCompanyUsers : optionsRegularUsers}
      isLoading={fetchingOptionsUserType || fetching}
    />
  );
};

export default observer(DropdownUsers);
