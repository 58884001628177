import { makeObservable, observable, action, computed } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { getDateRangeBounds } from 'utils/DateUtils';

export const defaultFilterValue: FilterType = {
  corporateId: 0,
  facilityId: [],
  dosStart: '',
  dosEnd: '',
  period: 'A',
  providerType: 0,
  invoice: '',
  postedFrom: '',
  postedTo: '',
  balance: '',
  duePeriod: '',
  stateId: [],
  region: [],
  location: [],
  orderType: '',
  pos: 0,
  invoiceFrom: '',
  invoiceTo: '',
  division: 0,
};

export interface FilterType {
  corporateId: number;
  facilityId: number[];
  dosStart: string;
  dosEnd: string;
  period: string;
  providerType: number;
  invoice: string;
  postedFrom: string;
  postedTo: string;
  balance: string;
  duePeriod: string;
  stateId: number[];
  region: number[];
  location: number[];
  orderType: string;
  pos: number;
  invoiceFrom: string;
  invoiceTo: string;
  division: number;
}

interface TotalSumType {
  payment: number;
  charge: number;
  balance: number;
}

interface LedgerType {
  actual: boolean;
  address1: string;
  age: number;
  bal: number;
  bill_email: string;
  date: string;
  debit_billed: string;
  facilityid: number;
  facilitynm: string;
  invoice_dt: string;
  invoice_id: number;
  invoice_no: string;
  ledger_id: number;
  month: string;
  paymentamount: number;
  phone: string;
  refid: number;
  region: string;
  location: string;
  total_price: number;
  transdetail: string;
  write_off: string;
  year: number;
  division: string;
}

class Ledger {
  fetching: boolean = false;
  ledgerList: LedgerType[] = [];
  ledgerTotal: number = 0;
  filter: FilterType = defaultFilterValue;
  page: Pagination = new Pagination({ id: 'ledger_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      ledgerList: observable,
      ledgerTotal: observable,
      filter: observable,

      totalSum: computed,

      setLedgerList: action,
      setLedgerCount: action,
      setFetching: action,
      setFilter: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setLedgerList(list: LedgerType[]) {
    this.ledgerList = list;
  }

  setLedgerCount(count: number) {
    this.ledgerTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  get totalSum(): TotalSumType {
    return this.ledgerList.reduce(
      (prev, current) => ({
        payment: prev.payment + current.paymentamount,
        charge: prev.charge + current.total_price,
        balance: current.actual ? prev.balance + current.bal : prev.balance,
      }),
      { payment: 0, charge: 0, balance: 0 }
    );
  }

  async getLedgerList(filter: FilterType) {
    const { pagination } = this.page;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const data = [
      filter.corporateId,
      filter.facilityId,
      dos.dateFrom,
      dos.dateTo,
      filter.providerType,
      filter.invoice,
      pagination.skip,
      pagination.pageSize,
      filter.postedFrom,
      filter.postedTo,
      filter.balance,
      filter.duePeriod,
      filter.stateId,
      filter.orderType,
      filter.pos,
      filter.invoiceFrom,
      filter.invoiceTo,
      filter.region,
      filter.location,
      filter.division,
    ];

    try {
      const response = await apiRequest<LedgerType[]>({
        url: 'facility.FacilityBilling.GetLedgerList',
        data,
      });

      const list = response.map((el) => ({
        ...el,
        age: Number(el.age),
        facilityid: Number(el.facilityid),
        invoice_id: Number(el.invoice_id),
        ledger_id: Number(el.ledger_id),
        refid: Number(el.refid),
        year: Number(el.year),
        paymentamount: Number(el.paymentamount),
        total_price: Number(el.total_price),
        bal: Number(el.bal),
      }));
      this.setLedgerList(list);
    } catch (e: any) {
      this.setLedgerList([]);
    }
  }

  async getLedgerCount(filter: FilterType) {
    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const data = [
      filter.corporateId,
      filter.facilityId,
      dos.dateFrom,
      dos.dateTo,
      filter.providerType,
      filter.invoice,
      filter.postedFrom,
      filter.postedTo,
      filter.balance,
      filter.duePeriod,
      filter.stateId,
      filter.orderType,
      filter.pos,
      filter.invoiceFrom,
      filter.invoiceTo,
      filter.region,
      filter.location,
      filter.division,
    ];

    try {
      const response = await apiRequest<number>({
        url: 'facility.FacilityBilling.GetLedgerList_count',
        data,
      });

      this.setLedgerCount(Number(response) || 0);
    } catch (e: any) {
      this.setLedgerCount(0);
    }
  }

  getLedgerListMain = (isDivisionEnabled: boolean) => {
    this.setFetching(true);

    const filter = {
      ...this.filter,
      division: isDivisionEnabled ? this.filter.division : 0,
    };

    const promiseList = this.getLedgerList(filter);

    const promiseCount = this.getLedgerCount(filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  generatePdf = async ({ filter }: { filter: string }) => {
    const data = {
      data: this.ledgerList,
      charge: this.totalSum.charge,
      balance: this.totalSum.balance,
      payment: this.totalSum.payment,
      filterString: filter,
    };

    try {
      const result = await apiRequest<string>({
        url: 'facility.ArPdfGenerator.GenerateFacLedgerPDF',
        data,
      });
      return result;
    } catch (e: any) {
      return '';
    }
  };

  prepareFilterForExport(isDivisionEnabled: boolean) {
    const { filter, ledgerTotal } = this;

    const dos = getDateRangeBounds({
      from: storeLedger.filter.dosStart,
      to: storeLedger.filter.dosEnd,
    });

    return [
      filter.corporateId,
      filter.facilityId,
      dos.dateFrom,
      dos.dateTo,
      filter.providerType,
      filter.invoice,
      0,
      ledgerTotal,
      filter.postedFrom,
      filter.postedTo,
      filter.balance,
      filter.duePeriod,
      filter.stateId,
      filter.orderType,
      filter.pos,
      filter.invoiceFrom,
      filter.invoiceTo,
      filter.region,
      filter.location,
      isDivisionEnabled ? filter.division : 0,
    ];
  }
}

export const storeLedger = new Ledger();
