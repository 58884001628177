import { useForm } from 'react-hook-form';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { InputCalendar } from 'components/form/inputCalendar';
import resolver from './validationCalibrationDate';

import {
  storeCalibrationRecords,
  CalibrationDateType,
} from 'stores/_mobx/workflow/serviceMaintenance/calibrationRecords';

export interface FormModel {
  nextCalibrationDate: string;
  calibrationDate: string;
  type_vl: string;
  refId: number;
}
export interface FormModelWithDate {
  nextCalibrationDate: Date;
  calibrationDate: Date;
  type_vl: string;
  refId: number;
}

interface PropsType {
  defaultValues: CalibrationDateType;
  onClose: () => void;
}

const DialogUpdateCalibrationDate = ({ defaultValues, onClose }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = () => {
    handleSubmit((payload) =>
      storeCalibrationRecords.updateCalibrationDate(payload).then((succeed) => {
        if (succeed) {
          onClose();
          storeCalibrationRecords.getCalibrationRecords('D');
        }
      })
    )();
  };

  return (
    <Dialog scrollable={false} handleClose={onClose}>
      <DialogHeader title="Update Calibration" onClose={onClose} />
      <DialogBody>
        <form>
          <InputCalendar
            name="calibrationDate"
            label="Calibration date"
            required
            control={control}
          />
          <InputCalendar
            name="nextCalibrationDate"
            label="Next calibration date"
            required
            control={control}
          />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          text="Reset"
          variant="warning"
          onClick={handleReset}
          disabled={isSubmitting}
        />
        <Button
          text="Save"
          onClick={handleClickSubmit}
          disabled={!isDirty || isSubmitting}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogUpdateCalibrationDate;
