import React, { useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import clsx from 'clsx';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    [{ color: [] as any[] }],
  ],
};

const formats = [
  'size',
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'align',
];

export interface PureTextEditorProps {
  name?: string;
  value?: string;
  label?: string;
  required?: boolean;
  className?: string;
  autoFocus?: boolean;
  errorMessage?: string;
  'data-testid'?: string;
  onChange?: (value: any) => void;
}

const PureTextEditor = React.forwardRef<any, PureTextEditorProps>(
  (
    {
      name,
      required = false,
      label = '',
      className,
      errorMessage,
      'data-testid': dataTestId,
      ...rest
    }: PureTextEditorProps,
    ref
  ) => {
    const innerRef = useRef<HTMLDivElement>(null);

    const cn = clsx(
      'formfield-holder',
      'formfield-text',
      { required },
      className
    );

    const testAttr = dataTestId || name;

    const onKeyUp = (e: KeyboardEvent): void => {
      if (e.code === 'Enter') {
        e.preventDefault();
      }
    };

    useEffect(() => {
      const AlignStyle = Quill.import('attributors/style/align');
      const SizeStyle = Quill.import('attributors/style/size');
      Quill.register(AlignStyle, true);
      Quill.register(SizeStyle, true);
    }, []);

    return (
      <div className={cn}>
        {label ? (
          <div className="form-label" data-testid={`${testAttr}-label`}>
            <label htmlFor={name}>{label}</label>
          </div>
        ) : null}
        <div className="formfield-input" ref={innerRef}>
          <ReactQuill
            theme="snow"
            className="react-quill-custom"
            id="body_content"
            data-testid={`${testAttr}-field`}
            formats={formats}
            modules={modules}
            {...rest}
            ref={ref}
            onKeyUp={onKeyUp}
          />
        </div>
        {errorMessage && (
          <div className="error" data-testid={`${testAttr}-error`}>
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

export default PureTextEditor;
