import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import FilterNameAndState, {
  FilterType,
} from 'stores/_mobx/options/filterNameAndStatus';
import { dateToLocalTimezone } from 'utils/DateUtils';
import { BASE_URL_FILE_DIR } from 'constant/config';

//MM/DD/YYYY -> YYYY-DD-MM
const changeDateFormatBeforeSend = (date: string) => {
  if (date) {
    const splitDate = date.split('/');
    return `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
  }
  return null;
};

//YYYY-DD-MM -> MM/DD/YYYY
const changeDateFormatAfterReceive = (date: string) => {
  if (date) {
    const splitDate = date.split('-');
    return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
  }
  return '';
};

export interface GeneratorType {
  calibration_date: string;
  calibration_due_date: string;
  enable: boolean;
  generator_slno: string;
  name: string;
  refid: number;
  start_date: string;
}

interface FileResponseType {
  desc: string;
  filenm: string;
  filestatus: string;
  remvImg: string;
  uploaded_dt: string;
}

interface GeneratorEntryType extends Omit<GeneratorType, 'enable'> {
  flag: string;
  image_path: string;
}

interface GeneratorResponseType {
  0: GeneratorEntryType;
  file: 0 | FileResponseType[];
}

interface FileType {
  desc: string;
  filenm: string;
  filestatus: string;
}

interface FilesPreviewType {
  desc: string;
  fileName: string;
  path: string;
  uploadDate: string;
}

export interface OptionType {
  label: string;
  value: number;
}

export interface GeneratorFormModel
  extends Omit<GeneratorType, 'refid' | 'enable'> {
  refid: number | null;
  documentList: FileType[];
}

class Generator {
  fetching: boolean = false;
  fetchingFileList: boolean = false;
  fetchingOptions: boolean = false;
  generatorList: GeneratorType[] = [];
  generatorTotal: number = 0;
  generator: GeneratorFormModel | undefined = undefined;
  idForDelete: number | null = null;
  fileListPreview: FilesPreviewType[] | null = null;
  noteId: number = 0;
  options: OptionType[] = [];

  filter: FilterNameAndState = new FilterNameAndState();
  page: Pagination = new Pagination({ id: 'generator_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingFileList: observable,
      fetchingOptions: observable,
      generatorList: observable,
      generatorTotal: observable,
      generator: observable,
      idForDelete: observable,
      fileListPreview: observable,
      noteId: observable,
      options: observable,

      setFetching: action,
      setFetchingFileList: action,
      setFetchingOptions: action,
      setGeneratorList: action,
      setGeneratorTotal: action,
      setGenerator: action,
      setIdForDelete: action.bound,
      setFileListPreview: action,
      setNoteId: action,
      clearIdForDelete: action.bound,
      clearGenerator: action.bound,
      clearFileListPreview: action.bound,
      clearNoteId: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFetchingFileList = (fetching: boolean) => {
    this.fetchingFileList = fetching;
  };

  setFetchingOptions = (fetching: boolean) => {
    this.fetchingOptions = fetching;
  };

  setGeneratorList(list: GeneratorType[]) {
    this.generatorList = list;
  }

  setGeneratorTotal(count: number) {
    this.generatorTotal = count;
  }

  setGenerator(generator?: GeneratorFormModel) {
    this.generator = generator;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setFileListPreview(fileList: FilesPreviewType[]) {
    this.fileListPreview = fileList;
  }

  setNoteId(id: number) {
    this.noteId = id;
  }

  clearIdForDelete() {
    this.idForDelete = null;
  }

  clearGenerator() {
    this.generator = undefined;
  }

  clearFileListPreview() {
    this.fileListPreview = null;
  }

  clearNoteId() {
    this.noteId = 0;
  }

  async getGeneratorList(filter: FilterType) {
    const { pagination } = this.page;

    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.name,
      filter.state,
    ];

    try {
      const response = await apiRequest<'SE' | GeneratorType[]>({
        url: 'vehicle.GeneratorMaster.GeneratorNameList',
        data,
      });
      if (Array.isArray(response)) {
        const list = response.map((item) => ({
          ...item,
          refid: Number(item.refid),
        }));
        this.setGeneratorList(list);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setGeneratorList([]);
    }
  }

  async getGeneratorCount(filter: FilterType) {
    try {
      const count = await apiRequest<'SE' | 'S' | number>({
        url: 'vehicle.GeneratorMaster.GeneratorNameTotalCount',
        data: [filter.name, filter.state],
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setGeneratorTotal(total);
    } catch (e) {
      this.setGeneratorTotal(0);
    }
  }

  getGeneratorMain = (filter: FilterType = this.filter.options) => {
    this.setFetching(true);

    const promiseList = this.getGeneratorList(filter);

    const promiseCount = this.getGeneratorCount(filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getGenerator(id: number | string) {
    this.setFetching(true);

    try {
      const response = await apiRequest<[] | GeneratorResponseType>({
        url: 'vehicle.GeneratorMaster.GetViewGeneratorName',
        data: [id],
      });
      if (Array.isArray(response)) {
        throw Error('');
      } else {
        const documentList = response.file
          ? response.file.map(({ filenm, desc, filestatus }) => ({
              filenm,
              desc,
              filestatus,
            }))
          : [];
        const start_date = changeDateFormatAfterReceive(response[0].start_date);
        const calibration_date = changeDateFormatAfterReceive(
          response[0].calibration_date
        );
        const calibration_due_date = changeDateFormatAfterReceive(
          response[0].calibration_due_date
        );
        const generator = {
          ...response['0'],
          start_date,
          calibration_date,
          calibration_due_date,
          refid: Number(id),
          documentList,
        };

        this.setGenerator(generator);
      }
    } catch (e) {
      this.setGenerator();
    } finally {
      this.setFetching(false);
    }
  }

  async addGenerator({ documentList, ...payload }: GeneratorFormModel) {
    const start_date = changeDateFormatBeforeSend(payload.start_date);
    const calibration_date = changeDateFormatBeforeSend(
      payload.calibration_date
    );
    const calibration_due_date = changeDateFormatBeforeSend(
      payload.calibration_due_date
    );

    const data = [
      payload.generator_slno,
      payload.name,
      start_date,
      calibration_due_date,
      calibration_date,
      [],
      documentList,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'vehicle.GeneratorMaster.AddGeneratorName',
        data,
      });
      if (result === 'S') {
        Notification.success('Generator was added successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'Serial number is not unique!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateGenerator({ documentList, ...payload }: GeneratorFormModel) {
    const start_date = changeDateFormatBeforeSend(payload.start_date);
    const calibration_date = changeDateFormatBeforeSend(
      payload.calibration_date
    );
    const calibration_due_date = changeDateFormatBeforeSend(
      payload.calibration_due_date
    );

    const data = [
      payload.refid,
      payload.generator_slno,
      payload.name,
      start_date,
      calibration_due_date,
      calibration_date,
      documentList,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'vehicle.GeneratorMaster.EditGeneratorName',
        data,
      });

      if (result === 'S') {
        Notification.success('Generator was updated successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'This Serial number already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  changeGeneratorStatus = async (ids: number[]) => {
    this.setFetching(true);
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'vehicle.GeneratorMaster.changeStatus',
        data: [ids],
      });
      if (response === 'S') {
        const { length } = ids;

        const statusMsg = this.filter.options.isActive
          ? 'deactivated'
          : 'activated';

        Notification.success(
          `${length} Generator${length > 1 ? 's were' : 'was'} ${statusMsg}`
        );
        return true;
      } else Error('');
    } catch (e) {
      Notification.warning('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  };

  async deleteGenerator() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'vehicle.GeneratorMaster.DeleteGeneratorName',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Generator was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("This Generator is in use, so it can't be deleted!");
      this.setFetching(false);
      return false;
    }
  }

  async getFileList(id: number) {
    runInAction(() => {
      this.fetchingFileList = true;
      this.fileListPreview = [];
    });
    try {
      const response = await apiRequest<[] | GeneratorResponseType>({
        url: 'vehicle.GeneratorMaster.GetViewGeneratorName',
        data: [id],
      });
      if (Array.isArray(response)) {
        throw Error('');
      } else {
        const files = response.file
          ? response.file.map(({ filenm, desc, uploaded_dt }) => ({
              fileName: filenm || 'unknown',
              desc,
              uploadDate: dateToLocalTimezone({ date: uploaded_dt }),
              path: `${BASE_URL_FILE_DIR}doc_img/generator/${id}-${filenm}`,
            }))
          : [];

        this.setFileListPreview(files);
      }
    } catch (e: any) {
    } finally {
      this.setFetchingFileList(false);
    }
  }

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve([]);

    this.setFetchingOptions(true);

    try {
      const options = await apiRequest<OptionType[]>({
        url: 'vehicle.GeneratorMaster.GetGeneratorDropDown',
      });

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storeGenerator = new Generator();
