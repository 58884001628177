import { useController, Control } from 'react-hook-form';

import PureTwoSideMapper, { PropsType as PurePropType } from './PureMapper';

interface PropsType extends Omit<PurePropType, 'onChange' | 'value'> {
  control: Control<any>;
  name: string;
}

const TwoSideMapper = ({ control, name, ...rest }: PropsType) => {
  const {
    field,
    fieldState: { isDirty },
  } = useController({
    name,
    control,
  });

  return <PureTwoSideMapper {...rest} {...field} isDirty={isDirty} />;
};

export default TwoSideMapper;
