import { Container } from 'flux/utils';

import FacilityPatientPageActions from 'actions/reports/report-creator/facility-patient/FacilityPatientPageActions';
import FacilityPatientPageStore from 'stores/reports/report-creator/facility-patient/FacilityPatientPageStore';
import BarChart from 'components/project/common/charts/BarChart';
import AbstractReportPage from '../AbstractReportPage';
import { prettierFilterPayload } from '../filter/prettierFilterPayload';

import { FACILITY_PATIENT as PAGE_ID } from 'constant/pagesId/reports';

const COLUMNS = [
  { name: 'patientName', title: 'Patient name' },
  { name: 'patientgender', title: 'Patient gender' },
  { name: 'dob', title: 'DOB', className: 'text-nowrap' },
  { name: 'mrn', title: 'MRN' },
  { name: 'phone', title: 'Phone' },
  { name: 'email', title: 'Email' },
  { name: 'ssn', title: 'SSN' },
  { name: 'faciName', title: 'Facility name' },
];

export class FacilityPatientPage extends AbstractReportPage {
  static getStores() {
    return [FacilityPatientPageStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = AbstractReportPage.getPageState('facility_patient_grid');
      prevState.paginationStrategy = AbstractReportPage.LOGIC_PAGINATION;
    }
    return {
      ...prevState,
      appId: PAGE_ID.PAGE,
      dataSource: FacilityPatientPageStore.getState().facilityPatientList,
      chartValues: FacilityPatientPageStore.getState().facilityPatientDiagram,
    };
  }

  getTitle() {
    return 'Facility Patient';
  }

  getColumns() {
    return COLUMNS;
  }

  getGridID() {
    return 'facility_patient_grid';
  }

  getExportedFileName() {
    return 'facility_patient';
  }

  getClass() {
    return 'facility-patient-overview-page';
  }

  renderChart() {
    return <BarChart data={this.state.chartValues} />;
  }

  getShownFilters() {
    return [
      'patientFirstName',
      'patientLastName',
      'dob',
      'mrn',
      'facility',
      'diagnosis',
      'cpt',
      'dispatchedDate',
      'examCompletedDate',
      'physicianFirstName',
      'physicianLastName',
      'examType',
    ];
  }

  loadListAction(conditionList, joint) {
    const payload = prettierFilterPayload(conditionList);

    return FacilityPatientPageActions.loadFacilityPatientList(payload, joint);
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }
  getActions() {
    return FacilityPatientPageActions;
  }
}

const facilityPatientPage = Container.create(FacilityPatientPage);
export default facilityPatientPage;
