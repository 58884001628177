import { action, makeObservable, observable, runInAction } from 'mobx';

import ExternalService from 'services/ExternalService';

export interface OptionType {
  value: number;
  label: string;
}

class DropdownUniversalIdType {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,

      setFetching: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  async getOptions() {
    if (this.fetching || this.options.length) return Promise.resolve();

    try {
      runInAction(() => {
        this.fetching = true;
      });
      const options = await ExternalService.doGet<OptionType[]>(
        'universal-id-type/dropdown'
      );

      runInAction(() => {
        this.fetching = false;
        this.options = Array.isArray(options) ? options : [];
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }
}

export const storeDropdownUniversalIdType = new DropdownUniversalIdType();
