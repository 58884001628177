import React from 'react';
import { Container } from 'flux/utils';

import Radio from 'components/form/input/Radio';
import Text from 'components/form/input/Text';
import AbstractOrderOverview, {
  PAbstractOrderOverview,
  SAbstractOrderOverview,
  TFilter,
} from 'page/workflow/order/AbstractOrderOverview';
import OrderOverviewStore from 'stores/workflow/order/OrderOverviewStore';
import ExamReviewStore from 'stores/workflow/order/ExamReviewStore';
import OrderOverviewActions from 'actions/workflow/order/OrderOverviewActions';
import Checkboxes from 'components/form/input/Checkboxes';
import UserProfileStore from 'stores/UserProfileStore';
import { getDateString } from 'utils/DateUtils';
import RolesGroupDropdown from 'components/project/dropdown/RolesGroupDropdown';
import PhysicianJoinedDropdown from 'components/project/dropdown/PhysicianJoinedDropdown';
import CptCategoryDropdown from 'components/project/dropdown/CptCategoryDropdown';
import Notification from 'components/modal/Notification';
import WorkflowOrderColumnsDescriptor from 'page/workflow/order/WorkflowOrderColumnsDescriptor';
import { Grid, GridControlButton } from 'components/grid';
import {
  initialValues,
  PatientIdOptions,
} from 'page/workflow/order/components/DialogAdditionalPatientIds';
import { storeCptCategory } from 'stores/_mobx/systemSetup/masterSetting/cptCategory';
import { storeGrid } from 'stores/_mobx/grid';
import { storeOrderOverview } from 'stores/_mobx/workflow/order/overview';

const GRID_ID = 'exam_review_orders_grid';

const ORDER_FLAGS = [
  { value: 'N', label: 'New' },
  { value: 'A', label: 'Assigned Orders' },
  { value: 'P', label: 'Exam Performed' },
  { value: 'IC_R', label: 'Pending Reports' },
  { value: 'IC_F', label: 'Pending Fax' },
  { value: 'C_CB', label: 'CallBack Manager' },
  { value: 'C_PR', label: 'Phone Results' },
  // { value: 'CRITICAL', label: 'Critical Findings' },
];

const STATUS = [
  { value: 'A', label: 'Active' },
  { value: 'D', label: 'Duplicate' },
  { value: 'C', label: 'Canceled' },
];

export interface PExamReview extends PAbstractOrderOverview {}

export class TExamReviewFilter extends TFilter {
  physician: string = '';
  physicianGroup: string = '';
  cpt_category_id: number = null;
  cptCode: string = '';
  accessionNum: string = '';
  mrn: string = '';
  recordsAccess: string = '';
  otherMrn: string = '';
  oid: string = '';
  iceNumber: string = '';
  marshallId: number = null;
  additionalPatientIdentifiersFields: PatientIdOptions = initialValues;
}

export class SExamReview extends SAbstractOrderOverview {
  orderFlags: any = {};
  filter: TExamReviewFilter = new TExamReviewFilter();
}

export class ExamReview extends AbstractOrderOverview<
  PExamReview,
  SExamReview
> {
  abortController: AbortController | null = null;

  static TITLE = 'Exam Review';

  static getStores() {
    return [OrderOverviewStore, ExamReviewStore];
  }

  static calculateState(prevState: SExamReview) {
    if (!prevState) {
      const defaultFilter = {
        ...new TExamReviewFilter(),
        period: 'TD',
        dosStart: getDateString(),
        dosEnd: getDateString(),
      };

      prevState = {
        ...AbstractOrderOverview.getDefaultState(GRID_ID),
        filter: storeGrid.getFilter(GRID_ID, defaultFilter),
        orderFlags: {},
        additionalPatientIdentifiers: false,
      };
    }
    return {
      ...prevState,
      dataSource: OrderOverviewStore.getState().dataSource,
      dataSourceCount: OrderOverviewStore.getState().dataSourceCount,
    };
  }

  getGridId() {
    return GRID_ID;
  }

  getTitle() {
    return ExamReview.TITLE;
  }

  onReset() {
    const filter = {
      ...new TExamReviewFilter(),
      period: 'TD',
      dosStart: getDateString(),
      dosEnd: getDateString(),
    };
    this.setState({ filter }, this.updateData);
  }

  prepareFilter(): TExamReviewFilter {
    const uiFilter = this.state.filter;
    const identifiersFilter = uiFilter.additionalPatientIdentifiersFields;

    const filter = {
      ...super.prepareFilter(),
      physician: uiFilter.physician,
      physicianGroup: uiFilter.physicianGroup,
      cpt_category_id: uiFilter.cpt_category_id,
      cptCode: uiFilter.cptCode,
      accessionNum: uiFilter.accessionNum,
      mrn: uiFilter.mrn,
      recordsAccess: UserProfileStore.getRecordsAccess(),
      ...(identifiersFilter
        ? {
            otherMrn: identifiersFilter.otherMrn,
            oid: identifiersFilter.oid,
            iceNumber: identifiersFilter.iceNumber,
            marshallId: identifiersFilter.marshallId,
          }
        : {}),
    };

    return filter;
  }

  componentDidMount() {
    storeCptCategory.getOptions().then(this.updateData);
  }

  updateData = () => {
    const controller = new AbortController();

    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = controller;

    this.setState({ onAjax: true });

    OrderOverviewActions.loadMedicalRecordGridData(
      this.prepareFilter() as any,
      this.state.pagination,
      this.mapFlags(),
      controller.signal
    ).then(this.updateDataCallback);
  };

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.abortController = null;

    this.setState({
      onAjax: false,
      selected: [],
    });

    if (grid) {
      // @ts-ignore
      grid.clearSelection();
    }
  };

  getFilterComponent() {
    const filter = super.getFilterComponent();
    return (
      <div>
        {filter}
        <Checkboxes
          noLabel
          name="orderFlags"
          options={ORDER_FLAGS}
          value={this.state.orderFlags}
          className="part-inline"
          onSetValue={(n, orderFlags) => {
            this.setState({ orderFlags }, this.updateData);
          }}
        />
      </div>
    );
  }

  renderOrderStatus() {
    return (
      <Radio
        name="status"
        noLabel
        options={STATUS}
        className="col-sm-5 part-inline"
        value={this.state.filter.orderStatus}
        onSetValue={(n, v) => {
          this.onSetStatusValue(v);
        }}
      />
    );
  }

  onSetStatusValue(orderStatus: string) {
    const grid = this.refs.grid as Grid;
    const filter = { ...this.state.filter, orderStatus };

    if (grid) {
      // TODO: grid.state.shownColumns & grid.setState outside grid component use - rework this!!!
      const columns = new Map<string, boolean>(grid.state.shownColumns);
      if (orderStatus === 'A') {
        columns.set('revert_cancelled', false);
      } else {
        columns.delete('revert_cancelled');
      }
      grid.onShownColumnsChange(columns);
    }
    this.setState({ filter }, this.updateData);
  }
  handleChangeAccessNumber = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const trimmedValue = value.trim();
    this.handleChangeFilter('accessionNum', trimmedValue);
  };

  mapFlags(): Array<{ selected: boolean; flag: string; sub?: string }> {
    const out: Array<{ selected: boolean; flag: string; sub?: string }> = [];
    const flags = this.state.orderFlags;
    for (let flagName in flags) {
      if (!flags.hasOwnProperty(flagName)) {
        continue;
      }
      const value = flags[flagName];
      if (value) {
        const split = flagName.split('_');
        const mapped: { selected: boolean; flag: string; sub?: string } = {
          selected: true,
          flag: split[0],
        };
        if (split.length === 2) {
          mapped.sub = split[1];
        }
        out.push(mapped);
      }
    }
    return out;
  }

  getAdditionalFilters() {
    const cptCode = (
      <Text
        name="cptCode"
        label="CPT Code"
        key="1"
        className="col-sm-3"
        value={this.state.filter.cptCode}
        onSetValue={this.handleChangeFilter}
      />
    );
    const cptCategory = (
      <CptCategoryDropdown
        name="cpt_category_id"
        key="CptCategoryDropdown_2"
        label="CPT Category"
        className="col-sm-3"
      />
    );
    const mrn = (
      <Text
        label="MRN"
        name="mrn"
        key="2"
        className="col-sm-3"
        value={this.state.filter.mrn}
        onSetValue={this.handleChangeFilter}
        contentAfter={
          <div>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ additionalPatientIdentifiers: true });
              }}>
              Additional Patient Identifiers Search
              {this.patientFilterHasValues() ? this.getExclamationPoint() : ''}
            </a>
          </div>
        }
      />
    );
    const accessionNum = (
      <Text
        name="accessionNum"
        label="Accession #"
        key="3"
        className="col-sm-3"
        value={this.state.filter.accessionNum}
        onBlur={this.handleChangeAccessNumber}
      />
    );
    if (UserProfileStore.getUserType() === 'D') {
      return [
        cptCode,
        <RolesGroupDropdown
          name="physicianGroup"
          key="2"
          className="col-sm-3"
        />,
        <PhysicianJoinedDropdown
          name="physician"
          key="3"
          className="col-sm-3"
          groupId={parseInt(this.state.filter.physicianGroup)}
        />,
      ];
    }
    return [cptCode, cptCategory, mrn, accessionNum];
  }

  getAllowedColumns() {
    let columns: Array<string> = [];
    switch (UserProfileStore.getUserType()) {
      case 'CA': //corporate medical
      case 'A': //facility admin
        columns =
          WorkflowOrderColumnsDescriptor.ExamReviewCorporateMedicalAndFacilityAdmin;
        break;
      case 'N': //nurse
        columns = WorkflowOrderColumnsDescriptor.ExamReviewNurse;
        break;
      case 'D': //physician
        columns = WorkflowOrderColumnsDescriptor.ExamReviewPhysician;
        break;
      case 'FC': //facility billing coordinator
        columns =
          WorkflowOrderColumnsDescriptor.ExamReviewFacilityBillingCoordinator;
        break;
      default:
        columns = WorkflowOrderColumnsDescriptor.ExamReviewDefault;
        break;
    }
    columns = Object.assign([], columns);
    if (!UserProfileStore.isClientUser()) {
      columns.push('cancel');
    }
    return columns;
  }

  handleCloseDialogAdditionalPatientIds = () => {
    this.setState({ additionalPatientIdentifiers: false });
  };

  additionalPatientIdentifierCallback = (data: PatientIdOptions) => {
    const filter = {
      ...this.state.filter,
      additionalPatientIdentifiersFields: data,
    };
    this.setState({ additionalPatientIdentifiers: false, filter });
  };

  patientFilterHasValues() {
    const filter = this.state.filter.additionalPatientIdentifiersFields;
    if (filter) {
      return (
        filter.otherMrn || filter.oid || filter.iceNumber || filter.marshallId
      );
    }
    return false;
  }

  getExclamationPoint() {
    return <span className="text-danger"> (!)</span>;
  }

  handleClickViewImg = () => {
    const eUnityCpt = this.getEUnityCpt();
    if (eUnityCpt.length) {
      storeOrderOverview.openEUnityLinkByParams(eUnityCpt);
    } else {
      Notification.warning(
        'Please select orders for same patient with total amount of CPT procedures greater than 2 and less than 8',
        { autoClose: 6000 }
      );
    }
  };

  getControlPanel = () => {
    const eUnityCpt = this.getEUnityCpt();

    return (
      <GridControlButton
        title="View Images in eUnity"
        className={`view_in_eunity${eUnityCpt.length ? '' : ' disabled'}`}
        onClick={this.handleClickViewImg}
      />
    );
  };

  getEUnityCpt() {
    const MAX_CPT = 8;
    const { selected } = this.state;
    const orders = this.state.dataSource;
    const selectedOrders = [];
    let thisPatientId = null;
    for (let i = 0; i < selected.length; i++) {
      const id = selected[i];
      for (let o = 0; o < orders.length; o++) {
        const order = orders[o];
        if (Number(order.refid) === Number(id)) {
          if (selectedOrders.length === MAX_CPT) {
            return [];
          }
          if (thisPatientId === null) {
            thisPatientId = order.patientid;
          } else if (thisPatientId !== order.patientid) {
            return [];
          }
          selectedOrders.push(order);
        }
      }
    }

    if (selectedOrders.length > MAX_CPT || selectedOrders.length < 2) {
      return [];
    }
    let cptCount = 0;
    const orderList: Array<any> = [];
    selectedOrders.forEach((order) => {
      const cpt = order.GetIntCptArr;
      orderList.push(order.refid);
      cptCount += cpt.length;
    });
    return cptCount > 1 && cptCount <= MAX_CPT ? orderList : [];
  }
}

export default Container.create(ExamReview);
