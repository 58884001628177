import React, { useMemo } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
// @ts-ignore
import useFluxStore from 'flux-hooks';

import UserProfileStore from 'stores/UserProfileStore';
import { backUrlGenerator } from 'utils/backUrlGenerator';

interface Props extends Pick<RouteProps, 'location'> {
  path: string;
  exact?: boolean;
  component: React.FunctionComponent | React.ClassType<any, any, any>;
}

const ProtectedRoute = ({ component: Component, ...rest }: Props) => {
  const isLoggedIn = useFluxStore(
    UserProfileStore,
    (prevState: any, store: any) => store.getState().loggedIn
  );
  const url = useMemo(() => {
    const { pathname, search } = rest.location;

    const url = backUrlGenerator(`${pathname}${search}`);

    return isLoggedIn ? '/' : url;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={url} />
      }
    />
  );
};

export default ProtectedRoute;
