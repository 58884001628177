import { apiRequest } from 'services/RequestService';

export class TZipCode {
  zip: string = '';
}

export class TZipRange {
  zipStart: string = '';
  zipEnd: string = '';
}

export class TAddJurisdiction {
  stateId: number = null;
  area: string = '';
  zipCodeList: Array<TZipCode> = [];
  zipCodeRangeList: Array<TZipRange> = [];
  modalityList: Array<TNpiItem> = [];
}

export class TNpiItem {
  npinumber: string = '';
  npiname: string = '';
  modality_id: number = null;
}

export class TUpdateJurisdiction {
  refid: number = null;
  stateId: number = null;
  area: string = '';
  modalityList: Array<TNpiItem> = [];
}

export class TJurisdiction {
  refid: number = null;
  stateId: number = null;
  area: string = '';
}

export class TJurisdictionModality {
  refid: number = null;
  exam_type_nm: string = '';
  modality: string = '';
  modality_id: number = null;
  npiname: string = '';
  npinumber: string = '';
}

export class TJurisdictionZipCode {
  refid: number = null;
  jurisdiction: number = null;
  zip: string = '';
}

export class TJurisdictionZipCodeRange {
  refid: number = null;
  jurisdiction: number = null;
  zipStart: string = '';
  zipEnd: string = '';
}

class JurisdictionDetailsService {
  createJurisdiction(item: TAddJurisdiction) {
    const data = [
      item.stateId,
      item.area,
      item.zipCodeList,
      item.zipCodeRangeList,
      item.modalityList,
    ];

    return apiRequest<'SE' | 'S' | 'E'>({
      url: 'generalmaster.jurisdiction.AddJurisdiction',
      data,
    });
  }

  updateJurisdiction(item: TUpdateJurisdiction) {
    const data = [item.refid, item.stateId, item.area, item.modalityList];

    return apiRequest<'SE' | 'S' | 'E'>({
      url: 'generalmaster.jurisdiction.UpdateJurisdiction',
      data,
    });
  }

  loadJurisdiction(id: number) {
    return apiRequest<Array<TJurisdiction>>({
      url: 'generalmaster.jurisdiction.GetJurisdiction',
      data: [id],
    });
  }

  loadJurisdictionStates() {
    return apiRequest<'SE' | Array<{ label: string; data: number }>>({
      url: 'generalmaster.MvLocation.GetStateDropDown',
    }).then((response: 'SE' | Array<{ label: string; data: number }>) => {
      response = response === 'SE' ? [] : response;
      return response.map((v) => {
        return { label: v.label, value: v.data };
      });
    });
  }

  loadJurisdictionModalityList(jurId: number) {
    return apiRequest<{ result: Array<TJurisdictionModality> }>({
      url: 'generalmaster.jurisdiction.GetJurisdictionModalities',
      data: [jurId],
    });
  }

  loadJurisdictionZipCodes(
    jurId: number,
    filter: { zip: string },
    pagination: { skip: number; limit: number }
  ) {
    return apiRequest<{
      totalCount: number;
      result: Array<TJurisdictionZipCode>;
    }>({
      url: 'generalmaster.jurisdiction.GetJurisdictionZipCodes',
      data: [jurId, filter, pagination],
    });
  }

  updateJurisdictionZipCodes(jurId: number, codes: Array<{ zip: string }>) {
    return apiRequest<'SE' | 'S'>({
      url: 'generalmaster.jurisdiction.UpdateJurisdictionZipCodes',
      data: [jurId, codes],
    });
  }

  jurisdictionZipCodeExists(jurId: number, zip: string) {
    return apiRequest<boolean>({
      url: 'generalmaster.jurisdiction.JurisdictionZipCodeExists',
      data: [jurId, zip],
    });
  }

  loadJurisdictionZipCodeRanges(
    jurId: number,
    filter: { zipStart: string; zipEnd: string },
    pagination: { skip: number; limit: number }
  ) {
    return apiRequest<{
      totalCount: number;
      result: Array<TJurisdictionZipCodeRange>;
    }>({
      url: 'generalmaster.jurisdiction.GetJurisdictionZipCodeRanges',
      data: [jurId, filter, pagination],
    });
  }

  updateJurisdictionZipCodeRanges(
    jurId: number,
    ranges: Array<{ zipStart: string; zipEnd: string }>
  ) {
    return apiRequest<'SE' | 'S'>({
      url: 'generalmaster.jurisdiction.UpdateJurisdictionZipCodeRanges',
      data: [jurId, ranges],
    });
  }

  addJurisdictionZipCode(jurId: number, zip: string) {
    return apiRequest<'SE' | 'S'>({
      url: 'generalmaster.jurisdiction.AddJurisdictionZipCode',
      data: [jurId, zip],
    });
  }

  deleteJurisdictionZipCode(jurId: number, zip: string) {
    return apiRequest<'SE' | 'S'>({
      url: 'generalmaster.jurisdiction.DeleteJurisdictionZipCode',
      data: [jurId, zip],
    });
  }

  addJurisdictionZipCodeRange(jurId: number, zipStart: string, zipEnd: string) {
    return apiRequest<'SE' | 'S'>({
      url: 'generalmaster.jurisdiction.AddJurisdictionZipCodeRange',
      data: [jurId, zipStart, zipEnd],
    });
  }

  deleteJurisdictionZipCodeRange(
    jurId: number,
    zipStart: string,
    zipEnd: string
  ) {
    return apiRequest<'SE' | 'S'>({
      url: 'generalmaster.jurisdiction.DeleteJurisdictionZipCodeRange',
      data: [jurId, zipStart, zipEnd],
    });
  }

  jurisdictionZipCodeRangeExists(
    jurId: number,
    zipStart: string,
    zipEnd: string
  ) {
    return apiRequest<boolean>({
      url: 'generalmaster.jurisdiction.JurisdictionZipCodeRangeExists',
      data: [jurId, zipStart, zipEnd],
    });
  }
}

export default new JurisdictionDetailsService();
