import { action, makeObservable, observable } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';

class TermsAndCond {
  fetching: boolean = false;
  terms: string = '';

  constructor() {
    makeObservable(this, {
      fetching: observable,
      terms: observable,

      setFetching: action,
      setTerms: action,
    });
  }

  setFetching(status: boolean) {
    this.fetching = status;
  }

  setTerms(terms: string) {
    this.terms = terms;
  }

  async getTerms() {
    this.setFetching(true);
    try {
      const response = await apiRequest<string>({
        url: 'generalmaster.TermsConditions.DisplayTC',
      });

      const terms = response?.replace(/[\\]/g, '') || '';
      this.setTerms(terms);
    } catch (e) {
      this.setTerms('');
    } finally {
      this.setFetching(false);
    }
  }

  async saveTerms(terms: string) {
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.TermsConditions.AddTermsConditions',
        data: [terms],
      });
      if (result === 'S') {
        Notification.success('Terms and conditions were updated!');
        return true;
      }
      throw Error('');
    } catch (e) {
      Notification.danger("Terms and conditions weren't save!");
      return false;
    }
  }
}

export const storeTermsAndConditions = new TermsAndCond();
