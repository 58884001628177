import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { FakeInput } from 'components/form/textField';
import { Select, SelectPropsType } from 'components/form/select';

import UserProfileStore from 'stores/UserProfileStore';
import { storeDropdownFacility } from 'stores/_mobx/dropDown/facility';

const DropdownFacilityRoleActive = (
  props: Omit<SelectPropsType, 'options'>
) => {
  const { currentUserFacilityName, optionsActiveOnly, fetchingActiveOnly } =
    storeDropdownFacility;

  const isAllowed = useMemo(() => UserProfileStore.canSeeFacilities(), []);

  useEffect(() => {
    if (!isAllowed && !currentUserFacilityName) {
      storeDropdownFacility.getFacilityName(UserProfileStore.getFacilityId());
    } else {
      storeDropdownFacility.getOptionsActiveFacility();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllowed, currentUserFacilityName]);

  return isAllowed ? (
    <Select
      {...props}
      options={optionsActiveOnly}
      isLoading={fetchingActiveOnly}
    />
  ) : (
    <FakeInput
      className={props.className}
      label={props.label}
      value={currentUserFacilityName}
    />
  );
};

export default observer(DropdownFacilityRoleActive);
