import React from 'react';

import { IconButton } from 'components/button';
import { Grid } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import EmployeeInformationActions from 'actions/reports/hr-reports/EmployeeInformationActions';
import { downloadFile } from 'utils/downloadFile';

const showDocument = (
  event: any,
  document: { user_id: number; file_name: string }
) => {
  if (event) {
    event.preventDefault();
  }
  downloadFile(`/documents/${document.user_id}/${document.file_name}`, true);
};

interface PLicensesPopup {
  userName: string;
  userId: number;
  documentType: string;
  onClose: () => void;
}

export class SLicensesPopup {
  documents: Array<{ user_id: number; file_name: string }> = [];
}

export default class LicensesPopup extends React.Component<
  PLicensesPopup,
  SLicensesPopup
> {
  state = new SLicensesPopup();

  COLUMNS = [
    { name: 'file_name', title: 'File Name' },
    { name: 'valid_from', title: 'Valid From', dataType: 'date' },
    { name: 'valid_to', title: 'Valid To', dataType: 'date' },
    {
      name: 'control',
      title: ' ',
      render: (v: any, document: { user_id: number; file_name: string }) => (
        <IconButton onClick={(event) => showDocument(event, document)}>
          <i className="icon icon-view" />
        </IconButton>
      ),
    },
  ];

  componentDidMount() {
    EmployeeInformationActions.loadDocuments(
      this.props.userId,
      this.props.documentType
    ).then((documents) => this.setState({ documents }));
  }

  render() {
    let title = 'Licenses';
    if (this.props.userName) {
      title += ' (' + this.props.userName + ')';
    }
    return (
      <Dialog scrollable={false} size="large" handleClose={this.props.onClose}>
        <DialogHeader title={title} onClose={this.props.onClose} />
        <DialogBody className="license-popup">
          <Grid
            dataSource={this.state.documents}
            wrapperClass=" "
            columns={this.COLUMNS}
            disablePagination
            stateless
          />
        </DialogBody>
      </Dialog>
    );
  }
}
