import { Route, Switch } from 'react-router-dom';

import { AccessRoute } from 'components/HOC';
import * as IDS from 'constant/pagesId/clinicianManager';
import * as PATH from 'constant/path/clinicalManager';

import PhysicianListPage from 'page/clinician-manager/physicians/ListPage';
import PhysicianEditPage from 'page/clinician-manager/physicians/EditPage';
import PhysicianAddPage from 'page/clinician-manager/physicians/AddPage';

import GroupManagerListPage from 'page/clinician-manager/groupManager/ListPage';
import GroupManagerAddPage from 'page/clinician-manager/groupManager/AddPage';
import GroupManagerEditPage from 'page/clinician-manager/groupManager/EditPage';

import SignedOrderPage from 'page/clinician-manager/sign-orders/SignedOrderPage';

import E404 from 'page/errors/404';

export const clinicalManagerRoutes = ['/clinician-manager'];

const ClinicalManager = () => (
  <Switch>
    <AccessRoute
      exact
      pageId={IDS.PHYSICIAN.PAGE}
      path={PATH.URL_PHYSICIANS}
      component={PhysicianListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.PHYSICIAN.ADD}
      path={PATH.URL_PHYSICIANS_ADD}
      component={PhysicianAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.PHYSICIAN.EDIT}
      path={PATH.URL_PHYSICIANS_EDIT}
      component={PhysicianEditPage}
    />

    <AccessRoute
      exact
      pageId={IDS.GROUP_MANAGER.PAGE}
      path={PATH.URL_GROUP_MANAGER}
      component={GroupManagerListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.GROUP_MANAGER.PAGE}
      path={PATH.URL_GROUP_MANAGER_ADD}
      component={GroupManagerAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.GROUP_MANAGER.PAGE}
      path={PATH.URL_GROUP_MANAGER_EDIT}
      component={GroupManagerEditPage}
    />

    <AccessRoute
      pageId={IDS.SIGN_ORDERS.PAGE}
      path={PATH.URL_SIGNED_ORDER}
      component={SignedOrderPage}
    />
    <Route path="*" component={E404} />
  </Switch>
);

export default ClinicalManager;
