import React from 'react';
import { Container } from 'flux/utils';

import Radio from 'components/form/input/Radio';
import Checkbox from 'components/form/input/Checkbox';
import DialogMessagesFacility from 'page/components/messages/DialogFacility';
import EncounterMessageDialog from 'page/components/messages/EncounterMessageDialog';
import Text from 'components/form/input/Text';
import NumericInput from 'components/form/input/NumericInput';
import Notification from 'components/modal/Notification';
import { Button, IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import { ControlsLayout, LayoutSideTitle } from 'components/layout';
import Badge from 'components/badge';
import FacilityPreviewStore from 'stores/billing-coding/FacilityPreviewStore';
import FacilityPreviewActions, {
  TFacilityPreviewFilter,
} from 'actions/billing-coding/FacilityPreviewActions';
import UserProfileStore from 'stores/UserProfileStore';
import DialogFormPerDiem, {
  PerDiemType,
} from 'page/billing-coding/DialogFormPerDiem';
import { moneyRender } from 'utils/StringUtils';
import PreInvoicesActions from 'actions/billing-coding/PreInvoicesActions';
import FacilityOverdueAmountsModal from 'page/billing-coding/FacilityOverdueAmountsModal';

import {
  TDueData,
  TPreinvoiceMultipleInsertUpdate,
  TInvoiceItem,
} from 'services/billing-coding/PreInvoicesService';
import {
  TOrderNewPay,
  TFacilityData,
  TPerDiemRpt,
} from 'services/billing-coding/FacilityPreviewService';
import { storeChatting } from 'stores/_mobx/message/chatting';
import { FACILITY_PREVIEW as PAGE_ID } from 'constant/pagesId/billingCodding';

const OPTIONS = [
  { value: 'pps', label: 'PPS' },
  { value: 'mco', label: 'MCO' },
  { value: 'arranged', label: 'ARRANGED' },
];

export class TPerDiemRptExt extends TPerDiemRpt {
  unit: string = '';
}

export interface PFacilityPreview {
  title?: string;
  invoiceInfo: {
    facilityid: number;
    preinvoice_no: number;
    invoice_no: number;
    perdiem: 'Y' | 'N';
  };
  canSeePage: boolean;
  filter: TFacilityPreviewFilter;
  invoiceType: 'F' | 'D' | 'H' | 'P' | '';
  onClose: () => void;
  callback: (value: TPreinvoiceMultipleInsertUpdate) => void;
}

export class TFacilityDataExt extends TFacilityData {
  facilityid: number = null;
  perdiem_rptrvalue: PerDiemType[] = [];
}

export class SFacilityPreview {
  onAjax: boolean = false;
  generateInvoice: boolean = false;
  billingMessage: boolean = false;
  dataSource: Array<TOrderNewPay> = [];
  selectAll: string = '';
  order: any = null;
  orderMessages: boolean = false;
  messagesExist: boolean = false;
  unitErrors: {
    [index: string]: { unit: Array<string> };
  } = {};
  selected: Array<any> = [];
  facility: TFacilityDataExt = new TFacilityDataExt();
  editPerDiem: boolean = false;
}

export class FacilityPreview extends React.Component<
  PFacilityPreview,
  SFacilityPreview
> {
  static getStores() {
    return [FacilityPreviewStore];
  }

  static calculateState(prevState = new SFacilityPreview()) {
    const store = FacilityPreviewStore.getState();
    const facility = Object.assign(new TFacilityDataExt(), store.facility);
    facility.perdiem_rptrvalue = facility.perdiem_rptrvalue.map(
      (item: TPerDiemRpt) => ({
        unit: '',
        ...item,
        index: Number(item.index),
      })
    );
    return Object.assign(prevState, {
      facility: facility,
      dataSource:
        prevState.dataSource.length === 0
          ? store.dataSource
          : prevState.dataSource,
    });
  }

  unitReferences: {
    [index: string]: { unit: Text };
  } = {};

  componentWillUnmount() {
    FacilityPreviewActions.clearStore();
    if (this.props.onClose) this.props.onClose();
  }

  componentDidMount() {
    if (!this.canSeePage()) {
      this.setState({ onAjax: false });
      return;
    }
    const { facility, invoiceNo } = this.getParams();
    FacilityPreviewActions.loadFacilityData(facility, invoiceNo)
      .then(this.updateData)
      .then(() =>
        storeChatting.hasLogByFacilityIds(facility).then((r) => {
          this.setState({
            onAjax: false,
            messagesExist: r[facility],
          });
        })
      );
  }

  getParams() {
    const info = this.props.invoiceInfo;
    if (info) {
      return {
        facility: info.facilityid,
        invoiceNo: info.preinvoice_no,
      };
    }
    return {
      facility: UserProfileStore.getFacility(),
      invoiceNo: 0,
    };
  }

  updateData = () => {
    if (!this.canSeePage()) {
      return;
    }

    const { facility, invoiceNo } = this.getParams();

    this.setState(
      {
        onAjax: true,
        dataSource: [],
      },
      () => {
        FacilityPreviewActions.loadOverview(
          facility,
          invoiceNo,
          this.props.filter
        ).then(this.updateDataCallback);
      }
    );
  };

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.setState({
      onAjax: false,
      selected: [],
    });
    if (grid) {
      // @ts-ignore
      grid.clearSelection();
    }
  };

  getFilterComponent() {
    return (
      <Radio
        name="selectAll"
        className="col-sm-6 part-inline"
        options={OPTIONS}
        value={this.state.selectAll}
        onSetValue={(n, v) => this.selectAll(v)}
      />
    );
  }

  selectAll(value: string) {
    const key = this.getPaymentName(value);
    const { dataSource } = this.state;
    const ds = dataSource.map((elem) =>
      Object.assign({}, elem, {
        selpps: false,
        selmco: false,
        selarranged: false,
        [key]: value,
      })
    );
    this.setState({ selectAll: value, dataSource: ds });
  }

  canSeePage() {
    if (this.props.canSeePage !== undefined) {
      return this.props.canSeePage;
    }
    return UserProfileStore.getUser().type === '1';
  }

  prepareOverdueAmountData() {
    if (this.state.facility) {
      return [
        {
          facilitynm: this.state.facility.facility_nm,
          facilityid:
            this.state.facility.facilityid || UserProfileStore.getFacility(),
        },
      ];
    }
    return [{}];
  }

  overdueAmountModalCallback(response: any, data: any) {
    let clb = null;
    if (response) {
      clb = () => this.generateInvoice(data);
    }
    this.setState({ generateInvoice: false }, clb);
  }

  onSelectChange = (selected: any[]) => {
    this.setState({ selected });
  };

  renderButtons = () => (
    <>
      <Button
        className="position-relative"
        onClick={() => {
          this.setState({ billingMessage: true });
        }}>
        Send Billing Message
        {this.state.messagesExist && (
          <Badge
            variant="danger"
            horizontal="right"
            position="absolute"
            rounded>
            !
          </Badge>
        )}
      </Button>
      {this.canGenerateInvoice() && (
        <Button
          disabled={this.state.onAjax}
          text="Generate Invoices"
          onClick={() => {
            if (!this.generateInvoiceValidate()) {
              Notification.warning('Invalid invoice data');
              return;
            }
            this.setState({ generateInvoice: true });
          }}
        />
      )}
    </>
  );

  render() {
    const { state, props } = this;
    const { order } = state;
    const invoiceInfo: {
      facilityid: number;
      preinvoice_no: number;
    } = props.invoiceInfo || { facilityid: null, preinvoice_no: null };

    const title = this.getTitle();

    return (
      <div>
        {!this.canSeePage() && (
          <div className="forbidden-overlay">
            <div className="forbidden-content">
              Only facility users can see this page
            </div>
          </div>
        )}
        <LayoutSideTitle appId={title ? '' : PAGE_ID.PAGE} title={title}>
          <Button
            type="button"
            variant="danger"
            text="Back"
            onClick={() => {
              this.props.callback(null);
            }}
          />
        </LayoutSideTitle>
        <ControlsLayout className="mb-4">{this.renderButtons()}</ControlsLayout>

        {this.getFilterComponent()}

        {this.isPerDiem() && (
          <Grid
            wrapperClass="col-md-6"
            columns={this.PER_DIEM_COLUMNS}
            disablePagination
            dataSource={this.getPerDiemDataSource()}
            gridControlPanel={
              <GridControlButton
                title="Edit"
                onClick={this.handleOpenPerDiamForEdit}
              />
            }
          />
        )}
        <p>
          {!UserProfileStore.isClientUser() ? (
            <span>
              The listed patient(s) are currently assigned to be invoiced to the
              facility.
              <br />
              Navigate to “Billing Assignment-Assigned” to re-assign billing
              assignment.
              <br />
              Please review charge amounts for compliant invoice generation.
            </span>
          ) : (
            <span>
              The listed patient(s) are currently assigned to be invoiced to the
              facility.
              <br />
              Click on the &quot;Send Billing Message&quot; button to submit a
              comment or request to Billing department.
              <br />
              If you see patient(s) listed below with an incorrect
              assignment/bill process you may navigate to
              <br />
              the &quot;Billing Assignment-Assigned&quot; section to re-assign a
              claim(s) billing assignment
            </span>
          )}
        </p>
        <Grid
          onAjax={state.onAjax}
          disablePagination
          columns={this.getColumns()}
          dataSource={state.dataSource}
          selectId="refid"
          onSelectChange={this.onSelectChange}
          id="facility_preview_grid"
          dataSourceCount={state.dataSource.length}
        />
        {!state.orderMessages ? null : (
          <EncounterMessageDialog
            id={order.claimId || order.studyId || order.orderId}
            onClose={this.orderMessages}
          />
        )}
        {!state.billingMessage ? null : (
          <DialogMessagesFacility
            id={invoiceInfo.facilityid || UserProfileStore.getFacility()}
            onClose={this.handleCloseFacilityMessage}
          />
        )}
        {state.generateInvoice && (
          <FacilityOverdueAmountsModal
            dataSource={this.prepareOverdueAmountData()}
            callback={this.overdueAmountModalCallback.bind(this)}
          />
        )}
        {state.editPerDiem && (
          <DialogFormPerDiem
            perDiemDetails={{ perDiem: state.facility.perdiem_rptrvalue || [] }}
            onUpdate={this.updatePerDiemList}
            onClose={this.closeEditPerDiem}
          />
        )}
      </div>
    );
  }

  updatePerDiemList = (perDiem: PerDiemType[]) => {
    const facility = {
      ...this.state.facility,
      perdiem_rptrvalue: perDiem,
    };
    this.setState({ editPerDiem: false, facility });
  };

  closeEditPerDiem = () => {
    this.setState({ editPerDiem: false });
  };

  PER_DIEM_COLUMNS = [
    { name: 'fee', title: 'Rate' },
    { name: 'name', title: 'Unit Name' },
    {
      name: 'unit',
      title: 'Unit',
      className: 'width-150',
      render: (valueIn: string, data: TPerDiemRptExt) => {
        const unitError = this.state.unitErrors[data.index];
        return (
          <NumericInput
            name="unit"
            value={data.unit}
            attr={{ min: 0, max: 1000000 }}
            validations="required:default positive"
            errors={unitError && unitError.unit}
            ref={(refTextControl: Text) => {
              const { index } = data;
              const unit: { unit: Text } = { ...this.unitReferences[index] };
              unit.unit = refTextControl;

              this.unitReferences = {
                ...this.unitReferences,
                [index]: unit,
              };
            }}
            onSetValue={(
              name: string,
              value: any,
              errorMessages?: any,
              errorKeys?: any,
              event?: any,
              rowRef?: any
            ) => {
              this.updatePerDiem(value, data);
            }}
            noLabel
          />
        );
      },
    },
  ];

  canGenerateInvoice() {
    return !UserProfileStore.isClientUser();
  }

  updatePerDiem(numberOfUnits: string, row: TPerDiemRptExt) {
    const pd = this.getPerDiemDataSource();
    for (let i = 0; i < pd.length; i++) {
      if ('' + pd[i].index !== '' + row.index) {
        continue;
      }
      const data = Object.assign(new TPerDiemRptExt(), row);
      data.unit = numberOfUnits;
      pd[i] = data;
      const facility = Object.assign(
        new TFacilityDataExt(),
        this.state.facility
      );
      facility.perdiem_rptrvalue = pd;
      const unitErrors = Object.assign({}, this.state.unitErrors);
      const unitRef: { unit: Text } = { ...this.unitReferences[row.index] };
      const inputState =
        unitRef && unitRef.unit
          ? unitRef.unit.getInputState(numberOfUnits)
          : null;
      unitErrors[data.index] = Object.assign({}, unitErrors[data.index], {
        unit: inputState ? inputState.errorMessages : '',
      });
      this.setState({ facility: facility, unitErrors: unitErrors });
      break;
    }
  }

  handleOpenPerDiamForEdit = () => {
    this.setState({ editPerDiem: true });
  };

  getPerDiemDataSource(): PerDiemType[] {
    return this.state.facility.perdiem_rptrvalue;
  }

  orderMessages = (shouldUpdate: boolean) => {
    this.setState({
      order: null,
      orderMessages: false,
    });
    if (shouldUpdate) this.updateData();
  };

  handleCloseFacilityMessage = (shouldUpdate: boolean) => {
    this.setState({ billingMessage: false });
    if (shouldUpdate) this.updateData();
  };

  getTitle() {
    if (this.props.title) {
      return this.props.title;
    }
    const facilityName = this.state.facility?.facility_nm || '';
    return facilityName ? `${facilityName} preview` : 'Facility Preview';
  }

  generateInvoiceValidate() {
    let flag = true;
    Object.entries(this.unitReferences).forEach(([index, row]) => {
      Object.entries(row).forEach(([name, input]) => {
        if (!input) {
          return;
        }
        const inputState = input.getInputState(input.getValue());
        if (inputState.errorKeys && inputState.errorKeys.length > 0) {
          flag = false;
        }
        this.setState(({ unitErrors, ...state }) => ({
          ...state,
          unitErrors: {
            ...unitErrors,
            [index]: {
              ...unitErrors[index],
              [name]: inputState.errorMessages,
            },
          },
        }));
      });
    });
    return flag;
  }

  generateInvoice(dueData: Array<TDueData>) {
    const { props, state } = this;
    const info: {
      facilityid: number;
      preinvoice_no: number;
      invoice_no: number;
      perdiem: 'Y' | 'N';
    } = props.invoiceInfo || {
      facilityid: 0,
      preinvoice_no: 0,
      invoice_no: 0,
      perdiem: 'N',
    };
    const { facility } = this.getParams();
    let ds: Array<TInvoiceItem> = [];
    const me = this;
    if (me.isPerDiem()) {
      state.dataSource.forEach((row: TOrderNewPay) => {
        ds.push(Object.assign(new TInvoiceItem(), row));
      });
    } else {
      state.dataSource.forEach((row: TOrderNewPay) => {
        ds.push(Object.assign(new TInvoiceItem(), row));
      });
    }
    PreInvoicesActions.insertUpdate(
      info.invoice_no || info.preinvoice_no,
      ds,
      facility,
      info.perdiem,
      props.invoiceType,
      this.getPerDiemUnits(),
      'A',
      this.getPerDiemNames(),
      this.getPerDiemPrices(),
      dueData[0]
    ).then((response: TPreinvoiceMultipleInsertUpdate) => {
      if (props.callback) {
        props.callback(response);
      }
    });
  }

  getPerDiemUnits() {
    return this._processPerDiem((item) => `${item.unit || 0},`);
  }

  getPerDiemNames() {
    return this._processPerDiem((item) => `${item.index || 0},`);
  }

  getPerDiemPrices() {
    return this._processPerDiem((item) => `${item.fee || 0},`);
  }

  _processPerDiem(clb: (value: any, idx: number, data: Array<any>) => string) {
    if (!this.isPerDiem()) {
      return '';
    }
    let out = '';
    const data = this.state.facility.perdiem_rptrvalue || [];
    for (let i = 0; i < data.length; i++) {
      out += clb(data[i], i, data);
    }
    return out;
  }

  isPerDiem() {
    const { props } = this;
    return props.invoiceInfo && props.invoiceInfo.perdiem === 'Y';
  }

  paymentRadio(data: TOrderNewPay) {
    return (
      !!data.claimId && (
        <Radio
          options={OPTIONS}
          onSetValue={(n, v) => this.updatePaymentRadio(v, data)}
          className="inline"
          value={this.findPaymentValue(data)}
          name={`${data.claimId}_payment`}
          noLabel
        />
      )
    );
  }

  findPaymentValue(data: TOrderNewPay) {
    if (data.selpps) {
      return 'pps';
    } else if (data.selmco) {
      return 'mco';
    } else if (data.selarranged) {
      return 'arranged';
    }
    return null;
  }

  getColumns() {
    const out = [
      { name: 'claimId', title: 'Claim #' },
      {
        name: 'select',
        title: 'Select',
        render: (v: any, data: TOrderNewPay) => this.select(data),
        noOverflow: true,
      },
      {
        name: '',
        title: 'PPS - MCO - ARRANGED',
        render: (v: any, data: TOrderNewPay) => this.paymentRadio(data),
      },
      { name: 'patientname', title: 'Patient Name' },
      {
        name: 'dos_content',
        title: 'DOS',
        className: 'text-nowrap',
        dataType: 'date',
      }, // todo apply proper DOS
      { name: 'cptcode_modifier', title: 'CPT/CHPC' },
      { name: 'units', title: 'Units' },
      { name: 'cpt_desc', title: 'CPT Description' },
      {
        name: 'cpt_price_val',
        title: 'Total Charges',
        render: (value: string | number) => moneyRender(value),
      },
    ];
    if (!UserProfileStore.isClientUser()) {
      out.push({
        name: 'encounterLog',
        title: 'Encounter Log',
        render: (v: any, data: TOrderNewPay) =>
          data.claimId && (
            <IconButton
              className="text-primary"
              onClick={() => {
                this.setState({
                  order: data,
                  orderMessages: true,
                });
              }}>
              <i
                className={`bi ${
                  data.hasLog ? 'bi-envelope-check-fill' : 'bi-envelope'
                }`}
              />
            </IconButton>
          ),
      });
    }
    return out;
  }

  select(data: TOrderNewPay) {
    return (
      !data.claimId && (
        <Checkbox
          value={data.selstatus ? 1 : 0}
          name="selstatus"
          onSetValue={(n, v) => this.updateGrid(n, v, data)}
          noLabel
        />
      )
    );
  }

  getPaymentName(value: string) {
    let name;
    if (value === 'pps') {
      name = 'selpps';
    } else if (value === 'mco') {
      name = 'selmco';
    } else if (value === 'arranged') {
      name = 'selarranged';
    } else {
      Notification.warning('Unknown payment type!');
      return null;
    }
    return name;
  }

  updatePaymentRadio(value: string, row: TOrderNewPay) {
    const name = this.getPaymentName(value);
    const ds = [...this.state.dataSource];
    for (let i = 0; i < ds.length; i++) {
      if (ds[i] === row) {
        ds[i] = Object.assign({}, row);
        ds[i].selpps = false;
        ds[i].selmco = false;
        ds[i].selarranged = false;
        (ds[i] as any)[name] = true;
      }
    }
    this.setState({ dataSource: ds });
  }

  updateGrid(name: string, value: any, row: TOrderNewPay) {
    const ds = [...this.state.dataSource];
    for (let i = 0; i < ds.length; i++) {
      if (ds[i] === row) {
        ds[i] = Object.assign({}, row);
        (ds[i] as any)[name] = value;
      }
    }
    this.setState({ dataSource: ds });
  }
}

const FacilityPreviewContainer = Container.create(FacilityPreview);
export default FacilityPreviewContainer;
