import { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import GridFeeSchedule from './GridFeeSchedule';

import { storeRadiologyGroup } from 'stores/_mobx/systemSetup/masterSetting/radiologyGroup';

interface PropsType {
  onClose: () => void;
}

const DialogFeeSchedule = ({ onClose }: PropsType) => {
  const {
    fetchingFee,
    feeProps,
    feeData,
    feeTotal,
    pageFee: { pagination, setPagination },
  } = storeRadiologyGroup;

  const [feeDataSource, setFeeDataSource] = useState(toJS(feeData));

  const handleSaveFee = () => {
    storeRadiologyGroup.updateFeeData(feeDataSource).then((isSucceed) => {
      if (isSucceed) storeRadiologyGroup.clearFee();
    });
  };

  const handleDataUpdate = (rowId: string, name: string, value: string) => {
    const feeList = feeDataSource.map((fee) =>
      fee.refid === rowId
        ? {
            ...fee,
            [name]: value,
          }
        : fee
    );

    setFeeDataSource(feeList);
  };

  useEffect(() => {
    setFeeDataSource(toJS(feeData));
  }, [feeData]);

  useEffect(() => {
    storeRadiologyGroup.getFeeData();

    return storeRadiologyGroup.clearFee;
  }, []);

  return (
    <GridFeeSchedule
      title={feeProps.radiologyGroupName}
      onAjax={fetchingFee}
      dataSource={feeDataSource}
      dataSourceCount={feeTotal}
      pagination={pagination}
      errorsStorage={null}
      onPaginationChange={setPagination}
      onUpdate={handleSaveFee}
      onClose={onClose}
      // @ts-ignore
      updateData={handleDataUpdate}
    />
  );
};

export default observer(DialogFeeSchedule);
