import { IconButton } from 'components/button';
import Tooltip from 'components/tooltip';

import {
  storeInsuranceVerification,
  InsuranceType,
} from 'stores/_mobx/billingCodding/insuranceVerification';

const columnsPrettier = ({
  isClientUser,
  isDivisionEnabled,
}: {
  isClientUser: boolean;
  isDivisionEnabled: boolean;
}) =>
  [
    { title: 'Claim #', name: 'refid' },
    { title: 'Last Verification Date', name: 'ins_last_verified' },
    {
      title: 'DOS',
      name: 'sheduleservdate',
      className: 'text-nowrap',
      dataType: 'date',
    },
    { title: 'Last Name', name: 'last_name' },
    { title: 'First Name', name: 'first_name' },
    { title: 'Facility', name: 'facilitynm' },
    isDivisionEnabled ? { title: 'Division', name: 'division' } : null,
    { title: 'Modality', name: 'examtype' },
    { title: 'DOB', name: 'patientdob', className: 'text-nowrap text-center' },
    { title: 'SSN', name: 'ssn', className: 'text-nowrap text-center' },
    {
      title: 'Visit Info',
      name: 'visitInfo',
      className: 'text-center',
      render: (_: undefined, data: InsuranceType) => (
        <IconButton
          className="text-primary"
          onClick={() => storeInsuranceVerification.setInsurancePreview(data)}>
          <i className="bi bi-journal-medical" />
        </IconButton>
      ),
    },
    {
      title: 'Accession #',
      name: 'accessionNumbersStr',
    },
    { title: 'State', name: 'state' },
    { title: 'Location', name: 'location' },
    { title: 'POS', name: 'pos' },
    { title: 'Technologist', name: 'technologistnm' },
    { title: 'Assigned Time', name: 'orderassigned_date' },
    {
      title: 'CPT',
      name: 'cptdesc',
      className: 'text-center',
      render: (code: string) =>
        code ? (
          <div className="fs-5 text-primary">
            <Tooltip body={code}>
              <i className="bi bi-upc-scan" />
            </Tooltip>
          </div>
        ) : null,
    },
    {
      title: 'Diagnosis',
      name: 'icddesc',
      className: 'text-center',
      render: (info: string) =>
        info ? (
          <div className="fs-5 text-primary">
            <Tooltip body={info}>
              <i className="bi bi-clipboard-pulse" />
            </Tooltip>
          </div>
        ) : null,
    },
    isClientUser
      ? null
      : {
          name: 'encounterLog',
          title: 'Encounter Log',
          className: 'text-center',
          render: (_: undefined, { refid, hasLog }: InsuranceType) => (
            <IconButton
              className="text-primary"
              onClick={() => {
                storeInsuranceVerification.setIdForOrderMessage(refid);
              }}>
              <i
                className={`bi ${
                  hasLog ? 'bi-envelope-check-fill' : 'bi-envelope'
                }`}
              />
            </IconButton>
          ),
        },
    {
      title: 'Auditlog',
      name: 'Auditlog',
      className: 'text-center',
      render: (_: undefined, data: InsuranceType) => (
        <IconButton
          onClick={() => {
            storeInsuranceVerification.setAuditLogProps(data);
          }}>
          <i className="icon icon-auditlog" />
        </IconButton>
      ),
    },
  ].filter(Boolean);

export default columnsPrettier;
