import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    facility_price: yup.number().typeError('Please, enter the facility price!'),
    insurance_price: yup
      .number()
      .typeError('Please, enter the insurance price!'),
    bill_jurisdictionid: yup
      .number()
      .nullable()
      .positive()
      .required('Please, select a jurisdiction!'),
    stateid: yup
      .number()
      .nullable()
      .positive()
      .required('Please, select a State!'),
  })
);

export default validation;
