import { Container } from 'flux/utils';

import PieChart from 'components/project/common/charts/PieChart';
import AbstractReportPage from 'page/reports/report-creator/AbstractReportPage';
import { prettierFilterPayload } from './filter/prettierFilterPayload';

import ArAgingReportActions from 'actions/reports/ArAgingReportActions';
import ArAgingReportStore from 'stores/reports/ArAgingReportStore';
import { AR_AGING_REPORT as PAGE_ID } from 'constant/pagesId/reports';

const COLUMNS = [
  { name: 'studyid', title: 'Study ID' },
  { name: 'patientname', title: 'Patient Name' },
  { name: 'dob', title: 'DOB', className: 'text-nowrap' },
  { name: 'facility_name', title: 'Facility Name' },
  { name: 'mrn', title: 'Patient MRN' },
  { name: 'age', title: 'Age' },
  { name: 'state', title: 'State' },
  { name: 'corporate_name', title: 'Corporate' },
  { name: 'cpt', title: 'CPT' },
  { name: 'facility_npi', title: 'Facility Npi' },
];

export class ArAgingReport extends AbstractReportPage {
  static getStores() {
    return [ArAgingReportStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = AbstractReportPage.getPageState('ar_aging_report_grid');
      prevState.paginationStrategy = AbstractReportPage.LOGIC_PAGINATION;
    }

    return {
      ...prevState,
      appId: PAGE_ID.PAGE,
      dataSource: ArAgingReportStore.getState().dataSource,
      chartValues: ArAgingReportStore.getState().chart,
    };
  }

  getShownFilters() {
    return [
      'studyID',
      'patientFirstName',
      'patientLastName',
      'dob',
      'state',
      'mrn',
      'facility',
      'dos',
      'cpt',
      'corporate',
      'diagnosis',
      'facilityNPI',
      'age',
    ];
  }

  getTitle() {
    return 'Ar Aging';
  }

  getColumns() {
    return COLUMNS;
  }

  getGridID() {
    return 'ar_aging_report_grid';
  }

  getExportedFileName() {
    return 'ArAgingReport';
  }

  getClass() {
    return 'ar_aging_report';
  }

  renderChart() {
    return <PieChart data={this.state.chartValues} />;
  }

  loadListAction(conditionList, joint) {
    const payload = prettierFilterPayload(conditionList);

    return ArAgingReportActions.loadList(payload, joint);
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }

  getActions() {
    return ArAgingReportActions;
  }
}

export default Container.create(ArAgingReport);
