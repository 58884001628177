import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import FilterNameAndStatus, {
  FilterType,
} from 'page/components/FilterNameAndStatus';
import DialogConfirm from 'components/modal/dialogConfirm';
import { LayoutSideTitle } from 'components/layout';
import {
  Grid,
  ColDef,
  GridExtraControlPanel,
  ICellRendererParams,
} from 'components/gridModern';
import Badge from 'components/badge';
import useDebounce from 'components/hook/useDebounce';
import { IconButton } from 'components/button';
import { GridControlButton } from 'components/grid';

import {
  storeState,
  StateType,
} from 'stores/_mobx/systemSetup/masterSetting/state';
import { MS_STATE as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_STATE_ADD,
  URL_STATE,
} from 'constant/path/systemSetup';
import { GRID_ID_STATE } from 'constant/gridsId/systemSetup';

const defaultColDef = {
  sortable: true,
  editable: false,
  resizable: true,
  autoHeaderHeight: true,
  wrapHeaderText: true,
  showDisabledCheckboxes: true,
};

const columns: ColDef<StateType>[] = [
  {
    headerName: 'Checkbox',
    field: 'id',
    colId: 'checkbox',
    pinned: 'left' as 'left',
    width: 50,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    resizable: false,
  },
  { field: 'state', headerName: 'State', flex: 1 },
  {
    field: 'shortName',
    headerName: 'Short Name',
    headerClass: 'text-center',
    cellClass: 'text-center',
    width: 120,
  },
  {
    field: 'flag',
    headerName: 'Status',
    headerClass: 'text-center',
    width: 100,
    cellRenderer: ({ value }: ICellRendererParams<StateType, 'A' | 'I'>) => (
      <span className='text-center w-100 d-inline-block'>
        <Badge variant={value === 'A' ? 'success' : 'danger'}>
          {value === 'A' ? 'Active' : 'Inactive'}
        </Badge>
      </span>
    ),
  },
  {
    field: 'id',
    colId: 'action',
    headerName: 'Action',
    headerClass: 'text-center',
    width: 100,
    sortable: false,
    cellRenderer: ({ value, data }: ICellRendererParams<StateType, number>) => (
      <div className="controls">
        <Link
          to={`${URL_STATE}/${value}`}
          onClick={() => storeState.setState(data)}>
          <i className="bi bi-pencil" />
        </Link>
        <IconButton onClick={() => storeState.setStateForDelete(value)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const StateListPage = () => {
  const {
    fetching,
    statesList,
    statesTotal,
    stateIdForDelete,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeState;

  const [selected, setSelected] = useState<number[]>([]);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const fetchingData = () => {
    storeState.getStates();
    setSelected([]);
  };

  const handleApproveDelete = () => {
    storeState.deleteState().then((response) => {
      if (response) fetchingData();
    });
  };

  const handleChangeStatus = () => {
    storeState.changeStateStatus(selected).then((isSucceed) => {
      if (isSucceed) {
        setSelected([]);
        setPaginationToStart();
      }
    });
  };

  const handleChangeFilter = (newFilter: FilterType) => {
    filter.setOptions(newFilter);
    debouncedMethod();
  };

  useEffect(() => {
    fetchingData();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="State">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <FilterNameAndStatus
        placeholder="State Name"
        filter={filter.options}
        fetching={fetching}
        onChangeFilter={handleChangeFilter}
      />

      <GridExtraControlPanel>
        <GridControlButton title="Add" url={URL_STATE_ADD} />
        <GridControlButton
          title={filter.options.isActive ? 'Deactivate' : 'Activate'}
          disabled={!selected.length}
          onClick={handleChangeStatus}
        />
      </GridExtraControlPanel>
      <Grid
        selectByField="id"
        id={GRID_ID_STATE}
        defaultColDef={defaultColDef}
        columnDefs={columns}
        isLoading={fetching}
        totalCount={statesTotal}
        rowData={statesList}
        paginationSettings={pagination}
        onPaginationChange={setPagination}
        onSelectChange={setSelected}
      />

      {stateIdForDelete ? (
        <DialogConfirm
          onCancel={storeState.clearStateForDelete}
          onApprove={handleApproveDelete}>
          Do you really want to delete this State?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(StateListPage);
