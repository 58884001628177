import React from 'react';

import { Grid } from 'components/grid';
import TimeSheetPageActions from 'actions/profile/personal/TimeSheetPageActions';
import { TTrackingData } from 'services/profile/personal/TimeSheetPageService';
import MathUtils from 'utils/MathUtils';

export interface PTimeSheetDailyNotes {
  year: number;
  month: number;
  week: number;
}

export class TTimeSheetDailyNotes {
  date: string = '';
  displayInput: boolean = false;
  daily_notes: string = '';
  difference: string = '';
  clock_start: string = '';
  clock_end: string = '';
}

export class STimeSheetDailyNotes {
  dataSource: Array<TTimeSheetDailyNotes> = [];

  constructor(props: PTimeSheetDailyNotes) {
    this.dataSource = TimeSheetDailyNotes.generateDataSource(
      props.year,
      props.month,
      props.week
    );
  }
}

export default class TimeSheetDailyNotes extends React.Component<
  PTimeSheetDailyNotes,
  STimeSheetDailyNotes
> {
  constructor(props: PTimeSheetDailyNotes) {
    super(props);
    this.state = new STimeSheetDailyNotes(props);
  }

  static generateDataSource(year: number, month: number, week: number) {
    const calendar = TimeSheetPageActions.generateCalendar(year, month, week);
    const dataSource: Array<TTimeSheetDailyNotes> = [];
    let days = 7;
    while (days--) {
      const displayInput = calendar.getMonth() === month;
      const item = new TTimeSheetDailyNotes();
      item.date = TimeSheetPageActions.getAndIncrement(calendar);
      item.displayInput = displayInput;
      dataSource.push(item);
    }
    return dataSource;
  }

  columns = [
    { name: 'date', title: 'Date', className: 'width-100' },
    { name: 'daily_notes', title: 'Daily Notes' },
  ];

  setData(data: Array<TTrackingData>) {
    const dataSource = TimeSheetDailyNotes.generateDataSource(
      this.props.year,
      this.props.month,
      this.props.week
    );
    for (let j = 0; j < dataSource.length; j++) {
      const row = dataSource[j];
      for (let i = 0; i < data.length; i++) {
        const dataRow = data[i];
        if (data[i].date === row.date && dataRow.difference !== null) {
          const diffParts = dataRow.difference.split('h');
          const hours = MathUtils.parseInt(diffParts[0]);
          const minutes = MathUtils.parseInt(diffParts[1]);
          if (row.daily_notes) {
            row.daily_notes += ';';
          }
          let fixedDifference = hours > 0 ? hours + 'h' : '';
          if (fixedDifference) {
            fixedDifference +=
              minutes > 0
                ? diffParts[1] /*it's ok, minutes with leading zero*/
                : '';
          } else {
            fixedDifference +=
              minutes > 0
                ? minutes + 'm' /*it's ok, minutes without leading zero*/
                : '';
          }
          let fixedClockStart = dataRow.clock_start;
          let fixedClockEnd = dataRow.clock_end;
          row.daily_notes +=
            fixedClockStart +
            '-' +
            fixedClockEnd +
            (fixedDifference ? '(' + fixedDifference + ')' : '');
        }
      }
    }
    this.setState({ dataSource });
  }

  render() {
    return (
      <div className="daily-notes col-sm-6">
        <Grid
          id="daily_notes_grid"
          columns={this.columns}
          disablePagination
          wrapperClass="_"
          dataSource={this.state.dataSource}
          dataSourceCount={7}
          noControl
          stateless
        />
      </div>
    );
  }
}
