import React from 'react';
import { Container } from 'flux/utils';

import FacilityRolesDropdown from 'components/project/dropdown/FacilityRolesDropdown';
import TechnicianDropdown from 'components/project/dropdown/TechnicianDropdown';
import PlaceOfServiceDropdown from 'components/project/dropdown/PlaceOfServiceDropdown';
import StateDropdown from 'components/project/dropdown/StateDropdown';
import LocationDropdown from 'components/project/dropdown/LocationDropdown';
import ExamTypeDropdown from 'components/project/dropdown/ExamTypeDropdown';
import Text from 'components/form/input/Text';
import Form from 'components/form/Form';
import { DateRangeLegacy } from 'components/form/dateRange';
import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import { Button } from 'components/button';
import Export from './Export';

import SummaryTechnologistActions from 'actions/reports/SummaryTechnologistActions';
import SummaryTechnologistStore from 'stores/reports/SummaryTechnologistStore';
import { storeGrid } from 'stores/_mobx/grid';
import UserProfileStore from 'stores/UserProfileStore';
import { getPagination } from 'utils/getPagination';
import { calcDateRange } from 'utils/calcDateRange';
import { TECH_VOLUME as PAGE_ID } from 'constant/pagesId/reports';
import { getDateRangeBounds } from 'utils/DateUtils';

const COLUMNS = [
  { name: 'tech_name', title: 'Technologist' },
  {
    name: 'Jan_pat',
    title: 'Patient Count',
    group: 'Jan',
    groupName: 'Jan',
    shownCheckboxTitle: 'Patient Count (Jan)',
  },
  {
    name: 'Jan_pro',
    title: 'Procedure Count',
    group: 'Jan',
    groupName: 'Jan',
    shownCheckboxTitle: 'Procedure Count (Jan)',
  },
  {
    name: 'Feb_pat',
    title: 'Patient Count',
    group: 'Feb',
    groupName: 'Feb',
    shownCheckboxTitle: 'Patient Count (Feb)',
  },
  {
    name: 'Feb_pro',
    title: 'Procedure Count',
    group: 'Feb',
    groupName: 'Feb',
    shownCheckboxTitle: 'Procedure Count (Feb)',
  },
  {
    name: 'Mar_pat',
    title: 'Patient Count',
    group: 'Mar',
    groupName: 'Mar',
    shownCheckboxTitle: 'Patient Count (Mar)',
  },
  {
    name: 'Mar_pro',
    title: 'Procedure Count',
    group: 'Mar',
    groupName: 'Mar',
    shownCheckboxTitle: 'Procedure Count (Mar)',
  },
  {
    name: 'Apr_pat',
    title: 'Patient Count',
    group: 'Apr',
    groupName: 'Apr',
    shownCheckboxTitle: 'Patient Count (Apr)',
  },
  {
    name: 'Apr_pro',
    title: 'Procedure Count',
    group: 'Apr',
    groupName: 'Apr',
    shownCheckboxTitle: 'Procedure Count (Apr)',
  },
  {
    name: 'May_pat',
    title: 'Patient Count',
    group: 'May',
    groupName: 'May',
    shownCheckboxTitle: 'Patient Count (May)',
  },
  {
    name: 'May_pro',
    title: 'Procedure Count',
    group: 'May',
    groupName: 'May',
    shownCheckboxTitle: 'Procedure Count (May)',
  },
  {
    name: 'Jun_pat',
    title: 'Patient Count',
    group: 'Jun',
    groupName: 'Jun',
    shownCheckboxTitle: 'Patient Count (Jun)',
  },
  {
    name: 'Jun_pro',
    title: 'Procedure Count',
    group: 'Jun',
    groupName: 'Jun',
    shownCheckboxTitle: 'Procedure Count (Jun)',
  },
  {
    name: 'Jul_pat',
    title: 'Patient Count',
    group: 'Jul',
    groupName: 'Jul',
    shownCheckboxTitle: 'Patient Count (Jul)',
  },
  {
    name: 'Jul_pro',
    title: 'Procedure Count',
    group: 'Jul',
    groupName: 'Jul',
    shownCheckboxTitle: 'Procedure Count (Jul)',
  },
  {
    name: 'Aug_pat',
    title: 'Patient Count',
    group: 'Aug',
    groupName: 'Aug',
    shownCheckboxTitle: 'Patient Count (Aug)',
  },
  {
    name: 'Aug_pro',
    title: 'Procedure Count',
    group: 'Aug',
    groupName: 'Aug',
    shownCheckboxTitle: 'Procedure Count (Aug)',
  },
  {
    name: 'Sep_pat',
    title: 'Patient Count',
    group: 'Sep',
    groupName: 'Sep',
    shownCheckboxTitle: 'Patient Count (Sep)',
  },
  {
    name: 'Sep_pro',
    title: 'Procedure Count',
    group: 'Sep',
    groupName: 'Sep',
    shownCheckboxTitle: 'Procedure Count (Sep)',
  },
  {
    name: 'Oct_pat',
    title: 'Patient Count',
    group: 'Oct',
    groupName: 'Oct',
    shownCheckboxTitle: 'Patient Count (Oct)',
  },
  {
    name: 'Oct_pro',
    title: 'Procedure Count',
    group: 'Oct',
    groupName: 'Oct',
    shownCheckboxTitle: 'Procedure Count (Oct)',
  },
  {
    name: 'Nov_pat',
    title: 'Patient Count',
    group: 'Nov',
    groupName: 'Nov',
    shownCheckboxTitle: 'Patient Count (Nov)',
  },
  {
    name: 'Nov_pro',
    title: 'Procedure Count',
    group: 'Nov',
    groupName: 'Nov',
    shownCheckboxTitle: 'Procedure Count (Nov)',
  },
  {
    name: 'Dec_pat',
    title: 'Patient Count',
    group: 'Dec',
    groupName: 'Dec',
    shownCheckboxTitle: 'Patient Count (Dec)',
  },
  {
    name: 'Dec_pro',
    title: 'Procedure Count',
    group: 'Dec',
    groupName: 'Dec',
    shownCheckboxTitle: 'Procedure Count (Dec)',
  },
];

const defaultFilter = {
  ...calcDateRange('M'),
  period: 'M',
  patientFirstName: '',
  patientLastName: '',
  techId: 0,
  pos: 0,
  stateId: 0,
  locId: 0,
  examType: 0,
  facility: FacilityRolesDropdown.getFacilityId(),
};

const commonClassName = 'col-sm-3';

const prepareFilterToExport = (filter) => {
  const dos = getDateRangeBounds({
    from: filter.dosStart,
    to: filter.dosEnd,
  });

  return {
    ...filter,
    dosStart: dos.dateFrom,
    dosEnd: dos.dateTo,
  };
};

export class SummaryTechnologist extends React.Component {
  static getStores() {
    return [SummaryTechnologistStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = {
        onAjax: false,
        showFilter: true,
        filter: defaultFilter,
        pagination: getPagination(
          storeGrid.getPageSize('summary_technologist_gridId')
        ),
        flagExporterDialog: false,
        manualFilter: true,
      };
    }
    return {
      ...prevState,
      dataSource: SummaryTechnologistStore.getState().dataSource,
      dataSourceCount: SummaryTechnologistStore.getState().count,
    };
  }

  handleChangeFilter = (name, value) => {
    const filter = { ...this.state.filter, [name]: value };

    this.setState({ filter });
  };

  handleChangeDatePeriod = (filter) => {
    this.setState({ filter });
  };

  updateData = () => {
    const controller = new AbortController();

    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = controller;

    this.setState({ onAjax: true });

    SummaryTechnologistActions.loadData(
      this.state.filter,
      this.state.pagination,
      controller.signal
    ).then(this.updateDataCallback);
  };

  updateDataCallback = () => {
    this.abortController = null;

    this.setState({
      onAjax: false,
    });
  };

  resetFilter = () => {
    const pagination = { ...this.state.pagination, skip: 0, page: 1 };
    this.setState(
      {
        pagination,
        filter: defaultFilter,
        onAjax: true,
      },
      this.updateData
    );
  };

  resetPagination = () => {
    const pagination = { ...this.state.pagination, skip: 0, page: 1 };
    this.setState({ pagination }, this.updateData);
  };

  onPaginationChange = (pagination) => {
    this.setState({ pagination }, this.updateData);
  };

  handleClickExport = () => {
    this.setState({ flagExporterDialog: true });
  };

  getFilter() {
    const { filter } = this.state;

    return (
      <Form
        model={filter}
        id="sum_tech_f"
        onCollectValues={this.handleChangeFilter}
        submit={(m, he) => {
          if (!he) this.updateData();
        }}>
        <Text
          name="patientLastName"
          label="Patient Last Name"
          className={commonClassName}
        />
        <Text
          name="patientFirstName"
          label="Patient First Name"
          className={commonClassName}
        />
        <TechnicianDropdown
          name="techId"
          label="Technologist"
          className={commonClassName}
        />
        <FacilityRolesDropdown name="facility" className={commonClassName} />
        <div />
        <DateRangeLegacy
          nameFrom="dosStart"
          nameTo="dosEnd"
          nameRadio="period"
          onChange={this.handleChangeDatePeriod}
        />
        <PlaceOfServiceDropdown
          name="pos"
          label="POS"
          className={commonClassName}
        />
        <div />
        <StateDropdown
          name="stateId"
          label="State"
          className={commonClassName}
        />
        <LocationDropdown
          name="locId"
          label="Location"
          className={commonClassName}
        />
        <ExamTypeDropdown
          name="examType"
          label="Modality"
          optionsForOrderOnly
          className={commonClassName}
        />
      </Form>
    );
  }

  componentDidMount() {
    this.updateData();
  }

  render() {
    const { state } = this;
    const appName = UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) || null;

    return (
      <>
        <LayoutSideTitle appId={PAGE_ID.PAGE} title="Tech Volumes">
          <>
            <Button
              variant="default"
              text={state.showFilter ? 'Hide Filter' : 'Show Filter'}
              onClick={() =>
                this.setState((state) => ({ showFilter: !state.showFilter }))
              }
            />
            {state.showFilter && (
              <>
                <Button
                  text="Reset"
                  variant="warning"
                  disabled={state.onAjax}
                  onClick={this.resetFilter}
                />
                <Button
                  text="Apply"
                  disabled={state.onAjax}
                  onClick={this.resetPagination}
                />
              </>
            )}
          </>
        </LayoutSideTitle>

        {this.state.showFilter ? this.getFilter() : null}

        <Grid
          id="summary_technologist_gridId"
          noDataText="Please refine your search using the filters above"
          minColumns={1}
          gridControlPanel={
            <GridControlButton
              title="Export"
              onClick={this.handleClickExport}
            />
          }
          headerGroups
          columns={COLUMNS}
          ref="grid"
          onAjax={this.state.onAjax}
          dataSource={this.state.dataSource}
          dataSourceCount={this.state.dataSourceCount}
          pagination={this.state.pagination}
          onPaginationChange={this.onPaginationChange}
        />
        {state.flagExporterDialog ? (
          <Export
            callback={() => this.setState({ flagExporterDialog: false })}
            reportName={appName}
            exporterName="SummaryTechnologistExporter"
            filter={prepareFilterToExport(state.filter)}
            self={this}
            columns={COLUMNS}
          />
        ) : null}
      </>
    );
  }
}

export default Container.create(SummaryTechnologist);
