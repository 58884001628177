import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { InputCalendar } from 'components/form/inputCalendar';
import MaskInput from 'components/form/maskInput';
import { Input } from 'components/form/textField';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { Radio } from 'components/form/radio';
import RadioOptions from 'components/project/common/RadioOptions';
import { Button } from 'components/button';
import useDebounce from 'components/hook/useDebounce';
import { ControlsLayout } from 'components/layout';

import {
  filterDefaultValues,
  storePatientDemographic,
  FilterType,
} from 'stores/_mobx/patientRecord/patientDemographic';

const withoutDebounceFields = ['facilityId', 'birthday', 'gender', 'status'];

const genderOptions = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'All', value: '' },
];

interface PropsType {
  isVisible: boolean;
  fetching: boolean;
  onSubmit: (data: FilterType) => void;
}

const Filter = ({ isVisible, fetching, onSubmit }: PropsType) => {
  const { control, reset, watch } = useForm<FilterType>({
    defaultValues: storePatientDemographic.filter,
  });

  const debouncedMethod = useDebounce(onSubmit);

  const handleClickReset = () => {
    reset(filterDefaultValues);
    onSubmit(filterDefaultValues);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (withoutDebounceFields.includes(name)) {
        onSubmit(formValue as FilterType);
      } else {
        debouncedMethod(formValue);
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className={`row${isVisible ? '' : ' d-none'}`}>
      <Input
        name="lastName"
        label="Last Name"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <Input
        name="firstName"
        label="First Name"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <InputCalendar
        name="birthday"
        label="Date of Birth"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <MaskInput
        name="ssn"
        label="SSN"
        format="###-##-####"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <MaskInput
        name="phoneNumber"
        label="Facility Phone"
        format="###-###-####"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <DropDownFacility
        name="facilityId"
        label="Facility"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <Input
        name="mrn"
        label="MRN"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <Radio
        className="col-md-6 col-lg-3 part-inline"
        name="status"
        label="State"
        options={RadioOptions.active}
        control={control}
      />
      <Radio
        className="col-md-6 col-lg-auto part-inline"
        name="gender"
        label="Gender"
        options={genderOptions}
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          text="Reset"
          variant="warning"
          disabled={fetching}
          onClick={handleClickReset}
        />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
