import React from 'react';

import Tabs from 'components/tabs';

import EquipmentPageActions from 'actions/workflow/equipment/EquipmentPageActions';
import UserProfileStore from 'stores/UserProfileStore';
import {
  URL_EQUIPMENT_CHECK_IN,
  URL_EQUIPMENT_CHECKOUT,
  URL_EQUIPMENT_CHECKOUT_REQUEST_SERVICE,
} from 'constant/path/workflow';
import { SpinnerFixed } from 'components/spinner';

export default class EquipmentCheckOut extends React.Component {
  state = {
    onAjax: false,
    includeCheckIn: false,
  };

  includeCheckInTab() {
    const userType = UserProfileStore.getUserType();
    const userId = UserProfileStore.getTypedUserId();

    if (userType === 'T') {
      this.setState({ onAjax: true }, () => {
        EquipmentPageActions.GetViewEquipmentByTech(userId).then((response) => {
          this.setState({ includeCheckIn: response !== 'S', onAjax: false });
        });
      });
    }
  }

  componentDidMount() {
    this.includeCheckInTab();
  }

  render() {
    const { onAjax, includeCheckIn } = this.state;

    const tabList = [
      { label: 'Check Out', url: URL_EQUIPMENT_CHECKOUT },
      {
        label: 'Check In',
        url: URL_EQUIPMENT_CHECK_IN,
        disabled: onAjax || !includeCheckIn,
      },
      { label: 'Request Service', url: URL_EQUIPMENT_CHECKOUT_REQUEST_SERVICE },
    ];

    return (
      <div className="equipment-check-out-page pt-4">
        <Tabs tabsList={tabList} />

        {this.props.children}
        {onAjax && <SpinnerFixed />}
      </div>
    );
  }
}
