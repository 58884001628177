import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import {
  apiRequest,
  errorPrettier,
  ErrorType,
  JsonLdResponseType,
} from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import AbortControllerService from 'stores/_mobx/abortControllerService';

import { GRID_ID_CPT_CATEGORY } from 'constant/gridsId/systemSetup';

export const defaultFilterValues: FilterModel = {
  cptCategoryTitle: '',
  cptCategoryFlag: 'A',
};

type CategoryStatus = 'R' | 'A';

export interface FilterModel {
  cptCategoryTitle: string;
  cptCategoryFlag: CategoryStatus;
}

export const initialValues: CategoryType = {
  id: 0,
  title: '',
  flag: 'A',
};

export interface CategoryType {
  id: number;
  title: string;
  flag: CategoryStatus;
}

export interface OptionType {
  value: number;
  label: string;
}

class CPTCategory extends AbortControllerService {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  filter: FilterModel = defaultFilterValues;
  cptCategoryList: CategoryType[] = [];
  cptCategoryTotal: number = 0;
  cptCategory: CategoryType | undefined = undefined;
  cptCategoryForDelete: number | null = null;
  options: OptionType[] = [];

  page: Pagination = new Pagination({ id: GRID_ID_CPT_CATEGORY });

  constructor() {
    super();

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      filter: observable,
      cptCategoryList: observable,
      cptCategoryTotal: observable,
      cptCategory: observable,
      cptCategoryForDelete: observable,

      setFetching: action,
      setFetchingOption: action,
      setCptCategories: action,
      setCptCategory: action,
      setCptCategoryForDelete: action,
      setFilter: action,
      clearCptCategoryForDelete: action.bound,
      clearCptCategory: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOption(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setCptCategories({ items, totalItems }: JsonLdResponseType<CategoryType>) {
    this.cptCategoryList = items;
    this.cptCategoryTotal = totalItems;
  }

  setCptCategory(cptCategory?: CategoryType | null) {
    this.cptCategory = cptCategory;
  }

  setCptCategoryForDelete(id: number) {
    this.cptCategoryForDelete = id;
  }

  setFilter(filter: FilterModel) {
    this.filter = filter;
  }

  clearCptCategoryForDelete() {
    this.cptCategoryForDelete = null;
  }

  clearCptCategory = () => {
    this.cptCategory = undefined;
  };

  async getCptCategories() {
    const {
      filter,
      page: { pagination },
    } = this;

    const abortSignal = this.manageAbortController();

    this.setFetching(true);

    try {
      const data = {
        ...filter,
        page: pagination.page,
        itemsPerPage: pagination.pageSize,
      };

      const response = await apiRequest<JsonLdResponseType<CategoryType>>({
        url: 'cpt_categories',
        method: 'GET',
        contentType: 'ld',
        legacy: false,
        signal: abortSignal,
        data,
      });

      this.setCptCategories(response);
    } catch (e) {
      this.setCptCategories({
        items: [],
        totalItems: 0,
      });
    } finally {
      if (!abortSignal.aborted) {
        this.setFetching(false);

        this.clearAbortController();
      }
    }
  }

  async getCptCategory(id: number) {
    this.setFetching(true);
    try {
      const category = await apiRequest<CategoryType>({
        url: `cpt_categories/${id}`,
        method: 'GET',
        legacy: false,
      });

      this.setCptCategory(category);
    } catch (e: any) {
      Notification.danger("Requested CPT Category doesn't exist!");
      this.setCptCategory(null);
    } finally {
      this.setFetching(false);
    }
  }

  async modifyCptCategory({ id, ...data }: CategoryType) {
    const isUpdatingProcess = Boolean(id);
    try {
      await apiRequest<CategoryType>({
        url: isUpdatingProcess ? `cpt_categories/${id}` : 'cpt_categories',
        method: isUpdatingProcess ? 'PATCH' : 'POST',
        legacy: false,
        data,
      });

      Notification.success(
        `CPT Category was ${
          isUpdatingProcess ? 'updated' : 'created'
        } successfully!`
      );

      return null;
    } catch (e: any) {
      const errors = errorPrettier(e as ErrorType<CPTCategory>);

      if (!errors) Notification.danger('An error occurred!');

      return errors;
    }
  }

  async changeCategoryStatus(ids: number[]) {
    if (this.fetching) return false;

    this.setFetching(true);

    const flag = this.filter.cptCategoryFlag === 'A' ? 'R' : 'A';

    const list = ids.map((id) => ({ id, flag }));

    try {
      await apiRequest<CategoryType[]>({
        url: 'mass_actions',
        method: 'PATCH',
        legacy: false,
        data: {
          iri: '/cpt_categories',
          mapping: list,
        },
      });

      const statusMsg =
        this.filter.cptCategoryFlag === 'R' ? 'activated' : 'deactivated';

      Notification.success(
        `${ids.length} CPT Categor${
          ids.length > 1 ? 'ies were' : 'y was'
        } ${statusMsg}!`
      );
      return true;
    } catch (e) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async deleteCptCategory() {
    this.setFetching(true);
    try {
      const id = this.cptCategoryForDelete;

      await apiRequest({
        url: `cpt_categories/${id}`,
        method: 'DELETE',
        legacy: false,
      });

      Notification.success('CPT Category was deleted!');

      return true;
    } catch (e: any) {
      Notification.danger(
        "This CPT Category already in use. You can't delete this CPT Category!"
      );
      this.setFetching(false);
      return false;
    } finally {
      this.clearCptCategoryForDelete();
    }
  }

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOption(true);

    try {
      const options = await apiRequest<OptionType[]>({
        url: 'codemaster.CptCategory.getDropdown',
      });

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storeCptCategory = new CPTCategory();
