import { Controller, Control } from 'react-hook-form';

import PureCanvas, { PureCanvasProps } from './PureCanvas';

/**
  @prop {string} 'data-testid' - data attribute for testing libraries
  If data-testid not presented, then prefix for data-testid attribute will generated with 'name' property
  '{dataTestId}-label' - data attribute for the label
  '{dataTestId}-error' - data attribute for the error block
*/

export interface PropsType
  extends Omit<
    PureCanvasProps,
    | 'value'
    | 'name'
    | 'onChange'
    | 'onBlur'
    | 'onFocus'
    | 'errorMessage'
    | 'pattern'
  > {
  control: Control<any>;
  name: string;
}

const CanvasField = ({
  control,
  name,
  required = false,
  ...rest
}: PropsType) => (
  <Controller
    name={name}
    control={control}
    rules={{
      required,
    }}
    render={({ field, fieldState: { error } }) => {
      const errorMessage = error?.message || '';

      return (
        <PureCanvas
          errorMessage={errorMessage}
          required={required}
          {...rest}
          {...field}
        />
      );
    }}
  />
);

export default CanvasField;
