import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormTransport, { FormModel } from './components/Form';
import { URL_TRANSPORT } from 'constant/path/systemSetup';

import { storeTransport } from 'stores/_mobx/systemSetup/masterSetting/transport';

interface PropsType extends RouteComponentProps {}

const TransportAddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: FormModel) =>
    // @ts-ignore
    storeTransport.addTransportCode(payload).then((response) => {
      if (response) {
        return response;
      }
      history.push(URL_TRANSPORT);
    });

  return (
    <>
      <Title title="Add Transport" />
      <FormTransport backUrl={URL_TRANSPORT} onSubmit={handleSubmit} />
    </>
  );
};

export default TransportAddPage;
