import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import { DateRange } from 'components/form/dateRange';
import { DropDownApplicationUserType } from 'components/project/dropdown/ApplicationUsersDropdown';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import {
  FilterType,
  storeCurrentActivityLog,
} from 'stores/_mobx/users/currentActivityLog';

const OPTIONS_USER_TYPE = [
  { value: 1, label: 'Client User' },
  { value: 0, label: 'Company User' },
];

const fieldCn = 'col-md-6 col-lg-3';

interface PropsType {
  isVisible: boolean;
  fetching: boolean;
  onSubmit: (filter: FilterType) => void;
}

const Filter = ({ isVisible, fetching, onSubmit }: PropsType) => {
  const { control, handleSubmit, watch, reset } = useForm<FilterType>({
    defaultValues: storeCurrentActivityLog.filter,
  });

  const type = watch('userTypeToggler');

  const handleClickReset = () => {
    const defaultFilterValue = storeCurrentActivityLog.getDefaultFilter();

    reset(defaultFilterValue);
    onSubmit(defaultFilterValue);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'userTypeToggler') {
        reset(
          {
            ...formValue,
            userType: 0,
            facility: 0,
          },
          { keepDirty: true, keepTouched: true }
        );
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="lastName"
        label="Last Name"
        className={fieldCn}
        control={control}
      />
      <Input
        name="firstName"
        label="First Name"
        className={fieldCn}
        control={control}
      />
      <div />
      <Radio
        name="userTypeToggler"
        label="User Type"
        className="col-sm-12 col-md-4 part-inline"
        options={OPTIONS_USER_TYPE}
        control={control}
      />
      <DropDownApplicationUserType
        name="userType"
        className="col-sm-6 col-md-4"
        label="User Type"
        type={type}
        control={control}
      />
      <DropDownFacility
        name="facility"
        label="Facility"
        disabled={type !== 1}
        className="col-sm-6 col-md-4"
        control={control}
      />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-6 col-lg-auto"
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
