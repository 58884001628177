import React, { CSSProperties } from 'react';

interface PropsType {
  className?: string;
  direction?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
}

const FormControl = React.forwardRef<HTMLDivElement, PropsType>(
  ({ className = '', direction = 'right', style, children }, ref) => {
    const classes = `controls-wrapper ${direction} ${className}`;

    return (
      <div className={classes} style={style} ref={ref}>
        {children}
      </div>
    );
  }
);

export default FormControl;
