import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';

import { storeDocumentType } from 'stores/_mobx/systemSetup/masterSetting/documentType';
import { MS_DOCTYPE as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_IMPORT_ITEM_ADD,
  URL_IMPORT_ITEM_EDIT,
} from 'constant/path/systemSetup';

const columns = [
  { name: 'type_name', title: 'Document Type' },
  { name: 'shrt_code', title: 'Code' },
  { name: 'comments', title: 'Comments' },
  {
    name: 'refid',
    title: 'Action',
    className: 'width-75',
    render: (id: number) => (
      <div className="control">
        <Link
          className="bi bi-pencil"
          to={URL_IMPORT_ITEM_EDIT.replace(':id', `${id}`)}
        />
        <IconButton onClick={() => storeDocumentType.setDocumentForDelete(id)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const ImportItemListPage = () => {
  const {
    fetching,
    documentForDelete,
    documentTypesList,
    documentTypesTotal,
    filterName,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeDocumentType;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const fetchData = () => {
    storeDocumentType.getDocumentTypesListMain({
      pagination: pagination,
      name: filterName,
    });
  };

  const handleConfirmDelete = () => {
    storeDocumentType.deleteDocument().then((response) => {
      if (response) fetchData();
    });
  };

  const handleChangeFilter = (name: string) => {
    storeDocumentType.setFilterName(name);

    debouncedMethod();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Report Type">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Document Type"
        className="col-sm-12 col-md-6 col-lg-4"
        name="docType"
        value={filterName}
        onChange={handleChangeFilter}
      />

      <Grid
        id="importItemGrid"
        columns={columns}
        onAjax={fetching}
        dataSource={documentTypesList}
        dataSourceCount={documentTypesTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_IMPORT_ITEM_ADD} />
        }
      />
      {documentForDelete ? (
        <DialogConfirm
          onCancel={storeDocumentType.clearDocumentForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(ImportItemListPage);
