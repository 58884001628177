import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    npi: yup
      .string()
      .required('Please, fill in a NPI')
      .test({
        test: (npi: string) => npi.length >= 10,
        message: 'NPI have to contain 10 digits length',
      }),
  })
);

export default validation;
