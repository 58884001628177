import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import QuestionForm from './components/Form';

import {
  storeSecurityQuestion,
  FormModel,
} from 'stores/_mobx/systemSetup/masterSetting/securityQuestion';
import { URL_SECURITY_QUESTION } from 'constant/path/systemSetup';

interface MatchParam {
  id: string;
}
interface PropsType extends RouteComponentProps<MatchParam> {}

const SecurityQuestionEditPage = ({
  match: { params },
  history,
}: PropsType) => {
  const { fetching, question } = storeSecurityQuestion;

  const handleSubmit = (payload: FormModel) =>
    storeSecurityQuestion.updateQuestion(payload).then((error) => {
      if (error) return error;

      history.push(URL_SECURITY_QUESTION);
    });

  useEffect(() => {
    storeSecurityQuestion.getQuestion(Number(params.id));
  }, [params.id]);

  return (
    <>
      <Title title="Edit Security Question" />
      <QuestionForm
        defaultValues={question}
        backUrl={URL_SECURITY_QUESTION}
        onSubmit={handleSubmit}
      />

      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(SecurityQuestionEditPage);
