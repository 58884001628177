import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import EncounterMessageDialog from 'page/components/messages/EncounterMessageDialog';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import { GridControlButton } from 'components/grid';
import {
  Grid,
  GridExtraControlPanel,
  ICellRendererParams,
} from 'components/gridModern';
import { dateComparator } from 'components/gridModern/helpers';
import { Button, IconButton } from 'components/button';
import { LayoutSideTitle } from 'components/layout';
import Filter from './components/Filter';
import DialogClaimPreview from '../components/dialogClaimPreview';

import {
  storeClaimReview,
  ClaimType,
  BatchOfClaimsType,
  FilterClaimReviewType,
} from 'stores/_mobx/billingCodding/claimReview';
import { CLAIM_REVIEW as PAGE_ID } from 'constant/pagesId/billingCodding';
import {
  GRID_ID_CLAIM_REVIEW_RELEASE,
  GRID_ID_BATCHES_REVIEW_RELEASE,
} from 'constant/gridsId/billingCoding';

const batchColumns = [
  {
    headerName: 'Batch #',
    field: 'id',
    width: 100,
  },
  { field: 'batchedDate', headerName: 'Batch Date' },
  {
    field: '',
    colId: 'claimsStatistic',
    headerName: 'Charges/Encounter count',
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<BatchOfClaimsType>) =>
      `${data.claimCodesCount}/${data.claimsCount}`,
  },
];

const ClaimReview = ({ isDivisionEnabled }: PropsTypeWitDivisionSettings) => {
  const gridRef = useRef<any>(null);

  const {
    fetching,
    claimsList,
    claimsTotal,
    permission,
    filter,
    idForEncounterLog,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeClaimReview;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [selected, setSelected] = useState<string[]>([]);

  const isPreviewByClaims = filter.viewBy === 'claim';

  const [paramsForClaimPreview, setParams] = useState<{
    claimUniqId: number;
    claimType: 'T' | 'P';
    orderId: number;
    patientFullName: string;
  }>(null);

  const isControlButtonDisabled = !selected.length;

  const handleClickViewClaim = useCallback((data: ClaimType) => {
    setParams({
      orderId: data.order.id,
      claimUniqId: data.order.id,
      claimType: filter.claimType,
      patientFullName: `${data.order.patient.lastName} ${data.order.patient.firstName}`,
    });
  }, [filter.claimType]);

  const columnDefs = useMemo(
    () =>
      isPreviewByClaims
        ? [
            {
              headerName: 'Claim #',
              field: 'order.id',
              colId: 'claimNumber',
              pinned: 'left' as 'left',
              headerCheckboxSelection: true,
              checkboxSelection: true,
            },
            { field: 'order.patient.lastName', headerName: 'Last Name' },
            { field: 'order.patient.firstName', headerName: 'First Name' },
            {
              field: 'order.scheduleServiceDate',
              headerName: 'DOS',
              comparator: dateComparator,
            },
            {
              field: 'releaseDate',
              headerName: 'Release Date',
              comparator: dateComparator,
            },
            {
              field: 'reReleaseDate',
              headerName: 'Re-release Date',
              comparator: dateComparator,
            },
            {
              field: 'order.facility.facilityName',
              headerName: 'Facility',
              minWidth: 150,
              flex: 1,
            },
            isDivisionEnabled
              ? {
                  field: 'order.facility.division.shortName',
                  headerName: 'Division',
                  minWidth: 150,
                }
              : null,
            {
              field: 'order.facility.dispatchRegion.state.state',
              headerName: 'State',
            },
            { field: 'order.acknowledgement', headerName: 'Status' },
            {
              field: 'order.id',
              colId: 'encounterLog',
              headerName: 'Encounter Log',
              cellClass: 'text-center text-primary',
              headerClass: 'text-center',
              sortable: false,
              width: 120,
              cellRenderer: ({
                value,
              }: ICellRendererParams<ClaimType, number>) =>
                value ? (
                  <IconButton
                    onClick={() => {
                      storeClaimReview.setIdForEncounterLog(value);
                    }}>
                    <i className="bi bi-envelope" />
                  </IconButton>
                ) : null,
            },
            {
              field: '',
              colId: 'viewClaim',
              headerName: 'View Claim',
              cellClass: 'text-center text-success',
              headerClass: 'text-center',
              sortable: false,
              width: 100,
              cellRenderer: ({
                data,
              }: ICellRendererParams<ClaimType, number>) =>
                'order' in data ? (
                  <IconButton
                    onClick={() => handleClickViewClaim(data)}>
                    <i className="bi bi-pencil-square" />
                  </IconButton>
                ) : null,
            },
          ].filter(Boolean)
        : batchColumns,
    [isPreviewByClaims, isDivisionEnabled, handleClickViewClaim]
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleClickExport = () => {
    storeClaimReview.exportClaims(selected);
  };

  const handleClickRelease = () => {
    storeClaimReview
      .releaseClaims({
        ids: selected,
        type: filter.claimType,
        isFirstRelease: true,
      })
      .then((isSucceed) => {
        if (isSucceed) {
          setSelected([]);
          setPaginationToStart();
        }
      });
  };

  const handleClickReleaseAgain = () => {
    storeClaimReview
      .releaseClaims({
        ids: selected,
        type: filter.claimType,
        isFirstRelease: false,
      })
      .then((isSucceed) => {
        if (isSucceed) {
          setSelected([]);
          setPaginationToStart();
        }
      });
  };

  const handleClickExportNewClaims = () => {
    storeClaimReview.exportReleasedClaims(filter);
  };

  const handleCloseClaimPreview = () => {
    setParams(null);
  };

  const handleChangeFilter = (filter: FilterClaimReviewType) => {
    if (selected.length) {
      setSelected([]);
    }
    storeClaimReview.setFilter(filter);

    setPaginationToStart();
  };

  useEffect(() => {
    if (gridRef.current && !permission.encounterLog) {
      gridRef.current.columnApi?.setColumnVisible('encounterLog', false);
    }
  }, [permission.encounterLog, gridRef.current?.columnApi]);

  useEffect(() => {
    if (isPreviewByClaims) {
      storeClaimReview.getClaims();
    } else {
      storeClaimReview.getBatches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  useLayoutEffect(() => {
    storeClaimReview.checkPermission();

    return storeClaimReview.clearStore;
  }, []);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Release Batch">
        <Button
          variant="default"
          data-testid="toggleFilter"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        fetching={fetching}
        isFilterVisible={isFilterVisible}
        onSubmit={handleChangeFilter}
      />

      <GridExtraControlPanel>
        <GridControlButton
          title="Export"
          disabled={isControlButtonDisabled}
          onClick={handleClickExport}
        />
        {filter.releaseStatus === 'R' ? (
          permission.releaseAgain && (
            <GridControlButton
              title="Re-Release"
              disabled={isControlButtonDisabled}
              onClick={handleClickReleaseAgain}
            />
          )
        ) : (
          <GridControlButton
            title="Release"
            disabled={isControlButtonDisabled}
            onClick={handleClickRelease}
          />
        )}
        {filter.releaseStatus === 'R' ? (
          <GridControlButton
            title="Total AR"
            onClick={handleClickExportNewClaims}
          />
        ) : null}
      </GridExtraControlPanel>

      <Grid
        id={
          isPreviewByClaims
            ? GRID_ID_CLAIM_REVIEW_RELEASE
            : GRID_ID_BATCHES_REVIEW_RELEASE
        }
        // @ts-ignore
        selectByField="order.id"
        ref={gridRef}
        isLoading={fetching}
        // @ts-ignore
        rowData={claimsList}
        totalCount={claimsTotal}
        // @ts-ignore
        columnDefs={columnDefs}
        paginationSettings={pagination}
        onPaginationChange={setPagination}
        onSelectChange={setSelected}
      />

      {idForEncounterLog ? (
        <EncounterMessageDialog
          id={idForEncounterLog}
          onClose={storeClaimReview.clearIdForEncounterLog}
        />
      ) : null}

      {paramsForClaimPreview && (
        <DialogClaimPreview
          orderId={paramsForClaimPreview.orderId}
          claimId={paramsForClaimPreview.claimUniqId}
          claimType={paramsForClaimPreview.claimType}
          title={paramsForClaimPreview.patientFullName}
          onClose={handleCloseClaimPreview}
        />
      )}
    </>
  );
};

export default withDivisionSettings(observer(ClaimReview));
