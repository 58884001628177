import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

interface Props {
  className?: string;
  children?: React.ReactElement | React.ReactElement[];
  handleClose?: (data?: any) => void;
}
const modalRoot = document.getElementById('modals-container');

class ModalCreator extends Component<Props> {
  wrapperRef = React.createRef<HTMLDivElement>();

  componentDidMount(): void {
    this.wrapperRef.current?.focus();
  }

  componentWillUnmount(): void {
    const modalsCount = modalRoot.childElementCount;

    if (modalsCount > 1) {
      const prevModal = modalRoot.childNodes[modalsCount - 2];
      // @ts-ignore
      prevModal?.focus();
    }
  }

  keyboardListener = (e: any) => {
    const isEscPressed = e.keyCode === 27;
    if (isEscPressed && this.props.handleClose) {
      this.props.handleClose();
      e.stopPropagation();
    }
  };

  render() {
    return createPortal(
      <div
        tabIndex={1}
        ref={this.wrapperRef}
        className={clsx('modal show d-block', this.props.className)}
        onKeyDown={this.keyboardListener}>
        {this.props.children}
      </div>,
      modalRoot
    );
  }
}

export default ModalCreator;
