import AbstractLikeFilter from './AbstractLikeFilter';

export default class AdjustmentCodeFilter extends AbstractLikeFilter {
  name = 'adjustmentCode';
  field = 'Adjustment Code';
  field1 = AbstractLikeFilter.getInsecureSecureField(
    'ppxray_order_payment.adjustment_code'
  );
  className = 'adjustment-code-filter';
}
