import { useEffect, useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import DialogImport from 'page/system-setup/components/DialogImport';
import Title from 'components/project/common/title';
import { IconButton } from 'components/button';
import { Grid } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import Filter from './components/Filter';
import GridControl from './components/GridControl';
import { exampleColumns, exampleData } from './components/assets';

import {
  storeModifiers,
  FilterModel,
} from 'stores/_mobx/systemSetup/codeSet/modifiers';
import AccessUtils from 'utils/AccessUtils';
import { CODES_SET_MODIFIERS as PAGE_ID } from 'constant/pagesId/systemSetup';
import { URL_MODIFIER } from 'constant/path/systemSetup';

const renderAction = (id: number) => (
  <div className="control">
    {AccessUtils.get(
      PAGE_ID.EDIT,
      <Link className="bi bi-pencil" to={`${URL_MODIFIER}/edit/${id}`} />
    )}
    {AccessUtils.get(
      PAGE_ID.DELETE,
      <IconButton onClick={() => storeModifiers.setIdForDelete(id)}>
        <i className="bi bi-trash" />
      </IconButton>
    )}
  </div>
);

const columnsInactive = [
  { name: 'modifier_code', title: 'Modifier', className: 'width-100' },
  { name: 'modifier_desc', title: 'Description' },
];

const columnsActive = [
  ...columnsInactive,
  {
    name: 'refid',
    title: 'Actions',
    className: 'width-100',
    render: renderAction,
  },
];

const ModifiersListPage = () => {
  const {
    fetching,
    modifierList,
    modifierTotal,
    filter,
    idForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeModifiers;

  const gridRef = useRef(null);

  const [selected, setSelected] = useState<number[]>([]);

  const [isImportVisible, toggleImportDialog] = useState<boolean>(false);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleFilterChange = (filter: FilterModel) => {
    storeModifiers.setFilter(filter);

    debouncedMethod();
  };

  const fetchingData = () => {
    storeModifiers.getModifierMain();

    gridRef.current?.clearSelection();
  };

  const handleChangeStatus = useCallback(() => {
    storeModifiers.changeModifierStatus(selected).then((isSucceed) => {
      if (isSucceed) {
        fetchingData();
      }
    });
  }, [selected]);

  const handleToggleImport = useCallback(() => {
    toggleImportDialog((state) => !state);
  }, []);

  const handleApproveDelete = () => {
    storeModifiers.deleteModifier().then((isSucceed) => {
      if (isSucceed) fetchingData();
    });
  };

  const handleApproveImport = (file: File) => {
    storeModifiers.importModifiers(file).then((isSucceed) => {
      if (isSucceed) {
        toggleImportDialog(false);
        fetchingData();
      }
    });
  };

  useEffect(() => {
    fetchingData();
  }, [pagination]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Modifiers" />

      <Filter fetching={fetching} onFilterChange={handleFilterChange} />

      <Grid
        selectId="refid"
        id="code_set_modifiers"
        columns={filter.status === 'A' ? columnsActive : columnsInactive}
        onAjax={fetching}
        dataSource={modifierList}
        dataSourceCount={modifierTotal}
        onSelectChange={setSelected}
        gridControlPanel={
          <GridControl
            selected={selected}
            onChangeStatus={handleChangeStatus}
            onToggleImport={handleToggleImport}
          />
        }
        pagination={pagination}
        onPaginationChange={setPagination}
        ref={gridRef}
      />

      {idForDelete ? (
        <DialogConfirm
          onCancel={storeModifiers.clearIdForDelete}
          onApprove={handleApproveDelete}>
          Are you sure you want to delete this Modifier?
        </DialogConfirm>
      ) : null}

      {isImportVisible && (
        <DialogImport
          fileNameOfExample="samplemodifier.csv"
          columns={exampleColumns}
          exampleData={exampleData}
          onImport={handleApproveImport}
          onClose={handleToggleImport}
        />
      )}
    </>
  );
};

export default observer(ModifiersListPage);
