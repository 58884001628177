import React from 'react';

import Text from 'components/form/input/Text';
import Select from 'components/form/input/Select';
import Checkbox from 'components/form/input/Checkbox';

export function ERRORS(id: string, name: string, errorStorage: any) {
  return errorStorage && errorStorage[id] ? errorStorage[id][name] : null;
}

export interface PButton {
  row: { [key: string]: any };
  name: string;
  errors: string[];
  className?: string;
  me?: any;
  onClick?: () => void;
}

class Button extends React.Component<PButton, any> {
  render() {
    const { props } = this;
    const { row, name, errors, onClick } = props;
    const value = row[name];

    const errorsRender =
      !!errors && !!errors.length ? (
        <span className={'errors-wrapper'}>
          {errors.map((v, i) => (
            <span key={i}>{v}</span>
          ))}
        </span>
      ) : null;
    return (
      <div
        className={props.className}
        style={{
          color: '#337ab7',
          textDecorationLine: 'underline',
          cursor: 'pointer',
        }}
        onClick={
          onClick
            ? onClick
            : (event: any) => {
                if (event) {
                  event.preventDefault();
                }
                props.row.displayInput = true;
                props.me.forceUpdate();
              }
        }>
        {value}
        <i className="bi bi-pencil ms-3" />
        {errorsRender}
      </div>
    );
  }
}

const FormCells = {
  textInputFactory(
    name: string,
    validations: any,
    me: any,
    idName: string,
    errorsStorage: any
  ) {
    return (
      v: string,
      row: any,
      props?: {
        onClick?: () => void;
        autoFocus?: boolean;
        onBlur?: () => void;
        onKeyPress?: (event: React.KeyboardEvent) => void;
      }
    ) => {
      if (row.displayInput) {
        return (
          <Text
            autoFocus={(props && props.autoFocus) || undefined}
            onBlur={(props && props.onBlur) || undefined}
            onKeyPress={(props && props.onKeyPress) || undefined}
            value={row[name]}
            name={name}
            errors={ERRORS(row[idName], name, errorsStorage)}
            onSetValue={(name: string, value: string, errors: any) => {
              me.updateTableCell(row[idName], name, value, errors);
            }}
            noLabel
            validations={validations}
          />
        );
      }
      return (
        <Button
          onClick={(props && props.onClick) || undefined}
          row={row}
          name={name}
          me={me}
          errors={ERRORS(row[idName], name, errorsStorage)}
        />
      );
    };
  },

  dropDownFactory(
    name: string,
    validations: any,
    me: any,
    idName: string,
    options: any,
    errorsStorage: any,
    attr: any
  ) {
    if (!attr) attr = {};
    return (v: string, row: any) => {
      if (row.displayInput) {
        return (
          <Select
            {...attr}
            options={options}
            value={row[name]}
            name={name}
            noLabel
            onSetValue={(name: string, value: string, errors: any) => {
              me.updateTableCell(row[idName], name, value, errors);
            }}
            validations={validations}
            errors={ERRORS(row[idName], name, errorsStorage)}
          />
        );
      } else {
        return (
          <Button
            row={row}
            name={name}
            me={me}
            errors={ERRORS(row[idName], name, errorsStorage)}
          />
        );
      }
    };
  },

  checkBoxFactory(
    name: string,
    validations: any,
    me: any,
    idName: string,
    errorsStorage: any
  ) {
    return (v: string, row: any) => {
      return (
        <Checkbox
          value={row[name]}
          name={name}
          noLabel
          onSetValue={(name: string, value: string, errors: any) => {
            me.updateTableCell(row[idName], name, value, errors);
          }}
          validations={validations}
          errors={ERRORS(row[idName], name, errorsStorage)}
        />
      );
    };
  },
};

export default FormCells;
