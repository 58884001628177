import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { DropDownApplicationUserType } from 'components/project/dropdown/ApplicationUsersDropdown';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { TextEditor } from 'components/form/richTextEditor';
import { Input } from 'components/form/textField';
import DescriptionsList from './DescriptionsList';
import resolver from './validation';

const initialValues: FormModel = {
  template_name: '',
  subject: '',
  userTypes: null,
  body: '',
};

export interface FormModel {
  template_name: string;
  subject: string;
  userTypes: number[] | null;
  body: string;
  refid?: number;
}

interface PropsType {
  defaultValues?: FormModel;
  descriptions?: string[];
  backUrl: string;
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormEmailTemplate = ({
  defaultValues = initialValues,
  backUrl,
  descriptions,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm({ defaultValues, resolver });

  const quillRef = useRef(null);

  const cn = `add-email-template-form col-sm-9${
    isSubmitting ? ' on-ajax' : ''
  }`;

  const handleClickDescription = (template: string) => {
    const range = quillRef.current?.getEditor().getSelection(true);
    if (range) {
      const formattedTemplate = `${range.index ? ' ' : ''}${template} `;
      quillRef.current?.getEditor().insertText(range, formattedTemplate);
    }
  };

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: FormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('template_name', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  return (
    <div className="row">
      <form className={cn} onSubmit={handleClickSubmit}>
        <Input
          name="template_name"
          label="Template Name"
          required
          control={control}
        />
        <DropDownApplicationUserType
          name="userTypes"
          label="User Types"
          isMulti
          control={control}
        />
        <Input name="subject" label="Subject" required control={control} />
        <TextEditor name="body" ref={quillRef} control={control} />

        <ControlsLayout alignX="right">
          <Link to={backUrl} className="btn btn-danger">
            Back
          </Link>
          <Button
            variant="warning"
            text="Reset"
            disabled={isSubmitting}
            onClick={handleReset}
          />
          <Button
            type="submit"
            text="Submit"
            disabled={!isDirty || isSubmitting}
          />
        </ControlsLayout>
      </form>
      <DescriptionsList
        descriptions={descriptions}
        onClick={handleClickDescription}
      />
    </div>
  );
};

export default FormEmailTemplate;
