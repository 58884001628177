import {
  makeObservable,
  observable,
  action,
  toJS,
  onBecomeObserved,
} from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { exporterInterface, ExporterInterface } from 'stores/_mobx/exporter';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';
import createLinkElement from 'utils/createLinkElement';
import { isQrCpt } from 'utils/cptCode';
import {
  ProviderType,
  PlaceOfServiceType,
  ModalityType,
  ClaimCodeType,
  CorporateGroupType,
} from './types';

import { GRID_ID_EXAM_QA } from 'constant/gridsId/patientRecords';
import { BASE_URL_FILE_DIR } from 'constant/config';

const columnsIdToAddDuration = [
  'encounterDuration',
  'examCompletionDuration',
  'readDuration',
];

const formatPayload = ({ dosStart, dosEnd, ...rest }: FilterType) => {
  const dos = getDateRangeBounds({
    from: dosStart,
    to: dosEnd,
  });

  const filter = {
    ...rest,
    'scheduleServiceDateFrom': dos.dateFrom,
    'scheduleServiceDateTo': dos.dateTo,
  };

  return filter;
};

export const filterDefaultValues: FilterType = {
  patient: {
    firstName: '',
    lastName: '',
  },
  facility: {
    id: 0,
    facilityType: { id: 0 },
    placeOfService: { id: 0 },
    corporateGroupMembers: {
      flag: '',
      group: { id: 0 },
    },
    dispatchRegion: { state: { id: 0 } },
  },
  modality: { id: 0 },
  claimCodes: { procedureCode: '' },
  details: { orderBased: '' },
  orderFlow: { technologist: { id: 0 } },
  radiologyGroup: { id: 0 },
  priority: { id: 0 },
  finding: '',
  dosStart: '',
  dosEnd: '',
  period: 'A',
};

export interface FilterType {
  patient: {
    firstName: string;
    lastName: string;
  };
  facility: {
    id: number;
    facilityType: { id: number };
    placeOfService: { id: number };
    corporateGroupMembers: CorporateGroupType;
    dispatchRegion: { state: { id: number } };
  };
  orderFlow: { technologist: { id: number } };
  details: { orderBased: string };
  modality: { id: number };
  claimCodes: { procedureCode: string };
  finding: string;
  priority: { id: number };
  radiologyGroup: { id: number };
  dosStart: string;
  dosEnd: string;
  period: string;
}

export interface ExamQaType {
  acknowledgementTimeView?: string;
  id: number;
  claimCodesActive: ClaimCodeType[];
  createdAt: string;
  createdBy: {
    id: number;
    fullName: string;
  };
  encounterDuration?: string;
  facility: {
    id: number;
    facilityName: string;
    corporateGroupMembers?: {
      id: number;
      group: {
        id: number;
        groupName: string;
      };
    };
    placeOfService?: PlaceOfServiceType;
    dispatchRegion: {
      id: number;
      state: {
        id: number;
        state: string;
      };
    };
  };
  resultsSentDate: string;
  findingView: string;
  modality?: ModalityType;
  examCompletionDuration?: string | null;
  movedToReportManager?: string;
  numberOfPatient: number;
  details?: {
    id: number;
    orderBasedView: string;
  };
  orderFlow: {
    id: number;
    orderAssignedDate: string;
    technologist?: {
      id: number;
      lastName: string;
      firstName: string;
    };
  };
  patient: {
    id: number;
    firstName: string;
    lastName: string;
    patientMrn: string;
    patientMrnFormatted: string;
    clientMrn: string;
  };
  priority?: {
    id: number;
    priorityDescription: string;
    priorityName: string;
  };
  provider?: ProviderType;
  radiologyGroup?: {
    id: number;
    radiologyGroupName: string;
  };
  resultsAvailableDate: string;
  scheduleServiceDate: string;
  techVisitInfo?: {
    id: number;
    visit: string;
    arrivalDateTime: string;
  };
  readDuration?: string;
  transportationCode?: string;
  claimProceduresCodes: string;
}

class ExamQA {
  isCorporateUser: boolean | null = null;
  fetching: boolean = false;
  qaList: ExamQaType[] = [];
  qaTotal: number = 0;
  isGenerating: boolean = false;
  filter: FilterType = filterDefaultValues;
  gridId = GRID_ID_EXAM_QA;

  page = new Pagination({ id: GRID_ID_EXAM_QA });
  exporterInterface: ExporterInterface = null;

  constructor(exporterInterface: ExporterInterface) {
    makeObservable(this, {
      fetching: observable,
      qaList: observable,
      qaTotal: observable,
      filter: observable,

      setDefaultFilter: action.bound,
      setFetching: action,
      setFilter: action,
      setExamQaData: action,
    });

    this.exporterInterface = exporterInterface;

    onBecomeObserved(this, 'filter', this.setDefaultFilter);
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setExamQaData(props: { qaList: ExamQaType[]; qaTotal: number }) {
    this.qaList = props.qaList;
    this.qaTotal = props.qaTotal;
  }

  setDefaultFilter() {
    const userRole =
      UserProfileStore.getUser().usertypename.toLocaleLowerCase();

    const isCorporateUser = userRole.includes('corporate');

    if (isCorporateUser) {
      const corporateId = Number(UserProfileStore.getUser().corporate_id) || 1;

      const filter = toJS(this.filter);

      filter.facility.corporateGroupMembers.group.id = corporateId;
      filter.facility.corporateGroupMembers.flag = 'A';
      this.filter = filter;
    }

    this.isCorporateUser = isCorporateUser;
  }

  getDefaultFilter() {
    if (this.isCorporateUser) {
      const corporateId = Number(UserProfileStore.getUser().corporate_id) || 1;

      const filter = JSON.parse(JSON.stringify(filterDefaultValues));

      filter.facility.corporateGroupMembers.group.id = corporateId;
      filter.facility.corporateGroupMembers.flag = 'A';
      return filter;
    }
    return filterDefaultValues;
  }

  async getExamQaList(sorting?: Record<string, 'asc' | 'desc'>) {
    const {
      page: { pagination },
    } = this;

    this.setFetching(true);

    const filter = formatPayload(this.filter);

    const data = {
      ...filter,
      ...sorting,
      page: pagination.page,
      itemsPerPage: pagination.pageSize,
    };

    try {
      const { items, totalItems } = await apiRequest<{
        items: ExamQaType[];
        totalItems: number;
      }>({
        url: 'patient/exam-qa',
        method: 'GET',
        contentType: 'ld',
        legacy: false,
        data,
      });

      const qaList = items.map((order) => ({
        ...order,
        claimCodesActive: order.claimCodesActive.filter(
          (claim) => !isQrCpt(claim.procedureCode)
        ),
        scheduleServiceDate: dateToLocalTimezone({
          date: order.scheduleServiceDate,
          dateOnly: true,
        }),
      }));
      this.setExamQaData({ qaList, qaTotal: totalItems });
    } catch (error) {
      this.setExamQaData({ qaList: [], qaTotal: 0 });
    } finally {
      this.setFetching(false);
    }
  }

  async generatePDF(orderId: number) {
    if (this.isGenerating) return false;

    this.isGenerating = true;

    try {
      const fileName = await apiRequest<string>({
        url: 'patient.PatientInfo.generatePatientExamDetail',
        data: { orderId },
      });

      const link = createLinkElement(
        `${BASE_URL_FILE_DIR}export-files/patient/order/${fileName}`
      );
      link.setAttribute('target', '_blank');
      document.body.appendChild(link); // Required for FF

      link.click();
      link.remove();
    } catch (e) {
      Notification.warning('PDF export failed!');
    } finally {
      this.isGenerating = false;
    }
  }

  runExportCsv = (gridColumns: any[]) => {
    const filter = formatPayload(this.filter);

    const columns = gridColumns
      .filter((column) => column.colId !== 'patientExamDetail')
      .map((el) =>
        columnsIdToAddDuration.includes(el.field)
          ? {
              ...el,
              field: `${el.field}Formatted`,
              headerName: `${el.headerName} (minutes)`,
            }
          : el
      );

    this.exporterInterface.generateReport({
      columns,
      instance: '/patient/exam-qa',
      source: 'PatientExamQA',
      fileExtension: 'csv',
      filter,
    });
  };
}

export const storeExamQA = new ExamQA(exporterInterface);
