import { apiRequest } from 'services/RequestService';

class TechPatientPageService {
  loadTechPatientList(conditionList, joint) {
    return apiRequest({
      url: 'report.ReportMaster.GetstudiesbytechReport',
      data: [conditionList, joint],
    }).then((response) => {
      if (response && response.chartval) {
        var newChartValues = [];
        newChartValues.push(['Technician', 'Patient count']);
        let chartValues = response.chartval;
        chartValues.forEach((item) => {
          newChartValues.push([item.ptname, parseInt(item.techcount)]);
        });
        response.chartval = newChartValues;
        return response;
      } else {
        return {};
      }
    });
  }

  createPdf(techPatientList, formatDescription) {
    return apiRequest({
      url: 'pdfcreater.OrderPdfGenerator.GetStudiesbyTechPDF',
      data: [techPatientList, formatDescription],
    });
  }
}

export default new TechPatientPageService();
