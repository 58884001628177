import ClaimsService, {
  TClaimManagerFilter,
} from 'services/billing-coding/claims/ClaimsService';
import appDispatcher from 'dispatcher/AppDispatcher';
import { TPagination } from 'components/grid/GridTypes';
import { getDateRangeBounds } from 'utils/DateUtils';

export class TClaimManagerFilterExt extends TClaimManagerFilter {
  claimType: string = '';
  billProcess: string = '';
}

export default class ClaimsAuditActions {
  static async loadOverview(
    filterPayload: TClaimManagerFilterExt,
    pagination: TPagination,
    isDivisionEnabled: boolean
  ) {
    const dos = getDateRangeBounds({
      from: filterPayload.dosStart,
      to: filterPayload.dosEnd,
    });

    const filter = {
      ...filterPayload,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
      division: isDivisionEnabled ? filterPayload.division : 0,
    };

    const promiseCount = ClaimsService.loadOverviewCount(
      filter,
      'A',
      'U',
      ClaimsAuditActions.billProcess(filter),
      filter.claimType,
      'CA',
      '',
      '',
      ''
    );

    const promiseList = ClaimsService.loadOverview(
      filter,
      pagination,
      'A',
      'U',
      ClaimsAuditActions.billProcess(filter),
      filter.claimType,
      'CA',
      '',
      '',
      ''
    );

    const [count, list] = await Promise.all([promiseCount, promiseList]);

    const response = !list || list === 'SE' ? [] : list;

    appDispatcher.dispatch({
      type: 'claim-audit-overview',
      data: response,
      count: count,
    } as any);

    return response;
  }

  static billProcess(filter: TClaimManagerFilterExt) {
    if (filter.claimType === 'P') {
      return filter.billProcess;
    }
    return '';
  }

  static moveTcPc(claimUniqIds: Array<string>, flag: 'T' | 'P' | '') {
    return ClaimsService.moveTcPc(claimUniqIds, flag);
  }
}
