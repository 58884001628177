import { useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Grid, GridControlButton } from 'components/grid';
import Title from 'components/project/common/title';
import { PureInput } from 'components/form/textField';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';

import { storeTrash } from 'stores/_mobx/message/trash';
import { TRASH as PAGE_ID } from 'constant/pagesId/messages';
import { URL_TRASH } from 'constant/path/messages';

const COLUMNS = [
  { name: 'to', title: 'To' },
  { name: 'subject', title: 'Subject' },
  { name: 'date', title: 'Date', className: 'width-100' },
  { name: 'time', title: 'Time', className: 'width-75' },
  {
    name: 'id',
    title: 'Action',
    render: (id: number) =>
      id ? (
        <div className="control">
          <Link
            to={`${URL_TRASH}/${id}`}
            className="icon icon-view"
            title="View"
          />
        </div>
      ) : null,
  },
];

interface PropsType extends RouteComponentProps {}

const TrashListPage = ({ history }: PropsType) => {
  const {
    fetching,
    trashList,
    trashesCount,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeTrash;

  const gridRef = useRef(null);

  const [selected, setSelected] = useState<number[]>([]);

  const [showDeleteConfirmation, toggleConfirmation] = useState<boolean>(false);

  const debouncedAction = useDebounce(setPaginationToStart);

  const handleSetSearch = (search: string) => {
    storeTrash.setFilter(search);
    debouncedAction();
  };

  const handleToggleConfirmation = () => {
    toggleConfirmation((state) => !state);
  };

  const handleDeleteMail = () => {
    storeTrash.clearTrash(selected).then((isSucceed) => {
      if (isSucceed) {
        gridRef.current.clearSelection();
        setSelected([]);
        storeTrash.getTrashListMain();
        toggleConfirmation(false);
      }
    });
  };

  const handleDoubleClick = ({ id }: { id: number }) => {
    history.push(`${URL_TRASH}/${id}`);
  };

  useEffect(() => {
    storeTrash.getTrashListMain();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Trash" />

      <div className="row">
        <PureInput
          name="subject"
          placeholder="Subject"
          className="col-md-6 col-lg-4"
          value={filter}
          onChange={handleSetSearch}
        />
      </div>

      <Grid
        id="trash_page_grid"
        moveToTrashText="Delete"
        selectId="id"
        ref={gridRef}
        onAjax={fetching}
        columns={COLUMNS}
        dataSource={trashList}
        dataSourceCount={trashesCount}
        pagination={pagination}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        onDoubleClick={handleDoubleClick}
        gridControlPanel={
          <GridControlButton
            title="Delete"
            onClick={handleToggleConfirmation}
            disabled={!selected.length}
          />
        }
      />
      {showDeleteConfirmation && (
        <DialogConfirm
          onCancel={handleToggleConfirmation}
          onApprove={handleDeleteMail}>
          Are you sure you want to permanently delete the selected message(s)?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(TrashListPage);
