import OrderFormPageService, {
  TPhysician,
} from 'services/workflow/order/OrderFormPageService';
import appDispatcher from 'dispatcher/AppDispatcher';
import { apiRequest } from 'services/RequestService';
import DropdownActions, {
  TDropDownItem,
} from 'actions/project/DropdownActions';

export default class PhysicianActions extends DropdownActions {
  static loadPhysicians(): Promise<Array<TDropDownItem>> {
    return OrderFormPageService.loadPhysicianList({
      firstName: '',
      lastName: '',
      npi: '',
    }).then((response: Array<TPhysician>) => {
      const res: Array<TDropDownItem> = [];
      for (let i = 0; i < response.length; i++) {
        const item: TDropDownItem = new TDropDownItem();
        item.value = parseInt('' + response[i].data);
        item.label = response[i].first_name + ' ' + response[i].last_name;
        res.push(item);
      }
      appDispatcher.dispatch({
        type: 'physician-dropdown',
        data: res,
      });
      return res;
    });
  }

  static loadPhysicianAdmins(): Promise<Array<TDropDownItem>> {
    return this.loadDropdown(
      'usermaster.ProviderInfo.loadPhysicianAdministrators',
      false,
      [true]
    );
  }

  static checkNpi(npiArray: Array<string>) {
    return apiRequest<Array<{ refid: number; npi: string }>>({
      url: 'usermaster.ProviderInfo.checkNpi',
      data: [npiArray],
    });
  }

  static loadPhysiciansForGroup(
    groupId: number
  ): Promise<Array<TDropDownItem>> {
    return apiRequest<Array<TDropDownItem>>({
      url: 'order.Order.GetPhysiciansByGroup',
      data: [0, groupId],
    }).then((r) => {
      if (r && r.forEach) {
        r.forEach((v) => {
          v.value = parseInt('' + v.data);
        });
      }
      return r;
    });
  }
}
