import { action, makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionResponseType {
  data: string;
  label: string;
  short_name: string;
}

interface OptionUsaResponseType {
  data: number;
  label: string;
  short_name: string;
  value: string;
}

interface RawOptionType {
  value: number;
  label: string;
  shortName: string;
}

export interface OptionType {
  value: number;
  label: string;
}

export interface OptionShortNameType {
  label: string;
  value: string;
}

export class DropdownState {
  fetching: boolean = false;
  fetchingUsaOption: boolean = false;
  rawOptions: RawOptionType[] = [];
  options: OptionType[] = [];
  optionsShortName: OptionShortNameType[] = [];
  optionsUsa: OptionType[] = [];
  optionsUsaShortName: OptionShortNameType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingUsaOption: observable,
      rawOptions: observable,
      options: observable,
      optionsShortName: observable,
      optionsUsa: observable,
      optionsUsaShortName: observable,

      setFetching: action,
      setFetchingUsaOption: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingUsaOption(fetching: boolean) {
    this.fetchingUsaOption = fetching;
  }

  async getOptions() {
    if (this.fetching) return Promise.resolve();

    this.setFetching(true);

    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.MvLocation.GetALLStateDropDown',
      });

      const { options, optionsShortName, rawOptions } = response.reduce(
        (prev, { data, label, short_name }) => {
          const value = Number(data);

          const options = prev.options.concat({ label, value });

          const optionsShortName = prev.optionsShortName.concat({
            label,
            value: short_name,
          });

          const rawOptions = prev.rawOptions.concat({
            label,
            shortName: short_name,
            value,
          });

          return {
            rawOptions,
            options,
            optionsShortName,
          };
        },
        { options: [], optionsShortName: [], rawOptions: [] }
      );

      runInAction(() => {
        this.fetching = false;
        this.options = options;
        this.optionsShortName = optionsShortName;
        this.rawOptions = rawOptions;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
        this.optionsShortName = [];
      });
    }
  }

  async getOptionsUsa() {
    if (this.fetching || this.optionsUsa.length) return Promise.resolve();

    this.setFetchingUsaOption(true);

    try {
      const response = await apiRequest<OptionUsaResponseType[]>({
        url: 'generalmaster.MvLocation.usaStateDropDown',
      });

      const { optionsUsa, optionsUsaShortName } = response.reduce(
        (prev, { data, label, short_name }) => {
          const optionsUsa = prev.optionsUsa.concat({
            label,
            value: Number(data),
          });

          const optionsUsaShortName = prev.optionsUsaShortName.concat({
            label,
            value: short_name,
          });

          return {
            optionsUsa,
            optionsUsaShortName,
          };
        },
        { optionsUsa: [], optionsUsaShortName: [] }
      );

      runInAction(() => {
        this.fetchingUsaOption = false;
        this.optionsUsa = optionsUsa;
        this.optionsUsaShortName = optionsUsaShortName;
      });
    } catch {
      runInAction(() => {
        this.fetchingUsaOption = false;
        this.optionsUsa = [];
        this.optionsUsaShortName = [];
      });
    }
  }

  findById = (id: number) => this.rawOptions.find(({ value }) => value === id);

  findByShortName = (shortName: string) =>
    this.rawOptions.find((state) => state.shortName === shortName);
}

export const storeDropdownState = new DropdownState();
