import { TProfile } from 'stores/record/DataObjects';
import MenuRecord from 'stores/record/MenuRecord';
import { TUserTypeEnum } from 'services/project/UserSelectionService';

export class TCompanyUser {
  compliance: boolean = false;
}

export class TAdminInfo {
  company_users: Array<TCompanyUser> = [];
  //
  company_name: string = '';
  billingaddress: string = '';
  billingcity: string = '';
  billingstate: string = '';
  billingzip: string = '';
  billingphone: string = '';
  billingfax: string = '';
  phone: string = '';
  logo_internalpage: string = '';
}

interface ChildMenu {
  app_id: string;
  app_name: string;
  group_name: string;
  img_url?: string;
  url: string;
}
export interface TopMenuItem {
  app_name: string;
  data: string;
  icon_class: string;
  url: string[];
}

export interface TopMenuItemExtends extends TopMenuItem {
  defaultUrl: string;
}

export interface SidebarItem {
  parentName: string;
  urls: string[];
  group:
    | null
    | {
        label: string;
        menu_item: ChildMenu[];
      }[];
  children: null | ChildMenu[];
}

export default class UserProfileRecord {
  refid: string = undefined; // TODO: number
  user_id: number = undefined;
  user_name: string = undefined;
  user_email: string = '';
  username: string = undefined;
  usertype: TUserTypeEnum = undefined;
  usertype_id: number = undefined;
  usertypename: string = undefined;
  status: string = undefined;
  IPAddress: string = undefined;
  corporate_id: string = undefined;
  facility_name: string = undefined;
  facility_id: string = undefined; // TODO: number
  global_user: string = undefined;
  ipin: string = undefined;
  last_login: string = undefined;
  last_logout: string = undefined;
  location_id: string = undefined;
  name: string = undefined;
  sec_ques1: string = undefined;
  sec_ques2: string = undefined;
  serviceuser_privilege: string = undefined;
  type: string = undefined;
  view_status: string = undefined;
  menu: MenuRecord = new MenuRecord();
  user_pass: string = undefined;
  staterefid: number = undefined;
  state: string = undefined;
  pos: number = undefined;
  posrefid: number = undefined;
  regionrefid: number = undefined;
  region: string = undefined;
  location?: string = '';
  tech_privilege: string = undefined;
  recordsAccess: 'A' | 'M' = undefined;

  app_sn: string = '';
  locationrefid: any = null;
  supervisedGroup: any = null;
  admininfo?: Array<TAdminInfo> = [];
  topMenu: TopMenuItemExtends[];
  sidebarMenu: SidebarItem[];
  userProfileLink: string = '';

  constructor(profile?: TProfile) {
    this.refid = undefined;
    this.user_id = undefined;
    this.user_name = undefined;
    this.user_email = '';
    this.username = undefined;
    this.usertype = undefined;
    this.usertype_id = undefined;
    this.usertypename = undefined;
    this.status = undefined;
    this.IPAddress = undefined;
    this.corporate_id = undefined;
    this.facility_name = undefined;
    this.facility_id = undefined;
    this.global_user = undefined;
    this.ipin = undefined;
    this.last_login = undefined;
    this.last_logout = undefined;
    this.location_id = undefined;
    this.name = undefined;
    this.sec_ques1 = undefined;
    this.sec_ques2 = undefined;
    this.serviceuser_privilege = undefined;
    this.type = undefined;
    this.view_status = undefined;
    this.menu = new MenuRecord();
    this.user_pass = undefined;
    this.staterefid = undefined;
    this.state = undefined;
    this.pos = undefined;
    this.posrefid = undefined;
    this.regionrefid = undefined;
    this.region = undefined;
    this.tech_privilege = undefined;
    this.recordsAccess = undefined;
    if (profile) {
      const messObj: any = profile;
      messObj.menu = new MenuRecord(
        profile && profile.menuarr ? profile.menuarr : undefined
      );
      for (let key in messObj) {
        (this as any)[key] = messObj[key];
      }
    }
    this.topMenu = [];
    this.sidebarMenu = [];
  }

  copy(copy: UserProfileRecord) {
    // TODO: consider deepcopy use here for 'any' type
    this.refid = copy.refid;
    this.user_id = copy.user_id;
    this.user_name = copy.user_name;
    this.username = copy.username;
    this.usertype = copy.usertype;
    this.usertype_id = parseInt('' + copy.usertype_id);
    this.usertypename = copy.usertypename;
    this.status = copy.status;
    this.IPAddress = copy.IPAddress;
    this.corporate_id = copy.corporate_id;
    this.facility_name = copy.facility_name;
    this.facility_id = copy.facility_id;
    this.global_user = copy.global_user;
    this.ipin = copy.ipin;
    this.last_login = copy.last_login;
    this.last_logout = copy.last_logout;
    this.location_id = copy.location_id;
    this.name = copy.name;
    this.sec_ques1 = copy.sec_ques1;
    this.sec_ques2 = copy.sec_ques2;
    this.serviceuser_privilege = copy.serviceuser_privilege;
    this.type = copy.type;
    this.view_status = copy.view_status;
    this.menu.copy(copy.menu);
    this.user_pass = copy.user_pass;
    this.staterefid = copy.staterefid;
    this.state = copy.state;
    this.pos = copy.pos;
    this.posrefid = copy.posrefid;
    this.regionrefid = copy.regionrefid;
    this.region = copy.region;
    this.tech_privilege = copy.tech_privilege;
    this.recordsAccess = copy.recordsAccess;

    this.app_sn = copy.app_sn;
    this.locationrefid = copy.locationrefid;
    this.supervisedGroup = copy.supervisedGroup;
    this.admininfo = copy.admininfo;
    this.topMenu = copy.topMenu;
    this.sidebarMenu = copy.sidebarMenu;
  }
}
