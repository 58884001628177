import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { IconButton } from 'components/button';
import { Grid } from 'components/grid';
import DialogNotes from './components/DialogNotes';
import Filter from './components/Filter';

import {
  storeCalibrationRecords,
  RecordType,
} from 'stores/_mobx/workflow/serviceMaintenance/calibrationRecords';

interface PropsType {
  isFilterVisible: boolean;
}

const columns = [
  { name: 'device_name', title: 'Equipment Name' },
  { name: 'serial_no', title: 'Serial #' },
  { name: 'type', title: 'Equipment Type' },
  { name: 'calibration_date', title: 'Last Calibration Date' },
  { name: 'calibration_due_date', title: 'Next Calibration Date' },
  {
    name: 'refid',
    title: 'Notes',
    render: (id: number, { type_val }: RecordType) => (
      <div className="control">
        <IconButton
          onClick={() => storeCalibrationRecords.getNotes(id, type_val)}>
          <i className="icon icon-notes" />
        </IconButton>
      </div>
    ),
  },
];

const EquipmentMaintenanceTab = ({ isFilterVisible }: PropsType) => {
  const { fetching, records, filterMaintenance, notes } =
    storeCalibrationRecords;

  useEffect(() => {
    storeCalibrationRecords.getCalibrationRecords('M');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterMaintenance]);

  return (
    <>
      <Filter
        filterType="filterMaintenance"
        visibility={isFilterVisible}
        fetching={fetching}
      />

      <Grid
        id="equipmentMaintenanceGrid"
        columns={columns}
        onAjax={fetching}
        dataSource={records}
        dataSourceCount={records.length}
        disablePagination
      />

      {notes ? <DialogNotes /> : null}
    </>
  );
};

export default observer(EquipmentMaintenanceTab);
