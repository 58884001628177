import { useForm } from 'react-hook-form';

import { Input } from 'components/form/textField';
import { DateRange } from 'components/form/dateRange';
import { DropdownLocation } from 'components/project/dropdown/LocationDropdown';
import DropDownTechnologist from 'components/project/dropdown/TechnologistDropDown';
import { DropDownFacilityRole } from 'components/project/dropdown/FacilityRolesDropdown';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';

import {
  storeSummaryLocation,
  FilterType,
} from 'stores/_mobx/report/summaryLocation';

const commonClassName = 'col-md-6 col-lg-3';

interface PropsType {
  fetching: boolean;
  isFilterVisible: boolean;
  onSearch: (data: FilterType) => void;
}

const Filter = ({ fetching, isFilterVisible, onSearch }: PropsType) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: storeSummaryLocation.filter,
  });

  const handleClickReset = () => {
    const defaultFilterValue = storeSummaryLocation.getDefaultFilter();

    reset(defaultFilterValue);
    onSearch(defaultFilterValue);
  };

  return (
    <form
      className={`row${isFilterVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSearch)}>
      <Input
        name="patientLastName"
        label="Patient Last Name"
        className={commonClassName}
        control={control}
      />
      <Input
        name="patientFirstName"
        label="Patient First Name"
        className={commonClassName}
        control={control}
      />
      <DropDownTechnologist
        name="technologistId"
        label="Technologist"
        className={commonClassName}
        control={control}
      />
      <DropDownFacilityRole
        name="facility"
        label="Facility"
        className={commonClassName}
        control={control}
      />
      <div />
      <DropdownLocation
        name="location"
        label="Location"
        className={commonClassName}
        control={control}
      />
      <div className="d-lg-none" />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-6 col-lg-3 col-xl-auto"
        control={control}
      />
      <ControlsLayout alignX="right">
        <>
          <Button
            text="Reset"
            variant="warning"
            disabled={fetching}
            onClick={handleClickReset}
          />
          <Button text="Apply" type="submit" disabled={fetching} />
        </>
      </ControlsLayout>
    </form>
  );
};

export default Filter;
