import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { storeImport } from 'stores/_mobx/import';

export const initStateFilter: FilterModel = {
  modifier: '',
  description: '',
  status: 'A',
};

interface ModifierType {
  comments: string;
  flag: 'A' | 'I';
  modifier_code: string;
  modifier_desc: string;
  refid: number;
  status: 'Active' | 'Inactive';
}

interface ModifierResponseType {
  comments: string;
  modifier_code: string;
  modifier_desc: string;
  refid: string;
}

export interface ModifierFormModel {
  comment: string;
  code: string;
  description: string;
  id: number | null;
}

export interface FilterModel {
  modifier: string;
  description: string;
  status: 'A' | 'I';
}

class Modifiers {
  fetching: boolean = false;
  modifier?: ModifierFormModel = undefined;
  modifierList: ModifierType[];
  modifierTotal: number = 0;
  idForDelete: number = 0;
  filter: FilterModel = initStateFilter;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'code_set_modifiers' });

    makeObservable(this, {
      fetching: observable,
      modifierList: observable,
      modifierTotal: observable,
      idForDelete: observable,
      modifier: observable,
      filter: observable,

      setFetching: action.bound,
      setModifier: action.bound,
      setModifierList: action.bound,
      setModifierTotal: action.bound,
      setIdForDelete: action.bound,
      setFilter: action.bound,
      clearIdForDelete: action.bound,
      clearModifier: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFilter(filter: FilterModel) {
    this.filter = filter;
  }

  setModifierList(modifier: ModifierType[]) {
    this.modifierList = modifier;
  }

  setModifierTotal(count: number) {
    this.modifierTotal = count;
  }

  setModifier(modifier?: ModifierFormModel) {
    this.modifier = modifier;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  clearModifier() {
    this.modifier = undefined;
  }

  async getModifierList(filter: FilterModel) {
    const { pagination } = this.page;

    const data = [
      filter.modifier,
      filter.description,
      filter.status,
      pagination.skip,
      pagination.pageSize,
    ];
    try {
      const response = await apiRequest<'SE' | 'S' | ModifierType[]>({
        url: 'codemaster.Modifier.ModifierList',
        data,
      });

      if (Array.isArray(response)) {
        const modifiers = response.map((code) => ({
          ...code,
          refid: Number(code.refid),
        }));
        this.setModifierList(modifiers);
      } else throw Error('');
    } catch (e) {
      this.setModifierList([]);
    }
  }

  async getModifierCount(filter: FilterModel) {
    const data = [filter.modifier, filter.description, filter.status];
    try {
      const count = await apiRequest<string>({
        url: 'codemaster.Modifier.TotalRecordCount',
        data,
      });
      this.setModifierTotal(Number(count));
    } catch (e) {
      this.setModifierTotal(0);
    }
  }

  getModifierMain = (filter: FilterModel = this.filter) => {
    this.setFetching(true);

    const promiseCount = this.getModifierCount(filter);

    const promiseRegions = this.getModifierList(filter);

    Promise.allSettled([promiseCount, promiseRegions]).finally(() => {
      this.setFetching(false);
    });
  };

  async getModifier(id: number) {
    this.setFetching(true);
    try {
      const response = await apiRequest<'SE' | [ModifierResponseType]>({
        url: 'codemaster.Modifier.GetViewModifier',
        data: [id],
      });

      if (Array.isArray(response)) {
        const modifier = {
          id,
          comment: response[0].comments,
          code: response[0].modifier_code,
          description: response[0].modifier_desc,
        };
        this.setModifier(modifier);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setModifier();
    } finally {
      this.setFetching(false);
    }
  }

  async addModifier(payload: ModifierFormModel) {
    const data = [payload.code, payload.description, payload.comment];

    try {
      const response = await apiRequest<'S' | 'E'>({
        url: 'codemaster.Modifier.AddModifier',
        data,
      });
      if (response === 'S') {
        Notification.success('Modifier was added successfully!');
        return null;
      } else if (response === 'E') {
        return { message: 'This Code already exist!' };
      } else {
        throw Error('');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateModifier(payload: ModifierFormModel) {
    const data = [
      payload.id,
      payload.code,
      payload.description,
      payload.comment,
    ];

    try {
      const response = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'codemaster.Modifier.EditModifier',
        data,
      });
      if (response === 'S') {
        Notification.success('Modifier was updated successfully!');
        return null;
      } else if (response === 'E') {
        return { message: 'This Code already exist!' };
      } else {
        throw Error('');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async deleteModifier() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'codemaster.Modifier.DeleteModifier',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Modifier code was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('An error occurred!');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async changeModifierStatus(ids: number[]) {
    if (this.fetching) return false;
    this.setFetching(true);

    const status = this.filter.status === 'A' ? 'Active' : 'Inactive';
    try {
      const response: any = await Promise.allSettled(
        ids.map((id) =>
          apiRequest<'SE' | 'S'>({
            url: 'codemaster.Modifier.UpdateStatus',
            data: [id, status],
          })
        )
      );

      const result = response.reduce(
        (prev: number, curr: any) => (curr?.value === 'S' ? ++prev : prev),
        0
      );

      if (result) {
        const statusMsg =
          this.filter.status === 'I' ? 'activated' : 'deactivated';

        Notification.success(
          `${result} Modifier code${
            result > 1 ? 's were' : ' was'
          } ${statusMsg}!`
        );
        return true;
      }
      throw Error('');
    } catch (e) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async importModifiers(file: File) {
    try {
      const fileName = await storeImport.uploadFile({
        url: 'temppath/tempImportUpload.php',
        file,
      });

      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'codemaster.Modifier.ImportModifier',
        data: [fileName],
      });

      if (result === 'E') {
        Notification.danger("Can't import codes, because it already exist");
        return false;
      } else if (result === 'S') {
        Notification.success('Modifiers were imported successfully');
        return true;
      }
      throw Error('');
    } catch (e) {
      Notification.danger('An error occurred!');
      return false;
    }
  }
}

export const storeModifiers = new Modifiers();
