import { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import GridFeeSchedule from './GridFeeSchedule';

import {
  storeFeeScheduleCommon,
  FeeDataType,
  FeeType,
} from 'stores/_mobx/systemSetup/feeSchedule/common';

const titleMapper: Record<FeeType, string> = {
  S: 'Jurisdiction',
  C: 'Corporation',
  F: 'Facility',
};

interface PropsType {
  id: number;
  groupId: number;
  isActive?: boolean;
  readOnly?: boolean;
  feeType: FeeType;
  onClose: () => void;
}

const DialogChangingFeeSchedule = ({
  isActive = true,
  readOnly = false,
  onClose,
  ...rest
}: PropsType) => {
  const {
    fetching,
    feeList,
    feeTotal,
    page: { pagination, setPagination },
  } = storeFeeScheduleCommon;

  const dialogTitle = useMemo(
    () => `${titleMapper[rest.feeType] || ''} Fee Schedule`.trim(),
    [rest.feeType]
  );

  const handleUpdate = useCallback(
    (feeList: FeeDataType[]) => {
      storeFeeScheduleCommon
        .updateFee({
          feeType: rest.feeType,
          feeList,
        })
        .then((isSucceed) => {
          if (isSucceed) {
            onClose();
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rest.feeType]
  );

  useEffect(() => {
    storeFeeScheduleCommon.getFeeListMain({
      ...rest,
      status: isActive ? 'A' : 'R',
    });

    return storeFeeScheduleCommon.clearFee;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <GridFeeSchedule
      fetching={fetching}
      title={dialogTitle}
      readOnly={readOnly}
      dataSource={feeList}
      dataSourceCount={feeTotal}
      pagination={pagination}
      setPagination={setPagination}
      onClose={onClose}
      onUpdate={handleUpdate}
    />
  );
};

export default observer(DialogChangingFeeSchedule);
