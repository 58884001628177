import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

interface ExamTypeProp {
  exam_type_desc: string;
  exam_type_nm: string;
  refid: number;
}

interface ExamTypeExtended extends ExamTypeProp {
  media_type: string;
  mod_price: string;
  q0092_setup: string;
}

interface OptionResponseType {
  data: string;
  label: string;
  desc: string;
}

export interface OptionType {
  value: number;
  label: string;
}

class ExamType {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  examsTypeList: ExamTypeProp[] = [];
  examsTypeTotal: number = 0;
  examType: ExamTypeExtended | undefined = undefined;
  examIdForDelete: number | null = null;
  options: OptionType[] = [];
  filter: string = '';

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'examTypeGrid' });

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      examsTypeList: observable,
      examsTypeTotal: observable,
      examType: observable,
      examIdForDelete: observable,
      options: observable,
      filter: observable,

      setFetching: action,
      setFetchingOptions: action,
      setExamTypeList: action,
      setExamTypeTotal: action,
      setExamForDelete: action,
      setFilter: action.bound,
      clearExamForDelete: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setExamTypeList(list: ExamTypeProp[]) {
    this.examsTypeList = list;
  }

  setExamTypeTotal(count: number) {
    this.examsTypeTotal = count;
  }

  setExamType(exam?: ExamTypeExtended) {
    this.examType = exam;
  }

  setFilter(examName: string) {
    this.filter = examName;
  }

  setExamForDelete(id: number | null) {
    this.examIdForDelete = id;
  }

  clearExamForDelete() {
    this.examIdForDelete = null;
  }

  clearExamType = () => {
    this.setExamType(undefined);
  };

  getExamsTypeList = async () => {
    const {
      filter: examTypeName,
      page: { pagination },
    } = this;

    try {
      const examsTypeList = await apiRequest<'SE' | ExamTypeProp[]>({
        url: 'generalmaster.ExamType.ExamTypeList',
        data: [pagination.skip, pagination.pageSize, examTypeName],
      });
      this.setExamTypeList(Array.isArray(examsTypeList) ? examsTypeList : []);
    } catch (e) {}
  };

  getExamTypeCount = async () => {
    try {
      const total = await apiRequest<'SE' | number>({
        url: 'generalmaster.ExamType.ExamTypeTotalCount',
        data: [this.filter],
      });
      this.setExamTypeTotal(total === 'SE' ? 0 : total);
    } catch (e) {}
  };

  getExamsType = () => {
    this.setFetching(true);

    const promiseList = this.getExamsTypeList();

    const promiseCount = this.getExamTypeCount();

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  getExamType = async (id: number | string) => {
    this.setFetching(true);
    try {
      const response = await apiRequest<'SE' | [ExamTypeExtended]>({
        url: 'generalmaster.ExamType.GetViewExamType',
        data: [id],
      });

      const examType = Array.isArray(response) ? response[0] : undefined;
      this.setExamType(examType);
    } catch (e) {
    } finally {
      this.setFetching(false);
    }
  };

  addExamType = async (payload: Omit<ExamTypeExtended, 'refid'>) => {
    const data = [
      payload.exam_type_nm,
      payload.exam_type_desc,
      payload.q0092_setup,
      payload.mod_price,
      payload.media_type,
    ];

    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.ExamType.AddExamType',
        data,
      });
      if (result === 'S') {
        Notification.success('Modality was added successfully!');
        return true;
      }
      throw Error('An error occurred!');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  };

  updateExamType = async (payload: ExamTypeExtended) => {
    const data = [
      payload.refid,
      payload.exam_type_nm,
      payload.exam_type_desc,
      payload.q0092_setup,
      payload.mod_price,
      payload.media_type,
    ];

    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.ExamType.EditExamType',
        data,
      });
      if (result === 'S') {
        Notification.success('Modality was updated successfully!');
        return true;
      }
      throw Error('An error occurred!');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  };

  deleteExmType = async () => {
    try {
      if (!this.examIdForDelete) {
        Notification.warning('Modality type not selected for delete');
        return false;
      }

      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.ExamType.DeleteExamType',
        data: [this.examIdForDelete],
      });

      if (result === 'S') {
        Notification.success('Modality type was deleted successfully!');
        this.clearExamForDelete();
        return true;
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  };

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    try {
      this.setFetchingOptions(true);

      const options = await apiRequest<OptionType[]>({
        url: 'generalmaster.ExamType.ExamTypeAllDropDown',
      });

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }

  async getOptionsForOrder() {
    if (this.fetchingOptions) return Promise.resolve();

    try {
      this.setFetchingOptions(true);

      const response = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.ExamType.ExamTypeDropDown',
      });

      const options = response.map(({ data, label }) => ({
        label,
        value: Number(data),
      }));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }

  findOption = (id: number) => this.options.find(({ value }) => value === id);
}

export const storeExamType = new ExamType();
