import React from 'react';

import IterableUtils from 'utils/IterableUtils';
import Text from 'components/form/input/Text';
import NumberInput from 'components/form/input/NumberInput';
import TextCalendar from 'components/form/input/TextCalendar';
import Form from 'components/form/Form';
import Radio from 'components/form/input/Radio';
import Select from 'components/form/input/Select';
import FormControl from 'components/form/FormControl';
import Fieldset from 'components/form/Fieldset';
import Image from 'components/form/Image';
import FileUpload from 'components/form/input/FileUpload';
import StateShortNameDropdown from 'components/project/dropdown/StateShortName';
import StateDropdown from 'components/project/dropdown/StateDropdown';
import SalutationDropdown from 'components/project/dropdown/SalutationDropdown';
import SuffixDropdown from 'components/project/dropdown/SuffixDropdown';
import OrganizationDropdown from 'components/project/dropdown/OrganizationDropdown';
import RegionDropdown from 'components/project/dropdown/RegionDropdown';
import LocationDropdown from 'components/project/dropdown/LocationDropdown';
import RadioOptions from 'components/project/common/RadioOptions';
import FacilityDropdown from 'components/project/dropdown/FacilityDropdown';
import { FakeInput } from 'components/form/textField';
import PatientRecordPageActions from 'actions/patient-records/patient-demographics/PatientRecordPageActions';
import PatientRecordPageStateActions from 'actions/patient-records/patient-demographics/PatientRecordPageStateActions';
import UserProfileStore from 'stores/UserProfileStore';
import {
  TGeneralInfo,
  TFacilityInfo,
  TContactInfo,
} from 'services/patient-records/patient-demographics/PatientRecordPageService';
import { BASE_URL_FILE_DIR } from 'constant/config';

const optionsMarital = [
  { label: 'Married', value: 'M' },
  { label: 'Single', value: 'S' },
  { label: 'Widowed', value: 'W' },
  { label: 'Separated', value: 'S' },
  { label: 'Divorced', value: 'D' },
];

export interface PPatientInfoTab {
  mode: number;
  generalInfo: TGeneralInfo;
  generalInfoEditable: boolean;
  facilityInfo: TFacilityInfo;
  facilityInfoEditable: boolean;
  contactInfo: TContactInfo;
  contactInfoEditable: boolean;
  updateGeneralInfo: (generalInfo: TGeneralInfo) => Promise<any>;
  updateFacilityInfo: (facilityInfo: TFacilityInfo) => Promise<any>;
  updateContactInfo: (contactInfo: TContactInfo) => Promise<any>;
  addPatientInfo: (patientInfo: any) => Promise<any>;
}

export class SPatientInfoTab {
  mode: number = null;
  generalInfo: TGeneralInfo = new TGeneralInfo();
  generalInfoEditable: boolean = false;
  facilityInfo: TFacilityInfo = new TFacilityInfo();
  facilityInfoEditable: boolean = false;
  contactInfo: TContactInfo = new TContactInfo();
  contactInfoEditable: boolean = false;
  generalInfoErrors: any = {};
  facilityInfoErrors: any = {};
  patientContactErrors: any = {};
  hasErrors: boolean = false;
  tempImage: boolean = false;
  collapsedGeneralInfo: boolean = false;
  collapsedPatientFacility: boolean = false;
  collapsedPatientContact: boolean = false;
  disabled: boolean = false;
}

export default class PatientInfoTab extends React.Component<
  PPatientInfoTab,
  SPatientInfoTab
> {
  constructor(props: PPatientInfoTab) {
    super(props);
    this.state = Object.assign(new SPatientInfoTab(), {
      generalInfo: this.props.generalInfo,
      generalInfoEditable: this.props.generalInfoEditable,
      facilityInfo: this.props.facilityInfo,
      facilityInfoEditable: this.props.facilityInfoEditable,
      contactInfo: this.props.contactInfo,
      contactInfoEditable: this.props.contactInfoEditable,
      mode: this.props.mode,
      generalInfoErrors: {},
      facilityInfoErrors: {},
      patientContactErrors: {},
      hasErrors: false,
      tempImage: false,
      collapsedGeneralInfo: false,
      collapsedPatientFacility: false,
      collapsedPatientContact: false,
      disabled: true,
    });
  }

  componentWillUnmount() {
    const generalInfo = IterableUtils.extend(true, {}, this.state.generalInfo);
    const facilityInfo = { ...this.state.facilityInfo };
    const contactInfo = { ...this.state.contactInfo };
    PatientRecordPageStateActions.cachePatientInfoTab(
      generalInfo,
      facilityInfo,
      contactInfo
    );
  }

  onSetDateOfBirth(name: string, value: any) {
    const generalInfo = { ...this.state.generalInfo, [name]: value };
    this.setState({ generalInfo });
  }

  enableEditing() {}

  updateImagePath(name: string, value: any) {
    const generalInfo = IterableUtils.extend(true, {}, this.state.generalInfo);
    generalInfo.old_patient_img_path = generalInfo.patient_img_path;
    generalInfo.patient_img_path = value;
    this.setState({ generalInfo, tempImage: true });
  }

  onFacilityUpdate(value: number) {
    PatientRecordPageActions.loadLocationByFacilityId(value).then(
      (response: any) => {
        if (response && response[0]) {
          const location = response[0];

          const facilityInfo = {
            ...this.state.facilityInfo,
            facility_id: value,
            location_id: Number(location.locationrefid) || 0,
            region_id: Number(location.regionrefid) || 0,
            state_id: Number(location.staterefid) || 0,
          };
          this.setState({ facilityInfo });
        }
      }
    );
  }

  generalInfoRef: Form = null;
  patientFacilityInfoRef: Form = null;
  patientContactInfo: Form = null;

  render() {
    const { generalInfo, tempImage } = this.state;

    const avatarUrl = tempImage
      ? `${BASE_URL_FILE_DIR}tempfolder/${generalInfo.patient_img_path}`
      : generalInfo.patient_img_path
      ? `${BASE_URL_FILE_DIR}doc_img/patientimage/${generalInfo.patient_img_path}`
      : '/assets/images/defaultpatient.png';

    const disabled = this.state.mode === 2;

    const submitFunction =
      this.state.mode === 1
        ? this.onSubmitPatientInfo
        : this.onSubmitContactInfo;
    const generalInfoDisabled = !this.state.generalInfoEditable
      ? { disabled }
      : {};
    const facilityInfoDisabled = !this.state.facilityInfoEditable
      ? { disabled }
      : {};
    const contactInfoDisable = !this.state.contactInfoEditable
      ? { disabled }
      : {};

    return (
      <div className="patient-info-tab">
        <Form
          model={generalInfo}
          submit={this.onSubmitGeneralInfo.bind(this)}
          ref={(ref) => {
            this.generalInfoRef = ref;
          }}
          onCollectValues={this.onCollectGeneralInfoValues.bind(this)}
          errors={this.state.generalInfoErrors}>
          <Fieldset
            className={
              this.state.collapsedGeneralInfo ? 'collapsed' : 'expanded'
            }>
            <legend
              onClick={() => {
                this.setState({
                  collapsedGeneralInfo: !this.state.collapsedGeneralInfo,
                });
              }}>
              General information
            </legend>
            <Fieldset className="row">
              <Fieldset className="col-sm-6">
                <Fieldset className="photo-section">
                  <Image
                    className="image-component"
                    alt="Patient photo"
                    path={avatarUrl}
                  />
                  <FileUpload
                    onSetValue={this.updateImagePath.bind(this)}
                    buttonLabel="Upload file"
                    noLabel
                  />
                </Fieldset>
                {this.state.mode === 2 ? (
                  <>
                    <FakeInput
                      name="patient_id"
                      label="Medical record number"
                      value={
                        UserProfileStore.getAppSn() + generalInfo.patient_id
                      }
                    />
                  </>
                ) : null}

                <Text
                  name="first_name"
                  label="First name"
                  validations="required"
                  errorMessages="Please, enter firstname!"
                  attr={generalInfoDisabled}
                />
                <Text
                  name="middle_name"
                  label="Middle name"
                  attr={generalInfoDisabled}
                />
                <Text
                  name="last_name"
                  label="Last name"
                  validations="required"
                  errorMessages="Please, enter the lastname!"
                  attr={generalInfoDisabled}
                />

                <Text
                  name="first_nickname"
                  label="First alias name"
                  attr={generalInfoDisabled}
                />
                <Text
                  name="last_nickname"
                  label="Last alias name"
                  attr={generalInfoDisabled}
                />

                <TextCalendar
                  name="birthdate"
                  label="Date of birth"
                  onSetValue={this.onSetDateOfBirth.bind(this)}
                  validations="required"
                  errorMessages="Please, select the date of birth!"
                  attr={generalInfoDisabled}
                />
                <Text
                  name="inmate_number"
                  label="OID number"
                  attr={generalInfoDisabled}
                />
              </Fieldset>
              <Fieldset className="col-sm-6">
                <Select
                  name="martial_status"
                  label="Marital status"
                  options={optionsMarital}
                  attr={generalInfoDisabled}
                />

                {/*needs additional processing*/}
                <Text
                  name="spouse_name"
                  label="Spouse name"
                  attr={generalInfoDisabled}
                />

                <Radio
                  className="part-inline"
                  label="Gender"
                  name="gender"
                  options={RadioOptions.gender}
                  errorMessages="Please, select the gender!"
                  attr={generalInfoDisabled}
                />

                <SalutationDropdown
                  name="salutationid"
                  label="Salutation"
                  attr={generalInfoDisabled}
                />
                <SuffixDropdown
                  name="suffixid"
                  label="Qualification"
                  attr={generalInfoDisabled}
                />
                <OrganizationDropdown
                  name="organization_id"
                  label="Organization"
                  attr={generalInfoDisabled}
                />
                <NumberInput
                  name="ssn"
                  label="Social security number"
                  format="###-##-####"
                  attr={generalInfoDisabled}
                />
                <Text
                  name="ice_number"
                  label="ICE number"
                  attr={generalInfoDisabled}
                />
                <Text
                  name="immigration_id"
                  label="Marshall ID"
                  attr={generalInfoDisabled}
                />
                <Text
                  name="clientMRN"
                  label="Other MRN"
                  attr={generalInfoDisabled}
                />
                <Radio
                  className="part-inline"
                  label="Tobacco use"
                  name="smoking"
                  options={[
                    { label: 'Yes', value: 'Y' },
                    { label: 'No', value: 'N' },
                    { label: 'Unknown', value: 'U' },
                  ]}
                  attr={generalInfoDisabled}
                />
              </Fieldset>
            </Fieldset>
          </Fieldset>
          <FormControl>
            {!this.state.collapsedGeneralInfo &&
            this.state.mode === 2 &&
            this.state.generalInfoEditable
              ? [
                  <input
                    key="2"
                    type="submit"
                    className="btn btn-primary"
                    value="Save"
                  />,
                ]
              : null}
          </FormControl>
        </Form>

        <Form
          model={this.state.facilityInfo}
          submit={this.onSubmitFacilityInfo.bind(this)}
          ref={(ref) => {
            this.patientFacilityInfoRef = ref;
          }}
          onCollectValues={this.onCollectFacilityInfoValues.bind(this)}
          errors={this.state.facilityInfoErrors}>
          <Fieldset
            className={
              this.state.collapsedPatientFacility ? 'collapsed' : 'expanded'
            }>
            <legend
              onClick={() => {
                this.setState({
                  collapsedPatientFacility:
                    !this.state.collapsedPatientFacility,
                });
              }}>
              Patient Facility Information
            </legend>
            <Fieldset className="row">
              <Fieldset className="col-sm-6">
                <FacilityDropdown
                  name="facility_id"
                  label="Facility"
                  validations="required:lazy"
                  errorMessages="Please, select a facility!"
                  noClear
                  attr={facilityInfoDisabled}
                />
                <RegionDropdown
                  name="region_id"
                  label="Region"
                  attr={{ disabled: 'disabled' }}
                />
              </Fieldset>
              <Fieldset className="col-sm-6">
                <StateDropdown
                  name="state_id"
                  label="State"
                  withExternalLoader
                  attr={{ disabled: 'disabled' }}
                />
                <LocationDropdown
                  name="location_id"
                  label="Location"
                  attr={{ disabled: 'disabled' }}
                />
              </Fieldset>
            </Fieldset>
          </Fieldset>
          <FormControl>
            {!this.state.collapsedPatientFacility &&
            this.state.mode === 2 &&
            this.state.facilityInfoEditable
              ? [
                  <input
                    key="2"
                    type="submit"
                    className="btn btn-primary"
                    value="Save"
                  />,
                ]
              : null}
          </FormControl>
        </Form>
        <Form
          model={this.state.contactInfo}
          submit={submitFunction.bind(this)}
          ref={(ref) => {
            this.patientContactInfo = ref;
          }}
          onCollectValues={this.onCollectContactInfoValues.bind(this)}
          errors={this.state.patientContactErrors}>
          <Fieldset
            className={
              this.state.collapsedPatientContact ? 'collapsed' : 'expanded'
            }>
            <legend
              onClick={() => {
                this.setState({
                  collapsedPatientContact: !this.state.collapsedPatientContact,
                });
              }}>
              Patient contact information
            </legend>
            <Fieldset className="row mb-5">
              <NumberInput
                name="phone"
                label="Home phone"
                format="###-###-####"
                className="col-md-6"
                attr={contactInfoDisable}
              />
              <NumberInput
                name="mobile"
                label="Cell phone"
                format="###-###-####"
                className="col-md-6"
                attr={contactInfoDisable}
              />
              <NumberInput
                name="fax"
                label="Fax"
                format="###-###-####"
                className="col-md-6"
                attr={contactInfoDisable}
              />
              <Text
                name="email"
                label="Email"
                className="col-md-6"
                attr={contactInfoDisable}
              />
            </Fieldset>
            <Fieldset className="row">
              <Fieldset className="col-sm-6">
                <legend>1st address information</legend>
                <Text
                  name="address1"
                  label="Address"
                  attr={contactInfoDisable}
                />
                <StateShortNameDropdown
                  name="state"
                  label="State"
                  withExternalLoader
                  attr={contactInfoDisable}
                />
                <Text name="city" label="City" attr={contactInfoDisable} />
                <NumberInput
                  name="zipcode"
                  label="Zip Code"
                  format="#####-####"
                  attr={contactInfoDisable}
                />
              </Fieldset>
              <Fieldset className="col-sm-6">
                <legend>2nd address information</legend>
                <Text
                  name="address2"
                  label="Address"
                  attr={contactInfoDisable}
                />
                <StateShortNameDropdown
                  name="state2"
                  label="State"
                  withExternalLoader
                  attr={contactInfoDisable}
                />
                <Text name="city2" label="City" attr={contactInfoDisable} />
                <NumberInput
                  name="zipcode2"
                  label="Zip Code"
                  format="#####-####"
                  attr={contactInfoDisable}
                />
              </Fieldset>
            </Fieldset>
          </Fieldset>
          <FormControl>
            {this.state.contactInfoEditable &&
            (!this.state.collapsedPatientContact || this.state.mode === 1) ? (
              <input type="submit" className="btn btn-primary" value="Save" />
            ) : null}
          </FormControl>
        </Form>
      </div>
    );
  }

  reset() {
    PatientRecordPageStateActions.restoreInitialPatientInfoTab();
  }

  validateLPNumber(LPNumber: any) {
    return true;
  }

  onCollectGeneralInfoValues(name: string, value: any, errorMessages: any) {
    const state = IterableUtils.extend(true, {}, this.state);
    state.generalInfo[name] = value;
    state.generalInfoErrors[name] = errorMessages;
    this.setState(state);
  }

  onCollectFacilityInfoValues = (
    name: string,
    value: any,
    errorMessages: any
  ) => {
    if (name === 'facility_id') {
      this.onFacilityUpdate(value);
      return;
    }
    const facilityInfo = { ...this.state.facilityInfo, [name]: value };
    const facilityInfoErrors = {
      ...this.state.facilityInfoErrors,
      [name]: errorMessages,
    };
    this.setState({ facilityInfo, facilityInfoErrors });
  };

  onCollectContactInfoValues(name: string, value: any, errorMessages: any) {
    const state = IterableUtils.extend(true, {}, this.state);
    state.contactInfo[name] = value;
    state.patientContactErrors[name] = errorMessages;
    this.setState(state);
  }

  onSubmit() {}

  onSubmitGeneralInfo(model: any, hasErrors: boolean, errors: any) {
    if (!hasErrors) {
      const generalInfo = IterableUtils.extend(
        true,
        {},
        this.state.generalInfo
      );
      this.props.updateGeneralInfo(generalInfo).then((response) => {
        generalInfo.patient_img_path = response.fileName;
        this.setState({ generalInfo, tempImage: false });
      });
    }
  }

  onSubmitFacilityInfo(model: any, hasErrors: boolean, errors: any) {
    const facilityInfo = { ...this.state.facilityInfo };
    this.props.updateFacilityInfo(facilityInfo);
  }

  onSubmitContactInfo(model: any, hasErrors: boolean, errors: any) {
    const contactInfo = { ...this.state.contactInfo };
    this.props.updateContactInfo(contactInfo);
  }

  onSubmitPatientInfo(model: any, hasErrors: boolean, errors: any) {
    const patientInfo = IterableUtils.extend(
      true,
      this.state.generalInfo,
      this.state.facilityInfo,
      this.state.contactInfo
    );
    this.props.addPatientInfo(patientInfo);
  }
}
