import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

export interface CodeType {
  code_type: string;
  description: string;
  refid: number;
}

interface CodeResponseType {
  code_type: string;
  description: string;
}

interface OptionResponseType {
  data: string;
  label: string;
}

export interface OptionType {
  value: number;
  label: string;
}

class AdjustmentCode {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  codesList: CodeType[] = [];
  codesTotal: number = 0;
  code: CodeType | undefined = undefined;
  idForDelete: number = 0;
  options: OptionType[] = [];
  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'adjustemnt_grid' });

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      codesList: observable,
      codesTotal: observable,
      code: observable,
      idForDelete: observable,
      options: observable,

      setFetching: action,
      setFetchingOptions: action,
      setCodesList: action,
      setCodesTotal: action,
      setCode: action,
      setIdForDelete: action,
      clearIdForDelete: action.bound,
      clearCode: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFetchingOptions = (fetching: boolean) => {
    this.fetchingOptions = fetching;
  };

  setCodesList = (list: CodeType[]) => {
    this.codesList = list;
  };

  setCodesTotal = (count: number) => {
    this.codesTotal = count;
  };

  setCode = (code?: CodeType) => {
    this.code = code;
  };

  setIdForDelete = (id: number | null) => {
    this.idForDelete = id;
  };

  clearIdForDelete() {
    this.idForDelete = null;
  }

  clearCode() {
    this.setCode(undefined);
  }

  async getCodesList(codes: string) {
    const { pagination } = this.page;

    const data = [pagination.skip, pagination.pageSize, codes];

    try {
      const response = await apiRequest<CodeType[]>({
        url: 'generalmaster.AdjustmentCodeMaster.GetAdjustmentCodeDetails',
        data,
      });

      const codes = response.map((code) => ({
        ...code,
        refid: Number(code.refid),
      }));

      this.setCodesList(codes);
    } catch (e) {
      this.setCodesList([]);
    }
  }

  async getCodesCount(code: string) {
    try {
      const total = await apiRequest<'SE' | number>({
        url: 'generalmaster.AdjustmentCodeMaster.TotalRecordCount',
        data: [code],
      });
      this.setCodesTotal(Number(total) || 0);
    } catch (e) {
      this.setCodesTotal(0);
    }
  }

  getCodesListMain = (code: string = '') => {
    this.setFetching(true);

    const promiseList = this.getCodesList(code);

    const promiseCount = this.getCodesCount(code);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getCode(id: number) {
    this.setFetching(true);
    try {
      const response = await apiRequest<'SE' | [CodeResponseType]>({
        url: 'generalmaster.AdjustmentCodeMaster.GetAdjustmentCodeById',
        data: [id],
      });

      const code = Array.isArray(response)
        ? { ...response[0], refid: id }
        : undefined;
      this.setCode(code);
    } catch (e) {
      this.setCode();
    } finally {
      this.setFetching(false);
    }
  }

  async addCode(payload: CodeType) {
    const data = [payload.code_type, payload.description];

    try {
      const result = await apiRequest<'SE' | 'N' | 'S'>({
        url: 'generalmaster.AdjustmentCodeMaster.AddAdjustmentCodeDetails',
        data,
      });
      if (result === 'S') {
        Notification.success('Adjustment code was added successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'This Code already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateCode(payload: CodeType) {
    const data = [payload.refid, payload.code_type, payload.description];

    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.AdjustmentCodeMaster.UpdateAdjustmentCodeDetails',
        data,
      });
      if (result === 'S') {
        Notification.success('Adjustment code was updated successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This Code already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async deleteCode() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.AdjustmentCodeMaster.DeleteAdjustmentCode',
        data: [this.idForDelete],
      });

      if (result === 'S') {
        Notification.success('Adjustment code was deleted successfully!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('An error occurred!');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    try {
      this.setFetchingOptions(true);

      const response = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.AdjustmentCodeMaster.AdjustmentCodeDropDown',
      });

      const options = response.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storeAdjustmentCode = new AdjustmentCode();
