import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Button, IconButton } from 'components/button';
import { Radio } from 'components/form/radio';
import { Textarea } from 'components/form/textarea';
import { Input } from 'components/form/textField';
import { ControlsLayout } from 'components/layout';
import DropdownUserCategory from 'components/project/dropdown/UserCategory';
import DropdownPrivilegeEquivalence from 'components/project/dropdown/PrivilegeEquivalence';
import DialogNewCategory from './DialogNewCategory';
import resolver from './validation';

import { DesignationEditType } from 'stores/_mobx/systemSetup/masterSetting/designation';
import { storeUserCategory } from 'stores/_mobx/systemSetup/masterSetting/userCategory';

const userTypeOptions = [
  { label: 'Company User Types', value: 0 },
  { label: 'Client User Types', value: 1 },
];

const initialValues: DesignationEditType = {
  categoryId: 0,
  logoutTime: 0,
  id: 0,
  privilegeId: '',
  userType: 0,
  description: '',
  name: '',
};

interface PropsType {
  backUrl: string;
  defaultValues?: DesignationEditType;
  onSubmit: (data: DesignationEditType) => Promise<any>;
}

const Form = ({
  defaultValues = initialValues,
  backUrl,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    setError,
    setValue,
    reset,
    handleSubmit,
  } = useForm<DesignationEditType>({ defaultValues, resolver });

  const [isDialogVisible, toggleDialog] = useState(false);

  const handleToggleDialog = () => {
    toggleDialog((state) => !state);
  };

  const handleCreateCategory = (categoryName: string) =>
    storeUserCategory.addCategory(categoryName).then((response) => {
      if (typeof response === 'number') {
        setValue('categoryId', response);
        handleToggleDialog();
        return null;
      }
      return response;
    });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: DesignationEditType) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('name', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <>
      <form
        className={`row${isSubmitting ? ' on-ajax' : ''}`}
        onSubmit={handleClickSubmit}>
        <div className="col-lg-6">
          <Radio
            name="userType"
            className="inline"
            label="User type"
            options={userTypeOptions}
            control={control}
          />
          <Input
            name="name"
            label="User Type Name"
            required
            control={control}
          />
          <div className="row gap-3">
            <DropdownUserCategory
              name="categoryId"
              label="Category"
              className="col-sm-auto flex-grow-1"
              required
              control={control}
            />
            <IconButton
              className="col-sm-auto text-primary"
              onClick={handleToggleDialog}>
              <i className="bi bi-plus-circle" />
            </IconButton>
          </div>
          <DropdownPrivilegeEquivalence
            name="privilegeId"
            label="Privilege Equivalence"
            required
            control={control}
          />
          <Textarea
            name="description"
            label="Description"
            required
            rows={2}
            control={control}
          />

          <Input
            label="Logout interval"
            name="logoutTime"
            required
            control={control}
          />

          <ControlsLayout alignX="right">
            <Link to={backUrl} className="btn btn-danger">
              Back
            </Link>
            <Button
              variant="warning"
              text="Reset"
              disabled={isSubmitting || !isDirty}
              onClick={handleClickReset}
            />
            <Button
              type="submit"
              text="Submit"
              disabled={isSubmitting || !isDirty}
            />
          </ControlsLayout>
        </div>
      </form>

      {isDialogVisible && (
        <DialogNewCategory
          onSubmit={handleCreateCategory}
          onClose={handleToggleDialog}
        />
      )}
    </>
  );
};

export default Form;
