import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import GridActionControls from 'page/components/GridActionControls';
import useDebounce from 'components/hook/useDebounce';
import Filter from './components/Filter';
import DialogFeeSchedule from './components/DialogFeeSchedule';
import { Grid, GridControlButton } from 'components/grid';
import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import DialogDocuments from './components/DialogDocuments';
import DialogConfirm from 'components/modal/dialogConfirm';

import {
  storeRadiologyGroup,
  RadiologyGroupType,
} from 'stores/_mobx/systemSetup/masterSetting/radiologyGroup';
import { MS_RADIOLOGY_GROUP as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_RADIOLOGY_GROUP_ADD,
  URL_RADIOLOGY_GROUP,
} from 'constant/path/systemSetup';

const columns = [
  { name: 'radiology_group_nm', title: 'Radiology group' },
  { name: 'contracted', title: 'Type' },
  { name: 'tax_id', title: 'Tax ID' },
  { name: 'address', title: 'Address 1' },
  { name: 'city', title: 'City' },
  { name: 'state', title: 'State' },
  { name: 'zipcode', title: 'Zip code' },
  { name: 'radiology_group_desc', title: 'Description' },
  {
    name: 'feeSchedule',
    title: 'Fee Schedule',
    className: 'text-center',
    render: (feeSchedule: undefined, data: RadiologyGroupType) =>
      feeSchedule ? (
        <IconButton
          onClick={() =>
            storeRadiologyGroup.setFeeProps({
              feeScheduleId: data.feeSchedule,
              radiologyGroupName: data.radiology_group_nm,
            })
          }
          className="text-primary">
          <i className="bi bi-eye-fill" />
        </IconButton>
      ) : (
        'N/A'
      ),
  },
  {
    name: 'docs',
    title: 'Documents',
    className: 'text-center',
    render: (values: undefined, { refid }: RadiologyGroupType) => (
      <IconButton
        className="text-primary"
        onClick={() => storeRadiologyGroup.setIdForDocs(refid)}>
        <i className="bi bi-folder2-open" />
      </IconButton>
    ),
  },
  {
    name: 'control',
    title: 'Actions',
    className: 'width-175',
    render: (value: undefined, { refid }: RadiologyGroupType) => (
      <GridActionControls
        id={refid}
        url={`${URL_RADIOLOGY_GROUP}/edit/${refid}`}
        onDelete={storeRadiologyGroup.setIdForDelete}
      />
    ),
  },
];

const RadiologyGroupListPage = () => {
  const {
    fetching,
    radiologyGroupsList,
    radiologyGroupsTotal,
    filterGroupName,
    idForDelete,
    idForDocument,
    feeProps,
    page: { pagination, setPagination },
  } = storeRadiologyGroup;

  const debouncedMethod = useDebounce(
    storeRadiologyGroup.setFilterAndPagination
  );

  const handleApproveDelete = () => {
    storeRadiologyGroup.deleteRadiologistGroup().then((isSucceed) => {
      if (isSucceed) {
        storeRadiologyGroup.getRadiologistGroupsMain();
      }
    });
  };

  useEffect(() => {
    storeRadiologyGroup.getRadiologistGroupsMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Radiology Group">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <Filter defaultValue={filterGroupName} onChange={debouncedMethod} />

      <Grid
        id="radiologyGroupGrid"
        columns={columns}
        onAjax={fetching}
        dataSource={radiologyGroupsList}
        dataSourceCount={radiologyGroupsTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_RADIOLOGY_GROUP_ADD} />
        }
      />
      {idForDelete ? (
        <DialogConfirm
          onCancel={storeRadiologyGroup.clearIdForDelete}
          onApprove={handleApproveDelete}>
          Are you sure you want to delete?
        </DialogConfirm>
      ) : null}

      {idForDocument ? (
        <DialogDocuments onClose={storeRadiologyGroup.clearDocuments} />
      ) : null}

      {feeProps && <DialogFeeSchedule onClose={storeRadiologyGroup.clearFee} />}
    </>
  );
};

export default observer(RadiologyGroupListPage);
