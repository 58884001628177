import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import GridActionControls from 'page/components/GridActionControls';
import Exporter from 'components/project/ExporterNew';
import useDebounce from 'components/hook/useDebounce';
import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import DialogZipCodesImport from './components/DialogZipCodesImport';
import Filter from './components/Filter';

import {
  storeZipCode,
  ZipType,
  FilterType,
} from 'stores/_mobx/systemSetup/masterSetting/zipCode';
import UserProfileStore from 'stores/UserProfileStore';
import AccessUtils from 'utils/AccessUtils';
import { MS_ZIP_CODE as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_ZIP_CODES,
  URL_ZIP_CODES_ADD,
} from 'constant/path/systemSetup';

const columns = [
  { name: 'state', title: 'State' },
  { name: 'zip', title: 'ZIP code' },
  { name: 'carrier', title: 'Carrier' },
  { name: 'locality', title: 'Locality' },
  { name: 'rural_index', title: 'Rural IND' },
  { name: 'lab_cb_locality', title: 'LAB CB Locality' },
  { name: 'rural_index_2', title: 'Rural IND2' },
  { name: 'plus_4_flag', title: 'Plus 4 FLAG' },
  { name: 'part_b_drug_indicator', title: 'Part B Drug indicator' },
  { name: 'year_qtr', title: 'Year/QTR' },
  {
    name: 'control',
    title: 'Action',
    export: 'exclude',
    render: (value: undefined, { refid }: ZipType) => (
      <GridActionControls
        id={refid}
        url={`${URL_ZIP_CODES}/${refid}`}
        onDelete={storeZipCode.setIdForDelete}
      />
    ),
  },
];

const ZipCodeListPage = () => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    uploading,
    zipCodesList,
    zipCodesTotal,
    idForDelete,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeZipCode;

  const [selected, setSelected] = useState<number[]>([]);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const [isImportVisible, toggleImport] = useState<boolean>(false);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const exportName = useMemo(
    () =>
      UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
      'jurisdiction_zip_codes',
    []
  );

  const handleChangeFilter = (filter: FilterType) => {
    storeZipCode.setFilter(filter);

    debouncedMethod();
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleToggleImport = () => {
    toggleImport((state) => !state);
  };

  const handleApproveDelete = () => {
    storeZipCode.deleteZipCode().then((isSucceed) => {
      if (isSucceed) storeZipCode.getZipCodesList();
    });
  };

  const handleSubmitImport = (file: File) => {
    storeZipCode.uploadZipCodeFile(file).then((isSucceed) => {
      if (isSucceed) {
        storeZipCode.getZipCodesList();
      }
      handleToggleImport();
    });
  };

  useEffect(() => {
    storeZipCode.getZipCodesList();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Jurisdiction Zip Codes">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <Filter onSubmit={handleChangeFilter} />

      <Grid
        ref={gridRef}
        columns={columns}
        onAjax={fetching}
        id="jurisdictionZipCodeGrid"
        selectId="refid"
        dataSource={zipCodesList}
        dataSourceCount={zipCodesTotal}
        pagination={pagination}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        gridControlPanel={
          <>
            <GridControlButton title="Export" onClick={handleToggleExport} />
            <GridControlButton title="Import" onClick={handleToggleImport} />
            {AccessUtils.checkAccess(PAGE_ID.ADD) && (
              <GridControlButton title="Add" url={URL_ZIP_CODES_ADD} />
            )}
          </>
        }
      />

      {idForDelete ? (
        <DialogConfirm
          onApprove={handleApproveDelete}
          onCancel={storeZipCode.clearIdForDelete}>
          Are you sure you want to delete?
        </DialogConfirm>
      ) : null}

      {isImportVisible && (
        <DialogZipCodesImport
          isUploading={uploading}
          onSubmit={handleSubmitImport}
          onClose={handleToggleImport}
        />
      )}
      {isExportVisible && (
        <Exporter
          columns={columns}
          gridRef={gridRef}
          reportName={exportName}
          filter={filter}
          dataSource={zipCodesList}
          selected={selected}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default observer(ZipCodeListPage);
