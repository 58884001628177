import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import FormModifyComputer, { FormModel } from './components/Form';
import { SpinnerFixed } from 'components/spinner';

import { storeComputer } from 'stores/_mobx/workflow/equipmentInventory/computer';
import { URL_COMPUTER } from 'constant/path/workflow';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const EditComputerPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, computer } = storeComputer;

  const handleSubmit = async (data: FormModel) => {
    const payload = {
      ...data,
      refid: Number(params.id),
    };

    return storeComputer.updateComputer(payload).then((result) => {
      if (result) return result;
      history.push(URL_COMPUTER);
    });
  };

  useEffect(() => {
    storeComputer.getComputer(params.id);
    return storeComputer.clearComputer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Computer" />
      <FormModifyComputer
        backUrl={URL_COMPUTER}
        defaultValues={computer}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(EditComputerPage);
