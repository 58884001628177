import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Grid } from 'components/grid';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Input } from 'components/form/textField';
import { Button } from 'components/button';

import { CorporateAminUserType } from 'services/system-setup/master-setup/corporations/CorporationOverviewPageService';

const COLUMNS = [
  { name: 'first_name', title: 'First name' },
  { name: 'last_name', title: 'Last name' },
  { name: 'usertype_name', title: 'User Type' },
];

const defaultValues = {
  firstName: '',
  lastName: '',
};

const searchString = (name: string, search: string): boolean => {
  if (search) {
    return name.toLowerCase().includes(search.toLowerCase());
  }
  return true;
};

interface PropsType {
  corporationId: number;
  userList: CorporateAminUserType[];
  selectedUsersIds: number[];
  onClose: () => void;
  onSubmit: (ids: number[]) => void;
}

const DialogCorporationUsers = ({
  userList,
  selectedUsersIds,
  onClose,
  onSubmit,
}: PropsType) => {
  const [selected, setSelected] = useState<number[]>([...selectedUsersIds]);

  const { control, watch } = useForm({ defaultValues });

  const { firstName, lastName } = watch();

  const filteredUsers = useMemo(() => {
    if (firstName || lastName) {
      return userList.filter((user) => {
        const isFirstNameFind = searchString(user.first_name, firstName);
        const isLastNameFind = searchString(user.last_name, lastName);
        return isFirstNameFind && isLastNameFind;
      });
    }
    return userList;
  }, [firstName, lastName, userList]);

  const handleSubmit = () => {
    onSubmit(selected);
  };

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Select users" onClose={onClose} />
      <DialogBody>
        <form className="row">
          <Input
            name="firstName"
            placeholder="First name"
            className="col-md-6"
            control={control}
          />
          <Input
            name="lastName"
            placeholder="Last name"
            className="col-md-6"
            control={control}
          />
        </form>
        <Grid
          id="selection_modal_grid"
          selectId="refid"
          disablePagination
          noControl
          columns={COLUMNS}
          dataSource={filteredUsers}
          selectedIds={selected}
          onSelectChange={setSelected}
        />
      </DialogBody>
      <DialogFooter>
        <Button text="Submit" onClick={handleSubmit} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogCorporationUsers;
