import { apiRequest } from 'services/RequestService';

class EquipmentPageService {
  DeviceXrayDropDown_newList(xray, ultra, id) {
    return apiRequest({
      url: 'vehicle.EquipmentMaster.DeviceXrayDropDown_new',
      data: [xray, ultra, id],
    });
  }

  loadEquipmentByTech(userId) {
    return apiRequest({
      url: 'vehicle.EquipmentMaster.GetEquipmentByTech',
      data: [userId],
    });
  }

  GetViewEquipment(id) {
    return apiRequest({
      url: 'vehicle.EquipmentMaster.GetViewEquipment',
      data: [id],
    });
  }

  GetViewEquipmentByTech(id) {
    return apiRequest({
      url: 'vehicle.EquipmentMaster.GetViewEquipmentByTech',
      data: [id],
    });
  }

  CheckValidTech(value) {
    return apiRequest({
      url: 'vehicle.EquipmentMaster.CheckValidTech',
      data: [value],
    });
  }

  editEquipmentCheckOut(item) {
    const data = [
      item.modifyId,
      item.vehicleid,
      item.unitid,
      item.deviceType,
      item.ekgid,
      item.phoneid,
      item.start_dt,
      item.technologistid,
      item.starting_odometer,
      item.txtcomments,
      item.halfLeadid,
      item.thyroid,
      item.gloveid,
      item.rightgLove,
      item.rbEquipment,
      item.boneid,
      item.fullLeads,
      item.groupid,
      item.userType,
    ];

    return apiRequest({ url: 'vehicle.EquipmentMaster.EditEquipment', data });
  }

  saveEquipmentCheckOut(item) {
    const data = [
      item.vehicleid,
      item.unitid,
      item.deviceType,
      item.ekgid,
      item.phoneid,
      item.start_dt,
      item.technologistid,
      item.starting_odometer,
      item.txtcomments,
      item.halfLeadid,
      item.thyroid,
      item.gloveid,
      item.rightgLove,
      item.rbEquipment,
      item.boneid,
      item.fullLeads,
      item.groupid,
      item.userType,
    ];

    return apiRequest({ url: 'vehicle.EquipmentMaster.AddEquipment', data });
  }

  activeEkgNameDropDown(id) {
    return apiRequest({
      url: 'vehicle.EquipmentMaster.EkgNameDropDown',
      data: [id],
    });
  }

  LoadVehicle(id) {
    return apiRequest({
      url: 'vehicle.EquipmentMaster.AvailableVehicleDropDown',
      data: [id],
    });
  }

  activePhoneNumberDropDown(id) {
    return apiRequest({
      url: 'vehicle.EquipmentMaster.PhoneNumberDropDown',
      data: [id],
    });
  }

  UpdateOdometerEquipment(item) {
    const data = [
      item.refid,
      item.vehicle,
      item.startOdom,
      item.endOdom,
      item.total,
    ];

    return apiRequest({
      url: 'vehicle.EquipmentMaster.UpdateOdometerEquipment',
      data,
    });
  }

  LoadTechnologistList() {
    return apiRequest({ url: 'order.Order.TechDropDown' });
  }
  loadOdometerValue(vehicleId) {
    return apiRequest({
      url: 'vehicle.EquipmentMaster.getOdometer',
      data: [vehicleId],
    });
  }
}
export default new EquipmentPageService();
