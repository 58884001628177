import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { PureInput } from 'components/form/textField';
import { Grid, GridControlButton } from 'components/grid';
import { IconButton } from 'components/button';
import { LayoutSideTitle } from 'components/layout';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';

import { storePrefix } from 'stores/_mobx/systemSetup/masterSetting/prefix';
import { MS_PREFIX as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_PREFIX_ADD,
  URL_PREFIX_EDIT,
} from 'constant/path/systemSetup';

const columns = [
  { name: 'prefix_nm', title: 'Prefix Name' },
  { name: 'prefix_desc', title: 'Prefix Description' },
  {
    name: 'refid',
    title: 'Action',
    render: (id: number) => (
      <div className="control">
        <Link to={URL_PREFIX_EDIT.replace(':id', `${id}`)}>
          <i className="bi bi-pencil" />
        </Link>
        <IconButton onClick={() => storePrefix.setPrefixForDelete(id)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const PrefixListPage = () => {
  const {
    fetching,
    prefixList,
    prefixTotal,
    prefixForDelete,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storePrefix;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleApproveDelete = () => {
    storePrefix.deletePrefix().then((isSucceed) => {
      if (isSucceed) storePrefix.getPrefixListMain();
    });
  };

  const handleChangeFilter = (name: string) => {
    storePrefix.setFilter(name);
    debouncedMethod();
  };

  useEffect(() => {
    storePrefix.getPrefixListMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle title="Prefix" appId={PAGE_ID.PAGE}>
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Prefix name"
        className="col-sm-4"
        value={filter}
        onChange={handleChangeFilter}
      />

      <Grid
        id="prefix_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={prefixList}
        dataSourceCount={prefixTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_PREFIX_ADD} />
        }
      />
      {prefixForDelete ? (
        <DialogConfirm
          onCancel={storePrefix.clearPrefixForDelete}
          onApprove={handleApproveDelete}>
          Do you really want to delete this Prefix?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(PrefixListPage);
