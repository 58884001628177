import React from 'react';
import AbstractFilter, { LIKE_CONDITIONS } from './AbstractFilter';

export default class AbstractLikeFilter extends AbstractFilter {

    CONDITIONS = LIKE_CONDITIONS;

    render() {
        return (
            <div className={`row ${this.className}`}>
                {this.renderConditions()}
                {this.renderTextValue()}
            </div>
        )
    }
}