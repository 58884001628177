import { Container } from 'flux/utils';

import AbstractUserSettings, {
  PAbstractUserSettings,
  SAbstractUserSettings,
} from './AbstractUserSettings';
import UserSelectionStore from 'stores/project/UserSelectionStore';
import { COMPANY as PAGE_ID } from 'constant/pagesId/privileges';

export interface PCompanyUserPrivilegesPage extends PAbstractUserSettings {}

export class SCompanyUserPrivilegesPage extends SAbstractUserSettings {}

export class CompanyUserPrivilegesPage extends AbstractUserSettings<
  PCompanyUserPrivilegesPage,
  SCompanyUserPrivilegesPage
> {
  static getStores() {
    return AbstractUserSettings.getStores();
  }

  static calculateState(
    prevState: SCompanyUserPrivilegesPage,
    props: PCompanyUserPrivilegesPage
  ) {
    return AbstractUserSettings.calculateState(prevState, props);
  }

  title = 'Company User Settings';

  hasViewLogAccess() {
    return true;
  }

  hasSavePermissionsAccess() {
    return true;
  }

  getUserTypeOptions() {
    if (UserSelectionStore.isUserTypesLoaded()) {
      return this.state.userTypeList.filter((v) => {
        if (v.value === '0') {
          return true;
        }
        if (v.value === 8) {
          return this.props.userType === 8;
        }
        return UserSelectionStore.isTech(v.value);
      });
    }
    return [];
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }
}

export default Container.create(CompanyUserPrivilegesPage, { withProps: true });
