import { useCallback, useLayoutEffect, useState } from 'react';

import { throttle } from './helpers';

const minGridHeightWithPagination = 238;

const gridPaginationWithMarginBottom = 67;

const getWindowHeight = () => window.innerHeight;

const calculateGridHeight = (gridWrapper: HTMLDivElement | null) => {
  const windowHeight = getWindowHeight();

  const distanceFromTopToGrid =
    Math.round(gridWrapper?.getBoundingClientRect().y) || 0;

  const workspaceHeight =
    windowHeight - distanceFromTopToGrid - gridPaginationWithMarginBottom;

  const gridHeight =
    workspaceHeight >= minGridHeightWithPagination
      ? workspaceHeight
      : minGridHeightWithPagination;

  return gridHeight;
};

interface PropsType {
  defaultHeight?: number;
  gridWrapperRef: React.MutableRefObject<HTMLDivElement>;
}

const useWorkspaceHeight = ({
  defaultHeight,
  gridWrapperRef,
}: PropsType): number => {
  const [height, setHeight] = useState<number>(
    defaultHeight || calculateGridHeight(gridWrapperRef.current)
  );

  const handleChangeHeight = useCallback(() => {
    const gridHeight = calculateGridHeight(gridWrapperRef.current);

    setHeight(gridHeight);
  }, [gridWrapperRef]);

  useLayoutEffect(() => {
    const throttledListener = throttle(handleChangeHeight);

    if (!defaultHeight) {
      window.addEventListener('resize', throttledListener);
    }

    return () => window.removeEventListener('resize', throttledListener);
  }, [defaultHeight, handleChangeHeight]);

  useLayoutEffect(() => {
    let observer: ResizeObserver = null;

    if (!defaultHeight) {
      const throttledListener = throttle(handleChangeHeight, 100);

      observer = new ResizeObserver(throttledListener);

      observer.observe(document.body);
    }

    return () => observer?.disconnect();
  }, [defaultHeight, handleChangeHeight]);

  return height;
};

export default useWorkspaceHeight;
