import React from 'react';
import { NavLink } from 'react-router-dom';

interface SidebarLinkProps {
  appName: string;
  url: string;
  onClick?: (e: React.MouseEvent) => void;
}

const SidebarLink = ({ appName, url, onClick }: SidebarLinkProps) => (
  <li key={appName}>
    <NavLink
      to={url.replace(/-{2,}/, '-')} // TODO: remove this, when url will fixed '/report-creator/tech---patient'
      className="submenu-link"
      activeClassName="active"
      onClick={onClick}
      data-testid={appName}>
      {appName}
    </NavLink>
  </li>
);

export default SidebarLink;
