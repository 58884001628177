import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from 'components/project/dropdown/AbstractDropdown';
import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';

import PhysicianActions from 'actions/project/PhysicianActions';
import PhysicianStore from 'stores/project/PhysicianStore';
import { storePhysician } from 'stores/_mobx/clinicianManager/physician';

export interface PPhysicianDropdown extends PAbstractDropdown {}

export class SPhysicianDropdown extends SAbstractDropdown {}

export default class PhysicianDropdown<
  P extends PPhysicianDropdown,
  S extends SPhysicianDropdown
> extends AbstractDropdown<P, S> {
  getStore() {
    return PhysicianStore;
  }

  loadOptions() {
    return AbstractDropdown.adaptPromise(PhysicianActions.loadPhysicians());
  }

  getOptions() {
    return AbstractDropdown.adaptArr(PhysicianStore.getState().options)
      .sort((a: any, b: any) => a.label.localeCompare(b.label))
      .filter(({ label }: any) => !!label.trim());
  }

  static findOption(id: string) {
    return PhysicianStore.findOption(id);
  }
}

export class APhysicianDropdown extends PhysicianDropdown<
  PPhysicianDropdown,
  SPhysicianDropdown
> {}

export const DropDownPhysician = observer(
  (props: Omit<SelectPropsType, 'options'>) => {
    const { fetching, options } = storePhysician;

    useEffect(() => {
      storePhysician.getOptions();
    }, []);

    return <Select {...props} options={options} isLoading={fetching} />;
  }
);
