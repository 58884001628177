export class TPagination {
    skip: number = 0;
    page: number = 1;
    pageSize: number = 10;

    constructor(pageSize: number = 10, skip: number = 0, page: number = 1) {
        this.skip = skip;
        this.page = page;
        this.pageSize = pageSize;
    }
}
