import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validation = yupResolver(
  yup.object().shape({
    type_name: yup.string().required('Please, enter the document type!'),
    shrt_code: yup.string().required('Please, enter the exam type!'),
  })
);

export default validation;
