import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import { IconButton } from 'components/button';
import DialogConfirm from 'components/modal/dialogConfirm';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';

import { storeProviderType } from 'stores/_mobx/systemSetup/masterSetting/providerType';
import { MS_PROVIDER_TYPE as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_PROVIDER_TYPE,
  URL_PROVIDER_TYPE_ADD,
} from 'constant/path/systemSetup';

const columns = [
  {
    name: 'facilitytype',
    title: 'Provider type',
  },
  {
    name: 'facilitytype_desc',
    title: 'Description',
  },
  {
    name: 'refid',
    title: 'Action',
    className: 'width-100',
    render: (id: number) => (
      <div className="control">
        <Link to={`${URL_PROVIDER_TYPE}/edit/${id}`}>
          <i className="bi bi-pencil" />
        </Link>
        <IconButton onClick={() => storeProviderType.setIdForDelete(id)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const ProviderTypeListPage = () => {
  const {
    fetching,
    providerTypeList,
    providerTypeTotal,
    idForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeProviderType;

  const [typeName, setTypeName] = useState<string>('');

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleChangeTypeName = (name: string) => {
    setTypeName(name);
    debouncedMethod();
  };

  const handleApproveDelete = () => {
    storeProviderType.deleteProviderType().then((isSucceed) => {
      if (isSucceed) {
        storeProviderType.getProviderTypesMain(typeName);
      }
    });
  };

  useEffect(() => {
    storeProviderType.getProviderTypesMain(typeName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Provider Type">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Provider type"
        className="col-sm-12 col-md-6 col-lg-4"
        value={typeName}
        onChange={handleChangeTypeName}
      />

      <Grid
        id="email_group_grid"
        onAjax={fetching}
        columns={columns}
        dataSource={providerTypeList}
        dataSourceCount={providerTypeTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_PROVIDER_TYPE_ADD} />
        }
      />
      {idForDelete ? (
        <DialogConfirm
          onCancel={storeProviderType.clearIdForDelete}
          onApprove={handleApproveDelete}>
          Do you really want to delete this Provider Type?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(ProviderTypeListPage);
