import { memo } from 'react';

import { ProfileDetailsType } from 'stores/_mobx/profile/myProfile';

interface PropsType {
  details: ProfileDetailsType;
}

const EmployerInfo = ({ details }: PropsType) => (
  <div className="row mb-5">
    <div className="col-md-6">
      <dl className="row">
        <dt className="col-sm-6 col-lg-4">Region</dt>
        <dd className="col-sm-6 col-lg-8">{details?.region}</dd>

        <dt className="col-sm-6 col-lg-4">Emergency phone</dt>
        <dd className="col-sm-6 col-lg-8">{details?.emergency_phone}</dd>

        <dt className="col-sm-6 col-lg-4">City</dt>
        <dd className="col-sm-6 col-lg-8">{details?.officecity}</dd>

        <dt className="col-sm-6 col-lg-4">Zip Code</dt>
        <dd className="col-sm-6 col-lg-8">{details?.officezipcode}</dd>
      </dl>
    </div>

    <div className="col-md-6">
      <dl className="row">
        <dt className="col-sm-6 col-lg-4">Facility</dt>
        <dd className="col-sm-6 col-lg-8">{details?.facilityName}</dd>

        <dt className="col-sm-6 col-lg-4">Address</dt>
        <dd className="col-sm-6 col-lg-8">{details?.office_address}</dd>

        <dt className="col-sm-6 col-lg-4">State</dt>
        <dd className="col-sm-6 col-lg-8">{details?.officestate}</dd>

        <dt className="col-sm-6 col-lg-4">Phone</dt>
        <dd className="col-sm-6 col-lg-8">{details?.work_phone}</dd>
      </dl>
    </div>
  </div>
);

export default memo(EmployerInfo);
