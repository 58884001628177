import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid, GridControlButton } from 'components/grid';
import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import Filter from './components/Filter';
import Export from '../Export';

import {
  storeReportFacilityContact,
  defaultFilter,
} from 'stores/_mobx/report/facilityContact';
import UserProfileStore from 'stores/UserProfileStore';
import { FACILITY_CONTACT_REPORT as PAGE_ID } from 'constant/pagesId/systemSetup';

const columns = [
  { name: 'contact_lname', title: 'Contact Last Name' },
  { name: 'contact_fname', title: 'Contact First Name' },
  { name: 'facility', title: 'Facility' },
  { name: 'phone', title: 'Phone' },
  { name: 'email', title: 'Email' },
  { name: 'address', title: 'Address' },
  { name: 'city', title: 'City' },
  { name: 'state', title: 'State' },
  { name: 'zipcode', title: 'Zip Code' },
  { name: 'fax', title: 'Fax' },
];

const FacilityContact = () => {
  const {
    fetching,
    facilityContact,
    facilityContactTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeReportFacilityContact;

  const gridRef = useRef(null);

  const [isFilterVisible, toggleVisibility] = useState<boolean>(false);

  const [isExportDialogVisible, toggleExportDialog] = useState<boolean>(false);

  const handleToggleFilter = () => {
    toggleVisibility((state) => !state);
  };

  const handleToggleExportDialog = () => {
    toggleExportDialog((state) => !state);
  };

  const handleReset = () => {
    storeReportFacilityContact.setFilterDefault();
    storeReportFacilityContact.getFacilityContact(defaultFilter);
  };

  useEffect(() => {
    storeReportFacilityContact.getFacilityContact();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Facility Contact Export">
        <>
          <Button
            variant="default"
            text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
            onClick={handleToggleFilter}
          />
          {isFilterVisible && (
            <>
              <Button
                text="Reset"
                variant="warning"
                disabled={fetching}
                onClick={handleReset}
              />
              <Button
                text="Apply"
                disabled={fetching}
                onClick={setPaginationToStart}
              />
            </>
          )}
        </>
      </LayoutSideTitle>

      <Filter visibility={isFilterVisible} onSubmit={setPaginationToStart} />

      <Grid
        id="facility_contact_report_gridId"
        noDataText="Please refine your search using the filters above"
        gridControlPanel={
          <GridControlButton
            title="Export"
            onClick={handleToggleExportDialog}
          />
        }
        columns={columns}
        ref={gridRef}
        onAjax={fetching}
        dataSource={facilityContact}
        dataSourceCount={facilityContactTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
      {isExportDialogVisible && (
        // @ts-ignore
        <Export
          callback={handleToggleExportDialog}
          reportName={UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) || null}
          exporterName="FacilityContactExporter"
          filter={filter}
          grid={gridRef.current}
          columns={columns}
        />
      )}
    </>
  );
};

export default observer(FacilityContact);
