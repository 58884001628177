import { makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionResponseType {
  code: string;
  data: string;
  desc: string;
  label: string;
}

export interface OptionType {
  value: number;
  label: string;
}

export class DropdownPlaceOfService {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,
    });
  }

  async getOptions() {
    if (this.fetching) return Promise.resolve();
    try {
      runInAction(() => {
        this.fetching = true;
      });
      const list = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.POS.POSDropDown',
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));
      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }

  findOption = (id: number) => {
    if (!id) return null;

    if (!this.options.length && !this.fetching)
      this.getOptions().then(() => {
        return this.options.find(({ value }) => id === value);
      });

    return this.options.find(({ value }) => id === value);
  };
}

export const storeDropdownPlaceOfService = new DropdownPlaceOfService();
