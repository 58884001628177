import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Text from 'components/form/input/Text';
import Form from 'components/form/Form';
import FormControl from 'components/form/FormControl';
import Fieldset from 'components/form/Fieldset';
import { Grid } from 'components/grid';
import { IconButton } from 'components/button';
import FileUpload from 'components/form/input/FileUpload';

import { BASE_URL_FILE_DIR } from 'constant/config';
import { URL_FACILITY } from 'constant/path/systemSetup';

export interface PDocumentTab {
  blocked: boolean;
  documentList: Array<any>;
  submitDocumentTab: (documentList: Array<any>) => void;
  id: number;
  backToList: () => void;
  history: RouteComponentProps['history'];
}

export class SDocumentTab {
  errors: any = {};
  saved: boolean = false;
  path: string = `${BASE_URL_FILE_DIR}/doc_img/facility`;
  documentList: Array<any> = [];
  isDocumentListChanged: boolean = false;
}

export default class DocumentTab extends React.Component<
  PDocumentTab,
  SDocumentTab
> {
  constructor(props: PDocumentTab) {
    super(props);
    this.state = Object.assign(new SDocumentTab(), {
      errors: {},
      saved: false,
      path: `${BASE_URL_FILE_DIR}doc_img/facility`,
      documentList: this.props.documentList,
      isDocumentListChanged: false
    });
  }

  updateTabState(state: SDocumentTab) {
    this.setState(state);
  }

  getColumns() {
    const textFieldCallback = (row: any, name: any, value: any) => {
      const documentList = this.state.documentList.map((listItem: any) => (
        {
          ...listItem,
          desc: listItem.refid === row.refid ? value : listItem.desc
        }
      ));
      const isDocumentListChanged = this.props.documentList
        .find((item: any) => item.refid === row.refid)?.desc !== value;

      this.setState({ documentList, isDocumentListChanged });
    };
    return [
      {
        name: 'filenm',
        title: 'File name',
      },
      {
        name: 'desc',
        title: 'Description',
        render: (rowItem: any, row: any) => {
          return (
            <Text
              name="desc"
              noLabel
              value={rowItem}
              onSetValue={(name, value) => {
                textFieldCallback(row, name, value);
              }}
            />
          );
        },
      },
      {
        name: 'view',
        title: 'View',
        render: (rowItem: any, row: any) => {
          if (!row['filenm']) {
            return null;
          }
          const fname = row['filenm'];
          const path = (row.path ? row.path : this.state.path) + '/' + fname;
          return (
            <a title={rowItem} href={path} target="_blank" rel="noreferrer">
              <i className="icon icon-view" />
            </a>
          );
        },
      },
      {
        name: 'remove',
        title: 'Remove',
        render: (rowItem: any, row: any) => {
          return (
            <IconButton
              title={rowItem}
              className="text-primary"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                const documentList: any[] = this.state.documentList.filter((document) => document.refid !== row.refid);
                const isSavedDocumentListItemRemoved = documentList.filter(doc => doc.hasOwnProperty('uploaded_dt')).length !== this.props.documentList.length;
                const isDocumentListChanged = documentList.some(doc => doc.filestatus === 'N') || isSavedDocumentListItemRemoved;
                this.setState({ documentList, isDocumentListChanged });
              }}>
              <i className="bi bi-trash" />
            </IconButton>
          );
        },
      },
    ];
  }

  updateImagePath(name: string, value: string) {
    const documentList = [...this.state.documentList];
    let max = 0;
    documentList.forEach((item) => {
      if (item.refid > max) {
        max = item.refid;
      }
    });
    max++;
    documentList.push({
      refid: max,
      filenm: value,
      desc: '',
      path: `${BASE_URL_FILE_DIR}tempfolder`,
      filestatus: 'N',
    });
    this.setState({ documentList, isDocumentListChanged: true });
  }

  render() {
    return (
      <div className="document-tab position-relative">
        {this.props.blocked ? <div className="overlay"></div> : null}
        <Form
          model={this.state.documentList}
          onCollectValues={this.onCollectValues.bind(this)}
          submit={this.handleSubmit}
          errors={this.state.errors}
          className="row">
          <FileUpload
            onSetValue={this.updateImagePath.bind(this)}
            buttonLabel="Upload file"
            noLabel
          />
          <Fieldset className="row">
            <Grid
              columns={this.getColumns()}
              dataSource={this.state.documentList}
              dataSourceCount={this.state.documentList.length}
              disablePagination
              noControl
            />
          </Fieldset>
          <FormControl>
            <input
              type="button"
              className="btn btn-warning"
              value="Reset"
              onClick={this.reset.bind(this)}
              disabled={!this.state.isDocumentListChanged}
            />
            <input
              type="submit"
              className="btn btn-primary"
              value="Submit"
              disabled={!this.state.isDocumentListChanged}
            />
          </FormControl>
        </Form>
      </div>
    );
  }

  handleSubmit = (model: any, hasErrors: any, errors: any) => {
    if (hasErrors) {
      let state = {
        documentList: { ...this.state.documentList },
        errors: { ...this.state.errors },
        isDocumentListChanged: this.state.isDocumentListChanged,
      };
      if (errors !== null) {
        for (let key in model) {
          state.errors[key] = errors[key];
        }
      }
      this.setState(state);
    } else {
      this.props.submitDocumentTab(this.state.documentList);
    }
  };

  onCollectValues(
    name: string,
    value: string,
    errorMessages: any,
    errorKeys: any,
    event: Event
  ) {
    const state: {
      documentList: any;
      errors: any;
    } = {
      documentList: [...this.state.documentList],
      errors: { ...this.state.errors },
    };
    state.documentList[name] = value;
    state.errors[name] = errorMessages;
    this.setState(state);
  }

  reset() {
    this.setState({
      documentList: [ ...this.props.documentList ],
      isDocumentListChanged: false,
    });
  }

  goToOverview() {
    this.props.history.push(URL_FACILITY);
  }
}
