import { useForm } from 'react-hook-form';

import mergedBillingOptions from 'page/billing-coding/MergedBillingOptions';
import { DropDownExamType } from 'components/project/dropdown/ExamTypeDropdown';
import DropDownTechnologist from 'components/project/dropdown/TechnologistDropDown';
import { DropDownFacilityRole } from 'components/project/dropdown/FacilityRolesDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropdownLocation } from 'components/project/dropdown/LocationDropdown';
import { DropdownRadiology } from 'components/project/dropdown/RadiologyDropdown';
import { DropDownOrderType } from 'components/project/dropdown/OrderType';
import { DropdownProviderType } from 'components/project/dropdown/ProviderTypeDropdown';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { SelectCptCategory } from 'components/project/dropdown/CptCategoryDropdown';
import { DropdownPayerName } from 'components/project/dropdown/PayerNameDropdown';
import { DropdownDivision } from 'components/project/dropdown/Division';
import { Button } from 'components/button';
import { InputCalendar } from 'components/form/inputCalendar';
import MaskInput from 'components/form/maskInput';
import { Input } from 'components/form/textField';
import { Radio } from 'components/form/radio';
import { Select } from 'components/form/select';
import { DateRange } from 'components/form/dateRange';
import { ControlsLayout } from 'components/layout';

import { storeCoding, FilterType } from 'stores/_mobx/billingCodding/coding';
import { OPTIONS_MEDIA_FULL } from 'constant/formComponentOptions';

interface PropsType {
  isVisible: boolean;
  fetching: boolean;
  onSubmit: (data: FilterType) => void;
}

const className = 'col-md-6 col-lg-3';

export const MEDIA_OPTIONS = [
  { value: 'DX', label: 'DX' },
  { value: 'CR', label: 'CR' },
  { value: 'RG', label: 'RG (Film)' },
  { value: 'not_defined', label: 'Not Defined' },
  {
    value: '',
    label: 'ALL',
  },
];

const CODING_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'N', label: 'New' },
  { value: 'I', label: 'Incomplete' },
  { value: 'Y', label: 'Completed' },
];

const FINDINGS_OPTIONS = [
  { value: '', label: 'Both' },
  { value: 'Y', label: 'With' },
  { value: 'N', label: 'Without' },
];

const Filter = ({ isVisible, fetching, onSubmit }: PropsType) => {
  const { control, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeCoding.filter,
  });

  const handleClickReset = () => {
    const filter = storeCoding.getDefaultFilter();

    reset(filter);
    onSubmit(filter);
  };

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="lastName"
        label="Last name"
        className={className}
        control={control}
      />
      <Input
        name="firstName"
        label="First name"
        className={className}
        control={control}
      />
      <DropDownExamType
        name="examType"
        label="Modality"
        optionsForOrderOnly
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropDownTechnologist
        name="technologistId"
        label="Technologist"
        className={className}
        control={control}
      />

      <div />

      <DropDownFacilityRole
        name="facility"
        label="Facility"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropDownState
        name="state"
        label="State"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownLocation
        name="location"
        label="Location"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownDivision
        name="division"
        label="Division"
        className={className}
        control={control}
      />
      <DropdownRadiology
        name="radiologyGroup"
        label="Radiology group"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropDownOrderType
        name="orderType"
        label="Order type"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <MaskInput
        name="ssn"
        label="SSN"
        format="###-##-####"
        className={className}
        control={control}
      />
      <InputCalendar
        name="dob"
        label="DOB"
        className={className}
        control={control}
      />
      <DropdownProviderType
        name="providerType"
        label="Provider type"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropDownPlaceOfService
        name="pos"
        label="Place of Service"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <SelectCptCategory
        name="cptCategoryId"
        label="CPT Category"
        className={className}
        control={control}
      />
      <Select
        name="mediaType"
        label="Media"
        options={OPTIONS_MEDIA_FULL}
        className={className}
        control={control}
      />
      <DropdownPayerName
        name="payer"
        label="Primary Payer"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <Select
        name="assignment"
        label="Assignment"
        options={mergedBillingOptions}
        className={className}
        control={control}
      />
      <Radio
        name="finding"
        label="Finding"
        className="col-md-6 col-lg-3 part-inline"
        options={FINDINGS_OPTIONS}
        control={control}
      />
      <Radio
        name="codingStatus"
        label="Status"
        className="col-md-6 col-lg-3 part-inline"
        options={CODING_OPTIONS}
        control={control}
      />
      <div />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-6 col-xl-5"
        control={control}
      />

      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button
          text="Apply"
          type="submit"
          data-testid="submit"
          disabled={fetching}
        />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
