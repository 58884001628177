import { action, makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionResponseType {
  data: string;
  label: string;
  table: string;
}

export interface OptionType {
  value: string;
  label: string;
}

class DropdownPrivilegeEquivalence {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,

      setFetching: action,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  getOptions = async (id: number = 0) => {
    if (this.fetching) return Promise.resolve([]);

    this.setFetching(true);

    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.Designation.LoadDropdownUserType',
        data: [id],
      });

      const options = response.map(({ data, label }) => ({
        value: data,
        label,
      }));

      runInAction(() => {
        this.options = options;
        this.fetching = false;
      });
    } catch (error) {
      runInAction(() => {
        this.options = [];
        this.fetching = false;
      });
    }
  };
}

export const storeDropdownPrivilegeEquivalence =
  new DropdownPrivilegeEquivalence();
