import { ReduceStore } from 'flux/utils';

export class SAbstractMessagesStore {
    data: Array<any> = [];
    count: number = 0;
    filter: string = '';
}

export abstract class AbstractMessagesStore<TState extends SAbstractMessagesStore, TPayload> extends ReduceStore<TState, TPayload> {

    getInitialState() {
        return new SAbstractMessagesStore() as TState;
    }

    areEqual(one: TState, two: TState): boolean {
        return false;
    }
}
