import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormCode, { FormModel } from './components/Form';

import { storeQ0092 } from 'stores/_mobx/systemSetup/masterSetting/q0092';
import { URL_Q0092 } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const Q0092AddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: FormModel) =>
    storeQ0092.addCode(payload).then((response) => {
      if (response) return response;
      history.push(URL_Q0092);
    });

  return (
    <>
      <Title title="Add Q0092" />
      <FormCode backUrl={URL_Q0092} onSubmit={handleSubmit} />
    </>
  );
};

export default Q0092AddPage;
