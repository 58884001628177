import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Grid, GridControlButton } from 'components/grid';
import { IconButton } from 'components/button';
import { LayoutSideTitle } from 'components/layout';
import DialogConfirm from 'components/modal/dialogConfirm';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';

import { storeSuffix } from 'stores/_mobx/systemSetup/masterSetting/suffix';
import { MS_SUFFIX as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_SUFFIX_ADD,
  URL_SUFFIX,
} from 'constant/path/systemSetup';

const getControls = (id: number) => (
  <div className="control">
    <Link to={`${URL_SUFFIX}/edit/${id}`}>
      <i className="bi bi-pencil" />
    </Link>
    <IconButton onClick={() => storeSuffix.setSuffixForDelete(id)}>
      <i className="bi bi-trash" />
    </IconButton>
  </div>
);

const columns = [
  { name: 'name', title: 'Name' },
  { name: 'description', title: 'Description' },
  {
    name: 'refid',
    title: 'Action',
    className: 'width-100',
    render: getControls,
  },
];

const SuffixListPage = () => {
  const {
    fetching,
    suffixList,
    suffixTotal,
    idForDelete,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeSuffix;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleApproveDelete = () => {
    storeSuffix.deleteSuffix().then((isSucceed) => {
      if (isSucceed) storeSuffix.getSuffixList();
    });
  };

  const handleChangeFilter = (suffixName: string) => {
    storeSuffix.setFilter(suffixName);

    debouncedMethod(suffixName);
  };

  useEffect(() => {
    storeSuffix.getSuffixList();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Suffix">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Suffix name"
        className="col-sm-12 col-md-6 col-lg-4"
        value={filter}
        onChange={handleChangeFilter}
      />
      <Grid
        id="suffix_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={suffixList}
        dataSourceCount={suffixTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_SUFFIX_ADD} />
        }
      />
      {idForDelete !== null && (
        <DialogConfirm
          onCancel={storeSuffix.clearSuffixForDelete}
          onApprove={handleApproveDelete}>
          Do you really want to delete the item?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(SuffixListPage);
