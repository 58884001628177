import { apiRequest } from 'services/RequestService';
import { TPagination } from 'components/grid/GridTypes';

export class TClaimManagerFilter {
  corporateId: number = null;
  facility: number = null;
  lastName: string = '';
  firstName: string = '';
  examType: string = '';
  pos: string = '';
  location: string = '';
  state: string = '';
  dosStart: string = '';
  dosEnd: string = '';
  radiologyId: number = null;
  orderType: string = '';
  providerType: string = '';
  type: 'T' | 'P' | '' = '';
  tcAssignment: string = '';
  inJurisdiction: string = '';
  isSigned: string = '';
  cpt: string = '';
  payerName: string = '';
  division: number = 0;
}

export class TClaimManagerOutFilterBase {
  corporateId: number = null;
  facilityId: number = null;
  lastName: string = '';
  firstName: string = '';
  examType: string = '';
  pos: string = '';
  location: string = '';
  state: string = '';
  fromdt: string = '';
  todate: string = '';
  dosSearch: string = '';
  technicalClaimsType: '' | 'T' = '';
  batchStatus: 'B' | 'U' = 'U';
  radiologyId: number = null;
  billProcess: string = '';
  orderType: string = '';
  providerType: string = '';
  claimType: string = '';
  claimAudit: string = '';
  batchId: string = '';
  batchDate: string = '';
  tcAssignment: string = '';
  inJurisdiction: string = '';
  isSigned: string = '';
  cpt: string = '';
  payerName: string = '';
  claimReleaseDate: string = '';
  claimReleaseDateFrom: string = '';
  claimReleaseDateTo: string = '';
  claimBatch: string = '';
  releaseStatus: string = '';
}

export class TClaimManagerOutFilterWithPageSkip {
  skip: number = 0;
  pageSize: number = 10;
}

export class TClaimManager {
  refid: number = null;
  orderid: number = null;
  claimUniqId: string = '';
  radiologistIdsList: string = '';
  release_status: string = '';
  dos: string = '';
  dob: string = '';
  patientFirstNm: string = '';
  patientLastNm: string = '';
  pos_override: string = '';
  first_name: string = '';
  last_name: string = '';
  modality: string = '';
  facility_type: string = '';
  facility_name: string = '';
  order_based: string = '';
  fac_bill_phone: string = '';
  inmate_number: string = '';
  immigration_id: number = null;
  ice_number: string = '';
  facilityphone: string = '';
  pos: string = '';
  location: string = '';
  bill_id: number = null;
  state: string = '';
  facilityid: number = null;
  npi: string = '';
  patientid: number = null;
  providerid: number = null;
  radiologistNames: string = null;
  ssn: string = '';
  medicare_id: number = null;
  medicaid_id: number = null;
  medicaid_stateid: number = null;
  face_sheet: string = '';
  name: string = '';
  pri_groupno: string = '';
  sec_groupno: string = '';
  ter_groupno: string = '';
  ins_notes: string = '';
  doctor_pop_img: string = '';
  doctor_pop_tooltip: string = '';
  facImg: string = '';
  facTooltip: string = '';
  zipImg: string = '';
  zipTooltip: string = '';
  posImg: string = '';
  posTooltip: string = '';
  insImg: string = '';
  insTooltip: string = '';
  dobImg: string = '';
  dobTooltip: string = '';
  hasLog: boolean = false;
}

export class TExamCodeDetail {
  pos: string = '';
  modality: string = '';
  facility_name: string = '';
  dos: string = '';
  firstname: string = '';
  lastname: string = '';
  codingstatus: string = '';
  comment: string = '';
  facilitytype: string = '';
  dob: string = '';
  qrcode: any = null;
  othercpt: any = null;
}

export class TRadiologist {
  radiologistid: number = null;
  radiologist_name: string = '';
  radiologist_lname: string = '';
  radiologist_fname: string = '';
  radiologist_npi: string = '';
  radiologist_state: string = '';
  radiologist_date: string = '';
  rendering_pecos: string = '';
  rendering_physician_id: number = null;
}

export class TOrderDetails {
  first_name: string = '';
  last_name: string = '';
  pos_override: string = '';
  dob: string = '';
  room: string = '';
  priority: string = '';
  priority_name: string = '';
  patientid: number = null;
  radiologistNames: string = '';
  radiologists: Array<TRadiologist> = [];
  phoned_time: string = '';
  batch_no: number = null;
  release_dt: string = '';
  created_by: string = '';
  given_by: string = '';
  cpt_desc: string = '';
  cptmodifier: string = '';
  icdmodifier: string = '';
  icddesc: string = '';
  modality: string = '';
  ordertype: 'Facility' | 'Correctional' | 'Occupational' | 'HomeCare' = null;
  accession: string = '';
  number_of_patient: string = '';
  facility_name: string = '';
  csz: string = '';
  facaddress: string = '';
  modality_name: string = '';
  pos_name: string = '';
  ordering_physician_name: string = '';
  ordering_physician_lname: string = '';
  ordering_physician_fname: string = '';
  ordering_physician_npi: string = '';
  ordering_physician_id: number = null;
  ordering_pecos: string = '';
  ordering_validity_date: string = '';
  ordering_state: string = '';
  primary_payer_name: string = '';
  primary_plan_name: string = '';
  secondary_payer_name: string = '';
  secondary_plan_name: string = '';
  teritary_payer_name: string = '';
  teritary_plan_name: string = '';
  claimnotes: string = '';
  completion_date: string = '';
  assigned_date: string = '';
  radiology_group: string = '';
  faxed_time: string = '';
  order_recieved: string = '';
  primary_provider_name: string = '';
  technologist: string = '';
  juridictionid: number = null;
  modalityid: number = null;
  provider_npi: string = '';
  j_refid: number = null;
  provider_name: string = '';
  short_state: string = '';

  // these 3 looks outdated from server side
  billing_npi: string = '';
  billing_pecos: string = '';
  billing_state: string = '';
}

class ClaimsService {
  loadOverview(
    filter: TClaimManagerFilter,
    pagination: TPagination,
    dosSearch: string,
    batchStatus: string,
    billProcess: string,
    claimType: string,
    claimAudit: string,
    batchId: string,
    batchDate: string | { dateFrom: string; dateTo: string },
    inJurisdiction: string
  ) {
    const data = ClaimsService.addFilter(
      filter,
      dosSearch,
      batchStatus,
      billProcess,
      claimType,
      claimAudit,
      batchId,
      batchDate,
      inJurisdiction,
      pagination
    );

    return apiRequest<'SE' | 0 | Array<TClaimManager>>({
      url: 'facility.ClaimManager.ClaimManagerList',
      data,
    });
  }

  static addPagination(
    pagination: TPagination
  ): TClaimManagerOutFilterWithPageSkip & TClaimManagerOutFilterBase {
    return Object.assign(
      new TClaimManagerOutFilterWithPageSkip(),
      {
        skip: pagination.skip,
        pageSize: pagination.pageSize,
      },
      new TClaimManagerOutFilterBase()
    );
  }

  static addFilter(
    filter: TClaimManagerFilter,
    dosSearch: string,
    batchStatus: string,
    billProcess: string,
    claimType: string,
    claimAudit: string,
    batchId: string,
    batchDate: string | { dateFrom: string; dateTo: string },
    inJurisdiction: string,
    pagination?: TPagination
  ) {
    const filterPayload = {
      ...(pagination
        ? { skip: pagination.skip, pageSize: pagination.pageSize }
        : {}),
      corporateId: filter.corporateId || 0,
      facilityId: filter.facility,
      lastName: filter.lastName,
      firstName: filter.firstName,
      examType: filter.examType,
      pos: filter.pos,
      location: filter.location,
      state: filter.state,
      fromdt: filter.dosStart,
      todate: filter.dosEnd,
      dosSearch,
      technicalClaimsType: claimType === 'P' ? '' : 'T',
      batchStatus:
        batchStatus && (batchStatus === 'B' || batchStatus === 'U')
          ? batchStatus
          : 'U',
      radiologyId: filter.radiologyId,
      billProcess: billProcess || '',
      orderType: filter.orderType,
      providerType: filter.providerType,
      claimType:
        filter.type && (filter.type === 'T' || filter.type === 'P')
          ? filter.type
          : claimType,
      claimAudit,
      batchId,
      // @ts-ignore
      batchDateFrom: batchDate ? batchDate.dateFrom : '',
      // @ts-ignore
      batchDateTo: batchDate ? batchDate.dateTo : '',
      tcAssignment: filter.tcAssignment,
      inJurisdiction: filter.inJurisdiction,
      isSigned: filter.isSigned,
      cpt: filter.cpt,
      payerName: filter.payerName,
      division: filter.division,
    };
    return filterPayload;
  }

  loadOverviewCount(
    filter: TClaimManagerFilter,
    dosSearch: string,
    batchStatus: string,
    billProcess: string,
    claimType: string,
    claimAudit: string,
    batchId: string,
    batchDate: string | { dateFrom: string; dateTo: string },
    inJurisdiction: string
  ) {
    const data = ClaimsService.addFilter(
      filter,
      dosSearch,
      batchStatus,
      billProcess,
      claimType,
      claimAudit,
      batchId,
      batchDate,
      inJurisdiction
    );

    return apiRequest<'SE' | number>({
      url: 'facility.ClaimManager.ClaimManagerCount',
      data,
    });
  }

  getOrderDetails(claimUniqId: string) {
    return apiRequest<'SE' | 0 | Array<TOrderDetails>>({
      url: 'facility.FacilityMaster.GetOrderDetails',
      data: [claimUniqId],
    });
  }

  updateClaimDoctorDetails(
    ordupdate: 1 | 0,
    renderupdate: 1 | 0,
    billupdate: 1 | 0,
    ord_pecos: string,
    rend_pecos: string,
    billing_pecos: string,
    orderingnpi: string,
    renderingnpi: string,
    billingnpi: string,
    orderingstate: string,
    renderingstate: string,
    billingstate: string,
    ordering_id: number,
    rendering_ids: string,
    billing_id: number,
    j_refid: {
      j_npi_nm: string;
      j_modality: string;
      j_jurid: number;
      j_refid: number;
    }
  ) {
    const data = {
      ordupdate,
      renderupdate,
      billupdate,
      ord_pecos,
      rend_pecos,
      billing_pecos,
      orderingnpi,
      renderingnpi,
      billingnpi,
      orderingstate,
      renderingstate,
      billingstate,
      ordering_id,
      rendering_ids,
      billing_id,
      j_refid,
    };

    return apiRequest<void>({
      url: 'facility.FacilityMaster.UpdateClaimDoctorDetails',
      data,
    });
  }

  getExamCodeDetails(orderId: number) {
    return apiRequest<Array<TExamCodeDetail>>({
      url: 'facility.ClaimManager.GetExamCodeDetails',
      data: [orderId],
    });
  }

  saveCancelReason(
    orderId: number,
    reasons: string,
    cancelStatus: 'V' | 'N' | ''
  ) {
    const data = { orderId, reasons, cancelStatus };
    return apiRequest<'SE' | 'S'>({
      url: 'facility.ClaimManager.SaveCancelReason',
      data,
    });
  }

  moveTcPc(claimUniqIds: Array<string>, flag: 'T' | 'P' | '') {
    return apiRequest<void>({
      url: 'facility.ClaimManager.MoveClaimToTCPC',
      data: { claimUniqIds, flag },
    });
  }

  createBatch(claimUniqIds: Array<string>, flag: 'T' | 'P' | '') {
    return apiRequest<'SE' | 'S' | 'E'>({
      url: 'facility.ClaimManager.createBatch',
      data: { claimUniqIds, flag },
    });
  }

  revertPos(claimUniqIds: Array<string>) {
    return apiRequest<'SE' | 1>({
      url: 'facility.ClaimManager.revertPos',
      data: { claimUniqIds },
    });
  }
  assignPosTo31(claimUniqIds: Array<string>) {
    return apiRequest<'SE' | 1>({
      url: 'facility.ClaimManager.assignPosTo31',
      data: { claimUniqIds },
    });
  }
}

export class TClaimManagerForReleaseFilter extends TClaimManagerFilter {
  batchDate: string = '';
  batch: number = null;
}

export default new ClaimsService();
