import { ReduceStore } from 'flux/utils';
import appDispatcher from 'dispatcher/AppDispatcher';

let buildActionName = function (name) {
  return 'reports/report-creator/studies-by-tech/' + name;
};

export let Actions = {
  LOAD_STUDIES_BY_TECH_LIST: buildActionName('load-studies-by-tech-list'),
};

class StudiesByTechPageStore extends ReduceStore {
  getEmptyDiagram() {
    return [['Facility name', 'Patients']];
  }

  getInitialState() {
    return {
      studiesByTechList: [],
      studiesByTechDiagram: this.getEmptyDiagram(),
    };
  }

  reduce(state, action) {
    switch (action.type) {
      case Actions.LOAD_STUDIES_BY_TECH_LIST:
        this.getState().studiesByTechList = action.data.dataval || [];
        this.getState().studiesByTechDiagram =
          action.data.chartval || this.getEmptyDiagram();
        break;
      default:
        break;
    }
    return this.getState();
  }

  areEqual(one, two) {
    return false;
  }
}

const studiesByTechPageStore = new StudiesByTechPageStore(appDispatcher);
export default studiesByTechPageStore;
