import { Dispatcher } from 'flux';
import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

const buildActionName = (name: string) => {
  return 'place-order/' + name;
};

export const Actions = {
  INIT_TABS_ACTION: buildActionName('init-tabs-action'),
  UNBLOCK_ALL_TABS_ACTION: buildActionName('unblock-all-tabs-action'),
  UNBLOCK_TAB_ACTION: buildActionName('unblock-tab-action'),
};

export class POrderTabStateStore extends CommonPayload {
  tabNumber: number;
}

export class SOrderTabStateStore {
  unblockedTabs: [boolean, boolean, boolean, boolean] = [
    true,
    false,
    false,
    false,
  ];
}

class OrderTabStateStore extends ReduceStore<
  SOrderTabStateStore,
  POrderTabStateStore
> {
  getInitialState() {
    return new SOrderTabStateStore();
  }

  areEqual(state1: SOrderTabStateStore, state2: SOrderTabStateStore) {
    return false;
  }

  getTabState(tabNumber: number) {
    if (tabNumber >= 0 && tabNumber <= 3) {
      return this.getState().unblockedTabs[tabNumber];
    }
    return null;
  }

  reduce(state: SOrderTabStateStore, action: POrderTabStateStore) {
    switch (action.type) {
      case Actions.INIT_TABS_ACTION:
        this.getState().unblockedTabs = new SOrderTabStateStore().unblockedTabs;
        break;
      case Actions.UNBLOCK_ALL_TABS_ACTION:
        this.getState().unblockedTabs = [true, true, true, true];
        break;
      case Actions.UNBLOCK_TAB_ACTION:
        if (action.tabNumber >= 0 && action.tabNumber <= 3) {
          this.getState().unblockedTabs[action.tabNumber] = true;
        }
        break;
      default:
        break;
    }
    return state;
  }
}

export default new OrderTabStateStore(
  appDispatcher as Dispatcher<POrderTabStateStore>
);
