import React, { useState } from 'react';

import useRequest from 'components/hook/useRequest';
import CommonLink from './CommonLink';

import {
  exportInvoicePdf,
  exportInvoicePerDiemPdf,
  InvoicePdfType,
} from 'stores/_mobx/export';
import { downloadFile } from 'utils/downloadFile';
import { BASE_URL_FILE_DIR } from 'constant/config';

interface PropsType {
  invoiceDetail: InvoicePdfType;
  className?: string;
  perDiemType?: boolean;
}

const InvoicePdfExportLink = ({
  invoiceDetail,
  className = '',
  perDiemType = false,
}: PropsType) => {
  const [link, setLink] = useState<string>('/');

  const { fetching, controlledAction } = useRequest<InvoicePdfType>(
    perDiemType ? exportInvoicePerDiemPdf : exportInvoicePdf
  );

  const handleClick = (e: React.SyntheticEvent) => {
    if (link === '/') {
      e.preventDefault();
      controlledAction(invoiceDetail).then((url) => {
        if (url) {
          const link = `${BASE_URL_FILE_DIR}pdf-creator/${url}`;
          setLink(link);
          downloadFile(link, true);
        }
      });
    }
  };

  return (
    <CommonLink
      link={link}
      fetching={fetching}
      className={className}
      onClick={handleClick}
    />
  );
};

export default InvoicePdfExportLink;
