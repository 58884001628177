import PhysicianActions from 'actions/project/PhysicianActions';
import PhysicianDropdown, {
  PPhysicianDropdown,
  SPhysicianDropdown,
} from 'components/project/dropdown/PhysicianDropdown';
import { TDropDownItem } from 'actions/project/DropdownActions';
import AbstractDropdown from 'components/project/dropdown/AbstractDropdown';
import { OptionType } from 'components/form/input/Select';

export interface PPhysicianJoinedDropdown extends PPhysicianDropdown {
  groupId: number;
}

export class SPhysicianJoinedDropdown extends SPhysicianDropdown {
  physicians: Array<TDropDownItem> = [];
}

export default class PhysicianJoinedDropdown extends PhysicianDropdown<
  PPhysicianJoinedDropdown,
  SPhysicianJoinedDropdown
> {
  loadOptions(): Promise<Array<OptionType>> {
    const groupId = this.props.groupId;
    if (!groupId) {
      return Promise.resolve([]);
    }
    return PhysicianActions.loadPhysiciansForGroup(groupId).then(
      (response: Array<TDropDownItem>) => {
        this.setState({ physicians: response });
        return AbstractDropdown.adaptArr(response);
      }
    );
  }

  getOptions() {
    return AbstractDropdown.adaptArr(this.state.physicians);
  }

  componentDidUpdate(
    prevProps: PPhysicianJoinedDropdown,
    prevState: SPhysicianJoinedDropdown
  ) {
    if (
      (!prevProps.groupId &&
        this.props.groupId &&
        this.getOptions().length === 0) ||
      (prevProps.groupId && prevProps.groupId !== this.props.groupId)
    ) {
      this.loadOptions();
    }
  }
}
