import { Button } from 'components/button';
import { InputCalendar } from 'components/form/inputCalendar';
import { Input } from 'components/form/textField';
import { useForm } from 'react-hook-form';

import DropDownTechnologist from 'components/project/dropdown/TechnologistDropDown';
import {
  storeCheckOutHistory,
  initialValueFilter,
  FilterType,
} from 'stores/_mobx/workflow/checkOutIn/checkOutHistory';

interface PropsType {
  isVisible: boolean;
  fetching: boolean;
  onChange: (filter: FilterType) => void;
}

const Filter = ({ isVisible, fetching, onChange }: PropsType) => {
  const {
    control,
    formState: { isSubmitting, isDirty },
    reset,
    handleSubmit,
  } = useForm<FilterType>({ defaultValues: storeCheckOutHistory.filter });

  const handleClickReset = () => {
    reset(initialValueFilter);
    onChange(initialValueFilter);
  };

  return (
    <form
      className={isVisible ? '' : ' d-none'}
      onSubmit={handleSubmit(onChange)}>
      <div className="row">
        <Input
          name="vehicle"
          className="col-md-6 col-lg-3"
          label="Vehicle"
          control={control}
        />
        <Input
          name="ekg"
          className="col-md-6 col-lg-3"
          label="EKG"
          control={control}
        />
        <Input
          name="phone"
          className="col-md-6 col-lg-3"
          label="Phone"
          control={control}
        />

        <DropDownTechnologist
          name="technologistId"
          label="Technologist"
          className="col-md-6 col-lg-3"
          control={control}
        />
      </div>

      <div className="row">
        <InputCalendar
          name="checkOutDate"
          label="Check Out Date"
          className="col-md-6 col-lg-3"
          control={control}
        />
        <InputCalendar
          name="checkInDate"
          label="Check In Date"
          className="col-md-6 col-lg-3"
          control={control}
        />
        <div className="col-md-12 col-lg-6 d-flex justify-content-end align-items-center gap-3">
          <Button
            text="Reset"
            variant="warning"
            disabled={!isDirty || isSubmitting || fetching}
            onClick={handleClickReset}
          />
          <Button
            type="submit"
            text="Apply"
            disabled={isSubmitting || fetching}
          />
        </div>
      </div>
    </form>
  );
};

export default Filter;
