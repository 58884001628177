import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from 'flux/utils';

import DialogFormImportSchedule from 'page/system-setup/feeSchedules/facility/components/DialogFormImportSchedule';
import DialogChangingFeeSchedule from 'page/system-setup/components/DialogChangingFeeSchedule';
import Text from 'components/form/input/Text';
import NumberInput from 'components/form/input/NumberInput';
import DialogAlert from 'components/modal/DialogAlert';
import Form from 'components/form/Form';
import Radio from 'components/form/input/Radio';
import Checkbox from 'components/form/input/Checkbox';
import FormControl from 'components/form/FormControl';
import Fieldset from 'components/form/Fieldset';
import { Grid } from 'components/grid';
import { Button, IconButton } from 'components/button';
import PerDiemDropdown from 'components/project/dropdown/PerDiemDropdown';
import JurisdictionDropdown from 'components/project/dropdown/JurisdictionDropdown';
import PlaceOfServiceDropdown from 'components/project/dropdown/PlaceOfServiceDropdown';
import Notification from 'components/modal/Notification';

import FacilityPageActions from 'actions/system-setup/master-setup/facility/FacilityPageActions';
import FacilityPageStore from 'stores/system-setup/facility/FacilityPageStore';
import {
  storeFacilityFeeSchedule,
  ImportPayloadType,
} from 'stores/_mobx/systemSetup/feeSchedule/facility';
import { emailsUrlPrettier } from 'utils/emailValuesFormatter';
import { DIR_WITH_EXAMPLE } from 'constant/config';
import { URL_FACILITY } from 'constant/path/systemSetup';
import { PureInput } from 'components/form/textField';

export interface PBillingTab {
  blocked: boolean;
  facilityItem: any;
  billingItem: any;
  facilityId: number;
  serviceList: Array<any>;
  history: RouteComponentProps['history'];
  chargeUpdateOverview: () => void;
  submitBillingTab: (billingItem: any, serviceList: Array<any>) => void;
  backToList: () => void;
  refReplace: (ref: BillingTab) => void;
}

export class SBillingTab {
  serviceList: Array<any> = [];
  billingItem: { [key: string]: any } = {};
  errors: any = {};
  sameAsFacility: boolean = false;
  jurisdictionWarning: boolean = false;
  scheduleMode: 1 | 2 = 2;
  prevBillingItem: any = {};
  billingItemLoaded: boolean = false;
  cpt_info: any = null;
  groupid: any = null;
  onAjax: boolean = false;
  showFeePopup: boolean = false;
  feeImportDefaultValues: ImportPayloadType | null = null;
  locations: any = [];
  selectedRow: any;
}

export class BillingTab extends React.Component<PBillingTab, SBillingTab> {
  constructor(props: PBillingTab) {
    super(props);
    this.state = new SBillingTab();
    props.refReplace(this);
  }

  static getStores() {
    return [FacilityPageStore];
  }

  static calculateState(prevState: SBillingTab) {
    if (!prevState) {
      prevState = Object.assign(new SBillingTab(), {
        errors: {},
        billingItem: {},
        sameAsFacility: false,
        jurisdictionWarning: false,
        scheduleMode: 2,
        prevBillingItem: {},
        showFeePopup: false,
        billingItemLoaded: false,
        cpt_info: null, // can be updated after facility fee update
        groupid: null,
      });
    }
    let newState: SBillingTab;
    if (
      !prevState.billingItemLoaded &&
      FacilityPageStore.getState().billingItemLoaded
    ) {
      newState = Object.assign({
        billingItem: FacilityPageStore.getState().billingItem,
        billingItemLoaded: FacilityPageStore.getState().billingItemLoaded,
      });
    } else {
      newState = Object.assign({});
    }
    newState.serviceList = FacilityPageStore.getState().serviceList;
    const res = Object.assign(prevState, newState);
    return res;
  }

  updateBillingItem(billingItem: any) {
    this.setState({ billingItem });
  }

  isActive() {
    return true;
  }

  getColumns() {
    const textFieldCallback = (row: any, name: string, value: any) => {
      row[name] = value;
      this.forceUpdate();
    };
    return [
      {
        name: 'name',
        title: 'Service name',
      },
      {
        name: 'percent',
        title: 'Discount Percent',
        render: (rowItem: any, row: any) => (
          <Text
            name="percent"
            noLabel
            value={rowItem}
            onSetValue={(name, value) => {
              textFieldCallback(row, name, value);
            }}
          />
        ),
      },
    ];
  }

  onPerDiemChanged(name: string, value: any, i: number) {
    const billingItem = { ...this.state.billingItem };
    billingItem.perdiemarr[i][name] = value;
    this.setState({ billingItem });
  }

  onAddRowClicked = () => {
    const billingItem = { ...this.state.billingItem };
    billingItem.perdiemarr.push({ FullLead: 0, price: '' });
    this.setState({ billingItem });
  };

  onRemoveRowClicked = (i: number) => () => {
    const billingItem = { ...this.state.billingItem };
    billingItem.perdiemarr.splice(i, 1);
    this.setState({ billingItem });
  };

  renderPerDiemList() {
    const optionList = [];
    let perDiemList = this.state.billingItem.perdiemarr;
    if (!perDiemList || perDiemList.length === 0) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.billingItem.perdiemarr = [{ FullLead: 0, price: '' }];
      perDiemList = this.state.billingItem.perdiemarr;
    }
    const len = perDiemList.length;
    for (let i = 0; i < len; i++) {
      optionList.push(
        <Fieldset className="row" key={'' + i}>
          {i === 0
            ? [
                <Fieldset key="1" className="col-sm-4">
                  <PerDiemDropdown
                    name="FullLead"
                    label="Unit"
                    value={perDiemList[i].FullLead}
                    onSetValue={(name: string, value: any) => {
                      this.onPerDiemChanged(name, value, i);
                    }}
                  />
                </Fieldset>,

                <Fieldset key="2" className="col-sm-3">
                  <>
                    <PureInput
                      name={`price.${i}`}
                      label="Price"
                      type="number"
                      isChildrenPrefix
                      value={perDiemList[i].price}
                      onChange={(value) =>
                        this.onPerDiemChanged('price', value, i)
                      }>
                      <span className="input-group-text">$</span>
                    </PureInput>
                  </>
                </Fieldset>,
                <Fieldset key="3" className="col-sm-2">
                  {i === len - 1 && i < 2 ? (
                    <IconButton
                      className="text-primary fs-5"
                      style={{ marginTop: '32px' }}
                      onClick={this.onAddRowClicked}>
                      <i className="bi bi-plus-circle" />
                    </IconButton>
                  ) : (
                    <IconButton
                      className="text-primary fs-5"
                      style={{ marginTop: '32px' }}
                      onClick={this.onRemoveRowClicked(i)}>
                      <i className="bi bi-x-circle" />
                    </IconButton>
                  )}
                </Fieldset>,
              ]
            : [
                <Fieldset key="1" className="col-sm-4">
                  <PerDiemDropdown
                    name="FullLead"
                    noLabel
                    value={perDiemList[i].FullLead}
                    onSetValue={(name: string, value: any) => {
                      this.onPerDiemChanged(name, value, i);
                    }}
                  />
                </Fieldset>,

                <Fieldset key="2" className="col-sm-3">
                  <>
                    <PureInput
                      name={`price.${i}`}
                      type="number"
                      isChildrenPrefix
                      value={perDiemList[i].price}
                      onChange={(value) =>
                        this.onPerDiemChanged('price', value, i)
                      }>
                      <span className="input-group-text">$</span>
                    </PureInput>
                  </>
                </Fieldset>,
                <Fieldset key="3" className="col-sm-2">
                  {i === len - 1 && i < 4 ? (
                    <IconButton
                      className="text-primary fs-5"
                      style={{ marginTop: '7px' }}
                      onClick={this.onAddRowClicked}>
                      <i className="bi bi-plus-circle" />
                    </IconButton>
                  ) : (
                    <IconButton
                      className="text-primary fs-5"
                      style={{ marginTop: '7px' }}
                      onClick={this.onRemoveRowClicked(i)}>
                      <i className="bi bi-x-circle" />
                    </IconButton>
                  )}
                </Fieldset>,
              ]}
        </Fieldset>
      );
    }
    return optionList;
  }

  setScheduleMode(name: string, value: 1 | 2) {
    this.setState({ scheduleMode: value });
  }

  showActiveFee = () => {
    this.setState({ showFeePopup: true, selectedRow: this.props.facilityItem });
  };

  renderServiceList() {
    return (
      <Fieldset className="row">
        <Grid
          wrapperClass="col-sm-12"
          columns={this.getColumns()}
          dataSource={this.state.serviceList}
          dataSourceCount={this.state.serviceList.length}
          disablePagination
          noControl
        />
      </Fieldset>
    );
  }

  renderImportComponent() {
    return (
      <div>
        <Button
          variant="warning"
          text="Import"
          onClick={this.showImportPopup}
        />
        <Button text="View" className="mx-3" onClick={this.showActiveFee} />
        <a
          key="3"
          href={`${DIR_WITH_EXAMPLE}/samplecptcode.csv`}
          target="_blank"
          className="btn btn-primary"
          rel="noreferrer">
          Download Sample
        </a>
      </div>
    );
  }

  handleCloseFeeScheduleDialog = () => {
    this.setState({ showFeePopup: false });
  };

  showImportPopup = () => {
    const defaultValues = {
      from: '',
      to: '',
      destinationId: Number(this.state.billingItem.refid),
      jurisdictionId: Number(this.state.billingItem.bill_regionid),
      file: '' as '',
    };
    this.setState({
      feeImportDefaultValues: defaultValues,
      jurisdictionWarning: false,
    });
  };

  onSameAsFacility(name: string, value: any) {
    let billingItem;
    let prevBillingItem;

    if (value) {
      billingItem = { ...this.state.billingItem };
      prevBillingItem = { ...this.state.billingItem };

      billingItem.bill_addres1 = billingItem.address1;
      billingItem.bill_addres2 = billingItem.address2;
      billingItem.bill_city = billingItem.city;
      billingItem.bill_email = emailsUrlPrettier(billingItem.email);
      billingItem.bill_fax = billingItem.fax;
      billingItem.bill_phone = billingItem.phone;
      billingItem.bill_regionid = billingItem.regionid;
      billingItem.bill_state = billingItem.state;
      billingItem.bill_zipcode = billingItem.zipcode;
      billingItem.bill_contactnm = billingItem.nursing_nm;
    } else {
      billingItem = { ...this.state.prevBillingItem };
      prevBillingItem = {};
    }
    this.setState({
      sameAsFacility: value ? true : false,
      billingItem,
      prevBillingItem,
    });
  }

  handleChangeEmail = (e: React.SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;

    const prettierValue = emailsUrlPrettier(value);

    const billingItem = {
      ...this.state.billingItem,
      bill_email: prettierValue,
    };

    this.setState({ billingItem });
  };

  render() {
    const { facilityItem } = this.props;
    const cpt_info = this.state.cpt_info || facilityItem.cpt_info;
    const groupid = this.state.groupid || facilityItem.groupid;

    return (
      <div className="position-relative">
        {this.props.blocked ? <div className="overlay" /> : null}
        <Form
          model={this.state.billingItem}
          onCollectValues={this.handleChangeFilter}
          submit={this.handleSubmit}
          errors={this.state.errors}
          className={`row${this.state.onAjax ? ' on-ajax' : null}`}>
          <Fieldset className="row">
            <Fieldset className="col-sm-12">
              <Checkbox
                noclone="false"
                name="same_as_facility"
                label="Same as Facility"
                value={this.state.sameAsFacility ? 1 : 0}
                onSetValue={this.onSameAsFacility.bind(this)}
              />
            </Fieldset>
          </Fieldset>
          <Fieldset className="row">
            <Fieldset className="col-sm-6">
              <Text
                name="bill_contactnm"
                label="Contact"
                validations="required"
                errorMessages="Please, enter the contact!"
              />
              <Text
                name="bill_addres1"
                label="Address 1"
                validations="required"
                errorMessages="Please, enter the address!"
              />
              <Text name="bill_addres2" label="Address 2" />
              <Text
                name="bill_city"
                label="City"
                validations="required"
                errorMessages="Please, enter the city!"
              />
              <NumberInput
                name="bill_zipcode"
                label="Zip Code"
                format="#####-####"
                validations="required"
                errorMessages="Please, enter the zipcode!"
              />
              <NumberInput name="bill_fax" label="Fax" format="###-###-####" />
              <Checkbox name="per_diem" label="Per diem" />
            </Fieldset>
            <Fieldset className="col-sm-6">
              <NumberInput
                name="bill_phone"
                label="Phone"
                format="###-###-####"
                validations="required"
                errorMessages="Please, enter the phone!"
              />
              <Text
                name="bill_state"
                label="State"
                validations="required"
                errorMessages="Please, enter the state!"
              />

              <JurisdictionDropdown
                name="bill_regionid"
                label="Billing jurisdiction"
                validations="required:lazy"
                errorMessages="Please, select a jurisdiction!"
                noClear
              />

              <Text
                name="bill_email"
                label="Email"
                onBlur={this.handleChangeEmail}
              />

              <PlaceOfServiceDropdown
                name="pos"
                label="Place of service"
                validations="required:lazy"
                errorMessages="Please, select an option!"
              />

              <Radio
                className="part-inline"
                name="perdiem_billtype"
                options={[
                  {
                    label: 'PPS',
                    value: 'P',
                  },
                  {
                    label: 'MCO',
                    value: 'M',
                  },
                  {
                    label: 'ARRANGED',
                    value: 'A',
                  },
                ]}
                label="Billing type"
              />
            </Fieldset>
          </Fieldset>
          <Fieldset>
            {this.state.billingItem.per_diem && this.renderPerDiemList()}
          </Fieldset>
          <Radio
            noclone="false"
            className="part-inline"
            name="schedule_mode"
            value={this.state.scheduleMode}
            onSetValue={(name: string, value: string) =>
              this.setScheduleMode(
                name,
                '' + value === '1' ? 1 : '' + value === '2' ? 2 : null
              )
            }
            options={[
              {
                label: 'Fee Schedule',
                value: 1,
              },
              {
                label: 'PPS/Contract Schedule',
                value: 2,
              },
            ]}
            noLabel
          />
          {this.state.scheduleMode === 1
            ? this.renderImportComponent()
            : this.renderServiceList()}
          <FormControl>
            <input type="submit" className="btn btn-primary" value="Submit" />
          </FormControl>
        </Form>

        {this.state.feeImportDefaultValues && (
          <DialogFormImportSchedule
            isFacilityDisabled
            defaultValues={this.state.feeImportDefaultValues}
            onSubmit={this.importFee}
            onClose={this.closeImportPopup}
          />
        )}

        {this.state.showFeePopup && (
          <DialogChangingFeeSchedule
            groupId={groupid}
            id={cpt_info.id}
            feeType={cpt_info.flag || 'F'}
            onClose={this.handleCloseFeeScheduleDialog}
          />
        )}
        {this.state.jurisdictionWarning &&
          !this.state.feeImportDefaultValues && (
            <DialogAlert
              text="Warning! Facility billing jurisdiction may be changed."
              onApprove={() => this.setState({ jurisdictionWarning: false })}
            />
          )}
      </div>
    );
  }

  showCptCodeExist() {
    Notification.danger(
      "Can't import CPT code, because such file already exist."
    );
  }

  importFee = (payload: ImportPayloadType) =>
    storeFacilityFeeSchedule.importFeeSchedule(payload).then((isSucceed) => {
      if (isSucceed) {
        this.closeImportPopup();
        FacilityPageActions.loadFacilityItem(this.props.facilityId).then(
          (response) => {
            const facility = response.facility && response.facility[0];
            const { cpt_info, groupid } = facility;
            this.setState(
              {
                onAjax: false,
                cpt_info,
                groupid,
              },
              this.props.chargeUpdateOverview
            );
          }
        );
      }
      return isSucceed;
    });

  closeImportPopup = () => {
    this.setState({ feeImportDefaultValues: null });
  };

  handleSubmit = (model: any, hasErrors: any, errors: any) => {
    if (hasErrors) {
      const state = {
        billingItem: { ...this.state.billingItem },
        errors: { ...this.state.errors, ...errors },
      };
      if (errors !== null) {
        for (let key in model) {
          state.errors[key] = errors[key];
        }
      }
      this.setState(state);
    } else {
      this.props.submitBillingTab(
        this.state.billingItem,
        this.state.serviceList
      );
    }
  };

  handleChangeFilter = (name: string, value: any, errorMessages: any) => {
    const billingItem = {
      ...this.state.billingItem,
      [name]: value,
    };

    const errors = { ...this.state.errors, [name]: errorMessages };

    this.setState({ errors, billingItem });
  };

  goToOverview() {
    this.props.history.push(URL_FACILITY);
  }
}

const billingTabContainer = Container.create(BillingTab);
export default billingTabContainer;
