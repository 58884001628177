import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { Textarea } from 'components/form/textarea';
import { Button } from 'components/button';

import resolver from './validation';

import { FormModel } from 'stores/_mobx/systemSetup/masterSetting/hospice';
import MaskInput from 'components/form/maskInput';
import DropdownUsaStateShortName from 'components/project/dropdown/UsaStateShortName';

const initialValues: FormModel = {
  address1: '',
  address2: '',
  city: '',
  fax: '',
  firstName: '',
  hospiceName: '',
  lastName: '',
  notes: '',
  npi: '',
  phone: '',
  id: 0,
  state: '',
  zipcode: '',
};

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormHospice = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const prevNPIValue = useRef<string>('');

  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
    watch,
    setValue,
  } = useForm({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: FormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('hospiceName', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    const subscription = watch(({ npi }, { name }) => {
      if (name === 'npi' && !/^\d+$/.test(npi)) {
        if (prevNPIValue.current !== npi) setValue('npi', prevNPIValue.current);
      } else {
        prevNPIValue.current = npi;
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={`row${isSubmitting ? ' on-ajax' : ''}`}
      onSubmit={handleClickSubmit}>
      <Input
        name="hospiceName"
        label="Hospice name"
        className="col-md-6"
        required
        control={control}
      />
      <div />
      <Input
        name="firstName"
        label="First name"
        className="col-md-6"
        required
        control={control}
      />
      <Input
        name="lastName"
        label="Last name"
        className="col-md-6"
        required
        control={control}
      />
      <DropdownUsaStateShortName
        name="state"
        label="State"
        className="col-md-6"
        required
        control={control}
      />
      <Input
        name="city"
        label="City"
        className="col-md-6"
        required
        control={control}
      />
      <Input
        name="address1"
        label="Address 1"
        className="col-md-6"
        required
        control={control}
      />
      <Input
        name="address2"
        label="Address 2"
        className="col-md-6"
        control={control}
      />
      <MaskInput
        name="phone"
        label="Phone"
        required
        format="###-###-####"
        className="col-md-6"
        control={control}
      />
      <MaskInput
        name="fax"
        label="Fax"
        format="###-###-####"
        className="col-md-6"
        control={control}
      />
      <MaskInput
        name="zipcode"
        label="Zip Code"
        format="#####-####"
        className="col-md-6"
        required
        control={control}
      />
      <Input
        name="npi"
        label="NPI"
        className="col-md-6"
        required
        maxLength={10}
        control={control}
      />
      <Textarea
        name="notes"
        label="Notes"
        rows={4}
        className="col-md-6"
        control={control}
      />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormHospice;
