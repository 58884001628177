import { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid, GridControlButton } from 'components/grid';
import { Button, IconButton } from 'components/button';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import Exporter from 'components/project/ExporterNew';
import { LayoutSideTitle } from 'components/layout';
import EligibilityPopupWrapper from './components/EligibilityPopup';
import Filter from './components/Filter';
import DialogReview from '../components/dialogReview';

import {
  storeProFfsReport,
  FilterType,
  LogType,
} from 'stores/_mobx/billingCodding/proFfsReport';
import UserProfileStore from 'stores/UserProfileStore';
import { PRO_FSS as PAGE_ID } from 'constant/pagesId/billingCodding';

// Do not change order of columns. It affected on export!
const getColumns = (isDivisionEnabled: boolean) => {
  const columns = [
    { name: 'dos', title: 'DOS', className: 'text-nowrap' },
    { name: 'refid', title: 'Claim No.' },
    { name: 'patient_mrn', title: 'Patient MRN' },
    { name: 'patientfist', title: 'First name' },
    { name: 'patientlast', title: 'Last name' },
    { name: 'birthdate', title: 'DOB', className: 'text-nowrap' },
    { name: 'facilitynm', title: 'Facility' },
    isDivisionEnabled ? { name: 'division', title: 'Division' } : null,
    { name: 'pos', title: 'POS' },
    { name: 'examtype', title: 'Modality' },
    {
      name: 'cpt',
      title: 'Procedure',
    },
    { name: 'radiologist', title: 'Reading MD' },
    { name: 'insurance_status', title: 'Insurance status' },
    {
      name: 'facilityid',
      title: 'Review',
      export: 'exclude',
      render: (facilityid: number, { patientid }: LogType) => (
        <IconButton
          onClick={() => {
            storeProFfsReport.setForReview({
              facilityId: facilityid,
              patientId: patientid,
            });
          }}>
          <i className="icon icon-view-item" />
        </IconButton>
      ),
    },
    {
      name: 'patientid',
      title: 'Insurance info',
      className: 'text-center',
      export: 'exclude',
      render: (id: number, data: LogType) => (
        <IconButton
          className="text-primary"
          onClick={() => {
            storeProFfsReport.setInsuranceDetails(data);
          }}>
          <i className="bi bi-info-circle fs-5" />
        </IconButton>
      ),
    },
    {
      name: 'bill_process',
      title: 'Bill process',
      group: 'professional_guarantor',
      groupName: 'Professional guarantor',
    },
    {
      name: 'bill_type',
      title: 'Bill type',
      group: 'professional_guarantor',
      groupName: 'Professional guarantor',
    },
  ];

  return columns.filter(Boolean);
};

const ProFFSReportsPage = ({
  isDivisionEnabled,
}: PropsTypeWitDivisionSettings) => {
  const {
    fetching,
    logList,
    logTotal,
    reviewProps,
    insuranceDetails,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeProFfsReport;

  const gridRef = useRef<Grid>(null);

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const columns = useMemo(
    () => getColumns(isDivisionEnabled),
    [isDivisionEnabled]
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleSearch = (filter: FilterType) => {
    storeProFfsReport.setFilter(filter);

    setPaginationToStart();
  };

  const handleCloseEligibility = (shouldUpdate: boolean) => {
    if (shouldUpdate) storeProFfsReport.getReportListMain(isDivisionEnabled);
    storeProFfsReport.clearInsuranceDetails();
  };

  useEffect(() => {
    storeProFfsReport.getReportListMain(isDivisionEnabled);
  }, [isDivisionEnabled, pagination]);

  useEffect(() => {
    return storeProFfsReport.clearInsuranceDetails();
  }, []);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Pro FFS Reports">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        fetching={fetching}
        isFilterVisible={isFilterVisible}
        onSearch={handleSearch}
      />

      <Grid
        id="pro_ffs_reports_grid"
        noDataText="Please refine your search using the filters above"
        headerGroups
        columns={columns}
        ref={gridRef}
        onAjax={fetching}
        gridControlPanel={
          <GridControlButton
            title="Export"
            disabled={!logTotal}
            onClick={handleToggleExport}
          />
        }
        dataSource={logList}
        dataSourceCount={logTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      {insuranceDetails && (
        <EligibilityPopupWrapper
          details={insuranceDetails}
          onClose={handleCloseEligibility}
        />
      )}

      {reviewProps && (
        <DialogReview
          onClose={storeProFfsReport.clearReview}
          params={reviewProps}
        />
      )}

      {isExportVisible && (
        <Exporter
          exporter="ProFFSReportExporter"
          columns={columns}
          gridRef={gridRef}
          dataSource={logList}
          filter={storeProFfsReport.filter}
          filterForRequest={storeProFfsReport.prepareFilterForExport(
            isDivisionEnabled
          )}
          reportName={
            UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
            'pro_ffs_reports'
          }
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default withDivisionSettings(observer(ProFFSReportsPage));
