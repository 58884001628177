import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeDesignation,
  DesignationEditType,
} from 'stores/_mobx/systemSetup/masterSetting/designation';
import { URL_DESIGNATION } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const DesignationAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: DesignationEditType) =>
    storeDesignation.addDesignation(data).then((error) => {
      if (error) return error;
      history.push(URL_DESIGNATION);
    });

  return (
    <>
      <Title title="Add User Type" />
      <Form backUrl={URL_DESIGNATION} onSubmit={handleSubmit} />
    </>
  );
};

export default DesignationAddPage;
