import { action, makeObservable, observable, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

interface SpecialtyType {
  refid: number;
  specialty_name: string;
  texonomy_code: string;
}

export interface SpecialtyFormModel {
  taxonomyCode: string;
  specialtyName: string;
  id: number;
}

interface OptionResponseType {
  data: string;
  label: string;
}

export interface OptionType {
  value: number;
  label: string;
}

class Specialty {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  idForDelete: number = 0;
  specialty?: SpecialtyFormModel = undefined;
  specialtyList: SpecialtyType[] = [];
  specialtyTotal: number = 0;
  options: OptionType[] = [];

  page: Pagination = new Pagination({ id: 'mvSpecialtyGrid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      specialty: observable,
      specialtyList: observable,
      specialtyTotal: observable,
      idForDelete: observable,
      options: observable,

      setFetching: action,
      setFetchingOptions: action,
      setList: action,
      setTotal: action,
      setSpecialtyForEdit: action,
      setIdForDelete: action,
      clearIdForDelete: action.bound,
      clearSpecialty: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFetchingOptions = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setSpecialtyForEdit = (specialty?: SpecialtyFormModel) => {
    this.specialty = specialty;
  };

  setList = (list: SpecialtyType[]) => {
    this.specialtyList = list;
  };

  setTotal = (total: number) => {
    this.specialtyTotal = total;
  };

  setIdForDelete = (id: number) => {
    this.idForDelete = id;
  };

  clearIdForDelete = () => {
    this.idForDelete = undefined;
  };

  clearSpecialty = () => {
    this.specialty = undefined;
  };

  async getSpecialtyList(specialtyName: string) {
    const { pagination } = this.page;

    const data = [pagination.skip, pagination.pageSize, specialtyName];

    try {
      const response = await apiRequest<'SE' | SpecialtyType[]>({
        url: 'generalmaster.Mv_Specialty.Mv_SpecialtyList',
        data,
      });

      if (Array.isArray(response)) {
        const list = response.map((el) => ({
          ...el,
          refid: Number(el.refid),
        }));
        this.setList(list);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setList([]);
    }
  }

  getSpecialtyCount = async (specialtyName: string) => {
    try {
      const total = await apiRequest<number>({
        url: 'generalmaster.Mv_Specialty.TotalRecordCount',
        data: [specialtyName],
      });
      this.setTotal(Number(total) || 0);
    } catch (e) {
      this.setTotal(0);
    }
  };

  getSpecialtiesMain = async (specialtyName: string) => {
    this.setFetching(true);

    const promiseList = this.getSpecialtyList(specialtyName);

    const promiseCount = this.getSpecialtyCount(specialtyName);

    Promise.allSettled([promiseList, promiseCount]).finally(() => {
      this.setFetching(false);
    });
  };

  async getSpecialty(id: number) {
    this.setFetching(true);
    try {
      const response = await apiRequest<[SpecialtyType]>({
        url: 'generalmaster.Mv_Specialty.GetViewMv_Specialty',
        data: [id],
      });

      if (Array.isArray(response)) {
        const specialty = {
          id,
          specialtyName: response[0].specialty_name,
          taxonomyCode: response[0].texonomy_code,
        };
        this.setSpecialtyForEdit(specialty);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setSpecialtyForEdit();
    } finally {
      this.setFetching(false);
    }
  }

  addSpecialty = async (payload: SpecialtyFormModel) => {
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.Mv_Specialty.AddMv_Specialty',
        data: [payload.specialtyName, payload.taxonomyCode],
      });
      if (result === 'S') {
        Notification.success('Specialty was added successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This Specialty already exist!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  };

  updateSpecialty = async (payload: SpecialtyFormModel) => {
    const data = [payload.id, payload.specialtyName, payload.taxonomyCode];
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.Mv_Specialty.EditMv_Specialty',
        data,
      });

      if (result === 'S') {
        Notification.success('Specialty was updated successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This Specialty already exist!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  };

  deleteSpecialty = async () => {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.Mv_Specialty.DeleteMv_Specialty',
        data: [this.idForDelete],
      });

      if (result === 'S') {
        Notification.success('Specialty was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  };

  async validateTaxonomyCode(taxonomyCode: string) {
    try {
      const result = await apiRequest<boolean>({
        url: 'generalmaster.Mv_Specialty.IsExistSpecialtyCode',
        data: [taxonomyCode],
      });
      return result;
    } catch (e: any) {
      return true;
    }
  }

  async getOptions() {
    if (this.fetching) return Promise.resolve();
    this.setFetchingOptions(true);
    try {
      const list = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.Mv_Specialty.LoadMv_Specialty',
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }

  findOptionById(id: number) {
    return this.options.find(({ value }) => value === id);
  }
}

export const storeSpecialty = new Specialty();
