import React, { useMemo } from 'react';

import Custom from 'components/form/input/Custom';
import PlaceOfServiceDropdown, {
  DropDownPlaceOfService,
} from './PlaceOfServiceDropdown';
import { PropsType as SelectPropsType } from 'components/form/select/Select';
import { FakeInput } from 'components/form/textField';

import UserProfileStore from 'stores/UserProfileStore';

const availableUserTypes = ['D', 'L', 'RA', 'RC'];

interface PropsType {
  name: string;
  label?: string;
  value?: number;
  className?: string;
  noLabel?: boolean;
  attr?: any;
}

export default class PlaceOfServiceRolesDropdown extends React.Component<
  PropsType,
  any
> {
  static getPlaceOfServiceId() {
    if (PlaceOfServiceRolesDropdown.canSeeStates()) {
      return 0;
    }
    return UserProfileStore.getPlaceOfServiceId();
  }

  static canSeeStates() {
    return (
      availableUserTypes.includes(UserProfileStore.getUserType()) ||
      !UserProfileStore.isClientUser() ||
      UserProfileStore.getUser()
        .usertypename.toLowerCase()
        .indexOf('corporate') > -1
    );
  }

  getValue() {
    if (PlaceOfServiceRolesDropdown.canSeeStates()) {
      return this.props.value;
    }
    return UserProfileStore.getPlaceOfServiceId();
  }

  render() {
    if (PlaceOfServiceRolesDropdown.canSeeStates()) {
      return <PlaceOfServiceDropdown {...this.props} />;
    } else {
      return (
        <Custom
          {...this.props}
          custom={<span>{UserProfileStore.getPlaceOfServiceName()}</span>}
        />
      );
    }
  }
}

export const DropdownPlaceOfServiceWithPermission = (
  props: Omit<SelectPropsType, 'options'>
) => {
  const hasPermission = useMemo(
    () =>
      availableUserTypes.includes(UserProfileStore.getUserType()) ||
      !UserProfileStore.isClientUser() ||
      UserProfileStore.getUser()
        .usertypename.toLowerCase()
        .includes('corporate'),
    []
  );

  return hasPermission ? (
    <DropDownPlaceOfService {...props} />
  ) : (
    <FakeInput
      className={props.className}
      required={props.required}
      label={props.label}
      value={UserProfileStore.getPlaceOfServiceName()}
    />
  );
};
