import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeDrPlate,
  DrPlateEntryType,
} from 'stores/_mobx/workflow/equipmentInventory/drPlate';
import { URL_DR_PLATE } from 'constant/path/workflow';

interface PropsType extends RouteComponentProps {}

const DrPlateAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: DrPlateEntryType) =>
    storeDrPlate.addDrPlate(data).then((error) => {
      if (error) return error;
      history.push(URL_DR_PLATE);
    });

  return (
    <>
      <Title title="Add DR Plate" />
      <Form onSubmit={handleSubmit} backUrl={URL_DR_PLATE} />
    </>
  );
};

export default DrPlateAddPage;
