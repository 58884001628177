import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { DropDownCorporation } from 'components/project/dropdown/CorporateDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropdownLocation } from 'components/project/dropdown/LocationDropdown';
import { DropdownProviderType } from 'components/project/dropdown/ProviderTypeDropdown';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropDownOrderType } from 'components/project/dropdown/OrderType';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { DropdownDivision } from 'components/project/dropdown/Division';
import { Button, IconButton } from 'components/button';
import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { DateRange } from 'components/form/dateRange';

export const filterDefaultValues: FilterType = {
  corporate: 0,
  state: [],
  orderType: '',
  lastName: '',
  firstName: '',
  facility: [],
  location: [],
  pos: 0,
  providerType: [],
  dosStart: '',
  dosEnd: '',
  period: 'A',
  claim: '',
  division: 0,
};

export interface FilterType {
  corporate: number;
  state: number[];
  orderType: string;
  lastName: string;
  firstName: string;
  facility: number[];
  location: number[];
  pos: number;
  providerType: number[];
  dosStart: string;
  dosEnd: string;
  period: 'A' | 'TD' | 'W' | 'M';
  claim: string;
  division: number;
}

interface PropsType {
  fetching: boolean;
  isVisible: boolean;
  defaultValues: FilterType;
  generateInvoice?(facilityId: number): void;
  onSubmit: (payload: FilterType) => void;
}

const commonClassName = 'col-md-6 col-lg-3';

const dropdownAttributes = {
  isMulti: true,
  isStyleDependsOnValue: true,
};

const Filter = ({
  fetching,
  isVisible,
  defaultValues,
  generateInvoice,
  onSubmit,
}: PropsType) => {
  const { control, watch, reset, handleSubmit } = useForm<FilterType>();

  const facilityId = watch('facility') || [];

  const handleClickReset = () => {
    const defaultValues = { ...filterDefaultValues };
    reset(defaultValues);
    onSubmit(defaultValues);
  };

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={isVisible ? '' : 'd-none'}
      onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <DropDownCorporation
          name="corporate"
          label="Corporate"
          className={commonClassName}
          control={control}
        />
        <DropDownState
          name="state"
          label="State"
          className={commonClassName}
          {...dropdownAttributes}
          control={control}
        />
        <Input
          name="lastName"
          label="Last Name"
          className={commonClassName}
          control={control}
        />
        <Input
          name="firstName"
          label="First Name"
          className={commonClassName}
          control={control}
        />
        <div />
        <DropDownFacility
          name="facility"
          className={`${commonClassName} position-relative`}
          {...dropdownAttributes}
          label={
            generateInvoice ? (
              <>
                <span>Facility</span>
                <IconButton
                  className="text-success mx-3"
                  disabled={facilityId.length !== 1}
                  onClick={() => generateInvoice(facilityId[0])}>
                  <i className="bi bi-pencil-square" />
                </IconButton>
              </>
            ) : (
              'Facility'
            )
          }
          control={control}
        />
        <DropdownLocation
          name="location"
          label="Location"
          className={commonClassName}
          {...dropdownAttributes}
          control={control}
        />
        <DropdownDivision
          name="division"
          label="Division"
          className={commonClassName}
          control={control}
        />
        <DropDownPlaceOfService
          name="pos"
          label="Place of Service"
          className={commonClassName}
          control={control}
        />
        <DropDownOrderType
          name="orderType"
          label="Order Type"
          className={commonClassName}
          control={control}
        />
        <DropdownProviderType
          name="providerType"
          label="Provider Type"
          className={commonClassName}
          {...dropdownAttributes}
          control={control}
        />
        <DateRange
          nameFrom="dosStart"
          nameTo="dosEnd"
          nameRadio="period"
          radioClassName="col-sm-12 col-md-6 col-lg-auto"
          control={control}
        />
        <ControlsLayout alignX="auto">
          <Button
            text="Reset"
            variant="warning"
            type="reset"
            disabled={fetching}
            onClick={handleClickReset}
          />
          <Button
            type="submit"
            data-testid="submit"
            text="Apply"
            disabled={fetching}
          />
        </ControlsLayout>
      </div>
    </form>
  );
};

export default Filter;
