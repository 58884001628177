import { useEffect, useMemo, useState } from 'react';

import {
  getPatientBasicValues,
  PatientFormBasicData,
  PatientType,
  storePatientDemographic
} from 'stores/_mobx/patientRecord/patientDemographic';
import { PatientInfoType } from 'stores/_mobx/workflow/order/patient';
import { storeCurrentUserOptions } from 'stores/_mobx/curentUserOptions';

import DialogCreatePatient from './dialogCreatePatient';
import DialogExistingPatients from './dialogExistingPatients';
import DialogValidatePatientFacility from './dialogValidatePatientFacility';

export { type PatientInfoType };

export interface PatientDifferencesType {
  patient: PatientType;
  newFacilityId: number;
  currentFacilityName?: string;
  isClient: boolean;
}

interface PropsType {
  onClose: () => void;
  onSubmit: (details: PatientInfoType) => void;
}

const DialogAddPatient = ({ onClose, onSubmit }: PropsType) => {
  const { isCorrectional, fetchingCorrectionalStatus } = storeCurrentUserOptions;

  const [availablePatients, setAvailablePatients] = useState<PatientType[]>([]);

  const [patientDifferences, setPatientDifferences] =
    useState<PatientDifferencesType>(null);

  const [selectedPatient, setPatient] = useState<PatientType | null>(null);

  const [patientFormValues, setPatientFormValues] = useState<PatientFormBasicData>(getPatientBasicValues());

  const handleCloseAddPatientPopup = () => {
    setAvailablePatients([]);
  };

  const submitExistedPatient = (patient: PatientType = selectedPatient) => {
    if(!selectedPatient) return;

    const payload: PatientInfoType = {
      client_mrn: patient.clientMRN,
      facility_id: patient.facility_id,
      refid: patient.refid,
      dob: patient.birthdate,
      last_name: patient.last_name,
      first_name: patient.first_name,
      middle_name: patient.middle_name,
      ssn: patient.ssn,
      gender: patient.gender,
      phone: patient.mobile,
      inmate_number: patient.inmate_number,
      ice_number: patient.ice_number,
      immigration_id: patient.immigration_id,
      patient_comment: patient.patient_comment,
      created: false,
    };

    onSubmit(payload);
  };

  const handleCreatePatient = async (
    payload: PatientFormBasicData = patientFormValues
  ) => {
    const patient: PatientInfoType =
      await storePatientDemographic.createPatientThroughOrder(payload);

    if (patient) {
      onSubmit(patient);
    }
  };

  const handleSubmitAddPatient = async (payload: PatientFormBasicData) => {
    const filter = {
      status: 'A',
      phoneNumber: '',
      mrn: '',
      gender: '', //Gender was skipped bcs Patient can be already added, but with UNKNOWN gender
      birthday: payload.birthdate,
      facilityId: 0,
      ssn: '',
      lastName: payload.last_name,
      firstName: payload.first_name,
    };

    const patients = await storePatientDemographic.getPatientsForOrder({
      pageSize: 20,
      skip: 0,
      filter,
    }) || [];

    if (patients.length) {
      setAvailablePatients(patients);
      setPatientFormValues(payload);
      return;
    }

    await handleCreatePatient(payload);
  };

  const dialogState = useMemo(() => ({
    showDialogExistingPatients: availablePatients.length > 0 && !patientDifferences,
    showDialogValidatePatientFacility: !!patientDifferences
  }), [availablePatients.length, patientDifferences]);

  useEffect(() => {
    storeCurrentUserOptions.checkCorrectionalStatus();
  }, []);

  return (
    <>
      <DialogCreatePatient
        onClose={onClose}
        isCorrectional={isCorrectional}
        fetchingCorrectionalStatus={fetchingCorrectionalStatus}
        defaultValues={patientFormValues}
        onSubmit={handleSubmitAddPatient}
      />

      {dialogState.showDialogExistingPatients ? (
        <DialogExistingPatients
          isCorrectional={isCorrectional}
          availablePatients={availablePatients}
          newPatientFacilityId={patientFormValues?.facilityid}
          addPatient={handleCreatePatient}
          submitExistedPatient={submitExistedPatient}
          selectedPatient={selectedPatient}
          setPatientDifferences={setPatientDifferences}
          setPatient={setPatient}
          onClose={handleCloseAddPatientPopup}
        />
      ) : null}

      {dialogState.showDialogValidatePatientFacility ? (
        <DialogValidatePatientFacility
          patientDifferences={patientDifferences}
          submitExistedPatient={submitExistedPatient}
          setPatientDifferences={setPatientDifferences}
        />
      ) : null }
    </>
  );
};

export default DialogAddPatient;
