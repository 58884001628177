import React from 'react';
import { Container } from 'flux/utils';

import Tabs from 'components/tabs';
import { Grid } from 'components/grid';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import Select from 'components/form/input/Select';
import { Button } from 'components/button';

import UserSelectionActions from 'actions/project/UserSelectionActions';
import EmployeeTimeSheetPageActions from 'actions/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageActions';
import UserSelectionStore from 'stores/project/UserSelectionStore';
import UserProfileStore from 'stores/UserProfileStore';
import EmployeeTimeSheetPageStore from 'stores/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageStore';
import MathUtils from 'utils/MathUtils';

const COLUMNS = [{ name: 'to_name', title: 'Name' }];

const tabsList = [{ label: 'Company Users' }];

class AssignSupervisorModal extends React.Component {
  userSelectionChangeListener = UserSelectionStore.addListener(() =>
    this.onUserSelectionChange()
  );
  superVisorsListChangeListener = EmployeeTimeSheetPageStore.addListener(() =>
    this.onSuperVisorsListChange()
  );

  componentWillUnmount() {
    if (this.userSelectionChangeListener) {
      this.userSelectionChangeListener.remove();
    }
    if (this.superVisorsListChangeListener) {
      this.superVisorsListChangeListener.remove();
    }
    if (this.refs.grid) {
      this.refs.grid.clearSelection();
    }
  }

  handleApprove = () => {
    EmployeeTimeSheetPageActions.assignSuperVisors(
      this.state.globalSelected
    ).then(() => {
      this.onClose(true);
    });
  };

  handleCancel = () => {
    this.onClose(false);
  };

  onClose(response) {
    EmployeeTimeSheetPageActions.clearSuperVisorIdsList();
    this.setState({
      globalSelected: null,
    });
    this.props.callback(response);
  }

  onUserSelectionChange() {
    const store = UserSelectionStore.getState();
    this.setState({
      userTypes: store.userTypes,
      dataSourceLength: store.userTypes.length,
      userList: store.recipients,
    });
  }

  onSuperVisorsListChange() {
    if (this.state.globalSelected === null) {
      const store = EmployeeTimeSheetPageStore.getState();
      this.setState({
        globalSelected: store.superVisorIdsList,
      });
    }
  }

  static getStores() {
    return [EmployeeTimeSheetPageStore, UserSelectionStore];
  }

  componentDidMount() {
    UserSelectionActions.loadUserTypes();
    EmployeeTimeSheetPageActions.getSuperVisorIdsList();
    this.updateData();
  }

  static calculateState(prevState, props) {
    if (!prevState) {
      prevState = {
        selectedUserType: null,
        globalSelected: [],
        selectedTab: 0,
        onAjax: false,
        typeChangeCompleted: true,
        userList: [],
      };
    }
    return {
      ...prevState,
      globalSelected: EmployeeTimeSheetPageStore.getState().superVisorIdsList,
    };
  }

  onGridSelectRow(currentSelected) {
    const { state } = this;
    const globalSelected = state.globalSelected ? state.globalSelected : [];
    for (let i = 0; i < state.userList.length; i++) {
      const item = state.userList[i];
      const idxInCurrentSelected = currentSelected.indexOf(item.to_mail);
      const idxInGlobalSelected = globalSelected.indexOf(item.to_mail);
      if (idxInCurrentSelected >= 0 && idxInGlobalSelected < 0) {
        globalSelected.push(item.to_mail);
      } else if (idxInGlobalSelected >= 0 && idxInCurrentSelected < 0) {
        globalSelected.splice(idxInGlobalSelected, 1);
      }
    }
    this.setState({
      globalSelected: globalSelected,
    });
  }

  updateData() {
    const { selectedUserType } = this.state;
    const currentUserId = UserProfileStore.getUserId();
    const currentUserType = UserProfileStore.getUserType();
    const userType = this.findUserType(
      UserSelectionStore.getState().userTypes,
      selectedUserType
    );
    return UserSelectionActions.loadRecipients(
      userType ? userType.usertype : '',
      currentUserType,
      currentUserId,
      0,
      '',
      selectedUserType
    );
  }

  onChangeTab = (selectedTab) => {
    const state = { selectedTab };
    if (selectedTab !== this.state.selectedTab) {
    }
    this.setState(state, () => {
      this.updateData();
    });
  };

  render() {
    const { state } = this;
    const userTypes = this.getUserTypesHtml();
    return (
      <Dialog size="extraLarge" handleClose={this.handleCancel}>
        <DialogHeader
          title="Assign Supervisor(s)"
          onClose={this.handleCancel}
        />
        <DialogBody>
          <div className="row mb-4">
            <Tabs tabsList={tabsList}>
              {userTypes ? (
                <div className="row">{userTypes.companyUsers}</div>
              ) : null}
            </Tabs>

            {!state.typeChangeCompleted ? null : (
              <Grid
                id="supervisor_grid"
                onAjax={state.onAjax}
                onSelectChange={(currentSelected) =>
                  this.onGridSelectRow(currentSelected)
                }
                selectedIds={state.globalSelected}
                selectId="to_mail"
                ref="grid"
                dataSource={state.userList}
                disablePagination
                columns={COLUMNS}
                dataSourceCount={state.totalRows}
              />
            )}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button text="No" variant="warning" onClick={this.handleCancel} />
          <Button text="Yes" onClick={this.handleApprove} />
        </DialogFooter>
      </Dialog>
    );
  }

  onChangeUserType(name, value, errors) {
    const { state } = this;
    if (state.selectedUserType !== value) {
      this.setState(
        { typeChangeCompleted: false, selectedUserType: value, onAjax: true },
        () => {
          this.updateData().then(() =>
            this.setState({ onAjax: false, typeChangeCompleted: true })
          );
        }
      );
    }
  }

  findUserType(userTypes, refid) {
    if (!userTypes || !userTypes.length) {
      return null;
    }
    for (let i = 0; i < userTypes.length; i++) {
      if (userTypes[i].refid === refid) {
        return userTypes[i];
      }
    }
    return null;
  }

  getUserTypesHtml() {
    const userTypes = UserSelectionStore.getState().userTypes;
    if (!userTypes || !userTypes.length) {
      return null;
    }
    const companyUsers = {
      name: 'companyUsers',
      noLabel: true,
      className: 'col-sm-6 col-md-4 user-selection-select',
      onSetValue: (name, value, errors) =>
        this.onChangeUserType(name, value, errors),
      value: this.state.selectedUserType,
      options: [],
    };
    for (let i = 0; i < userTypes.length; i++) {
      const type = userTypes[i];
      if (MathUtils.parseInt(type.type) !== 1) {
        companyUsers.options.push({
          label: type.usertype_name,
          value: type.refid,
        });
      }
    }
    return {
      companyUsers: <Select {...companyUsers} key="admin" />,
    };
  }
}

export default Container.create(AssignSupervisorModal, { withProps: true });
