import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, NavLink } from 'react-router-dom';

import JIRAIssueCollector from 'components/collectorJiraTicket';
import { DownloadManager } from 'components/exporter';

import UserProfileStore from 'stores/UserProfileStore';
import { URL_LOGOUT } from 'constant/path/auth';
import {
  URL_GRID_SETTINGS,
  URL_INTEGRATION_DASHBOARD,
} from 'constant/path/other';

interface PropsType {
  onClickPermission: (event: React.MouseEvent) => void;
  onClickDownloadRequisition: (event: React.MouseEvent) => void;
}

const UserMenu = ({
  onClickPermission,
  onClickDownloadRequisition,
}: PropsType) => {
  const refIcon = useRef<HTMLAnchorElement>();

  const [isNavbarVisible, toggleVisibility] = useState<boolean>(false);

  const user = UserProfileStore.getUser();

  const userLetters = useMemo(
    () =>
      user.name
        ?.split(' ')
        .reduce(
          (prev: string, curr, idx) =>
            curr && idx < 2 ? `${prev}${curr[0]}` : prev,
          ''
        ),
    [user.name]
  );

  const isSimpleUser = user.usertype === 'U';

  const isGlobalUser = user.global_user === 'Y';

  const isClient = Number(user.type) > 0;

  const handleClickMenu = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toggleVisibility((state) => !state);
  };

  const handleCloseMenu = useCallback(({ target }: MouseEvent) => {
    const isMenuClicked = refIcon.current?.contains(target as Node);

    if (!isMenuClicked) toggleVisibility(false);
  }, []);

  useEffect(() => {
    if (isNavbarVisible)
      document.body.addEventListener('click', handleCloseMenu);

    return () => {
      document.body.removeEventListener('click', handleCloseMenu);
    };
  }, [isNavbarVisible, handleCloseMenu]);

  return (
    <div className="user-menu">
      <DownloadManager />
      <div className="user-menu-item">
        <a
          href="/"
          className="menu-icon"
          ref={refIcon}
          onClick={handleClickMenu}>
          <span className="menu-icon-content">{userLetters}</span>
          <i className="bi bi-chevron-down ms-3" />
        </a>

        <div className={`menu-short-info${isNavbarVisible ? ' d-none' : ''}`}>
          <span>{user.name}</span>
          <span>{user.user_email}</span>
        </div>

        <ul
          data-bs-popper="static"
          className={`dropdown-menu dropdown-menu-end${
            isNavbarVisible ? ' show' : ''
          }`}>
          <li className="dropdown-info">
            <span className="info-name">{user.name}</span>
            <span className="info-position">{user.usertypename}</span>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          {isSimpleUser && (
            <li className="nav-item">
              <NavLink
                className="dropdown-item"
                activeClassName="active"
                to={URL_INTEGRATION_DASHBOARD}>
                Integrations
              </NavLink>
            </li>
          )}
          {isSimpleUser && isGlobalUser && (
            <li className="nav-item">
              <a className="dropdown-item" href="/" onClick={onClickPermission}>
                Company User Permissions
              </a>
            </li>
          )}
          <li className="nav-item">
            <NavLink
              className="dropdown-item"
              activeClassName="active"
              to={URL_GRID_SETTINGS}>
              Grids Settings
            </NavLink>
          </li>
          {!isClient && (
            <li className="nav-item">
              <a
                href="/"
                target="_blank"
                title="Sample Blank PDF Format"
                className="dropdown-item"
                rel="noreferrer"
                onClick={onClickDownloadRequisition}>
                Download blank exam requisition
              </a>
            </li>
          )}
          {user.userProfileLink ? (
            <li className="nav-item">
              <NavLink
                className="dropdown-item"
                activeClassName="active"
                to={user.userProfileLink}>
                Profile
              </NavLink>
            </li>
          ) : null}
          <li className="nav-item">
            <JIRAIssueCollector className="dropdown-item" />
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <Link to={URL_LOGOUT} className="dropdown-item text-danger">
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default memo(UserMenu);
