import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { InputCalendar } from 'components/form/inputCalendar';
import MaskInput from 'components/form/maskInput';
import { Input } from 'components/form/textField';
import { ControlsLayout } from 'components/layout';

import {
  storeFax,
  filterDefaultValues,
  FilterModel,
} from 'stores/_mobx/workflow/order/fax';

interface PropsType {
  onChange: (filter: FilterModel) => void;
}

const Filter = ({ onChange }: PropsType) => {
  const { control, reset, watch } = useForm<FilterModel>({
    defaultValues: storeFax.filter,
  });

  const handleClickReset = () => {
    reset(filterDefaultValues);
  };

  useEffect(() => {
    const subscription = watch((formValue) => {
      onChange(formValue as FilterModel);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form>
      <div className="row">
        <Input
          name="name"
          label="Receiver name"
          className="col-sm-3"
          control={control}
        />

        <MaskInput
          name="fax"
          label="Receiver fax"
          format="###-###-####"
          className="col-sm-3"
          control={control}
        />

        <Input
          name="mail"
          label="Receiver Email"
          className="col-sm-3"
          control={control}
        />
      </div>
      <div className="row">
        <InputCalendar
          name="dateFrom"
          label="Date From"
          className="col-sm-3"
          control={control}
        />

        <InputCalendar
          name="dateTo"
          label="Date To"
          className="col-sm-3"
          control={control}
        />

        <Input
          name="subject"
          label="Subject"
          className="col-sm-3"
          control={control}
        />

        <ControlsLayout alignX="auto">
          <Button variant="warning" text="Reset" onClick={handleClickReset} />
        </ControlsLayout>
      </div>
    </form>
  );
};

export default Filter;
