import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

const gridId = 'icdCodeGrid';

export const defaultFilterValues: IcdCodeFilterType = {
  name: '',
  description: '',
  icdFlag: 'Y',
  smoking: 'N',
  symptomatic: 'N',
  all: false,
};

export interface IcdCodeFilterType {
  name: string;
  description: string;
  icdFlag: 'Y' | 'N' | 'U';
  smoking: 'Y' | 'N' | 'U';
  symptomatic: 'Y' | 'N' | 'U';
  all: boolean;
}

interface IcdCodeResponseType {
  icd_billable_status: string;
  icd_code: string;
  refid: string;
  short_description: string;
}

export interface IcdCodeType {
  icd_billable_status: number;
  icd_code: string;
  refid: number;
  short_description: string;
}

interface IcdLogType {
  created_dt: string;
  icd_code: string;
  short_description: string;
  status: 'Active' | 'History' | '';
}

class IcdCode {
  fetching: boolean = false;
  fetchingIcdLog: boolean = false;
  icdCodeList: IcdCodeType[] = [];
  icdCodeTotal: number;
  icdLogList: IcdLogType[] = [];
  icdLogTotal: number = 0;
  filter: IcdCodeFilterType = defaultFilterValues;
  gridId = gridId;

  page: Pagination = new Pagination({ id: gridId });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingIcdLog: observable,
      icdCodeList: observable,
      icdCodeTotal: observable,
      icdLogList: observable,
      icdLogTotal: observable,
      filter: observable,

      setFetching: action,
      setFetchingIcdLog: action,
      setCodesCount: action,
      setCodesList: action,
      setIcdLogCount: action,
      setIcdLogList: action,
      setFilter: action,
      clearStore: action.bound,
      clearLogs: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingIcdLog(fetching: boolean) {
    this.fetchingIcdLog = fetching;
  }

  setCodesCount(count: number) {
    this.icdCodeTotal = count;
  }

  setCodesList(codes: IcdCodeType[]) {
    this.icdCodeList = codes;
  }

  setIcdLogCount(count: number) {
    this.icdLogTotal = count;
  }

  setIcdLogList(logs: IcdLogType[]) {
    this.icdLogList = logs;
  }

  setFilter(filter: IcdCodeFilterType) {
    this.filter = filter;
  }

  clearStore() {
    this.icdCodeList = [];
    this.icdCodeTotal = 0;
    this.filter = defaultFilterValues;
    this.page.setPaginationToStart();
  }

  clearLogs() {
    this.icdLogList = [];
    this.icdLogTotal = 0;
  }

  async getIcdCodesTotal(filter: IcdCodeFilterType) {
    const data = [
      filter.name,
      filter.description,
      null,
      null,
      null,
      null,
      filter.icdFlag,
      filter.smoking,
      filter.symptomatic,
      filter.all,
    ];
    try {
      const count = await apiRequest<number>({
        url: 'codemaster.ICD9Code.ICD9CodeTotalRecordCount',
        data,
      });

      this.setCodesCount(Number(count) || 0);
    } catch (e: any) {
      this.setCodesCount(0);
    }
  }

  async getIcdCodesList({
    pagination,
    filter,
  }: {
    pagination: Pagination['pagination'];
    filter: IcdCodeFilterType;
  }) {
    const data = [
      filter.name,
      filter.description,
      pagination.skip,
      pagination.pageSize,
      filter.icdFlag,
      filter.smoking,
      filter.symptomatic,
      filter.all,
    ];
    try {
      const response = await apiRequest<IcdCodeResponseType[]>({
        url: 'codemaster.ICD9Code.ICD9CodeListValue',
        data,
      });

      const codes = response.map((code) => ({
        ...code,
        icd_billable_status: Number(code.icd_billable_status),
        refid: Number(code.refid),
      }));

      this.setCodesList(codes);
    } catch (e: any) {
      this.setCodesList([]);
    }
  }

  getIcdCodesListMain() {
    const {
      page: { pagination },
      filter,
    } = this;
    this.setFetching(true);

    const promiseList = this.getIcdCodesList({ pagination, filter });

    const promiseTotal = this.getIcdCodesTotal(filter);

    Promise.allSettled([promiseList, promiseTotal]).finally(() => {
      this.setFetching(false);
    });
  }

  async getIcdLogTotal(orderId: number) {
    try {
      const count = await apiRequest<string>({
        url: 'order.Order.InitialICDLogCount',
        data: [orderId],
      });

      this.setIcdLogCount(Number(count) || 0);
    } catch (e: any) {
      this.setIcdLogCount(0);
    }
  }

  async getIcdLogList({
    pagination,
    orderId,
  }: {
    pagination: Pagination['pagination'];
    orderId: number;
  }) {
    const data = [orderId, pagination.skip, pagination.pageSize];

    try {
      const logs = await apiRequest<IcdLogType[]>({
        url: 'order.Order.InitialICDLog',
        data,
      });

      this.setIcdLogList(logs);
    } catch (e: any) {
      this.setIcdLogList([]);
    }
  }

  getIcdLogListMain(payload: {
    orderId: number;
    pagination: Pagination['pagination'];
  }) {
    this.setFetchingIcdLog(true);

    const promiseList = this.getIcdLogList(payload);

    const promiseTotal = this.getIcdLogTotal(payload.orderId);

    Promise.allSettled([promiseList, promiseTotal]).finally(() => {
      this.setFetchingIcdLog(false);
    });
  }
}

export const storeIcdCode = new IcdCode();
