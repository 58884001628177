import * as yup from 'yup';

interface Props {
  minLength: number;
  maxLength: number;
  alphaNumeric: boolean;
}

const validation = (props: Props) =>
  yup.object().shape({
    oldPassword: yup.string().required("Can't be empty."),
    password: yup
      .string()
      .required("Can't be empty.")
      .min(
        props.minLength,
        'Should contain one digit and one non-digit character'
      )
      .test({
        test: (password: string) => {
          return props.alphaNumeric
            ? /(\d[a-z])|([a-z]\d)/i.test(password)
            : true;
        },
        message: 'Password should include at least 1 letter and 1 number',
      }),
    repeatPassword: yup
      .string()
      .required("Can't be empty.")
      .oneOf([yup.ref('password'), null], 'Password did not match'),
    secQues1: yup.number().positive().required("Can't be empty"),
    secQues2: yup.number().positive().required("Can't be empty"),
    answer1: yup.string().required("Can't be empty."),
    answer2: yup.string().required("Can't be empty."),
  });

export default validation;
