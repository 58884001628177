import { useMemo, useReducer } from 'react';

import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';
import DialogPinDisplaying from 'components/project/modals/pinDisplaying';
import FormUserAuthentic, {
  FormModel as FormModelUserAuthenticType,
} from '../FormUserAuthentic';
import FormSecurityQuestion, {
  FormModel as FormSecurityQuestionType,
} from '../FormSecurityQuestion';
import FormChangePassword, {
  FormModel as FormChangePasswordType,
} from '../FormChangePassword';

import UserProfileActions from 'actions/UserProfileActions';
import { reducer, initialState, ActionType } from './state';

interface PropsType {
  onClose: () => void;
}

const DialogForgotPassword = ({ onClose }: PropsType) => {
  const [{ userData, pin, errorMessage, step }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const questions: [string, string] = useMemo(
    () => [userData?.quest_id || '', userData?.quest1_id || ''],
    [userData]
  );

  const dialogTitle = step === 3 ? 'Change Password' : 'Forgot Password';

  const handleSubmitSignIn = (payload: FormModelUserAuthenticType) =>
    UserProfileActions.forgotPasswordStep1(payload).then((response: any) => {
      if (response === 'E') {
        dispatch({
          type: ActionType.SetErrorMessage,
          payload: 'User not found',
        });
      } else if (response && response[0]) {
        dispatch({ type: ActionType.SetUserData, payload: response[0] });
      }
    });

  const handleSubmitSecurityQuestion = (data: FormSecurityQuestionType) => {
    const payload = {
      refid: Number(userData.refid),
      usertype: userData.usertype,
      ...data,
    };
    return UserProfileActions.forgotPasswordStep2(payload).then((response) => {
      if (response.refid) {
        dispatch({ type: ActionType.SetSecurityQuestionSuccess });
      } else {
        dispatch({
          type: ActionType.SetErrorMessage,
          payload:
            'The given details are incorrect. Please provide proper details.',
        });
      }
      return response;
    });
  };

  const handleSubmitChangePassword = ({ password }: FormChangePasswordType) =>
    UserProfileActions.updateResetPassword(
      Number(userData.refid),
      password
    ).then((pin) => {
      if (Number(pin)) {
        dispatch({ type: ActionType.ChangePasswordSuccess, payload: pin });
      } else {
        dispatch({
          type: ActionType.SetErrorMessage,
          payload:
            "Can't change password due unknown reasons. Please contact your admin",
        });
      }
    });

  return step === 4 ? (
    <DialogPinDisplaying pin={pin} onClose={onClose} />
  ) : (
    <Dialog handleClose={onClose}>
      <DialogHeader title={dialogTitle} onClose={onClose} />
      <DialogBody>
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
        {step === 1 && <FormUserAuthentic onSubmit={handleSubmitSignIn} />}
        {step === 2 && (
          <FormSecurityQuestion
            questions={questions}
            onSubmit={handleSubmitSecurityQuestion}
          />
        )}
        {step === 3 && (
          <FormChangePassword onSubmit={handleSubmitChangePassword} />
        )}
      </DialogBody>
    </Dialog>
  );
};

export default DialogForgotPassword;
