import {
  useFieldArray,
  useFormState,
  Control,
  UseFormWatch,
} from 'react-hook-form';
import { Observer } from 'mobx-react-lite';

import { DropdownRadiologist } from 'components/project/dropdown/RadiologistDropdown';
import { DropdownRadiology } from 'components/project/dropdown/RadiologyDropdown';
import DropdownReportType from 'components/project/dropdown/ReportType';
import { FileUploadControl } from 'components/form/input/FileUpload';
import { FakeInput, Input } from 'components/form/textField';
import { Checkbox } from 'components/form/checkbox';
import { Button } from 'components/button';
import GroupButtons from './GroupButtons';

import {
  defaultTranscriptionValue,
  PermissionType,
  TranscriptionFormType,
} from 'stores/_mobx/workflow/order/transcription';

interface PropsType {
  permission: PermissionType;
  control: Control<TranscriptionFormType, any>;
  isEditMode: boolean;
  reValidate: () => void;
  watch: UseFormWatch<TranscriptionFormType>;
  onAddInterpretation: (idx: number) => void;
  onClickAddRadiologist: (payload: {
    id: number;
    transcriptionIdx: number;
  }) => void;
}

const Form = ({
  permission,
  isEditMode,
  control,
  reValidate,
  watch,
  onAddInterpretation,
  onClickAddRadiologist,
}: PropsType) => {
  const { fields, remove, insert } = useFieldArray({
    control,
    name: 'transcriptions',
    keyName: 'fieldId',
  });
  const { errors } = useFormState({ control });

  const transcriptionsValue = watch('transcriptions');

  const handleRemoveCpt = (idx: number) => {
    remove(idx);
    reValidate();
  };

  const handleInsertCpt = (idx: number) => {
    const { flagNeedAddendum, needAddendumFromRadiologyGroupId, ...rest } =
      transcriptionsValue[idx];

    const isFinalAdded = transcriptionsValue
      .filter((procedure) => procedure.cptSessionNo === rest.cptSessionNo)
      .some((procedure) => procedure.reportType === 'F');

    const newTranscription = {
      ...defaultTranscriptionValue,
      reportType: isFinalAdded ? defaultTranscriptionValue.reportType : 'F',
      cptCode: rest.cptCode,
      cptDescription: rest.cptDescription,
      cptSessionNo: rest.cptSessionNo,
      radiologyGroupId: flagNeedAddendum
        ? needAddendumFromRadiologyGroupId
        : rest.radiologyGroupId,
      radiologistId: flagNeedAddendum ? 0 : rest.radiologistId,
      radiologistName: flagNeedAddendum ? '' : rest.radiologistName,
    };

    insert(++idx, newTranscription);
    reValidate();
  };

  return (
    <form>
      {fields.map((field, idx) => {
        const currentValue = transcriptionsValue[idx];

        const isInterpretationDisabled =
          !currentValue.radiologistId || !currentValue.reportType;

        const isDeleteDisabled =
          Boolean(currentValue.refid) || fields.length <= 1;

        const errorAddendum =
          errors?.transcriptions?.[idx]?.addendumValidation?.message || '';

        const errorInterpretation =
          errors?.transcriptions?.[idx]?.interpretationHash?.message || '';

        return (
          <div
            key={field.fieldId}
            className={`row grouped-fields-block${
              field.flagNeedAddendum ? ' fill-warning' : ''
            }`}>
            <DropdownRadiology
              name={`transcriptions.${idx}.radiologyGroupId`}
              label="Radiology Group"
              className="col-md-6 col-lg-5"
              menuPlacement="bottom"
              required
              control={control}
            />
            <DropdownRadiologist
              name={`transcriptions.${idx}.radiologistId`}
              radiologyGroupId={currentValue.radiologyGroupId}
              defaultRadiologistName={currentValue.radiologistName}
              label="Radiologist"
              className="col-md-6 col-lg-5"
              menuPlacement="bottom"
              required
              control={control}
            />
            <div className="col-md-6 col-lg-2" style={{ paddingTop: '26px' }}>
              <Button
                text="Add"
                className="w-100"
                disabled //TODO: First of all we need to fix flow on Backend of adding Radiologist to the group, then we can un-disable this button
                onClick={() => {
                  onClickAddRadiologist({
                    id: currentValue.radiologyGroupId,
                    transcriptionIdx: idx,
                  });
                }}
              />
            </div>
            <div />
            <FakeInput
              label="Accession Number"
              value={currentValue.cptSessionNo}
              className="col-md-3 col-lg-2"
            />
            <Input
              className="col-md-3 col-lg-2"
              label="CPT Code"
              name={`transcriptions.${idx}.cptCode`}
              disabled
              control={control}
            />
            <Input
              className="col-md-6 col-lg-6"
              name={`transcriptions.${idx}.cptDescription`}
              label="CPT Description"
              disabled
              control={control}
            />
            <DropdownReportType
              className="col-md-6 col-lg-2"
              name={`transcriptions.${idx}.reportType`}
              label="Report Type"
              required
              disabled={currentValue.flagNeedAddendum}
              disableFinalOption={isEditMode}
              control={control}
            />
            <div />
            <div className="col-sm-9 col-lg-6">
              <FileUploadControl
                accept="application/pdf"
                name={`transcriptions.${idx}.filePath`}
                pathPrefix={
                  currentValue.useTempFolder
                    ? 'tempfolder/'
                    : '/doc_img/documents'
                }
                fileName={currentValue.fileName || ''}
                control={control}
              />
            </div>
            <div className="formfield-holder formfield-text col-sm-3 col-lg-3">
              <Observer>
                {() =>
                  permission.edit && (
                    <Button
                      text="Interpretation"
                      data-testid="interpretation"
                      disabled={isInterpretationDisabled}
                      onClick={() => onAddInterpretation(idx)}
                    />
                  )
                }
              </Observer>
            </div>
            <Checkbox
              label="Critical"
              className="col-sm-6 col-lg-1 text-danger label-bold"
              name={`transcriptions.${idx}.critical`}
              control={control}
            />
            <Checkbox
              className="col-sm-6 col-lg-2"
              name={`transcriptions.${idx}.flagNeedAddendum`}
              label="Needs Addendum"
              disabled
              control={control}
            />
            <div />
            <p className="text-secondary col-sm-12">
              <i>
                In order to create an Interpretation you must select Radiology
                Group, Radiologist and Report Type first.
              </i>
            </p>
            {errorInterpretation || errorAddendum ? (
              <div className="text-danger mb-3">
                {errorInterpretation ? <div>{errorInterpretation}</div> : null}
                {errorAddendum ? <div>{errorAddendum}</div> : null}
              </div>
            ) : null}
            {isEditMode ? null : (
              <GroupButtons
                idx={idx}
                permission={permission}
                isDeleteDisabled={isDeleteDisabled}
                onInsert={handleInsertCpt}
                onRemove={handleRemoveCpt}
              />
            )}
          </div>
        );
      })}
    </form>
  );
};

export default Form;
