import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import MaskInput from 'components/form/maskInput';
import { Radio } from 'components/form/radio';
import { Input, InputPassword } from 'components/form/textField';
import RadioOptions from 'components/project/common/RadioOptions';
import { DropdownSalutation } from 'components/project/dropdown/SalutationDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropdownSpecialty } from 'components/project/dropdown/SpecialtyDropdown';
import { DropdownTaxonomyCode } from 'components/project/dropdown/TaxonomyCodeDropdown';
import { InputNpiExtended } from 'components/project/NpiLookupInput';
import validation from './validation';

import { FormPartialPhysicianType } from 'stores/_mobx/clinicianManager/physician';
import { NpiType } from 'stores/_mobx/npi';
import physicianPrettier from 'utils/physicianPrettier';

const defaultValues: FormPartialPhysicianType = {
  address: '',
  city: '',
  contactAddress: '',
  contactCity: '',
  contactPhone: '',
  contactState: '',
  contactZipCode: '',
  fax: '',
  firstName: '',
  gender: '',
  lastName: '',
  middleName: '',
  npi: '',
  phone: '',
  provider_status: 'A',
  pwd: '',
  repeatPassword: '',
  salutation: 0,
  specialty: 0,
  state: 0,
  taxonomy_code: 0,
  username: '',
  zipCode: '',
};

type KeyOfValues = keyof typeof defaultValues;

interface PropsType {
  fetching: boolean;
  refResetButton: React.MutableRefObject<HTMLButtonElement>;
  refSubmitButton: React.MutableRefObject<HTMLButtonElement>;
  passwordOptions: {
    minLength: number;
    maxLength: number;
    alphaNumeric: boolean;
  };
  onSubmit: (
    data: FormPartialPhysicianType
  ) => Promise<null | Partial<Record<KeyOfValues, string>>>;
}

const PhysicianPartialInformationForm = ({
  fetching,
  passwordOptions,
  refResetButton,
  refSubmitButton,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting },
    reset,
    getValues,
    handleSubmit,
    setValue,
    trigger,
    setError,
    watch,
  } = useForm<FormPartialPhysicianType>({
    defaultValues,
    resolver: validation(passwordOptions),
  });

  const handlePhysicianSelected = (physicianData: NpiType) => {
    const selectedPhysician = physicianPrettier(physicianData);
    const formValues = getValues();

    reset(
      {
        ...formValues,
        ...selectedPhysician,
      },
      { keepDirty: true, keepTouched: true }
    );
  };

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: FormPartialPhysicianType) =>
    onSubmit(data).then((error) => {
      if (error) {
        Object.entries(error).forEach(([fieldName, message]) => {
          setError(fieldName as KeyOfValues, {
            type: 'server',
            message,
          });
        });
      }
    })
  );

  useEffect(() => {
    const subscription = watch((formValue, { name, type }) => {
      if (
        (name === 'taxonomy_code' || name === 'specialty') &&
        type === 'change'
      ) {
        const fieldName =
          name === 'taxonomy_code' ? 'specialty' : 'taxonomy_code';

        const value =
          name === 'taxonomy_code'
            ? formValue.taxonomy_code
            : formValue.specialty;

        setValue(fieldName, value);
        trigger(fieldName);
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form
      className={`row${fetching || isSubmitting ? ' on-ajax' : ''}`}
      onSubmit={handleClickSubmit}>
      <div className="col-md-6">
        <Input name="lastName" label="Last name" required control={control} />
        <Input name="middleName" label="Middle name" control={control} />
        <Input name="firstName" label="First name" required control={control} />
        <MaskInput
          name="phone"
          label="Phone number"
          format="###-###-####"
          control={control}
        />
        <InputNpiExtended
          name="npi"
          label="NPI"
          required
          control={control}
          onPhysicianSelected={handlePhysicianSelected}
        />
        <Input name="username" label="Username" control={control} />
        <InputPassword name="pwd" label="Password" control={control} />
        <InputPassword
          name="repeatPassword"
          label="Repeat password"
          control={control}
        />
        <Input name="email" label="Contact email" control={control} />

        <Radio
          name="gender"
          label="Gender"
          className="part-inline"
          options={RadioOptions.normalGender}
          control={control}
        />

        <DropdownSalutation
          name="salutation"
          label="Salutation"
          control={control}
        />
      </div>

      <div className="col-md-6">
        <Input name="address" label="Address" control={control} />
        <Input name="city" label="City" control={control} />
        <MaskInput
          name="fax"
          label="Fax"
          format="###-###-####"
          control={control}
        />
        <MaskInput
          name="zipCode"
          label="ZIP Code"
          format="#####-####"
          control={control}
        />
        <DropDownState name="state" label="State" control={control} />

        <Input
          name="contactAddress"
          label="Contact address"
          control={control}
        />
        <Input name="contactCity" label="Contact city" control={control} />
        <MaskInput
          name="contactPhone"
          label="Contact phone"
          format="###-###-####"
          control={control}
        />
        <MaskInput
          name="contactZipCode"
          label="Contact ZIP code"
          format="#####-####"
          control={control}
        />
        <Input name="contactState" label="Contact state" control={control} />
        <DropdownSpecialty
          name="specialty"
          label="Specialty"
          control={control}
        />
        <DropdownTaxonomyCode
          name="taxonomy_code"
          label="Taxonomy code"
          control={control}
        />
      </div>
      <button
        type="button"
        className="d-none"
        ref={refResetButton}
        onClick={handleClickReset}
      />
      <button type="submit" className="d-none" ref={refSubmitButton} />
    </form>
  );
};

export default PhysicianPartialInformationForm;
