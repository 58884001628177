import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import GridActionControls from 'page/components/GridActionControls';
import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';

import {
  storeEmailGroup,
  EmailGroupType,
} from 'stores/_mobx/systemSetup/masterSetting/emailGroup';
import {
  URL_MASTER_SETTINGS,
  URL_EMAIL_GROUP_ADD,
  URL_EMAIL_GROUP,
} from 'constant/path/systemSetup';
import { MS_EMAIL_GROUP as PAGE_ID } from 'constant/pagesId/systemSetup';

const columns = [
  {
    name: 'groupname',
    title: 'Group Name',
  },
  {
    name: 'groupdesc',
    title: 'Group Description',
  },
  {
    name: 'totalmemcount',
    title: 'Members',
  },
  {
    name: 'action',
    title: 'Action',
    render: (value: undefined, { refid }: EmailGroupType) => (
      <GridActionControls
        id={refid}
        url={`${URL_EMAIL_GROUP}/edit/${refid}`}
        onDelete={storeEmailGroup.setIdForDelete}
      />
    ),
  },
];

const EmailGroupListPage = () => {
  const {
    fetching,
    groupList,
    groupTotal,
    idForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeEmailGroup;

  const [groupName, setGroupName] = useState<string>('');

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleChangeGroupName = (value: string) => {
    setGroupName(value);
    debouncedMethod();
  };

  const fetchData = () => {
    storeEmailGroup.getGroupListMain(groupName);
  };

  const handleApproveDelete = () => {
    storeEmailGroup.deleteGroup().then((isSucceed) => {
      if (isSucceed) fetchData();
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Email Group">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        className="col-md-6 col-lg-4 col-xl-3"
        placeholder="Group Name"
        name="groupName"
        value={groupName}
        onChange={handleChangeGroupName}
      />

      <Grid
        id="email_group_grid"
        onAjax={fetching}
        columns={columns}
        dataSource={groupList}
        dataSourceCount={groupTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_EMAIL_GROUP_ADD} />
        }
      />
      {idForDelete ? (
        <DialogConfirm
          onCancel={storeEmailGroup.clearIdForDelete}
          onApprove={handleApproveDelete}>
          Do you really want to delete this group?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(EmailGroupListPage);
