interface Props {
  className?: string;
  children: any;
}

const DialogBody = ({ className = '', children }: Props) => (
  <div className={`modal-body p-4 ${className}`}>{children}</div>
);

export default DialogBody;
