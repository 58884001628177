import { makeObservable, action, observable } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import { storeImport } from 'stores/_mobx/import';
import Pagination from 'stores/_mobx/options/pagination';

const urlForArchive = {
  S: 'codemaster.StateCPTCode.DeleteCPTCode', // Jurisdiction
  C: 'codemaster.CorporateCPTCode.DeleteCPTCode', // Corporation
  F: 'codemaster.FacilityCPTCode.DeleteCPTCode', // Facility
};

const urlForImport = {
  S: 'codemaster.StateCPTCode.ImportCPTSCode', // Jurisdiction
  C: 'codemaster.FacilityCPTCode.ImportCPTFCodeBycorpate', // Corporation
  F: '', // Facility
};

const urlForFeeCount = {
  S: 'codemaster.StateCPTCode.StateCPTCodeCount', // Jurisdiction
  C: 'codemaster.CorporateCPTCode.CorporateCPTCodeTotalCount', // Corporation
  F: 'codemaster.FacilityCPTCode.CPTCodeTotalCount', // Facility
};

const urlForFeeList = {
  S: 'codemaster.StateCPTCode.SetupStateCPTCodeList', // Jurisdiction
  C: 'codemaster.CorporateCPTCode.CorporateCPTCodeList', // Corporation
  F: 'codemaster.FacilityCPTCode.CPTCodeList', // Facility
};

const urlForUpdateFee = {
  S: 'codemaster.StateCPTCode.UpdateStateMedicare', // Jurisdiction
  C: 'codemaster.CorporateCPTCode.UpdateCorporateMedicare', // Corporation
  F: 'codemaster.FacilityCPTCode.UpdateFacilityMedicare', // Facility
};

export type FeeType = 'C' | 'F' | 'S';

export interface FeeDataType {
  cpt_code: string;
  cpt_description: string;
  cpt_price: string;
  flag: 'A' | 'R';
  globalprice: string;
  groupid: number;
  insure_glbl_prc: string;
  insure_prof_prc: string;
  insure_tech_prc: string;
  is_default: string;
  is_default_insure: string;
  modifier_code: string;
  profprice: string;
  refid: number;
  jurisdiction_id?: string;
  corporate_id?: string;
  corportename?: string;
  statename?: null;
  status?: string;
  facility_id: string;
  facilitynm: string;
}

interface FeeListPayloadType {
  id: number;
  groupId: number;
  status: 'A' | 'R';
  feeType: 'C' | 'F' | 'S';
}

interface ScheduleFeeType {
  cpt_status: 'C' | 'F' | 'S' | '';
  groupid: number;
  editId: number;
  refid: number;
}

interface ActionPropsType {
  cpt: 'C' | 'F' | 'S' | '';
  id: number;
  groupId: number;
}

interface ImportFeeSchedulePayloadType {
  cpt: 'S' | 'F' | 'C';
  from: string;
  to: string;
  destinationId: number;
  file: File | '';
}

class FeeScheduleCommon {
  fetching: boolean;
  feeList: FeeDataType[] = [];
  feeTotal: number = 0;
  archiveInProgress: boolean = false;
  editInProgress: boolean = false;
  archiveProps: ActionPropsType | null = null;
  editProps: ActionPropsType | null = null;

  page: Pagination = new Pagination({
    id: 'fee_schedule_grid',
    clearOnUnmount: true,
  });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      feeList: observable,
      feeTotal: observable,
      archiveInProgress: observable,
      editInProgress: observable,
      archiveProps: observable,
      editProps: observable,

      setFetching: action,
      setFeeList: action,
      setFeeTotal: action,
      setArchiveProgress: action,
      setEditProgress: action,
      setArchiveProps: action,
      setEditProps: action,
      clearArchiveProps: action.bound,
      clearEditProps: action.bound,
      clearStore: action.bound,
      clearFee: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFeeList(list: FeeDataType[]) {
    this.feeList = list;
  }

  setFeeTotal(count: number) {
    this.feeTotal = count;
  }

  setArchiveProgress(fetching: boolean) {
    this.archiveInProgress = fetching;
  }

  setEditProgress(fetching: boolean) {
    this.editInProgress = fetching;
  }

  setArchiveProps(props: ScheduleFeeType) {
    this.archiveProps = {
      cpt: props.cpt_status,
      id: props.refid,
      groupId: props.groupid,
    };
  }

  setEditProps(props: ScheduleFeeType) {
    this.editProps = {
      cpt: props.cpt_status,
      id: props.editId,
      groupId: props.groupid,
    };
  }

  clearArchiveProps() {
    this.archiveProps = null;
  }

  clearEditProps() {
    this.editProps = null;
  }

  clearStore() {
    this.clearArchiveProps();
    this.clearEditProps();
  }

  clearFee() {
    this.feeList = [];
    this.feeTotal = 0;
  }

  async archiveFeeSchedule() {
    const { archiveProps } = this;

    if (!archiveProps) return false;

    const url = archiveProps.cpt ? urlForArchive[archiveProps.cpt] : '';

    this.setArchiveProgress(true);

    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: url,
        data: [archiveProps.id, archiveProps.groupId],
      });
      if (response === 'S') {
        Notification.success('Data moved to archive');
        this.clearArchiveProps();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Error occurred!');
      return false;
    } finally {
      this.setArchiveProgress(false);
      this.clearArchiveProps();
    }
  }

  async importFeeSchedule({ cpt, ...rest }: ImportFeeSchedulePayloadType) {
    const url = urlForImport[cpt];

    try {
      const fileName = await storeImport.uploadFile({
        url: 'temppath/tempImportUpload.php',
        file: rest.file || null,
      });

      const response = await apiRequest<'SE' | 'S' | 'E'>({
        url,
        data: [
          fileName,
          rest.destinationId,
          rest.from,
          rest.to,
          '', // payload.groupId,
        ],
      });
      if (response === 'S') {
        Notification.success('CPT code imported');
        return true;
      }
      if (response === 'E') {
        Notification.danger(
          "Can't import CPT code, because such file already exist."
        );
        return false;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Error occurred!');
    }
  }

  async getFeeCount({ id, groupId, status, feeType }: FeeListPayloadType) {
    const url = urlForFeeCount[feeType];

    const data =
      feeType === 'F' ? [id, groupId, status, 'FF'] : [id, groupId, status];
    try {
      const count = await apiRequest<number>({
        url,
        data,
      });

      this.setFeeTotal(count);
    } catch (error) {
      this.setFeeTotal(0);
    }
  }

  async getFeeList({ id, groupId, status, feeType }: FeeListPayloadType) {
    const { pagination } = this.page;

    const url = urlForFeeList[feeType];

    const data =
      feeType === 'F'
        ? [id, groupId, status, 'FF', pagination.skip, pagination.pageSize]
        : [id, groupId, status, pagination.skip, pagination.pageSize];

    try {
      const response = await apiRequest<FeeDataType[]>({
        url,
        data,
      });

      const list = response.map((el) => ({
        ...el,
        refid: Number(el.refid),
        groupid: Number(el.groupid),
      }));

      this.setFeeList(list);
    } catch (error) {
      this.setFeeList([]);
    }
  }

  getFeeListMain(payload: FeeListPayloadType) {
    this.setFetching(true);

    const promiseList = this.getFeeList(payload);

    const promiseCount = this.getFeeCount(payload);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  }

  async updateFee({
    feeType,
    feeList,
  }: {
    feeType: FeeType;
    feeList: FeeDataType[];
  }) {
    const url = urlForUpdateFee[feeType];

    this.setFetching(true);

    try {
      const response = await apiRequest<'SE' | 'S'>({
        url,
        data: [feeList],
      });

      if (response === 'S') {
        Notification.success('Data updated successfully!');

        return true;
      }

      throw Error('');
    } catch (error) {
      Notification.danger('Error occurred');

      return false;
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeFeeScheduleCommon = new FeeScheduleCommon();
