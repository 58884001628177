import { apiRequest } from 'services/RequestService';

export class TUserPermissionLog {
  app_name: string;
  status: 'Enabled' | 'Disabled';
  color: '0xFF0000';
  enable_dt: string;
  disable_dt: string;
  created_by: number;
  created_user_nm: string;
}

class ClientUserPrivilegesPageService {
  loadUserSpecificPermission(userId: number) {
    return apiRequest<Array<{ appid: number; flag: 'Y' | 'N' }>>({
      url: 'permission.PermissionInfo.LoadUserSpecificPermission',
      data: [userId],
    });
  }

  saveChanges(userId: number, ids: Array<number>, isAllowed: Array<string>) {
    return apiRequest<void>({
      url: 'permission.PermissionInfo.SaveUser_Permission',
      data: [userId, ids, isAllowed],
    });
  }
}

export default new ClientUserPrivilegesPageService();
