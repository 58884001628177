import { Dispatcher } from 'flux';

import { TProfile, TPrivilege, TTopMenu } from 'stores/record/DataObjects';
import AbstractStore from 'stores/AbstractStore';
import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import UserProfileRecord, { TAdminInfo } from 'stores/record/UserProfileRecord';
import { topMenuFormatter, sidebarMenuFormatter } from 'utils/MenuUtils';
import {
  BASE_URL_FILE_DIR,
  CLIENTS_WHICH_HAS_ACCESS_TO_LEGACY_PAGE,
} from 'constant/config';
import { URL_LEGACY_PATIENT_DATA } from 'constant/path/patientRecords';

const storage = window.localStorage;

const uselessMenuItems = [
  'Dashboards',
  'Utilization',
  'Financial',
  'Data Analitics',
];

const filterOutMenu = (menu: TTopMenu) => {
  const sub_menu = menu.sub_menu.filter(
    ({ label }) => !uselessMenuItems.includes(label)
  );

  return {
    ...menu,
    sub_menu,
  };
};
export const LOGGED_IN = 'loggedIn';

class PUserProfileStore extends CommonPayload {
  permissions: any;
  unsignedOrders?: number;
}

export class SUserProfileStore {
  success: boolean = false;
  loggedIn: boolean = false;
  loginOutput: any = {};
  isCorrectional: any = null;
  permissionsObjectByAppId: Map<string, TPrivilege> = new Map<
    string,
    TPrivilege
  >();
  userProfile: UserProfileRecord = new UserProfileRecord();
  permissions: Array<TPrivilege>;
  unsignedOrders: boolean = false;
  userId: number = 0;
}

class UserProfileStore extends AbstractStore<
  SUserProfileStore,
  PUserProfileStore
> {
  updateMenuArr(
    menuArr: Array<TTopMenu>,
    updatedItem: TPrivilege
  ): Array<TTopMenu> {
    let menu = menuArr.concat([]);
    let end = false;
    for (let item of menu) {
      if (!item.sub_menu || !Array.isArray(item.sub_menu)) {
        break;
      }
      for (let subMenu of item.sub_menu) {
        if (!subMenu || !Array.isArray(subMenu.menu_item)) {
          break;
        }
        for (let menuItem of subMenu.menu_item) {
          if (menuItem.app_id === updatedItem.app_id) {
            menuItem.app_name = updatedItem.app_name;
            menuItem.url = updatedItem.url;
            end = true;
            break;
          }
        }
        if (end) {
          break;
        }
      }
      if (end) {
        break;
      }
    }
    return menu;
  }

  updatePermission(
    permissions: Array<TPrivilege>,
    updatedItem: TPrivilege
  ): Array<TPrivilege> {
    const newPermissions = permissions.concat([]);
    const index = newPermissions.findIndex((perm: TPrivilege) => {
      return perm.app_id === updatedItem.app_id;
    });
    newPermissions[index].app_name = updatedItem.app_name;
    return newPermissions;
  }

  updatePermissionObject(
    permissionObject: Map<string, TPrivilege>,
    updatedItem: TPrivilege
  ): Map<string, TPrivilege> {
    const newPermissionObject = new Map<string, TPrivilege>(permissionObject);
    if (updatedItem.app_id) {
      newPermissionObject.set(updatedItem.app_id, updatedItem);
    }
    return newPermissionObject;
  }

  getInitialState() {
    const loggedIn = storage.getItem(LOGGED_IN) === 'true';
    const out = new SUserProfileStore();
    out.loggedIn = loggedIn;
    return out;
  }

  reduce(
    state: SUserProfileStore,
    action: PUserProfileStore
  ): SUserProfileStore {
    switch (action.type) {
      case 'user-profile/check-ipin':
      case 'user-profile/is-authorized':
        if (action.type === 'user-profile/check-ipin') {
          const id = Number(action.data?.refid);

          if (state.userId && id !== state.userId) {
            window.location.reload();
            return null;
          }
        }

        return {
          ...processUserLogin(state, action.data, action.permissions),
          unsignedOrders: Boolean(action.unsignedOrders),
          userId: Number(action.data?.refid),
        };
      case 'user-profile/check-login':
        this.getState().loginOutput = action.data;
        break;
      case 'user-profile-is-correctional':
        this.getState().isCorrectional = action.data;
        break;
      case 'user-profile-logout':
        return { ...new SUserProfileStore(), userId: state.userId };
      case 'user-profile-reset-unsigned-orders':
        this.getState().unsignedOrders = false;
        break;
      default:
        break;
    }
    return this.getState();
  }

  getAdminInfo(): TAdminInfo {
    const user = this.getUser();
    return user.admininfo?.[0] || new TAdminInfo();
  }

  getInternalLogoURL(): string {
    const logo = this.getState().userProfile?.admininfo?.[0]?.logo_internalpage;

    return logo
      ? `${BASE_URL_FILE_DIR}${logo.replace(/^\//, '')}`
      : `assets/images/theme/logo_internalpage.png`;
  }

  getUser() {
    return this.getState().userProfile;
  }

  getAppSn() {
    return this.getUser().app_sn || '';
  }

  getUserId(): number {
    return Number(this.getUser()?.refid || 0);
  }

  getTypedUserId() {
    return Number(this.getUser()?.user_id) || 0;
  }

  getUserType() {
    return this.getUser().usertype || null;
  }

  getTechPrivilege() {
    return this.getUser().tech_privilege || null;
  }

  getRecordsAccess() {
    return this.getUser().recordsAccess || 'A';
  }

  getUserTypeId() {
    return Number(this.getUser().usertype_id) || null;
  }

  getLogin() {
    return this.getUser().user_name || null;
  }

  getFacility(): number {
    return Number(this.getUser().facility_id) || 0;
  }

  getFacilityId() {
    if (this.canSeeFacilities()) {
      return 0;
    }
    return this.getFacility();
  }

  getStateId() {
    return Number(this.getUser().staterefid) || 0;
  }

  getStateName() {
    return this.getUser().state || null;
  }

  getLocationId() {
    return Number(this.getUser().locationrefid) || null;
  }

  getRegionId() {
    return this.getUser().regionrefid || null;
  }

  getRegionName() {
    return this.getUser().region || null;
  }

  getLocationName() {
    return this.getUser().location || '';
  }

  getPlaceOfServiceId() {
    return Number(this.getUser().posrefid) || 0;
  }

  getPlaceOfServiceName() {
    return this.getUser().pos || null;
  }

  getCorporateId() {
    return Number(this.getUser().corporate_id) || null;
  }

  isClientUser() {
    const user = this.getUser();
    return user && parseInt(user.type) > 0;
  }

  getSupervisedGroup() {
    return Number(this.getUser().supervisedGroup) || null;
  }

  isGlobalUser() {
    return this.getUser().global_user === 'Y';
  }

  isSuperAdmin() {
    return this.getUser().usertypename.toLowerCase().includes('super admin');
  }

  haveAccess(appId: string): boolean {
    return !!this.getAccess(appId);
  }

  getAccess(appId: string): TPrivilege {
    const permissionsObject = this.getPermissionsObjectByAppId();
    return permissionsObject.get(appId);
  }

  getPermissionsObjectByAppId(): Map<string, TPrivilege> {
    const res: Map<string, TPrivilege> =
      this.getState().permissionsObjectByAppId;
    return res;
  }

  findAppNameByAppId(appId: string) {
    const permissionsObject = this.getAccess(appId);
    return permissionsObject && permissionsObject.app_name;
  }

  canSeeFacilities() {
    return (
      this.getUserType() === 'D' ||
      this.getUserType() === 'L' ||
      this.getUserType() === 'RA' ||
      this.getUserType() === 'RC' ||
      !this.isClientUser() ||
      this.getUser().usertypename.toLowerCase().indexOf('corporate') > -1
    );
  }
}

function permissionsArrayToObjectByAppId(
  permissions: Array<TPrivilege>
): Map<string, TPrivilege> {
  const newPermissionsObject = new Map<string, TPrivilege>();
  if (!Array.isArray(permissions)) {
    return newPermissionsObject;
  }
  permissions.forEach((permission) => {
    if (permission.app_id) {
      newPermissionsObject.set(permission.app_id, permission);
    }
  });
  return newPermissionsObject;
}

const processUserLogin = (
  currentState: SUserProfileStore,
  responseData: TProfile,
  permissions: Array<TPrivilege>
) => {
  if (!responseData) {
    return;
  }
  const status = responseData.status;
  if (status === 'S') {
    const hasAccessToLegacyPage =
      CLIENTS_WHICH_HAS_ACCESS_TO_LEGACY_PAGE.includes(responseData.app_sn);

    // TODO: Delete this filter after useless reports will
    // exclude from response
    const filteredMenu = responseData.menuarr.map((el) =>
      el.app_name === 'Reports' ? filterOutMenu(el) : el
    );

    const sidebar = sidebarMenuFormatter(filteredMenu);

    // Next block of code adds submenu into sidebar
    // for KS-Mobile client only
    if (hasAccessToLegacyPage) {
      const menuItem = sidebar.find(
        ({ parentName }) => parentName === 'Patient Records'
      );

      menuItem?.children?.push({
        app_id: 'legacyPatientInfo',
        app_name: 'Legacy Patient Data',
        group_name: '',
        url: URL_LEGACY_PATIENT_DATA,
      });
    }

    const menu = topMenuFormatter(responseData.menuarr);
    const userProfileLink =
      menu.find(({ app_name }) => app_name === 'Personal Profile')
        ?.defaultUrl || '';

    const userProfile = {
      ...new UserProfileRecord(responseData),
      topMenu: menu.filter(({ app_name }) => app_name !== 'Personal Profile'),
      userProfileLink,
      sidebarMenu: sidebar,
    };

    if (userProfile.admininfo[0]) {
      const { logo_internalpage: logo } = userProfile.admininfo[0];
      userProfile.admininfo[0].logo_internalpage = logo
        ? `${logo}?v=${performance.now()}`
        : '';
    }

    const newState = {
      ...currentState,
      success: true,
      loggedIn: true,
      userProfile,
    } as SUserProfileStore;
    currentState = newState;
    storage.setItem(LOGGED_IN, 'true');
  } else {
    currentState.success = false;
    currentState.loggedIn = false;
    currentState.userProfile = new UserProfileRecord();
    storage.removeItem(LOGGED_IN);
  }
  currentState.permissions = permissions;
  currentState.permissionsObjectByAppId =
    permissionsArrayToObjectByAppId(permissions);

  return currentState;
};

export default new UserProfileStore(
  appDispatcher as Dispatcher<PUserProfileStore>
);
