import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeUltrasound,
  UltrasoundFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/ultrasound';
import { URL_ULTRASOUND } from 'constant/path/workflow';

interface PropsType extends RouteComponentProps {}

const UltrasoundAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: UltrasoundFormModel) =>
    storeUltrasound.addUltrasound(data).then((error) => {
      if (error) return error;
      history.push(URL_ULTRASOUND);
    });

  return (
    <>
      <Title title="Add Ultrasound" />
      <Form onSubmit={handleSubmit} backUrl={URL_ULTRASOUND} />
    </>
  );
};

export default UltrasoundAddPage;
