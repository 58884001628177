import { makeObservable, observable, action, runInAction } from 'mobx';

import CorporateRolesDropdown from 'components/project/dropdown/CorporateRolesDropdown';
import StateRolesDropdown from 'components/project/dropdown/StateRolesDropdown';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { getDateRangeBounds } from 'utils/DateUtils';

const defaultFilterValue: FilterType = {
  dosStart: '',
  dosEnd: '',
  period: 'A',
  corporateId: [],
  stateId: [],
  locationId: [],
  regionId: [],
  facilityId: [],
  technologistId: [],
  physicianId: 0,
  pos: [],
};

export interface FilterType {
  dosStart: string;
  dosEnd: string;
  period: string;
  stateId: number[];
  locationId: number[];
  regionId: number[];
  facilityId: number[];
  corporateId: number[];
  technologistId: number[];
  physicianId: number;
  pos: number[];
}

interface LogType {
  count: number;
  modality: string;
  refid: number;
}

interface ModalitySummaryResponse {
  count: number;
  data: LogType[];
}

class ModalitySummary {
  fetching: boolean = false;
  logList: LogType[] = [];
  logTotal: number = 0;
  filter: FilterType = defaultFilterValue;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'modality_summary_gridId' });

    makeObservable(this, {
      fetching: observable,
      logList: observable,
      logTotal: observable,
      filter: observable,

      setFetching: action,
      setFilter: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFilter(payload: FilterType) {
    this.filter = payload;
  }

  setDefaultFilter() {
    const currentUserFacility = UserProfileStore.getFacilityId();
    const currentUserState = StateRolesDropdown.getStateId();
    const currentUserCorporation = CorporateRolesDropdown.getCorporationId();

    const filter = {
      ...this.filter,
      facilityId: currentUserFacility
        ? [currentUserFacility]
        : this.filter.facilityId,
      stateId: currentUserState ? [currentUserState] : this.filter.stateId,
      corporateId: currentUserCorporation
        ? [currentUserCorporation]
        : this.filter.corporateId,
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const currentUserFacility = UserProfileStore.getFacilityId();
    const currentUserState = StateRolesDropdown.getStateId();
    const currentUserCorporation = CorporateRolesDropdown.getCorporationId();

    const filter = {
      ...defaultFilterValue,
      facilityId: currentUserFacility
        ? [currentUserFacility]
        : defaultFilterValue.facilityId,
      stateId: currentUserState
        ? [currentUserState]
        : defaultFilterValue.stateId,
      corporateId: currentUserCorporation
        ? [currentUserCorporation]
        : defaultFilterValue.corporateId,
    };

    return filter;
  }

  async getModalitySummaryLog(filter: FilterType = this.filter) {
    const { pagination } = this.page;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const payload = [
      {
        skip: pagination.skip,
        pageSize: pagination.pageSize,
      },
      {
        dosFrom: dos.dateFrom,
        dosTo: dos.dateTo,
        state: filter.stateId,
        location: filter.locationId,
        facility: filter.facilityId,
        corporate: filter.corporateId,
        tech: filter.technologistId,
        physician: filter.physicianId,
        pos: filter.pos,
        regionId: filter.regionId,
      },
    ];

    this.setFetching(true);

    try {
      const { count, data } = await apiRequest<ModalitySummaryResponse>({
        url: 'report.ModalitySummaryReport.getOverview',
        data: payload,
      });

      const logList = data.map((log) => ({
        modality: log.modality,
        refid: Number(log.refid),
        count: Number(log.count),
      }));

      runInAction(() => {
        this.logList = logList;
        this.logTotal = count;
      });
    } catch (e) {
      runInAction(() => {
        this.logList = [];
        this.logTotal = 0;
      });
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeModalitySummary = new ModalitySummary();
