import React from 'react';
import Select from 'components/form/input/Select';

export const options = [
  { value: 'PDT', label: 'Pacific' },
  { value: 'MDT', label: 'Mountain' },
  { value: 'CDT', label: 'Central' },
  { value: 'EDT', label: 'Eastern' },
];

export interface PSimpleTimezoneDropdown {
  name: string;
  className: string;
  value: string;
  validations: string;
}

export class SSimpleTimezoneDropdown {}

export default class SimpleTimezoneDropdown extends React.Component<
  PSimpleTimezoneDropdown,
  SSimpleTimezoneDropdown
> {
  static getOptions() {
    return options;
  }
  static getOption(value: string) {
    return (
      SimpleTimezoneDropdown.getOptions().filter((v) => v.value === value)[0] ||
      null
    );
  }

  render() {
    return <Select {...this.props} ref="input" options={options} />;
  }

  getInputState() {
    return this.refs.input
      ? (this.refs.input as Select).getInputState(this.props.value)
      : null;
  }

  getValue() {
    return this.refs.input ? (this.refs.input as Select).getValue() : null;
  }
}
