import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { LayoutSideTitle } from 'components/layout';
import Tabs from 'components/tabs';
import { Button } from 'components/button';
import EditOrderFormPage from 'page/workflow/order/EditOrderFormPage';
import OrderViewComponent from 'page/workflow/order/visit-information/OrderViewComponent';
import ViewResultsTab from 'page/workflow/order/visit-information/ViewResultsTab';
import DialogTranscription from 'page/workflow/order/components/dialogTranscription';
import Text from 'components/form/input/Text';
import Custom from 'components/form/input/Custom';
import EditPatientRecordPage from 'page/patient-records/patientDemographics/patient-record/EditPatientRecordPage';
import VisitComments from 'page/workflow/order/order/VisitComments';
import ViewOtherExams from 'page/workflow/order/order/ViewOtherExams';
import OrderDocuments from 'page/workflow/order/orderDocuments';
import { TEiModel } from 'page/workflow/order/order/ExamInformation';
import { TPiModelExt } from 'page/workflow/order/order/PatientInformationModel';
import AccessUtils from 'utils/AccessUtils';
import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';
import { URL_ORDER_BASE } from 'constant/path/workflow';
import { PERMISSIONS_ORDER_ACTIONS } from 'constant/pagesId/other';
import { storeOrderTranscription } from 'stores/_mobx/workflow/order/transcription';
import { storePriority } from 'stores/_mobx/systemSetup/masterSetting/priority';
import { storeExamType } from 'stores/_mobx/systemSetup/masterSetting/examType';
import { storeDropdownTechnologist } from 'stores/_mobx/dropDown/technologist';

const descClass = 'col-md-6 col-lg-4 col-xl-2';
const genderMapper = {
  M: 'Male',
  F: 'Female',
  U: 'Unknown',
  '': 'Unknown',
};

export interface PVisitInformationPage extends RouteComponentProps {
  selectedOrder: TSignOrderItem;
  defaultTab?: number;
  hideTabs?: (flag: boolean) => void;
  callback?: () => void;
}

export class SVisitInformationPage {
  onAjax: boolean = false;
  selectedTab: number = 0;
  displayOrderInfo: boolean = true;
  showAddPopup: boolean = false;
  callerNameErrors: Array<any> = [];
  callerName: string = '';
  orderDetails: TSignOrderItem;
  permission: { addReport: boolean; editOrder: boolean } = {
    addReport: false,
    editOrder: false,
  };
}

export class VisitInformationPage extends React.Component<
  PVisitInformationPage,
  SVisitInformationPage
> {
  static PERMISSIONS = {
    VISIT_RESULTS: 'vist_results',
    VISIT_INFORMATION: 'vist_information',
    DEMOGRAPHIC: 'demographic',
    DOCUMENT_VIEW: 'order_doc',
    VISIT_INFORMATION_EDIT: 'exam_edit',
    VIEW_OTHER_EXAMS: 'view_other_exams',
  };

  static TITLES: Array<string> = [
    'View Results',
    'Visit Information',
    'Demographic',
    'Visit Comments',
    'Documents',
    'View Other Exams',
  ];

  static TAB_PERMISSION_MAPPING: any = {
    'View Results': VisitInformationPage.PERMISSIONS.VISIT_RESULTS,
    'Visit Information': VisitInformationPage.PERMISSIONS.VISIT_INFORMATION,
    Demographic: VisitInformationPage.PERMISSIONS.DEMOGRAPHIC,
    Documents: VisitInformationPage.PERMISSIONS.DOCUMENT_VIEW,
    'View Other Exams': VisitInformationPage.PERMISSIONS.VIEW_OTHER_EXAMS,
  };

  tabsList: Array<{ label: string }> = [];
  viewReportResults: any = null;

  constructor(props: PVisitInformationPage) {
    super(props);

    const permission = {
      addReport: AccessUtils.checkAccess(PERMISSIONS_ORDER_ACTIONS.ADD_REPORT),
      editOrder: AccessUtils.checkAccess(
        VisitInformationPage.PERMISSIONS.VISIT_INFORMATION_EDIT
      ),
    };

    this.state = {
      ...new SVisitInformationPage(),
      selectedTab: props.defaultTab || 0,
      callerName: props.selectedOrder.order_source_nm,
      orderDetails: props.selectedOrder,
      permission,
    };

    this.tabsList = AccessUtils.filter(
      VisitInformationPage.TITLES,
      VisitInformationPage.TAB_PERMISSION_MAPPING as any
    ).map((label) => ({ label }));
  }

  handleUpdatePatient = (patientData: TPiModelExt) => {
    const patientnm = `${patientData.firstName} ${patientData.lastName}`.trim();

    const orderDetails = {
      ...(this.state.orderDetails || this.props.selectedOrder),
      clientMRN: patientData.clientMRN,
      immigrationNumber: patientData.immigration_id,
      iceNumber: patientData.ice_number,
      inmateNumber: patientData.inmate_number,

      patientnm,
      patientid: patientData.patientId,
      facilityid: patientData.facility,
      patientdob: patientData.dob,
      gender: patientData.patientGender || 'U',
    };

    this.setState({ orderDetails });
  };

  handleUpdateOrder = (orderData: TEiModel) => {
    const {
      cpt_code_session_no2,
      cpt_code1,
      accessionNumbers,
      cptDescriptions,
    } = orderData.GetIntCptArr?.reduce(
      (prev, { accession_number, cpt_code, cpt_description }, idx: number) => {
        const sessionDetails = `${accession_number} - ${cpt_code}`;

        return {
          cpt_code_session_no2:
            prev.cpt_code_session_no2.concat(accession_number),
          cpt_code1: prev.cpt_code1.concat(cpt_code),
          accessionNumbers: prev.accessionNumbers.concat(sessionDetails),
          cptDescriptions: prev.cptDescriptions.concat(
            `${idx + 1}. ${cpt_code} - ${cpt_description}`
          ),
        };
      },
      {
        cpt_code_session_no2: [],
        cpt_code1: [],
        accessionNumbers: [],
        cptDescriptions: [],
      }
    );

    const accessionNumbersStr = accessionNumbers.join(', ') || '';

    const cptdesc = cptDescriptions.join(', ');

    const icd = orderData.GetIntIcdArr || [];

    const phoneResult =
      orderData.FaxPhone?.map(({ Phone }) => Phone)
        .filter(Boolean)
        .join(', ') || '';

    const priority_nm =
      storePriority.findOption(Number(orderData.priority))?.label || '';

    const examtype =
      storeExamType.findOption(Number(orderData.examtypeid))?.label || '';

    const technologistnm =
      storeDropdownTechnologist.findOption(Number(orderData.technologistid))
        ?.label || 'Not Assigned';

    const sheduleservdate1 = orderData.sheduleservdate;

    const orderDetails = {
      ...(this.state.orderDetails || this.props.selectedOrder),
      cpt_code_session_no2,
      cpt_code1,
      technologistnm,
      accessionNumbersStr,
      priority_nm,
      examtype,
      sheduleservdate1,
      phoneResult,
      icd,
      cptdesc,
      comment: orderData.comment,
      GetIntCptArr: orderData.GetIntCptArr,
    };

    this.setState({ orderDetails });
  };

  componentWillUnmount() {
    if (this.props.hideTabs) {
      this.props.hideTabs(false);
    }

    storeOrderTranscription.clearFilter();
    storeOrderTranscription.clearTranscriptions();
  }

  render() {
    const { props } = this;
    const { orderDetails, callerName, showAddPopup, selectedTab } = this.state;

    const {
      VISIT_RESULTS,
      VISIT_INFORMATION,
      DEMOGRAPHIC,
      VIEW_OTHER_EXAMS,
      DOCUMENT_VIEW,
    } = VisitInformationPage.PERMISSIONS;

    return (
      <>
        <LayoutSideTitle title={this.tabsList[selectedTab].label}>
          <>{this.buildLinks()}</>
        </LayoutSideTitle>

        <ul className="row">
          <li className={descClass}>
            Patient Name: &nbsp; <b>{orderDetails.patientnm}</b>
          </li>
          <li className={descClass}>
            DOB: &nbsp; <b>{orderDetails.patientdob}</b>
          </li>
          <li className={descClass}>
            Gender: &nbsp;{' '}
            <b>{genderMapper[orderDetails.gender] || 'Unknown'}</b>
          </li>
          <li className={descClass}>
            Date of Service: &nbsp; <b>{orderDetails.dos}</b>
          </li>
          <li className={descClass}>
            Insurance: &nbsp; <b>{orderDetails.insure}</b>
          </li>
          <li className={descClass}>
            Modality: &nbsp; <b>{orderDetails.examtype}</b>
          </li>
        </ul>
        <hr />

        <Tabs
          tabsList={this.tabsList}
          defaultTabIndex={this.state.selectedTab}
          onClick={this.selectTab}>
          {AccessUtils.checkAccess(VISIT_RESULTS) && (
            // @ts-ignore
            <ViewResultsTab order={orderDetails} />
          )}
          {AccessUtils.checkAccess(VISIT_INFORMATION) && (
            <>
              {this.state.displayOrderInfo ? (
                <OrderViewComponent {...props} order={orderDetails} />
              ) : (
                <EditOrderFormPage
                  {...props}
                  params={{
                    id: orderDetails.refid,
                    facilityId: orderDetails.facilityid,
                  }}
                  hideTitle
                  backToListCallBack={props.callback}
                  handleUpdateOrder={this.handleUpdateOrder}
                  // @ts-ignore
                  handleUpdatePatient={this.handleUpdatePatient}
                  callerName={callerName}
                  order={orderDetails}
                  noPadding
                  onEdit
                  submitCaller={this.openOrderView}
                />
              )}
            </>
          )}
          {AccessUtils.checkAccess(DEMOGRAPHIC) && (
            <EditPatientRecordPage
              // @ts-ignore
              match={{ params: { id: `${orderDetails.patientid}` } }}
              noTitle
            />
          )}

          <VisitComments orderId={orderDetails.refid} />

          {AccessUtils.checkAccess(DOCUMENT_VIEW) && (
            <OrderDocuments
              orderId={orderDetails.refid}
              patientId={orderDetails.patientid}
            />
          )}
          {AccessUtils.checkAccess(VIEW_OTHER_EXAMS) && (
            <ViewOtherExams patientId={orderDetails.patientid} />
          )}
        </Tabs>
        {showAddPopup && (
          <DialogTranscription
            orderId={orderDetails.refid}
            patientInfo={{
              patientName: orderDetails.patientnm,
              dob: orderDetails.patientdob,
              dos: orderDetails.dos,
              gender: orderDetails.gender as 'M' | 'F' | '',
              examType: orderDetails.examtype,
              id: Number(orderDetails.patientid),
            }}
            onClose={this.handleCloseTranscription}
          />
        )}
      </>
    );
  }

  handleOpenTranscription = () => {
    this.setState({ showAddPopup: true });
  };

  handleCloseTranscription = (shouldUpdate: boolean) => {
    let clb;
    if (shouldUpdate) {
      clb = () =>
        storeOrderTranscription.getTranscriptionMain({
          ...storeOrderTranscription.filter,
          orderId: this.props.selectedOrder.refid,
          patientId: this.props.selectedOrder.patientid,
          pagination: storeOrderTranscription.page.pagination,
        });
    }
    this.setState({ showAddPopup: false }, clb);
  };

  handleClickBackToList = () => {
    if (this.props.callback) this.props.callback();
    else this.props.history.push(URL_ORDER_BASE);
  };

  buildLinks = () => {
    const { displayOrderInfo, selectedTab, permission } = this.state;
    let out: React.ReactNode =
      !selectedTab && permission.addReport ? (
        <Button
          text="Add Report"
          className="width-100"
          onClick={this.handleOpenTranscription}
        />
      ) : (
        <div className="width-100" />
      );

    if (selectedTab === 1) {
      if (permission.editOrder) {
        out = displayOrderInfo ? (
          <Button
            className="width-100"
            text="Edit"
            onClick={this.displayOrderEdit}
          />
        ) : (
          <>
            <Text
              name="callerName"
              className="caller-name-input"
              validations="required"
              value={this.state.callerName}
              errors={this.state.callerNameErrors}
              onSetValue={(n, v, e) =>
                this.setState({
                  callerName: v,
                  callerNameErrors: e,
                })
              }
            />
            <Button
              className="width-100"
              text="Back to view"
              onClick={this.openOrderView}
            />
          </>
        );
      }
    }

    return (
      <>
        {this.state.displayOrderInfo && (
          <Custom
            className="caller-name-input custom"
            label="Caller Name"
            custom={this.state.callerName}
          />
        )}
        {out}
        <Button
          text="Back to List"
          variant="danger"
          onClick={this.handleClickBackToList}
        />
      </>
    );
  };

  openOrderView = (e?: React.MouseEvent) => {
    e?.preventDefault();
    this.setState({ displayOrderInfo: true });
  };

  displayOrderEdit = () => {
    this.setState({ displayOrderInfo: false });
  };

  selectTab = (selectedTab: number, prevTab: number) => {
    if (
      this.tabsList[prevTab].label === 'Visit Information' &&
      this.state.displayOrderInfo === false
    ) {
      this.setState({
        selectedTab,
        displayOrderInfo: true,
      });
    } else {
      this.setState({ selectedTab });
    }
  };
}

export default withRouter(VisitInformationPage);
