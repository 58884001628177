import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import Dialog, { DialogHeader, DialogBody } from 'components/modal/dialog';

import { storeEquipmentLogs } from 'stores/_mobx/workflow/checkOutIn/logs';

const columns = [
  { name: 'technisican', title: 'Technologist' },
  { name: 'check_out_dt', title: 'Check Out Date' },
  { name: 'check_in_dt', title: 'Check In Date' },
];

interface PropsType {
  id: number;
  onClose: () => void;
}

const DialogAccessLog = ({ id, onClose }: PropsType) => {
  const { accessLogs, fetching } = storeEquipmentLogs;

  useEffect(() => {
    storeEquipmentLogs.getAccessLog(id);

    return storeEquipmentLogs.clearAccessLog;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Equipment Access logs" onClose={onClose} />
      <DialogBody>
        <Grid
          columns={columns}
          onAjax={fetching}
          disablePagination
          dataSource={accessLogs}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogAccessLog);
