import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import VisitInformationPage from 'page/workflow/order/VisitInformationPage';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import { LayoutSideTitle } from 'components/layout';
import Tabs from 'components/tabs';
import { Button } from 'components/button';
import ClaimsAudit from './ClaimsAudit';
import TcClaims from './TcClaims';
import PcClaims from './PcClaims';

import { storeBillingAssignment } from 'stores/_mobx/billingCodding/billingAssignment';
import { CLAIM_MANAGER as PAGE_ID } from 'constant/pagesId/billingCodding';
import { URL_CLAIM_MANAGER } from 'constant/path/billingCoding';

const tabsList = [
  { label: 'Claims Audit', url: `${URL_CLAIM_MANAGER}/audit` },
  { label: 'Technical Component Claim', url: `${URL_CLAIM_MANAGER}/tc` },
  { label: 'Professional Component Claims', url: `${URL_CLAIM_MANAGER}/pc` },
];

const ClaimManager = ({ isDivisionEnabled }: PropsTypeWitDivisionSettings) => {
  const { visitDetails } = storeBillingAssignment;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleCloseVisitInfo = () => {
    storeBillingAssignment.clearVisitDetails();
  };

  useEffect(() => storeBillingAssignment.clearVisitDetails, []);

  return visitDetails ? (
    <VisitInformationPage
      selectedOrder={visitDetails}
      callback={handleCloseVisitInfo}
    />
  ) : (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Claim Manager">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          data-testid="toggleFilter"
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Tabs tabsList={tabsList}>
        <ClaimsAudit
          isFilterVisible={isFilterVisible}
          isDivisionEnabled={isDivisionEnabled}
        />
        <TcClaims
          isFilterVisible={isFilterVisible}
          isDivisionEnabled={isDivisionEnabled}
        />
        <PcClaims
          isFilterVisible={isFilterVisible}
          isDivisionEnabled={isDivisionEnabled}
        />
      </Tabs>
    </>
  );
};

export default withDivisionSettings(observer(ClaimManager));
