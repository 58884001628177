import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import UserSelection from 'components/project/users/UserSelection';
import { ControlsLayout } from 'components/layout';
import { Textarea } from 'components/form/textarea';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import { Radio } from 'components/form/radio';
import { TextEditor } from 'components/form/richTextEditor';
import EmailTemplates from './EmailTemplates';
import validation from './validation';

import { EmailType, RecipientType } from 'services/messages/inbox/InboxService';
import { EmailTemplateManualType } from 'stores/_mobx/systemSetup/masterSetting/emailTemplate';

const initialValues: EmailType = {
  to: [],
  toS: '',
  cc: [],
  ccS: '',
  bcc: [],
  bccS: '',
  subject: '',
  priority: 'N',
  body: '',
  submitType: 'Send',
  draftId: 0,
};

interface PropsType {
  backLink?: string;
  defaultValues?: EmailType;
  onSubmit: (data: EmailType) => Promise<any>;
}

enum UserForFieldType {
  Cc = 'cc',
  To = 'to',
  Bcc = 'bcc',
}

const className = 'col-md-6 col-lg-4';

const priorityOptions = [
  { label: 'Normal', value: 'N' },
  { label: 'Urgent', value: 'U' },
  { label: 'ASAP', value: 'M' },
];

const EmailForm = ({
  backLink,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const [fieldName, setFieldName] = useState<UserForFieldType | ''>('');

  const [isDialogDraftOpen, setDialogDraft] = useState<boolean>(false);

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues,
    resolver: yupResolver(validation),
  });

  const handleOpenDialogUser = (fieldName: UserForFieldType) => () => {
    setFieldName(fieldName);
  };

  const handleCloseDialogUser = () => {
    setFieldName('');
  };

  const handleSelectUser = (event: any, items: RecipientType[]) => {
    const valueString: string = items
      .map((user: any) => user?.to_name || user.label)
      .join('; ');

    setValue(`${fieldName}S` as 'toS' | 'ccS' | 'bccS', valueString, {
      shouldValidate: true,
    });
    setValue(fieldName as 'cc' | 'bcc' | 'to', items);

    handleCloseDialogUser();
  };

  const handleToggleDialogTemplates = () => {
    setDialogDraft((state) => !state);
  };

  const handleSelectDraft = ({ subject, body }: EmailTemplateManualType) => {
    setValue('subject', subject);
    setValue('body', body);
    handleToggleDialogTemplates();
  };

  const handleClickSaveToDrafts = () => {
    handleSubmit((data: EmailType) =>
      onSubmit({
        ...data,
        submitType: 'Save',
      })
    )();
  };

  return (
    <>
      <form className="row flex-column" onSubmit={handleSubmit(onSubmit)}>
        <input name="to" type="hidden" />
        <Textarea
          name="toS"
          label="To"
          rows={2}
          required
          readOnly
          onClick={handleOpenDialogUser(UserForFieldType.To)}
          className={className}
          control={control}
        />

        <input name="cc" type="hidden" />
        <Textarea
          name="ccS"
          label="Cc"
          readOnly
          rows={2}
          onClick={handleOpenDialogUser(UserForFieldType.Cc)}
          className={className}
          control={control}
        />

        <input name="bcc" type="hidden" />
        <Textarea
          name="bccS"
          label="Bcc"
          readOnly
          rows={2}
          onClick={handleOpenDialogUser(UserForFieldType.Bcc)}
          className={className}
          control={control}
        />

        <Input
          name="subject"
          label="Subject"
          className={className}
          control={control}
        />

        <Radio
          name="priority"
          label="Priority"
          className="inline"
          options={priorityOptions}
          control={control}
        />

        <TextEditor name="body" label="Message" control={control} />

        <ControlsLayout alignX="right" className="mb-5">
          {backLink ? (
            <Link to={backLink} className="btn btn-danger">
              Back
            </Link>
          ) : null}
          <Button
            variant="warning"
            onClick={handleClickSaveToDrafts}
            text="Save to Drafts"
          />
          <Button onClick={handleToggleDialogTemplates} text="Use Draft" />
          <Button type="submit" text="Send" />
        </ControlsLayout>
      </form>
      {fieldName && (
        <UserSelection
          isOpen
          onSubmit={handleSelectUser}
          initialSelected={watch(fieldName)}
          onCancel={handleCloseDialogUser}
        />
      )}
      {isDialogDraftOpen && (
        <EmailTemplates
          onClose={handleToggleDialogTemplates}
          onSelect={handleSelectDraft}
        />
      )}
    </>
  );
};

export default EmailForm;
