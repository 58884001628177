export const isDevMode = process.env.NODE_ENV === 'development';

export const isProdMode = process.env.NODE_ENV === 'production';

export const BACKEND_ROOT = '/api';

export const DIR_WITH_EXAMPLE = `${BACKEND_ROOT}/services/sampleimport`;

export const BASE_URL_FILE_DIR = `${BACKEND_ROOT}/file-storage/`;

/**
@deprecated backend - use backendApi instead
**/
export const backend = `${BACKEND_ROOT}/json.php`;

export const exchange = `${BACKEND_ROOT}/exchange/latticepro/`;

export const SESSION_STORAGE_KEY_FOR_APP_OPTIONS = 'LP_Options';

export const SESSION_STORAGE_KEY_APP_VER = 'code-version';

export const CLIENTS_WHICH_HAS_ACCESS_TO_LEGACY_PAGE = ['KS'];
