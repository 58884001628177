import Select from 'components/form/input/Select';

export default class EkgComponent extends Select {
  getAttributes() {
    var out = super.getAttributes();
    var render = {
      optionRenderer: (option, index, onMouseDown) => {
        switch (option.textcolor) {
          case '0xFF0000':
            return (
              <div onMouseDown={onMouseDown}>
                <div>{option.label}</div>
                <div className="small text-danger">Out Of Service</div>
              </div>
            );
          case '0xFBB917':
            return (
              <div onMouseDown={onMouseDown}>
                <div>{option.label}</div>
                <div className="small text-danger">Checked Out</div>
              </div>
            );
          default:
            return <div onMouseDown={onMouseDown}>{option.label}</div>;
        }
      },
    };
    if (!out) {
      return render;
    } else {
      return { ...out, render };
    }
  }
}
