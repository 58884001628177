import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import resolver from './validation';

import { SpecialtyFormModel } from 'stores/_mobx/systemSetup/masterSetting/specialty';

const initialValues: SpecialtyFormModel = {
  specialtyName: '',
  taxonomyCode: '',
  id: 0,
};

interface PropsType {
  backUrl: string;
  defaultValues?: SpecialtyFormModel;
  onSubmit: (data: SpecialtyFormModel) => Promise<any>;
}

const FormSpecialty = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm<SpecialtyFormModel>({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: SpecialtyFormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('specialtyName', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={`col-lg-8 col-xl-6${isSubmitting ? ' on-ajax' : ''}`}
      onSubmit={handleClickSubmit}>
      <Input
        name="specialtyName"
        label="Taxonomy Name"
        required
        control={control}
      />
      <Input
        name="taxonomyCode"
        label="Taxonomy Code"
        required
        control={control}
      />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={!isDirty || isSubmitting}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormSpecialty;
