import { Container } from 'flux/utils';

import BarChart from 'components/project/common/charts/BarChart';
import AbstractReportPage from 'page/reports/report-creator/AbstractReportPage';
import PhysicianPatientPageActions from 'actions/reports/custom-reports/physician-patient/PhysicianPatientPageActions';
import PhysicianPatientPageStore from 'stores/reports/custom-reports/physician-patient/PhysicianPatientPageStore';
import { PATIENT_REPORT as PAGE_ID } from 'constant/pagesId/reports';

const COLUMNS = [
  { name: 'studyid', title: 'Study ID' },
  { name: 'mrn', title: 'MRN' },
  { name: 'patientname', title: 'Patient Name' },
  { name: 'faci_name', title: 'Facility' },
  { name: 'physicianname', title: 'Physician' },
  { name: 'dos', title: 'DOS', className: 'text-nowrap', dataType: 'date' },
];

export class PhysicianPatientPage extends AbstractReportPage {
  static getStores() {
    return [PhysicianPatientPageStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = AbstractReportPage.getPageState('physician_patient_grid_id');
      prevState.paginationStrategy = AbstractReportPage.LOGIC_PAGINATION;
    }
    return {
      ...prevState,
      appId: PAGE_ID.PAGE,
      dataSource: PhysicianPatientPageStore.getState().dataSource,
      chartValues: PhysicianPatientPageStore.getState().chart,
    };
  }

  getShownFilters() {
    return [
      'patientFirstName',
      'patientLastName',
      'mrn',
      'studyID',
      'physicianFirstName',
      'physicianLastName',
      'facility',
      'dob',
      'cpt',
      'diagnosis',
    ];
  }

  getTitle() {
    return 'Physician - Patient';
  }

  getGridId() {
    return 'physician_patient_grid_id';
  }

  getColumns() {
    return COLUMNS;
  }

  renderChart() {
    return <BarChart data={this.state.chartValues} />;
  }

  loadListAction(conditionList, joint) {
    return PhysicianPatientPageActions.loadList(conditionList, joint);
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }

  getActions() {
    return PhysicianPatientPageActions;
  }
}

const physicianPatientPage = Container.create(PhysicianPatientPage);
export default physicianPatientPage;
