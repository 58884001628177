import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

const defaultFilterValue: FilterType = {
  jurisdictionId: 0,
  stateId: 0,
};

export interface FilterType {
  stateId: number;
  jurisdictionId: number;
}

interface CodeType {
  billJurisdiction: string;
  code: string;
  facility_price: string;
  insurance_price: string;
  no_of_patient: string;
  refid: number;
  state: string;
  stateid: string;
}

interface CodeEditType {
  code: string;
  facility_price: number;
  insurance_price: number;
  no_of_patient: number;
  refid?: number;
  bill_jurisdictionid: number;
  stateid: number;
}

class Q0092 {
  filter: FilterType = defaultFilterValue;
  fetching: boolean = false;
  codesList: CodeType[] = [];
  codesCount: number = 0;
  code?: CodeEditType = undefined;
  codeForDelete: number | null = null;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'q0092_grid' });

    makeObservable(this, {
      fetching: observable,
      codesList: observable,
      codesCount: observable,
      code: observable,
      codeForDelete: observable,
      filter: observable,

      setFetching: action,
      setCodesList: action,
      setCodesTotal: action,
      setCode: action,
      setCodeForDelete: action,
      setFilter: action.bound,
      clearCodeForDelete: action.bound,
      clearCode: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setCodesList(list: CodeType[]) {
    this.codesList = list;
  }

  setCodesTotal(count: number) {
    this.codesCount = count;
  }

  setCode(code?: CodeEditType) {
    this.code = code;
  }

  setCodeForDelete(id: number) {
    this.codeForDelete = id;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  clearCode() {
    this.code = undefined;
  }

  clearCodeForDelete() {
    this.codeForDelete = null;
  }

  async getCodesList() {
    const {
      filter,
      page: { pagination },
    } = this;

    try {
      const data = [
        pagination.skip,
        pagination.pageSize,
        filter.stateId,
        filter.jurisdictionId,
      ];

      const response = await apiRequest<CodeType[] | string>({
        url: 'generalmaster.Q0092Setup.Q0092TotalList',
        data,
      });
      if (Array.isArray(response)) {
        this.setCodesList(response);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setCodesList([]);
    }
  }

  async getCodesCount() {
    const { filter } = this;
    try {
      const count = await apiRequest<string>({
        url: 'generalmaster.Q0092Setup.Q0092TotalRecordCount',
        data: [filter.stateId, filter.jurisdictionId],
      });

      this.setCodesTotal(Number(count) || 0);
    } catch (e) {
      this.setCodesTotal(0);
    }
  }

  getCodesMain() {
    this.setFetching(true);

    const promiseCount = this.getCodesCount();

    const promiseCodesList = this.getCodesList();

    Promise.allSettled([promiseCodesList, promiseCount]).finally(() => {
      this.setFetching(false);
    });
  }

  async getCode(id: number | string) {
    this.setFetching(true);
    try {
      const response = await apiRequest<[Required<CodeEditType>]>({
        url: 'generalmaster.Q0092Setup.ViewQ0092Setup',
        data: [id],
      });
      const transportCode = Array.isArray(response)
        ? {
            code: response[0].code,
            facility_price: Number(response[0].facility_price),
            insurance_price: Number(response[0].insurance_price),
            no_of_patient: Number(response[0].no_of_patient),
            refid: Number(response[0].refid),
            bill_jurisdictionid: Number(response[0].bill_jurisdictionid),
            stateid: Number(response[0].stateid),
          }
        : undefined;

      this.setCode(transportCode);
    } catch (e) {
      this.setCode();
    } finally {
      this.setFetching(false);
    }
  }

  async addCode(payload: CodeEditType) {
    const data = [
      payload.code,
      payload.no_of_patient,
      payload.facility_price,
      payload.insurance_price,
      payload.stateid,
      payload.bill_jurisdictionid,
    ];

    try {
      const result = await apiRequest<'SE' | 'E' | 'S'>({
        url: 'generalmaster.Q0092Setup.AddQ0092Setup',
        data,
      });
      if (result === 'S') {
        Notification.success('Code was added successfully!');
        return null;
      } else if (result === 'E') {
        return {
          message: 'Code for this jurisdiction already exists!',
        };
      }
      throw Error('An error occurred!');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateCode(payload: CodeEditType) {
    const data = [
      payload.refid,
      payload.code,
      payload.no_of_patient,
      payload.facility_price,
      payload.insurance_price,
      payload.stateid,
      payload.bill_jurisdictionid,
    ];

    try {
      const result = await apiRequest<'E' | 'S' | 'SE'>({
        url: 'generalmaster.Q0092Setup.EditQ0092Setup',
        data,
      });

      if (result === 'S') {
        Notification.success('Code was updated successfully!');
        return null;
      } else if (result === 'E') {
        return {
          message: 'Transportation code for this jurisdiction already exists!',
        };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async deleteCode() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'S'>({
        url: 'generalmaster.Q0092Setup.DeleteQ0092Setup',
        data: [this.codeForDelete],
      });
      if (result === 'S') {
        Notification.success('Code was deleted!');
        this.clearCodeForDelete();
        return true;
      } else {
        throw Error('');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }
}

export const storeQ0092 = new Q0092();
