import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    file: yup.mixed().test({
      test: (file: File | string) => Boolean(file),
      message: 'Please, select the file!',
    }),
    documentsType: yup.array().min(1, 'Please, select a type!'),
  })
);

export default validation;
