import { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import DownloadItem from './DownloadItem';

import { storeExporter, RecordType } from 'stores/_mobx/exporter';

const DownloadManager = () => {
  const refIcon = useRef<HTMLAnchorElement>();

  const refMenuDropdown = useRef<HTMLDivElement>();

  const { fetching, records, newRecordsCount } = storeExporter;

  const [isDownloadsOpened, toggleVisibility] = useState<boolean>(false);

  const contentClassName = clsx('downloads-manager dropdown-menu show', {
    'on-ajax': fetching,
  });

  const handleClickDownload = useCallback((subscription: RecordType) => {
    storeExporter.downloadFile(subscription);
    toggleVisibility(false);
  }, []);

  const handleClickMenu = (e: React.SyntheticEvent) => {
    e.preventDefault();

    toggleVisibility((state) => !state);
  };

  const handleCloseMenu = useCallback(({ target }: MouseEvent) => {
    const isChildrenElementClicked = refMenuDropdown.current?.contains(
      target as Node
    );

    const isMenuClicked = refIcon.current?.contains(target as Node);

    if (!isChildrenElementClicked && !isMenuClicked) toggleVisibility(false);
  }, []);

  useEffect(() => {
    if (isDownloadsOpened)
      document.body.addEventListener('click', handleCloseMenu);

    return () => {
      document.body.removeEventListener('click', handleCloseMenu);
    };
  }, [isDownloadsOpened, handleCloseMenu]);

  return (
    <div className="user-menu-item">
      <a href="/" className="menu-icon" ref={refIcon} onClick={handleClickMenu}>
        <span className="menu-icon-content position-relative">
          <i className="bi bi-download" />
          {newRecordsCount ? (
            <span className="downloads-counter">{newRecordsCount}</span>
          ) : null}
        </span>
      </a>
      <div className={`menu-short-info${isDownloadsOpened ? ' d-none' : ''}`}>
        {newRecordsCount ? (
          <>
            <span>{`${newRecordsCount} file${
              newRecordsCount > 1 ? 's have ' : ' has '
            }not been`}</span>
            <span>downloaded yet.</span>
          </>
        ) : (
          <span>Download History</span>
        )}
      </div>

      {isDownloadsOpened && (
        <menu className={contentClassName} ref={refMenuDropdown}>
          <li className="dropdown-info">
            <b>Exports</b>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          {records.length ? (
            records.map((subscription) => (
              <DownloadItem
                key={`${subscription.id}_${subscription.createdAt}`}
                record={subscription}
                onDownload={handleClickDownload}
                onDelete={storeExporter.deleteFile}
              />
            ))
          ) : (
            <li className="dropdown-info">
              There is no report to download yet!
            </li>
          )}
        </menu>
      )}
    </div>
  );
};

export default observer(DownloadManager);
