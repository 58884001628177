import React from 'react';

interface PSortableTH {
  column: any;
  currentSort: { [key: string]: any };
  groupClass?: string;
  draggable?: boolean;
  onDragStart?: (e: any) => void;
  onDragOver?: (e: any) => void;
  onDragEnd?: (e: any) => void;
  onChangeSort?: (name: string, direction?: 0 | 1 | -1) => void;
}

interface State {
  sortOrder: 0 | 1 | -1;
}

export default class SortableTH extends React.Component<PSortableTH, State> {
  state = {
    sortOrder:
      this.props.currentSort !== null ? this.props.currentSort.direction : 0,
  };

  setSortOrder = (sortOrder: 0 | 1 | -1, callback?: () => void) => {
    this.setState({ sortOrder }, callback);
  };

  render() {
    const { props, state } = this;
    const { column } = props;
    let arrowClassName = 'visually-hidden';
    let className = column.className || '';
    if (
      state.sortOrder !== 0 &&
      props.currentSort &&
      props.currentSort.column === column.name
    ) {
      if (state.sortOrder > 0) {
        arrowClassName = 'bi bi-caret-up-fill sorter';
      } else {
        arrowClassName = 'bi bi-caret-down-fill sorter';
      }
      className += ' sorter-enabled';
    }
    if (props.groupClass) {
      className += props.groupClass;
    }

    const group = column.group;
    if (group) {
      className += ' group group-' + group;
    }
    const maxWidth = column.maxWidth ? column.maxWidth : undefined;
    const textAlignHeader = column.textAlignHeader
      ? column.textAlignHeader
      : undefined;

    return (
      <th
        className={className}
        draggable={props.draggable}
        onDragStart={props.onDragStart}
        onDragOver={props.onDragOver}
        onDragEnd={props.onDragEnd}
        style={{
          maxWidth: maxWidth,
          width: maxWidth,
          textAlign: textAlignHeader,
        }}
        onClick={this.changeSortOrder}>
        {props.children}
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            this.changeSortOrder(e);
          }}>
          <i className={arrowClassName} />
        </a>
      </th>
    );
  }

  changeSortOrder = (e: React.MouseEvent) => {
    let sortOrder: 0 | 1 | -1 = -1;
    if (this.props.column.noSort === true) {
      return;
    }
    if (this.state.sortOrder === 0) {
      sortOrder = 1;
    } else if (this.state.sortOrder === 1) {
      sortOrder = -1;
    } else {
      sortOrder = 0;
    }
    const name = this.props.column.name;
    this.setSortOrder(sortOrder, () =>
      this.props.onChangeSort(name, sortOrder)
    );
  };
}
