import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const messageModality = 'Please, select Modality!';

const messageRadiologyGroup = 'Please, select Radiology Group!';

const checkMappingState = (mapping: any[]) =>
  mapping.length === 1 && !mapping[0].examType && !mapping[0].radiologyGroup;

const validation = yupResolver(
  yup.object().shape({
    radiologyGroupToModalityMapping: yup.array().of(
      yup.object().shape({
        examType: yup
          .number()
          .nullable()
          .test({
            test: (value, rest) => {
              const mapping =
                // @ts-ignore
                rest.from[1].value.radiologyGroupToModalityMapping;
              const mappingIsEmpty = checkMappingState(mapping);

              return mappingIsEmpty ? true : Boolean(value);
            },
            message: messageModality,
          }),
        radiologyGroup: yup
          .number()
          .nullable()
          .test({
            test: (value, rest) => {
              const mapping =
                // @ts-ignore
                rest.from[1].value.radiologyGroupToModalityMapping;
              const mappingIsEmpty = checkMappingState(mapping);

              return mappingIsEmpty ? true : Boolean(value);
            },
            message: messageRadiologyGroup,
          }),
      })
    ),
  })
);

export default validation;
