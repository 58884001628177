import React from 'react';
import clsx from 'clsx';

export type Variant = 'primary' | 'danger' | 'warning' | 'default' | 'success';

export interface ButtonProps {
  text?: string;
  children?: any;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  style?: React.CSSProperties;
  variant?: Variant;
  className?: string;
  title?: string;
  fullWidth?: boolean;
  tabIndex?: number;
  'data-testid'?: string;
  onClick?: (e: React.MouseEvent) => void;
}

const Button = ({
  text,
  children,
  disabled = false,
  type = 'button',
  variant = 'primary',
  className,
  fullWidth,
  'data-testid': dataTestId,
  ...rest
}: ButtonProps) => {
  const cn = clsx(
    'btn',
    {
      'full-width': fullWidth,
      'btn-primary': variant === 'primary',
      'btn-danger': variant === 'danger',
      'btn-warning': variant === 'warning',
      'btn-light': variant === 'default',
      'btn-success': variant === 'success',
    },
    className
  );

  const testAttr = dataTestId || type;

  return (
    <button
      className={cn}
      type={type}
      disabled={disabled}
      data-testid={testAttr}
      {...rest}>
      {text || children}
    </button>
  );
};

export default Button;
