import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import FilterNameAndStatus, {
  FilterType,
} from 'page/components/FilterNameAndStatus';
import GridActionControls from 'page/components/GridActionControls';
import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import { IconButton } from 'components/button';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import DialogServicesNotes from '../components/DialogServicesNotes';

import {
  storePhone,
  PhoneType,
} from 'stores/_mobx/workflow/equipmentInventory/phone';
import { EI_PHONES as PAGE_ID } from 'constant/pagesId/workflow';
import {
  URL_PHONE,
  URL_PHONE_ADD,
  URL_EQUIPMENT_INVENTORY,
} from 'constant/path/workflow';

const columns = [
  { name: 'phone_no', title: 'Phone number' },
  { name: 'phone_desc', title: 'Phone description' },
  { name: 'mobileop_name', title: 'Carrier' },
  {
    name: 'notes',
    title: 'History',
    render: (value: undefined, { refid }: PhoneType) => (
      <div className="control">
        <IconButton onClick={() => storePhone.setNoteId(refid)}>
          <i className="icon icon-notes" />
        </IconButton>
      </div>
    ),
  },
  {
    name: 'refid',
    title: 'Actions',
    className: 'width-100',
    render: (id: number) => (
      <GridActionControls
        id={id}
        url={`${URL_PHONE}/edit/${id}`}
        onDelete={storePhone.setIdForDelete}
      />
    ),
  },
];

const PhoneListPage = () => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    phoneList,
    phoneTotal,
    idForDelete,
    noteId,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storePhone;

  const [selected, setSelected] = useState<number[]>([]);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const fetchData = () => {
    storePhone.getPhoneMain();

    if (selected) gridRef.current?.clearSelection();
  };

  const handleConfirmDelete = () => {
    storePhone.deletePhone().then((isSucceed) => {
      if (isSucceed) {
        fetchData();
      }
    });
  };

  const handleChangeStatus = () => {
    storePhone.changePhoneStatus(selected).then((isSucceed) => {
      if (isSucceed) fetchData();
    });
  };

  const handleChangeFilter = (newFilter: FilterType) => {
    filter.setOptions(newFilter);
    debouncedMethod();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Lead">
        <Link to={URL_EQUIPMENT_INVENTORY} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <FilterNameAndStatus
        placeholder="Phone description"
        filter={filter.options}
        fetching={fetching}
        onChangeFilter={handleChangeFilter}
      />

      <Grid
        id="phone_grid"
        selectId="refid"
        ref={gridRef}
        columns={columns}
        onAjax={fetching}
        dataSource={phoneList}
        dataSourceCount={phoneTotal}
        pagination={pagination}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        gridControlPanel={
          <>
            <GridControlButton title="Add" url={URL_PHONE_ADD} />
            <GridControlButton
              title={filter.options.isActive ? 'Deactivate' : 'Activate'}
              onClick={handleChangeStatus}
              disabled={!selected.length}
            />
          </>
        }
      />

      {Boolean(idForDelete) && (
        <DialogConfirm
          onCancel={storePhone.clearIdForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this Phone?
        </DialogConfirm>
      )}

      {Boolean(noteId) && (
        <DialogServicesNotes
          type="L"
          id={noteId}
          onClose={storePhone.clearNoteId}
        />
      )}
    </>
  );
};

export default observer(PhoneListPage);
