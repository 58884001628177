import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { Button } from 'components/button';
import { Textarea } from 'components/form/textarea';
import MaskInput from 'components/form/maskInput';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import DropDownCallType from 'components/project/dropdown/CallType';
import resolver from './validation';

import { FormModel } from 'stores/_mobx/workflow/order/callLog';

const initialValues: FormModel = {
  facilityId: 0,
  callTypeId: 0,
  callerName: '',
  phoneNumber: '',
  reason: '',
  priorityId: '',
  remarks: '',
  id: 0,
};

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
}

const Form = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={isSubmitting ? 'on-ajax' : ''}
      onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <DropDownFacility
          name="facilityId"
          label="Facility"
          className="col-sm-6"
          control={control}
        />
        <DropDownCallType
          name="callTypeId"
          label="Call type"
          className="col-sm-6"
          required
          control={control}
        />

        <Input
          name="callerName"
          label="Caller name"
          className="col-sm-6"
          required
          control={control}
        />
        <MaskInput
          name="phoneNumber"
          label="Phone number"
          format="###-###-####"
          className="col-sm-6"
          required
          control={control}
        />
      </div>

      <Textarea
        name="reason"
        label="Reason"
        required
        rows={2}
        control={control}
      />

      <Textarea name="remarks" label="Remarks" rows={2} control={control} />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default Form;
