import { makeObservable, action, observable } from 'mobx';

import { FaxModel } from 'page/components/DialogFormTextFax';
import { FormModel as EmailModel } from 'page/patient-records/documentManager/documentAudit/components/DialogFormEmail';
import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';

interface PropsForMessageType {
  bill_email: string;
  bill_fax: string;
  id: number;
  name: string;
}

export interface SendFaxPayloadType {
  fax: FaxModel;
  type: 'F' | 'PHY';
  orders?: any[];
  filter: any;
  recipient: {
    refid: number;
    name: string;
    fax: string;
    email: null;
  };
}

export interface SendEmailPayloadType {
  email: EmailModel;
  type: 'F' | 'PHY';
  orders?: any[];
  filter: any;
  recipient: {
    refid: number;
    name: string;
    email: string;
    fax: null;
  };
}

class DocManagerFaxAndMail {
  fetching: boolean = false;
  selectedEmail: PropsForMessageType | null = null;
  selectedFax: PropsForMessageType | null = null;
  newEmail: SendEmailPayloadType | null = null;
  newFax: SendFaxPayloadType | null = null;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      selectedEmail: observable,
      selectedFax: observable,
      newEmail: observable,
      newFax: observable,

      setFetching: action,
      setEmail: action,
      setFax: action,
      setNewEmail: action,
      setNewFax: action,
      clearEmail: action.bound,
      clearFax: action.bound,
      clearNewEmail: action.bound,
      clearNewFax: action.bound,
      clearStore: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setEmail(email: PropsForMessageType) {
    this.selectedEmail = email;
  }

  setFax(fax: PropsForMessageType) {
    this.selectedFax = fax;
  }

  setNewEmail(email: SendEmailPayloadType) {
    this.newEmail = email;
  }

  setNewFax(fax: SendFaxPayloadType) {
    this.newFax = fax;
  }

  clearEmail() {
    this.selectedEmail = null;
  }

  clearFax() {
    this.selectedFax = null;
  }

  clearNewEmail() {
    this.newEmail = null;
  }

  clearNewFax() {
    this.newFax = null;
  }

  clearStore() {
    this.clearEmail();
    this.clearFax();
    this.clearNewEmail();
    this.clearNewFax();
  }

  async sendFax({
    fax,
    recipient,
    type,
    filter,
    orders = null,
  }: SendFaxPayloadType) {
    this.setFetching(true);
    try {
      const data = [fax, recipient, type, orders, filter];

      const response = await apiRequest<'S' | 'SE'>({
        url: 'facility.DocumentManager.saveFax',
        data,
      });
      if (response === 'S') {
        Notification.success('Fax was sent successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Error during sending fax!');
      return false;
    } finally {
      this.setFetching(false);
    }
  }

  async updateFax(payload: { id: number; type: 'F' | 'PHY'; fax: string }) {
    try {
      const response = await apiRequest<'S' | 'SE' | null>({
        url: 'facility.DocumentManager.updateFax',
        data: [payload.id, payload.type, payload.fax],
      });

      if (response === 'S' || response === null) {
        Notification.success('Fax updated successfully!');
      } else {
        throw Error('');
      }
    } catch (e: any) {
      Notification.danger('Error during updating fax number!');
    }
  }

  async sendEmail({
    email,
    recipient,
    type,
    orders = null,
    filter,
  }: SendEmailPayloadType) {
    this.setFetching(true);
    try {
      const data = [email, recipient, type, orders, filter];

      const response = await apiRequest<'S' | 'SE'>({
        url: 'facility.DocumentManager.saveEmail',
        data,
      });
      if (response === 'S') {
        Notification.success('Email sent successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Error during sending email!');
      return false;
    } finally {
      this.setFetching(false);
    }
  }

  async updateEmail(payload: { id: number; type: 'F' | 'PHY'; email: string }) {
    try {
      const response = await apiRequest<'S' | 'SE' | null>({
        url: 'facility.DocumentManager.updateBillingEmail',
        data: [payload.id, payload.type, payload.email],
      });

      if (response === 'S' || response === null) {
        Notification.success('Email updated successfully!');
      } else {
        throw Error('');
      }
    } catch (e: any) {
      Notification.danger('Error during updating email address!');
    }
  }
}

export const storeDocManagerFaxAndMail = new DocManagerFaxAndMail();
