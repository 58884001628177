import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { storeSpecialty } from 'stores/_mobx/systemSetup/masterSetting/specialty';

const validation = yupResolver(
  yup.object().shape({
    specialtyName: yup.string().required('Please, enter the Specialty Name!'),
    taxonomyCode: yup
      .string()
      .required('Please, enter the Taxonomy Code!')
      .when('refid', {
        is: (refid?: string) => !refid,
        then: yup
          .string()
          .test(
            'texonomy_code',
            'Taxonomy Code already exists!',
            async (texonomy_code: string) => {
              const result = await storeSpecialty.validateTaxonomyCode(
                texonomy_code
              );
              return !result;
            }
          ),
        otherwise: yup.string().nullable(),
      }),
  })
);

export default validation;
