import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { phoneValidator } from 'utils/formValidators';

interface PropsType {
  withRecipientNumber: boolean;
}

const validation = (props: PropsType) =>
  yupResolver(
    yup.object().shape({
      to: yup
        .string()
        .test({
          test: (to: string) =>
            props.withRecipientNumber ? to.length > 0 : true,
          message: 'Please, fill in recipient fax number!',
        })
        .test({
          test: (to: string) =>
            props.withRecipientNumber ? phoneValidator(to) : true,
          message: 'Should be in xxx-xxx-xxxx format.',
        }),
      faxFrom: yup.string().test({
        test: (faxFrom: string) =>
          faxFrom.length ? phoneValidator(faxFrom) : true,
        message: 'Should be in xxx-xxx-xxxx format.',
      }),
      body: yup.string().test({
        test: (body: string) => body && body !== '<p><br></p>',
        message: 'Please, fill in fax message!',
      }),
    })
  );

export default validation;
