import React from 'react';

import AbstractInput, {
  PAbstractInput,
  SAbstractInput,
} from 'components/form/input/AbstractInput';
import Checkbox from 'components/form/input/Checkbox';
import { SelectOptionType } from 'components/form/input/Select';

export interface PCheckboxes extends PAbstractInput {
  options: Array<SelectOptionType>;
  value?: any;
  flagAllInvisibleChecked?: boolean;
}

export class SCheckboxes extends SAbstractInput {
  value: any = {};

  constructor(props: PCheckboxes) {
    super();
    this.value = Object.assign({}, props.value);
  }
}

export default class Checkboxes extends AbstractInput<
  PCheckboxes,
  SCheckboxes
> {
  constructor(props: PCheckboxes) {
    super(props);
    this.state = new SCheckboxes(props);
  }

  static getDerivedStateFromProps(
    nextProps: Readonly<PCheckboxes>,
    prevState: SCheckboxes
  ): any {
    prevState.value = Object.assign({}, nextProps.value);
    return null;
  }

  render() {
    const { options, className } = this.props;
    const group = this.buildCheckboxesGroup(options);
    const label = this.buildLabel(null, [this.getLabelValue()]);
    const formFieldClassName =
      'formfield-holder formfield-checkboxes' +
      (className ? ' ' + className : '');
    return this.wrapInput(group, label, false, formFieldClassName);
  }

  onUpdateGroup = (
    name: string,
    value: string,
    event: React.SyntheticEvent
  ) => {
    const out = this.state.value;
    out[name] = value;
    this.setValue(out, event);
  };

  buildCheckboxesGroup(options: Array<SelectOptionType>) {
    const { state, props } = this;
    const dataTestId = props['data-testid'] || props.name;
    const out = [];
    const id = this.getId();
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const radioId =
        'checkboxes_' + id + '_' + i + '_' + option.label + '_' + option.value;
      const optionValue = option.value;
      const checked: boolean = state.value['' + optionValue] === true;
      out.push(
        <Checkbox
          label={option.label}
          name={'' + option.value}
          id={radioId}
          type="checkbox"
          key={radioId}
          checked={checked}
          onSetValue={(
            name: string,
            value: string,
            event: React.SyntheticEvent
          ) => this.onUpdateGroup(name, value, event)}
          attr={{ id: radioId }}
          flagInvisibleChecked={props.flagAllInvisibleChecked}
          data-testid={`${dataTestId}-${option.value}`}
        />
      );
    }
    return out;
  }
}
