import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';

const SIZES: string[] = [
  'Bytes',
  'KB',
  'MB',
  'GB',
  'TB',
  'PB',
  'EB',
  'ZB',
  'YB',
];

const BASE_NUMBER: number = 1024;

export const MAX_CONTENT_SIZE = 16777200;

export const prettierBytes = (byteNumber: number = 0, decimals = 2) => {
  if (byteNumber === 0) {
    return '0 Bytes';
  }

  const validDecimals = decimals < 0 ? 0 : decimals;

  const i = Math.floor(Math.log(byteNumber) / Math.log(BASE_NUMBER));

  return (
    parseFloat((byteNumber / Math.pow(BASE_NUMBER, i)).toFixed(validDecimals)) +
    ' ' +
    SIZES[i]
  );
};

interface PageDataType {
  comment: string;
  html: string;
  name: string;
  refid: number;
  userId: number;
}

class HomePageStore {
  fetching: boolean = true;
  isLoadingStart: boolean = true;
  content: string = '';
  editableContent: string = '';

  constructor() {
    makeObservable(this, {
      fetching: observable,
      isLoadingStart: observable,
      content: observable,
      editableContent: observable,

      setFetching: action,
      setLoading: action,
      setContent: action,
      setEditableContent: action,
      clearContent: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setLoading(fetching: boolean) {
    this.isLoadingStart = fetching;
  }

  setContent(content: string) {
    this.content = content;
  }

  setEditableContent(content: string) {
    this.editableContent = content;
  }

  clearContent() {
    this.editableContent = '';
  }

  async getHomePageContent() {
    try {
      const content = await apiRequest<string>({
        url: 'home.HomePage.getContent',
      });
      this.setContent(content);
    } catch (e: any) {
      this.setContent('');
    } finally {
      this.setLoading(false);
    }
  }

  async getHomePageData() {
    this.setFetching(true);
    try {
      const { html } = await apiRequest<PageDataType>({
        url: 'home.HomePage.getRevision',
        data: [0],
      });
      this.setEditableContent(html);
    } catch (e: any) {
      this.setEditableContent('');
    } finally {
      this.setFetching(false);
    }
  }

  async saveContent(content: string, comment: string = '') {
    runInAction(() => {
      this.fetching = true;
      this.isLoadingStart = true;
      this.content = '';
    });

    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'home.HomePage.saveHomePage',
        data: [content, comment],
      });

      if (response === 'S') {
        Notification.success('Home page was modified successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeHomePage = new HomePageStore();
