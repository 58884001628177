import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';

import { storeAdjustmentCode } from 'stores/_mobx/systemSetup/masterSetting/adjustmentCode';
import { MS_ADJUSTMENT_CODE as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_ADJUSTMENT_CODE,
  URL_ADJUSTMENT_CODE_ADD,
} from 'constant/path/systemSetup';

const columns = [
  { name: 'code_type', title: 'Adjustment code' },
  { name: 'description', title: 'Description' },
  {
    name: 'refid',
    title: 'Action',
    render: (id: number) => (
      <div className="control">
        <Link
          className="bi bi-pencil"
          to={`${URL_ADJUSTMENT_CODE}/edit/${id}`}
        />
        <IconButton onClick={() => storeAdjustmentCode.setIdForDelete(id)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const AdjustmentCodesListPage = () => {
  const {
    fetching,
    codesList,
    codesTotal,
    idForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeAdjustmentCode;

  const [codeName, setCodeName] = useState<string>('');

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleApprove = () => {
    storeAdjustmentCode.deleteCode().then((isSucceed) => {
      if (isSucceed) storeAdjustmentCode.getCodesListMain(codeName);
    });
  };

  const handleChangeFilter = (codeName: string) => {
    setCodeName(codeName);
    debouncedMethod();
  };

  useEffect(() => {
    storeAdjustmentCode.getCodesListMain(codeName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Adjustment Code">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Adjustment code"
        className="col-sm-12 col-md-6 col-lg-4"
        name="codeName"
        value={codeName}
        onChange={handleChangeFilter}
      />
      <Grid
        id="adjustemnt_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={codesList}
        dataSourceCount={codesTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_ADJUSTMENT_CODE_ADD} />
        }
      />
      {idForDelete ? (
        <DialogConfirm
          onCancel={storeAdjustmentCode.clearIdForDelete}
          onApprove={handleApprove}>
          Are you sure you want to delete?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(AdjustmentCodesListPage);
