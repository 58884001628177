import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeEquipment,
  EquipmentFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/other';
import { URL_OTHER_EQUIPMENT } from 'constant/path/workflow';

interface PropsType extends RouteComponentProps {}

const OtherEquipmentAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: EquipmentFormModel) =>
    storeEquipment.addEquipment(data).then((error) => {
      if (error) return error;
      history.push(URL_OTHER_EQUIPMENT);
    });

  return (
    <>
      <Title title="Add Other Equipment" />
      <Form onSubmit={handleSubmit} backUrl={URL_OTHER_EQUIPMENT} />
    </>
  );
};

export default OtherEquipmentAddPage;
