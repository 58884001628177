import React, { memo, useEffect, useState } from 'react';

import { PureSelect } from 'components/form/select';
import { PureInput } from 'components/form/textField';
import { TPagination } from 'components/grid/GridTypes';
import { IconButton } from 'components/button';
import { useDebounce } from 'components/hook';

import { getPagination } from 'utils/getPagination';

const PAGE_SIZE = [
  { label: '1', value: 1 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '200', value: 200 },
  { label: '500', value: 500 },
];

export interface PropsType {
  pagination: TPagination;
  totalCount?: number;
  fetching?: boolean;
  onChangePageSettings: (pagination: TPagination) => void;
}

const Pagination = ({
  totalCount = 0,
  pagination: { page, pageSize } = getPagination(),
  fetching,
  onChangePageSettings,
}: PropsType) => {
  const [innerPage, setInnerPage] = useState<number>(page);

  const debouncedPageChanging = useDebounce(onChangePageSettings);

  const numberOfPages = Number(totalCount)
    ? Math.ceil(totalCount / pageSize)
    : 1;

  const displayTo = innerPage * pageSize;

  const rangeStart = displayTo - pageSize + (totalCount ? 1 : 0);

  const rangeEnd = displayTo > totalCount ? totalCount : displayTo;

  const handlePageChange = (page: number) => {
    setInnerPage(page);
    debouncedPageChanging({
      pageSize,
      skip: (page - 1) * pageSize,
      page,
    });
  };

  const handleChangePageSize = (pageSize: number) => {
    onChangePageSettings({
      page: 1,
      skip: 0,
      pageSize,
    });
  };

  const prevPage = (event: React.MouseEvent) => {
    event.preventDefault();

    handlePageChange(innerPage - 1);
  };

  const nextPage = (event: React.MouseEvent) => {
    event.preventDefault();

    handlePageChange(innerPage + 1);
  };

  useEffect(() => {
    setInnerPage(page);
  }, [page]);

  return (
    <div className="grid-pagination">
      <IconButton
        title="Previous Page"
        disabled={innerPage <= 1 || fetching}
        onClick={prevPage}>
        <i className="bi bi-caret-left-fill" />
      </IconButton>
      <PureInput
        name="page"
        type="number"
        className="page-input"
        isClearable={false}
        disabled={!numberOfPages || fetching}
        min={numberOfPages ? 1 : 0}
        max={numberOfPages}
        value={innerPage}
        onChange={handlePageChange}
      />
      <IconButton
        title="Next Page"
        disabled={displayTo >= totalCount || fetching}
        onClick={nextPage}>
        <i className="bi bi-caret-right-fill" />
      </IconButton>
      <PureSelect
        className="page-size"
        label="Size"
        name="page_size"
        disabled={fetching}
        isClearable={false}
        value={pageSize}
        options={PAGE_SIZE}
        onChange={handleChangePageSize}
      />

      <div data-testid="pagination-summary">{`${rangeStart} - ${rangeEnd} of ${totalCount} items`}</div>
    </div>
  );
};

export default memo(Pagination);
