import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeRejectReason,
  FormModel,
} from 'stores/_mobx/systemSetup/masterSetting/rejectReason';
import { URL_REJECT_REASON } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const RejectReasonAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: FormModel) =>
    storeRejectReason.addReason(data).then((error) => {
      if (error) return error;
      history.push(URL_REJECT_REASON);
    });

  return (
    <>
      <Title title="Add Reject Reason" />
      <Form backUrl={URL_REJECT_REASON} onSubmit={handleSubmit} />
    </>
  );
};

export default RejectReasonAddPage;
