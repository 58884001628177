import { ReduceStore } from 'flux/utils';
import appDispatcher from 'dispatcher/AppDispatcher';
import { dateToLocalTimezone } from 'utils/DateUtils';

let buildActionName = function (name) {
  return 'reports/report-creator/order-report/' + name;
};

export let Actions = {
  LOAD_ORDER_REPORT_LIST: buildActionName('load-order-report-list'),
};

class OrderReportPageStore extends ReduceStore {
  getEmptyDiagram() {
    return [['Order type', 'Count']];
  }

  getInitialState() {
    return {
      orderReportList: [],
      orderReportFullListCount: 0,
      orderReportDiagram: this.getEmptyDiagram(),
    };
  }

  reduce(state, action) {
    switch (action.type) {
      case Actions.LOAD_ORDER_REPORT_LIST: {
        const logs = (action.data.dataval || []).map((log) => ({
          ...log,
          dos: dateToLocalTimezone({ date: log.dos, dateOnly: true }),
        }));

        this.getState().orderReportList = logs;
        this.getState().orderReportFullListCount = action.data.listCount;
        this.getState().orderReportDiagram =
          action.data.chartval || this.getEmptyDiagram();
        break;
      }
      default:
        break;
    }
    return this.getState();
  }

  areEqual(one, two) {
    return false;
  }
}

const orderReportPageStore = new OrderReportPageStore(appDispatcher);
export default orderReportPageStore;
