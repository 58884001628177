import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import DialogImport from 'page/system-setup/components/DialogImport';
import Title from 'components/project/common/title';
import { IconButton } from 'components/button';
import {
  Grid,
  GridExtraControlPanel,
  ICellRendererParams,
  ColDef,
} from 'components/gridModern';
import { currencyFormatter } from 'components/gridModern/helpers';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import Filter from './components/Filter';
import GridControl from './components/GridControl';
import {
  exportHeadCells,
  exampleColumns,
  exampleData,
} from './components/assets';

import {
  storeCodeActivation,
  FilterModel,
  CptType,
} from 'stores/_mobx/systemSetup/codeActivationMaster';
import { CODE_ACTIVATION_MASTER as PAGE_ID } from 'constant/pagesId/systemSetup';
import { URL_CODE_ACTIVATION_MASTER } from 'constant/path/systemSetup';
import { GRID_ID_CODE_ACTIVATION_MASTER } from 'constant/gridsId/systemSetup';

const CodesListPage = () => {
  const {
    fetching,
    codeList,
    codeTotal,
    idForDelete,
    permission,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeCodeActivation;

  const [selected, setSelected] = useState<number[]>([]);

  const [isImportVisible, toggleImportDialog] = useState<boolean>(false);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const columns: ColDef<CptType>[] = useMemo(
    () => [
      {
        headerName: 'Checkbox',
        field: 'id',
        colId: 'cptCodeId',
        pinned: 'left' as 'left',
        width: 50,
        sortable: false,
        resizable: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { field: 'cptCode', headerName: 'CPT Code', width: 100 },
      {
        field: 'cptDescription',
        headerName: 'CPT Description',
        minWidth: 300,
        flex: 1,
      },
      {
        field: 'cptPrice',
        headerName: 'CPT Value',
        valueFormatter: currencyFormatter,
      },
      {
        field: 'cptCategory.title',
        headerName: 'CPT Category',
        width: 180,
      },
      {
        field: 'id',
        colId: 'action',
        headerName: 'Action',
        headerClass: 'text-center',
        width: 100,
        sortable: false,
        cellRenderer: ({ value }: ICellRendererParams<CptType>) => (
          <div className="controls">
            {permission.editCode && (
              <Link
                className="bi bi-pencil"
                to={`${URL_CODE_ACTIVATION_MASTER}/${value}`}
              />
            )}
            {permission.deleteCode && (
              <IconButton
                onClick={() => storeCodeActivation.setIdForDelete(value)}>
                <i className="bi bi-trash" />
              </IconButton>
            )}
          </div>
        ),
      },
    ],
    [permission.editCode, permission.deleteCode]
  );

  const handleFilterChange = (filter: FilterModel) => {
    storeCodeActivation.setFilter(filter);
    debouncedMethod();
  };

  const fetchingData = () => {
    storeCodeActivation.getCodeList();

    setSelected([]);
  };

  const handleChangeStatus = useCallback(() => {
    storeCodeActivation.changeCodeStatus(selected).then((isSucceed) => {
      if (isSucceed) {
        fetchingData();
      }
    });
  }, [selected]);

  const handleToggleImport = useCallback(() => {
    toggleImportDialog((state) => !state);
  }, []);

  const handleApproveDelete = () => {
    storeCodeActivation.deleteCode().then((isSucceed) => {
      if (isSucceed) fetchingData();
    });
  };

  const handleApproveImport = (file: File) => {
    storeCodeActivation.importModifiers(file).then((isSucceed) => {
      if (isSucceed) {
        toggleImportDialog(false);
        fetchingData();
      }
    });
  };

  const handleExportPart = useCallback(() => {
    storeCodeActivation.exportPart(exportHeadCells);
  }, []);

  const handleExportAll = useCallback(() => {
    storeCodeActivation.exportAll(exportHeadCells);
  }, []);

  useEffect(() => {
    fetchingData();
  }, [pagination]);

  useLayoutEffect(() => {
    storeCodeActivation.checkPermission();
  }, []);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Code Activation Master" />

      <Filter fetching={fetching} onFilterChange={handleFilterChange} />

      <GridExtraControlPanel>
        <GridControl
          selected={selected}
          onChangeStatus={handleChangeStatus}
          onToggleImport={handleToggleImport}
          onExportAll={handleExportAll}
          onExportPart={handleExportPart}
        />
      </GridExtraControlPanel>

      <Grid
        id={GRID_ID_CODE_ACTIVATION_MASTER}
        selectByField="id"
        columnDefs={columns}
        isLoading={fetching}
        rowData={codeList}
        totalCount={codeTotal}
        paginationSettings={pagination}
        onPaginationChange={setPagination}
        onSelectChange={setSelected}
      />

      {idForDelete ? (
        <DialogConfirm
          onCancel={storeCodeActivation.clearIdForDelete}
          onApprove={handleApproveDelete}>
          Are you sure you want to delete this CPT Code?
        </DialogConfirm>
      ) : null}

      {isImportVisible && (
        <DialogImport
          fileNameOfExample="sample_code_activation_master.csv"
          columns={exampleColumns}
          exampleData={exampleData}
          onImport={handleApproveImport}
          onClose={handleToggleImport}
        />
      )}
    </>
  );
};

export default observer(CodesListPage);
