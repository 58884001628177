import { forwardRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Controller, Control } from 'react-hook-form';
import clsx from 'clsx';

import PureCheckbox, {
  PureCheckboxProps,
} from 'components/form/checkbox/PureCheckbox';
import AbstractCheckboxes, {
  PAbstractCheckboxes,
  SAbstractCheckboxes,
} from 'components/project/checkboxes/AbstractCheckboxes';

import {
  storeDropdownOrderType,
  OptionType,
} from 'stores/_mobx/dropDown/orderType';

interface CheckboxesPropsType extends PAbstractCheckboxes {
  options: OptionType[];
  ref: any;
}

class OrderTypeCheckboxes extends AbstractCheckboxes<
  CheckboxesPropsType,
  SAbstractCheckboxes
> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const CheckboxesMiddleware = forwardRef<
  OrderTypeCheckboxes,
  PAbstractCheckboxes
>((props: PAbstractCheckboxes, ref) => {
  const { options } = storeDropdownOrderType;

  useEffect(() => {
    storeDropdownOrderType.getOptions();
  }, []);

  return <OrderTypeCheckboxes {...props} ref={ref} options={options} />;
});

export default observer(CheckboxesMiddleware);

interface PropsType
  extends Omit<PureCheckboxProps, 'onChange' | 'onBlur' | 'onFocus'> {
  control: Control<any>;
  displayVariant?: 'multiLine' | 'oneLine';
}

export const OrderTypeCheckboxGroup = observer(
  ({
    control,
    name,
    required = false,
    disabled = false,
    label = '',
    className,
    displayVariant = 'multiLine',
    'data-testid': dataTestId,
    ...rest
  }: PropsType) => {
    const { fetching, options } = storeDropdownOrderType;

    const cn = clsx(
      'formfield-holder formfield-checkboxes part-inline position-relative',
      { required, 'part-inline': displayVariant === 'oneLine' },
      className
    );

    const testAttr = dataTestId || name;

    useEffect(() => {
      storeDropdownOrderType.getOptions();
    }, []);

    return (
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({
          field: { value: currentValueList, onChange, ...field },
          fieldState: { error },
        }) => {
          const errorMessage = (error && error.message) || '';

          return (
            <div className={cn}>
              {label && (
                <div className="formfield-label">
                  <label data-testid={`${testAttr}-label-common`}>
                    {label}
                  </label>
                </div>
              )}
              <div className={`formfield-input${fetching ? ' on-ajax' : ''}`}>
                {options?.map(({ value, label: optionLabel }) => (
                  <PureCheckbox
                    {...field}
                    {...rest}
                    key={optionLabel}
                    disabled={disabled}
                    name={`${name}.${value}`}
                    label={optionLabel}
                    checked={currentValueList[value]}
                    data-testid={`${testAttr}-${value}`}
                    onChange={(checked: boolean) => {
                      onChange({ ...currentValueList, [value]: checked });
                    }}
                  />
                ))}
              </div>
              {errorMessage && (
                <div className="error" data-testid={`${testAttr}-error`}>
                  {errorMessage}
                </div>
              )}
            </div>
          );
        }}
      />
    );
  }
);
