import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

export class PPreInvoicesStore extends CommonPayload {}

export class SPreInvoicesStore {
  invoiceMessages: Array<any> = [];
  dataSource: Array<any> = [];
  dataSourceCount: number = 0;
}

class PreInvoicesStore extends ReduceStore<
  SPreInvoicesStore,
  PPreInvoicesStore
> {
  getInitialState() {
    return new SPreInvoicesStore();
  }

  areEqual(state1: SPreInvoicesStore, state2: SPreInvoicesStore) {
    return false;
  }

  reduce(state: SPreInvoicesStore, action: PPreInvoicesStore) {
    const storeState = this.getState();
    switch (action.type) {
      case 'pre-invoice-overview':
        storeState.dataSource = action.data;
        storeState.dataSourceCount = (action as any).count;
        break;
      case 'facility.Billingassignment.Getinvoicemessages':
        storeState.invoiceMessages = action.data;
        break;
      default:
        break;
    }
    return storeState;
  }
}

export default new PreInvoicesStore(appDispatcher);
