import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import {
  storeBoneSonometer,
  SonometerFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/boneSonometer';
import { URL_BONE_SONOMETER } from 'constant/path/workflow';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const BoneSonometerEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, sonometer } = storeBoneSonometer;

  const handleSubmit = async (data: SonometerFormModel) =>
    storeBoneSonometer.updateSonometer(data).then((error) => {
      if (error) return error;
      history.push(URL_BONE_SONOMETER);
    });

  useEffect(() => {
    if (!fetching) storeBoneSonometer.getSonometer(Number(params.id));

    return storeBoneSonometer.clearSonometer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Bone Sonometer" />
      <Form
        backUrl={URL_BONE_SONOMETER}
        defaultValues={sonometer}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(BoneSonometerEditPage);
