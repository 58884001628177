import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validation = yupResolver(
  yup.object().shape({
    receiversString: yup
      .string()
      .required('Please select at least one receiver name'),
    date: yup.string().required('Please choose date'),
    time: yup.string().required('Invalid time format, must be hh:mm:ss.'),
    subject: yup.string().required("Can't be empty"),
    description: yup.string().required("Can't be empty"),
  })
);

export default validation;
