import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { DropDownFacilityRole } from 'components/project/dropdown/FacilityRolesDropdown';
import { DropDownCorporation } from 'components/project/dropdown/CorporateDropdown';
import { DropDownPriority } from 'components/project/dropdown/PriorityDropdown';
import { options as optionsTimezone } from 'components/project/dropdown/SimpleTimezoneDropdown';
import DropDownTechnologist from 'components/project/dropdown/TechnologistDropDown';
import { Input } from 'components/form/textField';
import { Select } from 'components/form/select';
import { DateRange } from 'components/form/dateRange';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import {
  storeTechReviewExam,
  FilterType,
} from 'stores/_mobx/report/techReviewExam';
import { getTimeZoneName } from 'utils/DateUtils';

interface PropsType {
  isVisible: boolean;
  fetching: boolean;
  onSubmit: (data: FilterType) => void;
}

const Filter = ({ isVisible, fetching, onSubmit }: PropsType) => {
  const { control, watch, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeTechReviewExam.filter,
  });

  const timeZoneName = useMemo(getTimeZoneName, []);

  const handleClickReset = () => {
    const defaultFilterValue = storeTechReviewExam.getDefaultFilter();

    reset(defaultFilterValue);
    onSubmit(defaultFilterValue);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'timezone') {
        storeTechReviewExam.setFilter(formValue as FilterType);
      }
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="patientLastName"
        label="Patient Last Name"
        className="col-sm-12 col-md-6 col-lg-3"
        control={control}
      />
      <Input
        name="patientFirstName"
        label="Patient First Name"
        className="col-sm-12 col-md-6 col-lg-3"
        control={control}
      />
      <DropDownTechnologist
        name="technologistId"
        label="Technologist"
        className="col-sm-12 col-md-6 col-lg-3"
        control={control}
      />
      <DropDownPriority
        name="priorityId"
        label="Priority"
        className="col-sm-12 col-md-6 col-lg-3"
        control={control}
      />
      <div />

      <DropDownFacilityRole
        name="facilityId"
        label="Facility"
        className="col-sm-12 col-md-6 col-lg-3"
        control={control}
      />
      <DropDownCorporation
        name="corporateId"
        label="Corporation"
        className="col-sm-12 col-md-6 col-lg-3"
        control={control}
      />
      <Select
        name="timezone"
        label="Timezone"
        className="col-sm-12 col-md-6 col-lg-3"
        options={optionsTimezone}
        placeholder={timeZoneName}
        control={control}
      />
      <div />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-6 col-lg-auto"
        control={control}
      />

      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          type="reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
