import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { GridControlButton } from 'components/grid';
import {
  Grid,
  GridExtraControlPanel,
  ICellRendererParams,
  ColDef,
} from 'components/gridModern';
import { IconButton } from 'components/button';
import { LayoutSideTitle } from 'components/layout';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import Filter from './components/Filter';

import {
  storeCptCategory,
  CategoryType,
  FilterModel,
} from 'stores/_mobx/systemSetup/masterSetting/cptCategory';
import { MS_CPT_CATEGORY as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_CPT_CATEGORY,
  URL_CPT_CATEGORY_ADD,
} from 'constant/path/systemSetup';
import { GRID_ID_CPT_CATEGORY } from 'constant/gridsId/systemSetup';

const columns: ColDef<CategoryType>[] = [
  {
    headerName: 'Checkbox',
    field: 'id',
    colId: 'checkbox',
    pinned: 'left' as 'left',
    width: 50,
    resizable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  { field: 'title', headerName: 'Category Name', flex: 1 },
  {
    field: 'id',
    colId: 'action',
    headerName: 'Action',
    headerClass: 'text-center',
    width: 100,
    cellRenderer: ({
      value,
      data,
    }: ICellRendererParams<CategoryType, number>) => (
      <div className="controls">
        <Link
          to={`${URL_CPT_CATEGORY}/${value}`}
          onClick={() => storeCptCategory.setCptCategory(data)}>
          <i className="bi bi-pencil" />
        </Link>
        <IconButton
          onClick={() => storeCptCategory.setCptCategoryForDelete(value)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const CptCategoryListPage = () => {
  const {
    fetching,
    filter,
    cptCategoryForDelete,
    cptCategoryList,
    cptCategoryTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeCptCategory;

  const [selected, setSelected] = useState<number[]>([]);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleChangeFilter = (filter: FilterModel) => {
    storeCptCategory.setFilter(filter);

    debouncedMethod();
  };

  const handleChangeStatus = () => {
    storeCptCategory.changeCategoryStatus(selected).then((isSucceed) => {
      if (isSucceed) {
        setSelected([]);
        setPaginationToStart();
      }
    });
  };

  const handleApproveDelete = () => {
    storeCptCategory.deleteCptCategory().then((response) => {
      if (response) storeCptCategory.getCptCategories();
    });
  };

  useEffect(() => {
    storeCptCategory.getCptCategories();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="CPT Category">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <Filter onFilterChange={handleChangeFilter} />

      <GridExtraControlPanel>
        <GridControlButton title="Add" url={URL_CPT_CATEGORY_ADD} />
        <GridControlButton
          title={filter.cptCategoryFlag === 'A' ? 'Deactivate' : 'Activate'}
          disabled={!selected.length}
          onClick={handleChangeStatus}
        />
      </GridExtraControlPanel>
      <Grid
        selectByField="id"
        id={GRID_ID_CPT_CATEGORY}
        columnDefs={columns}
        isLoading={fetching}
        rowData={cptCategoryList}
        totalCount={cptCategoryTotal}
        paginationSettings={pagination}
        onPaginationChange={setPagination}
        onSelectChange={setSelected}
      />

      {cptCategoryForDelete ? (
        <DialogConfirm
          onCancel={storeCptCategory.clearCptCategoryForDelete}
          onApprove={handleApproveDelete}>
          Do you really want to delete this CPT Category?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(CptCategoryListPage);
