import React from 'react';

import spinner from '../../assets/images/spinner.svg';
interface PropsType {
  className?: string;
}

const Spinner = ({ className = '' }: PropsType) => (
  <div className={`spinner ${className}`}>
    <img src={spinner} alt="loading" className="spinner-image" />
  </div>
);

export default Spinner;
