import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';

import { storeDropdownState } from 'stores/_mobx/dropDown/state';

const DropdownUsaStateShortName = (props: Omit<SelectPropsType, 'options'>) => {
  const { fetchingUsaOption, optionsUsaShortName } = storeDropdownState;

  useEffect(() => {
    storeDropdownState.getOptionsUsa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      {...props}
      options={optionsUsaShortName}
      isLoading={fetchingUsaOption}
    />
  );
};

export default observer(DropdownUsaStateShortName);
