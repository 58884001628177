import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import ModifierForm from './components/Form';

import {
  storeModifiers,
  ModifierFormModel,
} from 'stores/_mobx/systemSetup/codeSet/modifiers';
import { URL_MODIFIER } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const ModifierAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: ModifierFormModel) =>
    storeModifiers.addModifier(data).then((error) => {
      if (error) return error;
      history.push(URL_MODIFIER);
    });

  return (
    <>
      <Title title="New Modifier" />
      <ModifierForm backUrl={URL_MODIFIER} onSubmit={handleSubmit} />
    </>
  );
};

export default ModifierAddPage;
