import { useForm } from 'react-hook-form';

import { Input } from 'components/form/textField';
import { DateRange } from 'components/form/dateRange';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';
import DropDownTechnologist from 'components/project/dropdown/TechnologistDropDown';
import { DropDownFacilityRole } from 'components/project/dropdown/FacilityRolesDropdown';

import {
  storeEncounterByTech,
  FilterType,
} from 'stores/_mobx/report/encounterByTech';

const commonClassName = 'col-md-4 col-lg-3';

interface PropsType {
  fetching: boolean;
  isFilterVisible: boolean;
  onSearch: (filter: FilterType) => void;
}

const Filter = ({ fetching, isFilterVisible, onSearch }: PropsType) => {
  const { control, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeEncounterByTech.filter,
  });

  const handleClickReset = () => {
    const defaultFilterValue = storeEncounterByTech.getDefaultFilter();
    reset(defaultFilterValue);
    onSearch(defaultFilterValue);
  };

  return (
    <form
      className={`row${isFilterVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSearch)}>
      <Input
        name="patientLastName"
        label="Patient Last Name"
        className={commonClassName}
        control={control}
      />
      <Input
        name="patientFirstName"
        label="Patient First Name"
        className={commonClassName}
        control={control}
      />
      <DropDownTechnologist
        name="technologistId"
        label="Technologist"
        className={commonClassName}
        control={control}
      />
      <DropDownFacilityRole
        name="facilityId"
        label="Facility"
        className={commonClassName}
        control={control}
      />
      <div />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-4 col-lg-auto"
        control={control}
      />
      <ControlsLayout alignX="right" className="mb-3">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
