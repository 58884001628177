import { memo } from 'react';

import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface PropsType {
  order: TSignOrderItem;
}

const genderMapper = {
  F: 'Female',
  M: 'Male',
};

const commonClassName = 'col-sm-2';

const PatientDetailsBlock = ({ order }: PropsType) => (
  <>
    <legend>Patient Details</legend>
    <dl className="row">
      <dt className={commonClassName}>Patient</dt>
      <dd className={commonClassName}>{order.patientnm}</dd>

      <dt className={commonClassName}>Study ID</dt>
      <dd className={commonClassName}>{order.refid}</dd>

      <dt className={commonClassName}>DOS</dt>
      <dd className={commonClassName}>
        {dateToLocalTimezone({ date: order.dos, dateOnly: true })}
      </dd>

      <dt className={commonClassName}>Facility</dt>
      <dd className={commonClassName}>{order.facilitynm}</dd>

      <dt className={commonClassName}>Gender</dt>
      <dd className={commonClassName}>
        {genderMapper[order.gender as 'M' | 'F'] || order.gender}
      </dd>

      <dt className={commonClassName}>POS</dt>
      <dd className={commonClassName}>{order.pos}</dd>
    </dl>
  </>
);

export default memo(PatientDetailsBlock);
