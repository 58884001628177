import { ReduceStore } from 'flux/utils';
import { Dispatcher } from 'flux';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import {
  TPatientInsuranceInfo,
  TInsurancePlan,
} from 'actions/project/OrganizationActions';

const defaultInsuranceValue: TInsurancePlan = {
  payorType: '',
  payorId: 0,
  payer_type: '',
  payorName: '',
  group: '',
  insuranceNo: '',
  planId: 0,
  groupNo: '',
  insStT: '',
  insEndT: '',
  insPatRelation: '',
  planName: '',
  plan: [],
  msp: 0,
  refid: null,
};
export class POrganizationStore extends CommonPayload {
  isLoaded: boolean = false;
}

export class SOrganizationStore {
  organizationList: Array<any> = [];
  patientInsurance: TPatientInsuranceInfo = new TPatientInsuranceInfo();
  insuranceLoaded: boolean = false;
}

class OrganizationStore extends ReduceStore<
  SOrganizationStore,
  POrganizationStore
> {
  getInitialState() {
    return new SOrganizationStore();
  }

  areEqual(first: SOrganizationStore, second: SOrganizationStore) {
    return false;
  }

  reduce(state: SOrganizationStore, action: POrganizationStore) {
    switch (action.type) {
      case 'patient.PatientInfo.GetPatientInsuranceInfo':
        const primaryPlan = {
          ...(action.data.primaryPlan || defaultInsuranceValue),
          payorId: Number(action.data?.primaryPlan?.payorId) || 0,
        };
        const secondaryPlan = {
          ...(action.data.secondaryPlan || defaultInsuranceValue),
          payorId: Number(action.data?.secondaryPlan?.payorId) || 0,
        };
        const tertiaryPlan = {
          ...(action.data.tertiaryPlan || defaultInsuranceValue),
          payorId: Number(action.data?.tertiaryPlan?.payorId) || 0,
        };

        this.getState().patientInsurance = {
          ...action.data,
          primaryPlan,
          secondaryPlan,
          tertiaryPlan,
        };
        this.getState().insuranceLoaded = action.isLoaded;
        break;
      default:
        break;
    }
    return state;
  }
}

const organizationStore = new OrganizationStore(
  appDispatcher as Dispatcher<POrganizationStore>
);
export default organizationStore;
