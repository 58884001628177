import React, { useMemo } from 'react';

import Custom from 'components/form/input/Custom';
import StateDropdown, {
  DropDownState,
} from 'components/project/dropdown/StateDropdown';
import { PropsType as SelectPropsType } from 'components/form/select/Select';
import { FakeInput } from 'components/form/textField';

import UserProfileStore from 'stores/UserProfileStore';

const availableUserTypes = ['D', 'L', 'RA', 'RC'];

interface PropsType {
  name: string;
  label?: string;
  value?: number;
  className?: string;
  noLabel?: boolean;
  attr?: any;
}

export default class StateRolesDropdown extends React.Component<
  PropsType,
  any
> {
  static getStateId() {
    if (StateRolesDropdown.canSeeStates()) {
      return 0;
    }
    return UserProfileStore.getStateId();
  }

  static canSeeStates() {
    return (
      availableUserTypes.includes(UserProfileStore.getUserType()) ||
      !UserProfileStore.isClientUser() ||
      UserProfileStore.getUser()
        .usertypename.toLowerCase()
        .indexOf('corporate') > -1
    );
  }

  getValue() {
    if (StateRolesDropdown.canSeeStates()) {
      return this.props.value;
    }
    return UserProfileStore.getStateId();
  }

  render() {
    if (StateRolesDropdown.canSeeStates()) {
      return <StateDropdown {...this.props} />;
    }
    return (
      <Custom
        ref="input"
        {...this.props}
        custom={<span>{UserProfileStore.getStateName()}</span>}
      />
    );
  }
}

export const DropdownStateWithPermission = (
  props: Omit<SelectPropsType, 'options'>
) => {
  const hasPermission = useMemo(
    () =>
      availableUserTypes.includes(UserProfileStore.getUserType()) ||
      !UserProfileStore.isClientUser() ||
      UserProfileStore.getUser()
        .usertypename.toLowerCase()
        .includes('corporate'),
    []
  );

  return hasPermission ? (
    <DropDownState {...props} />
  ) : (
    <FakeInput
      className={props.className}
      required={props.required}
      label={props.label}
      value={UserProfileStore.getStateName()}
    />
  );
};
