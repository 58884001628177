import { Container } from 'flux/utils';

import BarChart from 'components/project/common/charts/BarChart';
import AbstractReportPage from '../AbstractReportPage';
import { prettierFilterPayload } from '../filter/prettierFilterPayload';

import StudiesByFacilityPageActions from 'actions/reports/report-creator/studies-by-facility/StudiesByFacilityPageActions';
import StudiesByFacilityPageStore from 'stores/reports/report-creator/studies-by-facility/StudiesByFacilityPageStore';
import { timeZoneRender } from 'utils/DateUtils';
import { FACILITY_STUDIES as PAGE_ID } from 'constant/pagesId/reports';

export class StudiesByFacilityPage extends AbstractReportPage {
  static getStores() {
    return [StudiesByFacilityPageStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = AbstractReportPage.getPageState('studies_by_facility_grid');
      prevState.paginationStrategy = AbstractReportPage.LOGIC_PAGINATION;
    }
    return {
      ...prevState,
      appId: PAGE_ID.PAGE,
      dataSource: StudiesByFacilityPageStore.getState().studiesByFacilityList,
      chartValues:
        StudiesByFacilityPageStore.getState().studiesByFacilityDiagram,
    };
  }

  COLUMNS = [
    { name: 'studyid', title: 'Study ID' },
    { name: 'mrn', title: 'MRN' },
    { name: 'patientnm', title: 'Patient name' },
    { name: 'dob', title: 'DOB', className: 'text-nowrap' },
    { name: 'faci_name', title: 'Facility name' },
    { name: 'facilitynpi', title: 'Facility NPI' },
    { name: 'facilityphone', title: 'Facility phone' },
    { name: 'region', title: 'Region' },
    { name: 'location', title: 'Location' },
    { name: 'providertype', title: 'Provider type' },
    { name: 'ordertype', title: 'Order type' },
    { name: 'pos', title: 'POS' },
    {
      name: 'completed_date',
      title: 'Completed date',
      render: (date) =>
        this.state.timezone && this.state.timezone !== 'CDT'
          ? timeZoneRender(date, this.state.timezone)
          : date,
    },
  ];

  getTitle() {
    return 'Studies by Facility';
  }

  getColumns() {
    return this.COLUMNS;
  }

  getGridID() {
    return 'studies_by_facility_grid';
  }

  getExportedFileName() {
    return 'studies_by_facility';
  }

  getClass() {
    return 'studies-by-facility-overview-page';
  }

  getShownFilters() {
    return [
      'patientFirstName',
      'patientLastName',
      'dob',
      'mrn',
      'facility',
      'diagnosis',
      'cpt',
      'facilityNPI',
      'facilityPhone',
      'examCompletedDate',
      'region',
      'location',
      'providerType',
      'orderType',
      'studyID',
      'pos',
    ];
  }

  renderChart() {
    return <BarChart data={this.state.chartValues} />;
  }

  getActions() {
    return StudiesByFacilityPageActions;
  }

  loadListAction(conditionList, joint) {
    const payload = prettierFilterPayload(conditionList);

    return StudiesByFacilityPageActions.loadStudiesByFacilityList(
      payload,
      joint
    );
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }
  allowTimezoneChange() {
    return true;
  }
}

const studiesByFacilityPage = Container.create(StudiesByFacilityPage);
export default studiesByFacilityPage;
