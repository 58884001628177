import { TPagination } from 'components/grid/GridTypes';
import { apiRequest } from 'services/RequestService';
import { TDayName, dateToLocalTimezone } from 'utils/DateUtils';

export class TClockedInResponse {
  data: Array<{ employee_name: string; clock_start: string }>;
  count: number;
}

export type TTimeSheetFileType = 'undef' | 'expense' | 'schedule';

export class TTimeSheetDoc {
  refid: string;
  created: string;
  fileName: string;
  fileOnDiskName: string;
  comment: string;
  date: string;
  time: string;
}

export class TTimeSheetFullInfoDoc extends TTimeSheetDoc {
  refid: string;
  userId: string;
  fileName: string;
  date: string;
  time: string;
  comment: string;
  uploadedBy: string;
  created: string;
  fileOnDiskName: string;
  fileOnDiskPath: string;
}

export class TTimeSheetData {
  refid: string;
  created: string;
  fileName: string;
  fileOnDiskName: string;
  comment: string;
  date: string;
  time: string;
}

export class TTimeSheetCalendar {
  refid: string = '';
  day_date: string = '';
  day: TDayName = null;
  clock_start: string = '';
  clock_end: string = '';
  over_time: string = '';
  call_hours: string = '';
  vacation: string = '';
  benefits: string = '';
  benefits_type: string = '';
  pto_paid: string = '';
  regular_time: string = null;
  mileage: number = 0;
  expenses: number = 0;
  isSubmitted: 'Y' | 'N' = 'N';
  isLocked: 'Y' | 'N' = 'N';
  errors: any = {};
  total: boolean = false;
  shiftList: Array<TTrackingData> = [];
  hours: string = '';
}

export class TTimeSheetUpdateCalendarDate {
  month: number;
  year: number;
  week: number;
}

export class TWeekNotes {
  comments: string = '';
  date: string = '';
  supervisorComments: string = '';
  timezone: string = '';
}

export class TNotesData {
  refid: number;
  user_id: number;
  weekIndex: number;
  comments: string;
  notes_date: string;
  supervisorComments: string;
  timezone: string;
}

export class TNotes {
  data: Array<TNotesData> = [];
  lastTimezone: string = '';
  canEditComments: boolean = false;
  canEditSupervisorComments: boolean = false;
}

export class TTrackingData {
  refid: number = 0;
  user_id: number = 0;
  clock_start: string = '';
  clock_end: string = '';
  difference: string = '';
  date: string = '';

  copy?(copy: TTrackingData): TTrackingData {
    this.refid = copy.refid;
    this.user_id = copy.user_id;
    this.clock_start = copy.clock_start;
    this.clock_end = copy.clock_end;
    this.difference = copy.difference;
    this.date = copy.date;
    return this;
  }
}

export class TCalendarData {
  refid: string;
  user_id: string;
  day_date: string;
  vacation: string;
  benefits: string;
  benefitsType: string;
  ptoPaid: string;
  week_of_year: string;
  over_time: string;
  mileage: number = 0;
  expenses: number = 0;
  regular_time: string;
  call_hours: string;
  isSubmitted: 'Y' | 'N' = 'N';
  isLocked: 'Y' | 'N' = 'N';
}

export class TTimeSheet {
  calendar: Array<TCalendarData> = [];
  tracking: Array<TTrackingData> = [];
  notes: TNotes = new TNotes();
  clockIn: -1 | 0 | 1 = -1;
}

class TimeSheetPageService {
  saveTimeSheet(
    userId: string,
    timeSheetCalendar: Array<TTimeSheetCalendar>,
    updateCalendarDate: TTimeSheetUpdateCalendarDate,
    weekNotes: TWeekNotes
  ) {
    const data = [userId, timeSheetCalendar, updateCalendarDate, weekNotes];

    return apiRequest({ url: 'filemanager.TimeSheet.saveTimeSheet', data });
  }

  loadTimeSheet(userId: string, month: number, week: number, year: number) {
    const data = [month, year, week, userId];

    return apiRequest<TTimeSheet>({
      url: 'filemanager.TimeSheet.getTimeSheet',
      data,
    });
  }

  clockIn() {
    return apiRequest({
      url: 'filemanager.TimeSheet.clockIn',
      data: [null, this.getNow()],
    });
  }

  clockOut() {
    return apiRequest({
      url: 'filemanager.TimeSheet.clockOut',
      data: [null, this.getNow()],
    });
  }

  loadUserDocs(userId: string, date: string, type: TTimeSheetFileType) {
    return apiRequest<Array<TTimeSheetFullInfoDoc>>({
      url: 'filemanager.TimeSheet.loadUserDocs',
      data: [userId, date, type],
    });
  }

  saveUserDocs(
    userId: string,
    date: string,
    type: TTimeSheetFileType,
    docsList: Array<TTimeSheetDoc>
  ) {
    const data = [userId, date, type, docsList];

    return apiRequest<Array<TTimeSheetFullInfoDoc>>({
      url: 'filemanager.TimeSheet.saveUserDocs',
      data,
    });
  }

  submitTimeSheet(
    userId: string,
    submit: boolean,
    dateFrom: string,
    dateTo: string
  ) {
    const data = { userId, submit, dateFrom, dateTo };

    return apiRequest({ url: 'filemanager.TimeSheet.submitTimeSheet', data });
  }

  getNow() {
    return `${dateToLocalTimezone({ date: new Date() })}:00`;
  }

  getClockedIn(pagination: TPagination) {
    const data = {
      skip: pagination.skip,
      limit: pagination.pageSize,
    };

    return apiRequest<TClockedInResponse>({
      url: 'filemanager.TimeSheet.getClockedIn',
      data,
    }).then((response: TClockedInResponse) => {
      response.data.forEach((v) => {
        v.clock_start = dateToLocalTimezone({ date: v.clock_start });
      });
      return response;
    });
  }
}

export default new TimeSheetPageService();
