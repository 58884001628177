import { InfoType } from 'stores/_mobx/billingCodding/claimReview';

interface PropsType {
  guarantor: InfoType['guarantor'];
}

const SelfPayTab = ({ guarantor }: PropsType) =>
  guarantor ? (
    <>
      <legend>Personal Information</legend>

      <dl className="row mb-5">
        <dt className="col-sm-auto mb-3">Guarantor Relationship:</dt>
        <dd className="col-sm-auto mb-3">{guarantor.relationship}</dd>

        <div />

        <div className="col-md-6 mb-3">
          <dt>Last Name:</dt>
          <dd>{guarantor.lastName}</dd>
        </div>

        <div className="col-md-6 mb-3">
          <dt>First Name:</dt>
          <dd>{guarantor.firstName}</dd>
        </div>

        <div className="col-md-6 mb-3">
          <dt>Middle Name:</dt>
          <dd>{guarantor.middleName}</dd>
        </div>

        <div className="col-sm-6 col-md-4 mb-3">
          <dt>Date of Birth:</dt>
          <dd>{guarantor.dateOfBirth}</dd>
        </div>

        <div className="col-sm-6 mb-3">
          <dt>Gender:</dt>
          <dd>{guarantor.gender}</dd>
        </div>

        <div className="col-sm-6 mb-3">
          <dt>SSN:</dt>
          <dd>{guarantor.ssn}</dd>
        </div>

        <div className="col-sm-6">
          <dt>Work Phone:</dt>
          <dd>{guarantor.phoneWork}</dd>
        </div>
      </dl>

      <legend>Address</legend>
      <div className="row">
        <div className="col-sm-12 mb-3">
          <dt>Primary address:</dt>
          <dd>{guarantor.address}</dd>
        </div>

        <div className="col-md-6 mb-3">
          <dt>City:</dt>
          <dd>{guarantor.address}</dd>
        </div>

        <div className="col-md-6 mb-3">
          <dt>State:</dt>
          <dd>{guarantor.state}</dd>
        </div>

        <div className="col-md-6 mb-3">
          <dt>Zip Code:</dt>
          <dd>{guarantor.zip}</dd>
        </div>

        <div className="col-md-6 mb-3">
          <dt>Home Phone:</dt>
          <dd>{guarantor.phoneHome}</dd>
        </div>

        <div className="col-md-6">
          <dt>Email:</dt>
          <dd>{guarantor.email}</dd>
        </div>

        <div className="col-md-6">
          <dt>Fax:</dt>
          <dd>{guarantor.fax}</dd>
        </div>
      </div>
    </>
  ) : (
    <div style={{ height: '602px' }} />
  );

export default SelfPayTab;
