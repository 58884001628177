import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import Button, { ButtonProps } from './Button';

type ActionType = () => void;

interface MenuType {
  label: string;
  disabled?: boolean;
  action: ActionType;
}

interface PropsType extends Omit<ButtonProps, 'style'> {
  menu: MenuType[];
  disabled?: boolean;
}

const ButtonDropdown = ({ menu, ...rest }: PropsType) => {
  const buttonWrapperRef = useRef<HTMLDivElement | null>(null);

  const [menuIsOpen, toggleMenu] = useState<boolean>(false);

  const buttonClassName = clsx({ 'dropdown-toggle': true, show: menuIsOpen });

  const menuClassName = clsx('dropdown-menu', { show: menuIsOpen });

  const handleClose = () => {
    toggleMenu(false);
  };

  const keyListener = useCallback((e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      handleClose();
    }
  }, []);

  const mouseClickListener = useCallback((e: MouseEvent) => {
    const target = e.target as Node;
    if (!buttonWrapperRef.current?.contains(target)) {
      handleClose();
    }
  }, []);

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    toggleMenu((state) => !state);
  };

  const handleMenuClick = (action: ActionType) => (e: React.MouseEvent) => {
    e.preventDefault();
    action();
    handleClose();
  };

  useEffect(() => {
    if (menuIsOpen) {
      document.addEventListener('keydown', keyListener);
      document.addEventListener('click', mouseClickListener);
    } else {
      document.removeEventListener('keydown', keyListener);
      document.removeEventListener('click', mouseClickListener);
    }
  }, [menuIsOpen, keyListener, mouseClickListener]);

  return (
    <div className="dropdown" ref={buttonWrapperRef}>
      <Button
        className={buttonClassName}
        aria-expanded={menuIsOpen}
        {...rest}
        onClick={handleButtonClick}
      />

      <ul className={menuClassName}>
        {menu.map(({ label, disabled, action }) => (
          <li key={label}>
            <a
              className={clsx({ 'dropdown-item': true, disabled })}
              href="/"
              onClick={handleMenuClick(action)}>
              {label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ButtonDropdown;
