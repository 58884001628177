import { action, makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionsStateResponse {
  data: number;
  label: string;
}

export interface OptionType {
  value: number;
  label: string;
}

interface OptionsDetailsType {
  fetching: boolean;
  options: OptionType[];
}

class DropdownRadiologist {
  optionsCollection: Record<string, OptionsDetailsType> = {};

  constructor() {
    makeObservable(this, {
      optionsCollection: observable,

      setFetching: action,
      clearOptionsCollection: action.bound,
    });
  }

  setFetching(key: string, fetching: boolean) {
    this.optionsCollection[key] = {
      fetching,
      options: [],
    };
  }

  clearOptionsCollection() {
    this.optionsCollection = {};
  }

  async getOptions(radiologyGroupId: number) {
    const key = String(radiologyGroupId);

    const optionsInstance = this.optionsCollection[key];

    if (optionsInstance?.fetching || optionsInstance?.options)
      return Promise.resolve();

    this.setFetching(key, true);

    try {
      const list = await apiRequest<OptionsStateResponse[]>({
        url: 'generalmaster.RadiologyGroup.RadiologistDropDown',
        data: [radiologyGroupId],
      });

      let options: OptionType[] = [];

      if (radiologyGroupId) {
        options = list.map(({ label, data }) => ({
          label,
          value: data,
        }));
      } else {
        const ids = new Set(list.map(({ data }) => data));
        // @ts-ignore
        options = [...ids].map((value: number) => {
          const option = list.find(({ data }) => data === value);
          return {
            value,
            label: option.label,
          };
        });
      }

      runInAction(() => {
        this.optionsCollection[key] = {
          fetching: false,
          options,
        };
      });
    } catch {
      runInAction(() => {
        this.optionsCollection[key] = {
          fetching: false,
          options: [],
        };
      });
    }
  }

  findOption = (id: number, radiologyGroupId: number = null) =>
    this.optionsCollection[`${radiologyGroupId}`]?.options?.find(
      ({ value }) => value === id
    );
}

export const storeDropdownRadiologist = new DropdownRadiologist();
