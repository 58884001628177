import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import useRequest from 'components/hook/useRequest';
import MessageArchiveForm, { FormModel } from './Form';

import { storeMessagesArchive } from 'stores/_mobx/systemSetup/masterSetting/messagesArchive';
import { URL_MASTER_SETTINGS } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const MessageArchivePage = ({ history }: PropsType) => {
  const {
    clientSettings,
    companySettings,
    getArchiveSettings,
    saveArchiveSettings,
  } = storeMessagesArchive;

  const { fetching, controlledAction } = useRequest(getArchiveSettings);

  const handleSubmit = async (data: FormModel) =>
    saveArchiveSettings(data).then((response) => {
      if (response) {
        history.push(URL_MASTER_SETTINGS);
      }
    });

  useEffect(() => {
    controlledAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`position-relative${fetching ? ' on-ajax' : ''}`}>
      <Title title="Message Archiving" />
      <MessageArchiveForm
        onSubmit={handleSubmit}
        defaultValues={companySettings}
        clientUsers={clientSettings}
      />
    </div>
  );
};

export default observer(MessageArchivePage);
