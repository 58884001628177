import { RouteComponentProps } from 'react-router';

import Title from 'components/project/common/title';
import FormModifyComputer, { FormModel } from './components/Form';

import { storeComputer } from 'stores/_mobx/workflow/equipmentInventory/computer';
import { URL_COMPUTER } from 'constant/path/workflow';

interface PropsType extends RouteComponentProps {}

const ComputerAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: FormModel) =>
    storeComputer.addComputer(data).then((result) => {
      if (result) return result;
      history.push(URL_COMPUTER);
    });

  return (
    <>
      <Title title="Add Computer" />
      <FormModifyComputer backUrl={URL_COMPUTER} onSubmit={handleSubmit} />
    </>
  );
};

export default ComputerAddPage;
