import { useLayoutEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';
import PhysicianCompleteInformationForm from './components/CompleteInformation';

import {
  storePhysician,
  PhysicianFormType,
  PhysicianAddedSuccessResponseType,
} from 'stores/_mobx/clinicianManager/physician';
import { storePasswordStrength } from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';

export { type PhysicianFormType } from 'stores/_mobx/clinicianManager/physician';

interface PropsType {
  memberForGroup: number;
  onCloseDialog: () => void;
  onCreatePhysician: (physician: PhysicianFormType) => void;
}

const DialogOfCreatingPhysician = ({
  memberForGroup,
  onCloseDialog,
  onCreatePhysician,
}: PropsType) => {
  const { physicianDetailsDefaultValues, fetchingPhysicianDetails } =
    storePhysician;

  const { fetching, passwordOptions, passwordSettings } = storePasswordStrength;

  const [physician, setPhysicianData] = useState<{
    physicianSettings: PhysicianAddedSuccessResponseType;
    physicianData: PhysicianFormType;
  }>(null);

  const isLoading = fetching || !passwordSettings;

  const physicianDetails = useMemo(
    () => ({
      ...physicianDetailsDefaultValues,
      participateInGroup: [memberForGroup],
    }),
    [memberForGroup, physicianDetailsDefaultValues]
  );

  const handleClosePinDialog = () => {
    onCreatePhysician(physician.physicianData);
  };

  const handleSubmit = (payload: PhysicianFormType) =>
    storePhysician
      .addPhysician(payload)
      .then(({ isSucceed, errorMessage, physicianInfo }) => {
        if (isSucceed)
          setPhysicianData({
            physicianSettings: physicianInfo,
            physicianData: payload,
          });
        return isSucceed ? null : errorMessage;
      });

  useLayoutEffect(() => {
    if (!passwordSettings) storePasswordStrength.getPasswordSettings();

    return storePhysician.clearPhysicianStore;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog size="extraLarge" handleClose={onCloseDialog}>
        <DialogHeader title="Add new Physician" onClose={onCloseDialog} />
        <DialogBody className={isLoading ? 'on-ajax' : ''}>
          {isLoading ? (
            <div style={{ minHeight: '100vh' }} />
          ) : (
            <PhysicianCompleteInformationForm
              passwordOptions={passwordOptions}
              defaultValues={physicianDetails}
              fetching={fetchingPhysicianDetails}
              onSubmit={handleSubmit}
            />
          )}
        </DialogBody>
      </Dialog>

      {physician && (
        <Dialog handleClose={handleClosePinDialog}>
          <DialogHeader title="User Pin" onClose={handleClosePinDialog} />
          <DialogBody>
            <div>
              <p>New Physician was created successfully!</p>
              <p>Pin value: {physician.physicianSettings.ipin}</p>
            </div>
          </DialogBody>
        </Dialog>
      )}
    </>
  );
};

export default observer(DialogOfCreatingPhysician);
