import { RouteComponentProps } from 'react-router-dom';
import { Container } from 'flux/utils';
import { isValid } from 'date-fns';

import AbstractOrderPage, {
  PAbstractOrderPage,
  SAbstractOrderPage,
} from 'page/workflow/order/order/AbstractOrderPage';
import DialogConfirmDeleteCPTCode from 'page/workflow/order/components/DialogConfirmDeleteCPTCode';
import { Button, IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import Form from 'components/form/Form';
import FormControl from 'components/form/FormControl';
import Fieldset from 'components/form/Fieldset';
import Text from 'components/form/input/Text';
import Radio from 'components/form/input/Radio';
import Custom from 'components/form/input/Custom';
import TextArea from 'components/form/input/TextArea';
import NumberInput from 'components/form/input/NumberInput';
import TextCalendar from 'components/form/input/TextCalendar';
import RadioOptions from 'components/project/common/RadioOptions';
import FloorDropdown from 'components/project/dropdown/FloorDropdown';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import ExamTypeDropdown from 'components/project/dropdown/ExamTypeDropdown';
import PriorityDropdown from 'components/project/dropdown/PriorityDropdown';
import Notification from 'components/modal/Notification';
import CPTCodeModal from 'page/workflow/order/order/CPTCodeModal';
import DialogIcdCode from 'page/components/DialogIcdCode';
import OrderFormPageActions from 'actions/workflow/order/OrderFormPageActions';
import OrderFormPageStore from 'stores/workflow/order/OrderFormPageStore';
import {
  dateToLocalTimezone,
  getDateString,
  getTimeZoneName,
} from 'utils/DateUtils';
import EndDayLabel from 'page/workflow/order/EndDayLabel';
import { TechnologistDropdown } from 'components/project/dropdown/TechnologistDropDown';
import DialogCPTLogs from '../components/DialogCPTLogs';
import DialogICDLog from '../components/DialogICDLog';

import {
  TFacilityFloor,
  TExamInfoOrderData,
  TPatient,
  TAddExamInfoOrderRes,
  TEdIntCpt,
} from 'services/workflow/order/OrderFormPageService';
import MathUtils from 'utils/MathUtils';
import AccessUtils from 'utils/AccessUtils';
import { URL_ORDER_BASE } from 'constant/path/workflow';

const ISOLATION_PRECAUTIONS = RadioOptions.yesNo;

const TOBACCO_USE = [
  { value: 'Y', label: 'Yes' },
  { value: 'N', label: 'No' },
  { value: 'U', label: 'Unknown' },
];

const splitToDateAndTime = (dateStr: string, timeStr: string) => {
  const [date, time] = dateToLocalTimezone({
    date: `${dateStr} ${timeStr}`,
  }).split(' ');

  return [date, time];
};

export interface PExamInformation extends PAbstractOrderPage {
  allowSubmit: boolean;
  callerName: string;
  cachedState: SExamInformation;
  history: RouteComponentProps['history'];
  submitSuccess: (
    orderId: number,
    state: SExamInformation,
    lastModifiedLock: string
  ) => void;
  onGoBack: () => void;
  onPopupOpen: (flag: boolean) => void;
}

export class TEiModel {
  priority: number = null;
  sheduleservdate: string = '';
  scheduleServiceDate: string = '';
  schedulestime: string = '';
  scheduleendtime: string = '';
  orderassigned_date: string = '';
  orderassigned_time: string = '';
  ordercompletion_date: string = '';
  ordercompletion_time: string = '';
  examtypeid: number = null;
  FaxPhone: Array<any> = [{}];
  GetIntCptArr: TEdIntCpt[] = [];
  GetIntIcdArr: Array<any> = [];
  GetFnlCptArr: Array<any> = [];
  smoking: 'Y' | 'N' | 'U' = 'U';
  isolation: 'Y' | 'N' | 'U' = 'U';
  symptoms: string = '';
  comment: string = '';
  symptomatic: 'Y' | 'N' | 'U' = 'U';
  sessionno: string = '';
  isolation_description: string = '';
  radiologygroupid: string = '';
  technologistid: string = '';
  floor: string = '';
  room: string = '';
  signstatusvis: boolean = false;
  number_of_patient: string = '';
  techsignstatus: string = '';
  radiosignstatus: string = '';
  signstatus: string = '';
  lastModifiedLock: string = '';
}

export class SExamInformation extends SAbstractOrderPage {
  isCptLogModalOpen: boolean = false;
  isIcdLogModalOpen: boolean = false;
  isCptCodeModalOpen: boolean = false;
  isIcdCodeModalOpen: boolean = false;
  cptForDelete: any = null;
  saveDuplicatePopup: boolean = false;
  saveDuplicate: boolean = false;
  fromTimeFocused: boolean = false;
  examDataInitted: boolean = false;
  model: TEiModel = new TEiModel();
  errors: any = {};
}

export class ExamInformation extends AbstractOrderPage<
  PExamInformation,
  SExamInformation
> {
  timeZoneName = getTimeZoneName();
  COLUMNS = [
    { name: 'accession_number', title: 'Accession Number' },
    { name: 'cpt_code', title: 'CPT Code' },
    {
      name: 'cpt_description',
      title: 'Description',
      render: (v: string, data: any) => data.cpt_desc || v,
    },
    {
      name: 'controls',
      title: 'Actions',
      className: 'width-75 text-center',
      render: (v: string, d: any) => this.renderControl(d),
    },
  ];

  COLUMNS_DIAGNOSIS = [
    { name: 'icd_code', title: 'ICD Code' },
    { name: 'short_description', title: 'ICD Description' },
    {
      name: 'Delete',
      title: 'Delete',
      className: 'width-75 text-center',
      render: (v: string, d: any) => this.renderICDControl(d),
    },
  ];

  showDeleteCptButtonToAllowedUserTypes = AccessUtils.haveAccessToDeleteCptCodes();

  tooltipBlurListener: () => void = null;

  constructor(props: PExamInformation) {
    super(props);
    this.tooltipBlurListener = () => {
      this.tooltipBlurListenerOrigin();
    };
    window.addEventListener('blur', this.tooltipBlurListener);
  }

  static getStores() {
    return [OrderFormPageStore];
  }

  static calculateState(prevState: SExamInformation, props: PExamInformation) {
    const state = Object.assign(
      new SExamInformation(),
      AbstractOrderPage.calculateState(prevState, props)
    );
    state.model = Object.assign(new TEiModel(), state.model);
    state.model.lastModifiedLock = props.lastModifiedLock;
    if (!prevState) {
      state.isCptLogModalOpen = false;
      state.isIcdLogModalOpen = false;
      state.isCptCodeModalOpen = false;
      state.isIcdCodeModalOpen = false;
      state.saveDuplicatePopup = false;
      state.saveDuplicate = false;
      state.fromTimeFocused = false;
    }
    if (
      !state.examDataInitted &&
      AbstractOrderPage.hasExamData(props.orderId)
    ) {
      state.examDataInitted = true;
      const { examData } = state;
      const model: TEiModel = state.model;
      examData.room = model.room ? model.room : examData.room;
      examData.FaxPhone = model.FaxPhone?.length
        ? model.FaxPhone
        : examData.FaxPhone?.length
        ? examData.FaxPhone
        : [
            {
              DelOpt: false,
              AddOpt: true,
              Fax: '',
              Phone: '',
              floor: '',
              floorName: '',
              refid: null,
            },
          ];
      examData.isolation =
        model.isolation && model.isolation !== 'U'
          ? model.isolation
          : examData.isolation;

      state.model = Object.assign(new TEiModel(), model, examData);
      ExamInformation.fixModel(state.model);
    }
    return state;
  }

  static fixModel(model: TEiModel) {
    if (!model.priority || '' + model.priority === '0') {
      model.priority = 3;
    }

    if (!model.sheduleservdate || !isValid(new Date(model.sheduleservdate))) {
      const scheduleServiceDate = `${getDateString()} 00:00:00`;
      model.sheduleservdate = scheduleServiceDate;
      model.scheduleServiceDate = scheduleServiceDate;
    } else {
      const scheduleServiceDate = dateToLocalTimezone({
        date: model.sheduleservdate,
      });
      model.scheduleServiceDate = scheduleServiceDate;
      model.sheduleservdate = scheduleServiceDate;

      if (model.orderassigned_date && model.orderassigned_time) {
        const [date, time] = splitToDateAndTime(
          model.orderassigned_date,
          model.orderassigned_time
        );
        model.orderassigned_date = date;
        model.orderassigned_time = time;
      }
      if (model.ordercompletion_date && model.ordercompletion_time) {
        const [date, time] = splitToDateAndTime(
          model.ordercompletion_date,
          model.ordercompletion_time
        );
        model.ordercompletion_date = date;
        model.ordercompletion_time = time;
      }
    }
  }

  handleCloseCptDeleteConfirm = () => {
    this.setState({ cptForDelete: null });
  };

  componentWillUnmount() {
    window.removeEventListener('blur', this.tooltipBlurListener);
  }

  tooltipBlurListenerOrigin() {
    setTimeout(() => {
      this.setState({ fromTimeFocused: false });
    }, 200);
  }

  renderControl(data: any) {
    return (
      <div className="d-flex justify-content-center gap-3">
        <IconButton
          className="text-danger"
          onClick={() => {
            this.setState({ cptForDelete: data });
          }}
          disabled={!this.showDeleteCptButtonToAllowedUserTypes && Boolean(data.transcription_id)}>
          <i className="bi bi-x-circle" />
        </IconButton>
        <IconButton
          className="text-primary"
          onClick={() => {
            this.addCptCode();
          }}>
          <i className="bi bi-pencil" />
        </IconButton>
      </div>
    );
  }

  renderICDControl(data: any) {
    return (
      <IconButton
        className="text-danger"
        onClick={() => {
          this.removeICDCode(data);
        }}>
        <i className="bi bi-x-circle" />
      </IconButton>
    );
  }

  addCptCode = () => {
    const examType = this.state.model.examtypeid;
    if (!examType || '' + examType === '0') {
      Notification.warning('Please select modality at first!');
    } else {
      this.setState({ isCptCodeModalOpen: true }, () =>
        this.props.onPopupOpen(true)
      );
    }
  };

  addIcdCode = () => {
    this.setState({ isIcdCodeModalOpen: true }, () =>
      this.props.onPopupOpen(true)
    );
  };

  getStationDetails = (floorId: number, index: number) => {
    OrderFormPageActions.onExistingStation(floorId).then(
      (arr: Array<TFacilityFloor>) => {
        const model = Object.assign({}, this.state.model);
        if (arr) {
          if (!model.FaxPhone) {
            model.FaxPhone = [];
          }
          for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            model.FaxPhone[index] = {
              Fax: item.floor_fax,
              Phone: item.floor_phone,
              floor: MathUtils.parseInt('' + item.refid),
              refid: model.FaxPhone[index].refid,
              onAjax: false,
              AddOpt: true,
              DelOpt: false,
            };
          }
          if (model.FaxPhone.length >= 3) {
            model.FaxPhone[model.FaxPhone.length - 1].AddOpt = false;
          }
        }
        this.setState({ model });
      }
    );
  };

  onUpdateModel(name: string, value: any, errorMessages: any) {
    const newState = {
      model: Object.assign({}, this.state.model),
      errors: Object.assign({}, this.state.errors),
    };
    let clb = null;
    if (name === 'floor') {
      const index = errorMessages;
      errorMessages = [];
      clb = () => {
        const model = Object.assign({}, this.state.model);
        if (!model.FaxPhone) {
          model.FaxPhone = [{}];
        }
        if (!model.FaxPhone[index]) {
          model.FaxPhone[index] = {};
        }
        model.FaxPhone[index].onAjax = true;
        model.FaxPhone[index].Fax = '';
        model.FaxPhone[index].Phone = '';
        const floorId: number = value || 0;
        if (!value) {
          model.FaxPhone[index] = {
            Fax: '',
            Phone: '',
            floor: '',
            refid: '',
            onAjax: false,
            AddOpt: true,
            DelOpt: false,
          };
        }
        this.setState({ model }, () => {
          if (floorId) this.getStationDetails(floorId, index);
        });
      };
    } else if (name === 'isolation_description') {
      if (value && value.length > 12) {
        return;
      }
    }
    (newState.model as any)[name] = value;
    newState.errors[name] = errorMessages;
    this.setState(newState, clb);
  }

  onPhoneUpdateModel = (name: string, value: string, idx: number) => {
    const { model, errors } = this.state;
    const newModel = {
      ...model,
      FaxPhone: model.FaxPhone.map((el, i: number) =>
        i === idx
          ? {
              ...el,
              [name]: value,
            }
          : el
      ),
    };
    const newErrors = {
      ...errors,
      [name]: errors[name]?.map((errorMessage: string, i: number) =>
        idx === i ? (Boolean(value) ? [] : ["Can't be empty"]) : errorMessage
      ),
    };
    this.setState({ model: newModel, errors: newErrors });
  };

  getFaxPhoneComponents() {
    const { state } = this;
    const model = state.model || new TEiModel();
    const orderDataPatient = state.orderDataPatient || new TPatient();
    let arr = model.FaxPhone;
    if (!arr || arr.length === 0) {
      arr = [
        {
          Fax: '',
          Phone: '',
          refid: '',
          floor: '',
          AddOpt: true,
          DelOpt: false,
        },
      ];
    }
    const len = arr.length;
    const out = [];
    for (let i = 0; i < len; i++) {
      let item = arr[i];
      let highLightPhone = !arr[0].Phone;
      let highLightFax = !arr[0].Fax;
      out.push(
        <Fieldset
          key={'fieldsset_faxphone_' + i}
          formModel={item}
          className="row"
          onSetValue={(name, value, errors) =>
            this.updateFaxPhoneItem(i, name, value, errors)
          }
          errors={state.errors}
          errorMessages={state.errorMessages}>
          <FloorDropdown
            key={'floor_' + i}
            name="floor"
            label="Existing Station"
            className="col-sm-3"
            onSetValue={(n: any, v: any) => {
              this.onUpdateModel(n, v, i);
            }}
            facilityId={orderDataPatient.facilityid || 0}
          />
          <NumberInput
            key={'phone_' + i}
            name="Phone"
            label="Phone"
            format="###-###-####"
            className={
              'col-sm-4 ' +
              this.solveBackgroundByCondition(!highLightPhone) +
              (item.onAjax ? ' on-ajax-line' : '')
            }
            onSetValue={(n: any, v: any) => this.onPhoneUpdateModel(n, v, i)}
            validations="required"
            errors={state.errors?.Phone?.[i] || []}
          />
          <NumberInput
            key={'fax_' + i}
            name="Fax"
            label="Fax"
            format="###-###-####"
            className={
              'col-sm-4 ' + this.solveBackgroundByCondition(!highLightFax)
            }
            onSetValue={(n: any, v: any) => this.onPhoneUpdateModel(n, v, i)}
            validations="required"
            errors={state.errors?.Fax?.[i] || []}
          />

          <div className="col-sm-1 mt-5">
            <div className="d-flex gap-3">
              <IconButton
                disabled={arr.length >= 3}
                className="text-primary"
                onClick={() => {
                  this.addFaxPhone();
                }}>
                <i className="bi bi-plus-circle" />
              </IconButton>

              <IconButton
                disabled={arr.length <= 1}
                className="text-primary"
                onClick={() => {
                  this.removeFaxPhone(i);
                }}>
                <i className="bi bi-x-circle" />
              </IconButton>
            </div>
          </div>
        </Fieldset>
      );
    }
    return out;
  }

  handleCloseDialogCPTLog = () => {
    this.setState({ isCptLogModalOpen: false }, () =>
      this.props.onPopupOpen(false)
    );
  };

  handleCloseDialogICDLog = () => {
    this.setState({ isIcdLogModalOpen: false }, () =>
      this.props.onPopupOpen(false)
    );
  };

  cptCodeModalResponse(cptCodes: Array<any> | null) {
    const model = Array.isArray(cptCodes)
      ? {
          ...this.state.model,
          GetIntCptArr: cptCodes,
        }
      : this.state.model;

    this.setState({ isCptCodeModalOpen: false, model }, () =>
      this.props.onPopupOpen(false)
    );
  }

  icdCodeModalResponse(icdCodes: Array<any>) {
    const model = { ...this.state.model, GetIntIcdArr: icdCodes };
    this.setState({ isIcdCodeModalOpen: false, model }, () =>
      this.props.onPopupOpen(false)
    );
  }

  updateFaxPhoneItem(
    index: number,
    name: string,
    value: string,
    errorMessages: any
  ) {
    const model = this.state.model || new TEiModel();
    const errors = Object.assign({}, this.state.errors);
    const source = model.FaxPhone || [];
    const faxPhone = source[index]
      ? source
      : [
          ...source,
          {
            name: value,
          },
        ];

    errors[name] = errorMessages;
    this.updateFaxPhoneObject(faxPhone, errors);
  }

  cptLogModalOpen = () => {
    this.setState({ isCptLogModalOpen: true }, () =>
      this.props.onPopupOpen(true)
    );
  };

  icdLogModalOpen = () => {
    this.setState({ isIcdLogModalOpen: true }, () =>
      this.props.onPopupOpen(true)
    );
  };

  getColumns() {
    return this.COLUMNS;
  }

  getDiagnosisColumns() {
    return this.COLUMNS_DIAGNOSIS;
  }

  addFaxPhone() {
    const model = this.state.model || new TEiModel();
    const faxPhoneSource = model.FaxPhone || [];
    const faxPhone = faxPhoneSource.concat({
      AddOpt: faxPhoneSource.length < 4,
      DelOpt: faxPhoneSource.length > 0,
      Fax: '',
      Phone: '',
      refid: '',
      floor: '',
    });
    this.updateFaxPhoneObject(faxPhone);
  }

  removeFaxPhone(index: number) {
    const { model, errors } = this.state;
    const faxPhone = [...model.FaxPhone];
    const faxErrors = errors.Fax?.filter(
      (errorMessage: string, idx: number) => idx !== index
    );
    const phoneErrors = errors.Phone?.filter(
      (errorMessage: string, idx: number) => idx !== index
    );
    faxPhone.splice(index, 1);
    const newErrors = {
      ...errors,
      Phone: phoneErrors,
      Fax: faxErrors,
    };

    this.updateFaxPhoneObject(faxPhone, newErrors);
  }

  handleApproveDeleteCpt = () => {
    const { model, cptForDelete } = this.state;
    const intCptArr = [...model.GetIntCptArr];
    const index = intCptArr.indexOf(cptForDelete);
    this.handleCloseCptDeleteConfirm();
    if (index >= 0) {
      intCptArr.splice(index, 1);
      this.updateCptCode(intCptArr);
    }
  };

  removeICDCode(icdCode: any) {
    const intIcdArr = [].concat(this.state.model.GetIntIcdArr);
    const index = intIcdArr.indexOf(icdCode);
    if (index >= 0) {
      intIcdArr.splice(index, 1);
      this.updateICDCode(intIcdArr);
    }
  }

  updateICDCode(intIcdArr: any) {
    const model = Object.assign({}, this.state.model, {
      GetIntIcdArr: intIcdArr,
    });
    this.setState({ model: model });
  }

  updateCptCode(intCptArr: any) {
    const model = Object.assign({}, this.state.model, {
      GetIntCptArr: intCptArr,
    });
    this.setState({ model: model });
  }

  updateFaxPhoneObject(faxPhone: any, errors?: any) {
    const model = Object.assign({}, this.state.model, { FaxPhone: faxPhone });
    let state = {};
    if (errors) {
      state = { model, errors };
    } else {
      state = { model };
    }
    this.setState(state);
  }

  handleSubmit = (
    updatedModel: any,
    hasErrors: boolean,
    updatedErrors: any
  ) => {
    const { state } = this;

    const faxErrors = state.model.FaxPhone.map((el) =>
      Boolean(el.Fax) ? null : ["Can't be empty."]
    );
    const phoneErrors = state.model.FaxPhone.map((el) =>
      Boolean(el.Phone) ? null : ["Can't be empty."]
    );
    const doesFaxHasErrors = faxErrors.some((errorMessage) =>
      Boolean(errorMessage)
    );
    const doesPhoneHasErrors = phoneErrors.some((errorMessage) =>
      Boolean(errorMessage)
    );

    if (hasErrors || doesFaxHasErrors || doesPhoneHasErrors) {
      const model = { ...state.model, ...updatedModel };
      const errors = {
        ...state.errors,
        ...updatedErrors,
        Phone: phoneErrors,
        Fax: faxErrors,
      };
      this.setState({ model, errors });
    } else {
      this.submitSuccess();
    }
  };

  render() {
    const { state, props } = this;
    const model = state.model || new TEiModel();
    let numPatient = model.number_of_patient;
    let techsignstatus = model.techsignstatus;
    let radiosignstatus = model.radiosignstatus;
    let physian = model.signstatus;
    const numberOfPatients = !props.onEdit ? null : (
      <Text
        name="numPatient"
        label="Number of patients"
        value={numPatient}
        attr={{ disabled: true }}
      />
    );

    const showTooltips =
      props.showToolTips &&
      !state.isCptLogModalOpen &&
      !state.isIcdLogModalOpen &&
      !state.isCptCodeModalOpen &&
      !state.isIcdCodeModalOpen &&
      !state.saveDuplicatePopup;

    return (
      <div
        className={'exam-information ' + (state.dataLoaded ? '' : 'on-ajax')}>
        <Form
          onCollectValues={this.onUpdateModel.bind(this)}
          submit={this.handleSubmit}
          model={model}
          errors={state.errors}
          errorMessages={state.errorMessages}
          ref="form">
          <Fieldset className={this.props.onEdit ? 'col-sm-9' : 'col-sm-12'}>
            <Fieldset className="row">
              <PriorityDropdown
                name="priority"
                className="col-sm-6"
                label="Order Priority"
              />
              <ExamTypeDropdown
                name="examtypeid"
                label="Modality"
                className={
                  'col-sm-6 ' + this.solveBackground('examtypeid', true)
                }
                validations="required:lazy"
                errorMessages="Select a modality from the list!"
                optionsForOrderOnly
              />
            </Fieldset>
          </Fieldset>

          <Fieldset
            className={`dos-form ${
              this.props.onEdit ? 'col-sm-9' : 'col-sm-12'
            }`}>
            <Fieldset className="row">
              <legend>Scheduled Service Date</legend>
              <TextCalendar
                className="col-sm-4"
                name="sheduleservdate"
                label="MM/DD/YYYY"
                validations="required"
              />
              <Fieldset className="col-sm-8">
                <Fieldset className="row">
                  <NumberInput
                    format="##:##"
                    label="From (HH:MM)"
                    className="col-sm-4"
                    ref="timeInput"
                    attr={{
                      onBlur: () => {
                        this.tooltipBlurListenerOrigin();
                      },
                      onFocus: () => {
                        this.setState({ fromTimeFocused: true });
                      },
                    }}
                    tooltip={{
                      theme: 'dark',
                      allowPointer: true,
                      overlay: (
                        <div>
                          Ensure you are entering start From and To times in
                          24-hour format.
                          <br />
                          If you enter a time in the To field that is before the
                          time entered in the From field,
                          <br />
                          the system will assume the DOS extends into the next
                          day.
                          <br />
                          Please click
                          <a
                            href="/assets/images/24-hour-format-help.png"
                            target="_blank"
                            rel="noreferrer"
                            className="mx-2">
                            here
                          </a>
                          for a guide on 24-hour time.
                        </div>
                      ),
                      visible:
                        showTooltips &&
                        !state.model.schedulestime &&
                        !state.model.scheduleendtime &&
                        state.fromTimeFocused,
                      onVisibleChange: () => {},
                      placement: 'bottom',
                    }}
                    name="schedulestime"
                    validations="time::23"
                  />
                  <NumberInput
                    format="##:##"
                    className="col-sm-4"
                    name="scheduleendtime"
                    label={
                      <EndDayLabel
                        key="scheduleendtime"
                        start={model.schedulestime}
                        end={model.scheduleendtime}
                        text="To (HH:MM)"
                      />
                    }
                  />
                  <Custom
                    noclone="true"
                    className="col-sm-4"
                    label="&nbsp;"
                    custom={<div>Timezone: {this.timeZoneName}</div>}
                  />
                </Fieldset>
              </Fieldset>
            </Fieldset>

            <Fieldset className="row">
              <legend>Fax / Phone Results To</legend>
              <Fieldset className="row col-sm-12">
                <Text
                  name="room"
                  value={model.room}
                  label="Bldg / Station / Unit / Room"
                  validations="required"
                  className={'col-sm-3 ' + this.solveBackground('room')}
                />
                <Fieldset className="col-sm-9">
                  {this.getFaxPhoneComponents()}
                </Fieldset>
              </Fieldset>

              <legend className="required">Procedures</legend>
              <Grid
                wrapperClass={this.solveBackgroundByCondition(
                  model.GetIntCptArr && model.GetIntCptArr.length
                )}
                noDataText={
                  <a
                    href="/"
                    style={{ color: 'black' }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.addCptCode();
                    }}>
                    Click 'Add' to select CPTs (Procedures)
                  </a>
                }
                columns={this.getColumns()}
                stateless
                dataSource={model.GetIntCptArr}
                id="cptGrid"
                disablePagination
                gridControlPanel={
                  <>
                    <GridControlButton title="Add" onClick={this.addCptCode} />
                    {props.onEdit ? (
                      <GridControlButton
                        title="Initial CPT Log"
                        onClick={this.cptLogModalOpen}
                      />
                    ) : null}
                  </>
                }
              />
              <Text
                type="hidden"
                validations="required"
                name="cpt_codes_error"
                errorMessages="Please specify at least one CPT code"
                noLabel
                className="col-sm-12"
                value={
                  model.GetIntCptArr && model.GetIntCptArr.length ? '1' : ''
                }
              />
              <TextArea
                name="symptoms"
                className={this.solveBackground('symptoms')}
                label="Reason for exam symptoms"
                attr={{ rows: 4 }}
                validations="required"
              />
              <Fieldset className="col-sm-12">
                <Fieldset className="row">
                  <Radio
                    options={TOBACCO_USE}
                    label="Tobacco Use"
                    className="part-inline col-md-6 col-lg-4"
                    name="smoking"
                  />
                  <Radio
                    options={RadioOptions.yesNo}
                    attr={{ disabled: model.smoking !== 'Y' }}
                    name="symptomatic"
                    className="part-inline col-md-6 col-lg-8"
                    validations={model.smoking === 'Y' ? 'required' : ''}
                  />
                </Fieldset>
              </Fieldset>

              <Fieldset className="col-sm-12">
                <Fieldset className="row">
                  <Radio
                    name="isolation"
                    className="part-inline col-md-6 col-lg-4"
                    label="Isolation Precautions"
                    options={ISOLATION_PRECAUTIONS}
                  />
                  <Text
                    name="isolation_description"
                    className="col-md-6 col-lg-8"
                    validations={
                      model.isolation === 'Y' ? 'required length:0:12' : ''
                    }
                    label="Isolation Precaution Description"
                    attr={{ disabled: model.isolation !== 'Y' }}
                  />
                </Fieldset>
              </Fieldset>

              <legend>Initial Diagnosis</legend>

              <Grid
                columns={this.getDiagnosisColumns()}
                stateless
                dataSource={model.GetIntIcdArr}
                id="diagnosisGrid"
                disablePagination
                gridControlPanel={
                  <>
                    <GridControlButton title="Add" onClick={this.addIcdCode} />
                    {props.onEdit ? (
                      <GridControlButton
                        title="Initial Diagnosis Log"
                        onClick={this.icdLogModalOpen}
                      />
                    ) : null}
                  </>
                }
              />
            </Fieldset>
          </Fieldset>

          {this.props.onEdit ? (
            <Fieldset className="col-sm-3">
              <TechnologistDropdown
                label="Technologist"
                name="technologistid"
              />

              {numberOfPatients}
              <Custom
                label="Technologist Sign Status"
                custom={techsignstatus}
              />
              <Custom
                label="Radiologist Sign Status"
                custom={radiosignstatus}
              />
              <Custom label="Physician Sign Status" custom={physian} />

              <Fieldset className="row">
                <legend>Assigned Timestamp</legend>
                <TextCalendar
                  className="col-sm-6 no-margin"
                  name="orderassigned_date"
                  label="MM/DD/YYYY"
                  attr={{ disabled: true }}
                />
                <NumberInput
                  format="##:##"
                  className="col-sm-6 no-margin"
                  name="orderassigned_time"
                  attr={{ disabled: true }}
                  label="HH:MM"
                />
                <div style={{ marginBottom: 10 }}>
                  Timezone: {this.timeZoneName}
                </div>

                <legend>Completed Timestamp</legend>
                <TextCalendar
                  className="col-sm-6 no-margin"
                  name="ordercompletion_date"
                  label="MM/DD/YYYY"
                  attr={{ disabled: true }}
                />
                <NumberInput
                  format="##:##"
                  className="col-sm-6 no-margin"
                  name="ordercompletion_time"
                  attr={{ disabled: true }}
                  label="HH:MM"
                />
                <div style={{ marginBottom: 10 }}>
                  Timezone: {this.timeZoneName}
                </div>
              </Fieldset>
            </Fieldset>
          ) : null}

          <Fieldset>
            <TextArea
              name="comment"
              label="Exam Notes/Comments"
              attr={{ rows: 4 }}
            />
          </Fieldset>

          <FormControl>
            <Button
              variant="danger"
              text="Back"
              onClick={() => this.props.onGoBack()}
            />
            <Button
              type="submit"
              text="Submit"
              disabled={!this.props.allowSubmit}
            />
          </FormControl>
        </Form>
        {state.isCptLogModalOpen && (
          <DialogCPTLogs
            orderId={this.props.orderId}
            onClose={this.handleCloseDialogCPTLog}
          />
        )}
        {state.isIcdLogModalOpen && (
          <DialogICDLog
            orderId={this.props.orderId}
            onClose={this.handleCloseDialogICDLog}
          />
        )}
        {state.isCptCodeModalOpen && (
          <CPTCodeModal
            modalityId={model.examtypeid}
            callback={(response) => this.cptCodeModalResponse(response)}
            dataSource={this.cloneCpt(model.GetIntCptArr)}
          />
        )}
        {!state.isIcdCodeModalOpen ? null : (
          <DialogIcdCode
            smoking={model.smoking}
            symptomatic={model.symptomatic}
            callback={(response) => this.icdCodeModalResponse(response)}
            selectedCodes={model.GetIntIcdArr.map((code) => ({
              ...code,
              refid: Number(code.refid),
            }))}
          />
        )}
        {!state.saveDuplicatePopup ? null : (
          <Dialog handleClose={this.handleCloseDialogDuplicate}>
            <DialogHeader
              title="Confirm"
              onClose={this.handleCloseDialogDuplicate}
            />
            <DialogBody>
              {`It looks like an order is already placed for ${this.findPatientName()} with the selected Modality and Date of
              Service. Are you sure you want to proceed placing this order?`}
            </DialogBody>
            <DialogFooter>
              <Button
                text="Cancel"
                variant="warning"
                onClick={this.handleCloseDialogDuplicate}
              />
              <Button text="Proceed" onClick={this.handleApproveDuplicate} />
            </DialogFooter>
          </Dialog>
        )}
        {state.cptForDelete && (
          <DialogConfirmDeleteCPTCode
            onCancel={this.handleCloseCptDeleteConfirm}
            onConfirm={this.handleApproveDeleteCpt}
          />
        )}
        {this.renderOrderModifyWarningNotification()}
      </div>
    );
  }

  cloneCpt(cpt: Array<any>) {
    if (!cpt) {
      cpt = [];
    }
    return cpt.map((code) => ({ ...code }));
  }

  findPatientName() {
    const patient = this.state.patientViewInfo;
    if (patient && patient[0]) {
      return patient[0].last_name + ' ' + patient[0].first_name;
    }
    return 'this patient';
  }

  handleCloseDialogDuplicate = () => {
    OrderFormPageActions.deleteOrder(this.props.orderId).then(() =>
      this.props.history.push(URL_ORDER_BASE)
    );
  };

  handleApproveDuplicate = () => {
    this.setState({ saveDuplicate: true }, () =>
      (this.refs.form as Form).submit()
    );
  };

  submitSuccess() {
    const { props, state } = this;
    if (!props.allowSubmit) {
      return;
    }
    const { model, orderDataPatient } = state;
    const cpt = model.GetIntCptArr || [];
    const fnlCpt = model.GetFnlCptArr || [];

    return OrderFormPageActions.addExamInfoOrder(
      props.onEdit ? 'E' : 'A',
      Object.assign(new TExamInfoOrderData(), {
        examtypeid: model.examtypeid,
        callerName: props.callerName,
        patient_id: orderDataPatient.patientid,
        symptoms: model.symptoms,
        comment: model.comment,
        schedulestime: model.schedulestime,
        priority: model.priority,
        orderid: props.orderId,
        scheduleendtime: model.scheduleendtime,
        smoking: model.smoking,
        symptomatic: model.symptomatic,
        sheduleservdate: model.sheduleservdate,
        scheduleServiceDate: model.scheduleServiceDate,
        isolation: model.isolation,
        isolation_description:
          model.isolation === 'Y' ? model.isolation_description : '',
        radiologygp: model.radiologygroupid,
        technician: model.technologistid,
        assigntime: model.orderassigned_time,
        assgindt: model.orderassigned_date,
        compdt: model.ordercompletion_date,
        room: model.room,
        lastModifiedLock: model.lastModifiedLock,
      }),
      cpt.map(ExamInformation.mapCptCode),
      model.GetIntIcdArr,
      fnlCpt.map(ExamInformation.mapCptCode),
      model.FaxPhone,
      state.saveDuplicate ? 'SD' : '',
      props.onEdit ? (model.signstatusvis ? 'PR' : 'N') : 'N' //??
    ).then((response: 'S' | 'E' | TAddExamInfoOrderRes) => {
      if (response !== 'S' && response !== 'E' && response.orderExist) {
        this.setState({ saveDuplicatePopup: true }, () =>
          this.props.onPopupOpen(true)
        );
        return response;
      }
      const flagError: boolean =
        response === 'E' || (response !== 'S' && !response.success);
      if (flagError) {
        if (response === 'E') {
          Notification.warning('Data submit error!');
        } else {
          this.showOrderModifyWarningNotification();
        }
        return response;
      }
      const responseSuccess: TAddExamInfoOrderRes =
        response === 'S' || response === 'E'
          ? new TAddExamInfoOrderRes()
          : response;
      const updatedModel = {
        ...model,
        lastModifiedLock: responseSuccess.lastModifiedLock,
      };

      const updatedState = {
        onAjax: false,
        model: updatedModel,
        examData: (response as TAddExamInfoOrderRes).success
          ? {
              ...this.state.examData,
              FaxPhone: updatedModel.FaxPhone,
            }
          : this.state.examData,
      };
      this.setState(updatedState, () => {
        props.submitSuccess(
          props.orderId ? props.orderId : responseSuccess.orderId,
          this.state,
          responseSuccess.lastModifiedLock
        );
      });
      return response;
    });
  }

  static mapCptCode(item: any) {
    return {
      refid: item.refid,
      modifier: item.modifier_code,
      price: item.cpt_price,
      editvisible: true,
      data: item.cpt_code,
      desc: item.cpt_description,
      extremity: '',
      cpt_desc: item.cpt_desc || item.cpt_description,
      cpt_description: item.cpt_desc || item.cpt_description,
      addvisible: true,
      accession_number: item.accession_number,
      external_accession_number: item.external_accession_number,
      external_placer_order_number: item.external_placer_order_number || '',
      discount_percentage: '',
      cptcount: '',
      cpt_id: '',
      mode: '',
    };
  }
}

export default Container.create(ExamInformation, { withProps: true });
