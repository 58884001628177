import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import RadioOptions from 'components/project/common/RadioOptions';
import { Select } from 'components/form/select';
import { FakeInput } from 'components/form/textField';
import { Textarea } from 'components/form/textarea';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';
import resolver, { modeOfPaymentRequiresNotes } from './validation';

export interface BulkInvoiceFormType {
  bal: number;
  discount: number;
  feeSchedule: number;
  modeOfPayment: number;
  notes: string;
  payment: number;
  total: number;
}

interface PropTypes {
  isInvoicePaid: boolean;
  defaultValues: BulkInvoiceFormType;
  onSubmit: (payload: BulkInvoiceFormType) => Promise<any>;
}

const FormInvoiceBulkMode = ({
  isInvoicePaid,
  defaultValues,
  onSubmit,
}: PropTypes) => {
  const {
    control,
    formState: { isSubmitting, isDirty },
    reset,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver,
  });

  const modeOfPayment = watch('modeOfPayment');

  const isNotesRequired = useMemo(
    () => modeOfPaymentRequiresNotes.includes(modeOfPayment),
    [modeOfPayment]
  );

  useEffect(() => {
    reset(defaultValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form className="row" onSubmit={handleSubmit(onSubmit)}>
      <FakeInput
        name="feeSchedule"
        label="Fee Schedule"
        className="col-sm-6 col-md-3"
        value={`$${defaultValues.feeSchedule.toFixed(2)}`}
      />
      <FakeInput
        name="discount"
        label="Discount"
        className="col-sm-6 col-md-3"
        value={`$${defaultValues.discount.toFixed(2)}`}
      />
      <FakeInput
        name="bal"
        label="Charge"
        className="col-sm-6 col-md-3"
        value={`$${defaultValues.bal.toFixed(2)}`}
      />
      <FakeInput
        name="payment"
        label="Payment"
        className="col-sm-6 col-md-3"
        value={`$${defaultValues.payment.toFixed(2)}`}
      />
      <Select
        name="modeOfPayment"
        label="Mode Of Payment"
        className="col-md-6 col-lg-4"
        required
        disabled={isInvoicePaid}
        options={RadioOptions.modeOfPayment}
        control={control}
      />
      <Textarea
        name="notes"
        label="Notes"
        className="col-md-6 col-lg-8"
        disabled={isInvoicePaid}
        rows={1}
        required={isNotesRequired}
        control={control}
      />
      <FakeInput
        name=""
        label="Balance Total:"
        className={clsx(
          'col-sm-6 col-md-3',
          defaultValues.total < 0.01 ? 'color-green' : 'text-danger'
        )}
        value={`$${defaultValues.total.toFixed(2)}`}
      />
      <ControlsLayout alignX="auto">
        {isInvoicePaid && (
          <div className="text-secondary">Invoice have paid.</div>
        )}
        <Button
          text="Apply"
          type="submit"
          data-testid="submit"
          disabled={isInvoicePaid || isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormInvoiceBulkMode;
