import { apiRequest } from 'services/RequestService';
import { dateISOStringToDateAndTime } from 'utils/DateUtils';

export class TFacilityFilter {
  searchKey: string = '';
  facilityName: string = '';
  state: string = '';
  region: string = '';
  lastname: string = '';
  firstname: string = '';
  contactTitle: string = '';
}

export class DiscountService {
  service: string = '';
  percentage: number = 0;
  serviceId: number = 0;
}

export class FacilityPageDiscount {
  '1_X-Ray': DiscountService = new DiscountService();
  '2_Ultrasound': DiscountService = new DiscountService();
  '3_EKG': DiscountService = new DiscountService();
  '5_Transportation single pt': DiscountService = new DiscountService();
  '6_Transportation multi pts': DiscountService = new DiscountService();
  '7_Set Up': DiscountService = new DiscountService();
  '8_Employee Screening': DiscountService = new DiscountService();
  '9_Echocardiogram': DiscountService = new DiscountService();
  '10_Bone Density': DiscountService = new DiscountService();
  '11_CT': DiscountService = new DiscountService();
  '12_Athletic Services': DiscountService = new DiscountService();
  '13_H.I.R. & Interest Payments': DiscountService = new DiscountService();
}

export class TFacilityItem {
  address1: string = '';
  address2: string = '';
  admin_email: string = '';
  admin_nm: string = '';
  bed_cnt: string = '';
  bill_regionid: string = '';
  city: string = '';
  comment_facility: string = '';
  country: string = '';
  cpt_info: { id: string; flag: string } = { id: null, flag: null };
  division_id: number;
  flag: string = null;
  id: string = null;
  dispatch_region_nm: string = '';
  eligibility_status: string = '';
  facility_alias_nm: string = '';
  facility_nm: string = '';
  facility_npi: string = '';
  facility_stat: string = '';
  facility_type: string = '';
  fax: string = '';
  groupid: string = null;
  integration_id: Array<string> = [];
  nursing_email: string = '';
  nursing_nm: string = '';
  phone: string = '';
  pos: string = '';
  refid: number = null;
  start_dt: string = '';
  state: string = '';
  website: string = '';
  zipcode: string = '';
}

export class TFacilityItemRes {
  facility: Array<TFacilityItem> = [];
  Region: Array<TRegion> = [];
  floor: Array<TFloor> = [];
  OrderType: { [key: number]: boolean } = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  };
}

export class TRegion {
  location: string = '';
  refid: number = null;
  region_id: number = null;
  state_id: number = null;
}

export class TFloor {
  refid: number = null;
  Name: string = '';
  Fax: string = '';
  Phone: string = '';
  extension: string = '';
  Email: string = '';
  Comment: string = '';
  AddOpt: boolean = false;
  DelOpt: boolean = true;
}

export class TFacilityData {
  start_dt: string = '';
  facility_addtype: string = '';
}

export class TStationDetails {
  Name: string = '';
  Phone: string = '';
  extension: string = '';
  Fax: string = '';
  Email: string = '';
  Comment: string = '';
}

export class TAddFacilityItem {
  location?: any;
  facilityData: TFacilityData = new TFacilityData();
  OrderType: Array<number> = [];
  stationDetailsList: Array<TStationDetails> = [];
}

export class TEditFacilityItem extends TAddFacilityItem {
  refid: number = null;
}

export class TViewFacilityMaster {
  refid: number = null;
  facility_nm: string = '';
  facility_stat: string = '';
  pos: string = '';
  bed_cnt: string = '';
  start_dt: string = '';
  comment_billing: string = '';
  address1: string = '';
  address2: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  country: string = '';
  phone: string = '';
  fax: string = '';
  nursing_nm: string = '';
  nursing_email: string = '';
  admin_nm: string = '';
  admin_email: string = '';
  dispatch_region_nm: string = '';
  bill_contactnm: string = '';
  bill_phone: string = '';
  bill_email: string = '';
  bill_addres1: string = '';
  bill_addres2: string = '';
  bill_city: string = '';
  bill_state: string = '';
  bill_zipcode: string = '';
  facility_npi: string = '';
  pps: string = '';
  xray: string = '';
  idtf: string = '';
  ekg: string = '';
  transportation: string = '';
  setup: string = '';
  specialty: string = '';
  bill_fax: string = '';
  bill_regionid: string = '';
  perdiem_unit: string = '';
  perdiem_price: string = '';
  perdiem_billtype: string = '';
  per_diem: string = '';
  fee_schedule: Array<TFeeSchedule> = [];
  floorArr: Array<TFloorItem> = [];
  OwnerArr: Array<TOwnerItem> = [];
}

export class TUpdatedViewFacilityMaster {
  refid: number = null;
  facility_nm: string = '';
  facility_stat: string = '';
  pos: string = '';
  bed_cnt: string = '';
  start_dt: string = '';
  comment_billing: string = '';
  address1: string = '';
  address2: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  country: string = '';
  phone: string = '';
  fax: string = '';
  nursing_nm: string = '';
  nursing_email: string = '';
  admin_nm: string = '';
  admin_email: string = '';
  dispatch_region_nm: string = '';
  bill_contactnm: string = '';
  bill_phone: string = '';
  bill_email: string = '';
  bill_addres1: string = '';
  bill_addres2: string = '';
  bill_city: string = '';
  bill_state: string = '';
  bill_zipcode: string = '';
  facility_npi: string = '';
  pps: string = '';
  xray: string = '';
  idtf: string = '';
  ekg: string = '';
  transportation: string = '';
  setup: string = '';
  specialty: string = '';
  bill_fax: string = '';
  bill_regionid: string = '';
  perdiem_unit: string = '';
  perdiem_price: string = '';
  perdiem_billtype: string = '';
  per_diem: boolean = false;
  fee_schedule: Array<TFeeSchedule> = [];
  floorArr: Array<TFloorItem> = [];
  OwnerArr: Array<TOwnerItem> = [];
  perdiemarr: Array<{ FullLead: number; price: string }> = [];
}

export class TOwnerItem {
  refid: number = null;
  first_name: string = '';
  last_name: string = '';
  fullname: string = '';
  contact_title: string = '';
  email: string = '';
  phone: string = '';
  fax: string = '';
  status: string = '';
  comments: string = '';
  selstat: boolean = false;
}

export class TFloorItem {
  refid: number = null;
  Name: string = '';
  Fax: string = '';
  Phone: string = '';
  extension: string = '';
  Email: string = '';
  Comment: string = '';
  AddOpt: boolean = false;
  DelOpt: boolean = false;
}

export class TFeeSchedule {
  refid: number = null;
  cpt_code: string = '';
  cpt_description: string = '';
  cpt_price: string = '';
  profprice: string = '';
  globalprice: string = '';
  is_default: '' | 'T' | 'G' | 'p' = '';
  insure_glbl_prc: string = '';
  insure_prof_prc: string = '';
  insure_tech_prc: string = '';
  is_default_insure: '' | 'T' | 'G' | 'P' = '';
  modifier_code: string = '';
  facility_id: number = null;
}

export class TPerdiemUnit {
  refid: number = null;
  FullLead: string = '';
  Index: string = '';
  price: string = '';
  AddOpt: boolean = false;
  DelOpt: boolean = false;
}

class FacilityPageService {
  loadFacilityItem(id: number): Promise<TFacilityItemRes> {
    return apiRequest<{
      facility: Array<TFacilityItem>;
      Region: Array<TRegion>;
      floor: Array<TFloor>;
      OrderType: Array<{ OrdId: number }>;
    }>({ url: 'facility.FacilityMaster.GetFacility', data: [id] }).then(
      (response) => {
        const res: {
          facility: Array<TFacilityItem>;
          Region: Array<TRegion>;
          floor: Array<TFloor>;
          OrderType: { [key: number]: boolean };
        } = {
          facility: response.facility,
          Region: response.Region,
          floor: response.floor,
          OrderType: { 1: false, 2: false, 3: false, 4: false, 5: false },
        };
        if (
          res.facility[0].start_dt &&
          res.facility[0].start_dt !== '0000-00-00'
        ) {
          const [startDate] = dateISOStringToDateAndTime(
            `${res.facility[0].start_dt}T00:00:00Z`
          );
          res.facility[0].start_dt = startDate;
        } else {
          res.facility[0].start_dt = null;
        }
        const selected = Object.assign([], response.OrderType);
        res.OrderType = { 1: false, 2: false, 3: false, 4: false, 5: false };
        if (res.OrderType !== null) {
          const len = selected.length;
          for (let i = 0; i < len; i++) {
            res.OrderType[selected[i].OrdId] = true;
          }
        }
        return res;
      }
    );
  }

  beforeSend<T extends TAddFacilityItem>(item: T) {
    const selected: Array<number> = [];
    for (let i = 1; i <= 5; i++) {
      if (item.OrderType[i]) {
        selected.push(i);
      }
    }
    item.OrderType = selected;
    return item;
  }

  saveFacilityItem(item: TAddFacilityItem) {
    item = this.beforeSend(item);
    item.facilityData.facility_addtype = 'O';
    const data = [item.facilityData, item.stationDetailsList, item.OrderType];

    return apiRequest<'E' | number>({
      url: 'facility.FacilityMaster.AddFacility',
      data,
    });
  }

  updateFacilityItem(item: TEditFacilityItem) {
    item = this.beforeSend(item);
    item.facilityData.facility_addtype = 'O';
    const data = [
      item.facilityData,
      item.stationDetailsList,
      item.refid,
      item.OrderType,
    ];

    return apiRequest<'E' | number>({
      url: 'facility.FacilityMaster.EditFacility',
      data,
    });
  }

  loadLocationList(
    regionId: number,
    stateId: number
  ): Promise<Array<{ label: string; value: number }>> {
    return apiRequest<'SE' | Array<{ label: string; data: number }>>({
      url: 'generalmaster.MvLocation.GetLoactionByRegion',
      data: [regionId, stateId],
    }).then((response) => {
      if (response === 'SE') {
        return [];
      }
      const res: Array<{ label: string; value: number }> = [];
      for (let i = 0; i < response.length; i++) {
        res.push({
          label: response[i].label,
          value: parseInt('' + response[i].data),
        });
      }
      return res;
    });
  }

  loadServiceList(id: number) {
    return apiRequest<
      0 | Array<{ refid: number; name: string; percent: string }>
    >({ url: 'codemaster.CPTCode.GetServiceName', data: [id, 'F'] });
  }

  loadBillingItem(id: number) {
    return apiRequest<{
      0: TViewFacilityMaster;
      perdiem_unit: Array<TPerdiemUnit>;
    }>({
      url: 'facility.FacilityMaster.GetViewFacilityMaster',
      data: [id],
    }).then((response) => {
      const viewFacilityMaster: TViewFacilityMaster = response[0];
      const updatedViewFacilityMaster: TUpdatedViewFacilityMaster =
        Object.assign(new TUpdatedViewFacilityMaster(), response[0]);
      const perdiem_unit: Array<TPerdiemUnit> = response.perdiem_unit;
      updatedViewFacilityMaster.per_diem = viewFacilityMaster.per_diem === 'Y';
      updatedViewFacilityMaster.perdiemarr = perdiem_unit
        ? Object.keys(perdiem_unit).map((key) => (perdiem_unit as any)[key])
        : [];
      if (
        !updatedViewFacilityMaster.perdiemarr ||
        !Array.isArray(updatedViewFacilityMaster.perdiemarr)
      ) {
        updatedViewFacilityMaster.perdiemarr = [];
      }
      if (updatedViewFacilityMaster.perdiemarr.length === 0) {
        updatedViewFacilityMaster.perdiemarr.push({ FullLead: 0, price: '' });
      }
      return updatedViewFacilityMaster;
    });
  }

  updateBillingItem(item: {
    refid: number;
    perdiem_billtype: string;
    per_diem: boolean;
    floorArr: any;
    serviceArr: Array<{ refid: number; percent: string }>;
    perdiemarr: Array<{ FullLead: string; price: string }>;
    bill_contactnm: string;
    bill_phone: string;
    bill_email: string;
    bill_addres1: string;
    bill_addres2: string;
    bill_city: string;
    bill_state: string;
    bill_zipcode: string;
    bill_fax: string;
    pps: string;
    xray: string;
    idtf: string;
    ekg: string;
    setup: string;
    specialty: string;
    transportation: string;
    comment_billing: string;
    pos: string;
    bill_regionid: number;
    perdiem: string;
    perdiembilltype: string;
  }) {
    item.perdiembilltype = item.perdiem_billtype;
    item.perdiem = item.per_diem === true ? 'Y' : 'N';

    delete item.perdiem_billtype;
    delete item.per_diem;
    const data = [item.refid, item, item.floorArr, item.serviceArr];

    return apiRequest<'S'>({
      url: 'facility.FacilityMaster.UpdateFacilityDtl',
      data,
    }).then((response) => {
      return response[0];
    });
  }

  loadContactList(
    id: number,
    flagActiveOnly: boolean
  ): Promise<{
    comments: string;
    corpowner: Array<{ is_selected: boolean; refid: number }>;
    selected: Array<number>;
  }> {
    return apiRequest<
      Array<{
        comments: string;
        corpowner: Array<{ is_selected: boolean; refid: number }>;
      }>
    >({
      url: 'facility.FacilityMaster.GetFacility_MarketInfo',
      data: [id, flagActiveOnly],
    }).then((response) => {
      const contactList = response[0].corpowner;
      const selected: Array<number> = [];
      contactList.forEach((item) => {
        if (item.is_selected) {
          selected.push(item.refid);
        }
      });
      const res: {
        comments: string;
        corpowner: Array<{ is_selected: boolean; refid: number }>;
        selected: Array<number>;
      } = {
        comments: response[0].comments,
        corpowner: response[0].corpowner,
        selected: selected,
      };
      return res;
    });
  }

  saveContactList(item: {
    facilityId: number;
    comments: string;
    contactList: Array<{ refid: number }>;
    selected: Array<number>;
  }) {
    const { contactList, selected } = item;
    const ownerList: Array<TOwner> = [];
    for (let i = 0; i < contactList.length; i++) {
      const owner = contactList[i];
      let found = false;
      for (let j = 0; j < selected.length; j++) {
        const selectedId = selected[j];
        if (owner.refid === selectedId) {
          found = true;
          break;
        }
      }
      // @ts-ignore
      ownerList.push({ ...owner, is_selected: found });
    }
    return this.saveContactListDirect(
      item.facilityId,
      item.comments,
      ownerList
    );
  }

  saveContactListDirect(
    facilityId: number,
    comments: string,
    ownerList: Array<TOwner>
  ) {
    const data = [facilityId, comments, ownerList];

    return apiRequest<'SE' | 'S'>({
      url: 'facility.FacilityMaster.SaveFacility_MarketInfo',
      data,
    });
  }

  loadContactItem(id: number) {
    return apiRequest<'SE' | Array<TMvFacilityOwnerView>>({
      url: 'facility.MvFacilityOwner.GetViewByID',
      data: [id],
    }).then((response) => {
      if (response === 'SE') {
        return null;
      }
      if (!response[0].status) {
        response[0].status = 'A';
      }
      return response[0];
    });
  }

  saveContactItem(item: TAddMvFacilityOwner) {
    item.corp_name = '';
    item.radiology_id = 0;
    if (!item.status) {
      item.status = 'A';
    }
    const data = [
      item.corp_name,
      item.radiology_id,
      item.first_name,
      item.middle_name,
      item.last_name,
      item.billing_address,
      item.address1,
      item.address2,
      item.city,
      item.state,
      item.zipcode,
      item.phone,
      item.fax,
      item.email,
      item.contact_name,
      item.contact_nm,
      item.comments,
      item.status,
      item.con_title,
      item.facility_id,
      'Y',
    ];

    //TODO: look for last argument - facility local
    return apiRequest<'SE' | 'E' | number>({
      url: 'facility.MvFacilityOwner.AddMvFacilityOwner',
      data,
    });
  }

  updateContactItem(item: TEditMvFacilityOwner) {
    item.corp_name = '';
    item.radiology_id = 0;
    const data = [
      item.refid,
      item.corp_name,
      item.radiology_id,
      item.first_name,
      item.middle_name,
      item.last_name,
      item.billing_address,
      item.address1,
      item.address2,
      item.city,
      item.state,
      item.zipcode,
      item.phone,
      item.fax,
      item.email,
      item.contact_name,
      item.contact_nm,
      item.comments,
      item.status,
      item.con_title,
      item.facility_id,
    ];

    return apiRequest<'SE' | 'E' | 'S'>({
      url: 'facility.MvFacilityOwner.EditMvFacilityOwner',
      data,
    });
  }

  deleteContactItem(id: number) {
    return apiRequest<'SE' | 'S'>({
      url: 'facility.MvFacilityOwner.DeleteFacilityOwner',
      data: [id],
    });
  }

  loadDocumentList(facilityId: number) {
    return apiRequest<0 | { file: Array<TFacilityDocument> }>({
      url: 'facility.FacilityMaster.GetFacilityDocument',
      data: [facilityId],
    }).then((response) => {
      if (!response) {
        return [];
      }
      const fileList: Array<TFacilityDocument> = response.file;
      if (!fileList) {
        return [];
      }
      if (fileList.length === 1 && fileList[0].filenm === '') {
        return [];
      }
      let count = 1;
      const fileEditList: Array<TEditFacilityDocument> = [];
      fileList.forEach((item) => {
        const itemEdit: TEditFacilityDocument = new TEditFacilityDocument();
        itemEdit.filenm = item.filenm;
        itemEdit.desc = item.desc;
        itemEdit.remvImg = item.remvImg;
        itemEdit.uploaded_dt = item.uploaded_dt;
        itemEdit.refid = count++;
        fileEditList.push(itemEdit);
      });
      return fileEditList;
    });
  }

  saveDocumentList(
    facilityId: number,
    fileList: Array<{ filestatus: 'Y' | 'N'; filenm: string; desc: string }>
  ) {
    return apiRequest<'SE' | number>({
      url: 'facility.FacilityMaster.AddFacilityDocument',
      data: [facilityId, fileList],
    });
  }

  onImport(filename: string) {
    return apiRequest<'SE' | 'S' | 'E'>({
      url: 'codemaster.Modifier.ImportModifier',
      data: [filename],
    });
  }
}

export class TOwner {
  refid: number = null;
  is_selected: boolean = false;
  first_name: string = '';
  last_name: string = '';
  phone: string = '';
  fax: string = '';
  email: string = '';
  comments: string = '';
  status: string = '';
  contact_title: string = '';
}

export class TMvFacilityOwnerView {
  refid: number = null;
  first_name: string = '';
  corportation_name: string = '';
  radiology_id: number = null;
  last_name: string = '';
  middle_name: string = '';
  billing_address: string = '';
  address1: string = '';
  address2: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  phone: string = '';
  fax: string = '';
  email: string = '';
  con_title: string = '';
  contact_name: string = '';
  facility_id: number = null;
  contact_nm: string = '';
  comments: string = '';
  status: string = '';
  facility_local: string = '';
}

export class TAddMvFacilityOwner {
  corp_name: string = '';
  radiology_id: number = null;
  first_name: string = '';
  middle_name: string = '';
  last_name: string = '';
  billing_address: string = '';
  address1: string = '';
  address2: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  phone: string = '';
  fax: string = '';
  email: string = '';
  contact_name: string = '';
  contact_nm: string = '';
  comments: string = '';
  status: string = '';
  con_title: string = '';
  facility_id: number = null;
}

export class TEditMvFacilityOwner extends TAddMvFacilityOwner {
  refid: number = null;
}

export class TFacilityDocument {
  filenm: string = '';
  desc: string = '';
  remvImg: string = '';
  uploaded_dt: string = '';
}

export class TEditFacilityDocument extends TFacilityDocument {
  refid: number = null;
}

export class TStateRegoin {
  refid: number = null;
  region_id: number = null;
  state_id: number = null;
  location: string = '';
}

export default new FacilityPageService();
