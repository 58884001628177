import React from 'react';
import { Container } from 'flux/utils';

import { RequestedDocExportLink } from 'page/components/ExportLink';
import DialogFormTextFax, {
  getInitialValues,
  FaxModel,
} from 'page/components/DialogFormTextFax';
import { Grid, GridControlButton, TPagination } from 'components/grid';
import { Button, IconButton } from 'components/button';
import Notification from 'components/modal/Notification';
import Fieldset from 'components/form/Fieldset';
import Checkboxes from 'components/form/input/Checkboxes';
import { LayoutSideTitle } from 'components/layout';
import Badge from 'components/badge';
import RequestedDocuments from './DialogRequestedDocuments';
import DialogFormEmail, {
  FormModel as DialogFormEmailType,
} from './DialogFormEmail';

import FacilityTabActions from 'actions/patient-records/document-manager/document-audit/facility/FacilityTabActions';
import FacilityTabStore from 'stores/patient-records/document-manager/document-audit/facility/FacilityTabStore';
import { storeGrid } from 'stores/_mobx/grid';
import { TTransmitLogFilter } from 'services/patient-records/document-manager/document-audit/facility/FacilityTabService';
import { getPagination } from 'utils/getPagination';

export interface PTransmitLogComponent {
  pageKey: string;
  title: string;
  facilityId: number;
  filter: TTransmitLogFilter;
  selectedEntity: { bill_fax: string; bill_email: string };
  toggleFilterButton: (isVisible: boolean) => void;
  callback: () => void;
  saveEmail: (data: any, selectedItems: Array<any>) => void;
  saveFax: (data: any, selectedItems: Array<any>) => void;
}

export class STransmitLogComponent {
  transmitLogList: Array<any> = [];
  transmitLogCount: number = 0;
  onAjax: boolean = false;
  pagination: TPagination = new TPagination();
  showFilter: boolean = false;
  showSendEmail: boolean = false;
  faxValues: FaxModel | null = null;
  requestedDocuments: string[] = [];
  optionsLoaded: boolean = false;
  emailItem: any = null;
  legend: string = '';
  selected: Array<any> = [];
  optionsDocument: { label: string; value: string }[] = [];
}

class TransmitLogComponent extends React.Component<
  PTransmitLogComponent,
  STransmitLogComponent
> {
  grid: Grid = null;
  COLUMNS: Array<any> = null;

  constructor(props: PTransmitLogComponent) {
    super(props);
    this.COLUMNS = [
      { name: 'ppxray_patientinfo.last_name', title: 'Last name' },
      { name: 'ppxray_patientinfo.first_name', title: 'First name' },
      { name: 'examdetails', title: 'Exam information' },
      { name: 'dos', title: 'DOS', className: 'width-100' },
      {
        name: 'isSigned',
        title: 'Signature Status',
        className: 'text-center',
        render: (value: boolean) => (
          <Badge variant={value ? 'success' : 'danger'}>
            {value ? 'Signed' : 'Unsigned'}
          </Badge>
        ),
      },
      {
        name: 'clinicalnote',
        title: 'Clinical Note',
        className: 'text-center',
        render: (value: any, data: any) => {
          const className = `bi bi-${
            data.repcode.CN === 1 ? 'check-circle' : 'x-circle'
          }`;

          return (
            <span className="text-primary fs-5">
              <i className={className} />
            </span>
          );
        },
      },
      {
        name: 'insurance',
        title: 'Insurance',
        className: 'text-center',
        render: (value: any, data: any) => {
          const className = `bi bi-${
            data.repcode.F === 1 ? 'check-circle' : 'x-circle'
          }`;

          return (
            <span className="text-primary fs-5">
              <i className={className} />
            </span>
          );
        },
      },
      {
        name: 'docs_needed',
        title: 'Document(s) needed',
        render: (name: any, data: any) => {
          return (
            <Checkboxes
              className="part-inline width-200"
              name={data.orderid + '_documentOptions'}
              noLabel
              options={this.state.optionsDocument || []}
              value={data.repcode}
              onSetValue={(n, d) => {
                data.repcode = d;
                this.forceUpdate();
              }}
            />
          );
        },
      },
    ];
    if (this.props.pageKey !== 'F') {
      this.COLUMNS.push({ name: 'facility', title: 'Facility' });
    }
  }

  static getStores() {
    return [FacilityTabStore];
  }

  static calculateState(
    prevState: STransmitLogComponent,
    props: PTransmitLogComponent
  ) {
    if (!prevState) {
      prevState = Object.assign(new STransmitLogComponent(), {
        pagination: getPagination(
          storeGrid.getPageSize('transmit_tab_grid' + props.pageKey)
        ),
      });
    }

    return {
      ...prevState,
      transmitLogList: FacilityTabStore.getState().transmitLogList,
      transmitLogCount: FacilityTabStore.getState().transmitLogCount,
    };
  }

  onPaginationChange = (pagination: TPagination) => {
    this.setState({ pagination }, () => this.updateData());
  };

  onSelectChange = (selected: Array<any>) => {
    this.setState({ selected });
  };

  componentDidMount() {
    this.props.toggleFilterButton(false);
    FacilityTabActions.loadUserDocumentOptions().then((optionsDocument) => {
      this.setState({ optionsLoaded: true, optionsDocument }, this.updateData);
    });
  }

  componentWillUnmount() {
    this.props.toggleFilterButton(true);
  }

  render() {
    const { state, props } = this;

    return (
      <>
        <LayoutSideTitle title={props.title}>
          <Button variant="danger" text="Back" onClick={props.callback} />
        </LayoutSideTitle>
        <p className="text-secondary fw-bold">
          Please provide the following documents identified in the list below.
          Federal regulations require on file for conditions of coverage
        </p>

        <div className="d-flex gap-3 text-primary">
          <IconButton
            disabled={!this.state.selected.length}
            onClick={this.onSendEmail}>
            <i className="bi bi-envelope" />
          </IconButton>
          <IconButton
            disabled={!this.state.selected.length}
            onClick={this.onSendFax}>
            <i className="bi bi-printer" />
          </IconButton>
          <RequestedDocExportLink
            facilityId={this.props.facilityId}
            pageKey={this.props.pageKey}
            orders={this.getSelectedItems()}
          />
        </div>

        <Fieldset className="row">
          <Grid
            id={'transmit_tab_grid' + props.pageKey}
            gridControlPanel={
              <GridControlButton
                title="Choose Requested Documents"
                onClick={this.showRequestedDocuments}
              />
            }
            columns={this.COLUMNS}
            onAjax={state.onAjax || !state.optionsLoaded}
            onSelectChange={this.onSelectChange}
            selectId="id"
            dataSource={state.transmitLogList}
            dataSourceCount={state.transmitLogCount}
            pagination={state.pagination}
            onPaginationChange={this.onPaginationChange}
            ref="grid"
          />
        </Fieldset>
        {state.faxValues && (
          <DialogFormTextFax
            withRecipientNumber
            withExamRequisition={this.props.pageKey === 'PHY'}
            defaultValues={state.faxValues}
            onSubmit={this.handleSendFax}
            onClose={this.handleCloseSendFax}
          />
        )}
        {state.showSendEmail && (
          <DialogFormEmail
            defaultEmail={props.selectedEntity.bill_email}
            withExam
            onClose={this.handleCloseDialogEmail}
            onSubmit={this.handleSendEmail}
          />
        )}
        {state.requestedDocuments.length ? (
          <RequestedDocuments
            selectedDocuments={this.state.requestedDocuments}
            onSubmit={this.handleSubmitRequestedDocs}
            onCancel={this.handleCloseDialogRequestedDocs}
          />
        ) : null}
      </>
    );
  }

  getSelectedItems(): Array<any> {
    const { selected } = this.state;
    return this.state.transmitLogList.filter(({ id }) => selected.includes(id));
  }

  onSendEmail = (event: React.MouseEvent) => {
    event.preventDefault();

    if (this.noSelection()) {
      return;
    }
    this.setState({ showSendEmail: true });
  };

  noSelection() {
    const { selected } = this.state;
    if (selected.length) {
      return false;
    }
    Notification.warning('Please select at least one document');
    return true;
  }

  onSendFax = (event: React.MouseEvent) => {
    event.preventDefault();

    if (this.noSelection()) {
      return;
    }

    this.setState({
      faxValues: {
        ...getInitialValues(),
        to: this.props.selectedEntity.bill_fax,
      },
    });
  };

  handleCloseDialogEmail = () => {
    this.setState({ showSendEmail: false });
  };

  handleSendEmail = (data: DialogFormEmailType) => {
    this.handleCloseDialogEmail();

    const selectedItems = this.getSelectedItems();

    return this.props.saveEmail(data, selectedItems);
  };

  handleCloseSendFax = () => {
    this.setState({ faxValues: null });
  };

  handleSendFax = (data: FaxModel) => {
    const selectedItems = this.getSelectedItems();

    this.setState({ faxValues: null });

    this.props.saveFax(data, selectedItems);
  };

  updateData = () => {
    const { pagination } = this.state;

    this.setState({ onAjax: true }, () => {
      FacilityTabActions.loadTransmitLogList(
        pagination.skip,
        pagination.pageSize,
        this.props.facilityId,
        this.props.pageKey,
        this.props.filter
      ).then(() => {
        this.updateDataCallback();
        this.assignExistingRepCodes();
      });
    });
  };

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.setState({
      onAjax: false,
      selected: [],
    });

    if (grid) {
      // @ts-ignore
      grid.clearSelection();
    }
  };

  showRequestedDocuments = () => {
    const requestedDocuments = this.state.optionsDocument.map(
      ({ value }) => value
    );
    this.setState({ requestedDocuments });
  };

  handleCloseDialogRequestedDocs = () => {
    this.setState({ requestedDocuments: [] });
  };

  handleSubmitRequestedDocs = (codes: Array<{ code: string }>) => {
    const payload = codes.map(({ code }) => ({ value: code, label: code }));
    this.updateRepCodes(payload);
    FacilityTabActions.saveDocumentOptions(payload);
    this.handleCloseDialogRequestedDocs();
  };

  updateRepCodes(optionsDocument: Array<{ label: string; value: string }>) {
    const transmitLogList = this.state.transmitLogList.map((log) => {
      const newLog = { ...log };

      if (log.repcode) {
        newLog.repcode = {};
        optionsDocument.forEach(({ value }) => {
          newLog.repcode[value] = log.repcode[value] || false;
        });
      }
      return newLog;
    });
    this.setState({ optionsDocument, transmitLogList });
  }

  assignExistingRepCodes() {
    this.updateRepCodes(this.state.optionsDocument);
  }
}

export default Container.create(TransmitLogComponent, { withProps: true });
