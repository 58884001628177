import { useState } from 'react';

import { Button } from 'components/button';
import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'components/modal/dialog';
import { PureRadio } from 'components/form/radio';

export type OptionsResendBy = '' | 'fax' | 'oru';

interface PropsType {
  isLoading: boolean;
  onClose: () => void;
  onResend: (resendBy: OptionsResendBy) => void;
}

const options = [
  { label: 'Fax', value: 'fax' },
  { label: 'ORU', value: 'oru' },
  { label: 'Both', value: '' },
];

const DialogResendReport = ({ isLoading, onClose, onResend }: PropsType) => {
  const [resendBy, setType] = useState<OptionsResendBy>('fax');

  const handleClickResend = () => {
    onResend(resendBy);
  };

  return (
    <Dialog handleClose={onClose}>
      <DialogHeader title="Resend Report" onClose={onClose} />
      <DialogBody className={isLoading ? 'on-ajax' : ''}>
        Would you like to resend the radiology report using:
        <PureRadio
          name="resendBy"
          className="part-inline"
          value={resendBy}
          options={options}
          onChange={setType}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          variant="warning"
          text="Cancel"
          disabled={isLoading}
          onClick={onClose}
        />
        <Button
          text="Resend"
          disabled={isLoading}
          onClick={handleClickResend}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogResendReport;
