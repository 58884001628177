import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react-lite';
// @ts-ignore
import useFluxStore from 'flux-hooks';

import DialogSecurityPermission from 'page/billing-coding/components/DialogSecurityPermission';
import Logo from './Logo';
import TopMenu from './TopMenu';
import UserMenu from './userMenu';

import UserProfileStore from 'stores/UserProfileStore';
import { TopMenuItemExtends } from 'stores/record/UserProfileRecord';
import { apiRequest } from 'services/RequestService';
import { storeInbox } from 'stores/_mobx/message/inbox';
import { downloadFile } from 'utils/downloadFile';
import { BASE_URL_FILE_DIR } from 'constant/config';

const handleClickDownloadRequisition = (event: React.MouseEvent) => {
  event.preventDefault();
  apiRequest<string | number | boolean>({
    url: 'pdfcreater.OrderPdfGenerator.staticPdf',
    data: ['Blank Exam Requisition'],
  }).then((r: string | number | boolean) => {
    downloadFile(
      `${BASE_URL_FILE_DIR}pdf-creator/${encodeURIComponent(r)}`,
      true
    );
  });
};

interface StateType {
  logoURL: string;
  menu: TopMenuItemExtends[];
}

const Header = () => {
  const [isDialogVisible, toggleDialog] = useState<boolean>(false);

  const { menu, logoURL }: StateType = useFluxStore(
    UserProfileStore,
    (prev: any, state: any) => ({
      menu: state.getState().userProfile.topMenu,
      logoURL: state.getInternalLogoURL(),
    })
  );

  const handleShowPermissionClick = (event: React.MouseEvent) => {
    event.preventDefault();
    toggleDialog(true);
  };

  useEffect(() => {
    storeInbox.getUnreadEmailCount();
  }, []);

  return (
    <>
      <header className="header">
        <Logo url={logoURL} />

        <Observer>
          {() => <TopMenu menu={menu} unreadCount={storeInbox.unreadTotal} />}
        </Observer>

        <UserMenu
          onClickPermission={handleShowPermissionClick}
          onClickDownloadRequisition={handleClickDownloadRequisition}
        />
      </header>

      {isDialogVisible && (
        <DialogSecurityPermission callback={() => toggleDialog(false)} />
      )}
    </>
  );
};

export default Header;
