import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import {
  storeProbe,
  ProbeFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/probe';
import { URL_PROBE } from 'constant/path/workflow';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const ProbeEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, probe } = storeProbe;

  const handleSubmit = async (data: ProbeFormModel) =>
    storeProbe.updateProbe(data).then((error) => {
      if (error) return error;
      history.push(URL_PROBE);
    });

  useEffect(() => {
    if (!fetching) storeProbe.getProbe(Number(params.id));

    return storeProbe.clearProbe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Probe" />
      <Form backUrl={URL_PROBE} defaultValues={probe} onSubmit={handleSubmit} />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(ProbeEditPage);
