import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

export class SClientUserPrivilegesPageStore {
  personSettings: Array<any> = [];
  dataSource: Array<any> = [];
  userList: Array<any> = [];
}

class ClientUserPrivilegesPageStore extends ReduceStore<
  SClientUserPrivilegesPageStore,
  CommonPayload
> {
  getInitialState() {
    return new SClientUserPrivilegesPageStore();
  }

  areEqual(
    state1: SClientUserPrivilegesPageStore,
    state2: SClientUserPrivilegesPageStore
  ) {
    return false;
  }

  reduce(state: SClientUserPrivilegesPageStore, action: CommonPayload) {
    switch (action.type) {
      case 'client-user-settings-overview-action':
        this.getState().personSettings = action.data;
        break;
      case 'client-user-settings-overview-applications-action':
        this.getState().dataSource = action.data;
        break;
      case 'client-user-settings-user-list-action':
        this.getState().userList = action.data;
        break;
      case 'client-user-settings-clear-data':
        this.getState().dataSource = [];
        this.getState().userList = [];
        this.getState().personSettings = [];
        break;
      default:
        break;
    }
    return state;
  }
}

const clientUserPrivilegesPageStore = new ClientUserPrivilegesPageStore(
  appDispatcher
);
export default clientUserPrivilegesPageStore;
