import AbstractLikeFilter from './AbstractLikeFilter';

export default class PhysicianLastName extends AbstractLikeFilter {
  name = 'physicianLastName';
  field = 'Physician Last Name';
  field1 = AbstractLikeFilter.getInsecureSecureField(
    'ppxray_providerinfo.last_name'
  );
  className = 'physician-last-name-filter';
}
