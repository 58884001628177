import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import Badge from 'components/badge';
import { Button, IconButton } from 'components/button';
import { Input, PureInput } from 'components/form/textField';
import MaskInput from 'components/form/maskInput';
import ReportInfoBlock from './components/ReportInfoBlock';
import FileCheckbox from './components/FileCheckbox';
import resolver from './components/validation';

import {
  storeFax,
  FaxSavePayloadType,
  PhoneReportType,
  FlagType,
  FaxTypeLabels,
} from 'stores/_mobx/workflow/order/fax';

const defaultFaxValue: FaxType = {
  faxNumber: '',
  receiverName: '',
};

interface FormType {
  faxes: FaxType[];
  files: FileType[];
  flag: FlagType;
}

interface FileType {
  fileName: string;
  filePath: string;
  isChecked: boolean;
  isCritical: boolean;
  faxType?: FaxTypeLabels;
}

export interface FaxType {
  faxNumber: string;
  receiverName: string;
}

export type DetailsType = PhoneReportType;

interface PropsType {
  title?: string;
  details: DetailsType;
  onClose: () => void;
  onSendFax: () => void;
}

const DialogPhone = ({
  title = 'Phone Report',
  details: {
    subscriber,
    facilityPhones,
    defaultValues,
    isSubscriberEditable,
    faxLabel,
    ...details
  },
  onClose,
  onSendFax,
}: PropsType) => {
  const [subscriberValue, setSubscriber] = useState<string>(subscriber);
  const {
    control,
    formState: { isSubmitting, errors },
    reset,
    handleSubmit,
  } = useForm<FormType>({ defaultValues, resolver });

  const {
    fields: faxes,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'faxes',
  });

  const { fields: files } = useFieldArray({
    control,
    name: 'files',
  });

  // @ts-ignore
  const errorMessage = errors?.files?.message || '';

  const handleClickAddFax = () => {
    append(defaultFaxValue);
  };

  const handleClickSubmit = () => {
    handleSubmit(({ faxes, files, flag }) => {
      const payload: FaxSavePayloadType = {
        faxes,
        flag,
        id: details.id,
        subject: '',
        report: isSubscriberEditable ? 'N' : 'Y',
        files: files
          .filter(({ isChecked }) => isChecked)
          .map((file) => ({
            ...file,
            filePath: `/file-storage/${file.filePath}`,
          })),
      };

      return storeFax.sendFax(payload).then((isSucceed) => {
        if (isSucceed) onSendFax();
      });
    })();
  };

  const handleClickSave = () => {
    storeFax
      .saveFaxSubscriber({
        subscriber: subscriberValue,
        id: details.id,
      })
      .then((isSucceed) => {
        if (isSucceed) onSendFax();
      });
  };

  const handleClickReset = () => {
    setSubscriber(subscriber);
    reset(defaultValues);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Dialog
      className="fax-report-popup"
      size="extraLarge"
      handleClose={onClose}>
      <DialogHeader title={title} onClose={onClose} />
      <DialogBody className={isSubmitting ? ' on-ajax' : ''}>
        <ReportInfoBlock info={details} />

        {isSubscriberEditable && (
          <pre className="row">
            {faxLabel.map((label, i) => (
              <span
                key={`${i}`}
                className={`col-sm-4${i < 3 ? ' fw-bold' : ''}`}>
                {label}
              </span>
            ))}
          </pre>
        )}

        <div className="row">
          <form onSubmit={handleClickSubmit} className="col-lg-8">
            {files.map(({ faxType, ...field }, index) => (
              <div className="d-flex align-items-center gap-3">
                <FileCheckbox
                  key={field.id}
                  field={field}
                  index={index}
                  control={control}
                />
                {faxType ? <Badge variant="light">{faxType}</Badge> : null}
              </div>
            ))}

            {errorMessage && <div className="text-danger">{errorMessage}</div>}

            <div className="mb-4" />

            {faxes.map((field, index) => (
              <div key={field.id} className="row">
                <Input
                  name={`faxes.${index}.receiverName`}
                  label="Receiver Name"
                  className="col-sm-6"
                  control={control}
                />
                <MaskInput
                  name={`faxes.${index}.faxNumber`}
                  label="Fax No"
                  format="###-###-####"
                  className="col-sm-4"
                  control={control}
                />
                <div className="controls text-primary col-sm-2">
                  <IconButton onClick={handleClickAddFax}>
                    <i className="bi bi-plus-circle" />
                  </IconButton>
                  <IconButton
                    disabled={faxes.length <= 1}
                    onClick={() => remove(index)}>
                    <i className="bi bi-trash" />
                  </IconButton>
                </div>
              </div>
            ))}
          </form>
          <div className="col-lg-4">
            <PureInput
              label="Spoke with"
              name="subscriber"
              value={subscriberValue}
              disabled={!isSubscriberEditable}
              onChange={setSubscriber}
            />
            <div>
              <b>Phones:</b>
            </div>
            {facilityPhones.map((contact, i) => (
              <p key={`${i}`}>{contact}</p>
            ))}
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          text="Send Fax"
          disabled={isSubmitting}
          onClick={handleClickSubmit}
        />
        {isSubscriberEditable && (
          <Button
            text="Save"
            disabled={!subscriberValue}
            onClick={handleClickSave}
          />
        )}
      </DialogFooter>
    </Dialog>
  );
};

export default DialogPhone;
