import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import DiagnosisForm, { FormModel } from './components/Form';

import { storeDiagnosisCode } from 'stores/_mobx/systemSetup/codeSet/diagnosisCodes';
import { URL_DIAGNOSIS_CODES } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const DiagnosisCodesAddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: FormModel) =>
    storeDiagnosisCode.addCode(payload).then((response) => {
      if (!response) {
        history.push(URL_DIAGNOSIS_CODES);
      }
      return response;
    });

  return (
    <>
      <Title title="Create new ICD code" />
      <DiagnosisForm backUrl={URL_DIAGNOSIS_CODES} onSubmit={handleSubmit} />
    </>
  );
};

export default DiagnosisCodesAddPage;
