import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import Dialog, { DialogHeader, DialogBody } from 'components/modal/dialog';

import {
  storeOrderDocuments,
  DocumentType,
} from 'stores/_mobx/workflow/order/documents';

interface PropsType {
  patientId: number;
  orderId: number;
  onClose: () => void;
}

const columns = [
  { name: 'typeName', title: 'Document type' },
  {
    name: 'fileName',
    title: 'File name',
    render: (fileName: string, { filePath }: DocumentType) => (
      <a href={filePath} target="_blank" rel="noreferrer">
        {fileName}
      </a>
    ),
  },
  { name: 'creationDate', title: 'Date' },
];

const DialogDocuments = ({ patientId, orderId, onClose }: PropsType) => {
  const { fetching, documents } = storeOrderDocuments;

  useEffect(() => {
    storeOrderDocuments.getDocuments({ patientId, orderId });

    return storeOrderDocuments.clearDocumentsStore;
  }, [patientId, orderId]);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Documents" onClose={onClose} />
      <DialogBody>
        <Grid
          groupBy="typeName"
          disablePagination
          noControl
          stateless
          onAjax={fetching}
          columns={columns}
          dataSource={documents}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogDocuments);
