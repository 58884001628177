import React from 'react';

import SortableTH from './SortableTH';

interface PDraggableTH {
  column: any;
  currentSort: { [key: string]: any };
  onDragStart: (e: any) => void;
  onDragOver: (e: any) => void;
  onDragEnd: (e: any) => void;
  onChangeSort: (name: string, direction?: 0 | 1 | -1) => void;
}

export default class DraggableTH extends React.Component<PDraggableTH, any> {
  render() {
    const { props } = this;
    return (
      <SortableTH
        column={props.column}
        currentSort={props.currentSort}
        draggable
        onChangeSort={(name, direction) => props.onChangeSort(name, direction)}
        onDragStart={(e: any) => props.onDragStart(e)}
        onDragOver={(e: any) => props.onDragOver(e)}
        onDragEnd={(e: any) => props.onDragEnd(e)}
      >
        {props.children}
      </SortableTH>
    );
  }
}
