import { makeObservable, observable, action, runInAction } from 'mobx';

import { TPagination } from 'components/grid/GridTypes';
import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import FilterNameAndState, {
  FilterType,
} from 'stores/_mobx/options/filterNameAndStatus';

interface GloveType {
  description: string;
  glove_number: string;
  last_survey: string;
  next_survey: string;
  refid: number;
}

interface GloveResponseType {
  description: string;
  glove_number: string;
  lastsurvey: string;
  nextsurvey: string;
  glove_type: string;
}

interface GloveEditType {
  description: string;
  glove_number: string;
  lastsurvey: string;
  nextsurvey: string;
  glove_type: string;
  refid: number;
}

export interface OptionType {
  label: string;
  value: number;
}

class Glove {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  glovesList: GloveType[] = [];
  glovesTotal: number = 0;
  glove: GloveEditType | undefined = undefined;
  gloveForDelete: number = 0;
  options: OptionType[] = [];

  filter: FilterNameAndState = new FilterNameAndState();
  page: Pagination = new Pagination({ id: 'glove_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      glovesList: observable,
      glovesTotal: observable,
      glove: observable,
      gloveForDelete: observable,
      filter: observable,
      options: observable,

      setFetching: action,
      setGlovesList: action,
      setGlovesTotal: action,
      setGlove: action,
      setGloveForDelete: action,
      clearGloveForDelete: action.bound,
      clearGlove: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setGlovesList(list: GloveType[]) {
    this.glovesList = list;
  }

  setGlovesTotal(count: number) {
    this.glovesTotal = count;
  }

  setGlove(glove?: GloveEditType) {
    this.glove = glove;
  }

  setGloveForDelete(id: number) {
    this.gloveForDelete = id;
  }

  clearGloveForDelete() {
    this.gloveForDelete = 0;
  }

  clearGlove() {
    this.glove = undefined;
  }

  async getGlovesList({
    pagination,
    filter,
  }: {
    pagination: TPagination;
    filter: FilterType;
  }) {
    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.name,
      filter.state,
    ];

    try {
      const response = await apiRequest<'SE' | GloveType[]>({
        url: 'generalmaster.GloveMaster.GetGloveList',
        data,
      });
      if (Array.isArray(response)) {
        const gloves = response.map((pc) => ({
          ...pc,
          refid: Number(pc.refid),
        }));
        this.setGlovesList(gloves);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setGlovesList([]);
    }
  }

  async getGlovesCount(filter: FilterType) {
    try {
      const count = await apiRequest<'SE' | 'S' | number>({
        url: 'generalmaster.GloveMaster.GloveCount',
        data: [filter.name, filter.state],
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setGlovesTotal(total);
    } catch (e) {
      this.setGlovesTotal(0);
    }
  }

  getGlovesListMain = () => {
    this.setFetching(true);

    const props = {
      pagination: this.page.pagination,
      filter: this.filter.options,
    };

    const promiseList = this.getGlovesList(props);

    const promiseCount = this.getGlovesCount(props.filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getGlove(id: number | string) {
    this.setFetching(true);

    try {
      const response = await apiRequest<'SE' | [GloveResponseType]>({
        url: 'generalmaster.GloveMaster.GetGlove',
        data: [id],
      });
      if (Array.isArray(response)) {
        const glove: GloveEditType = {
          ...response[0],
          refid: Number(id),
        };

        this.setGlove(glove);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setGlove();
    } finally {
      this.setFetching(false);
    }
  }

  async addGlove(payload: Omit<GloveEditType, 'refid'>) {
    const data = [
      payload.glove_number,
      payload.glove_type,
      payload.nextsurvey,
      payload.lastsurvey,
      payload.description,
    ];

    try {
      const result = await apiRequest<'S' | 'SE'>({
        url: 'generalmaster.GloveMaster.AddGlove',
        data,
      });
      if (result === 'S') {
        Notification.success('Glove was added successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async updateGlove(payload: GloveEditType) {
    const data = [
      payload.refid,
      payload.glove_number,
      payload.glove_type,
      payload.nextsurvey,
      payload.lastsurvey,
      payload.description,
    ];

    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.GloveMaster.EditGlove',
        data,
      });

      if (result === 'S') {
        Notification.success('Glove was updated successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  changeGloveStatus = async (ids: number[], state: string) => {
    this.setFetching(true);
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'generalmaster.GloveMaster.changeStatus',
        data: [ids],
      });
      if (response === 'S') {
        const { length } = ids;

        Notification.success(
          `${length} Glove${length > 1 ? 's were' : 'was'} ${state}`
        );
        return true;
      } else Error('');
    } catch (e) {
      Notification.warning('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  };

  async deleteGlove() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.GloveMaster.DeleteGlove',
        data: [this.gloveForDelete],
      });
      if (result === 'S') {
        Notification.success('Glove was deleted!');
        this.clearGloveForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    runInAction(() => {
      this.fetchingOptions = true;
    });
    try {
      const options = await apiRequest<OptionType[]>({
        url: 'vehicle.EquipmentMaster.LeftGloveDropDown',
      });

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storeGlove = new Glove();
