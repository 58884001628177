import { makeObservable, observable, action, computed } from 'mobx';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { getDateRangeBounds, timeZoneRender } from 'utils/DateUtils';

const defaultFilterValue: FilterType = {
  dosStart: '',
  dosEnd: '',
  period: 'A',
  firstName: '',
  lastName: '',
  facilityId: 0,
  technologistId: 0,
  timeZone: '',
};

export interface FilterType {
  dosStart: string;
  dosEnd: string;
  period: string;
  facilityId: number;
  firstName: string;
  lastName: string;
  technologistId: number;
  timeZone: string;
}

interface LogResponseType {
  complete_dt: string;
  dispatched_dt: string;
  elapsed_time: string;
  facility_nm: string;
  patient_firstnm: string;
  patient_lastnm: string;
  proc_cnt: number;
  service_dt: string;
  tech_firstname: string;
  tech_lastname: string;
}

interface LogType extends LogResponseType {
  technologistName: string;
}

class EncounterDuration {
  fetching: boolean = false;
  logList: LogType[] = [];
  logTotal: number = 0;
  filter: FilterType = defaultFilterValue;

  page: Pagination = new Pagination({ id: 'tech_patients_gridId' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      logList: observable,
      logTotal: observable,
      filter: observable,

      logs: computed,

      setFetching: action,
      setTotal: action,
      setLogs: action,
      setTimeZone: action.bound,
      setFilter: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFilter(payload: FilterType) {
    this.filter = payload;
  }

  setTotal(count: number) {
    this.logTotal = count;
  }

  setLogs(logs: LogType[]) {
    this.logList = logs;
  }

  setTimeZone(timeZone: string) {
    this.filter.timeZone = timeZone;
  }

  setDefaultFilter() {
    const filter = {
      ...this.filter,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facilityId,
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const filter = {
      ...defaultFilterValue,
      facilityId: UserProfileStore.getFacilityId() || 0,
    };

    return filter;
  }

  get logs() {
    const {
      filter: { timeZone },
      logList,
    } = this;
    const result = logList.map((log) => ({
      ...log,
      service_dt: timeZoneRender(log.service_dt, timeZone, true),
      complete_dt: timeZoneRender(log.complete_dt, timeZone),
      dispatched_dt: timeZoneRender(log.dispatched_dt, timeZone),
    }));

    return result;
  }

  async getLogCount(data: Record<string, string | number>) {
    try {
      const count = await apiRequest<string>({
        url: 'report.TechnicianExam.GetTechpatientdetailcount',
        data,
      });

      this.setTotal(Number(count) || 0);
    } catch (e: any) {
      this.setTotal(0);
    }
  }

  async getLog(data: Record<string, string | number>) {
    try {
      const response = await apiRequest<LogResponseType[]>({
        url: 'report.TechnicianExam.GetTechpatientdetail',
        data,
      });

      const logs = response.map((el) => ({
        ...el,
        technologistName: `${el.tech_firstname} ${el.tech_lastname}`.trim(),
      }));

      this.setLogs(logs);
    } catch (e: any) {
      this.setLogs([]);
    }
  }

  getLogsMain = () => {
    const {
      filter,
      page: { pagination },
    } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const payload = {
      technologist: filter.technologistId || null,
      dosFrom: dos.dateFrom,
      dosTo: dos.dateTo,
      facility: filter.facilityId || null,
      lastName: filter.lastName,
      firstName: filter.firstName,
    };

    this.setFetching(true);

    const promiseCount = this.getLogCount(payload);

    const promiseLogs = this.getLog({
      skip: pagination.skip,
      pageSize: pagination.pageSize,
      ...payload,
    });

    Promise.all([promiseCount, promiseLogs]).finally(() => {
      this.setFetching(false);
    });
  };

  exportToPdf = async ({ filter }: { filter: string }) => {
    this.setFetching(true);
    try {
      const fileName = await apiRequest<string>({
        url: 'pdfcreater.reportPdfGenerator.techpatientdetailpdf',
        data: [this.logList, filter],
      });
      return fileName.replace(/^\//, '');
    } catch (e: any) {
      return '';
    } finally {
      this.setFetching(false);
    }
  };

  prepareFilterForExport() {
    const { filter } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return {
      ...filter,
      facility: filter.facilityId || null,
      techId: filter.technologistId || null,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
    };
  }
}

export const storeEncounterDuration = new EncounterDuration();
