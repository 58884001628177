import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Textarea } from 'components/form/textarea';
import { Input } from 'components/form/textField';

import resolver from './validation';

export interface FormModel {
  payor_desc: string;
  payortype_nm: string;
  refid?: number;
}

const initialValues: FormModel = {
  payor_desc: '',
  payortype_nm: '',
};

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormPayerType = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const handleReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form className="col-lg-8" onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="payortype_nm"
        label="Payer type"
        required
        control={control}
      />
      <Textarea name="payor_desc" label="Description" control={control} />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormPayerType;
