import { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import useFluxStore from 'flux-hooks';

import UserProfileStore from 'stores/UserProfileStore';
import { checkSession } from 'services/SessionMonitorService';
import { TIMER_DELAY_CHECKING_MONITOR } from 'constant/timerDelayCheckingMonitor';
import { URL_LOGIN, URL_LOGOUT } from 'constant/path/auth';

let timer: NodeJS.Timeout | null = null;

const SessionMonitor = (): null => {
  const prevUserStatus = useRef<boolean>(false);

  const history = useHistory();

  const { loggedIn } = useFluxStore(
    UserProfileStore,
    (prevState: any, store: any) => store.getState()
  );

  const setCheckingInterval = () => {
    clearInterval(timer);

    timer = setTimeout(fetchSessionStatus, TIMER_DELAY_CHECKING_MONITOR);
  };

  const fetchSessionStatus = () => {
    checkSession().then((status: boolean) => {
      if (status) {
        setCheckingInterval();
      } else {
        runLogoutProcess();
      }
    });
  };

  const runLogoutProcess = () => {
    clearTimeout(timer);
    logoutUser();
  };

  const logoutUser = () => {
    const { pathname } = window.location;

    const searchParams =
      pathname !== URL_LOGIN && pathname !== URL_LOGOUT
        ? `?backUrl=${encodeURIComponent(pathname)}`
        : '';

    history.push({
      pathname: URL_LOGOUT,
      search: searchParams,
    });
  };

  useEffect(() => {
    if (loggedIn) {
      setCheckingInterval();
    } else if (prevUserStatus.current && !loggedIn) {
      runLogoutProcess();
    }

    prevUserStatus.current = loggedIn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return null;
};

export default SessionMonitor;
