export const ORDER_MAIN = {
  PAGE: 'ordermaster',
};

export const ORDER_PLACE = {
  PAGE: 'new_order',
};

export const ORDER_UNASSIGNED = {
  PAGE: 'dispatch',
};

export const ORDER_ASSIGNED = {
  PAGE: 'assigned_exams',
};

export const ORDER_REPORT_MNG = {
  PAGE: 'incomplete_order',
};

export const ORDER_CALLBACK_MNG = {
  PAGE: 'completed_order',
};

export const ORDER_VISIT_SCHEDULER = {
  PAGE: 'dailyworklist',
};

export const ORDER_REVIEW = {
  PAGE: 'examreview',
};

export const TRANSCRIPTION = {
  ADD: 'trans_add',
  EDIT: 'trans_edit',
  DELETE: 'trans_delete',
  ADD_FAX: 'add_fax',
};

export const FAX = {
  PAGE: 'faxmaster',
};

export const CALL_LOG = {
  PAGE: 'call_log',
};

export const CHECK_OUT = {
  PAGE: 'EquipmentMaster',
};

export const CHECK_OUT_HISTORY = {
  PAGE: 'VehicleHistory',
};

export const CLOCK_IN = {
  PAGE: 'EmployeeClockIn',
};

// EI - Equipment Inventory

export const EI = {
  PAGE: 'InventoryValue',
};

export const EI_BONE_SONOMETER = {
  PAGE: 'Bonesonometer',
};

export const EI_COMPUTER = {
  PAGE: 'ComputerMaster',
};

export const EI_DR_PLATE = {
  PAGE: 'PlateMaster',
};

export const EI_EKG = {
  PAGE: 'EkgMaster',
};

export const EI_GENERATOR = {
  PAGE: 'GeneratorMaster',
};

export const EI_GLOVE = {
  PAGE: 'GloveMaster',
};

export const EI_LEAD = {
  PAGE: 'LeadMaster',
};

export const EI_OTHER = {
  PAGE: 'OtherEquipment',
};

export const EI_PHONES = {
  PAGE: 'PhoneMaster',
};

export const EI_PROBE = {
  PAGE: 'ProbeMaster',
};

export const EI_THYROID_SHIELD = {
  PAGE: 'ThyroidMaster',
};

export const EI_ULTRASOUND = {
  PAGE: 'DeviceMaster',
};

export const EI_VEHICLE = {
  PAGE: 'VehicleMaster',
};

export const EI_XRAY_FRAME = {
  PAGE: 'XrayMaster',
};

export const EI_UNIT_ULTRASOUND = {
  PAGE: 'UltraSoundGroup',
};

export const EI_UNIT_XRAY = {
  PAGE: 'XrayGroup',
};

export const TASK_MANAGER = {
  PAGE: 'VehicleServiceArea',
};

export const SERVICE_HISTORY = {
  PAGE: 'ServiceHistory',
};

export const EQUIPMENT_MAINTENANCE = {
  PAGE: 'EquipmentMaintenance',
};

export const REJECT_REASON = {
  PAGE: 'RejectReasonReport',
};

export const EMAIL_REPORT = {
  PAGE: 'EmailReport',
};
