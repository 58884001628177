import AbstractLikeFilter from './AbstractLikeFilter';

export default class TechnicianFirstNameFilter extends AbstractLikeFilter {
  name = 'technicianFirstName';
  field = 'Technician First Name';
  field1 = AbstractLikeFilter.getInsecureSecureField(
    'ppxray_staffinfo.first_name'
  );
  className = 'technician-first-name-filter';
}
