import Tabs from 'components/tabs';
import FacilityTab from './documentAudit/FacilityTab';
import PhysicianTab from './documentAudit/PhysicianTab';

const tabsList = [
  { label: 'Facility', url: '?subtub=facility' },
  { label: 'Physician', url: '?subtub=physician' },
];

interface PropsType {
  isFilterVisible: boolean;
  toggleFilterButton: (isVisible: boolean) => void;
}

const DocumentAuditTab = ({
  isFilterVisible,
  toggleFilterButton,
}: PropsType) => (
  <div className="document-audit-tab pt-4">
    <Tabs tabsList={tabsList}>
      <FacilityTab
        isFilterVisible={isFilterVisible}
        toggleFilterButton={toggleFilterButton}
      />
      <PhysicianTab
        isFilterVisible={isFilterVisible}
        toggleFilterButton={toggleFilterButton}
      />
    </Tabs>
  </div>
);

export default DocumentAuditTab;
