import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropDownJurisdiction } from 'components/project/dropdown/JurisdictionDropdown';
import { DropdownTaxonomyCode } from 'components/project/dropdown/TaxonomyCodeDropdown';
import { ExamExportLink } from 'page/components/ExportLink';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import MaskInput from 'components/form/maskInput';
import DialogConfirm from 'components/modal/dialogConfirm';
import ClaimBasicInfo, { OrderDetailType } from './ClaimBasicInfo';
import validation from './validation';

import { storeDropdownPlaceOfService } from 'stores/_mobx/dropDown/placeOfService';
import {
  storeClaimAddressDetails,
  ClaimAddressDetailsType,
} from 'stores/_mobx/billingCodding/claimAddressDetails';
import { URL_USPS } from 'constant/externalLinks';

interface PropsType {
  title: string;
  facilityOnly?: boolean;
  isPCInstance: boolean;
  claimAndFacility: boolean;
  order: OrderDetailType;
  onClose: () => void;
  onSubmit: () => void;
}

type SubmittingType = 'claim' | 'claimAndFacility' | '';

const DialogFormClaimAddressDetails = ({
  title,
  order,
  facilityOnly = false,
  isPCInstance,
  claimAndFacility,
  onClose,
  onSubmit,
}: PropsType) => {
  const { fetching, claimDetails } = storeClaimAddressDetails;

  const isRadiologistFieldVisible =
    isPCInstance && !claimDetails?.isInJurisdiction;

  const {
    control,
    formState: { isSubmitting, isDirty },
    reset,
    handleSubmit,
  } = useForm<ClaimAddressDetailsType>({
    resolver: validation(isRadiologistFieldVisible),
  });

  const [submittingType, toggleConfirm] = useState<SubmittingType>('');

  const isLoading = fetching || isSubmitting;

  const posName = useMemo(
    () =>
      isPCInstance
        ? storeDropdownPlaceOfService.findOption(Number(order.pos_override))
            ?.label || order.pos
        : order.pos,
    [isPCInstance, order]
  );

  const handleClickSubmit = (type: SubmittingType) => () => {
    if (facilityOnly && claimDetails?.isHomeCare) {
      toggleConfirm(type);
      return Promise.resolve();
    }
    return type === 'claim'
      ? handleClickClaimSubmit()
      : handleClickClaimAndFacilitySubmit();
  };

  const handleClickClaimSubmit = () => {
    handleSubmit((data) =>
      storeClaimAddressDetails.addOrderClaim(data).then((isSucceed) => {
        if (isSucceed) onSubmit();
      })
    )();
  };

  const handleClickClaimAndFacilitySubmit = () => {
    handleSubmit((data) =>
      storeClaimAddressDetails.addOrderClaimFacility(data).then((isSucceed) => {
        if (isSucceed) onSubmit();
      })
    )();
  };

  const handleCloseConfirm = () => {
    toggleConfirm('');
  };

  const handleApproveConfirm = () => {
    if (submittingType === 'claim') {
      handleClickClaimSubmit();
    } else if (submittingType === 'claimAndFacility') {
      handleClickClaimAndFacilitySubmit();
    }
    toggleConfirm('');
  };

  const handleClickReset = () => {
    reset(claimDetails);
  };

  useEffect(() => {
    reset(claimDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimDetails]);

  useEffect(() => {
    const payload = {
      orderId: Number(order.refid),
      facilityId: Number(order.facilityid),
      facilityOnly,
      isPc: isPCInstance,
    };

    storeClaimAddressDetails.getClaimDetails(payload);

    return storeClaimAddressDetails.clearClaimDetails;
  }, [order, isPCInstance, facilityOnly]);

  return (
    <>
      <Dialog size="large" handleClose={onClose}>
        <DialogHeader title={title} onClose={onClose} />
        <DialogBody className={isLoading ? 'on-ajax' : ''}>
          <ClaimBasicInfo order={order} posName={posName} />

          <form className="row">
            <DropDownPlaceOfService
              name="posId"
              label="POS"
              className="col-md-6"
              control={control}
            />

            <hr />

            {isRadiologistFieldVisible && !isLoading && (
              <>
                <div className="col-sm-12 mb-3">
                  {claimDetails?.isInJurisdiction
                    ? 'In Jurisdiction Locality: '
                    : 'Out of Jurisdiction Locality: '}
                  <span
                    className={
                      claimDetails?.isInJurisdiction
                        ? 'text-success'
                        : 'text-danger'
                    }>
                    {claimDetails?.isInJurisdiction
                      ? 'Facility'
                      : 'Rendering MD'}
                  </span>
                </div>

                <hr />

                <Input
                  name="radiologist.lastName"
                  label="Last Name"
                  className="col-md-6"
                  required
                  control={control}
                />
                <Input
                  name="radiologist.firstName"
                  label="First Name"
                  className="col-md-6"
                  required
                  control={control}
                />
              </>
            )}
            <Input
              name="address"
              label="Address"
              className="col-md-6"
              required
              control={control}
            />
            <Input
              name="state"
              label="State"
              className="col-md-6"
              required
              control={control}
            />
            <Input
              name="city"
              label="City"
              className="col-md-6"
              required
              control={control}
            />
            <div className="col-md-6 position-relative d-flex">
              <MaskInput
                name="zipcode"
                label="Zip Code"
                format="#####-####"
                className="flex-grow-1"
                required
                control={control}
              />
              <a
                href={URL_USPS}
                className="mt-5 ms-3"
                target="_blank"
                rel="noreferrer">
                <img alt="logo" src="assets/images/zip-logo.png" />
              </a>
            </div>
            {isRadiologistFieldVisible ? (
              <>
                <Input
                  name="radiologist.npi"
                  className="col-md-6"
                  maxLength={10}
                  control={control}
                />
                <DropdownTaxonomyCode
                  name="radiologist.taxonomyCode"
                  className="col-md-6"
                  control={control}
                />
              </>
            ) : (
              <DropDownJurisdiction
                name="billId"
                label="Billing Jurisdiction"
                className="col-md-6"
                control={control}
              />
            )}
            <div className="col-md-6">
              <div className="fw-bold">Exam Requisition</div>
              <ExamExportLink orderId={order.refid || order.Studyid} />
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            text="Claim"
            disabled={isLoading || !isDirty}
            onClick={handleClickSubmit('claim')}
          />
          {claimAndFacility && (
            <Button
              text="Claim and Facility"
              disabled={isLoading || !isDirty}
              onClick={handleClickSubmit('claimAndFacility')}
            />
          )}
          <Button
            text="Reset Address"
            variant="warning"
            disabled={isLoading}
            onClick={handleClickReset}
          />
        </DialogFooter>
      </Dialog>

      {submittingType ? (
        <DialogConfirm
          onCancel={handleCloseConfirm}
          onApprove={handleApproveConfirm}>
          Selected order have type 'HOME CARE', but this popup contain facility
          data.
          <br />
          Submit can rewrite original place of service information.
          <br />
          Are you sure to process?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(DialogFormClaimAddressDetails);
