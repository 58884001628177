import React from 'react';
import { useParams } from 'react-router-dom';
import { Control, useController } from 'react-hook-form';

import FileUpload from 'components/form/input/FileUpload';
import Fieldset from 'components/form/Fieldset';
import { Grid } from 'components/grid';
import Text from 'components/form/input/Text';
import { IconButton } from 'components/button';
import { BASE_URL_FILE_DIR } from 'constant/config';

interface PMultipleFileUpload {
  id?: string;
  value: any[];
  dir: string;
  refid: string;
  filePath?: string;
  onSetValue: (arg: any[]) => void;
  action?: string;
}

interface State {
  documentList: any[];
}

interface Column {
  name: string;
  title: string;
  className?: string;
  render?: (rowItem: string, row: string) => React.ReactElement;
}

export default class MultipleFileUpload extends React.Component<
  PMultipleFileUpload,
  State
> {
  id: string;

  constructor(props: PMultipleFileUpload) {
    super(props);
    this.state = {
      documentList: this.props.value || [],
    };

    this.id = props.id || window.performance.now().toString(36);
  }

  setDocumentList(documentList: any[], callback?: () => void) {
    this.setState({ documentList }, callback);
  }

  getFilePath(file: any): string {
    const { dir, refid } = this.props;

    if (file.filestatus === 'N') {
      return `${BASE_URL_FILE_DIR}tempfolder/${file.filenm}`;
    }

    return `${BASE_URL_FILE_DIR}doc_img/${dir}/${refid}-${file.filenm}`;
  }

  getColumns(): Column[] {
    const textFieldCallback = (row: any, name: string, value: string) => {
      row[name] = value;
      this.forceUpdate();
    };

    return [
      {
        name: 'filenm',
        title: 'File Name',
      },
      {
        name: 'desc',
        title: 'Description',
        render: (rowItem: string, row: string) => {
          return (
            <Text
              name="desc"
              noLabel
              value={rowItem}
              onSetValue={(name: string, value: string) => {
                textFieldCallback(row, name, value);
              }}
            />
          );
        },
      },
      {
        name: 'view',
        title: 'View',
        className: 'text-center',
        render: (rowItem: string, row: any) => {
          if (!row.filenm) {
            return null;
          }
          const path = this.props.filePath
            ? this.props.filePath
            : this.getFilePath(row);
          const isPdfFile = /.*\.pdf$/.test(path);
          const attributes = isPdfFile ? {} : { download: row.filenm };

          return (
            <a
              title={rowItem}
              href={path}
              rel="noreferrer"
              target="_blank"
              {...attributes}>
              <i className="icon icon-view" />
            </a>
          );
        },
      },
      {
        name: 'filenm',
        title: 'Remove',
        className: 'text-center',
        render: (filenm: string, row: any) => (
          <IconButton
            title={`Delete ${filenm}`}
            className="text-primary"
            onClick={() => {
              const list = Array.isArray(this.props.value)
                ? this.props.value
                : this.state.documentList;

              const documentList = list.filter((item) => item !== row);

              this.setDocumentList(documentList, () =>
                this.props.onSetValue(documentList)
              );
            }}>
            <i className="bi bi-trash" />
          </IconButton>
        ),
      },
    ];
  }

  updateDocumentPath(name: string, value: string): void {
    const list = Array.isArray(this.props.value)
      ? this.props.value
      : this.state.documentList;

    const documentList: any[] = list.concat({
      filenm: value,
      desc: '',
      filestatus: 'N',
    });

    this.setDocumentList(documentList, () =>
      this.props.onSetValue(documentList)
    );
  }

  render() {
    const { value, action } = this.props;
    return (
      <div>
        <FileUpload
          onSetValue={this.updateDocumentPath.bind(this)}
          buttonLabel="Upload File"
          action={action}
          noLabel
        />

        <Fieldset>
          <Grid
            columns={this.getColumns()}
            dataSource={value}
            disablePagination
            noControl
          />
        </Fieldset>
      </div>
    );
  }
}

interface PropsType
  extends Omit<PMultipleFileUpload, 'refid' | 'value' | 'onSetValue'> {
  name: string;
  control: Control<any>;
}

export const MultipleFileUploadControl = ({
  control,
  name,
  ...rest
}: PropsType) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    defaultValue: [],
  });

  const { id } = useParams<{ id: string }>();

  return (
    <MultipleFileUpload
      {...rest}
      refid={id}
      value={value}
      onSetValue={onChange}
    />
  );
};
