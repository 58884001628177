import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from 'flux/utils';

import CPTCodeModal from 'page/workflow/order/order/CPTCodeModal';
import DialogIcdCode from 'page/components/DialogIcdCode';
import { Button, IconButton } from 'components/button';
import Notification from 'components/modal/Notification';
import { SpinnerFixed } from 'components/spinner';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Grid, GridControlButton } from 'components/grid';
import Form from 'components/form/Form';
import Fieldset from 'components/form/Fieldset';
import Custom from 'components/form/input/Custom';
import Radio from 'components/form/input/Radio';
import Checkbox from 'components/form/input/Checkbox';
import { PureInput } from 'components/form/textField';
import { PureSelect } from 'components/form/select';
import { PureCheckbox } from 'components/form/checkbox';
import { ControlsLayout } from 'components/layout';
import { ExamExportLink } from 'page/components/ExportLink';
import Tooltip from 'components/tooltip';
import DialogConfirm from 'components/modal/dialogConfirm';
import FindingPopup from './FindingPopup';

import CodingActions from 'actions/billing-coding/CodingActions';
import VisitNotesActions from 'actions/workflow/order/VisitNotesActions';
import { InitCptType } from 'services/billing-coding/CodingService';
import ModifierActions from 'actions/project/ModifierActions';
import ModifierStore from 'stores/project/ModifierStore';

import MathUtils from 'utils/MathUtils';
import { dateToLocalTimezone } from 'utils/DateUtils';
import AccessUtils from 'utils/AccessUtils';
import IterableUtils from 'utils/IterableUtils';
import { isQrCpt } from 'utils/cptCode';
import { BASE_URL_FILE_DIR } from 'constant/config';
import { URL_CODING } from 'constant/path/billingCoding';
import { URL_NPPES_NPI } from 'constant/externalLinks';

const BILL_OPTS = [
  { value: 'F', label: 'Facility', disabled: true },
  { value: 'R', label: 'Correctional', disabled: true },
  { value: 'B', label: 'Non-Billable', disabled: true },
  { value: 'C', label: 'Occ. Health care', disabled: true },
  { value: 'S', label: 'Self Pay', disabled: true },
  { value: 'I', label: 'Insurance', disabled: true },
  { value: 'H', label: 'Hospice', disabled: true },
  { value: 'O', label: 'Others', disabled: true },
];

const GREEN = 0;
const ORANGE = 1;
const GRAY = 2;

const ASSIGNED_OPTIONS = [
  { value: 'Y', label: 'Assigned', disabled: true },
  { value: 'N', label: 'Not Assigned', disabled: true },
];

const className = 'col-md-4';
const billingClassName = 'part-inline';

const buildCptClass = (type: any) => {
  switch (type) {
    case ORANGE:
      return 'bi bi-check-circle-fill text-warning';
    case GRAY:
      return 'bi bi-check-circle-fill text-secondary';
    default:
      return 'bi bi-check-circle-fill text-primary';
  }
};

interface PCptDetails {
  fetching: boolean;
  orderId: number;
  history: RouteComponentProps['history'];
  order: { GetIntCptArr: InitCptType; GetFnlCptArr: Array<any> } | null;
}

class TCptDetailsModel {
  refid: number = null;
  npi: string = '';
  same_visits: boolean = false;
  GetIntCptArr: InitCptType = [];
  GetIntIcdArr: Array<any> = [];
  GetFnlCptArr: Array<any> = [];
  examtype: string = '';
  noofpatient: string = '';
  patient_name: string = '';
  facilitynm: string = '';
  sheduleservdate: string = '';
  modality: any = null;
  exambody: string = '';
  examtypeid: number = null;
  smoking: 'Y' | 'N' = 'N';
  symptomatic: 'Y' | 'N' = 'N';
  isfinding: string = '';
  facilityid: string = '';
  providerid: string = '';
  dateofbirth: string = '';
  physicianname: string = '';
  daily_encounter: any = null;
  Reports: any = null;
  exam_symptoms: any = null;
  completion_time: string = '';
}

class SCptDetails {
  model: TCptDetailsModel = new TCptDetailsModel();
  errors: any = {};
  initialCptPriceRefidInFocus: string = '';
  collapsedPatient: boolean = false;
  modifiers: any = {};
  finalModifiers: any = {};
  findingsDataSource: Array<any> = [];
  showFindingsPopup: boolean = false;
  isCptCodeModalOpen: boolean = false;
  isIcdCodeModalOpen: boolean = false;
  isFindingSet: boolean = false;
  showNoFindings: boolean = false;
  lastSubmit: boolean = false;
  zeroPriceConfirm: boolean = false;
  currentCpt: any[] = [];
  modifierOption: Array<{ label: string; value: number }> = [];
}

class CptDetails extends React.Component<PCptDetails, SCptDetails> {
  static Q_R_REGEXP = /^([QR]).*/;
  static alphavalue = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  static cptWithAccession(cpt: any, data: InitCptType['0']) {
    const title =
      // @ts-ignore
      data.external_accession_number ||
      // @ts-ignore
      data.accession_number ||
      (data as any).session_no;

    return (
      <Tooltip body={title}>
        <strong>{cpt}</strong>
      </Tooltip>
    );
  }

  static getStores(): Array<any> {
    return [ModifierStore];
  }

  static calculateState(prevState = new SCptDetails(), props: any) {
    return {
      ...prevState,
      model: props.order,
      modifierOption: ModifierStore.getState().modifierList,
    };
  }

  refError = React.createRef<HTMLDivElement>();

  initialCptColumns = [
    {
      name: 'cpt_code_val',
      title: 'CPT Code',
      render: (cpt: any, data: InitCptType['0']) =>
        CptDetails.cptWithAccession(cpt, data),
    },
    {
      name: 'modifier_code',
      title: 'Modifier',
      render: (modifierCode: string, data: InitCptType['0']) => {
        const { same_visits: isSameVisit } = this.state.model;

        const value = modifierCode ? modifierCode.split(',').map(Number) : '';

        return (
          <div className="d-flex align-items-center gap-3">
            {isSameVisit ? (
              <Tooltip body="Separate/distinct modifier needed">
                <i className="bi bi-exclamation-triangle-fill text-danger" />
              </Tooltip>
            ) : null}
            <PureSelect
              name={`modifier_code_${data.refid}_${data.cpt_code_val}`}
              className="width-150"
              isMulti
              value={value}
              options={this.state.modifierOption}
              onChange={(value: number[]) => {
                this.updateCptModifier(data, value);
              }}
            />
          </div>
        );
      },
      noOverflow: true,
    },
    { name: 'cpt_desc', title: 'Description' },
    {
      name: 'cpt_price',
      title: 'Price ($)',
      className: 'text-center',
      render: (price: number | string, row: InitCptType['0']) => {
        return AccessUtils.checkAccess('modify_coding_price')
          ? this.priceEditor(price, row)
          : Number(price).toFixed(2);
      },
    },
    {
      name: 'cptcount',
      title: 'Unit(s)',
      className: 'text-center',
    },
    {
      name: 'control',
      title: 'Apply',
      className: 'width-75 text-center',
      render: (_: undefined, data: InitCptType['0']) =>
        this.acceptInitialCptRender(data),
    },
  ];

  priceEditor(value: number | string, row: InitCptType['0']) {
    const { state } = this;

    if (row.refid === state.initialCptPriceRefidInFocus) {
      return (
        <PureInput
          autoFocus
          className="width-75 mx-auto"
          name="cpt_price"
          type="number"
          isClearable={false}
          onBlur={() => {
            this.updateTableCell(
              row.refid,
              'cpt_price',
              Number(value).toFixed(2)
            );
            this.setState({ initialCptPriceRefidInFocus: '' });
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              this.updateTableCell(
                row.refid,
                'cpt_price',
                Number(value).toFixed(2)
              );
              this.setState({ initialCptPriceRefidInFocus: '' });
            }
          }}
          value={value}
          onChange={(value) => {
            this.updateTableCell(row.refid, 'cpt_price', value);
          }}
        />
      );
    }
    return (
      <a
        href="/"
        className="d-inline-block width-75"
        onClick={(e) => {
          e.preventDefault();
          this.setState({ initialCptPriceRefidInFocus: row.refid });
        }}>
        <u>{Number(value).toFixed(2)}</u>
        <i className="bi bi-pencil ms-3" />
      </a>
    );
  }

  recalculateQ(cptList: any) {
    cptList = cptList || [];
    const qCptList = cptList.filter(
      (item: any) => item.cpt_code_val && item.cpt_code_val.startsWith('Q')
    );
    if (qCptList.length === 0) {
      return cptList;
    }
    const rCptList = cptList.filter(
      (item: any) => item.cpt_code_val && item.cpt_code_val.startsWith('R')
    );
    const serviceCptList = cptList.filter(
      (item: any) => item.cpt_code_val && !isQrCpt(item.cpt_code_val)
    );

    const qBatchSize = 4;
    const singlePrice = qCptList[0].single_price;

    const sum = serviceCptList.reduce(
      (sum: any, item: any) => sum + item.cptcount,
      0
    );
    const qSum = qCptList.reduce(
      (sum: any, item: any) => sum + item.cptcount,
      0
    );
    const delta = sum - qSum;

    let count = 0;
    while (count < delta) {
      let index = qCptList.length > 0 ? qCptList.length - 1 : 0;
      if (qCptList[index] && qCptList[index].cptcount < qBatchSize) {
        qCptList[index].cptcount++;
        qCptList[index].cpt_price = MathUtils.parseFloat(
          singlePrice * qCptList[index].cptcount,
          2
        );
      } else {
        qCptList.push({
          cpt_code: 'Q0092',
          cpt_code_val: 'Q0092',
          cptcount: 1,
          cpt_desc: 'Equipment Setup',
          cpt_price: Number(singlePrice).toFixed(2),
          single_price: singlePrice,
          modifier_code: '',
        });
      }
      count++;
    }

    return serviceCptList.concat(qCptList, rCptList);
  }

  checkAllIndicatorForLetter = (character: string) => (isChecked: boolean) => {
    const { GetFnlCptArr: cptValues } = this.state.model;

    const GetFnlCptArr = cptValues.map((code) => ({
      ...code,
      [`dgc${character}selstat`]: isChecked,
      [`dgcIcdAlpha${character}`]: isChecked ? character : '',
    }));

    const model = {
      ...this.state.model,
      GetFnlCptArr,
    };

    this.setState({ model });
  };

  initialIcdColumns = [
    { name: 'icd_code', title: 'ICD Code' },
    { name: 'short_description', title: 'Description' },
    {
      name: 'selicd_status',
      title: 'Accept',
      className: 'text-center',
      render: (isChecked: boolean, data: any) => (
        <PureCheckbox
          name="selicd_status"
          checked={isChecked}
          onChange={(isChecked) => {
            this.toggleCodeState({
              refid: data.refid,
              isChecked,
              letter: data.alphaButton,
            });
          }}
        />
      ),
    },
    {
      name: 'alphavalue',
      title: 'Code Indicator',
      render: (v: any, data: any) => this.codeIndicator(data),
      noOverflow: true,
    },
  ];

  getFinalCptColumns(initialIcd: any[]) {
    const { GetFnlCptArr: cptValues } = this.state.model;

    const filteredAlphaColumns = initialIcd.filter(
      (icd) => icd.selicd_status && icd.alphaButton
    );

    const availableCharacters: string[] = filteredAlphaColumns.map(
      ({ alphaButton }) => alphaButton
    );

    const allCheckboxStatus = availableCharacters.reduce((prev, key) => {
      const isAllChecked = cptValues.length
        ? cptValues.every((icd) => icd[`dgc${key}selstat`])
        : false;

      return {
        ...prev,
        [key]: isAllChecked,
      };
    }, {});

    const out = [
      {
        name: 'cpt_code',
        title: 'CPT Code',
        className: 'width-100',
        render: (cpt: any, row: InitCptType['0']) =>
          CptDetails.cptWithAccession(cpt, row),
      },
      {
        name: 'modifier_code',
        title: 'Modifier',
        render: (modifierCode: string, data: InitCptType['0']) => {
          const value = modifierCode
            ? modifierCode.split(',').map((code: string) => Number(code))
            : [];
          return (
            <PureSelect
              name={'final_modifier_code_' + data.refid}
              isMulti
              value={value}
              options={this.state.modifierOption}
              onChange={(value: number[]) => {
                this.updateFinalCptModifier(data, value);
              }}
            />
          );
        },
      },
      {
        name: 'cpt_price',
        title: 'Price ($)',
        className: 'text-center width-100',
        render: (cptPrice: string) => (Number(cptPrice) || 0).toFixed(2),
      },
      {
        name: 'cptcount',
        title: 'Unit(s)',
        className: 'text-center width-100',
      },
      { name: 'cpt_description', title: 'Description' },
    ];

    const alphaColumns = filteredAlphaColumns
      .map((icd) => ({
        name: icd.icd_code,
        className: 'width-150',
        noSort: true,
        title: (
          <PureCheckbox
            name="fax"
            label={`${icd.icd_code} (${icd.alphaButton})`}
            // @ts-ignore
            checked={allCheckboxStatus[icd.alphaButton] || false}
            onChange={this.checkAllIndicatorForLetter(icd.alphaButton)}
          />
        ),

        alphaButton: icd.alphaButton || '',
        render: (_: any, data: any) => this.buildFnlCptIcd(data, icd),
      }))
      .sort((a, b) => a.alphaButton.localeCompare(b?.alphaButton));

    // @ts-ignore
    return out.concat(alphaColumns, {
      name: 'control',
      title: 'Actions',
      className: 'text-center width-75',
      render: (_: undefined, data: any, idx: number) => (
        <IconButton
          className="text-danger"
          onClick={() => {
            this.removeCpt(idx);
          }}>
          <i className="bi bi-trash" />
        </IconButton>
      ),
    });
  }

  toggleCodeState = ({
    refid,
    isChecked,
    letter,
  }: {
    refid: number;
    isChecked: boolean;
    letter: string;
  }) => {
    const { GetIntIcdArr: icdCodes, GetFnlCptArr: cptValues } =
      this.state.model;

    const GetFnlCptArr = isChecked
      ? cptValues
      : cptValues.map((code) => ({
          ...code,
          [`dgc${letter}selstat`]: false,
          [`dgcIcdAlpha${letter}`]: '',
        }));

    const GetIntIcdArr = icdCodes.map((code) =>
      code.refid === refid
        ? {
            ...code,
            selicd_status: isChecked,
          }
        : code
    );

    const model = {
      ...this.state.model,
      GetIntIcdArr,
      GetFnlCptArr,
    };

    this.setState({ model });
  };

  updateTableCell = (refid: any, name: any, value: any) => {
    const GetIntCptArr = this.state.model.GetIntCptArr.map((procedure) =>
      procedure.refid === refid
        ? {
            ...procedure,
            [name]: value,
          }
        : procedure
    );
    const model = {
      ...this.state.model,
      GetIntCptArr,
    };

    this.setState({ model });
  };

  codeIndicator(data: any) {
    const map = [];
    const GetIntIcdArr = [].concat(this.state.model.GetIntIcdArr);
    let alphaIndex = 0;
    for (let key = 0; key < GetIntIcdArr.length; key++) {
      map.push(CptDetails.alphavalue[alphaIndex++]);
    }
    const out = [];
    for (let i = 0; i < map.length; i++) {
      const alpha = map[i];
      out.push(
        <Button
          key={alpha}
          variant={data.alphaButton === alpha ? 'success' : 'default'}
          className="custom-width code-button-indicator"
          onClick={() => {
            this.changeIcdAlphanum(data, alpha);
          }}
          text={alpha}
        />
      );
    }
    return out;
  }

  changeIcdAlphanum(data: any, value: any) {
    const GetIntIcdArr = [].concat(this.state.model.GetIntIcdArr);
    for (let key = 0; key < GetIntIcdArr.length; key++) {
      const item = GetIntIcdArr[key];
      if (data.refid === item.refid) {
        let passed = true;
        if (item.alphaButton === value) {
          item.alphaButton = null;
        } else {
          const icd = GetIntIcdArr || [];
          for (let j = 0; j < icd.length; j++) {
            if (icd[j] !== item && icd[j].alphaButton === value) {
              passed = false;
              Notification.warning('This character already in use');
              break;
            }
          }
          if (passed) {
            item.alphaButton = value;
          }
        }
        if (passed) {
          const model = {
            ...this.state.model,
            GetIntIcdArr,
          };
          this.setState({ model });
        }
        break;
      }
    }
  }

  buildFnlCptIcd(cpt: any, icd: any) {
    const name = 'dgc' + icd.alphaButton + 'selstat';
    return (
      <Checkbox
        name={name}
        noLabel
        value={cpt[name] === true ? 1 : 0}
        onSetValue={(name: any, value: any) =>
          this.addIcdToCpt(value, cpt, icd)
        }
      />
    );
  }

  addIcdToCpt(value: any, cpt: any, icd: any) {
    const model = { ...this.state.model };
    const GetFnlCptArr = [].concat(model.GetFnlCptArr);
    for (let key = 0; key < GetFnlCptArr.length; key++) {
      const item = GetFnlCptArr[key];
      if (item !== cpt) {
        continue;
      }
      const selectedName = 'dgc' + icd.alphaButton + 'selstat';
      const valueName = 'dgcIcdAlpha' + icd.alphaButton;
      const obj: any = {};
      obj[selectedName] = value;
      obj[valueName] = value ? icd.alphaButton : '';
      GetFnlCptArr[key] = { ...item, ...obj };
      model.GetFnlCptArr = GetFnlCptArr;
      this.setState({ model });
      break;
    }
  }

  removeCpt(idx: number) {
    const claimCodes = this.state.model.GetFnlCptArr.filter(
      (_, index) => idx !== index
    );

    const model = {
      ...this.state.model,
      GetFnlCptArr: this.recalculateQ(claimCodes),
    };
    const errors = {
      ...this.state.errors,
      claims: '',
    };
    this.setState({ model, errors });
  }

  handleAddCpt = (currentCpt: InitCptType['0']) => () => {
    const type = this.getCptType(currentCpt);

    if (type === GRAY) {
      Notification.warning(
        "Can't select this item. It was marked as 'Additional Exams'."
      );
    } else {
      const zeroPriceConfirm = Number(currentCpt.cpt_price) <= 0;

      this.setState(
        { zeroPriceConfirm, currentCpt: [currentCpt] },
        zeroPriceConfirm ? null : () => this.moveToFinal([currentCpt])
      );
    }
  };

  acceptInitialCptRender(cpt: InitCptType['0']) {
    const type = this.getCptType(cpt);

    return (
      <div className="control justify-content-center">
        <IconButton onClick={this.handleAddCpt(cpt)}>
          <i className={buildCptClass(type)} />
        </IconButton>
      </div>
    );
  }

  handleApplyAllCpt = () => {
    const availableCpt = this.state.model?.GetIntCptArr.filter(
      (code) => this.getCptType(code) !== GRAY
    );

    const isAllCptWithPrice = availableCpt.every(
      (cpt) => Number(cpt.cpt_price) > 0
    );

    this.setState(
      { zeroPriceConfirm: !isAllCptWithPrice, currentCpt: availableCpt },
      isAllCptWithPrice ? () => this.moveToFinal(availableCpt) : null
    );
  };

  moveToFinal(codes: any[]) {
    const source = this.state.model?.GetFnlCptArr || [];

    const filteredCodes = codes.filter(
      (cpt) =>
        cpt.cpt_code_val === 'Q0092' ||
        !source.some(
          (existed) =>
            cpt.cpt_code_val === existed.cpt_code &&
            cpt.modifier_code === existed.modifier_code
        )
    );

    const isPresent = codes.length !== filteredCodes.length;

    if (isPresent) {
      Notification.warning(
        'Can not add same CPT code, because it already added!'
      );
    }
    const formattedCodes = filteredCodes.map((code) => ({
      refid: code.refid,
      single_price: code.single_price,
      cpt_code: code.cpt_code_val,
      cpt_code_val: code.cpt_code_val,
      cpt_modifier: code.modifier_code,
      cpt_price: code.cpt_price,
      cpt_id: code.cpt_id,
      cptcount: code.cptcount,
      cpt_description: code.cpt_desc,
      accession_number: code.accession_number,
      external_accession_number: code.external_accession_number,
      external_placer_order_number: code.external_placer_order_number,
      modifier_code: code.modifier_code,
    }));

    const model = {
      ...this.state.model,
    };

    model.GetFnlCptArr = source.concat(formattedCodes);

    this.setState({ currentCpt: [], model });
  }

  updateCptModifier(
    cpt: InitCptType['0'],
    cptModifierIds: Array<string | number>
  ) {
    const state = this.state;
    let data = state.model.GetIntCptArr;
    if (!data) {
      return;
    }
    data = [].concat(data);
    for (let key = 0; key < data.length; key++) {
      const obj = data[key];

      if (obj.refid !== cpt.refid || obj.cpt_code_val !== cpt.cpt_code_val) {
        continue;
      }
      // @ts-ignore
      if (obj.defaultCpt === undefined) {
        // @ts-ignore
        obj.defaultCpt = obj.modifier_code || '';
      }
      const oldModifiedCodes = (obj.modifier_code || '')
        .split(',')
        .map((v: any) => Number(v))
        .filter(Boolean);

      if (cptModifierIds.includes(1)) {
        obj.modifier_code = '';
      } else if (cptModifierIds.includes(2)) {
        obj.modifier_code = 'defaultCpt' in obj ? obj.defaultCpt || '' : '';
      } else {
        obj.modifier_code = cptModifierIds ? cptModifierIds.join(',') : '';
      }
      if (!isQrCpt(cpt.cpt_code_val)) {
        const contain38Previously = oldModifiedCodes.includes(38);
        const contain38Now = cptModifierIds.includes(38);
        const currentPrice = obj.cpt_price;
        let message = null;
        if (!contain38Previously && contain38Now) {
          obj.cpt_price =
            'technical_price' in obj ? Number(obj.technical_price) : 0;
          // @ts-ignore
          if (currentPrice !== obj.technical_price) {
            message = 'technical';
          }
        } else if (contain38Previously && !contain38Now) {
          obj.cpt_price = 'globalprice' in obj ? Number(obj.globalprice) : 0;
          // @ts-ignore
          if (currentPrice !== obj.globalprice) {
            message = 'global';
          }
        }
        if (currentPrice !== obj.cpt_price && message !== null) {
          Notification.warning(
            'The price was overridden. Currently using ' + message + ' price. ',
            { autoClose: 5000 }
          );
        }
      }
      break;
    }
    this.setState({
      model: {
        ...state.model,
        GetIntCptArr: data,
      },
    });
  }

  updateFinalCptModifier(cpt: any, cptModifierId: number[]) {
    const state = this.state;
    if (!state.model.GetFnlCptArr) {
      return;
    }

    const data = state.model.GetFnlCptArr.map((code) => {
      if (code !== cpt) return code;

      const defaultCpt = code.defaultCpt || code.modifier_code || '';

      const modifier_code = cptModifierId.includes(1)
        ? ''
        : cptModifierId.includes(2)
        ? defaultCpt
        : cptModifierId.join(',');

      return {
        ...code,
        defaultCpt,
        modifier_code,
      };
    });

    const model = {
      ...state.model,
      GetFnlCptArr: data,
    };
    this.setState({ model });
  }

  isXray() {
    const examType = (this.state.model.examtype || '')
      .toLowerCase()
      .replace(/[\s-_]/g, '');
    return examType.indexOf('xray') > -1;
  }

  getCptType(cpt: InitCptType['0']) {
    const dat = (cpt as any).value || cpt.cpt_code_val;
    const model = this.state.model;
    const visitType = model.noofpatient;
    if (visitType === 'Addtl Exam' && (dat === 'R0070' || dat === 'R0075')) {
      return GRAY;
    }
    if (this.isXray()) {
      return GREEN;
    }
    return ORANGE;
  }

  componentDidMount() {
    ModifierActions.loadModifierList();
  }

  openFindingsPopup = () => {
    const ds = this.getWorkDataSource();
    if (ds.ds.length === 0) {
      let msg;
      if (ds.codesEmpty) {
        msg = 'Final CPT must have at least one Claim Code!';
      } else {
        msg =
          'Q and R codes are not considered as Claim Code for findings, Please choose other CPT to Claim Code!';
      }
      Notification.warning(msg);
    } else {
      this.setState({ findingsDataSource: ds.ds, showFindingsPopup: true });
    }
  };

  getWorkDataSource() {
    const out: {
      codesEmpty: boolean;
      ds: Array<any>;
    } = {
      codesEmpty: true,
      ds: [],
    };
    const ds = this.state.model.GetFnlCptArr;
    if (!ds) {
      return out;
    }
    for (let k = 0; k < ds.length; k++) {
      out.codesEmpty = false;
      const item = ds[k];
      if (CptDetails.Q_R_REGEXP.test(item.cpt_code)) {
        continue;
      }
      const modified = { ...item, cpt_id: item.refid };
      out.ds.push(modified);
    }
    return out;
  }

  onUpdateModel = (
    name: string,
    value: string,
    err?: any,
    clb?: () => void
  ) => {
    const { state } = this;

    const model = {
      ...state.model,
      [name]: value,
    };

    const errors = {
      ...state.errors,
      [name]: err,
    };

    this.setState({ model, errors }, typeof clb === 'function' ? clb : null);
  };

  handleCloseZeroPriceConfirmation = () => {
    this.setState({ zeroPriceConfirm: false });
  };

  handleAddZeroPriceCPT = () => {
    this.setState({ zeroPriceConfirm: false }, () =>
      this.moveToFinal(this.state.currentCpt)
    );
  };

  render() {
    const { props, state } = this;
    const { model } = state;
    const diagnosis = model?.GetIntIcdArr || [];
    const procedures = model?.GetIntCptArr || [];
    const claimCodes = model?.GetFnlCptArr || [];

    const isActionDisabled = !claimCodes.length;

    let modailty = model?.modality || null;

    if (model?.exambody) {
      modailty += '(' + model.exambody + ')';
    }

    return props.fetching || !state.model ? (
      <SpinnerFixed />
    ) : (
      <div className="cpt-details row">
        <ControlsLayout className="align-items-center mb-4">
          <Button
            disabled={isActionDisabled}
            onClick={this.prepareDataToSend(false)}
            text="Incomplete"
          />

          <Button
            disabled={isActionDisabled}
            text="Complete"
            onClick={this.prepareDataToSend(true)}
          />

          <Button
            disabled={isActionDisabled}
            onClick={this.openFindingsPopup}
            text="Findings"
          />

          <ExamExportLink orderId={state.model.refid} />
        </ControlsLayout>

        <Form model={model} onCollectValues={this.onUpdateModel}>
          <Fieldset
            className={
              this.state.collapsedPatient ? 'collapsed' : 'expanded row'
            }>
            <legend
              onClick={() => {
                this.setState({
                  collapsedPatient: !this.state.collapsedPatient,
                });
              }}>
              Exam information
            </legend>

            <Custom
              className={className}
              noclone="true"
              label="DOB"
              custom={model.dateofbirth}
            />
            <Radio
              attr={{ disabled: true }}
              name="assign_status"
              className={`${className} part-inline`}
              options={ASSIGNED_OPTIONS}
              label="Assignment"
            />

            <Radio
              options={BILL_OPTS}
              attr={{ disabled: true }}
              className={billingClassName}
              name="tc_billprocess"
              label="Billing Responsibility"
            />
            <div />
            <Custom
              label="Ordering Physician/NPP"
              noclone="true"
              custom={model.physicianname}
              className={className}
            />
            <Custom
              label="Patient Seen in visit"
              noclone="true"
              custom={model.noofpatient}
              className={className}
            />
            <Custom
              label="Modality"
              noclone="true"
              custom={modailty}
              className={className}
            />
            <Custom
              data-testid="npi"
              className={className}
              noclone="true"
              label={
                <React.Fragment key={model.npi}>
                  <span>Ordering Physician </span>
                  <a
                    key="link01"
                    href={URL_NPPES_NPI}
                    target="_blank"
                    className="npi"
                    rel="noreferrer">
                    NPI
                  </a>
                </React.Fragment>
              }
              custom={
                <span key='span01'>
                  {model.npi ? model.npi + ' ' : 'no npi '}
                </span>
              }
            />
            <Custom
              label="Procedure Count"
              noclone="true"
              custom={model.daily_encounter}
              className={className}
            />
            <Custom
              label="Reason for Exam Symptoms"
              noclone="true"
              custom={model.exam_symptoms}
              className={className}
            />
            <Custom
              label="Completed Time Stamp"
              noclone="true"
              custom={dateToLocalTimezone({
                date: model.completion_time,
              })}
              className={className}
            />
            <Custom
              label="Reports:"
              noclone="true"
              custom={this.prepareReports(model.Reports)}
              className="col-sm-12 col-md-auto"
            />
          </Fieldset>
        </Form>

        <ul className="coding-legend">
          <li>
            <i className="bi bi-check-circle-fill text-primary" />
            X-Ray
          </li>
          <li>
            <i className="bi bi-check-circle-fill text-warning" />
            Others
          </li>
          <li>
            <i className="bi bi-check-circle-fill text-secondary" />
            Additional Exam
          </li>
          <li>
            <Button
              text="Add/Edit Procedure Codes"
              variant="warning"
              disabled // This feature doesn't work properly here
              onClick={() => this.setState({ isCptCodeModalOpen: true })}
            />
          </li>
        </ul>

        <div className="col-xl-6 mb-5">
          <div
            className="d-flex justify-content-between grid-wrapper"
            style={{ marginBottom: '4px' }}>
            <h5>Claim Builder</h5>
            <GridControlButton
              title="Apply all"
              disabled={!procedures.length}
              onClick={this.handleApplyAllCpt}
            />
          </div>
          <Grid
            stateless
            disablePagination
            dataSource={IterableUtils.mergeDeep([], procedures)}
            columns={this.initialCptColumns}
            noControl
          />
        </div>

        <div className="col-xl-6 mb-5">
          <div className="grid-wrapper" style={{ marginBottom: '7px' }}>
            <GridControlButton
              title="Add Initial ICD"
              onClick={() => this.setState({ isIcdCodeModalOpen: true })}
            />
          </div>
          <Grid
            stateless
            disablePagination
            dataSource={IterableUtils.mergeDeep([], diagnosis)}
            columns={this.initialIcdColumns}
            noControl
          />
        </div>

        <h5>Claim Codes</h5>
        <Grid
          stateless
          disablePagination
          noControl
          dataSource={model.GetFnlCptArr}
          columns={this.getFinalCptColumns(diagnosis)}
        />
        <div
          style={{ marginTop: '-20px' }}
          className={`mb-2 text-danger${state.errors.claims ? '' : ' d-none'}`}
          ref={this.refError}>
          {state.errors.claims}
        </div>

        {state.showFindingsPopup ? (
          <FindingPopup
            order={model}
            dataSource={state.findingsDataSource}
            onClose={this.onProcessFindings}
          />
        ) : null}

        {state.isCptCodeModalOpen ? (
          <CPTCodeModal
            orderId={props.orderId}
            checkableZeroPrice
            modalityId={model.examtypeid}
            callback={(response: any) => this.cptCodeModalResponse(response)}
            dataSource={this.getCptModalDs(procedures)}
          />
        ) : null}
        {state.isIcdCodeModalOpen ? (
          <DialogIcdCode
            smoking={model.smoking}
            symptomatic={model.symptomatic}
            callback={this.icdCodeModalResponse}
            selectedCodes={diagnosis.map((code) => ({
              ...code,
              refid: Number(code.refid),
            }))}
          />
        ) : null}
        {state.showNoFindings && (
          <Dialog handleClose={this.handleCloseFindingsEmpty}>
            <DialogHeader
              title="Confirm"
              onClose={this.handleCloseFindingsEmpty}
            />
            <DialogBody>You did not select the findings.</DialogBody>
            <DialogFooter>
              <Button
                variant="warning"
                text={
                  state.lastSubmit
                    ? 'Complete the coding'
                    : 'Incomplete the coding'
                }
                onClick={this.handleApplyCoding}
              />
              <Button
                text="Proceed for findings"
                onClick={this.handleProceedFinding}
              />
            </DialogFooter>
          </Dialog>
        )}
        {state.zeroPriceConfirm && (
          <DialogConfirm
            onCancel={this.handleCloseZeroPriceConfirmation}
            onApprove={this.handleAddZeroPriceCPT}>
            Are you sure you want to add a zero price CPT?
          </DialogConfirm>
        )}
      </div>
    );
  }

  handleApplyCoding = () => {
    this.setState({ showNoFindings: true, isFindingSet: true }, () => {
      this.prepareDataToSend(this.state.lastSubmit)();
    });
  };

  handleProceedFinding = () => {
    this.setState({ showNoFindings: false });
    this.openFindingsPopup();
  };

  handleCloseFindingsEmpty = () => {
    this.setState({ showNoFindings: false, isFindingSet: false });
  };

  getCptModalDs = (initialCpt: InitCptType) =>
    initialCpt.map(({ cpt_desc, cpt_code_val, ...cloned }) => ({
      ...cloned,
      cpt_description: cpt_desc,
      cpt_code: cpt_code_val,
      initial: true,
    }));

  cptCodeModalResponse(cptCodes: Array<any>) {
    if (cptCodes !== null) {
      const model = { ...this.state.model };
      for (let k = 0; k < cptCodes.length; k++) {
        let item = cptCodes[k];
        if (!item.initial) {
          item.cpt_id = item.refid;
        } else {
          delete item.initial;
        }
        if (item.cpt_description) {
          item.cpt_desc = item.cpt_description;
          delete item.cpt_description;
        }
        if (item.cpt_code) {
          item.cpt_code_val = item.cpt_code;
          delete item.cpt_code;
        }
        if (item.modifier_code === '0') {
          item.modifier_code = '';
        }
      }
      model.GetIntCptArr = this.recalculateQ(cptCodes);
      this.setState({ isCptCodeModalOpen: false, model });
    } else {
      this.setState({ isCptCodeModalOpen: false });
    }
  }

  icdCodeModalResponse = (icdCodes: Array<any>) => {
    const model = {
      ...this.state.model,
      GetIntIcdArr: icdCodes,
    };
    this.setState({ isIcdCodeModalOpen: false, model });
  };

  makeCPTString(modifiers: string) {
    const modifierList = modifiers ? modifiers.split(',') : [];
    const optionsList = ModifierStore.getState().modifierList;

    const modifierStr = modifierList
      .map((id: string) => {
        const option = optionsList.find(({ value }) => Number(id) === value);
        return option?.label || '';
      })
      .filter(Boolean)
      .join(',');

    return modifierStr;
  }

  prepareDataToSend = (isComplete: boolean) => () => {
    const { model, isFindingSet } = this.state;
    const procedures = model.GetIntCptArr || [];
    const claimCodes = model.GetFnlCptArr || [];
    const diagnosis = model.GetIntIcdArr || [];

    const isClaimsRelevant = isComplete
      ? claimCodes
          .filter((claim) => !isQrCpt(claim.cpt_code))
          .every((claim) => {
            const accessionNumber = claim.session_no || claim.accession_number;
            return procedures.some(
              (procedure) =>
                claim.cpt_code === procedure.cpt_code_val &&
                // @ts-ignore
                accessionNumber === procedure.accession_number
            );
          })
      : true;

    if (!isClaimsRelevant) {
      const errors = {
        ...this.state.errors,
        claims:
          "Some Claim Code doesn't match Procedures! Please ensure that the CPT Code and Accession Number for each claim code accurately correspond to the associated procedure in Claim Builder.",
      };
      this.setState({ errors }, () => {
        this.refError.current?.scrollIntoView({ behavior: 'auto' });
      });
      return;
    }

    if (!isFindingSet && model.isfinding !== 'Y') {
      this.setState({ showNoFindings: true, lastSubmit: isComplete });
      return;
    }

    claimCodes.forEach((item) => {
      item.modifier = this.makeCPTString(item.modifier_code);
    });

    CodingActions.sendCoding(
      {
        comp_status: isComplete ? 'Y' : 'I',
        orderid: model.refid,
        facilityid: model.facilityid,
      },
      procedures.map((v) => ({
        data: v.cpt_code_val,
        addvisible: false,
        desc: v.cpt_desc,
        price: v.cpt_price,
        modifier: this.makeCPTString(v.modifier_code),
        // @ts-ignore
        refid: v.cpt_id || null,
        // @ts-ignore
        accession_number: v.accession_number || '',
        // @ts-ignore
        external_placer_order_number: v.external_placer_order_number || '',
        // @ts-ignore
        external_accession_number: v.external_accession_number || '',
        cptcount: v.cptcount || 1,
      })),
      diagnosis.map((v) => ({
        desc: v.short_description,
        selicd_status: v.selicd_status || false,
        alphavalue:
          v.alphaButton ||
          v.alphavalue /*alphaButton - js value, alphavalue - php value*/,
        data: v.icd_code,
        refid: v.refid,
      })),
      claimCodes.map((v) => {
        let icdCodes = v.icd_code ? v.icd_code.split(',') : [];
        icdCodes.forEach((item: any) => {
          if (item) {
            v['dgcIcdAlpha' + item] = item;
          }
        });
        if (!v.accession_number && v.session_no) {
          v.accession_number = v.session_no;
        }
        v.data = v.cpt_code;
        v.price = v.cpt_price;
        v.cptcount = v.cptcount || 1;
        v.mode = 'F';
        v.cpt_id = v.cpt_id || null;
        v.icd = null; //@todo check it, can depend from icd codes
        return v;
      })
    ).then(() => {
      const { history, orderId } = this.props;
      history.push(URL_CODING);
      VisitNotesActions.loadNotes(orderId);
      Notification.success('Coding details has been updated successfully');
    });
  };

  onProcessFindings = (justClose: boolean) => {
    const state: any = { showFindingsPopup: false };
    if (justClose) {
      state.isFindingSet = true;
    }
    this.setState(state);
  };

  prepareReports(reports: any) {
    if (!reports || typeof reports === 'string') {
      return 'Reports not available';
    }

    const linksToReports =
      reports?.fax_report?.map((report: any) => (
        <div key={report.file_name} className="report-holder">
          <a
            key={report.file_name}
            href={`${BASE_URL_FILE_DIR}doc_img/documents/${report.file_path}`}
            target="_blank"
            className="report-holder-wrapper"
            rel="noreferrer">
            <i className="bi bi-filetype-pdf fs-4 me-3" />
            {report.file_name}
          </a>
        </div>
      )) || null;
    return <>{linksToReports}</>;
  }
}

export default Container.create(CptDetails, { withProps: true });
