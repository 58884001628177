import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import Form from './components/form';

import {
  storeIntegrations,
  IntegrationDetailsType,
} from 'stores/_mobx/systemSetup/masterSetting/integrations';
import { URL_INTEGRATION_MASTER_NEW } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const IntegrationEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, integrationDetails } = storeIntegrations;

  const handleSubmit = (payload: IntegrationDetailsType) =>
    storeIntegrations.updateIntegration(payload).then((response) => {
      if (response) {
        return response;
      }
      history.push(URL_INTEGRATION_MASTER_NEW);
    });

  useEffect(() => {
    if (integrationDetails === null) {
      history.replace(URL_INTEGRATION_MASTER_NEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationDetails]);

  useEffect(() => {
    const id = Number(params.id);

    if (id !== integrationDetails?.id) {
      storeIntegrations.getIntegrationDetails(id);
    }
    return storeIntegrations.clearIntegrationDetails;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LayoutSideTitle title="Edit Integration">
        <Link to={URL_INTEGRATION_MASTER_NEW} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <Form
        defaultValues={integrationDetails}
        fetching={fetching}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default observer(IntegrationEditPage);
