import React from 'react';

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
  title?: string;
  className?: string;
  'data-testid'?: string;
  onClick?: (e: React.MouseEvent) => void;
}

const IconButton = ({ children, className = '', onClick, ...rest }: Props) => (
  <button
    type="button"
    className={`icon-container ${className}`}
    onClick={onClick}
    {...rest}
  >
    {children}
  </button>
);

export default IconButton;
