import { useState } from 'react';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { PureCheckbox } from 'components/form/checkbox';
import { Button } from 'components/button';

interface PropsType {
  onCancel: () => void;
  onConfirm: () => void;
}

const DialogConfirmDeleteCPTCode = ({ onCancel, onConfirm }: PropsType) => {
  const [confirmation, setConfirmation] = useState<boolean>(false);

  return (
    <Dialog handleClose={onCancel}>
      <DialogHeader title="Delete Procedure" />
      <DialogBody>
        <img
          src="/assets/images/stop-large.png"
          alt="stop"
          className="codes-window-stop-img"
        />
        <div className="codes-window-stop-text">
          <p>Are you sure you want to delete this CPT code?</p>
          <p>Deleting the code will <b>REMOVE</b> the associated accession number from the system.</p>
          <p>To retain the accession number, click the pencil icon to edit the CPT code.</p>
        </div>
        <PureCheckbox
          name="confirm"
          className="codes-window-stop-checkbox text-danger"
          label="I confirm my intentions to delete this CPT code"
          isLabelBold
          checked={confirmation}
          onChange={setConfirmation}
        />
      </DialogBody>
      <DialogFooter>
        <Button text="Cancel" variant="warning" onClick={onCancel} />
        <Button text="Delete" disabled={!confirmation} onClick={onConfirm} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogConfirmDeleteCPTCode;
