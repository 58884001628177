import { useForm } from 'react-hook-form';

import RadioOptions from 'components/project/common/RadioOptions';
import { DropDownPhysician } from 'components/project/dropdown/PhysicianDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropDownOrderType } from 'components/project/dropdown/OrderType';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { InputCalendar } from 'components/form/inputCalendar';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import { DateRange } from 'components/form/dateRange';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';

import {
  storeDocumentManagerAcquisition,
  filterDefaultValues,
  FilterType,
} from 'stores/_mobx/patientRecord/documentManagerAcquisition';

interface PropsType {
  fetching: boolean;
  isFilterVisible: boolean;
  onSearch: (data: FilterType) => void;
}

const FilterAcquisition = ({
  fetching,
  isFilterVisible,
  onSearch,
}: PropsType) => {
  const { control, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeDocumentManagerAcquisition.filter,
  });

  const handleClickReset = () => {
    reset(filterDefaultValues);
    onSearch(filterDefaultValues);
  };

  return (
    <form
      className={`row ${isFilterVisible ? '' : 'd-none'}`}
      onSubmit={handleSubmit(onSearch)}>
      <Input
        name="lastName"
        label="Patient Last Name"
        className="col-sm-3"
        control={control}
      />
      <Input
        name="firstName"
        label="Patient First Name"
        className="col-sm-3"
        control={control}
      />
      <InputCalendar
        name="dob"
        label="DOB"
        className="col-sm-3"
        control={control}
      />
      <DropDownPhysician
        name="physicianId"
        label="Physician"
        className="col-sm-3"
        control={control}
      />
      <div />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        control={control}
      />
      <DropDownState
        name="stateId"
        label="State"
        className="col-sm-3"
        control={control}
      />
      <div />
      <DropDownPlaceOfService
        name="posId"
        label="Place of Service"
        className="col-sm-3"
        control={control}
      />
      <DropDownOrderType
        name="orderType"
        label="Order Type"
        className="col-sm-3"
        control={control}
      />
      <DropDownFacility
        name="facilityId"
        label="Facility"
        className="col-sm-3"
        control={control}
      />
      <Radio
        name="status"
        label="Order Status"
        className="part-inline col-sm-3"
        options={RadioOptions.orderStatus}
        control={control}
      />
      <ControlsLayout alignX="right" className="mb-4">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button type="submit" text="Apply" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default FilterAcquisition;
