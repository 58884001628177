import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { InputCalendar } from 'components/form/inputCalendar';
import { Textarea } from 'components/form/textarea';
import { Input } from 'components/form/textField';
import resolver from './validation';

export interface FormModel {
  refid?: number;
  thyroid_name: string;
  thyroid_number: string;
  last_survey: string;
  next_survey: string;
  description: string;
}

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
}

const initialValues: FormModel = {
  thyroid_name: '',
  thyroid_number: '',
  last_survey: '',
  next_survey: '',
  description: '',
};

const ModifyThyroidShieldForm = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting, isDirty },
    handleSubmit,
    reset,
  } = useForm({ defaultValues, resolver });

  const cn = `col-md-10 col-lg-8 col-xl-6${isSubmitting ? ' on-ajax' : ''}`;

  const handleReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form className={cn} onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="thyroid_name"
        label="Thyroid Shield Name"
        required
        control={control}
      />
      <Input
        name="thyroid_number"
        label="Thyroid shield number"
        required
        control={control}
      />
      <div className="row">
        <InputCalendar
          name="last_survey"
          label="Last survey date"
          className="col-sm-6"
          placeholder="dd/mm/yyyy"
          control={control}
        />
        <InputCalendar
          name="next_survey"
          label="Next survey date"
          className="col-sm-6"
          placeholder="dd/mm/yyyy"
          control={control}
        />
      </div>
      <Textarea name="description" label="Description" control={control} />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          type="reset"
          disabled={!isDirty}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default ModifyThyroidShieldForm;
