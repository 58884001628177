import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { observer } from 'mobx-react-lite';

import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import Tabs from 'components/tabs';
import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import InvoiceBasicTab from './BasicTab';
import ChargePosting from './chargePosting';

import { storeInvoice } from 'stores/_mobx/billingCodding/invoice';
import AccessUtils from 'utils/AccessUtils';
import { INVOICE as PAGE_ID } from 'constant/pagesId/billingCodding';
import { URL_INVOICES } from 'constant/path/billingCoding';

type Keys = 'F' | 'PD' | 'O';

const TABS: Keys[] = ['F', 'PD', 'O'];

const TABS_PERMISSION_ARRAY = [
  PAGE_ID.TAB_FACILITY,
  PAGE_ID.TAB_PER_DIEM,
  PAGE_ID.TAB_OCC_HLTH,
];

const tabs: Record<
  Keys,
  {
    label: string;
    url: string;
    type: 'H' | 'N' | 'Y';
    flag: 'P' | 'F';
    Component: React.ComponentType<any>;
  }
> = {
  F: {
    label: 'Facility Invoice',
    url: `${URL_INVOICES}?tab=${TABS[0]}`,
    type: 'N',
    flag: 'F',
    Component: InvoiceBasicTab,
  },
  PD: {
    label: 'Per Diem Invoice',
    url: `${URL_INVOICES}?tab=${TABS[1]}`,
    type: 'Y',
    flag: 'P',
    Component: InvoiceBasicTab,
  },
  O: {
    label: 'Occ. Hlth. Invoice',
    url: `${URL_INVOICES}?tab=${TABS[2]}`,
    type: 'H',
    flag: 'F',

    Component: InvoiceBasicTab,
  },
};

const InvoicePage = ({ isDivisionEnabled }: PropsTypeWitDivisionSettings) => {
  const { invoiceForChargePosting } = storeInvoice;

  const [isFilterVisible, toggleFilter] = useState(false);

  const { search } = useLocation();

  const allowedTabs = useMemo(
    () => AccessUtils.filterBySameIndex(TABS, TABS_PERMISSION_ARRAY) as Keys[],
    []
  );

  const tabsList = useMemo(
    () =>
      allowedTabs.map((key) => {
        const tab = tabs[key];
        return {
          label: tab.label,
          url: tab.url,
        };
      }),
    [allowedTabs]
  );

  const selectedTab = useMemo(
    () =>
      Object.values(tabs).find(({ url }) => url === `${URL_INVOICES}${search}`),
    [search]
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleCloseChargePosting = (shouldUpdate: boolean) => {
    storeInvoice.clearInvoiceForChargePosting();

    if (shouldUpdate)
      storeInvoice.getInvoicesListMain({
        invoiceType: selectedTab.type,
        isDivisionEnabled,
      });
  };

  if (!allowedTabs.length) {
    return <div>Sorry, there are no invoices available for you.</div>;
  }

  return (
    <>
      <div className={invoiceForChargePosting ? 'd-none' : ''}>
        <LayoutSideTitle appId={PAGE_ID.PAGE} title="Invoices">
          <Button
            variant="default"
            text={isFilterVisible ? 'Hide filter' : 'Show filter'}
            data-testid="toggleFilter"
            onClick={handleToggleFilter}
          />
        </LayoutSideTitle>

        <Tabs tabsList={tabsList}>
          {allowedTabs.map((key) => {
            const { Component, url, type, flag } = tabs[key];

            const isPerDiemTab = key === 'PD';

            return (
              <Component
                key={url}
                isDivisionEnabled={isDivisionEnabled}
                isFilterVisible={isFilterVisible}
                invoiceType={type}
                invoiceFlag={flag}
                isPerDiemTab={isPerDiemTab}
              />
            );
          })}
        </Tabs>
      </div>

      {invoiceForChargePosting && selectedTab ? (
        <ChargePosting
          title={selectedTab.label}
          invoice={invoiceForChargePosting}
          flag={selectedTab.flag}
          callback={handleCloseChargePosting}
        />
      ) : null}
    </>
  );
};

export default withDivisionSettings(observer(InvoicePage));
