import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { DropDownApplicationUserType } from 'components/project/dropdown/ApplicationUsersDropdown';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { InputCalendar } from 'components/form/inputCalendar';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import { Select } from 'components/form/select';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import { FilterType, storeAuditTrail } from 'stores/_mobx/users/auditTrail';

const OPTIONS = [
  { value: 'ADD', label: 'ADD' },
  { value: 'DELETE', label: 'DELETE' },
  { value: 'EDIT', label: 'EDIT' },
  { value: 'LOGIN', label: 'LOGIN' },
  { value: 'LOGIN ERROR', label: 'LOGIN ERROR' },
  { value: 'LOGOUT', label: 'LOGOUT' },
  { value: 'MESSAGING', label: 'MESSAGING' },
  { value: 'PASSWORD', label: 'PASSWORD' },
  { value: 'SEARCH', label: 'SEARCH' },
  { value: 'STATUS_CHANGE', label: 'STATUS_CHANGE' },
  { value: 'VIEW', label: 'VIEW' },
];

const OPTIONS_USER_TYPE = [
  { value: '', label: 'All' },
  { value: 1, label: 'Client User' },
  { value: 0, label: 'Company User' },
];

const fieldCn = 'col-md-6 col-lg-3';

interface PropsType {
  isVisible: boolean;
  fetching: boolean;
  onSubmit: (filter: FilterType) => void;
}

const Filter = ({ isVisible, fetching, onSubmit }: PropsType) => {
  const { control, handleSubmit, watch, reset } = useForm<FilterType>({
    defaultValues: storeAuditTrail.filter,
  });

  const type = watch('userTypeToggler');

  const handleClickReset = () => {
    const defaultFilterValue = storeAuditTrail.getDefaultFilter();

    reset(defaultFilterValue);
    onSubmit(defaultFilterValue);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'userTypeToggler') {
        reset({
          ...formValue,
          userType: 0,
          facility: 0,
        });
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="lastName"
        label="Last Name"
        className={fieldCn}
        control={control}
      />
      <Input
        name="firstName"
        label="First Name"
        className={fieldCn}
        control={control}
      />
      <InputCalendar
        name="dateFrom"
        label="Date From"
        className={fieldCn}
        control={control}
      />
      <InputCalendar
        name="dateTo"
        label="Date To"
        className={fieldCn}
        control={control}
      />
      <div />
      <Select
        name="type"
        label="Action Type"
        className={fieldCn}
        options={OPTIONS}
        control={control}
      />
      <Radio
        name="userTypeToggler"
        label="User Type"
        className={`${fieldCn} part-inline`}
        options={OPTIONS_USER_TYPE}
        control={control}
      />

      <DropDownApplicationUserType
        name="userType"
        label="User Type"
        className={fieldCn}
        type={type}
        control={control}
      />

      <DropDownFacility
        name="facility"
        label="Facility"
        disabled={type !== 1}
        className={fieldCn}
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
