import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormSuffix, { FormModel } from './components/Form';

import { storeSuffix } from 'stores/_mobx/systemSetup/masterSetting/suffix';
import { URL_SUFFIX } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const SuffixAddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: FormModel) =>
    storeSuffix.addSuffix(payload).then((response) => {
      if (response === true) {
        history.push(URL_SUFFIX);
      }
      return typeof response === 'boolean' ? null : response;
    });

  return (
    <>
      <Title title="Add suffix" />
      <FormSuffix backUrl={URL_SUFFIX} onSubmit={handleSubmit} />
    </>
  );
};

export default SuffixAddPage;
