import { Container } from 'flux/utils';

import AbstractUserSettings, {
  PAbstractUserSettings,
  SAbstractUserSettings,
} from './AbstractUserSettings';
import UserSelectionStore from 'stores/project/UserSelectionStore';
import AccessUtils from 'utils/AccessUtils';
import { CLIENT as PAGE_ID } from 'constant/pagesId/privileges';

export interface PClientUserPrivilegesPage extends PAbstractUserSettings {}

export class SClientUserPrivilegesPage extends SAbstractUserSettings {}

export class ClientUserPrivilegesPage extends AbstractUserSettings<
  PClientUserPrivilegesPage,
  SClientUserPrivilegesPage
> {
  title = 'Client User Settings';

  static getStores() {
    return AbstractUserSettings.getStores();
  }

  static calculateState(
    prevState: SClientUserPrivilegesPage,
    props: PClientUserPrivilegesPage
  ) {
    return AbstractUserSettings.calculateState(prevState, props);
  }

  hasViewLogAccess() {
    return AccessUtils.checkAccess(PAGE_ID.VIEW_LOG);
  }

  hasSavePermissionsAccess() {
    return AccessUtils.checkAccess(PAGE_ID.SAVE);
  }

  getUserTypeOptions() {
    if (UserSelectionStore.isUserTypesLoaded()) {
      return this.state.userTypeList.filter((v) => {
        return (
          v.value === '0' ||
          (v.value !== 8 && !UserSelectionStore.isTech(v.value))
        );
      });
    } else {
      return [];
    }
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }
}

export default Container.create(ClientUserPrivilegesPage, { withProps: true });
