import { FakeInput } from 'components/form/textField';

interface PropsType {
  facilityName: string;
  facilityNpi: string;
  phone: string;
}

const InvoiceDetailHeader = ({
  facilityName,
  facilityNpi,
  phone,
}: PropsType) => (
  <div className="row">
    <FakeInput
      className="col-md-4"
      label="Facility Name"
      value={facilityName}
    />
    <FakeInput className="col-md-4" label="Facility NPI" value={facilityNpi} />
    <FakeInput className="col-md-4" label="Phone" value={phone} />
  </div>
);

export default InvoiceDetailHeader;
