import { useState } from 'react';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { PureRadio } from 'components/form/radio';

const REDIRECT_OPTIONS = [
  { value: 1, label: 'Same facility' },
  { value: 2, label: 'Same patient' },
  { value: 3, label: 'New order' },
];

interface PropsType {
  onClose: () => void;
  onSubmit: (result: number) => void;
}

const DialogRedirectToOrder = ({ onClose, onSubmit }: PropsType) => {
  const [redirect, setRedirect] = useState<number>(1);

  const handleSubmit = () => {
    onSubmit(redirect);
  };

  return (
    <Dialog handleClose={onClose}>
      <DialogHeader title="Lattice Pro" onClose={onClose} />
      <DialogBody>
        <b>Would you like to continue with another order?</b>
        <PureRadio
          name="redirect_options"
          className="part-inline"
          value={redirect}
          options={REDIRECT_OPTIONS}
          onChange={setRedirect}
        />
      </DialogBody>
      <DialogFooter>
        <Button variant="warning" text="No" onClick={onClose} />
        <Button className="btn btn-primary" text="Yes" onClick={handleSubmit} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogRedirectToOrder;
