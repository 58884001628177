import Fieldset, { PFieldset, SFieldset } from 'components/form/Fieldset';
import Text from 'components/form/input/Text';
import Select from 'components/form/input/Select';
import Custom from 'components/form/input/Custom';
import TextCalendar from 'components/form/input/TextCalendar';
import PayerNameDropdown from 'components/project/dropdown/PayerNameDropdown';

import PatientRecordPageService from 'services/patient-records/patient-demographics/PatientRecordPageService';

import RadioOptions from 'components/project/common/RadioOptions';

const MSP_OPTIONS = [
  { label: '--select--', value: 0 },
  { label: 'Auto No Fault Insurance', value: 15 },
  { label: 'Black Lung', value: 41 },
  { label: 'Disabled Beneficiary', value: 13 },
  { label: 'End Stage Renal Disease', value: 14 },
  { label: 'Federal', value: 43 },
  { label: 'Hospice', value: 1 },
  { label: 'Liability Insurance', value: 42 },
  { label: 'Workers Compensation Medical Set Aside', value: 47 },
  { label: 'Working Aged Beneficiary', value: 12 },
];

const notEmpty = (value?: null | string) =>
  value ? value.trim().length > 0 : false;

const notEmptyAndValid = (name: string, formModel: any, formErrors: any) =>
  notEmpty(!formModel || formModel[name]) &&
  (!formErrors || !formErrors[name] || formErrors[name].length <= 0);

const calculateEnableArchive = (
  value: string,
  errors: any,
  formModel: any,
  formErrors: any
) => {
  const enableArchive =
    !formErrors?.payorId?.length &&
    Boolean(formModel?.payorId) &&
    notEmptyAndValid('insuranceNo', formModel, formErrors) &&
    notEmptyAndValid('insStT', formModel, formErrors) &&
    !formErrors?.planId?.length &&
    Boolean(formModel?.planId) &&
    notEmpty(value) &&
    (!errors || errors.length <= 0);
  return enableArchive;
};

interface OptionType {
  label: string;
  value: number;
}

export interface PInsurance extends PFieldset {
  isPrimaryInsurance?: boolean;
  withMSPField?: boolean;
  onPlanOptionUpdate: (plan: OptionType[]) => void;
}

export class SInsurance extends SFieldset {
  enableArchive: boolean = false;
}

export default class Insurance extends Fieldset<PInsurance, SInsurance> {
  constructor(props: PInsurance) {
    super(props);
    const state = new SInsurance();
    const insEndT = props.formModel ? props.formModel.insEndT : null;
    state.enableArchive = calculateEnableArchive(
      insEndT,
      null,
      props.formModel,
      props.formErrors
    );
    this.state = state;
  }

  static getDerivedStateFromProps(props: PInsurance) {
    const insEndTimeValue = props.formModel ? props.formModel.insEndT : null;
    const insEndTimeError = props.formErrors ? props.formErrors.insEndT : null;
    const enableArchive = calculateEnableArchive(
      insEndTimeValue,
      insEndTimeError,
      props.formModel,
      props.formErrors
    );
    return { enableArchive };
  }

  renderChildren() {
    const clazz = 'col-sm-4';
    const clazz02 = 'col-sm-8';
    const { props, state } = this;
    let validations;
    if (props.formModel && Boolean(props.formModel.payorId)) {
      if (props.validations) {
        validations = props.validations;
      } else {
        validations = {
          planId: props.isPrimaryInsurance ? 'required' : '',
          insuranceNo: 'required',
          insStT: 'required',
        };
      }
    } else {
      validations = {};
    }

    return [
      <h3 key={0}>{props.title}</h3>,
      <PayerNameDropdown
        key={1}
        onSetValue={this.onSetPayer}
        name="payorId"
        label="Payer Name"
        className={clazz}
        validations={validations.payorId}
      />,
      <Text
        key={2}
        name="insuranceNo"
        label="Insured ID"
        className={clazz}
        validations={validations.insuranceNo}
      />,
      <TextCalendar
        key={3}
        name="insStT"
        label="Effective Date"
        className={clazz}
        validations={validations.insStT}
      />,
      <div key={4} />,
      <Select
        key={5}
        name="planId"
        label="Plan Name"
        accurateSearch
        options={props.formModel?.plan || []}
        className={clazz}
        validations={validations.planId}
      />,
      <Text
        key={6}
        name="groupNo"
        label="Group #"
        className={clazz}
        validations={validations.group}
      />,
      <TextCalendar
        key={7}
        onSetValue={this.onSetTerminationDate}
        name="insEndT"
        label="Termination Date"
        className={clazz}
        validations={validations.insEndT}
      />,
      <div key={8} className="clearfix" />,
      <Select
        key={9}
        name="insPatRelation"
        label="Relationship"
        options={RadioOptions.relationship}
        className={clazz}
        validations={validations.insPatRelation}
      />,
      this.isMSPFieldVisible() ? (
        <Select
          key={11}
          options={MSP_OPTIONS}
          name="msp"
          label="MSP"
          validations="required"
          className={clazz}
        />
      ) : null,
      <Custom
        key={12}
        label="&nbsp;"
        className={clazz02}
        custom={
          <div>
            <input
              type="button"
              disabled={!state.enableArchive}
              onClick={() => this.archivePayer()}
              value="Archive &amp; Save"
              className="btn btn-warning"
            />
            <small className="fw-bold px-2" style={{ color: 'black' }}>
              'Termination Date' Required for Archiving *
            </small>
          </div>
        }
      />,
    ].map((child, key) => {
      return this._cloneChild(child, '' + key);
    });
  }

  archivePayer() {
    this.props.archivePayer();
  }

  getPlanOption() {
    const { planId, plan } = this.props.formModel;

    if (planId) {
      const option = (plan as OptionType[]).find(
        ({ value }) => value === planId
      );

      return option;
    }

    return null;
  }

  isMSPFieldVisible = (): boolean => {
    const { withMSPField } = this.props;
    if (withMSPField) {
      const planLabel = this.getPlanOption()?.label || '';

      return planLabel.toLowerCase().startsWith('medicare');
    }
    return false;
  };

  onSetPayer = (name: string, value: string, errors: any) => {
    const { props } = this;
    if (value) {
      PatientRecordPageService.loadPayerPlan(Number(value)).then(
        props.onPlanOptionUpdate
      );
    }
    if (props.onSetValue) {
      props.onSetValue(name, value, errors);
    }
  };

  onSetTerminationDate = (name: string, value: string, errors: any) => {
    if (!errors) {
      errors = [];
    }
    const { props } = this;
    const enableArchive = calculateEnableArchive(
      value,
      errors,
      props.formModel,
      props.formErrors
    );
    this.setState({ enableArchive: enableArchive });
    if (props.onSetValue) {
      props.onSetValue(name, value, errors);
    }
  };
}
