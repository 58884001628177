import AbstractLikeFilter from './AbstractLikeFilter';

export default class CorporateFilter extends AbstractLikeFilter {
  name = 'corporate';
  field = 'Corporate';
  field1 = AbstractLikeFilter.getInsecureSecureField(
    'ppxray_corporategroup.group_nm'
  );
  className = 'corporate-filter';
}
