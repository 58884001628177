import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { zipValidator, phoneValidator } from 'utils/formValidators';

const zipValidation = (message: string) =>
  yup.string().test({
    test: (zipCode: string) => (zipCode ? zipValidator(zipCode) : true),
    message: `${message} should be in xxxxx-xxxx format!`,
  });

const phoneValidation = (message: string) =>
  yup.string().test({
    test: (phone: string) => (phone ? phoneValidator(phone) : true),
    message: `${message} should be in xxx-xxx-xxxx format!`,
  });

const validation = yupResolver(
  yup.object().shape({
    email: yup
      .string()
      .required('Please fill in Email')
      .email('Invalid email address.'),
    homezipcode: zipValidation('Zip Code'),
    home_phone: phoneValidation('Phone'),
    cell: phoneValidation('Mobile Phone'),
    fax: phoneValidation('Fax'),
    emergency_phone: phoneValidation('Emergency Phone'),
  })
);

export default validation;
