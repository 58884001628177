import { useEffect, forwardRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';

import {
  PureSelect,
  PureSelectProps,
  SelectPropsType,
} from 'components/form/select';

import { storeDivisionMaster } from 'stores/_mobx/systemSetup/masterSetting/divisionMaster';

export const DropdownPureDivision = observer(
  forwardRef<PureSelectProps, any>((props, ref) => {
    const {
      options,
      fetchingOptions,
      storeDivisionSettingsStatus: { isDivisionEnabled, fetching },
    } = storeDivisionMaster;

    useEffect(() => {
      if (isDivisionEnabled) {
        storeDivisionMaster.getOptions();
      }

      return storeDivisionMaster.clearOptions;
    }, [isDivisionEnabled]);

    return isDivisionEnabled ? (
      <PureSelect
        {...props}
        ref={ref}
        options={options}
        isLoading={fetching || fetchingOptions}
        disabled={fetching || props.disabled}
      />
    ) : null;
  })
);

export const DropdownDivision = ({
  name,
  control,
  ...props
}: Omit<SelectPropsType, 'options'>) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => {
      const errorMessage = error?.message || '';

      return (
        <DropdownPureDivision
          errorMessage={errorMessage}
          {...field}
          {...props}
        />
      );
    }}
  />
);
