import React from 'react';
import { Container } from 'flux/utils';

import Notification from 'components/modal/Notification';
import DialogConfirm from 'components/modal/dialogConfirm';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import Fieldset from 'components/form/Fieldset';
import Text from 'components/form/input/Text';
import DialogZipCodeList from './DialogZipCodeList';

import JurisdictionDetailsActions from 'actions/system-setup/master-setup/operations/jurisdiction/JurisdictionDetailsActions';
import JurisdictionDetailsStore from 'stores/system-setup/masterSettings/jurisdiction/JurisdictionDetailsStore';

export interface PJurisdictionZipCodesGrid {
  isNew?: boolean;
  id?: any;
}

export class SJurisdictionZipCodesGrid {
  zipCodeList?: any = null;
  zipDictionaryModal?: any = null;
}

export class JurisdictionZipCodesGrid extends React.Component<
  PJurisdictionZipCodesGrid,
  SJurisdictionZipCodesGrid
> {
  static getStores() {
    return [JurisdictionDetailsStore];
  }

  static calculateState(prevState: PJurisdictionZipCodesGrid) {
    return {
      zipCodeList: JurisdictionDetailsStore.getState().zipCodeList,
      zipDictionaryModal:
        JurisdictionDetailsStore.getState().zipDictionaryModal,
    };
  }

  componentDidMount() {
    if (!this.props.isNew) {
      this.loadZipCodes();
    } else {
      JurisdictionDetailsActions.toggleZipCodesLoading(false);
    }
  }

  componentWillUnmount() {
    JurisdictionDetailsActions.clearZipCodeList();
    JurisdictionDetailsActions.clearZipCodeRangeList();
  }

  render() {
    const { zipCodeList } = this.state;
    const filter = zipCodeList.filter;

    return (
      <Fieldset className="row">
        {!this.props.isNew ? (
          <Fieldset className="col-md-4 col-sm-4">
            <Text
              name="zip"
              label="Zip"
              value={filter.model.zip}
              errorMessages={filter.errors.zip}
              onSetValue={this.updateZipCodeFilter.bind(this)}
            />
          </Fieldset>
        ) : null}
        <Grid
          id="jurisdictionZipCodeList"
          onAjax={zipCodeList.onAjax}
          columns={this.getZipCodeListColumns()}
          disablePagination={this.props.isNew}
          dataSource={zipCodeList.dataSource}
          dataSourceCount={zipCodeList.dataSourceCount}
          pagination={zipCodeList.pagination}
          onPaginationChange={(o) => {
            JurisdictionDetailsActions.changeZipCodeListPagination(
              o,
              this.props.id
            );
          }}
          gridControlPanel={
            <GridControlButton
              title="Add"
              onClick={() =>
                JurisdictionDetailsActions.toggleZipCodeDictionaryModal(true)
              }
            />
          }
        />
        {zipCodeList.deleteZipCodeConfirm.isShown && (
          <DialogConfirm
            onCancel={this.handleCloseDialogDeleteConfirm}
            onApprove={this.onDeleteZipCodeConfirm}>
            Are you sure you want to delete?
          </DialogConfirm>
        )}
        {this.state.zipDictionaryModal.isShown && (
          <DialogZipCodeList
            onChoose={this.onZipCodeChoose}
            onCancel={() => {
              JurisdictionDetailsActions.toggleZipCodeDictionaryModal(false);
            }}
          />
        )}
      </Fieldset>
    );
  }

  updateZipCodeFilter(name: any, value: any, errorMessages: any) {
    JurisdictionDetailsActions.updateZipCodeFilter(
      name,
      value,
      errorMessages,
      this.props.id
    );
  }

  getZipCodeListColumns() {
    return [
      { name: 'zip', title: 'Zip' },
      {
        name: 'action',
        title: 'Action',
        className: 'width-75',
        render: (value: any, { zip }: any) => (
          <IconButton
            className="text-primary"
            onClick={(event: any) => {
              event.preventDefault();
              JurisdictionDetailsActions.toggleZipCodeDeleteConfirm(true, zip);
            }}>
            <i className="bi bi-trash" />
          </IconButton>
        ),
      },
    ];
  }

  handleCloseDialogDeleteConfirm = () => {
    JurisdictionDetailsActions.toggleZipCodeDeleteConfirm(false);
  };

  onDeleteZipCodeConfirm = () => {
    const zip = this.state.zipCodeList.deleteZipCodeConfirm.id;
    if (this.props.isNew) {
      JurisdictionDetailsActions.deleteJurisdictionZipCodeLocaly(zip);
      this.handleCloseDialogDeleteConfirm();
    } else
      JurisdictionDetailsActions.deleteJurisdictionZipCode(
        this.props.id,
        zip
      ).then((response) => {
        if (response === 'S') {
          Notification.success('Successfully deleted!');
          this.loadZipCodes();
        } else {
          Notification.danger('An error occurred!');
        }
        this.handleCloseDialogDeleteConfirm();
      });
  };

  loadZipCodes() {
    const list = this.state.zipCodeList;
    const pg = list.pagination;
    JurisdictionDetailsActions.loadJurisdictionZipCodes(
      this.props.id,
      list.filter.model,
      { skip: pg.skip, limit: pg.pageSize }
    );
  }

  onZipCodeChoose = (zipCode: any) => {
    if (this.props.isNew) {
      let zips = this.state.zipCodeList.dataSource;
      if (!zips.some(({ zip }: any) => zip === zipCode.zip)) {
        JurisdictionDetailsActions.addJurisdictionZipCodeLocaly(zipCode['zip']);
        JurisdictionDetailsActions.toggleZipCodeDictionaryModal(false);
      } else {
        Notification.danger('This ZIP code already exists!');
      }
    } else {
      JurisdictionDetailsActions.jurisdictionZipCodeExists(
        this.props.id,
        zipCode['zip']
      ).then((exists) => {
        if (!exists) {
          JurisdictionDetailsActions.addJurisdictionZipCode(
            this.props.id,
            zipCode['zip']
          ).then((response) => {
            if (response === 'S') {
              Notification.success('Successfully added!');
              this.loadZipCodes();
            } else Notification.danger('An error occurred!');
            JurisdictionDetailsActions.toggleZipCodeDictionaryModal(false);
          });
        } else {
          Notification.danger('This ZIP code already exists!');
        }
      });
    }
  };
}

const JurisdictionZipCodesGridContainer = Container.create(
  JurisdictionZipCodesGrid
);
export default JurisdictionZipCodesGridContainer;
