import SupOneDay, {
  PropsType as DayPropsType,
} from 'page/workflow/order/SupOneDay';

interface PropsType extends DayPropsType {
  text?: string;
}

const EndDayLabel = ({ text = 'End (HH:MM)', start, end }: PropsType) => (
  <div>
    {text}
    <SupOneDay start={start} end={end} />
  </div>
);

export default EndDayLabel;
