import appDispatcher from 'dispatcher/AppDispatcher';

import PhysicianExamCountPageService from 'services/reports/report-creator/physician-exam-count/PhysicianExamCountPageService';
import { Actions } from 'stores/reports/report-creator/physician-exam-count/PhysicianExamCountPageStore';

export default class PhysicianExamCountPageActions {
  static loadPhysicianExamCountList(conditionList, joint) {
    return PhysicianExamCountPageService.loadPhysicianExamCountList(
      conditionList,
      joint
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_PHYSICIAN_EXAM_COUNT_LIST,
        data: response,
      });
    });
  }

  static createPdf(physicianExamCountList, filterString) {
    return PhysicianExamCountPageService.createPdf(
      physicianExamCountList,
      filterString
    );
  }
}
