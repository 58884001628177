import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import FilterNameAndStatus, {
  FilterType,
} from 'page/components/FilterNameAndStatus';
import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import DialogServicesNotes from '../components/DialogServicesNotes';

import { storeGlove } from 'stores/_mobx/workflow/equipmentInventory/glove';
import { EI_GLOVE as PAGE_ID } from 'constant/pagesId/workflow';
import {
  URL_EQUIPMENT_INVENTORY,
  URL_GLOW_ADD,
  URL_GLOW_EDIT,
} from 'constant/path/workflow';

const GlovesListPage = () => {
  const gridRef = useRef(null);

  const {
    fetching,
    gloveForDelete,
    glovesList,
    glovesTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeGlove;

  const [selected, setSelected] = useState<number[]>([]);

  const [noteId, setNoteId] = useState<number>(0);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const columns = useMemo(
    () => [
      { name: 'glove_number', title: 'Glove Number' },
      { name: 'last_survey', title: 'Last survey date' },
      { name: 'next_survey', title: 'Next survey date' },
      { name: 'description', title: 'Description' },
      {
        name: 'refid',
        title: 'History',
        render: (id: number) => (
          <div className="control">
            <IconButton onClick={() => setNoteId(id)}>
              <i className="icon icon-notes" />
            </IconButton>
          </div>
        ),
      },
      {
        name: 'refid',
        title: 'Actions',
        render: (id: number) => (
          <div className="control">
            <Link
              className="bi bi-pencil"
              to={URL_GLOW_EDIT.replace(':id', String(id))}
            />
            <IconButton
              onClick={() => {
                storeGlove.setGloveForDelete(id);
              }}>
              <i className="bi bi-trash" />
            </IconButton>
          </div>
        ),
        className: 'width-100',
      },
    ],
    []
  );

  const fetchData = () => {
    storeGlove.getGlovesListMain();
    if (selected) gridRef.current?.clearSelection();
  };

  const handleConfirmDelete = () => {
    storeGlove.deleteGlove().then((result) => {
      if (result) {
        fetchData();
      }
    });
  };

  const handleChangeStatus = () => {
    const statusMsg = filter.options.isActive ? 'deactivated' : 'activated';

    storeGlove.changeGloveStatus(selected, statusMsg).then((result) => {
      if (result) fetchData();
    });
  };

  const handleChangeFilter = (newFilter: FilterType) => {
    filter.setOptions(newFilter);
    debouncedMethod();
  };

  const handleCloseNotes = () => {
    setNoteId(0);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Glove">
        <Link to={URL_EQUIPMENT_INVENTORY} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <FilterNameAndStatus
        placeholder="Glove Name"
        filter={filter.options}
        fetching={fetching}
        onChangeFilter={handleChangeFilter}
      />

      <Grid
        id="glove_grid"
        selectId="refid"
        ref={gridRef}
        columns={columns}
        onAjax={fetching}
        dataSource={glovesList}
        dataSourceCount={glovesTotal}
        pagination={pagination}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        gridControlPanel={
          <>
            <GridControlButton title="Add" url={URL_GLOW_ADD} />
            <GridControlButton
              title={filter.options.isActive ? 'Deactivate' : 'Activate'}
              onClick={handleChangeStatus}
              disabled={!selected.length}
            />
          </>
        }
      />

      {gloveForDelete ? (
        <DialogConfirm
          onCancel={storeGlove.clearGloveForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this Glove?
        </DialogConfirm>
      ) : null}

      {noteId ? (
        <DialogServicesNotes type="GL" id={noteId} onClose={handleCloseNotes} />
      ) : null}
    </>
  );
};

export default observer(GlovesListPage);
