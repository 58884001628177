import { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { PureTextEditor } from 'components/form/richTextEditor';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { SpinnerFixed } from 'components/spinner';

import { storeTermsAndConditions } from 'stores/_mobx/systemSetup/masterSetting/termsAndConditions';
import { MS_TERMS_CONDITIONS as PAGE_ID } from 'constant/pagesId/systemSetup';
import { URL_MASTER_SETTINGS } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const TermsConditionsPage = ({ history }: PropsType) => {
  const { fetching, terms } = storeTermsAndConditions;

  const [termsAndConditions, setTerms] = useState<string>(terms);

  const isDirty = termsAndConditions !== terms;

  const handleReset = () => {
    setTerms(terms);
  };

  const handleSubmit = () => {
    storeTermsAndConditions.saveTerms(termsAndConditions).then((isSucceed) => {
      if (isSucceed) history.push(URL_MASTER_SETTINGS);
    });
  };

  useEffect(() => {
    setTerms(terms);
  }, [terms]);

  useEffect(() => {
    if (!fetching) storeTermsAndConditions.getTerms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Terms and Conditions" />
      <PureTextEditor value={termsAndConditions} onChange={setTerms} />
      <ControlsLayout alignX="right">
        <Button
          type="submit"
          text="Submit"
          disabled={fetching || !isDirty}
          onClick={handleSubmit}
        />
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching || !isDirty}
          onClick={handleReset}
        />
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </ControlsLayout>
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(TermsConditionsPage);
