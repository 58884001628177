import AbstractChart, {
  PAbstractChart,
  SAbstractChart,
} from 'components/project/common/charts/AbstractChart';

export default class AreaChart extends AbstractChart<
  PAbstractChart,
  SAbstractChart
> {
  prepareCache() {
    this.cache = { ...this.props };
    if (!this.cache.options) {
      this.cache.options = {};
    }
    const data = this.props.data;
    if (!data || !data.length) {
      throw Error(
        "Data must be defined, at lease first element of data. This element should be array with data description in following format - ['dataDescription', 'valueDescription1' ... 'valueDescriptionN']"
      );
    }
    for (let i = 1; i < data.length; i++) {
      const item = data[i];
      if (typeof item[1] !== 'number') {
        throw Error('Second element of data array must be a number');
      }
    }
  }

  getChartType() {
    return 'AreaChart';
  }
}
