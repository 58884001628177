import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import { TDropDownItem } from 'actions/project/DropdownActions';

class SPhysicianStore {
  adminOptions: Array<TDropDownItem> = [];
  options: Array<TDropDownItem> = [];
}

class PhysicianStore extends ReduceStore<SPhysicianStore, CommonPayload> {
  getInitialState() {
    return new SPhysicianStore();
  }

  reduce(state: SPhysicianStore, action: CommonPayload): SPhysicianStore {
    switch (action.type) {
      case 'usermaster.ProviderInfo.loadPhysicianAdministrators':
        this.getState().adminOptions = action.data;
        break;
      case 'physician-dropdown':
        this.getState().options = action.data;
        break;
      default:
        break;
    }
    return state;
  }

  areEqual(one: SPhysicianStore, two: SPhysicianStore): boolean {
    return false;
  }

  findOption(id: string) {
    if (!id || id === '0') {
      return null;
    }
    const list = this.getState().options;
    let length = list.length;
    while (length--) {
      if (list[length].value === id) {
        return list[length];
      }
    }
    return null;
  }
}

export default new PhysicianStore(appDispatcher);
