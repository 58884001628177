import React from 'react';
import { Container } from 'flux/utils';

import AbstractOrderOverview, {
  PAbstractOrderOverview,
  SAbstractOrderOverview,
  TFilter,
} from 'page/workflow/order/AbstractOrderOverview';
import OrderOverviewStore from 'stores/workflow/order/OrderOverviewStore';
import { GridControlButton } from 'components/grid';
import UnassignedOrdersStore from 'stores/workflow/order/UnassignedOrdersStore';
import { getDateString } from 'utils/DateUtils';
import UserProfileStore from 'stores/UserProfileStore';
import WorkflowOrderColumnsDescriptor from 'page/workflow/order/WorkflowOrderColumnsDescriptor';
import { storeGrid } from 'stores/_mobx/grid';

export interface PUnassignedOrders extends PAbstractOrderOverview {}

export class SUnassignedOrders extends SAbstractOrderOverview {}

const GRID_ID = 'unassigned_orders_grid';

export class UnassignedOrders extends AbstractOrderOverview<
  PUnassignedOrders,
  SUnassignedOrders
> {
  static TITLE = 'Unassigned Orders';

  static getStores() {
    return [OrderOverviewStore, UnassignedOrdersStore];
  }

  static calculateState(prevState: SUnassignedOrders) {
    if (!prevState) {
      const defaultFilter = {
        ...new TFilter(),
        period: 'TD',
        dosStart: getDateString(),
        dosEnd: getDateString(),
      };

      prevState = AbstractOrderOverview.getDefaultState(GRID_ID);
      prevState.filter = storeGrid.getFilter(GRID_ID, defaultFilter) as TFilter;
      prevState.unassignOption = false;
      prevState.dosEditable = true;
    }
    return {
      ...prevState,
      dataSource: OrderOverviewStore.getState().dataSource,
      dataSourceCount: OrderOverviewStore.getState().dataSourceCount,
    };
  }

  onReset() {
    const filter = {
      ...new TFilter(),
      period: 'TD',
      dosStart: getDateString(),
      dosEnd: getDateString(),
    };

    this.setState({ filter }, this.updateData);
  }

  getGridId() {
    return GRID_ID;
  }

  getTitle() {
    return UnassignedOrders.TITLE;
  }

  prepareFilter() {
    const filter = super.prepareFilter();
    //filter.signed = 'N';
    return filter;
  }

  getRecordsAccess(): 'M' | 'A' {
    return 'A'; // 'M' or 'A' only
  }

  getShownColumns() {
    return Object.assign(
      [],
      WorkflowOrderColumnsDescriptor.UnassignedOrdersInitial
    );
  }

  getControlPanel = () => {
    const userType = UserProfileStore.getUserType();

    const userIsClient = UserProfileStore.isClientUser();

    const isUserHavePermissionForAssign = !(
      userType === 'CA' ||
      userType === 'N' ||
      userIsClient
    );

    return isUserHavePermissionForAssign ? (
      <GridControlButton
        title="Assign"
        disabled={!this.state.selected.length}
        onClick={this.onAssignOrder.bind(this)}
      />
    ) : null;
  };

  renderOrderStatus(): React.ReactNode {
    return null;
  }

  getAllowedColumns() {
    let out: Array<string> = Object.assign(
      [],
      WorkflowOrderColumnsDescriptor.UnassignedOrdersDefault
    );
    if (UserProfileStore.isClientUser()) {
      out = out.filter((v) => v !== 'assign');
    }
    return out;
  }
}

const container = Container.create(UnassignedOrders);
export default container;
