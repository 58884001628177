import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import DialogOfCreatingPhysician, {
  PhysicianFormType,
} from 'page/clinician-manager/physicians/DialogOfCreatingPhysician';
import RadioOptions from 'components/project/common/RadioOptions';
import { DropdownIntegration } from 'components/project/dropdown/SendingApplicationDropdown';
import DropdownPhysicianGroupAdmin from 'components/project/dropdown/PhysicianGroupAdmin';
import { DropdownRadiology } from 'components/project/dropdown/RadiologyDropdown';
import { ControlsLayout } from 'components/layout';
import { Textarea } from 'components/form/textarea';
import { Checkbox } from 'components/form/checkbox';
import { Radio } from 'components/form/radio';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import MaskInput from 'components/form/maskInput';
import { PhysicianMapper } from 'components/form/twoSideMapper';
import resolver from './validation';

import { PhysicianGroupDetailsType } from 'stores/_mobx/clinicianManager/groupManager';
import { storePhysicianExtendedOption } from 'stores/_mobx/clinicianManager/physicianExtendedOption';

const EmailLabel = () => (
  <>
    Email
    <small className="fw-light ms-2">(Uses "Results Available" template)</small>
  </>
);
interface PropsType {
  fetching: boolean;
  canModifyIntegrationOfGroup: boolean;
  canAssignGroupAdmin: boolean;
  defaultValues: PhysicianGroupDetailsType | null;
  onSubmit: (payload: PhysicianGroupDetailsType) => Promise<any>;
}

const Form = ({
  fetching,
  defaultValues,
  canModifyIntegrationOfGroup,
  canAssignGroupAdmin,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting, isDirty },
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    setError,
  } = useForm<PhysicianGroupDetailsType>({ defaultValues, resolver });

  const [isPhysicianModalVisible, togglePhysicianModal] =
    useState<boolean>(false);

  const isLoading = fetching || isSubmitting;

  const [integrationIds, notificationType, alsoRadiologyGroup, physicians] =
    watch([
      'isIntegrationChecked',
      'notificationType',
      'alsoRadiologyGroup',
      'physicians',
    ]);

  const handleClickAddPhysician = () => {
    togglePhysicianModal((state) => !state);
  };

  const handleRemovePhysicianFromGroup = useCallback(
    (removedPhysicians: PhysicianGroupDetailsType['physicians']) => {
      const admins = getValues('admin');

      const shouldChangeAdmin = removedPhysicians.some(({ user_id }) =>
        admins.includes(user_id)
      );

      if (shouldChangeAdmin) {
        const updatedAdmins = admins.filter((id) =>
          removedPhysicians.some(({ user_id }) => id !== user_id)
        );

        setValue('admin', updatedAdmins);
      }
    },
    [getValues, setValue]
  );

  const handleCreatePhysician = (physicianDetails: PhysicianFormType) => {
    if (
      physicianDetails.participateInGroup?.some(
        (id: number) => id === defaultValues.id
      )
    ) {
      const fullName =
        `${physicianDetails.lastName} ${physicianDetails.firstName}`.trim();

      const physician = {
        value: physicianDetails.id,
        label: fullName,
        groupId: defaultValues.id,
        firstName: physicianDetails.lastName,
        lastName: physicianDetails.firstName,
        middleName: physicianDetails.middleName,
        type: 'D',
        user_id: physicianDetails.userInfoId,
        npi: physicianDetails.licensor.npi,
      };

      setValue('physicians', physicians.concat(physician));
    }
    if (physicianDetails.supervisedGroup === defaultValues.id) {
      const admin = getValues('admin');

      setValue('admin', admin.concat(physicianDetails.userInfoId));
    }
    storePhysicianExtendedOption.getExtendedOptionsMain();
    togglePhysicianModal(false);
  };

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: PhysicianGroupDetailsType) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('groupName', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'isIntegrationChecked' && !formValue.isIntegrationChecked) {
        setValue('integrationIds', []);
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <>
      <form
        className={isLoading ? ' on-ajax' : ''}
        onSubmit={handleClickSubmit}>
        <div className="row">
          {canModifyIntegrationOfGroup && (
            <>
              <div className="col-md-4">
                <label>Integration State</label>
                <Checkbox
                  name="isIntegrationChecked"
                  label="Integrations Active"
                  control={control}
                />
              </div>
              <DropdownIntegration
                name="integrationIds"
                label="Integration Name"
                className="col-md-8 col-lg-4"
                isMulti
                disabled={!integrationIds}
                required={integrationIds}
                control={control}
              />
              <div />
            </>
          )}
          <Input
            name="groupName"
            label="Group Name"
            className="col-md-4"
            required
            control={control}
          />
          <div className="col-md-4">
            <DropdownPhysicianGroupAdmin
              name="admin"
              label="Group Admin"
              isMulti
              disabled={!canAssignGroupAdmin}
              groupId={defaultValues?.id}
              additionalOptions={physicians}
              control={control}
            />
            <small
              style={{ marginTop: '-10px' }}
              className="d-block fw-light mb-4">
              To add a Physician as a Group Admin, the Physician must first be added to the group and visible in the
              Group Physician Grid below. Physician Group Admin users may be added if they are not already assigned as
              Admin to an existing Clinical Group.
            </small>
          </div>

          <Textarea
            name="groupDesc"
            label="Group Description"
            className="col-md-4"
            rows={4}
            resize={false}
            control={control}
          />

          <Radio
            name="notificationType"
            label="Group Notification Type"
            className="col-md-4 part-inline"
            options={RadioOptions.notificationType}
            control={control}
          />
          <Input
            name="email"
            className="col-md-4"
            label={<EmailLabel />}
            disabled={notificationType !== 'E'}
            required={notificationType === 'E'}
            control={control}
          />
          <MaskInput
            name="fax"
            label="Fax"
            format="###-###-####"
            className="col-md-4"
            disabled={notificationType !== 'F'}
            required={notificationType === 'F'}
            control={control}
          />

          <Radio
            name="defaultAffiliation"
            label="Default Affiliation"
            className="part-inline col-md-4"
            options={RadioOptions.yesNo}
            control={control}
          />
          <Radio
            name="alsoRadiologyGroup"
            label="Is Also Radiology Group?"
            className="part-inline col-md-4"
            options={RadioOptions.yesNo}
            control={control}
          />
          <DropdownRadiology
            label="Radiology Group"
            name="radiologyGroupId"
            className="col-md-4"
            disabled={alsoRadiologyGroup !== 'Y'}
            control={control}
          />

          <div className="col-sm-12 my-4">
            <Button onClick={handleClickAddPhysician}>Add Physician</Button>
          </div>

          <PhysicianMapper
            name="physicians"
            className="mb-4"
            isDirty={isDirty}
            control={control}
            // @ts-ignore
            onRemove={handleRemovePhysicianFromGroup}
          />

          <ControlsLayout alignX="right">
            <Button
              text="Reset"
              variant="warning"
              disabled={isLoading}
              onClick={handleClickReset}
            />
            <Button
              text="Save"
              type="submit"
              disabled={!isDirty || isLoading}
            />
          </ControlsLayout>
        </div>
      </form>

      {isPhysicianModalVisible && (
        <DialogOfCreatingPhysician
          memberForGroup={defaultValues.id}
          onCloseDialog={handleClickAddPhysician}
          onCreatePhysician={handleCreatePhysician}
        />
      )}
    </>
  );
};

export default Form;
