import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const generateHash = ({
  radiologistId,
  reportType,
}: {
  radiologistId: any;
  reportType: any;
}) => `${radiologistId}-${reportType}`;

const transcriptionValidation = (isUpdatingMode: boolean) =>
  yup.object().shape({
    radiologyGroupId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? 0 : value))
      .min(1, 'Please select Radiology Group'),
    radiologistId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? 0 : value))
      .min(1, 'Please select Radiologist'),
    reportType: yup
      .string()
      .nullable()
      .required('Please select Report Type')
      .test({
        // @ts-ignore
        test: (reportType, { from, parent, path, createError }) => {
          if (isUpdatingMode || reportType !== 'F') return true;

          const {
            value: { transcriptions },
          } = from[1];

          const finalReports = transcriptions.filter(
            (procedure: any) =>
              procedure.cptSessionNo === parent.cptSessionNo &&
              procedure.reportType === 'F'
          );
          if (finalReports.length > 1) {
            return createError({
              path,
              message: 'A procedure can only have one "Final" report.',
            });
          }
          return true;
        },
      }),
    filePath: yup
      .string()
      .nullable()
      .required('Please upload transcription or add interpretation'),
    interpretationHash: yup.string().test({
      test: (interpretationHash, { parent }) => {
        const { radiologistId, reportType } = parent;

        const hash = generateHash({ radiologistId, reportType });

        return interpretationHash ? interpretationHash === hash : true;
      },
      message:
        'The Report Type or Radiologist has been changed since the Interpretation was added. Please regenerate the Interpretation to ensure accuracy.',
    }),
    flagNeedAddendum: yup.boolean().test({
      // @ts-ignore
      test: (isNeedAddendum, { parent, from, path, createError }) => {
        if (!isNeedAddendum || isUpdatingMode) return true;

        const { transcriptions } = from[1].value;

        const isAddendumAdded = transcriptions.some(
          (el: any) =>
            el.cptSessionNo === parent.cptSessionNo && el.reportType === 'A'
        );

        if (!isAddendumAdded) {
          const parentPath = path.replace(
            '.flagNeedAddendum',
            '.addendumValidation'
          );
          return createError({
            path: parentPath,
            message:
              'This transcription also requires Addendum report. Add another record with Addendum report type.',
          });
        }
        return true;
      },
    }),
  });

const validation = (isUpdating: boolean) =>
  yupResolver(
    yup.object().shape({
      transcriptions: yup.array().of(transcriptionValidation(isUpdating)),
    })
  );

export default validation;
