import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import {
  Grid,
  GridExtraControlPanel,
  ICellRendererParams,
  ColDef,
} from 'components/gridModern';
import { GridControlButton } from 'components/grid';
import { IconButton } from 'components/button';
import useDebounce from 'components/hook/useDebounce';
import Filter from './components/Filter';

import {
  storeIntegrations,
  IntegrationType,
  FilterModel,
} from 'stores/_mobx/systemSetup/masterSetting/integrations';
import {
  URL_INTEGRATION_MASTER_NEW,
  URL_INTEGRATION_MASTER_ADD_NEW,
  URL_MASTER_SETTINGS,
} from 'constant/path/systemSetup';
import DialogConfirm from 'components/modal/dialogConfirm';

const columns: ColDef<IntegrationType>[] = [
  { field: 'name', headerName: 'Name', flex: 1 },
  {
    field: 'externalApplicationName',
    headerName: 'External Application Name',
    flex: 1,
  },
  { field: 'shortName', headerName: 'Short Name' },
  { field: 'timezone', headerName: 'Timezone', width: 150 },
  {
    field: 'id',
    colId: 'action',
    headerName: 'Action',
    headerClass: 'text-center',
    width: 100,
    cellRenderer: ({ value }: ICellRendererParams<IntegrationType, number>) => (
      <div className="controls">
        <Link to={`${URL_INTEGRATION_MASTER_NEW}/${value}`}>
          <i className="bi bi-pencil" />
        </Link>
        <IconButton onClick={() => storeIntegrations.setIdForDelete(value)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const IntegrationsListPage = () => {
  const {
    fetching,
    integrations,
    integrationsTotal,
    idForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeIntegrations;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleChangeFilter = (filter: FilterModel) => {
    storeIntegrations.setFilter(filter);

    debouncedMethod();
  };

  const handleApproveDelete = () => {
    storeIntegrations.deleteIntegration().then((isSucceed) => {
      if (isSucceed) storeIntegrations.getIntegrations();
    });
  };

  useEffect(() => {
    storeIntegrations.getIntegrations();
  }, [pagination]);

  useEffect(() => {
    return storeIntegrations.clearIdForDelete;
  }, []);

  return (
    <>
      <LayoutSideTitle title="Integrations">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <Filter onFilterChange={handleChangeFilter} />

      <GridExtraControlPanel>
        <GridControlButton title="Add" url={URL_INTEGRATION_MASTER_ADD_NEW} />
      </GridExtraControlPanel>
      <Grid
        gridId={storeIntegrations.gridId}
        columnDefs={columns}
        isLoading={fetching}
        rowData={integrations}
        totalCount={integrationsTotal}
        paginationSettings={pagination}
        onPaginationChange={setPagination}
      />

      {idForDelete ? (
        <DialogConfirm
          onCancel={storeIntegrations.clearIdForDelete}
          onApprove={handleApproveDelete}>
          Are you sure you want to delete this Integration?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(IntegrationsListPage);
