import { makeObservable, action, observable } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import AccessUtils from 'utils/AccessUtils';
import { FEE_SCHEDULE_FACILITY as PAGE_ID } from 'constant/pagesId/systemSetup';
import Notification from 'components/modal/Notification';
import { storeImport } from 'stores/_mobx/import';

const prettierSchedule = (
  schedule: ScheduleItemResponseType[]
): ScheduleItemType[] =>
  schedule.map((el) => ({
    ...el,
    groupid: Number(el.groupid) || 0,
    editId:
      el.cpt_status === 'C' || el.cpt_status === 'S'
        ? Number(el.corpid)
        : el.cpt_status === 'F'
        ? Number(el.refid)
        : 0,
    refid: Number(el.refid),
    deldate: el.deldate || '',
    filename: el.filename || '',
    cpt_status: el.cpt_status || ('' as ''),
  }));

const defaultPermissionValue: PermissionType = {
  importAction: false,
  archive: false,
  edit: false,
};

export const filterDefaultValue: FilterType = {
  stateId: 0,
  regionId: 0,
  jurisdictionId: 0,
  description: '',
  facilityName: '',
  status: 'active',
  isActive: true,
};

interface PermissionType {
  importAction: boolean;
  archive: boolean;
  edit: boolean;
}

export interface FilterType {
  stateId: number;
  jurisdictionId: number;
  regionId: number;
  description: string;
  facilityName: string;
  status: 'active' | 'archive';
  isActive: boolean;
}

export interface ImportPayloadType {
  from: string;
  to: string;
  jurisdictionId: number;
  destinationId: number;
  file: File | '';
}

interface ScheduleItemResponseType {
  active_end: string;
  active_on: string;
  area: string;
  cpt_status?: 'F' | 'S' | 'C';
  date: string;
  deldate: string | null;
  filename: string;
  groupid?: string;
  corpid?: string;
  refid: string;
  statename: string;
  status: string;
}

export interface ScheduleItemType
  extends Omit<
    ScheduleItemResponseType,
    'cpt_status' | 'groupid' | 'corpid' | 'refid' | 'deldate'
  > {
  cpt_status: 'F' | 'S' | 'C' | '';
  deldate: string;
  groupid: number;
  editId: number;
  refid: number;
}

class FacilityFeeSchedule {
  fetching: boolean = false;
  feeSchedulesList: ScheduleItemType[] = [];
  feeSchedulesTotal: number = 0;
  filter: FilterType = filterDefaultValue;
  permission: PermissionType = defaultPermissionValue;

  page: Pagination = new Pagination({
    id: 'facility_fee_schedule',
    clearOnUnmount: true,
  });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      feeSchedulesList: observable,
      feeSchedulesTotal: observable,
      filter: observable,
      permission: observable,

      setFetching: action,
      setFeeSchedulesList: action,
      setFeeSchedulesTotal: action,
      setPermission: action,
      setFilter: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFeeSchedulesList(list: ScheduleItemType[]) {
    this.feeSchedulesList = list;
  }

  setFeeSchedulesTotal(count: number) {
    this.feeSchedulesTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = {
      ...filter,
      isActive: filter.status === 'active',
    };
  }

  setPermission(permission: PermissionType) {
    this.permission = permission;
  }

  async getFeeSchedulesList(filter: FilterType) {
    const { pagination } = this.page;
    const data = {
      facilityId: 0,
      status: filter.isActive ? 'A' : 'R',
      description: filter.jurisdictionId,
      startIndex: pagination.skip,
      limit: pagination.pageSize,
      descriptionText: filter.description,
      txtSearch: filter.facilityName,
      cmbState: filter.stateId,
      cmbRegion: filter.regionId,
      txtlastname: '',
      txtfirstname: '',
      txtcon: '',
    };

    try {
      const response = await apiRequest<ScheduleItemResponseType[]>({
        url: 'codemaster.FacilityCPTCode.NewFacilityList',
        data,
      });

      const list = prettierSchedule(response);

      this.setFeeSchedulesList(list);
    } catch (e: any) {
      this.setFeeSchedulesList([]);
    }
  }

  async getFeeSchedulesTotal(filter: FilterType) {
    const data = {
      facilityId: 0,
      status: filter.isActive ? 'A' : 'R',
      description: filter.jurisdictionId,
      descriptionText: filter.description,
      txtSearch: filter.facilityName,
      cmbState: filter.stateId,
      cmbRegion: filter.regionId,
      txtlastname: '',
      txtfirstname: '',
      txtcon: '',
    };

    try {
      const count = await apiRequest<number>({
        url: 'codemaster.FacilityCPTCode.NewFacilityCPTListCount',
        data,
      });

      this.setFeeSchedulesTotal(count);
    } catch (e: any) {
      this.setFeeSchedulesTotal(0);
    }
  }

  getFeeSchedulesListMain = (filter: FilterType = this.filter) => {
    this.setFetching(true);

    const promiseList = this.getFeeSchedulesList(filter);

    const promiseCount = this.getFeeSchedulesTotal(filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  checkPermission() {
    const importAction = AccessUtils.checkAccess(PAGE_ID.IMPORT);

    const archive = AccessUtils.checkAccess(PAGE_ID.INACTIVE);

    const edit = AccessUtils.checkAccess(PAGE_ID.EDIT);

    this.setPermission({
      importAction,
      archive,
      edit,
    });
  }

  async importFeeSchedule(payload: ImportPayloadType) {
    try {
      const fileName = await storeImport.uploadFile({
        url: 'temppath/tempImportUpload.php',
        file: payload.file || null,
      });

      const response = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'codemaster.FacilityCPTCode.ImportCPTFCode',
        data: [
          fileName,
          payload.destinationId,
          payload.jurisdictionId,
          payload.from,
          payload.to,
          0, // payload.groupId,
        ],
      });
      if (response === 'S') {
        Notification.success('CPT code imported');
        return true;
      }
      if (response === 'E') {
        Notification.danger(
          "Can't import CPT code, because such file already exist."
        );
        return false;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Error occurred!');
    }
  }
}

export const storeFacilityFeeSchedule = new FacilityFeeSchedule();
