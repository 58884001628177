import React from 'react';
import { Container } from 'flux/utils';

import UserSelection from 'components/project/users/UserSelection';
import Notification from 'components/modal/Notification';
import { Button, IconButton } from 'components/button';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import BriefcasePageActions from 'actions/profile/personal/BriefcasePageActions';
import BriefcasePageStore from 'stores/profile/BriefcasePageStore';

export interface PUserSharing {
  file: { refid: number };
  showUserSelection: (event: React.MouseEvent) => void;
  onSubmit: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export class SUserSharing {
  file: { refid: number; type: string; image: string; title: string };
  sharingUsers: Array<any> = [];
  showUserSelection: boolean = false;
}

class UserSharing extends React.Component<PUserSharing, SUserSharing> {
  static getStores() {
    return [BriefcasePageStore];
  }

  static calculateState(prevState: SUserSharing, props: PUserSharing) {
    if (!prevState) {
      prevState = Object.assign({
        file: props.file,
        sharingUsers: [],
        showUserSelection: false,
      });
    }
    return Object.assign(prevState, BriefcasePageStore.getState());
  }

  componentDidMount() {
    this.updateData();
  }

  updateData() {
    return BriefcasePageActions.getListOfSharingUsers(this.props.file.refid);
  }

  componentWillUnmount() {
    BriefcasePageActions.clearListOfSharingUsers();
  }

  render() {
    const { file } = this.state;
    return (
      <>
        <Dialog size="large" handleClose={this.onModalCallback}>
          <DialogHeader
            title="Sharing Options"
            onClose={this.onModalCallback}
          />
          <DialogBody>
            <div className="shredUserList">
              <img
                src={`assets/images/filetypes/small-${
                  file.type === 'D' ? 'folder.png' : file.image
                }`}
                style={{ marginRight: '15px' }}
                alt={file.title}
              />
              {file.title}
              <ul className="mb-4">{this.getListOfUsers()}</ul>
              <Button text="Add users" onClick={this.addSharingPerson} />
            </div>
          </DialogBody>
        </Dialog>
        {this.state.showUserSelection ? (
          <UserSelection
            isOpen
            onSubmit={(e, result) => this.onUserSelect(e, result)}
            onCancel={() => this.onUserSelect(undefined, null)}
          />
        ) : null}
      </>
    );
  }

  onUserSelect(event: React.MouseEvent<HTMLAnchorElement>, result: any) {
    alert('This is unimplemented!');
  }

  getListOfUsers() {
    let sharingUsers = this.state.sharingUsers;
    let usersRes = [];
    for (let i = 0; i < sharingUsers.length; i++) {
      let userId = sharingUsers[i].refid;
      let fileId = this.state.file.refid;
      usersRes.push(
        <li className="d-flex align-items-center gap-3">
          <span>{`${sharingUsers[i].name} (${sharingUsers[i].userType})`}</span>

          <IconButton
            className="text-secondary"
            onClick={(e) => this.removeSharingPerson(e, fileId, userId)}>
            <i className="bi bi-x-circle" />
          </IconButton>
        </li>
      );
    }
    return usersRes;
  }

  onModalCallback = () => {
    this.props.onSubmit(null);
  };

  removeSharingPerson(event: any, fileId: number, userId: number) {
    if (event) {
      event.preventDefault();
    }
    BriefcasePageActions.removeSharingUser(fileId, userId).then((response) => {
      if (response === 'S') {
        Notification.success('User removed from list.');
      } else {
        Notification.danger(
          "Can't remove user, probably you have no access to file."
        );
      }
      this.updateData();
    });
  }

  addSharingPerson = (event: React.MouseEvent) => {
    event.preventDefault();
    this.props.showUserSelection(event);
  };
}

const container = Container.create(UserSharing, { withProps: true });
export default container;
