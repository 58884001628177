import { useForm } from 'react-hook-form';

import { Input, InputPassword } from 'components/form/textField';
import { Button } from 'components/button';
import { validationLogin as resolver } from './validation';

const defaultValues = {
  username: '',
  password: '',
};

export interface FormModel {
  username: string;
  password: string;
}

interface PropsType {
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormLogin = ({ onSubmit }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const formCn = `login-form${isSubmitting ? ' on-ajax' : ''}`;

  return (
    <form className={formCn} onSubmit={handleSubmit(onSubmit)}>
      <div className="login-form-body">
        <Input
          name="username"
          label="Your Login"
          required
          autoFocus
          isClearable={false}
          control={control}
        />

        <InputPassword
          name="password"
          label="Your Password"
          required
          control={control}
        />
      </div>

      <Button
        type="submit"
        text="Login"
        fullWidth
        disabled={!isDirty || isSubmitting}
      />
    </form>
  );
};

export default FormLogin;
