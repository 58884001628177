import appDispatcher from 'dispatcher/AppDispatcher';
import InvoicesService, {
  TOrderNewPayItem,
} from 'services/billing-coding/InvoicesService';
import FacilityPreviewService from 'services/billing-coding/FacilityPreviewService';

export class TOrderNewPayItemUpdated extends TOrderNewPayItem {
  dsIndex: number = null;
  modifiedOrderId: string = '';
}

export default class InvoicesActions {
  static clearNewPayList() {
    appDispatcher.dispatch({
      type: 'facility.FacilityBilling.OrderNewPayList',
      data: [],
    });
  }

  static orderNewPayList(
    invoiceNo: number,
    facilityId: number,
    flag: 'P' | string
  ) {
    return InvoicesService.orderNewPayList(invoiceNo, facilityId, flag).then(
      (response: Array<TOrderNewPayItem>) => {
        const result: Array<TOrderNewPayItemUpdated> = [];
        if (response && response.length) {
          for (let i = 0; i < response.length; i++) {
            const item: TOrderNewPayItemUpdated = Object.assign(
              new TOrderNewPayItemUpdated(),
              response[i]
            );
            item.pay_mode = Number(item.pay_mode);
            item.dsIndex = i;
            item.adjustment_code = parseInt('' + item.adjustment_code);
            item.modifiedOrderId =
              item.orderId + '_' + (item.hasLog ? '1' : '0'); //hack for grouped columns
            result.push(item);
          }
        }
        appDispatcher.dispatch({
          type: 'facility.FacilityBilling.OrderNewPayList',
          data: result,
        });
        return result;
      }
    );
  }

  static getFacilityDetailPreinvoice(facilityId: number, invoiceNo: number) {
    return FacilityPreviewService.loadFacilityData({
      facilityId,
      invoiceNo,
    }).then((r) => {
      appDispatcher.dispatch({
        type: 'charge-posting-facility.FacilityBilling.Getfacilitydetail_preinvoice',
        data: r && r[0] ? r[0] : {},
      });
      return r;
    });
  }

  static saveInvoiceAddress(
    facilityId: number,
    address1: string,
    address2: string,
    city1: string,
    city2: string,
    state1: string,
    state2: string,
    zipcode1: string,
    zipcode2: string,
    addressflag: 'F' | 'C' | 'M' | 'O' | '',
    invoiceno: number
  ) {
    return InvoicesService.saveInvoiceAddress(
      facilityId,
      address1,
      address2,
      city1,
      city2,
      state1,
      state2,
      zipcode1,
      zipcode2,
      addressflag,
      invoiceno
    );
  }
}
