import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MutableRefObject,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  SelectionChangedEvent,
  ColumnState,
  ColumnMovedEvent,
  ColumnPinnedEvent,
} from 'ag-grid-community';

import { TPagination } from 'components/grid/GridTypes';
import Pagination from 'components/grid/Pagination';
import { Spinner } from 'components/spinner';
import { useDebounce } from 'components/hook';
import DialogColumnStateVisibility from './DialogColumnStateVisibility';
import GridControlPanel from './GridControlPanel';
import useWorkspaceHeight from './useWorkspaceHeight';
import { getSortOptions } from './helpers';
import { GridPropsType } from './types';

import { storeGrid } from 'stores/_mobx/grid';
import { getPagination } from 'utils/getPagination';

const containerStyleSettings = {
  width: '100%',
  height: '100%',
};
const allowedSources = ['uiColumnResized', 'uiColumnMoved', 'uiColumnSorted'];

const allowedTypes = ['sortChanged', 'columnVisible'];

const gridOptions = {
  animateRows: true,
  suppressRowClickSelection: true,
  pagination: false,
  suppressPaginationPanel: true,
  enableCellTextSelection: true,
  rowSelection: 'multiple' as 'multiple',
  // TODO: uncommit next line when bug for sorting by multi-columns will be fixed on BE
  // multiSortKey: 'ctrl' as 'ctrl',
  overlayNoRowsTemplate:
    '<span class="ag-overlay-no-rows-center">No Data To Show</span>',
};

export const defaultColumnSettings = {
  sortable: true,
  editable: false,
  resizable: true,
  autoHeaderHeight: true,
  wrapHeaderText: true,
  showDisabledCheckboxes: true,
  width: 120,
};

const PureGrid = forwardRef(
  <T extends Record<string, any>>(
    {
      id,
      isLoading = false,
      paginationSettings,
      totalCount,
      selectByField,
      defaultColDef,
      rowData,
      gridContainerHeight, // 645 - height of 14 rows
      onSelectChange,
      onPaginationChange,
      onRefreshData,
      onChangeSort,
      ...rest
    }: GridPropsType<T>,
    ref?: any
  ) => {
    const innerRef = useRef<AgGridReact<T>>();

    const gridWrapperRef = useRef<HTMLDivElement>();

    const timer = useRef<NodeJS.Timeout>();

    const pageSettings = useRef<TPagination>(
      paginationSettings || getPagination()
    );

    pageSettings.current = paginationSettings || pageSettings.current;

    const gridRef: MutableRefObject<AgGridReact<T>> = ref || innerRef;

    const [columnState, setColumnState] = useState<ColumnState[] | null>(null);

    const [isInit, setInitStatus] = useState<boolean>(false);

    const defaultColSettings = useMemo(
      () =>
        onChangeSort
          ? {
              ...defaultColumnSettings,
              ...defaultColDef,
              comparator: () => 0,
            }
          : defaultColDef,
      [defaultColDef, onChangeSort]
    );

    const workSpaceHeight = useWorkspaceHeight({
      defaultHeight: gridContainerHeight,
      gridWrapperRef,
    });

    const isMac = useMemo(
      () => window.navigator.userAgent.includes('Mac OS X'),
      []
    );

    const handleSaveStateOfColumns = useCallback(() => {
      if (id) {
        const columnState = gridRef.current.columnApi.getColumnState();

        const { pageSize } = pageSettings.current;

        storeGrid.saveGridState({
          gridId: id,
          pageSize,
          columnState,
        });
      }
    }, [id, gridRef]);

    const debouncedSaveColumnsState = useDebounce(
      handleSaveStateOfColumns,
      500
    );

    const debouncedOnChangeSort = useDebounce(onChangeSort, 500);

    const handleRowChecked = useCallback(
      ({ api }: SelectionChangedEvent<T>) => {
        if (onSelectChange) {
          const selectedRows = api.getSelectedRows();

          const selected = selectByField
            ? selectedRows.map((data) =>
                (selectByField as string)
                  .split('.')
                  .reduce((prev, key) => prev?.[key], data)
              )
            : selectedRows;

          onSelectChange(selected);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const handleChangePagination = (page: TPagination) => {
      if (onPaginationChange) {
        onPaginationChange(page);
      }
      if (onSelectChange) {
        onSelectChange([]);
      }
      if (pageSettings.current.pageSize !== page.pageSize) {
        pageSettings.current = page;
        handleSaveStateOfColumns();
      }
    };

    const handleClickResetColumnSettings = useCallback(() => {
      gridRef.current.columnApi.resetColumnState();

      handleChangePagination(getPagination());

      if (id) storeGrid.deleteGridSetting(id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleClickRefreshData = () => {
      if (isLoading) return;

      if (onRefreshData) {
        onRefreshData();
      } else {
        handleChangePagination({ ...paginationSettings });
      }
    };

    const handleClickColumnVisibilitySettings = () => {
      const columnState = gridRef.current.columnApi.getColumnState();

      setColumnState(columnState);
    };

    const handleCloseDialogColumnVisibility = () => {
      setColumnState(null);
      handleSaveStateOfColumns();
    };

    const handleInitGrid = useCallback(() => {
      const state = id
        ? storeGrid.getSetting<{ column_drag_order: ColumnState[] }>(id)
        : null;

      const sortingModel = getSortOptions(state?.column_drag_order || []);
      if (onChangeSort) onChangeSort(sortingModel);

      if (state) {
        gridRef.current.columnApi.applyColumnState({
          state: state.column_drag_order,
          applyOrder: true,
        });
      }

      timer.current = setTimeout(() => setInitStatus(true), 200);
    }, [id, gridRef, onChangeSort]);

    const handleSortChanged = (params: ColumnMovedEvent<T>) => {
      handleChangeColumnState(params);

      if (!onChangeSort || !isInit) return;

      const columnsState = params.columnApi.getColumnState();

      const sortModel = getSortOptions(columnsState);

      if (isInit) {
        debouncedOnChangeSort(sortModel);
      }
    };

    const handleChangeColumnState = ({
      source,
      finished,
      type,
    }: ColumnMovedEvent<T>) => {
      const isAllowedSource = allowedSources.includes(source);

      const isEventFinished = finished || allowedTypes.includes(type);
      if (isAllowedSource && isEventFinished) {
        debouncedSaveColumnsState();
      }
    };

    const handleChangePinnedColumn = ({ source }: ColumnPinnedEvent<T>) => {
      if (source === 'uiColumnDragged') {
        debouncedSaveColumnsState();
      }
    };

    useEffect(() => {
      const shouldSetFixedHeight = rowData.length > (workSpaceHeight - 49) / 42;

      const domLayout = shouldSetFixedHeight ? 'normal' : 'autoHeight';

      const height = shouldSetFixedHeight ? `${workSpaceHeight}px` : '100%';

      gridRef.current?.api?.setDomLayout(domLayout);

      gridWrapperRef.current!.style.height = height;
    }, [rowData.length, workSpaceHeight, gridRef]);

    useEffect(() => {
      if (!gridRef.current?.api) return;

      if (isLoading) {
        gridRef.current.api.showLoadingOverlay();
      } else if (!rowData.length) {
        setTimeout(() => gridRef.current.api.showNoRowsOverlay());
      } else {
        gridRef.current.api.hideOverlay();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, rowData, gridRef.current?.api]);

    useEffect(() => {
      if (isInit) handleInitGrid();

      return () => clearTimeout(timer.current);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
      <>
        <div className="mb-5">
          <GridControlPanel
            enableRefreshButton={Boolean(paginationSettings || onRefreshData)}
            onClickRefreshData={handleClickRefreshData}
            onClickColumnVisibilitySettings={
              handleClickColumnVisibilitySettings
            }
            onClickResetColumnVisibilitySettings={
              handleClickResetColumnSettings
            }
          />

          <div className={isInit ? undefined : 'ag-grit-initializing'}>
            <div
              ref={gridWrapperRef}
              style={containerStyleSettings}
              className={`ag-theme-alpine${isMac ? '' : ' custom-scrollbar'}`}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                defaultColDef={defaultColSettings}
                loadingOverlayComponent={Spinner}
                onSelectionChanged={handleRowChecked}
                onGridReady={handleInitGrid}
                onColumnMoved={handleChangeColumnState}
                onColumnVisible={handleChangeColumnState}
                onColumnResized={handleChangeColumnState}
                onColumnPinned={handleChangePinnedColumn}
                onSortChanged={handleSortChanged}
                {...gridOptions}
                {...rest}
              />
            </div>
          </div>

          <div className="row align-items-center justify-content-between">
            <div className="col-sm-auto" />
            {paginationSettings ? (
              <div className="col-sm-auto">
                <Pagination
                  totalCount={totalCount}
                  pagination={paginationSettings}
                  onChangePageSettings={handleChangePagination}
                />
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>

        {columnState && (
          <DialogColumnStateVisibility
            columnState={columnState}
            columnDefs={rest.columnDefs}
            ref={gridRef}
            onClose={handleCloseDialogColumnVisibility}
          />
        )}
      </>
    );
  }
);

export default PureGrid as <T extends Record<string, any>>(
  props: GridPropsType<T> & { ref?: MutableRefObject<AgGridReact<T>> }
) => JSX.Element;
