import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Textarea } from 'components/form/textarea';
import { Input } from 'components/form/textField';
import { DropdownPayerName } from 'components/project/dropdown/PayerNameDropdown';
import resolver from './validation';

const initialState: FormModel = {
  payerid: null,
  planname: '',
  comments: '',
  payer_type: '',
};

export interface FormModel {
  payerid: number | null;
  planname: string;
  comments: string;
  payer_type: string;
  refid?: number;
}

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: <T extends FormModel>(data: T) => Promise<any>;
}

const FormPayerPlan = ({
  defaultValues = initialState,
  backUrl,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const handleReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form className="col-sm-9 col-md-6" onSubmit={handleSubmit(onSubmit)}>
      <DropdownPayerName
        name="payerid"
        label="Payer name"
        required
        control={control}
      />
      <Input name="planname" label="Plan name" required control={control} />

      <Textarea name="comments" label="Comments" rows={4} control={control} />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={!isDirty || isSubmitting}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormPayerPlan;
