import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

export const Actions = {
  LOAD_SUPER_ADMIN_LIST_ACTION: 'load-super-admin-list',
  LOAD_SECURITY_PERMISSIONS_ACTION: 'load-security-permissions',
};

class SSecurityPermissionStore {
  superAdminList: any = [];
  securityPermissionIdsCompany: any = [];
  securityPermissionIdsClient: any = [];
  securityPermissionIdsClinician: any = [];
  hrAdminIds: any = [];
}

class SecurityPermissionStore extends ReduceStore<
  SSecurityPermissionStore,
  CommonPayload
> {
  getInitialState() {
    return new SSecurityPermissionStore();
  }

  reduce(state: SSecurityPermissionStore, action: CommonPayload) {
    const internState = this.getState();
    switch (action.type) {
      case Actions.LOAD_SUPER_ADMIN_LIST_ACTION:
        internState.superAdminList = action.data;
        break;
      case Actions.LOAD_SECURITY_PERMISSIONS_ACTION:
        internState.securityPermissionIdsCompany =
          action.data.securityPermissionIdsCompany;
        internState.securityPermissionIdsClient =
          action.data.securityPermissionIdsClient;
        internState.securityPermissionIdsClinician =
          action.data.securityPermissionIdsClinician;
        internState.hrAdminIds = action.data.hrAdminIds;
        break;
      default:
        break;
    }
    return this.getState();
  }

  areEqual(
    one: SSecurityPermissionStore,
    two: SSecurityPermissionStore
  ): boolean {
    return false;
  }
}

const securityPermissionStore = new SecurityPermissionStore(appDispatcher);
export default securityPermissionStore;
