import { Container } from 'flux/utils';

import AbstractOrderFormPage, {
  PAbstractOrderFormPage,
  SAbstractOrderFormPage,
} from 'page/workflow/order/AbstractOrderFormPage';
import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';

export interface PEditOrderFormPage extends PAbstractOrderFormPage {
  callerName: string;
  order: TSignOrderItem;
}

export class SEditOrderFormPage extends SAbstractOrderFormPage {}

class EditOrderFormPage extends AbstractOrderFormPage<
  PEditOrderFormPage,
  SEditOrderFormPage
> {
  static calculateState(
    prevState: SEditOrderFormPage,
    props: PEditOrderFormPage
  ) {
    return AbstractOrderFormPage.calculateState(prevState, props, true);
  }

  static getStores() {
    return AbstractOrderFormPage.getStores();
  }

  getCallerName() {
    return this.props.callerName;
  }
}

export default Container.create(EditOrderFormPage, { withProps: true });
