import FacilityPreviewService, {
  TOrderNewPay,
  TFacilityData,
} from 'services/billing-coding/FacilityPreviewService';
import appDispatcher from 'dispatcher/AppDispatcher';
import { getDateRangeBounds } from 'utils/DateUtils';

export class TFacilityPreviewFilter {
  invoiceEditFlag: string = '';
  perDiem: string = '';
  dosFrom: string = '';
  dosTo: string = '';
  invoiceType: string = '';
  pdfFlag: boolean = false;
  lastName: string = '';
  firstName: string = '';
  patientId: number = 0;
  orderIds: string = '';
}

export default class FacilityPreviewActions {
  static loadOverview(
    facilityId: number,
    invoiceNo: number,
    filter?: TFacilityPreviewFilter
  ) {
    if (!filter) {
      filter = new TFacilityPreviewFilter();
    }

    const dos = getDateRangeBounds({ from: filter.dosFrom, to: filter.dosTo });

    return FacilityPreviewService.loadOverview(
      facilityId,
      invoiceNo,
      filter.invoiceEditFlag || '',
      filter.perDiem || '',
      dos.dateFrom,
      dos.dateTo,
      filter.invoiceType || '',
      filter.pdfFlag || false,
      filter.lastName || '',
      filter.firstName || '',
      filter.patientId || 0,
      filter.orderIds || ''
    ).then((response: 'SE' | Array<TOrderNewPay>) => {
      if (response === 'SE') {
        return [];
      }
      let ds: Array<TOrderNewPay> = [];
      const grouped: { [key: number]: Array<TOrderNewPay> } = {};
      for (let i = 0; i < response.length; i++) {
        const item = response[i];
        if (!grouped[item.studyId]) {
          grouped[item.studyId] = [];
        }
        grouped[item.studyId].push(item);
      }
      for (let studyId in grouped) {
        if (!grouped.hasOwnProperty(studyId)) {
          continue;
        }
        const studyChain: Array<TOrderNewPay> = [];
        let claimed = null;
        for (let j = 0; j < grouped[studyId].length; j++) {
          const claim: TOrderNewPay = grouped[studyId][j];
          if (claim.claimId) {
            claimed = claim;
          } else {
            studyChain.push(claim);
          }
        }
        if (claimed) {
          studyChain.push(claimed);
        }
        ds = ds.concat(studyChain);
      }
      appDispatcher.dispatch({
        type: 'facility.FacilityBilling.OrderNewPayList1',
        data: ds,
      });
      return [];
    });
  }

  static loadFacilityData(facility: number, invoiceNo: number) {
    return FacilityPreviewService.loadFacilityData({
      facilityId: facility,
      invoiceNo: invoiceNo,
    }).then((response: Array<TFacilityData>) => {
      appDispatcher.dispatch({
        type: 'facility.FacilityBilling.Getfacilitydetail_preinvoice',
        data: response && response[0] ? response[0] : new TFacilityData(),
      });
      return [];
    });
  }

  static clearStore() {
    appDispatcher.dispatch({
      type: 'clear-facility-preview',
    });
  }
}
