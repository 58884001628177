import { useForm } from 'react-hook-form';

import { DropdownRadiology } from 'components/project/dropdown/RadiologyDropdown';
import { DropDownFacilityRole } from 'components/project/dropdown/FacilityRolesDropdown';
import { Input } from 'components/form/textField';
import { DateRange } from 'components/form/dateRange';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import {
  storeRadiologyReport,
  FilterType,
} from 'stores/_mobx/report/radiologyReport';

const className = 'col-md-6 col-lg-3';

interface PropsType {
  isVisible: boolean;
  fetching: boolean;
  onSubmit: (data: FilterType) => void;
}

const Filter = ({ fetching, isVisible, onSubmit }: PropsType) => {
  const { control, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeRadiologyReport.filter,
  });

  const handleClickReset = () => {
    const defaultFilterValue = storeRadiologyReport.getDefaultFilter();

    reset(defaultFilterValue);
    onSubmit(defaultFilterValue);
  };

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="patientFirstName"
        label="Patient First Name"
        className={className}
        control={control}
      />
      <Input
        name="patientLastName"
        label="Patient Last Name"
        className={className}
        control={control}
      />
      <DropdownRadiology
        name="radiologyGroupId"
        label="Radiology Group"
        className={className}
        control={control}
      />
      <DropDownFacilityRole
        name="facilityId"
        label="Facility"
        className={className}
        control={control}
      />
      <div />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-6 col-lg-auto"
        control={control}
      />
      <div />
      <Input
        name="studyId"
        label="Study ID"
        className={className}
        control={control}
      />
      <Input
        name="mrn"
        label="Patient MRN"
        className={className}
        control={control}
      />

      <ControlsLayout alignX="auto">
        <Button
          onClick={handleClickReset}
          variant="warning"
          text="Reset"
          disabled={fetching}
        />

        <Button type="submit" text="Apply" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
