interface State {
  isOrdSelectPhysicianOpen: boolean;
  isCptCodeModalOpen: boolean;
  isIcdCodeModalOpen: boolean;
  sameVisit: string;
}

export enum ActionType {
  TogglePhysician = 'toggleSelectPhysicianDialog',
  ToggleCptCode = 'toggleCptCodeDialog',
  ToggleIcdCode = 'toggleIcdCodeDialog',
  SetSameVisit = 'setSameVisit',
}

type Action = { type: ActionType; payload?: any };

export const initialState: State = {
  isOrdSelectPhysicianOpen: false,
  isCptCodeModalOpen: false,
  isIcdCodeModalOpen: false,
  sameVisit: '',
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'toggleSelectPhysicianDialog':
      return {
        ...state,
        isOrdSelectPhysicianOpen: !state.isOrdSelectPhysicianOpen,
      };
    case 'toggleCptCodeDialog':
      return { ...state, isCptCodeModalOpen: !state.isCptCodeModalOpen };
    case 'toggleIcdCodeDialog':
      return { ...state, isIcdCodeModalOpen: !state.isIcdCodeModalOpen };
    case 'setSameVisit':
      return { ...state, sameVisit: action.payload };
    default:
      return state;
  }
}
