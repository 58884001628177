import { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import {
  BillAddressVariant,
  BillAddressFormType,
} from 'stores/_mobx/billingCodding/billAddress';

const togglerOptions = [
  { value: 'C', label: 'Corporate' },
  { value: 'F', label: 'Facility' },
  { value: 'O', label: 'Others' },
  { value: 'M', label: 'US Marshell' },
];

const defaultBill = {
  address1: '',
  address2: '',
  city1: '',
  city2: '',
  state1: '',
  state2: '',
  zipcode1: '',
  zipcode2: '',
};

const initialValues: BillAddressFormType = {
  addressVariant: BillAddressVariant.C,
  C: defaultBill,
  F: defaultBill,
  O: defaultBill,
  M: defaultBill,
};

interface PropsType {
  defaultValues?: BillAddressFormType;
  loading: boolean;
  onSubmit: (data: BillAddressFormType) => Promise<any>;
  onClose: () => void;
}

const DialogFormBillAddress = ({
  defaultValues = initialValues,
  loading,
  onSubmit,
  onClose,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    watch,
  } = useForm({ defaultValues });

  const cn = `row${isSubmitting || loading ? ' on-ajax' : ''}`;

  const addressVariant = watch('addressVariant');

  const handleClickSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const handleReset = () => {
    const variant = isDirty ? addressVariant : defaultValues.addressVariant;

    reset({ ...defaultValues, addressVariant: variant });
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <Dialog handleClose={onClose}>
      <DialogHeader title="Bill Address" onClose={onClose} />

      <DialogBody>
        <form className={cn}>
          <Radio
            name="addressVariant"
            className="inline"
            options={togglerOptions}
            control={control}
          />
          {togglerOptions.map(({ value }) =>
            addressVariant === value ? (
              <Fragment key={value}>
                <div className="col-lg-6">
                  <Input
                    name={`${value}.address1`}
                    label="Address 1"
                    control={control}
                  />
                  <Input
                    name={`${value}.city1`}
                    label="City"
                    control={control}
                  />
                  <Input
                    name={`${value}.state1`}
                    label="State"
                    control={control}
                  />
                  <Input
                    name={`${value}.zipcode1`}
                    label="Zip Code"
                    control={control}
                  />
                </div>
                <div className="col-lg-6">
                  <Input
                    name={`${value}.address2`}
                    label="Address 2"
                    control={control}
                  />
                  <Input
                    name={`${value}.city2`}
                    label="City"
                    control={control}
                  />
                  <Input
                    name={`${value}.state2`}
                    label="State"
                    control={control}
                  />
                  <Input
                    name={`${value}.zipcode2`}
                    label="Zip Code"
                    control={control}
                  />
                </div>
              </Fragment>
            ) : null
          )}
        </form>
      </DialogBody>

      <DialogFooter>
        <Button
          text="Reset"
          variant="warning"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          text="Save"
          disabled={!isDirty || isSubmitting}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogFormBillAddress;
