import appDispatcher from 'dispatcher/AppDispatcher';

import FacilityPatientPageService from 'services/reports/report-creator/facility-patient/FacilityPatientPageService';
import { Actions } from 'stores/reports/report-creator/facility-patient/FacilityPatientPageStore';

export default class FacilityPatientPageActions {
  static loadFacilityPatientList(conditionList, joint) {
    return FacilityPatientPageService.loadFacilityPatientList(
      conditionList,
      joint
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_FACILITY_PATIENT_LIST,
        data: response,
      });
    });
  }

  static createPdf(studiesByFacilityList, formatDescription) {
    return FacilityPatientPageService.createPdf(
      studiesByFacilityList,
      formatDescription
    );
  }
}
