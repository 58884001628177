const NORMAL_TEXT_REGEXP = /([a-z])([A-Z])/;
const IS_DOLLAR_PRICE_REGEXP =
  /^\$?\s?(([1-9]\d{0,2}(,\d{3})*)|(([1-9]\d*)?\d))(\.\d\d)?$/;
const REMOVE_FIRST_LAST_SLASHES = /^(\/)|(\/)$/;
const LOW_DASH = /_/;
const QUOTE = /"/;

/**
 * do this transformations:
 * someCamelCasedText => Some Camel Cased Text
 * some_low_dashed_text => Some Low Dashed Text
 * some___MoreDifficultSITUATION => Some More Difficult S I T U A T I O N
 *
 * @param str
 * @returns {*}
 */
export function normalizeText(str: string) {
  if (!str) {
    str = '';
  }
  if (str.indexOf('_') > -1) {
    str = str.replace(LOW_DASH, ' ');
  }
  if (str.match(NORMAL_TEXT_REGEXP)) {
    str = str.replace(NORMAL_TEXT_REGEXP, '$1 $2');
  }
  if (str.indexOf(' ') > -1) {
    return normalizeString(str);
  }
  return str.charAt(0).toUpperCase() + str.substring(1);
}

export function normalizeString(str: string) {
  const p = str.split(' ');
  let out = '';
  for (let i = 0; i < p.length; i++) {
    if (!p[i]) {
      continue;
    }
    out +=
      p[i].charAt(0).toUpperCase() +
      p[i].substring(1) +
      (i !== p.length - 1 ? ' ' : '');
  }
  return out;
}

/**
 * Check is input string dollar price.
 * Valid formats: ['$1231.12', '$ 122.44', '$ 123', '$123']
 * @param price
 * @returns {*}
 */
export function isDollarPrice(price: string) {
  return price.match(IS_DOLLAR_PRICE_REGEXP);
}

/**
 * Transform value to CSV compatible
 * @param value
 * @returns string
 */
export function toCsv(value: string) {
  if (value === null || value === undefined) {
    return '';
  }
  if (
    value.indexOf('"') !== -1 ||
    value.indexOf(',') !== -1 ||
    value.indexOf('\r') === -1 ||
    value.indexOf('\n') === -1
  ) {
    value = value.replace(QUOTE, '""');
    value = '"' + value + '"';
  }
  return value;
}

export function removeSlashes(str: string) {
  return str.replace(REMOVE_FIRST_LAST_SLASHES, '');
}

export function equalsIgnoreCase(s1: string, s2: string) {
  return s1.toLowerCase() === s2.toLowerCase();
}

export function moneyRender(value: string | number) {
  if (Number.isNaN(Number(value))) return '';

  return `$ ${Number(value).toFixed(2)}`;
}

export function leftPad(
  str: string | number,
  padLength: number,
  filler?: string
) {
  if (typeof str !== 'string') {
    str = str + '';
  }
  if (str.length >= padLength) {
    return str;
  }
  if (!filler) {
    filler = '0';
  }
  let length = padLength - str.length;
  while (length--) {
    str = filler + str;
  }
  return str;
}

export function deleteIdFromUrl(str: string | number) {
  if (!str) {
    return '';
  }
  if (typeof str !== 'string') {
    str = str + '';
  }
  str = removeSlashes(str);
  const strArr = str.split('/');
  const lastStrArr = strArr[strArr.length - 1];
  const checkId = parseInt(lastStrArr);
  if (Number(checkId)) {
    strArr.splice(-1, 1);
  }
  str = strArr.join('/');
  return str;
}

export function isBlank(str: string) {
  return !str || /^\s*$/.test(str);
}

export function trimLeft(str: string, charlist: string = 's') {
  return str.replace(new RegExp(`^[${prepareRegexpString(charlist)}]+`), '');
}

export function strMapToObj(strMap: Map<any, any>) {
  const obj = Object.create(null);
  strMap.forEach((value, key) => (obj[key] = value));
  return obj;
}

export function objToStrMap(obj: Object) {
  const strMap = new Map(Object.entries(obj));
  return strMap;
}

export function removeNewlineCarriageRet(str: string) {
  // remove newline / carriage return
  return str.replace(/\n/g, '');
}

export function removeWhitespaceBeforeTags(str: string) {
  // remove whitespace (space and tabs) before tags
  return str.replace(/[\t ]+</g, '<');
}

export function removeWhitespaceBetweenTags(str: string) {
  // remove whitespace between tags
  return str.replace(/>[\t ]+</g, '><');
}

export function removeWhitespaceAfterTags(str: string) {
  // remove whitespace after tags
  return str.replace(/>[\t ]+$/g, '>');
}

export const prepareRegexpString = (value: string) => {
  const regexpString = value.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
  return regexpString;
};

const StringUtils = {
  normalizeText,
  normalizeString,
  isDollarPrice,
  toCsv,
  removeSlashes,
  moneyRender,
  equalsIgnoreCase,
  leftPad,
  deleteIdFromUrl,
  isBlank,
  trimLeft,
  strMapToObj,
  objToStrMap,
  removeNewlineCarriageRet,
  removeWhitespaceBeforeTags,
  removeWhitespaceBetweenTags,
  removeWhitespaceAfterTags,
};

export default StringUtils;
