import { apiRequest } from 'services/RequestService';
import appDispatcher from 'dispatcher/AppDispatcher';
export class THospiceAddress {
  hospice_nm: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
}

export default class HospiceActions {
  static loadHospiceAddress(id: number): Promise<Array<THospiceAddress>> {
    return apiRequest<Array<THospiceAddress>>({
      url: 'generalmaster.ExamType.GetHospiceAddress',
      data: [id],
    }).then((response: Array<THospiceAddress>) => {
      appDispatcher.dispatch({
        type: 'generalmaster.ExamType.GetHospiceAddress',
        data: response[0] || {},
        id: id,
      } as any);
      return response;
    });
  }
}
