import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useController } from 'react-hook-form';

import { PureSelect } from 'components/form/select';
import { PropsType as SelectPropsType } from 'components/form/select/Select';

import { PhysicianShortInfoType } from 'stores/_mobx/clinicianManager/physicianExtendedOption';
import { storeDropdownPhysicianGroupAdmin } from 'stores/_mobx/dropDown/physicianGroupAdmin';
import { storeGroupManager } from 'stores/_mobx/clinicianManager/groupManager';

interface PropsType extends Omit<SelectPropsType, 'options'> {
  groupId?: number;
  additionalOptions?: PhysicianShortInfoType[];
}

const DropdownPhysicianGroupAdmin = ({
  groupId = 0,
  additionalOptions = [],
  name,
  control,
  ...props
}: PropsType) => {
  const { fetching: fetchingGroup, options: optionsGroup } = storeGroupManager;
  const { fetching, options } = storeDropdownPhysicianGroupAdmin;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: props.required },
  });

  const combinedOptions = useMemo(() => {
    const formattedOptions = additionalOptions.map((el) => ({
      label: el.label,
      value: el.user_id,
      groupId: el.groupId || 0,
    }));

    const combinedOptions = options.concat(formattedOptions).map((option) => {
      const isAnotherGroupAdmin =
        option.groupId && option.groupId !== groupId
          ? optionsGroup.some(({ value }) => value === option.groupId)
          : false;

      return {
        ...option,
        groupId,
        isDisabled: isAnotherGroupAdmin,
      };
    });

    return combinedOptions.sort((prev, next) =>
      prev.label.localeCompare(next.label)
    );
  }, [options, additionalOptions, optionsGroup, groupId]);

  useEffect(() => {
    storeGroupManager.getOptions();
    storeDropdownPhysicianGroupAdmin.getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PureSelect
      errorMessage={error?.message || ''}
      {...field}
      {...props}
      isLoading={fetching || fetchingGroup}
      options={combinedOptions}
    />
  );
};

export default observer(DropdownPhysicianGroupAdmin);
