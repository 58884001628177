import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { CanvasField } from 'components/form/canvas';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';
import { FileField } from 'components/form/file';
import DialogConfirmSelectedTemplate from './DialogConfirmSelectedTemplate';
import GeneralInfo from './GeneralInfo';
import EmployerInfo from './EmployerInfo';
import ContactDetails from './ContactDetails';
import resolver from './validation';

import { OptionType, ProfileDetailsType } from 'stores/_mobx/profile/myProfile';

interface PropsType {
  fetching: boolean;
  defaultValues: ProfileDetailsType;
  emailTemplatesOption: OptionType[];
  onSubmit: (payload: ProfileDetailsType) => Promise<any>;
}

const Form = ({
  fetching,
  defaultValues,
  emailTemplatesOption,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty },
    getValues,
    setValue,
    reset,
    watch,
    handleSubmit,
  } = useForm<ProfileDetailsType>({
    defaultValues,
    resolver,
  });

  const wasConfirmed = useRef<boolean>(false);

  const [isHIPPAConfirmationVisible, toggleHIPPAConfirmation] = useState(false);

  const isUserTypeDoctor =
    defaultValues?.userType === 'L' || defaultValues?.userType === 'D';

  const hippaOptions = useMemo(
    () =>
      emailTemplatesOption.filter((option) =>
        option.label.includes('Results Available')
      ),
    [emailTemplatesOption]
  );

  const handleCloseDialog = () => {
    const currentTemplates = getValues('emailTemplates');

    const emailTemplates = currentTemplates.filter(
      (id) => !hippaOptions.some(({ value }) => value === id)
    );

    setValue('emailTemplates', emailTemplates);

    toggleHIPPAConfirmation(false);
  };

  const handleConfirm = () => {
    toggleHIPPAConfirmation(false);
  };

  const checkDefaultConfirmStatus = useCallback(() => {
    const isHIPPASetByDefault = hippaOptions.length
      ? defaultValues?.emailTemplates.some((id) =>
          hippaOptions.some((option) => option.value === id)
        )
      : false;

    wasConfirmed.current = isHIPPASetByDefault;
  }, [defaultValues, hippaOptions]);

  const handleClickReset = () => {
    checkDefaultConfirmStatus();
    reset(defaultValues);
  };

  useEffect(() => {
    const subscription = watch(({ emailTemplates }, { name }) => {
      if (name === 'emailTemplates' && hippaOptions.length) {
        const isSpecificOptionSelected = emailTemplates.some((id) =>
          hippaOptions.find((option) => option.value === id)
        );

        if (!wasConfirmed.current) {
          toggleHIPPAConfirmation(isSpecificOptionSelected);
        }
        wasConfirmed.current = isSpecificOptionSelected;
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, hippaOptions]);

  useEffect(() => {
    checkDefaultConfirmStatus();
  }, [checkDefaultConfirmStatus]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4 className="my-profile-header">General Information</h4>
        <div className="row mb-5">
          <GeneralInfo details={defaultValues} />

          <div className="col-md-6">
            <FileField
              name="avatar"
              label="Profile image"
              accept="image/*"
              classNameContent="general-information-avatar"
              disabled={defaultValues?.userType === 'D'}
              control={control}
            />
          </div>
        </div>

        <h4 className="my-profile-header">
          {isUserTypeDoctor ? 'Primary Practice Address' : 'Employer Details'}
        </h4>
        <EmployerInfo details={defaultValues} />

        <h4 className="my-profile-header">
          {isUserTypeDoctor ? 'Mailing Address' : 'Contact Details'}
        </h4>
        <ContactDetails
          emailTemplatesOption={emailTemplatesOption}
          control={control}
          details={defaultValues}
        />

        {defaultValues?.userType === 'D' ? (
          <>
            <h4 className="my-profile-header">Signature Pad</h4>
            <CanvasField
              name="sig_path_staff"
              label="Signature"
              control={control}
            />
          </>
        ) : null}

        <ControlsLayout alignX="right">
          <Button
            variant="warning"
            text="Reset"
            disabled={fetching}
            onClick={handleClickReset}
          />
          <Button text="Apply" type="submit" disabled={fetching || !isDirty} />
        </ControlsLayout>
      </form>
      {isHIPPAConfirmationVisible && (
        <DialogConfirmSelectedTemplate
          onClose={handleCloseDialog}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};

export default Form;
