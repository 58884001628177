const Info = () => (
  <div style={{ fontSize: '1.6rem' }}>
      If an Export Format Setting is not enabled in
      Master Settings / Application Settings / Export Format, then "Export" will
      not be available in the Eligibility Manager.
    <br />
    <br />
    See Application Settings for available export formats.
    <br />
    Application Settings may be found in Master Settings.
  </div>
);

export default Info;
