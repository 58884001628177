import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Input } from 'components/form/textField';
import { Button } from 'components/button';
import NpiDetailView from './DetailView';
import resolver from './validation';

import { storeNpi, NpiType } from 'stores/_mobx/npi';

interface PropsType {
  npi?: string;
  onClose: () => void;
  onSelect: (npiDetailed: NpiType) => void;
}

const DialogNpi = ({ npi = '', onClose, onSelect }: PropsType) => {
  const { fetching, npiDetail } = storeNpi;

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({ defaultValues: { npi }, resolver });

  const cn = fetching || isSubmitting ? 'on-ajax' : '';

  const isDisabled = !npiDetail || isSubmitting || fetching;

  const info =
    npiDetail === undefined
      ? 'Provided NPI does not exist in the NPPES registry'
      : null;

  const handleSelect = () => {
    if (!isDisabled) onSelect(npiDetail);
  };

  const handleClickSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit((payload: { npi: string }) => storeNpi.getNpi(payload))();
  };

  useEffect(() => {
    if (String(npi).length === 10) {
      storeNpi.getNpi({ npi });
    }
    return storeNpi.clearNpi;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Search in NPI Registry" onClose={onClose} />
      <DialogBody className={cn}>
        <form className="row" onSubmit={handleClickSubmit}>
          <Input
            name="npi"
            label="NPI Number"
            className="col-md-8 col-lg-4"
            onKeyPress={(e) => {
              if (!/^\d/.test(e.key)) e.preventDefault();
            }}
            maxLength={10}
            required
            control={control}>
            <Button type="submit">
              <i className="bi bi-search" />
            </Button>
          </Input>
        </form>
        {npiDetail ? <NpiDetailView details={npiDetail} /> : info}
      </DialogBody>
      <DialogFooter>
        <Button text="Select" disabled={isDisabled} onClick={handleSelect} />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogNpi);
