import { useEffect, useState } from 'react';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';
import AbstractDropdown, { AAbstractDropdown } from './AbstractDropdown';
import Notification from 'components/modal/Notification';

import SendingApplicationsStore from 'stores/system-setup/masterSettings/integrations/SendingApplicationsStore';
import SendingApplicationsActions from 'actions/system-setup/master-setup/master-settings/integrations/SendingApplicationsActions';

interface OptionType {
  label: string;
  value: number;
}

export default class SendingApplicationDropdown extends AAbstractDropdown {
  static getDefaultFilter() {
    return {
      appSn: '',
      namespace: '',
      universalIdType: 0,
      user: '',
      communication: '',
    };
  }

  getStore() {
    return SendingApplicationsStore;
  }

  loadOptions() {
    return AbstractDropdown.adaptPromise(
      SendingApplicationsActions.loadAppSn()
        .then((response) => {
          const filter = SendingApplicationDropdown.getDefaultFilter();
          filter.appSn = response.app_sn;
          return SendingApplicationsActions.loadApplicationsDropdown(filter);
        })
        .then((response: OptionType[] | { status: string }) => {
          if (!Array.isArray(response)) {
            Notification.danger('Unable to load integrations list.', {
              autoClose: 5000,
            });
            return [];
          }
          return response;
        })
    );
  }

  validateOptions = (dropdown: any) => {
    return Array.isArray(dropdown) ? dropdown : [];
  };

  getOptions() {
    const dropdown = SendingApplicationsStore.getState().dropdown;
    return this.validateOptions(dropdown);
  }
}

export const DropdownIntegration = (
  props: Omit<SelectPropsType, 'options'>
) => {
  const [fetching, setFetching] = useState<boolean>(false);

  const [options, setOptions] = useState<OptionType[]>([]);

  useEffect(() => {
    setFetching(true);

    SendingApplicationsActions.loadAppSn()
      .then((response) => {
        const filter = {
          appSn: response?.app_sn || '',
          namespace: '',
          universalIdType: 0,
          user: '',
          communication: '',
        };
        return SendingApplicationsActions.loadApplicationsDropdown(filter);
      })
      .then((response: OptionType[] | { status: string }) => {
        const options = Array.isArray(response) ? response : [];

        setOptions(options);
      })
      .finally(() => {
        setFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Select {...props} options={options} isLoading={fetching} />;
};
