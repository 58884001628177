import { memo } from 'react';

import Li from './Li';

import { storeDropdownPlaceOfService } from 'stores/_mobx/dropDown/placeOfService';
import { OrderDetailsType } from 'stores/_mobx/billingCodding/claimReview';

interface PropsType {
  orderDetails: OrderDetailsType | null;
}

const TabViewBatch = ({ orderDetails }: PropsType) => {
  const posName = orderDetails
    ? orderDetails.pos_name ||
      storeDropdownPlaceOfService.findOption(orderDetails.pos_override)
        ?.label ||
      ''
    : '';

  return (
    <ul className="row">
      <Li label="Patient Room">{orderDetails?.room}</Li>
      <Li label="Facility Name">{orderDetails?.facility_name}</Li>
      <Li label="DOB">{orderDetails?.dob}</Li>
      <Li label="Facility Address">{orderDetails?.facaddress}</Li>
      <Li label="Order Entered By">{orderDetails?.created_by}</Li>
      <Li label="City, State, Zip Code">{orderDetails?.csz}</Li>
      <Li label="Order given By">{orderDetails?.given_by}</Li>
      <Li label="POS Code">{posName}</Li>
      <Li label="Order Taken By">{orderDetails?.technologist}</Li>
      <Li label="Order Type">{orderDetails?.ordertype}</Li>
      <Li label="Order Priority">{orderDetails?.priority_name}</Li>
      <Li label="Modality">{orderDetails?.modality_name}</Li>
      <Li label="Time Order Received">{orderDetails?.order_recieved}</Li>
      <Li label="Rendering Group">{orderDetails?.radiology_group}</Li>
      <Li label="Assigned Time">{orderDetails?.assigned_date}</Li>
      <Li label="Rendering MD">{orderDetails?.radiologistNames}</Li>
      <Li label="Completed Time">{orderDetails?.completion_date}</Li>
      <Li label="Ordering Physician">
        {orderDetails?.ordering_physician_name}
      </Li>
      <Li label="Time Result Faxed">{orderDetails?.faxed_time}</Li>
      <Li label="Ordering Physician NPI">
        {orderDetails?.ordering_physician_npi}
      </Li>
      <Li label="Time Results Phoned">{orderDetails?.phoned_time}</Li>
      <Li label="Accession #">{orderDetails?.accession}</Li>
      <Li label="Number of Patients Seen">{orderDetails?.number_of_patient}</Li>
    </ul>
  );
};

export default memo(TabViewBatch);
