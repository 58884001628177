const customStyles = {
  control: (style: any, state: any) => {
    const { isMulti, isFocused, selectProps } = state;
    const isStyleDependsOnValue = isMulti && selectProps.isStyleDependsOnValue;
    const isSeveralValuesSelected =
      isStyleDependsOnValue && state.getValue().length >= 2;
    const borderColor =
      isStyleDependsOnValue && isSeveralValuesSelected
        ? 'var(--bs-green)'
        : '#ccc';
    const bgColor =
      isStyleDependsOnValue && isSeveralValuesSelected
        ? 'var(--bs-primary)'
        : 'hsl(0, 0%, 100%)';
    const borderWidth =
      isStyleDependsOnValue && isSeveralValuesSelected ? '1.5px' : '1px';

    return {
      ...style,
      borderWidth,
      borderColor: isFocused ? 'hsl(0, 0%, 80%)' : borderColor,
      backgroundColor: state.isDisabled ? '#e9ecef' : bgColor,
      boxShadow: isFocused ? '0 0 0 0.25rem rgba(44, 159, 179, 0.25)' : 'none',
      borderRadius: '0.375rem',
      minHeight: '28px',
    };
  },
  valueContainer: (style: any, state: any) => ({
    ...style,
    padding: state.isMulti ? '0 0.55rem' : '0.23rem 0.55rem',
  }),
  singleValue: (style: any, state: any) => {
    const color = state.data?.color ? state.data.color : style.color;
    return {
      ...style,
      color,
    };
  },
  indicatorSeparator: (style: any) => ({
    ...style,
    margin: '4px 0',
  }),
  clearIndicator: (style: any) => ({
    ...style,
    padding: '7px 2px',
    borderRadius: '2px',
  }),
  dropdownIndicator: (style: any) => ({
    ...style,
    padding: '7px 2px',
    borderRadius: '2px',
  }),
  menu: (style: any) => ({
    ...style,
    margin: 0,
    zIndex: 1056,
  }),
  menuPortal: (style: any) => ({
    ...style,
    zIndex: 1056,
  }),
  option: (style: any, state: any) => {
    const isOptionSelected =
      state.isFocused || state.isSelected || state.isFocused;
    const color = state.data?.color
      ? state.data.color
      : !state.isDisabled && isOptionSelected
      ? '#000'
      : style.color;
    return {
      ...style,
      padding: '6px 7px',
      minHeight: '28px',
      color,
      backgroundColor: isOptionSelected ? '#c7edf4' : style.backgroundColor,
    };
  },
  input: (style: any) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  placeholder: (style: any) => ({
    ...style,
    color: '#808080',
    marginLeft: '2px',
    marginRight: '2px',
  }),
};

export default customStyles;
