import { useState } from 'react';

import { PureFile } from 'components/form/file';
import { Button } from 'components/button';
import { Grid } from 'components/grid';
import { ControlsLayout } from 'components/layout';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';

import { DIR_WITH_EXAMPLE } from 'constant/config';

interface PropsType {
  fileNameOfExample: string;
  exampleData: Record<string, string | number>[];
  columns: { title: string; name: string }[];
  onImport: (file: File) => void;
  onClose: () => void;
}

const DialogImport = ({
  fileNameOfExample,
  exampleData,
  columns,
  onImport,
  onClose,
}: PropsType) => {
  const [isExampleVisible, toggleExample] = useState<boolean>(false);

  const [file, setFile] = useState<File | string>('');

  const handleToggleExample = () => {
    toggleExample((state) => !state);
  };

  const handleSubmit = () => {
    onImport(file as File);
  };

  return (
    <Dialog size="large" scrollable={false} handleClose={onClose}>
      <DialogHeader title="Import Codes" onClose={onClose} />
      <DialogBody>
        <>
          <div className="row align-items-end justify-content-between">
            <div className="col-md-4 col-lg-3">
              <PureFile
                accept=".csv"
                name="file"
                label="Select CSV file"
                required
                value={file}
                onChange={setFile}
              />
            </div>
            <ControlsLayout alignX="auto">
              <a
                href={`${DIR_WITH_EXAMPLE}/${fileNameOfExample}`}
                target="_blank"
                className="btn btn-primary"
                rel="noreferrer">
                Download Sample
              </a>
            </ControlsLayout>
          </div>

          {isExampleVisible && (
            <Grid
              noControl
              hideSwitcher
              disablePagination
              columns={columns}
              dataSource={exampleData}
            />
          )}
        </>
      </DialogBody>
      <DialogFooter>
        <Button
          className="my-4"
          variant="default"
          text={isExampleVisible ? 'Hide Sample' : 'Show Sample'}
          onClick={handleToggleExample}
        />
        <Button text="Submit" disabled={!file} onClick={handleSubmit} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogImport;
