import { apiRequest } from 'services/RequestService';
import appDispatcher from 'dispatcher/AppDispatcher';
import DropdownActions from 'actions/project/DropdownActions';

export class TInsurancePlan {
  payorType: string = '';
  payorId: number = null;
  payer_type: string = '';
  payorName: string = '';
  group: string = '';
  insuranceNo: string = '';
  planId: number = 0;
  groupNo: string = '';
  insStT: string = '';
  insEndT: string = '';
  insPatRelation: string = '';
  planName: string = '';
  plan: Array<{ label: string; data: string; value?: number }> = [];
  msp: number = 0;
  refid?: number = null;
}

export class TArchivePayer {
  refid: number = null;
  payerName: string = '';
  payerId: number = null;
  EffectDate: string = '';
  payerType: string = '';
}

export class TPatientInsuranceInfo {
  primaryPlan: TInsurancePlan = new TInsurancePlan();
  secondaryPlan: TInsurancePlan = new TInsurancePlan();
  tertiaryPlan: TInsurancePlan = new TInsurancePlan();
  guar_g_fnm: string = '';
  guar_g_lnm: string = '';
  guar_g_relation: string = '';
  guar_g_address1: string = '';
  guar_g_address2: string = '';
  guar_g_city: string = '';
  guar_g_state: string = '';
  guar_g_zip: string = '';
  archivePayer: 0 | Array<TArchivePayer> = 0;
  initialPayer: 0 | Array<TArchivePayer> = 0;
}

export default class OrganizationActions extends DropdownActions {
  static getPatientInsuranceInfo(
    patientId: number,
    flagOrderEntryPayerInfo?: boolean
  ) {
    return apiRequest<TPatientInsuranceInfo | 'SE'>({
      url: 'patient.PatientInfo.GetPatientInsuranceInfo',
      data: [patientId, flagOrderEntryPayerInfo],
    }).then((response: TPatientInsuranceInfo | 'SE') => {
      if (response === 'SE') {
        console.log('No session error');
        return;
      }
      if (!response) {
        console.log('Empty response is ' + response);
        return;
      }

      const primaryPlan = {
        ...(response.primaryPlan || new TInsurancePlan()),
        msp: Number(response.primaryPlan?.msp) || 0,
        planId: Number(response.primaryPlan?.planId) || 0,
        plan: response.primaryPlan?.plan.map(({ label, data }) => ({
          label,
          value: Number(data),
        })),
      };

      const secondaryPlan = {
        ...(response.secondaryPlan || new TInsurancePlan()),
        msp: Number(response.secondaryPlan?.msp) || 0,
        planId: Number(response.secondaryPlan?.planId) || 0,
        plan: response.secondaryPlan?.plan.map(({ label, data }) => ({
          label,
          value: Number(data),
        })),
      };

      const tertiaryPlan = {
        ...(response.tertiaryPlan || new TInsurancePlan()),
        msp: Number(response.tertiaryPlan?.msp) || 0,
        planId: Number(response.tertiaryPlan?.planId) || 0,
        plan: response.tertiaryPlan?.plan.map(({ label, data }) => ({
          label,
          value: Number(data),
        })),
      };

      const data = {
        ...response,
        primaryPlan,
        secondaryPlan,
        tertiaryPlan,
      };

      appDispatcher.dispatch({
        type: 'patient.PatientInfo.GetPatientInsuranceInfo',
        data,
        isLoaded: true,
      } as any);
    });
  }

  static updateArchivedPayers(
    patientId: number,
    flagOrderEntryPayerInfo: boolean
  ): Promise<{
    archivePayer: 0 | Array<TArchivePayer>;
    initialPayer: 0 | Array<TArchivePayer>;
  }> {
    return apiRequest<TPatientInsuranceInfo>({
      url: 'patient.PatientInfo.GetPatientInsuranceInfo',
      data: [patientId, flagOrderEntryPayerInfo],
    }).then((response: TPatientInsuranceInfo) => {
      const res: {
        archivePayer: 0 | Array<TArchivePayer>;
        initialPayer: 0 | Array<TArchivePayer>;
      } = { archivePayer: [], initialPayer: [] };
      if (response && response.archivePayer) {
        res.archivePayer = response.archivePayer;
      }
      if (response && response.initialPayer) {
        res.initialPayer = response.initialPayer;
      }
      return res;
    });
  }

  static clearPatientInsuranceInfo(patientId?: number) {
    appDispatcher.dispatch({
      type: 'patient.PatientInfo.GetPatientInsuranceInfo',
      data: {},
      isLoaded: false,
    } as any);
  }
}
