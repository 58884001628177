export const FINDING_CODING = {
  PAGE: 'finding_coding',
};

export const INSURANCE_VERIFY = {
  PAGE: 'billingverification',
};

export const ELIGIBILITY = {
  PAGE: 'EligibilityCheck',
};

export const CODING = {
  PAGE: 'codingreview',
};

export const BILLING_ASSIGNMENT = {
  PAGE: 'FacilityWorkInvoice',
};

export const TRANSMIT_UNASSIGNMENT = {
  PAGE: 'transmitunassign',
};

export const FACILITY_PREVIEW = {
  PAGE: 'FacilityPayment',
};

export const CLAIM_MANAGER = {
  PAGE: 'claimmanager',
};

export const CLAIM_REVIEW = {
  PAGE: 'claimreview',
};

export const PRO_FSS = {
  PAGE: 'feeforservice',
};

export const PRO_GROUP_BILLING = {
  PAGE: 'RgApproval',
};

export const PRE_INVOICE = {
  PAGE: 'patientfacinvoice',
};

export const INVOICE = {
  PAGE: 'FacilityInvoicing',
  TAB_FACILITY: 'invoice_facility_tab',
  TAB_PER_DIEM: 'invoice_per_diem_tab',
  TAB_OCC_HLTH: 'invoice_occ_hlth_tab',
};

export const LEDGER = {
  PAGE: 'FacilityLedger',
};

export const AR_AGING = {
  PAGE: 'ARAging',
};
