import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed,
} from 'mobx';

import Notification from 'components/modal/Notification';

import SecurityPermissionActions from 'actions/SecurityPermissionActions';
import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import AccessUtils from 'utils/AccessUtils';
import { getDayBounds } from 'utils/DateUtils';
import { USER_APP as PAGE_ID } from 'constant/pagesId/privileges';

const filterDefaultValues: FilterType = {
  status: 'A',
  userTypeToggler: '',
  userRoleId: 0,
  facilityId: 0,
  lastName: '',
  firstName: '',
  cellPhone: '',
  loginDate: '',
  emailTemplateId: 0,
  email: '',
  username: '',
};

interface UserPermissionType {
  addUser: boolean;
  editUser: boolean;
  deleteUser: boolean;
  privileges: boolean;
  privilegesClientUser: boolean;
  privilegesCompanyUser: boolean;
  viewCredentials: boolean;
  resetPassword: boolean;
  resendPassword: boolean;
  toggleState: boolean;
}

export interface FilterType {
  firstName: string;
  lastName: string;
  cellPhone: string;
  loginDate: string;
  userRoleId: number;
  userTypeToggler: string;
  facilityId: number;
  status: 'A' | 'I';
  emailTemplateId: number;
  email: string;
  username: string;
}

export interface UserType {
  UserId: number;
  birthdate: string;
  comment: string;
  email: string;
  facility: string;
  first_name: string;
  flagViewPassword: boolean;
  gender_name: 'Male' | 'Female' | '';
  last_name: string;
  mobile: string;
  refid: number;
  status: 'Active' | 'Inactive';
  type: number;
  user_name: string;
  user_no: number;
  user_type: string;
  usertype_id: number;
}

export interface SecurityDetailsType {
  ipin: string;
  refid: string;
  user_name: string;
  user_pass: string;
  user_type: string;
  ut_category_id: number;
  ut_type: number;
}

export interface PasswordResetType {
  userId: number;
  userName: string;
  pin?: string;
}

class User {
  fetching: boolean = false;
  fetchingPermissions: boolean = false;
  fetchingSecurity: boolean = false;
  usersList: UserType[] = [];
  usersTotal: number = 0;
  permissionUserType: string = '';
  securityDetails: SecurityDetailsType | null = null;
  passwordResetDetails: PasswordResetType | null = null;
  filter: FilterType = filterDefaultValues;
  idsForChangeStatus: number[] = [];

  page: Pagination = new Pagination({ id: 'application_users_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingPermissions: observable,
      fetchingSecurity: observable,
      usersList: observable,
      usersTotal: observable,
      permissionUserType: observable,
      filter: observable,
      securityDetails: observable,
      passwordResetDetails: observable,
      idsForChangeStatus: observable,

      permission: computed,

      setFetching: action,
      setFetchingSecurity: action,
      setUsersList: action,
      setUsersTotal: action,
      setSecurityDetails: action,
      setPasswordResetDetails: action,
      setIdsForChangeStatus: action,
      setFilter: action.bound,
      clearPermissionUserType: action.bound,
      clearSecurityDetails: action.bound,
      clearPasswordResetDetails: action.bound,
      clearIdsForChangeStatus: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingSecurity(fetching: boolean) {
    this.fetchingSecurity = fetching;
  }

  setUsersList(users: UserType[]) {
    this.usersList = users;
  }

  setUsersTotal(count: number) {
    this.usersTotal = count;
  }

  setSecurityDetails(details: SecurityDetailsType) {
    this.securityDetails = details;
  }

  setPasswordResetDetails(details: PasswordResetType) {
    this.passwordResetDetails = details;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setIdsForChangeStatus(ids: number[]) {
    this.idsForChangeStatus = ids;
  }

  clearSecurityDetails() {
    this.securityDetails = null;
  }

  clearPasswordResetDetails() {
    this.passwordResetDetails = null;
  }

  clearPermissionUserType() {
    this.permissionUserType = '';
  }

  clearIdsForChangeStatus() {
    this.idsForChangeStatus = [];
  }

  setDefaultFilter() {
    const filter = {
      ...this.filter,
      userTypeToggler: UserProfileStore.isClientUser() ? 'A' : '',
      facilityId: UserProfileStore.getFacilityId(),
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const filter = {
      ...filterDefaultValues,
      userTypeToggler: UserProfileStore.isClientUser() ? 'A' : '',
      facilityId: UserProfileStore.getFacilityId(),
    };

    return filter;
  }

  get permission(): UserPermissionType {
    const {
      permissionUserType,
      filter: { userTypeToggler },
    } = this;

    const viewCredentials =
      permissionUserType.includes('clinician') ||
      (permissionUserType.includes('client') && userTypeToggler === 'A') ||
      (permissionUserType.includes('company') && userTypeToggler === 'B');

    return {
      addUser: AccessUtils.checkAccess(PAGE_ID.ADD),
      editUser: AccessUtils.checkAccess(PAGE_ID.EDIT),
      deleteUser: AccessUtils.checkAccess(PAGE_ID.DELETE),
      privileges: UserProfileStore.haveAccess('usrperm_save'),
      privilegesCompanyUser: AccessUtils.checkAccess('parentsPerm'),
      privilegesClientUser: AccessUtils.checkAccess('Userperm'),
      resetPassword: AccessUtils.checkAccess(PAGE_ID.PASSWORD_RESET),
      resendPassword: UserProfileStore.haveAccess(PAGE_ID.PASSWORD_RESEND),
      toggleState: AccessUtils.checkAccess(PAGE_ID.INACTIVE),
      viewCredentials,
    };
  }

  async getUsersCount(data: (string | number)[]) {
    try {
      const count = await apiRequest<number>({
        url: 'usermaster.UserInfo.TotalRecordCount',
        data,
      });

      this.setUsersTotal(count);
    } catch (e: any) {
      this.setUsersTotal(0);
    }
  }

  async getUsersList(data: (string | number)[]) {
    try {
      const response = await apiRequest<UserType[]>({
        url: 'usermaster.UserInfo.UserInfoList',
        data,
      });

      const users = response.map((user) => ({
        ...user,
        birthdate: user.birthdate === '0000-00-00' ? '' : user.birthdate,
        UserId: Number(user.UserId),
        refid: Number(user.refid),
        type: Number(user.type),
        user_no: Number(user.user_no),
        usertype_id: Number(user.usertype_id),
      }));
      this.setUsersList(users);
    } catch (e: any) {
      this.setUsersList([]);
    }
  }

  async getUsersListMain() {
    this.setFetching(true);

    const {
      filter,
      page: { pagination },
    } = this;

    const loginDate = getDayBounds(filter.loginDate);

    const payload = [
      filter.userRoleId,
      filter.status,
      filter.userTypeToggler === 'A' ? filter.facilityId : 0,
      filter.lastName,
      loginDate.dateFrom,
      loginDate.dateTo,
      '', // userDisplay
      pagination.skip,
      pagination.pageSize,
      filter.firstName,
      filter.cellPhone,
      filter.userTypeToggler,
      '', // npi
      '', //pecos
      filter.emailTemplateId,
      filter.email,
      filter.username,
    ];

    const payloadForCount = [...payload];

    payloadForCount.splice(6, 2);

    const promiseCount = this.getUsersCount(payloadForCount);

    const promiseList = this.getUsersList(payload);

    return Promise.all([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  }

  async getPermissionUserType() {
    runInAction(() => {
      this.fetchingPermissions = true;
    });
    try {
      const userId = UserProfileStore.getUserId();

      const response =
        await SecurityPermissionActions.getUserViewPasswordPermission(userId);

      runInAction(() => {
        this.fetchingPermissions = false;
        this.permissionUserType = response;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingPermissions = false;
        this.permissionUserType = '';
      });
    }
  }

  async getSecurityDetails(data: { userType: number; userId: number }) {
    this.setFetchingSecurity(true);

    try {
      const [response] = await apiRequest<null | [SecurityDetailsType]>({
        url: 'usermaster.UserInfo.GetUserDetails',
        data,
      });

      const details = {
        ...response,
        ut_category_id: Number(response.ut_category_id),
        ut_type: Number(response.ut_type),
      };

      this.setSecurityDetails(details);
    } catch (e: any) {
      this.setSecurityDetails(null);
    } finally {
      this.setFetchingSecurity(false);
    }
  }

  resetPassword = async ({ password }: { password: string }) => {
    const { passwordResetDetails } = this;

    const data = {
      userId: passwordResetDetails.userId,
      password,
      createdBy: UserProfileStore.getUserId(),
      username: passwordResetDetails.userName,
    };

    try {
      const pin = await apiRequest<string>({
        url: 'usermaster.ResetPwd.UpdateResetPassword',
        data,
      });

      this.setPasswordResetDetails({
        ...passwordResetDetails,
        pin,
      });
    } catch (e: any) {
      Notification.danger('An error occurred during reset password process!');
    }
  };

  async resendPassword(userId: number) {
    this.setFetchingSecurity(true);
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'usermaster.ResetPwd.SendLoginDetails',
        data: [userId],
      });
      if (response === 'S') {
        Notification.success('Login details sent successfully!');
      } else {
        throw Error('');
      }
    } catch (e: any) {
      Notification.danger("Password wasn't send! An error occurred!");
    } finally {
      this.setFetchingSecurity(false);
    }
  }

  async changeUserStatus(data: {
    userId: number;
    status: 'Active' | 'Inactive';
  }) {
    try {
      const response = await apiRequest<string>({
        url: 'usermaster.UserInfo.UpdateStatus',
        data,
      });
      return response === 'S';
    } catch (e: any) {
      return false;
    }
  }

  async changeUserStatusMain() {
    const {
      filter: { status },
      idsForChangeStatus,
    } = this;

    const currentStatus = status === 'A' ? 'Active' : 'Inactive';

    this.setFetching(true);

    try {
      const result = await Promise.allSettled(
        idsForChangeStatus.map((userId) =>
          storeUser.changeUserStatus({ userId, status: currentStatus })
        )
      );

      // @ts-ignore
      const count = result.reduce((prev, { value }) => prev + value, 0);

      if (count) {
        Notification.success(
          `${count} user${count > 1 ? 's were' : ' was'} ${
            status === 'A' ? 'deactivated' : 'activated'
          } successfully!`
        );
        return true;
      }
      throw Error('');
    } catch (error) {
      Notification.danger('Error occurred!');
      this.setFetching(false);
      return false;
    } finally {
      this.clearIdsForChangeStatus();
    }
  }
}

export const storeUser = new User();
