import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    firstName: yup.string().required('Please, enter radiologist first name!'),
    lastName: yup.string().required('Please, enter radiologist last name'),
    npi: yup
      .string()
      .required('Please, enter a radiologist NPI')
      .test({
        test: (npi: string) => /^(\d){10}$/.test(npi),
        message: 'Radiologist NPI should contain 10 numbers',
      }),
  })
);

export default validation;
