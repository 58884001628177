import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { emailValidator, phoneValidator } from 'utils/formValidators';

export const sendEmailValidation = yupResolver(
  yup.object().shape({
    subject: yup.string().required('Fill in email subject'),
    email: yup
      .string()
      .required('Fill in your email address')
      .test({
        test: (email: string) =>
          email.split(',').every((address) => emailValidator(address.trim())),
        message: 'Invalid email address.',
      }),
  })
);

export const emailValidation = yupResolver(
  yup.object().shape({
    value: yup.string().email('Invalid email address.'),
  })
);

export const phoneValidation = yupResolver(
  yup.object().shape({
    value: yup.string().test({
      test: phoneValidator,
      message: 'Should be in xxx-xxx-xxxx format.',
    }),
  })
);
