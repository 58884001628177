import { useEffect, useState } from 'react';
// @ts-ignore
import useFluxStore from 'flux-hooks';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { PureTextarea } from 'components/form/textarea';
import useRequest from 'components/hook/useRequest';

import UserProfileStore from 'stores/UserProfileStore';
import OrderFormPageStore from 'stores/workflow/order/OrderFormPageStore';
import OrderFormPageActions from 'actions/workflow/order/OrderFormPageActions';

interface PropsType {
  orderId: number;
}

type Comments = Array<{
  result: string;
  name: string;
  date: string;
  time: string;
}>;

const VisitComments = ({ orderId }: PropsType) => {
  const { fetching, controlledAction } = useRequest(
    OrderFormPageActions.loadVisitComments
  );

  const [comment, setComment] = useState<string>('');

  const comments: Comments = useFluxStore(
    OrderFormPageStore,
    (prevState: any, store: any) => store.getState().visitComments
  );

  useEffect(() => {
    controlledAction(orderId);

    return () => {
      OrderFormPageActions.clearVisitComments();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    OrderFormPageActions.saveVisitComment(
      orderId,
      UserProfileStore.getUserId(),
      comment
    ).then(() => {
      setComment('');
      controlledAction(orderId);
    });
  };

  return (
    <div className={fetching ? 'on-ajax' : ''}>
      {comments.map((v, i) => (
        <div key={i} className="visit-comments">
          <p>{v.result}</p>
          <div className="author">
            By {v.name} ON {v.date}
          </div>
        </div>
      ))}
      <form onSubmit={handleSubmit}>
        <PureTextarea
          name="comment"
          value={comment}
          rows={4}
          onChange={setComment}
        />
        <ControlsLayout alignX="right">
          <Button text="Add Comment" onClick={handleSubmit} />
        </ControlsLayout>
      </form>
    </div>
  );
};

export default VisitComments;
