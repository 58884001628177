import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import { Radio } from 'components/form/radio';
import { Textarea } from 'components/form/textarea';
import { OPTIONS_IS_BILLABLE, OPTIONS_IS_ICD_10 } from './formOptions';
import resolver from './validation';

export interface FormModel {
  refid?: number;
  bill_status: 'B' | 'N';
  icd_billable_status: string;
  icd_code: string;
  icd_flag: 'Y' | 'N';
  icd_type: string;
  short_description: string;
}

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
}

const initialValues: FormModel = {
  bill_status: 'B',
  icd_billable_status: '',
  icd_code: '',
  icd_flag: 'Y',
  icd_type: '',
  short_description: '',
};

const DiagnosisForm = ({
  defaultValues = initialValues,
  backUrl,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting, isDirty },
    handleSubmit,
    setError,
    reset,
  } = useForm({ defaultValues, resolver });

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: FormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('icd_code', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      onSubmit={handleClickSubmit}
      className={`col-lg-6${isSubmitting ? ' on-ajax' : ''}`}>
      <Radio
        name="icd_flag"
        label="ICD Flag"
        className="d-flex gap-3 inline"
        disabled={Boolean(defaultValues.refid)}
        options={OPTIONS_IS_ICD_10}
        control={control}
      />

      <Radio
        name="bill_status"
        label="Bill status"
        className="d-flex gap-3 inline"
        options={OPTIONS_IS_BILLABLE}
        control={control}
      />

      <Input name="icd_code" label="ICD Code" required control={control} />
      <Textarea
        name="short_description"
        label="Description"
        rows={8}
        required
        control={control}
      />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          text="Reset"
          variant="warning"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button type="submit" text="Save" disabled={isSubmitting || !isDirty} />
      </ControlsLayout>
    </form>
  );
};

export default DiagnosisForm;
