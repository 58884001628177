import React from 'react';

import DropdownPureAdjustmentCode from 'components/project/dropdown/AdjustmentCode';
import Text from 'components/form/input/Text';
import { Grid } from 'components/grid';

import MathUtils from 'utils/MathUtils';
import { moneyRender } from 'utils/StringUtils';

export interface PPerDiem {
  postType: 'L' | 'B';
  perDiemData: any;
  updatePerDiem: (name: any, value: any, data: any) => void;
  onAjax: boolean;
}

export class SPerDiem {}

export default class PerDiem extends React.Component<PPerDiem, SPerDiem> {
  COLUMNS = [
    { name: 'name', title: 'Unit Name' },
    { name: 'fee', title: 'Price', render: moneyRender },
    { name: 'price', title: 'Unit', render: MathUtils.parseInt },
    { name: 'total', title: 'Total', render: moneyRender },
    { name: 'payment', title: 'Payment', render: moneyRender },
    { name: 'writeoff', title: 'Writeoff', render: moneyRender },
    {
      name: 'adjcode',
      title: 'Adjustment',
      render: (code: number) => (
        <DropdownPureAdjustmentCode value={code} disabled />
      ),
    },
    { name: 'balance', title: 'Balance', render: moneyRender },
  ];

  LINE_COLUMNS = [
    { name: 'name', title: 'Unit Name' },
    { name: 'fee', title: 'Price', render: moneyRender },
    { name: 'price', title: 'Unit', render: MathUtils.parseInt },
    { name: 'total', title: 'Total', render: moneyRender },
    {
      name: 'payment',
      title: 'Payment',
      render: (v: any, data: any) => {
        return (
          <Text
            noLabel
            name="payment"
            value={v}
            onSetValue={(n, v, e) => this.props.updatePerDiem(n, v, data)}
          />
        );
      },
    },
    {
      name: 'writeoff',
      title: 'Writeoff',
      render: (v: any, data: any) => {
        return (
          <Text
            noLabel
            name="writeoff"
            value={v}
            onSetValue={(n, v, e) => this.props.updatePerDiem(n, v, data)}
          />
        );
      },
    },
    {
      name: 'adjcode',
      title: 'Adjustment',
      render: (code: number, data: any) => (
        <DropdownPureAdjustmentCode
          className="width-200"
          value={code}
          onChange={(code) => {
            this.props.updatePerDiem('adjcode', code, data);
          }}
        />
      ),
    },
    { name: 'balance', title: 'Balance', render: moneyRender },
  ];

  getColumns() {
    if (this.props.postType === 'L') {
      return this.LINE_COLUMNS;
    }
    return this.COLUMNS;
  }

  render() {
    return (
      <Grid
        dataSource={this.getDataSource()}
        columns={this.getColumns()}
        disablePagination
        noControl
      />
    );
  }

  getDataSource() {
    const { perDiemData } = this.props;
    if (!perDiemData) {
      return [];
    }
    return perDiemData.perdiem_rptrvalue || [];
  }
}
