import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { phoneValidator } from 'utils/formValidators';

const validation = yupResolver(
  yup.object().shape({
    groupName: yup.string().required('Please fill in Group Name.'),
    integrationIds: yup.array().when('isIntegrationChecked', {
      is: true,
      then: yup.array().min(1, 'Select at least 1 Integration.'),
    }),
    email: yup.string().when('notificationType', {
      is: (notificationType: string) => notificationType === 'E',
      then: yup
        .string()
        .required('Please fill in Email.')
        .email('Invalid email address.'),
    }),
    fax: yup.string().when('notificationType', {
      is: (notificationType: string) => notificationType === 'F',
      then: yup
        .string()
        .required('Please fill in Fax.')
        .test({
          test: (fax: string) => phoneValidator(fax),
          message: 'Should be in xxx-xxx-xxxx format!',
        }),
    }),
  })
);

export default validation;
