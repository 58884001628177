import { Link, RouteComponentProps } from 'react-router-dom';

import { LayoutSideTitle } from 'components/layout';
import FormState from './components/Form';

import {
  storeState,
  StateType,
} from 'stores/_mobx/systemSetup/masterSetting/state';
import { URL_STATE } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const StateAddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: StateType) =>
    storeState.modifyState(payload).then((response) => {
      if (response) {
        return response;
      }
      history.push(URL_STATE);
    });

  return (
    <>
      <LayoutSideTitle title="Add State">
        <Link to={URL_STATE} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <FormState onSubmit={handleSubmit} />
    </>
  );
};

export default StateAddPage;
