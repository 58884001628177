import { IconButton } from 'components/button';
import { GridControlPanelPropTypes } from './types';

const GridControlPanel = (props: GridControlPanelPropTypes) => (
  <div className="grid-controls-panel">
    {props.enableRefreshButton ? (
      <IconButton onClick={props.onClickRefreshData}>
        <i className="bi bi-arrow-repeat" />
      </IconButton>
    ) : null}
    <IconButton onClick={props.onClickColumnVisibilitySettings}>
      <i className="bi bi-gear" />
    </IconButton>
    <IconButton onClick={props.onClickResetColumnVisibilitySettings}>
      <i className="bi bi-eraser" />
    </IconButton>
  </div>
);

export default GridControlPanel;
