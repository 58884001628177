import { useState } from 'react';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { PureCheckbox } from 'components/form/checkbox';
import { Button } from 'components/button';

interface PropsType {
  onCancel: () => void;
  onConfirm: () => void;
}

const DialogConfirmEditCPTCode = ({ onCancel, onConfirm }: PropsType) => {
  const [confirmation, setConfirmation] = useState<boolean>(false);

  return (
    <Dialog handleClose={onCancel}>
      <DialogHeader title="Confirm" />
      <DialogBody>
        <p>
          You are about to edit the CPT code associated with this accession
          number.
          <br />
          Please check the box below to confirm you intend to perform this
          action
        </p>
        <PureCheckbox
          name="confirmEdit"
          checked={confirmation}
          onChange={setConfirmation}
          label="I confirm my intentions to edit this CPT code"
        />
      </DialogBody>
      <DialogFooter>
        <Button text="Cancel" variant="warning" onClick={onCancel} />
        <Button text="Ok" disabled={!confirmation} onClick={onConfirm} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogConfirmEditCPTCode;
