import {
  makeObservable,
  action,
  computed,
  observable,
  runInAction,
} from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import AccessUtils from 'utils/AccessUtils';
import { FEE_SCHEDULE_JURISDICTION as PAGE_ID } from 'constant/pagesId/systemSetup';

const prettierSchedule = (
  schedule: ScheduleItemResponseType[]
): ScheduleItemType[] =>
  schedule
    .map((el) => ({
      ...el,
      filename: el.filename || '',
      groupid: Number(el.groupid),
      realState: Number(el.realState),
      refid: Number(el.refid),
      editId: Number(el.refid),
      deldate: el.deldate || '',
      cpt_status: 'S' as 'S',
    }))
    .sort((a, b) =>
      a.statename.toLowerCase().localeCompare(b.statename.toLowerCase())
    );

const filterOutSchedule = (
  scheduleIncome: ScheduleItemType[],
  filter: FilterType
) => {
  const isFilterSet =
    Boolean(filter.description) ||
    Boolean(filter.jurisdictionId) ||
    Boolean(filter.stateId);

  const scheduleFiltered = isFilterSet
    ? scheduleIncome.filter((el) => {
        if (filter.description) return el.filename.includes(filter.description);
        if (filter.jurisdictionId) return el.refid === filter.jurisdictionId;
        return el.realState === filter.stateId;
      })
    : scheduleIncome;

  return scheduleFiltered;
};

const defaultPermissionValue: PermissionType = {
  importAction: false,
  archive: false,
  edit: false,
};

export const filterDefaultValue: FilterType = {
  stateId: 0,
  jurisdictionId: 0,
  description: '',
  status: 'active',
  isActive: true,
};

interface PermissionType {
  importAction: boolean;
  archive: boolean;
  edit: boolean;
}

export interface FilterType {
  stateId: number;
  jurisdictionId: number;
  description: string;
  status: 'active' | 'archive';
  isActive: boolean;
}

interface ScheduleItemResponseType {
  active_end: string;
  active_on: string;
  date: string;
  deldate: null | string;
  filename: string | null;
  groupid: string;
  realState: string;
  refid: string;
  statename: string;
  status: 'Available' | 'Active' | 'Archived';
}

export interface ScheduleItemType
  extends Omit<
    ScheduleItemResponseType,
    'groupid' | 'realState' | 'filename' | 'refid' | 'deldate'
  > {
  cpt_status: 'S';
  deldate: string;
  filename: string;
  groupid: number;
  editId: number;
  realState: number;
  refid: number;
}

interface ScheduleResponseType {
  active: ScheduleItemResponseType[];
  archived: ScheduleItemResponseType[];
}

class FeeScheduleJurisdiction {
  fetching: boolean = false;
  activeSchedule: ScheduleItemType[] = [];
  archiveSchedule: ScheduleItemType[] = [];
  filter: FilterType = filterDefaultValue;
  permission: PermissionType = defaultPermissionValue;

  page: Pagination = new Pagination({
    id: 'state_fee_schedule',
    clearOnUnmount: true,
  });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      activeSchedule: observable,
      archiveSchedule: observable,
      filter: observable,
      permission: observable,

      scheduleData: computed,

      setFetching: action,
      setPermission: action,
      setFilter: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFilter(filter: FilterType) {
    this.filter = {
      ...filter,
      isActive: filter.status === 'active',
    };
  }

  setPermission(permission: PermissionType) {
    this.permission = permission;
  }

  get scheduleData() {
    const {
      filter,
      page: { pagination },
    } = this;

    const scheduleIncome =
      filter.status === 'active' ? this.activeSchedule : this.archiveSchedule;

    const scheduleFiltered = filterOutSchedule(scheduleIncome, filter);

    return {
      scheduleTotal: scheduleFiltered.length,
      scheduleList: scheduleFiltered.slice(
        pagination.skip,
        pagination.skip + pagination.pageSize
      ),
    };
  }

  async getFeeSchedule() {
    this.setFetching(true);
    try {
      const { active, archived } = await apiRequest<ScheduleResponseType>({
        url: 'codemaster.StateCPTCode.StateCptList',
        data: { state: null, description: null },
      });
      runInAction(() => {
        this.activeSchedule = prettierSchedule(active);
        this.archiveSchedule = prettierSchedule(archived);
      });
    } catch (e: any) {
      runInAction(() => {
        this.activeSchedule = [];
        this.archiveSchedule = [];
      });
    } finally {
      this.setFetching(false);
    }
  }

  checkPermission() {
    const importAction = AccessUtils.checkAccess(PAGE_ID.IMPORT);

    const archive = AccessUtils.checkAccess(PAGE_ID.ARCHIVE);

    const edit = AccessUtils.checkAccess(PAGE_ID.EDIT);

    this.setPermission({
      importAction,
      archive,
      edit,
    });
  }
}

export const storeFeeScheduleJurisdiction = new FeeScheduleJurisdiction();
