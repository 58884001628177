import { apiRequest } from 'services/RequestService';
import { TUserTypeEnum } from 'services/project/UserSelectionService';

export class TUserType {
  refid: number;
  usertype_name: string;
  usertype: string;
  category: string;
  type: string;
  // client created props - get rid of these
  value: number;
  label: string;
}

class UserTypeService {
  loadUserTypeList(
    userType: TUserTypeEnum,
    flagCompanyUsersOnly: boolean
  ): Promise<Array<TUserType>> {
    return apiRequest<Array<TUserType>>({
      url: 'generalmaster.Mv_UserType.loadUserTypeByType',
      data: [userType, flagCompanyUsersOnly],
    }).then((response) => {
      response.forEach((v) => {
        v.value = parseInt('' + v.refid);
        v.label = v.usertype_name;
        return v;
      });
      return response;
    });
  }
}

export default new UserTypeService();
