import { TTopMenu, TMenuItem } from 'stores/record/DataObjects';
import {
  TopMenuItemExtends,
  SidebarItem,
} from 'stores/record/UserProfileRecord';

const CONCAT_MENU = [
  'Messages',
  'Clinician Manager',
  'Patient Records',
  'Privileges',
  'Personal Profile',
];

const exceptionsList = [
  {
    original: 'data-reports/facility-contact',
    alias: '/master-setup/facility-contacts-report',
  },
  {
    original: 'check-',
    alias: '/check-out-in',
  },
];

const getLink = (url: string) => {
  const exception = exceptionsList.find(({ original }) => original === url);

  return exception ? exception.alias : `/${url.replace(/\/.*/, '')}`;
};

const prettierMenuItem = (menuItem: TMenuItem[]) =>
  menuItem.map(({ url, ...rest }) => {
    const exception = exceptionsList.find(({ original }) => original === url);

    return {
      ...rest,
      url: exception ? exception.alias : `/${url.replace(/-+/g, '-')}`, // some url has 2 dash next to each other "report-creator/tech---patient"
    };
  });

export const topMenuFormatter = (menuarr: TTopMenu[]): TopMenuItemExtends[] =>
  menuarr
    .map(({ sub_menu, app_name, ...rest }) => {
      const flatChildren = sub_menu.map(({ menu_item }) => menu_item).flat();

      const name = app_name === 'Exam Orders' ? 'Workflow' : app_name;

      const url = flatChildren.reduce((prev, { url }) => {
        const link = getLink(url);

        const isLinkExist = prev.includes(link);

        if (!isLinkExist) {
          prev.push(link);
        }

        return prev;
      }, []);

      return url.length
        ? {
            ...rest,
            app_name: name,
            url,
            defaultUrl: `/${flatChildren[0].url.replace(/-+/g, '-')}`,
          }
        : null;
    })
    .filter((menu) => Boolean(menu));

export const sidebarMenuFormatter = (menuarr: TTopMenu[]): SidebarItem[] =>
  menuarr.map(({ app_name, sub_menu }) => {
    const parentName = app_name === 'Exam Orders' ? 'Workflow' : app_name;

    const flatChildren = sub_menu.map(({ menu_item }) => menu_item).flat();

    const urls = flatChildren.reduce((prev, { url }) => {
      const link = getLink(url);

      const isLinkExist = prev.includes(link);

      if (!isLinkExist) {
        prev.push(link);
      }

      return prev;
    }, [] as string[]);

    if (CONCAT_MENU.includes(parentName)) {
      return {
        parentName,
        urls,
        group: null,
        children: sub_menu
          .map(({ menu_item }) => prettierMenuItem(menu_item))
          .flat(),
      };
    }
    return {
      parentName,
      urls,
      group: sub_menu.map(({ label, menu_item }) => ({
        label,
        menu_item: prettierMenuItem(menu_item),
      })),
      children: null,
    };
  });
