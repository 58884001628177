import { useForm } from 'react-hook-form';

import { DropdownLocation } from 'components/project/dropdown/LocationDropdown';
import { DropdownProviderType } from 'components/project/dropdown/ProviderTypeDropdown';
import { DropDownFacilityRole } from 'components/project/dropdown/FacilityRolesDropdown';
import { DropDownCorporation } from 'components/project/dropdown/CorporateDropdown';
import { DropdownStateWithPermission } from 'components/project/dropdown/StateRolesDropdown';
import { DropdownPlaceOfServiceWithPermission } from 'components/project/dropdown/PlaceOfServiceRolesDropdown';
import { DropDownOrderType } from 'components/project/dropdown/OrderType';
import { DropdownRegion } from 'components/project/dropdown/RegionDropdown';
import { DropdownDivision } from 'components/project/dropdown/Division';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import { DateRange } from 'components/form/dateRange';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import { storeInvoice, FilterType } from 'stores/_mobx/billingCodding/invoice';

const INVOICE_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'B', label: 'Aging' },
  { value: 'R', label: 'Posting' },
];

const className = 'col-md-6 col-lg-3';

const radioClass = `${className} part-inline`;

interface PropsType {
  fetching: boolean;
  isVisible: boolean;
  onSubmit: (data: FilterType) => void;
}

const Filter = ({ fetching, isVisible, onSubmit }: PropsType) => {
  const { control, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeInvoice.filter,
  });

  const handleClickReset = () => {
    const defaultFilter = storeInvoice.getDefaultFilter();

    reset(defaultFilter);
    onSubmit(defaultFilter);
  };

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <DropDownCorporation
        name="corporateId"
        label="Corporate"
        className={className}
        control={control}
      />
      <DropdownStateWithPermission
        name="state"
        label="State"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropDownOrderType
        name="orderType"
        label="Order type"
        className={className}
        control={control}
      />
      <Radio
        name="invoicesStatus"
        label="Invoice status"
        options={INVOICE_OPTIONS}
        className={radioClass}
        control={control}
      />
      <div />
      <DropDownFacilityRole
        name="facility"
        label="Facility"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownLocation
        name="location"
        label="Location"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownRegion
        name="region"
        label="Region"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownDivision
        name="division"
        label="Division"
        className={className}
        control={control}
      />
      <DropdownPlaceOfServiceWithPermission
        name="pos"
        label="Place of Service"
        className={className}
        control={control}
      />
      <Input
        name="claim"
        label="Claim"
        className={className}
        control={control}
      />
      <Input
        name="invoice"
        label="Invoice"
        className={className}
        control={control}
      />
      <DropdownProviderType
        name="providerType"
        label="Provider type"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <div />
      <DateRange
        nameFrom="invoiceDateFrom"
        nameTo="invoiceDateTo"
        nameRadio="period"
        labelFrom="Billed Date From"
        labelTo="Billed Date To"
        labelRadio="Billed Date"
        radioClassName="col-sm-12 col-md-6"
        isOptionsExpanded
        control={control}
      />

      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button
          text="Apply"
          type="submit"
          data-testid="submit"
          disabled={fetching}
        />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
