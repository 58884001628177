import { makeObservable, observable, action, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { getDateRangeBounds } from 'utils/DateUtils';

const defaultFilterValue: FilterType = {
  procedureCode: '',
  procedureDescription: '',
  lastName: '',
  firstName: '',
  technologistId: 0,
  facilityId: 0,
  corporateId: 0,
  pos: '',
  dosStart: '',
  dosEnd: '',
  period: 'A',
};

export interface FilterType {
  procedureCode: string;
  procedureDescription: string;
  lastName: string;
  firstName: string;
  technologistId: number;
  facilityId: number;
  corporateId: number;
  pos: string;
  dosStart: string;
  dosEnd: string;
  period: string;
}

interface LogType {
  cpt: string;
  cpt_category: string;
  description: string;
  facility: string;
  facilityId: number;
}

interface ProcedureCountResponse {
  count: number;
  data: LogType[];
}

class ProcedureCount {
  fetching: boolean = false;
  logList: LogType[] = [];
  logTotal: number = 0;
  filter: FilterType = defaultFilterValue;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'procedure_count_gridId' });

    makeObservable(this, {
      fetching: observable,
      logList: observable,
      logTotal: observable,
      filter: observable,

      setFetching: action,
      setFilter: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFilter(payload: FilterType) {
    this.filter = payload;
  }

  setDefaultFilter() {
    const filter = {
      ...this.filter,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facilityId,
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const filter = {
      ...defaultFilterValue,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facilityId,
    };

    return filter;
  }

  async getProcedureCountLog(filter: FilterType = this.filter) {
    const { pagination } = this.page;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const payload = {
      skip: pagination.skip,
      pageSize: pagination.pageSize,
      facility: filter.facilityId,
      corporation: filter.corporateId,
      dosFrom: dos.dateFrom,
      dosTo: dos.dateTo,
      procedureCode: filter.procedureCode,
      procedureDescription: filter.procedureDescription,
      patientLastName: filter.lastName,
      patientFirstName: filter.firstName,
      techId: filter.technologistId,
      pos: filter.pos,
    };

    this.setFetching(true);

    try {
      const { count, data } = await apiRequest<ProcedureCountResponse>({
        url: 'report.ProcedureCodeCount.FacilityUsedCPTCodesList',
        data: payload,
      });

      const logList = data.map((log) => ({
        ...log,
        facilityId: Number(log.facilityId),
      }));

      runInAction(() => {
        this.logList = logList;
        this.logTotal = count;
      });
    } catch (e) {
      runInAction(() => {
        this.logList = [];
        this.logTotal = 0;
      });
    } finally {
      this.setFetching(false);
    }
  }

  generatePdf = async ({ filter }: { filter: string }) => {
    const data = {
      dataSource: this.logList,
      filter,
    };
    try {
      const response = await apiRequest<string>({
        url: 'pdfcreater.reportPdfGenerator.Procedurecodecntpdf',
        data,
      });
      return response;
    } catch (e: any) {
      return '';
    }
  };

  prepareFilterForExport() {
    const { filter } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return {
      ...filter,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
    };
  }
}

export const storeProcedureCount = new ProcedureCount();
