import React from 'react';

import { TAbstractInputAttr } from 'components/form/input/AbstractInput';
import { PText, SText, TText } from 'components/form/input/Text';

export interface PTextArea extends PText {
  attr?: TAbstractInputAttr & React.TextareaHTMLAttributes<HTMLElement>;
}

export class STextArea extends SText {}

export default class TextArea extends TText<PTextArea, STextArea> {
  getInputType(): string {
    return 'textarea';
  }

  buildInput(attributes: PText): React.ReactElement {
    if (attributes.value === undefined) {
      attributes.value = '';
    }
    return <textarea {...attributes} className="form-control" />;
  }
}
