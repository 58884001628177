import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import DropdownFacilityRoleActive from 'components/project/dropdown/FacilityRolesActive';
import { Button } from 'components/button';
import { InputCalendar } from 'components/form/inputCalendar';
import MaskInput from 'components/form/maskInput';
import { Input } from 'components/form/textField';

import UserProfileStore from 'stores/UserProfileStore';

const className = 'col-sm-12 col-md-6 col-lg-4';

export const getDefaultValues = (): FilterType => ({
  lastName: '',
  firstName: '',
  ssn: '',
  dateOfBirth: '',
  facilityId: UserProfileStore.canSeeFacilities()
    ? 0
    : UserProfileStore.getFacilityId(),
  inmateNumber: '',
  iceNumber: '',
  immigrationId: '',
});

export interface FilterType {
  lastName: string;
  firstName: string;
  ssn: string;
  dateOfBirth: string;
  facilityId: number;
  inmateNumber: string;
  iceNumber: string;
  immigrationId: number | '';
}

interface PropsType {
  isCorrectional: boolean;
  onSubmit: (values: FilterType) => void;
}

const PatientFilter = ({ isCorrectional, onSubmit }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitSuccessful },
    handleSubmit,
    reset,
  } = useForm({ defaultValues: getDefaultValues() });

  const dateOfBirthLimit = useMemo(() => new Date(), []);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <form className="row" onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="lastName"
        label="Last Name"
        className={className}
        control={control}
      />
      <Input
        name="firstName"
        label="First Name"
        className={className}
        control={control}
      />
      <MaskInput
        name="ssn"
        label="SSN"
        format="###-##-####"
        className={className}
        control={control}
      />
      <InputCalendar
        name="dateOfBirth"
        label="Date of birth"
        preventOpenOnFocus
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={90}
        maxDate={dateOfBirthLimit}
        className={className}
        control={control}
      />
      <DropdownFacilityRoleActive
        name="facilityId"
        label="Facility"
        className={className}
        control={control}
      />

      {isCorrectional && (
        <>
          <Input
            name="inmateNumber"
            label="OID Number"
            className={className}
            control={control}
          />
          <Input
            name="iceNumber"
            label="ICE Number"
            className={className}
            control={control}
          />
          <Input
            name="immigrationId"
            label="Marshall ID"
            type="number"
            className={className}
            control={control}
          />
        </>
      )}
      <div className={`${className} mt-auto`} style={{ marginBottom: '15px' }}>
        <Button
          type="submit"
          data-testid="submitFilter"
          disabled={!isDirty}
          fullWidth>
          Apply Filter
        </Button>
      </div>
    </form>
  );
};

export default PatientFilter;
