import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface MirthChannel {
  id: string;
  name: string;
}

export interface OptionType {
  value: string;
  label: string;
}

class DropdownMirthChannel {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,

      setFetching: action,
      setOptions: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setOptions(options: OptionType[]) {
    this.options = options;
  }

  async getOptions() {
    if (this.fetching) return Promise.resolve(this.options);

    this.setFetching(true);
    try {
      const response = await apiRequest<MirthChannel[]>({
        url: 'mirth/channels',
        method: 'GET',
        legacy: false,
      });

      const options = response.map((option) => ({
        label: option.name,
        value: option.id,
      }));

      this.setOptions(options);
    } catch {
      this.setOptions([]);
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeDropdownMirthChannel = new DropdownMirthChannel();
