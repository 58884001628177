import React from 'react';
import { Container } from 'flux/utils';

import Notification from 'components/modal/Notification';
import DialogConfirm from 'components/modal/dialogConfirm';
import Fieldset from 'components/form/Fieldset';
import { Grid, GridControlButton } from 'components/grid';
import Text from 'components/form/input/Text';
import { IconButton } from 'components/button';
import { TPagination } from 'components/grid/GridTypes';
import JurisdictionZipCodeRangeDetailsModal from 'page/system-setup/master-setup/jurisdiction/JurisdictionZipCodeRangeDetailsModal';
import JurisdictionDetailsActions from 'actions/system-setup/master-setup/operations/jurisdiction/JurisdictionDetailsActions';
import JurisdictionDetailsStore from 'stores/system-setup/masterSettings/jurisdiction/JurisdictionDetailsStore';

export interface PJurisdictionZipCodeRangesGrid {
  isNew?: boolean;
  id: any;
}

export class SJurisdictionZipCodeRangesGrid {
  zipCodeRangeList: any = null;
  zipRangeDetailsModal: any = null;
}

export class JurisdictionZipCodeRangesGrid extends React.Component<
  PJurisdictionZipCodeRangesGrid,
  SJurisdictionZipCodeRangesGrid
> {
  static getStores() {
    return [JurisdictionDetailsStore];
  }

  static calculateState(prevState: SJurisdictionZipCodeRangesGrid) {
    return {
      zipCodeRangeList: JurisdictionDetailsStore.getState().zipCodeRangeList,
      zipRangeDetailsModal:
        JurisdictionDetailsStore.getState().zipRangeDetailsModal,
    };
  }

  componentDidMount() {
    if (!this.props.isNew) {
      this.loadZipCodeRanges();
    } else {
      JurisdictionDetailsActions.toggleZipCodeRangesLoading(false);
    }
  }

  render() {
    const zipCodeRangeList = this.state.zipCodeRangeList;
    const filter = zipCodeRangeList.filter;

    return (
      <Fieldset className="row">
        {this.props.isNew ? null : (
          <Fieldset className="row">
            <Text
              className="col-md-6 col-sm-6"
              name="zipStart"
              label="From"
              value={zipCodeRangeList.filter.model.zipStart}
              errorMessages={filter.errors.zipStart}
              onSetValue={this.updateZipCodeRangeFilter.bind(this)}
            />
            <Text
              className="col-md-6 col-sm-6"
              name="zipEnd"
              label="To"
              value={zipCodeRangeList.filter.model.zipEnd}
              errorMessages={filter.errors.zipEnd}
              onSetValue={this.updateZipCodeRangeFilter.bind(this)}
            />
          </Fieldset>
        )}

        <Grid
          id="jurisdictionZipCodeRangeList"
          onAjax={zipCodeRangeList.onAjax}
          disablePagination={this.props.isNew}
          columns={this.getZipCodeRangeListColumns()}
          dataSource={zipCodeRangeList.dataSource}
          dataSourceCount={zipCodeRangeList.dataSourceCount}
          pagination={zipCodeRangeList.pagination}
          onPaginationChange={(o: TPagination) => {
            JurisdictionDetailsActions.changeZipCodeRangeListPagination(
              o,
              this.props.id
            );
          }}
          gridControlPanel={
            <GridControlButton
              title="Add"
              onClick={() =>
                JurisdictionDetailsActions.toggleZipCodeRangeDetailsModal(true)
              }
            />
          }
        />
        {zipCodeRangeList.deleteZipCodeRangeConfirm.isShown && (
          <DialogConfirm
            onCancel={this.handleCloseDialogDeleteZipRange}
            onApprove={this.onDeleteZipCodeRangeConfirm}>
            Are you sure you want to delete?
          </DialogConfirm>
        )}
        {this.state.zipRangeDetailsModal.isShown && (
          <JurisdictionZipCodeRangeDetailsModal
            jurisdiction={this.props.id}
            callback={this.onZipCodeRangeDetailsCallback.bind(this)}
          />
        )}
      </Fieldset>
    );
  }

  updateZipCodeRangeFilter(name: any, value: any, errorMessages: any) {
    JurisdictionDetailsActions.updateZipCodeRangeFilter(
      name,
      value,
      errorMessages,
      this.props.id
    );
  }

  getZipCodeRangeListColumns() {
    return [
      { name: 'zipStart', title: 'ZIP start' },
      { name: 'zipEnd', title: 'ZIP end' },
      {
        name: 'action',
        title: 'Action',
        className: 'width-75',
        render: (value: any, data: any) => (
          <IconButton
            className="text-primary"
            onClick={() => {
              JurisdictionDetailsActions.toggleZipCodeRangeDeleteConfirm(
                true,
                data.zipStart,
                data.zipEnd
              );
            }}>
            <i className="bi bi-trash" />
          </IconButton>
        ),
      },
    ];
  }

  handleCloseDialogDeleteZipRange = () => {
    JurisdictionDetailsActions.toggleZipCodeRangeDeleteConfirm(false);
  };

  onDeleteZipCodeRangeConfirm = () => {
    const state = this.state.zipCodeRangeList.deleteZipCodeRangeConfirm;
    if (this.props.isNew) {
      JurisdictionDetailsActions.deleteJurisdictionZipCodeRangeLocaly(
        state.zipStart,
        state.zipEnd
      );
      this.handleCloseDialogDeleteZipRange();
    } else {
      JurisdictionDetailsActions.deleteJurisdictionZipCodeRange(
        this.props.id,
        state.zipStart,
        state.zipEnd
      ).then((response) => {
        if (response === 'S') {
          Notification.success('Successfully deleted!');
          this.loadZipCodeRanges();
        } else Notification.danger('An error occurred!');
        this.handleCloseDialogDeleteZipRange();
      });
    }
  };

  loadZipCodeRanges() {
    let list = this.state.zipCodeRangeList;
    const pg = list.pagination;
    JurisdictionDetailsActions.loadJurisdictionZipCodeRanges(
      this.props.id,
      list.filter.model,
      { skip: pg.skip, limit: pg.pageSize }
    );
  }

  onZipCodeRangeDetailsCallback(confirm: any, range: any) {
    if (confirm) {
      const jurisdiction = this.props.id;
      if (this.props.isNew) {
        let ranges = this.state.zipCodeRangeList.dataSource;
        const rangeIsExist = ranges.some(
          ({ zipStart, zipEnd }: any) =>
            zipStart === range.zipStart && zipEnd === range.zipEnd
        );
        if (!rangeIsExist) {
          JurisdictionDetailsActions.addJurisdictionZipCodeRangeLocaly(
            range.zipStart,
            range.zipEnd
          );
          JurisdictionDetailsActions.toggleZipCodeRangeDetailsModal(false);
        } else {
          Notification.danger('This ZIP code range already exists!');
        }
      } else {
        JurisdictionDetailsActions.jurisdictionZipCodeRangeExists(
          jurisdiction,
          range.zipStart,
          range.zipEnd
        ).then((exists) => {
          if (!exists) {
            JurisdictionDetailsActions.addJurisdictionZipCodeRange(
              jurisdiction,
              range.zipStart,
              range.zipEnd
            ).then((response) => {
              if (response === 'S') {
                Notification.success('Successfully added!');
              } else {
                Notification.danger('An error occurred!');
              }
              JurisdictionDetailsActions.toggleZipCodeRangeDetailsModal(false);
              this.loadZipCodeRanges();
            });
          } else {
            Notification.danger('This ZIP code already exists!');
          }
        });
      }
    } else {
      JurisdictionDetailsActions.toggleZipCodeRangeDetailsModal(false);
    }
  }
}

const JurisdictionZipCodeRangesGridContainer = Container.create(
  JurisdictionZipCodeRangesGrid
);
export default JurisdictionZipCodeRangesGridContainer;
