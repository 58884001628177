import { useForm } from 'react-hook-form';

import { DropDownApplicationUserType } from 'components/project/dropdown/ApplicationUsersDropdown';
import { DropDownFacilityRole } from 'components/project/dropdown/FacilityRolesDropdown';
import RadioOptions from 'components/project/common/RadioOptions';
import DropdownEmailTemplate from 'components/project/dropdown/EmailTemplate';
import { Button } from 'components/button';
import { InputCalendar } from 'components/form/inputCalendar';
import MaskInput from 'components/form/maskInput';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import { ControlsLayout } from 'components/layout';

import { storeUser, FilterType } from 'stores/_mobx/users/user';
import UserProfileStore from 'stores/UserProfileStore';

export const USER_TYPE_OPTIONS = [
  { value: '', label: 'All Users' },
  { value: 'A', label: 'Client Users' },
  { value: 'B', label: 'Company Users' },
];

interface PropsType {
  isVisible: boolean;
  onSubmit: (data: FilterType) => void;
}

const Filter = ({ isVisible, onSubmit }: PropsType) => {
  const { control, reset, watch, handleSubmit } = useForm({
    defaultValues: storeUser.filter,
  });

  const type = watch('userTypeToggler');

  const userType = type ? (type === 'A' ? 1 : 0) : '';

  const handleClickReset = () => {
    const filterDefaultValues = storeUser.getDefaultFilter();

    reset(filterDefaultValues);
    onSubmit(filterDefaultValues);
  };

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="lastName"
        label="Last name"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <Input
        name="firstName"
        label="First name"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <MaskInput
        name="cellPhone"
        label="Mobile phone"
        className="col-md-6 col-lg-3"
        format="###-###-####"
        control={control}
      />
      <InputCalendar
        name="loginDate"
        label="Login date"
        className="col-md-6 col-lg-3"
        control={control}
      />
      {!UserProfileStore.isClientUser() && (
        <Radio
          name="userTypeToggler"
          label="User type"
          className="part-inline col-md-auto col-lg-6 col-xl-3"
          options={USER_TYPE_OPTIONS}
          control={control}
        />
      )}
      <DropDownApplicationUserType
        name="userRoleId"
        label="User role"
        className="col-md-6 col-lg-3"
        type={userType}
        control={control}
      />
      <DropDownFacilityRole
        name="facilityId"
        label="Facility"
        className="col-md-6 col-lg-3"
        disabled={type !== 'A'}
        control={control}
      />
      <DropdownEmailTemplate
        name="emailTemplateId"
        label="Template"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <Radio
        name="status"
        label="User status"
        className="part-inline col-md-6 col-lg-3"
        options={RadioOptions.active}
        control={control}
      />
      <Input
        name="username"
        label="Username"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <Input
        name="email"
        label="Email"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button variant="warning" text="Reset" onClick={handleClickReset} />
        <Button type="submit" text="Apply" />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
