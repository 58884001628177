import React from 'react';
import { Container } from 'flux/utils';

import Form from 'components/form/Form';
import Fieldset from 'components/form/Fieldset';
import FileUpload from 'components/form/input/FileUpload';
import { Grid } from 'components/grid';
import Text from 'components/form/input/Text';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button, IconButton } from 'components/button';
import DialogConfirm from 'components/modal/dialogConfirm';

import UserProfileStore from 'stores/UserProfileStore';
import TimeSheetPageStore from 'stores/profile/TimeSheetPageStore';
import TimeSheetPageActions from 'actions/profile/personal/TimeSheetPageActions';
import { TTimeSheetFileType } from 'services/profile/personal/TimeSheetPageService';
import { BASE_URL_FILE_DIR } from 'constant/config';

interface PManageDocuments {
  title: string;
  userId: string;
  anchorDayDate: string;
  type: TTimeSheetFileType;
  flagViewOnly?: boolean;
  callback: (isClosed: boolean) => void;
}

export class SManageDocuments {
  onAjax: boolean = false;
  documentsList: Array<any> = [];
  isOpen: boolean = false;
  showDeleteItemPopUp: boolean = false;
  selectedRefId: number = null;
  errors: any = {};
  model: any = {};
}

export class ManageDocuments extends React.Component<
  PManageDocuments,
  SManageDocuments
> {
  static getStores() {
    return [TimeSheetPageStore];
  }

  static calculateState(prevState: SManageDocuments, props: PManageDocuments) {
    if (!prevState) {
      let userId = '' + props.userId;
      if (!userId || userId.trim() === '') {
        userId = '0';
      }
      prevState = new SManageDocuments();
    }
    return { ...prevState, model: TimeSheetPageStore.getState().userDocsList };
  }

  adjustUserName(name: string) {
    const arr = name.split(/(\s+)/).filter((val) => {
      return val.trim().length > 0;
    });
    if (arr.length >= 2) {
      name = arr[1] + ', ' + arr[0];
    }
    return name;
  }

  onFileUpload(name: string, uploadedName: string) {
    const created = 'new';
    const fileOnDiskPath = `${BASE_URL_FILE_DIR}tempfolder/`;
    const fileOnDiskName = uploadedName;
    const user = UserProfileStore.getUser();
    const uploadedBy = this.adjustUserName(user.name);
    const documentsList = this.state.documentsList.concat({
      refid: 'tmp_' + Date.now(),
      fileName: uploadedName,
      fileOnDiskPath,
      fileOnDiskName,
      created: created,
      comment: '',
      uploadedBy: uploadedBy,
    });
    this.setState({ documentsList });
  }

  onUpdateModel = (
    name?: string,
    value?: string,
    errorName?: string,
    errorKeys?: any,
    event?: Event,
    clb?: () => void
  ) => {
    const model = {
      ...this.state.model,
      [name]: value,
    };

    const errors = { ...this.state.errors, [name]: errorName };

    this.setState({ model, errors }, clb);
  };

  submit = (model?: any, hasErrors?: boolean, errors?: any) => {
    if (hasErrors) {
      this.setState({ errors, model });
    } else {
      this.submitSuccess();
    }
  };

  componentDidMount() {
    const { props } = this;
    this.setState({ onAjax: true });
    TimeSheetPageActions.loadUserDocs(
      props.userId,
      props.anchorDayDate,
      props.type
    ).then((response) => {
      this.setState({ documentsList: response, onAjax: false });
    });
  }

  getColumns() {
    const { props } = this;
    const columns: any[] = [
      {
        name: 'fileName',
        title: 'File',
      },
      {
        name: 'created',
        title: 'Date Uploaded',
        maxWidth: 160,
      },
    ];
    if (props.type === 'schedule') {
      columns.push({
        name: 'uploadedBy',
        title: 'Uploaded By',
        maxWidth: 160,
      });
      columns.push({
        name: 'comment',
        title: 'Comment',
        maxWidth: 200,
        render: (value: string, row: any) => {
          if (props.flagViewOnly) {
            return value;
          }
          return this.renderTextInput('comment', value, row);
        },
      });
    }
    columns.push({
      name: 'actions',
      title: 'Actions',
      maxWidth: 80,
      render: (
        v: any,
        row: {
          fileOnDiskPath: string;
          fileOnDiskName: string;
          fileName: string;
          refid: number;
        }
      ) => {
        const isPdfFile = /.*\.pdf$/.test(row.fileOnDiskName);
        const attributes = isPdfFile ? {} : { download: row.fileOnDiskName };

        return (
          <div className="controls">
            <a
              rel="noreferrer"
              target="_blank"
              href={`${BASE_URL_FILE_DIR}user_files/${row.fileOnDiskName}`}
              {...attributes}
              title={row.fileName}>
              <i className="icon icon-view" />
            </a>
            {!props.flagViewOnly && (
              <IconButton
                className="text-primary"
                onClick={() => {
                  this.removeDocumentInitial(row.refid);
                }}>
                <i className=" bi bi-trash" />
              </IconButton>
            )}
          </div>
        );
      },
    });
    return columns;
  }

  renderTextInput(name: string, value: string, row: any, attr: any = null) {
    return (
      <Text
        className="fixed-number-input-size"
        name={name}
        noLabel
        readOnly={false}
        attr={{ ...attr, disabled: false }}
        rowRef={row}
        onSetValue={(name, value, errorMessages, errorKeys, event, rowRef) => {
          this.updateTableCell(
            name,
            value,
            errorMessages,
            errorKeys,
            event,
            rowRef
          );
        }}
        value={value}
      />
    );
  }

  updateTableCell(
    name: string,
    value: string,
    errorMessages: any,
    errorKeys: any,
    event: any,
    rowRef: any
  ) {
    if (!rowRef || rowRef[name] === value) {
      return;
    }
    rowRef[name] = value;
    //rowRef.flagChanged = true;
    this.forceUpdate();
  }

  handleClose = () => {
    this.props.callback(false);
  };

  render() {
    const { props, state } = this;
    return (
      <>
        <Dialog size="extraLarge" handleClose={this.handleClose}>
          <DialogHeader title={props.title} onClose={this.handleClose} />
          <DialogBody>
            <Form
              ref="form"
              model={state.model}
              errors={state.errors}
              onCollectValues={() => this.onUpdateModel()}
              submit={this.submit}>
              <Fieldset className="row">
                <Fieldset>
                  {!props.flagViewOnly && (
                    <FileUpload
                      onSetValue={(name, uploadedFileName) => {
                        this.setState({ onAjax: false });
                        this.onFileUpload(name, uploadedFileName);
                      }}
                      buttonLabel="Upload file"
                      noLabel
                    />
                  )}
                </Fieldset>
              </Fieldset>
              <Grid
                columns={this.getColumns()}
                onAjax={state.onAjax}
                dataSource={state.documentsList}
                disablePagination
                stateless
              />
            </Form>
          </DialogBody>
          {!props.flagViewOnly && (
            <DialogFooter>
              <Button text="Submit" onClick={() => this.submit()} />
            </DialogFooter>
          )}
        </Dialog>
        {state.showDeleteItemPopUp && (
          <DialogConfirm
            onCancel={this.handleCloseDialogConfirm}
            onApprove={this.handleApproveDeleting}>
            Are you sure you want to delete?
          </DialogConfirm>
        )}
      </>
    );
  }

  handleCloseDialogConfirm = () => {
    this.setState({ showDeleteItemPopUp: false, selectedRefId: null });
  };

  handleApproveDeleting = () => {
    this.removeDocument(this.state.selectedRefId);
  };

  removeDocumentInitial(refid: number) {
    this.setState({ showDeleteItemPopUp: true, selectedRefId: refid });
  }

  removeDocument(refid: number) {
    let documentsList = this.state.documentsList;
    documentsList = documentsList.filter((item) => item.refid !== refid);
    this.setState({
      documentsList: documentsList,
      showDeleteItemPopUp: false,
      selectedRefId: null,
    });
  }

  submitSuccess() {
    const { props, state } = this;
    TimeSheetPageActions.saveUserDocs(
      props.userId,
      props.anchorDayDate,
      props.type,
      state.documentsList
    ).then(() => props.callback(true));
  }
}

const manageDocuments = Container.create(ManageDocuments, { withProps: true });
export default manageDocuments;
