import AbstractEqualFilter from './AbstractEqualFilter';
import OrderTypeDropdown from 'components/project/dropdown/OrderType';

export default class ProviderTypeFilter extends AbstractEqualFilter {
  name = 'orderType';
  field = 'Order Type';
  field1 = 'P';

  dropdown = null;

  getLabelByValue(value) {
    const optionList = this.dropdown.getOptions() || [];
    const obj = optionList.find((item) => {
      return item.value === value;
    });
    return obj && obj.label ? obj.label : '';
  }

  onSetValue(name, value) {
    const filterItem = { ...this.state.filterItem, value };

    const filterItemToSend = {
      ...this.state.filterItem,
      value: this.getLabelByValue(value),
    };

    this.setState({ filterItem }, () =>
      this.props.onSetValue(filterItemToSend)
    );
  }

  render() {
    return (
      <div className="order-type-filter">
        {this.renderConditions()}
        <OrderTypeDropdown
          className="col-sm-3"
          name="orderType"
          label="Value"
          ref={(dropdown) => {
            this.dropdown = dropdown;
          }}
          value={this.state.filterItem.value}
          onSetValue={(name, value) => this.onSetValue(name, value)}
        />
      </div>
    );
  }
}
