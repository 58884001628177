export const URL_AUDIT_TRAIL = '/logs/audit-trail';
export const URL_CURRENT_ACTIVITY_LOG = '/logs/current-activity-log';
export const URL_LOGIN_LOG = '/logs/login-log';

export const URL_USER_TYPE_PRIVILEGES =
  '/privilege-settings/user-type-privilege-settings';
export const URL_CLIENT_USER_SETTINGS =
  '/privilege-settings/client-user-settings';
export const URL_COMPANY_USER_SETTINGS =
  '/privilege-settings/company-user-settings';

export const URL_APPLICATION_USER = '/user-demographics/application-users';
export const URL_APPLICATION_USER_ADD = `${URL_APPLICATION_USER}/new`;
export const URL_APPLICATION_USER_EDIT = `${URL_APPLICATION_USER}/edit/:id`;
