import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import {
  TOrderNewPay,
  TFacilityData,
} from 'services/billing-coding/FacilityPreviewService';
import { dateToLocalTimezone } from 'utils/DateUtils';

export class PFacilityPreviewStore extends CommonPayload {}

export class SFacilityPreviewStore {
  dataSource: Array<TOrderNewPay> = [];
  facility: TFacilityData = new TFacilityData();
}

class FacilityPreviewStore extends ReduceStore<
  SFacilityPreviewStore,
  PFacilityPreviewStore
> {
  getInitialState() {
    return new SFacilityPreviewStore();
  }

  areEqual(state1: SFacilityPreviewStore, state2: SFacilityPreviewStore) {
    return false;
  }

  reduce(state: SFacilityPreviewStore, action: PFacilityPreviewStore) {
    const storeState = this.getState();
    switch (action.type) {
      case 'facility.FacilityBilling.OrderNewPayList1':
        storeState.dataSource = action.data?.map((el: TOrderNewPay) => ({
          ...el,
          dos_content: dateToLocalTimezone({
            date: el.dos_content,
            dateOnly: true,
          }),
        }));
        break;
      case 'facility.FacilityBilling.Getfacilitydetail_preinvoice':
        storeState.facility = action.data;
        break;
      case 'clear-facility-preview':
        const clear = new SFacilityPreviewStore();
        storeState.dataSource = clear.dataSource;
        storeState.facility = clear.facility;
        break;
      default:
        break;
    }
    return storeState;
  }
}

export default new FacilityPreviewStore(appDispatcher);
