import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Textarea } from 'components/form/textarea';
import { Input } from 'components/form/textField';
import { Radio } from 'components/form/radio';
import { Button } from 'components/button';
import resolver from './validation';

import { URL_MASTER_SETTINGS } from 'constant/path/systemSetup';

const TYPES = [
  {
    label: 'Company users',
    value: 'P',
  },
  {
    label: 'Client users',
    value: 'C',
  },
];

export interface FormModel {
  type: 'P' | 'C';
  no_of_days: number;
  description: string;
}

interface PropsType {
  clientUsers: FormModel;
  defaultValues: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
}

const MessageArchiveForm = ({
  defaultValues,
  clientUsers,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting },
    watch,
    reset,
    setValue,
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const watchableType = watch('type');

  const handleClickReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useEffect(() => {
    if (watchableType === 'P') {
      setValue('no_of_days', defaultValues.no_of_days);
      setValue('description', defaultValues.description, {
        shouldValidate: true,
      });
    } else if (watchableType === 'C') {
      setValue('no_of_days', clientUsers.no_of_days);
      setValue('description', clientUsers.description, {
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchableType]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row">
      <div className="col-md-8 col-lg-6">
        <Radio name="type" options={TYPES} control={control} />
        <Input
          name="no_of_days"
          label="Number of days"
          type="number"
          min={0}
          required
          control={control}
        />
        <Textarea
          name="description"
          label="Description"
          rows={3}
          control={control}
        />
        <ControlsLayout alignX="right">
          <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
            Back
          </Link>
          <Button
            type="button"
            variant="warning"
            text="Reset"
            onClick={handleClickReset}
          />
          <Button type="submit" text="Submit" disabled={isSubmitting} />
        </ControlsLayout>
      </div>
    </form>
  );
};

export default MessageArchiveForm;
