import { makeObservable, observable, action, set } from 'mobx';

import Notification from 'components/modal/Notification';
import { Variant } from 'components/badge/type';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { storeImport } from 'stores/_mobx/import';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';

const defaultValuePrettier = ({
  faxes,
  files,
  flag,
}: {
  faxes: ReportNumberType[];
  files: ReportFileType[];
  flag: FlagType;
}) => ({
  flag,
  faxes: faxes.map((fax) => ({
    faxNumber: fax.fax_no,
    receiverName: fax.receiver_name,
  })),
  files: files
    .filter((file) => Boolean(file.file_path))
    .map((file) => ({
      fileName: file.file_name,
      filePath: `doc_img/documents/${file.file_path}`,
      isChecked: file.fileselect,
      isCritical: Boolean(file.critical),
      faxType: faxReportTypes[file.report_type] || '',
    })),
});

const faxReportTypes: Record<TypeOfFaxReport, string> = {
  A: 'Addendum',
  P: 'Prelim',
  F: 'Final',
  '': '',
};

export const filterDefaultValues: FilterModel = {
  name: '',
  fax: '',
  mail: '',
  dateFrom: '',
  dateTo: '',
  subject: '',
};

export const faxStatusStyle: Record<FaxStatusType, Variant> = {
  'In Progress': 'warning',
  Sent: 'success',
  Failed: 'danger',
  Queued: 'light',
};

export type FaxStatusType = 'In Progress' | 'Sent' | 'Failed' | 'Queued';

export type TypeOfFaxReport = '' | 'P' | 'F' | 'A';

export type FaxTypeLabels = (typeof faxReportTypes)[TypeOfFaxReport];

interface FaxResponseType {
  createdDt: string;
  documentPath: string;
  errorDescription: null | string;
  plainTextMessage: string;
  faxStatus: FaxStatusType;
  faxType: 'F' | 'M';
  id: number;
  receiverFax: string;
  receiverMail: string;
  receiverName: string;
  subject: string;
}

export interface FaxType extends Omit<FaxResponseType, 'faxType'> {
  faxIsFile: boolean;
}

export interface FilterModel {
  name: string;
  fax: string;
  mail: string;
  dateFrom: string;
  dateTo: string;
  subject: string;
}

export interface FormModel {
  name: string;
  fax: string;
  email: string;
  subject: string;
  mode: 'M' | 'F';
  message: string;
  file: File | null;
}

interface ReportNumberType {
  fax_no: string;
  floor_name: string | null;
  phone: string;
  receiver_name: string;
  refid: number;
}

interface ReportFileType {
  critical: 0 | 1;
  file_desc: string;
  file_name: string;
  file_path: string;
  fileselect: boolean;
  image: string;
  refid: null | number;
  report_type: TypeOfFaxReport;
}

interface FaxReportResponseType {
  fax_faxnumber: ReportNumberType[];
  fax_report: ReportFileType[];
  fax_faxnumlabel?: string;
}

export type FlagType = 'OF' | 'PR' | 'PF' | 'OC' | 'ER' | 'MR' | '';

export interface FaxReportType {
  id: number;
  dos: string;
  facility: string;
  patient: string;
  examType: string;
  room: string;
  defaultValues: {
    flag: FlagType;
    faxes: { faxNumber: string; receiverName: string }[];
    files: {
      fileName: string;
      filePath: string;
      isChecked: boolean;
      isCritical: boolean;
      faxType?: FaxTypeLabels;
    }[];
  };
}

export interface PhoneReportType extends FaxReportType {
  facilityPhones: string[];
  subscriber: string;
  isSubscriberEditable: boolean;
  faxLabel: string[];
}

export interface FaxSavePayloadType {
  id: number;
  faxes: { faxNumber: string; receiverName: string }[];
  files: {
    fileName: string;
    filePath: string;
    isChecked: boolean;
    isCritical: boolean;
  }[];
  flag: FlagType;
  report: 'Y' | 'N';
  subject: string;
}

class Fax {
  fetching: boolean = false;
  fetchingReport: boolean = false;
  faxes: FaxType[] = [];
  faxesTotal: number = 0;
  idForDelete: number = 0;
  report: FaxReportType | null = null;
  reportPhone: PhoneReportType | null = null;
  filter: FilterModel = { ...filterDefaultValues };
  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'fax_grid' });

    makeObservable(this, {
      fetching: observable,
      fetchingReport: observable,
      faxes: observable,
      faxesTotal: observable,
      idForDelete: observable,
      report: observable,
      reportPhone: observable,
      filter: observable,

      setFetching: action,
      setFetchingReport: action,
      setFaxes: action,
      setCount: action,
      setIdForDelete: action,
      setReport: action,
      setReportPhone: action,
      setFilter: action,
      clearReport: action.bound,
      clearReportPhone: action.bound,
      clearIdForDelete: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingReport(fetching: boolean) {
    this.fetchingReport = fetching;
  }

  setFaxes(list: FaxType[]) {
    this.faxes = list;
  }

  setCount(count: number) {
    this.faxesTotal = count;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setReport(report: FaxReportType) {
    this.report = report;
  }

  setReportPhone(report: PhoneReportType) {
    this.reportPhone = report;
  }

  setFilter(filter: FilterModel) {
    this.filter = filter;
  }

  clearReport() {
    this.report = null;
  }

  clearReportPhone() {
    this.reportPhone = null;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  changeFilter(filter: FilterModel) {
    this.filter = filter;
  }

  async getFaxes(filter: any[]) {
    const { pagination } = this.page;

    const data = [pagination.skip, pagination.pageSize, ...filter];

    try {
      const response = await apiRequest<FaxResponseType[]>({
        url: 'email.fax.GetFaxList',
        data,
      });

      const faxes = response.map(({ id, faxType, documentPath, ...rest }) => ({
        id: Number(id),
        faxIsFile: faxType === 'F',
        ...rest,
        createdDt: dateToLocalTimezone({
          date: rest.createdDt,
        }),
        documentPath: documentPath?.replace(/^\//, '') || '',
      }));
      this.setFaxes(faxes);
    } catch (e: any) {
      this.setFaxes([]);
    }
  }

  async getFaxesCount(data: any[]) {
    try {
      const response = await apiRequest<number>({
        url: 'email.fax.GetFaxListCount',
        data,
      });

      const total = Number(response) || 0;

      this.setCount(total);
    } catch (e: any) {
      this.setCount(0);
    }
  }

  getFaxesMain = () => {
    const { filter } = this;

    this.setFetching(true);

    const { dateFrom, dateTo } = getDateRangeBounds({
      from: filter.dateFrom,
      to: filter.dateTo,
    });

    const filterPayload = [
      filter.name,
      filter.fax,
      filter.mail,
      dateFrom,
      dateTo,
      filter.subject,
    ];

    const promiseList = this.getFaxes(filterPayload);

    const promiseCount = this.getFaxesCount(filterPayload);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async resendFax(id: number) {
    this.setFetching(true);
    try {
      await apiRequest({
        url: 'email.fax.UpdateFaxStatus',
        data: [id, 0],
      });

      const idx = this.faxes.findIndex((faxData) => faxData.id === id);

      const faxDetail = {
        ...this.faxes[idx],
        errorDescription: '',
        faxStatus: 'Queued',
      };

      set(this.faxes, idx, faxDetail);

      Notification.success('Fax has been resent!');
    } catch (e: any) {
      Notification.danger("Fax didn't send!");
    } finally {
      this.setFetching(false);
    }
  }

  async addFax(payload: FormModel) {
    try {
      const fileName =
        payload.mode === 'F'
          ? await storeImport.uploadFile({ file: payload.file })
          : '';

      const data = [
        payload.name,
        payload.fax,
        payload.email,
        payload.subject,
        payload.mode,
        payload.mode === 'F' ? fileName : payload.message,
      ];

      const result = await apiRequest<'SE' | 'S' | 'X'>({
        url: 'email.fax.faxaddedit',
        data,
      });
      if (result === 'S') {
        Notification.success('The Fax was save successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async deleteFax() {
    this.setFetching(true);

    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'email.fax.DeleteFax',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Fax entry was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("Error occurred, so it can't be deleted!");
      this.setFetching(false);
      return false;
    }
  }

  async getFaxReport({ order, flag }: { order: any; flag: FlagType }) {
    this.setFetchingReport(true);
    try {
      const data = {
        orderId: order.refid,
        facilityId: order.facilityId,
        facilityName: order.facilitynm,
        flag,
      };

      const { fax_report, fax_faxnumber } =
        await apiRequest<FaxReportResponseType>({
          url: 'email.fax.GetorderReportFax',
          data,
        });

      const defaultValues = defaultValuePrettier({
        flag,
        faxes: fax_faxnumber,
        files: fax_report,
      });

      const report: FaxReportType = {
        id: data.orderId,
        dos: dateToLocalTimezone({ date: order.dos, dateOnly: true }),
        facility: order.facilitynm,
        patient: order.patientnm,
        examType: order.examtype,
        room: order.room,
        defaultValues,
      };

      this.setReport(report);
    } catch (e: any) {
      this.setReport(null);
    } finally {
      this.setFetchingReport(false);
    }
  }

  async getPhoneReport({
    order,
    flag,
    isSubscriberEditable,
  }: {
    order: any;
    flag: FlagType;
    isSubscriberEditable: boolean;
  }) {
    this.setFetchingReport(true);
    try {
      const data = {
        orderId: order.refid,
        facilityId: order.facilityid,
        facilityName: order.facilitynm,
        flag,
      };

      const reportPromise = apiRequest<FaxReportResponseType>({
        url: 'email.fax.GetorderReportFax',
        data,
      });

      const facilityPhonesPromise = apiRequest<string[]>({
        url: 'facility.FacilityMaster.GetFacilityPhoneNos',
        data: [order.facilityid],
      });

      const [{ fax_faxnumber, fax_report, fax_faxnumlabel }, facilityPhones] =
        await Promise.all([reportPromise, facilityPhonesPromise]);

      const defaultValues = defaultValuePrettier({
        flag,
        faxes: fax_faxnumber,
        files: fax_report,
      });

      const faxLabel = fax_faxnumlabel
        .trim()
        .replace(/\s+/g, ' ')
        .replace('\\n', '')
        .split(' ');

      const report: PhoneReportType = {
        id: data.orderId,
        dos: dateToLocalTimezone({ date: order.dos, dateOnly: true }),
        facility: order.facilitynm,
        patient: order.patientnm,
        examType: order.examtype,
        room: order.room,
        facilityPhones,
        isSubscriberEditable,
        subscriber: order.spoke_with,
        faxLabel,
        defaultValues,
      };
      this.setReportPhone(report);
    } catch (e: any) {
      this.setReportPhone(null);
    } finally {
      this.setFetchingReport(false);
    }
  }

  async sendFax({
    flag,
    subject,
    report,
    files,
    faxes,
    id,
  }: FaxSavePayloadType) {
    const data = {
      faxarr: faxes.map((fax) => ({
        receivername: fax.receiverName,
        faxno: fax.faxNumber,
      })),
      reportarr: files.map((file) => ({
        fileselect: file.isChecked,
        file_path: file.filePath,
      })),
      orderId: id,
      faxreport: report,
      faxsubject: subject,
      flag,
    };
    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'pdfcreater.OrderPdfGenerator.SaveFax',
        data,
      });
      if (response === 'S') {
        Notification.success('Fax was sent successfully!');
        return true;
      } else {
      }
    } catch (e: any) {
      Notification.danger("Can't send fax");
      return false;
    }
  }

  async saveFaxSubscriber({
    subscriber,
    id,
  }: {
    subscriber: string;
    id: number;
  }) {
    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'email.fax.SaveInfo',
        data: [subscriber, id],
      });
      if (response === 'S') {
        Notification.success('Details are successfully Saved!');
        return true;
      }
      throw Error('');
    } catch (error) {
      Notification.danger("Can't save details!");
      return false;
    }
  }
}

export const storeFax = new Fax();
