import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Container } from 'flux/utils';

import Notification from 'components/modal/Notification';
import Text from 'components/form/input/Text';
import Title from 'components/project/common/title';
import Form from 'components/form/Form';
import FormControl from 'components/form/FormControl';
import Fieldset from 'components/form/Fieldset';
import { Grid } from 'components/grid';
import UsaStateDropdown from 'components/project/dropdown/UsaStateDropdown';
import NpiInput from 'components/project/NpiInput';
import { Button } from 'components/button';
import JurisdictionZipCodeRangesGrid from 'components/project/common/JurisdictionZipCodeRangesGrid';
import JurisdictionZipCodesGrid from './components/JurisdictionZipCodesGrid';

import JurisdictionDetailsActions from 'actions/system-setup/master-setup/operations/jurisdiction/JurisdictionDetailsActions';
import {
  TAddJurisdiction,
  TUpdateJurisdiction,
} from 'services/system-setup/master-setup/operations/jurisdiction/JurisdictionDetailsService';
import JurisdictionDetailsStore from 'stores/system-setup/masterSettings/jurisdiction/JurisdictionDetailsStore';
import { URL_JURISDICTION } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

export interface PJurisdictionDetailsPage
  extends RouteComponentProps<MatchParams> {
  updateOverview: () => void;
}

export class SJurisdictionDetailsPage {
  modalityList: { dataSource: any } = null;
  zipCodeList: { dataSource: any } = null;
  zipCodeRangeList: { dataSource: any } = null;
  model: any = {};
  errors: any = {};
}

export class JurisdictionDetailsPage extends React.Component<
  PJurisdictionDetailsPage,
  SJurisdictionDetailsPage
> {
  getTitle() {
    return `${this.isNew() ? 'Add' : 'Edit'} Jurisdiction`;
  }

  isNew() {
    return 'new' === this.props.match.params.id;
  }

  getId() {
    return Number(this.props.match.params.id);
  }

  static getStores() {
    return [JurisdictionDetailsStore];
  }

  static calculateState() {
    return JurisdictionDetailsStore.getState();
  }

  load() {
    JurisdictionDetailsActions.loadJurisdiction(this.getId());
  }

  reset = () => {
    JurisdictionDetailsActions.resetJurisdiction();
  };

  componentWillUnmount() {
    this.reset();
  }

  componentDidMount() {
    JurisdictionDetailsActions.loadStateList();
    this.load();
    const jurId = this.getId();
    JurisdictionDetailsActions.loadModalityList(jurId);
  }

  getColumns() {
    const textFieldCallback = (row: any, name: string, value: string) => {
      row[name] = value;
      this.forceUpdate();
    };
    return [
      {
        name: 'modality',
        title: 'Modality',
      },
      {
        name: 'npinumber',
        title: 'NPI #',
        render: (rowItem: any, row: any) => {
          return (
            <NpiInput
              name="npinumber"
              noLabel
              value={rowItem}
              onSetValue={(name, value) => {
                textFieldCallback(row, name, value);
              }}
            />
          );
        },
      },
      {
        name: 'npiname',
        title: 'NPI Name',
        render: (rowItem: any, row: any) => {
          return (
            <Text
              name="npiname"
              noLabel
              value={rowItem}
              onSetValue={(name, value) => {
                textFieldCallback(row, name, value);
              }}
            />
          );
        },
      },
    ];
  }

  save(jurisdiction: TAddJurisdiction | TUpdateJurisdiction) {
    if (this.isNew()) {
      JurisdictionDetailsActions.createJurisdiction(
        jurisdiction as TAddJurisdiction
      ).then((response) => this.saveCallback(response));
    } else {
      JurisdictionDetailsActions.updateJurisdiction(
        jurisdiction as TUpdateJurisdiction
      ).then((response) => this.saveCallback(response));
    }
  }

  saveCallback(response: 'SE' | 'S' | 'E') {
    if (response === 'S') {
      Notification.success('Successfully saved!');
      if (this.props.updateOverview) {
        this.props.updateOverview();
      }
      this.goToOverview();
    } else if (response === 'E') {
      Notification.danger('Same jurisdiction area already exists!');
    } else {
      Notification.danger('An error occurred!');
    }
  }

  submitSuccess(): any {
    const s = this.state;
    const zipList = s.zipCodeList.dataSource;
    const zipRangeList = s.zipCodeRangeList.dataSource;
    const jurisdiction = Object.assign({}, s.model, {
      modalityList: s.modalityList.dataSource,
    });
    if (this.isNew()) {
      if (zipList.length > 0) {
        jurisdiction.zipCodeList = zipList;
      }
      if (zipRangeList.length > 0) {
        jurisdiction.zipCodeRangeList = zipRangeList;
      }
    }
    if (zipList.length > 0 || zipRangeList.length > 0) {
      this.save(jurisdiction);
    } else {
      Notification.danger('Need at least one ZIP!');
    }
  }
  handleSubmit = (
    updatedModel: any,
    hasErrors: boolean,
    updatedErrors: any
  ) => {
    const isZipCodeSelected = Boolean(
      this.state.zipCodeList.dataSource.length ||
        this.state.zipCodeRangeList.dataSource.length
    );

    if (hasErrors || !isZipCodeSelected) {
      const { state } = this;
      const model = { ...state.model, ...updatedModel };
      const errors = {
        ...state.errors,
        ...updatedErrors,
        zipCodes: isZipCodeSelected
          ? ''
          : 'You have to select or ZIP code or a range of ZIP codes!',
      };
      this.setState({ model, errors });
    } else {
      this.submitSuccess();
    }
  };

  render() {
    const { modalityList, errors } = this.state;

    return (
      <div className="jurisdiction-add-page">
        <Title title={this.getTitle()} />
        <Form
          id="add_jurisdiction_form"
          submit={this.handleSubmit}
          onCollectValues={this.updateJurisdictionModelAndErrors.bind(this)}
          model={this.state.model}
          errors={this.state.errors}>
          <UsaStateDropdown
            name="stateId"
            label="State"
            className="col-md-6"
            validations="required"
            errorMessages="Please, select a state!"
          />
          <Text
            name="area"
            label="Area"
            className="col-md-6"
            validations="required"
            errorMessages="Please, enter the area!"
          />
          <Fieldset>
            <Grid
              columns={this.getColumns()}
              dataSource={modalityList.dataSource}
              dataSourceCount={modalityList.dataSource.length}
              disablePagination
              noControl
            />
          </Fieldset>

          <Fieldset className="row">
            <Fieldset className="col-sm-6">
              <legend className="required">ZIP codes</legend>
              <JurisdictionZipCodesGrid
                isNew={this.isNew()}
                id={this.getId()}
              />
            </Fieldset>

            <Fieldset className="col-sm-6">
              <legend>ZIP code ranges</legend>
              <JurisdictionZipCodeRangesGrid
                isNew={this.isNew()}
                id={this.getId()}
              />
            </Fieldset>
          </Fieldset>
          {errors.zipCodes ? (
            <div className="text-danger">{errors.zipCodes}</div>
          ) : null}

          <FormControl className="mt-4">
            <Link to={URL_JURISDICTION} className="btn btn-danger">
              Back
            </Link>
            <Button variant="warning" text="Reset" onClick={this.reset} />
            <Button type="submit" text="Submit" />
          </FormControl>
        </Form>
      </div>
    );
  }

  updateJurisdictionModelAndErrors(
    name: string,
    value: string,
    errorMessages: any
  ) {
    JurisdictionDetailsActions.updateJurisdictionModelAndErrorsProperty(
      name,
      value,
      errorMessages
    );
  }

  goToOverview() {
    this.props.history.push(URL_JURISDICTION);
  }
}

export default Container.create(JurisdictionDetailsPage);
