import { IconButton } from 'components/button';
import { SpinnerFixed } from 'components/spinner';

interface PropsType {
  fetching: boolean;
  title: string;
  onUpdate: () => void;
  children: React.ReactNode;
}

const DashboardCard = ({ fetching, title, onUpdate, children }: PropsType) => (
  <div className="col-lg-4 col-md-6 dashboard">
    <div className="dashboard-title">
      <h3>{title}</h3>
      <IconButton className="dashboard-refresh" onClick={onUpdate}>
        <i className="bi bi-arrow-repeat" />
      </IconButton>
    </div>
    <div className="dashboard-body">{children}</div>
    {fetching && <SpinnerFixed />}
  </div>
);

export default DashboardCard;
