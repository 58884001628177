export default class WorkflowOrderColumnsDescriptor {
  public static PlacedOrderInitial: Array<string> = [
    'ordersrc',
    'assign',
    'last_name',
    'first_name',
    'group_affiliation',
    'dos',
    'dostime',
    'room',
    'facility',
    'division',
    'faxReq',
    'cancel',
    'modality',
    'cpt',
    'visitInfo',
    'notes',
    'ordercreated_dt',
    'exam_request',
  ];

  public static PlacedOrderCorporateMedical: Array<string> = [
    'last_name',
    'first_name',
    'group_affiliation',
    'dos',
    'dostime',
    'room',
    'facility',
    'division',
    'cancel',
    'cpt',
    'visitInfo',
    'ordercreated_dt',
    'exam_request',
  ];

  public static PlacedOrderPhysician: Array<string> = [
    'last_name',
    'first_name',
    'group_affiliation',
    'dos',
    'dostime',
    'room',
    'facility',
    'division',
    'cancel',
    'modality',
    'cpt',
    'visitInfo',
    'ordercreated_dt',
    'exam_request',
  ];

  public static PlacedOrderNurse: Array<string> = [
    'last_name',
    'first_name',
    'group_affiliation',
    'dos',
    'dostime',
    'room',
    'cancel',
    'modality',
    'cpt',
    'visitInfo',
    'ordercreated_dt',
    'exam_request',
  ];

  public static PlacedOrderFacilityAdmin: Array<string> = [
    'ordersrc',
    'assign',
    'last_name',
    'first_name',
    'group_affiliation',
    'dos',
    'dostime',
    'room',
    'facility',
    'division',
    'faxReq',
    'cancel',
    'cpt',
    'visitInfo',
    'visit_notes',
    'ordercreated_dt',
    'exam_request',
  ];

  public static PlacedOrderFacilityBillingCoordinator: Array<string> = [
    'ordersrc',
    'assign',
    'last_name',
    'first_name',
    'group_affiliation',
    'dos',
    'dostime',
    'room',
    'facility',
    'division',
    'faxReq',
    'cancel',
    'modality',
    'cpt',
    'visitInfo',
    'visit_notes',
    'ordercreated_dt',
    'exam_request',
  ];

  public static PlacedOrderDefault: Array<string> = [
    'ordersrc',
    'assign',
    'last_name',
    'first_name',
    'group_affiliation',
    'dos',
    'dostime',
    'room',
    'facility',
    'division',
    'faxReq',
    'priority',
    'cancel',
    'modality',
    'cpt',
    'visitInfo',
    'notes',
    'ordercreated_dt',
    'exam_request',
    'accessionNumbersStr',
    'patientdob',
    'location',
    'ssn',
    'technologistnm',
    'pos',
    'orderassigned_date',
    'diagnosis',
    'auditLog',
  ];

  public static UnassignedOrdersInitial: Array<string> = [
    'ordersrc',
    'assign',
    'last_name',
    'first_name',
    'group_affiliation',
    'visitInfo',
    'dostime',
    'room',
    'facility',
    'division',
    'location',
    'modality',
    'cpt',
    'diagnosis',
    'notes',
    'faxReq',
    'cancel',
    'exam_request',
  ];

  public static UnassignedOrdersDefault: Array<string> = [
    'ordersrc',
    'assign',
    'last_name',
    'first_name',
    'group_affiliation',
    'visitInfo',
    'room',
    'facility',
    'division',
    'location',
    'ordercreated_dt',
    'modality',
    'cpt',
    'diagnosis',
    'notes',
    'faxReq',
    'cancel',
    'dos',
    'dostime',
    'exam_request',
    'priority',
    'accessionNumbersStr',
    'patientdob',
    'ssn',
    'technologistnm',
    'pos',
    'orderassigned_date',
    'auditLog',
  ];

  public static AssignedOrdersInitial: Array<string> = [
    'unreassign',
    'last_name',
    'first_name',
    'group_affiliation',
    'visitInfo',
    'room',
    'facility',
    'division',
    'location',
    'orderassigned_date',
    'technologistnm',
    'acknowledgement',
    'modality',
    'notes',
    'faxReq',
    'cancel',
    'exam_request',
  ];

  public static AssignedOrdersDefault: Array<string> = [
    'unreassign',
    'last_name',
    'first_name',
    'group_affiliation',
    'visitInfo',
    'room',
    'facility',
    'division',
    'location',
    'ordercreated_dt',
    'technologistnm',
    'acknowledgement',
    'modality',
    'notes',
    'faxReq',
    'priority',
    'cancel',
    'exam_request',
    'dos',
    'dostime',
    'accessionNumbersStr',
    'patientdob',
    'ssn',
    'pos',
    'orderassigned_date',
    'cpt',
    'diagnosis',
    'auditLog',
    'viewImage',
  ];

  public static ReportManagerInitial: Array<string> = [
    'dos',
    'dostime',
    'last_name',
    'first_name',
    'group_affiliation',
    'room',
    'facility',
    'division',
    'location',
    'modality',
    'physician',
    'exam_request',
    'eunity',
  ];

  public static ReportManagerDefault: Array<string> = [
    'dos',
    'dostime',
    'last_name',
    'first_name',
    'group_affiliation',
    'room',
    'addReport',
    'faxReport',
    'priority',
    'facility',
    'division',
    'location',
    'modality',
    'physicianname',
    'cancel',
    'viewImage',
    'exam_request',
    'faxReq',
    'visitInfo',
    'ordersrc',
    'accessionNumbersStr',
    'patientdob',
    'ssn',
    'technologistnm',
    'pos',
    'orderassigned_date',
    'ordercreated_dt',
    'cpt',
    'diagnosis',
    'phoned',
    'viewImage',
    'faxed',
    'report',
    'documents',
    'addendum',
    'complete',
    'auditLog',
  ];

  public static CallbackManagerInitial: Array<string> = [
    'dos',
    'dostime',
    'last_name',
    'first_name',
    'group_affiliation',
    'phoneResults',
    'facility',
    'division',
    'room',
    'recipientFaxNumber',
    'faxed_time',
    'exam_request',
    'viewImage',
  ];

  public static CallbackManagerDefault: Array<string> = [
    'dos',
    'dostime',
    'last_name',
    'first_name',
    'group_affiliation',
    'phoneResults',
    'facility',
    'division',
    'room',
    'faxed_time' /*@todo check that is report faxed time*/,
    'cancel',
    'recipientFaxNumber',
    'exam_request',
    'visitInfo',
    'viewImage',
    'accessionNumbersStr',
    'patientdob',
    'location',
    'ssn',
    'technologistnm',
    'pos',
    'orderassigned_date',
    'modality',
    'ordercreated_dt',
    'cpt',
    'diagnosis',
    'phoned',
    'viewImage',
    'faxed',
    'priority',
    'report',
    'documents',
    'addendum',
    'auditLog',
  ];

  public static VisitScheduleInitial: Array<string> = [
    'DOS',
    'dostime',
    'last_name',
    'first_name',
    'group_affiliation',
    'facility',
    'division',
    'modality',
    'orderassigned_date',
    'visit',
    'noofpatients',
    'notes',
    'visitInfo',
    'technologistnm',
    'viewImage',
    'examRequest',
    'visit_notes',
  ];

  public static VisitScheduleDefault: Array<string> = [
    'dos',
    'dostime',
    'last_name',
    'first_name',
    'group_affiliation',
    'room',
    'facility',
    'division',
    'modality',
    'orderassigned_date',
    'visit',
    'noofpatients',
    'visit_notes',
    'notes',
    'visitInfo',
    'technologistnm',
    'cancel',
    'ordercreated_dt',
    'vw_patientid',
    'clientMRN',
    'gender',
    'ssn',
    'patientdob',
    'pos',
    'diagnosis',
    'cpt',
    'priority',
    'viewImage',
    'exam_request',
    'reason',
    'auditLog',
  ];

  public static ExamReviewCorporateMedicalAndFacilityAdmin: Array<string> = [
    'dos',
    'dostime',
    'last_name',
    'first_name',
    'group_affiliation',
    'room',
    'facility',
    'division',
    'modality',
    'notes',
    'accessionNumbersStr',
    'cpt',
    'visitInfo',
    'report',
    'faxed',
    'phoned',
    'documents',
    'addendum',
    'viewImage',
    'exam_request',
  ];

  public static ExamReviewNurse: Array<string> = [
    'dos',
    'dostime',
    'last_name',
    'first_name',
    'group_affiliation',
    'room',
    'modality',
    'notes',
    'accessionNumbersStr',
    'cpt',
    'visitInfo',
    'report',
    'faxed',
    'phoned',
    'documents',
    'addendum',
    'viewImage',
    'exam_request',
  ];

  public static ExamReviewPhysician: Array<string> = [
    'dos',
    'last_name',
    'first_name',
    'group_affiliation',
    'facility',
    'division',
    'physicianname',
    'modality',
    'notes',
    'cpt',
    'visitInfo',
    'report',
    'viewImage',
    'exam_request',
  ];

  public static ExamReviewFacilityBillingCoordinator: Array<string> = [
    'dos',
    'dostime',
    'last_name',
    'first_name',
    'group_affiliation',
    'room',
    'facility',
    'division',
    'technologistnm',
    'physicianname',
    'physician_assignment',
    'modality',
    'notes',
    'accessionNumbersStr',
    'cpt',
    'visitInfo',
    'report',
    'faxed',
    'phoned',
    'documents',
    'addendum',
    'viewImage',
    'faxReq',
    'exam_request',
  ];

  public static ExamReviewDefault: Array<string> = [
    'revert_cancelled',
    'dos',
    'dostime',
    'last_name',
    'first_name',
    'group_affiliation',
    'room',
    'facility',
    'division',
    'technologistnm',
    'acknowledgement',
    'physicianname',
    'physician_assignment',
    'modality',
    'notes',
    'accessionNumbersStr',
    'cpt',
    'visitInfo',
    'report',
    'faxed',
    'phoned',
    'documents',
    'addendum',
    'viewImage',
    'viewImage',
    'cancelreason',
    'faxReq',
    'ordercreated_dt',
    'vw_patientid',
    'clientMRN',
    'gender',
    'patientdob',
    'ssn',
    'pos',
    'eligibility_status',
    'exam_request',
    'priority',
    'diagnosis',
    'auditLog',
    'exam_request',
  ];
}
