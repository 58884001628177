import { ReduceStore } from 'flux/utils';
import { Dispatcher } from 'flux';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';
import { TUpdatedOrderNote } from 'actions/workflow/order/OrderOverviewActions';

export class POrderOverviewStore extends CommonPayload {
  count: number;
}

export class SOrderOverviewStore {
  notes: Array<TUpdatedOrderNote> = [];
  dataSource: Array<TSignOrderItem> = [];
  dataSourceCount: number = 0;
  fetching: boolean = false;
}

class OrderOverviewStore extends ReduceStore<
  SOrderOverviewStore,
  POrderOverviewStore
> {
  getInitialState() {
    return new SOrderOverviewStore();
  }

  areEqual(state1: SOrderOverviewStore, state2: SOrderOverviewStore) {
    return false;
  }

  reduce(state: SOrderOverviewStore, action: POrderOverviewStore) {
    switch (action.type) {
      case 'orders-order-overview':
        this.getState().dataSource = action.data;
        this.getState().dataSourceCount = action.count;
        this.getState().fetching = false;
        break;
      case 'order.OrderNotes.GetNotes':
        this.getState().notes = action.data;
        break;
      default:
        break;
    }
    return state;
  }
}

export default new OrderOverviewStore(
  appDispatcher as Dispatcher<POrderOverviewStore>
);
