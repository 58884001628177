import { apiRequest } from '../RequestService';

class ArAgingReportService {
  loadList(conditionList, joint) {
    return apiRequest({
      url: 'report.ReportMaster.GetarageReport',
      data: [conditionList, joint],
    });
  }

  createPdf(list, description) {
    return apiRequest({
      url: 'pdfcreater.OrderPdfGenerator.GetArageReport',
      data: [list, description],
    });
  }
}
export default new ArAgingReportService();
