import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Dialog, {
  DialogHeader,
  DialogFooter,
  DialogBody,
} from 'components/modal/dialog';
import { Input } from 'components/form/textField';
import { Button } from 'components/button';
import validation from './validation';

import { storeUser } from 'stores/_mobx/users/user';
import {
  PASSWORD_LENGTH_MIN,
  PASSWORD_LENGTH_MAX,
} from 'constant/PasswordParams';

interface PropsType {
  minLength?: number;
  maxLength?: number;
  alphaNumeric?: boolean;
  onClose: () => void;
}

interface FormModel {
  password: string;
  repeatPassword: string;
}

const defaultValues: FormModel = {
  password: '',
  repeatPassword: '',
};

const DialogFormResetPassword = ({
  minLength = PASSWORD_LENGTH_MIN,
  maxLength = PASSWORD_LENGTH_MAX,
  alphaNumeric = false,
  onClose,
}: PropsType) => {
  const { passwordResetDetails } = storeUser;

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(
      validation({
        minLength,
        maxLength,
        alphaNumeric,
      })
    ),
  });

  const handleClickSubmit = () => {
    handleSubmit(storeUser.resetPassword)();
  };

  const handleResetForm = () => {
    reset(defaultValues);
  };

  return passwordResetDetails.pin ? (
    <Dialog>
      <DialogHeader title="Lattice Pro" />
      <DialogBody>
        <p>Password was reset!</p>
        <p>{`New PIN: ${passwordResetDetails.pin}`}</p>
        <p>Check your mail for more information</p>
      </DialogBody>
      <DialogFooter>
        <Button text="Ok" onClick={onClose} />
      </DialogFooter>
    </Dialog>
  ) : (
    <Dialog handleClose={onClose}>
      <DialogHeader title="Confirm" onClose={onClose} />
      <DialogBody className={isSubmitting ? 'on-ajax' : ''}>
        <form onSubmit={handleClickSubmit}>
          <Input
            name="password"
            label="Password"
            type="password"
            required
            control={control}
          />
          <Input
            name="repeatPassword"
            label="Repeat Password"
            type="password"
            required
            control={control}
          />
        </form>
      </DialogBody>

      <DialogFooter>
        <Button text="Clear" variant="warning" onClick={handleResetForm} />
        <Button text="Change Password" onClick={handleClickSubmit} />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogFormResetPassword);
