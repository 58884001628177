import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import { Observer } from 'mobx-react-lite';

import { ControlsLayout } from 'components/layout';
import { Button, IconButton } from 'components/button';
import { Select } from 'components/form/select';
import resolver from './validation';

import { UnitFormModel } from 'stores/_mobx/workflow/equipmentInventory/ultrasoundUnit';
import { storeUltrasound } from 'stores/_mobx/workflow/equipmentInventory/ultrasound';
import { storeComputer } from 'stores/_mobx/workflow/equipmentInventory/computer';
import { storeProbe } from 'stores/_mobx/workflow/equipmentInventory/probe';

const initialValues: UnitFormModel = {
  refid: null,
  computerId: 0,
  ultrasoundId: 0,
  probeIds: [{ ProbeID: 0 }],
};

interface PropsType {
  backUrl: string;
  defaultValues?: UnitFormModel;
  onSubmit: (data: UnitFormModel) => Promise<any>;
}

const FormModifyUltrasoundUnit = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'probeIds',
  });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useEffect(() => {
    storeUltrasound.getOptionsUltrasound();
    storeComputer.getOptions();
    storeProbe.getOptionsProbe();
  }, []);

  return (
    <form
      className={isSubmitting ? 'on-ajax' : ''}
      onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <Observer>
          {() => (
            <Select
              name="ultrasoundId"
              label="Ultrasound"
              className="col-sm-10 col-md-8 col-lg-4"
              required
              isLoading={storeUltrasound.fetchingOption}
              options={storeUltrasound.optionsUltrasound}
              control={control}
            />
          )}
        </Observer>
        <div />
        <Observer>
          {() => (
            <Select
              name="computerId"
              label="Computer"
              className="col-sm-10 col-md-8 col-lg-4"
              required
              isLoading={storeComputer.fetchingOption}
              options={storeComputer.options}
              control={control}
            />
          )}
        </Observer>
      </div>

      <b>Probe</b>
      <hr className="col-sm-10 col-md-8 col-lg-4" />

      <Observer>
        {() => (
          <>
            {fields.map((item, index) => (
              <div key={item.id} className="row align-items-center mb-4">
                <Select
                  name={`probeIds.${index}.ProbeID`}
                  className="col-sm-10 col-md-8 col-lg-4 my-0"
                  isLoading={storeProbe.fetchingOption}
                  options={storeProbe.optionsProbe}
                  control={control}
                />
                {fields.length - 1 === index ? (
                  <IconButton
                    className="col-sm-auto text-primary"
                    onClick={() => append({ ProbeID: 0 })}>
                    <i className="bi bi-plus-circle" />
                  </IconButton>
                ) : (
                  <IconButton
                    className="col-sm-auto text-primary"
                    onClick={() => remove(index)}>
                    <i className="bi bi-x-circle" />
                  </IconButton>
                )}
              </div>
            ))}
          </>
        )}
      </Observer>

      <ControlsLayout>
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormModifyUltrasoundUnit;
