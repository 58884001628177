import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface PropsType {
  minLength: number;
  maxLength: number;
  alphaNumeric: boolean;
}

const validation = (props: PropsType) =>
  yupResolver(
    yup.object().shape({
      newPassword: yup
        .string()
        .required('Please specify password')
        .min(
          props.minLength,
          `Password length must be between ${props.minLength} and ${props.maxLength}`
        )
        .max(
          props.maxLength,
          `Password length must be between ${props.minLength} and ${props.maxLength}`
        )
        .test({
          test: (newPassword: string) =>
            props.alphaNumeric
              ? /(\d[a-z])|([a-z]\d)/i.test(newPassword)
              : true,
          message: 'Password should include at least 1 letter and 1 number',
        }),
      newPasswordConfirm: yup
        .string()
        .required('Please repeat password')
        .oneOf([yup.ref('newPassword'), null], 'Password did not match'),
    })
  );

export default validation;
