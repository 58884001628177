import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validation = yupResolver(
  yup.object().shape({
    stateId: yup
      .number()
      .positive()
      .integer()
      .required('Please, select the "State"!'),
    carrier: yup
      .string()
      .min(3, 'This is not valid length.')
      .max(15, 'This is not valid length.')
      .required("Can't be empty."),
    locality: yup
      .string()
      .min(2, 'This is not valid length.')
      .max(2, 'This is not valid length.')
      .required("Can't be empty."),
    lab_cb_locality: yup
      .string()
      .min(2, 'This is not valid length.')
      .max(2, 'This is not valid length.')
      .required("Can't be empty."),
    rural_index: yup.string().max(1, 'This is not valid length.'),
    rural_index_2: yup.string().max(1, 'This is not valid length.'),
    part_b_drug_indicator: yup
      .string()
      .max(1, 'This is not valid length.')
      .required("Can't be empty."),
    year_qtr: yup
      .string()
      .min(5, 'This is not valid length.')
      .max(5, 'This is not valid length.')
      .required("Can't be empty."),
  })
);

export default validation;
