import { Redirect, Route, useLocation } from 'react-router-dom';
// @ts-ignore
import useFluxStore from 'flux-hooks';

import UserProfileStore from 'stores/UserProfileStore';

interface PropsType {
  path: string;
  exact?: boolean;
  component: React.FunctionComponent | React.ClassType<any, any, any>;
}

const getUrl = (search: string) => {
  const backEncodedUrl = search?.startsWith('?backUrl=')
    ? search.replace(/^\?backUrl=/, '')
    : '';
  return backEncodedUrl ? decodeURIComponent(backEncodedUrl) : '/';
};

const RedirectRoute = ({ component: Component, ...rest }: PropsType) => {
  const { loggedIn, success } = useFluxStore(
    UserProfileStore,
    (prevState: any, store: any) => store.getState()
  );

  const { search } = useLocation();

  const isLoggedIn = loggedIn && success;

  const url = isLoggedIn ? getUrl(search) : '';

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Redirect to={url} /> : <Component {...props} />
      }
    />
  );
};

export default RedirectRoute;
