import { apiRequest } from 'services/RequestService';

class VisitScheduleService {
  addPatientVisit(orderId: any, dos: any, visit: any) {
    return apiRequest<any>({
      url: 'patient.PatientInfo.AddPatientVisit',
      data: [orderId, dos, visit],
    });
  }
}

export default new VisitScheduleService();
