import React from 'react';

import CommonLink from 'page/components/ExportLink/CommonLink';
import useRequest from 'components/hook/useRequest';

import {
  storeTransmitUnassignment,
  AppointmentType,
} from 'stores/_mobx/billingCodding/transmitUnassignment';

interface PropsType {
  details: AppointmentType;
}

const ExportLink = ({ details }: PropsType) => {
  const { fetching, controlledAction } = useRequest<AppointmentType>(
    storeTransmitUnassignment.exportAppointmentToPdf
  );

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    controlledAction(details);
  };

  return <CommonLink link="/" fetching={fetching} onClick={handleClick} />;
};

export default ExportLink;
