import React from 'react';

import { Link } from 'react-router-dom';

interface PropsType {
  url: string;
}

const Logo = ({ url }: PropsType) => (
  <Link to="/" className="logo">
    <img src={url} alt="Lattice Pro" />
  </Link>
);

export default Logo;
