import { makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionsStateResponse {
  data: string;
  label: string;
  user_type: string;
}

export interface OptionType {
  value: number;
  label: string;
}

export class DropdownTechnologist {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,
    });
  }

  async getOptions() {
    if (this.fetching) return Promise.resolve();
    try {
      runInAction(() => {
        this.fetching = true;
      });
      const list = await apiRequest<OptionsStateResponse[]>({
        url: 'order.Order.TechDropDown',
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));
      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }

  findOption = (id: number) => this.options.find(({ value }) => value === id);
}

export const storeDropdownTechnologist = new DropdownTechnologist();
