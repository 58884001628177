import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storePriority,
  PriorityEntryType,
} from 'stores/_mobx/systemSetup/masterSetting/priority';
import { URL_PRIORITY } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const PriorityEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, priority } = storePriority;

  const handleSubmit = async (data: PriorityEntryType) =>
    storePriority.updatePriority(data).then((error) => {
      if (error) return error;
      history.push(URL_PRIORITY);
    });

  useEffect(() => {
    if (!fetching) storePriority.getPriority(Number(params.id));

    return storePriority.clearPriority;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Priority" />
      <Form
        backUrl={URL_PRIORITY}
        defaultValues={priority}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(PriorityEditPage);
