import Title from 'components/project/common/title';

interface PropsType {
  title: string | React.ReactNode;
  appId?: string;
  children: React.ReactNode;
}

const LayoutSideTitle = ({ title, appId, children }: PropsType) => (
  <div className="d-flex justify-content-between align-items-start">
    <div>
      <Title title={title} appId={appId} className="col-md-12" />
    </div>
    <div className="d-flex gap-3 align-items-start page-title-controls">
      {children}
    </div>
  </div>
);

export default LayoutSideTitle;
