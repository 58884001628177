import React from 'react';

import IterableUtils from 'utils/IterableUtils';
import { IconButton } from 'components/button';
import Fieldset from 'components/form/Fieldset';
import Select from 'components/form/input/Select';
import TextCalendar from 'components/form/input/TextCalendar';

import Title from 'components/project/common/title';
import Form from 'components/form/Form';
import Text from 'components/form/input/Text';
import FormControl from 'components/form/FormControl';
import Custom from 'components/form/input/Custom';
import Notification from 'components/modal/Notification';
import BoneSonometerDropdown from 'components/project/dropdown/BoneSonometerDropdown';
import GloveDropdown from 'components/project/dropdown/GloveDropdown';
import ThyroidShieldDropdown from 'components/project/dropdown/ThyroidShieldDropdown';
import UserProfileStore from 'stores/UserProfileStore';
import EquipmentPageActions from 'actions/workflow/equipment/EquipmentPageActions';
import EquipmentPageStore from 'stores/workflow/equipment/EquipmentPageStore';
import EkgComponent from './components/EkgComponent';
import PhoneComponent from './components/PhoneComponent';
import AccessUtils from 'utils/AccessUtils';
import TextArea from 'components/form/input/TextArea';
import GeneralDropdown from 'components/project/dropdown/GeneralDropdown';
import DropdownService from 'services/project/DropdownService';
import { URL_EQUIPMENT_CHECKOUT } from 'constant/path/workflow';

const initialValues = {
  vehicleid: '',
  technologistid: '',
  ekgid: '',
  starting_odometer: '',
  gloveid: '',
  unitid: '',
  phoneid: '',
  thyroid: '',
  halfLeadid: '',
  txtcomments: '',
  userType: '',
  deviceType: '',
  rightgLove: '',
  rbEquipment: '',
  fullLeads: [],
};

export default class AbstractAddEquipmentCheckOut extends React.Component {
  static PERMISSIONS = {
    X_RAY: 'xray',
    ULTRASOUND: 'ultrasound',
  };

  static UNIT_TYPE_PERMISSIONS_MAPPING = {
    D: AbstractAddEquipmentCheckOut.PERMISSIONS.ULTRASOUND,
    X: AbstractAddEquipmentCheckOut.PERMISSIONS.X_RAY,
  };

  static getStores() {
    return [EquipmentPageStore];
  }

  static calculateState(prevState) {
    var store = EquipmentPageStore.getState();
    if (!prevState) {
      prevState = {
        onAjax: true,
        errors: {},
        checkOutItemIsLoaded: false,
        model: initialValues,
        flagVal: false,
        hboxcan: true,
        showComments: false,
        phoneVisible: true,
      };
    }
    return {
      ...prevState,
      vehicleList: store.vehicleList,
      techList: store.techList,
      unitList: AccessUtils.filter(
        store.unitList,
        AbstractAddEquipmentCheckOut.UNIT_TYPE_PERMISSIONS_MAPPING,
        (unit) => unit.type
      ),
      ekgList: store.ekgList,
      phoneList: store.phoneList,
      model:
        store.checkOutItemIsLoaded && !prevState.checkOutItemIsLoaded
          ? store.checkOutItem
          : prevState.model,
      checkOutItemIsLoaded: store.checkOutItemIsLoaded,
    };
  }

  componentDidMount() {
    const { params } = this.props.match;

    const id = params.mode === 'M' ? params.id : '';

    const xray = params.mode === 'M' ? 'Y' : 'N';

    const ultra = params.mode === 'M' ? 'Y' : 'N';

    if (params.mode === 'M') {
      EquipmentPageActions.GetViewEquipment(id);
      this.setState({
        flagVal: true,
        hboxcan: true,
        showComments: false,
      });
    }

    const vehiclePromise = EquipmentPageActions.LoadVehicle(id);
    const technologistPromise = EquipmentPageActions.LoadTechnologistList();
    const ekgPromise = EquipmentPageActions.ActiveEkgNameDropDown(id);
    const phonePromise = EquipmentPageActions.ActivePhoneNumberDropDown(id);
    const xRayPromise = EquipmentPageActions.DeviceXrayDropDown_newList(
      xray,
      ultra,
      id
    );
    Promise.allSettled([
      vehiclePromise,
      technologistPromise,
      ekgPromise,
      phonePromise,
      xRayPromise,
    ]).then(() => this.setState({ onAjax: false }));
  }

  submitEquipmentCheckout(item) {
    const { params } = this.props.match;

    if (params.mode !== 'M') {
      return EquipmentPageActions.saveEquipmentCheckOut(item).then(
        function (response) {
          if (response === 'S') {
            Notification.success('Successfully saved!');
            this.backToList();
          } else {
            Notification.danger(
              'You have equipment that is presently checked out.\nPlease check it in before checking out more!'
            );
          }
        }.bind(this)
      );
    } else {
      item.modifyId = params.id;
      item.userType = UserProfileStore.getUserType();
      return EquipmentPageActions.editEquipmentCheckOut(item).then(
        function (response) {
          if (response === 'S') {
            Notification.success('Successfully edited!');
            this.backToList();
          }
        }.bind(this)
      );
    }
  }

  submitSuccess() {
    this.submitEquipmentCheckout({ ...this.state.model });
  }

  updateFullLeadsObject(fullLeads) {
    const model = { ...this.state.model, fullLeads };
    this.setState({ model });
  }

  addLead = () => {
    const fullLeads = [...this.state.model.fullLeads];
    fullLeads.push({
      AddOpt: this.state.model.fullLeads
        ? this.state.model.fullLeads.length < 4
        : false,
      DelOpt: this.state.model.fullLeads
        ? this.state.model.fullLeads.length > 0
        : false,
      refid: 0,
      FullLead: '',
      Index: '',
    });
    this.updateFullLeadsObject(fullLeads);
  };

  removeLead(i) {
    let fullLeads = [...this.state.model.fullLeads];
    fullLeads.splice(i, 1);
    this.updateFullLeadsObject(fullLeads);
  }

  updateLeads(index, name, value) {
    var source = this.state.model.fullLeads || [];
    let leads = [...source];
    if (!leads[index]) {
      leads[index] = {};
    }
    leads[index].FullLead = value;
    this.updateFullLeadsObject(leads);
  }

  buildFullLeads() {
    let out = [];
    let arr = this.state.model.fullLeads;
    if (!arr || arr.length === 0) {
      arr = [
        { refid: 0, FullLead: '', Index: '', AddOpt: true, DelOpt: false },
      ];
    }
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      out.push(
        <Fieldset
          className="leads row"
          key={i}
          formModel={item}
          onSetValue={(n, v, e) => {
            this.updateLeads(i, n, v, e);
          }}>
          <GeneralDropdown
            name="FullLead"
            label="Full Lead"
            className="col-sm-8"
            optionsLoader={() => DropdownService.loadLeadList()}
          />
          <Custom
            label="&nbsp;"
            className="col-sm-4 mt-2"
            custom={
              <div className="control">
                <IconButton
                  className="text-primary fs-5"
                  disabled={arr.length >= 5}
                  onClick={this.addLead}>
                  <i className="bi-plus-circle" />
                </IconButton>
                <IconButton
                  className="text-danger fs-5"
                  onClick={() => {
                    this.removeLead(i);
                  }}>
                  <i className="bi bi-trash" />
                </IconButton>
              </div>
            }
          />
        </Fieldset>
      );
    }
    return out;
  }

  setEkgValue(name, value, err) {
    const { params } = this.props.match;
    let model = { ...this.state.model };
    let errors = { ...this.state.errors };
    errors[name] = err;
    model.ekgid = value;

    if (value > 0) {
      var list = this.state.ekgList || [];
      var option = list.find((e) => {
        return e.value === value;
      });
      if (option) {
        if (option.textcolor === '0xFBB917' && option.check === 'Y') {
          if (params.mode === 'M') {
            if (this.state.model.ekgIndex !== option) {
              Notification.danger(
                'EKG is checkout by someone else, EKG is out of service!  Yet it can be picked up for use.\nReason : ' +
                  option.reason +
                  '.'
              );
              model.ekgid = 0;
            }
          } else {
            Notification.danger(
              'EKG is checkout by someone else, EKG is out of service!  Yet it can be picked up for use.\nReason : ' +
                option.reason +
                '.'
            );
            model.ekgid = 0;
          }
        } else if (option.textcolor === '0xFBB917' && option.check !== 'Y') {
          Notification.danger(
            'EKG is out of service! Yet it can be picked up for use.\nReason : ' +
              option.reason +
              '.'
          );
          model.ekgid = 0;
        } else if (option.textcolor !== '0xFBB917' && option.check === 'Y') {
          Notification.danger('EKG is checkout by someone else.');
          model.ekgid = 0;
        } else if (option.textcolor === '0xFF0000') {
          Notification.danger(
            'EKG is out of service!  Please choose any other EKG.\nReason : ' +
              option.reason +
              '.'
          );
          model.ekgid = 0;
        }
      }
    }
    this.setState({ model, errors }, () =>
      EquipmentPageActions.ActivePhoneNumberDropDown(
        params.mode === 'M' ? params.id : ''
      )
    );
  }

  setVehicleValue(name, value) {
    let item = { ...this.state.model };
    if (value > 0) {
      item.starting_odometer = '';
      item.phoneVisible = true;
      item.flagVal = false;
    }
    item.vehicleid = value;
    this.setState({ model: item }, () => this.postSetVehicleValue());
  }

  postSetVehicleValue() {
    const { mode, id } = this.props.match.params;
    return EquipmentPageActions.ActiveEkgNameDropDown(mode === 'M' ? id : '');
  }

  setUnitValue(name, val) {
    const item = {
      ...this.state.model,
      unitid: val,
    };
    var result = IterableUtils.grep(this.state.unitList, function (e) {
      return e.value === val;
    });
    if (result && result.length > 0) {
      var option = result[0];
      item.deviceType = option.type;
      if (option.type === 'D') {
        item.requiredEkg = false;
      } else {
        item.requiredEkg = true;
      }
      this.setState({ model: item }, () =>
        this.ValidateUnitValue(name, val, option)
      );
    }
  }

  ValidateUnitValue(name, value, option) {
    let item = { ...this.state.model };
    if (value > 0) {
      if (option.textcolor === '0xFBB917' && option.check === 'Y') {
        item.deviceFlag = value;
        if (this.props.match.params.mode === 'M') {
          if (this.state.model.deviceIndex !== value) {
            Notification.danger(
              'Unit is checkout by someone else, Unit is out of service! Yet it can be picked up for use.\nReason : ' +
                option.reason +
                '.'
            );
          }
        } else {
          Notification.danger(
            'Unit is checkout by someone else, Unit is out of service! Yet it can be picked up for use.\nReason : ' +
              option.reason +
              '.'
          );
        }
      }
    }
  }

  setPhoneValue(name, value) {
    const { params } = this.props.match;
    let model = { ...this.state.model };
    let emptyPhone = false;
    model.phoneid = value;
    var result = IterableUtils.grep(this.state.phoneList, function (e) {
      return e.value === value;
    });
    if (result && result.length > 0) {
      var option = result[0];
      if (option.check === 'Y' && option.textcolor === '0xFBB917') {
        model.phoneFlag = value;
        if (params.mode === 'M') {
          if (this.state.model.phoneIndex !== value) {
            Notification.danger(
              'Phone is checkout by someone else, Phone is out of service! Yet it can be picked up for use.\nReason : ' +
                option.reason +
                '.'
            );
            emptyPhone = true;
          }
        } else {
          Notification.danger(
            'Phone is checkout by someone else, Phone is out of service! Yet it can be picked up for use.\nReason : ' +
              option.reason +
              '.'
          );
          emptyPhone = true;
        }
      } else if (option.check !== 'Y' && option.textcolor === '0xFBB917') {
        model.phoneFlag = value;
        Notification.danger(
          'Phone is out of service! Yet it can be picked up for use.\nReason : ' +
            option.reason +
            '.'
        );
        emptyPhone = true;
      } else if (
        option.check === 'Y' &&
        option.textcolor !== '0xFBB917' &&
        params.mode !== 'M'
      ) {
        Notification.danger('Phone is checkout by someone else.');
        emptyPhone = true;
      } else if (option.textcolor === '0xFF0000') {
        model.phoneFlag = value;
        Notification.danger(
          'Phone is out of service! Please choose any other Phone.\nReason : ' +
            option.reason +
            '.'
        );
        emptyPhone = true;
      }
    }
    if (emptyPhone) {
      model.phoneid = 0;
    }
    this.setState({ model: model });
  }

  onUpdateModel(name, value, err, callback) {
    const { state } = this;

    const model = {
      ...state.model,
      [name]: value,
    };

    const errors = {
      ...state.errors,
      [name]: err,
    };

    this.setState(
      { model, errors },
      typeof callback === 'function' ? callback : undefined
    );
  }

  loadProperData(name, value) {
    let item = { ...this.state.model };
    let xray = '';
    let ultra = '';
    let hboxcan = false;
    let flagVal = false;
    item.technologistid = value;
    var list = this.state.techList || [];
    var option = list.find((e) => {
      return e.value === value;
    });
    if (option) {
      item.userType = option.user_type;
    }

    EquipmentPageActions.CheckValidTech(value).then((response) => {
      const { mode, id } = this.props.match.params;

      if (response === 'S') {
        hboxcan = true;
        xray = 'Y';
        ultra = 'Y';
        flagVal = true;
      } else if (response === 'D') {
        hboxcan = false;
        xray = 'N';
        ultra = 'Y';
        flagVal = false;
      } else if (response === 'X') {
        hboxcan = true;
        xray = 'Y';
        ultra = 'N';
        flagVal = true;
      }
      item.xray = xray;
      item.ultra = ultra;
      this.setState({ model: item, hboxcan: hboxcan, flagVal: flagVal }, () =>
        EquipmentPageActions.DeviceXrayDropDown_newList(
          xray,
          ultra,
          mode === 'M' ? id : ''
        )
      );
    });
  }

  handleSubmit = (updatedModel, hasErrors, updatedErrors) => {
    if (hasErrors) {
      const { state } = this;
      const model = { ...state.model, ...updatedModel };
      const errors = { ...state.errors, ...updatedErrors };
      this.setState({ model, errors });
    } else {
      this.submitSuccess();
    }
  };

  render() {
    let userType = UserProfileStore.getUserType();

    return (
      <div>
        <Title title={this.title} />
        <div className="facility-tab col-sm-12">
          <Form
            onCollectValues={this.onUpdateModel.bind(this)}
            className={this.state.onAjax ? 'on-ajax' : ''}
            submit={this.handleSubmit}
            id="requestServiceForm"
            model={this.state.model}
            errors={this.state.errors}
            errorMessages={this.state.errorMessages}>
            <Fieldset className="row">
              <Fieldset className="col-sm-6">
                {this.state.vehicleList ? (
                  <Select
                    options={this.state.vehicleList}
                    label="Vehicle"
                    name="vehicleid"
                    validations="required"
                    onSetValue={this.setVehicleValue.bind(this)}
                  />
                ) : null}
                {this.state.techList ? (
                  <Select
                    options={this.state.techList}
                    label="Technologist"
                    name="technologistid"
                    validations="required"
                    onSetValue={this.loadProperData.bind(this)}
                  />
                ) : null}
                {this.state.ekgList ? (
                  <EkgComponent
                    options={this.state.ekgList}
                    label="EKG"
                    name="ekgid"
                    onSetValue={(n, v, e) => this.setEkgValue(n, v, e)}
                    validations={
                      this.state.model.flagVal || userType === 'T'
                        ? 'required'
                        : ''
                    }
                  />
                ) : null}

                <Text
                  name="starting_odometer"
                  label="Starting Odometer"
                  validations="min:1"
                  errorMessages="Invalid mileage!"
                />
                {this.state.showComments ? (
                  <TextArea name="txtcomments" />
                ) : null}
                {this.state.hboxcan ? (
                  <GloveDropdown label="Glove" name="gloveid" />
                ) : null}
                {this.buildFullLeads()}
              </Fieldset>
              <Fieldset className="col-sm-6">
                <TextCalendar
                  name="start_dt"
                  label="Date"
                  validations="required"
                />
                <Select
                  options={this.state.unitList}
                  name="unitid"
                  label="Unit"
                  onSetValue={this.setUnitValue.bind(this)}
                  validations="required"
                />
                {this.state.phoneVisible ? (
                  <PhoneComponent
                    validations="required"
                    name="phoneid"
                    label="Phone"
                    onSetValue={this.setPhoneValue.bind(this)}
                    options={this.state.phoneList}
                  />
                ) : null}
                {this.state.phoneVisible && this.state.model.phoneid ? (
                  <Custom
                    noLabel
                    custom={<div>{this.findPhoneNo() || ''}</div>}
                  />
                ) : null}

                <BoneSonometerDropdown name="boneid" label="Bone Sonometer" />
                {this.state.hboxcan ? (
                  <ThyroidShieldDropdown
                    name="thyroid"
                    label="Thyroid Shield"
                  />
                ) : null}
                {this.state.hboxcan ? (
                  <GeneralDropdown
                    name="halfLeadid"
                    label="Half Lead"
                    optionsLoader={() => DropdownService.loadLeadList()}
                  />
                ) : null}
              </Fieldset>
            </Fieldset>

            <FormControl direction="left">
              <input type="submit" className="btn btn-primary" value="Save" />
              <input
                type="reset"
                onClick={() =>
                  this.setState({
                    model: initialValues,
                    flagVal: false,
                    hboxcan: true,
                    showComments: false,
                    phoneVisible: true,
                  })
                }
                className="btn btn-warning"
                value="Reset"
              />

              <input
                type="button"
                className="btn btn-danger"
                value="Back to List"
                onClick={this.backToList.bind(this)}
              />
            </FormControl>
          </Form>
        </div>
      </div>
    );
  }

  findPhoneNo() {
    const list = this.state.phoneList || [];
    const option = this.state.model.phoneid
      ? list.find(({ value }) => value === Number(this.state.model.phoneid))
      : null;
    return option ? option.description : '';
  }

  backToList() {
    EquipmentPageActions.setBackToList(
      true,
      this.props.match.params.mode === 'M'
    );
    if (this.props.updateOverview) {
      this.props.updateOverview();
    }
    this.props.history.push(URL_EQUIPMENT_CHECKOUT);
  }
}
