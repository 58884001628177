import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { zipValidator, phoneValidator } from 'utils/formValidators';

interface PropsType {
  minLength: number;
  maxLength: number;
  alphaNumeric: boolean;
}

const zipCodeValidation = yup.string().test({
  test: (zipCode: string) => (zipCode.length ? zipValidator(zipCode) : true),
  message: 'Should be in xxxxx-xxxx format!',
});

const phoneNumberValidation = yup.string().test({
  test: (phone: string) => (phone.length ? phoneValidator(phone) : true),
  message: 'Should be in xxx-xxx-xxxx format!',
});

const validation = (props: PropsType) => {
  return yupResolver(
    yup.object().shape({
      lastName: yup.string().required('Please, enter Last name!'),
      firstName: yup.string().required('Please, enter First name!'),
      phone: phoneNumberValidation,
      fax: phoneNumberValidation,
      zipCode: zipCodeValidation,
      contactPhone: phoneNumberValidation,
      contactZipCode: zipCodeValidation,
      npi: yup
        .string()
        .required('Please, enter NPI number!')
        .test({
          test: (npi: string) => npi.length === 10,
          message: 'NPI must contain 10 characters in length!',
        }),
      pwd: yup
        .string()
        .test({
          test: (password: string) =>
            password.length
              ? password.length >= props.minLength &&
                password.length <= props.maxLength
              : true,
          message: `Password length must be between ${props.minLength} and ${props.maxLength}!`,
        })
        .test({
          test: (password: string) =>
            props.alphaNumeric && password.length
              ? /(\d[a-z])|([a-z]\d)/i.test(password)
              : true,
          message: 'Password should include at least 1 letter and 1 number!',
        }),
      repeatPassword: yup
        .string()
        .oneOf([yup.ref('pwd'), null], 'Password did not match!'),
      email: yup.string().email('Invalid email address!'),
    })
  );
};

export default validation;
