import { useForm } from 'react-hook-form';

import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropDownExamType } from 'components/project/dropdown/ExamTypeDropdown';
import DropDownTechnologist from 'components/project/dropdown/TechnologistDropDown';
import { Input } from 'components/form/textField';
import { DateRange } from 'components/form/dateRange';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import {
  storeRejectReasonReport,
  FilterType,
  defaultFilter,
} from 'stores/_mobx/workflow/serviceMaintenance/rejectReasonReport';

interface PropsType {
  fetching: boolean;
  isVisible: boolean;
  onChange: (filter: FilterType) => void;
}

const Filter = ({ fetching, isVisible, onChange }: PropsType) => {
  const { control, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeRejectReasonReport.filter,
  });

  const handleClickReset = () => {
    reset(defaultFilter);
    storeRejectReasonReport.getReportMain(defaultFilter);
  };

  const handleClickSubmit = handleSubmit(onChange);

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleClickSubmit}>
      <Input
        name="lastName"
        label="Last Name"
        className="col-md-4 col-lg-3"
        control={control}
      />
      <Input
        name="firstName"
        label="First Name"
        className="col-md-4 col-lg-3"
        control={control}
      />
      <DropDownState
        name="stateId"
        label="State"
        className="col-md-4 col-lg-3"
        control={control}
      />
      <DropDownFacility
        name="facilityId"
        label="Facility"
        className="col-md-4 col-lg-3"
        control={control}
      />
      <DropDownPlaceOfService
        name="posId"
        label="POS"
        className="col-md-4 col-lg-3"
        control={control}
      />
      <DropDownExamType
        name="examType"
        className="col-md-4 col-lg-3"
        label="Modality"
        control={control}
      />
      <DropDownTechnologist
        name="techId"
        label="Technologist"
        className="col-md-4 col-lg-3"
        control={control}
      />
      <div />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-auto"
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          text="Reset"
          type="reset"
          variant="warning"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
