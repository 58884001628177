import { apiRequest } from 'services/RequestService';

export class TUserSelection {
  label: string = '';
  data: string = '';
}

export class TUserType {
  refid: number = null;
  usertype_name: string = '';
  usertype: TUserTypeEnum = '';
  category: string = '';
  type: string = '';
}

export class TLabelValue {
  value: number = null;
  label: string = '';
}

export class TUserName {
  refid: number = null;
  to_name: string = '';
  to_mail: number = null;
  firstName: string = '';
  lastName: string = '';
  type: TUserTypeEnum = null;
  facility: string = '';
}

export type TUserTypeEnum =
  | ''
  | 'A'
  | 'B'
  | 'B1'
  | 'B2'
  | 'BA'
  | 'C'
  | 'CA'
  | 'CC'
  | 'D'
  | 'FC'
  | 'IC'
  | 'L'
  | 'N'
  | 'P'
  | 'PA'
  | 'R'
  | 'RA'
  | 'RC'
  | 'S'
  | 'SM'
  | 'SP'
  | 'SU'
  | 'T'
  | 'U';

class UserSelectionService {
  loadUserTypes() {
    return apiRequest<Array<TUserType>>({
      url: 'generalmaster.Mv_UserType.loadUserTypeByType',
    });
  }

  loadUserGroups() {
    return apiRequest<Array<TLabelValue>>({
      url: 'email.GroupMessage.getUserGroupsWithOwnCreated',
    });
  }

  loadGroupMembers(groupId: number) {
    return apiRequest<Array<TLabelValue>>({
      url: 'email.GroupMessage.getGroupMembers',
      data: [groupId],
    });
  }

  loadRecipients(
    type: TUserTypeEnum,
    currentUserType: TUserTypeEnum,
    userId: number,
    facilityId: number,
    facilityFlag: 'F' | string,
    id: number
  ) {
    const data = {
      usertype: type,
      user_Type: currentUserType,
      userId: userId,
      facilityID: facilityId,
      facilityFlag: facilityFlag,
      refid: id,
    };

    return apiRequest<Array<TUserName>>({
      url: 'email.EmailMessageDisplay.LoadSender',
      data,
    });
  }

  loadUserListOfTech(
    userTypeName: TUserTypeEnum,
    userTypeId: number
  ): Promise<Array<TUserSelection>> {
    const data = {
      userTypeName: userTypeName,
      userTypeId: userTypeId,
    };

    return apiRequest<Array<TUserSelection>>({
      url: 'permission.PermissionInfo.LoadUserListofTech',
      data,
    });
  }

  loadUserList(
    userTypeName: TUserTypeEnum,
    facilityId: number,
    userTypeId: number
  ): Promise<Array<TUserSelection>> {
    const data = {
      userTypeName: userTypeName,
      facilityId: facilityId,
      userTypeId: userTypeId,
    };

    return apiRequest<Array<TUserSelection>>({
      url: 'permission.PermissionInfo.LoadUserList',
      data,
    });
  }
}

export default new UserSelectionService();
