import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import {
  storeEmailGroup,
  FormType,
} from 'stores/_mobx/systemSetup/masterSetting/emailGroup';
import { URL_EMAIL_GROUP } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const EmailGroupAddPage = ({ history }: PropsType) => {
  const { fetching, groupDetail, availableUsers } = storeEmailGroup;

  const handleSubmit = async (data: FormType) =>
    storeEmailGroup.addGroup(data).then((error) => {
      if (error) return error;
      history.push(URL_EMAIL_GROUP);
    });

  useEffect(() => {
    if (!fetching) storeEmailGroup.getGroupFullDetails();

    return storeEmailGroup.clearGroupDetails;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LayoutSideTitle title="Add Email Group">
        <Link to={URL_EMAIL_GROUP} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <Form
        backUrl={URL_EMAIL_GROUP}
        options={availableUsers}
        defaultValues={groupDetail}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(EmailGroupAddPage);
