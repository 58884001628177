import EquipmentPageService from 'services/workflow/equipment/EquipmentPageService';
import { Actions } from 'stores/workflow/equipment/EquipmentPageStore';
import appDispatcher from 'dispatcher/AppDispatcher';

export default class EquipmentPageActions {
  static loadEquipmentByTech(userId) {
    return EquipmentPageService.loadEquipmentByTech(userId).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_EQUIPMENT_BY_TECH,
        valData: response,
      });
    });
  }

  static GetViewEquipmentByTech(userId) {
    return EquipmentPageService.GetViewEquipmentByTech(userId);
  }

  static UpdateOdometerEquipment(item) {
    return EquipmentPageService.UpdateOdometerEquipment(item);
  }

  static ActiveEkgNameDropDown(id) {
    return EquipmentPageService.activeEkgNameDropDown(id)
      .then((response) => {
        return this.parseOutput(response);
      })
      .then((response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_EKG_LIST_ACTION,
          valData: response,
        });
      });
  }

  static editEquipmentCheckOut(item) {
    return EquipmentPageService.editEquipmentCheckOut(item);
  }

  static saveEquipmentCheckOut(item) {
    return EquipmentPageService.saveEquipmentCheckOut(item);
  }

  static ActivePhoneNumberDropDown(id) {
    return EquipmentPageService.activePhoneNumberDropDown(id)
      .then((response) => {
        return this.parseOutput(response);
      })
      .then((response) => {
        appDispatcher.dispatch({
          type: 'vehicle.EquipmentMaster.PhoneNumberDropDown',
          data: response,
        });
        return response;
      });
  }

  static parseOutput(response) {
    for (let i = 0; i < response.length; i++) {
      if (response[i].data) {
        response[i].value = parseInt(response[i].data);
      }
    }
    return response;
  }

  static LoadVehicle(id) {
    return EquipmentPageService.LoadVehicle(id)
      .then((response) => {
        return this.parseOutput(response);
      })
      .then((response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_VEHICLE_LIST_ACTION,
          valData: response,
        });
      });
  }

  static CheckValidTech(item) {
    return EquipmentPageService.CheckValidTech(item);
  }

  static DeviceXrayDropDown_newList(xray, ultra, id) {
    return EquipmentPageService.DeviceXrayDropDown_newList(xray, ultra, id)
      .then((response) => {
        return this.parseOutput(response);
      })
      .then((response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_UNIT_LIST_ACTION,
          valData: response,
        });
      });
  }

  static LoadTechnologistList() {
    return EquipmentPageService.LoadTechnologistList()
      .then(this.parseOutput)
      .then((response) => {
        appDispatcher.dispatch({
          type: 'order.Order.TechDropDown_equipment_page',
          data: response,
        });
      });
  }

  static setBackToList(backToList, reloadOutList) {
    return appDispatcher.dispatch({
      type: Actions.SET_BACK_TO_LIST_EQUIPMENT,
      data: {
        backToList: backToList,
        outFilter: reloadOutList,
      },
    });
  }

  static GetViewEquipment(id) {
    return EquipmentPageService.GetViewEquipment(id)
      .then((response) => {
        if (response && response.finalArr && response.finalArr.length > 0) {
          let item = response.finalArr[0];
          return {
            hboxsono: item.device_type === 'D',
            requiredEkg: item.device_type === 'D',
            vehicleid: item.vehicle_id,
            technologistid: item.tech_id,
            ekgid: item.ekg_id,
            boneid: item.bone_id,
            deviceType: item.device_type,
            starting_odometer: item.start_odometer,
            start_dt: item.date,
            thyroid: item.thyroid_shield,
            phoneid: item.phone_id,
            phoneIndex: item.phone_id,
            fullLeads:
              response.FullLeadArr && response.FullLeadArr.length > 0
                ? response.FullLeadArr
                : [],
            unitid: item.device_id,
            gloveid: item.leftglove_id,
            rightGlove: item.rightGlove_id,
            halfLeadid: item.lead,
            txtcomments: item.comments,
            rbEquipment: '',
            rightgLove: item.rightGlove_id,
            groupid: item.group_id,
          };
        }
        return response;
      })
      .then((response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_ITEM_ACTION,
          valData: response,
          isLoaded: true,
        });
      });
  }

  static clear() {
    appDispatcher.dispatch({
      type: Actions.CLEAR_STORE_ACTION,
      valData: {},
      isLoaded: false,
    });
  }

  static loadOdometerValue(vehicleId) {
    return EquipmentPageService.loadOdometerValue(vehicleId);
  }
}
