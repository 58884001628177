import { useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import RadioOptions from 'components/project/common/RadioOptions';
import { Grid } from 'components/grid';
import { IconButton } from 'components/button';
import { PureSelect } from 'components/form/select';
import { PureRadio } from 'components/form/radio';
import FileUpload from 'components/form/input/FileUpload';
import TabComponent from './components/TabComponent';

import {
  storeBillingAssignment,
  tcBillingOptions,
  BillingAssignmentCommonType,
  FilterTCType,
} from 'stores/_mobx/billingCodding/billingAssignment';
import { BASE_URL_FILE_DIR } from 'constant/config';

const clientOptions = [
  { value: 'F', label: 'Facility' },
  { value: 'I', label: 'Insurance' },
];

const columnVisibleByDefault = [
  'studyId',
  'dos',
  'billType',
  'responsible',
  'patientLastName',
  'patientFirstName',
  'birthDate',
  'facilityName',
  'modality',
  'visitInfo',
  'review',
  'encounterLog',
  'division',
];

interface PropsType {
  isFilterVisible: boolean;
}

const TcAssignmentTab = ({ isFilterVisible }: PropsType) => {
  const gridRef = useRef<Grid>();

  const {
    tcBillingAssignments,
    tcBillingAssignmentsTotal,
    permission,
    filterTC,
    pageTC,
    storeDivisionSettingsStatus: { isDivisionEnabled },
  } = storeBillingAssignment;

  const optionsBillProcess = useMemo(
    () => (permission.isClient ? clientOptions : tcBillingOptions),
    [permission.isClient]
  );

  const fetchData = () => {
    storeBillingAssignment.getTCBillingAssignmentsMain();
  };

  const handleUpdateProfession =
    (studyId: number) => (profession: 'Y' | 'N') => {
      storeBillingAssignment
        .updateProfession({
          profession,
          id: studyId,
        })
        .then(fetchData);
    };

  const handleChangeResponsible =
    (id: string) =>
    (responsible: BillingAssignmentCommonType['responsible']) => {
      gridRef.current.selectRow(id, Boolean(responsible));

      storeBillingAssignment.updateBillProcess({
        id,
        responsible,
        isTcBillProcess: true,
      });
    };

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        name: 'claimId',
        title: 'Claim #',
        render: (_: undefined, { studyId }: BillingAssignmentCommonType) =>
          studyId,
      },
      { name: 'dos', title: 'DOS', className: 'text-nowrap', dataType: 'date' },
      {
        name: 'billType',
        title: 'Bill Type',
        className: 'width-150',
        group: 'technical',
        groupName: 'Technical Guarantor',
      },
      {
        name: 'responsible',
        title: 'Technical Bill Process',
        group: 'technical',
        groupName: 'Technical Guarantor',
        noOverflow: true,
        render: (
          responsible: BillingAssignmentCommonType['responsible'],
          { claimUniqId }: BillingAssignmentCommonType
        ) => (
          <PureSelect
            options={optionsBillProcess}
            name={`responsibility_${claimUniqId}`}
            className="m-width-150"
            value={responsible}
            onChange={handleChangeResponsible(claimUniqId)}
          />
        ),
      },
      { name: 'patientLastName', title: 'Patient Last Name' },
      { name: 'patientFirstName', title: 'Patient First Name' },
      { name: 'birthDate', title: 'Date Of Birth', className: 'text-nowrap' },
      { name: 'facilityName', title: 'Facility' },
      isDivisionEnabled ? { name: 'division', title: 'Division' } : null,
      permission.viewAllColumns
        ? { name: 'providerName', title: 'Provider Name' }
        : null,
      permission.viewAllColumns ? { name: 'pos', title: 'POS' } : null,
      permission.viewAllColumns
        ? { name: 'modality', title: 'Modality' }
        : null,
      permission.viewAllColumns ? { name: 'studyId', title: 'Study ID' } : null,
      permission.viewAllColumns
        ? { name: 'patientId', title: 'Patient ID' }
        : null,
      permission.viewAllColumns
        ? { name: 'clientMRN', title: 'Other MRN' }
        : null,
      permission.viewAllColumns ? { name: 'state', title: 'State' } : null,
      permission.viewAllColumns
        ? { name: 'location', title: 'Location' }
        : null,
      permission.viewAllColumns
        ? {
            name: 'visitInfo',
            title: 'Visit Info',
            render: (
              value: undefined,
              { studyId }: BillingAssignmentCommonType
            ) => (
              <IconButton
                className="text-primary"
                onClick={() => storeBillingAssignment.getVisitDetails(studyId)}>
                <i className="bi bi-journal-medical" />
              </IconButton>
            ),
          }
        : null,
      permission.viewAllColumns
        ? { name: 'radiologist', title: 'Reading Rad(s)' }
        : null,
      permission.viewAllColumns
        ? {
            name: 'review',
            title: 'Review',
            render: (_: undefined, data: BillingAssignmentCommonType) => (
              <IconButton
                onClick={() => storeBillingAssignment.setParamsForReview(data)}>
                <i className="icon icon-view-item" />
              </IconButton>
            ),
          }
        : null,
      permission.viewAllColumns
        ? { name: 'radiologyGroupName', title: 'Radiology Group' }
        : null,
      permission.viewAllColumns
        ? {
            name: 'profession',
            title: 'Is Professional',
            group: 'profession',
            groupName: 'Professional Guarantor',
            render: (value: any, { studyId }: BillingAssignmentCommonType) => (
              <PureRadio
                className="part-inline width-100"
                name={`profession_${studyId}`}
                options={RadioOptions.yesNo}
                value={value}
                onChange={handleUpdateProfession(studyId)}
              />
            ),
          }
        : null,
      permission.viewAllColumns
        ? {
            name: 'prof_guarantor',
            title: 'Responsibility',
            group: 'profession',
            groupName: 'Professional Guarantor',
          }
        : null,
      permission.isClient || !permission.viewAllColumns
        ? null
        : {
            name: 'encounterLog',
            title: 'Encounter Log',
            render: (
              value: undefined,
              { hasLog, studyId }: BillingAssignmentCommonType
            ) => (
              <IconButton
                className="text-primary"
                onClick={() => {
                  storeBillingAssignment.setIdForNotes(studyId);
                }}>
                <i
                  className={`bi ${
                    hasLog ? 'bi-envelope-check-fill' : 'bi-envelope'
                  }`}
                />
              </IconButton>
            ),
          },
      permission.viewAllColumns
        ? {
            name: 'auditLog',
            title: 'Audit Log',
            render: (value: undefined, data: BillingAssignmentCommonType) => (
              <IconButton
                onClick={() =>
                  storeBillingAssignment.setParamsForAuditLog(data)
                }>
                <i className="icon icon-auditlog" />
              </IconButton>
            ),
          }
        : null,
      permission.updatingFaceSheet
        ? {
            name: 'file_upload_status',
            title: 'Status',
          }
        : null,
      permission.updatingFaceSheet
        ? {
            name: 'facesheet',
            title: 'Face Sheet',
            render: (value: undefined, data: BillingAssignmentCommonType) => (
              <FileUpload
                buttonLabel="Upload"
                name="fu"
                noLabel
                onSetValue={(n, fileName) =>
                  storeBillingAssignment.updateFaceSheet({
                    id: data.studyId,
                    fileName,
                  })
                }
                value=""
                // value={this.state.fileName}
                noWarning
                hideFileName
                path={`${BASE_URL_FILE_DIR}doc_img/externaldoc`}
              />
            ),
          }
        : null,
    ];

    return defaultColumns.filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    permission.isClient,
    permission.updatingFaceSheet,
    permission.viewAllColumns,
    isDivisionEnabled,
  ]);

  const columnsVisibleByDefault = useMemo(() => {
    const visibleColumns = permission.updatingFaceSheet
      ? columnVisibleByDefault.concat(['file_upload_status', 'facesheet'])
      : columnVisibleByDefault;

    const finalColumns: Map<string, boolean> = new Map<string, boolean>();

    columns.forEach((c) => {
      finalColumns.set(c.name, visibleColumns.includes(c.name));
    });

    return finalColumns;
  }, [permission.updatingFaceSheet, columns]);

  const handleApplyFilter = (filter: FilterTCType) => {
    storeBillingAssignment.setTCFilter(filter);

    pageTC.setPaginationToStart();
  };

  useEffect(() => {
    fetchData();
  }, [pageTC.pagination]);

  return (
    <TabComponent
      mode="TC"
      isTcBillProcess
      ref={gridRef}
      isFilterVisible={isFilterVisible}
      filter={filterTC}
      columns={columns}
      columnsVisibleByDefault={columnsVisibleByDefault}
      optionsBillProcess={tcBillingOptions}
      billingAssignments={tcBillingAssignments}
      billingAssignmentsTotal={tcBillingAssignmentsTotal}
      page={pageTC}
      fetchData={fetchData}
      // @ts-ignore
      onApplyFilter={handleApplyFilter}
    />
  );
};

export default observer(TcAssignmentTab);
