import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Checkbox } from 'components/form/checkbox';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import RadioOptions from 'components/project/common/RadioOptions';
import { Textarea } from 'components/form/textarea';
import { Button } from 'components/button';

import resolver from './validation';

const initialValues: FormModel = {
  comments: '',
  is_mobile: false,
  shrt_code: '',
  sign_order: 'N',
  type_name: '',
  view_in_list: false,
};

export interface FormModel {
  comments: string;
  is_mobile: boolean;
  refid?: number;
  shrt_code: string;
  sign_order: string;
  type_name: string;
  view_in_list: boolean;
}

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormReportType = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm({ defaultValues, resolver });

  const cn = `col-lg-8${isSubmitting ? ' on-ajax' : ''}`;

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: FormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('shrt_code', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  return (
    <form className={cn} onSubmit={handleClickSubmit}>
      <Input
        name="type_name"
        label="Document Type"
        required
        control={control}
      />
      <Input name="shrt_code" label="Code" required control={control} />
      <Checkbox name="view_in_list" label="Mark in Report" control={control} />
      <Checkbox name="is_mobile" label="Allowed for mobile" control={control} />
      <Radio
        name="sign_order"
        label="Sign Order on document upload"
        options={RadioOptions.yesNo}
        control={control}
      />
      <Textarea name="comments" label="Comments" rows={3} control={control} />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={!isDirty || isSubmitting}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormReportType;
