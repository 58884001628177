import clsx from 'clsx';

interface PropsType {
  className?: string;
  alignX?: 'left' | 'center' | 'right' | 'between' | 'auto';
  children: any;
}

const ControlsLayout = ({
  className,
  children,
  alignX = 'left',
}: PropsType) => {
  const cn = clsx('controls-wrapper', alignX, className);
  return <div className={cn}>{children}</div>;
};

export default ControlsLayout;
