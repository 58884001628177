import { makeObservable, observable, action, computed } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import {
  dateToLocalTimezone,
  getDateRangeBounds,
  timeZoneRender,
} from 'utils/DateUtils';

export const defaultFilterValue: FilterType = {
  dosEnd: '',
  dosStart: '',
  period: 'A',
  studyId: '',
  timezone: '',
  patientFirstName: '',
  patientLastName: '',
};

export interface FilterType {
  studyId: string;
  dosStart: string;
  dosEnd: string;
  period: string;
  patientFirstName: string;
  patientLastName: string;
  timezone: string;
}

interface LogType {
  assigndate: string;
  completedate: string;
  created_on: string;
  dos: string;
  patfname: string;
  patlname: string;
  placeddate: string;
  studyid: number;
}

class OrderTrackHistory {
  fetching: boolean = false;
  logList: LogType[] = [];
  logTotal: number = 0;
  filter: FilterType = defaultFilterValue;

  page: Pagination = new Pagination({ id: 'orderTrackHistoryGrid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      logList: observable,
      logTotal: observable,
      filter: observable,

      finalLogList: computed,

      setFetching: action,
      setLogList: action,
      setLogTotal: action,
      setFilter: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setLogTotal(count: number) {
    this.logTotal = count;
  }

  setLogList(list: LogType[]) {
    this.logList = list;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  get finalLogList() {
    const { timezone } = this.filter;

    const finalList = this.logList.map((log) => ({
      ...log,
      placeddate: timeZoneRender(log.placeddate, timezone || ''),
      assigndate: timeZoneRender(log.assigndate, timezone || ''),
      completedate: timeZoneRender(log.completedate, timezone || ''),
    }));

    return finalList;
  }

  async getLogs(data: (number | string)[]) {
    try {
      const response = await apiRequest<LogType[]>({
        url: 'report.ReportMaster.GetOrdertrackhistory',
        data,
      });

      const logList = response.map((log) => ({
        ...log,
        dos: dateToLocalTimezone({ date: log.dos, dateOnly: true }),
        studyid: Number(log.studyid) || 0,
      }));
      this.setLogList(logList);
    } catch (e) {
      this.setLogList([]);
    }
  }

  async getLogsCount(data: (number | string)[]) {
    try {
      const count = await apiRequest<string>({
        url: 'report.ReportMaster.GetOrdertrackhistorycount',
        data,
      });

      this.setLogTotal(Number(count) || 0);
    } catch (e) {
      this.setLogTotal(0);
    }
  }

  async getLogsListMain() {
    const {
      filter,
      page: { pagination },
    } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const logCountPayload = [
      filter.patientLastName,
      filter.patientFirstName,
      filter.studyId,
      dos.dateFrom,
      dos.dateTo,
    ];

    const logListPayload = [
      pagination.skip,
      pagination.pageSize,
      ...logCountPayload,
    ];

    this.setFetching(true);

    const promiseCount = this.getLogsCount(logCountPayload);

    const promiseRegions = this.getLogs(logListPayload);

    Promise.allSettled([promiseCount, promiseRegions]).finally(() => {
      this.setFetching(false);
    });
  }

  generatePdf = async ({ filter }: { filter: string }) => {
    const data = {
      dataSource: this.finalLogList,
      filter,
    };
    try {
      const response = await apiRequest<string>({
        url: 'pdfcreater.OrderPdfGenerator.GeneratePDFforOrderatrack',
        data,
      });
      return response;
    } catch (e: any) {
      return '';
    }
  };

  prepareFilterForExport() {
    const { filter } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return {
      patientLastName: filter.patientLastName,
      patientFirstName: filter.patientFirstName,
      studyId: filter.studyId,
      dosFrom: dos.dateFrom,
      dosTo: dos.dateTo,
      timezone: filter.timezone,
    };
  }
}

export const storeOrderTrackHistory = new OrderTrackHistory();
