import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeBoneSonometer,
  SonometerFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/boneSonometer';
import { URL_BONE_SONOMETER } from 'constant/path/workflow';

interface PropsType extends RouteComponentProps {}

const BoneSonometerAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: SonometerFormModel) =>
    storeBoneSonometer.addSonometer(data).then((error) => {
      if (error) return error;
      history.push(URL_BONE_SONOMETER);
    });

  return (
    <>
      <Title title="Add Bone Sonometer" />
      <Form onSubmit={handleSubmit} backUrl={URL_BONE_SONOMETER} />
    </>
  );
};

export default BoneSonometerAddPage;
