import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { GridControlButton } from 'components/grid';
import { Grid, GridExtraControlPanel } from 'components/gridModern';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { useMemoState } from 'components/hook';
import FilterExamInfo from './components/FilterExamInfo';
import { examInfoColumns } from './components/Columns';

import { storeExamInfo, FilterType } from 'stores/_mobx/patientRecord/examInfo';
import { deepEqualObjectsCompare as compareFilters } from 'utils/deepEqualObjectsCompare';

const columnSettings = {
  autoHeaderHeight: false,
};

const gridOptions = {
  headerHeight: 30,
};

interface PropsType {
  isFilterVisible: boolean;
}

const ExamInfoTab = ({ isFilterVisible }: PropsType) => {
  const {
    isCorporateUser,
    fetching,
    examList,
    examTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeExamInfo;

  const [sorting, setSorting] =
    useMemoState<Record<string, 'asc' | 'desc'>>(null);

  const [isRunExportConfirmDialogVisible, toggleRunExportConfirmDialog] =
    useState<boolean>(false);

  const defaultValues = storeExamInfo.getDefaultFilter();

  const isFilterChanged = useMemo(() => {
    return !compareFilters(filter, defaultValues);
  }, [filter,defaultValues]);

  const handleClickExport = () => {
    storeExamInfo.runExportCsv(examInfoColumns);
    toggleRunExportConfirmDialog(false);
  };

  const handleToggleRunExportDialog = () => {
    toggleRunExportConfirmDialog((state) => !state);
  };

  const handleClickSearch = (filterData: FilterType) => {
    storeExamInfo.setFilter(filterData);
    setPaginationToStart();
  };

  useEffect(() => {
    if (sorting) storeExamInfo.getExamList(sorting);
  }, [pagination, sorting]);

  return (
    <>
      <FilterExamInfo
        fetching={fetching}
        isCorporateUser={isCorporateUser}
        initValues={filter}
        isFilterVisible={isFilterVisible}
        onSearch={handleClickSearch}
      />

      <GridExtraControlPanel>
        <GridControlButton
          title="Export CSV"
          disabled={!examTotal}
          onClick={isFilterChanged ? handleClickExport : handleToggleRunExportDialog}
        />
      </GridExtraControlPanel>
      <Grid
        id={storeExamInfo.gridId}
        defaultColDef={columnSettings}
        columnDefs={examInfoColumns}
        isLoading={fetching}
        rowData={examList}
        totalCount={examTotal}
        paginationSettings={pagination}
        {...gridOptions}
        onChangeSort={setSorting}
        onPaginationChange={setPagination}
      />

      {isRunExportConfirmDialogVisible ? (
        <Dialog handleClose={handleToggleRunExportDialog}>
          <DialogHeader
            title='Export Confirmation'
            onClose={handleToggleRunExportDialog}
          />
          <DialogBody>
            <div>
              <p>You are about to export <strong>ALL the available records</strong>.</p>
              <p>This process may take a very long time depending on the number of records.</p>
              <p>Please consider exporting a smaller subset of records.</p>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              variant='warning'
              text='Cancel'
              onClick={handleToggleRunExportDialog}
            />
            <Button
              variant='primary'
              text='Export Anyway'
              onClick={handleClickExport}
            />
          </DialogFooter>
        </Dialog>
      ) : null}
    </>
  );
};

export default observer(ExamInfoTab);
