import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';

import { IconButton } from 'components/button';
import Title from 'components/project/common/title';
import { Grid, GridControlButton } from 'components/grid';
import Filter from './components/Filter';
import DialogMessage from './components/DialogMessage';
import DialogFilesList from './components/DialogFilesList';
import { checkAndDownload } from './components/helpers';

import UserProfileStore from 'stores/UserProfileStore';
import {
  storeEmailReport,
  EmailReportType,
  FilterType,
} from 'stores/_mobx/workflow/serviceMaintenance/emailReport';
import convertToCsv from 'utils/convertToCsv';
import { downloadCsv } from 'utils/downloadFile';
import { EMAIL_REPORT as PAGE_ID } from 'constant/pagesId/workflow';
import { BASE_URL_FILE_DIR } from 'constant/config';

const messageView = (id: number) =>
  id ? (
    <IconButton
      onClick={() => {
        storeEmailReport.setMessagePreview(id);
      }}>
      <i className="bi bi-eye-fill text-success" />
    </IconButton>
  ) : (
    <span className="text-danger">
      <i className="bi bi-eye-slash-fill" />
    </span>
  );

const columns = [
  { name: 'template_name', title: 'Template Name' },
  { name: 'ct_name', title: 'Contact Name' },
  { name: 'rec_mail', title: 'Receiver Mail' },
  { name: 'sending_date', title: 'Date' },
  {
    name: 'refid',
    className: 'text-center',
    title: 'View Message',
    render: messageView,
  },
  {
    name: 'file_nm',
    className: 'text-center',
    title: 'View File',
    render: (fileName: string) =>
      fileName && (
        <>
          <a
            href={`${BASE_URL_FILE_DIR}doc_img/documents/${fileName}`}
            onClick={
              /\|/.test(fileName)
                ? storeEmailReport.setFilesToDisplay(fileName)
                : checkAndDownload(fileName)
            }
            target="_blank"
            rel="noreferrer">
            View file
          </a>
        </>
      ),
  },
];

const exportToCsv = (emailReport: EmailReportType[]) => {
  const exportColumns = columns.map(({ title, name }) => ({
    title,
    name,
  }));

  const csvData = convertToCsv(emailReport, exportColumns);

  const appName =
    UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) || 'Email Report';

  const fileName = `${appName}_${format(new Date(), 'yyyy_MM_dd_HH-mm')}.csv`;

  downloadCsv(csvData, fileName);
};

const EmailReportPage = () => {
  const {
    fetching,
    emailReport,
    emailsReportTotal,
    message,
    filesToDisplay,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeEmailReport;

  const handleExport = useCallback(
    () => exportToCsv(emailReport),
    [emailReport]
  );

  const handleChangeFilter = (filter: FilterType) => {
    storeEmailReport.setFilter(filter);
    setPaginationToStart();
  };

  useEffect(() => {
    storeEmailReport.getEmailReportMain();
  }, [pagination]);

  useEffect(() => {
    storeEmailReport.getEmailTemplateList();

    return () => {
      storeEmailReport.clearMessagePreview();
      storeEmailReport.clearFilesToDisplay();
    };
  }, []);

  return (
    <>
      <Title title="Email Report" appId={PAGE_ID.PAGE} />

      <Filter onChange={handleChangeFilter} />

      <Grid
        id="emailReportGrid"
        columns={columns}
        onAjax={fetching}
        gridControlPanel={
          <GridControlButton
            title="Export"
            disabled={!emailsReportTotal}
            onClick={handleExport}
          />
        }
        dataSource={emailReport}
        pagination={pagination}
        dataSourceCount={emailsReportTotal}
        onPaginationChange={setPagination}
      />

      {message && <DialogMessage message={message} />}

      {filesToDisplay.length ? (
        <DialogFilesList
          files={filesToDisplay}
          onClose={storeEmailReport.clearFilesToDisplay}
        />
      ) : null}
    </>
  );
};

export default observer(EmailReportPage);
