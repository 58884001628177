import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

export class SUserTypePrivilegePageStore {
  dataSource: Array<any> = [];
  dataSourceCount: number = 0;
}

class UserTypePrivilegePageStore extends ReduceStore<
  SUserTypePrivilegePageStore,
  CommonPayload
> {
  getInitialState() {
    return new SUserTypePrivilegePageStore();
  }

  areEqual(
    state1: SUserTypePrivilegePageStore,
    state2: SUserTypePrivilegePageStore
  ) {
    return false;
  }

  reduce(state: SUserTypePrivilegePageStore, action: CommonPayload) {
    switch (action.type) {
      case 'user-type-privilege-settings-overview-action':
        this.getState().dataSource = action.data;
        break;
      default:
        break;
    }
    return state;
  }
}

const userTypePrivilegePageStore = new UserTypePrivilegePageStore(
  appDispatcher
);
export default userTypePrivilegePageStore;
