import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';
import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';

import { months } from 'utils/DateUtils';

const options = months.map((month, value) => ({
  label: month,
  value,
}));

export default class MonthSelectionDropdown extends AbstractDropdown<
  PAbstractDropdown,
  SAbstractDropdown
> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return options;
  }
}

export const DropDownMonth = (props: Omit<SelectPropsType, 'options'>) => (
  <Select {...props} options={options} />
);
