import { makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionsResponseType {
  appRefID: string;
  template_name: string;
  template_code: string;
  selected: boolean;
}

export interface OptionType {
  value: number;
  label: string;
}

export interface OptionBasicType {
  value: string;
  label: string;
}

class EventsForSubscribing {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,
    });
  }

  async getOptionsByUserTypeId(userTypeId: number) {
    if (this.fetching) return Promise.resolve();

    try {
      runInAction(() => {
        this.fetching = true;
      });
      const list = await apiRequest<OptionsResponseType[]>({
        url: 'permission.PermissionInfo.loadTemplateListForType',
        data: [userTypeId],
      });

      const options = list
        .filter(({ template_name }) => Boolean(template_name))
        .map(({ template_name, appRefID }) => ({
          label: template_name,
          value: Number(appRefID),
        }));

      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }
}

export const storeEventsForSubscribing = new EventsForSubscribing();
