import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const regionMsg = 'Please, select the region!';

const stateMsg = 'Please, select the state!';

const validation = yupResolver(
  yup.object().shape({
    region_id: yup.number().typeError(regionMsg).positive(regionMsg),
    state_id: yup.number().typeError(stateMsg).positive(stateMsg),
    location: yup.string().required('Please, enter the location!'),
  })
);

export default validation;
