import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import DiagnosisForm, { FormModel } from './components/Form';

import { storeDiagnosisCode } from 'stores/_mobx/systemSetup/codeSet/diagnosisCodes';
import { URL_DIAGNOSIS_CODES } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}
export interface PropsType extends RouteComponentProps<MatchParams> {}

const DiagnosisCodesEditPage = ({ match: { params }, history }: PropsType) => {
  const { fetching, code } = storeDiagnosisCode;

  const handleSubmit = (payload: FormModel) =>
    storeDiagnosisCode
      .updateCode(payload as Required<FormModel>)
      .then((response) => {
        if (!response) {
          history.push(URL_DIAGNOSIS_CODES);
        }
        return response;
      });

  useEffect(() => {
    storeDiagnosisCode.getCode(params.id);
    return storeDiagnosisCode.clearCode;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit ICD code" />
      <DiagnosisForm
        backUrl={URL_DIAGNOSIS_CODES}
        defaultValues={code}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(DiagnosisCodesEditPage);
