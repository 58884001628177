import React from 'react';

import AbstractInput, {
  PAbstractInput,
  SAbstractInput,
} from 'components/form/input/AbstractInput';
import RadioButton from 'components/form/input/RadioButton';

export class TRadioOption {
  label: React.ReactNode;
  value: string | boolean | number;
  disabled?: boolean = false;
  checked?: boolean = false;
}

interface PRadio extends PAbstractInput {
  name?: string;
  allowDeselect?: boolean;
  options?: Array<TRadioOption>;

  value?: string | number;
  attr?: {
    id?: string;
    disabled?: boolean;
    className?: string;
  };

  onSetValue?: (
    name: string,
    value: string,
    errorMessages?: any,
    errorKeys?: any,
    event?: any,
    clb?: () => void
  ) => void;
}

class SRadio extends SAbstractInput {}

export default class Radio extends AbstractInput<PRadio, SRadio> {
  render() {
    const { attr, className, noLabel, label } = this.props;
    const group = this.buildRadioGroup(this.getOptions());
    const labelOwnerId = attr ? attr.id : null;
    const labelValue = this.getLabelValue();
    const value = [labelValue];
    const isLabelRequired = noLabel ? false : !!label || !!labelValue;
    const labelFinalValue = isLabelRequired
      ? this.buildLabel(
          labelOwnerId,
          value,
          undefined,
          attr ? attr.disabled : false
        )
      : null;
    const formFieldClassName =
      'formfield-holder formfield-radio' + (className ? ' ' + className : '');
    return this.wrapInput(group, labelFinalValue, false, formFieldClassName);
  }

  getOptions() {
    return this.props.options;
  }

  buildRadioGroup(options: Array<TRadioOption>): React.ReactElement[] {
    const out: React.ReactElement[] = [];
    const dataTestId = this.props['data-testid'] || this.props.name;
    const onSetValue = (
      name: string,
      value: string,
      isChecked: boolean,
      errorMessages: any,
      errorKeys: any,
      event: any
    ) => {
      this.onRadioButtonClick(
        name,
        value,
        isChecked,
        errorMessages,
        errorKeys,
        event
      );
    };
    const id = this.getId();
    for (let i = 0; i < options.length; i++) {
      const option: TRadioOption = options[i];
      const radioId =
        `radio_${id}_${i}_${option.label}_${option.value}`.replace('/', '_');
      out.push(
        <RadioButton
          key={radioId}
          name={this.props.name}
          label={option.label}
          value={'' + option.value}
          checked={this.getValue() === option.value}
          attr={{
            id: radioId,
            disabled:
              (this.props.attr && this.props.attr.disabled) || option.disabled,
          }}
          onSetValue={onSetValue}
          data-testid={`${dataTestId}-${option.value}`}
        />
      );
    }
    return out;
  }

  onRadioButtonClick(
    name: string,
    value: string,
    isChecked: boolean,
    errorMessages: any,
    errorKeys: any,
    event: any
  ) {
    const { props } = this;
    const { onSetValue, allowDeselect } = this.props;
    if (typeof onSetValue === 'function') {
      if (isChecked) {
        onSetValue(props.name, value, errorMessages, errorKeys, event);
      } else if (allowDeselect && this.getValue() === value) {
        onSetValue(props.name, '', errorMessages, errorKeys, event);
      }
    }
  }
}
