import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { InputCalendar } from 'components/form/inputCalendar';
import { Input } from 'components/form/textField';
import { Radio } from 'components/form/radio';
import { Button } from 'components/button';
import useDebounce from 'components/hook/useDebounce';
import { ControlsLayout } from 'components/layout';

import {
  filterDefaultValues,
  storeLegacyPatientData,
  FilterType,
} from 'stores/_mobx/patientRecord/legacyPatientData';

const genderOptions = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'All', value: '' },
];

interface PropsType {
  isVisible: boolean;
  fetching: boolean;
  onSubmit: (data: FilterType) => void;
}

const Filter = ({ isVisible, fetching, onSubmit }: PropsType) => {
  const { control, reset, watch } = useForm<FilterType>({
    defaultValues: storeLegacyPatientData.filter,
  });

  const debouncedMethod = useDebounce(onSubmit);

  const handleClickReset = () => {
    reset(filterDefaultValues);
    onSubmit(filterDefaultValues);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name) {
        debouncedMethod(formValue);
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className={`row${isVisible ? '' : ' d-none'}`}>
      <Input
        name="lastName"
        label="Last Name"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <Input
        name="firstName"
        label="First Name"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <Input
        name="middleName"
        label="Middle name"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <Input
        name="accessionNumber"
        label="Accession No"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <Input
        name="medicalRecordNumber"
        label="Medical Record Number"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <Input
        name="refPhysicianName"
        label="Ref Physician Name"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <InputCalendar
        name="birthDate"
        label="Birth Date"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <InputCalendar
        name="scheduleDate"
        label="Schedule Date"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <Radio
        className="col-md-6 col-lg-auto part-inline"
        name="gender"
        label="Gender"
        options={genderOptions}
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          text="Reset"
          variant="warning"
          disabled={fetching}
          onClick={handleClickReset}
        />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
