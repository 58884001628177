import { useState } from 'react';

import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';
import { PureInput } from 'components/form/textField';
import { Grid } from 'components/grid';

const columns = [{ name: 'name', title: 'Facility name' }];

interface PropsType {
  corpName: string;
  facilities: { name: string }[];
  onClose: () => void;
}

const DialogFacilitiesList = ({ corpName, facilities, onClose }: PropsType) => {
  const [searchName, setSearchName] = useState<string>('');

  const filteredList = searchName
    ? facilities.filter(({ name }) =>
        name.toLowerCase().includes(searchName.toLowerCase())
      )
    : facilities;

  return (
    <Dialog handleClose={onClose}>
      <DialogHeader title={`Facilities ${corpName}`} onClose={onClose} />
      <DialogBody>
        <div className="row mb-5">
          <PureInput
            placeholder="Facility name"
            className="col-md-8"
            value={searchName}
            onChange={setSearchName}
          />
        </div>
        <Grid
          columns={columns}
          dataSource={filteredList}
          disablePagination
          noControl
        />
      </DialogBody>
    </Dialog>
  );
};

export default DialogFacilitiesList;
