import { apiRequest } from 'services/RequestService';
import appDispatcher from 'dispatcher/AppDispatcher';
import { TUserTypeEnum } from 'services/project/UserSelectionService';
import { TPagination } from 'components/grid/GridTypes';

export class TEmployeeUserInfo {
  refid: number = null;
  user_name: string = '';
  user_no: string = '';
  comment: string = '';
  usertype_id: number = null;
  email: string = '';
  facility: string = '';
  type: string = '';
  status: 'Active' | 'Inactive' | 'Locked' | '';
  usertypename: string = '';
  user_type: TUserTypeEnum = '';
  UserId: number = null;
  flagViewPassword: boolean = false;
  first_name: string = '';
  last_name: string = '';
  birthdate: string = '';
  mobile: string = '';
  gender_name: 'Female' | 'Male' | '' = '';
  has_credentials: boolean = false;
  has_certifications: boolean = false;
  valid_credential: Array<any>;
  valid_certification: Array<any>;
}

export class TEmployeeMasterOverview {
  hrAdminPermissionAllowed: boolean = false;
  count: number = 0;
  data: Array<TEmployeeUserInfo> = [];
}

export class TEmployeeInfoFilter {
  lastName: string = '';
  firstName: string = '';
  userType: number = 0;
  licenses: string = '';
  expiration: string = '';
  active: string = 'A';
}

export class TDocument {
  refid: number = null;
  user_id: number = null;
  flag: string = '';
  state: string = '';
  valid_from: string = '';
  valid_to: string = '';
  type: string = '';
  license_no: string = '';
  file_name: string = '';
}

export default class EmployeeInformationActions {
  static loadOverview(filter: TEmployeeInfoFilter, pagination: TPagination) {
    return apiRequest<TEmployeeMasterOverview>({
      url: 'usermaster.EmployeeMaster.getOverview',
      data: [filter, pagination],
    }).then((v) => {
      appDispatcher.dispatch({
        type: 'application-users-employee-overview',
        hrAdminPermissionAllowed: v.hrAdminPermissionAllowed,
        data: v.data,
        count: v.count,
      } as any);
      return v;
    });
  }

  static loadDocuments(userId: number, documentType: string) {
    return apiRequest<Array<TDocument>>({
      url: 'usermaster.EmployeeMaster.getDocuments',
      data: [userId, documentType],
    });
  }

  static notifyExpired(userIds: Array<number>, subject: string, body: string) {
    return apiRequest<{ success: 0 | 1 }>({
      url: 'usermaster.EmployeeMaster.notifyExpired',
      data: [userIds, subject, body],
    });
  }
}
