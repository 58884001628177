import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import {
  storeDivisionMaster,
  DivisionDetailsType,
} from 'stores/_mobx/systemSetup/masterSetting/divisionMaster';
import { URL_DIVISION_MASTER } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const DivisionMasterEditPage = ({ match: { params }, history }: PropsType) => {
  const { fetching, divisionSettings } = storeDivisionMaster;

  const handleSubmit = async (data: DivisionDetailsType) =>
    storeDivisionMaster.modifyDivision(data).then((error) => {
      if (error) return error;
      history.push(URL_DIVISION_MASTER);
    });

  useEffect(() => {
    if (divisionSettings && divisionSettings.id !== Number(params.id)) {
      history.replace(URL_DIVISION_MASTER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisionSettings, params.id]);

  useEffect(() => {
    storeDivisionMaster.getDivision(Number(params.id));
  }, [params.id]);

  return (
    <>
      <LayoutSideTitle title="Edit Division">
        <Link to={URL_DIVISION_MASTER} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <Form defaultValues={divisionSettings} onSubmit={handleSubmit} />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(DivisionMasterEditPage);
