import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import FilterGroupTab, { FormModel, defaultValues } from './FilterGroupTab';

import {
  storeGroupManager,
  GroupTrimmedInfoType,
} from 'stores/_mobx/clinicianManager/groupManager';
import { storeExamReview } from 'stores/_mobx/workflow/order/examReview';

interface PropsType {
  orderId: number;
  order: { assign_providergrpid: number };
  onAssign: () => void;
}

const groupsFilter = (filter: FormModel, groups: GroupTrimmedInfoType[]) => {
  const isFilterEmpty = !filter.groupName && !filter.groupDescription;

  const groupName = filter.groupName.trim().toLowerCase();

  const groupDescription = filter.groupDescription.trim().toLowerCase();

  return isFilterEmpty
    ? groups
    : groups.filter(
        (group) =>
          group.groupName.toLowerCase().includes(groupName) &&
          group.description.toLowerCase().includes(groupDescription)
      );
};

const GroupTab = ({ order, orderId, onAssign }: PropsType) => {
  const { fetching, groupTrimmedInfoList } = storeGroupManager;

  const { assignInProgress } = storeExamReview;

  const [filter, setFilter] = useState<FormModel>(defaultValues);

  const filteredGroups = useMemo(
    () => groupsFilter(filter, groupTrimmedInfoList),
    [filter, groupTrimmedInfoList]
  );

  const handleClickAssignGroup = (data: GroupTrimmedInfoType) => {
    const payload = {
      ...data,
      orderId,
    };
    storeExamReview.assignPhysicianGroup(payload).then((isSucceed) => {
      if (isSucceed) onAssign();
    });
  };

  const columns = useMemo(
    () => [
      {
        name: 'groupName',
        title: 'Group name',
      },
      {
        name: 'description',
        title: 'Description',
      },
      {
        name: 'assign',
        title: 'Assign',
        render: (value: undefined, group: GroupTrimmedInfoType) =>
          group.id === order.assign_providergrpid ? (
            'Assigned'
          ) : (
            <a
              href="/"
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault();
                handleClickAssignGroup(group);
              }}>
              Assign
            </a>
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order.assign_providergrpid]
  );

  const buildRow = ({ id }: GroupTrimmedInfoType) =>
    id === order.assign_providergrpid ? { className: 'fw-bold' } : {};

  useEffect(() => {
    if (!fetching) storeGroupManager.getGroupsTrimmedInfoList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FilterGroupTab onChange={setFilter} />
      <Grid
        id="physician_assignment_group_grid"
        onAjax={fetching || assignInProgress}
        columns={columns}
        dataSource={filteredGroups}
        disablePagination
        gridControlPanelBottom={null}
        rowArguments={buildRow}
      />
    </>
  );
};

export default observer(GroupTab);
