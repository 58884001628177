import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';

export const filterDefaultValues: FilterModel = {
  facilityId: 0,
  callTypeId: 0,
  dateFrom: '',
  dateTo: '',
};

interface CallLogType {
  call_type: string;
  contact_person: string;
  facilityname: string;
  phone_no: string;
  priority: string;
  reason: string;
  refid: number;
  remarks: string;
  user_name: string;
  utc: string;
}

export interface FilterModel {
  facilityId: number;
  callTypeId: number;
  dateFrom: string;
  dateTo: string;
}

interface CallLogResponseType {
  call_type: number;
  caller_id: number;
  contact_person: string;
  facility_id: number;
  phone_no: string;
  priority: string;
  reason: string;
  refid: number;
  remarks: string;
}

export interface FormModel {
  facilityId: number;
  callTypeId: number;
  callerName: string;
  phoneNumber: string;
  reason: string;
  priorityId: number | '';
  remarks: string;
  id: number;
}

interface OptionResponseType {
  label: string;
  data: string;
}

export interface OptionType {
  label: string;
  value: number;
}

class CallLog {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  logsList: CallLogType[] = [];
  logsTotal: number = 0;
  log?: FormModel;
  idForDelete: number = 0;
  filter: FilterModel = { ...filterDefaultValues };
  callTypeOptions: OptionType[] = [];
  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'call_log_grid' });

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      logsList: observable,
      logsTotal: observable,
      log: observable,
      idForDelete: observable,
      filter: observable,
      callTypeOptions: observable,

      setFetching: action,
      setFetchingOptions: action,
      setLogsList: action,
      setLogsCount: action,
      setLog: action,
      setFilter: action,
      setIdForDelete: action.bound,
      clearIdForDelete: action.bound,
      clearLog: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setLogsList(logs: CallLogType[]) {
    this.logsList = logs;
  }

  setLogsCount(total: number) {
    this.logsTotal = total;
  }

  setLog(log?: FormModel) {
    this.log = log;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setFilter(filter: FilterModel) {
    this.filter = filter;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  clearLog() {
    this.log = undefined;
  }

  async getLogs(payload: any[]) {
    const { pagination } = this.page;

    const data = [...payload, pagination.skip, pagination.pageSize];

    try {
      const response = await apiRequest<CallLogType[]>({
        url: 'order.CallLog.CallLogList',
        data,
      });

      const logs = response.map((log) => ({
        ...log,
        utc: dateToLocalTimezone({ date: log.utc }),
        refid: Number(log.refid),
      }));

      this.setLogsList(logs);
    } catch (e: any) {
      this.setLogsList([]);
    }
  }

  async getLogsCount(data: any[]) {
    try {
      const response = await apiRequest<number>({
        url: 'order.CallLog.CallLogTotalCount',
        data,
      });

      this.setLogsCount(Number(response) || 0);
    } catch (e: any) {
      this.setLogsCount(0);
    }
  }

  getLogsMain = () => {
    const { filter } = this;

    this.setFetching(true);

    const { dateFrom, dateTo } = getDateRangeBounds({
      from: filter.dateFrom,
      to: filter.dateTo,
    });

    const filterPayload = [
      filter.facilityId,
      filter.callTypeId,
      dateFrom,
      dateTo,
    ];

    const promiseList = this.getLogs(filterPayload);

    const promiseCount = this.getLogsCount(filterPayload);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getLog(id: number) {
    this.setFetching(true);
    try {
      const [callLog] = await apiRequest<[CallLogResponseType]>({
        url: 'order.CallLog.GetViewCallLog',
        data: [id],
      });

      const log: FormModel = {
        facilityId: Number(callLog.facility_id),
        callTypeId: Number(callLog.caller_id),
        callerName: callLog.contact_person,
        phoneNumber: callLog.phone_no,
        reason: callLog.reason,
        priorityId: Number(callLog.priority) || '',
        remarks: callLog.remarks,
        id,
      };
      this.setLog(log);
    } catch (e: any) {
      this.setLog();
    } finally {
      this.setFetching(false);
    }
  }

  async addCallLog(payload: FormModel) {
    const data = [
      payload.facilityId,
      payload.callTypeId,
      null,
      payload.callerName,
      payload.phoneNumber,
      payload.reason,
      1,
      payload.priorityId,
      payload.remarks,
      [] as [],
    ];

    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'order.CallLog.AddCallLog',
        data,
      });
      if (result === 'S') {
        Notification.success('Call log added successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async updateCallLog(payload: FormModel) {
    const data = [
      payload.id,
      payload.facilityId,
      payload.callTypeId,
      null,
      payload.callerName,
      payload.phoneNumber,
      payload.reason,
      1,
      payload.priorityId,
      payload.remarks,
      [] as [],
    ];

    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'order.CallLog.EditCallLog',
        data,
      });
      if (result === 'S') {
        Notification.success('Call log updated successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async deleteCallLog() {
    this.setFetching(true);

    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'order.CallLog.DeleteCallLog',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Call log deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("Error occurred! It can't be deleted!");
      this.setFetching(false);
      return false;
    }
  }

  async getCallTypeOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOptions(true);

    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'order.CallLog.GetCallType',
      });

      const options = response.map((el) => ({
        label: el.label,
        value: Number(el.data),
      }));

      runInAction(() => {
        this.fetchingOptions = false;
        this.callTypeOptions = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.callTypeOptions = [];
      });
    }
  }
}

export const storeCallLog = new CallLog();
