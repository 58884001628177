import { memo } from 'react';

type MessageType = {
  date: string;
  message: string;
  user: string;
};

interface PropsType {
  list: Array<MessageType>;
}

const Messages = ({ list }: PropsType) => {
  let prevUser = '';
  let prevDirection = true;

  const messagesList = list.map((message, index) => {
    const userIsDifferent = prevUser && prevUser !== message.user;

    const isLeft = userIsDifferent ? !prevDirection : true;

    prevDirection = userIsDifferent ? !prevDirection : prevDirection;

    prevUser = message.user;

    return (
      <div
        key={String(index)}
        className={`message${isLeft ? '' : ' text-end'}`}>
        <div className="content">
          <div className={`meta d-flex${isLeft ? '' : ' justify-content-end'}`}>
            <span>{message.user}</span>
            <span className="mx-3">{message.date}</span>
          </div>

          <div className={`message-triangle${isLeft ? '' : ' right'}`} />
          <div className="message-text">{message.message}</div>
        </div>
      </div>
    );
  });

  return <div className="messages">{messagesList}</div>;
};

export default memo(Messages);
