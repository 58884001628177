import React from 'react';

import UserProfileStore from 'stores/UserProfileStore';
import { UserTypeEnum } from 'stores/_mobx/dropDown/userType';

interface AllowedUser {
  refid: number;
  userType: UserTypeEnum;
  userTypeName: string;
}

export const ALLOWED_USER_TYPES_TO_DELETE_CPT_CODES_HAVE_REPORTS: AllowedUser[] = [
  {
    refid: 8,
    userType: 'U',
    userTypeName: 'Super Admin'
  },
  {
    refid: 13,
    userType: 'B2',
    userTypeName: 'Staff-Billing'
  },
  {
    refid: 30,
    userType: 'SM',
    userTypeName: 'Company Admin'
  }
];

export default class AccessUtils {
  static checkAccess(appId: string): boolean {
    if (UserProfileStore.getUserType() === 'U') {
      return true;
    }
    return UserProfileStore.haveAccess(appId);
  }

  static checkAccessAny = function (...args: string[]) {
    return args.some((id) => AccessUtils.checkAccess(id));
  };

  static get(
    appId: string,
    value: string | React.ReactNode,
    defaultValue: string = null
  ): string | React.ReactNode {
    return AccessUtils.checkAccess(appId) ? value : defaultValue;
  }

  static filter(arr: Array<any>, valueAppIdMapping: any, key = (x: any) => x) {
    return arr.filter(
      (v) =>
        !valueAppIdMapping.hasOwnProperty(key(v)) ||
        AccessUtils.checkAccess(valueAppIdMapping[key(v)])
    );
  }

  static filterBySameIndex(arr: Array<string>, appIdArr: Array<string>) {
    return arr.filter((v: string, i: number) =>
      AccessUtils.checkAccess(appIdArr[i])
    );
  }

  static filterBySameIndexRn(
    arr: Array<React.ReactNode | any>,
    appIdArr: Array<string>
  ) {
    return arr.filter((v: React.ReactNode, i: number) =>
      AccessUtils.checkAccess(appIdArr[i])
    );
  }

  static haveAccessToDeleteCptCodes(): boolean {
    return ALLOWED_USER_TYPES_TO_DELETE_CPT_CODES_HAVE_REPORTS
      .some((user) => user.refid === UserProfileStore.getUserTypeId());
  }
}
