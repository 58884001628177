import React, { createRef } from 'react';

import EncounterMessageDialog from 'page/components/messages/EncounterMessageDialog';
import DialogAuditLog from 'page/workflow/order/components/DialogAuditLog';
import EligibilityPopup, {
  TOrder4EligibilityPopup,
} from 'page/workflow/order/eligibility/EligibilityPopup';
import { Grid, TPagination } from 'components/grid';
import { IconButton } from 'components/button';
import DialogConfirm from 'components/modal/dialogConfirm';
import Legend from './components/Legend';
import ExamCode, {
  TOrder4ExamCode,
} from '../components/provider-update/ExamCode';
import DialogClaimPreview from '../components/dialogClaimPreview';
import DialogFormClaimAddressDetails from '../components/dialogFormClaimAddressDetails';
import ProviderUpdate from '../components/ProviderUpdate';

import { TClaimManager } from 'services/billing-coding/claims/ClaimsService';
import UserProfileStore from 'stores/UserProfileStore';
import { storeGrid } from 'stores/_mobx/grid';
import { storeBillingAssignment } from 'stores/_mobx/billingCodding/billingAssignment';
import { getPagination } from 'utils/getPagination';

export interface PAbstractClaim {
  isFilterVisible: boolean;
  isDivisionEnabled: boolean;
}

export class TAbstractClaimFilter {
  radiologyId: number[] = [];
  lastName: string = '';
  firstName: string = '';
  examType: number[] = [];
  providerType: number[] = [];
  facility: number[] = [];
  location: number[] = [];
  state: number[] = [];
  orderType: number[] = [];
  pos: number[] = [];
  billProcess: string = '';
  dosStart: string = '';
  dosEnd: string = '';
  period: string = 'A';
  claimType: 'T' | 'P' = 'T';
  batched: string = 'U';
  batchedDate: string = '';
  isSigned: string = '';
  cpt: string = '';
  payerName: number[] = [];
  division: number = 0;
}

export class SAbstractClaim {
  onAjax: boolean = false;
  encounterId: number = null;
  dataSource: Array<TClaimManager> = [];
  dataSourceCount: number = 0;
  selected: Array<string> = [];
  pagination: TPagination = new TPagination();
  filter: TAbstractClaimFilter = new TAbstractClaimFilter();
  facilityView: boolean = false;
  providerUpdate: boolean = false;
  showEligibilityPopup: boolean = false;
  examCode: boolean = false;
  showMoveTo: boolean = false;
  order: TClaimManager = null;
  isAuditLogOpen: boolean = false;
  viewClaim: boolean = false;
  zipView: boolean = false;
  appliedClaimType: 'T' | 'P' = 'T';
}

export default abstract class AbstractClaim<
  P extends PAbstractClaim,
  S extends SAbstractClaim
> extends React.Component<P, S> {
  static getDefaultState(gridId: string) {
    return {
      ...new SAbstractClaim(),
      pagination: getPagination(storeGrid.getPageSize(gridId)),
    };
  }

  grid = createRef<Grid>();

  componentDidMount() {
    this.updateData();
  }

  updateData(): void {}

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.setState({
      onAjax: false,
    });
    if (grid) {
      // @ts-ignore
      grid.clearSelection();
    }
  };

  getGridActions(): React.ReactNode {
    return null;
  }

  getTitle(): React.ReactNode {
    throw new Error('implement me');
  }

  exportNewClaims() {
    throw new Error('Not implemented');
  }

  onPaginationChange = (pagination: TPagination) => {
    this.setState({ pagination }, () => this.updateData());
  };

  onSelectChange = (selected: Array<any>) => {
    this.setState({ selected });
  };

  abstract getFilterComponent(): React.ReactNode;

  onFilterSubmit() {
    this.updateData();
    this.setState({ selected: [] });
  }

  handleCloseClaim = () => {
    this.setState({ viewClaim: false, order: null });
  };

  handleChangeDivision = (division: number) => {
    const filter = {
      ...this.state.filter,
      division,
    };

    this.setState({ filter });
  };

  render() {
    const state = this.state;
    const claimType = state.filter.claimType;
    const panel = this.getGridActions();

    return (
      <div>
        {this.getFilterComponent()}

        <Grid
          ref={this.grid}
          selectId="claimUniqId"
          gridControlPanel={panel}
          id={this.getGridId()}
          onAjax={state.onAjax}
          columns={this.getColumns()}
          dataSource={state.dataSource}
          pagination={state.pagination}
          selectedIds={state.selected}
          dataSourceCount={state.dataSourceCount}
          onDoubleClick={this.handleClickVisitInfo}
          shownColumns={this.getStartShownColumns()}
          onPaginationChange={this.onPaginationChange}
          onSelectChange={this.onSelectChange}
        />
        <Legend />

        {state.showEligibilityPopup && (
          <EligibilityPopup
            order={Object.assign(new TOrder4EligibilityPopup(), {
              last_name: state.order.last_name,
              first_name: state.order.first_name,
              dob: state.order.dob,
              ssn: state.order.ssn,
              dos: state.order.dos,
              iceNumber: state.order.ice_number,
              inmateNumber: state.order.inmate_number,
              immigrationNumber: state.order.immigration_id,
              state: state.order.state,
              facilitynm: state.order.facility_name,
              phone: state.order.fac_bill_phone,
              face_sheet: state.order.face_sheet,
              medicare_id: state.order.medicare_id,
              medicaid_id: state.order.medicaid_id,
              medicaid_stateid: Number(state.order.medicaid_stateid) || 0,
              ins_notes: state.order.ins_notes,
              // @ts-ignore
              correctionalFacility: state.order.correctionalFacility,
              patientid: state.order.patientid,
              refid: state.order.refid,
              facilityid: state.order.facilityid,
            })}
            eligibility={'ONLY_SAVE'}
            callback={(v) => this.closeEligibility(v)}
          />
        )}
        {state.facilityView && (
          <DialogFormClaimAddressDetails
            title="Facility Information"
            order={state.order}
            isPCInstance={false}
            claimAndFacility={false}
            facilityOnly
            onClose={this.handleCloseDialogFacility}
            onSubmit={this.handleApplyDialogFacility}
          />
        )}
        {state.zipView && (
          <DialogFormClaimAddressDetails
            title="Service Location"
            order={state.order}
            isPCInstance={this.isProfessional()}
            claimAndFacility={false}
            onClose={this.handleCloseDialogZip}
            onSubmit={this.handleApplyDialogZip}
          />
        )}
        {state.providerUpdate && (
          <ProviderUpdate
            claimUniqId={state.order.claimUniqId}
            order={state.order}
            onClose={this.handleCloseDialogProviderUpdate}
            onUpdate={this.handleSubmitDialogProviderUpdate}
          />
        )}
        {state.examCode && (
          <ExamCode
            order={Object.assign(new TOrder4ExamCode(), state.order)}
            hideLeftGrid={this.hideLeftGrid()}
            cancelStatus={claimType === 'T' ? 'V' : 'N'}
            onClose={this.examCode}
          />
        )}
        {state.showMoveTo && (
          <DialogConfirm
            onApprove={() => this.closeMoveTo(true)}
            onCancel={() => this.closeMoveTo(false)}>
            {`Do you want to move this patient to ${
              claimType === 'T' ? 'technical' : 'professional'
            } component claims?`}
          </DialogConfirm>
        )}
        {state.encounterId && (
          <EncounterMessageDialog
            id={this.state.encounterId}
            onClose={(shouldUpdate: boolean) =>
              this.setState({ encounterId: null }, () => {
                if (shouldUpdate) this.updateData();
              })
            }
          />
        )}
        {state.viewClaim && (
          <DialogClaimPreview
            orderId={state.order.orderid}
            claimId={state.order.claimUniqId}
            claimType={state.appliedClaimType}
            title={`${state.order.patientLastNm} ${state.order.patientFirstNm}`}
            onClose={this.handleCloseClaim}
          />
        )}
        {state.isAuditLogOpen && (
          <DialogAuditLog
            order={this.state.order}
            onClose={() => this.setState({ isAuditLogOpen: false })}
          />
        )}
      </div>
    );
  }

  abstract getGridId(): string;
  abstract hideLeftGrid(): boolean;

  closeMoveTo(response: any, flag?: boolean): void {}

  isProfessional(): boolean {
    throw new Error('Implement me');
  }

  viewClaim(order: TClaimManager) {
    this.setState({ viewClaim: true, order });
  }

  handleApplyFilter = () => {
    const pagination = {
      ...this.state.pagination,
      page: 1,
      skip: 0,
    };

    const appliedClaimType = this.state.filter.claimType;

    this.setState({ pagination, selected: [], appliedClaimType }, this.updateData);
  };

  getButtons(): any {
    return null;
  }

  examCode = () => {
    this.simpleModalClose(false, 'examCode');
  };

  handleCloseDialogProviderUpdate = () => {
    this.setState({ order: null, providerUpdate: false });
  };

  handleSubmitDialogProviderUpdate = () => {
    this.handleCloseDialogProviderUpdate();
    this.updateData();
  };

  providerUpdate(v: boolean) {
    this.simpleModalClose(v, 'providerUpdate');
  }

  handleApplyDialogFacility = () => {
    this.setState({ facilityView: false, order: null }, () =>
      this.updateData()
    );
  };

  handleCloseDialogFacility = () => {
    this.setState({ facilityView: false, order: null });
  };

  handleApplyDialogZip = () => {
    this.setState({ zipView: false, order: null }, () => this.updateData());
  };

  handleCloseDialogZip = () => {
    this.setState({ zipView: false, order: null });
  };

  closeEligibility(v: boolean) {
    this.simpleModalClose(v, 'showEligibilityPopup');
  }

  simpleModalClose(flag: boolean, property: string) {
    const state: any = { order: null };
    state[property] = false;
    if (flag) {
      this.setState(state, () => this.updateData());
    } else {
      this.setState(state);
    }
  }

  buildIcon(
    iconPath: string,
    order: TClaimManager,
    clb: (order: TClaimManager) => void
  ) {
    return (
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault();
          clb(order);
        }}>
        <img src={'assets/' + iconPath} alt="img" />
      </a>
    );
  }

  validateInsurance(order: TClaimManager) {
    this.setState({ showEligibilityPopup: true, order });
  }

  validateFacility(order: TClaimManager) {
    this.setState({ facilityView: true, order });
  }

  validateZip(order: TClaimManager) {
    this.setState({ zipView: true, order });
  }

  displayOrderingPopup(order: TClaimManager) {
    this.setState({ providerUpdate: true, order });
  }

  displayExamCode(order: TClaimManager) {
    this.setState({ examCode: true, order });
  }

  getColumns() {
    const { isDivisionEnabled } = this.props;

    const out = [
      { name: 'orderid', title: 'Claim #' },
      { name: 'facility_name', title: 'Facility' },
      isDivisionEnabled ? { name: 'division', title: 'Division' } : null,
      { name: 'patientLastNm', title: 'Last Name' },
      { name: 'patientFirstNm', title: 'First Name' },
      { name: 'dos', title: 'DOS', className: 'text-nowrap', dataType: 'date' },
      { name: 'radiologist', title: 'Radiologist' },
      {
        name: 'insImg',
        title: 'Insurance',
        render: (value: string, data: TClaimManager) => {
          return this.buildIcon(value, data, (order) =>
            this.validateInsurance(order)
          );
        },
      },
      {
        name: 'facImg',
        title: 'Facility Address',
        render: (value: string, data: TClaimManager) => {
          return this.buildIcon(value, data, (order) =>
            this.validateFacility(order)
          );
        },
      },
      {
        name: 'zipImg',
        title: '9 Digit Zip',
        render: (value: string, data: TClaimManager) => {
          return this.buildIcon(value, data, (order) =>
            this.validateZip(order)
          );
        },
      },
      {
        name: 'doctor_pop_img',
        title: 'Ordering MD',
        render: (value: string, data: TClaimManager) => {
          return this.buildIcon(value, data, (order) =>
            this.displayOrderingPopup(order)
          );
        },
      },
      {
        name: 'dobImg',
        title: 'DOB',
        className: 'text-nowrap',
        render: (value: string, data: TClaimManager) => {
          return this.buildIcon(value, data, (order) =>
            this.validateInsurance(order)
          );
        },
      },
      {
        name: 'posImg',
        title: 'POS',
        render: (value: string, data: TClaimManager) => {
          return this.buildIcon(value, data, (order) =>
            this.validateZip(order)
          );
        },
      },
      {
        name: 'viewClaim',
        title: 'View Claim',
        render: (value: string, data: TClaimManager) => {
          return this.buildIcon('images/info_available.png', data, (order) =>
            this.viewClaim(order)
          );
        },
      },
      {
        name: 'examCode',
        title: 'Exam Code',
        render: (value: string, data: TClaimManager) => {
          return this.buildIcon('images/info_available.png', data, (order) =>
            this.displayExamCode(order)
          );
        },
      },
      { name: 'modality', title: 'Modality' },
      { name: 'location', title: 'Location' },
      { name: 'state', title: 'State' },
      {
        name: 'fee',
        title: 'Fee',
        className: 'text-center',
        groupName: 'Performing Physician Information',
        group: 'physInfo',
      },
      {
        name: 'info',
        title: 'Info',
        className: 'text-center',
        groupName: 'Performing Physician Information',
        group: 'physInfo',
      },
      {
        name: 'visitInfo',
        title: 'Visit Info',
        render: (value: string, data: TClaimManager) => (
          <IconButton
            className="text-primary"
            onClick={() => this.handleClickVisitInfo(data)}>
            <i className="bi bi-journal-medical" />
          </IconButton>
        ),
      },
      UserProfileStore.isClientUser()
        ? null
        : {
            name: 'encounterLog',
            title: 'Encounter Log',
            render: (r: any, { refid, hasLog }: TClaimManager) => (
              <IconButton
                className="text-primary"
                onClick={() => {
                  this.setState({ encounterId: refid });
                }}>
                <i
                  className={`bi ${
                    hasLog ? 'bi-envelope-check-fill' : 'bi-envelope'
                  }`}
                />
              </IconButton>
            ),
          },
      {
        name: 'auditLog',
        title: 'Auditlog',
        render: (r: any, data: TClaimManager) => (
          <IconButton onClick={() => this.showAuditLog(data)}>
            <i className="icon icon-auditlog" />
          </IconButton>
        ),
      },
    ];

    return out.filter(Boolean);
  }

  showAuditLog = (order: TClaimManager) => {
    this.setState({ order, isAuditLogOpen: true });
  };

  getStartShownColumns(): Map<string, boolean> {
    const data = [
      'facility_name',
      'patientLastNm',
      'patientFirstNm',
      'dos',
      'radiologist',
      'insImg',
      'facImg',
      'zipImg',
      'doctor_pop_img',
      'dobImg',
      'posImg',
      'viewClaim',
      'examCode',
      'fee',
      'info',
      'encounterLog',
    ];
    const out = new Map<string, boolean>();
    const columns = this.getColumns();
    for (let i = 0; i < columns.length; i++) {
      const name = columns[i].name;
      if (data.indexOf(name) === -1) {
        out.set(name, false);
      }
    }
    return out;
  }

  handleClickVisitInfo = (data: TClaimManager) => {
    storeBillingAssignment.getVisitDetails(data.orderid);
  };
}
