import { makeObservable, observable, action } from 'mobx';

import { TPagination } from 'components/grid/GridTypes';
import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

export interface OptionResponseType {
  code: string;
  data: string;
  label: string;
  selstat: boolean;
  status: boolean;
}

interface DocType {
  comments: string;
  edit_option: string;
  is_mobile: boolean;
  refid: number;
  shrt_code: string;
  type_name: string;
  view_in_list: boolean;
}

interface DocEditType {
  comments: string;
  is_mobile: boolean;
  refid: number;
  shrt_code: string;
  sign_order: string;
  type_name: string;
  view_in_list: boolean;
}

interface RequestPayloadType {
  pagination: TPagination;
  name: string;
}

export interface OptionType {
  label: string;
  value: number;
}

class DocumentType {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  documentTypesList: DocType[] = [];
  documentTypesTotal: number = 0;
  document: DocEditType | undefined = undefined;
  documentForDelete: number = 0;
  options: OptionType[] = [];

  filterName: string = '';
  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'importItemGrid' });

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      documentTypesList: observable,
      documentTypesTotal: observable,
      document: observable,
      documentForDelete: observable,
      filterName: observable,
      options: observable,

      setFetching: action.bound,
      setFetchingOptions: action,
      setOptions: action,
      setFilterName: action.bound,
      setDocumentTypesList: action.bound,
      setDocumentTypesTotal: action.bound,
      setDocument: action.bound,
      setDocumentForDelete: action.bound,
      clearDocumentForDelete: action.bound,
      clearDocument: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setOptions(options: OptionType[]) {
    this.options = options;
  }

  setFilterName(name: string) {
    this.filterName = name;
  }

  setDocumentTypesList(docs: DocType[]) {
    this.documentTypesList = docs;
  }

  setDocumentTypesTotal(total: number) {
    this.documentTypesTotal = total;
  }

  setDocument(doc?: DocEditType) {
    this.document = doc;
  }

  setDocumentForDelete(id: number) {
    this.documentForDelete = id;
  }

  clearDocumentForDelete() {
    this.documentForDelete = null;
  }

  clearDocument = () => {
    this.document = undefined;
  };

  async getDocumentTypesList({ pagination, name }: RequestPayloadType) {
    try {
      const response = await apiRequest<DocType[]>({
        url: 'generalmaster.ImportItemType.ImportItemTypeList',
        data: [name, pagination.skip, pagination.pageSize],
      });

      const documents = response.map((doc) => ({
        ...doc,
        refid: Number(doc.refid),
      }));
      this.setDocumentTypesList(documents);
    } catch (e) {
      this.setDocumentTypesList([]);
    }
  }

  async getDocumentTypesCount(name: string) {
    try {
      const count = await apiRequest<DocType[]>({
        url: 'generalmaster.ImportItemType.ImportItemTypeTotalCount',
        data: [name],
      });

      this.setDocumentTypesTotal(Number(count));
    } catch (e) {
      this.setDocumentTypesTotal(0);
    }
  }

  getDocumentTypesListMain = (props: RequestPayloadType) => {
    this.setFetching(true);

    const promiseList = this.getDocumentTypesList(props);

    const promiseCount = this.getDocumentTypesCount(props.name);

    Promise.allSettled([promiseList, promiseCount]).finally(() => {
      this.setFetching(false);
    });
  };

  async getDocument(id: number | string) {
    this.setFetching(true);
    try {
      const [document] = await apiRequest<[DocEditType]>({
        url: 'generalmaster.ImportItemType.GetViewImportItemType',
        data: [id],
      });
      if (document.refid) {
        this.setDocument(document);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setDocument();
    } finally {
      this.setFetching(false);
    }
  }

  async addDocument(payload: Omit<DocEditType, 'refid'>) {
    const data = [
      payload.type_name,
      payload.shrt_code,
      payload.view_in_list,
      payload.comments,
      payload.sign_order,
      payload.is_mobile,
    ];

    try {
      const result = await apiRequest<'E' | 'S'>({
        url: 'generalmaster.ImportItemType.AddImportItemType',
        data,
      });
      if (result === 'E') {
        return { message: 'This report code already exist!' };
      }
      Notification.success('Report type was added successfully!');
      return null;
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateDocument(payload: DocEditType) {
    const data = [
      payload.refid,
      payload.type_name,
      payload.shrt_code,
      payload.view_in_list,
      payload.comments,
      payload.sign_order,
      payload.is_mobile,
    ];

    try {
      const result = await apiRequest<'E' | 'S'>({
        url: 'generalmaster.ImportItemType.EditImportItemType',
        data,
      });

      if (result === 'E') {
        return { message: 'This report code already exist!' };
      }
      Notification.success('Report type was updated successfully!');
      return null;
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async deleteDocument() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.ImportItemType.DeleteImportItemType',
        data: [this.documentForDelete],
      });
      if (result === 'S') {
        Notification.success('Report type was deleted!');
        this.clearDocumentForDelete();
        return true;
      } else {
        throw Error('');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getOptions() {
    this.setFetchingOptions(true);
    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.ImportItemType.ImportItemTypeDropDown',
      });

      const options = response.map((option) => ({
        label: option.label,
        value: Number(option.data),
      }));

      this.setOptions(options);
      return response.map((el) => ({
        ...el,
        value: Number(el.data),
      }));
    } catch (e: any) {
      this.setOptions([]);
      return [];
    } finally {
      this.setFetchingOptions(false);
    }
  }
}

export const storeDocumentType = new DocumentType();
