import appDispatcher from 'dispatcher/AppDispatcher';
import { Actions } from 'stores/reports/custom-reports/physician-patient/PhysicianPatientPageStore';

import PhysicianPatientPageService from 'services/reports/custom-reports/physician-patient/PhysicianPatientPageService';

export default class PhysicianPatientPageActions {
  static loadList(conditionList, joint) {
    return PhysicianPatientPageService.loadList(conditionList, joint).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_PHYSICIAN_PATIENT_LIST,
          data: response,
        });
      }
    );
  }

  static createPdf(list, description) {
    return PhysicianPatientPageService.createPdf(list, description);
  }
}
