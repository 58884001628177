import { useEffect } from 'react';
import { Observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { Checkbox } from 'components/form/checkbox';
import { Select } from 'components/form/select';

import {
  storeServiceHistory,
  defaultFilter,
  FilterType,
} from 'stores/_mobx/workflow/serviceMaintenance/serviceHistory';

const className = 'col-md-6 col-lg-3';

const EQUIPMENT_TYPE = [
  { value: 'device', label: 'Unit' },
  { value: 'ekg', label: 'EKG' },
  { value: 'phone', label: 'Phone' },
  { value: 'vehicle', label: 'Vehicle' },
  { value: 'bone', label: 'Bone Sonometer' },
];

interface PropsType {
  visibility: boolean;
  fetching: boolean;
  onChange: (filter: FilterType) => void;
}

const Filter = ({ visibility, fetching, onChange }: PropsType) => {
  const { control, watch, reset } = useForm({
    defaultValues: storeServiceHistory.filter,
  });

  const handleReset = () => {
    reset(defaultFilter);
  };

  useEffect(() => {
    const subscription = watch((formValue) => {
      onChange(formValue as FilterType);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    storeServiceHistory.getServicedOptions();
  }, []);

  return (
    <form className={`row${visibility ? '' : ' visually-hidden'}`}>
      <Observer>
        {() => (
          <Select
            name="id"
            label="Serviced By"
            isLoading={storeServiceHistory.fetchingOptions}
            options={storeServiceHistory.options}
            className={className}
            control={control}
          />
        )}
      </Observer>

      <div className="col-lg-8">
        <div className="form-label">
          <b>Equipment Type</b>
        </div>
        <div className="d-flex">
          {EQUIPMENT_TYPE.map(({ value, label }) => (
            <Checkbox
              key={value}
              name={`equipmentType.${value}`}
              label={label}
              control={control}
              className="mx-3"
            />
          ))}
        </div>
      </div>

      <div className="col-lg-1 formfield-holder d-flex align-self-end justify-content-end">
        <Button
          text="Reset"
          variant="warning"
          disabled={fetching}
          onClick={handleReset}
        />
      </div>
    </form>
  );
};

export default Filter;
