import { Link, RouteComponentProps } from 'react-router-dom';

import { LayoutSideTitle } from 'components/layout';
import Form from './components/form';

import { IntegrationDetailsType, storeIntegrations } from 'stores/_mobx/systemSetup/masterSetting/integrations';
import { URL_INTEGRATION_MASTER_NEW } from 'constant/path/systemSetup';

const IntegrationAddPage = ({ history }: RouteComponentProps) => {
  const handleClickSubmit = (payload: IntegrationDetailsType) =>
    storeIntegrations.createIntegration(payload).then((response) => {
      if (response) {
        return response;
      }
      history.push(URL_INTEGRATION_MASTER_NEW);
    });

  return (
    <>
      <LayoutSideTitle title='Add Integration'>
        <Link to={URL_INTEGRATION_MASTER_NEW} className='btn btn-danger'>
          Back
        </Link>
      </LayoutSideTitle>
      <Form onSubmit={handleClickSubmit} />
    </>
  );
};

export default IntegrationAddPage;
