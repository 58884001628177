import { Link, RouteComponentProps } from 'react-router-dom';

import { LayoutSideTitle } from 'components/layout';
import Form from './components/Form';

import {
  storeDivisionMaster,
  DivisionDetailsType,
} from 'stores/_mobx/systemSetup/masterSetting/divisionMaster';
import { URL_DIVISION_MASTER } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const DivisionMasterAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: DivisionDetailsType) =>
    storeDivisionMaster.modifyDivision(data).then((error) => {
      if (error) return error;
      history.push(URL_DIVISION_MASTER);
    });

  return (
    <>
      <LayoutSideTitle title="Add Division">
        <Link to={URL_DIVISION_MASTER} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <Form onSubmit={handleSubmit} />
    </>
  );
};

export default DivisionMasterAddPage;
