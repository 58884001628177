import React from 'react';
import clsx from 'clsx';

/**
  @prop {string} 'data-testid' - data attribute for testing libraries
  If data-testid not presented, then prefix for data-testid attribute will generated with 'name' property
  '{dataTestId}-label' - data attribute for the label
  '{dataTestId}-field' - data attribute for the input field
  '{dataTestId}-error' - data attribute for the error block
*/

export interface PureTextareaProps {
  name?: string;
  value?: string | number;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  errorMessage?: string;
  rows?: number;
  resize?: boolean;
  defaultValue?: string;
  'data-testid'?: string;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  onFocus?: (e: React.SyntheticEvent) => void;
  onClick?: () => void;
}

const PureTextarea = React.forwardRef<HTMLTextAreaElement, PureTextareaProps>(
  (
    {
      name,
      required = false,
      disabled = false,
      label = '',
      className,
      errorMessage,
      rows = 10,
      resize = true,
      'data-testid': dataTestId,
      onBlur,
      onChange,
      ...rest
    }: PureTextareaProps,
    ref
  ) => {
    const cn = clsx(
      'formfield-holder',
      'formfield-text',
      { required },
      className
    );

    const testAttr = dataTestId || name;

    const handleChange = (e: React.SyntheticEvent) => {
      const currentValue = (e.target as HTMLInputElement).value;

      if (!onChange) return;
      onChange(currentValue);
    };

    const handleBlur = (e: React.SyntheticEvent) => {
      const currentValue = (e.target as HTMLInputElement).value;

      if (!onBlur) return;
      onBlur(currentValue);
    };

    return (
      <div className={cn}>
        {label ? (
          <div className="form-label">
            <label htmlFor={name} data-testid={`${testAttr}-label`}>
              {label}
            </label>
          </div>
        ) : null}
        <div className="formfield-input" data-testid={name}>
          <textarea
            {...rest}
            rows={rows}
            disabled={disabled}
            id={name}
            ref={ref}
            className="form-control"
            style={{ resize: resize ? 'vertical' : 'none' }}
            data-testid={`${testAttr}-field`}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        {errorMessage && (
          <div className="error" data-testid={`${testAttr}-error`}>
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

export default PureTextarea;
