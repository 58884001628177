import AbstractLikeFilter from './AbstractLikeFilter';

export default class ModalityFilter extends AbstractLikeFilter {
  name = 'modality';
  field = 'Modality';
  field1 = AbstractLikeFilter.getInsecureSecureField(
    'ppxray_mv_examtype.exam_type_nm'
  );
  className = 'modality-filter';
}
