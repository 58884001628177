import { RouteComponentProps } from 'react-router-dom';

import appDispatcher from 'dispatcher/AppDispatcher';
import UserProfileRequestService, {
  TAuthorisation,
  ResetPasswordSignInForm,
  ResetPasswordSecurityQuestionForm,
  LoginForm,
} from 'services/UserProfileRequestService';
import { backUrlGenerator } from 'utils/backUrlGenerator';
import { FormModel } from 'page/authorization/login/components/DialogFormRequestForLogin';
import { normalizeText } from 'utils/StringUtils';
import { URL_LICENSE_AGREEMENT } from 'constant/path/other';
import { storeGrid } from 'stores/_mobx/grid';
import { storeAuthorization } from 'stores/_mobx/auth';
import { storeDropdownFacility } from 'stores/_mobx/dropDown/facility';
import { storeGroupManager } from 'stores/_mobx/clinicianManager/groupManager';
import { storeService } from 'stores/_mobx/service';

export default class UserProfileActions {
  static checkLogin(payload: LoginForm) {
    return UserProfileRequestService.checkLogin(payload);
  }

  static isAuthorised({
    callback,
    history,
  }: {
    callback?: () => void;
    history: any;
  }) {
    return UserProfileRequestService.isAuthorised()
      .then((response) => {
        if (response?.[0]) {
          const user = {
            userID: String(response[0].refid),
            userType: String(response[0].usertype_id),
          };

          storeAuthorization.setSessionStorageOptions(user);

          return storeAuthorization
            .login({
              username: response[0].user_name,
              password: response[0].user_pass,
            })
            .then(() => response);
        }
        return response;
      })
      .then((response: Array<TAuthorisation>) => {
        const userInfo = response?.[0] || null;

        const promise =
          userInfo?.usertype === 'D'
            ? UserProfileActions.getUnSignOrderCount(Number(userInfo?.user_id))
            : Promise.resolve(0);

        storeService.setApplicationTimeZone(
          userInfo?.applicationTimezone || ''
        );

        return promise.then((count: number) => {
          // @ts-ignore
          storeGrid.getDataFromFlux(userInfo?.grids_data || []);
          appDispatcher.dispatch({
            type: 'user-profile/is-authorized',
            data: userInfo,
            permissions: userInfo?.privileges || [],
            unsignedOrders: count,
          } as any);

          if (callback) callback();

          if (userInfo && userInfo.view_status !== 'N') {
            history.push(URL_LICENSE_AGREEMENT);
          }
          return userInfo;
        });
      });
  }

  static getUnSignOrderCount(userId: number) {
    return UserProfileRequestService.getUnSignOrderCount(userId);
  }

  static getUnSignEquipmentCount(userId: number) {
    return UserProfileRequestService.getUnSignEquipmentCount(userId);
  }

  static async pinCheck(
    pin: string,
    history: any,
    credentials: { username: string; password: string }
  ) {
    try {
      const [response] = await UserProfileRequestService.pinCheck(pin);

      const isError = response.status === 'INVALID' || response.status === 'F';

      if (isError) throw Error('Incorrect PIN');

      const user = {
        userID: String(response.refid),
        userType: String(response.usertype_id),
      };

      storeAuthorization.setSessionStorageOptions(user);

      await storeAuthorization.login(credentials);

      const countPromise =
        response?.usertype === 'D'
          ? UserProfileActions.getUnSignOrderCount(Number(response.user_id))
          : Promise.resolve(0);

      const count = await countPromise;

      // @ts-ignore
      storeGrid.getDataFromFlux(response?.grids_data || []);

      storeService.setApplicationTimeZone(response?.applicationTimezone || '');

      appDispatcher.dispatch({
        type: 'user-profile/check-ipin',
        data: response,
        // @ts-ignore
        permissions: response?.privileges || [],
        unsignedOrders: count,
      });

      if (response?.view_status !== 'N') {
        history.push(URL_LICENSE_AGREEMENT);
      }

      return null;
    } catch (e: any) {
      return { pin: 'Incorrect PIN' };
    }
  }

  static requestForLogin(model: FormModel) {
    const messages = [];
    for (let name in model) {
      let value: string | number = (model as any)[name];
      let skip = false;
      switch (name) {
        case 'otherTitle':
          skip = true;
          break;
        case 'title':
          if (value === 'OTHER') {
            value = model.otherTitle;
          }
          break;
        case 'facility':
          const facility = storeDropdownFacility.findOption(value as number);
          if (facility) {
            value = facility.label;
          }
          break;
        case 'group':
          const group = storeGroupManager.findOptionById(value as number);
          value = group?.label || value;
          break;
      }
      if (!skip) {
        messages.push(normalizeText(name) + ': ' + (value || ''));
      }
    }
    return UserProfileRequestService.requestForLogin(messages.join('\n'));
  }

  static forgotPasswordStep1(payload: ResetPasswordSignInForm) {
    return UserProfileRequestService.forgotPasswordStep1(payload);
  }

  static forgotPasswordStep2(payload: ResetPasswordSecurityQuestionForm) {
    return UserProfileRequestService.verifyAnswer(payload);
  }

  static updateResetPassword(userId: number, newPassword: string) {
    return UserProfileRequestService.updateResetPassword(userId, newPassword);
  }

  static loginChangePassword(
    userId: number,
    login: string,
    oldPassword: string,
    newPassword: string,
    securityQuesId1: number,
    answer1: string,
    securityQuesId2: number,
    answer2: string
  ) {
    return UserProfileRequestService.loginChangePassword(
      userId,
      login,
      oldPassword,
      newPassword,
      securityQuesId1,
      answer1,
      securityQuesId2,
      answer2
    );
  }

  static checkSession(history: RouteComponentProps['history']) {
    const url = backUrlGenerator(window.location.pathname);
    return UserProfileRequestService.checkSession().then((resp) => {
      if (typeof resp !== 'string' || resp !== 'S') {
        storeAuthorization.logout().then((isSucceed) => {
          if (isSucceed) {
            UserProfileActions.logout();
            history.push(`/login${url}`);
          }
        });
      }
    });
  }

  static logout() {
    appDispatcher.dispatch({
      type: 'user-profile-logout',
    });
  }

  static resetCountUnsignedOrders() {
    appDispatcher.dispatch({
      type: 'user-profile-reset-unsigned-orders',
    });
  }
}
