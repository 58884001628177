import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { downloadFile } from 'utils/downloadFile';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';
import { BASE_URL_FILE_DIR } from 'constant/config';
import { REJECT_REASON as PAGE_ID } from 'constant/pagesId/workflow';

export const defaultFilter: FilterType = {
  lastName: '',
  firstName: '',
  stateId: 0,
  facilityId: 0,
  posId: 0,
  examType: 0,
  techId: 0,
  dosStart: '',
  dosEnd: '',
  period: 'A',
};

export interface FilterType {
  lastName: string;
  firstName: string;
  stateId: number;
  facilityId: number;
  posId: number;
  examType: number;
  techId: number;
  dosStart: string;
  dosEnd: string;
  period: 'M' | 'W' | 'A' | 'TD';
}

export interface ReportType {
  cptdesc: string;
  dos: string;
  facility_name: string;
  modality: string;
  no_of_img: number;
  order_id: string;
  pt_dob: string;
  pt_firstname: string;
  pt_lastname: string;
  rejected_no_of_img: string;
  rejected_reason: null;
  tech_name: string;
  techtooltip: string;
  tooltipCPT: string;
  total_img: number;
}

class RejectReasonReport {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  reports: ReportType[] = [];
  reportTotal: number = 0;

  filter: FilterType = defaultFilter;
  page: Pagination = new Pagination({ id: 'reject_reason_report_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      reports: observable,
      reportTotal: observable,
      filter: observable,

      setFetching: action,
      setReport: action,
      setReportTotal: action,
      setFilter: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setReport(reports: ReportType[]) {
    this.reports = reports;
  }

  setReportTotal = (count: number) => {
    this.reportTotal = count;
  };

  setFilter = (filter: FilterType) => {
    this.filter = filter;
  };

  async getReport(filter: FilterType) {
    const { pagination } = this.page;

    const { dateFrom, dateTo } = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const data = [
      filter.lastName,
      filter.firstName,
      filter.techId,
      filter.facilityId,
      filter.stateId,
      filter.posId,
      dateFrom,
      dateTo,
      filter.period,
      pagination.skip,
      pagination.pageSize,
      filter.examType || '',
    ];

    try {
      const response = await apiRequest<ReportType[]>({
        url: 'generalmaster.RejectReason.GetRejectReasonReportList',
        data,
      });
      const logs = response.map((log) => ({
        ...log,
        dos: dateToLocalTimezone({ date: log.dos, dateOnly: true }),
        no_of_img: Number(log.no_of_img) || 0,
      }));
      this.setReport(logs);
    } catch (e) {
      this.setReport([]);
    }
  }

  async getReportCount(filter: FilterType) {
    const { dateFrom, dateTo } = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const data = [
      filter.lastName,
      filter.firstName,
      filter.techId,
      filter.facilityId,
      filter.stateId,
      filter.posId,
      dateFrom,
      dateTo,
      filter.period,
      filter.examType || '',
    ];

    try {
      const count = await apiRequest<'SE' | number>({
        url: 'generalmaster.RejectReason.GetRejectReasonReportCount',
        data,
      });

      const total = Number(count) || 0;

      this.setReportTotal(total);
    } catch (e) {
      this.setReportTotal(0);
    }
  }

  getReportMain = async (filter: FilterType = this.filter) => {
    this.setFetching(true);

    const promiseList = this.getReport(filter);

    const promiseCount = this.getReportCount(filter);

    return Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  exportReport = async () => {
    const { filter } = this;

    this.setFetching(true);

    const exportName =
      UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
      'Reject Reason Report';

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    try {
      const data = [
        filter.lastName,
        filter.firstName,
        filter.techId,
        filter.facilityId,
        filter.stateId,
        filter.posId,
        dos.dateFrom,
        dos.dateTo,
        filter.period,
        this.reportTotal,
        filter.examType || '',
        exportName,
      ];

      const fileName = await apiRequest<string>({
        url: 'generalmaster.RejectReason.ExportRejectReason',
        data,
      });

      if (fileName) {
        downloadFile(`${BASE_URL_FILE_DIR}doc_img/rejectimage/${fileName}`);
      } else {
        throw Error('');
      }
    } catch (e: any) {
    } finally {
      this.setFetching(false);
    }
  };
}

export const storeRejectReasonReport = new RejectReasonReport();
