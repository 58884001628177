import AbstractLikeFilter from './AbstractLikeFilter';

export default class ProviderTypeFilter extends AbstractLikeFilter {
  name = 'proverType';
  field = 'Provider Type';
  field1 = AbstractLikeFilter.getInsecureSecureField(
    'ppxray_mv_facilitytype.facilitytype'
  );
  className = 'provide-type-filter';
}
