import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid, ColDef, ICellRendererParams } from 'components/gridModern';
import Dialog, {
  DialogBody,
  DialogHeader,
  DialogFooter,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { PureCheckbox } from 'components/form/checkbox';

import {
  storePatientDemographic,
  PatientType,
} from 'stores/_mobx/patientRecord/patientDemographic';
import { storeDropdownFacility } from 'stores/_mobx/dropDown/facility';
import UserProfileStore from 'stores/UserProfileStore';

import { PatientDifferencesType } from './index';

const basicColumns: ColDef<PatientType>[] = [
  { field: 'first_name', headerName: 'First Name', width: 150 },
  { field: 'middle_name', headerName: 'Middle Name', width: 150 },
  { field: 'last_name', headerName: 'Last Name', width: 150 },
  { field: 'birthdate', headerName: 'Date of Birth' },
  { field: 'genderView', headerName: 'Gender', width: 100 },
  { field: 'ssn', headerName: 'SSN', width: 150 },
  { field: 'facility', headerName: 'Facility', minWidth: 150, flex: 1 },
];

const additionalColumns: ColDef<PatientType>[] = [
  { field: 'inmate_number', headerName: 'OID Number' },
  { field: 'ice_number', headerName: 'ICE Number' },
  { field: 'immigration_id', headerName: 'Marshall ID' },
];

interface PropsType {
  isCorrectional: boolean;
  newPatientFacilityId: number;
  availablePatients: PatientType[];
  selectedPatient: PatientType;
  addPatient: () => Promise<void>;
  submitExistedPatient: (patient: PatientType) => void;
  setPatientDifferences: (patient: PatientDifferencesType) => void;
  setPatient: (patient: PatientType | null) => void;
  onClose: () => void;
}

const DialogExistingPatients = ({
  availablePatients,
  newPatientFacilityId,
  isCorrectional,
  addPatient,
  onClose,
  submitExistedPatient,
  selectedPatient,
  setPatientDifferences,
  setPatient,
}: PropsType) => {
  const { changeFacilityInProgress, fetching } = storePatientDemographic;

  const handleClickUseSelectedPatientBtn = () => {
    const userFacility = UserProfileStore.getFacility();

    if (userFacility && userFacility !== selectedPatient.facility_id) {
      setPatientDifferences({
        patient: selectedPatient,
        isClient: true,
        newFacilityId: userFacility,
      });
    } else if (newPatientFacilityId !== selectedPatient.facility_id) {
      setPatientDifferences({
        patient: selectedPatient,
        isClient: false,
        newFacilityId: newPatientFacilityId,
        currentFacilityName:
          storeDropdownFacility.findOption(newPatientFacilityId)?.label || '',
      });
    } else {
      submitExistedPatient(selectedPatient);
    }
  };

  const handleSelectPatient = useCallback((patient: PatientType) => {
    if(patient.refid !== selectedPatient?.refid) {
      setPatient(patient);
    } else {
      setPatient(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatient?.refid]);

  const columnDefs = useMemo<ColDef<PatientType>[]>(() => {
    const columns = basicColumns.concat(
      isCorrectional ? additionalColumns : [],
      {
        field: 'refid',
        headerName: 'Select Patient',
        width: 90,
        pinned: 'left',
        cellRenderer: ({ data }: ICellRendererParams<PatientType, number>) => (
          <PureCheckbox
            checked={data.refid === selectedPatient?.refid}
            onClick={() => handleSelectPatient(data)}
          />
        ),
      }
    );
    return columns;
  }, [isCorrectional, handleSelectPatient, selectedPatient?.refid]);

  return (
    <>
      <Dialog size="extraLarge" handleClose={onClose}>
        <DialogHeader title="Similar Patient(s) Found" onClose={onClose} />
        <DialogBody className={changeFacilityInProgress ? 'on-ajax' : ''}>
          <p>Patients with similar patient data have been found. You may use a patient below or continue adding the new patient.</p>
          <Grid
            columnDefs={columnDefs}
            rowData={availablePatients}
            totalCount={availablePatients.length}
          />
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={() => addPatient()}
            disabled={changeFacilityInProgress || fetching || Boolean(selectedPatient)}
            text="Continue Adding New Patient"
          />
          <Button
            onClick={handleClickUseSelectedPatientBtn}
            disabled={changeFacilityInProgress || fetching || !selectedPatient}
            text="Use Selected Patient"
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default observer(DialogExistingPatients);
