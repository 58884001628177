import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import RadioOptions from 'components/project/common/RadioOptions';
import DropdownEmailTemplate from 'components/project/dropdown/EmailTemplate';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Radio } from 'components/form/radio';
import { TextEditor } from 'components/form/richTextEditor';
import resolver from './validation';

import { storeEmailTemplate } from 'stores/_mobx/systemSetup/masterSetting/emailTemplate';

export interface FormModel {
  emailsList: string;
  template: number;
  attachment: string;
  subject: string;
  body: string;
}

interface PropsType {
  defaultValues: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
  onClose: () => void;
}

const DialogFormEmail = ({ defaultValues, onClose, onSubmit }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    watch,
    setValue,
    reset,
    handleSubmit,
  } = useForm<FormModel>({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'template') {
        const template = storeEmailTemplate.findTemplate(formValue.template);

        const emailBody = template?.body?.replace(/[(^")("$)]/g, '') || '';

        setValue('subject', template?.subject || '', { shouldDirty: true });

        setValue('body', emailBody, {
          shouldDirty: true,
        });
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Send email" onClose={onClose} />
      <DialogBody>
        <form onSubmit={handleClickSubmit}>
          <Input name="emailsList" label="Email" control={control} />

          <DropdownEmailTemplate
            name="template"
            label="Email template"
            control={control}
          />

          <Radio
            name="attachment"
            label="Attachment"
            className="part-inline"
            options={RadioOptions.yesNo}
            control={control}
          />

          <Input name="subject" label="Subject" control={control} />
          <TextEditor name="body" control={control} />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="warning"
          text="Reset"
          onClick={handleClickReset}
          disabled={isSubmitting}
        />
        <Button
          text="Send"
          onClick={handleClickSubmit}
          disabled={!isDirty || isSubmitting}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogFormEmail;
