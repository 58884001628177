import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import FilterNameAndStatus, {
  FilterType,
} from 'page/components/FilterNameAndStatus';
import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import DialogServicesNotes from '../components/DialogServicesNotes';

import { storeComputer } from 'stores/_mobx/workflow/equipmentInventory/computer';
import { EI_COMPUTER as PAGE_ID } from 'constant/pagesId/workflow';
import {
  URL_EQUIPMENT_INVENTORY,
  URL_COMPUTER_ADD,
  URL_COMPUTER_EDIT,
} from 'constant/path/workflow';

const ComputersListPage = () => {
  const gridRef = useRef<Grid>();

  const [noteId, setNoteId] = useState<number>(0);

  const [selected, setSelected] = useState<number[]>([]);

  const {
    fetching,
    computerForDelete,
    computersList,
    computersTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeComputer;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const renderControl = (id: number) => (
    <div className="control">
      <Link
        className="bi bi-pencil"
        to={URL_COMPUTER_EDIT.replace(':id', String(id))}
      />
      <IconButton onClick={() => storeComputer.setComputerForDelete(id)}>
        <i className="bi bi-trash" />
      </IconButton>
    </div>
  );

  const columns = useMemo(
    () => [
      { name: 'computer', title: 'Computer name' },
      { name: 'mac_id', title: 'Mac ID' },
      { name: 'serial_no', title: 'Serial No.' },
      { name: 'model', title: 'Model' },
      { name: 'mark', title: 'Mark' },
      { name: 'description', title: 'Description' },
      {
        name: 'refid',
        title: 'History',
        render: (id: number) => (
          <div className="control">
            <IconButton onClick={() => setNoteId(id)}>
              <i className="icon icon-notes" />
            </IconButton>
          </div>
        ),
      },
      {
        name: 'refid',
        title: 'Actions',
        className: 'width-100',
        render: renderControl,
      },
    ],
    []
  );

  const fetchData = () => {
    storeComputer.getComputersListMain();
    if (selected) gridRef.current?.clearSelection();
  };

  const handleConfirmDelete = () => {
    storeComputer.deleteComputer().then((result) => {
      if (result) {
        fetchData();
      }
    });
  };

  const handleChangeStatus = () => {
    const statusMsg = filter.options.isActive ? 'deactivated' : 'activated';

    storeComputer.changeComputerStatus(selected, statusMsg).then((result) => {
      if (result) fetchData();
    });
  };

  const handleCloseNotes = () => {
    setNoteId(0);
  };

  const handleChangeFilter = (newFilter: FilterType) => {
    filter.setOptions(newFilter);

    debouncedMethod();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Computer">
        <Link to={URL_EQUIPMENT_INVENTORY} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <FilterNameAndStatus
        placeholder="Computer Name"
        filter={filter.options}
        fetching={fetching}
        onChangeFilter={handleChangeFilter}
      />

      <Grid
        id="computer_grid"
        selectId="refid"
        ref={gridRef}
        columns={columns}
        onAjax={fetching}
        dataSource={computersList}
        dataSourceCount={computersTotal}
        pagination={pagination}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        gridControlPanel={
          <>
            <GridControlButton title="Add" url={URL_COMPUTER_ADD} />
            <GridControlButton
              title={filter.options.isActive ? 'Deactivate' : 'Activate'}
              onClick={handleChangeStatus}
              disabled={!selected.length}
            />
          </>
        }
      />

      {Boolean(computerForDelete) && (
        <DialogConfirm
          onCancel={storeComputer.clearComputerForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this item?
        </DialogConfirm>
      )}

      {Boolean(noteId) && (
        <DialogServicesNotes type="C" id={noteId} onClose={handleCloseNotes} />
      )}
    </>
  );
};

export default observer(ComputersListPage);
