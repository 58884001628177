import React, { forwardRef } from 'react';
import { Controller, Control } from 'react-hook-form';

import PurTextEditor, { PureTextEditorProps } from './PureTextEditor';

interface Props
  extends Omit<
    PureTextEditorProps,
    'value' | 'name' | 'onChange' | 'onBlur' | 'onFocus'
  > {
  control: Control<any>;
  required?: boolean;
  defaultValue?: string;
  name: string;
}

const TextEditor = forwardRef(
  (
    { control, name, defaultValue = '', required = false, ...rest }: Props,
    ref: any
  ) => (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ field, fieldState: { error } }) => {
        const errorMessage = (error && error.message) || '';

        return (
          <PurTextEditor
            errorMessage={errorMessage}
            required={required}
            {...rest}
            {...field}
            ref={ref}
          />
        );
      }}
    />
  )
);

export default TextEditor;
