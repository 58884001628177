import { useEffect, useMemo, useReducer, useRef } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import DialogChangingFeeSchedule from 'page/system-setup/components/DialogChangingFeeSchedule';
import DialogImporting, { ImportType } from 'page/components/DialogImporting';
import { FacilityExportLink } from 'page/components/ExportLink';
import { Grid, GridControlButton } from 'components/grid';
import { Button, IconButton } from 'components/button';
import { LayoutSideTitle } from 'components/layout';
import Exporter from 'components/project/ExporterNew';
import Tooltip from 'components/tooltip';
import useDebounce from 'components/hook/useDebounce';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import Filter from './components/Filter';
import { reducer, initialState, ActionType } from './stateFacilityListPage';

import {
  storeFacility,
  FacilityType,
  FilterType,
} from 'stores/_mobx/systemSetup/facility';
import UserProfileStore from 'stores/UserProfileStore';
import { DIR_WITH_EXAMPLE } from 'constant/config';
import { FACILITY_ID as PAGE_ID } from 'constant/pagesId/systemSetup';
import { URL_FACILITY_ADD, URL_FACILITY_EDIT } from 'constant/path/systemSetup';

const FacilitiesListPage = observer(
  ({ isDivisionEnabled }: PropsTypeWitDivisionSettings) => {
    const gridRef = useRef<Grid>();

    const {
      fetching,
      facilities,
      facilityTotal,
      filter,
      permission,
      paramsFeeDialog,
      page: { pagination, setPagination, setPaginationToStart },
    } = storeFacility;

    const [state, dispatch] = useReducer(reducer, initialState);

    const isActiveSelected = filter.status === 'A';

    const debouncedMethod = useDebounce(setPaginationToStart);

    const hasPermissionToChangeStatus = isActiveSelected
      ? permission.deactivateFacility
      : permission.activateFacility;

    const columns = useMemo(() => {
      const defaultColumns = [
        {
          name: 'facility_nm',
          title: 'Facility Name',
          className: 'text-icon-column',
          render: (facility: string, { alert_facility }: FacilityType) => (
            <div className="text-icon-wrapper">
              <span className="text-icon-pic">
                {alert_facility ? (
                  <Tooltip
                    theme="danger"
                    title="Facility alert"
                    body={alert_facility}>
                    <i className="bi bi-exclamation-triangle-fill text-danger" />
                  </Tooltip>
                ) : null}
              </span>
              <span>{facility}</span>
            </div>
          ),
        },
        {
          name: 'facilityID',
          title: 'Facility ID',
          render: (value: undefined, { refid }: FacilityType) => refid,
        },
        isDivisionEnabled
          ? { name: 'divisionShortName', title: 'Division' }
          : null,
        { name: 'status', title: 'Fee Schedule Status' },
        { name: 'facilitytype', title: 'Provider Type' },
        { name: 'phone', title: 'Facility Phone' },
        { name: 'fax', title: 'Facility Fax' },
        { name: 'bill_email', title: 'Billing Email' },
        { name: 'location', title: 'Location' },
        { name: 'ownername', title: 'Facility Contacts' },
        { name: 'jurisdiction', title: 'Billing Jurisdiction' },
        { name: 'r_values', title: 'R Code', className: 'text-nowrap' },
        { name: 'q_values', title: 'Q Code', className: 'text-nowrap' },
        {
          name: 'fee_schedule',
          title: 'Fee Schedule',
          className: 'width-50 text-center',
          export: { exclude: true },
          render: (value: undefined, data: FacilityType) => (
            <IconButton
              className="text-primary"
              disabled={!data.cpt_status}
              onClick={() => storeFacility.setParamsFeeDialog(data)}>
              <i className="bi bi-cash-coin" />
            </IconButton>
          ),
        },
        {
          name: 'profile',
          title: 'Profile',
          className: 'width-50 text-center',
          export: { exclude: true },
          render: (value: undefined, { refid }: FacilityType) => (
            <FacilityExportLink key={refid} id={refid} />
          ),
        },
        permission.editFacility
          ? {
              name: 'control',
              title: 'Action',
              className: 'width-50',
              export: { exclude: true },
              render: (value: undefined, { refid }: FacilityType) => (
                <div className="control">
                  <Link
                    to={URL_FACILITY_EDIT.replace(':id', `${refid}`)}
                    className="bi bi-pencil"
                    title="Edit"
                  />
                </div>
              ),
            }
          : null,
      ];

      return defaultColumns.filter((column) => Boolean(column));
    }, [permission.editFacility, isDivisionEnabled]);

    const columnsVisibleByDefault = useMemo(() => {
      const finalColumns: Map<string, boolean> = new Map<string, boolean>();

      columns.forEach((column) => {
        finalColumns.set(column.name, column.name !== 'ownername');
      });

      return finalColumns;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = () => {
      gridRef.current.clearSelection();
      storeFacility.getFacilitiesListMain(isDivisionEnabled);
    };

    const handleToggleFilter = () => {
      dispatch({
        type: ActionType.TOGGLE_FILTER,
      });
    };

    const handleToggleExport = () => {
      dispatch({
        type: ActionType.TOGGLE_EXPORT,
      });
    };

    const handleToggleImport = () => {
      dispatch({
        type: ActionType.TOGGLE_IMPORT,
      });
    };

    const handleSetSelected = (ids: number[]) => {
      dispatch({
        type: ActionType.SET_SELECTED,
        payload: ids,
      });
    };

    const handleChangeStatus = () => {
      storeFacility
        .changeFacilityStatusMain(state.selected)
        .then((isSucceed) => {
          if (isSucceed) {
            fetchData();
          }
        });
    };

    const handleChangeFilter = (newFilter: FilterType) => {
      storeFacility.setFilter(newFilter);
      debouncedMethod();
    };

    useEffect(() => {
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    useEffect(() => {
      storeFacility.checkPermission();

      return storeFacility.clearParamsFeeDialog;
    }, []);

    return (
      <>
        <LayoutSideTitle title="Physicians Page" appId={PAGE_ID.PAGE}>
          <Button
            variant="default"
            text={state.isFilterVisible ? 'Hide filter' : 'Show filter'}
            onClick={handleToggleFilter}
          />
        </LayoutSideTitle>
        <Filter
          fetching={fetching}
          isVisible={state.isFilterVisible}
          onFilterChange={handleChangeFilter}
        />

        <Grid
          id="facility_grid"
          selectId="refid"
          ref={gridRef}
          columns={columns}
          shownColumns={columnsVisibleByDefault}
          onAjax={fetching}
          dataSource={facilities}
          dataSourceCount={facilityTotal}
          pagination={pagination}
          onSelectChange={handleSetSelected}
          onPaginationChange={setPagination}
          gridControlPanel={
            <>
              <GridControlButton
                title="Export"
                disabled={!facilityTotal}
                onClick={handleToggleExport}
              />
              {permission.importAction && (
                <GridControlButton
                  title="Import"
                  onClick={handleToggleImport}
                />
              )}
              {permission.addFacility && (
                <GridControlButton title="Add" url={URL_FACILITY_ADD} />
              )}
              {hasPermissionToChangeStatus && (
                <GridControlButton
                  title={isActiveSelected ? 'Deactivate' : 'Activate'}
                  disabled={!state.selected.length}
                  onClick={handleChangeStatus}
                />
              )}
            </>
          }
        />

        {state.isExportVisible && (
          <Exporter
            exporter="FacilityListExporter"
            gridRef={gridRef}
            filter={filter}
            dataSource={facilities}
            columns={[]}
            reportName={
              UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
              'FacilityList'
            }
            exportOnBackend
            onClose={handleToggleExport}
            filterForRequest={[
              0,
              facilityTotal,
              filter.searchKey,
              filter.facilityName,
              filter.status,
              filter.billingJurisdictionId,
              filter.stateId,
              filter.regionId,
              '',
              '',
              '',
              true,
              filter.pos,
              filter.providerTypeId,
              filter.completenessOfData,
              isDivisionEnabled ? filter.division : 0,
            ]}
          />
        )}

        {state.isImportVisible && (
          <DialogImporting
            type={ImportType.facility}
            examplePath={`${DIR_WITH_EXAMPLE}/facility.csv`}
            onClose={handleToggleImport}
            onImportSucceed={fetchData}
          />
        )}

        {paramsFeeDialog && (
          <DialogChangingFeeSchedule
            onClose={storeFacility.clearParamsFeeDialog}
            {...paramsFeeDialog}
          />
        )}
      </>
    );
  }
);

export default withDivisionSettings(FacilitiesListPage);
