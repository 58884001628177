// These IDs are using for defying access to pages/functions
// according to the user role

// System setup pages

export const FACILITY_ID = {
  ADD: 'faci_add',
  ACTIVE: 'faci_active',
  INACTIVE: 'faci_inactive',
  DELETE: 'faci_delete',
  EDIT: 'faci_edit',
  PAGE: 'FacilityMaster',
};

export const FEE_SCHEDULE_JURISDICTION = {
  ARCHIVE: 'cpts_archive',
  IMPORT: 'cpts_import',
  EDIT: 'cpts_edit',
  PAGE: 'statecpt',
};

export const FEE_SCHEDULE_CORPORATE = {
  ARCHIVE: 'cptc_archive',
  IMPORT: 'cptc_import',
  EDIT: 'cptc_edit',
  PAGE: 'CorpCpt',
};

export const FEE_SCHEDULE_FACILITY = {
  INACTIVE: 'cpt_inactive',
  IMPORT: 'cpt_import',
  EDIT: 'cpt_edit',
  PAGE: 'facilitycpt',
};

export const CODE_ACTIVATION_MASTER = {
  ADD: 'cpt_gen_add',
  INACTIVE: 'cpt_gen_inactive',
  IMPORT: 'cpt_gen_import',
  ACTIVE: 'cpt_gen_active',
  DELETE: 'cpt_gen_delete',
  EDIT: 'cpt_gen_edit',
  PAGE: 'CptMaster',
};

export const CODES_SET_MODIFIERS = {
  ADD: 'modifier_add',
  DEACTIVATE: 'modifier_inactive',
  IMPORT: 'modifier_import',
  ACTIVATE: 'modifier_active',
  DELETE: 'modifier_delete',
  EDIT: 'modifier_edit',
  PAGE: 'Modifier',
};

export const CODES_SET_DIAGNOSIS = {
  ADD: 'icd_add',
  DEACTIVATE: 'icd_inactive',
  IMPORT: 'icd_import',
  ACTIVATE: 'icd_active',
  DELETE: 'icd_delete',
  EDIT: 'icd_edit',
  PAGE: 'IcdMaster',
};

export const CODES_SET_MAPPING = {
  PAGE: 'ServiceCategory',
};

// MS - Master-Settings
export const MS = {
  PAGE: 'MasterValue',
};

export const MS_APP_SETTINGS = {
  PAGE: 'app-settings',
};

export const MS_COMPANY_INFO = {
  PAGE: 'admininfo',
};

export const MS_EMAIL_GROUP = {
  PAGE: 'groupmsg',
};

export const MS_EMAIL_TEMPLATE = {
  PAGE: 'MailTemplate',
};

export const MS_INTEGRATION = {
  PAGE: 'integrations-master',
};

export const MS_LOGIN_ATTEMPT = {
  PAGE: 'LoginAttempt',
};

export const MS_MESSAGE_ARCHIVE = {
  PAGE: 'ArchiveDay',
};

export const MS_PWD_STRENGTH = {
  PAGE: 'PwdStrength',
};

export const MS_PREFIX = {
  PAGE: 'Prefix',
};

export const MS_PROVIDER_TYPE = {
  PAGE: 'FacilityTypeMaster',
};

export const MS_QUESTIONS = {
  PAGE: 'Question',
};

export const MS_SUFFIX = {
  PAGE: 'Suffix',
};

export const MS_TERMS_CONDITIONS = {
  PAGE: 'TermsCond',
};

export const MS_ADJUSTMENT_CODE = {
  PAGE: 'AdjustmentCode',
};

export const MS_FINDINGS = {
  PAGE: 'Finding',
};

export const MS_HOSPICE = {
  PAGE: 'hospicemaster',
};

export const MS_JURISDICTION = {
  PAGE: 'jurisdiction',
};

export const MS_PAYER_INFO = {
  ADD: 'pay_add',
  DELETE: 'pay_delete',
  EDIT: 'pay_edit',
  CONTRACT: 'pay_contract',
  PAGE: 'PayerInformation',
};

export const MS_PAYER_PLAN = {
  PAGE: 'payerplan_master',
};

export const MS_PAYER_TYPE = {
  PAGE: 'PayerType',
};

export const MS_PER_DIEM = {
  PAGE: 'perdiem',
};

export const MS_PLACE_OF_SERVICE = {
  PAGE: 'POS',
};

export const MS_Q0092 = {
  PAGE: 'Q0092Setup',
};

export const MS_TRANSPORT = {
  PAGE: 'transport',
};

export const MS_CPT_CATEGORY = {
  PAGE: 'cpt_category',
};

export const MS_DOCTYPE = {
  PAGE: 'ImportItem',
};

export const MS_MODALITY = {
  PAGE: 'ExamType',
};

export const MS_MOBILE_OPERATOR = {
  PAGE: 'MobileOperator',
};

export const MS_PRIORITY = {
  PAGE: 'Priority',
};

export const MS_RADIOLOGY_GROUP = {
  IMPORT_PC_CPT_CODES: 'ImportPcCptCodes',
  PAGE: 'RadiologyGroup',
};

export const MS_REGION = {
  PAGE: 'region',
};

export const MS_REJECT_REASON = {
  PAGE: 'RejectReason',
};

export const MS_SPECIALTY = {
  PAGE: 'Specialty',
};

export const MS_STATE = {
  PAGE: 'statemaster',
};

export const MS_DESIGNATION = {
  PAGE: 'Designation',
};

export const MS_ZIP_CODE = {
  ADD: 'AddZipCodes',
  PAGE: 'ZipCodes',
  CREATE: 'CREATE_ZIP_CODE',
  DELETE: 'DELETE_ZIP_CODE',
  EXPORT: 'EXPORT_ZIP_CODES',
  IMPORT: 'IMPORT_ZIP_CODES',
};

export const MS_DIVISION_MASTER = {
  PAGE: 'division-master',
};

export const DISPATCH_REGION = {
  ADD: 'loc_add',
  DELETE: 'loc_delete',
  EDIT: 'loc_edit',
  PAGE: 'LocationMaster',
};

export const CORPORATION = {
  PAGE: 'CorporateGroup',
};

export const FACILITY_CONTACT = {
  ADD: 'faciown_add',
  ACTIVE: 'faciown_active',
  INACTIVE: 'faciown_inactive',
  DELETE: 'faciown_delete',
  EDIT: 'faciown_edit',
  STATUS: 'faciown_status',
  PAGE: 'FacilityOwner',
};

export const FACILITY_CONTACT_REPORT = {
  PAGE: 'faccontactrpt',
};
