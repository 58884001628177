import { forwardRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';
import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';

import {
  storeExamType,
  OptionType,
} from 'stores/_mobx/systemSetup/masterSetting/examType';

interface PropsType extends PAbstractDropdown {
  options: OptionType[];
  ref: any;
  optionsForOrderOnly?: boolean;
}

interface NewPropsType extends Omit<SelectPropsType, 'options'> {
  optionsForOrderOnly?: boolean;
}

class ExamTypeDropdown extends AbstractDropdown<PropsType, SAbstractDropdown> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<ExamTypeDropdown, any>(
  ({ optionsForOrderOnly, ...props }: PropsType, ref) => {
    const { options, fetchingOptions } = storeExamType;

    useEffect(() => {
      if (optionsForOrderOnly) {
        storeExamType.getOptionsForOrder();
      } else {
        storeExamType.getOptions();
      }
    }, [optionsForOrderOnly]);

    return (
      <ExamTypeDropdown
        {...props}
        ref={ref}
        options={options}
        fetching={fetchingOptions}
      />
    );
  }
);

export default observer(DropDownMiddleware);

export const DropDownExamType = observer(
  ({ optionsForOrderOnly, ...props }: NewPropsType) => {
    const { fetchingOptions, options } = storeExamType;

    useEffect(() => {
      if (optionsForOrderOnly) {
        storeExamType.getOptionsForOrder();
      } else {
        storeExamType.getOptions();
      }
    }, [optionsForOrderOnly]);
    return <Select {...props} options={options} isLoading={fetchingOptions} />;
  }
);
