import { apiRequest } from 'services/RequestService';
import { MessagePreviewType } from 'stores/_mobx/message/sent';

export interface RecipientBasicType {
  mail: string;
  to_mail: string;
  to_name: string;
}
export interface RecipientType {
  facility: string;
  firstName: string;
  lastName: string;
  mail?: number;
  refid: string;
  to_mail: number;
  to_name: string;
  type: string;
}

export interface EmailType {
  to: (RecipientType | RecipientBasicType)[];
  toS: string;
  cc: (RecipientType | RecipientBasicType)[];
  ccS: string;
  bcc: (RecipientType | RecipientBasicType)[];
  bccS: string;
  subject: string;
  priority: string;
  body: string;
  submitType: string;
  draftId: number;
}

const formatReceiver = (list: (RecipientType | RecipientBasicType)[]) =>
  list.map((receiver) => ({ ...receiver, mail: receiver.to_mail }));

class InboxService {
  getInboxMessageDetail(id: string) {
    return apiRequest<MessagePreviewType[]>({
      url: 'email.EmailMessageDisplay.EmailBodyMessageDisplay',
      data: [id, 'I', 'I'],
    });
  }

  sendEmail({ cc, bcc, ...payload }: EmailType) {
    const data = {
      to: formatReceiver(payload.to),
      cc: cc?.length ? formatReceiver(cc) : '',
      bcc: bcc?.length ? formatReceiver(bcc) : '',
      subject: payload.subject,
      priority: payload.priority,
      body: payload.body,
      toS: payload.toS,
      ccS: payload.ccS,
      submitType: payload.submitType,
      draftId: payload.draftId,
    };

    return apiRequest<'S' | 'SE'>({
      url: 'email.Email.AddEmail',
      data,
    });
  }
}

export default new InboxService();
