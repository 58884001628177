import { RouteComponentProps } from 'react-router-dom';

import AbstractOrderOverview, {
  PAbstractOrderOverview,
  SAbstractOrderOverview,
  TFilter,
} from 'page/workflow/order/AbstractOrderOverview';
import { Button } from 'components/button';
import { TSignOrderFilter } from 'services/clinician-manager/sign-orders/SignOrdersPageService';
import Form from 'components/form/Form';
import Text from 'components/form/input/Text';
import TextCalendar from 'components/form/input/TextCalendar';
import { DateRangeLegacy } from 'components/form/dateRange';
import { storeGrid } from 'stores/_mobx/grid';
import UserProfileStore from 'stores/UserProfileStore';
import SignOrdersPageActions from 'actions/clinician-manager/sign-orders/SignOrdersPageActions';
import SignOrdersPageStore from 'stores/clinician-manager/sign-orders/SignOrdersPageStore';
import StateDropdown from 'components/project/dropdown/StateDropdown';
import RegionDropdown from 'components/project/dropdown/RegionDropdown';
import FacilityRolesDropdown from 'components/project/dropdown/FacilityRolesDropdown';
import PlaceOfServiceDropdown from 'components/project/dropdown/PlaceOfServiceDropdown';
import { TPagination } from 'components/grid/GridTypes';
import { TUserTypeEnum } from 'services/project/UserSelectionService';
import { getPagination } from 'utils/getPagination';
import { getDateRangeBounds } from 'utils/DateUtils';
import { ControlsLayout } from 'components/layout';

export interface PAbstractSignOrdersPage
  extends PAbstractOrderOverview,
    RouteComponentProps {}

export class TAbstractSignOrdersPageFilter extends TFilter {
  coperate_Id: number = 0;
  srchLastName: '';
  userType: TUserTypeEnum = UserProfileStore.getUserType();
  facilityId: number = FacilityRolesDropdown.getFacilityId();
  userId: number = UserProfileStore.getUserId();
  srchFirstName: string = '';
  stateId: number = 0;
  locId: number = null;
  posId: number = 0;
  signed: string = 'N';
  dosSearch: string = '';
  physicianId: number = null;
  physicianGroupID: number = null;
  pcpId: number = 0;
  companyClient: number = null;
  tab: string = '';
  srchDate: string = '';
  fromDate: string = '';
  toDate: string = '';
  period: string = '';
}

export class SAbstractSignOrdersPage extends SAbstractOrderOverview {
  onAjax: boolean = true;
  isAssignOpen: boolean = false;
  selectedAssignId: number = -1;
  dataSource: Array<any> = [];
  dataSourceCount: number = 0;
  model: any = {};
  errors: any = {};
  selected: Array<any> = [];
  pagination: TPagination = new TPagination();
  filter: TAbstractSignOrdersPageFilter;
  selectedOrder: any = null;
  showAddRadilogyReport: boolean = false;
  showVisitNotes: boolean = false;
  mouseX: number = 0;
  mouseY: number = 0;
  showTechPopUp: boolean = false;
  techTechData: string = '';
  showPhysicianPopUp: boolean = false;
  physianData: string = '';
  showFacilityPopUp: boolean = false;
  facilityData: string = '';
  showVisitInformation: boolean = false;
  showDiagnosisPopUp: boolean = false;
  diagnosisData: string = '';
  showCptPopUp: boolean = false;
  cptData: string = '';
}

export default abstract class AbstractSignOrdersPage<
  P extends PAbstractSignOrdersPage,
  S extends SAbstractSignOrdersPage
> extends AbstractOrderOverview<P, S> {
  static PERMISSIONS = {
    PAGE: 'PhyReport',
  };

  static getDefaultState(gridId: string): SAbstractSignOrdersPage {
    const state = {
      ...new SAbstractSignOrdersPage(),
      pagination: getPagination(storeGrid.getPageSize(gridId)),
    };
    return state;
  }

  static getStores() {
    return [SignOrdersPageStore];
  }

  getDefaultFilter() {}

  updateTabState(state: SAbstractSignOrdersPage) {
    this.setState(state);
  }

  prepareFilter(): TSignOrderFilter {
    const { filter, pagination } = this.state;

    const { dateFrom, dateTo } = getDateRangeBounds({
      from: filter.fromDate,
      to: filter.toDate,
    });

    return {
      coperate_Id: 0,
      srchLastName: filter.srchLastName || '',
      srchDate: filter.srchDate,
      fromDate: dateFrom,
      toDate: dateTo,
      userType: UserProfileStore.getUserType(),
      facilityId: filter.facilityId !== 0 ? filter.facilityId : null,
      userId: UserProfileStore.getTypedUserId(),
      srchFirstName: filter.srchFirstName || '',
      stateId: filter.stateId || null,
      locId: filter.locId !== 0 ? filter.locId : null,
      posId: filter.posId || 0,
      signed: filter.signed || '',
      dosSearch: '',
      physicianId: filter.physicianId !== 0 ? filter.physicianId : null,
      physicianGroupID:
        filter.physicianGroupID !== 0 ? filter.physicianGroupID : null,
      pcpId: 0,
      companyClient: filter.companyClient || null,
      tab: filter.tab || '',
      clinicianGroupId: null,
      startIndex: pagination.skip,
      limit: pagination.pageSize,
    };
  }

  handleChangeFilter = (name: string, value: any) => {
    const filter = { ...this.state.filter, [name]: value };

    this.setState({ filter });
  };

  // @ts-ignore
  handleChangeDatePeriod = (filter: SAbstractSignOrdersPage['filter']) => {
    this.setState({ filter });
  };

  submitSuccess(model?: any): Promise<any> {
    return Promise.resolve();
  }

  handleSubmit = (
    updatedModel: any,
    hasErrors: boolean,
    updatedErrors: any
  ) => {
    if (hasErrors) {
      const { state } = this;
      const filter = { ...state.filter, ...updatedModel };
      const errors = { ...state.errors, ...updatedErrors };
      this.setState({ filter, errors });
    } else {
      this.submitSuccess(updatedModel);
    }
  };

  componentWillUnmount() {
    storeGrid.saveFilter(this.getGridId(), this.state.filter);
    SignOrdersPageActions.clear();
  }

  getFilterComponent() {
    const { isFilterVisible } = this.props;

    return (
      <Form
        className={isFilterVisible ? '' : ' d-none'}
        onCollectValues={this.handleChangeFilter}
        submit={this.handleSubmit}
        model={this.state.filter}>
        <Text
          name="srchLastName"
          className="col-md-6 col-lg-3"
          label="Last Name"
        />
        <Text
          name="srchFirstName"
          className="col-md-6 col-lg-3"
          label="First Name"
        />
        <PlaceOfServiceDropdown
          name="posId"
          label="Place of Service"
          className="col-md-6 col-lg-3"
        />
        <FacilityRolesDropdown
          name="facilityId"
          className="col-md-6 col-lg-3"
          label="Facility"
        />
        <div />
        <StateDropdown
          name="stateId"
          className="col-md-6 col-lg-3"
          label="State"
        />
        <RegionDropdown
          name="locId"
          className="col-md-6 col-lg-3"
          label="Location"
        />
        <TextCalendar
          name="srchDate"
          label="DOB"
          className="col-sm-6 col-md-4 col-lg-3 col-xl-2"
        />
        <div />
        <DateRangeLegacy
          nameFrom="fromDate"
          nameTo="toDate"
          nameRadio="period"
          radioClassName="col-md-4 col-lg-auto"
          onChange={this.handleChangeDatePeriod}
        />
        <ControlsLayout alignX="auto">
          <Button
            text="Reset"
            variant="warning"
            onClick={this.handleClickReset}
          />
          <Button
            text="Apply"
            data-testid="applyFilter"
            onClick={() => this.onFilterSubmit()}
          />
        </ControlsLayout>
      </Form>
    );
  }

  getStartShownColumns() {
    if (this.isColumnsInit === true) {
      return null;
    }
    this.isColumnsInit = true;
    const shownColumns = this.getShownColumns();
    if (shownColumns === null) {
      return null;
    }
    let examRequestColIdx = -1,
      visitInfoColIdx = -1;
    const out: any = {};
    this.getColumns().forEach((column: any, index: number) => {
      if (shownColumns.indexOf(column.name) === -1) {
        out[column.name] = false;
      }
      if (column.name === 'exam_request') {
        examRequestColIdx = index;
      }
      if (column.name === 'visitInfo') {
        visitInfoColIdx = index;
      }
    });
    if (examRequestColIdx > visitInfoColIdx && visitInfoColIdx >= 0) {
      // reorder columns: exam_request before visitInfo
      const examRequestColArr = this.getColumns().splice(examRequestColIdx, 1);
      this.getColumns().splice(visitInfoColIdx, 0, ...examRequestColArr);
    }
    return out;
  }

  updateData() {
    this.setState({ onAjax: true });

    SignOrdersPageActions.loadOverview(this.prepareFilter()).then(
      this.updateDataCallback
    );
  }

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.setState({
      onAjax: false,
      selected: [],
    });

    if (grid) {
      // @ts-ignore
      grid.clearSelection();
    }
  };

  getPageAppId() {
    return AbstractSignOrdersPage.PERMISSIONS.PAGE;
  }
}
