import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    name: yup.string().required('Please, enter the name!'),
    category: yup.string().required('Please, select a category!'),
  })
);

export default validation;
