import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import FormFacilityContact from './components/Form';

import {
  storeFacilityContact,
  FacilityContactEditType,
} from 'stores/_mobx/systemSetup/facilityContact';
import { FACILITY_CONTACT as PAGE_ID } from 'constant/pagesId/systemSetup';
import { URL_FACILITY_CONTACTS } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const FacilityContactsEditPage = ({
  history,
  match: { params },
}: PropsType) => {
  const { fetching, facilityContact } = storeFacilityContact;

  const handleSubmit = async (payload: FacilityContactEditType) =>
    storeFacilityContact.updateFacilityContact(payload).then((result) => {
      if (result) return result;
      history.push(URL_FACILITY_CONTACTS);
    });

  useEffect(() => {
    storeFacilityContact.getFacilityContact(Number(params.id));

    return storeFacilityContact.clearFacility;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Facility Contact" appId={PAGE_ID.EDIT} />
      <FormFacilityContact
        backUrl={URL_FACILITY_CONTACTS}
        defaultValues={facilityContact}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(FacilityContactsEditPage);
