import {
  DraftMessageDetailsType,
  DraftMessageRecipientType,
} from 'services/messages/draft/DraftsService';
import { MessageDetailedType } from 'stores/messages/inbox/InboxPageStore';
import {
  RecipientBasicType,
  EmailType,
} from 'services/messages/inbox/InboxService';

const recipientsFilter = (recipients: RecipientBasicType[], userId: number) =>
  recipients.filter(
    ({ to_mail, to_name }) => to_name.trim() && Number(to_mail) !== userId
  );

const recipientsToString = (recipients: RecipientBasicType[]): string =>
  recipients.map(({ to_name }) => to_name).join('; ');

export const emailInboxReplyFormatter = (
  data: MessageDetailedType,
  userId: number
): EmailType => {
  const sender: RecipientBasicType | [] =
    Number(data.mailto_id) >= 0
      ? {
          to_name: data.mailtonm,
          mail: data.mailto_id,
          to_mail: data.mailto_id,
        }
      : [];

  const to = recipientsFilter(data.mailToData, userId).concat(sender);

  const cc = recipientsFilter(data.mailCcData, userId);

  return {
    to,
    cc,
    bcc: [] as RecipientBasicType[],
    toS: recipientsToString(to),
    ccS: recipientsToString(cc),
    bccS: '',
    submitType: 'Send',
    subject: `RE: ${data.mail_sub}`,
    body: `<br/>-------------------------\n${data.mail_body?.trim() || ''}`,
    priority: 'N',
    draftId: 0,
  };
};

const getBodyDataForForward = (data: MessageDetailedType) => {
  const space =
    '<div><br></div><div><br></div><div>-------------------------------------- </div>';
  const [mailContent] = data.mailcontent;
  const from = `<b>From:</b> ${mailContent.mailid || ''}`;
  const sent = data.dttime.trim()
    ? `<b>Sent:</b> ${data.dttime} UTC+0`
    : '<b>Sent:</b>';
  const to = `<b>To:</b> ${data.mailtonm}`;
  const subject = `<b>Subject:</b> ${data.mail_sub}`;
  const textBody = data.mail_body?.trim() || '';
  return `${space}${from}<br/>${sent}<br/>${to}<br/>${subject}<div><br></div><div><br></div>${textBody}`;
};

export const emailForwardFormatter = (data: MessageDetailedType): EmailType => {
  return {
    to: [],
    cc: [],
    bcc: [],
    toS: '',
    ccS: '',
    bccS: '',
    submitType: 'Send',
    subject: `FWD: ${data.mail_sub}`,
    body: getBodyDataForForward(data),
    priority: 'N',
    draftId: 0,
  };
};

const draftRecipientsFormatter = (
  dataarray: DraftMessageRecipientType[],
  type: string
) =>
  dataarray.reduce(
    (prev, recipient) =>
      type.toLowerCase() === recipient.receive_type.toLowerCase()
        ? prev.concat({
            mail: recipient.mail,
            to_name: recipient.name,
            to_mail: recipient.mail,
          })
        : prev,
    [] as RecipientBasicType[]
  );

export const emailDraftFormatter = (
  { dataarray, subject, message }: DraftMessageDetailsType,
  draftId: number
) => {
  const to = draftRecipientsFormatter(dataarray, 'to');
  const cc = draftRecipientsFormatter(dataarray, 'cc');
  const bcc = draftRecipientsFormatter(dataarray, 'bcc');

  return {
    to,
    cc,
    bcc,
    subject,
    body: message,
    priority: 'N',
    submitType: 'Send' as string,
    draftId,
    toS: to.map(({ to_name }) => to_name).join('; '),
    ccS: cc.map(({ to_name }) => to_name).join('; '),
    bccS: bcc.map(({ to_name }) => to_name).join('; '),
  };
};

/**
 * @description - Method accept emails list splitted by different separators ( ,;|) and return list of emails splitted by ', '
 * @param emails - string with list of emails
 * @returns {string}
 */
export const emailsUrlPrettier = (emails: string = ''): string =>
  emails
    ?.replace(/[\s;|]/g, ',')
    .split(',')
    .filter(Boolean)
    .join(', ') || '';
