import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import {
  CheckboxGroup,
  CheckboxGroupPropsType,
} from 'components/form/checkbox';
import { storeEventsForSubscribing } from 'stores/_mobx/dropDown/eventsForSubscribing';

interface PropsType extends Omit<CheckboxGroupPropsType, 'options'> {
  userRoleId?: number;
}

const CheckboxesEventsForSubscriptions = ({
  userRoleId,
  ...props
}: PropsType) => {
  const { fetching, options } = storeEventsForSubscribing;

  const isOptionsAvailable = !fetching && Boolean(options.length);

  useEffect(() => {
    storeEventsForSubscribing.getOptionsByUserTypeId(userRoleId);
  }, [userRoleId]);

  return isOptionsAvailable ? (
    <CheckboxGroup {...props} options={options} />
  ) : (
    <div>No available email subscriptions for this user type</div>
  );
};

export default observer(CheckboxesEventsForSubscriptions);
