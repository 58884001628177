import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import Notification from 'components/modal/Notification';
import { Button, IconButton } from 'components/button';
import { Grid } from 'components/grid';
import useDebounce from 'components/hook/useDebounce';
import Filter from './Filter';

import {
  storeIcdCode,
  IcdCodeFilterType,
  IcdCodeType,
} from 'stores/_mobx/workflow/order/icdCode';

interface PropsType {
  smoking: IcdCodeFilterType['smoking'];
  symptomatic: IcdCodeFilterType['symptomatic'];
  selectedCodes: IcdCodeType[];
  callback: (list: IcdCodeType[]) => void;
}

const DialogIcdCode = ({ selectedCodes, callback, ...rest }: PropsType) => {
  const {
    fetching,
    icdCodeList,
    icdCodeTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeIcdCode;

  const [selected, setSelected] = useState<IcdCodeType[]>(selectedCodes);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleSearch = (filter: IcdCodeFilterType) => {
    storeIcdCode.setFilter(filter);
    debouncedMethod();
  };

  const handleAddCode = (code: IcdCodeType, event: React.SyntheticEvent) => {
    event.preventDefault();

    if (!code.icd_billable_status) return;

    const isCurrentCodeAlreadySelected = selected.some(
      ({ refid }) => refid === code.refid
    );

    if (isCurrentCodeAlreadySelected) {
      Notification.warning("Can't add same ICD code");
    } else {
      const codes = selected.concat(code);

      setSelected(codes);
    }
  };

  const handleDeleteCode = (id: number) => {
    const codes = selected.filter(({ refid }) => refid !== id);

    setSelected(codes);
  };

  const columns = useMemo(
    () => [
      {
        name: 'icd_code',
        title: 'ICD Code',
        className: 'width-100',
        render: (value: string, code: IcdCodeType) => (
          <a
            href="/"
            className={!code.icd_billable_status ? 'text-danger' : ''}
            onClick={(event) => handleAddCode(code, event)}>
            {value}
          </a>
        ),
      },
      {
        name: 'short_description',
        title: 'ICD Description',
        render: (value: string, code: IcdCodeType) => (
          <a
            href="/"
            className={!code.icd_billable_status ? 'text-danger' : ''}
            onClick={(event) => handleAddCode(code, event)}>
            {value}
          </a>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  const columnsOfSelectedCodes = useMemo(
    () => [
      { name: 'icd_code', title: 'ICD Code', className: 'width-100' },
      { name: 'short_description', title: 'ICD Description' },
      {
        name: 'refid',
        title: 'Action',
        className: 'width-50 text-center',
        render: (id: number) => (
          <IconButton onClick={() => handleDeleteCode(id)}>
            <i className="bi bi-x-circle text-danger" />
          </IconButton>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  const handleCloseDialog = () => {
    callback(selectedCodes);
  };

  const handleApplySelectedCodes = () => {
    callback(selected);
  };

  useEffect(() => {
    storeIcdCode.getIcdCodesListMain();
  }, [pagination]);

  useEffect(() => storeIcdCode.clearStore, []);

  return (
    <Dialog size="large" handleClose={handleCloseDialog}>
      <DialogHeader title="ICD Code" onClose={handleCloseDialog} />

      <DialogBody className={fetching ? 'on-ajax' : ''}>
        <Filter {...rest} onChange={handleSearch} />

        <Grid
          columns={columns}
          id={storeIcdCode.gridId}
          dataSource={icdCodeList}
          dataSourceCount={icdCodeTotal}
          pagination={pagination}
          onPaginationChange={setPagination}
        />

        <h5>Selected ICD codes</h5>
        <Grid
          columns={columnsOfSelectedCodes}
          dataSource={selected}
          disablePagination
          noControl
        />
      </DialogBody>
      <DialogFooter>
        <Button text="Apply" onClick={handleApplySelectedCodes} />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogIcdCode);
