import { useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import RadioOptions from 'components/project/common/RadioOptions';
import { Grid } from 'components/grid';
import { IconButton } from 'components/button';
import { PureSelect } from 'components/form/select';
import { PureRadio } from 'components/form/radio';
import FileUpload from 'components/form/input/FileUpload';
import TabComponent from './components/TabComponent';

import {
  storeBillingAssignment,
  pcBillingOptions,
  BillingAssignmentPcType,
  FilterPCType,
} from 'stores/_mobx/billingCodding/billingAssignment';
import { BASE_URL_FILE_DIR } from 'constant/config';

const columnVisibleByDefault = [
  'studyId',
  'dos',
  'billType',
  'responsible',
  'patientLastName',
  'patientFirstName',
  'tcBillProcessLabel',
  'facilityName',
  'modality',
  'radiologist',
  'eligibilityStatus',
  'coding_status',
  'billing_notes',
  'review',
  'encounterLog',
  'division',
];

interface PropsType {
  isFilterVisible: boolean;
}

const PcAssignmentTab = ({ isFilterVisible }: PropsType) => {
  const gridRef = useRef<Grid>();

  const {
    pcBillingAssignments,
    pcBillingAssignmentsTotal,
    permission,
    filterPC,
    pagePC,
    storeDivisionSettingsStatus: { isDivisionEnabled },
  } = storeBillingAssignment;

  const fetchData = () => {
    storeBillingAssignment.getPCBillingAssignmentsMain();
  };

  const handleUpdateProfession =
    (studyId: number) => (profession: 'Y' | 'N') => {
      storeBillingAssignment
        .updateProfession({
          profession,
          id: studyId,
        })
        .then(fetchData);
    };

  const handleChangeResponsible =
    (id: string) => (responsible: BillingAssignmentPcType['responsible']) => {
      gridRef.current.selectRow(id, Boolean(responsible));

      storeBillingAssignment.updateBillProcess({
        id,
        responsible,
        isTcBillProcess: false,
      });
    };

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        name: 'claimId',
        title: 'Claim #',
        render: (value: undefined, { studyId }: BillingAssignmentPcType) =>
          studyId,
      },
      { name: 'dos', title: 'DOS', className: 'text-nowrap', dataType: 'date' },
      {
        name: 'billType',
        title: 'Bill Type',
        className: 'width-150',
        group: 'professional',
        groupName: 'Professional Guarantor',
      },
      {
        name: 'responsible',
        title: 'Professional Bill Process',
        group: 'professional',
        groupName: 'Professional Guarantor',
        noOverflow: true,
        render: (
          responsible: BillingAssignmentPcType['responsible'],
          { claimUniqId }: BillingAssignmentPcType
        ) => (
          <PureSelect
            options={pcBillingOptions}
            name={`responsibility_${claimUniqId}`}
            className="m-width-150"
            value={responsible}
            onChange={handleChangeResponsible(claimUniqId)}
          />
        ),
      },
      {
        name: 'tcBillProcess',
        title: 'Technical Bill Process',
      },
      { name: 'patientLastName', title: 'Patient Last Name' },
      { name: 'patientFirstName', title: 'Patient First Name' },
      permission.viewAllColumns
        ? {
            name: 'birthDate',
            title: 'Date Of Birth',
            className: 'text-nowrap',
          }
        : null,
      { name: 'facilityName', title: 'Facility' },
      isDivisionEnabled ? { name: 'division', title: 'Division' } : null,
      permission.viewAllColumns
        ? { name: 'providerName', title: 'Provider Name' }
        : null,
      permission.viewAllColumns ? { name: 'pos', title: 'POS' } : null,
      { name: 'modality', title: 'Modality' },
      permission.viewAllColumns ? { name: 'studyId', title: 'Study ID' } : null,
      permission.viewAllColumns
        ? { name: 'patientId', title: 'Patient ID' }
        : null,
      permission.viewAllColumns
        ? { name: 'clientMRN', title: 'Other MRN' }
        : null,
      permission.viewAllColumns ? { name: 'state', title: 'State' } : null,
      permission.viewAllColumns
        ? { name: 'location', title: 'Location' }
        : null,
      permission.viewAllColumns
        ? {
            name: 'visitInfo',
            title: 'Visit Info',
            render: (_: undefined, { studyId }: BillingAssignmentPcType) => (
              <IconButton
                className="text-primary"
                onClick={() => storeBillingAssignment.getVisitDetails(studyId)}>
                <i className="bi bi-journal-medical" />
              </IconButton>
            ),
          }
        : null,
      permission.viewAllColumns
        ? { name: 'eligibilityStatus', title: 'Eligibility Status' }
        : null,
      { name: 'coding_status', title: 'Coding Status' },
      permission.viewAllColumns
        ? { name: 'radiologist', title: 'Reading Rad(s)' }
        : null,
      {
        name: 'review',
        title: 'Review',
        render: (value: undefined, data: BillingAssignmentPcType) => (
          <IconButton
            onClick={() => storeBillingAssignment.setParamsForReview(data)}>
            <i className="icon icon-view-item" />
          </IconButton>
        ),
      },
      { name: 'radiologyGroupName', title: 'Radiology Group' },
      permission.viewAllColumns
        ? {
            name: 'profession',
            title: 'Is Professional',
            group: 'professional',
            groupName: 'Professional Guarantor',
            render: (value: any, { studyId }: BillingAssignmentPcType) => (
              <PureRadio
                className="part-inline width-100"
                name={`profession_${studyId}`}
                options={RadioOptions.yesNo}
                value={value}
                onChange={handleUpdateProfession(studyId)}
              />
            ),
          }
        : null,
      permission.viewAllColumns
        ? {
            name: 'prof_guarantor',
            title: 'Responsibility',
            group: 'professional',
            groupName: 'Professional Guarantor',
          }
        : null,
      permission.isClient || !permission.viewAllColumns
        ? null
        : {
            name: 'encounterLog',
            title: 'Encounter Log',
            render: (
              value: undefined,
              { hasLog, studyId }: BillingAssignmentPcType
            ) => (
              <IconButton
                className="text-primary"
                onClick={() => {
                  storeBillingAssignment.setIdForNotes(studyId);
                }}>
                <i
                  className={`bi ${
                    hasLog ? 'bi-envelope-check-fill' : 'bi-envelope'
                  }`}
                />
              </IconButton>
            ),
          },
      permission.viewAllColumns
        ? {
            name: 'auditLog',
            title: 'Audit Log',
            render: (value: undefined, data: BillingAssignmentPcType) => (
              <IconButton
                onClick={() =>
                  storeBillingAssignment.setParamsForAuditLog(data)
                }>
                <i className="icon icon-auditlog" />
              </IconButton>
            ),
          }
        : null,
      permission.updatingFaceSheet
        ? {
            name: 'file_upload_status',
            title: 'Status',
          }
        : null,
      permission.updatingFaceSheet
        ? {
            name: 'facesheet',
            title: 'Face Sheet',
            render: (value: undefined, data: BillingAssignmentPcType) => (
              <FileUpload
                buttonLabel="Upload"
                name="fu"
                noLabel
                onSetValue={(n, fileName) =>
                  storeBillingAssignment.updateFaceSheet({
                    id: data.studyId,
                    fileName,
                  })
                }
                value=""
                // value={this.state.fileName}
                noWarning
                hideFileName
                path={`${BASE_URL_FILE_DIR}doc_img/externaldoc`}
              />
            ),
          }
        : null,
    ];

    return defaultColumns.filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    permission.isClient,
    permission.updatingFaceSheet,
    permission.viewAllColumns,
    isDivisionEnabled,
  ]);

  const columnsVisibleByDefault = useMemo(() => {
    const visibleColumns = permission.updatingFaceSheet
      ? columnVisibleByDefault.concat(['file_upload_status', 'facesheet'])
      : columnVisibleByDefault;

    const finalColumns: Map<string, boolean> = new Map<string, boolean>();

    columns.forEach((c) => {
      finalColumns.set(c.name, visibleColumns.includes(c.name));
    });

    return finalColumns;
  }, [permission.updatingFaceSheet, columns]);

  const handleApplyFilter = (filter: FilterPCType) => {
    storeBillingAssignment.setPCFilter(filter);

    pagePC.setPaginationToStart();
  };

  useEffect(() => {
    fetchData();
  }, [pagePC.pagination]);

  return (
    <TabComponent
      mode="PC"
      ref={gridRef}
      isFilterVisible={isFilterVisible}
      filter={filterPC}
      columns={columns}
      columnsVisibleByDefault={columnsVisibleByDefault}
      optionsBillProcess={pcBillingOptions}
      billingAssignments={pcBillingAssignments}
      billingAssignmentsTotal={pcBillingAssignmentsTotal}
      page={pagePC}
      fetchData={fetchData}
      //@ts-ignore
      onApplyFilter={handleApplyFilter}
    />
  );
};

export default observer(PcAssignmentTab);
