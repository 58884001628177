import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import { IconButton } from 'components/button';
import Cell, { OptionType, ColumnType } from './components/Cell';
import MapperTitle from './components/Title';
import { Input } from '../textField';

const defaultValues: FilterProp = {
  facilityName: '',
  zipCode: '',
  npi: '',
};

interface FilterProp {
  facilityName: string;
  zipCode: string;
  npi: string;
}

export interface PropsType {
  titleLeft: string;
  titleRight: string;
  columnLeft: ColumnType[];
  columnRight: ColumnType[];
  className?: string;
  options: OptionType[];
  value: OptionType[];
  isDirty?: boolean;
  onChange?: (data: OptionType[]) => void;
  onHover?: (data: OptionType) => void;
}

interface FilterListPayloadType {
  list: OptionType[];
  filter: FilterProp;
}

const filterList = ({
  list,
  filter: { facilityName, npi, zipCode },
}: FilterListPayloadType) => {
  if (facilityName || npi || zipCode) {
    return list.filter(({ facility_nm, zipcode, facility_npi }) => {
      const isNameMatches = facilityName
        ? facility_nm.toLowerCase().startsWith(facilityName.toLowerCase())
        : true;

      const isZipMatches = zipCode ? zipcode.startsWith(zipCode) : true;

      const isNpiMatches: boolean = npi ? facility_npi.startsWith(npi) : true;

      return isNameMatches && isZipMatches && isNpiMatches;
    });
  }

  return list;
};

const FacilityMapper = React.forwardRef<HTMLDivElement, PropsType>(
  (
    {
      isDirty = false,
      options,
      value: resultValue,
      columnLeft,
      columnRight,
      titleLeft,
      titleRight,
      className,
      onChange,
      onHover,
    }: PropsType,
    ref
  ) => {
    const prevDirtyStatus = useRef<boolean>(isDirty);

    const { control, watch } = useForm<FilterProp>({ defaultValues });

    const [selectedLeft, setLeftSelected] = useState<OptionType[]>([]);

    const [selectedRight, setRightSelected] = useState<OptionType[]>([]);

    const [newValues, setNewValues] = useState<OptionType[]>([]);

    const [newOptions, setNewOptions] = useState<OptionType[]>([]);

    const filterOptions = watch();

    const resultOptions = useMemo(() => {
      const optionsWithoutSelected = newValues.length
        ? options.filter(
            (option) => !newValues.some(({ value }) => option.value === value)
          )
        : options;

      const combinedOptions = newOptions.length
        ? optionsWithoutSelected.concat(newOptions)
        : optionsWithoutSelected;

      return filterList({
        list: combinedOptions,
        filter: filterOptions,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      options,
      newValues,
      newOptions,
      filterOptions.facilityName,
      filterOptions.zipCode,
      filterOptions.npi,
    ]);

    const handleSelectLeft = (option: OptionType) => {
      const isIncluded = selectedLeft.some(
        ({ value }) => value === option.value
      );

      const entry = isIncluded
        ? selectedLeft.filter(({ value }) => value !== option.value)
        : selectedLeft.concat(option);

      setLeftSelected(entry);
    };

    const handleSelectRight = (option: OptionType) => {
      const isIncluded = selectedRight.some(
        ({ value }) => value === option.value
      );

      const codes = isIncluded
        ? selectedRight.filter(({ value }) => value !== option.value)
        : selectedRight.concat(option);

      setRightSelected(codes);
    };

    const handleAddValue = () => {
      const restNewOptions = newOptions.filter(
        (option) => !selectedLeft.some(({ value }) => option.value === value)
      );

      const restNewValues = selectedLeft.filter(({ value }) =>
        options.some((option) => value === option.value)
      );

      if (onChange) onChange(resultValue.concat(selectedLeft));
      setNewValues(newValues.concat(restNewValues));
      setNewOptions(restNewOptions);
      setLeftSelected([]);
    };

    const handleRemoveValue = () => {
      const restValues = resultValue.filter(
        (option) => !selectedRight.some(({ value }) => value === option.value)
      );

      const restOptions = selectedRight.filter(
        ({ value }) => !options.some((option) => value === option.value)
      );

      const restNewValues = newValues.filter(
        ({ value }) => !selectedRight.some((option) => option.value === value)
      );

      if (onChange) onChange(restValues);
      setNewOptions(newOptions.concat(restOptions));
      setNewValues(restNewValues);
      setRightSelected([]);
    };

    useEffect(() => {
      setLeftSelected([]);
    }, [filterOptions.facilityName, filterOptions.zipCode, filterOptions.npi]);

    useEffect(() => {
      setRightSelected([]);
      setLeftSelected([]);
    }, [options]);

    useEffect(() => {
      if (!isDirty && prevDirtyStatus.current) {
        setRightSelected([]);
        setLeftSelected([]);
        setNewValues([]);
        setNewOptions([]);
      }

      prevDirtyStatus.current = isDirty;
    }, [isDirty]);

    return (
      <div className={clsx('mapper-container', className)} ref={ref}>
        <div className="mapper-side">
          <div className="row">
            <Input
              className="col-sm-4"
              name="facilityName"
              placeholder="Facility"
              control={control}
            />
            <Input
              className="col-sm-4"
              name="zipCode"
              placeholder="Zip code"
              control={control}
            />
            <Input
              className="col-sm-4"
              name="npi"
              placeholder="NPI"
              type="number"
              control={control}
            />
          </div>
          <div className="mapper-legend">{`${titleLeft}: ${resultOptions.length}`}</div>
          <MapperTitle
            scrollable={resultOptions.length > 10}
            columns={columnLeft}
          />
          <ul className="mapper-list">
            {resultOptions.length ? (
              <List
                width="auto"
                height={310}
                itemSize={31}
                itemCount={resultOptions.length}>
                {({ index, style }) => (
                  <Cell
                    className={clsx({
                      selected: selectedLeft.some(
                        ({ value }) => value === resultOptions[index].value
                      ),
                      moved: newOptions.some(
                        ({ value }) => value === resultOptions[index].value
                      ),
                      "pe-4": resultOptions.length > 10
                    })}
                    style={style}
                    data={resultOptions[index]}
                    columns={columnLeft}
                    onClick={handleSelectLeft}
                    onHover={onHover}
                  />
                )}
              </List>
            ) : (
              <li className="mapper-list-empty">There is no any option.</li>
            )}
          </ul>
        </div>

        <div className="mapper-control">
          <IconButton disabled={!selectedLeft.length} onClick={handleAddValue}>
            <i className="bi bi-caret-right-fill" />
          </IconButton>
          <IconButton
            disabled={!selectedRight.length}
            onClick={handleRemoveValue}>
            <i className="bi bi-caret-left-fill" />
          </IconButton>
        </div>

        <div className="mapper-side">
          <div className="row">
            <div className="mapper-empty-filter" />
          </div>
          <div className="mapper-legend">{`${titleRight}: ${resultValue.length}`}</div>
          <MapperTitle
            scrollable={resultValue.length > 10}
            columns={columnRight}
          />
          <ul className="mapper-list" onMouseLeave={() => onHover(null)}>
            {resultValue.length ? (
              <List
                width="auto"
                height={310}
                itemSize={31}
                itemCount={resultValue.length}>
                {({ index, style }) => (
                  <Cell
                    className={clsx({
                      selected: selectedRight.some(
                        ({ value }) => value === resultValue[index].value
                      ),
                      moved: newValues.some(
                        ({ value }) => value === resultValue[index].value
                      ),
                    })}
                    style={style}
                    data={resultValue[index]}
                    columns={columnRight}
                    onClick={handleSelectRight}
                    onHover={onHover}
                  />
                )}
              </List>
            ) : (
              <li className="mapper-list-empty">
                Please select the entry in the left Grid.
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
);

export default FacilityMapper;
