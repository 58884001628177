import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import FormPlaceOfService, { FormModel } from './components/Form';

import { storePlaceOfService } from 'stores/_mobx/systemSetup/masterSetting/placeOfService';
import { URL_PLACE_OF_SERVICE } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const PlaceOfServiceEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, place } = storePlaceOfService;

  const handleSubmit = (payload: FormModel) =>
    storePlaceOfService
      .updatePlace(payload as Required<FormModel>)
      .then((isSucceed) => {
        if (isSucceed) {
          history.push(URL_PLACE_OF_SERVICE);
        }
      });

  useEffect(() => {
    storePlaceOfService.getPlace(params.id);
    return storePlaceOfService.clearPlace;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Place of Service" />
      <FormPlaceOfService
        backUrl={URL_PLACE_OF_SERVICE}
        defaultValues={place}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(PlaceOfServiceEditPage);
