import appDispatcher from 'dispatcher/AppDispatcher';

import ProcedureCodeCountPageService from 'services/reports/report-creator/procedure-code-count/ProcedureCodeCountPageService';
import { Actions } from 'stores/reports/report-creator/procedure-code-count/ProcedureCodeCountPageStore';

export default class ProcedureCodeCountPageActions {
  static loadProcedureCodeCountList(conditionList, joint) {
    return ProcedureCodeCountPageService.loadProcedureCodeCountList(
      conditionList,
      joint
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_PROCEDURE_CODE_COUNT_LIST,
        data: response,
      });
    });
  }

  static createPdf(procedureCodeCountList, filter) {
    return ProcedureCodeCountPageService.createPdf(
      procedureCodeCountList,
      filter
    );
  }
}
