import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import RadioOptions from 'components/project/common/RadioOptions';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';

import {
  storePlaceOfService,
  FilterType,
} from 'stores/_mobx/systemSetup/masterSetting/placeOfService';

interface PropsType {
  onChange: (filter: FilterType) => void;
}

const Filter = ({ onChange }: PropsType) => {
  const { control, watch } = useForm<FilterType>({
    defaultValues: storePlaceOfService.filter,
  });

  useEffect(() => {
    const subscription = watch((formValue) => {
      onChange(formValue as FilterType);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row">
      <Input
        name="placeName"
        placeholder="Place of Service"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <Radio
        name="state"
        className="col-md-6 col-lg-4 inline"
        options={RadioOptions.active}
        control={control}
      />
    </form>
  );
};

export default Filter;
