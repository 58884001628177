import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { InputCalendar } from 'components/form/inputCalendar';
import { Button } from 'components/button';
import { DropDownApplicationUserType } from 'components/project/dropdown/ApplicationUsersDropdown';
import DropdownUsers from 'components/project/dropdown/Users';

import {
  storeUserExpenseReport,
  defaultValueFilter,
  FilterType,
} from 'stores/_mobx/report/userExpense';

interface PropsType {
  fetching: boolean;
  isVisible: boolean;
  onSubmit: (data: FilterType) => void;
}

const Filter = ({ fetching, isVisible, onSubmit }: PropsType) => {
  const { control, watch, setValue, reset } = useForm<FilterType>({
    defaultValues: storeUserExpenseReport.filter,
  });

  const userTypeId = watch('userTypeId');

  const handleClickReset = () => {
    reset(defaultValueFilter);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      let filterData = formValue;
      if (name === 'userTypeId') {
        setValue('userId', 0);
        filterData = {
          ...formValue,
          userId: 0,
        };
      }
      onSubmit(filterData as Required<FilterType>);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className={`row mb-4${isVisible ? '' : ' d-none'}`}>
      <InputCalendar
        name="dateFrom"
        label="From"
        className="col-md-6 col-lg-3 col-xl-2"
        control={control}
      />
      <InputCalendar
        name="dateTo"
        label="To"
        className="col-md-6 col-lg-3 col-xl-2"
        control={control}
      />
      <DropDownApplicationUserType
        name="userTypeId"
        className="col-md-6 col-lg-3 col-xl-3"
        label="User Type"
        control={control}
      />
      <DropdownUsers
        name="userId"
        label="Select User"
        className="col-md-6 col-lg-3 col-xl-3"
        userTypeId={userTypeId}
        disabled={!userTypeId}
        control={control}
      />

      <div className="col-sm-12 col-xl-2 align-self-end mb-4 d-flex justify-content-end">
        <Button
          text="Reset"
          variant="danger"
          disabled={fetching}
          onClick={handleClickReset}
        />
      </div>
    </form>
  );
};

export default Filter;
