import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import { Grid } from 'components/grid';
import Filter from './components/Filter';

import {
  storeCheckOutHistory,
  FilterType,
} from 'stores/_mobx/workflow/checkOutIn/checkOutHistory';
import { CHECK_OUT_HISTORY as PAGE_ID } from 'constant/pagesId/workflow';

const columns = [
  {
    name: 'technologist',
    title: 'Technologist',
  },
  { name: 'vehicle_name', title: 'Vehicle' },
  { name: 'device_name', title: 'Unit' },
  { name: 'ekg_name', title: 'EKG' },
  { name: 'phone_desc', title: 'Phone' },
  { name: 'check_out_dt', title: 'Check Out Date' },
  { name: 'check_in_dt', title: 'Check In Date' },
];

const CheckOutHistoryPage = () => {
  const {
    fetching,
    logsTotal,
    logs,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeCheckOutHistory;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeCheckOutHistory.setFilter(filter);
    setPaginationToStart();
  };

  useEffect(() => {
    storeCheckOutHistory.getLogsLisMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Check Out/In History">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        isVisible={isFilterVisible}
        fetching={fetching}
        onChange={handleChangeFilter}
      />

      <Grid
        id="checkOutHistoryGrid"
        columns={columns}
        onAjax={fetching}
        dataSource={logs}
        dataSourceCount={logsTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
    </>
  );
};

export default observer(CheckOutHistoryPage);
