import React from 'react';

interface Props {
  title: string;
  onClose?: (e: React.MouseEvent) => void;
}

const DialogHeader = ({ title, onClose }: Props) => (
  <div className="modal-header p-4">
    <h3 className="modal-title text-danger">{title}</h3>
    {onClose && (
      <button type="button" className="btn-close" onClick={onClose}></button>
    )}
  </div>
);

export default DialogHeader;
