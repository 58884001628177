import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

interface CptLogType {
  created_dt: string;
  cpt_code: string;
  cpt_description: string;
  status: 'Active' | 'History' | '';
}

class CptCode {
  fetchingCptLog: boolean = false;
  cptLogList: CptLogType[] = [];
  cptLogTotal: number = 0;

  constructor() {
    makeObservable(this, {
      fetchingCptLog: observable,
      cptLogList: observable,
      cptLogTotal: observable,

      setFetchingCptLog: action,
      setCptLogCount: action,
      setCptLogList: action,
      clearLogs: action.bound,
    });
  }

  setFetchingCptLog(fetching: boolean) {
    this.fetchingCptLog = fetching;
  }

  setCptLogCount(count: number) {
    this.cptLogTotal = count;
  }

  setCptLogList(logs: CptLogType[]) {
    this.cptLogList = logs;
  }

  clearLogs() {
    this.cptLogList = [];
    this.cptLogTotal = 0;
  }

  async getCptLogTotal(orderId: number) {
    try {
      const count = await apiRequest<string>({
        url: 'order.Order.InitialCPTLogCount',
        data: [orderId],
      });

      this.setCptLogCount(Number(count) || 0);
    } catch (e: any) {
      this.setCptLogCount(0);
    }
  }

  async getCptLogList({
    pagination,
    orderId,
  }: {
    pagination: Pagination['pagination'];
    orderId: number;
  }) {
    const data = [orderId, pagination.skip, pagination.pageSize];

    try {
      const logs = await apiRequest<CptLogType[]>({
        url: 'order.Order.InitialCPTLog',
        data,
      });

      this.setCptLogList(logs);
    } catch (e: any) {
      this.setCptLogList([]);
    }
  }

  getCptLogListMain(payload: {
    orderId: number;
    pagination: Pagination['pagination'];
  }) {
    this.setFetchingCptLog(true);

    const promiseList = this.getCptLogList(payload);

    const promiseTotal = this.getCptLogTotal(payload.orderId);

    Promise.allSettled([promiseList, promiseTotal]).finally(() => {
      this.setFetchingCptLog(false);
    });
  }
}

export const storeCptCode = new CptCode();
