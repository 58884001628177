import { makeObservable, observable, action, onBecomeObserved } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import UserProfileStore from 'stores/UserProfileStore';
import { dateToLocalTimezone } from 'utils/DateUtils';
import messagePrettier from 'utils/messagePrettier';

export type EmailPriorityType = 'N' | 'U' | 'M';

interface InboxResponseType {
  col: string;
  mail_date: string;
  mail_subject: string;
  mail_time: string;
  mail_to: string;
  messageid: string;
  priority: EmailPriorityType;
  refid: string;
  view_status: 'N' | 'A';
}

export interface InboxType {
  date: string;
  subject: string;
  time: string;
  sender: string;
  priority: EmailPriorityType;
  id: number;
  isUnread: boolean;
}

interface EmailPreviewType {
  body: string;
  date: string;
  from: string;
  subject: string;
  to: string;
}

interface EmailContentType {
  mailheading: string;
  mailid: string;
  refid: string;
}

export interface EmailPreviewResponseType {
  mailcontent: EmailContentType[];
  dttime: string;
  mail_sub: string;
  mail_body: string;
  mailto_id: string;
  mailtonm: string;
}

class Inbox {
  private numbersOfRequests = 0;
  private userId = 0;

  fetching: boolean = false;
  inboxList: InboxType[] = [];
  inboxTotal: number = 0;
  unreadTotal: number = 0;
  filter: string = '';
  emailPreview: EmailPreviewType | null = null;

  page: Pagination = new Pagination({ id: 'inbox_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      inboxList: observable,
      inboxTotal: observable,
      unreadTotal: observable,
      filter: observable,
      emailPreview: observable,

      setFetching: action,
      setInboxList: action,
      setInboxTotal: action,
      setUnreadTotal: action,
      setFilter: action,
      setEmailPreview: action,
      clearEmailPreview: action.bound,
    });

    onBecomeObserved(this, 'inboxList', this.getUserId);
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setInboxList(list: InboxType[]) {
    this.inboxList = list;
  }

  setInboxTotal(count: number) {
    this.inboxTotal = count;
  }

  setUnreadTotal(count: number) {
    this.unreadTotal = count;
  }

  setFilter(subject: string) {
    this.filter = subject;
  }

  setEmailPreview(email: EmailPreviewType) {
    this.emailPreview = email;
  }

  clearEmailPreview() {
    this.emailPreview = null;
  }

  getUserId = () => {
    this.userId = UserProfileStore.getUserId();
  };

  async getUnreadEmailCount() {
    try {
      const count = await apiRequest<string>({
        url: 'email.Email.inboxUnreadCount',
      });

      this.setUnreadTotal(Number(count) || 0);
    } catch (e: any) {
      this.setUnreadTotal(0);
    }
  }

  async getInboxCount(props: { filter: string; userId: number }) {
    const data = {
      UserId: props.userId,
      filter: props.filter,
    };

    try {
      const count = await apiRequest<string>({
        url: 'email.Email.InboxTotalCount',
        data,
      });

      this.setInboxTotal(Number(count) || 0);
    } catch (e: any) {
      this.setInboxTotal(0);
    }
  }

  async getInboxList(props: { filter: string; userId: number }) {
    const { pagination } = this.page;

    try {
      const data = {
        UserId: props.userId,
        search: props.filter,
        StartIndex: pagination.skip,
        Limit: pagination.pageSize,
      };

      const { Data: response } = await apiRequest<{
        Data: InboxResponseType[];
      }>({
        url: 'email.Email.EmailInboxList',
        data,
      });

      const list = response.map((inbox) => {
        const [date, time] = dateToLocalTimezone({
          date: `${inbox.mail_date} ${inbox.mail_time}`,
        }).split(' ');

        return {
          date,
          time,
          subject: inbox.mail_subject,
          sender: inbox.mail_to,
          id: Number(inbox.refid),
          priority: inbox.priority,
          isUnread: inbox.view_status === 'N',
        };
      });

      this.setInboxList(list);
    } catch (e: any) {
      this.setInboxList([]);
    }
  }

  getInboxListMain = async (filter = this.filter) => {
    this.numbersOfRequests += 1;

    this.setFetching(true);

    const promiseCount = this.getInboxCount({ filter, userId: this.userId });

    const promiseReminderList = this.getInboxList({
      filter,
      userId: this.userId,
    });

    Promise.allSettled([promiseCount, promiseReminderList]).finally(() => {
      this.numbersOfRequests -= 1;

      const fetching = Boolean(this.numbersOfRequests);

      this.setFetching(fetching);
    });
  };

  async moveToTrash(ids: number[]) {
    this.setFetching(true);
    try {
      const payload = ids.map((id) => ({
        flag: 'T',
        stat: 'Inbox',
        mode: '',
        idvalue: id,
      }));

      const response = await apiRequest<'A' | 'D' | 'R' | 'T' | 'SE' | null>({
        url: 'email.Email.MoveEmail',
        data: [payload],
      });

      if (response === 'T') {
        Notification.success(
          `${ids.length} email${
            ids.length > 1 ? 's' : ''
          } deleted successfully!`
        );
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("You can't delete this email!");
      this.setFetching(false);
      return false;
    }
  }

  async getEmailPreview(data: [number, null | 'I', 'T' | 'I']) {
    this.setFetching(true);
    try {
      const response = await apiRequest<[EmailPreviewResponseType]>({
        url: 'email.EmailMessageDisplay.EmailBodyMessageDisplay',
        data,
      });
      if (Array.isArray(response)) {
        const email = messagePrettier(response[0]);

        this.setEmailPreview(email);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e: any) {
      this.setEmailPreview(null);
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeInbox = new Inbox();
