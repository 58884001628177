import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';
import Tabs from 'components/tabs';
import InformationTab from './InformationTab';
import MappingTab from './MappingTab';
import resolver from './validation';

import {
  defaultValueRadiologyGroup,
  RadiologyGroupDetailsType,
} from 'stores/_mobx/systemSetup/masterSetting/radiologyGroup';
import { URL_RADIOLOGY_GROUP } from 'constant/path/systemSetup';

const tabsList = [
  { label: 'Information', url: '?tab=info' },
  { label: 'Radiology Mapping', url: '?tab=mapping' },
];

interface PropsType {
  details?: RadiologyGroupDetailsType;
  isNewGroup: boolean;
  fetching: boolean;
  onSubmit: (payload: RadiologyGroupDetailsType) => Promise<any>;
}

const Form = ({
  fetching,
  isNewGroup,
  details = defaultValueRadiologyGroup,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, errors },
    reset,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
  } = useForm<RadiologyGroupDetailsType>({
    defaultValues: details,
    resolver,
  });

  const history = useHistory();

  const redirectToFirstTab = () => {
    const isNewGroup = !details.id;

    const path = isNewGroup ? '/add' : `/edit/${details.id}`;

    history.push(`${URL_RADIOLOGY_GROUP}${path}${tabsList[0].url}`);
  };

  const handleClickReset = () => {
    reset(details);
  };

  const handleClickSubmit = handleSubmit((data: RadiologyGroupDetailsType) =>
    onSubmit(data).then((error) => {
      if (error) {
        redirectToFirstTab();

        setError('radiologyGroupName', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    const isInvalid = !!Object.keys(errors).length;

    if (isInvalid) {
      redirectToFirstTab();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'isIntegrationEnabled' && !formValue.isIntegrationEnabled) {
        setValue('radiologyGroupIntegrationIds', []);
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    reset(details);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  return (
    <form
      className={`row${fetching ? ' on-ajax' : ''}`}
      onSubmit={handleClickSubmit}>
      <Tabs className="mb-4" tabsList={tabsList}>
        <InformationTab
          isNewGroup={isNewGroup}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
        <MappingTab
          isDirty={isDirty}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
      </Tabs>
      <ControlsLayout alignX="right">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button type="submit" text="Submit" disabled={fetching || !isDirty} />
      </ControlsLayout>
    </form>
  );
};

export default Form;
