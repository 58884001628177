import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import useDebounce from 'components/hook/useDebounce';
import Filter from './components/Filter';

import {
  storeDesignation,
  FilterModel,
} from 'stores/_mobx/systemSetup/masterSetting/designation';
import { MS_DESIGNATION as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_DESIGNATION,
  URL_DESIGNATION_ADD,
} from 'constant/path/systemSetup';

const columns = [
  { name: 'usertype_name', title: 'User Type' },
  { name: 'usertype_desc', title: 'Description' },
  { name: 'logout_time', title: 'Logout interval' },
  {
    name: 'refid',
    title: 'Action',
    className: 'width-75',
    render: (id: number) => (
      <div className="control">
        <Link className="bi bi-pencil" to={`${URL_DESIGNATION}/edit/${id}`} />
      </div>
    ),
  },
];

const DesignationListPage = () => {
  const {
    fetching,
    designationList,
    designationTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeDesignation;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleFilterChange = (filter: FilterModel) => {
    storeDesignation.setFilter(filter);

    debouncedMethod();
  };

  useEffect(() => {
    storeDesignation.getDesignationListMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="User Types">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <Filter fetching={fetching} onFilterChange={handleFilterChange} />

      <Grid
        id="designationGrid"
        columns={columns}
        onAjax={fetching}
        dataSource={designationList}
        dataSourceCount={designationTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_DESIGNATION_ADD} />
        }
      />
    </>
  );
};

export default observer(DesignationListPage);
