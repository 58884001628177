import { makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionsResponseType {
  data: string;
  label: string;
  short_name: string;
}

export interface OptionType {
  value: number;
  label: string;
}

export class DropdownCoordinator {
  fetching: boolean = false;
  fetchingBillingUsers: boolean = false;
  options: OptionType[] = [];
  optionsBillingUsers: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingBillingUsers: observable,
      options: observable,
      optionsBillingUsers: observable,
    });
  }

  async getOptions() {
    if (this.fetching) return Promise.resolve();
    try {
      runInAction(() => {
        this.fetching = true;
      });
      const list = await apiRequest<OptionsResponseType[]>({
        url: 'generalmaster.RadiologyGroup.LoadRadiologistCoordinator',
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));
      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }

  async getOptionsBillingUsers() {
    if (this.fetchingBillingUsers) return Promise.resolve();
    try {
      runInAction(() => {
        this.fetchingBillingUsers = true;
      });
      const options = await apiRequest<OptionType[]>({
        url: 'generalmaster.RadiologyGroup.getRadiologyBillingUsers',
      });

      runInAction(() => {
        this.fetchingBillingUsers = false;
        this.optionsBillingUsers = options;
      });
    } catch {
      runInAction(() => {
        this.fetchingBillingUsers = false;
        this.optionsBillingUsers = [];
      });
    }
  }
}

export const storeDropdownCoordinator = new DropdownCoordinator();
