import createLinkElement from './createLinkElement';

const mimeType = 'data:text/csv;charset=utf-8';

export const downloadCsv = (csvContent: string, fileName: string) => {
  const encodedUri = URL.createObjectURL(
    new Blob([csvContent], {
      type: mimeType,
    })
  );

  const link = createLinkElement(encodedUri);

  link.setAttribute('download', fileName);
  document.body.appendChild(link); // Required for FF

  link.click();
  link.remove();
};

export const downloadFile = (path: string, justOpen?: boolean) => {
  const link = createLinkElement(path);

  if (!justOpen) {
    link.setAttribute('download', path);
  }

  document.body.appendChild(link); // Required for FF

  link.click();
  link.remove();
};
