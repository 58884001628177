import { ReduceStore } from 'flux/utils';
import appDispatcher from 'dispatcher/AppDispatcher';
import { dateToLocalTimezone } from 'utils/DateUtils';

let buildActionName = function (name) {
  return 'reports/report-creator/studies-by-facility/' + name;
};

export let Actions = {
  LOAD_STUDIES_BY_FACILITY_LIST: buildActionName(
    'load-studies-by-facility-list'
  ),
};

class StudiesByFacilityPageStore extends ReduceStore {
  getEmptyDiagram() {
    return [['Data', 'Value']];
  }

  getInitialState() {
    return {
      studiesByFacilityList: [],
      studiesByFacilityDiagram: this.getEmptyDiagram(),
    };
  }

  reduce(state, action) {
    switch (action.type) {
      case Actions.LOAD_STUDIES_BY_FACILITY_LIST: {
        const logs = (action.data.dataval || []).map((log) => ({
          ...log,
          completed_date: dateToLocalTimezone({ date: log.completed_date }),
        }));
        this.getState().studiesByFacilityList = logs;
        this.getState().studiesByFacilityDiagram =
          action.data.chartval || this.getEmptyDiagram();
        break;
      }
      default:
        break;
    }
    return this.getState();
  }

  areEqual(one, two) {
    return false;
  }
}

const studiesByFacilityPageStore = new StudiesByFacilityPageStore(
  appDispatcher
);
export default studiesByFacilityPageStore;
