import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    fromDate: yup
      .string()
      .typeError('Please, enter the date!')
      .required('Please, enter the date!'),
    toDate: yup
      .string()
      .typeError('Please choose next calibration date')
      .required('Please, enter the date!')
      .test({
        test: (toDate: string, { parent }) => {
          const { fromDate } = parent;
          const fromDateObj = new Date(fromDate);
          const toDateObj = new Date(toDate);
          return toDateObj > fromDateObj;
        },
        message: "TO date can't be earlier than FROM date",
      }),
    file: yup.mixed().test({
      test: (file: '' | File) => Boolean(file),
      message: 'Please, choose the file!',
    }),
  })
);

export default validation;
