import { apiRequest } from 'services/RequestService';

const Requests = {
  LOAD_PATIENT_RECORD: 'patient.PatientInfo.GetViewPatientInfo',
  UPDATE_PATIENT_RECORD: 'patient.PatientInfo.EditPatientInfo',
  SAVE_PATIENT_RECORD: 'patient.PatientInfo.AddPatientInfo',
  DELETE_PATIENT_RECORD: 'patient.PatientInfo.',
  MERGE_PATIENT_RECORDS: 'patient.PatientInfo.mergePatient',
  LOAD_LOCATION_BY_FACILITY_ID: 'generalmaster.MvLocation.GetSateRegionLoc',
  LOAD_PAYER_PLAN: 'generalmaster.PayorPlan.LoadPlanName',

  UPDATE_GENERAL_INFO: 'patient.PatientInfo.EditPatientInfo',
  UPDATE_FACILITY_INFO: 'patient.PatientInfo.EditPatientFacilityInfo',
  UPDATE_CONTACT_INFO: 'patient.AddressInfoManager.EditAddressType',
  UPDATE_EMPLOYER_INFO: 'patient.AddressInfoManager.AddMv_Address',
  UPDATE_PATIENT_STATUS_INFO: 'patient.PatientInfo.EditOtherInformation',
  UPDATE_GUARANTOR_INFO: 'patient.GuarantorInfo.EditGuarantorInfo',
  UPDATE_INSURED_INFO: 'patient.PatientInfo.EditInsInfo',
  UPDATE_COMMENT_INFO: 'patient.PatientInfo.EditPatientCommentInfo',

  SAVE_FACILITY_INFO: 'patient.PatientInfo.AddPatientFacilityInfo',
  SAVE_CONTACT_INFO: 'patient.AddressInfoManager.AddAddressType',
};

export class TPatientRecord {
  refid: number = null;
  patient_id: string = '';
  salutationid: string = '';
  workingphone: string = '';
  first_name: string = '';
  middle_name: string = '';
  last_name: string = '';
  last_nickname: string = '';
  first_nickname: string = '';
  suffixid: string = '';
  gender: 'M' | 'F' | 'U' | '' = '' as '';
  martial_status: string = '';
  birthdate: string = '';
  ssn: string = '';
  copay: string = '';
  employer_name: string = '';
  org_status: string = '';
  spouse_name: string = '';
  ice_number: string = '';
  inmate_number: string = '';
  immigration_id: string = '';
  clientMRN: string = '';
  smoking: string = '';
  symptomatic: string = '';
  patient_img_path: string = '';
  organization_id: string = '';
  organArr: string = '';
  room: string = '';
  yearly_deductible_paid: string = '';
  lblyearly_deductible_paid: 'Yes' | 'No' = null;
  ins_last_verified: string = '';
  patient_status: string = '';
  lblpatient_status: 'Patient Died' | 'Patient Discharged' | 'Patient Active' =
    null;
  dt_of_death: string = '';
  salutation: string = '';
  suffix: string = '';
  address1: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  mobile: string = '';
  phone: string = '';
  fax: string = '';
  email: string = '';
  address2: string = '';
  city2: string = '';
  state2: string = '';
  zipcode2: string = '';
  work_phone: string = '';
  Orgaddress: string = '';
  Orgaddress2: string = '';
  Orgstate: string = '';
  Orgcity: string = '';
  Orgzipcode: string = '';
  medicare_id: string = '';
  medicaid_id: string = '';
  medicaid_stateid: string = '';
  noinsure: string = '';
  primpayortype: string = '';
  primpayorid: string = '';
  primpayorname: string = '';
  primgroup: string = '';
  priminsurance_no: string = '';
  primplanid: string = '';
  priminsfname: string = '';
  priminsmname: string = '';
  priminslname: string = '';
  priminsgender: string = '';
  priminsdt: string = '';
  priminsstdt: string = '';
  priminsendt: string = '';
  priminsorgname: string = '';
  priminspatrelation: string = '';
  primplanname: string = '';
  prigroupno: string = '';
  priplan: string = '';
  priminsaddr2: string = '';
  priminscity2: string = '';
  priminsstate2: string = '';
  priminszip2: string = '';
  priminsaddr1: string = '';
  priminscity: string = '';
  priminsstate: string = '';
  priminszip: string = '';
  priminsph: string = '';
  priminsmobile: string = '';
  priminsfax: string = '';
  priminsemail: string = '';
  secpayortype: string = '';
  secpayorid: string = '';
  secgroup: string = '';
  secpayorname: string = '';
  secninsurance_no: string = '';
  secplanid: string = '';
  secinsfname: string = '';
  secinsmname: string = '';
  secinslname: string = '';
  secinsdt: string = '';
  secinsstdt: string = '';
  secinsendt: string = '';
  secinsgender: string = '';
  secinsorgname: string = '';
  secinspatrelation: string = '';
  secplanname: string = '';
  secgroupno: string = '';
  secplan: string = '';
  secinsaddr1: string = '';
  secinscity: string = '';
  secinsstate: string = '';
  secinszip: string = '';
  secinsph: string = '';
  secinsmobile: string = '';
  secinsfax: string = '';
  secinsemail: string = '';
  secinsaddr2: string = '';
  secinscity2: string = '';
  secinsstate2: string = '';
  secinszip2: string = '';
  msp: string = '';
  terpayortype: string = '';
  terpayorid: string = '';
  terpayorname: string = '';
  tergroup: string = '';
  teryinsurance_no: string = '';
  teryplanid: string = '';
  teryinsfname: string = '';
  teryinsmname: string = '';
  teryinslname: string = '';
  teryinsdt: string = '';
  teryinsstdt: string = '';
  teryinsendt: string = '';
  teryinsgender: string = '';
  teryinsorgname: string = '';
  teryinspatrelation: string = '';
  teryplanname: string = '';
  tergroupno: string = '';
  terplan: string = '';
  teryinsaddr1: string = '';
  teryinscity: string = '';
  teryinsstate: string = '';
  teryinszip: string = '';
  teryinsph: string = '';
  teryinsmobile: string = '';
  teryinsfax: string = '';
  teryinsemail: string = '';
  teryinsaddr2: string = '';
  teryinscity2: string = '';
  teryinsstate2: string = '';
  teryinszip2: string = '';
  guar_g_fnm: string = '';
  guar_g_mid: string = '';
  guar_g_lnm: string = '';
  guar_g_ssn: string = '';
  guar_g_gen: string = '';
  guar_g_dob: string = '';
  guar_g_relation: string = '';
  guar_g_wphone: string = '';
  gaddr_relationship: '' | 'Other' | 'Child' | 'Spouse' | 'Self' = '';
  gaddr_relation: string = '';
  gaddr_lastname: string = '';
  gaddr_firstname: string = '';
  gaddr_phone: string = '';
  guar_g_address1: string = '';
  guar_g_address2: string = '';
  guar_g_city: string = '';
  guar_g_state: string = '';
  guar_g_zip: string = '';
  guar_g_phone: string = '';
  guar_g_mobile: string = '';
  guar_g_fax: string = '';
  guar_g_email: string = '';
  guar_g_city2: string = '';
  guar_g_state2: string = '';
  guar_g_zip2: string = '';
  user_refid: string = '';
  user_name: string = '';
  user_pass: string = '';
  patcomment: string = '';
  facility_id: string = '';
  location: string = '';
  location_id: string = '';
  region: string = '';
  region_id: string = '';
  st: string = '';
  state_id: string = '';
  LocationArr: string = '';
  fac_addr: string = '';
  fac_addr2: string = '';
  fac_city: string = '';
  fac_state: string = '';
  fac_zipcode: string = '';
  fac_phno: string = '';
  fac_fax: string = '';
  fac_email: string = '';
}

export class TGeneralInfo {
  refid: number = null;
  patient_id: number = null;
  last_name: string = '';
  first_name: string = '';
  birthdate: string = '';
  salutationid: string = '';
  suffixid: string = '';
  patient_img_path: string = '';
  old_patient_img_path: string = '';
  copay: string = '';
  martial_status: string = '';
  spouse_name: string = '';
  organization_id: string = '';
  middle_name: string = '';
  ssn: string = '';
  gender: string = '';
  last_nickname: string = '';
  first_nickname: string = '';
  inmate_number: string = '';
  ice_number: string = '';
  immigration_id: string = '';
  clientMRN: string = '';
  smoking: string = '';
  symptomatic: string = '';
}

export class TContactInfo {
  refid: number = null;
  address1: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  mobile: string = '';
  phone: string = '';
  fax: string = '';
  email: string = '';
  address2: string = '';
  city2: string = '';
  state2: string = '';
  zipcode2: string = '';
}

export class TEmployerInfo {
  refid: number = null;
  Orgaddress: string = '';
  Orgcity: string = '';
  Orgstate: string = '';
  Orgzipcode: string = '';
  mobile: string = '';
  work_phone: string = '';
  fax: string = '';
  email: string = '';
  addressType: string = '';
  moduleType: string = '';
  employer_name: string = '';
  org_status: string = '';
}

export class TPatientStatusInfo {
  refid: number = null;
  room: string = '';
  patient_status: string = '';
  dt_of_death: string = '';
}

export class TGuarantorInfo {
  refid: number = null;
  guar_g_relation: string = '';
  guar_g_lnm: string = '';
  guar_g_fnm: string = '';
  guar_g_mid: string = '';
  guar_g_gen: string = '';
  guar_g_dob: string = '';
  guar_g_ssn: string = '';
  guar_g_wphone: string = '';
  guar_g_address1: string = '';
  guar_g_city: string = '';
  guar_g_state: string = '';
  guar_g_zip: string = '';
  guar_g_mobile: string = '';
  guar_g_phone: string = '';
  guar_g_fax: string = '';
  guar_g_email: string = '';
  guar_g_address2: string = '';
  guar_g_city2: string = '';
  guar_g_state2: string = '';
  guar_g_zip2: string = '';
}

export class TFacilityInfo {
  refid: number = null;
  location_id: number = null;
  facility_id: number = null;
}

interface TPayerPlan {
  label: string;
  data: string;
}

export class TInsuredInfo {
  refid: number = null;
  //
  primpayorid: number = null;
  secpayorid: number = null;
  terpayorid: number = null;
}

export class TCommentInfo {
  refid: number = null;
  comment: string = '';
  alert: string = '';
}

class PatientRecordPageService {
  loadPatientRecord(id: number) {
    return apiRequest<'SE' | 0 | Array<TPatientRecord>>({
      url: Requests.LOAD_PATIENT_RECORD,
      data: [id],
    }).then((response) => {
      if (response === 'SE' || response === 0) {
        return new TPatientRecord();
      }
      return (response as Array<TPatientRecord>)[0];
    });
  }

  savePatientRecord(patientRecord: any) {
    return apiRequest<number>({
      url: Requests.SAVE_PATIENT_RECORD,
      data: [patientRecord],
    });
  }

  updatePatientRecord(patientRecord: any) {
    return apiRequest({ url: Requests.UPDATE_PATIENT_RECORD });
  }

  deletePatientRecord(id: number) {
    return apiRequest({ url: Requests.DELETE_PATIENT_RECORD, data: [id] });
  }

  loadLocationByFacilityId(facilityId: number) {
    return apiRequest({
      url: Requests.LOAD_LOCATION_BY_FACILITY_ID,
      data: [facilityId],
    });
  }

  loadPayerPlan(payerId: number) {
    return apiRequest<Array<TPayerPlan>>({
      url: Requests.LOAD_PAYER_PLAN,
      data: [payerId],
    }).then((response) => {
      const options = response.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));

      return options;
    });
  }

  //TODO: old_patient_img_path, copay, symptomatic
  updateGeneralInfo(generalInfo: TGeneralInfo) {
    const data = [
      generalInfo.refid,
      generalInfo.patient_id,
      generalInfo.last_name,
      generalInfo.first_name,
      generalInfo.birthdate,
      generalInfo.salutationid,
      generalInfo.suffixid,
      generalInfo.patient_img_path,
      generalInfo.old_patient_img_path,
      generalInfo.copay,
      generalInfo.martial_status,
      generalInfo.spouse_name,
      generalInfo.organization_id,
      generalInfo.middle_name,
      generalInfo.ssn,
      generalInfo.gender,
      generalInfo.last_nickname,
      generalInfo.first_nickname,
      generalInfo.inmate_number,
      generalInfo.ice_number,
      generalInfo.immigration_id,
      generalInfo.clientMRN,
      generalInfo.smoking,
      generalInfo.symptomatic,
    ];

    return apiRequest({ url: Requests.UPDATE_GENERAL_INFO, data });
  }

  updateFacilityInfo(facilityInfo: TFacilityInfo) {
    const userType = 'P';
    const data = [
      facilityInfo.refid,
      facilityInfo.location_id,
      facilityInfo.facility_id,
      userType,
    ];

    return apiRequest({ url: Requests.UPDATE_FACILITY_INFO, data });
  }

  updateContactInfo(contactInfo: TContactInfo) {
    const data = [
      contactInfo.refid,
      contactInfo.address1,
      contactInfo.city,
      contactInfo.state,
      contactInfo.zipcode,
      contactInfo.mobile,
      contactInfo.phone,
      contactInfo.fax,
      contactInfo.email,
      contactInfo.address2,
      contactInfo.city2,
      contactInfo.state2,
      contactInfo.zipcode2,
    ];

    return apiRequest({ url: Requests.UPDATE_CONTACT_INFO, data });
  }

  saveFacilityInfo(facilityInfo: { location_id: number; facility_id: number }) {
    const userType = 'P';
    const data = [facilityInfo.location_id, facilityInfo.facility_id, userType];

    return apiRequest({ url: Requests.SAVE_FACILITY_INFO, data });
  }

  saveContactInfo(contactInfo: TContactInfo) {
    const data = [
      contactInfo.address1,
      contactInfo.city,
      contactInfo.state,
      contactInfo.zipcode,
      contactInfo.mobile,
      contactInfo.phone,
      contactInfo.fax,
      contactInfo.email,
      contactInfo.address2,
      contactInfo.city2,
      contactInfo.state2,
      contactInfo.zipcode2,
    ];

    return apiRequest({ url: Requests.SAVE_CONTACT_INFO, data });
  }

  updateEmployerInfo(employerInfo: TEmployerInfo) {
    employerInfo.moduleType = 'P';

    employerInfo.addressType = 'O1';

    const data = [
      employerInfo.refid,
      employerInfo.Orgaddress,
      employerInfo.Orgcity,
      employerInfo.Orgstate,
      employerInfo.Orgzipcode,
      employerInfo.mobile,
      employerInfo.work_phone,
      employerInfo.fax,
      employerInfo.email,
      employerInfo.addressType,
      employerInfo.moduleType,
      null,
      null,
      null,
      employerInfo.employer_name,
      employerInfo.org_status,
      '',
    ];

    return apiRequest({ url: Requests.UPDATE_EMPLOYER_INFO, data });
  }

  updatePatientStatusInfo(patientStatusInfo: TPatientStatusInfo) {
    const data = [
      patientStatusInfo.refid,
      patientStatusInfo.room,
      null,
      patientStatusInfo.patient_status,
      patientStatusInfo.dt_of_death || '',
    ];

    return apiRequest({ url: Requests.UPDATE_PATIENT_STATUS_INFO, data });
  }

  updateGuarantorInfo(guarantorInfo: TGuarantorInfo) {
    const data = [
      guarantorInfo.refid,
      guarantorInfo.guar_g_relation,
      guarantorInfo.guar_g_lnm,
      guarantorInfo.guar_g_fnm,
      guarantorInfo.guar_g_mid,
      guarantorInfo.guar_g_gen,
      guarantorInfo.guar_g_dob || '',
      guarantorInfo.guar_g_ssn,
      guarantorInfo.guar_g_wphone,
      guarantorInfo.guar_g_address1,
      guarantorInfo.guar_g_city,
      guarantorInfo.guar_g_state,
      guarantorInfo.guar_g_zip,
      guarantorInfo.guar_g_mobile,
      guarantorInfo.guar_g_phone,
      guarantorInfo.guar_g_fax,
      guarantorInfo.guar_g_email,
      guarantorInfo.guar_g_address2,
      guarantorInfo.guar_g_city2,
      guarantorInfo.guar_g_state2,
      guarantorInfo.guar_g_zip2,
    ];

    return apiRequest({ url: Requests.UPDATE_GUARANTOR_INFO, data });
  }

  updateInsuredInfo(insuredInfo: TInsuredInfo) {
    return apiRequest({
      url: Requests.UPDATE_INSURED_INFO,
      data: [insuredInfo.refid, insuredInfo],
    });
  }

  updateCommentInfo(commentInfo: TCommentInfo) {
    const userType = 'P';
    const data = [
      commentInfo.refid,
      commentInfo.comment,
      commentInfo.alert,
      userType,
    ];

    return apiRequest({ url: Requests.UPDATE_COMMENT_INFO, data });
  }
}

export default new PatientRecordPageService();
