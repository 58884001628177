import { startOfDay, endOfDay } from 'date-fns';

import RadioOptions from 'components/project/common/RadioOptions';
import { PureRadio } from 'components/form/radio';
import { PureInputCalendar } from 'components/form/inputCalendar';

import { calcDateRange } from 'utils/calcDateRange';

const calendarClassName = 'col-sm-6 col-md-3 col-xl-2';

interface PropsType<T> {
  nameFrom: string;
  nameTo: string;
  nameRadio: string;
  labelFrom?: string;
  labelTo?: string;
  labelRadio?: string;
  formModel?: T;
  radioClassName?: string;
  isUTC?: boolean;
  onChange: (state: T) => void;
}

const DateRangeLegacy = <T extends Record<string, any>>({
  nameFrom,
  nameTo,
  nameRadio,
  labelFrom = 'DOS From',
  labelTo = 'DOS To',
  labelRadio = 'Date Of Service',
  radioClassName = 'col-sm-12 col-md-6 col-lg-3',
  formModel,
  isUTC,
  onChange,
}: PropsType<T>) => {
  const handleChangeDateFrom = (name: string) => (value: string) => {
    const date =
      value && isUTC ? startOfDay(new Date(value)).toISOString() : value;

    const state = {
      ...formModel,
      [name]: date,
      [nameRadio]: '',
    };

    onChange(state);
  };

  const handleChangeDateTo = (name: string) => (value: string) => {
    const date =
      value && isUTC ? endOfDay(new Date(value)).toISOString() : value;

    const state = {
      ...formModel,
      [name]: date,
      [nameRadio]: '',
    };

    onChange(state);
  };

  const handleChangeDatePeriod = (value: string) => {
    const { dosStart, dosEnd } = calcDateRange(value, isUTC);

    const state = {
      ...formModel,
      [nameFrom]: dosStart,
      [nameTo]: dosEnd,
      [nameRadio]: value,
    };

    onChange(state);
  };

  return (
    <>
      <PureInputCalendar
        selectsStart
        name={nameFrom}
        label={labelFrom}
        value={formModel[nameFrom]}
        className={calendarClassName}
        startDate={formModel[nameFrom]}
        endDate={formModel[nameTo]}
        onChange={handleChangeDateFrom(nameFrom)}
      />
      <PureInputCalendar
        selectsEnd
        name={nameTo}
        label={labelTo}
        value={formModel[nameTo]}
        className={calendarClassName}
        startDate={formModel[nameFrom]}
        endDate={formModel[nameTo]}
        minDate={formModel[nameFrom]}
        onChange={handleChangeDateTo(nameTo)}
      />
      <PureRadio
        name={nameRadio}
        label={labelRadio}
        className={`part-inline ${radioClassName}`}
        value={formModel[nameRadio] || ''}
        options={RadioOptions.filterPeriod}
        onChange={handleChangeDatePeriod}
      />
    </>
  );
};

export default DateRangeLegacy;
