import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { DialogDocuments } from 'components/project/modals/DialogDocuments';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import { PureInput } from 'components/form/textField';
import DialogFacilitiesList from './components/DialogFacilitiesList';

import {
  storeCorporation,
  CorporationType,
} from 'stores/_mobx/systemSetup/corporation';
import {
  URL_CORPORATION,
  URL_CORPORATION_ADD,
} from 'constant/path/systemSetup';
import { CORPORATION as PAGE_ID } from 'constant/pagesId/systemSetup';

const columns = [
  { name: 'groupname', title: 'Corporation name' },
  { name: 'groupdesc', title: 'Description' },
  {
    name: 'facilityCount',
    title: 'No. of Facilities',
    className: 'text-center',
    render: (facilityCount: string, data: CorporationType) =>
      facilityCount ? (
        <Link
          to="/"
          onClick={(e: React.SyntheticEvent) => {
            e.preventDefault();
            storeCorporation.setParamsFacilities({
              corporationName: data.groupname,
              facilities: data.facilities,
            });
          }}>
          {facilityCount}
        </Link>
      ) : null,
  },
  {
    name: 'docs',
    title: 'Documents',
    className: 'text-center',
    render: (value: undefined, data: CorporationType) =>
      data.enable ? (
        <IconButton
          className="text-primary"
          onClick={() =>
            storeCorporation.getDocuments({
              id: data.refid,
              name: data.groupname,
            })
          }>
          <i className="bi bi-folder2-open" />
        </IconButton>
      ) : null,
  },
  { name: 'phone', title: 'Phone' },
  { name: 'fax', title: 'Fax' },
  {
    name: 'control',
    title: 'Action',
    render: (value: undefined, data: CorporationType) => (
      <div className="control">
        <Link
          className="bi bi-pencil"
          to={`${URL_CORPORATION}/edit/${data.refid}`}
        />
        <IconButton
          onClick={() =>
            storeCorporation.setParamsForDelete({
              id: data.refid,
              name: data.groupname,
            })
          }>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const CorporationListPage = () => {
  const {
    fetching,
    fetchingDocuments,
    corporationList,
    corporationTotal,
    filter,
    paramsForDelete,
    paramsForDocuments,
    paramsFacilities,
    documents,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeCorporation;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleFilterChange = (groupName: string) => {
    storeCorporation.setFilter(groupName);

    debouncedMethod();
  };

  const handleConfirmDeleting = () => {
    storeCorporation.deleteCorporation().then((isSucceed) => {
      if (isSucceed) storeCorporation.getCorporationListMain();
    });
  };

  const handleCloseDocuments = () => {
    storeCorporation.clearParamsForDocuments();
    storeCorporation.clearDocuments();
  };

  useEffect(() => {
    storeCorporation.getCorporationListMain();

    return storeCorporation.clearStore;
  }, [pagination]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Corporations" />

      <PureInput
        placeholder="Corporation Name"
        className="col-md-6 col-xl-4"
        value={filter}
        onChange={handleFilterChange}
      />

      <Grid
        id="corporations_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={corporationList}
        dataSourceCount={corporationTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_CORPORATION_ADD} />
        }
      />
      {paramsForDelete && (
        <DialogConfirm
          onCancel={storeCorporation.clearParamsForDelete}
          onApprove={handleConfirmDeleting}>
          {`Are you sure you want to delete the ${paramsForDelete.name} corporation?`}
        </DialogConfirm>
      )}

      {paramsFacilities && (
        <DialogFacilitiesList
          corpName={paramsFacilities.corporationName}
          facilities={paramsFacilities.facilities}
          onClose={storeCorporation.clearParamsFacilities}
        />
      )}

      {paramsForDocuments && (
        <DialogDocuments
          title={`Documents ${paramsForDocuments.name}`}
          fetching={fetchingDocuments}
          documents={documents}
          onClose={handleCloseDocuments}
        />
      )}
    </>
  );
};

export default observer(CorporationListPage);
