import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { Radio } from 'components/form/radio';
import { Textarea } from 'components/form/textarea';
import { Button } from 'components/button';
import resolver from './vlidation';

import { FormModel } from 'stores/_mobx/systemSetup/masterSetting/finding';

const categoryOptions = [
  { label: 'Positive', value: 'P' },
  { label: 'Negative', value: 'N' },
  { label: 'Formative', value: 'F' },
  { label: 'Indeterminate', value: 'I' },
];

const initialValues: FormModel = {
  category: '',
  comment: '',
  name: '',
  id: 0,
};

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormFinding = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const handleReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={`col-lg-8${isSubmitting ? ' on-ajax' : ''}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Input name="name" label="Name" required control={control} />
      <Radio
        className="inline"
        name="category"
        label="Category"
        required
        options={categoryOptions}
        control={control}
      />
      <Textarea name="comment" label="Comment" rows={3} control={control} />
      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormFinding;
