import React from 'react';
import AbstractEqualFilter from './AbstractEqualFilter';
import Select from 'components/form/input/Select';

const AGE = [
  { value: '', label: ' -- Select -- ' },
  { value: '0 to 30', label: '0 to 30' },
  { value: '30 to 60', label: '30 to 60' },
  { value: '60 to 90', label: '60 to 90' },
  { value: '90 to 120', label: '90 to 120' },
  { value: 'Above 120', label: 'Above 120' },
];

export default class Age extends AbstractEqualFilter {
  name = 'age';
  field = 'Age';
  field1 = 'AGE';

  render() {
    return (
      <div className="first-name-filter">
        {this.renderConditions()}
        <Select
          options={AGE}
          className="col-sm-3"
          name={this.name}
          label="Value"
          value={this.state.filterItem.value}
          onSetValue={(name, value) => this.onSetValue(name, value)}
        />
      </div>
    );
  }
}
