import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Grid } from 'components/grid';
import { Button } from 'components/button';
import Filter from './components/Filter';
import DiagnosticViewingLink from './components/DiagnosticViewingLink';

import {
  storeLegacyPatientData,
  PatientType,
  FilterType,
} from 'stores/_mobx/patientRecord/legacyPatientData';

const startShownColumns: Map<string, boolean> = new Map<string, boolean>([
  ['accession_no', false],
  ['gender', false],
]);

const columns = [
  { name: 'first_name', title: 'First Name' },
  { name: 'last_name', title: 'Last Name' },
  { name: 'middle_name', title: 'Middle Name' },
  {
    title: 'Accession #',
    name: 'accession_no',
  },
  { name: 'schedule_date', title: 'Schedule Date', className: 'width-100' },
  { name: 'medical_record_number', title: 'MRN' },
  { name: 'birth_date', title: 'Birth Date', className: 'width-100' },
  { name: 'gender', title: 'Gender' },
  { name: 'referring_physician_name', title: 'Ref Physician Name' },
  { name: 'cpt_codes', title: 'CPT' },
  {
    name: 'pdf_action',
    title: 'View Images',
    className: 'width-75 text-center',
    render: (value: undefined, patient: PatientType) => (
      <DiagnosticViewingLink patient={patient} />
    ),
  },
];

const LegacyPatientData = () => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    patients,
    patientsTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeLegacyPatientData;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeLegacyPatientData.setFilter(filter);
    setPaginationToStart();
  };

  useEffect(() => {
    storeLegacyPatientData.getPatients();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle title="Legacy Patient Information">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide filter' : 'Show filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        isVisible={isFilterVisible}
        fetching={fetching}
        onSubmit={handleChangeFilter}
      />

      <Grid
        id="exam_info_grid"
        ref={gridRef}
        columns={columns}
        onAjax={fetching}
        dataSource={patients}
        dataSourceCount={patientsTotal}
        pagination={pagination}
        shownColumns={startShownColumns}
        onPaginationChange={setPagination}
      />
    </>
  );
};

export default observer(LegacyPatientData);
