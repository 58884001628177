import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import GridActionControls from 'page/components/GridActionControls';
import Title from 'components/project/common/title';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';

import { storeGroupManager } from 'stores/_mobx/clinicianManager/groupManager';
import { GROUP_MANAGER as PAGE_ID } from 'constant/pagesId/clinicianManager';
import {
  URL_GROUP_MANAGER_ADD,
  URL_GROUP_MANAGER,
} from 'constant/path/clinicalManager';

const columns = [
  { name: 'groupname', title: 'Clinical Group Name' },
  { name: 'groupdesc', title: 'Clinical Group Description' },
  {
    name: 'pcpcnt',
    title: 'Count of Physicians',
    className: 'width-150 text-center',
  },
  {
    name: 'refid',
    title: 'Actions',
    className: 'width-75',
    render: (id: number) => (
      <GridActionControls
        id={id}
        url={`${URL_GROUP_MANAGER}/edit/${id}`}
        onDelete={storeGroupManager.setIdForDelete}
      />
    ),
  },
];

const GroupManagerListPage = () => {
  const {
    fetching,
    groupList,
    groupTotal,
    idForDelete,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeGroupManager;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleApproveDelete = () => {
    storeGroupManager.deleteGroup().then((isSucceed) => {
      if (isSucceed) storeGroupManager.getGroupListMain();
    });
  };

  const handleSearch = (groupName: string) => {
    storeGroupManager.setFilter(groupName);
    debouncedMethod();
  };

  useEffect(() => {
    storeGroupManager.getGroupListMain();
  }, [pagination]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Group Manager Page" />

      <PureInput
        name="name"
        placeholder="Clinical Group Name"
        className="col-md-6 col-lg-4"
        value={filter}
        onChange={handleSearch}
      />

      <Grid
        id="clinician_manager_group_manager_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={groupList}
        dataSourceCount={groupTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_GROUP_MANAGER_ADD} />
        }
      />
      {idForDelete ? (
        <DialogConfirm
          onApprove={handleApproveDelete}
          onCancel={storeGroupManager.clearIdForDelete}>
          Are you sure you want to delete this Group?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(GroupManagerListPage);
