import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone, getDayBounds } from 'utils/DateUtils';

export const initialFilterValues: FilterModel = {
  technologistId: 0,
  vehicle: '',
  ekg: '',
  phone: '',
  date: '',
  serviceId: 'N',
};

export interface FilterModel {
  technologistId: number;
  vehicle: string;
  ekg: string;
  phone: string;
  date: string;
  serviceId: 'Y' | 'N';
}

interface EntryResponseType {
  check_out_dt: string;
  date: string;
  device_name: string;
  ekg_name: string;
  end_odometer: string;
  full_lead: number;
  glove_name: string;
  groupid: number;
  half_lead: number;
  no_of_stops: number;
  phone_desc: string;
  phone_no: string;
  refid: number;
  reg_number: string;
  sign: string;
  start_odometer: string;
  technisicanfirstname: string;
  technisicanlastname: string;
  thyroid_shield: string;
  tootip: string;
  total_miles: string;
  vehicle_id: number;
  vehicle_name: string;
  visible: boolean;
}

interface EquipmentInfoType {
  date: string;
  device_id: number;
  device_type: string;
  first_name: string;
  last_name: string;
  phone_no: string;
  start_odometer: string;
  vehicle_name: string;
}

export interface EntryType
  extends Omit<
    EntryResponseType,
    'technisicanfirstname' | 'technisicanlastname'
  > {
  technologist: string;
}

interface UnitType {
  isActive: boolean;
  deviceId: number;
  details: string;
}

interface XrayType extends Omit<UnitType, 'deviceId'> {
  deviceId: string;
}

export interface RequestServiceFormType {
  xRayUnit: XrayType;
  ekg: UnitType;
  phone: UnitType;
  vehicle: UnitType;
  boneSonometer: UnitType;
}

class CheckOutIn {
  fetching: boolean = false;
  fetchingEquipment: boolean = false;
  sendingServiceRequest: boolean = false;
  entryList: EntryType[] = [];
  entryTotal: number = 0;
  idForDelete: number = 0;
  equipmentForCheckIn: EntryType | null = null;
  equipmentInfo: EquipmentInfoType | null = null;
  filter: FilterModel = initialFilterValues;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'checkOutGrid' });

    makeObservable(this, {
      fetching: observable,
      fetchingEquipment: observable,
      sendingServiceRequest: observable,
      entryList: observable,
      entryTotal: observable,
      idForDelete: observable,
      equipmentForCheckIn: observable,
      equipmentInfo: observable,
      filter: observable,

      setFetching: action,
      setFetchingEquipment: action,
      setSendingServiceRequest: action,
      setFilter: action,
      setEntryList: action,
      setEntryTotal: action,
      setIdForDelete: action,
      setEquipmentInfo: action,
      setEquipmentForCheckIn: action,
      clearIdForDelete: action.bound,
      clearEquipmentForCheckIn: action.bound,
      clearEquipment: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingEquipment(fetching: boolean) {
    this.fetchingEquipment = fetching;
  }

  setSendingServiceRequest(fetching: boolean) {
    this.sendingServiceRequest = fetching;
  }

  setEntryList(list: EntryType[]) {
    this.entryList = list;
  }

  setEntryTotal(total: number) {
    this.entryTotal = total;
  }

  setFilter(filter: FilterModel) {
    this.filter = filter;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setEquipmentInfo(info: EquipmentInfoType) {
    this.equipmentInfo = info;
  }

  setEquipmentForCheckIn(equipmentDetails: EntryType) {
    this.equipmentForCheckIn = equipmentDetails;
  }

  clearEquipmentForCheckIn() {
    this.equipmentForCheckIn = null;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  clearEquipment() {
    this.equipmentInfo = null;
  }

  clearStore = () => {
    this.equipmentForCheckIn = null;
    this.idForDelete = 0;
    this.equipmentInfo = null;
  };

  async getEntryCount(data: any[]) {
    try {
      const count = await apiRequest<number>({
        url: 'vehicle.EquipmentMaster.EquipmentTotalCount',
        data,
      });

      this.setEntryTotal(Number(count) || 0);
    } catch (e: any) {
      this.setEntryTotal(0);
    }
  }

  async getEntryList(filter: any[]) {
    const { pagination } = this.page;

    const data = [pagination.skip, pagination.pageSize, ...filter];

    try {
      const response = await apiRequest<EntryResponseType[]>({
        url: 'vehicle.EquipmentMaster.EquipmentList',
        data,
      });

      const list = response.map(
        ({ technisicanfirstname, technisicanlastname, ...el }) => ({
          ...el,
          check_out_dt: dateToLocalTimezone({
            date: el.check_out_dt,
          }),
          technologist: `${technisicanfirstname} ${technisicanlastname}`,
          groupid: Number(el.groupid),
          half_lead: Number(el.half_lead),
          no_of_stops: Number(el.no_of_stops),
          refid: Number(el.refid),
          vehicle_id: Number(el.vehicle_id),
        })
      );

      this.setEntryList(list);
    } catch (e: any) {
      this.setEntryList([]);
    }
  }

  getEntryLisMain = (filter: FilterModel = this.filter) => {
    this.setFetching(true);

    const { dateFrom, dateTo } = getDayBounds(filter.date);

    const filterPayload = [
      filter.vehicle,
      filter.technologistId,
      'L',
      filter.serviceId,
      dateFrom,
      dateTo,
      filter.ekg,
      filter.phone,
    ];

    const promiseList = this.getEntryList(filterPayload);

    const promiseCount = this.getEntryCount(filterPayload);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async deleteEntry() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'vehicle.EquipmentMaster.DeleteEquipment',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Entry was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("This entry can't be deleted!");
      this.setFetching(false);
      return false;
    }
  }

  async getEquipmentInfoByTechId(id: number) {
    if (!id) {
      this.clearEquipment();
      return Promise.resolve();
    }

    this.setFetchingEquipment(true);
    try {
      const response = await apiRequest<EquipmentInfoType | []>({
        url: 'vehicle.EquipmentMaster.GetCheckOutDetails',
        data: [id],
      });
      if (Array.isArray(response)) {
        throw new Error('');
      } else {
        this.setEquipmentInfo(response);
      }
    } catch (e: any) {
      this.clearEquipment();
    } finally {
      this.setFetchingEquipment(false);
    }
  }

  async sendServiceRequest({ xRayUnit, ...payload }: RequestServiceFormType) {
    this.setSendingServiceRequest(true);

    const [xRayType, xRayId] = xRayUnit.isActive
      ? xRayUnit.deviceId.split('_')
      : [null, null];

    const ekg = payload.ekg.isActive ? payload.ekg : null;

    const phone = payload.phone.isActive ? payload.phone : null;

    const vehicle = payload.vehicle.isActive ? payload.vehicle : null;

    const bs = payload.boneSonometer.isActive ? payload.boneSonometer : null;

    const data = [
      xRayType,
      xRayId,
      xRayUnit.isActive ? xRayUnit.details : '',
      ekg?.deviceId || null,
      ekg?.details || '',
      phone?.deviceId || null,
      phone?.details || '',
      vehicle?.deviceId || null,
      vehicle?.details || '',
      bs?.deviceId || null,
      bs?.details || '',
      'W',
    ];
    try {
      const response = await apiRequest<string>({
        url: 'vehicle.EquipmentMaster.UpdateVehicleGroup',
        data,
      });

      if (response === 'S') {
        Notification.success('Service request has been sent');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      return false;
    } finally {
      this.setSendingServiceRequest(false);
    }
  }

  async updateOdometerValue() {
    if (this.fetching) return Promise.resolve(false);

    this.setFetching(true);

    try {
      const equipmentDetails = this.equipmentForCheckIn;
      const data = [
        equipmentDetails.refid,
        equipmentDetails.vehicle_id,
        equipmentDetails.start_odometer,
        equipmentDetails.start_odometer,
        equipmentDetails.total_miles,
      ];
      const response = await apiRequest<'S' | 'E'>({
        url: 'vehicle.EquipmentMaster.UpdateOdometerEquipment',
        data,
      });

      if (response === 'S') {
        Notification.success('Equipment registered successfully!');
        return true;
      }
      return false;
    } catch (e: any) {
      Notification.danger('An error is occurred!');
      this.setFetching(false);
      return false;
    } finally {
      this.clearEquipmentForCheckIn();
    }
  }
}

export const storeCheckOutIn = new CheckOutIn();
