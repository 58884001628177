import React from 'react';

import Exporter from 'components/project/Exporter';
import ManageDocuments from 'components/project/modals/ManageDocuments';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import Validation from 'components/form/Validation';
import NumberInput from 'components/form/input/NumberInput';
import Text from 'components/form/input/Text';
import Select from 'components/form/input/Select';

import TimeSheetPageActions from 'actions/profile/personal/TimeSheetPageActions';
import {
  TTrackingData,
  TCalendarData,
  TTimeSheetCalendar,
  TWeekNotes,
} from 'services/profile/personal/TimeSheetPageService';
import UserProfileStore from 'stores/UserProfileStore';
import DateUtils, { months, daysOfWeek } from 'utils/DateUtils';
import MathUtils from 'utils/MathUtils';
import StringUtils from 'utils/StringUtils';

const BENEFITS_CODES = [
  { value: 'S', label: 'Sick' },
  { value: 'H', label: 'Holiday' },
  { value: 'B', label: 'Bereavement' },
];

const DAY_LIMIT = 8 * 60 + 29;
const ZERO_TIME = '00:00:00';

export class TTimeSheetTime {
  hours: number = 0;
  minutes: number = 0;
}

export interface PTimeSheetCalendar {
  appId?: string;
  userId: string;
  userName: string;
  year: number;
  month: number;
  week: number;
  getComments: () => TWeekNotes;
}

export class STimeSheetCalendar {
  dataSource: Array<TTimeSheetCalendar> = [];
  importFileIsOpen: boolean = false;
  selectedRow: TTimeSheetCalendar = null;
  exporter: boolean = false;
}

export default class TimeSheetCalendar extends React.Component<
  PTimeSheetCalendar,
  STimeSheetCalendar
> {
  constructor(props: PTimeSheetCalendar) {
    super(props);
    this.state = new STimeSheetCalendar();
  }

  setData(calendar: Array<TCalendarData>, tracker: Array<TTrackingData>) {
    const { props } = this;
    const dataSource: Array<TTimeSheetCalendar> = this.generateDataSource(
      props.year,
      props.month,
      props.week
    );
    for (let j = 0; j < dataSource.length; j++) {
      const row: TTimeSheetCalendar = dataSource[j];
      for (let i = 0; i < calendar.length; i++) {
        const dataRow: TCalendarData = calendar[i];
        if (dataRow.day_date === row.day_date) {
          row.vacation = dataRow.vacation !== ZERO_TIME ? dataRow.vacation : '';
          row.benefits = dataRow.benefits !== ZERO_TIME ? dataRow.benefits : '';
          row.benefits_type = dataRow.benefitsType;
          row.pto_paid = dataRow.ptoPaid !== ZERO_TIME ? dataRow.ptoPaid : '';
          row.over_time =
            dataRow.over_time !== ZERO_TIME ? dataRow.over_time : '';
          row.regular_time =
            dataRow.regular_time !== ZERO_TIME ? dataRow.regular_time : '';
          row.mileage = dataRow.mileage;
          row.expenses = dataRow.expenses;
          row.refid = dataRow.refid;
          row.call_hours =
            dataRow.call_hours !== ZERO_TIME ? dataRow.call_hours : '';
          row.isSubmitted = dataRow.isSubmitted;
          row.isLocked = dataRow.isLocked;
        }
      }
      const shiftList: Array<TTrackingData> = [];
      for (let i = 0; i < tracker.length; i++) {
        const trackerRow: TTrackingData = tracker[i];
        if (trackerRow.date === row.day_date) {
          shiftList.push(new TTrackingData().copy(trackerRow));
        }
      }
      row.shiftList = shiftList;
      row.hours = this.calculateHours([
        /*row.regular_time,*/ row.vacation,
        row.benefits,
      ]);
    }
    const totalStub: TTimeSheetCalendar = new TTimeSheetCalendar();
    totalStub.total = true;
    dataSource.push(totalStub);
    this.setState({ dataSource });
    return dataSource;
  }

  formatHours(time: number) {
    return (
      StringUtils.leftPad(MathUtils.parseInt('' + time / 60), 2) +
      ':' +
      StringUtils.leftPad(time % 60, 2)
    );
  }

  generateDataSource(
    year: number,
    month: number,
    week: number
  ): Array<TTimeSheetCalendar> {
    const calendar = TimeSheetPageActions.generateCalendar(year, month, week);
    const dataSource: Array<TTimeSheetCalendar> = [];
    let days = 7;

    while (days--) {
      //warning! method TimeSheetPageActions.getAndIncrement change calendar value
      const day = daysOfWeek[calendar.getDayOfWeek().valueOf()];
      const item: TTimeSheetCalendar = new TTimeSheetCalendar();
      item.day_date = TimeSheetPageActions.getAndIncrement(calendar);
      item.day = day;
      dataSource.push(item);
    }
    return dataSource;
  }

  updateTableCell(
    name: string,
    value: string,
    row: TTimeSheetCalendar,
    errors?: any
  ) {
    const dataSource: Array<TTimeSheetCalendar> = [];
    this.state.dataSource.forEach((item) => {
      if (item === row) {
        (row as any)[name] = value;
        if (errors) {
          if (!row.errors) row.errors = {};
          row.errors[name] = errors;
        }
        dataSource.push(row);
      } else {
        dataSource.push(item);
      }
    });
    this.setState({ dataSource });
  }

  getData(): { data: Array<TTimeSheetCalendar>; hasErrors: boolean } {
    const dataSource = this.state.dataSource
      .filter((v) => !v.total)
      .map((v: TTimeSheetCalendar) => {
        const parts = v.day_date.split('/');
        const day = parts[2] + '-' + parts[0] + '-' + parts[1];
        const out: TTimeSheetCalendar = {
          ...v,
          shiftList: v.shiftList.map((v) => ({
            ...v,
            clock_end: v.clock_end ? `${day} ${v.clock_end}:00` : '',
            clock_start: v.clock_start ? `${day} ${v.clock_start}:00` : '',
          })),
        };
        return out;
      });
    return {
      data: dataSource,
      hasErrors: this.validate(),
    };
  }

  validate(): boolean {
    let ds = this.state.dataSource;
    for (let i = 0; i < ds.length; i++) {
      let errors = ds[i].errors;
      if (typeof errors !== 'undefined') {
        for (let key in errors) {
          if (errors.hasOwnProperty(key) && errors[key].length > 0) {
            return true;
          }
        }
      }
    }
    return false;
  }

  addShiftItem(event: any, row: TTimeSheetCalendar, index: number) {
    if (event) {
      event.preventDefault();
    }
    const dataSource: Array<TTimeSheetCalendar> = [...this.state.dataSource];
    const shiftList: Array<TTrackingData> = row.shiftList;
    shiftList.splice(index + 1, 0, new TTrackingData());
    this.setState({ dataSource: dataSource });
  }

  removeShiftItem(event: any, row: TTimeSheetCalendar, index: number) {
    if (event) {
      event.preventDefault();
    }
    const dataSource: Array<TTimeSheetCalendar> = [...this.state.dataSource];
    row.shiftList.splice(index, 1);
    this.setState({ dataSource: dataSource });
  }

  onChangeClock(
    row: TTimeSheetCalendar,
    index: number,
    name: string,
    value: string,
    errors: any
  ) {
    const dataSource: Array<TTimeSheetCalendar> = [...this.state.dataSource];
    dataSource.forEach((item) => {
      if (item.refid === row.refid) {
        if (row.shiftList.length !== 0) {
          (row.shiftList[index] as any)[name] = value;
        } else {
          const shift = new TTrackingData();
          (shift as any)[name] = value;
          row.shiftList.push(shift);
        }
        if (errors) {
          row.errors[name + '_' + index] = errors;
        }
      }
    });
    this.setState({ dataSource: dataSource });
  }

  parseClocks(clock: string): number {
    if (clock) {
      let parts = clock.split(':').map((v) => MathUtils.parseInt(v));
      if (parts.length > 0) {
        return parts[0] * 60 + (parts[1] ? parts[1] : 0);
      }
    }
    return 0;
  }

  getColumns() {
    const me = this;
    const timeSpreadAttributes = {
      validations: 'time::23',
      noLabel: true,
      format: '##:##',
    };
    const totalRender = (
      v: string,
      data: TTimeSheetCalendar,
      column: string,
      customRender: (v: string, data: TTimeSheetCalendar) => React.ReactNode
    ) => {
      if (typeof data.total !== 'undefined' && data.total) {
        let total: number = 0;
        switch (column) {
          case 'in_out_time':
            return me.boldText('Total:');
          case 'hours':
            total = 0;
            me.state.dataSource.forEach((v) => {
              total += me.calculateRegularTime(v);
            });
            return me.boldText(me.formatHours(total));
          case 'regular_time':
          case 'over_time':
          case 'call_hours':
          case 'vacation':
          case 'pto_paid':
          case 'benefits':
            total = 0;
            me.state.dataSource.forEach((v: TTimeSheetCalendar) => {
              total += me.parseClocks(v[column]);
            });
            return me.boldText(me.formatHours(total));
          case 'shifts':
            total = 0;
            me.state.dataSource.forEach((v) => {
              total += v.shiftList ? v.shiftList.length : 0;
            });
            return me.boldText(total);
          case 'expenses':
            total = 0;
            me.state.dataSource.forEach((v: TTimeSheetCalendar) => {
              total += MathUtils.parseFloat(v[column]);
            });
            return me.boldText('$ ' + MathUtils.parseFloat(total, 2));
          case 'mileage':
            total = 0;
            me.state.dataSource.forEach((v: TTimeSheetCalendar) => {
              total += MathUtils.parseFloat(v[column]);
            });
            return me.boldText('' + MathUtils.parseFloat(total, 2) + ' mi');
          default:
            return v;
        }
      } else {
        return customRender(v, data);
      }
    };
    return [
      {
        name: 'day_date',
        title: 'In Date',
        className: 'width-100',
        export: {
          target: (v: string, row: TTimeSheetCalendar) => {
            if (row.total) {
              return this.props.getComments().comments;
            }
            return v + ' ' + row.day;
          },
        },
        render: (v: string, data: TTimeSheetCalendar) =>
          totalRender(v, data, '', () => {
            return (
              <div className="width-100">
                {v}
                <br />
                {data.day}
              </div>
            );
          }),
      },
      {
        title: 'In / Out Time',
        name: 'clock_start',
        noSort: true,
        export: {
          target: (v: string, row: TTimeSheetCalendar) => {
            if (row.total) {
              return this.props.getComments().supervisorComments;
            }
            let shiftList =
              row.shiftList && row.shiftList.length > 0 ? row.shiftList : [];
            let out: Array<string> = [];
            shiftList.forEach((shift) => {
              out.push([shift.clock_start, shift.clock_end].join(' - '));
            });
            return out.join(', ');
          },
        },
        render: (value: string, row: TTimeSheetCalendar) =>
          totalRender(value, row, 'in_out_time', () => {
            const numberInputs: Array<React.ReactNode> = [];
            const shiftList: Array<TTrackingData> =
              row.shiftList && row.shiftList.length > 0
                ? row.shiftList
                : [new TTrackingData()];
            const disabled = this.isDisabledRow(row);
            shiftList.forEach((shift, index) => {
              numberInputs.push(
                <React.Fragment
                  key={`clock_start_${row.refid}_${row.day_date}`}>
                  <NumberInput
                    className="fixed-input"
                    {...timeSpreadAttributes}
                    name={'clock_start_' + row.refid + '_' + index}
                    onSetValue={(name: string, value: string, errors: any) =>
                      this.onChangeClock(
                        row,
                        index,
                        'clock_start',
                        value,
                        errors
                      )
                    }
                    attr={{ disabled: disabled }}
                    errors={row.errors['clock_start_' + index]}
                    value={shift.clock_start}
                  />
                  <NumberInput
                    className="fixed-input"
                    {...timeSpreadAttributes}
                    name={'clock_end_' + row.refid + '_' + index}
                    onSetValue={(name: string, value: string, errors: any) =>
                      this.onChangeClock(row, index, 'clock_end', value, errors)
                    }
                    attr={{ disabled: disabled }}
                    errors={row.errors['clock_end_' + index]}
                    value={shift.clock_end}
                  />
                  {disabled ? null : (
                    <div className="tracking-control">
                      <IconButton
                        onClick={(e) => this.addShiftItem(e, row, index)}>
                        <i className="bi bi-plus-circle" />
                      </IconButton>
                      <IconButton
                        onClick={(e) => this.removeShiftItem(e, row, index)}>
                        <i className="bi bi-x-circle" />
                      </IconButton>
                    </div>
                  )}
                </React.Fragment>
              );
            });
            return (
              <div
                className="tracking"
                key={`in_out_time_${row.refid}_${row.day_date}`}>
                {numberInputs}
              </div>
            );
          }),
      },
      {
        name: 'hours',
        noSort: true,
        export: {
          target: (v: string, row: TTimeSheetCalendar) => {
            if (row.total) return '';
            return this.renderRegularTime(row);
          },
        },
        render: (value: string, row: TTimeSheetCalendar) =>
          totalRender(value, row, 'hours', () => {
            return this.renderRegularTime(row);
          }),
      },
      {
        name: 'regular_time',
        noSort: true,
        title: 'Regular Time',
        export: {
          target: (value: string, row: TTimeSheetCalendar) => {
            if (row.total) return '';
            return value;
          },
        },
        render: (value: string, row: TTimeSheetCalendar) =>
          totalRender(value, row, 'regular_time', () => {
            const disabled = this.isDisabledRow(row);
            return (
              <NumberInput
                key={`reqular_time_${row.refid}_${row.day_date}`}
                {...timeSpreadAttributes}
                className="fixed-input "
                name="regular_time"
                attr={{ disabled }}
                onSetValue={(n: string, v: string, errors: any) =>
                  this.updateTableCell(n, v, row, errors)
                }
                errors={row.errors.regular_time}
                value={value}
              />
            );
          }),
      },
      {
        name: 'over_time',
        noSort: true,
        export: {
          target: (v: string, row: TTimeSheetCalendar) => {
            if (row.total) return '';
            return v;
          },
        },
        render: (value: string, row: TTimeSheetCalendar) =>
          totalRender(value, row, 'over_time', () => {
            const disabled = this.isDisabledRow(row);
            return (
              <NumberInput
                key={`over_time_${row.refid}_${row.day_date}`}
                {...timeSpreadAttributes}
                className="fixed-input"
                name="over_time"
                attr={{ disabled }}
                onSetValue={(n: string, v: string, errors: any) =>
                  this.updateTableCell(n, v, row, errors)
                }
                errors={row.errors.over_time}
                value={value}
              />
            );
          }),
      },
      {
        name: 'call_hours',
        noSort: true,
        export: {
          target: (v: string, row: TTimeSheetCalendar) => {
            if (row.total) return '';
            return v;
          },
        },
        render: (value: string, row: TTimeSheetCalendar) =>
          totalRender(value, row, 'call_hours', () => {
            const disabled = this.isDisabledRow(row);
            const timeSpreadAttributesCallHours = {
              validations: 'time::99',
              noLabel: true,
              format: '##:##',
            };
            return (
              <NumberInput
                key={`call_hours_${row.refid}_${row.day_date}`}
                {...timeSpreadAttributesCallHours}
                className="fixed-input "
                name="call_hours"
                attr={{ disabled }}
                onSetValue={(n: string, v: string, errors: any) =>
                  this.updateTableCell(n, v, row, errors)
                }
                errors={row.errors.call_hours}
                value={value}
              />
            );
          }),
      },
      {
        name: 'vacation',
        noSort: true,
        export: {
          target: (v: string, row: TTimeSheetCalendar) => {
            if (row.total) return '';
            return v;
          },
        },
        title: 'Vacation',
        render: (value: string, row: TTimeSheetCalendar) =>
          totalRender(value, row, 'vacation', () => {
            const disabled = this.isDisabledRow(row);
            return (
              <NumberInput
                key={`vacation_${row.refid}_${row.day_date}`}
                {...timeSpreadAttributes}
                className="fixed-input "
                name="vacation"
                attr={{ disabled }}
                onSetValue={(n: string, v: string, errors: any) =>
                  this.updateTableCell(n, v, row, errors)
                }
                errors={row.errors.vacation}
                value={value}
              />
            );
          }),
      },
      {
        name: 'benefits',
        noSort: true,
        export: {
          target: (value: string, row: TTimeSheetCalendar) => {
            if (row.total) {
              return '';
            }
            return (
              row.benefits +
              (row.benefits_type
                ? '(' +
                  BENEFITS_CODES.filter((v) => v.value === row.benefits_type)
                    .map((v) => v.label)
                    .join('') +
                  ')'
                : '')
            );
          },
        },
        title: 'Benefits',
        render: (value: string, row: TTimeSheetCalendar) =>
          totalRender(value, row, 'benefits', () => {
            const disabled = this.isDisabledRow(row);
            return (
              <div
                key={`benefits_${row.refid}_${row.day_date}`}
                className="pair-input benefits">
                <NumberInput
                  className="input1"
                  name="benefits"
                  noLabel
                  attr={{ disabled: disabled }}
                  validations={{
                    time: ['', 23],
                    custom: (value: string) =>
                      this.validateBenefits(row, value),
                  }}
                  value={row.benefits}
                  onSetValue={(n: string, v: string, errors: any) =>
                    this.updateTableCell(n, v, row, errors)
                  }
                  errors={row.errors.benefits}
                  format="##:##"
                />
                <Select
                  className="input2"
                  name="benefits_type"
                  noLabel
                  attr={{ disabled: disabled, readOnlyMode: disabled }}
                  value={row.benefits_type}
                  onSetValue={(n: string, v: string, e: any) =>
                    this.updateTableCell(n, v, row, e)
                  }
                  errors={row.errors ? row.errors.benefits_type : null}
                  options={BENEFITS_CODES}
                  errorMessages={{ custom: Validation.messages.required }}
                  validations={{
                    custom: (value: string) =>
                      this.validateBenefitsType(row, value),
                  }}
                />
              </div>
            );
          }),
      },
      {
        name: 'pto_paid',
        noSort: true,
        export: {
          target: (v: string, row: TTimeSheetCalendar) => {
            if (row.total) {
              return '';
            }
            return v;
          },
        },
        title: 'Non-Paid',
        render: (value: string, row: TTimeSheetCalendar) =>
          totalRender(value, row, 'pto_paid', () => {
            const disabled = this.isDisabledRow(row);
            return (
              <NumberInput
                {...timeSpreadAttributes}
                key={`pto_paid_${row.refid}_${row.day_date}`}
                className="fixed-input "
                name="pto_paid"
                attr={{ disabled: disabled }}
                onSetValue={(n: string, v: string, errors: any) =>
                  this.updateTableCell(n, v, row, errors)
                }
                errors={row.errors['pto_paid']}
                value={value}
              />
            );
          }),
      },
      {
        name: 'shifts',
        noSort: true,
        title: 'Shifts',
        export: {
          target: (v: string, row: TTimeSheetCalendar) => {
            if (row.total) {
              return '';
            }
            return row.shiftList.length;
          },
        },
        render: (value: string, row: TTimeSheetCalendar) =>
          totalRender(value, row, 'shifts', () => row.shiftList.length),
      },
      {
        name: 'mileage',
        className: 'mileage-td',
        noSort: true,
        export: {
          target: (v: string, row: TTimeSheetCalendar) => {
            if (row.total) {
              return '';
            }
            return v;
          },
        },
        render: (value: string, row: TTimeSheetCalendar) =>
          totalRender(value, row, 'mileage', () => {
            const disabled = this.isDisabledRow(row);
            return (
              <Text
                className="fixed-select-size"
                key={`mileage_${row.refid}_${row.day_date}`}
                name="mileage"
                noLabel
                attr={{ disabled: disabled }}
                onSetValue={(n: string, v: string) =>
                  this.updateTableCell(n, v, row)
                }
                value={value}
              />
            );
          }),
      },
      {
        name: 'expenses',
        noSort: true,
        export: {
          target: (v: string, row: TTimeSheetCalendar) => {
            if (row.total) return '';
            return v;
          },
        },
        className: 'expenses-td',
        render: (value: string, row: TTimeSheetCalendar) =>
          totalRender(value, row, 'expenses', () => {
            const disabled = this.isDisabledRow(row);
            return (
              <div
                key={`expenses_${row.refid}_${row.day_date}`}
                className="expenses-wrapper">
                <Text
                  name="expenses"
                  attr={{ placeholder: '$', disabled: disabled }}
                  noLabel
                  onSetValue={(n: string, v: string) =>
                    this.updateTableCell(n, v, row)
                  }
                  value={value}
                  placeholder="$"
                />
                {disabled ? null : (
                  <IconButton
                    className="text-primary fs-5"
                    onClick={(event) => this.showImportFileModal(event, row)}>
                    <i className="bi bi-cloud-upload" />
                  </IconButton>
                )}
              </div>
            );
          }),
      },
    ];
  }

  validateBenefits(row: TTimeSheetCalendar, value: string) {
    if (value) {
      if (!row.benefits_type) {
        row.errors.benefits_type = [Validation.messages.required];
      }
    } else {
      row.errors.benefits_type = [];
    }
    return true;
  }

  validateBenefitsType(row: TTimeSheetCalendar, value: string) {
    if (value) {
      if (!row.benefits) {
        row.errors.benefits = [Validation.messages.required];
        row.errors.benefits_type = [];

        return true;
      }
    } else {
      if (row.benefits) {
        row.errors.benefits_type = [Validation.messages.required];
        return false;
      } else {
        row.errors.benefits = [];
      }
      row.errors.benefits_type = [];
    }
    return true;
  }

  showImportFileModal(event: any, row: TTimeSheetCalendar) {
    this.setState({ importFileIsOpen: true, selectedRow: row });
  }

  importFileCallback() {
    this.setState({ importFileIsOpen: false });
  }

  handleClickExport = () => {
    this.setState({ exporter: true });
  };

  render() {
    const { props, state } = this;
    const { dataSource } = state;
    let exportName = 'Timesheet';
    let anchorDayDate = null;
    if (state.importFileIsOpen) {
      const date = DateUtils.parseDate(
        state.selectedRow.day_date,
        state.selectedRow.day_date.indexOf('/') > -1 ? '/' : '-'
      );
      anchorDayDate = DateUtils.toDateCanonic(date.getTime().valueOf());
    }
    if (props.appId) {
      let appId = props.appId;
      let appName = UserProfileStore.findAppNameByAppId(appId);
      if (appName) {
        exportName = appName;
      }
    }
    return (
      <div className="time-sheet-calendar">
        <Grid
          dataSource={dataSource}
          minColWidth={132}
          ref="grid"
          columns={this.getColumns()}
          gridControlPanel={
            <GridControlButton
              title="Export"
              onClick={this.handleClickExport}
            />
          }
          disablePagination
          stateless
          dataSourceCount={7}
        />

        {state.importFileIsOpen ? (
          <ManageDocuments
            type="expense"
            userId={props.userId}
            anchorDayDate={anchorDayDate}
            title="Upload Expense Documents"
            callback={() => this.importFileCallback()}
          />
        ) : null}
        {state.exporter ? (
          <Exporter
            callback={(r: any) => this.setState({ exporter: false })}
            this={this}
            filters={() => this.getDataArguments()}
            reportName={exportName}
          />
        ) : null}
      </div>
    );
  }

  getDataArguments() {
    return {
      toString: () => {
        const props = this.props;
        return (
          (this.props.userName ? this.props.userName + '; ' : '') +
          'Year: ' +
          props.year +
          '; Month: ' +
          months[props.month - 1] +
          '; Week: ' +
          (props.week + 1)
        );
      },
    };
  }

  boldText(text: React.ReactNode) {
    return <strong>{text}</strong>;
  }

  sumTimes(times: Array<string>): TTimeSheetTime {
    const result = new TTimeSheetTime();
    for (let i = 0; i < times.length; i++) {
      if (times[i] !== '' && times[i] !== '00:00') {
        const timeParts = this.splitTime(times[i]);
        result.hours += +timeParts.hours;
        result.minutes += +timeParts.minutes;
      }
    }
    return result; //this.convertToTime(result);
  }

  splitTime(time: string) {
    const times = (time ? time : ':').split(':');
    return { hours: times[0], minutes: times[1] };
  }

  convertToTime(time: TTimeSheetTime) {
    time.hours += parseInt('' + time.minutes) / 60;
    time.minutes = time.minutes % 60;
  }

  timeToString(time: TTimeSheetTime): string {
    return (
      StringUtils.leftPad(time.hours, 2) +
      ':' +
      StringUtils.leftPad(time.minutes, 2)
    );
  }

  calculateHours(times: Array<string>): string {
    const hours = this.sumTimes(times);
    this.convertToTime(hours);
    return this.timeToString(hours);
  }

  calculateRegularTime(row: TTimeSheetCalendar) {
    if (row.total) {
      return 0;
    }
    let out = 0;
    row.shiftList.forEach((v) => {
      if (v.clock_end && v.clock_start) {
        out += this.parseClocks(v.clock_end) - this.parseClocks(v.clock_start);
      }
    });
    if (out > DAY_LIMIT) {
      out -= 30;
    }
    return out;
  }

  renderRegularTime(row: TTimeSheetCalendar) {
    const count = this.calculateRegularTime(row);
    if (count > 0) {
      return this.formatHours(this.calculateRegularTime(row));
    } else {
      return '';
    }
  }

  isDisabledRow(row: TTimeSheetCalendar) {
    if (row.isSubmitted === 'Y' || row.isLocked === 'Y') {
      return true;
    }
    const date = DateUtils.parseDate(row.day_date, '/');
    const month4Row = date.getMonth().valueOf() + 1;
    const month4Control = this.props.month;
    return month4Row !== month4Control;
  }
}
