import { forwardRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';
import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';

import { storeDropdownState, OptionType } from 'stores/_mobx/dropDown/state';

interface PropsType extends PAbstractDropdown {
  options: OptionType[];
  ref: any;
}

class UsaStateDropdown extends AbstractDropdown<PropsType, SAbstractDropdown> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<UsaStateDropdown, any>(
  (props: PAbstractDropdown, ref) => {
    const { fetchingUsaOption, optionsUsa } = storeDropdownState;

    useEffect(() => {
      storeDropdownState.getOptionsUsa();
    }, []);

    return (
      <UsaStateDropdown
        {...props}
        ref={ref}
        options={optionsUsa}
        fetching={fetchingUsaOption}
      />
    );
  }
);

export default observer(DropDownMiddleware);

export const DropdownUsaState = observer(
  (props: Omit<SelectPropsType, 'options'>) => {
    const { fetchingUsaOption, optionsUsa } = storeDropdownState;

    useEffect(() => {
      storeDropdownState.getOptionsUsa();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Select {...props} options={optionsUsa} isLoading={fetchingUsaOption} />
    );
  }
);
