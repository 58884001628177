import { ReduceStore } from 'flux/utils';
import appDispatcher from 'dispatcher/AppDispatcher';

export let Actions = {
  LOAD_PATIENT_LIST: 'load-patient-list',
  LOAD_FACILITY_LIST: 'load-facility-list',
};

class InvoiceReportPageStore extends ReduceStore {
  getInitialState() {
    return {
      resultList: [],
    };
  }
  reduce(state, action) {
    switch (action.type) {
      case Actions.LOAD_PATIENT_LIST:
        this.getState().resultList = action.data;
        break;
      case Actions.LOAD_FACILITY_LIST:
        this.getState().resultList = action.data;
        break;
      default:
        break;
    }
    return this.getState();
  }

  areEqual(one, two) {
    return false;
  }
}

const invoiceReportPageStore = new InvoiceReportPageStore(appDispatcher);
export default invoiceReportPageStore;
