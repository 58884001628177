import React from 'react';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import Notification from 'components/modal/Notification';
import { Grid } from 'components/grid';
import { PureSelect } from 'components/form/select';

import CodingService, {
  TFindingCpt,
  TFindingCategory,
  TCptFindingMapping,
} from 'services/billing-coding/CodingService';
import IterableUtils from 'utils/IterableUtils';

const optionsCategoryFinding = [
  { value: 'P', label: 'Positive' },
  { value: 'N', label: 'Negative' },
  { value: 'F', label: 'Formative' },
  { value: 'I', label: 'Intermediate' },
];

class TFindingData {
  refid: number = null;
  cpt_id: string = '';
  cpt_code: string = '';
  cpt_description: string = '';
  modifier_code: string = '';

  copy(copy: TFindingData): TFindingData {
    this.refid = copy.refid;
    this.cpt_id = copy.cpt_id;
    this.cpt_code = copy.cpt_code;
    this.cpt_description = copy.cpt_description;
    this.modifier_code = copy.modifier_code;
    return this;
  }
}

export class TFindingDataAndCategory extends TFindingData {
  category_type: string = '';
  category_id: string = '';
  findingCategoryList: Array<TFindingCategory> = [];

  copy(copy: TFindingData) {
    super.copy(copy);
    return this;
  }
}

interface PFindingPopup {
  order?: { refid: number };
  dataSource?: Array<TFindingData>;
  onClose: (justClose: boolean) => void;
}

class SFindingPopup {
  dataSource: Array<TFindingDataAndCategory> = [];

  constructor(props: PFindingPopup) {
    this.dataSource = [];
    for (let i = 0; i < props.dataSource.length; i++) {
      this.dataSource.push(
        new TFindingDataAndCategory().copy(props.dataSource[i])
      );
    }
  }
}

export default class FindingPopup extends React.Component<
  PFindingPopup,
  SFindingPopup
> {
  categoryType2findingCategory: Map<string, Array<TFindingCategory>> =
    new Map();

  constructor(props: PFindingPopup) {
    super(props);
    this.state = new SFindingPopup(props);
    CodingService.loadFindings(
      this.props.order.refid,
      this.state.dataSource
    ).then((findingCategories: Array<TFindingCpt>) => {
      const dataSource = IterableUtils.mergeDeep([], this.state.dataSource);

      for (let i = 0; i < findingCategories.length; i++) {
        const item = findingCategories[i];
        this.categoryType2findingCategory.set(
          item.category_type,
          item.category
        );
        dataSource[i].category_type = item.category_type;
        dataSource[i].category_id = item.category_id;
        dataSource[i].findingCategoryList = item.category;
      }
      this.setState({
        dataSource,
      });
    });
  }

  COLUMNS = [
    { name: 'cpt_code', title: 'CPT Code' },
    { name: 'cpt_description', title: 'Description' },
    { name: 'cpt_modifier', title: 'Modifier' },
    {
      name: 'finding_category',
      title: 'Finding Category',
      className: 'width-150',
      render: (v: any, data: TFindingDataAndCategory) => (
        <PureSelect
          name={`category_type_${data.refid}`}
          value={data.category_type}
          options={optionsCategoryFinding}
          onChange={(value: string) => {
            this.categoryTypeUpdate(data, value);
          }}
        />
      ),
    },
    {
      name: 'category_type',
      title: 'Category Type',
      className: 'width-300',
      render: (v: any, data: TFindingDataAndCategory) => (
        <PureSelect
          name={`category_id_${data.refid}`}
          options={data.findingCategoryList}
          value={data.category_id}
          onChange={(value: string) => this.categoryIdUpdate(data, value)}
        />
      ),
    },
  ];

  categoryTypeUpdate({ ...data }: TFindingDataAndCategory, value: string) {
    if (data.category_type === value) {
      return;
    }
    const dataSource = this.state.dataSource.map((el) =>
      el.refid === data.refid && el.cpt_id === data.cpt_id
        ? {
            ...el,
            category_type: value,
            category_id: '',
          }
        : el
    );

    data.category_type = value;
    data.category_id = '';

    // @ts-ignore
    this.setState({ dataSource }, () => this.updateCategories(data));
  }

  categoryIdUpdate(data: TFindingDataAndCategory, value: string) {
    if (data.category_id === value) {
      return;
    }
    data.category_id = value;
    const dataSource = this.copyDataSource4Update(data);
    this.setState({ dataSource });
  }

  updateCategories(data: TFindingDataAndCategory) {
    const findingCategoryList = this.getCategoryTypeFor(data);
    data.findingCategoryList = findingCategoryList;
    const dataSource = this.copyDataSource4Update(data);
    this.setState({ dataSource });
  }

  getCategoryTypeFor(data: TFindingDataAndCategory): Array<TFindingCategory> {
    const { category_type } = data;

    const res: Array<TFindingCategory> =
      this.categoryType2findingCategory.get(category_type);
    if (res?.length) {
      return res;
    }
    CodingService.loadFindingCategory(category_type).then(
      (findingCategoryList: Array<TFindingCategory>) => {
        this.categoryType2findingCategory.set(
          category_type,
          findingCategoryList
        );
        data.findingCategoryList = findingCategoryList;
        const dataSource = this.copyDataSource4Update(data);
        this.setState({
          dataSource,
        });
      }
    );
    return [];
  }

  copyDataSource4Update(data: TFindingData): Array<TFindingDataAndCategory> {
    const dataSource = IterableUtils.mergeDeep([], this.state.dataSource);
    for (let i = 0; i < dataSource.length; i++) {
      if (
        dataSource[i].refid === data.refid &&
        dataSource[i].cpt_id === data.cpt_id
      ) {
        dataSource[i] = { ...data };
      }
    }
    return dataSource;
  }

  handleClose = () => {
    this.props.onClose(false);
  };

  onPositive = () => {
    CodingService.saveFindingMapping(
      this.props.order.refid,
      this.state.dataSource.map((data: TFindingDataAndCategory) => {
        const res = new TCptFindingMapping();
        res.cpt_id = data.cpt_id;
        res.category_type = data.category_type;
        res.modifier = data.modifier_code;
        res.category_id = data.category_id;
        return res;
      })
    ).then(() => {
      this.submitSuccess();
    });
  };

  submitSuccess() {
    Notification.success('Details Successfully Added');
    this.props.onClose(true);
  }

  render() {
    const { state } = this;
    return (
      <Dialog scrollable={false} size="large" handleClose={this.handleClose}>
        <DialogHeader
          title="Finding - CPT Mapping"
          onClose={this.handleClose}
        />
        <DialogBody>
          <Grid
            dataSource={state.dataSource}
            columns={this.COLUMNS}
            stateless
            disablePagination
            wrapperClass=" "
          />
        </DialogBody>

        <DialogFooter>
          <Button onClick={this.onPositive} text="Save" />
        </DialogFooter>
      </Dialog>
    );
  }
}
