import React from 'react';
import { Container } from 'flux/utils';

import PhysicianData from 'page/billing-coding/components/provider-update/PhysicianData';
import PhysicianView from 'page/billing-coding/components/provider-update/PhysicianView';
import Notification from 'components/modal/Notification';
import Form from 'components/form/Form';
import Fieldset from 'components/form/Fieldset';
import Text from 'components/form/input/Text';
import Custom from 'components/form/input/Custom';
import { Button } from 'components/button';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';

import OrganizationStore from 'stores/project/OrganizationStore';
import ClaimStore from 'stores/billing-coding/claims/ClaimStore';
import ClaimActions from 'actions/billing-coding/claims/ClaimActions';
import PhysicianActions from 'actions/project/PhysicianActions';
import { TOrderDetails } from 'services/billing-coding/claims/ClaimsService';

export class TOrder4ProviderUpdate {
  refid: number = null;
  last_name: string = '';
  first_name: string = '';
  dob: string = '';
  dos: string = '';
  modality: string = '';
  facility_name: string = '';
}

export interface PProviderUpdate {
  claimUniqId: string;
  order: TOrder4ProviderUpdate;
  onClose: () => void;
  onUpdate: () => void;
}

export class SProviderUpdate {
  model: TOrderDetails = new TOrderDetails();
  errors: any = {};
  errorMessages: any = {};
  loaded: boolean = false;
  orderingPecos: boolean = false;
  renderingPecos: boolean = false;
  billingPecos: boolean = false;
}

const cn = 'col-sm-4';
const cn1 = 'row col-sm-6';

class ProviderUpdate extends React.Component<PProviderUpdate, SProviderUpdate> {
  static getStores() {
    return [OrganizationStore, ClaimStore];
  }

  static calculateState(prevState: SProviderUpdate, props: PProviderUpdate) {
    if (!prevState) {
      prevState = Object.assign(new SProviderUpdate(), {
        model: new TOrderDetails(),
        errors: {},
        loaded: false,
        orderingPecos: false,
        renderingPecos: false,
        billingPecos: false,
      });
    }
    const store = ClaimStore.getState();
    return {
      ...prevState,
      model:
        store.orderDetailsLoaded && !prevState.loaded
          ? store.orderDetails
          : prevState.model,
      loaded: store.orderDetailsLoaded,
    };
  }

  submitBtnRef = React.createRef<HTMLButtonElement>();

  componentDidMount() {
    ClaimActions.getOrderDetails(this.props.claimUniqId);
  }

  componentWillUnmount() {
    ClaimActions.clearOrderDetails();
  }

  handleClickSubmit = () => {
    this.submitBtnRef.current.click();
  };

  render() {
    const { state } = this;
    const { order } = this.props;

    const orderingPhysician = this.prepareOrderingData();
    const renderingPhysician = this.prepareRenderingData();

    return (
      <Dialog size="extraLarge" handleClose={this.props.onClose}>
        <DialogHeader title="Provider Update" onClose={this.props.onClose} />
        <DialogBody>
          <Form
            ref="form"
            model={state.model}
            errors={state.errors}
            className="row"
            errorMessages={state.errorMessages}
            onCollectValues={this.onUpdateModel.bind(this)}
            submit={this.submit.bind(this)}>
            <Custom name="lastName" custom={order.last_name} className={cn} />
            <Custom name="dob" label="DOB" custom={order.dob} className={cn} />
            <Custom name="dos" label="DOS" custom={order.dos} className={cn} />

            <Custom name="firstName" custom={order.first_name} className={cn} />
            <Custom name="modality" custom={order.modality} className={cn} />
            <Custom
              name="facility"
              custom={order.facility_name}
              className={cn}
            />

            <hr />

            <Fieldset className="row align-items-start">
              <h3 className="title">Ordering</h3>
              <PhysicianData
                className={cn1}
                formModel={orderingPhysician}
                stateAsLabel
                id="ordering"
                validations={{ npi: 'required' }}
                formErrors={state.errors.ordering}
                onSetValue={(n: any, v: any, e: any) =>
                  this.setOrdering(n, v, e)
                }
              />
              <PhysicianView className={cn1} formModel={orderingPhysician} />
            </Fieldset>
            <hr />

            <Fieldset className="row align-items-start">
              <h3 className="title">Rendering</h3>

              <PhysicianData
                className={cn1}
                formModel={renderingPhysician}
                stateAsLabel
                id="rendering"
                npiRequired={false}
                onSetValue={(n: any, v: any, e: any) =>
                  this.setRendering(n, v, e)
                }
              />
              <Text
                name="provider_name"
                label="Provider"
                className="col-sm-6"
              />
              <button
                type="submit"
                className="d-none"
                ref={this.submitBtnRef}
              />
            </Fieldset>
          </Form>
        </DialogBody>
        <DialogFooter>
          <Button text="Close" variant="warning" onClick={this.props.onClose} />
          <Button text="Update" onClick={this.handleClickSubmit} />
        </DialogFooter>
      </Dialog>
    );
  }

  setRendering(name: any, value: any, errors: any) {
    let additionalValues = null;
    switch (name) {
      case 'npi':
        name = 'provider_npi';
        break;
      case 'rendering_pecos':
        additionalValues = { renderingPecos: true };
        break;
      default:
        console.log('unknown name');
        return;
    }
    this.onUpdateModel(name, value, errors, additionalValues);
  }

  submit(model: any, hasErrors: any, errors: any) {
    const err: any = {
      ordering: {},
    };
    if (hasErrors) {
      for (let key in errors) {
        if (errors.hasOwnProperty(key) && key !== 'npi') {
          if (errors[key].length) {
            err[key] = errors[key];
          }
        }
      }
      if (errors.npi) {
        err.ordering.npi = errors.npi;
      }
      this.setState({ errors: err });
    } else {
      this.setState({ errors: err }, () => this.submitSuccess(model));
    }
  }

  setBilling(name: any, value: any, errors: any) {
    let additionalValues = null;
    switch (name) {
      case 'npi':
        name = 'billing_npi';
        break;
      case 'state':
        name = 'billing_state';
        break;
      case 'billing_pecos':
        additionalValues = { billingPecos: true };
        break;
      default:
        console.log('unknown name');
        return;
    }
    this.onUpdateModel(name, value, errors, additionalValues);
  }

  prepareRenderingData() {
    const { model } = this.state;

    return {
      npi: model.provider_npi,
      pecos: model.radiologists[0]?.rendering_pecos || '',
      state: model.short_state,
    };
  }

  setOrdering(name: any, value: any, errors: any) {
    let additionalValues = null;
    switch (name) {
      case 'npi':
        name = 'ordering_physician_npi';
        break;
      case 'ordering_pecos':
        additionalValues = { orderingPecos: true };
        break;
      default:
        console.log('unknown name');
        return;
    }
    this.onUpdateModel(name, value, errors, additionalValues, 'ordering');
  }

  prepareOrderingData() {
    const model = this.state.model;
    return {
      firstName: model.ordering_physician_fname,
      lastName: model.ordering_physician_lname,
      validityDate: model.ordering_validity_date,
      npi: model.ordering_physician_npi,
      pecos: model.ordering_pecos,
      state: model.ordering_state,
    };
  }

  onUpdateModel(
    name: string,
    value: any,
    errors: any,
    additionalValues?: any,
    fieldsetName?: any
  ) {
    const model = { ...this.state.model };

    const err = { ...this.state.errors };

    let errorsHolder: any;
    if (fieldsetName) {
      if (!err[fieldsetName]) {
        err[fieldsetName] = [];
      }
      errorsHolder = err[fieldsetName];
    } else {
      errorsHolder = err;
    }

    if (name === 'rendering_pecos') {
      // @ts-ignore
      model.radiologists = model.radiologists[0]
        ? model.radiologists.map((el) => ({
            ...el,
            rendering_pecos: value,
          }))
        : [{ [name]: value }];
    } else {
      // @ts-ignore
      model[name] = value;
    }

    errorsHolder[name] = errors;

    let state = { model, errors: err };

    if (additionalValues) {
      state = Object.assign(state, additionalValues);
    }
    this.setState(state);
  }

  submitSuccess(modelIn?: any) {
    const model = this.state.model;
    PhysicianActions.checkNpi([
      model.ordering_physician_npi /*,
                model.provider_npi,
                model.billing_npi*/,
    ]).then((response: Array<{ refid: number; npi: string }>) => {
      const npiValidation = this.isValidNpi(response);
      if (npiValidation.isValid) {
        ClaimActions.updateClaimDoctorDetails(
          this.state.orderingPecos ? 1 : 0,
          this.state.renderingPecos ? 1 : 0,
          this.state.billingPecos ? 1 : 0,
          model.ordering_pecos === 'Y' ? 'Y' : 'N',
          model.radiologists[0]?.rendering_pecos || 'N',
          model.billing_pecos === 'Y' ? 'Y' : 'N',
          model.ordering_physician_npi,
          model.provider_npi,
          model.billing_npi,
          model.ordering_state,
          model.short_state,
          model.billing_state,
          model.ordering_physician_id,
          // @ts-ignore
          model.radiologists[0]?.radiologistid || '',
          0,
          {
            j_npi_nm: model.provider_name,
            j_modality: model.modality,
            j_jurid: model.juridictionid,
            j_refid: model.j_refid,
          }
        ).then((r) => {
          if (model.j_refid > 1) {
            Notification.success(
              'Provider details has been updated successfully'
            );
          } else {
            Notification.success(
              'Given ordering NPI updated but rendering NPI will not be saved,' +
                ' please select the billing jurisdiction for this facility to save the rendering NPI',
              { autoClose: 30000 }
            );
          }
          this.props.onUpdate();
        });
      } else {
        const errors = Object.assign({}, this.state.errors);
        if (!errors.ordering) {
          errors.ordering = {};
        }
        const message = 'NPI is not unique';
        if (!errors.ordering.npi) {
          errors.ordering.npi = [message];
        } else if (errors.ordering.npi.indexOf(message) === -1) {
          errors.ordering.npi.push(message);
        }
        this.setState({ errors }, () =>
          Notification.danger(npiValidation.messages.join(';'))
        );
      }
    });
  }

  isValidNpi(data: Array<{ refid: number; npi: string }>) {
    const model = this.state.model;
    const out: {
      isValid: boolean;
      messages: Array<string>;
    } = {
      isValid: true,
      messages: [],
    };
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (
        item.refid !== model.ordering_physician_id &&
        model.ordering_physician_npi === item.npi
      ) {
        out.isValid = false;
        const message = 'Provider NPI is not unique';
        if (out.messages.indexOf(message) === -1) {
          out.messages.push(message);
        }
      }
    }
    return out;
  }
}

export default Container.create(ProviderUpdate, { withProps: true });
