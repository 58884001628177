import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from 'mobx';

import Notification from 'components/modal/Notification';
import { apiRequest } from 'services/RequestService';

const defaultSettings = {
  description: '',
  no_of_days: 0,
};

interface ArchiveType {
  description: string;
  no_of_days: number;
}

export interface ArchiveExtendedType extends ArchiveType {
  type: 'P' | 'C';
}

class MessagesArchive {
  archiveSettings: [ArchiveType | null, ArchiveType | null] = [null, null];
  fetching: boolean = false;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      archiveSettings: observable,
      companySettings: computed,
      clientSettings: computed,

      setFetching: action,
    });
  }

  get companySettings(): ArchiveExtendedType {
    return { ...(this.archiveSettings[0] || defaultSettings), type: 'P' };
  }

  get clientSettings(): ArchiveExtendedType {
    return { ...(this.archiveSettings[1] || defaultSettings), type: 'C' };
  }

  setFetching = (state: boolean) => {
    this.fetching = state;
  };

  getArchiveSettings = async (signal?: AbortSignal) => {
    this.setFetching(true);
    try {
      const response = await apiRequest<[ArchiveType, ArchiveType]>({
        url: 'generalmaster.ArchiveDay.ListArchiveDay',
        signal,
      });

      runInAction(() => {
        this.archiveSettings = response;
        this.fetching = false;
      });
    } catch (error) {
      this.setFetching(false);
    }
  };

  saveArchiveSettings = async ({
    no_of_days,
    description,
    type,
  }: ArchiveExtendedType) => {
    const data = [no_of_days, description, type];
    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.ArchiveDay.AddArchiveDay',
        data,
      });
      if (response === 'S') {
        Notification.success('Successfully saved!');
        return true;
      } else throw Error('An error occurred!');
    } catch (error) {
      Notification.danger('An error occurred!');
      return false;
    }
  };
}

export const storeMessagesArchive = new MessagesArchive();
