import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import resolver from './validation';

import {
  RegionType,
  initialValues,
} from 'stores/_mobx/systemSetup/masterSetting/region';
import { ServerFormValidationType } from 'types';

interface PropsType {
  defaultValues?: RegionType;
  onSubmit: (data: RegionType) => Promise<any>;
}

const FormRegion = ({ defaultValues = initialValues, onSubmit }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm<RegionType>({ defaultValues, resolver });

  const cn = `col-md-10 col-lg-8 col-xl-6${isSubmitting ? ' on-ajax' : ''}`;

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: RegionType) =>
    onSubmit(data).then((errors: ServerFormValidationType<RegionType>[]) => {
      if (errors) {
        errors.forEach(({ field, ...body }) => {
          setError(field, body);
        });
      }
    })
  );

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form className={cn} onSubmit={handleClickSubmit}>
      <Input name="region" label="Region" required control={control} />

      <ControlsLayout alignX="right">
        <Button
          variant="warning"
          text="Reset"
          disabled={!isDirty || isSubmitting}
          onClick={handleReset}
        />
        <Button
          text="Submit"
          disabled={!isDirty || isSubmitting}
          onClick={handleClickSubmit}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormRegion;
