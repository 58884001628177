import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validation = yupResolver(
  yup.object().shape({
    name: yup.string().required('Please, enter the User Type Name!'),
    description: yup.string().required('Please, enter a Description!'),
    categoryId: yup
      .number()
      .typeError('Please, select a Category!')
      .positive('Please, select a Category!'),
    privilegeId: yup
      .string()
      .typeError('Please, select a Privilege Equivalence!')
      .required('Please, select a Privilege Equivalence!'),
    logoutTime: yup
      .number()
      .typeError('Please, enter logout interval')
      .positive('Must be more then 0'),
  })
);

export default validation;
