import { observable, action, makeObservable, computed } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import CsvExporter from 'utils/CsvExporter';

const allowedCols = [
  'in_date',
  'in_time',
  'out_time',
  'over_time',
  'pto',
  'holiday',
  'other',
  'pto_other',
  'lunch_start',
  'lunch_end',
  'mileage',
  'expenses',
];

export const defaultValueFilter: FilterType = {
  dateFrom: '',
  dateTo: '',
  userTypeId: 0,
  userId: 0,
};

export interface FilterType {
  dateFrom: string;
  dateTo: string;
  userTypeId: number;
  userId: number;
}

interface ChartDataType {
  amt: number;
  lbl: string;
}

interface ExpenseType {
  UserName: string;
  callhour: string;
  expenses: number;
  facilityName: string;
  first_name: string;
  last_name: string;
  user_id: number;
  usertype_name: string;
}

interface ExpenseResponseType {
  chartval: ChartDataType[];
  dataval: ExpenseType[];
}

interface ExpenseDetailType {
  call_hour: string;
  expenses: number;
  holiday: string;
  in_date: string;
  mileage: number;
  over_time: string;
  pto: string;
  regular_time: string;
}

class UserExpenseReport {
  fetching: boolean = false;
  fetchingDetail: boolean = false;
  expenseDetailList: ExpenseDetailType[] = [];
  expenseDetailTotal: number = 0;
  chartData: [string, string | number][] = [['Action type', 'Amount']];
  logList: ExpenseType[] = [];
  logTotal: number = 0;
  userIdForDetails: number = 0;
  filter: FilterType = defaultValueFilter;

  page: Pagination;
  pageDetail: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'userExpenseGrid' });
    this.pageDetail = new Pagination({ id: 'viewModalLogGrid' });

    makeObservable(this, {
      fetching: observable,
      fetchingDetail: observable,
      expenseDetailList: observable,
      expenseDetailTotal: observable,
      chartData: observable,
      logList: observable,
      logTotal: observable,
      userIdForDetails: observable,
      filter: observable,

      expenseDetailTitle: computed,

      setFetching: action,
      setFetchingDetail: action,
      setExpenseDetailList: action,
      setExpenseDetailTotal: action,
      setChartData: action,
      setLogList: action,
      setLogTotal: action,
      setUserIdForDetail: action,
      clearUserIdForDetail: action.bound,
      setFilter: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingDetail(fetching: boolean) {
    this.fetchingDetail = fetching;
  }

  setExpenseDetailList(list: ExpenseDetailType[]) {
    this.expenseDetailList = list;
  }

  setExpenseDetailTotal(count: number) {
    this.expenseDetailTotal = count;
  }

  setChartData(chartData: [string, string | number][]) {
    this.chartData = chartData;
  }

  setLogList(logs: ExpenseType[]) {
    this.logList = logs;
  }

  setLogTotal(count: number) {
    this.logTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setUserIdForDetail(id: number) {
    this.userIdForDetails = id;
  }

  clearUserIdForDetail() {
    this.userIdForDetails = 0;
    this.expenseDetailList = [];
    this.expenseDetailTotal = 0;
    this.pageDetail.setPagination({
      ...this.pageDetail.pagination,
      page: 1,
      skip: 0,
    });
  }

  get expenseDetailTitle() {
    const { userIdForDetails, logList } = this;

    if (!userIdForDetails) return '';

    const expense = logList.find(({ user_id }) => userIdForDetails === user_id);

    return expense
      ? `${expense.first_name} ${expense.last_name}`.trim()
      : 'User Expense Details';
  }

  async getLogCount() {
    const { filter } = this;

    const data = [
      filter.dateFrom,
      filter.dateTo,
      filter.userTypeId || '',
      filter.userId,
    ];

    try {
      const count = await apiRequest<number>({
        url: 'filemanager.FileManager.GetExpenseReportCount',
        data,
      });

      this.setLogTotal(count);
    } catch (e: any) {
      this.setLogTotal(0);
    }
  }

  async getLogList() {
    const {
      filter,
      page: { pagination },
    } = this;

    const data = [
      filter.dateFrom,
      filter.dateTo,
      filter.userTypeId || '',
      filter.userId,
      pagination.skip,
      pagination.pageSize,
    ];

    try {
      const { dataval, chartval } = await apiRequest<ExpenseResponseType>({
        url: 'filemanager.FileManager.GetExpenseReport',
        data,
      });

      const logList = dataval.map((userData) => ({
        ...userData,
        user_id: Number(userData.user_id),
      }));

      const chartData = chartval.reduce(
        (prev: [string, number | string][], { lbl, amt }) =>
          prev.concat([[lbl, amt]]),
        [['Action type', 'Amount']]
      );

      this.setChartData(chartData);
      this.setLogList(logList);
    } catch (e: any) {
      this.setChartData([['Action type', 'Amount']]);
      this.setLogList([]);
    }
  }

  async getLogsMain() {
    this.setFetching(true);

    const promiseList = this.getLogCount();

    const promiseCount = this.getLogList();

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  }

  async getExpenseDetailCount() {
    const { userIdForDetails } = this;

    try {
      const count = await apiRequest<number>({
        url: 'filemanager.FileManager.GetExpenseViewCount',
        data: [userIdForDetails],
      });

      this.setExpenseDetailTotal(count);
    } catch (e: any) {
      this.setExpenseDetailTotal(0);
    }
  }

  async getExpenseDetailList() {
    const {
      userIdForDetails,
      pageDetail: { pagination },
    } = this;

    const data = [userIdForDetails, pagination.skip, pagination.pageSize];

    try {
      const detailsList = await apiRequest<ExpenseDetailType[]>({
        url: 'filemanager.FileManager.GetExpenseViewList',
        data,
      });

      this.setExpenseDetailList(detailsList);
    } catch (e: any) {
      this.setExpenseDetailList([]);
    }
  }

  async getExpenseDetailMain() {
    this.setFetchingDetail(true);

    const promiseList = this.getExpenseDetailList();

    const promiseCount = this.getExpenseDetailCount();

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetchingDetail(false);
    });
  }

  exportExpenseDetailToCsv = (
    columns: { name: string; title: string }[],
    title: string
  ) => {
    const filteredColumns = columns.filter(({ name }) =>
      allowedCols.includes(name)
    );
    const csvExporter = new CsvExporter(
      this.expenseDetailList,
      filteredColumns
    );

    csvExporter.exportAndDownload(title);
  };

  exportToPdf = async ({ filter }: { filter: string }) => {
    this.setFetching(true);
    try {
      const fileName = await apiRequest<string>({
        url: 'pdfcreater.OrderPdfGenerator.GeneratePDFforExpense',
        data: [this.logList, filter],
      });
      return fileName.replace(/^\//, '');
    } catch (e: any) {
      return '';
    } finally {
      this.setFetching(false);
    }
  };
}

export const storeUserExpenseReport = new UserExpenseReport();
