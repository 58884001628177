import { RouteComponentProps } from 'react-router';

import Title from 'components/project/common/title';
import FormXReyUnit, { FormModel } from './components/Form';

import { storeXRayUnit } from 'stores/_mobx/workflow/equipmentInventory/xRayUnit';
import { URL_XRAY_UNIT } from 'constant/path/workflow';

interface PropsType extends RouteComponentProps {}

const XRayUnitAddPage = ({ history }: PropsType) => {
  const handleSubmit = (data: FormModel) =>
    storeXRayUnit.addXRayUnit(data).then((response) => {
      if (response) {
        history.push(URL_XRAY_UNIT);
      }
    });

  return (
    <>
      <Title title="Add X-Ray Unit" />
      <FormXReyUnit onSubmit={handleSubmit} backUrl={URL_XRAY_UNIT} />
    </>
  );
};

export default XRayUnitAddPage;
