import * as yup from 'yup';

interface PropsType {
  minLength: number;
  maxLength: number;
  alphaNumeric: boolean;
}

const validation = (props: PropsType) =>
  yup.object().shape({
    password: yup
      .string()
      .required('Please specify password')
      .min(
        props.minLength,
        `Password length must be between ${props.minLength} and ${props.maxLength}`
      )
      .max(
        props.maxLength,
        `Password length must be between ${props.minLength} and ${props.maxLength}`
      )
      .test({
        test: (password: string) => {
          return props.alphaNumeric
            ? /(\d[a-z])|([a-z]\d)/i.test(password)
            : true;
        },
        message: 'Password should include at least 1 letter and 1 number',
      }),
    repeatPassword: yup
      .string()
      .required('Please repeat password')
      .oneOf([yup.ref('password'), null], 'Password did not match'),
  });

export default validation;
