import DropdownPureAdjustmentCode from 'components/project/dropdown/AdjustmentCode';
import RadioOptions from 'components/project/common/RadioOptions';
import { PureSelect } from 'components/form/select';
import { PureInput } from 'components/form/textField';
import { PureTextarea } from 'components/form/textarea';
import { IconButton } from 'components/button';

import { moneyRender } from 'utils/StringUtils';

export interface CellFieldPayload {
  name: string;
  value: string;
  invoiceIndex: number;
}

interface CellPropTypes {
  name: string;
  defaultValue: string;
  invoiceIndex: number;
  errorMessage?: string;
  onChange: (payload: CellFieldPayload) => void;
}

interface PropsType {
  postType: 'L' | 'B' | null;
  dataSourceErrors: Record<string, string>;
  onUpdateInvoice: (payload: CellFieldPayload) => void;
  onDisplayLog: (orderId: number) => void;
}

const CellInput = ({
  name,
  defaultValue,
  invoiceIndex,
  onChange,
}: CellPropTypes) => (
  <PureInput
    className="width-150"
    name={`${name}[${invoiceIndex}]`}
    value={defaultValue}
    isChildrenPrefix
    onChange={(value) => {
      onChange({
        name,
        value: /^-?\d*(\.\d{0,2})?$/.test(value) ? value : defaultValue,
        invoiceIndex,
      });
    }}
    onBlur={(value) => {
      onChange({
        name,
        value: Number(value).toFixed(2),
        invoiceIndex,
      });
    }}>
    <span className="input-group-text">$</span>
  </PureInput>
);

const CellTextarea = ({
  name,
  defaultValue,
  invoiceIndex,
  errorMessage,
  onChange,
}: CellPropTypes) => (
  <div className="width-150">
    <PureTextarea
      rows={2}
      resize={false}
      name={`${name}[${invoiceIndex}]`}
      defaultValue={defaultValue}
      errorMessage={errorMessage}
      onBlur={(value) => {
        onChange({
          name,
          value,
          invoiceIndex,
        });
      }}
    />
  </div>
);

const getColumns = ({
  postType,
  dataSourceErrors,
  onUpdateInvoice,
  onDisplayLog,
}: PropsType) => {
  const basicColumns = [
    {
      name: 'patientname',
      title: 'Patient Details',
      className: 'width-150',
      maxWidth: 150,
    },
    {
      name: 'modifiedOrderId',
      title: 'Invoice #',
      className: 'width-100',
      maxWidth: 100,
      groupRender: (data: any, a: any) => {
        const [orderId, hasLog] = data.split('_');

        return (
          <>
            <IconButton
              onClick={() => {
                onDisplayLog(Number(orderId));
              }}>
              <i
                className={`bi bi-envelope${
                  hasLog === '1' ? '-check-fill' : ''
                } text-primary`}
              />
            </IconButton>
            <span>{orderId}</span>
          </>
        );
      },
    },
    { name: 'cpt_code_val', title: 'CPT Code' },
    { name: 'cpt_desc', title: 'CPT Description' },
    { name: 'modifier', title: 'Modifier' },
    {
      name: 'fee_schdule',
      title: 'Fee Schedule',
      render: (price: string) => (
        <span className="no-wrap">{moneyRender(price)}</span>
      ),
    },
    {
      name: 'discount',
      title: 'Discount',
      render: (price: string) => (
        <span className="no-wrap">{moneyRender(price)}</span>
      ),
    },
    {
      name: 'cpt_price_val',
      title: 'Charge',
      render: (price: string) => (
        <span className="no-wrap">{moneyRender(price)}</span>
      ),
    },
  ];

  const finalColumns =
    postType === 'L'
      ? [
          ...basicColumns,
          {
            name: 'payment',
            title: 'Payment',
            render: (price: string, data: any) =>
              price === undefined ? null : (
                <CellInput
                  name="payment"
                  defaultValue={price}
                  invoiceIndex={data.dsIndex}
                  onChange={onUpdateInvoice}
                />
              ),
          },
          {
            name: 'writeoff',
            title: 'Write-off',
            render: (price: string, data: any) =>
              price === undefined ? null : (
                <CellInput
                  name="writeoff"
                  defaultValue={price}
                  invoiceIndex={data.dsIndex}
                  onChange={onUpdateInvoice}
                />
              ),
          },
          {
            name: 'pay_mode',
            title: 'Mode Of Payment',
            className: 'width-200 text-nowrap',
            maxWidth: 200,
            render: (payMode: number | undefined, data: any) => {
              if (payMode === undefined) return null;
              return (
                <PureSelect
                  name={`pay_mode[${data.dsIndex}]`}
                  options={RadioOptions.modeOfPayment}
                  value={payMode}
                  required
                  className="width-150"
                  onChange={(value) =>
                    onUpdateInvoice({
                      name: 'pay_mode',
                      value,
                      invoiceIndex: data.dsIndex,
                    })
                  }
                />
              );
            },
          },
          {
            name: 'pay_desc',
            title: 'Notes',
            className: 'width-200',
            maxWidth: 200,
            render: (description: string, data: any) => {
              const errorMessage =
                dataSourceErrors[`${data.studyId}_${data.cpt_code_val}`] || '';

              return description === undefined ? null : (
                <CellTextarea
                  name="pay_desc"
                  defaultValue={description}
                  invoiceIndex={data.dsIndex}
                  errorMessage={errorMessage}
                  onChange={onUpdateInvoice}
                />
              );
            },
          },
          {
            name: 'adjustment_code',
            title: 'Adjustment',
            className: 'width-200',
            minWidth: 200,
            render: (code: number | undefined, data: any) => {
              if (code === undefined) return null;

              return (
                <DropdownPureAdjustmentCode
                  className="width-200"
                  name={`adjustment_code[${data.dsIndex}]`}
                  value={code}
                  onChange={(value) =>
                    onUpdateInvoice({
                      name: 'adjustment_code',
                      value,
                      invoiceIndex: data.dsIndex,
                    })
                  }
                />
              );
            },
          },
          {
            name: 'balance',
            title: 'Balance',
            render: (price: undefined, data: any) => {
              if (data.cpt_price_val === undefined) {
                return null;
              }
              const balance =
                data.cpt_price_val -
                Number(data.payment) +
                Number(data.writeoff);

              return (
                <span
                  className={
                    balance > 0
                      ? 'text-danger'
                      : balance < 0
                      ? 'color-green'
                      : ''
                  }>
                  {`$${balance.toFixed(2)}`}
                </span>
              );
            },
          },
        ]
      : basicColumns;

  return finalColumns;
};

export default getColumns;
