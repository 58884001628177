import { apiRequest } from 'services/RequestService';

export class TInvoiceItemByFacilityBillPayment {
  refid: number = null;
  hasLog: boolean = false;
  facilityId: number = null;
  aging: string = '';
  lateFee: string = '';
  lateFeePaid: string = '';
  pay_type: string = '';
  facilitynm: string = '';
  invoice_no: string = '';
  invoice_alphanum: string = '';
  invoice_upd_count: string = '';
  invoice_dt: string = '';
  perdiemTotal: string = '';
  total_price: string = '';
  address1: string = '';
  phone: string = '';
  balance: string = '';
  total_paid: string = '';
  overpaid: string = '';
  discount: string = '';
  bill_email: string = '';
  facility_npi: string = '';
  writeoff: string = '';
  paid: string = '';
  ownername: string = '';
  pos: string = '';
  status: 'CANCELED' | 'PAID' | string = '';
}

export class TOrderNewPayItem {
  invoice_id: number = null;
  mode: 'A' | 'E' | string = '';
  patientFirst: string = '';
  patientLast: string = '';
  patientname: string = '';
  birthdate: string = '';
  facilityid: number = null;
  studyId: number = null;
  orderId: number = null;
  pps_flag: string = '';
  mco_flag: string = '';
  arranged_flag: string = '';
  fee_schdule: string = '';
  discount: string = '';
  modifier: string = '';
  cpt_desc: string = '';
  dx_code: string = '';
  dx_desc: string = '';
  cpt_code_val: string = '';
  dos: string = '';
  referalname: string = '';
  units: string = '';
  adjustment: string = '';
  payment: string = '';
  writeoff: string = '';
  adjustment_code: number = null;
  pay_mode: number = null;
  examType: string = '';
  pay_desc: string = '';
  total_payment: string = '';
  notes: string = '';
  lateFee: number = null;
  lateFeePaid: number = null;
  cpt_price_val: string = '';
  bal: string = '';
  linepost_balance: string = '';
  payment_view: boolean = false;
  writeoff_view: boolean = false;
  adjustment_view: boolean = false;
  //
  refid: number = null;
  balance: string = '';
  totcpt_price: string = '';
  over_paid: string = '';
  edit: boolean = false;
  hasLog: boolean = false;
}

export class TCptBulkPayment {
  refid: number = null;
  cpt_price_val: number = null;
  studyId: number = null;
  code_type: string = '';
  cpt_code_val: string = '';
  cpt_desc: string = '';
  fee_schdule: string = '';
  discount: string = '';
  modifier: string = '';
  invoice_id: number = null;
}

export class TPdDetail {
  writeoff: string = '';
  payment: string = '';
  adjcode: string = '';
  total: string = '';
}

export class TCptFacilityPayment {
  refid: number = null;
  cpt_price_val: number = null;
  payment: number = null;
  writeoff: number = null;
  pay_mode: string = '';
  adjustment_code: string = '';
  pay_desc: string = '';
  pps_flag: string = '';
  mco_flag: string = '';
  arranged_flag: string = '';
  studyId: number = null;
  cpt_code_val: string = '';
  cpt_desc: string = '';
  dx_code: string = '';
  fee_schdule: string = '';
  discount: string = '';
  modifier: string = '';
  invoice_id: number = null;
}

export class TAddress {
  address1: string = '';
  city1: string = '';
  state1: string = '';
  zipcode1: string = '';
  address2: string = '';
  city2: string = '';
  state2: string = '';
  zipcode2: string = '';
}

class InvoicesService {
  orderNewPayList(invoiceNo: number, facilityId: number, flag: 'P' | string) {
    const data = { invoiceNo, facilityId, flag };

    return apiRequest<Array<TOrderNewPayItem>>({
      url: 'facility.FacilityBilling.OrderNewPayList',
      data,
    });
  }

  saveInvoiceAddress(
    facilityId: number,
    address1: string,
    address2: string,
    city1: string,
    city2: string,
    state1: string,
    state2: string,
    zipcode1: string,
    zipcode2: string,
    addressflag: 'F' | 'C' | 'M' | 'O' | '',
    invoiceno: number
  ) {
    const data = {
      facilityId,
      address1,
      address2,
      city1,
      city2,
      state1,
      state2,
      zipcode1,
      zipcode2,
      addressflag,
      invoiceno,
    };

    return apiRequest<'SE' | 'S'>({
      url: 'facility.FacilityBilling.Saveinvoiceaddress',
      data,
    });
  }
}

export default new InvoicesService();
