export default class ConstantBuilder {
  static build(prefix: string, obj: any) {
    if (!(obj instanceof Object && !Array.isArray(obj))) {
      throw new Error('build(...): Argument must be an object.');
    }
    const r: any = {};
    for (let key in obj) {
      r[key] = prefix + key.toLowerCase().replace(/_/g, '-');
    }
    return r;
  }
}
