import React, { useMemo, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import clsx from 'clsx';

import { IconButton } from 'components/button';
import Custom from 'components/form/input/Custom';
import Validation from 'components/form/Validation';
import AbstractInput, {
  PAbstractInput,
  SAbstractInput,
} from 'components/form/input/AbstractInput';
import InputState from 'components/form/InputState';

import { BASE_URL_FILE_DIR } from 'constant/config';
import { apiFileRequest } from 'services/RequestService';

const styles = {
  nonVisible: {
    padding: 0,
    margin: 0,
    height: 0,
    width: 0,
    border: 'none',
  },
};

interface PFileUpload extends PAbstractInput {
  action?: string;
  labelClassName?: string;
  buttonLabel?: string;
  defaultFileName?: string;
  noWarning?: boolean;
  accept?: string;
  onDelete?: ((event?: React.MouseEvent) => void) | boolean;
  path?: string;
  hideFileName?: boolean;
  fileName?: string;
  errorMessage?: string;
  disabled?: boolean;
}

interface StateType extends SAbstractInput {
  isUploading: boolean;
}

export default class FileUpload extends AbstractInput<PFileUpload, StateType> {
  inputRef = React.createRef<HTMLInputElement>();

  id = this.props.id || window.performance.now().toString(36);

  state = {
    isUploading: false,
    id: this.id,
  };

  getValue() {
    return this.props.value;
  }

  getInputState(value: string): InputState {
    const errorKeys: any[] = Validation.validate(value, this.props.validations);
    const errorMessages = AbstractInput.findErrorMessage(errorKeys, null, null);
    return new InputState(value, errorKeys, errorMessages);
  }

  buildFileNameLabel(): React.ReactElement {
    const { value, onDelete, path, hideFileName, disabled, fileName } =
      this.props;
    if (value) {
      const onDeleteComponent = onDelete ? (
        <IconButton
          className="text-primary"
          disabled={disabled}
          onClick={this.onDelete}>
          <i className="bi bi-trash" />
        </IconButton>
      ) : null;

      const onViewComponent = path ? (
        <a
          href={this.buildFileFullPath()}
          target="_blank"
          rel="noreferrer"
          className="text-primary fs-5">
          <i className="bi bi-eye-fill" />
        </a>
      ) : null;

      const labelComponent = hideFileName ? null : (
        <span>{fileName || value}</span>
      );

      return (
        <span className="delete-link">
          {onViewComponent}
          {labelComponent}
          {onDeleteComponent}
        </span>
      );
    }
  }

  buildFileFullPath(): string {
    const { value, path } = this.props;
    let fullPath;

    if (path[path.length - 1] === '/') {
      fullPath = path + value;
    } else if (path.includes(value.toString())) {
      fullPath = path;
    } else {
      fullPath = path + '/' + value;
    }
    return fullPath;
  }

  handleChange = async (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget as HTMLInputElement;

    if (!files[0] || this.state.isUploading) return;

    const url = this.props.action || 'temppath/tempupload.php';

    this.setState({ isUploading: true });

    const formData = new FormData();

    formData.append('Filedata', files[0]);

    try {
      const fileName = await apiFileRequest<string>({
        url,
        data: formData,
      });

      this.props.onSetValue(this.props.name, fileName, '');
    } catch (e: any) {
      this.props.onSetValue(this.props.name, '', "File can't be uploaded!");
    } finally {
      this.setState({ isUploading: false });
    }
  };

  handleLabelClick = (e: React.SyntheticEvent) => {
    if (this.state.isUploading) e.preventDefault();
    this.inputRef.current.value = null;
  };

  onDelete = (event: React.MouseEvent): void => {
    if (event) {
      event.preventDefault();
    }
    const { onSetValue, name, onDelete } = this.props;
    const elemFile: any = document.getElementById(`${this.id}_path_input`);
    if (elemFile) {
      elemFile.value = null;
    }
    onSetValue(name, '', null);
    if (typeof onDelete === 'function') {
      onDelete(event);
    }
  };

  render() {
    const { isUploading } = this.state;
    const { props } = this;
    const {
      disabled,
      labelClassName,
      buttonLabel,
      defaultFileName,
      accept,
      contentAfter,
    } = props;

    const btnClassName = clsx(labelClassName || 'btn btn-primary', {
      disabled,
    });

    const custom = (
      <>
        <div className="fileUpload">
          <label
            className={btnClassName}
            htmlFor={`${this.id}_path_input`}
            onClick={disabled ? undefined : this.handleLabelClick}>
            <span className="upload">
              <input
                ref={this.inputRef}
                id={`${this.id}_path_input`}
                name={props.name || 'FileData'}
                type="file"
                className="upload"
                accept={accept}
                onChange={this.handleChange}
              />
            </span>
            <span className={isUploading ? 'invisible' : null}>
              {buttonLabel || 'Upload'}
            </span>
            {isUploading && (
              <div className="spinner-wrapper">
                <div className="spinner-border text-light" role="status" />
              </div>
            )}
          </label>
          {Boolean(defaultFileName) && (
            <input
              id={`${this.id}_filename_input`}
              name="Filename"
              type="text"
              style={styles.nonVisible}
              defaultValue={defaultFileName}
            />
          )}
          {this.buildFileNameLabel()}
        </div>
        {props.errorMessage ? (
          <div className="error">{props.errorMessage}</div>
        ) : null}
      </>
    );
    return (
      <Custom
        custom={custom}
        noLabel={!props.label}
        {...props}
        contentAfter={<div>{contentAfter}</div>}
      />
    );
  }
}

interface PropsType
  extends Omit<PFileUpload, 'value' | 'onSetValue' | 'path' | 'action'> {
  name: string;
  control: Control<any>;
  pathPrefix: string;
  action?: string;
}

export const FileUploadControl = ({
  control,
  name,
  pathPrefix,
  ...rest
}: PropsType) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: '',
  });
  const [isJustUploaded, setJustUploaded] = useState<boolean>(false);

  const url = useMemo(() => {
    const path = isJustUploaded ? 'tempfolder/' : pathPrefix.replace(/^\//, '');

    return `${BASE_URL_FILE_DIR}${path}`;
  }, [pathPrefix, isJustUploaded]);

  const handleUpload = (fieldName: string, path: string) => {
    if (path) {
      onChange(path);
      setJustUploaded(true);
    }
  };

  const handleDelete = () => {
    onChange('');
    setJustUploaded(false);
  };

  return (
    <FileUpload
      {...rest}
      path={url}
      value={value}
      errorMessage={error?.message}
      onSetValue={handleUpload}
      onDelete={handleDelete}
    />
  );
};
