import { apiRequest } from 'services/RequestService';

export interface DraftMessageRecipientType {
  mail: string;
  name: string;
  receive_type: string;
  refid: string;
  type: string;
}

export interface DraftMessageDetailsType {
  mailto: string;
  mailcc: string;
  mailbcc: string;
  subject: string;
  message: string;
  dataarray: Array<DraftMessageRecipientType>;
}

class DraftsService {
  getDraftMessageDetails(
    id: string
  ): Promise<{ SubjectBody: DraftMessageDetailsType[] }> {
    return apiRequest<{ SubjectBody: DraftMessageDetailsType[] }>({
      url: 'email.EmailMessageDisplay.getDraftMessageDetailsById',
      data: [id],
    });
  }
}

export default new DraftsService();
