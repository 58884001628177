import { useFieldArray, useForm } from 'react-hook-form';

import { DropdownPerDiem } from 'components/project/dropdown/PerDiemDropdown';
import { Button, IconButton } from 'components/button';
import { Input } from 'components/form/textField';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';

import { storePerDiem } from 'stores/_mobx/systemSetup/masterSetting/perDiem';

const defaultPerDiemValue: PerDiemType = {
  adjcode: '0',
  balance: '0.00',
  fee: '0.00',
  index: 0,
  name: '',
  payment: '0.00',
  price: '0.00',
  total: '0.00',
  writeoff: '0.00',
  unit: '',
};

export interface PerDiemType {
  adjcode: string;
  balance: string;
  fee: string;
  index: number;
  name: string;
  payment: string;
  price: string;
  total: string;
  writeoff: string;
  unit: string;
}

interface FormModel {
  perDiem: PerDiemType[];
}

interface PropsType {
  perDiemDetails: FormModel;
  onUpdate: (payload: FormModel['perDiem']) => void;
  onClose: () => void;
}

const preparePayload = (perDiemList: PerDiemType[]) => {
  const payload = perDiemList.map((el) => {
    const name =
      storePerDiem.options.find((perDiem) => perDiem.value === el.index)
        ?.label || '';

    return {
      ...el,
      name,
    };
  });

  return payload;
};

const DialogFormPerDiem = ({
  perDiemDetails,
  onClose,
  onUpdate,
}: PropsType) => {
  const defaultValues = perDiemDetails.perDiem.length
    ? perDiemDetails
    : { perDiem: [defaultPerDiemValue] };
  const {
    control,
    formState: { isDirty },
    reset,
    handleSubmit,
  } = useForm<FormModel>({ defaultValues });

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'perDiem',
  });

  const handleClickAddFax = () => {
    append(defaultPerDiemValue);
  };

  const handleClickSubmit = () => {
    handleSubmit(({ perDiem }) => {
      const payload = preparePayload(perDiem);

      onUpdate(payload);
    })();
  };

  const handleClickReset = () => {
    reset(perDiemDetails);
  };

  return (
    <Dialog scrollable={false} size="large" handleClose={onClose}>
      <DialogHeader title="Edit per diem" onClose={onClose} />
      <DialogBody>
        <form onSubmit={handleClickSubmit}>
          {fields.map((field, index) => (
            <div key={field.id} className="row">
              <DropdownPerDiem
                name={`perDiem.${index}.index`}
                label="Unit"
                className="col-md-5"
                isClearable={false}
                control={control}
              />
              <Input
                name={`perDiem.${index}.price`}
                label="Price"
                className="col-md-5"
                type="number"
                control={control}
              />
              <div className="d-flex text-primary col-sm-auto ms-auto mt-4 gap-3">
                <IconButton onClick={handleClickAddFax}>
                  <i className="bi bi-plus-circle" />
                </IconButton>
                <IconButton
                  disabled={fields.length <= 1}
                  onClick={() => remove(index)}>
                  <i className="bi bi-trash" />
                </IconButton>
              </div>
            </div>
          ))}
        </form>
      </DialogBody>
      <DialogFooter>
        <Button text="Reset" variant="warning" onClick={handleClickReset} />
        <Button text="Save" disabled={!isDirty} onClick={handleClickSubmit} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogFormPerDiem;
