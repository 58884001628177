import React from 'react';
import { Route, RouterProps } from 'react-router-dom';

import AccessDenied from 'page/errors/403';
import AccessUtils from 'utils/AccessUtils';

interface Props {
  path: string | string[];
  exact?: boolean;
  pageId: string;
  component?: React.FunctionComponent | React.ClassType<any, any, any>;
  children?: any;
  render?: (props: RouterProps) => any;
}

const AccessRoute = ({
  pageId,
  children,
  component: Component,
  render,
  ...rest
}: Props) => {
  const hasAccess = AccessUtils.checkAccess(pageId);

  const handleRender = (props: RouterProps) =>
    Component ? <Component {...props} /> : null;

  return hasAccess ? (
    <Route {...rest} render={render || handleRender}>
      {children}
    </Route>
  ) : (
    <AccessDenied />
  );
};

export default AccessRoute;
