import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import Tooltip from 'components/tooltip';
import DialogPatientMerge from './components/DialogPatientMerge';
import Filter from './components/Filter';

import {
  storePatientDemographic,
  PatientType,
  FilterType,
} from 'stores/_mobx/patientRecord/patientDemographic';
import AccessUtils from 'utils/AccessUtils';
import { PATIENT_DEMOGRAPHIC as PAGE_ID } from 'constant/pagesId/patientRecords';
import {
  URL_PATIENT_DEMOGRAPHIC,
  URL_PATIENT_DEMOGRAPHIC_ADD,
} from 'constant/path/patientRecords';

const getRowSettings = ({ facility_id }: PatientType) =>
  facility_id === 0 ? { className: 'background-red' } : {};

const getColumns = () =>
  [
    { name: 'vw_patientid', title: 'MRN' },
    { name: 'clientMRN', title: 'Other MRN' },
    {
      name: 'first_name',
      title: 'First name',
      className: 'text-icon-column',
      render: (firstName: string, { patient_alert }: PatientType) => (
        <div className="text-icon-wrapper">
          <span className="text-icon-pic">
            {patient_alert ? (
              <Tooltip
                theme="danger"
                title="Patient alert"
                body={patient_alert}>
                <i className="bi bi-exclamation-triangle-fill text-danger" />
              </Tooltip>
            ) : null}
          </span>
          <span>{firstName}</span>
        </div>
      ),
    },
    { name: 'last_name', title: 'Last name' },
    { name: 'gender', title: 'Gender' },
    { name: 'birthdate', title: 'DOB', className: 'text-nowrap' },
    { name: 'mobile', title: 'Facility phone' },
    { name: 'ssn', title: 'SSN' },
    AccessUtils.checkAccess(PAGE_ID.EDIT)
      ? {
          name: 'control',
          title: 'Action',
          className: 'width-50',
          render: (value: undefined, { refid }: PatientType) => (
            <div className="control">
              <Link
                className="bi bi-pencil"
                to={`${URL_PATIENT_DEMOGRAPHIC}/edit/${refid}`}
              />
            </div>
          ),
        }
      : null,
  ].filter((column) => Boolean(column));

const GridControlPanel = ({
  isActivePatient,
  selectedPatientsCount,
  onChangeStatus,
  onMerge,
}: {
  isActivePatient: boolean;
  selectedPatientsCount: number;
  onChangeStatus: () => void;
  onMerge: () => void;
}) => (
  <>
    {AccessUtils.checkAccess(PAGE_ID.ADD) && (
      <GridControlButton title="Add" url={URL_PATIENT_DEMOGRAPHIC_ADD} />
    )}
    <GridControlButton
      title="Merge"
      disabled={selectedPatientsCount < 2}
      onClick={onMerge}
    />
    {AccessUtils.checkAccess(
      isActivePatient ? PAGE_ID.INACTIVE : PAGE_ID.ACTIVE
    ) && (
      <GridControlButton
        title={isActivePatient ? 'Deactivate' : 'Activate'}
        onClick={onChangeStatus}
        disabled={!selectedPatientsCount}
      />
    )}
  </>
);

const PatientsDemographicListPage = () => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    patientList,
    patientTotal,
    filter,
    patientsForMerge,
    page: { pagination, setPagination, setPaginationToStart },
  } = storePatientDemographic;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [selected, setSelected] = useState<number[]>([]);

  const columns = useMemo(() => getColumns(), []);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleClickDeactivate = () => {
    storePatientDemographic
      .changePatientListStatus(selected)
      .then((isSucceed) => {
        if (isSucceed) {
          storePatientDemographic.getPatientsListMain();
          gridRef.current.clearSelection();
        }
      });
  };

  const handleClickMerge = () => {
    storePatientDemographic.setPatientsForMerging(selected);
  };

  const handleSubmitPatientMerge = () => {
    setSelected([]);
    storePatientDemographic.clearPatientsForMerging();
    storePatientDemographic.getPatientsListMain();
  };

  const handleChangeFilter = (filter: FilterType) => {
    storePatientDemographic.setFilter(filter);
    setPaginationToStart();
  };

  useEffect(() => {
    storePatientDemographic.getPatientsListMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle title="Patient Demographics" appId={PAGE_ID.PAGE}>
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide filter' : 'Show filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        fetching={fetching}
        isVisible={isFilterVisible}
        onSubmit={handleChangeFilter}
      />

      <Grid
        id="patient_demographics_grid"
        selectId="refid"
        ref={gridRef}
        selectedIds={selected}
        columns={columns}
        onAjax={fetching}
        dataSource={patientList}
        dataSourceCount={patientTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        onSelectChange={setSelected}
        rowArguments={getRowSettings}
        gridControlPanel={
          <GridControlPanel
            isActivePatient={filter.status === 'A'}
            selectedPatientsCount={selected.length}
            onChangeStatus={handleClickDeactivate}
            onMerge={handleClickMerge}
          />
        }
      />

      {patientsForMerge.length ? (
        <DialogPatientMerge
          mergeList={patientsForMerge}
          onClose={storePatientDemographic.clearPatientsForMerging}
          onSucceed={handleSubmitPatientMerge}
        />
      ) : null}
    </>
  );
};

export default observer(PatientsDemographicListPage);
