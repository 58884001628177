import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    subject: yup.string().required('Please, fill in the Subject.'),
    body: yup.string().test({
      test: (body: string) => body && body !== '<p><br></p>',
      message: 'Please, fill in notification message!',
    }),
  })
);

export default validation;
