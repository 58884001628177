import React from 'react';

import AbstractInput, {
  PAbstractInput,
  SAbstractInput,
  TAbstractInputAttr,
} from 'components/form/input/AbstractInput';

export interface PText extends PAbstractInput {
  inputRef?: React.Ref<HTMLElement>;
  maxLength?: number;
  autoComplete?: string;
  readOnly?: boolean;
  isClearable?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onMouseDown?: React.MouseEventHandler<HTMLElement>;
  onTouchStart?: React.TouchEventHandler<HTMLElement>;
  attr?: TAbstractInputAttr;
}

export class SText extends SAbstractInput {}

export class TText<P extends PText, S extends SText> extends AbstractInput<
  P,
  S
> {
  static defaultProps: {
    type: string;
    maxLength: number;
    autoComplete: string;
    isClearable: boolean;
  } = {
    type: 'text',
    isClearable: true,
    maxLength: undefined,
    autoComplete: undefined,
  };

  prepareAttributes() {
    const {
      props: { isClearable = true, ...props },
    } = this;
    const dataTestId = props['data-testid'] || props.name;
    const attributes = Object.assign({}, props.attr, {
      'data-testid': `${dataTestId}-field`,
    });
    const clearable = !props.attr?.disabled && isClearable;
    attributes.type = props.type;
    attributes.maxLength = props.maxLength;
    attributes.value = this.getValue();
    attributes.autoComplete = props.autoComplete;
    attributes.name = props.name;
    attributes.className = `form-control${clearable ? ' clearable' : ''}`;
    if (!attributes.onChange) {
      attributes.onChange = this.changeValue.bind(this);
    }
    if (!attributes.id) {
      attributes.id = props.formId
        ? `${props.formId}_${props.name}` +
          (attributes.idx ? '_' + attributes.idx : '')
        : null;
    }
    if (props.autoFocus) {
      attributes.autoFocus = props.autoFocus;
    }
    if (props.onClick) {
      attributes.onClick = props.onClick;
    }
    if (props.onBlur) {
      attributes.onBlur = props.onBlur;
    }
    if (props.onKeyDown) {
      attributes.onKeyDown = props.onKeyDown;
    }
    if (props.onKeyPress) {
      attributes.onKeyPress = props.onKeyPress;
    }
    if (props.onMouseDown) {
      attributes.onMouseDown = props.onMouseDown;
    }
    if (props.onTouchStart) {
      attributes.onTouchStart = props.onTouchStart;
    }
    if (props.readOnly) {
      attributes.readOnly = true;
    }
    if (props.inputRef) {
      attributes.ref = props.inputRef;
    }
    if (attributes.value === undefined || attributes.value === null) {
      attributes.value = '';
    }
    return attributes;
  }

  getInputType() {
    return 'input';
  }

  handleClear = (event: React.SyntheticEvent) => {
    const { props } = this;
    if (props.onSetValue && props.value !== '') {
      const { errorMessages, errorKeys } = this.getInputState('');
      this.props.onSetValue(
        props.name,
        '',
        errorMessages,
        errorKeys,
        event,
        props.rowRef
      );
    }
  };

  // @ts-ignore
  buildInput({ noInput, ...attributes }: PText): React.ReactNode {
    const { attr, isClearable = true } = this.props;
    return noInput ? null : (
      <>
        {React.createElement(this.getInputType(), attributes)}
        {!attr?.disabled && isClearable ? (
          <span className="field-clearIndicator" onClick={this.handleClear}>
            <i className="bi bi-x-lg"></i>
          </span>
        ) : null}
      </>
    );
  }

  render() {
    const { noLabel, attr } = this.props;
    // @ts-ignore
    const input = this.buildInput(this.prepareAttributes());
    const label =
      noLabel === true
        ? null
        : this.buildLabel(attr ? attr.id : null, [this.getLabelValue()]);
    return this.wrapInput(input, label);
  }
}

export default class Text extends TText<PText, SText> {}
