import { makeObservable, observable, action, runInAction } from 'mobx';

import { TPagination } from 'components/grid/GridTypes';
import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import FilterNameAndState, {
  FilterType,
} from 'stores/_mobx/options/filterNameAndStatus';

interface ThyroidShieldType {
  description: string;
  last_survey: string;
  next_survey: string;
  refid: number;
  thyroid_number: string;
}

interface ThyroidShieldResponseType {
  description: string;
  last_survey: string;
  next_survey: string;
  thyroid_name: string;
  thyroid_number: string;
}

interface ThyroidShieldEditType extends ThyroidShieldResponseType {
  refid: number;
}

export interface OptionType {
  label: string;
  value: number;
}

class ThyroidShield {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  thyroidShieldsList: ThyroidShieldType[] = [];
  thyroidShieldsTotal: number = 0;
  thyroidShield: ThyroidShieldEditType | undefined = undefined;
  thyroidShieldForDelete: number = 0;
  options: OptionType[] = [];

  filter: FilterNameAndState = new FilterNameAndState();
  page: Pagination = new Pagination({ id: 'thyroid_shield_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      thyroidShieldsList: observable,
      thyroidShieldsTotal: observable,
      thyroidShield: observable,
      thyroidShieldForDelete: observable,
      options: observable,
      filter: observable,

      setFetching: action,
      setFetchingOptions: action,
      setThyroidShieldsList: action,
      setThyroidShieldsTotal: action,
      setThyroidShield: action,
      setThyroidShieldForDelete: action,
      clearThyroidShieldForDelete: action.bound,
      clearThyroidShield: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setThyroidShieldsList(list: ThyroidShieldType[]) {
    this.thyroidShieldsList = list;
  }

  setThyroidShieldsTotal(count: number) {
    this.thyroidShieldsTotal = count;
  }

  setThyroidShield(thyroidShield?: ThyroidShieldEditType) {
    this.thyroidShield = thyroidShield;
  }

  setThyroidShieldForDelete(id: number) {
    this.thyroidShieldForDelete = id;
  }

  clearThyroidShieldForDelete() {
    this.thyroidShieldForDelete = 0;
  }

  clearThyroidShield() {
    this.thyroidShield = undefined;
  }

  async getThyroidShieldsList({
    pagination,
    filter,
  }: {
    pagination: TPagination;
    filter: FilterType;
  }) {
    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.name,
      filter.state,
    ];

    try {
      const response = await apiRequest<'SE' | ThyroidShieldType[]>({
        url: 'generalmaster.ThyroidMaster.GetThyroidList',
        data,
      });
      if (Array.isArray(response)) {
        const shields = response.map((shield) => ({
          ...shield,
          refid: Number(shield.refid),
        }));
        this.setThyroidShieldsList(shields);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setThyroidShieldsList([]);
    }
  }

  async getThyroidShieldsCount(filter: FilterType) {
    try {
      const count = await apiRequest<'SE' | 'S' | number>({
        url: 'generalmaster.ThyroidMaster.ThyroidCount',
        data: [filter.name, filter.state],
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setThyroidShieldsTotal(total);
    } catch (e) {
      this.setThyroidShieldsTotal(0);
    }
  }

  getThyroidShieldMain = () => {
    this.setFetching(true);

    const props = {
      pagination: this.page.pagination,
      filter: this.filter.options,
    };

    const promiseList = this.getThyroidShieldsList(props);

    const promiseCount = this.getThyroidShieldsCount(props.filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getThyroidShield(id: number | string) {
    this.setFetching(true);

    try {
      const response = await apiRequest<'SE' | [ThyroidShieldResponseType]>({
        url: 'generalmaster.ThyroidMaster.GetThyroid',
        data: [id],
      });
      if (Array.isArray(response)) {
        const shield: ThyroidShieldEditType = {
          ...response[0],
          refid: Number(id),
        };

        this.setThyroidShield(shield);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setThyroidShield();
    } finally {
      this.setFetching(false);
    }
  }

  async addThyroidShield(payload: Omit<ThyroidShieldEditType, 'refid'>) {
    const data = [
      payload.thyroid_name,
      payload.thyroid_number,
      payload.last_survey,
      payload.next_survey,
      payload.description,
    ];

    try {
      const result = await apiRequest<'S' | 'SE'>({
        url: 'generalmaster.ThyroidMaster.AddThyroid',
        data,
      });
      if (result === 'S') {
        Notification.success('Thyroid Shield was added successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async updateThyroidShield(payload: ThyroidShieldEditType) {
    const data = [
      payload.refid,
      payload.thyroid_name,
      payload.thyroid_number,
      payload.last_survey,
      payload.next_survey,
      payload.description,
    ];

    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.ThyroidMaster.EditThyroid',
        data,
      });

      if (result === 'S') {
        Notification.success('Thyroid Shield was updated successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  changeThyroidShieldStatus = async (ids: number[], state: string) => {
    this.setFetching(true);
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'generalmaster.ThyroidMaster.changeStatus',
        data: [ids],
      });
      if (response === 'S') {
        const { length } = ids;

        Notification.success(
          `${length} Thyroid Shield${length > 1 ? 's were' : 'was'} ${state}`
        );
        return true;
      } else Error('');
    } catch (e) {
      Notification.warning('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  };

  async deleteThyroidShield() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.ThyroidMaster.DeleteThyroid',
        data: [this.thyroidShieldForDelete],
      });
      if (result === 'S') {
        Notification.success('Thyroid Shield was deleted!');
        this.clearThyroidShieldForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOptions(true);
    try {
      const options = await apiRequest<OptionType[]>({
        url: 'vehicle.EquipmentMaster.ThyroidDropDown',
      });

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storeThyroidShield = new ThyroidShield();
