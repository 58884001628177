import React from 'react';
import { Container } from 'flux/utils';

import { IconButton } from 'components/button';
import Notification from 'components/modal/Notification';
import { LayoutSideTitle } from 'components/layout';
import FileUpload from 'components/form/input/FileUpload';
import BriefcasePageActions from 'actions/profile/personal/BriefcasePageActions';
import UserSelection from 'components/project/users/UserSelection';
import UserSharing from 'components/project/users/UserSharing';
import Tooltip from 'components/tooltip';
import { Grid } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import DialogPrompt from 'components/project/modals/DialogPrompt';
import Badge from 'components/badge';
import BriefcaseItem from './components/BriefcaseItem';

import BriefcasePageStore from 'stores/profile/BriefcasePageStore';
import AccessUtils from 'utils/AccessUtils';
import { BRIEFCASE as PAGE_ID } from 'constant/pagesId/personalProfile';

const OWN = 0;
const ROOT = -2;

export class Path {
  refid: number = 0;
  title: string = '';
  dirId: number = 0;
  ownerId: number = 0;
  createdDate: string = '';
  type: string = '';
  flag: string = '';
  sharedBy: number = 0;
  sharedByName: string = '';
  sharedDate: string = '';
  children: Array<Path> = [];
  shared_count: number = 0;
  onOpenDirectory: any = null;
  rootDir: boolean = false;
  homeFolder: boolean = true;
  view_createdDate: string = '';
  image?: string;
  noControl?: boolean = null;
}

class Directories {
  own: Path;
  share: Path;
}

class SelectedUser {
  refid: number;
  to_name: string;
  to_mail: string;
  firstName: string;
  lastName: string;
  type: string;
  facility: string;
}

interface PBriefcasePage {}

class SBriefcasePage {
  currentDir: number = -2;
  onAjaxLoad: boolean = false;
  filterFiles: 'all' | 'images' | 'docs' | 'xls' | 'pdf' = 'all';
  files: any = [];
  directories: Directories = null;
  showUserSelection: boolean = false;
  showShareNotification: boolean = false;
  onRename: boolean = false;
  showRenameNotification: boolean = false;
  onRemove: boolean = false;
  showDeleteNotification: boolean = false;
  currentPath: Array<string> = [];
  selectedStructure: Path = null;
  selectedItem: Path = null;
  showSharingUsers: boolean = false;
  path: Array<Path> = [];
  showCreateNewDirectory: boolean = false;
  selectedUsers: Array<SelectedUser> = [];
}

export class BriefcasePage extends React.Component<
  PBriefcasePage,
  SBriefcasePage
> {
  enableUpload = false;
  onAjaxLoad = false;

  static getStores() {
    return [BriefcasePageStore];
  }

  static calculateState(prevState: SBriefcasePage) {
    if (!prevState) {
      prevState = new SBriefcasePage();
    }
    return { ...prevState, ...BriefcasePageStore.getState() };
  }

  componentDidMount() {
    this.updateDirectories();
  }

  renderFilTypeFilter = () => {
    const classNameFor = (
      filterName: 'all' | 'images' | 'docs' | 'xls' | 'pdf'
    ) => (this.state.filterFiles === filterName ? 'active' : '');
    return (
      <div className="type-filter">
        <ul>
          <li className={classNameFor('all')}>
            <a href="/" onClick={(event) => this.changeFilter(event, 'all')}>
              All
            </a>
          </li>
          <li className={classNameFor('images')}>
            <a href="/" onClick={(event) => this.changeFilter(event, 'images')}>
              Images
            </a>
          </li>
          <li className={classNameFor('docs')}>
            <a href="/" onClick={(event) => this.changeFilter(event, 'docs')}>
              Docs
            </a>
          </li>
          <li className={classNameFor('xls')}>
            <a href="/" onClick={(event) => this.changeFilter(event, 'xls')}>
              CSV/XLS
            </a>
          </li>
          <li className={classNameFor('pdf')}>
            <a href="/" onClick={(event) => this.changeFilter(event, 'pdf')}>
              PDF
            </a>
          </li>
        </ul>
      </div>
    );
  };

  renderButtons = () => {
    const buttons = this.state.path.map((item: Path, index: number) =>
      index !== this.state.path.length - 1 ? (
        <Tooltip
          key="tt_prev_dir"
          body={
            <>
              <div>Open Directory:</div>
              <div style={{ textAlign: 'center' }}>{item.title}</div>
            </>
          }>
          <a
            href="/"
            className="label label-primary"
            onClick={(event) => {
              this.openPrevDirectory(event, item);
            }}>
            <Badge variant="info">{item.title}</Badge>
          </a>
        </Tooltip>
      ) : (
        <Tooltip key="tt_active_dir" body="Active Directory">
          <Badge variant="secondary">{item.title}</Badge>
          {/* <span className="label label-default">{item.title}</span> */}
        </Tooltip>
      )
    );

    return (
      <>
        {buttons}
        {AccessUtils.checkAccess(PAGE_ID.FILE_UPLOAD) ? (
          <Tooltip body="Upload File">
            <FileUpload
              onSetValue={(name: string, fileData: string) => {
                this.afterFileUpload(name, fileData);
              }}
              labelClassName="bi bi-cloud-upload text-primary fs-5"
              action="temppath/briefcaseupload.php"
              buttonLabel=" "
              noWarning
              noLabel
            />
          </Tooltip>
        ) : null}
        {AccessUtils.checkAccess(PAGE_ID.FOLDER_CREATE) ? (
          <Tooltip body="New Directory">
            <IconButton
              className="text-primary fs-5"
              style={{ top: 1 }}
              onClick={this.onCreateNewDirectory}>
              <i className="bi-plus-circle" />
            </IconButton>
          </Tooltip>
        ) : null}
        <Tooltip body="Go Back">
          <IconButton
            className="text-primary fs-5"
            onClick={(e) => this.openPrevDirectory(e, null)}>
            <i className="bi bi-arrow-return-left" />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  render() {
    const { selectedStructure } = this.state;

    const isFileSelected = selectedStructure?.type === 'F';

    return (
      <div className="briefcase-page">
        <div className={this.onAjaxLoad ? ' on-ajax on-ajax-col' : ''}>
          <LayoutSideTitle appId={PAGE_ID.PAGE} title="Briefcase">
            {this.renderButtons()}
          </LayoutSideTitle>

          {this.renderFilTypeFilter()}
          <div className="clearfix" />
          <ul className="files row">
            <Grid
              disablePagination
              columns={this.getColumns()}
              dataSource={this.mergeDirsAndFiles()}
              noControl
              stateless
            />
          </ul>
          {this.state.showUserSelection ? (
            <UserSelection
              isOpen
              onSubmit={(e, result) => this.onUserSelect(e, result)}
              onCancel={() => this.onUserSelect(undefined, null)}
              initialSelected={[]}
            />
          ) : null}

          {this.state.onRemove && (
            <DialogConfirm
              onApprove={this.handleConfirmRemove}
              onCancel={this.handleCloseConfirmDelete}>
              {isFileSelected ? (
                `Do you want to delete '${selectedStructure.title}' file?`
              ) : (
                <div>
                  All the files in '{selectedStructure.title}' directory will be
                  deleted.
                  <br />
                  Are you sure you want to delete this directory?
                </div>
              )}
            </DialogConfirm>
          )}

          {this.state.showCreateNewDirectory ? (
            <DialogPrompt
              onClose={this.handleCloseNewDirectoryDialog}
              onSubmit={this.createNewDirectory}
              title="New directory"
              label="Please, enter the name of the directory"
            />
          ) : null}

          {this.state.onRename && (
            <DialogPrompt
              title={`Rename the ${isFileSelected ? 'file' : 'folder'}`}
              label={`${isFileSelected ? 'File' : 'Folder'} name`}
              defaultValue={selectedStructure.title}
              onSubmit={this.renameStructure}
              onClose={this.handleCloseRenameDialog}
            />
          )}

          {this.state.showSharingUsers ? (
            <UserSharing
              file={this.state.selectedItem}
              showUserSelection={(event: React.MouseEvent) =>
                this.onShare(event, this.state.selectedItem)
              }
              onSubmit={(event: React.MouseEvent) => this.onUserSharing(event)}
            />
          ) : null}
        </div>
      </div>
    );
  }

  onRename = (event: React.MouseEvent, selectedStructure: Path) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ onRename: true, selectedStructure });
  };

  onRemove = (event: React.MouseEvent, file: Path) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ onRemove: true, selectedStructure: file });
  };

  handleCloseConfirmDelete = () => {
    this.setState({ onRemove: false, selectedStructure: null });
  };

  handleConfirmRemove = () => {
    const { currentDir, selectedStructure } = this.state;

    BriefcasePageActions.deleteStructure(selectedStructure).then((response) => {
      if (response === 'E') {
        Notification.danger("Can't delete due unknown reasons!");
      }
      this.handleCloseConfirmDelete();
      if (response !== 'E') {
        Notification.success('Successfully deleted!');

        this.updateDirectories(currentDir);
      }
    });
  };

  openPrevDirectory = (event: React.MouseEvent, currentDir: Path) => {
    if (event) {
      event.preventDefault();
    }
    const path = [].concat(this.state.path);
    if (currentDir !== null) {
      let length = path.length;
      while (length && path[--length].refid !== currentDir.refid) {
        path.pop();
      }
    } else {
      path.pop();
      if (path.length) {
        currentDir = path[path.length - 1];
      }
    }
    this.setState(
      { currentDir: currentDir ? currentDir.refid : ROOT, path },
      () => {
        if (this.state.currentDir !== ROOT) {
          this.loadFiles(this.state.currentDir);
        }
      }
    );
  };

  openDirectoryById = (dirId: number) => {
    this.setState({ currentDir: dirId });
  };

  handleCloseRenameDialog = () => {
    this.setState({ onRename: false });
  };

  renameStructure = (newName: string) => {
    const currentDir = this.state.currentDir;

    BriefcasePageActions.renameStructure(
      this.state.selectedStructure,
      newName
    ).then(() => this.updateDirectories(currentDir));
    this.handleCloseRenameDialog();
  };

  handleCloseNewDirectoryDialog = () => {
    this.setState({ showCreateNewDirectory: false });
  };

  createNewDirectory = (name: string) => {
    let currentDir = this.state.currentDir;
    if (currentDir === ROOT) {
      currentDir = OWN;
    }

    BriefcasePageActions.createNewDirectory(currentDir, name).then(
      (response) => {
        if (response === 'E') {
          Notification.danger("Can't create directory");
        } else if (response === 'S') {
          Notification.success('Directory created');
        } else {
          Notification.danger(
            'Something gone wrong. Please notify your supervisor.'
          );
        }
        this.updateDirectories(currentDir);
      }
    );
    this.handleCloseNewDirectoryDialog();
  };

  onCreateNewDirectory = (event: React.MouseEvent) => {
    event.preventDefault();
    this.setState({ showCreateNewDirectory: true });
  };

  onUserSelect = (event: React.MouseEvent, result: Array<SelectedUser>) => {
    if (event) {
      event.preventDefault();
    }
    let clb = null;

    if (result !== null) {
      clb = () => {
        this.share()?.then(() => this.updateDirectories(this.state.currentDir));
      };
    }
    this.setState({ showUserSelection: false, selectedUsers: result }, clb);
  };

  onUserSharing = (event: React.MouseEvent) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ showSharingUsers: false }, () =>
      this.updateDirectories(this.state.currentDir)
    );
  };

  share = () => {
    const users = this.state.selectedUsers.map((value) => value.to_mail);
    if (users.length === 0) {
      Notification.warning('Please select at least one user');
      return;
    }
    return BriefcasePageActions.share(this.state.selectedStructure.refid, users)
      .then((response) => {
        if (response === 'S') {
          Notification.success('Successfully shared!');
        } else {
          Notification.danger('An error occurred!');
        }
        return response;
      })
      .then(() => {
        return BriefcasePageActions.getListOfSharingUsers(
          this.state.selectedStructure.refid
        );
      });
  };

  shareFile = () => {
    const users = this.state.selectedUsers.map(
      (v) => v.to_mail /*to_mail is correct identifier.*/
    );
    const file: Path = this.state.selectedStructure;
    BriefcasePageActions.share(file.refid, users).then((response) => {
      if (response === 'S') {
        Notification.success('Successfully shared!');
      } else {
        Notification.danger('An error occurred!');
      }
    });
  };

  afterFileUpload = (name: string, fileData: string) => {
    const currentDir = this.state.currentDir;
    if (!fileData || !fileData.length) {
      return;
    }
    BriefcasePageActions.uploadFiles(currentDir, fileData).then(
      (response: number | string) => {
        if (response === 'E') {
          Notification.danger("Can't upload file");
          return;
        } else {
          Notification.success('File uploaded');
        }
        if (response === currentDir) {
          return this.loadFiles(currentDir);
        } else {
          return this.loadFiles(response as number).then(() => {
            const dir = this.findDirectoryById(response as number);
            if (dir) {
              this.onOpenDirectory(null, dir);
            } else {
              return this.loadFiles(currentDir);
            }
            return response;
          });
        }
      }
    );
  };

  findDirectoryById = (id: number) => {
    const dirs: Directories = this.state.directories || {
      share: null,
      own: null,
    };
    let dir = this.lookUpForDir(dirs.own, id);
    if (dir === null) {
      dir = this.lookUpForDir(dirs.share, id);
    }
    return dir;
  };

  onShare = (event: React.MouseEvent, selectedStructure: Path) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      selectedStructure,
      showUserSelection: true,
    });
  };

  onShareList = (event: React.MouseEvent, selectedItem: Path) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ selectedItem, showSharingUsers: true });
  };

  getFiles = () => {
    const currentDir = this.state.currentDir;
    const files = this.state.files[currentDir];
    if (!files) {
      return [];
    }
    const out = [];
    for (let i = 0; i < files.length; i++) {
      let item = files[i];
      if (!this.filterByExtension(item.title)) {
        continue;
      }
      out.push({ ...item, homeFolder: this.isHomeFolder() });
    }
    return out;
  };

  updateDirectories = (masterDirId: number = null) => {
    return BriefcasePageActions.loadDirectoriesAndFiles()
      .then(() => this.loadFiles(masterDirId))
      .then(() => {
        if (masterDirId !== null) {
          this.openDirectoryById(masterDirId);
        }
      });
  };

  loadFiles = (masterId: number = 0) => {
    return BriefcasePageActions.loadFiles(masterId).then((fileList) => {
      const files = [...this.state.files];
      files[masterId] = fileList;
      this.setState({ files });
      return fileList;
    });
  };

  onOpenDirectory = (event: React.MouseEvent, dir: Path) => {
    if (event) {
      event.preventDefault();
    }
    const path: Array<Path> = [...this.state.path];
    path.push(dir);
    this.setState({ currentDir: dir.refid, path }, () =>
      this.loadFiles(dir.refid)
    );
  };

  lookUpForDir = (dir: Path, id: number): Path => {
    if (!dir) {
      return null;
    }
    if (dir.refid === id) {
      return dir;
    }
    if (dir.children && dir.children.length) {
      for (let i = 0; i < dir.children.length; i++) {
        const out: Path = this.lookUpForDir(dir.children[i], id);
        if (out !== null) {
          return out;
        }
      }
    }
    return null;
  };

  mergeDirsAndFiles = () => {
    let out: Array<Path> = [];
    const currentDir = this.state.currentDir;
    const dirs: Directories = this.state.directories || {
      own: null,
      share: null,
    };
    let dirsMerged = false;
    if (currentDir !== ROOT) {
      const dir = this.findDirectoryById(currentDir);
      if (dir !== null) {
        dirsMerged = true;
        out = out.concat(this.drawDirChildren(dir));
      }
    }
    if (!dirsMerged) {
      out.push(this.drawDir(dirs.own, true));
      out.push(this.drawDir(dirs.share, true));
    }
    return out.concat(this.getFiles()).filter((value: Path) => value);
  };

  drawDirChildren = (dir: Path) => {
    const out: Array<Path> = [];
    if (this.state.currentDir !== ROOT) {
      const dto = { ...dir, title: '..', noControl: true };
      out.push(
        this.drawDir(dto, false, (event: React.MouseEvent) =>
          this.openPrevDirectory(event, null)
        )
      );
    }
    if (dir && dir.children && dir.children.length) {
      dir.children.forEach((value: Path) =>
        out.push(this.drawDir(value, false))
      );
    }
    return out;
  };

  isHomeFolder = (): boolean => {
    const paths = this.state.path;
    for (let i = 0; i < paths.length; i++) {
      if (paths[i].refid === -1) {
        return false;
      }
    }
    return true;
  };

  drawDir = (
    item: Path,
    rootDir: boolean,
    onOpenDirectory?: (event: React.MouseEvent) => void
  ): Path => {
    if (!item) {
      return null;
    }
    return {
      ...item,
      onOpenDirectory,
      rootDir,
      homeFolder: this.isHomeFolder(),
    };
  };

  changeFilter = (
    event: React.MouseEvent,
    filter: 'all' | 'images' | 'docs' | 'xls' | 'pdf'
  ) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ filterFiles: filter });
  };

  filterByExtension = (title: string) => {
    let allowed: Array<string>;
    switch (this.state.filterFiles) {
      case 'images':
        allowed = ['png', 'jpg', 'jpeg', 'gif', 'bmp'];
        break;
      case 'docs':
        allowed = ['doc', 'docx'];
        break;
      case 'xls':
        allowed = ['xls', 'xlsx', 'csv'];
        break;
      case 'pdf':
        allowed = ['pdf'];
        break;
      default:
        return true;
    }
    const parts = title.split('.');
    if (parts.length > 1) {
      return allowed.indexOf(parts.pop().toLowerCase()) > -1;
    }
    return false;
  };

  getColumns = () => {
    const isHomeFolder = this.isHomeFolder();
    type TOut = {
      name: string;
      title: string;
      className?: string;
      dataType?: string;
      sorter?: (c1: string, c2: string, direction: number) => number;
      render?: (value: undefined, data: Path) => React.ReactNode;
    };
    const out: Array<TOut> = [
      {
        name: 'title',
        title: 'File Name',
        sorter: (c1: string, c2: string, direction: number) => {
          if (c1 === '..') {
            return -1;
          } else if (c2 === '..') {
            return -1;
          }
          if (c1 > c2) {
            return direction;
          }
          if (c1 < c2) {
            return -direction;
          }
          return 0;
        },
        render: (value: undefined, data: Path) => {
          return BriefcaseItem.renderTitle(
            data,
            (event: React.MouseEvent, dir: Path) =>
              this.onOpenDirectory(event, dir)
          );
        },
      },
      {
        name: 'createdDate',
        title: 'Date Added',
        className: 'width-200',
      },
    ];
    if (isHomeFolder) {
      out.push({
        name: 'actions',
        title: 'Actions',
        className: 'width-150',
        render: (value: undefined, data: Path) => {
          return BriefcaseItem.renderActions(
            data,
            (event: React.MouseEvent, entity: Path) =>
              this.onRename(event, entity),
            (event: React.MouseEvent, entity: Path) =>
              this.onRemove(event, entity),
            (event: React.MouseEvent, entity: Path) =>
              this.onShare(event, entity),
            (event: React.MouseEvent, entity: Path) =>
              this.onShareList(event, entity)
          );
        },
      });
    } else {
      out.push({
        name: 'sharedDate',
        title: 'Date Shared',
        className: 'width-200',
        dataType: 'datetime',
      });
      out.push({
        name: 'sharedByName',
        title: 'Shared By',
        className: 'width-200',
      });
    }
    return out;
  };
}

const container = Container.create(BriefcasePage);
export default container;
