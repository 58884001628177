import React from 'react';

import DraggableTH from './DraggableTH';
import SortableTH from './SortableTH';

interface PDraggableTR {
  columns: any[];
  headers: any[];
  noDrag: any[];
  currentSort: any;
  onChangeSort: (columnName: string, direction: number) => void;
  groupMetadata?: { [key: string]: any };
  dragColumns: (column: number, over: number) => void;
  dragColumn?: any;
  dragOver?: any;
  onDragOver?: any;
}

interface State {
  dragColumn: number;
  dragOver: number;
}

export default class DraggableTR extends React.Component<PDraggableTR, State> {
  state = {
    dragColumn: -1,
    dragOver: -1,
  };

  setDragColumn(dragColumn: number) {
    this.setState({ dragColumn });
  }

  setDragOver(dragOver: number, callback?: () => void) {
    this.setState({ dragOver }, callback);
  }

  render() {
    const { props } = this;
    const children = [];
    const headers = this.prepareHeaders();
    let group = null;
    for (let i = 0; i < headers.length; i++) {
      let column = props.columns[i];
      if (props.noDrag.indexOf(i) > -1) {
        let groupClass = '';
        if (column.group) {
          if (group !== column.group) {
            group = column.group;
            groupClass = ' group-start';
          }
        } else {
          group = null;
        }
        if (
          group &&
          props.columns[i + 1] &&
          props.columns[i + 1].group !== group
        ) {
          groupClass += ' group-end';
        }
        children.push(
          this.buildSortableTH(i, column, groupClass, props, headers[i])
        );
      } else {
        children.push(this.buildDraggableTH(i, props, headers[i]));
      }
    }
    return (
      <tr
        onDrop={(e) => {
          e.preventDefault();
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
      >
        {children}
      </tr>
    );
  }

  buildSortableTH: (
    index: number,
    column: any,
    groupClass: string,
    props: PDraggableTR,
    header: any
  ) => React.ReactElement = (index, column, groupClass, props, header) => {
    const { currentSort, onChangeSort } = props;
    return (
      <SortableTH
        key={index}
        column={column}
        groupClass={groupClass}
        currentSort={currentSort}
        onChangeSort={onChangeSort}
      >
        {header}
      </SortableTH>
    );
  };

  buildDraggableTH: (
    index: number,
    props: PDraggableTR,
    header: any
  ) => React.ReactElement = (index, props, header) => {
    const { columns, currentSort, onChangeSort } = props;
    return (
      <DraggableTH
        key={index}
        column={columns[index]}
        onChangeSort={onChangeSort}
        currentSort={currentSort}
        onDragOver={(e) => this.onDragOver(e, index)}
        onDragStart={(e) => this.onDragStart(e, index)}
        onDragEnd={(e) => this.onDragEnd(e, index)}
      >
        {header}
      </DraggableTH>
    );
  };

  prepareHeaders() {
    const { props } = this;
    if (!props.groupMetadata) {
      return props.headers;
    }
    let out = [];
    for (let i = 0; i < props.groupMetadata.length; i++) {
      let meta = props.groupMetadata[i];
      for (let j = 0; j < meta.headers.length; j++) {
        out.push(meta.headers[j]);
      }
    }
    return out;
  }

  onDragEnd(e: Event, i: number) {
    this.props.dragColumns(this.state.dragColumn, this.state.dragOver);
  }

  onDragStart(e: Event, i: number) {
    if (this.state.dragColumn !== i) {
      this.setDragColumn(i);
    }
  }

  onDragOver(e: Event, i: number) {
    if (this.state.dragOver !== i) {
      this.setDragOver(i, () =>
        this.props.onDragOver(i, this.state.dragColumn)
      );
    }
  }
}
