import { Textarea } from 'components/form/textarea';
import { Input } from 'components/form/textField';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import resolver from './validation';
import {
  FormType,
  UserType,
} from 'stores/_mobx/systemSetup/masterSetting/emailGroup';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Link } from 'react-router-dom';
import EmailGroupPureTwoSideMapper from './EmailGroupUsersMapper';

const columns = [
  { title: 'User name', name: 'label', width: '150px' },
  { title: 'User title', name: 'title', width: '150px' },
  { title: 'User Type', name: 'type', width: '150px' },
  { title: 'Facility', name: 'facilityName', width: '150px' },
];

interface PropsType {
  backUrl: string;
  defaultValues: FormType;
  options: UserType[];
  onSubmit: (data: FormType) => Promise<any>;
}

const Form = ({ defaultValues, backUrl, options, onSubmit }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: FormType) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('name', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form className="row" onSubmit={handleClickSubmit}>
      <Input
        name="name"
        label="Group Name"
        className="col-md-8 col-lg-6"
        required
        control={control}
      />
      <div />
      <Textarea
        name="description"
        label="Description"
        className="col-md-8 col-lg-6"
        rows={2}
        control={control}
      />
      <div className="formfield-holder required">
        <label className="form-label">Select User</label>
        <EmailGroupPureTwoSideMapper
          name="members"
          titleLeft="Application users"
          titleRight="Group members"
          options={options}
          columnLeft={columns}
          columnRight={columns}
          control={control}
        />
      </div>

      <ControlsLayout alignX="between">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <div>
          <Button
            text="Reset"
            variant="warning"
            disabled={isSubmitting || !isDirty}
            onClick={handleClickReset}
          />
          <Button
            type="submit"
            text="Submit"
            disabled={isSubmitting || !isDirty}
          />
        </div>
      </ControlsLayout>
    </form>
  );
};

export default Form;
