import SendingApplicationsActions, {
  SendingApplicationFormDto,
} from 'actions/system-setup/master-setup/master-settings/integrations/SendingApplicationsActions';
import AbstractSendingApplication, {
  PAbstractSendingApplication,
  SAbstractSendingApplication,
  TAbstractSendingApplicationModel,
  TSftpAccess,
} from './AbstractSendingApplication';

interface PropsType extends PAbstractSendingApplication {}

export default class EditSendingApplication extends AbstractSendingApplication<
  PropsType,
  SAbstractSendingApplication
> {
  getTitle() {
    return `Edit ${this.state.title}`;
  }

  componentDidMount() {
    this.updateData();
  }

  updateData() {
    SendingApplicationsActions.loadOne(Number(this.props.match.params.id)).then(
      (response: SendingApplicationFormDto | { status: string }) => {
        const model: TAbstractSendingApplicationModel = Object.assign(
          new TAbstractSendingApplicationModel(),
          response as SendingApplicationFormDto
        );
        const tcpData = (model.responseUrl || '').split(':');
        const mapped = { ...model, host: tcpData[0], port: tcpData[1] };
        if (!mapped.sftpAccess || mapped.sftpAccess.length === 0) {
          mapped.sftpAccess = [new TSftpAccess()];
        }
        this.setState({ model: mapped, title: model.namespace });
      }
    );
  }

  getErrorMessage(r?: any): string {
    return "Can't update application due unknown reasons";
  }
}
