import { useEffect, useLayoutEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import { LayoutSideTitle } from 'components/layout';
import PhysicianCompleteInformationForm from './components/CompleteInformation';

import {
  storePhysician,
  PhysicianFormType,
} from 'stores/_mobx/clinicianManager/physician';
import { storePasswordStrength } from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';
import { URL_PHYSICIANS } from 'constant/path/clinicalManager';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const PhysicianEditPage = ({ history, match: { params } }: PropsType) => {
  const { physicianDetailsDefaultValues, fetchingPhysicianDetails } =
    storePhysician;

  const { fetching, passwordOptions, passwordSettings } = storePasswordStrength;

  const isLoading = fetching || !passwordSettings;

  const handleSubmit = (payload: PhysicianFormType) =>
    storePhysician
      .editPhysician(payload)
      .then(({ isSucceed, errorMessage }) => {
        if (isSucceed) history.push(URL_PHYSICIANS);
        return isSucceed ? null : errorMessage;
      });

  useLayoutEffect(() => {
    if (!passwordSettings) storePasswordStrength.getPasswordSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const id = Number(params.id);

    storePhysician.getPhysicianDetails(id).then((isSucceed) => {
      if (!isSucceed) history.replace(URL_PHYSICIANS);
    });

    return storePhysician.clearPhysicianStore;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <>
      <LayoutSideTitle title="Edit Physician">
        <Link to={URL_PHYSICIANS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      {isLoading ? (
        <SpinnerFixed />
      ) : (
        <PhysicianCompleteInformationForm
          backUrl={URL_PHYSICIANS}
          passwordOptions={passwordOptions}
          defaultValues={physicianDetailsDefaultValues}
          fetching={fetchingPhysicianDetails}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default observer(PhysicianEditPage);
