import { forwardRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from 'components/project/dropdown/AbstractDropdown';
import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';

import {
  storeDropdownOrderType,
  OptionBasicType,
} from 'stores/_mobx/dropDown/orderType';

interface PropsType extends PAbstractDropdown {
  options: OptionBasicType[];
  ref: any;
}

class OrderTypeDropdown extends AbstractDropdown<PropsType, SAbstractDropdown> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<OrderTypeDropdown, any>(
  (props: PAbstractDropdown, ref) => {
    const { optionsBasic, fetchingBasicOptions } = storeDropdownOrderType;

    useEffect(() => {
      storeDropdownOrderType.getOptionsBasic();
    }, []);

    return (
      <OrderTypeDropdown
        {...props}
        ref={ref}
        options={optionsBasic}
        fetching={fetchingBasicOptions}
      />
    );
  }
);

export default observer(DropDownMiddleware);

export const DropDownOrderType = observer(
  (props: Omit<SelectPropsType, 'options'>) => {
    const { fetchingBasicOptions, optionsBasic } = storeDropdownOrderType;

    useEffect(() => {
      storeDropdownOrderType.getOptionsBasic();
    }, []);

    return (
      <Select
        {...props}
        options={optionsBasic}
        isLoading={fetchingBasicOptions}
      />
    );
  }
);

export const DropDownOrderTypeId = observer(
  (props: Omit<SelectPropsType, 'options'>) => {
    const { fetching, options } = storeDropdownOrderType;

    useEffect(() => {
      storeDropdownOrderType.getOptions();
    }, []);

    return <Select {...props} isLoading={fetching} options={options} />;
  }
);
