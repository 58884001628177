import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import Tabs, { TabOption } from 'components/tabs';
import { LayoutSideTitle } from 'components/layout';
import ContactTab from './components/form-tabs/ContactTab';
import UsersTab from './components/form-tabs/UsersTab';
import BillingTab from './components/form-tabs/BillingTab';
import FacilitiesTab from './components/form-tabs/FacilitiesTab';

import CorporationOverviewPageActions from 'actions/system-setup/master-setup/corporations/CorporationOverviewPageActions';
import { URL_CORPORATION } from 'constant/path/systemSetup';

const tabsList = [
  { label: 'Contact', url: '?subtub=contact' },
  { label: 'Facilities', url: '?subtub=facilities' },
  { label: 'Users', url: '?subtub=users' },
  { label: 'Billing', url: '?subtub=billing' },
];

interface MatchParams {
  id: string;
  title?: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

class StateType {
  prevCorpId: string = '0';
  tabsList: TabOption[] = tabsList;
  fetchingFacilities: boolean = false;
  editNotificationText: string = 'Sorry, an error occurred!';
  showEditNotification: boolean = false;
  editNotificationType: string = 'danger';
  corporationName: string = '';
}

class CorporationForm extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = new StateType();
  }

  static getDerivedStateFromProps(
    { match: { params } }: PropsType,
    state: StateType
  ) {
    const isIdNumber = /^\d+$/.test(params.id);
    if (params.id !== state.prevCorpId) {
      return {
        prevCorpId: params.id,
        tabsList: isIdNumber
          ? tabsList
          : tabsList.map((tab, idx) =>
              idx ? { ...tab, disabled: true } : tab
            ),
      };
    }
    return null;
  }

  getData = () => {
    this.setState({ fetchingFacilities: true });

    CorporationOverviewPageActions.loadFacilityList().then(() => {
      this.setState({ fetchingFacilities: false });
    });
  };

  componentDidUpdate(prevProps: PropsType) {
    const { id } = this.props.match.params;
    if (!prevProps.match.params.id && id) {
      this.getData();
    }
  }

  componentWillUnmount() {
    CorporationOverviewPageActions.clear();
  }

  componentDidMount(): void {
    const { id } = this.props.match.params;

    if (id) {
      this.getData();
    }
  }

  updateContactTab(corporationName: string) {
    this.setState({ corporationName });
  }

  render() {
    const {
      props: { match, history },
      state,
    } = this;
    const corporationId = Number(match.params.id);
    const title = match.params.id ? state.corporationName : 'Add Corporation';

    return (
      <div className="corporation-form">
        <LayoutSideTitle title={title}>
          <Link className="btn btn-danger" to={URL_CORPORATION}>
            Back
          </Link>
        </LayoutSideTitle>
        <Tabs tabsList={state.tabsList} className="mb-4">
          <ContactTab
            corporationId={corporationId}
            history={history}
            updateCorporationName={this.updateContactTab.bind(this)}
          />

          <FacilitiesTab
            corporationId={corporationId}
            fetchingFacilities={state.fetchingFacilities}
            history={history}
          />

          <UsersTab corporationId={corporationId} />

          <BillingTab
            corporationId={corporationId}
            corporationName={state.corporationName}
            history={history}
          />
        </Tabs>
      </div>
    );
  }
}

export default CorporationForm;
