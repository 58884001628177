export class TPiModel {
  patient_diff_address: string = '';
  patient_diff_city: string = '';
  patient_diff_state: string = '';
  patient_diff_zipcode: string = '';
  patient_diff_phone: string = '';
  room: string = '';
  orderType: 'F' | 'O' | 'H' | 'C' | 'P' | '' = '';
  placeOfService: string = '';
  faceSheet: string = '';
  hospicePayment: string = '';
  patientId: number = null;
  facility: number = null;
  facilityState: string = '';
  facilityPhone: string = '';
  tempFaceSheet: string = '';
  lastName: string = '';
  firstName: string = '';
  dob: string = '';
  ssn: string = '';
  ice_number: string = '';
  inmate_number: string = '';
  immigration_id: string = '';
  medicare_id: string = '';
  medicaid_id: string = '';
  medicaid_stateid: string = '';
  ins_notes: string = undefined;
  clientMRN: string = '';
  //billingResponsibility: string = '';
  lastModifiedLock: string = '';
}

export class TPiModelExt extends TPiModel {
  middleName: string = '';
  patientGender: 'M' | 'F' | 'U' | '' = 'U';
}
