import ProcedureSummaryPageService from 'services/reports/custom-reports/procedure-summary/ProcedureSummaryPageService';
import { Actions } from 'stores/reports/custom-reports/procedure-summary/ProcedureSummaryPageStore';
import appDispatcher from 'dispatcher/AppDispatcher';

export default class ProcedureSummaryPageActions {
  static clear() {
    appDispatcher.dispatch({
      type: Actions.CLEAR_STORE_ACTION,
      data: {},
    });
  }

  static getSumProcedCodeReport(
    conditionList,
    joint,
    startIndex,
    limit,
    oldListCount
  ) {
    return ProcedureSummaryPageService.getSumProcedCodeReport(
      conditionList,
      joint,
      startIndex,
      limit,
      oldListCount
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_SUMMARY_LIST,
        subList: response.subList,
        listCount: response.listCount,
      });
    });
  }

  static createPdf(dataSource, formatDescription) {
    return ProcedureSummaryPageService.createPdf(dataSource, formatDescription);
  }
}
