import { Container } from 'flux/utils';

import TechExamCountPageActions from 'actions/reports/report-creator/tech-exam-count/TechExamCountPageActions';
import TechExamCountPageStore from 'stores/reports/report-creator/tech-exam-count/TechExamCountPageStore';
import PieChart from 'components/project/common/charts/PieChart';
import AbstractReportPage from '../AbstractReportPage';
import { prettierFilterPayload } from '../filter/prettierFilterPayload';

import { TECH_EXAM_COUNT as PAGE_ID } from 'constant/pagesId/reports';

const COLUMNS = [
  { name: 'techname', title: 'Technologist name' },
  { name: 'asscnt', title: 'Assigned exam count' },
  { name: 'procnt', title: 'Processed exam count' },
  { name: 'comcnt', title: 'Completed exam count' },
];

export class TechExamCountPage extends AbstractReportPage {
  static getStores() {
    return [TechExamCountPageStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = AbstractReportPage.getPageState('tech_exam_count_grid');
      prevState.paginationStrategy = AbstractReportPage.LOGIC_PAGINATION;
    }
    return {
      ...prevState,
      appId: PAGE_ID.PAGE,
      dataSource: TechExamCountPageStore.getState().techExamCountList,
      chartValues: TechExamCountPageStore.getState().techExamCountDiagram,
    };
  }

  getTitle() {
    return 'Tech Exam Count';
  }

  getColumns() {
    return COLUMNS;
  }

  getGridID() {
    return 'tech_exam_count_grid';
  }

  getExportedFileName() {
    return 'tech_exam_count';
  }

  getClass() {
    return 'tech-exam-count-overview-page';
  }

  renderChart() {
    return <PieChart data={this.state.chartValues} />;
  }

  getShownFilters() {
    return [
      'patientFirstName',
      'patientLastName',
      'mrn',
      'facility',
      'diagnosis',
      'cpt',
      'facilityNPI',
      'facilityPhone',
      'examCompletedDate',
      'technicianFirstName',
      'technicianLastName',
      'pos',
    ];
  }

  loadListAction(conditionList, joint) {
    const payload = prettierFilterPayload(conditionList);

    return TechExamCountPageActions.loadTechExamCountList(payload, joint);
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }

  getActions() {
    return TechExamCountPageActions;
  }
}

const techExamCountPage = Container.create(TechExamCountPage);
export default techExamCountPage;
