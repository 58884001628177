import { SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';

const Crash = () => {
  const history = useHistory();

  const handleClickBack = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    history.goBack();
    window.location.reload();
  };

  return (
    <div className="error-page-wrapper">
      <div className="error-page">
        <h2>SORRY</h2>
        <div className="error-page-title">Something went wrong on our end</div>
        <div className="error-page-body">
          Please{' '}
          <a href="/" className="text-primary" onClick={handleClickBack}>
            go back
          </a>{' '}
          and try again or go to{' '}
          <a href="/" className="text-primary">
            Home Page
          </a>
        </div>
      </div>
    </div>
  );
};

export default Crash;
