import React from 'react';
import { Container } from 'flux/utils';

import DialogConfirm from 'components/modal/dialogConfirm';
import { Button } from 'components/button';
import Badge from 'components/badge';
import { ControlsLayout, LayoutSideTitle } from 'components/layout';
import DialogMessagesInvoice from 'page/components/messages/DialogInvoice';
import EncounterMessageDialog from 'page/components/messages/EncounterMessageDialog';
import WrapperFormInvoiceBulkMode from './WrapperFormInvoiceBulkMode';
import WrapperFormInvoiceLineMode from './WrapperFormInvoiceLineMode';
import InvoiceGrid, { CellFieldPayload } from './InvoiceGrid';
import ChargeInfo from './ChargeInfo';
import PerDiem from './PerDiem';
import FormFees, { FormFeesValueType } from './FormFees';
import DialogFormWriteForm, {
  FormPayload,
} from '../components/DialogFormWriteForm';

import InvoicesActions from 'actions/billing-coding/InvoicesActions';
import UserProfileStore from 'stores/UserProfileStore';
import InvoicesStore from 'stores/billing-coding/InvoicesStore';
import { storeChatting } from 'stores/_mobx/message/chatting';
import { InvoiceType } from 'stores/_mobx/billingCodding/invoice';
import { storeInvoiceChargePosting } from 'stores/_mobx/billingCodding/invoiceChargePosting';
import { storeInvoice } from 'stores/_mobx/billingCodding/invoice';
import MathUtils from 'utils/MathUtils';

export interface PChargePosting {
  invoice: InvoiceType;
  flag: 'F' | 'P';
  title: string;
  callback: (shouldUpdate: boolean) => void;
}

export class SChargePosting {
  onAjax: boolean = false;
  writeOffValue: Omit<FormPayload, 'dataSource'> | null = null;
  modeOfPayment: number = 0;
  reverseBulkPost: boolean = false;
  messagesExist: boolean = false;
  notesErrors: Record<string, string> = {};
  notes: string = '';
  postType: 'L' | 'B' = null;
  perDiemData: any = {};
  fees: FormFeesValueType = {
    lateFee: 0,
    lateFeePaid: 0,
  };
  invoiceMessages: boolean = false;
  idForLog: number = 0;
  dataSource: Array<any> = [];
}

class ChargePosting extends React.Component<PChargePosting, SChargePosting> {
  static getStores() {
    return [InvoicesStore];
  }

  static calculateState(prevState: SChargePosting, props: PChargePosting) {
    if (!prevState) {
      const invoice = props.invoice;

      prevState = Object.assign(new SChargePosting(), {
        onAjax: false,
        writeOff: false,
        modeOfPayment: 0,
        reverseBulkPost: false,
        messagesExist: false,
        notesErrors: {},
        notes: '',
        postType: invoice.payType === 'LINE' ? 'L' : 'B',
        idForLog: 0,
        perDiemData: {},
        fees: {
          lateFee: Number(invoice.lateFee),
          lateFeePaid:
            invoice.payType === 'LINE'
              ? Number(invoice.lateFeePaid)
              : Number(invoice.lateFee),
        },
      });
    }
    const store = InvoicesStore.getState();
    return Object.assign({}, prevState, {
      dataSource: store.invoiceDetails,
    });
  }

  toggleMode = () => {
    const { lateFee, lateFeePaid } = this.props.invoice;
    const { postType } = this.state;

    const fees = {
      lateFee: Number(lateFee),
      lateFeePaid: postType === 'B' ? Number(lateFeePaid) : Number(lateFee),
    };

    this.setState(
      { fees, postType: postType === 'B' ? 'L' : 'B' },
      this.updateData
    );
  };

  review = () => {
    const writeOffValue = {
      note: '',
      invoice_no: this.props.invoice.invoiceNumber,
      total_price: this.props.invoice.total_price,
    };
    this.setState({ writeOffValue });
  };

  back = () => {
    this.props.callback(false);
  };

  handleCloseDialogWriteOff = () => {
    this.setState({ writeOffValue: null });
  };

  handleSubmitWriteOff = (payload: FormPayload) =>
    storeInvoiceChargePosting
      .moveInvoiceToAssignment(payload)
      .then((isSucceed) => {
        if (isSucceed) {
          this.props.callback(true);
        }
      });

  componentDidMount() {
    this.updateData();
    const invoiceId = this.props.invoice.invoiceNumber;
    storeChatting.hasLogByInvoices(invoiceId).then((r) => {
      this.setState({ messagesExist: r[invoiceId] });
    });
  }

  havePerDiem() {
    return this.props.flag === 'P';
  }

  updateData = () => {
    const { invoice } = this.props;
    this.setState({ onAjax: true }, () => {
      InvoicesActions.orderNewPayList(
        invoice.invoiceNumber,
        invoice.facilityId,
        this.props.flag
      ).then((v) => {
        const clb = () => {
          if (this.havePerDiem()) {
            InvoicesActions.getFacilityDetailPreinvoice(
              invoice.facilityId,
              invoice.invoiceNumber
            ).then(() => {
              this.setState(
                { perDiemData: InvoicesStore.getState().perDiemData },
                this.updateDataCallback
              );
            });
          } else {
            this.updateDataCallback();
          }
        };
        if (v && v[0]) {
          const markerData = v[0];
          this.setState(
            {
              notes: markerData.notes || '',
              modeOfPayment: Number(markerData.pay_mode),
            },
            clb
          );
        } else {
          clb();
        }
        return v;
      });
    });
  };

  updateDataCallback = () => {
    this.setState({
      onAjax: false,
    });
  };

  handleCloseDialogReverseBulk = () => {
    this.setState({ reverseBulkPost: false });
  };

  handleApproveReverseBulk = () => {
    this.handleCloseDialogReverseBulk();
    storeInvoiceChargePosting
      .reverseBulkPost(this.props.invoice.invoiceNumber)
      .then((isSucceed) => {
        if (isSucceed) this.props.callback(true);
      });
  };

  handleSetNotesErrors = (notesErrors: Record<string, string>) => {
    this.setState({ notesErrors });
  };

  renderButtons = () => {
    const modeIsSelected = Boolean(this.props.invoice.payType);

    const isReversVisible =
      this.props.invoice.payType === 'BULK' &&
      UserProfileStore.haveAccess('reverse_bulk_post');

    return (
      <ControlsLayout className="my-4">
        <Button
          disabled={modeIsSelected}
          text="Toggle post mode"
          onClick={this.toggleMode}
        />
        {UserProfileStore.haveAccess('write_off') && (
          <Button text="Review" onClick={this.review} />
        )}
        <Button
          onClick={() => this.setState({ invoiceMessages: true })}
          className="position-relative">
          Invoice Log
          {this.state.messagesExist && (
            <Badge
              rounded
              position="absolute"
              horizontal="right"
              variant="danger">
              !
            </Badge>
          )}
        </Button>
        {isReversVisible && (
          <Button
            text="Reverse Bulk Post"
            variant="danger"
            onClick={() => this.setState({ reverseBulkPost: true })}
          />
        )}
      </ControlsLayout>
    );
  };

  handleUpdateInvoice = ({ name, value, invoiceIndex }: CellFieldPayload) => {
    const { dataSource } = this.state;

    const finalData = dataSource.map((invoice, idx) => {
      if (idx === invoiceIndex) {
        const finalInvoice: any = {
          ...invoice,
          [name]: value,
        };

        return finalInvoice;
      }
      return invoice;
    });

    this.setState({ dataSource: finalData });
  };

  handleChangeFee = (fees: FormFeesValueType) => {
    this.setState({ fees });
  };

  handleOpenLogPopup = (idForLog: number) => {
    this.setState({ idForLog });
  };

  handleCloseLogPopup = (shouldUpdate: boolean) => {
    this.setState({ idForLog: 0 }, shouldUpdate ? this.updateData : undefined);
  };

  handleSubmitSuccess = () => {
    this.props.callback(true);
  };

  componentWillUnmount() {
    InvoicesActions.clearNewPayList();
    storeInvoice.clearInvoiceForChargePosting();
  }

  render() {
    const { props, state } = this;

    const isBulkMode = state.postType === 'B';

    const { invoice, title } = props;

    const pageTitle = `${title} Details ${
      isBulkMode ? '(BULK POST)' : '(LINE POST)'
    }`;

    return (
      <div className={(state.onAjax ? 'on-ajax' : '') + ' row'}>
        <LayoutSideTitle title={pageTitle}>
          <Button text="Back" variant="danger" onClick={this.back} />
        </LayoutSideTitle>

        {this.renderButtons()}

        <ChargeInfo order={invoice} />

        {this.havePerDiem() ? (
          <PerDiem
            perDiemData={state.perDiemData}
            postType={state.postType}
            onAjax={state.onAjax}
            updatePerDiem={this.updatePerDiem}
          />
        ) : null}

        <FormFees
          defaultValues={state.fees}
          isBulk={isBulkMode}
          onUpdate={this.handleChangeFee}
        />

        <InvoiceGrid
          dataSource={state.dataSource}
          dataSourceErrors={state.notesErrors}
          postType={state.postType}
          onUpdateInvoice={this.handleUpdateInvoice}
          onDisplayLog={this.handleOpenLogPopup}
        />

        {isBulkMode ? (
          <WrapperFormInvoiceBulkMode
            notes={state.notes}
            modeOfPayment={state.modeOfPayment}
            isInvoicePaid={invoice.payType === 'BULK'}
            perDiemDetails={props.flag === 'P' ? state.perDiemData : null}
            basicInvoiceDetails={props.invoice}
            fees={state.fees}
            invoices={state.dataSource}
            onSubmitSucceed={this.handleSubmitSuccess}
          />
        ) : (
          <WrapperFormInvoiceLineMode
            notes={state.notes}
            modeOfPayment={state.modeOfPayment}
            flag={props.flag}
            perDiemDetails={props.flag === 'P' ? state.perDiemData : null}
            basicInvoiceDetails={props.invoice}
            fees={state.fees}
            invoices={state.dataSource}
            setNotesErrorMessage={this.handleSetNotesErrors}
            onSubmitSucceed={this.handleSubmitSuccess}
          />
        )}
        <div />

        {state.reverseBulkPost && (
          <DialogConfirm
            onCancel={this.handleCloseDialogReverseBulk}
            onApprove={this.handleApproveReverseBulk}>
            Are you sure you would like to cancel the payment for the current
            invoice?
          </DialogConfirm>
        )}

        {state.writeOffValue && (
          <DialogFormWriteForm
            orders={state.dataSource}
            defaultValues={state.writeOffValue}
            onClose={this.handleCloseDialogWriteOff}
            onSubmit={this.handleSubmitWriteOff}
          />
        )}
        {state.invoiceMessages ? (
          <DialogMessagesInvoice
            id={invoice.invoiceNumber}
            onClose={this.invoiceMessages}
          />
        ) : null}
        {state.idForLog ? (
          <EncounterMessageDialog
            id={state.idForLog}
            onClose={this.handleCloseLogPopup}
          />
        ) : null}
      </div>
    );
  }

  updatePerDiem = (name: string, value: string | number, row: any) => {
    const pd = { ...this.state.perDiemData };
    const rows = [...pd.perdiem_rptrvalue];
    for (let i = 0; i < rows.length; i++) {
      if (rows[i] === row) {
        const clone = { ...row };
        clone[name] = value;
        clone.balance =
          MathUtils.parseFloat(clone.total) -
          MathUtils.parseFloat(clone.payment) -
          MathUtils.parseFloat(clone.writeoff);
        clone.balance = MathUtils.parseFloat(clone.balance);

        rows[i] = clone;
        pd.perdiem_rptrvalue = rows;
        this.setState({ perDiemData: pd });
        break;
      }
    }
  };

  invoiceMessages = (shouldUpdate: boolean) => {
    this.setState({ invoiceMessages: false });
    if (shouldUpdate) this.updateData();
  };
}

export default Container.create(ChargePosting, { withProps: true });
