import { useForm } from 'react-hook-form';

import Dialog, {
  DialogFooter,
  DialogHeader,
  DialogBody,
} from 'components/modal/dialog';
import { Input } from 'components/form/textField';
import { Select } from 'components/form/select';
import MaskInput from 'components/form/maskInput';
import { Textarea } from 'components/form/textarea';
import { Button } from 'components/button';
import { DropdownRolesGroup } from 'components/project/dropdown/RolesGroupDropdown';
import { DropDownFacilityPublic } from 'components/project/dropdown/FacilityDropdown';
import { validationRequestForLogin as resolver } from './validation';

const defaultValues: FormModel = {
  comments: '',
  email: '',
  facility: '',
  fax: '',
  firstName: '',
  group: '',
  lastName: '',
  otherTitle: '',
  phone: '',
  title: '',
};

const OPTIONS_TITLE = [
  { label: 'HUC Admin', value: 'HUC Admin' },
  { label: 'LPN', value: 'LPN' },
  { label: 'MANAGER', value: 'MANAGER' },
  { label: 'MD', value: 'MD' },
  { label: 'NPP', value: 'NPP' },
  { label: 'OTHER', value: 'OTHER' },
  { label: 'PA', value: 'PA' },
  { label: 'RN', value: 'RN' },
];

export interface FormModel {
  comments: string;
  email: string;
  facility: string;
  fax: string;
  firstName: string;
  group: string;
  lastName: string;
  otherTitle: string;
  phone: string;
  title: string;
}

interface PropsType {
  onClose: () => void;
  onSubmit: (data: FormModel) => Promise<any>;
}

const DialogFormRequestForLogin = ({ onClose, onSubmit }: PropsType) => {
  const {
    control,
    formState: { isSubmitting, isDirty },
    watch,
    reset,
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const isTitleOther = watch('title') === 'OTHER';

  const selectClassName = isTitleOther ? 'col-sm-6' : 'col-sm-12';

  const textClassName = isTitleOther ? 'col-sm-6' : 'visually-hidden';

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <Dialog handleClose={onClose}>
      <DialogHeader title="Request For Login" onClose={onClose} />
      <DialogBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="firstName"
            label="First Name"
            required
            control={control}
          />
          <Input name="lastName" label="Last Name" required control={control} />
          <div className="row">
            <Select
              name="title"
              label="Title"
              options={OPTIONS_TITLE}
              className={selectClassName}
              control={control}
            />
            <Input
              name="otherTitle"
              label="Other Title"
              className={textClassName}
              control={control}
            />
          </div>
          <DropDownFacilityPublic
            name="facility"
            label="Facility"
            control={control}
          />
          <DropdownRolesGroup
            name="group"
            label="Group"
            userType="E"
            control={control}
          />
          <Input name="email" label="Email" required control={control} />
          <MaskInput
            name="phone"
            label="Phone"
            format="###-###-####"
            required
            control={control}
          />
          <MaskInput
            name="fax"
            label="Fax"
            format="###-###-####"
            control={control}
          />
          <Textarea
            name="comments"
            label="Coments"
            rows={4}
            control={control}
          />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          text="Reset"
          variant="warning"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          text="Submit"
          disabled={!isDirty || isSubmitting}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogFormRequestForLogin;
