import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';

import { storeEmailTemplate } from 'stores/_mobx/systemSetup/masterSetting/emailTemplate';

interface PropsType extends Omit<SelectPropsType, 'options'> {
  userRoleId?: number;
}

const DropdownEmailTemplate = ({ userRoleId, ...props }: PropsType) => {
  const { fetchingOptions, options } = storeEmailTemplate;

  useEffect(() => {
    storeEmailTemplate.getOptions(userRoleId);
  }, [userRoleId]);

  return <Select {...props} options={options} isLoading={fetchingOptions} />;
};

export default observer(DropdownEmailTemplate);
