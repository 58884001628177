import React from 'react';
import { Container } from 'flux/utils';

import { getDateString } from 'utils/DateUtils';
import SignOrdersPageStore from 'stores/clinician-manager/sign-orders/SignOrdersPageStore';
import AbstractSignOrdersPage, {
  PAbstractSignOrdersPage,
  SAbstractSignOrdersPage,
  TAbstractSignOrdersPageFilter,
} from 'page/clinician-manager/sign-orders/AbstractSignOrdersPage';
import UserProfileStore from 'stores/UserProfileStore';
import SignOrdersPageActions from 'actions/clinician-manager/sign-orders/SignOrdersPageActions';
import { GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import { URL_SIGNED_ORDER_UNSIGNED_TAB } from 'constant/path/clinicalManager';
import { storeGrid } from 'stores/_mobx/grid';

export interface PUnsignedOrdersTab extends PAbstractSignOrdersPage {}

export class SUnsignedOrdersTab extends SAbstractSignOrdersPage {
  signMultyModal: boolean = false;
}

const GRID_ID = 'unsigned_orders_grid';

const getDefaultFilter = (isUserPhysician: boolean) => ({
  ...new TAbstractSignOrdersPageFilter(),
  signed: 'N',
  period: isUserPhysician ? 'A' : 'TD',
  fromDate: isUserPhysician ? '' : getDateString(),
  toDate: isUserPhysician ? '' : getDateString(),
});

export class UnsignedOrdersTab extends AbstractSignOrdersPage<
  PUnsignedOrdersTab,
  SUnsignedOrdersTab
> {
  static get TITLE() {
    if (UserProfileStore.getUserType() === 'D') {
      return 'My Unsigned Orders';
    }
    return 'Unsigned Orders';
  }

  static calculateState(prevState: SUnsignedOrdersTab) {
    if (!prevState) {
      prevState = {
        ...AbstractSignOrdersPage.getDefaultState(GRID_ID),
        filter: storeGrid.getFilter(
          GRID_ID,
          getDefaultFilter(UserProfileStore.getUserType() === 'D')
        ),
        signMultyModal: false,
      };
    }

    return {
      ...prevState,
      dataSource: SignOrdersPageStore.getState().signOrdersList,
      dataSourceCount: SignOrdersPageStore.getState().signOrdersCount,
    };
  }

  getDefaultFilter = () =>
    getDefaultFilter(UserProfileStore.getUserType() === 'D');

  handleClickSignOrder = () => {
    this.setState({ signMultyModal: true });
  };

  getControlPanel = () => {
    const isUserPhysician = UserProfileStore.getUserType() === 'D';

    return isUserPhysician ? (
      <GridControlButton
        title="Sign Order"
        onClick={this.handleClickSignOrder}
      />
    ) : null;
  };

  getContentBefore() {
    if (this.state.signMultyModal) {
      const confirm = (
        <DialogConfirm
          onCancel={this.handleCloseDialogSign}
          onApprove={this.handleApproveSign}>
          <div>Are you sure, you want to sign this order?</div>
        </DialogConfirm>
      );
      const out = super.getContentBefore();
      if (out) {
        return [out, confirm];
      }
      return confirm;
    }
    return super.getContentBefore();
  }

  handleCloseDialogSign = () => {
    this.setState({ signMultyModal: false });
  };

  handleApproveSign = () => {
    this.handleCloseDialogSign();
    SignOrdersPageActions.updateSignStatusByStaff(this.state.selected).then(
      () => this.updateData()
    );
  };

  goToOverview() {
    this.props.history.push(URL_SIGNED_ORDER_UNSIGNED_TAB);
  }

  getGridId() {
    return GRID_ID;
  }

  getTitle() {
    return UnsignedOrdersTab.TITLE;
  }

  renderOrderStatus(): React.ReactNode {
    return null;
  }

  getShownColumns() {
    let res: Array<string> = [];
    if (UserProfileStore.getUserType() === 'D') {
      res = [
        'sign',
        'dos',
        'last_name',
        'first_name',
        'facility',
        'physicianname',
        'exam_request',
        'visitInfo',
        'viewImage',
      ];
    } else {
      res = [
        'sign',
        'dos',
        'dostime',
        'last_name',
        'first_name',
        'room',
        'facility',
        'technologistnm',
        'physicianname',
        'visitInfo',
        'exam_request',
      ];
    }
    return res;
  }

  getAllowedColumns() {
    return [
      'sign',
      'dos',
      'dostime',
      'last_name',
      'first_name',
      'room',
      'facility',
      'technologistnm',
      'physicianname',
      'visitInfo',
      'images',
      'viewImage',
      'ordercreated_dt',
      'vw_patientid',
      'clientMRN',
      'gender',
      'patientdob',
      'ssn',
      'pos',
      'eligibility_status',
      'exam_request',
      'cpt',
      'diagnosis',
    ];
  }
}

const unsignedOrdersTab = Container.create(UnsignedOrdersTab);
export default unsignedOrdersTab;
