import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { Button } from 'components/button';
import MaskInput from 'components/form/maskInput';
import { Radio } from 'components/form/radio';
import { TwoSideMapper, filterRegionList } from 'components/form/twoSideMapper';
import DropdownMobileOperator from 'components/project/dropdown/MobileOperator';
import resolver from './validation';

import {
  availableMessage,
  PhoneFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/phone';
import { CheckboxGroup } from 'components/form/checkbox';

const columns = [{ title: 'Region', name: 'label' }];

const settings = {
  leftFirstPlaceholder: 'Region name',
  leftFirstClassName: 'col-sm-12',
  leftSecondClassName: 'd-none',
  rightFirstPlaceholder: 'Region name',
  rightFirstClassName: 'col-sm-12',
  rightSecondClassName: 'd-none',
};

const optionsStatus = [
  { label: 'All', value: 'A' },
  { label: 'Only assigned', value: 'S' },
];

interface PropsType {
  backUrl: string;
  defaultValues: PhoneFormModel;
  availableRegions: { label: string; value: number }[];
  onSubmit: (data: PhoneFormModel) => Promise<any>;
}

const FormModifyLead = ({
  backUrl,
  defaultValues,
  availableRegions,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    watch,
    reset,
    handleSubmit,
    setError,
  } = useForm<PhoneFormModel>({ defaultValues, resolver });

  const isOnlyAssigned = watch('status') === 'S';

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: PhoneFormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('phoneNumber', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={`row${isSubmitting ? 'on-ajax' : ''}`}
      onSubmit={handleClickSubmit}>
      <div className="col-md-8">
        <div className="row">
          <Input
            name="description"
            label="Phone Description"
            className="col-md-6 col-lg-4"
            required
            control={control}
          />
          <MaskInput
            name="phoneNumber"
            label="Phone number"
            className="col-md-6 col-lg-4"
            format="###-###-####"
            required
            control={control}
          />
          <DropdownMobileOperator
            name="operatorId"
            label="Carrier"
            className="col-md-6 col-lg-4"
            required
            control={control}
          />
        </div>
        <label className="form-label">Regions</label>
        <TwoSideMapper
          name="regions"
          titleLeft="Total available regions"
          titleRight="Total selected regions"
          disabled={isOnlyAssigned}
          settings={settings}
          columnLeft={columns}
          columnRight={columns}
          options={availableRegions}
          control={control}
          filterMethod={filterRegionList}
        />
      </div>

      <div className="col-md-4 mb-5">
        <Radio
          name="status"
          label="Receive orders"
          className="part-inline"
          options={optionsStatus}
          control={control}
        />
        <CheckboxGroup
          label="Message content"
          name="availableMessage"
          options={availableMessage}
          control={control}
        />
        <div className="fst-italic fw-lighter">
          *Check desired boxes for text message content above
        </div>
      </div>

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          type="reset"
          disabled={isSubmitting || !isDirty}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormModifyLead;
