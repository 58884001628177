import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import * as PATH from 'constant/path/other';

import E404 from 'page/errors/404';
import Greeting from 'page/homePage/Greeting';
import EditHomePage from 'page/homePage/EditPage';
import Wrapper from 'page/Wrapper';
import IntegrationsDashboard from 'page/integrations/IntegrationsDashboard';
import GridsSettingPageContainer from 'page/profile/GridsSettingPage';
import { DiagnosticViewingPage } from 'page/components/DiagnosticViewing';
import Messages, { messagesRoutes } from './Messages';
import SystemSetup, { systemSetupRoutes } from './SystemSetup';
import ClinicalManager, { clinicalManagerRoutes } from './ClinicalManager';
import PatientRecords, { patientRecordsPaths } from './PatientRecords';
import Workflow, { workflowRoutes } from './Workflow';
import BillingCoding, { billingCoddingRoutes } from './BillingCoding';
import Reports, { reportsRoutes } from './Reports';
import Privileges, { privilegesRoutes } from './Privileges';
import PersonalProfile, { personalProfileRoutes } from './PersonalProfile';
import { BACKEND_ROOT } from 'constant/config';

const ProtectedPages = (props: RouteComponentProps) => (
  <Wrapper {...props}>
    <Switch>
      <Route path="/" exact component={Greeting} />
      <Route path={messagesRoutes} component={Messages} />
      <Route path={systemSetupRoutes} component={SystemSetup} />
      <Route path={clinicalManagerRoutes} component={ClinicalManager} />
      <Route path={patientRecordsPaths} component={PatientRecords} />
      <Route path={workflowRoutes} component={Workflow} />
      <Route path={billingCoddingRoutes} component={BillingCoding} />
      <Route path={reportsRoutes} component={Reports} />
      <Route path={privilegesRoutes} component={Privileges} />
      <Route path={personalProfileRoutes} component={PersonalProfile} />
      <Route
        path={PATH.URL_INTEGRATION_DASHBOARD}
        component={IntegrationsDashboard}
      />
      <Route
        path={PATH.URL_GRID_SETTINGS}
        component={GridsSettingPageContainer}
      />
      <Route
        path={PATH.URL_DIAGNOSTIC_VIEWING}
        component={DiagnosticViewingPage}
      />
      <Route exact path={PATH.URL_EDIT_HOME_PAGE} component={EditHomePage} />
      {/* Next route needs for situation when user was redirected from login form to the link to document */}
      <Route
        path={BACKEND_ROOT}
        render={({ location: { pathname } }: any) => {
          window.location.pathname = pathname;
          return null;
        }}
      />
      <Route path={['*', '/404']} component={E404} />
    </Switch>
  </Wrapper>
);

export default ProtectedPages;
