import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Input } from 'components/form/textField';
import { DropdownRadiologist } from 'components/project/dropdown/RadiologistDropdown';
import { DropdownRadiology } from 'components/project/dropdown/RadiologyDropdown';

import {
  storeOrderTranscription,
  FilterType,
} from 'stores/_mobx/workflow/order/transcription';

interface PropsType {
  onChange: (payload: FilterType) => void;
}

const FilterViewResults = ({ onChange }: PropsType) => {
  const { control, watch, reset } = useForm<FilterType>({
    defaultValues: storeOrderTranscription.filter,
  });

  const radiologyGroupId = watch('radiologyGroupId');

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      const isRadiologyGroupChanged = name === 'radiologyGroupId';

      const payload = isRadiologyGroupChanged
        ? {
            ...formValue,
            radiologistId: 0,
          }
        : formValue;

      if (isRadiologyGroupChanged) {
        reset(payload);
      }

      onChange(payload as FilterType);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row">
      <Input
        name="cptDescription"
        label="CPT Description"
        className="col-md-4"
        control={control}
      />
      <DropdownRadiology
        name="radiologyGroupId"
        label="Radiology Group"
        className="col-md-4"
        control={control}
      />
      <DropdownRadiologist
        name="radiologistId"
        label="Radiologist"
        className="col-md-4"
        radiologyGroupId={radiologyGroupId}
        control={control}
      />
    </form>
  );
};

export default FilterViewResults;
