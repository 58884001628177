import { Control, useFieldArray, UseFormWatch } from 'react-hook-form';

import { IconButton } from 'components/button';
import DropdownMirthChannel from 'components/project/dropdown/MirthChannel';

import {
  defaultChannelValue,
  IntegrationDetailsType,
} from 'stores/_mobx/systemSetup/masterSetting/integrations';
import { storeDropdownMirthChannel } from 'stores/_mobx/dropDown/mirthChannels';

interface PropsType {
  name: 'ormChannels' | 'oruChannels' | 'adtChannels';
  isSettingsEnabled: boolean;
  control: Control<IntegrationDetailsType>;
  watch: UseFormWatch<IntegrationDetailsType>;
}

const Channels = ({ control, name, isSettingsEnabled, watch }: PropsType) => {
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name,
    keyName: 'fieldId',
  });

  const { options } = storeDropdownMirthChannel;
  const currentChannels = watch(name);

  const handleClickReplace = () => {
    replace([defaultChannelValue]);
  };

  const handleClickAddOrmChannel = () => {
    append(defaultChannelValue);
  };

  const isAddChannelsButtonDisabled =
    currentChannels.length >= options.length ||
    currentChannels.some(({ channel: { channelID } }) => !channelID);

  return (
    <>
      <small className="mb-2 d-block text-secondary">Outbound Channel ID</small>
      {fields.map((field, idx) => (
        <fieldset
          className="row mb-3"
          key={field.fieldId}
          disabled={!isSettingsEnabled}>
          <span style={{ width: '100px', marginTop: '5px' }}>Channel ID</span>
          <DropdownMirthChannel
            key={performance.now().toString(16)}
            className="col-md-6 col-lg-4 mb-0"
            name={`${name}.${idx}.channel.channelID`}
            required={isSettingsEnabled}
            control={control}
            currentChannels={currentChannels}
            index={idx}
          />
          <div className="d-flex text-primary col-sm-auto gap-3">
            <IconButton
              disabled={isAddChannelsButtonDisabled}
              onClick={handleClickAddOrmChannel}>
              <i className="bi bi-plus-circle" />
            </IconButton>
            <IconButton
              disabled={Boolean(!currentChannels[idx].channel.channelID)}
              onClick={
                fields.length > 1 ? () => remove(idx) : handleClickReplace
              }>
              <i className="bi bi-trash" />
            </IconButton>
          </div>
        </fieldset>
      ))}
    </>
  );
};

export default Channels;
