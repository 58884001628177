import { useEffect, useState } from 'react';
import clsx from 'clsx';

interface PropsType {
  title: string;
  isCollapsed?: boolean;
  isDisabled?: boolean;
  children?: React.ReactNode;
  render?: (props: any) => React.ReactNode;
  containerClassName?: string;
}

const Collapse = ({
  title,
  children,
  isCollapsed = true,
  isDisabled,
  render,
  containerClassName,
  ...rest
}: PropsType) => {
  const [isHidden, toggleVisibility] = useState(true);

  const wrapperClassName = clsx(`collapse-wrapper${isDisabled ? ' disabled' : ''}`, containerClassName);

  const contentClassName = `collapse-content${isHidden ? '' : ' open'}`;

  const iconClassName = `bi bi-caret-${isHidden ? 'right-fill' : 'down-fill'}`;

  const handleToggleVisibility = () => {
    if (!isDisabled) toggleVisibility((state) => !state);
  };

  useEffect(() => {
    toggleVisibility(isDisabled || isCollapsed);
  }, [isDisabled, isCollapsed]);

  return (
    <div className={wrapperClassName}>
      <div className="collapse-title" onClick={handleToggleVisibility}>
        <i className={iconClassName} />
        <span>{title}</span>
      </div>
      <div className={contentClassName}>
        <div className="expandable">{render ? render(rest) : children}</div>
      </div>
    </div>
  );
};

export default Collapse;
