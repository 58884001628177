import { action, makeObservable, observable, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

interface ProvidersListType {
  facilitytype: string;
  facilitytype_desc: string;
  refid: number;
}

interface ProviderResponseType {
  OrderType: { OrdId: number }[];
  facilityOrderType: [ProvidersListType];
}

export interface ProviderFormModel {
  orderId: Record<string, boolean>;
  type: string;
  description: string;
  id: number | null;
}

interface OptionResponseType {
  OrderType: { OrdId: string }[];
  data: string;
  label: string;
}

export interface OptionType {
  label: string;
  value: number;
}

class ProviderType {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  idForDelete: number = 0;
  providerType?: ProviderFormModel = undefined;
  providerTypeList: ProvidersListType[] = [];
  providerTypeTotal: number = 0;
  options: OptionType[] = [];
  // This property we are using in Master-settings->facility->facilityTab form
  orderMapToProvider: Record<number, { OrdId: string }[]> | null = null;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'email_group_grid' });

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      providerType: observable,
      providerTypeList: observable,
      providerTypeTotal: observable,
      idForDelete: observable,
      options: observable,

      setFetching: action,
      setFetchingOptions: action,
      setList: action,
      setTotal: action,
      setProviderForEdit: action,
      setIdForDelete: action,
      clearIdForDelete: action.bound,
      clearProviderType: action.bound,
    });
  }

  setFetching = (status: boolean) => {
    this.fetching = status;
  };

  setFetchingOptions = (status: boolean) => {
    this.fetchingOptions = status;
  };

  setProviderForEdit = (provider?: ProviderFormModel) => {
    this.providerType = provider;
  };

  setList = (list: ProvidersListType[]) => {
    this.providerTypeList = list;
  };

  setTotal = (total: number) => {
    this.providerTypeTotal = total;
  };

  setIdForDelete = (id: number) => {
    this.idForDelete = id;
  };

  clearIdForDelete = () => {
    this.idForDelete = undefined;
  };

  clearProviderType = () => {
    this.providerType = undefined;
  };

  async getProviderTypesList(type: string) {
    const { pagination } = this.page;

    const data = [pagination.skip, pagination.pageSize, type];

    try {
      const response = await apiRequest<'SE' | ProvidersListType[]>({
        url: 'facility.FacilityTypeMaster.GetFacilityTypeList',
        data,
      });

      if (Array.isArray(response)) {
        const list = response.map((el) => ({
          ...el,
          refid: Number(el.refid),
        }));
        this.setList(list);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setList([]);
    }
  }

  getProviderTypesCount = async (type: string) => {
    try {
      const total = await apiRequest<number>({
        url: 'facility.FacilityTypeMaster.GetTotalCountFacilityType',
        data: [type],
      });
      this.setTotal(Number(total) || 0);
    } catch (e) {
      this.setTotal(0);
    }
  };

  getProviderTypesMain = async (type: string) => {
    this.setFetching(true);

    const promiseList = this.getProviderTypesList(type);

    const promiseCount = this.getProviderTypesCount(type);

    Promise.allSettled([promiseList, promiseCount]).finally(() => {
      this.setFetching(false);
    });
  };

  async getProviderType(id: number) {
    this.setFetching(true);
    try {
      const response = await apiRequest<ProviderResponseType>({
        url: 'facility.FacilityTypeMaster.GetFacilityTypeById',
        data: [id],
      });

      const data = {
        orderId: response.OrderType.reduce(
          (prev, { OrdId }) => ({ ...prev, [OrdId]: true }),
          {}
        ),
        type: response.facilityOrderType[0]?.facilitytype || '',
        description: response.facilityOrderType[0]?.facilitytype_desc || '',
        id: response.OrderType.length ? id : null,
      };

      this.setProviderForEdit(data);
    } catch (e) {
      this.setProviderForEdit();
    } finally {
      this.setFetching(false);
    }
  }

  addProviderType = async (payload: ProviderFormModel) => {
    const options = Object.entries(payload.orderId).reduce(
      (prev, [key, value]) => (value ? prev.concat(Number(key)) : prev),
      []
    );
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'facility.FacilityTypeMaster.AddFacilityType',
        data: [payload.type, payload.description, options],
      });
      if (result === 'S') {
        Notification.success('Provider type was added successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This Provider type name already exist!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  };

  updateProviderType = async (payload: ProviderFormModel) => {
    const options = Object.entries(payload.orderId).reduce(
      (prev, [key, value]) => (value ? prev.concat(Number(key)) : prev),
      []
    );

    const data = [payload.id, payload.type, payload.description, options];
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'facility.FacilityTypeMaster.UpdateFacilityType',
        data,
      });

      if (result === 'S') {
        Notification.success('Provider type was updated successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This Provider type name already exist!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  };

  deleteProviderType = async () => {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'facility.FacilityTypeMaster.DeleteFacilityType',
        data: [this.idForDelete],
      });

      if (result === 'S') {
        Notification.success('Provider type was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  };

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOptions(true);

    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'facility.FacilityMaster.FacilityTypeDropDown',
      });

      const { options, orderMapToProvider } = response.reduce(
        (prev, { data, label, OrderType }) => {
          const value = Number(data);
          const options = prev.options.concat({ label, value });
          const orderMapToProvider = prev.orderMapToProvider
            ? { ...prev.orderMapToProvider, [value]: OrderType }
            : { [value]: OrderType };
          return {
            options,
            orderMapToProvider,
          };
        },
        { options: [], orderMapToProvider: null }
      );

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
        this.orderMapToProvider = orderMapToProvider;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
        this.orderMapToProvider = null;
      });
    }
  }

  findOption = (id: number) => this.options.find(({ value }) => value === id);
}

export const storeProviderType = new ProviderType();
