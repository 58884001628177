import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { phoneValidator } from 'utils/formValidators';

const faxValidation = yup.object().shape({
  receiverName: yup.string().required('Please fill in receiver name!'),
  faxNumber: yup.string().required('Please fill in fax number!').test({
    test: phoneValidator,
    message: 'Please, enter fax number in the correct format xxx-xxx-xxxx!',
  }),
});

const validation = yupResolver(
  yup.object().shape({
    faxes: yup.array().of(faxValidation),
    files: yup.array().test({
      test: (files: any[]) => files.some(({ isChecked }) => isChecked),
      message: 'Please, select at least 1 file!',
    }),
  })
);

export default validation;
