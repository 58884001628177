import { apiRequest } from 'services/RequestService';

export class TState {
  refid: number;
  state: string;
  is_operated: 'Y' | 'N';
  short_name: string;
  label: string;
  active_state: 'Active' | 'Inactive';
}

export class TStateTiny {
  refid?: number;
  state: string;
  short_name: string;
}

export class FacilityType {
  state: string;
  staterefid: number;
  region: string;
  regionrefid: number;
  location: string;
  locationrefid: number;
  pos: string;
  posrefid: number;
}

class FacilityDetailsService {
  getStateRegionLoc(facilityId: number) {
    return apiRequest<Array<FacilityType>>({
      url: 'generalmaster.MvLocation.GetSateRegionLoc',
      data: [facilityId],
    });
  }
}

export default new FacilityDetailsService();
