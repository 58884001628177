const SPECIAL_CHARS = "!@#$%^&*()_+{}:'<>?| []; ',./`~";
const LOWERCASE_CHARS = 'abcdefghijklmnopqrstuvwxyz';
const UPPERCASE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const DIGIT_CHARS = '0123456789';
const CharType = {
  SPECIAL: 'SPECIAL',
  DIGIT: 'DIGIT',
  UPPERCASE: 'UPPERCASE',
  LOWERCASE: 'LOWERCASE',
};

const removeArrayValue = (value: string, array: string[]): void => {
  const index = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
  }
};

const randomBetween = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min);

const generateCustomPassword = (): string => {
  const length = randomBetween(12, 14);

  const maxSpecials = randomBetween(1, 3);
  let specials = 0;

  const maxDigits = randomBetween(2, 4);
  let digits = 0;

  const maxUppercase = randomBetween(2, 4);
  let uppercase = 0;

  const maxLowercase = length - maxSpecials - maxDigits - maxUppercase;
  let lowercase = 0;

  let result = '';
  const types = [
    CharType.SPECIAL,
    CharType.DIGIT,
    CharType.UPPERCASE,
    CharType.LOWERCASE,
  ];

  for (let i = 0; i < length; i++) {
    const type = types[randomBetween(0, types.length - 1)];
    switch (type) {
      case CharType.SPECIAL:
        if (specials < maxSpecials) {
          result += SPECIAL_CHARS.charAt(
            randomBetween(0, SPECIAL_CHARS.length - 1)
          );
          specials++;
        } else {
          i--;
          removeArrayValue(CharType.SPECIAL, types);
        }
        break;
      case CharType.DIGIT:
        if (digits < maxDigits) {
          result += DIGIT_CHARS.charAt(
            randomBetween(0, DIGIT_CHARS.length - 1)
          );
          digits++;
        } else {
          i--;
          removeArrayValue(CharType.DIGIT, types);
        }
        break;
      case CharType.UPPERCASE:
        if (uppercase < maxUppercase) {
          result += UPPERCASE_CHARS.charAt(
            randomBetween(0, UPPERCASE_CHARS.length - 1)
          );
          uppercase++;
        } else {
          i--;
          removeArrayValue(CharType.UPPERCASE, types);
        }
        break;
      case CharType.LOWERCASE:
        if (lowercase < maxLowercase) {
          result += LOWERCASE_CHARS.charAt(
            randomBetween(0, LOWERCASE_CHARS.length - 1)
          );
          lowercase++;
        } else {
          i--;
          removeArrayValue(CharType.LOWERCASE, types);
        }
        break;
    }
  }
  return result;
};

const availableChars =
  '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const passwordGenerator = (passwordLength: number = 8) => {
  let password = '';
  for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * availableChars.length);
    password += availableChars.substring(randomNumber, randomNumber + 1);
  }
  return password;
};

export default generateCustomPassword;
