import React from 'react';

import BillingAddress from 'page/billing-coding/components/BillingAddress';
import { PreInvoiceExportLink } from 'page/components/ExportLink';
import DialogMessagesFacility from 'page/components/messages/DialogFacility';
import FacilityPreview from 'page/billing-coding/FacilityPreview';
import FacilityOverdueAmountsModal from 'page/billing-coding/FacilityOverdueAmountsModal';
import { PropsTypeWitDivisionSettings } from 'components/HOC';
import { IconButton } from 'components/button';
import { Grid, GridControlButton, TPagination } from 'components/grid';
import Notification from 'components/modal/Notification';
import DialogAlert from 'components/modal/DialogAlert';
import Filter, { filterDefaultValues, FilterType } from './components/Filter';
import DialogPrompt from '../components/DialogPrompt';

import InvoicesActions from 'actions/billing-coding/InvoicesActions';
import PreInvoicesActions from 'actions/billing-coding/PreInvoicesActions';
import { TPreinvoiceMultipleInsertUpdate } from 'services/billing-coding/PreInvoicesService';
import { storeGrid } from 'stores/_mobx/grid';
import PreInvoicesStore from 'stores/billing-coding/PreInvoicesStore';
import { BillAddressFormType } from 'stores/_mobx/billingCodding/billAddress';
import CsvExporter from 'utils/CsvExporter';
import { getPagination } from 'utils/getPagination';

const allowedCols = [
  'facilitynm',
  'total_price',
  'paid',
  'balance',
  'overpaid',
  'invoice_dt',
  'invoice_alphanum',
  'division',
];

export interface PAbstractPreInvoices extends PropsTypeWitDivisionSettings {
  isFilterVisible: boolean;
  title: string;
  handleToggleInvoiceGeneration: (isVisible: boolean) => void;
}

export class SAbstractPreInvoices {
  onAjax: boolean = false;
  dataSource: Array<any> = [];
  dataSourceCount: number = 0;
  selected: Array<any> = [];
  emailMessageError: Array<any> = [];
  pagination: TPagination = new TPagination();
  filter: FilterType = filterDefaultValues;
  chargePosting: boolean = false;
  billingAddress: boolean = false;
  preInvoiceMessages: boolean = false;
  generateInvoices: boolean = false;
  email: boolean = false;
  emailValue: string = '';
  order: any = null;
  response: Array<any> = null;
  traversableAddress: any = {};
  billingAddressNotification: any = null;
  overdueAmountModal: boolean = false;
  invoiceGenerated: string = null;
  gridId: string = '';
}

export default class AbstractPreInvoices<
  P extends PAbstractPreInvoices,
  S extends SAbstractPreInvoices
> extends React.Component<P, S> {
  abortController: AbortController | null = null;

  static TITLE: string = '';

  static getStores() {
    return [PreInvoicesStore];
  }

  static calculateState(prevState: SAbstractPreInvoices, gridId: string) {
    if (!prevState) {
      prevState = {
        ...new SAbstractPreInvoices(),
        pagination: getPagination(storeGrid.getPageSize(gridId)),
        filter: storeGrid.getFilter(gridId, filterDefaultValues),
        gridId,
      };
    }

    const store = PreInvoicesStore.getState();
    return {
      ...prevState,
      dataSourceCount: store.dataSourceCount,
      dataSource: store.dataSource,
    };
  }

  handleGeneratePerDiemInvoice?(facilityId: number): void;

  handleChangeFilter = (name: string, value?: string) => {
    const filter = { ...this.state.filter, [name]: value };

    this.setState({ filter });
  };

  handleClickOverdueAmountModal = () => {
    this.setState({ overdueAmountModal: true });
  };

  componentDidMount() {
    this.updateData();
  }

  componentWillUnmount() {
    const { gridId, filter } = this.state;

    storeGrid.saveFilter(gridId, filter);

    PreInvoicesActions.clearStore();

    if (this.abortController) {
      this.abortController.abort();
    }
  }

  exportCsv() {
    const columns = this.getColumns().filter(({ name }) =>
      allowedCols.includes(name)
    );

    const csvExporter = new CsvExporter(this.state.dataSource, columns);

    csvExporter.exportAndDownload('Facility_Invoices');
  }

  prepareOverdueAmountData() {
    const { selected, dataSource } = this.state;

    return dataSource.filter((item) => selected.indexOf(item.orderid_grp) > -1);
  }

  getGridId(): string {
    throw new ReferenceError('Implement me');
  }

  onPaginationChange = (pagination: TPagination) => {
    this.setState({ pagination }, this.updateData);
  };

  handleCloseBillAddress = () => {
    this.setState({ billingAddress: false });
  };

  handleSubmitBillAddress = (data: BillAddressFormType) => {
    const flag = data.addressVariant;

    const address = {
      facilityId: this.state.order.facilityId,
      invoice_no: null as null,
      flag,
      ...data[flag],
    };

    this.setState({
      billingAddress: false,
      billingAddressNotification: this.prepareBillingNotification(address),
      traversableAddress: this.prepareTraversableAddress(address),
    });
    return Promise.resolve();
  };

  handleApplyFilter = (filter: FilterType) => {
    const pagination = {
      ...this.state.pagination,
      page: 1,
      skip: 0,
    };

    this.setState({ pagination, filter }, this.updateData);
  };

  render() {
    const { order, ...state } = this.state;
    const filter = state.filter;

    if (state.generateInvoices) {
      const facilityPreviewTitle = order.facilitynm
        ? `${this.props.title} "${order.facilitynm}"`
        : this.props.title;

      return (
        <FacilityPreview
          invoiceInfo={order}
          canSeePage
          invoiceType={this.getInvoiceType()}
          title={facilityPreviewTitle}
          onClose={this.handleCloseInvoiceForm}
          callback={this.closeGenerateInvoices}
          filter={{
            invoiceEditFlag: '',
            perDiem: this.isPerDiem(),
            dosFrom: filter.dosStart,
            dosTo: filter.dosEnd,
            invoiceType: this.getInvoiceType(),
            pdfFlag: false,
            lastName: filter.lastName,
            firstName: filter.firstName,
            patientId: 0,
            orderIds: '',
          }}
        />
      );
    }

    return (
      <>
        <Filter
          fetching={state.onAjax}
          isVisible={this.props.isFilterVisible}
          defaultValues={state.filter}
          generateInvoice={this.handleGeneratePerDiemInvoice}
          onSubmit={this.handleApplyFilter}
        />
        <Grid
          id={this.getGridId()}
          onAjax={state.onAjax}
          pagination={state.pagination}
          onPaginationChange={this.onPaginationChange}
          columns={this.getColumns()}
          dataSource={state.dataSource}
          gridControlPanel={
            <>
              <GridControlButton
                title="Generate Invoices"
                disabled={!state.selected.length}
                onClick={this.handleClickOverdueAmountModal}
              />
              <GridControlButton
                title="Email"
                disabled={!state.selected.length}
                onClick={this.handleClickShowEmail}
              />
            </>
          }
          selectId="orderid_grp"
          ref="grid"
          onSelectChange={this.onUpdateSelection}
          dataSourceCount={state.dataSourceCount}
        />
        {state.billingAddress && (
          <BillingAddress
            facilityId={order.facilityid}
            invoiceno={order.invoice_no}
            onClose={this.handleCloseBillAddress}
            onSubmit={this.handleSubmitBillAddress}
          />
        )}
        {state.preInvoiceMessages ? (
          <DialogMessagesFacility
            id={order.facilityid}
            onClose={(shouldUpdate: boolean) => {
              this.setState({ preInvoiceMessages: false });
              if (shouldUpdate) this.updateData();
            }}
          />
        ) : null}
        {state.email && (
          <DialogPrompt
            title="Email"
            label="Message"
            defaultValue={state.emailValue}
            onSubmit={this.handleSubmitEmails}
            onClose={this.handleCloseDialogEmail}
          />
        )}
        {state.response ? (
          <DialogAlert
            title="Lattice Pro"
            onApprove={() => this.setState({ response: null })}>
            {this.buildResponseMessage()}
          </DialogAlert>
        ) : null}
        {state.invoiceGenerated && (
          <DialogAlert
            text={`Invoice number: ${state.invoiceGenerated}`}
            onApprove={() => this.setState({ invoiceGenerated: null })}
          />
        )}
        {state.billingAddressNotification ? (
          <DialogAlert
            onApprove={() =>
              this.setState({ billingAddressNotification: null })
            }>
            {state.billingAddressNotification}
          </DialogAlert>
        ) : null}
        {state.overdueAmountModal && (
          <FacilityOverdueAmountsModal
            dataSource={this.prepareOverdueAmountData()}
            callback={(response, data) =>
              this.overdueAmountModalCallback(response, data)
            }
          />
        )}
      </>
    );
  }

  overdueAmountModalCallback(response: any, data: any) {
    this.setState({ overdueAmountModal: false }, () => {
      if (response) this.generateInvoices(data);
    });
  }

  prepareTraversableAddress(address: any) {
    const traversableAddress = Object.assign({}, this.state.traversableAddress);
    traversableAddress[this.state.order.facilityid] = address;
    return traversableAddress;
  }

  prepareBillingNotification(address?: any) {
    return (
      <p>
        Custom billing address will be used only after invoice generation.
        <br />
        This address will be used only once.
        <br />
        {`Please generate invoice for ${this.state.order.facilitynm} to process.`}
      </p>
    );
  }

  buildResponseMessage() {
    const { response } = this.state;
    if (response && response.length) {
      const out = [<p key="desc">Invoice has been successfully generated</p>];
      response.forEach((message, i) => {
        out.push(<p key={i}>{message}</p>);
      });
      return out;
    }
    return "Can't create invoice due unknown reasons";
  }
  handleCloseInvoiceForm = () => {
    this.props.handleToggleInvoiceGeneration(false);
    this.setState({
      generateInvoices: false,
    });
  };

  closeGenerateInvoices = (value: TPreinvoiceMultipleInsertUpdate) => {
    this.setState(
      { generateInvoices: false, invoiceGenerated: value ? value.label : null },
      () => {
        if (value) this.updateData();
      }
    );
  };

  generateInvoices(overdueData: Array<{ facilityid: number }>) {
    this.setState({ onAjax: true }, () => {
      const orders = [];
      const { selected } = this.state;
      const ds = this.state.dataSource;
      for (let i = 0; i < selected.length; i++) {
        for (let j = 0; j < ds.length; j++) {
          if (selected[i] === ds[j].orderid_grp) {
            const order = Object.assign({}, ds[j], {
              dueData: AbstractPreInvoices.findDue(
                ds[j].facilityid,
                overdueData
              ),
            });
            orders.push(order);
          }
        }
      }

      const { filter } = this.state;
      PreInvoicesActions.preinvoiceMultipleInsertUpdate(
        orders,
        this.isPerDiem(),
        filter.dosStart,
        filter.dosEnd,
        this.getInvoiceType(),
        filter.lastName,
        filter.firstName
      ).then((r: Array<TPreinvoiceMultipleInsertUpdate>) =>
        this.postProcessInvoices(r)
      );
    });
  }

  static findDue(facilityId: number, dueData: Array<{ facilityid: number }>) {
    for (let i = 0; i < dueData.length; i++) {
      if (dueData[i].facilityid === facilityId) {
        return dueData[i];
      }
    }
    return null;
  }

  postProcessInvoices(r: Array<TPreinvoiceMultipleInsertUpdate>) {
    const labels: Array<any> = [];
    r.forEach((v) => labels.push(v.label));
    this.setState({ response: labels }, () => {
      const billAddressCandidates: Array<any> = [];
      const traversableAddress = Object.assign(
        {},
        this.state.traversableAddress
      );
      r.forEach((v) => {
        const item = traversableAddress[v.facility];
        if (item) {
          billAddressCandidates.push(
            Object.assign({}, item, { invoice_no: v.invoice })
          );
          delete traversableAddress[v.facility];
        }
      });
      if (billAddressCandidates.length > 0) {
        this.setState({ traversableAddress }, () => {
          const arrayPromises = billAddressCandidates.map((address) =>
            this.saveAddress(address)
          );
          Promise.all(arrayPromises).then(this.updateData);
        });
      } else {
        this.updateData();
      }
    });
  }

  saveAddress(address: any) {
    return InvoicesActions.saveInvoiceAddress(
      address.facilityId,
      address.address1,
      address.address2,
      address.city1,
      address.city2,
      address.state1,
      address.state2,
      address.zipcode1,
      address.zipcode2,
      address.flag,
      address.invoice_no
    );
  }

  isPerDiem() {
    return 'N';
  }

  handleClickShowEmail = () => {
    this.setState({ email: true });
  };

  handleCloseDialogEmail = () => {
    this.setState({ email: false, emailValue: '' });
  };

  handleSubmitEmails = (message: string) => {
    const { selected, dataSource } = this.state;

    const orders = dataSource
      .filter(({ orderid_grp }) => selected.includes(orderid_grp))
      .map((invoice) => ({
        bill_email: invoice.facility_billemail,
        facilitynm: invoice.facilitynm,
        facilityid: invoice.facilityid,
      }));

    const receiversWithoutEmail = orders.filter(
      ({ bill_email }) => !bill_email
    );

    const receivers = orders.filter(({ bill_email }) => Boolean(bill_email));

    const facilitiesList = receiversWithoutEmail
      .map(({ facilitynm }) => facilitynm)
      .join(', ');

    if (receiversWithoutEmail.length) {
      Notification.warning(
        `Few facilities don't have billing email address: ${
          facilitiesList.length > 100
            ? facilitiesList.substring(0, 99) + '... '
            : facilitiesList
        }. Add email address first and try send message again!`
      );
    }
    if (receivers.length) {
      PreInvoicesActions.email(message, receivers)
        .then(() => {
          Notification.success('Email sent successfully');
        })
        .finally(() => {
          this.handleCloseDialogEmail();
        });
    } else {
      this.handleCloseDialogEmail();
    }
  };

  getColumns() {
    const { filter } = this.state;

    const { isDivisionEnabled } = this.props;

    return [
      {
        name: 'preInvoiceEdit',
        title: 'Pre-Invoice Edit',
        render: (refid: any, order: any) => (
          <IconButton
            className="text-primary fs-5"
            onClick={() => {
              this.setState(
                {
                  order,
                  generateInvoices: true,
                },
                () => this.props.handleToggleInvoiceGeneration(true)
              );
            }}>
            <i className="bi bi-check-circle" />
          </IconButton>
        ),
      },
      { name: 'assigned_count', title: '# of Pts Assigned' },
      { name: 'unassigned_count', title: '# of Pts Un-assigned' },
      {
        name: 'billingAddress',
        title: 'Billing Address',
        render: (v: any, order: any) => {
          const link = (
            <IconButton
              className="text-primary"
              onClick={() => {
                this.setState({ billingAddress: true, order });
              }}>
              <i className="bi bi-building" />
            </IconButton>
          );
          if (this.state.traversableAddress[order.facilityid]) {
            return (
              <p className="d-flex gap-3 align-items-center">
                <IconButton
                  className="text-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    const traversableAddress = {
                      ...this.state.traversableAddress,
                    };

                    delete traversableAddress[order.facilityid];
                    this.setState({ traversableAddress });
                  }}>
                  <i className="bi bi-x-circle" />
                </IconButton>
                {link}
              </p>
            );
          } else {
            return link;
          }
        },
      },
      { name: 'facilitynm', title: 'Facility' },
      ...(isDivisionEnabled ? [{ name: 'division', title: 'Division' }] : []),
      {
        name: 'billingPreview',
        title: 'Invoice Preview',
        render: (v: any, order: any) => {
          const invoiceDetail = {
            facilityId: Number(order.facilityid),
            invoiceNo: order.preinvoice_no,
            invoiceAlpha: order.preinvoice_alpha,
            fromDate: filter.dosStart,
            toDate: filter.dosEnd,
            invoiceType: this.getInvoiceType(),
            lastName: filter.lastName,
            firstName: filter.firstName,
          };
          return <PreInvoiceExportLink invoiceDetail={invoiceDetail} />;
        },
      },
      {
        name: 'invoiceMessage',
        title: 'Invoice Message',
        render: (v: any, order: any) => (
          <IconButton
            className="text-primary"
            onClick={() => {
              this.setState({ preInvoiceMessages: true, order });
            }}>
            <i
              className={`bi ${
                order.hasLog ? 'bi-envelope-check-fill' : 'bi bi-envelope'
              }`}
            />
          </IconButton>
        ),
      },
    ];
  }

  updateData = () => {
    const { isDivisionEnabled } = this.props;

    const filter = {
      ...this.state.filter,
      division: isDivisionEnabled ? this.state.filter.division : 0,
    };

    const controller = new AbortController();

    const signal = controller.signal;

    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = controller;

    this.setState({ onAjax: true }, () => {
      PreInvoicesActions.loadGridData(
        filter,
        this.state.pagination,
        this.getInvoiceType(),
        signal
      ).then(this.updateDataCallback);
    });
  };

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.setState({
      onAjax: false,
      selected: [],
    });

    this.abortController = null;

    if (grid) {
      // @ts-ignore
      grid.clearSelection();
    }
  };

  getInvoiceType(): 'D' | 'F' | 'H' {
    throw new ReferenceError('Override this method');
  }

  onUpdateSelection = (selected: Array<any>) => {
    this.setState({ selected });
  };
}
