import ConstBuilder from 'constant/util/ConstantBuilder';

const PREFIX = 'jurisdiction-jurisdiction-details-';

const Actions = ConstBuilder.build(PREFIX, {
  TOGGLE_JURISDICTION_DETAILS_LOADING: null,

  LOAD_DETAILS: null,

  LOAD_MODALITY_LIST: null,
  LOAD_STATE_LIST: null,

  LOAD_ZIP_CODES: null,

  LOAD_ZIP_CODE_RANGES: null,

  TOGGLE_ZIP_CODE_DICTIONARY_MODAL: null,
  TOGGLE_ZIP_CODE_RANGE_DETAILS_MODAL: null,

  TOGGLE_ZIP_CODE_DELETE_CONFIRM: null,
  TOGGLE_ZIP_CODE_RANGE_DELETE_CONFIRM: null,

  SELECT_ZIP_CODES: null,
  SELECT_ZIP_CODE_RANGES: null,

  DELETE_ZIP_CODE: null,
  ADD_ZIP_CODE: null,

  DELETE_ZIP_CODE_RANGE: null,
  ADD_ZIP_CODE_RANGE: null,

  TOGGLE_RESET_CONFIRM: null,
  TOGGLE_SAVE_CONFIRM: null,

  CREATE: null,
  UPDATE: null,

  UPDATE_ZIP_CODE_FILTER: null,
  UPDATE_ZIP_CODE_RANGE_FILTER: null,

  TOGGLE_ZIP_CODES_LOADING: null,
  TOGGLE_ZIP_CODE_RANGES_LOADING: null,

  UPDATE_MODEL_AND_ERRORS_PROPERTY: null,
  UPDATE_MODEL_AND_ERRORS: null,
  RESET_MODEL_AND_ERRORS: null,

  CHANGE_ZIP_CODE_LIST_PAGINATION: null,
  CHANGE_ZIP_CODE_RANGE_LIST_PAGINATION: null,
});
Actions.getPrefix = (): string => PREFIX;
Actions.contains = (action: string) =>
  action && ~action.indexOf(Actions.getPrefix());

export default Actions;
