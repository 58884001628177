import React from 'react';
import { Container } from 'flux/utils';
import { Link } from 'react-router-dom';

import { Button } from 'components/button';
import { Grid } from 'components/grid';
import Form from 'components/form/Form';
import Notification from 'components/modal/Notification';
import UniversalIdTypeDropdown from 'components/project/dropdown/UniversalIdTypeDropdown';
import DialogConfirm from 'components/modal/dialogConfirm';
import Text from 'components/form/input/Text';
import Select from 'components/form/input/Select';
import { TPagination } from 'components/grid/GridTypes';
import Badge from 'components/badge';
import { LayoutSideTitle } from 'components/layout';
import ShowOrders from './components/ShowOrders';
import ServiceNotAvailable from './components/ServiceNotAvailable';
import SettingsForm from './components/SettingsForm';

import SendingApplicationsStore from 'stores/system-setup/masterSettings/integrations/SendingApplicationsStore';
import SendingApplicationsActions, {
  TPage,
  SendingApplicationFormDto,
} from 'actions/system-setup/master-setup/master-settings/integrations/SendingApplicationsActions';
import { getPagination } from 'utils/getPagination';
import { URL_INTEGRATION_MASTER } from 'constant/path/systemSetup';
import { URL_MASTER_SETTINGS } from 'constant/path/systemSetup';
import { storeIntegration } from 'stores/_mobx/integration';

interface IntegrationPropsType {
  name: string;
  id: number;
}

interface PSendingApplications {}

class TSendingApplicationModel {
  login: string = '';
  password: string = '';
  app_sn: string = '';
}

class TSendingApplicationFilter {
  appSn: string = '';
  namespace: string = '';
  universalIdType: number = 0;
  user: string = '';
  communication: string = '';
}

class SSendingApplications {
  onAjax: boolean = false;
  pagination = getPagination();
  selectedLine: SendingApplicationFormDto = null;
  changeStatus: string | boolean = '';
  model: TSendingApplicationModel = new TSendingApplicationModel();
  errors: any = {};
  filter: TSendingApplicationFilter = new TSendingApplicationFilter();
  dataSource: Array<SendingApplicationFormDto> = [];
  dataSourceCount: number = 0;
  loginError: boolean = false;
  authError: boolean = false;
  isServiceAvailable: boolean = true;
  showFilter: boolean = false;
  integrationProps: IntegrationPropsType | null = null;
}

export class SendingApplications extends React.Component<
  PSendingApplications,
  SSendingApplications
> {
  filterForm: Form;

  constructor(props: PSendingApplications) {
    super(props);
    this.filterForm = null;
  }

  COLUMNS = [
    { name: 'namespace', title: 'Name' },
    {
      name: 'universalIdTypeEntity',
      title: 'ID',
      render: (v: any) => v?.code || '',
    },
    {
      name: 'username',
      title: 'On Behalf of',
      render: (v: any) => SendingApplicationsStore.getState().userNames[v],
    },
    {
      name: 'isSftp',
      title: 'Protocol',
      render: (isSftp: boolean) => (isSftp ? 'SFTP' : 'TCP/IP'),
    },
    {
      name: 'is_subscriber',
      title: 'Send Orders',
      render: (isSubscriber: boolean) => (isSubscriber ? 'YES' : 'NO'),
    },
    {
      name: 'active',
      title: 'Status',
      render: (active: boolean) => (
        <Badge variant={active ? 'success' : 'danger'}>
          {active ? 'Active' : 'Inactive'}
        </Badge>
      ),
    },
    {
      name: 'control',
      title: 'Control',
      className: 'text-center',
      render: (v: any, row: SendingApplicationFormDto) =>
        this.renderControl(row),
    },
  ];

  static calculateState(prevState: SSendingApplications) {
    const state = SendingApplicationsStore.getState();

    return {
      ...(prevState || new SSendingApplications()),
      isServiceAvailable: state.isServiceAvailable,
      authError: state.authError,
      dataSource: state.dataSource,
      dataSourceCount: state.dataSourceCount,
    };
  }

  static getStores() {
    return [SendingApplicationsStore];
  }

  handleOpenOrders = (integrationProps: IntegrationPropsType) => {
    this.setState({
      integrationProps,
    });
  };

  handleCloseOrders = () => {
    this.setState({
      integrationProps: null,
    });
  };

  componentDidMount() {
    storeIntegration.getAllOptions();
    this.updateData();
  }

  updateData = () => {
    this.setState({ onAjax: true });
    SendingApplicationsActions.loadAppSn().then((response) => {
      const filter = { ...this.state.filter, appSn: response.app_sn };

      this.setState({ filter }, () => {
        SendingApplicationsActions.updateData(
          this.state.filter,
          this.state.pagination
        )
          .then(
            (
              response: TPage<SendingApplicationFormDto> | { status: string }
            ) => {
              const page = response as TPage<SendingApplicationFormDto>;

              this.setState(
                { dataSource: page.content, dataSourceCount: page.total },
                () => {
                  let allHere = true;
                  const userNames =
                    SendingApplicationsStore.getState().userNames;
                  this.state.dataSource.forEach((v) => {
                    const name = userNames[v.username];
                    if (!name) {
                      allHere = false;
                    }
                  });
                  if (!allHere) {
                    return SendingApplicationsActions.mapLogins(
                      this.state.dataSource.map((v) => v.username)
                    );
                  }
                  return {};
                }
              );
            }
          )
          .finally(() => {
            this.setState({ onAjax: false });
          });
      });
    });
  };

  onUpdateModel(name: string, value: string, err?: any) {
    const { state } = this;

    const model = {
      ...state.model,
      [name]: value,
    };

    const errors = {
      ...state.errors,
      [name]: err,
    };

    this.setState({ model, errors });
  }

  onPaginationChange = (pagination: TPagination) => {
    this.setState({ pagination, onAjax: true }, this.updateData);
  };

  render() {
    const state = this.state;
    const showFilter = state.showFilter;

    if (!state.isServiceAvailable)
      return (
        <ServiceNotAvailable
          fetching={state.onAjax}
          onRetry={this.updateData}
        />
      );

    return state.integrationProps ? (
      <ShowOrders
        application={state.integrationProps.id}
        callback={this.handleCloseOrders}
        title={state.integrationProps.name}
      />
    ) : (
      <>
        <LayoutSideTitle title="Integrations master">
          <>
            <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
              Back
            </Link>
            <Button
              variant="default"
              onClick={() => {
                this.setState({ showFilter: !showFilter });
              }}
              text={showFilter ? 'Hide Filter' : 'Show Filter'}
            />
            {showFilter && (
              <Button
                text="Apply Filter"
                onClick={() => {
                  this.filterForm?.submit();
                }}
              />
            )}
          </>
        </LayoutSideTitle>

        {this.createFilter()}

        <SettingsForm />

        <Grid
          onAjax={state.onAjax}
          // Temporary disabled according to the task LPFE-1195
          // gridControlPanel={
          //   <GridControlButton
          //     title="New Application"
          //     url={URL_INTEGRATION_MASTER_ADD}
          //   />
          // }
          columns={this.COLUMNS}
          dataSource={state.dataSource}
          pagination={state.pagination}
          onPaginationChange={this.onPaginationChange}
          dataSourceCount={state.dataSourceCount}
        />

        {state.changeStatus && (
          <DialogConfirm
            onCancel={this.handleCloseDialogStatusChanging}
            onApprove={this.handleApproveChangeStatus}>
            {state.changeStatus}
          </DialogConfirm>
        )}
      </>
    );
  }

  renderControl = (row: SendingApplicationFormDto) => (
    <div className="d-flex justify-content-center gap-3">
      <Button
        text="View Orders"
        onClick={() =>
          this.handleOpenOrders({ id: row.id, name: row.namespace })
        }
      />
      <Link
        className="btn btn-primary"
        to={`${URL_INTEGRATION_MASTER}/${row.id}`}>
        Edit
      </Link>
      <Button
        style={{ width: '90px' }}
        variant={row.active ? 'danger' : 'primary'}
        text={row.active ? 'Deactivate' : 'Activate'}
        onClick={(e) => this.confirmChangeStatus(e, row)}
      />
    </div>
  );

  handleChangeFilter = (name: string, value?: string) => {
    const filter = { ...this.state.filter, [name]: value };

    this.setState({ filter });
  };

  createFilter() {
    return (
      this.state.showFilter && (
        <Form
          model={this.state.filter}
          ref={(ref) => (this.filterForm = ref)}
          submit={(m: any, he: any) => {
            if (!he) {
              this.updateData();
            }
          }}
          onCollectValues={this.handleChangeFilter}>
          <Text name="namespace" label="Name" className="col-md-4 col-xl-3" />
          <UniversalIdTypeDropdown
            name="universalIdType"
            label="ID type"
            className="col-md-4 col-xl-3"
          />
          <Select
            name="communication"
            className="col-md-4 col-xl-3"
            options={[
              { value: 'sftp', label: 'SFTP' },
              { value: 'tcp_ip', label: 'TCP/IP' },
            ]}
          />
        </Form>
      )
    );
  }

  handleCloseDialogStatusChanging = () => {
    this.setState({ selectedLine: null, changeStatus: false });
  };

  handleApproveChangeStatus = () => {
    const { selectedLine } = this.state;
    this.handleCloseDialogStatusChanging();
    SendingApplicationsActions.changeStatus(selectedLine).then((r) => {
      if (r.status === 'OK') {
        this.updateData();
      } else {
        Notification.warning("Can't change status due unknown reasons");
      }
    });
  };

  confirmChangeStatus(event: any, row: SendingApplicationFormDto) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      selectedLine: row,
      changeStatus: `You are about to ${row.active ? 'de' : ''}activate ${
        row.namespace
      }. Do you want to proceed?`,
    });
  }
}

const SendingApplicationsContainer = Container.create(SendingApplications);
export default SendingApplicationsContainer;
