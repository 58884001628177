import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import { storeImport } from 'stores/_mobx/import';
import Pagination from 'stores/_mobx/options/pagination';
import UserProfileStore from 'stores/UserProfileStore';
import { FileManager } from 'stores/_mobx/fileManager';
import { getPagination } from 'utils/getPagination';
import AccessUtils from 'utils/AccessUtils';
import { dateToLocalTimezone } from 'utils/DateUtils';
import { MS_RADIOLOGY_GROUP as PAGE_ID } from 'constant/pagesId/systemSetup';
import { BACKEND_ROOT } from 'constant/config';

const compareArrays = (
  radiologists: RadiologistOptionType[],
  defaultUsers: RadiologistOptionType[]
) => {
  const isUsersChanged =
    radiologists.length !== defaultUsers.length
      ? true
      : radiologists.some(
          ({ value }) => !!defaultUsers.find((user) => user.value !== value)
        );

  return isUsersChanged;
};

const defaultPermission: PermissionType = {
  changeIntegration: false,
  importFee: false,
};

export const defaultValueRadiologyGroup: RadiologyGroupDetailsType = {
  radiologyGroupName: '',
  isIntegrationEnabled: false,
  radiologyGroupIntegrationIds: [],
  taxId: '',
  zipcode: '',
  userCoordinators: [],
  radiologyType: {
    ffs: false,
    insurance: false,
    purchasedService: false,
  },
  address: '',
  address2: '',
  flag: 'A',
  dicomRoutingTag: '',
  city: '',
  state: '',
  radiologyGroupBillingUsers: [],
  id: 0,
  radiologyGroupDesc: '',
  radiologists: [],
  radiologyGroupDocuments: [],
  feeData: null,
  feeScheduleId: 0,
  pos: 0,
};

interface GroupDetailsResponseType {
  id: number;
  radiologyGroupName: string;
  radiologyGroupDesc: string;
  pos: number;
  rgCoordinatorId: number;
  ffs: 'Y' | 'N';
  insurance: 'Y' | 'N';
  purchasedService: 'Y' | 'N';
  taxId: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  createdBy: number;
  createdDt: string;
  flag: 'A';
  utc: string;
  dicomRoutingTag: string;
  pcSchedules: FeeScheduleType[];
  radiologyGroupBillingUsers: { id: number; vaccinations: string[] }[];
  radiologyGroupDocuments: DocumentType[];
  radiologyGroupIntegration: { id: number; integrationId: number }[];
  radiologyGroupIntegrationIds: number[];
  userCoordinators: { id: number; vaccinations: string[] }[];
}

export interface PermissionType {
  changeIntegration: boolean;
  importFee: boolean;
}

interface FeeScheduleType {
  fileName: string;
  flag: string;
  fromDate: string;
  id: number;
  toDate: string;
}

export interface DocumentType {
  description: string;
  docName: string;
  id?: number;
  lastModified: string;
  file?: File;
}

interface RadiologistType {
  data: number;
  email: string;
  fax: string;
  first_name: string;
  home_address: string;
  home_city: string;
  home_state: string;
  home_zipcode: string;
  image: string;
  label: string;
  last_name: string;
  work_phone: string;
}

interface RadiologistGroupDetailsResponseType {
  0: { pc_schedule: number };
  file: 0 | unknown[];
  group: 0 | RadiologistType[];
}

interface RadiologyGroupResponseType {
  address: string;
  address2: string;
  city: string;
  contracted: string;
  enable: boolean;
  feeSchedule: number;
  group: 0 | RadiologistType[];
  radiology_group_desc: string;
  radiology_group_nm: string;
  refid: string;
  state: string;
  tax_id: string;
  zipcode: string;
}

interface FeeType {
  cptId: string;
  cpt_code: string;
  cptCode: string;
  default_facility: null | string;
  default_insurance: null | string;
  description: string;
  flag: string;
  global_fee: string;
  insurance: string;
  insurance_fee: string;
  insurance_global_fee: string;
  insurance_pro_fee: string;
  other_rate: string;
  pc_schedule: string;
  price: string;
  pro_fee: string;
  purchased_services: string;
  refid: string;
  tech_fee: string;
}

interface FeeScheduleUploadResponseType {
  cpt_code: string;
  cpt_code_id: string;
  default_facility: string;
  default_insurance: string;
  description: string;
  flag: string;
  global_fee: string;
  insurance_fee: string;
  insurance_global_fee: string;
  insurance_pro_fee: string;
  pc_schedule: number;
  pro_fee: string;
  tech_fee: string;
}

export interface RadiologyGroupType
  extends Omit<RadiologyGroupResponseType, 'group' | 'refid'> {
  group: RadiologistType[];
  refid: number;
}

export interface ImportFeePayloadType {
  file: File;
  fromDate: string;
  toDate: string;
  radiologyGroup: number;
}

interface FeePropsType {
  feeScheduleId: number;
  radiologyGroupName: string;
}

export interface RadiologistOptionType {
  label: string;
  value: number;
  firstName?: string;
  lastName?: string;
}

export interface RadiologyGroupDetailsType {
  radiologyGroupName: string;
  isIntegrationEnabled: boolean;
  radiologyGroupIntegrationIds: number[];
  taxId: string;
  zipcode: string;
  userCoordinators: number[];
  radiologyType: {
    ffs: boolean;
    insurance: boolean;
    purchasedService: boolean;
  };
  address: string;
  address2: string;
  city: string;
  state: string;
  radiologyGroupBillingUsers: number[];
  id: number;
  radiologyGroupDesc: string;
  radiologists: RadiologistOptionType[];
  feeScheduleId: number;
  radiologyGroupDocuments: DocumentType[];
  pos: number;
  dicomRoutingTag: string;
  flag: 'A' | 'I';
  feeData: null | {
    fromDate: string;
    toDate: string;
    file: File;
  };
}

class RadiologyGroup {
  uploading: boolean = false;
  fetching: boolean = false;
  fetchingFee: boolean = false;
  fetchingDocs: boolean = false;
  radiologyGroupsList: RadiologyGroupType[] = [];
  radiologyGroupsTotal: number = 0;
  radiologyGroupDetails?: RadiologyGroupDetailsType;
  idForDelete: number = 0;
  idForDocument: number = 0;
  documentsList: DocumentType[] = [];
  feeProps: FeePropsType | null = null;
  feeData: FeeType[] = [];
  feeTotal: number = 0;
  filterGroupName: string = '';
  permission: PermissionType = defaultPermission;

  page: Pagination = new Pagination({ id: 'radiologyGroupGrid' });
  pageFee: Pagination = new Pagination({ pageSize: 500, clearOnUnmount: true });
  documentManager = new FileManager('radiology_group_docs');

  constructor() {
    makeObservable(this, {
      uploading: observable,
      fetching: observable,
      fetchingFee: observable,
      fetchingDocs: observable,
      radiologyGroupsList: observable,
      radiologyGroupsTotal: observable,
      radiologyGroupDetails: observable,
      idForDelete: observable,
      idForDocument: observable,
      feeProps: observable,
      feeData: observable,
      feeTotal: observable,
      documentsList: observable,
      filterGroupName: observable,
      permission: observable,

      setUploading: action,
      setFetching: action,
      setFetchingFee: action,
      setFetchingDocs: action,
      setRadiologyGroupsList: action,
      setRadiologyGroupsTotal: action,
      setRadiologyGroupDetails: action,
      setFee: action,
      setFeeProps: action,
      setDocuments: action,
      setIdForDocs: action,
      setPermission: action,
      setIdForDelete: action.bound,
      setFilterAndPagination: action.bound,
      clearDocuments: action.bound,
      clearFee: action.bound,
      clearIdForDelete: action.bound,
      clearRadiologyGroupList: action.bound,
      clearRadiologyGroupDetails: action.bound,
    });
  }

  setUploading(inProgress: boolean) {
    this.uploading = inProgress;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingFee(fetching: boolean) {
    this.fetchingFee = fetching;
  }

  setFetchingDocs(fetching: boolean) {
    this.fetchingDocs = fetching;
  }

  setRadiologyGroupsList(list: RadiologyGroupType[]) {
    this.radiologyGroupsList = list;
  }

  setRadiologyGroupsTotal(total: number) {
    this.radiologyGroupsTotal = total;
  }

  setRadiologyGroupDetails(details: RadiologyGroupDetailsType) {
    this.radiologyGroupDetails = details;
  }

  setFilterAndPagination(groupName: string) {
    this.page.pagination = {
      ...this.page.pagination,
      skip: 0,
      page: 1,
    };
    this.filterGroupName = groupName;
  }

  setDocuments(docs: DocumentType[]) {
    this.documentsList = docs;
  }

  setFeeProps(feeProps: FeePropsType) {
    this.feeProps = feeProps;
  }

  setFee(feeData: FeeType[], total: number) {
    this.feeData = feeData;
    this.feeTotal = total;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setIdForDocs(id: number) {
    this.idForDocument = id;
  }

  setPermission(permission: PermissionType) {
    this.permission = permission;
  }

  clearDocuments() {
    this.idForDocument = 0;
    this.documentsList = [];
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  clearFee() {
    this.feeProps = null;
    this.feeData = [];
    this.feeTotal = 0;
    this.pageFee.pagination = getPagination();
  }

  clearRadiologyGroupList() {
    this.clearIdForDelete();
    this.clearDocuments();
  }

  clearRadiologyGroupDetails() {
    this.radiologyGroupDetails = undefined;
  }

  checkPermission() {
    const isSuperAdmin = UserProfileStore.getUserType() === 'U';

    const importFee = AccessUtils.checkAccess(PAGE_ID.IMPORT_PC_CPT_CODES);

    const permission = {
      changeIntegration: isSuperAdmin,
      importFee,
    };

    this.setPermission(permission);
  }

  async getRadiologistGroupCount() {
    try {
      const count = await apiRequest<'SE' | 'S' | number>({
        url: 'generalmaster.RadiologyGroup.RadiologyGroupTotalCount',
        data: [this.filterGroupName],
      });

      this.setRadiologyGroupsTotal(Number(count));
    } catch (e: any) {
      this.setRadiologyGroupsTotal(0);
    }
  }

  async getRadiologistGroupList() {
    const {
      filterGroupName,
      page: { pagination },
    } = this;

    try {
      const response = await apiRequest<RadiologyGroupResponseType[]>({
        url: 'generalmaster.RadiologyGroup.RadiologyGroupList',
        data: [pagination.skip, pagination.pageSize, filterGroupName],
      });

      const list = response.map(({ group, refid, ...rest }) => ({
        ...rest,
        refid: Number(refid),
        group: group || [],
      }));
      this.setRadiologyGroupsList(list);
    } catch (e: any) {
      this.setRadiologyGroupsList([]);
    }
  }

  async getRadiologistGroupsMain() {
    this.setFetching(true);

    const promiseCount = this.getRadiologistGroupCount();

    const promiseCodesList = this.getRadiologistGroupList();

    Promise.allSettled([promiseCodesList, promiseCount]).finally(() => {
      this.setFetching(false);
    });
  }

  async importFeeSchedule({ file, ...payload }: ImportFeePayloadType) {
    this.setUploading(true);
    try {
      const fileName = await storeImport.uploadFile({ file });

      const response = await apiRequest<
        | FeeScheduleUploadResponseType[]
        | FeeScheduleUploadResponseType
        | { message: string }
      >({
        url: 'codemaster.PcCptCodes.importCodes',
        data: [{ fileName, ...payload }],
      });

      if (Array.isArray(response)) {
        const codes = response.map(({ cpt_code }: any) => cpt_code).join(', ');

        if (codes) {
          Notification.danger(`Following codes were not imported: ${codes}`);
        }
        return true;
      } else if ('pc_schedule' in response) {
        return true;
      }
      Notification.danger(
        `Cannot import fee schedule${
          response?.message ? `: ${response.message}` : '!'
        }`
      );
      return false;
    } catch (e: any) {
      Notification.danger('Cannot import fee schedule!');
      return false;
    } finally {
      this.setUploading(false);
    }
  }

  async getDocuments() {
    this.setFetchingDocs(true);
    try {
      if (!this.idForDocument) throw Error('');

      const { radiologyGroupDocuments } =
        await apiRequest<GroupDetailsResponseType>({
          url: `radiology_groups/${this.idForDocument}`,
          method: 'GET',
          legacy: false,
        });

      const docs = radiologyGroupDocuments.map((doc) => ({
        ...doc,
        lastModified: dateToLocalTimezone({ date: doc.lastModified }),
      }));

      this.setDocuments(docs);
    } catch (e: any) {
      this.setDocuments([]);
    } finally {
      this.setFetchingDocs(false);
    }
  }

  async getRadiologyGroupDetails(id: number) {
    this.setFetching(true);
    try {
      const promiseDetails = apiRequest<GroupDetailsResponseType>({
        url: `radiology_groups/${id}`,
        method: 'GET',
        legacy: false,
      });

      const promiseLegacyDetails =
        apiRequest<RadiologistGroupDetailsResponseType>({
          url: 'generalmaster.RadiologyGroup.GetViewRadiologyGroup',
          data: [id],
        });

      const [
        {
          ffs,
          purchasedService,
          insurance,
          rgCoordinatorId,
          createdBy,
          createdDt,
          pcSchedules,
          radiologyGroupIntegration,
          ...response
        },
        { group, ...legacyDetails },
      ] = await Promise.all([promiseDetails, promiseLegacyDetails]);

      const pcScheduleId = legacyDetails[0]?.pc_schedule;

      const details = {
        ...response,
        isIntegrationEnabled: Boolean(radiologyGroupIntegration.length),
        radiologyGroupIntegrationIds: radiologyGroupIntegration.map(
          ({ integrationId }) => integrationId
        ),
        userCoordinators: response.userCoordinators.map(({ id }) => id),
        radiologyType: {
          ffs: ffs === 'Y',
          insurance: insurance === 'Y',
          purchasedService: purchasedService === 'Y',
        },
        radiologyGroupBillingUsers: response.radiologyGroupBillingUsers.map(
          ({ id }) => id
        ),
        radiologists: group
          ? group.map((el) => ({
              label: el.label,
              value: Number(el.data),
              firstName: el.first_name,
              lastName: el.last_name,
            }))
          : [],
        feeData: null as null,
        feeScheduleId: pcScheduleId || 0,
        pos: Number(response.pos),
      };

      this.setRadiologyGroupDetails(details);
    } catch (e: any) {
      this.setRadiologyGroupDetails(defaultValueRadiologyGroup);
    } finally {
      this.setFetching(false);
    }
  }

  async checkRadiologyGroupRoutingPath(id: number) {
    try {
      const { dicomRoutingTag } = await apiRequest<GroupDetailsResponseType>({
        url: `radiology_groups/${id}`,
        method: 'GET',
        legacy: false,
      });
      return Boolean(dicomRoutingTag);
    } catch (e: any) {
      return false;
    }
  }

  async deleteRadiologistGroup() {
    this.setFetching(true);
    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.RadiologyGroup.DeleteRadiologyGroup',
        data: [this.idForDelete],
      });

      if (response === 'S') {
        Notification.success('Radiologist group deleted successfully!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getFeeData() {
    const {
      feeProps,
      pageFee: { pagination },
    } = this;

    this.setFetchingFee(true);

    try {
      if (!feeProps?.feeScheduleId) throw Error('');

      const filter = { pc_schedule: feeProps?.feeScheduleId };

      const { result, totalCount } = await apiRequest<{
        totalCount: number;
        result: FeeType[];
      }>({
        url: 'codemaster.PcCptCodes.cptCodes',
        data: [filter, pagination],
      });

      this.setFee(result, totalCount);
    } catch (e: any) {
      this.setFee([], 0);
    } finally {
      this.setFetchingFee(false);
    }
  }

  async updateFeeData(payload: FeeType[]) {
    this.setFetchingFee(true);
    try {
      const response = await apiRequest<number>({
        url: 'codemaster.PcCptCodes.updateCptCodes',
        data: [payload],
      });
      if (response === 1) {
        Notification.success('Fee scheduler updated successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Fee scheduler updated successfully!');
      return false;
    } finally {
      this.setFetchingFee(false);
    }
  }

  async updateRadiologyGroupUsers(
    groupId: number,
    radiologists: RadiologistOptionType[]
  ) {
    const list = radiologists.map(({ value }) => ({ data: value }));

    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'generalmaster.RadiologyGroup.AddRadiologistMap',
        data: [groupId, list],
      });
      return response === 'S';
    } catch (e: any) {
      return false;
    }
  }

  updateRadiologyGroup = async ({
    radiologyGroupDocuments,
    radiologyGroupBillingUsers,
    radiologyType,
    ...payload
  }: RadiologyGroupDetailsType) => {
    this.setFetching(true);

    const {
      documentManager: { manageDocuments },
      radiologyGroupDetails,
    } = this;

    const defaultUsers = radiologyGroupDetails?.radiologists || [];

    const isUsersChanged = compareArrays(payload.radiologists, defaultUsers);

    try {
      const documents = await manageDocuments({
        defaultList: radiologyGroupDetails?.radiologyGroupDocuments || [],
        newList: radiologyGroupDocuments,
      });

      const basicInfo = {
        ...payload,
        ffs: radiologyType.ffs ? 'Y' : 'N',
        insurance: radiologyType.insurance ? 'Y' : 'N',
        purchasedService: radiologyType.purchasedService ? 'Y' : 'N',
        pos: String(payload.pos),
        radiologyGroupDocuments: documents.map(
          (id) => `${BACKEND_ROOT}/radiology_group_docs/${id}`
        ),
        radiologyGroupBillingUsers: radiologyGroupBillingUsers.map(
          (id) => `${BACKEND_ROOT}/userinfos/${id}`
        ),
        userCoordinators: payload.userCoordinators.map(
          (id) => `${BACKEND_ROOT}/userinfos/${id}`
        ),
      };

      const uploadingFee = payload.feeData
        ? this.importFeeSchedule({
            ...payload.feeData,
            radiologyGroup: payload.id,
          })
        : Promise.resolve(true);

      const uploadingBasicData = apiRequest<GroupDetailsResponseType>({
        url: `radiology_groups/${payload.id}`,
        method: 'PUT',
        data: basicInfo,
        legacy: false,
      });

      const updatingUser = isUsersChanged
        ? this.updateRadiologyGroupUsers(payload.id, payload.radiologists)
        : Promise.resolve(true);

      const [isFeeUploaded, basicDataResponse, isUserUpdated] =
        await Promise.all([uploadingFee, uploadingBasicData, updatingUser]);

      if (isFeeUploaded && basicDataResponse && isUserUpdated) {
        Notification.success(
          `Radiology group ${payload.radiologyGroupName} updated!`
        );
        return null;
      }
    } catch (e: any) {
      this.setFetching(false);
      Notification.danger('Group updating failed!');
      return { message: '' };
    }
  };

  addRadiologyGroup = async ({
    radiologyType,
    radiologyGroupBillingUsers,
    radiologyGroupDocuments,
    id,
    feeData,
    feeScheduleId,
    isIntegrationEnabled,
    radiologists,
    ...payload
  }: RadiologyGroupDetailsType) => {
    this.setFetching(true);

    const {
      documentManager: { manageDocuments },
      radiologyGroupDetails,
    } = this;

    const defaultUsers = radiologyGroupDetails?.radiologists || [];

    const isUsersChanged = compareArrays(radiologists, defaultUsers);

    try {
      const documents = await manageDocuments({
        defaultList: radiologyGroupDetails?.radiologyGroupDocuments || [],
        newList: radiologyGroupDocuments,
      });

      const basicInfo = {
        ...payload,
        ffs: radiologyType.ffs ? 'Y' : 'N',
        insurance: radiologyType.insurance ? 'Y' : 'N',
        purchasedService: radiologyType.purchasedService ? 'Y' : 'N',
        pos: String(payload.pos),
        radiologyGroupDocuments: documents.map(
          (id) => `${BACKEND_ROOT}/radiology_group_docs/${id}`
        ),
        radiologyGroupBillingUsers: radiologyGroupBillingUsers.map(
          (id) => `${BACKEND_ROOT}/userinfos/${id}`
        ),
        userCoordinators: payload.userCoordinators.map(
          (id) => `${BACKEND_ROOT}/userinfos/${id}`
        ),
      };

      const groupId = await apiRequest<'SE' | 'E' | number>({
        url: 'radiology_groups',
        method: 'POST',
        legacy: false,
        data: basicInfo,
      });

      const isGroupCreated = typeof groupId === 'number' ? groupId > 0 : false;

      const uploadingFee = feeData
        ? this.importFeeSchedule({
            ...feeData,
            radiologyGroup: groupId as number,
          })
        : Promise.resolve(true);

      const updatingUser = isUsersChanged
        ? this.updateRadiologyGroupUsers(groupId as number, radiologists)
        : Promise.resolve(true);

      const [isFeeUploaded, isUsersUploaded] = await Promise.all([
        updatingUser,
        uploadingFee,
      ]);

      if (isFeeUploaded && isGroupCreated && isUsersUploaded) {
        Notification.success(
          `Radiology group ${payload.radiologyGroupName} created!`
        );
      }

      return null;
    } catch (e: any) {
      this.setFetching(false);
      return { message: e?.faultString || '' };
    }
  };
}

export const storeRadiologyGroup = new RadiologyGroup();
