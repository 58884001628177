import { Controller, Control } from 'react-hook-form';

import PureRadio, { PureRadioProps } from './PureRadio';

/**
  @prop {string} 'data-testid' - data attribute for testing libraries
  If data-testid not presented, then prefix for data-testid attribute will generated with 'name' property
  '{dataTestId}-label' - data attribute for the main label
  '{dataTestId}-field-{optionLabel}' - data attribute for the option input field
  '{dataTestId}-label-{optionLabel}' - data attribute for the option input label
  '{dataTestId}-error' - data attribute for the error block
*/

export interface PropsType
  extends Omit<
    PureRadioProps,
    'value' | 'name' | 'onChange' | 'onBlur' | 'onFocus' | 'errorMessage'
  > {
  control: Control<any>;
  name: string;
}

const Radio = ({
  control,
  name,
  required = false,
  'data-testid': dataTestId,
  ...rest
}: PropsType) => (
  <Controller
    name={name}
    control={control}
    rules={{ required }}
    render={({ field, fieldState: { error } }) => {
      const errorMessage = (error && error.message) || '';

      return (
        <PureRadio
          errorMessage={errorMessage}
          required={required}
          {...rest}
          {...field}
        />
      );
    }}
  />
);

export default Radio;
