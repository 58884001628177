import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import {
  storeGenerator,
  GeneratorFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/generator';
import { URL_GENERATOR } from 'constant/path/workflow';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const GeneratorEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, generator } = storeGenerator;

  const handleSubmit = async (data: GeneratorFormModel) =>
    storeGenerator.updateGenerator(data).then((error) => {
      if (error) return error;
      history.push(URL_GENERATOR);
    });

  useEffect(() => {
    if (!fetching) storeGenerator.getGenerator(Number(params.id));

    return storeGenerator.clearGenerator;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Generator" />
      <Form
        backUrl={URL_GENERATOR}
        defaultValues={generator}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(GeneratorEditPage);
