import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormPayerType, { FormModel } from './components/Form';

import { storePayerType } from 'stores/_mobx/systemSetup/masterSetting/payerType';
import { URL_PAYER_TYPE } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const PayerTypeAddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: Omit<FormModel, 'refid'>) =>
    storePayerType.addPayerType(payload).then((response) => {
      if (response) {
        history.push(URL_PAYER_TYPE);
      }
    });

  return (
    <>
      <Title title="Add Payer Type" />
      <FormPayerType backUrl={URL_PAYER_TYPE} onSubmit={handleSubmit} />
    </>
  );
};

export default PayerTypeAddPage;
