import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Select } from 'components/form/select';
import { Input } from 'components/form/textField';
import { Textarea } from 'components/form/textarea';
import resolver from './validation';

import { AttemptSettingsFormModel } from 'stores/_mobx/systemSetup/masterSetting/loginAttempt';

const periodOptions = [
  { label: 'Days', value: 1 },
  { label: 'Months', value: 2 },
  { label: 'Years', value: 3 },
];

const defaultValues: AttemptSettingsFormModel = {
  attemptNumber: '',
  releaseDuration: '',
  comment: '',
  inactivePeriodType: 1,
  inactivePeriodValue: '',
  pwdPeriodType: 1,
  pwdPeriodValue: '',
};

interface PropsType {
  backUrl: string;
  onSubmit: (data: AttemptSettingsFormModel) => Promise<any>;
}

const Form = ({ backUrl, onSubmit }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<AttemptSettingsFormModel>({ defaultValues, resolver });

  const handleReset = () => {
    reset(defaultValues);
  };

  return (
    <form className="col-md-8 col-lg-6" onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="attemptNumber"
        label="Number of Attempts"
        type="number"
        min={0}
        max={99}
        required
        control={control}
      />
      <Input
        name="releaseDuration"
        label="Autorelease duration [minutes]"
        type="number"
        required
        control={control}
      />
      <div className="row">
        <Input
          name="inactivePeriodValue"
          label="Inactive Period Value"
          type="number"
          className="col-sm-6"
          required
          control={control}
        />
        <Select
          name="inactivePeriodType"
          label="Inactive Period Type"
          className="col-sm-6"
          options={periodOptions}
          required
          control={control}
        />
      </div>
      <div className="row">
        <Input
          name="pwdPeriodValue"
          label="Password Period Value"
          type="number"
          className="col-sm-6"
          required
          control={control}
        />
        <Select
          name="pwdPeriodType"
          label="Password period type"
          className="col-sm-6"
          required
          options={periodOptions}
          control={control}
        />
      </div>
      <Textarea name="comment" label="Comment" rows={2} control={control} />
      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={!isDirty || isSubmitting}
        />
      </ControlsLayout>
    </form>
  );
};

export default Form;
