import clsx from 'clsx';
import { memo } from 'react';
import { Chart } from 'react-google-charts';

interface PropsType {
  title?: string;
  className?: string;
  chartData: [string, string | number][];
}

const TwoChartBlock = ({ className, ...props }: PropsType) => {
  const optionsPie = { title: props?.title, pieHole: 0.4, is3D: true };
  const optionsBar = {
    title: props?.title,
    bar: { groupWidth: '95%' },
    legend: { position: 'none' },
  };

  const cn = clsx('row', className);

  return props.chartData.length > 1 ? (
    <div className={cn}>
      <div className="col-md-6">
        <Chart
          chartType="BarChart"
          data={props?.chartData}
          options={optionsBar}
          graph_id="BarChart"
          width="100%"
          height="400px"
        />
      </div>
      <div className="col-md-6">
        <Chart
          chartType="PieChart"
          data={props?.chartData}
          options={optionsPie}
          graph_id="PieChart"
          width="100%"
          height="400px"
          legend_toggle
        />
      </div>
    </div>
  ) : (
    <div className={`${cn} text-center py-5 fs-3`}>No data to display</div>
  );
};

export default memo(TwoChartBlock);
