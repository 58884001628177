import { makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionType {
  value: number;
  label: string;
}

class VaccinationDropdown {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,
    });
  }

  async getOptions() {
    if (this.fetching) return Promise.resolve();
    try {
      runInAction(() => {
        this.fetching = true;
      });
      const options = await apiRequest<OptionType[]>({
        legacy: false,
        url: 'vaccination_types',
        method: 'GET',
      });

      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }
}

export const storeVaccinationDropdown = new VaccinationDropdown();
