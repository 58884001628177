import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validation = yupResolver(
  yup.object().shape({
    code: yup.string().required('Please, enter the modifier code'),
    description: yup.string().required('Please, enter the description'),
  })
);

export default validation;
