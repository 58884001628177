import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import TileMenu from 'components/TileMenu';

import { storeMasterSettingsMenu } from 'stores/_mobx/systemSetup/masterSetting/menu';
import { MS as PAGE_ID } from 'constant/pagesId/systemSetup';
import { isDevMode } from 'constant/config';

const MasterSettingsPage = () => {
  const { fetching, systemSetting, billing, operation, hl7Integrations } =
    storeMasterSettingsMenu;

  useEffect(() => {
    storeMasterSettingsMenu.getMenu();

    return () => storeMasterSettingsMenu.setFetching(true);
  }, []);

  return (
    <>
      <Title title="Master Settings" className="my-3" appId={PAGE_ID.PAGE} />

      {fetching ? (
        <SpinnerFixed />
      ) : (
        <>
          <h4>System Settings</h4>
          <div className="equipments-menu">
            {systemSetting.map((menu) => (
              <TileMenu key={menu.refid} settings={menu} />
            ))}
          </div>

          <h4>Billing</h4>
          <div className="equipments-menu">
            {billing.map((menu) => (
              <TileMenu key={menu.refid} settings={menu} />
            ))}
          </div>

          <h4>Operations</h4>
          <div className="equipments-menu">
            {operation.map((menu) => (
              <TileMenu key={menu.refid} settings={menu} />
            ))}
          </div>

          {isDevMode ? (
            <>
              <h4>HL7 Integrations</h4>
              <div className="equipments-menu">
                {hl7Integrations.map((menu) => (
                  <TileMenu key={menu.refid} settings={menu} />
                ))}
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default observer(MasterSettingsPage);
