import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import { storeImport } from 'stores/_mobx/import';
import { splitFileEntitiesToGroup } from 'utils/splitFileEntitiesToGroup';

interface DocumentType {
  description: string;
  docName: string;
  id?: number;
  lastModified: string;
  file?: File;
}

interface DocumentResponseType {
  id: number;
  docName: string;
  description: string;
  createdBy: number;
  createdDate: string;
  lastModified: string;
}

export class FileManager {
  private endpoint: string = '';

  constructor(path: string) {
    this.endpoint = path;
  }

  deleteFile = async (
    file: Required<DocumentType>
  ): Promise<DocumentResponseType | null> => {
    try {
      return apiRequest({
        url: `${this.endpoint}/${file.id}`,
        legacy: false,
        method: 'DELETE',
      });
    } catch (e: any) {
      return Promise.resolve(null);
    }
  };

  uploadFile = async ({
    id,
    ...payload
  }: {
    id?: number;
    file?: File;
    description: string;
  }): Promise<DocumentResponseType | null> => {
    try {
      return storeImport.uploadFileNewApi<DocumentResponseType>({
        url: id ? `${this.endpoint}/${id}` : this.endpoint,
        payload,
      });
    } catch (e: any) {
      return null;
    }
  };

  manageDocuments = async ({
    defaultList,
    newList,
  }: {
    newList: DocumentType[];
    defaultList: DocumentType[];
  }) => {
    const { filesToCreating, filesToUpdating, filesToDelete } =
      splitFileEntitiesToGroup({ defaultList, newList });
    try {
      let responseCreating: number[] = [];

      const promiseDeleting = filesToDelete.map(this.deleteFile);

      const promiseCreating = filesToCreating.map(
        ({ file, description }) =>
          () =>
            this.uploadFile({ file, description })
      );

      const promiseUpdating = filesToUpdating.map(({ id, description }) =>
        this.uploadFile({ id, description })
      );

      await Promise.all([...promiseUpdating, ...promiseDeleting]);

      for (const action of promiseCreating) {
        const result = await action();
        responseCreating.push(result.id);
      }

      return responseCreating;
    } catch (error) {
      Notification.danger('Updating document error!');
      return [];
    }
  };
}
