import { TTopMenu } from 'stores/record/DataObjects';

export default class MenuRecord {
  menuarr: Array<TTopMenu> = [];

  constructor(menuarr?: Array<TTopMenu>) {
    this.menuarr = menuarr ? menuarr : [];
  }

  copy(copy: MenuRecord) {
    this.menuarr = copy.menuarr;
  }
}
