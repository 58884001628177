import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface LogType {
  app_name: string;
  color: string;
  created_by: number;
  created_user_nm: string;
  disable_dt: string;
  enable_dt: string;
  'ppxray_userpermission.flag': 'Y' | 'N';
  status: 'Enabled' | 'Disabled';
}

class Permission {
  fetchingLog: boolean = false;
  logsList: LogType[] = [];
  logsTotal: number = 0;

  pageLogs: Pagination = new Pagination({
    id: 'users_log_modal_grid',
  });

  constructor() {
    makeObservable(this, {
      fetchingLog: observable,
      logsList: observable,
      logsTotal: observable,

      setFetchingLogs: action,
      setLogsList: action,
      setLogsCount: action,
      clearLog: action.bound,
    });
  }

  setFetchingLogs(fetching: boolean) {
    this.fetchingLog = fetching;
  }

  setLogsList(logs: LogType[]) {
    this.logsList = logs;
  }

  setLogsCount(total: number) {
    this.logsTotal = total;
  }

  clearLog() {
    this.logsList = [];
    this.logsTotal = 0;
  }

  async getLogsCount(id: number) {
    try {
      const count = await apiRequest<string>({
        url: 'permission.PermissionInfo.LoadUserPermissionLogCount',
        data: [id],
      });

      this.setLogsCount(Number(count) || 0);
    } catch (e: any) {
      this.setLogsCount(0);
    }
  }

  async getLogsList(id: number) {
    const { pagination } = this.pageLogs;

    try {
      const response = await apiRequest<LogType[]>({
        url: 'permission.PermissionInfo.LoadUserPermissionLog',
        data: [id, pagination.skip, pagination.pageSize],
      });

      const logs = response.map((log) => ({
        ...log,
        enable_dt: dateToLocalTimezone({ date: log.enable_dt }),
        disable_dt: dateToLocalTimezone({ date: log.disable_dt }),
        created_by: Number(log.created_by),
      }));

      this.setLogsList(logs);
    } catch (e: any) {
      this.setLogsList([]);
    }
  }

  getLogsListMain = async (id: number) => {
    this.setFetchingLogs(true);

    const promiseList = this.getLogsList(id);

    const promiseCount = this.getLogsCount(id);

    return Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetchingLogs(false);
    });
  };
}

export const storePermission = new Permission();
