import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'components/modal/dialog';
import Messages from 'components/messages';
import { Button } from 'components/button';
import { PureCheckbox } from 'components/form/checkbox';
import { PureTextarea } from 'components/form/textarea';

import {
  storeServiceNote,
  NoteType,
} from 'stores/_mobx/workflow/equipmentInventory/serviceNote';
import CsvExporter from 'utils/CsvExporter';

interface Props {
  id: number;
  type: string;
  onClose: () => void;
}

const cols = [
  { title: 'Note', name: 'message' },
  { title: 'Note by', name: 'user' },
  { title: 'Date On', name: 'date' },
];

const exportCsv = (messages: NoteType[]) => {
  const csvExporter = new CsvExporter(messages, cols);

  csvExporter.exportAndDownload('equipment_inventory_report');
};

const DialogServicesNotes = ({ id, type, onClose }: Props) => {
  const { fetching, noteCount, notes } = storeServiceNote;

  const [message, setMessage] = useState<string>('');

  const [allNotes, setNoteType] = useState<boolean>(false);

  const handleClickSend = () => {
    storeServiceNote
      .addMessage({
        id,
        type,
        message,
      })
      .then((isSucceed) => {
        if (isSucceed) {
          setMessage('');
          storeServiceNote.getServiceNotes({
            id,
            type,
            onlyNew: !allNotes,
          });
        }
      });
  };

  const handleClickExport = () => {
    exportCsv(notes);
  };

  useEffect(() => {
    storeServiceNote.getServiceNotes({
      id,
      type,
      onlyNew: !allNotes,
    });
  }, [allNotes, type, id]);

  useEffect(() => {
    return storeServiceNote.clearNotes;
  }, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Service Notes" onClose={onClose} />
      <DialogBody className={`${fetching ? 'on-ajax' : ''}`}>
        <PureCheckbox
          name="noteType"
          label="All Notes"
          checked={allNotes}
          onChange={setNoteType}
        />

        <div className="pb-3">
          {noteCount ? <Messages list={notes} /> : 'No messages'}
        </div>

        <PureTextarea
          className="mb-4"
          placeholder="Type new message"
          value={message}
          rows={4}
          resize={false}
          onChange={setMessage}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          text="Export"
          disabled={!noteCount}
          onClick={handleClickExport}
        />
        <Button
          text="Send"
          onClick={handleClickSend}
          disabled={!message.length || fetching}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogServicesNotes);
