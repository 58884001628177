import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';

import { storeImport, ImportType } from 'stores/_mobx/import';

export { ImportType } from 'stores/_mobx/import';

interface PropsType {
  type: ImportType;
  examplePath?: string;
  onClose: () => void;
  onImportSucceed: () => void;
}

const DialogImporting = ({
  type,
  examplePath,
  onClose,
  onImportSucceed,
}: PropsType) => {
  const inputRef = useRef(null);

  const [file, setFile] = useState<null | File>(null);

  const [errorMessages, setErrorMessage] = useState<string>('');

  const { uploading } = storeImport;

  const instance = type === ImportType.physician ? 'Physicians' : 'Facilities';

  const handleChangeFile = (e: React.SyntheticEvent) => {
    const { files } = e.currentTarget as HTMLInputElement;

    if (files?.[0]) {
      setFile(files[0]);
      setErrorMessage('');
    }
  };

  const handleClickSubmit = () => {
    if (errorMessages) return false;

    storeImport
      .importFile({
        file,
        importType: type,
      })
      .then((errorMessages) => {
        if (errorMessages) setErrorMessage(errorMessages);
        else {
          onClose();
          onImportSucceed();
        }
      });
  };

  return (
    <Dialog handleClose={onClose}>
      <DialogHeader title={`Import ${instance}`} onClose={onClose} />
      <DialogBody className={uploading ? 'on-ajax' : ''}>
        <div className="file-upload">
          <input
            ref={inputRef}
            id="fileUploadingField"
            type="file"
            accept=".csv"
            className="file-upload-input"
            onChange={handleChangeFile}
          />
          <label
            htmlFor="fileUploadingField"
            className="btn btn-primary"
            onClick={(e) => {
              inputRef.current.value = null;
            }}>
            Browse CSV file
          </label>
          <div>File: {file?.name || 'Not selected'}</div>
        </div>
        {errorMessages && (
          <div className="text-danger pt-4">{errorMessages}</div>
        )}
      </DialogBody>
      <DialogFooter>
        {examplePath && (
          <a
            href={examplePath}
            target="_blank"
            className="btn btn-primary"
            rel="noreferrer">
            Download CSV example
          </a>
        )}
        <Button text="Submit" disabled={!file} onClick={handleClickSubmit} />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogImporting);
