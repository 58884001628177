import { makeObservable, observable, action } from 'mobx';

import { TPagination } from 'components/grid/GridTypes';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';

const defaultFilterValue: FilterType = {
  dosEnd: '',
  dosStart: '',
  period: 'A',
  userTypeToggler: '',
  firstName: '',
  lastName: '',
  facility: 0,
  userType: 0,
};

type UserTypeToggler = 0 | 1 | '';

export interface FilterType {
  userTypeToggler: UserTypeToggler;
  firstName: string;
  lastName: string;
  facility: number;
  userType: number;
  dosEnd: string;
  dosStart: string;
  period: string;
}

interface FilterRequestType extends Omit<FilterType, 'userTypeToggler'> {
  userTypeToggler: string;
}

interface ActivityLogType {
  facility_nm: string;
  lastmodified: string;
  lastModified: string;
  name: string;
  user_name: string;
  user_type: string;
}

class CurrentActivityLog {
  fetching: boolean = false;
  logs: ActivityLogType[] = [];
  logsTotal: number = 0;
  filter: FilterType = defaultFilterValue;
  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'current_activity_log' });

    makeObservable(this, {
      fetching: observable,
      logs: observable,
      logsTotal: observable,
      filter: observable,

      setLogs: action,
      setLogsTotal: action,
      setFetching: action,
      setFilter: action,
    });
  }

  setFilter(payload: FilterType) {
    this.filter = payload;
  }

  setLogs(logs: ActivityLogType[]) {
    this.logs = logs;
  }

  setLogsTotal(count: number) {
    this.logsTotal = count;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setDefaultFilter() {
    const filter = {
      ...this.filter,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facility,
      userTypeToggler: UserProfileStore.isClientUser()
        ? 1
        : ('' as UserTypeToggler),
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const filter = {
      ...defaultFilterValue,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facility,
      userTypeToggler: UserProfileStore.isClientUser()
        ? 1
        : ('' as UserTypeToggler),
    };

    return filter;
  }

  async getCurrentActivityLog({
    pagination,
    filter,
  }: {
    pagination: TPagination;
    filter: FilterRequestType;
  }) {
    try {
      const response = await apiRequest<ActivityLogType[]>({
        url: 'userlogin.LoginMaster.GetCurrentUserList',
        data: [filter, pagination.skip, pagination.pageSize],
      });
      if (Array.isArray(response)) {
        const logs = response.map((log) => ({
          ...log,
          lastModified: dateToLocalTimezone({
            date: log.lastModified,
          }),
        }));
        this.setLogs(logs);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setLogs([]);
    }
  }

  async getLogCount(filter: FilterRequestType) {
    try {
      const count = await apiRequest<number>({
        url: 'userlogin.LoginMaster.GetCurrentUserCount',
        data: [filter],
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setLogsTotal(total);
    } catch (e) {
      this.setLogsTotal(0);
    }
  }

  getCurrentActivityLogMain = async () => {
    this.setFetching(true);

    const { filter } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const payload = {
      ...filter,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
      userTypeToggler:
        filter.userTypeToggler === ''
          ? ''
          : filter.userTypeToggler === 1
          ? 'A'
          : 'B',
    };

    const promiseList = this.getCurrentActivityLog({
      pagination: this.page.pagination,
      filter: payload,
    });

    const promiseCount = this.getLogCount(payload);

    return Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };
}

export const storeCurrentActivityLog = new CurrentActivityLog();
