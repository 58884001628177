import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { MultipleFileUploadControl } from 'components/form/input/MultipleFileUpload';
import { Button } from 'components/button';
import resolver from './validation';

import { VehicleFormModel } from 'stores/_mobx/workflow/equipmentInventory/vehicle';
import { Textarea } from 'components/form/textarea';

const initialValues: VehicleFormModel = {
  refid: null,
  vehicledesc: '',
  vehicle: '',
  vechcolor: '',
  regnumber: '',
  vehicleyear: '',
  model: '',
  vinnumber: '',
  documentList: [],
};

interface PropsType {
  backUrl: string;
  defaultValues?: VehicleFormModel;
  onSubmit: (data: VehicleFormModel) => Promise<any>;
}

const FormModifyVehicle = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: VehicleFormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('regnumber', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={isSubmitting ? 'on-ajax' : ''}
      onSubmit={handleClickSubmit}>
      <div className="row">
        <Input
          name="vehicle"
          label="Vehicle name"
          className="col-md-7 col-lg-4"
          required
          control={control}
        />
        <div />
        <Input
          name="regnumber"
          label="License Plate No."
          className="col-md-7 col-lg-4"
          required
          control={control}
        />
        <div />
        <Input
          name="model"
          label="Vehicle model"
          className="col-md-7 col-lg-4"
          control={control}
        />
        <div />
        <Input
          name="vehicleyear"
          label="Make year"
          className="col-md-3 col-lg-2"
          type="number"
          control={control}
        />
        <Input
          name="vechcolor"
          label="Color"
          className="col-md-3 col-lg-2"
          control={control}
        />
        <div />
        <Input
          name="vinnumber"
          label="VIN number"
          className="col-md-7 col-lg-4"
          control={control}
        />
      </div>

      <Textarea
        name="vehicledesc"
        label="Description"
        rows={3}
        control={control}
      />

      <MultipleFileUploadControl
        name="documentList"
        dir="vehicle"
        control={control}
      />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          type="reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormModifyVehicle;
