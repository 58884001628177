import { Controller, Control } from 'react-hook-form';
import clsx from 'clsx';

import { PureInputProps } from './PureInput';
import { useState } from 'react';

interface PropsType
  extends Omit<
    PureInputProps,
    | 'value'
    | 'name'
    | 'onChange'
    | 'onBlur'
    | 'onFocus'
    | 'errorMessage'
    | 'type'
  > {
  control: Control<any>;
  defaultValue?: string;
  name: string;
  buttons?: React.ReactNode;
}

const InputPassword = ({
  name,
  control,
  defaultValue,
  label,
  required = false,
  className,
  buttons,
  'data-testid': dataTestId,
  ...rest
}: PropsType) => {
  const [isPasswordVisible, toggleVisibility] = useState<boolean>(false);

  const testAttr = dataTestId || name;

  const wrapperCn = clsx('formfield-holder', { required }, className);

  const handleClick = () => {
    toggleVisibility((state) => !state);
  };

  return (
    <div className={wrapperCn}>
      {label ? (
        <div className="formfield-label" data-testid={`${testAttr}-label`}>
          <label htmlFor={name}>{label}</label>
        </div>
      ) : null}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required }}
        render={({ field, fieldState: { error } }) => {
          const errorMessage = error?.message || '';

          return (
            <>
              <div className="input-group">
                <input
                  type={isPasswordVisible ? 'text' : 'password'}
                  className="form-control"
                  {...rest}
                  {...field}
                />
                <button
                  className="btn btn-secondary password-toggle"
                  type="button"
                  disabled={rest.disabled}
                  onClick={handleClick}>
                  <i
                    className={`bi ${
                      isPasswordVisible ? 'bi-eye-slash' : 'bi-eye'
                    }`}
                  />
                </button>
                {buttons}
              </div>
              {errorMessage && (
                <div className="error" data-testid={`${testAttr}-error`}>
                  {errorMessage}
                </div>
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default InputPassword;
