import { apiRequest } from 'services/RequestService';

class StudiesByFacilityPageService {
  loadStudiesByFacilityList(conditionList, joint) {
    return apiRequest({
      url: 'report.ReportMaster.GetFacReport',
      data: [conditionList, joint],
    }).then((response) => {
      if (response && response.chartval) {
        var newChartValues = [];
        newChartValues.push(['Data', 'Value']);
        let chartValues = response.chartval;
        chartValues.forEach((item) => {
          newChartValues.push([item.facilityname, parseInt(item.CompOrders)]);
        });
        response.chartval = newChartValues;
        return response;
      } else {
        return {};
      }
    });
  }

  createPdf(studiesByFacilityList, filterString) {
    return apiRequest({
      url: 'pdfcreater.OrderPdfGenerator.GeneratePDFForStudiesByFacility',
      data: [studiesByFacilityList, filterString],
    });
  }
}

export default new StudiesByFacilityPageService();
