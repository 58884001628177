import FacilityTabService, {
  TTransmitLogFilter,
  TDocumentFacilityFilter,
} from 'services/patient-records/document-manager/document-audit/facility/FacilityTabService';
import { Actions } from 'stores/patient-records/document-manager/document-audit/facility/FacilityTabStore';
import appDispatcher from 'dispatcher/AppDispatcher';

export default class FacilityTabActions {
  static loadDocumentFacilityList(
    skip: number,
    limit: number,
    filter: TDocumentFacilityFilter
  ) {
    return FacilityTabService.loadDocumentFacilityList(
      skip,
      limit,
      filter
    ).then((response) => {
      appDispatcher.dispatch({
        type: 'facility.DocumentManager.DocFacilityList',
        data: response.data,
        count: response.count,
      } as any);
    });
  }

  static loadTransmitLogList(
    skip: number,
    limit: number,
    orderId: number,
    key: 'F' | 'PHY' | string,
    filter: TTransmitLogFilter
  ) {
    return FacilityTabService.loadTransmitLogList(
      skip,
      limit,
      orderId,
      key,
      filter
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_TRANSMIT_LOG_LIST_ACTION,
        data: response.data,
        count: response.count,
      } as any);
      return response;
    });
  }

  static updateImportTypes(importTypes: Array<{ refid: number }>) {
    return FacilityTabService.updateImportTypes(importTypes);
  }

  static saveDocumentOptions(
    DOCUMENT_OPTIONS: Array<{ value: string; label: string }>
  ) {
    return FacilityTabService.saveDocumentOptions(DOCUMENT_OPTIONS);
  }

  static loadUserDocumentOptions() {
    return FacilityTabService.loadUserDocumentOptions().then((r) => {
      if (r && r.length) {
        return r;
      } else {
        return [
          { value: 'CN', label: 'CN', title: 'Chart Notes' },
          { value: 'F', label: 'F', title: 'Facesheet' },
          { value: 'SO', label: 'SO', title: 'Signed Provider Orders' },
        ];
      }
    });
  }
}
