import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { phoneValidator } from 'utils/formValidators';

const validateReceiver = yup.string().test({
  test: (destination: string, { parent }) =>
    Boolean(parent.email) || Boolean(parent.fax),
  message: 'Fax or email should be defined!',
});

const validation = yupResolver(
  yup.object().shape({
    email: validateReceiver.email('Invalid email address!'),
    fax: validateReceiver.test({
      test: (fax: string) => (fax ? phoneValidator(fax) : true),
      message: 'Should be in xxx-xxx-xxxx format!',
    }),
    file: yup
      .mixed()
      .test('file-check', 'Please, select a file!', (file, { parent }) => {
        return parent.mode === 'F' ? Boolean(file) : true;
      }),
    message: yup
      .string()
      .test('file-check', 'Please, enter the message!', (message, { parent }) =>
        parent.mode === 'M' ? Boolean(message) : true
      ),
  })
);

export default validation;
