import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import DialogServicesNotes from '../components/DialogServicesNotes';

import { storeXRayUnit } from 'stores/_mobx/workflow/equipmentInventory/xRayUnit';
import { EI_UNIT_XRAY as PAGE_ID } from 'constant/pagesId/workflow';
import {
  URL_EQUIPMENT_INVENTORY,
  URL_XRAY_UNIT,
  URL_XRAY_UNIT_ADD,
} from 'constant/path/workflow';
import GridActionControls from 'page/components/GridActionControls';

const columns = [
  { name: 'device_name', title: 'X-Ray device name' },
  { name: 'computer', title: 'Computer name' },
  { name: 'name', title: 'Generator name' },
  { name: 'plate_name', title: 'X-Ray plate name' },
  {
    name: 'refid',
    title: 'History',
    render: (id: number) => (
      <div className="control">
        <IconButton onClick={() => storeXRayUnit.setIdForNote(id)}>
          <i className="icon icon-notes" />
        </IconButton>
      </div>
    ),
  },
  {
    name: 'refid',
    title: 'Actions',
    className: 'width-100',
    render: (id: number) => (
      <GridActionControls
        id={id}
        url={`${URL_XRAY_UNIT}/edit/${id}`}
        onDelete={storeXRayUnit.setIdForDelete}
      />
    ),
  },
];

const XRayUnitsListPage = () => {
  const {
    fetching,
    idForDelete,
    xRayUnitList,
    xRayUnitTotal,
    idForNote,
    page: { pagination, setPagination },
  } = storeXRayUnit;

  const handleConfirmDelete = () => {
    storeXRayUnit.deleteXrayUnit().then((isSuccess) => {
      if (isSuccess) {
        storeXRayUnit.getXRayUnitsListMain();
      }
    });
  };

  useEffect(() => {
    storeXRayUnit.getXRayUnitsListMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle title="X-Ray Unit" appId={PAGE_ID.PAGE}>
        <Link to={URL_EQUIPMENT_INVENTORY} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <Grid
        id="x_ray_unit_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={xRayUnitList}
        dataSourceCount={xRayUnitTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_XRAY_UNIT_ADD} />
        }
      />

      {Boolean(idForDelete) && (
        <DialogConfirm
          onCancel={storeXRayUnit.clearIdForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this item?
        </DialogConfirm>
      )}

      {idForNote ? (
        <DialogServicesNotes
          type="XU"
          id={idForNote}
          onClose={storeXRayUnit.clearIdForNote}
        />
      ) : null}
    </>
  );
};

export default observer(XRayUnitsListPage);
