import React from 'react';
import AbstractLikeFilter from './AbstractLikeFilter';
import NpiInput from 'components/project/NpiInput';

export default class FacilityNPIFilter extends AbstractLikeFilter {
  name = 'facilityNPI';
  field = 'Facility NPI';
  field1 = AbstractLikeFilter.getInsecureSecureField(
    'ppxray_facility.facility_npi'
  );

  render() {
    return (
      <div className="facility-npi-filter">
        {this.renderConditions()}
        {this.renderTextValue()}
        <NpiInput
          className="col-sm-3"
          name={this.name}
          label="Value"
          value={this.state.filterItem.value}
          onSetValue={(name, value) => this.onSetValue(name, value)}
        />
      </div>
    );
  }
}
