import { Link, RouteComponentProps } from 'react-router-dom';

import OrderDocuments from 'page/workflow/order/orderDocuments';
import { LayoutSideTitle } from 'components/layout';

import UrlUtils from 'utils/UrlUtils';
import { URL_ORDER_EXAM_REVIEW } from 'constant/path/workflow';

interface MatchParams {
  orderId: string;
  patientId: string;
}

export interface PropsType extends RouteComponentProps<MatchParams> {}

const DocumentsOverview = ({ match: { params } }: PropsType) => {
  const patientId = Number(params.patientId);

  const orderId = Number(params.orderId);

  const backUrl = UrlUtils.getQuery('backUrl') || URL_ORDER_EXAM_REVIEW;

  return (
    <>
      <LayoutSideTitle title="Documents">
        <Link to={backUrl || URL_ORDER_EXAM_REVIEW} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <OrderDocuments patientId={patientId} orderId={orderId} />
    </>
  );
};

export default DocumentsOverview;
