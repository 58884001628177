import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

interface CoordsType {
  left: number;
  top: number;
  height: number;
}

interface PropTypes {
  title?: string;
  children: React.ReactNode;
  coords: CoordsType;
  theme: 'dark' | 'light' | 'danger' | 'warning';
  allowPointer?: boolean;
}

const body = document.getElementsByTagName('body')[0];

const Popover = ({
  children,
  coords,
  title,
  theme,
  allowPointer,
}: PropTypes) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isVisible, setVisible] = useState<boolean>(false);

  const [correction, setCorrection] = useState({
    width: 'max-content',
    translateX: '-50%',
    translateY: '-100%',
    left: `${coords.left}px`,
    top: `${coords.top}px`,
    arrowLeft: '50%',
    arrowTranslateX: '-50%',
    position: 'top',
  });

  useEffect(() => {
    const wrapperCoords = wrapperRef.current?.getBoundingClientRect();

    setVisible(true);

    if (wrapperCoords.y < 10) {
      const isHasSpace =
        body.clientHeight - wrapperCoords.bottom >= wrapperCoords.height;

      const withOptions =
        wrapperCoords.y > 0 && wrapperCoords.width <= 200
          ? {
              ...correction,
              width: '250px',
              translateX: 'calc(-50% - 60px)',
              arrowTranslateX: 'calc(-50% + 60px)',
            }
          : {};
      setCorrection({
        ...correction,
        ...withOptions,
        top: `${isHasSpace ? coords.top + coords.height : coords.top}px`,
        position: isHasSpace ? 'bottom' : correction.position,
        translateY: isHasSpace ? '0px' : correction.translateY,
      });
    } else if (body.clientWidth - wrapperCoords.right <= 10) {
      const shift = body.clientWidth - wrapperCoords.width - 10;
      const arrowShift = coords.left - shift;
      setCorrection({
        ...correction,
        left: `${shift}px`,
        translateX: `0px`,
        arrowLeft: `${arrowShift}px`,
      });
    }

    if (wrapperCoords.x < 10) {
      setCorrection({
        ...correction,
        left: '10px',
        translateX: '0px',
        arrowLeft: `${coords.left - 10}px`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cn = clsx('popover fade', `bs-popover-${correction.position}`, theme, {
    show: isVisible,
  });

  return (
    <div
      className={cn}
      ref={wrapperRef}
      style={{
        display: 'block',
        position: 'fixed',
        left: correction.left,
        top: correction.top,
        transform: `translate(${correction.translateX}, ${correction.translateY})`,
        width: correction.width,
        pointerEvents: allowPointer ? 'all' : 'inherit',
      }}>
      <div
        className="popover-arrow"
        style={{
          position: 'absolute',
          left: correction.arrowLeft,
          transform: `translate(${correction.arrowTranslateX}, 0)`,
        }}
      />
      {title && <div className="popover-header">{title}</div>}
      <div className="popover-body">{children}</div>
    </div>
  );
};

export default Popover;
