import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import MaskInput from 'components/form/maskInput';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import { DropDownStateShortName } from 'components/project/dropdown/StateShortName';
import { ControlsLayout } from 'components/layout';

import { TEmployerInfo } from 'services/patient-records/patient-demographics/PatientRecordPageService';

interface PropsType {
  readOnly: boolean;
  defaultValues: TEmployerInfo;
  onSubmit: (data: TEmployerInfo) => Promise<any>;
  onSaveCache: (data: TEmployerInfo) => void;
  onReset: () => void;
}

const optionsStatus = [
  { label: 'Employed', value: 'E' },
  { label: 'Unemployed', value: 'U' },
  { label: 'Full-time student', value: 'F' },
  { label: 'Part-time student', value: 'P' },
];

const FormEmployer = ({
  defaultValues,
  readOnly,
  onReset,
  onSubmit,
  onSaveCache,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting },
    reset,
    handleSubmit,
    getValues,
  } = useForm({ defaultValues });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(
    () => () => onSaveCache ? onSaveCache(getValues()) : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <form className="row" onSubmit={handleSubmit(onSubmit)}>
      <Radio
        name="org_status"
        className="col-sm-12 part-inline"
        options={optionsStatus}
        control={control}
      />
      <Input
        name="employer_name"
        label="Organization name"
        className="col-md-6"
        disabled={readOnly}
        control={control}
      />
      <MaskInput
        name="Orgzipcode"
        label="Zip Code"
        format="#####-####"
        className="col-md-6"
        disabled={readOnly}
        control={control}
      />

      <Input
        name="Orgcity"
        label="City"
        className="col-md-6"
        disabled={readOnly}
        control={control}
      />
      <MaskInput
        name="work_phone"
        label="Work Phone"
        format="###-###-####"
        className="col-md-6"
        disabled={readOnly}
        control={control}
      />

      <DropDownStateShortName
        name="Orgstate"
        label="State"
        className="col-md-6"
        disabled={readOnly}
        control={control}
      />
      <Input
        name="Orgaddress"
        label="Address"
        className="col-md-6"
        disabled={readOnly}
        control={control}
      />

      <ControlsLayout alignX="right">
        <Button
          text="Reset"
          variant="warning"
          disabled={readOnly || isSubmitting}
          onClick={onReset}
        />
        <Button type="submit" text="Save" disabled={readOnly || isSubmitting} />
      </ControlsLayout>
    </form>
  );
};

export default FormEmployer;
