import appDispatcher from 'dispatcher/AppDispatcher';

import ArAgingReportService from 'services/reports/ArAgingReportService';

export default class FacilityExamCountPageActions {
  static loadList(conditionList, joint) {
    return ArAgingReportService.loadList(conditionList, joint)
      .then((response) => {
        if (response && response.chartval) {
          var out = [];
          var chartValues = response.chartval || [];
          chartValues.forEach((item) => {
            out.push([item.patient_name, parseInt(item.age)]);
          });
          response.chartval = out;
          return response;
        } else {
          return {};
        }
      })
      .then((response) => {
        appDispatcher.dispatch({
          type: 'aragingreport_data_overview',
          data: response,
        });
      });
  }

  static createPdf(list, description) {
    return ArAgingReportService.createPdf(list, description);
  }
}
