import React from 'react';
import { Container } from 'flux/utils';

import { Grid, TPagination } from 'components/grid';
import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import OutgoingMessage from './OutgoingMessage';
import IncomingMessage from './IncomingMessage';

import SendingApplicationsActions, {
  OrderRequestForward,
} from 'actions/system-setup/master-setup/master-settings/integrations/SendingApplicationsActions';
import SendingApplicationsStore from 'stores/system-setup/masterSettings/integrations/SendingApplicationsStore';
import { storeGrid } from 'stores/_mobx/grid';
import StringUtils from 'utils/StringUtils';
import { getPagination } from 'utils/getPagination';

interface PShowOrders {
  application: number;
  title?: string;
  callback: () => void;
}

class SShowOrders {
  onAjax: boolean = false;
  pagination = getPagination(
    storeGrid.getPageSize('sendin_applications_orders')
  );
  outgoing: boolean = false;
  incoming: boolean = false;
  dataSource: Array<OrderRequestForward> = [];
  dataSourceCount: number = 0;
  order: OrderRequestForward = new OrderRequestForward();
  appSn: string = '';
}

export class ShowOrders extends React.Component<PShowOrders, SShowOrders> {
  COLUMNS: Array<any>;

  static getStores() {
    return [SendingApplicationsStore];
  }

  static calculateState(prevState?: SShowOrders) {
    const state = SendingApplicationsStore.getState();
    return {
      ...(prevState || new SShowOrders()),
      dataSource: state.orders,
      dataSourceCount: state.ordersCount,
    };
  }

  constructor(props: PShowOrders) {
    super(props);
    this.state = new SShowOrders();
    this.COLUMNS = [
      { name: 'latticeproOrderId', title: 'Order ID' },
      {
        name: 'external',
        title: 'External',
        render: (v: any) => (v ? 'Y' : 'N'),
      },
      { name: 'creationDate', title: 'Created' },
      {
        name: 'isResponded',
        title: 'Responded',
        render: (v: any) => (v ? 'Y' : 'N'),
      },
      { name: 'responseAttempts', title: 'Response Attempts' },
      {
        name: 'status',
        title: 'Status',
        render: (v: any, order: OrderRequestForward) =>
          ShowOrders.renderStatus(order),
      },
      {
        name: 'v_incoming',
        title: 'Incoming Message',
        render: (v: any, order: OrderRequestForward) =>
          (!order.external && order.oruAckNum <= 0) ||
          (order.external &&
            StringUtils.isBlank(order.ack) &&
            StringUtils.isBlank(order.message)) ? null : (
            <a
              href="/"
              onClick={(event) => {
                this.incoming(event, order);
              }}>
              view
            </a>
          ),
      },
      {
        name: 'v_outgoing',
        title: 'Outgoing Message',
        render: (v: any, order: OrderRequestForward) =>
          (order.external && order.oruAckNum <= 0) ||
          (!order.external &&
            StringUtils.isBlank(order.ack) &&
            StringUtils.isBlank(order.message)) ? null : (
            <a
              href="/"
              onClick={(event) => {
                this.outgoing(event, order);
              }}>
              view
            </a>
          ),
      },
    ];
  }

  static renderStatus(d: {
    isResponded: boolean;
    responseAttempts: number;
    isResponseReady?: boolean;
  }) {
    let out = '';
    if (d.isResponded) {
      out = 'Responded';
    } else if (d.responseAttempts > 4) {
      out = 'Error';
    } else if (d.isResponseReady) {
      out = 'Ready To Send';
    } else {
      out = 'Not Ready';
    }
    return out;
  }

  outgoing(event: any, order: OrderRequestForward) {
    if (event) {
      event.preventDefault();
    }
    this.setState({ outgoing: true, order });
  }

  incoming(event: any, order: OrderRequestForward) {
    if (event) {
      event.preventDefault();
    }
    this.setState({ incoming: true, order });
  }

  onPaginationChange = (pagination: TPagination) => {
    this.setState({ pagination, onAjax: true }, () => this.updateData());
  };

  componentDidMount() {
    this.updateData();
  }

  updateData() {
    this.setState({ onAjax: true });

    SendingApplicationsActions.loadAppSn().then((response) => {
      this.setState({ appSn: response.app_sn }, () => {
        SendingApplicationsActions.loadOrders(
          this.props.application,
          this.state.appSn,
          this.state.pagination
        ).then(() => {
          this.setState({ onAjax: false });
        });
      });
    });
  }

  render() {
    const { state, props } = this;
    return (
      <>
        <LayoutSideTitle title={`Orders for ${props.title}`}>
          <Button variant="danger" text="Back" onClick={props.callback} />
        </LayoutSideTitle>

        <Grid
          columns={this.COLUMNS}
          id="sendin_applications_orders"
          onAjax={state.onAjax}
          pagination={state.pagination}
          onPaginationChange={this.onPaginationChange}
          dataSource={state.dataSource}
          dataSourceCount={state.dataSourceCount}
        />
        {state.outgoing && (
          <OutgoingMessage
            callback={() => this.setState({ outgoing: false })}
            order={state.order}
          />
        )}
        {state.incoming && (
          <IncomingMessage
            callback={() => this.setState({ incoming: false })}
            order={state.order}
          />
        )}
      </>
    );
  }
}

export default Container.create(ShowOrders, { withProps: true });
