import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    year: yup
      .number()
      .nullable()
      .when('month', {
        is: (month: number | null) => Boolean(month),
        then: yup
          .number()
          .nullable()
          .test({
            test: (year: number | null) => Boolean(year),
            message: 'Please select year!',
          }),
        otherwise: yup.number().nullable(),
      }),
    dateFrom: yup.string().when('dateTo', {
      is: '',
      then: yup.string(),
      otherwise: yup.string().required('Please specify date!'),
    }),
    dateTo: yup.string().test({
      test: (dateTo, { parent }: any) =>
        parent.dateFrom ? Boolean(dateTo) : true,
      message: 'Please specify date!',
    }),
  })
);

export default validation;
