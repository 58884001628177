import AbstractLikeFilter from './AbstractLikeFilter';

export default class LocationFilter extends AbstractLikeFilter {
  name = 'location';
  field = 'Location';
  field1 = AbstractLikeFilter.getInsecureSecureField(
    'ppxray_location.location'
  );
  className = 'location-filter';
}
