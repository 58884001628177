import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';

class CurrentUserOptions {
  fetchingCorrectionalStatus: boolean = false;
  isCorrectional: boolean = false;

  constructor() {
    makeObservable(this, {
      fetchingCorrectionalStatus: observable,
      isCorrectional: observable,

      setFetchingCorrectionalStatus: action,
      setCorrectional: action,
    });
  }

  setFetchingCorrectionalStatus(status: boolean) {
    this.fetchingCorrectionalStatus = status;
  }

  setCorrectional(isCorrectional: boolean) {
    this.isCorrectional = isCorrectional;
  }

  async checkCorrectionalStatus() {
    this.setFetchingCorrectionalStatus(true);
    try {
      const result = await apiRequest<boolean>({
        url: 'usermaster.UserInfo.isCorrectional',
      });

      this.setCorrectional(result);
    } catch (error: unknown) {
      this.setCorrectional(false);
    } finally {
      this.setFetchingCorrectionalStatus(false);
    }
  }
}

export const storeCurrentUserOptions = new CurrentUserOptions();
