import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import DialogFormBillAddress from './DialogFormBillAddress';

import {
  storeBillAddress,
  BillAddressFormType,
} from 'stores/_mobx/billingCodding/billAddress';

interface PropsType {
  facilityId: number;
  invoiceno: number;
  onClose: () => void;
  onSubmit: (payload: BillAddressFormType) => Promise<any>;
}

const BillingAddress = ({
  facilityId,
  invoiceno,
  onClose,
  onSubmit,
}: PropsType) => {
  const { fetching, billAddress } = storeBillAddress;

  useEffect(() => {
    storeBillAddress.getBillAddress({
      facilityId,
      invoiceNo: invoiceno,
    });

    return storeBillAddress.clearBillAddress;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DialogFormBillAddress
      loading={fetching}
      defaultValues={billAddress}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default observer(BillingAddress);
