import React from 'react';

import Dialog, { DialogHeader, DialogBody } from 'components/modal/dialog';

const messageFacade = (input: string) =>
  input ? input.replace(/\r\n/gm, '\n').replace(/\r/gm, '\n') : '';

interface PropsType {
  title: string;
  topMessage: string;
  bottomMessage: string;
  onClose: () => void;
}

const MessageView = ({
  title,
  topMessage,
  bottomMessage,
  onClose,
}: PropsType) => (
  <Dialog size="extraLarge" handleClose={onClose}>
    <DialogHeader title={title} onClose={onClose} />
    <DialogBody>
      <h3>Message:</h3>
      <pre>{messageFacade(topMessage)}</pre>
      <h3>Ack:</h3>
      <pre>{messageFacade(bottomMessage)}</pre>
    </DialogBody>
  </Dialog>
);

export default MessageView;
