import { apiRequest } from 'services/RequestService';

class StudiesByTechPageService {
  loadStudiesByTechList(conditionList, joint) {
    return apiRequest({
      url: 'report.ReportMaster.FacilityPatientReport',
      data: [conditionList, joint],
    }).then((response) => {
      if (response && response.chartval) {
        var newChartValues = [];
        newChartValues.push(['Facility name', 'Patients']);
        let chartValues = response.chartval;
        chartValues.forEach((item) => {
          newChartValues.push([item.FacilityName, parseInt(item.Patients)]);
        });
        response.chartval = newChartValues;
        return response;
      } else {
        return {};
      }
    });
  }

  createPdf(studiesByTechList, formatDescription) {
    return apiRequest({
      url: 'pdfcreater.OrderPdfGenerator.generatePdfStudiesByTech',
      data: [studiesByTechList, formatDescription],
    });
  }
}

export default new StudiesByTechPageService();
