import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';
import { apiRequest } from 'services/RequestService';

const defaultBillAddress: BillAddressType = {
  address1: '',
  address2: '',
  city1: '',
  city2: '',
  state1: '',
  state2: '',
  zipcode1: '',
  zipcode2: '',
};

export interface BillAddressType {
  address1: string;
  city1: string;
  state1: string;
  zipcode1: string;
  address2: string;
  city2: string;
  state2: string;
  zipcode2: string;
}

interface BillAddressPayload extends BillAddressType {
  addressflag: BillAddressVariant;
  facilityId: number;
  invoiceno: number;
}

export enum BillAddressVariant {
  C = 'C',
  F = 'F',
  O = 'O',
  M = 'M',
}

interface BillAddressResponseType {
  addressFlag: BillAddressVariant;
  corporate: [BillAddressType] | [];
  facility: [BillAddressType] | [];
  others: [BillAddressType] | [];
  usmarshell: [BillAddressType] | [];
}

export interface BillAddressFormType
  extends Record<BillAddressVariant, BillAddressType> {
  addressVariant: BillAddressVariant;
}

class BillAddress {
  fetching: boolean = false;
  billAddress?: BillAddressFormType;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      billAddress: observable,

      setBillAddress: action,
      setFetching: action,
      clearBillAddress: action.bound,
    });
  }

  setBillAddress(address: BillAddressFormType) {
    this.billAddress = address;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  clearBillAddress() {
    this.billAddress = undefined;
  }

  async getBillAddress(data: { facilityId: number; invoiceNo: number }) {
    this.setFetching(true);
    try {
      const response = await apiRequest<'SE' | BillAddressResponseType>({
        url: 'facility.FacilityBilling.GetInvoiceaddress',
        data,
      });

      if (response === 'SE') {
        throw Error('');
      } else {
        const address = {
          addressVariant: response.addressFlag,
          [BillAddressVariant.C]: response.corporate[0] || defaultBillAddress,
          [BillAddressVariant.F]: response.facility[0] || defaultBillAddress,
          [BillAddressVariant.O]: response.others[0] || defaultBillAddress,
          [BillAddressVariant.M]: response.usmarshell[0] || defaultBillAddress,
        };
        this.setBillAddress(address);
      }
    } catch (e: any) {
      this.clearBillAddress();
    } finally {
      this.setFetching(false);
    }
  }

  async updateBillAddress(payload: BillAddressPayload) {
    const data = {
      facilityId: payload.facilityId,
      address1: payload.address1,
      address2: payload.address2,
      city1: payload.city1,
      city2: payload.city2,
      state1: payload.state1,
      state2: payload.state2,
      zipcode1: payload.zipcode1,
      zipcode2: payload.zipcode2,
      addressflag: payload.addressflag,
      invoiceno: payload.invoiceno,
    };
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'facility.FacilityBilling.Saveinvoiceaddress',
        data,
      });
      if (result === 'S') {
        Notification.success('Invoice address was updated');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.warning("Invoice address wasn't updated");
      return false;
    }
  }
}

export const storeBillAddress = new BillAddress();
