import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import Title from 'components/project/common/title';
import FormRejectReason from './components/Form';

import {
  storeRejectReason,
  FormModel,
} from 'stores/_mobx/systemSetup/masterSetting/rejectReason';
import { URL_REJECT_REASON } from 'constant/path/systemSetup';

interface MatchParam {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParam> {}

const RejectReasonEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, reason } = storeRejectReason;

  const handleSubmit = async (data: FormModel) =>
    storeRejectReason.updateReason(data).then((error) => {
      if (error) return error;
      history.push(URL_REJECT_REASON);
    });

  useEffect(() => {
    if (!fetching) storeRejectReason.getReason(Number(params.id));

    return storeRejectReason.clearReason;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Reject Reason" />
      <FormRejectReason
        backUrl={URL_REJECT_REASON}
        defaultValues={reason}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(RejectReasonEditPage);
