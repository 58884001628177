import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import { storeAuditTrail } from 'stores/_mobx/users/auditTrail';

const COLUMNS = [
  { name: 'action_on', title: 'Action On' },
  { name: 'action', title: 'Action' },
  { name: 'action_by', title: 'Action By' },
];

interface PropsType {
  order: {
    refid?: number;
    studyId?: number;
    accessionNumbersStr?: string;
    last_name: string;
    first_name: string;
  };
  onClose: () => void;
}

const DialogAuditLog = ({ order, onClose }: PropsType) => {
  const { fetchingAuditLog, auditLog } = storeAuditTrail;

  const refId = order.studyId || order.refid;

  const auditLogId = order.accessionNumbersStr || refId;

  useEffect(() => {
    if (!fetchingAuditLog) storeAuditTrail.getAuditLog(refId);

    return storeAuditTrail.clearAuditLog;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader
        title={`Audit log for ${auditLogId} (${order.last_name} ${order.first_name})`}
        onClose={onClose}
      />

      <DialogBody>
        <Grid
          stateless
          disablePagination
          columns={COLUMNS}
          onAjax={fetchingAuditLog}
          dataSource={auditLog}
          gridControlPanelBottom={null}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogAuditLog);
