import appDispatcher from 'dispatcher/AppDispatcher';

import FacilityExamCountPageService from 'services/reports/report-creator/facility-exam-count/FacilityExamCountPageService';
import { Actions } from 'stores/reports/report-creator/facility-exam-count/FacilityExamCountPageStore';

export default class FacilityExamCountPageActions {
  static loadFacilityExamCountList(conditionList, joint) {
    return FacilityExamCountPageService.loadFacilityExamCountList(
      conditionList,
      joint
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_FACILITY_EXAM_COUNT_LIST,
        data: response,
      });
    });
  }

  static createPdf(facilityExamCountList, filter) {
    return FacilityExamCountPageService.createPdf(
      facilityExamCountList,
      filter
    );
  }
}
