import { apiRequest } from 'services/RequestService';

export interface LoginForm {
  username: string;
  password: string;
}

export interface ResetPasswordSignInForm {
  userName: string;
  email: string;
}

export interface ResetPasswordSecurityQuestionForm {
  refid: number;
  usertype: string;
  answer1: string;
  answer2: string;
}

export interface RestorePasswordUserData {
  facility_id: string;
  ['ppxray_userinfo.refid']: string;
  quest1_id: string;
  quest_id: string;
  refid: string;
  usertype: string;
}
export interface AdminDetail {
  address: string;
  billingaddress: string;
  billingcity: string;
  billingemail: string;
  billingfax: string;
  billingphone: string;
  billingstate: string;
  billingtollfree: string;
  billingzip: string;
  city: string;
  comment: string;
  company_name: string;
  company_users: {
    admin: number;
    cellphone: string;
    email: string;
    firstname: string;
    lastname: string;
  }[];
  email: string;
  fax: string;
  logo: string | null;
  logo_internalpage: string | null;
  logo_name: string;
  npi: string;
  npiDetailsList: { npi: string; desc: string }[];
  pdf_logo: string | null;
  phone: string;
  refid: string;
  release_duration: number;
  requestmail: string;
  state: string;
  tollfreenum: string;
  web_site: '';
  zipcode: string;
}
export class TCompanyDetails {
  refid: number = null;
  company_name: string = '';
  company_users: string = '';
  web_site: string = '';
  phone: string = '';
  email: string = '';
  fax: string = '';
  billingemail: string = '';
  tollfreenum: string = '';
  address: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  comment: string = '';
  logo_name: string = '';
  npi: string = '';
  billingphone: string = '';
  billingfax: string = '';
  billingtollfree: string = '';
  billingaddress: string = '';
  billingcity: string = '';
  billingstate: string = '';
  billingzip: string = '';
  requestmail: string = '';
  release_duration: string = '';
}

export class TMenu {
  data: number = null;
  app_name: string = '';
  icon_class: string = '';
  sub_menu: Array<any> = []; // TODO: specify correct type
}

export class TPrivilege {
  app_name: string = '';
  app_id: number = null;
  refid: number = null;
  url: string = '';
  flag: string = '';
}

export class TGridData {
  refid: number = null;
  user_id: number = null;
  table_id: string = '';
  page_url: string = '';
  grid_size: number = null;
  grid_columns: string = '';
  grid_filters: string = '';
  column_drag_order: string = '';
  column_sort_order: string = '';
}

export class TAuthorisation {
  msg: string = '';
  status: 'INVALID' | 'R' | 'I' | 'L' | 'P' | 'OK' | 'S' | 'F' | 'E' = null;
  admininfo: AdminDetail[] | null = null;
  username: string = '';
  global_user: string = '';
  refid: number = null;
  facility_name: string = '';
  region: string = '';
  regionrefid: number = null;
  state: string = '';
  staterefid: number = null;
  location: string = '';
  locationrefid: number = null;
  pos: string = '';
  posrefid: number = null;
  usertype: string = '';
  type: string = '';
  usertypename: string = '';
  user_name: string = '';
  ipin: string = '';
  user_id: number = null;
  usertype_id: number = null;
  view_status: string = '';
  location_id: number = null;
  facility_id: number = null;
  corporate_id: number = null;
  user_email: string = '';
  tech_privilege: string = '';
  recordsAccess: 'A' | 'M' = 'A';
  serviceuser_privilege: string = '';
  sec_ques1: string = '';
  sec_ques2: string = '';
  IPAddress: string = '';
  user_pass: string = '';
  app_sn: string = '';
  grids_data: Array<TGridData> = [];
  supervisedGroup: string = '';
  signature: string = '';
  last_login: string = '';
  last_logout: string = '';
  menuarr: Array<TMenu> = [];
  privileges: Array<TPrivilege> = [];
  loginattempt: string = '';
  applicationTimezone: string;
}

class UserProfileRequestService {
  isAuthorised() {
    return apiRequest<Array<TAuthorisation>>({
      url: 'userlogin.LoginMaster.isAuthorised',
    });
  }

  checkLogin(params: LoginForm) {
    return apiRequest({
      url: 'userlogin.LoginMaster.LoginCheckJson',
      data: { params },
    });
  }

  isCorrectional() {
    return apiRequest<boolean>({ url: 'usermaster.UserInfo.isCorrectional' });
  }

  getUnSignOrderCount(userId: number) {
    return apiRequest<number>({
      url: 'order.Order.GetUnSignOrderCount',
      data: { userId },
    });
  }

  getUnSignEquipmentCount(userId: number) {
    return apiRequest({
      url: 'vehicle.EquipmentMaster.GetUnSignEquipmentCount',
      data: { userId },
    });
  }

  pinCheck(pin: string) {
    return apiRequest<[TAuthorisation]>({
      url: 'userlogin.LoginMaster.pinCheck',
      data: [pin],
    });
  }

  requestForLogin(message: string) {
    return apiRequest<'S'>({
      url: 'email.Email.requestmailsend',
      data: [message],
    });
  }

  forgotPasswordStep1(payload: ResetPasswordSignInForm) {
    return apiRequest<[RestorePasswordUserData]>({
      url: 'userlogin.LoginMaster.SendFavoriteQuestion',
      data: [payload.email, payload.userName],
    });
  }

  verifyAnswer({
    refid,
    usertype,
    answer1,
    answer2,
  }: ResetPasswordSecurityQuestionForm) {
    const data = [refid, usertype, answer1, answer2];

    return apiRequest<
      Array<{
        refid: number;
        user_name?: string;
        user_pass?: string;
        usertype_id?: string;
      }>
    >({ url: 'userlogin.LoginMaster.VerifyAnswer', data }).then((response) => {
      return response[0];
    });
  }

  updateResetPassword(userId: number, newPassword: string) {
    return apiRequest<string>({
      url: 'usermaster.ResetPwd.UpdateResetPassword',
      data: [userId, newPassword],
    });
  }

  loginChangePassword(
    userId: number,
    login: string,
    oldPassword: string,
    newPassword: string,
    securityQuesId1: number,
    answer1: string,
    securityQuesId2: number,
    answer2: string
  ) {
    const data = [
      userId,
      login,
      oldPassword,
      newPassword,
      securityQuesId1,
      answer1,
      securityQuesId2,
      answer2,
    ];

    return apiRequest<string>({
      url: 'userlogin.LoginMaster.LoginChangePassword',
      data,
    });
  }

  checkSession() {
    return apiRequest<string>({ url: '?ts_check=1', method: 'GET' });
  }
}

export default new UserProfileRequestService();
