import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from 'App';

import 'react-datepicker/dist/react-datepicker.css';
import './scss/main.scss';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('app')
);
