import { useMemo } from 'react';

import { Grid, GridControlButton, TPagination } from 'components/grid';
import Tooltip from 'components/tooltip';

import { PatientInfoExtendedType } from 'stores/_mobx/workflow/order/patient';

interface PropsType {
  isLoading: boolean;
  isCorrectional: boolean;
  isAddPatientBtnDisabled: boolean;
  patients: PatientInfoExtendedType[];
  patientsTotal: number;
  pagination: TPagination;
  onClickAddPatient: () => void;
  onChangePage: (pagination: TPagination) => void;
  onSelect: (value: PatientInfoExtendedType) => void;
}

const PatientGrid = ({
  isLoading,
  isCorrectional,
  isAddPatientBtnDisabled,
  patients,
  patientsTotal,
  pagination,
  onClickAddPatient,
  onChangePage,
  onSelect,
}: PropsType) => {
  const cellsRender = (value: any, row: PatientInfoExtendedType) => (
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        onSelect(row);
      }}>
      {value}
    </a>
  );

  const columns = useMemo(
    () => [
      {
        name: 'last_name',
        title: 'Last Name',
        className: 'text-icon-column',
        render: (lastName: string, row: PatientInfoExtendedType) => (
          <div className="text-icon-wrapper">
            <span className="text-icon-pic">
              {row.patient_alert ? (
                <Tooltip
                  body={row.patient_alert}
                  theme="danger"
                  title="Patient alert">
                  <i className="bi bi-exclamation-triangle-fill text-danger" />
                </Tooltip>
              ) : null}
            </span>
            {cellsRender(lastName, row)}
          </div>
        ),
      },
      { name: 'first_name', title: 'First Name', render: cellsRender },
      {
        name: 'dob',
        title: 'DOB',
        className: 'text-nowrap',
        render: cellsRender,
      },
      {
        name: 'facility',
        title: 'Facility',
        className: 'text-icon-column',
        render: (facility: string, row: PatientInfoExtendedType) => (
          <div className="text-icon-wrapper">
            <span className="text-icon-pic">
              {row.alert_facility ? (
                <Tooltip
                  body={row.alert_facility}
                  theme="danger"
                  title="Facility alert">
                  <i className="bi bi-exclamation-triangle-fill text-danger" />
                </Tooltip>
              ) : null}
            </span>
            {cellsRender(facility, row)}
          </div>
        ),
      },
      { name: 'state', title: 'State', render: cellsRender },
      { name: 'ssn', title: 'SSN', render: cellsRender },
      ...(isCorrectional
        ? []
        : [
            {
              name: 'inmate_number',
              title: 'OID Number',
              render: cellsRender,
            },
            {
              name: 'ice_number',
              title: 'ICE Number',
              render: cellsRender,
            },
            {
              name: 'immigration_id',
              title: 'Marshall ID',
              render: cellsRender,
            },
          ]),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCorrectional]
  );

  return (
    <>
      <Grid
        columns={columns}
        onAjax={isLoading}
        dataSource={patients}
        dataSourceCount={patientsTotal}
        pagination={pagination}
        gridControlPanel={
          <>
            <GridControlButton
              title="Add New Patient"
              disabled={isLoading || isAddPatientBtnDisabled}
              onClick={onClickAddPatient}
            />
            {isAddPatientBtnDisabled && <span className='text-secondary'>( Search before adding new patient )</span>}
          </>
        }
        onPaginationChange={onChangePage}
      />
    </>
  );
};

export default PatientGrid;
