import { Container } from 'flux/utils';

import AbstractSettings, {
  PAbstractSettings,
  SAbstractSettings,
} from './AbstractSettings';
import { Button } from 'components/button';
import Form from 'components/form/Form';
import Select from 'components/form/input/Select';
import UserTypePrivilegePageActions from 'actions/privileges/settings/UserTypePrivilegePageActions';
import UserTypePrivilegePageStore from 'stores/privileges/settings/UserTypePrivilegePageStore';
import UserTypeStore from 'stores/project/UserTypeStore';
import AccessUtils from 'utils/AccessUtils';
import { getPagination } from 'utils/getPagination';
import { USER_TYPE as PAGE_ID } from 'constant/pagesId/privileges';

export interface PUserTypePrivilegePage extends PAbstractSettings {}

export class SUserTypePrivilegePage extends SAbstractSettings {
  onAjax: boolean = false;
  pagination = getPagination();
  filter: Record<string, any> = {
    userType: '',
  };
  applications: Array<any> = [];
  showSavedNotification: boolean;
  selected: Array<any> = [];
  dataSource: Array<any> = [];
  userTypeList: Array<any> = [];
  showLogChangeModal: boolean = false;
  personSettings: Array<any> = [];
  model: any = {};
  errors: any = {};
}

export class UserTypePrivilegePage extends AbstractSettings<
  PUserTypePrivilegePage,
  SUserTypePrivilegePage
> {
  static getStores() {
    return [UserTypePrivilegePageStore, UserTypeStore];
  }

  static calculateState(prevState?: SUserTypePrivilegePage) {
    return Object.assign(
      prevState || new SUserTypePrivilegePage(),
      UserTypePrivilegePageStore.getState(),
      { userTypeList: UserTypeStore.getState().userTypeList }
    );
  }

  title = 'User Type Privilege Settings';

  getButtons = () =>
    AccessUtils.checkAccess(PAGE_ID.GROUP_SAVE) ? (
      <Button text="Save" onClick={this.saveChanges.bind(this)} />
    ) : null;

  callActionToSave(idDto: any, isAllowedDto: any) {
    return UserTypePrivilegePageActions.saveChanges(
      this.state.filter.userType,
      idDto,
      isAllowedDto
    );
  }

  updateData = () => {
    if (!this.state.filter.userType || this.state.filter.userType === '0') {
      this.setState({
        dataSource: [],
        onAjax: false,
      });
    } else {
      UserTypePrivilegePageActions.loadApplications(
        0,
        this.state.filter.userType,
        false
      ).then(this.updateDataCallback);
    }
  };

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.setState({
      onAjax: false,
      selected: [],
    });

    if (grid) {
      // @ts-ignore
      grid.clearSelection();
    }
  };

  handleChangeFilter = (name: string, value?: string) => {
    const state = {
      filter: { ...this.state.filter, [name]: value },
    };

    this.setState(state, this.updateData);
  };

  buildFormFilter() {
    return (
      <Form onCollectValues={this.handleChangeFilter} model={this.state.filter}>
        <Select
          name="userType"
          className="col-sm-4"
          options={this.getUserTypeOptions()}
          onAjax={this.state.userTypeList.length === 0}
        />
      </Form>
    );
  }

  getUserTypeOptions() {
    return this.state.userTypeList.filter((v) => v.value !== 8);
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }
}

const container = Container.create(UserTypePrivilegePage);
export default container;
