import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import UserProfileStore from 'stores/UserProfileStore';

export const getPatientBasicValues = (): PatientFormBasicData => {
  const hasPermission =
    UserProfileStore.getUserType() === 'D' ||
    !UserProfileStore.isClientUser() ||
    UserProfileStore.getUser().usertypename.toLowerCase().includes('corporate');

  return {
    usetyp: 'P',
    mrn_id: '',
    last_name: '',
    first_name: '',
    middle_name: '',
    last_nickname: '',
    first_nickname: '',
    birthdate: '',
    clientMRN: '',
    inmate_number: '',
    gender: '',
    ice_number: '',
    immigration_id: '',
    ssn: '',
    facilityid: hasPermission ? 0 : UserProfileStore.getFacility(),
  };
};

export const filterDefaultValues: FilterType = {
  status: 'A',
  facilityId: 0,
  firstName: '',
  lastName: '',
  birthday: '',
  phoneNumber: '',
  ssn: '',
  mrn: '',
  gender: '',
};

export interface FilterType {
  status: string;
  facilityId: number;
  firstName: string;
  lastName: string;
  birthday: string;
  phoneNumber: string;
  ssn: string;
  mrn: string;
  gender: string;
  correctional?: {
    ice_number: string;
    inmate_number: string;
    immigration_id: string;
  };
}

interface PatientResponseType {
  birthdate: string;
  clientMRN: string;
  employer_name: string;
  facility: string;
  facility_id: number;
  first_name: string;
  first_nickname: string;
  gender: 'Male' | 'Female' | 'Unknown';
  gender_lbl: 'M' | 'F' | 'U';
  ice_number: string;
  immigration_id: string;
  inmate_number: string;
  last_name: string;
  last_nickname: string;
  martial_status: string;
  middle_name: string;
  mobile: string;
  org_status: string;
  patient_alert: string | null;
  patient_comment: string;
  patientmrn: string;
  refid: number;
  salutation: number;
  selstat: boolean;
  spouse_name: string;
  ssn: string;
  status: 'Active' | 'Inactive';
  suffix: number;
  vw_patientid: string;
  work_phone: string;
}

export interface PatientType
  extends Omit<PatientResponseType, 'gender' | 'gender_lbl'> {
  genderView: 'Male' | 'Female' | 'Unknown';
  gender: 'M' | 'F' | 'U';
}

interface PatientForMergePayloadType {
  activePatientId: number;
  patients: { value: number; label: string }[];
}

export interface PatientFormBasicData {
  usetyp: 'P';
  mrn_id: string;
  last_name: string;
  first_name: string;
  middle_name: string;
  last_nickname: string;
  first_nickname: string;
  birthdate: string;
  clientMRN: string;
  inmate_number: string;
  gender: string;
  ice_number: string;
  immigration_id: string;
  ssn: string;
  facilityid: number;
}

interface CreatingPatientResponseType {
  birthdate: string;
  client_mrn: string;
  created: boolean;
  facility_id: number;
  first_name: string;
  gender: 'M' | 'F';
  ice_number: string;
  immigration_id: string;
  inmate_number: string;
  last_name: string;
  middle_name: string;
  phone: string;
  ssn: string;
  user_id: number;
}

class PatientDemographic {
  fetching: boolean = false;
  mergeInProgress: boolean = false;
  changeFacilityInProgress: boolean = false;
  patientList: PatientType[] = [];
  patientTotal: number = 0;
  patientsForMerge: PatientType[] = [];
  filter: FilterType = filterDefaultValues;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'patient_demographics_grid' });

    makeObservable(this, {
      fetching: observable,
      mergeInProgress: observable,
      changeFacilityInProgress: observable,
      patientList: observable,
      patientTotal: observable,
      patientsForMerge: observable,
      filter: observable,

      setFetching: action,
      setMergeProgress: action,
      setChangeFacilityProgress: action,
      setPatientList: action,
      setPatientCount: action,
      setPatientsForMerging: action,
      setFilter: action.bound,
      clearPatientsForMerging: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setMergeProgress(fetching: boolean) {
    this.mergeInProgress = fetching;
  }

  setChangeFacilityProgress(fetching: boolean) {
    this.changeFacilityInProgress = fetching;
  }

  setPatientList(patients: PatientType[]) {
    this.patientList = patients;
  }

  setPatientCount(count: number) {
    this.patientTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setPatientsForMerging(ids: number[]) {
    const patients = ids.map((id) =>
      this.patientList.find(({ refid }) => refid === id)
    );
    this.patientsForMerge = patients;
  }

  clearPatientsForMerging() {
    this.patientsForMerge = [];
  }

  async getPatientsCount() {
    const { filter } = this;

    const data = [
      filter.status,
      filter.facilityId,
      filter.lastName,
      filter.birthday,
      filter.firstName,
      filter.phoneNumber,
      filter.ssn,
      filter.mrn,
      filter.gender,
    ];
    try {
      const count = await apiRequest<string>({
        url: 'patient.PatientInfo.PatientRecordCount',
        data,
      });

      this.setPatientCount(Number(count) || 0);
    } catch (e: any) {
      this.setPatientCount(0);
    }
  }

  async getPatientsList() {
    const {
      filter,
      page: { pagination },
    } = this;

    const data = [
      filter.status,
      filter.facilityId,
      filter.lastName,
      filter.birthday,
      pagination.skip,
      pagination.pageSize,
      filter.firstName,
      filter.phoneNumber,
      filter.ssn,
      filter.mrn,
      filter.gender,
      filter.correctional || null,
    ];

    try {
      const response = await apiRequest<PatientResponseType[]>({
        url: 'patient.PatientInfo.PatientRecordList',
        data,
      });

      const patients = response.map((patient) => ({
        ...patient,
        gender: patient.gender_lbl,
        genderView: patient.gender,
        facility_id: Number(patient.facility_id),
        refid: Number(patient.refid),
        salutation: Number(patient.salutation),
        suffix: Number(patient.suffix),
      }));

      this.setPatientList(patients);
    } catch (e: any) {
      this.setPatientList([]);
    }
  }

  // TODO: Delete this method after order will modified
  async getPatientsForOrder({
    filter,
    skip,
    pageSize,
  }: {
    skip: number;
    pageSize: number;
    filter: FilterType;
  }) {
    const data = [
      filter.status,
      filter.facilityId,
      filter.lastName,
      filter.birthday,
      skip,
      pageSize,
      filter.firstName,
      filter.phoneNumber,
      filter.ssn,
      filter.mrn,
      filter.gender,
      filter.correctional || null,
    ];

    try {
      const response = await apiRequest<PatientResponseType[] | 0>({
        url: 'patient.PatientInfo.PatientRecordList',
        data,
      });

      return (response || []).map((patient) => ({
        ...patient,
        gender: patient.gender_lbl,
        genderView: patient.gender,
        facility_id: Number(patient.facility_id),
        refid: Number(patient.refid),
        salutation: Number(patient.salutation),
        suffix: Number(patient.suffix),
      }));
    } catch (e: any) {
      return [];
    }
  }

  getPatientsListMain = () => {
    this.setFetching(true);

    const promiseCount = this.getPatientsCount();

    const promiseList = this.getPatientsList();

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async changePatientStatus(id: number, status: 'Active' | 'Inactive') {
    try {
      const result = await apiRequest<'S' | 'SE'>({
        url: 'patient.PatientInfo.UpdateStatus',
        data: [id, status],
      });

      return result === 'S';
    } catch (e: any) {
      return false;
    }
  }

  changePatientListStatus = (ids: number[]) => {
    const status = this.filter.status === 'A' ? 'Active' : 'Inactive';

    this.setFetching(true);

    const promises = ids.map((id) => this.changePatientStatus(id, status));

    return Promise.allSettled(promises).then((response) => {
      const totalSucceed = response.reduce(
        // @ts-ignore
        (count, current) => count + current.value,
        0
      );
      if (totalSucceed) {
        Notification.success(
          `${totalSucceed} patient${totalSucceed > 1 ? 's were' : 'was'} ${
            this.filter.status === 'A' ? 'deactivated' : 'activated'
          } successfully!`
        );
        return true;
      }

      this.setFetching(false);
      Notification.danger('Error an occurred!');
      return false;
    });
  };

  async mergePatients({
    activePatientId,
    patients,
  }: PatientForMergePayloadType) {
    const activePatient = { refid: activePatientId };

    const inactivePatients = patients.reduce<{ refid: number }[]>(
      (prev, { value }) =>
        value !== activePatientId ? prev.concat({ refid: value }) : prev,
      []
    );

    const data = [inactivePatients, [activePatient], null];

    this.setMergeProgress(true);

    try {
      const result = await apiRequest<'S' | 'SE'>({
        url: 'patient.PatientInfo.mergePatient',
        data,
      });
      if (result === 'S') {
        Notification.success('Patients successfully merged!');
        return true;
      }
      return true;
    } catch (e: any) {
      Notification.danger('Error an occurred');
      return false;
    } finally {
      this.setMergeProgress(false);
    }
  }

  async changePatientFacility({
    facilityId,
    patientId,
  }: {
    facilityId: number;
    patientId: number;
  }) {
    this.setChangeFacilityProgress(true);
    try {
      const data = [{ facilityid: facilityId }, patientId];

      await apiRequest({
        url: 'facility.FacilityMaster.changeFacilityForPatient',
        data,
      });
      return true;
    } catch (error: unknown) {
      Notification.danger("Sorry, we can't change facility for this patient!");
      return false;
    } finally {
      this.setChangeFacilityProgress(false);
    }
  }

  async createPatientThroughOrder(payload: PatientFormBasicData) {
    try {
      this.setFetching(true);

      const [{ user_id, birthdate, ...patient }] = await apiRequest<
        [CreatingPatientResponseType]
      >({
        url: 'patient.PatientInfo.AddNewPatientInfo',
        data: [payload],
      });

      Notification.success('New patient was created!');

      return {
        ...patient,
        dob: birthdate,
        facility_id: Number(patient.facility_id),
        refid: Number(user_id),
      };
    } catch (error: any) {
      Notification.danger('Error occurred!');
      return null;
    } finally {
      this.setFetching(false);
    }
  }
}

export const storePatientDemographic = new PatientDemographic();
