import { ReduceStore } from 'flux/utils';
import { extend } from 'utils/IterableUtils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import A from 'constant/system-setup/master-setup/operations/jurisdiction/JurisdictionDetailsActions';
import JurisdictionDetailsActions from 'actions/system-setup/master-setup/operations/jurisdiction/JurisdictionDetailsActions';
import { TPagination } from 'components/grid/GridTypes';

class TEmptyZipCodeList {
  deleteZipCodeConfirm: {
    isShown: boolean;
    id: number;
  } = {
    isShown: false,
    id: null,
  };
  selected: Array<any> = [];
  onAjax: boolean = true;
  dataSource: Array<any> = [];
  dataSourceCount: number = 0;
  filter: {
    model: any;
    errors: any;
  } = {
    model: {},
    errors: {},
  };
  pagination: TPagination = new TPagination();
}

class TEmptyZipCodeRangeList {
  deleteZipCodeRangeConfirm: {
    isShown: boolean;
    zipStart: string;
    zipEnd: string;
  } = {
    isShown: false,
    zipStart: null,
    zipEnd: null,
  };
  selected: Array<any> = [];
  onAjax: true;
  dataSource: Array<any> = [];
  dataSourceCount: number = 0;
  filter: {
    model: any;
    errors: any;
  } = {
    model: {},
    errors: {},
  };
  pagination: TPagination = new TPagination();
}

class SJurisdictionDetailsPageStore {
  isLoading: boolean = false;
  zipDictionaryModal: {
    isShown: boolean;
  } = {
    isShown: false,
  };
  zipRangeDetailsModal: {
    isShown: boolean;
  } = {
    isShown: false,
  };
  modalityList: {
    dataSource: Array<any>;
  } = {
    dataSource: [],
  };
  stateList: {
    dataSource: Array<any>;
  } = {
    dataSource: [],
  };
  zipCodeRangeList: TEmptyZipCodeRangeList = new TEmptyZipCodeRangeList();
  zipCodeList: TEmptyZipCodeList = new TEmptyZipCodeList();
  model: {
    stateId: string;
    area: string;
  } = {
    stateId: '',
    area: '',
  };
  errors: any = {};
}

class JurisdictionDetailsPageStore extends ReduceStore<
  SJurisdictionDetailsPageStore,
  CommonPayload
> {
  getInitialState() {
    return new SJurisdictionDetailsPageStore();
  }

  areEqual(
    state1: SJurisdictionDetailsPageStore,
    state2: SJurisdictionDetailsPageStore
  ) {
    return false;
  }

  reduce(prevState: SJurisdictionDetailsPageStore, action: CommonPayload) {
    let state = A.contains(action.type)
      ? extend(true, {}, prevState)
      : prevState;
    switch (action.type) {
      case A.LOAD_DETAILS:
        state.model = action.data;
        state.isLoading = false;
        break;
      case A.LOAD_STATE_LIST:
        state.stateList.dataSource = action.data;
        break;
      case A.LOAD_MODALITY_LIST:
        state.modalityList.dataSource = action.data.result;
        break;
      case A.LOAD_ZIP_CODES:
        state.zipCodeList.dataSource = action.data.result;
        state.zipCodeList.dataSourceCount = action.data.totalCount;
        state.zipCodeList.onAjax = false;
        break;
      case A.LOAD_ZIP_CODE_RANGES:
        state.zipCodeRangeList.dataSource = action.data.result;
        state.zipCodeRangeList.dataSourceCount = action.data.totalCount;
        state.zipCodeRangeList.onAjax = false;
        break;
      case A.UPDATE_MODEL_AND_ERRORS_PROPERTY: {
        let p = action.data.property;
        state.model[p.expression] = p.value;
        state.errors[p.expression] = p.error;
        break;
      }
      case A.UPDATE_MODEL_AND_ERRORS:
        state.model = action.data.model;
        state.errors = action.data.errors;
        break;
      case A.UPDATE_ZIP_CODE_FILTER: {
        let p = action.data.property;
        let f = state.zipCodeList.filter;
        f.model[p.expression] = p.value;
        f.errors[p.expression] = p.error;
        this.loadZipCodes(state, (action as any).id);
        break;
      }
      case A.UPDATE_ZIP_CODE_RANGE_FILTER: {
        let p = action.data.property;
        let f = state.zipCodeRangeList.filter;
        f.model[p.expression] = p.value;
        f.errors[p.expression] = p.error;
        this.loadZipCodeRanges(state, (action as any).id);
        break;
      }
      case A.RESET_MODEL_AND_ERRORS:
        let initialState = this.getInitialState();
        state.model = initialState.model;
        state.errors = initialState.errors;
        break;
      case A.CHANGE_ZIP_CODE_LIST_PAGINATION:
        state.zipCodeList.pagination = action.data.pagination;
        this.loadZipCodes(state, (action as any).id);
        break;
      case A.CHANGE_ZIP_CODE_RANGE_LIST_PAGINATION:
        state.zipCodeRangeList.pagination = action.data.pagination;
        this.loadZipCodeRanges(state, (action as any).id);
        break;
      case A.TOGGLE_ZIP_CODE_DICTIONARY_MODAL:
        state.zipDictionaryModal.isShown = action.data.toggle;
        break;
      case A.TOGGLE_ZIP_CODE_RANGE_DETAILS_MODAL:
        state.zipRangeDetailsModal.isShown = action.data.toggle;
        break;
      case A.TOGGLE_ZIP_CODE_DELETE_CONFIRM:
        state.zipCodeList.deleteZipCodeConfirm.isShown = action.data.toggle;
        state.zipCodeList.deleteZipCodeConfirm.id = action.data.id || null;
        break;
      case A.TOGGLE_ZIP_CODE_RANGE_DELETE_CONFIRM:
        state.zipCodeRangeList.deleteZipCodeRangeConfirm.isShown =
          action.data.toggle;
        state.zipCodeRangeList.deleteZipCodeRangeConfirm.zipStart =
          action.data.zipStart || null;
        state.zipCodeRangeList.deleteZipCodeRangeConfirm.zipEnd =
          action.data.zipEnd || null;
        break;
      case A.TOGGLE_ZIP_CODES_LOADING:
        state.zipCodeList.onAjax = action.data.toggle;
        break;
      case A.TOGGLE_ZIP_CODE_RANGES_LOADING:
        state.zipCodeRangeList.onAjax = action.data.toggle;
        break;
      case A.SELECT_ZIP_CODES:
        state.zipCodeList.selected = action.data.selected;
        break;
      case A.SELECT_ZIP_CODE_RANGES:
        state.zipCodeRangeList.selected = action.data.selected;
        break;
      case A.ADD_ZIP_CODE:
        state.zipCodeList.dataSource.push({ zip: action.data.zip });
        state.zipCodeList.dataSourceCount++;
        break;
      case A.DELETE_ZIP_CODE:
        const filteredList = state.zipCodeList.dataSource.filter(
          ({ zip }: any) => zip !== action.data.zip
        );
        state.zipCodeList.dataSource = filteredList;
        state.zipCodeList.dataSourceCount = filteredList.length;
        break;
      case A.ADD_ZIP_CODE_RANGE:
        state.zipCodeRangeList.dataSource.push({
          zipStart: action.data.zipStart,
          zipEnd: action.data.zipEnd,
        });
        state.zipCodeRangeList.dataSourceCount++;
        break;
      case A.DELETE_ZIP_CODE_RANGE:
        state.zipCodeRangeList.dataSource =
          state.zipCodeRangeList.dataSource.filter(
            (o: any) =>
              o.zipStart !== action.data.zipStart &&
              o.zipEnd !== action.data.zipEnd
          );
        state.zipCodeRangeList.dataSourceCount--;
        break;
      case 'jurisdiction/clear-zip-codes':
        state.zipCodeList = new TEmptyZipCodeList();
        break;
      case 'jurisdiction/clear-zip-codes-range':
        state.zipCodeRangeList = new TEmptyZipCodeRangeList();
        break;
      default:
        break;
    }
    return state;
  }

  loadZipCodeRanges(state: SJurisdictionDetailsPageStore, id: number) {
    if ((id + '').toLowerCase() === 'new') {
      return;
    }
    const list = state.zipCodeRangeList;
    const pg = list.pagination;
    JurisdictionDetailsActions.loadJurisdictionZipCodeRanges(
      id,
      list.filter.model,
      {
        skip: pg.skip,
        limit: pg.pageSize,
      }
    );
  }

  loadZipCodes(state: SJurisdictionDetailsPageStore, id: number) {
    const list = state.zipCodeList;
    const pg = list.pagination;
    JurisdictionDetailsActions.loadJurisdictionZipCodes(id, list.filter.model, {
      skip: pg.skip,
      limit: pg.pageSize,
    });
  }
}

const jurisdictionPageStore = new JurisdictionDetailsPageStore(appDispatcher);
export default jurisdictionPageStore;
