import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { MultipleFileUploadControl } from 'components/form/input/MultipleFileUpload';
import { Button } from 'components/button';
import { InputCalendar } from 'components/form/inputCalendar';
import resolver from './validation';

import { GeneratorFormModel } from 'stores/_mobx/workflow/equipmentInventory/generator';

const initialValues: GeneratorFormModel = {
  calibration_date: '',
  calibration_due_date: '',
  generator_slno: '',
  name: '',
  refid: null,
  start_date: '',
  documentList: [],
};

interface PropsType {
  backUrl: string;
  defaultValues?: GeneratorFormModel;
  onSubmit: (data: GeneratorFormModel) => Promise<any>;
}

const FormModifyBoneSonometer = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: GeneratorFormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('generator_slno', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={isSubmitting ? 'on-ajax' : ''}
      onSubmit={handleClickSubmit}>
      <div className="row">
        <Input
          name="generator_slno"
          label="Serial No."
          className="col-md-7 col-lg-4"
          required
          control={control}
        />
        <div />
        <Input
          name="name"
          label="Generator name"
          className="col-md-7 col-lg-4"
          required
          control={control}
        />
      </div>

      <div className="row">
        <InputCalendar
          name="start_date"
          label="Installation purchase date"
          className="col-md-7 col-lg-4"
          required
          control={control}
        />
        <InputCalendar
          name="calibration_date"
          label="Last calibration date"
          className="col-md-7 col-lg-4"
          control={control}
        />
        <InputCalendar
          name="calibration_due_date"
          label="Next calibration date"
          className="col-md-7 col-lg-4"
          control={control}
        />
      </div>

      <MultipleFileUploadControl
        name="documentList"
        dir="generator"
        control={control}
      />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          type="reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormModifyBoneSonometer;
