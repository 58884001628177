import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FakeInput } from 'components/form/textField';
import { Textarea } from 'components/form/textarea';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import resolver from './validation';

import { storeClaimReview } from 'stores/_mobx/billingCodding/claimReview';

interface FormModel {
  orderId: number;
  cptCode: string;
  examDescription: string;
  diagnosisDescription: string;
  diagnosisCode: string;
  notes: string;
}

interface PropsType {
  examDetails: FormModel;
}

const TabFormExamDescription = ({ examDetails }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<FormModel>({ defaultValues: examDetails, resolver });

  useEffect(() => {
    reset(examDetails);
  }, [examDetails, reset]);

  return (
    <form
      className={`row${isSubmitting ? ' on-ajax' : ''}`}
      onSubmit={handleSubmit(storeClaimReview.addClaimNotes)}>
      <FakeInput label="Exam Description" value={examDetails.examDescription} />
      <FakeInput
        label="Diagnosis Description"
        value={examDetails.diagnosisDescription}
      />
      <FakeInput
        label="CPT Code"
        className="col-sm-6"
        value={examDetails.cptCode}
      />
      <FakeInput
        label="Diagnosis Code"
        className="col-sm-6"
        value={examDetails.diagnosisCode}
      />
      <Textarea
        name="notes"
        label="Claim Notes"
        className="mb-5"
        required
        rows={3}
        control={control}
      />

      <ControlsLayout alignX="right">
        <Button
          type="submit"
          text="Save Claim Notes"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default TabFormExamDescription;
