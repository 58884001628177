import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid, TPagination } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import { storeGrid } from 'stores/_mobx/grid';
import { storeIcdCode } from 'stores/_mobx/workflow/order/icdCode';
import { getPagination } from 'utils/getPagination';

const columns = [
  { name: 'icd_code', title: 'ICD Code', className: 'width-100' },
  { name: 'short_description', title: 'ICD Description' },
  { name: 'created_dt', title: 'Created Date' },
  { name: 'status', title: 'Status', className: 'width-100 text-center' },
];

interface PropsType {
  orderId: number;
  onClose: () => void;
}

const DialogICDLog = ({ orderId, onClose }: PropsType) => {
  const { fetchingIcdLog, icdLogList, icdLogTotal } = storeIcdCode;

  const [pagination, setPagination] = useState<TPagination>(
    getPagination(storeGrid.getPageSize('icdLogGrid'))
  );

  useEffect(() => {
    storeIcdCode.getIcdLogListMain({ orderId, pagination });
  }, [orderId, pagination]);

  useEffect(() => storeIcdCode.clearLogs, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Initial ICD Code" onClose={onClose} />

      <DialogBody>
        <Grid
          id="icdLogGrid"
          columns={columns}
          onAjax={fetchingIcdLog}
          dataSource={icdLogList}
          dataSourceCount={icdLogTotal}
          pagination={pagination}
          onPaginationChange={setPagination}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogICDLog);
