import { Container } from 'flux/utils';

import BarChart from 'components/project/common/charts/BarChart';
import { ORDER_REPORT as PAGE_ID } from 'constant/pagesId/reports';
import AbstractReportPage from '../AbstractReportPage';
import { prettierFilterPayload } from '../filter/prettierFilterPayload';

import OrderReportPageActions from 'actions/reports/report-creator/order-report/OrderReportPageActions';
import OrderReportPageStore from 'stores/reports/report-creator/order-report/OrderReportPageStore';

const COLUMNS = [
  { name: 'firstname', title: 'First name' },
  { name: 'lastname', title: 'Last name' },
  { name: 'faci_name', title: 'Facility name' },
  { name: 'ordertype', title: 'Order type' },
  { name: 'dos', title: 'DOS', className: 'text-nowrap', dataType: 'date' },
  { name: 'modality', title: 'Modality' },
  { name: 'visit', title: 'Visit' },
  { name: 'group_nm', title: 'Group name' },
  { name: 'cpt_code', title: 'CPT Code' },
  { name: 'cpt_description', title: 'CPT Description' },
  { name: 'cpt_category', title: 'CPT Category' },
  { name: 'priority', title: 'Priority' },
];

export class OrderReportPage extends AbstractReportPage {
  static getStores() {
    return [OrderReportPageStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = AbstractReportPage.getPageState('order_report_grid');
      prevState.exporterName = 'OrderReportExporter';
    }
    return {
      ...prevState,
      appId: PAGE_ID.PAGE,
      dataSource: OrderReportPageStore.getState().orderReportList,
      dataSourceCount: OrderReportPageStore.getState().orderReportFullListCount,
      chartValues: OrderReportPageStore.getState().orderReportDiagram,
    };
  }

  getTitle() {
    return 'Order Report';
  }

  getColumns() {
    return COLUMNS;
  }

  onSetConditionList(conditionList, joint) {
    const { state } = this;
    const pagination = { ...state.pagination, skip: 0, page: 1 };

    this.setState(
      {
        conditionList,
        joint,
        dataSourceCount: 0,
        pagination,
      },
      this.updateData.bind(this)
    );
  }

  getDataSourceCount() {
    return this.state.dataSourceCount ? this.state.dataSourceCount : 0;
  }

  getGridID() {
    return 'order_report_grid';
  }

  getExportedFileName() {
    return 'order_report';
  }

  getClass() {
    return 'order-report-overview-page';
  }

  renderChart() {
    return <BarChart data={this.state.chartValues} />;
  }

  getShownFilters() {
    return [
      'patientFirstName',
      'patientLastName',
      'mrn',
      'dos',
      'dob',
      'facility',
      'modality',
      'diagnosis',
      'studyID',
      'orderType',
      'corporate',
    ];
  }

  getExportDataSource() {
    const cptCodes = {};
    this.state.dataSource.forEach((v) => {
      const cpt = v.cpt_code.split(';');
      cpt.forEach((code) => {
        code = code.trim();
        if (cptCodes[code]) {
          cptCodes[code]++;
        } else {
          cptCodes[code] = 1;
        }
      });
    });
    const cptArray = [];
    for (let key in cptCodes) {
      cptArray.push({ code: key, count: cptCodes[key] });
    }
    return cptArray
      .sort((v1, v2) => v1 - v2)
      .map((v) => {
        return { firstname: v.code, lastname: v.count };
      })
      .concat(this.state.dataSource);
  }
  loadListAction(conditionList, joint) {
    const state = this.state;
    const pagination = state.pagination;
    const payload = prettierFilterPayload(conditionList);
    return OrderReportPageActions.loadOrderReportList(
      payload,
      joint,
      pagination.skip,
      pagination.pageSize,
      state.dataSourceCount
    );
  }

  onPaginationChange = (pagination) => {
    this.setState({ pagination }, () => this.updateData());
  };

  getSpreadProps() {
    return {
      pagination: this.state.pagination,
      onPaginationChange: this.onPaginationChange,
    };
  }

  getActions() {
    return OrderReportPageActions;
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }
}

export default Container.create(OrderReportPage);
