import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Radio } from 'components/form/radio';
import { Button } from 'components/button';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader
} from 'components/modal/dialog';

export interface ExportFormatOptionType {
  value: string;
  label: string;
}

const volumeOptions: ExportFormatOptionType[] = [
  { value: 'selected', label: 'Selected' },
  { value: 'all', label: 'All' },
];

interface OptionType {
  exportFormat: string;
  volume: string;
}

type PropsType = {
  popupTitle: string;
  submitBtnText: string;
  defaultValues: OptionType;
  exportFormatOptions: ExportFormatOptionType[];
  onSubmit: (data: OptionType) => void;
  onClose: () => void;
}

const DialogEligibilityExport = ({
  popupTitle,
  submitBtnText,
  defaultValues,
  exportFormatOptions,
  onSubmit,
  onClose,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<OptionType>({ defaultValues });

  const handleClickSubmit = () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Dialog handleClose={onClose}>
      <DialogHeader title={popupTitle} onClose={onClose} />

      <DialogBody>
        <form>
          { exportFormatOptions.length ? (
            <Radio
              name="exportFormat"
              label="Export Format"
              required
              options={exportFormatOptions}
              control={control}
            />
          ) : null }
          <Radio
            name="volume"
            label="Volume"
            required
            options={volumeOptions}
            control={control}
          />
        </form>
      </DialogBody>

      <DialogFooter>
        <Button
          text={submitBtnText}
          disabled={isSubmitting}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogEligibilityExport;
