import { InsuranceDetailsType } from 'stores/_mobx/billingCodding/claimReview';

interface PropsType {
  insurance: InsuranceDetailsType;
}

const InsuranceDetailsBlock = ({ insurance }: PropsType) => (
  <div className="row">
    <div className="col-md-6">
      <div className="mb-3">
        <dt>First Name:</dt>
        <dd>{insurance.firstName}</dd>
      </div>
      <div className="mb-3">
        <dt>Middle Name:</dt>
        <dd>{insurance.middleName}</dd>
      </div>
      <div className="mb-3">
        <dt>Last Name:</dt>
        <dd>{insurance.lastName}</dd>
      </div>
      <div className="mb-3">
        <dt>Date of birth:</dt>
        <dd>{insurance.dateOfBirth}</dd>
      </div>
      <div className="mb-3">
        <dt>Gender:</dt>
        <dd>{insurance.gender}</dd>
      </div>
      <div className="mb-3">
        <dt>Group number:</dt>
        <dd>{insurance.groupNumber}</dd>
      </div>
    </div>

    <div className="col-md-6">
      <div className="mb-3">
        <dt>Insured Relationship:</dt>
        <dd>{insurance.relationship}</dd>
      </div>
      <div className="mb-3">
        <dt>Effective start date:</dt>
        <dd>{insurance.startDate}</dd>
      </div>
      <div className="mb-3">
        <dt>Effective end date:</dt>
        <dd>{insurance.endDate}</dd>
      </div>
      <div className="mb-3">
        <dt>Insurance number:</dt>
        <dd>{insurance.insuranceNumber}</dd>
      </div>
      <div className="mb-3">
        <dt>Organization name:</dt>
        <dd>{insurance.organizationName}</dd>
      </div>
      <div className="mb-3">
        <dt>Payer name:</dt>
        <dd>{insurance.payerName}</dd>
      </div>
      <div className="mb-3">
        <dt>Payer plan:</dt>
        <dd>{insurance.payerPlanName}</dd>
      </div>
    </div>
  </div>
);

export default InsuranceDetailsBlock;
