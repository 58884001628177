import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

class SApplicationUsersPageStore {
  dataSource: Array<any> = [];
  dataSourceCount: number = 0;
  //this property called dirty, because it can be set implicitly, after data loaded
  isHrAdminDirty: boolean = true;
}

class ApplicationUsersPageStore extends ReduceStore<
  SApplicationUsersPageStore,
  CommonPayload
> {
  getInitialState() {
    return new SApplicationUsersPageStore();
  }

  areEqual(
    state1: SApplicationUsersPageStore,
    state2: SApplicationUsersPageStore
  ) {
    return false;
  }

  reduce(state: SApplicationUsersPageStore, action: CommonPayload) {
    switch (action.type) {
      case 'application-users-employee-overview':
        this.getState().isHrAdminDirty = (
          action as any
        ).hrAdminPermissionAllowed;
        this.getState().dataSource = action.data;
        this.getState().dataSourceCount = (action as any).count;
        break;
      default:
        break;
    }
    return state;
  }
}

const applicationUsersPageStore = new ApplicationUsersPageStore(appDispatcher);
export default applicationUsersPageStore;
