import { Container } from 'flux/utils';

import FacilityExamCountPageActions from 'actions/reports/report-creator/facility-exam-count/FacilityExamCountPageActions';
import FacilityExamCountPageStore from 'stores/reports/report-creator/facility-exam-count/FacilityExamCountPageStore';
import PieChart from 'components/project/common/charts/PieChart';
import AbstractReportPage from '../AbstractReportPage';
import { prettierFilterPayload } from '../filter/prettierFilterPayload';

import { FACILITY_EXAM_COUNT as PAGE_ID } from 'constant/pagesId/reports';

const COLUMNS = [
  { name: 'facilityname', title: 'Facility name' },
  { name: 'newcnt', title: 'New exam count' },
  { name: 'asscnt', title: 'Assigned exam count' },
  { name: 'procnt', title: 'Process exam count' },
  { name: 'comcnt', title: 'Completed exam count' },
];

export class FacilityExamCountPage extends AbstractReportPage {
  static getStores() {
    return [FacilityExamCountPageStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = AbstractReportPage.getPageState('facility_exam_count_grid');
      prevState.paginationStrategy = AbstractReportPage.LOGIC_PAGINATION;
    }
    return {
      ...prevState,
      appId: PAGE_ID.PAGE,
      dataSource: FacilityExamCountPageStore.getState().facilityExamCountList,
      chartValues:
        FacilityExamCountPageStore.getState().facilityExamCountDiagram,
    };
  }

  getShownFilters() {
    return [
      'patientFirstName',
      'patientLastName',
      'mrn',
      'facility',
      'diagnosis',
      'cpt',
      'facilityNPI',
      'facilityPhone',
      'dispatchedDate',
      'examCompletedDate',
      'corporate',
    ];
  }

  getTitle() {
    return 'Facility Exam Count';
  }

  getColumns() {
    return COLUMNS;
  }

  getGridID() {
    return 'facility_exam_count_grid';
  }

  getExportedFileName() {
    return 'facility_exam_count';
  }

  getClass() {
    return 'facility-exam-count-overview-page';
  }

  renderChart() {
    return <PieChart data={this.state.chartValues} />;
  }

  loadListAction(conditionList, joint) {
    const payload = prettierFilterPayload(conditionList);

    return FacilityExamCountPageActions.loadFacilityExamCountList(
      payload,
      joint
    );
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }

  getActions() {
    return FacilityExamCountPageActions;
  }
}

const facilityExamCountPage = Container.create(FacilityExamCountPage);
export default facilityExamCountPage;
