import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { phoneValidator } from 'utils/formValidators';

const validation = yupResolver(
  yup.object().shape({
    description: yup.string().required('Enter the phone description!'),
    phoneNumber: yup.string().required('Enter the phone number!').test({
      test: phoneValidator,
      message: 'Should be in xxx-xxx-xxxx format.',
    }),
    operatorId: yup
      .number()
      .typeError('Select a mobile operator!')
      .positive('Select a mobile operator!'),
  })
);

export default validation;
