export const URL_PHYSICIANS = '/clinician-manager/physicians';
export const URL_PHYSICIANS_EDIT = `${URL_PHYSICIANS}/edit/:id`;
export const URL_PHYSICIANS_ADD = `${URL_PHYSICIANS}/new`;

export const URL_GROUP_MANAGER = '/clinician-manager/group-manager';
export const URL_GROUP_MANAGER_ADD = `${URL_GROUP_MANAGER}/new`;
export const URL_GROUP_MANAGER_EDIT = `${URL_GROUP_MANAGER}/edit/:id`;

export const URL_SIGNED_ORDER = '/clinician-manager/sign-orders';
export const URL_SIGNED_ORDER_UNSIGNED_TAB = `${URL_SIGNED_ORDER}/unsigned-orders`;
export const URL_SIGNED_ORDER_SIGNED_TAB = `${URL_SIGNED_ORDER}/signed-orders`;
export const URL_SIGNED_ORDER_GROUP_TAB = `${URL_SIGNED_ORDER}/group-orders`;
