import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import {
  storeUltrasound,
  UltrasoundFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/ultrasound';
import { URL_ULTRASOUND } from 'constant/path/workflow';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const UltrasoundEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, ultrasound } = storeUltrasound;

  const handleSubmit = async (data: UltrasoundFormModel) =>
    storeUltrasound.updateUltrasound(data).then((error) => {
      if (error) return error;
      history.push(URL_ULTRASOUND);
    });

  useEffect(() => {
    if (!fetching) storeUltrasound.getUltrasound(Number(params.id));

    return storeUltrasound.clearUltrasound;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Ultrasound" />
      <Form
        backUrl={URL_ULTRASOUND}
        defaultValues={ultrasound}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};
export default observer(UltrasoundEditPage);
