import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionsResponseType {
  data: string;
  label: string;
}

export interface OptionType {
  value: number;
  label: string;
}

interface GroupOptionType {
  fetching: boolean;
  options: OptionType[];
}

class DropdownFloor {
  groupOptions: Record<number, GroupOptionType> = {};

  constructor() {
    makeObservable(this, {
      groupOptions: observable,

      setFetching: action,
      setOptions: action,
    });
  }

  setFetching(facilityId: number, fetching: boolean) {
    const group = this.groupOptions[facilityId];

    this.groupOptions[facilityId] = {
      options: group?.options || [],
      fetching,
    };
  }

  setOptions(facilityId: number, options: OptionType[]) {
    const group = this.groupOptions[facilityId];

    this.groupOptions[facilityId] = {
      fetching: group?.fetching,
      options,
    };
  }

  async getOptions(facilityId: number) {
    const isFetching = this.groupOptions[facilityId]?.fetching;

    if (isFetching) return Promise.resolve([]);

    this.setFetching(facilityId, true);
    try {
      const list = await apiRequest<OptionsResponseType[]>({
        url: 'facility.FacilityMaster.FloorDropDown',
        data: [facilityId],
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));
      this.setOptions(facilityId, options);
    } catch {
      this.setOptions(facilityId, []);
    } finally {
      this.setFetching(facilityId, false);
    }
  }
}

export const storeDropdownFloor = new DropdownFloor();
