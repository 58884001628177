import FacilityPageService, {
  TFacilityItemRes,
} from 'services/system-setup/master-setup/facility/FacilityPageService';
import { Actions } from 'stores/system-setup/facility/FacilityPageStore';
import appDispatcher from 'dispatcher/AppDispatcher';
import { apiRequest } from 'services/RequestService';

export default class FacilityPageActions {
  static saveFacilityItem(item: any) {
    return FacilityPageService.saveFacilityItem(item);
  }

  static updateFacilityItem(item: any) {
    return FacilityPageService.updateFacilityItem(item).then(
      (response: 'E' | number) => {
        return response;
      }
    );
  }

  static loadFacilityItem(id: number) {
    return FacilityPageService.loadFacilityItem(id).then(
      (response: TFacilityItemRes) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_FACILITY_ITEM_ACTION,
          data: response,
        });
        return response;
      }
    );
  }

  static loadLocationList(regionId: number, stateId: number) {
    return FacilityPageService.loadLocationList(regionId, stateId).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_LOCATION_LIST,
          data: response,
        });
        return response;
      }
    );
  }

  static loadBillingItem(id: number) {
    return FacilityPageService.loadBillingItem(id).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_BILLING_ITEM_ACTION,
        data: response,
      });
    });
  }

  static loadServiceList(id: number) {
    return FacilityPageService.loadServiceList(id).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_SERVICE_LIST_ACTION,
        data: response,
      });
    });
  }

  static updateBillingItem(item: any) {
    return FacilityPageService.updateBillingItem(item);
  }

  static loadContactList(id: number, flagActiveOnly: any) {
    return FacilityPageService.loadContactList(id, flagActiveOnly).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_CONTACT_LIST_ACTION,
          data: response,
        });
      }
    );
  }

  static saveContactList(item: any) {
    return FacilityPageService.saveContactList(item);
  }

  static saveContactListDirect(facilityId: any, comments: any, ownerList: any) {
    return FacilityPageService.saveContactListDirect(
      facilityId,
      comments,
      ownerList
    );
  }

  static loadContactItem(id: any) {
    return FacilityPageService.loadContactItem(id).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_CONTACT_ITEM_ACTION,
        data: response,
      });
    });
  }

  static saveContactItem(item: any) {
    return FacilityPageService.saveContactItem(item);
  }

  static updateContactItem(item: any) {
    return FacilityPageService.updateContactItem(item);
  }

  static deleteContactItem(id: any) {
    return FacilityPageService.deleteContactItem(id);
  }

  static loadDocumentList(facilityId: any) {
    return FacilityPageService.loadDocumentList(facilityId).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_DOCUMENT_LIST_ACTION,
        data: response,
      });
    });
  }

  static saveDocumentList(facilityId: any, fileList: any) {
    return FacilityPageService.saveDocumentList(facilityId, fileList);
  }

  static clearFacilityItem() {
    return appDispatcher.dispatch({
      type: 'reload-facility-on-fee-schedule',
    });
  }

  static clearAllTabs() {
    return appDispatcher.dispatch({
      type: Actions.CLEAR_ALL_TABS_ACTION,
      data: {},
    });
  }

  static updateTabsState(currentTab: number, blocked: any) {
    return appDispatcher.dispatch({
      type: Actions.UPDATE_TABS_STATE,
      data: {
        currentTab,
        blocked,
      },
    });
  }

  static updateCurrentTab(currentTab: any) {
    return appDispatcher.dispatch({
      type: Actions.UPDATE_CURRENT_TAB,
      data: {
        currentTab: currentTab,
      },
    });
  }

  static setBackToList(backToList: any) {
    return appDispatcher.dispatch({
      type: Actions.SET_BACK_TO_LIST,
      data: {
        backToList: backToList,
      },
    });
  }

  static clearContactItemModal() {
    return appDispatcher.dispatch({
      type: Actions.CLEAR_CONTACT_ITEM_MODAL_ACTION,
      data: {},
    });
  }

  static onImport(fileName: string) {
    return apiRequest<'SE' | 'S' | 'E'>({
      url: 'codemaster.Modifier.ImportModifier',
      data: [fileName],
    });
  }
}
