import React, { useCallback, useEffect, useRef } from 'react';
import SignaturePad from 'signature_pad';
import clsx from 'clsx';

import { Button } from 'components/button';
import {
  fileToBase64,
  getBase64FromUrl,
  resizeCanvas,
  useOnScreen,
} from './helpers';

const padOptions = {
  throttle: 8,
  backgroundColor: 'rgb(255, 255, 255)',
};

const optionsForInsertImg = {
  ratio: 1,
  width: 300,
  height: 150,
  xOffset: 0,
  yOffset: 0,
};

export interface PureCanvasProps {
  name?: string;
  value?: string;
  label?: React.ReactNode;
  required?: boolean;
  className?: string;
  errorMessage?: string;
  'data-testid'?: string;
  onChange: (value: string) => void;
}

const PureCanvas = ({
  className,
  required,
  label,
  name,
  value,
  errorMessage,
  'data-testid': dataTestId,
  onChange,
}: PureCanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement>();

  const signaturePad = useRef<SignaturePad>();

  const isVisible = useOnScreen(canvasRef);

  const testAttr = dataTestId || name || 'input';

  const cn = clsx(
    'formfield-holder',
    'formfield-text',
    { required },
    className
  );

  const handleUploadFile = async (
    e: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const file = e.currentTarget?.files?.[0];
    try {
      if (file) signaturePad.current.clear();

      const result = file ? await fileToBase64(file) : '';

      if (result) {
        signaturePad.current.fromDataURL(result, optionsForInsertImg);
      }
      onChange(result);
    } catch (e: any) {
      onChange('');
    }
  };

  const handleChange = useCallback(() => {
    const img = signaturePad.current.toDataURL();

    onChange(img);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickClear = () => {
    signaturePad.current.clear();
    onChange('');
  };

  useEffect(() => {
    const padInstance = new SignaturePad(canvasRef.current, padOptions);

    signaturePad.current = padInstance;

    padInstance.addEventListener('endStroke', handleChange);

    resizeCanvas(canvasRef.current, padInstance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const valueIsLink = value && !value.startsWith('data:image/');

    if (valueIsLink && isVisible) {
      resizeCanvas(canvasRef.current, signaturePad.current);
      getBase64FromUrl(value).then((image) => {
        signaturePad.current.fromDataURL(image, optionsForInsertImg);
      });
    } else if (!value && isVisible) {
      resizeCanvas(canvasRef.current, signaturePad.current);
    }
  }, [value, isVisible]);

  return (
    <div className={cn}>
      {label ? (
        <label className="form-label" data-testid={`${testAttr}-label`}>
          {label}
        </label>
      ) : null}
      <div className="input-group">
        <canvas className="signature-pad-body" ref={canvasRef} />
      </div>
      {errorMessage && (
        <div className="error" data-testid={`${testAttr}-error`}>
          {errorMessage}
        </div>
      )}
      <div className="signature-pad-controls">
        <Button text="Clear" variant="warning" onClick={handleClickClear} />
        <label className="btn btn-primary">
          Upload Signature
          <input
            value=""
            type="file"
            accept="image/png"
            className="signature-pad-file"
            onChange={handleUploadFile}
          />
        </label>
      </div>
    </div>
  );
};

export default PureCanvas;
