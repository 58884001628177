import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

interface XRayUnitType {
  computer: string;
  device_name: string;
  name: string;
  plate_name: string;
  refid: number;
}

interface XRayUnitResponseType {
  computer_id: string;
  generator_id: string;
  plate_id: string;
  refid: string;
  xray_id: string;
}

interface XRayUnitEditType {
  computer_id: number;
  generator_id: number;
  plate_id: number;
  refid: number;
  xray_id: number;
}

interface OptionResponseType {
  data: string;
  label: string;
  type: string;
}

export interface OptionType {
  value: string;
  label: string;
}

class XRayUnit {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  xRayUnitList: XRayUnitType[] = [];
  xRayUnitTotal: number = 0;
  xRayUnit: XRayUnitEditType | undefined = undefined;
  idForDelete: number | null = null;
  idForNote: number = 0;
  options: OptionType[] = [];

  page: Pagination = new Pagination({ id: 'x_ray_unit_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      xRayUnitList: observable,
      xRayUnitTotal: observable,
      xRayUnit: observable,
      idForDelete: observable,
      idForNote: observable,
      options: observable,

      setFetching: action,
      setFetchingOptions: action,
      setXRayUnitList: action,
      setXRayUnitTotal: action,
      setXRayUnit: action,
      setIdForNote: action,
      setIdForDelete: action.bound,
      clearIdForDelete: action.bound,
      clearXRayUnit: action.bound,
      clearIdForNote: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setXRayUnitList(list: XRayUnitType[]) {
    this.xRayUnitList = list;
  }

  setXRayUnitTotal(count: number) {
    this.xRayUnitTotal = count;
  }

  setXRayUnit(xRayUnit?: XRayUnitEditType) {
    this.xRayUnit = xRayUnit;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setIdForNote(id: number) {
    this.idForNote = id;
  }

  clearIdForDelete() {
    this.idForDelete = null;
  }

  clearXRayUnit() {
    this.xRayUnit = undefined;
  }

  clearIdForNote() {
    this.idForNote = 0;
  }

  async getXRayUnitsList() {
    const { pagination } = this.page;

    try {
      const units = await apiRequest<'SE' | XRayUnitType[]>({
        url: 'inventory.XrayGroupMaster.GetGroupList',
        data: [pagination.skip, pagination.pageSize],
      });
      if (Array.isArray(units)) {
        const unitsList = units.map((unit) => ({
          ...unit,
          refid: Number(unit.refid),
        }));
        this.setXRayUnitList(unitsList);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setXRayUnitList([]);
    }
  }

  async getXRayUnitsCount() {
    try {
      const count = await apiRequest<'SE' | 'S' | number>({
        url: 'inventory.XrayGroupMaster.TotalRecordCount',
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setXRayUnitTotal(total);
    } catch (e) {
      this.setXRayUnitTotal(0);
    }
  }

  getXRayUnitsListMain() {
    this.setFetching(true);

    const promiseList = this.getXRayUnitsList();

    const promiseCount = this.getXRayUnitsCount();

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  }

  async getXRayUnit(id: number | string) {
    this.setFetching(true);
    try {
      const response = await apiRequest<'SE' | [XRayUnitResponseType]>({
        url: 'inventory.XrayGroupMaster.GetXrayGroup',
        data: [id],
      });
      if (Array.isArray(response)) {
        const unit = Object.entries(response[0]).reduce(
          (prev, [key, value]) => ({
            ...prev,
            [key]: Number(value),
          }),
          {} as XRayUnitEditType
        );

        this.setXRayUnit(unit);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setXRayUnit();
    } finally {
      this.setFetching(false);
    }
  }

  async addXRayUnit(payload: Omit<XRayUnitEditType, 'refid'>) {
    const data = [
      payload.xray_id,
      payload.generator_id,
      payload.computer_id,
      payload.plate_id,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'X'>({
        url: 'inventory.XrayGroupMaster.AddXrayGroupMaster',
        data,
      });
      if (result === 'S') {
        Notification.success('X-Ray Unit was added successfully!');
        return true;
      } else if (result === 'X') {
        Notification.warning('X-Ray Unit with the same parameters are exist!');
        return false;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async updateXRayUnit(payload: XRayUnitEditType) {
    const data = [
      payload.refid,
      payload.xray_id,
      payload.generator_id,
      payload.computer_id,
      payload.plate_id,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'X'>({
        url: 'inventory.XrayGroupMaster.UpdGroup',
        data,
      });

      if (result === 'S') {
        Notification.success('X-Ray Unit was updated successfully!');
        return true;
      } else if (result === 'X') {
        Notification.warning('X-Ray Unit with the same parameters are exist!');
        return false;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async deleteXrayUnit() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'inventory.XrayGroupMaster.DeleteGroup',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('X-Ray Unit was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOptions(true);

    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'vehicle.DeviceMaster.ActiveXrayDropDown',
        data: ['Y', 'Y'],
      });

      const options = response.map(({ type, data, label }) => ({
        label,
        value: `${type}_${data}`,
      }));

      runInAction(() => {
        this.options = options;
        this.fetchingOptions = false;
      });
    } catch (error) {
      runInAction(() => {
        this.options = [];
        this.fetchingOptions = false;
      });
    }
  }
}

export const storeXRayUnit = new XRayUnit();
