import { makeObservable, observable, action, onBecomeObserved } from 'mobx';

import { TPagination } from 'components/grid/GridTypes';

import { storeGrid } from 'stores/_mobx/grid';
import { getPagination } from 'utils/getPagination';

interface PropsType {
  id?: string;
  pageSize?: number;
  clearOnUnmount?: boolean;
}

class Pagination {
  pagination: TPagination = null;
  props?: PropsType;

  constructor(props?: PropsType) {
    this.props = props;

    makeObservable(this, {
      pagination: observable,

      setPagination: action.bound,
      setPaginationToStart: action.bound,
      resetPagination: action.bound,
    });

    onBecomeObserved(this, 'pagination', this.getPaginatingSettings);
  }

  public setPagination(pagination: TPagination) {
    this.pagination = pagination;
  }

  public setPaginationToStart() {
    this.pagination = { ...this.pagination, page: 1, skip: 0 };
  }

  public resetPagination() {
    this.pagination = this.getDefaultSettings();
  }

  private getDefaultSettings = () => {
    const pageSize = this.props?.id
      ? storeGrid.getPageSize(this.props.id)
      : this.props?.pageSize;

    return getPagination(pageSize);
  };

  private getPaginatingSettings = () => {
    const pagination =
      this.props?.clearOnUnmount || !this.pagination
        ? this.getDefaultSettings()
        : this.pagination;

    this.setPagination(pagination);
  };
}

export default Pagination;
