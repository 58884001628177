import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';

import {
  storeEmailTemplate,
  FilterType,
} from 'stores/_mobx/systemSetup/masterSetting/emailTemplate';

const options = [
  {
    label: 'Default',
    value: 'D',
  },
  { label: 'Manual', value: 'M' },
];

interface PropsType {
  onChange: (filter: FilterType) => void;
}

const Filter = ({ onChange }: PropsType) => {
  const { control, watch } = useForm<FilterType>({
    defaultValues: storeEmailTemplate.filter,
  });

  useEffect(() => {
    const subscription = watch((formValue) => {
      onChange(formValue as FilterType);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row">
      <Input
        name="name"
        placeholder="Template name"
        className="col-md-6 col-lg-4"
        control={control}
      />

      <div className="col-md-4">
        <Radio
          name="type"
          className="inline"
          options={options}
          control={control}
        />
      </div>
    </form>
  );
};

export default Filter;
