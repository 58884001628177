import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import DialogConfirm from 'components/modal/dialogConfirm';
import Dialog, { DialogBody, DialogFooter } from 'components/modal/dialog';
import { Button } from 'components/button';
import { PureSelectTechnician } from 'components/project/dropdown/TechnicianDropdown';

import { storeDropdownTechnician } from 'stores/_mobx/dropDown/technician';
import { storeCheckOutIn } from 'stores/_mobx/workflow/checkOutIn/checkOutIn';
import { storeOrderOverview } from 'stores/_mobx/workflow/order/overview';

interface OrderPartType {
  patient_comment?: string;
  patientid: number;
  patientnm: string;
}

interface PropsType {
  orderList: OrderPartType[];
  isVaccinationRequired: boolean;
  unassignOption: boolean;
  orderIds: number[];
  onClose: () => void;
  onApprove: () => void;
}

const generateWarningList = (orderList: OrderPartType[]) => {
  const warningList: string[] = [];
  const seenPatientIds: number[] = [];

  orderList.forEach(({ patient_comment, patientid, patientnm }) => {
    if (patient_comment && !seenPatientIds.includes(patientid)) {
      warningList.push(`${patientnm}: ${patient_comment}`);
      seenPatientIds.push(patientid);
    }
  });

  return warningList;
};

const DialogAssignTechnician = ({
  orderList,
  unassignOption,
  orderIds,
  isVaccinationRequired,
  onClose,
  onApprove,
}: PropsType) => {
  const { fetchingEquipment, equipmentInfo } = storeCheckOutIn;

  const { proceedAssign } = storeOrderOverview;

  const warningList = useMemo(
    () => generateWarningList(orderList),
    [orderList]
  );

  const [selectedTechnicianId, setTechId] = useState<number>(0);

  const [isConfirmVisible, toggleConfirmation] = useState<boolean>(false);

  const handleToggleConfirmation = () => {
    toggleConfirmation((state) => !state);
  };

  const handleSelectTechnician = (id: number | null) => {
    storeCheckOutIn.getEquipmentInfoByTechId(id || 0);

    setTechId(id || 0);
  };

  const handleGetResponse = (isSucceed: boolean) => {
    if (isSucceed) onApprove();
  };

  const handleClickUnassign = () => {
    storeOrderOverview.unassignOrders(orderIds).then(handleGetResponse);
  };

  const handleClickAssign = () => {
    const payload = {
      ids: orderIds,
      technician: storeDropdownTechnician.findOption(selectedTechnicianId),
      state: unassignOption ? 'Assigned' : undefined,
    };
    storeOrderOverview.assignOrders(payload).then(handleGetResponse);
  };

  useEffect(() => {
    return storeCheckOutIn.clearEquipment;
  }, []);

  return (
    <>
      <Dialog scrollable={false} handleClose={onClose}>
        <DialogBody
          className={`assign-order${
            fetchingEquipment || proceedAssign ? ' on-ajax' : ''
          }`}>
          <PureSelectTechnician
            name="technologistId"
            value={selectedTechnicianId}
            disabled={fetchingEquipment}
            isVaccinationRequired={isVaccinationRequired}
            onChange={handleSelectTechnician}
          />
          <dl>
            <dt>Vehicle</dt>
            <dd>{equipmentInfo?.vehicle_name || 'N/A'}</dd>
            <dt>Date</dt>
            <dd>{equipmentInfo?.date || 'N/A'}</dd>
            <dt>Phone</dt>
            <dd>{equipmentInfo?.phone_no || 'N/A'}</dd>
            <dt>Starting odometer</dt>
            <dd>{equipmentInfo?.start_odometer || 'N/A'}</dd>
            <dt>Unit</dt>
            <dd>{equipmentInfo ? '' : 'N/A'}</dd>
          </dl>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="warning"
            text="Cancel"
            disabled={proceedAssign}
            onClick={onClose}
          />
          {unassignOption && (
            <Button
              type="button"
              text="Unassign"
              disabled={proceedAssign}
              onClick={handleClickUnassign}
            />
          )}
          <Button
            disabled={!selectedTechnicianId || proceedAssign}
            text={unassignOption ? 'Reassign' : 'Assign'}
            onClick={
              warningList.length ? handleToggleConfirmation : handleClickAssign
            }
          />
        </DialogFooter>
      </Dialog>

      {isConfirmVisible && (
        <DialogConfirm onCancel={onClose} onApprove={handleClickAssign}>
          <>
            <div className="mb-2">
              {`Are you sure you want to proceed with the selected order${
                warningList.length > 1 ? 's' : ''
              }?`}
              <br />
              The following patients have comments in their demographic:
              <br />
            </div>
            {warningList.map((item, i) => (
              <div key={`${i}`}>{item}</div>
            ))}
          </>
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(DialogAssignTechnician);
