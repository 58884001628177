import { observable, action, makeObservable } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

interface UnitResponseType {
  computer: string;
  'ppxray_device.device_name': string;
  'ppxray_ultrasoundgroup.refid': string;
  probe_id: string;
  probname: string;
  refid: string;
  ultraname: string;
}

interface UnitType {
  computer: string;
  deviceName: string;
  ultrasoundGroupId: number;
  probe_id: string;
  probname: string;
  refid: number;
  ultraname: string;
}

export interface UnitFormModel {
  refid: number | null;
  ultrasoundId: number;
  computerId: number;
  probeIds: { ProbeID: number }[];
}

interface UnitEntryType {
  ProbeArr: { ProbeID: '1' }[];
  finalArr: [
    {
      computer_id: string;
      probe_id: string;
      refid: string;
      ultrasound_id: string;
    }
  ];
}

class UltrasoundUnit {
  fetching: boolean = false;
  unitList: UnitType[] = [];
  unitTotal: number = 0;
  unit?: UnitFormModel = undefined;
  noteId: number = 0;
  idForDelete: number = 0;

  page: Pagination = new Pagination({ id: 'ultrasound_unit_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      unitList: observable,
      unitTotal: observable,
      unit: observable,
      noteId: observable,
      idForDelete: observable,

      setFetching: action,
      setUnitList: action,
      setUnitTotal: action,
      setUnit: action,
      setNoteId: action,
      setIdForDelete: action.bound,
      clearUnit: action.bound,
      clearNoteId: action.bound,
      clearIdForDelete: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setUnitList(list: UnitType[]) {
    this.unitList = list;
  }

  setUnitTotal(total: number) {
    this.unitTotal = total;
  }

  setUnit(unit?: UnitFormModel) {
    this.unit = unit;
  }

  setNoteId(id: number) {
    this.noteId = id;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  clearUnit() {
    this.unit = undefined;
  }

  clearNoteId() {
    this.noteId = 0;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  async getUnitList() {
    const { pagination } = this.page;

    const data = [pagination.skip, pagination.pageSize];
    try {
      const response = await apiRequest<'SE' | UnitResponseType[]>({
        url: 'generalmaster.UltraSoundGroup.UltraSoundGroupList',
        data,
      });

      if (Array.isArray(response)) {
        const list = response.map(
          ({
            'ppxray_device.device_name': deviceName,
            'ppxray_ultrasoundgroup.refid': id,
            ...item
          }) => ({
            ...item,
            deviceName,
            ultrasoundGroupId: Number(id),
            refid: Number(item.refid),
          })
        );

        this.setUnitList(list);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e: any) {
      this.setUnitList([]);
    }
  }

  async getUnitCount() {
    try {
      const count = await apiRequest<'SE' | 'S' | number>({
        url: 'generalmaster.UltraSoundGroup.TotalRecordCount',
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setUnitTotal(total);
    } catch (e) {
      this.setUnitTotal(0);
    }
  }

  getUnitMain = () => {
    this.setFetching(true);

    const promiseList = this.getUnitList();

    const promiseCount = this.getUnitCount();

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getUnit(id: number) {
    this.setFetching(true);

    try {
      const { finalArr, ProbeArr } = await apiRequest<UnitEntryType>({
        url: 'generalmaster.UltraSoundGroup.ViewUltraSoundGroup',
        data: [id],
      });

      const unit: UnitFormModel = {
        refid: id,
        computerId: Number(finalArr[0].computer_id),
        ultrasoundId: Number(finalArr[0].ultrasound_id),
        probeIds: ProbeArr.map(({ ProbeID }) => ({
          ProbeID: Number(ProbeID) || 0,
        })),
      };
      this.setUnit(unit);
    } catch (e) {
      this.setUnit();
    } finally {
      this.setFetching(false);
    }
  }

  async addUnit(payload: UnitFormModel) {
    const data = [
      payload.ultrasoundId,
      payload.computerId,
      payload.probeIds.filter(({ ProbeID }) => Boolean(ProbeID)),
    ];

    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'generalmaster.UltraSoundGroup.AddUltraSoundGroup',
        data,
      });
      if (result === 'S') {
        Notification.success('Ultrasound Unit was added successfully!');
        return null;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateUnit(payload: UnitFormModel) {
    const data = [
      payload.refid,
      payload.ultrasoundId,
      payload.computerId,
      payload.probeIds.filter(({ ProbeID }) => Boolean(ProbeID)),
    ];

    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'generalmaster.UltraSoundGroup.EditUltraSoundGroup',
        data,
      });

      if (result === 'S') {
        Notification.success('ULtrasound Unit was updated successfully!');
        return null;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async deleteUnit() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.UltraSoundGroup.DeleteUltraSoundGroup',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Ultrasound Unit was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(
        "This Ultrasound Unit is in use, so it can't be deleted!"
      );
      this.setFetching(false);
      return false;
    }
  }
}

export const storeUltrasoundUnit = new UltrasoundUnit();
