import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { DropdownUsaState } from 'components/project/dropdown/UsaStateDropdown';
import { Input } from 'components/form/textField';

import {
  storeZipCode,
  FilterType,
} from 'stores/_mobx/systemSetup/masterSetting/zipCode';

interface PropsType {
  onSubmit: (data: FilterType) => void;
}

const Filter = ({ onSubmit }: PropsType) => {
  const { control, watch, handleSubmit } = useForm<FilterType>({
    defaultValues: storeZipCode.filter,
  });

  useEffect(() => {
    const subscription = watch((formValue) => {
      onSubmit(formValue as Required<FilterType>);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row" onSubmit={handleSubmit(onSubmit)}>
      <DropdownUsaState
        name="stateId"
        label="State"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <Input
        name="locality"
        label="Locality"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <button type="submit" className="d-none" />
    </form>
  );
};

export default Filter;
