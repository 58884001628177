import React from 'react';

import Custom from 'components/form/input/Custom';
import LocationDropdown from './LocationDropdown';

import UserProfileStore from 'stores/UserProfileStore';

const availableUserTypes = ['D', 'L', 'RA', 'RC'];

interface PropsType {
  name: string;
  label?: string;
  value?: number;
  className?: string;
  noLabel?: boolean;
  attr?: any;
}

export default class LocationRolesDropdown extends React.Component<
  PropsType,
  any
> {
  static getLocationId() {
    if (LocationRolesDropdown.canSeeLocations()) {
      return 0;
    }
    return UserProfileStore.getLocationId();
  }

  static canSeeLocations() {
    return (
      availableUserTypes.includes(UserProfileStore.getUserType()) ||
      !UserProfileStore.isClientUser() ||
      UserProfileStore.getUser()
        .usertypename.toLowerCase()
        .indexOf('corporate') > -1
    );
  }

  getValue() {
    if (LocationRolesDropdown.canSeeLocations()) {
      return this.props.value;
    }
    return UserProfileStore.getLocationId();
  }

  render() {
    return LocationRolesDropdown.canSeeLocations() ? (
      <LocationDropdown {...this.props} />
    ) : (
      <Custom
        {...this.props}
        ref="input"
        custom={<span>{UserProfileStore.getLocationName()}</span>}
      />
    );
  }
}
