interface FileEntityType {
  description: string;
  docName: string;
  id?: number;
  lastModified: string;
  file?: File;
}

interface PropTypes {
  newList: FileEntityType[];
  defaultList: FileEntityType[];
}

interface ReturnedValueType {
  filesToDelete: Required<FileEntityType>[];
  filesToCreating: FileEntityType[];
  filesToUpdating: FileEntityType[];
}

/**
 * function to split array of files to 3 lists of files.
 * @param {FileEntityType[]} args.newList - The list of files after form submitting
 * @param {FileEntityType[]} args.defaultList - The income list of files from the server
 * @returns {ReturnedValueType} - 3 files collections, for deleting, updating, creating
 */
export const splitFileEntitiesToGroup = ({
  newList,
  defaultList,
}: PropTypes): ReturnedValueType => {
  const filesToDelete = defaultList.filter(({ id }) =>
    newList.every((file) => file.id !== id)
  ) as Required<FileEntityType>[];

  const { filesToCreating, filesToUpdating } = newList.reduce(
    (prev, document) => {
      if (document.file) {
        return {
          ...prev,
          filesToCreating: prev.filesToCreating.concat(document),
        };
      }
      const legacyFile = defaultList.find(({ id }) => id === document.id);

      const isDescriptionUpdated =
        legacyFile?.description !== document.description;

      return isDescriptionUpdated
        ? {
            ...prev,
            filesToUpdating: prev.filesToUpdating.concat(document),
          }
        : prev;
    },
    { filesToCreating: [], filesToUpdating: [] }
  );

  return {
    filesToDelete,
    filesToCreating,
    filesToUpdating,
  };
};
