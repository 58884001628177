import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Button } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import { LayoutSideTitle } from 'components/layout';
import Exporter from 'components/project/ExporterNew';
import Filter from './components/FilterHoursByEmployee';

import {
  storeHouseByEmployee,
  FilterType,
} from 'stores/_mobx/report/hoursByEmployee';

const columns = [
  { name: 'employee_name', title: 'Employee Name' },
  { name: 'employee_type', title: 'Employee Type' },
  {
    name: 'regular_time',
    title: 'Regular Time',
    maxWidth: 120,
  },
  {
    name: 'over_time',
    title: 'Over Time',
    maxWidth: 120,
  },
  {
    name: 'call_hours',
    title: 'Call Hours',
    maxWidth: 120,
  },
  {
    name: 'vacation',
    title: 'Vacation',
    maxWidth: 120,
  },
  {
    name: 'benefits',
    title: 'Benefits',
    maxWidth: 120,
  },
  {
    name: 'pto_paid',
    title: 'Non-Paid',
    maxWidth: 120,
  },
];

const HoursByEmployeeTab = () => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    logList,
    logTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeHouseByEmployee;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const [selected, setSelected] = useState<number[]>([]);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleApplyFilter = (filter: FilterType) => {
    storeHouseByEmployee.setFilter(filter);

    setPaginationToStart();
  };

  useEffect(() => {
    storeHouseByEmployee.getLogsMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle title="Hours by Employee">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        fetching={fetching}
        isVisible={isFilterVisible}
        onSubmit={handleApplyFilter}
      />

      <Grid
        id="hoursByEmployeeTypeGrid"
        selectId="refid"
        ref={gridRef}
        columns={columns}
        onAjax={fetching}
        dataSourceCount={logTotal}
        dataSource={logList}
        selectedIds={selected}
        pagination={pagination}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Export" onClick={handleToggleExport} />
        }
      />

      {isExportVisible && (
        <Exporter
          reportName="Hours_by_Employee"
          exporter="HoursByEmployeeTypeExporter"
          columns={columns}
          gridRef={gridRef}
          dataSource={logList}
          filter={storeHouseByEmployee.filter}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default observer(HoursByEmployeeTab);
