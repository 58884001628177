import CorporationOverviewPageService, {
  TFacilityItem,
  TCorporationItem,
  TCorporationAddress,
  TCorporationAddAddress,
  TDocs,
  TServiceBase,
} from 'services/system-setup/master-setup/corporations/CorporationOverviewPageService';
import { Actions } from 'stores/system-setup/corporations/CorporationOverviewPageStore';
import appDispatcher from 'dispatcher/AppDispatcher';

export default class CorporationOverviewPageActions {
  static loadCorporationItem(corpId: number) {
    return CorporationOverviewPageService.loadCorporationItem(corpId).then(
      (response: TCorporationItem) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_CORPORATION_ITEM_ACTION,
          data: response,
        });
        return response;
      }
    );
  }

  static saveCorporationItem(
    addressList: Array<TCorporationAddress>,
    additionalAddressList: Array<TCorporationAddAddress>,
    fileList: Array<TDocs>
  ) {
    return CorporationOverviewPageService.saveCorporationItem(
      addressList,
      additionalAddressList,
      fileList
    );
  }

  static updateCorporationItem(
    corpId: number,
    addressList: Array<TCorporationAddress>,
    additionalAddressList: Array<TCorporationAddAddress>,
    fileList: Array<TDocs>
  ) {
    return CorporationOverviewPageService.updateCorporationItem(
      corpId,
      addressList,
      additionalAddressList,
      fileList
    );
  }

  static loadFacilityMemberList(corpId: number) {
    return CorporationOverviewPageService.loadFacilityMemberList(corpId).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_FACILITY_MEMBERS_ACTION,
          data: response,
        });
      }
    );
  }

  static loadFacilityList() {
    return CorporationOverviewPageService.loadFacilityList().then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_FACILITY_LIST_ACTION,
          data: response,
        });
      }
    );
  }

  static loadFacilityListById(corpId: number) {
    return CorporationOverviewPageService.loadFacilityListById(corpId).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_FACILITY_LIST_BY_ID_ACTION,
          data: response,
        });
        return response;
      }
    );
  }

  static saveFacilityList(
    corpId: number,
    facilityList: Array<{ refid: number }>
  ): Promise<number> {
    const ids: Array<number> = [];
    facilityList = facilityList.filter((item) => {
      if (ids.indexOf(item.refid) === -1) {
        ids.push(item.refid);
        return true;
      }
      return false;
    });
    return CorporationOverviewPageService.saveFacilityList(
      corpId,
      facilityList
    );
  }

  static saveNewFacilityItem(facilityItem: TFacilityItem) {
    return CorporationOverviewPageService.saveNewFacilityItem(facilityItem);
  }

  static loadUserList() {
    return CorporationOverviewPageService.loadUserList().then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_USER_LIST_ACTION,
        data: response,
      });
    });
  }

  static loadSelectedUsers(corpId: number) {
    return CorporationOverviewPageService.loadSelectedUsers(corpId).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_SELECTED_USERS_LIST_ACTION,
          data: response,
        });
      }
    );
  }

  static clear() {
    appDispatcher.dispatch({ type: Actions.CLEAR_ACTION });
  }

  static saveSelectedUsers(corpId: number, userIdList: Array<number>) {
    return CorporationOverviewPageService.saveSelectedUsers(corpId, userIdList);
  }

  static loadServiceList(corpId: number) {
    return CorporationOverviewPageService.loadServiceList(corpId).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_SERVICE_LIST_ACTION,
          data: response,
        });
      }
    );
  }

  static saveServiceList(corpId: number, serviceList: Array<TServiceBase>) {
    return CorporationOverviewPageService.saveServiceList(corpId, serviceList);
  }

  static loadFeeListByCorporation(
    corpId: number,
    groupId: number,
    cptStatus: 'A' | 'R'
  ) {
    return CorporationOverviewPageService.loadFeeListByCorporation(
      corpId,
      groupId,
      cptStatus
    );
  }

  static loadFeeCountByCorporation(
    corpId: number,
    groupId: number,
    cptStatus: 'A' | 'R'
  ) {
    return CorporationOverviewPageService.loadFeeCountByCorporation(
      corpId,
      groupId,
      cptStatus
    );
  }

  static restoreInitialContactTab() {
    appDispatcher.dispatch({
      type: Actions.RESTORE_INITIAL_CONTACT_TAB_ACTION,
    });
  }

  static restoreInitialBillingTab() {
    appDispatcher.dispatch({
      type: Actions.RESTORE_INITIAL_BILLING_TAB_ACTION,
    });
  }
}
