export const URL_REPORT_HR = '/hr-reports';
export const URL_BRIEFCASE_HISTORY = `${URL_REPORT_HR}/briefcase-history`;
export const URL_EMPLOYEE_TIMESHEET = `${URL_REPORT_HR}/employee-timesheet`;
export const URL_USER_EXPENSE = `${URL_REPORT_HR}/user-expense-report`;
export const URL_EMPLOYEE_INFO = `${URL_REPORT_HR}/employee-information`;

export const URL_REPORT_CREATOR = '/report-creator';
export const URL_FACILITY_EXM_COUNT = `${URL_REPORT_CREATOR}/facility-exam-count`;
export const URL_STUDIES_BY_FACILITY = `${URL_REPORT_CREATOR}/studies-by-facility`;
export const URL_FACILITY_PATIENT = `${URL_REPORT_CREATOR}/facility-patient`;
export const URL_TECH_PATIENT = `${URL_REPORT_CREATOR}/tech-patient`;
export const URL_TECH_EXAM_COUNT_DATA = `${URL_REPORT_CREATOR}/tech-exam-count`;
export const URL_STUDIES_BY_TECH = `${URL_REPORT_CREATOR}/studies-by-tech`;
export const URL_ORDER_REPORT = `${URL_REPORT_CREATOR}/order-report`;
export const URL_PHYSICIAN_EXAM_COUNT = `${URL_REPORT_CREATOR}/physician-exam-count`;
export const URL_PROCEDURE_CODE_COUNT = `${URL_REPORT_CREATOR}/procedure-code-count`;
export const URL_RADIOLOGY_REPORT = `${URL_REPORT_CREATOR}/radiology-report`;

export const URL_REPORT_DATA = '/data-reports';
export const URL_PATIENT_SEEN = `${URL_REPORT_DATA}/patients-seen`;
export const URL_PROCEDURE_COUNT = `${URL_REPORT_DATA}/procedure-count`;
export const URL_TECH_EXAM_COUNT = `${URL_REPORT_DATA}/tech-exam-count`;
export const URL_TECH_PATIENT_DATA = `${URL_REPORT_DATA}/tech-patients`;
export const URL_EXAM_REPORT = `${URL_REPORT_DATA}/exam-report`;
export const URL_SUMMARY_LOCATION = `${URL_REPORT_DATA}/summary-location`;
export const URL_SUMMARY_TECHNOLOGIST = `${URL_REPORT_DATA}/summary-technologist`;
export const URL_TECH_REVIEW_EXAM = `${URL_REPORT_DATA}/tech-review-exams`;
export const URL_SUMMARY_FACILITY = `${URL_REPORT_DATA}/summary-facility`;
export const URL_MODALITY_SUMMARY = `${URL_REPORT_DATA}/modality-summary`;
export const URL_CORPORATE_SUMMARY = `${URL_REPORT_DATA}/corporate-summary`;
export const URL_UNBILLED_CLAIMS = `${URL_REPORT_DATA}/unbilled-claims`;

export const URL_REPORT_CUSTOM = '/custom-reports';
export const URL_AR_AGING_REPORT = `${URL_REPORT_CUSTOM}/ar-aging`;
export const URL_CUSTOM_INVOICE = `${URL_REPORT_CUSTOM}/invoice-report`;
export const URL_PROCEDURE_SUMMARY = `${URL_REPORT_CUSTOM}/procedure-summary`;
export const URL_PHYSICIAN_PATIENT = `${URL_REPORT_CUSTOM}/physician-patient`;

export const URL_EXAM = '/exam';
export const URL_ORDER_TRACK_HISTORY = `${URL_EXAM}/study-reports/order-track-history`;
