import { makeObservable, observable, action, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

export const defaultFilter: FilterType = {
  facility: 0,
  fax: '',
  firstName: '',
  lastName: '',
  mail: '',
  phone: '',
};

export interface FilterType {
  facility: number;
  fax: string;
  firstName: string;
  lastName: string;
  mail: string;
  phone: string;
}

interface FacilityContactType {
  address: string;
  city: string;
  contact_fname: string;
  contact_lname: string;
  email: string;
  facility: string;
  fax: string;
  phone: string;
  state: string;
  zipcode: string;
}

interface FacilityContactResponse {
  count: number;
  data: FacilityContactType[];
}

class ReportFacilityContact {
  fetching: boolean = false;
  facilityContact: FacilityContactType[] = [];
  facilityContactTotal: number = 0;
  filter: FilterType = defaultFilter;
  filterResetMarker: number = 0;

  page: Pagination = new Pagination({ id: 'facility_contact_report_gridId' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      facilityContact: observable,
      facilityContactTotal: observable,
      filter: observable,
      filterResetMarker: observable,

      setFetching: action,
      setFilter: action,
      setFilterDefault: action,
      clearResetMarker: action.bound,
    });
  }

  setFilter(payload: FilterType) {
    this.filter = payload;
  }

  setFilterDefault() {
    this.filterResetMarker = performance.now();
  }

  clearResetMarker() {
    this.filterResetMarker = 0;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  async getFacilityContact(filter: FilterType = this.filter) {
    const { pagination } = this.page;

    const payload = {
      skip: pagination.skip,
      pageSize: pagination.pageSize,
      lastName: filter.lastName,
      firstName: filter.firstName,
      facility: filter.facility,
      phone: filter.phone,
      mail: filter.mail,
      fax: filter.fax,
    };

    this.setFetching(true);

    try {
      const { count, data } = await apiRequest<FacilityContactResponse>({
        url: 'report.TechnicianExam.getfacilitycontactlist',
        data: payload,
      });

      runInAction(() => {
        this.facilityContact = data;
        this.facilityContactTotal = Number(count);
      });
    } catch (e) {
      runInAction(() => {
        this.facilityContact = [];
        this.facilityContactTotal = 0;
      });
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeReportFacilityContact = new ReportFacilityContact();
