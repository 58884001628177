import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

const filterDefaultValue: FilterModel = {
  name: '',
  status: 'A',
  category: '',
};

export interface FindingType {
  active_status: 'Active' | 'Inactive';
  category: string;
  category_name: string;
  comment: string;
  created_by: number;
  created_date: string;
  flag: 'A' | 'I';
  name: string;
  refid: number;
}

interface FindingResponseType {
  category: string;
  comment: string;
  created_by: string;
  created_date: string;
  name: string;
  refid: string;
}

export interface FormModel {
  id: number;
  name: string;
  category: string;
  comment: string;
}

export interface FilterModel {
  name: string;
  status: 'A' | 'I';
  category: string;
}

class Finding {
  fetching: boolean = false;
  findingList: FindingType[] = [];
  findingTotal: number = 0;
  finding?: FormModel;
  idForDelete: number | null = null;
  filter: FilterModel = filterDefaultValue;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'finding_grid' });

    makeObservable(this, {
      fetching: observable,
      findingList: observable,
      findingTotal: observable,
      finding: observable,
      filter: observable,
      idForDelete: observable,

      setFetching: action,
      setFindingList: action,
      setFindingTotal: action,
      setFinding: action,
      setFilter: action,
      setIdForDelete: action.bound,
      clearIdForDelete: action.bound,
      clearFinding: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFindingList(list: FindingType[]) {
    this.findingList = list;
  }

  setFindingTotal(count: number) {
    this.findingTotal = count;
  }

  setFinding(finding?: FormModel) {
    this.finding = finding;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setFilter(filter: FilterModel) {
    this.filter = filter;
  }

  clearIdForDelete() {
    this.idForDelete = null;
  }

  clearFinding() {
    this.finding = undefined;
  }

  async getFindingList(filter: FilterModel) {
    const { pagination } = this.page;

    const data = [
      filter.name,
      filter.category,
      filter.status,
      pagination.skip,
      pagination.pageSize,
    ];

    try {
      const response = await apiRequest<FindingType[]>({
        url: 'generalmaster.Finding.GetFindingAllValueList',
        data,
      });

      const list = response.map((item) => ({
        ...item,
        created_by: Number(item.created_by),
        refid: Number(item.refid),
      }));

      this.setFindingList(list);
    } catch (e) {
      this.setFindingList([]);
    }
  }

  async getFindingCount(filter: FilterModel) {
    try {
      const count = await apiRequest<number>({
        url: 'generalmaster.Finding.GetFindingAllValueCount',
        data: [filter.name, filter.category, filter.status],
      });

      this.setFindingTotal(Number(count) || 0);
    } catch (e) {
      this.setFindingTotal(0);
    }
  }

  getFindingMain = (filter: FilterModel = this.filter) => {
    this.setFetching(true);

    const promiseList = this.getFindingList(filter);

    const promiseCount = this.getFindingCount(filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getFinding(id: number) {
    this.setFetching(true);

    try {
      const [response] = await apiRequest<[FindingResponseType]>({
        url: 'generalmaster.Finding.GetFindingValueByRefid',
        data: [id],
      });

      const finding = {
        id,
        name: response.name,
        category: response.category,
        comment: response.comment,
      };

      this.setFinding(finding);
    } catch (e) {
      this.setFinding();
    } finally {
      this.setFetching(false);
    }
  }

  async addFinding(payload: FormModel) {
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.Finding.AddFinding',
        data: [payload.name, payload.category, payload.comment],
      });
      if (result === 'S') {
        Notification.success('Finding was added successfully!');
        return null;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateFinding(payload: FormModel) {
    const data = [payload.id, payload.name, payload.category, payload.comment];

    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'generalmaster.Finding.EditFinding',
        data,
      });

      if (result === 'S') {
        Notification.success('Finding was updated successfully!');
        return null;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  changeFindingStatus = async (ids: number[]) => {
    this.setFetching(true);
    try {
      const response = await Promise.allSettled(
        ids.map((id) =>
          apiRequest<'S' | 'SE'>({
            url: 'generalmaster.Finding.UpdateActiveInactiveFinding',
            data: [id, this.filter.status === 'A' ? 'Inactive' : 'Active'],
          })
        )
      );

      const count = response.reduce(
        // @ts-ignore
        (counter, { value }) => (value === 'S' ? ++counter : counter),
        0
      );
      if (count) {
        const statusMsg =
          this.filter.status === 'A' ? 'deactivated' : 'activated';

        Notification.success(
          `${count} Finding${count > 1 ? 's were' : 'was'} ${statusMsg}`
        );
        return true;
      }
      throw Error('');
    } catch (e) {
      Notification.warning('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  };

  async deleteFinding() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.Finding.DeleteFinding',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Finding was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("This Finding can't be deleted!");
      this.setFetching(false);
      return false;
    }
  }
}

export const storeFinding = new Finding();
