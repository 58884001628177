import { useEffect, forwardRef } from 'react';
import { observer } from 'mobx-react-lite';

import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';

import { storeDropdownFloor, OptionType } from 'stores/_mobx/dropDown/floor';

interface WrapperPropsType extends PAbstractDropdown {
  facilityId: number;
}

interface PropsType extends PAbstractDropdown {
  options: OptionType[];
  ref: any;
}

class FloorDropdown extends AbstractDropdown<PropsType, SAbstractDropdown> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<FloorDropdown, any>(
  ({ facilityId = 0, ...props }: WrapperPropsType, ref) => {
    const { groupOptions } = storeDropdownFloor;

    const options = groupOptions[facilityId]?.options || [];

    const fetching = groupOptions[facilityId]?.fetching || false;

    useEffect(() => {
      storeDropdownFloor.getOptions(facilityId);
    }, [facilityId]);

    return (
      <FloorDropdown
        {...props}
        ref={ref}
        options={options}
        fetching={fetching}
      />
    );
  }
);

export default observer(DropDownMiddleware);
