import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import RadioOptions from 'components/project/common/RadioOptions';
import TechnologistDropdown from 'components/project/dropdown/TechnologistDropDown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { DropDownExamType } from 'components/project/dropdown/ExamTypeDropdown';
import { DropdownProviderType } from 'components/project/dropdown/ProviderTypeDropdown';
import { DropDownPriority } from 'components/project/dropdown/PriorityDropdown';
import { DropDownCorporation } from 'components/project/dropdown/CorporateDropdown';
import { DropDownOrderType } from 'components/project/dropdown/OrderType';
import { DropdownRadiology } from 'components/project/dropdown/RadiologyDropdown';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import { DateRange } from 'components/form/dateRange';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import { storeExamQA, FilterType } from 'stores/_mobx/patientRecord/examQA';
import { storePayerPlan } from 'stores/_mobx/systemSetup/masterSetting/payerPlan';

const commonClassName = 'col-sm-6 col-md-3';

interface PropsType {
  fetching: boolean;
  initValues: FilterType;
  isFilterVisible: boolean;
  isCorporateUser: boolean;
  onSearch: (data: FilterType) => void;
}

const FilterQA = ({
  fetching,
  isFilterVisible,
  isCorporateUser,
  initValues,
  onSearch,
}: PropsType) => {
  const { control, watch, setValue, handleSubmit, reset } = useForm<FilterType>(
    {
      defaultValues: initValues,
    }
  );

  const handleClickReset = () => {
    const defaultValues = storeExamQA.getDefaultFilter();

    reset(defaultValues);

    onSearch(defaultValues as Required<FilterType>);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'facility.corporateGroupMembers.group.id') {
        const isGroupSelected = Boolean(
          formValue.facility.corporateGroupMembers.group.id
        );

        setValue(
          'facility.corporateGroupMembers.flag',
          isGroupSelected ? 'A' : ''
        );
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    storePayerPlan.getOptions();
  }, []);

  return (
    <form
      className={isFilterVisible ? '' : ' d-none'}
      onSubmit={handleSubmit(onSearch)}>
      <div className="row">
        <Input
          name="patient.lastName"
          label="Last Name"
          className={commonClassName}
          control={control}
        />
        <Input
          name="patient.firstName"
          label="First Name"
          className={commonClassName}
          control={control}
        />
        <DropDownFacility
          name="facility.id"
          label="Facility"
          className={commonClassName}
          control={control}
        />
        <DropDownState
          name="facility.dispatchRegion.state.id"
          label="State"
          className={commonClassName}
          control={control}
        />
        <div />

        <DropDownExamType
          name="modality.id"
          label="Modality"
          className={commonClassName}
          control={control}
        />
        <DropdownProviderType
          name="facility.facilityType.id"
          label="Provider Type"
          className={commonClassName}
          control={control}
        />
        <Input
          name="claimCodes.procedureCode"
          label="CPT"
          className={commonClassName}
          control={control}
        />
        <DropDownPlaceOfService
          name="facility.placeOfService.id"
          label="POS"
          className={commonClassName}
          control={control}
        />
        <DropDownCorporation
          name="facility.corporateGroupMembers.group.id"
          label="Corporation"
          isClearable={!isCorporateUser}
          className={commonClassName}
          control={control}
        />
        <TechnologistDropdown
          name="orderFlow.technologist.id"
          label="Technologist"
          className={commonClassName}
          control={control}
        />
        <DropdownRadiology
          name="radiologyGroup.id"
          label="Radiology Group"
          className={commonClassName}
          control={control}
        />
        <DropDownPriority
          name="priority.id"
          label="Priority"
          className={commonClassName}
          control={control}
        />
        <DropDownOrderType
          name="details.orderBased"
          label="Order Type"
          className={commonClassName}
          control={control}
        />
        <Radio
          name="finding"
          label="Finding"
          options={RadioOptions.yesNoAll}
          className={`${commonClassName} part-inline`}
          control={control}
        />
        <div />
        <DateRange
          nameFrom="dosStart"
          nameTo="dosEnd"
          nameRadio="period"
          radioClassName="col-sm-12 col-md-auto"
          control={control}
        />
        <ControlsLayout alignX="auto">
          <Button
            variant="warning"
            text="Reset"
            disabled={fetching}
            onClick={handleClickReset}
          />
          <Button type="submit" text="Apply" disabled={fetching} />
        </ControlsLayout>
      </div>
    </form>
  );
};

export default FilterQA;
