import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { subYears } from 'date-fns';

import DropdownFacilityRoleActive from 'components/project/dropdown/FacilityRolesActive';
import RadioOptions from 'components/project/common/RadioOptions';
import FormControl from 'components/form/FormControl';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import { InputCalendar } from 'components/form/inputCalendar';
import MaskInput from 'components/form/maskInput';
import { Button } from 'components/button';
import Dialog, { DialogBody } from 'components/modal/dialog';
import resolver from './validation';

import UserProfileStore from 'stores/UserProfileStore';
import { PatientFormBasicData } from 'stores/_mobx/patientRecord/patientDemographic';
import { PatientInfoType } from 'stores/_mobx/workflow/order/patient';

export { type PatientInfoType };

interface PropsType {
  onClose: () => void;
  isCorrectional: boolean;
  fetchingCorrectionalStatus: boolean;
  defaultValues: PatientFormBasicData;
  onSubmit: (patientFormValues: PatientFormBasicData) => Promise<void>;
}

const DialogCreatePatient = ({
  onClose,
  onSubmit,
  isCorrectional,
  fetchingCorrectionalStatus,
  defaultValues,
}: PropsType) => {

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<PatientFormBasicData>({
    defaultValues,
    resolver,
  });

  const dobLimits = useMemo(
    () => ({
      min: subYears(new Date(), 150),
      max: new Date(),
    }),
    []
  );

  const isLoading = isSubmitting || fetchingCorrectionalStatus;

  return (
    <>
      <Dialog size="large" scrollable={false} handleClose={onClose}>
        <DialogBody className={isLoading ? 'on-ajax' : undefined}>
          <form className="row" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-lg-6">
              <Input
                name="last_name"
                label="Last Name"
                required
                control={control}
              />
              <Input
                name="first_name"
                label="First Name"
                required
                control={control}
              />
              <Input
                name="last_nickname"
                label="Last Alias Name"
                control={control}
              />
              <InputCalendar
                name="birthdate"
                label="Date of Birth"
                minDate={dobLimits.min}
                maxDate={dobLimits.max}
                required
                control={control}
              />
              {!UserProfileStore.isClientUser() && (
                <Input name="clientMRN" label="Other MRN" control={control} />
              )}
              {isCorrectional && (
                <>
                  <Input
                    name="inmate_number"
                    label="OID Number"
                    control={control}
                  />
                  <Input
                    name="ice_number"
                    label="ICE Number"
                    control={control}
                  />
                </>
              )}
            </div>
            <div className="col-lg-6">
              <Input name="middle_name" label="Middle Name" control={control} />
              <Radio
                name="gender"
                label="Gender"
                className="part-inline"
                required
                options={RadioOptions.gender}
                control={control}
              />
              <Input
                name="first_nickname"
                label="First Alias Name"
                control={control}
              />
              <MaskInput
                format="###-##-####"
                name="ssn"
                label="SSN"
                control={control}
              />
              {isCorrectional && (
                <Input
                  name="immigration_id"
                  label="Marshall ID"
                  control={control}
                />
              )}
              <DropdownFacilityRoleActive
                name="facilityid"
                label="Facility"
                required
                control={control}
              />
            </div>
            <FormControl>
              <Button
                variant="danger"
                text="Close"
                data-testid="Close"
                onClick={onClose}
              />
              <Button
                type="submit"
                data-testid="Add"
                text="Add"
              />
            </FormControl>
          </form>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default DialogCreatePatient;
