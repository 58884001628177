import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import FormExamType, { FormModel } from './components/Form';

import { storeExamType } from 'stores/_mobx/systemSetup/masterSetting/examType';
import { URL_EXAM_TYPE } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const ExamTypeEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, examType } = storeExamType;

  useEffect(() => {
    storeExamType.getExamType(params.id);
    return storeExamType.clearExamType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (data: FormModel) =>
    storeExamType.updateExamType(data as Required<FormModel>).then((result) => {
      if (result) {
        history.push(URL_EXAM_TYPE);
      }
    });

  return (
    <>
      <Title title="Edit Modality" />
      <FormExamType
        defaultValues={examType}
        backUrl={URL_EXAM_TYPE}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(ExamTypeEditPage);
