import { useCallback } from 'react';
import { Control, useController } from 'react-hook-form';
import { startOfDay, endOfDay } from 'date-fns';

import RadioOptions from 'components/project/common/RadioOptions';
import { PureRadio } from 'components/form/radio';
import { PureInputCalendar } from 'components/form/inputCalendar';

import { calcDateRange } from 'utils/calcDateRange';

const calendarClassName = 'col-sm-6 col-md-3 col-xl-2';

interface PropsType {
  nameFrom: string;
  nameTo: string;
  nameRadio: string;
  labelFrom?: string;
  labelTo?: string;
  labelRadio?: string;
  radioClassName?: string;
  isOptionsExpanded?: boolean;
  isUTC?: boolean;
  disabled?: boolean;
  control: Control<any>;
}

const DateRange = ({
  nameFrom,
  nameTo,
  nameRadio,
  labelFrom = 'DOS From',
  labelTo = 'DOS To',
  labelRadio = 'Date Of Service',
  radioClassName = 'col-sm-12 col-md-6 col-lg-3',
  isOptionsExpanded,
  isUTC,
  disabled,
  control,
}: PropsType) => {
  const fieldFrom = useController({ name: nameFrom, control });
  const fieldTo = useController({ name: nameTo, control });
  const fieldRadio = useController({ name: nameRadio, control });

  const clearPeriodField = useCallback(() => {
    if (fieldRadio.field.value) fieldRadio.field.onChange('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRadio.field.value]);

  const handleChangeDateFrom = useCallback(
    (value: string) => {
      const date =
        value && isUTC ? startOfDay(new Date(value)).toISOString() : value;
      fieldFrom.field.onChange(date);
      clearPeriodField();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearPeriodField]
  );

  const handleChangeDateTo = useCallback(
    (value: string) => {
      const date =
        value && isUTC ? endOfDay(new Date(value)).toISOString() : value;
      fieldTo.field.onChange(date);
      clearPeriodField();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearPeriodField]
  );

  const handleChangeDatePeriod = useCallback((value: string) => {
    const { dosStart, dosEnd } = calcDateRange(value, isUTC);

    fieldFrom.field.onChange(dosStart);
    fieldTo.field.onChange(dosEnd);
    fieldRadio.field.onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PureInputCalendar
        selectsStart
        name={nameFrom}
        label={labelFrom}
        className={calendarClassName}
        {...fieldFrom.field}
        disabled={disabled}
        startDate={fieldFrom.field.value}
        endDate={fieldTo.field.value}
        onChange={handleChangeDateFrom}
      />
      <PureInputCalendar
        selectsEnd
        name={nameTo}
        label={labelTo}
        className={calendarClassName}
        {...fieldTo.field}
        disabled={disabled}
        startDate={fieldFrom.field.value}
        endDate={fieldTo.field.value}
        minDate={fieldFrom.field.value}
        onChange={handleChangeDateTo}
      />
      <PureRadio
        name={nameRadio}
        label={labelRadio}
        className={`part-inline ${radioClassName}`}
        disabled={disabled}
        options={
          isOptionsExpanded
            ? RadioOptions.filterPeriodExtended
            : RadioOptions.filterPeriod
        }
        {...fieldRadio.field}
        onChange={handleChangeDatePeriod}
      />
    </>
  );
};

export default DateRange;
