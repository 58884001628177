import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import Title from 'components/project/common/title';
import FormTransport, { FormModel } from './components/Form';

import { storeTransport } from 'stores/_mobx/systemSetup/masterSetting/transport';
import { URL_TRANSPORT } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}
interface PropsType extends RouteComponentProps<MatchParams> {}

const TransportEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, transportCode } = storeTransport;

  useEffect(() => {
    storeTransport.getTransportCode(params.id);
    return storeTransport.clearTransportCode;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (payload: FormModel) =>
    // @ts-ignore
    storeTransport.updateTransportCode(payload).then((response) => {
      if (response) {
        return response;
      }
      history.push(URL_TRANSPORT);
    });

  return (
    <>
      <Title title="Edit Transport" />

      {transportCode?.refid === Number(params.id) ? (
        <FormTransport
          backUrl={URL_TRANSPORT}
          defaultValues={transportCode}
          onSubmit={handleSubmit}
        />
      ) : null}

      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(TransportEditPage);
