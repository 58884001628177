import OrderFormPageService, {
  OrderScheduleServiceDateType,
  TPatientOrder,
  TCorrectional,
  TExamInfoOrderData,
  TExamInfoOrderDataExt,
  TCpt,
  TFinalCpt,
  TFaxPhone,
  TIcd,
  TPhysicianInfo,
  TFacilityFloor,
  TPhysicianDetail,
  TCptCode,
  TCptCodeExt,
  TOrderFaxPhoneItem,
  TPatient,
  TAddExamInfoOrderRes,
  TAddPatientOrderRes,
  TFacilityAddress,
  TFacilityAddressExt,
  TExamDetail,
  TEdIntCpt,
  TDirectNumber,
  TOrderCallerDetails,
  TDirectFaxPhoneNumber,
  TVisitComments,
  TCptIcd,
  TModifyOrderRes,
} from 'services/workflow/order/OrderFormPageService';
import { TPatientRecord } from 'services/patient-records/patient-demographics/PatientRecordPageService';
import appDispatcher from 'dispatcher/AppDispatcher';
import { Actions } from 'stores/workflow/order/OrderFormPageStore';

export default class OrderFormPageActions {
  static updateScheduleServiceDate(dos: OrderScheduleServiceDateType) {
    return OrderFormPageService.updateScheduleServiceDate(dos);
  }

  static loadPhysicianList(payload: {
    firstName: string;
    lastName: string;
    npi: string;
  }) {
    return OrderFormPageService.loadPhysicianList(payload).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_PHYSICIAN_LIST_ACTION,
        data: response,
      });
      return response;
    });
  }

  static clearPatientInfo() {
    appDispatcher.dispatch({
      type: Actions.CLEAR_PATIENT_INFO,
    });
  }

  static clearOrderInfo() {
    appDispatcher.dispatch({
      type: Actions.CLEAR_ORDER_INFO,
    });
  }

  static clearForSameFacility() {
    appDispatcher.dispatch({
      type: 'order-form-clear-for-facility',
    });
  }

  static clearForSamePatient() {
    appDispatcher.dispatch({
      type: 'order-form-clear-for-patient',
    });
  }

  static loadFacilityWithoutInactiveList(id: number) {
    return OrderFormPageService.loadFacilityWithoutInactiveList(id).then(
      (response) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_FACILITY_WITHOUT_INACTIVE_LIST,
          data: response,
        });
        return response;
      }
    );
  }

  static loadDirectNumberList(facilityId: number) {
    return OrderFormPageService.loadDirectNumberList(facilityId).then(
      (response: 0 | Array<TDirectNumber>) => {
        response = response === 0 ? [] : response;
        appDispatcher.dispatch({
          type: Actions.LOAD_DIRECT_NUMBER_LIST,
          data: response,
        });
        return response;
      }
    );
  }

  static addPatientOrder(
    item: TPatientOrder,
    correctional: TCorrectional
  ): Promise<'SE' | Array<TAddPatientOrderRes>> {
    return OrderFormPageService.addPatientOrder(item, correctional);
  }

  static loadPatientItemByOrderId(orderId: number) {
    return OrderFormPageService.loadPatientItemByOrderId(orderId).then(
      (response: 'SE' | 0 | Array<TPatient>) => {
        const res = [new TPatient()];
        if (response && response !== 'SE' && response[0]) {
          res[0] = Object.assign(res[0], response[0]);
        }
        appDispatcher.dispatch({
          type: Actions.LOAD_PATIENT_ITEM_ACTION,
          data: res,
        });
        return res;
      }
    );
  }

  static loadPatientViewInfo(patientId: number) {
    return OrderFormPageService.loadPatientViewInfo(patientId).then(
      (response: 'SE' | 0 | Array<TPatientRecord>) => {
        if (response !== 'SE' && response !== 0) {
          appDispatcher.dispatch({
            type: Actions.LOAD_PATIENT_VIEW_INFO_ACTION,
            data: response,
          });
        }
        return response;
      }
    );
  }

  static loadLastAccessionNumber() {
    return OrderFormPageService.loadLastAccessionNumber().then(
      (response: string) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_LAST_ACCESSION_NUMBER_ACTION,
          data: response,
        });
        return response;
      }
    );
  }

  static loadFloorList(facilityId: number) {
    return OrderFormPageService.loadFloorList(facilityId).then(
      (response: Array<{ data: string; label: string }>) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_FLOOR_LIST,
          data: response,
        });
        return response;
      }
    );
  }

  static loadFacilityAddress(facilityId: number) {
    return OrderFormPageService.loadFacilityAddress(facilityId).then(
      (response: TFacilityAddress) => {
        const res: TFacilityAddressExt = Object.assign(
          new TFacilityAddressExt(),
          response
        );
        res.facilityId = facilityId;
        appDispatcher.dispatch({
          type: Actions.LOAD_FACILITY_ADDRESS,
          data: res,
        });
        return res;
      }
    );
  }

  static clearFacilityAddress() {
    appDispatcher.dispatch({
      type: Actions.LOAD_FACILITY_ADDRESS,
      data: [],
    });
  }

  static GetExamDetailByOrderId(orderId: number) {
    return OrderFormPageService.GetExamDetailByOrderId(orderId).then(
      (response: 'SE' | 0 | Array<TExamDetail>) => {
        if (response === 'SE' || response === 0 || response.length <= 0) {
          response = [new TExamDetail()];
        }
        if (response[0]) {
          if (!response[0].refid) {
            response[0].refid = orderId;
          }
          const initialCpt: Array<TEdIntCpt> = response[0].GetIntCptArr;
          OrderFormPageActions.fixCptExtremity(initialCpt);
        }
        appDispatcher.dispatch({
          type: Actions.LOAD_EXAM_ITEM_ACTION,
          data: response,
          status: true,
        } as any);
      }
    );
  }

  static fixCptExtremity(cpt: Array<TEdIntCpt>) {
    const fixed = cpt || [];
    for (let i = 0; i < fixed.length; i++) {
      const item = fixed[i];
      if (!item.extremity && item.cpt_desc) {
        const start = item.cpt_desc.indexOf('[');
        const end = item.cpt_desc.indexOf(']');
        if (start > -1 && end > -1 && start < end) {
          item.extremity = item.cpt_desc.substring(start + 1, end);
          //item.cpt_description = item.cpt_desc;//cpt_desc contain extremity, cpt_description does not
        }
      }
    }
  }

  static clearExamDetails() {
    appDispatcher.dispatch({
      type: Actions.LOAD_EXAM_ITEM_ACTION,
      data: [new TExamDetail()],
      status: false,
    } as any);
  }

  static GetCPTCodeTotalRecordCountByModality(
    filter: { search: string; searchDesc: string },
    modality: number
  ) {
    return OrderFormPageService.GetCPTCodeTotalRecordCountByModality(
      filter.search,
      filter.searchDesc,
      'A',
      modality
    ).then((response: 'SE' | number) => {
      response = response === 'SE' ? 0 : response;
      appDispatcher.dispatch({
        type: Actions.LOAD_CPT_CODE_COUNT,
        data: response,
      });
    });
  }

  static GetCPTCodeListValueByModality(
    filter: { search: string; searchDesc: string },
    modality: number,
    index: number,
    limit: number
  ) {
    return OrderFormPageService.GetCPTCodeListValueByModality(
      filter.search,
      filter.searchDesc,
      'A',
      modality,
      index,
      limit
    ).then((response: 'SE' | Array<TCptCode>) => {
      response = response === 'SE' ? [] : response;
      appDispatcher.dispatch({
        type: Actions.LOAD_CPT_CODE,
        data: response,
      });
      return response;
    });
  }

  static getGeneratedAccessionNumbersList(size: number) {
    return OrderFormPageService.getGeneratedAccessionNumbersList(size);
  }

  static GetCPTCodeListValueByOrder(
    filter: { search: string; searchDesc: string },
    modality: number,
    index: number,
    limit: number,
    orderId: number
  ) {
    return OrderFormPageService.GetCPTCodeListValueByOrder(
      filter.search,
      filter.searchDesc,
      'A',
      modality,
      index,
      limit,
      orderId
    ).then((response: Array<TCptCodeExt>) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_CPT_CODE,
        data: response,
      });
      return response;
    });
  }

  static loadPhysicianDetailsByOrderId(
    orderId: number
  ): Promise<TPhysicianDetail> {
    return OrderFormPageService.loadPhysicianDetailsByOrderId(orderId).then(
      (response: TPhysicianDetail) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_PHYSICIAN_DETAILS,
          data: response,
        });
        return response;
      }
    );
  }

  static getOrderTypeByFacility(facilityId: number) {
    return OrderFormPageService.getOrderTypeByFacility(facilityId).then(
      (response: Array<{ data: string; label: string }>) => {
        const modifiedResponse: Array<{ label: string; value: string }> =
          response
            .filter((item: { label: string; data: string }) => {
              return item.label && !item.label.toLowerCase().includes('select');
            })
            .map((item: { label: string; data: string }) => {
              return { label: item.label, value: item.data };
            });
        appDispatcher.dispatch({
          type: 'order.Order.GetOrderTypeByFacility',
          data: modifiedResponse,
        });
        return modifiedResponse;
      }
    );
  }

  static clearOrderTypeByFacility() {
    appDispatcher.dispatch({
      type: 'order.Order.GetOrderTypeByFacility',
      data: [],
    });
  }

  static updatePhysicianInfo(
    physInfo: TPhysicianInfo,
    autoSave: boolean
  ): Promise<TModifyOrderRes> {
    return OrderFormPageService.updatePhysicianInfo(physInfo, autoSave).then(
      (response: 'SE' | TModifyOrderRes) => {
        response = response === 'SE' ? new TModifyOrderRes() : response;
        return response;
      }
    );
  }

  static getFaxPhone(orderId: number) {
    return OrderFormPageService.getFaxPhone(orderId).then(
      (response: 'SE' | Array<TOrderFaxPhoneItem>) => {
        const res: Array<TOrderFaxPhoneItem> =
          response === 'SE' ? [] : response;
        appDispatcher.dispatch({
          type: Actions.LOAD_FAX_PHONE_INFORMATION,
          data: res,
        });
        return res;
      }
    );
  }

  static loadOrderCallerDetailsByOrderId(orderId: number) {
    return OrderFormPageService.loadOrderCallerDetailsByOrderId(orderId).then(
      (response: 'SE' | 0 | Array<TOrderCallerDetails>) => {
        response = response === 'SE' || response === 0 ? [] : response;
        appDispatcher.dispatch({
          type: Actions.LOAD_ORDER_DETAIL_ACTION,
          data: response,
        });
        return response[0];
      }
    );
  }

  static loadOrderDirectNumberList(orderId: number) {
    return OrderFormPageService.loadOrderDirectNumberList(orderId).then(
      (response: 'SE' | 0 | TDirectFaxPhoneNumber) => {
        response =
          response === 'SE' || response === 0
            ? new TDirectFaxPhoneNumber()
            : response;
        appDispatcher.dispatch({
          type: Actions.LOAD_ORDER_DIRECT_NUMBER_LIST,
          data: response,
        });
        return response;
      }
    );
  }

  static addExamInfoOrder(
    mode: 'CD' | 'E' | 'A',
    orderData: TExamInfoOrderData,
    cptArr: Array<TCpt>,
    icdArr: Array<TIcd>,
    finalCptArr: Array<TFinalCpt>,
    faxPhoneArr: Array<TFaxPhone>,
    saveDuplicate: 'SD' | '',
    autoSave: 'N' | 'PR'
  ) {
    return OrderFormPageService.addExamInfoOrder(
      mode,
      orderData,
      cptArr,
      icdArr,
      finalCptArr,
      faxPhoneArr,
      saveDuplicate,
      autoSave
    ).then((response: 'S' | 'E' | TAddExamInfoOrderRes) => {
      const orderDataExamInfo: TExamInfoOrderDataExt = Object.assign(
        new TExamInfoOrderDataExt(),
        orderData
      );
      orderDataExamInfo.faxPhoneArr = faxPhoneArr;
      appDispatcher.dispatch({
        type: Actions.UPDATE_EXAM_INFO_ORDER,
        orderDataExamInfo: orderDataExamInfo,
      } as any);
      return response;
    });
  }

  static deleteOrder(orderId: number) {
    return OrderFormPageService.deleteOrder(orderId);
  }

  static loadVisitComments(orderId: number) {
    return OrderFormPageService.loadVisitComments(orderId).then(
      (response: 'SE' | Array<TVisitComments>) => {
        response = response === 'SE' ? [] : response;
        appDispatcher.dispatch({
          type: 'medicalrecord.OrderResult.GetResult',
          data: response,
        });
        return response;
      }
    );
  }

  static clearVisitComments() {
    appDispatcher.dispatch({
      type: 'medicalrecord.OrderResult.GetResult',
      data: [],
    });
  }

  static saveVisitComment(orderId: number, userId: number, comment: string) {
    return OrderFormPageService.saveVisitComment(orderId, userId, comment);
  }

  static getCptIcdByPatient(patientId: number) {
    return OrderFormPageService.getCptIcdByPatient(patientId).then(
      (response: 0 | Array<TCptIcd>) => {
        response = response === 0 ? [] : response;
        appDispatcher.dispatch({
          type: 'order.Order.GetCptIcdByPatient',
          data: response,
        });
        return response;
      }
    );
  }

  static clearCptIcdByPatient() {
    appDispatcher.dispatch({
      type: 'order.Order.GetCptIcdByPatient',
      data: [],
    });
  }

  static loadFaxNumberByFacilityId(facilityId: number) {
    return OrderFormPageService.loadFaxNumberByFacilityId(facilityId);
  }

  static getCorrectional(
    orderId: number
  ): Promise<'SE' | 0 | Array<TCorrectional>> {
    return OrderFormPageService.getCorrectional(orderId);
  }

  static onExistingStation(floorId: number): Promise<Array<TFacilityFloor>> {
    return OrderFormPageService.onExistingStation(floorId);
  }

  static changeFacility(
    patientId: number,
    facilityId: number,
    oldFacility: number,
    orderId: number
  ) {
    return OrderFormPageService.changeFacility(
      patientId,
      facilityId,
      oldFacility,
      orderId
    );
  }
}
