import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { Textarea } from 'components/form/textarea';
import { Button } from 'components/button';
import resolver from './validation';

export interface FormModel {
  computer: string;
  mac_id: string;
  serial_no: string;
  model: string;
  mark: string;
  description: string;
}

const initialValues: FormModel = {
  computer: '',
  mac_id: '',
  serial_no: '',
  model: '',
  mark: '',
  description: '',
};

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormModifyComputer = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: FormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('mac_id', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={isSubmitting ? 'on-ajax' : ''}
      onSubmit={handleClickSubmit}>
      <Input name="computer" label="Computer name" required control={control} />
      <Input name="mac_id" label="Mac ID" control={control} />
      <Input name="serial_no" label="Serial No." control={control} />
      <Input name="model" label="Model" control={control} />
      <Input name="mark" label="Mark" control={control} />
      <Textarea name="description" label="Description" control={control} />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          type="reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormModifyComputer;
