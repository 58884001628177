const Info = () => (
  <div style={{ fontSize: '1.6rem' }}>
    Selecting single Radiology Type enables auto-assignment feature on the
    Billing assignment section:
    <br />
    <br />
    Insurance → Professional Group Billing
    <br />
    Fee for Service → FFS
    <br />
    Purchased Service → FFS and Claim Manager
  </div>
);

export default Info;
