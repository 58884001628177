import { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import Tabs from 'components/tabs';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import FacilityPreInvoices from './FacilityPreInvoices';
import PerDiemPreInvoices from './PerDiemPreInvoices';
import OccHealthPreInvoice from './OccHlthPreInvoice';

import { PRE_INVOICE as PAGE_ID } from 'constant/pagesId/billingCodding';
import { URL_PRE_INVOICE } from 'constant/path/billingCoding';
import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';

const tabsList = [
  {
    label: 'Facility Pre-Invoice',
    url: `${URL_PRE_INVOICE}/facility`,
  },
  {
    label: 'Per Diem Pre-Invoice',
    url: `${URL_PRE_INVOICE}/per-diem`,
  },
  { label: 'Occ. Hlth. Pre-Invoice', url: `${URL_PRE_INVOICE}/occ` },
];

const PreInvoices = ({ isDivisionEnabled }: PropsTypeWitDivisionSettings) => {
  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isGenerateInvoiceFormVisible, toggleInvoiceGeneration] =
    useState<boolean>(false);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  return (
    <>
      {isGenerateInvoiceFormVisible ? null : (
        <>
          <LayoutSideTitle appId={PAGE_ID.PAGE} title="Pre Invoices">
            <Button
              variant="default"
              text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
              data-testid="toggleFilter"
              onClick={handleToggleFilter}
            />
          </LayoutSideTitle>

          <Tabs tabsList={tabsList} />
        </>
      )}

      <Switch>
        <Route
          exact
          path={tabsList[0].url}
          render={(props) => (
            <FacilityPreInvoices
              {...props}
              isDivisionEnabled={isDivisionEnabled}
              title={tabsList[0].label}
              isFilterVisible={isFilterVisible}
              handleToggleInvoiceGeneration={toggleInvoiceGeneration}
            />
          )}
        />
        <Route
          exact
          path={tabsList[1].url}
          render={(props) => (
            <PerDiemPreInvoices
              {...props}
              isDivisionEnabled={isDivisionEnabled}
              title={tabsList[1].label}
              isFilterVisible={isFilterVisible}
              handleToggleInvoiceGeneration={toggleInvoiceGeneration}
            />
          )}
        />
        <Route
          exact
          path={tabsList[2].url}
          render={(props) => (
            <OccHealthPreInvoice
              {...props}
              isDivisionEnabled={isDivisionEnabled}
              title={tabsList[2].label}
              isFilterVisible={isFilterVisible}
              handleToggleInvoiceGeneration={toggleInvoiceGeneration}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default withDivisionSettings(PreInvoices);
