import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    date: yup
      .string()
      .required('Please choose date')
      .test({
        test: (date: string) => /^\d{2}\/\d{2}\/\d{4}$/.test(date),
        message: 'Invalid date format',
      }),
  })
);

export default validation;
