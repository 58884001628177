import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeProbe,
  ProbeFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/probe';
import { URL_PROBE } from 'constant/path/workflow';

interface PropsType extends RouteComponentProps {}

const ProbeAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: ProbeFormModel) =>
    storeProbe.addProbe(data).then((error) => {
      if (error) return error;
      history.push(URL_PROBE);
    });

  return (
    <>
      <Title title="Add Probe" />
      <Form onSubmit={handleSubmit} backUrl={URL_PROBE} />
    </>
  );
};

export default ProbeAddPage;
