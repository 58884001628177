import { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid, GridControlButton } from 'components/grid';
import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import Export from 'components/project/ExporterNew';
import Filter from './components/Filter';

import UserProfileStore from 'stores/UserProfileStore';
import {
  storeProcedureCount,
  FilterType,
} from 'stores/_mobx/report/procedureCount';
import { PROCEDURE_COUNT as PAGE_ID } from 'constant/pagesId/reports';

const columns = [
  { name: 'facility', title: 'Facility' },
  { name: 'modality', title: 'Modality' },
  { name: 'cpt', title: 'Procedure Code' },
  { name: 'description', title: 'Procedure Description' },
  { name: 'cpt_category', title: 'CPT Category' },
  { name: 'count', title: 'Procedure Count' },
  { name: 'setup', title: 'Setup' },
  { name: 'transportation', title: 'Transportation' },
];

const ProcedureCountPage = () => {
  const {
    fetching,
    logList,
    logTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeProcedureCount;

  const gridRef = useRef<Grid>(null);

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const appName = useMemo(
    () =>
      UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
      'Procedure_Count_By_Facility',
    []
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeProcedureCount.setFilter(filter);
    setPaginationToStart();
  };

  useEffect(() => {
    storeProcedureCount.setDefaultFilter();
  }, []);

  useEffect(() => {
    storeProcedureCount.getProcedureCountLog();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Procedure Count by Facility">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        fetching={fetching}
        isFilterVisible={isFilterVisible}
        onSearch={handleChangeFilter}
      />

      <Grid
        id="procedure_count_gridId"
        noDataText="Please refine your search using the filters above"
        gridControlPanel={
          <GridControlButton
            title="Export"
            disabled={!logTotal}
            onClick={handleToggleExport}
          />
        }
        columns={columns}
        ref={gridRef}
        onAjax={fetching}
        dataSource={logList}
        dataSourceCount={logTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      {isExportVisible && (
        <Export
          exporter="ProcedureCountExporter"
          reportName={appName}
          filter={storeProcedureCount.prepareFilterForExport()}
          gridRef={gridRef}
          columns={columns}
          dataSource={logList}
          pdfExporter={storeProcedureCount.generatePdf}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default observer(ProcedureCountPage);
