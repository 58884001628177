import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Button, IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import useDebounce from 'components/hook/useDebounce';
import DialogServiceHistory from './components/Dialog';
import Filter from './components/Filter';

import {
  storeServiceHistory,
  ServicesType,
  FilterType,
} from 'stores/_mobx/workflow/serviceMaintenance/serviceHistory';
import UserProfileStore from 'stores/UserProfileStore';
import CsvExporter from 'utils/CsvExporter';
import { SERVICE_HISTORY as PAGE_ID } from 'constant/pagesId/workflow';

const columns = [
  { name: 'name', title: 'Equipment-Serial #' },
  { name: 'eqtype', title: 'Equipment Type' },
  { name: 'techname', title: 'Check Out By' },
  { name: 'serusername', title: 'Serviced By' },
  {
    name: 'equipment_id',
    title: 'History',
    render: (id: number, { equip_type }: ServicesType) => (
      <div className="control">
        <IconButton
          onClick={() => storeServiceHistory.getHistoryLogs(id, equip_type)}>
          <i className="icon icon-notes" />
        </IconButton>
      </div>
    ),
  },
];

const handleExport = (list: ServicesType[]) => {
  const appName =
    UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) || 'Service_history';

  const csvExporter = new CsvExporter(list, columns);
  csvExporter.exportAndDownload(appName);
};

const ServiceHistoryPage = () => {
  const {
    fetching,
    serviceLog,
    serviceLogTotal,
    historyLog,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeServiceHistory;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const debouncedMethod = useDebounce(setPaginationToStart, 400);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeServiceHistory.setFilter(filter);

    debouncedMethod();
  };

  useEffect(() => {
    storeServiceHistory.getServiceHistoryMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Service History">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide filter' : 'Show filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        visibility={isFilterVisible}
        fetching={fetching}
        onChange={handleChangeFilter}
      />

      <Grid
        columns={columns}
        onAjax={fetching}
        id="serviceHistoryGrid"
        gridControlPanel={
          <GridControlButton
            title="Export"
            onClick={() => handleExport(serviceLog)}
          />
        }
        dataSource={serviceLog}
        dataSourceCount={serviceLogTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      {historyLog && <DialogServiceHistory />}
    </>
  );
};

export default observer(ServiceHistoryPage);
