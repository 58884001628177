import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import FilterNameAndState, {
  FilterType,
} from 'stores/_mobx/options/filterNameAndStatus';
import { dateToLocalTimezone } from 'utils/DateUtils';
import { BASE_URL_FILE_DIR } from 'constant/config';

//MM/DD/YYYY -> YYYY-DD-MM
const changeDateFormatBeforeSend = (date: string) => {
  if (date) {
    const splitDate = date.split('/');
    return `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
  }
  return null;
};

//YYYY-DD-MM -> MM/DD/YYYY
const changeDateFormatAfterReceive = (date: string) => {
  if (date) {
    const splitDate = date.split('-');
    return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
  }
  return '';
};

export interface ProbeType {
  cal_due_date: string;
  description: string;
  enable: boolean;
  file_path: string;
  last_cal_date: string;
  manuf_date: string;
  model_no: string;
  probe: string;
  refid: number;
  serial_no: string;
}

interface FileResponseType {
  desc: string;
  filenm: string;
  filestatus: string;
  remvImg: string;
  uploaded_dt: string;
}

interface ProbeResponseType {
  '0': Omit<ProbeType, 'enable' | 'refid'>;
  file: 0 | FileResponseType[];
}

interface FileType {
  desc: string;
  filenm: string;
  filestatus: string;
}

interface FilesPreviewType {
  desc: string;
  fileName: string;
  path: string;
  uploadDate: string;
}

interface ProbeDopeDownResponse {
  label: string;
  data: string;
}

interface OptionType {
  label: string;
  value: number;
}

export interface ProbeFormModel
  extends Omit<ProbeType, 'refid' | 'enable' | 'file_path'> {
  refid: number | null;
  documentList: FileType[];
}

class Probe {
  fetching: boolean = false;
  fetchingFileList: boolean = false;
  fetchingOption: boolean = false;
  probeList: ProbeType[] = [];
  probeTotal: number = 0;
  probe: ProbeFormModel | undefined = undefined;
  idForDelete: number | null = null;
  fileListPreview: FilesPreviewType[] | null = null;
  noteId: number = 0;
  optionsProbe: OptionType[] = [];

  filter: FilterNameAndState = new FilterNameAndState();
  page: Pagination = new Pagination({ id: 'other_equipment_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingFileList: observable,
      fetchingOption: observable,
      probeList: observable,
      probeTotal: observable,
      probe: observable,
      idForDelete: observable,
      fileListPreview: observable,
      noteId: observable,
      optionsProbe: observable,

      setFetching: action,
      setFetchingFileList: action,
      setFetchingOption: action,
      setProbeList: action,
      setProbeTotal: action,
      setProbe: action,
      setIdForDelete: action.bound,
      setFileListPreview: action,
      setNoteId: action,
      clearIdForDelete: action.bound,
      clearProbe: action.bound,
      clearFileListPreview: action.bound,
      clearNoteId: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFetchingFileList = (fetching: boolean) => {
    this.fetchingFileList = fetching;
  };

  setFetchingOption = (fetching: boolean) => {
    this.fetchingOption = fetching;
  };

  setProbeList(list: ProbeType[]) {
    this.probeList = list;
  }

  setProbeTotal(count: number) {
    this.probeTotal = count;
  }

  setProbe(probe?: ProbeFormModel) {
    this.probe = probe;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setFileListPreview(fileList: FilesPreviewType[]) {
    this.fileListPreview = fileList;
  }

  setNoteId(id: number) {
    this.noteId = id;
  }

  clearIdForDelete() {
    this.idForDelete = null;
  }

  clearProbe() {
    this.probe = undefined;
  }

  clearFileListPreview() {
    this.fileListPreview = null;
  }

  clearNoteId() {
    this.noteId = 0;
  }

  async getProbeList(filter: FilterType) {
    const { pagination } = this.page;

    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.name,
      filter.state,
    ];

    try {
      const response = await apiRequest<'SE' | ProbeType[]>({
        url: 'vehicle.ProbeMaster.GetProbeList',
        data,
      });
      if (Array.isArray(response)) {
        const list = response.map((item) => ({
          ...item,
          refid: Number(item.refid),
        }));
        this.setProbeList(list);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setProbeList([]);
    }
  }

  async getProbeCount(filter: FilterType) {
    try {
      const count = await apiRequest<'SE' | 'S' | number>({
        url: 'vehicle.ProbeMaster.ProbeCount',
        data: [filter.name, filter.state],
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setProbeTotal(total);
    } catch (e) {
      this.setProbeTotal(0);
    }
  }

  getProbeMain = () => {
    this.setFetching(true);

    const filter = this.filter.options;

    const promiseList = this.getProbeList(filter);

    const promiseCount = this.getProbeCount(filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getProbe(id: number) {
    this.setFetching(true);

    try {
      const response = await apiRequest<[] | ProbeResponseType>({
        url: 'vehicle.ProbeMaster.GetProbe',
        data: [id],
      });
      if (Array.isArray(response)) {
        throw Error('');
      } else {
        const documentList = response.file
          ? response.file.map(({ filenm, desc, filestatus }) => ({
              filenm,
              desc,
              filestatus,
            }))
          : [];
        const manuf_date = changeDateFormatAfterReceive(response[0].manuf_date);

        const last_cal_date = changeDateFormatAfterReceive(
          response[0].last_cal_date
        );

        const cal_due_date = changeDateFormatAfterReceive(
          response[0].cal_due_date
        );

        const { file_path, ...payload } = response['0'];

        this.setProbe({
          ...payload,
          manuf_date,
          last_cal_date,
          cal_due_date,
          refid: id,
          documentList,
        });
      }
    } catch (e) {
      this.setProbe();
    } finally {
      this.setFetching(false);
    }
  }

  async addProbe({ documentList, ...payload }: ProbeFormModel) {
    const manuf_date = changeDateFormatBeforeSend(payload.manuf_date);
    const last_cal_date = changeDateFormatBeforeSend(payload.last_cal_date);
    const cal_due_date = changeDateFormatBeforeSend(payload.cal_due_date);

    const data = [
      {
        ...payload,
        manuf_date,
        last_cal_date,
        cal_due_date,
      },
      [],
      documentList,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'vehicle.ProbeMaster.AddProbe',
        data,
      });
      if (result === 'S') {
        Notification.success('Probe was added successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'Serial number is not unique!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateProbe({ documentList, ...payload }: ProbeFormModel) {
    const manuf_date = changeDateFormatBeforeSend(payload.manuf_date);
    const last_cal_date = changeDateFormatBeforeSend(payload.last_cal_date);
    const cal_due_date = changeDateFormatBeforeSend(payload.cal_due_date);

    const data = [
      payload.refid,
      { ...payload, manuf_date, last_cal_date, cal_due_date },
      documentList,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'vehicle.ProbeMaster.EditProbe',
        data,
      });

      if (result === 'S') {
        Notification.success('Probe was updated successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'This Serial number already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  changeProbeStatus = async (ids: number[]) => {
    this.setFetching(true);
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'vehicle.ProbeMaster.changeStatus',
        data: [ids],
      });
      if (response === 'S') {
        const { length } = ids;

        const statusMsg = this.filter.options.isActive
          ? 'deactivated'
          : 'activated';

        Notification.success(
          `${length} Probe${length > 1 ? 's were' : 'was'} ${statusMsg}`
        );
        return true;
      } else Error('');
    } catch (e) {
      Notification.warning('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  };

  async deleteProbe() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'vehicle.ProbeMaster.DeleteProbe',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Probe was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("This Probe is in use, so it can't be deleted!");
      this.setFetching(false);
      return false;
    }
  }

  async getFileList(id: number) {
    runInAction(() => {
      this.fetchingFileList = true;
      this.fileListPreview = [];
    });
    try {
      const response = await apiRequest<[] | ProbeResponseType>({
        url: 'vehicle.ProbeMaster.GetProbe',
        data: [id],
      });
      if (Array.isArray(response)) {
        throw Error('');
      } else {
        const files = response.file
          ? response.file.map(({ filenm, desc, uploaded_dt }) => ({
              fileName: filenm || 'unknown',
              desc,
              uploadDate: dateToLocalTimezone({ date: uploaded_dt }),
              path: `${BASE_URL_FILE_DIR}doc_img/probe/${id}-${filenm}`,
            }))
          : [];

        this.setFileListPreview(files);
      }
    } catch (e: any) {
    } finally {
      this.setFetchingFileList(false);
    }
  }

  async getOptionsProbe() {
    if (this.fetchingOption) return Promise.resolve();
    this.setFetchingOption(true);
    try {
      const response = await apiRequest<ProbeDopeDownResponse[]>({
        url: 'vehicle.ProbeMaster.ProbeDropDown',
      });

      const options = response.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));
      runInAction(() => {
        this.fetchingOption = false;
        this.optionsProbe = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOption = false;
        this.optionsProbe = [];
      });
    }
  }
}

export const storeProbe = new Probe();
