import React, { Component } from 'react';

import { Grid } from 'components/grid';

export interface PChildModifier {
  id?: string;
  errors?: any;
  className?: string;
  errorMessages?: any;
}

export class SChildModifier {}

export default class ChildModifier<
  P extends PChildModifier,
  S extends SChildModifier
> extends Component<P, S> {
  renderChildren() {
    const { props } = this;
    if (!props.children) {
      return null;
    }
    let children;
    if (React.isValidElement(props.children)) {
      children = [props.children];
    } else if (props.children instanceof Array) {
      if (
        (props.children && props.children.length) ||
        props.children.length === 0
      ) {
        children = props.children;
      }
    } else if (props.children) {
      throw new Error('Unknown children format in form');
    } else {
      return null;
    }
    return children.map((child, key) => this._cloneChild(child, '' + key));
  }

  _cloneChild(child: any, key: string): any {
    if (Array.isArray(child)) {
      return child.map((child, index) =>
        this._cloneChild(child, '' + key + '_' + index)
      );
    }
    return this.cloneChild(child, key);
  }

  cloneChild(child: any, key: string): any {
    return null;
  }

  isFormChild(child: string | React.ReactElement) {
    if (child) {
      if (typeof child === 'string' || typeof child.type === 'string') {
        return false;
      } else if (child.props && child.props.noclone === 'true') {
        return false;
      } else return !(child instanceof Grid);
    }
    return false;
  }
}
