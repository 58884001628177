import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import DialogInterpretation, {
  InterpretationOptions,
} from 'page/workflow/order/components/DialogInterpretation';
import DialogFormRadiologist from 'page/components/DialogFormRadiologist';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import PatientInfo, { PropsType as PatientPropTypes } from './PatientInfo';
import Form from './Form';
import validation, { generateHash } from './validation';

import {
  storeOrderTranscription,
  TranscriptionFormType,
} from 'stores/_mobx/workflow/order/transcription';
import { RadiologistType } from 'stores/_mobx/users/radiologist';
import { storeDropdownRadiology } from 'stores/_mobx/dropDown/radiology';
import { storeDropdownRadiologist } from 'stores/_mobx/dropDown/radiologist';

const NoCptInformation = () => (
  <p>
    This order doesn't have assigned CPT codes!
    <br />
    Please, open <b>Visit Information</b> of this order and select appropriate
    CPTs on <b>Exam Information</b> form!
  </p>
);

interface PropsType {
  orderId: number;
  conclusion?: string;
  transcriptionId?: number;
  patientInfo: PatientPropTypes['patientInfo'];
  onClose: (shouldUpdate: boolean) => void;
}

const DialogTranscription = ({
  orderId,
  transcriptionId,
  patientInfo,
  conclusion = '',
  onClose,
}: PropsType) => {
  const {
    fetchingDetails,
    permission,
    transcriptionDetails: transcriptions,
  } = storeOrderTranscription;

  const [interpretationOptions, setInterpretationOptions] =
    useState<InterpretationOptions>(null);

  const [radiologyGroupOptions, setRadiologyGroupId] = useState<{
    id: number;
    transcriptionIdx: number;
  }>(null);

  const {
    control,
    formState: { isDirty, isSubmitting, isSubmitted },
    watch,
    reset,
    getValues,
    setValue,
    trigger,
    handleSubmit,
  } = useForm<TranscriptionFormType>({
    resolver: validation(Boolean(transcriptionId)),
  });

  const isLoading = isSubmitting || fetchingDetails;

  const handleCloseTranscriptionDialog = () => {
    onClose(false);
  };

  const handleRunRevalidate = useCallback(() => {
    if (isSubmitted) trigger('transcriptions');
  }, [isSubmitted, trigger]);

  const handleClickAddInterpretation = (idx: number) => {
    const transcription = getValues(`transcriptions.${idx}`);
    const interpretationOptions = {
      idx,
      orderId,
      cpt: transcription.cptCode,
      cptId: transcription.refid,
      cptSessionNo: transcription.cptSessionNo,
      radiologistId: transcription.radiologistId,
      interpretation: transcription.interpretation || '',
      reportType:
        transcription.reportType as InterpretationOptions['reportType'],
    };

    setInterpretationOptions(interpretationOptions);
  };

  const handleCloseRadiologistDialog = () => {
    setRadiologyGroupId(null);
  };

  const handleAddRadiologist = (radiologist: RadiologistType) => {
    const { id, transcriptionIdx } = radiologyGroupOptions;

    storeDropdownRadiologist.getOptions(id).then(() => {
      setValue(
        `transcriptions.${transcriptionIdx}.radiologistId`,
        radiologist.radiologistId
      );

      handleCloseRadiologistDialog();
    });
  };

  const handleCloseDialogInterpretation = () => {
    setInterpretationOptions(null);
  };

  const handleSubmitInterpretation = (
    fileName: string,
    interpretation: string
  ) => {
    const { idx, radiologistId, reportType } = interpretationOptions;

    const interpretationHash = generateHash({ radiologistId, reportType });

    handleCloseDialogInterpretation();

    setValue(`transcriptions.${idx}.fileName`, fileName, {
      shouldDirty: true,
    });
    setValue(`transcriptions.${idx}.filePath`, fileName, {
      shouldDirty: true,
    });
    setValue(`transcriptions.${idx}.useTempFolder`, true);
    setValue(`transcriptions.${idx}.interpretation`, interpretation);
    setValue(`transcriptions.${idx}.interpretationHash`, interpretationHash);

    trigger(`transcriptions.${idx}.filePath`);
    trigger(`transcriptions.${idx}.interpretationHash`);
  };

  const handleClickSubmit = handleSubmit((payload) =>
    storeOrderTranscription.addTranscription(payload).then((isSucceed) => {
      if (isSucceed) onClose(true);
    })
  );

  const handleClickReset = () => {
    reset({ transcriptions, orderId, patientId: patientInfo.id, conclusion });
  };

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      if (name?.includes('filePath') && type) {
        // This condition is true, when file Uploaded
        const [idx] = name.match(/\d+/);

        const transcriptionFieldName =
          `transcriptions.${idx}` as `transcriptions.${number}`;

        const transcription = getValues(transcriptionFieldName);

        const value = {
          ...transcription,
          fileName: transcription.filePath,
          useTempFolder: false,
          interpretation: '',
          interpretationHash: '',
        };

        setValue(transcriptionFieldName, value);
      } else if (isSubmitted) {
        if (name?.includes('reportType') || name?.includes('radiologistId')) {
          trigger('transcriptions');
        }
      } else if (name?.includes('radiologyGroupId') && type) {
        const fieldName = name.replace(
          'radiologyGroupId',
          'radiologistId'
        ) as `transcriptions.${number}.radiologistId`;

        setValue(fieldName, 0, {
          shouldDirty: true,
          shouldValidate: isSubmitted,
        });
        trigger('transcriptions');
      }
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, watch]);

  useEffect(() => {
    storeOrderTranscription.getTranscriptionDetails({
      orderId,
      transcriptionId,
    });
  }, [orderId, transcriptionId]);

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptions]);

  useLayoutEffect(() => {
    storeOrderTranscription.checkPermission();

    return () => {
      storeOrderTranscription.clearPermission();
      storeOrderTranscription.clearTranscriptionDetails();
      storeDropdownRadiologist.clearOptionsCollection();
    };
  }, []);

  return (
    <>
      <Dialog size="extraLarge" handleClose={handleCloseTranscriptionDialog}>
        <DialogHeader
          title={`${transcriptionId ? 'Edit' : 'Add'} Radiology Report`}
          onClose={handleCloseTranscriptionDialog}
        />
        <DialogBody className={isLoading && 'on-ajax'}>
          <PatientInfo patientInfo={patientInfo} />
          {fetchingDetails && (
            <div className="grouped-fields-block" style={{ height: '245px' }} />
          )}
          {transcriptions ? (
            <Form
              control={control}
              permission={permission}
              isEditMode={Boolean(transcriptionId)}
              watch={watch}
              reValidate={handleRunRevalidate}
              onAddInterpretation={handleClickAddInterpretation}
              onClickAddRadiologist={setRadiologyGroupId}
            />
          ) : (
            <NoCptInformation />
          )}
        </DialogBody>
        <DialogFooter>
          <Button
            text="Reset"
            variant="warning"
            disabled={isLoading || !isDirty}
            onClick={handleClickReset}
          />
          <Button
            text="Save"
            disabled={isLoading || !isDirty}
            onClick={handleClickSubmit}
          />
        </DialogFooter>
      </Dialog>

      {interpretationOptions && (
        <DialogInterpretation
          options={interpretationOptions}
          onSubmit={handleSubmitInterpretation}
          onClose={handleCloseDialogInterpretation}
        />
      )}

      {radiologyGroupOptions && (
        <DialogFormRadiologist
          groupId={radiologyGroupOptions.id}
          groupName={
            storeDropdownRadiology.findOption(radiologyGroupOptions.id)
              ?.label || ''
          }
          onSubmit={handleAddRadiologist}
          onClose={handleCloseRadiologistDialog}
        />
      )}
    </>
  );
};

export default observer(DialogTranscription);
