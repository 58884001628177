import React from 'react';
import { Chart } from 'react-google-charts';

export interface PAbstractChart {
  className?: string;
  data: any;
  options?: any;
}

export class SAbstractChart {}

/**
 * This class handle window resize event for charts
 */
export default class AbstractChart<
  P extends PAbstractChart,
  S extends SAbstractChart
> extends React.Component<P, S> {
  cache: any = null;
  prevProps: P = null;
  updateDimensionsFunc: () => void;

  constructor(props: P) {
    super(props);
    this.cache = null;
    this.prevProps = null;
    this.updateDimensionsFunc = () => this.updateDimensions();
  }

  updateDimensions() {
    this.forceUpdate();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensionsFunc);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensionsFunc);
  }

  render() {
    if (this.cache === null || this.prevProps !== this.props) {
      this.prevProps = this.props;
      this.prepareCache();
    }
    return (
      <div className={this.props.className}>
        <Chart
          {...this.cache}
          chartType={this.getChartType()}
          width="100%"
          height="250"
        />
      </div>
    );
  }

  prepareCache() {
    throw Error('Implement this function return type - void');
  }

  getChartType() {
    throw Error('Implement this function. Return type - string');
  }
}
