import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Notification from 'components/modal/Notification';
import Form from 'components/form/Form';
import FormControl from 'components/form/FormControl';
import FacilityRolesDropdown from 'components/project/dropdown/FacilityRolesDropdown';
import { TRadioOption } from 'components/form/input/Radio';
import { BASE_URL_FILE_DIR } from 'constant/config';
import { SpinnerFixed } from 'components/spinner';
import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import FormComponentsProvider from './FormComponentsProvider';
import DialogImportVaccination, {
  VaccinationPayloadType,
} from './components/DialogImportVaccination';

import FacilityDetailsActions from 'actions/system-setup/master-setup/facility/FacilityDetailsActions';
import UserSelectionActions from 'actions/project/UserSelectionActions';
import FacilityActions from 'actions/project/FacilityActions';
import SecurityPermissionActions from 'actions/SecurityPermissionActions';
import GroupManagerActions, {
  TClinicianGroup,
} from 'actions/privileges/GroupManagerActions';
import { TTemplateForUserType } from 'services/SecurityPermissionService';
import { EmailTemplateResponse } from 'stores/_mobx/profile/myProfile';
import { TUserTypeEnum } from 'services/project/UserSelectionService';
import UserSelectionStore from 'stores/project/UserSelectionStore';
import UserTypeStore from 'stores/project/UserTypeStore';
import { storeDropdownTaxonomy } from 'stores/_mobx/dropDown/taxonomy';
import { storeSpecialty } from 'stores/_mobx/systemSetup/masterSetting/specialty';
import FacilityStore from 'stores/project/FacilityStore';
import FacilityDetailsStore from 'stores/system-setup/facility/FacilityDetailsStore';
import GroupManagerStore from 'stores/privileges/GroupManagerStore';
import { NpiType, TaxonomyType } from 'stores/_mobx/npi';
import { VaccinationDetailType } from 'stores/_mobx/users/vaccination';
import { URL_APPLICATION_USER } from 'constant/path/privileges';
import UserProfileStore from 'stores/UserProfileStore';
import { apiFileRequest } from 'services/RequestService';
import { storePasswordStrength } from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';
import { dateToLocalTimezone } from 'utils/DateUtils';

const idsException = [11, 21, 23];

const USER_TYPE_OPTIONS = [
  { value: '', label: 'All Users' },
  { value: 'A', label: 'Client Users' },
  { value: 'B', label: 'Company Users' },
];

const defaultNpiBasic = {
  first_name: '',
  last_name: '',
  middle_name: '',
  name_prefix: 0,
  status: '',
  gender: 'U',
};

const defaultAddress = {
  address_1: '',
  address_2: '',
  postal_code: '',
  state: '',
  city: '',
  fax_number: '',
  telephone_number: '',
};

interface MatchParams {
  id?: string;
}

export interface PAbstractAddEdit extends RouteComponentProps<MatchParams> {
  callback?: (
    value: boolean,
    response?: string | number | { ipin: string }
  ) => void;
  backUrl?: string;
  onlyCorporate?: boolean;
  disableLowerControl?: boolean;
  preselectedType?: any;
  disableTitle?: boolean;
  parentContext?: { backUrl: string };
}

export class TAbstractAddEditModel {
  userTypeToggler: TUserTypeEnum = '';
  userType: number | 0 = 0;
  userTitle: string = '';
  messaging: string = 'B';
  clinicalGroup: Array<number> = [];
  gender: 'F' | 'M' | 'U' = 'U';
  address: string = '';
  city: string = '';
  officeState: string = '';
  fax: string = '';
  phone: string = '';
  zipCode: string = '';
  state: string = '';
  contactAddress: string = '';
  contactCity: string = '';
  contactPhone: string = '';
  contactZipCode: string = '';
  contactState: string = '';
  salutation: number = 0;
  lastName: string = '';
  firstName: string = '';
  middleName: string = '';
  taxonomy_code: number = 0;
  userinfo_id: number = null;
  emailTemplates: Array<EmailTemplateResponse> = [];
  password: string = '';
  repeatPassword: string = '';
  supervisedGroup: string = '';
  imageUpload: string = '';
  licenses: Array<any> = [];
  corporateId: number = null;
  created_by: number = null;
  npi: any = null;
  username: string = '';
  qualification: number = null;
  floor: number = null;
  contactMobile: string = '';
  ssn: string = '';
  contactMobileOperator: number = null;
  facility: number = 0;
  email: string = '';
  comments: string = '';
  staffInfoRefid: number = null;
  linkedIdName: string = '';
  emergencyName: string = '';
  dateOfBirth: string = '';
  emergencyPhone: string = '';
  location: number = null;
  spouseName: string = '';
  designation: number = null;
  technologist: string = '';
  recordsAccess: string = 'A';
  serviceUser: string = '';
  sig_path_staff: string = '';
  signature: string = '';
  signatureUpload: string | null = null;
  radiologyGroups: Array<any> = [];
  vaccinations: VaccinationDetailType[] = [];
}

export class TFacility {
  location: string = '';
  locationrefid: number = 0;
  pos: string = '';
  posrefid: number = 0;
  region: string = '';
  regionrefid: number = 0;
  state: string = '';
  staterefid: number = 0;
}

export class SAbstractAddEdit {
  onAjax: boolean = true;
  model: TAbstractAddEditModel = new TAbstractAddEditModel();
  tempMainImage: string = '';
  isTempImageUploaded: boolean = false;
  showPinAlert: boolean = false;
  signatureImage: any = null;
  facility: TFacility = new TFacility();
  flagClinicalGroupDataLoaded: boolean = false;
  clinicalGroupData: Array<TClinicianGroup> = [];
  isVaccinationDialogOpen: boolean = false;
  errors: any = {};
  errorMessages: any = {};
  mobileOperator: Array<any> = [];
  floorArray: Array<any> = [];
  fieldsets: Array<string> = [
    'login',
    'employer',
    'contact',
    'licensor',
    'signature',
    'comments',
    'emailNotification',
    'clinicalGroup',
    'otherDocuments',
    'emailTemplates',
  ];
  showHipaaConfirmation: boolean = false;
  showHipaaConfirmationAppRefId: number = null;
  disagreeHipaaConfirmationCallback: () => void = null;
  refreshResultsAvailableEmailTemplateLabel: () => void = null;
  pinContent: React.ReactNode = null;
  pinClb: () => void = null;
}

export default abstract class AbstractAddEdit<
  P extends PAbstractAddEdit,
  S extends SAbstractAddEdit
> extends React.Component<P, S> {
  groupmanagerToken: any = null;
  mvLocationToken: any = null;
  facilityToken: any = null;
  isNew: boolean = false;
  title: string = '';

  abstract onSubmitSuccess(
    model: any,
    hasErrors: boolean,
    errors: any
  ): Promise<string | number | { ipin: string }>;

  abstract getSavedNotification(ipin: any): any;

  static PASSWORDS_NOT_MATCH = 'Passwords did not match.';

  constructor(props: P) {
    super(props);

    this.state = Object.assign(new SAbstractAddEdit(), this.state);

    this.mvLocationToken = FacilityDetailsStore.addListener(() => {
      if (
        this.state.facility !== FacilityDetailsStore.getState().facilityDetail
      ) {
        this.setState({
          facility: FacilityDetailsStore.getState().facilityDetail,
        });
      }
    });
    this.facilityToken = FacilityStore.addListener(() => {
      if (this.state.floorArray !== FacilityStore.getState().facilityFloor) {
        this.setState({ floorArray: FacilityStore.getState().facilityFloor });
      }
    });
    storeSpecialty.getOptions();
    storeDropdownTaxonomy.getOptions();
  }

  uploadSignature = (): Promise<string | null> => {
    const { signature } = this.state.model;
    if (signature) {
      const isNewImg = signature.startsWith('data:image/png');
      const payload = signature
        ? {
            name: `signature_${Date.now()}${parseInt(
              String(window.performance.now())
            )}.png`,
            content: signature,
          }
        : null;

      return isNewImg
        ? apiFileRequest<string>({
            url: 'temppath/json.image.php',
            data: JSON.stringify(payload),
          }).catch((e: any) => {
            Notification.danger('An signature upload error occurred!');
            return null;
          })
        : Promise.resolve('');
    }
    return Promise.resolve(null);
  };

  presetUserType() {
    const preselected = this.props.preselectedType;
    if (!preselected) {
      return;
    }
    const userType = UserSelectionStore.findUserByType(preselected);
    if (userType !== null) {
      const model: TAbstractAddEditModel = Object.assign({}, this.state.model);
      model.userType = userType.refid;
      model.userTypeToggler = '' + userType.type === '0' ? 'B' : 'A';
      this.setState({ model });
    }
  }

  getUser = () => {
    return this.props.match.params;
  };

  componentWillUnmount() {
    storePasswordStrength.clearPasswordSettings();

    if (this.mvLocationToken !== null) {
      this.mvLocationToken.remove();
    }
    if (this.facilityToken !== null) {
      this.facilityToken.remove();
    }
    FacilityActions.clearFloorData();
    if (this.groupmanagerToken !== null) {
      this.groupmanagerToken.remove();
    }
  }

  static getDefaultModel() {
    return new TAbstractAddEditModel();
  }

  static getDefaultState() {
    return new SAbstractAddEdit();
  }

  static GENDER_OPTIONS: Array<TRadioOption> = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
  ];

  static MESSAGING_OPTIONS: Array<TRadioOption> = [
    { label: 'Internal', value: 'I' },
    { label: 'External', value: 'E' },
    { label: 'Both', value: 'B' },
    { label: 'None', value: 'N' },
  ];

  uploadMainImage(name: string, value: string) {
    if (value) {
      this.setState({ tempMainImage: value, isTempImageUploaded: true });
    } else {
      this.setState({ tempMainImage: '', isTempImageUploaded: false });
    }
  }

  updateRadiologistData(nppesPhysician: any) {}

  onRadiologistLookup(data: NpiType) {
    const model: TAbstractAddEditModel = Object.assign({}, this.state.model);
    const primaryPracticeAddress = data.addresses
      ? data.addresses[0]
      : { ...defaultAddress };
    model.address = primaryPracticeAddress
      ? primaryPracticeAddress.address_1
      : '';
    model.city = primaryPracticeAddress ? primaryPracticeAddress.city : '';
    model.officeState = primaryPracticeAddress
      ? primaryPracticeAddress.state
      : '';
    model.fax = primaryPracticeAddress ? primaryPracticeAddress.fax_number : '';
    model.phone = primaryPracticeAddress
      ? primaryPracticeAddress.telephone_number
      : '';
    model.zipCode = primaryPracticeAddress
      ? primaryPracticeAddress.postal_code
      : '';
    model.state = primaryPracticeAddress ? primaryPracticeAddress.state : '';

    const mailingAddress = data.addresses
      ? data.addresses[1]
      : { ...defaultAddress };
    model.contactAddress = mailingAddress ? mailingAddress.address_1 : '';
    model.contactCity = mailingAddress ? mailingAddress.city : '';
    model.contactPhone = primaryPracticeAddress
      ? primaryPracticeAddress.telephone_number
      : '';
    model.contactZipCode = mailingAddress ? mailingAddress.postal_code : '';
    model.contactState = mailingAddress ? mailingAddress.state : '';

    const basicInfo = data.basic || defaultNpiBasic;
    // @ts-ignore
    model.salutation = basicInfo ? basicInfo.name_prefix : 0;
    model.lastName = basicInfo ? basicInfo.last_name : '';
    model.firstName = basicInfo ? basicInfo.first_name : '';
    model.middleName = basicInfo ? basicInfo.middle_name : '';

    // @ts-ignore
    model.gender = basicInfo ? basicInfo.gender : 'U';

    const taxonomies: TaxonomyType[] = data.taxonomies || [];
    const primaryTaxonomy = taxonomies.filter((v) => v.primary);
    const taxonomy = primaryTaxonomy.length
      ? primaryTaxonomy[0]
      : taxonomies[0];
    if (taxonomy) {
      const option = storeDropdownTaxonomy.findOptionByLabel(taxonomy.code);
      model.taxonomy_code = option?.value || 0;
    }

    this.setState({ model });
  }

  isEmpty = (obj: any) => {
    if (!obj) {
      return true;
    }
    const objType: string = typeof obj;
    if (
      objType === 'array' ||
      objType === 'string' ||
      (objType === 'object' && typeof obj['length'] === 'number')
    ) {
      return obj.length === 0;
    }
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && !this.isEmpty(obj[key])) {
        return false;
      }
    }
    return true;
  };

  handleDeleteVaccination = (id: number) => {
    const vaccinations = this.state.model.vaccinations.filter(
      ({ vaccinationTypeId }) => vaccinationTypeId !== id
    );

    const model = {
      ...this.state.model,
      vaccinations,
    };

    this.setState({ model });
  };

  handleUpdateVaccination = (payload: VaccinationPayloadType) => {
    const { model } = this.state;

    const isNewVaccination = !model.vaccinations.some(
      ({ vaccinationTypeId }) => vaccinationTypeId === payload.vaccinationTypeId
    );

    const vaccineEntry = {
      id: 0,
      vaccinationTypeId: payload.vaccinationTypeId,
      vaccinationName: payload.vaccinationName,
      documentName: (payload.document as File).name,
      document: payload.document,
      documentId: 0,
      comment: payload.comment,
      date: dateToLocalTimezone({
        date: new Date(),
      }),
    };

    const list = isNewVaccination
      ? model.vaccinations.concat(vaccineEntry)
      : model.vaccinations.map((vaccine) =>
          vaccine.vaccinationTypeId === payload.vaccinationTypeId
            ? {
                ...vaccineEntry,
                id: vaccine.id,
                documentId: vaccine.documentId,
              }
            : vaccine
        );

    this.setState({
      model: {
        ...this.state.model,
        vaccinations: list,
      },
      isVaccinationDialogOpen: false,
    });
  };

  render() {
    const { props, state } = this;
    const facilityUser = UserSelectionStore.isUserTypesLoaded()
      ? !UserSelectionStore.isTech(state.model.userType)
      : false;
    const facilityUserClass = facilityUser ? 'col-sm-4' : 'visually-hidden';
    const fieldsetsDisabled = !state.model.userType;

    return (
      <>
        <div className="app-user-form">
          {props.disableTitle ? null : <Title title={this.title} />}

          {this.getForm(
            facilityUser,
            facilityUserClass,
            fieldsetsDisabled,
            undefined,
            (data: NpiType) => this.onRadiologistLookup(data)
          )}
          {state.showPinAlert && (
            <Dialog handleClose={() => state.pinClb()}>
              <DialogHeader title="User Pin" onClose={() => state.pinClb()} />
              <DialogBody>{state.pinContent}</DialogBody>
            </Dialog>
          )}
          {!state.showHipaaConfirmation ? null : (
            <Dialog>
              <DialogHeader title="Confirm" />
              <DialogBody>
                You have chosen to enable the Results Available template. <br />
                You agree to having an encrypted email inbox and adhere to all
                HIPAA regulations for receiving patient information.
              </DialogBody>
              <DialogFooter>
                <Button
                  text="Disagree"
                  variant="warning"
                  onClick={() => this.handleClickDialogHippa(false)}
                />
                <Button
                  text="Agree"
                  onClick={() => this.handleClickDialogHippa(true)}
                />
              </DialogFooter>
            </Dialog>
          )}
          {state.isVaccinationDialogOpen && (
            <DialogImportVaccination
              onClose={this.handleToggleDialogVaccination}
              onVaccinationUpdate={this.handleUpdateVaccination}
            />
          )}
        </div>
      </>
    );
  }

  handleClickDialogHippa = (response: boolean) => {
    const { state } = this;
    this.setState(
      {
        showHipaaConfirmation: false,
        refreshResultsAvailableEmailTemplateLabel: () => {
          const appId = state.showHipaaConfirmationAppRefId;
          const userId = state.model.userinfo_id;
          this.setState(
            {
              refreshResultsAvailableEmailTemplateLabel: undefined,
              showHipaaConfirmationAppRefId: undefined,
            },
            () => {
              this.refreshResultsAvailableEmailTemplateLabel(appId, userId);
            }
          );
        },
      },
      response
        ? () => {
            if (state.model.userinfo_id)
              this.onSubmitInternal(
                state.model,
                !this.isEmpty(state.errors),
                state.errors,
                true
              );
          }
        : () => {
            if (state.disagreeHipaaConfirmationCallback) {
              state.disagreeHipaaConfirmationCallback();
            }
          }
    );
  };

  refreshResultsAvailableEmailTemplateLabel = (
    appId: number,
    userId: number
  ) => {
    const { state } = this;

    if (userId) {
      UserSelectionActions.getEmailTemplateNameWithUpdateDateTimeLabel(
        appId,
        userId
      ).then((response) => {
        const model = {
          ...state.model,
          emailTemplates: (state.model.emailTemplates || []).map((option) =>
            Number(option.appRefID) === appId
              ? {
                  ...option,
                  ...response,
                  appRefID: appId,
                }
              : option
          ),
        };

        this.setState({ model });
      });
    }
  };

  onToggleUserType(value: TUserTypeEnum) {
    const model: TAbstractAddEditModel = Object.assign({}, this.state.model);
    model.userTypeToggler = value;
    model.userType = null;
    this.setState({ model: model });
  }

  handleUpdateVaccine = (idx: number) => (comment: string) => {
    const vaccinations = this.state.model.vaccinations.map(
      (el: any, index: number) =>
        index === idx
          ? {
              ...el,
              comment,
            }
          : el
    );

    const model = {
      ...this.state.model,
      vaccinations,
    };
    this.setState({ model });
  };

  getForm(
    facilityUser: boolean,
    facilityUserClass: string,
    fieldsetsDisabled: boolean,
    legendClassName: string = '',
    onRadiologistLookup: (entity: NpiType) => void
  ) {
    const { props, state } = this;
    const provider = new FormComponentsProvider(
      fieldsetsDisabled,
      legendClassName,
      (value: string, openOnly: boolean) => {
        this.toggleFieldSet(value, openOnly);
      },
      this.isNew,
      state,
      this,
      UserTypeStore.isRadiologist(state.model.userType)
    );

    return this.state.onAjax ? (
      <SpinnerFixed />
    ) : (
      <Form
        id="application_users_edit"
        key={state.model.userinfo_id}
        onCollectValues={this.onSetValue.bind(this)}
        submit={this.handleSubmit.bind(this)}
        model={state.model}
        errors={state.errors}
        errorMessages={state.errorMessages}>
        {this.getFormControl()}

        {provider.getUserTitleFieldset(
          USER_TYPE_OPTIONS,
          AbstractAddEdit.MESSAGING_OPTIONS,
          false,
          (value: string) => this.onToggleUserType(value as TUserTypeEnum),
          props.onlyCorporate,
          Boolean(state.model.technologist),
          [
            { label: 'All Orders', value: 'A' },
            { label: 'Only My Orders', value: 'M' },
          ]
        )}
        {provider.getGeneralInformationFieldset(
          AbstractAddEdit.GENDER_OPTIONS,
          this.uploadMainImage.bind(this),
          this.deleteMainImage.bind(this),
          false
        )}
        {provider.getLoginInformationFieldset(
          this.updatePassword.bind(this),
          {
            custom: (v: string) => {
              return v === state.model.password;
            },
          },
          AbstractAddEdit.PASSWORDS_NOT_MATCH
        )}
        {provider.getOtherDocuments(
          props.match.params && ~props.match.params.id
            ? Number(props.match.params.id)
            : -1
        )}
        {this.isPhysicianGroupAdmin()
          ? null
          : provider.getEmployerDetailsFieldset(
              facilityUser,
              facilityUserClass,
              this.changeFacility.bind(this),
              state.model.userTypeToggler === 'A' && state.model.userType !== 2 // if not company user and not physician
            )}
        {provider.getContactsDetailsFieldset(
          this.onSetValue.bind(this),
          state.model.userTypeToggler === 'B' // B - company users, A - Client users, see USERTYPE_OPTIONS
        )}
        {provider.getEmailTemplatesFieldset()}
        {provider.getRadiologyFieldset(onRadiologistLookup)}
        {provider.getUploadSignatureFieldset(false)}
        {/* UserType 29 is Staff-Technologist only this type of user can see vaccination part LPFE-1526 */}
        {Number(state.model.userType) === 29 &&
          provider.getVaccinationFieldset(
            this.state.model.vaccinations,
            this.handleDeleteVaccination,
            this.handleUpdateVaccine
          )}
        {provider.getCommentsFieldset()}
        {this.isPhysicianGroupAdmin() ? provider.getSupervisedGroup() : null}
        {state.model.userTypeToggler === 'B'
          ? provider.getLicensesFieldset()
          : null}
        {props.disableLowerControl ? null : this.getFormControl()}
      </Form>
    );
  }

  isPhysicianGroupAdmin() {
    return parseInt('' + this.state.model.userType) === 27;
  }

  clinicalGroupAddClb(data: Array<string>) {
    const model: TAbstractAddEditModel = Object.assign({}, this.state.model);
    model.clinicalGroup = data.map((item: string) => parseInt('' + item));
    if (data.indexOf(model.supervisedGroup) === -1) {
      model.supervisedGroup = '';
    }
    this.setState({ model: model });
  }

  getClinicalGroup(): Array<TClinicianGroup> {
    const { state } = this;
    if (!state.flagClinicalGroupDataLoaded) {
      return null;
    }
    const out: Array<TClinicianGroup> = [];
    for (let i = 0; i < state.clinicalGroupData.length; i++) {
      const item = state.clinicalGroupData[i];
      const flagFindNumber =
        state.model.clinicalGroup.indexOf(parseInt('' + item.value)) > -1;
      const flagFindString = state.model.clinicalGroup.some(
        (value: number) => '' + value === '' + item.data
      );
      if (flagFindNumber || flagFindString) {
        out.push(item);
      }
    }
    if (out.length === 0) {
      out.push({ value: null, data: null });
    }
    return out;
  }

  handleToggleDialogVaccination = () => {
    this.setState((state) => ({
      isVaccinationDialogOpen: !state.isVaccinationDialogOpen,
    }));
  };

  toggleFieldSet(name: string, openOnly: boolean) {
    const fieldsets: Array<string> = [...this.state.fieldsets];

    const index = fieldsets.indexOf(name);
    if (index > -1) {
      fieldsets.splice(index, 1);
    } else {
      if (openOnly !== true) {
        fieldsets.push(name);
      }
    }
    this.setState({ fieldsets: fieldsets });
  }

  deleteMainImage(event: Event = null) {
    if (event) {
      event.preventDefault();
    }
    if (this.state.isTempImageUploaded) {
      this.setState({ tempMainImage: null, isTempImageUploaded: false });
    } else {
      const model: TAbstractAddEditModel = Object.assign({}, this.state.model);
      model.imageUpload = '';
      this.setState({ model: model });
    }
  }

  changeFacility(name: string, value: number, errors: any) {
    this.onSetValue(name, value, errors, null, null, () =>
      AbstractAddEdit.onFacilityChange(value)
    );
  }

  updatePassword(name: string, value: string, errors: any) {
    this.onSetValue(
      name,
      value,
      errors,
      null,
      null,
      value !== this.state.model.repeatPassword
        ? () =>
            this.onSetValue('repeatPassword', this.state.model.repeatPassword, [
              AbstractAddEdit.PASSWORDS_NOT_MATCH,
            ])
        : () =>
            this.onSetValue(
              'repeatPassword',
              this.state.model.repeatPassword,
              []
            )
    );
  }

  getFormControl() {
    return (
      <FormControl className="col-sm-12 mb-4">
        {this.props.callback ? (
          <Button
            text="Back"
            variant="danger"
            onClick={() => this.props.callback(false)}
          />
        ) : (
          <Link
            to={this.props.backUrl || URL_APPLICATION_USER}
            className="btn btn-danger">
            Back
          </Link>
        )}
        <Button
          text="Reset"
          variant="warning"
          onClick={this.clearForm.bind(this)}
        />
        <Button type="submit" text="Submit" />
      </FormControl>
    );
  }

  clearForm(event: React.MouseEvent) {
    if (event) {
      event.preventDefault();
    }
    // @ts-ignore
    const model = this.getDefaultValue
      ? // @ts-ignore
        this.getDefaultValue()
      : AbstractAddEdit.getDefaultModel();

    this.setState({ model });
  }

  componentDidMount() {
    storePasswordStrength.getPasswordSettings();

    if (FacilityRolesDropdown.getFacilityId() !== 0) {
      this.changeFacility(
        'facility',
        FacilityRolesDropdown.getFacilityId(),
        []
      );
    }

    if (!UserSelectionStore.isUserTypesLoaded()) {
      UserSelectionActions.loadUserTypes().then(() => this.presetUserType());
    } else {
      this.presetUserType();
    }
    if (!this.state.flagClinicalGroupDataLoaded) {
      this.groupmanagerToken = GroupManagerStore.addListener(() => {
        this.setState({
          clinicalGroupData: GroupManagerStore.getState().clinicalGroupData,
          flagClinicalGroupDataLoaded:
            GroupManagerStore.getState().flagClinicalGroupDataLoaded,
        });
      });
      GroupManagerStore.getState().flagClinicalGroupDataLoaded = false;
      GroupManagerActions.loadClinicalGroup();
    }
  }

  static onFacilityChange(id: number) {
    if (id && id !== 0) {
      FacilityDetailsActions.getFacilityDetails(id).then(() =>
        FacilityActions.loadFloorDropdown(id)
      );
    } else {
      FacilityDetailsActions.clearFacilityDetails();
    }
  }

  onUpdateModel(name: string, value: string, err?: any, clb?: () => void) {
    const { state } = this;

    const model = {
      ...state.model,
      [name]: value,
    };

    const errors = {
      ...state.errors,
      [name]: err,
    };

    this.setState({ model, errors }, typeof clb === 'function' ? clb : null);
  }

  onSetValue(
    name: string,
    value: any,
    errors?: Array<string>,
    errorKeys?: any,
    event?: any,
    clb?: () => void
  ) {
    if (name === 'emailTemplates') {
      const emailTemplates: Array<EmailTemplateResponse> = [].concat(
        this.state.model.emailTemplates || []
      );
      for (let appRefID in value) {
        const isSelected = value[appRefID];
        emailTemplates.forEach((item: EmailTemplateResponse) => {
          if (
            item.selected !== isSelected &&
            '' + item.appRefID === '' + appRefID
          ) {
            if (
              isSelected &&
              item.template_name.includes('Results Available')
            ) {
              this.setState({
                showHipaaConfirmationAppRefId: parseInt('' + appRefID),
                showHipaaConfirmation: true,
                disagreeHipaaConfirmationCallback: () => {
                  item.selected = false;
                  const model: TAbstractAddEditModel = Object.assign(
                    {},
                    this.state.model
                  );
                  model.emailTemplates = emailTemplates;
                  this.setState({
                    model: model,
                    disagreeHipaaConfirmationCallback: null,
                  });
                },
              });
            }
            item.selected = isSelected;
          }
        });
      }
      value = emailTemplates;
    } else if (name === 'userType') {
      clb = () => {
        SecurityPermissionActions.loadTemplateListForType(
          this.state.model.userType
        ).then((emailTemplates: Array<TTemplateForUserType>) => {
          const model: TAbstractAddEditModel = Object.assign(
            {},
            this.state.model
          );
          model.emailTemplates = emailTemplates;
          this.setState({ model });
        });
      };
    }
    this.onUpdateModel(name, value as any, errors, clb);
  }

  handleSubmit(model: any, hasErrors: boolean, errors: any) {
    this.onSubmitInternal(model, hasErrors, errors);
  }

  onSubmitInternal(
    model: any,
    hasErrors: boolean,
    errors: any,
    flagNoClose: boolean = false
  ) {
    const { state } = this;
    const isRadiologist = UserTypeStore.isRadiologist(state.model.userType);
    const isPhysicianUser = state.model.userType === 2;
    const isEmailRequired = !(
      isRadiologist ||
      state.model.userType === 2 ||
      UserProfileStore.getUserType() === 'D'
    );

    const isEmailValid = isEmailRequired ? Boolean(state.model.email) : true;

    const userType = UserSelectionStore.findUserType(this.state.model.userType);
    const isCorporateMedicalOrBillingCoordinator = userType
      ? userType.usertype === 'CA' || userType.usertype === 'CC'
      : false;

    const isFacilityRequired =
      isRadiologist ||
      this.isPhysicianGroupAdmin() ||
      idsException.includes(Number(state.model.userType))
        ? false
        : !isCorporateMedicalOrBillingCoordinator &&
          state.model.userTypeToggler === 'A' &&
          state.model.userType !== 2;
    const isClinicalGroupAdminRequired = this.isPhysicianGroupAdmin();

    const isClinicalGroupAdminValid = isClinicalGroupAdminRequired
      ? Boolean(state.model.supervisedGroup)
      : true;
    const isCorporateValid = isCorporateMedicalOrBillingCoordinator
      ? Boolean(state.model.corporateId)
      : true;
    const isFacilityValid = isFacilityRequired
      ? Boolean(state.model.facility)
      : true;

    const isLastNameInvalid = !state.model.lastName;
    const isFirstNameInvalid = !state.model.firstName;
    const isGenderInvalid = !state.model.gender || state.model.gender === 'U';
    const isUsernameInvalid = !state.model.username;
    const isSpecialtyValid = isPhysicianUser
      ? // @ts-ignore
        Boolean(state.model.speciality)
      : true;
    const isPecosValid = isPhysicianUser
      ? // @ts-ignore
        Boolean(state.model.pecos)
      : true;

    const isNpiValid = isPhysicianUser ? state.model.npi?.length === 10 : true;

    if (
      hasErrors ||
      !isEmailValid ||
      !isFacilityValid ||
      isLastNameInvalid ||
      isFirstNameInvalid ||
      isGenderInvalid ||
      !isPecosValid ||
      !isSpecialtyValid ||
      !isNpiValid ||
      !isClinicalGroupAdminValid
    ) {
      const err = Object.assign(
        { lastName: isLastNameInvalid ? ["Can't be empty."] : [] },
        { firstName: isFirstNameInvalid ? ["Can't be empty."] : [] },
        { gender: isGenderInvalid ? ["Can't be empty."] : [] },
        { username: isUsernameInvalid ? ["Can't be empty."] : [] },
        { email: isEmailValid ? [] : ["Can't be empty."] },
        { facility: isFacilityValid ? [] : ["Can't be empty."] },
        { corporateId: isCorporateValid ? [] : ["Can't be empty."] },
        { speciality: isSpecialtyValid ? [] : ["Can't be empty."] },
        { pecos: isPecosValid ? [] : ["Can't be empty."] },
        {
          npi: isPhysicianUser
            ? !state.model.npi
              ? ["Can't be empty."]
              : state.model.npi.length !== 10
              ? ['Npi can contain 10 characters in length']
              : []
            : [],
        },
        {
          supervisedGroup: isClinicalGroupAdminValid ? [] : ["Can't be empty."],
        },
        state.errors,
        errors
      );
      const mod = Object.assign({}, state.model, model, {
        emailTemplates: state.model.emailTemplates,
      });
      this.setState({ errors: err, model: mod, fieldsets: [] });
    } else {
      this.onSubmitSuccess(model, hasErrors, errors).then((response) => {
        const { props, state } = this;
        if (state.refreshResultsAvailableEmailTemplateLabel && flagNoClose) {
          state.refreshResultsAvailableEmailTemplateLabel();
          this.setState({ ...state, onAjax: false });
          return;
        }
        if (response === 'X' || response === 'E') {
          this.showUserExistNotification();
          this.setState({ onAjax: false });
        } else {
          const ipin: string =
            response && response !== 'S'
              ? // @ts-ignore
                response?.ipin || response || ''
              : '';

          const content = this.getSavedNotification(ipin);

          const pinClb = () => {
            if (props.callback) {
              props.callback(
                true,
                typeof response === 'object'
                  ? { ...response, ...state.model }
                  : response
              );
            } else {
              const backUrl = this.getOverviewPageUrl();
              if (backUrl) this.props.history.push(backUrl);
            }
          };
          if (content) {
            this.setState({
              showPinAlert: true,
              pinContent: content,
              pinClb,
            });
          } else {
            pinClb();
          }
        }
      });
    }
  }

  getOverviewPageUrl(): string {
    throw Error('implement getOverviewPageUrl method');
  }

  showUserExistNotification() {
    Notification.warning(
      "Can't save user. Probably, this username already in use."
    );
  }

  getStaffData(signatureFileName: string | null): TStaffData {
    const { props, state } = this;
    const { model } = state;
    const otherDocuments: any = this.refs.otherDocuments;

    return {
      user_title: model.userTitle,
      emailTemplates: (model.emailTemplates || []).filter((v) => v.selected),
      last_name: model.lastName,
      prefix: model.salutation,
      licenses: this.getLicenses(model.licenses),
      corporate_id: model.corporateId,
      fax: model.fax,
      secq1: '', //-
      secq2: '', //-
      userinforefid:
        props.match.params && ~props.match.params.id
          ? Number(props.match.params.id)
          : -1,
      creatid: model.created_by,
      oldimg: model.imageUpload,
      img_path: state.isTempImageUploaded ? state.tempMainImage : '',

      taxonomy: model.taxonomy_code,
      radio_npi: model.npi,

      username: model.username,
      qualification: model.qualification,
      floor_id: model.floor,
      cell: model.contactMobile,
      ssn: model.ssn,
      mobileop_id: model.contactMobileOperator,
      facilityid: model.facility,
      office_address: model.address,
      home_address: model.contactAddress,
      homecity: model.contactCity,
      homestate: model.contactState,
      mail_type: model.messaging,
      homezipcode: model.contactZipCode,
      officecity: model.city,
      officestate: model.officeState,
      email: model.email,
      gender: model.gender,
      comment: model.comments,
      refid: model.staffInfoRefid,
      linkid: model.linkedIdName,
      officezipcode: model.zipCode,
      home_phone: model.contactPhone,
      paswrd: model.password,
      usernm: model.username,
      emergency_name: model.emergencyName,
      first_name: model.firstName,
      dateofbirth: model.dateOfBirth || '',
      work_phone: model.phone,

      emergency_phone: model.emergencyPhone,
      middle_name: model.middleName,
      locationid: model.location,
      spouse_name: model.spouseName,
      usetyp: model.userType,
      designation: model.designation,
      tech_privilege: model.technologist ? '1' : '',
      recordsAccess: model.recordsAccess,
      serviceuser_privilege: model.serviceUser ? '1' : '',
      sig_path_staff: signatureFileName,
      sig_path: signatureFileName,
      signature: signatureFileName,
      supervisedGroup: model.supervisedGroup || '0',
      radiologyGroups: model.radiologyGroups || [],
      otherDocuments: (otherDocuments && otherDocuments.getData()) || null,
    };
  }

  getSignatureDir() {
    return `${BASE_URL_FILE_DIR}doc_img/radiologistsignature/`;
  }

  getLicenses(licenses: Array<any>) {
    if (!licenses) {
      return [];
    }
    return licenses.filter((v: any) => {
      return v.state || v.valid_from || v.valid_to || v.license_no || v.file;
    });
  }
}

export class TStaffData {
  user_title: string = '';
  emailTemplates: Array<EmailTemplateResponse> = [];
  last_name: string = '';
  prefix: number = null;
  licenses: Array<any> = [];
  corporate_id: number = null;
  fax: string = '';
  secq1: string = '';
  secq2: string = '';
  userinforefid: number = null;
  creatid: number = null;
  oldimg: string = '';
  img_path: string = '';

  taxonomy: number = 0;
  radio_npi: string = '';

  username: string = '';
  qualification: number = null;
  floor_id: number = null;
  cell: string = '';
  ssn: string = '';
  mobileop_id: number = null;
  facilityid: number = null;
  office_address: string = '';
  home_address: string = '';
  homecity: string = '';
  homestate: string = '';
  mail_type: string = '';
  homezipcode: string = '';
  officecity: string = '';
  officestate: string = '';
  email: string = '';
  gender: string = '';
  comment: string = '';
  refid: number = null;
  linkid: string = '';
  officezipcode: string = '';
  home_phone: string = '';
  paswrd: string = '';
  usernm: string = '';
  emergency_name: string = '';
  first_name: string = '';
  dateofbirth: string = '';
  work_phone: string = '';

  emergency_phone: string = '';
  middle_name: string = '';
  locationid: number = null;
  spouse_name: string = '';
  usetyp: number = null;
  designation: number = null;
  tech_privilege: string = '';
  recordsAccess: string = '';
  serviceuser_privilege: string = '';
  sig_path_staff: string = '';
  sig_path: string = '';
  signature: string = '';
  supervisedGroup: string = '';
  radiologyGroups: Array<any> = [];
  otherDocuments: string = '';
}
