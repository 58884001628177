import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import {
  AbstractMessagesStore,
  SAbstractMessagesStore,
} from 'stores/messages/AbstractMessagesStore';

export interface MessageDetailedType {
  dttime: string;
  mailCcData: { mail: string; to_mail: string; to_name: string }[];
  mailToData: { mail: string; to_mail: string; to_name: string }[];
  mail_body: string;
  mail_sub: string;
  mailcontent: { refid: string; mailheading: string; mailid: string }[];
  mailto_id: string;
  mailtonm: string;
}

export class SInboxPageStore extends SAbstractMessagesStore {
  messageDetailed: MessageDetailedType | null = null;
}

class InboxPageStore extends AbstractMessagesStore<
  SInboxPageStore,
  CommonPayload
> {
  getInitialState() {
    return new SInboxPageStore();
  }

  reduce(state: SInboxPageStore, action: CommonPayload) {
    switch (action.type) {
      case 'messages-inbox-details':
        this.getState().messageDetailed = action.data;
        break;
      default:
        break;
    }
    return state;
  }
}

const inboxPageStore = new InboxPageStore(appDispatcher);
export default inboxPageStore;
