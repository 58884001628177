import { action, makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

export interface OptionType {
  value: number;
  label: string;
}

class DropdownPayer {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,

      setFetching: action,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  getOptions = async () => {
    if (this.fetching) return Promise.resolve([]);

    this.setFetching(true);

    try {
      const options = await apiRequest<OptionType[]>({
        url: 'usermaster.PayerInfo.LoadPayer',
      });

      runInAction(() => {
        this.options = options;
        this.fetching = false;
      });
    } catch (error) {
      runInAction(() => {
        this.options = [];
        this.fetching = false;
      });
    }
  };
}

export const storeDropdownPayer = new DropdownPayer();
