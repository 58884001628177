import AbstractStore from 'stores/AbstractStore';
import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

class PFacilityStore extends CommonPayload {}

class SFacilityStore {
  facilityFloor: Array<any> = [];
  activeFacilityDates: any = null;
  facilityOnlyActiveList: Array<any> = [];
  facilityName: string = '';
}

class FacilityStore extends AbstractStore<SFacilityStore, PFacilityStore> {
  getInitialState() {
    return new SFacilityStore();
  }

  reduce(state: SFacilityStore, action: PFacilityStore) {
    switch (action.type) {
      case 'facility.FacilityMaster.FacilityDropDownWithoutInactive':
        this.getState().facilityOnlyActiveList = action.data;
        break;
      case 'facility-cpt-active-dates':
        this.getState().activeFacilityDates = null;
        break;
      case 'load-facility-floors':
        this.getState().facilityFloor = action.data;
        break;
      case 'facility.FacilityMaster.GetFacilityName':
        this.getState().facilityName = action.data;
        break;
      default:
        break;
    }
    return state;
  }
}

const facilityStore = new FacilityStore(appDispatcher);
export default facilityStore;
