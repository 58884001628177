import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import { Button, IconButton } from 'components/button';
import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { Select } from 'components/form/select';
import { Textarea } from 'components/form/textarea';
import { Grid, GridControlButton } from 'components/grid';
import { FileUploadControl } from 'components/form/input/FileUpload';
import FormPayerAddress, { defaultValuesAddress } from './FormPayerAddress';
import { validationPayerInfo as resolver } from './validation';

import {
  PayerAddress,
  FormModel,
} from 'stores/_mobx/systemSetup/masterSetting/payerInfo';
import { storePayerType } from 'stores/_mobx/systemSetup/masterSetting/payerType';
import AccessUtils from 'utils/AccessUtils';
import { MS_PAYER_INFO as PAGE_ID } from 'constant/pagesId/systemSetup';

const initialValues: FormModel = {
  id: 0,
  addressList: [],
  filenm: '',
  filepath: '',
  name: '',
  notes: '',
  payor_code: '',
  groupNumber: '',
  payerType: 0,
  experianId: '',
};

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormPayerInfo = ({
  defaultValues = initialValues,
  backUrl,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting, isDirty },
    watch,
    setValue,
    reset,
    handleSubmit,
    getValues,
  } = useForm<FormModel>({ defaultValues, resolver });

  const [addressForEdit, setAddressForEdit] = useState<number | null>(null);

  const isUpdateMode = addressForEdit >= 0;

  const addressList = watch('addressList');

  const handleDeleteAddress = (index: number) => {
    const address = getValues('addressList').filter((el, idx) => idx !== index);
    setValue('addressList', address);
  };

  const columns = useMemo(
    () => [
      { name: 'contact_nm', title: 'Contact' },
      { name: 'address_1', title: 'Address 1' },
      { name: 'address_2', title: 'Address 2' },
      { name: 'city', title: 'City' },
      { name: 'state', title: 'State' },
      { name: 'zipcode', title: 'Zip Code' },
      { name: 'country', title: 'Country' },
      { name: 'mobile', title: 'Cell Phone' },
      { name: 'phone', title: 'Phone' },
      { name: 'fax', title: 'Fax' },
      { name: 'email', title: 'Email' },
      { name: 'website', title: 'Website' },
      {
        name: 'action',
        title: 'Action',
        render: (value: any, data: PayerAddress, idx: number) => (
          <div className="control">
            <IconButton onClick={() => setAddressForEdit(idx)}>
              <i className="bi bi-pencil" />
            </IconButton>
            <IconButton onClick={() => handleDeleteAddress(idx)}>
              <i className="bi bi-trash" />
            </IconButton>
          </div>
        ),
        className: 'width-100',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickAddAddress = () => {
    setAddressForEdit(-1);
  };

  const handleCloseDialog = () => {
    setAddressForEdit(null);
  };

  const handleAddAddress = (address: PayerAddress) => {
    const newAddressList = addressList.concat(address);
    setValue('addressList', newAddressList, {
      shouldDirty: true,
      shouldTouch: true,
    });
    handleCloseDialog();
  };

  const handleUpdateAddress = (address: PayerAddress) => {
    addressList[addressForEdit] = address;
    setValue('addressList', addressList, {
      shouldDirty: true,
      shouldTouch: true,
    });
    handleCloseDialog();
  };

  useEffect(() => {
    storePayerType.getOptions();
  }, []);

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={`row${isSubmitting ? ' on-ajax' : ''}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="name"
        label="Payer name"
        required
        className="col-md-6"
        control={control}
      />
      <Input
        name="payor_code"
        label="Payer ID"
        className="col-md-6"
        control={control}
      />
      <Observer>
        {() => (
          <Select
            name="payerType"
            label="Payer type"
            className="col-md-6"
            isLoading={storePayerType.fetchingOptions}
            options={storePayerType.options}
            control={control}
          />
        )}
      </Observer>
      <Input
        name="groupNumber"
        label="Group number"
        type="number"
        className="col-md-6"
        control={control}
      />
      <Input
        name="experianId"
        label="Experian ID"
        className="col-md-6"
        control={control}
      />

      {AccessUtils.checkAccess(PAGE_ID.CONTRACT) ? (
        <div className="col-sm-12">
          <FileUploadControl
            id="upload_contract"
            accept=".doc, .xls, .pdf, .txt"
            name="filepath"
            style={{ marginLeft: 0, paddingLeft: 0 }}
            noLabel
            pathPrefix="doc_img/contractdetails"
            control={control}
          />
        </div>
      ) : null}

      <Textarea
        name="notes"
        label="Notes"
        className="col-sm-12"
        rows={2}
        control={control}
      />

      <Grid
        wrapperClass="col-sm-12"
        columns={columns}
        dataSource={addressList}
        dataSourceCount={addressList.length}
        disablePagination
        gridControlPanel={
          <GridControlButton
            title="Add address"
            onClick={handleClickAddAddress}
          />
        }
      />

      {addressForEdit !== null && (
        <FormPayerAddress
          isUpdateMode={isUpdateMode}
          defaultValues={
            isUpdateMode ? addressList[addressForEdit] : defaultValuesAddress
          }
          onClose={handleCloseDialog}
          onSubmit={isUpdateMode ? handleUpdateAddress : handleAddAddress}
        />
      )}

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormPayerInfo;
