import { useMemo } from 'react';

import { Grid } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

interface DocumentType {
  fileName: string;
  uploaded: string;
  filePath: string;
}

interface DocumentIncomeType {
  desc?: string;
  filenm: string;
  filestatus: string;
  remvImg: string;
  uploaded_dt: string;
  fileNameToDisplay?: string;
}

interface LegacyPropsType {
  path?: string;
  documentList: Array<DocumentIncomeType>;
  getPath?: (row: any) => string;
  onClose: () => void;
}

const DialogDocumentsLegacy = ({
  path,
  documentList,
  onClose,
  getPath,
}: LegacyPropsType) => {
  const documents = useMemo(
    () =>
      documentList.map((file) =>
        file.fileNameToDisplay
          ? file
          : {
              ...file,
              fileNameToDisplay: file.filenm,
            }
      ),

    [documentList]
  );

  const COLUMNS = [
    { name: 'fileNameToDisplay', title: 'Document name' },
    { name: 'uploaded_dt', title: 'Uploaded date' },
    {
      name: 'view',
      title: 'View',
      className: 'text-center',
      render: (_: any, row: any) => {
        const pathToDoc = getPath ? getPath(row) : `${path}${row.filenm}`;

        return (
          <a href={pathToDoc} target="_blank" rel="noreferrer">
            <i className="icon icon-view-item" />
          </a>
        );
      },
    },
  ];

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Documents" onClose={onClose} />
      <DialogBody>
        <Grid
          id="document_modal_grid"
          columns={COLUMNS}
          dataSource={documents}
          disablePagination
        />
      </DialogBody>
    </Dialog>
  );
};

export default DialogDocumentsLegacy;

interface PropsType {
  fetching?: boolean;
  title?: string;
  documents: DocumentType[];
  onClose: () => void;
}

const columns = [
  { name: 'fileName', title: 'Document name' },
  { name: 'uploaded', title: 'Uploaded date' },
  {
    name: 'filePath',
    title: 'View',
    render: (filePath: string, { fileName }: DocumentType) => {
      const isPdfFile = /.*\.pdf$/.test(filePath);
      const attributes = isPdfFile ? {} : { download: fileName };

      return (
        <a
          target="_blank"
          rel="noreferrer"
          className="fs-5"
          href={filePath}
          {...attributes}>
          <i className="bi bi-eye-fill" />
        </a>
      );
    },
  },
];

export const DialogDocuments = ({
  fetching = false,
  title = 'Documents',
  documents,
  onClose,
}: PropsType) => (
  <Dialog size="large" handleClose={onClose}>
    <DialogHeader title={title} onClose={onClose} />
    <DialogBody className={fetching ? 'on-ajax' : ''}>
      <Grid
        id="document_modal_grid"
        columns={columns}
        dataSource={documents}
        disablePagination
        gridControlPanelBottom={null}
      />
    </DialogBody>
  </Dialog>
);
