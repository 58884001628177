import { useForm } from 'react-hook-form';

import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'components/modal/dialog';

import { PayerAddress } from 'stores/_mobx/systemSetup/masterSetting/payerInfo';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import MaskInput from 'components/form/maskInput';
import { validationPayerAddress as resolver } from './validation';
import DropdownUsaStateShortName from 'components/project/dropdown/UsaStateShortName';

export const defaultValuesAddress: PayerAddress = {
  address_1: '',
  address_2: '',
  city: '',
  contact_nm: '',
  country: '',
  email: '',
  fax: '',
  mobile: '',
  phone: '',
  state: '',
  website: '',
  zipcode: '',
};

interface PropsType {
  isUpdateMode: boolean;
  defaultValues: PayerAddress;
  onClose: () => void;
  onSubmit: (data: PayerAddress) => void;
}

const FormPayerAddress = ({
  defaultValues,
  isUpdateMode,
  onSubmit,
  onClose,
}: PropsType) => {
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<PayerAddress>({ defaultValues, resolver });

  const handleClickSubmit = () => {
    handleSubmit(onSubmit)();
  };
  const handleClickReset = () => {
    reset(defaultValues);
  };

  return (
    <Dialog size="large" scrollable={false} handleClose={onClose}>
      <DialogHeader
        title={`${isUpdateMode ? 'Modify' : 'Add'} Payer Info address`}
        onClose={onClose}
      />
      <DialogBody>
        <form className="row" onSubmit={handleClickSubmit}>
          <div className="col-md-6">
            <Input name="contact_nm" label="Contact name" control={control} />
            <Input name="address_1" label="Address 1" control={control} />
            <Input name="address_2" label="Address 2" control={control} />
            <Input name="city" label="City" control={control} />
            <DropdownUsaStateShortName
              name="state"
              label="State"
              control={control}
            />
            <MaskInput
              name="zipcode"
              label="Zip code"
              format="#####-####"
              control={control}
            />
          </div>
          <div className="col-sm-6">
            <Input name="country" label="Country" control={control} />
            <MaskInput
              name="mobile"
              label="Cell phone"
              format="###-###-####"
              control={control}
            />
            <MaskInput
              name="phone"
              label="Phone"
              format="###-###-####"
              control={control}
            />
            <MaskInput
              name="fax"
              label="Fax"
              format="###-###-####"
              control={control}
            />
            <Input name="email" label="Email" control={control} />
            <Input name="website" label="Website" control={control} />
          </div>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button variant="warning" text="Reset" onClick={handleClickReset} />
        <Button
          onClick={handleClickSubmit}
          text={isUpdateMode ? 'Update' : 'Add'}
          disabled={!isDirty}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default FormPayerAddress;
