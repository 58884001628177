import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    facilityName: yup.string().required('Please, fill in the Facility name'),
    address: yup.string().required('Please, fill in the address'),
    city: yup.string().required('Please, fill in the City'),
    state: yup
      .string()
      .typeError('Please, select a State')
      .required('Please, select a State'),
    zipCode: yup
      .string()
      .test({
        test: (zipCode: string) =>
          zipCode.length === 5 || zipCode.length === 10,
        message: 'Zip code should contain at least 5 digits',
      })
      .required('Please, select the State'),
    location: yup
      .number()
      .nullable()
      .positive()
      .required('Please, select the Location'),
    phone: yup.string().required('Please, fill in the phone'),
    orderType: yup.object().test({
      test: (orderType: Record<number, boolean>) =>
        Object.values(orderType).some((type) => type),
      message: 'Please, select at least 1 order type',
    }),
  })
);

export default validation;
