import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { Grid, GridControlButton } from 'components/grid';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';

import { storeDispatchRegions } from 'stores/_mobx/systemSetup/dispatchRegions';
import AccessUtils from 'utils/AccessUtils';
import { DISPATCH_REGION as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_DISPATCH_REGION_ADD,
  URL_DISPATCH_REGION,
} from 'constant/path/systemSetup';

const renderControl = (id: number) => (
  <div className="control">
    {AccessUtils.get(
      PAGE_ID.EDIT,
      <Link className="bi bi-pencil" to={`${URL_DISPATCH_REGION}/edit/${id}`} />
    )}
  </div>
);

const DispatchRegionListPage = () => {
  const {
    fetching,
    dispatchRegionsList,
    dispatchRegionTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeDispatchRegions;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const columns = useMemo(
    () => [
      { name: 'region', title: 'Region' },
      { name: 'state', title: 'State' },
      { name: 'location', title: 'Location' },
      AccessUtils.checkAccessAny(PAGE_ID.EDIT, PAGE_ID.DELETE)
        ? {
            name: 'refid',
            title: 'Action',
            className: 'width-75',
            render: renderControl,
          }
        : null,
    ],
    []
  );

  const handleChangeFilter = (regionName: string) => {
    storeDispatchRegions.setFilter(regionName);

    debouncedMethod();
  };

  useEffect(() => {
    storeDispatchRegions.getDispatchRegionsList();
  }, [pagination]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Dispatch Regions" />

      <PureInput
        placeholder="Region"
        className="col-md-6 col-lg-4"
        value={filter}
        onChange={handleChangeFilter}
      />

      <Grid
        id="dispatch_regions_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={dispatchRegionsList}
        dataSourceCount={dispatchRegionTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          AccessUtils.checkAccess(PAGE_ID.ADD) && (
            <GridControlButton title="Add" url={URL_DISPATCH_REGION_ADD} />
          )
        }
      />
    </>
  );
};

export default observer(DispatchRegionListPage);
