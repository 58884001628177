import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import {
  AbstractMessagesStore,
  SAbstractMessagesStore,
} from 'stores/messages/AbstractMessagesStore';
import { DraftMessageDetailsType } from 'services/messages/draft/DraftsService';

export class SDraftOverviewStore extends SAbstractMessagesStore {
  messageDetails: DraftMessageDetailsType;
}

class DraftOverviewStore extends AbstractMessagesStore<
  SDraftOverviewStore,
  CommonPayload
> {
  reduce(state: SDraftOverviewStore, action: CommonPayload) {
    switch (action.type) {
      case 'messages-draft-get-details':
        this.getState().messageDetails = action.data;
        break;
      default:
        break;
    }
    return state;
  }
}

const draftOverviewStore = new DraftOverviewStore(appDispatcher);
export default draftOverviewStore;
