import { forwardRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';
import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';

import {
  storeGroupManager,
  OptionType,
} from 'stores/_mobx/clinicianManager/groupManager';

interface PropsType extends PAbstractDropdown {
  options: OptionType[];
  ref: any;
}

interface PropsTypeWrapperType extends PAbstractDropdown {
  userType?: string;
}

interface NewPropsType extends Omit<SelectPropsType, 'options'> {
  userType?: string;
}

class RolesGroupDropdown extends AbstractDropdown<
  PropsType,
  SAbstractDropdown
> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<RolesGroupDropdown, any>(
  ({ userType = '', ...props }: PropsTypeWrapperType, ref) => {
    const { options, fetchingOptions } = storeGroupManager;

    useEffect(() => {
      storeGroupManager.getOptionsByPhysician(userType);
    }, [userType]);

    return (
      <RolesGroupDropdown
        {...props}
        ref={ref}
        options={options}
        fetching={fetchingOptions}
      />
    );
  }
);

export default observer(DropDownMiddleware);

export const DropdownRolesGroup = observer(
  ({ userType, ...props }: NewPropsType) => {
    const { options, fetchingOptions } = storeGroupManager;

    useEffect(() => {
      storeGroupManager.getOptionsByPhysician(userType);
    }, [userType]);

    return <Select {...props} options={options} isLoading={fetchingOptions} />;
  }
);
