import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    exam_type_nm: yup.string().required('Please, fill in the exam type!'),
    exam_type_desc: yup.string().required('Please fill in the description!'),
    q0092_setup: yup.string().required('Please, select a Q00092 Setup!'),
    mod_price: yup.string().required('Please, select order of placement!'),
    media_type: yup.string().required('Please, select a media type!'),
  })
);

export default validation;
