import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface LoginType {
  date: string;
  login_time: string;
  logout_time: string;
  logoutdt: null | string;
  status: string;
  user_id: number;
  user_name: string;
  username: string;
  usertype_id: string;
  woking_hours: string;
}

class LoginReport {
  fetching: boolean = false;
  logs: LoginType[] = [];
  logsTotal: number = 0;

  page: Pagination = new Pagination({ id: 'login_report_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      logs: observable,
      logsTotal: observable,

      setLogs: action,
      setLogsTotal: action,
      setFetching: action,
    });
  }

  setLogs(logs: LoginType[]) {
    this.logs = logs;
  }

  setLogsTotal(count: number) {
    this.logsTotal = count;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  async getLoginReport(userId: number) {
    const { skip, pageSize } = this.page.pagination;

    const data = {
      userId,
      start: skip,
      limit: pageSize,
      userTypeId: null as null,
      fromDate: '',
      toDate: '',
      facilityId: null as null,
      clientOrCompany: null as null,
      lastName: null as null,
      firstName: null as null,
    };

    try {
      const response = await apiRequest<LoginType[]>({
        url: 'userlogin.LoginMaster.GetAllLoginReport',
        data,
      });
      if (Array.isArray(response)) {
        const logs = response.map((log) => ({
          ...log,
          user_id: Number(log.user_id),
          logout_time: dateToLocalTimezone({
            date: log.logout_time,
          }),
          login_time: dateToLocalTimezone({
            date: log.login_time,
          }),
        }));
        this.setLogs(logs);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setLogs([]);
    }
  }

  async getLogCount(userId: number) {
    const data = {
      userId,
      userTypeId: null as null,
      fromDate: '',
      toDate: '',
      facilityId: null as null,
      clientOrCompany: null as null,
      lastName: null as null,
      firstName: null as null,
    };

    try {
      const count = await apiRequest<number>({
        url: 'userlogin.LoginMaster.GetAllLoginReportCount',
        data,
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setLogsTotal(total);
    } catch (e) {
      this.setLogsTotal(0);
    }
  }

  getLoginReportMain = async (userId: number) => {
    this.setFetching(true);

    const promiseList = this.getLoginReport(userId);

    const promiseCount = this.getLogCount(userId);

    return Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };
}

export const storeLoginReport = new LoginReport();
