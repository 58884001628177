import { makeObservable, observable, action, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { getDateRangeBounds } from 'utils/DateUtils';

const defaultFilterValue: FilterType = {
  technologistId: 0,
  dosStart: '',
  dosEnd: '',
  period: 'A',
  facilityId: 0,
  patientLastName: '',
  patientFirstName: '',
};

export interface FilterType {
  technologistId: number;
  dosStart: string;
  dosEnd: string;
  period: string;
  facilityId: number;
  patientLastName: string;
  patientFirstName: string;
}

interface EncounterType {
  exam_count: null | number;
  tech_firstname: string;
  tech_lastname: string;
  technologistid: string;
  incomplete_orders: string;
  tech_proc_cnt: string;
}

interface LogType {
  incompleteOrderCount: number;
  procedureCount: number;
  technologistId: number;
  technologistFullName: string;
  examCount: number;
}

interface LogResponse {
  count: number;
  data: EncounterType[];
}

class ProcedureByTech {
  fetching: boolean = false;
  logList: LogType[] = [];
  logTotal: number = 0;
  rawList: EncounterType[] = [];
  filter: FilterType = defaultFilterValue;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'tech_exam_count_gridId' });

    makeObservable(this, {
      fetching: observable,
      logList: observable,
      logTotal: observable,
      filter: observable,

      setFetching: action,
      setFilter: action.bound,
    });
  }

  setFilter(payload: FilterType) {
    this.filter = payload;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setDefaultFilter() {
    const filter = {
      ...this.filter,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facilityId,
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const filter = {
      ...defaultFilterValue,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facilityId,
    };

    return filter;
  }

  async getLogs(filter: FilterType = this.filter) {
    const { pagination } = this.page;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const payload = {
      skip: pagination.skip,
      pageSize: pagination.pageSize,
      techId: filter.technologistId,
      dosFrom: dos.dateFrom,
      dosTo: dos.dateTo,
      facilityId: filter.facilityId,
      patientLastName: filter.patientLastName,
      patientFirstName: filter.patientFirstName,
    };

    this.setFetching(true);

    try {
      const { count, data } = await apiRequest<LogResponse>({
        url: 'report.TechnicianExam.TechnicianExamCountList',
        data: payload,
      });

      const logList = data.map((log) => ({
        examCount: Number(log.exam_count) || 0,
        technologistFullName: `${log.tech_firstname} ${log.tech_lastname}`,
        technologistId: Number(log.technologistid),
        incompleteOrderCount: Number(log.incomplete_orders),
        procedureCount: Number(log.tech_proc_cnt),
      }));

      runInAction(() => {
        this.logList = logList;
        this.logTotal = count;
        this.rawList = data;
      });
    } catch (e) {
      runInAction(() => {
        this.logList = [];
        this.logTotal = 0;
        this.rawList = [];
      });
    } finally {
      this.setFetching(false);
    }
  }

  generatePdf = async ({ filter }: { filter: string }) => {
    const data = {
      dataSource: this.rawList,
      filter,
    };
    try {
      const response = await apiRequest<string>({
        url: 'pdfcreater.reportPdfGenerator.techexamcountpdf',
        data,
      });
      return response;
    } catch (e: any) {
      return '';
    }
  };

  prepareFilterForExport() {
    const { filter } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return {
      ...filter,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
    };
  }
}

export const storeProcedureByTech = new ProcedureByTech();
