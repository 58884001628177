import { apiRequest } from 'services/RequestService';
import {
  MyProfilePageSubmitData,
  ViewStaffInfo,
  ViewProviderInfo,
} from 'stores/profile/MyProfilePageTypes';
import { TUserTypeEnum } from 'services/project/UserSelectionService';
import { storeImport } from 'stores/_mobx/import';

export class TFacility {
  label: string;
  data: string;
  phone: string;
  fax: string;
  stateid: string;
  facility_stat: string;
}

export class TFloor {
  label: string;
  data: string;
}

export class TDropDownItem {
  label: string;
  data: string;
}

export class TEmailTemplate {
  appRefID: string = null;
  template_name: string = '';
  template_code: string = '';
  selected: boolean = false;
}

export class TViewUserInfo {
  refid: number = null;
  usertype_id: number = null;
  user_name: string = '';
  user_pass: string = '';
  user_id: number = null;
  comment: string = '';
  facility_id: number = null;
  corporate_id: number = null;
  location_id: number = null;
  floor_id: number = null;
  created_by: number = null;
  created_dt: string = '';
  linked_url: string = '';
  user_title: string = '';
  Region: Array<any> = [];
  usertpeval: string = '';
  user_type: TUserTypeEnum = '';
  facilityArr: Array<TFacility> = [];
  region: string = '';
  state: string = '';
  location: string = '';
  floorArr: Array<TFloor> = [];
  licenses: Array<any> = [];
  emailTemplates: Array<TEmailTemplate> = [];
  //
  userinfo_id: number = null;
}

export class TPermissionInfoTemplate {
  appRefID: string;
  template_name: string;
  template_code: string;
  selected: boolean;
}

export class TMobileOperator {
  label: string;
  data: string;
  carrFormat: string;
}

export class TOperatedState {
  label: string;
  state: string;
}

export class TStaffInfo {
  refid: number = null;
  dateofbirth: string = '';
  userinforefid: number = null;
  usernm: string = '';
  prefix: number = null;
  first_name: string = '';
  middle_name: string = '';
  last_name: string = '';
  qualification: number = null;
  designation: number = null;
  gender: string = '';
  ssn: string = '';
  home_address: string = '';
  homecity: string = '';
  homestate: string = '';
  homezipcode: string = '';
  office_address: string = '';
  officecity: string = '';
  officestate: string = '';
  officezipcode: string = '';
  home_phone: string = '';
  work_phone: string = '';
  cell: string = '';
  mobileop_id: number = null;
  fax: string = '';
  email: string = '';
  emergency_name: string = '';
  emergency_phone: string = '';
  spouse_name: string = '';
  oldimg: string = '';
  oldsig?: string = '';
  tech_privilege: string = '';
  recordsAccess: 'A' | 'M' | string = 'A';
  serviceuser_privilege: string = '';
  usetyp: number = null;
  comment: string = '';
  facilityid: number = null;
  locationid: number = null;
  floor_id: number = null;
  username: string = '';
  linkid: string = '';
  corporate_id: number = null;
  user_title: string = '';
  mail_type: string = '';
  paswrd: string = '';
  emailTemplates: Array<TEmailTemplate> = [];
  licenses: Array<any> = [];
  supervisedGroup: string = '';
  radiologyGroups: Array<any> = [];
  img_path: string = '';
  sig_path_staff: string = '';
}

export class TAddRadiologist {
  dateofbirth: string = '';
  taxonomy: string = '';
  first_name: string = '';
  middle_name: string = '';
  last_name: string = '';
  gender: string = '';
  qualification: number = null;
  prefix: number = null;
  home_address: string = '';
  homecity: string = '';
  homestate: string = '';
  homezipcode: string = '';
  office_address: string = '';
  officecity: string = '';
  officestate: string = '';
  officezipcode: string = '';
  work_phone: string = '';
  home_phone: string = '';
  cell: string = '';
  mobileop_id: number = null;
  fax: string = '';
  email: string = '';
  emergency_name: string = '';
  emergency_phone: string = '';
  spouse_name: string = '';
  radio_npi: string = '';
  img_path: string = '';
  sig_path: string = '';
  comment: string = '';
  usetyp: number = null;
  usernm: string = '';
  paswrd: string = '';
  facilityid: number = null;
  locationid: number = null;
  floor_id: number = null;
  username: string = '';
  linkid: string = '';
  user_title: string = '';
  mail_type: string = '';
  emailTemplates: Array<TEmailTemplate> = [];
  radiologyGroups: Array<any> = [];
  otherDocuments: string = '';
}

export class TEditRadiologist {
  refid: number = null;
}

export class Radiologist {
  refid: number = null;
  taxonomy: string = '';
  first_name: string = '';
  middle_name: string = '';
  last_name: string = '';
  gender: string = '';
  birthdate: string = '';
  suffix: string = '';
  prefix: string = '';
  home_address: string = '';
  homecity: string = '';
  homestate: string = '';
  homezipcode: string = '';
  office_address: string = '';
  officecity: string = '';
  officestate: string = '';
  offlocality: string = '';
  offzipcode: string = '';
  work_phone: string = '';
  home_phone: string = '';
  mobile: string = '';
  mobileop_id: string = '';
  mobileop_name: string = '';
  user_title: string = '';
  mail_type: string = '';
  fax: string = '';
  email: string = '';
  emergency_name: string = '';
  emergency_phone: string = '';
  spouse_name: string = '';
  img_path: string = '';
  sig_path: string = '';
  radio_npi: string = '';
  Radiologygrp: string = '';
  statearr: any = null;
  radiologyGroups: any = null;
}

class MyProfilePageService {
  getViewUserInfo(userId: number): Promise<TViewUserInfo> {
    return apiRequest<Array<TViewUserInfo>>({
      url: 'usermaster.UserInfo.GetViewUserInfo',
      data: {
        userId,
      },
    }).then((r) => this.mapViewInfo(r));
  }

  getViewStaffInfo(id: number, userTypeId: number): Promise<ViewStaffInfo> {
    const data = {
      userId: id,
      userType: userTypeId,
    };

    return apiRequest<Array<ViewStaffInfo>>({
      url: 'usermaster.StaffInfo.GetViewStaffInfo',
      data,
    }).then((r: Array<ViewStaffInfo>) => this.mapViewInfo<ViewStaffInfo>(r));
  }

  getRadiologistByID(id: number, userTypeId: number): Promise<Radiologist> {
    const data = {
      userId: id,
      userType: userTypeId,
    };

    return apiRequest<Array<Radiologist>>({
      url: 'usermaster.Radiologist.GetRadiologistByID',
      data,
    }).then((r: any) => this.mapViewInfo(r));
  }

  getViewProviderInfo(id: number): Promise<ViewProviderInfo> {
    return apiRequest<Array<ViewProviderInfo>>({
      url: 'usermaster.ProviderInfo.GetViewProviderInfo',
      data: [id],
    }).then((r: Array<ViewProviderInfo>) =>
      this.mapViewInfo<ViewProviderInfo>(r)
    );
  }

  getRefProvider(id: number) {
    return apiRequest({
      url: 'usermaster.ReferProvider.GetReferProviderByID',
      data: [id],
    }).then((r: any) => this.mapViewInfo(r));
  }

  mapViewInfo<T>(response: Array<T>): T {
    if (response && response.length && response.length === 1) {
      const staffInfo = response[0];
      // @ts-ignore
      const emailTemplates = Array.isArray(staffInfo?.emailTemplates)
        ? {
            // @ts-ignore
            emailTemplates: staffInfo.emailTemplates.filter((el: any) =>
              Boolean(el.template_name)
            ),
          }
        : {};
      return {
        ...staffInfo,
        ...emailTemplates,
      };
    }
    throw new TypeError('Undefined response type');
  }

  async editStaffInfo(data: MyProfilePageSubmitData): Promise<'X' | 'S'> {
    try {
      const fileName =
        typeof data.img_path === 'string'
          ? data.img_path
          : await storeImport.uploadFile({ file: data.img_path as File });

      data.img_path = fileName;
      const response = await apiRequest<'X' | 'S'>({
        url: 'usermaster.StaffInfo.EditStaffInfo',
        data: [data],
      });
      return response;
    } catch (error) {
      return Promise.resolve('S');
    }
  }

  addStaffInfo(data: TStaffInfo) {
    return apiRequest<
      'SE' | 'X' | { ipin: string; refid: number; user_id: number }
    >({
      url: 'usermaster.StaffInfo.AddStaffInfo',
      data: [data],
    });
  }
}

export default new MyProfilePageService();
