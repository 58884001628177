import { makeObservable, observable, action, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { getDateRangeBounds } from 'utils/DateUtils';

const defaultFilterValue: FilterType = {
  corporateId: 0,
  dosEnd: '',
  dosStart: '',
  facility: null,
  facilityId: 0,
  patientFirstName: '',
  patientLastName: '',
  period: 'A',
  technologistId: null,
};

export interface FilterType {
  corporateId: number;
  dosEnd: string;
  dosStart: string;
  facility: number | null;
  facilityId: number | null;
  patientFirstName: string;
  patientLastName: string;
  period: string;
  technologistId: number;
}

interface LogResponseType {
  cancelled_order_count: string;
  count_of_visit: string;
  facility: string;
  facilityId: string;
  incomplete_orders: string;
  location: string;
  modality: string;
  patient_seen_count: string;
  pos: string;
  procedure_count: string;
  state: string;
  technologist: string;
  technologistId: string;
}

interface LogType {
  cancelled_order_count: number;
  count_of_visit: number;
  facility: string;
  facilityId: number;
  incomplete_orders: number;
  location: string;
  modality: string;
  patient_seen_count: number;
  pos: number;
  procedure_count: number;
  state: string;
  technologist: string;
  technologistId: number;
}

interface LogResponse {
  listCount: number;
  listData: LogResponseType[];
}

class SummaryLocation {
  fetching: boolean = false;
  logList: LogType[] = [];
  logTotal: number = 0;
  filter: FilterType = defaultFilterValue;

  page: Pagination = new Pagination({ id: 'summary_location_gridId' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      logList: observable,
      logTotal: observable,
      filter: observable,

      setFetching: action,
      setFilter: action,
    });
  }

  setFilter(payload: FilterType) {
    this.filter = payload;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setDefaultFilter() {
    const filter = {
      ...this.filter,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facilityId,
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const filter = {
      ...defaultFilterValue,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facilityId,
    };

    return filter;
  }

  async getLogs({ technologistId, ...rest }: FilterType = this.filter) {
    const { pagination } = this.page;

    const dos = getDateRangeBounds({
      from: this.filter.dosStart,
      to: this.filter.dosEnd,
    });

    const data = {
      filter: {
        ...rest,
        techId: technologistId,
        dosStart: dos.dateFrom,
        dosEnd: dos.dateTo,
      },
      startIndex: pagination.skip,
      limit: pagination.pageSize,
    };

    this.setFetching(true);

    try {
      const { listCount, listData } = await apiRequest<LogResponse>({
        url: 'report.ReportSummaryLocation.getLocationCountAndList',
        data,
      });

      runInAction(() => {
        this.logList = listData.map((log) => ({
          ...log,
          cancelled_order_count: Number(log.cancelled_order_count),
          count_of_visit: Number(log.count_of_visit),
          facilityId: Number(log.facilityId),
          incomplete_orders: Number(log.incomplete_orders),
          patient_seen_count: Number(log.patient_seen_count),
          pos: Number(log.pos),
          procedure_count: Number(log.procedure_count),
          technologistId: Number(log.technologistId),
        }));
        this.logTotal = Number(listCount);
      });
    } catch (e) {
      runInAction(() => {
        this.logList = [];
        this.logTotal = 0;
      });
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeSummaryLocation = new SummaryLocation();
