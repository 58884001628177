import appDispatcher from 'dispatcher/AppDispatcher';
import UserSelectionStore from 'stores/project/UserSelectionStore';
import UserSelectionActions from 'actions/project/UserSelectionActions';
import MyProfilePageService, {
  TStaffInfo,
  TAddRadiologist,
  TEditRadiologist,
  TViewUserInfo,
} from 'services/profile/personal/MyProfilePageService';
import { MyProfilePageSubmitData } from 'stores/profile/MyProfilePageTypes';
import { TUserTypeEnum } from 'services/project/UserSelectionService';
import GroupManagerActions, {
  TClinicianGroup,
} from 'actions/privileges/GroupManagerActions';
import { apiRequest } from 'services/RequestService';
import {
  storeUserVaccination,
  VaccinationDetailType,
  VaccinationResponseType,
} from 'stores/_mobx/users/vaccination';
import { BASE_URL_FILE_DIR } from 'constant/config';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface ResponseWrapperType {
  userInfo: TViewUserInfo;
  staffInfo: any;
  clinicalGroup: Array<TClinicianGroup>;
}

export default class ApplicationUsersAddEditActions {
  static loadFormData(userId: number) {
    const responseWrapper: ResponseWrapperType = {
      userInfo: new TViewUserInfo(),
      staffInfo: {},
      clinicalGroup: [],
    };
    const callback = () => {
      return MyProfilePageService.getViewUserInfo(userId)
        .then((userInfo: TViewUserInfo) => {
          responseWrapper.userInfo = userInfo;
          const userType: TUserTypeEnum = userInfo.user_type;

          switch (userType) {
            case 'L':
              return ApplicationUsersAddEditActions.getRadiologistByID(
                userInfo.user_id,
                userInfo.usertype_id
              ) as Promise<any>;
            /*case 'D':
                        return ApplicationUsersAddEditActions.getViewProviderInfo(userInfo.user_id); look for PhysiciansPageEdit*/
            case 'R':
              return ApplicationUsersAddEditActions.getRefProvider(
                userInfo.user_id
              );
            default:
              return ApplicationUsersAddEditActions.getViewStaffInfo(
                userInfo.user_id,
                userInfo.usertype_id,
                userInfo.refid
              );
          }
        })
        .then((staffInfo: any) => {
          function clb() {
            responseWrapper.staffInfo = staffInfo;
            appDispatcher.dispatch({
              type: 'application-users-edit-load-action',
              data: responseWrapper,
            });
            return responseWrapper;
          }
          if (responseWrapper.userInfo.user_type === 'D') {
            GroupManagerActions.loadClinicalGroupForPhysitianAdmin(
              responseWrapper.userInfo.user_id
            ).then((clinicalGroup: Array<TClinicianGroup>) => {
              responseWrapper.clinicalGroup = clinicalGroup;
              clb();
            });
          } else {
            clb();
          }

          return staffInfo;
        });
    };
    if (UserSelectionStore.isUserTypesLoaded()) {
      return callback();
    }
    return UserSelectionActions.loadUserTypes().then(callback);
  }

  static async getViewStaffInfo(
    userId: number,
    userTypeId: number,
    refid: number
  ) {
    try {
      //UserType 29 is Staff-Technologist only this type of user can see vaccination part LPFE-1526
      const promiseVaccine =
        userTypeId === 29
          ? apiRequest<VaccinationResponseType[]>({
              url: `userinfos/${refid}/vaccinations`,
              legacy: false,
              method: 'GET',
            })
          : Promise.resolve([] as VaccinationResponseType[]);

      const promiseInfo = MyProfilePageService.getViewStaffInfo(
        userId,
        userTypeId
      );

      const [vaccinationsResponse, info] = await Promise.all([
        promiseVaccine,
        promiseInfo,
      ]);

      const vaccinations: VaccinationDetailType[] = vaccinationsResponse.map(
        ({
           updatedAt,
           createdAt,
           document,
           ...rest
         }, i) => ({
          ...rest,
          vaccinationTypeId: rest.type,
          document: document.contentUrl || '',
          documentName: document.filePath,
          documentId: document.id,
          date: dateToLocalTimezone({
            date: updatedAt,
          }),
        })
      );

      return {
        ...info,
        vaccinations,
      };
    } catch (error) {
      return {
        vaccinations: [] as any[],
      };
    }
  }

  static getRadiologistByID(userId: number, userTypeId: number) {
    return MyProfilePageService.getRadiologistByID(userId, userTypeId).then(
      (response) => {
        const sig_path = response.sig_path
          ? `${BASE_URL_FILE_DIR}doc_img/radiologistsignature/${response.sig_path}`
          : '';
        return { ...response, sig_path };
      }
    );
  }

  static getRefProvider(userId: number) {
    return MyProfilePageService.getRefProvider(userId);
  }

  static clearModel() {
    appDispatcher.dispatch({
      type: 'application-users-edit-load-action-clear',
    });
  }

  static async updateVaccination(
    vaccinations: VaccinationDetailType[],
    userId: number
  ) {
    try {
      const { newCertificates, newComments } = vaccinations.reduce(
        (prev, vaccine) => {
          const isNewComment = typeof vaccine.document === 'string';
          const key = isNewComment ? 'newComments' : 'newCertificates';
          prev[key].push(vaccine);
          return prev;
        },
        { newCertificates: [], newComments: [] }
      );

      const certificatesUploadingPromise =
        storeUserVaccination.uploadCertificatesCollection(newCertificates);

      const uploadedCertificates = await Promise.all(
        certificatesUploadingPromise
      );

      const updatingVaccinationPromise = newCertificates.map((vaccine, idx) => {
        const isNewEntry = !vaccine.id;

        const data = {
          type: vaccine.vaccinationTypeId,
          comment: vaccine.comment,
          document: `/api/vaccination_record_documents/${uploadedCertificates[idx].id}`,
          userinfo: `/api/userinfos/${userId}`,
        };
        return isNewEntry
          ? () => storeUserVaccination.addVaccination(data)
          : () =>
              storeUserVaccination.updateVaccination(
                { comment: data.comment, type: data.type },
                vaccine.id
              );
      });

      for (const action of updatingVaccinationPromise) {
        await action();
      }

      const updatingVaccinationCommentPromise = newComments.map((vaccine) => {
        const data = {
          type: vaccine.vaccinationTypeId,
          comment: vaccine.comment,
        };
        return storeUserVaccination.updateVaccination(data, vaccine.id);
      });

      const vaccinationPromise = Promise.all([
        updatingVaccinationCommentPromise,
      ]);

      return vaccinationPromise;
    } catch (e: any) {
      return null;
    }
  }

  static async editStaffInfo(
    staffData: MyProfilePageSubmitData,
    vaccinations: VaccinationDetailType[],
    vaccinesTrash: VaccinationDetailType[]
  ) {
    const promiseBasicInfo = MyProfilePageService.editStaffInfo(staffData);

    const deleteVaccinationPromise = Promise.all(
      vaccinesTrash.map((vaccine) =>
        storeUserVaccination.deleteVaccination(vaccine.id)
      )
    );

    const vaccinationPromise = ApplicationUsersAddEditActions.updateVaccination(
      vaccinations,
      staffData.userinforefid
    );

    const [response] = await Promise.all([
      promiseBasicInfo,
      vaccinationPromise,
      deleteVaccinationPromise,
    ]);

    return response;
  }

  static async addStaffInfo(
    staffData: TStaffInfo,
    vaccinations: VaccinationDetailType[]
  ) {
    const response = await MyProfilePageService.addStaffInfo(staffData);

    if (typeof response !== 'string') {
      await ApplicationUsersAddEditActions.updateVaccination(
        vaccinations,
        response.refid
      );
    }

    return response;
  }

  static addRadiologistInfo(radiologist: TAddRadiologist) {
    return apiRequest<'SE' | 'F' | 'C' | 'E' | string | number>({
      url: 'usermaster.Radiologist.AddRadiologist',
      data: [radiologist],
    });
  }

  static editRadiologistInfo(radiologist: TEditRadiologist) {
    return apiRequest<'SE' | 'S'>({
      url: 'usermaster.Radiologist.EditRadiologist',
      data: [radiologist],
    });
  }

  static deleteUserDocument(id: number) {
    return apiRequest({
      url: 'usermaster.UserOtherDocuments.deleteDocument',
      data: [id],
    });
  }

  static uploadOtherDocuments(file: {
    userId?: number;
    fileName: string;
    folder: string;
    description: string;
  }) {
    return apiRequest<{ status: 'S'; id: number }>({
      url: 'usermaster.UserOtherDocuments.uploadDocument',
      data: [file],
    });
  }

  static getOtherDocuments(userId: number) {
    return apiRequest({
      url: 'usermaster.UserOtherDocuments.getDocuments',
      data: [userId],
    });
  }
  static getOtherDocumentsStructure() {
    return apiRequest({ url: 'usermaster.UserOtherDocuments.getStructure' });
  }
  static updateOtherDocuments(file: { refid: number; description: string }) {
    return apiRequest<{ status: 'S'; id: number }>({
      url: 'usermaster.UserOtherDocuments.updateDocument',
      data: [file],
    });
  }
}
