import { action, makeObservable, observable } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest, JsonLdResponseType } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import {
  storeFacility,
  FacilityAddressResponseType,
} from 'stores/_mobx/systemSetup/facility';
import {
  storePatient,
  PatientDetailsResponseType,
  Relationship,
} from 'stores/_mobx/workflow/order/patient';
import {
  storeDivisionSettingsStatus,
  DivisionSettingsStatus,
} from 'stores/_mobx/systemSetup/masterSetting/divisionMaster';
import Pagination from 'stores/_mobx/options/pagination';
import { downloadFile } from 'utils/downloadFile';
import {
  dateToLocalTimezone,
  getDateRangeBounds,
  getDayBounds,
} from 'utils/DateUtils';
import { CLAIM_REVIEW as PAGE_ID } from 'constant/pagesId/billingCodding';
import { BASE_URL_FILE_DIR } from 'constant/config';
import { GRID_ID_CLAIM_REVIEW_RELEASE } from 'constant/gridsId/billingCoding';

const genderMapper = {
  M: 'Male',
  F: 'Female',
  U: 'Unknown',
};

const relationshipMapper: Record<Relationship, string> = {
  SE: 'Self',
  SP: 'Spouse',
  CH: 'Child',
  OT: 'Other',
};

const defaultPermission: PermissionType = {
  releaseAgain: false,
  encounterLog: false,
};

export const defaultFilterValue: FilterClaimReviewType = {
  claimType: 'T', // Technical
  patientFirstName: '',
  patientLastName: '',
  facility: [],
  state: [],
  primaryPayer: [],
  facilityType: [],
  releaseStatus: 'N',
  modality: [],
  scheduleServiceDateFrom: '',
  scheduleServiceDateTo: '',
  scheduleServiceDatePeriod: 'A',
  claimReleaseDateFrom: '',
  claimReleaseDateTo: '',
  claimReleaseDatePeriod: 'A',
  batch: '',
  batchDate: '',
  procedureCode: '',
  isSigned: '',
  viewBy: 'claim',
  division: 0,
};

const guarantorPrettier = (
  patient: PatientDetailsResponseType
): GuarantorDetailsType => {
  const guarantorDetails = {
    relationship: patient.guar_g_relation
      ? relationshipMapper[patient.guar_g_relation]
      : 'Not selected',
    firstName: patient.guar_g_fnm,
    lastName: patient.guar_g_lnm,
    middleName: patient.guar_g_mid,
    dateOfBirth: patient.guar_g_dob,
    gender: patient.guar_g_gen ? genderMapper[patient.guar_g_gen] : 'Unknown',
    ssn: patient.guar_g_ssn,
    phoneWork: patient.guar_g_wphone,
    phoneHome: patient.guar_g_mobile,
    address: patient.guar_g_address1,
    city: patient.guar_g_city,
    state: patient.guar_g_state, //shortName
    zip: patient.guar_g_zip,
    email: patient.guar_g_email,
    fax: patient.guar_g_fax,
  };

  return guarantorDetails;
};

const facilityAddressPrettier = (
  facility: FacilityAddressResponseType
): FacilityDetailsType => {
  const facilityDetails = {
    address: facility.address,
    city: facility.city,
    state: facility.state, //shortName
    zip: facility.zipcode,
    billingAddress: facility.bill_addres1,
    billingCity: facility.bill_city,
    billingState: facility.bill_state,
    billingZip: facility.bill_zipcode,
  };

  return facilityDetails;
};

const insurancePrettier = (
  patient: PatientDetailsResponseType
): InsuranceType => {
  const insurances = {
    primary:
      'primgroup' in patient
        ? {
            gender: patient.priminsgender
              ? genderMapper[patient.priminsgender]
              : 'Unknown',
            relationship: patient.priminspatrelation
              ? relationshipMapper[patient.priminspatrelation]
              : 'Not selected',
            firstName: patient.priminsfname || '',
            lastName: patient.priminslname || '',
            middleName: patient.priminsmname || '',
            dateOfBirth: patient.priminsdt || '',
            groupNumber: patient.primgroup || '',
            startDate: patient.priminsstdt || '',
            endDate: patient.priminsendt || '',
            insuranceNumber: patient.priminsurance_no || '',
            organizationName: patient.priminsorgname || '',
            payerName: patient.primpayorname || '',
            payerPlanName: patient.primplanname || '',
          }
        : null,
    secondary:
      'secgroup' in patient
        ? {
            gender: patient.secinsgender
              ? genderMapper[patient.secinsgender]
              : 'Unknown',
            relationship: patient.secinspatrelation
              ? relationshipMapper[patient.secinspatrelation]
              : 'Not selected',
            firstName: patient.secinsfname || '',
            lastName: patient.secinslname || '',
            middleName: patient.secinsmname || '',
            dateOfBirth: patient.secinsdt || '',
            groupNumber: patient.secgroup || '',
            startDate: patient.secinsstdt || '',
            endDate: patient.secinsendt || '',
            insuranceNumber: patient.secinsurance_no || '',
            organizationName: patient.secinsorgname || '',
            payerName: patient.secpayorname || '',
            payerPlanName: patient.secplanname || '',
          }
        : null,
    tertiary:
      'tergroup' in patient
        ? {
            gender: patient.teryinsgender
              ? genderMapper[patient.teryinsgender]
              : 'Unknown',
            relationship: patient.teryinspatrelation
              ? relationshipMapper[patient.teryinspatrelation]
              : 'Not selected',
            firstName: patient.teryinsfname || '',
            lastName: patient.teryinslname || '',
            middleName: patient.teryinsmname || '',
            dateOfBirth: patient.teryinsdt || '',
            groupNumber: patient.tergroup || '',
            startDate: patient.teryinsstdt || '',
            endDate: patient.teryinsendt || '',
            insuranceNumber: patient.teryinsurance_no || '',
            organizationName: patient.teryinsorgname || '',
            payerName: patient.terpayorname || '',
            payerPlanName: patient.teryplanname || '',
          }
        : null,
  };

  return insurances;
};

interface PermissionType {
  releaseAgain: boolean;
  encounterLog: boolean;
}

export interface FilterClaimReviewType {
  batch: string;
  batchDate: string;
  procedureCode: string;
  isSigned: 'Y' | 'N' | '';
  primaryPayer: number[];
  claimReleaseDateFrom: string;
  claimReleaseDateTo: string;
  claimReleaseDatePeriod: 'A' | 'TD' | 'W' | 'M';
  releaseStatus: 'R' | 'N';
  claimType: 'T' | 'P';
  patientFirstName: string;
  patientLastName: string;
  modality: number[];
  facility: number[];
  facilityType: number[];
  state: number[];
  division: number;
  scheduleServiceDateFrom: string;
  scheduleServiceDateTo: string;
  scheduleServiceDatePeriod: 'A' | 'TD' | 'W' | 'M';
  viewBy: 'claim' | 'batch';
}

interface GuarantorDetailsType {
  relationship: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  gender: string;
  ssn: string;
  phoneWork: string;
  phoneHome: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  fax: string;
}

export interface InsuranceDetailsType {
  gender: string;
  relationship: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  groupNumber: string;
  startDate: string;
  endDate: string;
  insuranceNumber: string;
  organizationName: string;
  payerName: string;
  payerPlanName: string;
}

interface InsuranceType {
  primary: InsuranceDetailsType | null;
  secondary: InsuranceDetailsType | null;
  tertiary: InsuranceDetailsType | null;
}

export interface InfoType {
  guarantor: GuarantorDetailsType;
  facility: FacilityDetailsType;
  insurances: InsuranceType;
}

interface FacilityDetailsType {
  address: string;
  city: string;
  state: string;
  zip: string;
  billingAddress: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
}

interface ClaimDetailsPayload {
  patientId: number;
  facilityId: number;
}

interface ClaimReviewDetails {
  accession: string | null;
  assigned_date: string;
  batch_no: string;
  claimnotes: string;
  completion_date: string;
  cpt_desc: string | null;
  cptmodifier: string | null;
  created_by: string;
  csz: string;
  dob: string;
  facaddress: string;
  facility_name: string;
  faxed_time: string;
  first_name: string;
  given_by: string;
  icddesc: string;
  icdmodifier: string;
  j_refid: number;
  juridictionid: number;
  last_name: string;
  modality: number;
  modality_name: string;
  number_of_patient: number;
  order_recieved: string;
  ordering_pecos: 'Y' | 'N';
  ordering_physician_fname: string;
  ordering_physician_id: number;
  ordering_physician_lname: string;
  ordering_physician_name: string;
  ordering_physician_npi: string;
  ordering_state: string;
  ordering_validity_date: string;
  ordertype: string;
  patientid: number;
  phoned_time: string;
  pos_name: string;
  pos_override: number;
  primary_provider_name: string;
  priority: number;
  priority_name: string;
  radiologistNames: string;
  radiologists: {
    radiologist_date: string;
    radiologist_fname: string;
    radiologist_lname: string;
    radiologist_name: string;
    radiologist_npi: string;
    radiologist_state: string;
    rendering_pecos: 'Y' | 'N';
    rendering_physician_id: number;
  }[];
  radiology_group: string;
  room: string;
  technologist: string;
}

export interface BatchOfClaimsType {
  batchedDate: string;
  claimCodesCount: number;
  claimsCount: number;
  id: number;
}

export interface ClaimType {
  id: number;
  order: {
    id: number;
    acknowledgement: 'Not Updated' | 'Success' | 'Error';
    scheduleServiceDate: string;
    division: {
      id: number;
      name: string;
      overrideCompanyInformation: boolean;
      shortName: string;
      updatedAt: string;
    };
    facility: {
      id: number;
      facilityName: string;
      dispatchRegion: {
        id: number;
        state: {
          id: number;
          state: string;
          shortName: string;
        };
      };
      facilitySettings: {
        id: number;
      };
    };
    patient: {
      id: string;
      firstName: string;
      lastName: string;
    };
  };
  reReleaseDate: string;
  releaseDate: string;
}

export interface OrderDetailsType {
  accession: string;
  assigned_date: string;
  batch_no: number;
  claimnotes: null | string;
  completion_date: string;
  cpt_desc: string;
  cptmodifier: string;
  created_by: string;
  csz: string;
  dob: string;
  facaddress: string;
  facility_name: string;
  faxed_time: string;
  first_name: string;
  given_by: string;
  icddesc: string;
  icdmodifier: string;
  j_refid: number;
  juridictionid: number;
  last_name: string;
  modality: number;
  modality_name: string;
  modalityid: number;
  number_of_patient: number;
  order_recieved: string;
  ordering_pecos: 'Y' | 'N';
  ordering_physician_fname: string;
  ordering_physician_id: number;
  ordering_physician_lname: string;
  ordering_physician_name: string;
  ordering_physician_npi: string;
  ordering_state: string;
  ordering_validity_date: string;
  ordertype: string;
  patientid: number;
  phoned_time: string;
  pos_name: string;
  pos_override: number;
  primary_payer_name: string;
  primary_plan_name: string;
  primary_provider_name: string;
  priority: number;
  priority_name: string;
  provider_name: string;
  provider_npi: string;
  radiologistNames: string;
  radiologists: unknown[];
  radiology_group: string;
  release_dt?: string;
  room: string;
  secondary_payer_name: string;
  secondary_plan_name: string;
  short_state: string;
  technologist: string;
}

class ClaimReview {
  fetching: boolean = false;
  fetchingOrderDetails: boolean = false;
  fetchingClaimReviewDetails: boolean = false;
  claimsList: BatchOfClaimsType[] | ClaimType[] = [];
  claimsTotal: number = 0;
  info: InfoType | null = null;
  orderDetails: OrderDetailsType | null = null;
  claimReviewDetails: ClaimReviewDetails | null = null;
  filter: FilterClaimReviewType = defaultFilterValue;
  permission: PermissionType = defaultPermission;
  idForEncounterLog: number = 0;

  page: Pagination = new Pagination({ id: GRID_ID_CLAIM_REVIEW_RELEASE });
  storeDivisionSettingsStatus: DivisionSettingsStatus = null;

  constructor(storeDivisionSettingsStatus: DivisionSettingsStatus) {
    makeObservable(this, {
      fetching: observable,
      fetchingOrderDetails: observable,
      fetchingClaimReviewDetails: observable,
      claimsList: observable,
      claimsTotal: observable,
      info: observable,
      orderDetails: observable,
      claimReviewDetails: observable,
      filter: observable,
      permission: observable,
      idForEncounterLog: observable,

      setFetching: action,
      setFetchingOrderDetails: action,
      setFetchingClaimReviewDetails: action,
      setClaims: action,
      setInfo: action,
      setOrderDetails: action,
      setClaimReviewDetails: action,
      setFilter: action,
      setPermission: action,
      setIdForEncounterLog: action,
      clearIdForEncounterLog: action.bound,
      clearInfo: action.bound,
      clearOrderDetails: action.bound,
      clearClaimReviewDetails: action.bound,
    });

    this.storeDivisionSettingsStatus = storeDivisionSettingsStatus;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOrderDetails(fetching: boolean) {
    this.fetchingOrderDetails = fetching;
  }

  setFetchingClaimReviewDetails(fetching: boolean) {
    this.fetchingClaimReviewDetails = fetching;
  }

  setClaims({
    claimsList,
    claimsTotal,
  }: {
    claimsList: BatchOfClaimsType[] | ClaimType[];
    claimsTotal: number;
  }) {
    this.claimsList = claimsList;
    this.claimsTotal = claimsTotal;
  }

  setFilter(filter: FilterClaimReviewType) {
    this.filter = filter;
  }

  setInfo(info: InfoType) {
    this.info = info;
  }

  setOrderDetails(details: OrderDetailsType) {
    this.orderDetails = details;
  }

  setClaimReviewDetails(details: ClaimReviewDetails) {
    this.claimReviewDetails = details;
  }

  setPermission(permission: PermissionType) {
    this.permission = permission;
  }

  setIdForEncounterLog(id: number) {
    this.idForEncounterLog = id;
  }

  clearIdForEncounterLog() {
    this.idForEncounterLog = 0;
  }

  clearInfo() {
    this.info = null;
  }

  clearOrderDetails() {
    this.orderDetails = null;
  }

  clearClaimReviewDetails() {
    this.claimReviewDetails = null;
  }

  clearStore = () => {
    this.idForEncounterLog = 0;
  };

  checkPermission() {
    const encounterLog = !UserProfileStore.isClientUser();

    const releaseAgain = UserProfileStore.isSuperAdmin();

    this.setPermission({
      releaseAgain,
      encounterLog,
    });
  }

  async getBatches() {
    const {
      filter: { viewBy, batchDate, ...filter },
      page: { pagination },
      storeDivisionSettingsStatus: { isDivisionEnabled },
    } = this;

    const batchDateBounds = getDayBounds(batchDate);

    const dos = getDateRangeBounds({
      from: filter.scheduleServiceDateFrom,
      to: filter.scheduleServiceDateTo,
    });

    const claimReleaseDate = getDateRangeBounds({
      from: filter.claimReleaseDateFrom,
      to: filter.claimReleaseDateTo,
    });

    this.setFetching(true);

    this.setClaims({
      claimsTotal: 0,
      claimsList: [],
    });

    try {
      const data = {
        ...filter,
        page: pagination.page,
        itemsPerPage: pagination.pageSize,
        batchDateFrom: batchDateBounds.dateFrom,
        batchDateTo: batchDateBounds.dateTo,
        scheduleServiceDateFrom: dos.dateFrom,
        scheduleServiceDateTo: dos.dateTo,
        claimReleaseDateFrom: claimReleaseDate.dateFrom,
        claimReleaseDateTo: claimReleaseDate.dateTo,
        division: isDivisionEnabled ? filter.division : 0,
      };

      const { totalItems, items } = await apiRequest<
        JsonLdResponseType<BatchOfClaimsType>
      >({
        url: 'order/claimbatches',
        method: 'GET',
        legacy: false,
        contentType: 'ld',
        data,
      });

      const batches = items.map((batch) => ({
        ...batch,
        batchedDate: dateToLocalTimezone({
          date: batch.batchedDate,
          dateOnly: true,
        }),
      }));

      this.setClaims({
        claimsTotal: totalItems,
        claimsList: batches,
      });
    } catch (e: any) {
      this.setClaims({
        claimsTotal: 0,
        claimsList: [],
      });
    } finally {
      this.setFetching(false);
    }
  }

  async getClaims() {
    const {
      filter: { viewBy, batchDate, ...filter },
      page: { pagination },
      storeDivisionSettingsStatus: { isDivisionEnabled },
    } = this;

    const batchDateBounds = getDayBounds(batchDate);

    const dos = getDateRangeBounds({
      from: filter.scheduleServiceDateFrom,
      to: filter.scheduleServiceDateTo,
    });

    const claimReleaseDate = getDateRangeBounds({
      from: filter.claimReleaseDateFrom,
      to: filter.claimReleaseDateTo,
    });

    this.setFetching(true);

    this.setClaims({
      claimsTotal: 0,
      claimsList: [],
    });

    try {
      const data = {
        ...filter,
        page: pagination.page,
        itemsPerPage: pagination.pageSize,
        batchDateFrom: batchDateBounds.dateFrom,
        batchDateTo: batchDateBounds.dateTo,
        scheduleServiceDateFrom: dos.dateFrom,
        scheduleServiceDateTo: dos.dateTo,
        claimReleaseDateFrom: claimReleaseDate.dateFrom,
        claimReleaseDateTo: claimReleaseDate.dateTo,
        division: isDivisionEnabled ? filter.division : 0,
      };

      const { totalItems, items } = await apiRequest<
        JsonLdResponseType<ClaimType>
      >({
        url: 'order/claims',
        method: 'GET',
        legacy: false,
        contentType: 'ld',
        data,
      });

      const claimsList = items.map((claim) => ({
        ...claim,
        order: {
          ...claim.order,
          scheduleServiceDate: dateToLocalTimezone({
            date: claim.order.scheduleServiceDate,
            dateOnly: true,
          }),
        },
        releaseDate: dateToLocalTimezone({
          date: claim.releaseDate,
          dateOnly: true,
        }),
        reReleaseDate: dateToLocalTimezone({
          date: claim.reReleaseDate,
          dateOnly: true,
        }),
      }));

      this.setClaims({
        claimsTotal: totalItems,
        claimsList: claimsList,
      });
    } catch (e: any) {
      this.setClaims({
        claimsTotal: 0,
        claimsList: [],
      });
    } finally {
      this.setFetching(false);
    }
  }

  async getClaimDetails({ facilityId, patientId }: ClaimDetailsPayload) {
    this.setFetchingOrderDetails(true);
    try {
      const facilityPromise = storeFacility.getFacilityAddress(facilityId);

      const patientPromise = storePatient.getPatientInfo(patientId);

      const [patientDetails, facilityDetails] = await Promise.all([
        patientPromise,
        facilityPromise,
      ]);

      const guarantor = guarantorPrettier(patientDetails);

      const facility = facilityAddressPrettier(facilityDetails);

      const insurances = insurancePrettier(patientDetails);

      const info = {
        guarantor,
        facility,
        insurances,
      };

      this.setInfo(info);
    } catch (e: any) {
      this.setInfo(null);
    } finally {
      this.setFetchingOrderDetails(false);
    }
  }

  async getOrderDetails({ claimId, claimType }: { claimId: string | number, claimType: string }) {
    this.setFetchingOrderDetails(true);
    try {
      const [response] = await apiRequest<[OrderDetailsType]>({
        url: 'facility.FacilityMaster.GetOrderDetails',
        data: [claimId, claimType],
      });

      const details = {
        ...response,
        assigned_date: dateToLocalTimezone({ date: response.assigned_date }),
        completion_date: dateToLocalTimezone({
          date: response.completion_date,
        }),
        faxed_time: dateToLocalTimezone({ date: response.faxed_time }),
        phoned_time: dateToLocalTimezone({ date: response.phoned_time }),
        order_recieved: dateToLocalTimezone({ date: response.order_recieved }),
        release_dt:
          dateToLocalTimezone({ date: response.release_dt }) || 'Not Released',
        batch_no: Number(response.batch_no) || 0,
        j_refid: Number(response.j_refid) || 0,
        juridictionid: Number(response.juridictionid) || 0,
        modality: Number(response.modality) || 0,
        modalityid: Number(response.modalityid) || 0,
        number_of_patient: Number(response.number_of_patient) || 0,
        ordering_physician_id: Number(response.ordering_physician_id) || 0,
        patientid: Number(response.patientid),
        pos_override: Number(response.pos_override),
        priority: Number(response.priority),
      };

      this.setOrderDetails(details);
    } catch (e: any) {
    } finally {
      this.setFetchingOrderDetails(false);
    }
  }

  async addClaimNotes({ orderId, notes }: { orderId: number; notes: string }) {
    try {
      const response = await apiRequest<'A' | 'U'>({
        url: 'facility.FacilityMaster.SaveClaimNotes',
        data: [orderId, notes],
      });
      if (response === 'A' || response === 'U') {
        Notification.success(
          response === 'A'
            ? 'Notes added successfully!'
            : 'Notes updated successfully!'
        );
      } else {
        throw Error('');
      }
    } catch (e: any) {
      Notification.danger('An error occurred!');
    }
  }

  async releaseClaims({
    ids,
    type,
    isFirstRelease,
  }: {
    ids: string[];
    type: 'T' | 'P';
    isFirstRelease: boolean;
  }) {
    this.setFetching(true);
    try {
      const data = {
        orders: ids,
        type,
        flag: isFirstRelease ? 0 : 1,
      };

      const response = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'facility.ClaimManager.ReleaseProcess',
        data,
      });
      if (response === 'S') {
        Notification.success('Claim was released');
        return true;
      }
      throw Error();
    } catch (e: any) {
      Notification.success('An error occurred during release!');
      this.setFetching(false);
      return false;
    }
  }

  async exportClaims(ids: string[]) {
    this.setFetching(true);

    try {
      const exportName =
        UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) || '';

      Notification.success(
        'Please wait for a moment, your report will be ready after few moments'
      );

      const fileName = await apiRequest<string>({
        url: 'facility.ClaimManager.GetAllDetailsByOrderId',
        data: {
          orders: ids,
          exportName,
        },
      });
      downloadFile(`${BASE_URL_FILE_DIR}doc_img/billingexport/${fileName}`);
    } catch (e: any) {
      Notification.danger('Error occurred during exporting data.');
    } finally {
      this.setFetching(false);
    }
  }

  async exportReleasedClaims(filter: FilterClaimReviewType) {
    this.setFetching(true);

    const batchDateBounds = getDayBounds(filter.batchDate);

    const dos = getDateRangeBounds({
      from: filter.scheduleServiceDateFrom,
      to: filter.scheduleServiceDateTo,
    });

    const claimReleaseDate = getDateRangeBounds({
      from: filter.claimReleaseDateFrom,
      to: filter.claimReleaseDateTo,
    });

    try {
      const data = {
        facilityId: filter.facility,
        claimReleaseDate: null as null,
        claimReleaseDateFrom: claimReleaseDate.dateFrom,
        claimReleaseDateTo: claimReleaseDate.dateTo,
        batchDateFrom: batchDateBounds.dateFrom,
        batchDateTo: batchDateBounds.dateTo,
        batchId: filter.batch,
        patientLastName: filter.patientLastName,
        patientFirstName: filter.patientFirstName,
        stateId: filter.state,
        dosStart: dos.dateFrom,
        dosEnd: dos.dateTo,
        dosSearch: 'A',
        type: filter.claimType,
        // claimBatch: filter.claimBatch,
        releaseStatus: filter.releaseStatus,
        examType: filter.modality,
        providerType: filter.facilityType,
        cpt: filter.procedureCode,
        payerName: filter.primaryPayer,
        signed: filter.isSigned,
        division: filter.division,
      };

      Notification.success(
        'Please wait for a moment, your report will be ready after few moments'
      );

      const response = await apiRequest<string>({
        url: 'facility.ClaimManager.exportReleaseClaims',
        data,
      });

      const link = response.replace(/^\//, '');
      downloadFile(`${BASE_URL_FILE_DIR}${link}`);
    } catch (e: any) {
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeClaimReview = new ClaimReview(storeDivisionSettingsStatus);
