import appDispatcher from 'dispatcher/AppDispatcher';
import { apiRequest } from 'services/RequestService';
import { dateToLocalTimezone } from 'utils/DateUtils';

export default class BriefcasePageActions {
  static loadDirectories() {
    return apiRequest({ url: 'filemanager.FileManager.GetDirListJson' });
  }

  static loadFiles(directoryId: number) {
    return apiRequest({
      url: 'filemanager.FileManager.GetFileList',
      data: [directoryId],
    }).then((response) => {
      const formattedResponse = Array.isArray(response)
        ? response.map((file: any) => ({
            ...file,
            createdDate: dateToLocalTimezone({
              date: file.createdDate,
            }),
            sharedDate: dateToLocalTimezone({
              date: file.sharedDate,
            }),
          }))
        : response;

      appDispatcher.dispatch({
        type: 'briefcase-load-files',
        data: formattedResponse,
        directoryId,
      } as any);

      return formattedResponse;
    });
  }

  static loadDirectoriesAndFiles() {
    return BriefcasePageActions.loadDirectories().then((directories) => {
      appDispatcher.dispatch({
        type: 'briefcase-load-directories',
        data: directories,
      });
    });
  }

  static createNewDirectory(parentFolderId: number, name: string) {
    return apiRequest({
      url: 'filemanager.FileManager.CreateDirectory',
      data: [parentFolderId, name],
    });
  }

  static renameStructure(selected: { refid: number }, newName: string) {
    return apiRequest({
      url: 'filemanager.FileManager.RenameDirectory',
      data: [selected.refid, newName],
    });
  }

  static deleteStructure(selected: { refid: number }) {
    return apiRequest({
      url: 'filemanager.FileManager.deleteStructure',
      data: [selected.refid],
    });
  }

  static share(fileName: number, users: Array<string>) {
    return apiRequest({
      url: 'filemanager.FileManager.share',
      data: [fileName, users],
    });
  }

  static uploadFiles(folderId: number, fileName: string) {
    const data = [
      folderId,
      {
        filestatus: 'N',
        filenm: fileName,
      },
    ];
    return apiRequest<number | string>({
      url: 'filemanager.FileManager.UploadFiles',
      data,
    });
  }

  static getListOfSharingUsers(fileId: number) {
    return apiRequest({
      url: 'filemanager.FileManager.getListOfSharingUsers',
      data: [fileId],
    }).then((users) => {
      appDispatcher.dispatch({
        type: 'briefcase-sharing-users',
        data: users,
      });
      return users;
    });
  }
  static clearListOfSharingUsers() {
    appDispatcher.dispatch({
      type: 'briefcase-sharing-users',
      data: [],
    });
  }

  static removeSharingUser(fileId: number, userId: number) {
    return apiRequest({
      url: 'filemanager.FileManager.removeSharingUser',
      data: [fileId, userId],
    });
  }
}
