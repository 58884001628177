import { makeObservable, action, observable } from 'mobx';

import { FaxModel } from 'page/components/DialogFormTextFax';
import { FormModel as EmailModel } from 'page/patient-records/documentManager/documentAudit/components/DialogFormEmail';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { getDateRangeBounds } from 'utils/DateUtils';

export const filterDefaultValues: FilterType = {
  dosStart: '',
  dosEnd: '',
  facilityId: 0,
  stateId: 0,
  posId: 0,
  orderType: '',
  period: 'A',
  status: '',
  physicianId: null,
  orderStatus: '',
  patientFirstName: '',
  patientLastName: '',
};

export interface FilterType {
  dosStart: string;
  dosEnd: string;
  facilityId: number;
  stateId: number;
  posId: number;
  orderType: string;
  period: string;
  status: string;
  physicianId: number | null;
  orderStatus: string;
  patientFirstName: string;
  patientLastName: string;
}

interface AuditFacilityResponseType {
  admin_email: string;
  bill_email: string;
  bill_fax: string;
  clinical_count: number;
  dos: string;
  facility: string;
  facility_nm: string;
  fax_date: null | string;
  insurance_count: number;
  orderid: string;
  total_ordercount: string;
  unsign_count: number;
}

export interface AuditFacilityType {
  admin_email: string;
  bill_email: string;
  bill_fax: string;
  clinical_count: number;
  dos: string;
  id: number;
  name: string;
  fax_date: string;
  insurance_count: number;
  orderid: number;
  total_ordercount: number;
  unsign_count: number;
}

export interface SendFaxPayloadType {
  fax: FaxModel;
  type: 'F' | 'PHY';
  orders?: any[];
  recipient: {
    refid: number;
    name: string;
    fax: string;
    email: null;
  };
}

export interface SendEmailPayloadType {
  email: EmailModel;
  type: 'F' | 'PHY';
  orders?: any[];
  recipient: {
    refid: number;
    name: string;
    email: string;
    fax: null;
  };
}

class DocManagerAuditFacility {
  fetching: boolean = false;
  auditFacilityEntries: AuditFacilityType[] = [];
  auditFacilityTotal: number = 0;
  filter: FilterType = filterDefaultValues;

  page: Pagination = new Pagination({ id: 'facility_tab_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      auditFacilityEntries: observable,
      auditFacilityTotal: observable,
      filter: observable,

      setFetching: action,
      setAuditEntries: action,
      setFilter: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setAuditEntries({
    entries,
    count,
  }: {
    entries: AuditFacilityType[];
    count: number;
  }) {
    this.auditFacilityEntries = entries;
    this.auditFacilityTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  async getAuditFacility() {
    const {
      filter,
      page: { pagination },
    } = this;

    const { dateFrom, dateTo } = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.facilityId,
      dateFrom,
      dateTo,
      filter.stateId,
      filter.posId,
      filter.orderType,
      filter.physicianId,
      filter.orderStatus,
    ];
    this.setFetching(true);
    try {
      const { count, data: response } = await apiRequest<{
        count: number;
        data: AuditFacilityResponseType[];
      }>({
        url: 'facility.DocumentManager.DocFacilityList',
        data,
      });

      const entries: AuditFacilityType[] = response.map(
        ({ facility_nm, ...el }) => ({
          ...el,
          id: Number(el.facility) || 0,
          name: facility_nm,
          fax_date: el.fax_date || '',
          orderid: Number(el.orderid),
          total_ordercount: Number(el.total_ordercount),
        })
      );

      this.setAuditEntries({ entries, count });
    } catch (e: any) {
      this.setAuditEntries({
        entries: [],
        count: 0,
      });
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeDocManagerAuditFacility = new DocManagerAuditFacility();
