import React from 'react';
import { Container } from 'flux/utils';

import TimeSheetControl from './timesheet/TimeSheetControl';
import TimeSheetCalendar from './timesheet/TimeSheetCalendar';
import TimeSheetDailyNotes from './timesheet/TimeSheetDailyNotes';
import TimeSheetWeeklyComments from './timesheet/TimeSheetWeeklyComments';
import TimeSheetPageActions from 'actions/profile/personal/TimeSheetPageActions';
import TimeSheetPageStore from 'stores/profile/TimeSheetPageStore';
import DateUtils, { daysOfWeek } from 'utils/DateUtils';
import Notification from 'components/modal/Notification';
import UserProfileStore from 'stores/UserProfileStore';
import {
  TTimeSheetCalendar,
  TTrackingData,
  TCalendarData,
} from 'services/profile/personal/TimeSheetPageService';
import { TIME_SHEET as PAGE_ID } from 'constant/pagesId/personalProfile';

interface PTimeSheetPage {
  date?: Date;
  year: number;
  month: number;
  week: number;
  userId: string;
  userName?: string;
  callback?: () => void;
}

class STimeSheetPage {
  year: number = 0;
  month: number = 0;
  week: number = 0;
  userId: string = '';
  calendarLoaded: boolean = false;
  isSubmitted: boolean = false;
  enableSubmit: boolean = false;
  status: -1 | 0 | 1 = -1; //-1 can't clockin, can't clockout. 0 - can clock in, 1 - can clock out
  isLocked: boolean = false;
}

export class TimeSheetPage extends React.Component<
  PTimeSheetPage,
  STimeSheetPage
> {
  static getStores() {
    return [TimeSheetPageStore];
  }

  static calculateState(prevState: STimeSheetPage, props: PTimeSheetPage) {
    if (!prevState) {
      const date: Date = props.date
        ? props.date
        : props.year && props.month
        ? new Date(props.year, props.month - 1)
        : new Date();
      prevState = {
        userId: props.userId
          ? props.userId
          : String(UserProfileStore.getUserId()),
        year: props.year ? props.year : date.getFullYear(),
        month: props.month ? props.month : date.getMonth() + 1,
        week: props.week ? props.week : DateUtils.getWeekNumber(date),
        calendarLoaded: false,
        isSubmitted: false,
        enableSubmit: false,
        status: -1, //-1 can't clockin, can't clockout. 0 - can clock in, 1 - can clock out
        isLocked: false,
      };
    }
    const store = TimeSheetPageStore.getState();

    const newState = prevState.calendarLoaded
      ? {}
      : {
          calendar: store.calendar,
          tracking: store.tracking,
          notes: store.notes,
          status: store.clockIn,
        };

    return { ...prevState, ...newState };
  }

  updateData() {
    const { state } = this;
    this.setState({ calendarLoaded: false }, () =>
      TimeSheetPageActions.loadTimeSheet(
        state.userId,
        state.month,
        state.week,
        state.year
      ).then(() =>
        this.setState({ calendarLoaded: true }, () =>
          this.setDataToComponents()
        )
      )
    );
  }

  setDataToComponents() {
    const store = TimeSheetPageStore.getState();
    const tracking: Array<TTrackingData> = [].concat(store.tracking);
    const calendar: Array<TCalendarData> = [].concat(store.calendar);
    this.getDailyNotes().setData(tracking);
    const newDataSource = this.getCalendar().setData(calendar, tracking);

    let weekNotesList = store.notes.data || [];
    let thisWeekNote = null; //always have one item, with permissions to write comments
    let inDate = false;
    for (let j = 0; j < newDataSource.length; j++) {
      const dayFromGrid = newDataSource[j];
      for (let i = 0; i < weekNotesList.length; i++) {
        const weekNote = weekNotesList[i];
        if (dayFromGrid.day_date === weekNote.notes_date) {
          thisWeekNote = weekNote;
          inDate = true;
          break;
        }
      }
    }
    let timeZone;
    if (inDate) {
      timeZone = thisWeekNote.timezone;
    } else {
      timeZone = store.notes.lastTimezone;
    }
    this.getControl().setData(timeZone);
    this.getWeekComments().setData({
      date: inDate ? thisWeekNote.notes_date : newDataSource[0].day_date,
      comments: inDate ? thisWeekNote.comments : '',
      supervisorComments: inDate ? thisWeekNote.supervisorComments : '',
      canEditComments: store.notes.canEditComments || false,
      canEditSupervisorComments: store.notes.canEditSupervisorComments || false,
    });

    const month4Control = this.state.month;
    let isLocked = false;
    let enableSubmit = false;
    let isSubmitted = null;
    let submittedCounter = 0;
    let monthCheckCounter = 0;
    for (let i = 0; newDataSource && i < newDataSource.length; i++) {
      const day = newDataSource[i];
      if (!daysOfWeek.includes(day.day)) {
        continue;
      }
      if (day.isLocked === 'Y') {
        isLocked = true;
        break;
      }
      const isSubmittedRow = day.isSubmitted === 'Y';
      const date = DateUtils.parseDate(day.day_date, '/');
      const month4Row = date.getMonth().valueOf() + 1;
      if (month4Control === month4Row) {
        monthCheckCounter++;
        enableSubmit = true;
      }
      if (month4Control === month4Row && isSubmittedRow) {
        submittedCounter++;
      }
      if (month4Control === month4Row && !isSubmittedRow) {
        isSubmitted = false;
        break;
      }
    }
    if (isLocked) {
      this.setState({ isSubmitted: true, enableSubmit: false, isLocked: true });
    } else {
      isSubmitted =
        isSubmitted !== null
          ? isSubmitted
          : submittedCounter > 0 && submittedCounter === monthCheckCounter;
      this.setState({
        isSubmitted: isSubmitted,
        enableSubmit: enableSubmit,
        isLocked: false,
      });
    }
  }

  componentDidMount() {
    this.updateData();
  }

  updateCalendar(notUpdateData: boolean = false) {
    const timezone = this.getControl().getTimeZone();
    if (timezone === null) {
      return;
    }
    const calendar: { data: Array<TTimeSheetCalendar>; hasErrors: boolean } =
      this.getCalendar().getData();
    if (calendar.hasErrors) {
      Notification.warning('Please fill all mandatory fields.');
      return null;
    }
    const comments = this.getWeekComments().getData();
    comments.timezone = timezone;

    let updateCalendarDate = {
      year: this.state.year,
      month: this.state.month,
      week: this.state.week,
    };
    let deffered = TimeSheetPageActions.saveTimeSheet(
      this.state.userId,
      calendar.data,
      updateCalendarDate,
      comments
    );
    if (notUpdateData) {
      return deffered;
    } else {
      return deffered.then(() => this.updateData());
    }
  }

  clockIn() {
    TimeSheetPageActions.clockIn().then(() => this.updateData());
  }

  clockOut() {
    TimeSheetPageActions.clockOut().then(() => this.updateData());
  }

  render() {
    const { state, props } = this;
    return (
      <div
        className={
          (state.calendarLoaded ? '' : 'on-ajax ') + 'time-sheet-page'
        }>
        <TimeSheetControl
          ref="control"
          week={state.week}
          updateWeek={(week) => this.updateWeek(week)}
          userName={this.props.userName}
          month={state.month}
          updateMonth={(month) => this.updateMonth(month)}
          year={state.year}
          userId={state.userId}
          updateYear={(year) => this.updateYear(year)}
          status={state.status}
          callback={props.callback}
          clockIn={() => this.clockIn()}
          clockOut={() => this.clockOut()}
          updateCalendar={() => this.updateCalendar()}
          isSubmitted={state.isSubmitted}
          enableSubmit={state.enableSubmit}
          submitTimesheet={(status) => this.submitTimesheet(status)}
          appId={PAGE_ID.PAGE}
          isLocked={state.isLocked}
        />

        <TimeSheetCalendar
          ref="timeSheetCalendar"
          userName={this.props.userName}
          getComments={() => this.getWeekComments().getData()}
          userId={state.userId}
          week={state.week}
          month={state.month}
          appId={PAGE_ID.PAGE}
          year={state.year}
        />

        <div className="row">
          <TimeSheetDailyNotes
            ref="timeSheetDailyNotes"
            week={state.week}
            month={state.month}
            year={state.year}
          />
          <TimeSheetWeeklyComments ref="timeSheetWeeklyComments" />
        </div>
      </div>
    );
  }

  submitTimesheet(status: boolean) {
    this.setState({ calendarLoaded: false }, () => {
      const deffered = this.updateCalendar(true);
      if (deffered) {
        deffered
          .then(() => {
            const calendar = this.getCalendar().getData();
            if (calendar.hasErrors) {
              Notification.danger(
                "Can't " +
                  (status ? 'submit' : 'unsubmit') +
                  ' calendar, because of errors',
                { autoClose: 10000 }
              );
              return;
            }
            const firstDay = calendar.data[0].day_date;
            const lastDay = calendar.data[calendar.data.length - 1].day_date;
            return TimeSheetPageActions.submitTimeSheet(
              this.state.userId,
              status,
              firstDay,
              lastDay
            );
          })
          .then(() => this.updateData());
      } else {
        Notification.danger(
          "Can't " +
            (status ? 'submit' : 'unsubmit') +
            ' calendar, because of errors.',
          { autoClose: 10000 }
        );
        this.setState({ calendarLoaded: true });
      }
    });
  }

  updateWeek(week: number) {
    this.setState({ week: week }, () => this.setDataToComponents());
  }

  updateMonth(month: number) {
    const { state } = this;
    const date = new Date(state.year, month - 1);
    const weekMax = DateUtils.getWeekMaxNumber(date);
    const week =
      state.month < month ? 0 : state.month > month ? weekMax : state.week;
    this.setState({ month: month, week: week }, () => this.updateData());
  }

  updateYear(year: number) {
    const { state } = this;
    const week = state.year !== year ? 0 : state.week;
    this.setState({ year: year, week: week }, () => this.updateData());
  }

  getCalendar(): TimeSheetCalendar {
    return this.refs.timeSheetCalendar as TimeSheetCalendar;
  }

  getWeekComments(): TimeSheetWeeklyComments {
    return this.refs.timeSheetWeeklyComments as TimeSheetWeeklyComments;
  }

  getDailyNotes(): TimeSheetDailyNotes {
    return this.refs.timeSheetDailyNotes as TimeSheetDailyNotes;
  }

  getControl(): TimeSheetControl {
    return this.refs.control as TimeSheetControl;
  }
}

export default Container.create(TimeSheetPage, { withProps: true });
