import { observable, action, makeObservable, runInAction } from 'mobx';

import { TPagination } from 'components/grid/GridTypes';
import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

interface MobileOperatorType {
  carrier_format: string;
  mobile_operator: string;
  refid: number;
  sms_limit: number;
}

interface NewOperatorType {
  carrier_format: string;
  mobile_operator: string;
  sms_limit: number;
}

interface OperatorEditableType extends NewOperatorType {
  refid: number | string;
}

interface OptionResponseType {
  carrFormat: string;
  data: string;
  label: string;
}

interface OptionType {
  value: number;
  label: string;
}

class MobileOperator {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  mobileOperatorsList: MobileOperatorType[] = [];
  mobileOperatorsTotal: number = 0;
  mobileOperator: OperatorEditableType | undefined = undefined;
  operatorIdForDelete: null | number = null;
  options: OptionType[] = [];

  page: Pagination = new Pagination({ id: 'mobileOperatorGrid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      mobileOperatorsList: observable,
      mobileOperatorsTotal: observable,
      mobileOperator: observable,
      operatorIdForDelete: observable,
      options: observable,

      setFetching: action,
      setFetchingOptions: action,
      setMobileOperatorsList: action,
      setMobileOperatorsTotal: action,
      setOperator: action,
      setIdForDelete: action,
      clearIdForDelete: action,
      clearMobileOperator: action,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFetchingOptions = (fetching: boolean) => {
    this.fetchingOptions = fetching;
  };

  setMobileOperatorsList = (list: MobileOperatorType[]) => {
    this.mobileOperatorsList = list;
  };

  setMobileOperatorsTotal = (count: number) => {
    this.mobileOperatorsTotal = count;
  };

  setOperator = (operator: OperatorEditableType) => {
    this.mobileOperator = operator;
  };

  setIdForDelete = (id: number) => {
    this.operatorIdForDelete = id;
  };

  clearIdForDelete = () => {
    this.operatorIdForDelete = null;
  };

  clearMobileOperator = () => {
    this.mobileOperator = undefined;
  };

  getMobileOperatorCount = async (operatorName: string) => {
    try {
      const total = await apiRequest<string>({
        url: 'generalmaster.MobileOperatorMaster.TotalRecordCount',
        data: [operatorName],
      });
      this.setMobileOperatorsTotal(Number(total));
    } catch (e) {}
  };

  getMobileOperators = async ({
    pagination,
    operatorName,
  }: {
    pagination: TPagination;
    operatorName: string;
  }) => {
    try {
      const list = await apiRequest<Array<MobileOperatorType>>({
        url: 'generalmaster.MobileOperatorMaster.GetMobileOperatorDetails',
        data: [pagination.skip, pagination.pageSize, operatorName],
      });

      this.setMobileOperatorsList(list);
    } catch (e) {}
  };

  getMobileOperatorsList = (operatorName: string) => {
    this.setFetching(true);

    const props = {
      pagination: this.page.pagination,
      operatorName,
    };

    const promiseList = this.getMobileOperators(props);

    const promiseCount = this.getMobileOperatorCount(props.operatorName);

    Promise.allSettled([promiseList, promiseCount]).finally(() => {
      this.setFetching(false);
    });
  };

  getOperator = async (id: string | number) => {
    this.setFetching(true);
    try {
      const operator = await apiRequest<[OperatorEditableType]>({
        url: 'generalmaster.MobileOperatorMaster.GetMobileOperatorById',
        data: [id],
      });

      this.setOperator(operator[0]);
    } catch (e) {
    } finally {
      this.setFetching(false);
    }
  };

  addMobileOperator = async (payload: NewOperatorType) => {
    const data = [
      payload.mobile_operator,
      payload.carrier_format,
      payload.sms_limit,
    ];
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.MobileOperatorMaster.AddMobileOperatorDetails',
        data,
      });
      if (result === 'S') {
        Notification.success('Mobile operator was added successfully!');
        return true;
      }
      throw Error('An error occurred!');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  };

  updateMobileOperator = async (payload: OperatorEditableType) => {
    const data = [
      payload.refid,
      payload.mobile_operator,
      payload.carrier_format,
      payload.sms_limit,
    ];
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.MobileOperatorMaster.UpdateMobileOperatorDetails',
        data,
      });
      if (result === 'S') {
        Notification.success('Mobile operator was updated successfully!');
        return true;
      }
      throw Error('An error occurred!');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  };

  deleteMobileOperator = async () => {
    this.setFetching(true);
    try {
      if (!this.operatorIdForDelete) {
        Notification.warning('Mobile operator not selected for delete');
        return false;
      }

      const result = await apiRequest<'S'>({
        url: 'generalmaster.MobileOperatorMaster.DeleteMobileOperator',
        data: [this.operatorIdForDelete],
      });
      if (result === 'S') {
        Notification.success('Mobile operator was deleted!');
        this.clearIdForDelete();
        return true;
      } else {
        throw Error('');
      }
    } catch (error) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  };

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOptions(true);

    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.MobileOperatorMaster.MobileOperatorDropDown',
      });

      const options = response.map((option) => ({
        label: option.label,
        value: Number(option.data),
      }));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storeMobileOperator = new MobileOperator();
