import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import FormService from './components/FormService';

import {
  storeCheckOutIn,
  RequestServiceFormType,
} from 'stores/_mobx/workflow/checkOutIn/checkOutIn';
import { storePhone } from 'stores/_mobx/workflow/equipmentInventory/phone';
import { storeVehicle } from 'stores/_mobx/workflow/equipmentInventory/vehicle';
import { storeBoneSonometer } from 'stores/_mobx/workflow/equipmentInventory/boneSonometer';
import { storeXRayUnit } from 'stores/_mobx/workflow/equipmentInventory/xRayUnit';
import { storeEkg } from 'stores/_mobx/workflow/equipmentInventory/ekg';

const RequestServiceTab = () => {
  const { options: phoneOptions, fetchingOptions: fetchingPhoneOptions } =
    storePhone;
  const { options: vehicleOptions, fetchingOptions: fetchingVehicleOptions } =
    storeVehicle;
  const { options, fetchingOptions } = storeBoneSonometer;
  const { options: optionsXRay, fetchingOptions: fetchingXRayOptions } =
    storeXRayUnit;
  const { options: optionsEkg, fetchingOptions: fetchingEkgOptions } = storeEkg;

  const { sendingServiceRequest } = storeCheckOutIn;

  const isFetching =
    fetchingPhoneOptions ||
    fetchingVehicleOptions ||
    fetchingXRayOptions ||
    fetchingEkgOptions ||
    fetchingOptions ||
    sendingServiceRequest;

  const getDropdownsOptions = () => {
    storePhone.getOptions();
    storeVehicle.getOptions();
    storeBoneSonometer.getOptions();
    storeXRayUnit.getOptions();
    storeEkg.getOptions();
  };

  const handleSubmit = (data: RequestServiceFormType) =>
    storeCheckOutIn.sendServiceRequest(data).then((isSucceed) => {
      if (isSucceed) {
        getDropdownsOptions();
      }
      return isSucceed;
    });

  useEffect(() => {
    getDropdownsOptions();
  }, []);

  return (
    <>
      <FormService
        phoneOptions={phoneOptions}
        vehicleOptions={vehicleOptions}
        boneSonometerOptions={options}
        optionsXRay={optionsXRay}
        optionsEkg={optionsEkg}
        onSubmit={handleSubmit}
      />
      {isFetching && <SpinnerFixed />}
    </>
  );
};

export default observer(RequestServiceTab);
