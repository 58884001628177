import React from 'react';
import { Container } from 'flux/utils';

import AbstractOrderOverview, {
  PAbstractOrderOverview,
  SAbstractOrderOverview,
  TFilter,
} from 'page/workflow/order/AbstractOrderOverview';
import OrderOverviewStore from 'stores/workflow/order/OrderOverviewStore';
import UserProfileStore from 'stores/UserProfileStore';
import WorkflowOrderColumnsDescriptor from 'page/workflow/order/WorkflowOrderColumnsDescriptor';
import { GridControlButton } from 'components/grid';
import { storeGrid } from 'stores/_mobx/grid';
import { URL_ORDER_ADD } from 'constant/path/workflow';

const GRID_ID = 'placed_orders_grid';

export interface PPlacedOrder extends PAbstractOrderOverview {}

export class SPlacedOrder extends SAbstractOrderOverview {}

export class PlacedOrder extends AbstractOrderOverview<
  PPlacedOrder,
  SPlacedOrder
> {
  static TITLE = 'Placed Orders';

  static getStores() {
    return [OrderOverviewStore];
  }

  static calculateState(prevState: SPlacedOrder) {
    if (!prevState) {
      const defaultFilter = {
        ...new TFilter(),
        period: 'A',
        dosStart: '',
        dosEnd: '',
      };

      prevState = AbstractOrderOverview.getDefaultState(GRID_ID);
      prevState.dosEditable = true;
      prevState.filter = storeGrid.getFilter(GRID_ID, defaultFilter) as TFilter;
    }
    return {
      ...prevState,
      dataSource: OrderOverviewStore.getState().dataSource,
      dataSourceCount: OrderOverviewStore.getState().dataSourceCount,
    };
  }

  getGridId() {
    return GRID_ID;
  }

  getTitle() {
    return PlacedOrder.TITLE;
  }

  getShownColumns() {
    return Object.assign([], WorkflowOrderColumnsDescriptor.PlacedOrderInitial);
  }

  getAllowedColumns() {
    let out: Array<string>;
    switch (UserProfileStore.getUserType()) {
      case 'CA': //corporate medical
        out = WorkflowOrderColumnsDescriptor.PlacedOrderCorporateMedical;
        break;
      case 'D': //Physician
        out = WorkflowOrderColumnsDescriptor.PlacedOrderPhysician;
        break;
      case 'N': //NURSE
        out = WorkflowOrderColumnsDescriptor.PlacedOrderNurse;
        break;
      case 'A': //facility admin
        out = WorkflowOrderColumnsDescriptor.PlacedOrderFacilityAdmin;
        break;
      case 'FC': //facility billing coordinator
        out =
          WorkflowOrderColumnsDescriptor.PlacedOrderFacilityBillingCoordinator;
        break;
      default:
        out = WorkflowOrderColumnsDescriptor.PlacedOrderDefault;
    }
    out = Object.assign([], out);

    if (UserProfileStore.isClientUser()) {
      out = out.filter((v) => v !== 'assign');
    }
    return out;
  }

  getControlPanel = () => {
    const userType = UserProfileStore.getUserType();

    const userIsRegular = UserProfileStore.isClientUser();

    const isUserHavePermissionForAssign = !(
      userType === 'CA' ||
      userType === 'N' ||
      userIsRegular
    );

    return (
      <>
        <GridControlButton title="Place Order" url={URL_ORDER_ADD} />
        {isUserHavePermissionForAssign && (
          <GridControlButton
            title="Assign"
            disabled={!this.state.selected.length}
            onClick={this.onAssignOrder.bind(this)}
          />
        )}
      </>
    );
  };

  renderOrderStatus(): React.ReactNode {
    return null;
  }

  getRecordsAccess(): 'M' | 'A' {
    return 'A'; // 'M' or 'A' only
  }

  onReset() {
    const filter = {
      ...new TFilter(),
      period: 'A',
      dosStart: '',
      dosEnd: '',
    };
    this.setState({ filter }, this.updateData);
  }
}

export default Container.create(PlacedOrder);
