import { OrderDetailsType } from 'stores/_mobx/billingCodding/claimReview';

interface PropTypes {
  orderDetails: OrderDetailsType | null;
}

const TabInsuranceInfo = ({ orderDetails }: PropTypes) => (
  <>
    <div className="row">
      <b className="col-sm-5 col-md-3">Batch #:</b>
      <span className="col-sm-7 col-md-9">{orderDetails?.batch_no}</span>
    </div>
    <div className="row mb-5">
      <b className="col-sm-5 col-md-3">Release Date:</b>
      <span className="col-sm-7 col-md-9">
        {orderDetails?.release_dt}
      </span>
    </div>

    <div className="row my-3">
      <b className="col-sm-3 offset-sm-3">Payer Name</b>
      <b className="col-sm-3">Policy ID</b>
    </div>
    <div className="row">
      <b className="col-sm-3">Primary Insurance:</b>
      <span className="col-sm-3">{orderDetails?.primary_payer_name}</span>
      <span className="col-sm-3">{orderDetails?.primary_plan_name}</span>
    </div>
    <div className="row">
      <b className="col-sm-3">Secondary Insurance:</b>
      <span className="col-sm-3">{orderDetails?.secondary_payer_name}</span>
      <span className="col-sm-3">{orderDetails?.secondary_plan_name}</span>
    </div>
  </>
);

export default TabInsuranceInfo;
