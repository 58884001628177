import { GridControlButton } from 'components/grid';

import AccessUtils from 'utils/AccessUtils';
import { CODES_SET_DIAGNOSIS as PAGE_ID } from 'constant/pagesId/systemSetup';

interface ControlProps {
  isActive: boolean;
  isButtonDisabled: boolean;
  basicUrl: string;
  onChangeStatus: () => void;
  onClickExport: () => void;
  onClickImport: () => void;
}

const DiagnosisControlPanel = ({
  isActive,
  isButtonDisabled,
  basicUrl,
  onChangeStatus,
  onClickExport,
  onClickImport,
}: ControlProps) => (
  <>
    {AccessUtils.checkAccess(PAGE_ID.ADD) && (
      <GridControlButton title="Add" url={`${basicUrl}/new`} />
    )}
    {AccessUtils.checkAccess(
      isActive ? PAGE_ID.DEACTIVATE : PAGE_ID.ACTIVATE
    ) && (
      <GridControlButton
        title={isActive ? 'Deactivate' : 'Activate'}
        onClick={onChangeStatus}
        disabled={isButtonDisabled}
      />
    )}
    <GridControlButton
      title="Export"
      disabled={isButtonDisabled}
      onClick={onClickExport}
    />
    {AccessUtils.checkAccess(PAGE_ID.IMPORT) && (
      <GridControlButton title="Import" onClick={onClickImport} />
    )}
  </>
);

export default DiagnosisControlPanel;
