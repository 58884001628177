import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import { LayoutSideTitle } from 'components/layout';
import MessageViewer from '../components/MessageViewer';

import { storeReminder } from 'stores/_mobx/message/reminder';
import { URL_REMINDER } from 'constant/path/messages';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const ReminderPreviewPage = ({ match: { params } }: PropsType) => {
  const { fetching, reminderView } = storeReminder;

  useEffect(() => {
    storeReminder.getReminderView(Number(params.id));

    return storeReminder.clearReminderView;
  }, [params.id]);

  return (
    <>
      <LayoutSideTitle title="Remainder Message">
        <Link to={URL_REMINDER} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      {reminderView && <MessageViewer email={reminderView} />}
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(ReminderPreviewPage);
