import { apiRequest } from 'services/RequestService';
import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';
import { dateToLocalTimezone } from 'utils/DateUtils';
export class TEquipValues {
  refid: number = null;
  visit: string = '';
  order_id: number = null;
  patient_proc: string = '';
  views: string = '';
  time: string = '';
  tech_flag: string = '';
  assited_by: string = '';
  clinic_notes: string = '';
  radiologygroupid: number = null;
  radiologygroupnm: string = '';
  reasonid: number = null;
  rejectimgno: string = '';
  arrival_dt: string = '';
}

export class TResEquipValues {
  arrivalDate: string = '';
  arrivalTime: string = '';
  submittedImages: string = '';
  assistedBy: string = '';
  rejectedImages: string = '';
  radiologyGroup: number = null;
  rejectedReasonId: number = null;
  flagOrder: string = '';
  additionalClinicalNotes: string = '';
  visitSchedule: string = '';
}

class VisitNotesService {
  loadNotes(orderId: number): Promise<TResEquipValues> {
    return apiRequest<'S' | Array<TEquipValues>>({
      url: 'patient.PatientInfo.GetEquipValues',
      data: [orderId],
    }).then((response) => {
      if (response === 'S') {
        return null;
      }
      const visitNotesItem = response[0];
      return {
        arrivalDate: visitNotesItem.arrival_dt,
        arrivalTime: visitNotesItem.time,
        submittedImages: visitNotesItem.views,
        assistedBy: visitNotesItem.assited_by,
        rejectedImages: visitNotesItem.rejectimgno,
        radiologyGroup: visitNotesItem.radiologygroupid,
        rejectedReasonId: visitNotesItem.reasonid,
        flagOrder: visitNotesItem.tech_flag,
        additionalClinicalNotes: visitNotesItem.clinic_notes,
        visitSchedule: visitNotesItem.visit,
      };
    });
  }

  getVisitNotesForOrder(order: TSignOrderItem): Promise<any> {
    return apiRequest<'S' | Array<TEquipValues>>({
      url: 'patient.PatientInfo.GetEquipValues',
      data: [Number(order.refid)],
    }).then((response) => {
      if (response === 'S') {
        const [arrivalDate, arrivalTime] = dateToLocalTimezone({
          date: new Date().toISOString(),
        }).split(' ');

        return {
          arrivalDate,
          arrivalTime,
          submittedImages: '',
          visitSchedule: '',
          radiologyGroup: Number(order.radiologygrouprefid) || 0,
          rejectedImages: '',
          rejectedReasonId: 0,
          assistedBy: '',
          additionalClinicalNotes: '',
          groupAffiliation: order.group_affiliation_name,
          media_type: order.order_media_type,
          modality: order.examtype,
          modalityId: Number(order.examtypeid),
          phyid: Number(order.providerid),
          physicianname: order.physicianname,
          cptCodes: order.cptCodes,
          icdCodes: order.icd,
          orderId: Number(order.refid),
          techId: Number(order.techid),
          dos: order.dos,
        };
      }
      const visit = response[0];

      const date = dateToLocalTimezone({
        date: `${visit.arrival_dt} ${visit.time}`,
      });

      const [arrivalDate, arrivalTime] = date ? date.split(' ') : ['', ''];

      return {
        arrivalDate,
        arrivalTime,
        submittedImages: visit.views,
        visitSchedule: visit.visit,
        radiologyGroup: Number(visit.radiologygroupid),
        rejectedImages: visit.rejectimgno,
        rejectedReasonId: Number(visit.reasonid),
        assistedBy: visit.assited_by,
        additionalClinicalNotes: visit.clinic_notes,
        groupAffiliation: order.group_affiliation_name,
        media_type: order.order_media_type,
        modality: order.examtype,
        phyid: Number(order.providerid),
        physicianname: order.physicianname,
        cptCodes: order.cptCodes,
        icdCodes: order.icd,
        orderId: Number(order.refid),
        techId: Number(order.techid),
        dos: order.dos,
      };
    });
  }
}

export default new VisitNotesService();
