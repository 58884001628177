import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';

import { storeMobileOperator } from 'stores/_mobx/systemSetup/masterSetting/mobileOperator';
import { MS_MOBILE_OPERATOR as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_MOBILE_OPERATOR_ADD,
  URL_MOBILE_OPERATOR_EDIT,
} from 'constant/path/systemSetup';

const renderControl = (id: number) => (
  <div className="control">
    <Link
      className="bi bi-pencil"
      to={URL_MOBILE_OPERATOR_EDIT.replace(':id', `${id}`)}
    />
    <IconButton onClick={(event) => storeMobileOperator.setIdForDelete(id)}>
      <i className="bi bi-trash" />
    </IconButton>
  </div>
);

const COLUMNS = [
  { name: 'mobile_operator', title: 'Mobile Carrier' },
  { name: 'carrier_format', title: 'Carrier Format' },
  {
    name: 'sms_limit',
    title: 'SMS Text Limit',
    render: (v: string) => Number(v) || 'Unlimited',
  },
  {
    name: 'refid',
    title: 'Action',
    className: 'width-75',
    render: renderControl,
  },
];

const MobileOperatorListPage = () => {
  const {
    fetching,
    mobileOperatorsList,
    mobileOperatorsTotal,
    operatorIdForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeMobileOperator;

  const [operatorName, setOperatorName] = useState<string>('');

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleChangeFilter = (operatorName: string) => {
    setOperatorName(operatorName);
    debouncedMethod();
  };

  const handleApproveDelete = () => {
    storeMobileOperator.deleteMobileOperator().then((result) => {
      if (result) {
        storeMobileOperator.getMobileOperatorsList(operatorName);
      }
    });
  };

  useEffect(() => {
    storeMobileOperator.getMobileOperatorsList(operatorName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Mobile Operator">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Mobile Operator"
        className="col-sm-12 col-md-6 col-lg-4"
        name="mobileOperator"
        value={operatorName}
        onChange={handleChangeFilter}
      />

      <Grid
        id="mobileOperatorGrid"
        columns={COLUMNS}
        onAjax={fetching}
        dataSource={mobileOperatorsList}
        dataSourceCount={mobileOperatorsTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_MOBILE_OPERATOR_ADD} />
        }
      />
      {operatorIdForDelete !== null && (
        <DialogConfirm
          onCancel={storeMobileOperator.clearIdForDelete}
          onApprove={handleApproveDelete}>
          Are you sure you want to delete?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(MobileOperatorListPage);
