import { action, makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionsResponse {
  data: string;
  label: string;
}

export interface OptionType {
  value: number;
  label: string;
}

export class DropdownCorporation {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,

      setFetching: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  async getOptions() {
    if (this.fetching) return Promise.resolve();

    this.setFetching(true);

    try {
      const list = await apiRequest<OptionsResponse[]>({
        url: 'groupmanager.CorporateGroup.corporateGroupDropDown',
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));
      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }

  findOptionById = (id: number) =>
    this.options.find(({ value }) => value === id);
}

export const storeDropdownCorporation = new DropdownCorporation();
