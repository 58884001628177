import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import FormEmailTemplate, { FormModel } from './components/Form';

import { storeEmailTemplate } from 'stores/_mobx/systemSetup/masterSetting/emailTemplate';
import { URL_EMAIL_TEMPLATE } from 'constant/path/systemSetup';

export const URL_EMAIL_TEMPLATE_EDIT = `${URL_EMAIL_TEMPLATE}/edit/:id`;

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const EmailTemplateEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, template, descriptions } = storeEmailTemplate;

  const handleSubmit = (data: FormModel) =>
    storeEmailTemplate.updateTemplate(data).then((response) => {
      if (response) return response;
      history.push(URL_EMAIL_TEMPLATE);
      return null;
    });

  useEffect(() => {
    storeEmailTemplate.getTemplate(params.id);

    return storeEmailTemplate.clearTemplate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Email Template" />
      {fetching ? (
        <SpinnerFixed />
      ) : (
        <FormEmailTemplate
          backUrl={URL_EMAIL_TEMPLATE}
          defaultValues={template}
          descriptions={descriptions}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default observer(EmailTemplateEditPage);
