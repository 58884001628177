import { Switcher } from 'components/form/switcher';
import { useForm } from 'react-hook-form';
import { UpdateDFTSettingsPayload } from 'stores/_mobx/systemSetup/masterSetting/integrationsDft';
import Channels from './Channels';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { observer } from 'mobx-react-lite';

interface PropsType {
  fetching?: boolean;
  defaultValues?: UpdateDFTSettingsPayload;
  onSubmit: (payload: UpdateDFTSettingsPayload) => Promise<any>;
}

const Form = ({ fetching, defaultValues, onSubmit }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    watch,
  } = useForm<UpdateDFTSettingsPayload>({
    defaultValues,
  });
  const handleClickSubmit = handleSubmit((data: UpdateDFTSettingsPayload) =>
    onSubmit(data)
  );
  return (
    <form
      className={fetching || isSubmitting ? 'on-ajax' : undefined}
      onSubmit={handleClickSubmit}>
      <legend>Outbound DFT</legend>
      <small className="mb-2 d-block text-secondary">
        Select events to send DFT messages when
      </small>
      <Switcher
        name="sendOnBillingCompleted"
        label="Send on Billing completed"
        control={control}
      />
      <Channels control={control} watch={watch} />
      <ControlsLayout>
        <Button type="submit" text="Save" disabled={fetching || !isDirty} />
      </ControlsLayout>
    </form>
  );
};

export default observer(Form);
