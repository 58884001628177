import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { Checkbox } from 'components/form/checkbox';
import { Select } from 'components/form/select';
import { Textarea } from 'components/form/textarea';
import { ControlsLayout } from 'components/layout';
import { validationRequestService as resolver } from './validation';

import { RequestServiceFormType } from 'stores/_mobx/workflow/checkOutIn/checkOutIn';

const defaultValues: RequestServiceFormType = {
  xRayUnit: {
    isActive: false,
    deviceId: '',
    details: '',
  },
  ekg: {
    isActive: false,
    deviceId: 0,
    details: '',
  },
  phone: {
    isActive: false,
    deviceId: 0,
    details: '',
  },
  vehicle: {
    isActive: false,
    deviceId: 0,
    details: '',
  },
  boneSonometer: {
    isActive: false,
    deviceId: 0,
    details: '',
  },
};

interface OptionType {
  label: string;
  value: number;
}

interface PropsType {
  phoneOptions: OptionType[];
  vehicleOptions: OptionType[];
  boneSonometerOptions: OptionType[];
  optionsEkg: OptionType[];
  optionsXRay: { label: string; value: string }[];
  onSubmit: (payload: RequestServiceFormType) => Promise<boolean>;
}

const FormService = (props: PropsType) => {
  const {
    control,
    formState: { isDirty },
    reset,
    watch,
    handleSubmit,
  } = useForm<RequestServiceFormType>({
    defaultValues,
    resolver,
  });

  const [
    isXRayActive,
    isEkgActive,
    isPhoneActive,
    isVehicleActive,
    isBoneSonometerActive,
  ] = watch([
    'xRayUnit.isActive',
    'ekg.isActive',
    'phone.isActive',
    'vehicle.isActive',
    'boneSonometer.isActive',
  ]);

  const isAnyDeviceSelected =
    isXRayActive ||
    isEkgActive ||
    isPhoneActive ||
    isVehicleActive ||
    isBoneSonometerActive;

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = (data: RequestServiceFormType) =>
    props.onSubmit(data).then((isSucceed) => {
      if (isSucceed) handleClickReset();
    });

  return (
    <form
      className="col-lg-10 col-xl-8"
      onSubmit={handleSubmit(handleClickSubmit)}>
      <div className="row mb-4">
        <div className="col-md-6 col-lg-4">
          <Checkbox
            name="xRayUnit.isActive"
            label="X-Ray Unit"
            control={control}
          />
          <Select
            name="xRayUnit.deviceId"
            label="Select a device"
            disabled={!isXRayActive}
            required={isXRayActive}
            options={props.optionsXRay}
            control={control}
          />
        </div>
        <Textarea
          name="xRayUnit.details"
          label="Service details"
          className="col-md-6 col-lg-8"
          rows={3}
          disabled={!isXRayActive}
          required={isXRayActive}
          control={control}
        />
      </div>

      <div className="row mb-4">
        <div className="col-md-6 col-lg-4">
          <Checkbox name="ekg.isActive" label="EKG" control={control} />
          <Select
            name="ekg.deviceId"
            label="Select a device"
            disabled={!isEkgActive}
            required={isEkgActive}
            options={props.optionsEkg}
            control={control}
          />
        </div>
        <Textarea
          name="ekg.details"
          label="Service details"
          className="col-md-6 col-lg-8"
          rows={3}
          disabled={!isEkgActive}
          required={isEkgActive}
          control={control}
        />
      </div>

      <div className="row mb-4">
        <div className="col-md-6 col-lg-4">
          <Checkbox name="phone.isActive" label="Phone" control={control} />
          <Select
            name="phone.deviceId"
            label="Select a device"
            disabled={!isPhoneActive}
            required={isPhoneActive}
            options={props.phoneOptions}
            control={control}
          />
        </div>
        <Textarea
          name="phone.details"
          label="Service details"
          className="col-md-6 col-lg-8"
          rows={3}
          disabled={!isPhoneActive}
          required={isPhoneActive}
          control={control}
        />
      </div>

      <div className="row mb-4">
        <div className="col-md-6 col-lg-4">
          <Checkbox name="vehicle.isActive" label="Vehicle" control={control} />
          <Select
            name="vehicle.deviceId"
            label="Select a device"
            disabled={!isVehicleActive}
            required={isVehicleActive}
            options={props.vehicleOptions}
            control={control}
          />
        </div>
        <Textarea
          name="vehicle.details"
          label="Service details"
          className="col-md-6 col-lg-8"
          rows={3}
          disabled={!isVehicleActive}
          required={isVehicleActive}
          control={control}
        />
      </div>

      <div className="row mb-4">
        <div className="col-md-6 col-lg-4">
          <Checkbox
            name="boneSonometer.isActive"
            label="Bone Sonometer"
            control={control}
          />
          <Select
            name="boneSonometer.deviceId"
            label="Select a device"
            disabled={!isBoneSonometerActive}
            required={isBoneSonometerActive}
            options={props.boneSonometerOptions}
            control={control}
          />
        </div>
        <Textarea
          name="boneSonometer.details"
          label="Service details"
          className="col-md-6 col-lg-8"
          rows={3}
          disabled={!isBoneSonometerActive}
          required={isBoneSonometerActive}
          control={control}
        />
      </div>
      <ControlsLayout alignX="right">
        <Button text="Reset" variant="warning" onClick={handleClickReset} />
        <Button
          type="submit"
          text="Save"
          disabled={!isDirty || !isAnyDeviceSelected}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormService;
