import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import {
  URL_INTEGRATION_MASTER_NEW,
  URL_INTEGRATION_MASTER_NEW_DFT,
  URL_MASTER_SETTINGS,
} from 'constant/path/systemSetup';

interface MenuResponseType {
  app_id: string;
  app_name: string;
  img_url: string;
  refid: string;
}

interface MenuType extends Omit<MenuResponseType, 'refid'> {
  refid: number;
  link: string;
}

type RequestPayloadType = [number, string, number];

class MasterSettingsMenu {
  fetching: boolean = false;
  systemSetting: MenuType[] = [];
  billing: MenuType[] = [];
  operation: MenuType[] = [];
  hl7Integrations: MenuType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      systemSetting: observable,
      billing: observable,
      operation: observable,

      setFetching: action,
      setSystemSettingMenu: action,
      setBillingMenu: action,
      setOperationMenu: action,
      setHL7IntegrationsMenu: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setSystemSettingMenu(menu: MenuType[]) {
    this.systemSetting = menu;
  }

  setBillingMenu(menu: MenuType[]) {
    this.billing = menu;
  }

  setOperationMenu(menu: MenuType[]) {
    this.operation = menu;
  }

  setHL7IntegrationsMenu(menu: MenuType[]) {
    this.hl7Integrations = menu;
  }

  async getSystemSettingMenu(data: RequestPayloadType) {
    if (this.systemSetting.length) return Promise.resolve();

    try {
      const response = await apiRequest<MenuResponseType[]>({
        url: 'userlogin.LoginMaster.GetSetupMaster',
        data,
      });

      const menu = response
        .filter(({ app_id }) => app_id !== 'AppMaster')
        .map((item) => ({
          ...item,
          link: `${URL_MASTER_SETTINGS}/${item.app_id.toLowerCase()}`,
          refid: Number(item.refid),
        }));

      this.setSystemSettingMenu(menu);
    } catch (e: any) {
      this.setSystemSettingMenu([]);
    }
  }

  async getBillingMenu(data: RequestPayloadType) {
    if (this.billing.length) return Promise.resolve();

    try {
      const response = await apiRequest<MenuResponseType[]>({
        url: 'userlogin.LoginMaster.GetBillingGroup',
        data,
      });

      const menu = response.map((item) => ({
        ...item,
        link: `${URL_MASTER_SETTINGS}/${item.app_id.toLowerCase()}`,
        refid: Number(item.refid),
      }));

      this.setBillingMenu(menu);
    } catch (e: any) {
      this.setBillingMenu([]);
    }
  }

  async getOperationMenu(data: RequestPayloadType) {
    try {
      const response = await apiRequest<MenuResponseType[]>({
        url: 'userlogin.LoginMaster.GetOperationGroup',
        data,
      });

      const menu = response.map((item) => ({
        ...item,
        link: `${URL_MASTER_SETTINGS}/${item.app_id.toLowerCase()}`,
        refid: Number(item.refid),
      }));

      this.setOperationMenu(menu);
    } catch (e: any) {
      this.setOperationMenu([]);
    }
  }

  async getHL7IntegrationsMenu(): Promise<void> {
    this.setHL7IntegrationsMenu([
      {
        app_id: 'integrations-master',
        app_name: 'ORM, ORU and ADT',
        img_url: 'assets/icons/integrations_master.png',
        link: `${URL_INTEGRATION_MASTER_NEW}`,
        refid: Math.random(),
      },
      {
        app_id: 'integrations-master-dft',
        app_name: 'DFT',
        img_url: 'assets/icons/integrations_master.png',
        link: `${URL_INTEGRATION_MASTER_NEW_DFT}`,
        refid: Math.random(),
      },
    ]);
  }

  getMenu() {
    const data: RequestPayloadType = [
      UserProfileStore.getUserId(),
      UserProfileStore.getUserType(),
      UserProfileStore.getUserTypeId(),
    ];

    this.setFetching(true);

    const promiseSystemSetting = this.getSystemSettingMenu(data);
    const promiseBilling = this.getBillingMenu(data);
    const promiseOperation = this.getOperationMenu(data);
    const promiseHL7 = this.getHL7IntegrationsMenu();

    Promise.allSettled([
      promiseSystemSetting,
      promiseBilling,
      promiseOperation,
      promiseHL7,
    ]).finally(() => {
      this.setFetching(false);
    });
  }
}

export const storeMasterSettingsMenu = new MasterSettingsMenu();
