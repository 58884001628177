import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import Tooltip from 'components/tooltip';
import Filter from './components/Filter';

import {
  storeRejectReasonReport,
  FilterType,
} from 'stores/_mobx/workflow/serviceMaintenance/rejectReasonReport';
import { REJECT_REASON as PAGE_ID } from 'constant/pagesId/workflow';

const columns = [
  { name: 'order_id', title: 'Accession No.' },
  { name: 'pt_firstname', title: 'First Name' },
  { name: 'pt_lastname', title: 'Last Name' },
  { name: 'facility_name', title: 'Facility' },
  { name: 'modality', title: 'Modality', className: 'text-center' },
  { name: 'dos', title: 'DOS', className: 'text-nowrap', dataType: 'date' },
  { name: 'tech_name', title: 'Technologist' },
  {
    name: 'cptdesc',
    className: 'text-center',
    title: 'CPT Description',
    render: (code: string) =>
      code ? (
        <Tooltip body={code.replace(/(")|(\\)/g, '')}>
          <i className="bi bi-upc-scan text-primary fs-5" />
        </Tooltip>
      ) : null,
  },
  { name: 'pt_dob', title: 'DOB', className: 'text-nowrap' },
  { name: 'total_img', title: 'Total Images', className: 'text-center' },
  { name: 'no_of_img', title: 'Submitted Images', className: 'text-center' },
  {
    name: 'rejected_no_of_img',
    title: 'Rejected Images',
    className: 'text-center',
  },
  {
    name: 'rejected_reason',
    title: 'Rejected Reason',
    className: 'text-center',
  },
];

const RejectReasonReportPage = () => {
  const {
    fetching,
    reports,
    reportTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeRejectReasonReport;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeRejectReasonReport.setFilter(filter);

    setPaginationToStart();
  };

  useEffect(() => {
    storeRejectReasonReport.getReportMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Reject Reason Report">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        fetching={fetching}
        isVisible={isFilterVisible}
        onChange={handleChangeFilter}
      />

      <Grid
        id="reject_reason_report_grid"
        columns={columns}
        gridControlPanel={[
          <GridControlButton
            title="Export all"
            disabled={!reportTotal}
            onClick={storeRejectReasonReport.exportReport}
          />,
        ]}
        onAjax={fetching}
        dataSource={reports}
        dataSourceCount={reportTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
    </>
  );
};

export default observer(RejectReasonReportPage);
