interface PaginationValue {
  page: number;
  skip: number;
  pageSize: number;
}

export const getPagination = (pageSize = 10): PaginationValue => ({
  page: 1,
  skip: 0,
  pageSize,
});
