import React from 'react';

import { Path } from 'page/profile/personal/BriefcasePage';
import { IconButton } from 'components/button';
import Tooltip from 'components/tooltip';

import AccessUtils from 'utils/AccessUtils';
import { backend } from 'constant/config';

export default class BriefcaseItem extends React.Component {
  static PERMISSIONS = {
    FOLDER_RENAME: 'rename_folder',
    FOLDER_DELETE: 'delete_folder',
    FILE_RENAME: 'rename_file',
    FILE_DELETE: 'delete_file',
    PAGE: 'Briefcase',
  };

  static renderActions(
    entity: Path,
    onRename: (event: React.MouseEvent, entity: Path) => void,
    onRemove: (event: React.MouseEvent, entity: Path) => void,
    onShare: (event: React.MouseEvent, entity: Path) => void,
    onShareList: (event: React.MouseEvent, entity: Path) => void
  ) {
    if (entity.noControl) {
      return null;
    }
    const isFile = entity.type === 'F';
    return (
      <div className="control struct-control text-primary">
        {AccessUtils.checkAccess(
          isFile
            ? BriefcaseItem.PERMISSIONS.FILE_RENAME
            : BriefcaseItem.PERMISSIONS.FOLDER_RENAME
        ) ? (
          <Tooltip body="Rename">
            <IconButton onClick={(e) => onRename(e, entity)}>
              <i className="bi bi-pencil" />
            </IconButton>
          </Tooltip>
        ) : null}
        {AccessUtils.checkAccess(
          isFile
            ? BriefcaseItem.PERMISSIONS.FILE_DELETE
            : BriefcaseItem.PERMISSIONS.FOLDER_DELETE
        ) ? (
          <Tooltip body="Delete">
            <IconButton onClick={(e) => onRemove(e, entity)}>
              <i className="bi bi-trash" />
            </IconButton>
          </Tooltip>
        ) : null}
        <Tooltip body="Share">
          <IconButton onClick={(e) => onShare(e, entity)}>
            <i className="bi bi-share-fill" />
          </IconButton>
        </Tooltip>
        {entity.shared_count > 0 ? (
          <Tooltip body="Shared with">
            <IconButton
              className="text-primary fs-4"
              onClick={(e) => onShareList(e, entity)}>
              <i className="bi bi-person" />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    );
  }

  static renderTitle(
    entity: Path,
    onOpenDirectory: (event: React.MouseEvent, dir: Path) => void
  ): React.ReactNode {
    let onClick;
    const isFile = entity.type === 'F';
    if (isFile) {
      onClick = () => {};
    } else {
      if (entity.onOpenDirectory) {
        onClick = (event: React.MouseEvent) =>
          entity.onOpenDirectory(event, entity);
      } else {
        onClick = (event: React.MouseEvent) => onOpenDirectory(event, entity);
      }
    }
    let icon;
    if (entity.type === 'F') {
      icon = 'assets/images/filetypes/small-' + entity.image;
    } else {
      icon = 'assets/images/filetypes/small-folder.png';
    }
    return (
      <a
        href={
          isFile
            ? `${backend}/filemanager.FileManager.file/${entity.refid}`
            : '/'
        }
        target={isFile ? '_blank' : ''}
        rel="noreferrer"
        onClick={onClick}>
        <img src={icon} style={{ marginRight: '15px' }} alt={entity.title} />
        {entity.title}
      </a>
    );
  }
}
