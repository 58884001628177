import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import { storeImport } from 'stores/_mobx/import';
import AccessUtils from 'utils/AccessUtils';
import { BASE_URL_FILE_DIR } from 'constant/config';

const labelMapper = (
  documentsType: number[],
  options: { label: string; value: number }[]
) => {
  const labels = documentsType.map((id) => {
    const option = options.find((el) => el.value === id);
    return option.label;
  });

  return labels;
};

export const defaultDocumentDetailsValue: DocumentDetailsType = {
  comments: '',
  id: 0,
  file: '',
  documentsType: [],
};

const permissionDefaultValues: PermissionType = {
  import: false,
};

interface PermissionType {
  import: boolean;
}

export interface UploadDocumentFormType {
  payload: DocumentDetailsType;
  options: { label: string; value: number }[];
}

interface UploadDocumentPayloadType extends UploadDocumentFormType {
  params: {
    orderId: number;
    patientId: number;
  };
}

interface DocumentDetailsResponseType {
  comments: string;
  file_name: string;
  item_name: string;
  item_typeid: string;
  itemarr: string[];
}

export interface DocumentDetailsType {
  comments: string;
  id: number;
  file: File | string;
  documentsType: number[];
}

interface DocumentResponseType {
  absolute_path: string;
  comments: string;
  created_dt: string;
  desc: string;
  description: string;
  file_name: string;
  file_path: string;
  filename: string;
  filepath: string;
  item_name: string;
  item_subject: string;
  item_typeid: string;
  patient_id: string;
  pcpid: string;
  pcpname: string;
  phyname: string;
  'ppxray_importitem.comments': string;
  'ppxray_importitem.created_dt': string;
  'ppxray_importitem.refid': '38';
  'ppxray_mv_importitemtype.type_name': string;
  provider_id: string;
  refid: string;
  sign_status: string;
  type: 'import' | 'facesheet' | '';
  type_ids: string[];
  type_name: string;
}

export interface DocumentType {
  typeName: string;
  typeIds: number[];
  fileName: string;
  filePath: string;
  comments: string;
  refid: number;
  creationDate: string;
  essence: 'import' | 'facesheet' | '';
  patientId: number;
}

class OrderDocuments {
  fetching: boolean = false;
  fetchingDocumentDetails: boolean = false;
  documents: DocumentType[] = [];
  documentDetails: DocumentDetailsType | null = null;
  idForDelete: number = 0;
  isDeleteFaceSheetVisible: boolean = false;
  permission: PermissionType = permissionDefaultValues;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingDocumentDetails: observable,
      documents: observable,
      documentDetails: observable,
      idForDelete: observable,
      isDeleteFaceSheetVisible: observable,
      permission: observable,

      setFetching: action,
      setFetchingDocumentDetails: action,
      setDocuments: action,
      setDocumentDetails: action,
      setIdForDelete: action,
      setPermission: action,
      openFaceSheetDialog: action.bound,
      clearDocumentsStore: action.bound,
      clearIdForDelete: action.bound,
      clearDocumentDetails: action.bound,
      closeFaceSheetDialog: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingDocumentDetails(fetching: boolean) {
    this.fetchingDocumentDetails = fetching;
  }

  setDocuments(docs: DocumentType[]) {
    this.documents = docs;
  }

  setDocumentDetails(doc: DocumentDetailsType) {
    this.documentDetails = doc;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setPermission(permission: PermissionType) {
    this.permission = permission;
  }

  openFaceSheetDialog() {
    this.isDeleteFaceSheetVisible = true;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  closeFaceSheetDialog() {
    this.isDeleteFaceSheetVisible = false;
  }

  clearDocumentDetails() {
    this.documentDetails = null;
  }

  clearDocumentsStore() {
    this.documents = [];
    this.idForDelete = 0;
    this.isDeleteFaceSheetVisible = false;
  }

  checkPermission() {
    const importDocs = AccessUtils.checkAccess('ext_single_impfile');

    this.setPermission({
      import: importDocs,
    });
  }

  async getDocuments({
    orderId,
    patientId,
  }: {
    orderId: number;
    patientId: number;
  }) {
    this.setFetching(true);
    try {
      const response = await apiRequest<DocumentResponseType[]>({
        url: 'order.ImportItem.ImportItemList',
        data: [orderId, patientId],
      });

      const docs = response.map((doc) => ({
        typeName: doc.item_name,
        typeIds: doc.type_ids.map((id) => Number(id)),
        fileName: doc.file_name,
        filePath: `${BASE_URL_FILE_DIR}${doc.absolute_path}${
          doc.file_path || doc.filepath
        }`,
        comments: doc.comments,
        refid: Number(doc.refid),
        creationDate: doc.created_dt,
        essence:
          doc.type === 'facesheet' || doc.type === 'import'
            ? doc.type
            : ('' as ''),
        patientId: Number(doc.patient_id),
      }));

      this.setDocuments(docs);
    } catch (e: any) {
      this.setDocuments([]);
    } finally {
      this.setFetching(false);
    }
  }

  async deleteFaceSheet(orderId: number) {
    try {
      const response = await apiRequest<'S' | 'E'>({
        url: 'order.Order.deleteFacesheet',
        data: [orderId],
      });

      if (response === 'S') {
        Notification.success('Facesheet document was deleted');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred');
      return false;
    } finally {
      this.closeFaceSheetDialog();
    }
  }

  async deleteDocument() {
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'order.ImportItem.DeleteDocument',
        data: [this.idForDelete],
      });

      if (response === 'S') {
        Notification.success('Document deleted successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      return false;
    } finally {
      this.clearIdForDelete();
    }
  }

  async getDocumentDetails(docId: number) {
    this.setFetchingDocumentDetails(true);
    try {
      const [response] = await apiRequest<[DocumentDetailsResponseType]>({
        url: 'order.ImportItem.editDocument',
        data: [docId],
      });

      const details = {
        comments: response.comments,
        id: docId,
        file: response.file_name || '',
        documentsType: response.itemarr.map((id) => Number(id)),
      };

      this.setDocumentDetails(details);
    } catch (e: any) {
      this.setDocumentDetails(defaultDocumentDetailsValue);
    } finally {
      this.setFetchingDocumentDetails(false);
    }
  }

  addDocument = async ({
    payload: { documentsType, ...rest },
    params,
    options,
  }: UploadDocumentPayloadType) => {
    this.setFetchingDocumentDetails(true);

    const file = rest.file as File;

    const documentsTypeLabel = labelMapper(documentsType, options);

    try {
      const fileName = await storeImport.uploadFile({ file });

      const payload = {
        comments: rest.comments,
        file_name: fileName,
        order_id: params.orderId,
        patient_id: params.patientId,
      };

      const data = [payload, documentsTypeLabel, documentsType];

      const { status } = await apiRequest<{ status: 'S' | 'E'; id?: number }>({
        url: 'order.ImportItem.AddImportItem',
        data,
      });
      if (status === 'S') {
        Notification.success('Successfully imported!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Error occurred!');
      return false;
    } finally {
      this.setFetchingDocumentDetails(false);
    }
  };

  updateDocument = async ({
    payload: { documentsType, ...rest },
    params,
    options,
  }: UploadDocumentPayloadType) => {
    const documentsTypeLabel = labelMapper(documentsType, options);

    try {
      const fileName =
        typeof rest.file === 'string'
          ? rest.file
          : await storeImport.uploadFile({ file: rest.file });

      const payload = {
        comments: rest.comments,
        file_name: fileName,
        order_id: params.orderId,
        patient_id: params.patientId,
      };

      const data = [payload, documentsTypeLabel, rest.id, documentsType];

      const response = await apiRequest<'S' | 'E' | 'SE'>({
        url: 'order.ImportItem.EditImportItem',
        data,
      });

      if (response === 'S') {
        Notification.success('Document updated successfully!');
        return true;
      }
      throw Error('');
    } catch (error) {
      Notification.danger('Error occurred!');
      return false;
    } finally {
      this.setFetchingDocumentDetails(false);
    }
  };
}

export const storeOrderDocuments = new OrderDocuments();
