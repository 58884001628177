import { useForm } from 'react-hook-form';

import Dialog, { DialogBody } from 'components/modal/dialog';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Textarea } from 'components/form/textarea';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import resolver from './validation';

import { FormEmailType } from 'stores/_mobx/patientRecord/documentManagerAcquisition';

const priorityOptions = [
  { label: 'Normal', value: 'Normal' },
  { label: 'Urgent', value: 'Urgent' },
  { label: 'ASAP', value: 'ASAP' },
];

interface PropsType {
  defaultValues: FormEmailType;
  onSubmit: (data: FormEmailType) => Promise<any>;
  onClose: () => void;
}

const DialogSendMessage = ({ defaultValues, onSubmit, onClose }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const cn = `row${isSubmitting ? ' on-ajax' : ''}`;

  return (
    <Dialog handleClose={onClose}>
      <DialogBody>
        <form onSubmit={handleSubmit(onSubmit)} className={cn}>
          <Input name="email" label="Email" required control={control} />
          <Input name="subject" label="Subject" control={control} />
          <Input name="cc" label="Cc" control={control} />
          <Radio
            name="priority"
            className="part-inline"
            options={priorityOptions}
            control={control}
          />
          <Textarea
            name="message"
            label="Message"
            rows={10}
            required
            control={control}
          />
          <ControlsLayout alignX="right">
            <Button
              variant="warning"
              text="Cancel"
              disabled={isSubmitting}
              onClick={onClose}
            />
            <Button
              type="submit"
              text="Submit"
              disabled={!isDirty || isSubmitting}
            />
          </ControlsLayout>
        </form>
      </DialogBody>
    </Dialog>
  );
};

export default DialogSendMessage;
