import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import PhysicianPartialInformationForm from 'page/clinician-manager/physicians/components/PartialInformation';

import { storePasswordStrength } from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';
import {
  storePhysician,
  FormPartialPhysicianType,
  CreatedPartialPhysicianType,
} from 'stores/_mobx/clinicianManager/physician';

interface PropsType {
  onClose: () => void;
  onAddPhysician: (physician: CreatedPartialPhysicianType) => void;
}

const DialogPhysicianAdd = ({ onClose, onAddPhysician }: PropsType) => {
  const refSubmitButton = useRef<HTMLButtonElement>();

  const refResetButton = useRef<HTMLButtonElement>();

  const { fetching, passwordOptions } = storePasswordStrength;

  const { fetchingPhysicianDetails } = storePhysician;

  const isLoading = fetching || fetchingPhysicianDetails;

  const handleClickSubmit = () => {
    refSubmitButton.current.click();
  };

  const handleClickReset = () => {
    refResetButton.current.click();
  };

  const handleSubmit = (data: FormPartialPhysicianType) =>
    storePhysician.addPhysicianPartial(data).then((response) => {
      if ('userId' in response) {
        onAddPhysician(response);
        return null;
      }
      return response;
    });

  useEffect(() => {
    storePasswordStrength.getPasswordSettings();
  }, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Add Physician" onClose={onClose} />
      <DialogBody>
        <PhysicianPartialInformationForm
          fetching={fetching}
          passwordOptions={passwordOptions}
          refSubmitButton={refSubmitButton}
          refResetButton={refResetButton}
          onSubmit={handleSubmit}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          text="Reset"
          variant="warning"
          disabled={isLoading}
          onClick={handleClickReset}
        />
        <Button
          text="Submit"
          disabled={isLoading}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogPhysicianAdd);
