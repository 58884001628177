import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from 'components/project/dropdown/AbstractDropdown';
import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';

interface OptionType {
  label: string;
  value: string;
}

const options: OptionType[] = [
  { label: 'EKG', value: 'EKG' },
  { label: 'EKG WX', value: 'EKG WX' },
  { label: 'Addtl Exam', value: 'Addtl Exam' },
];

for (let i = 1; i <= 30; i++) {
  const label = 'Visit ' + i;
  options.push({ value: label, label });
}

export interface PVisitScheduleDropdown extends PAbstractDropdown {
  orderId: number;
}

export class SVisitScheduleDropdown extends SAbstractDropdown {}

export default class VisitScheduleDropdown extends AbstractDropdown<
  PVisitScheduleDropdown,
  SVisitScheduleDropdown
> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return options;
  }
}

export const DropdownVisitSchedule = (
  props: Omit<SelectPropsType, 'options'>
) => {
  return <Select {...props} options={options} />;
};
