import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Control, useController, useForm } from 'react-hook-form';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import clsx from 'clsx';

import { IconButton } from 'components/button';
import useDebounce from 'components/hook/useDebounce';
import { Input } from 'components/form/textField';
import {
  filterPhysicianList,
  FilterPhysiciansPayloadType,
} from './components/filterMethods';
import Cell, { OptionType, ColumnType } from './components/Cell';
import MapperTitle from './components/Title';

import {
  storePhysicianExtendedOption,
  PayloadPhysicianShortInfoType,
} from 'stores/_mobx/clinicianManager/physicianExtendedOption';

const stubMethodLoadData = () => {};

const defaultPaginationValues = { skip: 0, page: 1, pageSize: 20 };

const columnsAvailablePhysicians: ColumnType[] = [
  {
    title: 'Physician',
    name: 'label',
    width: 'calc(100% - 100px)',
  },
  {
    title: 'NPI',
    name: 'npi',
    width: 100,
  },
];

const columnsSelectedPhysicians: ColumnType[] = [
  {
    title: 'Group Physician',
    name: 'label',
    width: 'calc(100% - 100px)',
  },
  {
    title: 'NPI',
    name: 'npi',
    width: 100,
  },
];

const defaultFilterValues: FilterType = {
  availablePhysicians: {
    firstName: '',
    lastName: '',
    npi: '',
  },
  membersOfGroup: {
    firstName: '',
    lastName: '',
    npi: '',
  },
};

interface FilterPhysicianType {
  firstName: string;
  lastName: string;
  npi: string;
}

interface FilterType {
  availablePhysicians: FilterPhysicianType;
  membersOfGroup: FilterPhysicianType;
}

export interface PropsType {
  className?: string;
  options: OptionType[];
  totalOptions: number;
  value: OptionType[];
  isDirty?: boolean;
  disabled?: boolean;
  fetching: boolean;
  onChange?: (values: OptionType[]) => void;
  onRemove?: (values: OptionType[]) => void; // Action runs when move option from right grid to right
}

const PhysicianMapper = React.forwardRef<HTMLDivElement, PropsType>(
  (
    {
      isDirty = false,
      options,
      value = [],
      className,
      disabled,
      totalOptions,
      fetching,
      onChange,
      onRemove,
    }: PropsType,
    ref
  ) => {
    const prevDirtyStatus = useRef<boolean>(isDirty);

    const { control, watch, getValues } = useForm<FilterType>({
      defaultValues: defaultFilterValues,
    });

    const [selectedLeft, setLeftSelected] = useState<OptionType[]>([]);

    const [selectedRight, setRightSelected] = useState<OptionType[]>([]);

    const [newValues, setNewValues] = useState<OptionType[]>([]);

    const [filterForGroupMembers, setFilterQuery] = useState<
      FilterPhysiciansPayloadType['searchQuery']
    >(defaultFilterValues.membersOfGroup);

    const cn = clsx('mapper-container', className, { disabled });

    const handleSetFilterMembersOfGroup = (
      searchQuery: FilterPhysiciansPayloadType['searchQuery']
    ) => {
      setRightSelected([]);
      setFilterQuery(searchQuery);
    };

    const handleSearchAvailablePhysicians = (
      searchQuery: PayloadPhysicianShortInfoType
    ) => {
      setLeftSelected([]);
      storePhysicianExtendedOption.getExtendedOptionsMain(searchQuery);
    };

    const debouncedSearchAvailablePhysicians = useDebounce(
      handleSearchAvailablePhysicians
    );

    const debouncedSearchMembersOfGroup = useDebounce(
      handleSetFilterMembersOfGroup
    );

    const filteredOptions = useMemo(() => {
      const filteredOptions = options.filter(
        (option) => !value.some(({ value }) => option.value === value)
      );

      return filteredOptions;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, value]);

    const filteredValue = useMemo(
      () =>
        filterPhysicianList({
          list: value,
          searchQuery: filterForGroupMembers,
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [filterForGroupMembers, value]
    );

    const total = totalOptions ? totalOptions - value.length : 0;

    const itemCount =
      filteredOptions.length < totalOptions
        ? filteredOptions.length + 1
        : filteredOptions.length;

    const handleSelectLeft = (option: OptionType) => {
      const isIncluded = selectedLeft.some(
        ({ value }) => value === option.value
      );

      const codes = isIncluded
        ? selectedLeft.filter(({ value }) => value !== option.value)
        : selectedLeft.concat(option);

      setLeftSelected(codes);
    };

    const handleSelectRight = (option: OptionType) => {
      const isIncluded = selectedRight.some(
        ({ value }) => value === option.value
      );

      const codes = isIncluded
        ? selectedRight.filter(({ value }) => value !== option.value)
        : selectedRight.concat(option);

      setRightSelected(codes);
    };

    const handleAddValue = () => {
      const restNewValues = selectedLeft.filter(({ value }) =>
        options.some((option) => value === option.value)
      );

      if (onChange) onChange(value.concat(selectedLeft));
      setNewValues(newValues.concat(restNewValues));
      setLeftSelected([]);
    };

    const handleRemoveValue = () => {
      const restValues = value.filter(
        (option) => !selectedRight.some(({ value }) => value === option.value)
      );

      const restNewValues = newValues.filter(
        ({ value }) => !selectedRight.some((option) => option.value === value)
      );

      if (onChange) onChange(restValues);
      if (onRemove) onRemove(selectedRight);
      setNewValues(restNewValues);
      setRightSelected([]);
    };

    const loadNextPage = (startIndex: number, pageSize: number) => {
      if (!fetching) {
        const filter = getValues();

        storePhysicianExtendedOption.getExtendedOptions({
          ...filter.availablePhysicians,
          pagination: {
            pageSize,
            skip: startIndex,
            page: 0,
          },
        });
      }
    };

    const handleCheckItemLoadedStatus = (index: number) => {
      const isAllPageLoaded = filteredOptions.length >= totalOptions;

      const isContentLoaded = isAllPageLoaded || index < filteredOptions.length;

      if (!isContentLoaded) {
        loadNextPage(filteredOptions.length, 20);
      }

      return true;
    };

    useEffect(() => {
      const subscription = watch((formValue, { name }) => {
        if (name.startsWith('availablePhysicians')) {
          const payload = {
            ...formValue.availablePhysicians,
            pagination: defaultPaginationValues,
          };
          debouncedSearchAvailablePhysicians(payload);
        } else {
          debouncedSearchMembersOfGroup({
            ...formValue.membersOfGroup,
          } as Required<FilterType['membersOfGroup']>);
        }
      });

      return subscription.unsubscribe;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    useEffect(() => {
      if (!isDirty && prevDirtyStatus.current) {
        setRightSelected([]);
        setLeftSelected([]);
        setNewValues([]);
      }

      prevDirtyStatus.current = isDirty;
    }, [isDirty]);

    useEffect(() => {
      const payload = {
        ...defaultFilterValues.availablePhysicians,
        pagination: defaultPaginationValues,
      };

      debouncedSearchAvailablePhysicians(payload);

      return storePhysicianExtendedOption.clearPhysicianShortInfoList;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className={cn} ref={ref}>
        <div className="mapper-side">
          <div className="row">
            <Input
              name="availablePhysicians.lastName"
              placeholder="Last Name"
              disabled={disabled}
              className="col-md-4"
              control={control}
            />
            <Input
              name="availablePhysicians.firstName"
              placeholder="First Name"
              className="col-md-4"
              disabled={disabled}
              control={control}
            />
            <Input
              name="availablePhysicians.npi"
              placeholder="NPI"
              disabled={disabled}
              className="col-md-4"
              control={control}
            />
          </div>
          <div className="mapper-legend">{`Total available physicians: ${total}`}</div>
          <MapperTitle
            scrollable={total > 10}
            columns={columnsAvailablePhysicians}
          />
          <ul className="mapper-list">
            {total ? (
              <InfiniteLoader
                minimumBatchSize={10}
                itemCount={itemCount}
                isItemLoaded={handleCheckItemLoadedStatus}
                loadMoreItems={stubMethodLoadData}>
                {({ onItemsRendered, ref }) => (
                  <List
                    width="auto"
                    height={310}
                    itemSize={31}
                    ref={ref}
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}>
                    {({ index, style }) =>
                      filteredOptions[index] ? (
                        <Cell
                          className={clsx({
                            selected: selectedLeft.some(
                              ({ value }) =>
                                value === filteredOptions[index].value
                            ),
                          })}
                          style={style}
                          data={filteredOptions[index]}
                          columns={columnsAvailablePhysicians}
                          onClick={handleSelectLeft}
                        />
                      ) : (
                        <div style={style} className="text-center">
                          Loading...
                        </div>
                      )
                    }
                  </List>
                )}
              </InfiniteLoader>
            ) : (
              <li className="mapper-list-empty">There is no any option.</li>
            )}
          </ul>
        </div>

        <div className="mapper-control">
          <IconButton
            disabled={disabled || !selectedLeft.length}
            onClick={handleAddValue}>
            <i className="bi bi-caret-right-fill" />
          </IconButton>
          <IconButton
            disabled={disabled || !selectedRight.length}
            onClick={handleRemoveValue}>
            <i className="bi bi-caret-left-fill" />
          </IconButton>
        </div>

        <div className="mapper-side">
          <div className="row">
            <Input
              name="membersOfGroup.lastName"
              placeholder="Last Name"
              className="col-md-4"
              disabled={disabled}
              control={control}
            />
            <Input
              name="membersOfGroup.firstName"
              placeholder="First Name"
              className="col-md-4"
              disabled={disabled}
              control={control}
            />
            <Input
              name="membersOfGroup.npi"
              placeholder="NPI"
              className="col-md-4"
              disabled={disabled}
              control={control}
            />
          </div>
          <div className="mapper-legend">{`Total physicians in this group: ${filteredValue.length}`}</div>
          <MapperTitle
            scrollable={filteredValue.length > 10}
            columns={columnsSelectedPhysicians}
          />
          <ul className="mapper-list">
            {filteredValue.length ? (
              <List
                width="auto"
                height={310}
                itemSize={31}
                itemCount={filteredValue.length}>
                {({ index, style }) => (
                  <Cell
                    className={clsx({
                      selected: selectedRight.some(
                        ({ value }) => value === filteredValue[index].value
                      ),
                      moved: newValues.some(
                        ({ value }) => value === filteredValue[index].value
                      ),
                    })}
                    style={style}
                    data={filteredValue[index]}
                    columns={columnsSelectedPhysicians}
                    onClick={handleSelectRight}
                  />
                )}
              </List>
            ) : (
              <li className="mapper-list-empty">
                Please select the entry in the left Grid.
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
);

interface ControlledPropsType
  extends Omit<
    PropsType,
    'onChange' | 'value' | 'fetching' | 'options' | 'totalOptions'
  > {
  control: Control<any>;
  name: string;
}

const PhysicianMapperControlled = ({
  control,
  name,
  ...rest
}: ControlledPropsType) => {
  const {
    field,
    fieldState: { isDirty },
  } = useController({
    name,
    control,
  });
  const { fetching, physicianExtendedOptions, physicianExtendedOptionsTotal } =
    storePhysicianExtendedOption;

  return (
    <PhysicianMapper
      {...rest}
      {...field}
      fetching={fetching}
      options={physicianExtendedOptions}
      totalOptions={physicianExtendedOptionsTotal}
      isDirty={isDirty}
    />
  );
};

export default observer(PhysicianMapperControlled);
