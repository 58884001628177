import appDispatcher from 'dispatcher/AppDispatcher';
import { apiRequest } from 'services/RequestService';
import MathUtils from 'utils/MathUtils';

export class TClinicianGroup {
  label?: string = '';
  data: number = null;
  //
  value: number = null;
}

export default class GroupManagerActions {
  static loadClinicalGroup() {
    return apiRequest<Array<TClinicianGroup>>({
      url: 'groupmanager.GroupManager.GetAllClinicianGroup',
    }).then((response: Array<TClinicianGroup>) => {
      if (response && response.length) {
        response.forEach((v) => {
          v.value = MathUtils.parseInt('' + v.data);
        });
      }
      appDispatcher.dispatch({
        type: 'groupmanager.GroupManager.GetAllClinicianGroup',
        data: response,
      });
      return response;
    });
  }

  static loadClinicalGroupForPhysitian(id: number) {
    return apiRequest<Array<TClinicianGroup>>({
      url: 'groupmanager.GroupManager.GetClinicianGroup',
      data: [id],
    }).then((response: Array<TClinicianGroup>) => {
      if (response && response.length) {
        response.forEach((v) => {
          v.value = MathUtils.parseInt('' + v.data);
        });
      }
      return response;
    });
  }

  static loadClinicalGroupForPhysitianAdmin(id: number) {
    return apiRequest<Array<TClinicianGroup>>({
      url: 'groupmanager.GroupManager.GetClinicianGroupForAdmin',
      data: [id],
    }).then((response: Array<TClinicianGroup>) => {
      if (response && response.length) {
        response.forEach((v) => {
          v.value = MathUtils.parseInt('' + v.data);
        });
      }
      return response;
    });
  }
}
