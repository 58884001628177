import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { exporterInterface, ExporterInterface } from 'stores/_mobx/exporter';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';
import { GRID_ID_UNBILLED_CLAIMS } from 'constant/gridsId/reports';
import { TCBillProcessType } from 'types';

export const defaultFilterValue: FilterType = {
  division: 0,
  facility: [],
  dosStart: '',
  dosEnd: '',
  period: 'A',
  patientFirstName: '',
  patientLastName: '',
  modality: [],
  state: [],
  region: [],
};

export interface FilterType {
  division: number;
  dosStart: string;
  dosEnd: string;
  period: string;
  patientFirstName: string;
  patientLastName: string;
  modality: number[];
  facility: number[];
  state: number[];
  region: number[];
}

export interface UnbilledClaimType {
  id: string;
  patient: {
    id: string;
    firstName: string;
    lastName: string;
  };
  modality: {
    id: string;
    name: string;
  };
  facility: {
    id: string;
    facilityName: string;
    division: {
      id: string;
      name: string;
      shortName: string;
    };
    dispatchRegion: {
      id: string;
      region?: {
        id: number;
        region: string;
      };
      state: {
        id: string;
        state: string;
        shortName: string;
      };
      location: string;
    };
    corporateGroupMembers: {
      id: number;
      group: {
        id: number;
        groupName: string;
        flag: 'A';
      };
    }[];
  };
  facilityInvoiceStatus: 'N';
  procedureCount: number;
  scheduleServiceDate: string;
  tcBillProcess: TCBillProcessType;
  tcBillProcessView: string;
  techClaimStatus: 'N';
  techClaimStatusView: string;
}

class UnbilledClaims {
  fetching: boolean = false;
  records: UnbilledClaimType[] = [];
  recordsTotal: number = 0;
  chartData: [string, number | string][] = [];
  filter: FilterType = defaultFilterValue;
  gridId = GRID_ID_UNBILLED_CLAIMS;

  page: Pagination = new Pagination({ id: GRID_ID_UNBILLED_CLAIMS });
  exporterInterface: ExporterInterface = null;

  constructor(exporterInterface: ExporterInterface) {
    makeObservable(this, {
      fetching: observable,
      records: observable,
      recordsTotal: observable,
      filter: observable,

      setFetching: action,
      setLogsData: action,
      setFilter: action,
    });

    this.exporterInterface = exporterInterface;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setLogsData(props: { records: UnbilledClaimType[]; recordsTotal: number }) {
    this.records = props.records;
    this.recordsTotal = props.recordsTotal;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  async getLogs({
    isDivisionEnabled,
    sorting,
  }: {
    isDivisionEnabled: boolean;
    sorting: Record<string, 'asc' | 'desc'>;
  }) {
    const {
      page: { pagination },
    } = this;

    this.setFetching(true);

    const { dosStart, dosEnd, period, ...filter } = this.filter;

    const dos = getDateRangeBounds({
      from: dosStart,
      to: dosEnd,
    });

    const data = {
      ...filter,
      ...sorting,
      page: pagination.page,
      itemsPerPage: pagination.pageSize,
      scheduleServiceDateFrom: dos.dateFrom,
      scheduleServiceDateTo: dos.dateTo,
      division: isDivisionEnabled ? filter.division : 0,
    };

    try {
      const { items, totalItems } = await apiRequest<{
        items: UnbilledClaimType[];
        totalItems: number;
      }>({
        url: 'report/unbilled-claims',
        method: 'GET',
        contentType: 'ld',
        legacy: false,
        data,
      });

      const records = items.map((claim) => ({
        ...claim,
        scheduleServiceDate: dateToLocalTimezone({
          date: claim.scheduleServiceDate,
          dateOnly: true,
        }),
      }));

      this.setLogsData({
        records: records,
        recordsTotal: totalItems,
      });
    } catch (e: any) {
      this.setLogsData({
        records: [],
        recordsTotal: 0,
      });
    } finally {
      this.setFetching(false);
    }
  }

  runExportCsv = (columns: any[]) => {
    const { dosStart, dosEnd, ...rest } = this.filter;

    const dos = getDateRangeBounds({
      from: dosStart,
      to: dosEnd,
    });

    const filter = {
      ...rest,
      scheduleServiceDateFrom: dos.dateFrom,
      scheduleServiceDateTo: dos.dateTo,
    };

    this.exporterInterface.generateReport({
      columns,
      instance: '/report/unbilled-claims',
      source: 'UnbilledClaims',
      fileExtension: 'csv',
      filter,
    });
  };
}

export const storeUnbilledClaims = new UnbilledClaims(exporterInterface);
