export const prepareSequence = (data: any) => {
  if (data === null || data === undefined) {
    return null;
  }
  return { sequence_array: fixData(data) };
};

const fixData = (data: any): any => {
  let out: any = undefined;
  if (
    data === null ||
    typeof data === 'string' ||
    typeof data === 'number' ||
    typeof data === 'boolean'
  ) {
    out = data;
  } else if (data === undefined) {
    out = null;
  } else if (Array.isArray(data)) {
    out = [];
    for (let i = 0; i < data.length; i++) {
      out.push(fixData(data[i]));
    }
  } else if (typeof data === 'object') {
    out = {};
    for (let key in data) {
      out[key] = fixData(data[key]);
    }
  } else {
    out = data;
  }
  return out;
};
