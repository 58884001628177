import React from 'react';

import PrivilegesTable, { TPrivilegeItem } from './PrivilegesTable';
let NAMES = ['Main Menu', 'Sub Menu', 'Action Menu'];

const style = {
  marginTop: '20px',
  maxWidth: '33.3%',
};

const Legend = (
  <ul className="row legend">
    <li className="col-sm-3">
      <span className="type-permission" />
      Global user type permission
    </li>
    <li className="col-sm-3">
      <span className="user-checked" />
      Added specific to this user
    </li>
    <li className="col-sm-3">
      <span className="user-checked type-permission" />
      Both
    </li>
    <li className="col-sm-3">
      <span className="user-unchecked" />
      Removed specific to this user
    </li>
  </ul>
);

export interface PPrivilegesWrapper {
  dataSource: Array<TPrivilegeItem>;
  onAjax: boolean;
  updateSelection: (dto: any) => void;
  personSettings: Array<string>;
}

export class SPrivilegesWrapper {
  stack: Array<any>;
}

export default class PrivilegesWrapper extends React.Component<
  PPrivilegesWrapper,
  SPrivilegesWrapper
> {
  constructor(props: PPrivilegesWrapper) {
    super(props);
    this.state = {
      stack: [{ app_name: '', refid: '0' }],
    };
  }

  render() {
    const tables = [];
    for (let i = 0; i < this.state.stack.length; i++) {
      let stack = this.state.stack[i];
      let dataSource = [];
      for (let j = 0; j < this.props.dataSource.length; j++) {
        let item = this.props.dataSource[j];
        if (item.mass_id === stack.refid) {
          dataSource.push(item);
        }
      }
      if (dataSource.length === 0) {
        continue;
      }
      let selectedRefid = this.getSelectedRefId(i + 1);
      tables.push(
        <PrivilegesTable
          dataSource={dataSource}
          fullDataSource={this.props.dataSource}
          tableInStack={i}
          title={i === 0 ? 'Application Name' : stack.app_name}
          key={i}
          style={style}
          selectedRefid={selectedRefid}
          personSettings={this.props.personSettings}
          tableTitle={NAMES[i] ? NAMES[i] : NAMES[NAMES.length - 1]}
          updateSelection={this.updateSelection.bind(this)}
          onSelectAll={i > 0 ? this.selectAll.bind(this) : null}
          openNextPermission={this.openNextPermission.bind(this)}
          collapsePermission={this.collapsePermission.bind(this)}
        />
      );
    }
    return (
      <div
        className={'privilege-wrapper' + (this.props.onAjax ? ' on-ajax' : '')}>
        {this.props.personSettings ? Legend : null}
        <div className="row">{tables}</div>
      </div>
    );
  }

  getSelectedRefId(index: any) {
    if (this.state.stack[index]) {
      return this.state.stack[index].refid;
    }
    return null;
  }

  openNextPermission(tableInStack: any, item: any) {
    let stack = [];
    for (let i = 0; i < tableInStack + 1; i++) {
      stack.push(this.state.stack[i]);
    }
    stack.push(item);
    this.setState({ stack: stack });
  }

  collapsePermission(tableInStack: any) {
    let stack = [];
    for (let i = 0; i < tableInStack + 1; i++) {
      stack.push(this.state.stack[i]);
    }
    this.setState({ stack });
  }

  static hasChildrensCache: any = {};
  static hasChildrens(refid: any, fullDataSource: any) {
    if (PrivilegesWrapper.hasChildrensCache[refid] === undefined) {
      PrivilegesWrapper.hasChildrensCache[refid] = false;
      for (let i = 0; i < fullDataSource.length; i++) {
        if (fullDataSource[i].mass_id === refid) {
          PrivilegesWrapper.hasChildrensCache[refid] = true;
          break;
        }
      }
    }
    return PrivilegesWrapper.hasChildrensCache[refid];
  }

  selectAll(dataSource: any, value: any) {
    let out: any = {};
    for (let i = 0; i < dataSource.length; i++) {
      out[dataSource[i].refid] = value;
    }
    this.props.updateSelection(out);
  }

  updateSelection(name: string, value: boolean) {
    let object: any = {};
    object[name] = value;
    this.props.updateSelection(object);
  }
}
