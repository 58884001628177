import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import Title from 'components/project/common/title';
import FormFinding from './components/Form';

import {
  storeFinding,
  FormModel,
} from 'stores/_mobx/systemSetup/masterSetting/finding';
import { URL_FINDING } from 'constant/path/systemSetup';

interface MatchParam {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParam> {}

const FindingEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, finding } = storeFinding;

  const handleSubmit = (payload: FormModel) =>
    storeFinding.updateFinding(payload).then((response) => {
      if (!response) {
        history.push(URL_FINDING);
      }
    });

  useEffect(() => {
    storeFinding.getFinding(Number(params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Finding" />
      <FormFinding
        backUrl={URL_FINDING}
        defaultValues={finding}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(FindingEditPage);
