import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { URL_MOBILE_OPERATOR } from 'constant/path/systemSetup';
import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import FormMobileOperator, { FormModel } from './components/Form';

import { storeMobileOperator } from 'stores/_mobx/systemSetup/masterSetting/mobileOperator';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const MobileOperatorEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, mobileOperator } = storeMobileOperator;

  const handleSubmit = (data: FormModel) => {
    const payload = {
      ...data,
      refid: Number(params.id),
    };
    return storeMobileOperator
      .updateMobileOperator(payload)
      .then((response) => {
        if (response) {
          history.push(URL_MOBILE_OPERATOR);
        }
      });
  };

  useEffect(() => {
    storeMobileOperator.getOperator(params.id);
    return storeMobileOperator.clearMobileOperator;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Mobile Operator" />
      <FormMobileOperator
        backUrl={URL_MOBILE_OPERATOR}
        defaultValues={mobileOperator}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(MobileOperatorEditPage);
