import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import {
  storeEmailTemplate,
  EmailTemplateManualType,
} from 'stores/_mobx/systemSetup/masterSetting/emailTemplate';

interface PropsType {
  onClose: () => void;
  onSelect: (template: EmailTemplateManualType) => void;
}

const renderColumns =
  (onSelect: (template: EmailTemplateManualType) => void) =>
  (label: string, row: EmailTemplateManualType) => {
    const handleClick = (e: React.MouseEvent) => {
      e.preventDefault();
      onSelect(row);
    };

    return (
      <a href="/" onClick={handleClick}>
        {label}
      </a>
    );
  };

const EmailTemplates = ({ onClose, onSelect }: PropsType) => {
  const { fetchingOptions, manualTemplates } = storeEmailTemplate;

  const columns = useMemo(
    () => [
      {
        name: 'label',
        render: renderColumns(onSelect),
      },
    ],
    [onSelect]
  );

  useEffect(() => {
    storeEmailTemplate.getOptions();

    return storeEmailTemplate.clearManualTemplate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Email Manual Templates" onClose={onClose} />
      <DialogBody>
        <Grid
          id="emailTemplateId"
          onAjax={fetchingOptions}
          disablePagination
          dataSource={manualTemplates}
          columns={columns}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(EmailTemplates);
