import { Container } from 'flux/utils';

import AbstractOrderPage, {
  PAbstractOrderPage,
  SAbstractOrderPage,
} from 'page/workflow/order/order/AbstractOrderPage';
import DialogRedirectToOrder from 'page/workflow/order/components/DialogRedirectToOrder';
import DialogSelectPhysician from 'page/workflow/order/order/dialogSelectPhysician';
import AddPhysicianModal from 'page/workflow/order/order/DialogPhysicianAdd';
import Form from 'components/form/Form';
import FormControl from 'components/form/FormControl';
import Text from 'components/form/input/Text';
import NumberInput from 'components/form/input/NumberInput';
import Checkbox from 'components/form/input/Checkbox';
import Fieldset from 'components/form/Fieldset';
import Notification from 'components/modal/Notification';
import NpiInput from 'components/project/NpiInput';
import { Button } from 'components/button';
import DialogAlert from 'components/modal/DialogAlert';
import DialogConfirm from 'components/modal/dialogConfirm';
import DialogFax, {
  DetailsType,
} from 'page/components/DialogFaxPhone/DialogFax';

import OrderFormPageActions from 'actions/workflow/order/OrderFormPageActions';
import OrderFormPageStore from 'stores/workflow/order/OrderFormPageStore';
import UserProfileStore from 'stores/UserProfileStore';
import {
  TPhysicianInfo,
  TPhysicianDetail,
  TModifyOrderRes,
} from 'services/workflow/order/OrderFormPageService';
import { exportExam } from 'stores/_mobx/export';
import { PhysicianType } from 'stores/_mobx/clinicianManager/physician';
import { CreatedPartialPhysicianType } from 'stores/_mobx/clinicianManager/physician';
import { dateToLocalTimezone } from 'utils/DateUtils';

const disableAttr = { readOnly: true, disabled: true };

let tempCache: SPhysicianInformation | null | 'redirect' = null;

export interface PPhysicianInformation extends PAbstractOrderPage {
  physicianDetails: any;
  onPopupOpen: (flag: boolean) => void;
  allowSubmit: boolean;
  callerName: string;
  submitSuccess: (
    orderId?: number,
    state?: SPhysicianInformation,
    lastModifiedLock?: string
  ) => void;
  onSameAsFacility: () => void;
  onSamePatient: () => void;
  newOrderCallback: () => void;
  onGoBack: () => void;
  cachedState: SPhysicianInformation;
}

export class TPhysicianData {
  first_name: string = '';
  last_name: string = '';
  npi: string = '';
  mobile: string = '';
  fax: string = '';
  phy_group: string = '';
  group_affiliation_name: string = '';
}

export class TPhysicianDetails {
  orderingPhysician: TPhysicianData = new TPhysicianData();
  primaryCarePhysician: TPhysicianData = new TPhysicianData();
  consultingPhysician: TPhysicianData = new TPhysicianData();
  other_value: string = '';
  //
  ordPhysId: number = null;
  ord_phys_firstname: string = '';
  ord_phys_lastname: string = '';
  ord_phys_npi: string = '';
  ord_phys_phone: string = '';
  ord_phys_fax: string = '';
  ord_phys_provider_group: string = '';
  ord_phys_group_affiliation_name: string = '';
  //
  primPhysId: number = null;
  prim_phys_firstname: string = '';
  prim_phys_lastname: string = '';
  prim_phys_npi: string = '';
  prim_phys_phone: string = '';
  prim_phys_fax: string = '';
  prim_phys_provider_group: string = '';
  prim_phys_group_affiliation_name: string = '';
  primaryAsOrdering: boolean = false;
  //
  consPhysId: number = null;
  cons_phys_firstname: string = '';
  cons_phys_lastname: string = '';
  cons_phys_npi: string = '';
  cons_phys_phone: string = '';
  cons_phys_fax: string = '';
  cons_phys_provider_group: string = '';
  cons_phys_group_affiliation_name: string = '';
  consultingAsOrdering: boolean = false;
}

export class SPhysicianInformation extends SAbstractOrderPage {
  physicianDetails: TPhysicianDetails = new TPhysicianDetails();
  errors: any = {};
  orderFaxPopup: boolean = false;
  orderRedirectModal: boolean = false;
  examData: any = {};
  faxDetail: DetailsType | null = null;
  confirmFaxModal: boolean = false;
  isAddPhysicianOpen: boolean = false;
  PIN: any = null;
  showPIN: boolean = false;

  variantOfPhysician: '' | 'prim_phys_' | 'ord_phys_' | 'cons_phys_' = '';

  primPhysChosen: boolean = false;
  ordPhysChosen: boolean = false;
  consPhysChosen: boolean = false;
  showRequestByTextfield: boolean = false;
  collapsedPrimaryProvider: boolean = false;
  collapsedConsultingPhysician: boolean = false;
}

export class PhysicianInformation extends AbstractOrderPage<
  PPhysicianInformation,
  SPhysicianInformation
> {
  static getStores() {
    return [OrderFormPageStore];
  }

  static calculateState(
    prevState: SPhysicianInformation,
    props: PPhysicianInformation
  ) {
    const examViewInfo = OrderFormPageStore.getState().examViewInfo;

    const isOrderSame =
      tempCache !== 'redirect'
        ? props.orderId === tempCache?.examData.refid
        : false;
    const tempObj = tempCache === 'redirect' ? null : tempCache;

    const state = !isOrderSame
      ? (AbstractOrderPage.calculateState(
          prevState,
          props
        ) as SPhysicianInformation)
      : {
          ...tempObj,
          examData:
            props.orderId === examViewInfo.refid
              ? examViewInfo
              : tempObj.examData,
        };

    if (!prevState) {
      state.physicianDetails = isOrderSame
        ? tempObj.physicianDetails
        : props.physicianDetails || {};
      state.onAjax = false;
      state.confirmFaxModal = false;
      state.orderFaxPopup = false;
      state.orderRedirectModal = false;
      state.faxDetail = null;

      state.variantOfPhysician = '';

      state.primPhysChosen = false;
      state.ordPhysChosen = false;
      state.consPhysChosen = false;

      state.isAddPhysicianOpen = false;
      state.PIN = false;
      state.showRequestByTextfield = false;
      state.collapsedPrimaryProvider = isOrderSame
        ? tempObj.collapsedPrimaryProvider
        : true;
      state.collapsedConsultingPhysician = isOrderSame
        ? tempObj.collapsedConsultingPhysician
        : true;
    }
    return state;
  }

  cachePrimaryProvider: null | Record<string, number | string> = null;
  cacheConsultingProvider: null | Record<string, number | string> = null;

  componentWillUnmount() {
    if (tempCache !== 'redirect') {
      tempCache = { ...this.state };
    }
  }

  componentDidMount() {
    const isOrderSame =
      tempCache !== 'redirect' &&
      this.props.orderId === tempCache?.examData.refid;

    if (isOrderSame) {
      tempCache = null;
      return;
    }
    if (tempCache) {
      tempCache = null;
    }
    super.componentDidMount();
    if (this.props.orderId) {
      this.setState({ onAjax: true });
      OrderFormPageActions.loadPhysicianDetailsByOrderId(
        this.props.orderId
      ).then((response: TPhysicianDetail) => {
        this.setState(
          {
            physicianDetails: Object.assign(
              new TPhysicianDetails(),
              OrderFormPageStore.getState().physicianDetails
            ),
          },
          () => {
            const physDetails = Object.assign(
              new TPhysicianDetails(),
              this.state.physicianDetails
            );
            const ordPhys = physDetails.orderingPhysician;
            const primPhys = physDetails.primaryCarePhysician;
            const consPhys = physDetails.consultingPhysician;

            physDetails.ord_phys_firstname = ordPhys.first_name;
            physDetails.ord_phys_lastname = ordPhys.last_name;
            physDetails.ord_phys_npi = ordPhys.npi;
            physDetails.ord_phys_phone = ordPhys.mobile;
            physDetails.ord_phys_fax = ordPhys.fax;
            physDetails.ord_phys_provider_group = ordPhys.phy_group;
            physDetails.ord_phys_group_affiliation_name =
              ordPhys.group_affiliation_name;

            physDetails.prim_phys_firstname = primPhys.first_name;
            physDetails.prim_phys_lastname = primPhys.last_name;
            physDetails.prim_phys_npi = primPhys.npi;
            physDetails.prim_phys_phone = primPhys.mobile;
            physDetails.prim_phys_fax = primPhys.fax;
            physDetails.prim_phys_provider_group = primPhys.phy_group;
            physDetails.prim_phys_group_affiliation_name =
              primPhys.group_affiliation_name;

            physDetails.cons_phys_firstname = consPhys.first_name;
            physDetails.cons_phys_lastname = consPhys.last_name;
            physDetails.cons_phys_npi = consPhys.npi;
            physDetails.cons_phys_phone = consPhys.mobile;
            physDetails.cons_phys_fax = consPhys.fax;
            physDetails.cons_phys_provider_group = consPhys.phy_group;
            physDetails.cons_phys_group_affiliation_name =
              consPhys.group_affiliation_name;

            this.setState({ physicianDetails: physDetails, onAjax: false });
          }
        );
      });
    }
  }

  onSetSameAsOrdinary = (name: string, isChecked: boolean) => {
    const { physicianDetails } = this.state;

    this.cachePrimaryProvider = isChecked
      ? {
          prim_phys_firstname: physicianDetails.prim_phys_firstname,
          prim_phys_lastname: physicianDetails.prim_phys_lastname,
          prim_phys_npi: physicianDetails.prim_phys_npi,
          prim_phys_phone: physicianDetails.prim_phys_phone,
          prim_phys_fax: physicianDetails.prim_phys_fax,
          prim_phys_provider_group: physicianDetails.prim_phys_provider_group,
          primPhysId: physicianDetails.primPhysId,
        }
      : this.cachePrimaryProvider;

    const physicianInfo = isChecked
      ? {
          prim_phys_firstname: physicianDetails.ord_phys_firstname,
          prim_phys_lastname: physicianDetails.ord_phys_lastname,
          prim_phys_npi: physicianDetails.ord_phys_npi,
          prim_phys_phone: physicianDetails.ord_phys_phone,
          prim_phys_fax: physicianDetails.ord_phys_fax,
          prim_phys_provider_group: physicianDetails.ord_phys_provider_group,
          primPhysId: physicianDetails.ordPhysId,
        }
      : {
          primaryAsOrdering: isChecked,
          ...this.cachePrimaryProvider,
        };

    const physDetails = {
      ...physicianDetails,
      ...physicianInfo,
      [name]: isChecked,
    };

    this.setState({
      physicianDetails: physDetails,
    });
  };

  onSetSameAsOrdinaryCons = (name: string, isChecked: any) => {
    const { physicianDetails } = this.state;

    this.cacheConsultingProvider = isChecked
      ? {
          cons_phys_firstname: physicianDetails.cons_phys_firstname,
          cons_phys_lastname: physicianDetails.cons_phys_lastname,
          cons_phys_npi: physicianDetails.cons_phys_npi,
          cons_phys_phone: physicianDetails.cons_phys_phone,
          cons_phys_fax: physicianDetails.cons_phys_fax,
          cons_phys_provider_group: physicianDetails.cons_phys_provider_group,
          consPhysId: physicianDetails.consPhysId,
        }
      : this.cacheConsultingProvider;

    const physicianInfo = isChecked
      ? {
          cons_phys_firstname: physicianDetails.ord_phys_firstname,
          cons_phys_lastname: physicianDetails.ord_phys_lastname,
          cons_phys_npi: physicianDetails.ord_phys_npi,
          cons_phys_phone: physicianDetails.ord_phys_phone,
          cons_phys_fax: physicianDetails.ord_phys_fax,
          cons_phys_provider_group: physicianDetails.ord_phys_provider_group,
          consPhysId: physicianDetails.ordPhysId,
        }
      : {
          ...this.cacheConsultingProvider,
        };

    const physDetails = {
      ...physicianDetails,
      ...physicianInfo,
      [name]: isChecked,
    };

    this.setState({
      physicianDetails: physDetails,
    });
  };

  handleResetForm = () => {
    this.setState({ physicianDetails: new TPhysicianDetails() });
  };

  handleCloseDialogRedirect = () => {
    this.setState({ orderRedirectModal: false });
    this.props.submitSuccess();
    tempCache = 'redirect';
  };

  handleApproveRedirect = (idx: number) => {
    tempCache = 'redirect';
    this.setState({ orderRedirectModal: false });
    if (idx === 1) {
      this.props.onSameAsFacility();
    } else if (idx === 2) {
      this.props.onSamePatient();
    } else {
      this.props.newOrderCallback();
    }
  };

  handleSetPhysicianVariant =
    (variantOfPhysician: 'prim_phys_' | 'ord_phys_' | 'cons_phys_') => () => {
      this.setState({ variantOfPhysician }, () => this.props.onPopupOpen(true));
    };

  render() {
    const { props, state } = this;
    const physicianDetails = state.physicianDetails;
    const showTooltips = !(
      Boolean(state.variantOfPhysician) ||
      state.isAddPhysicianOpen ||
      state.showPIN ||
      state.confirmFaxModal ||
      state.orderFaxPopup ||
      state.orderRedirectModal
    );

    return (
      <>
        <Form
          className={
            'physician-information row ' + (state.onAjax ? 'on-ajax' : '')
          }
          onCollectValues={this.onCollectValues.bind(this)}
          submit={this.handleSubmit}
          model={physicianDetails}
          errors={state.errors}
          errorMessages={state.errorMessages}>
          <Fieldset className="col-lg-6">
            <legend className="required">Ordering Physician</legend>
            <div className="text-center mb-3">
              <Button
                className="btn-lg search-patient-btn"
                text="Search for Ordering Provider"
                onClick={this.handleSetPhysicianVariant('ord_phys_')}
              />
            </div>
            <Text
              name="ord_phys_lastname"
              label="Last Name"
              attr={disableAttr}
            />
            <Text
              name="ord_phys_firstname"
              label="First Name"
              attr={disableAttr}
            />
            <NpiInput
              name="ord_phys_npi"
              className="npi-lookup"
              hasLookUp
              label="NPI"
              attr={disableAttr}
            />
            <NumberInput
              name="ord_phys_phone"
              label="Phone"
              format="###-###-####"
              attr={disableAttr}
            />
            <NumberInput
              name="ord_phys_fax"
              label="Fax"
              format="###-###-####"
              attr={disableAttr}
            />
            <Text
              name="ord_phys_provider_group"
              label="Provider Group"
              attr={disableAttr}
            />
            <Text
              name="ord_phys_group_affiliation_name"
              label="Group Affiliation"
              attr={disableAttr}
            />
            <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-sm-12">
                <Button
                  text="Add New Provider"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ isAddPhysicianOpen: true }, () =>
                      this.props.onPopupOpen(true)
                    );
                  }}
                />
              </div>
            </div>
          </Fieldset>
          <Fieldset className="col-lg-6">
            <Fieldset
              className={
                state.collapsedPrimaryProvider ? 'collapsed' : 'expanded'
              }>
              <legend
                onClick={() => {
                  this.setState((state) => ({
                    collapsedPrimaryProvider: !state.collapsedPrimaryProvider,
                  }));
                }}>
                Primary Provider (optional)
              </legend>

              <div className="text-center mb-3">
                <Button
                  className="btn-lg search-patient-btn"
                  text="Search for Primary Provider"
                  onClick={this.handleSetPhysicianVariant('prim_phys_')}
                />
              </div>

              <Text
                name="prim_phys_lastname"
                label="Last Name"
                attr={disableAttr}
              />
              <Text
                name="prim_phys_firstname"
                label="First Name"
                attr={disableAttr}
              />
              <NpiInput
                className="npi"
                name="prim_phys_npi"
                hasLookUp
                label="NPI"
                attr={disableAttr}
              />
              <NumberInput
                name="prim_phys_phone"
                label="Phone"
                format="###-###-####"
                attr={disableAttr}
              />
              <NumberInput
                name="prim_phys_fax"
                label="Fax"
                format="###-###-####"
                attr={disableAttr}
              />
              <Text
                name="prim_phys_provider_group"
                label="Provider Group"
                attr={disableAttr}
              />
              <Checkbox
                name="primaryAsOrdering"
                label="Same as Ordering"
                onSetValue={this.onSetSameAsOrdinary}
                attr={{ disabled: !physicianDetails.ordPhysId }}
              />
            </Fieldset>

            <Fieldset
              className={
                state.collapsedConsultingPhysician ? 'collapsed' : 'expanded'
              }>
              <legend
                onClick={() => {
                  this.setState((state) => ({
                    collapsedConsultingPhysician:
                      !state.collapsedConsultingPhysician,
                  }));
                }}>
                Consulting physician (optional)
              </legend>
              <div className="text-center mb-3">
                <Button
                  className="btn-lg search-patient-btn"
                  text="Search for Consulting Provider"
                  onClick={this.handleSetPhysicianVariant('cons_phys_')}
                />
              </div>
              <Text
                name="cons_phys_lastname"
                label="Last Name"
                attr={disableAttr}
              />
              <Text
                name="cons_phys_firstname"
                label="First Name"
                attr={disableAttr}
              />
              <NpiInput
                className="npi"
                name="cons_phys_npi"
                hasLookUp
                label="NPI"
                attr={disableAttr}
              />
              <NumberInput
                name="cons_phys_phone"
                label="Phone"
                format="###-###-####"
                attr={disableAttr}
              />
              <NumberInput
                name="cons_phys_fax"
                label="Fax"
                format="###-###-####"
                attr={disableAttr}
              />
              <Text
                name="cons_phys_provider_group"
                label="Provider Group"
                attr={disableAttr}
              />
              <Checkbox
                name="consultingAsOrdering"
                label="Same as Ordering"
                onSetValue={this.onSetSameAsOrdinaryCons}
                attr={{ disabled: !physicianDetails.ordPhysId }}
              />
            </Fieldset>
          </Fieldset>
          <div />
          <Text
            className={'col-sm-6 ' + this.solveBackground('other_value')}
            name="other_value"
            label="This exam is to be done portable because"
            validations="required"
            errorMessages="Please, enter a reason!"
            tooltip={{
              overlay: 'Finish the sentence here',
              visible:
                props.showToolTips &&
                showTooltips &&
                !physicianDetails.other_value,
              onVisibleChange: () => {},
              placement: 'left',
            }}
          />
          <FormControl>
            <Button
              type="button"
              variant="danger"
              text="Back"
              onClick={props.onGoBack}
            />
            <Button
              variant="warning"
              text="Reset"
              onClick={this.handleResetForm}
            />
            <Button type="submit" text="Submit" disabled={!props.allowSubmit} />
          </FormControl>
        </Form>

        {Boolean(state.variantOfPhysician) && (
          <DialogSelectPhysician
            onCancel={this.handleCloseDialogPrimaryPhysician}
            onSelect={this.handleSelectPhysician}
          />
        )}

        {state.isAddPhysicianOpen && (
          <AddPhysicianModal
            onClose={this.handleCloseDialogPhysicianCreate}
            onAddPhysician={this.afterPhysicianAdd}
          />
        )}
        {state.showPIN && (
          <DialogAlert
            onApprove={() =>
              this.setState({ showPIN: false }, () =>
                this.props.onPopupOpen(false)
              )
            }
            text={state.PIN}
            title="PIN"
          />
        )}
        {state.confirmFaxModal && (
          <DialogConfirm
            onCancel={() => {
              this.handleConfirmFax(false);
            }}
            onApprove={() => {
              this.handleConfirmFax(true);
            }}>
            Would you like to fax the order now?
          </DialogConfirm>
        )}
        {state.orderFaxPopup && (
          <DialogFax
            title="Fax Requisition"
            isFaxWithReport={false}
            details={this.state.faxDetail}
            onClose={this.closeOrderFax}
            onSendFax={this.closeOrderFax}
          />
        )}
        {state.orderRedirectModal && (
          <DialogRedirectToOrder
            onClose={this.handleCloseDialogRedirect}
            onSubmit={this.handleApproveRedirect}
          />
        )}
        {this.renderOrderModifyWarningNotification()}
      </>
    );
  }

  closeOrderFax = () => {
    this.setState({
      orderFaxPopup: false,
      faxDetail: null,
      orderRedirectModal: true,
    });
  };

  formDataForFax() {
    const { state } = this;
    const patient = `${state.patientViewInfo[0]?.first_name || ''} ${
      state.patientViewInfo[0]?.last_name || ''
    }`.trim();

    return {
      id: state.orderDataPatient?.orderId || 0,
      patient: `${state.patientViewInfo[0]?.first_name || ''} ${
        state.patientViewInfo[0]?.last_name || ''
      }`.trim(),
      room: state.orderDataPatient?.room || '',
      examType: state.examData?.exam_type_name || '',
      facility: state.orderDataPatient?.facilityname || '',
      dos: state.examData?.sheduleservdate
        ? dateToLocalTimezone({
            date: state.examData.sheduleservdate,
            dateOnly: true,
          })
        : '',
      fileName: `${patient}.pdf`,
    };
  }

  handleConfirmFax(displayFaxPopup: boolean) {
    const faxPhone = this.state.examData.FaxPhone || [];
    const faxes = faxPhone.length
      ? faxPhone.map((fax: any) => ({
          faxNumber: fax.Fax,
          receiverName: this.state.orderDataPatient.facilityname || '',
        }))
      : [
          {
            faxNumber: '',
            receiverName: this.state.orderDataPatient.facilityname,
          },
        ];

    let clb = null;
    if (displayFaxPopup) {
      clb = () => {
        this.modalViewPDF().then((filePath: any) => {
          if (filePath.indexOf('.pdf') > -1) {
            const { fileName, ...details } = this.formDataForFax();

            const defaultValues = {
              flag: 'OC' as 'OC',
              faxes,
              files: [
                {
                  filePath,
                  fileName,
                  isChecked: true,
                  isCritical: false,
                },
              ],
            };

            const faxDetail = {
              ...details,
              defaultValues,
            };

            this.setState(
              {
                onAjax: false,
                faxDetail,
                orderFaxPopup: displayFaxPopup,
              },
              () => this.props.onPopupOpen(displayFaxPopup)
            );
          } else {
            Notification.danger(
              "Can't save fax. Error occurred during requisition form generation."
            );
            this.setState(
              {
                onAjax: false,
              },
              () => this.props.onPopupOpen(false)
            );
          }
        });
      };
    }
    this.setState(
      {
        confirmFaxModal: false,
        orderRedirectModal: !displayFaxPopup,
        onAjax: displayFaxPopup,
      },
      clb
    );
  }

  modalViewPDF() {
    const { orderId } = this.props;

    return exportExam(Number(orderId));
  }

  handleCloseDialogPhysicianCreate = () => {
    this.setState({ isAddPhysicianOpen: false });
    this.props.onPopupOpen(false);
  };

  afterPhysicianAdd = ({
    pinInfo,
    ...physician
  }: CreatedPartialPhysicianType) => {
    const physicianDetails = {
      ...this.state.physicianDetails,
      ordPhysId: physician.userId,
      ord_phys_firstname: physician.firstName,
      ord_phys_lastname: physician.lastName,
      ord_phys_npi: physician.npi,
      ord_phys_phone: physician.phone,
      ord_phys_fax: physician.fax,
      ord_phys_provider_group: '',
      ord_phys_group_affiliation_name: '',
    };
    this.setState(
      {
        isAddPhysicianOpen: false,
        PIN: pinInfo,
        showPIN: true,
        physicianDetails,
      },
      () =>
        OrderFormPageActions.loadPhysicianList({
          firstName: physician.firstName,
          lastName: physician.lastName,
          npi: physician.npi,
        })
    );
  };

  handleSelectPhysician = (physicianItem: PhysicianType) => {
    const { variantOfPhysician } = this.state;
    const keyId =
      variantOfPhysician === 'prim_phys_'
        ? 'primPhysId'
        : variantOfPhysician === 'cons_phys_'
        ? 'consPhysId'
        : 'ordPhysId';

    const checkboxesState =
      variantOfPhysician === 'ord_phys_'
        ? {
            primaryAsOrdering: false,
            consultingAsOrdering: false,
          }
        : variantOfPhysician === 'prim_phys_'
        ? {
            primaryAsOrdering: false,
          }
        : { consultingAsOrdering: false };

    const physicianDetails = {
      ...this.state.physicianDetails,
      ...checkboxesState,
      [keyId]: physicianItem.id,
      [`${variantOfPhysician}firstname`]: physicianItem.first_name,
      [`${variantOfPhysician}lastname`]: physicianItem.last_name,
      [`${variantOfPhysician}npi`]: physicianItem.npi,
      [`${variantOfPhysician}fax`]: physicianItem.fax,
      [`${variantOfPhysician}phone`]: physicianItem.mobile,
      [`${variantOfPhysician}provider_group`]: physicianItem.phy_group,
      [`${variantOfPhysician}group_affiliation_name`]:
        physicianItem.group_affiliation_name,
    };

    this.setState({ variantOfPhysician: '', physicianDetails }, () =>
      this.props.onPopupOpen(false)
    );
  };

  handleCloseDialogPrimaryPhysician = () => {
    this.setState({ variantOfPhysician: '' }, () =>
      this.props.onPopupOpen(false)
    );
  };

  onCollectValues(name: string, value: any, errorMessages: any) {
    const physicianDetails = Object.assign({}, this.state.physicianDetails);
    const errors = Object.assign({}, this.state.errors);
    (physicianDetails as any)[name] = value;
    errors[name] = errorMessages;
    this.setState({ physicianDetails, errors });
  }

  handleSubmit = (
    updatedModel: any,
    hasErrors: boolean,
    updatedErrors: any
  ) => {
    if (hasErrors) {
      const { state } = this;
      const physicianDetails = { ...state.physicianDetails, ...updatedModel };
      const errors = { ...state.errors, ...updatedErrors };
      this.setState({ physicianDetails, errors });
    } else {
      this.submitSuccess();
    }
  };

  submitSuccess() {
    const { props, state } = this;
    if (!props.allowSubmit) {
      return Promise.resolve();
    }
    const orderId = props.orderId;
    const details = state.physicianDetails;
    const physDetails: TPhysicianInfo = {
      orderId: orderId,
      callerName: props.callerName,
      primPhysId: details.primPhysId,
      ordPhysId: details.ordPhysId,
      consPhysId: details.consPhysId,
      other_value: details.other_value,
      orderSource: undefined,
      lastModifiedLock: props.lastModifiedLock,
    };
    if (physDetails.ordPhysId === 0 || !physDetails.ordPhysId) {
      Notification.danger('Please choose ordering physician');
      return Promise.resolve();
    }
    if (!props.callerName) {
      Notification.danger('Please enter the Caller Name');
      return Promise.resolve();
    }
    return OrderFormPageActions.updatePhysicianInfo(
      physDetails,
      !props.onEdit
    ).then((response: TModifyOrderRes) => {
      if (response.success) {
        Notification.success('Successfully updated!');
        if (props.onEdit) {
          props.submitSuccess(
            props.orderId ? props.orderId : response.orderId,
            this.state,
            response.lastModifiedLock
          );
        } else {
          if (UserProfileStore.isClientUser()) {
            props.submitSuccess();
          } else {
            this.setState({ confirmFaxModal: true }, () =>
              props.onPopupOpen(true)
            );
          }
        }
      } else this.showOrderModifyWarningNotification();
    });
  }

  getFormModel() {
    return this.state.physicianDetails;
  }
}

export default Container.create(PhysicianInformation, { withProps: true });
