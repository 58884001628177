import React from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';

import { PText, SText, TText } from 'components/form/input/Text';

const LEFT_KEY = 'ArrowLeft';
const DOWN_LEY = 'ArrowDown';
const UP_KEY = 'ArrowUp';
const RIGHT_KEY = 'ArrowRight';

const clearValue = (value?: string) => (value || '').replace(/\D/g, '');

/** Usage: <NumberInput format='###-###:###' name='name' value={this.state ? this.state.value : ''} onSetValue={this.onSet.bind(this)} />; */
interface PNumberInput extends PText {
  value?: string;
  format?: string;
  ref?: any;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  maxLength?: number;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

class SNumberInput extends SText {
  active: number = -1;
  goToDirrection: number = 0;
  activeInput: number = -1;
}

export default class NumberInput extends TText<PNumberInput, SNumberInput> {
  constructor(props: PNumberInput) {
    super(props);
    this.state = new SNumberInput();
  }

  static defaultProps: {
    type: string;
    maxLength: number;
    autoComplete: string;
    isClearable: boolean;
  } = {
    type: 'text',
    isClearable: false,
    maxLength: undefined,
    autoComplete: undefined,
  };

  getFormat(): string {
    return this.props.format;
  }

  componentDidMount() {
    super.componentDidMount();
    const thisValue = this.getValue();
    if (thisValue) {
      const normal = this.fixValue(thisValue);
      if (normal !== thisValue) {
        super.setValue(normal, null);
      }
    }
  }

  componentDidUpdate(prevProps: PNumberInput) {
    if (this.state.activeInput > -1) {
      const input: any = this.getHtmlInput();
      const rangeStart = this.state.activeInput;
      const rangeEnd = this.state.activeInput + 1;
      input.setSelectionRange(rangeStart, rangeEnd);
      input.focus();
    }

    const thisValue = prevProps.value;

    if (!thisValue) {
      const nextValue = this.props.value;
      if (nextValue !== thisValue) {
        const normal = this.fixValue(nextValue);

        if (normal !== nextValue) {
          super.setValue(normal, null);
        }
      }
    }
  }

  shouldComponentUpdate(nextProps: PNumberInput, nextState: SNumberInput) {
    return (
      this.props.value !== nextProps.value ||
      this.props.errors !== nextProps.errors ||
      this.props.validations !== nextProps.validations ||
      this.props.errorMessages !== nextProps.errorMessages ||
      this.props.attr !== nextProps.attr ||
      this.state !== nextState ||
      this.props.className !== nextProps.className ||
      this.props.tooltip !== nextProps.tooltip
    );
  }

  getHtmlInput(): Element | Text {
    return ReactDOM.findDOMNode(this.refs.input);
  }

  buildInput(attributes: PNumberInput): React.ReactElement {
    attributes.ref = 'input';
    attributes.onBlur = (event) => this.onBlurNumberInput(event);
    attributes.maxLength = this.calculateActiveSize();
    attributes.onKeyUp = (event) => this.handleKeyUp(event);
    // @ts-ignore
    attributes.onPaste = this.handleOnPast;
    // @ts-ignore
    attributes.onKeyDown = this.handleKeyDown;
    let value = this.normalizeValue();
    let format = this.getFormat();
    attributes.value = value;
    let mask = [];
    let valueIndex = 0;
    for (let i = 0; i < format.length; i++) {
      let char = format[i];
      let elem;
      if (char !== '#') {
        if (char === value[valueIndex]) {
          valueIndex++;
        }
        elem = (
          <span key={i} className="cell">
            {char}
          </span>
        );
      } else {
        elem = this.createMaskElement(value, i, valueIndex);
        valueIndex++;
      }
      mask.push(elem);
    }
    return (
      <div className="formatted-input" key="input">
        {super.buildInput(attributes)}
        {this.props.contentBefore}
        <div
          className={clsx('mask', {
            disabled: this.props.readOnly || this.props.attr?.disabled,
          })}
          onClick={(event: any) => this.goToPosition(0, event)}>
          {mask}
        </div>
      </div>
    );
  }

  createMaskElement = (
    value: any,
    i: number,
    valueIndex: number
  ): React.ReactElement => {
    return (
      <span
        key={'span_mask_item_' + i}
        className={
          'cell input' +
          (this.state.activeInput === valueIndex ? ' active' : '')
        }
        onClick={(e: any) => this.goToPosition(valueIndex, e)}>
        {value[valueIndex] ? value[valueIndex] : <span>&nbsp;</span>}
      </span>
    );
  };

  getValue() {
    return this.props.value;
  }

  normalizeValue(val?: any): string {
    let value = '';
    const p = this.props;
    let format = this.getFormat();
    if (!val) {
      val = p.value;
    }
    if (val && isFinite(val)) {
      val += '';
    }
    if (val) {
      for (let i = 0; i < format.length; i++) {
        if (val.length <= i) {
          break;
        } else if (format[i] === '#') {
          value += val[i];
        } else if (format[i] !== val[i]) {
          value += val[i];
        }
      }
    }
    return value;
  }

  getValueLength(): number {
    return this.normalizeValue().length;
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Backspace') {
      const { activeInput } = this.state;
      const value = this.normalizeValue() || '';
      const canDeleteValue = value.length && activeInput >= 0;
      if (canDeleteValue) {
        event.preventDefault();
        const newMarkerPosition = activeInput ? activeInput - 1 : 0;

        const newValue = newMarkerPosition
          ? value.substring(0, newMarkerPosition) + value.substring(activeInput)
          : value.substring(1);

        this.setState({ activeInput: newMarkerPosition }, () =>
          this.setValue(newValue)
        );
      }
    }
  };

  handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>): void {
    switch (event.key) {
      case LEFT_KEY:
      case DOWN_LEY:
        this.goToPosition(
          this.state.activeInput - 1 < 0 ? 0 : this.state.activeInput - 1,
          event
        );
        break;
      case UP_KEY:
      case RIGHT_KEY:
        let position;
        let length = this.getValueLength();
        if (this.state.activeInput + 1 > length) {
          if (length < this.calculateActiveSize()) {
            position = length - 1;
          } else {
            position = length;
          }
        } else {
          position = this.state.activeInput + 1;
        }
        this.goToPosition(position, event);
        break;
      default:
        const input: any = this.getHtmlInput();
        const start = input.selectionStart;
        const end = input.selectionEnd;

        if (start === end) {
          if (this.state.activeInput === this.getValueLength()) {
            input.setSelectionRange(start, start + 1);
          } else {
            this.setState({ activeInput: this.state.activeInput + 1 }, () =>
              input.setSelectionRange(start, start + 1)
            );
          }
        }
    }
  }

  goToPosition(
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ): void {
    const { props } = this;
    const { attr } = props;
    if (attr && attr.disabled) {
      return;
    }
    if (event) {
      event.stopPropagation();
    }
    if (!props.value) {
      index = 0;
    } else if (index >= props.value.toString().length) {
      index = props.value.toString().length - 1;
    }
    const input: any = this.getHtmlInput();
    input.focus();
    this.setState({ activeInput: index }, () =>
      input.setSelectionRange(index, index + 1)
    );
  }

  onBlurNumberInput(event: React.FocusEvent<HTMLInputElement>): void {
    this.removeSelection();
    const { attr } = this.props;
    if (attr && attr.onBlur) {
      attr.onBlur(event);
    }
  }

  handleOnPast = (e: ClipboardEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const input: any = this.getHtmlInput();
    const { activeInput } = this.state;
    const size = this.calculateActiveSize();
    const availableLength = size - activeInput;
    const currentValue = clearValue(this.props.value);
    const value =
      //@ts-ignore
      window.clipboardData?.getData
        ? //@ts-ignore
          window.clipboardData.getData('Text')
        : e.clipboardData.getData('text/plain');

    const prettierValue = clearValue(value);
    const finalValue = `${currentValue.substring(
      0,
      activeInput
    )}${prettierValue.substring(0, availableLength)}`;
    const newMarkerPosition =
      finalValue.length >= size ? size - 1 : finalValue.length;
    this.setState({ activeInput: newMarkerPosition });
    super.setValue(finalValue);
    input.selectionStart = newMarkerPosition;
    input.selectionEnd = newMarkerPosition;
  };

  removeSelection(): void {
    this.setState({ activeInput: -1 });
  }

  calculateActiveSize(): number {
    const format = this.getFormat();

    const prettierFormat = format.replace(/[^#]/g, '');

    return prettierFormat.length;
  }

  setValue(value: string, e?: React.KeyboardEvent<any>): void {
    const event = e?.target ? e : undefined;

    const isValueCorrect = e?.target
      ? /^\d+$/.test((e.target as HTMLInputElement).value)
      : true;

    const prettierValue = clearValue(value);

    if (isValueCorrect && (/^\d+$/.test(prettierValue) || !prettierValue)) {
      const input: any = this.getHtmlInput();
      const size = this.calculateActiveSize();
      const finalValue = prettierValue.substring(0, size);

      this.setState({ activeInput: input.selectionStart }, () => {
        super.setValue(this.fixValue(finalValue), event);
      });
    }
  }

  fixValue(notFormatted: string): string {
    if (!notFormatted) {
      notFormatted = '';
    }
    notFormatted = notFormatted.toString();
    let format = this.getFormat();
    let value = '';
    let j = 0;
    for (let i = 0; i < format.length; i++) {
      if (notFormatted.length - 1 < j) {
        break;
      } else {
        if (format[i] === '#' || notFormatted[j] === format[i]) {
          value += notFormatted[j];
          j++;
        } else {
          value += format[i];
        }
      }
    }
    return value;
  }

  getContentBefore(): null {
    return null;
  }
}
