import { useCallback, useEffect, useMemo, useState } from 'react';
import { Observer, observer } from 'mobx-react-lite';

import DialogTransmitLog from 'page/billing-coding/components/DialogTransmitLog';
import DialogMessagesFacility from 'page/components/messages/DialogFacility';
import useRequest from 'components/hook/useRequest';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import { Button, IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import { PureCheckbox } from 'components/form/checkbox';
import Notification from 'components/modal/Notification';
import DialogFormTextFax, { FaxModel } from 'page/components/DialogFormTextFax';
import { LayoutSideTitle } from 'components/layout';
import ExportLink from './components/ExportLink';
import DialogFormEmail, { FormModel } from './components/DialogFormEmail';
import Filter from './components/Filter';
import GridCheckbox from './components/GridCheckbox';

import SignOrdersPageActions from 'actions/clinician-manager/sign-orders/SignOrdersPageActions';
import {
  storeTransmitUnassignment,
  AppointmentType,
  FilterType,
} from 'stores/_mobx/billingCodding/transmitUnassignment';
import UserProfileStore from 'stores/UserProfileStore';
import { TRANSMIT_UNASSIGNMENT as PAGE_ID } from 'constant/pagesId/billingCodding';

const getColumns = ({
  isClient,
  isDivisionEnabled,
}: {
  isClient: boolean;
  isDivisionEnabled: boolean;
}) => {
  const columns = [
    { name: 'facility_name', title: 'Facility', className: 'width-200' },
    isDivisionEnabled ? { name: 'division', title: 'Division' } : null,
    { name: 'bill_contactnm', title: 'Billing Contact' },
    {
      name: 'viewUnassigned',
      title: 'View Unassigned',
      render: (value: undefined, data: AppointmentType) => (
        <ExportLink details={data} />
      ),
    },
    { name: 'unassign_count', title: 'Facility Unassigned Count' },
    {
      name: 'billEmail',
      noSort: true,
      shownCheckboxTitle: 'Email',
      title: (
        <Observer
          render={() => (
            <PureCheckbox
              name="email"
              label="Email"
              checked={storeTransmitUnassignment.isAllEmailChecked}
              onChange={storeTransmitUnassignment.setAllIdForEmail}
            />
          )}
        />
      ),
      render: (
        value: undefined,
        { facility_billemail, facility_id }: AppointmentType
      ) => (
        <Observer
          render={() => (
            <GridCheckbox
              type="email"
              id={facility_id}
              disabled={!facility_billemail}
              selectedIds={storeTransmitUnassignment.selectedIdForEmail}
              setIds={storeTransmitUnassignment.setIdForEmail}
            />
          )}
        />
      ),
    },
    {
      name: 'facility_billemail',
      title: 'Facility Billing Email',
      className: 'width-200',
    },
    {
      name: 'email_date',
      title: 'Last Email Date',
      render: (value: string) => <span className="text-nowrap">{value}</span>,
    },
    {
      name: 'billFax',
      shownCheckboxTitle: 'Fax',
      noSort: true,
      title: (
        <Observer
          render={() => (
            <PureCheckbox
              name="fax"
              label="Fax"
              checked={storeTransmitUnassignment.isAllFaxChecked}
              onChange={storeTransmitUnassignment.setAllIdForFax}
            />
          )}
        />
      ),
      render: (
        value: undefined,
        { facility_billfax, facility_id }: AppointmentType
      ) => (
        <Observer
          render={() => (
            <GridCheckbox
              type="fax"
              id={facility_id}
              disabled={!facility_billfax}
              selectedIds={storeTransmitUnassignment.selectedIdForFax}
              setIds={storeTransmitUnassignment.setIdForFax}
            />
          )}
        />
      ),
    },
    {
      name: 'facility_billfax',
      title: 'Facility Billing Fax',
      className: 'width-150',
    },
    {
      name: 'fax_date',
      title: 'Last Fax Date',
      render: (value: string) => <span className="text-nowrap">{value}</span>,
    },
    {
      name: 'transmit_log',
      title: 'Transmit Log',
      render: (
        value: undefined,
        { facility_id, facility_name }: AppointmentType
      ) => (
        <IconButton
          onClick={() =>
            storeTransmitUnassignment.setParamsForTransmitLog({
              facilityId: facility_id,
              facilityName: facility_name,
            })
          }>
          <i className="icon icon-notes" />
        </IconButton>
      ),
    },
    isClient
      ? null
      : {
          name: 'preinvoiceLog',
          title: 'Invoice Message',
          render: (
            value: undefined,
            { facility_id, hasLog }: AppointmentType
          ) => (
            <IconButton
              className="text-primary"
              onClick={() =>
                storeTransmitUnassignment.setIdForNotes(facility_id)
              }>
              <i
                className={`bi ${
                  hasLog ? 'bi-envelope-check-fill' : 'bi-envelope'
                }`}
              />
            </IconButton>
          ),
        },
  ];

  return columns.filter(Boolean);
};

const TransmitUnassignmentPage = ({
  isDivisionEnabled,
}: PropsTypeWitDivisionSettings) => {
  const {
    fetching,
    appointments,
    appointmentsTotal,
    paramsForTransmitLog,
    idForNotes,
    emailDialogProps,
    faxDialogProps,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeTransmitUnassignment;

  const { fetching: isFaxSending, controlledAction: sendFax } = useRequest(
    SignOrdersPageActions.sendFax
  );

  const { fetching: isEmailSending, controlledAction: sendEmail } = useRequest(
    SignOrdersPageActions.sendEmail
  );

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const isLoading = fetching || isFaxSending || isEmailSending;

  const columns = useMemo(
    () =>
      getColumns({
        isDivisionEnabled,
        isClient: UserProfileStore.isClientUser(),
      }),
    [isDivisionEnabled]
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleCloseMessageDialog = (shouldUpdate: boolean) => {
    storeTransmitUnassignment.clearIdForNotes();
    if (shouldUpdate)
      storeTransmitUnassignment.getAppointmentsListMain(isDivisionEnabled);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeTransmitUnassignment.setFilter(filter);

    setPaginationToStart();
  };

  const handleGetAppointments = useCallback(() => {
    storeTransmitUnassignment.clearSelectedIds();
    storeTransmitUnassignment.getAppointmentsListMain(isDivisionEnabled);
  }, [isDivisionEnabled]);

  const handleSendEmail = (data: FormModel) => {
    const orders = appointments.filter(({ facility_id }) =>
      storeTransmitUnassignment.selectedIdForEmail.includes(facility_id)
    );

    storeTransmitUnassignment.clearEmailDialogProps();

    return sendEmail({
      ...data,
      emailList: orders,
      tab: '',
    }).then(() => {
      Notification.success(
        `${orders.length} E-mail${
          orders.length > 1 ? 's' : ''
        } sent successfully!`
      );
      handleGetAppointments();
    });
  };

  const handleSendFax = (data: FaxModel) => {
    const orders = appointments.filter(
      ({ facility_id, facility_billfax }) =>
        facility_billfax &&
        storeTransmitUnassignment.selectedIdForFax.includes(facility_id)
    );

    const faxes = orders.map(({ facility_billfax }) => facility_billfax);

    storeTransmitUnassignment.clearFaxDialogProps();

    sendFax({
      // @ts-ignore
      objList: orders,
      body: data.body,
      faxno: data.faxFrom,
      from: data.from,
      dateVal: data.date,
      faxto: faxes.length ? faxes[0] : '',
      tab: '',
      title: '', // data.title
      subject: data.subject,
    }).then(() => {
      Notification.success(
        `${faxes.length} Fax${faxes.length > 1 ? 'es' : ''} sent successfully!`
      );
      handleGetAppointments();
    });
  };

  useEffect(() => {
    handleGetAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Transmit Unassignment">
        <Button
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          variant="default"
          data-testid="toggleFilter"
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        isVisible={isFilterVisible}
        fetching={fetching}
        onSubmit={handleChangeFilter}
      />

      <Grid
        id="transmit_unassignment_grid"
        onAjax={isLoading}
        columns={columns}
        dataSource={appointments}
        dataSourceCount={appointmentsTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <>
            <GridControlButton
              title="Email"
              disabled={
                !storeTransmitUnassignment.selectedIdForEmail.length ||
                isEmailSending
              }
              onClick={storeTransmitUnassignment.setEmailDialogProps}
            />
            <GridControlButton
              title="Fax"
              disabled={
                !storeTransmitUnassignment.selectedIdForFax.length ||
                isFaxSending
              }
              onClick={storeTransmitUnassignment.setFaxDialogProps}
            />
          </>
        }
      />

      {paramsForTransmitLog && (
        <DialogTransmitLog
          details={paramsForTransmitLog}
          onClose={storeTransmitUnassignment.clearParamsForTransmitLog}
        />
      )}

      {emailDialogProps && (
        <DialogFormEmail
          defaultValues={emailDialogProps}
          onClose={storeTransmitUnassignment.clearEmailDialogProps}
          onSubmit={handleSendEmail}
        />
      )}

      {faxDialogProps && (
        <DialogFormTextFax
          defaultValues={faxDialogProps}
          onSubmit={handleSendFax}
          onClose={storeTransmitUnassignment.clearFaxDialogProps}
        />
      )}

      {idForNotes ? (
        <DialogMessagesFacility
          id={idForNotes}
          onClose={handleCloseMessageDialog}
        />
      ) : null}
    </>
  );
};

export default withDivisionSettings(observer(TransmitUnassignmentPage));
