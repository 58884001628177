import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import LocationFilter from 'page/system-setup/components/LocationFilter';
import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';

import {
  storeQ0092,
  FilterType,
} from 'stores/_mobx/systemSetup/masterSetting/q0092';
import { MS_Q0092 as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_Q0092_ADD,
  URL_Q0092_EDIT,
} from 'constant/path/systemSetup';

const renderControl = (id: number) => (
  <div className="control">
    <Link
      className="bi bi-pencil"
      to={URL_Q0092_EDIT.replace(':id', `${id}`)}
    />
    <IconButton onClick={() => storeQ0092.setCodeForDelete(id)}>
      <i className="bi bi-trash" />
    </IconButton>
  </div>
);

const columns = [
  { name: 'state', title: 'State' },
  { name: 'code', title: 'Code' },
  { name: 'no_of_patient', title: 'No. of patient' },
  { name: 'billJurisdiction', title: 'Billing jurisdiction' },
  { name: 'facility_price', title: 'Facility price' },
  { name: 'insurance_price', title: 'Insurance price' },
  { name: 'refid', title: 'Action', render: renderControl },
];

const Q0092ListPage = () => {
  const {
    fetching,
    filter,
    codesList,
    codesCount,
    codeForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeQ0092;

  const handleConfirmDelete = () => {
    storeQ0092.deleteCode().then((isSucceed) => {
      if (isSucceed) {
        storeQ0092.getCodesMain();
      }
    });
  };

  const handleFilterChange = (filter: FilterType) => {
    storeQ0092.setFilter(filter);

    setPaginationToStart();
  };

  useEffect(() => {
    storeQ0092.getCodesMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Q0092">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <LocationFilter defaultValues={filter} fetchData={handleFilterChange} />

      <Grid
        id="q0092_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={codesList}
        dataSourceCount={codesCount}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={<GridControlButton title="Add" url={URL_Q0092_ADD} />}
      />
      {codeForDelete !== null && (
        <DialogConfirm
          onCancel={storeQ0092.clearCodeForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(Q0092ListPage);
