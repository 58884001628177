import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import {
  storeUltrasoundUnit,
  UnitFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/ultrasoundUnit';
import { URL_ULTRASOUND_UNIT } from 'constant/path/workflow';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const UltrasoundUnitEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, unit } = storeUltrasoundUnit;

  const handleSubmit = async (data: UnitFormModel) =>
    storeUltrasoundUnit.updateUnit(data).then((error) => {
      if (error) return error;
      history.push(URL_ULTRASOUND_UNIT);
    });

  useEffect(() => {
    if (!fetching) storeUltrasoundUnit.getUnit(Number(params.id));

    return storeUltrasoundUnit.clearUnit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Ultrasound Unit" />
      <Form
        backUrl={URL_ULTRASOUND_UNIT}
        defaultValues={unit}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(UltrasoundUnitEditPage);
