import {
  pcBillingOptions,
  tcBillingOptions,
} from 'stores/_mobx/billingCodding/billingAssignment';

export default (() => {
  const out = [].concat(pcBillingOptions, tcBillingOptions);
  const container: any = {};
  return out.filter((v) => {
    if (!container[v.value]) {
      container[v.value] = true;
      return true;
    }
    return false;
  });
})();
