import { Route, Switch } from 'react-router-dom';

import { AccessRoute } from 'components/HOC';
import * as IDS from 'constant/pagesId/reports';
import * as PATH from 'constant/path/report';

import BriefcaseHistoryPage from 'page/reports/hr-reports/briefcaseHistory';
import EmployeeTimeSheetPage from 'page/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPage';
import UserExpenseReportPage from 'page/reports/hr-reports/userExpenseReport';
import EmployeeInformation from 'page/reports/hr-reports/employee-information/EmployeeInformation';

import OrderTrackHistoryPage from 'page/reports/report-creator/orderTrackHistory';

import FacilityExamCountPage from 'page/reports/report-creator/facility-exam-count/FacilityExamCountPage';
import StudiesByFacilityPage from 'page/reports/report-creator/studies-by-facility/StudiesByFacilityPage';
import FacilityPatientPage from 'page/reports/report-creator/facility-patient/FacilityPatientPage';
import TechPatientPage from 'page/reports/report-creator/tech-patient/TechPatientPage';
import TechExamCountPage from 'page/reports/report-creator/tech-exam-count/TechExamCountPage';
import StudiesByTechPage from 'page/reports/report-creator/studies-by-tech/StudiesByTechPage';
import OrderReportPage from 'page/reports/report-creator/order-report/OrderReportPage';
import PhysicianExamCountPage from 'page/reports/report-creator/physician-exam-count/PhysicianExamCountPage';
import ProcedureCodeCountPage from 'page/reports/report-creator/procedure-code-count/ProcedureCodeCountPage';
import RadiologyReportPage from 'page/reports/report-creator/radiologyGroup';
import ArAgingReport from 'page/reports/report-creator/ArAgingReport';
import {
  StatisticEncounterPage,
  StatisticProcedurePage,
} from 'page/reports/report-creator/statisticByTech';
import ProcedureCountPage from 'page/reports/report-creator/procedureCount';
import EncounterDuration from 'page/reports/report-creator/encounterDuration';
import ExamReport from 'page/reports/report-creator/examReport';
import SummaryLocationPage from 'page/reports/report-creator/summaryLocation';
import SummaryTechnologist from 'page/reports/report-creator/SummaryTechnologist';
import TechReviewExams from 'page/reports/report-creator/techReviewExams';
import SummaryFacility from 'page/reports/report-creator/summaryFacility';
import InvoiceReportPage from 'page/reports/custom-reports/invoice-report/InvoiceReportPage';
import ProcedureSummaryPage from 'page/reports/custom-reports/procedure-summary/ProcedureSummaryPage';
import PhysicianPatientPage from 'page/reports/custom-reports/physician-patient/PhysicianPatientPage';
import ModalitySummary from 'page/reports/report-creator/modalitySummary';
import CorporateSummary from 'page/reports/report-creator/corporateSummary';
import UnbilledClaims from 'page/reports/report-creator/unbilledClaims';
import E404 from 'page/errors/404';

export const reportsRoutes = [
  PATH.URL_REPORT_DATA,
  PATH.URL_EXAM,
  PATH.URL_REPORT_HR,
  PATH.URL_REPORT_CUSTOM,
  PATH.URL_REPORT_CREATOR,
];

const Reports = () => (
  <Switch>
    <AccessRoute
      exact
      pageId={IDS.TECH_VOLUME.PAGE}
      path={PATH.URL_SUMMARY_TECHNOLOGIST}
      component={SummaryTechnologist}
    />
    <AccessRoute
      exact
      pageId={IDS.TECH_ENCOUNTER.PAGE}
      path={PATH.URL_PATIENT_SEEN}
      component={StatisticEncounterPage}
    />
    <AccessRoute
      exact
      pageId={IDS.TECH_EXAM_COUNT_OTHER.PAGE}
      path={PATH.URL_TECH_EXAM_COUNT}
      component={StatisticProcedurePage}
    />
    <AccessRoute
      exact
      pageId={IDS.TECH_PATIENT_OTHER.PAGE}
      path={PATH.URL_TECH_PATIENT_DATA}
      component={EncounterDuration}
    />
    <AccessRoute
      exact
      pageId={IDS.BRIEFCASE_HISTORY.PAGE}
      path={PATH.URL_BRIEFCASE_HISTORY}
      component={BriefcaseHistoryPage}
    />
    <AccessRoute
      exact
      pageId={IDS.USER_EXPENSE.PAGE}
      path={PATH.URL_USER_EXPENSE}
      component={UserExpenseReportPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EMPLOYER_TIME_SHEET.PAGE}
      path={PATH.URL_EMPLOYEE_TIMESHEET}
      component={EmployeeTimeSheetPage}
    />
    <AccessRoute
      exact
      pageId={IDS.FACILITY_EXAM_COUNT.PAGE}
      path={PATH.URL_FACILITY_EXM_COUNT}
      component={FacilityExamCountPage}
    />
    <AccessRoute
      exact
      pageId={IDS.FACILITY_STUDIES.PAGE}
      path={PATH.URL_STUDIES_BY_FACILITY}
      component={StudiesByFacilityPage}
    />
    <AccessRoute
      exact
      pageId={IDS.FACILITY_PATIENT.PAGE}
      path={PATH.URL_FACILITY_PATIENT}
      component={FacilityPatientPage}
    />
    <AccessRoute
      exact
      pageId={IDS.RADIOLOGY_REPORT.PAGE}
      path={PATH.URL_RADIOLOGY_REPORT}
      component={RadiologyReportPage}
    />
    <AccessRoute
      exact
      pageId={IDS.ORDER_REPORT.PAGE}
      path={PATH.URL_ORDER_REPORT}
      component={OrderReportPage}
    />
    <AccessRoute
      exact
      pageId={IDS.PHYSICIAN_EXAM_COUNT.PAGE}
      path={PATH.URL_PHYSICIAN_EXAM_COUNT}
      component={PhysicianExamCountPage}
    />
    <AccessRoute
      exact
      pageId={IDS.PROCEDURE_CODE_COUNT.PAGE}
      path={PATH.URL_PROCEDURE_CODE_COUNT}
      component={ProcedureCodeCountPage}
    />
    <AccessRoute
      exact
      pageId={IDS.TECH_STUDIES.PAGE}
      path={PATH.URL_STUDIES_BY_TECH}
      component={StudiesByTechPage}
    />
    <AccessRoute
      exact
      pageId={IDS.TECH_EXAM_COUNT.PAGE}
      path={PATH.URL_TECH_EXAM_COUNT_DATA}
      component={TechExamCountPage}
    />
    <AccessRoute
      exact
      pageId={IDS.TECH_PATIENT.PAGE}
      path={PATH.URL_TECH_PATIENT}
      component={TechPatientPage}
    />
    <AccessRoute
      exact
      pageId={IDS.ORDER_TRACK_HISTORY.PAGE}
      path={PATH.URL_ORDER_TRACK_HISTORY}
      component={OrderTrackHistoryPage}
    />
    <AccessRoute
      exact
      pageId={IDS.PROCEDURE_SUMMARY.PAGE}
      path={PATH.URL_PROCEDURE_SUMMARY}
      component={ProcedureSummaryPage}
    />
    <AccessRoute
      exact
      pageId={IDS.AR_AGING_REPORT.PAGE}
      path={PATH.URL_AR_AGING_REPORT}
      component={ArAgingReport}
    />
    <AccessRoute
      exact
      pageId={IDS.INVOICE_REPORT.PAGE}
      path={PATH.URL_CUSTOM_INVOICE}
      component={InvoiceReportPage}
    />
    <AccessRoute
      exact
      pageId={IDS.PATIENT_REPORT.PAGE}
      path={PATH.URL_PHYSICIAN_PATIENT}
      component={PhysicianPatientPage}
    />
    <AccessRoute
      exact
      pageId={IDS.PROCEDURE_COUNT.PAGE}
      path={PATH.URL_PROCEDURE_COUNT}
      component={ProcedureCountPage}
    />
    <AccessRoute
      exact
      pageId={IDS.EXAM_REPORT_OTHER.PAGE}
      path={PATH.URL_EXAM_REPORT}
      component={ExamReport}
    />
    <AccessRoute
      exact
      pageId={IDS.SUMMARY_LOCATION.PAGE}
      path={PATH.URL_SUMMARY_LOCATION}
      component={SummaryLocationPage}
    />
    <AccessRoute
      exact
      pageId={IDS.TECH_EXAM_REVIEW.PAGE}
      path={PATH.URL_TECH_REVIEW_EXAM}
      component={TechReviewExams}
    />
    <AccessRoute
      exact
      pageId={IDS.FACILITY_SUMMARY.PAGE}
      path={PATH.URL_SUMMARY_FACILITY}
      component={SummaryFacility}
    />
    <AccessRoute
      exact
      pageId={IDS.MODALITY_SUMMARY.PAGE}
      path={PATH.URL_MODALITY_SUMMARY}
      component={ModalitySummary}
    />
    <AccessRoute
      exact
      pageId={IDS.CORPORATION_SUMMARY.PAGE}
      path={PATH.URL_CORPORATE_SUMMARY}
      component={CorporateSummary}
    />
    <AccessRoute
      exact
      pageId={IDS.EMPLOYEE_INFO.PAGE}
      path={PATH.URL_EMPLOYEE_INFO}
      component={EmployeeInformation}
    />
    <AccessRoute
      exact
      pageId={IDS.UNBILLED_CLAIMS.PAGE}
      path={PATH.URL_UNBILLED_CLAIMS}
      component={UnbilledClaims}
    />
    <Route path="*" component={E404} />
  </Switch>
);

export default Reports;
