import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

export const initialValues: RegionDetailsType = {
  location: '',
  refid: null,
  region_id: 0,
  state_id: 0,
};

interface RegionType {
  location: string;
  refid: number;
  region: string;
  state: string;
}

export interface RegionDetailsType {
  location: string;
  refid: number;
  region_id: number;
  state_id: number;
}

interface OptionResponseType {
  data: string;
  label: string;
  short_name: string;
}

export interface OptionType {
  value: number;
  label: string;
}

class DispatchRegions {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  dispatchRegionsList: RegionType[] = [];
  dispatchRegionTotal: number = 0;
  region?: RegionDetailsType = undefined;
  filter: string = '';
  options: OptionType[] = [];

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'dispatch_regions_grid' });

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      dispatchRegionsList: observable,
      dispatchRegionTotal: observable,
      region: observable,
      filter: observable,

      setFetching: action,
      setFetchingOption: action,
      setRegionsList: action,
      setRegionCount: action,
      setRegion: action,
      setFilter: action,
      clearRegion: action,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFetchingOption = (fetching: boolean) => {
    this.fetchingOptions = fetching;
  };

  setRegionsList = (regions: RegionType[]) => {
    this.dispatchRegionsList = regions;
  };

  setRegionCount = (count: number) => {
    this.dispatchRegionTotal = count;
  };

  setRegion = (region: RegionDetailsType) => {
    this.region = region;
  };

  setFilter = (regionName: string) => {
    this.filter = regionName;
  };

  clearRegion = () => {
    this.region = undefined;
  };

  async getDispatchRegions(regionName: string) {
    const { pagination } = this.page;

    const data = [pagination.skip, pagination.pageSize, regionName];
    try {
      const regions = await apiRequest<'SE' | 'S' | RegionType[]>({
        url: 'generalmaster.MvLocation.LocationList',
        data,
      });
      if (Array.isArray(regions)) {
        this.setRegionsList(regions);
      } else {
        throw Error('An error occurred!');
      }
    } catch (e) {
      this.setRegionsList([]);
    }
  }

  async getDispatchRegionsCount(regionName: string) {
    try {
      const response = await apiRequest<'SE' | 'S' | number>({
        url: 'generalmaster.MvLocation.LocationTotalCount',
        data: [regionName],
      });

      const total = Number(response);
      if (Number.isNaN(total)) {
        throw Error('Error is occurred!');
      }
      this.setRegionCount(total);
    } catch (e) {
      this.setRegionCount(0);
    }
  }

  getDispatchRegionsList = (regionName: string = this.filter) => {
    this.setFetching(true);

    const promiseCount = this.getDispatchRegionsCount(regionName);

    const promiseRegions = this.getDispatchRegions(regionName);

    Promise.allSettled([promiseCount, promiseRegions]).finally(() => {
      this.setFetching(false);
    });
  };

  getRegion = async (id: number) => {
    this.setFetching(true);
    try {
      const response = await apiRequest<[RegionDetailsType]>({
        url: 'generalmaster.MvLocation.GetStateRegoinByLocation',
        data: [id],
      });
      if (Array.isArray(response)) {
        this.setRegion({
          location: response[0].location,
          refid: id,
          region_id: Number(response[0].region_id),
          state_id: Number(response[0].state_id),
        });
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setRegion(null);
    } finally {
      this.setFetching(false);
    }
  };

  addDispatchRegion = async (payload: Omit<RegionDetailsType, 'refid'>) => {
    const data = [payload.region_id, payload.state_id, payload.location];
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.MvLocation.AddLocation',
        data,
      });
      if (result === 'S') {
        Notification.success('Dispatch Region was added successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This dispatch region already exist!' };
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  };

  updateDispatchRegion = async (payload: RegionDetailsType) => {
    const data = [
      payload.refid,
      payload.region_id,
      payload.state_id,
      payload.location,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.MvLocation.EditLocation',
        data,
      });

      if (result === 'S') {
        Notification.success('Dispatch Region was updated successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This dispatch region already exist!' };
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  };

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOption(true);

    try {
      const list = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.MvLocation.GetRegionDropDown',
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }

  findOption = (id: number) => this.options.find(({ value }) => value === id);
}

export const storeDispatchRegions = new DispatchRegions();
