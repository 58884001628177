import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import { LayoutSideTitle } from 'components/layout';
import MessageViewer from '../components/MessageViewer';

import { storeInbox } from 'stores/_mobx/message/inbox';
import { URL_TRASH } from 'constant/path/messages';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const TrashEmailPreviewPage = ({ match: { params } }: PropsType) => {
  const { fetching, emailPreview } = storeInbox;

  useEffect(() => {
    const emailId = Number(params.id);

    storeInbox.getEmailPreview([emailId, null, 'T']);

    return storeInbox.clearEmailPreview;
  }, [params.id]);

  return (
    <>
      <LayoutSideTitle title="Trash Message">
        <Link to={URL_TRASH} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      {emailPreview && <MessageViewer email={emailPreview} />}

      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(TrashEmailPreviewPage);
