import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validation = yupResolver(
  yup.object().shape({
    reason: yup.string().required('Please clarify cancel reasons'),
  })
);

export default validation;
