import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import FormPayerType, { FormModel } from './components/Form';

import { storePayerType } from 'stores/_mobx/systemSetup/masterSetting/payerType';
import { URL_PAYER_TYPE } from 'constant/path/systemSetup';

interface MatchProps {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchProps> {}

const PayerTypeEditPage = ({ history, match: { params } }: PropsType) => {
  const {
    fetching,
    payerType,
    getPayerType,
    updatePayerType,
    setPayerForEdit,
  } = storePayerType;

  useEffect(() => {
    getPayerType(Number(params.id));
    return () => {
      setPayerForEdit(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (payload: FormModel) =>
    updatePayerType(payload as Required<FormModel>).then((response) => {
      if (response) {
        history.push(URL_PAYER_TYPE);
      }
    });

  return (
    <>
      <Title title="Edit Payer Type" />
      <FormPayerType
        backUrl={URL_PAYER_TYPE}
        defaultValues={payerType || undefined}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(PayerTypeEditPage);
