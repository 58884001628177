import { makeObservable, observable, action, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';
import { calcDateRange } from 'utils/calcDateRange';

const getDefaultFilterValue = (): FilterType => ({
  radiologyGroupId: 0,
  ...calcDateRange('M'),
  period: 'M',
  studyId: '',
  facilityId: 0,
  patientFirstName: '',
  patientLastName: '',
  mrn: '',
});

export interface FilterType {
  radiologyGroupId: number;
  facilityId: number;
  dosStart: string;
  dosEnd: string;
  period: string;
  studyId: string;
  patientFirstName: string;
  patientLastName: string;
  mrn: string;
}

interface ChartType {
  CompOrd: number;
  rgname: string;
}

interface LogType {
  accessionno: string;
  cptcnt: number;
  dob: string;
  faci_name: string;
  modality: string;
  mrn: string;
  p_cptcnt: number;
  patient: string;
  radioname: string;
  rgname: string;
  sheduleservdate: string;
  studyid: string;
}

interface LogResponse {
  chartval: ChartType[];
  dataval: LogType[];
}

class RadiologyReport {
  fetching: boolean = false;
  logList: LogType[] = [];
  logTotal: number = 0;
  chartData: [string, number | string][] = [];
  filter: FilterType = getDefaultFilterValue();

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'radiology_report_grid' });

    makeObservable(this, {
      fetching: observable,
      logList: observable,
      logTotal: observable,
      chartData: observable,
      filter: observable,

      setFetching: action,
      setLogTotal: action,
      setChartData: action,
      setFilter: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setLogTotal(count: number) {
    this.logTotal = count;
  }

  setFilter(payload: FilterType) {
    this.filter = payload;
  }

  setChartData(chartData: [string, number | string][]) {
    this.chartData = chartData;
  }

  setDefaultFilter() {
    const filter = {
      ...this.filter,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facilityId,
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const filter = {
      ...getDefaultFilterValue(),
      facilityId: UserProfileStore.getFacilityId() || 0,
    };

    return filter;
  }

  async getLogs(data: (number | string)[]) {
    try {
      const { chartval, dataval } = await apiRequest<LogResponse>({
        url: 'report.ReportMaster.GetRadioGroupList',
        data,
      });

      const chartData = chartval.reduce(
        (prev, { CompOrd, rgname }) => prev.concat([[rgname, Number(CompOrd)]]),
        [['Radiology Group name', 'Completed Orders']] as [
          string,
          string | number
        ][]
      );

      const logList = dataval.map((el) => ({
        ...el,
        sheduleservdate: dateToLocalTimezone({
          date: el.sheduleservdate,
          dateOnly: true,
        }),
      }));

      runInAction(() => {
        this.logList = logList;
        this.chartData = chartData;
      });
    } catch (e) {
      runInAction(() => {
        this.logList = [];
        this.chartData = [['Radiology Group name', 'Completed Orders']];
      });
    }
  }

  async getLogsCount(data: (number | string)[]) {
    try {
      const count = await apiRequest<number>({
        url: 'report.ReportMaster.GetRadioGroupCount',
        data,
      });

      this.setLogTotal(count);
    } catch (e) {
      this.setLogTotal(0);
    }
  }

  async getLogsListMain() {
    const {
      filter,
      page: { pagination },
    } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const logCountPayload = [
      filter.radiologyGroupId,
      filter.facilityId || '',
      dos.dateFrom,
      dos.dateTo,
      filter.studyId,
      filter.patientFirstName,
      filter.patientLastName,
      filter.mrn,
    ];

    const logListPayload = [
      pagination.skip,
      pagination.pageSize,
      filter.radiologyGroupId,
      dos.dateFrom,
      dos.dateTo,
      null,
      filter.studyId,
      filter.facilityId || '',
      filter.patientFirstName,
      filter.patientLastName,
      filter.mrn,
    ];

    this.setFetching(true);

    const promiseCount = this.getLogsCount(logCountPayload);

    const promiseRegions = this.getLogs(logListPayload);

    Promise.allSettled([promiseCount, promiseRegions]).finally(() => {
      this.setFetching(false);
    });
  }

  generatePdf = async ({ filter }: { filter: string }) => {
    const data = {
      dataSource: this.logList,
      filter,
    };
    try {
      const response = await apiRequest<string>({
        url: 'pdfcreater.OrderPdfGenerator.radiologyReportPdf',
        data,
      });
      return response;
    } catch (e: any) {
      return '';
    }
  };

  prepareFilterForExport() {
    const { filter } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return {
      ...filter,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
    };
  }
}

export const storeRadiologyReport = new RadiologyReport();
