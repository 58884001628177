import { makeObservable, action, observable } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { getDateRangeBounds } from 'utils/DateUtils';

export const filterDefaultValues: FilterType = {
  dosStart: '',
  dosEnd: '',
  period: 'A',
  dateOfBirth: '',
  facilityId: 0,
  orderType: '',
  orderStatus: '',
  posId: 0,
  physicianId: null,
  patientFirstName: '',
  patientLastName: '',
  stateId: 0,
  status: '',
};

export interface FilterType {
  dosStart: string;
  dosEnd: string;
  period: string;
  dateOfBirth: string;
  facilityId: number;
  orderType: string;
  orderStatus: string;
  posId: number;
  physicianId: number | null;
  patientFirstName: string;
  patientLastName: string;
  stateId: number;
  status: string;
}

interface AuditPhysicianResponseType {
  admin_email: string;
  bill_email: string;
  bill_fax: string;
  clinical_count: number;
  comments: string;
  dos: string;
  fax_date: null | string;
  insurance_count: number;
  orderid: string;
  physician_nm: string;
  providerid: string;
  total_ordercount: string;
  unsign_count: number;
}

export interface AuditPhysicianType {
  admin_email: string;
  bill_email: string;
  bill_fax: string;
  clinical_count: number;
  comments: string;
  dos: string;
  fax_date: null | string;
  insurance_count: number;
  orderid: number;
  name: string;
  id: number;
  total_ordercount: number;
  unsign_count: number;
}

class DocManagerAuditPhysician {
  fetching: boolean = false;
  auditFacilityEntries: AuditPhysicianType[] = [];
  auditFacilityTotal: number = 0;
  filter: FilterType = filterDefaultValues;
  idForTransmitLog: number = 0;

  page: Pagination = new Pagination({ id: 'physician_tab_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      auditFacilityEntries: observable,
      auditFacilityTotal: observable,
      filter: observable,
      idForTransmitLog: observable,

      setFetching: action,
      setAuditEntries: action,
      setFilter: action,
      setIdForTransmitLogDialog: action,
      clearIdForTransmitLogDialog: action.bound,
      clearStore: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setAuditEntries({
    entries,
    count,
  }: {
    entries: AuditPhysicianType[];
    count: number;
  }) {
    this.auditFacilityEntries = entries;
    this.auditFacilityTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setIdForTransmitLogDialog(id: number) {
    this.idForTransmitLog = id;
  }

  clearIdForTransmitLogDialog() {
    this.idForTransmitLog = 0;
  }

  clearStore() {
    this.clearIdForTransmitLogDialog();
  }

  async getAuditPhysician() {
    const {
      filter,
      page: { pagination },
    } = this;

    const { dateFrom, dateTo } = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const data = [
      pagination.skip,
      pagination.pageSize,
      dateFrom,
      dateTo,
      filter.stateId,
      filter.posId,
      filter.orderType,
      filter.physicianId,
      filter.orderStatus,
      filter.patientLastName,
      filter.patientFirstName,
      filter.dateOfBirth,
    ];

    this.setFetching(true);
    try {
      const { count, data: response } = await apiRequest<{
        count: number;
        data: AuditPhysicianResponseType[];
      }>({
        url: 'facility.DocumentManager.DocPhysicianList',
        data,
      });

      const entries: AuditPhysicianType[] = response.map(
        ({ physician_nm, ...el }) => ({
          ...el,
          id: Number(el.providerid),
          name: physician_nm,
          fax_date: el.fax_date || '',
          orderid: Number(el.orderid),
          total_ordercount: Number(el.total_ordercount),
        })
      );

      this.setAuditEntries({ entries, count });
    } catch (e: any) {
      this.setAuditEntries({
        entries: [],
        count: 0,
      });
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeDocManagerAuditPhysician = new DocManagerAuditPhysician();
