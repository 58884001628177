import { forwardRef } from 'react';
import { Controller, Control } from 'react-hook-form';

import PureFile, { PureFileProps } from './PureFile';

/**
  @prop {string} 'data-testid' - data attribute for testing libraries
  If data-testid not presented, then prefix for data-testid attribute will generated with 'name' property
  '{dataTestId}-label' - data attribute for the label
  '{dataTestId}-field' - data attribute for the file display block
  '{dataTestId}-error' - data attribute for the error block
*/

export interface PropsType
  extends Omit<
    PureFileProps,
    | 'value'
    | 'name'
    | 'onChange'
    | 'onBlur'
    | 'onFocus'
    | 'errorMessage'
    | 'pattern'
  > {
  control: Control<any>;
  name: string;
}

const FileField = forwardRef<HTMLInputElement, PropsType>(
  ({ control, name, ...rest }: PropsType, ref) => (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const errorMessage = error?.message || '';

        return (
          <PureFile
            errorMessage={errorMessage}
            {...rest}
            {...field}
            ref={ref}
          />
        );
      }}
    />
  )
);

export default FileField;
