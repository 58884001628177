import React from 'react';

interface PropsType {
  link: string;
  fetching: boolean;
  icon?: string;
  className?: string;
  downloading?: boolean;
  onClick: (e: React.SyntheticEvent) => void;
}

const CommonLink = ({
  link,
  fetching,
  icon = 'bi bi-filetype-pdf',
  className = '',
  downloading,
  onClick,
}: PropsType) => (
  <a
    href={link}
    target="_blank"
    rel="noreferrer"
    {...(downloading ? { download: link } : {})}
    className={`position-relative fs-5 ${className}${
      fetching ? ' on-ajax-line' : ''
    }`}
    onClick={onClick}>
    <i className={icon} />
  </a>
);

export default CommonLink;
