import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// @ts-ignore
import useFluxStore from 'flux-hooks';

import UserProfileStore from 'stores/UserProfileStore';
import { CLIENTS_WHICH_HAS_ACCESS_TO_LEGACY_PAGE } from 'constant/config';

interface Props {
  path: string;
  exact?: boolean;
  component: React.FunctionComponent | React.ClassType<any, any, any>;
}

const LegacyPageRoute = ({ component: Component, ...rest }: Props) => {
  const isKSMobileUser = useFluxStore(
    UserProfileStore,
    (prevState: any, store: any) => {
      const appSN = store.getState().userProfile?.app_sn || '';

      return appSN
        ? CLIENTS_WHICH_HAS_ACCESS_TO_LEGACY_PAGE.includes(appSN)
        : false;
    }
  );

  return isKSMobileUser ? (
    <Route {...rest} component={Component} />
  ) : (
    <Redirect to="/404" />
  );
};

export default LegacyPageRoute;
