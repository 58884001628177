import { useEffect, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import { Grid } from 'components/grid';
import Filter from './components/Filter';

import { storeAuditTrail, FilterType } from 'stores/_mobx/users/auditTrail';
import { AUDIT_LOG as PAGE_ID } from 'constant/pagesId/privileges';

const columns = [
  { name: 'ntptim', title: 'Time', className: 'width-150' },
  { name: 'usernm', title: 'User Name' },
  { name: 'modnam', title: 'Module' },
  { name: 'usract', title: 'Action Type' },
  { name: 'descrp', title: 'Description' },
  { name: 'remtip', title: 'IP Address', className: 'width-100' },
];

const AuditTrailPage = () => {
  const {
    fetching,
    filter,
    auditTrailPart,
    auditTrailTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeAuditTrail;

  const [isFilterVisible, setFilterVisibility] = useState<boolean>(false);

  const toggleFilterVisibility = () => {
    setFilterVisibility((state) => !state);
  };

  const handleSearch = (filter: FilterType) => {
    setPaginationToStart();

    storeAuditTrail.setFilter(filter);
  };

  useLayoutEffect(() => {
    storeAuditTrail.setDefaultFilter();
  }, []);

  useEffect(() => {
    storeAuditTrail.getAuditTrail();
  }, [filter]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Audit Trail">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={toggleFilterVisibility}
        />
      </LayoutSideTitle>

      <Filter
        isVisible={isFilterVisible}
        fetching={fetching}
        onSubmit={handleSearch}
      />

      <Grid
        noDataText="Please refine your search using the filters above"
        columns={columns}
        onAjax={fetching}
        dataSource={auditTrailPart}
        pagination={pagination}
        dataSourceCount={auditTrailTotal}
        onPaginationChange={setPagination}
      />
    </>
  );
};

export default observer(AuditTrailPage);
