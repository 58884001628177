import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    name: yup.string().trim().required('Please, fill in Integration Name!'),
    externalApplicationName: yup
      .string()
      .trim()
      .required('Please, fill in External Integration Name!'),
    shortName: yup.string().trim().required('Please, fill in Short Name!'),
    orderType: yup
      .number()
      .nullable()
      .test({
        test: (orderType, { parent }) => {
          let isOrmSettingsEnabled = false;

          for (let key in parent.ormSettings) {
            const value = parent.ormSettings[key];

            if (value) {
              isOrmSettingsEnabled = value;
              break;
            }
          }

          return isOrmSettingsEnabled ? Boolean(orderType) : true;
        },
        message: 'Please, select Order Based option!',
      }),
  })
);

export default validation;
