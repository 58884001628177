import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import Portal from './Portal';
import Popover from './Popover';

interface PropsType {
  title?: string;
  body?: string | number | React.ReactNode;
  children: React.ReactNode;
  className?: string;
  alwaysVisible?: boolean;
  theme?: 'dark' | 'light' | 'danger' | 'warning';
  allowPointer?: boolean;
}

interface CoordsType {
  left: number;
  top: number;
  height: number;
}

const Tooltip = ({
  title,
  body,
  className,
  children,
  alwaysVisible,
  allowPointer,
  theme = 'light',
}: PropsType) => {
  const handleRef = useRef<HTMLSpanElement>(null);

  const [coords, setCoords] = useState<CoordsType | null>(null);

  const hidePopup = useCallback(() => {
    setCoords(null);
  }, []);

  const getCoords = (target?: HTMLElement) => {
    const child = target?.getBoundingClientRect();

    const parent = target?.parentElement?.getBoundingClientRect();

    const width =
      child.right > parent.right ? parent.right - child.x : child.width;

    return {
      top: child.y,
      height: child.height,
      left: child.x + width / 2,
    };
  };

  const handleHover = (e: React.SyntheticEvent) => {
    if (!alwaysVisible) {
      const rect = getCoords(e.target as HTMLElement);

      setCoords(rect);
    }
  };

  const handleLeave = () => {
    if (!alwaysVisible) hidePopup();
  };

  useEffect(() => {
    if (alwaysVisible) {
      const rect = getCoords(handleRef.current);

      setCoords(rect);
    }
  }, [alwaysVisible]);

  useEffect(() => {
    window.addEventListener('scroll', hidePopup);

    return () => window.removeEventListener('scroll', hidePopup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <span
        ref={handleRef}
        className={clsx('tooltip-anchor', className)}
        onMouseEnter={body ? handleHover : undefined}
        onMouseLeave={body ? handleLeave : undefined}>
        {children}
      </span>
      {coords && body ? (
        <Portal>
          <Popover
            coords={coords}
            title={title}
            theme={theme}
            allowPointer={allowPointer}>
            {body}
          </Popover>
        </Portal>
      ) : null}
    </>
  );
};

export default Tooltip;
