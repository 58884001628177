import { apiRequest } from 'services/RequestService';

export class TUserTypeApplication {
  refid: number;
  app_name: string;
  app_id: string;
  type: string;
  img_url: string;
  mass_id: string;
  action: string;
  action_type: string;
  flag: string;
  isselect: boolean;
}

class UserTypePrivilegePageService {
  loadApplications(userId: number, userTypeId: number, flag: boolean) {
    const data = {
      userId: userId,
      userTypeId: userTypeId,
      flag: flag,
    };

    return apiRequest<Array<TUserTypeApplication>>({
      url: 'permission.PermissionInfo.LoadUserTypeApplication',
      data,
    });
  }

  saveChanges(userType: number, ids: Array<number>, isAllowed: Array<string>) {
    const data = {
      userType: userType,
      ids: ids,
      isAllowed: isAllowed,
    };

    return apiRequest<'1'>({
      url: 'permission.PermissionInfo.SaveUserType_Permission',
      data,
    });
  }
}

export default new UserTypePrivilegePageService();
