import { Select } from 'components/form/select';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { storeIntegration, SettingsFormType } from 'stores/_mobx/integration';

const SettingsForm = () => {
  const { fetchingOptions, optionsSettings, optionsORM, optionsORU } =
    storeIntegration;

  const { control, reset, watch } = useForm<SettingsFormType>({
    defaultValues: optionsSettings,
  });

  useEffect(() => {
    reset(optionsSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingOptions]);

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name)
        storeIntegration.updateSettings({
          path: name,
          value: formValue[name],
        });
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row mb-4">
      <Select
        name="orm_send_events:send_orm"
        label="Send ORM"
        className="col-md-6 col-lg-4"
        isClearable={false}
        options={optionsORM}
        control={control}
      />
      <div />
      <Select
        name="oru_send_events:send_oru"
        label="Send ORU"
        className="col-md-6 col-lg-4"
        isClearable={false}
        options={optionsORU}
        control={control}
      />
    </form>
  );
};

export default observer(SettingsForm);
