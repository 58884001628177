import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import DropdownBillingJurisdiction from 'components/project/dropdown/BillingJurisdiction';
import { DropDownState } from 'components/project/dropdown/StateDropdown';

const initialValues: FormModel = {
  stateId: 0,
  jurisdictionId: 0,
};

interface PropsType {
  defaultValues?: FormModel;
  fetchData: (data: FormModel) => void;
}

interface FormModel {
  stateId: number;
  jurisdictionId: number;
}

const LocationFilter = ({
  defaultValues = initialValues,
  fetchData,
}: PropsType) => {
  const { control, watch, reset } = useForm<FormModel>({ defaultValues });

  const stateId = watch('stateId');

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      const jurisdictionId = name === 'stateId' ? 0 : formValue.jurisdictionId;

      const data = { ...formValue, jurisdictionId };

      if (name === 'stateId') reset(data);

      fetchData(data as FormModel);
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row">
      <DropDownState
        className="col-md-6 col-lg-4 col-xl-3"
        name="stateId"
        label="State"
        required
        control={control}
      />
      <DropdownBillingJurisdiction
        name="jurisdictionId"
        label="Billing jurisdiction"
        className="col-md-6 col-lg-4 col-xl-3"
        required
        stateId={stateId}
        control={control}
      />
    </form>
  );
};

export default observer(LocationFilter);
