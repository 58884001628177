import { Link } from 'react-router-dom';

import { URL_INTEGRATION_MASTER } from 'constant/path/systemSetup';

interface PropsType {
  settings: {
    app_id: string;
    app_name: string;
    img_url: string;
    refid: number;
    link: string;
  };
}

const TileMenu = ({ settings }: PropsType) => (
  <Link
    style={
      settings.link.includes(URL_INTEGRATION_MASTER)
        ? { filter: 'grayscale(1)' }
        : undefined
    }
    className="menu-item"
    to={settings.link}>
    <img
      src={`assets/icons/menu/${settings.app_id}.svg`}
      alt={settings.app_name}
    />
    <span>{settings.app_name}</span>
  </Link>
);

export default TileMenu;
