import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { phoneValidator, zipValidator } from 'utils/formValidators';

const npiListValidation = yup.object().shape({
  npi: yup.string().test({
    test: (npi, rest) => {
      const mapping =
        // @ts-ignore
        rest.from[1].value.npiDetailsList;
      const mappingIsEmpty =
        mapping.length === 1 && !mapping[0].npi && !mapping[0].desc;

      return mappingIsEmpty ? true : /^\d{10}$/.test(npi);
    },
    message: 'NPI must contain 10 digits in length',
  }),
});

const zipCodeValidation = yup.string().test({
  test: (zipCode: string) => (zipCode ? zipValidator(zipCode) : true),
  message: 'Should be in xxxxx-xxxx format!',
});

const emailValidation = yup
  .string()
  .required('Please, enter email address!')
  .email('Invalid email address.');

const phoneNumberValidation = yup.string().test({
  test: (phone: string) => (phone ? phoneValidator(phone) : true),
  message: 'Should be in xxx-xxx-xxxx format!',
});

const validation = yupResolver(
  yup.object().shape({
    company_name: yup.string().required('Please, enter company name!'),
    email: emailValidation,
    phone: phoneNumberValidation,
    fax: phoneNumberValidation,
    tollfreenum: phoneNumberValidation,
    zipcode: zipCodeValidation,
    billingemail: emailValidation,
    billingphone: phoneNumberValidation,
    billingfax: phoneNumberValidation,
    billingtollfree: phoneNumberValidation,
    billingzip: zipCodeValidation,
    npiDetailsList: yup.array().of(npiListValidation),
  })
);

export default validation;
