import appDispatcher from 'dispatcher/AppDispatcher';

import OrderReportPageService from 'services/reports/report-creator/order-report/OrderReportPageService';
import { Actions } from 'stores/reports/report-creator/order-report/OrderReportPageStore';

export default class OrderReportPageActions {
  static loadOrderReportList(
    conditionList,
    joint,
    startIndex,
    limit,
    oldListCount
  ) {
    return OrderReportPageService.loadOrderReportList(
      conditionList,
      joint,
      startIndex,
      limit,
      oldListCount
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_ORDER_REPORT_LIST,
        data: response,
      });
    });
  }

  static createPdf(orderReportList, filter) {
    return OrderReportPageService.createPdf(orderReportList, filter);
  }
}
