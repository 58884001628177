import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import { storeDocManagerAuditTransmitLog } from 'stores/_mobx/patientRecord/docManagerAuditTransmitLog';

interface PropsType {
  type: 'facility' | 'physician';
  id: number;
  onClose: () => void;
}

const columns = [
  { name: 'name', title: 'User' },
  { name: 'action', title: 'Action' },
  { name: 'date', title: 'Date' },
  {
    name: 'faxedRequest',
    title: 'Faxed Request',
  },
];

const DialogTransmitLog = ({ id, type, onClose }: PropsType) => {
  const {
    fetching,
    logList,
    logTotal,
    page: { pagination, setPagination },
  } = storeDocManagerAuditTransmitLog;

  useEffect(() => {
    storeDocManagerAuditTransmitLog.getTransmitLog({ id, type });
  }, [pagination, id, type]);

  useEffect(() => storeDocManagerAuditTransmitLog.clearLogs, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Transmit Log" onClose={onClose} />
      <DialogBody>
        <Grid
          stateless
          onAjax={fetching}
          columns={columns}
          pagination={pagination}
          dataSource={logList}
          dataSourceCount={logTotal}
          onPaginationChange={setPagination}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogTransmitLog);
