import NumberInput from 'components/form/input/NumberInput';
import Validation from 'components/form/Validation';

export default class ZipCodeInput extends NumberInput {
  getFormat(): string {
    return '#####-####';
  }

  validate(value: string): string[] {
    return Validation.validate(value, this.prepareValidations());
  }

  prepareValidations(): string {
    const validations = this.props.validations;
    if (!validations) {
      return 'zip';
    }
    if (typeof validations === 'string') {
      return validations + ' zip';
    } else if (validations.zip === undefined) {
      return { ...validations, zip: true };
    }
    return validations;
  }
}
