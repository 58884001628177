import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

export interface FilterType {
  placeName: string;
  state: 'A' | 'I';
}

interface PosType {
  active_state: 'Inactive' | 'Active';
  data: string;
  pos: string;
  pos_code: string;
  pos_desc: string;
}

interface PosEditType {
  pos: string;
  pos_code: string;
  pos_desc: string;
  refid?: number;
}

class PlaceOfService {
  filter: FilterType = { placeName: '', state: 'A' };
  fetching: boolean = false;
  placesList: PosType[] = [];
  placesCount: number = 0;
  place?: PosEditType = undefined;
  placeForDelete: number | null = null;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'place_of_service_grid' });

    makeObservable(this, {
      fetching: observable,
      place: observable,
      placesList: observable,
      placesCount: observable,
      placeForDelete: observable,
      filter: observable,

      setFetching: action,
      setPlacesList: action,
      setPlacesTotal: action,
      setPlace: action,
      setPlaceForDelete: action,
      clearPlaceForDelete: action,
      clearPlace: action,
      changeFilter: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setPlacesList(list: PosType[]) {
    this.placesList = list;
  }

  setPlacesTotal(count: number) {
    this.placesCount = count;
  }

  setPlace(place?: PosEditType) {
    this.place = place;
  }

  setPlaceForDelete(id: number) {
    this.placeForDelete = id;
  }

  clearPlace = () => {
    this.place = undefined;
  };

  clearPlaceForDelete = () => {
    this.placeForDelete = null;
  };

  changeFilter = (filter: FilterType) => {
    this.filter = filter;
  };

  async getPlacesList(filter: FilterType) {
    const { pagination } = this.page;

    try {
      const data = [
        pagination.skip,
        pagination.pageSize,
        filter.placeName,
        filter.state,
      ];

      const response = await apiRequest<PosType[] | string>({
        url: 'generalmaster.POS.POSList',
        data,
      });
      if (Array.isArray(response)) {
        this.setPlacesList(response);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setPlacesList([]);
    }
  }

  async getPlacesCount(filter: FilterType) {
    try {
      const count = await apiRequest<number>({
        url: 'generalmaster.POS.POSTotalCount',
        data: [filter.placeName, filter.state],
      });

      this.setPlacesTotal(Number(count));
    } catch (e) {
      this.setPlacesTotal(0);
    }
  }

  getPlacesMain = (filter: FilterType = this.filter) => {
    this.setFetching(true);

    const promiseCount = this.getPlacesCount(filter);

    const promiseCodesList = this.getPlacesList(filter);

    Promise.allSettled([promiseCodesList, promiseCount]).finally(() => {
      this.setFetching(false);
    });
  };

  async getPlace(id: number | string) {
    this.setFetching(true);
    try {
      const response = await apiRequest<[Required<PosEditType>]>({
        url: 'generalmaster.POS.GetViewPOS',
        data: [id],
      });
      const transportCode = Array.isArray(response)
        ? {
            ...response[0],
            refid: Number(response[0].refid),
          }
        : undefined;

      this.setPlace(transportCode);
    } catch (e) {
      this.setPlace();
    } finally {
      this.setFetching(false);
    }
  }

  async addPlace(payload: PosEditType) {
    const data = [payload.pos_code, payload.pos, payload.pos_desc];

    try {
      const result = await apiRequest<'SE' | 'E' | 'S'>({
        url: 'generalmaster.POS.AddPOS',
        data,
      });
      if (result === 'S') {
        Notification.success('Place of Service was added successfully!');
        return true;
      } else if (result === 'E') {
        Notification.warning('Place of Service with this Code already exist!');
        return false;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async updatePlace(payload: Required<PosEditType>) {
    const data = [
      payload.refid,
      payload.pos_code,
      payload.pos,
      payload.pos_desc,
    ];

    try {
      const result = await apiRequest<'E' | 'S' | 'SE'>({
        url: 'generalmaster.POS.EditPOS',
        data,
      });

      if (result === 'S') {
        Notification.success('Place of Service was updated successfully!');
        return true;
      } else if (result === 'E') {
        Notification.warning('Place of Service with this Code already exist!');
        return false;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async updatePlaceStatus({
    id,
    status,
  }: {
    id: number;
    status: 'Active' | 'Inactive';
  }) {
    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.POS.Updatestatus',
        data: [id, status],
      });
      return response === 'S';
    } catch (e) {
      return false;
    }
  }

  async updateMultiplePlacesStatus({
    ids,
    status,
  }: {
    ids: number[];
    status: 'Active' | 'Inactive';
  }) {
    const promises = ids.map((id) => this.updatePlaceStatus({ id, status }));

    this.setFetching(true);

    return Promise.allSettled(promises)
      .then((result) => {
        const count = result.reduce(
          (count, status) => (status ? ++count : count),
          0
        );
        if (count) {
          const statusMsg = status === 'Active' ? 'deactivated' : 'activated';
          Notification.success(
            `${count} Place${
              count > 1 ? 's' : ''
            } was ${statusMsg} successfully!`
          );
          return true;
        } else {
          Notification.warning('Something went wrong!');
          return false;
        }
      })
      .catch(() => false)
      .finally(() => {
        this.setFetching(false);
      });
  }

  async deletePlace() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'S' | 'SE'>({
        url: 'generalmaster.POS.DeletePOS',
        data: [this.placeForDelete],
      });
      if (result === 'S') {
        Notification.success('Place of Service was deleted!');
        this.clearPlaceForDelete();
        return true;
      } else {
        throw Error('');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }
}

export const storePlaceOfService = new PlaceOfService();
