import { ColumnType } from './Cell';

interface PropsType {
  offsetLeft?: number;
  scrollable: boolean;
  columns: ColumnType[];
}

const MapperTitle = ({ scrollable, columns, offsetLeft }: PropsType) => (
  <div className={`mapper-title${scrollable ? ' scrollable' : ''}`}>
    <div style={offsetLeft ? { marginLeft: `${offsetLeft}px` } : null}>
      {columns.map(({ title, width }, idx) => (
        <span key={`${idx}_title`} style={{ width }}>
          {title}
        </span>
      ))}
    </div>
  </div>
);

export default MapperTitle;
