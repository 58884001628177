import { useEffect } from 'react';

const RateLimit = () => {
  const handleClickBack = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.location.reload();
  };

  useEffect(() => {
    document.body.classList.remove('on-ajax');
  }, []);

  return (
    <div className="wrapper">
      <div className="error-page">
        <h1>429</h1>
        <div className="error-page-title">Too many requests received</div>
        <div className="error-page-body text-center">
          We are sorry, but you have sent to many requests to us recently.
          <br />
          Please{' '}
          <a href="/" className="text-primary" onClick={handleClickBack}>
            try again
          </a>{' '}
          later.
        </div>
      </div>
    </div>
  );
};

export default RateLimit;
