import React from 'react';

export interface OptionType extends Record<string, any> {
  label: string;
  value: number;
}

export interface ColumnType {
  title: string;
  name: string;
  width?: string | number;
}

const Cell = <T extends OptionType>({
  className,
  columns,
  style,
  data,
  onClick,
  onHover,
}: {
  className: string;
  columns: ColumnType[];
  style: React.CSSProperties;
  data: T;
  onClick: (option: T) => void;
  onHover?: (option: T) => void;
}) => (
  <li
    className={className}
    style={style}
    onClick={() => onClick(data)}
    onMouseEnter={() => (onHover ? onHover(data) : onHover)}>
    {columns.map((cell, idx) => (
      <span
        key={`${idx}_${cell.title}`}
        title={data[cell.name]}
        style={{
          width: cell.width || '100%',
        }}>
        {data[cell.name]}
      </span>
    ))}
  </li>
);

export default Cell;
