import { useForm } from 'react-hook-form';

import { DropdownCorporateWithPermission } from 'components/project/dropdown/CorporateRolesDropdown';

import { DropDownPhysician } from 'components/project/dropdown/PhysicianDropdown';
import { DropDownFacilityRole } from 'components/project/dropdown/FacilityRolesDropdown';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropdownLocation } from 'components/project/dropdown/LocationDropdown';
import { DropdownRegion } from 'components/project/dropdown/RegionDropdown';
import DropdownTechnologist from 'components/project/dropdown/TechnologistDropDown';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { DateRange } from 'components/form/dateRange';

import {
  storeModalitySummary,
  FilterType,
} from 'stores/_mobx/report/modalitySummary';

const commonClassName = 'col-md-2 col-lg-3';

interface PropsType {
  fetching: boolean;
  isFilterVisible: boolean;
  onSearch: (filter: FilterType) => void;
}

const Filter = ({ fetching, isFilterVisible, onSearch }: PropsType) => {
  const { control, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeModalitySummary.filter,
  });

  const handleClickReset = () => {
    const defaultFilterValue = storeModalitySummary.getDefaultFilter();

    reset(defaultFilterValue);
    onSearch(defaultFilterValue);
  };

  return (
    <form
      className={`row${isFilterVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSearch)}>
      <DropdownTechnologist
        name="technologistId"
        label="Technologist"
        isMulti
        isStyleDependsOnValue
        className={commonClassName}
        control={control}
      />
      <DropdownCorporateWithPermission
        name="corporateId"
        label="Corporation"
        className="col-md-4 col-lg-3"
        isMulti
        isStyleDependsOnValue
        control={control}
      />
      <DropDownPhysician
        name="physicianId"
        label="Physician"
        className={commonClassName}
        control={control}
      />
      <DropDownFacilityRole
        name="facilityId"
        label="Facility"
        isMulti
        isStyleDependsOnValue
        className={commonClassName}
        control={control}
      />
      <div />
      <DropDownPlaceOfService
        name="pos"
        label="POS"
        isMulti
        isStyleDependsOnValue
        className={commonClassName}
        control={control}
      />
      <DropDownState
        name="stateId"
        label="State"
        isMulti
        isStyleDependsOnValue
        className={commonClassName}
        control={control}
      />
      <DropdownLocation
        name="locationId"
        label="Location"
        isMulti
        isStyleDependsOnValue
        className={commonClassName}
        control={control}
      />
      <DropdownRegion
        name="regionId"
        label="Region"
        isMulti
        isStyleDependsOnValue
        className={commonClassName}
        control={control}
      />
      <div />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-6 col-lg-auto"
        control={control}
      />
      <ControlsLayout alignX="right" className="mb-3">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
