import { Route, Switch } from 'react-router-dom';

import { AccessRoute } from 'components/HOC';
import { LegacyPageRoute } from 'components/HOC';

import ExamInformation from 'page/patient-records/examInformation';
import DocManagerPage from 'page/patient-records/documentManager';
import PatientsDemographicListPage from 'page/patient-records/patientDemographics/ListPage';
import AddPatientRecordPage from 'page/patient-records/patientDemographics/patient-record/AddPatientRecordPage';
import EditPatientRecordPage from 'page/patient-records/patientDemographics/patient-record/EditPatientRecordPage';
import DocumentsOverview from 'page/patient-records/documentsOverview';
import LegacyPatientData from 'page/patient-records/legacyPatientData';
import E404 from 'page/errors/404';

import * as IDS from 'constant/pagesId/patientRecords';
import * as PATH from 'constant/path/patientRecords';

export const patientRecordsPaths = ['/patient-records', '/retention'];

const PatientRecords = () => (
  <Switch>
    <AccessRoute
      pageId={IDS.EXAM_INFO.PAGE}
      path={PATH.URL_EXAM_INFO}
      render={() => (
        <>
          <Route
            exact
            path={PATH.URL_DOCUMENT_OVERVIEW}
            component={DocumentsOverview}
          />
          <Route
            exact
            path={[
              PATH.URL_EXAM_INFO,
              PATH.URL_EXAM_INFO_EXAM_TAB,
              PATH.URL_EXAM_INFO_QA_TAB,
            ]}
            component={ExamInformation}
          />
        </>
      )}
    />

    <AccessRoute
      exact
      pageId={IDS.PATIENT_DEMOGRAPHIC.PAGE}
      path={PATH.URL_PATIENT_DEMOGRAPHIC}
      component={PatientsDemographicListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.PATIENT_DEMOGRAPHIC.ADD}
      path={PATH.URL_PATIENT_DEMOGRAPHIC_ADD}
      component={AddPatientRecordPage}
    />
    <AccessRoute
      exact
      pageId={IDS.PATIENT_DEMOGRAPHIC.EDIT}
      path={PATH.URL_PATIENT_DEMOGRAPHIC_EDIT}
      component={EditPatientRecordPage}
    />
    <AccessRoute
      pageId={IDS.DOC_MANAGER.PAGE}
      path={PATH.URL_DOC_MANAGER}
      component={DocManagerPage}
    />
    <LegacyPageRoute
      path={PATH.URL_LEGACY_PATIENT_DATA}
      component={LegacyPatientData}
    />
    <Route path="*" component={E404} />
  </Switch>
);

export default PatientRecords;
