import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validation = yupResolver(
  yup.object().shape({
    calibrationDate: yup.date().typeError('Please choose calibration date'),
    nextCalibrationDate: yup
      .date()
      .typeError('Please choose next calibration date')
      .min(
        yup.ref('calibrationDate'),
        "Next date can't be before previous celebration date"
      ),
  })
);

export default validation;
