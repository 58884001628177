import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import { Select } from 'components/form/select';
import { DateRange } from 'components/form/dateRange';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import {
  storeEmailReport,
  FilterType,
  defaultFilter,
} from 'stores/_mobx/workflow/serviceMaintenance/emailReport';

interface PropsType {
  onChange: (filter: FilterType) => void;
}

const Filter = ({ onChange }: PropsType) => {
  const { fetching, fetchingOptions, filter, optionsEmailTemplate } =
    storeEmailReport;

  const {
    control,
    formState: { isDirty },
    watch,
    reset,
  } = useForm({
    defaultValues: filter,
  });

  const handleReset = () => {
    reset(defaultFilter);
  };

  useEffect(() => {
    const subscription = watch((formValue) => {
      onChange(formValue as FilterType);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row">
      <Select
        name="emailTemplateSelect"
        label="Email Template"
        className="col-md-8 col-lg-6 col-xl-4"
        isLoading={fetchingOptions}
        options={optionsEmailTemplate}
        control={control}
      />
      <div className="col-xl-auto" />
      <DateRange
        nameFrom="dateFrom"
        nameTo="dateTo"
        nameRadio="period"
        labelFrom="From"
        labelTo="To"
        labelRadio="Mailing date"
        radioClassName="col-sm-12 col-md-auto"
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching || !isDirty}
          onClick={handleReset}
        />
      </ControlsLayout>
    </form>
  );
};

export default observer(Filter);
