import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const stringSchema = (message: string) =>
  yup.string().when('isActive', {
    is: true,
    then: yup.string().required(message),
  });

const numberSchema = (message: string) =>
  yup.number().when('isActive', {
    is: true,
    then: yup.number().typeError(message).positive(message),
  });

export const validationRequestService = yupResolver(
  yup.object().shape({
    xRayUnit: yup.object({
      deviceId: stringSchema('Please, select an X-Ray unit.'),
      details: stringSchema('Please, fill in the details.'),
    }),
    ekg: yup.object({
      deviceId: numberSchema('Please, select an EKG device.'),
      details: stringSchema('Please, fill in the details.'),
    }),
    phone: yup.object({
      deviceId: numberSchema('Please, select a Phone device.'),
      details: stringSchema('Please, fill in the details.'),
    }),
    vehicle: yup.object({
      deviceId: numberSchema('Please, select a Vehicle device.'),
      details: stringSchema('Please, fill in the details.'),
    }),
    boneSonometer: yup.object({
      deviceId: numberSchema('Please, select a Bone Sonometer device.'),
      details: stringSchema('Please, fill in the details.'),
    }),
  })
);
