/**
 * Compare the values of two objects to check if they are different.
 *
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @returns {boolean} True if the values of the objects are different, otherwise false.
 */
export const areObjectsNotEqual = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
): boolean => {
  const keysOfObj1 = Object.keys(obj1);
  const keysOfObj2 = Object.keys(obj2);

  const isLengthEqual = keysOfObj1.length === keysOfObj2.length;

  return isLengthEqual && !keysOfObj1.every((key) => obj2[key] === obj1[key]);
};