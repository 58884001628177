import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed,
} from 'mobx';

import { TPagination } from 'components/grid/GridTypes';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone } from 'utils/DateUtils';

const basicTitle = 'Service History Log';

export const defaultFilter: FilterType = {
  id: 0,
  equipmentType: {
    device: false,
    ekg: false,
    vehicle: false,
    phone: false,
    bone: false,
    xray: false,
  },
};

export interface FilterType {
  id: number;
  equipmentType: {
    device: boolean;
    ekg: boolean;
    vehicle: boolean;
    phone: boolean;
    bone: boolean;
    xray: boolean;
  };
}

interface OptionsResponse {
  data: string;
  label: string;
}

interface OptionsType {
  label: string;
  value: number;
}

interface ServicesResponseType {
  eqtype: string;
  equip_type: string;
  equipment_id: string;
  equipment_type: string;
  first_name: string;
  last_name: string;
  name: string;
  names: string;
  'ppxray_equipment_service.refid': string;
  serusername: string;
  service_userid: string;
  techname: string;
}

export interface ServicesType
  extends Omit<
    ServicesResponseType,
    'ppxray_equipment_service.refid' | 'equipment_id'
  > {
  refid: number;
  equipment_id: number;
}

interface HistoryResponse {
  created_by: string;
  created_dt: string;
  eqname: string;
  equ_status: string;
  equipment_id: string;
  equipment_type: string;
  name: string;
  'ppxray_equipment_service.created_by': string;
  'ppxray_equipment_service.created_dt': string;
  'ppxray_equipment_service.created_time': string;
  'ppxray_equipment_service.refid': string;
  reason: string;
  service_userid: string;
  status: string;
  user_id: string;
  usertype_id: string;
}

export interface HistoryType
  extends Omit<
    HistoryResponse,
    | 'ppxray_equipment_service.created_by'
    | 'ppxray_equipment_service.created_dt'
    | 'ppxray_equipment_service.created_time'
    | 'ppxray_equipment_service.refid'
  > {
  created_by: string;
  createdDate: string;
  refid: number;
}

class ServiceHistory {
  fetching: boolean = false;
  fetchingHistory: boolean = false;
  fetchingOptions: boolean = false;
  serviceLog: ServicesType[] = [];
  serviceLogTotal: number = 0;
  historyLog: HistoryType[] | null = null;
  historyLogForId: number = 0;
  options: OptionsType[] = [];
  filter: FilterType = defaultFilter;
  page: Pagination = new Pagination({ id: 'serviceHistoryGrid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingHistory: observable,
      fetchingOptions: observable,
      serviceLog: observable,
      serviceLogTotal: observable,
      historyLog: observable,
      historyLogForId: observable,
      options: observable,
      filter: observable,

      historyLogDetails: computed,

      setFetching: action,
      setFetchingHistory: action,
      setFetchingOptions: action,
      setServiceLog: action,
      setServiceLogTotal: action,
      setFilter: action,
      setHistoryLog: action,
      setOptions: action,
      clearHistoryLog: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingHistory(fetching: boolean) {
    this.fetchingHistory = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setServiceLog(logs: ServicesType[]) {
    this.serviceLog = logs;
  }

  setServiceLogTotal(total: number) {
    this.serviceLogTotal = total;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setHistoryLog(history: HistoryType[] | null) {
    this.historyLog = history;
  }

  setOptions(options: OptionsType[]) {
    this.options = options;
  }

  clearHistoryLog() {
    this.historyLog = null;
    this.historyLogForId = 0;
  }

  get historyLogDetails() {
    if (this.historyLogForId) {
      const service = this.serviceLog.find(
        ({ equipment_id }) => this.historyLogForId === equipment_id
      );

      const title = service.eqtype
        ? `${basicTitle} - ${service.names || ''}(${service.eqtype})`
        : basicTitle;

      return {
        title,
        fileName: `Service_History_Log_${service.name}_maintenance.csv`,
      };
    }
    return {
      title: basicTitle,
      fileName: 'Service_History_Log_maintenance.csv',
    };
  }

  async getServiceHistory({
    pagination,
    filter,
  }: {
    pagination: TPagination;
    filter: FilterType;
  }) {
    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.equipmentType.device,
      filter.equipmentType.ekg,
      filter.equipmentType.vehicle,
      filter.equipmentType.phone,
      filter.equipmentType.bone,
      filter.id,
    ];

    this.setFetching(true);

    try {
      const response = await apiRequest<ServicesResponseType[] | 'SE'>({
        url: 'generalmaster.ServiceHistroy.GetServiceHistroyList',
        data,
      });

      if (Array.isArray(response)) {
        const logs = response.map(
          ({
            'ppxray_equipment_service.refid': refid,
            equipment_id,
            ...record
          }) => ({
            ...record,
            equipment_id: Number(equipment_id),
            refid: Number(refid),
          })
        );

        this.setServiceLog(logs);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e: any) {
      this.setServiceLog([]);
    } finally {
      this.setFetching(false);
    }
  }

  async getServiceHistoryCount(filter: FilterType) {
    const data = [
      filter.equipmentType.device,
      filter.equipmentType.ekg,
      filter.equipmentType.vehicle,
      filter.equipmentType.phone,
      filter.equipmentType.bone,
      filter.id,
    ];
    try {
      const count = await apiRequest({
        url: 'generalmaster.ServiceHistroy.TotalRecordCount',
        data,
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setServiceLogTotal(total);
    } catch (e: any) {
      this.setServiceLogTotal(0);
    }
  }

  async getServiceHistoryMain(filter: FilterType = this.filter) {
    this.setFetching(true);

    const promiseList = this.getServiceHistory({
      pagination: this.page.pagination,
      filter: this.filter,
    });

    const promiseCount = this.getServiceHistoryCount(filter);

    return Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  }

  async getHistoryLogs(id: number, type: string) {
    runInAction(() => {
      this.fetchingHistory = true;
      this.historyLog = [];
      this.historyLogForId = id;
    });

    try {
      const response = await apiRequest<HistoryResponse[] | 'SE'>({
        url: 'generalmaster.ServiceHistroy.GetViewServiceLog',
        data: [id, type],
      });
      if (Array.isArray(response)) {
        const logs = response.map(
          ({
            'ppxray_equipment_service.created_by': created_by,
            'ppxray_equipment_service.created_dt': created_dt,
            'ppxray_equipment_service.created_time': created_time,
            'ppxray_equipment_service.refid': refid,
            ...log
          }) => ({
            ...log,
            created_by,
            createdDate: dateToLocalTimezone({
              date: `${created_dt} ${created_time}`,
            }),
            refid: Number(refid),
          })
        );

        this.setHistoryLog(logs);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e: any) {
      this.clearHistoryLog();
    } finally {
      this.setFetchingHistory(false);
    }
  }

  async getServicedOptions() {
    if (this.options.length || this.fetchingOptions) return Promise.resolve();

    try {
      const response = await apiRequest<OptionsResponse>({
        url: 'generalmaster.ServiceHistroy.ServiceDropDown',
      });

      if (Array.isArray(response)) {
        const options = response.map(({ data, label }) => ({
          label,
          value: Number(data),
        }));

        this.setOptions(options);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e: any) {
      this.setOptions([]);
    }
  }
}

export const storeServiceHistory = new ServiceHistory();
