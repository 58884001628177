import {
  makeObservable,
  observable,
  action,
  onBecomeObserved,
  toJS,
} from 'mobx';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { exporterInterface, ExporterInterface } from 'stores/_mobx/exporter';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';
import { isQrCpt } from 'utils/cptCode';
import {
  ProviderType,
  PlaceOfServiceType,
  ModalityType,
  ClaimCodeType,
  CorporateGroupType,
} from './types';

import { GRID_ID_EXAM_INFO } from 'constant/gridsId/patientRecords';

const formatPayload = (filter: FilterType) => {
  const { dosStart, dosEnd, period, patient, ...rest } = filter;

  const dos = getDateRangeBounds({
    from: dosStart,
    to: dosEnd,
  });

  const flag =
    patient.payors.payorInfo.id ||
    patient.payors.payorType ||
    patient.payors.payorPlanDetails.id
      ? { flag: 'A' }
      : {};

  const data = {
    ...rest,
    patient: {
      ...patient,
      payors: {
        ...patient.payors,
        ...flag,
      },
    },
    'scheduleServiceDateFrom': dos.dateFrom,
    'scheduleServiceDateTo': dos.dateTo,
  };

  return data;
};

export const filterDefaultValues: FilterType = {
  patient: {
    firstName: '',
    lastName: '',
    payors: {
      payorType: '',
      payorPlanDetails: { id: 0 },
      payorInfo: { id: 0 },
    },
  },
  facility: {
    id: 0,
    placeOfService: { id: 0 },
    corporateGroupMembers: {
      flag: '',
      group: { id: 0 },
    },
    facilityType: { id: 0 },
    dispatchRegion: { state: { id: 0 } },
  },
  modality: { id: 0 },
  provider: { id: 0 },
  finding: '',
  dosStart: '',
  dosEnd: '',
  period: 'A',
  claimCodes: {
    procedureCode: '',
  },
};

export interface FilterType {
  patient: {
    firstName: string;
    lastName: string;
    payors: {
      payorType: 'P' | 'S' | 'T' | '';
      payorPlanDetails: { id: number };
      payorInfo: { id: number };
    };
  };
  facility: {
    id: number;
    placeOfService: { id: number };
    corporateGroupMembers: CorporateGroupType;
    facilityType: { id: number };
    dispatchRegion: { state: { id: number } };
  };
  modality: { id: number };
  claimCodes: { procedureCode: string };
  provider: { id: number };
  finding: string;
  dosStart: string;
  dosEnd: string;
  period: string;
}

interface PayorType {
  id: number;
  payorInfo: {
    id: number;
    name: string;
  };
  payorPlanDetails?: {
    id: number;
    planName: string;
  };
  payorType: 'P' | 'S' | 'T';
  flag: 'A';
}

export interface ExamInfoType {
  claimCodesActive: ClaimCodeType[];
  diagnoses: {
    id: number;
    icdCode: {
      id: number;
      icdCode: string;
      shortDescription: string;
    };
  }[];
  id: number;
  isolationPrecautionView: string;
  facility: {
    id: number;
    facilityName: string;
    facilityType: {
      id: number;
      facilityType: string;
    };
    placeOfService?: PlaceOfServiceType;
    perDiemBillTypeView: string;
  };
  findingView: string;
  modality: ModalityType;
  patient: {
    id: number;
    firstName: string;
    lastName: string;
    genderView: string;
    birthdate: string;
    DOB: string;
    iceNumber: string;
    inmateNumber: string;
    immigrationId: string;
    patientMrn: string;
    patientMrnFormatted: string;
    clientMrn: string;
    smokingView: string;
    primaryPayor?: PayorType;
    secondaryPayor?: PayorType;
    tertiaryPayor?: PayorType;
    primaryGuarantor?: {
      id: number;
      firstName: string;
      middleName: string;
      lastName: string;
      address1: string;
      city: string;
      state: string;
      zip: string;
    };
  };
  pcBillProcessView: string;
  provider?: ProviderType;
  scheduleServiceDate: string;
  claimProceduresCodes: string;
  icdCodesDescriptions: string;
}

class ExamInfo {
  fetching: boolean = false;
  isCorporateUser: boolean = false;
  examList: ExamInfoType[] = [];
  examTotal: number = 0;
  filter: FilterType = filterDefaultValues;
  gridId = GRID_ID_EXAM_INFO;

  page = new Pagination({ id: GRID_ID_EXAM_INFO });
  exporterInterface: ExporterInterface = null;

  constructor(exporterInterface: ExporterInterface) {
    makeObservable(this, {
      fetching: observable,
      isCorporateUser: observable,
      examList: observable,
      examTotal: observable,
      filter: observable,

      setDefaultFilter: action.bound,
      setFetching: action,
      setFilter: action,
      setExamInfoData: action,
    });

    this.exporterInterface = exporterInterface;
    onBecomeObserved(this, 'filter', this.setDefaultFilter);
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setExamInfoData(props: { examList: ExamInfoType[]; examTotal: number }) {
    this.examList = props.examList;
    this.examTotal = props.examTotal;
  }

  setDefaultFilter() {
    const userRole =
      UserProfileStore.getUser().usertypename.toLocaleLowerCase();

    const isCorporateUser = userRole.includes('corporate');

    if (isCorporateUser) {
      const corporateId = Number(UserProfileStore.getUser().corporate_id) || 1;

      const filter = toJS(this.filter);

      filter.facility.corporateGroupMembers.group.id = corporateId;
      filter.facility.corporateGroupMembers.flag = 'A';
      this.filter = filter;
    }

    this.isCorporateUser = isCorporateUser;
  }

  getDefaultFilter() {
    if (this.isCorporateUser) {
      const corporateId = Number(UserProfileStore.getUser().corporate_id) || 1;

      const filter = JSON.parse(JSON.stringify(filterDefaultValues));

      filter.facility.corporateGroupMembers.group.id = corporateId;
      filter.facility.corporateGroupMembers.flag = 'A';
      return filter;
    }
    return filterDefaultValues;
  }

  async getExamList(sorting: Record<string, 'asc' | 'desc'>) {
    const {
      filter,
      page: { pagination },
    } = this;

    this.setFetching(true);

    const payload = formatPayload(filter);

    const data = {
      ...payload,
      ...sorting,
      page: pagination.page,
      itemsPerPage: pagination.pageSize,
    };

    try {
      const { items, totalItems } = await apiRequest<{
        items: ExamInfoType[];
        totalItems: number;
      }>({
        url: 'patient/exam-information',
        method: 'GET',
        contentType: 'ld',
        legacy: false,
        data,
      });

      const examList = items.map((order) => ({
        ...order,
        claimCodesActive: order.claimCodesActive.filter(
          (claim) => !isQrCpt(claim.procedureCode)
        ),
        scheduleServiceDate: dateToLocalTimezone({
          date: order.scheduleServiceDate,
          dateOnly: true,
        }),
      }));

      this.setExamInfoData({ examList, examTotal: totalItems });
    } catch (error) {
      this.setExamInfoData({ examList: [], examTotal: 0 });
    } finally {
      this.setFetching(false);
    }
  }

  runExportCsv = (columns: any[]) => {
    const filter = formatPayload(this.filter);

    this.exporterInterface.generateReport({
      columns,
      instance: '/patient/exam-information',
      source: 'PatientExamInformation',
      fileExtension: 'csv',
      filter,
    });
  };
}

export const storeExamInfo = new ExamInfo(exporterInterface);
