import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { FaxReportType } from 'stores/_mobx/workflow/order/fax';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';

interface FaxDataType {
  receiverName: string;
  faxNumber: string;
}

const initEmail: FormEmailType = {
  cc: '',
  email: '',
  message: '',
  orderId: 0,
  priority: 'Normal',
  subject: '',
  senderEmail: '',
  senderName: '',
};

export const filterDefaultValues: FilterType = {
  facilityId: 0,
  stateId: 0,
  posId: 0,
  orderType: '',
  dosStart: '',
  dosEnd: '',
  period: 'A',
  status: 'A',
  physicianId: 0,
  firstName: '',
  lastName: '',
  dob: '',
};

export interface FilterType {
  facilityId: number;
  stateId: number;
  posId: number;
  orderType: string;
  dosStart: string;
  dosEnd: string;
  status: string;
  physicianId: number;
  firstName: string;
  lastName: string;
  dob: string;
  period: string;
}

export interface FormEmailType {
  cc: string;
  email: string;
  message: string;
  orderId: number;
  priority: string;
  subject: string;
  senderEmail: string;
  senderName: string;
}

interface DocumentResponseType {
  count: string;
  data: DocumentType[];
}

interface FaxResponse {
  Fax: string;
  Phone: string;
  floor: string;
  floorName: string | null;
  refid: number;
}

export interface DocumentType {
  admin_email: string;
  bill_email: string;
  clinicalnote: string;
  dos: string;
  examtype: string;
  facility_id: number;
  facility_name: string;
  insurance: string;
  isSigned: boolean;
  order_id: number;
  patient_firstname: string;
  patient_id: number;
  patient_lastname: string;
  provider_name: string;
  resultimgsource: string;
  room: string;
}

class DocumentManagerAcquisition {
  fetching: boolean = false;
  documentList: DocumentType[] = [];
  documentTotal: number = 0;
  email?: FormEmailType;
  faxDetail: FaxReportType | null = null;
  filter: FilterType = filterDefaultValues;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'document_acquisition_tab_grid' });

    makeObservable(this, {
      fetching: observable,
      documentList: observable,
      documentTotal: observable,
      email: observable,
      faxDetail: observable,
      filter: observable,

      setFetching: action,
      setDocumentList: action,
      setDocumentTotal: action,
      setFilter: action,
      setEmail: action,
      clearEmail: action.bound,
      clearFaxDetail: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setDocumentList(list: DocumentType[]) {
    this.documentList = list;
  }

  setDocumentTotal(count: number) {
    this.documentTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setEmail({ order_id, admin_email }: DocumentType) {
    const email = {
      ...initEmail,
      orderId: order_id,
      email: admin_email,
    };
    this.email = email;
  }

  clearEmail() {
    this.email = undefined;
  }

  clearFaxDetail() {
    this.faxDetail = null;
  }

  async getDocumentList(filter: FilterType = this.filter) {
    const { pagination } = this.page;

    const { dateFrom, dateTo } = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.facilityId,
      filter.stateId,
      filter.posId,
      filter.orderType,
      dateFrom,
      dateTo,
      filter.status,
      filter.physicianId,
      filter.firstName,
      filter.lastName,
      filter.dob,
    ];

    this.setFetching(true);
    try {
      const { count, data: list } = await apiRequest<DocumentResponseType>({
        url: 'facility.DocumentManager.Get_documentuploadlist',
        data,
      });

      const documentList = list.map((document) => ({
        ...document,
        dos: dateToLocalTimezone({ date: document.dos, dateOnly: true }),
        patient_id: Number(document.patient_id),
        facility_id: Number(document.facility_id),
        order_id: Number(document.order_id),
      }));

      runInAction(() => {
        this.fetching = false;
        this.documentList = documentList;
        this.documentTotal = Number(count) || 0;
      });
    } catch (e) {
      runInAction(() => {
        this.fetching = false;
        this.documentList = [];
        this.documentTotal = 0;
      });
    }
  }

  sendEmail = async (payload: FormEmailType) => {
    const data = [
      payload.orderId,
      payload.email,
      payload.message,
      payload.cc,
      payload.subject,
      payload.priority,
      payload.senderEmail,
      payload.senderName,
    ];
    try {
      const response = await apiRequest({
        url: 'facility.DocumentManager.SendDocumentEmailArr',
        data,
      });

      if (response === 'S') {
        Notification.success('Message successfully sent!');
        this.clearEmail();
      } else {
        throw Error('');
      }
    } catch (e: any) {
      Notification.danger('Error occurred!');
    }
  };

  async showFaxDialog(document: DocumentType) {
    this.setFetching(true);
    try {
      const filePath = await apiRequest<string>({
        url: 'pdfcreater.OrderPdfGenerator.GeneratePDF',
        data: [document.order_id],
      });

      if (!/\.pdf$/.test(filePath)) throw Error('');

      const fileName = `${document.patient_firstname} ${document.patient_lastname}.pdf`;

      const response = await apiRequest<'SE' | FaxResponse[]>({
        url: 'order.Order.GetFaxPhone',
        data: [document.order_id],
      });

      const faxes: FaxDataType[] =
        Array.isArray(response) && response.length
          ? response.map(({ Fax }) => ({
              faxNumber: Fax,
              receiverName: document.facility_name,
            }))
          : [
              {
                faxNumber: '',
                receiverName: document.facility_name,
              },
            ];

      const defaultValues = {
        flag: 'OC' as 'OC',
        files: [
          {
            fileName,
            filePath: filePath.replace(/^\//, ''),
            isChecked: true,
            isCritical: false,
          },
        ],
        faxes,
      };

      const faxDetail: FaxReportType = {
        defaultValues,
        id: document.order_id,
        facility: document.facility_name,
        patient: `${document.patient_firstname} ${document.patient_lastname}`,
        room: document.room,
        examType: document.examtype,
        dos: document.dos,
      };

      runInAction(() => {
        this.fetching = false;
        this.faxDetail = faxDetail;
      });
    } catch (e: any) {
      Notification.warning('Invalid action!');
      runInAction(() => {
        this.fetching = false;
        this.faxDetail = null;
      });
    }
  }
}

export const storeDocumentManagerAcquisition = new DocumentManagerAcquisition();
