import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import FormPrefix, { FormModel } from './components/Form';

import { storePrefix } from 'stores/_mobx/systemSetup/masterSetting/prefix';
import { URL_PREFIX } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const PrefixEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, prefix } = storePrefix;

  const handleSubmit = (data: FormModel) =>
    storePrefix.updatePrefix(data as Required<FormModel>).then((response) => {
      if (response) return response;

      history.push(URL_PREFIX);
      return null;
    });

  useEffect(() => {
    storePrefix.getPrefix(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit prefix" />
      <FormPrefix
        backUrl={URL_PREFIX}
        defaultValues={prefix}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(PrefixEditPage);
