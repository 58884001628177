import React from 'react';

interface Props {
  index: number;
  args: any;
  children: any;
  isSelected: boolean;
  onClick: (index: number) => void;
  onDoubleClick: (index: number) => void;
}

const TR = ({
  children,
  index,
  isSelected,
  args,
  onClick,
  onDoubleClick,
}: Props) => {
  const cn = `
  ${args.className || ''}${isSelected ? ' mouse_selected' : ''}`;

  const handleClick = () => {
    onClick(index);
  };

  const handleDoubleClick = () => {
    onDoubleClick(index);
  };

  return (
    <tr
      {...args}
      className={cn}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}>
      {children}
    </tr>
  );
};

export default TR;
