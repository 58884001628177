import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    ultrasoundId: yup.number().test({
      test: (id: number) => id > 0,
      message: 'Please, select a device!',
    }),
    computerId: yup.number().test({
      test: (id: number) => id > 0,
      message: 'Please, select a computer!',
    }),
  })
);

export default validation;
