import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';

import { storePayerType } from 'stores/_mobx/systemSetup/masterSetting/payerType';
import { MS_PAYER_TYPE as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_PAYER_TYPE_ADD,
  URL_PAYER_TYPE,
} from 'constant/path/systemSetup';

const renderControl = (id: number) => (
  <div className="control">
    <Link className="bi bi-pencil" to={`${URL_PAYER_TYPE}/edit/${id}`} />
    <IconButton onClick={() => storePayerType.setPayerIdForDelete(id)}>
      <i className="bi bi-trash" />
    </IconButton>
  </div>
);

const COLUMNS = [
  { name: 'payortype_nm', title: 'Payer type' },
  { name: 'payor_desc', title: 'Description' },
  {
    name: 'refid',
    title: 'Action',
    className: 'width-75',
    render: renderControl,
  },
];

const PayerTypeListPage = () => {
  const {
    fetching,
    payerTypeList,
    payerTypeTotal,
    payerIdForDelete,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storePayerType;

  const debouncedAction = useDebounce(setPaginationToStart);

  const handleConfirmDelete = () => {
    storePayerType.deletePayerType().then((isSucceed) => {
      if (isSucceed) storePayerType.getPayerTypesList();
    });
  };

  const handleSetPlanName = (payerTypeName: string) => {
    storePayerType.setFilter(payerTypeName);
    debouncedAction();
  };

  useEffect(() => {
    storePayerType.getPayerTypesList();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Payer Type">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Payer type"
        className="col-sm-12 col-md-6 col-lg-4"
        value={filter}
        onChange={handleSetPlanName}
      />

      <Grid
        id="payer_type_grid"
        columns={COLUMNS}
        onAjax={fetching}
        dataSource={payerTypeList}
        dataSourceCount={payerTypeTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_PAYER_TYPE_ADD} />
        }
      />

      {payerIdForDelete !== null && (
        <DialogConfirm
          onCancel={storePayerType.clearIdForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(PayerTypeListPage);
