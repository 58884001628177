import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import { storeFax, FormModel } from 'stores/_mobx/workflow/order/fax';
import { URL_ORDER_FAX } from 'constant/path/workflow';

interface PropsType extends RouteComponentProps {}

const FaxAddPage = ({ history }: PropsType) => {
  const handleSubmit = (data: FormModel) =>
    storeFax.addFax(data).then((isSucceed) => {
      if (isSucceed) history.push(URL_ORDER_FAX);
    });

  return (
    <>
      <Title title="Add Fax" />
      <Form onSubmit={handleSubmit} />
    </>
  );
};

export default FaxAddPage;
