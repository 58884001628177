import { makeObservable, observable, action, computed } from 'mobx';

import { apiRequest } from 'services/RequestService';
import { TPagination } from 'components/grid';

type Gender = 'F' | 'M' | 'U' | '';

export type Relationship = 'SE' | 'SP' | 'CH' | 'OT';

export interface FilterType {
  lastName: string;
  firstName: string;
  ssn: string;
  dateOfBirth: string;
  facilityId: number;
  inmateNumber: string;
  iceNumber: string;
  immigrationId: number | '';
}

export interface PatientInfoType {
  client_mrn: string;
  facility_id: number;
  refid: number;
  last_name: string;
  first_name: string;
  middle_name: string;
  ssn: string;
  gender: Gender;
  dob: string;
  phone: string;
  inmate_number: string;
  ice_number: string;
  immigration_id: string;
  patient_comment?: string;
  created: boolean;
}

export interface PatientInfoExtendedType extends PatientInfoType {
  facility: string;
  state: string;
  alert_facility: string;
  patient_alert: string;
  medicaid_id: string;
  medicare_id: string;
  medicaid_stateid: string;
}

export interface PatientForOrderResponseType {
  alert_facility: string;
  client_mrn: string;
  dg_dob: string;
  dob: string;
  facility: string;
  facility_id: string;
  facility_phone: string;
  first_name: string;
  gender: Gender;
  ice_number: string;
  immigration_id: string;
  inmate_number: string;
  last_name: string;
  medicaid_id: string;
  medicaid_stateid: string;
  medicare_id: string;
  middle_name: string;
  patient_alert: string;
  patient_comment: string;
  refid: string;
  ssn: string;
  state: string;
}

export interface PatientDetailsResponseType {
  LocationArr: { label: string; data: string }[];
  Orgaddress: string;
  Orgaddress2: string;
  Orgcity: string;
  Orgstate: string;
  Orgzipcode: string;
  address1: string;
  address2: string;
  birthdate: string;
  city: string;
  city2: string;
  clientMRN: string;
  copay: string;
  dt_of_death: string | null;
  email: string;
  employer_name: string;
  fac_addr: string;
  fac_addr2: string;
  fac_city: string;
  fac_email: string;
  fac_fax: string;
  fac_phno: string;
  fac_state: string;
  fac_zipcode: string;
  facility_id: string;
  fax: string;
  first_name: string;
  first_nickname: string;
  gaddr_firstname: string;
  gaddr_lastname: string;
  gaddr_phone: string;
  gaddr_relation: Relationship | '';
  gaddr_relationship: Relationship | '';
  gender: 'M' | 'F' | '';
  guar_g_address1: string;
  guar_g_address2: string | null;
  guar_g_city: string;
  guar_g_city2: string | null;
  guar_g_dob: string;
  guar_g_email: string;
  guar_g_fax: string;
  guar_g_fnm: string;
  guar_g_gen: 'M' | 'F' | '';
  guar_g_lnm: string;
  guar_g_mid: string;
  guar_g_mobile: string;
  guar_g_phone: string;
  guar_g_relation: Relationship | '';
  guar_g_ssn: string;
  guar_g_state: string;
  guar_g_state2: string | null;
  guar_g_wphone: string;
  guar_g_zip: string;
  guar_g_zip2: string | null;
  ice_number: string;
  immigration_id: string;
  inmate_number: string;
  ins_last_verified: string;
  last_name: string;
  last_nickname: string;
  lblpatient_status: string;
  location: string;
  location_id: string;
  martial_status: string;
  medicaid_id: string;
  medicaid_stateid: string;
  medicare_id: string;
  middle_name: string;
  mobile: string;
  noinsure: string;
  org_status: string;
  organArr: { data: string; label: string }[];
  organization_id: string;
  patcomment: string;
  patient_id: string;
  patient_img_path: string;
  patient_status: 'A' | 'I';
  phone: string;

  prigroupno?: string;
  primgroup?: string;
  priminsaddr1?: string;
  priminsaddr2?: string;
  priminscity?: string;
  priminscity2?: string;
  priminsdt?: string;
  priminsemail?: string;
  priminsendt?: string;
  priminsfax?: string;
  priminsfname?: string;
  priminsgender: 'M' | 'F' | '';
  priminslname?: string;
  priminsmname?: string;
  priminsmobile?: string;
  priminsorgname?: string;
  priminspatrelation?: Relationship;
  priminsph?: string;
  priminsstate?: string;
  priminsstate2?: string;
  priminsstdt?: string;
  priminsurance_no?: string;
  priminszip?: string;
  priminszip2?: string;
  primpayorid?: string;
  primpayorname?: string;
  primpayortype?: string;
  primplanid?: string;
  primplanname?: string;
  priplan?: { label: string; value: string }[];

  secgroupno?: string;
  secgroup?: string;
  secinsaddr1?: string;
  secinsaddr2?: string;
  secinscity?: string;
  secinscity2?: string;
  secinsdt?: string;
  secinsemail?: string;
  secinsendt?: string;
  secinsfax?: string;
  secinsfname?: string;
  secinsgender: 'M' | 'F' | '';
  secinslname?: string;
  secinsmname?: string;
  secinsmobile?: string;
  secinsorgname?: string;
  secinspatrelation?: Relationship;
  secinsph?: string;
  secinsstate?: string;
  secinsstate2?: string;
  secinsstdt?: string;
  secinsurance_no?: string;
  secinszip?: string;
  secinszip2?: string;
  secpayorid?: string;
  secpayorname?: string;
  secpayortype?: string;
  secplanid?: string;
  secplanname?: string;
  secplan?: { label: string; value: string }[];

  tergroupno?: string;
  tergroup?: string;
  teryinsaddr1?: string;
  teryinsaddr2?: string;
  teryinscity?: string;
  teryinscity2?: string;
  teryinsdt?: string;
  teryinsemail?: string;
  teryinsendt?: string;
  teryinsfax?: string;
  teryinsfname?: string;
  teryinsgender: 'M' | 'F' | '';
  teryinslname?: string;
  teryinsmname?: string;
  teryinsmobile?: string;
  teryinsorgname?: string;
  teryinspatrelation?: Relationship;
  teryinsph?: string;
  teryinsstate?: string;
  teryinsstate2?: string;
  teryinsstdt?: string;
  teryinsurance_no?: string;
  teryinszip?: string;
  teryinszip2?: string;
  terpayorid?: string;
  terpayorname?: string;
  terpayortype?: string;
  teryplanid?: string;
  teryplanname?: string;
  terplan?: { label: string; value: string }[];

  refid: string;
  region: string;
  region_id: string;
  room: string;
  salutation: string;
  salutationid: string;
  smoking: string;
  spouse_name: string;
  ssn: string;
  st: string;
  state: string;
  state2: string;
  state_id: string;
  suffix: string;
  suffixid: string;
  symptomatic: string;
  user_name: string;
  user_pass: string;
  user_refid: string;
  work_phone: string;
  workingphone: string;
  yearly_deductible_paid: string;
  zipcode: string;
  zipcode2: string;
}

class Patient {
  patients: PatientInfoExtendedType[] = [];
  patientsTotal: number = 0;
  requestsCounter: number = 0;

  abortController: AbortController | null = null;

  constructor() {
    makeObservable(this, {
      patients: observable,
      patientsTotal: observable,
      requestsCounter: observable,

      fetchingPatients: computed,

      setPatients: action,
      setPatientsTotal: action,
      clearPatients: action.bound,
      incrementActiveRequests: action,
      decrementActiveRequests: action,
    });
  }

  incrementActiveRequests() {
    this.requestsCounter++;
  };

  decrementActiveRequests() {
    this.requestsCounter--;
  };

  get fetchingPatients(): boolean {
    return Boolean(this.requestsCounter);
  }

  setPatients(patients: PatientInfoExtendedType[]) {
    this.patients = patients;
  }

  setPatientsTotal(total: number) {
    this.patientsTotal = total;
  }

  clearPatients() {
    this.patients = [];
    this.patientsTotal = 0;
  }

  manageAbortController() {
    if (this.abortController) {
      this.abortController.abort();
    }
    const abortController = new AbortController();

    this.abortController = abortController;

    return abortController.signal;
  }

  async getPatientInfo(id: number) {
    try {
      const [patientInfo] = await apiRequest<[PatientDetailsResponseType]>({
        url: 'patient.PatientInfo.GetViewPatientInfo',
        data: [id],
      });

      return patientInfo;
    } catch (e: any) {
      return null;
    }
  }

  async getPatientsTotal(data: Record<string, any>, signal: AbortSignal) {
    try {
      const total = apiRequest<number>({
        url: 'patient.PatientInfo.GetinitialorderTotalCount',
        data,
        signal,
      });

      return total;
    } catch (error: unknown) {
      return 0;
    }
  }

  async getPatients(
    data: Record<string, any>,
    signal: AbortSignal
  ): Promise<PatientInfoExtendedType[]> {
    try {
      const response = await apiRequest<PatientForOrderResponseType[]>({
        url: 'patient.PatientInfo.Getinitialorderdetail',
        data,
        signal,
      });

      const patients = response.map((patient) => ({
        client_mrn: patient.client_mrn,
        facility_id: Number(patient.facility_id),
        facility: patient.facility,
        alert_facility: patient.alert_facility,
        patient_alert: patient.patient_alert,
        state: patient.state,
        user_id: Number(patient.refid),
        refid: Number(patient.refid),
        last_name: patient.last_name,
        first_name: patient.first_name,
        middle_name: patient.middle_name,
        ssn: patient.ssn,
        gender: patient.gender,
        birthdate: patient.dg_dob,
        dob: patient.dg_dob,
        phone: '',
        inmate_number: patient.inmate_number,
        ice_number: patient.ice_number,
        immigration_id: patient.immigration_id,
        patient_comment: patient.patient_comment,
        medicaid_id: patient.medicaid_id,
        medicare_id: patient.medicare_id,
        medicaid_stateid: patient.medicaid_stateid,
        created: false,
      }));

      return patients;
    } catch (error: unknown) {
      return [];
    }
  }

  async getPatientsMain(filter: FilterType, pagination: TPagination) {
    this.incrementActiveRequests();

    const abortSignal = this.manageAbortController();

    const payloadForCount = [
      0, // stateId
      filter.facilityId,
      '', // filter.cellphone,
      filter.dateOfBirth,
      filter.lastName,
      filter.firstName,
      '', // filter.middleName,
      filter.ssn,
      filter.inmateNumber,
      filter.iceNumber,
      filter.immigrationId,
    ];

    const payloadForList = [
      0, // stateId
      filter.facilityId,
      '', // filter.cellphone,
      filter.dateOfBirth,
      filter.lastName,
      filter.firstName,
      '', // filter.middleName,
      filter.ssn,
      pagination.skip,
      pagination.pageSize,
      filter.inmateNumber,
      filter.iceNumber,
      filter.immigrationId,
    ];
    try {
      const promiseCount = this.getPatientsTotal(payloadForCount, abortSignal);

      const promisePatients = this.getPatients(payloadForList, abortSignal);

      const [total, patients] = await Promise.all([
        promiseCount,
        promisePatients,
      ]);

      this.setPatients(patients);

      this.setPatientsTotal(total);
    } catch (error: unknown) {
      this.clearPatients();
    } finally {
      this.decrementActiveRequests();
    }
  }
}

export const storePatient = new Patient();
