import React from 'react';

import StringUtils from 'utils/StringUtils';
import AbstractInput, {
  PAbstractInput,
  SAbstractInput,
} from 'components/form/input/AbstractInput';

export interface CheckboxOption {
  value: number;
  label: string;
}

export interface PCheckbox extends PAbstractInput {
  checked?: boolean;
  fakeLabel?: boolean;
  flagInColumn?: boolean;
  flagInvisibleChecked?: boolean;
}

export class SCheckbox extends SAbstractInput {}

export default class Checkbox extends AbstractInput<PCheckbox, SCheckbox> {
  render() {
    const {
      className,
      fakeLabel,
      contentAfter,
      flagInColumn,
      flagInvisibleChecked,
    } = this.props;
    const dataTestId = this.props['data-testid'] || this.props.name;
    const attributes = this.getCheckBoxAttributes();
    const input = flagInvisibleChecked ? null : (
      <input
        type={attributes.type}
        key={attributes.key}
        id={attributes.id}
        name={attributes.name}
        checked={attributes.checked}
        onChange={attributes.onChange}
        disabled={attributes.disabled}
        data-testid={`${dataTestId}-field`}
      />
    );
    const labelText = this.getLabelValue();
    const labelElem = (
      <span key="lp-label">{labelText && !flagInColumn ? labelText : ' '}</span>
    );
    const elems = [input, labelElem];
    const label = this.buildLabel(
      attributes.id,
      elems,
      undefined,
      attributes.disabled
    );
    const formFieldClassName =
      'formfield-holder formfield-' +
      this.getInputType() +
      (className ? ' ' + className : '');
    const errors = this.buildErrorMessage();
    const inputElem = (
      <div className={formFieldClassName}>
        {fakeLabel ? <div className="fake-label">&nbsp;</div> : null}
        {label}
        {errors}
        {contentAfter}
      </div>
    );
    return flagInColumn
      ? this.wrapInput(inputElem, this.buildLabel(attributes.id, [labelText]))
      : inputElem;
  }

  onChangeRadio(event: any): void {
    const { checked, value } = this.props;
    const valueToSet = checked !== undefined ? !checked : !value;
    this.setValue(valueToSet, event);
  }

  getValue(): number {
    const { checked, value } = this.props;
    return checked !== undefined
      ? checked
        ? 1
        : 0
      : typeof value === 'boolean'
      ? value
      : typeof value === 'number'
      ? parseInt('' + value)
      : typeof value === 'string' &&
        !StringUtils.isBlank(value) &&
        (value === 'true' || value === '1')
      ? 1
      : 0;
  }

  getCheckBoxAttributes() {
    const { name, formId, attr } = this.props;
    const out = Object.assign({}, attr);
    out.type = this.getInputType();
    out.checked = this.getValue() ? true : false;
    out.name = name;
    out.key = 'input';
    if (!out.id) {
      out.id = formId
        ? formId + '_' + name + (attr && attr.idx ? '_' + attr.idx : '')
        : null;
    }
    if (!out.onChange) {
      out.onChange = this.onChangeRadio.bind(this);
    }
    return out;
  }

  getInputType(): string {
    return 'checkbox';
  }
}
