import CodingService, {
  TCoddingValues,
} from 'services/billing-coding/CodingService';
import OrderFormPageService, {
  TExamData,
} from 'services/workflow/order/OrderFormPageService';
import ModifierStore from 'stores/project/ModifierStore';
import { dateToLocalTimezone } from 'utils/DateUtils';

export default class CodingActions {
  static loadCodingValues(orderId: number) {
    return CodingService.loadCodingValues(orderId).then(
      (response: TCoddingValues) => {
        if (Array.isArray(response?.GetIntIcdArr)) {
          for (let i = 0; i < response.GetIntIcdArr.length; i++) {
            response.GetIntIcdArr[i].alphaButton =
              response.GetIntIcdArr[i].alphavalue;
          }
        }
        const clb = () => {
          const optionsList = ModifierStore.getState().modifierList;
          const mapModifiers = (item: any) => {
            const modifierStr = item.modifier_code
              ? item.modifier_code.trim()
              : '';
            item.modifier_code = modifierStr
              ? modifierStr.split(',').map((label: any) => {
                  const option = optionsList.find((it: any) => {
                    return label === it.label;
                  });
                  if (option) {
                    return parseInt('' + option.value);
                  }
                  return 0;
                })
              : [];
            item.modifier_code = item.modifier_code.filter((v: any) => {
              return v !== 0;
            });
            item.modifier_code = item.modifier_code.join(',');
          };
          if (response) {
            response.dos = dateToLocalTimezone({
              date: response.dos,
              dateOnly: true,
            });
            (response.GetFnlCptArr || []).forEach(mapModifiers);
            (response.GetIntCptArr || []).forEach(mapModifiers);
          }
          return response;
        };
        return clb();
      }
    );
  }

  static updateNpi(providerId: any, npi: any) {
    return CodingService.updateNpi(providerId, npi);
  }

  static sendCoding(
    orderdata: any,
    cptArr: any,
    icdArr: any,
    finalCptArr: any
  ) {
    const examData = Object.assign(new TExamData(), orderdata, {
      mode: 'CD',
      GetIntCptArr: cptArr,
      GetIcdArr: icdArr,
      BodyArea: '',
      GetFinalCptArr: finalCptArr,
      GetFinalIcdArr: [],
      FaxPhone: [],
    });
    return OrderFormPageService.AddExamInfoOrder(examData);
  }
}
