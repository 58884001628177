import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Switcher } from 'components/form/switcher';
import RadiologyGroupToModalityMappingField from './RadiologyGroupToModalityMappingField';
import resolver from './validation';

import { storeExamType } from 'stores/_mobx/systemSetup/masterSetting/examType';
import { storeDropdownRadiology } from 'stores/_mobx/dropDown/radiology';
import {
  defaultSettings,
  FacilitySettingsType,
} from 'stores/_mobx/systemSetup/facility';

const mappingFieldsName = [
  'radiologyGroupToModalityMapping.0.radiologyGroup',
  'radiologyGroupToModalityMapping.0.examType',
];

const faxSettingsFieldsName = [
  'sendFaxResultAll',
  'sendFaxResultPrioritySTAT',
  'sendFaxResultCritical',
];

const callbackSettingsFieldsName = [
  'callbackAll',
  'callbackPrioritySTAT',
  'callbackCritical',
];

const optionsForResetSettings = {
  keepDefaultValues: true,
  keepDirty: true,
  keepErrors: true,
  keepIsSubmitted: true,
  keepTouched: true,
};

interface PropsType {
  fetching: boolean;
  isBlocked: boolean;
  initialValues?: FacilitySettingsType;
  onSubmit: (payload: FacilitySettingsType) => Promise<boolean>;
}

const SettingsTab = ({
  fetching,
  isBlocked,
  initialValues = defaultSettings,
  onSubmit,
}: PropsType) => {
  const { options: optionsModality, fetchingOptions } = storeExamType;

  const { options: optionsRadiologyGroup, fetching: fetchingRadiologyOptions } =
    storeDropdownRadiology;

  const {
    control,
    formState: { isDirty, isSubmitting, isSubmitted },
    handleSubmit,
    reset,
    watch,
    trigger,
  } = useForm<FacilitySettingsType>({ defaultValues: initialValues, resolver });

  const isLoading = isSubmitting || fetching;

  const isOrderAutomationOn = watch('shouldMoveOrderToExamReview');

  const handleClickReset = () => {
    reset(initialValues);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      const isOneLine = formValue.radiologyGroupToModalityMapping.length === 1;

      const isFirstLineValueChanged = mappingFieldsName.includes(name);

      if (name === 'shouldMoveOrderToExamReview' && formValue[name]) {
        reset(
          {
            ...formValue,
            sendFaxResultAll: false,
            sendFaxResultPrioritySTAT: false,
            sendFaxResultCritical: false,
            callbackAll: false,
            callbackPrioritySTAT: false,
            callbackCritical: false,
          },
          optionsForResetSettings
        );
      } else if (isOneLine && isFirstLineValueChanged && isSubmitted) {
        trigger(
          name === 'radiologyGroupToModalityMapping.0.radiologyGroup'
            ? 'radiologyGroupToModalityMapping.0.examType'
            : 'radiologyGroupToModalityMapping.0.radiologyGroup'
        );
      } else if (faxSettingsFieldsName.includes(name)) {
        // @ts-ignore
        const newStatus: boolean = formValue[name];

        const restOfFaxSettingsFields = newStatus
          ? faxSettingsFieldsName.filter((fieldName) => fieldName !== name)
          : [];

        const newFaxSettingsOptions = restOfFaxSettingsFields.reduce(
          (prev, fieldName) => ({ ...prev, [fieldName]: !newStatus }),
          {}
        );

        reset(
          {
            ...formValue,
            ...newFaxSettingsOptions,
          },
          optionsForResetSettings
        );
      } else if (callbackSettingsFieldsName.includes(name)) {
        // @ts-ignore
        const newStatus: boolean = formValue[name];

        const restCallbackSettingsFields = newStatus
          ? callbackSettingsFieldsName.filter((fieldName) => fieldName !== name)
          : [];

        const newCallbackSettingsOptions = restCallbackSettingsFields.reduce(
          (prev, fieldName) => ({ ...prev, [fieldName]: !newStatus }),
          {}
        );

        reset(
          {
            ...formValue,
            ...newCallbackSettingsOptions,
          },
          optionsForResetSettings
        );
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, isSubmitted]);

  return (
    <div className="document-tab position-relative">
      {isBlocked ? <div className="overlay"></div> : null}
      <form
        className={isLoading ? 'on-ajax' : ''}
        onSubmit={handleSubmit(onSubmit)}>
        <legend>Radiology Group to Modality Mapping</legend>
        <small className="mb-4 d-block text-secondary">
          When a new order is created, this mapping will be checked against the
          modality of the order and will automatically assign the radiology
          group to the order based on the selection below.
        </small>
        <div className="row mb-3">
          <b className="col-md-5 col-lg-4 col-xl-3">Modality</b>
          <b className="col-md-5 col-lg-4 col-xl-3 ms-3">Radiology Group</b>
        </div>
        <div className="mb-3">
          <RadiologyGroupToModalityMappingField
            fetchingModalityOptions={fetchingOptions}
            fetchingRadiologyOptions={fetchingRadiologyOptions}
            optionsModality={optionsModality}
            optionsRadiologyGroup={optionsRadiologyGroup}
            control={control}
            watch={watch}
          />
        </div>

        <legend>Order Automation</legend>
        <small className="mb-4 d-block text-secondary">
          When Visit Notes are completed, this option will Automatically bypass
          Report Manager and Callback Manager by completing the order without
          order results.
        </small>
        <Switcher
          name="shouldMoveOrderToExamReview"
          label="Move Order to Exam Review - After Visit Notes Completed"
          control={control}
        />

        <legend>
          Fax Order Results
          {isOrderAutomationOn && (
            <small className="text-danger mx-2 fw-normal">
              (Unavailable when Order Automation - Move Order to Exam Review is
              selected)
            </small>
          )}
        </legend>
        <small className="mb-4 d-block text-secondary">
          Fax Results settings only affect “Order Results” (Patient Report)
          faxes. Even when “Off” is selected the facility will still receive all
          other faxes. Only one of the three options may be active.
        </small>
        <div className="mb-5">
          <Switcher
            name="sendFaxResultAll"
            label="Never Send Fax"
            disabled={isOrderAutomationOn}
            control={control}
          />
          <Switcher
            name="sendFaxResultPrioritySTAT"
            label="Send Only When Order Priority STAT"
            disabled={isOrderAutomationOn}
            control={control}
          />
          <Switcher
            name="sendFaxResultCritical"
            label="Send Only Critical Results"
            disabled={isOrderAutomationOn}
            control={control}
          />
        </div>

        <legend>
          Callback Order Results
          {isOrderAutomationOn && (
            <small className="text-danger mx-2 fw-normal">
              (Unavailable when Order Automation - Move Order to Exam Review is
              selected)
            </small>
          )}
        </legend>
        <small className="mb-4 d-block text-secondary">
          Send to Callback Manager for Callback on these conditions. When Order
          Automation is Move Order to Exam Review orders will never go to
          Callback Manager.
        </small>
        <div className="mb-5">
          <Switcher
            name="callbackAll"
            label="Skip Callback Manager"
            disabled={isOrderAutomationOn}
            control={control}
          />
          <Switcher
            name="callbackPrioritySTAT"
            label="Callback Only When Order Priority STAT"
            disabled={isOrderAutomationOn}
            control={control}
          />
          <Switcher
            name="callbackCritical"
            label="Callback Only Critical Results"
            disabled={isOrderAutomationOn}
            control={control}
          />
        </div>

        <ControlsLayout alignX="right">
          <Button
            variant="warning"
            type="reset"
            text="Reset"
            onClick={handleClickReset}
          />
          <Button type="submit" text="Submit" disabled={!isDirty} />
        </ControlsLayout>
      </form>
    </div>
  );
};

export default observer(SettingsTab);
