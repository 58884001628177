import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { Grid, GridControlButton } from 'components/grid';
import { PureInput } from 'components/form/textField';
import DialogConfirm from 'components/modal/dialogConfirm';
import { IconButton } from 'components/button';
import useDebounce from 'components/hook/useDebounce';

import {
  storeInbox,
  InboxType,
  EmailPriorityType,
} from 'stores/_mobx/message/inbox';
import { INBOX as PAGE_ID } from 'constant/pagesId/messages';
import { URL_INBOX } from 'constant/path/messages';

const legend = [
  { className: 'priority-N', label: 'Normal' },
  { className: 'priority-M', label: 'Warning' },
  { className: 'priority-U', label: 'Urgent' },
];

const Legend = memo(() => (
  <div className="d-flex gap-4">
    {legend.map(({ className, label }) => (
      <p key={label} className="legend">
        <i className={className} />
        <span>{label}</span>
      </p>
    ))}
  </div>
));

interface PropsType extends RouteComponentProps {}

const InboxListPage = ({ history }: PropsType) => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    inboxList,
    inboxTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeInbox;

  const [selected, setSelected] = useState<number[]>([]);

  const [idForDelete, setIdForDelete] = useState<number>(0);

  const [isDialogVisible, toggleDialog] = useState<boolean>(false);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const numbersForDelete = idForDelete ? 1 : selected.length;

  const columns = useMemo(
    () => [
      {
        name: 'from',
        title: 'From',
        render: (from: string, { isUnread }: InboxType) =>
          isUnread ? <b>{from}</b> : from,
      },
      {
        name: 'subject',
        title: 'Subject',
        render: (subject: string, { isUnread }: InboxType) =>
          isUnread ? <b>{subject}</b> : subject,
      },
      {
        name: 'priority',
        title: 'Priority',
        textAlign: 'center',
        width: 70,
        render: (priority: EmailPriorityType) => (
          <div className={`priority-${priority}`} />
        ),
      },
      {
        name: 'date',
        title: 'Date',
        render: (date: string, { isUnread }: InboxType) =>
          isUnread ? <b>{date}</b> : date,
      },
      {
        name: 'time',
        title: 'Time',
        render: (time: string, { isUnread }: InboxType) =>
          isUnread ? <b>{time}</b> : time,
      },
      {
        name: 'id',
        title: 'Action',
        className: 'text-center width-100',
        render: (id: number) => (
          <div className="control">
            <Link
              to={`${URL_INBOX}/${id}/reply`}
              className="bi bi-reply"
              title="Reply"
            />
            <Link
              to={`${URL_INBOX}/${id}/forward`}
              className="bi bi-forward"
              title="Forward"
            />
            <Link
              to={`${URL_INBOX}/${id}`}
              className="bi bi-eye-fill"
              title="View"
            />
            <IconButton onClick={() => setIdForDelete(id)}>
              <i className="bi bi-trash" />
            </IconButton>
          </div>
        ),
      },
    ],
    []
  );

  const handleCloseDialog = () => {
    toggleDialog(false);
    setIdForDelete(0);
  };
  const handleOpenDialog = () => {
    toggleDialog(true);
  };

  const handleApproveDelete = () => {
    const ids = idForDelete ? [idForDelete] : selected;

    storeInbox.moveToTrash(ids).then((isSucceed) => {
      if (isSucceed) {
        gridRef.current.clearSelection();
        storeInbox.getInboxListMain();
        storeInbox.getUnreadEmailCount();
      }
      handleCloseDialog();
      setIdForDelete(0);
    });
  };

  const handleDoubleClick = ({ id }: { id: number }) => {
    history.push(`${URL_INBOX}/${id}`);
  };

  const handleFilterChange = (value: string) => {
    storeInbox.setFilter(value);
    debouncedMethod();
  };

  useEffect(() => {
    storeInbox.getInboxListMain();
  }, [pagination]);

  useEffect(() => {
    storeInbox.getUnreadEmailCount();
  }, []);

  return (
    <div className="page-inbox">
      <Title appId={PAGE_ID.PAGE} title="Inbox" />

      <PureInput
        name="subject"
        placeholder="Subject"
        className="col-md-6 col-lg-4"
        value={filter}
        onChange={handleFilterChange}
      />

      <Legend />

      <Grid
        id="inbox_grid"
        selectId="id"
        onAjax={fetching}
        columns={columns}
        onSelectChange={setSelected}
        ref={gridRef}
        dataSource={inboxList}
        dataSourceCount={inboxTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        onDoubleClick={handleDoubleClick}
        gridControlPanel={
          <GridControlButton
            title="Move to Trash"
            disabled={!selected.length}
            onClick={handleOpenDialog}
          />
        }
      />
      {(isDialogVisible || Boolean(idForDelete)) && (
        <DialogConfirm
          onCancel={handleCloseDialog}
          onApprove={handleApproveDelete}>
          {`Do you want to move ${numbersForDelete} selected email${
            numbersForDelete > 1 ? 's' : ''
          } to the Trash?`}
        </DialogConfirm>
      )}
    </div>
  );
};

export default observer(InboxListPage);
