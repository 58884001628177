import { RouteComponentProps, withRouter } from 'react-router-dom';

import Title from 'components/project/common/title';
import Notification from 'components/modal/Notification';
import EmailForm from './EmailForm';

import InboxActions from 'actions/messages/inbox/InboxActions';
import { EmailType } from 'services/messages/inbox/InboxService';

interface PropsType extends RouteComponentProps {
  title: string;
  fetching?: boolean;
  backLink?: string;
  defaultValues?: EmailType;
}

const EmailFormWrapper = ({
  title,
  defaultValues,
  fetching,
  backLink,
  history,
}: PropsType) => {
  const handleSubmit = (data: EmailType) =>
    InboxActions.sendEmail(data).then((response) => {
      if (response === 'S') {
        const isSave = data.submitType === 'Save';
        Notification.success(
          isSave ? 'Email was saved to drafts!' : 'Message has been sent!'
        );
        if (isSave) {
          history.push('/messages/drafts');
        } else {
          history.push('/messages/inbox');
        }
      }
    });

  return (
    <>
      <Title title={title} />
      {fetching ? (
        <div style={{ height: '80vh' }} className="on-ajax" />
      ) : (
        <EmailForm
          defaultValues={defaultValues}
          backLink={backLink}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default withRouter(EmailFormWrapper);
