import { forwardRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as PropsTypeSelect,
} from 'components/form/select/Select';
import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';

import {
  storeDispatchRegions,
  OptionType,
} from 'stores/_mobx/systemSetup/dispatchRegions';

interface PropsType extends PAbstractDropdown {
  options: OptionType[];
  ref: any;
}

class RegionDropdown extends AbstractDropdown<PropsType, SAbstractDropdown> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<RegionDropdown, any>(
  (props: PAbstractDropdown, ref) => {
    const { fetchingOptions, options } = storeDispatchRegions;

    useEffect(() => {
      storeDispatchRegions.getOptions();
    }, []);

    return (
      <RegionDropdown
        {...props}
        ref={ref}
        options={options}
        fetching={fetchingOptions}
      />
    );
  }
);

export default observer(DropDownMiddleware);

export const DropdownRegion = observer(
  (props: Omit<PropsTypeSelect, 'options'>) => {
    const { options, fetchingOptions } = storeDispatchRegions;

    useEffect(() => {
      storeDispatchRegions.getOptions();
    }, []);

    return <Select {...props} options={options} isLoading={fetchingOptions} />;
  }
);
