import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import DropdownBillingJurisdiction from 'components/project/dropdown/BillingJurisdiction';
import { DropdownRegion } from 'components/project/dropdown/RegionDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import { Button } from 'components/button';
import useDebounce from 'components/hook/useDebounce';
import { ControlsLayout } from 'components/layout';

import {
  storeFacilityFeeSchedule,
  filterDefaultValue,
  FilterType,
} from 'stores/_mobx/systemSetup/feeSchedule/facility';

const RADIO_BUTTON_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Archive', value: 'archived' },
];

interface PropsType {
  isVisible: boolean;
  fetching: boolean;
  onChange: (data: FilterType) => void;
}

const Filter = ({ isVisible, fetching, onChange }: PropsType) => {
  const { control, reset, watch } = useForm<FilterType>({
    defaultValues: storeFacilityFeeSchedule.filter,
  });

  const stateId = watch('stateId');

  const debouncedChange = useDebounce(onChange);

  const handleClickReset = () => {
    reset(filterDefaultValue);
    onChange(filterDefaultValue);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (!name) return;
      if (name === 'stateId') {
        const defaultData = {
          ...(formValue as Required<FilterType>),
          jurisdictionId: 0,
        };
        reset(defaultData);
        onChange(defaultData);
      } else if (name === 'status') {
        const defaultData = {
          ...filterDefaultValue,
          status: formValue.status,
          isActive: formValue.status === 'active',
        };
        reset(defaultData);
        onChange(defaultData);
      } else if (name === 'description' || name === 'facilityName') {
        debouncedChange(formValue);
      } else {
        onChange(formValue as Required<FilterType>);
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className={`row${isVisible ? '' : ' d-none'}`}>
      <DropDownState
        name="stateId"
        label="State"
        className="col-md-6 col-lg-4 col-xl-3"
        control={control}
      />
      <DropdownBillingJurisdiction
        name="jurisdictionId"
        label="Jurisdiction"
        className="col-md-6 col-lg-4 col-xl-3"
        stateId={stateId}
        control={control}
      />
      <DropdownRegion
        name="regionId"
        label="Region"
        className="col-md-6 col-lg-4 col-xl-3"
        control={control}
      />
      <Input
        name="facilityName"
        label="Facility name"
        className="col-md-6 col-lg-4 col-xl-3"
        control={control}
      />
      <Input
        name="description"
        label="Description"
        className="col-md-6 col-lg-4 col-xl-3"
        control={control}
      />
      <Radio
        name="status"
        label="Schedule status"
        className="inline col-md-6 col-lg-auto"
        options={RADIO_BUTTON_OPTIONS}
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
