import { Container } from 'flux/utils';

import AbstractAddEquipmentCheckOut from './AbstractAddEquipmentCheckOut';
import EquipmentPageActions from 'actions/workflow/equipment/EquipmentPageActions';

export class EditEquipmentCheckOut extends AbstractAddEquipmentCheckOut {
  title = 'Edit equipment Check Out';

  componentWillUnmount() {
    EquipmentPageActions.clear();
  }
}

export default Container.create(EditEquipmentCheckOut);
