import { NpiType, AddressType } from 'stores/_mobx/npi';

interface PropsType {
  details: NpiType;
}

const AddressBlock = ({ address }: { address: AddressType }) => (
  <div className="row">
    <ul className="col-sm-6">
      <li>Address 1: {address.address_1}</li>
      {address.address_2 ? <li>Address 2: {address.address_2}</li> : null}
      {address.city ? <li>City: {address.city}</li> : null}
      {address.postal_code ? <li>ZIP: {address.postal_code}</li> : null}
    </ul>
    <ul className="col-sm-6">
      {address.state ? <li>State: {address.state}</li> : null}
      {address.fax_number ? <li>Fax: {address.fax_number}</li> : null}
      {address.telephone_number ? (
        <li>Phone: {address.telephone_number}</li>
      ) : null}
    </ul>
  </div>
);

const NpiDetailView = ({
  details: { number, basic, addresses, taxonomies },
}: PropsType) => (
  <div className="npi-details">
    <div className="m-15">
      <legend>General Information</legend>
      <ul className="row">
        <li className="col-sm-6">First Name: {basic.first_name}</li>
        <li className="col-sm-6">Last Name: {basic.last_name}</li>
        <li className="col-sm-6">
          Gender: {basic.gender === 'M' ? 'Male' : 'Female'}
        </li>
        {number ? <li className="col-sm-6">NPI: {number}</li> : null}
        <li className="col-sm-6">
          Status: {basic.status === 'A' ? 'Active' : basic.status}
        </li>
      </ul>
    </div>

    <div className="m-15">
      <legend>Address Information</legend>
      {addresses.map((address, idx) => (
        <div key={`address${idx}`} className="mb-3">
          {
            <div className="fw-bold mb-2">
              {idx ? 'Practice Address:' : 'Mailing Address:'}
            </div>
          }
          <AddressBlock address={address} />
        </div>
      ))}
    </div>

    <div className="row m-15">
      <legend>Taxonomy</legend>
      <ul className="col-sm-6">
        <li>Code: {taxonomies[0].code}</li>
        <li>Description: {taxonomies[0].desc}</li>
      </ul>
      <ul className="col-sm-6">
        <li>License: {taxonomies[0].license}</li>
        <li>State: {taxonomies[0].state}</li>
      </ul>
    </div>
  </div>
);

export default NpiDetailView;
