import React, { useEffect, useMemo, useState } from 'react';
import SignaturePad from 'signature_pad';

import { storeAuthorization } from 'stores/_mobx/auth';

export const fileToBase64 = (file: File | Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const getBase64FromUrl = async (url: string) => {
  const headerParams = storeAuthorization.getParamsForHeader();

  const data = await fetch(url, {
    headers: {
      ...headerParams,
    },
  });
  const file = await data.blob();
  return fileToBase64(file);
};

export const resizeCanvas = (
  canvas: HTMLCanvasElement,
  signaturePad: SignaturePad
) => {
  const ratio = Math.max(window.devicePixelRatio || 1, 1);

  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext('2d').scale(ratio, ratio);
  signaturePad.clear();
};

export const useOnScreen = (ref: React.MutableRefObject<HTMLCanvasElement>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isIntersecting;
};
