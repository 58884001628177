import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import { storeAuditLog } from 'stores/_mobx/billingCodding/auditLog';

const COLUMNS = [
  { name: 'user', title: 'User' },
  { name: 'message', title: 'Message' },
  { name: 'utc', title: 'Time', className: 'width-150' },
];

interface PropsType {
  invoiceId: number;
  includePayment: boolean;
  onClose: () => void;
}

const DialogAuditLog = ({ invoiceId, includePayment, onClose }: PropsType) => {
  const { fetching, logs } = storeAuditLog;

  const title = useMemo(() => `Invoice Audit Log ${invoiceId}`, [invoiceId]);

  useEffect(() => {
    storeAuditLog.getAuditLogs(invoiceId, includePayment);
  }, [invoiceId, includePayment]);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title={title} onClose={onClose} />
      <DialogBody>
        <Grid
          stateless
          disablePagination
          dataSource={logs}
          noControl
          columns={COLUMNS}
          hideSwitcher
          onAjax={fetching}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogAuditLog);
