import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import { ControlsLayout } from 'components/layout';
import { Select } from 'components/form/select';
import { Button } from 'components/button';
import resolver from './validation';

import { storeGenerator } from 'stores/_mobx/workflow/equipmentInventory/generator';
import { storeComputer } from 'stores/_mobx/workflow/equipmentInventory/computer';
import { storeDrPlate } from 'stores/_mobx/workflow/equipmentInventory/drPlate';
import { storeXRayMaster } from 'stores/_mobx/workflow/equipmentInventory/xRayMaster';

export interface FormModel {
  xray_id: number;
  computer_id: number;
  generator_id: number;
  plate_id: number;
  refid?: number;
}

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
}

const initialValues: FormModel = {
  xray_id: 0,
  computer_id: 0,
  generator_id: 0,
  plate_id: 0,
};

const FormXReyUnit = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const [optionsIsLoading, setLoading] = useState<boolean>(true);

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
  } = useForm({ defaultValues, resolver });

  const cn = clsx('col-md-8 col-lg-6 col-xl-4', {
    'on-ajax': isSubmitting || optionsIsLoading,
  });

  const handleReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useEffect(() => {
    const promiseXrayDeviceList = storeXRayMaster.getOptions();
    const promiseComputerList = storeComputer.getOptions();
    const promiseGeneratorList = storeGenerator.getOptions();
    const promisePlateList = storeDrPlate.getOptions();

    Promise.allSettled([
      promiseXrayDeviceList,
      promiseComputerList,
      promiseGeneratorList,
      promisePlateList,
    ]).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <form className={cn} onSubmit={handleSubmit(onSubmit)}>
      <Observer>
        {() => (
          <Select
            name="xray_id"
            label="X-Ray device name"
            required
            isLoading={optionsIsLoading}
            options={storeXRayMaster.options}
            control={control}
          />
        )}
      </Observer>
      <Observer>
        {() => (
          <Select
            name="computer_id"
            label="Computer name"
            required
            options={storeComputer.options}
            control={control}
          />
        )}
      </Observer>
      <Observer>
        {() => (
          <Select
            name="generator_id"
            label="Generator name"
            required
            options={storeGenerator.options}
            control={control}
          />
        )}
      </Observer>
      <Observer>
        {() => (
          <Select
            name="plate_id"
            label="X-Ray plate name"
            required
            options={storeDrPlate.options}
            control={control}
          />
        )}
      </Observer>
      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormXReyUnit;
