import React from 'react';

import Title from 'components/project/common/title';
import Notification from 'components/modal/Notification';
import DialogPermissionsLog from './DialogPermissionsLog';
import { TPrivilegeItem } from '../PrivilegesTable';
import PrivilegesWrapper from '../PrivilegesWrapper';

import UserTypePrivilegePageActions from 'actions/privileges/settings/UserTypePrivilegePageActions';
import UserTypeActions from 'actions/project/UserTypeActions';
import IterableUtils from 'utils/IterableUtils';

export interface PAbstractSettings {}

export class SAbstractSettings {
  onAjax: boolean;
  dataSource: Array<TPrivilegeItem>;
  userTypeList: Array<any>;
  showLogChangeModal: boolean;
  personSettings: Array<string>;
  filter: any = {};
}

export default abstract class AbstractSettings<
  P extends PAbstractSettings,
  S extends SAbstractSettings
> extends React.Component<P, S> {
  xhr: any = null;
  title: string = '';

  abstract getButtons(): any;
  abstract buildFormFilter(): any;
  abstract callActionToSave(
    in01: Array<string>,
    in02: Array<string>
  ): Promise<any>;

  getPageAppId() {
    return '';
  }

  render() {
    const { state } = this;
    return (
      <>
        <Title appId={this.getPageAppId()} title={this.title} />

        <div className="mb-4 d-flex gap-3">{this.getButtons()}</div>

        {this.buildFormFilter()}

        <PrivilegesWrapper
          dataSource={state.dataSource}
          onAjax={state.onAjax}
          updateSelection={this.updateSelection.bind(this)}
          personSettings={state.personSettings}
        />

        {state.showLogChangeModal && (
          <DialogPermissionsLog
            id={state.filter.userId}
            onClose={() => this.setState({ showLogChangeModal: false })}
          />
        )}
      </>
    );
  }

  findParent(dto: any) {
    const ds = this.state.dataSource;
    let parent: string = '0';
    for (let id in dto) {
      if (!dto.hasOwnProperty(id)) {
        continue;
      }
      for (let i = 0; i < ds.length; i++) {
        let item = ds[i];
        if (item.refid === id) {
          parent = item.mass_id;
          break;
        }
      }
    }
    let checked = this._isChecked(parent);
    let name = '';
    if (!checked) {
      for (let i = 0; i < ds.length; i++) {
        const item = ds[i];
        if (item.refid === parent) {
          checked = item.isselect;
          name = item.app_name;
          break;
        }
      }
    }
    return {
      checked: this._postCheck(checked, parent),
      name: name,
    };
  }

  _postCheck(checked: boolean, parent: any): boolean {
    return checked;
  }
  _isChecked(parent: string) {
    return parent === '0';
  }

  saveChanges() {
    this.setState({ onAjax: true }, () => {
      let idDto: Array<string> = [];
      let isAllowedDto: Array<string> = [];
      this.state.dataSource.forEach((item: TPrivilegeItem) => {
        if (this.ignore(item)) {
          return;
        }
        idDto.push(item.refid);
        isAllowedDto.push(this.isItemSelected(item) ? 'Y' : 'N');
      });
      this.callActionToSave(idDto, isAllowedDto).then(() => {
        this.updateData();
        Notification.success('Item saved!');
      });
    });
  }

  isItemSelected(item: TPrivilegeItem) {
    return item.isselect;
  }

  ignore(item: TPrivilegeItem) {
    return false;
  }

  updateSelection(dto: any) {
    const parentMenu = this.findParent(dto);
    if (!parentMenu.checked) {
      Notification.warning(
        'Please select ' + parentMenu.name + ' permission first'
      );
      return;
    }
    const { state } = this;
    const { dataSource } = state;
    for (let refid in dto) {
      if (!dto.hasOwnProperty(refid)) {
        continue;
      }
      for (let i = 0; i < dataSource.length; i++) {
        if (dataSource[i].refid === refid) {
          dataSource[i].isselect = dto[refid];
          break;
        }
      }
    }
    this.setState({ dataSource: IterableUtils.mergeDeep([], dataSource) });
  }

  updateData(): void {}

  componentDidMount() {
    if (this.state.userTypeList.length) {
      this.updateData();
    } else {
      UserTypeActions.loadUserTypeList().then(() => this.updateData());
    }
  }

  componentWillUnmount() {
    this.clearData();
    if (this.xhr && this.xhr.abort) {
      this.xhr.abort();
    }
  }

  clearData() {
    UserTypePrivilegePageActions.clearData();
  }
}
