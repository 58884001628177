import { ReduceStore } from 'flux/utils';
import appDispatcher from 'dispatcher/AppDispatcher';
import { dateToLocalTimezone } from 'utils/DateUtils';

let buildActionName = function (name) {
  return 'reports/report-creator/tech-patient/' + name;
};

export let Actions = {
  LOAD_TECH_PATIENT_LIST: buildActionName('load-tech-patient-list'),
};

class TechPatientPageStore extends ReduceStore {
  getEmptyDiagram() {
    return [['Technician', 'Patient count']];
  }

  getInitialState() {
    return {
      techPatientList: [],
      techPatientDiagram: this.getEmptyDiagram(),
    };
  }

  reduce(state, action) {
    switch (action.type) {
      case Actions.LOAD_TECH_PATIENT_LIST: {
        const logs = (action.data.recordval || []).map((log) => ({
          ...log,
          completeddate: dateToLocalTimezone({ date: log.completeddate }),
          dispatchdate: dateToLocalTimezone({ date: log.dispatchdate }),
          dos: dateToLocalTimezone({ date: log.dos, dateOnly: true }),
        }));
        this.getState().techPatientList = logs;
        this.getState().techPatientDiagram =
          action.data.chartval || this.getEmptyDiagram();
        break;
      }
      default:
        break;
    }
    return this.getState();
  }

  areEqual(one, two) {
    return false;
  }
}

const techPatientPageStore = new TechPatientPageStore(appDispatcher);
export default techPatientPageStore;
