import { Button } from 'components/button';
import { Grid } from 'components/grid';
import { LayoutSideTitle } from 'components/layout';

import {
  InvoiceDetailsType,
  FacilityDetailsType,
} from 'stores/_mobx/billingCodding/arAging';

const columns = [
  { name: 'studyid', title: 'Study ID' },
  { name: 'patientnm', title: 'Patient Name' },
  { name: 'bal', title: 'Charge' },
];

interface PropsType {
  facilityDetail: FacilityDetailsType;
  invoice: InvoiceDetailsType;
  onClose: () => void;
}

const InvoiceInfo = ({ invoice, facilityDetail, onClose }: PropsType) => (
  <>
    <LayoutSideTitle title={facilityDetail.facilityName}>
      <Button text="Back" variant="danger" onClick={onClose} />
    </LayoutSideTitle>

    <h1 className="text-center">** INVOICE **</h1>

    <p className="title">{facilityDetail.companyName} Info</p>
    <dl className="row">
      <dt className="col-sm-3">Address: </dt>
      <dd className="col-sm-3">{facilityDetail.adminAddress}</dd>
      <dt className="col-sm-3">Facility Name: </dt>
      <dd className="col-sm-3">{facilityDetail.facilityName}</dd>
    </dl>

    <dl className="row">
      <dt className="col-sm-3">Billing Inquiries: </dt>
      <dd className="col-sm-3">{facilityDetail.adminBillPhone}</dd>
      <dt className="col-sm-3">Address: </dt>
      <dd className="col-sm-3">{facilityDetail.address}</dd>
    </dl>

    <dl className="row">
      <dt className="col-sm-3">Main Office: </dt>
      <dd className="col-sm-3">{facilityDetail.adminPhone}</dd>
    </dl>

    <dl className="row mb-4">
      <dt className="col-sm-3">Phone: </dt>
      <dd className="col-sm-3">{facilityDetail.phone}</dd>
      <dt className="col-sm-3">Email: </dt>
      <dd className="col-sm-3">{facilityDetail.staffEmail}</dd>
    </dl>

    <Grid
      columns={columns}
      dataSource={[invoice]}
      disablePagination
      stateless
      noControl
    />

    <p className="title">Invoice details</p>
    <dl className="row">
      <dt className="col-sm-6 col-lg-3 col-xl-2">Invoice Number: </dt>
      <dd className="col-sm-6 col-lg-3 col-xl-4">{invoice.invoice_no}</dd>
      <dt className="col-sm-6 col-lg-3 col-xl-2">Invoice Date: </dt>
      <dd className="col-sm-6 col-lg-3 col-xl-4">{invoice.invoice_dt}</dd>
      <dt className="col-sm-6 col-lg-3 col-xl-2">Invoice Amount: </dt>
      <dd className="col-sm-6 col-lg-3 col-xl-4">{invoice.bal}</dd>
    </dl>
  </>
);

export default InvoiceInfo;
