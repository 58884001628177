import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { InputCalendar } from 'components/form/inputCalendar';
import { DateRange } from 'components/form/dateRange';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropdownLocation } from 'components/project/dropdown/LocationDropdown';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropdownProviderType } from 'components/project/dropdown/ProviderTypeDropdown';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { ControlsLayout } from 'components/layout';

import {
  storeTransmitUnassignment,
  filterDefaultValue,
  FilterType,
} from 'stores/_mobx/billingCodding/transmitUnassignment';
import { DropdownDivision } from 'components/project/dropdown/Division';

interface PropsType {
  isVisible: boolean;
  fetching: boolean;
  onSubmit: (data: FilterType) => void;
}

const className = 'col-md-6 col-lg-3';

const Filter = ({ isVisible, fetching, onSubmit }: PropsType) => {
  const { control, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeTransmitUnassignment.filter,
  });

  const handleClickReset = () => {
    reset(filterDefaultValue);
    onSubmit(filterDefaultValue);
  };

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <DropDownState
        name="state"
        label="State"
        className={className}
        isMulti
        isStyleDependsOnValue
        control={control}
      />
      <DropDownFacility
        name="facility"
        label="Facility"
        className={className}
        isMulti
        isStyleDependsOnValue
        control={control}
      />
      <InputCalendar
        name="emailDate"
        label="Email Date"
        className={`${className} col-xl-2`}
        control={control}
      />
      <InputCalendar
        name="faxDate"
        label="Fax Date"
        className={`${className} col-xl-2`}
        control={control}
      />
      <div />
      <DropdownLocation
        name="location"
        label="Location"
        className={className}
        isMulti
        isStyleDependsOnValue
        control={control}
      />
      <DropdownDivision
        name="division"
        label="Division"
        className={className}
        control={control}
      />
      <DropDownPlaceOfService
        name="pos"
        label="Place of Service"
        className={className}
        isMulti
        isStyleDependsOnValue
        control={control}
      />
      <DropdownProviderType
        name="providerType"
        label="Provider type"
        className={className}
        isMulti
        isStyleDependsOnValue
        control={control}
      />
      <div />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-6 col-lg auto"
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
