import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { Switcher } from 'components/form/switcher';
import MaskInput from 'components/form/maskInput';
import { FileField } from 'components/form/file';
import resolver from './validation';

import {
  storeDivisionMaster,
  defaultDivisionSettings,
  DivisionDetailsType,
} from 'stores/_mobx/systemSetup/masterSetting/divisionMaster';
import { DropDownState } from 'components/project/dropdown/StateDropdown';

interface PropsType {
  defaultValues?: DivisionDetailsType;
  onSubmit: (payload: DivisionDetailsType) => Promise<any>;
}

const Form = ({
  defaultValues = defaultDivisionSettings,
  onSubmit,
}: PropsType) => {
  const {
    formState: { isDirty, isSubmitting, isSubmitted },
    control,
    watch,
    reset,
    trigger,
    setError,
    handleSubmit,
  } = useForm<DivisionDetailsType>({ resolver });

  const isOverridingInfoEnabled = watch('overrideCompanyInformation');

  const handleClickSubmit = handleSubmit((data: DivisionDetailsType) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('name', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  const handleClickReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'overrideCompanyInformation' && isSubmitted) {
        trigger([
          'divisionCompanyAddress.address1',
          'divisionCompanyAddress.city',
          'divisionCompanyAddress.stateId',
          'divisionCompanyAddress.zipCode',
          'divisionCompanyAddress.phone',
          'divisionCompanyAddress.tollFreeNumber',
          'divisionCompanyAddress.email',
          'divisionBillingAddress.address1',
          'divisionBillingAddress.city',
          'divisionBillingAddress.stateId',
          'divisionBillingAddress.zipCode',
          'divisionBillingAddress.phone',
          'divisionBillingAddress.tollFreeNumber',
          'divisionBillingAddress.email',
        ]);
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, isSubmitted]);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useEffect(() => storeDivisionMaster.clearDivisionSettings, []);

  return (
    <form className="row" onSubmit={handleClickSubmit}>
      <Input
        name="name"
        label="Name"
        className="col-sm-12 col-lg-6 col-xl-4"
        required
        control={control}
      />
      <div />
      <Input
        name="shortName"
        label="Short Name"
        className="col-sm-12 col-lg-6 col-xl-4"
        maxLength={10}
        required
        control={control}
      />
      <div />
      <div>
        <Switcher
          name="overrideCompanyInformation"
          label="Override Company Information"
          className="mb-2"
          control={control}
        />
      </div>
      <div className="col-sm-auto mb-5 text-secondary">
        <small>
          When selected will override all Company Billing Information, from the
          Company Master, on Invoices and Fax communication for any facility
          assigned to this Division.
        </small>
      </div>

      {!isOverridingInfoEnabled && (
        <div className="col-sm-auto mb-5 text-danger">
          <small>
            PDF reports logo, Company Billing Address and Division Billing
            Address below unavailable until Override Company Information is
            turned off
          </small>
        </div>
      )}

      <FileField
        name="divisionLogo"
        label="PDF reports logo (260 x 80)"
        accept="image/jpeg"
        classNameContent="document-logo mb-4"
        disabled={!isOverridingInfoEnabled}
        required={isOverridingInfoEnabled}
        control={control}
      />

      <div className="col-lg-6 mb-5">
        <legend>Company Address</legend>
        <fieldset className="row" disabled={!isOverridingInfoEnabled}>
          <div className="fs-5 col-sm-12"></div>
          <Input
            name="divisionCompanyAddress.address1"
            label="Primary Address"
            required={isOverridingInfoEnabled}
            control={control}
          />
          <Input
            name="divisionCompanyAddress.address2"
            label="Secondary Address"
            control={control}
          />
          <Input
            name="divisionCompanyAddress.city"
            label="City"
            required={isOverridingInfoEnabled}
            control={control}
          />
          <DropDownState
            name="divisionCompanyAddress.stateId"
            label="State"
            className="col-lg-7 col-xl-8"
            disabled={!isOverridingInfoEnabled}
            required={isOverridingInfoEnabled}
            control={control}
          />
          <MaskInput
            name="divisionCompanyAddress.zipCode"
            label="Zipcode"
            format="#####-####"
            className="col-lg-5 col-xl-4"
            required={isOverridingInfoEnabled}
            control={control}
          />
          <Input
            name="divisionCompanyAddress.email"
            label="Email"
            required={isOverridingInfoEnabled}
            control={control}
          />
          <MaskInput
            name="divisionCompanyAddress.fax"
            label="Fax Number"
            format="###-###-####"
            className="col-xl-6"
            control={control}
          />
          <MaskInput
            name="divisionCompanyAddress.phone"
            label="Phone Number"
            format="###-###-####"
            className="col-xl-6"
            required={isOverridingInfoEnabled}
            control={control}
          />
          <MaskInput
            name="divisionCompanyAddress.tollFreeNumber"
            label="Toll Free Number"
            format="###-###-####"
            className="col-xl-6"
            control={control}
          />
        </fieldset>
      </div>

      <div className="col-lg-6 mb-5">
        <legend>Billing Address</legend>
        <fieldset className="row" disabled={!isOverridingInfoEnabled}>
          <Input
            name="divisionBillingAddress.address1"
            label="Primary Address"
            required={isOverridingInfoEnabled}
            control={control}
          />
          <Input
            name="divisionBillingAddress.address2"
            label="Secondary Address"
            control={control}
          />
          <Input
            name="divisionBillingAddress.city"
            label="City"
            required={isOverridingInfoEnabled}
            control={control}
          />
          <DropDownState
            name="divisionBillingAddress.stateId"
            label="State"
            className="col-lg-7 col-xl-8"
            disabled={!isOverridingInfoEnabled}
            required={isOverridingInfoEnabled}
            control={control}
          />
          <MaskInput
            name="divisionBillingAddress.zipCode"
            label="Zipcode"
            format="#####-####"
            className="col-lg-5 col-xl-4"
            required={isOverridingInfoEnabled}
            control={control}
          />
          <Input
            name="divisionBillingAddress.email"
            label="Email"
            required={isOverridingInfoEnabled}
            control={control}
          />
          <MaskInput
            name="divisionBillingAddress.fax"
            label="Billing Fax Number"
            format="###-###-####"
            className="col-xl-6"
            control={control}
          />
          <MaskInput
            name="divisionBillingAddress.phone"
            label="Billing Phone Number"
            format="###-###-####"
            className="col-xl-6"
            required={isOverridingInfoEnabled}
            control={control}
          />
          <MaskInput
            name="divisionBillingAddress.tollFreeNumber"
            label="Billing Toll Free Number"
            format="###-###-####"
            className="col-xl-6"
            control={control}
          />
        </fieldset>
      </div>
      <ControlsLayout alignX="right">
        <Button
          variant="warning"
          type="reset"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default Form;
