import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import FilterNameAndStatus, {
  FilterType,
} from 'page/components/FilterNameAndStatus';
import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import DialogServicesNotes from '../components/DialogServicesNotes';

import { storeThyroidShield } from 'stores/_mobx/workflow/equipmentInventory/thyroidShield';
import { EI_THYROID_SHIELD as PAGE_ID } from 'constant/pagesId/workflow';
import {
  URL_EQUIPMENT_INVENTORY,
  URL_THYROID_SHIELD_ADD,
  URL_THYROID_SHIELD_EDIT,
} from 'constant/path/workflow';

const ThyroidShieldsListPage = () => {
  const {
    fetching,
    thyroidShieldForDelete,
    thyroidShieldsList,
    thyroidShieldsTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeThyroidShield;

  const gridRef = useRef<Grid>(null);

  const [noteId, setNoteId] = useState<number>(0);

  const [selected, setSelected] = useState<number[]>([]);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const fetchData = () => {
    storeThyroidShield.getThyroidShieldMain();
    if (selected) gridRef.current?.clearSelection();
  };

  const columns = useMemo(
    () => [
      { name: 'thyroid_name', title: 'Name' },
      { name: 'thyroid_number', title: 'Number' },
      { name: 'last_survey', title: 'Last survey date' },
      { name: 'next_survey', title: 'Next survey date' },
      { name: 'description', title: 'Description' },
      {
        name: 'refid',
        title: 'History',
        render: (id: number) => (
          <div className="control">
            <IconButton onClick={() => setNoteId(id)}>
              <i className="icon icon-notes" />
            </IconButton>
          </div>
        ),
      },
      {
        name: 'refid',
        title: 'Actions',
        render: (id: number) => (
          <div className="control">
            <Link
              className="bi bi-pencil"
              to={URL_THYROID_SHIELD_EDIT.replace(':id', String(id))}
            />
            <IconButton
              onClick={() => {
                storeThyroidShield.setThyroidShieldForDelete(id);
              }}>
              <i className="bi bi-trash" />
            </IconButton>
          </div>
        ),
        className: 'width-100',
      },
    ],
    []
  );

  const handleConfirmDelete = () => {
    storeThyroidShield.deleteThyroidShield().then((result) => {
      if (result) {
        fetchData();
      }
    });
  };

  const handleChangeStatus = () => {
    const statusMsg = filter.options.isActive ? 'deactivated' : 'activated';

    storeThyroidShield
      .changeThyroidShieldStatus(selected, statusMsg)
      .then((result) => {
        if (result) fetchData();
      });
  };

  const handleChangeFilter = (newFilter: FilterType) => {
    filter.setOptions(newFilter);
    debouncedMethod();
  };

  const handleCloseNotes = () => {
    setNoteId(0);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Thyroid Shield">
        <Link to={URL_EQUIPMENT_INVENTORY} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <FilterNameAndStatus
        placeholder="Thyroid Shield Name"
        filter={filter.options}
        fetching={fetching}
        onChangeFilter={handleChangeFilter}
      />

      <Grid
        id="thyroid_shield_grid"
        selectId="refid"
        ref={gridRef}
        columns={columns}
        onAjax={fetching}
        dataSource={thyroidShieldsList}
        dataSourceCount={thyroidShieldsTotal}
        pagination={pagination}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        gridControlPanel={
          <>
            <GridControlButton title="Add" url={URL_THYROID_SHIELD_ADD} />
            <GridControlButton
              title={filter.options.isActive ? 'Deactivate' : 'Activate'}
              disabled={!selected.length}
              onClick={handleChangeStatus}
            />
          </>
        }
      />

      {thyroidShieldForDelete ? (
        <DialogConfirm
          onCancel={storeThyroidShield.clearThyroidShieldForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this Thyroid Shield?
        </DialogConfirm>
      ) : null}

      {noteId ? (
        <DialogServicesNotes type="T" id={noteId} onClose={handleCloseNotes} />
      ) : null}
    </>
  );
};

export default observer(ThyroidShieldsListPage);
