import { forwardRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import DialogReview from 'page/billing-coding/components/dialogReview';
import EncounterMessageDialog from 'page/components/messages/EncounterMessageDialog';
import DialogAuditLog from 'page/workflow/order/components/DialogAuditLog';
import Exporter from 'components/project/ExporterNew';
import DialogConfirm from 'components/modal/dialogConfirm';
import { Grid, GridControlButton } from 'components/grid';
import { PureSelect } from 'components/form/select';
import FilterTC from './FilterTC';
import FilterPC from './FilterPC';
import {
  exporterNameMapper,
  gridIdsMapper,
  getColumnsForExport,
} from './helpers';

import {
  storeBillingAssignment,
  FilterTCType,
  FilterPCType,
  BillingAssignmentPcType,
  BillingAssignmentCommonType,
  OptionType,
} from 'stores/_mobx/billingCodding/billingAssignment';
import Pagination from 'stores/_mobx/options/pagination';
import UserProfileStore from 'stores/UserProfileStore';
import { BILLING_ASSIGNMENT as PAGE_ID } from 'constant/pagesId/billingCodding';

type ResponsibleType = BillingAssignmentCommonType['responsible'];

interface PropsType {
  mode: 'TC' | 'PC';
  isFilterVisible: boolean;
  isTcBillProcess?: boolean;
  filter: FilterPCType | FilterTCType;
  columns: Record<string, any>[];
  columnsVisibleByDefault: Map<string, boolean>;
  optionsBillProcess: OptionType[];
  billingAssignments: BillingAssignmentPcType[] | BillingAssignmentCommonType[];
  billingAssignmentsTotal: number;
  page: Pagination;
  fetchData: () => void;
  onApplyFilter: (filter: FilterPCType | FilterTCType) => void;
}

const TabComponent = forwardRef<Grid, PropsType>((props, gridRef) => {
  const {
    isFilterVisible,
    columns,
    columnsVisibleByDefault,
    billingAssignments,
    billingAssignmentsTotal,
    filter,
    mode,
    isTcBillProcess = false,
    optionsBillProcess,
    page: { pagination, setPagination },
    onApplyFilter,
    fetchData,
  } = props;

  const { fetching, paramsForReview, paramsForAuditLog, idForNotes } =
    storeBillingAssignment;

  const [selected, setSelected] = useState<string[]>([]);

  const [isAssignVisible, toggleAssignDialog] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const [allResponsible, setAllResponsible] = useState<ResponsibleType>('');

  const isAllSelected =
    selected.length && selected.length === billingAssignments.length;

  const gridId = gridIdsMapper[mode];

  const exporterName = exporterNameMapper[mode];

  const labelBillProcessForAll = `Set ${
    isTcBillProcess ? 'Technical' : 'Professional'
  } Bill Process for all`;

  const handleClickApplyFilter = (filter: FilterPCType | FilterTCType) => {
    onApplyFilter(filter);
    // @ts-ignore
    gridRef.current.clearSelection();
  };

  const handleToggleAssignDialog = () => {
    toggleAssignDialog((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleChangeBillProcessForAll = (responsible: ResponsibleType) => {
    setAllResponsible(responsible);

    storeBillingAssignment.updateBillProcessForAll({
      responsible,
      isTcBillProcess,
    });
  };

  const handleCloseIdForNotes = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      fetchData();
    }
    storeBillingAssignment.clearIdForNotes();
  };

  const handleDisplayVisitInfo = ({ studyId }: BillingAssignmentCommonType) => {
    storeBillingAssignment.getVisitDetails(studyId);
  };

  const handleCheckRow = (ids: string[]) => {
    const selectedIds = ids.filter((claimUniqId: string) => {
      const row = billingAssignments.find(
        (entry) => entry.claimUniqId === claimUniqId
      );
      return Boolean(row.responsible);
    });

    // @ts-ignore
    gridRef.current.selectGroupOfRows(selectedIds);
    setSelected(selectedIds);
  };

  const handleConfirmAssign = () => {
    const assignments = selected.map((id) => {
      const item = billingAssignments.find(
        ({ claimUniqId }) => claimUniqId === id
      );

      return {
        claimUniqId: item.claimUniqId,
        studyId: item.studyId,
        patientId: item.patientId,
        pcBillProcess: item.responsible,
        tcBillProcess: item.responsible,
      };
    });

    const payload = {
      assignments,
      assigned: filter.assigned,
      isTcAssignment: isTcBillProcess,
    };
    storeBillingAssignment
      // @ts-ignore
      .reassignBillingAssignment(payload)
      .then((isSucceed) => {
        if (isSucceed) {
          fetchData();
          // @ts-ignore
          gridRef.current.clearSelection();
        }
      });

    toggleAssignDialog(false);
  };

  return (
    <>
      {isTcBillProcess ? (
        <FilterTC
          fetching={fetching}
          isVisible={isFilterVisible}
          defaultValues={filter as FilterTCType}
          onSubmit={handleClickApplyFilter}
        />
      ) : (
        <FilterPC
          fetching={fetching}
          isVisible={isFilterVisible}
          defaultValues={filter as FilterPCType}
          onSubmit={handleClickApplyFilter}
        />
      )}

      <div className="row justify-content-end">
        <PureSelect
          name="billProcessForAll"
          className="col-md-6 col-lg-4 col-xl-3"
          label={labelBillProcessForAll}
          value={allResponsible}
          options={optionsBillProcess}
          disabled={!isAllSelected}
          onChange={handleChangeBillProcessForAll}
        />
      </div>

      <Grid
        selectId="claimUniqId"
        id={gridId}
        headerGroups
        ref={gridRef}
        onAjax={fetching}
        columns={columns}
        dataSource={billingAssignments}
        dataSourceCount={billingAssignmentsTotal}
        shownColumns={columnsVisibleByDefault}
        pagination={pagination}
        onDoubleClick={handleDisplayVisitInfo}
        onPaginationChange={setPagination}
        onSelectChange={handleCheckRow}
        gridControlPanel={
          <>
            <GridControlButton
              disabled={!selected.length}
              title={filter.assigned === 'N' ? 'Assign' : 'Re-assign'}
              onClick={handleToggleAssignDialog}
            />
            <GridControlButton
              disabled={!billingAssignmentsTotal}
              title="Export"
              onClick={handleToggleExport}
            />
          </>
        }
      />

      {paramsForAuditLog && (
        <DialogAuditLog
          order={paramsForAuditLog}
          onClose={storeBillingAssignment.clearParamsForAuditLog}
        />
      )}

      {isAssignVisible && (
        <DialogConfirm
          onApprove={handleConfirmAssign}
          onCancel={handleToggleAssignDialog}>
          Do you want to assign the orders?
        </DialogConfirm>
      )}

      {idForNotes ? (
        <EncounterMessageDialog
          id={idForNotes}
          onClose={handleCloseIdForNotes}
        />
      ) : null}

      {paramsForReview && (
        <DialogReview
          onClose={storeBillingAssignment.clearParamsForReview}
          params={paramsForReview}
        />
      )}

      {isExportVisible && (
        <Exporter
          exporter={exporterName}
          reportName={
            UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
            'UserExpenseReport'
          }
          columns={getColumnsForExport({ columns, mode })}
          filterForRequest={storeBillingAssignment.prepareFilterForExport(
            isTcBillProcess
          )}
          // @ts-ignore
          gridRef={gridRef}
          dataSource={billingAssignments}
          filter={filter}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
});

export default observer(TabComponent);
