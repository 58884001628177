import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import BarChart from 'components/project/common/charts/BarChart';
import Exporter from 'components/project/ExporterNew';
import { Button } from 'components/button';
import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import Filter from './components/Filter';

import {
  storeTechReviewExam,
  FilterType,
} from 'stores/_mobx/report/techReviewExam';
import UserProfileStore from 'stores/UserProfileStore';
import { TECH_EXAM_REVIEW as PAGE_ID } from 'constant/pagesId/reports';

const columns = [
  { name: 'patientlastname', title: 'Patient Last Name' },
  { name: 'patientfirstname', title: 'Patient First Name' },
  { name: 'faci_name', title: 'Ordering Facility' },
  { name: 'dos', title: 'Service Date', className: 'text-nowrap' },
  { name: 'techname', title: 'Technologist' },
  { name: 'priority', title: 'Priority', className: 'text-nowrap' },
  {
    name: 'dispatched_date',
    title: 'Dispatched Time',
  },
  {
    name: 'completed_date',
    title: 'Exam Completed Time',
  },
  { name: 'Totaltime', title: 'Total Exam Time' },
  { name: 'proc_count', title: 'Procedure Count' },
  { name: 'NoofPat', title: 'Patient Count During Trip' },
];

const TechReviewExams = () => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    finalLogList,
    logTotal,
    chartData,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeTechReviewExam;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isChartVisible, toggleChart] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleChart = () => {
    toggleChart((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleApplyFilter = (filter: FilterType) => {
    storeTechReviewExam.setFilter(filter);

    setPaginationToStart();
  };

  useLayoutEffect(() => {
    storeTechReviewExam.setDefaultFilter();
  }, []);

  useEffect(() => {
    storeTechReviewExam.getLogData();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Visit Schedule Audit">
        <Button text="Chart view" onClick={handleToggleChart} />
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        fetching={fetching}
        isVisible={isFilterVisible}
        onSubmit={handleApplyFilter}
      />

      {isChartVisible && <BarChart data={chartData} />}

      <Grid
        id="tech_review_exams_gridId"
        noDataText="Please refine your search using the filters above"
        ref={gridRef}
        columns={columns}
        onAjax={fetching}
        dataSource={finalLogList}
        dataSourceCount={logTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Export" onClick={handleToggleExport} />
        }
      />
      {isExportVisible && (
        <Exporter
          exporter="TechReviewExamsExporter"
          reportName={
            UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
            'Visit Schedule Audit'
          }
          filter={storeTechReviewExam.prepareFilterForExport()}
          gridRef={gridRef}
          columns={columns}
          dataSource={finalLogList}
          pdfExporter={storeTechReviewExam.generatePdf}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default observer(TechReviewExams);
