// @ts-ignore
import useFluxStore from 'flux-hooks';

import { SpinnerFixed } from 'components/spinner';
import EligibilityPopup, {
  TOrder4EligibilityPopup,
} from 'page/workflow/order/eligibility/EligibilityPopup';

import OrganizationStore from 'stores/project/OrganizationStore';
import { InsuranceDetailsType } from 'stores/_mobx/billingCodding/proFfsReport';

interface PropsType {
  details: InsuranceDetailsType;
  onClose: (shouldUpdate: boolean) => void;
}

const EligibilityPopupWrapper = ({ details, onClose }: PropsType) => {
  const patientInsurance = useFluxStore(
    OrganizationStore,
    (prevState: any, store: any) => store.getState().patientInsurance
  );

  return patientInsurance ? (
    <EligibilityPopup
      order={{
        ...new TOrder4EligibilityPopup(),
        ...patientInsurance,
        ...details,
      }}
      eligibility="ONLY_SAVE"
      callback={onClose}
    />
  ) : (
    <SpinnerFixed />
  );
};

export default EligibilityPopupWrapper;
