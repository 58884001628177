import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { endOfMonth } from 'date-fns';

import { DropDownApplicationUserType } from 'components/project/dropdown/ApplicationUsersDropdown';
import { DropDownYear } from 'components/project/dropdown/YearSelectionDropdown';
import { DropDownMonth } from 'components/project/dropdown/MonthSelectionDropdown';
import { Input } from 'components/form/textField';
import { InputCalendar } from 'components/form/inputCalendar';
import { Button } from 'components/button';
import resolver from './validation';

import {
  storeHouseByEmployee,
  FilterType,
  defaultFilterValue,
} from 'stores/_mobx/report/hoursByEmployee';

interface PropsType {
  fetching: boolean;
  isVisible: boolean;
  onSubmit: (data: FilterType) => void;
}

const Filter = ({ fetching, isVisible, onSubmit }: PropsType) => {
  const { control, reset, handleSubmit, watch, setValue } = useForm<FilterType>(
    {
      defaultValues: storeHouseByEmployee.filter,
      resolver,
    }
  );

  const [month, year] = watch(['month', 'year']);

  const [minDate, maxDate] = useMemo(() => {
    if (year) {
      return [
        new Date(year, month || 0),
        endOfMonth(new Date(year, month || 11)),
      ];
    }
    return [null, null];
  }, [month, year]);

  const handleClickReset = () => {
    reset(defaultFilterValue);
    onSubmit(defaultFilterValue);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'year' || name === 'month') {
        setValue('dateFrom', '');
        setValue('dateTo', '');
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <DropDownApplicationUserType
        name="employeeTypeId"
        label="Employee Type"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <Input
        name="employeeName"
        label="Employee Name"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <div />
      <DropDownYear
        className="col-md-6 col-lg-3 col-xl-2"
        label="Select Year"
        name="year"
        control={control}
      />
      <DropDownMonth
        className="col-md-6 col-lg-3 col-xl-2"
        label="Select Month"
        name="month"
        control={control}
      />
      <InputCalendar
        name="dateFrom"
        label="Date From"
        className="col-md-6 col-lg-3 col-xl-2"
        minDate={minDate}
        maxDate={maxDate}
        showDisabledMonthNavigation
        control={control}
      />
      <InputCalendar
        name="dateTo"
        label="Date To"
        className="col-md-6 col-lg-3 col-xl-2"
        minDate={minDate}
        maxDate={maxDate}
        showDisabledMonthNavigation
        control={control}
      />
      <div className="d-flex gap-3 justify-content-end col-xl-4 align-self-end mb-4">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button type="submit" text="Apply" disabled={fetching} />
      </div>
    </form>
  );
};

export default Filter;
