import { useState } from 'react';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { PureTextarea } from 'components/form/textarea';

import {
  PayloadUpdateType,
  ParamsForUpdate,
} from 'stores/_mobx/workflow/serviceMaintenance/taskManager';

const cn = 'col-sm-6';

interface PropsType {
  service: ParamsForUpdate;
  onCancel: () => void;
  onApprove: (payload: PayloadUpdateType) => void;
}

const DialogServiceForm = ({ service, onCancel, onApprove }: PropsType) => {
  const [statusText, setText] = useState<string>('');

  const [errorMessage, setError] = useState<string>('');

  const handleClickSubmit = (statusCode: string) => {
    if (!statusText) {
      setError('Please, fill in details!');
    } else {
      onApprove({
        statusCode,
        statusText,
      });
    }
  };

  const handleType = (text: string) => {
    setText(text);
    if (errorMessage) setError('');
  };

  return (
    <Dialog size="large" handleClose={onCancel}>
      <DialogHeader title="Service Area" onClose={onCancel} />
      <DialogBody className="assign-order">
        <div className="row mb-3">
          <div className={cn}>
            <b>Equipment Name</b>
            <br />
            <span>{service.equipmentName}</span>
          </div>
          <div className={cn}>
            <b>Requested Date</b>
            <br />
            <span>{service.requestDate}</span>
          </div>
        </div>
        <div className="row mb-3">
          <div className={cn}>
            <b>Requested By</b>
            <br />
            <span>{service.requestBy}</span>
          </div>
          <div className={cn}>
            <b>Requested Status</b>
            <br />
            <span>
              {service.equipmentStatus === 'U' ? 'Usable' : 'Under Service'}
            </span>
          </div>
        </div>

        <PureTextarea
          name="serviceDetails"
          label="Service Details"
          required
          rows={4}
          errorMessage={errorMessage}
          value={statusText}
          onChange={handleType}
        />
      </DialogBody>
      <DialogFooter>
        {service.equipmentStatus === 'O' ? (
          <Button text="Usable" onClick={() => handleClickSubmit('U')} />
        ) : null}

        <Button text="Repaired" onClick={() => handleClickSubmit('A')} />

        {service.equipmentStatus === 'U' ? (
          <Button text="Under Repair" onClick={() => handleClickSubmit('O')} />
        ) : null}
      </DialogFooter>
    </Dialog>
  );
};

export default DialogServiceForm;
