import { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Button, IconButton } from 'components/button';
import { PureSelect } from 'components/form/select';
import { Grid } from 'components/grid';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';

import {
  storePatientDemographic,
  PatientType,
} from 'stores/_mobx/patientRecord/patientDemographic';

const optionsFormatter = (list: PatientType[]) =>
  list.map(({ vw_patientid, refid }) => ({
    label: vw_patientid,
    value: Number(refid),
  }));

interface PropsType {
  mergeList: PatientType[];
  onSucceed: () => void;
  onClose: () => void;
}

const DialogPatientMerge = ({ mergeList, onClose, onSucceed }: PropsType) => {
  const { mergeInProgress } = storePatientDemographic;

  const [activePatientId, setActivePatientId] = useState<number>(0);

  const [patients, setPatientsList] = useState<PatientType[]>(mergeList);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const options = useMemo(() => optionsFormatter(patients), [patients]);

  const COLUMNS = [
    { name: 'vw_patientid', title: 'MRN' },
    { name: 'first_name', title: 'First name' },
    { name: 'last_name', title: 'Last name' },
    { name: 'ssn', title: 'SSN' },
    { name: 'birthdate', title: 'DOB', className: 'text-nowrap' },
    {
      name: 'refid',
      title: 'Action',
      className: 'width-100 text-center',
      render: (value: number) => (
        <IconButton
          onClick={() => removeMergePatient(value)}
          className="text-primary">
          <i className="bi bi-trash" />
        </IconButton>
      ),
    },
  ];

  const handleSelectActivePatient = (id: number) => {
    setErrorMessage('');
    setActivePatientId(id);
  };

  const handleClickReset = () => {
    setActivePatientId(0);
    setPatientsList(mergeList);
    setErrorMessage('');
  };

  const removeMergePatient = (id: number) => {
    const filteredPatients = patients.filter(
      ({ refid }) => Number(refid) !== id
    );

    if (!filteredPatients.length) {
      onClose();
    }

    if (activePatientId === id) {
      setActivePatientId(0);
    }

    setPatientsList(filteredPatients);
  };

  const handleMerge = () => {
    if (activePatientId) {
      storePatientDemographic
        .mergePatients({ activePatientId, patients: options })
        .then((isSucceed) => {
          if (isSucceed) {
            onSucceed();
          }
        });
    } else {
      setErrorMessage('Select active patient first!');
    }
  };

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Patients merging" onClose={onClose} />
      <DialogBody className={mergeInProgress ? 'on-ajax' : ''}>
        <div className="row">
          <PureSelect
            className="col-sm-10 col-md-8 col-lg-6"
            placeholder="Select patient record"
            errorMessage={errorMessage}
            options={options}
            value={activePatientId}
            onChange={handleSelectActivePatient}
          />
        </div>
        <Grid
          disablePagination
          columns={COLUMNS}
          dataSource={patients}
          dataSourceCount={patients.length}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          variant="warning"
          text="Reset"
          disabled={mergeInProgress}
          onClick={handleClickReset}
        />
        <Button
          text="Merge"
          disabled={patients.length < 2 || mergeInProgress}
          onClick={handleMerge}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogPatientMerge);
