import { apiRequest } from 'services/RequestService';

class PhysicianExamCountPageService {
  loadPhysicianExamCountList(conditionList, joint) {
    return apiRequest({
      url: 'report.ReportMaster.GetExamCounByPhysician',
      data: [conditionList, joint],
    }).then((response) => {
      if (response && response.chartval) {
        const newChartValues = [];
        newChartValues.push(['Data', 'Value']);
        let chartValues = response.chartval;
        chartValues.forEach((item) => {
          newChartValues.push([item.lbl, parseInt(item.amt)]);
        });
        response.chartval = newChartValues;
        return response;
      } else {
        return {};
      }
    });
  }

  createPdf(physicianExamCountList, filterString) {
    return apiRequest({
      url: 'pdfcreater.OrderPdfGenerator.GeneratePDFExamCountByPhy',
      data: [physicianExamCountList, filterString],
    });
  }
}

export default new PhysicianExamCountPageService();
