import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { InputPassword } from 'components/form/textField';
import { validationPin as resolver } from './validation';

const defaultValues: FormModel = {
  ipin: '',
};

export interface FormModel {
  ipin: string;
}

interface PropsType {
  onCancel: () => void;
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormPin = ({ onCancel, onSubmit }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const formCn = `login-form${isSubmitting ? ' on-ajax' : ''}`;

  return (
    <form className={formCn} onSubmit={handleSubmit(onSubmit)}>
      <div className="login-form-body">
        <InputPassword
          name="ipin"
          label="PIN"
          autoFocus
          required
          control={control}
        />

        <div className="d-flex gap-5">
          <Button
            type="button"
            variant="danger"
            text="Cancel"
            fullWidth
            onClick={onCancel}
          />
          <Button
            type="submit"
            text="Login"
            fullWidth
            disabled={!isDirty || isSubmitting}
          />
        </div>
      </div>
    </form>
  );
};

export default FormPin;
