import { memo } from 'react';
import { Observer } from 'mobx-react-lite';

import { GridControlButton } from 'components/grid';

import { storeModifiers } from 'stores/_mobx/systemSetup/codeSet/modifiers';
import AccessUtils from 'utils/AccessUtils';
import { URL_MODIFIER_ADD } from 'constant/path/systemSetup';
import { CODES_SET_MODIFIERS as PAGE_ID } from 'constant/pagesId/systemSetup';

interface PropsType {
  selected: number[];
  onChangeStatus: () => void;
  onToggleImport: () => void;
}

const GridControl = ({
  selected,
  onChangeStatus,
  onToggleImport,
}: PropsType) => (
  <>
    {AccessUtils.checkAccess(PAGE_ID.ADD) && (
      <GridControlButton title="Add" url={URL_MODIFIER_ADD} />
    )}
    <Observer>
      {() => {
        const isActive = storeModifiers.filter.status === 'A';

        return (
          AccessUtils.checkAccess(
            isActive ? PAGE_ID.DEACTIVATE : PAGE_ID.ACTIVATE
          ) && (
            <GridControlButton
              title={isActive ? 'Deactivate' : 'Activate'}
              onClick={onChangeStatus}
              disabled={!selected.length}
            />
          )
        );
      }}
    </Observer>
    {AccessUtils.checkAccess(PAGE_ID.IMPORT) && (
      <GridControlButton title="Import" onClick={onToggleImport} />
    )}
  </>
);

export default memo(GridControl);
