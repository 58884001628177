import UserTypePrivilegePageService from 'services/privelegies/settings/UserTypePrivilegePageService';
import appDispatcher from 'dispatcher/AppDispatcher';

export default class UserTypePrivilegePageActions {
  static loadApplications(userId: number, userTypeId: number, flag: boolean) {
    return UserTypePrivilegePageService.loadApplications(
      userId,
      userTypeId,
      flag
    ).then((response) => {
      appDispatcher.dispatch({
        type: 'user-type-privilege-settings-overview-action',
        data: response,
      });
    });
  }

  static saveChanges(
    userType: number,
    ids: Array<number>,
    isAllowed: Array<string>
  ) {
    return UserTypePrivilegePageService.saveChanges(userType, ids, isAllowed);
  }

  static clearData() {
    appDispatcher.dispatch({
      type: 'user-type-privilege-settings-overview-action',
      data: [],
    });
  }
}
