import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import UserSelectionStore from 'stores/project/UserSelectionStore';
import { VaccinationDetailType } from 'stores/_mobx/users/vaccination';

export class ApplicationUsersAddEditModel {
  userinfo_id: number = null;
  emailTemplates: Array<any> = [];
  userTypeToggler: string = '';
  userType: number = null;
  userTitle: string = '';
  messaging: string = '';
  licenses: string = '';
  //
  salutation: string = '';
  lastName: string = '';
  firstName: string = '';
  middleName: string = '';
  qualification: string = '';
  dateOfBirth: string = '';
  gender: string = '';
  //
  username: string = '';
  password: string = '';
  repeatPassword: string = '';
  //
  facility: string = '';
  state: string = '';
  region: string = '';
  location: string = '';
  //
  phone: string = '';
  city: string = '';
  address: string = '';
  zipCode: string = '';
  officeState: string = '';
  floor: string = '';
  //
  contactAddress: string = '';
  contactCity: string = '';
  contactState: string = '';
  contactZipCode: string = '';
  contactPhone: string = '';
  contactMobile: string = '';
  contactMobileOperator: string = '';
  //
  email: string = '';
  fax: string = '';
  emergencyName: string = '';
  emergencyPhone: string = '';
  spouseName: string = '';
  linkedIdName: string = '';
  technologist: boolean = false;
  serviceUser: boolean = false;
  ssn: string = '';
  //
  signature: string = '';
  comments: string = '';
  //
  imageUpload: string = '';
  signatureUpload: string = '';
  //
  otherDocuments: string = '';
  //
  designation: string = '';
  created_by: string = '';
  user_id: string = '';
  corporateId: string = '';
  staffInfoRefid: string = '';
  phy_group: string = '';
  dea: string = '';
  ein: string = '';
  ein_name: string = '';
  npi: string = '';
  provider_level: string = '';
  upin: string = '';
  website: string = '';
  notify_supervisor: string = '';
  pecos: string = '';
  provider_status: string = '';
  co_sign_required: string = '';
  clia_number: string = '';
  supervisor: string = '';
  referring_nos: string = '';
  taxonomy_code: string = '';
  emailnotify: string = 'W';
  emailnotify_period: string = '';
  notificationEmail: string = '';
  speciality: string = '';
  isPhysicianAdmin: boolean = false;
  supervisedGroup: string = '';
  radiologyGroups: string = '';
  //
  clinicalGroup: Array<any> = [];
  //
  state_0: string = '';
  licenseno_0: string = '';
  vaccinations: VaccinationDetailType[] = [];
}

export class SApplicationUsersAddEditStore {
  model: ApplicationUsersAddEditModel = new ApplicationUsersAddEditModel();
  facility: any = {};
}

class ApplicationUsersAddEditStore extends ReduceStore<
  SApplicationUsersAddEditStore,
  CommonPayload
> {
  getInitialState() {
    return new SApplicationUsersAddEditStore();
  }

  areEqual(
    state1: SApplicationUsersAddEditStore,
    state2: SApplicationUsersAddEditStore
  ) {
    return false;
  }

  reduce(state: SApplicationUsersAddEditStore, action: CommonPayload) {
    switch (action.type) {
      case 'application-users-edit-load-action-clear':
        const initial = this.getInitialState();
        this.getState().model = initial.model;
        this.getState().facility = {};
        break;
      case 'application-users-edit-load-action':
        const { userInfo, staffInfo, clinicalGroup } = action.data;
        this.getState().model = Object.assign(
          new ApplicationUsersAddEditModel(),
          {
            userinfo_id: userInfo.refid,
            emailTemplates: userInfo.emailTemplates,
            userTypeToggler: UserSelectionStore.isTech(userInfo.usertype_id)
              ? 'B'
              : 'A',
            userType: userInfo.usertype_id,
            userTitle: userInfo.user_title,
            messaging: staffInfo.mail_type,
            licenses: userInfo.licenses,

            salutation: staffInfo.salutation || staffInfo.prefix,
            lastName: staffInfo.last_name,
            firstName: staffInfo.first_name,
            middleName: staffInfo.middle_name,
            qualification: staffInfo.suffix || staffInfo.qualification,
            dateOfBirth: staffInfo.birthdate,
            gender: staffInfo.gender,

            username: userInfo.user_name,
            password: userInfo.user_pass,
            repeatPassword: userInfo.user_pass,

            facility: userInfo.facility_id,
            state: userInfo.state,
            region: userInfo.region,
            location: userInfo.location,

            phone: staffInfo.work_phone,
            city: staffInfo.officecity,
            address: staffInfo.officeaddress || staffInfo.office_address,
            zipCode: staffInfo.offzipcode || staffInfo.officezipcode || '',
            officeState: staffInfo.officestate,
            floor: userInfo.floor_id,

            contactAddress: staffInfo.homeaddress || staffInfo.home_address,
            contactCity: staffInfo.homecity,
            contactState: staffInfo.homestate,
            contactZipCode: staffInfo.homezipcode,
            contactPhone: staffInfo.homephone || staffInfo.home_phone,
            contactMobile: staffInfo.mobile || staffInfo.cell,
            contactMobileOperator: staffInfo.mobileop_id,

            email: staffInfo.email,
            fax: staffInfo.fax,
            emergencyName: staffInfo.emergency_name,
            emergencyPhone: staffInfo.emergency_phone,
            spouseName: staffInfo.spouse_name,
            linkedIdName: userInfo.linked_url,
            technologist: staffInfo.tech_privilege === 'Y',
            recordsAccess: staffInfo.recordsAccess,
            serviceUser: staffInfo.serviceuser_privilege === 'Y',
            ssn: staffInfo.ssn,

            signature:
              staffInfo.staff_sign_path ||
              staffInfo.provider_sig_path ||
              staffInfo.sig_path,
            comments: userInfo.comment,

            imageUpload: staffInfo.staff_image_path || staffInfo.provider_img,
            signatureUpload:
              staffInfo.staff_sign_path || staffInfo.provider_sig_path,

            otherDocuments: staffInfo.other_documents,

            //for update compaibilities
            designation: staffInfo.designation,
            created_by: userInfo.created_by,
            user_id: userInfo.user_id,
            corporateId: userInfo.corporate_id,
            staffInfoRefid: userInfo.user_id,
            phy_group: staffInfo.phy_group,
            dea: staffInfo.dea,
            ein: staffInfo.ein,
            ein_name: staffInfo.ein_name,
            npi: staffInfo.npi || staffInfo.radio_npi,
            provider_level: staffInfo.provider_level,
            upin: staffInfo.upin,
            website: staffInfo.website || '',
            notify_supervisor: staffInfo.notify_supervisor,
            pecos: staffInfo.pecos,
            provider_status: staffInfo.provider_status,
            co_sign_required: staffInfo.co_sign_required,
            clia_number: staffInfo.clia_number,
            supervisor: staffInfo.supervisor,
            referring_nos: staffInfo.referring_nos,
            taxonomy_code: staffInfo.taxonomy_code || staffInfo.taxonomy,
            emailnotify: staffInfo.email_notify || 'W',
            emailnotify_period: staffInfo.emailnotify_period,
            notificationEmail: staffInfo.secemail,
            speciality: staffInfo.specialty,
            isPhysicianAdmin:
              staffInfo.supervisedGroup && staffInfo.supervisedGroup !== '0',
            //in case if we have physician,
            //supervisedGroup will be stored inside of staffInfo
            //in case if we have physician group admin, supervisedGroup will be stored inside of
            //userinfo.
            supervisedGroup:
              staffInfo.supervisedGroup || userInfo.supervisedGroup,
            radiologyGroups: staffInfo.radiologyGroups || [],
            vaccinations: staffInfo.vaccinations || [],
          }
        );
        if (clinicalGroup) {
          this.getState().model.clinicalGroup = clinicalGroup;
        } else {
          this.getState().model.clinicalGroup = [];
        }
        if (staffInfo.StateVal && staffInfo.StateVal[0]) {
          const license = staffInfo.StateVal[0];
          this.getState().model.state_0 = license.state;
          this.getState().model.licenseno_0 = license.licenseno;
        } else {
          this.getState().model.state_0 = '';
          this.getState().model.licenseno_0 = '';
        }
        break;
    }
    return state;
  }
}

export default new ApplicationUsersAddEditStore(appDispatcher);
