import { makeObservable, observable, action, runInAction } from 'mobx';

import { TPagination } from 'components/grid/GridTypes';
import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import FilterNameAndState, {
  FilterType,
} from 'stores/_mobx/options/filterNameAndStatus';
import { dateToLocalTimezone } from 'utils/DateUtils';
import { BASE_URL_FILE_DIR } from 'constant/config';

//MM/DD/YYYY -> YYYY-DD-MM
const changeDateFormatBeforeSend = (date: string) => {
  if (date) {
    const splitDate = date.split('/');
    return `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
  }
  return null;
};

//YYYY-DD-MM -> MM/DD/YYYY
const changeDateFormatAfterReceive = (date: string) => {
  if (date) {
    const splitDate = date.split('-');
    return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
  }
  return '';
};

export interface EkgType {
  calibration_date: string;
  comments: string;
  description: string;
  device_name: string;
  enable: boolean;
  main_date: string;
  refid: number;
  start_date: string;
  unique_id: string;
}

interface FileResponseType {
  desc: string;
  filenm: string;
  filestatus: string;
  remvImg: string;
  uploaded_dt: string;
}

interface EkgResponseType {
  0: Omit<EkgType, 'enable'>;
  file: 0 | FileResponseType[];
}

interface FileType {
  desc: string;
  filenm: string;
  filestatus: string;
}

interface FilesPreviewType {
  desc: string;
  fileName: string;
  path: string;
  uploadDate: string;
}

interface OptionResponseType {
  check: string;
  data: string;
  label: string;
  reason: string;
  textcolor: string;
}

interface OptionType {
  label: string;
  value: number;
}

export interface EkgFormModel extends Omit<EkgType, 'enable' | 'refid'> {
  refid: number | null;
  documentList: FileType[];
}

class EKG {
  fetching: boolean = false;
  fetchingFileList: boolean = false;
  fetchingOptions: boolean = false;
  ekgList: EkgType[] = [];
  ekgTotal: number = 0;
  ekg: EkgFormModel | undefined = undefined;
  idForDelete: number | null = null;
  fileListPreview: FilesPreviewType[] | null = null;
  noteId: number = 0;
  options: OptionType[] = [];

  filter: FilterNameAndState = new FilterNameAndState();
  page: Pagination = new Pagination({ id: 'ekg_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingFileList: observable,
      fetchingOptions: observable,
      ekgList: observable,
      ekgTotal: observable,
      ekg: observable,
      idForDelete: observable,
      fileListPreview: observable,
      noteId: observable,

      setFetching: action,
      setFetchingFileList: action,
      setFetchingOptions: action,
      setEkgList: action,
      setEkgTotal: action,
      setEkg: action,
      setIdForDelete: action.bound,
      setFileListPreview: action,
      setNoteId: action,
      clearIdForDelete: action.bound,
      clearEkg: action.bound,
      clearFileListPreview: action.bound,
      clearNoteId: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingFileList(fetching: boolean) {
    this.fetchingFileList = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setEkgList(list: EkgType[]) {
    this.ekgList = list;
  }

  setEkgTotal(count: number) {
    this.ekgTotal = count;
  }

  setEkg(ekg?: EkgFormModel) {
    this.ekg = ekg;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setFileListPreview(fileList: FilesPreviewType[]) {
    this.fileListPreview = fileList;
  }

  setNoteId(id: number) {
    this.noteId = id;
  }

  clearIdForDelete() {
    this.idForDelete = null;
  }

  clearEkg() {
    this.ekg = undefined;
  }

  clearFileListPreview() {
    this.fileListPreview = null;
  }

  clearNoteId() {
    this.noteId = 0;
  }

  async getEkgList({
    pagination,
    filter,
  }: {
    pagination: TPagination;
    filter: FilterType;
  }) {
    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.name,
      filter.state,
    ];

    try {
      const response = await apiRequest<'SE' | EkgType[]>({
        url: 'inventory.ekg.GetEkgDetails',
        data,
      });
      if (Array.isArray(response)) {
        const list = response.map((item) => ({
          ...item,
          refid: Number(item.refid),
        }));
        this.setEkgList(list);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setEkgList([]);
    }
  }

  async getEkgCount(filter: FilterType) {
    try {
      const count = await apiRequest<'SE' | 'S' | number>({
        url: 'inventory.ekg.TotalRecordCount',
        data: [filter.name, filter.state],
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setEkgTotal(total);
    } catch (e) {
      this.setEkgTotal(0);
    }
  }

  getEkgMain = () => {
    this.setFetching(true);

    const props = {
      pagination: this.page.pagination,
      filter: this.filter.options,
    };

    const promiseList = this.getEkgList(props);

    const promiseCount = this.getEkgCount(props.filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getEkg(id: number) {
    this.setFetching(true);

    try {
      const response = await apiRequest<[] | EkgResponseType>({
        url: 'inventory.ekg.GetEkgDetailsById',
        data: [id],
      });
      if (Array.isArray(response)) {
        throw Error('');
      } else {
        const documentList = response.file
          ? response.file.map(({ filenm, desc, filestatus }) => ({
              filenm,
              desc,
              filestatus,
            }))
          : [];
        const start_date = changeDateFormatAfterReceive(response[0].start_date);
        const main_date = changeDateFormatAfterReceive(response[0].main_date);
        const calibration_date = changeDateFormatAfterReceive(
          response[0].calibration_date
        );
        const drPlate = {
          ...response['0'],
          start_date,
          main_date,
          calibration_date,
          refid: Number(id),
          documentList,
        };

        this.setEkg(drPlate);
      }
    } catch (e) {
      this.setEkg();
    } finally {
      this.setFetching(false);
    }
  }

  async addEkg({ documentList, ...payload }: EkgFormModel) {
    const start_date = changeDateFormatBeforeSend(payload.start_date);
    const main_date = changeDateFormatBeforeSend(payload.main_date);
    const calibration_date = changeDateFormatBeforeSend(
      payload.calibration_date
    );
    const data = [
      payload.unique_id,
      payload.device_name,
      start_date,
      main_date,
      calibration_date,
      [],
      payload.description,
      payload.comments,
      documentList,
    ];

    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'inventory.ekg.AddEkgDetails',
        data,
      });
      if (result === 'S') {
        Notification.success('Ekg was added successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'Serial number is not unique!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateEkg({ documentList, ...payload }: EkgFormModel) {
    const main_date = changeDateFormatBeforeSend(payload.main_date);
    const start_date = changeDateFormatBeforeSend(payload.start_date);
    const calibration_date = changeDateFormatBeforeSend(
      payload.calibration_date
    );

    const data = [
      payload.refid,
      payload.unique_id,
      payload.device_name,
      start_date,
      main_date,
      calibration_date,
      payload.description,
      payload.comments,
      documentList,
    ];

    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'inventory.ekg.UpdateEkgDetails',
        data,
      });

      if (result === 'S') {
        Notification.success('EKG was updated successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'This Serial number already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  changeEkgStatus = async (ids: number[]) => {
    this.setFetching(true);
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'inventory.ekg.changeStatus',
        data: [ids],
      });
      if (response === 'S') {
        const { length } = ids;

        const statusMsg = this.filter.options.isActive
          ? 'deactivated'
          : 'activated';

        Notification.success(
          `${length} EKG device${length > 1 ? 's were' : 'was'} ${statusMsg}`
        );
        return true;
      } else Error('');
    } catch (e) {
      Notification.warning('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  };

  async deleteEkg() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'inventory.ekg.DeleteEkg',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('EKG was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("This EKG is in use, so it can't be deleted!");
      this.setFetching(false);
      return false;
    }
  }

  async getFileList(id: number) {
    runInAction(() => {
      this.fetchingFileList = true;
      this.fileListPreview = [];
    });
    try {
      const response = await apiRequest<[] | EkgResponseType>({
        url: 'inventory.ekg.GetEkgDetailsById',
        data: [id],
      });
      if (Array.isArray(response)) {
        throw Error('');
      } else {
        const files = response.file
          ? response.file.map(({ filenm, desc, uploaded_dt }) => ({
              fileName: filenm,
              desc,
              uploadDate: dateToLocalTimezone({ date: uploaded_dt }),
              path: `${BASE_URL_FILE_DIR}doc_img/ekg/${id}-${filenm}`,
            }))
          : [];

        this.setFileListPreview(files);
      }
    } catch (e: any) {
    } finally {
      this.setFetchingFileList(false);
    }
  }

  async getOptions() {
    this.setFetchingOptions(true);
    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'inventory.ekg.EkgNameDropDown',
      });

      const options = response.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storeEkg = new EKG();
