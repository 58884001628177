import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import AreaChart from 'components/project/common/charts/AreaChart';
import PieChart from 'components/project/common/charts/PieChart';
import Exporter from 'components/project/ExporterNew';
import { Button, IconButton } from 'components/button';
import { LayoutSideTitle } from 'components/layout';
import { SpinnerFixed } from 'components/spinner';
import { Grid, GridControlButton } from 'components/grid';
import InvoiceDetailHeader from './components/InvoiceDetailHeader';
import InvoiceInfo from './components/InvoiceInfo';

import {
  storeArAging,
  InvoiceDetailsType,
} from 'stores/_mobx/billingCodding/arAging';
import { URL_AR_AGING } from 'constant/path/billingCoding';

const optionsLinearChart = {
  title: 'Ar Aging',
  legend: 'none',
  vAxis: { title: 'Age' },
  hAxis: { title: 'Invoice' },
};

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const ArAgingInvoiceFacilityDetailsPage = ({
  match: { params },
}: PropsType) => {
  const gridRef = useRef<Grid>();

  const { fetching, invoiceFacilityDetails } = storeArAging;

  const [isExportVisible, toggleExporter] = useState<boolean>(false);

  const [isChartView, toggleSummaryView] = useState<boolean>(false);

  const [invoiceDetail, setInvoiceDetail] = useState<InvoiceDetailsType | null>(
    null
  );

  const columns = useMemo(
    () => [
      { title: 'Study ID', name: 'studyid' },
      { title: 'Invoice #', name: 'invoice_no', className: 'text-nowrap' },
      { title: 'Patient Name', name: 'patientnm' },
      { title: 'Patient MRN', name: 'patientmrn' },
      { title: 'Other MRN', name: 'clientMRN' },
      { title: 'CPT', name: 'cptdesc' },
      { title: 'Diagnosis', name: 'icddesc' },
      {
        title: 'DOS',
        name: 'sheduleservdate',
        className: 'text-nowrap',
      },
      {
        title: 'Date of Invoice',
        name: 'invoice_dt',
        className: 'text-nowrap',
      },
      {
        title: 'Age of invoice (days)',
        name: 'age',
        className: 'width-100 text-center',
      },
      { title: 'Charge', name: 'total_price', className: 'text-nowrap' },
      { title: 'Balance', name: 'bal', className: 'text-nowrap' },
      {
        title: 'Actions',
        name: 'control',
        className: 'width-75 text-center',
        render: (value: undefined, data: InvoiceDetailsType) => (
          <IconButton
            className="text-primary"
            onClick={() => setInvoiceDetail(data)}>
            <i className="bi bi-eye-fill" />
          </IconButton>
        ),
      },
    ],
    []
  );

  const handleCloseInvoiceDetails = () => {
    setInvoiceDetail(null);
  };

  const handleToggleExport = () => {
    toggleExporter((state) => !state);
  };

  const handleToggleSummaryView = () => {
    toggleSummaryView((state) => !state);
  };

  useEffect(() => {
    storeArAging.getInvoiceFacilityDetails(Number(params.id));
    return storeArAging.clearFacilityDetails;
  }, [params.id]);

  if (!invoiceFacilityDetails) return <SpinnerFixed />;

  return invoiceDetail ? (
    <InvoiceInfo
      invoice={invoiceDetail}
      facilityDetail={invoiceFacilityDetails}
      onClose={handleCloseInvoiceDetails}
    />
  ) : (
    <>
      <LayoutSideTitle title="Facility Invoice Item List">
        <Link to={URL_AR_AGING} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <InvoiceDetailHeader
        facilityName={invoiceFacilityDetails.facilityName}
        facilityNpi={invoiceFacilityDetails.facilityNpi}
        phone={invoiceFacilityDetails.phone}
      />

      <Grid
        id="ar_aging_grid"
        ref={gridRef}
        onAjax={fetching}
        columns={columns}
        disablePagination
        dataSource={invoiceFacilityDetails.invoices || []}
        gridControlPanel={
          <GridControlButton title="Export" onClick={handleToggleExport} />
        }
      />

      <div className="mb-3">
        <Button
          text={isChartView ? 'Show Grid' : 'Show Chart'}
          onClick={handleToggleSummaryView}
        />
      </div>

      {isChartView ? (
        <>
          <AreaChart
            options={optionsLinearChart}
            className="col-sm-6 col-lg-7"
            data={invoiceFacilityDetails.chartDataLinear}
          />
          <PieChart
            className="col-sm-6 col-lg-5"
            data={invoiceFacilityDetails.chartDataPie}
          />
        </>
      ) : (
        <ul className="font-large">
          <li>
            Total charge: <b>{`$${invoiceFacilityDetails.charge}`}</b>
          </li>
          <li>
            Total balance: <b>{`$${invoiceFacilityDetails.balance}`}</b>
          </li>
        </ul>
      )}

      {isExportVisible && (
        <Exporter
          reportName="AR_Aging_Invoice_Facility_Details"
          columns={columns}
          gridRef={gridRef}
          exportOptions={[{ label: 'CSV', value: 'csv' }]}
          dataSource={invoiceFacilityDetails.invoices}
          pdfExporter={storeArAging.generatePdfInvoiceDetails}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default observer(ArAgingInvoiceFacilityDetailsPage);
