import {
  action,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import { apiRequest } from 'services/RequestService';

import { OptionResponseType } from 'stores/_mobx/integration';

import { URL_SYSTEM_APPLICATION } from './appSettingsMaster';

export interface EligibilityStateFormModel {
  eligibilityState: {
    experianState: boolean;
    availityState: boolean;
  };
}



export class EligibilitySettingsStatus {
  eligibilityFetching: boolean = false;

  isExperianEnabled: boolean = false;
  isAvailityEnabled: boolean = false;

  constructor() {
    makeObservable(this, {
      isExperianEnabled: observable,
      isAvailityEnabled: observable,
      eligibilityFetching: observable,

      setFetchingState: action,
      clearState: action.bound,
    });
  }

  setFetchingState(fetching: boolean) {
    this.eligibilityFetching = fetching;
  }

  clearState() {
    this.isExperianEnabled = false;
    this.isAvailityEnabled = false;
  }

  async getEligibilityState() {
    if (this.eligibilityFetching) return Promise.resolve();

    this.setFetchingState(true);

    try {
      const { value: experianValue } = await apiRequest<OptionResponseType<boolean>>({
        url: `${URL_SYSTEM_APPLICATION}/eligibility_manager_export_settings:experian_export`,
        method: 'GET',
        legacy: false,
      });

      const { value: availityValue } = await apiRequest<OptionResponseType<boolean>>({
        url: `${URL_SYSTEM_APPLICATION}/eligibility_manager_export_settings:availity`,
        method: 'GET',
        legacy: false,
      });

      runInAction(() => {
        this.eligibilityFetching = false;

        this.isExperianEnabled = experianValue;
        this.isAvailityEnabled = availityValue;
      });

    } catch (e: unknown) {
      runInAction(() => {
        this.eligibilityFetching = false;
        this.isExperianEnabled = false;
        this.isAvailityEnabled = false;
      });
    }
  }
}

export const storeEligibilitySettingsStatus = new EligibilitySettingsStatus();