import { action, makeObservable, observable } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

export interface PayerPlanType {
  comments: string;
  payername: string;
  plan_name: string;
  refid: string;
}

interface BasePayerPlan {
  comments: string;
  payerid: number;
  planname: string;
  payer_type: string;
}

interface PayerPlanEditableType extends BasePayerPlan {
  refid: number;
}

interface OptionResponseType {
  data: string;
  label: string;
}

export interface OptionType {
  value: number;
  label: string;
}

class PayerPlan {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  payerPlansTotal: number = 0;
  payerPlans: PayerPlanType[] = [];
  deletePayerId: null | number = null;
  options: OptionType[] = [];
  payer: PayerPlanEditableType | null = null;
  filter: string = '';

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'payer_plan_grid' });

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      payerPlans: observable,
      payerPlansTotal: observable,
      options: observable,
      deletePayerId: observable,
      payer: observable,
      filter: observable,

      setPlans: action,
      setTotal: action,
      setOptions: action,
      setIdForDelete: action,
      setFetching: action,
      setPayer: action,
      setFetchingOptions: action,
      setFilter: action,
      clearIdForDelete: action.bound,
      clearPayer: action.bound,
    });
  }

  setPlans(plans: PayerPlanType[]) {
    this.payerPlans = plans;
  }

  setTotal(total: number) {
    this.payerPlansTotal = total;
  }

  setOptions(options: OptionType[]) {
    this.options = options;
  }

  setPayer(payer: PayerPlanEditableType) {
    this.payer = payer;
  }

  setIdForDelete = (id: number | null) => {
    this.deletePayerId = id;
  };

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setFilter(planName: string) {
    this.filter = planName;
  }

  clearIdForDelete() {
    this.deletePayerId = null;
  }

  clearPayer() {
    this.payer = null;
  }

  getPayerPlans = async () => {
    const {
      filter: planName,
      page: { pagination },
    } = this;

    try {
      const plans = await apiRequest<PayerPlanType[]>({
        url: 'generalmaster.PayorPlan.GetAllPlayer',
        data: [pagination.skip, pagination.pageSize, planName],
      });

      this.setPlans(plans);
    } catch (error) {}
  };

  getPayerPlanTotal = async () => {
    try {
      const total = await apiRequest<number>({
        url: 'generalmaster.PayorPlan.GetPlanCount',
        data: [this.filter],
      });
      this.setTotal(total);
    } catch (error) {
      this.setTotal(0);
    }
  };

  getPayerPlansList = () => {
    this.setFetching(true);

    const promiseGetPlans = this.getPayerPlans();

    const promiseGetTotal = this.getPayerPlanTotal();

    return Promise.allSettled([promiseGetPlans, promiseGetTotal]).finally(
      () => {
        this.setFetching(false);
      }
    );
  };

  getOptions = async () => {
    if (this.fetchingOptions) return Promise.resolve([]);

    this.setFetchingOptions(true);

    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.PayorPlan.LoadPlanName',
      });

      const options = response.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));

      this.setOptions(options);
    } catch (error) {
      this.setOptions([]);
    } finally {
      this.setFetchingOptions(false);
    }
  };

  getPayerPlan = async (id: number) => {
    this.setFetching(true);
    this.setPayer(null);
    try {
      const response = await apiRequest<[PayerPlanEditableType]>({
        url: 'generalmaster.PayorPlan.GetPlanById',
        data: [id],
      });

      const payerPlan = {
        ...response[0],
        payerid: Number(response[0].payerid),
      };

      this.setPayer(payerPlan);
    } catch (error) {
    } finally {
      this.setFetching(false);
    }
  };

  addPayerPlan = async (payload: BasePayerPlan) => {
    const data = [
      payload.payerid,
      payload.planname,
      payload.comments,
      payload.payer_type,
    ];
    try {
      const response = await apiRequest<'E' | 'S'>({
        url: 'generalmaster.PayorPlan.AddPayorPlan',
        data,
      });
      if (response === 'S') {
        Notification.success('Successfully added!');
        return true;
      }
      throw Error('Error');
    } catch (error) {
      Notification.danger('An error occurred!');
      return false;
    }
  };

  updatePayerPlan = async (payload: PayerPlanEditableType) => {
    const data = [
      payload.refid,
      payload.payerid,
      payload.planname,
      payload.comments,
      payload.payer_type,
    ];

    try {
      const result = await apiRequest<'E' | 'S'>({
        url: 'generalmaster.PayorPlan.EditPayorPlan',
        data,
      });

      if (result === 'S') {
        Notification.success('Payer plan was successfully updated!');
        return true;
      }
      throw Error('error');
    } catch (error) {
      Notification.danger('An error occurred!');
      return false;
    }
  };

  deletePayerPlan = async () => {
    this.setFetching(true);
    try {
      const result = await apiRequest<'S'>({
        url: 'generalmaster.PayorPlan.DeletePayorPlan',
        data: [this.deletePayerId],
      });
      if (result === 'S') {
        Notification.success('Payer plan was deleted!');
        this.setIdForDelete(null);
        return true;
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  };
}

export const storePayerPlan = new PayerPlan();
