import { useMemo } from 'react';

import Select, {
  PropsType as PropsTypeSelect,
} from 'components/form/select/Select';

import { ReportType } from 'stores/_mobx/workflow/order/transcription';

const REPORT_TYPE_OPTIONS: {
  value: ReportType;
  label: string;
}[] = [
  { value: 'P', label: 'Prelim' },
  { value: 'F', label: 'Final' },
  { value: 'A', label: 'Addendum' },
];

interface PropsType extends Omit<PropsTypeSelect, 'options'> {
  disableFinalOption?: boolean;
}

const DropdownReportType = ({ disableFinalOption, ...props }: PropsType) => {
  const options = useMemo(
    () =>
      disableFinalOption
        ? REPORT_TYPE_OPTIONS.map((option) =>
            option.value === 'F' || option.value === 'A'
              ? { ...option, isDisabled: true }
              : option
          )
        : REPORT_TYPE_OPTIONS,
    [disableFinalOption]
  );

  return <Select {...props} options={options} />;
};

export default DropdownReportType;
