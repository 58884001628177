import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import TwoChartBlock from 'page/reports/components/TwoChartBlock';
import { Grid, GridControlButton } from 'components/grid';
import Exporter from 'components/project/ExporterNew';
import { Button } from 'components/button';
import { BRIEFCASE_HISTORY as PAGE_ID } from 'constant/pagesId/reports';
import { LayoutSideTitle } from 'components/layout';
import useDebounce from 'components/hook/useDebounce';
import Filter from './components/Filter';

import {
  storeBriefcaseHistory,
  FilterType,
} from 'stores/_mobx/report/briefcaseHistory';

const columns = [
  { name: 'file_name', title: 'File Name' },
  { name: 'action', title: 'Action', className: 'width-150' },
  { name: 'desc', title: 'Description' },
  { name: 'created_by', title: 'Action By' },
  { name: 'actionDate', title: 'Date', className: 'width-100' },
];

const BriefcaseHistoryPage = () => {
  const gridRef = useRef<Grid>(null);

  const {
    fetching,
    logList,
    logTotal,
    chartData,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeBriefcaseHistory;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportOpened, toggleExport] = useState<boolean>(false);

  const [isGridVisible, toggleGrid] = useState<boolean>(true);

  const debouncedMethod = useDebounce(setPaginationToStart, 400);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleToggleGrid = () => {
    toggleGrid((state) => !state);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeBriefcaseHistory.setFilter(filter);
    debouncedMethod();
  };

  useEffect(() => {
    storeBriefcaseHistory.getBriefcaseLogsMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Briefcase History">
        <Button
          text={isGridVisible ? 'Graph View' : 'Grid View'}
          onClick={handleToggleGrid}
        />
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide filter' : 'Show filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        isFilterVisible={isFilterVisible}
        fetching={fetching}
        onSubmit={handleChangeFilter}
      />

      <TwoChartBlock
        className={isGridVisible ? 'd-none' : ''}
        chartData={chartData}
      />

      <Grid
        id="briefcaseHistoryGrid"
        ref={gridRef}
        wrapperClass={isGridVisible ? '' : 'd-none'}
        columns={columns}
        onAjax={fetching}
        dataSourceCount={logTotal}
        dataSource={logList}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Export" onClick={handleToggleExport} />
        }
      />

      {isExportOpened && (
        <Exporter
          reportName="BriefcaseHistory"
          exporter="BriefcaseHistoryExporter"
          columns={columns}
          gridRef={gridRef}
          dataSource={logList}
          filter={storeBriefcaseHistory.filter}
          pdfExporter={storeBriefcaseHistory.exportToPdf}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default observer(BriefcaseHistoryPage);
