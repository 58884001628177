import React, { useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import UserSelection from 'components/project/users/UserSelection';
import Title from 'components/project/common/title';
import { Textarea } from 'components/form/textarea';
import { InputCalendar, InputTime } from 'components/form/inputCalendar';
import { Input } from 'components/form/textField';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';
import resolver from './validation';

import {
  storeReminder,
  ReminderAddType,
  ReceiverType,
} from 'stores/_mobx/message/reminder';
import { dateToLocalTimezone } from 'utils/DateUtils';
import { URL_REMINDER } from 'constant/path/messages';

interface UserType {
  facility: string;
  firstName: string;
  lastName: string;
  refid: string;
  to_mail: number;
  to_name: string;
  type: string;
}

const getDefaultValues = (): ReminderAddType => ({
  recievers: [],
  receiversString: '',
  date: dateToLocalTimezone({ date: new Date(), dateOnly: true }),
  time: dateToLocalTimezone({ date: new Date() }).replace(
    /^\d{2}\/\d{2}\/\d{4}\s/,
    ''
  ),
  subject: '',
  description: '',
});

interface PropsType extends RouteComponentProps {}

const usersListPrettier = (users: UserType[]): ReceiverType[] =>
  users.map((user) => ({
    name: user.to_name,
    mail: user.to_mail,
    refid: user.refid,
    type: user.type,
  }));

const ReminderAddPage = ({ history }: PropsType) => {
  const [usersDialogIsOpen, toggleUsersDialog] = useState(false);

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues: getDefaultValues(), resolver });

  const onSubmit = (data: ReminderAddType) => {
    storeReminder.addReminder(data).then((isSucceed) => {
      if (isSucceed) history.push(URL_REMINDER);
    });
  };

  const handleClickReset = () => {
    reset(getDefaultValues());
  };

  const handleToggleUsersDialog = () => {
    toggleUsersDialog((state) => !state);
  };

  const handleSubmitUserDialog = (
    event: React.MouseEvent,
    users: UserType[]
  ) => {
    const receiversString = users.map(({ to_name }) => to_name).join('; ');
    const recievers = usersListPrettier(users);

    setValue('recievers', recievers);
    setValue('receiversString', receiversString, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
    handleToggleUsersDialog();
  };

  return (
    <>
      <Title title="Set Reminder" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Textarea
          name="receiversString"
          label="Receiver(s):"
          readOnly
          rows={4}
          required
          control={control}
          onClick={handleToggleUsersDialog}
        />
        <input type="hidden" name="recievers" />
        <div className="row">
          <InputCalendar
            name="date"
            label="Reminder Date"
            className="col-sm-6 col-md-3 col-lg-2"
            required
            control={control}
          />
          <InputTime
            name="time"
            label="Reminder Time"
            className="col-sm-6 col-md-3 col-lg-2"
            required
            control={control}
          />
        </div>
        <Input
          name="subject"
          label="Subject"
          className="col-lg-6"
          required
          control={control}
        />
        <Textarea
          name="description"
          label="Description"
          className="col-sm-12"
          rows={4}
          required
          control={control}
        />
        <ControlsLayout alignX="right">
          <Link to={URL_REMINDER} className="btn btn-danger">
            Back
          </Link>
          <Button
            variant="warning"
            text="Reset"
            onClick={handleClickReset}
            disabled={isSubmitting}
          />
          <Button
            type="submit"
            text="Send"
            disabled={!isDirty || isSubmitting}
          />
        </ControlsLayout>
      </form>

      {usersDialogIsOpen && (
        <UserSelection
          isOpen
          onSubmit={handleSubmitUserDialog}
          onCancel={handleToggleUsersDialog}
        />
      )}
    </>
  );
};

export default ReminderAddPage;
