import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeLead,
  LeadFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/lead';
import { URL_LEAD } from 'constant/path/workflow';

interface PropsType extends RouteComponentProps {}

const LeadAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: LeadFormModel) =>
    storeLead.addLead(data).then((error) => {
      if (error) return error;
      history.push(URL_LEAD);
    });

  return (
    <>
      <Title title="Add Lead" />
      <Form onSubmit={handleSubmit} backUrl={URL_LEAD} />
    </>
  );
};

export default LeadAddPage;
