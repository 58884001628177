import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormEmailTemplate, { FormModel } from './components/Form';

import { storeEmailTemplate } from 'stores/_mobx/systemSetup/masterSetting/emailTemplate';
import { URL_EMAIL_TEMPLATE } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const EmailTemplateAddPage = ({ history }: PropsType) => {
  const handleSubmit = (data: FormModel) =>
    storeEmailTemplate.addTemplate(data).then((response) => {
      if (response) return response;
      history.push(URL_EMAIL_TEMPLATE);
      return null;
    });

  return (
    <>
      <Title title="Add Email Template" />
      <FormEmailTemplate backUrl={URL_EMAIL_TEMPLATE} onSubmit={handleSubmit} />
    </>
  );
};

export default EmailTemplateAddPage;
