import { apiRequest } from 'services/RequestService';

class FacilityPatientPageService {
  loadFacilityPatientList(conditionList, joint) {
    return apiRequest({
      url: 'report.ReportMaster.FacilityPatientReport',
      data: [conditionList, joint],
    }).then((response) => {
      if (response && response.chartval) {
        var newChartValues = [];
        newChartValues.push(['Facility', 'Patient count']);
        let chartValues = response.chartval;
        chartValues.forEach((item) => {
          newChartValues.push([item.FacilityName, parseInt(item.Patients)]);
        });
        response.chartval = newChartValues;
        return response;
      } else {
        return {};
      }
    });
  }

  createPdf(facilityPatientList, formatDescription) {
    return apiRequest({
      url: 'pdfcreater.OrderPdfGenerator.GeneratePDFFacilityPatient',
      data: [facilityPatientList, formatDescription],
    });
  }
}
export default new FacilityPatientPageService();
