import { makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionsResponseType {
  data: string;
  label: string;
}

export interface OptionType {
  value: number;
  label: string;
}

export interface OptionBasicType {
  value: string;
  label: string;
}

export class DropdownOrderType {
  fetching: boolean = false;
  fetchingBasicOptions: boolean = false;
  options: OptionType[] = [];
  optionsBasic: OptionBasicType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingBasicOptions: observable,
      options: observable,
      optionsBasic: observable,
    });
  }

  async getOptions() {
    if (this.fetching) return Promise.resolve();

    try {
      runInAction(() => {
        this.fetching = true;
      });
      const list = await apiRequest<OptionsResponseType[]>({
        url: 'order.Order.GetOrderTypeByIds',
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));
      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }

  async getOptionsBasic() {
    if (this.fetchingBasicOptions) return Promise.resolve();

    try {
      runInAction(() => {
        this.fetchingBasicOptions = true;
      });
      const response = await apiRequest<OptionsResponseType[]>({
        url: 'order.Order.GetOrderType',
      });

      const options = response.map(({ label, data }) => ({
        label,
        value: data,
      }));

      runInAction(() => {
        this.fetchingBasicOptions = false;
        this.optionsBasic = options;
      });
    } catch {
      runInAction(() => {
        this.fetchingBasicOptions = false;
        this.optionsBasic = [];
      });
    }
  }

  findBasicOrderType = (id: string) =>
    this.optionsBasic.find(({ value }) => value === id);
}

export const storeDropdownOrderType = new DropdownOrderType();
