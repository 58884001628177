import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const validation = yupResolver(
  yup.object().shape({
    con_title: yup.string().required('Please, enter the Role!'),
    first_name: yup.string().required('Please, enter the First name!'),
    last_name: yup.string().required('Please, enter the Last name!'),
    email: yup.string().email('Invalid email address!'),
  })
);
