import { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import Badge from 'components/badge';
import { TopMenuItemExtends } from 'stores/record/UserProfileRecord';

const getActiveMenu = (menu: TopMenuItemExtends[], pathname: string) => {
  const active = menu.find(({ url }) =>
    url.some((path) => pathname.startsWith(path))
  );

  return active?.app_name || '';
};

interface TopMenuProps {
  menu: TopMenuItemExtends[];
  unreadCount: number;
}

const TopMenu = ({ menu, unreadCount }: TopMenuProps) => {
  const [isOpened, setOpened] = useState<boolean>(false);

  const { pathname } = useLocation();

  const currentMenu = useMemo(
    () => getActiveMenu(menu, pathname),
    [menu, pathname]
  );

  const unreadCountFormatted = useMemo(
    () => new Intl.NumberFormat().format(unreadCount),
    [unreadCount]
  );

  const handleClickToggleSwitcher = () => {
    setOpened((state) => !state);
  };

  const handleClick = () => {
    setOpened(false);
  };

  const handleCheckIsActive =
    (urls: string[], name: string) =>
    (match: null | object, { pathname }: { pathname: string }) => {
      const isPathMatches =
        Boolean(match) ||
        urls.some(
          (link) => pathname === link || pathname.startsWith(`${link}/`)
        );

      return isPathMatches;
    };

  const toggleMenu = useCallback(() => {
    setOpened(false);
  }, []);

  useEffect(() => {
    if (isOpened) {
      document.body.addEventListener('click', toggleMenu);
    }
    return () => document.body.removeEventListener('click', toggleMenu);
  }, [isOpened, toggleMenu]);

  return (
    <div className="top-menu-wrapper">
      <label className="top-menu-toggler" onClick={handleClickToggleSwitcher}>
        {currentMenu}
        <span>
          <i />
          <i />
          <i />
        </span>
      </label>

      <ul className={`top-menu${isOpened ? ' open' : ''}`}>
        {menu.map(({ app_name, defaultUrl, data, url }) => {
          const isMsg = app_name === 'Messages';

          return (
            <li key={data}>
              <NavLink
                to={defaultUrl}
                activeClassName="active"
                className="top-menu-link"
                isActive={handleCheckIsActive(url, app_name)}
                data-testid={app_name}
                onClick={handleClick}>
                {app_name}
                {isMsg && unreadCount ? (
                  <small>
                    <Badge rounded position="absolute" horizontal="right">
                      {unreadCountFormatted}
                    </Badge>
                  </small>
                ) : null}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TopMenu;
