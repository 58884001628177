import { apiRequest } from 'services/RequestService';
import { TDropDownItem } from 'actions/project/DropdownActions';

export class TFacilityDropdown {
  data: number = null;
  label: string = '';
  phone: string = '';
  fax: string = '';
  stateid: number = null;
  facility_stat: string = '';
}

export class TFacilityDropdownNew {
  data: number = null;
  label: string = '';
  OrderType: string = '';
}

class FacilityService {
  loadFloorDropdown(facilityId: number) {
    return apiRequest<Array<TDropDownItem>>({
      url: 'facility.FacilityMaster.FloorDropDown',
      data: [facilityId],
    });
  }

  addNewFacility(payload: CreateFacilityType) {
    const data = [
      payload.facilityName,
      payload.address,
      payload.phone,
      payload.city,
      payload.state,
      payload.zipCode,
      payload.country,
      payload.location,
      payload.orderType,
      payload.addType,
    ];

    return apiRequest<'SE' | 'E' | number>({
      url: 'facility.FacilityMaster.AddNewFacility',
      data,
    });
  }

  loadFacilityValues(facilityId: number) {
    return apiRequest<Array<TFacilityValues>>({
      url: 'facility.FacilityMaster.GetFacilityValues',
      data: [facilityId],
    });
  }
}

export class TFacilityValues {
  facility_nm: string = '';
  address1: string = '';
  address2: string = '';
  bill_address2: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
}

export interface CreateFacilityType {
  address: string;
  city: string;
  facilityName: string;
  location: number | null;
  orderType: number[];
  phone: string;
  state: string;
  zipCode: string;
  country: string;
  addType: string;
}

export default new FacilityService();
