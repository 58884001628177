import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validationExamDescription = yupResolver(
  yup.object().shape({
    notes: yup.string().nullable().required('Please, fill in the claim notes!'),
  })
);

export default validationExamDescription;
