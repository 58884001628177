import { useForm } from 'react-hook-form';

import { DropDownFacilityRole } from 'components/project/dropdown/FacilityRolesDropdown';
import { DropdownCorporateWithPermission } from 'components/project/dropdown/CorporateRolesDropdown';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropDownPhysician } from 'components/project/dropdown/PhysicianDropdown';
import DropDownTechnologist from 'components/project/dropdown/TechnologistDropDown';
import { Input } from 'components/form/textField';
import { DateRange } from 'components/form/dateRange';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import {
  storeSummaryFacility,
  FilterType,
} from 'stores/_mobx/report/summaryFacility';

const commonClassName = 'col-md-6 col-lg-3';

interface PropsType {
  fetching: boolean;
  isFilterVisible: boolean;
  onSearch: (data: FilterType) => void;
}

const Filter = ({ fetching, isFilterVisible, onSearch }: PropsType) => {
  const { control, handleSubmit, reset } = useForm<FilterType>({
    defaultValues: storeSummaryFacility.filter,
  });

  const handleClickReset = () => {
    const defaultFilterValue = storeSummaryFacility.getDefaultFilter();

    reset(defaultFilterValue);
    onSearch(defaultFilterValue);
  };

  return (
    <form
      className={isFilterVisible ? '' : ' d-none'}
      onSubmit={handleSubmit(onSearch)}>
      <div className="row">
        <DropDownTechnologist
          name="technologistId"
          label="Technologist"
          className={commonClassName}
          control={control}
        />
        <DropdownCorporateWithPermission
          name="corporateId"
          label="Corporate"
          className={commonClassName}
          control={control}
        />
        <DropDownPhysician
          name="physicianId"
          label="Physician"
          className={commonClassName}
          control={control}
        />
        <DropDownPlaceOfService
          name="pos"
          label="POS"
          className={commonClassName}
          control={control}
        />
        <div />
        <Input
          name="patientLastName"
          label="Patient Last name"
          className={commonClassName}
          control={control}
        />
        <Input
          name="patientFirstName"
          label="Patient First Name"
          className={commonClassName}
          control={control}
        />
        <Input
          name="mrn"
          label="Patient MRN#"
          className={commonClassName}
          control={control}
        />
        <DropDownFacilityRole
          name="facilityId"
          label="Facility"
          className={commonClassName}
          control={control}
        />
        <div />
        <DateRange
          nameFrom="dosStart"
          nameTo="dosEnd"
          nameRadio="period"
          radioClassName="col-sm-12 col-md-6 col-lg-3 col-xl-auto"
          control={control}
        />
        <ControlsLayout alignX="auto">
          <Button
            text="Reset"
            variant="warning"
            disabled={fetching}
            onClick={handleClickReset}
          />
          <Button text="Apply" type="submit" disabled={fetching} />
        </ControlsLayout>
      </div>
    </form>
  );
};

export default Filter;
