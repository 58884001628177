import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

export const Actions = {
  LOAD_LIST_ACTION: 'load-signed-orders-list-action',
  LOAD_COUNT_ACTION: 'load-signed-orders-count-action',
  CLEAR_STORE_ACTION: 'clear-store-action',
  UPDATE_TABS_STATE: 'update-tabs-state',
  LOAD_TRANSMIT_LIST_ACTION: 'load-transmit-list-action',
  LOAD_TRANSMIT_LIST_COUNT_ACTION: 'load-transmit-list-count-action',
  LOAD_ITEM_ACTION: 'LOAD_ITEM_ACTION',
};

class SSignOrdersPageStore {
  signOrdersList: Array<any> = [];
  signOrderItem: any = {};
  signOrdersCount: number = 0;
  transmitLogList: Array<any> = [];
  transmitLogCount: number = 0;
  currentTab: any = null;
  blocked: any = null;
}

class SignOrdersPageStore extends ReduceStore<
  SSignOrdersPageStore,
  CommonPayload
> {
  getInitialState() {
    return new SSignOrdersPageStore();
  }

  reduce(state: SSignOrdersPageStore, action: CommonPayload) {
    switch (action.type) {
      case Actions.LOAD_TRANSMIT_LIST_ACTION:
        this.getState().transmitLogList = action.data;
        break;
      case Actions.LOAD_TRANSMIT_LIST_COUNT_ACTION:
        this.getState().transmitLogCount = action.data;
        break;
      case Actions.LOAD_LIST_ACTION:
        this.getState().signOrdersList = action.data;
        break;
      case Actions.LOAD_COUNT_ACTION:
        this.getState().signOrdersCount = action.data;
        break;
      case Actions.CLEAR_STORE_ACTION:
        this.getState().signOrderItem = {};
        break;
      case Actions.LOAD_ITEM_ACTION:
        this.getState().signOrderItem = action.data[0];
        break;
      case Actions.UPDATE_TABS_STATE:
        this.getState().currentTab = action.data.currentTab;
        this.getState().blocked = action.data.blocked;
        break;
      case 'medicalrecord.MedicalRecord.getOrderAudit':
        this.getState().signOrdersList = action.data;
        this.getState().signOrdersCount = (action as any).count;
        break;
      default:
        break;
    }
    return state;
  }

  areEqual(state1: SSignOrdersPageStore, state2: SSignOrdersPageStore) {
    return false;
  }
}

const signOrdersPageStore = new SignOrdersPageStore(appDispatcher);
export default signOrdersPageStore;
