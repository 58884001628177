import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import { OptionType } from 'stores/_mobx/dropDown/technician';

interface PayloadAssignMultiple {
  ids: number[];
  status?: 'P' | 'R' | 'A';
  state?: 'New' | 'Assigned' | string;
  technician: OptionType;
}

class OrderOverview {
  isEUnityLinkFetching: boolean = false;
  proceedAssign: boolean = false;

  constructor() {
    makeObservable(this, {
      proceedAssign: observable,
      isEUnityLinkFetching: observable,

      setProceedAssign: action,
      setFetchingUnityLink: action,
    });
  }

  setProceedAssign(inProgress: boolean) {
    this.proceedAssign = inProgress;
  }

  setFetchingUnityLink(inProgress: boolean) {
    this.isEUnityLinkFetching = inProgress;
  }

  async assignOrders({
    ids,
    status = 'A',
    state = 'A',
    technician,
  }: PayloadAssignMultiple) {
    const isOrderAssigned = state === 'Assigned';

    const orders = ids.map((refid) =>
      isOrderAssigned
        ? { refid, orderstatus: 'Assign', status: 'Assigned' }
        : { refid }
    );

    const data = [
      orders,
      status,
      isOrderAssigned ? 'A' : state,
      technician.value,
      technician.userType,
      technician.label,
    ];

    this.setProceedAssign(true);

    try {
      const result = await apiRequest<'S' | 'SE'>({
        url: 'order.Order.UpdateMultipleAssign',
        data,
      });

      if (result === 'S') {
        Notification.success(
          `Technician was successfully ${
            isOrderAssigned ? 're-assigned' : 'assigned'
          }!`
        );
        return true;
      }
    } catch (e: any) {
      Notification.danger('Error occurred!');
      return false;
    } finally {
      this.setProceedAssign(false);
    }
  }

  async unassignOrders(ids: number[]) {
    const orders = ids.map((refid) => ({ refid }));

    this.setProceedAssign(true);

    try {
      const result = await apiRequest<'S' | 'SE'>({
        url: 'order.Order.updateTimeAndSign',
        data: [orders, 'N', null],
      });

      if (result === 'S') {
        Notification.success('Technician unassigned!');
        return true;
      }
    } catch (e: any) {
      Notification.danger('Error occurred!');
      return false;
    } finally {
      this.setProceedAssign(false);
    }
  }

  async openEUnityLinkByParams(orderId: number | number[]) {
    this.setFetchingUnityLink(true);
    try {
      const searchParams = await apiRequest<string>({
        url: 'order.Order.getGenerateEunityLink',
        data: [orderId],
      });

      const url = await this.getEUnityLink(searchParams);

      window.open(url, '_blank').focus();
    } catch (e: any) {
    } finally {
      this.setFetchingUnityLink(false);
    }
  }

  async getEUnityLink(searchParams: string) {
    try {
      const queryParams = new URLSearchParams(
        searchParams.replace(/^.*\?/, '')
      );

      const url = await apiRequest<string>({
        url: 'medicalrecord.MedicalRecord.generateEunityLink',
        data: [queryParams.get('accession_number'), queryParams.get('patient')],
      });
      return url;
    } catch (e: any) {
      return '';
    }
  }
}

export const storeOrderOverview = new OrderOverview();
