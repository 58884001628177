import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeCodeMapping,
  CodeDetailType,
} from 'stores/_mobx/systemSetup/codeSet/codeMapping';
import { URL_CODE_MAPPING_BY_MODALITY } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropType extends RouteComponentProps<MatchParams> {}

const CodeMappingEditPage = ({ match: { params }, history }: PropType) => {
  const { fetching, fetchingCptCodes, cptCodes, code } = storeCodeMapping;

  const handleSubmit = async (payload: CodeDetailType) =>
    storeCodeMapping.updateCode(payload).then((result) => {
      if (result) return result;
      history.push(URL_CODE_MAPPING_BY_MODALITY);
    });

  useEffect(() => {
    const id = Number(params.id);

    storeCodeMapping.getCode(id);
    storeCodeMapping.getCptCodes({
      id,
      filterQCode: false,
      filterRCode: false,
    });

    return storeCodeMapping.clearCode;
  }, [params.id]);

  return (
    <>
      <Title title="Code Mapping By Modality" />
      <Form
        defaultValues={code}
        cptCodes={cptCodes}
        backUrl={URL_CODE_MAPPING_BY_MODALITY}
        onSubmit={handleSubmit}
        onCodeSearch={storeCodeMapping.getCptCodes}
      />
      {(fetching || fetchingCptCodes) && <SpinnerFixed />}
    </>
  );
};

export default observer(CodeMappingEditPage);
