import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import GridActionControls from 'page/components/GridActionControls';
import Filter from './components/Filter';
import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';

import {
  storeFinding,
  FilterModel,
} from 'stores/_mobx/systemSetup/masterSetting/finding';
import { MS_FINDINGS as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_FINDING_ADD,
  URL_FINDING,
} from 'constant/path/systemSetup';

const columns = [
  { name: 'name', title: 'Name' },
  { name: 'category_name', title: 'Category' },
  { name: 'comment', title: 'Comment' },
  {
    name: 'refid',
    title: 'Action',
    className: 'width-75',
    render: (id: number) => (
      <GridActionControls
        id={id}
        url={`${URL_FINDING}/edit/${id}`}
        onDelete={storeFinding.setIdForDelete}
      />
    ),
  },
];

const FindingListPage = () => {
  const {
    fetching,
    findingList,
    findingTotal,
    idForDelete,
    filter: { status },
    page: { pagination, setPagination, setPaginationToStart },
  } = storeFinding;

  const gridRef = useRef(null);

  const [selected, setSelected] = useState<number[]>([]);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleFilterChange = (filter: FilterModel) => {
    storeFinding.setFilter(filter);

    debouncedMethod();
  };

  const updateIfSucceed = (isSucceed: boolean) => {
    if (isSucceed) {
      gridRef.current?.clearSelection();
      storeFinding.getFindingMain();
    }
  };

  const handleChangeStatus = () => {
    storeFinding.changeFindingStatus(selected).then(updateIfSucceed);
  };

  const handleConfirmDelete = () => {
    storeFinding.deleteFinding().then(updateIfSucceed);
  };

  useEffect(() => {
    storeFinding.getFindingMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Finding">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <Filter onSearch={handleFilterChange} />

      <Grid
        id="finding_grid"
        selectId="refid"
        columns={columns}
        onAjax={fetching}
        dataSource={findingList}
        dataSourceCount={findingTotal}
        pagination={pagination}
        ref={gridRef}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        gridControlPanel={
          <>
            <GridControlButton title="Add" url={URL_FINDING_ADD} />
            <GridControlButton
              title={status === 'A' ? 'Deactivate' : 'Activate'}
              disabled={!selected.length}
              onClick={handleChangeStatus}
            />
          </>
        }
      />
      {idForDelete ? (
        <DialogConfirm
          onCancel={storeFinding.clearIdForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this Finding?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(FindingListPage);
