import { RestorePasswordUserData } from 'services/UserProfileRequestService';

interface State {
  step: 1 | 2 | 3 | 4;
  userData: RestorePasswordUserData | null;
  errorMessage: string;
  pin: string;
}

export enum ActionType {
  SetUserData = 'setUserData',
  SetSecurityQuestionSuccess = 'setSecurityQuestionSuccess',
  ChangePasswordSuccess = 'changePasswordSuccess',
  SetErrorMessage = 'setErrorMessage',
}

type Action = { type: ActionType; payload?: any };

export const initialState: State = {
  step: 1,
  userData: null,
  errorMessage: '',
  pin: '',
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setErrorMessage':
      return {
        ...state,
        errorMessage: action.payload,
      };
    case 'setUserData':
      return {
        ...state,
        userData: action.payload,
        step: 2,
        errorMessage: '',
      };
    case 'setSecurityQuestionSuccess':
      return { ...state, step: 3, errorMessage: '' };
    case 'changePasswordSuccess':
      return { ...state, errorMessage: '', step: 4, pin: action.payload };

    default:
      return state;
  }
}
