import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/FormPayerInfo';

import {
  storePayerInfo,
  FormModel,
} from 'stores/_mobx/systemSetup/masterSetting/payerInfo';
import { URL_PAYER_INFO } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const PayerInfoAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: FormModel) =>
    storePayerInfo.addPayerInfo(data).then((error) => {
      if (!error) history.push(URL_PAYER_INFO);
    });

  return (
    <>
      <Title title="Add Payer Information" />
      <Form backUrl={URL_PAYER_INFO} onSubmit={handleSubmit} />
    </>
  );
};

export default PayerInfoAddPage;
