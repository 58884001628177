import DateUtils from '../../utils/DateUtils';

const IS_EMAIL =
  /^([a-zA-Z0-9_.+-])+@(([cd a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const IS_PRICE = /^(([1-9]\d{0,2}(,\d{3})*)|(([1-9]\d*)?\d))(\.\d\d)?$/;
const IS_ZIP = /^\d{5}((?:[-\s]\d{4})|(\d{4}))?$/;
const IS_NPI = /^\d{10}$/;
const IS_TIME_STRING = /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
const IS_NUMBER = /^(-?\d*)$/;
const IS_POSITIVE_NUMBER = /^(\d*)$/;
const IS_NEGATIVE_NUMBER = /^(-\d*)$/;
const IS_PHONE_NUMBER = /^((\d){3}-(\d){3}-(\d){4})$/;
const IS_SSN = /^([1-9]{3}-[1-9]{2}-[1-9]{4})$/;
const Rules: any = {
  pattern: (value: string, pattern: RegExp) => pattern.test(value),

  _checkPattern: (value: string, pattern: RegExp) =>
    value ? pattern.test(value) : true,

  price: (value: string) => (value ? Rules.pattern(value, IS_PRICE) : true),

  max: (value: string, limit: number) =>
    value ? parseInt(value) <= limit : 0 <= limit,

  min: (value: string, limit: number) =>
    value ? parseInt(value) >= limit : 0 <= limit,

  positive: (value: string) => Rules.pattern(value, IS_POSITIVE_NUMBER),

  negative: (value: string) => Rules.pattern(value, IS_NEGATIVE_NUMBER),

  email: (value: string) => Rules._checkPattern(value, IS_EMAIL),

  number: (value: string) => Rules._checkPattern(value, IS_NUMBER),

  phone: (value: string) => Rules._checkPattern(value, IS_PHONE_NUMBER),

  npi: (value: string) => Rules._checkPattern(value, IS_NPI),

  ssn: (value: string) => Rules._checkPattern(value, IS_SSN),

  zip: (value: string) => Rules._checkPattern(value, IS_ZIP),

  timeString: (value: string) => Rules._checkPattern(value, IS_TIME_STRING),

  length: (value: string, min: number, max?: number) =>
    Rules._checkPattern(
      value,
      new RegExp('^(.{' + min + ',' + (max ? max : '') + '})$', 'g')
    ),

  custom: (value: string, callback: (arg: string) => any) => callback(value),

  required: (
    value: string | object,
    flag: 'lazy' | 'checkboxes' | 'default' = 'default'
  ) => {
    switch (flag) {
      case 'lazy': {
        return value === '0' || value === ' ' ? false : !!value;
      }
      case 'checkboxes': {
        const values: any = value;
        for (let i in values) {
          if (values[i]) {
            return true;
          }
        }
        return false;
      }
      default: {
        return !!value;
      }
    }
  },

  time: (value?: string, separator?: string, limit?: number) => {
    if (!value) {
      return true;
    }
    if (!separator) {
      separator = ':';
    }
    const parts = value.split(separator);
    if (parts.length === 2 || parts.length === 3) {
      if (!limit) {
        limit = 13;
      }
      const hours = parseInt(parts[0]);
      const minutes = parseInt(parts[1]);
      if (hours < 0 || hours > limit) {
        return false;
      }
      return parts[1].length === 2 && !(minutes < 0 || minutes > 59);
    } else {
      return false;
    }
  },

  dateString: (value: string, format: string, separator: string) => {
    if (!value) {
      return true;
    }
    if (!separator) {
      separator = '/';
    }
    const parts = value.split(separator);
    if (parts.length !== 3) {
      return false;
    }
    const template: any = { d: -1, m: -1, y: -1 };
    if (format) {
      format.split(separator).forEach((v, i) => {
        template[v] = i;
      });
    } else {
      template.m = 0;
      template.d = 1;
      template.y = 2;
    }
    const day = parts[template.d];
    const month = parts[template.m];
    const year = parts[template.y];

    const getDateMetric = (value: string) => {
      let number = parseInt(value);
      return number ? number : -1;
    };
    return DateUtils.validateDate(
      getDateMetric(day),
      getDateMetric(month),
      getDateMetric(year)
    );
  },
};

let _cache: { [key: string]: any } = {};
const Validation = {
  messages: {
    required: "Can't be empty.",
    max: 'Number is too large.',
    min: 'Number is too small.',
    length: 'This is not valid length.',
    email: 'Invalid email address.',
    number: 'Value is not a number.',
    positive: 'Value is not a positive number.',
    negative: 'Value is not a negative number.',
    phone: 'Should be in xxx-xxx-xxxx format.',
    npi: 'Npi should contain 10 digits.',
    ssn: 'Should be in xxx-xx-xxxx format.',
    zip: 'ZIP code should be 5 or 9 numbers in length',
    time: 'Invalid time format',
    dateString: 'Invalid date format.',
    timeString: 'Invalid time format, must be hh:mm:ss.',
    price: "Invalid price value. Keep 'xxx.cc' format.",
    default: 'Something wrong.',
  },

  Rules: Rules,

  /**
   * @param value
   * @param rules (if string: 'min:6 max:4 require') (if object: {require: true, min:[1], max: 5, custom: function(v){return v === 4}}
   * @returns []
   */
  validate: (value: string, rules: string | object) => {
    const normalizedRules = Validation._normalizeRules(rules);
    let errors = [];
    for (let i in normalizedRules) {
      if (i) {
        if (Rules.hasOwnProperty(i)) {
          const paramsArr = [].concat(value, normalizedRules[i]);
          const isValid: boolean = Rules[i].apply(Rules, paramsArr);
          if (!isValid) {
            errors.push(i);
          }
        } else {
          throw Error(
            `Unknown validation rule: ${i}. Please use one of the following: ${Object.keys(
              Rules
            )}`
          );
        }
      }
    }
    return errors;
  },

  _normalizeRules: (rules: string | object) => {
    let normalizedRules: { [key: string]: any };
    if (typeof rules === 'string') {
      normalizedRules = Validation._normalizeStringRules(rules);
    } else {
      normalizedRules = Validation._normalizeObjectRules(rules);
    }
    return normalizedRules ? normalizedRules : {};
  },

  _normalizeStringRules: (rules: string) => {
    if (_cache[rules] === undefined) {
      const rulesArr = rules.split(' ');

      let object: { [key: string]: any } = {};

      for (let i = 0; i < rulesArr.length; i++) {
        const args = rulesArr[i].split(':');
        const ruleName = args.shift();
        object[ruleName] = args;
      }
      _cache[rules] = object;
    }
    return _cache[rules];
  },

  _normalizeObjectRules: (rules: { [key: string]: any }) => {
    for (let key in rules) {
      if (rules.hasOwnProperty(key) && !Array.isArray(rules[key])) {
        rules[key] = [rules[key]];
      }
    }
    return rules;
  },
};

export default Validation;
