import Exporter from 'components/project/Exporter';
import FacilityRolesDropdown from 'components/project/dropdown/FacilityRolesDropdown';
import ExamTypeDropdown from 'components/project/dropdown/ExamTypeDropdown';

import { storePriority } from 'stores/_mobx/systemSetup/masterSetting/priority';
import { storeDropdownTechnologist } from 'stores/_mobx/dropDown/technologist';
import { storeDropdownPlaceOfService } from 'stores/_mobx/dropDown/placeOfService';
import { storeDropdownCorporation } from 'stores/_mobx/dropDown/corporation';
import { storeDropdownTechnician } from 'stores/_mobx/dropDown/technician';
import { storePhysician } from 'stores/_mobx/clinicianManager/physician';
import { storeRegion } from 'stores/_mobx/systemSetup/masterSetting/region';

const findTechnologistOptionById = (id) => {
  return storeDropdownTechnologist.findOption(id);
};

export const filterMapper = (filter) => {
  const result = { ...filter };
  if (filter.techId) {
    let option = findTechnologistOptionById(filter.techId);
    if (!option) {
      //different methods, same data
      option = storeDropdownTechnician(Number(filter.techId));
    }
    if (option) {
      result.techId = "Technologist: '" + option.label + "'";
    }
  }

  if (filter.facilityId) {
    const option = FacilityRolesDropdown.findOption(filter.facilityId);
    if (option) {
      result.facilityId = "Facility: '" + option.label + "'";
    }
  }
  if (filter.facility) {
    const option = FacilityRolesDropdown.findOption(filter.facility);
    if (option) {
      result.facility = "Facility: '" + option.label + "'";
    }
  }
  if (filter.corporation) {
    const option = storeDropdownCorporation.findOptionById(filter.corporation);
    if (option) {
      result.corporation = "Corporation: '" + option.label + "'";
    }
  }
  if (filter.corporate) {
    const option = storeDropdownCorporation.findOptionById(filter.corporate);
    if (option) {
      result.corporate = "Corporation: '" + option.label + "'";
    }
  }
  if (filter.corporateId) {
    const option = storeDropdownCorporation.findOptionById(filter.corporateId);
    if (option) {
      result.corporateId = "Corporation: '" + option.label + "'";
    }
  }
  if (filter.examtype) {
    const option = ExamTypeDropdown.findOption(filter.examtype);
    if (option) {
      result.examtype = "Exam Type: '" + option.label + "'";
    }
  }
  if (filter.modalityId) {
    const option = ExamTypeDropdown.findOption(filter.modalityId);
    if (option) {
      result.modalityId = "Exam Type: '" + option.label + "'";
    }
  }
  if (filter.priority) {
    const option = storePriority.findOption(Number(filter.priority) || 0);
    if (option) {
      result.priority = "Priority: '" + option.label + "'";
    }
  }
  if (filter.location) {
    const option = storeRegion.findOption(Number(filter.location));
    if (option) {
      result.location = "Location: '" + option.label + "'";
    }
  }
  if (filter.physician) {
    const option = storePhysician.findPhysicianById(Number(filter.physician));
    if (option) {
      result.physician = "Physician: '" + option.label + "'";
    }
  }
  if (filter.locId) {
    const option = storeRegion.findOption(Number(filter.locId));
    if (option) {
      result.localId = "Location: '" + option.label + "'";
    }
  }

  if (filter.pos) {
    const option = storeDropdownPlaceOfService.findOption(Number(filter.pos));
    if (option) {
      result.pos = "POS: '" + option.label + "'";
    }
  }

  return result;
};

const getDataArguments = (filter) => () => {
  const out = { ...filter };

  out.toString = function () {
    const out = [];
    if (filter.techId) {
      let option = findTechnologistOptionById(filter.techId);
      if (!option) {
        //different methods, same data
        option = storeDropdownTechnician(Number(filter.techId));
      }
      if (option) {
        out.push("Technologist: '" + option.label + "'");
      }
    }
    if (filter.patientLastName) {
      out.push("Patient Last Name: '" + filter.patientLastName + "'");
    }
    if (filter.patientFirstName) {
      out.push("Patient First Name: '" + filter.patientFirstName + "'");
    }
    if (filter.lastName) {
      out.push("Last Name: '" + filter.lastName + "'");
    }
    if (filter.firstName) {
      out.push("First Name: '" + filter.firstName + "'");
    }
    if (filter.phone) {
      out.push("Phone: '" + filter.phone + "'");
    }
    if (filter.mail) {
      out.push("Email: '" + filter.mail + "'");
    }
    if (filter.fax) {
      out.push("Fax: '" + filter.fax + "'");
    }
    if (filter.procedureCode) {
      out.push("Procedure Code: '" + filter.procedureCode + "'");
    }
    if (filter.procedureDescription) {
      out.push("Procedure Code: '" + filter.procedureDescription + "'");
    }
    if (filter.facilityId) {
      const option = FacilityRolesDropdown.findOption(filter.facilityId);
      if (option) {
        out.push("Facility: '" + option.label + "'");
      }
    }
    if (filter.facility) {
      const option = FacilityRolesDropdown.findOption(filter.facility);
      if (option) {
        out.push("Facility: '" + option.label + "'");
      }
    }
    if (filter.corporation) {
      const option = storeDropdownCorporation.findOptionById(
        filter.corporation
      );
      if (option) {
        out.push("Corporation: '" + option.label + "'");
      }
    }
    if (filter.corporate) {
      const option = storeDropdownCorporation.findOptionById(filter.corporate);
      if (option) {
        out.push("Corporation: '" + option.label + "'");
      }
    }
    if (filter.corporateId) {
      const option = storeDropdownCorporation.findOptionById(
        filter.corporateId
      );
      if (option) {
        out.push("Corporation: '" + option.label + "'");
      }
    }
    if (filter.examtype) {
      const option = ExamTypeDropdown.findOption(filter.examtype);
      if (option) {
        out.push("Exam Type: '" + option.label + "'");
      }
    }
    if (filter.modalityId) {
      const option = ExamTypeDropdown.findOption(filter.modalityId);
      if (option) {
        out.push("Exam Type: '" + option.label + "'");
      }
    }
    if (filter.priority) {
      const option = storePriority.findOption(Number(filter.priority) || 0);
      if (option) {
        out.push("Priority: '" + option.label + "'");
      }
    }
    if (filter.location) {
      const option = storeRegion.findOption(Number(filter.location));
      if (option) {
        out.push("Location: '" + option.label + "'");
      }
    }
    if (filter.physician) {
      const option = storePhysician.findPhysicianById(Number(filter.physician));
      if (option) {
        out.push("Physician: '" + option.label + "'");
      }
    }
    if (filter.locId) {
      const option = storeRegion.findOption(Number(filter.locId));
      if (option) {
        out.push("Location: '" + option.label + "'");
      }
    }
    if (filter.year) {
      out.push("Year: '" + filter.year + "'");
    }
    if (filter.pos) {
      const option = storeDropdownPlaceOfService.findOption(Number(filter.pos));
      if (option) {
        out.push("POS: '" + option.label + "'");
      }
    }
    if (filter.patientMrn) {
      out.push("Patient MRN: '" + filter.patientMrn + "'");
    }
    if (filter.dosStart) {
      out.push("DOS after: '" + filter.dosStart + "'");
    }
    if (filter.dosEnd) {
      out.push("DOS before: '" + filter.dosEnd + "'");
    }
    return out.join('; ');
  };
  return out;
};

const Export = ({
  callback,
  reportName,
  exporterName,
  filter,
  self,
  grid,
  columns,
}) => (
  <Exporter
    exporter={exporterName}
    filters={getDataArguments(filter)}
    callback={callback}
    this={self}
    grid={grid}
    reportName={reportName}
    getExportColumns={columns}
    pdfExporter={false}
  />
);

export default Export;
