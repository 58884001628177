import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Radio } from 'components/form/radio';
import { DateRange } from 'components/form/dateRange';
import { Input } from 'components/form/textField';
import { InputCalendar } from 'components/form/inputCalendar';
import { DropDownExamType } from 'components/project/dropdown/ExamTypeDropdown';
import { DropdownProviderType } from 'components/project/dropdown/ProviderTypeDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropdownPayerName } from 'components/project/dropdown/PayerNameDropdown';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { DropdownDivision } from 'components/project/dropdown/Division';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';

import {
  defaultFilterValue,
  storeClaimReview,
  FilterClaimReviewType,
} from 'stores/_mobx/billingCodding/claimReview';

const CLAIM_TYPE_OPTIONS = [
  { value: 'T', label: 'Technical' },
  { value: 'P', label: 'Professional' },
];

const RELEASE_OPTIONS = [
  { value: 'R', label: 'Released' },
  { value: 'N', label: 'Not Released' },
];

const SIGNED_OPTIONS = [
  { value: 'Y', label: 'Signed' },
  { value: 'N', label: 'Unsigned' },
  { value: '', label: 'All' },
];

const VIEW_BY_OPTIONS = [
  { value: 'batch', label: 'Batch' },
  { value: 'claim', label: 'Claim' },
];

interface PropsType {
  isFilterVisible: boolean;
  fetching: boolean;
  onSubmit: (filter: FilterClaimReviewType) => void;
}

const Filter = ({ isFilterVisible, fetching, onSubmit }: PropsType) => {
  const { control, watch, reset, handleSubmit } = useForm({
    defaultValues: storeClaimReview.filter,
  });

  const isReleasedSelected = watch('releaseStatus') === 'R';

  const handleClickReset = () => {
    reset(defaultFilterValue);

    onSubmit(defaultFilterValue);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'releaseStatus' && formValue.releaseStatus === 'N') {
        reset({
          ...formValue,
          claimReleaseDatePeriod: 'A',
          claimReleaseDateFrom: '',
          claimReleaseDateTo: '',
        });
      }
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReleasedSelected]);

  return (
    <form
      className={isFilterVisible ? '' : 'd-none'}
      onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <Input
          name="patientLastName"
          label="Last Name"
          className="col-md-6 col-lg-3"
          control={control}
        />
        <Input
          name="patientFirstName"
          label="First Name"
          className="col-md-6 col-lg-3"
          control={control}
        />
        <DropDownExamType
          name="modality"
          label="Modality"
          optionsForOrderOnly
          className="col-md-6 col-lg-3"
          isMulti
          isStyleDependsOnValue
          control={control}
        />
        <DropdownProviderType
          name="facilityType"
          label="Provider Type"
          className="col-md-6 col-lg-3"
          isMulti
          isStyleDependsOnValue
          control={control}
        />
        <div />

        <Input
          name="procedureCode"
          label="CPT Code"
          className="col-md-6 col-lg-3"
          control={control}
        />
        <DropdownPayerName
          name="primaryPayer"
          label="Primary Payer"
          className="col-md-6 col-lg-3"
          isMulti
          isStyleDependsOnValue
          control={control}
        />
        <DropDownFacility
          name="facility"
          label="Facility"
          className="col-md-6 col-lg-3"
          isMulti
          isStyleDependsOnValue
          control={control}
        />
        <DropDownState
          name="state"
          label="State"
          className="col-md-6 col-lg-3"
          isMulti
          isStyleDependsOnValue
          control={control}
        />
        <div />
        <DropdownDivision
          name="division"
          label="Division"
          className="col-md-6 col-lg-3"
          control={control}
        />
        <Radio
          name="releaseStatus"
          label="Status of release"
          className="col-sm-12 col-md-6 col-lg-3 part-inline"
          options={RELEASE_OPTIONS}
          control={control}
        />
        <Radio
          name="claimType"
          label="Claim Type"
          className="col-sm-12 col-md-6 col-lg-3 part-inline"
          options={CLAIM_TYPE_OPTIONS}
          control={control}
        />
        <Radio
          name="isSigned"
          label="Provider"
          className="col-sm-12 col-md-6 col-lg-3 part-inline"
          options={SIGNED_OPTIONS}
          control={control}
        />
        <div />
        <DateRange
          nameFrom="scheduleServiceDateFrom"
          nameTo="scheduleServiceDateTo"
          nameRadio="scheduleServiceDatePeriod"
          radioClassName="col-sm-12 col-md-6"
          control={control}
        />
        <div />
        <DateRange
          nameFrom="claimReleaseDateFrom"
          nameTo="claimReleaseDateTo"
          nameRadio="claimReleaseDatePeriod"
          labelFrom="Release Date From"
          labelTo="Release Date To"
          labelRadio="Claim Release Date Period"
          radioClassName="col-sm-12 col-md-6"
          disabled={!isReleasedSelected}
          control={control}
        />
        <div />
        <Radio
          name="viewBy"
          label="View by"
          className="col-sm-6 col-md-3 col-xl-2 part-inline"
          options={VIEW_BY_OPTIONS}
          control={control}
        />
        <Input
          name="batch"
          label="Batch #"
          className="col-sm-6 col-md-2"
          control={control}
        />
        <InputCalendar
          name="batchDate"
          label="Batch Date"
          className="col-sm-6 col-md-4 col-lg-3 col-xl-2"
          control={control}
        />

        <ControlsLayout alignX="auto">
          <Button
            variant="warning"
            text="Reset"
            disabled={fetching}
            onClick={handleClickReset}
          />
          <Button
            text="Apply"
            type="submit"
            data-testid="submit"
            disabled={fetching}
          />
        </ControlsLayout>
      </div>
    </form>
  );
};

export default Filter;
