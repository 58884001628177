export const TECH_VOLUME = {
  PAGE: 'SummaryTech',
};

export const TECH_ENCOUNTER = {
  PAGE: 'OtherPtSeenCnt',
};

export const TECH_EXAM_COUNT_OTHER = {
  PAGE: 'OtherTechExamCnt',
};

export const TECH_PATIENT_OTHER = {
  PAGE: 'OtherTechPtDtl',
};

export const BRIEFCASE_HISTORY = {
  PAGE: 'UpHistory',
};

export const USER_EXPENSE = {
  PAGE: 'ExpenseReport',
};

export const EMPLOYER_TIME_SHEET = {
  PAGE: 'EmpReport',
};

export const FACILITY_EXAM_COUNT = {
  PAGE: 'FacilityExamCnt',
};

export const FACILITY_STUDIES = {
  PAGE: 'FacilityPtDtl',
};

export const FACILITY_PATIENT = {
  PAGE: 'FacilityPtRst',
};

export const RADIOLOGY_REPORT = {
  PAGE: 'RadReport',
};

export const ORDER_REPORT = {
  PAGE: 'OrderReport',
};

export const PHYSICIAN_EXAM_COUNT = {
  PAGE: 'PtSeenCnt',
};

export const PROCEDURE_CODE_COUNT = {
  PAGE: 'ProcCodeCnt',
};

export const TECH_STUDIES = {
  PAGE: 'TechExam',
};

export const TECH_EXAM_COUNT = {
  PAGE: 'TechExamCnt',
};

export const TECH_PATIENT = {
  PAGE: 'TechPtDtl',
};

export const ORDER_TRACK_HISTORY = {
  PAGE: 'trackhistory',
};

export const PROCEDURE_SUMMARY = {
  PAGE: 'ProcSumCnt',
};

export const AR_AGING_REPORT = {
  PAGE: 'araging_report',
};

export const INVOICE_REPORT = {
  PAGE: 'invoiceReport',
};

export const PATIENT_REPORT = {
  PAGE: 'studybyphysician_report',
};

export const PROCEDURE_COUNT = {
  PAGE: 'OtherProcCodeCnt',
};

export const EXAM_REPORT_OTHER = {
  PAGE: 'OtherExpenseReport',
};

export const SUMMARY_LOCATION = {
  PAGE: 'SummaryLoc',
};

export const TECH_EXAM_REVIEW = {
  PAGE: 'TechReport',
};

export const FACILITY_SUMMARY = {
  PAGE: 'summaryfcltyrpt',
};

export const UTILIZATION = {
  PAGE: 'ClinicianOutcome',
};

export const MODALITY_SUMMARY = {
  PAGE: 'ModalitySummary',
};

export const CORPORATION_SUMMARY = {
  PAGE: 'CorporateSummary',
};

export const EMPLOYEE_INFO = {
  PAGE: 'employee_information',
};

export const UNBILLED_CLAIMS = {
  PAGE: 'unbilledClaimsReport',
};
