import React, { useMemo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Tabs from 'components/tabs';
import UnsignedOrdersContainer, {
  UnsignedOrdersTab,
} from './UnsignedOrdersTab';
import SignedOrdersContainer, { SignedOrdersTab } from './SignedOrdersTab';
import GroupOrdersContainer, { GroupOrdersTab } from './GroupOrdersTab';

import UserProfileStore from 'stores/UserProfileStore';
import {
  URL_SIGNED_ORDER_UNSIGNED_TAB,
  URL_SIGNED_ORDER_GROUP_TAB,
  URL_SIGNED_ORDER_SIGNED_TAB,
} from 'constant/path/clinicalManager';
import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import * as PATH from 'constant/path/clinicalManager';

const SignedOrderPage = () => {
  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isTabVisible, toggleTabsVisibility] = useState<boolean>(true);

  const tabsList = useMemo(() => {
    const userType = UserProfileStore.getUserType();

    const tabs = [
      {
        label: UnsignedOrdersTab.TITLE,
        url: URL_SIGNED_ORDER_UNSIGNED_TAB,
      },
      { label: SignedOrdersTab.TITLE, url: URL_SIGNED_ORDER_SIGNED_TAB },
      { label: GroupOrdersTab.TITLE, url: URL_SIGNED_ORDER_GROUP_TAB },
    ];
    return tabs.filter((tab, idx) =>
      userType === 'D' || userType === 'U' ? true : idx > 1
    );
  }, []);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  return (
    <>
      {isTabVisible && (
        <LayoutSideTitle title="Sign Orders">
          <Button
            variant="default"
            text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
            onClick={handleToggleFilter}
          />
        </LayoutSideTitle>
      )}
      <div className="orders-page">
        {isTabVisible && <Tabs tabsList={tabsList} />}
        <Switch>
          <Route
            exact
            path={PATH.URL_SIGNED_ORDER_UNSIGNED_TAB}
            render={(props: any) => (
              <UnsignedOrdersContainer
                {...props}
                isFilterVisible={isFilterVisible}
                toggleFilterVisibility={handleToggleFilter}
                toggleTabsVisibility={toggleTabsVisibility}
              />
            )}
          />
          <Route
            exact
            path={PATH.URL_SIGNED_ORDER_SIGNED_TAB}
            render={(props: any) => (
              <SignedOrdersContainer
                {...props}
                isFilterVisible={isFilterVisible}
                toggleFilterVisibility={handleToggleFilter}
                toggleTabsVisibility={toggleTabsVisibility}
              />
            )}
          />
          <Route
            exact
            path={PATH.URL_SIGNED_ORDER_GROUP_TAB}
            render={(props: any) => (
              <GroupOrdersContainer
                {...props}
                isFilterVisible={isFilterVisible}
                toggleFilterVisibility={handleToggleFilter}
                toggleTabsVisibility={toggleTabsVisibility}
              />
            )}
          />
        </Switch>
      </div>
    </>
  );
};

export default SignedOrderPage;
