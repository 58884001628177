type ReturnGroupType = Record<string, Record<string, any>[]>;

const GroupUtils = {
  getGroupedDs: function (
    dataSource: any,
    groupBy?: string | string[]
  ): ReturnGroupType {
    if (!groupBy) return dataSource;

    let thisGroupBy = typeof groupBy === 'string' ? [groupBy] : [...groupBy];

    const firstGroupName: string = thisGroupBy.shift();
    let out: { [key: string]: any } = this.groupArrayByName(
      dataSource,
      firstGroupName
    );

    while (thisGroupBy.length) {
      let groupName = thisGroupBy.shift();
      out = GroupUtils.groupSubObjects(out, groupName);
    }

    return out;
  },

  groupSubObjects: (parent: { [key: string]: any }, name: string) => {
    if (Array.isArray(parent)) {
      return GroupUtils.groupArrayByName(parent, name);
    } else {
      for (let groupName in parent) {
        if (Array.isArray(parent[groupName])) {
          parent[groupName] = GroupUtils.groupArrayByName(
            parent[groupName],
            name
          );
        } else {
          parent[groupName] = GroupUtils.groupSubObjects(
            parent[groupName],
            name
          );
        }
      }
      return parent;
    }
  },

  groupArrayByName: (ds: Record<string, any>[], name: string) => {
    let out: { [key: string]: any } = {};

    for (let i = 0; i < ds.length; i++) {
      const nameValue = ds[i][name];

      if (!out[nameValue]) out[nameValue] = [];
      out[nameValue].push(ds[i]);
    }

    return out;
  },
};

export default GroupUtils;
