import { useCallback, useRef, useState } from 'react';

const useMemoState = <T extends Record<string, any>>(
  initValue: T
): [T, (value: T) => void] => {
  const prevValue = useRef<T>();

  const [state, setState] = useState<T>(initValue);

  const handleSetState = useCallback((newValue: T) => {
    const prev = prevValue.current || null;

    const isValueEqual = JSON.stringify(prev) === JSON.stringify(newValue);

    if (!isValueEqual) {
      prevValue.current = newValue;
      setState(newValue);
    }
  }, []);

  return [state, handleSetState];
};

export default useMemoState;
