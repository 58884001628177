import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import {
  apiRequest,
  errorPrettier,
  ErrorType,
  JsonLdResponseType,
} from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { storeImport } from 'stores/_mobx/import';
import { CategoryType } from 'stores/_mobx/systemSetup/masterSetting/cptCategory';
import AbortControllerService from 'stores/_mobx/abortControllerService';

import CsvExporter from 'utils/CsvExporter';
import AccessUtils from 'utils/AccessUtils';
import { CODE_ACTIVATION_MASTER as PAGE_ID } from 'constant/pagesId/systemSetup';
import { GRID_ID_CODE_ACTIVATION_MASTER } from 'constant/gridsId/systemSetup';

const defaultPermissionValue: PermissionType = {
  addCode: false,
  editCode: false,
  deleteCode: false,
  activateCode: false,
  deactivateCode: false,
  importAction: false,
};

export const initialValues: CptCodeDetailsType = {
  cptCategory: { id: 0 },
  cptCode: '',
  cptDescription: '',
  cptPrice: 0,
  id: null,
  modifierCode: '',
  flag: 'A',
};

export const initStateFilter: FilterModel = {
  cptCode: '',
  cptDescription: '',
  cptCategoryId: 0,
  cptCodeFlag: 'A',
};

export interface CptType {
  cptCategory?: CategoryType;
  cptCode: string;
  cptDescription: string;
  cptPrice: number;
  createdAt: string;
  createdBy: string;
  flag: 'A' | 'I';
  id: number;
  modifierCode: string;
  updatedAt: string;
}

export interface CodesCollectionResponseType {
  totalItems: number;
  items: CptType[];
}

export interface CptCodeDetailsType
  extends Omit<
    CptType,
    'updatedAt' | 'createdBy' | 'createdAt' | 'cptCategory'
  > {
  cptCategory: {
    id: number;
  };
}

export interface FilterModel {
  cptCode: string;
  cptDescription: string;
  cptCategoryId: number;
  cptCodeFlag: 'A' | 'I';
}

export interface PermissionType {
  addCode: boolean;
  editCode: boolean;
  deleteCode: boolean;
  activateCode: boolean;
  deactivateCode: boolean;
  importAction: boolean;
}

class CodeActivation extends AbortControllerService {
  fetching: boolean = false;
  code?: CptCodeDetailsType = undefined;
  codeList: CptType[] = [];
  codeTotal: number = 0;
  idForDelete: number = 0;
  filter: FilterModel = initStateFilter;
  permission: PermissionType = defaultPermissionValue;

  page: Pagination = new Pagination({ id: GRID_ID_CODE_ACTIVATION_MASTER });

  constructor() {
    super();

    makeObservable(this, {
      fetching: observable,
      codeList: observable,
      codeTotal: observable,
      idForDelete: observable,
      code: observable,
      filter: observable,

      setFetching: action,
      setCode: action,
      setCodesCollection: action,
      setIdForDelete: action,
      setFilter: action,
      setPermission: action,
      clearIdForDelete: action.bound,
      clearCode: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFilter(filter: FilterModel) {
    this.filter = filter;
  }

  setCodesCollection({ items, totalItems }: JsonLdResponseType<CptType>) {
    this.codeList = items;
    this.codeTotal = totalItems;
  }

  setCode(code?: CptCodeDetailsType) {
    this.code = code;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setPermission(permission: PermissionType) {
    this.permission = permission;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  clearCode() {
    this.code = undefined;
  }

  async getCodeList() {
    const {
      filter,
      page: { pagination },
    } = this;

    const data = {
      ...filter,
      page: pagination.page,
      itemsPerPage: pagination.pageSize,
    };

    const abortSignal = this.manageAbortController();

    this.setFetching(true);

    try {
      const response = await apiRequest<JsonLdResponseType<CptType>>({
        url: 'cpt_codes',
        method: 'GET',
        contentType: 'ld',
        legacy: false,
        signal: abortSignal,
        data,
      });

      this.setCodesCollection(response);
    } catch (e) {
      this.setCodesCollection({ totalItems: 0, items: [] });
    } finally {
      if (!abortSignal.aborted) {
        this.setFetching(false);

        this.clearAbortController();
      }
    }
  }

  async getCode(id: number) {
    this.setFetching(true);
    try {
      const { createdBy, createdAt, updatedAt, cptCategory, ...response } =
        await apiRequest<CptType>({
          url: `cpt_codes/${id}`,
          method: 'GET',
          legacy: false,
        });

      const code = {
        ...response,
        cptCategory: {
          id: cptCategory?.id || 0,
        },
      };

      this.setCode(code);
    } catch (e) {
      Notification.danger("CPT code you request doesn't exist!");
      this.setCode(initialValues);
    } finally {
      this.setFetching(false);
    }
  }

  async modifyCode({ id, cptCategory, ...payload }: CptCodeDetailsType) {
    const isUpdating = Boolean(id);

    const data = {
      ...payload,
      cptCategory: cptCategory.id ? `/cpt_categories/${cptCategory.id}` : null,
    };

    try {
      await apiRequest<CptType>({
        url: isUpdating ? `cpt_codes/${id}` : 'cpt_codes',
        method: isUpdating ? 'PATCH' : 'POST',
        legacy: false,
        data,
      });

      Notification.success(
        `Code was ${isUpdating ? 'updated' : 'added'} successfully!`
      );
      return null;
    } catch (e: any) {
      const errors = errorPrettier(e as ErrorType<CptType>);

      if (!errors) Notification.danger('An error occurred!');

      return errors;
    }
  }

  async deleteCode() {
    this.setFetching(true);
    try {
      await apiRequest({
        url: `cpt_codes/${this.idForDelete}`,
        method: 'DELETE',
        legacy: false,
      });
      Notification.success('Code was deleted!');
      return true;
    } catch (e: unknown) {
      Notification.danger(
        "This CPT code is already in use. You can't delete it!"
      );
      this.setFetching(false);
      return false;
    } finally {
      this.clearIdForDelete();
    }
  }

  async changeCodeStatus(ids: number[]) {
    if (this.fetching) return false;

    this.setFetching(true);

    const flag = this.filter.cptCodeFlag === 'A' ? 'I' : 'A';

    const list = ids.map((id) => ({ id, flag }));

    try {
      await apiRequest<{ mapping: CptType[] }>({
        url: 'mass_actions',
        method: 'PATCH',
        legacy: false,
        data: {
          iri: '/cpt_codes',
          mapping: list,
        },
      });

      const statusMsg =
        this.filter.cptCodeFlag === 'I' ? 'activated' : 'deactivated';

      Notification.success(
        `${ids.length} Code${ids.length > 1 ? 's were' : ' was'} ${statusMsg}!`
      );
      return true;
    } catch (e) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async importModifiers(file: File) {
    try {
      const fileName = await storeImport.uploadFile({
        url: 'temppath/tempImportUpload.php',
        file,
      });

      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'codemaster.CPTCode.ImportCPTCode',
        data: [fileName],
      });

      if (result === 'E') {
        Notification.danger("Can't import codes, because it already exist");
        return false;
      } else if (result === 'S') {
        Notification.success('Codes were imported successfully');
        return true;
      }
      throw Error('');
    } catch (e) {
      Notification.danger('An error occurred!');
      return false;
    }
  }

  exportPart = (cells: { name: string; title: string }[]) => {
    const fileName =
      UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
      'code_activation_master';

    const codesList = this.codeList.map((el) => ({
      ...el,
      cptPrice: `$${el.cptPrice}`,
      flag: el.flag === 'A' ? 'Active' : 'Inactive',
    }));

    const csvExporter = new CsvExporter(codesList, cells);

    csvExporter.exportAndDownload(fileName);
  };

  exportAll = async (cells: { name: string; title: string }[]) => {
    const data = {
      ...this.filter,
      page: 1,
      itemsPerPage: this.codeTotal,
    };

    const status = this.filter.cptCodeFlag === 'A' ? 'Active' : 'Inactive';

    this.setFetching(true);

    try {
      const response = await apiRequest<CptType[]>({
        url: 'cpt_codes',
        method: 'GET',
        legacy: false,
        data,
      });

      const codesList = response.map((el) => ({
        ...el,
        cptPrice: `$${el.cptPrice}`,
        flag: status,
      }));

      const fileName =
        UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
        'code_activation_master';

      const csvExporter = new CsvExporter(codesList, cells);

      csvExporter.exportAndDownload(fileName);
    } catch (e: any) {
      Notification.danger('Error occurred!');
    } finally {
      this.setFetching(false);
    }
  };

  checkPermission() {
    const addCode = AccessUtils.checkAccess(PAGE_ID.ADD);

    const editCode = AccessUtils.checkAccess(PAGE_ID.EDIT);

    const activateCode = AccessUtils.checkAccess(PAGE_ID.ACTIVE);

    const deactivateCode = AccessUtils.checkAccess(PAGE_ID.INACTIVE);

    const deleteCode = AccessUtils.checkAccess(PAGE_ID.DELETE);

    const importAction = AccessUtils.checkAccess(PAGE_ID.IMPORT);

    this.setPermission({
      importAction,
      addCode,
      editCode,
      deleteCode,
      activateCode,
      deactivateCode,
    });
  }
}

export const storeCodeActivation = new CodeActivation();
