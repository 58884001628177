import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import { Button, IconButton } from 'components/button';
import Form from 'components/form/Form';
import UniversalIdTypeDropdown from 'components/project/dropdown/UniversalIdTypeDropdown';
import Text from 'components/form/input/Text';
import Radio from 'components/form/input/Radio';
import FormControl from 'components/form/FormControl';
import Notification from 'components/modal/Notification';
import { Grid } from 'components/grid';
import FormCells from 'components/grid/FormCells';
import Validation from 'components/form/Validation';
import Checkbox from 'components/form/input/Checkbox';
import Fieldset from 'components/form/Fieldset';

import SendingApplicationsActions, {
  SendingApplicationFormDto,
  SftpAccessDto,
  SpecificRuleDto,
} from 'actions/system-setup/master-setup/master-settings/integrations/SendingApplicationsActions';
import StringUtils from 'utils/StringUtils';
import MathUtils from 'utils/MathUtils';
import { URL_INTEGRATION_MASTER } from 'constant/path/systemSetup';

const OPTIONS_SUBSCRIBER = [
  { value: true, label: 'YES' },
  { value: false, label: 'NO' },
];
const OPTIONS_STATUS = [
  { value: false, label: 'Inactive' },
  { value: true, label: 'Active' },
];

const OPTIONS_USE_FOR = [
  { value: 'both', label: 'Both' },
  { value: 'in', label: 'Incoming' },
  { value: 'out', label: 'Outgoing' },
];

interface MatchParams {
  id?: string;
}

export interface PAbstractSendingApplication
  extends RouteComponentProps<MatchParams> {
  parentContext: { backUrl: string };
  updateOverview?: () => void;
}

export class TSftpAccess extends SftpAccessDto {
  displayInput: boolean = false;
}

export class TAbstractSendingApplicationModel extends SendingApplicationFormDto {
  sftpAccess: Array<TSftpAccess> = [];
  host: string = '';
  port: string = '';
}

export class SAbstractSendingApplication {
  title: string = '';
  sftpErrors: any = {};
  errors: any = {};
  model: TAbstractSendingApplicationModel =
    new TAbstractSendingApplicationModel();
}

export default abstract class AbstractSendingApplication<
  P extends PAbstractSendingApplication,
  S extends SAbstractSendingApplication
> extends React.Component<P, S> {
  newId: number;
  sftpValidations: any;

  constructor(props: P) {
    super(props);
    this.state = new SAbstractSendingApplication() as S;
    this.newId = -1;

    this.sftpValidations = {
      required: Validation.Rules.required,
      positive: Validation.Rules.positive,
      custom: (v: any) => {
        if (!v) {
          return true;
        }
        const length = this.state.model.sftpAccess.length;
        if (length > 2) {
          return false;
        } else if (v === 'both' && this.state.model.sftpAccess.length > 1) {
          return false;
        } else {
          return !(v !== 'both' && this.state.model.sftpAccess.length < 2);
        }
      },
    };
  }

  getSftpColumns() {
    return [
      { name: 'host', title: 'Host', render: this.textFactory('host') },
      {
        name: 'port',
        title: 'Port',
        render: this.textFactory('port', 'positive required'),
      },
      {
        name: 'username',
        title: 'Username',
        render: this.textFactory('username'),
      },
      {
        name: 'password',
        title: 'Password',
        render: this.textFactory('password'),
      },
      { name: 'path', title: 'Path', render: this.textFactory('path') },
      {
        name: 'useFor',
        title: 'Use For',
        render: FormCells.dropDownFactory(
          'useFor',
          { required: true, custom: this.sftpValidations.custom },
          this,
          'id',
          OPTIONS_USE_FOR,
          this.state.sftpErrors,
          {
            errorMessages: {
              custom: (v: string) => this.sftpErrorMessage(v),
              required: Validation.messages.required,
            },
          }
        ),
      },
      {
        name: 'id',
        title: 'Control',
        className: 'control',
        render: (id: any) => {
          const access = this.state.model.sftpAccess || [];
          const canAdd = access.length < 2;
          const canDelete = access.length > 1;
          return (
            <div>
              {canAdd && (
                <IconButton
                  className="text-primary fs-5"
                  onClick={this.addNewSftp}>
                  <i className="bi bi-plus-circle" />
                </IconButton>
              )}
              &nbsp;
              {canDelete && (
                <IconButton
                  className="text-primary fs-5"
                  onClick={() => {
                    this.deleteSftp(id);
                  }}>
                  <i className="bi bi-x-circle" />
                </IconButton>
              )}
            </div>
          );
        },
      },
    ];
  }

  sftpErrorMessage(value: string) {
    const length = this.state.model.sftpAccess.length;
    switch (length) {
      case 1:
        if (value !== 'both') {
          return (
            "If '" +
            value +
            "' option selected, two configurations should present"
          );
        }
        break;
      case 2:
        if (value === 'both') {
          return "If 'both' option selected, only one configuration should present";
        }
        break;
    }
    return '';
  }

  addNewSftp = () => {
    const entity: TSftpAccess = Object.assign(new TSftpAccess(), {
      id: this.newId,
      displayInput: true,
    });
    this.newId--;
    this.state.model.sftpAccess.push(entity);
    this.forceUpdate();
  };

  deleteSftp = (id: number) => {
    const access = this.state.model.sftpAccess;
    for (let i = 0; i < access.length; i++) {
      if (access[i].id === id) {
        this.state.model.sftpAccess.splice(i, 1);
        this.forceUpdate();
        break;
      }
    }
  };

  textFactory(name: string, validations?: any) {
    return FormCells.textInputFactory(
      name,
      validations || 'required',
      this,
      'id',
      this.state.sftpErrors
    );
  }

  getTitle(): string {
    throw new Error('Not implemented');
  }

  handleSubmit = (
    updatedModel: any,
    hasErrors: boolean,
    updatedErrors: any
  ) => {
    if (hasErrors) {
      const { state } = this;
      const model = { ...state.model, ...updatedModel };
      const errors = { ...state.errors, ...updatedErrors };
      this.setState({ model, errors });
    } else {
      this.submitSuccess();
    }
  };

  render() {
    const state = this.state;
    const model = state.model || {};
    const clazz = 'col-sm-6 col-md-4';
    const isSftp = this.state.model.isSftp === true;

    return (
      <div className="integrations-page-facility ">
        <Title title={this.getTitle()} />

        <Form
          model={model}
          errors={state.errors}
          onCollectValues={this.onUpdateModel}
          submit={this.handleSubmit}>
          <Fieldset className={clazz}>
            <Text name="namespace" />
            <div className="clearfix" />
            <UniversalIdTypeDropdown name="universalIdType" label="ID type" />
            <div className="clearfix" />
            <Text name="username" validations="length:4:255" />
            <div className="clearfix" />
            <Text
              type="password"
              validations="length:6:255"
              name="userPassword"
            />
            <div className="clearfix" />
            <Radio name="isSubscriber" options={OPTIONS_SUBSCRIBER} />
            <div className="clearfix" />
            <Radio name="active" label="Status" options={OPTIONS_STATUS} />
            <div className="clearfix" />
            {!isSftp && <Text name="host" validations="required" />}
            {!isSftp && <div className="clearfix" />}
            {!isSftp && <Text name="port" validations="required positive" />}
            {!isSftp && <div className="clearfix" />}
          </Fieldset>
          <div className={clazz}>{this.renderSpecificRules()}</div>
          {isSftp && this.getSftpConfig()}
          <FormControl className={`${clazz} align-items-end`}>
            <Link to={URL_INTEGRATION_MASTER} className="btn btn-danger">
              Back
            </Link>
            <Button type="submit" text="Save" />
          </FormControl>
        </Form>
      </div>
    );
  }

  lookupForRule = (name: string): { found: boolean; rule: SpecificRuleDto } => {
    const specificRules = this.state.model.specificRules || [];
    let found = false;
    let rule: SpecificRuleDto = null;
    for (let i = 0; i < specificRules.length; i++) {
      rule = specificRules[i];
      if (rule.name === name) {
        found = true;
        break;
      }
    }
    if (!found) {
      rule = { ...new SpecificRuleDto(), name, value: '' };
    }
    return {
      found: found,
      rule: rule,
    };
  };

  onSetValue = (name: string, v: string) => {
    const ruleWrapper = this.lookupForRule(name);
    ruleWrapper.rule.value = v;
    const specificRules = this.state.model.specificRules || [];
    const model = {
      ...this.state.model,
      specificRules: !ruleWrapper.found
        ? specificRules.concat(ruleWrapper.rule)
        : specificRules,
    };
    this.setState({ model });
  };

  renderSpecificRules() {
    const report = this.lookupForRule('report');
    const facility_name = this.lookupForRule('facility_name');
    const coding_system = this.lookupForRule('coding_system');
    const room_in_pid_22_23 = this.lookupForRule('room_in_pid_22_23');
    return (
      <Fieldset>
        <Radio
          name="report"
          label="Outgoing Report Type"
          value={report.rule.value || ''}
          options={[
            { label: 'Text', value: 'text' },
            { label: 'Base64 Encoded', value: 'bytes' },
            { label: 'URL', value: '' },
          ]}
          onSetValue={this.onSetValue}
        />
        <Text
          name="facility_name"
          value={facility_name.rule.value}
          onSetValue={this.onSetValue}
        />
        <Text
          name="coding_system"
          value={coding_system.rule.value}
          onSetValue={this.onSetValue}
        />
        <Checkbox
          name="room_in_pid_22_23"
          label="Room in PID 22 and 23?"
          value={room_in_pid_22_23.rule.value}
          onSetValue={this.onSetValue}
        />
      </Fieldset>
    );
  }

  onUpdateModel = (name: string, value: string, err?: any) => {
    const { state } = this;

    const model = {
      ...state.model,
      [name]: value,
    };

    const errors = {
      ...state.errors,
      [name]: err,
    };

    this.setState({ model, errors }, () => {
      const model = this.state.model;
      if (name === 'isSftp') {
        if (!model.sftpAccess || model.sftpAccess.length === 0) {
          model.sftpAccess = [];
          model.sftpAccess.push(new TSftpAccess());
          this.forceUpdate();
        }
      }
    });
  };

  getSftpConfig() {
    return (
      <Grid
        columns={this.getSftpColumns()}
        id="sending-application-orders"
        wrapperClass="sftp-grid"
        stateless
        disablePagination
        dataSource={this.state.model.sftpAccess}
      />
    );
  }

  submitSuccess() {
    const model = {
      ...this.state.model,
      responseUrl:
        (this.state.model.host || '') + ':' + (this.state.model.port || ''),
      isSftp: !!this.state.model.isSftp,
    };
    if (model.isSftp) {
      const sftp = this.state.model.sftpAccess || [];
      if (sftp.length === 0) {
        return;
      } else {
        const errors: any = {};
        let hasErrors = false;
        const sftpColumns = this.getSftpColumns();
        for (let i = 0; i < sftp.length; i++) {
          const access = sftp[i] as any;
          for (let c = 0; c < sftpColumns.length; c++) {
            let name = sftpColumns[c].name;
            if (name === 'id') {
              continue;
            }
            const value = access[name];
            let valueSet = this.sftpValidations.required(value);
            let customValidation = true;
            if (!errors[access.id]) {
              errors[access.id] = {};
            }
            if (!valueSet) {
              errors[access.id][name] = [Validation.messages.required];
            }
            if (valueSet) {
              if (name === 'useFor') {
                customValidation = this.sftpValidations.custom(value);
                if (!customValidation) {
                  if (!errors[access.id][name]) {
                    errors[access.id][name] = [];
                  }
                  errors[access.id][name].push(this.sftpErrorMessage(value));
                }
              } else if (name === 'port') {
                customValidation = this.sftpValidations.positive(value);
                if (!customValidation) {
                  if (!errors[access.id][name]) {
                    errors[access.id][name] = [];
                  }
                  errors[access.id][name].push(Validation.messages.positive);
                }
              }
            }
            if (!valueSet || !customValidation) {
              hasErrors = true;
            }
          }
        }
        if (hasErrors) {
          this.setState({ sftpErrors: errors });
          this.forceUpdate();
          return;
        }
      }
    }
    delete model.host;
    delete model.port;
    if (model.isSftp) {
      const sftp = model.sftpAccess || [];
      for (let i = 0; i < sftp.length; i++) {
        const access = sftp[i];
        access.path = StringUtils.removeSlashes(access.path) + '/';
        if (MathUtils.parseInt('' + access.id) < 1) {
          delete access.id;
        }
      }
    } else {
      model.sftpAccess = [];
    }
    return SendingApplicationsActions.updateApplication(
      Number(this.props.match.params.id),
      model
    ).then((response: SendingApplicationFormDto | { status: string }) => {
      const model = response as SendingApplicationFormDto;
      if (model.id) {
        this.props.history.push(URL_INTEGRATION_MASTER);
        if (this.props.updateOverview) this.props.updateOverview();
      } else {
        Notification.danger(this.getErrorMessage());
      }
    });
  }

  getErrorMessage(): React.ReactNode {
    throw new Error('Implement me');
  }
}
