import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { CheckboxGroup } from 'components/form/checkbox';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import {
  storeTaskManager,
  defaultFilter,
  FilterType,
} from 'stores/_mobx/workflow/serviceMaintenance/taskManager';

const EQUIPMENT_TYPE = [
  { value: 'device', label: 'Unit' },
  { value: 'ekg', label: 'EKG' },
  { value: 'phone', label: 'Phone' },
  { value: 'vehicle', label: 'Vehicle' },
  { value: 'bone', label: 'Bone Sonometer' },
];

const SERVICE_STATUS = [
  { value: 'retired', label: 'Retired' },
  { value: 'waiting', label: 'Service Requested' },
  { value: 'open', label: 'Under Service' },
  { value: 'usable', label: 'Usable' },
];

interface PropsType {
  isFilterVisible: boolean;
  onSearch: (filterValue: FilterType) => void;
}

const Filter = ({ isFilterVisible, onSearch }: PropsType) => {
  const {
    control,
    formState: { isDirty },
    reset,
    watch,
  } = useForm<FilterType>({
    defaultValues: storeTaskManager.filter,
  });

  const handleClickReset = () => {
    reset(defaultFilter);
  };

  useEffect(() => {
    const subscription = watch((formValue) => {
      onSearch(formValue as FilterType);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className={`mb-3${isFilterVisible ? '' : ' d-none'}`}>
      <div className="row">
        <CheckboxGroup
          name="equipmentType"
          label="Equipment Type"
          className="part-inline col-lg-5"
          options={EQUIPMENT_TYPE}
          control={control}
        />
        <CheckboxGroup
          name="serviceStatus"
          label="Service Status"
          className="part-inline col-md-auto col-lg-5 me-auto"
          options={SERVICE_STATUS}
          control={control}
        />
        <ControlsLayout alignX="auto">
          <Button
            text="Reset"
            variant="warning"
            disabled={!isDirty}
            onClick={handleClickReset}
          />
        </ControlsLayout>
      </div>
    </form>
  );
};

export default Filter;
