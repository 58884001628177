import { Container } from 'flux/utils';

import PhysicianExamCountPageActions from 'actions/reports/report-creator/physician-exam-count/PhysicianExamCountPageActions';
import PhysicianExamCountPageStore from 'stores/reports/report-creator/physician-exam-count/PhysicianExamCountPageStore';
import PieChart from 'components/project/common/charts/PieChart';
import AbstractReportPage from '../AbstractReportPage';
import { prettierFilterPayload } from '../filter/prettierFilterPayload';

import { PHYSICIAN_EXAM_COUNT as PAGE_ID } from 'constant/pagesId/reports';

const COLUMNS = [
  { name: 'providername', title: 'Physician name' },
  { name: 'newcnt', title: 'New exam count' },
  { name: 'asscnt', title: 'Assigned exam count' },
  { name: 'procnt', title: 'Process exam count' },
  { name: 'comcnt', title: 'Completed exam count' },
];

export class PhysicianExamCountPage extends AbstractReportPage {
  static getStores() {
    return [PhysicianExamCountPageStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = AbstractReportPage.getPageState('physician_exam_count_grid');
      prevState.paginationStrategy = AbstractReportPage.LOGIC_PAGINATION;
    }
    return {
      ...prevState,
      appId: PAGE_ID.PAGE,
      dataSource: PhysicianExamCountPageStore.getState().physicianExamCountList,
      chartValues:
        PhysicianExamCountPageStore.getState().physicianExamCountDiagram,
    };
  }

  getShownFilters() {
    return [
      'patientFirstName',
      'patientLastName',
      'mrn',
      'pos',
      'facility',
      'diagnosis',
      'cpt',
      'facilityNPI',
      'facilityPhone',
      'dispatchedDate',
      'examCompletedDate',
      'physicianFirstName',
      'physicianLastName',
    ];
  }

  getTitle() {
    return 'Physician Exam Count';
  }

  getColumns() {
    return COLUMNS;
  }

  getGridID() {
    return 'physician_exam_count_grid';
  }

  getExportedFileName() {
    return 'physician_exam_count';
  }

  getClass() {
    return 'physician-exam-count-overview-page';
  }

  renderChart() {
    return <PieChart data={this.state.chartValues} />;
  }

  loadListAction(conditionList, joint) {
    const payload = prettierFilterPayload(conditionList);

    return PhysicianExamCountPageActions.loadPhysicianExamCountList(
      payload,
      joint
    );
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }
  getActions() {
    return PhysicianExamCountPageActions;
  }
}

const physicianExamCountPage = Container.create(PhysicianExamCountPage);
export default physicianExamCountPage;
