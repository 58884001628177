import { Container } from 'flux/utils';

import AbstractReportPage from 'page/reports/report-creator/AbstractReportPage';
import ProcedureSummaryPageActions from 'actions/reports/custom-reports/procedure-summary/ProcedureSummaryPageActions';
import ProcedureSummaryPageStore from 'stores/reports/custom-reports/procedure-summary/ProcedureSummaryPageStore';
import { PROCEDURE_SUMMARY as PAGE_ID } from 'constant/pagesId/reports';
import { prettierFilterPayload } from 'page/reports/report-creator/filter/prettierFilterPayload';

const COLUMNS = [
  { name: 'studyid', title: 'Study ID' },
  { name: 'cpt', title: 'Procedure Code' },
  { name: 'cptdescription', title: 'Description' },
  { name: 'cpt_category', title: 'CPT Category' },
  { name: 'patientname', title: 'Patient Name' },
  { name: 'dos', title: 'DOS', className: 'text-nowrap', dataType: 'date' },
];

export class ProcedureSummaryPage extends AbstractReportPage {
  static getStores() {
    return [ProcedureSummaryPageStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = AbstractReportPage.getPageState(
        'procedure_summary_report_grid'
      );
      prevState.hideChart = true;
      prevState.exporterName = 'ProcedureSummaryExporter';
    }
    return {
      ...prevState,
      appId: PAGE_ID.PAGE,
      dataSource: ProcedureSummaryPageStore.getState().resultSubList,
      dataSourceCount: ProcedureSummaryPageStore.getState().resultListCount,
    };
  }

  getTitle() {
    return 'Procedure Summary';
  }

  getColumns() {
    return COLUMNS;
  }

  onSetConditionList(conditionList, joint) {
    const { state } = this;
    const pagination = {
      ...state.pagination,
      skip: 0,
      page: 1,
    };

    this.setState(
      {
        conditionList,
        joint,
        dataSourceCount: 0,
        pagination,
      },
      this.updateData.bind(this)
    );
  }

  getDataSourceCount() {
    return this.state.dataSourceCount ? this.state.dataSourceCount : 0;
  }

  onPaginationChange = (pagination) => {
    this.setState({ pagination }, () => this.updateData());
  };

  getSpreadProps() {
    return {
      pagination: this.state.pagination,
      onPaginationChange: this.onPaginationChange,
    };
  }

  getGridID() {
    return 'procedure_summary_report_grid';
  }

  getClass() {
    return 'procedure-summary-overview-page';
  }

  getShownFilters() {
    return [
      'cpt',
      'cptCategory',
      'cptDescription',
      'studyID',
      'patientFirstName',
      'patientLastName',
      'dos',
    ];
  }

  getExportedFileName() {
    return 'ProcedureSummary';
  }

  getActions() {
    return ProcedureSummaryPageActions;
  }

  loadListAction(conditionList, joint) {
    const { state } = this;
    const { pagination } = state;
    const payload = prettierFilterPayload(conditionList);

    return ProcedureSummaryPageActions.getSumProcedCodeReport(
      payload,
      joint,
      pagination.skip,
      pagination.pageSize,
      state.dataSourceCount
    );
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }
}

const procedureSummaryPage = Container.create(ProcedureSummaryPage);
export default procedureSummaryPage;
