import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

export const defaultFilterValue: FilterType = {
  jurisdictionId: 0,
  stateId: 0,
};

export interface FilterType {
  stateId: number;
  jurisdictionId: number;
}

interface TransportCodeType {
  billJurisdiction: string;
  code: string;
  facility_price: string;
  insurance_price: string;
  no_of_patient: string;
  refid: number;
  state: string;
  stateid: string;
}

interface TransportCodeEditType {
  code: string;
  facility_price: number;
  insurance_price: number;
  no_of_patient: number;
  refid: number;
  region_id: number;
  stateid: number;
}

class Transport {
  filter: FilterType = defaultFilterValue;
  fetching: boolean = false;
  transportCodesList: TransportCodeType[] = [];
  transportCodesCount: number = 0;
  transportCode?: TransportCodeEditType = undefined;
  transportCodeForDelete: number | null = null;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'transport_grid' });

    makeObservable(this, {
      fetching: observable,
      transportCodesList: observable,
      transportCodesCount: observable,
      transportCode: observable,
      transportCodeForDelete: observable,
      filter: observable,

      setFetching: action,
      setTransportCodesList: action,
      setTransportCodesTotal: action,
      setTransportCode: action,
      setTransportCodeForDelete: action,
      setFilter: action.bound,
      clearTransportCodeForDelete: action.bound,
      clearTransportCode: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setTransportCodesList(list: TransportCodeType[]) {
    this.transportCodesList = list;
  }

  setTransportCodesTotal(count: number) {
    this.transportCodesCount = count;
  }

  setTransportCode(transportCode?: TransportCodeEditType) {
    this.transportCode = transportCode;
  }

  setTransportCodeForDelete(id: number) {
    this.transportCodeForDelete = id;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  clearTransportCode = () => {
    this.transportCode = undefined;
  };

  clearTransportCodeForDelete = () => {
    this.transportCodeForDelete = null;
  };

  async getTransportCodesList() {
    const {
      filter,
      page: { pagination },
    } = this;

    try {
      const data = [
        pagination.skip,
        pagination.pageSize,
        filter.stateId || 0,
        filter.jurisdictionId || 0,
      ];

      const response = await apiRequest<TransportCodeType[] | string>({
        url: 'Transportation.Transport.LoadTransportation',
        data,
      });
      if (Array.isArray(response)) {
        this.setTransportCodesList(response);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setTransportCodesList([]);
    }
  }

  async getTransportCodesCount() {
    const { filter } = this;

    try {
      const count = await apiRequest<string>({
        url: 'Transportation.Transport.GetTransportationCount',
        data: [filter.stateId, filter.jurisdictionId],
      });

      this.setTransportCodesTotal(Number(count) || 0);
    } catch (e) {
      this.setTransportCodesTotal(0);
    }
  }

  getTransportCodesMain() {
    this.setFetching(true);

    const promiseCount = this.getTransportCodesCount();

    const promiseCodesList = this.getTransportCodesList();

    Promise.allSettled([promiseCodesList, promiseCount]).finally(() => {
      this.setFetching(false);
    });
  }

  async getTransportCode(id: number | string) {
    this.setFetching(true);
    try {
      const response = await apiRequest<[TransportCodeEditType]>({
        url: 'Transportation.Transport.ViewTransResultbyId',
        data: [id],
      });
      const transportCode = Array.isArray(response)
        ? {
            code: response[0].code,
            facility_price: Number(response[0].facility_price),
            insurance_price: Number(response[0].insurance_price),
            no_of_patient: Number(response[0].no_of_patient),
            refid: Number(response[0].refid),
            region_id: Number(response[0].region_id),
            stateid: Number(response[0].stateid),
          }
        : undefined;

      this.setTransportCode(transportCode);
    } catch (e) {
      this.setTransportCode();
    } finally {
      this.setFetching(false);
    }
  }

  async addTransportCode(payload: TransportCodeEditType) {
    const data = [
      payload.code,
      payload.no_of_patient,
      payload.stateid,
      payload.region_id,
      payload.facility_price,
      payload.insurance_price,
    ];

    try {
      const result = await apiRequest<'N' | 'S'>({
        url: 'Transportation.Transport.AddTransportion',
        data,
      });
      if (result === 'S') {
        Notification.success('Transportation code was added successfully!');
        return null;
      } else {
        return {
          message: 'Transportation code for this jurisdiction already exists!',
        };
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async updateTransportCode(payload: TransportCodeEditType) {
    const data = [
      payload.refid,
      payload.code,
      payload.no_of_patient,
      payload.stateid,
      payload.region_id,
      payload.facility_price,
      payload.insurance_price,
    ];

    try {
      const result = await apiRequest<'E' | 'S'>({
        url: 'Transportation.Transport.EditTransportion',
        data,
      });

      if (result === 'S') {
        Notification.success('Transportation code was added successfully!');
        return null;
      } else {
        return {
          message: 'Transportation code for this jurisdiction already exists!',
        };
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async deleteTransportCode() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'S'>({
        url: 'Transportation.Transport.DeleteTransportion',
        data: [this.transportCodeForDelete],
      });
      if (result === 'S') {
        Notification.success('Transportation code was deleted!');
        this.clearTransportCodeForDelete();
        return true;
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }
}

export const storeTransport = new Transport();
