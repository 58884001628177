import { InfoType } from 'stores/_mobx/billingCodding/claimReview';

interface PropsType {
  facility?: InfoType['facility'];
}

const FacilityTab = ({ facility }: PropsType) =>
  facility ? (
    <div className="row">
      <legend className="col-sm-12">Facility Information</legend>

      <div className="col-md-6">
        <div className="mb-3">
          <dt>Facility Address:</dt>
          <dd>{facility.address}</dd>
        </div>
        <div className="mb-3">
          <dt>City:</dt>
          <dd>{facility.city}</dd>
        </div>
        <div className="mb-3">
          <dt>State:</dt>
          <dd>{facility.state}</dd>
        </div>
        <div>
          <dt>Zip Code:</dt>
          <dd>{facility.zip}</dd>
        </div>
      </div>

      <div className="col-md-6">
        <div className="mb-3">
          <dt>Billing Address:</dt>
          <dd>{facility.billingAddress}</dd>
        </div>
        <div className="mb-3">
          <dt>City:</dt>
          <dd>{facility.billingCity}</dd>
        </div>
        <div className="mb-3">
          <dt>State:</dt>
          <dd>{facility.billingState}</dd>
        </div>
        <div>
          <dt>Zip Code:</dt>
          <dd>{facility.billingZip}</dd>
        </div>
      </div>
    </div>
  ) : (
    <div style={{ height: '256px' }} />
  );

export default FacilityTab;
