import { apiRequest } from 'services/RequestService';
import { storeImport } from 'stores/_mobx/import';

export interface VaccinationResponseType {
  comment: string;
  createdAt: string;
  document: CertificateUploadResponseType;
  id: number;
  updatedAt: string;
  vaccinationName: string;
  type: number;
}

export interface VaccinationDetailType {
  id: number;
  vaccinationTypeId: number;
  vaccinationName: string;
  document: string | File;
  documentName: string;
  documentId: number;
  comment: string;
  date: string;
}

interface CertificateUploadResponseType {
  filePath: string;
  contentUrl: string;
  id: number;
}

export interface VaccinationPayloadType {
  type: number;
  comment: string;
  document: string;
  userinfo: string;
}

interface VaccinationUpdateResponseType {
  comment: string;
  document: string;
  id: number;
  userinfo: { id: number };
  vaccinationTypeId: number;
}

class UserVaccination {
  uploadCertificate(file: File, fileId?: number) {
    return storeImport.uploadFileNewApi<CertificateUploadResponseType>({
      url: fileId
        ? `vaccination_record_documents/${fileId}`
        : 'vaccination_record_documents',
      payload: { file },
    });
  }

  uploadCertificatesCollection(vaccinations: VaccinationDetailType[]) {
    return vaccinations.map((vaccine) =>
      this.uploadCertificate(vaccine.document as File, vaccine.documentId)
    );
  }

  async addVaccination(data: VaccinationPayloadType) {
    try {
      const response = await apiRequest<VaccinationUpdateResponseType>({
        url: 'vaccination_records',
        legacy: false,
        method: 'POST',
        data,
      });

      return response;
    } catch (e: any) {
      return null;
    }
  }

  async updateVaccination(data: { comment: string; type: number }, id: number) {
    try {
      const response = await apiRequest<VaccinationUpdateResponseType>({
        url: `vaccination_records/${id}`,
        legacy: false,
        method: 'PATCH',
        data,
      });

      return response;
    } catch (e: any) {
      return null;
    }
  }

  async deleteVaccination(id: number) {
    try {
      await apiRequest({
        url: `vaccination_records/${id}`,
        method: 'DELETE',
        legacy: false,
      });
      return true;
    } catch (e: any) {
      return false;
    }
  }
}

export const storeUserVaccination = new UserVaccination();
