import { forwardRef } from 'react';
import { components, MenuListProps, OptionProps } from 'react-select';
import { FixedSizeList as List } from 'react-window';

import Badge from 'components/badge';

const height = 33;

const innerElementType = forwardRef(({ style, ...rest }: any, ref) => (
  <div
    // @ts-ignore
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height)}px`,
    }}
    {...rest}
  />
));

export const MenuList = ({
  options,
  children,
  maxHeight,
  getValue,
}: MenuListProps) => {
  const [value] = getValue();

  // @ts-ignore
  const count = children.length;

  const isScrollable = count * height > maxHeight;

  const initialOffset = isScrollable ? options.indexOf(value) * height : 0;

  const maxHeightList = isScrollable ? maxHeight : count * height || height;

  return (
    <List
      width="auto"
      className="react-select__options-list"
      height={maxHeightList}
      itemCount={count}
      itemSize={height}
      innerElementType={innerElementType}
      initialScrollOffset={initialOffset}>
      {({ index, style }) => (
        <div className="select-option" style={style}>
          {/* @ts-ignore */}
          {children[index]}
        </div>
      )}
    </List>
  );
};

export const Option = ({ children, ...props }: OptionProps) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;

  const newProps = { ...props, innerProps: rest };

  // @ts-ignore
  const { optionsWithBadges } = props.selectProps;

  const badges = optionsWithBadges
    ? // @ts-ignore
      props.data.badges?.map((badge: string) => (
        <Badge variant="light" className="mx-1">
          {badge}
        </Badge>
      ))
    : null;

  return (
    <components.Option {...newProps}>
      {children}
      {badges}
    </components.Option>
  );
};

export const SingleValue = ({ children, ...props }: OptionProps) => {
  // @ts-ignore
  const { optionsWithBadges } = props.selectProps;

  const badges = optionsWithBadges
    ? // @ts-ignore
      props.data.badges?.map((badge: string) => (
        <Badge variant="light" className="mx-1">
          {badge}
        </Badge>
      ))
    : null;
  const innerProps = {
    // @ts-ignore
    'data-value': props.selectProps.value?.value,
    // @ts-ignore
    'data-testid': props.selectProps['data-testid'],
  };

  return (
    // @ts-ignore
    <components.SingleValue {...props} innerProps={innerProps}>
      {children}
      {badges}
    </components.SingleValue>
  );
};
