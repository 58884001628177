import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    serial_no: yup.string().required('Please, enter the serial number!'),
    device_name: yup.string().required('Please, enter the name!'),
    manuf_date: yup.string().required('Please, enter the date!'),
  })
);

export default validation;
