import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { zipValidator } from 'utils/formValidators';

const validation = (withRadiologistValues: boolean) =>
  yupResolver(
    yup.object().shape({
      radiologist: yup.object().shape({
        firstName: yup.string().test({
          test: (firstName: string) =>
            withRadiologistValues ? Boolean(firstName) : true,
          message: 'Please, fill in First Name!',
        }),
        lastName: yup.string().test({
          test: (firstName: string) =>
            withRadiologistValues ? Boolean(firstName) : true,
          message: 'Please, fill in Last Name!',
        }),
        npi: yup.string().test({
          test: (npi: string) =>
            withRadiologistValues ? npi.length === 10 : true,
          message: 'NPI should contain 10 numbers!',
        }),
      }),
      address: yup.string().required('Please, fill in Address!'),
      city: yup.string().required('Please, fill in City!'),
      state: yup
        .string()
        .required('Please, fill in City!')
        .test({
          test: (state: string) => state.length >= 2,
          message: 'State should contain more then 2 letters',
        }),
      zipcode: yup
        .string()
        .required('Please, fill in Zip Code!')
        .test({
          test: (zip: string) => (zip.length ? zipValidator(zip) : true),
          message: 'Should be in xxxxx-xxxx format!',
        }),
    })
  );

export default validation;
