import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Button } from 'components/button';
import { LayoutSideTitle } from 'components/layout';
import { Grid } from 'components/grid';
import Filter from './components/Filter';

import {
  storeCurrentActivityLog,
  FilterType,
} from 'stores/_mobx/users/currentActivityLog';
import { USER_LOG as PAGE_ID } from 'constant/pagesId/privileges';

const columns = [
  { name: 'facility_nm', title: 'Facility Name' },
  { name: 'name', title: 'Name' },
  { name: 'user_name', title: 'User Name' },
  { name: 'user_type', title: 'User Type' },
  { name: 'lastModified', title: 'Last Login', className: 'width-150' },
];

const CurrentActivityPage = () => {
  const {
    fetching,
    logs,
    logsTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeCurrentActivityLog;

  const [isFilterVisible, setFilterVisibility] = useState<boolean>(false);

  const toggleFilterVisibility = () => {
    setFilterVisibility((state) => !state);
  };

  const handleSearch = (filter: FilterType) => {
    storeCurrentActivityLog.setFilter(filter);
    setPaginationToStart();
  };

  useEffect(() => {
    storeCurrentActivityLog.setDefaultFilter();
  }, []);

  useEffect(() => {
    storeCurrentActivityLog.getCurrentActivityLogMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Current Activity Log">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={toggleFilterVisibility}
        />
      </LayoutSideTitle>

      <Filter
        isVisible={isFilterVisible}
        fetching={fetching}
        onSubmit={handleSearch}
      />

      <Grid
        id="current_activity_log"
        columns={columns}
        onAjax={fetching}
        dataSource={logs}
        dataSourceCount={logsTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
    </>
  );
};

export default observer(CurrentActivityPage);
