import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    destinationId: yup
      .number()
      .typeError('Please, select a Facility!')
      .min(1, 'Please, select a Facility!'),
    jurisdictionId: yup
      .number()
      .typeError('Please, select a Jurisdiction!')
      .min(1, 'Please, select a Jurisdiction!'),
    from: yup.string().typeError('Please, select a date!'),
    to: yup.string().typeError('Please, select a date!'),
    file: yup.mixed().test({
      test: (file: File | '') => Boolean(file),
      message: 'Please, select a file!',
    }),
  })
);

export default validation;
