import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import FormXReyUnit, { FormModel } from './components/Form';

import { storeXRayUnit } from 'stores/_mobx/workflow/equipmentInventory/xRayUnit';
import { URL_XRAY_UNIT } from 'constant/path/workflow';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const XRayUnitEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, xRayUnit } = storeXRayUnit;

  const handleSubmit = (data: FormModel) =>
    storeXRayUnit
      .updateXRayUnit(data as Required<FormModel>)
      .then((response) => {
        if (response) {
          history.push(URL_XRAY_UNIT);
        }
      });

  useEffect(() => {
    storeXRayUnit.getXRayUnit(params.id);
    return storeXRayUnit.clearXRayUnit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit X-Ray Unit" />
      <FormXReyUnit
        backUrl={URL_XRAY_UNIT}
        defaultValues={xRayUnit}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(XRayUnitEditPage);
