import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validation = yupResolver(
  yup.object().shape({
    state: yup.string().trim().required('Please, enter the state name!'),
    shortName: yup.string().trim().required('Please, enter the short name!'),
  })
);

export default validation;
