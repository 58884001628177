import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import {
  apiRequest,
  errorPrettier,
  ErrorType,
  JsonLdResponseType,
} from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import FilterNameAndState from 'stores/_mobx/options/filterNameAndStatus';
import AbortControllerService from 'stores/_mobx/abortControllerService';

import { GRID_ID_STATE } from 'constant/gridsId/systemSetup';

export const initialValues: StateType = {
  id: 0,
  state: '',
  shortName: '',
  isOperated: 'Y',
  flag: 'A',
};

export interface StateType {
  id: number;
  state: string;
  shortName: string;
  isOperated: 'Y' | 'N';
  flag: 'A' | 'I';
}

class State extends AbortControllerService {
  fetching: boolean = false;
  statesList: StateType[] = [];
  statesTotal: number = 0;
  state: StateType | undefined = undefined;
  stateIdForDelete: number = 0;

  filter: FilterNameAndState = new FilterNameAndState();
  page: Pagination = new Pagination({ id: GRID_ID_STATE });

  constructor() {
    super();

    makeObservable(this, {
      fetching: observable,
      statesList: observable,
      statesTotal: observable,
      state: observable,
      stateIdForDelete: observable,

      setFetching: action,
      setStates: action,
      setState: action,
      setStateForDelete: action,
      clearState: action.bound,
      clearStateForDelete: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setStates = ({ items, totalItems }: JsonLdResponseType<StateType>) => {
    this.statesList = items;
    this.statesTotal = totalItems;
  };

  setState = (state?: StateType) => {
    this.state = state;
  };

  setStateForDelete = (id: number) => {
    this.stateIdForDelete = id;
  };

  clearState() {
    this.state = undefined;
  }

  clearStateForDelete() {
    this.stateIdForDelete = 0;
  }

  getStates = async () => {
    const {
      filter,
      page: { pagination },
    } = this;

    const data = {
      stateSearch: filter.options.name,
      flag: filter.options.state,
      page: pagination.page,
      itemsPerPage: pagination.pageSize,
    };

    const abortSignal = this.manageAbortController();

    this.setFetching(true);

    try {
      const response = await apiRequest<JsonLdResponseType<StateType>>({
        url: 'states',
        method: 'GET',
        contentType: 'ld',
        legacy: false,
        signal: abortSignal,
        data,
      });

      this.setStates(response);
    } catch (e) {
      this.setStates({ items: [], totalItems: 0 });
    } finally {
      if (!abortSignal.aborted) {
        this.setFetching(false);

        this.clearAbortController();
      }
    }
  };

  getState = async (id: number) => {
    this.setFetching(true);
    try {
      const state = await apiRequest<StateType>({
        url: `states/${id}`,
        method: 'GET',
        legacy: false,
      });

      this.setState(state);
    } catch (e) {
      Notification.danger("Requested State doesn't exist!");
      this.setState(null);
    } finally {
      this.setFetching(false);
    }
  };

  modifyState = async ({ id, ...data }: StateType) => {
    const isUpdatingProcess = Boolean(id);

    try {
      await apiRequest<StateType>({
        url: isUpdatingProcess ? `states/${id}` : 'states',
        method: isUpdatingProcess ? 'PATCH' : 'POST',
        legacy: false,
        data,
      });

      Notification.success(
        `State was ${isUpdatingProcess ? 'updated' : 'created'} successfully!`
      );

      return null;
    } catch (e: any) {
      const errors = errorPrettier(e as ErrorType<StateType>);

      if (!errors) Notification.danger('An error occurred!');

      return errors;
    }
  };

  async changeStateStatus(ids: number[]) {
    if (this.fetching) return false;

    this.setFetching(true);

    const { isActive } = this.filter.options;

    const flag = isActive ? 'I' : 'A';

    const list = ids.map((id) => ({ id, flag }));

    try {
      await apiRequest<StateType[]>({
        url: 'mass_actions',
        method: 'PATCH',
        legacy: false,
        data: {
          iri: '/states',
          mapping: list,
        },
      });

      const statusMsg = isActive ? 'deactivated' : 'activated';

      Notification.success(
        `${ids.length} State${ids.length > 1 ? 's were' : ' was'} ${statusMsg}!`
      );
      return true;
    } catch (e) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async deleteState() {
    this.setFetching(true);
    try {
      const id = this.stateIdForDelete;

      await apiRequest({
        url: `states/${id}`,
        method: 'DELETE',
        legacy: false,
      });

      Notification.success('State was deleted!');

      return true;
    } catch (e: any) {
      Notification.danger(
        "This state is already in use. You can't delete this state!"
      );
      this.setFetching(false);
      return false;
    } finally {
      this.clearStateForDelete();
    }
  }
}

export const storeState = new State();
