import React from 'react';
import { Container } from 'flux/utils';

import UserTypeDropdown from 'components/project/dropdown/UserTypeDropdown';
import SupervisorDropdown from 'components/project/dropdown/SupervisorDropdown';
import Radio from 'components/form/input/Radio';
import Exporter from 'components/project/Exporter';
import { Grid, GridControlButton } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';
import SuperVisorAssignedEmployeesDropDown from './SuperVisorAssignedEmployeesDropDown';

import EmployeeTimeSheetPageActions from 'actions/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageActions';
import EmployeeTimeSheetPageStore from 'stores/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageStore';
import { storeGrid } from 'stores/_mobx/grid';
import IterableUtils from 'utils/IterableUtils';
import { getPagination } from 'utils/getPagination';

const EmployeeActiveStates = {
  INACTIVE: 'I',
  ACTIVE: 'A',
};

const SuperVisorDropDownNamePrefix = 'sd';

class AssignEmployeeModal extends React.Component {
  getKeyDateTitle() {
    const { state } = this;
    return state.userTypeModeOnUi === EmployeeActiveStates.ACTIVE
      ? 'Assigned Date'
      : 'Deactivated Date';
  }

  getExportColumns() {
    const keyDateTitle = this.getKeyDateTitle();
    return [
      { name: 'user_full_name', title: 'Employee Name' },
      { name: 'user_type', title: 'User Type' },
      { name: 'supervisor_user_full_names', title: 'Supervisor' },
      {
        name: 'supervisor_last_assigned_or_deactivated_date',
        title: keyDateTitle,
      },
    ];
  }

  getGridColumns() {
    const keyDateTitle = this.getKeyDateTitle();
    return [
      {
        name: 'user_full_name',
        title: 'Employee Name',
        render: (value, row) => {
          let user_full_name = row.user_full_name
            ? row.user_full_name.trim()
            : '';
          if (user_full_name === ',') {
            user_full_name = '';
          }
          return '' + user_full_name;
        },
      },
      {
        name: 'user_type',
        title: 'User Type',
        render: (value, row) => {
          let user_type = row.user_type ? row.user_type.trim() : '';
          return '' + user_type;
        },
      },
      {
        name: 'supervisor',
        title: 'Supervisor',
        render: (value, row) => {
          return this.renderSupervisorItem(row);
        },
      },
      {
        name: 'supervisor_last_assigned_or_deactivated_date',
        title: keyDateTitle,
      },
    ];
  }

  renderSupervisorItem(row) {
    let superVisorUserIds = row.supervisor_user_ids
      ? ('' + row.supervisor_user_ids).split(';')
      : [];
    superVisorUserIds = superVisorUserIds.map((v) => {
      return parseInt(v);
    });
    return (
      <SupervisorDropdown
        name={SuperVisorDropDownNamePrefix + row.refid}
        noLabel
        value={superVisorUserIds}
        style={{
          minWidth: 100,
        }}
        attr={{ isMulti: true, placeholder: 'Not Assigned...' }}
        onSetValue={(name, value, errorMessages) =>
          this.onSetSuperVisorValue4Employee(row.refid, value, errorMessages)
        }
      />
    );
  }

  onSetSuperVisorValue4Employee(employeeId, value) {
    const { state } = this;
    if (!state.onAjax) {
      this.setState({ onAjax: true });
    }
    value = value || [];
    for (let i = 0; i < state.userList.length; i++) {
      const item = state.userList[i];
      if (item.refid === employeeId) {
        item.supervisor_user_ids = value.join(';');
        break;
      }
    }
    EmployeeTimeSheetPageActions.assignSuperVisors4Employee(
      employeeId,
      value
    ).then(() => {
      this.setState({
        userList: state.userList,
        onAjax: false,
      });
    });
  }

  storeChangeListener = EmployeeTimeSheetPageStore.addListener(() =>
    this.onStoreChange()
  );

  componentWillUnmount() {
    if (this.storeChangeListener) {
      this.storeChangeListener.remove();
    }
    if (this.refs.grid) {
      this.refs.grid.clearSelection();
    }
    storeGrid.saveFilter('assignEmployeeModalGrid', this.state.filter);
  }

  onStoreChange() {
    const store = EmployeeTimeSheetPageStore.getState();
    this.setState({
      totalRows: store.usersFullCount,
      userList: store.usersFullList,
    });
  }

  static getStores() {
    return [EmployeeTimeSheetPageStore];
  }

  updateData() {
    const { onAjax, pagination, filter } = this.state;
    if (!onAjax) {
      this.setState({ onAjax: true });
    }

    if (filter.employeeName !== undefined) {
      filter.employeeUserId = undefined;
    }
    EmployeeTimeSheetPageActions.getCompanyUsersFullCount(filter)
      .then(() =>
        EmployeeTimeSheetPageActions.getCompanyUsersFullList(
          filter,
          pagination.skip,
          pagination.pageSize
        )
      )
      .then(() =>
        this.setState({ userTypeModeOnUi: filter.userTypeMode, onAjax: false })
      );
  }

  onPaginationChange = (pagination) => {
    this.setState({ pagination, onAjax: true }, () => this.updateData());
  };

  componentDidMount() {
    EmployeeTimeSheetPageActions.getSuperVisorsFullList();
    this.updateData();
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = {
        onAjax: false,
        userTypeModeOnUi: EmployeeActiveStates.ACTIVE,
        superVisorChangeCompleted: true,
        pagination: getPagination(
          storeGrid.getPageSize('assignEmployeeModalGrid')
        ),
        filter: storeGrid.getFilter(
          'assignEmployeeModalGrid',
          AssignEmployeeModal.getDefaultFilter()
        ),
        totalRows: 0,
        userList: [],
        flagExporterDialog: false,
        exporterName: 'AssignEmployeesExporter',
      };
    }
    return prevState;
  }

  static getDefaultFilter() {
    return {
      superVisorId: null,
      employeeUserId: undefined,
      employeeName: undefined,
      assigned: '',
      userTypeId: null,
      userTypeMode: EmployeeActiveStates.ACTIVE,
    };
  }

  onModalCallback(response) {
    this.onClose(response);
  }

  onClose(response) {
    EmployeeTimeSheetPageActions.clearEmployeeIdsLists();
    this.props.callback(response);
  }

  onSetValue4EmployeeName(value) {
    const filter = IterableUtils.extend(true, {}, this.state.filter);
    filter.employeeUserId = value;
    filter.employeeName = undefined;
    this.setState({ filter });
  }

  onInputChange4EmployeeName(value) {
    const filter = IterableUtils.extend(true, {}, this.state.filter);
    if (value && ('' + value).trim().length === 0) {
      value = undefined;
    }
    filter.employeeName = value;
    filter.employeeUserId = 0;
    this.setState({ filter });
  }

  handleClose = () => {
    this.onModalCallback(false);
  };

  render() {
    const { state } = this;
    const { filter } = state;
    const employeeNameValue = filter.employeeUserId ? filter.employeeUserId : 0;
    const activeStates = {
      name: 'userTypeMode',
      className: 'col-sm-4 part-inline',
      onSetValue: (name, value, errors) =>
        this.updateFilter(name, value, errors),
      value: filter.userTypeMode,
      options: [
        { label: 'Active', value: EmployeeActiveStates.ACTIVE },
        { label: 'Inactive', value: EmployeeActiveStates.INACTIVE },
      ],
    };
    return (
      <Dialog size="extraLarge" handleClose={this.handleClose}>
        <DialogHeader title="Assign Employee(s)" onClose={this.handleClose} />
        <DialogBody>
          <div className="row">
            <SupervisorDropdown
              name="superVisorId"
              label="Supervisor"
              className="col-sm-4"
              value={filter.superVisorId}
              onSetValue={(name, value, errorMessages) =>
                this.updateFilter(name, value, errorMessages)
              }
            />
            <Radio {...activeStates} label="&nbsp;" key="activeState" />
            <Radio
              name="assigned"
              allowDeselect
              label="&nbsp;"
              className="col-sm-4 part-inline"
              value={filter.assigned}
              onSetValue={(name, value, errors) =>
                this.updateFilter(name, value, errors)
              }
              options={[
                { value: 'Y', label: 'Assigned' },
                { value: 'N', label: 'Not Assigned' },
              ]}
              key="assignedState"
            />
            <div className="clearfix" />
            <SuperVisorAssignedEmployeesDropDown
              name="employeeName"
              attr={{
                placeholder: 'Employee Name',
                noResultsText: 'Not Assigned, Try Searching.',
                onBlurResetsInput: false,
                onInputChange: (value) =>
                  this.onInputChange4EmployeeName(value),
              }}
              label="Employee Name"
              className="col-sm-5"
              superVisorId={filter.superVisorId}
              value={employeeNameValue}
              onSetValue={(name, value) => this.onSetValue4EmployeeName(value)}
            />
            <UserTypeDropdown
              name="userTypeId"
              label="User Type"
              className="col-sm-4"
              flagCompanyUsersOnly
              value={filter.userTypeId}
              onSetValue={(name, value, errorMessages) =>
                this.updateFilter(name, value, errorMessages)
              }
            />
            <div
              className="formfield-holder formfield-text col-sm-3"
              style={{ marginTop: 15 }}>
              <input
                key={'controlPanelItem_6'}
                type="button"
                className="btn btn-primary"
                style={{ float: 'right' }}
                value="Search"
                onClick={() => {
                  const { pagination } = state;
                  pagination.page = 1;
                  pagination.skip = 0;
                  this.setState({ pagination: pagination, onAjax: true }, () =>
                    this.updateData()
                  );
                }}
              />
            </div>
            <div className="clearfix" />
            {!state.superVisorChangeCompleted ? null : (
              <Grid
                id="employee_grid"
                onAjax={state.onAjax}
                gridControlPanel={
                  this.getGridControlPanel ? this.getGridControlPanel() : null
                }
                ref="grid"
                dataSourceCount={state.totalRows}
                dataSource={state.userList}
                pagination={state.pagination}
                onPaginationChange={this.onPaginationChange}
                columns={this.getGridColumns()}
              />
            )}
          </div>
          {state.flagExporterDialog && (
            <Exporter
              exporter={state.exporterName}
              filters={() => this.getDataArguments()}
              callback={() => this.setState({ flagExporterDialog: false })}
              exportColumns={this.getExportColumns()}
              this={this}
            />
          )}
        </DialogBody>
      </Dialog>
    );
  }

  handleClickExport = () => {
    this.setState({ flagExporterDialog: true });
  };

  getGridControlPanel() {
    return [
      <GridControlButton title="Export" onClick={this.handleClickExport} />,
    ];
  }

  getDataArguments() {
    return [
      {
        filter: this.state.filter,
      },
    ];
  }

  updateFilter(name, value) {
    const filter = { ...this.state.filter, [name]: value };
    this.setState({ filter });
  }
}

export default Container.create(AssignEmployeeModal, { withProps: true });
