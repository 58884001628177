import JurisdictionDetailsService, {
  TAddJurisdiction,
  TUpdateJurisdiction,
} from 'services/system-setup/master-setup/operations/jurisdiction/JurisdictionDetailsService';
import A from 'constant/system-setup/master-setup/operations/jurisdiction/JurisdictionDetailsActions';
import appDispatcher from 'dispatcher/AppDispatcher';
import { TPagination } from 'components/grid/GridTypes';

const updateModelAndErrorsProperty = (
  type: string,
  propExpr: string,
  propVal: string,
  error: any,
  id?: number
) => {
  appDispatcher.dispatch({
    type,
    data: {
      property: {
        expression: propExpr,
        value: propVal,
        error,
      },
    },
    id,
  } as any);
};

export default class JurisdictionDetailsActions {
  static createJurisdiction(item: TAddJurisdiction) {
    return JurisdictionDetailsService.createJurisdiction(item);
  }

  static updateJurisdiction(filter: TUpdateJurisdiction) {
    return JurisdictionDetailsService.updateJurisdiction(filter);
  }

  static loadJurisdiction(id: number) {
    return JurisdictionDetailsService.loadJurisdiction(id).then((response) => {
      appDispatcher.dispatch({
        type: A.LOAD_DETAILS,
        data: response,
      });
    });
  }

  static loadStateList() {
    return JurisdictionDetailsService.loadJurisdictionStates().then(
      (response) => {
        appDispatcher.dispatch({
          type: A.LOAD_STATE_LIST,
          data: response,
        });
      }
    );
  }

  static loadModalityList(jurId: number) {
    return JurisdictionDetailsService.loadJurisdictionModalityList(jurId).then(
      (response) => {
        appDispatcher.dispatch({
          type: A.LOAD_MODALITY_LIST,
          data: response,
        });
      }
    );
  }

  static loadJurisdictionZipCodes(
    jurId: number,
    filter: { zip: string },
    pagination: { skip: number; limit: number }
  ) {
    return JurisdictionDetailsService.loadJurisdictionZipCodes(
      jurId,
      filter,
      pagination
    ).then((response) => {
      appDispatcher.dispatch({
        type: A.LOAD_ZIP_CODES,
        data: response,
      });
    });
  }

  static updateJurisdictionZipCodes(
    jurId: number,
    codes: Array<{ zip: string }>
  ) {
    return JurisdictionDetailsService.updateJurisdictionZipCodes(jurId, codes);
  }

  static loadJurisdictionZipCodeRanges(
    jurId: number,
    filter: { zipStart: string; zipEnd: string },
    pagination: { skip: number; limit: number }
  ) {
    return JurisdictionDetailsService.loadJurisdictionZipCodeRanges(
      jurId,
      filter,
      pagination
    ).then((response) => {
      appDispatcher.dispatch({
        type: A.LOAD_ZIP_CODE_RANGES,
        data: response,
      });
    });
  }

  static updateJurisdictionZipCodeRanges(
    jurId: number,
    ranges: Array<{ zipStart: string; zipEnd: string }>
  ) {
    return JurisdictionDetailsService.updateJurisdictionZipCodeRanges(
      jurId,
      ranges
    );
  }

  static updateJurisdictionModelAndErrorsProperty(
    propExpr: string,
    propVal: string,
    error: any
  ) {
    updateModelAndErrorsProperty(
      A.UPDATE_MODEL_AND_ERRORS_PROPERTY,
      propExpr,
      propVal,
      error
    );
  }

  static updateZipCodeFilter(
    propExpr: string,
    propVal: string,
    error: any,
    id: number
  ) {
    updateModelAndErrorsProperty(
      A.UPDATE_ZIP_CODE_FILTER,
      propExpr,
      propVal,
      error,
      id
    );
  }

  static updateZipCodeRangeFilter(
    propExpr: string,
    propVal: string,
    error: any,
    id: number
  ) {
    updateModelAndErrorsProperty(
      A.UPDATE_ZIP_CODE_RANGE_FILTER,
      propExpr,
      propVal,
      error,
      id
    );
  }

  static updateJurisdictionModelAndErrors(model: any, errors: any) {
    appDispatcher.dispatch({
      type: A.UPDATE_MODEL_AND_ERRORS,
      data: {
        model,
        errors,
      },
    });
  }

  static resetJurisdiction() {
    appDispatcher.dispatch({
      type: A.RESET_MODEL_AND_ERRORS,
      data: {},
    });
  }

  static changeZipCodeListPagination(pagination: TPagination, id: number) {
    appDispatcher.dispatch({
      type: A.CHANGE_ZIP_CODE_LIST_PAGINATION,
      data: { pagination },
      id,
    } as any);
  }

  static changeZipCodeRangeListPagination(pagination: TPagination, id: number) {
    appDispatcher.dispatch({
      type: A.CHANGE_ZIP_CODE_RANGE_LIST_PAGINATION,
      data: { pagination },
      id,
    } as any);
  }

  static toggleZipCodesLoading(toggle: boolean) {
    appDispatcher.dispatch({
      type: A.TOGGLE_ZIP_CODES_LOADING,
      data: { toggle },
    });
  }

  static toggleZipCodeRangesLoading(toggle: boolean) {
    appDispatcher.dispatch({
      type: A.TOGGLE_ZIP_CODE_RANGES_LOADING,
      data: { toggle },
    });
  }

  static toggleZipCodeDictionaryModal(toggle: boolean) {
    appDispatcher.dispatch({
      type: A.TOGGLE_ZIP_CODE_DICTIONARY_MODAL,
      data: { toggle },
    });
  }

  static toggleZipCodeRangeDetailsModal(toggle: boolean) {
    appDispatcher.dispatch({
      type: A.TOGGLE_ZIP_CODE_RANGE_DETAILS_MODAL,
      data: { toggle },
    });
  }

  static toggleZipCodeDeleteConfirm(toggle: boolean, id?: number) {
    appDispatcher.dispatch({
      type: A.TOGGLE_ZIP_CODE_DELETE_CONFIRM,
      data: {
        toggle,
        id,
      },
    });
  }

  static toggleZipCodeRangeDeleteConfirm(
    toggle: boolean,
    zipStart?: string,
    zipEnd?: string
  ) {
    appDispatcher.dispatch({
      type: A.TOGGLE_ZIP_CODE_RANGE_DELETE_CONFIRM,
      data: {
        toggle,
        zipStart,
        zipEnd,
      },
    });
  }

  static addJurisdictionZipCode(jurId: number, zip: string) {
    return JurisdictionDetailsService.addJurisdictionZipCode(jurId, zip);
  }

  static jurisdictionZipCodeExists(jurId: number, zip: string) {
    return JurisdictionDetailsService.jurisdictionZipCodeExists(jurId, zip);
  }

  static deleteJurisdictionZipCode(jurId: number, zip: string) {
    return JurisdictionDetailsService.deleteJurisdictionZipCode(jurId, zip);
  }

  static addJurisdictionZipCodeRange(
    jurId: number,
    zipStart: string,
    zipEnd: string
  ) {
    return JurisdictionDetailsService.addJurisdictionZipCodeRange(
      jurId,
      zipStart,
      zipEnd
    );
  }

  static deleteJurisdictionZipCodeRange(
    jurId: number,
    zipStart: string,
    zipEnd: string
  ) {
    return JurisdictionDetailsService.deleteJurisdictionZipCodeRange(
      jurId,
      zipStart,
      zipEnd
    );
  }

  static jurisdictionZipCodeRangeExists(
    jurId: number,
    zipStart: string,
    zipEnd: string
  ) {
    return JurisdictionDetailsService.jurisdictionZipCodeRangeExists(
      jurId,
      zipStart,
      zipEnd
    );
  }

  static addJurisdictionZipCodeLocaly(zip: string) {
    appDispatcher.dispatch({
      type: A.ADD_ZIP_CODE,
      data: { zip },
    });
  }

  static deleteJurisdictionZipCodeLocaly(zip: string) {
    appDispatcher.dispatch({
      type: A.DELETE_ZIP_CODE,
      data: { zip },
    });
  }

  static addJurisdictionZipCodeRangeLocaly(zipStart: string, zipEnd: string) {
    appDispatcher.dispatch({
      type: A.ADD_ZIP_CODE_RANGE,
      data: {
        zipStart,
        zipEnd,
      },
    });
  }

  static deleteJurisdictionZipCodeRangeLocaly(
    zipStart: string,
    zipEnd: string
  ) {
    appDispatcher.dispatch({
      type: A.DELETE_ZIP_CODE_RANGE,
      data: {
        zipStart,
        zipEnd,
      },
    });
  }

  static clearZipCodeList() {
    appDispatcher.dispatch({
      type: 'jurisdiction/clear-zip-codes',
    });
  }

  static clearZipCodeRangeList() {
    appDispatcher.dispatch({
      type: 'jurisdiction/clear-zip-codes-range',
    });
  }

  static selectZipCodes(selected: string) {
    appDispatcher.dispatch({
      type: A.SELECT_ZIP_CODES,
      data: { selected },
    });
  }

  static selectZipCodeRanges(selected: string) {
    appDispatcher.dispatch({
      type: A.SELECT_ZIP_CODE_RANGES,
      data: { selected },
    });
  }
}
