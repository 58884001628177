import Notification from 'components/modal/Notification';
import { makeObservable, observable, action } from 'mobx';
import { apiRequest } from 'services/RequestService';

interface PayloadAssignPhysicianType {
  orderId: number;
  firstName: string;
  lastName: string;
  id: number;
}

interface PayloadAssignPhysicianGroupType {
  orderId: number;
  groupName: string;
  id: number;
}

class ExamReview {
  assignInProgress: boolean = false;

  constructor() {
    makeObservable(this, {
      assignInProgress: observable,

      setAssignInProgress: action,
    });
  }

  setAssignInProgress(fetching: boolean) {
    this.assignInProgress = fetching;
  }

  async assignPhysician(payload: PayloadAssignPhysicianType) {
    const data = [
      payload.id,
      payload.orderId,
      payload.firstName,
      payload.lastName,
    ];
    this.setAssignInProgress(true);
    try {
      const response = await apiRequest<'E' | 'S'>({
        url: 'order.Order.Reassign_physician',
        data,
      });
      if (response === 'S') {
        Notification.success('Physician reassigned successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Error occurred!');
      return false;
    } finally {
      this.setAssignInProgress(false);
    }
  }

  async assignPhysicianGroup(payload: PayloadAssignPhysicianGroupType) {
    const data = [payload.id, payload.orderId, payload.groupName];
    this.setAssignInProgress(true);
    try {
      const response = await apiRequest<'E' | 'S'>({
        url: 'order.Order.Reassign_physiciangrp',
        data,
      });

      if (response === 'S') {
        Notification.success('Physician Group reassigned successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Error occurred!');
      return false;
    } finally {
      this.setAssignInProgress(false);
    }
  }
}

export const storeExamReview = new ExamReview();
