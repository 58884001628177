import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Spinner } from 'components/spinner';

import UserProfileActions from 'actions/UserProfileActions';
import { storeAuthorization } from 'stores/_mobx/auth';

const LogoutPage = ({ history, location: { search } }: RouteComponentProps) => {
  const handleLogout = () => {
    UserProfileActions.logout();
    history.push(`/login${search || ''}`);
  };

  useEffect(() => {
    storeAuthorization.logout().then((isSucceed) => {
      if (isSucceed) handleLogout();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="logout-wrapper">
      <Spinner />
      <div className="logout-text">Logging out</div>
    </div>
  );
};

export default LogoutPage;
