import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storePriority,
  PriorityEntryType,
} from 'stores/_mobx/systemSetup/masterSetting/priority';
import { URL_PRIORITY } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const BoneSonometerAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: PriorityEntryType) =>
    storePriority.addPriority(data).then((error) => {
      if (error) return error;
      history.push(URL_PRIORITY);
    });

  return (
    <>
      <Title title="Add Priority" />
      <Form onSubmit={handleSubmit} backUrl={URL_PRIORITY} />
    </>
  );
};

export default BoneSonometerAddPage;
