import { useMemo } from 'react';

import InvoiceUtils from 'page/billing-coding/invoice/InvoiceUtils';

import { InvoiceType } from 'stores/_mobx/billingCodding/invoice';

const infoClass = 'col-sm-12 col-md-6 col-lg-3 mb-4';

interface PropsType {
  order: InvoiceType;
}

const ChargeInfo = ({ order }: PropsType) => {
  const payment = useMemo(() => InvoiceUtils.getPaidRender(order), [order]);

  return (
    <div className="row my-4">
      <div className={infoClass}>
        <strong>Facility Name</strong>
        <div>{order.facilitynm}</div>
      </div>

      <div className={infoClass}>
        <strong>Invoice</strong>
        <div>{order.invoice_alphanum}</div>
      </div>

      <div className={infoClass}>
        <strong>Payment</strong>
        <div>{payment}</div>
      </div>

      <div className={infoClass}>
        <strong>Invoice Total</strong>
        <div>{`$${order.total_price.toFixed(2)}`}</div>
      </div>

      <div className={infoClass}>
        <strong>Facility Owner</strong>
        <div>{order.ownerName}</div>
      </div>

      <div className={infoClass}>
        <strong>POS</strong>
        <div>{order.pos}</div>
      </div>

      <div className={infoClass}>
        <strong>Invoice Date</strong>
        <div>{order.invoice_dt}</div>
      </div>

      <div className={infoClass}>
        <strong>Balance</strong>
        <div>{`$${order.balance}`}</div>
      </div>
    </div>
  );
};

export default ChargeInfo;
