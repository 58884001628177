import { forwardRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from 'components/project/dropdown/AbstractDropdown';
import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';

import {
  storePriority,
  OptionType,
} from 'stores/_mobx/systemSetup/masterSetting/priority';

interface PropsType extends PAbstractDropdown {
  options: OptionType[];
  ref: any;
}

class PriorityDropdown extends AbstractDropdown<PropsType, SAbstractDropdown> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<PriorityDropdown, any>(
  (props: PAbstractDropdown, ref) => {
    const { options, fetchingOptions } = storePriority;

    useEffect(() => {
      storePriority.getOptions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <PriorityDropdown
        {...props}
        ref={ref}
        options={options}
        fetching={fetchingOptions}
      />
    );
  }
);

export default observer(DropDownMiddleware);

export const DropDownPriority = observer(
  (props: Omit<SelectPropsType, 'options'>) => {
    const { fetchingOptions, options } = storePriority;

    useEffect(() => {
      storePriority.getOptions();
    }, []);

    return <Select {...props} options={options} isLoading={fetchingOptions} />;
  }
);
