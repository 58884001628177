import React from 'react';

import { Grid } from 'components/grid';
import { IconButton } from 'components/button';
import Checkbox from 'components/form/input/Checkbox';
import PrivilegesWrapper from './PrivilegesWrapper';

export class TPrivilegeItem {
  refid: string;
  app_name: string;
  //app_id: string;
  //type: string;
  //img_url: string;
  mass_id: string;
  //action: string;
  //action_type: string;
  //flag: string;
  isselect: boolean;
}

export interface PPrivilegesTable {
  dataSource: Array<TPrivilegeItem>;
  onSelectAll?: (name: any, value: any) => void;
  title: string;
  style: any;
  personSettings: Array<string>;
  selectedRefid: any;
  tableTitle: string;
  updateSelection: (name: string, value: boolean) => void;
  fullDataSource: any;
  openNextPermission: (row: any, event: any) => void;
  collapsePermission: (row: any, event: any) => void;
  tableInStack: any;
}

export class SPrivilegesTable {}

export default class PrivilegesTable extends React.Component<
  PPrivilegesTable,
  SPrivilegesTable
> {
  COLUMNS = [
    { name: 'app_name', title: '', render: this.nameRender.bind(this) },
    {
      name: 'permissions',
      title: 'Permissions',
      render: this.renderPermissions.bind(this),
      className: 'width-100',
      noOverflow: true,
    },
  ];

  id: any = null;

  render() {
    const { props } = this;
    return (
      <div className="privilege-table col" style={props.style}>
        <div className="d-flex">
          <h5 className="flex-grow-1">{props.title}</h5>
          {this.props.onSelectAll ? (
            <div className="width-100">
              <Checkbox
                name="select_all"
                attr={{ id: 'select_all_' + this.getId() }}
                value={this.isAllSelected() ? 1 : 0}
                onSetValue={this.onSelectAll.bind(this)}
                className="no-margin"
              />
            </div>
          ) : null}
        </div>
        <Grid
          disablePagination
          id={'grid' + this.getId()}
          wrapperClass=" "
          hideSwitcher
          columns={this.getColumns()}
          dataSource={props.dataSource}
          stateless
        />
      </div>
    );
  }

  nameRender(value: any, row: any) {
    if (row.refid === this.props.selectedRefid) {
      return <b>{value}</b>;
    }
    return value;
  }

  getColumns() {
    this.COLUMNS[0].title = this.props.tableTitle;
    return this.COLUMNS;
  }

  getId() {
    if (this.id === null) {
      this.id = window.performance.now().toString(36);
    }
    return this.id;
  }

  renderPermissions(value: any, row: TPrivilegeItem) {
    let checked = row.isselect;
    let className = 'permission';
    const { personSettings } = this.props;
    if (personSettings) {
      if (personSettings[parseInt(row.refid)] === 'Y') {
        checked = true;
        if (row.isselect) {
          className += ' user-checked type-permission';
        } else {
          className += ' user-checked';
        }
      } else if (personSettings[parseInt(row.refid)] === 'N') {
        checked = false;
        if (row.isselect) {
          className += ' user-unchecked';
        }
      } else {
        checked = row.isselect;
        if (row.isselect) {
          className += ' type-permission';
        }
      }
    }
    return (
      <div className={className}>
        <Checkbox
          name={row.refid}
          onSetValue={(name: string, value: boolean) => {
            this.props.updateSelection(name, value);
          }}
          noLabel
          value={checked ? 1 : 0}
        />
        {this.buildToggler(row)}
      </div>
    );
  }

  isAllSelected(): boolean {
    const { props } = this;
    let res = true;
    for (let i = 0; i < props.dataSource.length; i++) {
      let row = props.dataSource[i];
      let flagSelected = row.isselect;
      if (props.personSettings[parseInt(row.refid)] === 'Y') {
        flagSelected = true;
      } else if (props.personSettings[parseInt(row.refid)] === 'N') {
        flagSelected = false;
      }
      if (!flagSelected) {
        res = false;
        break;
      }
    }
    return res;
  }

  buildToggler(row: any) {
    if (PrivilegesWrapper.hasChildrens(row.refid, this.props.fullDataSource)) {
      const isSelected = row.refid === this.props.selectedRefid;
      const handleToggle = isSelected
        ? this.collapsePermission.bind(this, row)
        : this.openNextPermission.bind(this, row);
      return (
        <IconButton onClick={handleToggle}>
          <i
            className={`bi bi-caret-${isSelected ? 'left-fill' : 'right-fill'}`}
          />
        </IconButton>
      );
    }
    return (
      <IconButton className="invisible">
        <i className="bi bi-caret-left-fill" />
      </IconButton>
    );
  }

  openNextPermission(row: any, event: any) {
    event.preventDefault();
    this.props.openNextPermission(this.props.tableInStack, row);
  }

  collapsePermission(row: any, event: any) {
    event.preventDefault();
    this.props.collapsePermission(this.props.tableInStack, row);
  }

  onSelectAll(name: any, value: any) {
    this.props.onSelectAll(this.props.dataSource, value);
  }
}
