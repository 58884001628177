import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeHospice,
  FormModel,
} from 'stores/_mobx/systemSetup/masterSetting/hospice';
import { URL_HOSPICE } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const HospiceAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: FormModel) =>
    storeHospice.addHospice(data).then((error) => {
      if (error) return error;
      history.push(URL_HOSPICE);
    });

  return (
    <>
      <Title title="Add Hospice" />
      <Form backUrl={URL_HOSPICE} onSubmit={handleSubmit} />
    </>
  );
};

export default HospiceAddPage;
