import { makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionsStateResponse {
  data: string;
  label: string;
}

export interface OptionType {
  value: number;
  label: string;
}

export class DropdownTaxonomy {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,
    });
  }

  async getOptions() {
    if (this.fetching) return Promise.resolve();
    try {
      runInAction(() => {
        this.fetching = true;
      });
      const list = await apiRequest<OptionsStateResponse[]>({
        url: 'generalmaster.Mv_Specialty.LoadMv_TaxonomyCode',
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));
      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }

  findOptionByLabel(search: string) {
    return this.options.find(
      ({ label }) => label.toLowerCase() === search.toLowerCase()
    );
  }
}

export const storeDropdownTaxonomy = new DropdownTaxonomy();
