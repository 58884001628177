import React from 'react';
import clsx from 'clsx';

/**
  @prop {string} 'data-testid' - data attribute for testing libraries
  If data-testid not presented, then prefix for data-testid attribute will generated with 'name' property
  '{dataTestId}-label' - data attribute for the main label
  '{dataTestId}-field-{optionLabel}' - data attribute for the option input field
  '{dataTestId}-label-{optionLabel}' - data attribute for the option input label
  '{dataTestId}-error' - data attribute for the error block
*/

interface OptionType {
  label: string;
  value: string | number;
}

export interface PureRadioProps {
  name?: string;
  label?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  options: OptionType[];
  value: string | number;
  errorMessage?: string;
  'data-testid'?: string;
  onChange?: (value: any) => void;
  onBlur?: (e: React.SyntheticEvent) => void;
  onFocus?: (e: React.SyntheticEvent) => void;
}

const Radio = React.forwardRef<HTMLInputElement, PureRadioProps>(
  (
    {
      name,
      required = false,
      disabled = false,
      label = '',
      className,
      options,
      errorMessage,
      value: currentValue,
      'data-testid': dataTestId,
      onChange,
      ...rest
    }: PureRadioProps,
    ref
  ) => {
    const cn = clsx(
      'formfield-holder',
      'formfield-radio',
      { required },
      className
    );

    const testAttr = dataTestId || name;

    const handleChange = (value: any) => {
      if (onChange) onChange(value);
    };

    return (
      <div className={cn}>
        {label && (
          <div className="form-label">
            <label data-testid={`${testAttr}-label`}>{label}</label>
          </div>
        )}
        <div
          className="formfield-input"
          data-testid={`radio-group-${testAttr}`}>
          {options.map(({ value, label: optionLabel }) => (
            <div className="formfield-holder formfield-radio" key={value}>
              <label data-testid={`${testAttr}-label-${value}`}>
                <input
                  {...rest}
                  ref={ref}
                  type="radio"
                  disabled={disabled}
                  value={value}
                  checked={currentValue === value}
                  data-testid={`${testAttr}-field-${value}`}
                  onChange={() => handleChange(value)}
                />
                <span>{optionLabel}</span>
              </label>
            </div>
          ))}
        </div>
        {errorMessage && (
          <div className="error" data-testid={`${testAttr}-error`}>
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

export default Radio;
