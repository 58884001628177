import {
  ICellRendererParams,
  ValueFormatterParams,
} from 'components/gridModern';
import { IconButton } from 'components/button';
import Tooltip from 'components/tooltip';

import { ExamInfoType } from 'stores/_mobx/patientRecord/examInfo';
import { storeExamQA, ExamQaType } from 'stores/_mobx/patientRecord/examQA';
import { ClaimCodeType } from 'stores/_mobx/patientRecord/types';
import { dateToLocalTimezone, durationFormatter } from 'utils/DateUtils';

const AccessionNumber = ({
  accessionNumber,
  isCptExist,
}: {
  accessionNumber: string;
  isCptExist: boolean;
}) => (
  <>
    {accessionNumber ? (
      <Tooltip className="me-2" body={`Accession Number: ${accessionNumber}`}>
        <i className="bi bi-upc-scan fs-5 text-primary" />
      </Tooltip>
    ) : isCptExist ? (
      <i className="bi bi-upc-scan fs-5 text-secondary opacity-75 me-2" />
    ) : null}
  </>
);

const formatDiagnoses = (value: ExamInfoType['diagnoses']) =>
  value.reduce(
    (prev, diagnose) => {
      prev.codes.push(diagnose.icdCode.icdCode);
      prev.descriptions.push(
        <div key={diagnose.icdCode.id}>
          <b>{diagnose.icdCode.icdCode}</b>
          <span>{` - ${diagnose.icdCode.shortDescription};`}</span>
        </div>
      );
      return prev;
    },
    { codes: [] as string[], descriptions: [] as React.ReactNode[] }
  );

export const diagnosisCellRender = ({
  data: { diagnoses },
}: ICellRendererParams<ExamInfoType, ExamInfoType['diagnoses']>) => {
  if (!diagnoses.length) return null;

  const diagnosesWithIcdCodes = diagnoses.filter(
    (diagnose) => diagnose.icdCode
  );

  if (!diagnosesWithIcdCodes.length) return null;

  const { codes, descriptions } = formatDiagnoses(diagnosesWithIcdCodes);

  return (
    <>
      <Tooltip className="me-2" body={descriptions}>
        <i className="bi bi-clipboard-pulse fs-5 text-primary" />
      </Tooltip>
      <span>{codes.join(', ')}</span>
    </>
  );
};

export const claimCodeCellRender = ({
  data: { claimCodesActive },
}: ICellRendererParams<ExamQaType | ExamInfoType, ClaimCodeType>) => {
  const cptCodes = claimCodesActive
    .map(({ procedureCode }) => procedureCode)
    .join(', ');

  const cptDescription = claimCodesActive.map((procedure) => (
    <div key={procedure.id}>
      <b>{procedure.procedureCode}</b>
      {` - ${procedure.procedureDescription}`}
    </div>
  ));

  const accessionNumber = claimCodesActive
    .map((procedure) => procedure.accessionNumber)
    .filter(Boolean)
    .join(', ');

  return (
    <>
      <AccessionNumber
        accessionNumber={accessionNumber}
        isCptExist={Boolean(cptCodes)}
      />
      <Tooltip className="d-inline" body={cptDescription}>
        {cptCodes}
      </Tooltip>
    </>
  );
};

export const fullDateFormatter = ({
  value,
}: ValueFormatterParams<any, string>) =>
  dateToLocalTimezone({
    date: value,
  });

export const linkToPdfCellRender = ({
  value,
}: ICellRendererParams<ExamQaType, ExamQaType['id']>) => (
  <IconButton
    className="text-primary"
    onClick={() => storeExamQA.generatePDF(value)}>
    <i className="bi bi-filetype-pdf fs-4" />
  </IconButton>
);

export const timeDurationCellRender = ({
  value,
}: ValueFormatterParams<any, string>) => durationFormatter(value);
