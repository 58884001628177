import { apiRequest } from 'services/RequestService';

class EmployeeTimeSheetPageService {
  getPayrollReportCount(filter) {
    return apiRequest({
      url: 'filemanager.TimeSheet.getPayrollReportCount',
      data: [filter],
    });
  }

  getPayrollReportList(filter, skip, pageSize) {
    const data = [filter, skip, pageSize];

    return apiRequest({
      url: 'filemanager.TimeSheet.getPayrollReportList',
      data,
    });
  }

  getHrAdminReportCount(filter) {
    return apiRequest({
      url: 'filemanager.TimeSheet.getHrAdminReportCount',
      data: [filter],
    });
  }

  getHrAdminReportList(filter, skip, pageSize) {
    return apiRequest({
      url: 'filemanager.TimeSheet.getHrAdminReportList',
      data: [filter, skip, pageSize],
    }).then((r) => {
      //add status field for export
      r.forEach((i) => (i.status = ''));
      return r;
    });
  }

  createPdf(list, description) {
    return apiRequest({
      url: 'pdfcreater.OrderPdfGenerator.GeneratePDFforEmpExpense',
      data: [list, description],
    });
  }

  getSuperVisorIdsList() {
    return apiRequest({ url: 'filemanager.TimeSheet.getSuperVisorIdsList' });
  }

  getEmployeeIdsList(superVisorId) {
    return apiRequest({
      url: 'filemanager.TimeSheet.getEmployeeIdsList',
      data: [superVisorId],
    });
  }

  loadSuperVisorAssignedEmployeesList(superVisorId) {
    return apiRequest({
      url: 'filemanager.TimeSheet.loadSuperVisorAssignedEmployeesList',
      data: [superVisorId],
    });
  }

  assignSuperVisors(superVisorIdList) {
    return apiRequest({
      url: 'filemanager.TimeSheet.assignSuperVisors',
      data: [superVisorIdList],
    });
  }

  assignEmployees(superVisorId, employeeIdList) {
    return apiRequest({
      url: 'filemanager.TimeSheet.assignEmployees',
      data: [superVisorId, employeeIdList],
    });
  }

  assignSupervisorsEmployees(employeeIdsLists) {
    return apiRequest({
      url: 'filemanager.TimeSheet.assignSupervisorsEmployees',
      data: [employeeIdsLists],
    });
  }

  assignSuperVisors4Employee(employeeId, superVisorIdList) {
    return apiRequest({
      url: 'filemanager.TimeSheet.assignSuperVisors4Employee',
      data: [employeeId, superVisorIdList],
    });
  }

  approveTimeSheet(refid, filter) {
    return apiRequest({
      url: 'filemanager.TimeSheet.approveTimeSheet',
      data: [refid, filter],
    });
  }

  getSuperVisorsFullCount(filter) {
    return apiRequest({
      url: 'filemanager.TimeSheet.getSuperVisorsFullCount',
      data: [filter],
    });
  }

  getSuperVisorsFullList(filter, skip, pageSize) {
    return apiRequest({
      url: 'filemanager.TimeSheet.getSuperVisorsFullList',
      data: [filter, skip, pageSize],
    });
  }

  getEmployeesFullCount(filter) {
    return apiRequest({
      url: 'filemanager.TimeSheet.getEmployeesFullCount',
      data: [filter],
    });
  }

  getEmployeesFullList(filter, skip, pageSize) {
    return apiRequest({
      url: 'filemanager.TimeSheet.getEmployeesFullList',
      data: [filter, skip, pageSize],
    });
  }

  getUsersFullCount(filter) {
    return apiRequest({
      url: 'filemanager.TimeSheet.getUsersFullCount',
      data: [filter],
    });
  }

  getUsersFullList(filter, skip, pageSize) {
    return apiRequest({
      url: 'filemanager.TimeSheet.getUsersFullList',
      data: [filter, skip, pageSize],
    });
  }

  getCompanyUsersFullCount(filter) {
    return apiRequest({
      url: 'filemanager.TimeSheet.getCompanyUsersFullCount',
      data: [filter],
    });
  }

  getCompanyUsersFullList(filter, skip, pageSize) {
    return apiRequest({
      url: 'filemanager.TimeSheet.getCompanyUsersFullList',
      data: [filter, skip, pageSize],
    });
  }

  sendNotifyMessage(selected, model) {
    return apiRequest({
      url: 'filemanager.TimeSheet.sendNotifyMessage',
      data: [selected, model],
    });
  }

  updateLockTimesheet(ids, status, dateFrom, dateTo) {
    const data = [ids, status, dateFrom, dateTo];
    return apiRequest({ url: 'filemanager.TimeSheet.lockTimesheet', data });
  }
}
export default new EmployeeTimeSheetPageService();
