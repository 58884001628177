import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import Form from './components/Form';

import {
  storeGroupManager,
  PhysicianGroupDetailsType,
} from 'stores/_mobx/clinicianManager/groupManager';
import { URL_GROUP_MANAGER } from 'constant/path/clinicalManager';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {
  updateOverview: () => void;
}

const GroupManagerEditPage = ({ match: { params }, history }: PropsType) => {
  const { fetchingDetails, groupDetails, permission } = storeGroupManager;

  const handleSubmit = (payload: PhysicianGroupDetailsType) =>
    storeGroupManager.editGroup(payload).then((result) => {
      if (result) return result;
      history.push(URL_GROUP_MANAGER);
    });

  useEffect(() => {
    storeGroupManager.getGroupDetails(Number(params.id)).then((isSucceed) => {
      if(isSucceed) {
        storeGroupManager.checkPermission();
      } else {
        history.replace(URL_GROUP_MANAGER);
      }
    });

    return storeGroupManager.clearGroupDetails;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <>
      <LayoutSideTitle title="Edit Group">
        <Link to={URL_GROUP_MANAGER} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <Form
        fetching={fetchingDetails}
        defaultValues={groupDetails}
        {...permission}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default observer(GroupManagerEditPage);
