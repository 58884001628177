import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormPayerPlan, { FormModel } from './components/Form';

import { storePayerPlan } from 'stores/_mobx/systemSetup/masterSetting/payerPlan';
import { URL_PAYER_PLAN } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const PayerPlanAddPage = ({ history }: PropsType) => {
  const { addPayerPlan } = storePayerPlan;

  const handleSubmit = (payload: FormModel) =>
    addPayerPlan(payload).then((response) => {
      if (response) {
        history.push(URL_PAYER_PLAN);
      }
    });

  return (
    <>
      <Title title="Add Payer Plan" />
      <FormPayerPlan backUrl={URL_PAYER_PLAN} onSubmit={handleSubmit} />
    </>
  );
};

export default PayerPlanAddPage;
