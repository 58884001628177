import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface DraftResponseType {
  mail_date: string;
  mail_subject: string;
  mail_time: string;
  mail_to: string;
  messageid: string;
  messdetail_id: string;
  refid: string;
  trash: string;
}

interface DraftType {
  date: string;
  time: string;
  subject: string;
  recipient: string;
  id: number;
}

class Draft {
  private numbersOfRequests: number = 0;

  fetching: boolean = false;
  draftList: DraftType[] = [];
  draftTotal: number = 0;

  filter: string = '';
  page: Pagination = new Pagination({ id: 'drafts_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      draftList: observable,
      draftTotal: observable,
      filter: observable,

      setFetching: action,
      setDraftList: action,
      setDraftTotal: action,
      setFilter: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setDraftList(list: DraftType[]) {
    this.draftList = list;
  }

  setDraftTotal(count: number) {
    this.draftTotal = count;
  }

  setFilter(subject: string) {
    this.filter = subject;
  }

  async getDraftCount(filter: string) {
    try {
      const count = await apiRequest<number>({
        url: 'email.Email.NewDraftTotalCount',
        data: { filter },
      });

      this.setDraftTotal(count);
    } catch (e: any) {
      this.setDraftTotal(0);
    }
  }

  async getDraftList(filter: string) {
    const { pagination } = this.page;
    try {
      const data = {
        search: filter,
        StartIndex: pagination.skip,
        Limit: pagination.pageSize,
      };
      const response = await apiRequest<DraftResponseType[]>({
        url: 'email.Email.NewEmailDraftList',
        data,
      });

      const list = response.map((draft) => {
        const [date, time] = dateToLocalTimezone({
          date: `${draft.mail_date} ${draft.mail_time}`,
        }).split(' ');

        return {
          date,
          time,
          subject: draft.mail_subject,
          recipient: draft.mail_to,
          id: Number(draft.messageid),
        };
      });

      this.setDraftList(list);
    } catch (e: any) {
      this.setDraftList([]);
    }
  }

  getDraftsMain = async (filter = this.filter) => {
    this.numbersOfRequests += 1;

    this.setFetching(true);

    const promiseCount = this.getDraftCount(filter);

    const promiseReminderList = this.getDraftList(filter);

    Promise.allSettled([promiseCount, promiseReminderList]).finally(() => {
      this.numbersOfRequests -= 1;

      const fetching = Boolean(this.numbersOfRequests);

      this.setFetching(fetching);
    });
  };

  async moveToTrash(ids: number[]) {
    this.setFetching(true);
    try {
      const payload = ids.map((id) => ({
        flag: 'T',
        stat: 'Draft',
        mode: '',
        idvalue: id,
      }));

      const response = await apiRequest<'A' | 'D' | 'R' | 'T' | 'SE' | null>({
        url: 'email.Email.MoveEmail',
        data: [payload],
      });

      if (response && response !== 'SE') {
        Notification.success(
          `${ids.length} message${
            ids.length > 1 ? 's' : ''
          } deleted successfully!`
        );
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("You can't delete this message!");
      this.setFetching(false);
      return false;
    }
  }
}

export const storeDraft = new Draft();
