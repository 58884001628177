import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import { storeImport } from 'stores/_mobx/import';
import {
  dateISOStringToDateAndTime,
  convertToAppTimezone,
} from 'utils/DateUtils';

export interface CPTCodeType {
  accession_number: string;
  addvisible: boolean;
  cpt_desc: string;
  cpt_description: string;
  cpt_id: string;
  cptcount: string;
  data: string;
  desc: string;
  discount_percentage: string;
  editvisible: true;
  external_accession_number: string;
  external_placer_order_number: string;
  extremity: string;
  mode: string;
  modifier: string;
  price: number | string;
  refid: string;
}

export interface IcdCodeType {
  alphavalue: string;
  icd_code: string;
  refid: string;
  selicd_status: boolean;
  short_description: string;
}

export interface VisitNotesType {
  techId: number;
  orderId: number;
  visitSchedule: string;
  dos: string;
  modality: string;
  modalityId: number;
  submittedImages: string;
  arrivalDate: string;
  arrivalTime: string;
  assistedBy: string;
  additionalClinicalNotes: string;
  flagOrder: string;
  radiologyGroup: string;
  rejectedReasonId: number;
  rejectedImages: string;
  rejectedReason: string;
  codes: {
    cpt: CPTCodeType[];
    icd: IcdCodeType[];
  };
  media_type: string;
  phyid: number;
  physicianname: string;
  pacsFile: File | null;
}

class AssignedOrders {
  // TODO Remove this method after AuditLogs will implement with database (not files) on BE
  async addLog(orderId: number) {
    const data = {
      orderId: orderId,
      action: 'User uploaded PDF to send to DICOM',
    };
    try {
      const response = await apiRequest<Record<string, string | number>>({
        url: 'auditlog.AuditTrail.AddOrderAuditLog',
        data,
      });

      return response;
    } catch (e: any) {
      return null;
    }
  }

  async saveVisitNotes(visitNotes: VisitNotesType) {
    const payload = visitNotes.pacsFile
      ? {
          orderId: visitNotes.orderId,
          radiologyGroupId: visitNotes.radiologyGroup,
          file: visitNotes.pacsFile,
        }
      : null;

    const date =
      visitNotes.arrivalDate && visitNotes.arrivalTime
        ? convertToAppTimezone(
            `${visitNotes.arrivalDate} ${visitNotes.arrivalTime}`
          )
        : null;

    const [arrivalDate, arrivalTime] = date
      ? dateISOStringToDateAndTime(date.toISOString())
      : [visitNotes.arrivalDate, visitNotes.arrivalTime];

    const data = [
      visitNotes.techId,
      visitNotes.orderId,
      visitNotes.visitSchedule,
      visitNotes.dos,
      '', //transportation
      visitNotes.modalityId,
      visitNotes.submittedImages,
      arrivalDate,
      arrivalTime,
      visitNotes.assistedBy,
      visitNotes.additionalClinicalNotes,
      '', //chatNt
      null, //equipmentArr
      visitNotes.flagOrder,
      visitNotes.radiologyGroup,
      visitNotes.rejectedReasonId,
      visitNotes.rejectedImages,
      visitNotes.rejectedReason,
      visitNotes.codes,
      visitNotes.media_type,
      visitNotes.phyid,
      true, // to show BE which App send a request (Web or Mobile App). Mobile App send date in Application timezone, but Web already works with UTC
    ];

    try {
      const promisePACSuploading = payload
        ? storeImport.uploadFileNewApi({ url: 'dicom_send_results', payload })
        : Promise.resolve(null);

      const promiseVisitNotes = apiRequest<'S'>({
        url: 'patient.PatientInfo.AddTechVisit',
        data,
      });

      const [response, responseDICOM] = await Promise.all([
        promiseVisitNotes,
        promisePACSuploading,
      ]);

      if (response === 'S' && responseDICOM) {
        this.addLog(visitNotes.orderId);
      }

      if (response === 'S') {
        Notification.success('Successfully added!');

        return true;
      } else {
        throw Error('');
      }
    } catch (error) {
      Notification.danger('Error occurred!');
      return false;
    }
  }

  async updateAcknowledgement(orderId: number, acknowledgement: 'Y' | 'N') {
    try {
      const { status } = await apiRequest<{ status: 'S' | 'SE' }>({
        url: 'order.Order.updateAcknowledgement',
        data: [orderId, acknowledgement],
      });

      if (status === 'S') {
        return true;
      }
      throw Error('');
    } catch (e: any) {
      return false;
    }
  }
}

export const storeAssignedOrders = new AssignedOrders();
