import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { DropdownUsaState } from 'components/project/dropdown/UsaStateDropdown';
import FormControl from 'components/form/FormControl';
import { Input } from 'components/form/textField';
import { Radio } from 'components/form/radio';
import MaskInput from 'components/form/maskInput';
import { Button } from 'components/button';
import resolver from './validation';

import { URL_ZIP_CODES } from 'constant/path/systemSetup';
import { ZipCodeEditType } from 'stores/_mobx/systemSetup/masterSetting/zipCode';

interface FormProps {
  defaultValues?: ZipCodeEditType;
  onSubmit: (data: ZipCodeEditType) => Promise<any>;
}

const initialValues: ZipCodeEditType = {
  refid: 0,
  stateId: 0,
  zip: '',
  carrier: '',
  locality: '00',
  rural_index: '',
  lab_cb_locality: '',
  rural_index_2: '',
  plus_4_flag: 'N',
  part_b_drug_indicator: '',
  year_qtr: '',
};

const plusFlagOptions = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];

const cn = 'col-md-6';

const FormJurisdictionZipCode = ({
  defaultValues = initialValues,
  onSubmit,
}: FormProps) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm<ZipCodeEditType>({
    defaultValues,
    resolver,
  });

  const handleReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={`row col-xl-8${isSubmitting ? ' on-ajax' : ''}`}
      onSubmit={handleSubmit(onSubmit)}>
      <DropdownUsaState
        name="stateId"
        label="State"
        required
        className={cn}
        control={control}
      />

      <MaskInput
        format="#####-####"
        name="zip"
        label="Zip code"
        className={cn}
        control={control}
      />

      <Input
        name="carrier"
        label="Carrier"
        className={cn}
        control={control}
        required
      />

      <Input
        name="locality"
        label="Locality"
        required
        className={cn}
        control={control}
      />

      <Input
        name="rural_index"
        label="Rural IND"
        className={cn}
        control={control}
      />

      <Input
        name="lab_cb_locality"
        label="LAB CB Locality"
        required
        className={cn}
        control={control}
      />

      <Input
        name="rural_index_2"
        label="Rural IND2"
        className={cn}
        control={control}
      />

      <Radio
        name="plus_4_flag"
        label="Plus 4 flag"
        className={`${cn} part-inline`}
        control={control}
        options={plusFlagOptions}
      />

      <Input
        name="part_b_drug_indicator"
        label="Part B Drug indicator"
        required
        className={cn}
        control={control}
      />

      <Input
        type="number"
        name="year_qtr"
        label="YAER/QT"
        required
        className={cn}
        control={control}
      />

      <FormControl>
        <Link to={URL_ZIP_CODES} className="btn btn-danger">
          Back
        </Link>

        <Button
          variant="warning"
          text="Reset"
          disabled={!isDirty && isSubmitting}
          onClick={handleReset}
        />

        <Button
          type="submit"
          text="Submit"
          disabled={!isDirty || isSubmitting}
        />
      </FormControl>
    </form>
  );
};

export default FormJurisdictionZipCode;
