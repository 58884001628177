import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import {
  AbstractMessagesStore,
  SAbstractMessagesStore,
} from 'stores/messages/AbstractMessagesStore';
import { MessagePreviewType } from 'stores/_mobx/message/sent';

export class SSentOverviewStore extends SAbstractMessagesStore {
  messageDetailed: MessagePreviewType | null = null;
}

class SentOverviewStore extends AbstractMessagesStore<
  SSentOverviewStore,
  CommonPayload
> {
  getInitialState() {
    return new SSentOverviewStore();
  }

  reduce(state: SSentOverviewStore, action: CommonPayload) {
    switch (action.type) {
      case 'messages-sent-details':
        this.getState().messageDetailed = action.data;
        break;
      default:
        break;
    }
    return state;
  }
}

const sentOverviewStore = new SentOverviewStore(appDispatcher);
export default sentOverviewStore;
