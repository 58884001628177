import { Dispatcher } from 'flux';
import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

export class PReportManagerStore extends CommonPayload {
  count: number = 0;
}

export class SReportManagerStore {
  dataSource: Array<any> = [];
  dataSourceCount: number = 0;
}

class ReportManagerStore extends ReduceStore<
  SReportManagerStore,
  PReportManagerStore
> {
  getInitialState() {
    return new SReportManagerStore();
  }

  areEqual(state1: SReportManagerStore, state2: SReportManagerStore) {
    return false;
  }

  reduce(state: SReportManagerStore, action: PReportManagerStore) {
    switch (action.type) {
      case 'orders-order-information-report-manager-overview':
        this.getState().dataSource = action.data;
        this.getState().dataSourceCount = action.count;
        break;
      default:
        break;
    }
    return state;
  }
}

const reportManagerStore = new ReportManagerStore(
  appDispatcher as Dispatcher<PReportManagerStore>
);
export default reportManagerStore;
