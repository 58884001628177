import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import { CorporateAminUserType } from 'services/system-setup/master-setup/corporations/CorporationOverviewPageService';

const buildActionName = (name: string) => {
  return 'system-setup/master-setup/corporations/' + name;
};

export const Actions = {
  LOAD_CORPORATION_LIST_ACTION: buildActionName('load-corporation-list-action'),
  LOAD_CORPORATION_COUNT_ACTION: buildActionName(
    'load-corporation-count-action'
  ),
  LOAD_CORPORATION_ITEM_ACTION: buildActionName('load-corporation-item-action'),
  LOAD_CORPORATION_DOCUMENTS_ACTION: buildActionName(
    'load-corporation-documents-action'
  ),
  LOAD_FACILITY_LIST_ACTION:
    /*'facility.FacilityMaster.facilityDropDownNew',*/ buildActionName(
      'load-facility-list-action'
    ),
  LOAD_FACILITY_LIST_BY_ID_ACTION: buildActionName(
    'load-facility-list-by-id-action'
  ),
  LOAD_FACILITY_MEMBERS_ACTION: buildActionName('load-facility-members-action'),
  LOAD_USER_LIST_ACTION: buildActionName('load-user-list-action'),
  LOAD_SELECTED_USERS_LIST_ACTION: buildActionName(
    'load-selected-users-action'
  ),
  LOAD_SERVICE_LIST_ACTION: buildActionName('load-service-list-action'),
  CLEAR_ACTION: buildActionName('clear-action'),
  RESTORE_INITIAL_CONTACT_TAB_ACTION: buildActionName('restore-initial-contact-tab-action'),
  RESTORE_INITIAL_BILLING_TAB_ACTION: buildActionName('restore-initial-billing-tab-action'),
};

export class TCache {
  addressList: Array<{
    corp_name: string;
    collapsedContactInfo: boolean;
    additionalAddressList: Array<any>;
  }> = [
    { corp_name: '', collapsedContactInfo: false, additionalAddressList: [] },
  ];
  additionalAddressList: Array<any> = [{}];
  corporationItem: any = {};
  facilityMembers: Array<any> = [];
  fileList: Array<any> = [];
  facilityList: Array<any> = [];
  facilityListById: Array<any> = [];
  userList: CorporateAminUserType[] = [];
  selectedUsersIds: number[] = [];
  selectedUsers: CorporateAminUserType[] = [];
  serviceList: Array<any> = [];
  fileListLoaded: boolean = false;
}

class SAdditionalAddressList {
  refid: null | string;
  address1: null | string;
  city: null | string;
  state: null | string;
  zipcode: null | string;
  phone: null | string;
  email: null | string;
  website: null | string;
  country: null | string;
  address2: null | string;
  alt_phone: null | string;
  alt_email: null | string;
  first_name: null | string;
  last_name: null | string;
  middle_name: null | string;
  contact_title: null | string;
  fax: null | string;
}

export class AdditionalAddressList {
  refid: string = '';
  address1: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  phone: string = '';
  email: string = '';
  website: string = '';
  country: string = '';
  address2: string = '';
  alt_phone: string = '';
  alt_email: string = '';
  first_name: string = '';
  last_name: string = '';
  middle_name: string = '';
  contact_title: string = '';
  fax: string = '';
}

class SCorporationOverviewPageStore {
  addressList: Array<{
    corp_name: string;
    collapsedContactInfo: boolean;
    additionalAddressList: Array<any>;
  }> = [
    { corp_name: '', collapsedContactInfo: false, additionalAddressList: [] },
  ];
  additionalAddressList: Array<SAdditionalAddressList> = [];
  corporationItem: any = {};
  facilityMembers: Array<any> = [];
  fileList: Array<any> = [];
  facilityList: Array<any> = [];
  facilityListById: Array<any> = [];
  userList: CorporateAminUserType[] = [];
  selectedUsersIds: number[] = [];
  selectedUsers: CorporateAminUserType[] = [];
  serviceList: Array<any> = [];
  fileListLoaded: boolean = false;
  initial: TCache = new TCache();
}

class CorporationOverviewPageStore extends ReduceStore<
  SCorporationOverviewPageStore,
  CommonPayload
> {
  getInitialState() {
    return new SCorporationOverviewPageStore();
  }

  areEqual(
    state1: SCorporationOverviewPageStore,
    state2: SCorporationOverviewPageStore
  ) {
    return false;
  }

  reduce(state: SCorporationOverviewPageStore, action: CommonPayload) {
    const thst = this.getState();

    switch (action.type) {
      case Actions.LOAD_CORPORATION_ITEM_ACTION:
        thst.addressList = action.data.addressList;
        thst.additionalAddressList = action.data.additionalAddressList;
        thst.fileList = action.data.fileList;
        thst.fileListLoaded = true;

        thst.initial.addressList = action.data.addressList;
        thst.initial.additionalAddressList = action.data.additionalAddressList;
        thst.initial.fileList = action.data.fileList;
        thst.initial.fileListLoaded = true;
        break;
      case Actions.LOAD_FACILITY_LIST_ACTION:
        thst.facilityList = action.data.map((el: any) => ({
          ...el,
          value: Number(el.refid),
        }));
        break;
      case Actions.LOAD_FACILITY_LIST_BY_ID_ACTION:
        thst.facilityListById = action.data.map((el: any) => ({
          ...el,
          value: Number(el.refid),
        }));
        break;
      case Actions.LOAD_FACILITY_MEMBERS_ACTION:
        thst.facilityMembers = action.data;
        break;
      case Actions.LOAD_USER_LIST_ACTION:
        thst.userList = action.data || [];
        thst.selectedUsers = action.data.filter(
          ({ refid }: { refid: number }) =>
            thst.selectedUsersIds.includes(refid)
        );
        break;
      case Actions.LOAD_SELECTED_USERS_LIST_ACTION:
        thst.selectedUsersIds = action.data;
        thst.selectedUsers = thst.userList.filter(
          ({ refid }: { refid: number }) => action.data.includes(refid)
        );
        break;
      case Actions.LOAD_SERVICE_LIST_ACTION:
        thst.serviceList = action.data;
        thst.initial.serviceList = action.data;
        break;
      case Actions.CLEAR_ACTION:
        thst.addressList = [
          {
            corp_name: '',
            collapsedContactInfo: false,
            additionalAddressList: [],
          },
        ];
        thst.additionalAddressList = [];
        thst.fileList = [];
        thst.facilityListById = [];
        thst.facilityMembers = [];
        thst.selectedUsersIds = [];
        thst.userList = [];
        thst.serviceList = [];
        thst.fileListLoaded = false;

        thst.initial = new TCache();
        break;
      case Actions.RESTORE_INITIAL_CONTACT_TAB_ACTION:
        thst.addressList = thst.initial.addressList;
        thst.additionalAddressList = thst.initial.additionalAddressList;
        thst.fileList = thst.initial.fileList;
        thst.fileListLoaded = thst.initial.fileListLoaded;
        break;
      case Actions.RESTORE_INITIAL_BILLING_TAB_ACTION:
        thst.serviceList = thst.initial.serviceList;
        break;
      default:
        break;
    }
    return state;
  }
}

const corporationOverviewPageStore = new CorporationOverviewPageStore(
  appDispatcher
);
export default corporationOverviewPageStore;
