import { TPagination } from 'components/grid/GridTypes';

import OrderOverviewService, {
  TOrderNote,
} from 'services/workflow/order/OrderOverviewService';
import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';
import appDispatcher from 'dispatcher/AppDispatcher';
import UserProfileStore from 'stores/UserProfileStore';
import OrderFormPageActions from 'actions/workflow/order/OrderFormPageActions';
import { dateToLocalTimezone } from 'utils/DateUtils';

export class TOrderOverviewFilter {
  dosSearch: string = '';
  srchlastnameval: string = '';
  srchdate: string = '';
  facilityid: number = 0;
  examtype: string = '';
  status: string = '';
  fromdt: string = '';
  todate: string = '';
  provid: number = 0;
  pcpid: number = 0;
  currentUserType: string = '';
  sign: string = '';
  srchfirstnameval: string = '';
  srchssnval: string = '';
  stateArr: Array<any> = [];
  regionArr: Array<any> = [];
  faxstatus: string = '';
  techId: number = 0;
  recordsAccess: string = '';
  stateId: number = 0;
  locId: number = 0;
  posId: number = 0;
  radioId: number = 0;
  ordSource: string = null;
  signed: string = null;
  faceSheet: string = null;
  corporateId: number = 0;
  orderStatus: string = '';
  clinicianGroupId: number = null;
  division: number = 0;
}

export class TMedicalRecordFilter {
  corporateId: number = 0;
  srchlastnameval: string = '';
  srchdate: string = '';
  fromdt: string = '';
  todate: string = '';
  facilityid: number = 0;
  srchfirstnameval: string = '';
  examtype: number = 0;
  techId: number = 0;
  stateId: number = 0;
  locId: number = 0;
  posId: number = 0;
  radioId: number = 0;
  ordSource: string = null;
  signed: string = null;
  faceSheet: string = null;
  physician: number = null;
  physicianGroup: number = null;
  orderStatus: string = 'A';
  cptCode: string = null;
  cpt_category_id: number = null;
  accessionNum: string = null;
  mrn: string = null;
  otherMrn: string = null;
  oid: string = null;
  iceNumber: string = null;
  marshallId: string = null;
  clinicianGroupId: number = null;
  division: number = 0;
}

export class TUpdatedOrderNote {
  message: string = '';
  user: string = '';
  date: string = '';
}

export default class OrderOverviewActions {
  static loadGridData(
    filter: TOrderOverviewFilter,
    pagination: TPagination,
    signal: AbortSignal
  ) {
    let count: number = 0;
    return OrderOverviewService.loadOverviewCount(
      filter.dosSearch || '',
      filter.srchlastnameval ? filter.srchlastnameval.trim() : '',
      filter.srchdate || '',
      filter.facilityid || 0,
      filter.examtype,
      filter.status,
      filter.fromdt,
      filter.todate,
      filter.provid,
      filter.pcpid,
      // !! filter.currentUserType, Order count have no this property !!
      filter.sign,
      filter.srchfirstnameval ? filter.srchfirstnameval.trim() : '',
      filter.srchssnval,
      filter.stateArr,
      filter.regionArr,
      filter.faxstatus,
      filter.techId || 0,
      filter.recordsAccess,
      filter.stateId || 0,
      filter.locId || 0,
      filter.posId || 0,
      filter.radioId || 0,
      filter.ordSource || null,
      filter.signed || null,
      filter.faceSheet || null,
      filter.clinicianGroupId,
      filter.division,
      signal
    )
      .then((response: number) => {
        count = response ? parseInt('' + response) : 0;
        if (count <= 0) {
          return [];
        }
        return OrderOverviewService.loadOverview(
          filter.dosSearch || '',
          filter.srchlastnameval ? filter.srchlastnameval.trim() : '',
          filter.srchdate || '',
          filter.facilityid || 0,
          filter.examtype || '',
          filter.status,
          filter.fromdt,
          filter.todate,
          filter.provid,
          filter.pcpid,
          pagination.skip,
          pagination.pageSize,
          filter.currentUserType,
          filter.sign,
          filter.srchfirstnameval ? filter.srchfirstnameval.trim() : '',
          filter.srchssnval,
          filter.stateArr,
          filter.regionArr,
          filter.faxstatus,
          filter.techId || 0,
          filter.recordsAccess,
          filter.stateId || 0,
          filter.locId || 0,
          filter.posId || 0,
          filter.radioId || 0,
          filter.ordSource || null,
          filter.signed || null,
          filter.faceSheet || null,
          filter.clinicianGroupId,
          filter.division,
          signal
        );
      })
      .then((response: Array<TSignOrderItem>) => {
        if (response && response.length) {
          response.forEach((v) => {
            OrderFormPageActions.fixCptExtremity(v.GetIntCptArr);
            OrderFormPageActions.fixCptExtremity(v.cptCodes);
          });
        }
        appDispatcher.dispatch({
          type: 'orders-order-overview',
          data: response,
          count: count,
        } as any);
        return [];
      })
      .catch(() => {
        appDispatcher.dispatch({
          type: 'orders-order-overview',
          data: [],
          count: 0,
        } as any);
        return [];
      });
  }

  static clearNotes(orderId?: number) {
    appDispatcher.dispatch({
      type: 'order.OrderNotes.GetNotes',
      data: [],
    });
  }

  static getNotes(orderId: number) {
    return OrderOverviewService.getNotes(orderId).then(
      (notes: Array<TOrderNote>) => {
        let res: Array<TUpdatedOrderNote> = [];
        if (notes && notes.length) {
          res = notes
            .map((v) => ({
              message: v.notes,
              user: v.name,
              date: dateToLocalTimezone({
                date: v.date,
              }),
            }))
            .reverse();
        }
        appDispatcher.dispatch({
          type: 'order.OrderNotes.GetNotes',
          data: res,
        });
        return res;
      }
    );
  }

  static clearDataSource() {
    appDispatcher.dispatch({
      type: 'orders-order-overview',
      data: [],
      count: 0,
    } as any);
  }

  static cancelOrder(orderId: number, reason: string) {
    return OrderOverviewService.cancelOrder(orderId, reason);
  }

  static loadMedicalRecordGridData(
    filter: TMedicalRecordFilter,
    pagination: TPagination,
    orderFlags: Array<{ selected: boolean; flag: string; sub?: string }>,
    signal: AbortSignal
  ) {
    let count = 0;
    const userStore = UserProfileStore;
    const userType = userStore.getUserType();
    const typedUserId: number =
      userType === 'D' && userStore.getTypedUserId()
        ? parseInt('' + userStore.getTypedUserId())
        : 0;
    return OrderOverviewService.getPatientCountByNoTechnList(
      filter.corporateId || 0,
      filter.srchlastnameval ? filter.srchlastnameval.trim() : '',
      filter.srchdate || '',
      filter.fromdt,
      filter.todate,
      userType,
      filter.facilityid || 0,
      userStore.getUserId(),
      filter.srchfirstnameval ? filter.srchfirstnameval.trim() : '',
      filter.examtype || 0,
      filter.techId || 0,
      filter.stateId || 0,
      filter.locId || 0,
      filter.posId || 0,
      filter.radioId || 0,
      filter.ordSource || null,
      filter.signed || null,
      filter.faceSheet || null,
      'A',
      orderFlags,
      filter.physician,
      filter.physicianGroup,
      typedUserId,
      filter.orderStatus || 'A',
      filter.cptCode,
      filter.cpt_category_id,
      filter.accessionNum,
      filter.mrn,
      filter.otherMrn,
      filter.oid,
      filter.iceNumber,
      filter.marshallId,
      filter.clinicianGroupId,
      filter.division,
      signal
    )
      .then((response) => {
        count = response;
        if (response && parseInt('' + response) > 0) {
          return OrderOverviewService.getPatientByNoTechnList(
            filter.corporateId || 0,
            filter.srchlastnameval ? filter.srchlastnameval.trim() : '',
            filter.srchdate || '',
            filter.fromdt,
            filter.todate,
            userType,
            filter.facilityid || 0,
            userStore.getUserId(),
            pagination ? pagination.skip : 0,
            pagination ? pagination.pageSize : 10,
            filter.srchfirstnameval ? filter.srchfirstnameval.trim() : '',
            filter.examtype || 0,
            filter.techId || 0,
            filter.stateId || 0,
            filter.locId || 0,
            filter.posId || 0,
            filter.radioId || 0,
            filter.ordSource || null,
            filter.signed || null,
            filter.faceSheet || null,
            'A',
            orderFlags,
            filter.physician,
            filter.physicianGroup,
            typedUserId,
            filter.orderStatus || 'A',
            filter.cptCode,
            filter.cpt_category_id,
            filter.accessionNum,
            filter.mrn,
            filter.otherMrn,
            filter.oid,
            filter.iceNumber,
            filter.marshallId,
            filter.clinicianGroupId,
            filter.division,
            signal
          );
        } else {
          return [];
        }
      })
      .then((response) => {
        if (response && response.length) {
          response.forEach((v) => {
            OrderFormPageActions.fixCptExtremity(v.GetIntCptArr);
            OrderFormPageActions.fixCptExtremity(v.cptCodes);
          });
        }
        appDispatcher.dispatch({
          type: 'orders-order-overview',
          data: response,
          count: count,
        } as any);
        return [];
      })
      .catch(() => {});
  }

  static revertCancelledOrder(order: { refid: number }) {
    return OrderOverviewService.revertCancelledOrder(order);
  }

  static completeOrders(orderIds: Array<number>) {
    return OrderOverviewService.completeOrders(orderIds);
  }

  static loadCheckOutDetails(techId: number) {
    return OrderOverviewService.loadCheckOutDetails(techId);
  }

  static updateOrderStatus(
    orderId: number,
    techItem: { id: number; name: string }
  ) {
    return OrderOverviewService.updateOrderStatus(orderId, techItem);
  }
}
