import React from 'react';

import AbstractAddEdit, {
  PAbstractAddEdit,
  SAbstractAddEdit,
} from 'page/privileges/app-users/AbstractAddEdit';
import UserTypeStore from 'stores/project/UserTypeStore';
import ApplicationUsersAddEditActions from 'actions/privileges/ApplicationUsersAddEditActions';
import FacilityRolesDropdown from 'components/project/dropdown/FacilityRolesDropdown';
import { USER_APP as PAGE_ID } from 'constant/pagesId/privileges';
import { TUserTypeEnum } from 'services/project/UserSelectionService';
import { URL_APPLICATION_USER } from 'constant/path/privileges';

interface PApplicationUsersAdd extends PAbstractAddEdit {
  overviewPageUrl?: string;
}

class SApplicationUsersAdd extends SAbstractAddEdit {
  onAjax: boolean = false;
}

export default class ApplicationUsersAdd extends AbstractAddEdit<
  PApplicationUsersAdd,
  SApplicationUsersAdd
> {
  applicationUsersAddEditStore: any = null;

  title = 'Create New User';

  getOverviewPageUrl() {
    return this.props.overviewPageUrl || URL_APPLICATION_USER;
  }

  constructor(props: PApplicationUsersAdd) {
    super(props);
    this.state = Object.assign(new SApplicationUsersAdd(), this.state);
  }

  isNew: boolean = true;

  componentDidMount() {
    super.componentDidMount();
    this.updateData();
    const model = {
      ...this.state.model,
      userTypeToggler: 'A' as TUserTypeEnum,
    };
    if (FacilityRolesDropdown.getFacilityId() !== 0) {
      //Need to do it, because of react conflict.
      //Parent method update model, this method also update model.
      //Only last changes will be saved for 'model' field.
      model.facility = FacilityRolesDropdown.getFacilityId();
    }
    this.setState({ model, onAjax: false });
  }

  componentWillUnmount() {
    ApplicationUsersAddEditActions.clearModel();
    super.componentWillUnmount();
  }

  updateData() {}

  onSubmitSuccess(): Promise<string | number | { ipin: string }> {
    const callback = (
      signatureFileName: string | null
    ): Promise<string | number | { ipin: string }> => {
      const staffData = this.getStaffData(signatureFileName);

      const { vaccinations } = this.state.model;

      this.setState({ onAjax: true });

      const isRadiologist = UserTypeStore.isRadiologist(
        Number(this.state.model.userType)
      );

      return isRadiologist
        ? // @ts-ignore
          ApplicationUsersAddEditActions.addRadiologistInfo(staffData)
        : ApplicationUsersAddEditActions.addStaffInfo(staffData, vaccinations);
    };

    return this.uploadSignature().then(callback);
  }

  /**
   * override parent function
   */
  getSavedNotification(response: string) {
    return (
      <div>
        New User created.
        <br />
        Pin value: {response}
      </div>
    );
  }

  clearForm(event: React.MouseEvent<HTMLAnchorElement>) {
    if (event) {
      event.preventDefault();
    }
    const model = AbstractAddEdit.getDefaultModel();
    if (FacilityRolesDropdown.getFacilityId() !== 0) {
      //Need to do it, because of react conflict.
      //Parent method update model, this method also update model.
      //Only last changes will be saved for 'model' field.
      model.facility = FacilityRolesDropdown.getFacilityId();
    }
    this.setState({ model: model });
  }

  getPageAppId() {
    return PAGE_ID.ADD;
  }
}
