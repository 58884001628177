import React, { CSSProperties } from 'react';

import DomUtils from 'utils/DomUtils';

const SCROLL_SIZE = 12;
const MOUSE_UP_TYPE = 'mouseup';
const MOUSE_LEAVE_TYPE = 'mouseleave';
const BLUR_TYPE = 'blur';
const FOCUS_TYPE = 'focus';
const MOUSE_MOVE_TYPE = 'mousemove';
const RESIZE_TYPE = 'resize';

interface PScroll {
  onChange: (e?: any) => void;
  width: number;
}

interface Drag {
  startX: number;
  currentToggler: number;
  startMargin: number;
}

class SScroll {
  marginLeft: number;
  shiftY: number;
  dragStart: boolean;
  drag: Drag;

  constructor(
    marginLeft: number,
    shiftY: number,
    dragStart: boolean,
    drag: Drag
  ) {
    this.marginLeft = marginLeft;
    this.shiftY = shiftY;
    this.dragStart = dragStart;
    this.drag = drag;
  }
}

export default class Scroll extends React.Component<PScroll, SScroll> {
  _mouseListener: (e?: any) => void;
  _releaseWindow: (e?: any) => void;
  _mouseMoveListener: (e?: any) => void;
  _windowResizeListener: (e?: any) => void;
  container: any;
  projectGrid: any;

  constructor(props: PScroll) {
    super(props);
    this.state = new SScroll(0, 0, false, {
      startX: 0,
      currentToggler: 0,
      startMargin: 0,
    });
    this._mouseListener = () => this.releaseWindowOrigin();
    this._mouseMoveListener = (e) => this.onMouseMove(e);
    this._releaseWindow = () => this.releaseWindowOrigin();
    this._windowResizeListener = () => this.windowResizeOrigin();

    this.setListeners('addEventListener');

    this.container = null;
    this.projectGrid = null;
  }

  updateState = (
    marginLeft: number,
    startX: number,
    currentToggler: number,
    startMargin: number,
    callback: () => void
  ) => {
    this.setState(
      {
        marginLeft,
        drag: { startX, currentToggler, startMargin },
      },
      callback
    );
  };

  setMarginLeft = (marginLeft: number, callback?: () => void) => {
    this.setState(
      {
        marginLeft,
      },
      callback
    );
  };

  setShiftY = (shiftY: number) => {
    this.setState({ shiftY });
  };

  setDragStart = (dragStart: boolean) => {
    this.setState({ dragStart });
  };

  setDrag = (
    startX?: number,
    currentToggler?: number,
    startMargin?: number
  ) => {
    this.setState({ drag: { startX, currentToggler, startMargin } });
  };

  setContainer(container: any) {
    this.container = container;
  }

  setListeners(action: 'addEventListener' | 'removeEventListener'): void {
    if (!action) {
      return;
    }
    document.body[action](MOUSE_UP_TYPE, this._mouseListener);
    document[action](MOUSE_LEAVE_TYPE, this._mouseListener);
    window[action](BLUR_TYPE, this._mouseListener);
    window[action](FOCUS_TYPE, this._releaseWindow);
    document.body[action](MOUSE_MOVE_TYPE, this._mouseMoveListener);
    window[action](RESIZE_TYPE, this._windowResizeListener);
  }

  setGrid(projectGrid: any) {
    this.projectGrid = projectGrid;
  }

  /*shouldComponentUpdate(nextProps, nextState) {
        return nextProps.width !== this.props.width || this.state.marginLeft !== nextState.marginLeft || this.state.shiftY !== nextState.shiftY
    }*/

  moveScroll() {
    const projectGrid = this.projectGrid;
    const scrollableContainer = this.container;
    const grid = projectGrid.refs.grid;
    const offset = DomUtils.getOffset(
      grid,
      scrollableContainer === document ? null : scrollableContainer
    ).top;
    const height = DomUtils.getSizes(grid).height;
    let containerHeight, containerScroll;
    //grid can be in popup and in document. Popup has 'top' and 'padding top' properties that can affect calculations
    if (scrollableContainer === document) {
      containerScroll = DomUtils.documentScroll().top;
      containerHeight = DomUtils.getBrowserSize(true).height;
    } else {
      containerScroll = DomUtils.scroll(scrollableContainer).top;
      containerHeight = DomUtils.getInnerSizes(scrollableContainer).height;
    }
    const topBorderVisible = containerScroll + containerHeight - offset > 0;
    const bottomBorderVisible =
      containerScroll + containerHeight - (offset + height) > 0;
    const result = containerScroll - offset + containerHeight;
    let shiftY = 0;
    if (!topBorderVisible) {
      shiftY = -100000;
    } else if (bottomBorderVisible) {
      //attach to the end of grid
      shiftY = height + SCROLL_SIZE;
    } else {
      //attach to the end of screen
      shiftY = result;
    }
    if (this.state.shiftY !== shiftY) {
      this.setShiftY(shiftY);
    }
  }

  releaseWindowOrigin() {
    if (this.state.dragStart) {
      document.body.classList.add('no-selection');
      document.documentElement.classList.remove('no-overflow');
      document.documentElement.style.width = null;
      this.setDragStart(false);
    } else {
      document.body.classList.remove('no-selection');
      document.documentElement.classList.remove('no-overflow');
      document.documentElement.style.width = null;
    }
  }

  windowResizeOrigin() {
    if (this.state.marginLeft !== 0) {
      this.setMarginLeft(0, () => this.props.onChange(0));
    }
  }

  componentWillUnmount() {
    this.setListeners('removeEventListener');
    this._releaseWindow();
  }

  render() {
    const containerStyle: CSSProperties = {
      position: 'absolute',
      width: '100%',
      top: this.state.shiftY - SCROLL_SIZE,
      zIndex: 10,
    };
    const togglerStyle: CSSProperties = {
      marginLeft: this.state.marginLeft,
      width: this.props.width * 100 + '%',
    };
    return (
      <div style={containerStyle}>
        <div
          className="slider"
          ref="container"
          onClick={(e) => this.onContainerClick(e, 'click')}
          onTouchStart={(e) => this.onContainerClick(e, 'touch')}
        >
          <div
            className="toggler"
            ref="toggler"
            onClick={(e) => Scroll.onScrollClick(e)}
            style={togglerStyle}
            onMouseDown={(e) => this.onMouseDown(e)}
          />
        </div>
      </div>
    );
  }

  static onScrollClick(event: any) {
    event.preventDefault();
    event.stopPropagation();
    event.preventBubble = true;
  }

  static getClientX(event: any) {
    if (event.clientX !== undefined) {
      return event.clientX;
    }
    if (event.touches && event.touches.length) {
      return event.touches[0].clientX;
    }
    return 0;
  }

  onContainerClick(e: any, flag: 'click' | 'touch') {
    const container = (this.refs as any).container;
    const offset = DomUtils.getOffset(container).left;

    const relativeOffset = Scroll.getClientX(e) - offset;
    const width = container.offsetWidth;

    const relativePoint = relativeOffset / width;
    const relativeMargin = this.state.marginLeft / width;
    if (
      flag !== 'touch' &&
      relativePoint > relativeMargin &&
      relativePoint < relativeMargin + this.props.width
    ) {
      return; //click was inside of scrollbar. Prevent click on container borders.
    }
    const relativeHalfWidth = this.props.width / 2;
    const relativeScrollCenter = relativeMargin + relativeHalfWidth;
    const scrollWidth = (this.refs.toggler as any).offsetWidth;
    let callback: () => void;
    let margin: number;
    let intermediateChange: { callback: () => void; margin: number };
    if (relativePoint > relativeScrollCenter) {
      if (relativePoint + relativeHalfWidth > 1) {
        callback = () => this.props.onChange(1);
        margin = width - width * this.props.width;
      } else {
        intermediateChange = this.intermediateChange(
          relativePoint,
          relativeHalfWidth,
          width,
          scrollWidth
        );
        margin = intermediateChange.margin;
        callback = intermediateChange.callback;
      }
    } else {
      if (relativePoint - relativeHalfWidth < 0) {
        margin = 0;
        callback = () => this.props.onChange(0);
      } else {
        intermediateChange = this.intermediateChange(
          relativePoint,
          relativeHalfWidth,
          width,
          scrollWidth
        );
        margin = intermediateChange.margin;
        callback = intermediateChange.callback;
      }
    }
    this.setMarginLeft(margin, callback);
  }

  intermediateChange(
    relativePoint: number,
    relativeHalfWidth: number,
    width: number,
    scrollWidth: number
  ): { callback: () => void; margin: number } {
    const { onChange } = this.props;
    return {
      callback: () =>
        onChange(
          (relativePoint - relativeHalfWidth) / ((width - scrollWidth) / width)
        ),
      margin: (relativePoint - relativeHalfWidth) * width,
    };
  }

  onMouseDown(event: any) {
    const width = document.documentElement.clientWidth;
    document.body.classList.add('no-selection');
    document.documentElement.classList.add('no-overflow');
    document.documentElement.style.width = width + 'px';
    this.setState({
      dragStart: true,
    });
    this.setDrag(Scroll.getClientX(event), null, this.state.marginLeft);
  }

  onMouseMove(event: any) {
    const state = this.state;
    if (state.dragStart) {
      const cDrag = state.drag;
      const nDrag = {
        startX: cDrag.startX,
        currentToggler: Scroll.getClientX(event),
        startMargin: cDrag.startMargin,
      };
      let marginLeft;
      const value = nDrag.currentToggler - nDrag.startX + nDrag.startMargin;
      const toggler = this.refs.toggler as any;
      const container = this.refs.container as any;
      let percent: number;
      if (value > 0) {
        if (value + toggler.offsetWidth < container.offsetWidth) {
          marginLeft = value;
        } else {
          marginLeft = container.offsetWidth - toggler.offsetWidth;
        }
        percent = marginLeft / (container.offsetWidth - toggler.offsetWidth);
      } else {
        marginLeft = 0;
        percent = 0;
      }
      this.updateState(
        marginLeft,
        nDrag.startX,
        nDrag.currentToggler,
        nDrag.startMargin,
        () => this.props.onChange(percent)
      );
    }
  }
}
