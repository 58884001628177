import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';

interface PropsType {
  onClose: () => void;
  onApprove: (flag: boolean) => void;
}

const DialogVerifyInsurance = ({ onClose, onApprove }: PropsType) => {
  const handleClickNotRequired = () => {
    onApprove(false);
  };

  const handleClickRequired = () => {
    onApprove(true);
  };

  return (
    <Dialog handleClose={onClose}>
      <DialogHeader title="Confirm" onClose={onClose} />
      <DialogBody>Does this order require verification?</DialogBody>
      <DialogFooter>
        <Button text="Not Required" onClick={handleClickNotRequired} />
        <Button text="Required" onClick={handleClickRequired} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogVerifyInsurance;
