import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { Radio } from 'components/form/radio';
import { Button } from 'components/button';
import resolver from './validation';

import { PasswordSettingsType } from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';
import { URL_MASTER_SETTINGS } from 'constant/path/systemSetup';
import { useEffect } from 'react';

const alphaNumericOptions = [
  {
    label: 'Yes',
    value: 'Y',
  },
  {
    label: 'No',
    value: 'N',
  },
];

const initialValues: PasswordSettingsType = {
  min_character: 0,
  alpha_numeric: 'Y',
  no_password_reuse: 0,
  refid: null,
};

interface PropsType {
  defaultValues?: PasswordSettingsType;
  onSubmit: (data: PasswordSettingsType) => Promise<any>;
}

const PasswordStrengthForm = ({
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting, isDirty },
    reset,
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`row${isSubmitting ? ' on-ajax' : ''}`}>
      <div className="col-md-8 col-lg-6">
        <Input
          name="min_character"
          label="Minimum character"
          type="number"
          min={0}
          required
          control={control}
        />
        <Radio
          name="alpha_numeric"
          label="Alpha numeric"
          className="inline"
          options={alphaNumericOptions}
          control={control}
        />
        <Input
          name="no_password_reuse"
          label="Number of password reuses"
          type="number"
          min={0}
          required
          control={control}
        />

        <ControlsLayout alignX="right">
          <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
            Back
          </Link>
          <Button
            type="button"
            variant="warning"
            text="Reset"
            onClick={handleClickReset}
          />
          <Button
            type="submit"
            text="Submit"
            disabled={isSubmitting || !isDirty}
          />
        </ControlsLayout>
      </div>
    </form>
  );
};

export default PasswordStrengthForm;
