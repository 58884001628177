import React from 'react';

import IterableUtils from 'utils/IterableUtils';
import TextArea from 'components/form/input/TextArea';
import Form from 'components/form/Form';
import { Button } from 'components/button';
import FormControl from 'components/form/FormControl';
import PatientRecordPageStateActions from 'actions/patient-records/patient-demographics/PatientRecordPageStateActions';
import { TCommentInfo } from 'services/patient-records/patient-demographics/PatientRecordPageService';

export interface PCommentTab {
  mode: number;
  commentInfo: TCommentInfo;
  commentInfoEditable: boolean;
  updateCommentInfo: (commentInfo: TCommentInfo) => void;
}

export class SCommentTab {
  mode: number = null;
  commentInfo: TCommentInfo = new TCommentInfo();
  commentInfoEditable: boolean = false;
  errors: any = {};
}

export default class CommentTab extends React.Component<
  PCommentTab,
  SCommentTab
> {
  constructor(props: PCommentTab) {
    super(props);
    this.state = Object.assign(new SCommentTab(), {
      mode: this.props.mode,
      commentInfo: this.props.commentInfo,
      commentInfoEditable: this.props.commentInfoEditable,
      errors: {},
    });
  }

  componentWillUnmount() {
    const commentInfo = Object.assign({}, this.state.commentInfo);
    PatientRecordPageStateActions.cacheCommentInfoTab(commentInfo);
  }

  render() {
    const { state, props } = this;
    const textAreaAttrs: { rows: number; disabled: boolean } = {
      rows: 6,
      disabled: undefined,
    };
    if (!state.commentInfoEditable) {
      textAreaAttrs.disabled = true;
    }
    return (
      <div className="comment-tab">
        {props.mode === 1 ? <div className="overlay"></div> : null}
        <Form
          model={state.commentInfo}
          submit={this.handleSubmit.bind(this)}
          onCollectValues={this.onCollectValues.bind(this)}
          errors={state.errors}>
          <TextArea
            name="alert"
            label={
              <>
                Alert{' '}
                <span className="fw-normal text-secondary">
                  (Alerts will be displayed in the User interface, hover over
                  the icon{' '}
                  <i className="bi bi-exclamation-triangle-fill text-danger" />{' '}
                  to see Alerts)
                </span>
              </>
            }
            attr={textAreaAttrs}
          />
          <TextArea name="comment" label="Comment" attr={textAreaAttrs} />
          {!state.commentInfoEditable ? null : (
            <FormControl>
              <Button type="submit">Submit</Button>
            </FormControl>
          )}
        </Form>
      </div>
    );
  }

  reset() {
    PatientRecordPageStateActions.restoreInitialCommentInfoTab();
  }

  onCollectValues(name: string, value: any, errorMessages: any) {
    const state = IterableUtils.extend(true, {}, this.state);
    state.commentInfo[name] = value;
    state.errors[name] = errorMessages;
    this.setState(state);
  }

  handleSubmit() {
    const commentInfo = Object.assign({}, this.state.commentInfo);
    this.props.updateCommentInfo(commentInfo);
  }
}
