import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import UserProfileStore from 'stores/UserProfileStore';
import {
  convertToAppTimezone,
  dateISOStringToDateAndTime,
  dateToLocalTimezone,
} from 'utils/DateUtils';
import messagePrettier from 'utils/messagePrettier';

interface ReminderType {
  description: string;
  email_stat: string;
  receiver_id: number;
  refid: number;
  reminder_dt: string;
  reminder_time: string;
  sender_id: number;
  subject: string;
  user_id: number;
  username: string;
  usertype: string;
  view_status: string;
}

interface ReminderContentType {
  mailheading: string;
  mailid: string;
  refid: string;
}

interface ReminderViewResponse {
  mailcontent: ReminderContentType[];
  dttime: string;
  mail_sub: string;
  mail_body: string;
  mailto_id: string;
  mailtonm: string;
}

interface ReminderViewType {
  body: string;
  date: string;
  from: string;
  subject: string;
  to: string;
}

export interface ReceiverType {
  name: string;
  mail: number;
  refid: string;
  type: string;
}

export interface ReminderAddType {
  recievers: ReceiverType[];
  receiversString: string;
  date: string;
  time: string;
  subject: string;
  description: string;
}

class Reminder {
  fetching: boolean = false;
  reminderList: ReminderType[] = [];
  remindersTotal: number = 0;
  reminderView: ReminderViewType | null = null;
  reminderEntry: any = null;
  page: Pagination = new Pagination({ id: 'reminder_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      reminderList: observable,
      remindersTotal: observable,
      reminderEntry: observable,
      reminderView: observable,

      setReminderList: action,
      setRemindersTotal: action,
      setReminderEntry: action,
      setReminderView: action,
      setFetching: action,
      clearReminderEntry: action.bound,
      clearReminderView: action.bound,
    });
  }

  setReminderList(list: ReminderType[]) {
    this.reminderList = list;
  }

  setRemindersTotal(count: number) {
    this.remindersTotal = count;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setReminderView(entry: ReminderViewType) {
    this.reminderView = entry;
  }

  setReminderEntry(entry: any) {
    this.reminderEntry = entry;
  }

  clearReminderEntry() {
    this.reminderEntry = null;
  }

  clearReminderView() {
    this.reminderView = null;
  }

  async getRemindersTotal(userId: number, userType: string) {
    const data = {
      userId,
      userType,
    };

    try {
      const count = await apiRequest<string>({
        url: 'email.Remainder.TotalRecordCount',
        data,
      });

      this.setRemindersTotal(Number(count));
    } catch (e) {
      this.setRemindersTotal(0);
    }
  }

  async getRemindersList(userId: number, userType: string) {
    const { pagination } = this.page;

    const data = {
      userId,
      userType,
      StartIndex: pagination.skip,
      Limit: pagination.pageSize,
    };

    try {
      const response = await apiRequest<ReminderType[] | 'SE'>({
        url: 'email.Remainder.RemainderList',
        data,
      });

      if (Array.isArray(response)) {
        const reminders = response.map((reminder) => {
          const [reminder_dt, reminder_time] = dateToLocalTimezone({
            date: `${reminder.reminder_dt} ${reminder.reminder_time}`,
          }).split(' ');

          return {
            ...reminder,
            refid: Number(reminder.refid),
            subject: reminder.subject,
            description: reminder.description,
            reminder_dt,
            reminder_time,
          };
        });
        this.setReminderList(reminders);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setReminderList([]);
    }
  }

  async getReminderListMain() {
    this.setFetching(true);

    const userId = UserProfileStore.getUserId();

    const userType = UserProfileStore.getUserType();

    const promiseCount = this.getRemindersTotal(userId, userType);

    const promiseReminderList = this.getRemindersList(userId, userType);

    Promise.allSettled([promiseCount, promiseReminderList]).finally(() => {
      this.setFetching(false);
    });
  }

  async deleteReminders(ids: number[]) {
    const payload = ids.map((idvalue) => ({ idvalue }));

    try {
      const response = await apiRequest({
        url: 'email.Remainder.DeleteRemainder',
        data: [payload],
      });
      if (response === 'S') {
        Notification.success('Item was deleted!');
        return true;
      } else {
        throw Error('Error occurred');
      }
    } catch (e: any) {
      Notification.danger("Can't delete message due some unknown reasons.");
      return false;
    }
  }

  async getReminderView(id: number) {
    this.setFetching(true);
    try {
      const response = await apiRequest<ReminderViewResponse[] | 'SE'>({
        url: 'email.EmailMessageDisplay.ReminderBodyMessageDisplay',
        data: [id],
      });

      if (Array.isArray(response)) {
        const reminder = messagePrettier(response[0]);

        this.setReminderView(reminder);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e: any) {
      this.setReminderView(null);
    } finally {
      this.setFetching(false);
    }
  }

  async addReminder(payload: ReminderAddType) {
    const dateStr = convertToAppTimezone(
      `${payload.date} ${payload.time}`
    ).toISOString();

    const time = dateISOStringToDateAndTime(dateStr)[1];

    const date = dateStr.substring(0, 10);

    const data = {
      date,
      time,
      subject: payload.subject,
      description: payload.description,
      recievers: payload.recievers,
      senderId: UserProfileStore.getUserId(),
    };

    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'email.Remainder.AddReminder',
        data,
      });
      if (response === 'S') {
        Notification.success('Reminder was created');
        return true;
      } else {
        throw Error('Error occurred');
      }
    } catch (e: any) {
      Notification.danger("Can't create reminder");
      return false;
    }
  }
}

export const storeReminder = new Reminder();
