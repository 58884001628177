import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import { PureInput } from 'components/form/textField';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';

import { storeExamType } from 'stores/_mobx/systemSetup/masterSetting/examType';
import { MS_MODALITY as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_EXAM_TYPE_ADD,
  URL_EXAM_TYPE,
} from 'constant/path/systemSetup';

const renderControl = (id: number) => (
  <div className="control">
    <Link className="bi bi-pencil" to={`${URL_EXAM_TYPE}/edit/${id}`} />
    <IconButton onClick={() => storeExamType.setExamForDelete(id)}>
      <i className="bi bi-trash" />
    </IconButton>
  </div>
);

const COLUMNS = [
  { name: 'exam_type_nm', title: 'Modality Name' },
  { name: 'exam_type_desc', title: 'Modality Description' },
  {
    name: 'refid',
    title: 'Action',
    className: 'width-75',
    render: renderControl,
  },
];

const ExamTypeListPage = () => {
  const {
    fetching,
    examsTypeList,
    examsTypeTotal,
    examIdForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeExamType;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleChangeFilter = (examTypeName: string) => {
    storeExamType.setFilter(examTypeName);
    debouncedMethod();
  };

  const handleDeleteApprove = () => {
    storeExamType.deleteExmType().then((isSucceed) => {
      if (isSucceed) storeExamType.getExamsType();
    });
  };

  useEffect(() => {
    storeExamType.getExamsType();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle title="Modality" appId={PAGE_ID.PAGE}>
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Modality Name"
        className="col-sm-12 col-md-6 col-lg-4"
        name="modalityName"
        value={storeExamType.filter}
        onChange={handleChangeFilter}
      />

      <Grid
        columns={COLUMNS}
        onAjax={fetching}
        dataSource={examsTypeList}
        id="examTypeGrid"
        dataSourceCount={examsTypeTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_EXAM_TYPE_ADD} />
        }
      />
      {examIdForDelete !== null && (
        <DialogConfirm
          onCancel={storeExamType.clearExamForDelete}
          onApprove={handleDeleteApprove}>
          Are you sure you want to delete?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(ExamTypeListPage);
