import appDispatcher from 'dispatcher/AppDispatcher';

import StudiesByTechPageService from 'services/reports/report-creator/studies-by-tech/StudiesByTechPageService';
import { Actions } from 'stores/reports/report-creator/studies-by-tech/StudiesByTechPageStore';

export default class StudiesByTechPageActions {
  static loadStudiesByTechList(conditionList, joint) {
    return StudiesByTechPageService.loadStudiesByTechList(
      conditionList,
      joint
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_STUDIES_BY_TECH_LIST,
        data: response,
      });
    });
  }

  static createPdf(studiesByTechList, formatDescription) {
    return StudiesByTechPageService.createPdf(
      studiesByTechList,
      formatDescription
    );
  }
}
