import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeVehicle,
  VehicleFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/vehicle';
import { URL_VEHICLE } from 'constant/path/workflow';

interface PropsType extends RouteComponentProps {}

const VehicleAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: VehicleFormModel) =>
    storeVehicle.addVehicle(data).then((error) => {
      if (error) return error;
      history.push(URL_VEHICLE);
    });

  return (
    <>
      <Title title="Add Vehicle" />
      <Form onSubmit={handleSubmit} backUrl={URL_VEHICLE} />
    </>
  );
};

export default VehicleAddPage;
