import Notification from 'components/modal/Notification';
import { action, makeObservable, observable } from 'mobx';
import { defaultChannelValue } from 'page/system-setup/master-setup/masterSettings/integrations/DFT/components/form/Channels';
import { apiRequest } from 'services/RequestService';

export type DFTSettings = {
  sendOnBillingCompleted: boolean;
};

export type DFTChannel = {
  id: number;
  channel: {
    channelID: string;
  };
};

type APIChannelsResponse = {
  items: DFTChannel[];
  totalItems: number;
};

export type UpdateDFTSettingsPayload = {
  channels: DFTChannel[];
} & DFTSettings;

const initialDftSettings: DFTSettings = {
  sendOnBillingCompleted: false,
};
const initialChannels: DFTChannel[] = [];

const getChannelsToUpdate = (
  initialChannels: DFTChannel[],
  finalChannels: DFTChannel[]
) =>
  finalChannels
    .filter(({ channel: { channelID }, id }) => {
      if (!channelID) return false;
      return initialChannels.every(
        ({ channel, id: initId }) =>
          channel.channelID !== channelID || initId !== id
      );
    })
    .map(({ channel }) => channel.channelID);

const getChannelsForDelete = (
  initialChannels: DFTChannel[],
  finalChannels: DFTChannel[]
) =>
  initialChannels
    .filter(({ channel: { channelID }, id }) => {
      if (id === 0) return false;
      if (!channelID && id) return true;
      return finalChannels.every(
        ({ channel, id: finalId }) =>
          channel.channelID !== channelID || finalId !== id
      );
    })
    .map(({ id }) => id);

class IntegrationsDft {
  fetching: boolean = true;
  dftSettings: DFTSettings = initialDftSettings;
  channels: DFTChannel[] = initialChannels;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      dftSettings: observable,
      channels: observable,
      fetchDftSettings: action,
      setDftSettings: action,
      setChannels: action,
      setFetching: action,
      reset: action.bound,
    });
  }

  async fetchDftSettings() {
    try {
      this.fetching = true;
      const [settingsResponse, channelsResponse] = await Promise.all([
        apiRequest<DFTSettings>({
          url: 'hl7/integrations/settings/dft',
          method: 'GET',
          legacy: false,
          contentType: 'ld',
        }),
        apiRequest<APIChannelsResponse>({
          url: 'hl7/integrations/channels/dft',
          method: 'GET',
          legacy: false,
          contentType: 'ld',
        }),
      ]);
      this.setDftSettings(settingsResponse);
      this.setChannels(channelsResponse.items);
    } catch (error: unknown) {
      Notification.danger('Failed to fetch DFT settings');
      this.reset();
      throw error;
    } finally {
      this.setFetching(false);
    }
  }

  setDftSettings(settings: DFTSettings) {
    this.dftSettings = settings;
  }

  setChannels(channels: DFTChannel[]) {
    if (!channels.length) {
      this.channels = [ defaultChannelValue ];
      return;
    }
    this.channels = channels;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  reset() {
    this.setDftSettings(initialDftSettings);
    this.setChannels(initialChannels);
    this.setFetching(true);
  }

  getFormDefaultValues() {
    return {
      sendOnBillingCompleted: this.dftSettings.sendOnBillingCompleted,
      channels: this.channels,
    };
  }

  async update(
    dftSettings: UpdateDFTSettingsPayload
  ): Promise<undefined | null> {
    try {
      const channelsForDeletion = getChannelsForDelete(
        this.channels,
        dftSettings.channels
      );
      const channelsForAdding = getChannelsToUpdate(
        this.channels,
        dftSettings.channels
      );

      const poolOfRequests = [];
      if (channelsForDeletion.length) {
        poolOfRequests.push(
          apiRequest({
            url: 'hl7/integrations/channels/dft/batch/delete',
            method: 'POST',
            data: { ids: channelsForDeletion },
            legacy: false,
            contentType: 'ld',
          })
        );
      }

      if (channelsForAdding.length) {
        poolOfRequests.push(
          apiRequest({
            url: 'hl7/integrations/channels/dft/batch',
            method: 'POST',
            data: { ids: channelsForAdding },
            legacy: false,
            contentType: 'ld',
          })
        );
      }

      poolOfRequests.push(
        apiRequest({
          url: 'hl7/integrations/settings/dft',
          method: 'PATCH',
          data: dftSettings,
          legacy: false,
          contentType: 'ld',
        })
      );
      await Promise.all(poolOfRequests);
      Notification.success(`DFT settings updated successfully!`);
      return null;
    } catch (error) {
      Notification.danger('Failed to update DFT settings');
    } finally {
      this.reset();
    }
  }
}

export const storeDftSettings = new IntegrationsDft();
