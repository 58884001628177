import React from 'react';

import { downloadFile } from 'utils/downloadFile';
import { BASE_URL_FILE_DIR } from 'constant/config';

// TODO: Delete this method after bug wil fixed on the backend LPFE-1900
export const checkAndDownload =
  (fileName: string) => async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      const result = await fetch(
        `${BASE_URL_FILE_DIR}doc_img/documents/${fileName}`,
        {
          method: 'HEAD',
        }
      );
      if (result.status === 200) {
        downloadFile(`${BASE_URL_FILE_DIR}doc_img/documents/${fileName}`, true);
      } else {
        throw Error('');
      }
    } catch (error) {
      downloadFile(`${BASE_URL_FILE_DIR}facility/${fileName}`, true);
    }
  };
