import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import FormPayerPlan, { FormModel } from './components/Form';

import { storePayerPlan } from 'stores/_mobx/systemSetup/masterSetting/payerPlan';
import { URL_PAYER_PLAN } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}
interface PropsType extends RouteComponentProps<MatchParams> {}

const PayerPlanEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, payer } = storePayerPlan;

  const handleSubmit = (payload: FormModel) =>
    storePayerPlan
      .updatePayerPlan(payload as Required<FormModel>)
      .then((isSucceed) => {
        if (isSucceed) {
          history.push(URL_PAYER_PLAN);
        }
      });

  useEffect(() => {
    storePayerPlan.getPayerPlan(Number(params.id));

    return storePayerPlan.clearPayer;
  }, [params.id]);

  return (
    <>
      <Title title="Edit Payer Plan" />
      <FormPayerPlan
        defaultValues={payer || undefined}
        backUrl={URL_PAYER_PLAN}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(PayerPlanEditPage);
