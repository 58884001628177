import { memo } from 'react';

import { ProfileDetailsType } from 'stores/_mobx/profile/myProfile';

interface PropsType {
  details: ProfileDetailsType;
}

const GeneralInfo = ({ details }: PropsType) => (
  <div className="col-md-6">
    <dl className="row">
      <dt className="col-sm-6 col-lg-4">Salutation</dt>
      <dd className="col-sm-6 col-lg-8">{details?.salutation}</dd>

      <dt className="col-sm-6 col-lg-4">Last Name</dt>
      <dd className="col-sm-6 col-lg-8">{details?.last_name}</dd>

      <dt className="col-sm-6 col-lg-4">First Name</dt>
      <dd className="col-sm-6 col-lg-8">{details?.first_name}</dd>

      <dt className="col-sm-6 col-lg-4">Middle Name</dt>
      <dd className="col-sm-6 col-lg-8">{details?.middle_name}</dd>

      <dt className="col-sm-6 col-lg-4">Qualification</dt>
      <dd className="col-sm-6 col-lg-8">{details?.qualificationName}</dd>

      <dt className="col-sm-6 col-lg-4">Date Of Birth</dt>
      <dd className="col-sm-6 col-lg-8">{details?.dateofbirth}</dd>

      <dt className="col-sm-6 col-lg-4">Gender</dt>
      <dd className="col-sm-6 col-lg-8">{details?.genderName}</dd>
    </dl>
  </div>
);

export default memo(GeneralInfo);
