import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import { PureInput } from 'components/form/textField';
import { Button, IconButton } from 'components/button';
import { LayoutSideTitle } from 'components/layout';

import { storeGrid } from 'stores/_mobx/grid';

const columns = [
  { name: 'table_id', title: 'Grid ID' },
  {
    name: 'page_url',
    title: 'Grid page',
    render: (url: string) => <Link to={url}>{`/${url}`}</Link>,
  },
  { name: 'grid_size', title: 'Page Size', className: 'text-center' },
  {
    name: 'table_id',
    title: 'Control',
    classNam: 'text-center',
    render: (id: string) => (
      <div className="control justify-content-center">
        <IconButton onClick={() => storeGrid.setSettingForDelete(id)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const GridsSettingPage = () => {
  const { fetchingSettings, settings, settingsSearch, settingForDelete } =
    storeGrid;

  const [isClearSettings, setClearSettings] = useState<boolean>(false);

  const handleToggleClearAll = () => {
    setClearSettings((state) => !state);
  };

  const handleDelete = () => {
    const action = isClearSettings
      ? storeGrid.deleteAllGridSetting
      : storeGrid.deleteGridSetting;

    action().then((isSucceed) => {
      if (isSucceed) {
        if (isClearSettings) {
          setClearSettings(false);
        } else {
          storeGrid.clearSettingForDelete();
        }
        storeGrid.getGridSettings();
      }
    });
  };

  useEffect(() => {
    if (!fetchingSettings) storeGrid.getGridSettings();

    return () => {
      storeGrid.clearSettingForDelete();
      storeGrid.setSearchSettings('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LayoutSideTitle title="Grids Setting Page">
        <Button variant="danger" onClick={handleToggleClearAll}>
          Delete All Settings
        </Button>
      </LayoutSideTitle>

      <div className="row">
        <PureInput
          name="page_url"
          placeholder="Page Url"
          className="col-md-6 col-lg-4"
          value={settingsSearch}
          onChange={storeGrid.setSearchSettings}
        />
      </div>

      <Grid
        id="grids_settings_overview"
        columns={columns}
        onAjax={fetchingSettings}
        stateless
        dataSource={settings}
        disablePagination
      />

      {(settingForDelete || isClearSettings) && (
        <DialogConfirm
          onCancel={
            isClearSettings
              ? handleToggleClearAll
              : storeGrid.clearSettingForDelete
          }
          onApprove={handleDelete}>
          {isClearSettings
            ? 'Are you sure you want to delete setting for all grids?'
            : `Are you sure you want to delete setting for the grid ${settingForDelete}?`}
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(GridsSettingPage);
