import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';
import Tabs from 'components/tabs';
import Settings, { checkIsSomeEnabled } from './Settings';
import Facilities from './Facilities';
import RadiologyGroups from './RadiologyGroups';
import PhysicianGroup from './PhysicianGroup';
import resolver from './validation';

import {
  storeIntegrations,
  initialIntegrationDetails,
  IntegrationDetailsType
} from 'stores/_mobx/systemSetup/masterSetting/integrations';
import { ServerFormValidationType } from 'types';

const tabsList = [
  { label: 'Settings', url: `?tab=settings` },
  { label: 'Facilities', url: `?tab=facilities` },
  { label: 'Radiology Groups', url: `?tab=radiology-groups` },
  { label: 'Physician Groups', url: `?tab=physician-groups` },
];

interface PropsType {
  fetching?: boolean;
  defaultValues?: IntegrationDetailsType;
  onSubmit: (payload: IntegrationDetailsType) => Promise<any>;
}

const Form = ({
  fetching,
  defaultValues = initialIntegrationDetails,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting, errors },
    setValue,
    setError,
    trigger,
    watch,
    reset,
    handleSubmit,
  } = useForm<IntegrationDetailsType>({ defaultValues, resolver });

  const history = useHistory();

  const handleClickReset = useCallback(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleClickSubmit = handleSubmit((data: IntegrationDetailsType) =>
    onSubmit(data).then(
      (errors: ServerFormValidationType<IntegrationDetailsType>[]) => {
        if (errors)
          errors.forEach(({ field, ...body }) => {
            setError(field, body);
          });
      }
    )
  );

  useEffect(() => {
    if (Object.keys(errors).length) {
      history.push(tabsList[0].url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name?.startsWith('ormSettings.')) {
        const isSettingsEnabled = checkIsSomeEnabled(formValue.ormSettings);

        if (!isSettingsEnabled)
          setValue('ormChannels', defaultValues.ormChannels);
        trigger('orderType');
      } else if (name?.startsWith('oruSettings.')) {
        const isSettingsEnabled = checkIsSomeEnabled(formValue.oruSettings);

        if (!isSettingsEnabled)
          setValue('oruChannels', defaultValues.oruChannels);
      }
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, watch]);

  useEffect(() => {
    handleClickReset();
  }, [handleClickReset]);

  useEffect(() => {
    return storeIntegrations.clearIntegrationDetails;
  }, []);

  return (
    <form
      className={fetching || isSubmitting ? 'on-ajax' : undefined}
      onSubmit={handleClickSubmit}>
      <Tabs tabsList={tabsList} className="mb-5">
        <Settings control={control} watch={watch} />
        <Facilities control={control} />
        <RadiologyGroups control={control} />
        <PhysicianGroup control={control} />
      </Tabs>

      <ControlsLayout alignX="right">
        <Button
          type="reset"
          text="Reset"
          variant="warning"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button type="submit" text="Save" disabled={fetching || !isDirty} />
      </ControlsLayout>
    </form>
  );
};

export default Form;
