import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import { Button } from 'components/button';
import Exporter from 'components/project/ExporterNew';
import Filter from './components/Filter';

import UserProfileStore from 'stores/UserProfileStore';
import {
  storeSummaryLocation,
  FilterType,
} from 'stores/_mobx/report/summaryLocation';
import { SUMMARY_LOCATION as PAGE_ID } from 'constant/pagesId/reports';

const columns = [
  { name: 'facility', title: 'Facility' },
  { name: 'state', title: 'State' },
  { name: 'location', title: 'Location' },
  { name: 'pos', title: 'POS' },
  { name: 'modality', title: 'Modality' },
  { name: 'technologist', title: 'Technologist' },
  { name: 'count_of_visit', title: 'Count of Visit' },
  { name: 'patient_seen_count', title: 'Count of Patient Seen' },
  { name: 'procedure_count', title: 'Count of Procedures' },
  { name: 'cancelled_order_count', title: 'Count of Cancelled Orders' },
  { name: 'incomplete_orders', title: 'Count of Incomplete Orders' },
];

const SummaryLocationPage = () => {
  const {
    fetching,
    logList,
    logTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeSummaryLocation;

  const gridRef = useRef<Grid>(null);

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const appName = useMemo(
    () =>
      UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
      'SummaryLocationLogs',
    []
  );

  const handleClickExport = () => {
    toggleExport((state) => !state);
  };

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleClickSearch = (payload: FilterType) => {
    storeSummaryLocation.setFilter(payload);
    setPaginationToStart();
  };

  useLayoutEffect(() => {
    storeSummaryLocation.setDefaultFilter();
  }, []);

  useEffect(() => {
    storeSummaryLocation.getLogs();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Geographic Performance">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        fetching={fetching}
        isFilterVisible={isFilterVisible}
        onSearch={handleClickSearch}
      />

      <Grid
        id="summary_location_gridId"
        noDataText="Please refine your search using the filters above"
        gridControlPanel={
          <GridControlButton
            title="Export"
            disabled={!logTotal}
            onClick={handleClickExport}
          />
        }
        columns={columns}
        ref={gridRef}
        onAjax={fetching}
        dataSource={logList}
        dataSourceCount={logTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      {isExportVisible && (
        <Exporter
          reportName={appName}
          gridRef={gridRef}
          columns={columns}
          dataSource={logList}
          filter={storeSummaryLocation.filter}
          onClose={handleClickExport}
        />
      )}
    </>
  );
};

export default observer(SummaryLocationPage);
