import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Observer } from 'mobx-react-lite';

import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { CheckboxGroup } from 'components/form/checkbox';
import { Textarea } from 'components/form/textarea';
import { Button } from 'components/button';
import resolver from './validation';

import { ProviderFormModel } from 'stores/_mobx/systemSetup/masterSetting/providerType';
import { storeDropdownOrderType } from 'stores/_mobx/dropDown/orderType';

const initialValues: ProviderFormModel = {
  type: '',
  description: '',
  id: null,
  orderId: { 3: true },
};

interface PropsType {
  backUrl: string;
  defaultValues?: ProviderFormModel;
  onSubmit: (data: ProviderFormModel) => Promise<any>;
}

const ProviderTypeForm = ({
  defaultValues = initialValues,
  backUrl,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm({ defaultValues, resolver });

  const handleClickSubmit = handleSubmit((data: ProviderFormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('type', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  const handleReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useEffect(() => {
    storeDropdownOrderType.getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleClickSubmit} className="row">
      <Input
        name="type"
        label="Facility type"
        className="col-lg-8 col-xl-6"
        required
        control={control}
      />
      <div />

      <div className="col-md-6 col-lg-4">
        <Observer>
          {() => (
            <CheckboxGroup
              name="orderId"
              label="Order Type"
              required
              options={storeDropdownOrderType.options}
              fetching={storeDropdownOrderType.fetching}
              control={control}
            />
          )}
        </Observer>
      </div>

      <Textarea
        name="description"
        label="Description"
        className="col-md-6 col-lg-8"
        required
        rows={6}
        control={control}
      />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default ProviderTypeForm;
