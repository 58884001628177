import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    code: yup.string().required('Please, enter the code!'),
    facility_price: yup.number().typeError('Please, enter the facility price!'),
    insurance_price: yup
      .number()
      .typeError('Please, enter the insurance price!'),
    no_of_patient: yup
      .number()
      .typeError('Please, enter the number of the patients!')
      .test(
        'code-check',
        'Patients number is not satisfy transportation code',
        (value, { parent }) => {
          if (parent.code === 'R0075') {
            return value >= 2 && value <= 6;
          } else if (parent.code === 'R0070') {
            return value === 1;
          }
          return true;
        }
      ),
    region_id: yup
      .number()
      .nullable()
      .positive()
      .required('Please, select a jurisdiction!'),
    stateid: yup
      .number()
      .nullable()
      .positive()
      .required('Please, select a State!'),
  })
);

export default validation;
