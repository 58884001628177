import React, { useState } from 'react';

import useRequest from 'components/hook/useRequest';
import CommonLink from './CommonLink';

import { exportFacility } from 'stores/_mobx/export';
import { downloadFile } from 'utils/downloadFile';
import { BASE_URL_FILE_DIR } from 'constant/config';

interface PropsType {
  id: number;
}

const FacilityExportLink = ({ id }: PropsType) => {
  const [link, setLink] = useState<string>('/');

  const { fetching, controlledAction } = useRequest<number>(exportFacility);

  const handleClick = (e: React.SyntheticEvent) => {
    if (link === '/') {
      e.preventDefault();
      controlledAction(id).then((url) => {
        if (url) {
          const link = `${BASE_URL_FILE_DIR}facility/${url}`;
          setLink(link);
          downloadFile(link, true);
        }
      });
    }
  };

  return <CommonLink link={link} fetching={fetching} onClick={handleClick} />;
};

export default FacilityExportLink;
