import { Container } from 'flux/utils';

import AbstractPatientRecordPage, {
  SAbstractPatientRecordPage,
} from './AbstractPatientRecordPage';
import Notification from 'components/modal/Notification';
import PatientRecordPageActions from 'actions/patient-records/patient-demographics/PatientRecordPageActions';
import PatientRecordPageStore from 'stores/patient-records/patient-demographics/PatientRecordPageStore';
import { PATIENT_DEMOGRAPHIC as PAGE_ID } from 'constant/pagesId/patientRecords';
import { URL_PATIENT_DEMOGRAPHIC_EDIT } from 'constant/path/patientRecords';

export class AddPatientRecordPage extends AbstractPatientRecordPage {
  static getStores() {
    return [PatientRecordPageStore];
  }

  submitPatientInfo(patientInfo: any): Promise<any> {
    if (
      !patientInfo.first_name ||
      !patientInfo.last_name ||
      !patientInfo.facility_id ||
      !patientInfo.birthdate
    ) {
      Notification.danger('Please fill in all required fields!');
      return;
    }

    this.setState({ onAjax: true })

    PatientRecordPageActions.savePatientRecord(patientInfo).then((response) => {
      this.setState({ onAjax: false });

      if (response >= 0) {
        Notification.success('Successfully added!');
        this.props.history.push(
          // @ts-ignore
          URL_PATIENT_DEMOGRAPHIC_EDIT.replace(':id', response)
        );
      } else {
        Notification.info('Ar error occurred!');
      }
      return response;
    });
  }

  submitGeneralInfo(generalInfo: any): Promise<any> {
    return null;
  }

  submitFacilityInfo(facilityInfo: any): Promise<any> {
    return null;
  }

  submitContactInfo(contactInfo: any): Promise<any> {
    return null;
  }

  submitEmployerInfo(employerInfo: any): Promise<any> {
    return null;
  }

  submitPatientStatusInfo(patientStatusInfo: any): Promise<any> {
    return null;
  }

  submitGuarantorInfo(guarantorInfo: any): Promise<any> {
    return null;
  }

  submitInsuredInfo(insuredInfo: any): Promise<any> {
    return null;
  }

  submitCommentInfo(commentInfo: any): Promise<any> {
    return null;
  }

  static calculateState(prevState: SAbstractPatientRecordPage) {
    if (!prevState) {
      prevState = AbstractPatientRecordPage.getFormState();
      prevState.patientRecord = PatientRecordPageStore.getState().patientRecord;

      prevState.generalInfo = PatientRecordPageStore.getState().generalInfo;
      prevState.generalInfoEditable = true;

      prevState.facilityInfo = PatientRecordPageStore.getState().facilityInfo;
      prevState.facilityInfoEditable = true;

      prevState.contactInfo = PatientRecordPageStore.getState().contactInfo;
      prevState.contactInfoEditable = true;

      prevState.employerInfo = PatientRecordPageStore.getState().employerInfo;
      prevState.employerInfoEditable = true;

      prevState.patientStatusInfo =
        PatientRecordPageStore.getState().patientStatusInfo;
      prevState.patientStatusInfoEditable = true;

      prevState.guarantorInfo = PatientRecordPageStore.getState().guarantorInfo;
      prevState.guarantorInfoEditable = true;

      prevState.insuredInfo = PatientRecordPageStore.getState().insuredInfo;
      prevState.primaryInsuredEditable = true;
      prevState.secondaryInsuredEditable = true;
      prevState.tertiaryInsuredEditable = true;

      prevState.commentInfo = PatientRecordPageStore.getState().commentInfo;
      prevState.commentInfoEditable = true;

      prevState.mode = 1; //add mode

      prevState.patientRecordLoaded = true;
    }
    return prevState;
  }

  getPageAppId() {
    return PAGE_ID.ADD;
  }
}

const addPatientRecordPage = Container.create(AddPatientRecordPage);
export default addPatientRecordPage;
