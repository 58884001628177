import { ReduceStore } from 'flux/utils';
import appDispatcher from 'dispatcher/AppDispatcher';
import { dateToLocalTimezone } from 'utils/DateUtils';

let buildActionName = function (name) {
  return 'reports/custom-reports/physician-patient/' + name;
};

export let Actions = {
  LOAD_PHYSICIAN_PATIENT_LIST: buildActionName('load-physician-patient-list'),
};

class PhysicianPatientPageStore extends ReduceStore {
  getEmptyDiagram() {
    return [['Data', 'Value']];
  }

  getInitialState() {
    return {
      dataSource: [],
      chart: this.getEmptyDiagram(),
    };
  }

  reduce(state, action) {
    switch (action.type) {
      case Actions.LOAD_PHYSICIAN_PATIENT_LIST: {
        const logs = (action.data.recordval || []).map((log) => ({
          ...log,
          dos: dateToLocalTimezone({ date: log.dos, dateOnly: true }),
        }));
        this.getState().dataSource = logs;
        this.getState().chart = action.data.chartval || this.getEmptyDiagram();
        break;
      }
      default:
        break;
    }
    return this.getState();
  }

  areEqual(one, two) {
    return false;
  }
}

const physicianPatientPageStore = new PhysicianPatientPageStore(appDispatcher);
export default physicianPatientPageStore;
