import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Switcher } from 'components/form/switcher';

import { AppSettingsStateFormModel } from 'stores/_mobx/systemSetup/masterSetting/appSettingsMaster';

interface PropsType {
  defaultValues: AppSettingsStateFormModel;
  onSubmit: (payload: AppSettingsStateFormModel) => Promise<any>;
}

const Form = ({ defaultValues, onSubmit }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
  } = useForm<AppSettingsStateFormModel>();

  const handleClickReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-5">
        <legend>Features</legend>
        <small className="mb-4 d-block text-secondary">
          Divisions allows LatticePro to segregate groups of orders by division.
          Divisions are then set on a facility in the Facility Master edit page.
          Once the Facility division is set all orders placed with the facility
          can be filtered by a division.
        </small>
        <Switcher name="divisionState" label="Divisions" control={control} />
      </div>

      <div>
        <legend>Eligibility Manager Export</legend>
        <small className="mb-4 d-block text-secondary">
          Select the export types needed for Eligibility export in the Eligibility Manager.
          The selected exports will then be available for use in Eligibility Manager.
          <br />
          These third party exports can be used to send eligibility information to
          third party vendors.
        </small>
        <Switcher name="eligibilityState.experianState" label="Experian" control={control} />
        <Switcher name="eligibilityState.availityState" label="Availity Healthia Exchange" control={control} />
      </div>

      <ControlsLayout alignX="right">
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button type="submit" text="Save" disabled={!isDirty || isSubmitting} />
      </ControlsLayout>
    </form>
  );
};

export default Form;
