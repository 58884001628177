import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormAdjustmentCode from './components/Form';

import {
  storeAdjustmentCode,
  CodeType,
} from 'stores/_mobx/systemSetup/masterSetting/adjustmentCode';
import { URL_ADJUSTMENT_CODE } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const AdjustmentCodeAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: CodeType) =>
    storeAdjustmentCode.addCode(data).then((error) => {
      if (error) return error;
      history.push(URL_ADJUSTMENT_CODE);
    });

  return (
    <>
      <Title title="Add Adjustment Code" />
      <FormAdjustmentCode
        backUrl={URL_ADJUSTMENT_CODE}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default AdjustmentCodeAddPage;
