import { storeDropdownTaxonomy } from 'stores/_mobx/dropDown/taxonomy';
import { storeDropdownState } from 'stores/_mobx/dropDown/state';
import { NpiType } from 'stores/_mobx/npi';
import { storePrefix } from 'stores/_mobx/systemSetup/masterSetting/prefix';
import { storeSpecialty } from 'stores/_mobx/systemSetup/masterSetting/specialty';
import { PhysicianFormType } from 'stores/_mobx/clinicianManager/physician';

const capitalizeString = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

const zipFormat = (zipCode: string) =>
  /^(\d{5}|\d{9})$/.test(zipCode)
    ? zipCode.slice(0, 5) + '-' + zipCode.slice(5)
    : zipCode;

const physicianPrettier = (physician: NpiType) => {
  const primaryPracticeAddress = physician.addresses[1];
  const mailingAddress = physician.addresses[0];
  const basicInfo = physician.basic;
  const taxonomies = physician.taxonomies[0];
  const stateShortName = taxonomies?.state || primaryPracticeAddress?.state;
  const state = storeDropdownState.findByShortName(stateShortName)?.value || 0;
  const taxonomy_code: number = taxonomies?.code
    ? storeDropdownTaxonomy.findOptionByLabel(taxonomies.code)?.value || 0
    : 0;
  const specialty = taxonomy_code
    ? storeSpecialty.findOptionById(taxonomy_code)?.value || 0
    : 0;
  const prefix = basicInfo?.name_prefix
    ? basicInfo.name_prefix.replace(/\./g, '')
    : '';
  const salutation = prefix ? storePrefix.findByLabel(prefix)?.value || 0 : 0;

  const flattedData = {
    address: primaryPracticeAddress?.address_1 || '',
    city: capitalizeString(primaryPracticeAddress?.city || ''),
    fax: primaryPracticeAddress?.fax_number || '',
    phone: primaryPracticeAddress?.telephone_number.replaceAll('-', '') || '',
    zipCode: primaryPracticeAddress?.postal_code || '',
    state,

    contactAddress: mailingAddress?.address_1 || '',
    contactCity: mailingAddress?.city || '',
    contactPhone: mailingAddress?.telephone_number.replaceAll('-', '') || '',
    contactZipCode: mailingAddress?.postal_code || '',
    contactState: mailingAddress?.state || '',

    lastName: capitalizeString(basicInfo?.last_name || ''),
    firstName: capitalizeString(basicInfo?.first_name || ''),
    middleName: capitalizeString(basicInfo?.middle_name || ''),
    gender: basicInfo?.gender || ('' as ''),

    npi: String(physician.number),

    license: taxonomies?.license || '',
    salutation,
    taxonomy_code,
    specialty,
  };

  return flattedData;
};

export const physicianDetailsPrettier = (
  { addresses, basic, taxonomies, ...rest }: NpiType,
  formData: PhysicianFormType
) => {
  const primaryPractice = {
    ...formData.primaryPractice,
    address: addresses[1].address_1 || formData.primaryPractice.address,
    city: addresses[1].city || formData.primaryPractice.city,
    state: addresses[1].state || formData.primaryPractice.state,
    zipCode:
      zipFormat(addresses[1].postal_code) || formData.primaryPractice.zipCode,
    phone: addresses[1].telephone_number || formData.primaryPractice.phone,
  };
  const contact = {
    ...formData.contact,
    address: addresses[0].address_1 || formData.contact.address,
    city: addresses[0].city || formData.contact.city,
    state: addresses[0].state || formData.contact.state,
    zipCode: zipFormat(addresses[0].postal_code) || formData.contact.zipCode,
    phone: addresses[0].telephone_number || formData.contact.phone,
  };

  const salutation = basic?.name_prefix
    ? basic.name_prefix.replace(/\./g, '')
    : '';

  const prefix = salutation
    ? storePrefix.findByLabel(salutation)?.value || 0
    : 0;

  const taxonomyCode: number = taxonomies[0]?.code
    ? storeDropdownTaxonomy.findOptionByLabel(taxonomies[0]?.code)?.value || 0
    : 0;

  const specialty = taxonomyCode
    ? storeSpecialty.findOptionById(taxonomyCode)?.value || 0
    : 0;

  const state =
    storeDropdownState.findByShortName(taxonomies[0]?.state)?.value || 0;

  const physician = {
    ...formData,
    prefix,
    lastName: capitalizeString(basic?.last_name || ''),
    firstName: capitalizeString(basic?.first_name || ''),
    middleName: capitalizeString(basic?.middle_name || ''),
    gender: basic?.gender || '',
    licensor: {
      ...formData.licensor,
      npi: rest.number,
      taxonomyCode,
      specialty,
      state,
      licenseNumber: taxonomies[0]?.license,
    },
    primaryPractice,
    contact,
  };
  return physician;
};

export default physicianPrettier;
