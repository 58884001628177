import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionResponse {
  label: string;
  data: string;
}

export interface OptionType {
  value: number;
  label: string;
}

interface RequestPayload {
  isCompanyUserType: boolean;
  userTypeShortName: string;
  userTypeId: number;
}

class Users {
  fetchingRegular: boolean = false;
  fetchingTech: boolean = false;
  optionsRegularUsers: OptionType[] = [];
  optionsCompanyUsers: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetchingRegular: observable,
      fetchingTech: observable,
      optionsRegularUsers: observable,
      optionsCompanyUsers: observable,

      fetching: computed,

      setFetchingRegular: action,
      setFetchingTech: action,
    });
  }

  setFetchingRegular = (fetching: boolean) => {
    this.fetchingRegular = fetching;
  };

  setFetchingTech = (fetching: boolean) => {
    this.fetchingTech = fetching;
  };

  get fetching() {
    return this.fetchingRegular || this.fetchingTech;
  }

  getOptions = async ({
    isCompanyUserType,
    userTypeShortName,
    userTypeId,
  }: RequestPayload) => {
    if (isCompanyUserType) {
      return this.fetchingTech
        ? Promise.resolve([])
        : this.getCompanyUsersOption({ userTypeShortName, userTypeId });
    } else {
      return this.fetchingRegular
        ? Promise.resolve([])
        : this.getRegularUsersOption({ userTypeShortName, userTypeId });
    }
  };

  async getRegularUsersOption({
    userTypeShortName,
    userTypeId,
  }: Omit<RequestPayload, 'isCompanyUserType'>) {
    this.setFetchingRegular(true);

    try {
      const data = {
        userTypeName: userTypeShortName,
        facilityId: 0,
        userTypeId,
      };

      const list = await apiRequest<OptionResponse[]>({
        url: 'permission.PermissionInfo.LoadUserList',
        data,
      });

      const optionsRegularUsers = list.map((user) => ({
        label: user.label.replace(/\s-\s$/, ''),
        value: Number(user.data),
      }));

      runInAction(() => {
        this.fetchingRegular = false;
        this.optionsRegularUsers = optionsRegularUsers;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingRegular = false;
        this.optionsRegularUsers = [];
      });
    }
  }

  async getCompanyUsersOption({
    userTypeShortName,
    userTypeId,
  }: Omit<RequestPayload, 'isCompanyUserType'>) {
    this.setFetchingTech(true);

    try {
      const data = {
        userTypeName: userTypeShortName,
        userTypeId,
      };

      const list = await apiRequest<OptionResponse[]>({
        url: 'permission.PermissionInfo.LoadUserListofTech',
        data,
      });

      const optionsCompanyUsers = list.map((user) => ({
        label: user.label.replace(/\s-\s$/, ''),
        value: Number(user.data),
      }));

      runInAction(() => {
        this.fetchingTech = false;
        this.optionsCompanyUsers = optionsCompanyUsers;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingTech = false;
        this.optionsCompanyUsers = [];
      });
    }
  }
}

export const storeUsers = new Users();
