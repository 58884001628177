import { apiRequest } from 'services/RequestService';

class TechExamCountPageService {
  loadTechExamCountList(conditionList, joint) {
    return apiRequest({
      url: 'report.ReportMaster.GetExamCounByTech',
      data: [conditionList, joint],
    }).then((response) => {
      if (response && response.chartval) {
        var newChartValues = [];
        newChartValues.push(['Data', 'Value']);
        let chartValues = response.chartval;
        chartValues.forEach((item) => {
          newChartValues.push([item.lbl, parseInt(item.amt)]);
        });
        response.chartval = newChartValues;
        return response;
      } else {
        return {};
      }
    });
  }

  createPdf(techExamCountList, formatDescription) {
    return apiRequest({
      url: 'pdfcreater.OrderPdfGenerator.GeneratePDFExamCountByTech',
      data: [techExamCountList, formatDescription],
    });
  }
}

export default new TechExamCountPageService();
