import { Container } from 'flux/utils';

import Text from 'components/form/input/Text';
import Radio from 'components/form/input/Radio';
import Select from 'components/form/input/Select';
import { DateRangeLegacy } from 'components/form/dateRange';
import Notification from 'components/modal/Notification';
import Form from 'components/form/Form';
import RadiologyDropdown from 'components/project/dropdown/RadiologyDropdown';
import ExamTypeDropdown from 'components/project/dropdown/ExamTypeDropdown';
import ProviderTypeDropdown from 'components/project/dropdown/ProviderTypeDropdown';
import LocationDropdown from 'components/project/dropdown/LocationDropdown';
import StateDropdown from 'components/project/dropdown/StateDropdown';
import PlaceOfServiceDropdown from 'components/project/dropdown/PlaceOfServiceDropdown';
import AbstractClaim, {
  PAbstractClaim,
  SAbstractClaim,
  TAbstractClaimFilter,
} from 'page/billing-coding/claimManager/AbstractClaim';
import PayerNameDropdown from 'components/project/dropdown/PayerNameDropdown';
import OrderTypeDropdown from 'components/project/dropdown/OrderType';
import FacilityDropdown from 'components/project/dropdown/FacilityDropdown';
import { DropdownPureDivision } from 'components/project/dropdown/Division';
import { GridControlButton } from 'components/grid';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import ClaimsAuditActions, {
  TClaimManagerFilterExt,
} from 'actions/billing-coding/claims/ClaimsAuditActions';
import { TClaimManager } from 'services/billing-coding/claims/ClaimsService';
import ClaimsAuditStore from 'stores/billing-coding/claims/ClaimsAuditStore';
import { storeGrid } from 'stores/_mobx/grid';

const GRID_ID = 'claims_audit_grid';

const className = 'col-sm-12 col-md-6 col-lg-3';

const CLAIM_SELECT_OPTIONS = [
  { value: 'T', label: 'Fee for service' },
  { value: 'P', label: 'Purchased Services' },
];

const CLAIM_TYPE_OPTIONS = [
  { value: 'T', label: 'Technical' },
  { value: 'P', label: 'Professional' },
];

const dropdownAttributes = {
  isMulti: true,
  isStyleDependsOnValue: true,
};

const columnsVisibleByDefault = [
  'facility_name',
  'patientLastNm',
  'patientFirstNm',
  'dos',
  'radiologist',
  'insImg',
  'facImg',
  'zipImg',
  'doctor_pop_img',
  'dobImg',
  'posImg',
  'viewClaim',
  'examCode',
  'encounterLog',
  'division',
];

export interface PClaimsAudit extends PAbstractClaim {}

export class SClaimsAudit extends SAbstractClaim {
  dataSource: Array<TClaimManager> = [];
  dataSourceCount: number = 0;
}

class ClaimsAudit extends AbstractClaim<PClaimsAudit, SClaimsAudit> {
  static getStores() {
    return [ClaimsAuditStore];
  }

  static calculateState(prevState: SClaimsAudit) {
    if (!prevState) {
      prevState = {
        ...AbstractClaim.getDefaultState(GRID_ID),
        filter: storeGrid.getFilter(
          GRID_ID,
          new TAbstractClaimFilter()
        ) as TAbstractClaimFilter,
      };
    }
    const store = ClaimsAuditStore.getState();
    return {
      ...prevState,
      dataSource: store.dataSource,
      dataSourceCount: store.dataSourceCount,
    };
  }

  componentWillUnmount() {
    storeGrid.saveFilter(GRID_ID, this.state.filter);
  }

  isProfessional() {
    return this.state.filter.claimType === 'P';
  }

  getTitle() {
    return 'Claim Audit';
  }

  getGridId() {
    return GRID_ID;
  }

  hideLeftGrid() {
    return false;
  }

  handleChangeFilter = (name: string, value?: string) => {
    const filter = { ...this.state.filter, [name]: value };

    this.setState({ filter });
  };

  handleChangeDatePeriod = (filter: SAbstractClaim['filter']) => {
    this.setState({ filter });
  };

  getFilterComponent() {
    const { isFilterVisible } = this.props;

    const { claimType, division } = this.state.filter;

    return (
      isFilterVisible && (
        <Form
          onCollectValues={this.handleChangeFilter}
          model={this.state.filter}
          submit={this.handleApplyFilter}>
          <Text name="lastName" className={className} />
          <Text name="firstName" className={className} />
          <ExamTypeDropdown
            name="examType"
            label="Modality"
            optionsForOrderOnly
            className={className}
            attr={dropdownAttributes}
          />
          <ProviderTypeDropdown
            name="providerType"
            className={className}
            attr={dropdownAttributes}
          />

          <FacilityDropdown
            name="facility"
            className={className}
            attr={dropdownAttributes}
          />
          <LocationDropdown
            name="location"
            className={className}
            attr={dropdownAttributes}
          />
          <StateDropdown
            name="state"
            className={className}
            attr={dropdownAttributes}
          />
          <PlaceOfServiceDropdown
            name="pos"
            label="Place of Service"
            className={className}
            attr={dropdownAttributes}
          />
          <>
            <DropdownPureDivision
              name="division"
              label="Division"
              className={className}
              value={division}
              onChange={this.handleChangeDivision}
            />
          </>
          <DateRangeLegacy
            nameFrom="dosStart"
            nameTo="dosEnd"
            nameRadio="period"
            onChange={this.handleChangeDatePeriod}
          />
          <div />
          <RadiologyDropdown
            name="radiologyId"
            label="Radiology Group"
            className={className}
            attr={dropdownAttributes}
          />
          <OrderTypeDropdown
            name="orderType"
            className="col-sm-12 col-md-6 col-lg-3"
            attr={dropdownAttributes}
          />
          <Text
            name="cpt"
            label="CPT Code"
            className="col-sm-12 col-md-6 col-lg-3"
          />
          <PayerNameDropdown
            name="payerName"
            label="Primary Payer"
            className="col-sm-12 col-md-6 col-lg-3"
            attr={dropdownAttributes}
          />
          <div />
          <Radio
            name="claimType"
            className={'part-inline ' + className}
            label="Claim Type"
            options={CLAIM_TYPE_OPTIONS}
          />
          {claimType === 'P' ? (
            <Select
              name="billProcess"
              options={CLAIM_SELECT_OPTIONS}
              className={className}
            />
          ) : null}
          <ControlsLayout alignX="auto">
            <Button
              variant="warning"
              text="Reset"
              onClick={this.handleResetFilter}
            />
            <Button text="Apply" type="submit" data-testid="submit" />
          </ControlsLayout>
        </Form>
      )
    );
  }

  updateData() {
    const { isDivisionEnabled } = this.props;
    const { filter, pagination } = this.state;

    this.setState({ onAjax: true }, () => {
      ClaimsAuditActions.loadOverview(
        Object.assign(new TClaimManagerFilterExt(), filter),
        pagination,
        isDivisionEnabled
      ).then(this.updateDataCallback);
    });
  }

  moveTo = () => {
    const { selected } = this.state;
    if (!selected.length) {
      Notification.warning('Please select at least one order');
    } else {
      this.setState({ showMoveTo: true });
    }
  };

  getGridActions() {
    const label =
      this.state.filter.claimType === 'T' ? 'Technical' : 'Professional';
    return (
      <GridControlButton title={`Move to ${label}`} onClick={this.moveTo} />
    );
  }

  closeMoveTo(value: boolean) {
    const state = { showMoveTo: false };
    if (value) {
      ClaimsAuditActions.moveTcPc(
        this.state.selected,
        this.state.filter.claimType
      ).then((v) => {
        this.setState(state, () => {
          this.updateData();
        });
      });
    } else {
      this.setState(state);
    }
  }

  handleResetFilter = () => {
    const filter = new TAbstractClaimFilter();
    const pagination = {
      ...this.state.pagination,
      page: 1,
      skip: 0,
    };
    this.setState({ pagination, appliedClaimType: filter.claimType, filter }, this.updateData);
  };

  getStartShownColumns(): Map<string, boolean> {
    const out = new Map<string, boolean>();
    const columns = this.getColumns();
    for (let i = 0; i < columns.length; i++) {
      const name = columns[i].name;
      if (!columnsVisibleByDefault.includes(name)) {
        out.set(name, false);
      }
    }
    return out;
  }
}

export default Container.create(ClaimsAudit);
