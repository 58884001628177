import { useMemo } from 'react';

import { checkAndDownload } from './helpers';

import { Grid } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';
import { BASE_URL_FILE_DIR } from 'constant/config';

const columns = [
  {
    title: 'File',
    name: 'fileName',
    render: (fileName: string) => (
      <a
        href={`${BASE_URL_FILE_DIR}doc_img/documents/${fileName}`}
        onClick={checkAndDownload(fileName)}
        target="_blank"
        rel="noreferrer">
        {fileName.replaceAll('_', ' ')}
      </a>
    ),
  },
];

interface PropsType {
  files: string[];
  onClose: () => void;
}

const DialogFilesList = ({ files, onClose }: PropsType) => {
  const sourceData = useMemo(
    () => files.map((fileName) => ({ fileName })),
    [files]
  );

  return (
    <Dialog>
      <DialogHeader title="Available Files" onClose={onClose} />
      <DialogBody>
        <Grid
          noControl
          disablePagination
          dataSource={sourceData}
          dataSourceCount={files.length}
          columns={columns}
        />
      </DialogBody>
    </Dialog>
  );
};

export default DialogFilesList;
