import { apiRequest } from 'services/RequestService';
import { TUserTypeEnum } from 'services/project/UserSelectionService';

export class TSignOrderFilter {
  coperate_Id: number = null;
  srchLastName: string = '';
  srchDate: string = '';
  fromDate: string = '';
  toDate: string = '';
  userType: TUserTypeEnum = null;
  facilityId: number = null;
  userId: number = null;
  srchFirstName: string = '';
  stateId: number = null;
  locId: number = null;
  posId: number = null;
  signed: string = '';
  dosSearch: string = '';
  physicianId: number = null;
  physicianGroupID: number = null;
  pcpId: number = null;
  companyClient: number = null;
  tab: string = '';
  clinicianGroupId: number = null;
  //
  startIndex: number = 0;
  limit: number = 10;
}

export interface TOrderCpt {
  accession_number: string;
  cpt_code: string;
  cpt_desc: string;
  cpt_description: string;
  external_accession_number: string;
  external_placer_order_number: string;
  extremity: string;
  flag_need_addendum: boolean;
  need_addendum_from_radiology_group_id: number | null;
  order_initial_cpt_refid: number | null;
  refid: number | null;
  session_no: string;
  transcription_id?: string | null;
}

export class TSignOrderItem {
  refid: number = null;
  countDistinctOrderRefid: string = '';
  ordergp: string = '';
  patient_comment: string = '';
  patient_alert: string = '';
  alert_facility: string = '';
  hasLog: string = '';
  assign_providergrpid: string = '';
  assign_providerid: string = '';
  hasMedicalLog: string = '';
  medicare_id: string = '';
  medicaid_id: string = '';
  medicaid_stateid: string = '';
  number_of_patient: string = '';
  coding_status: string = '';
  priority_nm: string = '';
  row_color: string = '';
  exam_symptoms: string = '';
  spoke_with: string = '';
  inmateNumber: string = '';
  immigrationNumber: string = '';
  iceNumber: string = '';
  acknowledgement: 'Y' | 'N' | '' = '';
  group_affiliation_name: string = '';
  group_affiliation_description: string = '';
  patient_diff_phone: string = '';
  patient_diff_address: string = '';
  patient_diff_city: string = '';
  patient_diff_state: string = '';
  patient_diff_zipcode: string = '';
  orderType: string = '';
  order_based_type: string = '';
  sessionno: string = '';
  external_accession_number: string = '';
  accessionNumbersStr: string = '';
  accessionNumbersStrForReport: string = '';
  providerName: string = '';
  providerid: number = null; // if empty - order was not placed finally
  physicianname: string = '';
  phyaddress: string = '';
  phycity: string = '';
  phystate: string = '';
  phyzip: string = '';
  phyphone: string = '';
  phyemail: string = '';
  phynpi: string = '';
  phyuserinfoid: string = '';
  procity: string = '';
  prostate: string = '';
  assignflag: boolean = false;
  Pcpid: string = '';
  consPhysName: string = '';
  last_name: string = '';
  first_name: string = '';
  patientnm: string = '';
  patientid: number = null;
  face_sheet: string = '';
  patientmrn: string = '';
  eunity: string = '';
  facility_stat: string = '';
  facility_addtype: string = '';
  patientdob: string = '';
  ssn: string = '';
  room: string = '';
  examtype: string = '';
  modality_media_type: string = '';
  examtypeid: number = null;
  order_media_type: string = '';
  faxed_time: string = '';
  cptcount: number = 0;
  GetFnlCptArr: Array<any> = [];
  GetIntCptArr: TOrderCpt[] = [];
  fnlcptdesc: string = '';
  cptdesc: string = '';
  cptCodes: TOrderCpt[] = [];
  cpt_code: string = '';
  transRefid: string = '';
  imgvisible: boolean = false;
  lblvisible: boolean = false;
  lblvalue: string = '';
  cpt_code1: string[] = [];
  cpt_code_session_no2: string[] = [];
  cpt_refid: number = null;
  icd: Array<any> = [];
  icddesc: string = '';
  phoneimgsource: string = '';
  phonetooltip: string = '';
  enablePh: boolean = false;
  imgsource: string = '';
  imgtooltip: string = '';
  enableImg: boolean = false;
  faximgsource: string = '';
  faxtooltip: string = '';
  enableFax: boolean = false;
  resultimgsource: string = '';
  resulttooltip: string = '';
  enableRep: boolean = false;
  critical: boolean = false;
  externalimgsource: string = '';
  externaltooltip: string = '';
  enableEx: boolean = false;
  insure: 'No' | 'Yes' = 'No';
  priority: string = '';
  phoneresult: string = '';
  comment: string = '';
  hl7: string = '';
  gender: 'M' | 'F' | 'U' | '' = '';
  location: string = '';
  facilitynm: string = '';
  facilityid: number = null;
  correctionalFacility: string = '';
  clientMRN: string = '';
  vw_patientid: string = '';
  radiologygrouprefid: number = null;
  radiologygroupid: string = '';
  username: string = ''; // created by username (first_name + last_name)
  ordercreated_dt: string = '';
  techtooltip: string = '';
  technologistnm: string = '';
  techid: number = null;
  address1: string = '';
  address2: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  phone: string = '';
  bill_email: string = '';
  facility_npi: string = '';
  order_source: string = '';
  order_source_nm: string = '';
  order_based: string = '';
  ordertaken_date: string = '';
  status_flag: string = '';
  orderassigned_date: string = '';
  ordercompletion_date: string = '';
  sheduleservdate: string = '';
  dos: string = '';
  sheduleservdate1: string = '';
  sheduleservtime1: string = '';
  dostime: string = '';
  schedulestime: string = '';
  scheduleendtime: string = '';
  dateofservice: string = '';
  ordersigned_date: string = '';
  patient_img_path: string = '';
  duedate: string = '';
  orderstatus: 'Assign' = 'Assign';
  status: 'New' | 'Processing' | 'Assigned' | 'Completed' = 'New';
  btnvisible: boolean = false;
  signstatus: 'Unsigned' | 'Signed' = 'Unsigned';
  signstatusvis: boolean = false;
  pdfimgvis: boolean = true;
  cptvisible: boolean = false;
  icdvisible: boolean = false;
  pos: string = '';
  pos_id: string = '';
  reasonval: string = '';
  ordersrc: string = '';
  ins_notes: string = '';
  phoneResult: string = '';
  notes: string = '';
  imgnotes: string = '';
  visitimgsource: string = '';
  visitindex: string = '';
  recipientFaxNumber: string = '';
  lastModifiedLock: string = '';
  isVaccinationRequired: boolean = false;
  faxreport: 'Y' | 'N';
  report_status: 'Y' | 'N';
  division: string | null = '';
}

class SignOrdersPageService {
  loadSignOrdersCount(item: TSignOrderFilter) {
    const data = [
      item.coperate_Id,
      item.srchLastName,
      item.srchDate,
      item.fromDate,
      item.toDate,
      item.userType,
      item.facilityId,
      item.userId,
      item.srchFirstName,
      item.stateId,
      item.locId,
      item.posId,
      item.signed,
      item.dosSearch,
      item.physicianId,
      item.physicianGroupID,
      item.pcpId,
      item.companyClient,
      item.tab,
      item.clinicianGroupId,
    ];

    return apiRequest<number>({
      url: 'medicalrecord.MedicalRecord.getSignOrderCount',
      data,
    });
  }

  confirmSign(id: number) {
    return apiRequest<'S'>({
      url: 'order.Order.UpdateSingleOrderSignStatus',
      data: [id],
    });
  }

  updateSignStatusByStaff(orderIds: Array<number>) {
    return apiRequest<'S'>({
      url: 'order.Order.UpdatesignstatusbyStaff',
      data: [orderIds],
    });
  }

  loadSignOrdersList(item: TSignOrderFilter) {
    const data = [
      item.coperate_Id,
      item.srchLastName,
      item.srchDate,
      item.fromDate,
      item.toDate,
      item.userType,
      item.facilityId,
      item.userId,
      item.startIndex,
      item.limit,
      item.srchFirstName,
      item.stateId,
      item.locId,
      item.posId,
      item.signed,
      item.dosSearch,
      item.physicianId,
      item.physicianGroupID,
      item.pcpId,
      item.companyClient,
      item.tab,
      item.clinicianGroupId,
    ];

    return apiRequest<Array<TSignOrderItem>>({
      url: 'medicalrecord.MedicalRecord.getSignOrderList',
      data,
    });
  }

  sendEmail(item: TBillingAssignmentEmail) {
    const data = [
      item.emailList,
      item.subject,
      item.body,
      item.attachment,
      item.tab,
      item.emailsList,
    ];

    return apiRequest<'S'>({
      url: 'facility.Billingassignment.Email_send',
      data,
    });
  }

  sendFax(item: TBillingAssignmentFax) {
    const data = [
      item.objList,
      item.body,
      item.faxno,
      item.from,
      item.dateVal,
      item.faxto,
      item.tab,
      item.subject,
    ];

    return apiRequest<'S'>({
      url: 'facility.Billingassignment.Fax_send',
      data,
    });
  }
}

export class TBillingAssignmentEmail {
  subject: string = '';
  body: string = '';
  attachment: string = '';
  tab: string = '';
  emailsList: string = '';
  emailList: Array<{ notifyemail: string }> = [];
}

export class TFacilityData {
  facility_id: string = '';
  providerid: string = '';
  bill_city: string = '';
  bill_state: string = '';
  facility_name: string = '';
  bill_addres1: string = '';
  bill_zipcode: string = '';
  bill_contactnm: string = '';
  orderid_grp: string = '';
  from_date: string = '';
  end_date: string = '';
}

export class TBillingAssignmentFax {
  title: string = '';
  subject: string = '';
  body: string = '';
  tab: string = '';
  faxno: string = '';
  from: string = '';
  dateVal: string = '';
  faxto: string = '';
  objList: Array<TFacilityData> = [];
}

export default new SignOrdersPageService();
