import { makeObservable, observable, action } from 'mobx';

import { TPagination } from 'components/grid/GridTypes';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';

export const defaultFilter: FilterType = {
  fromDate: '',
  toDate: '',
  period: 'A',
  clientOrCompany: '',
  firstName: '',
  lastName: '',
  facilityId: 0,
  userTypeId: 0,
};

export interface FilterType {
  fromDate: string;
  toDate: string;
  period: string;
  clientOrCompany: 0 | 1 | '';
  firstName: string;
  lastName: string;
  facilityId: number;
  userTypeId: number;
}

interface LoginType {
  date: string;
  login_time: string;
  logout_time: string;
  logoutdt: null | string;
  status: string;
  user_id: number;
  user_name: string;
  username: string;
  usertype_id: string;
  woking_hours: string;
}

class LoginLog {
  fetching: boolean = false;
  logs: LoginType[] = [];
  logsTotal: number = 0;
  filter: FilterType = defaultFilter;
  page: Pagination = new Pagination({ id: 'current_activity_log' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      logs: observable,
      logsTotal: observable,
      filter: observable,

      setLogs: action,
      setLogsTotal: action,
      setFetching: action,
      setFilter: action,
    });
  }

  setFilter(payload: FilterType) {
    this.filter = payload;
  }

  setLogs(logs: LoginType[]) {
    this.logs = logs;
  }

  setLogsTotal(count: number) {
    this.logsTotal = count;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  async getLoginLog({
    pagination,
    filter,
  }: {
    pagination: TPagination;
    filter: FilterType;
  }) {
    const data = {
      userId: 0,
      start: pagination.skip,
      limit: pagination.pageSize,
      userTypeId: filter.userTypeId,
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      facilityId: filter.facilityId,
      clientOrCompany: filter.clientOrCompany,
      lastName: filter.lastName,
      firstName: filter.firstName,
    };

    try {
      const response = await apiRequest<LoginType[]>({
        url: 'userlogin.LoginMaster.GetAllLoginReport',
        data,
      });
      if (Array.isArray(response)) {
        const logs = response.map((log) => ({
          ...log,
          logout_time: dateToLocalTimezone({
            date: log.logout_time,
          }),
          login_time: dateToLocalTimezone({
            date: log.login_time,
          }),
          user_id: Number(log.user_id),
        }));

        this.setLogs(logs);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setLogs([]);
    }
  }

  async getLogCount(filter: FilterType) {
    const data = {
      userId: 0,
      userTypeId: filter.userTypeId,
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      facilityId: filter.facilityId,
      clientOrCompany: filter.clientOrCompany,
      lastName: filter.lastName,
      firstName: filter.firstName,
    };

    try {
      const count = await apiRequest<number>({
        url: 'userlogin.LoginMaster.GetAllLoginReportCount',
        data,
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setLogsTotal(total);
    } catch (e) {
      this.setLogsTotal(0);
    }
  }

  getLoginLogMain = async (filter: FilterType = this.filter) => {
    this.setFetching(true);

    const dos = getDateRangeBounds({
      from: filter.fromDate,
      to: filter.toDate,
    });

    const payload = {
      ...filter,
      fromDate: dos.dateFrom,
      toDate: dos.dateTo,
    };

    const promiseList = this.getLoginLog({
      pagination: this.page.pagination,
      filter: payload,
    });

    const promiseCount = this.getLogCount(payload);

    return Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };
}

export const storeLoginLog = new LoginLog();
