import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Button } from 'components/button';
import { LayoutSideTitle } from 'components/layout';
import { GridControlButton } from 'components/grid';
import { Grid, ColDef, GridExtraControlPanel } from 'components/gridModern';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import { useMemoState } from 'components/hook';
import Filter from './components/Filter';

import {
  storeUnbilledClaims,
  UnbilledClaimType,
  FilterType,
} from 'stores/_mobx/report/unbilledClaims';

const UnbilledClaims = ({
  isDivisionEnabled,
}: PropsTypeWitDivisionSettings) => {
  const {
    fetching,
    records,
    recordsTotal,
    gridId,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeUnbilledClaims;

  const [sorting, setSorting] =
    useMemoState<Record<string, 'asc' | 'desc'>>(null);

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const columns = useMemo<ColDef<UnbilledClaimType>[]>(
    () => [
      { field: 'id', headerName: 'Study ID', width: 100 },
      {
        field: 'patient.firstName',
        headerName: 'First Name',
        minWidth: 120,
        flex: 1,
      },
      {
        field: 'patient.lastName',
        headerName: 'Last Name',
        minWidth: 120,
        flex: 1,
      },
      {
        field: 'scheduleServiceDate',
        headerName: 'DOS',
      },
      { field: 'facility.facilityName', headerName: 'Facility', width: 200 },
      ...((isDivisionEnabled
        ? [{ field: 'facility.division.name', headerName: 'Division' }]
        : []) as ColDef<UnbilledClaimType>[]),
      { field: 'facility.dispatchRegion.state.state', headerName: 'State' },
      {
        field: 'facility.dispatchRegion.region.region',
        headerName: 'Region',
        minWidth: 150,
        flex: 1,
      },
      { field: 'modality.name', headerName: 'Modality', width: 100 },
      {
        field: 'procedureCount',
        headerName: 'Procedure Count',
        cellClass: 'text-center',
        width: 105,
      },
      {
        field: 'tcBillProcessView',
        headerName: 'TC Bill Process',
        sortable: false,
      },
    ],
    [isDivisionEnabled]
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleChangeFilter = (payload: FilterType) => {
    storeUnbilledClaims.setFilter(payload);
    setPaginationToStart();
  };

  const handleClickExport = () => {
    storeUnbilledClaims.runExportCsv(columns);
  };

  useEffect(() => {
    if (sorting) storeUnbilledClaims.getLogs({ isDivisionEnabled, sorting });
  }, [isDivisionEnabled, sorting, pagination]);

  return (
    <>
      <LayoutSideTitle title="Unbilled Claims">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>
      <Filter
        fetching={fetching}
        isFilterVisible={isFilterVisible}
        onChangeFilter={handleChangeFilter}
      />
      <GridExtraControlPanel>
        <GridControlButton
          title="Export CSV"
          disabled={!recordsTotal}
          onClick={handleClickExport}
        />
      </GridExtraControlPanel>
      <Grid
        id={gridId}
        isLoading={fetching}
        columnDefs={columns}
        rowData={records}
        totalCount={recordsTotal}
        paginationSettings={pagination}
        onChangeSort={setSorting}
        onPaginationChange={setPagination}
      />
    </>
  );
};

export default withDivisionSettings(observer(UnbilledClaims));
