import { makeObservable, observable, action, computed } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone } from 'utils/DateUtils';

export const defaultFilter: FilterType = {
  equipmentType: {
    device: false,
    ekg: false,
    vehicle: false,
    phone: false,
    bone: false,
  },
  serviceStatus: {
    retired: false,
    waiting: false,
    open: false,
    usable: false,
  },
  userType: UserProfileStore.getUserType() || '',
  userId: UserProfileStore.getUserId() || 0,
};

export interface FilterType {
  equipmentType: Record<string, boolean>;
  serviceStatus: Record<string, boolean>;
  userType: string;
  userId: number;
}

export interface TaskType {
  btnlabel: string;
  enable: boolean;
  equ_status: string;
  equipment_id: number;
  equipment_type: string;
  equipname: string;
  equtype: string;
  labelval: string;
  reason: string;
  refid: number;
  requestby: string;
  requestdt: string;
  servicename: string;
  time: string;
}

interface ParamsForServiceNote {
  id: Number;
  equipmentId: number;
  equipmentType: string;
}

interface ParamsForAssign {
  equipmentName: string;
  equipmentType: string;
  reason: string;
  id: number;
  isAssignMode: boolean;
}

interface NoteResponseType {
  bgcolor: string;
  date: string;
  name: string;
  reason: string;
  time: string;
}

interface ParamsForReject extends Omit<ParamsForAssign, 'isAssignMode'> {
  userId: number;
  equipmentStatus: string;
}

export interface ParamsForUpdate extends Omit<ParamsForReject, 'reason'> {
  equipmentId: number;
  equtype: string;
  requestDate: string;
  requestBy: string;
}

interface NoteType extends Omit<NoteResponseType, 'reason' | 'name'> {
  message: string;
  user: string;
}

interface OptionType {
  label: string;
  value: number;
}

export interface PayloadUpdateType {
  statusText: string;
  statusCode: string;
}

class TaskManager {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  fetchingNotes: boolean = false;
  taskList: TaskType[] = [];
  taskTotal: number = 0;
  paramsServiceNote: ParamsForServiceNote | null = null;
  paramsForAssign: ParamsForAssign | null = null;
  paramsForReject: ParamsForReject | null = null;
  paramsForUpdate: ParamsForUpdate | null = null;
  notes: NoteType[] = [];
  options: OptionType[] = [];

  filter: FilterType = defaultFilter;
  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'taskManagerGrid' });

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      fetchingNotes: observable,
      taskList: observable,
      taskTotal: observable,
      paramsServiceNote: observable,
      paramsForAssign: observable,
      paramsForReject: observable,
      paramsForUpdate: observable,
      notes: observable,
      filter: observable,
      options: observable,

      notesTotal: computed,

      setFetching: action,
      setFetchingOptions: action,
      setFetchingNotes: action,
      setTaskList: action,
      setTaskTotal: action,
      setParamServiceNote: action,
      setParamForAssign: action,
      setParamForReject: action,
      setParamsForUpdate: action,
      setNotes: action,
      setFilter: action,
      setOptions: action,
      clearNotes: action.bound,
      clearAssign: action.bound,
      clearReject: action.bound,
      clearUpdate: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setFetchingNotes(fetching: boolean) {
    this.fetchingNotes = fetching;
  }

  setTaskList(tasks: TaskType[]) {
    this.taskList = tasks;
  }

  setTaskTotal(total: number) {
    this.taskTotal = total;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setParamServiceNote(data: TaskType) {
    const params = {
      id: data.refid,
      equipmentId: data.equipment_id,
      equipmentType: data.equipment_type,
    };
    this.paramsServiceNote = params;
  }

  setParamForAssign(data: TaskType) {
    const params = {
      id: data.refid,
      equipmentName: data.equipname,
      equipmentType: data.equtype,
      reason: data.reason,
      isAssignMode: data.labelval === 'Assign',
    };
    this.paramsForAssign = params;
  }

  setParamForReject(data: TaskType) {
    const params = {
      id: data.refid,
      equipmentName: data.equipname,
      equipmentType: data.equtype,
      equipmentStatus: data.equ_status,
      reason: data.reason,
      userId: UserProfileStore.getUserId(),
    };
    this.paramsForReject = params;
  }

  setParamsForUpdate(data: TaskType) {
    const params = {
      id: data.refid,
      equipmentId: data.equipment_id,
      equipmentName: data.equipname,
      equipmentType: data.equipment_type,
      equipmentStatus: data.equ_status,
      equtype: data.equtype,
      userId: UserProfileStore.getUserId(),
      requestDate: data.requestdt,
      requestBy: data.requestby,
    };
    this.paramsForUpdate = params;
  }

  setNotes(notes: NoteType[]) {
    this.notes = notes;
  }

  setOptions(options: OptionType[]) {
    this.options = options;
  }

  clearNotes() {
    this.paramsServiceNote = null;
    this.notes = [];
  }

  clearAssign() {
    this.paramsForAssign = null;
  }

  clearReject() {
    this.paramsForReject = null;
  }

  clearUpdate() {
    this.paramsForUpdate = null;
  }

  get notesTotal() {
    return this.notes?.length || 0;
  }

  async getTaskList(filter: FilterType) {
    const { pagination } = this.page;
    const data = [
      filter.equipmentType.device,
      filter.equipmentType.ekg,
      filter.equipmentType.vehicle,
      filter.equipmentType.phone,
      filter.equipmentType.bone,
      filter.serviceStatus.waiting,
      filter.serviceStatus.open,
      filter.serviceStatus.usable,
      filter.userType,
      filter.userId,
      pagination.skip,
      pagination.pageSize,
      filter.serviceStatus.retired,
    ];

    this.setFetching(true);

    try {
      const response = await apiRequest<TaskType[]>({
        url: 'vehicle.EquipmentMaster.GetServiceList',
        data,
      });

      const tasks = response.map((task) => {
        const [requestdt, time] = dateToLocalTimezone({
          date: `${task.requestdt} ${task.time}`,
        }).split(' ');

        return {
          ...task,
          requestdt,
          time,
          refid: Number(task.refid),
          equipment_id: Number(task.equipment_id),
        };
      });

      this.setTaskList(tasks);
    } catch (e: any) {
      this.setTaskList([]);
    } finally {
      this.setFetching(false);
    }
  }

  async getTaskCount(filter: FilterType) {
    const data = [
      filter.equipmentType.device,
      filter.equipmentType.ekg,
      filter.equipmentType.vehicle,
      filter.equipmentType.phone,
      filter.equipmentType.bone,
      filter.serviceStatus.cbWaiting,
      filter.serviceStatus.open,
      filter.serviceStatus.usable,
      filter.userType,
      filter.userId,
      filter.serviceStatus.retired,
    ];
    try {
      const count = await apiRequest({
        url: 'vehicle.EquipmentMaster.GetServiceCnt',
        data,
      });

      this.setTaskTotal(Number(count) || 0);
    } catch (e: any) {
      this.setTaskTotal(0);
    }
  }

  getTaskListMain = async (filter: FilterType = this.filter) => {
    this.setFetching(true);

    const promiseList = this.getTaskList(filter);

    const promiseCount = this.getTaskCount(filter);

    return Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getNotes(isNewOnly: boolean) {
    const data = [
      this.paramsServiceNote.equipmentId,
      this.paramsServiceNote.equipmentType,
      isNewOnly,
    ];

    this.setFetchingNotes(true);

    try {
      const response = await apiRequest<NoteResponseType[]>({
        url: 'vehicle.EquipmentMaster.GetServiceNotes',
        data,
      });

      const notes = response.map(({ reason, name, ...rest }) => ({
        ...rest,
        date: dateToLocalTimezone({
          date: `${rest.date} ${rest.time}`,
        }),
        message: reason,
        user: name,
      }));
      this.setNotes(notes);
    } catch (e: any) {
      this.setNotes([]);
    } finally {
      this.setFetchingNotes(false);
    }
  }

  async addMessageToNotes(message: string) {
    this.setFetchingNotes(true);
    try {
      const response = await apiRequest<number>({
        url: 'vehicle.EquipmentMaster.addServiceMessage',
        data: [this.paramsServiceNote.id, message],
      });

      if (response) {
        Notification.success('Message was saved!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.warning("Message did't save!");
      return false;
    } finally {
      this.setFetchingNotes(false);
    }
  }

  async getServiceUsers() {
    this.setFetchingOptions(true);
    try {
      const response = await apiRequest<OptionType[]>({
        url: 'vehicle.EquipmentMaster.ServiceUserDropDown',
      });

      if (Array.isArray(response)) {
        this.setOptions(response);
      }
      throw Error('');
    } catch (e: any) {
      this.setOptions([]);
    } finally {
      this.setFetchingOptions(false);
    }
  }

  async assignUserToService(serviceUserId: number) {
    const params = this.paramsForAssign;
    const data = [
      params.id,
      serviceUserId,
      params.equipmentName,
      params.equipmentType,
      params.reason,
    ];
    this.setFetching(true);
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'vehicle.EquipmentMaster.UpdateService',
        data,
      });

      if (response === 'S') {
        const actionName = params.isAssignMode ? 'assigned' : 'reassigned';

        Notification.success(`Successfully ${actionName}!`);

        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async rejectService() {
    const params = this.paramsForReject;
    const data = [
      params.id,
      params.userId,
      params.equipmentStatus,
      params.equipmentName,
      params.equipmentType,
      params.reason,
    ];
    this.setFetching(true);
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'vehicle.EquipmentMaster.RejectService',
        data,
      });

      if (response === 'S') {
        Notification.success('Service was rejected successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async updateService(
    payload: PayloadUpdateType = {
      statusText: 'Service Opened',
      statusCode: 'O',
    }
  ) {
    const params = this.paramsForUpdate;
    const data = [
      params.id,
      params.equipmentStatus,
      params.equipmentId,
      params.equipmentType,
      payload.statusText || 'Service Opened',
      payload.statusCode || 'O',
      params.userId,
      params.equipmentName,
      params.equtype,
    ];
    this.setFetching(true);
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'vehicle.EquipmentMaster.UpdateEquipmentService',
        data,
      });

      if (response === 'S') {
        Notification.success('Service updated for the equipment successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }
}

export const storeTaskManager = new TaskManager();
