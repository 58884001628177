import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import { PureInput } from 'components/form/textField';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';

import { storePriority } from 'stores/_mobx/systemSetup/masterSetting/priority';
import { MS_PRIORITY as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_PRIORITY_ADD,
  URL_PRIORITY,
} from 'constant/path/systemSetup';

const columns = [
  { name: 'priority_nm', title: 'Priority Name' },
  { name: 'priority_desc', title: 'Priority Description' },
  {
    name: 'colorid',
    title: 'Color Code',
    render: (color: string) => (
      <span
        className="priority-example"
        style={{ backgroundColor: color }}
        title={color}>
        {color}
      </span>
    ),
  },
  { name: 'minutes', title: 'Duration Hr(s)' },
  {
    name: 'refid',
    title: 'Action',
    className: 'width-75',
    render: (id: number) => (
      <div className="control">
        <Link className="bi bi-pencil" to={`${URL_PRIORITY}/edit/${id}`} />
        <IconButton onClick={() => storePriority.setIdForDelete(id)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const PriorityListPage = () => {
  const {
    fetching,
    priorityList,
    priorityTotal,
    idForDelete,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storePriority;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleChangeFilter = (priorityName: string) => {
    storePriority.setFilter(priorityName);
    debouncedMethod();
  };

  const handleApproveDelete = () => {
    storePriority.deletePriority().then((isSucceed) => {
      if (isSucceed) storePriority.getPriorityMain();
    });
  };

  useEffect(() => {
    storePriority.getPriorityMain();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Priority">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Priority"
        className="col-sm-12 col-md-6 col-lg-4"
        name="priority"
        value={filter}
        onChange={handleChangeFilter}
      />

      <Grid
        id="priorityGrid"
        columns={columns}
        onAjax={fetching}
        dataSource={priorityList}
        dataSourceCount={priorityTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_PRIORITY_ADD} />
        }
      />
      {idForDelete ? (
        <DialogConfirm
          onCancel={storePriority.clearIdForDelete}
          onApprove={handleApproveDelete}>
          Are you sure you want to delete?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(PriorityListPage);
