import { Container } from 'flux/utils';

import OrderFormPageActions from 'actions/workflow/order/OrderFormPageActions';
import OrderTabStateStore from 'stores/workflow/order/OrderTabStateStore';
import AbstractOrderFormPage, {
  PAbstractOrderFormPage,
  SAbstractOrderFormPage,
} from 'page/workflow/order/AbstractOrderFormPage';

export interface PNewOrderFormPage extends PAbstractOrderFormPage {
  url: string;
}

export class SNewOrderFormPage extends SAbstractOrderFormPage {}

export class NewOrderFormPage extends AbstractOrderFormPage<
  PNewOrderFormPage,
  SNewOrderFormPage
> {
  flowFinished: boolean = false;

  constructor(props: PNewOrderFormPage) {
    super(props);
    this.state = Object.assign(
      new SNewOrderFormPage(),
      this.state,
      new SNewOrderFormPage()
    );
    this.flowFinished = props.onEdit || false;
  }

  static calculateState(
    prevState: SNewOrderFormPage,
    props: PNewOrderFormPage
  ) {
    return AbstractOrderFormPage.calculateState(prevState, props, false);
  }

  static getStores() {
    return AbstractOrderFormPage.getStores();
  }

  leavePageListener = (e: any) => {
    if (!this.props.onEdit) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  componentWillUnmount() {
    const isPhysicianInfoTabUnblocked = OrderTabStateStore.getTabState(2);

    super.componentWillUnmount();
    if (!this.flowFinished && this.state.orderId && !isPhysicianInfoTabUnblocked) {
      OrderFormPageActions.deleteOrder(this.state.orderId);
    }
    window.removeEventListener('beforeunload', this.leavePageListener);
  }

  componentDidMount(): void {
    super.componentDidMount();
    window.addEventListener('beforeunload', this.leavePageListener);
  }

  submitCaller(response?: any, state?: any) {
    if (!this.props.onEdit) {
      this.flowFinished = true;
    }
    super.submitCaller(response, state);
  }
}

export default Container.create(NewOrderFormPage);
