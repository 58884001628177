import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import CodeActivationForm from './components/Form';

import {
  storeCodeActivation,
  CptCodeDetailsType,
} from 'stores/_mobx/systemSetup/codeActivationMaster';
import { URL_CODE_ACTIVATION_MASTER } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

export interface PropsType extends RouteComponentProps<MatchParams> {}

const CodeActivationMasterEditPage = ({
  history,
  match: { params },
}: PropsType) => {
  const { fetching, code } = storeCodeActivation;

  const handleSubmit = async (data: CptCodeDetailsType) =>
    storeCodeActivation.modifyCode(data).then((error) => {
      if (error) return error;
      history.push(URL_CODE_ACTIVATION_MASTER);
    });

  useEffect(() => {
    if (code && Number('' + code.id) !== Number(params.id)) {
      history.replace(URL_CODE_ACTIVATION_MASTER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, params.id]);

  useEffect(() => {
    const id = Number(params.id);

    storeCodeActivation.getCode(id);

    return storeCodeActivation.clearCode;
  }, [params.id]);

  return (
    <>
      <Title title="Edit CPT code" />

      <CodeActivationForm
        backUrl={URL_CODE_ACTIVATION_MASTER}
        defaultValues={code}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(CodeActivationMasterEditPage);
