import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import TwoChartBlock from 'page/reports/components/TwoChartBlock';
import { LayoutSideTitle } from 'components/layout';
import { Button, IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import Exporter from 'components/project/ExporterNew';
import { USER_EXPENSE as PAGE_ID } from 'constant/pagesId/reports';
import ExpenseSummary from './components/ExpenseSummary';
import DialogExpenseDetail from './components/DialogExpenseDetail';
import Filter from './components/Filter';

import UserProfileStore from 'stores/UserProfileStore';
import {
  storeUserExpenseReport,
  FilterType,
} from 'stores/_mobx/report/userExpense';

const columns = [
  { name: 'last_name', title: 'Last Name' },
  { name: 'first_name', title: 'First Name' },
  { name: 'usertype_name', title: 'User Type' },
  { name: 'callhour', title: 'Call Hour', className: 'text-center' },
  { name: 'expenses', title: 'Expenses', className: 'text-center' },
  {
    name: 'user_id',
    title: 'View',
    export: 'exclude',
    render: (id: number) =>
      id ? (
        <div className="control">
          <IconButton
            onClick={() => storeUserExpenseReport.setUserIdForDetail(id)}>
            <i className="bi bi-file-earmark-plus" />
          </IconButton>
        </div>
      ) : null,
  },
];

const UserExpenseReportPage = () => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    logList,
    logTotal,
    chartData,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeUserExpenseReport;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const [isGridVisible, toggleGrid] = useState<boolean>(true);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleToggleGrid = () => {
    toggleGrid((state) => !state);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeUserExpenseReport.setFilter(filter);

    setPaginationToStart();
  };

  useEffect(() => {
    storeUserExpenseReport.getLogsMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="User Expense Report">
        <Button
          text={isGridVisible ? 'Graph View' : 'Grid View'}
          onClick={handleToggleGrid}
        />
        <Button
          text={isFilterVisible ? 'Hide filter' : 'Show filter'}
          variant="default"
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        isVisible={isFilterVisible}
        fetching={fetching}
        onSubmit={handleChangeFilter}
      />

      <div className={isGridVisible ? '' : ' d-none'}>
        <Grid
          id="userExpenseGrid"
          columns={columns}
          onAjax={fetching}
          dataSourceCount={logTotal}
          dataSource={logList}
          ref={gridRef}
          pagination={pagination}
          onPaginationChange={setPagination}
          gridControlPanel={
            <GridControlButton title="Export" onClick={handleToggleExport} />
          }
        />
        <ExpenseSummary data={chartData} />
      </div>

      <TwoChartBlock
        chartData={chartData}
        className={isGridVisible ? 'd-none' : ''}
      />

      <DialogExpenseDetail />

      {isExportVisible && (
        <Exporter
          exporter="UserExpenseExporter"
          reportName={
            UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
            'UserExpenseReport'
          }
          columns={columns}
          gridRef={gridRef}
          dataSource={logList}
          filter={storeUserExpenseReport.filter}
          pdfExporter={storeUserExpenseReport.exportToPdf}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default observer(UserExpenseReportPage);
