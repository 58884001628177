import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import TwoChartBlock from 'page/reports/components/TwoChartBlock';
import Exporter from 'components/project/ExporterNew';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import Filter from './components/Filter';
import SummaryView from './components/SummaryView';

import UserProfileStore from 'stores/UserProfileStore';
import {
  storeArAging,
  AgingType,
  FilterType,
} from 'stores/_mobx/billingCodding/arAging';
import { AR_AGING as PAGE_ID } from 'constant/pagesId/billingCodding';
import { URL_AR_AGING } from 'constant/path/billingCoding';

const GRID_ID = 'ar_aging_grid';

const commonColumns = [
  { title: 'Current', name: '0to30' },
  { title: '31 to 60', name: '31to60' },
  { title: '61 to 90', name: '61to90' },
  { title: '91 to 120', name: '91to120' },
  { title: 'above 120', name: 'above' },
  { title: 'Balance', name: 'balance' },
  { title: 'Location', name: 'location' },
  { title: 'Region', name: 'region' },
];

const columnsPatient = [
  { title: 'Last Name', name: 'lastname' },
  { title: 'First Name', name: 'firstname' },
  { title: 'Patient MRN', name: 'patientmrn' },
  { title: 'Other MRN', name: 'clientMRN' },
].concat(commonColumns);

const getFacilityColumns = (isDivisionEnabled: boolean) =>
  [
    {
      title: 'Facility Name',
      name: 'facilitynm',
      render: (facilityName: string, { facilityid }: AgingType) => (
        <Link to={`${URL_AR_AGING}/facility/${facilityid}`}>
          {facilityName}
        </Link>
      ),
    },
    ...(isDivisionEnabled ? [{ name: 'division', title: 'Division' }] : []),
    { title: 'Phone #', name: 'phone' },
  ].concat(commonColumns);

const ArAgingListPage = ({
  isDivisionEnabled,
}: PropsTypeWitDivisionSettings) => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    agingFormattedList,
    agingTotal,
    filter,
    summaryFormattedList,
    chartData,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeArAging;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExporter] = useState<boolean>(false);

  const [isChartView, toggleSummaryView] = useState<boolean>(false);

  const exportName = useMemo(
    () => UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) || 'AR_Aging',
    []
  );

  const columns = useMemo(
    () =>
      filter.arAgingType === 'P'
        ? columnsPatient
        : getFacilityColumns(isDivisionEnabled),
    [filter.arAgingType, isDivisionEnabled]
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExporter((state) => !state);
  };

  const handleToggleSummaryView = () => {
    toggleSummaryView((state) => !state);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeArAging.setFilter(filter);

    setPaginationToStart();
  };

  useEffect(() => {
    storeArAging.getAgingMain(isDivisionEnabled);
  }, [isDivisionEnabled, pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="AR Aging">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        isVisible={isFilterVisible}
        fetching={fetching}
        onSubmit={handleChangeFilter}
      />

      <Grid
        id={GRID_ID}
        ref={gridRef}
        onAjax={fetching}
        columns={columns}
        dataSource={agingFormattedList}
        dataSourceCount={agingTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Export" onClick={handleToggleExport} />
        }
      />

      <Button
        text={isChartView ? 'Show Grid' : 'Show Chart'}
        className="col-sm-auto mb-3"
        onClick={handleToggleSummaryView}
      />
      {isChartView ? (
        <TwoChartBlock chartData={chartData} />
      ) : (
        <SummaryView summaryInfo={summaryFormattedList} />
      )}

      {isExportVisible && (
        <Exporter
          reportName={exportName}
          columns={columns}
          gridRef={gridRef}
          dataSource={agingFormattedList}
          filter={filter}
          pdfExporter={storeArAging.generatePdf}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default withDivisionSettings(observer(ArAgingListPage));
