import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import { dateToLocalTimezone } from 'utils/DateUtils';

export interface InterpretationType {
  accession: string;
  age: number;
  dob: string;
  dateOfService: string;
  facility: string;
  mrn: string;
  name: string;
  physician: string;
  reasonForExam: string;
  study: string;
}

interface PayloadGetInterpretation {
  cptCode: string;
  cptRefid: number;
  cptSessionNo: string;
  orderId: number;
}

interface PayloadAddInterpretation {
  text: string;
  cpt: string;
  orderId: number;
  cptId: number;
  cptSessionNo: string;
  radiologistId: number;
  currentDateTime: string;
  reportType: 'P' | 'A' | 'F';
}

class Interpretation {
  fetching: boolean = false;
  interpretation: InterpretationType | null = null;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      interpretation: observable,

      setFetching: action,
      setInterpretation: action,
      clearInterpretation: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setInterpretation(data: InterpretationType) {
    this.interpretation = data;
  }

  clearInterpretation() {
    this.interpretation = null;
  }

  async getInterpretationInfo(data: PayloadGetInterpretation) {
    this.setFetching(true);
    try {
      const response = await apiRequest<InterpretationType>({
        url: 'pdfcreater.OrderPdfGenerator.getInterpretationDataPreView',
        data,
      });

      const dateOfService = dateToLocalTimezone({
        date: response.dateOfService,
        dateOnly: true,
      });

      const interpretationInfo = {
        ...response,
        dateOfService,
      };

      this.setInterpretation(interpretationInfo);
    } catch (e: any) {
    } finally {
      this.setFetching(false);
    }
  }

  async addInterpretation(payload: PayloadAddInterpretation) {
    try {
      const data = {
        text: payload.text,
        cpt: payload.cpt,
        orderId: payload.orderId,
        cptId: payload.cptId,
        cptSessionNo: payload.cptSessionNo,
        radiologistId: payload.radiologistId,
        currentDateTime: payload.currentDateTime,
        reportType: payload.reportType,
      };

      const response = await apiRequest<string>({
        url: 'pdfcreater.OrderPdfGenerator.CreateInterpretation',
        data,
      });

      return response;
    } catch (e) {
      return '';
    }
  }
}

export const storeInterpretation = new Interpretation();
