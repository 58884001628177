import { makeObservable, observable, action, computed } from 'mobx';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { getDateRangeBounds, timeZoneRender } from 'utils/DateUtils';

const defaultFilterValue: FilterType = {
  dosStart: '',
  dosEnd: '',
  period: 'A',
  patientLastName: '',
  patientFirstName: '',
  technologistId: 0,
  examTypeId: 0,
  facilityId: 0,
  timezone: '',
};

export interface FilterType {
  dosStart: string;
  dosEnd: string;
  period: string;
  patientLastName: string;
  patientFirstName: string;
  technologistId: number;
  examTypeId: number;
  facilityId: number;
  timezone: string;
}

interface LogType {
  ex_cpml: string;
  examtype: string;
  facility_nm: string;
  orderassign_dt: string;
  ordercompletion_dt: string;
  ordered_dt: string;
  pat_dob: string;
  pat_ftnm: string;
  pat_ltnm: string;
  priority: string;
  prov_nm: string;
  tech_firstnm: string;
  tech_lastnm: string;
}

class ExamReport {
  fetching: boolean = false;
  logList: LogType[] = [];
  logTotal: number = 0;

  filter: FilterType = defaultFilterValue;

  page: Pagination = new Pagination({ id: 'exam_report_gridId' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      logList: observable,
      logTotal: observable,
      filter: observable,

      finalLogList: computed,

      setFetching: action,
      setLogList: action,
      setLogTotal: action,
      setFilter: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setLogList(logs: LogType[]) {
    this.logList = logs;
  }

  setLogTotal(count: number) {
    this.logTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setDefaultFilter() {
    const filter = {
      ...this.filter,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facilityId,
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const filter = {
      ...defaultFilterValue,
      facilityId: UserProfileStore.getFacilityId() || 0,
    };

    return filter;
  }

  get finalLogList() {
    const { timezone } = this.filter;

    const finalList = this.logList.map((log) => ({
      ...log,
      orderassign_dt: timeZoneRender(log.orderassign_dt, timezone),
      ordercompletion_dt: timeZoneRender(log.ordercompletion_dt, timezone),
    }));

    return finalList;
  }

  async getLogs(data: Record<string, string | number>) {
    try {
      const logList = await apiRequest<LogType[]>({
        url: 'report.TechnicianExam.Getexamreportdetail',
        data,
      });

      this.setLogList(logList);
    } catch (e) {
      this.setLogList([]);
    }
  }

  async getLogsCount(data: Record<string, string | number>) {
    try {
      const count = await apiRequest<string>({
        url: 'report.TechnicianExam.Getexamreportdetailcount',
        data,
      });

      this.setLogTotal(Number(count) || 0);
    } catch (e) {
      this.setLogTotal(0);
    }
  }

  async getLogsListMain() {
    const {
      filter,
      page: { pagination },
    } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const logCountPayload = {
      facility: filter.facilityId,
      dosFrom: dos.dateFrom,
      dosTo: dos.dateTo,
      lastName: filter.patientLastName,
      firstName: filter.patientFirstName,
      technologist: filter.technologistId || null,
      examtype: filter.examTypeId || null,
    };

    const logListPayload = {
      skip: pagination.skip,
      pageSize: pagination.pageSize,
      facility: filter.facilityId,
      dosFrom: dos.dateFrom,
      dosTo: dos.dateTo,
      lastName: filter.patientLastName,
      firstName: filter.patientFirstName,
      technologist: filter.technologistId || null,
      examtype: filter.examTypeId || null,
    };

    this.setFetching(true);

    const promiseCount = this.getLogsCount(logCountPayload);

    const promiseRegions = this.getLogs(logListPayload);

    Promise.allSettled([promiseCount, promiseRegions]).finally(() => {
      this.setFetching(false);
    });
  }

  generatePdf = async ({ filter }: { filter: string }) => {
    const data = {
      dataSource: this.finalLogList,
      filter,
    };
    try {
      const response = await apiRequest<string>({
        url: 'pdfcreater.reportPdfGenerator.examreportpdf',
        data,
      });
      return response;
    } catch (e: any) {
      return '';
    }
  };

  prepareFilterToExport() {
    const { filter } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return {
      ...filter,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
    };
  }
}

export const storeExamReport = new ExamReport();
