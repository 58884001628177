import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';

import { storeCallLog } from 'stores/_mobx/workflow/order/callLog';

const DropdownCallType = (props: Omit<SelectPropsType, 'options'>) => {
  const { fetchingOptions, callTypeOptions } = storeCallLog;

  useEffect(() => {
    if (!callTypeOptions.length && !fetchingOptions) {
      storeCallLog.getCallTypeOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select {...props} options={callTypeOptions} isLoading={fetchingOptions} />
  );
};

export default observer(DropdownCallType);
