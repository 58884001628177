import { apiRequest } from 'services/RequestService';

class InvoiceReportPageService {
  getInvoiceOrderReportByPatient(conditionList, joint) {
    return apiRequest({
      url: 'report.ReportMaster.GetInvoiceOrderReportByPatient',
      data: [conditionList, joint],
    }).then((response) => {
      return response;
    });
  }

  getInvoiceOrderReportByFacility(conditionList, joint) {
    return apiRequest({
      url: 'report.ReportMaster.GetInvoiceOrderReportByFacility',
      data: [conditionList, joint],
    }).then((response) => {
      return response;
    });
  }

  createPdf(dataSource, formatDescription, pdfType) {
    if (pdfType === 'F') {
      return apiRequest({
        url: 'pdfcreater.OrderPdfGenerator.GenerateFacilityInvoicePDF',
        data: [dataSource, formatDescription],
      });
    } else {
      return apiRequest({
        url: 'pdfcreater.OrderPdfGenerator.GeneratePatientInvoicePDF',
        data: [dataSource, formatDescription],
      });
    }
  }
}
export default new InvoiceReportPageService();
