import React, { memo } from 'react';

interface PImage {
  className?: string;
  style?: React.CSSProperties;
  path: string;
  alt?: string;
}

const defaultStyles = {
  height: 100,
  width: 100,
};

const Image = ({ path, alt, style = {}, className = '' }: PImage) => (
  <div style={{ ...defaultStyles, ...style }}>
    <img src={path} className={`thumbnail ${className}`} alt={alt || path} />
  </div>
);

export default memo(Image);
