import React from 'react';
import { Container } from 'flux/utils';
import { RouteComponentProps } from 'react-router-dom';

import Text from 'components/form/input/Text';
import NumberInput from 'components/form/input/NumberInput';
import Fieldset from 'components/form/Fieldset';
import Select from 'components/form/input/Select';
import Form from 'components/form/Form';
import Notification from 'components/modal/Notification';
import FacilityMapper from 'components/form/twoSideMapper/FacilityMapper';
import Radio from 'components/form/input/Radio';
import OrderTypeCheckboxes from 'components/project/checkboxes/OrderTypeCheckboxes';
import RegionDropdown from 'components/project/dropdown/RegionDropdown';
import StateDropdown from 'components/project/dropdown/StateDropdown';
import StateShortNameDropdown from 'components/project/dropdown/StateShortName';
import CorporationOverviewPageActions from 'actions/system-setup/master-setup/corporations/CorporationOverviewPageActions';
import FacilityPageActions from 'actions/system-setup/master-setup/facility/FacilityPageActions';
import CorporationOverviewPageStore from 'stores/system-setup/corporations/CorporationOverviewPageStore';
import { TFacilityItem } from 'services/system-setup/master-setup/corporations/CorporationOverviewPageService';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { SpinnerFixed } from 'components/spinner';

const columns = [
  {
    name: 'facility_nm',
    title: 'Facility',
    width: 'calc(100% - 175px)',
  },
  {
    name: 'zipcode',
    title: 'Zip code',
    width: '80px',
  },
  {
    name: 'facility_npi',
    title: 'NPI',
    width: '90px',
  },
];

const TOGGLE_OPTIONS = [
  { label: 'Select Existing Facility', value: 1 },
  { label: 'Add New Facility', value: 2 },
];

const filteredFacilityList = (facilities: any[], selectedFacilities: any[]) => {
  if (!facilities.length) {
    return [];
  }

  const existingIds: Record<string, boolean> = {};

  selectedFacilities.forEach((item) => {
    existingIds[item.refid] = true;
  });

  return facilities.filter((item) => !existingIds[item.refid]);
};

export interface PFacilitiesTab {
  corporationId: number;
  fetchingFacilities: boolean;
  history: RouteComponentProps['history'];
}

export class TFacilitiesTabFilter {
  facilityName: string = '';
  zipCode: string = '';
  npi: string = '';
}

export class SFacilitiesTab {
  onAjax: boolean = false;
  isDirty: boolean = false;
  newFacilityItem: TFacilityItem = new TFacilityItem();
  isNewFacilityItemAdded: boolean = false;
  errors: any = {};
  selectedFacility: any = null;
  mode: number = 1;
  locationList: Array<any> = [];
  facilityList: Array<any> = [];
  currentFacilityList: Array<any> = [];
  facilityMembers: Array<any> = [];
  isStateSelected: boolean = false;
}

class FacilitiesTab extends React.Component<PFacilitiesTab, SFacilitiesTab> {
  formRef: Form = null;

  static getStores() {
    return [CorporationOverviewPageStore];
  }

  static calculateState(prevState: SFacilitiesTab) {
    if (!prevState) {
      prevState = new SFacilitiesTab();
    }

    const facilities =
      CorporationOverviewPageStore.getState().facilityList || [];

    const currentFacilityList =
      CorporationOverviewPageStore.getState().facilityListById || [];

    return {
      ...prevState,
      facilityList: filteredFacilityList(facilities, currentFacilityList),
      currentFacilityList,
      facilityMembers: CorporationOverviewPageStore.getState().facilityMembers,
    };
  }

  onSetMode(
    name: string,
    value: string,
    errorMessages?: any,
    errorKeys?: any,
    event?: any,
    clb?: () => void
  ): void {
    this.setState({ mode: parseInt(value) }, this.updateData.bind(this));
  }

  handleSubmit = (
    updatedModel: any,
    hasErrors: boolean,
    updatedErrors: any
  ) => {
    if (hasErrors) {
      const { state } = this;
      const newFacilityItem = { ...state.newFacilityItem, ...updatedModel };
      const errors = { ...state.errors, ...updatedErrors };
      this.setState({ newFacilityItem, errors });
    } else {
      this.submitSuccess();
    }
  };

  handleChangeMapper = (currentFacilityList: any[]) => {
    this.setState({ currentFacilityList, isDirty: true });
  };

  renderExistingFacility() {
    const {
      currentFacilityList,
      selectedFacility,
      facilityList,
      isDirty,
      onAjax,
    } = this.state;

    return (
      <>
        <div className="row facility-detail">
          <div className="col-sm-6">
            <dl className="row">
              <dt className="col-lg-4">Facility name</dt>
              <dd className="col-lg-8">{selectedFacility?.facility_nm}</dd>
              <dt className="col-lg-4">Place of service</dt>
              <dd className="col-lg-8">{selectedFacility?.pos}</dd>
              <dt className="col-lg-4">Facility NPI</dt>
              <dd className="col-lg-8">{selectedFacility?.facility_npi}</dd>
            </dl>
          </div>
          <div className="col-sm-6">
            <dl className="row">
              <dt className="col-lg-4">State</dt>
              <dd className="col-lg-8">{selectedFacility?.state}</dd>
              <dt className="col-lg-4">Zip code</dt>
              <dd className="col-lg-8">{selectedFacility?.zipcode}</dd>
              <dt className="col-lg-4">Phone</dt>
              <dd className="col-lg-8">{selectedFacility?.phone}</dd>
            </dl>
          </div>
        </div>
        <FacilityMapper
          titleLeft="Total available facilities"
          titleRight="Total selected facilities"
          isDirty={isDirty}
          columnLeft={columns}
          columnRight={columns}
          options={facilityList}
          value={currentFacilityList}
          onChange={this.handleChangeMapper}
          onHover={this.handleHover}
        />
        {(onAjax || this.props.fetchingFacilities) && <SpinnerFixed />}
      </>
    );
  }

  onCollectValues = (name: string, value: string, errorMessages: any) => {
    const isPlaceSelected = name === 'stateId' || name === 'regionId';

    const newFacilityItem = {
      ...this.state.newFacilityItem,
      locationId: isPlaceSelected
        ? null
        : this.state.newFacilityItem.locationId,
      [name]: value,
    };

    const errors = { ...this.state.errors, [name]: errorMessages };

    if (name === 'stateId') {
      let isStateSelected: boolean = parseInt(value) > 0;
      FacilityPageActions.loadLocationList(
        newFacilityItem.regionId,
        newFacilityItem.stateId
      ).then((response) => {
        const locationList = response || [];
        this.setState({
          locationList,
          isStateSelected,
        });
      });
    }
    if (name === 'regionId') {
      FacilityPageActions.loadLocationList(
        newFacilityItem.regionId,
        newFacilityItem.stateId
      ).then((response) => {
        const locationList = response || [];
        this.setState({ locationList });
      });
    }

    this.setState({ newFacilityItem, errors });
  };

  renderNewFacility() {
    const isStateSelected = this.state.isStateSelected;
    const existingFacilityList: Array<React.ReactNode> = [];
    let count = 1;
    this.state.facilityMembers.forEach((item) => {
      existingFacilityList.push(
        <li className="list-group-item" key={count}>
          <b>{item.facility_nm}</b>
        </li>
      );
      count++;
    });

    return (
      <>
        <Form
          ref={(f) => {
            this.formRef = f;
          }}
          onCollectValues={this.onCollectValues}
          model={this.state.newFacilityItem}
          errors={this.state.errors}
          submit={this.handleSubmit}>
          <Fieldset className="row">
            <Fieldset className="col-sm-8">
              <Fieldset className="col-sm-6">
                <Text
                  name="facilityName"
                  label="Facility name"
                  validations="required"
                  errorMessages="Please, enter the facility name!"
                />
                <StateDropdown
                  name="stateId"
                  label="State"
                  validations="required:lazy"
                  errorMessages="Please, select a state!"
                />
                <RegionDropdown
                  name="regionId"
                  label="Region"
                  attr={{ disabled: !isStateSelected }}
                  validations="required:lazy"
                  errorMessages="Please, select a region!"
                />
                <Select
                  name="locationId"
                  label="Location"
                  options={this.state.locationList}
                  attr={{ disabled: !isStateSelected }}
                  validations="required:lazy"
                  errorMessages="Please, select a location!"
                />
              </Fieldset>
              <Fieldset className="col-sm-6">
                <Text name="address" label="Address" />
                <Text name="country" label="Country" />
                <StateShortNameDropdown name="state" label="State" />
                <NumberInput name="phone" label="Phone" format="###-###-####" />
                <NumberInput
                  name="zipcode"
                  label="Zip Code"
                  format="#####-####"
                />
              </Fieldset>
              <OrderTypeCheckboxes
                name="orderType"
                className="part-inline"
                label="Order Type"
                validations={{ required: 'checkboxes' }}
                errorMessages="Please, select at least one option!"
              />
            </Fieldset>
            <Fieldset className="col-sm-4">
              <b>Existing Corporate Facilities:</b>
              <br />
              <br />
              <ul className="list-group">{existingFacilityList}</ul>
            </Fieldset>
          </Fieldset>
        </Form>
      </>
    );
  }

  render() {
    const { state } = this;

    return (
      <>
        <Radio
          name="toggleMode"
          noLabel
          className="part-inline"
          options={TOGGLE_OPTIONS}
          value={state.mode}
          onSetValue={this.onSetMode.bind(this)}
        />

        {state.mode === 1
          ? this.renderExistingFacility()
          : this.renderNewFacility()}

        <ControlsLayout alignX="right" className="my-3">
          <Button
            variant="warning"
            text="Reset"
            onClick={this.reset.bind(this)}
          />
          <Button text="Save" onClick={this.submit.bind(this)} />
        </ControlsLayout>
      </>
    );
  }

  handleHover = (facility: any) => {
    this.setState({ selectedFacility: facility });
  };

  componentDidMount() {
    this.updateData();
  }

  reset() {
    const { mode } = this.state;
    if (mode === 1) {
      const facilityList =
        CorporationOverviewPageStore.getState().facilityList || [];
      const currentFacilityList =
        CorporationOverviewPageStore.getState().facilityListById || [];
      this.setState({
        facilityList: filteredFacilityList(facilityList, currentFacilityList),
        currentFacilityList,
        isDirty: false,
      });
    } else {
      const newFacilityItem = new TFacilityItem();
      const facilityMembers =
        CorporationOverviewPageStore.getState().facilityMembers;
      this.setState({
        facilityMembers,
        newFacilityItem
      });
    }
  }

  updateFacilitiesList() {
    if (
      this.state.newFacilityItem.facilityName !== '' && this.state.isNewFacilityItemAdded
    ) {
      this.setState({ onAjax: true });

      CorporationOverviewPageActions.loadFacilityList().then(() => {
        const newFacilityItems = this.state.facilityList.filter(
          (item) => item.facility_nm === this.state.newFacilityItem.facilityName
            && item.dispatch_region_nm === this.state.newFacilityItem.locationId.toString()
        );

        if (newFacilityItems.length > 0) {
          const currentFacilityList = [ ...this.state.currentFacilityList, newFacilityItems[0] ];

          this.saveFacilityList(currentFacilityList).then(() => {
            this.setState({
              onAjax: false,
              isNewFacilityItemAdded: false,
              newFacilityItem: new TFacilityItem(),
              isStateSelected: false
            });
          });
        } else {
          this.setState({ onAjax: false, isNewFacilityItemAdded: false });
        }
      });
    }
  }

  saveFacilityList(currentFacilityList: any[], shouldRedirect: boolean = false){
    return CorporationOverviewPageActions.saveFacilityList(
      this.props.corporationId,
      currentFacilityList
    ).then((response: number) => {
      if (response && parseInt('' + response) > 0) {
        Notification.success('Facilities have been successfully updated!');

        if (shouldRedirect) {
          this.props.history.push({ search: '?subtub=users' });
        }
      } else {
        Notification.danger('An error occurred!');
      }
      this.updateData();
    });
  }

  submitSuccess(model?: any): Promise<any> {
    return CorporationOverviewPageActions.saveNewFacilityItem(
      this.state.newFacilityItem
    ).then((response: 'SE' | 'E' | number) => {
      if (response && parseInt('' + response) > 0) {
        Notification.success('Facility has been successfully added!');
        this.setState({ mode: 1, isNewFacilityItemAdded: true }, this.updateFacilitiesList);
      } else if (response === 'E') {
        Notification.warning("Can't save facility, because it already exists.");
      } else {
        Notification.danger('An error occurred!');
      }
    });
  }

  submit() {
    if (this.state.mode === 1) {
      this.saveFacilityList(this.state.currentFacilityList, true);
    } else {
      this.formRef.submit();
    }
  }

  updateData = () => {
    if (this.state.mode === 1) {
      this.setState({ onAjax: true });

      CorporationOverviewPageActions.loadFacilityListById(
        this.props.corporationId
      ).then(() => {
        this.setState({ onAjax: false });
      });
    } else {
      CorporationOverviewPageActions.loadFacilityMemberList(
        this.props.corporationId
      );
    }
  };
}

export default Container.create(FacilitiesTab);
