import {
  removeWhitespaceBeforeTags,
  removeWhitespaceBetweenTags,
  removeWhitespaceAfterTags,
} from 'utils/StringUtils';

const textPrettier = (interpretation: string) => {
  const text = `<TEXTFORMAT LEADING='2'>
                <P ALIGN='LEFT'>
                    <FONT FACE='Calibri' SIZE='14' COLOR='#000000' LETTERSPACING='0' KERNING='0'>
                        <interpretation_place_stub_tag/>
                    </FONT>
                </P>
            </TEXTFORMAT>`.trim();
  return removeWhitespaceBeforeTags(
    removeWhitespaceBetweenTags(removeWhitespaceAfterTags(text))
  ).replace('<interpretation_place_stub_tag/>', `${interpretation}`);
};

export default textPrettier;
