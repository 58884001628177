import { apiRequest } from 'services/RequestService';
import { BASE_URL_FILE_DIR } from 'constant/config';
import StringUtils from 'utils/StringUtils';
import { downloadFile } from 'utils/downloadFile';

export default class CsvExporter {
  dataSource: Array<any>;
  columns: Array<any>;
  action: string;
  filterString: string;

  /**
   *
   * @param dataSource array of objects with data
   * @param columns array of objects, applicable to Grid columns property
   * @param action url, where data will be transferred into csv, optional
   * @param filterString data that will be placed above
   */
  constructor(
    dataSource: Array<any>,
    columns: Array<any>,
    action: string,
    filterString: string
  ) {
    this.action = action || 'export.Export.exportXls';
    if (!dataSource) {
      throw new TypeError('dataSource is required for CsvExporter');
    }
    if (!columns) {
      throw new TypeError('columns is required for CsvExporter');
    }
    this.dataSource = dataSource;
    this.filterString = filterString;
    this.columns = columns.map((v) => ({
      name: v.name,
      title: v.title || StringUtils.normalizeText(v.name),
      group: v.group || null,
      groupName: v.groupName || null,
    }));
  }

  exportAndDownload(fileName: string) {
    const data = {
      fileName,
      dataSource: this.dataSource,
      columns: this.columns,
      filterString: this.filterString,
    };
    return apiRequest<string>({ url: this.action, data }).then(
      (fileName: string) => {
        const path = fileName.replace(/^\//, '');

        downloadFile(`${BASE_URL_FILE_DIR}${path}`, true);
      }
    );
  }
}
