import { makeObservable, observable, action } from 'mobx';

import { TPagination } from 'components/grid';
import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import AccessUtils from 'utils/AccessUtils';
import { TRANSCRIPTION } from 'constant/pagesId/workflow';
import { BASE_URL_FILE_DIR } from 'constant/config';

const reportTypeMapper: Record<ReportType, string> = {
  P: 'Prelim',
  F: 'Final',
  A: 'Addendum',
  '': '',
};

export const defaultTranscriptionValue: BasicTranscriptionType = {
  cptCode: '',
  cptDescription: '',
  cptSessionNo: '',
  critical: 0,
  fileName: '',
  filePath: '',
  flagNeedAddendum: false,
  interpretation: '',
  needAddendumFromRadiologyGroupId: 0,
  radiologistId: 0,
  radiologistName: '',
  radiologyGroupId: 0,
  refid: 0,
  reportType: 'A',
};

const defaultPermissionValue: PermissionType = {
  add: false,
  edit: false,
  delete: false,
};

const defaultFilterValues: FilterType = {
  orderId: 0,
  patientId: 0,
  radiologyGroupId: 0,
  radiologistId: 0,
  cptDescription: '',
};

export type ReportType = '' | 'P' | 'F' | 'A';

export interface FilterType {
  orderId: number;
  patientId: number;
  radiologyGroupId: number;
  radiologistId: number;
  cptDescription: string;
}

interface FilterExtendedType extends FilterType {
  pagination: TPagination;
}

export interface PermissionType {
  add: boolean;
  edit: boolean;
  delete: boolean;
}

interface TranscriptionModifyResponseType {
  files: Record<string, string>;
  orderTranscriptionsNum: string;
}

export interface TranscriptionFormType {
  transcriptions: ExtendedTranscriptionType[];
  conclusion: string;
  orderId: number;
  patientId: number;
}

interface ExtendedTranscriptionType extends BasicTranscriptionType {
  useTempFolder?: boolean;
  interpretationHash?: string;
  addendumValidation?: string;
}

interface BasicTranscriptionType {
  cptCode: string;
  cptDescription: string;
  cptSessionNo: string;
  critical: 0 | 1;
  fileName: string;
  filePath: string;
  flagNeedAddendum: boolean;
  interpretation: string;
  needAddendumFromRadiologyGroupId: number;
  radiologistId: number;
  radiologistName: string;
  radiologyGroupId: number;
  refid: number;
  reportType: ReportType;
}

interface TranscriptionResponseType extends BasicTranscriptionType {
  accessionNumber: string;
  conclusion: string;
  orderId: number;
  patientId: number;
  radiologyGroupName: string;
}

export interface TranscriptionType extends TranscriptionResponseType {
  isCritical: boolean;
  reportTypeName: string;
}

interface AddendumFlagPayloadType {
  refid: number;
  flagNeedAddendum: boolean;
  needAddendumFromRadiologyGroupId: number;
}

class OrderTranscription {
  fetching: boolean = false;
  resendOruInProgress: boolean = false;
  fetchingDetails: boolean = false;
  transcriptions: TranscriptionType[] = [];
  transcriptionsTotal: number = 0;
  transcriptionDetails: BasicTranscriptionType[] = [];
  filter: FilterType = defaultFilterValues;
  permission: PermissionType = defaultPermissionValue;

  page: Pagination = new Pagination({ id: 'view_results_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      resendOruInProgress: observable,
      fetchingDetails: observable,
      transcriptions: observable,
      transcriptionsTotal: observable,
      transcriptionDetails: observable,
      filter: observable,
      permission: observable,

      setFetching: action,
      setSendingStatus: action,
      setFetchingDetails: action,
      setTranscriptions: action,
      setTranscriptionsTotal: action,
      setTranscriptionDetails: action,
      setFilter: action,
      setPermission: action,
      clearTranscriptions: action.bound,
      clearTranscriptionDetails: action.bound,
      clearFilter: action.bound,
      clearPermission: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setSendingStatus(inProgress: boolean) {
    this.resendOruInProgress = inProgress;
  }

  setFetchingDetails(fetching: boolean) {
    this.fetchingDetails = fetching;
  }

  setTranscriptions(list: TranscriptionType[]) {
    this.transcriptions = list;
  }

  setTranscriptionsTotal(count: number) {
    this.transcriptionsTotal = count;
  }

  setTranscriptionDetails(transcription: BasicTranscriptionType[]) {
    this.transcriptionDetails = transcription;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setPermission(permission: PermissionType) {
    this.permission = permission;
  }

  clearTranscriptions() {
    this.transcriptions = [];
    this.transcriptionsTotal = 0;
  }

  clearTranscriptionDetails() {
    this.transcriptionDetails = [];
  }

  clearFilter() {
    this.filter = defaultFilterValues;
    this.page.resetPagination();
  }

  clearPermission() {
    this.permission = defaultPermissionValue;
  }

  async getTranscriptionsCount(payload: FilterExtendedType) {
    try {
      const data = [
        payload.orderId,
        payload.patientId,
        payload.radiologyGroupId,
        payload.radiologistId,
        payload.cptDescription,
      ];

      const count = await apiRequest<number>({
        url: 'order.Transcription.totalRecordCount',
        data,
      });

      this.setTranscriptionsTotal(count);
    } catch (e: any) {
      this.setTranscriptionsTotal(0);
    }
  }

  async getTranscriptions(payload: FilterExtendedType) {
    try {
      const data = [
        payload.orderId,
        payload.patientId,
        payload.radiologyGroupId,
        payload.radiologistId,
        payload.cptDescription,
        payload.pagination.skip,
        payload.pagination.pageSize,
      ];

      const response = await apiRequest<TranscriptionResponseType[]>({
        url: 'order.Transcription.transcriptionList',
        data,
      });

      const transcriptions = response.map((el) => ({
        ...el,
        isCritical: Boolean(el.critical),
        reportTypeName: reportTypeMapper[el.reportType] || '',
        filePath: el.filePath
          ? `${BASE_URL_FILE_DIR}doc_img/documents/${el.filePath}`
          : '',
      }));

      this.setTranscriptions(transcriptions);
    } catch (e: any) {
      this.setTranscriptions([]);
    }
  }

  getTranscriptionMain(payload: FilterExtendedType) {
    this.setFetching(true);

    const promiseCount = this.getTranscriptionsCount(payload);

    const promiseTranscriptions = this.getTranscriptions(payload);

    Promise.all([promiseCount, promiseTranscriptions]).finally(() => {
      this.setFetching(false);
    });
  }

  async getTranscriptionDetails({
    orderId,
    transcriptionId,
  }: {
    orderId: number;
    transcriptionId?: number;
  }) {
    this.setFetchingDetails(true);
    try {
      const response = await apiRequest<BasicTranscriptionType[]>({
        url: 'order.Transcription.getTranscriptionReport',
        data: { orderId },
      });

      if (!response?.length) {
        this.setTranscriptionDetails(null);
      } else {
        const transcriptionDetails = transcriptionId
          ? response.filter((el) => el.refid === transcriptionId)
          : response.sort((a, b) => a.cptCode.localeCompare(b.cptCode));

        this.setTranscriptionDetails(transcriptionDetails);
      }
    } catch (error) {
      Notification.warning('An error occurred!');
      this.setTranscriptionDetails(null);
    } finally {
      this.setFetchingDetails(false);
    }
  }

  async addTranscription({
    transcriptions,
    ...orderDetails
  }: TranscriptionFormType) {
    const cpt = transcriptions.map(
      ({
        flagNeedAddendum,
        filePath,
        radiologistName,
        critical,
        useTempFolder,
        ...rest
      }) => ({
        ...rest,
        refid: rest.refid,
        critical: Number(critical),
      })
    );

    try {
      const response = await apiRequest<TranscriptionModifyResponseType>({
        url: 'order.Transcription.AddTranscription',
        data: { mainData: orderDetails, cpt },
      });

      const failedReports = Object.entries(response.files)
        .filter(([_, value]) => value === 'false')
        .map(([fileName]) => fileName);

      const isMultipleFiles = failedReports.length > 1;

      const filesName = failedReports.join(', ');

      const message = `${failedReports.length} Report${
        isMultipleFiles ? 's were not ' : "wasn't "
      }added: ${filesName}. Because ${isMultipleFiles ? 'thees' : 'this'} file${
        isMultipleFiles ? 's' : ''
      } already uploaded!`;

      if (failedReports.length) {
        Notification.warning(message, { autoClose: 5000 });
      } else {
        Notification.success(
          isMultipleFiles
            ? 'All reports uploaded successfully!'
            : 'Report uploaded successfully!'
        );
      }

      await apiRequest<'S'>({
        url: 'order.Transcription.updateRadioReportStatus',
        data: { orderId: orderDetails.orderId },
      });

      await apiRequest({
        url: 'generalmaster.RadiologyGroup.UpdatePcAssignment',
        data: { radioGroupId: null, orderId: orderDetails.orderId },
      });

      return true;
    } catch (e: unknown) {
      Notification.danger('An error occurred!');
      return null;
    }
  }

  async deleteTranscription(id: number) {
    this.setFetching(true);
    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'order.Transcription.deleteTranscription',
        data: [id],
      });

      if (response === 'S') {
        Notification.success('Transcription deleted successfully!');
        return true;
      }

      throw Error('');
    } catch (e: any) {
      this.setFetching(false);
      Notification.danger("You can't delete this transcription!");
      return false;
    }
  }

  async setAdditionalOptions({
    orderId,
    options,
  }: {
    orderId: number;
    options: AddendumFlagPayloadType[];
  }) {
    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'order.Transcription.setNeedsAddendumFlag',
        data: [orderId, options],
      });

      if (response === 'S') {
        Notification.success('Addendum status updated.');
        return true;
      }

      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      return false;
    }
  }

  async resendOru(transcriptionId: number) {
    try {
      await apiRequest({
        url: 'order.Transcription.ResendOru',
        data: [transcriptionId],
      });

      Notification.success(`Radiology Report has been resent using ORU!`);

      return true;
    } catch (e: unknown) {
      Notification.danger(
        'There was an error resending the Radiology Report via the ORU, please try again later!'
      );
      return false;
    }
  }

  async resendFax(transcriptionId: number) {
    try {
      await apiRequest({
        url: 'order.Transcription.ResendFax',
        data: [transcriptionId],
      });

      Notification.success(`Radiology Report has been resent using Fax!`);

      return true;
    } catch (e: unknown) {
      Notification.danger(
        'There was an error resending the Radiology Report via the Fax, please try again later!'
      );
      return false;
    }
  }

  async resendReport({
    transcriptionId,
    resendBy,
  }: {
    resendBy: '' | 'fax' | 'oru';
    transcriptionId: number;
  }) {
    this.setSendingStatus(true);
    try {
      const sendingFax =
        !resendBy || resendBy === 'fax'
          ? this.resendFax(transcriptionId)
          : null;

      const sendingOru =
        !resendBy || resendBy === 'oru'
          ? this.resendOru(transcriptionId)
          : null;

      await Promise.all([sendingFax, sendingOru]);
    } catch (e: unknown) {
    } finally {
      this.setSendingStatus(false);
    }
  }

  checkPermission() {
    const add = AccessUtils.checkAccess(TRANSCRIPTION.ADD);

    const edit = AccessUtils.checkAccess(TRANSCRIPTION.EDIT);

    const remove = AccessUtils.checkAccess(TRANSCRIPTION.DELETE);

    this.setPermission({
      add,
      edit,
      delete: remove,
    });
  }
}

export const storeOrderTranscription = new OrderTranscription();
