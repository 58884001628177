import { useState } from 'react';

import { PureInput } from 'components/form/textField';

import { storeDivisionMaster } from 'stores/_mobx/systemSetup/masterSetting/divisionMaster';

interface PropsType {
  onChange: (name: string) => void;
}

const Filter = ({ onChange }: PropsType) => {
  const [divisionName, setDivisionName] = useState<string>(
    storeDivisionMaster.filter
  );

  const handleNameChange = (value: string) => {
    setDivisionName(value);
    onChange(value);
  };

  return (
    <PureInput
      name="name"
      placeholder="Division Name"
      className="col-sm-12 col-sm-6 col-md-4"
      value={divisionName}
      onChange={handleNameChange}
    />
  );
};

export default Filter;
