import React from 'react';
import { toJS } from 'mobx';

import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'components/modal/dialog';
import FormCells from 'components/grid/FormCells';
import { Grid, TPagination } from 'components/grid';
import { Button } from 'components/button';

import StringUtils from 'utils/StringUtils';
import convertToCsv from 'utils/convertToCsv';
import { downloadCsv } from 'utils/downloadFile';

const DROPDOWN_OPTIONS = [
  { value: 'T', label: 'Tech' },
  { value: 'P', label: 'Prof' },
  { value: 'G', label: 'Global' },
];

interface PropsType {
  title: string;
  fetching: boolean;
  readOnly: boolean;
  pagination: TPagination;
  dataSource: Array<any>;
  dataSourceCount: number;
  setPagination: (props: {
    page: number;
    skip: number;
    pageSize: number;
  }) => void;
  onClose: () => void;
  onUpdate: (data: any[]) => void;
}

interface StateType {
  dataSource: Array<any>;
  errorsStorage: Record<number, Record<string, string>>;
  changedActiveFee: Array<any>;
}

export default class GridFeeSchedule extends React.Component<
  PropsType,
  StateType
> {
  state = {
    dataSource: [] as any[],
    errorsStorage: {},
    changedActiveFee: [] as any[],
  };

  static getDerivedStateFromProps(props: PropsType, state: StateType) {
    return props.dataSource.length === state.dataSource.length
      ? null
      : {
          dataSource: toJS(props.dataSource),
        };
  }

  COLUMNS = [
    { name: 'cpt_code', title: 'CPT Code', className: 'width-100' },
    {
      name: 'cpt_price',
      title: 'Tech Fee',
      className: 'width-100',
      render: this.moneyFactory('cpt_price'),
    },
    {
      name: 'profprice',
      title: 'Pro Fee',
      className: 'width-100',
      render: this.moneyFactory('profprice'),
    },
    {
      name: 'globalprice',
      title: 'Global Fee',
      className: 'width-100',
      render: this.moneyFactory('globalprice'),
    },
    {
      name: 'insure_tech_prc',
      title: 'Ins Tech Fee',
      className: 'width-100',
      render: this.moneyFactory('insure_tech_prc'),
    },
    {
      name: 'insure_prof_prc',
      title: 'Ins Pro Fee',
      className: 'width-100',
      render: this.moneyFactory('insure_prof_prc'),
    },
    {
      name: 'insure_glbl_prc',
      title: 'Ins Global Fee',
      className: 'width-100',
      render: this.moneyFactory('insure_glbl_prc'),
    },
    {
      name: 'cpt_description',
      title: 'CPT Description',
      render: this.textFactory('cpt_description'),
    },
    {
      name: 'is_default',
      title: 'Def Fac',
      render: this.dropDownFactory('is_default'),
      className: 'width-100',
      noOverflow: true,
    },
    {
      name: 'is_default_insure',
      title: 'Def Ins',
      render: this.dropDownFactory('is_default_insure'),
      className: 'width-100',
      noOverflow: true,
    },
  ];

  textFactory(name: string, validations: any = null) {
    return this.props.readOnly
      ? null
      : FormCells.textInputFactory(
          name,
          validations,
          this,
          'refid',
          this.state.errorsStorage
        );
  }

  dropDownFactory(name: string) {
    return this.props.readOnly
      ? null
      : FormCells.dropDownFactory(
          name,
          null,
          this,
          'refid',
          DROPDOWN_OPTIONS,
          null,
          { className: 'width-100' }
        );
  }

  moneyFactory(name: string) {
    return this.textFactory(name, {
      custom: (value: string) => StringUtils.isDollarPrice(value),
    });
  }

  updateTableCell(rowId: number, name: string, value: string, errors: any) {
    if (rowId) {
      this.handleChangeFee(Number(rowId), name, value, errors);
    }
  }

  handleExport = () => {
    const { dataSource } = this.props;

    const columns = this.COLUMNS.map(({ title, name }) => ({
      title,
      name,
    }));

    const csvData = convertToCsv(dataSource, columns);

    const date = new Date();

    const fileName = `State_Medicare${date.getFullYear()}_${
      date.getMonth() + 1
    }_${date.getDate()}_${date.getHours()}-${date.getMinutes()}.csv`;

    downloadCsv(csvData, fileName);
  };

  handleChangeFee = (id: number, name: string, value: any, errors: any) => {
    const { errorsStorage, changedActiveFee, dataSource } = this.state;

    const idx = dataSource.findIndex(({ refid }) => id === refid);

    const result = [...dataSource];

    result[idx][name] = value;

    // @ts-ignore
    const errorItem = errorsStorage[id] || {};
    const errorsResult = {
      ...errorsStorage,
      [id]: {
        ...errorItem,
        [name]: errors.length
          ? "Invalid price value. Keep '$ xxx.cc' format."
          : null,
      },
    };

    this.setState({
      dataSource: result,
      changedActiveFee: changedActiveFee.includes(id)
        ? changedActiveFee
        : changedActiveFee.concat(id),
      errorsStorage: errorsResult,
    });
  };

  onFeeSave = () => {
    const changed = [];

    for (let i = 0; i < this.state.dataSource.length; i++) {
      const item = this.state.dataSource[i];

      if (this.state.changedActiveFee.includes(Number(item.refid))) {
        changed.push(item);
      }
    }
    this.props.onUpdate(changed);
  };

  handleChangePagination = (pagination: TPagination) => {
    this.props.setPagination(pagination);
    this.setState({ errorsStorage: {} });
  };

  render() {
    const { readOnly } = this.props;

    return (
      <Dialog size="extraLarge" handleClose={this.props.onClose}>
        <DialogHeader title={this.props.title} onClose={this.props.onClose} />
        <DialogBody>
          <h5 className="text-danger fw-bold">
            T - Tech, P - Prof, G - Global
          </h5>
          <Grid
            wrapperClass="fee-schedules"
            id="fee_schedule_grid"
            minColumns={1}
            onAjax={this.props.fetching}
            dataSource={this.state.dataSource}
            dataSourceCount={this.props.dataSourceCount}
            pagination={this.props.pagination}
            onPaginationChange={this.handleChangePagination}
            columns={this.COLUMNS}
          />
        </DialogBody>
        <DialogFooter>
          {!readOnly && <Button text="Update" onClick={this.onFeeSave} />}
          <Button
            onClick={this.handleExport}
            disabled={!this.props.dataSourceCount}>
            Export
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }
}
