import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

export interface PriorityType {
  color_code: string;
  colorid: string;
  durationid: 0 | 1;
  minutes: string;
  priority_desc: string;
  priority_nm: string;
  refid: number;
}

export interface PriorityEntryType {
  color_code: string;
  durationid: string;
  minutes: number | '';
  priority_desc: string;
  priority_nm: string;
  refid: number;
}

interface OptionResponseType {
  data: string;
  label: string;
}

export interface OptionType {
  label: string;
  value: number;
}

class Priority {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  priorityList: PriorityType[] = [];
  priorityTotal: number = 0;
  priority?: PriorityEntryType;
  idForDelete: number = 0;
  options: OptionType[] = [];
  filter: string = '';
  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'priorityGrid' });

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      priorityList: observable,
      priorityTotal: observable,
      priority: observable,
      idForDelete: observable,
      filter: observable,
      options: observable,

      setFetching: action,
      setFetchingOptions: action,
      setFilter: action,
      setPriorityList: action,
      setPriorityTotal: action,
      setPriority: action,
      setIdForDelete: action.bound,
      clearIdForDelete: action.bound,
      clearPriority: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setFilter(priorityName: string) {
    this.filter = priorityName;
  }

  setPriorityList(list: PriorityType[]) {
    this.priorityList = list;
  }

  setPriorityTotal(count: number) {
    this.priorityTotal = count;
  }

  setPriority(priority?: PriorityEntryType) {
    this.priority = priority;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  clearPriority() {
    this.priority = undefined;
  }

  async getPriorityList(priorityName: string) {
    const { pagination } = this.page;

    const data = [pagination.skip, pagination.pageSize, priorityName];

    try {
      const response = await apiRequest<PriorityType[]>({
        url: 'generalmaster.Priority.PriorityList',
        data,
      });

      const priorities = response.map((item) => ({
        ...item,
        colorid: item.colorid.startsWith('#')
          ? item.colorid
          : `#${item.colorid}`,
        refid: Number(item.refid),
      }));
      this.setPriorityList(priorities);
    } catch (e) {
      this.setPriorityList([]);
    }
  }

  async getPriorityCount(priorityName: string) {
    try {
      const count = await apiRequest<number>({
        url: 'generalmaster.Priority.PriorityTotalCount',
        data: [priorityName],
      });
      this.setPriorityTotal(Number(count) || 0);
    } catch (e) {
      this.setPriorityTotal(0);
    }
  }

  getPriorityMain = (priorityName: string = this.filter) => {
    this.setFetching(true);

    const promiseList = this.getPriorityList(priorityName);

    const promiseCount = this.getPriorityCount(priorityName);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getPriority(id: number) {
    this.setFetching(true);

    try {
      const response = await apiRequest<'SE' | [PriorityEntryType]>({
        url: 'generalmaster.Priority.GetViewPriority',
        data: [id],
      });
      if (Array.isArray(response)) {
        const priority = {
          ...response[0],
          refid: id,
          color_code: response[0].color_code.startsWith('#')
            ? response[0].color_code
            : `#${response[0].color_code}`,
          durationid: response[0].durationid,
        };

        this.setPriority(priority);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setPriority();
    } finally {
      this.setFetching(false);
    }
  }

  async addPriority(payload: PriorityEntryType) {
    const data = [
      payload.priority_nm,
      payload.priority_desc,
      payload.color_code,
      payload.durationid,
      payload.durationid ? (payload.minutes as number) * 60 : payload.minutes,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.Priority.AddPriority',
        data,
      });
      if (result === 'S') {
        Notification.success('Priority was added successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This priority name is already exist!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updatePriority(payload: PriorityEntryType) {
    const data = [
      payload.refid,
      payload.priority_nm,
      payload.priority_desc,
      payload.color_code,
      payload.durationid,
      payload.durationid ? (payload.minutes as number) * 60 : payload.minutes,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.Priority.EditPriority',
        data,
      });

      if (result === 'S') {
        Notification.success('Priority was updated successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This priority name is already exist!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async deletePriority() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.Priority.DeletePriority',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Priority was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOptions(true);

    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.Priority.PriorityDropDown',
      });

      const options = response.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }

  findOption(id: number) {
    return this.options.find(({ value }) => value === id);
  }
}

export const storePriority = new Priority();
