import { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Tabs from 'components/tabs';
import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import ExamInfoTab from './ExamInfoTab';
import ExamQATab from './ExamQATab';

import {
  URL_EXAM_INFO_QA_TAB,
  URL_EXAM_INFO_EXAM_TAB,
} from 'constant/path/patientRecords';
import { EXAM_INFO as PAGE_ID } from 'constant/pagesId/patientRecords';

const tabsList = [
  { label: 'Exam Info', url: URL_EXAM_INFO_EXAM_TAB },
  { label: 'Exam QA', url: URL_EXAM_INFO_QA_TAB },
];

const ExamInformation = () => {
  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Exam Information">
        <Button
          text={isFilterVisible ? 'Hide filter' : 'Show filter'}
          variant="default"
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Tabs tabsList={tabsList} />

      <Switch>
        <Route
          path={URL_EXAM_INFO_EXAM_TAB}
          render={() => <ExamInfoTab isFilterVisible={isFilterVisible} />}
        />
        <Route
          path={URL_EXAM_INFO_QA_TAB}
          render={() => <ExamQATab isFilterVisible={isFilterVisible} />}
        />
      </Switch>
    </>
  );
};

export default ExamInformation;
