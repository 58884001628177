import { Button } from 'components/button';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import ValidationMessageContent from './ValidationMessageContent';

interface PropsType {
  errors: Map<string, string | number>[];
  onClose: () => void;
  onMessageClick: (id: number) => void;
}

const TechnicianPreAssignValidationDialog = ({
  errors,
  onClose,
  onMessageClick,
}: PropsType) => (
  <Dialog scrollable handleClose={onClose}>
    <DialogHeader title="Assignment Failed" onClose={onClose} />
    <DialogBody>
      <p className="fst-italic">
        *The following orders are missing required items and must be fixed
        before assigning a Technologist.
      </p>
      <div className="container">
        <div
          className="row mb-3 py-2 border-bottom position-sticky bg-white"
          style={{ top: '-15px' }}>
          <strong className="col-sm-4">Patient Name</strong>
          <strong className="col-sm-8">Required Field</strong>
        </div>
        {errors.map((error) => {
          const id = error.get('id:') as number;

          return (
            <ValidationMessageContent
              key={id}
              id={id}
              error={error}
              onMessageClick={onMessageClick}
            />
          );
        })}
      </div>
    </DialogBody>
    <DialogFooter>
      <Button text="Ok" onClick={onClose} />
    </DialogFooter>
  </Dialog>
);

export default TechnicianPreAssignValidationDialog;
