import { Input } from 'components/form/textField';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  storeHospice,
  FilterModel,
} from 'stores/_mobx/systemSetup/masterSetting/hospice';

interface PropsType {
  onSearch: (data: FilterModel) => void;
}
const Filter = ({ onSearch }: PropsType) => {
  const { control, handleSubmit, watch } = useForm<FilterModel>({
    defaultValues: storeHospice.filter,
  });

  useEffect(() => {
    const subscription = watch((formValue) => {
      storeHospice.setFilter(formValue as FilterModel);

      onSearch(formValue as FilterModel);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row" onSubmit={handleSubmit(onSearch)}>
      <Input
        name="hospiceName"
        placeholder="Hospice Name"
        className="col-md-6 col-lg-4"
        control={control}
      />
      <Input
        name="npi"
        type="number"
        placeholder="Hospice NPI"
        className="col-md-6 col-lg-4"
        control={control}
      />
    </form>
  );
};

export default Filter;
