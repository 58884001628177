import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Exporter from 'components/project/ExporterNew';
import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import Filter from './components/Filter';

import UserProfileStore from 'stores/UserProfileStore';
import {
  storeOrderTrackHistory,
  FilterType,
} from 'stores/_mobx/report/orderTrackHistory';
import { ORDER_TRACK_HISTORY as PAGE_ID } from 'constant/pagesId/reports';

const columns = [
  { name: 'studyid', title: 'Study ID' },
  { name: 'dos', title: 'DOS', className: 'text-nowrap' },
  { name: 'patlname', title: 'Patient Last Name' },
  { name: 'patfname', title: 'Patient First Name' },
  {
    name: 'placeddate',
    title: 'Order Placed',
  },
  {
    name: 'assigndate',
    title: 'Order Assigned',
  },
  {
    name: 'completedate',
    title: 'Order Completed',
  },
];

const OrderTrackHistory = () => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    finalLogList,
    logTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeOrderTrackHistory;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleSubmitFilter = (filter: FilterType) => {
    storeOrderTrackHistory.setFilter(filter);

    setPaginationToStart();
  };

  useEffect(() => {
    storeOrderTrackHistory.getLogsListMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Order-Track History">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        fetching={fetching}
        isVisible={isFilterVisible}
        onSubmit={handleSubmitFilter}
      />

      <Grid
        id="orderTrackHistoryGrid"
        columns={columns}
        onAjax={fetching}
        dataSourceCount={logTotal}
        dataSource={finalLogList}
        ref={gridRef}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Export" onClick={handleToggleExport} />
        }
      />

      {isExportVisible && (
        <Exporter
          exporter="OrderTrackHistoryExporter"
          columns={columns}
          reportName={
            UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
            'Order-Track History'
          }
          filter={filter}
          filterForRequest={[
            {
              filter: storeOrderTrackHistory.prepareFilterForExport(),
            },
          ]}
          gridRef={gridRef}
          dataSource={finalLogList}
          pdfExporter={storeOrderTrackHistory.generatePdf}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default observer(OrderTrackHistory);
