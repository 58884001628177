import { memo } from 'react';

import { IcdCodeType } from 'stores/_mobx/workflow/order/assignedOrders';
import { CptCodeType } from 'utils/cptCode';

interface CptCodesPropsType {
  codes: CptCodeType[];
}

interface IcdCodesPropsType {
  codes: IcdCodeType[];
}

export const CptCodes = memo(({ codes }: CptCodesPropsType) => (
  <div className="mb-4">
    {codes.map((code, index) => {
      const description =
        code.cpt_desc ||
        `${code.extremity ? '[' + code.extremity + '] ' : ''} ${
          code.cpt_description
        }`;
      return (
        <div className="mb-2" key={`${code.cpt_code}_${index}`}>
          {`${code.cpt_code} - ${description}`}
        </div>
      );
    })}
  </div>
));

export const IcdCodes = memo(({ codes }: IcdCodesPropsType) => (
  <div>
    {codes.map((code, index) => (
      <div key={`${code.icd_code}_${index}`} className="mb-2">
        {`${code.icd_code} - ${code.short_description}`}
      </div>
    ))}
  </div>
));
