import { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Button } from 'components/button';
import { PureInput } from 'components/form/textField';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';

import { storeUserCategory } from 'stores/_mobx/systemSetup/masterSetting/userCategory';

interface PropsType {
  onClose: () => void;
  onSubmit: (name: string) => Promise<null | { message: string }>;
}

const DialogNewCategory = ({ onClose, onSubmit }: PropsType) => {
  const { fetching } = storeUserCategory;

  const [categoryName, setName] = useState<string>('');

  const [errorMessage, setError] = useState<string>('');

  const handleTypeCategory = (categoryName: string) => {
    setName(categoryName);
    setError('');
  };

  const handleClickApply = () => {
    onSubmit(categoryName).then((response) => {
      if (response) setError(response.message);
    });
  };

  return (
    <Dialog handleClose={onClose}>
      <DialogHeader title="Add Category" onClose={onClose} />
      <DialogBody className={fetching ? 'on-ajax' : ''}>
        <PureInput
          label="Enter a new category"
          autoFocus
          required
          value={categoryName}
          errorMessage={errorMessage}
          onChange={handleTypeCategory}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          text="Apply"
          disabled={!categoryName.length || fetching}
          onClick={handleClickApply}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogNewCategory);
