import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import DropdownBillingJurisdiction from 'components/project/dropdown/BillingJurisdiction';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import resolver from './validation';

const initialValues: FormModel = {
  code: '',
  facility_price: '',
  insurance_price: '',
  no_of_patient: '',
  region_id: 0,
  stateid: 0,
};

interface PropsType {
  defaultValues?: FormModel;
  backUrl: string;
  onSubmit: (data: FormModel) => Promise<any>;
}

export interface FormModel {
  code: string;
  facility_price: number | '';
  insurance_price: number | '';
  no_of_patient: number | '';
  refid?: number;
  region_id: number;
  stateid: number;
}

const FormTransport = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    setValue,
    watch,
    reset,
    setError,
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const stateId = watch('stateid');

  const cn = clsx('col-lg-10 col-xl-8', {
    'on-ajax': isSubmitting,
  });

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: FormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('code', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'stateid') {
        setValue('region_id', null);
      }
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className={cn} onSubmit={handleClickSubmit}>
      <Input name="code" label="Code" required control={control} />
      <Input
        name="no_of_patient"
        label="Number of patient"
        type="number"
        min={0}
        required
        control={control}
      />
      <div className="row">
        <DropDownState
          className="col-sm-6"
          name="stateid"
          label="State"
          required
          control={control}
        />
        <DropdownBillingJurisdiction
          name="region_id"
          label="Billing jurisdiction"
          className="col-sm-6"
          required
          stateId={stateId}
          control={control}
        />
      </div>
      <Input
        name="facility_price"
        label="Facility price"
        type="number"
        min={0}
        step={0.01}
        required
        control={control}
      />
      <Input
        name="insurance_price"
        label="Insurance price"
        type="number"
        min={0}
        step={0.01}
        required
        control={control}
      />
      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={!isDirty || isSubmitting}
        />
      </ControlsLayout>
    </form>
  );
};

export default observer(FormTransport);
