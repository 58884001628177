import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import { LayoutSideTitle } from 'components/layout';
import Form from './components/Form';

import {
  AppSettingsStateFormModel,
  storeAppSettingsMaster
} from 'stores/_mobx/systemSetup/masterSetting/appSettingsMaster';
import { URL_MASTER_SETTINGS } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const AppSettingsPage = ({ history }: PropsType) => {
  const {
    appSettings,
    divisionFetching,
    eligibilityFetching,
  } = storeAppSettingsMaster;

  const defaultValues = appSettings;

  const handleSubmit = (appState: AppSettingsStateFormModel) =>
    storeAppSettingsMaster.updateAppSettingsState(appState).then((isSucceed) => {
      if (isSucceed) history.push(URL_MASTER_SETTINGS);
    });

  useEffect(() => {
    storeAppSettingsMaster.getAppState();

    return storeAppSettingsMaster.clearAppSettings;
  }, []);

  return (
    <>
      <LayoutSideTitle title="Application Settings">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <Form defaultValues={defaultValues} onSubmit={handleSubmit} />
      {(divisionFetching || eligibilityFetching) && <SpinnerFixed />}
    </>
  );
};

export default observer(AppSettingsPage);
