import { ReduceStore } from 'flux/utils';
import appDispatcher from 'dispatcher/AppDispatcher';

let buildActionName = function (name) {
  return 'reports/report-creator/tech-exam-count/' + name;
};

export let Actions = {
  LOAD_TECH_EXAM_COUNT_LIST: buildActionName('load-tech-exam-count-list'),
};

class TechExamCountPageStore extends ReduceStore {
  getEmptyDiagram() {
    return [['Data', 'Value']];
  }

  getInitialState() {
    return {
      techExamCountList: [],
      techExamCountDiagram: this.getEmptyDiagram(),
    };
  }

  reduce(state, action) {
    switch (action.type) {
      case Actions.LOAD_TECH_EXAM_COUNT_LIST:
        this.getState().techExamCountList = action.data.contval || [];
        this.getState().techExamCountDiagram =
          action.data.chartval || this.getEmptyDiagram();
        break;
      default:
        break;
    }
    return this.getState();
  }

  areEqual(one, two) {
    return false;
  }
}

const techExamCountPageStore = new TechExamCountPageStore(appDispatcher);
export default techExamCountPageStore;
