import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { FileField } from 'components/form/file';
import { Textarea } from 'components/form/textarea';
import { Select } from 'components/form/select';
import resolver from './validation';

import { storeVaccinationDropdown } from 'stores/_mobx/dropDown/vaccination';

const documentDetails: VaccinationType = {
  document: '',
  comment: '',
  vaccinationTypeId: 0,
};

interface VaccinationType {
  document: File | string;
  comment: string;
  vaccinationTypeId: number;
}

export interface VaccinationPayloadType extends VaccinationType {
  vaccinationName: string;
}

interface PropsType {
  onClose: () => void;
  onVaccinationUpdate: (data: VaccinationPayloadType) => void;
}

const DialogImportVaccination = ({
  onClose,
  onVaccinationUpdate,
}: PropsType) => {
  const { fetching, options } = storeVaccinationDropdown;

  const { control, reset, handleSubmit } = useForm<VaccinationType>({
    resolver,
  });

  const handleClose = () => {
    onClose();
  };

  const handleClickReset = () => {
    reset(documentDetails);
  };

  const handleClickSubmit = () => {
    handleSubmit((payload) => {
      const vaccinationName =
        options.find(({ value }) => value === payload.vaccinationTypeId)
          ?.label || '';

      return onVaccinationUpdate({
        ...payload,
        vaccinationName,
      });
    })();
  };

  useEffect(() => {
    reset(documentDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentDetails]);

  useEffect(() => {
    if (!options.length && !fetching) storeVaccinationDropdown.getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog size="large" handleClose={handleClose}>
      <DialogHeader
        title="Add certificate of vaccination"
        onClose={handleClose}
      />
      <DialogBody>
        <form className="row" onSubmit={handleClickSubmit}>
          <FileField
            name="document"
            label="Certificate"
            accept="application/pdf"
            className="col-md-6 col-lg-4"
            required
            control={control}
          />
          <div />
          <Select
            name="vaccinationTypeId"
            label="Vaccination"
            className="col-md-6 col-lg-4"
            required
            isLoading={fetching}
            options={options}
            control={control}
          />
          <Textarea
            name="comment"
            label="Comments"
            control={control}
            rows={3}
          />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button variant="warning" text="Reset" onClick={handleClickReset} />
        <Button text="Submit" onClick={handleClickSubmit} />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogImportVaccination);
