import { makeObservable, observable, action, computed } from 'mobx';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import { URL_EQUIPMENT_INVENTORY } from 'constant/path/workflow';
interface MenuResponseType {
  app_id: string;
  app_name: string;
  img_url: string;
  refid: string;
}

interface MenuType extends Omit<MenuResponseType, 'refid'> {
  refid: number;
  link: string;
}

interface NoteResponseType {
  bgcolor: string;
  date: string;
  name: string;
  reason: string;
  time: string;
}

export interface NoteType {
  message: string;
  date: string;
  user: string;
}

interface NoteRequestType {
  id: number;
  type: string;
  onlyNew: boolean;
}

interface NotePayload {
  id: number;
  type: string;
  message: string;
}

type RequestPayloadType = [number, string, number];

class EquipmentInventoryMenu {
  fetching: boolean = false;
  master: MenuType[] = [];
  unit: MenuType[] = [];
  notes: NoteType[] = [];

  constructor() {
    makeObservable(this, {
      master: observable,
      unit: observable,
      fetching: observable,
      notes: observable,

      noteCount: computed,

      setMaster: action,
      setUnit: action,
      setFetching: action,
      setNotes: action,
      clearNotes: action.bound,
    });
  }

  setMaster(menu: MenuType[]) {
    this.master = menu;
  }

  setUnit(menu: MenuType[]) {
    this.unit = menu;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setNotes(notes: NoteType[]) {
    this.notes = notes;
  }

  clearNotes() {
    this.notes = [];
  }

  get noteCount() {
    return this.notes.length;
  }

  async getUnitMenu(data: RequestPayloadType) {
    if (this.unit.length) return Promise.resolve();

    try {
      const response = await apiRequest<MenuResponseType[]>({
        url: 'userlogin.LoginMaster.GetInventoryGroup',
        data,
      });

      const menu = response.map((item) => ({
        ...item,
        link: `${URL_EQUIPMENT_INVENTORY}/${item.app_id.toLowerCase()}`,
        refid: Number(item.refid),
      }));

      this.setUnit(menu);
    } catch (e: any) {
      this.setUnit([]);
    }
  }

  async getMasterMenu(data: RequestPayloadType) {
    if (this.master.length) return Promise.resolve();

    try {
      const response = await apiRequest<MenuResponseType[]>({
        url: 'userlogin.LoginMaster.GetInventoryMaster',
        data,
      });

      const menu = response.map((item) => ({
        ...item,
        link: `${URL_EQUIPMENT_INVENTORY}/${item.app_id.toLowerCase()}`,
        refid: Number(item.refid),
      }));

      this.setMaster(menu);
    } catch (e: any) {
      this.setMaster([]);
    }
  }

  getMenu() {
    this.setFetching(true);

    const data: RequestPayloadType = [
      UserProfileStore.getUserId(),
      UserProfileStore.getUserType(),
      UserProfileStore.getUserTypeId(),
    ];

    const promiseMaster = this.getMasterMenu(data);

    const promiseUnit = this.getUnitMenu(data);

    Promise.allSettled([promiseUnit, promiseMaster]).finally(() => {
      this.setFetching(false);
    });
  }

  async getServiceNotes({ id, type, onlyNew }: NoteRequestType) {
    this.setFetching(true);
    try {
      const response = await apiRequest<NoteResponseType[]>({
        url: 'vehicle.EquipmentMaster.GetServiceNotes',
        data: [id, type, onlyNew],
      });

      const notes = response.map((note) => ({
        message: note.reason,
        date: `${note.date} ${note.time}`,
        user: note.name,
      }));

      this.setNotes(notes);
    } catch (e: any) {
      this.setNotes([]);
    } finally {
      this.setFetching(false);
    }
  }

  async addMessage(payload: NotePayload) {
    this.setFetching(true);

    try {
      const response = await apiRequest<null | string>({
        url: 'vehicle.EquipmentMaster.addServiceMessageByType',
        data: [payload.id, payload.type, payload.message],
      });

      if (!response) return true;

      throw Error('Error occurred!');
    } catch (e: any) {
      this.setFetching(false);
      return false;
    }
  }
}

export const storeEquipmentInventoryMenu = new EquipmentInventoryMenu();
