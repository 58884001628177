import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import MaskInput from 'components/form/maskInput';
import { Input } from 'components/form/textField';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { DropDownStateShortName } from 'components/project/dropdown/StateShortName';
import { DropdownLocation } from 'components/project/dropdown/LocationDropdown';
import { OrderTypeCheckboxGroup } from 'components/project/checkboxes/OrderTypeCheckboxes';
import Notification from 'components/modal/Notification';
import resolver from './validation';

import FacilityActions from 'actions/project/FacilityActions';
import { storeDropdownFacility } from 'stores/_mobx/dropDown/facility';

const fieldCN = 'col-md-6';

const defaultValues: FormModel = {
  address: '',
  city: '',
  facilityName: '',
  location: null,
  orderType: {},
  phone: '',
  state: '',
  zipCode: '',
  country: 'USA',
  addType: 'Q',
};

interface FormModel {
  address: string;
  city: string;
  facilityName: string;
  location: number | null;
  orderType: Record<number, boolean>;
  phone: string;
  state: string;
  zipCode: string;
  country: string;
  addType: string;
}

interface PropsType {
  onClose: () => void;
  onSucceed: (facility: number) => void;
}

const DialogAddFacility = ({ onClose, onSucceed }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const handleReset = () => {
    reset(defaultValues);
  };

  const onSubmit = (data: FormModel) => {
    FacilityActions.addNewFacility(data).then((response) => {
      if (response === 'E') {
        Notification.warning("Can't save facility, because it already exists.");
      } else {
        Notification.success('Facility was created');
        storeDropdownFacility.getOptionsActiveFacility();
        onSucceed(response as number);
      }
    });
  };

  const handleClickSubmit = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <Dialog size="large" scrollable={false} handleClose={onClose}>
      <DialogHeader title="Add new facility" onClose={onClose} />
      <DialogBody>
        <form className="row">
          <Input
            name="facilityName"
            label="Facility Name"
            className={fieldCN}
            required
            control={control}
          />
          <Input
            name="address"
            label="Address"
            className={fieldCN}
            required
            control={control}
          />

          <Input
            name="city"
            label="City"
            className={fieldCN}
            required
            control={control}
          />

          <DropDownStateShortName
            name="state"
            label="State"
            required
            className={fieldCN}
            control={control}
          />

          <MaskInput
            name="zipCode"
            format="#####-####"
            label="Zip Code"
            required
            className={fieldCN}
            control={control}
          />
          <DropdownLocation
            name="location"
            label="Location"
            className={fieldCN}
            required
            control={control}
          />

          <MaskInput
            name="phone"
            label="Phone"
            format="###-###-####"
            required
            className={fieldCN}
            control={control}
          />

          <OrderTypeCheckboxGroup
            name="orderType"
            label="Order Type"
            required
            control={control}
          />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          text="Add"
          disabled={!isDirty || isSubmitting}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogAddFacility;
