import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import DispatchRegionForm from './components/Form';

import {
  storeDispatchRegions,
  RegionDetailsType,
} from 'stores/_mobx/systemSetup/dispatchRegions';
import { DISPATCH_REGION as PAGE_ID } from 'constant/pagesId/systemSetup';
import { URL_DISPATCH_REGION } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const DispatchRegionsEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, fetchingOptions, options, region } = storeDispatchRegions;

  const isDataLoading = fetching || fetchingOptions;

  const handleSubmit = (payload: RegionDetailsType) =>
    storeDispatchRegions.updateDispatchRegion(payload).then((error) => {
      if (error) {
        return error;
      }
      history.push(URL_DISPATCH_REGION);
    });

  useEffect(() => {
    storeDispatchRegions.getRegion(Number(params.id));
    storeDispatchRegions.getOptions();

    return storeDispatchRegions.clearRegion;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Location" appId={PAGE_ID.EDIT} />

      <DispatchRegionForm
        defaultValues={region}
        regionOptions={options}
        backUrl={URL_DISPATCH_REGION}
        onSubmit={handleSubmit}
      />

      {isDataLoading && <SpinnerFixed />}
    </>
  );
};

export default observer(DispatchRegionsEditPage);
