import React from 'react';

import ChildModifier, {
  PChildModifier,
  SChildModifier,
} from 'components/form/ChildModifier';

/**
 * Fieldset class allow to set properties from from to child elements, and make your code more short and readable.
 * Fieldset can contain another Fieldset as child.
 *
 * ------------------------------------
 * This two examples are equal:
 * <Form>
 *    <div className='aaa'>
 *         <Text name='a' onSetValue={this.onUpdateModel.bind(this)} value={this.state.model.a} errors={this.state.errors.a} errorMessages={this.state.errorMessages.a}/>
 *         <Text name='b' onSetValue={this.onUpdateModel.bind(this)} value={this.state.model.b} errors={this.state.errors.b} errorMessages={this.state.errorMessages.b}/>
 *         <Text name='c' onSetValue={this.onUpdateModel.bind(this)} value={this.state.model.c} errors={this.state.errors.c} errorMessages={this.state.errorMessages.c}/>
 *    </div>
 * </Form>
 *
 * <Form onCollectValues={this.onUpdateModel.bind(this)} model={this.state.model} errors={this.state.errors} errorMessages={this.state.errorMessages}>
 *    <Fieldset className='aaa'>
 *         <Text name='a' />
 *         <Text name='b' />
 *         <Text name='c' />
 *    </Fieldset>
 * </Form>
 *
 *
 * ------------------------------------
 * instantiation:
 *
 * <Fieldset />
 *
 * <Fieldset formModel={this.state.model.myCompositeObject} onSetValue={this.updateMyCompositeObject.bind(this)} />
 *
 * <Fieldset formModel={this.state.model.myCompositeObject}
 *             onSetValue={this.updateMyCompositeObject.bind(this)}
 *             formErrors={this.state.errors.myCompositeObjectErrors}
 *             formErrorMessages={this.state.errorMessages.myCompositeObjectErrorMessages}
 *             />
 *
 * ------------------------------------
 * var model = {
 *    user: {
 *       name: 'name',
 *       age: 10
 *    }
 *    address: {
 *       city: 'Minsk'
 *       street: 'Lenina'
 *    }
 * }
 * <Form model={model} onSetValue={onSetValue}>
 *     <Fieldset name='user'>
 *          <Text name='name'/>
 *          <Text name='age'/>
 *     </Fieldset>
 *     <Fieldset name='address'>
 *          <Text name='city'/>
 *          <Text name='street'/>
 *     </Fieldset>
 * </Form>
 * ------------------------------------
 * inheritance example:
 * class ChildClass extends Fieldset{
 *    renderChildren() {
 *       return [
 *           <Text name='firstname' />,
 *           <Text name='lastname' />
 *       ].map((child, key) => {return this._cloneChild(child, key)})
 *    }
 * }
 *
 * @param formModel undefined|object
 * @param onSetValue undefined|function(name, value, errors){}
 * @param formErrors undefined|object
 * @param formErrorMessages undefined|object
 */

export interface NewPFieldset extends PChildModifier {
  formId?: string;
  formModel?: { [key: string]: any };
  reactModel?: { [key: string]: any };
  formErrors?: { [key: string]: any };
  reactForm?: { [key: string]: any };
  formErrorMessages?: { [key: string]: any };
  key?: string;
  ref?: any;
  onSetValue?: (
    name: string,
    value: string,
    errors: any,
    errorKeys?: string[],
    event?: Event,
    callback?: () => void
  ) => void;
  value?: string;
  fieldsetName?: string;

  [key: string]: any;
}

export interface PFieldset extends NewPFieldset {
  disabled?: boolean;
  form?: string;
  title?: string;
  name?: string;
  shareProps?: any;
}

export class SFieldset extends SChildModifier {}

export default class Fieldset<
  P extends PFieldset,
  S extends SFieldset
> extends ChildModifier<P, S> {
  render() {
    return (
      <fieldset
        disabled={this.props.disabled || false}
        style={this.props.style}
        className={this.props.className}>
        {this.renderChildren()}
      </fieldset>
    );
  }

  cloneChild(child: any, key: string): React.ReactElement {
    if (!child) {
      return null;
    }
    const { props } = this;
    if (!this.isFormChild(child)) {
      return child;
    }
    const newProps: NewPFieldset = { formId: props.formId };
    const elementProps = child.props;
    if (elementProps.formModel) {
      newProps.formModel = elementProps.formModel;
    } else if (props.formModel) {
      newProps.formModel = props.formModel;
    }
    if (elementProps.reactForm) {
      newProps.reactForm = elementProps.reactForm;
    } else if (props.reactForm) {
      newProps.reactForm = props.reactForm;
    }
    if (elementProps.formErrors) {
      newProps.formErrors = elementProps.formErrors;
    } else if (props.formErrors) {
      newProps.formErrors = props.formErrors;
    }
    if (elementProps.formErrorMessages) {
      newProps.formErrorMessages = elementProps.formErrorMessages;
    } else if (props.formErrorMessages) {
      newProps.formErrorMessages = props.formErrorMessages;
    }
    if (props.shareProps) {
      for (let key in props.shareProps) {
        if (!elementProps[key]) {
          newProps[key] = props.shareProps[key];
        }
      }
    }
    const fieldsetName = props.name;
    if (fieldsetName) {
      newProps.formModel =
        newProps.formModel && newProps.formModel[fieldsetName]
          ? newProps.formModel[fieldsetName]
          : {};
      newProps.formErrors =
        newProps.formErrors && newProps.formErrors[fieldsetName]
          ? newProps.formErrors[fieldsetName]
          : {};
      newProps.formErrorMessages =
        newProps.formErrorMessages && newProps.formErrorMessages[fieldsetName]
          ? newProps.formErrorMessages[fieldsetName]
          : {};
    }

    newProps.key = key;
    newProps.ref = key;
    if (elementProps.onSetValue === undefined) {
      if (fieldsetName) {
        newProps.onSetValue = (
          name,
          value,
          errors,
          errorKeys,
          event,
          callback
        ) => {
          const data: { [key: string]: any } = {};
          data[name] = value;
          const fieldErrors: { [key: string]: any } = {};
          fieldErrors[name] = errors;
          const model = Object.assign({}, newProps.formModel, data);
          const errs = Object.assign({}, newProps.formErrors, fieldErrors);
          props.onSetValue(
            fieldsetName,
            model as any as string,
            errs,
            errorKeys,
            event,
            callback
          );
        };
      } else {
        newProps.onSetValue = props.onSetValue;
      }
    }
    if (elementProps.value === undefined && newProps.formModel) {
      newProps.value = newProps.formModel[elementProps.name];
    }
    if (elementProps.errors === undefined && newProps.formErrors) {
      newProps.errors = newProps.formErrors[elementProps.name];
    }
    if (
      elementProps.errorMessages === undefined &&
      newProps.formErrorMessages
    ) {
      newProps.errorMessages = newProps.formErrorMessages[elementProps.name];
    }
    if (!newProps.fieldsetName && props.name) {
      newProps.fieldsetName = props.name;
    }
    return React.cloneElement(child, newProps);
  }

  getAggregateState(): {
    __isFieldset: boolean;
    name: string;
    values: { [key: string]: any };
  } {
    const out: { [key: string]: any } = {};
    const fieldsetName = this.props.name || this.props.fieldsetName;
    if (this.refs) {
      for (let r in this.refs) {
        if (this.refs.hasOwnProperty(r)) {
          const child: any = this.refs[r];
          if (child.getInputState) {
            out[child.props.name] = child.getInputState(child.getValue());
          } else if (child.getAggregateState) {
            const aggregate = child.getAggregateState();
            if (fieldsetName && !out[fieldsetName]) {
              out[fieldsetName] = {};
            }
            for (let aKey in aggregate.values) {
              if (aggregate.values.hasOwnProperty(aKey)) {
                if (fieldsetName) {
                  out[fieldsetName][aKey] = aggregate.values[aKey];
                } else {
                  out[aKey] = aggregate.values[aKey];
                }
              }
            }
          }
        }
      }
    }
    return {
      __isFieldset: true,
      name: fieldsetName,
      values: out,
    };
  }
}
