import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import Tabs from 'components/tabs';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';
import TabViewBatch from './TabViewBatch';
import TabFormExamDescription from './TabFormExamDescription';
import TabInsuranceInfo from './TabInsuranceInfo';

import { storeClaimReview } from 'stores/_mobx/billingCodding/claimReview';

const tabsList = [
  { label: 'Order Details' },
  { label: 'Exam Description' },
  { label: 'Insurance Info' },
];

interface PropsType {
  title: string;
  claimId: string | number;
  claimType: 'T' | 'P';
  orderId: number;
  onClose: () => void;
}

const DialogClaimPreview = ({
  title,
  claimId,
  claimType,
  orderId,
  onClose,
}: PropsType) => {
  const { fetchingOrderDetails, orderDetails } = storeClaimReview;

  const examDetails = useMemo(
    () => ({
      orderId,
      cptCode: orderDetails?.cptmodifier || '',
      examDescription: orderDetails?.modality_name || '',
      diagnosisDescription: orderDetails?.icddesc || '',
      diagnosisCode: orderDetails?.icdmodifier || '',
      notes: orderDetails?.claimnotes || '',
    }),
    [orderDetails, orderId]
  );

  useEffect(() => {
    storeClaimReview.getOrderDetails({ claimId, claimType });

    return storeClaimReview.clearOrderDetails;
  }, [claimId, claimType]);

  return (
    <Dialog size="extraLarge" handleClose={onClose}>
      <DialogHeader title={title} onClose={onClose} />
      <DialogBody className={fetchingOrderDetails ? 'on-ajax' : ''}>
        <div className="view-claim pt-4">
          <Tabs tabsList={tabsList}>
            <TabViewBatch orderDetails={orderDetails} />

            <TabFormExamDescription examDetails={examDetails} />

            <TabInsuranceInfo orderDetails={orderDetails} />
          </Tabs>
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogClaimPreview);
