import { Controller, Control } from 'react-hook-form';

import PureSwitcher, { PureSwitcherProps } from './PureSwitcher';

/**
  @prop {string} 'data-testid' - data attribute for testing libraries
  If data-testid not presented, then prefix for data-testid attribute will generated with 'name' property
  '{dataTestId}-label' - data attribute for the label
  '{dataTestId}-field' - data attribute for the input field
  '{dataTestId}-error' - data attribute for the error block
*/

export interface Props
  extends Omit<
    PureSwitcherProps,
    'value' | 'name' | 'onChange' | 'onBlur' | 'onFocus'
  > {
  control: Control<any>;
  required?: boolean;
  defaultValue?: string;
  name: string;
}

const Switcher = ({
  control,
  name,
  defaultValue = '',
  required = false,
  ...rest
}: Props) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={{ required }}
    render={({ field, fieldState: { error } }) => {
      const errorMessage = (error && error.message) || '';

      return (
        <PureSwitcher
          errorMessage={errorMessage}
          required={required}
          {...rest}
          {...field}
          checked={field.value}
        />
      );
    }}
  />
);

export default Switcher;
