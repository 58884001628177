import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import Messages from 'components/messages';

import {
  storeCalibrationRecords,
  NoteType,
} from 'stores/_mobx/workflow/serviceMaintenance/calibrationRecords';
import UserProfileStore from 'stores/UserProfileStore';
import CsvExporter from 'utils/CsvExporter';

const cols = [
  { title: 'Note', name: 'message' },
  { title: 'Note by', name: 'user' },
  { title: 'Date On', name: 'date' },
];

const exporter = (notes: NoteType[]) => {
  const csvExporter = new CsvExporter(notes, cols);
  csvExporter.exportAndDownload('user_expense_report');
};

const DialogNotes = observer(() => {
  const { fetchingNote, notes, notesTotal } = storeCalibrationRecords;

  const [newMessage, setNewMessage] = useState<string>('');

  const noMessagesTitle = useMemo(
    () => (!notesTotal && !fetchingNote ? 'No messages' : ''),
    [notesTotal, fetchingNote]
  );

  const handleTypeMessage = (e: React.SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewMessage(value);
  };

  const handleClickExport = () => {
    exporter(notes);
  };

  const handleClickSave = () => {
    storeCalibrationRecords.addNote(UserProfileStore.getUserId(), newMessage);
  };

  return (
    <Dialog size="large" handleClose={storeCalibrationRecords.clearNote}>
      <DialogHeader
        title="Equipment Notes"
        onClose={storeCalibrationRecords.clearNote}
      />
      <DialogBody className={fetchingNote ? 'on-ajax' : ''}>
        <div className="pb-3">
          {notesTotal ? <Messages list={notes} /> : noMessagesTitle}
        </div>
        <div>
          <textarea
            className="form-control"
            placeholder="Type new message"
            value={newMessage}
            rows={4}
            style={{ resize: 'none' }}
            onChange={handleTypeMessage}
          />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          onClick={handleClickSave}
          disabled={!newMessage.length}
          text="Send"
        />
        <Button
          onClick={handleClickExport}
          disabled={!notesTotal}
          text="Export"
        />
      </DialogFooter>
    </Dialog>
  );
});

export default DialogNotes;
