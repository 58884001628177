import React, { useCallback, useMemo } from 'react';
import ReactSelect, { createFilter } from 'react-select';
import clsx from 'clsx';

import { MenuList, Option, SingleValue } from './Components';
import customStyles from './styles';
import { OptionType } from 'types';

const defaultFilterConfig = {
  trim: false,
  matchFrom: 'any' as const,
};

const portalForOptions: HTMLDivElement =
  document.querySelector('#select-option');

/**
  @prop {string} 'data-testid' - data attribute for testing libraries
  If data-testid not presented, then prefix for data-testid attribute will generated with 'name' property
  '{dataTestId}-label' - data attribute for the label
  '{dataTestId}-field' - data attribute for the input field
  '{dataTestId}-error' - data attribute for the error block
*/

export interface PureSelectProps {
  name?: string;
  value?: string | number | null | Array<string | number>;
  options: OptionType[];
  label?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
  required?: boolean;
  className?: string;
  isClearable?: boolean;
  isMulti?: boolean;
  errorMessage?: string;
  optionsWithBadges?: boolean;
  menuPlacement?: 'top' | 'bottom';
  isStyleDependsOnValue?: boolean;
  accurateSearch?: boolean;
  'data-testid'?: string;
  onChange?: (value: any) => void;
  onBlur?: (e: React.SyntheticEvent) => void;
  onFocus?: (e: React.SyntheticEvent) => void;
}

const PureSelect = React.forwardRef<HTMLInputElement, PureSelectProps>(
  (
    {
      name,
      label,
      options,
      placeholder = 'Select...',
      disabled = false,
      isLoading = false,
      required = false,
      isClearable = true,
      className = '',
      errorMessage,
      accurateSearch,
      'data-testid': dataTestId,
      onChange,
      ...rest
    },
    ref
  ) => {
    const cn = clsx(
      'formfield-holder',
      'formfield-select',
      { required },
      className
    );

    const filterConfig = useMemo(
      () => ({
        ...defaultFilterConfig,
        matchFrom: accurateSearch ? ('start' as const) : ('any' as const),
      }),
      [accurateSearch]
    );

    const value = useMemo(() => {
      if (rest.isMulti) {
        return Array.isArray(rest.value)
          ? rest.value?.map((value: string | number) =>
              options.find((el) => el.value === value)
            )
          : [];
      }
      return options.find(({ value }) => value === rest.value) || null;
    }, [options, rest.value, rest.isMulti]);

    const testAttr = dataTestId || name;

    const handleChange = useCallback(
      (option: any) => {
        if (onChange) {
          const value = Array.isArray(option)
            ? option.map(({ value }) => value)
            : option
            ? option.value
            : null;
          onChange(value);
        }
      },
      [onChange]
    );

    return (
      <div className={cn}>
        {label ? (
          <div className="form-label">
            <label data-testid={`${testAttr}-label`}>{label}</label>
          </div>
        ) : null}
        <div className="formfield-input" data-testid={testAttr} ref={ref}>
          <ReactSelect
            {...rest}
            menuPosition="fixed"
            menuPortalTarget={portalForOptions}
            closeMenuOnScroll={(e: any) =>
              !e.target?.classList?.contains('react-select__options-list')
            }
            styles={customStyles}
            onChange={handleChange}
            value={value}
            isDisabled={disabled}
            isClearable={isClearable}
            placeholder={placeholder}
            isLoading={isLoading}
            className="react-select"
            classNamePrefix="react-select"
            options={options}
            data-testid={`${testAttr}-field`}
            filterOption={createFilter(filterConfig)}
            // @ts-ignore
            components={{ MenuList, Option, SingleValue }}
          />
        </div>
        {errorMessage && (
          <div className="error" data-testid={`${testAttr}-error`}>
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

export default PureSelect;
