import GregorianCalendar from 'gregorian-calendar';

import TimeSheetPageService, {
  TTimeSheetFileType,
  TTimeSheetDoc,
  TTimeSheetFullInfoDoc,
  TTimeSheetCalendar,
  TTimeSheetUpdateCalendarDate,
  TWeekNotes,
  TTimeSheet,
} from 'services/profile/personal/TimeSheetPageService';
import appDispatcher from 'dispatcher/AppDispatcher';
import DateUtils, { dateToLocalTimezone } from 'utils/DateUtils';

export default class TimeSheetPageActions {
  static saveTimeSheet(
    userId: string,
    timeSheetCalendar: Array<TTimeSheetCalendar>,
    updateCalendarDate: TTimeSheetUpdateCalendarDate,
    weekNotes: TWeekNotes
  ) {
    return TimeSheetPageService.saveTimeSheet(
      userId,
      timeSheetCalendar,
      updateCalendarDate,
      weekNotes
    ).then((response) => {
      appDispatcher.dispatch({
        type: 'my-profile-save-week-data',
        data: response,
      });
    });
  }

  static loadTimeSheet(
    userId: string,
    month: number,
    week: number,
    year: number
  ) {
    return TimeSheetPageService.loadTimeSheet(userId, month, week, year).then(
      (response: TTimeSheet) => {
        appDispatcher.dispatch({
          type: 'load-time-sheet-data',
          data: response,
        });
        return response;
      }
    );
  }

  static clockIn() {
    return TimeSheetPageService.clockIn();
  }

  static clockOut() {
    return TimeSheetPageService.clockOut();
  }

  static generateCalendar(year: number, month: number, week: number) {
    const calendar: GregorianCalendar = DateUtils.getGregorianCalendar(
      year,
      month - 1,
      1
    );
    (calendar as any).addDayOfWeek(week * 7);
    (calendar as any).addDayOfWeek(-1 * (calendar as any).getDayOfWeek());
    return calendar;
  }

  static getAndIncrement(calendar: GregorianCalendar) {
    const out = DateUtils.doDateString(calendar);
    (calendar as any).addDayOfWeek(1);
    return out;
  }

  static clear() {
    appDispatcher.dispatch({
      type: 'load-time-sheet-data',
      data: new TTimeSheet(),
    });
  }

  static loadUserDocs(userId: string, date: string, type: TTimeSheetFileType) {
    return TimeSheetPageService.loadUserDocs(userId, date, type).then(
      (response: Array<TTimeSheetFullInfoDoc>) => {
        const documentsList = Array.isArray(response)
          ? response.map((doc) => ({
              ...doc,
              created: dateToLocalTimezone({
                date: doc.created,
              }),
            }))
          : response;

        appDispatcher.dispatch({
          type: 'load_user_docs',
          data: documentsList,
        });
        return documentsList;
      }
    );
  }

  static submitTimeSheet(
    userId: string,
    submit: boolean,
    dateFrom: string,
    dateTo: string
  ) {
    return TimeSheetPageService.submitTimeSheet(
      userId,
      submit,
      dateFrom,
      dateTo
    );
  }

  static saveUserDocs(
    userId: string,
    date: string,
    type: TTimeSheetFileType,
    docsList: Array<TTimeSheetDoc>
  ) {
    return TimeSheetPageService.saveUserDocs(userId, date, type, docsList).then(
      (response: Array<TTimeSheetFullInfoDoc>) => {
        appDispatcher.dispatch({
          type: 'load_user_docs',
          data: response,
        });
        return response;
      }
    );
  }
}
