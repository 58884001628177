import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import TileMenu from 'components/TileMenu';

import { storeEquipmentInventoryMenu } from 'stores/_mobx/workflow/equipmentInventory/menu';
import { EI as PAGE_ID } from 'constant/pagesId/workflow';

const EquipmentInventoryPage = () => {
  const { fetching, master, unit } = storeEquipmentInventoryMenu;

  useEffect(() => {
    if (!fetching) storeEquipmentInventoryMenu.getMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Equipment Inventory" />

      {fetching ? (
        <SpinnerFixed />
      ) : (
        <>
          <h4>Masters</h4>
          <div className="equipments-menu">
            {master.map((menu) => (
              <TileMenu key={menu.refid} settings={menu} />
            ))}
          </div>

          <h4>Units</h4>
          <div className="equipments-menu">
            {unit.map((menu) => (
              <TileMenu key={menu.refid} settings={menu} />
            ))}
          </div>
        </>
      )}
    </>
  );
};
export default observer(EquipmentInventoryPage);
