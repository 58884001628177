import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { RequestServiceTab } from 'page/workflow/checkOutIn';
import Tabs from 'components/tabs';
import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import TaskManagerTab from './TaskManagerTab';

import { TASK_MANAGER as PAGE_ID } from 'constant/pagesId/workflow';

const tabsList = [
  { label: 'Task Manager', url: '?tab=tm' },
  { label: 'Request Service', url: '?tab=rs' },
];

const TaskManagerPage = () => {
  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const { search } = useLocation();

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Task Manager">
        {search === tabsList[0].url && (
          <Button
            variant="default"
            text={isFilterVisible ? 'Hide filter' : 'Show filter'}
            onClick={handleToggleFilter}
          />
        )}
      </LayoutSideTitle>
      <Tabs className="pt-4" tabsList={tabsList}>
        <TaskManagerTab isFilterVisible={isFilterVisible} />
        <RequestServiceTab />
      </Tabs>
    </>
  );
};

export default TaskManagerPage;
