import Checkbox from 'components/form/input/Checkbox';

export default class RadioButton extends Checkbox {
  getCheckBoxAttributes() {
    const out = super.getCheckBoxAttributes();
    out.value = this.props.value;
    return out;
  }

  getInputType() {
    return 'radio';
  }

  setValue(value: any, event: any) {
    const { props } = this;
    if (typeof props.onSetValue === 'function') {
      let { errorMessages, errorKeys } = this.validate(value) as any as {
        errorMessages: any;
        errorKeys: any;
      };
      props.onSetValue(
        props.name,
        props.value,
        value,
        errorMessages,
        errorKeys,
        event
      );
    }
  }
}
