import { getDateRangeBounds } from 'utils/DateUtils';
import { apiRequest } from '../RequestService';

class SummaryTechnologistService {
  loadOverview(filter, pagination) {
    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const data = {
      patientFirstName: filter.patientFirstName,
      patientLastName: filter.patientLastName,
      techId: filter.techId,
      dosFrom: dos.dateFrom,
      dosTo: dos.dateTo,
      facility: filter.facility,
      pos: filter.pos,
      stateId: filter.stateId,
      locId: filter.locId,
      examType: filter.examType,
      skip: pagination.skip,
      limit: pagination.pageSize,
    };

    return apiRequest({
      url: 'facility.FacilityBilling.SummaryByTechList',
      data,
    });
  }

  createPdf(dataSource, columns) {
    return apiRequest({
      url: 'pdfcreater.reportPdfGenerator.summaryTechnologistPdf',
      data: {
        dataSource,
        columns,
      },
    });
  }
}

export default new SummaryTechnologistService();
