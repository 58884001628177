import Dialog, { DialogHeader, DialogBody } from 'components/modal/dialog';

import { SecurityDetailsType } from 'stores/_mobx/users/user';

interface PropsType {
  details: SecurityDetailsType;
  onClose: () => void;
}

const DialogSecurityDetails = ({ details, onClose }: PropsType) => (
  <Dialog handleClose={onClose}>
    <DialogHeader title="Security Details" onClose={onClose} />
    <DialogBody>
      <ul>
        <li>
          <b>User ID</b> {details.refid}
        </li>
        <li>
          <b>User Name</b> {details.user_name}
        </li>
        <li>
          <b>Password</b> {details.user_pass}
        </li>
        <li>
          <b>Pin</b> {details.ipin}
        </li>
      </ul>
    </DialogBody>
  </Dialog>
);

export default DialogSecurityDetails;
