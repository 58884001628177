import { useMemo, useState } from 'react';

import { Grid } from 'components/grid';
import getColumns, { CellFieldPayload } from './InvoiceGridColumns';
import InvoiceGridFilter, {
  defaultFilter,
  FilterType,
} from './InvoiceGridFilter';

export { type CellFieldPayload };

const groupByOptions = ['patientname', 'modifiedOrderId'];

interface PropsType {
  dataSource: any[];
  dataSourceErrors: Record<string, string>;
  postType: 'L' | 'B' | null;
  onUpdateInvoice: (payload: CellFieldPayload) => void;
  onDisplayLog: (orderId: number) => void;
}

const InvoiceGrid = ({ dataSource, ...rest }: PropsType) => {
  const [filter, setFilter] = useState<FilterType>(defaultFilter);

  const columns = useMemo(
    () => getColumns(rest),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rest.postType, rest.dataSourceErrors]
  );

  const filteredInsurances = useMemo(() => {
    const filterData = Object.entries(filter);

    return dataSource.filter((invoice: any) => {
      const result = filterData.every(([key, value]) =>
        value
          ? invoice[key]?.toLowerCase().startsWith(value.toLowerCase())
          : true
      );

      return result;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, dataSource, rest.dataSourceErrors]);

  return (
    <div className="mt-5">
      <InvoiceGridFilter defaultValues={filter} onChangeFilter={setFilter} />

      <Grid
        id="charge_posting_grid"
        columns={columns}
        dataSource={filteredInsurances}
        disablePagination
        stateless
        groupBy={groupByOptions}
      />
    </div>
  );
};

export default InvoiceGrid;
