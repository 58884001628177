import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { FileField } from 'components/form/file';
import { CheckboxGroup } from 'components/form/checkbox';
import { Textarea } from 'components/form/textarea';
import resolver from './validation';

import {
  storeOrderDocuments,
  defaultDocumentDetailsValue,
  DocumentDetailsType,
  UploadDocumentFormType,
} from 'stores/_mobx/workflow/order/documents';
import { storeDocumentType } from 'stores/_mobx/systemSetup/masterSetting/documentType';

interface PropsType {
  documentId: number | 'new';
  onClose: () => void;
  onDocumentUpdate: (data: UploadDocumentFormType) => void;
}

const DialogImportDocument = ({
  documentId,
  onClose,
  onDocumentUpdate,
}: PropsType) => {
  const { fetchingDocumentDetails, documentDetails } = storeOrderDocuments;

  const { fetchingOptions, options } = storeDocumentType;

  const { control, reset, handleSubmit } = useForm<DocumentDetailsType>({
    resolver,
  });

  const isNewDoc = documentId === 'new';

  const fetching = fetchingDocumentDetails || fetchingOptions;

  const handleClose = () => {
    if (!fetching) onClose();
  };

  const handleClickReset = () => {
    reset(documentDetails);
  };

  const handleClickSubmit = () => {
    handleSubmit((payload: DocumentDetailsType) =>
      onDocumentUpdate({
        payload,
        options,
      })
    )();
  };

  useEffect(() => {
    reset(documentDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentDetails]);

  useEffect(() => {
    if (isNewDoc) {
      storeOrderDocuments.setDocumentDetails({
        ...defaultDocumentDetailsValue,
        id: 0,
      });
    } else {
      storeOrderDocuments.getDocumentDetails(documentId);
    }

    storeDocumentType.getOptions();

    return storeOrderDocuments.clearDocumentDetails;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  return (
    <Dialog size="large" handleClose={handleClose}>
      <DialogHeader
        title={isNewDoc ? 'Add Document' : 'Edit Document'}
        onClose={handleClose}
      />
      <DialogBody className={fetching ? 'on-ajax' : ''}>
        <form className="row" onSubmit={handleClickSubmit}>
          <FileField
            name="file"
            accept=".doc, .xls, .pdf, .txt"
            className="col-md-6 col-lg-4"
            required
            control={control}
          />
          <CheckboxGroup
            className="part-inline"
            label="Document type"
            name="documentsType"
            required
            options={options}
            control={control}
          />
          <Textarea
            name="comments"
            label="Comments"
            control={control}
            rows={3}
          />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Submit" disabled={fetching} onClick={handleClickSubmit} />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogImportDocument);
