import Notification from 'components/modal/Notification';
import { BASE_URL_FILE_DIR } from 'constant/config';
import { makeObservable, observable, action, computed, toJS } from 'mobx';
import { apiRequest } from 'services/RequestService';
import { storeImport } from 'stores/_mobx/import';

export const defaultCompanyValues: FormCompanyType = {
  address: '',
  billingaddress: '',
  billingcity: '',
  billingemail: '',
  billingfax: '',
  billingphone: '',
  billingstate: '',
  billingtollfree: '',
  billingzip: '',
  city: '',
  comment: '',
  company_name: '',
  company_users: [],
  email: '',
  fax: '',
  npi: '',
  npiDetailsList: [],
  phone: '',
  refid: 0,
  requestmail: '',
  state: '',
  tollfreenum: '',
  web_site: '',
  zipcode: '',
  sameAsFacility: false,
  logoExternal: '',
  logoInternal: '',
  logoDocument: '',
};

interface CompanyUserType {
  admin: number;
  cellphone: string;
  email: string;
  firstname: string;
  lastname: string;
}

interface NpiDetailType {
  refid?: number;
  desc: string;
  npi: string;
}

interface CompanyDetailsType {
  address: string;
  billingaddress: string;
  billingcity: string;
  billingemail: string;
  billingfax: string;
  billingphone: string;
  billingstate: string;
  billingtollfree: string;
  billingzip: string;
  city: string;
  comment: string;
  company_name: string;
  company_users: CompanyUserType[];
  email: string;
  fax: string;
  logo: string;
  logo_internalpage: string;
  logo_name: string;
  npi: string;
  npiDetailsList: NpiDetailType[];
  pdf_logo: string;
  phone: string;
  refid: number;
  requestmail: string;
  state: string;
  tollfreenum: string;
  web_site: string;
  zipcode: string;
}

export interface FormCompanyType
  extends Omit<
    CompanyDetailsType,
    'logo' | 'logo_internalpage' | 'pdf_logo' | 'logo_name'
  > {
  sameAsFacility: boolean;
  logoExternal: string | File;
  logoInternal: string | File;
  logoDocument: string | File;
}

class CompanyInformation {
  fetching: boolean = false;
  company?: FormCompanyType;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      company: observable,

      companyDetails: computed,

      setFetching: action,
      setCompany: action,
      clearCompany: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setCompany(company: FormCompanyType) {
    this.company = company;
  }

  clearCompany() {
    this.company = null;
  }

  get companyDetails() {
    return toJS(this.company);
  }

  async getCompanyInfo() {
    this.setFetching(true);
    try {
      const [{ pdf_logo, logo, logo_internalpage, logo_name, ...response }] =
        await apiRequest<[CompanyDetailsType]>({
          url: 'generalmaster.Admininfo.Get_companydetails',
        });

      const company = {
        ...response,
        npiDetailsList: response.npiDetailsList.length
          ? response.npiDetailsList.map((npiData) =>
              npiData.npi === '0'
                ? { npi: '', desc: '', refid: Number(npiData.refid) }
                : {
                    ...npiData,
                    refid: Number(npiData.refid),
                  }
            )
          : [{ npi: '', desc: '' }],
        refid: Number(response.refid),
        logoExternal: logo
          ? `${BASE_URL_FILE_DIR}${logo.replace(
              /^\//,
              ''
            )}?${window.performance.now()}`
          : `assets/images/theme/logo.png`,
        logoInternal: logo_internalpage
          ? `${BASE_URL_FILE_DIR}${logo_internalpage.replace(
              /^\//,
              ''
            )}?${window.performance.now()}`
          : `assets/images/theme/logo_internalpage.png`,
        logoDocument: pdf_logo
          ? `${BASE_URL_FILE_DIR}${pdf_logo.replace(
              /^\//,
              ''
            )}?${window.performance.now()}`
          : `assets/images/theme/pdf_logo.jpg`,
        sameAsFacility: false,
      };

      this.setCompany(company);
    } catch (e: any) {
      this.setCompany(null);
    } finally {
      this.setFetching(false);
    }
  }

  async updateCompanyInformation({
    logoDocument,
    logoExternal,
    logoInternal,
    ...rest
  }: FormCompanyType) {
    this.setFetching(true);

    try {
      const loginPageLogoFlagTemp = typeof logoExternal !== 'string';
      const applicationHeaderLogoFlagTemp = typeof logoInternal !== 'string';
      const pdfReportsLogoFlagTemp = typeof logoDocument !== 'string';
      const promiseExternal =
        typeof logoExternal === 'string'
          ? Promise.resolve('')
          : storeImport.uploadFile({ file: logoExternal as File });
      const promiseInternal =
        typeof logoInternal === 'string'
          ? Promise.resolve('')
          : storeImport.uploadFile({ file: logoInternal as File });
      const promiseDocument =
        typeof logoDocument === 'string'
          ? Promise.resolve('')
          : storeImport.uploadFile({ file: logoDocument as File });

      const [
        defaultFileNameLoginPageLogo,
        applicationHeaderLogoName,
        pdfReportsLogoName,
      ] = await Promise.all([
        promiseExternal,
        promiseInternal,
        promiseDocument,
      ]);

      const payload = {
        ...rest,
        loginPageLogoFlagTemp,
        applicationHeaderLogoFlagTemp,
        defaultFileNameLoginPageLogo,
        pdfReportsLogoFlagTemp,
        loginPageLogoNameOld: (
          this.companyDetails.logoExternal as string
        ).startsWith('assets/images/')
          ? ''
          : (this.companyDetails.logoExternal as string).replace(
              BASE_URL_FILE_DIR,
              '/'
            ),
        applicationHeaderLogoNameOld: (
          this.companyDetails.logoInternal as string
        ).startsWith('assets/images/')
          ? ''
          : (this.companyDetails.logoInternal as string).replace(
              BASE_URL_FILE_DIR,
              '/'
            ),
        pdfReportsLogoNameOld: (
          this.companyDetails.logoDocument as string
        ).startsWith('assets/images/')
          ? ''
          : (this.companyDetails.logoDocument as string).replace(
              BASE_URL_FILE_DIR,
              '/'
            ),
        pdfReportsLogoName,
        applicationHeaderLogoName,
      };

      const response = await apiRequest<'S' | 'SE'>({
        url: 'generalmaster.Admininfo.Save_userdata',
        data: [payload],
      });
      if (response === 'S') {
        Notification.success('Company information updated!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeCompanyInformation = new CompanyInformation();
