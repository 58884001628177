import React from 'react';

import Checkboxes from 'components/form/input/Checkboxes';

export interface PAbstractCheckboxes {
  name?: string;
  label?: string;
  value?: string | string[] | number;
  attr?: any;
  className?: string;
  validations?: any;
  errorMessages?:
    | string
    | { required?: string; length?: string; positive?: string; custom?: string }
    | Array<any>;
}

export class SAbstractCheckboxes {
  options: Array<any> = [];
}

export default abstract class AbstractCheckboxes<
  P extends PAbstractCheckboxes,
  S extends SAbstractCheckboxes
> extends React.Component<P, S> {
  storeToken: any = null;

  constructor(props: P) {
    super(props);
    const state = new SAbstractCheckboxes();
    state.options = this.getOptions();
    this.state = state as S;
  }

  abstract getOptions(): Array<any>;
  abstract loadOptions(): any;
  abstract getStore(): any;

  render() {
    return (
      <Checkboxes
        {...this.props}
        ref="input"
        options={this.getOptions()}
        attr={this.getAttributes()}
      />
    );
  }

  getAttributes() {
    if (!this.isOnAjax()) {
      return this.props.attr;
    } else {
      if (!this.props.attr) {
        return { className: 'on-ajax-line', disable: true };
      } else {
        let className =
          (this.props.attr.className ? this.props.attr.className : '') +
          ' on-ajax-line';
        return { ...this.props.attr, disable: true, className };
      }
    }
  }

  isOnAjax() {
    try {
      return this.getOptions().length === 0;
    } catch (e) {
      throw Error('Error with options in ' + this.props.name);
    }
  }

  getValue() {
    return this.props.value;
  }

  getInputState() {
    return this.refs.input
      ? (this.refs.input as any).getInputState(this.props.value)
      : null;
  }
}
