import DateUtils from 'utils/DateUtils';

const SLASH_DATE_TIME_FORMAT = 'm/d/y h:M:s';
const DASH_DATE_TIME_FORMAT = 'd-m-y h:M:s';

interface CacheObject {
  cacheObject: any;
  ds: any;
  propsDataSource: any;
  stateSort: any;
  propsColumns: Array<any>;
}

export default class DatasourceSorter {
  static cache: Array<CacheObject> = [];

  static putToCache(
    ds: any,
    propsDataSource: any,
    stateSort: any,
    propsColumns: Array<any>,
    cacheObject: any
  ) {
    DatasourceSorter.cache.push({
      cacheObject: cacheObject,
      ds: ds,
      propsDataSource: propsDataSource,
      stateSort: stateSort,
      propsColumns: propsColumns,
    });
  }

  static cacheLookup(
    propsDataSource: any,
    stateSort: any,
    propsColumns: Array<any>,
    cacheObject: any
  ) {
    let length = DatasourceSorter.cache.length;
    while (length--) {
      const obj = DatasourceSorter.cache[length];
      if (obj.cacheObject === cacheObject) {
        if (
          obj.propsDataSource === propsDataSource &&
          obj.propsColumns === propsColumns &&
          obj.stateSort === stateSort
        ) {
          return obj.ds;
        } else {
          DatasourceSorter.cache.splice(length, 1);
        }
      }
    }
    return null;
  }

  static clearCache(cacheObject: any) {
    let length = DatasourceSorter.cache.length;
    while (length--) {
      let obj = DatasourceSorter.cache[length];
      if (obj.cacheObject === cacheObject) {
        DatasourceSorter.cache.splice(length, 1);
      }
    }
  }

  static getSorterDataSource(
    propsDataSource: any,
    stateSort: any,
    propsColumns: any,
    cacheObject: any
  ) {
    let out = DatasourceSorter.cacheLookup(
      propsDataSource,
      stateSort,
      propsColumns,
      cacheObject
    );
    if (out !== null) {
      return out;
    }
    if (!propsDataSource || !propsDataSource.length) {
      return [];
    }
    let ds = [...propsDataSource];
    let column: { [key: string]: any } = {};
    let direction = stateSort.direction;
    for (let i = 0; i < propsColumns.length; i++) {
      let col = propsColumns[i];
      if (stateSort.column === col.name) {
        column = col;
        break;
      }
    }
    ds.sort((i, v) => {
      let candidate1 = i[stateSort.column],
        candidate2 = v[stateSort.column];
      candidate1 = DatasourceSorter.castSortCandidate(candidate1, column);
      candidate2 = DatasourceSorter.castSortCandidate(candidate2, column);
      if (column.sorter) {
        return column.sorter(candidate1, candidate2, direction, i, v);
      } else {
        if (candidate1 > candidate2) {
          return direction;
        }
        if (candidate1 < candidate2) {
          return -direction;
        }
        return 0;
      }
    });
    DatasourceSorter.putToCache(
      ds,
      propsDataSource,
      stateSort,
      propsColumns,
      cacheObject
    );
    return ds;
  }

  static castSortCandidate(candidate: any, column: any) {
    if (column.sorter) {
      return candidate;
    }
    if (column.dataType === 'date') {
      if (!candidate) return 0;
      const out = DateUtils.parseDate(
        candidate,
        candidate.indexOf('/') > -1 ? '/' : '-'
      );
      return out ? out.getTime() : 0;
    }
    if (column.dataType === 'datetime') {
      if (!candidate) return 0;
      const separator = candidate.indexOf('/') > -1 ? '/' : '-';
      let format;
      if (separator === '/') {
        format = SLASH_DATE_TIME_FORMAT;
      } else {
        format = DASH_DATE_TIME_FORMAT;
      }
      const out = DateUtils.parseDateTime(candidate, format);
      return out ? out.getTime() : 0;
    }
    let type = typeof candidate;
    if (type === 'string') {
      return candidate.toLowerCase();
    }
    if (type === 'number') {
      return candidate;
    }
    if (candidate === null || candidate === undefined) {
      return '';
    }
    return candidate.toString().toLowerCase();
  }
}
