import { forwardRef, useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { Checkbox } from 'components/form/checkbox';
import { Input } from 'components/form/textField';
import Dialog, { DialogHeader, DialogBody } from 'components/modal/dialog';
import {
  defaultValueForGridColumnVisibility,
  checkboxValuePrettier,
} from './helpers';
import {
  WrappedColumnSettingsPropsType,
  ColumnSettingsPropsType,
} from './types';

const DialogColumnStateVisibility = forwardRef(
  ({ defaultValues, onClose }: ColumnSettingsPropsType, ref: any) => {
    const { control, watch, reset, setValue } = useForm<
      ColumnSettingsPropsType['defaultValues']
    >({
      defaultValues,
    });

    const { fields } = useFieldArray({
      control,
      name: 'columns',
    });

    const search = watch('search');

    const foundColumns = useMemo(() => {
      return fields.map(({ label }) =>
        search ? label.toLowerCase().includes(search.toLowerCase()) : true
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
      const subscription = watch((formValue, { name, type }) => {
        if (type !== 'change') return null;

        if (name?.startsWith('columns.')) {
          const idx = Number(name.replace(/[\D]/g, ''));

          const column = formValue.columns[idx];

          const isAllSelected = formValue.columns.every((col) => col.isVisible);

          if (isAllSelected !== formValue.selectAll)
            setValue('selectAll', isAllSelected);

          ref.current.columnApi.applyColumnState({
            state: [
              {
                colId: column.colId,
                hide: !column.isVisible,
              },
            ],
          });
        } else if (name === 'selectAll') {
          const { columns, state } = checkboxValuePrettier(
            formValue as Required<ColumnSettingsPropsType['defaultValues']>
          );

          const newFormValue = {
            ...formValue,
            columns,
          };

          ref.current.columnApi.applyColumnState({
            state,
          });

          reset(newFormValue);
        }
      });
      return subscription.unsubscribe;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    return (
      <Dialog size="large" handleClose={onClose}>
        <DialogHeader title="Column visibility options" onClose={onClose} />
        <DialogBody>
          <div className="row">
            <Checkbox
              name="selectAll"
              label="Select all"
              className="col-sm-4 control-input"
              control={control}
            />
            <Input
              name="search"
              placeholder="Search for column"
              className="col-sm-8 control-filter"
              autoFocus
              onKeyPress={(event: React.KeyboardEvent<object>) => {
                if (event.key === 'Escape' || event.key === 'Enter') {
                  onClose();
                }
              }}
              control={control}
            />
          </div>
          <div className="row">
            {fields.map((field, index) => (
              <Checkbox
                key={field.id}
                name={`columns.${index}.isVisible`}
                className={`col-sm-4${
                  foundColumns[index] ? '' : ' opacity-25'
                }`}
                disabled={!foundColumns[index]}
                label={field.label}
                control={control}
              />
            ))}
          </div>
        </DialogBody>
      </Dialog>
    );
  }
);

const DialogColumnStateVisibilityWrapper = forwardRef(
  (
    { columnState, columnDefs, ...rest }: WrappedColumnSettingsPropsType,
    ref: any
  ) => {
    const defaultValues = useMemo(
      () => defaultValueForGridColumnVisibility(columnState, columnDefs),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    return (
      <DialogColumnStateVisibility
        {...rest}
        defaultValues={defaultValues}
        ref={ref}
      />
    );
  }
);

export default DialogColumnStateVisibilityWrapper;
