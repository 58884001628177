import React from 'react';
import clsx from 'clsx';
/**
  @prop {string} 'data-testid' - data attribute for testing libraries
  If data-testid not presented, then prefix for data-testid attribute will generated with 'name' property
  '{dataTestId}-label' - data attribute for the label
  '{dataTestId}-field' - data attribute for the input field
  '{dataTestId}-error' - data attribute for the error block
*/

export interface PureSwitcherProps {
  id?: string;
  name?: string;
  label?: string;
  checked?: boolean;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  errorMessage?: string;
  'data-testid'?: string;
  onChange?: (checked: boolean) => void;
  onClick?: (e: React.SyntheticEvent) => void;
  onBlur?: (e: React.SyntheticEvent) => void;
  onFocus?: (e: React.SyntheticEvent) => void;
}

const PureSwitcher = React.forwardRef<HTMLInputElement, PureSwitcherProps>(
  (
    {
      name,
      id,
      disabled = false,
      required = false,
      checked = false,
      label = '',
      className,
      errorMessage,
      'data-testid': dataTestId,
      onChange,
      onClick,
      ...rest
    },
    ref
  ) => {
    const testAttr = dataTestId || name;

    const cn = clsx(
      'formfield-holder form-check form-switch',
      { required },
      className
    );

    const handleChange = (e: React.SyntheticEvent) => {
      const checked = (e.currentTarget as HTMLInputElement).checked;
      if (onChange) {
        onChange(checked);
      }
    };

    return (
      <div className={cn}>
        <label>
          <input
            key={String(checked)} // For some reason after reset form action component get correct properties but wrong css styles apply
            {...rest}
            type="checkbox"
            role="switch"
            className="form-check-input"
            data-testid={`${testAttr}-field`}
            ref={ref}
            name={name}
            disabled={disabled}
            checked={checked}
            onChange={handleChange}
          />
          <span
            className="form-check-label"
            data-testid={`${testAttr}-label`}
            onClick={onClick}>
            {label}
          </span>
        </label>
        {errorMessage && (
          <div className="error" data-testid={`${testAttr}-error`}>
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

export default PureSwitcher;
