import React from 'react';
import { Link } from 'react-router-dom';
import {
  TPiModel,
  TPiModelExt,
} from 'page/workflow/order/order/PatientInformationModel';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';

import {
  TPatient,
  TExamInfoOrderDataExt,
  TExamDetail,
  TFacilityAddressExt,
} from 'services/workflow/order/OrderFormPageService';
import OrderFormPageStore from 'stores/workflow/order/OrderFormPageStore';
import OrderFormPageActions from 'actions/workflow/order/OrderFormPageActions';
import { TPatientRecord } from 'services/patient-records/patient-demographics/PatientRecordPageService';
import {
  PatientInfoType,
  PatientInfoExtendedType,
} from 'stores/_mobx/workflow/order/patient';
import UrlUtils from 'utils/UrlUtils';
import { URL_ORDER_PLACED } from 'constant/path/workflow';

export interface PAbstractOrderPage {
  orderId: number;
  cachedState?: SAbstractOrderPage;
  model?: any;
  showToolTips: boolean;
  onEdit: any;
  lastModifiedLock: string;
  backToListCallBack?: () => void;
}

export class SAbstractOrderPage {
  onAjax: boolean = false;
  orderDataPatient: TPatient = null;
  dataLoaded: boolean = false;
  patientViewInfo: any = null;
  patientViewInfoLoaded: boolean = false;
  errorMessages: any = {};
  showAddPatient: boolean = false;
  showAddFacility: boolean = false;
  showAddHospice: boolean = false;
  showSelectPatient: boolean = false;
  orderTypeDropdown: Array<{ label: string; value: string }> = [];
  facilityAddress: TFacilityAddressExt = new TFacilityAddressExt();
  patientLoaded: boolean = false;
  examData: TExamDetail = new TExamDetail();
  examViewInfoLoaded: boolean = false;
  showOrderModifyWarningNotification: boolean = false;
  model: any = {};
}

export default class AbstractOrderPage<
  P extends PAbstractOrderPage,
  S extends SAbstractOrderPage
> extends React.Component<P, S> {
  static calculateState(
    prevState: SAbstractOrderPage,
    props: PAbstractOrderPage
  ) {
    const orderFormPageStore = OrderFormPageStore.getState();
    if (!prevState) {
      if (props.cachedState) {
        prevState = props.cachedState;
      } else {
        const model = Object.assign({}, props.model);
        if (!model.placeOfService) {
          model.placeOfService = 'PF';
        }
        if (!model.billingResponsibility) {
          model.billingResponsibility = 'I';
        }
        if (!model.hospicePayment) {
          model.hospicePayment = 0;
        }
        const orderDataPatient: TPatient = orderFormPageStore.orderDataPatient;
        const orderDataExamInfo: TExamInfoOrderDataExt =
          orderFormPageStore.orderDataExamInfo;
        model.room = orderDataPatient.room;
        model.FaxPhone = orderDataExamInfo.faxPhoneArr;
        model.isolation = orderDataExamInfo.isolation;
        prevState = Object.assign(new SAbstractOrderPage(), {
          model: model,
          errors: {},
          errorMessages: {},
          showAddPatient: false,
          showAddFacility: false,
          showAddHospice: false,
          showSelectPatient: false,
          orderTypeDropdown: [],
          facilityAddress: new TFacilityAddressExt(),
          patientLoaded: false,
          onAjax: !!props.onEdit,
        });
      }
    }
    if (
      parseInt('' + props.orderId) !== 0 &&
      orderFormPageStore.orderDataPatient &&
      parseInt('' + orderFormPageStore.orderDataPatient.orderId) ===
        parseInt('' + props.orderId)
    ) {
      AbstractOrderPage.initFacilityInModel(
        orderFormPageStore.orderDataPatient,
        prevState.model
      );
    }
    return Object.assign({}, props.cachedState, prevState, {
      facilityAddress: orderFormPageStore.facilityAddress,
      orderTypeDropdown: orderFormPageStore.orderTypeDropdown,
      orderDataPatient: orderFormPageStore.orderDataPatient,
      patientViewInfo: orderFormPageStore.patientViewInfo,
      patientViewInfoLoaded: orderFormPageStore.patientViewInfoLoaded,
      examData: orderFormPageStore.examViewInfo,
      examViewInfoLoaded: orderFormPageStore.examViewInfoLoaded,
    });
  }

  static hasExamData(orderId: number) {
    return (
      '' + OrderFormPageStore.getState().examViewInfo.refid === '' + orderId
    );
  }

  static hasPatientData(orderId: number) {
    if (!AbstractOrderPage.hasExamData(orderId)) {
      return false;
    }
    return (
      '' + OrderFormPageStore.getState().orderDataPatient.orderId ===
      '' + orderId
    );
  }

  static initFacilityInModel(orderDataPatient: TPatient, model: any) {
    if (!model.facility && orderDataPatient.facilityid !== undefined) {
      model.facility = orderDataPatient.facilityid
        ? parseInt('' + orderDataPatient.facilityid)
        : 0;
      model.facilityState = orderDataPatient.state_id
        ? parseInt('' + orderDataPatient.state_id)
        : 0;
      model.facilityPhone = orderDataPatient.facilityphone;
    }
    return model;
  }

  static tryUpdateFacilityFromPatient(
    patient: any,
    model: any,
    flagForcePatientFacility: boolean = false
  ) {
    if (flagForcePatientFacility || !model.facility) {
      model.facility = patient.facility_id
        ? parseInt('' + patient.facility_id)
        : 0;
      model.facilityState = patient.state_id
        ? parseInt('' + patient.state_id)
        : 0;
      model.facilityPhone = patient.facility_phone || patient.fac_phno;
    }
    return model;
  }

  static addPatientToModel(
    patient: TPatientRecord | PatientInfoExtendedType | PatientInfoType,
    thisModel: TPiModel,
    flagForcePatientFacility: boolean = false
  ): TPiModelExt {
    const model = Object.assign(new TPiModelExt(), thisModel);
    const dobData = ('dob' in patient ? patient.dob : patient.birthdate) || '';
    model.dob = dobData.replace(/-/g, '/');
    model.lastName = patient.last_name;
    model.middleName = patient.middle_name;
    model.firstName = patient.first_name;
    model.ssn = patient.ssn;
    model.clientMRN =
      ('client_mrn' in patient ? patient.client_mrn : patient.clientMRN) || '';
    model.patientGender = patient.gender;
    model.patientId = patient.refid || 0;

    AbstractOrderPage.tryUpdateFacilityFromPatient(
      patient,
      model,
      flagForcePatientFacility
    );
    model.faceSheet =
      (patient as any).face_sheet || model.tempFaceSheet || model.faceSheet;

    model.medicaid_id =
      'medicaid_id' in patient ? patient.medicaid_id || '' : '';
    model.medicare_id =
      'medicare_id' in patient ? patient.medicare_id || '' : '';
    model.medicaid_stateid =
      'medicaid_stateid' in patient ? patient.medicaid_stateid || '' : '';
    model.ins_notes = (patient as any).ins_notes;

    model.ice_number = patient.ice_number;
    model.inmate_number = patient.inmate_number;
    model.immigration_id = patient.immigration_id;
    return model;
  }

  componentDidMount() {
    const { props } = this;
    const { orderId } = props;
    if (orderId) {
      OrderFormPageActions.GetExamDetailByOrderId(orderId)
        .then(() => {
          return OrderFormPageActions.loadPatientItemByOrderId(orderId);
        })
        .then(() => {
          const orderDataPatient = this.state.orderDataPatient;
          if (orderDataPatient && orderDataPatient.patientid) {
            return OrderFormPageActions.loadPatientViewInfo(
              orderDataPatient.patientid
            );
          }
          return {};
        })
        .then((r) => this.beforeDataLoaded(r))
        .then(() => {
          this.setState({ dataLoaded: true });
        });
    } else {
      this.setState({
        patientViewInfo: {},
        patientViewInfoLoaded: true,
      });
    }
  }

  beforeDataLoaded(result: any) {
    return result;
  }

  solveBackgroundByCondition(condition: any) {
    if (!this.props.showToolTips) {
      return '';
    }
    if (condition) {
      return '';
    }
    return 'warmInputBackground';
  }

  solveBackground(inputName: any, isDropdown?: boolean) {
    if (!this.props.showToolTips) {
      return '';
    }
    const model = this.getFormModel();
    const value = model[inputName];
    if (!value || (isDropdown && value === '0')) {
      return 'warmInputBackground';
    }
    return '';
  }

  getFormModel() {
    return this.state.model;
  }

  handleCloseDialogWarning = () => {
    this.setState({
      onAjax: false,
      showOrderModifyWarningNotification: false,
    });
  };

  showOrderModifyWarningNotification(callback?: () => void) {
    this.setState(
      { onAjax: false, showOrderModifyWarningNotification: true },
      callback
    );
  }

  renderOrderModifyWarningNotification(): React.ReactNode {
    const { state, props } = this;

    const backUrl = UrlUtils.getQuery('backUrl');

    const url = backUrl || URL_ORDER_PLACED;

    return (
      state.showOrderModifyWarningNotification && (
        <Dialog handleClose={this.handleCloseDialogWarning}>
          <DialogHeader
            title="Warning"
            onClose={this.handleCloseDialogWarning}
          />
          <DialogBody>
            This order has been modified since your recent changes. Please click
            the 'Back to List' button to return to the Workflow screen.
          </DialogBody>
          <DialogFooter>
            {props.backToListCallBack ? (
              <Button
                text="Back to List"
                variant="danger"
                onClick={() => props.backToListCallBack()}
              />
            ) : (
              <Link
                className="btn btn-danger"
                to={url.startsWith('/') ? url : `/${url}`}>
                Back to List
              </Link>
            )}
          </DialogFooter>
        </Dialog>
      )
    );
  }
}
