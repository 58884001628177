import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import { storeEmailDashboard } from 'stores/_mobx/integration/emailDashboard';

const columns = [
  { name: 'refid', title: 'ID', className: 'width-75' },
  {
    name: 'receiver',
    title: 'Name',
    render: (receiver: string) =>
      receiver.length < 30 ? (
        receiver
      ) : (
        <abbr title={receiver}>{`${receiver.substring(0, 27)}...`}</abbr>
      ),
  },
  {
    name: 'rec_email',
    title: 'Email',
    render: (email: string) => (
      <a
        href={`mailto:${email || ''}`}
        onClick={(e) => {
          e.preventDefault();
        }}>
        {email}
      </a>
    ),
  },
  { name: 'subject', title: 'Subject' },
  { name: 'source', title: 'Source' },
  { name: 'lastmodified', title: 'Failed Date/Time', className: 'width-100' },
];

interface PropsType {
  onClose: () => void;
}

const DialogEmailWithError = ({ onClose }: PropsType) => {
  const {
    fetchingReport,
    emailReport,
    emailReportTotal,
    page: { pagination, setPagination },
  } = storeEmailDashboard;

  const [selected, setSelected] = useState<number[]>([]);

  const [isDialogVisible, toggleDialogVisibility] = useState<boolean>(false);

  const callback = (isSucceed: boolean) => {
    toggleDialogVisibility(false);

    if (isSucceed) storeEmailDashboard.getEmailReport();
  };

  const handleClickDelete = useCallback(() => {
    toggleDialogVisibility((state) => !state);
  }, []);

  const handleResendEmail = useCallback(() => {
    storeEmailDashboard.resendEmail(selected).then(callback);
  }, [selected]);

  const handleApproveDeleteEmail = useCallback(() => {
    storeEmailDashboard.deleteEmail(selected).then(callback);
  }, [selected]);

  useEffect(() => {
    storeEmailDashboard.getEmailReport();
  }, [pagination]);

  return (
    <>
      <Dialog size="extraLarge" handleClose={onClose}>
        <DialogHeader title="Emails with Errors" onClose={onClose} />
        <DialogBody>
          <Grid
            id="email_email_grid"
            gridControlPanel={
              <>
                <GridControlButton
                  title="Resend"
                  disabled={!selected.length}
                  onClick={handleResendEmail}
                />
                <GridControlButton
                  title="Delete"
                  disabled={!selected.length}
                  onClick={handleClickDelete}
                />
              </>
            }
            selectId="refid"
            columns={columns}
            onAjax={fetchingReport}
            dataSource={emailReport}
            dataSourceCount={emailReportTotal}
            pagination={pagination}
            onPaginationChange={setPagination}
            onSelectChange={setSelected}
          />
        </DialogBody>
      </Dialog>

      {isDialogVisible && (
        <DialogConfirm
          onCancel={handleClickDelete}
          onApprove={handleApproveDeleteEmail}>
          Selected items will be deleted, and this action can't be undone.
          <br />
          Are you sure you want to continue?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(DialogEmailWithError);
