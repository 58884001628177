import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

interface PrefixType {
  prefix_desc: string;
  prefix_nm: string;
  refid: number;
}

export interface OptionType {
  value: number;
  label: string;
}

class Prefix {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  prefixList: PrefixType[] = [];
  prefixTotal: number = 0;
  prefix?: PrefixType = undefined;
  prefixForDelete: number = 0;
  options: OptionType[] = [];
  filter: string = '';

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'prefix_grid' });

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      prefixList: observable,
      prefixTotal: observable,
      prefix: observable,
      prefixForDelete: observable,
      options: observable,
      filter: observable,

      setFetching: action,
      setFetchingOptions: action,
      setPrefixList: action,
      setPrefixTotal: action,
      setPrefix: action,
      setPrefixForDelete: action,
      setFilter: action.bound,
      clearPrefixForDelete: action.bound,
      clearPrefix: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setPrefixList(list: PrefixType[]) {
    this.prefixList = list;
  }

  setPrefixTotal(count: number) {
    this.prefixTotal = count;
  }

  setPrefix(prefix?: PrefixType) {
    this.prefix = prefix;
  }

  setPrefixForDelete(id: number) {
    this.prefixForDelete = id;
  }

  setFilter(prefixName: string) {
    this.filter = prefixName;
  }

  clearPrefixForDelete() {
    this.prefixForDelete = null;
  }

  clearPrefix() {
    this.prefix = undefined;
  }

  async getPrefixList() {
    const {
      filter: prefixName,
      page: { pagination },
    } = this;

    const data = [pagination.skip, pagination.pageSize, prefixName];

    try {
      const response = await apiRequest<'SE' | PrefixType[]>({
        url: 'generalmaster.Prefix.PrefixList',
        data,
      });

      if (Array.isArray(response)) {
        const prefixList = response.map((prefix) => ({
          ...prefix,
          refid: Number(prefix.refid),
        }));
        this.setPrefixList(prefixList);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setPrefixList([]);
    }
  }

  async getPrefixCount() {
    try {
      const count = await apiRequest<number | string>({
        url: 'generalmaster.Prefix.TotalRecordCount',
        data: [this.filter],
      });

      this.setPrefixTotal(Number(count) || 0);
    } catch (e) {
      this.setPrefixTotal(0);
    }
  }

  getPrefixListMain() {
    this.setFetching(true);

    const promiseList = this.getPrefixList();

    const promiseCount = this.getPrefixCount();

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  }

  async getPrefix(id: number | string) {
    this.setFetching(true);
    try {
      const [prefix] = await apiRequest<[PrefixType]>({
        url: 'generalmaster.Prefix.ViewPrefix',
        data: [id],
      });

      this.setPrefix(prefix);
      return prefix;
    } catch (e) {
      this.setPrefix();
      return null;
    } finally {
      this.setFetching(false);
    }
  }

  async addPrefix(payload: Omit<PrefixType, 'refid'>) {
    try {
      const result = await apiRequest<'SE' | 'E' | 'S'>({
        url: 'generalmaster.Prefix.AddPrefix',
        data: [payload.prefix_nm, payload.prefix_desc],
      });
      if (result === 'E') {
        return { message: 'This Prefix name already exist!' };
      } else if (result === 'S') {
        Notification.success('Prefix was added successfully!');
        return null;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updatePrefix(payload: PrefixType) {
    const data = [payload.refid, payload.prefix_nm, payload.prefix_desc];

    try {
      const result = await apiRequest<'SE' | 'E' | 'S'>({
        url: 'generalmaster.Prefix.EditPrefix',
        data,
      });

      if (result === 'E') {
        return { message: 'This Prefix name already exist!' };
      } else if (result === 'S') {
        Notification.success('Prefix was updated successfully!');
        return null;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async deletePrefix() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.Prefix.DeletePrefix',
        data: [this.prefixForDelete],
      });
      if (result === 'S') {
        Notification.success('Prefix was deleted!');
        this.clearPrefixForDelete();
        return true;
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve([]);

    this.setFetchingOptions(true);

    try {
      const options = await apiRequest<OptionType[]>({
        url: 'generalmaster.Prefix.LoadPrefix',
      });

      runInAction(() => {
        this.options = options;
        this.fetchingOptions = false;
      });
      return options;
    } catch (e: any) {
      runInAction(() => {
        this.options = [];
        this.fetchingOptions = false;
      });
      return [];
    }
  }

  findByLabel = (salutation: string) =>
    this.options.find(
      ({ label }) => salutation.toLowerCase() === label.toLowerCase()
    );
}

export const storePrefix = new Prefix();
