import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import GridActionControl from 'page/components/GridActionControls';
import FilterNameAndStatus, {
  FilterType,
} from 'page/components/FilterNameAndStatus';
import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import DialogServicesNotes from '../components/DialogServicesNotes';

import { storeVehicle } from 'stores/_mobx/workflow/equipmentInventory/vehicle';
import { EI_VEHICLE as PAGE_ID } from 'constant/pagesId/workflow';
import {
  URL_EQUIPMENT_INVENTORY,
  URL_VEHICLE_ADD,
  URL_VEHICLE,
} from 'constant/path/workflow';

const columns = [
  { name: 'vehicle_name', title: 'Name' },
  { name: 'reg_number', title: 'License Plate No.' },
  { name: 'vehicle_model', title: 'Model' },
  { name: 'make_year', title: 'Make year' },
  { name: 'color', title: 'Color' },
  { name: 'vin_number', title: 'VIN number' },
  { name: 'description', title: 'Description' },
  {
    name: 'refid',
    title: 'History',
    render: (id: number) => (
      <div className="control">
        <IconButton onClick={() => storeVehicle.setNoteId(id)}>
          <i className="icon icon-notes" />
        </IconButton>
      </div>
    ),
  },
  {
    name: 'refid',
    title: 'Actions',
    className: 'width-100',
    render: (id: number) => (
      <GridActionControl
        id={id}
        url={`${URL_VEHICLE}/edit/${id}`}
        onDelete={storeVehicle.setIdForDelete}
      />
    ),
  },
];

const VehicleListPage = () => {
  const gridRef = useRef(null);

  const [selected, setSelected] = useState<number[]>([]);

  const {
    fetching,
    noteId,
    idForDelete,
    vehicleList,
    vehicleTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeVehicle;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const fetchData = () => {
    storeVehicle.getVehicleMain();
    if (selected) gridRef.current?.clearSelection();
  };

  const handleConfirmDelete = () => {
    storeVehicle.deleteVehicle().then((isSucceed) => {
      if (isSucceed) {
        fetchData();
      }
    });
  };

  const handleChangeStatus = () => {
    storeVehicle.changeVehicleStatus(selected).then((isSucceed) => {
      if (isSucceed) fetchData();
    });
  };

  const handleChangeFilter = (newFilter: FilterType) => {
    filter.setOptions(newFilter);
    debouncedMethod();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Vehicle">
        <Link to={URL_EQUIPMENT_INVENTORY} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <FilterNameAndStatus
        placeholder="Vehicle Name"
        filter={filter.options}
        fetching={fetching}
        onChangeFilter={handleChangeFilter}
      />

      <Grid
        id="vehicle_grid"
        selectId="refid"
        ref={gridRef}
        columns={columns}
        onAjax={fetching}
        dataSource={vehicleList}
        dataSourceCount={vehicleTotal}
        pagination={pagination}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        gridControlPanel={
          <>
            <GridControlButton title="Add" url={URL_VEHICLE_ADD} />
            <GridControlButton
              title={filter.options.isActive ? 'Deactivate' : 'Activate'}
              disabled={!selected.length}
              onClick={handleChangeStatus}
            />
          </>
        }
      />

      {Boolean(idForDelete) && (
        <DialogConfirm
          onCancel={storeVehicle.clearIdForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this Vehicle?
        </DialogConfirm>
      )}

      {Boolean(noteId) && (
        <DialogServicesNotes
          type="V"
          id={noteId}
          onClose={storeVehicle.clearNoteId}
        />
      )}
    </>
  );
};

export default observer(VehicleListPage);
