import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import PasswordStrengthForm from './components/Form';

import {
  storePasswordStrength,
  PasswordSettingsType,
} from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';
import { URL_MASTER_SETTINGS } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const PasswordStrengthPage = ({ history }: PropsType) => {
  const { fetching, passwordSettings } = storePasswordStrength;

  const handleSubmit = (data: PasswordSettingsType) =>
    storePasswordStrength.savePasswordSettings(data).then((isSucceed) => {
      if (isSucceed) {
        history.push(URL_MASTER_SETTINGS);
      }
    });

  useEffect(() => {
    if (!fetching) storePasswordStrength.getPasswordSettings();

    return storePasswordStrength.clearPasswordSettings;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Password Strength" />
      <PasswordStrengthForm
        onSubmit={handleSubmit}
        defaultValues={passwordSettings}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(PasswordStrengthPage);
