import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

interface PerDiemType {
  refid: number;
  unitdesc: string;
  unitname: string;
}

interface PerDiemEditType {
  perdiem_desc: string;
  perdiem_name: string;
  refid?: number;
}

interface OptionResponseType {
  data: string;
  label: string;
}

export interface OptionType {
  value: number;
  label: string;
}

class PerDiem {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  perDiemList: PerDiemType[] = [];
  perDiemTotal: number = 0;
  perDiem: PerDiemEditType | undefined = undefined;
  perDiemIdForDelete: number = 0;
  options: OptionType[] = [];
  filter: string = '';

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'per_diem_grid' });

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      perDiemList: observable,
      perDiemTotal: observable,
      perDiem: observable,
      perDiemIdForDelete: observable,
      options: observable,
      filter: observable,

      setFetching: action,
      setFetchingOptions: action,
      setPerDiemList: action,
      setPerDiemTotal: action,
      setPerDiem: action,
      setPerDiemForDelete: action,
      setFilter: action.bound,
      clearPerDiemForDelete: action.bound,
      clearPerDiem: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setPerDiemList(list: PerDiemType[]) {
    this.perDiemList = list;
  }

  setPerDiemTotal(count: number) {
    this.perDiemTotal = count;
  }

  setPerDiem(perDiem?: PerDiemEditType) {
    this.perDiem = perDiem;
  }

  setPerDiemForDelete(id: number) {
    this.perDiemIdForDelete = id;
  }

  setFilter(userName: string) {
    this.filter = userName;
  }

  clearPerDiemForDelete() {
    this.perDiemIdForDelete = 0;
  }

  clearPerDiem() {
    this.perDiem = undefined;
  }

  async getPerDiemList() {
    const {
      filter: name,
      page: { pagination },
    } = this;

    const data = [pagination.skip, pagination.pageSize, name];

    try {
      const perDiem = await apiRequest<'SE' | PerDiemType[]>({
        url: 'generalmaster.PerdiemMaster.GetUnitList',
        data,
      });
      if (Array.isArray(perDiem)) {
        const perDiemList = perDiem.map((el) => ({
          ...el,
          refid: Number(el.refid),
        }));
        this.setPerDiemList(perDiemList);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setPerDiemList([]);
    }
  }

  async getPerDiemCount() {
    try {
      const count = await apiRequest<'SE' | 'S' | number>({
        url: 'generalmaster.PerdiemMaster.GetUnitCount',
        data: [this.filter],
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setPerDiemTotal(total);
    } catch (e) {
      this.setPerDiemTotal(0);
    }
  }

  getPerDiemListMain = () => {
    this.setFetching(true);

    const promiseList = this.getPerDiemList();

    const promiseCount = this.getPerDiemCount();

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getPerDiem(id: number | string) {
    this.setFetching(true);
    try {
      const region = await apiRequest<'SE' | [PerDiemEditType]>({
        url: 'generalmaster.PerdiemMaster.GetViewUnit',
        data: [id],
      });
      if (Array.isArray(region)) {
        this.setPerDiem(region[0]);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setPerDiem();
    } finally {
      this.setFetching(false);
    }
  }

  async addPerDiem(payload: PerDiemEditType) {
    const data = [payload.perdiem_name, payload.perdiem_desc];

    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.PerdiemMaster.AddUnit',
        data,
      });
      if (result === 'S') {
        Notification.success('Per Diem unit was added successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This unit name already exist!' };
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updatePerDiem(payload: Required<PerDiemEditType>) {
    const data = [payload.refid, payload.perdiem_name, payload.perdiem_desc];

    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.PerdiemMaster.EditUnit',
        data,
      });

      if (result === 'S') {
        Notification.success('Per Diem unit was updated successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This unit name already exist!' };
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async deletePerDiem() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.PerdiemMaster.DeleteUnit',
        data: [this.perDiemIdForDelete],
      });
      if (result === 'S') {
        Notification.success('Per Diem unit was deleted!');
        this.clearPerDiemForDelete();
        return true;
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    try {
      this.setFetchingOptions(true);

      const response = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.PerdiemMaster.GetPerdiemDropDown',
      });

      const options = response.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storePerDiem = new PerDiem();
