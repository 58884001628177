import React from 'react';

import { Grid } from 'components/grid';
import Form from 'components/form/Form';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';
import YearSelectionDropdown from 'components/project/dropdown/YearSelectionDropdown';
import MonthSelectionDropdown from 'components/project/dropdown/MonthSelectionDropdown';
import TextCalendar from 'components/form/input/TextCalendar';
import Custom from 'components/form/input/Custom';

import { apiRequest } from 'services/RequestService';
import { dateToLocalTimezone } from 'utils/DateUtils';
import { getPagination } from 'utils/getPagination';

const COLUMNS = [
  { name: 'user', title: 'User' },
  { name: 'message', title: 'Message' },
  { name: 'created', title: 'Time' },
];

export default class AuditLog extends React.Component {
  state = {
    filter: {},
    dataSource: [],
    dataSourceCount: 0,
    onAjax: false,
    pagination: getPagination(),
  };

  componentDidMount() {
    this.getAuditLog();
  }

  getAuditLog() {
    const { filter, pagination } = this.state;
    this.setState({ onAjax: true });

    const data = [
      this.props.logId,
      filter,
      pagination.skip,
      pagination.pageSize,
    ];
    apiRequest({ url: 'filemanager.TimeSheet.getAuditLog', data })
      .then((response) => {
        response?.data?.forEach((v) => {
          v.created = dateToLocalTimezone({ date: v.created });
        });
        return response;
      })
      .then((response) => {
        this.setState({
          onAjax: false,
          dataSource: response.data,
          dataSourceCount: response.count,
        });
      });
  }

  handleChangeFilter = (name, value, errors) => {
    const filter = { ...this.state.filter, [name]: value };

    this.setState({ filter });
  };

  setGridFilter() {
    const propsFilter = this.props.filter;
    const filter = {
      year: propsFilter.year,
      month: propsFilter.month,
      dateFrom: propsFilter.dateFrom,
      dateTo: propsFilter.dateTo,
    };
    this.setState({ filter });
  }

  render() {
    const { state } = this;
    return (
      <Dialog size="large" scrollable={false} handleClose={this.props.onClose}>
        <DialogHeader
          title={'Employee Time Sheet Audit Log (' + this.props.logLabel + ')'}
          onClose={this.props.onClose}
        />
        <DialogBody>
          <Form
            model={state.filter}
            id="al_filter"
            onCollectValues={this.handleChangeFilter}
            submit={(m, he) => {
              if (!he) {
                this.getAuditLog();
              }
            }}>
            <YearSelectionDropdown
              className="col-sm-4"
              label="Select Year"
              name="year"
            />
            <MonthSelectionDropdown
              className="col-sm-4"
              label="Select Month"
              name="month"
            />
            <Custom
              label="&nbsp;"
              className="col-sm-4"
              custom={
                <button
                  className="btn btn-light col-sm-4"
                  onClick={() => this.setGridFilter()}>
                  Set Grid Filters
                </button>
              }
            />
            <TextCalendar
              name="dateFrom"
              label="Date From"
              className="col-sm-4"
            />
            <TextCalendar name="dateTo" label="Date To" className="col-sm-4" />
            <Custom
              label="&nbsp;"
              className="col-sm-4"
              custom={
                <input
                  type="submit"
                  className="btn btn-primary col-sm-4"
                  value="Apply"
                />
              }
            />
          </Form>
          <Grid
            stateless
            pagination={this.state.pagination}
            onPaginationChange={(pagination) =>
              this.setState({ pagination }, () => this.getAuditLog())
            }
            dataSource={state.dataSource}
            dataSourceCount={state.dataSourceCount}
            columns={COLUMNS}
            hideSwitcher
            onAjax={state.onAjax}
          />
        </DialogBody>
      </Dialog>
    );
  }
}
