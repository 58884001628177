import { Container } from 'flux/utils';

import AbstractFacilityFormPage, {
  TABS,
  PAbstractFacilityFormPage,
  SAbstractFacilityFormPage,
} from './AbstractFacilityFormPage';
import Notification from 'components/modal/Notification';
import { withDivisionSettings } from 'components/HOC';

import FacilityPageActions from 'actions/system-setup/master-setup/facility/FacilityPageActions';
import FacilityPageStore from 'stores/system-setup/facility/FacilityPageStore';
import { FacilitySettingsType } from 'stores/_mobx/systemSetup/facility';
import { FACILITY_ID as PAGE_ID } from 'constant/pagesId/systemSetup';
import { URL_FACILITY, URL_FACILITY_EDIT } from 'constant/path/systemSetup';

export interface PFacilityAddPage extends PAbstractFacilityFormPage {}

export class SFacilityAddPage extends SAbstractFacilityFormPage {
  loaded: boolean = false;
}

export class FacilityAddPage extends AbstractFacilityFormPage<
  PFacilityAddPage,
  SFacilityAddPage
> {
  getTitle = () => 'Add Facility';

  static getStores() {
    return [FacilityPageStore];
  }

  static calculateState(prevState: SFacilityAddPage) {
    if (!prevState) {
      prevState = Object.assign(new SFacilityAddPage(), {
        loaded: true,
      });
    }
    return AbstractFacilityFormPage.calculateFacilityPageState(prevState);
  }

  componentDidMount() {
    FacilityPageActions.updateTabsState(TABS.FACILITY_TAB, {
      0: false,
      1: true,
      2: true,
      3: true,
    });
  }

  componentWillUnmount() {
    if (
      !window.location.pathname.startsWith(URL_FACILITY_EDIT.replace(':id', ''))
    ) {
      FacilityPageActions.clearAllTabs();
      if (FacilityPageStore.getState().backToList) {
        FacilityPageActions.updateTabsState(TABS.FACILITY_TAB, {
          0: false,
          1: false,
          2: false,
          3: false,
        });
        FacilityPageActions.setBackToList(false);
      }
    }
  }

  onSubmitFacilityTab = async (facilityItem?: any) => {
    return FacilityPageActions.saveFacilityItem(facilityItem).then(
      (facilityId) => {
        this.setState({ updateOverviewRequired: true });
        const isCreated = facilityId !== 'E' && facilityId >= 0;

        if (isCreated) {
          Notification.success('Successfully added!');
          sessionStorage.setItem('modeChanged', 'true');
          FacilityPageActions.loadBillingItem(facilityId);
          this.props.history.push(
            `${URL_FACILITY}/facility/edit/${facilityId}`
          );
          return null;
        } else {
          Notification.danger('Validation failed. The form contains errors.');
        }
        return isCreated
          ? null
          : { facility_nm: ['Facility with this name already exist!'] };
      }
    );
  };

  onSubmitBillingTab(billingItem?: any) {}

  onSubmitContactTab(contactItem?: any) {}

  onSubmitDocumentTab(documentTab?: any) {}

  async onSubmitSettingsTab(settings: FacilitySettingsType) {
    return Promise.resolve(true);
  }

  updateContactTabData() {
    return FacilityPageActions.loadContactList(
      Number(this.props.match.params.id),
      true
    );
  }

  updateDocumentTabData = () => {
    return FacilityPageActions.loadDocumentList(
      Number(this.props.match.params.id)
    );
  };

  getPageAppId() {
    return PAGE_ID.ADD;
  }
}

const facilityAddPage = Container.create(FacilityAddPage);
export default withDivisionSettings(facilityAddPage);
