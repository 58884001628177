import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import DialogConfirm from 'components/modal/dialogConfirm';
import { Button, IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import useDebounce from 'components/hook/useDebounce';
import FilterCheckOut from './components/FilterCheckOut';
import DialogAccessLog from './components/DialogAccessLog';
import DialogStatusLogs from './components/DialogStatusLogs';

import {
  storeCheckOutIn,
  EntryType,
  FilterModel,
} from 'stores/_mobx/workflow/checkOutIn/checkOutIn';
import {
  URL_EQUIPMENT_CHECKOUT_ADD,
  URL_EQUIPMENT_CHECKOUT,
} from 'constant/path/workflow';

const CheckOutTab = () => {
  const {
    fetching,
    entryList,
    entryTotal,
    idForDelete,
    equipmentForCheckIn,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeCheckOutIn;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [statusLogId, setStatusLogId] = useState<number>(0);

  const [accessLogId, setAccessLogId] = useState<number>(0);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleApproveEquipmentCheckIn = () => {
    storeCheckOutIn.updateOdometerValue().then((isSucceed) => {
      if (isSucceed) setPaginationToStart();
    });
  };

  const columns = useMemo(
    () => [
      { name: 'vehicle_name', title: 'Vehicle' },
      { name: 'device_name', title: 'Unit' },
      { name: 'ekg_name', title: 'EKG' },
      { name: 'phone_desc', title: 'Phone' },
      { name: 'reg_number', title: 'License Plate' },
      {
        name: 'technologist',
        title: 'Technologist',
      },
      { name: 'start_odometer', title: 'Starting Odometer' },
      { name: 'check_out_dt', title: 'Check Out Date' },
      {
        name: 'refid',
        title: 'Access Log',
        className: 'text-center',
        render: (id: number) => (
          <div className="control">
            <IconButton onClick={() => setAccessLogId(id)}>
              <i className="icon icon-audit-log" />
            </IconButton>
          </div>
        ),
      },
      {
        name: 'groupid',
        title: 'Status Log',
        className: 'text-center',
        render: (groupId: number) => (
          <div className="control">
            <IconButton onClick={() => setStatusLogId(groupId)}>
              <i className="icon icon-audit-log" />
            </IconButton>
          </div>
        ),
      },
      {
        name: 'sign',
        title: 'Equipment Status',
        render: (value: string, equipmentDetails: EntryType) =>
          value === 'Out' ? (
            <Button
              text="Out"
              key={equipmentDetails.refid}
              onClick={() =>
                storeCheckOutIn.setEquipmentForCheckIn(equipmentDetails)
              }
            />
          ) : (
            value
          ),
      },
      {
        name: 'refid',
        title: 'Action',
        render: (id: number, data: EntryType) => (
          <div className="control">
            {data.sign === 'Out' ? (
              <Link
                className="bi bi-pencil"
                to={`${URL_EQUIPMENT_CHECKOUT}/edit/${id}/M`}
              />
            ) : null}
            <div className="control">
              <IconButton onClick={() => storeCheckOutIn.setIdForDelete(id)}>
                <i className="bi bi-trash" />
              </IconButton>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  const handleCloseDialogStatusLog = () => {
    setStatusLogId(0);
  };

  const handleCloseDialogAccessLog = () => {
    setAccessLogId(0);
  };

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleConfirmDelete = () => {
    storeCheckOutIn.deleteEntry().then((isSucceed) => {
      if (isSucceed) storeCheckOutIn.getEntryLisMain();
    });
  };

  const handleChangeFilter = (filter: FilterModel) => {
    storeCheckOutIn.setFilter(filter);
    debouncedMethod();
  };

  useEffect(() => {
    storeCheckOutIn.getEntryLisMain();
  }, [pagination]);

  useEffect(() => storeCheckOutIn.clearStore, []);

  return (
    <>
      <LayoutSideTitle title="Equipment Check Out">
        <Button
          variant="default"
          onClick={handleToggleFilter}
          text={isFilterVisible ? 'Hide filter' : 'Show filter'}
        />
      </LayoutSideTitle>

      <FilterCheckOut
        fetching={fetching}
        isVisible={isFilterVisible}
        onChange={handleChangeFilter}
      />

      <Grid
        id="checkOutGrid"
        columns={columns}
        onAjax={fetching}
        dataSource={entryList}
        dataSourceCount={entryTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton
            title="Check Out"
            url={URL_EQUIPMENT_CHECKOUT_ADD}
          />
        }
      />

      {idForDelete ? (
        <DialogConfirm
          onApprove={handleConfirmDelete}
          onCancel={storeCheckOutIn.clearIdForDelete}>
          Are you sure you want to delete this entry?
        </DialogConfirm>
      ) : null}

      {accessLogId ? (
        <DialogAccessLog
          id={accessLogId}
          onClose={handleCloseDialogAccessLog}
        />
      ) : null}

      {statusLogId ? (
        <DialogStatusLogs
          id={statusLogId}
          onClose={handleCloseDialogStatusLog}
        />
      ) : null}

      {equipmentForCheckIn && (
        <DialogConfirm
          onCancel={storeCheckOutIn.clearEquipmentForCheckIn}
          onApprove={handleApproveEquipmentCheckIn}>
          Would you like check in this equipment?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(CheckOutTab);
