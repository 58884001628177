import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

const filterDefaultValue: FilterModel = {
  npi: '',
  hospiceName: '',
};

export interface FilterModel {
  hospiceName: string;
  npi: number | '';
}

interface HospiceType {
  address1: string;
  address2: string;
  city: string;
  hospice_nm: string;
  npi: string;
  refid: number;
  state: string;
  zipcode: string;
}

interface HospiceResponseType extends HospiceType {
  fax: string;
  first_name: string;
  last_name: string;
  notes: string;
  phone: string;
}

export interface FormModel {
  address1: string;
  address2: string;
  city: string;
  fax: string;
  firstName: string;
  hospiceName: string;
  lastName: string;
  notes: string;
  npi: string;
  phone: string;
  id: number;
  state: string;
  zipcode: string;
}

class Hospice {
  fetching: boolean = false;
  hospiceList: HospiceType[] = [];
  hospiceTotal: number = 0;
  hospice?: FormModel;
  idForDelete: number = 0;
  filter: FilterModel = filterDefaultValue;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'hospice_grid' });

    makeObservable(this, {
      fetching: observable,
      hospiceList: observable,
      hospiceTotal: observable,
      hospice: observable,
      idForDelete: observable,
      filter: observable,

      setFetching: action,
      setHospiceList: action,
      setHospiceTotal: action,
      setHospice: action,
      setFilter: action,
      setIdForDelete: action.bound,
      clearHospice: action.bound,
      clearIdForDelete: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setHospiceList(list: HospiceType[]) {
    this.hospiceList = list;
  }

  setHospiceTotal(count: number) {
    this.hospiceTotal = count;
  }

  setHospice(hospice?: FormModel) {
    this.hospice = hospice;
  }

  setFilter(filter: FilterModel) {
    this.filter = filter;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  clearHospice() {
    this.hospice = undefined;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  async getHospiceList(filter: FilterModel) {
    const { pagination } = this.page;

    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.hospiceName,
      filter.npi,
    ];

    try {
      const response = await apiRequest<HospiceType[]>({
        url: 'generalmaster.Hospice.HospiceList',
        data,
      });
      const hospices = response.map((hospice) => ({
        ...hospice,
        refid: Number(hospice.refid),
      }));
      this.setHospiceList(hospices);
    } catch (e) {
      this.setHospiceList([]);
    }
  }

  async getHospiceCount({ hospiceName, npi }: FilterModel) {
    try {
      const count = await apiRequest<number>({
        url: 'generalmaster.Hospice.HospiceListcount',
        data: [hospiceName, npi],
      });

      this.setHospiceTotal(Number(count) || 0);
    } catch (e) {
      this.setHospiceTotal(0);
    }
  }

  getHospiceListMain = (filter: FilterModel = this.filter) => {
    this.setFetching(true);

    const promiseList = this.getHospiceList(filter);

    const promiseCount = this.getHospiceCount(filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getHospice(id: number) {
    this.setFetching(true);

    try {
      const response = await apiRequest<'SE' | [HospiceResponseType]>({
        url: 'generalmaster.Hospice.ViewHospice',
        data: [id],
      });
      if (Array.isArray(response)) {
        const [{ first_name, last_name, hospice_nm, refid, ...rest }] =
          response;

        const hospice = {
          ...rest,
          firstName: first_name,
          lastName: last_name,
          hospiceName: hospice_nm,
          id,
        };

        this.setHospice(hospice);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setHospice();
    } finally {
      this.setFetching(false);
    }
  }

  async addHospice(payload: FormModel) {
    const data = [
      payload.hospiceName,
      payload.address1,
      payload.address2,
      payload.city,
      payload.state,
      payload.zipcode,
      payload.firstName,
      payload.lastName,
      payload.phone,
      payload.fax,
      payload.npi,
      payload.notes,
    ];

    try {
      const result = await apiRequest<'SE' | 'E' | number>({
        url: 'generalmaster.Hospice.AddHospice',
        data,
      });
      if (result > 0) {
        Notification.success('Hospice was added successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'Hospice name is not unique!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async updateHospice(payload: FormModel) {
    const data = [
      payload.id,
      payload.hospiceName,
      payload.address1,
      payload.address2,
      payload.city,
      payload.state,
      payload.zipcode,
      payload.firstName,
      payload.lastName,
      payload.phone,
      payload.fax,
      payload.npi,
      payload.notes,
    ];

    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.Hospice.EditHospice',
        data,
      });

      if (result === 'S') {
        Notification.success('Hospice was updated successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This Hospice already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async deleteHospice() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.Hospice.DeleteHospice',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Hospice was deleted!');
        this.clearIdForDelete();
        return true;
      } else {
        throw Error('');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }
}

export const storeHospice = new Hospice();
