import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from 'flux/utils';

import DialogFormImportSchedule, {
  ImportPayloadType,
} from 'page/system-setup/components/DialogImportFeeSchedule';
import DialogChangingFeeSchedule from 'page/system-setup/components/DialogChangingFeeSchedule';
import { Grid } from 'components/grid';
import { Button } from 'components/button';
import NumericInput from 'components/form/input/NumericInput';
import Radio from 'components/form/input/Radio';
import Notification from 'components/modal/Notification';
import { ControlsLayout } from 'components/layout';
import { SpinnerFixed } from 'components/spinner';

import CorporationOverviewPageActions from 'actions/system-setup/master-setup/corporations/CorporationOverviewPageActions';
import CorporationOverviewPageStore from 'stores/system-setup/corporations/CorporationOverviewPageStore';
import { storeFeeScheduleCommon } from 'stores/_mobx/systemSetup/feeSchedule/common';
import { URL_CORPORATION } from 'constant/path/systemSetup';

const TOGGLE_OPTIONS = [
  { label: 'Discount Percentages', value: 1 },
  { label: 'Fee Schedule', value: 2 },
];

export interface PBillingTab {
  corporationId: number;
  corporationName: string;
  history: RouteComponentProps['history'];
}

export class SBillingTab {
  mode: 1 | 2 = 1;
  feeImportDefaultValues: Omit<ImportPayloadType, 'cpt'> | null = null;
  showFeeWindow: boolean = false;
  params: any = null;
  loaded: boolean = false;
  filter: { active: 'active' } = { active: 'active' };
  serviceList: Array<any> = [];
  serviceListLoaded: boolean = false;
}

class BillingTab extends React.Component<PBillingTab, SBillingTab> {
  static getStores() {
    return [CorporationOverviewPageStore];
  }

  static calculateState(prevState: SBillingTab) {
    if (!prevState) {
      prevState = new SBillingTab();
    }
    return Object.assign({}, prevState, {
      serviceList: CorporationOverviewPageStore.getState().serviceList,
    });
  }

  componentDidMount() {
    this.updateData();
  }

  onSetMode(name: string, value: 1 | 2) {
    this.setState({ mode: value }, this.updateData.bind(this));
  }

  getColumns() {
    const textFieldCallback = (row: any, name: string, value: any) => {
      const serviceList = this.state.serviceList.map((listItem: any) => (
        {
          ...listItem,
          percent: listItem.refid === row.refid ? value : listItem.percent
        }
      ));

      this.setState({ serviceList });
    };

    return [
      {
        name: 'name',
        title: 'Service name',
      },
      {
        name: 'percent',
        title: 'Percent',
        render: (rowItem: number, row: any) => {
          return (
            <NumericInput
              name="percent"
              noLabel
              value={rowItem}
              attr={{ min: 0, max: 100 }}
              onSetValue={(name, value) => textFieldCallback(row, name, value)}
            />
          );
        },
      },
    ];
  }

  renderDiscounts() {
    return (
      <div>
        <div className="row">
          <Grid
            wrapperClass="col-sm-12"
            columns={this.getColumns()}
            dataSource={this.state.serviceList}
            dataSourceCount={this.state.serviceList.length}
            disablePagination
            noControl
          />
        </div>
      </div>
    );
  }

  closeImportPopup = () => {
    this.setState({ feeImportDefaultValues: null });
  };

  onImport = (payload: ImportPayloadType) =>
    storeFeeScheduleCommon.importFeeSchedule(payload).then((isSucceed) => {
      if (isSucceed) {
        this.setState({ feeImportDefaultValues: null });
        this.updateData();
      }
      return isSucceed;
    });

  handleClickImport = () => {
    const feeImportDefaultValues = {
      from: '',
      to: '',
      destinationId: this.props.corporationId,
      file: '' as '',
    };

    this.setState({ feeImportDefaultValues });
  };

  renderFeeSchedule() {
    const optionsForImport = [
      {
        value: this.props.corporationId,
        label: this.props.corporationName,
      },
    ];

    return (
      <>
        <div className="d-flex gap-3">
          <Button
            variant="warning"
            text="Import"
            onClick={this.handleClickImport}
          />

          <Button
            text="View"
            onClick={() => this.setState({ showFeeWindow: true })}
          />
        </div>

        {this.state.feeImportDefaultValues && (
          <DialogFormImportSchedule
            cptType="C"
            options={optionsForImport}
            onSubmit={this.onImport}
            onClose={this.closeImportPopup}
            isDestinationDisabled
            destinationLabel="Corporation"
            defaultValues={this.state.feeImportDefaultValues}
          />
        )}

        {!(this.state.loaded && this.state.showFeeWindow) ? null : (
          <DialogChangingFeeSchedule
            feeType={this.state.params.cptFlag}
            id={this.state.params.corporateId}
            groupId={this.state.params.groupid}
            onClose={() => {
              this.setState({ showFeeWindow: false });
            }}
          />
        )}
      </>
    );
  }

  getCustomDropDown() {}

  render() {
    return (
      <>
        <Radio
          name="toggleMode"
          noLabel
          className="part-inline"
          options={TOGGLE_OPTIONS}
          value={this.state.mode}
          onSetValue={(name: string, value: string) =>
            this.onSetMode(
              name,
              parseInt('' + value) === 1
                ? 1
                : parseInt('' + value) === 2
                ? 2
                : null
            )
          }
        />

        {this.state.mode === 1
          ? this.renderDiscounts()
          : this.renderFeeSchedule()}

        <ControlsLayout alignX="right" className="mb-3">
          <Button variant="warning" onClick={this.reset.bind(this)}>
            Reset
          </Button>
          <Button onClick={this.submit.bind(this)}>Save</Button>
        </ControlsLayout>

        {!this.state.serviceListLoaded && <SpinnerFixed />}
      </>
    );
  }

  updateData() {
    let corpId = this.props.corporationId;

    this.setState({ serviceListLoaded: false });

    CorporationOverviewPageActions.loadServiceList(corpId).then(
      () => this.setState({ serviceListLoaded: true }));

    if (this.state.mode === 2) {
      CorporationOverviewPageActions.loadCorporationItem(corpId).then(
        (response) => {
          let item = response.addressList[0] || ({} as any);
          this.setState({
            params: {
              cptFlag: item.cptFlag,
              corporateId: item.refid,
              stateId: item.stateId,
              groupid: item.groupid,
            },
            loaded: true,
          });
        }
      );
    }
  }

  submit() {
    CorporationOverviewPageActions.saveServiceList(
      this.props.corporationId,
      this.state.serviceList
    ).then((response) => {
      if (response === 'S') {
        Notification.success('Successfully changed!');
        this.props.history.push(URL_CORPORATION);
      } else {
        Notification.danger('An error occurred!');
      }
    });
  }

  reset() {
    CorporationOverviewPageActions.restoreInitialBillingTab();
  }
}

const billingTab = Container.create(BillingTab);
export default billingTab;
