import AbstractAddEdit, {
  PAbstractAddEdit,
  SAbstractAddEdit,
} from 'page/privileges/app-users/AbstractAddEdit';
import Notification from 'components/modal/Notification';

import ApplicationUsersAddEditActions from 'actions/privileges/ApplicationUsersAddEditActions';
import UserTypeStore from 'stores/project/UserTypeStore';
import ApplicationUsersAddEditStore from 'stores/privileges/ApplicationUsersAddEditStore';
import { USER_APP as PAGE_ID } from 'constant/pagesId/privileges';
import { URL_APPLICATION_USER } from 'constant/path/privileges';

interface PApplicationUsersEdit extends PAbstractAddEdit {}

class SApplicationUsersEdit extends SAbstractAddEdit {
  onAjax: boolean = false;
}

export default class ApplicationUsersEdit extends AbstractAddEdit<
  PApplicationUsersEdit,
  SApplicationUsersEdit
> {
  title = 'Edit User';
  appDataLoaded: boolean = false;
  applicationUsersAddEditStore: any = null;

  getOverviewPageUrl() {
    return URL_APPLICATION_USER;
  }

  constructor(props: PApplicationUsersEdit) {
    super(props);
    this.state = Object.assign(new SApplicationUsersEdit(), this.state);

    this.applicationUsersAddEditStore =
      ApplicationUsersAddEditStore.addListener(() => {
        if (
          !this.appDataLoaded &&
          ApplicationUsersAddEditStore.getState().model.userinfo_id ===
            Number(props.match.params.id)
        ) {
          this.appDataLoaded = true;

          const model = ApplicationUsersAddEditStore.getState().model;
          // @ts-ignore
          this.setState({ model });
        }
      });
  }

  componentWillUnmount() {
    ApplicationUsersAddEditActions.clearModel();
    this.applicationUsersAddEditStore.remove();
    super.componentWillUnmount();
  }

  isNew = false;

  componentDidMount() {
    super.componentDidMount();
    this.updateData();
  }

  updateData() {
    this.setState({ onAjax: true });

    const userId = Number(this.props.match.params.id);

    ApplicationUsersAddEditActions.loadFormData(userId)
      .then((response) => {
        if (response && response.userInfo && response.userInfo.facility_id) {
          return AbstractAddEdit.onFacilityChange(
            response.userInfo.facility_id
          );
        } else {
          return response;
        }
      })
      .then(() => this.setState({ onAjax: false }));
  }

  getSavedNotification(v: undefined) {
    Notification.success('User data was updated');
  }

  onSubmitSuccess(): Promise<string | number | { ipin: string }> {
    const callback = (
      signatureFileName: string | null
    ): Promise<string | number | { ipin: string }> => {
      const staffData = this.getStaffData(signatureFileName);

      const isRadiologist = UserTypeStore.isRadiologist(
        this.state.model.userType
      );

      const defaultVaccinations =
        ApplicationUsersAddEditStore.getState().model?.vaccinations || [];

      const { vaccinations } = this.state.model;

      const vaccinesTrash = defaultVaccinations.filter(({ id }) =>
        vaccinations.every((vaccine) => vaccine.id !== id)
      );

      const updatedVaccinations = vaccinations.filter(
        ({ document, comment, vaccinationTypeId }) => {
          if (typeof document !== 'string') return true;
          const vaccine = defaultVaccinations.find(
            (vaccine) => vaccine.vaccinationTypeId === vaccinationTypeId
          );

          return vaccine?.comment !== comment;
        }
      );

      this.setState({ onAjax: true });

      return isRadiologist
        ? ApplicationUsersAddEditActions.editRadiologistInfo(staffData)
        : ApplicationUsersAddEditActions.editStaffInfo(
            staffData,
            updatedVaccinations,
            vaccinesTrash
          );
    };
    return this.uploadSignature().then(callback);
  }

  getPageAppId() {
    return PAGE_ID.EDIT;
  }
}
