import clsx from 'clsx';

import ModalCreator from '../Creator';
export { default as DialogHeader } from './Header';
export { default as DialogBody } from './Body';
export { default as DialogFooter } from './Footer';

type SizeNames = 'small' | 'default' | 'large' | 'extraLarge';

interface Props {
  className?: string;
  children: any;
  size?: SizeNames;
  scrollable?: boolean;
  handleClose?: (data: any) => void;
}

const sizeVariants: Record<SizeNames, string> = {
  small: 'modal-sm',
  default: '',
  large: 'modal-lg',
  extraLarge: 'modal-xl',
};

const Dialog = ({
  className = '',
  children,
  size = 'default',
  scrollable = true,
  handleClose,
}: Props) => (
  <ModalCreator className={className} handleClose={handleClose}>
    <div
      className={clsx(
        'modal-dialog',
        { 'modal-dialog-scrollable': scrollable },
        sizeVariants[size]
      )}>
      <div className="modal-content" tabIndex={1}>
        {children}
      </div>
    </div>
  </ModalCreator>
);

export default Dialog;
