import { apiRequest } from 'services/RequestService';
import DropdownActions from 'actions/project/DropdownActions';

export interface VisitResponseType {
  forbidden: {
    facilityName: string;
    visit: null | string;
  }[];
  listed: {
    facilityName: string;
    visit: string;
  }[];
}

export default class TechnicianActions extends DropdownActions {
  static loadVisits(orderId: number) {
    return apiRequest<VisitResponseType>({
      url: 'order.Order.loadVisits',
      data: [orderId],
    });
  }
}
