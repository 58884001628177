import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Control } from 'react-hook-form';

import { TwoSideMapper } from 'components/form/twoSideMapper';
import { FilterPayloadType } from 'components/form/twoSideMapper/components/filterMethods';

import { IntegrationDetailsType } from 'stores/_mobx/systemSetup/masterSetting/integrations';
import { storeGroupManager } from 'stores/_mobx/clinicianManager/groupManager';

const settings = {
  leftFirstPlaceholder: 'Radiology Group Name',
  leftFirstClassName: 'col-sm-12',
  leftSecondHidden: true,
  rightFirstPlaceholder: 'Radiology Group Name',
  rightFirstClassName: 'col-sm-12',
  rightSecondHidden: true,
};

const columnsLeft = [{ title: 'Physician Group', name: 'label' }];

const filterFacility = ({ list, firstValue }: FilterPayloadType) => {
  if (firstValue) {
    return list.filter((facility) =>
      new RegExp(`${firstValue}`, 'i').test(facility.label)
    );
  }

  return list;
};

interface PropsType {
  control: Control<IntegrationDetailsType>;
}

const PhysicianGroup = ({ control }: PropsType) => {
  const { fetchingOptions, options } = storeGroupManager;

  useEffect(() => {
    if (!options.length) storeGroupManager.getOptions();
  }, [options]);

  return (
    <TwoSideMapper
      name="physicianGroupSubscriptions"
      className="mb-4"
      titleLeft="Total Physician Groups"
      titleRight="Total Subscriptions"
      control={control}
      columnLeft={columnsLeft}
      columnRight={columnsLeft}
      options={options}
      optionsLoading={fetchingOptions}
      settings={settings}
      filterMethod={filterFacility}
    />
  );
};

export default observer(PhysicianGroup);
