import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';

import { storeDropdownJurisdiction } from 'stores/_mobx/dropDown/jurisdiction';

interface PropsType extends Omit<SelectPropsType, 'options'> {
  stateId: number;
}

const DropdownBillingJurisdiction = observer(
  ({ stateId, ...rest }: PropsType) => {
    const { options, fetching } = storeDropdownJurisdiction;

    useEffect(() => {
      storeDropdownJurisdiction.getOptions(stateId);
    }, [stateId]);

    return <Select {...rest} options={options} isLoading={fetching} />;
  }
);

export default DropdownBillingJurisdiction;
