import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeZipCode,
  ZipCodeEditType,
} from 'stores/_mobx/systemSetup/masterSetting/zipCode';
import { URL_ZIP_CODES } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const ZipCodeAddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: ZipCodeEditType) =>
    storeZipCode.addZipCode(payload).then((isSucceed) => {
      if (isSucceed) history.push(URL_ZIP_CODES);
    });

  return (
    <>
      <Title title="Add Zip code" />

      <Form onSubmit={handleSubmit} />
    </>
  );
};

export default ZipCodeAddPage;

// export class JurisdictionZipCodeDetailsPage extends React.Component<
//   PJurisdictionZipCodeDetailsPage,
//   SJurisdictionZipCodeDetailsPage
// > {
//   getTitle() {
//     return `${this.isNew() ? 'Add' : 'Edit'} Zip code`;
//   }

//   static getStores() {
//     return [Store];
//   }

//   static calculateState() {
//     const { model, isLoading } = Store.getState();

//     return {
//       model,
//       isLoading,
//     };
//   }

//   static ACTIONS = {
//     RESET: 'RESET',
//   };

//   isNew() {
//     return this.props.match.params.id.toLowerCase() === 'new';
//   }

//   save = (payload: any) => {
//     const zipCodeAction = this.isNew()
//       ? ActionConstants.CREATE_ZIP_CODE
//       : ActionConstants.UPDATE_ZIP_CODE;
//     return Actions.saveZipCode(zipCodeAction, payload).then(
//       (response: string) => {
//         if (
//           response !== null &&
//           !isNaN(Number(response)) &&
//           Number(response) > -1
//         ) {
//           Notification.success('Successfully saved!');
//           if (!this.isNew()) {
//             this.loadData();
//           }
//           this.props.history.push(URL_ZIP_CODES);
//         } else {
//           Notification.danger('An error occurred!');
//         }
//       }
//     );
//   };

//   loadData() {
//     Actions.loadZipCodeDetails(Number(this.props.match.params.id));
//   }

//   componentDidMount() {
//     if (this.props.match.params.id !== 'new') {
//       this.loadData();
//     }
//   }

//   componentWillUnmount() {
//     Actions.resetZipCode();
//   }

//   render() {
//     const {
//       match: { params },
//     } = this.props;
//     const { isLoading, model } = this.state;

//     const isNewZip = params.id === 'new';

//     return (
//       <>
//         <Title title={this.getTitle()} />

//         <FormJurisdictionZipCode
//           key={!isNewZip && !isLoading ? `${window.performance.now()}` : ''}
//           onSubmit={this.save}
//           initialValues={isNewZip ? undefined : model}
//         />

//         {isLoading && <SpinnerFixed />}
//       </>
//     );
//   }
// }

// const container = Container.create(JurisdictionZipCodeDetailsPage);
// export default container;
