import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import MaskInput from 'components/form/maskInput';
import { DropDownStateShortName } from 'components/project/dropdown/StateShortName';
import { Checkbox } from 'components/form/checkbox';
import { Textarea } from 'components/form/textarea';
import { FileField } from 'components/form/file';
import NpiField from './NpiField';
import resolver from './validation';
import { getBillingData, getFacilityData } from './helpers';

import {
  defaultCompanyValues,
  FormCompanyType,
} from 'stores/_mobx/systemSetup/masterSetting/companyInformation';
import { URL_MASTER_SETTINGS } from 'constant/path/systemSetup';

interface PropsType {
  companyDetails: null | FormCompanyType;
  onSubmit: (data: FormCompanyType) => Promise<any>;
}

interface CacheBillingDataType {
  billingphone: string;
  billingstate: string;
  billingemail: string;
  billingtollfree: string;
  billingzip: string;
  billingcity: string;
  billingfax: string;
  billingaddress: string;
}

const Form = ({
  companyDetails = defaultCompanyValues,
  onSubmit,
}: PropsType) => {
  const prevBillingData = useRef<CacheBillingDataType | null>(null);

  const {
    control,
    formState: { isDirty, isSubmitting, isSubmitted },
    trigger,
    reset,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: companyDetails,
    resolver,
  });

  const sameAsFacility = watch('sameAsFacility');

  const handleClickReset = () => {
    prevBillingData.current = null;
    reset(companyDetails);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      const isOneLine = formValue.npiDetailsList.length === 1;

      const isFirstDescriptionChanged = 'npiDetailsList.0.desc' === name;

      if (isOneLine && isFirstDescriptionChanged && isSubmitted) {
        trigger('npiDetailsList.0.npi');
      } else if (name === 'sameAsFacility') {
        const billingData = formValue.sameAsFacility
          ? getBillingData(formValue as Required<FormCompanyType>)
          : null;

        const updatedData = {
          ...formValue,
          ...(formValue.sameAsFacility
            ? getFacilityData(formValue as Required<FormCompanyType>)
            : prevBillingData.current),
        };

        reset(updatedData, { keepDirty: true, keepTouched: true });

        prevBillingData.current = billingData;
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, isSubmitted]);

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDetails]);

  return (
    <form
      className={isSubmitting ? 'on-ajax' : ''}
      onSubmit={handleSubmit(onSubmit)}>
      <div className="row mb-5">
        <div className="col-sm-auto mx-auto">
          <FileField
            name="logoExternal"
            label="Login page logo (260x100)"
            accept="image/png"
            classNameContent="external-logo"
            control={control}
          />
        </div>
        <div className="col-sm-auto mx-auto">
          <FileField
            name="logoInternal"
            label="Application header logo (200x40)"
            accept="image/png"
            classNameContent="internal-logo"
            control={control}
          />
        </div>
        <div className="col-sm-auto mx-auto">
          <FileField
            name="logoDocument"
            label="PDF reports logo (260x80)"
            accept="image/jpeg"
            classNameContent="document-logo"
            control={control}
          />
        </div>
      </div>
      <div className="row mb-5">
        <legend>Company information</legend>
        <Input
          name="company_name"
          label="Company name"
          className="col-md-6"
          required
          control={control}
        />
        <div />
        <Input
          name="email"
          label="Email"
          className="col-md-6"
          required
          control={control}
        />
        <Input
          name="web_site"
          label="Web Site"
          className="col-md-6"
          control={control}
        />
        <MaskInput
          name="phone"
          label="Phone"
          format="###-###-####"
          className="col-md-6 col-xl-3"
          control={control}
        />
        <MaskInput
          name="fax"
          label="Fax"
          format="###-###-####"
          className="col-md-6 col-xl-3"
          control={control}
        />
        <MaskInput
          name="tollfreenum"
          label="Toll free number"
          format="###-###-####"
          className="col-md-6 col-xl-3"
          control={control}
        />
        <MaskInput
          name="zipcode"
          label="Zip Code"
          format="#####-####"
          className="col-md-6 col-xl-3"
          control={control}
        />
        <DropDownStateShortName
          name="state"
          label="State"
          className="col-md-6 col-xl-3"
          control={control}
        />
        <Input
          name="city"
          label="City"
          className="col-md-6 col-xl-3"
          control={control}
        />
        <Input
          name="address"
          label="Address"
          className="col-xl-6"
          control={control}
        />

        <NpiField
          className="col-lg-8 col-xl-6"
          name="npiDetailsList"
          control={control}
        />
      </div>

      <div className="row mb-5">
        <legend>Billing information</legend>
        <Checkbox
          name="sameAsFacility"
          label="Same as Company Information"
          control={control}
        />

        <Input
          name="billingemail"
          label="Email"
          className="col-md-6"
          required
          disabled={sameAsFacility}
          control={control}
        />
        <div />
        <MaskInput
          name="billingphone"
          label="Phone"
          format="###-###-####"
          className="col-md-6 col-xl-3"
          disabled={sameAsFacility}
          control={control}
        />
        <MaskInput
          name="billingfax"
          label="Fax"
          format="###-###-####"
          className="col-md-6 col-xl-3"
          disabled={sameAsFacility}
          control={control}
        />
        <MaskInput
          name="billingtollfree"
          label="Toll free number"
          format="###-###-####"
          className="col-md-6 col-xl-3"
          disabled={sameAsFacility}
          control={control}
        />
        <MaskInput
          name="billingzip"
          label="Zip Code"
          format="#####-####"
          className="col-md-6 col-xl-3"
          disabled={sameAsFacility}
          control={control}
        />
        <DropDownStateShortName
          name="billingstate"
          label="State"
          className="col-md-6 col-xl-3"
          disabled={sameAsFacility}
          control={control}
        />
        <Input
          name="billingcity"
          label="City"
          className="col-md-6 col-xl-3"
          disabled={sameAsFacility}
          control={control}
        />
        <Input
          name="billingaddress"
          label="Address"
          className="col-xl-6"
          disabled={sameAsFacility}
          control={control}
        />
      </div>

      <Textarea name="comment" label="Comment" rows={4} control={control} />

      <ControlsLayout alignX="between">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
        <div>
          <Button
            variant="warning"
            text="Reset"
            disabled={!isDirty || isSubmitting}
            onClick={handleClickReset}
          />
          <Button
            type="submit"
            text="Save"
            disabled={!isDirty || isSubmitting}
          />
        </div>
      </ControlsLayout>
    </form>
  );
};

export default Form;
