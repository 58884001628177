import { Link } from 'react-router-dom';

import { IconButton } from 'components/button';
import Badge from 'components/badge';
import { ExamExportLink } from 'page/components/ExportLink';

import {
  storeDocumentManagerAcquisition,
  DocumentType,
} from 'stores/_mobx/patientRecord/documentManagerAcquisition';
import {
  URL_EXAM_INFO_EXAM_TAB,
  URL_DOC_MANAGER,
} from 'constant/path/patientRecords';

const Icon = ({ isAvailable }: { isAvailable: boolean }) => {
  const className = `bi bi-${isAvailable ? 'check-circle' : 'x-circle'}`;
  return (
    <span className="text-primary fs-5">
      <i className={className} />
    </span>
  );
};

const columns = [
  { name: 'patient_lastname', title: 'Last Name' },
  { name: 'patient_firstname', title: 'First Name' },
  { name: 'dos', title: 'DOS', className: 'text-nowrap' },
  { name: 'provider_name', title: 'Ordering MD/NPP' },
  { name: 'facility_name', title: 'Facility' },
  {
    name: 'order_id',
    title: 'Exam Req',
    className: 'text-center',
    render: (orderId: number) => (
      <ExamExportLink key={orderId} orderId={orderId} />
    ),
  },
  {
    name: 'order_id',
    title: 'Fax Req',
    className: 'text-center',
    render: (orderId: number, data: DocumentType) => (
      <IconButton
        className="text-primary"
        onClick={() => storeDocumentManagerAcquisition.showFaxDialog(data)}>
        <i className="bi bi-printer" />
      </IconButton>
    ),
  },
  {
    name: 'isSigned',
    title: 'Signature Status',
    className: 'text-center',
    render: (isSigned: boolean) => (
      <Badge variant={isSigned ? 'success' : 'danger'}>
        {isSigned ? 'Signed' : 'Unsigned'}
      </Badge>
    ),
  },

  {
    name: 'clinicalnote',
    title: 'Clinical Note',
    className: 'text-center',
    render: (clinicalnote: string) => (
      <Icon isAvailable={clinicalnote === '/assets/images/available.png'} />
    ),
  },
  {
    name: 'insurance',
    title: 'Insurance',
    className: 'text-center',
    render: (insurance: string) => (
      <Icon isAvailable={insurance === '/assets/images/available.png'} />
    ),
  },
  {
    name: 'doc_upload',
    title: 'Document Upload',
    className: 'text-center',
    render: (value: any, data: DocumentType) => {
      const url = `${URL_EXAM_INFO_EXAM_TAB}/document-overview/${data.order_id}/${data.patient_id}`;
      return (
        <Link to={`${url}?subpage&backUrl=${URL_DOC_MANAGER}`}>
          <i className="bi bi-upload fs-5" />
        </Link>
      );
    },
  },
  {
    name: 'admin_email',
    title: 'Send Message',
    className: 'text-center',
    render: (value: string, data: DocumentType) => (
      <IconButton
        className="text-primary"
        onClick={() => storeDocumentManagerAcquisition.setEmail(data)}>
        <i className="bi bi-reply fs-5" />
      </IconButton>
    ),
  },
];

export default columns;
