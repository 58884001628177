import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import clsx from 'clsx';

import { IconButton } from 'components/button';
import Cell, { OptionType, ColumnType } from './components/Cell';
import MapperTitle from './components/Title';
import { FilterPayloadType } from './components/filterMethods';
import { PureInput } from '../textField';
import { SpinnerFixed } from 'components/spinner';

const itemSize = 31;

const getUniqueItems = (list: number[]) =>
  list.filter(
    (item, _, incomeList) =>
      incomeList.lastIndexOf(item) === incomeList.indexOf(item)
  );

interface SettingsType {
  leftFirstPlaceholder?: string;
  leftSecondPlaceholder?: string;
  leftFirstClassName?: string;
  leftSecondClassName?: string;
  leftSecondHidden?: boolean;
  rightFirstPlaceholder?: string;
  rightSecondPlaceholder?: string;
  rightFirstClassName?: string;
  rightSecondClassName?: string;
  rightSecondHidden?: boolean;
}

export interface PropsType {
  titleLeft: string;
  titleRight: string;
  columnLeft: ColumnType[];
  columnRight: ColumnType[];
  className?: string;
  options: OptionType[];
  value: OptionType[];
  isDirty?: boolean;
  settings?: SettingsType;
  disabled?: boolean;
  optionsLoading?: boolean;
  filterMethod: (data: FilterPayloadType) => OptionType[];
  onChange?: (data: OptionType[]) => void;
}

const PureTwoSideMapper = React.forwardRef<HTMLDivElement, PropsType>(
  (
    {
      isDirty = false,
      optionsLoading,
      value,
      columnLeft,
      columnRight,
      titleLeft,
      titleRight,
      className,
      settings,
      disabled,
      filterMethod,
      onChange,
      ...rest
    }: PropsType,
    ref
  ) => {
    const prevDirtyStatus = useRef<boolean>(isDirty);

    const [moved, setMoved] = useState<number[]>([]);

    const [selectedOption, setSelectedOption] = useState<OptionType[]>([]);

    const [selectedValue, setSelectedValue] = useState<OptionType[]>([]);

    const [leftFirstArgument, setLeftFirstArgument] = useState<string>('');

    const [leftSecondArgument, setLeftSecondArgument] = useState<string>('');

    const [rightFirstArgument, setRightFirstArgument] = useState<string>('');

    const [rightSecondArgument, setRightSecondArgument] = useState<string>('');

    const cn = clsx('mapper-container', className, { disabled });

    const combinedOptions = useMemo(() => {
      const filteredOptions = value?.length
        ? rest.options.filter(
            (option) => !value.some(({ value }) => option.value === value)
          )
        : rest.options;

      return filteredOptions;
    }, [rest.options, value]);

    const resultOptions = useMemo(
      () =>
        filterMethod({
          list: combinedOptions,
          firstValue: leftFirstArgument,
          secondValue: leftSecondArgument,
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [combinedOptions, leftFirstArgument, leftSecondArgument]
    );

    const resultValue = useMemo(
      () =>
        filterMethod({
          list: value,
          firstValue: rightFirstArgument,
          secondValue: rightSecondArgument,
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [rightFirstArgument, rightSecondArgument, value]
    );

    const handleSelectOption = (option: OptionType) => {
      const isIncluded = selectedOption.some(
        ({ value }) => value === option.value
      );

      const codes = isIncluded
        ? selectedOption.filter(({ value }) => value !== option.value)
        : selectedOption.concat(option);

      setSelectedOption(codes);
    };

    const handleSelectValue = (option: OptionType) => {
      const isIncluded = selectedValue.some(
        ({ value }) => value === option.value
      );

      const codes = isIncluded
        ? selectedValue.filter(({ value }) => value !== option.value)
        : selectedValue.concat(option);

      setSelectedValue(codes);
    };

    const handleAddValue = () => {
      const combinedList = [
        ...moved,
        ...selectedOption.map(({ value }) => value),
      ];

      const movedItems = getUniqueItems(combinedList);

      if (onChange) onChange(value.concat(selectedOption));

      setMoved(movedItems);
      setSelectedOption([]);
    };

    const handleRemoveValue = () => {
      const combinedList = [
        ...moved,
        ...selectedValue.map(({ value }) => value),
      ];

      const movedItems = getUniqueItems(combinedList);

      const restValues = value.filter(
        (option) => !selectedValue.some(({ value }) => value === option.value)
      );

      if (onChange) onChange(restValues);

      setMoved(movedItems);
      setSelectedValue([]);
    };

    useEffect(() => {
      setSelectedOption([]);
    }, [leftFirstArgument, leftSecondArgument]);

    useEffect(() => {
      setSelectedValue([]);
    }, [rightFirstArgument, rightSecondArgument]);

    useEffect(() => {
      if (!isDirty && prevDirtyStatus.current) {
        setSelectedValue([]);
        setSelectedOption([]);
        setMoved([]);
      }

      prevDirtyStatus.current = isDirty;
    }, [isDirty]);

    return (
      <div className={cn} ref={ref}>
        <div className="mapper-side">
          <div className="row">
            <PureInput
              name="leftFirstArgument"
              disabled={disabled}
              placeholder={settings?.leftFirstPlaceholder || 'CPT Code'}
              className={settings?.leftFirstClassName || 'col-md-6 col-lg-4'}
              value={leftFirstArgument}
              onChange={setLeftFirstArgument}
            />
            {settings?.leftSecondHidden ? null : (
              <PureInput
                name="leftSecondArgument"
                disabled={disabled}
                placeholder={
                  settings?.leftSecondPlaceholder || 'CPT Description'
                }
                className={settings?.leftSecondClassName || 'col-md-6 col-lg-8'}
                value={leftSecondArgument}
                onChange={setLeftSecondArgument}
              />
            )}
          </div>
          <div className="mapper-legend">{`${titleLeft}: ${resultOptions.length}`}</div>
          <MapperTitle
            scrollable={resultOptions.length > 10}
            columns={columnLeft}
          />
          <ul className="mapper-list">
            {resultOptions.length ? (
              <List
                width="auto"
                height={310}
                itemSize={itemSize}
                itemCount={resultOptions.length}>
                {({ index, style }) => (
                  <Cell
                    className={clsx({
                      selected: selectedOption.some(
                        ({ value }) => value === resultOptions[index].value
                      ),
                      moved: moved.includes(resultOptions[index].value),
                    })}
                    style={style}
                    data={resultOptions[index]}
                    columns={columnLeft}
                    onClick={handleSelectOption}
                  />
                )}
              </List>
            ) : (
              <li className="mapper-list-empty" style={{ height: '310px' }}>
                There is no any option.
              </li>
            )}
            {optionsLoading && <SpinnerFixed />}
          </ul>
        </div>

        <div className="mapper-control">
          <IconButton
            disabled={disabled || !selectedOption.length}
            onClick={handleAddValue}>
            <i className="bi bi-caret-right-fill" />
          </IconButton>
          <IconButton
            disabled={disabled || !selectedValue.length}
            onClick={handleRemoveValue}>
            <i className="bi bi-caret-left-fill" />
          </IconButton>
        </div>

        <div className="mapper-side">
          <div className="row">
            <PureInput
              name="rightFirstArgument"
              disabled={disabled}
              placeholder={settings?.rightFirstPlaceholder || 'Service Code'}
              className={settings?.rightFirstClassName || 'col-md-6 col-lg-4'}
              value={rightFirstArgument}
              onChange={setRightFirstArgument}
            />
            {settings?.rightSecondHidden ? null : (
              <PureInput
                name="rightSecondArgument"
                disabled={disabled}
                placeholder={
                  settings?.rightSecondPlaceholder || 'Service Description'
                }
                className={
                  settings?.rightSecondClassName || 'col-md-6 col-lg-8'
                }
                value={rightSecondArgument}
                onChange={setRightSecondArgument}
              />
            )}
          </div>
          <div className="mapper-legend">{`${titleRight}: ${resultValue.length}`}</div>
          <MapperTitle
            scrollable={resultValue.length > 10}
            columns={columnRight}
          />
          <ul className="mapper-list">
            {resultValue.length ? (
              <List
                width="auto"
                height={310}
                itemSize={itemSize}
                itemCount={resultValue.length}>
                {({ index, style }) => (
                  <Cell
                    className={clsx({
                      selected: selectedValue.some(
                        ({ value }) => value === resultValue[index].value
                      ),
                      moved: moved.includes(resultValue[index].value),
                    })}
                    style={style}
                    data={resultValue[index]}
                    columns={columnRight}
                    onClick={handleSelectValue}
                  />
                )}
              </List>
            ) : (
              <li className="mapper-list-empty">
                Please select the entry in the left Grid.
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
);

export default PureTwoSideMapper;
