import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { IconButton } from 'components/button';
import DialogConfirm from 'components/modal/dialogConfirm';
import { Grid } from 'components/grid';
import useDebounce from 'components/hook/useDebounce';
import DialogServiceNotes from './components/DialogServiceNotes';
import DialogAssignUser from './components/DialogAssignUser';
import ServiceAreaModal from './components/DialogServiceForm';
import Filter from './components/Filter';

import {
  storeTaskManager,
  TaskType,
  FilterType,
} from 'stores/_mobx/workflow/serviceMaintenance/taskManager';

export interface TaskManagerTabFilter {
  startIndex: number;
  limit: number;
  servId: number;
  device: boolean;
  ekg: boolean;
  vehicle: boolean;
  phone: boolean;
  waiting: boolean;
  bone: boolean;
  open: boolean;
  usable: boolean;
  gvUserType: string;
  gvCurrentUser: number;
  retired: boolean;
}

const columns = [
  { name: 'equipname', title: 'Equipment - Serial #' },
  { name: 'equtype', title: 'Equipment Type' },
  {
    name: 'refid',
    title: 'Service Notes',
    render: (id: number, data: TaskType) => (
      <div className="control">
        <IconButton onClick={() => storeTaskManager.setParamServiceNote(data)}>
          <i className="icon icon-notes" />
        </IconButton>
      </div>
    ),
  },
  { name: 'requestby', title: 'Requested By' },
  { name: 'requestdt', title: 'Requested Date' },
  { name: 'time', title: 'Requested Time' },
  { name: 'servicename', title: 'Assigned To' },
  {
    name: 'labelval',
    title: 'Assigned By',
    render: (label: string, data: TaskType) => (
      <a
        href="/"
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          storeTaskManager.setParamForAssign(data);
        }}>
        <b>{label}</b>
      </a>
    ),
  },
  {
    name: 'enable',
    title: 'Reject',
    render: (enable: boolean, data: TaskType) =>
      enable ? (
        <a
          href="/"
          onClick={(e: React.SyntheticEvent) => {
            e.preventDefault();
            storeTaskManager.setParamForReject(data);
          }}>
          <b>Reject</b>
        </a>
      ) : (
        <b>Reject</b>
      ),
  },
  {
    name: 'equ_status',
    title: 'Service Status',
    render: (status: undefined, data: TaskType) => (
      <a
        href="/"
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          storeTaskManager.setParamsForUpdate(data);
        }}>
        <b>{data.btnlabel}</b>
      </a>
    ),
  },
];

interface PropsType {
  isFilterVisible: boolean;
}

const TaskManagerTab = ({ isFilterVisible }: PropsType) => {
  const {
    fetching,
    taskList,
    taskTotal,
    paramsServiceNote,
    paramsForAssign,
    paramsForReject,
    paramsForUpdate,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeTaskManager;

  const debouncedMethod = useDebounce(setPaginationToStart, 400);

  const handleAssignUser = (userId: number) => {
    storeTaskManager.assignUserToService(userId).then((isSucceed) => {
      if (isSucceed) {
        storeTaskManager.clearAssign();
        storeTaskManager.getTaskListMain();
      }
    });
  };

  const handleConfirmReject = () => {
    storeTaskManager.rejectService().then((isSucceed) => {
      if (isSucceed) {
        storeTaskManager.clearReject();
        storeTaskManager.getTaskListMain();
      }
    });
  };

  const handleConfirmUpdate = (payload?: any) => {
    storeTaskManager.updateService(payload).then((isSucceed) => {
      if (isSucceed) {
        storeTaskManager.clearUpdate();
        storeTaskManager.getTaskListMain();
      }
    });
  };

  const handleSearch = (filterValue: FilterType) => {
    storeTaskManager.setFilter(filterValue);
    debouncedMethod();
  };

  useEffect(() => {
    storeTaskManager.getTaskListMain();
  }, [pagination]);

  return (
    <>
      <Filter isFilterVisible={isFilterVisible} onSearch={handleSearch} />

      <Grid
        id="taskManagerGrid"
        columns={columns}
        onAjax={fetching}
        dataSource={taskList}
        dataSourceCount={taskTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      {paramsServiceNote && (
        <DialogServiceNotes onClose={storeTaskManager.clearNotes} />
      )}

      {paramsForAssign && (
        <DialogAssignUser
          onSubmit={handleAssignUser}
          onClose={storeTaskManager.clearAssign}
        />
      )}

      {paramsForReject && (
        <DialogConfirm
          onApprove={handleConfirmReject}
          onCancel={storeTaskManager.clearReject}>
          Do you want to reject this service?
        </DialogConfirm>
      )}

      {paramsForUpdate && (
        <>
          {paramsForUpdate.equipmentStatus === 'W' ? (
            <DialogConfirm
              onCancel={storeTaskManager.clearUpdate}
              onApprove={handleConfirmUpdate}>
              Do you want to under take the service?
            </DialogConfirm>
          ) : (
            <ServiceAreaModal
              service={paramsForUpdate}
              onApprove={handleConfirmUpdate}
              onCancel={storeTaskManager.clearUpdate}
            />
          )}
        </>
      )}
    </>
  );
};

export default observer(TaskManagerTab);
