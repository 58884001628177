import SentPageService from 'services/messages/sent/SentPageService';
import appDispatcher from 'dispatcher/AppDispatcher';

export default class SentPageActions {
  static getSentMessageDetails(id: string) {
    return SentPageService.getSentMessageDetails(id).then((response) => {
      appDispatcher.dispatch({
        type: 'messages-sent-details',
        data: Array.isArray(response) ? response[0] : null,
      });
      return response;
    });
  }
}
