import AbstractStore from 'stores/AbstractStore';
import appDispatcher from 'dispatcher/AppDispatcher';

class ArAgingReportStore extends AbstractStore {
  getEmptyDiagram() {
    return [['Data', 'Value']];
  }

  getInitialState() {
    return {
      dataSource: [],
      chart: this.getEmptyDiagram(),
    };
  }

  reduce(state, action) {
    switch (action.type) {
      case 'aragingreport_data_overview':
        var empty = this.getEmptyDiagram();
        this.getState().dataSource = action.data.recordval || [];
        var chart;
        if (action.data.chartval) {
          action.data.chartval.unshift(empty[0]);
          chart = action.data.chartval;
        } else {
          chart = empty;
        }
        this.getState().chart = chart;
        break;
      default:
        break;
    }
    return this.getState();
  }
}

export default new ArAgingReportStore(appDispatcher);
