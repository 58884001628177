import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { storeImport } from 'stores/_mobx/import';
import { downloadFile } from 'utils/downloadFile';
import { BASE_URL_FILE_DIR } from 'constant/config';

const initStateFilter: FilterModel = {
  code: '',
  description: '',
  status: 'A',
  type: 'Y',
  billable: 'B',
};

interface DiagnosisType {
  flag: 'A' | 'I';
  icd_billable_status: string;
  icd_code: string;
  refid: number;
  short_description: string;
  status: 'Active' | 'Inactive';
}

interface DiagnosisEditType {
  refid: number;
  bill_status: 'B' | 'N';
  icd_billable_status: string;
  icd_code: string;
  icd_flag: 'Y' | 'N';
  icd_type: string;
  short_description: string;
}

type ValueOf<T> = T[keyof T];

export interface FilterModel {
  code: string;
  description: string;
  status: 'A' | 'I';
  type: 'N' | 'Y';
  billable: 'B' | 'N';
}

class DiagnosisCode {
  fetching: boolean = false;
  uploadingInProgress: boolean = false;
  code?: DiagnosisEditType = undefined;
  codesList: DiagnosisType[];
  codesTotal: number = 0;
  codeForDelete: number = 0;
  filter: FilterModel = initStateFilter;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'diagnosis_codes_grid' });

    makeObservable(this, {
      fetching: observable,
      uploadingInProgress: observable,
      codesList: observable,
      codesTotal: observable,
      codeForDelete: observable,
      code: observable,
      filter: observable,

      setFetching: action.bound,
      setUploadingProgress: action.bound,
      setCode: action.bound,
      setCodesList: action.bound,
      setCodesTotal: action.bound,
      setCodeForDelete: action.bound,
      setFilter: action.bound,
      clearCodeForDelete: action.bound,
      clearCode: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setUploadingProgress(fetching: boolean) {
    this.uploadingInProgress = fetching;
  }

  setFilter(values: FilterModel) {
    this.filter = values;
  }

  setFilterProp(name: keyof FilterModel, values: ValueOf<FilterModel>) {
    // @ts-ignore
    this.filter[name] = values;
  }

  setCodesList(codes: DiagnosisType[]) {
    this.codesList = codes;
  }

  setCodesTotal(count: number) {
    this.codesTotal = count;
  }

  setCode(code?: DiagnosisEditType) {
    this.code = code;
  }

  setCodeForDelete(id: number) {
    this.codeForDelete = id;
  }

  clearCodeForDelete() {
    this.codeForDelete = 0;
  }

  clearCode() {
    this.code = undefined;
  }

  async getCodesList(filter: FilterModel) {
    const { pagination } = this.page;
    const data = [
      filter.code,
      filter.description,
      filter.status,
      pagination.skip,
      pagination.pageSize,
      filter.type,
      filter.billable,
    ];
    try {
      const response = await apiRequest<'SE' | 'S' | DiagnosisType[]>({
        url: 'codemaster.ICD9Code.ICD9CodeList',
        data,
      });

      if (Array.isArray(response)) {
        const codes = response.map((code) => ({
          ...code,
          refid: Number(code.refid),
        }));
        this.setCodesList(codes);
      } else throw Error('');
    } catch (e) {
      this.setCodesList([]);
    }
  }

  async getCodesCount(filter: FilterModel) {
    const data = [
      filter.code,
      filter.description,
      filter.status,
      filter.type,
      filter.billable,
    ];
    try {
      const count = await apiRequest<string>({
        url: 'codemaster.ICD9Code.ICD9CodeTotalCount',
        data,
      });
      this.setCodesTotal(Number(count));
    } catch (e) {
      this.setCodesTotal(0);
    }
  }

  getCodesListMain = (filter: FilterModel = this.filter) => {
    this.setFetching(true);

    const promiseCount = this.getCodesCount(filter);

    const promiseRegions = this.getCodesList(filter);

    Promise.allSettled([promiseCount, promiseRegions]).finally(() => {
      this.setFetching(false);
    });
  };

  async getCode(id: string | number) {
    this.setFetching(true);
    try {
      const response = await apiRequest<'SE' | [DiagnosisEditType]>({
        url: 'codemaster.ICD9Code.GetViewICD9Code',
        data: [id],
      });

      if (Array.isArray(response)) {
        this.setCode({ ...response[0], refid: Number(response[0].refid) });
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setCode();
    } finally {
      this.setFetching(false);
    }
  }

  async addCode(payload: Omit<DiagnosisEditType, 'refid'>) {
    const data = [
      payload.icd_code,
      payload.short_description,
      payload.bill_status,
      payload.icd_flag,
    ];

    try {
      const response = await apiRequest<'S' | 'E'>({
        url: 'codemaster.ICD9Code.AddICD9Code',
        data,
      });
      if (response === 'S') {
        Notification.success('ICD Code was added successfully!');
        return null;
      } else if (response === 'E') {
        return { message: 'This Code already exist!' };
      } else {
        throw Error('');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateCode(payload: DiagnosisEditType) {
    const data = [
      payload.refid,
      payload.icd_code,
      payload.short_description,
      payload.bill_status,
    ];

    try {
      const response = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'codemaster.ICD9Code.EditICD9Code',
        data,
      });
      if (response === 'S') {
        Notification.success('ICD Code was updated successfully!');
        return null;
      } else if (response === 'E') {
        return { message: 'This Code already exist!' };
      } else {
        throw Error('');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async deleteCode() {
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'codemaster.ICD9Code.DeleteICD9Code',
        data: [this.codeForDelete],
      });
      if (result === 'S') {
        Notification.success('Diagnosis code was deleted!');
        this.clearCodeForDelete();
        return true;
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async changeCodeStatus(ids: number[], state: 'A' | 'I') {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'codemaster.ICD9Code.UpdateMultipleStatus',
        data: [ids, state],
      });

      if (result === 'S') {
        const statusMsg = state === 'I' ? 'activated' : 'deactivated';

        Notification.success(
          `${ids.length} ICD ${
            ids.length > 1 ? 'codes were' : 'code was'
          } ${statusMsg}!`
        );
        return true;
      }
      throw Error('');
    } catch (e) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async exportCodes(ids: number[]) {
    try {
      const fileName = await apiRequest<'SE' | 'E' | string>({
        url: 'codemaster.ICD9Code.ExportICDBySelected',
        data: [ids],
      });

      if (fileName !== 'S' && fileName !== 'E') {
        Notification.success(
          `${ids.length} ICD ${
            ids.length > 1 ? 'codes were' : 'code was'
          } exported!`
        );
        downloadFile(`${BASE_URL_FILE_DIR}doc_img/billingexport/${fileName}`);

        return true;
      }
      throw Error('');
    } catch (e) {
      Notification.danger('An error occurred!');
      return false;
    }
  }

  async importCodes(file: File) {
    this.setUploadingProgress(true);
    try {
      const fileName = await storeImport.uploadFile({
        url: 'temppath/tempImportUpload.php',
        file,
      });

      if (!fileName) throw Error('');

      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'codemaster.ICD9Code.ImportICDCode',
        data: [fileName],
      });

      if (result === 'S') {
        Notification.success('Diagnosis codes were imported!');
        return true;
      }
      throw Error('');
    } catch (e) {
      Notification.danger('An error occurred!');
      return false;
    } finally {
      this.setUploadingProgress(false);
    }
  }
}

export const storeDiagnosisCode = new DiagnosisCode();
