import { makeObservable, observable, action, runInAction } from 'mobx';

import { TPagination } from 'components/grid/GridTypes';
import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

export interface FilterType {
  name: string;
  type: 'D' | 'M';
}

interface TemplateResponseType {
  data: string;
  label: string;
  type: 'D' | 'M';
  typename: 'Default' | 'Manual';
}

interface TemplateType extends Omit<TemplateResponseType, 'data'> {
  refid: number;
  label: string;
  type: 'D' | 'M';
  typename: 'Default' | 'Manual';
}

interface TemplateEditType {
  body: string;
  notations: string;
  refid?: number;
  subject: string;
  template_code: string;
  template_name: string;
  template_type: 'D' | 'M';
  userTypes: number[];
}

interface TemplateEditResponseType {
  '0': TemplateEditType;
  Description: [string][];
}

interface TemplatePayloadType {
  template_name: string;
  subject: string;
  userTypes: number[] | null;
  body: string;
  refid?: number;
}

interface OptionResponseType {
  body: string;
  data: string;
  label: string;
  subject: string;
}

export interface OptionType {
  label: string;
  value: number;
}

export interface EmailTemplateManualType
  extends Omit<OptionResponseType, 'data'> {
  id: number;
}

class EmailTemplate {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  templatesList: TemplateType[] = [];
  templatesTotal: number = 0;
  template: TemplateEditType | undefined = undefined;
  templateIdForDelete: number | null = null;
  descriptions: string[];
  options: OptionType[] = [];
  manualTemplates: EmailTemplateManualType[] = [];

  filter: FilterType = {
    name: '',
    type: 'D',
  };
  page: Pagination = new Pagination({ id: 'email_template_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      templatesList: observable,
      templatesTotal: observable,
      template: observable,
      templateIdForDelete: observable,
      descriptions: observable,
      options: observable,
      manualTemplates: observable,
      filter: observable,

      setFetching: action,
      setFetchingOptions: action,
      setTemplatesList: action,
      setTemplatesTotal: action,
      setTemplate: action,
      setTemplateForDelete: action,
      setDescriptions: action,
      setFilter: action,
      clearTemplateForDelete: action,
      clearTemplate: action,
      clearManualTemplate: action.bound,
    });
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setTemplatesList(list: TemplateType[]) {
    this.templatesList = list;
  }

  setTemplatesTotal(count: number) {
    this.templatesTotal = count;
  }

  setTemplate(template?: TemplateEditType) {
    this.template = template;
  }

  setTemplateForDelete(id: number) {
    this.templateIdForDelete = id;
  }

  setDescriptions(descriptions: string[] = []) {
    this.descriptions = descriptions;
  }

  clearTemplateForDelete = () => {
    this.templateIdForDelete = null;
  };

  clearTemplate = () => {
    this.template = undefined;
  };

  clearManualTemplate = () => {
    this.manualTemplates = [];
  };

  async getTemplatesList({
    pagination,
    filter,
  }: {
    pagination: TPagination;
    filter: FilterType;
  }) {
    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.type,
      filter.name,
    ];

    try {
      const templates = await apiRequest<TemplateResponseType[]>({
        url: 'email.EmailTemplate.EmailTemplateList',
        data,
      });

      if (Array.isArray(templates)) {
        const templatesType = templates.map(({ data, ...template }) => ({
          ...template,
          refid: Number(data),
        }));
        this.setTemplatesList(templatesType);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setTemplatesList([]);
    }
  }

  async getTemplatesCount(filter: FilterType) {
    try {
      const count = await apiRequest<number | string>({
        url: 'email.EmailTemplate.EmailTemplateTotalCount',
        data: [filter.type, filter.name],
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setTemplatesTotal(total);
    } catch (e) {
      this.setTemplatesTotal(0);
    }
  }

  getTemplatesListMain() {
    this.setFetching(true);

    const props = {
      pagination: this.page.pagination,
      filter: this.filter,
    };

    const promiseList = this.getTemplatesList(props);

    const promiseCount = this.getTemplatesCount(props.filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  }

  async getTemplate(id: number | string) {
    this.setFetching(true);
    try {
      const template = await apiRequest<TemplateEditResponseType>({
        url: 'email.EmailTemplate.getEmailTemplate',
        data: [id],
      });
      runInAction(() => {
        this.template = template[0];
        this.descriptions = template.Description.map(
          ([description]) => description
        );
      });
    } catch (e) {
      this.setTemplate();
    } finally {
      this.setFetching(false);
    }
  }

  async addTemplate(payload: TemplatePayloadType) {
    const data = [
      payload.template_name,
      payload.subject,
      payload.body,
      payload.userTypes,
    ];
    try {
      const result = await apiRequest<'E' | number>({
        url: 'email.EmailTemplate.AddEmailTemplate',
        data,
      });
      if (result === 'E') {
        return { message: 'This template name already exist!' };
      }
      Notification.success('Email template was added successfully!');
      return null;
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateTemplate(payload: TemplatePayloadType) {
    const data = {
      refid: payload.refid,
      subject: payload.subject,
      body: payload.body,
      manualtplname: payload.template_name,
      userTypes: payload.userTypes,
    };

    try {
      const result = await apiRequest<'E' | number>({
        url: 'email.EmailTemplate.editEmailTemplate',
        data,
      });

      if (result === 'E') {
        return { message: 'This template name already exist!' };
      }
      Notification.success('Email template was updated successfully!');
      return null;
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async deleteTemplate() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'email.EmailTemplate.DeleteEmailTemplate',
        data: [this.templateIdForDelete],
      });
      if (result === 'S') {
        Notification.success('Email template was deleted!');
        this.clearTemplateForDelete();
        return true;
      } else {
        throw Error('An error occurred!');
      }
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getOptions(userRoleId: number = null) {
    if (this.fetchingOptions) return Promise.resolve([]);

    this.setFetchingOptions(true);
    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'email.Email.getEmailTemplate',
        data: [userRoleId],
      });

      const { options, manualTemplates } = response.reduce(
        (prev, { data, ...rest }) => {
          const id = Number(data);
          const options = prev.options.concat({ label: rest.label, value: id });
          const manualTemplates = prev.manualTemplates.concat({
            ...rest,
            id,
          });

          return {
            options,
            manualTemplates,
          };
        },
        { options: [], manualTemplates: [] }
      );

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
        this.manualTemplates = manualTemplates;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
        this.manualTemplates = [];
      });
    }
  }

  findTemplate(id: number) {
    return this.manualTemplates.find((template) => template.id === id);
  }
}

export const storeEmailTemplate = new EmailTemplate();
