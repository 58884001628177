import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface TransmitLogType {
  facility_name: string;
  facility_orders: string;
  message_type: string;
  sender_name: string;
  sent_date: string;
  unassigned_count: number;
}

class TransmitLog {
  fetching: boolean = false;
  logs: TransmitLogType[] = [];
  logsTotal: number = 0;

  page: Pagination = new Pagination({ id: 'transmit_log_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      logs: observable,
      logsTotal: observable,

      setFetching: action,
      setLogs: action,
      setLogsTotal: action,
      clearLogs: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setLogs(logs: TransmitLogType[]) {
    this.logs = logs;
  }

  setLogsTotal(count: number) {
    this.logsTotal = count;
  }

  clearLogs() {
    this.logs = [];
    this.logsTotal = 0;
    this.page = new Pagination({ id: 'transmit_log_grid' });
  }

  async getLogsCount(id: number) {
    try {
      const count = await apiRequest<string>({
        url: 'facility.Billingassignment.Transmitlog_count',
        data: [id, ''],
      });

      this.setLogsTotal(Number(count) || 0);
    } catch (e: any) {
      this.setLogsTotal(0);
    }
  }

  async getLogsList(id: number) {
    const { pagination } = this.page;

    const data = [id, '', pagination.skip, pagination.pageSize, ''];
    try {
      const response = await apiRequest<TransmitLogType[]>({
        url: 'facility.Billingassignment.Transmitlog_list',
        data,
      });

      const logs = response.map((log) => ({
        ...log,
        sent_date: dateToLocalTimezone({
          date: log.sent_date,
        }),
        unassigned_count: Number(log.unassigned_count),
      }));

      this.setLogs(logs);
    } catch (e: any) {
      this.setLogs([]);
    }
  }

  getLogsListMain(id: number) {
    this.setFetching(true);

    const promiseCount = this.getLogsCount(id);

    const promiseList = this.getLogsList(id);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  }
}

export const storeTransmitLog = new TransmitLog();
