import { apiRequest } from 'services/RequestService';
import { TPagination } from 'components/grid/GridTypes';

export class TRgApprovalFilter {
  lastName: string = '';
  firstName: string = '';
  examType: number = null;
  facility: number = null;
  state: number = null;
  location: number = null;
  radiologyGroup: number = null;
  radiologist: number = null;
  pos: number = null;
  billProcess: 'N' | 'I' | 'H' | 'O' = 'N';
  dosStart: string = '';
  dosEnd: string = '';
  providerType: number = null;
  exportFlag: 'E' | 'A' | 'H' = 'A';
  division: number = 0;
}

export class TExpHistoryFilter {
  lastName: string = '';
  firstName: string = '';
  userType: string = '';
  radiologyGroup: number = null;
}

export class TRgApproval {
  Studyid: number = null;
  room: string = '';
  patientid: number = null;
  vw_patientid: string = '';
  gender: string = '';
  patientnm: string = '';
  birthdate: string = '';
  patientfist: string = '';
  patientlast: string = '';
  patientmrn: string = '';
  facilitynm: string = '';
  order_based: string = '';
  fac_bill_phone: string = '';
  clientMRN: string = '';
  exported_date: string = '';
  exported_nm: string = '';
  inmate_number: string = '';
  immigration_id: number = null;
  ice_number: string = '';
  facilityphone: string = '';
  facility_addr: string = '';
  radio_name: string = '';
  radio_npi: string = '';
  radio_addr: string = '';
  facility_type: string = '';
  facilityid: number = null;
  npi: string = '';
  state: string = '';
  dos: string = '';
  radioname: string = '';
  prolast_name: string = '';
  profirst_name: string = '';
  pos: string = '';
  examtype: string = '';
  providerid: string = '';
  Pcpid: string = '';
  medicare_id: number = null;
  medicaid_id: number = null;
  medicaid_stateid: number = null;
  pri_groupno: string = '';
  sec_groupno: string = '';
  ter_groupno: string = '';
  bill_id: number = null;
  region: string = '';
  ssn: string = '';
  face_sheet: string = '';
  name: string = '';
  bill_process: 'Insurance' | 'Hospice' | 'Others' | 'NA' = null;
  showimage: string = '';
  has_notes: boolean = false;
  tooltip: string = '';
  tooltipcnt: number = null;
  cpt: string = '';
  cpt_desc: string = '';
  icd: string = '';
  icd_desc: string = '';
  pripayorname: string = '';
  priinsurance_no: string = '';
  priplan_id: number = null;
  pristart_dt: string = '';
  priend_dt: string = '';
  priplan_name: string = '';
  secpayorname: string = '';
  secinsurance_no: string = '';
  secplan_id: number = null;
  secstart_dt: string = '';
  secend_dt: string = '';
  secplan_name: string = '';
  tripayorname: string = '';
  triinsurance_no: string = '';
  triplan_id: number = null;
  tristart_dt: string = '';
  triend_dt: string = '';
  triplan_name: string = '';
  gualast_name: string = '';
  guafirst_name: string = '';
  guarantor_address: string = '';
  guarantor_city: number = null;
  guarantor_site: number = null;
  guarantor_zip: number = null;
  guarantor_phone: string = '';
  doctor_pop_img: string = '';
  doctor_pop_tooltip: string = '';
  facImg: string = '';
  facTooltip: string = '';
  posImg: string = '';
  posTooltip: string = '';
  insImg: string = '';
  insTooltip: string = '';
  dobImg: string = '';
  dobTooltip: string = '';
  zipImg: string = '';
  zipTooltip: string = '';
}

export class TExpHistoryItem {
  filename: string = '';
  exported_dt: string = '';
  exported_by: string = '';
}

class ClaimsService {
  loadOverview(filter: TRgApprovalFilter, pagination: TPagination) {
    const data: Record<string, any> = {
      lastName: filter.lastName,
      firstName: filter.firstName,
      modality: filter.examType,
      facility: filter.facility,
      state: filter.state,
      location: filter.location,
      radiologyId: filter.radiologyGroup,
      radiologist: filter.radiologist,
      pos: filter.pos,
      billProcess: filter.billProcess,
      sortValue: 'A',
      dosStart: filter.dosStart,
      dosEnd: filter.dosEnd,
      usertype: null,
      userId: null,
      providerType: filter.providerType,
      startIndex: pagination.skip,
      limit: pagination.pageSize,
      currentUser: null,
      exportType: filter.exportFlag,
      division: filter.division,
    };

    return apiRequest<'SE' | 0 | Array<TRgApproval>>({
      url: 'facility.FacilityBilling.RgApprovalList',
      data,
    });
  }

  loadCount(filter: TRgApprovalFilter) {
    const data: Record<string, any> = {
      lastName: filter.lastName,
      firstName: filter.firstName,
      modality: filter.examType,
      facility: filter.facility,
      state: filter.state,
      location: filter.location,
      radiologyId: filter.radiologyGroup,
      radiologist: filter.radiologist,
      pos: filter.pos,
      billProcess: filter.billProcess,
      sortValue: 'A',
      dosStart: filter.dosStart,
      dosEnd: filter.dosEnd,
      usertype: null,
      userId: null,
      providerType: filter.providerType,
      currentUser: null,
      exportType: filter.exportFlag,
      division: filter.division,
    };

    return apiRequest<'SE' | number>({
      url: 'facility.FacilityBilling.RgApprovalCount',
      data,
    });
  }

  loadHistoryOverview(filter: TExpHistoryFilter, pagination: TPagination) {
    const data = {
      lastName: filter.lastName, //'',
      firstName: filter.firstName, //'',
      userType: filter.userType, //'',
      radiologyId: filter.radiologyGroup, //0,
      startIndex: pagination.skip, //0,
      limit: pagination.pageSize, //50,
    };

    return apiRequest<0 | Array<TExpHistoryItem>>({
      url: 'facility.FacilityBilling.GetExpHistory',
      data,
    });
  }

  loadHistoryCount(filter: TExpHistoryFilter) {
    const data = {
      lastName: filter.lastName, //'',
      firstName: filter.firstName, //'',
      userType: filter.userType, //'',
      radiologyId: filter.radiologyGroup, //0,
    };
    return apiRequest<number>({
      url: 'facility.FacilityBilling.GetExpHistoryCount',
      data,
    });
  }
}

export default new ClaimsService();
