import { Container } from 'flux/utils';

import AbstractFacilityFormPage, {
  TABS,
  PAbstractFacilityFormPage,
  SAbstractFacilityFormPage,
} from './AbstractFacilityFormPage';
import Notification from 'components/modal/Notification';
import { withDivisionSettings } from 'components/HOC';

import FacilityPageActions from 'actions/system-setup/master-setup/facility/FacilityPageActions';
import FacilityPageStore from 'stores/system-setup/facility/FacilityPageStore';
import { storeExamType } from 'stores/_mobx/systemSetup/masterSetting/examType';
import { storeDropdownRadiology } from 'stores/_mobx/dropDown/radiology';
import {
  storeFacility,
  FacilitySettingsType,
} from 'stores/_mobx/systemSetup/facility';
import { BASE_URL_FILE_DIR } from 'constant/config';
import { FACILITY_ID as PAGE_ID } from 'constant/pagesId/systemSetup';
import { URL_FACILITY } from 'constant/path/systemSetup';

export interface PFacilityEditPage extends PAbstractFacilityFormPage {}

export class SFacilityEditPage extends SAbstractFacilityFormPage {
  loaded: boolean = false;
}

export class FacilityEditPage extends AbstractFacilityFormPage<
  PFacilityEditPage,
  SFacilityEditPage
> {
  getTitle = () => this.state.facilityName;

  constructor(props: PFacilityEditPage) {
    super(props);
    if (sessionStorage.getItem('modeChanged') === 'true') {
      FacilityPageActions.updateTabsState(TABS.BILLING_TAB, {
        0: false,
        1: false,
        2: true,
        3: true,
      });
      sessionStorage.setItem('modeChanged', 'false');
    }
  }

  static getStores() {
    return [FacilityPageStore];
  }

  static calculateState(prevState: SFacilityEditPage) {
    if (!prevState) {
      prevState = Object.assign(new SFacilityEditPage(), {
        loaded: false,
      });
    }
    return AbstractFacilityFormPage.calculateFacilityPageState(prevState);
  }

  updateFacilityTabData() {
    return FacilityPageActions.loadFacilityItem(
      Number(this.props.match.params.id)
    ).then(({ Region: [location] }) =>
      FacilityPageActions.loadLocationList(
        location.region_id,
        location.state_id
      )
    );
  }

  updateBillingTabData = () => {
    const facilityId = Number(this.props.match.params.id);
    return FacilityPageActions.loadBillingItem(facilityId).then(
      FacilityPageActions.loadServiceList.bind(null, facilityId)
    );
  };

  updateContactTabData = () => {
    return FacilityPageActions.loadContactList(
      Number(this.props.match.params.id),
      false
    );
  };

  updateDocumentTabData = () => {
    return FacilityPageActions.loadDocumentList(
      Number(this.props.match.params.id)
    );
  };

  componentDidMount() {
    storeExamType.getOptions();
    storeDropdownRadiology.getOptions();
    storeFacility.getFacilitySettings(Number(this.props.match.params.id));
    this.updateFacilityTabData()
      .then(() => this.updateBillingTabData())
      .then(() => this.updateContactTabData())
      .then(() => this.updateDocumentTabData())
      .then(() => this.setState({ loaded: true }));
  }

  componentWillUnmount() {
    storeFacility.clearSettings();
    FacilityPageActions.clearAllTabs();
    if (FacilityPageStore.getState().backToList) {
      FacilityPageActions.updateTabsState(TABS.FACILITY_TAB, {
        0: false,
        1: false,
        2: false,
        3: false,
      });
      FacilityPageActions.setBackToList(false);
    }
  }

  onSubmitFacilityTab = async (facilityItem?: any) => {
    const payload = {
      ...facilityItem,
      refid: Number(this.props.match.params.id),
    };
    return FacilityPageActions.updateFacilityItem(payload).then((response) => {
      this.setState({ updateOverviewRequired: true });

      if (response >= 0) {
        Notification.success('Changes saved!');
        this.handleSelected(TABS.BILLING_TAB);
        this.updateFacilityTabData()
          .then(() => this.updateBillingTabData())
          .then(() => {
            this.billingTab.updateBillingItem(this.state.billingItem);
            if (this.props.updateOverview) {
              this.props.updateOverview();
            }
          });
        return null;
      } else {
        Notification.danger('Validation failed. The form contains errors.');
      }
      return response >= 0
        ? null
        : { facility_nm: ['Facility with this name already exist!'] };
    });
  };

  onSubmitBillingTab(billingItem?: any, serviceArr?: Array<any>) {
    const payload = {
      ...billingItem,
      refid: Number(this.props.match.params.id),
      serviceArr,
    };
    return FacilityPageActions.updateBillingItem(payload).then((response) => {
      this.submitPostProcess(() => {
        if (response === 'S') {
          Notification.success('Changes saved!');
          const blocked = {
            ...this.state.blocked,
            [TABS.CONTACT_TAB]: false,
            [TABS.DOCUMENT_TAB]: false,
          };
          FacilityPageActions.updateTabsState(TABS.CONTACT_TAB, blocked);
          this.updateBillingTabData();
        } else {
          Notification.danger('An error occurred!');
        }
      });
    });
  }

  onSubmitContactTab(contactItem?: any) {
    const {
      state,
      props: { match },
    } = this;
    return FacilityPageActions.saveContactListDirect(
      match.params.id,
      contactItem.comments,
      contactItem.contactList
    ).then((response) => {
      this.submitPostProcess(() => {
        if (response === 'S') {
          Notification.success('Changes saved!');
          FacilityPageActions.updateTabsState(TABS.DOCUMENT_TAB, state.blocked);
          this.updateContactTabData();
        } else {
          Notification.danger('An error occurred!');
        }
      });
    });
  }

  onSubmitDocumentTab(documentList?: any) {
    return FacilityPageActions.saveDocumentList(
      this.props.match.params.id,
      documentList
    ).then((response) => {
      this.submitPostProcess(() => {
        if (response >= 0) {
          this.documentTab.updateTabState({
            saved: true,
            path: `${BASE_URL_FILE_DIR}doc_img/facility`,
          } as any);
          Notification.success('Changes saved!');
          FacilityPageActions.updateTabsState(
            TABS.SETTINGS_TAB,
            this.state.blocked
          );
          this.updateDocumentTabData();
        } else {
          Notification.danger('An error occurred!');
        }
      });
    });
  }

  onSubmitSettingsTab = async (settings: FacilitySettingsType) => {
    return storeFacility.updateFacilitySettings(settings).then((isSucceed) => {
      if (isSucceed) this.props.history.push(URL_FACILITY);
      return isSucceed;
    });
  };

  getPageAppId() {
    return PAGE_ID.EDIT;
  }
}

const facilityEditPageContainer = Container.create(FacilityEditPage);

export default withDivisionSettings(facilityEditPageContainer);
