import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import { storePermission } from 'stores/_mobx/users/permission';

const columns = [
  {
    name: 'app_name',
    title: 'Application name',
  },
  {
    name: 'enable_dt',
    title: 'Permission enabled',
  },
  {
    name: 'disable_dt',
    title: 'Permission disabled',
  },
  {
    name: 'status',
    title: 'Current status',
  },
  {
    name: 'created_user_nm',
    title: 'Created by',
  },
];

interface PropsType {
  id: any;
  onClose: () => void;
}

const DialogPermissionsLog = ({ id, onClose }: PropsType) => {
  const {
    fetchingLog,
    logsList,
    logsTotal,
    pageLogs: { pagination, setPagination },
  } = storePermission;

  useEffect(() => {
    storePermission.getLogsListMain(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  useEffect(() => {
    return storePermission.clearLog;
  }, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Permissions log" onClose={onClose} />

      <DialogBody>
        <Grid
          id="users_log_modal_grid"
          onAjax={fetchingLog}
          columns={columns}
          dataSource={logsList}
          dataSourceCount={logsTotal}
          pagination={pagination}
          onPaginationChange={setPagination}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogPermissionsLog);
