import { useEffect } from 'react';
// @ts-ignore
import useFluxStore from 'flux-hooks';

import Header from 'components/project/common/Header';
import SidebarMenu from 'components/project/common/sideBar';
import { ExporterDialog } from 'components/exporter';

import UserProfileStore from 'stores/UserProfileStore';
import { storeExporter } from 'stores/_mobx/exporter';

interface PropsType {
  children: any;
}

const Wrapper = ({ children }: PropsType) => {
  const { waitForLogin, menu } = useFluxStore(
    UserProfileStore,
    (prev: any, state: any) => ({
      menu: state.getState().userProfile.sidebarMenu,
      waitForLogin: !state.getState().success && state.getState().loggedIn,
    })
  );

  useEffect(() => {
    storeExporter.getReports();
  }, []);

  return (
    <>
      <div className={waitForLogin ? 'show-spinner' : 'hide-spinner'} />
      <div className="wrapper">
        <Header />
        <div className="container-fluid app-content">
          <div className="row flex-grow-1 flex-nowrap">
            <SidebarMenu menu={menu} />
            <div className="col-sm page-content">
              <div className="row px-3 h-100 align-content-start">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ExporterDialog />
    </>
  );
};

export default Wrapper;
