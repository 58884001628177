import React from 'react';

import { PText, SText, TText } from 'components/form/input/Text';
import Validation from 'components/form/Validation';
import Notification from 'components/modal/Notification';
import { URL_NPPES_NPI } from 'constant/externalLinks';

export interface PNpiInput extends PText {
  hasLookUp?: boolean;
}

export class SNpiInput extends SText {}

export default class NpiInput<
  P extends PNpiInput,
  S extends SNpiInput
> extends TText<P, S> {
  setValue(value: string, event?: React.SyntheticEvent) {
    if (value) {
      const validLength = (value + '').length <= 10;
      let isPositive = Validation.validate(value, 'positive').length === 0;
      if (!isPositive) {
        if ((value + '').length < (this.props.value + '').length) {
          let prevPositive =
            Validation.validate(this.props.value?.toString(), 'positive')
              .length === 0;
          if (!prevPositive) {
            //if previous value is not positive, and current value is not positive,
            //and current value shorter than previous, it mean that user try to fix initial value.
            isPositive = true;
          }
        }
      }
      if (validLength && isPositive) {
        super.setValue(value, event);
      } else if ((value + '').length > 10) {
        Notification.warning('Npi can contain 10 characters in length', {
          toastId: 'npiLengthLimit',
        });
      } else {
        Notification.warning('Npi can contain only numbers', {
          toastId: 'npiOnlyNumbers',
        });
      }
    } else {
      super.setValue('', event);
    }
  }

  validate(value: string) {
    let validations = this.props.validations;
    if (!validations) {
      validations = 'npi';
    } else if (typeof validations === 'string') {
      if (validations.indexOf('npi') === -1) {
        validations = validations + ' npi';
      }
    } else {
      if (!validations.npi) {
        validations.npi = true;
      }
    }
    return Validation.validate(value, validations);
  }

  getLabelValue(): React.ReactNode {
    const out = super.getLabelValue();
    if (out !== null) {
      if (this.props.hasLookUp) {
        return [
          out,
          <a
            key="npi"
            href={URL_NPPES_NPI}
            className="npi-label"
            target="_blank"
            rel="noreferrer">
            NPI Lookup
          </a>,
        ];
      }
      return out;
    }
    return null;
  }
}
