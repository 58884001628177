import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import DropDownCallType from 'components/project/dropdown/CallType';
import { InputCalendar } from 'components/form/inputCalendar';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';

import {
  filterDefaultValues as defaultValues,
  FilterModel,
} from 'stores/_mobx/workflow/order/callLog';

const cn = 'col-md-6 col-lg-3';

interface PropsType {
  isVisible: boolean;
  onChange: (filter: FilterModel) => void;
}

const Filter = ({ isVisible, onChange }: PropsType) => {
  const {
    control,
    formState: { isDirty },
    watch,
    reset,
  } = useForm<FilterModel>({
    defaultValues,
  });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    const subscription = watch((formValue) => {
      onChange(formValue as FilterModel);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className={`row${isVisible ? '' : ' d-none'}`}>
      <DropDownFacility
        name="facilityId"
        label="Facility"
        className={cn}
        control={control}
      />

      <DropDownCallType
        name="callTypeId"
        label="Call type"
        className={cn}
        control={control}
      />

      <InputCalendar
        name="dateFrom"
        label="Date From"
        className={cn}
        control={control}
      />
      <InputCalendar
        name="dateTo"
        label="Date To"
        className={cn}
        control={control}
      />
      <ControlsLayout alignX="right" className="mb-4">
        <Button
          variant="warning"
          text="Reset"
          disabled={!isDirty}
          onClick={handleClickReset}
        />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
