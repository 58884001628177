import React from "react";
import AbstractFilter, { EQUAL_CONDITIONS } from "./AbstractFilter";

export default class AbstractEqualFilter extends AbstractFilter {
  CONDITIONS = EQUAL_CONDITIONS;

  render() {
    return (
      <div className={`row ${this.className}`}>
        {this.renderConditions()}
        {this.renderTextValue()}
      </div>
    );
  }
}
