import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';
import { OptionResponseType } from 'stores/_mobx/integration';

import { EligibilitySettingsStatus } from './eligibilityMaster';

import Notification from 'components/modal/Notification';

export const URL_SYSTEM_APPLICATION = 'system/application_settings';

export interface AppSettingsStateFormModel {
  divisionState: boolean,
  eligibilityState: {
    experianState: boolean,
    availityState: boolean,
  },
}

interface EligibilityPropsType {
  experianState: boolean;
  availityState: boolean;
}

class AppSettingsMaster extends EligibilitySettingsStatus {
  divisionFetching: boolean;
  isDivisionEnabled: boolean;

  constructor() {
    super();
    makeObservable(this, {
      divisionFetching: observable,
      isDivisionEnabled: observable,

      appSettings: computed,

      setDivisionFetching: action,
      clearAppSettings: action.bound,
    });
  }

  setDivisionFetching(fetching: boolean) {
    this.divisionFetching = fetching;
  }

  clearAppSettings() {
    this.isDivisionEnabled = false;
    this.clearState();
  }

  get appSettings(): AppSettingsStateFormModel {
    return {
      divisionState: this.isDivisionEnabled,
      eligibilityState: {
        experianState: this.isExperianEnabled,
        availityState: this.isAvailityEnabled,
      }
    };
  }

  async getAppState() {
    await this.getDivisionState();
    await this.getEligibilityState();
  }

  async getDivisionState() {
    if (this.divisionFetching) return Promise.resolve();

    this.setDivisionFetching(true);

    try {
      const { value } = await apiRequest<OptionResponseType<boolean>>({
        url: `${URL_SYSTEM_APPLICATION}/features_switch:facility_division`,
        method: 'GET',
        legacy: false,
      });

      runInAction(() => {
        this.divisionFetching = false;
        this.isDivisionEnabled = value;
      });
    } catch (e: unknown) {
      runInAction(() => {
        this.divisionFetching = false;
        this.isDivisionEnabled = false;
      });
    }
  }

  async updateDivisionState(value: boolean) {
    try {
      const response = await apiRequest<OptionResponseType<boolean>>({
        url: `${URL_SYSTEM_APPLICATION}/features_switch:facility_division`,
        method: 'PUT',
        legacy: false,
        data: { value },
      });

      return value === response.value;
    } catch (e: any) {
      Notification.danger(e?.message || "An error occurred while updating Divisions Application Settings!");
      return false;
    }
  }

  async updateEligibilityState({
    eligibilityState,
    isExperianStateChanged,
    isAvailityStateChanged
  }: {
    eligibilityState: EligibilityPropsType,
    isExperianStateChanged: boolean,
    isAvailityStateChanged: boolean
  }) {
    try {
      const { experianState: experianValue, availityState: availityValue } = eligibilityState;

      let experianResponse = Promise.resolve(undefined);
      let availityResponse = Promise.resolve(undefined);

      if(isExperianStateChanged) {
        experianResponse = apiRequest<OptionResponseType<boolean>>({
          url: `${URL_SYSTEM_APPLICATION}/eligibility_manager_export_settings:experian_export`,
          method: 'PUT',
          legacy: false,
          data: { value: experianValue },
        });
      }

      if(isAvailityStateChanged) {
        availityResponse = apiRequest<OptionResponseType<boolean>>({
          url: `${URL_SYSTEM_APPLICATION}/eligibility_manager_export_settings:availity`,
          method: 'PUT',
          legacy: false,
          data: { value: availityValue },
        });
      }

      await Promise.all([experianResponse, availityResponse]);

      return true;
    } catch (e: any) {
      const message =
        isExperianStateChanged ? "An error occurred while updating Experian Settings!"
        : isAvailityStateChanged ? "An error occurred while updating Availity Healthia Exchange Settings!"
        : "Something went wrong!";

      Notification.danger(e?.message || message);
      return false;
    }
  }

  async updateAppSettingsState(appState: any) {
    try {
      const { divisionState, eligibilityState } = appState;

      let divisionSettings: Promise<boolean | undefined> = Promise.resolve(undefined);
      let eligibilitySettings: Promise<boolean | undefined> = Promise.resolve(undefined);

      if(this.isDivisionEnabled !== divisionState) {
        divisionSettings = this.updateDivisionState(divisionState);
      }

      const isExperianStateChanged = this.isExperianEnabled !== eligibilityState.experianState;
      const isAvailityStateChanged = this.isAvailityEnabled !== eligibilityState.availityState;

      if(isExperianStateChanged || isAvailityStateChanged) {
        eligibilitySettings = this.updateEligibilityState({
          eligibilityState,
          isExperianStateChanged,
          isAvailityStateChanged,
        });
      }

      const applicationState = await Promise.all([divisionSettings, eligibilitySettings]);

      if (applicationState.every(item => typeof item === "boolean")) {
        const isAllSucceed = applicationState.every(item => item);
        const message = isAllSucceed ? "All Application Settings updated successfully!"
          : applicationState[0] ? "Divisions Application Settings updated successfully!"
            : applicationState[1] ? "Eligibility Manager Exports Application Settings updated successfully!"
              : "";

        if(message) Notification.success(message);

        return isAllSucceed;
      } else {
        const message: string = this.isDivisionEnabled !== divisionState && applicationState[0]
          ? "Divisions Application Settings updated successfully!"
          : (isExperianStateChanged || isAvailityStateChanged) && applicationState[1] ? "Eligibility Manager Exports Application Settings updated successfully!"
            : "";
        if(message) Notification.success(message);

        if(applicationState[0] || ((isExperianStateChanged || isAvailityStateChanged) && applicationState[1]) ) {
          return true;
        }
        return false;
      }
    } catch (e: any) {
      Notification.danger(e?.message || "An error occurred while updating Application Settings!");
      return false;
    }
  }
}

export const storeAppSettingsMaster = new AppSettingsMaster();