import { action, makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

export interface OptionType {
  value: number;
  label: string;
}

class UserCategory {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,

      setFetching: action,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  async getOptions() {
    this.setFetching(true);

    try {
      const options = await apiRequest<OptionType[]>({
        url: 'generalmaster.Designation.CategoryDropDown',
      });

      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }

  async addCategory(categoryName: string) {
    this.setFetching(true);

    try {
      const [option] = await apiRequest<[OptionType]>({
        url: 'generalmaster.Designation.AddCategory',
        data: [categoryName],
      });
      this.options.push(option);
      return option.value;
    } catch (e: any) {
      return { message: e.faultString };
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeUserCategory = new UserCategory();
