import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import DispatchRegionForm from './components/Form';

import {
  storeDispatchRegions,
  RegionDetailsType,
} from 'stores/_mobx/systemSetup/dispatchRegions';
import { URL_DISPATCH_REGION } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const DispatchRegionsAddPage = ({ history }: PropsType) => {
  const { fetchingOptions, options } = storeDispatchRegions;

  const handleSubmit = (payload: RegionDetailsType) =>
    storeDispatchRegions.addDispatchRegion(payload).then((error) => {
      if (error) {
        return error;
      }
      history.push(URL_DISPATCH_REGION);
    });

  useEffect(() => {
    storeDispatchRegions.getOptions();
  }, []);

  return (
    <>
      <Title title="Add Location" />

      <DispatchRegionForm
        onSubmit={handleSubmit}
        regionOptions={options}
        backUrl={URL_DISPATCH_REGION}
      />

      {fetchingOptions && <SpinnerFixed />}
    </>
  );
};

export default observer(DispatchRegionsAddPage);
