import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Notification from 'components/modal/Notification';

enum ErrorType {
  S = 'S',
  P = 'P',
  E = 'E',
}

const errorsList: Record<ErrorType, string> = {
  S: '',
  P: 'This password was used before. Please enter new password',
  E: 'Old password incorrect',
};

export interface SecurityQuestionPayload {
  refid: number;
  usertype: string;
  answer1: string;
  answer2: string;
}
interface SecurityQuestionResponse {
  refid: number;
  quest_id: string;
  quest1_id: string;
}

interface SecurityQuestionVerifyResponse {
  refid: number;
  user_name?: string;
  user_pass?: string;
  usertype_id?: string;
}

interface ChangePasswordPayload {
  userId: number;
  login: string;
  oldPassword: string;
  newPassword: string;
}

class Credentials {
  pin: string = '';
  questions: [string, string] = ['', ''];
  questionsFetching: boolean = false;
  isQuestionsVerifyPass: boolean = false;
  errorMessage: string = '';

  constructor() {
    makeObservable(this, {
      pin: observable,
      questions: observable,
      questionsFetching: observable,
      isQuestionsVerifyPass: observable,
      errorMessage: observable,

      clearError: action,
      clearQuestions: action,
      clearPin: action,
      createPin: action,
      verifyAnswers: action,
      changePassword: action,
      getQuestions: action,
    });
  }

  clearPin = () => {
    this.pin = '';
  };

  clearError = () => {
    this.errorMessage = '';
  };

  clearQuestions = () => {
    this.questions = ['', ''];
  };

  getQuestions = async (userId: number, signal?: AbortSignal) => {
    this.clearQuestions();
    this.questionsFetching = true;
    try {
      const [response] = await apiRequest<SecurityQuestionResponse[]>({
        url: 'userlogin.LoginMaster.GetFavoriteQuestion',
        data: [userId],
        signal,
      });
      this.questions = [response.quest_id, response.quest1_id];
    } catch (error) {
    } finally {
      this.questionsFetching = false;
    }
  };

  verifyAnswers = async ({
    refid,
    usertype,
    answer1,
    answer2,
  }: SecurityQuestionPayload) => {
    try {
      const [response] = await apiRequest<SecurityQuestionVerifyResponse[]>({
        url: 'userlogin.LoginMaster.VerifyAnswer',
        data: [refid, usertype, answer1, answer2],
      });

      const isAnswersCorrect = Boolean(response.refid);
      this.errorMessage = isAnswersCorrect
        ? ''
        : 'The given details are incorrect. Please provide proper details.';

      this.isQuestionsVerifyPass = isAnswersCorrect;
    } catch (error) {
      this.errorMessage = 'Unknown error. Please, contact your administrator.';
    }
  };

  createPin = async (userId: number) => {
    this.clearPin();

    try {
      const response = await apiRequest<string>({
        url: 'usermaster.UserInfo.RegenerateIpin',
        data: [userId],
      });

      this.pin = response;
    } catch (error) {}
  };

  changePassword = async ({
    userId,
    login,
    oldPassword,
    newPassword,
  }: ChangePasswordPayload) => {
    const data = [userId, login, oldPassword, newPassword];
    try {
      const response = await apiRequest<ErrorType>({
        url: 'userlogin.LoginMaster.LoginChangePassword',
        data,
      });

      const isPasswordChanged = response === ErrorType.S;

      this.errorMessage =
        errorsList[response] ??
        'Unknown error. Please, contact your administrator.';

      if (isPasswordChanged) {
        Notification.success('Password was changed');
      }

      return isPasswordChanged;
    } catch (error) {
      return false;
    }
  };
}

export const storeProfileCredentials = new Credentials();
