import clsx from 'clsx';

import { Variant, HorizontalPositionType, PositionType } from './type';

const varianOptions: Record<Variant, string> = {
  primary: 'bg-primary',
  secondary: 'bg-secondary',
  success: 'bg-success',
  danger: 'bg-danger',
  dark: 'bg-dark',
  warning: 'bg-warning text-dark',
  info: 'bg-info text-dark',
  light: 'bg-light text-dark',
};

const horizontalPosition: Record<HorizontalPositionType, string> = {
  left: 'start-0',
  middle: 'start-50',
  right: 'start-100',
  '': '',
};

const positionOptions: Record<PositionType, string> = {
  static: '',
  absolute: 'position-absolute top-0 translate-middle',
};

interface PropsType {
  variant?: Variant;
  rounded?: boolean;
  className?: string;
  horizontal?: HorizontalPositionType;
  position?: PositionType;
  children: React.ReactChild;
}

const Badge = ({
  children,
  rounded = false,
  horizontal = '',
  className,
  variant = 'primary',
  position = 'static',
}: PropsType) => {
  const cn = clsx(
    'badge',
    varianOptions[variant],
    horizontalPosition[horizontal],
    positionOptions[position],
    { 'rounded-pill': rounded },
    className
  );
  return <span className={cn}>{children}</span>;
};

export default Badge;
