import React from 'react';

import { PText, TText } from 'components/form/input/Text';

interface PIconedInput extends PText {
  iconClass?: string;
  icon?: React.ReactElement;
}

interface BuildInput extends PText {
  input: string;
  label: string;
  forceLabel: string;
  className: string;
}

export default class IconedInput extends TText<PIconedInput, any> {
  buildInput(attr: BuildInput) {
    const out = super.buildInput(attr);
    return (
      <div className="formfield-input icon-holder">
        {out}{' '}
        {this.props.icon ? (
          this.props.icon
        ) : (
          <span className={this.props.iconClass} />
        )}
      </div>
    );
  }
}
