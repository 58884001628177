import InboxService from 'services/messages/inbox/InboxService';
import appDispatcher from 'dispatcher/AppDispatcher';
import { EmailType } from 'services/messages/inbox/InboxService';

export default class InboxActions {
  static getInboxMessageDetails(id: string) {
    return InboxService.getInboxMessageDetail(id).then((response) => {
      appDispatcher.dispatch({
        type: 'messages-inbox-details',
        data: Array.isArray(response) ? response[0] : null,
      });
      return response;
    });
  }

  static sendEmail(data: EmailType) {
    return InboxService.sendEmail(data);
  }
}
