import { useState } from 'react';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Grid } from 'components/grid';
import { Button } from 'components/button';
import { PureFile } from 'components/form/file';

const columns = [
  { name: 'state', title: 'State' },
  { name: 'zip', title: 'ZIP code' },
  { name: 'carrier', title: 'Carrier' },
  { name: 'locality', title: 'Locality' },
  { name: 'rural_index', title: 'Rural IND' },
  { name: 'lab_cb_locality', title: 'LAB CB Locality' },
  { name: 'rural_index_2', title: 'Rural IND2' },
  { name: 'plus_4_flag', title: 'Plus 4 FLAG' },
  { name: 'part_b_drug_indicator', title: 'Part B Drug indicator' },
  { name: 'year_qtr', title: 'Year/QTR' },
];

const exampleData = [
  {
    carrier: '2102',
    lab_cb_locality: 'Z9',
    locality: '1',
    part_b_drug_indicator: 'A',
    plus_4_flag: '0',
    rural_index: '',
    rural_index_2: '',
    state: 'AK',
    stateId: '1',
    year_qtr: '20171',
    zip: '99501',
  },
  {
    carrier: '2102',
    lab_cb_locality: 'Z9',
    locality: '1',
    part_b_drug_indicator: 'A',
    plus_4_flag: '0',
    rural_index: '',
    rural_index_2: '',
    state: 'AK',
    stateId: '1',
    year_qtr: '20171',
    zip: '99502',
  },
  {
    carrier: '2102',
    lab_cb_locality: 'Z9',
    locality: '1',
    part_b_drug_indicator: 'A',
    plus_4_flag: '0',
    rural_index: '',
    rural_index_2: '',
    state: 'AK',
    stateId: '1',
    year_qtr: '20171',
    zip: '99503',
  },
];

interface PropsType {
  isUploading: boolean;
  onSubmit: (file: File) => void;
  onClose: () => void;
}

const DialogZipCodesImport = ({
  isUploading,
  onSubmit,
  onClose,
}: PropsType) => {
  const [isExampleVisible, toggleExample] = useState<boolean>(false);

  const [file, setFile] = useState<File | string>('');

  const handleToggleExample = () => {
    toggleExample((state) => !state);
  };

  const handleClickSubmit = () => {
    onSubmit(file as File);
  };

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Confirm" onClose={onClose} />
      <DialogBody className={isUploading ? 'on-ajax' : ''}>
        <PureFile
          accept=".csv"
          name="uploader"
          className="col-sm-8 col-md-6 col-xl-4"
          label="CSV file"
          required
          value={file}
          onChange={setFile}
        />

        {isExampleVisible && (
          <Grid
            columns={columns}
            onAjax={false}
            dataSource={exampleData}
            id="jurisdictionZipCodeSampleGrid"
            disablePagination
          />
        )}
      </DialogBody>
      <DialogFooter>
        <Button
          variant="default"
          text={isExampleVisible ? 'Hide example' : 'Show example'}
          onClick={handleToggleExample}
        />
        <Button disabled={!file} onClick={handleClickSubmit}>
          Import
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default DialogZipCodesImport;
