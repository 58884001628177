import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import { storeOrderOverview } from 'stores/_mobx/workflow/order/overview';
import { IconButton } from 'components/button';

export const DiagnosticViewingPage = ({
  location,
  history,
}: RouteComponentProps) => {
  useEffect(() => {
    storeOrderOverview.getEUnityLink(location.search).then((url) => {
      if (url) window.location.href = url;
      else history.replace('/');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return <SpinnerFixed />;
};

export const DiagnosticViewingLink = observer(
  ({ orderId }: { orderId: number }) => {
    const { isEUnityLinkFetching } = storeOrderOverview;

    const handleClick = () => {
      storeOrderOverview.openEUnityLinkByParams(orderId);
    };

    return (
      <IconButton disabled={isEUnityLinkFetching} onClick={handleClick}>
        <i className="icon icon-eunity" />
      </IconButton>
    );
  }
);
