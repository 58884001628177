import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import FormCptCategory from './components/Form';

import {
  storeCptCategory,
  CategoryType,
} from 'stores/_mobx/systemSetup/masterSetting/cptCategory';
import { URL_CPT_CATEGORY } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const CptCategoryEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, cptCategory } = storeCptCategory;

  const handleSubmit = (payload: CategoryType) =>
    storeCptCategory.modifyCptCategory(payload).then((response) => {
      if (response) {
        return response;
      }
      history.push(URL_CPT_CATEGORY);
    });

  useEffect(() => {
    if (cptCategory === null) {
      history.replace(URL_CPT_CATEGORY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cptCategory]);

  useEffect(() => {
    const id = Number(params.id);

    if (id !== cptCategory?.id) {
      storeCptCategory.getCptCategory(id);
    }
    return storeCptCategory.clearCptCategory;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit CPT Category" />

      <FormCptCategory
        fetching={fetching}
        backUrl={URL_CPT_CATEGORY}
        defaultValues={cptCategory}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default observer(CptCategoryEditPage);
