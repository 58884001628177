import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import ProviderTypeForm from './components/Form';

import {
  storeProviderType,
  ProviderFormModel,
} from 'stores/_mobx/systemSetup/masterSetting/providerType';
import { URL_PROVIDER_TYPE } from 'constant/path/systemSetup';

interface MatchParam {
  id: string;
}
interface PropsType extends RouteComponentProps<MatchParam> {}

const ProviderTypeEditPage = ({ match: { params }, history }: PropsType) => {
  const { fetching, providerType } = storeProviderType;

  const handleSubmit = (payload: ProviderFormModel) =>
    storeProviderType.updateProviderType(payload).then((error) => {
      if (error) return error;

      history.push(URL_PROVIDER_TYPE);
    });

  useEffect(() => {
    storeProviderType.getProviderType(Number(params.id));
  }, [params.id]);

  return (
    <>
      <Title title="Edit Provider Type" />
      <ProviderTypeForm
        defaultValues={providerType}
        backUrl={URL_PROVIDER_TYPE}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(ProviderTypeEditPage);
