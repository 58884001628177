import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Grid } from 'components/grid';
import { Textarea } from 'components/form/textarea';
import { Button } from 'components/button';
import resolver from './validation';

import { TOrderNewPayItemUpdated } from 'actions/billing-coding/InvoicesActions';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface FormModel {
  invoice_no: number;
  note: string;
  total_price: number;
}

export interface FormPayload extends FormModel {
  dataSource: {
    IsSelect: boolean;
    orderId: number;
  }[];
}

const columns = [
  { name: 'studyId', title: 'Study ID' },
  { name: 'patientname', title: 'Patient Name' },
  {
    name: 'dos',
    title: 'DOS',
    className: 'width-100',
    render: (date: string) => dateToLocalTimezone({ date, dateOnly: true }),
  },
];

interface PropsType {
  defaultValues: FormModel;
  orders: TOrderNewPayItemUpdated[];
  onClose: () => void;
  onSubmit: (data: FormPayload) => Promise<any>;
}

const DialogFormWriteForm = ({
  orders,
  defaultValues,
  onClose,
  onSubmit,
}: PropsType) => {
  const [selected, setSelected] = useState<number[]>([]);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const filteredOrders = useMemo(() => {
    const existedOrders: Record<string, boolean> = {};
    return orders.filter(({ studyId }) => {
      if (existedOrders[studyId]) return false;
      return (existedOrders[studyId] = true);
    });
  }, [orders]);

  const handleClickSubmit = () => {
    handleSubmit((data: FormModel) => {
      const dataSource = filteredOrders.map(({ studyId, orderId }) => ({
        IsSelect: selected.includes(studyId),
        orderId,
      }));
      const payload = {
        dataSource,
        invoice_no: data.invoice_no,
        note: data.note,
        total_price: data.total_price,
      };
      return onSubmit(payload);
    })();
  };

  return (
    <Dialog handleClose={onClose}>
      <DialogHeader title="Study List" onClose={onClose} />
      <DialogBody className={isSubmitting ? 'on-ajax' : ''}>
        <Grid
          selectId="studyId"
          dataSource={filteredOrders}
          onSelectChange={setSelected}
          disablePagination
          stateless
          noControl
          columns={columns}
        />
        <form onSubmit={handleClickSubmit}>
          <Textarea
            name="note"
            label="Note"
            required
            rows={4}
            control={control}
          />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          text="Move to Assignment"
          disabled={!selected.length || isSubmitting}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogFormWriteForm;
