import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';

import {
  storeDesignation,
  FilterModel,
} from 'stores/_mobx/systemSetup/masterSetting/designation';

const options = [
  { label: 'Company User Types', value: 0 },
  { label: 'Client User Types', value: 1 },
];

interface PropsType {
  fetching: boolean;
  onFilterChange: (filter: FilterModel) => void;
}

const Filter = ({ fetching, onFilterChange }: PropsType) => {
  const { control, watch } = useForm<FilterModel>({
    defaultValues: storeDesignation.filter,
  });

  useEffect(() => {
    const subscription = watch((formValue) => {
      onFilterChange(formValue as FilterModel);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row">
      <Input
        name="name"
        className="col-sm-12 col-md-6 col-lg-4"
        placeholder="User Type"
        control={control}
      />
      <Radio
        name="userType"
        className="col-sm-auto inline"
        disabled={fetching}
        options={options}
        control={control}
      />
    </form>
  );
};

export default Filter;
