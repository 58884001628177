import { useForm } from 'react-hook-form';

import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { DropdownDivision } from 'components/project/dropdown/Division';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropDownExamType } from 'components/project/dropdown/ExamTypeDropdown';
import { DropdownRegion } from 'components/project/dropdown/RegionDropdown';
import { DateRange } from 'components/form/dateRange';
import { Input } from 'components/form/textField';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';

import {
  storeUnbilledClaims,
  defaultFilterValue,
  FilterType,
} from 'stores/_mobx/report/unbilledClaims';

const commonClassName = 'col-md-6 col-lg-4 col-xl-3';

interface PropsType {
  isFilterVisible: boolean;
  fetching: boolean;
  onChangeFilter: (payload: FilterType) => void;
}

const Filter = ({ isFilterVisible, fetching, onChangeFilter }: PropsType) => {
  const { control, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeUnbilledClaims.filter,
  });

  const handleClickReset = () => {
    reset(defaultFilterValue);
    onChangeFilter(defaultFilterValue);
  };

  return (
    <form
      className={isFilterVisible ? '' : 'd-none'}
      onSubmit={handleSubmit(onChangeFilter)}>
      <div className="row">
        <Input
          name="patientLastName"
          label="Patient Last Name"
          className={commonClassName}
          control={control}
        />
        <Input
          name="patientFirstName"
          label="Patient First Name"
          className={commonClassName}
          control={control}
        />
        <DropDownFacility
          name="facility"
          label="Facility"
          isMulti
          isStyleDependsOnValue
          className={commonClassName}
          control={control}
        />
        <DropdownDivision
          name="division"
          label="Division"
          className={commonClassName}
          control={control}
        />
        <DropDownState
          name="state"
          label="State"
          isMulti
          isStyleDependsOnValue
          className={commonClassName}
          control={control}
        />
        <DropdownRegion
          name="region"
          label="Region"
          isMulti
          isStyleDependsOnValue
          className={commonClassName}
          control={control}
        />
        <DropDownExamType
          name="modality"
          label="Modality"
          isMulti
          isStyleDependsOnValue
          className={commonClassName}
          control={control}
        />
        <div />
        <DateRange
          nameFrom="dosStart"
          nameTo="dosEnd"
          nameRadio="period"
          radioClassName="col-sm-12 col-md-6 col-lg-auto"
          control={control}
        />

        <ControlsLayout alignX="auto">
          <Button
            variant="warning"
            text="Reset"
            disabled={fetching}
            onClick={handleClickReset}
          />
          <Button type="submit" text="Apply" disabled={fetching} />
        </ControlsLayout>
      </div>
    </form>
  );
};

export default Filter;
