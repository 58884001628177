import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import DropDownTechnologist from 'components/project/dropdown/TechnologistDropDown';
import { Input } from 'components/form/textField';
import { InputCalendar } from 'components/form/inputCalendar';
import { Radio } from 'components/form/radio';
import { Button } from 'components/button';

import {
  storeCheckOutIn,
  initialFilterValues,
  FilterModel,
} from 'stores/_mobx/workflow/checkOutIn/checkOutIn';

const className = 'col-md-6 col-lg-3';

const SERVICE_STATUS = [
  { value: 'N', label: 'IN' },
  { value: 'Y', label: 'OUT' },
];

interface PropsType {
  fetching: boolean;
  isVisible: boolean;
  onChange: (data: FilterModel) => void;
}

const FilterCheckOut = ({ fetching, isVisible, onChange }: PropsType) => {
  const {
    control,
    formState: { isDirty },
    watch,
    reset,
  } = useForm({ defaultValues: storeCheckOutIn.filter });

  const handleClickReset = () => {
    reset(initialFilterValues);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      onChange(formValue as FilterModel);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className={`row${isVisible ? '' : ' d-none'}`}>
      <DropDownTechnologist
        label="Technologist"
        name="technologistId"
        className={className}
        control={control}
      />
      <Input
        name="vehicle"
        label="Vehicle"
        className={className}
        control={control}
      />
      <Input name="ekg" label="EKG" className={className} control={control} />
      <Input
        name="phone"
        label="Phone"
        className={className}
        control={control}
      />
      <InputCalendar
        name="date"
        label="Check Out Date"
        className={className}
        control={control}
      />
      <Radio
        name="serviceId"
        label="Service ID"
        options={SERVICE_STATUS}
        className="col-sm-6 col-md-3 me-auto part-inline"
        control={control}
      />
      <div className="col-sm-6 col-md-auto align-self-center">
        <Button
          text="Reset"
          variant="warning"
          disabled={!isDirty || fetching}
          onClick={handleClickReset}
        />
      </div>
    </form>
  );
};

export default FilterCheckOut;
