import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { PureInput } from 'components/form/textField';
import Title from 'components/project/common/title';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';

import { storeCodeMapping } from 'stores/_mobx/systemSetup/codeSet/codeMapping';
import {
  URL_CODE_MAPPING_BY_MODALITY_ADD,
  URL_CODE_MAPPING_BY_MODALITY,
} from 'constant/path/systemSetup';
import { CODES_SET_MAPPING as PAGE_ID } from 'constant/pagesId/systemSetup';

const columns = [
  { name: 'name', title: 'Service name' },
  {
    name: 'id',
    title: 'Control',
    className: 'width-100',
    render: (id: number) => (
      <div className="control">
        <Link
          className="bi bi-pencil"
          to={`${URL_CODE_MAPPING_BY_MODALITY}/edit/${id}`}
        />
        <IconButton onClick={() => storeCodeMapping.setIdForDelete(id)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const CodeMappingListPage = () => {
  const {
    codeList,
    codeTotal,
    fetching,
    filter,
    idForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeCodeMapping;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleChangeFilter = (codeName: string) => {
    storeCodeMapping.setFilter(codeName);

    debouncedMethod(codeName);
  };

  const handleConfirmDelete = () => {
    storeCodeMapping.deleteCode().then((isSucceed) => {
      if (isSucceed) {
        storeCodeMapping.getCodesListMain();
      }
    });
  };

  useEffect(() => {
    storeCodeMapping.getCodesListMain();
  }, [pagination]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Code Mapping By Modality" />

      <PureInput
        name="codeName"
        className="col-md-6 col-lg-4"
        label="Service Name"
        value={filter}
        onChange={handleChangeFilter}
      />

      <Grid
        id="code_set_code_mapping_by_modality"
        columns={columns}
        onAjax={fetching}
        dataSource={codeList}
        dataSourceCount={codeTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton
            title="Add"
            url={URL_CODE_MAPPING_BY_MODALITY_ADD}
          />
        }
      />

      {idForDelete ? (
        <DialogConfirm
          onCancel={storeCodeMapping.clearIdForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(CodeMappingListPage);
