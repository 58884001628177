import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import GridActionControl from 'page/components/GridActionControls';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import { LayoutSideTitle } from 'components/layout';
import DialogServicesNotes from '../components/DialogServicesNotes';

import { storeUltrasoundUnit } from 'stores/_mobx/workflow/equipmentInventory/ultrasoundUnit';
import { EI_UNIT_ULTRASOUND as PAGE_ID } from 'constant/pagesId/workflow';
import {
  URL_EQUIPMENT_INVENTORY,
  URL_ULTRASOUND_UNIT,
  URL_ULTRASOUND_UNIT_ADD,
} from 'constant/path/workflow';

const columns = [
  { name: 'ultraname', title: 'Name' },
  { name: 'computer', title: 'Computer name' },
  { name: 'probname', title: 'Probe name' },
  {
    name: 'refid',
    title: 'History',
    render: (id: number) => (
      <div className="control">
        <IconButton onClick={() => storeUltrasoundUnit.setNoteId(id)}>
          <i className="icon icon-notes" />
        </IconButton>
      </div>
    ),
  },
  {
    name: 'refid',
    title: 'Actions',
    className: 'width-100',
    render: (id: number) => (
      <GridActionControl
        id={id}
        url={`${URL_ULTRASOUND_UNIT}/edit/${id}`}
        onDelete={storeUltrasoundUnit.setIdForDelete}
      />
    ),
  },
];

const UltrasoundUnitListPage = () => {
  const {
    fetching,
    noteId,
    idForDelete,
    unitList,
    unitTotal,
    page: { pagination, setPagination },
  } = storeUltrasoundUnit;

  const handleConfirmDelete = () => {
    storeUltrasoundUnit.deleteUnit().then((isSucceed) => {
      if (isSucceed) {
        storeUltrasoundUnit.getUnitMain();
      }
    });
  };

  useEffect(() => {
    storeUltrasoundUnit.getUnitMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Ultrasound Unit">
        <Link to={URL_EQUIPMENT_INVENTORY} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <Grid
        id="ultrasound_unit_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={unitList}
        dataSourceCount={unitTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_ULTRASOUND_UNIT_ADD} />
        }
      />

      {Boolean(idForDelete) && (
        <DialogConfirm
          onCancel={storeUltrasoundUnit.clearIdForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this Ultrasound Unit?
        </DialogConfirm>
      )}

      {Boolean(noteId) && (
        <DialogServicesNotes
          type="XF"
          id={noteId}
          onClose={storeUltrasoundUnit.clearNoteId}
        />
      )}
    </>
  );
};

export default observer(UltrasoundUnitListPage);
