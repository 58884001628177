import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import { storeCallLog, FormModel } from 'stores/_mobx/workflow/order/callLog';
import { URL_CALL_LOG } from 'constant/path/workflow';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const CallLogEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, log } = storeCallLog;

  const handleSubmit = async (data: FormModel) =>
    storeCallLog.updateCallLog(data).then((isSucceed) => {
      if (isSucceed) history.push(URL_CALL_LOG);
    });

  useEffect(() => {
    if (!fetching) storeCallLog.getLog(Number(params.id));

    return storeCallLog.clearLog;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Call Log" />
      <Form
        backUrl={URL_CALL_LOG}
        defaultValues={log}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(CallLogEditPage);
