import { action, makeObservable, observable } from 'mobx';

import { TPagination } from 'components/grid/GridTypes';

import { apiRequest } from 'services/RequestService';

interface PhysicianShortInfoResponseType {
  UserName: string;
  email: string;
  first_name: string;
  last_name: string;
  npi: string;
  office_address: string;
  officecity: string;
  officestate: string;
  officezipcode: string;
  refid: number;
  type: string;
  user_id: number;
  work_phone: string;
}

export interface PhysicianShortInfoType {
  value: number;
  label: string;
  user_id: number;
  firstName: string;
  lastName: string;
  npi: string;
  type: string;
  groupId: number;
}

export interface PayloadPhysicianShortInfoType {
  firstName: string;
  lastName: string;
  npi: string;
  pagination: TPagination;
  shouldReset?: boolean;
}

class PhysicianExtendedOptions {
  fetching: boolean = false;
  physicianExtendedOptions: PhysicianShortInfoType[] = [];
  physicianExtendedOptionsTotal: number = 0;

  filterSettings: PayloadPhysicianShortInfoType = {
    firstName: '',
    lastName: '',
    npi: '',
    pagination: { page: 0, skip: 0, pageSize: 20 },
    shouldReset: false,
  };

  constructor() {
    makeObservable(this, {
      fetching: observable,
      physicianExtendedOptions: observable,
      physicianExtendedOptionsTotal: observable,

      setFetching: action,
      setPhysicianExtendedOptions: action,
      setPhysicianExtendedOptionsTotal: action,
      clearPhysicianShortInfoList: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setPhysicianExtendedOptions(list: PhysicianShortInfoType[]) {
    this.physicianExtendedOptions = list;
  }

  setPhysicianExtendedOptionsTotal(count: number) {
    this.physicianExtendedOptionsTotal = count;
  }

  clearPhysicianShortInfoList() {
    this.physicianExtendedOptions = [];
    this.physicianExtendedOptionsTotal = 0;
  }

  async getExtendedOptionsCount(
    payload: PayloadPhysicianShortInfoType = this.filterSettings
  ) {
    const data = [payload.firstName, payload.lastName, payload.npi];
    try {
      const count = await apiRequest<number>({
        url: 'groupmanager.GroupManager.GetPhysicianCount',
        data,
      });

      this.setPhysicianExtendedOptionsTotal(count);
    } catch (e: any) {
      this.setPhysicianExtendedOptionsTotal(0);
    }
  }

  async getExtendedOptions(
    {
      pagination,
      shouldReset,
      ...payload
    }: PayloadPhysicianShortInfoType = this.filterSettings
  ) {
    if (this.fetching) return;

    const data = [
      payload.firstName,
      payload.lastName,
      payload.npi,
      pagination.skip,
      pagination.pageSize,
    ];

    this.setFetching(true);

    this.filterSettings = {
      ...payload,
      shouldReset,
      pagination,
    };
    try {
      const response = await apiRequest<PhysicianShortInfoResponseType[]>({
        url: 'groupmanager.GroupManager.GetPhysicianList',
        data,
      });

      const physicians = response.map((physician) => ({
        firstName: physician.first_name,
        lastName: physician.last_name,
        npi: physician.npi,
        value: physician.refid,
        label: `${physician.last_name} ${physician.first_name}`.trim(),
        user_id: physician.user_id,
        type: physician.type,
        groupId: 0,
      }));

      const finalPhysiciansList = shouldReset
        ? physicians
        : this.physicianExtendedOptions.concat(physicians);

      this.setPhysicianExtendedOptions(finalPhysiciansList);
      return;
    } catch (e: any) {
      this.setPhysicianExtendedOptions([]);
      return;
    } finally {
      this.setFetching(false);
    }
  }

  getExtendedOptionsMain = (
    payload: PayloadPhysicianShortInfoType = this.filterSettings
  ) => {
    const promiseCount = this.getExtendedOptionsCount(payload);

    const promiseList = this.getExtendedOptions({
      ...payload,
      shouldReset: true,
    });

    Promise.all([promiseCount, promiseList]);
  };
}

export const storePhysicianExtendedOption = new PhysicianExtendedOptions();
