import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import UserProfileStore from 'stores/UserProfileStore';
import { PhysicianShortInfoType } from './physicianExtendedOption';

export const defaultValuesGroupDetails: PhysicianGroupDetailsType = {
  id: 0,
  admin: [],
  alsoRadiologyGroup: 'N',
  defaultAffiliation: 'N',
  email: '',
  fax: '',
  groupDesc: '',
  groupName: '',
  integrationIds: [],
  isIntegrationChecked: false,
  notificationType: 'N',
  physicians: [],
  radiologyGroupId: 0,
};

const defaultPermission: PermissionType = {
  canModifyIntegrationOfGroup: false,
  canAssignGroupAdmin: false,
};

interface PermissionType {
  canModifyIntegrationOfGroup: boolean;
  canAssignGroupAdmin: boolean;
}

interface GroupTrimmedInfoResponseType {
  groupdesc: string;
  groupname: string;
  refid: string;
}

export interface GroupTrimmedInfoType {
  groupName: string;
  description: string;
  id: number;
}

export interface GroupType {
  groupdesc: string;
  groupname: string;
  pcpcnt: number;
  refid: number;
}

interface PhysicianShortDetailsType {
  firstName: string;
  lastName: string;
  middleName: string;
  id: number;
  supervisedGroup: number;
  type: string;
  user_id: number;
  npi: string;
}

interface PhysicianGroupDetailsResponseType {
  admin: number[];
  alsoRadiologyGroup: 'Y' | 'N';
  defaultAffiliation: 'Y' | 'N';
  email: string;
  fax: string;
  groupDesc: string;
  groupName: string;
  integrationIds: number[];
  notificationType: 'E' | 'F' | 'N';
  radiologyGroupId: string;
  membersOfGroup: PhysicianShortDetailsType[];
  refid: number;
}

export interface PhysicianGroupDetailsType
  extends Omit<
    PhysicianGroupDetailsResponseType,
    'membersOfGroup' | 'refid' | 'radiologyGroupId'
  > {
  physicians: PhysicianShortInfoType[];
  id: number;
  radiologyGroupId: number;
  isIntegrationChecked: boolean;
}

interface OptionsResponseType {
  label: string;
  data: string;
}

export interface OptionType {
  label: string;
  value: number;
}

class GroupManager {
  fetching: boolean = false;
  fetchingDetails: boolean = false;
  fetchingOptions: boolean = false;
  groupList: GroupType[] = [];
  groupTotal: number = 0;
  groupTrimmedInfoList: GroupTrimmedInfoType[] = [];
  groupDetails: PhysicianGroupDetailsType = defaultValuesGroupDetails;
  idForDelete: number = 0;
  options: OptionType[] = [];
  filter: string = '';
  permission: PermissionType = defaultPermission;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'clinician_manager_group_manager_grid' });

    makeObservable(this, {
      fetching: observable,
      fetchingDetails: observable,
      fetchingOptions: observable,
      groupList: observable,
      groupTotal: observable,
      groupTrimmedInfoList: observable,
      groupDetails: observable,
      idForDelete: observable,
      options: observable,
      filter: observable,
      permission: observable,

      setFetching: action,
      setFetchingDetails: action,
      setFetchingOptions: action,
      setGroupList: action,
      setGroupTotal: action,
      setGroupDetails: action,
      setFilter: action,
      setGroupTrimmedInfoList: action,
      setPermission: action,
      setIdForDelete: action.bound,
      clearIdForDelete: action.bound,
      clearGroupDetails: action.bound,
      clearGroupTrimmedInfoList: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingDetails(fetching: boolean) {
    this.fetchingDetails = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setGroupList(list: GroupType[]) {
    this.groupList = list;
  }

  setGroupTotal(count: number) {
    this.groupTotal = count;
  }

  setGroupDetails(groupDetails: PhysicianGroupDetailsType) {
    this.groupDetails = groupDetails;
  }

  setFilter(filter: string) {
    this.filter = filter;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setPermission(permission: PermissionType) {
    this.permission = permission;
  }

  setGroupTrimmedInfoList(groups: GroupTrimmedInfoType[]) {
    this.groupTrimmedInfoList = groups;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  clearGroupTrimmedInfoList() {
    this.groupTrimmedInfoList = [];
  }

  clearGroupDetails() {
    this.groupDetails = defaultValuesGroupDetails;
  }

  checkPermission() {
    this.setPermission({
      canModifyIntegrationOfGroup: UserProfileStore.getUserType() === 'U',
      canAssignGroupAdmin: UserProfileStore.haveAccess(
        'physician_admin_assign'
      ),
    });
  }

  async getGroupList(filter: string) {
    const { skip, pageSize } = this.page.pagination;
    try {
      const response = await apiRequest<GroupType[]>({
        url: 'groupmanager.GroupManager.GetAllGroup',
        data: [skip, pageSize, filter],
      });

      const list = response.map((group) => ({
        ...group,
        pcpcnt: Number(group.pcpcnt),
        refid: Number(group.refid),
      }));

      this.setGroupList(list);
    } catch (e: any) {
      this.setGroupList([]);
    }
  }

  async getGroupCount(filter: string) {
    try {
      const response = await apiRequest<GroupType[]>({
        url: 'groupmanager.GroupManager.GetGroupCount',
        data: [filter],
      });

      this.setGroupTotal(Number(response) || 0);
    } catch (e: any) {
      this.setGroupTotal(0);
    }
  }

  getGroupListMain = (filter: string = this.filter) => {
    this.setFetching(true);

    const promiseList = this.getGroupList(filter);

    const promiseCount = this.getGroupCount(filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getGroupsTrimmedInfoList() {
    this.setFetching(true);
    try {
      const response = await apiRequest<GroupTrimmedInfoResponseType[]>({
        url: 'order.Order.GetAllGroup',
      });

      const groups = response.map((group) => ({
        id: Number(group.refid),
        groupName: group.groupname,
        description: group.groupdesc,
      }));

      this.setGroupTrimmedInfoList(groups);
    } catch (e: any) {
      this.setGroupTrimmedInfoList([]);
    } finally {
      this.setFetching(false);
    }
  }

  async deleteGroup() {
    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'groupmanager.GroupManager.DeleteGroup',
        data: [this.idForDelete],
      });
      if (response === 'S') {
        Notification.success('Group was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOptions(true);

    try {
      const list = await apiRequest<OptionsResponseType[]>({
        url: 'groupmanager.GroupManager.GetAllClinicianGroup',
      });

      const options = list
        .map(({ label, data }) => ({
          label,
          value: Number(data),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }

  async getOptionsByPhysician(userType: string | null) {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOptions(true);

    try {
      const list = await apiRequest<Array<{ data: number; label: string }>>({
        url: 'groupmanager.GroupManager.GetGroupByPhysician',
        data: [userType],
      });

      const options = list.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }

  async getGroupDetails(id: number) {
    this.setFetchingDetails(true);
    try {
      const [response] = await apiRequest<[PhysicianGroupDetailsResponseType]>({
        url: 'groupmanager.GroupManager.GetGroupById',
        data: [id],
      });

      if (!response.groupName) {
        throw new Error("Physician Group doesn't exist");
      }

      const { membersOfGroup, refid, radiologyGroupId, ...details } =
        response;

      const physicians = membersOfGroup.map(
        ({ id, supervisedGroup, ...physician }) => ({
          ...physician,
          value: id,
          label: `${physician.lastName} ${physician.firstName}`.trim(),
          groupId: supervisedGroup,
        })
      );

      this.setGroupDetails({
        ...details,
        id: refid,
        physicians,
        radiologyGroupId: Number(radiologyGroupId),
        isIntegrationChecked: Boolean(details.integrationIds.length),
      });
      return true;
    } catch (e: any) {
      Notification.danger("Physician Group with this ID doesn't exist!");
      this.setGroupDetails(defaultValuesGroupDetails);
      return false;
    } finally {
      this.setFetchingDetails(false);
    }
  }

  async addGroup(payload: PhysicianGroupDetailsType) {
    this.setFetchingDetails(true);
    try {
      const physicians = payload.physicians.map(({ value, type }) => ({
        refid: value,
        type,
      }));

      const data = [
        payload.groupName,
        payload.groupDesc,
        payload.notificationType,
        payload.email,
        payload.fax,
        payload.defaultAffiliation,
        payload.alsoRadiologyGroup,
        payload.radiologyGroupId,
        physicians,
        payload.admin,
        payload.integrationIds,
      ];

      const response = await apiRequest<'SE' | 'E' | 'S'>({
        url: 'groupmanager.GroupManager.AddGroup',
        data,
      });

      if (response === 'S') {
        Notification.success('Clinical Group created successfully');
        return null;
      } else if (response === 'E') {
        return { message: 'Group with this name already exist.' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Error occurred!');
      return { message: '' };
    } finally {
      this.setFetchingDetails(false);
    }
  }

  async editGroup(payload: PhysicianGroupDetailsType) {
    this.setFetchingDetails(true);
    try {
      const physicians = payload.physicians.map(({ value, type }) => ({
        refid: value,
        type,
      }));

      const data = [
        payload.id,
        payload.groupName,
        payload.groupDesc,
        payload.notificationType,
        payload.email,
        payload.fax,
        payload.defaultAffiliation,
        payload.alsoRadiologyGroup,
        payload.radiologyGroupId,
        physicians,
        payload.admin,
        payload.integrationIds,
      ];

      const response = await apiRequest<'SE' | 'E' | 'S'>({
        url: 'groupmanager.GroupManager.EditGroup',
        data,
      });

      if (response === 'S') {
        Notification.success('Clinical Group updated successfully');
        return null;
      } else if (response === 'E') {
        return { message: 'Group with this name already exist.' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Error occurred!');
      return { message: '' };
    } finally {
      this.setFetchingDetails(false);
    }
  }

  findOptionById(id: number) {
    return this.options.find(({ value }) => value === id);
  }
}

export const storeGroupManager = new GroupManager();
