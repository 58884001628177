import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    email: yup
      .string()
      .required("Please, enter the recipient's email!")
      .email('Invalid email address.'),
    message: yup.string().required('Please, enter a message!'),
  })
);

export default validation;
