import PureCanvas, { PureCanvasProps } from './PureCanvas';

/**
  @prop {string} 'data-testid' - data attribute for testing libraries
  If data-testid not presented, then prefix for data-testid attribute will generated with 'name' property
  '{dataTestId}-label' - data attribute for the label
  '{dataTestId}-error' - data attribute for the error block
*/

export interface PropsType
  extends Omit<PureCanvasProps, 'onChange' | 'onBlur' | 'onFocus' | 'pattern'> {
  onSetValue?: (name: string, value: string) => void;
  errorMessage?: string;
}

const SignaturePad = ({
  onSetValue,
  name,
  required = false,
  ...rest
}: PropsType) => {
  const handleChange = (value: string) => {
    onSetValue(name, value);
  };

  return (
    <PureCanvas
      required={required}
      {...rest}
      name={name}
      onChange={handleChange}
    />
  );
};

export default SignaturePad;
