import { useEffect, useState } from 'react';

import { Grid } from 'components/grid';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';

import {
  storeDocumentType,
  OptionResponseType,
} from 'stores/_mobx/systemSetup/masterSetting/documentType';

const COLUMNS = [
  { name: 'label', title: 'Document Type' },
  { name: 'code', title: 'Code' },
];

interface ImportItemType extends OptionResponseType {
  value: number;
}

interface PropsType {
  selectedDocuments: string[];
  onCancel: () => void;
  onSubmit: (payload: { code: string }[]) => void;
}

const DialogRequestedDocuments = ({
  selectedDocuments,
  onCancel,
  onSubmit,
}: PropsType) => {
  const [fetching, setFetching] = useState<boolean>(true);

  const [documents, setDocuments] = useState<ImportItemType[]>([]);

  const [selected, setSelected] = useState<string[]>(selectedDocuments);

  useEffect(() => {
    storeDocumentType
      .getOptions()
      .then((response) => {
        setDocuments(response);
      })
      .finally(() => {
        setFetching(false);
      });
  }, []);

  const prepareResponse = () => {
    const codesList: { code: string }[] = selected.map((code) => ({ code }));
    onSubmit(codesList);
  };

  return (
    <Dialog size="large" handleClose={onCancel}>
      <DialogHeader title="Choose Requested Documents" onClose={onCancel} />
      <DialogBody>
        <Grid
          key="content"
          dataSource={documents}
          columns={COLUMNS}
          onAjax={fetching}
          dataSourceCount={documents.length}
          disablePagination
          noControl
          selectId="code"
          onSelectChange={setSelected}
          selectedIds={selected}
        />
      </DialogBody>
      <DialogFooter>
        <Button text="Cancel" variant="warning" onClick={onCancel} />
        <Button text="Submit" onClick={prepareResponse} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogRequestedDocuments;
