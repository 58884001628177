import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';
import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';

interface OptionType {
  value: number;
  label: string;
}

interface PropsType extends PAbstractDropdown {}

const options: OptionType[] = [];

for (let i = 2009; i <= new Date().getFullYear(); i++) {
  options.push({ label: `${i}`, value: i });
}

export default class YearSelectionDropdown extends AbstractDropdown<
  PropsType,
  SAbstractDropdown
> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return options;
  }
}

export const DropDownYear = (props: Omit<SelectPropsType, 'options'>) => (
  <Select {...props} options={options} />
);
