import React from 'react';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import Form from 'components/form/Form';
import Radio from 'components/form/input/Radio';
import Notification from 'components/modal/Notification';
import { Grid } from 'components/grid';
import CsvExporter from 'utils/CsvExporter';
import XlsxExporter from 'utils/XlsxExporter';
import AllDsExporter from 'utils/AllDsExporter';
import { downloadFile } from 'utils/downloadFile';
import { BASE_URL_FILE_DIR } from 'constant/config';

/**
 *
 *  This component use columns attribute export
 *  if export is string, and it equal to 'exclude', this columns will be excluded
 *  if export is string, and it equal to 'render', method render will be used for data fetching
 *
 *  if export value is an object, it can contain `exclude` and `target` keys.
 *     If `exclude` key is equal to `false`, column will be excluded from report
 *     If `target` key is equal to 'render' string, render method will be used for data fetching
 *
 *  {state.exporter ? <Exporter isOpen <- modal requirements, required
 *                              callback={(r) => this.setState({exporter: false})} <- modal requirements, required
 *                              this={this}  <- reference to parent object. Parent object should contain ref to grid, as `this.refs.grid`. Required
 *                              reportName='My custom report' <- custom report name. As 'report' as default, optional
 *                              pdfFolder='phpmanager/services/doc_images/reports' <- custom folder for pdf reports, optional
 *                              pdfExporter=function(data, columns){} <- function to export current result set, return type - thennable, optional
 *                              exporter='InvoiceExporter'  <- php backend class, that will execute 'export all' functionality. optional
 *                              filters={() => {return this.getDataArguments(true)}}  <- filters to fetch data for 'export all'. Page size should be 9999999 or greater, optional, required only if exporter prop defined
 *                              getDataSource={() => {return {a: 'a'}}} <- custom datasource function. Please pay attention to grid sort. optional
 *                              /> : null}
 */

const defaultOptions = [
  { value: 'csv', label: 'CSV' },
  { value: 'xlsx', label: 'XLSX' },
];

export interface PExporter {
  reportName: string;
  pdfFolder?: string;
  pdfExporter?: (
    data: any,
    columns: any,
    volume: any,
    filters: any
  ) => Promise<string>;
  exporter?: string;
  logicPagination?: any;
  this?: React.Component;
  grid?: React.Component;
  afterDataSource?: Array<any>;
  filters?: any;
  exportOptions?: Array<{
    value: string;
    label: string;
  }>;
  exportOnBackend?: boolean;
  getDataSource?: () => Array<any>;
  getColumns?: () => Array<any>;
  callback: (result: boolean) => void;
}

export class TExporterModel {
  type: string = 'csv';
  volume: string = 'screen';
}

export class SExporter {
  model: TExporterModel = new TExporterModel();
  errors: any = {};
  errorMessages: any = {};
  selected: Array<any> = [];
}

export default class Exporter extends React.Component<PExporter, SExporter> {
  submitRef = React.createRef<HTMLButtonElement>();

  constructor(props: PExporter) {
    super(props);
    const state = new SExporter();
    const type = localStorage.getItem('export_type');
    const optionIsExist = (props.exportOptions || defaultOptions).some(
      ({ value }) => value === type
    );
    state.model.type = optionIsExist ? type : 'csv';
    this.state = state;
  }

  handleClose = () => {
    this.props.callback(false);
  };

  handleSubmit = () => {
    this.submitRef.current?.click();
  };

  onUpdateModel = (
    name?: string,
    value?: string,
    errorName?: string,
    errorKeys?: any,
    event?: Event,
    clb?: () => void
  ) => {
    const model = {
      ...this.state.model,
      [name]: value,
    };

    const errors = { ...this.state.errors, [name]: errorName };

    this.setState({ model, errors }, clb);
  };

  submit = (model?: any, hasErrors?: boolean, errors?: any) => {
    if (hasErrors) {
      this.setState({ errors, model });
    } else {
      this.submitSuccess(model);
    }
  };

  render() {
    return (
      <Dialog handleClose={this.handleClose}>
        <DialogHeader title="Export" onClose={this.handleClose} />
        <DialogBody>
          <Form
            ref="form"
            model={this.state.model}
            errors={this.state.errors}
            errorMessages={this.state.errorMessages}
            onCollectValues={this.onUpdateModel}
            submit={this.submit}>
            <Radio
              name="type"
              validations="required"
              onSetValue={(n, v, e) => this.onUpdateType(n, v, e)}
              options={this.getTypeOptions()}
            />
            <Radio
              name="volume"
              validations="required"
              options={this.getVolumeOptions()}
            />

            <button type="submit" className="d-none" ref={this.submitRef} />
          </Form>
        </DialogBody>
        <DialogFooter>
          <Button text="Submit" onClick={this.handleSubmit} />
        </DialogFooter>
      </Dialog>
    );
  }

  onUpdateType(name?: string, value?: string, errorName?: string) {
    localStorage.setItem('export_type', value);
    this.onUpdateModel(name, value, errorName);
  }

  getColumns() {
    const model = this.state.model;

    return (this.props.this as any)?.getExportColumns
      ? (this.props.this as any).getExportColumns(model.type, model.volume)
      : this.getGridCleanColumns();
  }

  submitSuccess(model: TExporterModel) {
    const { exportOnBackend } = this.props;
    const columns = this.getColumns();
    const filters = this.getFiltersString();

    if (
      (exportOnBackend && model.type !== 'pdf') ||
      (model.volume === 'all' && !this.props.logicPagination)
    ) {
      // Only for export facilities & physicians
      const idForExport =
        exportOnBackend && model.volume !== 'all'
          ? this.prepareData().map(({ refid }: any) => Number(refid))
          : undefined;
      if (model.volume !== 'all' && !idForExport.length) {
        Notification.warning('Nothing to export');
        return;
      }
      const exporter = new AllDsExporter(
        columns,
        model.type,
        this.props.exporter,
        this.props.filters,
        filters,
        idForExport
      );
      exporter
        .exportAndDownload(this.props.reportName || 'report')
        .catch(() => {
          Notification.danger('Error occurred during exporting data.');
        });
    } else {
      let exporter;
      let data = this.prepareData();
      if (data !== null && data.length === 0) {
        Notification.warning('Nothing to export');
        return;
      }
      if (this.props.afterDataSource) {
        data = [].concat(data, this.props.afterDataSource);
      }

      switch (model.type) {
        case 'csv':
          exporter = new CsvExporter(data, columns, filters);
          break;
        case 'xlsx':
          exporter = new XlsxExporter(data, columns, '', filters);
          break;
        case 'pdf':
          this.props
            .pdfExporter(data, columns, model.volume, filters)
            .then((name) => {
              const fileName = name.replace(/^\//, '');
              downloadFile(
                `${this.props.pdfFolder || BASE_URL_FILE_DIR}${fileName}`,
                true
              );
            });
          this.submitCallBack();
          return;
        default:
          Notification.danger(
            'An error occurred, look like export type is not specified.'
          );
          return;
      }
      exporter.exportAndDownload(this.props.reportName || 'report');
    }
    this.submitCallBack();
  }

  submitCallBack = () => {
    this.props.callback(true);
    Notification.success(
      'Please wait for a moment, your report will be ready after few moments'
    );
  };

  getTypeOptions() {
    const exportOptions = this.props.exportOptions || defaultOptions;
    return this.props.pdfExporter
      ? [...exportOptions, { value: 'pdf', label: 'PDF' }]
      : exportOptions;
  }

  getVolumeOptions() {
    const out = [{ value: 'screen', label: 'Current page' }];

    try {
      if (this.getGrid().props.onSelectChange) {
        out.push({ value: 'selected', label: 'Selected' });
      }
    } catch (e) {
      throw new Error(
        'error with this.props.this.refs.grid.state.selected chain'
      );
    }
    if (this.props.exporter || this.props.logicPagination) {
      out.push({ value: 'all', label: 'All' });
    }
    return out;
  }

  prepareData() {
    switch (this.state.model.volume) {
      case 'screen':
        return this.getCleanDataSource();
      case 'selected':
        return this.getSelectedDataSource();
      case 'all':
        return this.props.logicPagination
          ? (this.props.this.state as any).dataSource
          : null;
      default:
        Notification.danger(
          'An error occurred, look like data volume is not specified'
        );
        return;
    }
  }

  getGrid(): Grid {
    // @ts-ignore
    return this.props?.this?.refs?.grid || (this.props?.grid as Grid);
  }

  getSelectedDataSource() {
    const filteredDs = [];
    const grid = this.getGrid();
    const gridDataSource = this.getInitialDataSource();
    const { selected } = grid.state;
    const idKey = grid.props.selectId;
    for (let i = 0; i < gridDataSource.length; i++) {
      for (let s = 0; s < selected.length; s++) {
        const sel = selected[s];
        if (sel === gridDataSource[i][idKey]) {
          filteredDs.push(gridDataSource[i]);
          break;
        }
      }
    }
    return this.getCleanDataSource(filteredDs);
  }

  getInitialDataSource() {
    if (this.props.getDataSource) {
      return this.props.getDataSource();
    }
    const grid = this.getGrid();
    return grid.state.sort ? grid.getSortedDataSource() : grid.props.dataSource;
  }

  getGridCleanColumns() {
    try {
      const { getColumns } = this.props;
      const shownColumns = getColumns
        ? getColumns()
        : this.getGrid().getColumns();
      if (shownColumns[0] && shownColumns[0].name === 'select-all-column') {
        shownColumns.shift();
      }
      let index = shownColumns.length;
      while (index--) {
        const col = shownColumns[index];
        if (this.exclude(col.export)) {
          shownColumns.splice(index, 1);
        }
      }
      return shownColumns;
    } catch (e) {
      throw new Error(
        'Error with this.props.this.refs.grid.props.dataSource chain'
      );
    }
  }

  exclude(exp: any) {
    if (exp) {
      if (typeof exp === 'string') {
        return exp === 'exclude';
      }
      return exp.exclude;
    }
    return false;
  }

  getCleanDataSource(filteredDs?: any) {
    try {
      const shownColumns = this.getColumns();
      const gridDs = filteredDs || this.getInitialDataSource();
      const out = [];
      for (let i = 0; i < gridDs.length; i++) {
        const row: any = {};
        const item = gridDs[i];
        for (let key in item) {
          if (!item.hasOwnProperty(key)) {
            continue;
          }
          for (let c = 0; c < shownColumns.length; c++) {
            const col = shownColumns[c];
            if (col.name === key) {
              if (col.export && typeof col.export.target === 'function') {
                row[key] = col.export.target(item[key], item);
              } else if (this.getDataAsRender(col.export)) {
                row[key] = col.render(item[key], item);
              } else {
                row[key] = item[key];
              }
              break;
            }
          }
        }
        out.push(row);
      }
      return out;
    } catch (e) {
      throw new Error(
        'Error with this.props.this.refs.grid.props.dataSource chain'
      );
    }
  }

  getDataAsRender(exp: any) {
    if (exp) {
      if (typeof exp === 'string') {
        return exp === 'render';
      }
      return exp.target === 'render';
    }
    return false;
  }

  getFiltersString() {
    const { filters } = this.props;
    if (filters) {
      const out =
        typeof filters === 'function'
          ? filters().toString()
          : filters.toString();
      if (out && out !== '[object Object]') return out;
    }
    return '';
  }
}
