import { useEffect, forwardRef } from 'react';
import { observer } from 'mobx-react-lite';

import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';

import {
  storeThyroidShield,
  OptionType,
} from 'stores/_mobx/workflow/equipmentInventory/thyroidShield';

interface PropsType extends PAbstractDropdown {
  options: OptionType[];
  ref: any;
}

class ThyroidShieldDropdown extends AbstractDropdown<
  PropsType,
  SAbstractDropdown
> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<ThyroidShieldDropdown, any>(
  (props: PAbstractDropdown, ref) => {
    const { options, fetchingOptions } = storeThyroidShield;

    useEffect(() => {
      storeThyroidShield.getOptions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <ThyroidShieldDropdown
        {...props}
        ref={ref}
        options={options}
        fetching={fetchingOptions}
      />
    );
  }
);

export default observer(DropDownMiddleware);
