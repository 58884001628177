import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormCptCategory from './components/Form';

import {
  storeCptCategory,
  CategoryType,
} from 'stores/_mobx/systemSetup/masterSetting/cptCategory';
import { URL_CPT_CATEGORY } from 'constant/path/systemSetup';

export interface PropsType extends RouteComponentProps {}

const CptCategoryAddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: CategoryType) =>
    storeCptCategory.modifyCptCategory(payload).then((response) => {
      if (response) {
        return response;
      }
      history.push(URL_CPT_CATEGORY);
    });

  return (
    <>
      <Title title="Add CPT category" />
      <FormCptCategory backUrl={URL_CPT_CATEGORY} onSubmit={handleSubmit} />
    </>
  );
};

export default CptCategoryAddPage;
