export default class DomUtils {
    /**
     * Get element offset
     * @param container Dom element, required property
     * @param topElement Dom element, not required property.
     * If defined, offset will be calculated from container to topElement
     * @returns {{top, left}}
     */
    static getOffset(container: Element, topElement?: Element) {
        function getOffsetSum(container: Element, topElement?: Element) {
            let top = 0, left = 0;
            let containerTmp: Element = container;
            while (containerTmp && containerTmp !== topElement) {
                const htmlContainer: HTMLElement = containerTmp as HTMLElement;
                top = top + parseInt('' + htmlContainer.offsetTop);
                left = left + parseInt('' + htmlContainer.offsetLeft);
                containerTmp = htmlContainer.offsetParent;
            }
            return { top: top, left: left };
        }

        function getOffsetRect(container: Element, topElement?: Element) {
            const box = container.getBoundingClientRect();
            const body = document.body;
            const docElem = document.documentElement;
            const scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
            const scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft;
            const clientTop = docElem.clientTop || body.clientTop || 0;
            const clientLeft = docElem.clientLeft || body.clientLeft || 0;
            const top = box.top + scrollTop - clientTop;
            const left = box.left + scrollLeft - clientLeft;
            return { top: Math.round(top), left: Math.round(left) };
        }
        if (topElement || !container.getBoundingClientRect) {
            return getOffsetSum(container, topElement);
        } else {
            return getOffsetRect(container);
        }
    }

    static getBrowserSize(withoutScroll?: boolean): { width: number, height: number } {
        if (!withoutScroll && window.innerWidth) {
            return { width: window.innerWidth, height: window.innerHeight };
        }
        const de = document.documentElement;
        if (de && de.clientWidth) {
            return { width: de.clientWidth, height: de.clientHeight };
        }
        const db = document.body;
        if (db) {
            return { width: db.clientWidth, height: db.clientHeight };
        }
        return { width: 0, height: 0 };
    }

    /**
     * get size with scroll
     */
    static getSizes(container: HTMLElement) {
        return {
            width: container.offsetWidth,
            height: container.offsetHeight,
        };
    }

    /**
     * get size without scroll
     */
    static getInnerSizes(container: Element) {
        return {
            width: container.clientWidth,
            height: container.clientHeight,
        };
    }

    static scroll(element: Element) {
        return {
            top: element.scrollTop || 0,
            left: element.scrollLeft || 0,
        };
    }

    static documentScroll() {
        return {
            top: window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0,
            left: window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0,
        };
    }
}
