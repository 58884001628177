import { action, makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionType {
  value: number;
  label: string;
  groupId: number;
}

interface OptionResponseType {
  label: string;
  data: number;
  group_id: number;
}

class DropdownPhysicianGroupAdmin {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,

      setFetching: action,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  getOptions = async () => {
    if (this.fetching) return Promise.resolve([]);

    this.setFetching(true);

    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'usermaster.ProviderInfo.loadPhysicianAdministrators',
      });

      const options = response.map(({ data, group_id, label }) => ({
        label,
        value: data,
        groupId: group_id,
      }));

      runInAction(() => {
        this.options = options;
        this.fetching = false;
      });
    } catch (error) {
      runInAction(() => {
        this.options = [];
        this.fetching = false;
      });
    }
  };
}

export const storeDropdownPhysicianGroupAdmin =
  new DropdownPhysicianGroupAdmin();
