import { memo } from 'react';

import { URL_NPPES_NPI } from 'constant/externalLinks';

export interface OrderDetailType {
  Studyid?: number;
  refid: number;
  facilityid: number;
  pos_override: string;
  last_name: string;
  first_name: string;
  dob: string;
  dos: string;
  modality: string;
  pos: string;
  facility_name: string;
  npi: string;
  facility_type: string;
}

interface PropsType {
  posName: string;
  order: OrderDetailType;
}

const ClaimBasicInfo = ({ order, posName }: PropsType) => (
  <div className="row">
    <div className="col-md-4">
      <div className="mb-4">
        <strong>Last Name</strong>
        <div>{order.last_name}</div>
      </div>

      <div className="mb-4">
        <strong>First Name</strong>
        <div>{order.first_name}</div>
      </div>
    </div>

    <div className="col-md-4">
      <div className="mb-4">
        <strong>DOB</strong>
        <div>{order.dob}</div>
      </div>

      <div className="mb-4">
        <strong>Modality</strong>
        <div>{order.modality}</div>
      </div>
    </div>

    <div className="col-md-4">
      <div className="mb-4">
        <strong>DOS</strong>
        <div>{order.dos}</div>
      </div>

      <div className="mb-4">
        <strong>POS code</strong>
        <div>{posName}</div>
      </div>
    </div>

    <hr />

    <div className="mb-4 col-md-4">
      <strong>Facility</strong>
      <div>{order.facility_name}</div>
    </div>

    <div className="mb-4 col-md-4">
      <strong>NPI</strong>
      <div className="d-flex gap-3">
        {order.npi}
        <a href={URL_NPPES_NPI} target="_blank" rel="noreferrer">
          NPI
        </a>
      </div>
    </div>

    <div className="mb-4 col-md-4">
      <strong>Provider Type</strong>
      <div>{order.facility_type || '-'}</div>
    </div>
  </div>
);

export default memo(ClaimBasicInfo);
