import UserTypeActions from 'actions/project/UserTypeActions';
import UserTypeStore from 'stores/project/UserTypeStore';

import AbstractDropdown from './AbstractDropdown';

export default class UserTypeDropdown extends AbstractDropdown {
  getStore() {
    return UserTypeStore;
  }

  loadOptions() {
    return UserTypeActions.loadUserTypeList(
      this.props.userType,
      this.props.flagCompanyUsersOnly
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userType !== this.props.userType) {
      return UserTypeActions.loadUserTypeList(
        prevProps.userType,
        prevProps.flagCompanyUsersOnly
      );
    }
  }

  getOptions() {
    return UserTypeStore.getState().userTypeList;
  }
}
