import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import LocationFilter from 'page/system-setup/components/LocationFilter';
import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';

import {
  storeTransport,
  FilterType,
} from 'stores/_mobx/systemSetup/masterSetting/transport';
import { MS_TRANSPORT as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_TRANSPORT_ADD,
  URL_TRANSPORT_EDIT,
} from 'constant/path/systemSetup';

const renderControl = (id: number) => (
  <div className="control">
    <Link
      className="bi bi-pencil"
      to={URL_TRANSPORT_EDIT.replace(':id', `${id}`)}
    />
    <IconButton onClick={() => storeTransport.setTransportCodeForDelete(id)}>
      <i className="bi bi-trash" />
    </IconButton>
  </div>
);

const columns = [
  { name: 'state', title: 'State' },
  { name: 'code', title: 'Code' },
  { name: 'no_of_patient', title: 'No. of patient' },
  { name: 'billJurisdiction', title: 'Billing jurisdiction' },
  { name: 'facility_price', title: 'Facility price' },
  { name: 'insurance_price', title: 'Insurance price' },
  { name: 'refid', title: 'Action', render: renderControl },
];

const TransportListPage = () => {
  const {
    fetching,
    filter,
    transportCodesList,
    transportCodesCount,
    transportCodeForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeTransport;

  const handleConfirmDelete = () => {
    storeTransport.deleteTransportCode().then((isSucceed) => {
      if (isSucceed) storeTransport.getTransportCodesMain();
    });
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeTransport.setFilter(filter);

    setPaginationToStart();
  };

  useEffect(() => {
    storeTransport.getTransportCodesMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Transportation">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <LocationFilter defaultValues={filter} fetchData={handleChangeFilter} />

      <Grid
        id="transport_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={transportCodesList}
        dataSourceCount={transportCodesCount}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_TRANSPORT_ADD} />
        }
      />
      {transportCodeForDelete !== null && (
        <DialogConfirm
          onCancel={storeTransport.clearTransportCodeForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(TransportListPage);
