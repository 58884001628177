import { Container } from 'flux/utils';

import AbstractAddEquipmentCheckOut from './AbstractAddEquipmentCheckOut';
import EquipmentPageActions from 'actions/workflow/equipment/EquipmentPageActions';
import UserProfileStore from 'stores/UserProfileStore';

export class AddEquipmentCheckOut extends AbstractAddEquipmentCheckOut {
  static getStores() {
    return AbstractAddEquipmentCheckOut.getStores();
  }
  static calculateState(prevState) {
    const out = AbstractAddEquipmentCheckOut.calculateState(prevState);
    if (!prevState) {
      if (UserProfileStore.getUserType() === 'T') {
        out.model.technologistid = UserProfileStore.getTypedUserId();
      }
    }
    return out;
  }

  title = 'Add equipment Check Out';

  componentWillUnmount() {
    EquipmentPageActions.clear();
  }

  postSetVehicleValue() {
    super.postSetVehicleValue().then(() => this.loadOdometerValue());
  }

  loadOdometerValue() {
    EquipmentPageActions.loadOdometerValue(this.state.model.vehicleid).then(
      (v) => this.onUpdateModel('starting_odometer', v, [])
    );
  }
}

export default Container.create(AddEquipmentCheckOut);
