import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import FilterNameAndState, {
  FilterType,
} from 'stores/_mobx/options/filterNameAndStatus';
import { dateToLocalTimezone } from 'utils/DateUtils';
import { BASE_URL_FILE_DIR } from 'constant/config';

//MM/DD/YYYY -> YYYY-DD-MM
const changeDateFormatBeforeSend = (date: string) => {
  if (date) {
    const splitDate = date.split('/');
    return `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
  }
  return null;
};

//YYYY-DD-MM -> MM/DD/YYYY
const changeDateFormatAfterReceive = (date: string) => {
  if (date) {
    const splitDate = date.split('-');
    return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
  }
  return '';
};

export interface XRayType {
  calibration_date: string;
  calibration_due_date: string;
  comments: string;
  description: string;
  device_name: string;
  enable: boolean;
  refid: number;
  start_date: string;
  unique_id: string;
}

interface FileResponseType {
  desc: string;
  filenm: string;
  filestatus: string;
  remvImg: string;
  uploaded_dt: string;
}

interface XRayEntryType extends Omit<XRayType, 'enable' | 'refid'> {
  image_path: string;
}

interface XRayResponseType {
  '0': XRayEntryType;
  file: 0 | FileResponseType[];
}

interface FileType {
  desc: string;
  filenm: string;
  filestatus: string;
}

interface FilesPreviewType {
  desc: string;
  fileName: string;
  path: string;
  uploadDate: string;
}

export interface XRayFormModel extends Omit<XRayType, 'enable'> {
  refid: number | null;
  documentList: FileType[];
}

export interface OptionType {
  label: string;
  value: number;
}

class XRayMaster {
  fetching: boolean = false;
  fetchingFileList: boolean = false;
  fetchingOptions: boolean = false;
  xRayList: XRayType[] = [];
  xRayTotal: number = 0;
  xRay: XRayFormModel | undefined = undefined;
  idForDelete: number | null = null;
  fileListPreview: FilesPreviewType[] | null = null;
  noteId: number = 0;
  options: OptionType[] = [];

  filter: FilterNameAndState = new FilterNameAndState();
  page: Pagination = new Pagination({ id: 'x_ray_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingFileList: observable,
      fetchingOptions: observable,
      xRayList: observable,
      xRayTotal: observable,
      xRay: observable,
      idForDelete: observable,
      fileListPreview: observable,
      noteId: observable,
      options: observable,

      setFetching: action,
      setFetchingFileList: action,
      setFetchingOptions: action,
      setXRayList: action,
      setXRayTotal: action,
      setXRay: action,
      setIdForDelete: action.bound,
      setFileListPreview: action,
      setNoteId: action,
      clearIdForDelete: action.bound,
      clearXRay: action.bound,
      clearFileListPreview: action.bound,
      clearNoteId: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFetchingFileList = (fetching: boolean) => {
    this.fetchingFileList = fetching;
  };

  setFetchingOptions = (fetching: boolean) => {
    this.fetchingOptions = fetching;
  };

  setXRayList(list: XRayType[]) {
    this.xRayList = list;
  }

  setXRayTotal(count: number) {
    this.xRayTotal = count;
  }

  setXRay(xRay?: XRayFormModel) {
    this.xRay = xRay;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setFileListPreview(fileList: FilesPreviewType[]) {
    this.fileListPreview = fileList;
  }

  setNoteId(id: number) {
    this.noteId = id;
  }

  clearIdForDelete() {
    this.idForDelete = null;
  }

  clearXRay() {
    this.xRay = undefined;
  }

  clearFileListPreview() {
    this.fileListPreview = null;
  }

  clearNoteId() {
    this.noteId = 0;
  }

  async getXRayList(filter: FilterType) {
    const { pagination } = this.page;

    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.name,
      filter.state,
    ];

    try {
      const response = await apiRequest<'SE' | XRayType[]>({
        url: 'inventory.xray.GetXrayDetails',
        data,
      });
      if (Array.isArray(response)) {
        const list = response.map((item) => ({
          ...item,
          refid: Number(item.refid),
        }));
        this.setXRayList(list);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setXRayList([]);
    }
  }

  async getXRayCount(filter: FilterType) {
    try {
      const count = await apiRequest<'SE' | 'S' | number>({
        url: 'inventory.xray.TotalRecordCount',
        data: [filter.name, filter.state],
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setXRayTotal(total);
    } catch (e) {
      this.setXRayTotal(0);
    }
  }

  getXRayMain = () => {
    this.setFetching(true);

    const filter = this.filter.options;

    const promiseList = this.getXRayList(filter);

    const promiseCount = this.getXRayCount(filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getXRay(id: number) {
    this.setFetching(true);

    try {
      const response = await apiRequest<[] | XRayResponseType>({
        url: 'inventory.xray.GetXrayDetailsById',
        data: [id],
      });
      if (Array.isArray(response)) {
        throw Error('');
      } else {
        const documentList = response.file
          ? response.file.map(({ filenm, desc, filestatus }) => ({
              filenm,
              desc,
              filestatus,
            }))
          : [];
        const start_date = changeDateFormatAfterReceive(response[0].start_date);

        const calibration_date = changeDateFormatAfterReceive(
          response[0].calibration_date
        );

        const calibration_due_date = changeDateFormatAfterReceive(
          response[0].calibration_due_date
        );

        const { image_path, ...payload } = response['0'];

        this.setXRay({
          ...payload,
          start_date,
          calibration_date,
          calibration_due_date,
          refid: id,
          documentList,
        });
      }
    } catch (e) {
      this.setXRay();
    } finally {
      this.setFetching(false);
    }
  }

  async addXRay({ documentList, ...payload }: XRayFormModel) {
    const start_date = changeDateFormatBeforeSend(payload.start_date);
    const calibration_date = changeDateFormatBeforeSend(
      payload.calibration_date
    );
    const calibration_due_date = changeDateFormatBeforeSend(
      payload.calibration_due_date
    );

    const data = [
      {
        ...payload,
        start_date,
        calibration_date,
        calibration_due_date,
      },
      [],
      documentList,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'inventory.xray.AddXrayDetails',
        data,
      });
      if (result === 'S') {
        Notification.success('X-Ray Frame was added successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'Serial number is not unique!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateXRay({ documentList, ...payload }: XRayFormModel) {
    const start_date = changeDateFormatBeforeSend(payload.start_date);
    const calibration_date = changeDateFormatBeforeSend(
      payload.calibration_date
    );
    const calibration_due_date = changeDateFormatBeforeSend(
      payload.calibration_due_date
    );

    const data = [
      payload.refid,
      { ...payload, start_date, calibration_date, calibration_due_date },
      documentList,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'inventory.xray.UpdateXrayDetails',
        data,
      });

      if (result === 'S') {
        Notification.success('X-Ray Frame was updated successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'This Serial number already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  changeXRayStatus = async (ids: number[]) => {
    this.setFetching(true);
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'inventory.xray.changeStatus',
        data: [ids],
      });
      if (response === 'S') {
        const { length } = ids;

        const statusMsg = this.filter.options.isActive
          ? 'deactivated'
          : 'activated';

        Notification.success(
          `${length} X-Ray Frame${length > 1 ? 's were' : 'was'} ${statusMsg}`
        );
        return true;
      } else Error('');
    } catch (e) {
      Notification.warning('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  };

  async deleteXRay() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'inventory.xray.DeleteXray',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('X-Ray Frame was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(
        "This X-Ray Frame is in use, so it can't be deleted!"
      );
      this.setFetching(false);
      return false;
    }
  }

  async getFileList(id: number) {
    runInAction(() => {
      this.fetchingFileList = true;
      this.fileListPreview = [];
    });
    try {
      const response = await apiRequest<[] | XRayResponseType>({
        url: 'inventory.xray.GetXrayDetailsById',
        data: [id],
      });
      if (Array.isArray(response)) {
        throw Error('');
      } else {
        const files = response.file
          ? response.file.map(({ filenm, desc, uploaded_dt }) => ({
              fileName: filenm || 'unknown',
              desc,
              uploadDate: dateToLocalTimezone({ date: uploaded_dt }),
              path: `${BASE_URL_FILE_DIR}doc_img/xray/${id}-${filenm}`,
            }))
          : [];

        this.setFileListPreview(files);
      }
    } catch (e: any) {
    } finally {
      this.setFetchingFileList(false);
    }
  }

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOptions(true);

    try {
      const options = await apiRequest<OptionType[]>({
        url: 'inventory.xray.GetXrayDeviceDropDown',
      });

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storeXRayMaster = new XRayMaster();
