import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import RadioOptions from 'components/project/common/RadioOptions';
import { Radio } from 'components/form/radio';
import MaskInput from 'components/form/maskInput';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { Input } from 'components/form/textField';

import {
  storeFacilityContact,
  FilterType,
} from 'stores/_mobx/systemSetup/facilityContact';

const cn = 'col-lg-3';

interface PropsType {
  onSearch: (payload: FilterType) => void;
}

const Filter = ({ onSearch }: PropsType) => {
  const { control, watch } = useForm<FilterType>({
    defaultValues: storeFacilityContact.filter,
  });

  useEffect(() => {
    const subscription = watch((formValue) => {
      onSearch(formValue as FilterType);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row">
      <Input
        name="firstname"
        label="First Name"
        className={cn}
        control={control}
      />
      <Input
        name="lastname"
        label="Last Name"
        className={cn}
        control={control}
      />
      <Input
        name="contactTitle"
        label="Role"
        className={cn}
        control={control}
      />
      <Radio
        className="col-lg-3 inline"
        name="isActive"
        label="Status"
        options={RadioOptions.active}
        control={control}
      />
      <div />
      <DropDownFacility
        name="facilityId"
        label="Facility"
        className={cn}
        control={control}
      />
      <MaskInput
        name="phone"
        label="Phone"
        format="###-###-####"
        className={cn}
        control={control}
      />
      <MaskInput
        name="fax"
        label="Fax"
        format="###-###-####"
        className={cn}
        control={control}
      />
    </form>
  );
};

export default Filter;
