import { apiRequest } from 'services/RequestService';
import { OptionType } from 'components/form/input/Select';
import { CompositeMobileOperator } from 'stores/profile/MyProfilePageTypes';

interface TMobileOperator {
  label: string;
  data: string;
  carrFormat: string;
}

export class DropDownOptionType {
  label: string;
  carrFormat: string;
  data: string;
  value: number;

  constructor(label: string, carrFormat: string, data: string, value: number) {
    this.label = label;
    this.carrFormat = carrFormat;
    this.data = data;
    this.value = value;
  }
}

class DropdownService {
  jurisdictionList: Array<OptionType> = [];
  leadList: Array<OptionType> = [];
  ekgActiveList: Array<OptionType> = [];
  mobileOperatorsList: Array<OptionType> = [];
  modifiersList: Array<OptionType> = [];
  callTypeList: Array<OptionType> = [];

  billProcessList: Array<OptionType> = [
    { value: 'N', label: '-- Select --' },
    { value: 'I', label: 'Insurance' },
    { value: 'H', label: 'Hospice' },
    { value: 'O', label: 'Others' },
  ];

  loadLeadList() {
    return this.loadDropdown(this.leadList, () =>
      this.sendDropdownRequest('vehicle.EquipmentMaster.LeadDropDown')
    );
  }

  loadMobileOperators() {
    const mobileOperatorsLoader = () =>
      apiRequest<Array<TMobileOperator>>({
        url: 'generalmaster.MobileOperatorMaster.MobileOperatorDropDown',
      }).then((response) => {
        return response.map((item: CompositeMobileOperator) => {
          return new DropDownOptionType(
            item.label,
            item.carrFormat,
            item.data,
            parseInt(item.data)
          ) as any as OptionType; // this is 'bad hack' to trick typechecker, TODO is below
        });
      });
    // TODO: OptionType & DropDownOptionType - complex review is necessary
    return this.loadDropdown(this.mobileOperatorsList, mobileOperatorsLoader);
  }

  loadModifierList() {
    return this.loadDropdown(this.modifiersList, () =>
      this.sendDropdownRequest('codemaster.Modifier.ModifierDropdown')
    );
  }

  loadActiveEkgNameDropDown() {
    return this.loadDropdown(this.ekgActiveList, () =>
      this.sendDropdownRequest('inventory.ekg.EkgNameDropDown', false, [
        true,
        false,
      ])
    );
  }

  loadDropdown(
    cachedList: Array<OptionType>,
    dropdownLoader: () => Promise<Array<OptionType>>
  ): Promise<Array<OptionType>> {
    if (!cachedList || cachedList.length === 0) {
      return dropdownLoader().then((response) => {
        this.updateCache(response, cachedList);
        return response;
      });
    } else {
      return new Promise((resolve) => {
        resolve(cachedList);
      });
    }
  }

  sendDropdownRequest = (
    url: string,
    alphaKeys?: boolean,
    data?: any
  ): Promise<Array<OptionType>> => {
    return apiRequest({ url, data }).then((response: any) => {
      if (!response || response === '0') {
        response = [];
      }
      for (let index = 0; index < response.length; index++) {
        const item = response[index];
        const label = item.label;
        const data = item.data;
        const isNotLabel =
          (label && label.trim().toLowerCase() === '-- select --') ||
          (label === '' && data === 0);
        if (isNotLabel) {
          response.splice(index, 1);
        } else {
          let value = data;
          const isAlpha = !alphaKeys ? false : alphaKeys;
          if (isAlpha) {
            item.value = value;
          } else {
            value = value ? parseInt(value) : 0;
            item.value = isNaN(value) ? 0 : value;
          }
        }
      }
      return response;
    });
  };

  updateCache = (source: Array<OptionType>, cache: Array<OptionType>) => {
    cache.length = 0;
    if (Array.isArray(source)) {
      for (let i = 0; i < source.length; i++) {
        cache.push(source[i]);
      }
    }
  };

  findOption = (id: number, list: Array<OptionType>) => {
    if (!id || id === 0 || !list || list.length === 0) {
      return null;
    }
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item.value === id + '') {
        return item;
      }
    }
    return null;
  };

  invalidate = (list: Array<OptionType>) => {
    const entries: Array<[string, any]> = Object.entries(dropdownService);
    for (let i = 0; i < entries.length; i++) {
      const dropdownList = entries[i][1];
      if (dropdownList === list) {
        list.length = 0;
        return;
      }
    }
    console.error('Unknown key for invalidation dropdown list');
  };
}

const dropdownService = new DropdownService();
export default dropdownService;
