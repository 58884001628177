import React from 'react';

import { isDevMode, isProdMode } from 'constant/config';
import ChildModifier, {
  PChildModifier,
  SChildModifier,
} from 'components/form/ChildModifier';
import Notification from 'components/modal/Notification';
import InputState from 'components/form/InputState';

interface PForm extends PChildModifier {
  submit?: (
    model: any,
    onError?: boolean,
    errors?: any,
    event?: React.FormEvent
  ) => void;
  model?: any;
  onCollectValues?: (
    name: string,
    value: string,
    errorMessages: any,
    errorKeys: any,
    event: Event,
    callback?: () => void
  ) => void;

  [key: string]: any;
}

interface NewPForm {
  formId: string;
  reactForm?: React.Component;
  formModel?: any;
  formErrors?: any;
  formErrorMessages?: any;
  key?: string;
  ref?: any;
  onSetValue?: (
    name: string,
    value: string,
    errorMessages: any,
    errorKeys: any,
    event: Event,
    callback?: () => void
  ) => void;
  value?: string;
  errors?: any;
  errorMessages?: string[];
}

export default class Form extends ChildModifier<PForm, SChildModifier> {
  shouldComponentUpdate(nextProps: PForm) {
    for (let key in this.props) {
      if (this.props.hasOwnProperty(key)) {
        if (
          typeof nextProps[key] !== 'function' &&
          nextProps[key] !== this.props[key]
        ) {
          return true;
        }
      }
    }
    return false;
  }

  submit(event?: React.FormEvent) {
    if (typeof this.props.submit === 'function') {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      const errors = {};
      let onError: boolean = false;
      const model: { [key: string]: any } = {};

      if (this.refs) {
        for (let r in this.refs) {
          const child: any = this.refs[r];
          if (child.getInputState) {
            if (
              Form.onSubmitField(
                child.getInputState(child.getValue()),
                child.props.name,
                model,
                errors
              ) &&
              !onError
            ) {
              onError = true;
            }
          }
          if (child.getAggregateState) {
            const aggregate = child.getAggregateState();
            if (aggregate.__isFieldset) {
              const name = aggregate.name;
              if (!name) {
                if (
                  Form.onSubmitObject(errors, model, aggregate.values, onError)
                ) {
                  onError = true;
                }
              } else {
                if (Form.onSubmitFieldset(errors, model, name, aggregate)) {
                  onError = true;
                }
              }
            } else {
              for (let aKey in aggregate) {
                if (
                  Form.onSubmitField(aggregate[aKey], aKey, model, errors) &&
                  !onError
                ) {
                  onError = true;
                }
              }
            }
          }
        }
      }
      if (onError) {
        Notification.danger('Validation failed. The form contains errors.');
      }
      this.props.submit(model, onError, onError ? errors : null, event);
    } else if (isDevMode) {
      Notification.danger(
        'Submit function not specified. This alert will be omitted on production environment.'
      );
    } else if (isProdMode) {
      if (event) {
        event.preventDefault();
      }
    }
  }

  static onSubmitFieldset(
    errors: any,
    model: any,
    name: any,
    aggregate: any,
    onError?: any
  ) {
    if (!errors[name]) {
      errors[name] = {};
    }
    if (!model[name]) {
      model[name] = {};
    }
    for (let aKey in aggregate.values) {
      if (!aggregate.values.hasOwnProperty(aKey)) {
        continue;
      }
      if (
        Form.onSubmitField(
          aggregate.values[aKey],
          aKey,
          model[name],
          errors[name]
        ) &&
        !onError
      ) {
        return true;
      }
    }
    return false;
  }

  static onSubmitObject(
    errors: any,
    model: any,
    values: any,
    onError: (() => void) | boolean
  ) {
    for (let name in values) {
      if (values.hasOwnProperty(name)) {
        if (!errors[name]) {
          errors[name] = {};
        }
        if (!model[name]) {
          model[name] = {};
        }
        const obj = values[name];
        let onObjError;
        if (obj instanceof InputState) {
          onObjError = Form.onSubmitField(obj, name, model, errors);
        } else {
          onObjError = Form.onSubmitObject(
            errors[name],
            model[name],
            obj,
            onError
          );
        }
        if (!onError && onObjError) {
          onError = onObjError;
        }
      }
    }
    return onError;
  }

  static onSubmitField(
    field: InputState,
    key: string,
    model: { [key: string]: any },
    errors: any
  ): boolean {
    if (!field) {
      return false;
    }
    const { value, errorMessages, errorKeys } = field;
    model[key] = value;
    if (errorKeys && errorKeys.length) {
      errors[key] = errorMessages;
      return true;
    }
    return false;
  }

  render() {
    const { props } = this;
    return (
      <form
        onSubmit={this.submit.bind(this)}
        className={`row ${props.className || ''}`}
        id={props.id}>
        {this.renderChildren()}
      </form>
    );
  }

  cloneChild(child: any, key: string) {
    if (!this.isFormChild(child)) {
      return child;
    }
    const { props } = this;
    const newProps: NewPForm = {
      formId: props.id,
    };
    if (!child.props.form) {
      newProps.reactForm = this;
    }
    if (props.model && !child.props.formModel) {
      newProps.formModel = props.model;
    }
    if (props.errors && !child.props.formErrors) {
      newProps.formErrors = props.errors;
    }
    if (props.errorMessages && !child.props.formErrorMessages) {
      newProps.formErrorMessages = props.errorMessages;
    }
    newProps.key = key;
    newProps.ref = key;
    if (child.props.onSetValue === undefined) {
      newProps.onSetValue = this.collectValues.bind(this);
    }
    if (child.props.value === undefined && props.model) {
      newProps.value = props.model[child.props.name];
    }
    if (child.props.errors === undefined && props.errors) {
      newProps.errors = props.errors[child.props.name];
    }
    if (child.props.errorMessages === undefined && props.errorMessages) {
      newProps.errorMessages = [props.errorMessages[child.props.name]];
    }
    return React.cloneElement(child, newProps);
  }

  collectValues(
    name: string,
    value: string,
    errorMessages: any,
    errorKeys: any,
    event: Event,
    callback?: () => void
  ) {
    const { props } = this;
    if (typeof props.onCollectValues === 'function') {
      props.onCollectValues(
        name,
        value,
        errorMessages,
        errorKeys,
        event,
        callback
      );
    } else {
      throw new TypeError(
        "Form must contain 'onCollectValues' props. Please, specify this function."
      );
    }
  }
}
