import { toast, ToastOptions } from 'react-toastify';

export const success = (text: React.ReactNode, options?: ToastOptions) => {
  toast.success(text, options);
};

export const danger = (text: React.ReactNode, options?: ToastOptions) => {
  toast.error(text, options);
};

export const info = (text: React.ReactNode, options?: ToastOptions) => {
  toast.info(text, options);
};

export const warning = (text: React.ReactNode, options?: ToastOptions) => {
  toast.warn(text, options);
};

const Notification = {
  success,
  danger,
  info,
  warning,
};

export default Notification;
