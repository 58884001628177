import {
  makeObservable,
  observable,
  remove,
  set,
  computed,
  entries,
  get,
  action,
  runInAction,
} from 'mobx';
import { ColDef, ColGroupDef } from 'ag-grid-community';

import Notification from 'components/modal/Notification';

import { URLSearchParamsSpecific, apiRequest } from 'services/RequestService';
import { downloadCsv } from 'utils/downloadFile';
import { dateToLocalTimezone } from 'utils/DateUtils';

const RETRIEVE_REPORT_STATUS_TIMEOUT = 30000;

const messengerBaseUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_SSE_URL
    : '/mercure';

export enum GenerateStatus {
  loading = 0, // Report generate status is "in progress"
  success = 1, // Report generate status is "generated"
  failed = 2 // Report generate status is "failed"
}

const defaultSubscription: SubscriptionType = {
  fileExtension: '',
  instance: '',
  id: 0,
  generateStatus: GenerateStatus.loading,
  isConfirmationOpen: true,
  source: '',
  createdAt: '',
  generatedAt: '',
};

export const createFullFilename = ({
  source,
  createdAt,
  fileExtension,
}: Pick<BasicReportType, 'source' | 'createdAt' | 'fileExtension'>) => {
  const date = createdAt.replace(/[/:\s]+/g, '');

  return `${source}_${date}.${fileExtension}`;
};

const getCorrespondenceOfColumnsToData = (
  columns: (ColDef<any> | ColGroupDef<any>)[]
) => {
  const formattedColumns = columns.reduce((prev, column) => {
    if ('children' in column) {
      const children = column.children.map((col) => ({
        ...col,
        headerName: `${column.headerName} ${col.headerName}`,
      }));

      return prev.concat(children);
    }
    return prev.concat(column);
  }, []);

  return formattedColumns.reduce(
    (prev, column) => ({
      ...prev,
      [column.headerName]: column.field,
    }),
    {}
  );
};

const getReportDetails = async (id: number) => {
  try {
    const response = await apiRequest<RecordType>({
      url: `reports/${id}`,
      method: 'GET',
      legacy: false,
    });
    return response;
  } catch (e: unknown) {
    return null;
  }
};

const download = async (record: DownloadPropsType) => {
  const response = await apiRequest<string>({
    url: `reports/${record.id}/download`,
    method: 'GET',
    legacy: false,
  });

  const fileName = createFullFilename(record);

  downloadCsv(response, fileName);

  return fileName;
};

type ExportFormatType = 'pdf' | 'csv' | '';

interface ExporterPayloadType {
  fileExtension: ExportFormatType;
  instance: string;
  source: string;
  filter: Record<string, any>;
  columns?: (ColDef<any> | ColGroupDef<any>)[];
}

interface BasicReportType {
  createdAt: string;
  fileExtension: ExportFormatType;
  generatedAt: string;
  id: number;
  source: string;
}

type DownloadPropsType = Pick<
  BasicReportType,
  'source' | 'createdAt' | 'fileExtension' | 'id'
>;

export interface RecordType extends BasicReportType {
  requested: number;
  createdBy: string;
  generateStatus: GenerateStatus
}

export interface SubscriptionType extends BasicReportType {
  instance: string;
  generateStatus: GenerateStatus
  isConfirmationOpen: boolean;
}

/**
 * This is common class for ExportInterface and Exporter store. It contain common methods/logic for subscribe and unsubscribe to SSE
 */
abstract class SseService {
  private eventCollection: Map<string, EventSource> = new Map();
  private tempTimers: Set<NodeJS.Timeout> = new Set();

  private openConnection(connectionId: string, event: EventSource) {
    this.eventCollection.set(connectionId, event);
  }

  private closeConnection(connectionId: string) {
    const event = this.eventCollection.get(connectionId);

    event?.close();

    this.eventCollection.delete(connectionId);
  }

  public unsubscribeAll = () => {
    this.eventCollection.forEach((event) => {
      event.close();
    });
    this.tempTimers.forEach((timer) => {
      clearTimeout(timer);
    });
    this.tempTimers.clear();
  };

  abstract eventMessageHandler<T extends BasicReportType>(
    subscription: T
  ): void;

  async subscribeToFileGeneratingEvent<T extends BasicReportType>(
    subscription: T,
    timer?: NodeJS.Timeout
  ) {
    this.tempTimers.delete(timer);

    const params = new URLSearchParams({
      topic: `/reports/${subscription.id}`,
    });

    const url = `${messengerBaseUrl}/.well-known/mercure?${params}`;

    const eventSource = new EventSource(url, {
      withCredentials: true,
    });

    const connectionId = performance.now().toString(32);

    this.openConnection(connectionId, eventSource);

    eventSource.onmessage = ({ data }: { data: string }) => {
      try {
        const record: RecordType = JSON.parse(data);
        this.eventMessageHandler({
          ...subscription,
          source: record.source,
          generateStatus: record.generateStatus,
        });
      } catch (e: unknown) {
        this.eventMessageHandler(subscription);
      } finally {
        this.closeConnection(connectionId);
      }
    };

    eventSource.onerror = () => {
      this.closeConnection(connectionId);
      this.eventErrorHandler(subscription);
    };

    eventSource.onopen = async () => {
      const report = await getReportDetails(subscription.id);

      if (report?.generateStatus !== GenerateStatus.loading) {
        this.eventMessageHandler({
          ...subscription,
          source: report.source,
          generateStatus: report.generateStatus,
        });
        this.closeConnection(connectionId);
      }
    };
  }

  async eventErrorHandler<T extends BasicReportType>(subscription: T) {
    const record = await getReportDetails(subscription.id);

    if (record?.generateStatus !== GenerateStatus.loading) {
      this.eventMessageHandler({
        ...subscription,
        generateStatus: record.generateStatus,
      });
    } else {
      const timeout: NodeJS.Timeout = setTimeout(
        () => this.subscribeToFileGeneratingEvent(subscription, timeout),
        RETRIEVE_REPORT_STATUS_TIMEOUT
      );

      this.tempTimers.add(timeout);
    }
  }
}

/**
 * Instance of this class is using as the main interface to subscribe new features of (NEW) export into the proper store.
 * In order to connect new Export feature to the specific store just inject it in that store.
 */
export class ExporterInterface extends SseService {
  subscriptions: Record<string, SubscriptionType> = {};

  constructor() {
    super();
    makeObservable(this, {
      subscriptions: observable,

      openedSubscription: computed,

      updateSubscription: action,
      removeSubscription: action,
      closeDialogInfo: action,
    });
  }

  get openedSubscription(): SubscriptionType | null {
    const subscriptions = entries(this.subscriptions);

    const newSubscription = subscriptions.find(
      ([_, props]) => props.isConfirmationOpen
    );

    return newSubscription ? get(this.subscriptions, newSubscription[0]) : null;
  }

  updateSubscription(subscription: SubscriptionType) {
    const key = subscription.instance;

    set(this.subscriptions, key, subscription);
  }

  removeSubscription(instance: SubscriptionType['instance']) {
    remove(this.subscriptions, instance);
  }

  closeDialogInfo(instance: SubscriptionType['instance']) {
    set(this.subscriptions[instance], 'isConfirmationOpen', false);

    if (get(this.subscriptions[instance], 'generateStatus')) {
      this.removeSubscription(instance);
    }
  }

  checkSubscriptionStatus(instance: string) {
    const subscription = get(this.subscriptions, instance);

    if (subscription) {
      Notification.warning(
        subscription.generateStatus === GenerateStatus.success
          ? 'This report has been generated! Check the Exports list.'
          : 'This report generation request is being processed, please wait for completion!'
      );
    }

    return Boolean(subscription);
  }

  async generateReport(payload: ExporterPayloadType) {
    const instance = await this.runExportGeneration(payload);

    if (instance) {
      const subscription = get(this.subscriptions, instance);
      this.subscribeToFileGeneratingEvent(subscription);
    }
  }

  async runExportGeneration({ filter, columns, ...rest }: ExporterPayloadType) {
    const searchParams = new URLSearchParamsSpecific(filter).toString();

    const instance = `${rest.instance}/${searchParams}`;

    const isReportInProgress = this.checkSubscriptionStatus(instance);

    if (!columns || isReportInProgress) return '';

    this.updateSubscription({
      ...defaultSubscription,
      ...rest,
      instance,
    });

    const data = {
      iri: instance,
      fileExtension: rest.fileExtension,
      mapping: getCorrespondenceOfColumnsToData(columns),
    };

    try {
      const response = await apiRequest<BasicReportType>({
        url: 'reports',
        method: 'POST',
        legacy: false,
        data,
      });

      runInAction(() => {
        set(this.subscriptions[instance], 'source', response.source);
        set(
          this.subscriptions[instance],
          'createdAt',
          dateToLocalTimezone({ date: response.createdAt })
        );
        set(this.subscriptions[instance], 'generatedAt', response.generatedAt);
        set(this.subscriptions[instance], 'id', response.id);
      });

      return instance;
    } catch (e: unknown) {
      this.removeSubscription(instance);
      Notification.danger('An error occurred! Try again later.');
      return '';
    }
  }

  // @ts-ignore
  eventMessageHandler({ instance, id, generateStatus }: SubscriptionType) {
    runInAction(() => {
      set(this.subscriptions[instance], 'generateStatus', generateStatus);
    });

    const subscription = get(this.subscriptions, instance);

    storeExporter.updateRecordStatus(id, 'generateStatus', subscription.generateStatus);

    if (!subscription) return;

    const fileName = createFullFilename(subscription);

    if (!subscription.isConfirmationOpen) {

      if (subscription.generateStatus === GenerateStatus.success) {
        Notification.success(
          `${fileName} is ready to download! Check the Exports list.`
        );
      }

      if (subscription.generateStatus === GenerateStatus.failed) {
        Notification.danger(
          `An error occurred during the ${fileName} generation! Check the Exports list.`
        );
      }

      this.removeSubscription(subscription.instance);
    }
  }

  async downloadFile(options: SubscriptionType) {
    try {
      await download(options);

      this.removeSubscription(options.instance);
      storeExporter.updateRecordStatus(options.id, 'requested', 1);
    } catch (e: unknown) {
      Notification.danger(
        'An error occurred during the file download! Try again later.'
      );
    }
  }
}

/**
 * Instance of this class is using for displaying the list of requested reports in the Download manager
 * and for the subscription to the reports which still in progress
 */
class Exporter extends SseService {
  fetching: boolean = false;
  records: RecordType[] = [];

  constructor() {
    super();
    makeObservable(this, {
      records: observable,
      fetching: observable,

      newRecordsCount: computed,

      setRecords: action,
      setFetching: action,
      insertRecord: action,
      removeRecord: action,
      updateRecordStatus: action,
    });
  }

  get newRecordsCount() {
    const count = this.records.reduce(
      (count, record) => (record.generateStatus === GenerateStatus.success && record.requested === 0 ? ++count : count),
      0
    );
    return count;
  }

  setRecords(records: RecordType[]) {
    this.records = records;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  updateRecordStatus(id: number, field: keyof RecordType, value: any) {
    const idx = this.records.findIndex((record) => id === record.id);

    if (~idx) set(this.records[idx], field, value);
  }

  insertRecord({ instance, isConfirmationOpen, ...rest }: SubscriptionType) {
    const record = {
      requested: 0,
      createdBy: '',
      ...rest,
    };

    const idx = this.records.findIndex(({ id }) => record.id === id);

    if (~idx) {
      set(this.records, idx, record);
    } else {
      const records = [record, ...this.records];

      records.length = records.length > 20 ? 20 : records.length;

      this.records = records;
    }
  }

  removeRecord(id: number) {
    const idx = this.records.findIndex((record) => record.id === id);
    if (~idx) remove(this.records, String(idx));
  }

  async getReports() {
    this.setFetching(true);
    try {
      const response = await apiRequest<RecordType[]>({
        url: 'reports',
        method: 'GET',
        legacy: false,
        data: { page: 1, itemsPerPage: 20 },
      });

      const records = response.map((record) => ({
        ...record,
        createdAt: dateToLocalTimezone({ date: record.createdAt }),
      }));

      records.forEach((record) => {
        if (record.generateStatus === GenerateStatus.loading) this.subscribeToFileGeneratingEvent(record);
      });

      this.setRecords(records);
    } catch (error) {
      this.setRecords([]);
    } finally {
      this.setFetching(false);
    }
  }

  // @ts-ignore
  eventMessageHandler({ source, id, generateStatus }: RecordType) {
    const idx = this.records.findIndex((record) => record.id === id);

    const record = this.records[idx];

    if (!record) return;

    runInAction(() => {
      set(this.records[idx], 'generateStatus', generateStatus);
      set(this.records[idx], 'source', source);
    });

    const fileName = createFullFilename({ ...record, source });

    if (generateStatus === GenerateStatus.failed) {
      Notification.danger(
        `An error occurred during the ${fileName} generation! Check the Exports list.`
      );
    } else if (generateStatus === GenerateStatus.success) {
      Notification.success(
        `${fileName} is ready to download! Check the Exports list.`
      );
    }
  }

  async downloadFile(record: RecordType) {
    try {
      await download(record);

      runInAction(() => {
        set(record, 'requested', ++record.requested);
      });
    } catch (e: unknown) {
      Notification.danger(
        'An error occurred during the file download! Try again later.'
      );
    }
  }

  deleteFile = async (id: number) => {
    if (this.fetching) return Promise.resolve(false);

    this.setFetching(true);

    try {
      await apiRequest<string>({
        url: `reports/${id}`,
        method: 'DELETE',
        legacy: false,
      });
      this.removeRecord(id);
    } catch (e: unknown) {
      Notification.danger("An error occurred! Can't delete this file.");
    } finally {
      this.setFetching(false);
    }
  };
}

export const exporterInterface = new ExporterInterface();

export const storeExporter = new Exporter();
