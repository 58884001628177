import appDispatcher from 'dispatcher/AppDispatcher';

import StudiesByFacilityPageService from 'services/reports/report-creator/studies-by-facility/StudiesByFacilityPageService';
import { Actions } from 'stores/reports/report-creator/studies-by-facility/StudiesByFacilityPageStore';

export default class StudiesByFacilityPageActions {
  static loadStudiesByFacilityList(conditionList, joint) {
    return StudiesByFacilityPageService.loadStudiesByFacilityList(
      conditionList,
      joint
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_STUDIES_BY_FACILITY_LIST,
        data: response,
      });
    });
  }

  static createPdf(studiesByFacilityList, filterString) {
    return StudiesByFacilityPageService.createPdf(
      studiesByFacilityList,
      filterString
    );
  }
}
