import React from 'react';

export interface PLi {
  label?: string;
}

export class SLi {}

export default class Li extends React.Component<PLi, SLi> {
  render() {
    const { children, label } = this.props;
    return (
      <li className="col-sm-6 li">
        <span className="li-label">{label} :</span> {children}
      </li>
    );
  }
}
