import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = (destinationName: string) =>
  yupResolver(
    yup.object().shape({
      destinationId: yup
        .number()
        .typeError(`Please, select a ${destinationName}!`)
        .min(1, `Please, select a ${destinationName}!`),
      from: yup.string().typeError('Please, select a date!'),
      to: yup.string().typeError('Please, select a date!'),
      file: yup.mixed().test({
        test: (file: File | '') => Boolean(file),
        message: 'Please, select the file!',
      }),
    })
  );

export default validation;
