import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Container } from 'flux/utils';

import Tabs from 'components/tabs';
import { LayoutSideTitle } from 'components/layout';
import Notification from 'components/modal/Notification';
import DialogConfirm from 'components/modal/dialogConfirm';
import { Grid, TPagination } from 'components/grid';
import { Button, IconButton } from 'components/button';
import { ExamExportLink } from 'page/components/ExportLink';
import EligibilityPopup from 'page/workflow/order/eligibility/EligibilityPopup';
import VisitInformationPage from 'page/workflow/order/VisitInformationPage';
import DialogAuditLog from 'page/workflow/order/components/DialogAuditLog';
import EncounterMessageDialog from 'page/components/messages/EncounterMessageDialog';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
  PropsTypeWithEligibilityManagerSettings,
} from 'components/HOC';
import withEligibilitySettings from 'components/HOC/WithEligibilityManagerExportSettings';
import Tooltip from 'components/tooltip';
import Filter, { FilterType, filterDefaultValues } from './components/Filter';

import EligibilityManagerPageActions from 'actions/billing-coding/EligibilityManagerPageActions';
import EligibilityManagerPageService from 'services/billing-coding/EligibilityManagerPageService';
import OrderOverviewStore from 'stores/workflow/order/OrderOverviewStore';
import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';
import { TOrdersFilter } from 'services/billing-coding/EligibilityManagerPageService';
import EligibilityManagerPageStore from 'stores/billing-coding/EligibilityManagerPageStore';
import { storeGrid } from 'stores/_mobx/grid';
import { getDateRangeBounds, sortByDate } from 'utils/DateUtils';
import { getPagination } from 'utils/getPagination';
import { downloadFile } from 'utils/downloadFile';
import { BASE_URL_FILE_DIR } from 'constant/config';
import { ELIGIBILITY as PAGE_ID } from 'constant/pagesId/billingCodding';

import DialogEligibilityExport, { ExportFormatOptionType } from './components/DialogEligibilityExport';
import Info from './components/Info';

const GRID_ID = 'eligibility_manager_grid';

const tabsList = [
  { url: '?tab=W', label: 'Waiting' },
  { url: '?tab=P', label: 'Processing' },
  { url: '?tab=I', label: 'Incomplete' },
  { url: '?tab=C', label: 'Completed' },
];

export interface PropsType
  extends RouteComponentProps,
    PropsTypeWitDivisionSettings,
    PropsTypeWithEligibilityManagerSettings {}

export class StateType {
  currentTab: number = 0;
  dataSource: any[] = [];
  dataSourceCount: number = 0;
  filter: FilterType = filterDefaultValues;
  pagination: TPagination = getPagination();
  selected: number[] = [];
  onAjax: boolean = false;
  showFilter: boolean = false;
  showEligibilityPopup: boolean = false;
  showRevert: boolean = false;
  showVisitInformation: boolean = false;
  visitInfoDefaultTab: number = 0;
  isAuditLogOpen: boolean = false;
  orderMessages: boolean = false;
  selectedOrder: null | TSignOrderItem = null;
  showEligibilityExportPopup: OptionsType | null;
  eligibilityExportPopupTitle: string = 'Export';
  isExportOnly: boolean = false;
}

interface OptionsType {
  exportFormat: string;
  volume: string;
}

export class EligibilityManagerPage extends React.Component<
  PropsType,
  StateType
> {
  abortController: AbortController | null = null;

  isColumnsInit: boolean = false;

  static getStores() {
    return [OrderOverviewStore, EligibilityManagerPageStore];
  }

  static getDefaultState(gridId: string): StateType {
    const res = new StateType();
    res.pagination.pageSize = storeGrid.getPageSize(gridId);
    res.filter = filterDefaultValues;
    return res;
  }

  static calculateState(prevState: StateType) {
    if (!prevState) {
      prevState = Object.assign(
        EligibilityManagerPage.getDefaultState(GRID_ID)
      );

      const { search } = window.location;

      // @ts-ignore
      const tabKeyCode: 'I' | 'C' | 'W' | 'P' | '' =
        search.replace('?tab=', '') || 'W';

      const tabIndex = tabsList.findIndex(({ url }) => url === search);

      const currentTab = ~tabIndex ? tabIndex : 0;

      prevState.filter.eligibility = tabKeyCode;
      prevState.currentTab = currentTab;
    }
    return {
      ...prevState,
      dataSource: EligibilityManagerPageStore.getState().dataSource,
      dataSourceCount: EligibilityManagerPageStore.getState().dataSourceCount,
    };
  }

  getColumns = () => {
    const { isDivisionEnabled } = this.props;

    const columns = [
      {
        name: 'eligible_date',
        title: 'Eligibility',
        sorter: sortByDate,
      },
      { name: 'eligibility_status', title: 'Eligibility Status' },
      {
        name: 'updateInsurance',
        title: 'Update Insurance',
        render: (v: any, data: TSignOrderItem) => (
          <IconButton
            onClick={() => {
              this.setState({
                showEligibilityPopup: true,
                selectedOrder: data,
              });
            }}>
            <i className="icon icon-update-insurance" />
          </IconButton>
        ),
      },
      { name: 'last_name', title: 'Last Name' },
      { name: 'first_name', title: 'First Name' },
      {
        name: 'dos',
        title: 'DOS',
        className: 'text-nowrap',
        sorter: sortByDate,
      },
      isDivisionEnabled ? { name: 'division', title: 'Division' } : null,
      { name: 'uniqueid', title: 'Unique ID' },
      { name: 'state', title: 'State' },
      {
        name: 'facility',
        title: 'Facility',
        render: (phy: any, data: TSignOrderItem) => (
          <Tooltip body={this.facilityToolTip(data)}>{data.facilitynm}</Tooltip>
        ),
        sorter: (v1: any, v2: any, direction: any, i: any, v: any) => {
          const candidate1 = i.facilitynm;
          const candidate2 = v.facilitynm;
          if (candidate1 < candidate2) {
            return -1 * direction;
          } else if (candidate1 > candidate2) {
            return 1 * direction;
          }
          return 0;
        },
      },
      {
        name: 'revert',
        title: 'Revert',
        render: (v: any, selectedOrder: TSignOrderItem) => (
          <div className="text-primary">
            <IconButton
              onClick={() => {
                this.setState({ showRevert: true, selectedOrder });
              }}>
              <i className="bi bi-arrow-counterclockwise" />
            </IconButton>
          </div>
        ),
      },
      {
        name: 'modality',
        title: 'Modality',
        render: (modality: any, data: TSignOrderItem) => data.examtype,
        sorter: (v1: any, v2: any, direction: any, i: any, v: any) => {
          const candidate1 = i.examtype;
          const candidate2 = v.examtype;
          if (candidate1 < candidate2) {
            return -1 * direction;
          } else if (candidate1 > candidate2) {
            return 1 * direction;
          }
          return 0;
        },
      },

      {
        name: 'visitInfo',
        title: 'Visit Info',
        render: (r: any, selectedOrder: TSignOrderItem) => (
          <IconButton
            className="text-primary"
            onClick={() => this.handleDisplayVisitInfo(selectedOrder)}>
            <i className="bi bi-journal-medical" />
          </IconButton>
        ),
      },
      {
        name: 'ins_last_verified',
        title: 'Last Verified Date',
        className: 'width-100',
        sorter: sortByDate,
      },
      {
        name: 'exam_request',
        title: 'Exam Request',
        className: 'text-center',
        render: (r: any, data: TSignOrderItem) => (
          <ExamExportLink key={data.refid} orderId={data.refid} />
        ),
      },
      {
        title: 'Accession #',
        name: 'accessionNumbersStr',
        render: (r: string) => this.codeRender(r),
      },
      {
        name: 'documents',
        title: 'Documents',
        render: (value: undefined, data: TSignOrderItem) => {
          const documentIsEnabled = data.enableEx || Boolean(data.face_sheet);

          const cn = `available ${documentIsEnabled ? '' : ' negative'}`;

          return (
            <div className={cn}>
              <IconButton
                onClick={() => {
                  this.setState({
                    showVisitInformation: true,
                    selectedOrder: data,
                    visitInfoDefaultTab: 4,
                  });
                }}
                disabled={!documentIsEnabled}>
                <i
                  className={
                    documentIsEnabled ? 'bi bi-check-circle' : 'bi bi-x-circle'
                  }
                />
              </IconButton>
            </div>
          );
        },
      },
      { name: 'patientdob', title: 'DOB', className: 'text-nowrap' },
      { name: 'location', title: 'Location' },
      { name: 'ssn', title: 'SSN', className: 'width-150' },
      {
        name: 'technologistnm',
        title: 'Technologist',
        render: (tech: string, data: TSignOrderItem) =>
          data.techtooltip ? (
            <Tooltip body={this.technologistTooltip(data.techtooltip)}>
              {tech}
            </Tooltip>
          ) : (
            'Not assigned'
          ),
      },
      {
        name: 'auditLog',
        title: 'Auditlog',
        render: (r: any, data: TSignOrderItem) => (
          <IconButton
            onClick={() => {
              this.showAuditLog(data);
            }}>
            <i className="icon icon-auditlog" />
          </IconButton>
        ),
      },
      { name: 'pos', title: 'POS', className: 'width-100' },
      {
        name: 'encounterLog',
        title: 'Encounter Log',
        render: (v: any, data: TSignOrderItem) => (
          <IconButton
            className="text-primary"
            onClick={() => {
              this.setState({ selectedOrder: data, orderMessages: true });
            }}>
            <i
              className={`bi ${
                data.hasLog ? 'bi-envelope-check-fill' : 'bi-envelope'
              }`}
            />
          </IconButton>
        ),
      },
    ];

    return columns.filter(Boolean);
  };

  getTabKeyCode = () => {
    const { search } = this.props.location;
    const tabKeyCode = (search || tabsList[0].url).replace('?tab=', '');
    return tabKeyCode;
  };

  handleDisplayVisitInfo = (selectedOrder: TSignOrderItem) => {
    this.setState({
      showVisitInformation: true,
      selectedOrder,
    });
  };

  getExtraActions = () => {
    const {
      filter: { eligibility },
      dataSourceCount,
    } = this.state;
    const { selected } = this.state;
    const isBtnDisabled = !selected.length;
    const { isExperianEnabled, isAvailityEnabled } = this.props;

    return (
      <>
        {eligibility === 'W' ? (
          <Button
            text="Move to Processing"
            disabled={!dataSourceCount}
            onClick={() => this.onOpenMoveClaimPopup()}
          />
        ) : null}
        {eligibility === 'P' || eligibility === 'C' ? (
          <Button
            text="Move to Incomplete"
            disabled={isBtnDisabled}
            onClick={() => this.onUpdateExportedStatus('I')}
          />
        ) : null}
        {eligibility === 'I' ? (
          <Button
            text="Move to Complete"
            disabled={isBtnDisabled}
            onClick={() => this.onUpdateExportedStatus('C')}
          />
        ) : null}

        {(isExperianEnabled || isAvailityEnabled) ? (
          <Button
            text="Export"
            disabled={!dataSourceCount}
            onClick={() => this.onOpenMoveClaimPopup(true)}
          />
        ) : null}

        <Tooltip className="fs-5" title="Export Formats Settings" theme="warning" body={<Info />}>
          <i className="text-warning bi bi-info-circle" />
        </Tooltip>
      </>
    );
  };

  onOpenMoveClaimPopup(isExportBtnClicked: boolean = false) {
    const { isExperianEnabled, isAvailityEnabled } = this.props;

    const defaultValues: OptionsType = {
      exportFormat: isAvailityEnabled ? 'availity' : isExperianEnabled ? 'experian' : '',
      volume: 'all'
    };

    this.setState({ showEligibilityExportPopup: defaultValues });

    const tabKeyCode: string = this.getTabKeyCode();

    if (isExportBtnClicked) {
      this.setState({
        isExportOnly: true,
        eligibilityExportPopupTitle: 'Export'
      });
    } else {
      const popupTitle = tabKeyCode === 'W' ? 'Move to Processing'
        : (tabKeyCode === 'P' || tabKeyCode === 'C' ) ? 'Move to Incomplete'
          : tabKeyCode === 'I' ? 'Move to Complete' : '';

      this.setState({ eligibilityExportPopupTitle: popupTitle });
    }
  };

  onExport(options: OptionsType) {
    const {
      selected,
      dataSourceCount,
      onAjax,
      pagination: paginationState,
      isExportOnly
    } = this.state;

    const isAll: boolean = options.volume === 'all';
    const isWaitingTab = this.getTabKeyCode() === 'W';

    if (onAjax) return Promise.resolve();

    const pagination = {
      ...this.state.pagination,
      pageSize: isAll ? dataSourceCount : paginationState.pageSize,
    };

    this.setState({ onAjax: true });

    if (options.volume === 'selected' && selected.length === 0) {
      const message = isExportOnly ? "Nothing to export" : "Nothing to move and export";
      Notification.warning(message);
      this.setState({ onAjax: false });

      return Promise.resolve();
    }

    if (options.exportFormat === 'availity') {
      return EligibilityManagerPageActions.exportOrders(
        this.prepareFilter(),
        pagination,
        isAll ? [] : selected,
        !isExportOnly
      )
        .then((fileName: any) => {
          downloadFile(`${BASE_URL_FILE_DIR}doc_img/billingexport/${fileName}`);
          if (isWaitingTab) this.updateData();
          else this.setState({ onAjax: false });
        })
        .catch(() => {
          this.setState({ onAjax: false });
        });
    }

    return EligibilityManagerPageService.exportExperianOrders(
      this.prepareFilter(),
      pagination,
      isAll ? [] : selected,
      !isExportOnly
    )
      .then((fileName: any) => {
        downloadFile(`${BASE_URL_FILE_DIR}doc_img/billingexport/${fileName}`);
        if (isWaitingTab) this.updateData();
        else this.setState({ onAjax: false });
      })
      .catch(() => {
        this.setState({ onAjax: false });
      });
  };

  onChangeStatus = (options: OptionsType) => {
    const { selected, dataSourceCount } = this.state;

    const grid = this.refs.grid as Grid;

    if (options.volume === 'all') {
      const pagination = {
        ...this.state.pagination,
        pageSize: dataSourceCount,
      };

      return EligibilityManagerPageService.updateStatusForAll(
        this.prepareFilter(),
        pagination,
      )
        .then(() => {
          Notification.success('Status updated');
          if (grid) {
            grid.selectAll(false);
          }
          this.updateData();
        });
    }

    if (!selected.length) {
      Notification.warning('Nothing to move');

      return Promise.resolve();
    }

    const tabKeyCode = this.getTabKeyCode();
    const flag = tabKeyCode === 'W' ? 'W'
      : (tabKeyCode === 'P' || tabKeyCode === 'C' ) ? 'I'
        : tabKeyCode === 'I' ? 'C' : '';

    return EligibilityManagerPageActions.updateStatus(flag, selected).then(() => {
      Notification.success('Status updated');
      if (grid) {
        grid.selectAll(false);
      }
      this.updateData();
    });
  };

  onSubmitEligibilityPopup = (options: OptionsType) => {
    const { isAvailityEnabled, isExperianEnabled } = this.props;

    const moveClaims = (isAvailityEnabled || isExperianEnabled)
      ? this.onExport(options) : this.onChangeStatus(options);

    moveClaims.then(() => this.setState( {
      showEligibilityExportPopup: null,
      isExportOnly: false
    } ));
  };

  onCloseEligibilityPopup = () => {
    this.setState( {
      showEligibilityExportPopup: null,
      isExportOnly: false
    } );
  };

  getExportFormatOptions() {
    const { isAvailityEnabled, isExperianEnabled } = this.props;

    const exportFormatOptions: ExportFormatOptionType[] = [
      isAvailityEnabled ? { value: 'availity', label: 'Availity' } : null,
      isExperianEnabled ? { value: 'experian', label: 'Experian' } : null
    ].filter(Boolean);

    return exportFormatOptions;
  };

  getDialogSubmitBtnText() {
    const { isAvailityEnabled, isExperianEnabled } = this.props;
    const { isExportOnly } = this.state;

    const submitBtnText = (isAvailityEnabled || isExperianEnabled)
        ? (isExportOnly ? "Export" : "Move and Export")
        : "Move";

    return submitBtnText;
  };

  onUpdateExportedStatus(flag: 'W' | 'I' | 'C' | '') {
    const { selected } = this.state;
    const grid = this.refs.grid as Grid;

    EligibilityManagerPageActions.updateStatus(flag, selected).then(() => {
      Notification.success('Status updated');
      if (grid) {
        grid.selectAll(false);
      }
      this.updateData();
    });
  }

  updateData = () => {
    const controller = new AbortController();

    const signal = controller.signal;

    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = controller;

    this.setState({ onAjax: true });

    EligibilityManagerPageActions.loadGridData(
      this.prepareFilter(),
      this.state.pagination,
      signal
    ).then(this.updateDataCallback, () => {});
  };

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.abortController = null;

    this.setState({
      onAjax: false,
      selected: [],
    });

    if (grid) {
      // @ts-ignore
      grid.clearSelection();
    }
  };

  prepareFilter(): TOrdersFilter {
    const { isDivisionEnabled } = this.props;
    const { filter } = this.state;
    const tabKeyCode = this.getTabKeyCode();
    const status = tabKeyCode === 'C' ? '' : tabKeyCode;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return {
      facility: filter.facility,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
      corporateId: null,
      mode: 'VC',
      srchlastnameval: filter.lastName || '',
      srchfirstnameval: filter.firstName,
      orderId: null,
      status,
      examtype: filter.examType,
      stateId: filter.state,
      locId: filter.location,
      techId: filter.technologist,
      posId: filter.pos,
      dosSearch: '',
      batchclaim: filter.eligibility === 'C' ? 'C' : 'E',
      orderType: filter.orderType,
      providerType: filter.providerType,
      srchdate: filter.dob || '',
      payer: filter.payer,
      division: isDivisionEnabled ? filter.division : 0,
    };
  }

  showAuditLog(order: TSignOrderItem) {
    this.setState({ selectedOrder: order, isAuditLogOpen: true });
  }

  handleChangeTab = (currentTab: number) => {
    const { history } = this.props;

    const item = tabsList[currentTab];

    this.isColumnsInit = false;

    history.push({ search: item.url });

    const eligibility = item.url.replace('?tab=', '') as
      | 'I'
      | 'C'
      | 'W'
      | 'P'
      | '';

    const filter = {
      ...this.state.filter,
      eligibility,
    };

    const pagination = {
      ...this.state.pagination,
      skip: 0,
      page: 1,
    };

    this.setState(
      {
        currentTab,
        pagination,
        filter,
      },
      this.updateData
    );
  };

  handleChangeLocalFilter = (name: string, value: any) => {
    let shouldUpdate = false;
    const filter = { ...this.state.filter };
    if (name === 'period') {
      shouldUpdate = true;
      switch (value) {
        case 'A':
          filter.dosStart = null;
          filter.dosEnd = null;
          break;
        case 'W':
          break;
        case 'M':
          break;
      }
    } else if (name === 'eligibility') {
      shouldUpdate = true;
    }

    (filter as any)[name] = value;
    this.setState({ filter }, shouldUpdate ? this.updateData : undefined);
  };

  getEligibilityHiddenColumns() {
    const keyCode = this.getTabKeyCode();

    switch (keyCode) {
      case 'W':
        return ['updateInsurance'];
      case 'P':
        return ['revert'];
      case 'I':
        return [];
      case 'C':
        return ['revert'];
      default:
        return [];
    }
  }

  getEligibilityShownColumns() {
    const keyCode = this.getTabKeyCode();

    switch (keyCode) {
      case 'W':
        return ['revert'];
      case 'P':
        return ['updateInsurance'];
      case 'I':
        return ['revert', 'updateInsurance'];
      case 'C':
        return ['updateInsurance'];
      default:
        return [];
    }
  }

  getShownColumns() {
    const cols = [
      'eligible_date',
      'eligibility_status',
      'updateInsurance',
      'ins_last_verified',
      'last_name',
      'first_name',
      'dos',
      'patientdob',
      'ssn',
      'facility',
      'visitInfo',
      ...this.getEligibilityShownColumns(),
    ];
    const hidden = this.getEligibilityHiddenColumns();
    for (let i = 0; i < hidden.length; i++) {
      const index = cols.indexOf(hidden[i]);
      if (index > -1) {
        cols.splice(index, 1);
      }
    }
    return cols;
  }

  technologistTooltip(str: string) {
    const data: Array<React.ReactNode> = str.split('\n');
    const length = data.length;
    for (let i = length - 1; i > 0; i--) {
      data.splice(i, 0, <br key={'tt_' + i} />);
    }
    return data;
  }

  codeRender(data: string) {
    if (!data) {
      return null;
    }
    const formattedData = data
      .split(',')
      .map((v: string) =>
        v
          .trim()
          .split('-')
          .map((v) => v.trim())
      )
      .map((v: any, i: any) => (
        <div key={i}>
          {v.length === 2 ? (
            <span>
              <strong>{v[0]}</strong> - {v[1]}
            </span>
          ) : (
            v[0]
          )}
        </div>
      ));
    return (
      <Tooltip body={formattedData}>
        <i className="bi bi-upc-scan fs-5 text-primary" />
      </Tooltip>
    );
  }

  facilityToolTip(data: TSignOrderItem): React.ReactNode {
    return (
      <div>
        <p> Order Source : {data.order_source}</p>
        <p> Caller Name : {data.order_source_nm}</p>
        <p> Direct Number : {data.order_based}</p>
        <p>
          {' '}
          Address : {data.address1} {data.address2}
        </p>
        <p> City : {data.city}</p>
        <p> State : {data.state}</p>
        <p> Zip Code : {data.zipcode}</p>
        <p> Phone : {data.phone}</p>
        <p> Email : {data.bill_email}</p>
        <p> NPI : {data.facility_npi}</p>
      </div>
    );
  }

  closeEligibility(v: any) {
    const newState = {
      showEligibilityPopup: false,
      selectedOrder: null as null,
    };
    if (v) {
      this.setState(newState, this.updateData);
    } else {
      this.setState(newState);
    }
  }

  onPaginationChange = (pagination: TPagination) => {
    this.setState({ pagination }, this.updateData);
  };

  onUpdateSelection = (selected: Array<any>) => {
    this.setState({ selected });
  };

  handleFilterToggle = () => {
    this.setState((state) => ({ showFilter: !state.showFilter }));
  };

  handleFilterApply = (filter: FilterType) => {
    const pagination = {
      ...this.state.pagination,
      page: 1,
      skip: 0,
    };

    this.setState(
      {
        filter,
        pagination,
      },
      this.updateData
    );
  };

  handleCloseOrderMessages = (shouldUpdate: boolean) => {
    this.setState({ orderMessages: false });
    if (shouldUpdate) this.updateData();
  };

  handleCloseVisitInfo = () => {
    const { history } = this.props;

    const { currentTab } = this.state;

    history.push({ search: tabsList[currentTab].url });

    this.setState(
      {
        showVisitInformation: false,
        selectedOrder: null,
        visitInfoDefaultTab: 0,
      },
      this.updateData
    );
  };

  handleCloseRevert = () => {
    this.setState({ showRevert: false });
  };

  handleApproveRevert = () => {
    this.handleCloseRevert();

    EligibilityManagerPageActions.revertOrder(
      this.state.selectedOrder.refid
    ).then(() => {
      Notification.success('Claim successfully reverted to waiting status');
      this.updateData();
    });
  };

  componentDidMount() {
    this.updateData();
  }

  render() {
    const { state } = this;
    const { selectedOrder, showVisitInformation, visitInfoDefaultTab } = state;

    return (
      <>
        {showVisitInformation && (
          <VisitInformationPage
            defaultTab={visitInfoDefaultTab}
            selectedOrder={state.selectedOrder}
            callback={this.handleCloseVisitInfo}
          />
        )}
        <div className={showVisitInformation ? 'visually-hidden' : ''}>
          <LayoutSideTitle appId={PAGE_ID.PAGE} title="Eligibility Manager">
            <Button
              variant="default"
              data-testid="toggleFilter"
              onClick={this.handleFilterToggle}
              text={this.state.showFilter ? 'Hide' : 'Show Filter'}
            />
          </LayoutSideTitle>

          {!showVisitInformation && (
            <Tabs
              tabsList={tabsList}
              onClick={this.handleChangeTab}
              className="mb-4"
            />
          )}
          <Filter
            isVisible={state.showFilter}
            fetching={state.onAjax}
            defaultValues={state.filter}
            onApplyFilter={this.handleFilterApply}
          />
          <Grid
            ref="grid"
            selectId="refid"
            key={this.props.location.search}
            minColumns={6}
            gridControlPanel={this.getExtraActions()}
            onAjax={state.onAjax}
            id={GRID_ID}
            columns={this.getColumns()}
            dataSource={state.dataSource}
            pagination={state.pagination}
            dataSourceCount={state.dataSourceCount}
            onSelectChange={this.onUpdateSelection}
            onPaginationChange={this.onPaginationChange}
            onDoubleClick={this.handleDisplayVisitInfo}
          />
        </div>
        {state.showEligibilityPopup && (
          <EligibilityPopup
            order={{ ...selectedOrder, dob: selectedOrder.patientdob }}
            eligibility={state.filter.eligibility}
            flagOrderEntryPayerInfo
            callback={(v) => this.closeEligibility(v)}
          />
        )}
        {state.showRevert && (
          <DialogConfirm
            title="Revert to Waiting Status"
            onCancel={this.handleCloseRevert}
            onApprove={this.handleApproveRevert}>
            Would you like to revert this claim to Waiting? Clicking yes will
            revert this claim's eligibility status to "Waiting" and send the
            order back to the Waiting tab.
          </DialogConfirm>
        )}
        {state.isAuditLogOpen && (
          <DialogAuditLog
            order={selectedOrder}
            onClose={() => this.setState({ isAuditLogOpen: false })}
          />
        )}
        {state.orderMessages && (
          <EncounterMessageDialog
            id={selectedOrder.refid}
            onClose={this.handleCloseOrderMessages}
          />
        )}

        {state.showEligibilityExportPopup && (
          <DialogEligibilityExport
            popupTitle={state.eligibilityExportPopupTitle}
            submitBtnText={this.getDialogSubmitBtnText()}
            defaultValues={state.showEligibilityExportPopup}
            exportFormatOptions={this.getExportFormatOptions()}
            onSubmit={this.onSubmitEligibilityPopup}
            onClose={this.onCloseEligibilityPopup}
          />
        )}
      </>
    );
  }
}

const EligibilityManagerPageContainer = Container.create(
  EligibilityManagerPage
);

export default withEligibilitySettings(withDivisionSettings(EligibilityManagerPageContainer));
