import { useMemo } from 'react';

import Common, { ParentPropsType } from './Common';

import { storeChatting } from 'stores/_mobx/message/chatting';

const DialogMessagesFacility = ({ id, onClose }: ParentPropsType) => {
  const payload = useMemo(() => ({ facilityId: id }), [id]);

  const getListMessages = () => {
    storeChatting.getMessages(id, 'facility');
  };

  return (
    <Common
      title="Invoice Message"
      payload={payload}
      getListMessages={getListMessages}
      onClose={onClose}
    />
  );
};

export default DialogMessagesFacility;
