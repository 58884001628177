import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import {
  convertDateInStingToLocalTimezone,
  dateToLocalTimezone,
} from 'utils/DateUtils';

const defaultValueFilter: FilterType = {
  contactTitle: '',
  facilityId: 0,
  fax: '',
  firstname: '',
  isActive: 'A',
  lastname: '',
  phone: '',
};

export interface FilterType {
  contactTitle: string;
  facilityId: number;
  fax: string;
  firstname: string;
  isActive: 'A' | 'I';
  lastname: string;
  phone: string;
}

export interface FacilityContactType {
  address1: string;
  address2: string;
  billing_address: string;
  city: string;
  comments: string;
  con_title: string;
  contact_name: string;
  contact_nm: string;
  corporation_name: string;
  designation: string;
  email: string;
  facility: string;
  facility_local: string;
  fax: string;
  first_name: string;
  fullname: string;
  last_name: string;
  middle_name: string;
  owner_status: string;
  phone: string;
  radiology_id: string;
  refid: number;
  state: string;
  status: 'A' | 'I';
  zipcode: string;
}

interface FacilityListResponseType {
  data: FacilityContactType[];
  count: number;
}

interface AuditLogListResponseType {
  data: AuditLogType[];
  count: number;
}

interface FacilityEditResponseType
  extends Omit<
    FacilityContactType,
    | 'designation'
    | 'facility'
    | 'fullname'
    | 'owner_status'
    | 'corporation_name'
  > {
  facility_id: number[];
  corportation_name: string;
}

export interface FacilityContactEditType
  extends Omit<FacilityEditResponseType, 'refid' | 'state' | 'radiology_id'> {
  refid: number;
  state: string;
  radiology_id: number;
}

interface AuditLogType {
  action: string;
  contact_id: number;
  refid: number;
  ts: string;
  user: string;
  user_id: number;
}

class FacilityContact {
  fetching: boolean = false;
  fetchingAuditLog: boolean = false;
  auditLogList: AuditLogType[] = [];
  auditLogTotal: number = 0;
  facilityList: FacilityContactType[] = [];
  facilityTotal: number = 0;
  facilityContact?: FacilityContactEditType;
  idForDelete: number = 0;
  idForAuditLog: number = 0;
  filter: FilterType = defaultValueFilter;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'facility_contacts_grid' });

    makeObservable(this, {
      fetching: observable,
      fetchingAuditLog: observable,
      facilityList: observable,
      facilityTotal: observable,
      auditLogList: observable,
      auditLogTotal: observable,
      facilityContact: observable,
      filter: observable,
      idForDelete: observable,
      idForAuditLog: observable,

      setFetching: action,
      setFacilityContact: action,
      setFilter: action,
      setIdForDelete: action,
      setIdForAuditLog: action,
      clearIdForDelete: action.bound,
      clearAuditLog: action.bound,
      clearFacility: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setFacilityContact(contact?: FacilityContactEditType) {
    this.facilityContact = contact;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setIdForAuditLog(id: number) {
    this.idForAuditLog = id;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  clearAuditLog() {
    this.idForAuditLog = 0;
    this.auditLogTotal = 0;
    this.auditLogList = [];
  }

  clearFacility() {
    this.facilityContact = undefined;
  }

  getFacilityContactList = async (
    { facilityId, ...filter }: FilterType = this.filter
  ) => {
    const { pagination } = this.page;

    const data = [
      { ...filter, ...(facilityId ? { facilityId } : {}) },
      pagination.skip,
      pagination.pageSize,
    ];

    this.setFetching(true);
    try {
      const { count, data: facilityList } =
        await apiRequest<FacilityListResponseType>({
          url: 'facility.MvFacilityOwner.getOwnerList',
          data,
        });

      const list = facilityList.map((item) => ({
        ...item,
        refid: Number(item.refid),
      }));
      runInAction(() => {
        this.fetching = false;
        this.facilityList = list;
        this.facilityTotal = Number(count);
      });
    } catch (e) {
      runInAction(() => {
        this.fetching = false;
        this.facilityList = [];
        this.facilityTotal = 0;
      });
    }
  };

  async getFacilityContact(id: number) {
    this.setFetching(true);

    try {
      const response = await apiRequest<'SE' | [FacilityEditResponseType]>({
        url: 'facility.MvFacilityOwner.GetViewByID',
        data: [id],
      });
      if (Array.isArray(response)) {
        const [contact] = response;

        const facilityContact: FacilityContactEditType = {
          ...contact,
          refid: id,
          radiology_id: Number(contact.radiology_id),
        };

        this.setFacilityContact(facilityContact);
      } else {
        throw Error('');
      }
    } catch (e) {
      this.setFacilityContact();
    } finally {
      this.setFetching(false);
    }
  }

  async addFacilityContact(payload: FacilityContactEditType) {
    const data = [
      payload.corportation_name,
      payload.radiology_id,
      payload.first_name,
      payload.middle_name,
      payload.last_name,
      payload.billing_address,
      payload.address1,
      payload.address2,
      payload.city,
      payload.state,
      payload.zipcode,
      payload.phone,
      payload.fax,
      payload.email,
      payload.contact_name,
      payload.contact_nm,
      payload.comments,
      payload.status,
      payload.con_title,
      payload.facility_id,
      'N',
    ];

    try {
      const result = await apiRequest<'SE' | 'E' | number>({
        url: 'facility.MvFacilityOwner.AddMvFacilityOwner',
        data,
      });

      if (!Number.isNaN(result)) {
        Notification.success('Facility contact was added successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This email address already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateFacilityContact(payload: FacilityContactEditType) {
    const data = [
      payload.refid,
      payload.corportation_name,
      payload.radiology_id,
      payload.first_name,
      payload.middle_name,
      payload.last_name,
      payload.billing_address,
      payload.address1,
      payload.address2,
      payload.city,
      payload.state,
      payload.zipcode,
      payload.phone,
      payload.fax,
      payload.email,
      payload.contact_name,
      payload.contact_nm,
      payload.comments,
      payload.status,
      payload.con_title,
      payload.facility_id,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'facility.MvFacilityOwner.EditMvFacilityOwner',
        data,
      });

      if (result === 'S') {
        Notification.success('Facility contact was updated successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'This email already registered' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  changeFacilityContactStatus = async (ids: number[]) => {
    this.setFetching(true);
    try {
      const response = await Promise.allSettled(
        ids.map((id) =>
          apiRequest<'S' | 'SE'>({
            url: 'facility.MvFacilityOwner.UpdateStatus',
            data: [id, this.filter.isActive === 'A' ? 'Active' : 'Inactive'],
          })
        )
      );
      const totalSuccess = response.reduce(
        // @ts-ignore
        (count, { value }) => (value === 'S' ? ++count : count),
        0
      );

      if (totalSuccess) {
        const statusMsg =
          this.filter.isActive === 'A' ? 'deactivated' : 'activated';

        Notification.success(
          `${totalSuccess} Facility contact${
            totalSuccess > 1 ? 's were' : 'was'
          } ${statusMsg}`
        );
        return true;
      } else Error('');
    } catch (e) {
      Notification.warning('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  };

  async deleteFacilityContact() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'facility.MvFacilityOwner.DeleteFacilityOwner',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('Facility contact was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("This contact can't be deleted!");
      this.setFetching(false);
      return false;
    }
  }

  async getAuditLogs(id: number) {
    runInAction(() => {
      this.fetchingAuditLog = true;
    });
    try {
      const { count, data } = await apiRequest<AuditLogListResponseType>({
        url: 'facility.MvFacilityOwner.getContactListLog',
        data: [id, 0, 100],
      });
      const list = data.map((log) => ({
        ...log,
        ts: dateToLocalTimezone({ date: log.ts }),
        action: convertDateInStingToLocalTimezone(log.action),
        refid: Number(log.refid),
        contact_id: Number(log.contact_id),
        user_id: Number(log.user_id),
      }));

      runInAction(() => {
        this.fetchingAuditLog = false;
        this.auditLogList = list;
        this.auditLogTotal = Number(count);
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingAuditLog = false;
        this.auditLogList = [];
        this.auditLogTotal = 0;
      });
    }
  }
}

export const storeFacilityContact = new FacilityContact();
