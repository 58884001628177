import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    prefix_nm: yup.string().required("Prefix name can't be empty!"),
    prefix_desc: yup.string().required("Prefix description can't be empty!"),
  })
);

export default validation;
