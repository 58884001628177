import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { GridControlButton } from 'components/grid';
import {
  Grid,
  GridExtraControlPanel,
  ICellRendererParams,
  ColDef,
} from 'components/gridModern';
import { IconButton } from 'components/button';
import { LayoutSideTitle } from 'components/layout';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import Filter from './components/Filter';

import {
  storeDivisionMaster,
  DivisionType,
} from 'stores/_mobx/systemSetup/masterSetting/divisionMaster';
import {
  URL_MASTER_SETTINGS,
  URL_DIVISION_MASTER,
  URL_DIVISION_MASTER_ADD,
} from 'constant/path/systemSetup';
import { GRID_ID_DIVISION_MASTER } from 'constant/gridsId/systemSetup';

const columns: ColDef<DivisionType>[] = [
  { field: 'shortName', headerName: 'Short Name', flex: 1 },
  { field: 'name', headerName: 'Division Name', flex: 2 },
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
    cellClass: 'text-center',
    headerClass: 'text-center',
  },
  {
    field: 'id',
    colId: 'action',
    headerName: 'Action',
    headerClass: 'text-center',
    sortable: false,
    width: 100,
    cellRenderer: ({ value }: ICellRendererParams<DivisionType, number>) => (
      <div className="controls">
        <Link className="bi bi-pencil" to={`${URL_DIVISION_MASTER}/${value}`} />
        <IconButton onClick={() => storeDivisionMaster.setIdForDelete(value)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const DivisionMasterListPage = () => {
  const {
    fetching,
    idForDelete,
    divisions: { divisionsList, divisionsTotal },
  } = storeDivisionMaster;

  const handleChangeFilter = useDebounce(storeDivisionMaster.setFilter, 200);

  const handleGetData = useCallback(() => {
    storeDivisionMaster.getDivisions();
  }, []);

  const handleConfirmDelete = () => {
    storeDivisionMaster.deleteDivision().then((isSucceed) => {
      if (isSucceed) storeDivisionMaster.getDivisions();
    });
  };

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  return (
    <>
      <LayoutSideTitle title="Division Master">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <Filter onChange={handleChangeFilter} />

      <GridExtraControlPanel>
        <GridControlButton title="Add" url={URL_DIVISION_MASTER_ADD} />
      </GridExtraControlPanel>
      <Grid
        id={GRID_ID_DIVISION_MASTER}
        columnDefs={columns}
        isLoading={fetching}
        rowData={divisionsList}
        totalCount={divisionsTotal}
        onRefreshData={handleGetData}
      />

      {idForDelete ? (
        <DialogConfirm
          onCancel={storeDivisionMaster.clearIdForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this Division?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(DivisionMasterListPage);
