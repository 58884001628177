import { makeObservable, observable, action, computed } from 'mobx';

import { apiRequest } from 'services/RequestService';
import { TFacilityItemRes } from 'services/system-setup/master-setup/facility/FacilityPageService';
import { TAdminInfo } from 'stores/record/UserProfileRecord';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { moneyRender } from 'utils/StringUtils';
import { dateToLocalTimezone } from 'utils/DateUtils';

export const filterDefaultValue: FilterType = {
  arAgingType: 'F',
  balanceDueDays: '',
  billedDateFrom: '',
  billedDateTo: '',
  corporationId: 0,
  invoiceDateFrom: '',
  invoiceDateTo: '',
  period: '',
  facilityId: [],
  firstName: '',
  lastName: '',
  orderType: '',
  pos: 0,
  providerType: 0,
  stateId: [],
  region: [],
  location: [],
  division: 0,
};

export interface FilterType {
  arAgingType: 'P' | 'F';
  balanceDueDays: string;
  billedDateFrom: string;
  billedDateTo: string;
  corporationId: number;
  invoiceDateFrom: string;
  invoiceDateTo: string;
  period: string;
  facilityId: number[];
  firstName: string;
  lastName: string;
  orderType: string;
  pos: number;
  providerType: number;
  stateId: number[];
  region: number[];
  location: number[];
  division: number;
}

interface AgingResponseType {
  finalvalue: AgingType[];
  value: SummaryResponseType[];
}

export interface AgingType {
  '0to30': string;
  '31to60': string;
  '61to90': string;
  '91to120': string;
  above: string;
  address1: string;
  address2: string;
  balance: string;
  bill_email: string;
  facility_npi: string;
  facilityid: number;
  facilitynm: string;
  lastname?: string;
  firstname?: string;
  patientmrn?: string;
  clientMRN?: string;
  phone: string;
  region: string;
  location: string;
  division: string;
}

export interface FacilityDetailsType {
  id: number;
  facilityName: string;
  facilityNpi: string;
  phone: string;
  address: string;
  staffEmail: string;
  balance: string;
  charge: string;
  invoices: InvoiceDetailsType[];
  chartDataLinear: [string, string | number][];
  chartDataPie: [string, string | number][];
  companyName: string;
  adminAddress: string;
  adminBillPhone: string;
  adminPhone: string;
}

export interface InvoiceDetailsType {
  address1: string;
  adj: string;
  age: number;
  bal: string;
  bill_email: string;
  clientMRN: string;
  cptdesc: string;
  facilityId: number;
  facility_npi: string;
  facilityid: number;
  facilitynm: string;
  icddesc: string;
  inspaid: string;
  invoice_dt: string;
  invoice_no: string;
  patientmrn: string;
  patientnm: string;
  patpaid: string;
  phone: string;
  refid: Number;
  sheduleservdate: string;
  studyid: number;
  total_price: string;
  wrtdwn: string;
}

interface InvoiceFacilityResponseType {
  invoicedetail: InvoiceDetailsType[];
  value: [string, string, string];
}

interface SummaryResponseType {
  amt: number;
  lbl: string;
}

interface InvoiceChartResponseType {
  invoice_no: string;
  age: number;
}

class ArAging {
  fetching: boolean = false;
  agingList: AgingType[] = [];
  agingTotal: number = 0;
  invoiceFacilityDetails: FacilityDetailsType | null = null;
  chartData: [string, string | number][] = [['Period', 'Amount']];
  filter: FilterType = filterDefaultValue;
  page: Pagination = new Pagination({ id: 'ar_aging_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      agingList: observable,
      agingTotal: observable,
      chartData: observable,
      filter: observable,
      invoiceFacilityDetails: observable,

      agingFormattedList: computed,
      summaryFormattedList: computed,

      setFetching: action,
      setAgingTotal: action,
      setAgingList: action,
      setChartData: action,
      setFacilityDetails: action,
      setFilter: action.bound,
      clearFacilityDetails: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setAgingTotal(count: number) {
    this.agingTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setAgingList(list: AgingType[]) {
    this.agingList = list;
  }

  setChartData(chartData: [string, string | number][]) {
    this.chartData = chartData;
  }

  setFacilityDetails(details: FacilityDetailsType) {
    this.invoiceFacilityDetails = details;
  }

  clearFacilityDetails() {
    this.invoiceFacilityDetails = null;
  }

  get agingFormattedList() {
    return this.agingList.map((entry) => ({
      ...entry,
      '0to30': moneyRender(entry['0to30']),
      '31to60': moneyRender(entry['31to60']),
      '61to90': moneyRender(entry['61to90']),
      '91to120': moneyRender(entry['91to120']),
      above: moneyRender(entry.above),
      balance: moneyRender(entry.balance),
    }));
  }

  get summaryFormattedList() {
    return this.chartData.reduce(
      (prev: { label: string; value: string }[], [label, value], idx) => {
        if (!idx) return prev;
        return prev.concat({
          label: label.replace('From ', 'Total of '),
          value: moneyRender(value),
        });
      },
      []
    );
  }

  async getAgingCount(filter: any) {
    try {
      const count = await apiRequest<string>({
        url: 'facility.FacilityBilling.arAgingCount',
        data: { filter },
      });

      this.setAgingTotal(Number(count) || 0);
    } catch (e: any) {
      this.setAgingTotal(0);
    }
  }

  async getAgingList(filter: any) {
    const {
      page: { pagination },
    } = this;

    const data = { filter, skip: pagination.skip, limit: pagination.pageSize };

    try {
      const { finalvalue, value } = await apiRequest<AgingResponseType>({
        url: 'facility.FacilityBilling.arAging',
        data,
      });

      const logList = finalvalue.map((entry) => ({
        ...entry,
        facilityid: Number(entry.facilityid),
      }));

      const chartData = value.reduce(
        (prev: [string, number | string][], { lbl, amt }) =>
          prev.concat([[`From ${lbl}`, amt]]),
        [['Period', 'Amount']]
      );

      this.setChartData(chartData);
      this.setAgingList(logList);
    } catch (e: any) {
      this.setChartData([['Period', 'Amount']]);
      this.setAgingList([]);
    }
  }

  async getAgingMain(isDivisionEnabled: boolean) {
    const { filter } = this;

    this.setFetching(true);

    const formattedFilter = {
      arAgingType: filter.arAgingType,
      balanceDueDays: filter.balanceDueDays,
      billedDateFrom: filter.billedDateFrom,
      billedDateTo: filter.billedDateTo,
      corporation: filter.corporationId || '',
      dosEnd: filter.invoiceDateTo,
      dosStart: filter.invoiceDateFrom,
      facility: filter.facilityId,
      firstName: filter.firstName,
      lastName: filter.lastName,
      orderType: filter.orderType,
      posId: filter.pos || '',
      providerType: filter.providerType || '',
      state: filter.stateId,
      region: filter.region,
      location: filter.location,
      division: isDivisionEnabled ? filter.division : 0,
    };

    const promiseList = this.getAgingCount(formattedFilter);

    const promiseCount = this.getAgingList(formattedFilter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  }

  async getFacility(id: number) {
    try {
      const { facility } = await apiRequest<TFacilityItemRes>({
        url: 'facility.FacilityMaster.GetFacility',
        data: [id],
      });
      return facility[0];
    } catch (e: any) {
      return null;
    }
  }

  async getFacilityInvoices(facilityId: number) {
    try {
      const response = apiRequest<InvoiceFacilityResponseType>({
        url: 'facility.FacilityBilling.GetArInvoiceByFacility',
        data: { facilityId },
      });
      return response;
    } catch (e: any) {
      return null;
    }
  }

  async getChartDataFacilityInvoice({
    facilityId,
    isPieChart,
  }: {
    facilityId: number;
    isPieChart: boolean;
  }) {
    const url = `facility.FacilityBilling.GetArInvoiceByFacilityTo${
      isPieChart ? 'PieChart' : 'Chart'
    }`;

    try {
      const response = await apiRequest<InvoiceChartResponseType[]>({
        url,
        data: { facilityId },
      });

      const chartData = response.reduce(
        (prev: [string, number | string][], { invoice_no, age }) =>
          prev.concat([
            [isPieChart ? `Invoice ${invoice_no}` : String(invoice_no), age],
          ]),
        [['Invoice', 'Age']]
      );

      return chartData;
    } catch (e: any) {
      return [['Invoice', 'Age']] as [string, number | string][];
    }
  }

  async getInvoiceFacilityDetails(id: number) {
    this.setFetching(true);
    try {
      const promiseFacility = this.getFacility(id);

      const promiseInvoices = this.getFacilityInvoices(id);

      const promisePieCharts = this.getChartDataFacilityInvoice({
        facilityId: id,
        isPieChart: true,
      });

      const promiseLinearChart = this.getChartDataFacilityInvoice({
        facilityId: id,
        isPieChart: false,
      });

      const [facility, invoices, chartDataPie, chartDataLinear] =
        await Promise.all([
          promiseFacility,
          promiseInvoices,
          promisePieCharts,
          promiseLinearChart,
        ]);

      const adminInfo: TAdminInfo = UserProfileStore.getAdminInfo();

      const charge = invoices?.value[0] || '0.00';

      const balance = invoices?.value[1] || '0.00';

      const facilityDetails = {
        id,
        facilityName: facility.facility_nm,
        facilityNpi: facility.facility_npi,
        phone: facility.phone,
        address: facility.address1 || facility.address1,
        staffEmail: facility.admin_email || facility.nursing_email,
        balance,
        charge,
        invoices: Array.isArray(invoices?.invoicedetail)
          ? invoices.invoicedetail.map((invoice) => ({
              ...invoice,
              sheduleservdate: dateToLocalTimezone({
                date: invoice.sheduleservdate,
                dateOnly: true,
              }),
              total_price: `$${invoice.total_price}`,
              bal: `$${invoice.bal}`,
              refid: Number(invoice.refid),
              studyid: Number(invoice.studyid),
            }))
          : [],
        chartDataLinear,
        chartDataPie,
        companyName: adminInfo.company_name,
        adminAddress: [
          adminInfo.billingaddress,
          adminInfo.billingcity,
          adminInfo.billingstate,
          adminInfo.billingzip,
        ].join(', '),
        adminBillPhone: adminInfo.billingphone,
        adminPhone: adminInfo.phone,
      };
      this.setFacilityDetails(facilityDetails);
    } catch (e: any) {
      this.setFacilityDetails(null);
    } finally {
      this.setFetching(false);
    }
  }

  generatePdf = async () => {
    const { filter, agingList } = this;
    const data = {
      data: agingList,
      f0_30: this.chartData[1]?.[1],
      f31_60: this.chartData[2]?.[1],
      f61_90: this.chartData[3]?.[1],
      f91_120: this.chartData[4]?.[1],
      f120: this.chartData[5]?.[1],
    };
    const url =
      filter.arAgingType === 'F'
        ? 'facility.ArPdfGenerator.GenerateFacArPDF'
        : 'facility.ArPdfGenerator.GeneratePatArPDF';

    try {
      const fileName = await apiRequest<string>({
        url,
        data,
      });
      return `facility/${fileName}`;
    } catch (e: any) {
      return '';
    }
  };

  generatePdfInvoiceDetails = async () => {
    const { invoiceFacilityDetails } = this;

    this.setFetching(true);
    try {
      if (!invoiceFacilityDetails) throw Error('');

      const data = {
        dataSource: invoiceFacilityDetails.invoices,
        charge: invoiceFacilityDetails.charge,
        bal: invoiceFacilityDetails.balance,
        facilityName: invoiceFacilityDetails.facilityName,
        facilityPhone: invoiceFacilityDetails.phone,
        facilityId: invoiceFacilityDetails.id,
      };

      const fileName = await apiRequest<string>({
        url: 'facility.ArPdfGenerator.GenerateFacilityItemListPDF',
        data,
      });
      return `facility/${fileName}`;
    } catch (e: any) {
      return '';
    } finally {
      this.setFetching(false);
    }
  };
}

export const storeArAging = new ArAging();
