import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Tooltip from 'components/tooltip';
import Title from 'components/project/common/title';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import Filter from './components/Filter';

import {
  faxStatusStyle,
  storeFax,
  FaxType,
  FilterModel,
  FaxStatusType,
} from 'stores/_mobx/workflow/order/fax';
import { BASE_URL_FILE_DIR } from 'constant/config';
import { FAX as PAGE_ID } from 'constant/pagesId/workflow';
import { URL_ORDER_FAX_ADD } from 'constant/path/workflow';
import Badge from 'components/badge';

const columns = [
  { name: 'receiverName', title: 'Receiver name' },
  { name: 'receiverFax', title: 'Receiver fax' },
  { name: 'receiverMail', title: 'Receiver email' },
  { name: 'subject', title: 'Subject' },
  { name: 'createdDt', title: 'Date and time', className: 'width-100' },
  {
    name: 'faxStatus',
    title: 'Status',
    className: 'width-100 text-center',
    render: (status: FaxStatusType, { errorDescription }: FaxType) => (
      <div className="text-icon-wrapper">
        <span className="text-icon-pic">
          {errorDescription ? (
            <Tooltip
              body={errorDescription}
              theme="danger"
              title="Sending Error">
              <i className="bi bi-exclamation-triangle-fill text-danger" />
            </Tooltip>
          ) : null}
        </span>
        <Badge variant={faxStatusStyle[status]}>{status}</Badge>
      </div>
    ),
  },
  {
    name: 'faxIsFile',
    title: 'Fax message',
    className: 'text-center',
    render: (faxIsFile: boolean, { documentPath, plainTextMessage }: FaxType) =>
      faxIsFile ? (
        documentPath ? (
          <a
            href={`${BASE_URL_FILE_DIR}${documentPath}`}
            rel="noreferrer"
            className="fs-4"
            target="_blank">
            <i className="bi bi-filetype-pdf" />
          </a>
        ) : null
      ) : (
        <Tooltip body={plainTextMessage}>
          <i className="bi bi-card-text fs-4 text-primary" />
        </Tooltip>
      ),
  },
  {
    name: 'action',
    title: 'Actions',
    className: 'width-75',
    render: (value: undefined, { id, faxStatus }: FaxType) => (
      <div className="control">
        <IconButton
          title="Resend"
          data-testid="resend"
          disabled={faxStatus === 'In Progress' || faxStatus === 'Queued'}
          onClick={() => storeFax.resendFax(id)}>
          <i className="bi bi-arrow-clockwise" />
        </IconButton>
        <IconButton
          data-testid="delete"
          title="Delete"
          onClick={() => storeFax.setIdForDelete(id)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const FaxListPage = () => {
  const {
    fetching,
    faxes,
    faxesTotal,
    idForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeFax;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleConfirmDelete = () => {
    storeFax.deleteFax().then((isSucceed) => {
      if (isSucceed) {
        storeFax.getFaxesMain();
      }
    });
  };

  const handleChangeFilter = (filter: FilterModel) => {
    storeFax.setFilter(filter);
    debouncedMethod();
  };

  useEffect(() => {
    storeFax.getFaxesMain();
  }, [pagination]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Fax" />

      <Filter onChange={handleChangeFilter} />

      <Grid
        id="fax_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={faxes}
        dataSourceCount={faxesTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_ORDER_FAX_ADD} />
        }
      />
      {idForDelete ? (
        <DialogConfirm
          onCancel={storeFax.clearIdForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete this Fax entry?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(FaxListPage);
