import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeXRayMaster,
  XRayFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/xRayMaster';
import { URL_XRAY } from 'constant/path/workflow';

interface PropsType extends RouteComponentProps {}

const XRayFrameAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: XRayFormModel) =>
    storeXRayMaster.addXRay(data).then((error) => {
      if (error) return error;
      history.push(URL_XRAY);
    });

  return (
    <>
      <Title title="Add X-Ray Frame" />
      <Form onSubmit={handleSubmit} backUrl={URL_XRAY} />
    </>
  );
};

export default XRayFrameAddPage;
