import { Container } from 'flux/utils';

import StudiesByTechPageActions from 'actions/reports/report-creator/studies-by-tech/StudiesByTechPageActions';
import StudiesByTechPageStore from 'stores/reports/report-creator/studies-by-tech/StudiesByTechPageStore';
import BarChart from 'components/project/common/charts/BarChart';
import AbstractReportPage from '../AbstractReportPage';
import { prettierFilterPayload } from '../filter/prettierFilterPayload';

import { TECH_STUDIES as PAGE_ID } from 'constant/pagesId/reports';

const COLUMNS = [
  { name: 'patientName', title: 'Patient name' },
  { name: 'gender', title: 'Gender' },
  { name: 'dob', title: 'DOB', className: 'text-nowrap' },
  { name: 'mrn', title: 'MRN' },
  { name: 'phone', title: 'Phone' },
  { name: 'email', title: 'Email' },
  { name: 'ssn', title: 'SSN' },
  { name: 'faciName', title: 'Facility name' },
];

export class StudiesByTechPage extends AbstractReportPage {
  static getStores() {
    return [StudiesByTechPageStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = AbstractReportPage.getPageState('studies_by_tech_grid');
      prevState.paginationStrategy = AbstractReportPage.LOGIC_PAGINATION;
    }
    return {
      ...prevState,
      appId: PAGE_ID.PAGE,
      dataSource: StudiesByTechPageStore.getState().studiesByTechList,
      chartValues: StudiesByTechPageStore.getState().studiesByTechDiagram,
    };
  }

  getTitle() {
    return 'Studies by Tech';
  }

  getColumns() {
    return COLUMNS;
  }

  getGridID() {
    return 'studies_by_tech_grid';
  }

  getExportedFileName() {
    return 'studies_by_tech';
  }

  getClass() {
    return 'studies-by-tech-overview-page';
  }

  getShownFilters() {
    return [
      'patientFirstName',
      'patientLastName',
      'dob',
      'mrn',
      'facility',
      'diagnosis',
      'cpt',
      'facilityNPI',
      'studyID',
      'dos',
      'dispatchedDate',
      'technicianFirstName',
      'technicianLastName',
      'examCompletedDate',
      'corporate',
    ];
  }

  renderChart() {
    return <BarChart data={this.state.chartValues} />;
  }

  loadListAction(conditionList, joint) {
    const payload = prettierFilterPayload(conditionList);

    return StudiesByTechPageActions.loadStudiesByTechList(payload, joint);
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }

  getActions() {
    return StudiesByTechPageActions;
  }
}

const studiesByTechPage = Container.create(StudiesByTechPage);
export default studiesByTechPage;
