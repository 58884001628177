import UserTypeService, { TUserType } from 'services/project/UserTypeService';
import { TUserTypeEnum } from 'services/project/UserSelectionService';
import appDispatcher from 'dispatcher/AppDispatcher';
import { Actions } from 'stores/project/UserTypeStore';

export default class UserTypeActions {
  static loadUserTypeList(
    userType: TUserTypeEnum = null,
    flagCompanyUsersOnly: boolean = null
  ): Promise<Array<TUserType>> {
    return UserTypeService.loadUserTypeList(
      userType,
      flagCompanyUsersOnly
    ).then((response: Array<TUserType>) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_USER_TYPE_LIST_ACTION,
        data: response,
      });
      return response;
    });
  }
}
