import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';
import getColumns from './components/Columns';

import { storePayerInfo } from 'stores/_mobx/systemSetup/masterSetting/payerInfo';
import AccessUtils from 'utils/AccessUtils';
import { MS_PAYER_INFO as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_PAYER_INFO_ADD,
} from 'constant/path/systemSetup';

const PayerInfoListPage = () => {
  const {
    fetching,
    payerList,
    payerTotal,
    search,
    idForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storePayerInfo;

  const debouncedMethod = useDebounce(setPaginationToStart);

  const columns = useMemo(getColumns, []);

  const handleChangeFilter = (search: string) => {
    storePayerInfo.setFilter(search);
    debouncedMethod();
  };

  const handleDeleteApprove = () => {
    storePayerInfo.deletePayerInfo().then((isSucceed) => {
      if (isSucceed) storePayerInfo.getPayerMain();
    });
  };

  useEffect(() => {
    storePayerInfo.getPayerMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Payer Information">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Payer name"
        className="col-md-6 col-lg-5 col-xl-4"
        value={search}
        onChange={handleChangeFilter}
      />

      <Grid
        id="payer_info_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={payerList}
        dataSourceCount={payerTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          AccessUtils.checkAccess(PAGE_ID.ADD) && (
            <GridControlButton title="Add" url={URL_PAYER_INFO_ADD} />
          )
        }
      />

      {idForDelete ? (
        <DialogConfirm
          onCancel={storePayerInfo.clearIdForDelete}
          onApprove={handleDeleteApprove}>
          Are you sure you want to delete this Payer information?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(PayerInfoListPage);
