import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Input } from 'components/form/textField';

export const defaultValues: FormModel = {
  groupName: '',
  groupDescription: '',
};

export interface FormModel {
  groupName: string;
  groupDescription: string;
}

interface PropsType {
  onChange: (filter: FormModel) => void;
}

const FilterGroupTab = ({ onChange }: PropsType) => {
  const { control, watch } = useForm<FormModel>({ defaultValues });

  useEffect(() => {
    const subscription = watch((formValue) => {
      onChange(formValue as Required<FormModel>);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className="row">
      <Input
        className="col-md-6"
        name="groupName"
        placeholder="Group name"
        control={control}
      />
      <Input
        className="col-md-6"
        name="groupDescription"
        placeholder="Group description"
        control={control}
      />
    </form>
  );
};

export default FilterGroupTab;
