import React from 'react';

import Tabs from 'components/tabs';
import EmployeeTimeSheetTab from './EmployeeTimeSheetTab';
import HoursByEmployeeTypeTab from './HoursByEmployeeTab';
import HRAdminTab from './HRAdminTab';
import UserProfileStore from 'stores/UserProfileStore';
import SecurityPermissionActions from 'actions/SecurityPermissionActions';
import UrlUtils from 'utils/UrlUtils';

import { URL_EMPLOYEE_TIMESHEET } from 'constant/path/report';

class EmployeeTimeSheetPage extends React.Component {
  state = {
    userHasPermission: true,
  };

  componentDidMount() {
    const userId = UserProfileStore.getUserId();
    SecurityPermissionActions.userHasHrAdminPermission(userId).then(
      (userHasPermission) => {
        if (!userHasPermission && UrlUtils.getQuery('tab') === 'HR') {
          this.props.history.push({ search: '?tab=PR' });
        }
        this.setState({ userHasPermission });
      }
    );
  }

  render() {
    const { userHasPermission } = this.state;

    const filteredTabsList = tabsList.filter(({ hrAdmin }) =>
      hrAdmin ? userHasPermission : true
    );

    return (
      <div className="pt-4">
        <Tabs tabsList={filteredTabsList}>
          <EmployeeTimeSheetTab />
          <HoursByEmployeeTypeTab />
          {userHasPermission ? <HRAdminTab /> : null}
        </Tabs>
      </div>
    );
  }
}

const tabsList = [
  { label: 'Payroll Report', url: `${URL_EMPLOYEE_TIMESHEET}?tab=PR` },
  { label: 'Hours by Employee', url: `${URL_EMPLOYEE_TIMESHEET}?tab=HE` },
  {
    label: 'HR Admin',
    url: `${URL_EMPLOYEE_TIMESHEET}?tab=HR`,
    hrAdmin: true,
  },
];

export default EmployeeTimeSheetPage;
