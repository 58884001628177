import { apiRequest } from 'services/RequestService';

class ProcedureSummaryPageService {
  createPdf(dataSource, formatDescription) {
    return apiRequest({
      url: 'pdfcreater.OrderPdfGenerator.GetsumProcedcodePDF',
      data: { dataSource, formatDescription },
    });
  }

  getSumProcedCodeReport(
    conditionList,
    joint,
    startIndex,
    limit,
    oldListCount
  ) {
    const data = [conditionList, joint, startIndex, limit, oldListCount];

    return apiRequest({
      url: 'report.ReportMaster.getSumProceedCodeReport',
      data,
    }).then((response) => {
      return response;
    });
  }
}

export default new ProcedureSummaryPageService();
