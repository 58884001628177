export const URL_EXAM_INFO = '/patient-records/exam-information';
export const URL_EXAM_INFO_QA_TAB = `${URL_EXAM_INFO}/exam-qa`;
export const URL_EXAM_INFO_EXAM_TAB = `${URL_EXAM_INFO}/exam-info`;
export const URL_DOCUMENT_OVERVIEW = `${URL_EXAM_INFO_EXAM_TAB}/document-overview/:orderId/:patientId`;

export const URL_PATIENT_DEMOGRAPHIC = '/patient-records/patient-demographics';
export const URL_PATIENT_DEMOGRAPHIC_ADD = `${URL_PATIENT_DEMOGRAPHIC}/add`;
export const URL_PATIENT_DEMOGRAPHIC_EDIT = `${URL_PATIENT_DEMOGRAPHIC}/edit/:id`;

export const URL_DOC_MANAGER = '/retention/document-manager';
export const URL_DOC_MANAGER_ACQUISITION = `${URL_DOC_MANAGER}/acquisition`;
export const URL_DOC_MANAGER_AUDIT = `${URL_DOC_MANAGER}/audit`;

export const URL_LEGACY_PATIENT_DATA = '/patient-records/legacy-manager';
