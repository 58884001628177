import { makeObservable, observable, action, computed } from 'mobx';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { getDateRangeBounds, timeZoneRender } from 'utils/DateUtils';

const defaultFilterValue: FilterType = {
  corporateId: 0,
  dosStart: '',
  dosEnd: '',
  period: 'A',
  facilityId: 0,
  patientFirstName: '',
  patientLastName: '',
  priorityId: 0,
  technologistId: 0,
  timezone: '',
};

export interface FilterType {
  corporateId: number;
  dosStart: string;
  dosEnd: string;
  period: string;
  facilityId: number;
  patientFirstName: string;
  patientLastName: string;
  priorityId: number;
  technologistId: number;
  timezone: string;
}

interface LogType {
  NoofPat: string;
  Totaltime: string;
  completed_date: string;
  cpt: string;
  dispatched_date: string;
  dos: string;
  faci_name: string;
  patientfirstname: string;
  patientlastname: string;
  patientname: string;
  priority: string;
  proc_count: number;
  techname: string;
}

interface ChartType {
  CompOrders: number;
  techname: string;
}

interface LogResponseType {
  chartval: ChartType[];
  dataval: LogType[];
  count: number;
}

class TechReviewExam {
  fetching: boolean = false;
  logList: LogType[] = [];
  logTotal: number = 0;
  chartData: [string, number | string][] = [];
  filter: FilterType = defaultFilterValue;

  page: Pagination = new Pagination({ id: 'tech_review_exams_gridId' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      logList: observable,
      logTotal: observable,
      chartData: observable,
      filter: observable,

      finalLogList: computed,

      setFetching: action,
      setLogsData: action,
      setFilter: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setLogsData(props: {
    logList: LogType[];
    logTotal: number;
    chartData: [string, number | string][];
  }) {
    this.logList = props.logList;
    this.logTotal = props.logTotal;
    this.chartData = props.chartData;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setDefaultFilter() {
    const filter = {
      ...this.filter,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facilityId,
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const filter = {
      ...defaultFilterValue,
      facilityId: UserProfileStore.getFacilityId() || 0,
    };

    return filter;
  }

  get finalLogList() {
    const { timezone } = this.filter;

    const finalList = this.logList.map((log) => ({
      ...log,
      dos: timeZoneRender(log.dos, timezone || '', true),
      dispatched_date: timeZoneRender(log.dispatched_date, timezone || ''),
      completed_date: timeZoneRender(log.completed_date, timezone || ''),
    }));

    return finalList;
  }

  async getLogData() {
    const {
      filter,
      page: { pagination },
    } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const data = {
      skip: pagination.skip,
      pageSize: pagination.pageSize,
      technologist: filter.technologistId || null,
      patientLastName: filter.patientLastName,
      patientFirstName: filter.patientFirstName,
      facility: filter.facilityId || 0,
      corporate: filter.corporateId || null,
      dosFrom: dos.dateFrom,
      dosTo: dos.dateTo,
      priority: filter.priorityId || null,
    };

    this.setFetching(true);
    try {
      const { chartval, count, dataval } = await apiRequest<LogResponseType>({
        url: 'report.ReportMaster.GetReviewByTech',
        data,
      });

      const chartData = chartval.reduce(
        (prev, { CompOrders, techname }) =>
          prev.concat([[techname, Number(CompOrders)]]),
        [['Technologist Name', 'Orders count']] as [string, string | number][]
      );

      this.setLogsData({
        logList: dataval,
        logTotal: count,
        chartData,
      });
    } catch (e: any) {
      this.setLogsData({
        logList: [],
        logTotal: 0,
        chartData: [],
      });
    } finally {
      this.setFetching(false);
    }
  }

  generatePdf = async ({ filter }: { filter: string }) => {
    const data = {
      dataSource: this.finalLogList,
      filter,
    };
    try {
      const response = await apiRequest<string>({
        url: 'pdfcreater.OrderPdfGenerator.GeneratePDFforTech',
        data,
      });
      return response;
    } catch (e: any) {
      return '';
    }
  };

  prepareFilterForExport() {
    const { filter } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return {
      ...filter,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
    };
  }
}

export const storeTechReviewExam = new TechReviewExam();
