import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { InputCalendar } from 'components/form/inputCalendar';
import { Textarea } from 'components/form/textarea';
import { Button } from 'components/button';
import resolver from './validation';

export interface FormModel {
  refid?: number;
  glove_number: string;
  lastsurvey: string;
  nextsurvey: string;
  description: string;
  glove_type: string;
}
const initialValues: FormModel = {
  glove_number: '',
  lastsurvey: '',
  nextsurvey: '',
  description: '',
  glove_type: '',
};

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: (data: FormModel) => void;
}

const ModifyGloveForm = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
  } = useForm({ defaultValues, resolver });

  const cn = `col-md-10 col-lg-8 col-xl-6${isSubmitting ? ' on-ajax' : ''}`;

  const handleReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form className={cn} onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="glove_number"
        label="Glove number"
        required
        control={control}
      />
      <div className="row">
        <InputCalendar
          name="lastsurvey"
          label="Last survey date"
          placeholder="dd/mm/yyyy"
          className="col-sm-6"
          control={control}
        />
        <InputCalendar
          name="nextsurvey"
          label="Next survey date"
          placeholder="dd/mm/yyyy"
          className="col-sm-6"
          control={control}
        />
      </div>
      <Textarea name="description" label="Description" control={control} />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          type="reset"
          onClick={handleReset}
          disabled={isSubmitting}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default ModifyGloveForm;
