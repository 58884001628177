import { forwardRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';
import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from 'components/project/dropdown/AbstractDropdown';

import {
  storeDropdownFacility,
  OptionType,
} from 'stores/_mobx/dropDown/facility';

interface PropsType extends PAbstractDropdown {
  options: OptionType[];
  ref: any;
  perDiemOnly?: boolean;
}

class FacilityDropdown extends AbstractDropdown<PropsType, SAbstractDropdown> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<FacilityDropdown, any>(
  ({ perDiemOnly, ...props }: PropsType, ref) => {
    const { options, fetching } = storeDropdownFacility;

    useEffect(() => {
      const searchParams =
        perDiemOnly !== undefined ? { perDiemOnly } : undefined;

      storeDropdownFacility.getOptionsFacility(searchParams);
    }, [perDiemOnly]);

    return (
      <FacilityDropdown
        {...props}
        ref={ref}
        options={options}
        fetching={fetching}
      />
    );
  }
);

export default observer(DropDownMiddleware);

interface PropsTypeNew extends Omit<SelectPropsType, 'options'> {
  perDiemOnly?: boolean;
}

export const DropDownFacility = observer(
  ({ perDiemOnly, ...props }: PropsTypeNew) => {
    const { fetching, options } = storeDropdownFacility;

    useEffect(() => {
      const searchParams =
        perDiemOnly !== undefined ? { perDiemOnly } : undefined;

      if (searchParams || !options.length)
        storeDropdownFacility.getOptionsFacility();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Select {...props} options={options} isLoading={fetching} />;
  }
);

export const DropDownFacilityPublic = observer((props: PropsTypeNew) => {
  const { fetching, options } = storeDropdownFacility;

  useEffect(() => {
    storeDropdownFacility.getOptionsFacilityPublic();
  }, []);

  return <Select {...props} options={options} isLoading={fetching} />;
});
