import { Control } from 'react-hook-form';

import { DropDownStateShortName } from 'components/project/dropdown/StateShortName';
import DropdownMobileOperator from 'components/project/dropdown/MobileOperator';
import { OptionType } from 'components/form/input/Select';
import { Textarea } from 'components/form/textarea';
import { Input } from 'components/form/textField';
import { Radio as RadioField } from 'components/form/radio';
import MaskInput from 'components/form/maskInput';
import { CheckboxGroup } from 'components/form/checkbox';

import { ProfileDetailsType } from 'stores/_mobx/profile/myProfile';

const MESSAGING_OPTIONS: Array<OptionType> = [
  { label: 'Internal', value: 'I' },
  { label: 'External', value: 'E' },
  { label: 'Both', value: 'B' },
  { label: 'None', value: 'N' },
];

interface PropsType {
  emailTemplatesOption: OptionType[];
  details: ProfileDetailsType;
  control: Control<ProfileDetailsType>;
}

const ContactDetails = ({
  control,
  details,
  emailTemplatesOption,
}: PropsType) => (
  <>
    <div className="row">
      <Input
        name="home_address"
        label="Address"
        className="col-md-6"
        control={control}
      />
      <Input
        name="homecity"
        label="City"
        className="col-md-6"
        control={control}
      />
      <DropDownStateShortName
        name="homestate"
        label="State"
        className="col-md-6"
        control={control}
      />
      <MaskInput
        format="#####-####"
        name="homezipcode"
        label="Zip Code"
        className="col-md-6"
        control={control}
      />
      <MaskInput
        format="###-###-####"
        label="Phone"
        name="home_phone"
        className="col-md-6"
        control={control}
      />
      <MaskInput
        format="###-###-####"
        label="Mobile"
        name="cell"
        className="col-md-3"
        control={control}
      />
      <DropdownMobileOperator
        name="mobileop_id"
        label="Mobile Operator"
        className="col-md-3"
        control={control}
      />

      <Input
        name="email"
        label="Email"
        className="col-md-6"
        required
        control={control}
      />
      <MaskInput
        format="###-###-####"
        label="Fax"
        name="fax"
        className="col-md-6"
        control={control}
      />
      <Input
        name="emergency_name"
        label="Emergency Name"
        className="col-md-6"
        control={control}
      />
      <MaskInput
        format="###-###-####"
        label="Emergency Phone"
        name="emergency_phone"
        className="col-md-6"
        control={control}
      />
      <Input
        name="spouse_name"
        label="Spouse Name"
        className="col-md-6"
        control={control}
      />
      <Input
        name="linkid"
        label="LinkedIn ID Name"
        className="col-md-6"
        control={control}
      />

      <div className="unmodifiable m-15">
        <ul className="row">
          <li className="col-sm-6">
            User Designation: {details?.designationName}
          </li>
          <li className="col-sm-6">
            Social Security Number: {details?.ssn || 'None'}
          </li>
        </ul>
      </div>

      <RadioField
        name="mail_type"
        label="Mail Type"
        className="inline"
        options={MESSAGING_OPTIONS}
        control={control}
      />
    </div>

    {emailTemplatesOption.length ? (
      <CheckboxGroup
        name="emailTemplates"
        label="Templates"
        options={emailTemplatesOption}
        control={control}
      />
    ) : (
      <div className="mb-3">
        <b>Templates:</b>
        <span> No email templates for this user type.</span>
      </div>
    )}

    <Textarea
      name="comment"
      label="Comment"
      className="col-sm-12"
      rows={5}
      control={control}
    />
  </>
);

export default ContactDetails;
