import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as PropsTypeSelect,
} from 'components/form/select/Select';

import { storeRejectReason } from 'stores/_mobx/systemSetup/masterSetting/rejectReason';

interface PropsType extends Omit<PropsTypeSelect, 'options'> {}

const DropdownRejectReasons = (props: PropsType) => {
  const { fetchingOptions, options } = storeRejectReason;

  useEffect(() => {
    storeRejectReason.getOptions();
  }, []);

  return <Select {...props} options={options} isLoading={fetchingOptions} />;
};

export default observer(DropdownRejectReasons);
