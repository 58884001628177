import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import ProviderTypeForm from './components/Form';

import {
  storeProviderType,
  ProviderFormModel,
} from 'stores/_mobx/systemSetup/masterSetting/providerType';
import { URL_PROVIDER_TYPE } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const ProviderTypeAddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: ProviderFormModel) =>
    storeProviderType.addProviderType(payload).then((error) => {
      if (error) return error;

      history.push(URL_PROVIDER_TYPE);
    });

  return (
    <>
      <Title title="Add Provider Type" />
      <ProviderTypeForm backUrl={URL_PROVIDER_TYPE} onSubmit={handleSubmit} />
    </>
  );
};

export default ProviderTypeAddPage;
