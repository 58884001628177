import React from 'react';
import { Container } from 'flux/utils';

import { ExamExportLink } from 'page/components/ExportLink';
import { FakeInput } from 'components/form/textField';
import { Grid } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';
import DialogPrompt from '../DialogPrompt';

import ClaimActions from 'actions/billing-coding/claims/ClaimActions';
import { TExamCodeDetail } from 'services/billing-coding/claims/ClaimsService';
import ClaimStore from 'stores/billing-coding/claims/ClaimStore';

export class TOrder4ExamCode {
  refid: number = null;
  Studyid: number = null;
}

interface PropsType {
  order: TOrder4ExamCode;
  hideLeftGrid?: boolean;
  calcelBill?: boolean;
  cancelStatus: 'V' | 'N' | '';
  onClose: () => void;
}

class SExamCode {
  data: TExamCodeDetail = new TExamCodeDetail();
  loaded: boolean = false;
  cancelBill: boolean = false;
  reasonForCancel: boolean = false;
  reasonForCancelText: string = '';
}

class ExamCode extends React.Component<PropsType, SExamCode> {
  static getStores() {
    return [ClaimStore];
  }

  static calculateState(prevState: SExamCode) {
    if (!prevState) {
      prevState = Object.assign(new SExamCode(), {
        data: {},
        loaded: false,
        cancelBill: false,
        reasonForCancel: false,
        reasonForCancelText: '',
      });
    }
    const store = ClaimStore.getState();
    return Object.assign({}, prevState, {
      data:
        !prevState.loaded && store.examCodeLoaded
          ? store.examCode
          : prevState.data,
      loaded: store.examCodeLoaded,
    });
  }

  CPT = [
    {
      name: 'cpt_code',
      title: 'CPT Codes',
      render: (v: any) => (this.props.hideLeftGrid ? `${v} -26` : v),
    },
    { name: 'cptcount', title: 'Units' },
    { name: 'icd_code', title: 'ICD Pointer' },
  ];
  HCPC = [
    {
      name: 'cpt_code',
      title: 'HCPC',
      render: (v: any) => (this.props.hideLeftGrid ? `${v} -26` : v),
    },
    { name: 'cptcount', title: 'Units' },
    { name: 'icd_code', title: 'ICD Pointer' },
  ];

  handleCloseDialogCancelingOrder = () => {
    this.setState({ reasonForCancel: false });
  };

  reasonForCancel = (value: string) => {
    const { order, cancelStatus, onClose } = this.props;

    this.handleCloseDialogCancelingOrder();
    ClaimActions.saveCancelReason(order.refid, value, cancelStatus).then(() =>
      onClose()
    );
  };

  handleCloseConfirmDialog = () => {
    this.setState({ cancelBill: false, reasonForCancel: false });
  };
  cancelBill = () => {
    this.setState({ cancelBill: false, reasonForCancel: true });
  };

  componentDidMount() {
    const { refid, Studyid } = this.props.order;
    const id = Number(refid) || Number(Studyid);

    ClaimActions.getExamCodeDetails(id);
  }

  componentWillUnmount() {
    ClaimActions.clearExamCodeDetails();
  }

  render() {
    const { state, props } = this;
    const model = state.data || new TExamCodeDetail();
    const order = props.order;
    return (
      <>
        <Dialog size="extraLarge" handleClose={props.onClose}>
          <DialogHeader title="Exam Code" onClose={props.onClose} />
          <DialogBody>
            <div className="row">
              <FakeInput
                className="col-md-6"
                label="Last Name"
                value={model.lastname}
              />
              <FakeInput className="col-md-6" label="DOB" value={model.dob} />
              <FakeInput
                className="col-md-6"
                label="First Name"
                value={model.firstname}
              />
              <FakeInput className="col-md-6" label="DOS" value={model.dos} />
              <FakeInput
                className="col-md-6"
                label="Facility POS"
                value={model.pos}
              />
              <FakeInput
                className="col-md-6"
                label="Facility Type"
                value={model.facilitytype}
              />
              <FakeInput
                className="col-md-6"
                label="Facility"
                value={model.facility_name}
              />
              <FakeInput
                className="col-md-6"
                label="Modality"
                value={model.modality}
              />
            </div>
            <div className="row">
              {!this.props.hideLeftGrid ? (
                <Grid
                  columns={this.HCPC}
                  dataSource={model.qrcode || []}
                  wrapperClass="col-md-6"
                  disablePagination
                  stateless
                  hideSwitcher
                />
              ) : null}
              <Grid
                columns={this.CPT}
                dataSource={model.othercpt || []}
                wrapperClass={this.props.hideLeftGrid ? '' : 'col-md-6'}
                disablePagination
                stateless
                hideSwitcher
              />
            </div>
            <p>Reason for exam: {model.comment || '--'}</p>
            <p>
              <ExamExportLink
                orderId={
                  order.refid
                    ? order.refid
                    : order.Studyid
                    ? order.Studyid
                    : null
                }
              />
            </p>
          </DialogBody>
        </Dialog>

        {state.cancelBill && (
          <DialogConfirm
            onApprove={this.cancelBill}
            onCancel={this.handleCloseConfirmDialog}>
            Are you sure, you want to cancel the order from billing section?
          </DialogConfirm>
        )}
        {state.reasonForCancel && (
          <DialogPrompt
            title="Cancel Order"
            label="Reason for cancel"
            defaultValue={this.state.reasonForCancelText}
            onSubmit={this.reasonForCancel}
            onClose={this.handleCloseDialogCancelingOrder}
          />
        )}
      </>
    );
  }
}

export default Container.create(ExamCode);
