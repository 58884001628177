export const URL_FINDING_CODING = '/claim-management/finding-coding';
export const URL_INSURANCE_VERIFICATION =
  '/claim-management/insurance-verification';
export const URL_ELIGIBILITY_MANAGER_PAGE =
  '/claim-management/eligibility-manager';
export const URL_BILLING_ASSIGNMENT = '/claim-management/billing-assignment';
export const URL_TRANSMIT_UNASSIGNED =
  '/claim-management/transmit-unassignment';
export const URL_CLAIM_MANAGER = '/claim-management/claim-manager';
export const URL_FACILITY_PREVIEW = '/claim-management/facility-preview';
export const URL_CLAIM_REVIEW = '/claim-management/claim-review-release';
export const URL_CODING = '/claim-management/coding';
export const URL_CODING_DETAIL = `${URL_CODING}/:id`;

export const URL_LEDGER = '/accounts-receivable/ledger';
export const URL_AR_AGING = '/accounts-receivable/ar-aging';
export const URL_AR_AGING_EDIT = `${URL_AR_AGING}/facility/:id`;

export const URL_INVOICES = '/invoices/invoice';
export const URL_PRE_INVOICE = '/invoices/pre-invoice';

export const URL_PRO_FSS_REPORT = '/professional-group-billing/pro-ffs-reports';
export const URL_PRO_GROUP_BILLING =
  '/professional-group-billing/professional-group-billing';
