import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import resolver from './validation';

const initialValue: FormModel = {
  prefix_nm: '',
  prefix_desc: '',
};

export interface FormModel {
  refid?: number;
  prefix_nm: string;
  prefix_desc: string;
}

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormPrefix = ({
  backUrl,
  defaultValues = initialValue,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm({ defaultValues, resolver });

  const cn = `col-md-8 col-lg-6${isSubmitting ? ' on-ajax' : ''}`;

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: FormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('prefix_nm', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form className={cn} onSubmit={handleClickSubmit}>
      <Input name="prefix_nm" label="Prefix name" required control={control} />
      <Input
        name="prefix_desc"
        label="Prefix Description"
        required
        control={control}
      />
      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={!isDirty || isSubmitting}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormPrefix;
