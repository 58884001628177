import React, { useCallback, useRef } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { format } from 'date-fns';
import clsx from 'clsx';

import CustomInput from './CustomInputField';
import { strToDate } from 'utils/DateUtils';

const convertToDateString = (value: Date | null, isUTC?: boolean) => {
  if (value) {
    const date = isUTC
      ? format(value, 'yyyy-MM-dd')
      : format(value, 'MM/dd/yyyy');

    return isUTC ? `${date}T00:00:00.000Z` : date;
  }
  return '';
};

const prepareValueToDatepicker = (value?: string | Date) => {
  if (!value) return null;
  return value instanceof Date ? value : strToDate(value);
};

export interface PureInputCalendarPropsType
  extends Omit<ReactDatePickerProps, 'onChange'> {
  name?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  errorMessage?: string;
  innerRef?: React.Ref<HTMLInputElement>;
  isUTC?: boolean;
  'data-testid'?: string;
  onChange?: (value: any) => void;
}

const PureInputCalendar = React.forwardRef<
  HTMLInputElement,
  PureInputCalendarPropsType
>(
  (
    {
      name,
      required = false,
      label = '',
      className,
      errorMessage,
      'data-testid': dataTestId,
      innerRef,
      placeholder = 'MM/DD/YYYY',
      value,
      isUTC,
      onChange,
      ...rest
    }: PureInputCalendarPropsType,
    ref
  ) => {
    const refCalendar = useRef(null);

    const cn = clsx(
      'formfield-holder',
      'formfield-text',
      { required: required },
      className
    );

    const testAttr = dataTestId || name;

    const keyListener = useCallback((e: KeyboardEvent) => {
      if (e.key === 'Tab') refCalendar.current.setOpen(false);
    }, []);

    const handleCalendarOpen = () => {
      document.addEventListener('keydown', keyListener);
    };

    const handleCalendarClose = () => {
      document.removeEventListener('keydown', keyListener);
    };

    const handleChange = (value: Date) => {
      const prettierValue = convertToDateString(value, isUTC);

      if (onChange) onChange(prettierValue);
    };

    const handleOpenCalendarManually = () => {
      refCalendar.current.setOpen(true);
    };

    return (
      <div className={cn}>
        {label ? (
          <div className="form-label" data-testid={`${testAttr}-label`}>
            <label htmlFor={name}>{label}</label>
          </div>
        ) : null}
        <div className="date-picker__field">
          <DatePicker
            dateFormat="MM/dd/yyyy"
            className="form-control"
            todayButton="Today"
            disabledKeyboardNavigation
            ref={refCalendar}
            placeholderText={placeholder}
            selected={prepareValueToDatepicker(value)}
            {...rest}
            startDate={prepareValueToDatepicker(rest.startDate)}
            endDate={prepareValueToDatepicker(rest.endDate)}
            minDate={prepareValueToDatepicker(rest.minDate)}
            onChange={handleChange}
            onCalendarOpen={handleCalendarOpen}
            onCalendarClose={handleCalendarClose}
            customInput={
              <CustomInput
                data-testid={`${testAttr}-field`}
                preventOpenOnFocus={rest.preventOpenOnFocus}
                setOpen={refCalendar.current?.setOpen}
                onClick={handleOpenCalendarManually}
              />
            }
          />
        </div>
        {errorMessage && (
          <div className="error" data-testid={`${testAttr}-error`}>
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

export default PureInputCalendar;
