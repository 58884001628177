import { Container } from 'flux/utils';

import ProcedureCodeCountPageActions from 'actions/reports/report-creator/procedure-code-count/ProcedureCodeCountPageActions';
import ProcedureCodeCountPageStore from 'stores/reports/report-creator/procedure-code-count/ProcedureCodeCountPageStore';
import PieChart from 'components/project/common/charts/PieChart';
import AbstractReportPage from '../AbstractReportPage';
import { PROCEDURE_CODE_COUNT as PAGE_ID } from 'constant/pagesId/reports';

const COLUMNS = [
  { name: 'cpt', title: 'Procedure code' },
  { name: 'cptdescription', title: 'Procedure code description' },
  { name: 'cpt_category', title: 'CPT Category' },
  { name: 'count', title: 'Procedure count' },
];

export class ProcedureCodeCountPage extends AbstractReportPage {
  static getStores() {
    return [ProcedureCodeCountPageStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = AbstractReportPage.getPageState('procedure_code_count_grid');
      prevState.exporterName = 'ProcedureCodeCountExporter';
      prevState.paginationStrategy = AbstractReportPage.LOGIC_PAGINATION;
    }
    return {
      ...prevState,
      appId: PAGE_ID.PAGE,
      dataSource: ProcedureCodeCountPageStore.getState().procedureCodeCountList,
      chartValues:
        ProcedureCodeCountPageStore.getState().procedureCodeCountDiagram,
    };
  }

  getShownFilters() {
    return ['cpt', 'cptCategory', 'cptDescription'];
  }

  getTitle() {
    return 'Procedure Code Count';
  }

  getColumns() {
    return COLUMNS;
  }

  getGridID() {
    return 'procedure_code_count_grid';
  }

  getExportedFileName() {
    return 'procedure_code_count';
  }

  getClass() {
    return 'procedure-code-count-overview-page';
  }

  renderChart() {
    return <PieChart data={this.state.chartValues} />;
  }

  loadListAction(conditionList, joint) {
    return ProcedureCodeCountPageActions.loadProcedureCodeCountList(
      conditionList,
      joint
    );
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }

  getActions() {
    return ProcedureCodeCountPageActions;
  }
}

const procedureCodeCountPage = Container.create(ProcedureCodeCountPage);
export default procedureCodeCountPage;
