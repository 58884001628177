import { TPagination } from 'components/grid/GridTypes';

import appDispatcher from 'dispatcher/AppDispatcher';
import ExternalService from 'services/ExternalService';
import { apiRequest } from 'services/RequestService';

import { dateToLocalTimezone } from 'utils/DateUtils';

interface OptionType {
  label: string;
  value: number;
}

export class SendingApplicationFilter {
  appSn: string = '';
  namespace: string = '';
  universalIdType: number = null;
  communication: string = '';
}

export class UniversalIdType {
  code: string = '';
  name: string = '';
}

export class SpecificRuleDto {
  id: number = null;
  name: string = '';
  value: string = '';
}

export class SftpAccessDto {
  username: string = '';
  password: string = '';
  path: string = '';
  host: string = '';
  port: number = null;
  id: number = null;
  useFor: 'out' | 'in' | 'both' = null;
}

export class SendingApplicationFormDto {
  id: number = null;
  namespace: string = '';
  universalId: string = '';
  responseUrl: string = '';
  universalIdType: number = null;
  universalIdTypeEntity: UniversalIdType = new UniversalIdType();
  username: string = '';
  userPassword: string = '';
  isSftp: boolean = false;
  active: boolean = false;
  isSubscriber: boolean = false;
  sftpAccess: Array<SftpAccessDto> = [];
  owner: boolean = false;
  specificRules: Array<SpecificRuleDto> = [];
}

export class TPage<T> {
  total: number = 0;
  content: Array<T> = [];
}

export class OrderRequestForward {
  refId: number = null;
  external: boolean = false;
  sendingApplicationId: number = null;
  message: string = '';
  creationDate: string = '';
  ack: string = '';
  latticeproOrderId: number = null;
  isResponded: boolean = false;
  responseAttempts: number = null;
  ormAckNum: number = null;
  oruAckNum: number = null;
}

export class OrderMessagesDto {
  message: string = '';
  ack: string = '';
}

export default class SendingApplicationsActions {
  static loadApplicationsDropdown(filter: SendingApplicationFilter) {
    return ExternalService.doPost<OptionType[]>(
      'admin/sendingApplication/dropdown',
      { ...filter }
    )
      .then((response) => {
        appDispatcher.dispatch({
          type: 'admin/sendingApplication/dropdown',
          data: response,
        });
        return response;
      })
      .catch(() => {
        appDispatcher.dispatch({
          type: 'admin/sendingApplication/dropdown',
          data: [],
        });
        return [];
      });
  }

  static loadAppSn() {
    return apiRequest<{ app_sn: string }>({
      url: 'usermaster.StaffInfo.getAppSn',
    }).then((response) => {
      appDispatcher.dispatch({
        type: 'admin/sendingApplication/appSn',
        data: response,
      });
      return response;
    });
  }

  static updateData(filter: SendingApplicationFilter, pagination: TPagination) {
    return ExternalService.doPost<TPage<SendingApplicationFormDto>>(
      'admin/ajax/sendingApplication/Overview',
      { ...pagination, ...filter }
    )
      .then((response: any) => {
        const defaultObject = ExternalService.NOT_AUTHORISED;

        const isObjectEqual = Object.keys(defaultObject).every(
          // @ts-ignore
          (key) => defaultObject[key] === response?.[key]
        );
        if (isObjectEqual) {
          response = {
            content: [],
            total: 0,
          };
        }
        appDispatcher.dispatch({
          type: 'admin/ajax/sendingApplication/Overview',
          data: response,
        });
        return response;
      })
      .catch(() => {
        appDispatcher.dispatch({
          type: 'hl7-integration-authorization',
          data: false,
        });
      });
  }

  static changeStatus(application: SendingApplicationFormDto) {
    return ExternalService.doPost<{ status: string }>(
      'admin/sendingApplication/changeStatus',
      application
    );
  }

  static closeLogIn() {
    appDispatcher.dispatch({
      type: 'hl7-integration-not-authorized--close',
    });
  }

  static mapLogins(loginsArray: Array<string>) {
    if (loginsArray && loginsArray.length) {
      return apiRequest({
        url: 'usermaster.StaffInfo.mapLogins',
        data: [loginsArray],
      }).then((response) => {
        appDispatcher.dispatch({
          type: 'admin/ajax/sendingApplication/Overview--names',
          data: response,
        });
        return response;
      });
    }
    return {};
  }

  static loadOne(id: number) {
    return ExternalService.doGet<SendingApplicationFormDto>(
      `admin/application/data/${id}`
    );
  }

  static updateApplication(id: number, model: SendingApplicationFormDto) {
    return ExternalService.doPut<SendingApplicationFormDto>(
      `admin/application/${id}`,
      model
    );
  }

  static loadLatestOru(id: number) {
    return ExternalService.doGet<OrderMessagesDto>(
      `admin/order/${id}/latest_oru`
    );
  }

  static loadLatestOrm(id: number) {
    return ExternalService.doGet<OrderMessagesDto>(
      `admin/order/${id}/latest_orm`
    );
  }

  static loadOrders(id: number, appSn: string, pagination: TPagination) {
    return ExternalService.doGet<TPage<OrderRequestForward>>(
      `admin/orders/application/${appSn}/${id}?page=${
        pagination.page - 1
      }&size=${pagination.pageSize}`
    ).then((response: TPage<OrderRequestForward> | { status: string }) => {
      const page: TPage<OrderRequestForward> =
        response as TPage<OrderRequestForward>;
      const content =
        page?.content?.map((data) => {
          const creationDate = dateToLocalTimezone({ date: data.creationDate });

          return {
            ...data,
            creationDate,
          };
        }) || [];

      appDispatcher.dispatch({
        type: 'admin/orders/application/id',
        data: {
          content,
          total: page?.total || 0,
        },
      });
      return response;
    });
  }
}
