import { apiRequest } from 'services/RequestService';
import { normalizeText } from 'utils/StringUtils';
import { BASE_URL_FILE_DIR } from 'constant/config';
import { downloadFile } from 'utils/downloadFile';

export default class AllDsExporter {
  type: string;
  exporterName: string;
  columns: Array<any>;
  filters: any;
  filterString: string;
  idsForExport?: number[];

  /**
   * @param columns array of objects, applicable to Grid columns property
   * @param type string, one of [pdf, csv, xlsx]
   * @param exporterName string, exporter class name
   * @param filters mixed, arguments, that will be used as filters
   * @param filterString string, arguments, that will be shown above data
   * @param idsForExport Array of ids for export. This param only for export Facilities
   */
  constructor(
    columns: Array<any>,
    type: string,
    exporterName: string,
    filters: any,
    filterString: string,
    idsForExport?: number[]
  ) {
    if (!type) {
      throw new Error('type is required for AllDsExporter');
    }
    if (!exporterName) {
      throw new Error('exporterName is required for AllDsExporter');
    }
    if (!columns) {
      throw new Error('columns is required for AllDsExporter');
    }
    this.type = type;
    this.exporterName = exporterName;
    this.columns = columns.map(({ title, id, name, ...rest }) => {
      let cellTitle = title;
      if (title === undefined) {
        cellTitle = normalizeText(name);
      }
      return { ...rest, title: cellTitle, name: id || name };
    });
    this.filterString = filterString;
    this.filters = filters;
    this.idsForExport = idsForExport;
  }

  exportAndDownload(fileName: string) {
    const filter =
      typeof this.filters === 'function' ? this.filters() : this.filters;

    const data = {
      fileName,
      type: this.type,
      columns: this.columns,
      filters: filter,
      filterString: this.filterString,
      ...(this.idsForExport ? { ids: this.idsForExport } : {}),
    };

    return apiRequest<string>({
      url: `export.exporters.${this.exporterName}.export`,
      data,
    }).then((res: string) => {
      const link = res.replace(/^\//, '');
      if (link) {
        downloadFile(`${BASE_URL_FILE_DIR}${link}`, this.type === 'pdf');
      } else {
        throw Error('');
      }
    });
  }
}
