import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

const filterDefaultValue: FilterModel = {
  userType: 0,
  name: '',
};

interface DesignationType {
  logout_time: number;
  refid: number;
  usertype: string;
  usertype_desc: string;
  usertype_name: string;
}

interface DesignationResponseType {
  category_id: string;
  logout_time: string;
  refid: string;
  type: string;
  usertype: string;
  usertype_desc: string;
  usertype_name: string;
}

export interface DesignationEditType {
  categoryId: number;
  logoutTime: number;
  id: number;
  privilegeId: string;
  userType: number;
  description: string;
  name: string;
}

export interface FilterModel {
  userType: 0 | 1;
  name: string;
}

class Designation {
  fetching: boolean = false;
  designationList: DesignationType[] = [];
  designationTotal: number = 0;
  designation?: DesignationEditType;

  filter: FilterModel = filterDefaultValue;
  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'designationGrid' });

    makeObservable(this, {
      fetching: observable,
      designationList: observable,
      designationTotal: observable,
      designation: observable,
      filter: observable,

      setFetching: action,
      setDesignationList: action,
      setDesignationTotal: action,
      setDesignation: action,
      setFilter: action,
      clearDesignation: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setDesignationList(list: DesignationType[]) {
    this.designationList = list;
  }

  setDesignationTotal(count: number) {
    this.designationTotal = count;
  }

  setDesignation(designation?: DesignationEditType) {
    this.designation = designation;
  }

  setFilter(filter: FilterModel) {
    this.filter = filter;
  }

  clearDesignation() {
    this.designation = undefined;
  }

  async getDesignationList(filter: FilterModel) {
    const { pagination } = this.page;

    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.name,
      filter.userType,
    ];

    try {
      const response = await apiRequest<'SE' | DesignationType[]>({
        url: 'generalmaster.Designation.userTypeList',
        data,
      });
      if (Array.isArray(response)) {
        const designations = response.map((designation) => ({
          ...designation,
          refid: Number(designation.refid),
        }));
        this.setDesignationList(designations);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setDesignationList([]);
    }
  }

  async getDesignationCount(filter: FilterModel) {
    try {
      const count = await apiRequest<'SE' | 'S' | number>({
        url: 'generalmaster.Designation.TotalRecordCount',
        data: [filter.name, filter.userType],
      });
      this.setDesignationTotal(Number(count) || 0);
    } catch (e) {
      this.setDesignationTotal(0);
    }
  }

  getDesignationListMain = () => {
    this.setFetching(true);

    const { filter } = this;

    const promiseList = this.getDesignationList(filter);

    const promiseCount = this.getDesignationCount(filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getDesignation(id: number) {
    this.setFetching(true);

    try {
      const [response] = await apiRequest<[DesignationResponseType]>({
        url: 'generalmaster.Designation.viewUserType',
        data: [id],
      });

      const designation = {
        description: response.usertype_desc,
        name: response.usertype_name,
        categoryId: Number(response.category_id),
        logoutTime: Number(response.logout_time),
        userType: Number(response.type),
        privilegeId: response.usertype,
        id: Number(id),
      };

      this.setDesignation(designation);
      return designation;
    } catch (e) {
      this.setDesignation();
      return null;
    } finally {
      this.setFetching(false);
    }
  }

  async addDesignation(payload: DesignationEditType) {
    const data = [
      payload.name,
      payload.userType,
      '',
      payload.description,
      payload.privilegeId,
      payload.categoryId,
      payload.logoutTime,
    ];

    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.Designation.addUserType',
        data,
      });
      if (result === 'S') {
        Notification.success('User type was added successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This User type already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }

  async updateDesignation(payload: DesignationEditType) {
    const data = [
      payload.id,
      payload.name,
      payload.userType,

      payload.description,
      payload.privilegeId,
      payload.categoryId,
      payload.logoutTime,
    ];

    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'generalmaster.Designation.Editusertype',
        data,
      });

      if (result === 'S') {
        Notification.success('User type was updated successfully!');
        return null;
      } else if (result === 'E') {
        return { message: 'This User type already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }
}

export const storeDesignation = new Designation();
