import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toJS } from 'mobx';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button, IconButton } from 'components/button';
import { Input } from 'components/form/textField';
import MaskInput from 'components/form/maskInput';
import ReportInfoBlock from './components/ReportInfoBlock';
import FileCheckbox from './components/FileCheckbox';
import resolver from './components/validation';

import {
  storeFax,
  FaxSavePayloadType,
  FaxReportType,
  FlagType,
  FaxTypeLabels,
} from 'stores/_mobx/workflow/order/fax';
import Badge from 'components/badge';

const defaultFaxValue: FaxType = {
  faxNumber: '',
  receiverName: '',
};

interface FormType {
  faxes: FaxType[];
  files: FileType[];
  flag: FlagType;
}

interface FileType {
  fileName: string;
  filePath: string;
  isChecked: boolean;
  isCritical: boolean;
  faxType?: FaxTypeLabels;
}

export interface FaxType {
  faxNumber: string;
  receiverName: string;
}

export type DetailsType = FaxReportType;

interface PropsType {
  isFaxWithReport?: boolean;
  details: FaxReportType;
  title?: string;
  onClose: () => void;
  onSendFax: () => void;
}

const DialogFax = ({
  isFaxWithReport = true,
  title = 'Fax Report',
  details: { defaultValues, ...details },
  onClose,
  onSendFax,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting, isDirty, errors },
    reset,
    handleSubmit,
  } = useForm<FormType>({ defaultValues: toJS(defaultValues), resolver });

  const {
    fields: faxes,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'faxes',
  });

  const { fields: files } = useFieldArray({
    control,
    name: 'files',
  });

  // @ts-ignore
  const errorMessage = errors?.files?.message || '';

  const handleClickAddFax = () => {
    append(defaultFaxValue);
  };

  const handleClickSubmit = () => {
    handleSubmit(({ faxes, files, flag }) => {
      const payload: FaxSavePayloadType = {
        faxes,
        flag,
        id: details.id,
        subject: '',
        report: isFaxWithReport ? 'Y' : 'N',
        files: files
          .filter(({ isChecked }) => isChecked)
          .map(({ faxType, ...file }) => ({
            ...file,
            filePath: `/file-storage/${file.filePath}`,
          })),
      };

      return storeFax.sendFax(payload).then((isSucceed) => {
        if (isSucceed) onSendFax();
      });
    })();
  };

  const handleClickReset = () => {
    reset(toJS(defaultValues));
  };

  useEffect(() => {
    reset(toJS(defaultValues));
  }, [defaultValues, reset]);

  return (
    <Dialog className="fax-report-popup" size="large" handleClose={onClose}>
      <DialogHeader title={title} onClose={onClose} />
      <DialogBody className={isSubmitting ? ' on-ajax' : ''}>
        <ReportInfoBlock info={details} />

        <form onSubmit={handleClickSubmit} className="row">
          {files.map(({ faxType, ...field }, index) => (
            <div key={field.id} className="d-flex align-items-center gap-3">
              <FileCheckbox field={field} index={index} control={control} />
              {faxType ? <Badge variant="light">{faxType}</Badge> : null}
            </div>
          ))}

          {errorMessage && <div className="text-danger">{errorMessage}</div>}

          <div className="mb-4" />

          {faxes.map((field, index) => (
            <div key={field.id} className="row">
              <Input
                name={`faxes.${index}.receiverName`}
                label="Receiver Name"
                className="col-sm-6"
                control={control}
              />
              <MaskInput
                name={`faxes.${index}.faxNumber`}
                label="Fax No"
                format="###-###-####"
                className="col-sm-4"
                control={control}
              />
              <div className="controls text-primary col-sm-2">
                <IconButton onClick={handleClickAddFax}>
                  <i className="bi bi-plus-circle" />
                </IconButton>
                <IconButton
                  disabled={faxes.length <= 1}
                  onClick={() => remove(index)}>
                  <i className="bi bi-trash" />
                </IconButton>
              </div>
            </div>
          ))}
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting || !isDirty}
          onClick={handleClickReset}
        />
        <Button
          text="Send Fax"
          disabled={isSubmitting}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogFax;
