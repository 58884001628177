import { memo } from 'react';

import { BASE_URL_FILE_DIR } from 'constant/config';

interface PropsType {
  filePath: string;
  fileName: string;
  isCritical?: boolean;
  className?: string;
  label?: string;
}

const File = ({
  fileName,
  filePath,
  className,
  label,
  isCritical,
}: PropsType) => (
  <div className={`row ${className}`}>
    {label && (
      <div className="col-sm-auto align-self-center">
        <b>{label}</b>
      </div>
    )}
    <div className="col-sm-auto files">
      <i className="bi bi-filetype-pdf files-icon" />
      <span className="file-label">
        {fileName}
        {isCritical && <span className="bi bi-check-circle text-danger" />}
        <a
          href={`${BASE_URL_FILE_DIR}${filePath}`}
          target="_blank"
          rel="noreferrer">
          <i className="bi bi-eye-fill text-primary" />
        </a>
      </span>
    </div>
  </div>
);

export default memo(File);
