import Notification from 'components/modal/Notification';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

const filterDefaultValues: FilterType = {
  stateId: 0,
  area: '',
};

export interface FilterType {
  stateId: number;
  area: string;
}

export interface JurisdictionType {
  area: string;
  refid: number;
  state: string;
  zipcode: string;
  zipcoderange: string;
}

interface JurisdictionListResponseType {
  result: JurisdictionType[];
  totalCount: string;
}

interface OptionResponseType {
  data: string;
  label: string;
}

export interface OptionType {
  value: number;
  label: string;
}

class Jurisdiction {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  jurisdictionsList: JurisdictionType[] = [];
  jurisdictionsTotal: number = 0;
  idForDelete: number = 0;
  options: OptionType[] = [];
  filter: FilterType = filterDefaultValues;

  page: Pagination = new Pagination({ id: 'jurisdictionGrid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      jurisdictionsList: observable,
      jurisdictionsTotal: observable,
      idForDelete: observable,
      options: observable,
      filter: observable,

      setFetching: action,
      setFetchingOptions: action,
      setJurisdictions: action,
      setIdForDelete: action.bound,
      setFilter: action.bound,
      clearIdForDelete: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setJurisdictions(list: JurisdictionType[], total: number) {
    this.jurisdictionsList = list;
    this.jurisdictionsTotal = total;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  async getJurisdictionList() {
    const {
      filter,
      page: { pagination },
    } = this;

    this.setFetching(true);
    try {
      const { totalCount, result } =
        await apiRequest<JurisdictionListResponseType>({
          url: 'generalmaster.jurisdiction.GetJurisdictions',
          data: [
            pagination.skip,
            pagination.pageSize,
            filter.stateId,
            filter.area,
          ],
        });

      const list = result.map((el) => ({
        ...el,
        refid: Number(el.refid),
      }));

      this.setJurisdictions(list, Number(totalCount) || 0);
    } catch (e: any) {
      this.setJurisdictions([], 0);
    } finally {
      this.setFetching(false);
    }
  }

  async deleteJurisdiction() {
    this.setFetching(true);
    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.jurisdiction.DeleteJurisdiction',
        data: [this.idForDelete],
      });
      if (response === 'S') {
        this.clearIdForDelete();
        Notification.success('Jurisdiction deleted successfully!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }

  getOptions = async () => {
    if (this.fetchingOptions) return Promise.resolve([]);

    this.setFetchingOptions(true);

    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.jurisdiction.GetjurisdictionDropdown',
      });

      const options = response
        .map(({ label, data }) => ({
          label,
          value: Number(data),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (error) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  };
}

export const storeJurisdiction = new Jurisdiction();
