export const exportColumnsMapper = {
  TC: [
    { name: 'dos', id: 'dos', title: 'DOS' },
    { name: 'billType', id: 'facilitynm', title: 'Bill Type' },
    { name: 'patientLastName', id: 'patientlast', title: 'Patient Last Name' },
    {
      name: 'patientFirstName',
      id: 'patientfist',
      title: 'Patient First Name',
    },
    { name: 'birthDate', id: 'birthdate', title: 'Date Of Birth' },
    { name: 'facilityName', id: 'facilityname', title: 'Facility Name' },
    { name: 'division', id: 'division', title: 'Division' },
    { name: 'modality', id: 'examtype', title: 'Modality' },
    { name: 'studyId', id: 'studyId', title: 'Study ID' },
  ],
  PC: [
    { name: 'dos', id: 'dos', title: 'DOS' },
    { name: 'billType', id: 'facilitynm', title: 'Bill Type' },
    { name: 'patientLastName', id: 'patientlast', title: 'Patient Last Name' },
    {
      name: 'patientFirstName',
      id: 'patientfist',
      title: 'Patient First Name',
    },
    {
      name: 'tcBillProcess',
      id: 'tcbillprocess',
      title: 'Technical Bill Process',
    },
    { name: 'birthDate', id: 'birthdate', title: 'Date Of Birth' },
    { name: 'facilityName', id: 'facilityname', title: 'Facility Name' },
    { name: 'division', id: 'division', title: 'Division' },
    { name: 'modality', id: 'examtype', title: 'Modality' },
    { name: 'studyId', id: 'studyId', title: 'Study ID' },
    {
      name: 'eligibilityStatus',
      id: 'eligibility_status',
      title: 'Eligibility Status',
    },
    { name: 'coding_status', id: 'coding_status', title: 'Coding Status' },
    { name: 'radiologist', id: 'radiologist', title: 'Reading Rad(s)' },
  ],
};

export const gridIdsMapper = {
  TC: 'billing_assignment_grid_tc',
  PC: 'billing_assignment_grid_pc',
};

export const exporterNameMapper = {
  TC: 'BillingTcAssignmentExporter',
  PC: 'BillingPcAssignmentExporter',
};

export const getColumnsForExport = ({
  mode,
  columns,
}: {
  mode: 'TC' | 'PC';
  columns: Record<string, any>[];
}) => {
  const columnsForExport = exportColumnsMapper[mode];

  const filteredColumns = columnsForExport.filter(({ name }) =>
    columns.some((column) => column.name === name)
  );

  return filteredColumns;
};
