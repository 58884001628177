import ClientUserPrivilegesPageService from 'services/privelegies/settings/ClientUserPrivilegesPageService';
import appDispatcher from 'dispatcher/AppDispatcher';
import UserTypePrivilegePageService from 'services/privelegies/settings/UserTypePrivilegePageService';

export default class ClientUserPrivilegesPageActions {
  static loadUserSpecificPermission(
    userId: number,
    userTypeId: number,
    flag: boolean
  ) {
    return UserTypePrivilegePageService.loadApplications(0, userTypeId, flag)
      .then((response) => {
        appDispatcher.dispatch({
          type: 'client-user-settings-overview-applications-action',
          data: response,
        });
        return response;
      })
      .then(() => {
        return ClientUserPrivilegesPageService.loadUserSpecificPermission(
          userId
        );
      })
      .then((response: Array<any>) => {
        let data: Array<'Y' | 'N'> = [];
        response.forEach((item) => {
          data[parseInt(item.appid)] = item.flag;
        });
        appDispatcher.dispatch({
          type: 'client-user-settings-overview-action',
          data: data,
        });
      });
  }

  static clearData() {
    appDispatcher.dispatch({
      type: 'client-user-settings-clear-data',
    });
  }
  static clearDataSource() {
    appDispatcher.dispatch({
      type: 'client-user-settings-overview-action',
      data: [],
    });
  }

  static saveChanges(
    userId: number,
    ids: Array<number>,
    isAllowed: Array<string>
  ) {
    return ClientUserPrivilegesPageService.saveChanges(userId, ids, isAllowed);
  }
}
