import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import {
  storePhone,
  PhoneFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/phone';
import { URL_PHONE } from 'constant/path/workflow';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const PhoneEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, fetchingRegions, phone, availableRegions } = storePhone;

  const isDataLoading = fetching || fetchingRegions;

  const handleSubmit = async (data: PhoneFormModel) =>
    storePhone.updatePhone(data).then((error) => {
      if (error) return error;
      history.push(URL_PHONE);
    });

  useEffect(() => {
    storePhone.getPhone(Number(params.id));
    storePhone.getRegions();

    return storePhone.clearPhone;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Phone" />
      <Form
        backUrl={URL_PHONE}
        defaultValues={phone}
        availableRegions={availableRegions}
        onSubmit={handleSubmit}
      />
      {isDataLoading && <SpinnerFixed />}
    </>
  );
};

export default observer(PhoneEditPage);
