import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropDownCorporation } from 'components/project/dropdown/CorporateDropdown';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropdownProviderType } from 'components/project/dropdown/ProviderTypeDropdown';
import { DropDownOrderType } from 'components/project/dropdown/OrderType';
import { DropdownRegion } from 'components/project/dropdown/RegionDropdown';
import { DropdownLocation } from 'components/project/dropdown/LocationDropdown';
import { DropdownDivision } from 'components/project/dropdown/Division';
import { Input } from 'components/form/textField';
import { InputCalendar } from 'components/form/inputCalendar';
import { Radio } from 'components/form/radio';
import { DateRange } from 'components/form/dateRange';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';

import {
  storeLedger,
  defaultFilterValue,
  FilterType,
} from 'stores/_mobx/billingCodding/ledger';

const className = 'col-md-6 col-lg-3';

const BALANCE_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'D', label: 'Due' },
  { value: 'P', label: 'Paid' },
];

const DUE_PERIOD_OPTIONS = [
  { value: '30+', label: '30+' },
  { value: '60+', label: '60+' },
  { value: '90+', label: '90+' },
  { value: '120+', label: '120+' },
];

interface PropsType {
  fetching: boolean;
  isFilterVisible: boolean;
  onSearch: (filter: FilterType) => void;
}

const Filter = ({ fetching, isFilterVisible, onSearch }: PropsType) => {
  const { control, handleSubmit, watch, reset, setValue } = useForm<FilterType>(
    { defaultValues: storeLedger.filter }
  );

  const balance = watch('balance');

  const handleClickReset = () => {
    reset(defaultFilterValue);
    onSearch(defaultFilterValue);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'balance') {
        setValue('duePeriod', '');
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form
      className={`row${isFilterVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSearch)}>
      <Input
        name="invoice"
        label="Invoice #"
        className={className}
        control={control}
      />
      <DropDownPlaceOfService
        name="pos"
        label="POS"
        className={className}
        control={control}
      />
      <InputCalendar
        name="invoiceFrom"
        label="Invoiced Date From"
        className={className}
        control={control}
      />
      <InputCalendar
        name="invoiceTo"
        label="Invoiced Date To"
        className={className}
        control={control}
      />
      <div />

      <DropDownCorporation
        name="corporateId"
        label="Corporation"
        className={className}
        control={control}
      />
      <DropDownFacility
        name="facilityId"
        label="Facility"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <InputCalendar
        name="postedFrom"
        label="Posted From"
        className={className}
        control={control}
      />
      <InputCalendar
        name="postedTo"
        label="Posted To"
        className={className}
        control={control}
      />
      <div />
      <DropDownState
        name="stateId"
        label="State"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownRegion
        name="region"
        label="Region"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownLocation
        name="location"
        label="Location"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownDivision
        name="division"
        label="Division"
        className={className}
        control={control}
      />
      <DropdownProviderType
        name="providerType"
        label="Provider type"
        className={className}
        control={control}
      />
      <DropDownOrderType
        name="orderType"
        label="Order type"
        className={className}
        control={control}
      />
      <Radio
        name="balance"
        label="Balance"
        className={`part-inline ${className}`}
        options={BALANCE_OPTIONS}
        control={control}
      />
      {balance === 'D' && (
        <Radio
          name="duePeriod"
          label="Days"
          className="col-md-6 col-lg-auto part-inline"
          options={DUE_PERIOD_OPTIONS}
          control={control}
        />
      )}
      <div />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-6 col-lg-auto"
        control={control}
      />
      <ControlsLayout alignX="auto" className="mb-3">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
