import { Controller, Control } from 'react-hook-form';

import PureSelect, { PureSelectProps } from './PureSelect';

/**
  @prop {string} 'data-testid' - data attribute for testing libraries
  If data-testid not presented, then prefix for data-testid attribute will generated with 'name' property
  '{dataTestId}-label' - data attribute for the label
  '{dataTestId}-field' - data attribute for the input field
  '{dataTestId}-error' - data attribute for the error block
*/

export interface PropsType
  extends Omit<
    PureSelectProps,
    'name' | 'value' | 'onChange' | 'onBlur' | 'onFocus' | 'errorMessage'
  > {
  control: Control<any>;
  defaultValue?: string;
  name: string;
}

const Select = ({ name, control, ...rest }: PropsType) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => {
      const errorMessage = error?.message || '';
      return <PureSelect errorMessage={errorMessage} {...field} {...rest} />;
    }}
  />
);

export default Select;
