import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import FormCode, { FormModel } from './components/Form';

import { storeQ0092 } from 'stores/_mobx/systemSetup/masterSetting/q0092';
import { URL_Q0092 } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const Q0092EditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, code } = storeQ0092;

  const handleSubmit = (payload: FormModel) =>
    storeQ0092.updateCode(payload).then((response) => {
      if (response) return response;
      history.push(URL_Q0092);
    });

  useEffect(() => {
    storeQ0092.getCode(params.id);
    return storeQ0092.clearCode;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Q0092" />
      {code ? (
        <FormCode
          backUrl={URL_Q0092}
          defaultValues={code}
          onSubmit={handleSubmit}
        />
      ) : null}
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(Q0092EditPage);
