import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { emailValidation, phoneValidation } from './validation';

const initialValues: FormModel = {
  value: '',
};

interface FormModel {
  value: string;
}

interface PropsType {
  label: string;
  title: string;
  defaultValues?: string;
  isEmailUpdate?: boolean;
  onClose: () => void;
  onSubmit: (data: FormModel) => Promise<any>;
}

const DialogPrompt = ({
  label,
  title,
  isEmailUpdate,
  defaultValues,
  onClose,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: defaultValues ? { value: defaultValues } : initialValues,
    resolver: isEmailUpdate ? emailValidation : phoneValidation,
  });

  const handleClickApply = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <Dialog handleClose={onClose}>
      <DialogHeader title={title} />
      <DialogBody>
        <Input
          label={label}
          autoFocus
          required
          name="value"
          control={control}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          text="No"
          variant="warning"
          disabled={isSubmitting}
          onClick={onClose}
        />
        <Button text="Yes" disabled={isSubmitting} onClick={handleClickApply} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogPrompt;
