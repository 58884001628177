import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import { IconButton } from 'components/button';
import DialogConfirm from 'components/modal/dialogConfirm';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';

import { storeSecurityQuestion } from 'stores/_mobx/systemSetup/masterSetting/securityQuestion';
import { MS_QUESTIONS as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_SECURITY_QUESTION,
  URL_SECURITY_QUESTION_ADD,
} from 'constant/path/systemSetup';

const columns = [
  {
    name: 'questnm',
    title: 'Question',
  },
  {
    name: 'refid',
    title: 'Action',
    className: 'width-100',
    render: (id: number) => (
      <div className="control">
        <>
          <Link to={`${URL_SECURITY_QUESTION}/edit/${id}`}>
            <i className="bi bi-pencil" />
          </Link>
          <IconButton onClick={() => storeSecurityQuestion.setIdForDelete(id)}>
            <i className="bi bi-trash" />
          </IconButton>
        </>
      </div>
    ),
  },
];

const SecurityQuestionListPage = () => {
  const {
    fetching,
    questionList,
    questionTotal,
    idForDelete,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeSecurityQuestion;

  const [question, setQuestion] = useState<string>('');

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleSetQuestion = (phrase: string) => {
    setQuestion(phrase);
    debouncedMethod();
  };

  const handleApproveDelete = () => {
    storeSecurityQuestion.deleteQuestion().then((isSucceed) => {
      if (isSucceed) {
        storeSecurityQuestion.getQuestionsMain(question);
      }
    });
  };

  useEffect(() => {
    storeSecurityQuestion.getQuestionsMain(question);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Security Question">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <PureInput
        placeholder="Question"
        className="col-sm-6"
        value={question}
        onChange={handleSetQuestion}
      />

      <Grid
        id="security_question_grid"
        onAjax={fetching}
        columns={columns}
        dataSource={questionList}
        dataSourceCount={questionTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_SECURITY_QUESTION_ADD} />
        }
      />
      {idForDelete ? (
        <DialogConfirm
          onCancel={storeSecurityQuestion.clearIdForDelete}
          onApprove={handleApproveDelete}>
          Do you really want to delete this question?
        </DialogConfirm>
      ) : null}
    </>
  );
};

export default observer(SecurityQuestionListPage);
