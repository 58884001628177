import NumberInput from 'components/form/input/NumberInput';
import Validation from 'components/form/Validation';

export default class PhoneInput extends NumberInput {
  getFormat() {
    return '###-###-####';
  }

  validate(value: any): any[] {
    return Validation.validate(value, this.prepareValidations());
  }

  prepareValidations(): string {
    const validations = this.props.validations;
    if (!validations) {
      return 'phone';
    }
    if (typeof validations === 'string') {
      return validations + ' phone';
    } else if (validations.phone === undefined) {
      return { ...validations, phone: true };
    }
    return validations;
  }
}
