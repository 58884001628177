import { apiRequest } from 'services/RequestService';

const Requests = {
  UPDATE_IMPORT_TYPES: 'generalmaster.ImportItemType.UpdateReportType',
};

export class TTransmitLogFilter {
  physicianId: number = null;
  stateId: number = null;
  posId: number = null;
  orderType: string = '';
  orderStatus: string = '';
  dosStart: string = '';
  dosEnd: string = '';
  facilityId: number = null;
  patientFirstName: string = '';
  patientLastName: string = '';
}

export class TTransmitLogData {
  id: number = null;
  repcode: { CN: boolean; F: boolean; SO: boolean } = {
    CN: false,
    F: false,
    SO: false,
  };
  isSigned: 0 | 1 = 0;
  examdetails: string = '';
  facility: string = '';
  recname: string = '';
  fax: string = '';
  dos: string = '';
  first_name: string = '';
  last_name: string = '';
  physician_name: string = '';
  email: string = '';
  orderid: number = null;
  selstat: boolean = false;
}

export class TDocumentFacilityFilter {
  physicianId?: number = null;
  stateId: number = null;
  posId: number = null;
  orderType: string = '';
  orderStatus?: string = '';
  dosStart: string = '';
  dosEnd: string = '';
  facilityId: number = null;
}

export class TDocumentFacilityData {
  orderid: number = null;
  dos: string = '';
  facility_nm: string = '';
  facility: number = null;
  unsign_count: number = null;
  total_ordercount: number = null;
  clinical_count: number = null;
  insurance_count: number = null;
  bill_email: string = '';
  admin_email: string = '';
  bill_fax: string = '';
  fax_date: string = '';
}

class FacilityTabService {
  loadDocumentFacilityList(
    skip: number,
    limit: number,
    filter: TDocumentFacilityFilter
  ) {
    const data = [
      skip,
      limit,
      filter.facilityId,
      filter.dosStart,
      filter.dosEnd,
      filter.stateId,
      filter.posId,
      filter.orderType,
      filter.physicianId,
      filter.orderStatus,
    ];

    return apiRequest<{ count: number; data: Array<TDocumentFacilityData> }>({
      url: 'facility.DocumentManager.DocFacilityList',
      data,
    });
  }

  loadTransmitLogList(
    skip: number,
    limit: number,
    orderId: number,
    mode: 'F' | 'PHY' | string,
    filter: TTransmitLogFilter
  ) {
    const data = [skip, limit, orderId, mode, filter];

    return apiRequest<{ count: number; data: Array<TTransmitLogData> }>({
      url: 'facility.DocumentManager.getTransmitLogOverview',
      data,
    }).then((response) => {
      let count: number = 0;
      response.data.forEach((item) => {
        item.id = count++;
        item.repcode = {
          CN: !item.repcode.CN,
          F: !item.repcode.F,
          SO: !item.repcode.SO,
        };
      });
      return response;
    });
  }

  updateImportTypes(importTypeList: Array<{ refid: number }>) {
    return apiRequest<'S'>({
      url: Requests.UPDATE_IMPORT_TYPES,
      data: [importTypeList],
    });
  }

  saveDocumentOptions(
    DOCUMENT_OPTIONS: Array<{ value: string; label: string }>
  ) {
    return apiRequest<void>({
      url: 'generalmaster.ImportItemType.updateDocumentOptions',
      data: [DOCUMENT_OPTIONS],
    });
  }

  loadUserDocumentOptions() {
    return apiRequest<Array<{ value: string; label: string; title: string }>>({
      url: 'generalmaster.ImportItemType.getUserImportOptions',
    });
  }
}

export default new FacilityTabService();
