import { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'components/modal/dialog';
import Messages from 'components/messages';
import { Button } from 'components/button';
import { PureTextarea } from 'components/form/textarea';

import { storeChatting } from 'stores/_mobx/message/chatting';

export interface ParentPropsType {
  id: number;
  onClose: (shouldUpdate: boolean) => void;
}

interface PayloadType {
  facilityId?: number;
  orderId?: number;
  invoiceId?: number;
}

interface PropsType {
  title: string;
  payload: PayloadType;
  getListMessages: () => void;
  onClose: (shouldUpdate: boolean) => void;
}

const DialogMessage = ({
  title,
  payload,
  getListMessages,
  onClose,
}: PropsType) => {
  const { count, messages, fetching } = storeChatting;

  const [newMessage, setNewMessage] = useState<string>('');

  const shouldUpdate = useRef<boolean>(null);

  const handleClickSend = () => {
    storeChatting
      .addMessage({ ...payload, message: newMessage })
      .then((isSucceed) => {
        if (isSucceed) {
          setNewMessage('');
          getListMessages();
          shouldUpdate.current = true;
        }
      });
  };

  const handleClose = () => {
    onClose(shouldUpdate.current);
  };

  useEffect(() => {
    getListMessages();

    return storeChatting.clearMessages;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog size="large" handleClose={handleClose}>
      <DialogHeader title={title} onClose={handleClose} />
      <DialogBody className={`${fetching ? 'on-ajax' : ''}`}>
        <div className="pb-3">
          {count ? <Messages list={messages} /> : 'No messages'}
        </div>

        <PureTextarea
          className="mb-4"
          placeholder="Type new message"
          value={newMessage}
          rows={4}
          resize={false}
          onChange={setNewMessage}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          onClick={handleClickSend}
          disabled={!newMessage.length || fetching}
          text="Send"
        />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogMessage);
