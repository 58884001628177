import FacilityService, {
  CreateFacilityType,
} from 'services/project/FacilityService';
import appDispatcher from 'dispatcher/AppDispatcher';
import DropdownActions, {
  TDropDownItem,
} from 'actions/project/DropdownActions';

interface CreateFacilityPayload extends Omit<CreateFacilityType, 'orderType'> {
  orderType: Record<number, boolean>;
}
export default class FacilityActions extends DropdownActions {
  static loadFloorDropdown(facilityId: number) {
    return FacilityService.loadFloorDropdown(facilityId).then(
      (response: Array<TDropDownItem>) => {
        appDispatcher.dispatch({
          type: 'load-facility-floors',
          data: response.map((v) => {
            v.value = parseInt('' + v.data);
            return v;
          }),
        });
      }
    );
  }

  static clearFloorData() {
    appDispatcher.dispatch({
      type: 'load-facility-floors',
      data: [],
    });
  }

  static clearPhones() {
    appDispatcher.dispatch({
      type: 'facility.FacilityMaster.GetFacilityPhoneNos',
      data: [],
    });
  }

  static addNewFacility(payload: CreateFacilityPayload) {
    const orderType = Object.entries(payload.orderType).reduce(
      (prev: number[], [key, value]) =>
        value ? prev.concat(Number(key)) : prev,
      []
    );
    return FacilityService.addNewFacility({ ...payload, orderType }).then(
      (facilityId: 'SE' | 'E' | number) => {
        return facilityId;
      }
    );
  }
}
