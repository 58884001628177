import { IconButton } from 'components/button';
import { Input } from 'components/form/textField';
import { useFieldArray, Control } from 'react-hook-form';

interface PropsType {
  className?: string;
  name: string;
  control: Control<any>;
}

const NpiField = ({ className, name, control }: PropsType) => {
  const { fields, insert, remove, update } = useFieldArray({
    control,
    name,
  });

  const handleClickInsert = (i: number) => {
    insert(i, {
      npi: '',
      desc: '',
    });
  };

  const handleClickRemove = (index: number) => {
    if (fields.length === 1) {
      update(index, {
        npi: '',
        desc: '',
        refid: null,
      });
    } else {
      remove(index);
    }
  };

  return (
    <div className={className}>
      {fields.map((field, index) => (
        <div key={field.id} className="row">
          <Input
            name={`${name}.${index}.npi`}
            label="NPI"
            className="col-sm-4"
            maxLength={10}
            control={control}
          />
          <Input
            name={`${name}.${index}.desc`}
            label="Description"
            className="col-sm-6"
            control={control}
          />
          <div className="col-sm-auto">
            <div className="d-flex gap-3">
              <IconButton
                className="text-primary mt-5"
                onClick={() => handleClickInsert(++index)}>
                <i className="bi bi-plus-circle" />
              </IconButton>
              <IconButton
                className="text-primary mt-5"
                onClick={() => {
                  handleClickRemove(index);
                }}>
                <i className="bi bi-trash" />
              </IconButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NpiField;
