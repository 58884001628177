import { makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

interface OptionsStateResponse {
  data: string;
  label: string;
  user_type: string;
  vaccination_records_type: string[] | null;
}

export interface OptionType {
  value: number;
  label: string;
  userType: string;
  badges: string[];
}

export class DropdownTechnician {
  fetching: boolean = false;
  options: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      options: observable,
    });
  }

  async getOptions() {
    if (this.fetching) return Promise.resolve();
    try {
      runInAction(() => {
        this.fetching = true;
      });
      const list = await apiRequest<OptionsStateResponse[]>({
        url: 'order.Order.TechnicianDropDown',
      });

      const options = list
        .filter(({ label }) => Boolean(label))
        .map(({ label, data, user_type, vaccination_records_type }) => ({
          label,
          userType: user_type,
          value: Number(data),
          badges: vaccination_records_type || [],
        }));

      runInAction(() => {
        this.fetching = false;
        this.options = options;
      });
    } catch {
      runInAction(() => {
        this.fetching = false;
        this.options = [];
      });
    }
  }

  findOption(id: number) {
    return this.options.find(({ value }) => value === id);
  }
}

export const storeDropdownTechnician = new DropdownTechnician();
