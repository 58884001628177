import React, { forwardRef, KeyboardEvent, useRef } from 'react';
import clsx from 'clsx';

interface PropsType extends React.HTMLProps<HTMLInputElement> {
  value: string;
  isTime?: boolean;
  preventOpenOnFocus?: boolean;
  onChange: (e: any) => void;
  onClick?: () => void;
  setOpen?: (isOpen: boolean) => void;
}

const clearValue = (value: string) => value.replace(/\D/g, '');

const datePrettier = (date: string) => {
  const clearDate = clearValue(date).substring(0, 8);

  return clearDate
    .split('')
    .reduce(
      (prev, current, idx) =>
        `${prev}${idx === 1 || idx === 3 ? current + '/' : current}`,
      ''
    );
};

const timePrettier = (time: string) => {
  const clearTime = clearValue(time).substring(0, 4);

  return clearTime
    .split('')
    .reduce(
      (prev, current, idx) => `${prev}${idx === 1 ? current + ':' : current}`,
      ''
    );
};

const CustomInput = (
  {
    isTime,
    preventOpenOnFocus,
    onChange,
    onClick,
    setOpen,
    ...props
  }: PropsType,
  ref: React.Ref<HTMLInputElement>
) => {
  const refHidden = useRef<HTMLInputElement>();

  const prevDate = useRef<string>(props.value);

  const iconClassName = clsx('bi', {
    'bi-clock': isTime,
    'bi-calendar-week': !isTime,
    clickable: preventOpenOnFocus,
  });

  const handlePrettier = isTime ? timePrettier : datePrettier;

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setOpen(false);
    } else {
      props.onKeyDown(e);
    }
  };

  const handleChange = (e: React.SyntheticEvent) => {
    const { value } = e.currentTarget as HTMLInputElement;

    const isDeleting = prevDate.current.length >= value.length;

    const formattedValue = isDeleting ? value : handlePrettier(value);

    prevDate.current = formattedValue;

    const el = {
      target: {
        value: formattedValue,
      },
    };

    onChange(el);
  };

  return (
    <>
      <input
        className="form-control react-datepicker-ignore-onclickoutside"
        {...props}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={() => {
          refHidden.current.focus();
        }}
        ref={ref}
      />
      <input className="hidden" ref={refHidden} />
      <i
        className={iconClassName}
        onClick={preventOpenOnFocus ? onClick : undefined}
      />
    </>
  );
};

export default forwardRef<HTMLInputElement, any>(CustomInput);
