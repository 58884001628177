import React from 'react';
import {
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import { Observer } from 'mobx-react-lite';
import { Container } from 'flux/utils';
import { ToastContainer, Slide } from 'react-toastify';

import LoginPage from 'page/authorization/login/LoginPage';
import LogoutPage from 'page/authorization/LogoutPage';
import ChangePasswordPage from 'page/authorization/ChangePassword';
import LicenseAgreementPage from 'page/authorization/LicenseAgreementPage';
import CrashPage from 'page/errors/Crash';
import RateLimit from 'page/errors/RateLimit';
import { ProtectedRoute, RedirectAuthorizedRoute } from 'components/HOC';
import SessionMonitor from 'components/SessionMonitor';
import { SpinnerFixed } from 'components/spinner';
import AppVersion from 'components/appVersion';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import ProtectedPages from 'routes';

import UserProfileActions from 'actions/UserProfileActions';
import UserProfileStore from 'stores/UserProfileStore';
import { storeService } from 'stores/_mobx/service';
import { NOTIFICATION_DELAY_CLOSE_DIALOG } from 'constant/notificationDelayCloseModal';
import { URL_CHANGE_PASSWORD, URL_LOGIN, URL_LOGOUT } from 'constant/path/auth';
import { URL_LICENSE_AGREEMENT } from 'constant/path/other';
import { URL_SIGNED_ORDER_UNSIGNED_TAB } from 'constant/path/clinicalManager';

import 'react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export interface PReactApp extends RouteComponentProps {}

// TODO: this is temporary solution. Remove it after router will fixed;
export let history: null | RouteComponentProps['history'] = null;

export class SReactApp {
  error: boolean = false;
  fetching: boolean = true;
  unsignedOrders: boolean = false;
}

export class ReactApp extends React.Component<PReactApp, SReactApp> {
  constructor(props: PReactApp) {
    super(props);
    this.state = new SReactApp();
    history = props.history;
    storeService.clearVersion();
  }

  static getStores() {
    return [UserProfileStore];
  }

  static calculateState(prevState?: SReactApp) {
    const unsignedOrders = UserProfileStore.getState().unsignedOrders;

    return { ...(prevState || new SReactApp()), unsignedOrders };
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  handleToggleFetch = () => {
    this.setState(
      (store) => ({ fetching: !store.fetching }),
      () => {
        document.body.classList.remove('on-ajax');
      }
    );
  };

  handleCloseUnsignedOrders = () => {
    UserProfileActions.resetCountUnsignedOrders();
  };

  handleClickProceedSign = () => {
    this.handleCloseUnsignedOrders();
    this.props.history.push(URL_SIGNED_ORDER_UNSIGNED_TAB);
  };

  componentDidMount() {
    UserProfileActions.isAuthorised({
      callback: this.handleToggleFetch,
      history: this.props.history,
    });
  }

  render() {
    const { error, fetching, unsignedOrders } = this.state;

    if (error) {
      return <CrashPage />;
    }

    return (
      <Observer>
        {() =>
          storeService.isRateLimitReachedOut ? (
            <RateLimit />
          ) : fetching ? (
            <SpinnerFixed />
          ) : (
            <>
              <Switch>
                <RedirectAuthorizedRoute
                  path={URL_LOGIN}
                  component={LoginPage}
                />
                <Route exact path={URL_LOGOUT} component={LogoutPage} />
                <ProtectedRoute
                  exact
                  path={URL_LICENSE_AGREEMENT}
                  component={LicenseAgreementPage}
                />
                <ProtectedRoute
                  exact
                  path={URL_CHANGE_PASSWORD}
                  component={ChangePasswordPage}
                />
                <ProtectedRoute path="/" component={ProtectedPages} />
              </Switch>

              {unsignedOrders && (
                <Dialog handleClose={this.handleCloseUnsignedOrders}>
                  <DialogHeader title="Confirm" />
                  <DialogBody>
                    You have unsigned orders. Are you ready to sign?
                  </DialogBody>
                  <DialogFooter>
                    <Button
                      variant="warning"
                      text="No"
                      onClick={this.handleCloseUnsignedOrders}
                    />
                    <Button
                      text="Proceed to sign"
                      onClick={this.handleClickProceedSign}
                    />
                  </DialogFooter>
                </Dialog>
              )}
              <AppVersion />
              <SessionMonitor />
              <ToastContainer
                position="top-right"
                theme="colored"
                newestOnTop
                autoClose={NOTIFICATION_DELAY_CLOSE_DIALOG}
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                transition={Slide}
              />
            </>
          )
        }
      </Observer>
    );
  }
}

const ReactAppContainer = Container.create(ReactApp);
export default withRouter(ReactAppContainer);
