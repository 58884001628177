import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Control } from 'react-hook-form';

import { TwoSideMapper } from 'components/form/twoSideMapper';
import { FilterPayloadType } from 'components/form/twoSideMapper/components/filterMethods';

import { IntegrationDetailsType } from 'stores/_mobx/systemSetup/masterSetting/integrations';
import { storeDropdownFacility } from 'stores/_mobx/dropDown/facility';

const settings = {
  leftFirstPlaceholder: 'Facility Name',
  leftFirstClassName: 'col-sm-12',
  leftSecondHidden: true,
  rightFirstPlaceholder: 'Facility Name',
  rightFirstClassName: 'col-sm-12',
  rightSecondHidden: true,
};

const columnsLeft = [{ title: 'Facility', name: 'label' }];

const filterFacility = ({ list, firstValue }: FilterPayloadType) => {
  if (firstValue) {
    return list.filter((facility) =>
      new RegExp(`${firstValue}`, 'i').test(facility.label)
    );
  }

  return list;
};

interface PropsType {
  control: Control<IntegrationDetailsType>;
}

const Facilities = ({ control }: PropsType) => {
  const { fetchingActiveOnly, optionsActiveOnly } = storeDropdownFacility;

  useEffect(() => {
    if (!optionsActiveOnly.length)
      storeDropdownFacility.getOptionsActiveFacility();
  }, [optionsActiveOnly]);

  return (
    <TwoSideMapper
      name="facilitySubscriptions"
      className="mb-4"
      titleLeft="Total Facilities"
      titleRight="Total Subscriptions"
      control={control}
      columnLeft={columnsLeft}
      columnRight={columnsLeft}
      options={optionsActiveOnly}
      optionsLoading={fetchingActiveOnly}
      settings={settings}
      filterMethod={filterFacility}
    />
  );
};

export default observer(Facilities);
