import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { PureSelect, PureSelectProps } from 'components/form/select';

import { storeAdjustmentCode } from 'stores/_mobx/systemSetup/masterSetting/adjustmentCode';

const DropdownPureAdjustmentCode = (
  props: Omit<PureSelectProps, 'options'>
) => {
  const { options, fetchingOptions } = storeAdjustmentCode;

  useEffect(() => {
    storeAdjustmentCode.getOptions();
  }, []);

  return (
    <PureSelect {...props} options={options} isLoading={fetchingOptions} />
  );
};

export default observer(DropdownPureAdjustmentCode);
