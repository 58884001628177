import AbstractSendingApplication, {
  PAbstractSendingApplication,
  SAbstractSendingApplication,
} from './AbstractSendingApplication';

export default class AddSendingApplication extends AbstractSendingApplication<
  PAbstractSendingApplication,
  SAbstractSendingApplication
> {
  getTitle() {
    return 'New Application';
  }

  getErrorMessage(r?: any): string {
    return "Can't create application due unknown reasons";
  }
}
