import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface EmailReportResponse {
  count: number;
  data: EmailReportType[];
}

interface EmailReportType {
  lastmodified: string;
  rec_email: string;
  receiver: string;
  refid: number;
  sender_id: number;
  source: string;
  subject: string;
}

interface EmailReportPayload {
  email: string;
  refid: number;
}

interface DashboardType {
  errors: number;
  inQueue: number;
  lastError: string;
  status: 0 | 1;
}

class EmailDashboard {
  fetching: boolean = false;
  fetchingReport: boolean = false;
  info: DashboardType | null = null;
  emailReport: EmailReportType[] = [];
  emailReportTotal: number = 0;

  page: Pagination = new Pagination({ id: 'email_email_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingReport: observable,
      info: observable,
      emailReport: observable,
      emailReportTotal: observable,

      setFetching: action,
      setFetchingReport: action,
      setInfo: action,
      clearInfo: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingReport(fetching: boolean) {
    this.fetchingReport = fetching;
  }

  setInfo(info: DashboardType | null) {
    this.info = info;
  }

  clearInfo() {
    this.info = null;
  }

  async getDashboardInfo() {
    this.setFetching(true);

    try {
      const response = await apiRequest<DashboardType>({
        url: 'dashboards.EmailDashboard.info',
        method: 'GET',
      });
      const info = {
        ...response,
        lastError: dateToLocalTimezone({
          date: response.lastError,
        }),
      };
      this.setInfo(info);
    } catch (e: any) {
      this.setInfo(null);
    } finally {
      this.setFetching(false);
    }
  }

  changeStatus = async () => {
    this.setFetching(true);
    try {
      const info = await apiRequest<DashboardType>({
        url: 'dashboards.EmailDashboard.changeStatus',
        method: 'GET',
      });
      runInAction(() => {
        this.fetching = false;
        this.info = info;
      });
    } catch (e: any) {
      this.setFetching(false);
    }
  };

  preparePayload(ids: number[]): EmailReportPayload[] {
    return this.emailReport.reduce(
      (prev, { refid, rec_email }) =>
        ids.includes(refid) ? prev.concat({ email: rec_email, refid }) : prev,
      []
    );
  }

  async getEmailReport() {
    const { pagination } = this.page;
    this.setFetchingReport(true);

    try {
      const { count, data } = await apiRequest<EmailReportResponse>({
        url: 'email.Email.getEmailsWithErrors',
        data: [pagination.skip, pagination.pageSize],
      });

      runInAction(() => {
        this.fetchingReport = false;
        this.emailReport = data.map((el) => ({
          ...el,
          refid: Number(el.refid),
          sender_id: Number(el.sender_id),
          lastmodified: dateToLocalTimezone({
            date: el.lastmodified,
          }),
        }));
        this.emailReportTotal = count;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingReport = false;
        this.emailReport = [];
        this.emailReportTotal = 0;
      });
    }
  }

  async resendEmail(ids: number[]) {
    const list = this.preparePayload(ids);

    this.setFetchingReport(true);

    try {
      const result = await apiRequest<'S' | 'E' | 'SE'>({
        url: 'email.Email.resendEmail',
        data: [list, true],
      });

      if (result === 'S') {
        Notification.success(
          `The email${ids.length > 1 ? 's were' : 'was'} resent successfully.`
        );
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setFetchingReport(false);
      return false;
    }
  }

  async deleteEmail(ids: number[]) {
    const list = this.preparePayload(ids);

    this.setFetchingReport(true);

    try {
      const result = await apiRequest<'S' | 'E' | 'SE'>({
        url: 'email.Email.resendEmail',
        data: [list, false],
      });

      if (result === 'S') {
        Notification.success(
          `The email${ids.length > 1 ? 's were' : 'was'} deleted successfully.`
        );
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('An error occurred!');
      this.setFetchingReport(false);
    }
  }
}

export const storeEmailDashboard = new EmailDashboard();
