import { forwardRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';
import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';

import {
  storeDropdownTechnologist,
  OptionType,
} from 'stores/_mobx/dropDown/technologist';

interface PropsType extends PAbstractDropdown {
  options: OptionType[];
  ref: any;
}

class TechnologistDropdownClass extends AbstractDropdown<
  PropsType,
  SAbstractDropdown
> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const MiddlewareDropDown = forwardRef<TechnologistDropdownClass, any>(
  (props: PAbstractDropdown, ref) => {
    const { options, fetching } = storeDropdownTechnologist;

    useEffect(() => {
      storeDropdownTechnologist.getOptions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <TechnologistDropdownClass
        {...props}
        ref={ref}
        options={options}
        fetching={fetching}
      />
    );
  }
);

export const TechnologistDropdown = observer(MiddlewareDropDown);

const DropDownTechnologist = (props: Omit<SelectPropsType, 'options'>) => {
  const { fetching, options } = storeDropdownTechnologist;

  useEffect(() => {
    storeDropdownTechnologist.getOptions();
  }, []);

  return <Select {...props} options={options} isLoading={fetching} />;
};

export default observer(DropDownTechnologist);
