import { TCBillProcessType } from 'types';

export const tcBillTypeMapper: Record<TCBillProcessType, string> = {
  F: 'Facility',
  I: 'Insurance',
  S: 'Self Pay',
  H: 'Hospice',
  O: 'Other',
  C: 'Occ. Health Care',
  R: 'Correctional',
  B: 'Non-Billable',
  N: '',
  '': '',
};
