import React, { useCallback, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import clsx from 'clsx';

import CustomInput from './CustomInputField';
import { timeStringToDate } from 'utils/DateUtils';

export interface PureInputTimePropsType {
  name?: string;
  value?: string | null;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  autoFocus?: boolean;
  errorMessage?: string;
  step?: number;
  innerRef?: React.Ref<HTMLInputElement>;
  'data-testid'?: string;
  onChange?: (value: any) => void;
  onBlur?: (e: React.SyntheticEvent) => void;
  onFocus?: (e: React.SyntheticEvent) => void;
}

const PureInputTime = React.forwardRef<
  HTMLInputElement,
  PureInputTimePropsType
>(
  (
    {
      name,
      required = false,
      disabled = false,
      label = '',
      className,
      errorMessage,
      'data-testid': dataTestId,
      innerRef,
      placeholder = 'HH:MM',
      step = 10,
      value,
      onChange,
      ...rest
    }: PureInputTimePropsType,
    ref
  ) => {
    const refPicker = useRef(null);

    const [isOpen, toggleOpen] = useState<boolean>(false);

    const [tempValue, setTempValue] = useState<Date | null>();

    const cn = clsx(
      'formfield-holder',
      'formfield-text',
      { required: required },
      className
    );

    const testAttr = dataTestId || name;

    const formattedValue = isOpen
      ? tempValue
      : value
      ? timeStringToDate(value)
      : null;

    const keyListener = useCallback((e: KeyboardEvent) => {
      if (e.key === 'Tab' && refPicker?.current?.setOpen)
        refPicker.current.setOpen(false);
    }, []);

    const handleCalendarOpen = () => {
      const newDate = value ? timeStringToDate(value) : null;

      toggleOpen(true);

      setTempValue(newDate);

      document.addEventListener('keydown', keyListener);
    };

    const handleCalendarClose = () => {
      const dateString = tempValue ? format(tempValue, 'HH:mm:00') : '';

      toggleOpen(false);

      document.removeEventListener('keydown', keyListener);

      if (onChange) onChange(dateString);
    };

    return (
      <div className={cn}>
        {label ? (
          <div className="form-label" data-testid={`${testAttr}-label`}>
            <label htmlFor={name}>{label}</label>
          </div>
        ) : null}
        <div className="time-picker__field">
          <DatePicker
            disabledKeyboardNavigation
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={step}
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            className="form-control"
            placeholderText={placeholder}
            selected={formattedValue}
            {...rest}
            onChange={setTempValue}
            onCalendarOpen={handleCalendarOpen}
            onCalendarClose={handleCalendarClose}
            customInput={
              <CustomInput isTime data-testid={`${testAttr}-error`} />
            }
          />
        </div>
        {errorMessage && (
          <div className="error" data-testid={`${testAttr}-error`}>
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

export default PureInputTime;
