import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

const extensionRegexp = /[^\\]*\.(\w+)$/;

const getExtension = (fileName: string) =>
  fileName.match(extensionRegexp)?.[1] || '';

interface PayerInfoResponseType {
  filenm: string;
  filepath: string;
  group_no: number;
  mdx_int: number;
  name: string;
  notes: string;
  payor_code: string;
  payortype_nm: string;
  'ppxray_mv_payerytype.flag': string;
  'ppxray_mv_payerytype.payortype_nm': string;
  'ppxray_payorinfo.refid': string;
  refid: number;
  vbl: boolean;
}

export interface PayerInfoType
  extends Omit<
    PayerInfoResponseType,
    | 'ppxray_mv_payerytype.flag'
    | 'ppxray_mv_payerytype.payortype_nm'
    | 'ppxray_payorinfo.refid'
  > {
  extension: string;
}

export interface PayerAddress {
  address_1: string;
  address_2: string;
  city: string;
  contact_nm: string;
  country: string;
  email: string;
  fax: string;
  mobile: string;
  phone: string;
  refid?: number;
  state: string;
  website: string;
  zipcode: string;
}

interface PayerInfoEditType {
  addressList: PayerAddress[];
  filenm: string;
  filepath: string;
  group_no: string;
  name: string;
  notes: string;
  payer_type: string;
  payor_code: string;
  refid: string;
  experian_id: string;
}

export interface FormModel
  extends Omit<PayerInfoEditType, 'refid' | 'group_no' | 'payer_type' | 'experian_id'> {
  id: number;
  groupNumber: number | '';
  payerType: number;
  experianId: string | '';
}

class PayerInfo {
  fetching: boolean = false;
  payerList: PayerInfoType[] = [];
  payerTotal: number = 0;
  payerInfo?: FormModel;
  idForDelete: number = 0;
  search: string = '';

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'payer_info_grid' });

    makeObservable(this, {
      fetching: observable,
      payerList: observable,
      payerTotal: observable,
      payerInfo: observable,
      search: observable,
      idForDelete: observable,

      setFetching: action,
      setPayerList: action,
      setPayerTotal: action,
      setPayerInfo: action,
      setFilter: action,
      setIdForDelete: action.bound,
      clearIdForDelete: action.bound,
      clearPayerInfo: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setPayerList(list: PayerInfoType[]) {
    this.payerList = list;
  }

  setPayerTotal(count: number) {
    this.payerTotal = count;
  }

  setPayerInfo(info?: FormModel) {
    this.payerInfo = info;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setFilter(search: string) {
    this.search = search;
  }

  clearIdForDelete() {
    this.idForDelete = null;
  }

  clearPayerInfo() {
    this.payerInfo = undefined;
  }

  async getPayerList(search: string) {
    const { pagination } = this.page;

    const data = [search, pagination.skip, pagination.pageSize];

    try {
      const response = await apiRequest<PayerInfoResponseType[]>({
        url: 'usermaster.PayerInfo.PayerInfoList',
        data,
      });

      const list = response.map((info) => {
        delete info['ppxray_mv_payerytype.flag'];
        delete info['ppxray_mv_payerytype.payortype_nm'];
        delete info['ppxray_payorinfo.refid'];
        return {
          ...info,
          extension: info.filenm ? getExtension(info.filenm) : '',
          group_no: Number(info.group_no),
          mdx_int: Number(info.mdx_int),
          refid: Number(info.refid),
        };
      });

      this.setPayerList(list);
    } catch (e) {
      this.setPayerList([]);
    }
  }

  async getPayerCount(search: string) {
    try {
      const count = await apiRequest<number>({
        url: 'usermaster.PayerInfo.TotalRecordCount',
        data: [search],
      });

      this.setPayerTotal(Number(count) || 0);
    } catch (e) {
      this.setPayerTotal(0);
    }
  }

  getPayerMain = (search: string = this.search) => {
    this.setFetching(true);

    const promiseList = this.getPayerList(search);

    const promiseCount = this.getPayerCount(search);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getPayerInfo(id: number) {
    this.setFetching(true);

    try {
      const [{ refid, group_no, payer_type, experian_id, ...rest }] = await apiRequest<
        [PayerInfoEditType]
      >({
        url: 'usermaster.PayerInfo.GetViewPayerInfo',
        data: [id],
      });

      const payerInfo = {
        ...rest,
        id,
        groupNumber: Number(group_no),
        payerType: Number(payer_type),
        experianId: experian_id,
        addressList: rest.addressList.map((address) => ({
          ...address,
          refid: Number(address.refid),
        })),
      };

      this.setPayerInfo(payerInfo);
    } catch (e) {
      this.setPayerInfo();
    } finally {
      this.setFetching(false);
    }
  }

  async addPayerInfo(payload: FormModel) {
    const data = [
      payload.payerType,
      payload.payor_code,
      payload.name,
      payload.groupNumber,
      payload.notes,
      payload.filepath,
      payload.addressList,
      payload.experianId,
    ];
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'usermaster.PayerInfo.AddPayerInfo',
        data,
      });
      if (result === 'S') {
        Notification.success("Payer's information was added successfully!");
        return null;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updatePayerInfo(payload: FormModel) {
    const isOldFile = this.payerInfo.filepath === payload.filepath;
    const data = [
      payload.id,
      payload.payerType,
      payload.payor_code,
      payload.name,
      payload.groupNumber,
      payload.notes,
      payload.filepath,
      isOldFile ? '' : payload.filepath,
      payload.addressList,
      !isOldFile,
      payload.experianId,
    ];

    try {
      const result = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'usermaster.PayerInfo.EditPayerInfo',
        data,
      });

      if (result === 'S') {
        Notification.success("Payer's information was updated successfully!");
        return null;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async deletePayerInfo() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'usermaster.PayerInfo.DeletePayerInfo',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success("Payer's information was deleted!");
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("This Payer's information can't be deleted!");
      this.setFetching(false);
      return false;
    }
  }
}

export const storePayerInfo = new PayerInfo();
