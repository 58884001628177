import { useForm } from 'react-hook-form';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';

export const initialValues: PatientIdOptions = {
  otherMrn: '',
  oid: '',
  iceNumber: '',
  marshallId: '',
};
export interface PatientIdOptions {
  iceNumber: string;
  marshallId: string;
  oid: string;
  otherMrn: string;
}

interface PropsType {
  defaultValues?: PatientIdOptions;
  onClose: () => void;
  onSubmit: (data: PatientIdOptions) => void;
}

const DialogAdditionalPatientIds = ({
  defaultValues = initialValues,
  onClose,
  onSubmit,
}: PropsType) => {
  const { control, reset, handleSubmit } = useForm({ defaultValues });

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClear = () => {
    reset(initialValues);
  };

  const handleClickSubmit = handleSubmit(onSubmit);

  return (
    <Dialog handleClose={onClose}>
      <DialogHeader
        title="Additional Patient Identifiers Search"
        onClose={onClose}
      />
      <DialogBody>
        <form onSubmit={handleClickSubmit}>
          <Input name="otherMrn" label="Other MRN" control={control} />
          <Input name="oid" label="OID" control={control} />
          <Input name="iceNumber" label="ICE(A) Number" control={control} />
          <Input name="marshallId" label="Marshall ID" control={control} />
          <input type="submit" hidden />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button text="Clear" variant="default" onClick={handleClear} />
        <Button text="Reset" variant="warning" onClick={handleReset} />
        <Button text="Apply" onClick={handleClickSubmit} />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogAdditionalPatientIds;
