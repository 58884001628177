import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import { IconButton } from 'components/button';
import DialogConfirm from 'components/modal/dialogConfirm';
import useDebounce from 'components/hook/useDebounce';
import Filter from './components/Filter';

import {
  storeEmailTemplate,
  FilterType,
} from 'stores/_mobx/systemSetup/masterSetting/emailTemplate';
import { MS_EMAIL_TEMPLATE as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_EMAIL_TEMPLATE_ADD,
  URL_EMAIL_TEMPLATE_EDIT,
} from 'constant/path/systemSetup';

const columns = [
  {
    name: 'label',
    title: 'Email/Fax Template Name',
  },
  {
    name: 'typename',
    title: 'Template Type',
  },
  {
    name: 'refid',
    title: 'Action',
    render: (id: number) => (
      <div className="control">
        <Link to={URL_EMAIL_TEMPLATE_EDIT.replace(':id', `${id}`)}>
          <i className="bi bi-pencil" />
        </Link>
        <IconButton onClick={() => storeEmailTemplate.setTemplateForDelete(id)}>
          <i className="bi bi-trash" />
        </IconButton>
      </div>
    ),
  },
];

const EmailTemplateListPage = () => {
  const { fetching, templateIdForDelete, templatesList, templatesTotal, page } =
    storeEmailTemplate;

  const debouncedMethod = useDebounce(page.setPaginationToStart);

  const handleConfirmDelete = () => {
    storeEmailTemplate.deleteTemplate().then((response) => {
      if (response) storeEmailTemplate.getTemplatesListMain();
    });
  };

  const handleFilterChange = (filter: FilterType) => {
    storeEmailTemplate.setFilter(filter);
    debouncedMethod();
  };

  useEffect(() => {
    storeEmailTemplate.getTemplatesListMain();
  }, [page.pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Email Template">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <Filter onChange={handleFilterChange} />

      <Grid
        id="email_template_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={templatesList}
        dataSourceCount={templatesTotal}
        pagination={page.pagination}
        onPaginationChange={page.setPagination}
        gridControlPanel={
          <GridControlButton title="Add" url={URL_EMAIL_TEMPLATE_ADD} />
        }
      />

      {templateIdForDelete && (
        <DialogConfirm
          onCancel={storeEmailTemplate.clearTemplateForDelete}
          onApprove={handleConfirmDelete}>
          Do you really want to delete this Template?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(EmailTemplateListPage);
