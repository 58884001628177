import { useEffect, forwardRef } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';
import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';

import {
  storeRegion,
  OptionType,
} from 'stores/_mobx/systemSetup/masterSetting/region';

interface PropsType extends PAbstractDropdown {
  options: OptionType[];
  ref: any;
  regionId?: number;
  stateId?: number;
}

class LocationDropdown extends AbstractDropdown<PropsType, SAbstractDropdown> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<LocationDropdown, any>(
  (props: PAbstractDropdown, ref) => {
    const { options, fetchingOptions } = storeRegion;

    useEffect(() => {
      storeRegion.getOptions();
    }, []);

    return (
      <LocationDropdown
        {...props}
        ref={ref}
        options={options}
        fetching={fetchingOptions}
      />
    );
  }
);

export default observer(DropDownMiddleware);

export const DropdownLocation = observer(
  (props: Omit<SelectPropsType, 'options'>) => {
    const { options, fetchingOptions } = storeRegion;

    useEffect(() => {
      storeRegion.getOptions();
    }, []);

    return <Select {...props} options={options} isLoading={fetchingOptions} />;
  }
);
