import { Route, Switch } from 'react-router-dom';

import { AccessRoute } from 'components/HOC';
import * as IDS from 'constant/pagesId/privileges';
import * as PATH from 'constant/path/privileges';

import AuditTrailPage from 'page/privileges/auditTrail';
import CurrentActivityPage from 'page/privileges/currentActivityLog';
import LoginLogPage from 'page/privileges/loginLog';

import UserTypePrivilegePage from 'page/privileges/settings/UserTypePrivilegePage';
import ClientUserPrivilegesPage from 'page/privileges/settings/ClientUserPrivilegesPage';
import CompanyUserPrivilegesPage from 'page/privileges/settings/CompanyUserPrivilegesPage';

import ApplicationUsersListPage from 'page/privileges/applicationUsers/ListPage';
import ApplicationUsersAdd from 'page/privileges/ApplicationUsersAdd';
import ApplicationUsersEdit from 'page/privileges/ApplicationUsersEdit';
import E404 from 'page/errors/404';

export const privilegesRoutes = [
  '/user-demographics',
  '/privilege-settings',
  '/logs',
];

const Privileges = () => (
  <Switch>
    <AccessRoute
      exact
      pageId={IDS.USER_APP.PAGE}
      path={PATH.URL_APPLICATION_USER}
      component={ApplicationUsersListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.USER_APP.ADD}
      path={PATH.URL_APPLICATION_USER_ADD}
      component={ApplicationUsersAdd}
    />
    <AccessRoute
      exact
      pageId={IDS.USER_APP.EDIT}
      path={PATH.URL_APPLICATION_USER_EDIT}
      component={ApplicationUsersEdit}
    />
    <AccessRoute
      exact
      pageId={IDS.USER_TYPE.PAGE}
      path={PATH.URL_USER_TYPE_PRIVILEGES}
      component={UserTypePrivilegePage}
    />
    <AccessRoute
      exact
      pageId={IDS.CLIENT.PAGE}
      path={PATH.URL_CLIENT_USER_SETTINGS}
      component={ClientUserPrivilegesPage}
    />
    <AccessRoute
      exact
      pageId={IDS.COMPANY.PAGE}
      path={PATH.URL_COMPANY_USER_SETTINGS}
      component={CompanyUserPrivilegesPage}
    />
    <AccessRoute
      exact
      pageId={IDS.USER_LOG.PAGE}
      path={PATH.URL_CURRENT_ACTIVITY_LOG}
      component={CurrentActivityPage}
    />
    <AccessRoute
      exact
      pageId={IDS.LOGIN_LOG.PAGE}
      path={PATH.URL_LOGIN_LOG}
      component={LoginLogPage}
    />
    <AccessRoute
      exact
      pageId={IDS.AUDIT_LOG.PAGE}
      path={PATH.URL_AUDIT_TRAIL}
      component={AuditTrailPage}
    />
    <Route path="*" component={E404} />
  </Switch>
);

export default Privileges;
