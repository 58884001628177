import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { SelectCptCategory } from 'components/project/dropdown/CptCategoryDropdown';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';

import {
  storeCodeActivation,
  initStateFilter,
  FilterModel,
} from 'stores/_mobx/systemSetup/codeActivationMaster';

const options = [
  { label: 'Active', value: 'A' },
  { label: 'Inactive', value: 'I' },
];

interface PropsType {
  fetching: boolean;
  onFilterChange: (filter: FilterModel) => void;
}

const Filter = ({ fetching, onFilterChange }: PropsType) => {
  const { control, handleSubmit, watch, reset } = useForm<FilterModel>({
    defaultValues: storeCodeActivation.filter,
  });

  const handleClickReset = () => {
    reset(initStateFilter);
  };

  useEffect(() => {
    const subscription = watch((formValue) => {
      onFilterChange(formValue as FilterModel);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form onSubmit={handleSubmit(onFilterChange)}>
      <div className="row">
        <Input
          name="cptCode"
          label="CPT Code"
          className="col-md-6 col-lg-2"
          control={control}
        />
        <Input
          name="cptDescription"
          label="Description"
          className="col-md-6 col-lg-3"
          control={control}
        />
        <SelectCptCategory
          name="cptCategoryId"
          label="CPT Category"
          className="col-md-6 col-lg-3"
          control={control}
        />
        <Radio
          name="cptCodeFlag"
          label="State"
          className="col-sm-12 col-md-auto part-inline"
          disabled={fetching}
          options={options}
          control={control}
        />
        <ControlsLayout alignX="auto">
          <Button
            text="Reset"
            type="reset"
            variant="warning"
            onClick={handleClickReset}
          />
        </ControlsLayout>
      </div>
    </form>
  );
};

export default Filter;
