import { Control } from 'react-hook-form';

import { Checkbox } from 'components/form/checkbox';
import File from './File';

interface PropsType {
  control: Control<any>;
  field: { fileName: string; filePath: string; isCritical: boolean };
  index: number;
  className?: string;
}

const FileCheckbox = ({ control, field, index, className = '' }: PropsType) => (
  <div className={`d-flex ${className}`}>
    <Checkbox
      name={`files.${index}.isChecked`}
      className="my-auto"
      control={control}
    />
    <label htmlFor={`files.${index}.isChecked`}>
      <File {...field} />
    </label>
  </div>
);

export default FileCheckbox;
