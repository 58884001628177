import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import { FacilityType } from 'services/system-setup/master-setup/facility/FacilityDetailsService';

export const Actions = {
  getFacilityDetails: 'get-facility-details',
  clearFacilityDetails: 'clear-facility-details',
};

class FacilityState {
  facilityDetail: FacilityType | null = null;
}

class FacilityDetails extends ReduceStore<FacilityState, CommonPayload> {
  getInitialState() {
    return new FacilityState();
  }

  areEqual(state1: FacilityState, state2: FacilityState) {
    return false;
  }

  reduce(state: FacilityState, action: CommonPayload) {
    switch (action.type) {
      case Actions.getFacilityDetails:
        this.getState().facilityDetail = action.data
          ? {
              ...action.data,
              locationrefid: Number(action.data.locationrefid),
              posrefid: Number(action.data.posrefid),
              regionrefid: Number(action.data.regionrefid),
              staterefid: Number(action.data.staterefid),
            }
          : null;
        break;
      case Actions.clearFacilityDetails:
        this.getState().facilityDetail = null;
        break;
      default:
        break;
    }
    return state;
  }
}

const facilityDetailsStore = new FacilityDetails(appDispatcher);
export default facilityDetailsStore;
