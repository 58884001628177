import SimpleFacade from './SimpleFacade';
import Grid from '../Grid';

export default class SelectFacade extends SimpleFacade {
  constructor(columns: Array<any>, grid: Grid) {
    super(columns, grid);
    this.columns.unshift({
      title: grid.getSelectAllCheckbox(),
      className: 'width-50',
      name: 'select-all-column',
      maxWidth: 40,
      noSort: true,
      render: (value: any, row: { [key: string]: any }) =>
        grid.getRowSelectionCheckbox(value, row),
    });
  }
}
