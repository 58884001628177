import DraftsService from 'services/messages/draft/DraftsService';
import appDispatcher from 'dispatcher/AppDispatcher';
import { DraftMessageDetailsType } from 'services/messages/draft/DraftsService';

export default class DraftPageActions {
  static getDraftMessageDetails(id: string) {
    return DraftsService.getDraftMessageDetails(id).then(
      ({ SubjectBody }: { SubjectBody: DraftMessageDetailsType[] }) => {
        appDispatcher.dispatch({
          type: 'messages-draft-get-details',
          data: Array.isArray(SubjectBody) ? SubjectBody[0] : null,
        });
        return SubjectBody[0];
      }
    );
  }
}
