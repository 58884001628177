import React from 'react';

import MessageView from './MessageView';
import SendingApplicationsActions, {
  OrderRequestForward,
  OrderMessagesDto,
} from 'actions/system-setup/master-setup/master-settings/integrations/SendingApplicationsActions';

interface PIncomingMessage {
  callback?: () => void;
  order: OrderRequestForward;
}

class SIncomingMessage {
  bottomMessage: string = '';
  topMessage: string = '';
}

export default class IncomingMessage extends React.Component<
  PIncomingMessage,
  SIncomingMessage
> {
  constructor(props: PIncomingMessage) {
    super(props);
    this.state = new SIncomingMessage();
  }

  componentDidMount() {
    const { props } = this;
    if (props.order.external) {
      this.setState({
        bottomMessage: props.order.ack,
        topMessage: props.order.message,
      });
    } else {
      SendingApplicationsActions.loadLatestOru(
        props.order.latticeproOrderId
      ).then((res: OrderMessagesDto | { status: string }) => {
        const orderMessagesDto = res as OrderMessagesDto;
        this.setState({
          bottomMessage: orderMessagesDto.ack,
          topMessage: orderMessagesDto.message,
        });
      });
    }
  }

  render() {
    const { state, props } = this;
    return (
      <MessageView
        title="Incoming Orders"
        bottomMessage={state.bottomMessage}
        topMessage={state.topMessage}
        onClose={props.callback}
      />
    );
  }
}
