import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { ExamExportLink } from 'page/components/ExportLink';
import DialogAuditLog from 'page/workflow/order/components/DialogAuditLog';
import EncounterMessageDialog from 'page/components/messages/EncounterMessageDialog';
import VisitInformationPage from 'page/workflow/order/VisitInformationPage';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import { Button } from 'components/button';
import { Grid } from 'components/grid';
import Tooltip from 'components/tooltip';
import { IconButton } from 'components/button';
import { LayoutSideTitle } from 'components/layout';
import Filter from './components/Filter';

import UserProfileStore from 'stores/UserProfileStore';
import {
  storeCoding,
  CodingType,
  FilterType,
} from 'stores/_mobx/billingCodding/coding';
import { CODING as PAGE_ID } from 'constant/pagesId/billingCodding';
import { URL_CODING } from 'constant/path/billingCoding';

const getColumns = ({
  isClient,
  isDivisionEnabled,
}: {
  isClient: boolean;
  isDivisionEnabled: boolean;
}) =>
  [
    { name: 'refid', title: 'Claim #', className: 'text-nowrap' },
    { name: 'patientid', title: 'Patient ID', className: 'text-nowrap' },
    {
      name: 'last_name',
      title: 'Patient Last Name',
      className: 'text-icon-column',
      render: (lastName: string, row: CodingType) => (
        <div className="text-icon-wrapper">
          <span className="text-icon-pic">
            {row.patient_alert ? (
              <Tooltip
                body={row.patient_alert}
                theme="danger"
                title="Patient alert">
                <i className="bi bi-exclamation-triangle-fill text-danger" />
              </Tooltip>
            ) : null}
          </span>
          <span>{lastName}</span>
        </div>
      ),
    },
    { name: 'first_name', title: 'Patient First Name' },
    { name: 'patientdob', title: 'Date Of Birth', className: 'text-nowrap' },
    { name: 'dos', title: 'DOS', className: 'text-nowrap', dataType: 'date' },
    isDivisionEnabled ? { name: 'division', title: 'Division' } : null,
    { name: 'ins_last_verified', title: 'Last Verification Date' },
    { name: 'examtype', title: 'Modality' },
    { name: 'facilitynm', title: 'Facility Name' },
    { name: 'facility_type', title: 'Provider Type' },
    { name: 'pos', title: 'POS' },
    { name: 'technologyname', title: 'Technologist' },
    { name: 'coding_status', title: 'Coding Status' },
    { name: 'coded_uname', title: 'Coded By' },
    {
      name: 'examRequest',
      title: 'Exam Request',
      className: 'text-center',
      render: (value: undefined, { refid }: CodingType) => (
        <ExamExportLink key={refid} orderId={Number(refid)} />
      ),
    },
    {
      name: 'documents',
      title: 'Documents',
      render: (value: undefined, data: CodingType) => {
        const documentIsEnabled = data.enableEx || Boolean(data.face_sheet);

        const cn = `available ${documentIsEnabled ? '' : ' negative'}`;

        return (
          <div className={cn}>
            <IconButton
              onClick={() => storeCoding.setOrderForDocumentsPreview(data)}
              disabled={!documentIsEnabled}>
              <i
                className={
                  documentIsEnabled ? 'bi bi-check-circle' : 'bi bi-x-circle'
                }
              />
            </IconButton>
          </div>
        );
      },
    },
    isClient
      ? null
      : {
          name: 'encounterLog',
          title: 'Encounter Log',
          className: 'text-center',
          render: (v: any, { refid, hasLog }: CodingType) => (
            <IconButton
              className="text-primary"
              onClick={() => storeCoding.setIdForMessaging(refid)}>
              <i
                className={`bi ${
                  hasLog ? 'bi-envelope-check-fill' : 'bi-envelope'
                }`}
              />
            </IconButton>
          ),
        },
    {
      name: 'controls',
      title: 'Action',
      className: 'width-100 text-center',
      render: (v: any, coding: CodingType) => (
        <div className="control">
          <Link to={`${URL_CODING}/${coding.refid}`} className="bi bi-pencil" />
          <IconButton onClick={() => storeCoding.setCodingForAuditLog(coding)}>
            <i className="icon icon-auditlog" />
          </IconButton>
          <IconButton
            title="View Exam Info"
            onClick={() => storeCoding.setCodingForViewing(coding)}>
            <i className="bi bi-journal-medical" />
          </IconButton>
        </div>
      ),
    },
  ].filter(Boolean);

const CodingPage = ({ isDivisionEnabled }: PropsTypeWitDivisionSettings) => {
  const {
    fetching,
    codingList,
    codingTotal,
    codingForViewing,
    idForMessaging,
    codingForAuditLog,
    orderForDocsPreview,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeCoding;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const orderForPreview = codingForViewing || orderForDocsPreview;

  const columns = useMemo(
    () =>
      getColumns({
        isDivisionEnabled,
        isClient: UserProfileStore.isClientUser(),
      }),
    [isDivisionEnabled]
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleCloseMessageDialog = (wasMessageAdded: boolean) => {
    storeCoding.clearIdForMessaging();

    if (wasMessageAdded) storeCoding.getCodingListMain(isDivisionEnabled);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeCoding.setFilter(filter);

    setPaginationToStart();
  };

  useLayoutEffect(() => {
    storeCoding.setDefaultFilter();
    return storeCoding.clearCodingForViewing;
  }, []);

  useEffect(() => {
    storeCoding.getCodingListMain(isDivisionEnabled);
  }, [isDivisionEnabled, pagination]);

  return (
    <>
      {orderForPreview ? (
        <VisitInformationPage
          callback={storeCoding.clearCodingForViewing}
          defaultTab={codingForViewing ? 0 : 4}
          // @ts-ignore
          selectedOrder={orderForPreview}
        />
      ) : null}
      <div className={orderForPreview ? 'visually-hidden' : ''}>
        <LayoutSideTitle appId={PAGE_ID.PAGE} title="Coding">
          <Button
            variant="default"
            data-testid="toggleFilter"
            text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
            onClick={handleToggleFilter}
          />
        </LayoutSideTitle>

        <Filter
          isVisible={isFilterVisible}
          fetching={fetching}
          onSubmit={handleChangeFilter}
        />

        <Grid
          id="claim_management_coding_grid"
          columns={columns}
          onAjax={fetching}
          dataSource={codingList}
          dataSourceCount={codingTotal}
          pagination={pagination}
          onPaginationChange={setPagination}
          onDoubleClick={storeCoding.setCodingForViewing}
        />

        {codingForAuditLog && (
          <DialogAuditLog
            order={codingForAuditLog}
            onClose={storeCoding.clearCodingForAuditLog}
          />
        )}

        {idForMessaging ? (
          <EncounterMessageDialog
            id={idForMessaging}
            onClose={handleCloseMessageDialog}
          />
        ) : null}
      </div>
    </>
  );
};

export default withDivisionSettings(observer(CodingPage));
