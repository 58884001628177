import React from 'react';
import { startOfYear, endOfYear } from 'date-fns';

import Form from 'components/form/Form';
import Tooltip from 'components/tooltip';
import YearSelectionDropdown from 'components/project/dropdown/YearSelectionDropdown';
import MonthSelectionDropdown from 'components/project/dropdown/MonthSelectionDropdown';
import SimpleTimezoneDropdown from 'components/project/dropdown/SimpleTimezoneDropdown';
import Custom from 'components/form/input/Custom';
import ManageDocuments from 'components/project/modals/ManageDocuments';
import Validation from 'components/form/Validation';
import { Button, IconButton } from 'components/button';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import DialogConfirm from 'components/modal/dialogConfirm';
import { LayoutSideTitle } from 'components/layout';

import TimeSheetPageActions from 'actions/profile/personal/TimeSheetPageActions';
import DateUtils, { getDateString } from 'utils/DateUtils';

export interface PTimeSheetControl {
  year: number;
  month: number;
  week: number;
  userId: string;
  userName?: string;
  callback?: () => void;
  appId: string;
  status: -1 | 0 | 1; //-1 can't clockin, can't clockout. 0 - can clock in, 1 - can clock out
  isSubmitted?: boolean;
  isLocked?: boolean;
  enableSubmit?: boolean;
  updateCalendar: () => void;
  submitTimesheet: (status: boolean) => void;
  updateYear: (year: number) => void;
  updateMonth: (month: number) => void;
  updateWeek: (week: number) => void;
  clockIn: () => void;
  clockOut: () => void;
}

interface STimeSheetControl {
  weekMax: number;
  currentWeek: number;
  year: number;
  month: number;
  week: number;
  userId: string;
  submitTimesheet: boolean;
  from: string;
  to: string;
  timezone: string;
  errors: any;
  flagImportFileDialog: boolean;
  isHrAdmin: boolean;
  flagUnLockDialog: boolean;
  newIsSubmitted: boolean;
}

export default class TimeSheetControl extends React.Component<
  PTimeSheetControl,
  STimeSheetControl
> {
  constructor(props: PTimeSheetControl) {
    super(props);
    const date = new Date(props.year, props.month - 1);
    const weekMax = DateUtils.getWeekMaxNumber(date);
    this.state = {
      currentWeek: 0,
      year: props.year,
      month: props.month,
      week: props.week,
      weekMax,
      userId: props.userId,
      submitTimesheet: false,
      from: getDateString(),
      to: getDateString(),
      timezone: '',
      errors: {},
      flagImportFileDialog: false,
      isHrAdmin: false,
      flagUnLockDialog: false,
      newIsSubmitted: false,
    };
  }

  static getDerivedStateFromProps(
    props: PTimeSheetControl,
    state: STimeSheetControl
  ) {
    const date = new Date(props.year, props.month - 1);
    const weekMax = DateUtils.getWeekMaxNumber(date);

    return weekMax === state.weekMax ? null : { weekMax };
  }

  render() {
    const { state, props } = this;
    const className = 'col-sm-3';
    const tooltipClassName = 'col-sm-6';
    const tooltipInternalClassName = tooltipClassName;

    return (
      <div className="time-sheet-control">
        <LayoutSideTitle
          appId={this.props.userName ? null : this.props.appId}
          title={`Time Sheet ${
            this.props.userName ? `: ${this.props.userName}` : ''
          }`}>
          {props.callback ? (
            <Button text="Back" onClick={() => props.callback()} />
          ) : null}
        </LayoutSideTitle>

        {!props.callback && (
          <div style={{ marginBottom: 15 }} className="text-center">
            <input
              type="button"
              value="Clock In"
              disabled={!(props.status === 0) || props.isSubmitted}
              className="btn btn-lg btn-primary"
              style={{ marginRight: 15 }}
              onClick={() => props.clockIn()}
            />
            <input
              type="button"
              value="Clock Out"
              disabled={!(props.status === 1) || props.isSubmitted}
              className="btn btn-lg btn-primary"
              onClick={() => props.clockOut()}
            />
          </div>
        )}
        <div className="clearfix" />

        <Form
          className="import-form"
          onCollectValues={(name, value, errrors) =>
            this.onCollectValues(name, value, errrors)
          }
          submit={() => {}}
          errors={this.state.errors}>
          <YearSelectionDropdown
            className={className}
            label="Select Year"
            name="year"
            noClear
            value={props.year}
          />
          <MonthSelectionDropdown
            className={className}
            label="Select Month"
            name="month"
            noClear
            value={props.month}
          />
          <Custom
            className={className}
            label="&nbsp;"
            custom={
              <div className="week-toggler">
                <IconButton onClick={(event) => this.decrementWeek(event)}>
                  <i
                    className={`bi bi-caret-left-fill${
                      props.week > 0 ? '' : ' icon-disabled'
                    }`}
                  />
                </IconButton>
                <span className="font-large week">{`Week: ${
                  props.week + 1
                }`}</span>
                <IconButton onClick={(event) => this.incrementWeek(event)}>
                  <i
                    className={`bi bi-caret-right-fill${
                      props.week < state.weekMax ? '' : ' icon-disabled'
                    }`}
                  />
                </IconButton>
              </div>
            }
          />
          <SimpleTimezoneDropdown
            name="timezone"
            className={className}
            value={state.timezone}
            validations="required"
          />
          <div />
          <Tooltip body={this.getSubmitTooltip()} className={tooltipClassName}>
            <div className="row">
              <Custom
                className={tooltipInternalClassName}
                label="&nbsp;"
                custom={
                  <button
                    className="btn btn-primary"
                    onClick={() => this.submitTimesheet(!props.isSubmitted)}
                    disabled={props.isLocked || !props.enableSubmit}>
                    {props.isSubmitted
                      ? 'Unsubmit Timesheet'
                      : 'Submit Timesheet'}
                  </button>
                }
              />
              <Custom
                className={tooltipInternalClassName}
                label="&nbsp;"
                custom={
                  <button
                    className="btn btn-primary"
                    onClick={() => props.updateCalendar()}
                    disabled={props.isLocked}>
                    Update Calendar
                  </button>
                }
              />
            </div>
          </Tooltip>
          <Custom
            className={className}
            label="&nbsp;"
            custom={
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.setState({ flagImportFileDialog: true });
                }}>
                View Schedules
              </button>
            }
          />
          {props.isLocked && this.state.isHrAdmin && (
            <Custom
              className={className}
              label="&nbsp;"
              custom={
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({ flagUnLockDialog: true });
                  }}>
                  Unlock Timesheet
                </button>
              }
            />
          )}
        </Form>
        {this.renderDialogs()}
      </div>
    );
  }

  renderDialogs() {
    const { state, props } = this;
    if (!state.submitTimesheet && !state.flagImportFileDialog) {
      return null;
    }
    return (
      <>
        {state.submitTimesheet && (
          <Dialog handleClose={this.handleCloseDialogConfirmTimesheet}>
            <DialogHeader
              title="confirm"
              onClose={this.handleCloseDialogConfirmTimesheet}
            />
            <DialogBody>
              {props.isSubmitted
                ? 'You are about to unsubmit your timesheet. Are you sure you want to continue?'
                : 'You are about to submit your timesheet for review. Please make sure you have checked your hours accordingly.'}
            </DialogBody>
            <DialogFooter>
              <Button
                text={props.isSubmitted ? 'No' : 'Cancel'}
                variant="warning"
                onClick={this.handleCloseDialogConfirmTimesheet}
              />
              <Button
                text={props.isSubmitted ? 'Yes' : 'Submit'}
                onClick={this.approveSubmitTimesheet}
              />
            </DialogFooter>
          </Dialog>
        )}
        {state.flagImportFileDialog && (
          <ManageDocuments
            type="schedule"
            userId=""
            anchorDayDate={this.getInFocusDayDate()}
            title="Schedule Documents"
            flagViewOnly
            callback={() => this.setState({ flagImportFileDialog: false })}
          />
        )}
        {state.flagUnLockDialog && (
          <DialogConfirm
            title="Unlock Timesheet"
            onCancel={this.handleCloseDialogUnlockWeek}
            onApprove={this.handleApproveUnlockWeek}>
            Are you sure to unlock these days?
          </DialogConfirm>
        )}
      </>
    );
  }

  handleCloseDialogUnlockWeek = () => {
    this.setState({ flagUnLockDialog: false });
  };

  handleApproveUnlockWeek = () => {
    this.handleCloseDialogUnlockWeek();
    // not implemented
  };

  getInFocusDayDate() {
    return DateUtils.toDateCanonic(new Date().getTime());
  }

  handleCloseDialogConfirmTimesheet = () => {
    this.setState({ submitTimesheet: false });
  };

  approveSubmitTimesheet = () => {
    this.handleCloseDialogConfirmTimesheet();
    this.props.submitTimesheet(this.state.newIsSubmitted);
  };

  submitTimesheet(status: boolean) {
    this.setState({ submitTimesheet: true, newIsSubmitted: status });
  }

  onCollectValues(name: string, value: string, errors: any) {
    const { state, props } = this;
    const from = state.from.split('/');
    const to = state.to.split('/');
    if (name === 'month') {
      const month = parseInt('' + value);
      const newFrom =
        (month > 9 ? month : '0' + month) + ('/' + from[1] + '/' + from[2]);
      const newTo =
        (month > 9 ? month : '0' + month) + ('/' + to[1] + '/' + to[2]);
      this.setState({ from: newFrom, to: newTo }, () => {
        props.updateMonth(month);
      });
    } else if (name === 'year') {
      const year = String(value);
      const currentDate = new Date(year);
      const from = getDateString(startOfYear(currentDate));
      const to = getDateString(endOfYear(currentDate));
      this.setState({ from, to }, () => {
        // @ts-ignore
        props.updateYear(value);
      });
    } else {
      const newState = {
        ...state,
        [name]: value,
        errors: {
          ...state.errors,
          timezone: errors,
        },
      };
      this.setState(newState);
    }
  }

  decrementWeek(event: React.MouseEvent) {
    if (event) {
      event.preventDefault();
    }
    const { props } = this;
    let week = props.week;
    if (week > 0) {
      week = props.week - 1;
    } else if (week < 0) {
      week = 0;
    }
    TimeSheetPageActions.loadTimeSheet(
      props.userId,
      props.month,
      week,
      props.year
    ).then(() => props.updateWeek(week));
  }

  incrementWeek(event: React.MouseEvent) {
    if (event) {
      event.preventDefault();
    }
    const { state, props } = this;
    let week = props.week;
    if (week < 5) {
      week = props.week + 1;
    } else if (week > state.weekMax) {
      week = state.weekMax;
    }
    TimeSheetPageActions.loadTimeSheet(
      props.userId,
      props.month,
      week,
      props.year
    ).then(() => props.updateWeek(week));
  }

  setData(timeZone: string) {
    this.setState({
      timezone: timeZone || '',
    });
  }

  getTimeZone() {
    const { timezone, errors } = this.state;
    if (timezone) {
      return timezone;
    }
    this.setState({
      errors: Object.assign({}, errors, {
        timezone: [Validation.messages.required],
      }),
    });
    return null;
  }

  getSubmitTooltip() {
    return this.props.isLocked ? (
      <div>
        These hours are locked and have been submitted for pay
        <br />
        Please contact HR if there is an issue or change request to the hours
        already processed.
      </div>
    ) : (
      ''
    );
  }
}
