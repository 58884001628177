import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import {
  TTimeSheetFullInfoDoc,
  TTrackingData,
  TNotes,
  TCalendarData,
  TTimeSheet,
} from 'services/profile/personal/TimeSheetPageService';

class STimeSheetPageStore {
  userDocsList: Array<TTimeSheetFullInfoDoc> = [];
  importFileIsOpen: boolean = false;
  saveResponse: string = '';
  clockIn: -1 | 0 | 1 = -1;
  calendar: Array<TCalendarData> = [];
  tracking: Array<TTrackingData> = [];
  notes: TNotes = new TNotes();
}

class TimeSheetPageStore extends ReduceStore<
  STimeSheetPageStore,
  CommonPayload
> {
  getInitialState() {
    const state = new STimeSheetPageStore();
    return state;
  }

  areEqual(state1: STimeSheetPageStore, state2: STimeSheetPageStore) {
    return false;
  }

  reduce(state: STimeSheetPageStore, action: CommonPayload) {
    switch (action.type) {
      case 'my-profile-save-week-data':
        this.getState().saveResponse = action.data;
        break;
      case 'load-time-sheet-data':
        const timeSheet: TTimeSheet = action.data;
        this.getState().calendar = timeSheet.calendar;
        this.getState().tracking = timeSheet.tracking;
        this.getState().notes = timeSheet.notes;
        this.getState().clockIn = timeSheet.clockIn;
        break;
      case 'load_user_docs':
        this.getState().userDocsList = action.data;
        break;
      default:
        break;
    }
    return state;
  }
}

const timeSheetPageStore = new TimeSheetPageStore(appDispatcher);
export default timeSheetPageStore;
