import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { PureSelect } from 'components/form/select';

import { storeTaskManager } from 'stores/_mobx/workflow/serviceMaintenance/taskManager';

interface PropsType {
  onSubmit: (iuserId: number) => void;
  onClose: () => void;
}

const DialogAssignUser = ({ onClose, onSubmit }: PropsType) => {
  const { fetchingOptions, options, paramsForAssign } = storeTaskManager;

  const [userId, seUserId] = useState<number>(0);

  const [errorMessage, setError] = useState<string>('');

  const title = paramsForAssign.isAssignMode ? 'Assign' : 'Reassign';

  const handleChange = (value: number) => {
    seUserId(value);
    setError('');
  };

  const handleSubmit = () => {
    if (userId) {
      onSubmit(userId);
    } else {
      setError('Please, choose a Service User!');
    }
  };

  useEffect(() => {
    storeTaskManager.getServiceUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog scrollable={false} handleClose={onClose}>
      <DialogHeader title={title} onClose={onClose} />
      <DialogBody>
        <PureSelect
          label="Service User"
          required
          errorMessage={errorMessage}
          isLoading={fetchingOptions}
          options={options}
          onChange={handleChange}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          text={title}
          onClick={handleSubmit}
          disabled={fetchingOptions}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogAssignUser);
