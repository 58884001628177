import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import useDebounce from 'components/hook/useDebounce';
import { LayoutSideTitle } from 'components/layout';
import { IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import Filter from './components/Filter';

import {
  storePlaceOfService,
  FilterType,
} from 'stores/_mobx/systemSetup/masterSetting/placeOfService';
import { MS_PLACE_OF_SERVICE as PAGE_ID } from 'constant/pagesId/systemSetup';
import {
  URL_MASTER_SETTINGS,
  URL_PLACE_OF_SERVICE_ADD,
  URL_PLACE_OF_SERVICE_EDIT,
} from 'constant/path/systemSetup';

const renderControl = (id: number) => (
  <div className="control text-primary">
    <Link
      className="bi bi-pencil"
      title="Edit"
      to={URL_PLACE_OF_SERVICE_EDIT.replace(':id', `${id}`)}
    />
    <IconButton
      title="Delete"
      onClick={() => storePlaceOfService.setPlaceForDelete(id)}>
      <i className="bi bi-trash" />
    </IconButton>
  </div>
);

const columns = [
  { name: 'pos_code', title: 'Code' },
  { name: 'pos', title: 'Place of service' },
  { name: 'pos_desc', title: 'Description' },
  {
    name: 'data',
    title: 'Action',
    className: 'width-75',
    render: renderControl,
  },
];

const PlaceOfServiceListPage = () => {
  const gridRef = useRef(null);

  const {
    fetching,
    placeForDelete,
    placesCount,
    placesList,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storePlaceOfService;

  const [selected, setSelected] = useState<number[]>([]);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleChangeStatus = () => {
    const status = filter.state === 'A' ? 'Active' : 'Inactive';

    storePlaceOfService
      .updateMultiplePlacesStatus({ ids: selected, status })
      .then((isSucceed) => {
        if (isSucceed) storePlaceOfService.getPlacesMain();
      });
  };

  const handleConfirmDelete = () => {
    storePlaceOfService.deletePlace().then((isSucceed) => {
      if (isSucceed) storePlaceOfService.getPlacesMain();
    });
  };

  const handleChangeFilter = (filter: FilterType) => {
    storePlaceOfService.changeFilter(filter);
    debouncedMethod();
  };

  useEffect(() => {
    storePlaceOfService.getPlacesMain();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Place of Service">
        <Link to={URL_MASTER_SETTINGS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      <Filter onChange={handleChangeFilter} />

      <Grid
        id="place_of_service_grid"
        columns={columns}
        onAjax={fetching}
        selectId="data"
        onSelectChange={setSelected}
        dataSource={placesList}
        dataSourceCount={placesCount}
        pagination={pagination}
        onPaginationChange={setPagination}
        ref={gridRef}
        gridControlPanel={
          <>
            <GridControlButton title="Add" url={URL_PLACE_OF_SERVICE_ADD} />
            <GridControlButton
              title={filter.state === 'A' ? 'Deactivate' : 'Activate'}
              disabled={!selected.length}
              onClick={handleChangeStatus}
            />
          </>
        }
      />
      {placeForDelete !== null && (
        <DialogConfirm
          onCancel={storePlaceOfService.clearPlaceForDelete}
          onApprove={handleConfirmDelete}>
          Are you sure you want to delete?
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(PlaceOfServiceListPage);
