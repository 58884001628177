import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import DropdownBillingJurisdiction from 'components/project/dropdown/BillingJurisdiction';
import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { Button } from 'components/button';
import resolver from './validation';

const initialState: FormValuesType = {
  bill_jurisdictionid: 0,
  code: 'Q0092',
  facility_price: '',
  insurance_price: '',
  no_of_patient: 1,
  stateid: 0,
};

interface FormValuesType {
  bill_jurisdictionid: number;
  code: string;
  facility_price: number | '';
  insurance_price: number | '';
  no_of_patient: number;
  refid?: number;
  stateid: number;
}

export interface FormModel
  extends Omit<FormValuesType, 'facility_price' | 'insurance_price'> {
  facility_price: number;
  insurance_price: number;
}

interface PropsType {
  defaultValues?: FormValuesType;
  backUrl: string;
  onSubmit: (payload: FormModel) => Promise<null | { message: string }>;
}

const FormCode = ({
  defaultValues = initialState,
  backUrl,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    watch,
    setValue,
    handleSubmit,
    setError,
  } = useForm({ defaultValues, resolver });

  const cn = clsx('col-lg-10 col-xl-8', {
    'on-ajax': isSubmitting,
  });

  const stateId = watch('stateid');

  const handleReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data) =>
    onSubmit(data as FormModel).then((error) => {
      if (error) {
        setError('bill_jurisdictionid', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'stateid') {
        setValue('bill_jurisdictionid', null);
      }
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form onSubmit={handleClickSubmit} className={cn}>
      <Input name="code" label="Code" disabled control={control} />
      <Input
        name="no_of_patient"
        label="Number of units / studies"
        disabled
        control={control}
      />
      <div className="row">
        <DropDownState
          className="col-md-6"
          name="stateid"
          label="State"
          required
          control={control}
        />
        <DropdownBillingJurisdiction
          name="bill_jurisdictionid"
          label="Billing jurisdiction"
          className="col-md-6"
          required
          stateId={stateId}
          control={control}
        />
      </div>

      <div className="row">
        <Input
          name="facility_price"
          label="Facility price"
          className="col-md-6"
          required
          control={control}
        />
        <Input
          name="insurance_price"
          label="Insurance price"
          className="col-md-6"
          required
          control={control}
        />
      </div>
      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          className="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={!isDirty || isSubmitting}
        />
      </ControlsLayout>
    </form>
  );
};

export default observer(FormCode);
