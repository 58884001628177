import ClaimsService, {
  TClaimManagerFilter,
  TClaimManager,
} from 'services/billing-coding/claims/ClaimsService';
import appDispatcher from 'dispatcher/AppDispatcher';
import { TPagination } from 'components/grid/GridTypes';
import { getDateRangeBounds, getDayBounds } from 'utils/DateUtils';

export class TClaimManagerFilterExt extends TClaimManagerFilter {
  batched: string = '';
  batchedDate: string = '';
}

export default class PcClaimsActions {
  static loadOverview(
    { batchedDate, ...filterPayload }: TClaimManagerFilterExt,
    pagination: TPagination,
    isDivisionEnabled: boolean
  ) {
    let count = 0;

    const batchDate =
      filterPayload.batched === 'B' && batchedDate
        ? getDayBounds(batchedDate)
        : '';

    const dos = getDateRangeBounds({
      from: filterPayload.dosStart,
      to: filterPayload.dosEnd,
    });

    const filter = {
      ...filterPayload,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
      division: isDivisionEnabled ? filterPayload.division : 0,
    };

    return ClaimsService.loadOverviewCount(
      filter,
      'A',
      filter.batched,
      '',
      'P',
      '',
      '',
      batchDate,
      filter.inJurisdiction
    )
      .then((response: 'SE' | number) => {
        count = response === 'SE' || !response ? 0 : parseInt('' + response);
        if (count > 0) {
          return ClaimsService.loadOverview(
            filter,
            pagination,
            'A',
            filter.batched,
            '',
            'P',
            '',
            '',
            batchDate,
            filter.inJurisdiction
          );
        }
        return [];
      })
      .then((response: 'SE' | 0 | Array<TClaimManager>) => {
        response = !response || response === 'SE' ? [] : response;
        appDispatcher.dispatch({
          type: 'claim-pc-overview',
          data: response,
          count: count,
        } as any);
        return [];
      });
  }

  static revertPos(claimUniqIds: Array<string>) {
    return ClaimsService.revertPos(claimUniqIds);
  }

  static assignPosTo31(claimUniqIds: Array<string>) {
    return ClaimsService.assignPosTo31(claimUniqIds);
  }

  static createBatch(claimUniqIds: Array<string>) {
    return ClaimsService.createBatch(claimUniqIds, 'P');
  }
}
