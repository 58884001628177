import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface SentResponseType {
  cc: string;
  mail_date: string;
  mail_subject: string;
  mail_time: string;
  mail_to: string;
  messdetail_id: string;
  refid: string;
}

interface SentType {
  cc: string;
  subject: string;
  date: string;
  time: string;
  recipient: string;
  id: number;
}

export interface MessagePreviewType {
  body: string;
  date: string;
  from: string;
  subject: string;
  to: string;
}

class Sent {
  private numbersOfRequests = 0;

  fetching: boolean = false;
  sentList: SentType[] = [];
  sentTotal: number = 0;
  filter: string = '';

  page: Pagination = new Pagination({ id: 'sent_page_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      sentList: observable,
      sentTotal: observable,
      filter: observable,

      setFetching: action,
      setSentList: action,
      setSentTotal: action,
      setFilter: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setSentList(list: SentType[]) {
    this.sentList = list;
  }

  setSentTotal(count: number) {
    this.sentTotal = count;
  }

  setFilter(subject: string) {
    this.filter = subject;
  }

  async getSentCount(filter: string) {
    try {
      const count = await apiRequest<string>({
        url: 'email.Email.SentItemTotalCount',
        data: [filter],
      });
      this.setSentTotal(Number(count) || 0);
    } catch (e: any) {
      this.setSentTotal(0);
    }
  }

  async getSentList(filter: string) {
    const { pagination } = this.page;

    const data = {
      StartIndex: pagination.skip,
      Limit: pagination.pageSize,
      search: filter,
    };

    try {
      const response = await apiRequest<SentResponseType[]>({
        url: 'email.Email.EmailSentItemList',
        data,
      });

      const list = response.map((mail) => {
        const [date, time] = dateToLocalTimezone({
          date: `${mail.mail_date} ${mail.mail_time}`,
        }).split(' ');

        return {
          date,
          time,
          cc: mail.cc,
          recipient: mail.mail_to,
          subject: mail.mail_subject,
          id: Number(mail.refid),
        };
      });

      this.setSentList(list);
    } catch (e: any) {
      this.setSentList([]);
    }
  }

  getSentListMain = async (filter = this.filter) => {
    this.numbersOfRequests += 1;

    this.setFetching(true);

    const promiseCount = this.getSentCount(filter);

    const promiseReminderList = this.getSentList(filter);

    Promise.allSettled([promiseCount, promiseReminderList]).finally(() => {
      this.numbersOfRequests -= 1;

      const fetching = Boolean(this.numbersOfRequests);

      this.setFetching(fetching);
    });
  };

  async moveToTrash(ids: number[]) {
    this.setFetching(true);
    try {
      const payload = ids.map((id) => ({
        flag: 'T',
        stat: 'SendItem',
        mode: 'M',
        idvalue: Number(id),
      }));
      const response = await apiRequest<'A' | 'D' | 'R' | 'T' | 'SE' | null>({
        url: 'email.Email.MoveEmail',
        data: [payload],
      });

      if (response === 'T') {
        Notification.success(
          `${ids.length} email${
            ids.length > 1 ? 's' : ''
          } deleted successfully!`
        );
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("You can't delete this email!");
      this.setFetching(false);
      return false;
    }
  }
}

export const storeSent = new Sent();
