import { Container } from 'flux/utils';

import AbstractPreInvoices, {
  PAbstractPreInvoices,
  SAbstractPreInvoices,
} from './AbstractPreInvoices';

const GRID_ID = 'occ_hlth_invoice_grid';

class OccHlthInvoice extends AbstractPreInvoices<
  PAbstractPreInvoices,
  SAbstractPreInvoices
> {
  getGridId() {
    return GRID_ID;
  }

  static getStores() {
    return AbstractPreInvoices.getStores();
  }

  static calculateState(prevState: SAbstractPreInvoices) {
    return AbstractPreInvoices.calculateState(prevState, GRID_ID);
  }

  getInvoiceType(): 'D' | 'F' | 'H' {
    return 'H';
  }
}

export default Container.create(OccHlthInvoice);
