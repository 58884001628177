import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SpinnerFixed } from 'components/spinner';
import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeVehicle,
  VehicleFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/vehicle';
import { URL_VEHICLE } from 'constant/path/workflow';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const VehicleEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, vehicle } = storeVehicle;

  const handleSubmit = async (data: VehicleFormModel) =>
    storeVehicle.updateVehicle(data).then((error) => {
      if (error) return error;
      history.push(URL_VEHICLE);
    });

  useEffect(() => {
    if (!fetching) storeVehicle.getVehicle(Number(params.id));

    return storeVehicle.clearVehicle;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit DR Plate" />
      <Form
        backUrl={URL_VEHICLE}
        defaultValues={vehicle}
        onSubmit={handleSubmit}
      />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(VehicleEditPage);
