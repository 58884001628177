import { GridControlButton } from 'components/grid';

import AccessUtils from 'utils/AccessUtils';
import { FACILITY_CONTACT as PAGE_ID } from 'constant/pagesId/systemSetup';
import { URL_FACILITY_CONTACTS_ADD } from 'constant/path/systemSetup';

interface PropsType {
  isActive: boolean;
  isContactSelected: boolean;
  onChangeStatus: () => void;
}

const ControlPanel = ({
  isActive,
  isContactSelected,
  onChangeStatus,
}: PropsType) => (
  <>
    {AccessUtils.checkAccess(PAGE_ID.ADD) && (
      <GridControlButton title="Add" url={URL_FACILITY_CONTACTS_ADD} />
    )}
    {AccessUtils.checkAccess(isActive ? PAGE_ID.INACTIVE : PAGE_ID.ACTIVE) && (
      <GridControlButton
        title={isActive ? 'Deactivate' : 'Activate'}
        onClick={onChangeStatus}
        disabled={!isContactSelected}
      />
    )}
  </>
);

export default ControlPanel;
