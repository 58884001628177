import { FormCompanyType } from 'stores/_mobx/systemSetup/masterSetting/companyInformation';

export const getBillingData = (formValue: FormCompanyType) => ({
  billingphone: formValue.billingphone,
  billingstate: formValue.billingstate,
  billingemail: formValue.billingemail,
  billingtollfree: formValue.billingtollfree,
  billingzip: formValue.billingzip,
  billingcity: formValue.billingcity,
  billingfax: formValue.billingfax,
  billingaddress: formValue.billingaddress,
});

export const getFacilityData = (formValue: FormCompanyType) => ({
  billingphone: formValue.phone,
  billingstate: formValue.state,
  billingemail: formValue.email,
  billingtollfree: formValue.tollfreenum,
  billingzip: formValue.zipcode,
  billingcity: formValue.city,
  billingfax: formValue.fax,
  billingaddress: formValue.address,
});
