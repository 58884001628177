import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone, getDayBounds } from 'utils/DateUtils';

export const initialValueFilter: FilterType = {
  technologistId: 0,
  checkInDate: '',
  checkOutDate: '',
  vehicle: '',
  ekg: '',
  phone: '',
};

interface LogResponseType {
  check_in_dt: string;
  check_out_dt: string;
  device_name: string;
  ekg_name: string;
  phone_desc: string;
  phone_no: string;
  tech_firstnm: string;
  tech_lastnm: string;
  tootip: string;
  vehicle_name: string;
}

export interface LogType
  extends Omit<LogResponseType, 'tech_firstnm' | 'tech_lastnm'> {
  technologist: string;
}

export interface FilterType {
  technologistId: number;
  checkInDate: string;
  checkOutDate: string;
  vehicle: string;
  ekg: string;
  phone: string;
}

class CheckOutHistory {
  fetching: boolean = false;
  logs: LogType[] = [];
  logsTotal: number = 0;
  filter: FilterType = initialValueFilter;
  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'checkOutHistoryGrid' });

    makeObservable(this, {
      fetching: observable,
      logs: observable,
      logsTotal: observable,
      filter: observable,

      setFetching: action,
      setLogsCount: action,
      setLogs: action,
      setFilter: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setLogsCount(total: number) {
    this.logsTotal = total;
  }

  setLogs(logs: LogType[]) {
    this.logs = logs;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  async getLogsCount(data: any[]) {
    try {
      const count = await apiRequest<number>({
        url: 'vehicle.VehicleHistory.VehicleHistoryCount',
        data,
      });

      this.setLogsCount(Number(count) || 0);
    } catch (e: any) {
      this.setLogsCount(0);
    }
  }

  async getLogsList(filter: any[]) {
    const { pagination } = this.page;

    const data = [...filter, pagination.skip, pagination.pageSize];

    try {
      const response = await apiRequest<LogResponseType[]>({
        url: 'vehicle.VehicleHistory.VehicleHistoryList',
        data,
      });

      const logs = response.map(
        ({
          tech_firstnm,
          tech_lastnm,
          check_in_dt,
          check_out_dt,
          ...rest
        }) => ({
          ...rest,
          technologist: `${tech_firstnm} ${tech_lastnm}`.trim(),
          check_in_dt: dateToLocalTimezone({
            date: check_in_dt.trim(),
          }),
          check_out_dt: dateToLocalTimezone({
            date: check_out_dt.trim(),
          }),
        })
      );

      this.setLogs(logs);
    } catch (e: any) {
      this.setLogs([]);
    }
  }

  getLogsLisMain = (filter: FilterType = this.filter) => {
    this.setFetching(true);

    const checkInDate = getDayBounds(filter.checkInDate);

    const checkOutDate = getDayBounds(filter.checkOutDate);

    const filterPayload = [
      filter.technologistId,
      checkOutDate.dateFrom,
      checkOutDate.dateTo,
      checkInDate.dateFrom,
      checkInDate.dateTo,
      filter.vehicle,
      filter.ekg,
      filter.phone,
    ];

    const promiseList = this.getLogsList(filterPayload);

    const promiseCount = this.getLogsCount(filterPayload);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };
}

export const storeCheckOutHistory = new CheckOutHistory();
