import { ReduceStore } from 'flux/utils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';
import {
  TOrderDetails,
  TExamCodeDetail,
} from 'services/billing-coding/claims/ClaimsService';

export class PClaimStore extends CommonPayload {}

export class SClaimStore {
  orderDetails: TOrderDetails | null = null;
  orderDetailsLoaded: boolean = false;
  examCode: TExamCodeDetail = new TExamCodeDetail();
  examCodeLoaded: boolean = false;
}

class ClaimStore extends ReduceStore<SClaimStore, PClaimStore> {
  getInitialState() {
    return new SClaimStore();
  }

  areEqual(state1: SClaimStore, state2: SClaimStore) {
    return false;
  }

  reduce(state: SClaimStore, action: PClaimStore) {
    const storeState = this.getState();
    switch (action.type) {
      case 'facility.FacilityMaster.GetOrderDetails':
        storeState.orderDetails = action.data;
        storeState.orderDetailsLoaded = (action as any).loaded;
        break;
      case 'facility.ClaimManager.GetExamCodeDetails':
        storeState.examCode = action.data;
        storeState.examCodeLoaded = (action as any).loaded;
        break;
      default:
        break;
    }
    return storeState;
  }
}

export default new ClaimStore(appDispatcher);
