import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

export const formDefaultValue: FormType = {
  id: 0,
  name: '',
  description: '',
  facilityId: 0,
  userType: 'U',
  members: [],
};

export interface EmailGroupType {
  facilityname: string;
  groupdesc: string;
  groupname: string;
  refid: number;
  totalmemcount: number;
  userid: number;
}

export interface FormType {
  id: number;
  name: string;
  description: string;
  facilityId: number;
  userType: 'U';
  members: UserType[];
}

interface GroupDetailResponseType {
  0: { group_nm: string; group_desc: string };
  member_id: number[];
}

interface UserResponseType {
  UsertypeName: string;
  facility_id: number;
  facilityname: string;
  first_name: string;
  last_name: string;
  name: string;
  refid: number;
  user_title: string;
}

export interface UserType {
  type: string;
  facilityId: number;
  facilityName: string;
  firstName: string;
  lastName: string;
  label: string;
  value: number;
  refid: number;
  title: string;
}

class EmailGroup {
  fetching: boolean = false;
  groupList: EmailGroupType[] = [];
  groupTotal: number = 0;
  groupDetail: FormType = formDefaultValue;
  availableUsers: UserType[] = [];
  idForDelete: number = 0;

  page: Pagination = new Pagination({ id: 'email_group_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      groupList: observable,
      groupTotal: observable,
      groupDetail: observable,
      availableUsers: observable,
      idForDelete: observable,

      setFetching: action,
      setGroupList: action,
      setGroupTotal: action,
      setIdForDelete: action.bound,
      clearGroupDetails: action.bound,
      clearIdForDelete: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setGroupList(groupList: EmailGroupType[]) {
    this.groupList = groupList;
  }

  setGroupTotal(count: number) {
    this.groupTotal = count;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  clearIdForDelete() {
    this.idForDelete = 0;
  }

  clearGroupDetails() {
    this.groupDetail = null;
    this.availableUsers = [];
  }

  async getGroupCount(groupName: string) {
    try {
      const count = await apiRequest<string>({
        url: 'email.GroupMessage.GetGroupCount',
        data: ['U', groupName],
      });

      this.setGroupTotal(Number(count) || 0);
    } catch (e) {
      this.setGroupTotal(0);
    }
  }

  async getGroupList(groupName: string) {
    const { pagination } = this.page;

    const data = ['U', pagination.skip, pagination.pageSize, groupName];

    try {
      const response = await apiRequest<EmailGroupType[]>({
        url: 'email.GroupMessage.GetAllGroup',
        data,
      });

      const groupList = response.map((group) => ({
        ...group,
        refid: Number(group.refid),
        totalmemcount: Number(group.totalmemcount),
        userid: Number(group.userid),
      }));

      this.setGroupList(groupList);
    } catch (e) {
      this.setGroupList([]);
    }
  }

  getGroupListMain = (groupName: string) => {
    this.setFetching(true);

    const promiseList = this.getGroupList(groupName);

    const promiseCount = this.getGroupCount(groupName);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getGroupDetail(id: number) {
    try {
      const response = await apiRequest<GroupDetailResponseType>({
        url: 'email.GroupMessage.GetUserById',
        data: [id],
      });

      const details = {
        name: response[0].group_nm,
        description: response[0].group_desc,
        members: response.member_id.map((id) => Number(id)),
      };

      return details;
    } catch (e: any) {
      return {
        name: '',
        description: '',
        members: [],
      };
    }
  }

  async getAvailableUsers() {
    try {
      const count = await apiRequest<string>({
        url: 'email.GroupMessage.GetUserMembersCount',
        data: ['', '', '', 0, 0],
      });

      const response = await apiRequest<UserResponseType[]>({
        url: 'email.GroupMessage.GetUserMembers',
        data: ['', '', '', 0, 0, 0, Number(count) || 0],
      });

      return response.map((user) => ({
        type: user.UsertypeName,
        facilityId: user.facility_id,
        facilityName: user.facilityname,
        firstName: user.first_name,
        lastName: user.last_name,
        label: user.name,
        value: Number(user.refid),
        refid: Number(user.refid),
        title: user.user_title,
      }));
    } catch (error) {
      return [];
    }
  }

  async getGroupFullDetails(id?: number) {
    this.setFetching(true);
    try {
      const detailsPromise = id
        ? this.getGroupDetail(id)
        : Promise.resolve({ name: '', description: '', members: [] });

      const userPromise = this.getAvailableUsers();

      const [details, users] = await Promise.all([detailsPromise, userPromise]);

      const members = details.members.map((id) =>
        users.find(({ refid }) => id === refid)
      );

      details.members.forEach((id) => {
        const index = users.findIndex(({ refid }) => refid === id);
        if (~index) users.splice(index, 1);
      });

      const groupDetails = {
        ...formDefaultValue,
        ...details,
        id,
        members,
      };

      runInAction(() => {
        this.groupDetail = groupDetails;
        this.availableUsers = users;
        this.fetching = false;
      });
    } catch (e: any) {
      runInAction(() => {
        this.groupDetail = formDefaultValue;
        this.availableUsers = [];
        this.fetching = false;
      });
    }
  }

  async addGroup({ members, ...rest }: FormType) {
    try {
      const response = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'email.GroupMessage.AddGroup',
        data: [
          rest.name,
          rest.description,
          rest.facilityId,
          rest.userType,
          members.map(({ refid }) => refid),
        ],
      });

      if (response === 'S') {
        Notification.success('Email group successfully created!');
        return null;
      }
      if (response === 'E') {
        return {
          message: 'This group name already exists!',
        };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Error occurred!');
      return { message: '' };
    }
  }

  async updateGroup({ members, ...rest }: FormType) {
    try {
      const response = await apiRequest<'SE' | 'S' | 'E'>({
        url: 'email.GroupMessage.EditGroup',
        data: [
          rest.id,
          rest.name,
          rest.description,
          rest.facilityId,
          rest.userType,
          members.map(({ refid }) => refid),
        ],
      });

      if (response === 'S') {
        Notification.success('Email group successfully updated!');
        return null;
      }
      if (response === 'E') {
        return {
          message: 'This group name already exists!',
        };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger('Error occurred!');
      return { message: '' };
    }
  }

  async deleteGroup() {
    this.setFetching(true);
    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'email.GroupMessage.DeleteGroup',
        data: [this.idForDelete],
      });

      if (response === 'S') {
        Notification.success('Email group successfully deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (error) {
      this.setFetching(false);
      return false;
    }
  }
}

export const storeEmailGroup = new EmailGroup();
