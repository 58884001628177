import { makeObservable, action, observable } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

export const filterDefaultValues: FilterType = {
  accessionNumber: '',
  scheduleDate: '',
  medicalRecordNumber: '',
  firstName: '',
  middleName: '',
  lastName: '',
  birthDate: '',
  gender: '',
  refPhysicianName: '',
};

export interface FilterType {
  accessionNumber: string;
  scheduleDate: string;
  medicalRecordNumber: string;
  firstName: string;
  middleName: string;
  lastName: string;
  birthDate: string;
  gender: 'M' | 'F' | '';
  refPhysicianName: string;
}

export interface PatientType {
  accession_no: string;
  birth_date: string;
  cpt_codes: string;
  first_name: string;
  gender: 'M' | 'F' | 'U' | '';
  last_name: string;
  medical_record_number: string;
  middle_name: string;
  modalities: string;
  ordering_facility: string;
  reading_physician_name: string;
  referring_physician_name: string;
  schedule_date: string;
  study_description: string;
}

interface PatientsResponseType {
  count: { 'count(legacy_patient_info.id)': string };
  data: PatientType[];
}

class LegacyPatientData {
  fetching: boolean = false;
  patients: PatientType[] = [];
  patientsTotal: number = 0;
  filter: FilterType = filterDefaultValues;

  page = new Pagination({ id: 'exam_info_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      patients: observable,
      patientsTotal: observable,
      filter: observable,

      setFetching: action,
      setPatients: action,
      setFilter: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setPatients({
    patients,
    patientsTotal,
  }: {
    patients: PatientType[];
    patientsTotal: number;
  }) {
    this.patients = patients;
    this.patientsTotal = patientsTotal;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  async getPatients() {
    const {
      page: { pagination },
      filter,
    } = this;

    const data = [
      filter.accessionNumber,
      filter.scheduleDate,
      filter.medicalRecordNumber,
      filter.firstName,
      filter.middleName,
      filter.lastName,
      filter.birthDate,
      filter.gender,
      filter.refPhysicianName,
      pagination.skip,
      pagination.pageSize,
    ];

    this.setFetching(true);
    try {
      const response = await apiRequest<PatientsResponseType>({
        url: 'medicalrecord.MedicalRecord.legacyPatientData',
        data,
      });

      const patientsTotal = Number(
        response.count['count(legacy_patient_info.id)']
      );

      const patients = response.data;

      this.setPatients({ patients, patientsTotal });
    } catch (e: any) {
      this.setPatients({
        patients: [],
        patientsTotal: 0,
      });
    } finally {
      this.setFetching(false);
    }
  }

  async openEUnityLinkByParams(patient: PatientType) {
    const data = [patient.accession_no, patient.medical_record_number];
    try {
      const url = await apiRequest<string>({
        url: 'medicalrecord.MedicalRecord.generateEunityLink',
        data,
      });

      window.open(url, '_blank').focus();
    } catch (e: any) {}
  }
}

export const storeLegacyPatientData = new LegacyPatientData();
