import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { Checkbox } from 'components/form/checkbox';
import { InputCalendar } from 'components/form/inputCalendar';
import { Input } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';
import { ControlsLayout } from 'components/layout';

import {
  storeCalibrationRecords,
  defaultFilter,
  FilterType,
} from 'stores/_mobx/workflow/serviceMaintenance/calibrationRecords';

const className = 'col-md-6 col-lg-3';

const EQUIPMENT_TYPE = [
  { value: 'device', label: 'Ultrasound' },
  { value: 'ekg', label: 'EKG' },
  { value: 'gen', label: 'Generator' },
  { value: 'plate', label: 'Plate' },
  { value: 'xray', label: 'X-ray' },
];

interface PropsType {
  visibility: boolean;
  fetching: boolean;
  filterType: 'filterMaintenance' | 'filterMaintenanceDue';
}

const Filter = ({ visibility, fetching, filterType }: PropsType) => {
  const { control, watch, reset } = useForm({
    defaultValues: storeCalibrationRecords[filterType],
  });

  const setFilterDebounced = useDebounce(
    (props: FilterType) => storeCalibrationRecords.setFilter(props, filterType),
    400
  );

  const handleReset = () => {
    reset(defaultFilter);
  };

  useEffect(() => {
    const subscription = watch((formValue) => {
      setFilterDebounced(formValue as FilterType);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form className={`row${visibility ? '' : ' visually-hidden'}`}>
      <Input
        name="name"
        label="Equipment Name"
        className={className}
        control={control}
      />
      <InputCalendar
        name="date"
        label="Next Calibration Date"
        className={className}
        control={control}
      />

      <div className="col-lg-8 col-xl-auto">
        <div className="form-label">
          <b>Equipment Type</b>
        </div>
        <div className="d-flex">
          {EQUIPMENT_TYPE.map(({ value, label }) => (
            <Checkbox
              key={value}
              name={`equipmentType.${value}`}
              label={label}
              control={control}
              className="mx-3"
            />
          ))}
        </div>
      </div>

      <ControlsLayout alignX="auto">
        <Button
          text="Reset"
          variant="warning"
          disabled={fetching}
          onClick={handleReset}
        />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
