import { ColumnsDataType } from 'components/gridModern';

import {
  diagnosisCellRender,
  claimCodeCellRender,
  fullDateFormatter,
  linkToPdfCellRender,
  timeDurationCellRender,
} from './CellRenders';

import { ExamInfoType } from 'stores/_mobx/patientRecord/examInfo';
import { ExamQaType } from 'stores/_mobx/patientRecord/examQA';

export const examInfoColumns: ColumnsDataType<ExamInfoType> = [
  { field: 'id', headerName: 'Study ID', width: 100 },
  { field: 'pcBillProcessView', headerName: 'Bill Type', sortable: false },
  {
    field: 'facility.perDiemBillTypeView',
    headerName: 'Fee Type',
    sortable: false,
  },
  {
    headerName: 'Patient',
    marryChildren: true,
    openByDefault: false,
    children: [
      {
        field: 'patient.firstName',
        headerName: 'First name',
        width: 150,
        sortable: false,
      },
      {
        field: 'patient.lastName',
        headerName: 'Last name',
        width: 150,
        sortable: false,
      },
      {
        field: 'patient.DOB',
        headerName: 'DOB',
        headerTooltip: 'Date of birth',
        width: 120,
        hide: true,
        sortable: false,
      },
      {
        field: 'patient.genderView',
        headerName: 'Gender',
        columnGroupShow: 'open',
        width: 100,
        hide: true,
        sortable: false,
      },
      {
        field: 'patient.patientMrnFormatted',
        headerName: 'MRN',
        headerTooltip: 'Medical record number',
        width: 130,
        hide: true,
        sortable: false,
      },
      {
        field: 'patient.clientMrn',
        headerName: 'Other MRN',
        headerTooltip: 'Other medical record number',
        width: 130,
        hide: true,
        sortable: false,
      },
      {
        field: 'patient.iceNumber',
        headerName: 'ICE Number',
        columnGroupShow: 'open',
        sortable: false,
        hide: true,
      },
      {
        field: 'patient.inmateNumber',
        headerName: 'OID Number',
        columnGroupShow: 'open',
        sortable: false,
        hide: true,
      },
      {
        field: 'patient.immigrationId',
        headerName: 'Marshall ID',
        columnGroupShow: 'open',
        sortable: false,
        hide: true,
      },
      {
        field: 'patient.smokingView',
        headerName: 'Tobacco Use',
        columnGroupShow: 'open',
        sortable: false,
        hide: true,
      },
    ],
  },
  {
    field: 'scheduleServiceDate',
    headerName: 'DOS',
    headerTooltip: 'Date of service',
    width: 120,
    sortable: false,
  },
  { field: 'modality.name', headerName: 'Modality', sortable: false },
  {
    field: 'facility.facilityType.facilityType',
    headerName: 'Assignment',
    sortable: false,
  },
  {
    field: 'claimProceduresCodes',
    headerName: 'CPT',
    colId: 'cpt',
    sortable: false,
    cellRenderer: claimCodeCellRender,
  },
  {
    field: 'icdCodesDescriptions',
    headerName: 'Diagnosis',
    width: 200,
    sortable: false,
    cellRenderer: diagnosisCellRender,
  },
  {
    field: 'facility.id',
    headerName: 'Facility ID',
    sortable: false,
    width: 200,
  },
  {
    field: 'facility.facilityName',
    headerName: 'Facility',
    sortable: false,
    width: 200,
  },
  {
    field: 'facility.placeOfService.placeOfServiceName',
    headerName: 'POS',
    headerTooltip: 'Place of service',
    sortable: false,
    width: 200,
  },
  {
    field: 'provider.fullName',
    headerName: 'Referring MD/NPP',
    colId: 'providerFullName',
    sortable: false,
    hide: true,
    width: 150,
  },
  {
    field: 'provider.npi',
    headerName: 'Referring NPI',
    sortable: false,
    minWidth: 140,
    hide: true,
  },
  { field: 'findingView', headerName: 'Finding', sortable: false },
  {
    field: 'patient.primaryPayor.payorInfo.name',
    headerName: 'Primary Payer',
    sortable: false,
  },
  {
    headerName: 'Insurance Plan Name',
    marryChildren: true,
    children: [
      {
        field: 'patient.primaryPayor.payorPlanDetails.planName',
        headerName: 'Primary',
        sortable: false,
        hide: true,
      },
      {
        field: 'patient.secondaryPayor.payorPlanDetails.planName',
        headerName: 'Secondary',
        sortable: false,
        hide: true,
      },
      {
        field: 'patient.secondaryPayor.payorPlanDetails.id',
        headerName: 'Secondary ID',
        width: 130,
        sortable: false,
        hide: true,
      },
      {
        field: 'patient.tertiaryPayor.payorPlanDetails.planName',
        headerName: 'Tertiary',
        sortable: false,
        hide: true,
      },
      {
        field: 'patient.tertiaryPayor.payorPlanDetails.id',
        headerName: 'Tertiary ID',
        sortable: false,
        hide: true,
        suppressSpanHeaderHeight: false,
      },
    ],
  },
  {
    headerName: 'Guarantor',
    marryChildren: true,
    openByDefault: false,
    children: [
      {
        field: 'patient.primaryGuarantor.firstName',
        headerName: 'First Name',
        sortable: false,
        hide: true,
        width: 150,
      },
      {
        field: 'patient.primaryGuarantor.lastName',
        headerName: 'Last Name',
        sortable: false,
        hide: true,
        width: 150,
      },
      {
        field: 'patient.primaryGuarantor.address1',
        headerName: 'Address',
        columnGroupShow: 'open',
        sortable: false,
        hide: true,
      },
      {
        field: 'patient.primaryGuarantor.city',
        headerName: 'City',
        columnGroupShow: 'open',
        sortable: false,
        hide: true,
      },
      {
        field: 'patient.primaryGuarantor.state',
        headerName: 'State',
        columnGroupShow: 'open',
        sortable: false,
        hide: true,
      },
      {
        field: 'patient.primaryGuarantor.zip',
        headerName: 'ZIP code',
        columnGroupShow: 'open',
        sortable: false,
        hide: true,
      },
    ],
  },
  {
    field: 'isolationPrecautionView',
    headerName: 'Isolation Precautions',
    sortable: false,
    hide: true,
  },
];

export const columnsExamQa: ColumnsDataType<ExamQaType> = [
  { field: 'id', headerName: 'Study ID', width: 100 },
  {
    headerName: 'Patient',
    marryChildren: true,
    openByDefault: false,
    children: [
      {
        field: 'patient.firstName',
        headerName: 'First name',
        width: 150,
        sortable: false,
      },
      {
        field: 'patient.lastName',
        headerName: 'Last name',
        width: 150,
        sortable: false,
      },
      {
        field: 'patient.patientMrnFormatted',
        headerName: 'MRN',
        headerTooltip: 'Medical record number',
        width: 130,
        hide: true,
        sortable: false,
      },
      {
        field: 'patient.clientMrn',
        headerName: 'Other MRN',
        headerTooltip: 'Other medical record number',
        width: 130,
        hide: true,
        sortable: false,
      },
    ],
  },
  {
    field: 'facility.id',
    headerName: 'Facility ID',
    width: 200,
    sortable: false,
  },
  {
    field: 'facility.facilityName',
    headerName: 'Facility',
    width: 200,
    sortable: false,
  },
  { field: 'techVisitInfo.visit', headerName: 'Visit', sortable: false },
  {
    field: 'numberOfPatient',
    headerName: 'Number of Patients',
    hide: true,
    sortable: false,
  },
  {
    field: 'transportationCode',
    headerName: 'Transportation Code',
    hide: true,
    sortable: false,
  },
  {
    field: 'facility.placeOfService.placeOfServiceName',
    headerName: 'POS',
    headerTooltip: 'Place of service',
    width: 200,
    hide: true,
    sortable: false,
  },
  {
    field: 'scheduleServiceDate',
    headerName: 'DOS',
    headerTooltip: 'Date of service',
    width: 120,
    sortable: false,
  },
  {
    field: 'priority.priorityName',
    headerName: 'Priority',
    sortable: false,
  },
  {
    field: 'createdBy.fullName',
    headerName: 'Entered By',
    hide: true,
    sortable: false,
  },
  { field: 'modality.name', headerName: 'Modality', sortable: false },
  {
    field: 'claimProceduresCodes',
    headerName: 'Claim Code',
    cellClass: 'text-overflow-no',
    sortable: false,
    cellRenderer: claimCodeCellRender,
  },
  {
    field: 'createdAt',
    headerName: 'Order Created',
    hide: true,
    minWidth: 155,
    sortable: false,
    valueFormatter: fullDateFormatter,
  },
  {
    field: 'orderFlow.orderAssignedDate',
    headerName: 'Order Assigned',
    minWidth: 155,
    sortable: false,
    valueFormatter: fullDateFormatter,
  },
  {
    field: 'acknowledgementTimeView',
    headerName: 'Order Acknowledged',
    sortable: false,
    minWidth: 155,
    valueFormatter: fullDateFormatter,
  },
  {
    field: 'techVisitInfo.arrivalDateTime',
    headerName: 'Arrival Time',
    minWidth: 155,
    sortable: false,
    valueFormatter: fullDateFormatter,
  },
  {
    field: 'movedToReportManager',
    headerName: 'Visit Notes Completed',
    minWidth: 155,
    sortable: false,
    valueFormatter: fullDateFormatter,
  },
  {
    field: 'encounterDuration',
    headerName: 'Encounter Duration',
    sortable: false,
    valueFormatter: timeDurationCellRender,
  },
  {
    field: 'resultsAvailableDate',
    headerName: 'Results Available',
    sortable: false,
    minWidth: 155,
    valueFormatter: fullDateFormatter,
  },
  {
    field: 'readDuration',
    headerName: 'Read Duration',
    sortable: false,
    valueFormatter: timeDurationCellRender,
  },
  {
    field: 'resultsSentDate',
    headerName: 'Results Sent',
    hide: true,
    sortable: false,
    minWidth: 155,
    valueFormatter: fullDateFormatter,
  },
  {
    field: 'examCompletionDuration',
    headerName: 'Exam Completion Duration',
    sortable: false,
    valueFormatter: timeDurationCellRender,
  },
  { field: 'findingView', headerName: 'Finding', hide: true, sortable: false },
  {
    headerName: 'Technologist',
    marryChildren: true,
    children: [
      {
        field: 'orderFlow.technologist.lastName',
        headerName: 'Last Name',
        width: 150,
        sortable: false,
      },
      {
        field: 'orderFlow.technologist.firstName',
        headerName: 'First Name',
        width: 150,
        sortable: false,
      },
    ],
  },
  {
    field: 'provider.fullName',
    headerName: 'Ordering MD/NPP',
    hide: true,
    sortable: false,
    width: 150,
  },
  {
    field: 'details.orderBasedView',
    headerName: 'Order Type',
    hide: true,
    sortable: false,
  },
  {
    field: 'radiologyGroup.radiologyGroupName',
    headerName: 'Radiology Group',
    width: 200,
    hide: true,
    sortable: false,
  },
  {
    field: 'facility.dispatchRegion.state.state',
    headerName: 'State',
    hide: true,
    sortable: false,
  },
  {
    field: 'facility.corporateGroupMembers.group.groupName',
    headerName: 'Corporation',
    width: 200,
    hide: true,
    sortable: false,
  },
  {
    field: 'id',
    headerName: 'Patient/Exam Detail',
    colId: 'patientExamDetail',
    sortable: false,
    cellRenderer: linkToPdfCellRender,
  },
];
