import { useForm } from 'react-hook-form';

import RadioOptions from 'components/project/common/RadioOptions';
import { DropDownOrderType } from 'components/project/dropdown/OrderType';
import { DropDownPhysician } from 'components/project/dropdown/PhysicianDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { InputCalendar } from 'components/form/inputCalendar';
import { Radio } from 'components/form/radio';
import { Input } from 'components/form/textField';
import { DateRange } from 'components/form/dateRange';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import {
  storeDocManagerAuditPhysician,
  filterDefaultValues,
  FilterType,
} from 'stores/_mobx/patientRecord/docManagerAuditPhysician';

interface PropsType {
  isVisible: boolean;
  onSubmit: (data: FilterType) => void;
}

const FilterPhysician = ({ isVisible, onSubmit }: PropsType) => {
  const { control, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeDocManagerAuditPhysician.filter,
  });

  const handleClickReset = () => {
    reset(filterDefaultValues);
    onSubmit(filterDefaultValues);
  };

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="patientLastName"
        label="Patient Last Name"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <Input
        name="patientFirstName"
        label="Patient First Name"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <InputCalendar
        name="dateOfBirth"
        label="DOB"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <DropDownPhysician
        name="physicianId"
        label="Physician"
        className="col-md-6 col-lg-3"
        control={control}
      />

      <DropDownState
        name="stateId"
        label="State"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <DropDownPlaceOfService
        name="posId"
        label="Place of Service"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <DropDownOrderType
        name="orderType"
        label="Order Type"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <Radio
        name="orderStatus"
        label="Order Status"
        className="col-md-6 col-lg-3 part-inline"
        options={RadioOptions.orderSigned}
        control={control}
      />

      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-auto"
        control={control}
      />

      <ControlsLayout alignX="auto">
        <Button variant="warning" text="Reset" onClick={handleClickReset} />
        <Button type="submit" text="Apply" />
      </ControlsLayout>
    </form>
  );
};

export default FilterPhysician;
