import { makeObservable, observable, action, computed } from 'mobx';

import { apiRequest } from 'services/RequestService';
import { SESSION_STORAGE_KEY_APP_VER } from 'constant/config';

export const settingsDateOnly = {
  year: 'numeric' as 'numeric',
  month: '2-digit' as '2-digit',
  day: '2-digit' as '2-digit',
  timeZone: undefined as undefined | string,
};

export const settingsFullDate = {
  ...settingsDateOnly,
  hour: '2-digit' as '2-digit',
  minute: '2-digit' as '2-digit',
  hourCycle: 'h23' as 'h23',
};

interface VersionType {
  type: string;
  version: string;
}

const isVersionNotificationAllowed =
  window.location.host.includes('-stg.latticepro.com') ||
  window.location.host === 'dev.latticepro.com';

class Service {
  fetching: boolean = true;
  isNewVersionAvailable: boolean = false;
  showDialogNewVersion: boolean = false;
  isRateLimitReachedOut: boolean = false;
  versions: VersionType[] = [];

  constructor() {
    makeObservable(this, {
      versions: observable,
      fetching: observable,
      isRateLimitReachedOut: observable,
      isNewVersionAvailable: observable,
      showDialogNewVersion: observable,

      frontendVersion: computed,
      backendVersion: computed,

      setVersions: action,
      setFetching: action,
      setRateLimit: action,
      clearVersion: action,
      closeDialogNewVersion: action.bound,
      checkCodeVersion: action.bound,
    });
  }

  get frontendVersion() {
    const frontendVersion = this.versions.find(
      ({ type }) => type === 'frontend'
    );
    return frontendVersion?.version || '';
  }

  get backendVersion() {
    const backendVersion = this.versions.find(({ type }) => type === 'backend');
    return backendVersion?.version || '';
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setVersions(versions: VersionType[]) {
    this.versions = versions;
  }

  setRateLimit(status: boolean) {
    this.isRateLimitReachedOut = status;
  }

  setApplicationTimeZone(timeZone: string) {
    if (timeZone) {
      settingsDateOnly.timeZone = timeZone;
      settingsFullDate.timeZone = timeZone;
    } else {
      settingsDateOnly.timeZone = undefined;
      settingsFullDate.timeZone = undefined;
    }
  }

  closeDialogNewVersion() {
    this.showDialogNewVersion = false;
  }

  getAppVersions = async (page: number = 1) => {
    try {
      const versions = await apiRequest<VersionType[]>({
        url: `system/version?page=${page}`,
        method: 'GET',
        legacy: false,
      });

      this.setVersions(versions);
    } catch (e) {
    } finally {
      this.setFetching(false);
    }
  };

  checkCodeVersion(headers: Headers) {
    const currentCodeVersion = headers.get(SESSION_STORAGE_KEY_APP_VER);

    const persistedCodeVersion = sessionStorage.getItem(
      SESSION_STORAGE_KEY_APP_VER
    );

    if (!isVersionNotificationAllowed) return true; // Delete this line after Version Notification will be tested carefulle

    if (!currentCodeVersion) return true;

    if (!persistedCodeVersion) {
      sessionStorage.setItem(SESSION_STORAGE_KEY_APP_VER, currentCodeVersion);
      return true;
    }
    if (
      persistedCodeVersion !== currentCodeVersion &&
      !this.isNewVersionAvailable
    ) {
      this.isNewVersionAvailable = true;
      this.showDialogNewVersion = true;
      return false;
    }
  }

  updateToLatestVersion = () => {
    this.clearVersion();
    window.location.reload();
  };

  clearVersion = () => {
    sessionStorage.removeItem(SESSION_STORAGE_KEY_APP_VER);
    this.closeDialogNewVersion();
  };
}

export const storeService = new Service();
