import React from 'react';

import Custom from 'components/form/input/Custom';
import Radio from 'components/form/input/Radio';
import Text from 'components/form/input/Text';
import NpiInput from 'components/project/NpiInput';
import Fieldset, { PFieldset, SFieldset } from 'components/form/Fieldset';

import { URL_DATA_GOV } from 'constant/externalLinks';

const YES_NO = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];

export interface PPhysicianData extends PFieldset {
  id: string;
}

export class SPhysicianData extends SFieldset {}

const className = 'col-sm-9';
const controlClassName = 'col-sm-3';

export default class PhysicianData extends Fieldset<
  PPhysicianData,
  SPhysicianData
> {
  renderChildren() {
    const props = this.props;
    const model = props.formModel || {};
    const isNpiExist = Boolean(model.npi);

    return [
      <NpiInput
        name="npi"
        hasLookUp
        label="NPI #"
        className={className}
        validations={props.npiRequired === false ? '' : 'required'}
        key="1"
      />,
      <div
        style={{ margin: '20px 0 0' }}
        className={`${controlClassName} ${
          isNpiExist ? 'text-success' : 'text-danger'
        } fs-3`}>
        <i
          className={`bi bi-${isNpiExist ? 'check' : 'x'}`}
          title={isNpiExist ? 'Allow' : 'Deny'}
        />
      </div>,
      this.getState(),
      <Radio
        name={props.id + '_pecos'}
        label="Registered in PECOS"
        options={YES_NO}
        key="4"
        id={props.id + '_pecos'}
        value={model.pecos}
        className={`part-inline ${className}`}
      />,
      <Custom
        label="&nbsp;"
        className={controlClassName}
        noclone="true"
        key="5"
        custom={
          <a href={URL_DATA_GOV} target="_blank" rel="noreferrer">
            PECOS
          </a>
        }
      />,
    ].map((child: React.ReactNode, key: number) => {
      return this._cloneChild(child, '' + key);
    });
  }

  getState() {
    const props = this.props;
    const model = props.formModel || {};

    return props.stateAsLabel ? (
      <Custom name="state" custom={model.state} key="3" />
    ) : (
      <Text name="state" value={model.state} key="3" />
    );
  }
}
