export class TRadioOption {
  label: string = '';
  value: string | number = null;
}

class RadioOptions {
  active: Array<TRadioOption> = [
    { label: 'Active', value: 'A' },
    { label: 'Inactive', value: 'I' },
  ];
  gender: Array<TRadioOption> = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
    { label: 'Unknown', value: 'U' }, // todo check "Should it be U or ''?"
  ];
  normalGender: Array<TRadioOption> = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
  ];
  notificationType: Array<TRadioOption> = [
    { label: 'Email', value: 'E' },
    { label: 'Fax', value: 'F' },
    { label: 'None', value: 'N' },
  ];
  filterPeriod: Array<TRadioOption> = [
    { value: 'A', label: 'All' },
    { value: 'TD', label: 'Today' },
    { value: 'W', label: 'Week' },
    { value: 'M', label: 'Month' },
  ];
  filterPeriodExtended: Array<TRadioOption> = [
    { value: 'A', label: 'All' },
    { value: 'W', label: 'Week' },
    { value: 'M', label: 'Month' },
    { value: 60, label: '60' },
    { value: 90, label: '90' },
    { value: 120, label: '120' },
    { value: 'YTD', label: 'YTD' },
  ];
  orderStatus: Array<TRadioOption> = [
    { value: 'A', label: 'All' },
    { value: 'S', label: 'Signed' },
    { value: 'U', label: 'Unsigned' },
  ];
  yesNo: Array<TRadioOption> = [
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' },
  ];
  yesNoAll: Array<TRadioOption> = [
    { label: 'All', value: '' },
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' },
  ];
  amPm: Array<TRadioOption> = [
    { label: 'AM', value: 'am' },
    { label: 'PM', value: 'pm' },
  ];
  relationship: Array<TRadioOption> = [
    { label: ' -- Select --', value: '' },
    { label: 'Self', value: 'SE' },
    { label: 'Spouse', value: 'SP' },
    { label: 'Child', value: 'CH' },
    { label: 'Other', value: 'OT' },
  ];
  radiologyType: Array<TRadioOption> = [
    { label: 'Fee for Service', value: 'ffs' },
    { label: 'Insurance', value: 'insurance' },
    { label: 'Purchased Service', value: 'purchasedService' },
  ];
  modeOfPayment: Array<TRadioOption> = [
    { label: '-- select --', value: 0 },
    { label: 'Cash', value: 1 },
    { label: 'Cashier Check', value: 2 },
    { label: 'Check', value: 3 },
    { label: 'Credit Card', value: 4 },
    { label: 'Direct Deposit', value: 5 },
  ];
  insurancePlan: Array<TRadioOption> = [
    { label: 'Primary Insurance', value: 'P' },
    { label: 'Secondary Insurance', value: 'S' },
    { label: 'Tertiary Insurance', value: 'T' },
  ];
  orderSigned: Array<TRadioOption> = [
    { label: 'All', value: '' },
    { label: 'Signed', value: 'Y' },
    { label: 'Unsigned', value: 'N' },
  ];
}

export default new RadioOptions();
