import { apiRequest } from 'services/RequestService';
import { TPatientRecord } from 'services/patient-records/patient-demographics/PatientRecordPageService';
import { convertToAppTimezone } from 'utils/DateUtils';

export type TFlag = 'A' | 'H' | 'I' | 'R';

export class TFacilityAddress {
  address: string = '';
  address1: string = '';
  phone: string = '';
  city: string = '';
  state: string = '';
  mobile: string = '';
  zipcode: string = '';
  bill_addres1: string = '';
  bill_city: string = '';
  bill_state: string = '';
  bill_zipcode: string = '';
  bill_id: string = '';
  region: string = '';
  email: string = '';
  fax: string = '';
  pos: string = '';
  facility_name: string = '';
  source: string = '';
  zipimage: string = '';
  pdfimage: string = '';
}

export class TFacilityAddressExt extends TFacilityAddress {
  facilityId: number = null;
}

export interface OrderScheduleServiceDateType {
  date: string;
  timeStart: string;
  timeEnd: string;
  refid: number;
}

export class TPatientOrder {
  patientid: number = null;
  facilityid: number = null;
  patient_diff_address: string = '';
  patient_diff_state: string = '';
  patient_diff_city: string = '';
  patient_diff_zipcode: string = '';
  patient_diff_phone: string = '';
  hospiceId: number = null;
  clientMRN: string = '';
  callerName: string = '';
  facesheet: string = '';
  order_based: 'F' | 'O' | 'H' | 'C' | 'P' | '' = '';
  order_based_type: string = '';
  orderid: number = null;
  Oldfacesheet: string = '';
  lastModifiedLock: string = '';
}

export class TCorrectional {
  ice_number: string = '';
  inmate_number: string = '';
  immigration_id: string = '';
}

export class TExamInfoOrderData {
  sheduleservdate: string = '';
  scheduleServiceDate: string = '';
  scheduletime: string = '';
  orderid: number = null;
  facilityid: number = null;
  comp_status: string = '';
  patient_id: number = null;
  assgindt: string = '';
  compdt: string = '';
  assigntime: string = '';
  examtypeid: number = null;
  priority: number = null;
  phoneresult: string = '';
  symptoms: string = '';
  comment: string = '';
  hl7: string = '';
  isolation: string = '';
  isolation_description: string = '';
  radiologygp: string = '';
  room: string = '';
  smoking: string = '';
  symptomatic: string = '';
  callerName: string = '';
  technician: string = '';
  user_type: string = '';
  schedulestime: string = '';
  scheduleendtime: string = '';
  lastModifiedLock: string = '';
}

export class TExamInfoOrderDataExt extends TExamInfoOrderData {
  faxPhoneArr: Array<TFaxPhone> = [];
}

export class TCpt {
  refid: number = null;
  data: string = '';
}

export class TIcd {
  refid: number = null;
  alphavalue: string = '';
  selicd_status: string = '';
}

export class TFinalCpt {
  refid: number = null;
  data: string = '';
  mode: string = '';
  cpt_id: string = '';
  price: string = '';
  cptcount: string = '';
  discount_percentage: string = '';
  modifier: string = '';
  cpt_description: string = '';
  accession_number: string = '';
  external_accession_number: string = '';
}

export class TFaxPhone {
  refid: number = null;
  StationName: string = '';
  FacilityId: string = '';
  Fax: string = '';
  Phone: string = '';
  Email: string = '';
  Comment: string = '';
  FacilityFloorRefId: string = '';
}

export class TExamData {
  mode: 'CD' | 'E';
  GetIntCptArr: Array<TCpt> = [];
  GetIcdArr: Array<TIcd> = [];
  GetFinalCptArr: Array<TFinalCpt> = [];
  FaxPhone: Array<TFaxPhone> = [];
}

export class TPhysicianInfo {
  orderId: number = null;
  primPhysId: number = null;
  ordPhysId: number = null;
  consPhysId: number = null;
  other_value: string = '';
  callerName: string = '';
  orderSource: string = '';
  lastModifiedLock: string = '';
}

export class TOrderFaxPhoneItem {
  refid: number = null;
  floor: string = '';
  Fax: string = '';
  Phone: string = '';
  floorName: string = '';
}

export class TFacilityFloor {
  refid: number = null;
  floor_fax: string = '';
  floor_phone: string = '';
}

export class TEdFaxPhone {
  refid: number = null;
  Fax: string = '';
  Phone: string = '';
  floor: string = '';
  floorName: string = '';
  AddOpt: boolean = false;
  DelOpt: boolean = false;
}

export class TEdIntCpt {
  refid: number = null;
  accession_number: string = '';
  cpt_code: string = '';
  cpt_desc: string = '';
  cpt_description: string = '';
  external_accession_number: string = '';
  external_placer_order_number: string | null = '';
  extremity: string = '';
  session_no: string = '';
  flag_need_addendum: boolean = false;
  need_addendum_from_radiology_group_id: number = 0;
  order_initial_cpt_refid: number = 0;
}

export class TEdIntIcd {
  refid: number = null;
  icd_code: string = '';
  alphavalue: string = '';
  selicd_status: string = '';
  short_description: string = '';
}

export class TEdFnlIcd {
  refid: number = null;
  Data: string = '';
  Description: string = '';
  icd_code: string = '';
  short_description: string = '';
}

export class TExamDetail {
  refid: number = null;
  FaxPhone: Array<TEdFaxPhone> = [];
  GetFnlIcdArr: Array<TEdFnlIcd> = [];
  GetIntCptArr: TEdIntCpt[] = [];
  GetIntIcdArr: Array<TEdIntIcd> = [];
  comment: string = '';
  exam_type_name: string = '';
  examtypeid: number = null;
  fnlicddesc: string = '';
  hl7: string = '';
  icddesc: string = '';
  isolation: 'Y' | 'N' | 'U' = 'U';
  isolation_description: string = '';
  number_of_patient: string = '';
  orderassigned_date: string = '';
  orderassigned_time: string = '';
  ordercompletion_date: string = '';
  ordercompletion_time: string = '';
  pcpid: number = null;
  phoneresult: string = '';
  priority: string = '';
  providerid: number = null;
  radiologygroupid: number = null;
  radiosignstatus: string = '';
  room: string = '';
  scheduleendtime: string = '';
  schedulestime: string = '';
  sessionno: string = '';
  sheduleservdate: string = '';
  signstatus: string = '';
  signstatusvis: string = '';
  smoking: 'Y' | 'N' | 'U' = 'U';
  symptomatic: 'Y' | 'N' | 'U' = 'U';
  symptoms: string = '';
  technologistid: number = null;
  techsignstatus: string = '';
}

export class TLocation {
  label: string = '';
  data: string = '';
}

export class TRegion {
  refid: number = null;
  region_id: number = null;
  state_id: number = null;
  location: string = '';
}

export class TPatient {
  orderId: number = null;
  patientid: number = null;
  facilityid: number = null;
  state_id: string = '';
  room: string = '';
  locationid: number = null;
  Region: Array<TRegion> = [];
  LocationArr: Array<TLocation> = [];
  facilityname: string = '';
  facilityphone: string = '';
  insurance: string = '';
  selfpay: string = '';
  facility_billing: string = '';
  hospice: string = '';
  hospice_id: number = null;
  addr1: string = '';
  addr2: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  other_bill: string = '';
  patient_diff_address: string = '';
  patient_diff_state: string = '';
  patient_diff_city: string = '';
  patient_diff_zipcode: string = '';
  patient_diff_phone: string = '';
  order_based: 'F' | 'O' | 'H' | 'C' | 'P' | '' = '';
  order_based_type: string = '';
  face_sheet: string = '';
  name: string = '';
  flag: string = '';
  lastModifiedLock: string = '';
}

export class TPhysician {
  data: number = null;
  first_name: string = '';
  last_name: string = '';
  mobile: string = '';
  fax: string = '';
  npi: string = '';
  clinician_group: string = '';
  suffix_nm: string = '';
  phy_group: string = '';
  group_affiliation_name: string = '';
}

export class TPhysicianDetailByOrderId {
  sessionno: string = '';
  providerid: number = null;
  pcpid: number = null;
  consproviderid: number = null;
  examtypeid: number = null;
  orderingPhysician: TPhysician = new TPhysician();
  primaryCarePhysician: TPhysician = new TPhysician();
  consultingPhysician: TPhysician = new TPhysician();
  priority: string = '';
  phoneresult: string = '';
  comment: string = '';
  hl7: string = '';
  order_based: string = '';
  other: string = '';
  other_value: string = '';
}

export class TPhysicianDetail {
  primPhysId: number = null;
  ordPhysId: number = null;
  consPhysId: number = null;
  other_value: string = '';
  other: string = '';
  orderingPhysician: TPhysician = new TPhysician();
  primaryCarePhysician: TPhysician = new TPhysician();
  consultingPhysician: TPhysician = new TPhysician();
  callerName: string = '';
}

export class TCptCode {
  refid: number = null;
  cpt_code: string = '';
  cpt_description: string = '';
  cpt_price: string = '';
  modifier_code: string = '';
}

export class TCptCodeExt extends TCptCode {
  cptcount: number = null;
  profprice: string = '';
  technical_price: string = '';
  globalprice: string = '';
}

export class TModifyOrderRes {
  orderId: number = null;
  lastModifiedLock: string = '';
  success: boolean = false;
}

export class TAddPatientOrderRes extends TModifyOrderRes {
  cptFlag: 'S' | 'F' | 'C' = null;
}

export class TAddExamInfoOrderRes extends TModifyOrderRes {
  orderExist: boolean = false;
}

export class TFacilityDropDown {
  label: string = '';
  data: number = null;
  phone: string = '';
  fax: string = '';
  stateid: number = null;
  facility_stat: string = '';
}

export class TDirectNumber {
  data: number = null;
  desc: string = '';
  extension: string = '';
  label: string = '';
  directno: string = '';
}

export class TOrderCallerDetails {
  order_source: string = '';
  caller_detail: string = '';
  fax: string = '';
  face_sheet: string = '';
  name: string = '';
  order_based: string = '';
}

export class TDirectFaxPhoneNumber {
  refid: number = null;
  telephone: string = '';
  extension: string = '';
}

export class TVisitComments {
  name: string = '';
  result: string = '';
  desc: string = '';
  date: string = '';
  bgcolor: string = '';
  file_name: string = '';
  file_path: string = '';
  visible: boolean = false;
}

export class TCptIcdCptCode {
  refid: number = null;
  cpt_code: string = '';
  cpt_description: string = '';
  cpt_desc: string = '';
  session_no: string = '';
  external_accession_number: string = '';
  accession_number: string = '';
  extremity: string = '';
}

export class TIcdDescription {
  short_description: string = '';
  icd_code: string = '';
}

export class TImage {
  refid: number = null;
  img_name: string = '';
  img_path: string = '';
}

export class TCptIcd {
  dateofservice: string = '';
  examtype: string = '';
  refid: number = null;
  facility_nm: string = '';
  physicianname: string = '';
  providername: string = '';
  cpt_codes: Array<TCptIcdCptCode> = [];
  icddesc: Array<TIcdDescription> = [];
  technologistnm: string = '';
  Image: 0 | Array<TImage> = [];
  ImageId: 0 | Array<number> = [];
  phoneimgsource: string = '';
  phonetooltip: string = '';
  enablePh: boolean = false;
  imgsource: string = '';
  imgtooltip: string = '';
  enableImg: boolean = false;
  faximgsource: string = '';
  faxtooltip: string = '';
  enableFax: boolean = false;
  resultimgsource: string = '';
  resulttooltip: string = '';
  enableRep: boolean = false;
  externalimgsource: string = '';
  externaltooltip: string = '';
  enableEx: boolean = false;
}

export const prettierDos = (originalDate: string, currentDate: string) => {
  const dos = originalDate.startsWith(currentDate)
    ? convertToAppTimezone(originalDate).toISOString()
    : convertToAppTimezone(currentDate).toISOString();

  return dos;
};

class OrderFormPageService {
  loadPatientItemByOrderId(orderId: number) {
    return apiRequest<'SE' | 0 | Array<TPatient>>({
      url: 'order.Order.GetPatientByOrderId',
      data: [orderId],
    });
  }

  updateScheduleServiceDate(dos: OrderScheduleServiceDateType) {
    const date = dos.date
      ? convertToAppTimezone(dos.date).toISOString()
      : dos.date;

    const data = [dos.refid, date, dos.timeStart, dos.timeEnd];

    return apiRequest<'S'>({
      url: 'order.Order.updateScheduleServiceDate',
      data,
    });
  }

  GetExamDetailByOrderId(orderId: number) {
    return apiRequest<'SE' | 0 | Array<TExamDetail>>({
      url: 'order.Order.GetExamDetailByOrderId',
      data: [orderId],
    }).then((response) => {
      if (response === 0 || response === 'SE' || response.length <= 0) {
        return [];
      }
      const examDetail: TExamDetail = response[0];
      if (examDetail.smoking !== 'Y' && examDetail.smoking !== 'N') {
        examDetail.smoking = 'U';
      }
      if (examDetail.symptomatic !== 'Y' && examDetail.symptomatic !== 'N') {
        examDetail.symptomatic = 'U';
      }
      if (examDetail.isolation !== 'Y' && examDetail.isolation !== 'N') {
        examDetail.isolation = 'U';
      }
      return response;
    });
  }

  GetCPTCodeTotalRecordCountByModality(
    search: string,
    searchDesc: string,
    flag: TFlag,
    modality: number
  ) {
    const data = [search, searchDesc, flag, modality];

    return apiRequest<'SE' | number>({
      url: 'codemaster.CPTCode.CPTCodeTotalRecordCountByModality',
      data,
    });
  }

  GetCPTCodeListValueByOrder(
    search: string,
    searchDesc: string,
    flag: TFlag,
    modality: number,
    index: number,
    limit: number,
    orderId: number
  ): Promise<Array<TCptCodeExt>> {
    const data = [search, searchDesc, flag, modality, index, limit, orderId];

    return apiRequest<'SE' | Array<TCptCodeExt>>({
      url: 'codemaster.CPTCode.CPTCodeListByOrder',
      data,
    }).then((response) => {
      return response !== 'SE' ? response : [];
    });
  }

  GetCPTCodeListValueByModality(
    search: string,
    searchDesc: string,
    flag: TFlag,
    modality: number,
    index: number,
    limit: number
  ) {
    const data = [search, searchDesc, flag, modality, index, limit];

    return apiRequest<'SE' | Array<TCptCode>>({
      url: 'codemaster.CPTCode.CPTCodeListValueByModality',
      data,
    });
  }

  getGeneratedAccessionNumbersList(size: number) {
    return size
      ? apiRequest<Array<string>>({
          url: 'codemaster.CPTCode.getGeneratedAccessionNumbersList',
          data: [size],
        })
      : Promise.resolve([]);
  }

  deleteOrder(orderId: number) {
    return apiRequest({ url: 'order.Order.DeleteOrder', data: { orderId } });
  }

  addPatientOrder(item: TPatientOrder, correctional: TCorrectional) {
    return apiRequest<'SE' | Array<TAddPatientOrderRes>>({
      url: 'order.Order.AddPatientOrder',
      data: [item, correctional],
    });
  }

  loadPhysicianOverview(
    firstName: string,
    lastName: string,
    npi: string,
    skip: number,
    limit: number
  ) {
    const data = [firstName, lastName, npi, skip, limit];

    return apiRequest<'SE' | { count: number; data: Array<TPhysician> }>({
      url: 'order.Order.GetPhysicianDropDown',
      data,
    });
  }

  loadPhysicianList(payload: {
    firstName: string;
    lastName: string;
    npi: string;
  }): Promise<Array<TPhysician>> {
    return this.loadPhysicianOverview(
      payload.firstName,
      payload.lastName,
      payload.npi,
      0,
      999999
    ).then((response: 'SE' | { count: number; data: Array<TPhysician> }) => {
      if (response === 'SE') {
        return [];
      }
      return response.data;
    });
  }

  loadFacilityWithoutInactiveList(id: number) {
    return apiRequest<Array<TFacilityDropDown>>({
      url: 'facility.FacilityMaster.FacilityDropDownWithoutInactive',
      data: [id],
    });
  }

  GetFinalCPTByOrderId(orderId: number) {
    return apiRequest({
      url: 'order.CPTCode.GetFinalCPTByOrderId',
      data: [orderId],
    });
  }

  loadDirectNumberList(facilityId: number) {
    return apiRequest<0 | Array<TDirectNumber>>({
      url: 'order.Order.GetDirectNoDropdown',
      data: [facilityId],
    });
  }

  loadPatientViewInfo(patientId: number) {
    return apiRequest<'SE' | 0 | Array<TPatientRecord>>({
      url: 'patient.PatientInfo.GetViewPatientInfo',
      data: [patientId],
    });
  }

  loadPhysicianDetailsByOrderId(orderId: number): Promise<TPhysicianDetail> {
    return apiRequest<'SE' | 0 | TPhysicianDetailByOrderId>({
      url: 'order.Order.GetphysicianDetailByOrderId',
      data: [orderId],
    }).then((response: 'SE' | 0 | TPhysicianDetailByOrderId) => {
      const physDetails: TPhysicianDetail = new TPhysicianDetail();
      if (response !== 'SE' && response !== 0) {
        physDetails.primPhysId = response.pcpid;
        physDetails.ordPhysId = response.providerid;
        physDetails.consPhysId = response.consproviderid;
        physDetails.other_value = response.other_value;
        physDetails.other = response.other;
        physDetails.orderingPhysician = Object.assign(
          new TPhysician(),
          response.orderingPhysician
        );
        physDetails.primaryCarePhysician = Object.assign(
          new TPhysician(),
          response.primaryCarePhysician
        );
        physDetails.consultingPhysician = Object.assign(
          new TPhysician(),
          response.consultingPhysician
        );
      }
      return physDetails;
    });
  }

  loadLastAccessionNumber() {
    return apiRequest<string>({ url: 'order.Order.GetLastAccessionNumber' });
  }

  loadFloorList(facilityId: number) {
    return apiRequest<Array<{ data: string; label: string }>>({
      url: 'facility.FacilityMaster.FloorDropDown',
      data: [facilityId],
    });
  }

  loadFacilityAddress(facilityId: number) {
    return apiRequest<TFacilityAddress>({
      url: 'facility.FacilityMaster.GetFacilityAddress',
      data: [facilityId],
    });
  }

  getOrderTypeByFacility(facilityId: number) {
    return apiRequest<Array<{ data: string; label: string }>>({
      url: 'order.Order.GetOrderTypeByFacility',
      data: [facilityId],
    });
  }

  updatePhysicianInfo(
    physInfo: TPhysicianInfo,
    autoSave: boolean
  ): Promise<'SE' | TModifyOrderRes> {
    return apiRequest<'SE' | TModifyOrderRes>({
      url: 'order.Order.AddPhyInfoOrder',
      data: [physInfo, autoSave],
    });
  }

  loadOrderCallerDetailsByOrderId(orderId: number) {
    return apiRequest<'SE' | 0 | Array<TOrderCallerDetails>>({
      url: 'order.Order.getOrderCallerDetailsByOrderId',
      data: [orderId],
    });
  }

  getFaxPhone(orderId: number) {
    return apiRequest<'SE' | Array<TOrderFaxPhoneItem>>({
      url: 'order.Order.GetFaxPhone',
      data: [orderId],
    });
  }

  loadOrderDirectNumberList(orderId: number) {
    return apiRequest<'SE' | 0 | TDirectFaxPhoneNumber>({
      url: 'order.Order.GetDirectNumber',
      data: [orderId],
    });
  }

  AddExamInfoOrder(examData: TExamData) {
    const data = [
      examData.mode || 'E',
      examData,
      examData.GetIntCptArr,
      examData.GetIcdArr,
      examData.GetFinalCptArr,
      examData.FaxPhone,
    ];

    return apiRequest<'S' | 'E' | TAddExamInfoOrderRes>({
      url: 'order.Order.AddExamInfoOrder',
      data,
    });
  }

  addExamInfoOrder(
    mode: 'CD' | 'E' | 'A',
    { scheduleServiceDate, ...order }: TExamInfoOrderData,
    cptArr: Array<TCpt>,
    icdArr: Array<TIcd>,
    finalCptArr: Array<TFinalCpt>,
    faxPhoneArr: Array<TFaxPhone>,
    saveDuplicate: 'SD' | '',
    autoSave: 'N' | 'PR'
  ) {
    const dos = prettierDos(scheduleServiceDate, order.sheduleservdate);

    const orderData = {
      ...order,
      sheduleservdate: dos,
    };

    const data = {
      mode,
      orderData,
      cptArr,
      icdArr,
      finalCptArr,
      faxPhoneArr,
      saveDuplicate,
      autoSave,
    };

    return apiRequest<'S' | 'E' | TAddExamInfoOrderRes>({
      url: 'order.Order.AddExamInfoOrder',
      data,
    });
  }

  loadVisitComments(orderId: number) {
    return apiRequest<'SE' | Array<TVisitComments>>({
      url: 'medicalrecord.OrderResult.GetResult',
      data: [orderId],
    });
  }

  saveVisitComment(orderId: number, userId: number, comment: string) {
    const data = [orderId, userId, comment];
    return apiRequest({ url: 'medicalrecord.OrderResult.AddResult', data });
  }

  getCptIcdByPatient(patientId: number) {
    return apiRequest<0 | Array<TCptIcd>>({
      url: 'order.Order.GetCptIcdByPatient',
      data: [patientId],
    });
  }

  loadFaxNumberByFacilityId(facilityId: number) {
    return apiRequest({ url: 'email.fax.GetFaxNo', data: [facilityId] });
  }

  getCorrectional(orderId: number) {
    return apiRequest<'SE' | 0 | Array<TCorrectional>>({
      url: 'order.Order.GetCorrectionalDetailByOrderId',
      data: { orderId },
    });
  }

  onExistingStation(floorId: number) {
    return apiRequest<Array<TFacilityFloor>>({
      url: 'facility.FacilityMaster.getFloorDetail',
      data: [floorId],
    });
  }

  changeFacility(
    patientId: number,
    facilityId: number,
    oldFacilityId: number,
    orderId: number
  ) {
    const data = [patientId, facilityId, oldFacilityId, orderId];

    return apiRequest({
      url: 'patient.PatientInfo.UpdatePatientFacility',
      data,
    });
  }
}

export default new OrderFormPageService();
