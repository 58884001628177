import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import { downloadFile } from 'utils/downloadFile';
import { BASE_URL_FILE_DIR } from 'constant/config';

export interface InvoicePdfType {
  invoiceNumber: number;
  facilityId: number;
  isReceived: boolean;
  date: string;
}

export interface InvoiceXlsxType {
  invoice_no: number;
  facilityId: number;
  invoice_dt: string;
  flag: 'P' | 'F';
}

interface InvoiceXlsxResponseType {
  report: string;
  status: 'S' | 'SE';
}

export interface PreInvoiceType {
  facilityId: number;
  invoiceNo: number;
  invoiceAlpha: string;
  fromDate: string;
  toDate: string;
  invoiceType: string;
  lastName: string;
  firstName: string;
}

export interface RequestedDocType {
  facilityId: number;
  pageKey: string;
  orders: { orderid: number; repcode: Record<string, boolean> }[];
}

export const exportExam = async (orderId: number, signal?: AbortSignal) => {
  try {
    const response = await apiRequest<string>({
      url: 'pdfcreater.OrderPdfGenerator.GeneratePDF',
      data: [orderId],
      signal,
    });

    if (/\.pdf$/.test(response)) {
      return response.replace(/^\//, '');
    }
    throw Error('');
  } catch (e: any) {
    if (!signal?.aborted)
      Notification.danger('An error occurred during report generation!');
    return '';
  }
};

export const exportInvoicePdf = async (
  invoiceDetail: InvoicePdfType,
  signal?: AbortSignal
) => {
  const data = [
    invoiceDetail.invoiceNumber,
    invoiceDetail.facilityId,
    invoiceDetail.date,
    invoiceDetail.isReceived,
  ];

  try {
    const response = await apiRequest<string>({
      url: 'pdfcreater.OrderPdfGenerator.InvoicePdf',
      data,
      signal,
    });

    if (/\.pdf$/.test(response)) {
      return response.replace(/^\//, '');
    }
    throw Error('');
  } catch (e: any) {
    if (!signal?.aborted)
      Notification.danger('An error occurred during report generation!');
    return '';
  }
};

export const exportInvoicePerDiemPdf = async (
  invoiceDetail: InvoicePdfType,
  signal?: AbortSignal
) => {
  const data = [
    invoiceDetail.invoiceNumber,
    invoiceDetail.facilityId,
    invoiceDetail.date,
    invoiceDetail.isReceived,
  ];

  try {
    const response = await apiRequest<string>({
      url: 'pdfcreater.OrderPdfGenerator.InvoicePerdiemPdf',
      data,
      signal,
    });

    if (/\.pdf$/.test(response)) {
      return response.replace(/^\//, '');
    }
    throw Error('');
  } catch (e: any) {
    if (!signal?.aborted)
      Notification.danger('An error occurred during report generation!');
    return '';
  }
};

export const downloadExportInvoice = async (invoiceDetail: InvoicePdfType) => {
  exportInvoicePdf(invoiceDetail).then((fileName) => {
    if (fileName)
      downloadFile(`${BASE_URL_FILE_DIR}pdf-creator/${fileName}`, true);
  });
};

export const downloadExportInvoicePerDiem = async (
  invoiceDetail: InvoicePdfType
) => {
  exportInvoicePerDiemPdf(invoiceDetail).then((fileName) => {
    if (fileName)
      downloadFile(`${BASE_URL_FILE_DIR}pdf-creator/${fileName}`, true);
  });
};

export const exportPreInvoice = async (
  data: PreInvoiceType,
  signal?: AbortSignal
) => {
  try {
    const response = await apiRequest<string>({
      url: 'pdfcreater.InvoicePdfGenerator.BillingPdf',
      data,
      signal,
    });

    if (/\.pdf$/.test(response)) {
      return response.replace(/^\//, '');
    }
    throw Error('');
  } catch (e: any) {
    if (!signal?.aborted)
      Notification.danger('An error occurred during report generation!');
    return '';
  }
};

export const exportInvoiceXlsx = async (
  { flag, ...invoice }: InvoiceXlsxType,
  signal?: AbortSignal
) => {
  try {
    const { status, report } = await apiRequest<InvoiceXlsxResponseType>({
      url: 'facility.FacilityBilling.generateInvoiceExport',
      data: { invoice, flag },
      signal,
    });

    if (status === 'S') {
      return report;
    }
    throw Error('');
  } catch (e: any) {
    if (!signal?.aborted)
      Notification.danger('An error occurred during report generation!');
    return '';
  }
};

export const exportFacility = async (id: number, signal?: AbortSignal) => {
  try {
    const fileName = await apiRequest<string>({
      url: 'facility.FacilityMaster.generateReport',
      data: [id],
      signal,
    });

    return fileName;
  } catch (e: any) {
    if (!signal?.aborted)
      Notification.danger('An error occurred during file generation!');
    return '';
  }
};

export const exportRequestedDoc = async (
  { facilityId, pageKey, orders }: RequestedDocType,
  signal?: AbortSignal
) => {
  try {
    const fileName = await apiRequest<string>({
      url: 'pdfcreater.TransmitLog_PDF.GeneratePDF',
      data: [facilityId, pageKey, orders],
      signal,
    });
    if (/\.pdf$/.test(fileName)) {
      return fileName.replace(/^\//, '');
    }
    throw Error('');
  } catch (e: any) {
    if (!signal?.aborted)
      Notification.danger('An error occurred during file generation!');
    return '';
  }
};
