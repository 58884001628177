import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';

export const defaultFilterValue: FilterType = {
  fileName: '',
  dateFrom: '',
  dateTo: '',
  period: 'A',
  actionType: {
    create: true,
    delete: true,
    download: true,
    rename: true,
    share: true,
    upload: true,
  },
};

interface LogRecordType {
  action: string;
  action_date: string;
  action_time: string;
  actionDate: string;
  created_by: string;
  desc: string;
  file_name: string;
  file_size: string;
}

interface ChartDataType {
  amt: number;
  lbl: string;
}

interface LogsResponseType {
  chartval: ChartDataType[];
  gridval: LogRecordType[];
}

export interface FilterType {
  fileName: string;
  dateFrom: string;
  dateTo: string;
  period: string;
  actionType: {
    create: boolean;
    delete: boolean;
    download: boolean;
    rename: boolean;
    share: boolean;
    upload: boolean;
  };
}

class BriefcaseHistory {
  fetching: boolean = false;
  chartData: [string, string | number][] = [];
  logList: LogRecordType[] = [];
  logTotal: number = 0;
  filter: FilterType = defaultFilterValue;
  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'briefcaseHistoryGrid' });

    makeObservable(this, {
      fetching: observable,
      chartData: observable,
      logList: observable,
      logTotal: observable,
      filter: observable,

      setFetching: action,
      setChartData: action,
      setLogList: action,
      setLogTotal: action,
      setFilter: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setLogList(logs: LogRecordType[]) {
    this.logList = logs;
  }

  setLogTotal(count: number) {
    this.logTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setChartData(chartData: [string, string | number][]) {
    this.chartData = chartData;
  }

  async getBriefcaseCount(data: any[]) {
    try {
      const count = await apiRequest<string>({
        url: 'filemanager.FileManager.GetFleManHistoryCount',
        data,
      });

      this.setLogTotal(Number(count) || 0);
    } catch (e: any) {
      this.setLogTotal(0);
    }
  }

  async getBriefcaseLogs(data: any[]) {
    try {
      const { chartval, gridval } = await apiRequest<LogsResponseType>({
        url: 'filemanager.FileManager.GetFleManHistoryList',
        data,
      });

      const logList = gridval.map((log) => ({
        ...log,
        actionDate: dateToLocalTimezone({
          date: log.actionDate,
        }),
      }));

      const chartData = chartval.reduce(
        // @ts-ignore
        (prev, { lbl, amt }) => prev.concat([[lbl, amt]]),
        [['Action type', 'Amount']]
      );
      // @ts-ignore
      this.setChartData(chartData);
      this.setLogList(logList);
    } catch (e: any) {
      this.setChartData([]);
      this.setLogList([]);
    }
  }

  getBriefcaseLogsMain = async (filter: FilterType = this.filter) => {
    const { pagination } = this.page;

    this.setFetching(true);

    const actionDate = getDateRangeBounds({
      from: filter.dateFrom,
      to: filter.dateTo,
    });

    const data = [
      filter.actionType.upload,
      filter.actionType.download,
      filter.actionType.create,
      filter.actionType.rename,
      filter.actionType.delete,
      filter.actionType.share,
      filter.fileName,
      actionDate.dateFrom,
      actionDate.dateTo,
    ];

    const promiseList = this.getBriefcaseCount(data);

    const promiseCount = this.getBriefcaseLogs([
      pagination.skip,
      pagination.pageSize,
      ...data,
    ]);

    return Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  exportToPdf = async ({ filter }: { filter: string }) => {
    this.setFetching(true);
    try {
      const fileName = await apiRequest<string>({
        url: 'pdfcreater.OrderPdfGenerator.GetFileGeneratePDF',
        data: [this.logList, filter],
      });
      return fileName.replace(/^\//, '');
    } catch (e: any) {
      return '';
    } finally {
      this.setFetching(false);
    }
  };
}

export const storeBriefcaseHistory = new BriefcaseHistory();
