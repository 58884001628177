import { RouteComponentProps } from 'react-router';

import Title from 'components/project/common/title';
import ModifyThyroidShieldForm, { FormModel } from './components/Form';

import { storeThyroidShield } from 'stores/_mobx/workflow/equipmentInventory/thyroidShield';
import { URL_THYROID_SHIELD } from 'constant/path/workflow';

interface PropsType extends RouteComponentProps {}

const ThyroidShieldAddPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: FormModel) =>
    storeThyroidShield.addThyroidShield(payload).then((response) => {
      if (response) {
        history.push(URL_THYROID_SHIELD);
      }
    });

  return (
    <>
      <Title title="Add Thyroid Shield" />
      <ModifyThyroidShieldForm
        backUrl={URL_THYROID_SHIELD}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default ThyroidShieldAddPage;
