import { Container } from 'flux/utils';

import AbstractReportPage from 'page/reports/report-creator/AbstractReportPage';
import Radio from 'components/form/input/Radio';
import InvoiceReportPageActions from 'actions/reports/custom-reports/invoice-report/InvoiceReportPageActions';
import InvoiceReportPageStore from 'stores/reports/custom-reports/invoice-report/InvoiceReportPageStore';
import { INVOICE_REPORT as PAGE_ID } from 'constant/pagesId/reports';
import { prettierFilterPayload } from 'page/reports/report-creator/filter/prettierFilterPayload';

const FACILITY_COLUMNS = [
  { name: 'invoice_no', title: 'Billing #' },
  { name: 'invoice_dt', title: 'Billing Date' },
  { name: 'facilitynm', title: 'Facility Name' },
  { name: 'pos', title: 'POS' },
  { name: 'status', title: 'Status' },
  { name: 'total_price', title: 'Total Price' },
];

const PATIENT_COLUMNS = [
  { name: 'studyid', title: 'Study ID' },
  { name: 'refid', title: 'Invoice ID' },
  { name: 'invoice_no', title: 'Invoice #' },
  { name: 'invoice_dt', title: 'Invoice Date' },
  { name: 'mrn', title: 'MRN' },
  { name: 'firstname', title: 'First Name' },
  { name: 'lastname', title: 'Last Name' },
  { name: 'dob', title: 'DOB', className: 'text-nowrap' },
  { name: 'faci_name', title: 'Facility' },
  { name: 'dos', title: 'DOS', className: 'text-nowrap', dataType: 'date' },
  { name: 'modality', title: 'Modality' },
];

export class InvoiceReportPage extends AbstractReportPage {
  static getStores() {
    return [InvoiceReportPageStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = AbstractReportPage.getPageState('invoice_report_grid');
      prevState.hideChart = true;
      prevState.orderType = 'F';
      prevState.paginationStrategy = AbstractReportPage.LOGIC_PAGINATION;
    }
    return {
      ...prevState,
      dataSource: InvoiceReportPageStore.getState().resultList,
    };
  }

  getTitle() {
    return 'Invoice Report';
  }

  getColumns() {
    switch (this.state.orderType) {
      case 'P':
        return PATIENT_COLUMNS;
      case 'F':
        return FACILITY_COLUMNS;
      default:
        return [];
    }
  }

  getGridID() {
    return 'invoice_report_grid';
  }

  getExportedFileName() {
    return 'PatientInvoiceReport';
  }

  getClass() {
    return 'invoice-report-overview-page';
  }

  getShownFilters() {
    return [
      'facility',
      'studyID',
      'invoiceId',
      'invoiceNumber',
      'invoiceDate',
      'adjustmentCode',
      'modeOfPayment',
    ];
  }

  exportPdf = () => {
    let state = { ...this.state };
    const dataSource = [...this.state.dataSource];
    return this.getActions().createPdf(
      dataSource,
      this.getDataArguments().toString(),
      state.orderType
    );
  };

  loadListAction(conditionList, joint) {
    let state = { ...this.state };
    const payload = prettierFilterPayload(conditionList);

    switch (state.orderType) {
      case 'P':
        return InvoiceReportPageActions.getInvoiceOrderReportByPatient(
          payload,
          joint
        );

      case 'F':
        return InvoiceReportPageActions.getInvoiceOrderReportByFacility(
          payload,
          joint
        );
      default:
        break;
    }
  }

  extraFilters() {
    return [
      <div style={{ marginLeft: 10 }}>
        <Radio
          name="orderType"
          value={this.state.orderType}
          onSetValue={this.setOrderType.bind(this)}
          options={[
            { label: 'Facility Order', value: 'F' },
            { label: 'Patient Order', value: 'P' },
          ]}
          label={<b>&nbsp;</b>}
          className="part-inline"
        />
      </div>,
    ];
  }

  setOrderType(name, orderType) {
    this.setState({ orderType });
  }

  getPageAppId() {
    return PAGE_ID.PAGE;
  }

  getActions() {
    return InvoiceReportPageActions;
  }
}

const invoiceReportPage = Container.create(InvoiceReportPage);
export default invoiceReportPage;
