import { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
// @ts-ignore
import useFluxStore from 'flux-hooks';

import EmailFormWrapper from '../components/EmailFormWrapper';

import InboxActions from 'actions/messages/inbox/InboxActions';
import InboxPageStore from 'stores/messages/inbox/InboxPageStore';
import UserProfileStore from 'stores/UserProfileStore';
import { emailInboxReplyFormatter } from 'utils/emailValuesFormatter';
import { URL_INBOX } from 'constant/path/messages';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const InboxReplyPage = ({ match: { params } }: PropsType) => {
  const [fetching, setFetching] = useState<boolean>(true);

  const messageDetailed = useFluxStore(
    InboxPageStore,
    (prevState: any, store: any) => store.getState().messageDetailed
  );

  const userId = UserProfileStore.getUserId();

  const defaultValues = useMemo(
    () =>
      messageDetailed
        ? emailInboxReplyFormatter(messageDetailed, userId)
        : null,
    [messageDetailed, userId]
  );

  useEffect(() => {
    InboxActions.getInboxMessageDetails(params.id).then(() => {
      setFetching(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EmailFormWrapper
      title="Reply"
      fetching={fetching}
      defaultValues={defaultValues}
      backLink={URL_INBOX}
    />
  );
};

export default InboxReplyPage;
