import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import resolver from './validation';

const initialState: FormModel = {
  carrier_format: '',
  mobile_operator: '',
  sms_limit: 0,
};

export interface FormModel {
  carrier_format: string;
  mobile_operator: string;
  sms_limit: number;
}

interface PropsType {
  backUrl: string;
  defaultValues?: FormModel;
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormMobileOperator = ({
  defaultValues = initialState,
  backUrl,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  const cn = `col-md-10 col-lg-8 col-xl-6${isSubmitting ? ' on-ajax' : ''}`;

  const handleReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form className={cn} onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="mobile_operator"
        label="Mobile Operator"
        required
        control={control}
      />
      <Input
        name="carrier_format"
        label="Carrier Format"
        required
        control={control}
      />
      <Input
        name="sms_limit"
        label="SMS Length Limit"
        type="number"
        control={control}
      />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormMobileOperator;
