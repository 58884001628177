import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeUltrasoundUnit,
  UnitFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/ultrasoundUnit';

import { URL_ULTRASOUND_UNIT } from 'constant/path/workflow';

interface PropsType extends RouteComponentProps {}

const UltrasoundUnitAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: UnitFormModel) =>
    storeUltrasoundUnit.addUnit(data).then((error) => {
      if (error) return error;
      history.push(URL_ULTRASOUND_UNIT);
    });

  return (
    <>
      <Title title="Add Ultrasound Unit" />
      <Form onSubmit={handleSubmit} backUrl={URL_ULTRASOUND_UNIT} />
    </>
  );
};

export default UltrasoundUnitAddPage;
