import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import DropdownEmailTemplate from 'components/project/dropdown/EmailTemplate';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { Checkbox } from 'components/form/checkbox';
import { Input } from 'components/form/textField';
import { InputCalendar } from 'components/form/inputCalendar';
import MaskInput from 'components/form/maskInput';
import { TextEditor } from 'components/form/richTextEditor';
import validation from './validation';

import UserProfileStore from 'stores/UserProfileStore';
import { storeEmailTemplate } from 'stores/_mobx/systemSetup/masterSetting/emailTemplate';
import { getDateString } from 'utils/DateUtils';

const className = 'col-md-6';

export const createFaxBody = (company: string) =>
  `Our records show that we are in need of assignment for the billing direction. The exams performed by ${company} at your facility per your request are found within the attached list. Please identify those patient exams that were performed during the (PPS) Part A benefit period. Your facility will receive an invoice for the Portable Diagnostic Imaging services performed by ${company} at your facility.\nOnly those patients who have been identified by your facility representative and/or received services within the Part A benefit period will be applied to the facility invoice.\n For your convenience please refer to the attached list identifying the patient(s) and the Date of Service for which the exam was performed.`;

export const getInitialValues = (): FaxModel => ({
  from: UserProfileStore.getUser()?.name || '',
  faxFrom: UserProfileStore.getState()?.userProfile?.admininfo?.[0]?.billingfax,
  to: '',
  date: getDateString(),
  template: 0,
  subject: '',
  body: '<p><br></p>',
  examRequisition: false,
});

export interface FaxModel {
  from: string;
  to: string;
  date: string;
  faxFrom: string;
  template: number;
  subject: string;
  body: string;
  examRequisition: boolean;
}

interface PropsType {
  defaultValues: FaxModel;
  withRecipientNumber?: boolean;
  withExamRequisition?: boolean;
  onClose: () => void;
  onSubmit: (data: FaxModel) => void;
}

const DialogFormTextFax = ({
  defaultValues,
  withRecipientNumber = false,
  withExamRequisition = false,
  onClose,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isSubmitting },
    watch,
    reset,
    setValue,
    handleSubmit,
  } = useForm<FaxModel>({
    defaultValues,
    resolver: validation({ withRecipientNumber }),
  });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'template') {
        const template = storeEmailTemplate.findTemplate(formValue.template);

        setValue('subject', template?.subject || '');

        setValue('body', template?.body || '');
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Send Fax" onClose={onClose} />
      <DialogBody>
        <form className="row" onSubmit={handleClickSubmit}>
          <Input
            name="from"
            label="From"
            className={className}
            control={control}
          />
          <InputCalendar
            name="date"
            label="Date"
            className={className}
            control={control}
          />
          <MaskInput
            name="faxFrom"
            label="Fax from"
            format="###-###-####"
            className={className}
            control={control}
          />
          <DropdownEmailTemplate
            name="template"
            label="Email/Fax Template"
            className={className}
            control={control}
          />

          <Input
            name="subject"
            label="Subject"
            className={className}
            control={control}
          />

          {withRecipientNumber && (
            <MaskInput
              name="to"
              label="Fax To"
              format="###-###-####"
              required
              className={className}
              control={control}
            />
          )}

          {withExamRequisition && (
            <Checkbox
              name="examRequisition"
              label="Include Exam Requisition Form(s)"
              className={className}
              control={control}
            />
          )}

          <TextEditor
            name="body"
            label="Fax message"
            required
            control={control}
          />

          <button type="submit" className="d-none" />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          text="Send"
          disabled={isSubmitting}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogFormTextFax;
