import { useForm } from 'react-hook-form';

import RadioOptions from 'components/project/common/RadioOptions';
import { DropdownSpecialty } from 'components/project/dropdown/SpecialtyDropdown';
import { DropDownClinicianGroup } from 'components/project/dropdown/ClinicianGroupDropdown';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import { InputCalendar } from 'components/form/inputCalendar';
import { Radio } from 'components/form/radio';
import { ControlsLayout } from 'components/layout';

import {
  storePhysician,
  filterDefaultValues,
  FilterType,
} from 'stores/_mobx/clinicianManager/physician';

const PECOS_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];

const OPTIONS_COMPLETE = [
  { label: 'Complete', value: 'Y' },
  { label: 'Incomplete', value: 'N' },
];

interface PropTypes {
  isVisible: boolean;
  fetching: boolean;
  onSubmit: (payload: FilterType) => void;
}

const Filter = ({ isVisible, fetching, onSubmit }: PropTypes) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: storePhysician.filter,
  });

  const handleClickReset = () => {
    reset(filterDefaultValues);
    onSubmit(filterDefaultValues);
  };

  return (
    <form
      className={`row mb-3${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="lastName"
        label="Last Name"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <Input
        name="firstName"
        label="First Name"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <Input
        name="mobilePhone"
        label="Cell Phone"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <Input
        name="npi"
        label="NPI"
        className="col-md-6 col-lg-3"
        maxLength={10}
        control={control}
      />
      <InputCalendar
        name="loginDate"
        label="Login Date"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <DropDownClinicianGroup
        name="clinicianGroupId"
        label="Clinician Group"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <DropdownSpecialty
        name="specialtyId"
        label="Specialty"
        className="col-md-6 col-lg-3"
        control={control}
      />
      <Radio
        name="pecos"
        label="PECOS"
        className="inline col-md-6 col-lg-3"
        options={PECOS_OPTIONS}
        control={control}
      />
      <Radio
        label="Physician state"
        name="status"
        className="inline col-md-4 "
        options={RadioOptions.active}
        control={control}
      />
      <Radio
        className="col-md-4 inline"
        name="groupAdmin"
        label="Group Administrator"
        options={RadioOptions.yesNoAll}
        control={control}
      />
      <Radio
        name="completeness"
        label="Physician details"
        className="col-md-auto inline"
        options={OPTIONS_COMPLETE}
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button type="submit" text="Apply" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
