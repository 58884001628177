import React, { useEffect, useState } from 'react';

import useRequest from 'components/hook/useRequest';
import CommonLink from './CommonLink';

import { exportRequestedDoc, RequestedDocType } from 'stores/_mobx/export';
import { downloadFile } from 'utils/downloadFile';
import { BASE_URL_FILE_DIR } from 'constant/config';

interface PropsType {
  orders: any[];
  facilityId: number;
  pageKey: string;
}

const RequestedDocExportLink = (props: PropsType) => {
  const [link, setLink] = useState<string>('/');

  const { fetching, controlledAction } =
    useRequest<RequestedDocType>(exportRequestedDoc);

  const isLinkDisabled = !props.orders.length;

  const handleClick = (e: React.SyntheticEvent) => {
    if (isLinkDisabled) {
      e.preventDefault();
    } else if (link === '/') {
      e.preventDefault();
      const orders = props.orders.map(({ orderid, repcode }) => ({
        orderid,
        repcode: Object.entries(repcode).reduce(
          (prev, [key, isChecked]) => ({ ...prev, [key]: !isChecked }),
          {}
        ),
      }));
      controlledAction({ ...props, orders }).then((url) => {
        if (url) {
          const link = `${BASE_URL_FILE_DIR}pdf-creator/${url}`;
          setLink(link);
          downloadFile(link, true);
        }
      });
    }
  };

  useEffect(() => {
    setLink('/');
  }, [props.orders]);

  return (
    <CommonLink
      link={link}
      fetching={fetching}
      className={`fs-5${isLinkDisabled ? ' text-secondary' : ''}`}
      onClick={handleClick}
    />
  );
};

export default RequestedDocExportLink;
