import { Button } from 'components/button';
import { SpinnerFixed } from 'components/spinner';

interface PropsType {
  fetching: boolean;
  onRetry: () => void;
}

const ServiceNotAvailable = ({ fetching, onRetry }: PropsType) => (
  <>
    {fetching && <SpinnerFixed />}
    <div className="warning-block">Service not available</div>
    <div className="row justify-content-center">
      <Button
        text="Retry"
        variant="danger"
        className="col-sm-auto"
        disabled={fetching}
        onClick={onRetry}
      />
    </div>
  </>
);

export default ServiceNotAvailable;
