import ProfessionalGroupBillingService, {
  TRgApprovalFilter,
  TRgApproval,
  TExpHistoryFilter,
  TExpHistoryItem,
} from 'services/billing-coding/ProfessionalGroupBillingService';
import appDispatcher from 'dispatcher/AppDispatcher';
import { TPagination } from 'components/grid/GridTypes';
import { getDateRangeBounds } from 'utils/DateUtils';

export default class ClaimsActions {
  static loadOverview(
    filter: TRgApprovalFilter,
    pagination: TPagination
  ): Promise<Array<TRgApproval>> {
    let count = 0;
    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });
    const payload = {
      ...filter,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
    };
    return ProfessionalGroupBillingService.loadCount(payload)
      .then((response: 'SE' | number) => {
        count = response === 'SE' ? 0 : response;
        if (response === 0) {
          return [];
        }
        return ProfessionalGroupBillingService.loadOverview(
          payload,
          pagination
        );
      })
      .then((response: 'SE' | 0 | Array<TRgApproval>) => {
        response = response === 'SE' || response === 0 ? [] : response;
        appDispatcher.dispatch({
          type: 'professional-group-billing-overview',
          data: response,
          count,
        } as any);
        return response;
      });
  }

  static loadHistoryOverview(
    filter: TExpHistoryFilter,
    pagination: TPagination
  ): Promise<Array<TExpHistoryItem>> {
    let count = 0;
    return ProfessionalGroupBillingService.loadHistoryCount(filter)
      .then((response: number) => {
        count = response;
        if (response === 0) {
          return [];
        }
        return ProfessionalGroupBillingService.loadHistoryOverview(
          filter,
          pagination
        );
      })
      .then((response: 0 | Array<TExpHistoryItem>) => {
        response = response === 0 ? [] : response;
        appDispatcher.dispatch({
          type: 'professional-group-billing-history-overview',
          data: response,
          count,
        } as any);
        return response;
      });
  }

  static clear() {
    appDispatcher.dispatch({
      type: 'professional-group-billing-clean',
    });
  }
}
