import clsx from 'clsx';

interface PropsType {
  className?: string;
  required?: boolean;
  label?: React.ReactNode;
  name?: string;
  value: string | number;
}

const FakeInput = ({ className, required, label, value }: PropsType) => {
  const cn = clsx(
    'formfield-holder',
    'formfield-text',
    'fake-input',
    { required },
    className
  );

  return (
    <div className={cn}>
      {label ? <label className="form-label">{label}</label> : null}
      <div className="input-group">
        <div className="form-control">{value}</div>
      </div>
    </div>
  );
};

export default FakeInput;
