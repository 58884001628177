import { useMemo } from 'react';

import Common, { ParentPropsType } from './Common';

import { storeChatting } from 'stores/_mobx/message/chatting';

const DialogMessagesInvoice = ({ id, onClose }: ParentPropsType) => {
  const payload = useMemo(() => ({ invoiceId: id }), [id]);

  const getListMessages = () => {
    storeChatting.getMessages(id, 'invoice');
  };

  return (
    <Common
      title={`Invoice log for #${id}`}
      payload={payload}
      onClose={onClose}
      getListMessages={getListMessages}
    />
  );
};

export default DialogMessagesInvoice;
