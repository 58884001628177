import appDispatcher from 'dispatcher/AppDispatcher';

import TechPatientPageService from 'services/reports/report-creator/tech-patient/TechPatientPageService';
import { Actions } from 'stores/reports/report-creator/tech-patient/TechPatientPageStore';

export default class TechPatientPageActions {
  static loadTechPatientList(conditionList, joint) {
    return TechPatientPageService.loadTechPatientList(
      conditionList,
      joint
    ).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_TECH_PATIENT_LIST,
        data: response,
      });
    });
  }

  static createPdf(techPatientList, formatDescription) {
    return TechPatientPageService.createPdf(techPatientList, formatDescription);
  }
}
