import { ReduceStore } from 'flux/utils';

import appDispatcher from 'dispatcher/AppDispatcher';

export let Actions = {
  CLEAR_STORE_ACTION: 'clear-store-action',
  LOAD_STATUS_LOG_ACTION: 'load-status-log-list-action',
  LOAD_ACCESS_LOG_ACTION: 'load-access-log-list-action',
  LOAD_UNIT_LIST_ACTION: 'vehicle.DeviceMaster.DeviceXrayDropDown_new',
  LOAD_EKG_LIST_ACTION: 'inventory.ekg.EkgNameDropDown',
  LOAD_ITEM_ACTION: 'load-item-action',
  LOAD_EQUIPMENT_BY_TECH: 'load-equipment-by-tech',
  LOAD_VEHICLE_LIST_ACTION: 'load-vehicle-list-action',
  SET_BACK_TO_LIST_EQUIPMENT: 'back-to-list-equipment',
};

class EquipmentPageStore extends ReduceStore {
  getInitialState() {
    return {
      checkOutList: [],
      checkOutItem: {},
      checkOutCount: 0,
      statusLogList: [],
      accessLogList: [],
      checkOutListHistory: [],
      checkOutCountHistory: 0,
      unitList: [],
      ekgList: [],
      phoneList: [],
      checkOutItemIsLoaded: false,
      techList: [],
      techInformation: {},
      vehicleList: [],
    };
  }

  isLoaded() {
    return this.getState().checkOutItemIsLoaded;
  }

  areEqual(state1, state2) {
    return false;
  }

  reduce(state, action) {
    switch (action.type) {
      case Actions.LOAD_STATUS_LOG_ACTION:
        this.getState().statusLogList = action.valData;
        break;
      case Actions.LOAD_ACCESS_LOG_ACTION:
        this.getState().accessLogList = action.valData;
        break;
      case Actions.CLEAR_STORE_ACTION:
        this.getState().checkOutItem = {};
        this.getState().checkOutItemIsLoaded = action.isLoaded;
        this.getState().unitList = [];
        this.getState().ekgList = [];
        this.getState().phoneList = [];
        this.getState().techList = [];
        this.getState().vehicleList = [];
        break;
      case Actions.LOAD_UNIT_LIST_ACTION:
        this.getState().unitList = action.valData;
        break;
      case Actions.LOAD_EKG_LIST_ACTION:
        this.getState().ekgList = action.valData;
        break;
      case Actions.LOAD_ITEM_ACTION:
        this.getState().checkOutItem = action.valData;
        this.getState().checkOutItemIsLoaded = action.isLoaded;
        break;
      case 'vehicle.EquipmentMaster.PhoneNumberDropDown':
        this.getState().phoneList = action.valData || action.data;
        break;
      case Actions.LOAD_EQUIPMENT_BY_TECH:
        if (action.valData) {
          this.getState().techInformation = action.valData[0];
        } else {
          this.getState().techInformation = action.valData;
        }
        break;
      case Actions.SET_BACK_TO_LIST_EQUIPMENT:
        this.getState().backToList = action.data.backToList;
        this.getState().outFilter = action.data.outFilter;
        break;
      case 'order.Order.TechDropDown_equipment_page':
        this.getState().techList = action.data;
        break;
      case Actions.LOAD_VEHICLE_LIST_ACTION:
        this.getState().vehicleList = action.valData;
        break;
      default:
        break;
    }
    return state;
  }
}

let equipmentStore = new EquipmentPageStore(appDispatcher);
export default equipmentStore;
