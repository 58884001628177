import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import CodeActivationForm from './components/Form';

import {
  storeCodeActivation,
  CptCodeDetailsType,
} from 'stores/_mobx/systemSetup/codeActivationMaster';
import { URL_CODE_ACTIVATION_MASTER } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const CodeActivationMasterAddPage = ({ history }: PropsType) => {
  const handleSubmit = async (data: CptCodeDetailsType) =>
    storeCodeActivation.modifyCode(data).then((error) => {
      if (error) return error;
      history.push(URL_CODE_ACTIVATION_MASTER);
    });

  return (
    <>
      <Title title="Create new CPT code" />
      <CodeActivationForm
        backUrl={URL_CODE_ACTIVATION_MASTER}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default CodeActivationMasterAddPage;
