import { TOrderCpt } from 'services/clinician-manager/sign-orders/SignOrdersPageService';

export const getDefaultSelectedOrders = (cptCodes: TOrderCpt[]) =>
  cptCodes
    .filter(({ flag_need_addendum }) => flag_need_addendum)
    .map(({ order_initial_cpt_refid }) => order_initial_cpt_refid);

export const formatToGridData = (cptCodes: TOrderCpt[], examType: string) =>
  cptCodes
    .filter((cpt) => Boolean(cpt.transcription_id))
    .map((cpt: TOrderCpt) => ({
      id: cpt.order_initial_cpt_refid,
      cptCode: cpt.cpt_code,
      cptDescription: cpt.cpt_desc,
      modality: examType,
      accession: cpt.accession_number,
      radiologyGroupId: cpt.need_addendum_from_radiology_group_id,
    }));
