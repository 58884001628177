import { forwardRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select, {
  PropsType as SelectPropsType,
} from 'components/form/select/Select';
import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
} from './AbstractDropdown';

import {
  storePerDiem,
  OptionType,
} from 'stores/_mobx/systemSetup/masterSetting/perDiem';

interface PropsType extends PAbstractDropdown {
  options: OptionType[];
  ref: any;
}

class PerDiemDropdown extends AbstractDropdown<PropsType, SAbstractDropdown> {
  getStore(): null {
    return null;
  }

  loadOptions(): null {
    return null;
  }

  getOptions() {
    return this.props.options;
  }
}

const DropDownMiddleware = forwardRef<PerDiemDropdown, any>(
  (props: PAbstractDropdown, ref) => {
    const { options, fetchingOptions } = storePerDiem;

    useEffect(() => {
      storePerDiem.getOptions();
    }, []);

    return (
      <PerDiemDropdown
        {...props}
        ref={ref}
        options={options}
        fetching={fetchingOptions}
      />
    );
  }
);

export default observer(DropDownMiddleware);

export const DropdownPerDiem = observer(
  (props: Omit<SelectPropsType, 'options'>) => {
    const { fetching, options } = storePerDiem;

    useEffect(() => {
      storePerDiem.getOptions();
    }, []);

    return <Select {...props} options={options} isLoading={fetching} />;
  }
);
