import { forwardRef } from 'react';
import { Controller, Control } from 'react-hook-form';

import NumberInput from '../input/NumberInput';

interface Props {
  name: string;
  format: string;
  label?: string;
  placeholder?: string;
  control: Control<any>;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  autoFocus?: boolean;
  defaultValue?: string;
}

const MaskInput = forwardRef<any, Props>(
  (
    {
      control,
      name,
      defaultValue = '',
      label = '',
      required,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    const onKeyUp =
      (handleChange: (v: string) => void) => (name: string, value: string) => {
        handleChange(value);
      };
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required }}
        render={({ field, fieldState: { error } }) => {
          const errorMessage =
            error && error.message ? [error && error.message] : undefined;

          return (
            <NumberInput
              name={name}
              {...rest}
              {...field}
              ref={ref}
              label={label}
              validations={required ? 'required' : ''}
              onSetValue={onKeyUp(field.onChange)}
              errors={errorMessage}
              readOnly={disabled}
            />
          );
        }}
      />
    );
  }
);

export default MaskInput;
