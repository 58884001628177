import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface TransmitLogResponseType {
  action: string;
  date: string;
  faxed_req: 'N' | 'Y';
  name: string;
  refid: string;
}

interface TransmitLogType
  extends Omit<TransmitLogResponseType, 'refid' | 'faxed_req'> {
  id: number;
  faxedRequest: string;
}

interface LogResponseType {
  count: number;
  data: TransmitLogResponseType[];
}

interface TransmitLogProps {
  bill_email: string;
  bill_fax: string;
  id: number;
  name: string;
}

class DocManagerAuditTransmitLog {
  fetching: boolean = false;
  logList: TransmitLogType[] = [];
  logTotal: number = 0;
  idForTransmitLogDialog: number = 0;
  transmitLogProps: TransmitLogProps | null = null;

  page: Pagination = new Pagination({ clearOnUnmount: true });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      logList: observable,
      logTotal: observable,
      idForTransmitLogDialog: observable,
      transmitLogProps: observable,

      setFetching: action,
      setLogs: action,
      setIdForTransmitLogDialog: action,
      setTransmitLogProps: action,
      clearIdForTransmitLogDialog: action.bound,
      clearTransmitLogProps: action.bound,
      clearLogs: action.bound,
      clearTransmitLogsStore: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setLogs(list: TransmitLogType[] = [], total: number = 0) {
    this.logList = list;
    this.logTotal = total;
  }

  setIdForTransmitLogDialog(facilityId: number) {
    this.idForTransmitLogDialog = facilityId;
  }

  setTransmitLogProps(props: TransmitLogProps) {
    this.transmitLogProps = props;
  }

  clearTransmitLogProps() {
    this.transmitLogProps = null;
  }

  clearIdForTransmitLogDialog() {
    this.idForTransmitLogDialog = 0;
  }

  clearLogs() {
    this.logList = [];
    this.logTotal = 0;
  }

  clearTransmitLogsStore() {
    this.clearTransmitLogProps();
    this.clearIdForTransmitLogDialog();
  }

  async getTransmitLog({
    id,
    type,
  }: {
    id: number;
    type: 'physician' | 'facility';
  }) {
    const { pagination } = this.page;

    this.setFetching(true);

    try {
      const { count, data } = await apiRequest<LogResponseType>({
        url: 'facility.DocumentManagerLog.getLog',
        data: [id, type, pagination.skip, pagination.pageSize],
      });

      const logs = data.map(({ refid, faxed_req, date, ...log }) => ({
        ...log,
        id: Number(refid),
        faxedRequest: faxed_req === 'Y' ? 'Yes' : 'No',
        date: dateToLocalTimezone({ date }),
      }));

      this.setLogs(logs, count);
    } catch (e) {
      this.setLogs();
    } finally {
      this.setFetching(false);
    }
  }
}

export const storeDocManagerAuditTransmitLog = new DocManagerAuditTransmitLog();
