import { ReduceStore } from 'flux/utils';
import appDispatcher from 'dispatcher/AppDispatcher';

export const Actions = {
  CLEAR_STORE_ACTION: 'clear-store-action',
  LOAD_COUNT_ACTION: 'employee-time-sheet-load-count-action',
  LOAD_LIST_ACTION: 'employee-time-sheet-load-list-action',
  CLEAR_SUPERVISOR_IDS_LIST_ACTION: 'clear-supervisor-ids-list-action',
  LOAD_SUPERVISOR_IDS_LIST_ACTION: 'load-supervisor-ids-list-action',
  CLEAR_EMPLOYEE_IDS_LISTS_ACTION: 'clear-employee-ids-list-action',
  LOAD_EMPLOYEE_IDS_LIST_ACTION: 'load-employee-ids-list-action',
  LOAD_SUPERVISOR_ASSIGNED_EMPLOYEES_LIST_ACTION:
    'load-supervisor-assigned-employees-list-action',
  LOAD_SUPERVISORS_FULL_COUNT_ACTION: 'load-supervisors-full-count-action',
  LOAD_SUPERVISORS_FULL_LIST_ACTION: 'load-supervisors-full-list-action',
  LOAD_EMPLOYEES_FULL_COUNT_ACTION: 'load-employees-full-count-action',
  LOAD_EMPLOYEES_FULL_LIST_ACTION: 'load-employees-full-list-action',
  LOAD_USERS_FULL_COUNT_ACTION: 'load-users-full-count-action',
  LOAD_USERS_FULL_LIST_ACTION: 'load-users-full-list-action',
  LOAD_COMPANY_USERS_FULL_COUNT_ACTION: 'load-company-users-full-count-action',
  LOAD_COMPANY_USERS_FULL_LIST_ACTION: 'load-company-users-full-list-action',
};

class EmployeeTimeSheetPageStore extends ReduceStore {
  getInitialState() {
    return {
      employeeTimeSheetFilter: null,
      employeeTimeSheetCount: 0,
      employeeTimeSheetList: [],
      employeeTimeSheetItem: {},
      superVisorIdsList: null,
      employeeIdsLists: {},
      superVisorAssignedEmployeesList: [],
      superVisorsFullFilter: null,
      superVisorsFullCount: 0,
      superVisorsFullList: [],
      employeesFullFilter: null,
      employeesFullCount: 0,
      employeesFullList: [],
      usersFullFilter: null,
      usersFullCount: 0,
      usersFullList: [],
    };
  }

  areEqual(state1, state2) {
    return false;
  }

  reduce(state, action) {
    const internState = this.getState();
    switch (action.type) {
      case Actions.CLEAR_STORE_ACTION:
        internState.employeeTimeSheetFilter = null;
        internState.employeeTimeSheetList = [];
        internState.employeeTimeSheetCount = 0;
        internState.employeeTimeSheetItem = {};
        break;
      case Actions.LOAD_COUNT_ACTION:
        internState.employeeTimeSheetCount = action.data;
        break;
      case Actions.LOAD_LIST_ACTION:
        internState.employeeTimeSheetFilter = action.filter;
        internState.employeeTimeSheetList = action.data;
        break;
      case Actions.CLEAR_SUPERVISOR_IDS_LIST_ACTION:
        internState.superVisorIdsList = null;
        break;
      case Actions.LOAD_SUPERVISOR_IDS_LIST_ACTION:
        internState.superVisorIdsList = action.data;
        break;
      case Actions.CLEAR_EMPLOYEE_IDS_LISTS_ACTION:
        internState.employeeIdsLists = {};
        break;
      case Actions.LOAD_EMPLOYEE_IDS_LIST_ACTION:
        internState.employeeIdsLists[action.superVisorId] = action.data;
        break;
      case Actions.LOAD_SUPERVISOR_ASSIGNED_EMPLOYEES_LIST_ACTION:
        internState.superVisorAssignedEmployeesList = action.data;
        break;
      case Actions.LOAD_SUPERVISORS_FULL_COUNT_ACTION:
        internState.superVisorsFullCount = action.data;
        break;
      case Actions.LOAD_SUPERVISORS_FULL_LIST_ACTION:
        internState.superVisorsFullFilter = action.filter;
        internState.superVisorsFullList = action.data;
        break;
      case Actions.LOAD_EMPLOYEES_FULL_COUNT_ACTION:
        internState.employeesFullCount = action.data;
        break;
      case Actions.LOAD_EMPLOYEES_FULL_LIST_ACTION:
        internState.employeesFullFilter = action.filter;
        internState.employeesFullList = action.data;
        break;
      case Actions.LOAD_USERS_FULL_COUNT_ACTION:
        internState.usersFullCount = action.data;
        break;
      case Actions.LOAD_USERS_FULL_LIST_ACTION:
        internState.usersFullFilter = action.filter;
        internState.usersFullList = action.data;
        break;
      case Actions.LOAD_COMPANY_USERS_FULL_COUNT_ACTION:
        internState.usersFullCount = action.data;
        break;
      case Actions.LOAD_COMPANY_USERS_FULL_LIST_ACTION:
        internState.usersFullFilter = action.filter;
        internState.usersFullList = action.data;
        break;
      default:
        break;
    }
    return state;
  }
}

const employeeTimeSheetPageStore = new EmployeeTimeSheetPageStore(
  appDispatcher
);
export default employeeTimeSheetPageStore;
