import AbstractLikeFilter from './AbstractLikeFilter';

export default class PhysicianFirstNameFilter extends AbstractLikeFilter {
  name = 'physicianFirstName';
  field = ' Physician First Name';
  field1 = AbstractLikeFilter.getInsecureSecureField(
    'ppxray_providerinfo.first_name'
  );
  className = 'physician-first-name-filter';
}
