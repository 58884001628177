import { ReduceStore } from 'flux/utils';
import { extend } from 'utils/IterableUtils';

import appDispatcher, { CommonPayload } from 'dispatcher/AppDispatcher';

const buildActionName = (name: string) => {
  return `patient-records/patient-demographics/${name}`;
};

export const Actions = {
  LOAD_PATIENT_RECORD_ACTION: buildActionName('load-patient-record-action'),
  /*UPDATE_PATIENT_RECORD_ACTION : buildActionName('update-patient-record-action'),
    SAVE_PATIENT_RECORD_ACTION : buildActionName('save-patient-record-action'),
    DELETE_PATIENT_RECORD_ACTION : buildActionName('delete-patient-record-action')*/

  UPDATE_GENERAL_INFO_ACTION: buildActionName('update-general-nfo-action'),
  UPDATE_FACILITY_INFO_ACTION: buildActionName('update-facility-info-action'),
  UPDATE_CONTACT_INFO_ACTION: buildActionName('update-contact-info-action'),
  UPDATE_EMPLOYER_INFO_ACTION: buildActionName('update-employer-info-action'),
  UPDATE_PATIENT_STATUS_INFO_ACTION: buildActionName(
    'update-patient-status-info-action'
  ),
  UPDATE_GUARANTOR_INFO_ACTION: buildActionName('update-guarantor-info-action'),
  UPDATE_INSURED_INFO_ACTION: buildActionName('update-insured-info-action'),
  UPDATE_COMMENT_INFO_ACTION: buildActionName('update-comment-info-action'),

  CACHE_PATIENT_INFO_TAB_ACTION: buildActionName(
    'cache-patient-info-tab-action'
  ),
  CACHE_EMPLOYER_INFO_TAB_ACTION: buildActionName(
    'cache-employer-info-tab-action'
  ),
  CACHE_INSURANCE_INFO_TAB_ACTION: buildActionName(
    'cache-insurance-info-tab-action'
  ),
  CACHE_COMMENT_INFO_TAB_ACTION: buildActionName(
    'cache-comment-info-tab-action'
  ),

  UNCACHE_PATIENT_INFO_TAB_ACTION: buildActionName(
    'uncache-patient-info-tab-action'
  ),
  UNCACHE_EMPLOYER_INFO_TAB_ACTION: buildActionName(
    'uncache-employer-info-tab-action'
  ),
  UNCACHE_INSURANCE_INFO_TAB_ACTION: buildActionName(
    'uncache-insurance-info-tab-action'
  ),
  UNCACHE_COMMENT_INFO_TAB_ACTION: buildActionName(
    'uncache-comment-info-tab-action'
  ),

  RESTORE_INITIAL_PATIENT_INFO_TAB_ACTION: buildActionName(
    'restore-initial-patient-info-tab-action'
  ),
  RESTORE_INITIAL_EMPLOYER_INFO_TAB_ACTION: buildActionName(
    'restore-initial-employer-info-tab-action'
  ),
  RESTORE_INITIAL_INSURANCE_INFO_TAB_ACTION: buildActionName(
    'restore-initial-insurance-info-tab-action'
  ),
  RESTORE_INITIAL_COMMENT_INFO_TAB_ACTION: buildActionName(
    'restore-initial-comment-info-tab-action'
  ),

  CLEAR_PATIENT_RECORD_ACTION: buildActionName('clear-patient-record-action'),
};

export class TCache {
  patientRecord: any = {};
  facilityInfo: any = {};
  contactInfo: any = {};
  generalInfo: any = {};
  employerInfo: any = {};
  patientStatusInfo: any = {};
  guarantorInfo: any = {};
  insuredInfo: any = {};
  commentInfo: any = {};
}

export class SPatientRecordPageStore {
  patientRecord: any = {};
  facilityInfo: any = {};
  contactInfo: any = {};
  generalInfo: any = {};
  employerInfo: any = {};
  patientStatusInfo: any = {};
  guarantorInfo: any = {};
  insuredInfo: any = {};
  commentInfo: any = {};
  cache: TCache = new TCache();
  initial: TCache = new TCache();
  patientRecordLoaded: boolean = false;
}

class PatientRecordPageStore extends ReduceStore<
  SPatientRecordPageStore,
  CommonPayload
> {
  getInitialState() {
    return new SPatientRecordPageStore();
  }

  areEqual(state1: SPatientRecordPageStore, state2: SPatientRecordPageStore) {
    return false;
  }

  getGeneralInfo(patientRecord: any) {
    const generalInfo = {
      refid: Number(patientRecord.refid),
      patient_img_path: patientRecord.patient_img_path,
      patient_id: patientRecord.patient_id,
      first_name: patientRecord.first_name,
      middle_name: patientRecord.middle_name,
      last_name: patientRecord.last_name,
      first_nickname: patientRecord.first_nickname,
      last_nickname: patientRecord.last_nickname,
      birthdate: patientRecord.birthdate,
      inmate_number: patientRecord.inmate_number,
      martial_status: patientRecord.martial_status,
      spouse_name: patientRecord.spouse_name,
      gender: patientRecord.gender,
      salutationid: Number(patientRecord.salutationid) || 0,
      suffixid: Number(patientRecord.suffixid) || 0,
      organization_id: Number(patientRecord.organization_id) || 0,
      ssn: patientRecord.ssn,
      ice_number: patientRecord.ice_number,
      immigration_id: patientRecord.immigration_id,
      clientMRN: patientRecord.clientMRN,
      smoking: patientRecord.smoking,
    };
    return generalInfo;
  }

  getFacilityInfo(patientRecord: any) {
    return {
      refid: Number(patientRecord.refid),
      facility_id: Number(patientRecord.facility_id) || 0,
      region_id: Number(patientRecord.region_id) || 0,
      state_id: Number(patientRecord.state_id) || 0,
      location_id: Number(patientRecord.location_id) || 0,
    };
  }

  getContactInfo(patientRecord: any) {
    return {
      refid: patientRecord.refid,
      address1: patientRecord.address1,
      state: patientRecord.state,
      city: patientRecord.city,
      zipcode: patientRecord.zipcode,
      address2: patientRecord.address2,
      state2: patientRecord.state2,
      city2: patientRecord.city2,
      zipcode2: patientRecord.zipcode2,
      phone: patientRecord.phone,
      mobile: patientRecord.mobile,
      fax: patientRecord.fax,
      email: patientRecord.email,
    };
  }

  getEmployerInfo(patientRecord: any) {
    const employerInfo: any = {};
    employerInfo['refid'] = patientRecord['refid'];
    employerInfo['employer_name'] = patientRecord['employer_name'];
    employerInfo['Orgcity'] = patientRecord['Orgcity'];
    employerInfo['Orgstate'] = patientRecord.Orgstate || '';
    employerInfo['Orgzipcode'] = patientRecord['Orgzipcode'];
    employerInfo['work_phone'] = patientRecord['work_phone'];
    employerInfo['Orgaddress'] = patientRecord['Orgaddress'];
    employerInfo['org_status'] = patientRecord['org_status'];
    return employerInfo;
  }

  getPatientStatusInfo(patientRecord: any) {
    const patientStatusInfo: any = {};
    patientStatusInfo['refid'] = patientRecord['refid'];
    patientStatusInfo['dt_of_death'] = patientRecord['dt_of_death'];
    patientStatusInfo['ins_last_verified'] = patientRecord['ins_last_verified'];
    patientStatusInfo['patient_status'] = patientRecord['patient_status'];
    patientStatusInfo['room'] = patientRecord['room'];
    return patientStatusInfo;
  }

  getGuarantorInfo(patientRecord: any) {
    const guarantorInfo: any = {};
    guarantorInfo['refid'] = patientRecord['refid'];
    guarantorInfo['guar_g_fnm'] = patientRecord['guar_g_fnm'];
    guarantorInfo['guar_g_lnm'] = patientRecord['guar_g_lnm'];
    guarantorInfo['guar_g_mid'] = patientRecord['guar_g_mid'];
    guarantorInfo['guar_g_gen'] = patientRecord['guar_g_gen'];
    guarantorInfo['guar_g_wphone'] = patientRecord['guar_g_wphone'];
    guarantorInfo['guar_g_ssn'] = patientRecord['guar_g_ssn'];
    guarantorInfo['guar_g_address1'] = patientRecord['guar_g_address1'];
    guarantorInfo['guar_g_address2'] = patientRecord['guar_g_address2'];
    guarantorInfo['guar_g_dob'] = patientRecord['guar_g_dob'];
    guarantorInfo['guar_g_email'] = patientRecord['guar_g_email'];
    guarantorInfo['guar_g_fax'] = patientRecord['guar_g_fax'];
    guarantorInfo['guar_g_mobile'] = patientRecord['guar_g_mobile'];
    guarantorInfo['guar_g_phone'] = patientRecord['guar_g_phone'];
    guarantorInfo['guar_g_relation'] = patientRecord['guar_g_relation'];
    guarantorInfo['guar_g_state'] = patientRecord['guar_g_state'];
    guarantorInfo['guar_g_state2'] = patientRecord['guar_g_state2'];
    guarantorInfo['guar_g_city'] = patientRecord['guar_g_city'];
    guarantorInfo['guar_g_city2'] = patientRecord['guar_g_city2'];
    guarantorInfo['guar_g_zip'] = patientRecord['guar_g_zip'];
    guarantorInfo['guar_g_zip2'] = patientRecord['guar_g_zip2'];
    return guarantorInfo;
  }

  getInsuredInfo(patientRecord: any) {
    const insuredInfo: any = {};

    insuredInfo['patient_id'] = patientRecord['patient_id'];
    insuredInfo['medicare_id'] = patientRecord['medicare_id'];
    insuredInfo['medicaid_id'] = patientRecord['medicaid_id'];
    insuredInfo['medicaid_stateid'] = patientRecord['medicaid_stateid'];

    insuredInfo['refid'] = patientRecord['refid'];

    insuredInfo['priminsfname'] = patientRecord['priminsfname'];
    insuredInfo['priminslname'] = patientRecord['priminslname'];
    insuredInfo['priminsmname'] = patientRecord['priminsmname'];
    insuredInfo['prigroupno'] = patientRecord['prigroupno'];
    insuredInfo['primpayorid'] = patientRecord['primpayorid'];
    insuredInfo['primplanid'] = patientRecord['primplanid'];
    insuredInfo['priminsurance_no'] = patientRecord['priminsurance_no'];
    insuredInfo['priminspatrelation'] = patientRecord['priminspatrelation'];
    insuredInfo['priminsgender'] = patientRecord['priminsgender'];
    insuredInfo['priminsorgname'] = patientRecord['priminsorgname'];
    insuredInfo['priminsdt'] = patientRecord['priminsdt'];
    insuredInfo['priminsstdt'] = patientRecord['priminsstdt'];
    insuredInfo['priminsendt'] = patientRecord['priminsendt'];

    insuredInfo['secinsfname'] = patientRecord['secinsfname'];
    insuredInfo['secinslname'] = patientRecord['secinslname'];
    insuredInfo['secinsmname'] = patientRecord['secinsmname'];
    insuredInfo['secgroupno'] = patientRecord['secgroupno'];
    insuredInfo['secpayorid'] = patientRecord['secpayorid'];
    insuredInfo['secplanid'] = patientRecord['secplanid'];
    insuredInfo['secninsurance_no'] = patientRecord['secninsurance_no'];
    insuredInfo['secinspatrelation'] = patientRecord['secinspatrelation'];
    insuredInfo['secinsgender'] = patientRecord['secinsgender'];
    insuredInfo['secinsorgname'] = patientRecord['secinsorgname'];
    insuredInfo['secinsdt'] = patientRecord['secinsdt'];
    insuredInfo['secinsstdt'] = patientRecord['secinsstdt'];
    insuredInfo['secinsendt'] = patientRecord['secinsendt'];

    insuredInfo['teryinsfname'] = patientRecord['teryinsfname'];
    insuredInfo['teryinslname'] = patientRecord['teryinslname'];
    insuredInfo['teryinsmname'] = patientRecord['teryinsmname'];
    insuredInfo['tergroupno'] = patientRecord['tergroupno'];
    insuredInfo['terpayorid'] = patientRecord['terpayorid'];
    insuredInfo['teryplanid'] = patientRecord['teryplanid'];
    insuredInfo['teryinsurance_no'] = patientRecord['teryinsurance_no'];
    insuredInfo['teryinspatrelation'] = patientRecord['teryinspatrelation'];
    insuredInfo['teryinsgender'] = patientRecord['teryinsgender'];
    insuredInfo['teryinsorgname'] = patientRecord['teryinsorgname'];
    insuredInfo['teryinsdt'] = patientRecord['teryinsdt'];
    insuredInfo['teryinsstdt'] = patientRecord['teryinsstdt'];
    insuredInfo['teryinsendt'] = patientRecord['teryinsendt'];

    insuredInfo['teryinsaddr1'] = patientRecord['teryinsaddr1'];
    insuredInfo['teryinsstate'] = patientRecord['teryinsstate'];
    insuredInfo['teryinsmobile'] = patientRecord['teryinsmobile'];
    insuredInfo['teryinsfax'] = patientRecord['teryinsfax'];
    insuredInfo['teryinscity'] = patientRecord['teryinscity'];
    insuredInfo['teryinszip'] = patientRecord['teryinszip'];
    insuredInfo['teryinsph'] = patientRecord['teryinsph'];
    insuredInfo['teryinsemail'] = patientRecord['teryinsemail'];

    insuredInfo['teryinsaddr2'] = patientRecord['teryinsaddr2'];
    insuredInfo['teryinsstate2'] = patientRecord['teryinsstate2'];
    insuredInfo['teryinscity2'] = patientRecord['teryinscity2'];
    insuredInfo['teryinszip2'] = patientRecord['teryinszip2'];

    return insuredInfo;
  }

  getCommentInfo(patientRecord: any) {
    const commentInfo: any = {};
    commentInfo['refid'] = patientRecord['refid'];
    commentInfo['comment'] = patientRecord['patcomment'];
    commentInfo['alert'] = patientRecord['alert'];
    return commentInfo;
  }

  reduce(state: SPatientRecordPageStore, action: CommonPayload) {
    switch (action.type) {
      case Actions.LOAD_PATIENT_RECORD_ACTION:
        let patientRecord = action.data;

        this.getState().generalInfo = this.getGeneralInfo(patientRecord);
        this.getState().facilityInfo = this.getFacilityInfo(patientRecord);
        this.getState().contactInfo = this.getContactInfo(patientRecord);
        this.getState().employerInfo = this.getEmployerInfo(patientRecord);
        this.getState().patientStatusInfo =
          this.getPatientStatusInfo(patientRecord);
        this.getState().guarantorInfo = this.getGuarantorInfo(patientRecord);
        this.getState().insuredInfo = this.getInsuredInfo(patientRecord);
        this.getState().commentInfo = this.getCommentInfo(patientRecord);

        this.getState().cache.generalInfo = this.getGeneralInfo(patientRecord);
        this.getState().cache.facilityInfo =
          this.getFacilityInfo(patientRecord);
        this.getState().cache.contactInfo = this.getContactInfo(patientRecord);
        this.getState().cache.employerInfo =
          this.getEmployerInfo(patientRecord);
        this.getState().cache.patientStatusInfo =
          this.getPatientStatusInfo(patientRecord);
        this.getState().cache.guarantorInfo =
          this.getGuarantorInfo(patientRecord);
        this.getState().cache.insuredInfo = this.getInsuredInfo(patientRecord);
        this.getState().cache.commentInfo = this.getCommentInfo(patientRecord);

        this.getState().initial.generalInfo =
          this.getGeneralInfo(patientRecord);
        this.getState().initial.facilityInfo =
          this.getFacilityInfo(patientRecord);
        this.getState().initial.contactInfo =
          this.getContactInfo(patientRecord);
        this.getState().initial.employerInfo =
          this.getEmployerInfo(patientRecord);
        this.getState().initial.patientStatusInfo =
          this.getPatientStatusInfo(patientRecord);
        this.getState().initial.guarantorInfo =
          this.getGuarantorInfo(patientRecord);
        this.getState().initial.insuredInfo =
          this.getInsuredInfo(patientRecord);
        this.getState().initial.commentInfo =
          this.getCommentInfo(patientRecord);

        this.getState().patientRecord = action.data;
        this.getState().patientRecordLoaded = true;
        break;

      case Actions.CLEAR_PATIENT_RECORD_ACTION:
        this.getState().generalInfo = {};
        this.getState().facilityInfo = {};
        this.getState().contactInfo = {};
        this.getState().employerInfo = {};
        this.getState().patientStatusInfo = {};
        this.getState().guarantorInfo = {};
        this.getState().insuredInfo = {};
        this.getState().commentInfo = {};
        this.getState().cache = new TCache();
        this.getState().initial = new TCache();
        this.getState().patientRecordLoaded = false;
        break;

      case Actions.UPDATE_GENERAL_INFO_ACTION:
        this.getState().generalInfo = action.data;
        this.getState().initial.generalInfo = action.data;
        break;
      case Actions.UPDATE_FACILITY_INFO_ACTION:
        this.getState().facilityInfo = action.data;
        this.getState().initial.facilityInfo = action.data;
        break;
      case Actions.UPDATE_CONTACT_INFO_ACTION:
        this.getState().contactInfo = action.data;
        this.getState().initial.contactInfo = action.data;
        break;
      case Actions.UPDATE_EMPLOYER_INFO_ACTION:
        this.getState().employerInfo = action.data;
        this.getState().initial.employerInfo = action.data;
        break;
      case Actions.UPDATE_PATIENT_STATUS_INFO_ACTION:
        this.getState().patientStatusInfo = action.data;
        this.getState().initial.patientStatusInfo = action.data;
        break;
      case Actions.UPDATE_GUARANTOR_INFO_ACTION:
        this.getState().guarantorInfo = action.data;
        this.getState().initial.guarantorInfo = action.data;
        break;
      case Actions.UPDATE_INSURED_INFO_ACTION:
        this.getState().insuredInfo = action.data;
        this.getState().initial.insuredInfo = action.data;
        break;
      case Actions.UPDATE_COMMENT_INFO_ACTION:
        this.getState().commentInfo = action.data;
        this.getState().initial.commentInfo = action.data;
        break;

      case Actions.CACHE_PATIENT_INFO_TAB_ACTION:
        this.getState().cache.generalInfo = action.data.generalInfo;
        this.getState().cache.facilityInfo = action.data.facilityInfo;
        this.getState().cache.contactInfo = action.data.contactInfo;
        break;
      case Actions.CACHE_EMPLOYER_INFO_TAB_ACTION:
        this.getState().cache.employerInfo = action.data.employerInfo;
        break;
      case Actions.CACHE_INSURANCE_INFO_TAB_ACTION:
        this.getState().cache.patientStatusInfo = action.data.patientStatusInfo;
        this.getState().cache.guarantorInfo = action.data.guarantorInfo;
        this.getState().cache.insuredInfo = action.data.insuredInfo;
        break;
      case Actions.CACHE_COMMENT_INFO_TAB_ACTION:
        this.getState().cache.commentInfo = action.data.commentInfo;
        break;

      case Actions.UNCACHE_PATIENT_INFO_TAB_ACTION:
        this.getState().generalInfo = this.getState().cache.generalInfo;
        this.getState().facilityInfo = this.getState().cache.facilityInfo;
        this.getState().contactInfo = this.getState().cache.contactInfo;
        break;
      case Actions.UNCACHE_EMPLOYER_INFO_TAB_ACTION:
        this.getState().employerInfo = this.getState().cache.employerInfo;
        break;
      case Actions.UNCACHE_INSURANCE_INFO_TAB_ACTION:
        this.getState().patientStatusInfo =
          this.getState().cache.patientStatusInfo;
        this.getState().guarantorInfo = this.getState().cache.guarantorInfo;
        this.getState().insuredInfo = this.getState().cache.insuredInfo;
        break;
      case Actions.UNCACHE_COMMENT_INFO_TAB_ACTION:
        this.getState().contactInfo = this.getState().cache.contactInfo;
        break;

      case Actions.RESTORE_INITIAL_PATIENT_INFO_TAB_ACTION:
        this.getState().generalInfo = extend(
          true,
          {},
          this.getState().initial.generalInfo
        );
        this.getState().facilityInfo = this.getState().initial.facilityInfo;
        this.getState().contactInfo = this.getState().initial.contactInfo;
        break;
      case Actions.RESTORE_INITIAL_EMPLOYER_INFO_TAB_ACTION:
        this.getState().employerInfo = this.getState().initial.employerInfo;
        break;
      case Actions.RESTORE_INITIAL_INSURANCE_INFO_TAB_ACTION:
        this.getState().patientStatusInfo =
          this.getState().initial.patientStatusInfo;
        this.getState().guarantorInfo = this.getState().initial.guarantorInfo;
        break;
      case Actions.RESTORE_INITIAL_COMMENT_INFO_TAB_ACTION:
        this.getState().contactInfo = this.getState().initial.contactInfo;
        break;
      default:
        break;
    }
    return state;
  }
}

const patientRecordPageStore = new PatientRecordPageStore(appDispatcher);
export default patientRecordPageStore;
