import React from 'react';
import { Container } from 'flux/utils';
import { startOfMonth, endOfMonth } from 'date-fns';

import { Button } from 'components/button';
import Form from 'components/form/Form';
import Radio from 'components/form/input/Radio';
import Text from 'components/form/input/Text';
import NumberInput from 'components/form/input/NumberInput';
import { Grid, GridControlButton } from 'components/grid';
import YearSelectionDropdown from 'components/project/dropdown/YearSelectionDropdown';
import MonthSelectionDropdown from 'components/project/dropdown/MonthSelectionDropdown';
import TextCalendar from 'components/form/input/TextCalendar';
import Notification from 'components/modal/Notification';
import { LayoutSideTitle } from 'components/layout';
import DialogConfirm from 'components/modal/dialogConfirm';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import TimeSheetPage from 'page/profile/personal/TimeSheetPage';
import Exporter from 'components/project/Exporter';
import ManageDocuments from 'components/project/modals/ManageDocuments';
import AuditLog from './AuditLog';
import {
  getUserId,
  hasNotSubmittedDays,
  hasNotApprovedDays,
  getValueForNumberInput,
  getDataArguments,
} from './helpers';

import EmployeeTimeSheetPageActions from 'actions/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageActions';
import EmployeeTimeSheetPageStore from 'stores/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageStore';
import { storeGrid } from 'stores/_mobx/grid';
import CsvExporter from 'utils/CsvExporter';
import DateUtils, { getDateString } from 'utils/DateUtils';
import MathUtils from 'utils/MathUtils';
import { getPagination } from 'utils/getPagination';

const TIME_SHEET_STATUS = [
  { value: 'A', label: 'All' },
  { value: 'S', label: 'Submitted' },
  { value: 'U', label: 'Not Submitted' },
];

const getDefaultFilter = () => ({
  employeeName: '',
  timeSheetStatus: 'A',
  year: new Date().getFullYear(),
  month: null,
  dateFrom: getDateString(startOfMonth(new Date())),
  dateTo: getDateString(endOfMonth(new Date())),
});

class EmployeeTimeSheetTab extends React.Component {
  static getStores() {
    return [EmployeeTimeSheetPageStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = {
        dataSource: [],
        dataCount: 0,
        flagConfirmDialog: false,
        flagExporterDialog: false,
        flagNotifyMessageDialog: false,
        flagImportFileDialog: false,
        flagAuditLogDialog: false,
        editSelectedEmployeeWeekTimeSheet: false,
        errors: {},
        selected: [],
        inFocus: null,
        onAjax: true,
        manualFilter: true,
        showFilter: false,
        pagination: getPagination(
          storeGrid.getPageSize('employeeTimeSheetGrid')
        ),
        filter: storeGrid.getFilter('employeeTimeSheetGrid', {
          ...getDefaultFilter(),
        }),
      };
    }
    const store = EmployeeTimeSheetPageStore.getState();
    return {
      ...prevState,
      dataFilter: store.employeeTimeSheetFilter,
      dataSource: store.employeeTimeSheetList || [],
      dataCount: store.employeeTimeSheetCount,
    };
  }

  renderTimeSheetView = (value, row) =>
    row.refid ? (
      <div className="control text-center">
        <div
          onClick={(event) => this.showTimeSheetView(row, event)}
          style={{ fontWeight: 600, color: '#278d9f' }}
          className="btn-clickable">
          EDIT
        </div>
      </div>
    ) : null;

  updateTableCell = (name, value, errorMessages, errorKeys, event, rowRef) => {
    if (!rowRef || rowRef[name] === value) {
      return;
    }
    rowRef[name] = value;
    //rowRef.flagChanged = true;
    this.forceUpdate();
  };

  renderNumberInput = (name, value, row) => (
    <NumberInput
      className="fixed-number-input-size fixed-width-input"
      name={name}
      noLabel
      readOnly
      attr={{ disabled: true }}
      format="##:##"
      rowRef={row}
      onSetValue={(name, value, errorMessages, errorKeys, event, rowRef) => {
        this.updateTableCell(
          name,
          value,
          errorMessages,
          errorKeys,
          event,
          rowRef
        );
      }}
      value={getValueForNumberInput(value)}
    />
  );

  COLUMNS = [
    { name: 'employee_name', title: 'Employee Name' },
    { name: 'employee_type', title: 'Employee Type' },
    {
      name: 'regular_time',
      title: 'Regular Time',
      maxWidth: 100,
      render: (value, row) =>
        this.renderNumberInput('regular_time', value, row),
    },
    {
      name: 'over_time',
      title: 'Over Time',
      maxWidth: 80,
      render: (value, row) => this.renderNumberInput('over_time', value, row),
    },
    {
      name: 'call_hours',
      title: 'Call Hours',
      maxWidth: 80,
      render: (value, row) => this.renderNumberInput('call_hours', value, row),
    },
    {
      name: 'vacation',
      title: 'Vacation',
      maxWidth: 80,
      render: (value, row) => this.renderNumberInput('vacation', value, row),
    },
    {
      name: 'benefits',
      title: 'Benefits',
      maxWidth: 80,
      render: (value, row) => this.renderNumberInput('benefits', value, row),
    },
    {
      name: 'pto_paid',
      title: 'Non-Paid',
      maxWidth: 80,
      render: (value, row) => this.renderNumberInput('pto_paid', value, row),
    },
    {
      name: 'edit_time_sheet',
      title: 'Time Sheet',
      export: 'exclude',
      maxWidth: 100,
      render: this.renderTimeSheetView,
    },
    {
      name: 'mileage',
      title: 'Mileage',
      maxWidth: 80,
      render: (value, row) => {
        return this.renderTextInput('mileage', value, row);
      },
    },
    {
      name: 'expenses',
      title: 'Expenses',
      maxWidth: 80,
      render: (value, row) => {
        return this.renderTextInput('expenses', value, row, {
          placeholder: '$',
        });
      },
    },
    {
      name: 'entry_log',
      export: 'exclude',
      title: 'Entry Log',
      maxWidth: 80,
      render: (value, row) => {
        return this.renderEntryLog(value, row);
      },
    },
    {
      name: 'approved_by',
      title: 'Approved By',
      render: (name, row) => {
        let res = '';
        if (!row.refid) {
          return res;
        }
        if (row.approved_by_full_name && row.approved_by) {
          if (!hasNotApprovedDays(row.approved_by)) {
            res += row.approved_by_full_name;
          }
        }
        if (row.is_submitted) {
          if (hasNotSubmittedDays(row.is_submitted)) {
            res += (res.length > 0 ? ' | ' : '') + 'Not Submitted';
          }
        }
        if (res.length > 0) {
          return res;
        }
        return (
          <button
            className="btn btn-primary btn-approve pull-right"
            onClick={(event) => {
              this.approveTimeSheet(row.refid);
              event.preventDefault();
            }}>
            Approve
          </button>
        );
      },
    },
  ];

  updateData = () => {
    const { filter, pagination } = this.state;

    this.setState({ onAjax: true }, () => {
      EmployeeTimeSheetPageActions.getPayrollReportCount(filter);
      EmployeeTimeSheetPageActions.getPayrollReportList(
        filter,
        pagination.skip,
        pagination.pageSize
      ).then(this.updateDataCallback);
    });
  };

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.setState({
      onAjax: false,
      selected: [],
    });

    if (grid) {
      grid.clearSelection();
    }
  };

  onSelectChange = (selected) => {
    this.setState({
      inFocus: selected[selected.length - 1] || null,
      selected,
    });
  };

  componentDidMount() {
    this.updateData();
  }

  componentWillUnmount() {
    storeGrid.saveFilter('employeeTimeSheetGrid', this.state.filter);
    EmployeeTimeSheetPageActions.clear();
  }

  reset = () => {
    const pagination = {
      ...this.state.pagination,
      skip: 0,
      page: 1,
    };
    const filter = getDefaultFilter();
    this.setState({ pagination, filter }, this.updateData);
  };

  handleApplyFilter = () => {
    const pagination = {
      ...this.state.pagination,
      skip: 0,
      page: 1,
    };
    this.setState({ pagination }, this.updateData);
  };

  exportCsv() {
    const csvExporter = new CsvExporter(
      this.state.dataSource,
      this.getColumns()
    );
    csvExporter.exportAndDownload('Expense_Report');
  }

  notifyEmployees = () => {
    this.setState({ flagNotifyMessageDialog: true });
  };

  onFilterSubmit = (hasErrors, filterErrors) => {
    if (hasErrors) {
      this.setState({ filterErrors });
    } else {
      this.updateData();
    }
  };

  handleClickExport = () => {
    this.setState({ flagExporterDialog: true });
  };

  handleChangeFilter = (name, value) => {
    const filter = { ...this.state.filter, [name]: value };

    this.setState({ filter }, this.updateData);
  };

  monthValidation = (value) => (value ? Boolean(this.state.filter.year) : true);

  renderFilter() {
    const { state } = this;
    if (!state.showFilter) {
      return null;
    }
    return (
      <Form
        model={state.filter}
        id="employee_time_sheet_filter"
        ref="form"
        onCollectValues={this.handleChangeFilter}
        submit={this.handleApplyFilter}>
        <Text
          name="employeeName"
          attr={{ placeholder: 'Employee Name' }}
          label="Employee Name"
          className="col-md-6 col-lg-4"
        />
        <Radio
          name="timeSheetStatus"
          label="Time Sheet Status"
          className="col-md-auto part-inline"
          options={TIME_SHEET_STATUS}
        />
        <div />
        <YearSelectionDropdown
          className="col-md-6 col-lg-3 col-xl-2"
          label="Select Year"
          name="year"
        />
        <MonthSelectionDropdown
          className="col-md-6 col-lg-3 col-xl-2"
          label="Select Month"
          name="month"
          validations={{
            custom: (value) => (value ? Boolean(this.state.filter.year) : true),
          }}
          errorMessages={{ custom: () => 'Please select year' }}
        />
        <TextCalendar
          name="dateFrom"
          label="Date From"
          className="col-md-6 col-lg-3"
        />
        <TextCalendar
          name="dateTo"
          label="Date To"
          className="col-md-6 col-lg-3"
        />
      </Form>
    );
  }

  performNotifyMessage(res, model) {
    this.setState({ flagNotifyMessageDialog: false });
    if (res) {
      EmployeeTimeSheetPageActions.sendNotifyMessage(
        this.state.selected,
        model
      ).then((response) => {
        Notification.success('Selected employee(s) have been notified');
      });
    }
  }

  getRow = (id) => {
    const { dataSource } = this.state;

    return dataSource.find(({ refid }) => refid === id) || null;
  };

  handleToggleFilter = () => {
    this.setState((state) => ({ showFilter: !state.showFilter }));
  };

  onPaginationChange = (pagination) => {
    this.setState({ pagination }, this.updateData);
  };

  getInFocusDayDate = () => {
    const row = this.getRow(this.state.inFocus);
    return row ? row.day_date : DateUtils.toDateCanonic(new Date().getTime());
  };

  importFileDialogCallback = (flagSuccess) => {
    this.setState({ flagImportFileDialog: false });
    if (flagSuccess) {
      Notification.success('Schedule(s) successfully updated');
    }
  };

  render() {
    const { state } = this;
    const { filter } = state;
    const row = this.getRow(state.inFocus);
    if (state.editSelectedEmployeeWeekTimeSheet) {
      let date = null;
      if (state.filter.dateFrom) {
        const parts = state.filter.dateFrom
          .split('/')
          .map((v) => MathUtils.parseInt(v))
          .filter((v) => v > 0);

        if (parts.length === 3) {
          date = new Date(parts[2], parts[0] - 1, parts[1]);
        }
      }
      if (date === null && row && row.day_date) {
        date = new Date();
        const dateArr = row.day_date.split('-');
        date.setFullYear(dateArr[0], dateArr[1] - 1, dateArr[2]);
      }
      return (
        <TimeSheetPage
          userId={getUserId(state.inFocus)}
          userName={row.employee_name}
          date={date}
          callback={() => this.toggleTimeSheetView(false)}
        />
      );
    }

    return (
      <>
        <LayoutSideTitle title="Payroll Report">
          <Button
            text="Manage Schedules"
            onClick={() => this.setState({ flagImportFileDialog: true })}
          />
          <Button
            variant="default"
            text={state.showFilter ? 'Hide Filter' : 'Show Filter'}
            onClick={this.handleToggleFilter}
          />
          {state.showFilter && (
            <>
              <Button variant="warning" text="Reset" onClick={this.reset} />
              <Button text="Apply" onClick={this.handleApplyFilter} />
            </>
          )}
        </LayoutSideTitle>

        {this.renderFilter()}

        <Grid
          ref="grid"
          selectId="refid"
          id="employeeTimeSheetGrid"
          columns={this.COLUMNS}
          onAjax={state.onAjax}
          dataSourceCount={state.dataCount}
          dataSource={state.dataSource}
          selectedIds={state.selected}
          pagination={state.pagination}
          onSelectChange={this.onSelectChange}
          onPaginationChange={this.onPaginationChange}
          gridControlPanel={
            <>
              <GridControlButton
                title="Export"
                onClick={this.handleClickExport}
              />
              <GridControlButton
                title="Notify Employee(s)"
                onClick={this.notifyEmployees}
                disabled={!state.selected.length}
              />
            </>
          }
        />

        {state.flagConfirmDialog && (
          <DialogConfirm
            onClose={this.handleCloseDialogConfirm}
            onApprove={this.handleApproveDelete}>
            You have already approved the timesheet for this employee. Are you
            sure want to make changes again?
          </DialogConfirm>
        )}
        {state.flagExporterDialog && (
          <Exporter
            exporter="EmployeeTimeSheetExporter"
            reportName="Payroll_Report"
            this={this}
            filters={() => getDataArguments(state.filter)}
            callback={() => this.setState({ flagExporterDialog: false })}
            getDataSource={() => this.getExportDataSource()}
          />
        )}
        {state.flagImportFileDialog && (
          <ManageDocuments
            type="schedule"
            title="Upload Schedule Documents"
            userId={getUserId(state.inFocus)}
            anchorDayDate={this.getInFocusDayDate()}
            callback={(flagSuccess) =>
              this.importFileDialogCallback(flagSuccess)
            }
          />
        )}
        {state.inFocus && state.flagAuditLogDialog ? (
          <AuditLog
            isOpen={!state.flagAuditLogDialog}
            logId={state.inFocus}
            filter={filter}
            logLabel={
              row && row.employee_name ? row.employee_name : state.inFocus
            }
            onClose={() => {
              this.setState({ flagAuditLogDialog: false });
            }}
          />
        ) : null}
        {state.showLockPayroll && (
          <Dialog handleClose={this.handleCloseDialogPayroll}>
            <DialogHeader
              title="Confirm"
              onClose={this.handleCloseDialogPayroll}
            />
            <DialogBody>
              <div>
                You are about to lock the payroll for the selected employee(s)
                for date range:
              </div>
              <div>
                {filter.dateFrom} - {filter.dateTo}
              </div>
              {this.prepareLockPersons()}
            </DialogBody>
            <DialogFooter>
              <Button
                text="Cancel"
                variant="warning"
                onClick={this.handleCloseDialogPayroll}
              />
              <Button text="Confirm" onClick={this.handleApprovePayroll} />
            </DialogFooter>
          </Dialog>
        )}

        {state.showUnlock && (
          <Dialog handleClose={this.handleCloseDialogUnlockPayroll}>
            <DialogHeader
              title="Confirm"
              onClose={this.handleCloseDialogUnlockPayroll}
            />
            <DialogBody>
              <div>
                You are about to unlock the payroll for the{' '}
                {(state.unlockEmployee || {}).employee_name} for date range:
              </div>
              <div>
                {filter.dateFrom} - {filter.dateTo}
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                text="Cancel"
                variant="warning"
                onClick={this.handleCloseDialogUnlockPayroll}
              />
              <Button
                text="Confirm"
                onClick={this.handleApproveUnlockPayroll}
              />
            </DialogFooter>
          </Dialog>
        )}
      </>
    );
  }
}

export default Container.create(EmployeeTimeSheetTab);
