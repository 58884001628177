import { memo } from 'react';

const genderMapper = {
  M: 'bi-gender-male',
  F: 'bi-gender-female',
};

const genderTitleMapper = {
  M: 'Male',
  F: 'Female',
};

export interface PropsType {
  patientInfo: {
    patientName: string;
    dob: string;
    gender: 'M' | 'F' | '';
    dos: string;
    examType: string;
    id: number;
  };
}

const PatientInfo = ({ patientInfo }: PropsType) => (
  <dl className="description row mb-3">
    <dt className="col-sm-3 col-md-2">Patient Name</dt>
    <dd className="col-sm-3 col-md-4">
      {patientInfo.patientName}
      <i
        title={genderTitleMapper[patientInfo.gender as 'M' | 'F'] || 'Unknown'}
        className={`bi ${
          genderMapper[patientInfo.gender as 'M' | 'F'] || 'bi-gender-neuter'
        } ms-2 text-secondary`}
      />
    </dd>
    <dt className="col-sm-3 col-md-2">Date of Birth</dt>
    <dd className="col-sm-3 col-md-4">{patientInfo.dob}</dd>

    <dt className="col-sm-3 col-md-2">Date of Service</dt>
    <dd className="col-sm-3 col-md-4">{patientInfo.dos}</dd>

    <dt className="col-sm-3 col-md-2">Modality</dt>
    <dd className="col-sm-3 col-md-4">{patientInfo.examType}</dd>
  </dl>
);

export default memo(PatientInfo);
