import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const validationPayerInfo = yupResolver(
  yup.object().shape({
    name: yup.string().required('Please, enter the payer name!'),
  })
);

export const validationPayerAddress = yupResolver(
  yup.object().shape({
    email: yup.string().email('Invalid email address.'),
  })
);
