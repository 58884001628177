import React from 'react';
import { Container } from 'flux/utils';

import { Button } from 'components/button';
import Notification from 'components/modal/Notification';
import { ControlsLayout, LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import AssignSupervisorModal from './AssignSupervisorModal';
import AssignEmployeeModal from './AssignEmployeeModal';
import TimeSheetPage from 'page/profile/personal/TimeSheetPage';
import Exporter from 'components/project/Exporter';
import ManageDocuments from 'components/project/modals/ManageDocuments';
import AuditLog from './AuditLog';
import HrCanTLock from './HrCanTLock';
import {
  getUserId,
  hasNotSubmittedDays,
  hasNotApprovedDays,
  getDataArguments,
} from './helpers';
import FilterHrAdmin, { initialValues } from './components/FilterHrAdmin';
import DialogNotificationMessage from '../components/DialogNotificationMessage';

import EmployeeTimeSheetPageActions from 'actions/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageActions';
import EmployeeTimeSheetPageStore from 'stores/reports/hr-reports/employee-timesheet/EmployeeTimeSheetPageStore';
import { storeGrid } from 'stores/_mobx/grid';
import DateUtils from 'utils/DateUtils';
import MathUtils from 'utils/MathUtils';
import { getPagination } from 'utils/getPagination';

const defaultValuesSupervisorNotify = {
  subject: 'Timesheet awaiting your approval',
  body: "Dear #SUPERVISOR_NAME#, <br/><br/>You currently have one or more timesheets awaiting your approval. Please login to LatticePro and review your employee's timesheets for accuracy before submitting to HR.<br/><br/>Thank you",
};

class HRAdminTab extends React.Component {
  static getStores() {
    return [EmployeeTimeSheetPageStore];
  }

  static calculateState(prevState) {
    if (!prevState) {
      prevState = {
        editSelectedEmployeeWeekTimeSheet: false,
        errors: {},
        employeeFilter: {
          employeeName: '',
          userTypeId: null,
        },
        employeePagination: getPagination(10),
        employeesSource: [],
        employeesCount: 0,
        flagConfirmDialog: false,
        flagExporterDialog: false,
        flagImportFileDialog: false,
        flagAuditLogDialog: false,
        filter: storeGrid.getFilter('hrAdminGrid', initialValues),
        inFocus: null,
        manualFilter: true,
        showAssignEmployee: false,
        showAssignSupervisor: false,
        notifyMessageEmployeeDialogToggle: false,
        notifyMessageSupervisorDialogToggle: false,
        onAjax: true,
        showFilter: false,
        pagination: getPagination(storeGrid.getPageSize('hrAdminGrid')),
        supervisorPagination: getPagination(10),
        supervisorFilter: {},
        selected: [],
        supervisorsSource: [],
        supervisorsCount: 0,
        selectedToLock: [],
      };
    }
    const store = EmployeeTimeSheetPageStore.getState();

    return {
      ...prevState,
      dataFilter: store.employeeTimeSheetFilter,
      dataSource: store.employeeTimeSheetList,
      dataCount: store.employeeTimeSheetCount,
    };
  }

  showAuditLog = (row) => {
    row.flagChanged = false;
    this.setState({ inFocus: row.refid, flagAuditLogDialog: true });
  };

  renderEntryLog = (value, row) =>
    row.refid ? (
      <div className="control text-center">
        <div
          onClick={() => this.showAuditLog(row)}
          className={`icon btn-clickable ${
            row.flagChanged ? 'icon-auditlog-alarm' : 'icon-auditlog'
          }`}
        />
      </div>
    ) : null;

  toggleTimeSheetView = (flagShow) => {
    this.setState(
      { editSelectedEmployeeWeekTimeSheet: flagShow },
      this.updateData
    );
  };

  switchOnOffConfirmDialog = (flagConfirmDialog) => {
    this.setState({ flagConfirmDialog });
  };

  showTimeSheetView = (row, e) => {
    e.preventDefault();
    this.setState({ inFocus: row.refid });
    if (!hasNotApprovedDays(row.approved_by)) {
      this.switchOnOffConfirmDialog(true);
    } else {
      this.toggleTimeSheetView(true);
    }
  };

  renderTimeSheetView = (value, row) =>
    row.refid ? (
      <div className="text-center">
        <a
          href="/"
          onClick={(event) => this.showTimeSheetView(row, event)}
          className="fw-bold">
          EDIT
        </a>
      </div>
    ) : null;

  COLUMNS = [
    { name: 'employee_name', title: 'Employee Name' },
    { name: 'employee_type', title: 'Employee Type' },
    {
      name: 'has_comments',
      title: 'Comments',
      textAlign: 'center',
      export: 'render',
      maxWidth: 80,
      render: (v) => (v ? 'Y' : 'N'),
    },
    {
      name: 'regular_time',
      title: 'Regular Time',
      maxWidth: 100,
    },
    {
      name: 'over_time',
      title: 'Over Time',
      maxWidth: 80,
    },
    {
      name: 'call_hours',
      title: 'Call Hours',
      maxWidth: 80,
    },
    {
      name: 'vacation',
      title: 'Vacation',
      maxWidth: 80,
    },
    {
      name: 'benefits',
      title: 'Benefits',
      maxWidth: 80,
    },
    {
      name: 'pto_paid',
      title: 'Non-Paid',
      maxWidth: 80,
    },
    {
      name: 'edit_time_sheet',
      title: 'Time Sheet',
      export: 'exclude',
      maxWidth: 100,
      render: this.renderTimeSheetView,
    },
    {
      name: 'mileage',
      title: 'Mileage',
      maxWidth: 80,
    },
    {
      name: 'expenses',
      title: 'Expenses',
      maxWidth: 80,
    },
    {
      name: 'entry_log',
      title: 'Entry Log',
      maxWidth: 80,
      export: 'exclude',
      render: this.renderEntryLog,
    },
    {
      name: 'status',
      title: 'Status',
      export: 'render',
      render: (name, row) => {
        let res = '';
        if (!row.refid) {
          return res;
        }
        res += this.isSubmitted(row) ? 'Submitted' : 'Not Submitted';
        res += ', ';
        res += this.isApproved(row) ? 'AP' : 'NA';
        return res;
      },
    },
    {
      name: 'approved_by',
      title: 'Approved By',
      export: {
        target: (name, row) => {
          if (!row.refid) {
            return '';
          }
          let res = '';
          if (row.approved_by_full_name && row.approved_by) {
            if (!hasNotApprovedDays(row.approved_by)) {
              res += row.approved_by_full_name;
            }
          }
          if (res.length > 0) {
            return res;
          }
        },
      },
      render: (name, row) => {
        if (!row.refid) {
          return '';
        }
        let res = '';
        let approved = false;
        if (row.approved_by_full_name && row.approved_by) {
          if (!hasNotApprovedDays(row.approved_by)) {
            res += row.approved_by_full_name;
            approved = true;
          }
        }
        if (row.is_submitted) {
          if (!hasNotSubmittedDays(row.is_submitted) && !approved) {
            return (
              <button
                className="btn btn-primary btn-approve pull-right"
                onClick={(event) => {
                  this.approveTimeSheet(row.refid);
                  event.preventDefault();
                }}>
                Approve
              </button>
            );
          }
        }
        if (res.length > 0) {
          return res;
        }
      },
    },
    {
      name: 'is_locked',
      title: 'Is Locked',
      export: {
        target: (name, row) => {
          return this.isLocked(row) ? 'Yes' : 'No';
        },
      },
      render: (name, row) => {
        return this.isLocked(row) ? (
          <a href="/" onClick={(e) => this.attemptToUnLock(e, row)}>
            Yes
          </a>
        ) : (
          <a href="/" onClick={(e) => this.attemptToLockOne(e, row)}>
            No
          </a>
        );
      },
    },
    {
      name: 'locked_by',
      title: 'Locked By',
      export: 'render',
      render: (name, row) => {
        if (!row.refid) {
          return '';
        }
        let res = '';
        if (row.locked_by_full_name && row.locked_by) {
          if (!hasNotApprovedDays(row.locked_by)) {
            res += row.locked_by_full_name;
          }
        }
        if (res.length > 0) {
          return res;
        }
      },
    },
  ];

  isLocked = (row) => {
    if (!row.refid) {
      return false;
    }

    return !hasNotSubmittedDays(row.is_locked);
  };

  isApproved = (row) => {
    if (row.approved_by) {
      return !hasNotApprovedDays(row.approved_by);
    }

    return false;
  };

  isSubmitted = (row) => {
    if (row.is_submitted) {
      return !hasNotSubmittedDays(row.is_submitted);
    }
    return false;
  };

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.setState({
      onAjax: false,
      selected: [],
    });

    if (grid) {
      grid.clearSelection();
    }
  };

  updateData = () => {
    const { pagination, filter } = this.state;

    this.setState({ onAjax: true }, () => {
      EmployeeTimeSheetPageActions.getHrAdminOverview(
        filter,
        pagination.skip,
        pagination.pageSize
      ).then(this.updateDataCallback);
    });
  };

  updateEmployeeData = (keepSelection) => {
    const { state } = this;
    const clb = () =>
      EmployeeTimeSheetPageActions.getCompanyUsersFullCount(
        state.employeeFilter
      ).then(() =>
        EmployeeTimeSheetPageActions.getCompanyUsersFullList(
          state.employeeFilter,
          state.employeePagination.skip,
          state.employeePagination.pageSize
        ).then(() => {
          if (keepSelection) {
            this.setState({ onAjax: false });
          } else {
            this.updateDataCallback();
          }
        })
      );

    this.setState({ onAjax: true }, clb);
  };

  updateSupervisorData = (keepSelection) => {
    this.setState({ onAjax: true }, () =>
      EmployeeTimeSheetPageActions.getSuperVisorsFullList().then(() => {
        if (keepSelection) {
          this.setState({ onAjax: false });
        } else {
          this.updateDataCallback();
        }
      })
    );
  };

  onSelectChange = (selected) => {
    this.setState({
      inFocus: selected.length > 0 ? selected[selected.length - 1] : null,
      selected,
    });
  };

  handleClickExport = () => {
    this.setState({ flagExporterDialog: true });
  };

  handleFilterSubmit = (filter) => {
    this.setState({ filter }, this.updateData);
  };

  notifySupervisorsCheck = () => {
    this.setState({ notifyMessageSupervisorDialogToggle: true }, () =>
      this.updateSupervisorData(true)
    );
  };

  notifyEmployeesCheck = () => {
    this.setState({ notifyMessageEmployeeDialogToggle: true }, () =>
      this.updateEmployeeData(true)
    );
  };

  handleCloseDialogNotifyEmployee = () => {
    this.setState({ notifyMessageEmployeeDialogToggle: false });
  };

  handleSubmitNotifyEmployee = (data) =>
    EmployeeTimeSheetPageActions.sendNotifyMessage(
      this.state.selected,
      data
    ).then(() => {
      this.handleCloseDialogNotifyEmployee();
      Notification.success('Selected employee(s) have been notified');
    });

  handleCloseDialogNotifySupervisor = () => {
    this.setState({ notifyMessageSupervisorDialogToggle: false });
  };

  handleSubmitNotifySupervisor = (data) =>
    EmployeeTimeSheetPageActions.sendNotifyMessage(
      this.state.selected,
      data
    ).then(() => {
      this.handleCloseDialogNotifySupervisor();
      Notification.success('Selected supervisor(s) have been notified');
    });

  handleCloseDialogPayroll = () => {
    this.setState({ showLockPayroll: false });
  };

  handleApprovePayroll = () => {
    this.handleCloseDialogPayroll();
    this.lockTimesheet();
  };

  notifyGridColumns = () => [{ name: 'name', title: 'Name' }];

  employeeFilter = () => {
    const { state } = this;
    return {
      id: 1,
      onChangeEmployeeName: this.onChangeEmployeeName,
      onChangeUserTypeId: this.onChangeUserTypeId,
      employeeName: state.employeeFilter.employeeName,
      userTypeId: state.employeeFilter.userTypeId,
    };
  };

  employeeGrid = () => {
    const { state } = this;
    let columns = this.notifyGridColumns();
    let store = EmployeeTimeSheetPageStore.getState();
    return {
      columns: columns,
      onPageChange: this.onEmployeePaginationChange,
      pagination: state.employeePagination,
      datasource: this.convertDataSource(store.usersFullList),
      datasourceCount: store.usersFullCount,
      onAjax: state.onAjax,
    };
  };

  onEmployeePaginationChange = (employeePagination) => {
    const { employeeFilter } = this.state;

    this.setState(
      {
        employeePagination,
        onAjax: true,
      },
      () =>
        EmployeeTimeSheetPageActions.getCompanyUsersFullList(
          employeeFilter,
          employeePagination.skip,
          employeePagination.pageSize
        ).then(this.updateDataCallback)
    );
  };

  onSupervisorPaginationChange(supervisorPagination) {
    const { state } = this;
    this.setState(
      {
        supervisorPagination,
        onAjax: true,
      },
      () =>
        EmployeeTimeSheetPageActions.getSuperVisorsFullList(
          state.supervisorFilter,
          supervisorPagination.skip,
          supervisorPagination.pageSize
        ).then(this.updateDataCallback)
    );
  }

  convertDataSource = (data) =>
    data.map((item) => ({
      refid: item.refid,
      name: item.user_full_name + ' (' + item.user_type + ')',
    }));

  onChangeEmployeeName = (name, value) => {
    const { employeeFilter } = this.state;
    employeeFilter.employeeName = value;
    this.setState({ employeeFilter: employeeFilter, onAjax: true }, () =>
      this.updateEmployeeData()
    );
  };

  onChangeUserTypeId = (name, value) => {
    const { employeeFilter } = this.state;
    employeeFilter.userTypeId = value;
    this.setState({ employeeFilter: employeeFilter, onAjax: true }, () =>
      this.updateEmployeeData()
    );
  };

  lockTimesheet = () => {
    const metaData = this.prepareLockMetadata();
    const selected = metaData
      .filter(
        (meta) =>
          meta.priority === HrCanTLock.NOT_APPROVED ||
          meta.priority === HrCanTLock.VALID
      )
      .map((meta) => meta.person.refid);

    const filter = this.state.filter;
    if (selected.length > 0) {
      EmployeeTimeSheetPageActions.lockTimesheet(
        selected,
        filter.dateFrom,
        filter.dateTo
      ).then(() => {
        Notification.success(
          'Payroll for the selected employee' +
            (this.state.selectedToLock.length > 1 ? 's' : '') +
            ' has been locked'
        );
        this.updateData();
      });
    }
  };

  unlockTimesheet = () => {
    const state = this.state;
    const filter = state.filter;
    EmployeeTimeSheetPageActions.unLockTimesheet(
      state.unlockEmployee.refid,
      filter.dateFrom,
      filter.dateTo
    ).then(() => {
      Notification.success(
        'Payroll for the selected employees has been un locked'
      );
      this.updateData();
    });
  };

  attemptToLockMany(selectedToLock) {
    const state = this.state;
    const filter = state.filter;
    if (!filter.dateFrom && Boolean(filter.dateTo)) {
      Notification.warning(
        'Date From filter should be defined and valid for this operation'
      );
    } else if (!filter.dateTo && Boolean(filter.dateFrom)) {
      Notification.warning(
        'Date To filter should be defined and valid for this operation'
      );
    } else {
      this.setState({ showLockPayroll: true, selectedToLock });
    }
  }

  attemptToLockOne = (e, row) => {
    if (e) e.preventDefault();
    this.attemptToLockMany([row.refid]);
  };

  attemptToLock = () => {
    const { selected } = this.state;
    if (!selected || selected.length === 0) {
      Notification.warning('Please select at least one employee');
    } else {
      this.attemptToLockMany(selected);
    }
  };

  attemptToUnLock = (e, row) => {
    e.preventDefault();
    const state = this.state;
    const filter = state.filter;

    if (!filter.dateFrom && Boolean(filter.dateTo)) {
      Notification.warning(
        'Date From filter should be defined and valid for this operation'
      );
    } else if (!filter.dateTo && Boolean(filter.dateFrom)) {
      Notification.warning(
        'Date To filter should be defined and valid for this operation'
      );
    } else {
      this.setState({ showUnlock: true, unlockEmployee: row });
    }
  };

  prepareLockMetadata = () => {
    const metadata = [];
    const selected = this.state.selectedToLock;
    this.state.dataSource
      .filter((person) => selected.indexOf(person.refid) > -1)
      .forEach((person) => {
        if (this.isLocked(person)) {
          metadata.push({
            priority: HrCanTLock.ALREADY_LOCKED,
            person: person,
          });
        } else if (!this.isSubmitted(person)) {
          metadata.push({ priority: HrCanTLock.NOT_SUBMITTED, person: person });
        } else if (!this.isApproved(person)) {
          metadata.push({ priority: HrCanTLock.NOT_APPROVED, person: person });
        } else {
          metadata.push({ priority: HrCanTLock.VALID, person: person });
        }
      });
    if (metadata.length !== selected.length) {
      metadata.push({ priority: HrCanTLock.INVALID_DATA });
    }
    return metadata;
  };

  onPaginationChange = (pagination) => {
    this.setState({ pagination }, this.updateData);
  };

  handleToggleFilter = () => {
    this.setState((state) => ({ showFilter: !state.showFilter }));
  };

  getRow = (id) => {
    const { dataSource } = this.state;

    return dataSource.find(({ refid }) => refid === id) || null;
  };

  handleCloseDialogUnlockPayroll = () => {
    this.setState({ unlockName: null, showUnlock: false });
  };

  handleApproveUnlockPayroll = () => {
    this.handleCloseDialogUnlockPayroll();
    this.unlockTimesheet();
  };

  assignSupervisorCallback = (response) => {
    this.setState({ showAssignSupervisor: false });
    if (response) {
      Notification.success('Supervisor(s) successfully assigned');
    }
  };

  assignEmployeeCallback = (response) => {
    this.setState({ showAssignEmployee: false });
    if (response) {
      Notification.success('Employee(s) successfully assigned');
    }
  };

  getExportDataSource = () => this.state.dataSource || [];

  getInFocusDayDate = () => {
    const row = this.getRow(this.state.inFocus);
    return row ? row.day_date : DateUtils.toDateCanonic(new Date().getTime());
  };

  importFileDialogCallback = (flagSuccess) => {
    this.setState({ flagImportFileDialog: false });
    if (flagSuccess) {
      Notification.success('Schedule(s) successfully updated');
    }
  };

  componentDidMount() {
    this.updateData();
  }

  componentWillUnmount() {
    storeGrid.saveFilter('hrAdminGrid', this.state.filter);
    EmployeeTimeSheetPageActions.clear();
  }

  render() {
    const { state } = this;
    const { filter } = state;
    const row = this.getRow(state.inFocus);
    if (state.editSelectedEmployeeWeekTimeSheet) {
      let date = null;
      if (state.filter.dateFrom) {
        const parts = state.filter.dateFrom
          .split('/')
          .map((v) => MathUtils.parseInt(v))
          .filter((v) => v > 0);

        if (parts.length === 3) {
          date = new Date(parts[2], parts[0] - 1, parts[1]);
        }
      }
      if (date === null && row && row.day_date) {
        date = new Date();
        const dateArr = row.day_date.split('-');
        date.setFullYear(dateArr[0], dateArr[1] - 1, dateArr[2]);
      }
      return (
        <TimeSheetPage
          userId={getUserId(state.inFocus)}
          userName={row.employee_name}
          date={date}
          callback={() => this.toggleTimeSheetView(false)}
        />
      );
    }

    return (
      <>
        <LayoutSideTitle title="HR Admin">
          <Button
            variant="default"
            text={state.showFilter ? 'Hide Filter' : 'Show Filter'}
            onClick={this.handleToggleFilter}
          />
        </LayoutSideTitle>

        <FilterHrAdmin
          isVisible={state.showFilter}
          fetching={state.onAjax}
          defaultValues={state.filter}
          onSubmit={this.handleFilterSubmit}
        />

        <ControlsLayout alignX="right" className="mb-4">
          <Button
            variant="default"
            text="Assign Supervisor(s)"
            onClick={() => this.setState({ showAssignSupervisor: true })}
          />
          <Button
            variant="default"
            text="Assign Employee(s)"
            onClick={() => this.setState({ showAssignEmployee: true })}
          />
          <Button
            text="Notify Supervisor(s)"
            disabled={!state.selected.length}
            onClick={this.notifySupervisorsCheck}
          />
          <Button
            text="Notify Employee(s)"
            disabled={!state.selected.length}
            onClick={this.notifyEmployeesCheck}
          />
          <Button
            text="Manage Schedules"
            onClick={() => this.setState({ flagImportFileDialog: true })}
          />
        </ControlsLayout>

        <Grid
          id="hrAdminGrid"
          ref="grid"
          selectId="refid"
          columns={this.COLUMNS}
          onAjax={state.onAjax}
          dataSourceCount={state.dataCount}
          dataSource={state.dataSource}
          selectedIds={state.selected}
          pagination={state.pagination}
          onSelectChange={this.onSelectChange}
          onPaginationChange={this.onPaginationChange}
          gridControlPanel={
            <>
              <GridControlButton
                title="Export"
                onClick={this.handleClickExport}
              />
              <GridControlButton
                title="Lock Payroll"
                onClick={this.attemptToLock}
              />
            </>
          }
        />

        {state.showAssignSupervisor && (
          <AssignSupervisorModal callback={this.assignSupervisorCallback} />
        )}
        {state.showAssignEmployee && (
          <AssignEmployeeModal callback={this.assignEmployeeCallback} />
        )}
        {state.flagConfirmDialog && (
          <DialogConfirm
            onClose={this.handleCloseDialogConfirm}
            onApprove={this.handleApproveDelete}>
            You have already approved the timesheet for this employee. Are you
            sure want to make changes again?
          </DialogConfirm>
        )}
        {state.flagExporterDialog && (
          <Exporter
            this={this}
            reportName={'HR_Admin' || null}
            exporter="HrAdminExporter"
            getDataSource={this.getExportDataSource}
            filters={() => getDataArguments(state.filter)}
            callback={() => this.setState({ flagExporterDialog: false })}
          />
        )}
        {state.flagImportFileDialog && (
          <ManageDocuments
            type="schedule"
            title="Upload Schedule Documents"
            userId={getUserId(state.inFocus)}
            anchorDayDate={this.getInFocusDayDate()}
            callback={this.importFileDialogCallback}
          />
        )}
        {state.inFocus && state.flagAuditLogDialog ? (
          <AuditLog
            isOpen={!state.flagAuditLogDialog}
            logId={state.inFocus}
            filter={filter}
            logLabel={
              row && row.employee_name ? row.employee_name : state.inFocus
            }
            onClose={() => {
              this.setState({ flagAuditLogDialog: false });
            }}
          />
        ) : null}

        {state.notifyMessageEmployeeDialogToggle && (
          <DialogNotificationMessage
            title="Notify Employee(s)"
            onClose={this.handleCloseDialogNotifyEmployee}
            onSubmit={this.handleSubmitNotifyEmployee}
          />
        )}

        {!state.notifyMessageSupervisorDialogToggle ? null : (
          <DialogNotificationMessage
            title="Notify Supervisor(s)"
            defaultValues={defaultValuesSupervisorNotify}
            onClose={this.handleCloseDialogNotifySupervisor}
            onSubmit={this.handleSubmitNotifySupervisor}
          />
        )}

        {state.showLockPayroll && (
          <Dialog handleClose={this.handleCloseDialogPayroll}>
            <DialogHeader
              title="Confirm"
              onClose={this.handleCloseDialogPayroll}
            />
            <DialogBody>
              <div>
                You are about to lock the payroll for the selected employee(s)
                for date range:
              </div>
              <div>
                {filter.dateFrom} - {filter.dateTo}
              </div>
              <HrCanTLock data={this.prepareLockMetadata()} />
            </DialogBody>
            <DialogFooter>
              <Button
                text="Cancel"
                variant="warning"
                onClick={this.handleCloseDialogPayroll}
              />
              <Button text="Confirm" onClick={this.handleApprovePayroll} />
            </DialogFooter>
          </Dialog>
        )}

        {state.showUnlock && (
          <Dialog handleClose={this.handleCloseDialogUnlockPayroll}>
            <DialogHeader
              title="Confirm"
              onClose={this.handleCloseDialogUnlockPayroll}
            />
            <DialogBody>
              <div>
                You are about to unlock the payroll for the{' '}
                {(state.unlockEmployee || {}).employee_name} for date range:
              </div>
              <div>
                {filter.dateFrom} - {filter.dateTo}
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                text="Cancel"
                variant="warning"
                onClick={this.handleCloseDialogUnlockPayroll}
              />
              <Button
                text="Confirm"
                onClick={this.handleApproveUnlockPayroll}
              />
            </DialogFooter>
          </Dialog>
        )}
      </>
    );
  }
}

export default Container.create(HRAdminTab);
