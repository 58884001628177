import { useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import DialogConfirm from 'components/modal/dialogConfirm';
import { Grid, GridControlButton } from 'components/grid';
import { PureInput } from 'components/form/textField';
import useDebounce from 'components/hook/useDebounce';

import { storeSent } from 'stores/_mobx/message/sent';
import { SENT as PAGE_ID } from 'constant/pagesId/messages';
import { URL_SENT_MAIL } from 'constant/path/messages';

const columns = [
  { name: 'recipient', title: 'Recipient' },
  { name: 'cc', title: 'CC' },
  { name: 'subject', title: 'Subject', filterable: true },
  { name: 'date', title: 'Date', className: 'width-100' },
  { name: 'time', title: 'Time', className: 'width-75' },
  {
    name: 'id',
    title: 'Action',
    render: (id: number) => (
      <div className="control">
        <Link
          to={`${URL_SENT_MAIL}/${id}/forward`}
          className="icon icon-forward"
          title="Forward"
        />
        <Link
          to={`${URL_SENT_MAIL}/${id}`}
          className="icon icon-view"
          title="View"
        />
      </div>
    ),
  },
];

interface PropsType extends RouteComponentProps {}

const SentListPage = ({ history }: PropsType) => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    sentList,
    sentTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeSent;

  const [selected, setSelected] = useState<number[]>([]);

  const [isDialogVisible, toggleDialog] = useState<boolean>(false);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const handleToggleDialog = () => {
    toggleDialog((state) => !state);
  };

  const handleApproveDelete = () => {
    storeSent.moveToTrash(selected).then((isSucceed) => {
      if (isSucceed) {
        gridRef.current.clearSelection();
        storeSent.getSentListMain();
      }
      handleToggleDialog();
    });
  };

  const handleDoubleClick = ({ id }: { id: number }) => {
    history.push(`${URL_SENT_MAIL}/${id}`);
  };

  const handleFilterChange = (value: string) => {
    storeSent.setFilter(value);
    debouncedMethod();
  };

  useEffect(() => {
    storeSent.getSentListMain();
  }, [pagination]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Sent Items" />

      <PureInput
        name="subject"
        placeholder="Subject"
        className="col-md-6 col-lg-4"
        value={filter}
        onChange={handleFilterChange}
      />

      <Grid
        id="sent_page_grid"
        selectId="id"
        onAjax={fetching}
        columns={columns}
        ref={gridRef}
        dataSource={sentList}
        dataSourceCount={sentTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
        onSelectChange={setSelected}
        onDoubleClick={handleDoubleClick}
        gridControlPanel={
          <GridControlButton
            title="Move to Trash"
            disabled={!selected.length}
            onClick={handleToggleDialog}
          />
        }
      />
      {isDialogVisible && (
        <DialogConfirm
          onCancel={handleToggleDialog}
          onApprove={handleApproveDelete}>
          {`Do you want to move ${selected.length} selected email${
            selected.length > 1 ? 's' : ''
          } to the Trash?`}
        </DialogConfirm>
      )}
    </>
  );
};

export default observer(SentListPage);
