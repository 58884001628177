import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useController, Control } from 'react-hook-form';
import { FixedSizeList as List } from 'react-window';
import clsx from 'clsx';

import { IconButton } from 'components/button';
import Cell, {
  ColumnType,
} from 'components/form/twoSideMapper/components/Cell';
import MapperTitle from 'components/form/twoSideMapper/components/Title';
import { Input } from 'components/form/textField';

import { UserType } from 'stores/_mobx/systemSetup/masterSetting/emailGroup';

const defaultValues: FilterProp = {
  title: '',
  type: '',
  facility: '',
};

interface FilterProp {
  title: string;
  type: string;
  facility: string;
}

interface PurePropType {
  titleLeft: string;
  titleRight: string;
  columnLeft: ColumnType[];
  columnRight: ColumnType[];
  className?: string;
  options: UserType[];
  value?: UserType[];
  isDirty?: boolean;
  errorMessage?: string;
  onChange?: (data: UserType[]) => void;
}

interface PropsType extends Omit<PurePropType, 'onChange' | 'value'> {
  control: Control<any>;
  name: string;
}

interface FilterListPayloadType {
  list: UserType[];
  filter: FilterProp;
}

const filterList = ({
  list,
  filter: { title, type, facility },
}: FilterListPayloadType) => {
  if (title || type || facility) {
    return list.filter((user) => {
      const isFacilityMatch = facility
        ? user.facilityName.toLowerCase().startsWith(facility.toLowerCase())
        : true;

      const isTypeMatch = type
        ? user.type.toLowerCase().startsWith(type.toLowerCase())
        : true;

      const isTitleMatch = title
        ? user.title.toLowerCase().startsWith(title)
        : true;

      return isFacilityMatch && isTypeMatch && isTitleMatch;
    });
  }

  return list;
};

const PureTwoSideMapper = React.forwardRef<HTMLDivElement, PurePropType>(
  (
    {
      isDirty = false,
      options,
      value: resultValue = [],
      columnLeft,
      columnRight,
      titleLeft,
      titleRight,
      className,
      errorMessage,
      onChange,
    },
    ref
  ) => {
    const prevDirtyStatus = useRef<boolean>(isDirty);

    const leftList = useRef<HTMLUListElement>();

    const rightList = useRef<HTMLUListElement>();

    const { control, watch } = useForm<FilterProp>({ defaultValues });

    const [leftBlockOffset, setLeftBlockOffset] = useState<number>(0);

    const [rightBlockOffset, setRightBlockOffset] = useState<number>(0);

    const [selectedLeft, setLeftSelected] = useState<UserType[]>([]);

    const [selectedRight, setRightSelected] = useState<UserType[]>([]);

    const [newValues, setNewValues] = useState<UserType[]>([]);

    const [newOptions, setNewOptions] = useState<UserType[]>([]);

    const filterOptions = watch();

    const resultOptions = useMemo(() => {
      const optionsWithoutSelected = newValues.length
        ? options.filter(
            (option) => !newValues.some(({ value }) => option.value === value)
          )
        : options;

      const combinedOptions = newOptions.length
        ? optionsWithoutSelected.concat(newOptions)
        : optionsWithoutSelected;

      return filterList({
        list: combinedOptions,
        filter: filterOptions,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, newValues, newOptions, filterOptions]);

    const handleSelectLeft = (option: UserType) => {
      const isIncluded = selectedLeft.some(
        ({ value }) => value === option.value
      );

      const entry = isIncluded
        ? selectedLeft.filter(({ value }) => value !== option.value)
        : selectedLeft.concat(option);

      setLeftSelected(entry);
    };

    const handleSelectRight = (option: UserType) => {
      const isIncluded = selectedRight.some(
        ({ value }) => value === option.value
      );

      const codes = isIncluded
        ? selectedRight.filter(({ value }) => value !== option.value)
        : selectedRight.concat(option);

      setRightSelected(codes);
    };

    const handleAddValue = () => {
      const restNewOptions = newOptions.filter(
        (option) => !selectedLeft.some(({ value }) => option.value === value)
      );

      const restNewValues = selectedLeft.filter(({ value }) =>
        options.some((option) => value === option.value)
      );

      if (onChange) onChange(resultValue.concat(selectedLeft));
      setNewValues(newValues.concat(restNewValues));
      setNewOptions(restNewOptions);
      setLeftSelected([]);
    };

    const handleRemoveValue = () => {
      const restValues = resultValue.filter(
        (option) => !selectedRight.some(({ value }) => value === option.value)
      );

      const restOptions = selectedRight.filter(
        ({ value }) => !options.some((option) => value === option.value)
      );

      const restNewValues = newValues.filter(
        ({ value }) => !selectedRight.some((option) => option.value === value)
      );

      if (onChange) onChange(restValues);
      setNewOptions(newOptions.concat(restOptions));
      setNewValues(restNewValues);
      setRightSelected([]);
    };

    useEffect(() => {
      setLeftSelected([]);
    }, [filterOptions.type, filterOptions.title, filterOptions.facility]);

    useEffect(() => {
      setRightSelected([]);
      setLeftSelected([]);
    }, [options]);

    useEffect(() => {
      const leftListElement = leftList.current.firstChild;

      function leftListListener(e: any) {
        setLeftBlockOffset(-e.target.scrollLeft);
      }

      leftListElement.addEventListener('scroll', leftListListener);

      return () => {
        leftListElement.removeEventListener('scroll', leftListListener);
      };
    }, [resultOptions]);

    useEffect(() => {
      const rightListElement = rightList.current.firstChild;

      function rightListListener(e: any) {
        setRightBlockOffset(-e.target.scrollLeft);
      }
      rightListElement.addEventListener('scroll', rightListListener);
      return () => {
        rightListElement.removeEventListener('scroll', rightListListener);
      };
    }, [resultValue]);

    useEffect(() => {
      if (!isDirty && prevDirtyStatus.current) {
        setRightSelected([]);
        setLeftSelected([]);
        setNewValues([]);
        setNewOptions([]);
      }

      prevDirtyStatus.current = isDirty;
    }, [isDirty]);

    return (
      <div className={clsx('mapper-container', className)} ref={ref}>
        <div className="mapper-side">
          <div className="row">
            <Input
              className="col-sm-4"
              name="title"
              placeholder="User Title"
              control={control}
            />
            <Input
              className="col-sm-4"
              name="type"
              placeholder="User Type"
              control={control}
            />
            <Input
              className="col-sm-4"
              name="facility"
              placeholder="Facility"
              control={control}
            />
          </div>
          <div className="mapper-legend">{`${titleLeft}: ${resultOptions.length}`}</div>
          <MapperTitle
            offsetLeft={leftBlockOffset}
            scrollable={resultOptions.length > 10}
            columns={columnLeft}
          />
          <ul className="mapper-list" ref={leftList}>
            {resultOptions.length ? (
              <List
                width="auto"
                height={310}
                itemSize={31}
                itemCount={resultOptions.length}>
                {({ index, style }) => (
                  <Cell
                    className={clsx({
                      selected: selectedLeft.some(
                        ({ value }) => value === resultOptions[index].value
                      ),
                      moved: newOptions.some(
                        ({ value }) => value === resultOptions[index].value
                      ),
                    })}
                    style={style}
                    data={resultOptions[index]}
                    columns={columnLeft}
                    onClick={handleSelectLeft}
                  />
                )}
              </List>
            ) : (
              <li className="mapper-list-empty">There is no any option.</li>
            )}
          </ul>
        </div>

        <div className="mapper-control">
          <IconButton disabled={!selectedLeft.length} onClick={handleAddValue}>
            <i className="bi bi-caret-right-fill" />
          </IconButton>
          <IconButton
            disabled={!selectedRight.length}
            onClick={handleRemoveValue}>
            <i className="bi bi-caret-left-fill" />
          </IconButton>
        </div>

        <div className="mapper-side">
          <div className="row">
            <div className="mapper-empty-filter" />
          </div>
          <div className="mapper-legend">{`${titleRight}: ${resultValue.length}`}</div>
          <MapperTitle
            offsetLeft={rightBlockOffset}
            scrollable={resultValue.length > 10}
            columns={columnRight}
          />
          <ul className="mapper-list" ref={rightList}>
            {resultValue.length ? (
              <List
                width="auto"
                height={310}
                itemSize={31}
                itemCount={resultValue.length}>
                {({ index, style }) => (
                  <Cell
                    className={clsx({
                      selected: selectedRight.some(
                        ({ value }) => value === resultValue[index].value
                      ),
                      moved: newValues.some(
                        ({ value }) => value === resultValue[index].value
                      ),
                    })}
                    style={style}
                    data={resultValue[index]}
                    columns={columnRight}
                    onClick={handleSelectRight}
                  />
                )}
              </List>
            ) : (
              <li className="mapper-list-empty">
                Please select the entry in the left Grid.
              </li>
            )}
          </ul>
        </div>
        {errorMessage && <div className="error">{errorMessage}</div>}
      </div>
    );
  }
);

const EmailGroupPureTwoSideMapper = ({ name, control, ...rest }: PropsType) => {
  const {
    field,
    fieldState: { isDirty },
    formState: { errors },
  } = useController({
    name,
    control,
  });

  return (
    <PureTwoSideMapper
      {...rest}
      {...field}
      isDirty={isDirty}
      errorMessage={errors[name]?.message as string}
    />
  );
};

export default EmailGroupPureTwoSideMapper;
