import { makeObservable, observable, action, runInAction } from 'mobx';

import Notification from 'components/modal/Notification';

import {
  apiRequest,
  errorPrettier,
  ErrorType,
  JsonLdResponseType,
} from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import AbortControllerService from 'stores/_mobx/abortControllerService';

import { GRID_ID_REGION_MASTER } from 'constant/gridsId/systemSetup';

export const initialValues: RegionType = {
  id: 0,
  region: '',
};

export interface RegionType {
  id: number;
  region: string;
}

interface OptionResponseType {
  data: string;
  label: string;
}

export interface OptionType {
  label: string;
  value: number;
}

class Region extends AbortControllerService {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  regions: RegionType[] = [];
  regionsTotal: number = 0;
  regionDetails: RegionType | null = initialValues;
  regionIdForDelete: number | null = null;
  options: OptionType[] = [];
  filter: string = '';

  page: Pagination = new Pagination({ id: GRID_ID_REGION_MASTER });

  gridId = GRID_ID_REGION_MASTER;

  constructor() {
    super();

    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      regions: observable,
      regionsTotal: observable,
      regionDetails: observable,
      regionIdForDelete: observable,
      options: observable,
      filter: observable,

      setFetching: action,
      setFetchingOptions: action,
      setRegions: action,
      setRegionTotal: action,
      setRegionDetails: action,
      setRegionForDelete: action,
      setFilter: action.bound,
      clearRegionForDelete: action.bound,
      clearRegionDetails: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setRegions({ items, totalItems, }: JsonLdResponseType<RegionType>) {
    this.regions = items;
    this.regionsTotal = totalItems;
  }

  setRegionTotal(count: number) {
    this.regionsTotal = count;
  }

  setRegionDetails(details?: RegionType) {
    this.regionDetails = details;
  }

  setRegionForDelete(id: number) {
    this.regionIdForDelete = id;
  }

  setFilter(regionName: string) {
    this.filter = regionName;
  }

  clearRegionForDelete() {
    this.regionIdForDelete = null;
  }

  clearRegionDetails() {
    this.regionDetails = initialValues;
  }

  getRegions = async () => {
    const {
      filter: region,
      page: { pagination },
    } = this;

    const data = {
      region,
      page: pagination.page,
      itemsPerPage: pagination.pageSize,
    };

    const abortSignal = this.manageAbortController();

    this.setFetching(true);

    try {
      const response = await apiRequest<JsonLdResponseType<RegionType>>({
        url: 'regions',
        method: 'GET',
        contentType: 'ld',
        legacy: false,
        signal: abortSignal,
        data,
      });

      this.setRegions(response);
    } catch (e) {
      this.setRegions({ items: [], totalItems: 0 });
    } finally {
      if (!abortSignal.aborted) {
        this.setFetching(false);

        this.clearAbortController();
      }
    }
  }

  getRegionDetails = async (id: number | string) => {
    this.setFetching(true);
    try {
      const region = await apiRequest<RegionType>({
        url: `regions/${id}`,
        method: 'GET',
        legacy: false,
      });

      this.setRegionDetails(region);
    } catch (e) {
      Notification.danger("Requested Region doesn't exist!");
      this.setRegionDetails(null);
    } finally {
      this.setFetching(false);
    }
  }

  modifyRegion = async ({ id, ...data }: RegionType) => {
    this.setFetching(true);

    const isEditRegion = Boolean(id);

    try {
      await apiRequest<RegionType>({
        url: isEditRegion ? `regions/${id}` : 'regions',
        method: isEditRegion ? 'PATCH' : 'POST',
        legacy: false,
        data: { region: data.region },
      });
      Notification.success(
        `Region was ${isEditRegion ? 'updated' : 'added'} successfully!`
      );
      return null;
    } catch (e: any) {
      const errors = errorPrettier(e as ErrorType<RegionType>);

      if (!errors) Notification.danger('An error occurred!');

      return errors;
    } finally {
      this.setFetching(false);
    }
  }

  deleteRegion = async () => {
    this.setFetching(true);
    try {
      const id = this.regionIdForDelete;

      await apiRequest({
        url: `regions/${id}`,
        method: 'DELETE',
        legacy: false,
      });

      Notification.success('Region was deleted!');

      return true;
    } catch (e: any) {
      Notification.danger(
        "This Region is already in use. You can't delete this Region!"
      );

      return false;
    } finally {
      this.setFetching(false);
      this.clearRegionForDelete();
    }
  }

  getOptions = async () => {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOptions(true);

    try {
      const response = await apiRequest<OptionResponseType[]>({
        url: 'generalmaster.MvLocation.GetLoactionByRegion',
        data: [0, 0],
      });

      const options = response.map(({ label, data }) => ({
        label,
        value: Number(data),
      }));

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }

  findOption = (id: number) => this.options.find(({ value }) => value === id);
}

export const storeRegion = new Region();
