import { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { CORPORATION_SUMMARY as PAGE_ID } from 'constant/pagesId/reports';
import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import { Button } from 'components/button';
import Export from 'components/project/ExporterNew';
import Filter from './components/Filter';

import UserProfileStore from 'stores/UserProfileStore';
import {
  storeCorporateSummary,
  FilterType,
} from 'stores/_mobx/report/corporateSummary';

const columns = [
  { name: 'facility', title: 'Facility' },
  { name: 'patients', title: 'Patients' },
  { name: 'encounters', title: 'Encounters' },
  { name: 'visits', title: 'Visits' },
  { name: 'procedures', title: 'Procedures' },
  { name: 'cancellations', title: 'Cancellations' },
  { name: 'pos', title: 'POS' },
];

const CorporateSummaryPage = () => {
  const {
    fetching,
    logList,
    logTotal,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeCorporateSummary;

  const gridRef = useRef<Grid>(null);

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExportVisible, toggleExport] = useState<boolean>(false);

  const appName = useMemo(
    () =>
      UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
      'Corporate_Summary_Report',
    []
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExport((state) => !state);
  };

  const handleApplyFilter = (filter: FilterType) => {
    storeCorporateSummary.setFilter(filter);

    setPaginationToStart();
  };

  useEffect(() => {
    storeCorporateSummary.setDefaultFilter();
  }, []);

  useEffect(() => {
    storeCorporateSummary.getCorporateSummaryLog();
  }, [pagination]);

  return (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Corporate Summary">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        isFilterVisible={isFilterVisible}
        fetching={fetching}
        onSearch={handleApplyFilter}
      />

      <Grid
        id="corporate_summary_gridId"
        noDataText="Please refine your search using the filters above"
        gridControlPanel={
          <GridControlButton
            title="Export"
            disabled={!logTotal}
            onClick={handleToggleExport}
          />
        }
        columns={columns}
        ref={gridRef}
        onAjax={fetching}
        dataSource={logList}
        dataSourceCount={logTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      {isExportVisible && (
        <Export
          exporter="CorporateSummaryExporter"
          reportName={appName}
          filter={storeCorporateSummary.prepareFilterToExport()}
          gridRef={gridRef}
          columns={columns}
          dataSource={logList}
          onClose={handleToggleExport}
        />
      )}
    </>
  );
};

export default observer(CorporateSummaryPage);
