import { Button } from 'components/button';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';

interface PropsType {
  onClose: () => void;
  onConfirm: () => void;
}

const DialogConfirmSelectedTemplate = ({ onClose, onConfirm }: PropsType) => (
  <Dialog handleClose={onClose}>
    <DialogHeader title="Confirm" onClose={onClose} />
    <DialogBody>
      You have chosen to enable the Results Available template. <br />
      You agree to having an encrypted email inbox and adhere to all HIPAA
      regulations for receiving patient information.
    </DialogBody>
    <DialogFooter>
      <Button
        text="Disagree"
        variant="warning"
        data-testid="disagree"
        onClick={onClose}
      />
      <Button text="Agree" data-testid="agree" onClick={onConfirm} />
    </DialogFooter>
  </Dialog>
);

export default DialogConfirmSelectedTemplate;
