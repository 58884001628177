export const exportHeadCells = [
  { name: 'cptCode', title: 'CPT Code' },
  { name: 'cptDescription', title: 'Description' },
  { name: 'cptPrice', title: 'Value' },
  { name: 'cptCategory.title', title: 'Category' },
  { name: 'flag', title: 'Status' },
];

export const exampleColumns = [
  { name: 'cpt_code', title: 'CPT Code' },
  { name: 'cpt_description', title: 'CPT Description' },
  { name: 'cpt_value', title: 'CPT Value' },
];

export const exampleData = [
  {
    cpt_code: '78801',
    cpt_description:
      'Radiopharmaceutical localization of tumor; tomographic(SPECT)',
    cpt_value: '$23',
  },
  {
    cpt_code: '78803',
    cpt_description:
      'Radiopharmaceutical localization of tumor; tomographic(SPECT)',
    cpt_value: '$21',
  },
];
