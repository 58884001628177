import React, { useRef } from 'react';
import clsx from 'clsx';

import { Button, IconButton } from 'components/button';

/**
  @prop {string} 'data-testid' - data attribute for testing libraries
  If data-testid not presented, then prefix for data-testid attribute will generated with 'name' property
  '{dataTestId}-label' - data attribute for the label
  '{dataTestId}-field' - data attribute for the file display block
  '{dataTestId}-error' - data attribute for the error block
*/

export interface PureFileProps {
  name: string;
  value: string | File;
  label?: string;
  accept?: string;
  required?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  className?: string;
  classNameContent?: string;
  'data-testid'?: string;
  onChange: (file: string | File) => void;
}

const PureFile = React.forwardRef<HTMLInputElement, PureFileProps>(
  (
    {
      name,
      value,
      label,
      accept = '*',
      required,
      disabled,
      onChange,
      errorMessage,
      className,
      classNameContent,
      'data-testid': dataTestId,
    }: PureFileProps,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>();

    const testAttr = dataTestId || name || 'input';

    const cn = clsx('file-wrapper formfield-holder', className, {
      required,
      disabled,
    });

    const isImg = /^image\//.test(accept);

    const imgForPreview =
      isImg && value
        ? typeof value !== 'string' && 'lastModifiedDate' in value
          ? URL.createObjectURL(value)
          : (value as string)
        : '';

    const backgroundImage = imgForPreview ? `url(${imgForPreview})` : '';

    const handleClickContent = () => {
      inputRef.current.click();
    };

    const handleFileSelect = (e: React.SyntheticEvent<HTMLInputElement>) => {
      const { files } = e.currentTarget;
      if (files?.[0]) {
        onChange(files[0]);
      }
    };

    const handleClickDelete = (e: React.SyntheticEvent) => {
      e.stopPropagation();
      onChange('');
      inputRef.current.value = null;
    };

    return (
      <div className={cn}>
        {label ? (
          <label
            htmlFor={name}
            className="form-label"
            data-testid={`${testAttr}-label`}>
            {label}
          </label>
        ) : null}
        <div
          className={clsx('file-content', classNameContent)}
          data-testid={`${testAttr}-field`}
          onClick={handleClickContent}>
          <div
            className={clsx('file-content-preview', {
              'file-text': !isImg && value,
            })}
            style={{ backgroundImage }}>
            {!value && <i className="bi bi-cloud-upload" />}
            {!isImg && value ? (
              <span className="file-name">
                {typeof value === 'object' ? value.name : value}
              </span>
            ) : (
              ''
            )}
          </div>
          {value && !disabled ? (
            <IconButton
              className="button-delete"
              disabled={disabled}
              onClick={handleClickDelete}>
              <i className="bi bi-trash" />
            </IconButton>
          ) : null}

          <Button
            className="file-button"
            text="Select file"
            disabled={disabled}
          />
        </div>
        <input
          type="file"
          className="file-input"
          name={name}
          accept={accept}
          disabled={disabled}
          ref={inputRef}
          onChange={handleFileSelect}
        />
        {errorMessage && (
          <div className="error" data-testid={`${testAttr}-error`}>
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

export default PureFile;
