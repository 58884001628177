import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid, TPagination } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import { storeGrid } from 'stores/_mobx/grid';
import { storeCptCode } from 'stores/_mobx/workflow/order/cptCode';
import { getPagination } from 'utils/getPagination';

const columns = [
  { name: 'cpt_code', title: 'CPT Code', className: 'width-100' },
  { name: 'cpt_description', title: 'CPT Description' },
  { name: 'created_dt', title: 'Created Date' },
  { name: 'status', title: 'Status', className: 'width-100 text-center' },
];

interface PropsType {
  orderId: number;
  onClose: () => void;
}

const DialogCPTLogs = ({ orderId, onClose }: PropsType) => {
  const { cptLogList, cptLogTotal, fetchingCptLog } = storeCptCode;

  const [pagination, setPagination] = useState<TPagination>(
    getPagination(storeGrid.getPageSize('cptLogGrid'))
  );

  useEffect(() => {
    storeCptCode.getCptLogListMain({ orderId, pagination });
  }, [orderId, pagination]);

  useEffect(() => storeCptCode.clearLogs, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Initial CPT Code" onClose={onClose} />
      <DialogBody>
        <Grid
          id="cptLogGrid"
          columns={columns}
          onAjax={fetchingCptLog}
          dataSource={cptLogList}
          dataSourceCount={cptLogTotal}
          pagination={pagination}
          onPaginationChange={setPagination}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogCPTLogs);
