import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import MaskInput from 'components/form/maskInput';
import { Radio } from 'components/form/radio';
import { Textarea } from 'components/form/textarea';
import { FileField } from 'components/form/file';
import resolver from './validation';

import { FormModel } from 'stores/_mobx/workflow/order/fax';
import { URL_ORDER_FAX } from 'constant/path/workflow';

const options = [
  { label: 'Plain Text', value: 'M' },
  { label: 'File', value: 'F' },
];

const defaultValues: FormModel = {
  name: '',
  fax: '',
  email: '',
  subject: '',
  mode: 'M',
  message: '',
  file: null,
};

interface PropsType {
  onSubmit: (data: FormModel) => Promise<void>;
}

const Form = ({ onSubmit }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    watch,
    reset,
    handleSubmit,
    setValue,
    trigger,
  } = useForm<FormModel>({ defaultValues, resolver });

  const mode = watch('mode');

  const handleClickReset = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      if (name === 'mode') {
        if (formValue.mode === 'F')
          setValue('message', '', { shouldDirty: true, shouldTouch: true });
        else setValue('file', null, { shouldDirty: true, shouldTouch: true });
      } else if (name === 'fax' || name === 'email') {
        const fieldName = name === 'fax' ? 'email' : 'fax';

        trigger(fieldName);
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form
      className={isSubmitting ? 'on-ajax' : ''}
      onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <Input name="name" label="Receiver name" control={control} />
          <MaskInput
            name="fax"
            label="Receiver fax"
            format="###-###-####"
            control={control}
          />
          <Input name="email" label="Receiver email" control={control} />
          <Input name="subject" label="Subject" control={control} />
        </div>
        <div className="col-md-6 col-lg-8">
          <Radio
            name="mode"
            label="Type of message"
            className="part-inline"
            options={options}
            control={control}
          />
          {mode === 'M' ? (
            <Textarea name="message" rows={9} required control={control} />
          ) : (
            <FileField
              className="col-sm-6 col-md-12 col-lg-6"
              name="file"
              accept=".pdf"
              control={control}
            />
          )}
        </div>
      </div>

      <ControlsLayout alignX="right">
        <Link to={URL_ORDER_FAX} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default Form;
