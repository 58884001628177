import { action, computed, makeObservable, observable } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import {
  PASSWORD_LENGTH_MIN,
  PASSWORD_LENGTH_MAX,
} from 'constant/PasswordParams';

export interface PasswordSettingsType {
  alpha_numeric: 'Y' | 'N';
  min_character: number;
  no_password_reuse: number;
  refid: number;
}

class PasswordStrength {
  fetching: boolean = false;
  passwordSettings?: PasswordSettingsType;

  constructor() {
    makeObservable(this, {
      fetching: observable,
      passwordSettings: observable,

      passwordOptions: computed,

      setFetching: action,
      setPasswordSettings: action,
      clearPasswordSettings: action.bound,
    });
  }

  setFetching = (status: boolean) => {
    this.fetching = status;
  };

  setPasswordSettings = (settings?: PasswordSettingsType) => {
    this.passwordSettings = settings;
  };

  clearPasswordSettings = () => {
    this.passwordSettings = undefined;
  };

  get passwordOptions() {
    return {
      minLength: this.passwordSettings?.min_character || PASSWORD_LENGTH_MIN,
      maxLength: PASSWORD_LENGTH_MAX,
      alphaNumeric: this.passwordSettings?.alpha_numeric === 'Y',
    };
  }

  async getPasswordSettings() {
    this.setFetching(true);
    try {
      const [response] = await apiRequest<[PasswordSettingsType]>({
        url: 'generalmaster.MVPasswordStrength.getPwdStrength',
      });

      const settings = {
        ...response,
        min_character: Number(response.min_character) || 0,
        no_password_reuse: Number(response.no_password_reuse) || 0,
        refid: Number(response.refid),
      };
      this.setPasswordSettings(settings);
    } catch (e) {
      this.setPasswordSettings();
    } finally {
      this.setFetching(false);
    }
  }

  async savePasswordSettings(settings: PasswordSettingsType) {
    const data = [
      settings.min_character,
      settings.alpha_numeric,
      settings.no_password_reuse,
    ];

    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.MVPasswordStrength.AddMVPwddStrength',
        data,
      });
      if (result === 'S') {
        Notification.success('Password settings were successfully saved!');
        return true;
      }
      throw Error('');
    } catch (e) {
      Notification.danger("Password settings wasn't save!");
      return false;
    }
  }
}

export const storePasswordStrength = new PasswordStrength();
