import AbstractSettings, {
  PAbstractSettings,
  SAbstractSettings,
} from './AbstractSettings';
import Form from 'components/form/Form';
import Select, { SelectOptionType } from 'components/form/input/Select';
import { Button } from 'components/button';
import ClientUserPrivilegesPageActions from 'actions/privileges/settings/ClientUserPrivilegesPageActions';
import ClientUserPrivilegesPageStore from 'stores/privileges/settings/ClientUserPrivilegesPageStore';
import UserSelectionStore from 'stores/project/UserSelectionStore';
import UserSelectionActions from 'actions/project/UserSelectionActions';
import UserTypeStore from 'stores/project/UserTypeStore';
import Notification from 'components/modal/Notification';
import { TUserTypeEnum } from 'services/project/UserSelectionService';
import { TPrivilegeItem } from '../PrivilegesTable';

import IterableUtils from 'utils/IterableUtils';
import UrlUtils from 'utils/UrlUtils';
import { getPagination } from 'utils/getPagination';

export interface PAbstractUserSettings extends PAbstractSettings {
  userType: any;
  userId: any;
  backButton: any;
}

export class SAbstractUserSettings extends SAbstractSettings {
  onAjax: boolean = false;
  userListAjax: boolean = false;
  userList: Array<any> = [];
  dataSource: Array<any> = [];
  applications: Array<any> = [];
  selected: Array<any> = [];
  pagination = getPagination();
  filter: Record<string, any> = {};
  userTypeList: Array<any> = [];
  showLogChangeModal: boolean = false;
  personSettings: Array<any> = [];
  model: any = {};
  errors: any = {};
  showSavedNotification: boolean;
}

export default abstract class AbstractUserSettings<
  P extends PAbstractUserSettings,
  S extends SAbstractUserSettings
> extends AbstractSettings<P, S> {
  abstract getUserTypeOptions(): Array<SelectOptionType>;

  static getStores() {
    return [ClientUserPrivilegesPageStore, UserTypeStore, UserSelectionStore];
  }

  static calculateState(
    prevState: SAbstractUserSettings,
    props: PAbstractUserSettings
  ) {
    if (!prevState) {
      let userType = UrlUtils.getQuery('userType') || props.userType;
      let userId = UrlUtils.getQuery('userId') || props.userId;
      if (userType && userType !== '0') {
        ClientUserPrivilegesPageActions.clearData();
        let clb = () => {
          const userTypeName: TUserTypeEnum =
            UserSelectionStore.getUserTypeName(userType);
          const isTech = UserSelectionStore.isTech(userType);
          UserSelectionActions.loadUserList(
            isTech,
            userTypeName,
            0,
            userType,
            'client-user-settings-user-list-action'
          );
        };
        if (UserSelectionStore.isUserTypesLoaded()) {
          clb();
        } else {
          UserSelectionActions.loadUserTypes().then(clb);
        }
      }
      prevState = {
        ...new SAbstractUserSettings(),
        filter: {
          userType: userType || '',
          userId: userId || '',
        },
      };
    }
    const clientUserPrivilegesPageStoreState =
      ClientUserPrivilegesPageStore.getState();
    if (
      clientUserPrivilegesPageStoreState.personSettings !==
      prevState.personSettings
    ) {
      clientUserPrivilegesPageStoreState.dataSource = IterableUtils.mergeDeep(
        [],
        clientUserPrivilegesPageStoreState.dataSource
      );
    }
    const res = Object.assign(
      {},
      prevState,
      clientUserPrivilegesPageStoreState,
      {
        userTypeList: UserTypeStore.getState().userTypeList,
      }
    );
    return res;
  }

  updateSelection(dto: any) {
    const parentMenu = this.findParent(dto);
    if (!parentMenu.checked) {
      Notification.warning(
        'Please select ' + parentMenu.name + ' permission first'
      );
      return;
    }
    for (let id in dto) {
      if (!dto.hasOwnProperty(id)) {
        continue;
      }
      let defaultPermission = this.findDefaultPermission(id);
      let value = dto[id];
      const n = parseInt(id);
      if (defaultPermission) {
        if (value) {
          //user type allowed, current user not setted
          this.state.personSettings[n] = null;
        } else {
          if (this.state.personSettings[n]) {
            //user type allowed, current user forbidden
            this.state.personSettings[n] = 'N';
          } else {
            //user type allowed, current user allowed
            this.state.personSettings[n] = 'Y';
          }
        }
      } else {
        if (value) {
          //user type forbidden, current user allowed
          this.state.personSettings[n] = 'Y';
        } else {
          //user type forbidden, current user not setted
          this.state.personSettings[n] = null;
        }
      }
    }
    super.updateSelection(dto);
  }

  _isChecked(parent: string) {
    return (
      parent === '0' || this.state.personSettings[parseInt(parent)] === 'Y'
    );
  }

  _postCheck(checked: boolean, parent: string): boolean {
    if (checked && this.state.personSettings[parseInt(parent)] === 'N') {
      return false;
    }
    return checked;
  }

  ignore(item: TPrivilegeItem) {
    return (
      this.state.personSettings[parseInt(item.refid)] === 'D' ||
      !this.state.personSettings[parseInt(item.refid)]
    );
  }

  findDefaultPermission(id: any) {
    for (let i = 0; i < this.state.dataSource.length; i++) {
      if (this.state.dataSource[i].refid === id) {
        return this.state.dataSource[i].isselect;
      }
    }
    return null;
  }

  isItemSelected(item: TPrivilegeItem) {
    return this.state.personSettings[parseInt(item.refid)] === 'Y'; //if unsetted by user, false
  }

  changeFilterCallback = (name: string, value: string) => {
    if (name === 'userType') {
      ClientUserPrivilegesPageActions.clearData();
      if (value && value !== '0') {
        this.setState({ userListAjax: true, onAjax: false }, () => {
          const userTypeName: TUserTypeEnum =
            UserSelectionStore.getUserTypeName(value);
          const isTech = UserSelectionStore.isTech(value);
          UserSelectionActions.loadUserList(
            isTech,
            userTypeName,
            0,
            Number(value),
            'client-user-settings-user-list-action'
          ).then(() => this.setState({ userListAjax: false }));
        });
      } else {
        this.setState({ userListAjax: false, onAjax: false });
      }
    } else if (name === 'userId') {
      if (value && value !== '0') {
        ClientUserPrivilegesPageActions.loadUserSpecificPermission(
          Number(value),
          this.state.filter.userType,
          false
        ).then(this.updateDataCallback);
      } else {
        ClientUserPrivilegesPageActions.clearDataSource();
        this.updateDataCallback();
      }
    }
  };

  handleChangeFilter = (name: string, value: string) => {
    const state = {
      filter: { ...this.state.filter, [name]: value },
    };

    this.setState(state, () => this.changeFilterCallback(name, value));
  };

  componentDidMount() {
    if (UserSelectionStore.isUserTypesLoaded()) {
      super.componentDidMount();
    } else {
      UserSelectionActions.loadUserTypes().then(() =>
        super.componentDidMount()
      );
    }
  }
  clearData() {
    ClientUserPrivilegesPageActions.clearData();
  }

  hasViewLogAccess() {
    return false;
  }

  hasSavePermissionsAccess() {
    return false;
  }

  getButtons() {
    const isLogAccessButtonVisible =
      this.hasViewLogAccess() && this.state.filter.userId;

    return (
      <>
        {this.hasSavePermissionsAccess() && (
          <Button text="Save" onClick={this.saveChanges.bind(this)} />
        )}
        {isLogAccessButtonVisible && (
          <Button
            variant="warning"
            text="Permission Log Change"
            onClick={() => {
              this.setState({ showLogChangeModal: true });
            }}
          />
        )}
        {this.props.backButton ? (
          <Button text="Back" onClick={this.props.backButton} />
        ) : null}
      </>
    );
  }

  callActionToSave(idDto: any, isAllowedDto: any) {
    return ClientUserPrivilegesPageActions.saveChanges(
      this.state.filter.userId,
      idDto,
      isAllowedDto
    );
  }

  updateData() {
    if (this.state.filter.userId && this.state.filter.userId !== '0') {
      ClientUserPrivilegesPageActions.loadUserSpecificPermission(
        this.state.filter.userId,
        this.state.filter.userType,
        false
      ).then(this.updateDataCallback);
    } else {
      if (this.state.onAjax) {
        this.updateDataCallback();
      }
    }
  }

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.setState({
      onAjax: false,
      selected: [],
    });

    if (grid) {
      // @ts-ignore
      grid.clearSelection();
    }
  };

  buildFormFilter() {
    let userClassName =
      !this.state.filter.userType || this.state.filter.userType === '0'
        ? 'visually-hidden'
        : 'col-sm-4';
    return (
      <Form onCollectValues={this.handleChangeFilter} model={this.state.filter}>
        <Select
          name="userType"
          label="User Type"
          className="col-sm-4"
          options={this.getUserTypeOptions()}
          onAjax={this.state.userTypeList.length === 0}
        />
        <Select
          name="userId"
          label="User"
          className={userClassName}
          options={this.state.userList}
          onAjax={this.state.userListAjax}
        />
      </Form>
    );
  }
}
