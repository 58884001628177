import SecurityPermissionService, {
  TAdminItem,
  TCompanyUserPermissions,
  TTemplateForUserType,
} from 'services/SecurityPermissionService';
import { Actions } from 'stores/SecurityPermissionStore';
import appDispatcher from 'dispatcher/AppDispatcher';

export default class SecurityPermissionActions {
  static loadSuperAdminList() {
    return SecurityPermissionService.loadSuperAdminList().then(
      (response: Array<TAdminItem>) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_SUPER_ADMIN_LIST_ACTION,
          data: response,
        });
        return response;
      }
    );
  }

  static loadCompanyUserPermissions() {
    return SecurityPermissionService.loadCompanyUserPermissions().then(
      (response: TCompanyUserPermissions) => {
        appDispatcher.dispatch({
          type: Actions.LOAD_SECURITY_PERMISSIONS_ACTION,
          data: response,
        });
        return response;
      }
    );
  }

  static updateCompanyUserPermissions(
    selectedSecurityPermissionIdsCompany: Array<number>,
    selectedSecurityPermissionIdsClient: Array<number>,
    selectedSecurityPermissionIdsClinician: Array<number>,
    selectedHrAdminIds: Array<number>
  ) {
    return SecurityPermissionService.updateCompanyUserPermissions(
      selectedSecurityPermissionIdsCompany,
      selectedSecurityPermissionIdsClient,
      selectedSecurityPermissionIdsClinician,
      selectedHrAdminIds
    );
  }

  static getUserViewPasswordPermission(userId: number) {
    return SecurityPermissionService.getUserViewPasswordPermission(userId);
  }

  static userHasHrAdminPermission(userId: number) {
    return SecurityPermissionService.userHasHrAdminPermission(userId);
  }

  static loadTemplateListForType(
    userTypeId: number
  ): Promise<Array<TTemplateForUserType>> {
    return SecurityPermissionService.loadTemplateListForType(userTypeId);
  }
}
