import { makeObservable, observable, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';

export type UserTypeEnum =
  | ''
  | 'A'
  | 'B'
  | 'B1'
  | 'B2'
  | 'BA'
  | 'C'
  | 'CA'
  | 'CC'
  | 'D'
  | 'FC'
  | 'IC'
  | 'L'
  | 'N'
  | 'P'
  | 'PA'
  | 'R'
  | 'RA'
  | 'RC'
  | 'S'
  | 'SM'
  | 'SP'
  | 'SU'
  | 'T'
  | 'U';

interface OptionResponse {
  category: string;
  refid: string;
  type: '1' | '0';
  usertype: UserTypeEnum;
  usertype_name: string;
}

interface OptionsType {
  value: number;
  label: string;
  type: 1 | 0;
}

interface OptionRawType extends Omit<OptionResponse, 'refid' | 'type'> {
  id: number;
  type: number;
}

export class DropdownUserType {
  fetchingOptionsUserType: boolean = false;
  optionsRaw: OptionRawType[] = [];
  optionsUserType: OptionsType[] = [];
  optionsClientUserType: OptionsType[] = [];
  optionsCompanyUserType: OptionsType[] = [];

  constructor() {
    makeObservable(this, {
      fetchingOptionsUserType: observable,
      optionsRaw: observable,
      optionsUserType: observable,
      optionsClientUserType: observable,
      optionsCompanyUserType: observable,
    });
  }

  async getOptionsUserType() {
    if (this.fetchingOptionsUserType) return Promise.resolve();
    try {
      runInAction(() => {
        this.fetchingOptionsUserType = true;
      });
      const list = await apiRequest<OptionResponse[]>({
        url: 'generalmaster.Mv_UserType.loadUserTypeByType',
      });

      const optionRawList = list.map(({ refid, ...rest }) => ({
        ...rest,
        id: Number(refid),
        type: Number(rest.type),
      }));

      const optionsUserType = list.map(({ refid, usertype_name, type }) => ({
        label: usertype_name,
        value: Number(refid),
        type: Number(type) as 0 | 1,
      }));

      const company = optionsUserType.filter(({ type }) => type === 0);

      const client = optionsUserType.filter(({ type }) => type === 1);

      runInAction(() => {
        this.fetchingOptionsUserType = false;
        this.optionsRaw = optionRawList;
        this.optionsUserType = optionsUserType;
        this.optionsClientUserType = client;
        this.optionsCompanyUserType = company;
      });
    } catch {
      runInAction(() => {
        this.fetchingOptionsUserType = false;
        this.optionsRaw = [];
        this.optionsUserType = [];
        this.optionsClientUserType = [];
        this.optionsCompanyUserType = [];
      });
    }
  }
}

export const storeDropdownUserType = new DropdownUserType();
