import React from 'react';
import { Container } from 'flux/utils';

import { Button } from 'components/button';
import Notification from 'components/modal/Notification';
import { IconButton } from 'components/button';
import Form from 'components/form/Form';
import Fieldset from 'components/form/Fieldset';
import Text from 'components/form/input/Text';
import Select from 'components/form/input/Select';
import Checkbox from 'components/form/input/Checkbox';
import NumberInput from 'components/form/input/NumberInput';
import TextCalendar from 'components/form/input/TextCalendar';
import Custom from 'components/form/input/Custom';
import TextArea from 'components/form/input/TextArea';
import { Grid } from 'components/grid';
import StateDropdown from 'components/project/dropdown/StateDropdown';
import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'components/modal/dialog';
import DialogConfirm from 'components/modal/dialogConfirm';

import EligibilityManagerPageActions from 'actions/billing-coding/EligibilityManagerPageActions';
import EligibilityManagerPageStore from 'stores/billing-coding/EligibilityManagerPageStore';
import Insurance from './Insurance';
import RadioOptions from 'components/project/common/RadioOptions';
import OrganizationActions, {
  TInsurancePlan,
  TPatientInsuranceInfo,
} from 'actions/project/OrganizationActions';
import OrganizationStore from 'stores/project/OrganizationStore';
import {
  TCompletedInsuranceInfo,
  TArchivePayerInfo,
} from 'services/billing-coding/EligibilityManagerPageService';
import { BASE_URL_FILE_DIR } from 'constant/config';

const commonClassName = 'col-sm-4';

const validatePlan = (props: {
  insurancePlan: TInsurancePlan | undefined;
  isPrimaryInsurance: boolean;
}) => {
  const { insurancePlan, isPrimaryInsurance } = props;

  const isPayerSelected = Boolean(insurancePlan?.payorId);

  const insuranceErrors = {
    insuranceNo:
      isPayerSelected && !insurancePlan?.insuranceNo ? ["Can't be empty"] : [],
    insStT: isPayerSelected && !insurancePlan?.insStT ? ["Can't be empty"] : [],
    planId:
      isPrimaryInsurance && isPayerSelected && !Number(insurancePlan?.planId)
        ? ["Can't be empty"]
        : [],
  };
  const hasError = Object.values(insuranceErrors).some((arr) =>
    Boolean(arr.length)
  );

  return { insuranceErrors, hasError };
};

interface OptionType {
  value: number;
  label: string;
}

export class TOrder4EligibilityPopup {
  refid: number = null;
  facilityid: number = null;
  patientlast?: string = '';
  patientfist?: string = '';
  patientdob?: string = '';
  last_name: string = '';
  first_name: string = '';
  dob: string = '';
  ssn: string = '';
  dos: string = '';
  iceNumber: string = '';
  inmateNumber: string = '';
  immigrationNumber: string = '';
  state: string = '';
  facilitynm: string = '';
  phone: string = '';
  medicare_id: string = '';
  medicaid_id: string = '';
  medicaid_stateid: string = '';
  ins_notes: string = '';
  patientid: number = null;
  correctionalFacility: any = null;
  face_sheet: string = null;
  clientMRN: string = '';
}

interface PEligibilityPopup {
  order: TOrder4EligibilityPopup;
  eligibility: 'I' | 'C' | 'ONLY_SAVE' | 'P' | string;
  flagOrderEntryPayerInfo?: boolean;
  callback: (response: boolean, model01?: TEligibilityModel) => void;
}

export class TEligibilityModel {
  lastName: string = '';
  firstName: string = '';
  dob: string = '';
  ssn: string = '';
  dos: string = '';
  iceNumber: string = '';
  inmateNumber: string = '';
  immigrationNumber: string = '';
  state: string = '';
  facility: string = '';
  facilityPhone: string = '';
  medicareId: string = '';
  medicaidId: string = '';
  coveredUnder: string = '';
  primaryInsurance: any = {};
  secondaryInsurance: any = {};
  tertiaryInsurance: any = {};
  payers: Array<any> = [];
  guar_g_relation: string = '';
  guar_g_lnm: string = '';
  guar_g_fnm: string = '';
  guar_g_address1: string = '';
  guar_g_address2: string = '';
  guar_g_city: string = '';
  guar_g_state: string = '';
  guar_g_zip: string = '';
  ins_notes: string = '';
  insuranceLoaded: boolean = false;
  mrn: string = '';
}

export class SEligibilityPopup {
  insuranceLoaded: boolean = false;
  errors: any = {};
  model: TEligibilityModel = new TEligibilityModel();
  facilityValuesLoaded: boolean = false;
  facilityValues: any = {};
  insuranceErrors: any = {};
  payerToArchive: any = null;
  confirmInsuranceChange: any = null;
  sameAsFacility: boolean = false;
  showDeletePayor: boolean = false;
  showPayerWarning: boolean = false;
  patientInsurance: TPatientInsuranceInfo = new TPatientInsuranceInfo();
  errorMessages: any = {};
  payorToDelete: number = 0;
}

const insuranceStatusMessage = {
  I: 'Insurance details has been saved and moved to Incomplete status',
  C: 'Insurance details has been saved and moved to complete status',
  W: '',
};
export class EligibilityPopup extends React.Component<
  PEligibilityPopup,
  SEligibilityPopup
> {
  cacheGuarantorName = {
    firstName: '',
    lastName: '',
  };

  static getStores() {
    return [EligibilityManagerPageStore, OrganizationStore];
  }

  static calculateState(
    prevState: SEligibilityPopup,
    props: PEligibilityPopup
  ) {
    if (!prevState) {
      const { order } = props;
      prevState = Object.assign(new SEligibilityPopup(), {
        model: Object.assign(new TEligibilityModel(), {
          lastName: order.patientlast || order.last_name,
          // todo maybe second condition isn't needed
          firstName: order.patientfist || order.first_name,
          // @ts-ignore
          dob: order.patientdob || order.dob || order.birthdate,
          ssn: order.ssn,
          dos: order.dos || 'Not set',
          iceNumber: order.iceNumber,
          inmateNumber: order.inmateNumber,
          immigrationNumber: order.immigrationNumber,
          state: order.state,
          facility: order.facilitynm,
          // @ts-ignore
          facilityPhone: order.phone || order.facilityphone,
          medicareId: order.medicare_id,
          medicaidId: order.medicaid_id,
          coveredUnder: order.medicaid_stateid,
          primaryInsurance: {},
          secondaryInsurance: {},
          tertiaryInsurance: {},
          payers: [],
          guar_g_relation: '',
          guar_g_lnm: '',
          guar_g_fnm: '',
          guar_g_address1: '',
          guar_g_address2: '',
          guar_g_city: '',
          guar_g_state: '',
          guar_g_zip: '',
          ins_notes: order.ins_notes,
          insuranceLoaded: false,
          mrn: order.clientMRN,
        }),
        facilityValuesLoaded: false,
        facilityValues: {},
        insuranceErrors: {},
        payerToArchive: null,
        confirmInsuranceChange: null,
        sameAsFacility: false,
        showDeletePayor: false,
        showPayerWarning: false,
      });

      if (order.patientid) {
        OrganizationActions.getPatientInsuranceInfo(
          order.patientid,
          props.flagOrderEntryPayerInfo
        );
      }
    }
    const organization = OrganizationStore.getState();
    const storeState: SEligibilityPopup = Object.assign({
      insuranceLoaded: organization.insuranceLoaded,
    });
    if (!prevState.insuranceLoaded && organization.insuranceLoaded) {
      storeState.patientInsurance = organization.patientInsurance;
      const guarantor: TPatientInsuranceInfo =
        organization.patientInsurance || new TPatientInsuranceInfo();

      const isSelfRelationship = guarantor.guar_g_relation === 'SE';

      const lastName = props.order.patientlast || props.order.last_name;

      const firstName = props.order.patientfist || props.order.first_name;

      const model = {
        ...prevState.model,
        guar_g_relation: guarantor.guar_g_relation,
        guar_g_lnm: isSelfRelationship ? lastName : guarantor.guar_g_lnm,
        guar_g_fnm: isSelfRelationship ? firstName : guarantor.guar_g_fnm,
        guar_g_address1: guarantor.guar_g_address1,
        guar_g_address2: guarantor.guar_g_address2,
        guar_g_city: guarantor.guar_g_city,
        guar_g_state: guarantor.guar_g_state,
        guar_g_zip: guarantor.guar_g_zip,
      };
      storeState.model = model;
    } else {
      storeState.patientInsurance = prevState.patientInsurance;
    }
    return Object.assign({}, prevState, storeState);
  }

  componentWillUnmount() {
    OrganizationActions.clearPatientInsuranceInfo();
    EligibilityManagerPageActions.clearGuarantor();
  }

  getOrderEntryPayerInfoColumns() {
    return [
      { name: 'payerName', title: 'Payer' },
      { name: 'payerId', title: 'ID Number' },
      { name: 'EffectDate', title: 'Effective Dates' },
      {
        name: 'control',
        title: '',
        render: (v: any, data: { refid: number }) => (
          <div className="d-flex gap-3">
            {this.buildOrderEntryPayorLink('P', 'Primary', data)}
            {this.buildOrderEntryPayorLink('S', 'Secondary', data)}
            {this.buildOrderEntryPayorLink('T', 'Tertiary', data)}
          </div>
        ),
      },
      {
        name: 'refid',
        title: 'Delete',
        render: (id: number) => (
          <IconButton
            title="Delete"
            className="text-primary"
            onClick={() => {
              this.setState({
                payorToDelete: id,
                showDeletePayor: true,
              });
            }}>
            <i className="bi bi-trash" />
          </IconButton>
        ),
      },
    ];
  }

  buildOrderEntryPayorLink(
    flag: 'P' | 'S' | 'T',
    title: string,
    data: { refid: number }
  ) {
    return (
      <a
        href="/"
        className="btn btn-primary"
        title={title}
        onClick={(event) => {
          event.preventDefault();
          EligibilityManagerPageActions.getInitialPayerInfo(
            data.refid,
            this.props.order.refid,
            this.props.flagOrderEntryPayerInfo
          ).then((response: Array<TArchivePayerInfo>) => {
            if (!(response && response[0])) {
              Notification.warning('Error occurred');
              return;
            }
            const responseItem: TArchivePayerInfo = response[0];
            this.updatePlan(flag, responseItem, 0);
          });
        }}>
        {flag}
      </a>
    );
  }

  getArchivePayerColumns() {
    return [
      { name: 'payerName', title: 'Payer' },
      { name: 'payerId', title: 'ID Number' },
      { name: 'EffectDate', title: 'Effective Dates' },
      {
        name: 'refid',
        title: 'Control',
        render: (id: number) => (
          <div className="d-flex gap-3">
            {this.buildPayorLink('P', 'Primary', id)}
            {this.buildPayorLink('S', 'Secondary', id)}
            {this.buildPayorLink('T', 'Tertiary', id)}
          </div>
        ),
      },
      {
        name: 'refid',
        title: 'Delete',
        render: (id: number) => (
          <IconButton
            title="Delete"
            className="text-primary"
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                payorToDelete: id,
                showDeletePayor: true,
              });
            }}>
            <i className="bi bi-trash" />
          </IconButton>
        ),
      },
    ];
  }

  buildPayorLink(flag: 'P' | 'S' | 'T', title: string, refid: number) {
    return (
      <a
        href="/"
        className="btn btn-primary"
        title={title}
        onClick={(event) => {
          event.preventDefault();
          EligibilityManagerPageActions.getArchivePayerInfo(
            refid,
            this.props.order.refid
          ).then((response: Array<TArchivePayerInfo>) => {
            if (!(response && response[0])) {
              Notification.warning('Error occurred');
              return;
            }
            const responseItem: TArchivePayerInfo = response[0];
            this.updatePlan(flag, responseItem, refid);
          });
        }}>
        {flag}
      </a>
    );
  }

  updatePlan(
    flag: 'P' | 'S' | 'T',
    responseItem: TArchivePayerInfo,
    refid: number
  ) {
    const patientInsurance: TPatientInsuranceInfo = Object.assign(
      new TPatientInsuranceInfo(),
      this.state.patientInsurance
    );
    responseItem.plan_names.forEach((v) => {
      v.value = Number(v.data) || 0;
    });
    const plan: TInsurancePlan = new TInsurancePlan();
    plan.payorId = Number(responseItem.payor_id) || 0;
    plan.insuranceNo = responseItem.insurance_no;
    plan.insStT = responseItem.start_dt;
    plan.planId = Number(responseItem.plan_id) || 0;
    plan.groupNo = responseItem.groupno;
    plan.insEndT = '';
    plan.msp = Number(responseItem.msp) || 0;
    plan.insPatRelation = responseItem.patient_relationship;
    plan.payorType = flag;
    plan.plan = responseItem.plan_names;
    plan.refid = refid;
    if ('P' === flag) {
      patientInsurance.primaryPlan = plan;
    } else if ('S' === flag) {
      patientInsurance.secondaryPlan = plan;
    } else if ('T' === flag) {
      patientInsurance.tertiaryPlan = plan;
    }
    Notification.success(
      'Insurance data was recovered. Submit form to apply changes.'
    );
    this.setState({ patientInsurance });
  }

  onUpdateModel = (
    name?: string,
    value?: string,
    errorName?: string,
    errorKeys?: any,
    event?: Event,
    clb?: () => void
  ) => {
    const errors = { ...this.state.errors, [name]: errorName };

    if (name === 'guar_g_relation') {
      const isSelfRelationship = value === 'SE';

      this.cacheGuarantorName = isSelfRelationship
        ? {
            firstName: this.state.model.guar_g_fnm,
            lastName: this.state.model.guar_g_lnm,
          }
        : {
            firstName: OrganizationStore.getState().patientInsurance.guar_g_fnm,
            lastName: OrganizationStore.getState().patientInsurance.guar_g_lnm,
          };

      const model = {
        ...this.state.model,
        [name]: value,
        guar_g_lnm: isSelfRelationship
          ? this.state.model.lastName
          : this.cacheGuarantorName.lastName,
        guar_g_fnm: isSelfRelationship
          ? this.state.model.firstName
          : this.cacheGuarantorName.firstName,
      };

      this.setState({ model, errors });
    } else if (
      this.state.model.guar_g_relation === 'SE' &&
      (name === 'firstName' || name === 'lastName')
    ) {
      const model = {
        ...this.state.model,
        [name]: value,
        guar_g_lnm: name === 'lastName' ? value : this.state.model.lastName,
        guar_g_fnm: name === 'firstName' ? value : this.state.model.firstName,
      };
      this.setState({ model, errors });
    } else {
      const model = {
        ...this.state.model,
        [name]: value,
      };
      this.setState({ model, errors }, clb);
    }
  };

  validation = () => {
    const { insuranceErrors, patientInsurance } = this.state;

    const primary = validatePlan({
      insurancePlan: patientInsurance.primaryPlan,
      isPrimaryInsurance: true,
    });
    const secondary = validatePlan({
      insurancePlan: patientInsurance.secondaryPlan,
      isPrimaryInsurance: false,
    });
    const tertiary = validatePlan({
      insurancePlan: patientInsurance.tertiaryPlan,
      isPrimaryInsurance: false,
    });

    const err = {
      ...insuranceErrors,
      primaryPlan: primary.insuranceErrors,
      secondaryPlan: secondary.insuranceErrors,
      tertiaryPlan: tertiary.insuranceErrors,
    };

    if (patientInsurance.secondaryPlan?.planId) {
      const { planId, plan, msp } = patientInsurance.secondaryPlan;

      const planLabel =
        plan.find((option) => option.value === planId)?.label || '';

      const isMSPFieldRequired = planLabel.toLowerCase().startsWith('medicare');

      const isMSPSelected = isMSPFieldRequired ? Boolean(msp) : false;

      err.secondaryPlan = {
        ...err.secondaryPlan,
        msp: isMSPFieldRequired && !isMSPSelected ? ["Can't be empty"] : [],
      };

      secondary.hasError = isMSPFieldRequired
        ? secondary.hasError || !isMSPSelected
        : secondary.hasError;
    }

    if (primary.hasError || secondary.hasError || tertiary.hasError) {
      this.setState({ insuranceErrors: err });
      Notification.warning('Please, fill in all required fields!');
      return false;
    } else {
      return true;
    }
  };

  handleSubmit = () => {
    const isValid = this.validation();

    if (isValid) this.submitSuccess();
  };

  buildDeletePayorLink({ refid }: any) {
    return (
      <a
        href="/"
        className="btn btn-danger"
        title="Delete"
        onClick={(e) => {
          e.preventDefault();
          this.setState({
            payorToDelete: refid,
            showDeletePayor: true,
          });
        }}>
        D
      </a>
    );
  }

  findMsp() {
    const patientInsurance = this.state.patientInsurance;
    return patientInsurance.secondaryPlan?.msp || 0;
  }

  handleCloseDialog = () => {
    this.props.callback(false);
  };

  handleClickSaveWithSpecificStatus = (status: 'C' | 'I') => () => {
    const isFormValid = this.validation();

    if (isFormValid) {
      const { callback } = this.props;

      const { model } = this.state;

      this.updateCompleteInsuranceInfo(status).then(() =>
        callback(true, model)
      );
    }
  };

  updateCompleteInsuranceInfo(status: 'W' | 'I' | 'C' | '') {
    const { state, props } = this;
    const { order } = props;
    const orderId = order.refid;
    const { model } = state;
    return EligibilityManagerPageActions.updateCompletedInsuranceInfo(
      this.flatData(),
      order.patientid,
      [orderId],
      status,
      {
        ice_number: model.iceNumber,
        immigration_id: model.immigrationNumber,
        orderid: orderId,
        inmate_number: model.inmateNumber,
      },
      this.state.model.ins_notes,
      this.findMsp(),
      props.flagOrderEntryPayerInfo
    ).then((r: 'SE' | 'S' | 'E') => {
      const out = r === 'S';
      if (out) {
        const message =
          (status && insuranceStatusMessage[status]) ||
          'Insurance details has been updated successfully';
        Notification.success(message);
        return true;
      } else if (r === 'E') {
        Notification.warning('Patient Detail Exists');
        return false;
      } else {
        Notification.warning("Can't update insurance information.");
        return false;
      }
    });
  }

  updatePlanOptions =
    (insuranceType: 'P' | 'S' | 'T') => (plan: OptionType[]) => {
      const { patientInsurance } = this.state;

      const key =
        insuranceType === 'P'
          ? 'primaryPlan'
          : insuranceType === 'S'
          ? 'secondaryPlan'
          : 'tertiaryPlan';

      const updatedPatientInsurance = {
        ...patientInsurance,
        [key]: {
          ...patientInsurance[key],
          plan,
        },
      };

      this.setState({ patientInsurance: updatedPatientInsurance });
    };

  getFormControl() {
    const { props } = this;

    const showCompleteButton =
      props.eligibility !== 'C' && props.eligibility !== 'ONLY_SAVE';

    const showIncompleteButton =
      showCompleteButton && props.eligibility !== 'I';

    return (
      <>
        <Button text="Save" variant="warning" onClick={this.handleSubmit} />
        {showIncompleteButton && (
          <Button
            text="Incomplete"
            onClick={this.handleClickSaveWithSpecificStatus('I')}
          />
        )}
        {showCompleteButton && (
          <Button
            text="Complete"
            onClick={this.handleClickSaveWithSpecificStatus('C')}
          />
        )}
        <Button text="Cancel" onClick={this.handleCloseDialog} />
      </>
    );
  }

  render() {
    const { state, props } = this;
    const patientInsurance = state.patientInsurance;
    const insuranceErrors = state.insuranceErrors || {};
    const { correctionalFacility: isCorrectional, refid } = this.props.order;

    return (
      <>
        <Dialog size="extraLarge" handleClose={this.handleCloseDialog}>
          <DialogHeader
            title="Insurance Detail"
            onClose={this.handleCloseDialog}
          />
          <DialogBody>
            <Form
              ref="form"
              model={state.model}
              errors={state.errors}
              className="row"
              errorMessages={state.errorMessages}
              onCollectValues={this.onUpdateModel.bind(this)}
              submit={this.handleSubmit}>
              <Text name="lastName" className={commonClassName} />
              <Text name="firstName" className={commonClassName} />
              <TextCalendar
                name="dob"
                label="DOB"
                className={commonClassName}
              />
              <div />
              <NumberInput
                format="###-##-####"
                name="ssn"
                label="SSN"
                className={commonClassName}
              />
              <Custom
                name="dos"
                label="DOS"
                custom={state.model.dos || 'Not set'}
                className={commonClassName}
              />
              <Custom
                name="state"
                custom={state.model.state || 'Not set'}
                className={commonClassName}
              />
              <div />
              <Custom
                name="facility"
                custom={state.model.facility || 'Not set'}
                className={commonClassName}
              />
              <Custom
                name="facilityPhone"
                custom={state.model.facilityPhone || 'Not set'}
                className={commonClassName}
              />
              <Custom
                name="Other MRN"
                custom={state.model.mrn || 'Not set'}
                className={commonClassName}
              />
              <div />
              {!!isCorrectional && (
                <Text
                  name="iceNumber"
                  className={commonClassName}
                  label="ICE Number"
                />
              )}
              {!!isCorrectional && (
                <Text
                  name="inmateNumber"
                  className={commonClassName}
                  label="OID Number"
                />
              )}
              {!!isCorrectional && (
                <Text
                  name="immigrationNumber"
                  className={commonClassName}
                  label="Marshall ID"
                />
              )}
              <hr />
              <Text
                name="medicareId"
                className={commonClassName}
                label="Medicare ID"
              />
              <Text
                name="medicaidId"
                className={commonClassName}
                label="Medicaid ID"
              />
              <StateDropdown name="coveredUnder" className={commonClassName} />
              <div />
              <Custom
                name="faceSheet"
                label="Face Sheet"
                custom={
                  !props.order.face_sheet ? (
                    'Not available'
                  ) : (
                    <a
                      href={`${BASE_URL_FILE_DIR}doc_img/externaldoc/${props.order.face_sheet}`}
                      className="fw-bold"
                      target="_blank"
                      rel="noreferrer">
                      View
                    </a>
                  )
                }
                className={commonClassName}
              />
              {refid ? (
                <TextArea name="ins_notes" label="Notes" className="col-sm-8" />
              ) : null}

              {!props.flagOrderEntryPayerInfo ? null : (
                <>
                  <h3>Order Entry Payer Information</h3>
                  <Grid
                    stateless
                    disablePagination
                    columns={this.getOrderEntryPayerInfoColumns()}
                    dataSource={patientInsurance.initialPayer || []}
                    dataSourceCount={
                      (patientInsurance.initialPayer || []).length
                    }
                  />
                </>
              )}
              <Insurance
                title="Primary Insurance"
                className="row"
                isPrimaryInsurance
                formModel={patientInsurance.primaryPlan || {}}
                formErrors={insuranceErrors.primaryPlan || {}}
                archivePayer={() => this.archivePayer('P')}
                onPlanOptionUpdate={this.updatePlanOptions('P')}
                onSetValue={(n, v, e) =>
                  this.updateInsuranceModel(n, v, e, 'P')
                }
              />
              <hr />
              <Insurance
                title="Secondary Insurance"
                className="row"
                withMSPField
                formModel={patientInsurance.secondaryPlan || {}}
                formErrors={insuranceErrors.secondaryPlan || {}}
                archivePayer={() => this.archivePayer('S')}
                onPlanOptionUpdate={this.updatePlanOptions('S')}
                onSetValue={(n, v, e) =>
                  this.updateInsuranceModel(n, v, e, 'S')
                }
              />
              <hr />

              <Insurance
                title="Tertiary Insurance"
                className="row"
                formModel={patientInsurance.tertiaryPlan || {}}
                formErrors={insuranceErrors.tertiaryPlan || {}}
                archivePayer={() => this.archivePayer('T')}
                onPlanOptionUpdate={this.updatePlanOptions('T')}
                onSetValue={(n, v, e) =>
                  this.updateInsuranceModel(n, v, e, 'T')
                }
              />
              <hr />
              <h3>Archived Payers</h3>
              <Grid
                stateless
                disablePagination
                columns={this.getArchivePayerColumns()}
                dataSource={patientInsurance.archivePayer || []}
                dataSourceCount={(patientInsurance.archivePayer || []).length}
              />

              <Fieldset className="row">
                <h3>Guarantor</h3>
                <Checkbox
                  name="sameAsFacility"
                  onSetValue={(n, v) => this.onSameAsFacility(v)}
                  value={state.sameAsFacility ? 1 : 0}
                />
                <Select
                  label="Relationship"
                  name="guar_g_relation"
                  options={RadioOptions.relationship}
                  className={commonClassName}
                />
                <Text
                  label="Last Name"
                  name="guar_g_lnm"
                  className={commonClassName}
                  attr={{ disabled: state.model.guar_g_relation === 'SE' }}
                />
                <Text
                  label="First Name"
                  name="guar_g_fnm"
                  className={commonClassName}
                  attr={{ disabled: state.model.guar_g_relation === 'SE' }}
                />
                <Text
                  label="Address1"
                  name="guar_g_address1"
                  className={commonClassName}
                />
                <Text
                  label="Address2"
                  name="guar_g_address2"
                  className={commonClassName}
                />

                <Text
                  label="City"
                  name="guar_g_city"
                  className={commonClassName}
                />
                <Fieldset className={commonClassName}>
                  <Text label="State" name="guar_g_state" maxLength={2} />
                  <small>* State could contain 2 symbols</small>
                </Fieldset>
                <NumberInput
                  format="#####-####"
                  label="Zip Code"
                  name="guar_g_zip"
                  className={commonClassName}
                />
              </Fieldset>
            </Form>
          </DialogBody>
          <DialogFooter>{this.getFormControl()}</DialogFooter>
        </Dialog>
        {state.showDeletePayor && (
          <DialogConfirm
            onCancel={this.handleCloseDialogDeletePayor}
            onApprove={this.deletePayor}>
            Are you sure to delete?
          </DialogConfirm>
        )}

        {state.confirmInsuranceChange && (
          <DialogConfirm
            onCancel={this.handleCloseDialogProcessInsurance}
            onApprove={this.processInsuranceChange}>
            Are you sure to move insurance? Old data will be lost.
          </DialogConfirm>
        )}
        {state.showPayerWarning && (
          <DialogConfirm
            onCancel={this.handleCloseDialogConfirmEmptyDate}
            onApprove={this.handleApproveConfirmEmptyDate}>
            Termination date is not specified. Do you want to proceed?
          </DialogConfirm>
        )}
      </>
    );
  }

  moveInsurance(event: any, from: string, to: string) {
    if (event) {
      event.preventDefault();
    }
    const patientInsurance: any = this.state.patientInsurance;
    const insuranceFrom = patientInsurance[from];
    if (this.isEmptyInsurance(insuranceFrom)) {
      return;
    }
    const confirmInsuranceChange = {
      from,
      to,
      object: insuranceFrom,
    };
    if (!this.isEmptyInsurance(patientInsurance[to])) {
      this.setState({ confirmInsuranceChange });
    } else {
      this.processInsuranceChange(confirmInsuranceChange);
    }
  }

  handleCloseDialogConfirmEmptyDate = () => {
    this.setState({ showPayerWarning: false });
  };

  handleApproveConfirmEmptyDate = () => {
    this.handleCloseDialogConfirmEmptyDate();
    this.processArchive();
  };

  handleCloseDialogProcessInsurance = () => {
    this.setState({ confirmInsuranceChange: false });
  };

  processInsuranceChange(
    confirmInsuranceChange: { from: string; to: string; object: any } = this
      .state.confirmInsuranceChange
  ) {
    const state = {
      confirmInsuranceChange,
      patientInsurance: {
        ...this.state.patientInsurance,
        [confirmInsuranceChange.from]: {},
        [confirmInsuranceChange.to]: confirmInsuranceChange.object,
      },
    };

    this.setState(state);
  }

  handleCloseDialogDeletePayor = () => {
    this.setState({ showDeletePayor: false });
  };

  deletePayor = () => {
    EligibilityManagerPageActions.deletePayor(this.state.payorToDelete).then(
      () => {
        Notification.success('Information deleted');
        this.updateArchivedPayors();
      }
    );
    this.handleCloseDialogDeletePayor();
  };

  updateArchivedPayors() {
    const { props } = this;
    OrganizationActions.updateArchivedPayers(
      props.order.patientid,
      props.flagOrderEntryPayerInfo
    ).then((response) => {
      const patientInsurance = Object.assign({}, this.state.patientInsurance);
      patientInsurance.archivePayer = response.archivePayer;
      patientInsurance.initialPayer = response.initialPayer;
      this.setState({ patientInsurance });
    });
  }

  flatData(): TCompletedInsuranceInfo {
    const { state, props } = this;
    const patientInsurance = state.patientInsurance;
    const primary = patientInsurance.primaryPlan || new TInsurancePlan();
    const secondary = patientInsurance.secondaryPlan || new TInsurancePlan();
    const tet = patientInsurance.tertiaryPlan || new TInsurancePlan();
    const { model } = state;
    return {
      dob: model.dob,
      medicaid_id: model.medicaidId,
      medicaid_state: '' + model.coveredUnder,
      ssn: model.ssn,

      pripayor_id: primary.payorId,
      pri_insureid: primary.insuranceNo,
      pri_payerType: primary.payorType,
      pri_refid: primary.refid,
      sec_refid: secondary.refid,
      ter_refid: tet.refid,
      lastname: model.lastName,
      medicare_id: model.medicareId,
      guar_relationship: model.guar_g_relation,
      pri_groupno: primary.groupNo,
      pri_termdt: primary.insEndT,
      priplan_id: primary.planId,
      pri_effdt: primary.insStT,
      prirelationship: primary.insPatRelation,

      sec_insureid: secondary.insuranceNo,
      pri_payer_type: primary.payer_type,
      sec_payer_type: secondary.payer_type,
      ter_payer_type: tet.payer_type,
      sec_groupno: secondary.groupNo,
      sec_termdt: secondary.insEndT,
      secrelationship: secondary.insPatRelation,
      sec_payerType: secondary.payorType,
      sec_effdt: secondary.insStT,
      secplan_id: secondary.planId,
      sec_msp: secondary.msp,

      firstname: model.firstName,
      ter_groupno: tet.groupNo,
      terpayor_id: tet.payorId,
      terrelationship: tet.insPatRelation,
      ter_payerType: tet.payorType,
      terplan_id: tet.planId,
      ter_termdt: tet.insEndT,
      ter_effdt: tet.insStT,
      ter_insureid: tet.insuranceNo,

      guar_firstnm: model.guar_g_fnm,
      guar_state: model.guar_g_state,
      guar_lastnm: model.guar_g_lnm,
      orderID: props.order.refid,
      secpayor_id: secondary.payorId,
      guar_addr: model.guar_g_address1,
      guar_zip: model.guar_g_zip,
      guar_addr2: model.guar_g_address2,
      guar_city: model.guar_g_city,
    };
  }

  archivePayer(payorType: 'P' | 'S' | 'T') {
    const { state } = this;
    const patientInsurance = state.patientInsurance;
    const payer =
      payorType === 'P'
        ? patientInsurance.primaryPlan
        : payorType === 'S'
        ? patientInsurance.secondaryPlan
        : payorType === 'T'
        ? patientInsurance.tertiaryPlan
        : null;
    if (payer) {
      if (!payer.insEndT) {
        this.setState({ showPayerWarning: true, payerToArchive: payer });
      } else {
        this.setState({ payerToArchive: payer }, () =>
          this.processArchive(payorType)
        );
      }
    } else {
      Notification.warning('Nothing to archive');
    }
  }

  processArchive(payorType?: 'P' | 'S' | 'T') {
    const payer = this.state.payerToArchive;
    if (payer) {
      const { props } = this;
      const { order } = props;
      const refid = order.patientid;
      payorType = payorType || payer.payorType;
      const data = this.flatData();
      EligibilityManagerPageActions.archivePayer(
        refid,
        payorType,
        data,
        props.flagOrderEntryPayerInfo
      ).then((r) => {
        if (r === 'S') {
          Notification.success('Insurance was archived');
        } else {
          Notification.danger(
            'An error occurred. May be payer information empty.'
          );
        }
        OrganizationActions.clearPatientInsuranceInfo();
        OrganizationActions.getPatientInsuranceInfo(
          order.patientid,
          props.flagOrderEntryPayerInfo
        );
        this.setState({ payerToArchive: null });
      });
    }
  }

  updateInsuranceModel(
    name: string,
    value: any,
    errors: any,
    payorType: 'P' | 'S' | 'T'
  ) {
    const patientInsurance: TPatientInsuranceInfo = Object.assign(
      {},
      this.state.patientInsurance
    );
    const insurancePlan =
      payorType === 'P'
        ? patientInsurance.primaryPlan
        : payorType === 'S'
        ? patientInsurance.secondaryPlan
        : payorType === 'T'
        ? patientInsurance.tertiaryPlan
        : null;

    const key =
      payorType === 'P'
        ? 'primaryPlan'
        : payorType === 'S'
        ? 'secondaryPlan'
        : payorType === 'T'
        ? 'tertiaryPlan'
        : null;

    const pseudoModel: TInsurancePlan = {
      ...insurancePlan,
      planId: name === 'payorId' ? 0 : insurancePlan.planId,
      msp: name === 'payorId' ? 0 : insurancePlan.msp,
      payorType,
      [name]: value,
    };

    if (payorType === 'S' && name === 'planId') {
      const planLabel =
        insurancePlan.plan.find((option) => option.value === value)?.label ||
        '';

      const isMSPFieldRequired = planLabel.toLowerCase().startsWith('medicare');

      pseudoModel.msp = isMSPFieldRequired ? pseudoModel.msp : 0;
    }

    const insErrors =
      name === 'payorId' && !value ? {} : this.state.insuranceErrors[key];

    const insuranceErrors = {
      ...this.state.insuranceErrors,
      [key]: {
        ...insErrors,
        [name]: errors,
      },
    };
    patientInsurance[key] = pseudoModel;
    this.setState({ patientInsurance, insuranceErrors });
  }

  onSameAsFacility(value: boolean) {
    const guarantor = {
      guar_g_relation: '',
      guar_g_lnm: '',
      guar_g_fnm: '',
      guar_g_address1: '',
      guar_g_address2: '',
      guar_g_city: '',
      guar_g_state: '',
      guar_g_zip: '',
    };
    const updateGuarantor = () => {
      this.setState({
        model: Object.assign({}, this.state.model, guarantor),
        sameAsFacility: value,
      });
    };
    if (value) {
      const eligibility = EligibilityManagerPageStore.getState();
      const setEligilibity = () => {
        const { facilityValues } = eligibility;
        guarantor.guar_g_address1 = facilityValues.address1;
        guarantor.guar_g_address2 = facilityValues.address2;
        guarantor.guar_g_city = facilityValues.city;
        guarantor.guar_g_state = facilityValues.state;
        guarantor.guar_g_zip = facilityValues.zipcode;
        updateGuarantor();
      };
      if (eligibility.facilityValuesLoaded) {
        setEligilibity();
      } else {
        EligibilityManagerPageActions.loadGuarantor(
          this.props.order.facilityid
        ).then(() => setEligilibity());
      }
    } else {
      updateGuarantor();
    }
  }

  submitSuccess() {
    this.updateCompleteInsuranceInfo('').then(() => {
      this.props.callback(true, this.state.model);
    });
  }

  isEmptyInsurance(insurance: any) {
    if (!insurance) {
      return true;
    }
    for (let key in insurance) {
      if (insurance[key]) {
        return false;
      }
    }
    return true;
  }
}

export default Container.create(EligibilityPopup, { withProps: true });
