import { observer } from 'mobx-react-lite';

import { GridControlButton } from 'components/grid';
import { ButtonDropdown } from 'components/button';

import { storeCodeActivation } from 'stores/_mobx/systemSetup/codeActivationMaster';
import { URL_CODE_ACTIVATION_MASTER_ADD } from 'constant/path/systemSetup';

interface PropsType {
  selected: number[];
  onChangeStatus: () => void;
  onToggleImport: () => void;
  onExportPart: () => void;
  onExportAll: () => void;
}

const GridControl = ({
  selected,
  onChangeStatus,
  onToggleImport,
  onExportAll,
  onExportPart,
}: PropsType) => {
  const {
    permission,
    filter: { cptCodeFlag },
    page: {
      pagination: { pageSize },
    },
  } = storeCodeActivation;

  const isActiveStatusSelected = cptCodeFlag === 'A';

  return (
    <>
      {permission.addCode && (
        <GridControlButton title="Add" url={URL_CODE_ACTIVATION_MASTER_ADD} />
      )}
      {permission[
        isActiveStatusSelected ? 'deactivateCode' : 'activateCode'
      ] && (
        <GridControlButton
          title={isActiveStatusSelected ? 'Deactivate' : 'Activate'}
          disabled={!selected.length}
          onClick={onChangeStatus}
        />
      )}
      {permission.importAction && (
        <GridControlButton title="Import" onClick={onToggleImport} />
      )}
      <ButtonDropdown
        text="Export"
        menu={[
          {
            label: `Export ${pageSize} entries`,
            action: onExportPart,
          },
          { label: 'Export all', action: onExportAll },
        ]}
      />
    </>
  );
};

export default observer(GridControl);
