import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import { InputNpiExtended } from 'components/project/NpiLookupInput';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { FakeInput, Input } from 'components/form/textField';
import resolver from './validation';

import { storeNpi, NpiType } from 'stores/_mobx/npi';
import {
  storeRadiologist,
  RadiologistType,
} from 'stores/_mobx/users/radiologist';

const charCapitalize = (name: string = '') =>
  name ? `${name[0]}${name.slice(1).toLowerCase()}` : '';

const defaultValues: FormType = {
  firstName: '',
  middleName: '',
  lastName: '',
  npi: '',
};

interface FormType {
  firstName: string;
  middleName: string;
  lastName: string;
  npi: string;
}

interface PropsType {
  groupId?: number;
  groupName?: string;
  onSubmit: (radiologist: RadiologistType) => void;
  onClose: () => void;
}

const DialogFormRadiologist = ({
  groupId,
  groupName,
  onClose,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    watch,
    reset,
    handleSubmit,
  } = useForm<FormType>({ defaultValues, resolver });

  const { fetching, npiDetail } = storeNpi;

  const npi = watch('npi');

  const handlePhysicianSelected = (npiDetail: NpiType) => {
    const { first_name, last_name, middle_name } = npiDetail.basic;

    reset(
      {
        npi: `${npiDetail.number}`,
        firstName: charCapitalize(first_name),
        lastName: charCapitalize(last_name),
        middleName: charCapitalize(middle_name),
      },
      {
        keepDirty: true,
        keepTouched: true,
        keepErrors: false,
        keepDefaultValues: true,
      }
    );
  };

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = () =>
    handleSubmit((payload: FormType) =>
      storeRadiologist
        .createRadiologist({
          ...payload,
          groupId,
        })
        .then((radiologist) => {
          if (radiologist) {
            onSubmit(radiologist);
          }
        })
    )();

  useEffect(() => {
    if (npi.length === 10) {
      storeNpi.getNpi({ npi });
    }
  }, [npi]);

  useEffect(() => {
    if (npiDetail) {
      handlePhysicianSelected(npiDetail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [npiDetail]);

  useEffect(() => storeNpi.clearNpi, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Add Radiologist" onClose={onClose} />
      <DialogBody>
        <form
          className={fetching || isSubmitting ? 'on-ajax' : ''}
          onSubmit={handleClickSubmit}>
          {groupId && <FakeInput label="Radiology Group:" value={groupName} />}
          <Input
            name="firstName"
            label="First Name"
            required
            control={control}
          />
          <Input name="middleName" label="Middle Name" control={control} />
          <Input name="lastName" label="Last Name" required control={control} />
          <InputNpiExtended
            name="npi"
            label="NPI"
            required
            disabled={fetching}
            onPhysicianSelected={handlePhysicianSelected}
            control={control}
          />
          <button type="submit" className="d-none" />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button text="Clear" variant="warning" onClick={handleClickReset} />
        <Button
          text="Add"
          disabled={fetching || isSubmitting || !isDirty}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogFormRadiologist);
