import { Switch } from 'react-router-dom';

import PageNotFound from 'page/errors/404';
import {
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';

interface PropsType extends PropsTypeWitDivisionSettings {
  children: any;
}

const DivisionMaster = ({ children, isDivisionEnabled }: PropsType) =>
  isDivisionEnabled ? <Switch>{children}</Switch> : <PageNotFound />;

export default withDivisionSettings(DivisionMaster);
