import { makeObservable, observable, action, runInAction } from 'mobx';

import { TPagination } from 'components/grid/GridTypes';
import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import FilterNameAndState, {
  FilterType,
} from 'stores/_mobx/options/filterNameAndStatus';
import { dateToLocalTimezone } from 'utils/DateUtils';
import { BASE_URL_FILE_DIR } from 'constant/config';

//MM/DD/YYYY -> YYYY-DD-MM
const changeDateFormatBeforeSend = (date: string) => {
  if (date) {
    const splitDate = date.split('/');
    return `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
  }
  return null;
};

//YYYY-DD-MM -> MM/DD/YYYY
const changeDateFormatAfterReceive = (date: string) => {
  if (date) {
    const splitDate = date.split('-');
    return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
  }
  return '';
};

export interface DrPlateType {
  cal_due_date: string;
  center_id: string;
  enable: boolean;
  file_path: string;
  last_cal_date: string;
  plate_name: string;
  refid: number;
  start_date: string;
  unique_id: string;
}

interface FileResponseType {
  desc: string;
  filenm: string;
  filestatus: string;
  remvImg: string;
  uploaded_dt: string;
}

interface DrPlateResponseType {
  0: {
    cal_due_date: string;
    center_id: string;
    file_path: string;
    last_cal_date: string;
    plate_name: string;
    start_date: string;
    unique_id: string;
  };
  file: 0 | FileResponseType[];
}

interface FileType {
  desc: string;
  filenm: string;
  filestatus: string;
}

interface FilesPreviewType {
  desc: string;
  fileName: string;
  path: string;
  uploadDate: string;
}

export interface DrPlateEntryType {
  cal_due_date: string;
  center_id: string;
  last_cal_date: string;
  plate_name: string;
  start_date: string;
  unique_id: string;
  documentList: FileType[];
}

export interface DrPlateEditType extends DrPlateEntryType {
  refid: number;
}

export interface OptionType {
  label: string;
  value: number;
}

class DrPlate {
  fetching: boolean = false;
  fetchingFileList: boolean = false;
  fetchingOptions: boolean = false;
  drPlatesList: DrPlateType[] = [];
  drPlatesTotal: number = 0;
  drPlate: DrPlateEntryType | undefined = undefined;
  idForDelete: number | null = null;
  fileListPreview: FilesPreviewType[] | null = null;
  noteId: number = 0;
  options: OptionType[] = [];

  filter: FilterNameAndState = new FilterNameAndState();
  page: Pagination = new Pagination({ id: 'computer_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingFileList: observable,
      fetchingOptions: observable,
      drPlatesList: observable,
      drPlatesTotal: observable,
      drPlate: observable,
      idForDelete: observable,
      fileListPreview: observable,
      noteId: observable,
      options: observable,

      setFetching: action,
      setFetchingFileList: action,
      setFetchingOptions: action,
      setDrPlatesList: action,
      setDrPlatesTotal: action,
      setDrPlate: action,
      setIdForDelete: action.bound,
      setFileListPreview: action,
      setNoteId: action,
      clearIdForDelete: action.bound,
      clearDrPlate: action.bound,
      clearFileListPreview: action.bound,
      clearNoteId: action.bound,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setFetchingFileList = (fetching: boolean) => {
    this.fetchingFileList = fetching;
  };

  setFetchingOptions = (fetching: boolean) => {
    this.fetchingOptions = fetching;
  };

  setDrPlatesList(list: DrPlateType[]) {
    this.drPlatesList = list;
  }

  setDrPlatesTotal(count: number) {
    this.drPlatesTotal = count;
  }

  setDrPlate(drPlate?: DrPlateEditType) {
    this.drPlate = drPlate;
  }

  setIdForDelete(id: number) {
    this.idForDelete = id;
  }

  setFileListPreview(fileList: FilesPreviewType[]) {
    this.fileListPreview = fileList;
  }

  setNoteId(id: number) {
    this.noteId = id;
  }

  clearIdForDelete() {
    this.idForDelete = null;
  }

  clearDrPlate() {
    this.drPlate = undefined;
  }

  clearFileListPreview() {
    this.fileListPreview = null;
  }

  clearNoteId() {
    this.noteId = 0;
  }

  async getDrPlateList({
    pagination,
    filter,
  }: {
    pagination: TPagination;
    filter: FilterType;
  }) {
    const data = [
      pagination.skip,
      pagination.pageSize,
      filter.name,
      filter.state,
    ];

    try {
      const response = await apiRequest<'SE' | DrPlateType[]>({
        url: 'generalmaster.PlateMaster.GetPlateList',
        data,
      });
      if (Array.isArray(response)) {
        const list = response.map((item) => ({
          ...item,
          refid: Number(item.refid),
        }));
        this.setDrPlatesList(list);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setDrPlatesList([]);
    }
  }

  async getDrPlateCount(filter: FilterType) {
    try {
      const count = await apiRequest<'SE' | 'S' | number>({
        url: 'generalmaster.PlateMaster.PlateCount',
        data: [filter.name, filter.state],
      });

      const total = Number(count);

      if (Number.isNaN(Number(total))) {
        throw Error('Error is occurred!');
      }
      this.setDrPlatesTotal(total);
    } catch (e) {
      this.setDrPlatesTotal(0);
    }
  }

  getDrPlateMain = () => {
    this.setFetching(true);

    const props = {
      pagination: this.page.pagination,
      filter: this.filter.options,
    };

    const promiseList = this.getDrPlateList(props);

    const promiseCount = this.getDrPlateCount(props.filter);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async getDrPlate(id: number | string) {
    this.setFetching(true);

    try {
      const response = await apiRequest<[] | DrPlateResponseType>({
        url: 'generalmaster.PlateMaster.GetPlate',
        data: [id],
      });
      if (Array.isArray(response)) {
        throw Error('');
      } else {
        const documentList = response.file
          ? response.file.map(({ filenm, desc, filestatus }) => ({
              filenm,
              desc,
              filestatus,
            }))
          : [];
        const start_date = changeDateFormatAfterReceive(response[0].start_date);
        const last_cal_date = changeDateFormatAfterReceive(
          response[0].last_cal_date
        );
        const cal_due_date = changeDateFormatAfterReceive(
          response[0].cal_due_date
        );
        const drPlate = {
          ...response['0'],
          start_date,
          last_cal_date,
          cal_due_date,
          refid: Number(id),
          documentList,
        };

        this.setDrPlate(drPlate);
      }
    } catch (e) {
      this.setDrPlate();
    } finally {
      this.setFetching(false);
    }
  }

  async addDrPlate({ documentList, ...payload }: DrPlateEntryType) {
    const start_date = changeDateFormatBeforeSend(payload.start_date);
    const last_cal_date = changeDateFormatBeforeSend(payload.last_cal_date);
    const cal_due_date = changeDateFormatBeforeSend(payload.cal_due_date);

    const data = [
      { ...payload, start_date, last_cal_date, cal_due_date },
      [],
      documentList,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'generalmaster.PlateMaster.AddPlate',
        data,
      });
      if (result === 'S') {
        Notification.success('DR Plate was added successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'Serial number is not unique!' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  async updateDrPlate({ documentList, ...payload }: DrPlateEditType) {
    const start_date = changeDateFormatBeforeSend(payload.start_date);
    const last_cal_date = changeDateFormatBeforeSend(payload.last_cal_date);
    const cal_due_date = changeDateFormatBeforeSend(payload.cal_due_date);

    const data = [
      payload.refid,
      { ...payload, start_date, last_cal_date, cal_due_date },
      documentList,
    ];
    try {
      const result = await apiRequest<'SE' | 'S' | 'N'>({
        url: 'generalmaster.PlateMaster.EditPlate',
        data,
      });

      if (result === 'S') {
        Notification.success('DR Plate was updated successfully!');
        return null;
      } else if (result === 'N') {
        return { message: 'This Serial number already exist' };
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return { message: '' };
    }
  }

  changeDrPlateStatus = async (ids: number[]) => {
    this.setFetching(true);
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: 'generalmaster.PlateMaster.changeStatus',
        data: [ids],
      });
      if (response === 'S') {
        const { length } = ids;

        const statusMsg = this.filter.options.isActive
          ? 'deactivated'
          : 'activated';

        Notification.success(
          `${length} DR Plate${length > 1 ? 's were' : 'was'} ${statusMsg}`
        );
        return true;
      } else Error('');
    } catch (e) {
      Notification.warning('An error occurred!');
      return false;
    } finally {
      this.setFetching(false);
    }
  };

  async deleteDrPlate() {
    this.setFetching(true);
    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.PlateMaster.DeletePlate',
        data: [this.idForDelete],
      });
      if (result === 'S') {
        Notification.success('DR Plate was deleted!');
        this.clearIdForDelete();
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger("This item is in use, so it can't be deleted!");
      this.setFetching(false);
      return false;
    }
  }

  async getFileList(id: number) {
    runInAction(() => {
      this.fetchingFileList = true;
      this.fileListPreview = [];
    });
    try {
      const response = await apiRequest<[] | DrPlateResponseType>({
        url: 'generalmaster.PlateMaster.GetPlate',
        data: [id],
      });
      if (Array.isArray(response)) {
        throw Error('');
      } else {
        const files = response.file
          ? response.file.map(({ filenm, desc, uploaded_dt }) => ({
              fileName: filenm,
              desc,
              uploadDate: dateToLocalTimezone({ date: uploaded_dt }),
              path: `${BASE_URL_FILE_DIR}doc_img/xrayplate/${id}-${filenm}`,
            }))
          : [];

        this.setFileListPreview(files);
      }
    } catch (e: any) {
    } finally {
      this.setFetchingFileList(false);
    }
  }

  async getOptions() {
    if (this.fetchingOptions) return Promise.resolve();

    this.setFetchingOptions(true);

    try {
      const options = await apiRequest<OptionType[]>({
        url: 'generalmaster.PlateMaster.GetPlateDropDown',
      });

      runInAction(() => {
        this.fetchingOptions = false;
        this.options = options;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingOptions = false;
        this.options = [];
      });
    }
  }
}

export const storeDrPlate = new DrPlate();
