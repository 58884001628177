import PatientRecordPageService, {
  TGeneralInfo,
  TFacilityInfo,
  TContactInfo,
  TEmployerInfo,
  TPatientStatusInfo,
  TGuarantorInfo,
  TInsuredInfo,
  TCommentInfo,
} from 'services/patient-records/patient-demographics/PatientRecordPageService';
import { Actions } from 'stores/patient-records/patient-demographics/PatientRecordPageStore';
import appDispatcher from 'dispatcher/AppDispatcher';

export default class PatientRecordPageActions {
  static savePatientRecord(patientRecord: any) {
    return PatientRecordPageService.savePatientRecord(patientRecord);
  }

  static updatePatientRecord(patientRecord: any) {
    return PatientRecordPageService.updatePatientRecord(patientRecord);
  }

  static deletePatientRecord(id: number) {
    return PatientRecordPageService.deletePatientRecord(id);
  }

  static loadPatientRecord(id: number) {
    return PatientRecordPageService.loadPatientRecord(id).then((response) => {
      appDispatcher.dispatch({
        type: Actions.LOAD_PATIENT_RECORD_ACTION,
        data: response,
      });
      return response;
    });
  }

  static loadLocationByFacilityId(facilityId: number) {
    return PatientRecordPageService.loadLocationByFacilityId(facilityId);
  }

  static updateGeneralInfo(generalInfo: TGeneralInfo) {
    return PatientRecordPageService.updateGeneralInfo(generalInfo).then(
      (response) => {
        if (response === 'S') {
          appDispatcher.dispatch({
            type: Actions.UPDATE_GENERAL_INFO_ACTION,
            data: generalInfo,
          });
        }
        return response;
      }
    );
  }

  static updateFacilityInfo(facilityInfo: TFacilityInfo) {
    return PatientRecordPageService.updateFacilityInfo(facilityInfo).then(
      (response) => {
        if (response === 'S') {
          appDispatcher.dispatch({
            type: Actions.UPDATE_FACILITY_INFO_ACTION,
            data: facilityInfo,
          });
        }
        return response;
      }
    );
  }

  static updateContactInfo(contactInfo: TContactInfo) {
    return PatientRecordPageService.updateContactInfo(contactInfo).then(
      (response) => {
        if (response === 'S') {
          appDispatcher.dispatch({
            type: Actions.UPDATE_CONTACT_INFO_ACTION,
            data: contactInfo,
          });
        }
        return response;
      }
    );
  }

  static loadPayerPlan(
    payerId: number
  ): Promise<{ label: string; value: number }[]> {
    return PatientRecordPageService.loadPayerPlan(payerId);
  }

  static updateEmployerInfo(employerInfo: TEmployerInfo) {
    return PatientRecordPageService.updateEmployerInfo(employerInfo).then(
      (response) => {
        if (response === 'S') {
          appDispatcher.dispatch({
            type: Actions.UPDATE_EMPLOYER_INFO_ACTION,
            data: employerInfo,
          });
        }
        return response;
      }
    );
  }

  static updatePatientStatusInfo(patientStatusInfo: TPatientStatusInfo) {
    return PatientRecordPageService.updatePatientStatusInfo(
      patientStatusInfo
    ).then((response) => {
      if (response === 'S') {
        appDispatcher.dispatch({
          type: Actions.UPDATE_PATIENT_STATUS_INFO_ACTION,
          data: patientStatusInfo,
        });
      }
      return response;
    });
  }

  static updateGuarantorInfo(guarantorInfo: TGuarantorInfo) {
    return PatientRecordPageService.updateGuarantorInfo(guarantorInfo).then(
      (response) => {
        if (response === 'S') {
          appDispatcher.dispatch({
            type: Actions.UPDATE_GUARANTOR_INFO_ACTION,
            data: guarantorInfo,
          });
        }
        return response;
      }
    );
  }

  static updateInsuredInfo(insuredInfo: TInsuredInfo) {
    return PatientRecordPageService.updateInsuredInfo(insuredInfo).then(
      (response) => {
        if (response === 'S') {
          appDispatcher.dispatch({
            type: Actions.UPDATE_INSURED_INFO_ACTION,
            data: insuredInfo,
          });
        }
        return response;
      }
    );
  }

  static updateCommentInfo(commentInfo: TCommentInfo) {
    return PatientRecordPageService.updateCommentInfo(commentInfo).then(
      (response) => {
        if (response === 'S') {
          appDispatcher.dispatch({
            type: Actions.UPDATE_COMMENT_INFO_ACTION,
            data: commentInfo,
          });
        }
        return response;
      }
    );
  }

  static clearPatientRecord() {
    appDispatcher.dispatch({
      type: Actions.CLEAR_PATIENT_RECORD_ACTION,
      data: {},
    });
  }
}
