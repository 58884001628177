import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import { Input } from 'components/form/textField';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import MaskInput from 'components/form/maskInput';

import {
  FilterType,
  defaultFilter,
  storeReportFacilityContact,
} from 'stores/_mobx/report/facilityContact';

const fieldCn = 'col-md-6 col-lg-4 col-xl-3';

interface PropsType {
  visibility: boolean;
  onSubmit: () => void;
}

const Filter = ({ visibility, onSubmit }: PropsType) => {
  const { filterResetMarker, filter } = storeReportFacilityContact;

  const { control, handleSubmit, watch, reset } = useForm<FilterType>({
    defaultValues: filter,
  });

  useEffect(() => {
    if (filterResetMarker) reset(defaultFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterResetMarker]);

  useEffect(() => {
    const subscription = watch((formValue) => {
      storeReportFacilityContact.setFilter(formValue as FilterType);
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    return storeReportFacilityContact.clearResetMarker;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      className={`row${visibility ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="lastName"
        label="Last Name"
        className={fieldCn}
        control={control}
      />
      <Input
        name="firstName"
        label="First Name"
        className={fieldCn}
        control={control}
      />
      <DropDownFacility
        name="facility"
        label="Facility"
        className={fieldCn}
        control={control}
      />
      <div />
      <Input name="mail" label="Email" className={fieldCn} control={control} />
      <MaskInput
        format="###-###-####"
        name="phone"
        label="Phone"
        className={fieldCn}
        control={control}
      />
      <MaskInput
        format="###-###-####"
        name="fax"
        label="Fax"
        className={fieldCn}
        control={control}
      />
      <input type="submit" className="d-none" />
    </form>
  );
};

export default observer(Filter);
