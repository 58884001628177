import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    priority_nm: yup.string().required('Please, enter the priority name!'),
    priority_desc: yup
      .string()
      .required('Please, enter the priority description!'),
    minutes: yup
      .number()
      .typeError('Please, enter the time value!')
      .positive('Please, enter the time value!'),
    durationid: yup.number().typeError('Duration type should be selected!'),
  })
);

export default validation;
