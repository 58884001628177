import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

interface PropsType {
  title: string;
  url?: string;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const GridControlButton = ({
  title,
  url,
  style,
  className = '',
  disabled,
  onClick,
}: PropsType) => {
  const cn = clsx('control-btn', { disabled }, className);

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onClick) onClick();
  };

  const handleLinkClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  return url ? (
    <Link
      to={url}
      style={style}
      className={cn}
      onClick={handleLinkClick}
      data-testid={title}>
      {title}
    </Link>
  ) : (
    <input
      type="button"
      onClick={handleButtonClick}
      style={style}
      className={cn}
      value={title}
      disabled={disabled}
      data-testid={title}
    />
  );
};

export default GridControlButton;
