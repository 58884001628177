import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import FormMobileOperator, { FormModel } from './components/Form';

import { storeMobileOperator } from 'stores/_mobx/systemSetup/masterSetting/mobileOperator';
import { URL_MOBILE_OPERATOR } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const MobileOperatorAddPage = ({ history }: PropsType) => {
  const handleSubmit = (data: FormModel) =>
    storeMobileOperator.addMobileOperator(data).then((response) => {
      if (response) {
        history.push(URL_MOBILE_OPERATOR);
      }
    });

  return (
    <>
      <Title title="Add Mobile Operator" />
      <FormMobileOperator
        backUrl={URL_MOBILE_OPERATOR}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default MobileOperatorAddPage;
