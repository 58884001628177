import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    template_name: yup.string().required('Please, enter the template name!'),
    subject: yup.string().required('Please, enter the subject!'),
  })
);

export default validation;
