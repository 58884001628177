import React from 'react';

import Tabs from 'components/tabs';
import { Grid, GridControlButton } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';
import UserSelectionStore from 'stores/project/UserSelectionStore';
import UserSelectionActions from 'actions/project/UserSelectionActions';
import UserProfileStore from 'stores/UserProfileStore';
import { storeGrid } from 'stores/_mobx/grid';
import Text from 'components/form/input/Text';
import Checkbox from 'components/form/input/Checkbox';
import Select from 'components/form/input/Select';
import FacilityDropdown from 'components/project/dropdown/FacilityDropdown';
import { TPagination } from 'components/grid/GridTypes';
import {
  TUserTypeEnum,
  TLabelValue,
} from 'services/project/UserSelectionService';
import IterableUtils from 'utils/IterableUtils';
import MathUtils from 'utils/MathUtils';
import { getPagination } from 'utils/getPagination';

const filterByName = (
  recipients: TUserSelectionItem[],
  filter: TUserSelectionFilter
) => {
  if (filter.userName) {
    return recipients.filter((user) => {
      const isFirstNameMatch = user.to_name
        .toLowerCase()
        .includes(filter.userName.toLowerCase());

      return isFirstNameMatch;
    });
  }

  return recipients;
};

export interface PUserSelection {
  initialSelected?: Array<any>;
  isOpen: boolean;
  onSubmit?: (event: any, a2: any) => void;
  onCancel: () => void;
}

export class TUserSelectionFilter {
  userName: string = '';
  facility: number = 0;
}

export class TUserSelectionItem {
  refid: number = null;
  to_name: string = '';
  to_mail: number = null;
  firstName: string = '';
  lastName: string = '';
  type: string = '';
  facility: string = '';
}

export class TUserGroupItem {
  value: number = null;
  label: string = '';
}

export class TUserType {
  refid: number = null;
  usertype_name: string = '';
  usertype: TUserTypeEnum = '';
  category: string = '';
  type: string = '';
}

export class SUserSelection {
  onAjax: boolean = false;
  selectedUserType: string = null;
  globalSelected: any = {};
  userGroups: Array<TUserGroupItem> = [];
  recipients: Array<TUserSelectionItem> = [];
  selectedUsers: Array<TUserSelectionItem> = [];
  selectedTab: number = 0;
  filter: TUserSelectionFilter = new TUserSelectionFilter();
  pagination: TPagination = new TPagination();
  typeChangeCompleted: boolean = true;
  userTypes: Array<TUserType> = [];
  selected: Array<any> = [];
}

const allowedUserTypes = [
  'A',
  'C',
  'N',
  'S',
  'U',
  'B',
  'T',
  'B1',
  'B2',
  'PA',
  'CC',
  'FC',
  'IC',
  'SU',
  'BA',
  'SM',
  'RC',
  'CA',
  'RA',
  'SP',
];
/**
 * !!! READ IT !!!
 * This component designed for used selection for email messages.
 * There is dangerous data messing -
 * callback of this function return array with objects
 * [
 *    {
 *       refid: 111,
 *       mail_to: 121,
 *       name: 'Joe Doe'
 *    },
 *    {
 *       refid: 211,
 *       mail_to: 124,
 *       name: 'Jon Smith'
 *    },...
 * ]
 *
 * BUT refid is ppxray_userinfo.user_id
 * and to_mail is ppxray_userinfo.refid
 *
 * Generally, you need 'to_mail' field
 */
const defaultTabsList = [{ label: 'Company Users' }, { label: 'Client Users' }];

export default class UserSelection extends React.Component<
  PUserSelection,
  SUserSelection
> {
  COLUMNS = [
    {
      name: 'to_name',
      title: 'User Name',
      render: (to_name: string, d: TUserSelectionItem) => {
        return (
          <Checkbox
            name={'id_' + d.to_mail}
            label={to_name}
            onSetValue={(n: any, v: any) => this.updateTableSelection(d, v)}
            value={this.isSelected(d) ? 1 : 0}
          />
        );
      },
    },
  ];

  userSelectionToken: any = UserSelectionStore.addListener(() =>
    this.onUserSelectionChange()
  );

  constructor(props: PUserSelection) {
    super(props);
    const initialSelected = props.initialSelected;
    this.userSelectionToken = null;
    this.state = {
      selectedUserType: null,
      globalSelected: {},
      userGroups: [],
      recipients: [],
      selectedUsers:
        initialSelected && initialSelected.length ? initialSelected : [],
      selectedTab: 0,
      filter: {
        userName: '',
        facility: 0,
      },
      pagination: getPagination(storeGrid.getPageSize('user_selections')),
      onAjax: false,
      typeChangeCompleted: true,
      selected: [],
      userTypes: [],
    };
  }

  componentDidMount() {
    UserSelectionActions.loadUserTypes();
    UserSelectionActions.loadUserGroups();
  }

  componentWillUnmount() {
    if (this.userSelectionToken) {
      this.userSelectionToken.remove();
    }
    if (this.refs.grid) {
      (this.refs.grid as Grid).clearSelection();
    }
  }

  onUserSelectionChange() {
    const userTypes: Array<TUserType> = UserSelectionStore.getState().userTypes;
    const userGroups: Array<TLabelValue> =
      UserSelectionStore.getState().userGroups;
    this.setState({
      userTypes: userTypes,
      userGroups: userGroups,
      recipients: UserSelectionStore.getState().recipients,
    });
  }

  onChangeTab = (selectedTab: number) => {
    if (selectedTab !== this.state.selectedTab) {
      this.setState({
        filter: {
          ...this.state.filter,
          facility: 0,
        },
      });
    }
    this.setState({ selectedTab, selectedUserType: null }, () => {
      selectedTab > 1 ? this.updateData() : this.clearData();
    });
  };

  getShowFacilityFlag = (userType: TUserType) => {
    return (
      !Number(userType.type) || allowedUserTypes.includes(userType.usertype)
    );
  };

  renderTabsList = () => {
    const { userGroups } = this.state;

    const additionalTabsList = userGroups
      ? userGroups.map(({ label }) => ({ label }))
      : [];

    return defaultTabsList.concat(additionalTabsList);
  };

  render() {
    const { props, state } = this;
    if (!props.isOpen) {
      return null;
    }
    const userTypes = this.getUserTypesHtml();
    const userType = UserSelection.findUserType(
      UserSelectionStore.getState().userTypes,
      state.selectedUserType
    );
    const isAdditionalTabSelected = state.selectedTab >= 2;
    const showFacility =
      userType &&
      !isAdditionalTabSelected &&
      this.getShowFacilityFlag(userType);
    // @ts-ignore
    const dataSource = this.applyPagination(this.applyFilters());
    const gridDataNotLoaded = state.onAjax || !state.typeChangeCompleted;
    const selectedOptions = this.prepareSelectedOptions();

    const allUsersAreSelected = state.recipients.length
      ? this.isAllSelected()
      : false;

    const buttonTitle = allUsersAreSelected ? 'Deselect All' : 'Select All';

    const tabsList = this.renderTabsList();

    const userTypeDropdown =
      state.selectedTab === 0
        ? userTypes?.companyUsers
        : userTypes?.clientUsers;

    return (
      <Dialog size="extraLarge" handleClose={this.onModalCallback}>
        <DialogHeader
          title="Select Recipients"
          onClose={this.onModalCallback}
        />
        <DialogBody>
          <Tabs
            tabsList={tabsList}
            onClick={this.onChangeTab}
            className="mb-4"></Tabs>

          <div className="row">
            {userTypes && !isAdditionalTabSelected ? userTypeDropdown : null}
            {showFacility ? (
              <FacilityDropdown
                name="facility"
                className="col-lg-6"
                value={state.filter.facility}
                onSetValue={(name: string, value: number) =>
                  this.updateFacility(value)
                }
              />
            ) : null}
            {isAdditionalTabSelected && (
              <Text
                name="userName"
                label="User Name"
                className="col-lg-6"
                value={this.state.filter.userName}
                onSetValue={(n: any, v: any) => this.updateFilter(n, v)}
              />
            )}

            <Select
              name="selectedUsers"
              attr={{ isMulti: true, placeholder: ' ' }}
              options={selectedOptions.options}
              value={selectedOptions.value}
              onSetValue={(n: any, v: any) => this.setSelectedUsers(v)}
              className="selected-users"
            />

            <Grid
              id="user_selections"
              ref="grid"
              onAjax={gridDataNotLoaded}
              selectedIds={
                gridDataNotLoaded
                  ? []
                  : state.globalSelected[state.selectedUserType]
              }
              dataSource={gridDataNotLoaded ? [] : dataSource.data}
              dataSourceCount={gridDataNotLoaded ? 0 : dataSource.count}
              pagination={state.pagination}
              onPaginationChange={this.setPagination}
              columns={this.COLUMNS}
              gridControlPanel={
                <GridControlButton
                  title={`${buttonTitle} (${state.recipients.length})`}
                  onClick={
                    allUsersAreSelected ? this.deSelectAll : this.selectAll
                  }
                />
              }
            />
          </div>
        </DialogBody>
      </Dialog>
    );
  }

  isAllSelected() {
    const selectedUsersMap: any = {};
    const selectedUsers = this.state.selectedUsers;
    for (let i = 0; i < selectedUsers.length; i++) {
      selectedUsersMap[selectedUsers[i].to_mail] = true;
    }
    const recipients = this.state.recipients;
    for (let i = 0; i < recipients.length; i++) {
      const recipient = recipients[i];
      let isSelected = !!selectedUsersMap[recipient.to_mail];
      if (!isSelected) {
        return false;
      }
    }
    return true;
  }

  selectAll = () => {
    const { state } = this;
    const { selectedUsers, recipients } = state;
    const recipients4Update = IterableUtils.mergeDeep([], state.recipients);
    if (selectedUsers.length === 0) {
      this.setState({
        selectedUsers: [].concat(recipients),
        recipients: recipients4Update,
      });
      return;
    }
    const add = [];
    const selectedUsersMap: any = {};
    for (let i = 0; i < selectedUsers.length; i++) {
      selectedUsersMap[selectedUsers[i].to_mail] = true;
    }
    for (let i = 0; i < recipients.length; i++) {
      const recipient = recipients[i];
      let isSelected = !!selectedUsersMap[recipient.to_mail];
      if (!isSelected) {
        add.push(recipient);
        selectedUsersMap[recipient.to_mail] = true;
      }
    }
    this.setState({
      selectedUsers: [].concat(selectedUsers, add),
      recipients: recipients4Update,
    });
  };

  deSelectAll = () => {
    const { state } = this;
    const { selectedUsers, recipients } = state;
    if (selectedUsers.length === 0) {
      return;
    }
    const newSelectedUsers: Array<TUserSelectionItem> = [];
    const recipientsMap: any = {};
    for (let i = 0; i < recipients.length; i++) {
      recipientsMap[recipients[i].to_mail] = true;
    }
    for (let j = 0; j < selectedUsers.length; j++) {
      const selectedUser = selectedUsers[j];
      let found = !!recipientsMap[selectedUser.to_mail];
      if (!found) {
        newSelectedUsers.push(selectedUser);
      }
    }
    const recipients4Update = IterableUtils.mergeDeep([], state.recipients);
    this.setState({
      selectedUsers: newSelectedUsers,
      recipients: recipients4Update,
    });
  };

  isSelected(recipient: TUserSelectionItem) {
    const { selectedUsers } = this.state;
    for (let i = 0; i < selectedUsers.length; i++) {
      if (Number(selectedUsers[i].to_mail) === recipient.to_mail) {
        return true;
      }
    }
    return false;
  }

  updateTableSelection(recipient: TUserSelectionItem, isSelected: boolean) {
    const { state } = this;
    const selectedUsers = [].concat(state.selectedUsers);
    if (isSelected) {
      selectedUsers.push(recipient);
    } else {
      for (let i = 0; i < selectedUsers.length; i++) {
        if (selectedUsers[i].to_mail === recipient.to_mail) {
          selectedUsers.splice(i, 1);
          break;
        }
      }
    }
    const recipients = IterableUtils.mergeDeep([], state.recipients);
    this.setState({ selectedUsers, recipients });
  }

  prepareSelectedOptions() {
    const options: Array<{ value: number; label: string }> = [];
    const value: Array<number> = [];
    this.state.selectedUsers.forEach((v) => {
      options.push({
        value: v.to_mail,
        label: v.to_name,
      });
      value.push(v.to_mail);
    });
    return {
      options: options,
      value: value,
    };
  }

  setSelectedUsers(selectedUsersIds: number[]) {
    const { state } = this;
    const selectedUsers: Array<TUserSelectionItem> = [].concat(
      state.selectedUsers
    );
    const recipients = IterableUtils.mergeDeep([], state.recipients);
    this.setState({
      selectedUsers: selectedUsers.filter((value: TUserSelectionItem) =>
        selectedUsersIds.includes(value.to_mail)
      ),
      recipients: recipients,
    });
  }

  updateFilter = (n: keyof TUserSelectionFilter, v: number | string) => {
    const filter = { ...this.state.filter };

    const pagination = { ...this.state.pagination, skip: 0, page: 1 };
    // @ts-ignore
    filter[n] = v;
    this.setState({ filter, pagination });
  };

  applyFilters() {
    const recipients: TUserSelectionItem[] = this.state.recipients || [];
    const { filter, selectedTab } = this.state;

    const filteredRecipients =
      selectedTab >= 2 ? filterByName(recipients, filter) : recipients;

    return filteredRecipients;
  }

  applyPagination(recipients: Array<TUserSelectionItem>) {
    recipients = recipients || [];
    const pagination = this.state.pagination;
    const out: Array<TUserSelectionItem> = [];
    for (
      let i = pagination.skip;
      i < pagination.skip + pagination.pageSize;
      i++
    ) {
      if (recipients.length <= i) {
        break;
      }
      out.push(recipients[i]);
    }
    return {
      data: out,
      count: recipients.length,
    };
  }

  setPagination = (pagination: TPagination) => {
    this.setState({ pagination, onAjax: true }, this.updateData);
  };

  onModalCallback = (response: any) => {
    const filter = { ...this.state.filter, facility: 0 };
    this.setState({ filter }, () => {
      if (response) {
        this.handleSubmit(null);
      } else {
        this.props.onCancel();
      }
    });
  };

  /**
   * This component return an array of selected recipients. This function work with that result array, and do
   * string value from recipients name
   * @param data array
   * @returns string
   */
  static getStringValue(data: Array<{ label: string; to_name: string }>) {
    if (!data) {
      return '';
    }
    const out = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].to_name) {
        out.push(data[i].to_name);
      } else if (data[i].label) {
        out.push(data[i].label);
      }
    }
    return out.join('; ');
  }

  handleSubmit = (event: any) => {
    if (this.props.onSubmit) {
      const selectedUsers = [].concat(this.state.selectedUsers);
      this.setState({ globalSelected: {}, selectedUsers: [] }, () =>
        this.props.onSubmit(event, selectedUsers)
      );
    }
  };

  updateFacility(facility: number) {
    const filter = { ...this.state.filter, facility };

    const pagination = { ...this.state.pagination, skip: 0, page: 1 };

    this.setState({ filter, pagination }, this.updateData);
  }

  clearData() {
    UserSelectionActions.clearRecipients();
  }

  updateData = () => {
    const { state } = this;
    const userGroups = state.userGroups;
    if (
      state.selectedTab > 1 &&
      userGroups &&
      state.selectedTab - 2 < userGroups.length
    ) {
      return UserSelectionActions.loadGroupMembers(
        parseInt('' + userGroups[state.selectedTab - 2].value)
      ).then(this.updateDataCallback);
    }
    const currentUserId = UserProfileStore.getUserId();
    const userType = UserSelection.findUserType(
      UserSelectionStore.getState().userTypes,
      state.selectedUserType
    );
    const facilityFlag =
      userType && this.getShowFacilityFlag(userType) ? 'F' : 'A';
    return UserSelectionActions.loadRecipients(
      userType ? userType.usertype : '',
      UserProfileStore.getUserType(),
      currentUserId,
      parseInt('' + state.filter.facility),
      facilityFlag,
      parseInt('' + state.selectedUserType)
    ).then(this.updateDataCallback);
  };

  updateDataCallback = () => {
    this.setState({
      onAjax: false,
    });
  };

  onChangeUserType = (name: any, value: any) => {
    const { state } = this;
    if (state.selectedUserType !== value) {
      this.setState(
        {
          typeChangeCompleted: false,
          selectedUserType: value,
          onAjax: true,
          selected: state.globalSelected[state.selectedUserType],
        },
        () => {
          this.updateData().then(() =>
            this.setState({ typeChangeCompleted: true })
          );
        }
      );
    }
  };

  static findUserType(userTypes: Array<TUserType>, refid: string) {
    if (!userTypes || !userTypes.length) {
      return null;
    }
    for (let i = 0; i < userTypes.length; i++) {
      if ('' + userTypes[i].refid === '' + refid) {
        return userTypes[i];
      }
    }
    return null;
  }

  getUserTypesHtml() {
    const userTypes = UserSelectionStore.getState().userTypes;
    if (!userTypes || !userTypes.length) {
      return null;
    }
    const companyUsers: any = {
      name: 'companyUsers',
      type: 'radio',
      label: 'Please select User Type',
      className: 'col-lg-6 user-selection-select',
      onSetValue: this.onChangeUserType,
      value: this.state.selectedUserType,
      options: [],
    };
    const clientUsers = { ...companyUsers, options: [], name: 'clientUsers' };
    for (let i = 0; i < userTypes.length; i++) {
      const type = userTypes[i];
      if (MathUtils.parseInt(type.type) === 1) {
        clientUsers.options.push({
          label: type.usertype_name,
          value: type.refid,
        });
      } else {
        companyUsers.options.push({
          label: type.usertype_name,
          value: type.refid,
        });
      }
    }
    return {
      companyUsers: <Select {...companyUsers} key="admin" />,
      clientUsers: <Select {...clientUsers} key="not_admin" />,
    };
  }
}
