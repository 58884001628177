import React, { useState } from 'react';

import useRequest from 'components/hook/useRequest';
import CommonLink from './CommonLink';

import { exportInvoiceXlsx, InvoiceXlsxType } from 'stores/_mobx/export';
import { downloadFile } from 'utils/downloadFile';
import { BASE_URL_FILE_DIR } from 'constant/config';

interface PropsType {
  invoiceDetail: InvoiceXlsxType;
}

const InvoiceXlsxExportLink = ({ invoiceDetail }: PropsType) => {
  const [link, setLink] = useState<string>('/');

  const { fetching, controlledAction } =
    useRequest<InvoiceXlsxType>(exportInvoiceXlsx);

  const handleClick = (e: React.SyntheticEvent) => {
    if (link === '/') {
      e.preventDefault();
      controlledAction(invoiceDetail).then((url) => {
        if (url) {
          const link = `${BASE_URL_FILE_DIR}export-files/invoices/${url}`;
          setLink(link);
          downloadFile(link);
        }
      });
    }
  };

  return (
    <CommonLink
      icon="bi bi-filetype-xlsx"
      downloading
      link={link}
      fetching={fetching}
      onClick={handleClick}
    />
  );
};

export default InvoiceXlsxExportLink;
