import { getDateRangeBounds, getDayBounds } from 'utils/DateUtils';

const fieldsInUtc = ['dos', 'examCompletedDate', 'dispatchedDate'];

export const prettierFilterPayload = (conditionList) => {
  const payload = conditionList.map((el) => {
    if (fieldsInUtc.includes(el.name)) {
      if (el.condition === '=') {
        const date = getDayBounds(el.value);
        return {
          ...el,
          field1: el.field1.replace(/(^DATE\()|(\))/g, ''),
          value: `${date.dateFrom} AND ${date.dateTo}`,
          condition: 'between',
        };
      } else if (el.condition === 'between') {
        const [from, to] = el.value.split(' AND ');
        const date = getDateRangeBounds({ from, to });
        return {
          ...el,
          field1: el.field1.replace(/(^DATE\()|(\))/g, ''),
          value: `${date.dateFrom || from} AND ${date.dateTo || to}`,
        };
      } else if (el.condition === '>=' || el.condition === '<=') {
        const date = getDayBounds(el.value);
        return {
          ...el,
          field1: el.field1.replace(/(^DATE\()|(\))/g, ''),
          value: el.condition === '>=' ? date.dateFrom : date.dateTo,
        };
      } else if (el.condition === '>' || el.condition === '<') {
        const date = getDayBounds(el.value);
        return {
          ...el,
          field1: el.field1.replace(/(^DATE\()|(\))/g, ''),
          value: el.condition === '>' ? date.dateTo : date.dateFrom,
        };
      } else if (el.condition === '!=') {
        const date = getDayBounds(el.value);
        return {
          ...el,
          field1: el.field1.replace(/(^DATE\()|(\))/g, ''),
          value: `${date.dateFrom} AND ${date.dateTo}`,
          condition: 'not between',
        };
      }
    } else if (el.name === 'dob' || el.name === 'invoiceDate') {
      const [mm, dd, yyyy] = el.value.split('/');
      return {
        ...el,
        field1: el.field1.replace(/(^DATE\()|(\))/g, ''),
        value: `${yyyy}-${mm}-${dd}`,
      };
    }
    return el;
  });

  return payload;
};
