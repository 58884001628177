import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';

export interface TabOption {
  label: string | React.ReactNode;
  url?: string;
  disabled?: boolean;
}

interface Props {
  defaultTabIndex?: number;
  tabsList: TabOption[];
  children?: React.ReactNodeArray;
  className?: string;
  onClick?: (index: number, prevIndex: number) => void;
}

const defineActiveTab = (tabsList: TabOption[], defaultIdx: number) => {
  const { search, pathname } = window.location;

  const idx = tabsList.findIndex(
    ({ url }) =>
      url === pathname || url === search || url === `${pathname}${search}`
  );

  return idx < 0 ? defaultIdx : idx;
};

const Tabs = ({
  tabsList,
  defaultTabIndex = 0,
  children,
  className,
  onClick,
}: Props) => {
  const [activeTab, setActiveTab] = useState(
    defineActiveTab(tabsList, defaultTabIndex)
  );

  const history = useHistory();

  const { pathname, search } = useLocation();

  const classNameWrapper = clsx('nav nav-tabs', className);

  const tabIsActive = tabsList[activeTab] && !tabsList[activeTab].disabled;

  const handleClick = (index: number) => (e: React.MouseEvent) => {
    const currentTabClicked = activeTab === index;

    if (tabsList[index].disabled || currentTabClicked) {
      e.preventDefault();
      return;
    }
    if (onClick) {
      e.preventDefault();
      onClick(index, activeTab);
    }
    if (!tabsList[index].url) {
      e.preventDefault();
    }
    setActiveTab(index);
  };

  useEffect(() => {
    setActiveTab(defaultTabIndex);
  }, [defaultTabIndex]);

  useEffect(() => {
    const isUrlExist = 'url' in tabsList[0];

    const index = isUrlExist ? defineActiveTab(tabsList, -1) : -1;

    const tabIsDisabled = Boolean(tabsList[index] && tabsList[index].disabled);

    if ((!~index || tabIsDisabled) && isUrlExist) {
      const availableTabIdx = tabsList.findIndex((tab) => !tab.disabled);

      history.replace(tabsList[availableTabIdx].url);
    }

    setActiveTab(~index && !tabIsDisabled ? index : defaultTabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search]);

  return (
    <>
      <ul className={classNameWrapper}>
        {tabsList.map(({ label, url, disabled }, index) => {
          const cn = clsx('nav-link', {
            disabled,
            active: activeTab === index,
          });

          return (
            <li className="nav-item" key={String(index)} data-testid={label}>
              <Link className={cn} to={url || '/'} onClick={handleClick(index)}>
                {label}
              </Link>
            </li>
          );
        })}
      </ul>
      {children && tabIsActive ? children[activeTab] : null}
    </>
  );
};

export default Tabs;
