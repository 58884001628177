import React from 'react';
import { ReduceStore } from 'flux/utils';

import Select, { OptionType } from 'components/form/input/Select';

export class TItem {
  label: string;
  value: string | number;
}

export interface PAbstractDropdown {
  name?: string;
  label?: string;
  value?: string | number;
  format?: string;
  attr?: any;
  fetching?: boolean;
  optionsFilter?: any;
  sortAlphabetically?: boolean;
  flagOneListItemSelectedByDefault?: boolean;
  onSetValue?: (
    name: string,
    value: string | Array<string> | number,
    errors: any
  ) => void;
  validations?: any;
  noClear?: boolean;
  noLabel?: boolean;
  errorMessages?:
    | string
    | { required?: string; length?: string; positive?: string; custom?: string }
    | Array<any>;
  contentAfter?: React.ReactElement;
  style?: React.CSSProperties;
  className?: string;
  menuPlacement?: 'top' | 'bottom' | 'auto';
}

export class SAbstractDropdown {
  options: OptionType[] = [];
}

export default abstract class AbstractDropdown<
  P extends PAbstractDropdown,
  S extends SAbstractDropdown
> extends React.Component<P, S> {
  storeToken: any = null;

  constructor(props: P) {
    super(props);
    this.state = new SAbstractDropdown() as S;
  }

  abstract getOptions(): Array<OptionType>;
  abstract loadOptions(): Promise<Array<OptionType>>;
  abstract getStore(): ReduceStore<any, any>;

  static adaptArr(arr: Array<TItem>): Array<OptionType> {
    if (!arr) {
      return [];
    }
    const res: Array<OptionType> = arr.map<OptionType>(
      (item: TItem) => new OptionType('' + item.value, item.label)
    );
    return res;
  }

  static adaptPromise(
    promise: Promise<Array<TItem>>
  ): Promise<Array<OptionType>> {
    return promise.then((arr: Array<TItem>) => {
      return AbstractDropdown.adaptArr(arr);
    });
  }

  componentDidMount() {
    const store: ReduceStore<any, any> = this.getStore ? this.getStore() : null;
    this.storeToken = store
      ? store.addListener(() => this.setState({ options: this.getOptions() }))
      : null;
    const options = this.getOptions();
    if (options.length) {
      this.setState({ options });
    } else {
      this.loadOptions && this.loadOptions();
    }
  }

  componentWillUnmount() {
    if (this.storeToken) {
      this.storeToken.remove();
    }
  }

  sortAlphabetically(list: Array<OptionType>) {
    if (list && Array.isArray(list)) {
      return list.sort((item1, item2) => {
        if (item1.label.toLowerCase() < item2.label.toLowerCase()) {
          return -1;
        }
        if (item1.label.toLowerCase() > item2.label.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }
    return [];
  }

  getRenderOptions() {
    return this.getOptions();
  }

  render() {
    const { noClear, ...props } = this.props;
    let options = this.getRenderOptions();
    if (props.optionsFilter) {
      options = (options || []).filter(props.optionsFilter);
    }
    if (props.sortAlphabetically) {
      options = this.sortAlphabetically(options);
    }

    let value: string = props.value as string;
    if (props.flagOneListItemSelectedByDefault && options.length === 1) {
      value = options[0].value as string;
    }

    return (
      <Select
        {...props}
        isClearable={!noClear}
        value={value}
        ref="input"
        options={options}
        attr={this.getAttributes()}
        onSetValue={(name: string, value: any, errorMessages?: any) =>
          this.onSetValue(name, value, errorMessages)
        }
      />
    );
  }

  onSetValue(
    name: string,
    value: string | Array<string> | number,
    errors: any
  ) {
    this.props.onSetValue(name, value, errors);
  }

  getAttributes() {
    const { attr } = this.props;
    if (!this.isOnAjax()) {
      return attr;
    }
    if (!attr) {
      return { className: 'on-ajax-line', disable: true };
    }
    const className = (attr.className ? attr.className : '') + ' on-ajax-line';
    return Object.assign({}, attr, { className: className, disable: true });
  }

  isOnAjax() {
    const { fetching } = this.props;
    try {
      return fetching === undefined ? this.getOptions().length === 0 : fetching;
    } catch (ex) {
      throw Error('Error with options in ' + this.props.name);
    }
  }

  getValue() {
    return this.props.value;
  }

  getInputState() {
    return this.refs.input
      ? (this.refs.input as Select).getInputState(this.getValue())
      : null;
  }
}

export abstract class AAbstractDropdown extends AbstractDropdown<
  PAbstractDropdown,
  SAbstractDropdown
> {}
