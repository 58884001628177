import AbstractLikeFilter from './AbstractLikeFilter';

export default class FirstNameFilter extends AbstractLikeFilter {
  name = 'patientFirstName';
  field = 'Patient First Name';
  field1 = AbstractLikeFilter.getInsecureSecureField(
    'ppxray_patientinfo.first_name'
  );
  className = 'first-name-filter';
}
