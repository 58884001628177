import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { Button } from 'components/button';
import { Grid } from 'components/grid';

import { storeUserExpenseReport } from 'stores/_mobx/report/userExpense';

const columns = [
  { name: 'in_date', title: 'Date' },
  { name: 'regular_time', title: 'Regular Time' },
  { name: 'over_time', title: 'Over Time' },
  { name: 'call_hour', title: 'Call Hours' },
  { name: 'pto', title: 'PTO' },
  { name: 'holiday', title: 'Holiday' },
  { name: 'mileage', title: 'Mileage' },
  {
    name: 'expenses',
    title: 'Expenses',
    render: (expenses: number) => `$ ${expenses}`,
  },
];

const DialogExpenseDetail = () => {
  const {
    fetchingDetail,
    expenseDetailList,
    expenseDetailTotal,
    userIdForDetails,
    expenseDetailTitle,
    pageDetail: { pagination, setPagination },
  } = storeUserExpenseReport;

  const handleClickExport = () => {
    storeUserExpenseReport.exportExpenseDetailToCsv(
      columns,
      expenseDetailTitle
    );
  };

  useEffect(() => {
    if (userIdForDetails) {
      storeUserExpenseReport.getExpenseDetailMain();
    }
  }, [userIdForDetails, pagination]);

  return userIdForDetails ? (
    <Dialog
      size="large"
      handleClose={storeUserExpenseReport.clearUserIdForDetail}>
      <DialogHeader
        title={expenseDetailTitle}
        onClose={storeUserExpenseReport.clearUserIdForDetail}
      />
      <DialogBody>
        <Grid
          id="viewModalLogGrid"
          columns={columns}
          onAjax={fetchingDetail}
          dataSource={expenseDetailList}
          dataSourceCount={expenseDetailTotal}
          pagination={pagination}
          onPaginationChange={setPagination}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          text="Export to CSV"
          disabled={!expenseDetailList.length}
          onClick={handleClickExport}
        />
      </DialogFooter>
    </Dialog>
  ) : null;
};

export default observer(DialogExpenseDetail);
