import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/project/common/title';
import Form from './components/Form';

import {
  storeLoginAttempt,
  AttemptSettingsFormModel,
} from 'stores/_mobx/systemSetup/masterSetting/loginAttempt';
import { URL_LOGIN_ATTEMPT } from 'constant/path/systemSetup';

interface PropsType extends RouteComponentProps {}

const LoginAttemptSettingsPage = ({ history }: PropsType) => {
  const handleSubmit = (payload: AttemptSettingsFormModel) =>
    storeLoginAttempt.addAttemptSettings(payload).then((isSucceed) => {
      if (isSucceed) history.push(URL_LOGIN_ATTEMPT);
    });

  return (
    <>
      <Title title="Login Attempt Settings" />
      <Form backUrl={URL_LOGIN_ATTEMPT} onSubmit={handleSubmit} />
    </>
  );
};

export default LoginAttemptSettingsPage;
