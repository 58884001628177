import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    name: yup.string().required('Please, enter group name!'),
    members: yup.array().min(1, 'Select at least 1 person!'),
  })
);

export default validation;
