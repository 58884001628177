import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Title from 'components/project/common/title';
import { SpinnerFixed } from 'components/spinner';
import Form from './components/Form';

import {
  storeLead,
  LeadFormModel,
} from 'stores/_mobx/workflow/equipmentInventory/lead';
import { URL_LEAD } from 'constant/path/workflow';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const LeadEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, lead } = storeLead;

  const handleSubmit = async (data: LeadFormModel) =>
    storeLead.updateLead(data).then((error) => {
      if (error) return error;
      history.push(URL_LEAD);
    });

  useEffect(() => {
    if (!fetching) storeLead.getLead(Number(params.id));

    return storeLead.clearLead;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title title="Edit Lead" />
      <Form backUrl={URL_LEAD} defaultValues={lead} onSubmit={handleSubmit} />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(LeadEditPage);
