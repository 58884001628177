import { Container } from 'flux/utils';

import Form from 'components/form/Form';
import Text from 'components/form/input/Text';
import Radio from 'components/form/input/Radio';
import { DateRangeLegacy } from 'components/form/dateRange';
import RadiologyDropdown from 'components/project/dropdown/RadiologyDropdown';
import Notification from 'components/modal/Notification';
import ExamTypeDropdown from 'components/project/dropdown/ExamTypeDropdown';
import ProviderTypeDropdown from 'components/project/dropdown/ProviderTypeDropdown';
import LocationDropdown from 'components/project/dropdown/LocationDropdown';
import StateDropdown from 'components/project/dropdown/StateDropdown';
import PlaceOfServiceDropdown from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropdownPureDivision } from 'components/project/dropdown/Division';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';
import TcClaimsActions, {
  TClaimManagerFilterExt,
} from 'actions/billing-coding/claims/TcClaimsActions';
import TcClaimsStore from 'stores/billing-coding/claims/TcClaimsStore';
import AbstractClaim, {
  PAbstractClaim,
  SAbstractClaim,
  TAbstractClaimFilter,
} from 'page/billing-coding/claimManager/AbstractClaim';
import PayerNameDropdown from 'components/project/dropdown/PayerNameDropdown';
import OrderTypeDropdown from 'components/project/dropdown/OrderType';
import FacilityDropdown from 'components/project/dropdown/FacilityDropdown';
import { GridControlButton } from 'components/grid';
import { storeGrid } from 'stores/_mobx/grid';
import Select from 'components/form/input/Select';

const GRID_ID = 'tc_claim_grid';

const className = 'col-sm-12 col-md-6 col-lg-3';

const CLAIM_SELECT_OPTIONS = [
  { value: 'T', label: 'Fee for Service' },
  { value: 'P', label: 'Purchased Services' },
];

const SIGNED_OPTIONS = [
  { value: 'Y', label: 'Signed' },
  { value: 'N', label: 'Unsigned' },
  { value: '', label: 'All' },
];

const dropdownAttributes = {
  isMulti: true,
  isStyleDependsOnValue: true,
};

const columnsVisibleByDefault = [
  'facility_name',
  'patientLastNm',
  'patientFirstNm',
  'dos',
  'radiologist',
  'insImg',
  'facImg',
  'zipImg',
  'doctor_pop_img',
  'dobImg',
  'posImg',
  'viewClaim',
  'examCode',
  'encounterLog',
  'physInfo',
  'visitInfo',
  'division',
];

export interface PTcClaims extends PAbstractClaim {}

export class TTcClaimsFilter extends TAbstractClaimFilter {
  batched: string = 'U';
  batchedDate: string = '';
}

export class STcClaims extends SAbstractClaim {
  filter: TTcClaimsFilter = new TTcClaimsFilter();
}

class TcClaims extends AbstractClaim<PTcClaims, STcClaims> {
  static getStores() {
    return [TcClaimsStore];
  }

  static calculateState(prevState: STcClaims) {
    if (!prevState) {
      prevState = Object.assign(
        new STcClaims(),
        AbstractClaim.getDefaultState(GRID_ID)
      );
      prevState.filter = storeGrid.getFilter(
        GRID_ID,
        new TTcClaimsFilter()
      ) as TTcClaimsFilter;
    }
    const store = TcClaimsStore.getState();
    return Object.assign({}, prevState, {
      dataSource: store.dataSource,
      dataSourceCount: store.dataSourceCount,
    });
  }

  componentWillUnmount() {
    storeGrid.saveFilter(GRID_ID, this.state.filter);
  }

  getTitle() {
    return 'Technical Component Claims';
  }

  getGridId() {
    return GRID_ID;
  }

  hideLeftGrid() {
    return false;
  }

  isProfessional() {
    return false;
  }

  handleChangeFilter = (name: string, value?: string) => {
    const filter = { ...this.state.filter, [name]: value };

    this.setState({ filter });
  };

  handleChangeDatePeriod = (filter: TTcClaimsFilter) => {
    this.setState({ filter });
  };

  getFilterComponent() {
    const { isFilterVisible } = this.props;
    const { division } = this.state.filter;

    return (
      isFilterVisible && (
        <Form
          onCollectValues={this.handleChangeFilter}
          model={this.state.filter}
          submit={this.handleApplyFilter}>
          <Text name="lastName" className={className} />
          <Text name="firstName" className={className} />
          <ExamTypeDropdown
            name="examType"
            label="Modality"
            optionsForOrderOnly
            attr={dropdownAttributes}
            className={className}
          />
          <ProviderTypeDropdown
            name="providerType"
            className={className}
            attr={dropdownAttributes}
          />
          <div />

          <FacilityDropdown
            name="facility"
            attr={dropdownAttributes}
            className={className}
          />
          <LocationDropdown
            name="location"
            className={className}
            attr={dropdownAttributes}
          />
          <StateDropdown
            name="state"
            attr={dropdownAttributes}
            className={className}
          />
          <PlaceOfServiceDropdown
            name="pos"
            label="Place of Service"
            className={className}
            attr={dropdownAttributes}
          />
          <div />
          <>
            <DropdownPureDivision
              name="division"
              label="Division"
              className={className}
              value={division}
              onChange={this.handleChangeDivision}
            />
          </>
          <DateRangeLegacy
            nameFrom="dosStart"
            nameTo="dosEnd"
            nameRadio="period"
            onChange={this.handleChangeDatePeriod}
          />
          <div />
          <RadiologyDropdown
            name="radiologyId"
            label="Radiology Group"
            className={className}
            attr={dropdownAttributes}
          />
          <OrderTypeDropdown
            name="orderType"
            attr={dropdownAttributes}
            className={className}
          />
          <Text name="cpt" label="CPT Code" className="col-sm-3" />
          <PayerNameDropdown
            name="payerName"
            label="Primary Payer"
            className={className}
            attr={dropdownAttributes}
          />
          <div />
          <Select
            name="billProcess"
            className={className}
            options={CLAIM_SELECT_OPTIONS}
          />
          <Radio
            name="isSigned"
            label="Provider"
            options={SIGNED_OPTIONS}
            className="col-sm-12 col-md-6 col-lg-auto part-inline"
          />
          <ControlsLayout alignX="auto">
            <Button
              variant="warning"
              text="Reset"
              onClick={this.handleResetFilter}
            />
            <Button text="Apply" type="submit" />
          </ControlsLayout>
        </Form>
      )
    );
  }

  handleResetFilter = () => {
    const filter = new TTcClaimsFilter();
    const pagination = {
      ...this.state.pagination,
      page: 1,
      skip: 0,
    };
    this.setState({ pagination, appliedClaimType: filter.claimType, filter }, this.updateData);
  };

  createBatch = () => {
    const { state } = this;
    if (!state.selected.length) {
      Notification.warning('Please select at least one order');
    } else if (!state.onAjax) {
      this.setState({ onAjax: true }, () => {
        TcClaimsActions.createBatch(state.selected).then(() => {
          Notification.success(
            'Batch has been created for the selected order(s)'
          );
          this.updateData();
        });
      });
    }
  };

  updateData() {
    const { isDivisionEnabled } = this.props;
    const { filter, pagination } = this.state;
    this.setState({ onAjax: true }, () => {
      TcClaimsActions.loadOverview(
        Object.assign(new TClaimManagerFilterExt(), filter),
        pagination,
        isDivisionEnabled
      ).then(this.updateDataCallback);
    });
  }

  getGridActions() {
    return (
      <GridControlButton title="Create Batch" onClick={this.createBatch} />
    );
  }

  getStartShownColumns(): Map<string, boolean> {
    const out = new Map<string, boolean>();
    const columns = this.getColumns();
    for (let i = 0; i < columns.length; i++) {
      const name = columns[i].name;
      if (!columnsVisibleByDefault.includes(name)) {
        out.set(name, false);
      }
    }
    return out;
  }
}

export default Container.create(TcClaims);
