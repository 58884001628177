export const OPTIONS_MEDIA = [
  { value: 'DX', label: 'DX' },
  { value: 'CR', label: 'CR' },
  { value: 'RG', label: 'RG (Film)' },
  { value: 'not_defined', label: 'Not Defined' },
];

export const OPTIONS_MEDIA_FULL = [
  { value: 'DX', label: 'DX' },
  { value: 'CR', label: 'CR' },
  { value: 'RG', label: 'RG (Film)' },
  { value: 'not_defined', label: 'Not Defined' },
  {
    value: '',
    label: 'ALL',
  },
];

export const OPTIONS_TIMEZONE = Intl.supportedValuesOf('timeZone').map(
  (value) => ({ value, label: value })
);
