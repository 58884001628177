import StringUtils from 'utils/StringUtils';

const convertToCsv = (
  source: Array<any>,
  columns: { title: string; name: string }[],
  filter?: string | null
): string => {
  const rows = [columns.map(({ title }) => title).join(',')];

  source.forEach((entry) => {
    const row = columns.map(({ name }) => {
      const value = name.includes('.')
        ? name.split('.').reduce((key1, key2) => entry[key1]?.[key2])
        : entry[name];
      const clearText = value
        ? String(value)
            .replace(/(?:<).*?(?:>)/gm, '') //This line is replacing all HTML markup
            .replace(/(\r|\n)/gm, '') || ''
        : '';

      return StringUtils.toCsv(clearText);
    });
    rows.push(row.join(','));
  });

  return `${filter ? filter + '\n' : ''}${rows.join('\n')}`;
};

export default convertToCsv;
