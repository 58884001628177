import React from 'react';

import TextArea from 'components/form/input/TextArea';
import { TWeekNotes } from 'services/profile/personal/TimeSheetPageService';

export interface PTimeSheetWeeklyComments {}

export class STimeSheetWeeklyComments {
  comments: string = '';
  date: string = '';
  supervisorComments: string = '';
  index: number = 0;
  dataContainer: any = {};
  canEditComments: boolean = false;
  canEditSupervisorComments: boolean = false;
}

export class TTimeSheetWeekNotes {
  comments: string = '';
  date: string = '';
  supervisorComments: string = '';
  canEditComments: boolean = false;
  canEditSupervisorComments: boolean = false;
}

export default class TimeSheetWeeklyComments extends React.Component<
  PTimeSheetWeeklyComments,
  STimeSheetWeeklyComments
> {
  constructor(props: PTimeSheetWeeklyComments) {
    super(props);
    this.state = new STimeSheetWeeklyComments();
  }

  setData(weekNotes: TTimeSheetWeekNotes) {
    this.setState({
      date: weekNotes.date,
      comments: weekNotes.comments,
      supervisorComments: weekNotes.supervisorComments,
      canEditComments: weekNotes.canEditComments,
      canEditSupervisorComments: weekNotes.canEditSupervisorComments,
    });
  }

  getData(): TWeekNotes {
    const state = this.state;
    const res = new TWeekNotes();
    res.date = state.date;
    res.comments = state.comments;
    res.supervisorComments = state.supervisorComments;
    return res;
  }

  render() {
    const state = this.state;
    return (
      <div className="weekly-comments col-sm-6">
        <div className="row">
          <TextArea
            className="col-sm-6"
            name="comments"
            label="Employee Comments"
            attr={{ rows: 12, disabled: !state.canEditComments }}
            value={state.comments || ''}
            onSetValue={(n: any, v: any) => this.setState({ comments: v })}
          />
          <TextArea
            className="col-sm-6"
            name="supervisorComments"
            label="Supervisor Comments"
            attr={{ rows: 12, disabled: !state.canEditSupervisorComments }}
            value={state.supervisorComments || ''}
            onSetValue={(n: any, v: any) =>
              this.setState({ supervisorComments: v })
            }
          />
        </div>
      </div>
    );
  }
}
