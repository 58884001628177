import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader
} from 'components/modal/dialog';
import { Button } from 'components/button';

import { PatientType, storePatientDemographic } from 'stores/_mobx/patientRecord/patientDemographic';
import { PatientDifferencesType } from './index';

const getDialogContent = (
  patientDifferences: PatientDifferencesType
): React.ReactNode => {
  if (patientDifferences.isClient) {
    return (
      <>
        This patient exists in the system under a
        <b className="mx-2">{patientDifferences.patient.facility}</b>facility.
        <br />
        Do you want to change it to yours?
      </>
    );
  }

  const patientFullName = `${patientDifferences.patient.first_name} ${patientDifferences.patient.last_name}`;

  return (
    <>
      Patient {patientFullName} is assigned to <b>{patientDifferences.patient.facility}</b>.
      <br />
      <br />
      Would you like to re-assign the patient to
      <b className="mx-2">{patientDifferences.currentFacilityName}</b>?
    </>
  );
};

interface PropsType {
  patientDifferences: PatientDifferencesType;
  submitExistedPatient: (patient?: PatientType) => void;
  setPatientDifferences: (patient: PatientDifferencesType | null) => void;
}

const DialogValidatePatientFacility = ({
  patientDifferences,
  submitExistedPatient,
  setPatientDifferences,
}: PropsType) => {
  const handleCloseDialog = () => {
    setPatientDifferences(null);
  };

  const handleChangePatientFacility = () => {
    if (patientDifferences) {
      const patient = {
        ...patientDifferences.patient,
        facility_id: patientDifferences.newFacilityId,
      };

      storePatientDemographic
        .changePatientFacility({
          facilityId: patient.facility_id,
          patientId: patient.refid,
        })
        .then(() => {
          setPatientDifferences(null);
          submitExistedPatient(patient);
        });
    }
  };

  const handleKeepCurrentPatientFacility = () => {
    submitExistedPatient();
  };

  return (
    <Dialog handleClose={handleCloseDialog}>
      <DialogHeader title="Validate Patient Facility" />
      <DialogBody>
        {getDialogContent(patientDifferences)}
      </DialogBody>
      <DialogFooter>
        <Button
          onClick={handleKeepCurrentPatientFacility}
          variant='warning'
          text="Keep Current Facility"
        />
        <Button
          onClick={handleChangePatientFacility}
          text="Re-assign"
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogValidatePatientFacility;
