import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';

interface AttemptType {
  chngpwd_type: string;
  chngpwd_value: number;
  comments: string;
  lastmodified: string;
  no_of_attempts: number;
  period_type: string;
  period_value: number;
  refid: number;
  release_duration: number;
  status: string;
}

export interface AttemptSettingsFormModel {
  attemptNumber: number | '';
  releaseDuration: number | '';
  comment: string;
  inactivePeriodType: number;
  inactivePeriodValue: number | '';
  pwdPeriodType: number;
  pwdPeriodValue: number | '';
}

class LoginAttempt {
  fetching: boolean = false;
  attemptsList: AttemptType[] = [];
  attemptsTotal: number = 0;

  page: Pagination = new Pagination({ id: 'login_attempt_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      attemptsList: observable,
      attemptsTotal: observable,

      setFetching: action,
      setAttemptsList: action,
      setAttemptsTotal: action,
    });
  }

  setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  };

  setAttemptsList(list: AttemptType[]) {
    this.attemptsList = list;
  }

  setAttemptsTotal(count: number) {
    this.attemptsTotal = count;
  }

  async getAttemptsList(attemptsNumber: number | '') {
    const { pagination } = this.page;

    const data = [attemptsNumber, pagination.skip, pagination.pageSize];
    try {
      const response = await apiRequest<'SE' | AttemptType[]>({
        url: 'generalmaster.LoginAttempt.MVLoginAttemptsList',
        data,
      });
      if (Array.isArray(response)) {
        const attempts = response.map((attempt) => ({
          ...attempt,
          refid: Number(attempt.refid),
          chngpwd_value: Number(attempt.chngpwd_value),
          no_of_attempts: Number(attempt.no_of_attempts),
          period_value: Number(attempt.period_value),
          release_duration: Number(attempt.release_duration),
        }));
        this.setAttemptsList(attempts);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setAttemptsList([]);
    }
  }

  async getAttemptsCount(attemptsNumber: number | '') {
    try {
      const count = await apiRequest<number>({
        url: 'generalmaster.LoginAttempt.TotalRecordCount',
        data: [attemptsNumber],
      });

      this.setAttemptsTotal(Number(count) || 0);
    } catch (e) {
      this.setAttemptsTotal(0);
    }
  }

  getAttemptsListMain = (attemptsNumber: number | '') => {
    this.setFetching(true);

    const promiseList = this.getAttemptsList(attemptsNumber);

    const promiseCount = this.getAttemptsCount(attemptsNumber);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async addAttemptSettings(payload: AttemptSettingsFormModel) {
    const data = [
      payload.attemptNumber,
      payload.releaseDuration,
      payload.comment,
      payload.inactivePeriodType,
      payload.inactivePeriodValue,
      payload.inactivePeriodType,
      payload.inactivePeriodValue,
    ];

    try {
      const result = await apiRequest<'SE' | 'S'>({
        url: 'generalmaster.LoginAttempt.AddMVLoginAttempts',
        data,
      });
      if (result === 'S') {
        Notification.success('Authorization settings successfully saved!');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      Notification.danger(e?.message || 'An error occurred!');
      return false;
    }
  }
}

export const storeLoginAttempt = new LoginAttempt();
