export const URL_NPPES_NPI =
  'https://npiregistry.cms.hhs.gov/search?subAction=reset&searchType=ind';

export const URL_ISSUE_COLLECTOR =
  'https://evolvesolutions.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3o5b4z/b/5/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=3cb8815d';

export const URL_USPS =
  'https://tools.usps.com/zip-code-lookup.htm?citybyzipcode';

export const URL_DATA_GOV =
  'https://data.cms.gov/provider-characteristics/medicare-provider-supplier-enrollment/order-and-referring/data';

export const URL_GOOGLE_MAP =
  'https://www.google.com/maps/search/?api=1&query=';
