import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import Title from 'components/project/common/title';

import userProfileStore from 'stores/UserProfileStore';
import { storeLoginReport } from 'stores/_mobx/profile/loginReport';
import { LOGIN_REPORT as PAGE_ID } from 'constant/pagesId/personalProfile';

const COLUMNS = [
  { name: 'date' },
  { name: 'login_time' },
  { name: 'logout_time' },
  { name: 'woking_hours' },
];

const LoginReportPage = () => {
  const {
    fetching,
    logs,
    logsTotal,
    page: { pagination, setPagination },
  } = storeLoginReport;

  useEffect(() => {
    const userId = userProfileStore.getUserId();

    if (!fetching) storeLoginReport.getLoginReportMain(userId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <Title appId={PAGE_ID.PAGE} title="Login Report" />
      <Grid
        id="login_report_grid"
        columns={COLUMNS}
        onAjax={fetching}
        dataSource={logs}
        dataSourceCount={logsTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
    </>
  );
};

export default observer(LoginReportPage);
