import AbstractStore from 'stores/AbstractStore';
import appDispatcher from 'dispatcher/AppDispatcher';

class SummaryTechnologistStore extends AbstractStore {
  getInitialState() {
    return {
      dataSource: [],
      count: 0,
    };
  }

  reduce(state, action) {
    switch (action.type) {
      case 'summarytechnologist_data_overview':
        this.getState().dataSource = action.data || [];
        this.getState().count = action.count || 0;
        break;
      default:
        break;
    }
    return this.getState();
  }
}

export default new SummaryTechnologistStore(appDispatcher);
