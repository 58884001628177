export const PHYSICIAN = {
  ADD: 'phy_add',
  ACTIVE: 'phy_active',
  INACTIVE: 'phy_inactive',
  PASSWORD_RESET: 'phy_password',
  DELETE: 'phy_delete',
  EDIT: 'phy_edit',
  STATUS: 'phy_status',
  PAGE: 'PhyRefMaster',
};

export const GROUP_MANAGER = {
  PAGE: 'GroupManager',
};

export const SIGN_ORDERS = {
  PAGE: 'PhyReport',
};
