import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid, TPagination } from 'components/grid';
import { Button } from 'components/button';
import { PureInput } from 'components/form/textField';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';
import useDebounce from 'components/hook/useDebounce';

import {
  storeZipCode,
  ZipType,
} from 'stores/_mobx/systemSetup/masterSetting/zipCode';
import { getPagination } from 'utils/getPagination';

interface PropsType {
  onChoose: (code: ZipType) => void;
  onCancel: () => void;
}

const DialogZipCodeList = ({ onCancel, onChoose }: PropsType) => {
  const { fetching, zipCodesList, zipCodesTotal } = storeZipCode;

  const [zip, setZip] = useState<string>('');

  const [pagination, setPagination] = useState<TPagination>(getPagination());

  const debouncedMethod = useDebounce(setPagination);

  const handleSetZip = (zip: string) => {
    setZip(zip);

    debouncedMethod(getPagination());
  };

  const COLUMNS = useMemo(
    () => [
      { name: 'refid', title: 'ID#' },
      { name: 'zip', title: 'Zip' },
      {
        title: 'Action',
        name: 'action',
        className: 'width-100',
        render: (value: undefined, rowData: ZipType) => (
          <Button
            text="Choose"
            onClick={() => {
              onChoose(rowData);
            }}
          />
        ),
      },
    ],
    [onChoose]
  );

  useEffect(() => {
    const filter = zip === '' ? {} : { zip };

    storeZipCode.getZipCodesList({ filter, pagination });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  useEffect(() => storeZipCode.clearZipCodesList, []);

  return (
    <Dialog handleClose={onCancel}>
      <DialogHeader title="Zip codes" onClose={onCancel} />
      <DialogBody>
        <PureInput
          placeholder="Lookup ZIP codes"
          type="number"
          className="col-md-6 col-lg-4 mb-3"
          value={zip}
          onChange={handleSetZip}
        />

        <Grid
          columns={COLUMNS}
          onAjax={fetching}
          dataSource={zipCodesList}
          dataSourceCount={zipCodesTotal}
          pagination={pagination}
          onPaginationChange={setPagination}
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogZipCodeList);
