export const URL_MASTER_SETTINGS = '/master-setup/master-settings';

export const URL_JURISDICTION_FEE_SCHEDULE =
  '/fee-schedules/jurisdiction-fee-schedule';
export const URL_FACILITY_FEE_SCHEDULE =
  '/fee-schedules/facility-fee-schedules';
export const URL_CORPORATE_FEE_SCHEDULE =
  '/fee-schedules/corporate-fee-schedules';

export const URL_CODE_ACTIVATION_MASTER =
  '/fee-schedules/code-activation-master';
export const URL_CODE_ACTIVATION_MASTER_ADD = `${URL_CODE_ACTIVATION_MASTER}/new`;
export const URL_CODE_ACTIVATION_MASTER_EDIT = `${URL_CODE_ACTIVATION_MASTER}/:id`;

export const URL_MODIFIER = '/code-set/modifiers';
export const URL_MODIFIER_ADD = `${URL_MODIFIER}/new`;
export const URL_MODIFIER_EDIT = `${URL_MODIFIER}/edit/:id`;

export const URL_DIAGNOSIS_CODES = '/code-set/diagnosis-codes';
export const URL_DIAGNOSIS_CODES_ADD = `${URL_DIAGNOSIS_CODES}/new`;
export const URL_DIAGNOSIS_CODES_EDIT = `${URL_DIAGNOSIS_CODES}/edit/:id`;

export const URL_CODE_MAPPING_BY_MODALITY =
  '/code-set/code-mapping-by-modality';
export const URL_CODE_MAPPING_BY_MODALITY_EDIT = `${URL_CODE_MAPPING_BY_MODALITY}/edit/:id`;
export const URL_CODE_MAPPING_BY_MODALITY_ADD = `${URL_CODE_MAPPING_BY_MODALITY}/new`;

export const URL_APP_SETTINGS = `${URL_MASTER_SETTINGS}/app-settings`;

export const URL_SECURITY_QUESTION = `${URL_MASTER_SETTINGS}/question`;
export const URL_SECURITY_QUESTION_ADD = `${URL_SECURITY_QUESTION}/add`;
export const URL_SECURITY_QUESTION_EDIT = `${URL_SECURITY_QUESTION}/edit/:id`;

export const URL_PROVIDER_TYPE = `${URL_MASTER_SETTINGS}/facilitytypemaster`;
export const URL_PROVIDER_TYPE_ADD = `${URL_PROVIDER_TYPE}/add`;
export const URL_PROVIDER_TYPE_EDIT = `${URL_PROVIDER_TYPE}/edit/:id`;

export const URL_LOGIN_ATTEMPT = `${URL_MASTER_SETTINGS}/loginattempt`;
export const URL_LOGIN_ATTEMPT_ADD = `${URL_LOGIN_ATTEMPT}/add`;
export const URL_MESSAGE_ARCHIVING = `${URL_MASTER_SETTINGS}/archiveday`;
export const URL_PASSWORD_STRENGTH = `${URL_MASTER_SETTINGS}/pwdstrength`;

export const URL_TERMS_CONDITION = `${URL_MASTER_SETTINGS}/termscond`;

export const URL_ADJUSTMENT_CODE = `${URL_MASTER_SETTINGS}/adjustmentcode`;
export const URL_ADJUSTMENT_CODE_ADD = `${URL_ADJUSTMENT_CODE}/add`;
export const URL_ADJUSTMENT_CODE_EDIT = `${URL_ADJUSTMENT_CODE}/edit/:id`;

export const URL_PAYER_INFO = `${URL_MASTER_SETTINGS}/payerinformation`;
export const URL_PAYER_INFO_ADD = `${URL_PAYER_INFO}/add`;
export const URL_PAYER_INFO_EDIT = `${URL_PAYER_INFO}/edit/:id`;

export const URL_PER_DIEM = `${URL_MASTER_SETTINGS}/perdiem`;
export const URL_PER_DIEM_ADD = `${URL_PER_DIEM}/add`;
export const URL_PER_DIEM_EDIT = `${URL_PER_DIEM}/edit/:id`;

export const URL_PLACE_OF_SERVICE = `${URL_MASTER_SETTINGS}/pos`;
export const URL_PLACE_OF_SERVICE_ADD = `${URL_PLACE_OF_SERVICE}/add`;
export const URL_PLACE_OF_SERVICE_EDIT = `${URL_PLACE_OF_SERVICE}/edit/:id`;

export const URL_PAYER_TYPE = `${URL_MASTER_SETTINGS}/payertype`;
export const URL_PAYER_TYPE_ADD = `${URL_PAYER_TYPE}/add`;
export const URL_PAYER_TYPE_EDIT = `${URL_PAYER_TYPE}/edit/:id`;

export const URL_PAYER_PLAN = `${URL_MASTER_SETTINGS}/payerplan_master`;
export const URL_PAYER_PLAN_ADD = `${URL_PAYER_PLAN}/add`;
export const URL_PAYER_PLAN_EDIT = `${URL_PAYER_PLAN}/edit/:id`;

export const URL_HOSPICE = `${URL_MASTER_SETTINGS}/hospicemaster`;
export const URL_HOSPICE_ADD = `${URL_HOSPICE}/add`;
export const URL_HOSPICE_EDIT = `${URL_HOSPICE}/edit/:id`;

export const URL_TRANSPORT = `${URL_MASTER_SETTINGS}/transport`;
export const URL_TRANSPORT_EDIT = `${URL_TRANSPORT}/edit/:id`;
export const URL_TRANSPORT_ADD = `${URL_TRANSPORT}/add`;

export const URL_REGION = `${URL_MASTER_SETTINGS}/region`;
export const URL_REGION_EDIT = `${URL_REGION}/edit/:id`;
export const URL_REGION_ADD = `${URL_REGION}/add`;

export const URL_FINDING = `${URL_MASTER_SETTINGS}/finding`;
export const URL_FINDING_ADD = `${URL_FINDING}/add`;
export const URL_FINDING_EDIT = `${URL_FINDING}/edit/:id`;

export const URL_JURISDICTION = `${URL_MASTER_SETTINGS}/jurisdiction`;
export const URL_JURISDICTION_ADD = `${URL_JURISDICTION}/new`;
export const URL_JURISDICTION_MODIFY = `${URL_JURISDICTION}/:id`;

export const URL_REJECT_REASON = `${URL_MASTER_SETTINGS}/rejectreason`;
export const URL_REJECT_REASON_ADD = `${URL_REJECT_REASON}/add`;
export const URL_REJECT_REASON_EDIT = `${URL_REJECT_REASON}/edit/:id`;

export const URL_SPECIALTY = `${URL_MASTER_SETTINGS}/specialty`;
export const URL_SPECIALTY_ADD = `${URL_SPECIALTY}/add`;
export const URL_SPECIALTY_EDIT = `${URL_SPECIALTY}/edit/:id`;

export const URL_RADIOLOGY_GROUP = `${URL_MASTER_SETTINGS}/radiologygroup`;
export const URL_RADIOLOGY_GROUP_ADD = `${URL_RADIOLOGY_GROUP}/add`;
export const URL_RADIOLOGY_GROUP_EDIT = `${URL_RADIOLOGY_GROUP}/edit/:id`;

export const URL_EXAM_TYPE = `${URL_MASTER_SETTINGS}/examtype`;
export const URL_EXAM_TYPE_ADD = `${URL_EXAM_TYPE}/add`;
export const URL_EXAM_TYPE_EDIT = `${URL_EXAM_TYPE}/edit/:id`;

export const URL_MOBILE_OPERATOR = `${URL_MASTER_SETTINGS}/mobileoperator`;
export const URL_MOBILE_OPERATOR_ADD = `${URL_MOBILE_OPERATOR}/add`;
export const URL_MOBILE_OPERATOR_EDIT = `${URL_MOBILE_OPERATOR}/edit/:id`;

export const URL_ZIP_CODES = `${URL_MASTER_SETTINGS}/zipcodes`;
export const URL_ZIP_CODES_ADD = `${URL_ZIP_CODES}/new`;
export const URL_ZIP_CODES_EDIT = `${URL_ZIP_CODES}/:id`;

export const URL_PRIORITY = `${URL_MASTER_SETTINGS}/priority`;
export const URL_PRIORITY_ADD = `${URL_PRIORITY}/add`;
export const URL_PRIORITY_EDIT = `${URL_PRIORITY}/edit/:id`;

export const URL_Q0092 = `${URL_MASTER_SETTINGS}/q0092setup`;
export const URL_Q0092_ADD = `${URL_Q0092}/add`;
export const URL_Q0092_EDIT = `${URL_Q0092}/edit/:id`;

export const URL_PREFIX = `${URL_MASTER_SETTINGS}/prefix`;
export const URL_PREFIX_EDIT = `${URL_PREFIX}/edit/:id`;
export const URL_PREFIX_ADD = `${URL_PREFIX}/add`;

export const URL_SUFFIX = `${URL_MASTER_SETTINGS}/suffix`;
export const URL_SUFFIX_EDIT = `${URL_SUFFIX}/edit/:id`;
export const URL_SUFFIX_ADD = `${URL_SUFFIX}/add`;

export const URL_ADMIN_INFO = `${URL_MASTER_SETTINGS}/admininfo`;

export const URL_EMAIL_GROUP = `${URL_MASTER_SETTINGS}/groupmsg`;
export const URL_EMAIL_GROUP_ADD = `${URL_EMAIL_GROUP}/add`;
export const URL_EMAIL_GROUP_EDIT = `${URL_EMAIL_GROUP}/edit/:id`;

export const URL_EMAIL_TEMPLATE = `${URL_MASTER_SETTINGS}/mailtemplate`;
export const URL_EMAIL_TEMPLATE_ADD = `${URL_EMAIL_TEMPLATE}/add`;
export const URL_EMAIL_TEMPLATE_EDIT = `${URL_EMAIL_TEMPLATE}/edit/:id`;

export const URL_DISPATCH_REGION = '/master-setup/dispatch-regions';
export const URL_DISPATCH_REGION_ADD = `${URL_DISPATCH_REGION}/add`;
export const URL_DISPATCH_REGION_EDIT = `${URL_DISPATCH_REGION}/edit/:id`;

export const URL_FACILITY_CONTACTS = '/master-setup/facility-contacts';
export const URL_FACILITY_CONTACTS_ADD = `${URL_FACILITY_CONTACTS}/add`;
export const URL_FACILITY_CONTACTS_EDIT = `${URL_FACILITY_CONTACTS}/edit/:id`;

export const URL_FACILITY = '/master-setup/facilities';
export const URL_FACILITY_ADD = `${URL_FACILITY}/facility/add`;
export const URL_FACILITY_EDIT = `${URL_FACILITY}/facility/edit/:id`;
export const URL_FACILITY_CONTACT = '/master-setup/facility-contacts-report';

export const URL_CORPORATION = '/master-setup/corporations';
export const URL_CORPORATION_ADD = `${URL_CORPORATION}/add`;
export const URL_CORPORATION_EDIT = `${URL_CORPORATION}/edit/:id`;

export const URL_STATE = `${URL_MASTER_SETTINGS}/statemaster`;
export const URL_STATE_ADD = `${URL_STATE}/add`;
export const URL_STATE_EDIT = `${URL_STATE}/:id`;

export const URL_DESIGNATION = `${URL_MASTER_SETTINGS}/designation`;
export const URL_DESIGNATION_ADD = `${URL_DESIGNATION}/add`;
export const URL_DESIGNATION_EDIT = `${URL_DESIGNATION}/edit/:id`;

export const URL_IMPORT_ITEM = `${URL_MASTER_SETTINGS}/importitem`;
export const URL_IMPORT_ITEM_ADD = `${URL_IMPORT_ITEM}/add`;
export const URL_IMPORT_ITEM_EDIT = `${URL_IMPORT_ITEM}/edit/:id`;

export const URL_CPT_CATEGORY = `${URL_MASTER_SETTINGS}/cpt_category`;
export const URL_CPT_CATEGORY_EDIT = `${URL_CPT_CATEGORY}/:id`;
export const URL_CPT_CATEGORY_ADD = `${URL_CPT_CATEGORY}/add`;

export const URL_INTEGRATION_MASTER = `${URL_MASTER_SETTINGS}/integrations-master`;
export const URL_INTEGRATION_MASTER_EDIT = `${URL_INTEGRATION_MASTER}/:id`;
export const URL_INTEGRATION_MASTER_ADD = `${URL_INTEGRATION_MASTER}/add`;

export const URL_DIVISION_MASTER = `${URL_MASTER_SETTINGS}/division-master`;
export const URL_DIVISION_MASTER_EDIT = `${URL_DIVISION_MASTER}/:id`;
export const URL_DIVISION_MASTER_ADD = `${URL_DIVISION_MASTER}/add`;

export const URL_INTEGRATION_MASTER_NEW = `${URL_MASTER_SETTINGS}/integrations`;
export const URL_INTEGRATION_MASTER_NEW_DFT = `${URL_MASTER_SETTINGS}/integrations/dft`;
export const URL_INTEGRATION_MASTER_ADD_NEW = `${URL_INTEGRATION_MASTER_NEW}/add`;
export const URL_INTEGRATION_MASTER_EDIT_NEW = `${URL_INTEGRATION_MASTER_NEW}/:id`;
