import { makeObservable, observable, action, computed } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import UserProfileStore from 'stores/UserProfileStore';
import {
  getDateString,
  dateToLocalTimezone,
  convertDateInStingToLocalTimezone,
} from 'utils/DateUtils';

const defaultFilterValue: FilterType = {
  dateFrom: getDateString(),
  dateTo: getDateString(),
  type: '',
  userTypeToggler: '',
  firstName: '',
  lastName: '',
  facility: 0,
  userType: 0,
};

type UserTypeToggler = 0 | 1 | '';

export interface FilterType {
  dateFrom: string;
  dateTo: string;
  userTypeToggler: UserTypeToggler;
  type: string;
  firstName: string;
  lastName: string;
  facility: number;
  userType: number;
}

interface AuditTrailType {
  descrp: string;
  entdat: string;
  enttim: string;
  modnam: string;
  ntptim: string;
  patnam: string;
  patref: string;
  remtip: string;
  serval: string;
  userid: number;
  usernm: string;
  usract: string;
}

interface AuditLogType {
  action: string;
  action_by: string;
  action_on: string;
}

class AuditTrail {
  fetching: boolean = false;
  fetchingAuditLog: boolean = false;
  auditTrail: AuditTrailType[] = [];
  auditTrailTotal: number = 0;
  auditLog: AuditLogType[] = [];
  filter: FilterType = defaultFilterValue;
  page: Pagination;

  constructor() {
    this.page = new Pagination();

    makeObservable(this, {
      fetching: observable,
      fetchingAuditLog: observable,
      auditTrail: observable,
      auditTrailTotal: observable,
      auditLog: observable,
      filter: observable,

      auditTrailPart: computed,

      setAuditTrail: action,
      setAuditTrailTotal: action,
      setAuditLog: action,
      setFetching: action,
      setFetchingAuditLog: action,
      setFilter: action,
      clearAuditLog: action.bound,
    });
  }

  setFilter(payload: FilterType) {
    this.filter = payload;
  }

  setAuditTrail(list: AuditTrailType[]) {
    this.auditTrail = list;
  }

  setAuditTrailTotal(count: number) {
    this.auditTrailTotal = count;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingAuditLog(fetching: boolean) {
    this.fetchingAuditLog = fetching;
  }

  setAuditLog(log: AuditLogType[]) {
    this.auditLog = log;
  }

  clearAuditLog() {
    this.auditLog = [];
  }

  setDefaultFilter() {
    const filter = {
      ...this.filter,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facility,
      userTypeToggler: UserProfileStore.isClientUser()
        ? 1
        : ('' as UserTypeToggler),
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const filter = {
      ...defaultFilterValue,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facility,
      userTypeToggler: UserProfileStore.isClientUser()
        ? 1
        : ('' as UserTypeToggler),
    };

    return filter;
  }

  get auditTrailPart() {
    const { pageSize, skip } = this.page.pagination;

    const end = skip + pageSize;

    return this.auditTrail.slice(skip, end);
  }

  async getAuditTrail(filter: FilterType = this.filter) {
    const payload = {
      ...filter,
      userTypeToggler:
        filter.userTypeToggler === ''
          ? ''
          : filter.userTypeToggler === 1
          ? 'A'
          : 'B',
    };

    this.setFetching(true);

    try {
      const response = await apiRequest<AuditTrailType[]>({
        url: 'auditlog.AuditTrail.GetLogList',
        data: [payload],
      });

      if (Array.isArray(response)) {
        const trails = response.map((trail) => ({
          ...trail,
          ntptim: dateToLocalTimezone({
            date: trail.ntptim,
          }),
          userid: Number(trail.userid),
        }));
        this.setAuditTrail(trails);
        this.setAuditTrailTotal(trails.length);
      } else {
        throw Error('Error is occurred!');
      }
    } catch (e) {
      this.setAuditTrail([]);
    } finally {
      this.setFetching(false);
    }
  }

  async getAuditLog(id: number) {
    this.setFetchingAuditLog(true);

    try {
      const response = await apiRequest<AuditLogType[]>({
        url: 'auditlog.AuditTrail.GetOrderInformation',
        data: [id],
      });

      const logs = response.map((log) => ({
        ...log,
        action: convertDateInStingToLocalTimezone(log.action),
        action_on: dateToLocalTimezone({
          date: log.action_on,
        }),
      }));
      this.setAuditLog(logs);
    } catch (e) {
      this.setAuditLog([]);
    } finally {
      this.setFetchingAuditLog(false);
    }
  }
}

export const storeAuditTrail = new AuditTrail();
