import React, { memo } from 'react';
import clsx from 'clsx';

import UserProfileStore from 'stores/UserProfileStore';

export interface PropsType {
  className?: string;
  title: string | React.ReactNode;
  appId?: string;
}

const Title = ({ className = '', appId, title }: PropsType) => {
  const cn = clsx('page-title', className);

  const finalTitle = appId
    ? UserProfileStore.findAppNameByAppId(appId) || title
    : title;

  return <h1 className={cn}>{finalTitle}</h1>;
};

export default memo(Title);
