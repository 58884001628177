import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isAfter } from 'date-fns';
import { parseFromTimeZone } from 'date-fns-timezone';

import { storeRadiologyGroup } from 'stores/_mobx/systemSetup/masterSetting/radiologyGroup';
import {
  convertToAppTimezone,
  dateToLocalTimezone,
  getAppCurrentTimeString,
} from 'utils/DateUtils';

interface Props {
  orderMediaIsRequired: boolean;
}

const validation = ({ orderMediaIsRequired }: Props) => {
  const checkedGroups: Record<number, boolean> = {};

  return yupResolver(
    yup.object().shape({
      arrivalDate: yup.string().required('Select an Arrival Date!'),
      arrivalTime: yup.string().test({
        test: (arrivalTime, { parent: { arrivalDate }, createError }) => {
          if (!arrivalTime) return false;

          if (!arrivalDate) return true;

          const arrivalDateTime = convertToAppTimezone(
            `${arrivalDate} ${arrivalTime}`
          );

          const date = `${getAppCurrentTimeString().replace(',', '')}:00`;

          const maxDate = parseFromTimeZone(date, 'MM/DD/YYYY hh:mm:ss', {
            timeZone: 'America/Chicago',
          });

          if (isAfter(arrivalDateTime, maxDate)) {
            return createError({
              path: 'arrivalDateTime',
              message: `Arrival date and time cannot be in the future (after ${dateToLocalTimezone(
                { date: maxDate }
              )})`,
            });
          }

          return true;
        },
        message: 'Invalid time format, must be hh:mm',
      }),
      order_media_type: yup.string().test({
        test: (order_media_type: string) =>
          orderMediaIsRequired ? Boolean(order_media_type) : true,
        message: "Can't be empty",
      }),
      submittedImages: yup.string().required("Can't be empty"),
      visitSchedule: yup
        .string()
        .typeError("Can't be empty")
        .required("Can't be empty"),
      radiologyGroup: yup
        .number()
        .typeError("Can't be empty")
        .moreThan(0, "Can't be empty"),
      rejectedReasonId: yup.string().when('rejectedImages', {
        is: (rejectedImages: number) => rejectedImages > 0,
        then: yup
          .string()
          .nullable()
          .test({
            test: (rejectedReasonId: string) => Number(rejectedReasonId) > 0,
            message: "Can't be empty",
          }),
        otherwise: yup.string().nullable(),
      }),
      pacsFile: yup.mixed().test({
        test: async (file, { from }: any) => {
          const formData = from[0]?.value || {};

          const isFileSelected = Boolean(file) && typeof file === 'object';

          if (isFileSelected) {
            const isGroupHasRouting = checkedGroups[formData.radiologyGroup];
            if (formData.radiologyGroup && isGroupHasRouting === undefined) {
              const result =
                await storeRadiologyGroup.checkRadiologyGroupRoutingPath(
                  formData.radiologyGroup as number
                );
              checkedGroups[formData.radiologyGroup] = result;

              return Promise.resolve(result);
            }
            return Promise.resolve(Boolean(isGroupHasRouting));
          }
          return Promise.resolve(true);
        },
        message:
          'The Radiology group selected is not set up to send files to PACS',
      }),
    })
  );
};

export default validation;
