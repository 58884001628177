import { apiRequest } from 'services/RequestService';

interface MessageDetailedType {
  dttime: string;
  mailCcData: { mail: string; to_mail: string; to_name: string }[];
  mailToData: { mail: string; to_mail: string; to_name: string }[];
  mail_body: string;
  mail_sub: string;
  mailcontent: { refid: string; mailheading: string; mailid: string }[];
  mailto_id: string;
  mailtonm: string;
}

class SentPageService {
  getSentMessageDetails(id: string): Promise<MessageDetailedType[]> {
    return apiRequest<MessageDetailedType[]>({
      url: 'email.EmailMessageDisplay.EmailBodyMessageDisplay',
      data: [id, 'S'],
    });
  }
}

export default new SentPageService();
