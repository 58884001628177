import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    payerid: yup
      .number()
      .nullable()
      .positive()
      .required('Please, select the name of the payer!'),
    planname: yup.string().required('Please, enter the name of the plan!'),
  })
);

export default validation;
