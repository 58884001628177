import React from 'react';

import Text from 'components/form/input/Text';
import TextCalendar from 'components/form/input/TextCalendar';
import Select from 'components/form/input/Select';

export const LIKE_CONDITIONS = [
  { label: 'Starts with', value: 'Starts with' },
  { label: 'Ends with', value: 'Ends with' },
  { label: 'Contains', value: 'Contains' },
  { label: 'Does not start with', value: 'Does not start with' },
  { label: 'Does not end with', value: 'Does not end with' },
  { label: 'Does not contain', value: 'Does not contain' },
];

export const EQUAL_CONDITIONS = [
  { label: '=', value: '=' },
  { label: '!=', value: '!=' },
];

export const EQUAL_LABELED_CONDITIONS = [
  { label: '=', value: 'Starts with' },
  { label: '!=', value: 'Ends with' },
];

export const EQUAL_EXT_CONDITIONS = [
  { label: '=', value: '=' },
  { label: '>', value: '>' },
  { label: '>=', value: '>=' },
  { label: '<', value: '<' },
  { label: '<=', value: '<=' },
  { label: '!=', value: '!=' },
  { label: 'Between', value: 'between' },
];

export default class AbstractFilter extends React.Component {
  static getInsecureSecureField(val) {
    return `CAST(${val} as CHAR)`;
  }

  constructor(props) {
    super(props);
    this.state = {
      filterItem: {
        name: null,
        field: null,
        field1: null,
        condition: null,
        value: null,
      },
      date1: null,
      date2: null,
    };
  }

  componentDidMount() {
    const filterItem = {
      ...this.state.filterItem,
      name: this.name,
      field: this.field,
      field1: this.field1,
    };

    this.setState({ filterItem });
  }

  onSetValue(name, value) {
    const filterItem = { ...this.state.filterItem, value };

    this.setState({ filterItem }, () => this.props.onSetValue(filterItem));
  }

  onSetCondition(name, condition) {
    const filterItem = { ...this.state.filterItem, condition, value: null };
    this.setState({ filterItem }, () => this.props.onSetValue(filterItem));
  }

  renderConditions() {
    return (
      <Select
        name="condition"
        options={this.CONDITIONS}
        label="Conditions"
        className="col-sm-6"
        value={this.state.filterItem.condition}
        onSetValue={(name, value) => this.onSetCondition(name, value)}
      />
    );
  }

  renderTextValue() {
    return (
      <Text
        className="col-sm-6"
        name={this.name}
        label="Value"
        value={this.state.filterItem.value}
        onSetValue={(name, value) => this.onSetValue(name, value)}
      />
    );
  }

  renderDateValue() {
    return (
      <TextCalendar
        className="col-sm-6"
        name={this.name}
        label="Value"
        value={this.state.filterItem.value}
        onSetValue={(name, value) => this.onSetValue(name, value)}
      />
    );
  }

  onSetDateRange(name, value) {
    const state = {
      ...this.state,
      ...(name === '1' || name === '2' ? { [`date${name}`]: value } : {}),
    };

    state.filterItem.value = state.date1 + ' AND ' + state.date2;

    this.setState(state, this.props.onSetValue(state.filterItem));
  }

  renderDateRange() {
    return [
      <TextCalendar
        className="col-sm-2"
        name="1"
        label="From"
        key="1"
        value={this.state.date1}
        onSetValue={(name, value) => this.onSetDateRange(name, value)}
      />,
      <TextCalendar
        className="col-sm-2"
        name="2"
        label="To"
        key="2"
        value={this.state.date2}
        onSetValue={(name, value) => this.onSetDateRange(name, value)}
      />,
    ];
  }
}
