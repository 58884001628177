import { useState } from 'react';

import { PureInput } from 'components/form/textField';

interface PropsType {
  defaultValue: string;
  onChange: (groupName: string) => void;
}

const Filter = ({ defaultValue, onChange }: PropsType) => {
  const [groupName, setGroupName] = useState<string>(defaultValue);

  const handleChange = (value: string) => {
    setGroupName(value);
    onChange(value);
  };

  return (
    <PureInput
      placeholder="Radiology Group Name"
      className="col-md-6 col-lg-4"
      value={groupName}
      onChange={handleChange}
    />
  );
};

export default Filter;
