import { daysOfWeek } from 'utils/DateUtils';

export const getUserId = (refid: string) => {
  const arr = refid ? refid.split('.') : [];
  return arr?.[0] || '';
};

export const hasNotApprovedDays = (date: string) => {
  const arr = date ? date.split(',') : [];
  return arr.length === 0 || arr.indexOf('0') >= 0;
};

export const hasNotSubmittedDays = (date: string) => {
  const arr = date ? date.split(',') : [];
  return arr.length === 0 || arr.indexOf('N') >= 0;
};

const completeWith = (str: string, val: string, n: number) => {
  str = !str ? val : str;
  while (str && str.length < n) {
    str = val + str;
  }
  return str;
};

export const getValueForNumberInput = (str: string) => {
  let arr = ('' + (str ? str : ''))
    .split(':')
    .map((v) => completeWith(v.trim(), '0', 2));
  while (arr.length < 2) {
    arr = arr.concat(completeWith('', '0', 2));
  }
  return arr.join('');
};

export const getDataArguments = (filter: Record<string, any>) => {
  const out = [
    {
      filter: filter,
    },
  ];
  out.toString = () => {
    const out = [];
    if (filter.employeeName) {
      out.push("Employee Name: '" + filter.employeeName + "'");
    }
    if (filter.timeSheetStatus === 'S') {
      out.push('Submitted');
    }
    if (filter.timeSheetStatus === 'U') {
      out.push('Not Submitted');
    }
    if (filter.year) {
      out.push("Year: '" + filter.year + "'");
    }
    if (filter.month) {
      out.push("Month: '" + daysOfWeek[filter.month - 1] + "'");
    }
    if (filter.dateFrom) {
      out.push("Date From: '" + filter.dateFrom + "'");
    }
    if (filter.dateTo) {
      out.push("Date To: '" + filter.dateTo + "'");
    }
    return out.join('; ');
  };
  return out;
};
