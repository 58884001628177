import React from 'react';

import Text from 'components/form/input/Text';
import TextArea from 'components/form/input/TextArea';
import NumberInput from 'components/form/input/NumberInput';
import Form from 'components/form/Form';
import Fieldset from 'components/form/Fieldset';
import Radio from 'components/form/input/Radio';
import RadioOptions from 'components/project/common/RadioOptions';
import { IconButton } from 'components/button';
import { areObjectsNotEqual } from 'utils/compareObjects';

export interface PContactInfoSection {
  value: any;
  isLastElement: boolean;
  onSetValue: (contactInfo: any) => void;
  onControlClick: (value: any, isLastElement: boolean) => void;
  additional: boolean;
}

export class SContactInfoSection {
  isCollapsed: boolean = false;
  contactInfo: any = {};
  errors: any = {};
}

export default class ContactInfoSection extends React.Component<
  PContactInfoSection,
  SContactInfoSection
> {
  constructor(props: PContactInfoSection) {
    super(props);
    this.state = {
      isCollapsed: true,
      contactInfo: { ...props.value },
      errors: {},
    };
  }

  componentDidUpdate() {
    if (areObjectsNotEqual(this.props.value, this.state.contactInfo)) {
      this.setState({ contactInfo: { ...this.props.value }, errors: {} });
    }
  }

  toggleCollapsedState = () => {
    this.setState((state) => ({
      isCollapsed: !state.isCollapsed,
    }));
  };

  handleClickControl = () => {
    this.props.onControlClick(this.props.value, this.props.isLastElement);
  };

  render() {
    const { state, props } = this;
    let title =
      state.contactInfo && state.contactInfo.contact_title
        ? state.contactInfo.contact_title
        : '';
    title +=
      state.contactInfo &&
      state.contactInfo.status !== RadioOptions.active[0].value &&
      !props.isLastElement
        ? ' (Inactive)'
        : '';

    return (
      <div className="grouped-fields-block">
        <Form
          model={state.contactInfo}
          errors={state.errors}
          onCollectValues={(name, value, errorMessages) =>
            this.onCollectValues(name, value, errorMessages)
          }>
          <Fieldset className={state.isCollapsed ? 'collapsed' : 'expanded'}>
            <legend
              className={state.isCollapsed ? 'without-border' : ''}
              onClick={this.toggleCollapsedState}>
              <b>{title || 'New contact'}</b>
            </legend>

            <Fieldset className="row mx-3">
              <Text
                name="first_name"
                validations="required"
                errorMessages="Please, enter the first name!"
                className="col-md-6"
              />
              <Text
                name="last_name"
                validations="required"
                errorMessages="Please, enter the last name!"
                className="col-md-6"
              />
              <NumberInput
                name="phone"
                format="###-###-####"
                validations={''}
                errorMessages="Please, enter the phone!"
                className="col-md-6"
              />
              <NumberInput
                name="fax"
                format="###-###-####"
                validations={''}
                errorMessages="Please, enter the fax!"
                className="col-md-6"
              />
              <Text name="email" className="col-md-4" />
              <Text
                name="contact_title"
                label="Role"
                validations="required"
                errorMessages="Please, enter the role!"
                className="col-md-4"
              />
              <Radio
                id={state.contactInfo.refid}
                name="status"
                className="col-md-4 inline"
                options={RadioOptions.active}
                validations="required"
                errorMessages="Please, select the status!"
                onSetValue={this.onSetCheckBoxValue}
              />
              <TextArea
                name="comments"
                noLabel
                attr={{ placeholder: 'Comments', rows: 4 }}
              />
            </Fieldset>
          </Fieldset>
        </Form>
        <div className="grouped-fields-controls">
          <IconButton onClick={this.handleClickControl}>
            <i
              className={`bi bi-${
                props.isLastElement ? 'plus-circle' : 'trash'
              }`}
            />
          </IconButton>
        </div>
      </div>
    );
  }

  onSetCheckBoxValue = (name: string, value: any) => {
    const { state } = this;
    state.contactInfo.status = value;
    this.setState({ contactInfo: state.contactInfo });
  };

  onCollectValues(name: string, value: any, errorMessages: any) {
    const contactInfo = { ...this.state.contactInfo, [name]: value };
    const errors = { ...this.state.errors, [name]: errorMessages };
    this.setState({ contactInfo, errors });
    this.props.onSetValue(contactInfo);
  }
}
