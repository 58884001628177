import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid } from 'components/grid';
import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import { storeRadiologyGroup } from 'stores/_mobx/systemSetup/masterSetting/radiologyGroup';
import { BASE_URL_FILE_DIR } from 'constant/config';

const columns = [
  { name: 'docName', title: 'Document name' },
  { name: 'lastModified', title: 'Uploaded date' },
  {
    name: 'docName',
    title: 'View',
    className: 'text-center',
    render: (docName: string) => (
      <a
        className="fs-5"
        href={`${BASE_URL_FILE_DIR}doc_img/radiology/${docName}`}
        target="_blank"
        rel="noreferrer">
        <i className="bi bi-eye-fill" />
      </a>
    ),
  },
];

interface PropsType {
  onClose: () => void;
}

const DialogDocuments = ({ onClose }: PropsType) => {
  const { fetchingDocs, documentsList } = storeRadiologyGroup;

  useEffect(() => {
    storeRadiologyGroup.getDocuments();

    return storeRadiologyGroup.clearDocuments;
  }, []);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Documents" onClose={onClose} />
      <DialogBody>
        <Grid
          onAjax={fetchingDocs}
          columns={columns}
          dataSource={documentsList}
          disablePagination
          noControl
        />
      </DialogBody>
    </Dialog>
  );
};

export default observer(DialogDocuments);
