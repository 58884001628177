import StringUtils from 'utils/StringUtils';
import convertToCsv from 'utils/convertToCsv';
import { downloadCsv } from 'utils/downloadFile';

export type Column = {
  title: string;
  groupName?: string;
  name: string;
  field?: string;
  headerName?: string;
};

export default class CsvExporter {
  form: HTMLFormElement;
  input: HTMLTextAreaElement;
  filterString: string;
  dataSource: Array<any>;
  columns: Array<Column>;

  /**
   * @param dataSource array of objects with data
   * @param columns array of objects, applicable to Grid columns property
   * @param filterString filters, which will be displayed on top of data
   */
  constructor(
    dataSource: Array<any>,
    columns: Array<Column>,
    filterString?: string
  ) {
    if (!dataSource) {
      throw new TypeError('dataSource is required for CsvExporter');
    }
    if (!columns) {
      throw new TypeError('columns is required for CsvExporter');
    }
    this.dataSource = dataSource;
    this.filterString = filterString ? filterString : '';
    this.columns = columns;
    this.form = null;
    this.input = null;
  }

  exportAndDownload(name: string) {
    const data = this._prepareCsv();

    const date = new Date();

    const fileName = `${name}${date.getFullYear()}_${
      date.getMonth() + 1
    }_${date.getDate()}_${date.getHours()}-${date.getMinutes()}.csv`;

    downloadCsv(data, fileName);
  }

  _prepareCsv() {
    const filter =
      this.filterString && this.filterString !== '[object Object]'
        ? StringUtils.toCsv(this.filterString)
        : null;

    const columns = this.columns.map((el) => ({
      title: el.groupName
        ? `${el.title} (${el.groupName})`
        : el.title || el.headerName || '',
      name: el.name || el.field,
    }));

    const csvData = convertToCsv(this.dataSource, columns, filter);

    return csvData;
  }
}
