import { useEffect, useRef } from 'react';

import { DEBOUNCE_DELAY } from 'constant/debounceDelay';

const useDebounce = (method: any, delay: number = DEBOUNCE_DELAY) => {
  const timer = useRef<NodeJS.Timeout>();

  const debouncedMethod = (...payload: any) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => method(...payload), delay);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return debouncedMethod;
};

export default useDebounce;
