import { useForm } from 'react-hook-form';

import { DropDownExamType } from 'components/project/dropdown/ExamTypeDropdown';
import { DropdownProviderType } from 'components/project/dropdown/ProviderTypeDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropdownLocation } from 'components/project/dropdown/LocationDropdown';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropdownRadiology } from 'components/project/dropdown/RadiologyDropdown';
import { DropdownDivision } from 'components/project/dropdown/Division';
import MaskInput from 'components/form/maskInput';
import { Input } from 'components/form/textField';
import { InputCalendar } from 'components/form/inputCalendar';
import { DateRange } from 'components/form/dateRange';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import DropDownTechnologist from 'components/project/dropdown/TechnologistDropDown';
import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { DropDownOrderType } from 'components/project/dropdown/OrderType';

import {
  storeInsuranceVerification,
  defaultFilterValue,
  FilterType,
} from 'stores/_mobx/billingCodding/insuranceVerification';

const className = 'col-md-6 col-lg-3';

interface PropsType {
  fetching: boolean;
  isFilterVisible: boolean;
  onApply: (data: FilterType) => void;
}

const Filter = ({ isFilterVisible, fetching, onApply }: PropsType) => {
  const { control, reset, handleSubmit } = useForm<FilterType>({
    defaultValues: storeInsuranceVerification.filter,
  });

  const handleClickReset = () => {
    reset(defaultFilterValue);
    onApply(defaultFilterValue);
  };

  return (
    <form
      className={`row${isFilterVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onApply)}>
      <Input
        name="lastName"
        label="Last Name"
        className={className}
        control={control}
      />
      <Input
        name="firstName"
        label="First Name"
        className={className}
        control={control}
      />
      <DropDownExamType
        name="examType"
        label="Modality"
        isMulti
        isStyleDependsOnValue
        optionsForOrderOnly
        className={className}
        control={control}
      />
      <DropdownProviderType
        name="providerType"
        label="Provider Type"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <div />

      <DropDownFacility
        name="facility"
        label="Facility"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropDownState
        name="state"
        label="State"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownLocation
        name="location"
        label="Location"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropdownDivision
        name="division"
        label="Division"
        className={className}
        control={control}
      />
      <DropDownPlaceOfService
        name="pos"
        label="Place of Service"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropDownOrderType
        name="orderType"
        label="Order Type"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <DropDownTechnologist
        name="technologistId"
        label="Technologist"
        className={className}
        control={control}
      />
      <MaskInput
        name="ssn"
        label="SSN"
        format="###-##-####"
        className={className}
        control={control}
      />
      <DropdownRadiology
        name="radiologyGroup"
        label="Radiology Group"
        isMulti
        isStyleDependsOnValue
        className={className}
        control={control}
      />
      <InputCalendar
        name="dob"
        label="DOB"
        className="col-md-6 col-lg-3 col-xl-2"
        control={control}
      />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-6 col-lg-3 col-xl-auto"
        control={control}
      />
      <ControlsLayout alignX="right" className="mb-4">
        <Button
          onClick={handleClickReset}
          variant="warning"
          text="Reset"
          disabled={fetching}
        />

        <Button type="submit" text="Apply" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default Filter;
