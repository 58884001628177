import React from 'react';

interface PropsType {
  descriptions?: string[];
  onClick: (name: string) => void;
}

const DescriptionsList = ({ descriptions, onClick }: PropsType) => {
  const handleClick =
    (name: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onClick(name);
    };

  return descriptions ? (
    <ul className="col-sm-3 email-template_list">
      {descriptions.map((text) => (
        <li className="btn btn-light email-template_item" key={text}>
          <a href="/" onClick={handleClick(text)}>
            {text}
          </a>
        </li>
      ))}
    </ul>
  ) : null;
};

export default DescriptionsList;
