import { memo } from 'react';

import { ExamExportLink } from 'page/components/ExportLink';

import { JurisdictionDetailsType } from 'stores/_mobx/billingCodding/claimManagerPC';
import { dateToLocalTimezone } from 'utils/DateUtils';

interface PropsType {
  orderId: number;
  pos: string;
  patientDetails: JurisdictionDetailsType['patient'];
}

const PatientInfo = ({ patientDetails, pos, orderId }: PropsType) => {
  const dos = dateToLocalTimezone({
    date: patientDetails.dos,
    dateOnly: true,
  });

  return (
    <div className="row">
      <div className="formfield-holder col-sm-6 col-md-3">
        <div className="form-label fw-bold">Last Name</div>
        <div>{patientDetails.lastName}</div>
      </div>
      <div className="formfield-holder col-sm-6 col-md-3">
        <div className="form-label fw-bold">First Name</div>
        <div>{patientDetails.firstName}</div>
      </div>
      <div className="formfield-holder col-sm-6 col-md-3">
        <div className="form-label fw-bold">DOB</div>
        <div>{patientDetails.dob}</div>
      </div>
      <div className="formfield-holder col-sm-6 col-md-3">
        <div className="form-label fw-bold">DOS</div>
        <div>{dos}</div>
      </div>
      <div className="formfield-holder col-sm-6 col-md-3">
        <div className="form-label fw-bold">Modality</div>
        <div>{patientDetails.modality}</div>
      </div>
      <div className="formfield-holder col-sm-6 col-md-3">
        <div className="form-label fw-bold">POS</div>
        <div>{pos}</div>
      </div>
      <div className="formfield-holder col-sm-6 col-md-3">
        <div className="form-label fw-bold">Exam Requisition</div>
        <ExamExportLink orderId={orderId} />
      </div>
    </div>
  );
};

export default memo(PatientInfo);
