import { Route, Switch } from 'react-router-dom';

import { AccessRoute } from 'components/HOC';
import * as IDS from 'constant/pagesId/systemSetup';
import * as PATH from 'constant/path/systemSetup';

import MasterSettingsPage from 'page/system-setup/master-setup/masterSettings';

import FeeScheduleJurisdictionPage from 'page/system-setup/feeSchedules/jurisdictionPage';
import FeeScheduleFacilityPage from 'page/system-setup/feeSchedules/facility';
import FeeScheduleCorporatePage from 'page/system-setup/feeSchedules/corporate';
import CodeActivationMasterPage from 'page/system-setup/codesSet/codeActivationMaster/ListPage';
import CodeActivationMasterAddPage from 'page/system-setup/codesSet/codeActivationMaster/AddPage';
import CodeActivationMasterEditPage from 'page/system-setup/codesSet/codeActivationMaster/EditPage';

import ModifiersListPage from 'page/system-setup/codesSet/modifiers/ListPage';
import ModifierAddPage from 'page/system-setup/codesSet/modifiers/AddPage';
import ModifiersEditPage from 'page/system-setup/codesSet/modifiers/EditPage';

import DiagnosisCodesPage from 'page/system-setup/codesSet/diagnosisCodes/ListPage';
import DiagnosisCodesAddPage from 'page/system-setup/codesSet/diagnosisCodes/CreatingPage';
import DiagnosisCodesEditPage from 'page/system-setup/codesSet/diagnosisCodes/EditingPage';

import CodeMappingListPage from 'page/system-setup/codesSet/codeMapping/ListPage';
import CodeMappingEditPage from 'page/system-setup/codesSet/codeMapping/EditPage';
import CodeMappingAddPage from 'page/system-setup/codesSet/codeMapping/AddPage';

import SecurityQuestionListPage from 'page/system-setup/master-setup/masterSettings/securityQuestion/ListPage';
import SecurityQuestionAddPage from 'page/system-setup/master-setup/masterSettings/securityQuestion/AddPage';
import SecurityQuestionEditPage from 'page/system-setup/master-setup/masterSettings/securityQuestion/EditPage';

import ProviderTypeListPage from 'page/system-setup/master-setup/masterSettings/providerType/ListPage';
import ProviderTypeAddPage from 'page/system-setup/master-setup/masterSettings/providerType/AddPage';
import ProviderTypeEditPage from 'page/system-setup/master-setup/masterSettings/providerType/EditPage';

import LoginAttemptListPage from 'page/system-setup/master-setup/masterSettings/loginAttempt/ListPage';
import LoginAttemptSettingsPage from 'page/system-setup/master-setup/masterSettings/loginAttempt/SettingsPage';

import MessageArchivePage from 'page/system-setup/master-setup/masterSettings/messageArchiving';
import PasswordStrengthPage from 'page/system-setup/master-setup/masterSettings/passwordStrength';

import TermsAndConditionsPage from 'page/system-setup/master-setup/masterSettings/termsAndConditions';

import AdjustmentCodesListPage from 'page/system-setup/master-setup/masterSettings/adjustmentCode/ListPage';
import AdjustmentCodeAddPage from 'page/system-setup/master-setup/masterSettings/adjustmentCode/AddPage';
import AdjustmentCodeEditPage from 'page/system-setup/master-setup/masterSettings/adjustmentCode/EditPage';

import PayerInfoListPage from 'page/system-setup/master-setup/masterSettings/payerInfo/ListPage';
import PayerInfoAddPage from 'page/system-setup/master-setup/masterSettings/payerInfo/AddPage';
import PayerInfoEditPage from 'page/system-setup/master-setup/masterSettings/payerInfo/EditPage';

import PerDiemListPage from 'page/system-setup/master-setup/masterSettings/perDiem/ListPage';
import PerDiemAddPage from 'page/system-setup/master-setup/masterSettings/perDiem/AddPage';
import PerDiemEditPage from 'page/system-setup/master-setup/masterSettings/perDiem/EditPage';

import PlaceOfServiceListPage from 'page/system-setup/master-setup/masterSettings/placeOfService/ListPage';
import PlaceOfServiceAddPage from 'page/system-setup/master-setup/masterSettings/placeOfService/AddPage';
import PlaceOfServiceEditPage from 'page/system-setup/master-setup/masterSettings/placeOfService/EditPage';

import PayerTypeListPage from 'page/system-setup/master-setup/masterSettings/payerType/ListPage';
import PayerTypeAddPage from 'page/system-setup/master-setup/masterSettings/payerType/AddPage';
import PayerTypeEditPage from 'page/system-setup/master-setup/masterSettings/payerType/EditPage';

import PayerPlanListPage from 'page/system-setup/master-setup/masterSettings/payerPlan/ListPage';
import PayerPlanAddPage from 'page/system-setup/master-setup/masterSettings/payerPlan/AddPage';
import PayerPlanEditPage from 'page/system-setup/master-setup/masterSettings/payerPlan/EditPage';

import HospiceListPage from 'page/system-setup/master-setup/masterSettings/hospice/ListPage';
import HospiceAddPage from 'page/system-setup/master-setup/masterSettings/hospice/AddPage';
import HospiceEditPage from 'page/system-setup/master-setup/masterSettings/hospice/EditPage';

import TransportListPage from 'page/system-setup/master-setup/masterSettings/transport/ListPage';
import TransportEditPage from 'page/system-setup/master-setup/masterSettings/transport/EditPage';
import TransportAddPage from 'page/system-setup/master-setup/masterSettings/transport/AddPage';

import RegionListPage from 'page/system-setup/master-setup/masterSettings/region/ListPage';
import RegionEditPage from 'page/system-setup/master-setup/masterSettings/region/EditPage';
import RegionAddPage from 'page/system-setup/master-setup/masterSettings/region/AddPage';

import FindingPage from 'page/system-setup/master-setup/masterSettings/finding/ListPage';
import FindingAddPage from 'page/system-setup/master-setup/masterSettings/finding/AddPage';
import FindingEditPage from 'page/system-setup/master-setup/masterSettings/finding/EditPage';

import JurisdictionListPage from 'page/system-setup/master-setup/jurisdiction/JurisdictionListPage';
import JurisdictionDetailsPage from 'page/system-setup/master-setup/jurisdiction/JurisdictionDetailsPage';

import RejectReasonListPage from 'page/system-setup/master-setup/masterSettings/rejectReason/ListPage';
import RejectReasonAddPage from 'page/system-setup/master-setup/masterSettings/rejectReason/AddPage';
import RejectReasonEditPage from 'page/system-setup/master-setup/masterSettings/rejectReason/EditPage';

import SpecialtyListPage from 'page/system-setup/master-setup/masterSettings/specialty/ListPage';
import SpecialtyAddPage from 'page/system-setup/master-setup/masterSettings/specialty/AddPage';
import SpecialtyEditPage from 'page/system-setup/master-setup/masterSettings/specialty/EditPage';

import RadiologyGroupListPage from 'page/system-setup/master-setup/masterSettings/radiologyGroup/ListPage';
import RadiologyGroupDetailsPage from 'page/system-setup/master-setup/masterSettings/radiologyGroup/DetailsPage';

import ExamTypeListPage from 'page/system-setup/master-setup/masterSettings/examType/ListPage';
import ExamTypeAddPage from 'page/system-setup/master-setup/masterSettings/examType/AddPage';
import ExamTypeEditPage from 'page/system-setup/master-setup/masterSettings/examType/EditPage';

import MobileOperatorListPage from 'page/system-setup/master-setup/masterSettings/mobileOperator/ListPage';
import MobileOperatorAddPage from 'page/system-setup/master-setup/masterSettings/mobileOperator/AddPage';
import MobileOperatorEditPage from 'page/system-setup/master-setup/masterSettings/mobileOperator/EditPage';

import ZipCodeListPage from 'page/system-setup/master-setup/masterSettings/zipCodes/ListPage';
import ZipCodeAddPage from 'page/system-setup/master-setup/masterSettings/zipCodes/AddPage';
import ZipCodeEditPage from 'page/system-setup/master-setup/masterSettings/zipCodes/EditPage';

import DivisionMaster from 'page/system-setup/master-setup/masterSettings/divisionMaster';
import DivisionMasterListPage from 'page/system-setup/master-setup/masterSettings/divisionMaster/ListPage';
import DivisionMasterAddPage from 'page/system-setup/master-setup/masterSettings/divisionMaster/AddPage';
import DivisionMasterEditPage from 'page/system-setup/master-setup/masterSettings/divisionMaster/EditPage';

import PriorityListPage from 'page/system-setup/master-setup/masterSettings/priority/ListPage';
import PriorityAddPage from 'page/system-setup/master-setup/masterSettings/priority/AddPage';
import PriorityEditPage from 'page/system-setup/master-setup/masterSettings/priority/EditPage';

import Q0092ListPage from 'page/system-setup/master-setup/masterSettings/q0092/ListPage';
import Q0092AddPage from 'page/system-setup/master-setup/masterSettings/q0092/AddPage';
import Q0092EditPage from 'page/system-setup/master-setup/masterSettings/q0092/EditPage';

import PrefixListPage from 'page/system-setup/master-setup/masterSettings/prefix/ListPage';
import PrefixEditPage from 'page/system-setup/master-setup/masterSettings/prefix/EditPage';
import PrefixAddPage from 'page/system-setup/master-setup/masterSettings/prefix/AddPage';

import SuffixListPage from 'page/system-setup/master-setup/masterSettings/suffix/ListPage';
import SuffixEditPage from 'page/system-setup/master-setup/masterSettings/suffix/EditPage';
import SuffixAddPage from 'page/system-setup/master-setup/masterSettings/suffix/AddPage';

import AppSettingsPage from 'page/system-setup/master-setup/masterSettings/appSettings';

import CompanyInformationPage from 'page/system-setup/master-setup/masterSettings/companyInformation';

import EmailGroupListPage from 'page/system-setup/master-setup/masterSettings/emailGroup/ListPage';
import EmailGroupAddPage from 'page/system-setup/master-setup/masterSettings/emailGroup/AddPage';
import EmailGroupEditPage from 'page/system-setup/master-setup/masterSettings/emailGroup/EditPage';

import EmailTemplateListPage from 'page/system-setup/master-setup/masterSettings/emailTemplate/ListPage';
import EmailTemplateAddPage from 'page/system-setup/master-setup/masterSettings/emailTemplate/AddPage';
import EmailTemplateEditPage from 'page/system-setup/master-setup/masterSettings/emailTemplate/EditPage';

import DispatchRegionListPage from 'page/system-setup/master-setup/dispatchRegions/ListPage';
import DispatchRegionsAddPage from 'page/system-setup/master-setup/dispatchRegions/AddPage';
import DispatchRegionsEditPage from 'page/system-setup/master-setup/dispatchRegions/EditPage';

import FacilityContactListPage from 'page/system-setup/master-setup/facilityContact/ListPage';
import FacilityContactsAddPage from 'page/system-setup/master-setup/facilityContact/AddPage';
import FacilityContactsEditPage from 'page/system-setup/master-setup/facilityContact/EditPage';

import FacilitiesListPage from 'page/system-setup/master-setup/facility/ListPage';
import FacilityAddPage from 'page/system-setup/master-setup/facility/facility-form/FacilityAddPage';
import FacilityEditPage from 'page/system-setup/master-setup/facility/facility-form/FacilityEditPage';
import FacilityContact from 'page/reports/report-creator/facilityContact';

import CorporationListPage from 'page/system-setup/master-setup/corporations/ListPage';
import CorporationForm from 'page/system-setup/master-setup/corporations/CorporationForm';

import StateListPage from 'page/system-setup/master-setup/masterSettings/state/ListPage';
import StateAddPage from 'page/system-setup/master-setup/masterSettings/state/AddPage';
import StateEditPage from 'page/system-setup/master-setup/masterSettings/state/EditPage';

import DesignationPage from 'page/system-setup/master-setup/masterSettings/designation/ListPage';
import DesignationAddPage from 'page/system-setup/master-setup/masterSettings/designation/AddPage';
import DesignationEditPage from 'page/system-setup/master-setup/masterSettings/designation/EditPage';

import ImportItemListPage from 'page/system-setup/master-setup/masterSettings/importItem/ListPage';
import ImportItemAddPage from 'page/system-setup/master-setup/masterSettings/importItem/AddPage';
import ImportItemEditPage from 'page/system-setup/master-setup/masterSettings/importItem/EditPage';

import CptCategoryListPage from 'page/system-setup/master-setup/masterSettings/cptCategory/ListPage';
import CptCategoryEditPage from 'page/system-setup/master-setup/masterSettings/cptCategory/EditPage';
import CptCategoryAddPage from 'page/system-setup/master-setup/masterSettings/cptCategory/AddPage';

import SendingApplications from 'page/system-setup/master-setup/masterSettings/integrations';
import EditSendingApplication from 'page/system-setup/master-setup/masterSettings/integrations/EditSendingApplication';
import AddSendingApplication from 'page/system-setup/master-setup/masterSettings/integrations/AddSendingApplication';
import IntegrationsListPage from 'page/system-setup/master-setup/masterSettings/integrations/ListPage';
import IntegrationsAdd from 'page/system-setup/master-setup/masterSettings/integrations/AddPage';
import IntegrationsEdit from 'page/system-setup/master-setup/masterSettings/integrations/EditPage';
import IntegrationsDFT from 'page/system-setup/master-setup/masterSettings/integrations/DFT';

import E404 from 'page/errors/404';

export const systemSetupRoutes = [
  '/fee-schedules',
  '/code-set',
  '/master-setup',
];

const SystemSetup = () => (
  <Switch>
    <AccessRoute
      exact
      pageId={IDS.FEE_SCHEDULE_JURISDICTION.PAGE}
      path={PATH.URL_JURISDICTION_FEE_SCHEDULE}
      component={FeeScheduleJurisdictionPage}
    />
    <AccessRoute
      exact
      pageId={IDS.FEE_SCHEDULE_CORPORATE.PAGE}
      path={PATH.URL_CORPORATE_FEE_SCHEDULE}
      component={FeeScheduleCorporatePage}
    />
    <AccessRoute
      exact
      pageId={IDS.FEE_SCHEDULE_FACILITY.PAGE}
      path={PATH.URL_FACILITY_FEE_SCHEDULE}
      component={FeeScheduleFacilityPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CODE_ACTIVATION_MASTER.PAGE}
      path={PATH.URL_CODE_ACTIVATION_MASTER}
      component={CodeActivationMasterPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CODE_ACTIVATION_MASTER.ADD}
      path={PATH.URL_CODE_ACTIVATION_MASTER_ADD}
      component={CodeActivationMasterAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CODE_ACTIVATION_MASTER.EDIT}
      path={PATH.URL_CODE_ACTIVATION_MASTER_EDIT}
      component={CodeActivationMasterEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CODES_SET_MODIFIERS.PAGE}
      path={PATH.URL_MODIFIER}
      component={ModifiersListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CODES_SET_MODIFIERS.ADD}
      path={PATH.URL_MODIFIER_ADD}
      component={ModifierAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CODES_SET_MODIFIERS.EDIT}
      path={PATH.URL_MODIFIER_EDIT}
      component={ModifiersEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CODES_SET_DIAGNOSIS.ADD}
      path={PATH.URL_DIAGNOSIS_CODES}
      component={DiagnosisCodesPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CODES_SET_DIAGNOSIS.ADD}
      path={PATH.URL_DIAGNOSIS_CODES_ADD}
      component={DiagnosisCodesAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CODES_SET_DIAGNOSIS.EDIT}
      path={PATH.URL_DIAGNOSIS_CODES_EDIT}
      component={DiagnosisCodesEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CODES_SET_MAPPING.PAGE}
      path={PATH.URL_CODE_MAPPING_BY_MODALITY}
      component={CodeMappingListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CODES_SET_MAPPING.PAGE}
      path={PATH.URL_CODE_MAPPING_BY_MODALITY_ADD}
      component={CodeMappingAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CODES_SET_MAPPING.PAGE}
      path={PATH.URL_CODE_MAPPING_BY_MODALITY_EDIT}
      component={CodeMappingEditPage}
    />
    <Route
      exact
      path={PATH.URL_MASTER_SETTINGS}
      component={MasterSettingsPage}
    />

    {/* system settings */}
    <AccessRoute
      exact
      pageId={IDS.MS_APP_SETTINGS.PAGE}
      path={PATH.URL_APP_SETTINGS}
      component={AppSettingsPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_COMPANY_INFO.PAGE}
      path={PATH.URL_ADMIN_INFO}
      component={CompanyInformationPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_EMAIL_GROUP.PAGE}
      path={PATH.URL_EMAIL_GROUP}
      component={EmailGroupListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_EMAIL_GROUP.PAGE}
      path={PATH.URL_EMAIL_GROUP_ADD}
      component={EmailGroupAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_EMAIL_GROUP.PAGE}
      path={PATH.URL_EMAIL_GROUP_EDIT}
      component={EmailGroupEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_EMAIL_TEMPLATE.PAGE}
      path={PATH.URL_EMAIL_TEMPLATE}
      component={EmailTemplateListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_EMAIL_TEMPLATE.PAGE}
      path={PATH.URL_EMAIL_TEMPLATE_ADD}
      component={EmailTemplateAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_EMAIL_TEMPLATE.PAGE}
      path={PATH.URL_EMAIL_TEMPLATE_EDIT}
      component={EmailTemplateEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_INTEGRATION.PAGE}
      path={PATH.URL_INTEGRATION_MASTER}
      component={SendingApplications}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_INTEGRATION.PAGE}
      path={PATH.URL_INTEGRATION_MASTER_ADD}
      component={AddSendingApplication}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_INTEGRATION.PAGE}
      path={PATH.URL_INTEGRATION_MASTER_EDIT}
      component={EditSendingApplication}
    />
    <Route
      exact
      path={PATH.URL_INTEGRATION_MASTER_NEW}
      component={IntegrationsListPage}
    />
    <Route
      exact
      path={PATH.URL_INTEGRATION_MASTER_NEW_DFT}
      component={IntegrationsDFT}
    />
    <Route
      exact
      path={PATH.URL_INTEGRATION_MASTER_ADD_NEW}
      component={IntegrationsAdd}
    />
    <Route
      exact
      path={PATH.URL_INTEGRATION_MASTER_EDIT_NEW}
      component={IntegrationsEdit}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_LOGIN_ATTEMPT.PAGE}
      path={PATH.URL_LOGIN_ATTEMPT}
      component={LoginAttemptListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_LOGIN_ATTEMPT.PAGE}
      path={PATH.URL_LOGIN_ATTEMPT_ADD}
      component={LoginAttemptSettingsPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_MESSAGE_ARCHIVE.PAGE}
      path={PATH.URL_MESSAGE_ARCHIVING}
      component={MessageArchivePage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PWD_STRENGTH.PAGE}
      path={PATH.URL_PASSWORD_STRENGTH}
      component={PasswordStrengthPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PREFIX.PAGE}
      path={PATH.URL_PREFIX}
      component={PrefixListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PREFIX.PAGE}
      path={PATH.URL_PREFIX_ADD}
      component={PrefixAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PREFIX.PAGE}
      path={PATH.URL_PREFIX_EDIT}
      component={PrefixEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PROVIDER_TYPE.PAGE}
      path={PATH.URL_PROVIDER_TYPE}
      component={ProviderTypeListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PROVIDER_TYPE.PAGE}
      path={PATH.URL_PROVIDER_TYPE_ADD}
      component={ProviderTypeAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PROVIDER_TYPE.PAGE}
      path={PATH.URL_PROVIDER_TYPE_EDIT}
      component={ProviderTypeEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_QUESTIONS.PAGE}
      path={PATH.URL_SECURITY_QUESTION}
      component={SecurityQuestionListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_QUESTIONS.PAGE}
      path={PATH.URL_SECURITY_QUESTION_ADD}
      component={SecurityQuestionAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_QUESTIONS.PAGE}
      path={PATH.URL_SECURITY_QUESTION_EDIT}
      component={SecurityQuestionEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_SUFFIX.PAGE}
      path={PATH.URL_SUFFIX}
      component={SuffixListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_SUFFIX.PAGE}
      path={PATH.URL_SUFFIX_ADD}
      component={SuffixAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_SUFFIX.PAGE}
      path={PATH.URL_SUFFIX_EDIT}
      component={SuffixEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_TERMS_CONDITIONS.PAGE}
      path={PATH.URL_TERMS_CONDITION}
      component={TermsAndConditionsPage}
    />
    {/* billing */}
    <AccessRoute
      exact
      pageId={IDS.MS_ADJUSTMENT_CODE.PAGE}
      path={PATH.URL_ADJUSTMENT_CODE}
      component={AdjustmentCodesListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_ADJUSTMENT_CODE.PAGE}
      path={PATH.URL_ADJUSTMENT_CODE_ADD}
      component={AdjustmentCodeAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_ADJUSTMENT_CODE.PAGE}
      path={PATH.URL_ADJUSTMENT_CODE_EDIT}
      component={AdjustmentCodeEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_FINDINGS.PAGE}
      path={PATH.URL_FINDING}
      component={FindingPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_FINDINGS.PAGE}
      path={PATH.URL_FINDING_ADD}
      component={FindingAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_FINDINGS.PAGE}
      path={PATH.URL_FINDING_EDIT}
      component={FindingEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_HOSPICE.PAGE}
      path={PATH.URL_HOSPICE}
      component={HospiceListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_HOSPICE.PAGE}
      path={PATH.URL_HOSPICE_ADD}
      component={HospiceAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_HOSPICE.PAGE}
      path={PATH.URL_HOSPICE_EDIT}
      component={HospiceEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_JURISDICTION.PAGE}
      path={PATH.URL_JURISDICTION}
      component={JurisdictionListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_JURISDICTION.PAGE}
      path={PATH.URL_JURISDICTION_MODIFY}
      component={JurisdictionDetailsPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PAYER_INFO.PAGE}
      path={PATH.URL_PAYER_INFO}
      component={PayerInfoListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PAYER_INFO.ADD}
      path={PATH.URL_PAYER_INFO_ADD}
      component={PayerInfoAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PAYER_INFO.EDIT}
      path={PATH.URL_PAYER_INFO_EDIT}
      component={PayerInfoEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PAYER_PLAN.PAGE}
      path={PATH.URL_PAYER_PLAN}
      component={PayerPlanListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PAYER_PLAN.PAGE}
      path={PATH.URL_PAYER_PLAN_ADD}
      component={PayerPlanAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PAYER_PLAN.PAGE}
      path={PATH.URL_PAYER_PLAN_EDIT}
      component={PayerPlanEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PAYER_TYPE.PAGE}
      path={PATH.URL_PAYER_TYPE}
      component={PayerTypeListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PAYER_TYPE.PAGE}
      path={PATH.URL_PAYER_TYPE_ADD}
      component={PayerTypeAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PAYER_TYPE.PAGE}
      path={PATH.URL_PAYER_TYPE_EDIT}
      component={PayerTypeEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PER_DIEM.PAGE}
      path={PATH.URL_PER_DIEM}
      component={PerDiemListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PER_DIEM.PAGE}
      path={PATH.URL_PER_DIEM_ADD}
      component={PerDiemAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PER_DIEM.PAGE}
      path={PATH.URL_PER_DIEM_EDIT}
      component={PerDiemEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PLACE_OF_SERVICE.PAGE}
      path={PATH.URL_PLACE_OF_SERVICE}
      component={PlaceOfServiceListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PLACE_OF_SERVICE.PAGE}
      path={PATH.URL_PLACE_OF_SERVICE_ADD}
      component={PlaceOfServiceAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PLACE_OF_SERVICE.PAGE}
      path={PATH.URL_PLACE_OF_SERVICE_EDIT}
      component={PlaceOfServiceEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_Q0092.PAGE}
      path={PATH.URL_Q0092}
      component={Q0092ListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_Q0092.PAGE}
      path={PATH.URL_Q0092_ADD}
      component={Q0092AddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_Q0092.PAGE}
      path={PATH.URL_Q0092_EDIT}
      component={Q0092EditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_TRANSPORT.PAGE}
      path={PATH.URL_TRANSPORT}
      component={TransportListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_TRANSPORT.PAGE}
      path={PATH.URL_TRANSPORT_ADD}
      component={TransportAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_TRANSPORT.PAGE}
      path={PATH.URL_TRANSPORT_EDIT}
      component={TransportEditPage}
    />
    {/* Operations */}
    <AccessRoute
      exact
      pageId={IDS.MS_CPT_CATEGORY.PAGE}
      path={PATH.URL_CPT_CATEGORY}
      component={CptCategoryListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_CPT_CATEGORY.PAGE}
      path={PATH.URL_CPT_CATEGORY_ADD}
      component={CptCategoryAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_CPT_CATEGORY.PAGE}
      path={PATH.URL_CPT_CATEGORY_EDIT}
      component={CptCategoryEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_DOCTYPE.PAGE}
      path={PATH.URL_IMPORT_ITEM}
      component={ImportItemListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_DOCTYPE.PAGE}
      path={PATH.URL_IMPORT_ITEM_ADD}
      component={ImportItemAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_DOCTYPE.PAGE}
      path={PATH.URL_IMPORT_ITEM_EDIT}
      component={ImportItemEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_MODALITY.PAGE}
      path={PATH.URL_EXAM_TYPE}
      component={ExamTypeListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_MODALITY.PAGE}
      path={PATH.URL_EXAM_TYPE_ADD}
      component={ExamTypeAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_MODALITY.PAGE}
      path={PATH.URL_EXAM_TYPE_EDIT}
      component={ExamTypeEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_MOBILE_OPERATOR.PAGE}
      path={PATH.URL_MOBILE_OPERATOR}
      component={MobileOperatorListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_MOBILE_OPERATOR.PAGE}
      path={PATH.URL_MOBILE_OPERATOR_ADD}
      component={MobileOperatorAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_MOBILE_OPERATOR.PAGE}
      path={PATH.URL_MOBILE_OPERATOR_EDIT}
      component={MobileOperatorEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PRIORITY.PAGE}
      path={PATH.URL_PRIORITY}
      component={PriorityListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PRIORITY.PAGE}
      path={PATH.URL_PRIORITY_ADD}
      component={PriorityAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_PRIORITY.PAGE}
      path={PATH.URL_PRIORITY_EDIT}
      component={PriorityEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_RADIOLOGY_GROUP.PAGE}
      path={PATH.URL_RADIOLOGY_GROUP}
      component={RadiologyGroupListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_RADIOLOGY_GROUP.PAGE}
      path={PATH.URL_RADIOLOGY_GROUP_ADD}
      component={RadiologyGroupDetailsPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_RADIOLOGY_GROUP.PAGE}
      path={PATH.URL_RADIOLOGY_GROUP_EDIT}
      component={RadiologyGroupDetailsPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_REGION.PAGE}
      path={PATH.URL_REGION}
      component={RegionListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_REGION.PAGE}
      path={PATH.URL_REGION_ADD}
      component={RegionAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_REGION.PAGE}
      path={PATH.URL_REGION_EDIT}
      component={RegionEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_REJECT_REASON.PAGE}
      path={PATH.URL_REJECT_REASON}
      component={RejectReasonListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_REJECT_REASON.PAGE}
      path={PATH.URL_REJECT_REASON_ADD}
      component={RejectReasonAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_REJECT_REASON.PAGE}
      path={PATH.URL_REJECT_REASON_EDIT}
      component={RejectReasonEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_SPECIALTY.PAGE}
      path={PATH.URL_SPECIALTY}
      component={SpecialtyListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_SPECIALTY.PAGE}
      path={PATH.URL_SPECIALTY_ADD}
      component={SpecialtyAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_SPECIALTY.PAGE}
      path={PATH.URL_SPECIALTY_EDIT}
      component={SpecialtyEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_STATE.PAGE}
      path={PATH.URL_STATE}
      component={StateListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_STATE.PAGE}
      path={PATH.URL_STATE_ADD}
      component={StateAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_STATE.PAGE}
      path={PATH.URL_STATE_EDIT}
      component={StateEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_DESIGNATION.PAGE}
      path={PATH.URL_DESIGNATION}
      component={DesignationPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_DESIGNATION.PAGE}
      path={PATH.URL_DESIGNATION_ADD}
      component={DesignationAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_DESIGNATION.PAGE}
      path={PATH.URL_DESIGNATION_EDIT}
      component={DesignationEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_ZIP_CODE.PAGE}
      path={PATH.URL_ZIP_CODES}
      component={ZipCodeListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_ZIP_CODE.ADD}
      path={PATH.URL_ZIP_CODES_ADD}
      component={ZipCodeAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.MS_ZIP_CODE.ADD}
      path={PATH.URL_ZIP_CODES_EDIT}
      component={ZipCodeEditPage}
    />
    <AccessRoute
      pageId={IDS.MS_DIVISION_MASTER.PAGE}
      path={PATH.URL_DIVISION_MASTER}
      render={(props) => (
        <DivisionMaster {...props}>
          <Route
            exact
            path={PATH.URL_DIVISION_MASTER}
            component={DivisionMasterListPage}
          />
          <Route
            exact
            path={PATH.URL_DIVISION_MASTER_ADD}
            component={DivisionMasterAddPage}
          />
          <Route
            exact
            path={PATH.URL_DIVISION_MASTER_EDIT}
            component={DivisionMasterEditPage}
          />
        </DivisionMaster>
      )}
    />

    <AccessRoute
      exact
      pageId={IDS.DISPATCH_REGION.EDIT}
      path={PATH.URL_DISPATCH_REGION}
      component={DispatchRegionListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.DISPATCH_REGION.EDIT}
      path={PATH.URL_DISPATCH_REGION_ADD}
      component={DispatchRegionsAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.DISPATCH_REGION.EDIT}
      path={PATH.URL_DISPATCH_REGION_EDIT}
      component={DispatchRegionsEditPage}
    />

    <AccessRoute
      exact
      pageId={IDS.FACILITY_ID.PAGE}
      path={PATH.URL_FACILITY}
      component={FacilitiesListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.FACILITY_ID.ADD}
      path={PATH.URL_FACILITY_ADD}
      component={FacilityAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.FACILITY_ID.EDIT}
      path={PATH.URL_FACILITY_EDIT}
      component={FacilityEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CORPORATION.PAGE}
      path={PATH.URL_CORPORATION}
      component={CorporationListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.CORPORATION.PAGE}
      path={PATH.URL_CORPORATION_ADD}
      component={CorporationForm}
    />
    <AccessRoute
      exact
      pageId={IDS.CORPORATION.PAGE}
      path={PATH.URL_CORPORATION_EDIT}
      component={CorporationForm}
    />
    <AccessRoute
      exact
      pageId={IDS.FACILITY_CONTACT.PAGE}
      path={PATH.URL_FACILITY_CONTACTS}
      component={FacilityContactListPage}
    />
    <AccessRoute
      exact
      pageId={IDS.FACILITY_CONTACT.ADD}
      path={PATH.URL_FACILITY_CONTACTS_ADD}
      component={FacilityContactsAddPage}
    />
    <AccessRoute
      exact
      pageId={IDS.FACILITY_CONTACT.EDIT}
      path={PATH.URL_FACILITY_CONTACTS_EDIT}
      component={FacilityContactsEditPage}
    />
    <AccessRoute
      exact
      pageId={IDS.FACILITY_CONTACT_REPORT.PAGE}
      path={PATH.URL_FACILITY_CONTACT}
      component={FacilityContact}
    />
    <Route path="*" component={E404} />
  </Switch>
);

export default SystemSetup;
