import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import UserProfileStore from 'stores/UserProfileStore';
import { storeHomePage } from 'stores/_mobx/systemSetup/masterSetting/homePage';
import { URL_EDIT_HOME_PAGE } from 'constant/path/other';

const Greeting = () => {
  const { isLoadingStart, content } = storeHomePage;

  const isSimpleUser = useMemo(
    () => UserProfileStore.getUserType() === 'U',
    []
  );

  useEffect(() => {
    if (!content) storeHomePage.getHomePageContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={`home-screen${isLoadingStart ? ' loading' : ''}`}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {isSimpleUser && (
        <Link
          to={URL_EDIT_HOME_PAGE}
          title="Edit Home page"
          className="home-screen-edit-button">
          <i className="bi bi-pencil-fill" />
        </Link>
      )}
    </>
  );
};

export default observer(Greeting);
