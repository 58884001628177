import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Dialog, {
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'components/modal/dialog';
import { PureCheckbox } from 'components/form/checkbox';
import Messages from 'components/messages';
import { Button } from 'components/button';

import { storeTaskManager } from 'stores/_mobx/workflow/serviceMaintenance/taskManager';
import CsvExporter from 'utils/CsvExporter';

interface PropsType {
  onClose: () => void;
}

const colsForExport = [
  { title: 'Note', name: 'message' },
  { title: 'Note by', name: 'user' },
  { title: 'Date On', name: 'date' },
];

const DialogServiceNotes = ({ onClose }: PropsType) => {
  const { fetchingNotes, notes, notesTotal } = storeTaskManager;

  const [message, setMessage] = useState<string>('');

  const [allNotes, setCheckboxStatus] = useState<boolean>(false);

  const noMessagesTitle = notesTotal ? '' : 'No messages';

  const handleTypeMessage = (e: React.SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;
    setMessage(value);
  };

  const handleClickSend = () => {
    storeTaskManager.addMessageToNotes(message).then((isSucceed) => {
      if (isSucceed) {
        setMessage('');
        storeTaskManager.getNotes(!allNotes);
      }
    });
  };

  const handleClickExport = () => {
    const csvExporter = new CsvExporter(notes, colsForExport);
    csvExporter.exportAndDownload('user_expense_report');
  };

  useEffect(() => {
    storeTaskManager.getNotes(!allNotes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNotes]);

  return (
    <Dialog size="large" handleClose={onClose}>
      <DialogHeader title="Service Notes" onClose={onClose} />
      <DialogBody className={`${fetchingNotes ? 'on-ajax' : ''}`}>
        <div className="form-check mb-4">
          <PureCheckbox
            className="inline"
            id="allNotes"
            checked={allNotes}
            onChange={setCheckboxStatus}
          />
          <label className="form-check-label" htmlFor="allNotes">
            All Notes
          </label>
        </div>
        <div className="pb-3">
          <textarea
            className="form-control"
            placeholder="Type new message"
            value={message}
            rows={4}
            style={{ resize: 'none' }}
            onChange={handleTypeMessage}
          />
        </div>
        <div className="mb-4 text-end">
          <Button
            onClick={handleClickSend}
            disabled={!message.length || fetchingNotes}>
            Send
          </Button>
        </div>
        <div className="mb-4">
          {notesTotal ? <Messages list={notes} /> : noMessagesTitle}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          text="Export"
          onClick={handleClickExport}
          disabled={!notesTotal}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default observer(DialogServiceNotes);
