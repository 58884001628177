import React from 'react';

import Form from 'components/form/Form';
import ZipCodeInput from 'components/form/input/ZipCodeInput';
import FormControl from 'components/form/FormControl';
import Dialog, { DialogBody } from 'components/modal/dialog';

interface PropsType {
  jurisdiction: any;
  callback: (flag: boolean, range: any) => void;
}

export class StateType {
  model: any = {};
  errors: any = {};
}

export default class JurisdictionZipCodeRangeDetailsModal extends React.Component<
  PropsType,
  StateType
> {
  constructor(props: PropsType) {
    super(props);
    this.state = Object.assign(new StateType(), {
      model: {
        zipStart: '',
        zipEnd: '',
      },
      errors: {
        zipStart: [],
        zipEnd: [],
      },
    });
  }

  clear() {
    this.setState({ model: { zipStart: '', zipEnd: '' }, errors: {} });
  }

  submitSuccess(model: any) {
    const callback = this.props.callback;
    if (callback) {
      callback(true, model);
    }
    this.clear();
  }

  onUpdateModel = (
    name?: string,
    value?: string,
    errorName?: string,
    errorKeys?: any,
    event?: Event,
    clb?: () => void
  ) => {
    const model = {
      ...this.state.model,
      [name]: value,
    };

    const errors = { ...this.state.errors, [name]: errorName };

    this.setState({ model, errors }, clb);
  };

  submit = (model?: any, hasErrors?: boolean, errors?: any) => {
    if (hasErrors) {
      this.setState({ errors, model });
    } else {
      this.submitSuccess(model);
    }
  };

  onCancel = () => {
    const callback = this.props.callback;
    if (callback) {
      callback(false, null);
    }
    this.clear();
  };

  render() {
    return (
      <Dialog handleClose={this.onCancel}>
        <DialogBody>
          <Form
            ref="form"
            model={this.state.model}
            errors={this.state.errors}
            onCollectValues={this.onUpdateModel}
            submit={this.submit}>
            <ZipCodeInput
              name="zipStart"
              label="ZIP start:"
              validations="required"
            />
            <ZipCodeInput
              name="zipEnd"
              label="ZIP end:"
              validations="required"
            />
            <FormControl>
              <input type="submit" className="btn btn-primary" value="SAVE" />
              <input
                type="button"
                className="btn btn-light"
                onClick={this.onCancel}
                value="Cancel"
              />
            </FormControl>
          </Form>
        </DialogBody>
      </Dialog>
    );
  }
}
