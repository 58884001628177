import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { IconButton } from 'components/button';
import { Grid } from 'components/grid';
import DialogConfirm from 'components/modal/dialogConfirm';
import Tooltip from 'components/tooltip';
import useDebounce from 'components/hook/useDebounce';
import FilterViewResults from './FilterViewResults';
import DialogResendReport, {
  OptionsResendBy,
} from '../components/DialogResendReport';
import DialogTranscription from '../components/dialogTranscription';

import {
  storeOrderTranscription,
  TranscriptionType,
  ReportType,
  FilterType,
} from 'stores/_mobx/workflow/order/transcription';
import { BASE_URL_FILE_DIR } from 'constant/config';

interface PropsType {
  order: {
    patientnm: string;
    patientid: number;
    patientdob: string;
    dos: string;
    gender: 'M' | 'F' | '';
    examtype: ReportType;
    refid: number;
  };
}

const ViewResultsTab = ({ order }: PropsType) => {
  const {
    fetching,
    resendOruInProgress,
    transcriptions,
    transcriptionsTotal,
    filter,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeOrderTranscription;

  const [idForDelete, setIdForDelete] = useState<number>(0);

  const [transcriptionIdForResend, toggleTranscriptionId] = useState<number>(0);

  const [transcriptionIdForEdit, toggleEditDialog] = useState<number>(0);

  const debouncedMethod = useDebounce(setPaginationToStart);

  const columns = useMemo(
    () => [
      { name: 'radiologyGroupName', title: 'Radiology Group' },
      { name: 'radiologistName', title: 'Radiologist' },
      {
        title: 'Accession #',
        name: 'accessionNumber',
        render: (accession: string) =>
          accession ? (
            <Tooltip body={accession}>
              <i className="bi bi-upc-scan fs-5 text-primary" />
            </Tooltip>
          ) : null,
      },
      {
        name: 'cptCode',
        title: 'CPT Code',
        render: (cptCode: string, { isCritical }: TranscriptionType) => (
          <div className="d-flex align-items-center gap-2">
            {cptCode}
            {isCritical && (
              <span className="bi bi-check-circle text-danger fs-5" />
            )}
          </div>
        ),
      },
      {
        name: 'cptDescription',
        title: 'CPT Description',
      },
      {
        name: 'reportTypeName',
        title: 'Report Type',
      },
      {
        name: 'fileName',
        title: 'Document',
        render: (fileName: string, { filePath }: TranscriptionType) =>
          filePath ? (
            <a href={filePath} target="_blank" rel="noreferrer">
              {fileName}
            </a>
          ) : (
            <a
              href={`${BASE_URL_FILE_DIR}tempfolder/${fileName}`}
              target="_blank"
              rel="noreferrer">
              {fileName}
            </a>
          ),
      },
      {
        name: 'control',
        title: 'Actions',
        className: 'text-center',
        render: (_: undefined, data: TranscriptionType) => (
          <div className="control">
            <IconButton
              title="Resend report"
              className="mirroring-h"
              disabled={data.reportType === 'P'}
              onClick={() => {
                data.reportType !== 'P' && toggleTranscriptionId(data.refid);
              }}>
              <i className="bi bi-reply-all" />
            </IconButton>
            <IconButton
              onClick={() => {
                toggleEditDialog(data.refid);
              }}>
              <i className="bi bi-pencil" />
            </IconButton>
            <IconButton onClick={() => setIdForDelete(data.refid)}>
              <i className="bi bi-trash" />
            </IconButton>
          </div>
        ),
      },
    ],
    []
  );

  const fetchData = () => {
    const { refid, patientid } = order;

    const payload = {
      ...filter,
      patientId: patientid,
      orderId: refid,
      pagination,
    };

    storeOrderTranscription.getTranscriptionMain(payload);
  };

  const handleFilterChanged = (filterData: FilterType) => {
    storeOrderTranscription.setFilter(filterData);

    debouncedMethod();
  };

  const handleCloseTranscription = (shouldUpdate: boolean) => {
    toggleEditDialog(null);

    if (shouldUpdate) fetchData();
  };

  const handleCloseDeleteConfirmation = () => {
    setIdForDelete(0);
  };

  const handleApproveDeleting = () => {
    storeOrderTranscription
      .deleteTranscription(idForDelete)
      .then((isSucceed) => {
        if (isSucceed) {
          handleCloseDeleteConfirmation();
          fetchData();
        }
      });
  };

  const handleCloseResend = () => {
    toggleTranscriptionId(0);
  };

  const handleClickResend = (resendBy: OptionsResendBy) => {
    storeOrderTranscription
      .resendReport({ resendBy, transcriptionId: transcriptionIdForResend })
      .then(() => {
        toggleTranscriptionId(0);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <FilterViewResults onChange={handleFilterChanged} />

      <Grid
        id="view_results_grid"
        columns={columns}
        onAjax={fetching}
        dataSource={transcriptions}
        dataSourceCount={transcriptionsTotal}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      {idForDelete ? (
        <DialogConfirm
          onCancel={handleCloseDeleteConfirmation}
          onApprove={handleApproveDeleting}>
          Are you sure you want to delete?
        </DialogConfirm>
      ) : null}

      {transcriptionIdForEdit ? (
        <DialogTranscription
          orderId={order.refid}
          transcriptionId={transcriptionIdForEdit}
          patientInfo={{
            patientName: order.patientnm,
            dob: order.patientdob,
            dos: order.dos,
            gender: order.gender as 'M' | 'F' | '',
            examType: order.examtype,
            id: Number(order.patientid),
          }}
          onClose={handleCloseTranscription}
        />
      ) : null}

      {transcriptionIdForResend ? (
        <DialogResendReport
          isLoading={resendOruInProgress}
          onClose={handleCloseResend}
          onResend={handleClickResend}
        />
      ) : null}
    </>
  );
};

export default observer(ViewResultsTab);
