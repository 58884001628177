import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Grid, TPagination } from 'components/grid';
import useDebounce from 'components/hook/useDebounce';
import FilterPhysicianTab, {
  defaultValues,
  FormModel,
} from './FilterPhysicianTab';

import { storeGrid } from 'stores/_mobx/grid';
import {
  storePhysician,
  PhysicianShortInfoType,
} from 'stores/_mobx/clinicianManager/physician';
import { storeExamReview } from 'stores/_mobx/workflow/order/examReview';
import { getPagination } from 'utils/getPagination';

const getGridSize = () => {
  const gridSize = storeGrid.getPageSize(
    'physician_assignment_physicians_grid'
  );

  const pagination = getPagination(gridSize);

  return pagination;
};

interface PropsType {
  orderId: number;
  order: { assign_providerid: number };
  onAssign: () => void;
}

const PhysicianTab = ({ order, orderId, onAssign }: PropsType) => {
  const { fetching, physicianShortInfoList, physicianShortInfoTotal } =
    storePhysician;

  const { assignInProgress } = storeExamReview;

  const [searchParams, setSearchParams] = useState({
    pagination: getGridSize(),
    filter: defaultValues,
  });

  const setPagination = (pagination: TPagination) => {
    setSearchParams({
      ...searchParams,
      pagination,
    });
  };

  const debouncedMethod = useDebounce(setSearchParams);

  const handleClickAssignPhysician = (data: PhysicianShortInfoType) => {
    const payload = {
      ...data,
      orderId,
    };
    storeExamReview.assignPhysician(payload).then((isSucceed) => {
      if (isSucceed) onAssign();
    });
  };

  const buildRow = ({ id }: PhysicianShortInfoType) =>
    id === order.assign_providerid ? { className: 'fw-bold' } : {};

  const columns = useMemo(
    () => [
      {
        name: 'firstName',
        title: 'First name',
      },
      {
        name: 'lastName',
        title: 'Last name',
      },
      {
        name: 'npi',
        title: 'NPI',
        className: 'width-100',
      },
      {
        name: 'assign',
        title: 'Assign',
        className: 'width-100',
        render: (value: undefined, data: PhysicianShortInfoType) =>
          data.id === order.assign_providerid ? (
            'Assigned'
          ) : (
            <a
              href="/"
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault();
                handleClickAssignPhysician(data);
              }}>
              Assign
            </a>
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order.assign_providerid]
  );

  const handleFilterChange = (filter: FormModel) => {
    const payload = {
      filter,
      pagination: {
        ...searchParams.pagination,
        skip: 0,
        page: 1,
      },
    };

    debouncedMethod(payload);
  };

  useEffect(() => {
    const payload = {
      ...searchParams.filter,
      pagination: searchParams.pagination,
    };

    storePhysician.getShortInfoPhysiciansListMain(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <FilterPhysicianTab onChange={handleFilterChange} />
      <Grid
        id="physician_assignment_physicians_grid"
        onAjax={fetching || assignInProgress}
        columns={columns}
        dataSource={physicianShortInfoList}
        dataSourceCount={physicianShortInfoTotal}
        pagination={searchParams.pagination}
        onPaginationChange={setPagination}
        rowArguments={buildRow}
      />
    </>
  );
};

export default observer(PhysicianTab);
