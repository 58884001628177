import React from 'react';

import { TText, PText, SText } from 'components/form/input/Text';

export interface PCustom extends PText {
  custom?: React.ReactNode;
}

export class SCustom extends SText {}

export default class Custom extends TText<PCustom, SCustom> {
  buildInput(attributes: PCustom): React.ReactNode {
    const { custom } = this.props;
    return custom ? custom : null;
  }
}
