import React, { useMemo, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { SidebarItem } from 'stores/record/UserProfileRecord';
import SidebarLink from './SidebarLink';

interface SidebarMenuProps {
  menu: SidebarItem[];
}

const SidebarMenu = ({ menu }: SidebarMenuProps) => {
  const [activeGroup, setActiveGroup] = useState<string>('');

  const [isVisible, setSidebarVisibility] = useState<boolean>(true);

  const { pathname } = useLocation();

  const activeMenu = useMemo(
    () =>
      menu.find(({ urls }) =>
        urls.some((url) => pathname === url || pathname.startsWith(`${url}/`))
      ),
    [pathname, menu]
  );

  const handleClick = (name: string) => () => {
    setActiveGroup(name);
  };

  const toggleSidebar = () => {
    setSidebarVisibility((state) => !state);
  };

  useEffect(() => {
    if (activeMenu && activeMenu.group) {
      const item = activeMenu.group
        .map(({ menu_item }) => menu_item)
        .flat()
        .find(({ url }) => pathname === url || pathname.startsWith(url));
      const label = item ? item.group_name : activeMenu.group[0].label;
      setActiveGroup(label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenu]);

  return activeMenu ? (
    <div className={`sidebar-wrapper${isVisible ? '' : ' hidden'}`}>
      <button type="button" className="sidebar-toggler" onClick={toggleSidebar}>
        <i className={`bi bi-chevron-${isVisible ? 'left' : 'right'}`} />
      </button>
      <div className="submenu">
        <div className="submenu-title">{activeMenu.parentName}</div>
        {activeMenu.group
          ? activeMenu.group.map(({ label, menu_item }) => {
              const isActive = activeGroup === label;
              return (
                <div
                  key={label}
                  className={`submenu-item${isActive ? ' active' : ''}`}>
                  <span
                    className="submenu-label"
                    onClick={handleClick(label)}
                    data-testid={label}>
                    {label}
                    <i
                      className={`submenu-label-icon bi bi-chevron-${
                        isActive ? 'down' : 'right'
                      }`}
                    />
                  </span>
                  <ul
                    className={`submenu-list ${
                      isActive ? '' : 'visually-hidden'
                    }`}>
                    {menu_item.map(({ app_name, url }) => (
                      <SidebarLink
                        key={app_name}
                        appName={app_name}
                        url={url}
                        onClick={handleClick(label)}
                        data-testid={app_name}
                      />
                    ))}
                  </ul>
                </div>
              );
            })
          : activeMenu.children.map(({ app_name, url }) => (
              <div key={app_name} className="submenu-link-wrapper">
                <NavLink
                  to={url}
                  key={app_name}
                  className="submenu-link"
                  activeClassName="active"
                  data-testid={app_name}>
                  {app_name}
                </NavLink>
              </div>
            ))}
      </div>
    </div>
  ) : null;
};

export default SidebarMenu;
