import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { phoneValidator } from 'utils/formValidators';

const validation = yupResolver(
  yup.object().shape({
    hospiceName: yup.string().required('Please, enter the hospice name!'),
    firstName: yup.string().required('Please, enter the first name!'),
    lastName: yup.string().required('Please, enter the last name!'),
    state: yup.string().required('Please, select the state!'),
    city: yup.string().required('Please, enter the city!'),
    address1: yup.string().required('Please, enter address 1!'),
    phone: yup.string().required('Please, enter the phone!').test({
      test: phoneValidator,
      message: 'Please, enter phone number in the correct format xxx-xxx-xxxx!',
    }),
    fax: yup.string().test({
      test: phoneValidator,
      message: 'Please, enter fax number in the correct format xxx-xxx-xxxx!',
    }),
    zipcode: yup
      .string()
      .required('Please, enter the ZIP code!')
      .test({
        test: (zip: string) => zip.length === 5 || zip.length === 10,
        message: 'ZIP code must contain 5 or 9 numbers!',
      }),
    npi: yup
      .string()
      .required('Please, enter an NPI')
      .test({
        test: (npi: string) => npi.length === 10,
        message: 'NPI must contain 10 characters in length',
      }),
  })
);

export default validation;
