import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { ControlsLayout } from 'components/layout';
import { Input } from 'components/form/textField';
import { MultipleFileUploadControl } from 'components/form/input/MultipleFileUpload';
import { Button } from 'components/button';
import { InputCalendar } from 'components/form/inputCalendar';
import { Textarea } from 'components/form/textarea';
import resolver from './validation';

import { UltrasoundFormModel } from 'stores/_mobx/workflow/equipmentInventory/ultrasound';

const initialValues: UltrasoundFormModel = {
  calibration_date: '',
  calibration_due_date: '',
  comments: '',
  description: '',
  device_name: '',
  start_date: '',
  unique_id: '',
  refid: null,
  documentList: [],
};

interface PropsType {
  backUrl: string;
  defaultValues?: UltrasoundFormModel;
  onSubmit: (data: UltrasoundFormModel) => Promise<any>;
}

const FormModifyLead = ({
  backUrl,
  defaultValues = initialValues,
  onSubmit,
}: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setError,
  } = useForm({ defaultValues, resolver });

  const handleClickReset = () => {
    reset(defaultValues);
  };

  const handleClickSubmit = handleSubmit((data: UltrasoundFormModel) =>
    onSubmit(data).then((error) => {
      if (error) {
        setError('unique_id', {
          type: 'server',
          message: error.message,
        });
      }
    })
  );

  useEffect(() => {
    handleClickReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={isSubmitting ? 'on-ajax' : ''}
      onSubmit={handleClickSubmit}>
      <div className="row">
        <Input
          name="unique_id"
          label="Serial No."
          className="col-md-7 col-lg-4"
          required
          control={control}
        />
        <div />
        <Input
          name="device_name"
          label="Device name"
          className="col-md-7 col-lg-4"
          required
          control={control}
        />
      </div>

      <div className="row">
        <InputCalendar
          name="start_date"
          label="Installation purchase date"
          className="col-md-7 col-lg-4"
          required
          control={control}
        />
        <InputCalendar
          name="calibration_date"
          label="Last calibration date"
          className="col-md-7 col-lg-4"
          control={control}
        />
        <InputCalendar
          name="calibration_due_date"
          label="Next calibration date"
          className="col-md-7 col-lg-4"
          control={control}
        />
      </div>

      <Textarea
        name="description"
        label="Description"
        rows={3}
        control={control}
      />

      <Textarea name="comments" label="Comments" rows={3} control={control} />

      <MultipleFileUploadControl
        name="documentList"
        dir="device"
        control={control}
      />

      <ControlsLayout alignX="right">
        <Link to={backUrl} className="btn btn-danger">
          Back
        </Link>
        <Button
          variant="warning"
          text="Reset"
          type="reset"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormModifyLead;
